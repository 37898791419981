import './ChanceToWinPopup.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useContext } from 'react';
import { useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import SpinCouponItem from '@components/SpinAndWin/SpinCouponItem';

import { SpinWinBannerContext } from '../Spin&WinBanner';

const ChanceToWinPopup = ({ couponList }) => {
  const isLoading = useSelector((state) => state.spinAndWinReducer.getChanceToWinReducer.isLoading);
  const { layoutData } = useContext(SpinWinBannerContext);

  return isLoading ? (
    <LoadingMask />
  ) : (
    <div className='chancetowin-popup'>
      <div className='chancetowin-popup__list'>
        {couponList?.map((coupon, index) => (
          <SpinCouponItem
            isChanceToWin={true}
            key={coupon.Id || index}
            coupon={coupon}
            layoutData={layoutData}
          />
        ))}
      </div>
    </div>
  );
};

ChanceToWinPopup.propTypes = {
  couponList: PropTypes.array
};

export default ChanceToWinPopup;
