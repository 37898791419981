import { useCallback } from 'react';

export const SIMPLE_CTA_CONTAINER_BOX_ELEMENT = 'simple-cta-container-box';

export const useTruncateDescription = () => {
  return useCallback(({ descriptionElement, textRows = 3 }) => {
    return new Promise((resolve) => {
      const computedStyle = window?.getComputedStyle(descriptionElement);
      const descriptionElLineHeight = Number.parseInt(computedStyle?.lineHeight?.replace('px', '') || 0);
      const descriptionElHeight = descriptionElement?.offsetHeight;

      if (descriptionElLineHeight) {
        const maxHeightIsAllowed = textRows * descriptionElLineHeight;
        const currentDescriptionRow = Math.round(descriptionElHeight / descriptionElLineHeight);

        const truncateDescriptionStyle = {
          display: '-webkit-box',
          '-webkit-line-clamp': 3,
          '-webkit-box-orient': 'vertical',
          overflow: 'hidden'
        };
        for (const styleKey of Object.keys(truncateDescriptionStyle)) {
          // // INFO: set height for element
          // descriptionElement.style.height = `${maxHeightIsAllowed}px`;

          // INFO: set truncate style for element that larger than 3 rows
          descriptionElement.style[styleKey] = currentDescriptionRow > textRows ? truncateDescriptionStyle[styleKey] : '';
        }
      }

      resolve(true);
    }).then((response) => {
      if (response) {
        let highestHeight = 0;
        const containerBoxEls = document.querySelectorAll(`#${SIMPLE_CTA_CONTAINER_BOX_ELEMENT}`);
        if (containerBoxEls.length) {
          for (const boxElItem of containerBoxEls) {
            const boxItemHeight = boxElItem.offsetHeight;
            if (boxItemHeight > highestHeight) highestHeight = boxItemHeight;
          }
          if (highestHeight) {
            for (const boxElItem of containerBoxEls) {
              boxElItem.style.height = `${highestHeight}px`;
            }
          }
        }
      }
    });
  }, []);
};
