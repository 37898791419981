import React from 'react';
import { Fragment } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';

const RecentlyViewedProductsShimmer = () => {
  const params = {
    // rebuildOnUpdate: true,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 18,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
        navigation: {
          nextEl: '.recently-viewed-products .swiper-button-next',
          prevEl: '.recently-viewed-products .swiper-button-prev'
        }
      }
    }
  };

  return (
    <Fragment>
      <div className='recently-viewed-products skeleton__item'>
        <div className='container'>
          <h2 className='recently-viewed-products__title experience-title text-center'>
            <span className='text-line w-50'></span>
          </h2>
          <div className='recently-viewed-products__swiper'>
            <SwipeComponent param={params}>
              <div className='swiper-slide recently-viewed-products__contents'>
                <div className='recently-viewed-products__content-wrapper'>
                  <div className='recently-viewed-products__thumb image-wrap'>
                    <div className='image-line'></div>
                  </div>
                  <div className='recently-viewed-products__content-wrapper'>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                  </div>
                </div>
              </div>
              <div className='swiper-slide recently-viewed-products__contents'>
                <div className='recently-viewed-products__content-wrapper'>
                  <div className='recently-viewed-products__thumb image-wrap'>
                    <div className='image-line'></div>
                  </div>
                  <div className='recently-viewed-products__content-wrapper'>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                  </div>
                </div>
              </div>
              <div className='swiper-slide recently-viewed-products__contents'>
                <div className='recently-viewed-products__content-wrapper'>
                  <div className='recently-viewed-products__thumb image-wrap'>
                    <div className='image-line'></div>
                  </div>
                  <div className='recently-viewed-products__content-wrapper'>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                    <div className='clear-10'></div>
                    <span className='text-line'></span>
                  </div>
                </div>
              </div>
            </SwipeComponent>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RecentlyViewedProductsShimmer;