import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { deepCopy } from '@utils/utility';

import LanguageListModel from '../model/LanguageListModel';

const LanguageItem = (props) => {
  const { contentData, onGetLanguageHandler } = props;
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const languageListModel = new LanguageListModel();

    setInputData(languageListModel.getData(deepCopy(contentData)));
  }, []);

  return (
    inputData && (
      <li className='language-selector__item' onClick={() => onGetLanguageHandler(inputData.name, inputData.href)}>
        <span className='language-selector__item__link'>{inputData.name}</span>
      </li>
    )
  );
};

LanguageItem.propTypes = {
  contentData: PropTypes.any,
  onGetLanguageHandler: PropTypes.func
};

export default LanguageItem;
