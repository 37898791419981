import './SimpleCards.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import SimpleCardsModel from '../Model/SimpleCardsModel';
import SimpleCardItem from './SimpleCardItem';
import SimpleCardsShimmer from './SimpleCardsShimmer';

const SimpleCards = ({ params, fields }) => {
  const componentId = (params && params['Component Id']) || '';

  const [simpleCardsData, setsimpleCardsModel] = useState(null);

  const [windowWidth, setWindowWidth] = useState(768);

  const simpleCardsTitleFontsize = params?.['Font Size'] || '';
  const simpleCardsMobileTitleFontsize = params?.['Mobile Font Size'] || '';
  const simpleCardsDescriptionFontsize = params?.['Description Font Size'] || '';
  const simpleCardsMobileDescriptionFontsize = params?.['Mobile Description Font Size'] || '';
  const textLineHeight = params?.['Line Height'] || '';

  const [titleFontSize, setTitleFontSize] = useState(null);
  const [descriptionFontSize, setDescriptionFontSize] = useState(null);

  useEffect(() => {
    setsimpleCardsModel(new SimpleCardsModel().getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth && windowWidth < IPAD_BREAKPOINT) {
      setTitleFontSize(simpleCardsMobileTitleFontsize);

      setDescriptionFontSize(simpleCardsMobileDescriptionFontsize);
    }
    if (windowWidth && windowWidth >= IPAD_BREAKPOINT) {
      setTitleFontSize(simpleCardsTitleFontsize);

      setDescriptionFontSize(simpleCardsDescriptionFontsize);
    }
  }, [windowWidth]);
  
  const isSimpleCardsBackground = simpleCardsData?.['Background Image']?.value?.src || simpleCardsData?.['Mobile Background Image']?.value?.src;

  const getClass = (length) => {
    let fourItemPerRow = [6,7,8,11,12];

    if(fourItemPerRow.includes(length)) {
      return 'simple-card__list-wrap--4item-per-row';
    }

    if (length === 13) {
      return 'simple-card__list-wrap--4item-from-2ndrow';
    }

    return '';
  };

  return simpleCardsData && simpleCardsData.items.length > 0 ? (
    <div
      className={`simple-card animated-slow fadeIn delay-100 ${
        !!isSimpleCardsBackground ? 'simple-card--has-background' : ''
      } ${params['Item Position'] === 'Middle' ? '' : 'simple-card--position-bottom'
      } ${params['Text Color'] === 'Black' ? '' : 'simple-card--text-light'
      } ${params['Layout'] === 'Full-Height' ? 'simple-card--full-height' : ''
      } ${params['Is Not Full Width'] == 1 ? 'container not-full-width' : ''}`}
      id={componentId}
      style={{
        backgroundColor: simpleCardsData['Background Color'].fields.Value.value || ''
      }}
    >
      {!!isSimpleCardsBackground ? (
        <div className='simple-card__background'>
          <ResponsiveImage 
            // imageMobile={simpleCardsData['Mobile Background Image']?.value?.src}
            srcSet={[
              { mw: 768, image: simpleCardsData['Background Image']?.value?.src },
              { mxw: 767, image: simpleCardsData['Mobile Background Image']?.value?.src }
            ]}
            attributes={{
              alt: simpleCardsData['Background Image']?.value?.alt
            }}
          />
        </div>
      ) : (
        <></>
      )}
      <div className='simple-card__content'>
        <div className='container'>
          <Text className='simple-card__heading experience-title' tag='h2' field={simpleCardsData.Title} />
          <Text className='simple-card__description' tag='p' field={simpleCardsData.Description} />
          <div className='simple-card__list'>
            <div className={`simple-card__list-wrap justify-content-center ${getClass(simpleCardsData.items.length)}`}>
              {/* <div className='simple-card__list-wrap simple-card__list-wrap--4item-from-2ndrow justify-content-center'> */}
              {simpleCardsData.items.map((item) => {
                return (
                  <div className='simple-card__item-wrap' key={item.id}>
                    <SimpleCardItem>
                      <div className='simple-card__item'>
                        <div className='simple-card__item__image'>
                          <Image field={item.fields.Image} />
                        </div>
                        <div className='simpled-card__item__text' style={{ lineHeight: textLineHeight+'px' }}>
                          <Text className='simple-card__item__title' tag='h3' field={item.fields.Title} style={{ fontSize: titleFontSize+'px' }}/>
                          <RichText className='simple-card__item__description' tag='div' field={item.fields.Description} style={{ fontSize: descriptionFontSize+'px' }} />
                        </div>
                      </div>
                    </SimpleCardItem>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <SimpleCardsShimmer></SimpleCardsShimmer>
    </LoadingShimmer>
  );
};

SimpleCards.propTypes = {
  params: PropTypes.any,
  fields: PropTypes.any
};

export default SimpleCards;
