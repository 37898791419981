import React, { createContext, useCallback, useContext, useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { useNavigateNextStep } from '../../hooks/useNavigateNextStep';
import { useScheduleAppointmentContext } from '../../hooks/useScheduleAppointmentContext';
import { SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM, SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE } from '../configs';
import SelectDate from './SelectDate';
import SelectStoreSchedule from './SelectStoreSchedule';
import SelectTimeSlotAndGuest from './SelectTimeSlotAndGuest';
import { useScheduleAppointmentFormik } from './useScheduleAppointmentFormik';

const initialStates = {
  formik: {}
};
const ScheduleAppointmentFormContext = createContext(initialStates);

export const useScheduleAppointmentFormContext = () => useContext(ScheduleAppointmentFormContext);

const ScheduleAppointmentForm = () => {
  const { layoutData, scheduleData, setScheduleData } = useScheduleAppointmentContext();
  const navigateNextStep = useNavigateNextStep();

  const handleNavigateNextStep = useCallback(() => {
    const teasData = [...layoutData?.Teas];
    const scentsData = [...layoutData?.Scents];
    const questionsData = [...layoutData?.questions];
    if (!teasData.length && !scentsData.length && !questionsData.length) navigateNextStep(SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM);
    else navigateNextStep(SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE);
  }, [layoutData]);

  const handleSubmit = (values) => {
    setScheduleData((prev) => ({
      ...prev,
      scheduleExperience: {
        storeId: values.storeId,
        date: values.date,
        timeSlot: values.timeSlot,
        guest: values.guest
      }
    }));

    handleNavigateNextStep();
  };
  const formik = useScheduleAppointmentFormik(handleSubmit);

  const isDisableSubmitButton = useMemo(() => {
    return !layoutData?.appointment
      ? !Object.keys(scheduleData.scheduleExperience).every((key) => scheduleData.scheduleExperience[key]) &&
          !Object.keys(formik.values).every((key) =>
            key === 'guest' ? formik.values[key] : formik.values[key] !== scheduleData.scheduleExperience[key]
          )
      : false;
  }, [formik.values, scheduleData.scheduleExperience, layoutData]);

  return (
    <ScheduleAppointmentFormContext.Provider value={{ formik: formik }}>
      <form onSubmit={formik.handleSubmit} className='select-store be-schedule-appointment__schedule-experience'>
        <Text className='be-schedule-appointment__title' field={layoutData['Schedule Experience Title']} tag='h2' />
        <div className='row schedule-experience__body'>
          <div className='col-12 col-lg-4 schedule-experience__body-item'>
            <SelectStoreSchedule />
          </div>
          <div className='col-12 col-lg-4 schedule-experience__body-item'>
            <SelectDate />
          </div>
          <div className='col-12 col-lg-4 schedule-experience__body-item'>
            <SelectTimeSlotAndGuest />
          </div>
        </div>
        <div className='be-schedule-appointment__submit-btn'>
          <button
            type='submit'
            className={['btn btn-primary', isDisableSubmitButton ? 'btn-disable' : ''].join(' ')}
            disabled={isDisableSubmitButton}
          >
            <Text field={layoutData['Button Schedule Experience Text']} />
          </button>
        </div>
      </form>
    </ScheduleAppointmentFormContext.Provider>
  );
};

export default ScheduleAppointmentForm;
