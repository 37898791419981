import { bool, func, object } from 'prop-types';
import React from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';

const PartnerSpecialModal = ({ isShow, handleCloseModalPartner, partnerData, layoutData }) => {
  return (
    <ModalComponent
      isShow={isShow}
      onCloseHandler={handleCloseModalPartner}
      cssClass='osim-popup--no-padding'
    >
      <div className='partner-special-popup'>
        <div className='partner-special-popup__image'>
          <Image field={partnerData?.['Partner Banner']} />
        </div>
        {layoutData?.['See More Button Text']?.value && 
        (
          <>
            <Link field={{ href: partnerData?.['Partner Website'] }} className='partner-special-popup__btn btn btn-primary'>
              <Text field={layoutData?.['See More Button Text']} />
            </Link>
          </>
        )}
      </div>
    </ModalComponent>
  );
};

PartnerSpecialModal.propTypes = {
  isShow: bool,
  handleCloseModalPartner: func,
  partnerData: object,
  layoutData: object
};

export default PartnerSpecialModal;
