import { useCallback, useMemo } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import { HK_SITE_NAME, TW_SITE_NAME } from '../../constant';
import Global, { MONTH_NAMES } from '../../global';
import { convertDDMMYYYYStringToDate, convertHKTWDate } from './utils';

export const useFormatDate = () => {
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  return useCallback(
    (date) => {
      let dateConvertedResult = '';
      if (date) {
        const dateConverted = convertDDMMYYYYStringToDate(date);

        dateConvertedResult = Global.customDate(date, false);
        if (isConvertDate) {
          if (dateConverted) {
            dateConvertedResult = convertHKTWDate({ dateString: dateConverted });
          } else {
            dateConvertedResult = '';
          }
        }
      }

      return dateConvertedResult;
    },
    [isConvertDate]
  );
};

export const useFormatEnDate = () => {
  return useCallback(({ date, isUseComma = false, isHideYear = false }) => {
    const cusDate = new Date(date);

    const day = cusDate.getDate();
    const month = MONTH_NAMES[cusDate.getMonth()];
    const year = isHideYear ? '****' : cusDate.getFullYear();
    const comma = isUseComma ? ',' : '';

    return `${day} ${month}${comma} ${year}`;
  }, []);
};

export const useFormatTime = () => {
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  // 2024/6/28 11:28:11 AM
  return useCallback(
    (dateString = '') => {
      const dateStringInTypeDate = new Date(dateString);
      let result = '';
      if (dateStringInTypeDate) {
        result = dateStringInTypeDate.toLocaleTimeString('en-US');
      }

      return result;
    },
    [isConvertDate]
  );
};

export const useFormatDateTime = () => {
  const formatDateUtil = useFormatDate();
  const formatTimeUtil = useFormatTime();

  return useCallback((dateString) => {
    const dateConverted = formatDateUtil(dateString) ?? '';
    const timeConverted = formatTimeUtil(dateString);

    return dateConverted ? dateConverted + ' ' + timeConverted : '';
  }, []);
};
