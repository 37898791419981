import * as productReviewTypes from './productReviewTypes';

// getProductName
export const getProductsName = (payload) => ({
  type: productReviewTypes.GET_PRODUCTS_NAME,
  payload
});

export const getProductsNameSuccess = (payload) => ({
  type: productReviewTypes.GET_PRODUCTS_NAME_SUCCESS,
  payload
});

export const getProductsNameFailed = (error) => ({
  type: productReviewTypes.GET_PRODUCTS_NAME_FAILED,
  error
});

// getProductReview
export const getProductReview = (payload) => ({
  type: productReviewTypes.GET_PRODUCT_REVIEW,
  payload
});

export const getProductReviewSuccess = (payload) => ({
  type: productReviewTypes.GET_PRODUCT_REVIEW_SUCCESS,
  payload
});

export const getProductReviewFailed = (error) => ({
  type: productReviewTypes.GET_PRODUCT_REVIEW_FAILED,
  error
});

// getCustomerReviewsAverage
export const getCustomerReviewsAverage = (payload) => ({
  type: productReviewTypes.GET_CUSTOMER_REVIEWS_AVERAGE,
  payload
});

export const getCustomerReviewsAverageSuccess = (payload) => ({
  type: productReviewTypes.GET_CUSTOMER_REVIEWS_AVERAGE_SUCCESS,
  payload
});

export const getCustomerReviewsAverageFailed = (error) => ({
  type: productReviewTypes.GET_CUSTOMER_REVIEWS_AVERAGE_fAILED,
  error
});

//UploadListFiles
export const uploadMultiFiles = (payload) => ({
  type: productReviewTypes.UPLOAD_LIST_FILES,
  payload
});

export const uploadMultiFilesSuccess = (payload) => ({
  type: productReviewTypes.UPLOAD_LIST_FILES_SUCCESS,
  payload
});

export const uploadMultiFilesFailed = (error) => ({
  type: productReviewTypes.UPLOAD_LIST_FILES_FAILED,
  error
});

export const filesUploadedStore = (payload) => ({
  type: productReviewTypes.LIST_FILES_UPLOAD_STORE,
  payload
});

//SubmitProductReview
export const submitProductReview = (payload) => ({
  type: productReviewTypes.SUBMIT_PRODUCT_REVIEW,
  payload
});

export const submitProductReviewSuccess = (payload) => ({
  type: productReviewTypes.SUBMIT_PRODUCT_REVIEW_SUCCESS,
  payload
});

export const submitProductReviewFailed = (error) => ({
  type: productReviewTypes.SUBMIT_PRODUCT_REVIEW_FAILED,
  error
});

export const toggleReviewLogin = (payload) => ({
  type: productReviewTypes.TOGGLE_REVIEW_LOGIN,
  payload
});

export const reviewFormParams = (payload) => ({
  type: productReviewTypes.REVIEW_FORM_PARAMS,
  payload
});

//Check serial number
export const serialNumberFormatChecker = (payload) => ({
  type: productReviewTypes.SERIAL_NUMBER_FORMAT_CHECKER,
  payload
});

export const serialNumberFormatCheckerSuccess = (payload) => ({
  type: productReviewTypes.SERIAL_NUMBER_FORMAT_CHECKER_SUCCESS,
  payload
});

export const serialNumberFormatCheckerFailed = (error) => ({
  type: productReviewTypes.SERIAL_NUMBER_FORMAT_CHECKER_FAILED,
  error
});

//GetLatestReview
export const getLatestReview = () => ({
  type: productReviewTypes.GET_LATEST_REVIEW
});

export const getLatestReviewSuccess = (payload) => ({
  type: productReviewTypes.GET_LATEST_REVIEW_SUCCESS,
  payload
});

export const getLatestReviewFailed = (error) => ({
  type: productReviewTypes.GET_LATEST_REVIEW_FAILED,
  error
});

// GetCatalogHeaderNavigation for FullReviewListing page
export const getCatalogHeaderNavigation = (productPageId) => (
  {
    type: productReviewTypes.GET_CATALOG_HEADER_NAVIGATION,
    payload: productPageId
  }
);

export const getCatalogHeaderNavigationSuccess = (payload) => (
  {
    type: productReviewTypes.GET_CATALOG_HEADER_NAVIGATION_SUCCESS,
    payload
  }
);

export const getCatalogHeaderNavigationFailed = (error) => (
  {
    type: productReviewTypes.GET_CATALOG_HEADER_NAVIGATION_FAILED,
    payload: error
  }
);

//GetProductReviewById
export const getProductReviewById = (payload) => ({
  type: productReviewTypes.GET_PRODUCT_REVIEW_BY_ID,
  payload
});

export const getProductReviewByIdSuccess = (payload) => ({
  type: productReviewTypes.GET_PRODUCT_REVIEW_BY_ID_SUCCESS,
  payload
});

export const getProductReviewByIdFailed = (error) => ({
  type: productReviewTypes.GET_PRODUCT_REVIEW_BY_ID_FAILED,
  error
});

//GetBundleElement
export const GetBundleElement = (payload) => ({
  type: productReviewTypes.GET_BUNDLE_ELEMENT,
  payload
});

export const GetBundleElementSuccess = (payload) => ({
  type: productReviewTypes.GET_BUNDLE_ELEMENT_SUCCESS,
  payload
});

export const GetBundleElementFailed = (error) => ({
  type: productReviewTypes.GET_BUNDLE_ELEMENT_FAILED,
  error
});

//GetDynamicBundleElement
export const getDynamicBundleElement = (payload) => ({
  type: productReviewTypes.GET_DYNAMIC_BUNDLE_ELEMENT,
  payload
});

export const getDynamicBundleElementSuccess = (payload) => ({
  type: productReviewTypes.GET_DYNAMIC_BUNDLE_ELEMENT_SUCCESS,
  payload
});

export const getDynamicBundleElementFailed = (error) => ({
  type: productReviewTypes.GET_DYNAMIC_BUNDLE_ELEMENT_FAILED,
  error
});