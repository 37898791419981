import produce from 'immer';

import {
  CHECK_CONTACT_DUPLICATE_FAILURE,
  CHECK_CONTACT_DUPLICATE_START,
  CHECK_CONTACT_DUPLICATE_SUCCESS
} from '@redux/actions/cpCheckContactDuplicate/types';

const initialStates = {
  isChecking: false,
  contactFound: [],
  checkedData: [],
  errorMsg: null,
  checkContactErrorData: null
};

const cpCheckContactDuplicateReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case CHECK_CONTACT_DUPLICATE_START:
      draft.isChecking = true;

      draft.checkedError = null;

      draft.checkContactErrorData = null;
      break;

    case CHECK_CONTACT_DUPLICATE_SUCCESS:
      draft.isChecking = false;

      draft.checkedData = action.payload;
      break;

    case CHECK_CONTACT_DUPLICATE_FAILURE:
      draft.isChecking = false;
      if (typeof action.payload === 'string') {
        draft.errorMsg = action.payload;
      } else {
        draft.contactFound = action.payload.contacts;

        draft.checkContactErrorData = {
          isDuplicatedEmail: action.payload.isDuplicatedEmail,
          isDuplicatedPhone: action.payload.isDuplicatedPhone
        };
      }

      break;
    }

    return draft;
  });

export default cpCheckContactDuplicateReducer;
