import produce from 'immer';

import { SET_TAX_MESSAGE_START } from '@redux/actions/taxMessageDetail/textMessageDetailTypes';

const initialStates = {
  isEnable: false,
  taxMessageText: null
};

export const taxMessageDetailReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case SET_TAX_MESSAGE_START:
      draft.isEnable = action.payload.isEnable;

      draft.taxMessageText = action.payload.taxMessageText;
      break;
    }

    return draft;
  });
