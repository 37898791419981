import { any, bool } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { connect, useDispatch, useSelector } from 'react-redux';

import { addWarranty } from '@redux/actions/addWarranty';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import LoadingMask from '../../Share/LoadingMask';
import { useCurrentWarrantyRegistrationStep } from '../hooks/useCurrentWarrantyRegistrationStep';
import { useNavigateToStep } from '../hooks/useNavigateToStep';
import { LOGIN_REGISTER_STEP, SELECT_PRODUCT_STEP } from '../WarrantyGeneral';
import WarrantyRegisterLoginModel from './models/WarrantyRegisterLoginModel';
import WarrantyLoginForm from './WarrantyLoginForm';
import WarrantyRegisterForm from './WarrantyRegisterForm';

const registerLoginTab = {
  Register: 'osim-account-register',
  Login: 'osim-account-login'
};

const WarrantyRegisterLogin = ({ fields, ...props }) => {
  const { isRegisterSuccess, isRegistering, isLogging, isLoginSuccess } = props;
  const dispatch = useDispatch();
  const navigateToStep = useNavigateToStep();
  const currentStep = useCurrentWarrantyRegistrationStep();
  const { paramAPIData } = useSelector((state) => state.addWarrantyReducer);
  const [layoutData, setLayoutData] = useState(null);
  const [tabCurrent, setTab] = useState(registerLoginTab.Login);

  useEffect(() => {
    setLayoutData(new WarrantyRegisterLoginModel().getData(deepCopy(fields)));
  }, []);

  useEffect(() => {
    if (!paramAPIData) {
      navigateToStep(SELECT_PRODUCT_STEP);
    }
  }, [paramAPIData]);

  useDidUpdateEffect(() => {
    //INFO: tracking sign_up event GA4 when register success
    if (isRegisterSuccess || isLoginSuccess) {
      dispatch(addWarranty(paramAPIData));
    }
  }, [isRegisterSuccess, isLoginSuccess, paramAPIData]);

  return layoutData && currentStep === LOGIN_REGISTER_STEP ? (
    <>
      <div className='warranty-register__login osim-account'>
        <div className='osim-account__tab'>
          <div className='container osim-account__tab__wrap no-padding-mobile'>
            <Tabs activeKey={tabCurrent} onSelect={(tabCurrent) => setTab(tabCurrent)}>
              <Tab eventKey={registerLoginTab.Register} title={<Text field={layoutData['Register Tab Label']} />}>
                <div className='osim-account__tab__content'>
                  <WarrantyRegisterForm
                    layoutData={new WarrantyRegisterLoginModel().getData(deepCopy(fields))}
                    changeTabEvt={() => setTab(registerLoginTab.Login)}
                  />
                </div>
              </Tab>
              <Tab eventKey={registerLoginTab.Login} title={<Text field={layoutData['Login Tab Label']} />}>
                <div className='osim-account__tab__content'>
                  <WarrantyLoginForm
                    layoutData={new WarrantyRegisterLoginModel().getData(deepCopy(fields))}
                    changeTabEvt={() => setTab(registerLoginTab.Register)}
                  />
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
      {isRegistering || isLogging ? <LoadingMask /> : <></>}
    </>
  ) : (
    <></>
  );
};

WarrantyRegisterLogin.propTypes = {
  fields: any,
  isRegisterSuccess: bool,
  isRegistering: bool,
  isLogging: bool,
  isLoginSuccess: bool
};

const mapStateToProps = (state) => ({
  isRegisterSuccess: state.singleSignOnReducer.userInfoReducer.accountUser?.IsRegisterNew,
  isRegistering: state.singleSignOnReducer.registerReducer.isLoading,
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  isLogging: state.singleSignOnReducer.loginReducer?.isLoading
});

export default connect(mapStateToProps)(WarrantyRegisterLogin);
