import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getLatestSOCartFailure, getLatestSOCartSuccess } from '@redux/actions/personalizeOffer/getLatestSOCart';
import { GET_LATEST_SO_CART_START } from '@redux/actions/personalizeOffer/getLatestSOCart/types';
import { getAsObservable } from '@services/genericService';

import { GET_LATEST_SO_CART_ENDPOINT } from './configs';

export const getLatestSOCartEpic = (action$) =>
  action$.pipe(
    ofType(GET_LATEST_SO_CART_START),
    switchMap(() => {
      return getAsObservable(GET_LATEST_SO_CART_ENDPOINT).pipe(
        map((res) => {
          if (res.status === 200 || res.data?.Success) {
            return getLatestSOCartSuccess(res.data);
          } else {
            return getLatestSOCartFailure(res.data || 'Failed');
          }
        }),
        catchError((error) => of(getLatestSOCartFailure(error)))
      );
    })
  );
