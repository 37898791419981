import './PopupBanners.scss';

import Proptypes  from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useState } from 'react';
import ReactPlayer from 'react-player';

import ResponsiveImage from '@components/ResponsiveImage';
import ModalComponent from '@components/Share/ModalComponent';
import { IS_CLICK_OUTSIDE } from '@components/Share/ModalComponent/constant';
import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { THIRTY_DAYS_TO_SECONDS, YEAR_TO_MILLISECONDS } from '@utils/constant';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useWindowSize } from '@utils/customsHook/useWindowSize';

import ProductCountdownTimer from '../ProductCountdownTimer';
import PopupBannerModel from './model/PopupBannerModel';
import {
  handleTrackingPopBannerEvent,
  POPUP_BANNER_CLOSE_ACTION_NAME,
  POPUP_BANNER_TYPE_IMAGE,
  POPUP_BANNER_TYPE_VIDEO, POPUP_BANNER_VIEW_ACTION_NAME
} from './utils';

const PopupBanner = ({ fields, isNewsLetterPopup = false, children }) => {
  const [showPopupBanner, setShowPopupBanner] = useState(true);
  const [fieldData, setFieldData] = useState(null);
  const [playVideo, setPlayVideo] = useState(false);
  const [playIframe, setPlayIFrame] = useState(false);
  const [noRatio, setNoRatio] = useState(null);
  const [isShowBanner, setIsShowBanner] = useState(null);
  const videoRef = useRef(null);
  const [youtubeVideo, setYoutubeVideo] = useState(null);
  const [vimeoVideo, setVimeoVideo] = useState(null);
  const currentWindowWidth = useWindowSize();

  const newsletterWithPropsChild = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { setShowPopupBanner });
    }

    return child;
  });

  const LIST_CLASS_NAME__DESKTOP = {
    Left: 'content-left',
    Right: 'content-right',
    Middle: 'content-center',
    'Top Center': 'content-top-center',
    'Bottom Center': 'content-bottom-center',
    'Bottom Left': 'content-bottom-left',
    'Bottom Right': 'content-bottom-right',
    'Middle Center': 'content-center',
    'Top Left': 'content-top-left',
    'Top Right': 'content-top-right',
    'Middle Left': 'content-center-left',
    'Middle Right': 'content-center-right'
  };

  const LIST_CLASS_NAME__MOBILE = {
    Top: 'content-top',
    Middle: 'content-middle',
    Bottom: 'content-bottom'
  };

  useEffect(() => {
    let model = new PopupBannerModel();

    setFieldData(model.getData(fields));
  }, []);

  useDidUpdateEffect(() => {
    setYoutubeVideo(`https://www.youtube.com/watch?v=${fieldData?.['Youtube Video ID']?.value}`);

    setVimeoVideo(`https://player.vimeo.com/video/${fieldData?.['Vimeo Video ID'].value}`);

    fieldData['Auto play'].value ? setPlayIFrame(true) : setPlayIFrame(false);
  }, [fieldData]);

  useEffect(() => {
    let cookieValue = document.cookie.match('(^|;)\\s*' + 'popupExpired' + '\\s*=\\s*([^;]+)')?.pop() || '';
    let cookieArr = cookieValue.split('|');
    let now = new Date();
    let time = now.getTime();
    let expireTime = time + YEAR_TO_MILLISECONDS;

    now.setTime(expireTime);

    cookieArr.forEach((string, index) => {
      let tempArray = string.split('=');
      if (parseInt(tempArray[1]) + THIRTY_DAYS_TO_SECONDS < Math.floor(new Date().getTime() / 1000)) {
        cookieArr.splice(index, 1);

        cookieArr.join('|');

        document.cookie = `popupExpired=${cookieArr};expires=${now.toUTCString()}`;
      }
    });
  }, []);

  useEffect(() => {
    if (fieldData?.['Video']?.value?.href.length || fieldData?.['Vimeo Video ID']?.value?.length || fieldData?.['Youtube Video ID']?.value?.length) {
      setNoRatio(false);
    } else {
      setNoRatio(true);
    }
  }, [fieldData]);

  useEffect(() => {
    if (!videoRef.current) {
      return;
    }

    videoRef.current.defaultMuted = !fieldData['Enable audio'].value;

    videoRef.current.muted = !fieldData['Enable audio'].value;
  }, [fieldData]);

  useEffect(() => {
    setIsShowBanner(!(window.sessionStorage.getItem('popUpBannerArray') || []).includes(`${fieldData?.['Banner ID']}`));

    if (
      !fieldData?.['Video']?.value.href.length &&
      !fieldData?.['Youtube Video ID']?.value.length &&
      !fieldData?.['Vimeo Video ID'].value.length &&
      !fieldData?.['Desktop Image']?.value.src.length &&
      !fieldData?.['Mobile Image']?.value.src.length
    ) {
      setIsShowBanner(false);
    }
  }, [fieldData]);

  useEffect(() => {
    if (fieldData?.['Banner ID'].length) {
      if (window.localStorage.getItem('popUpBannerArray') === null) {
        let obj = {};

        obj[`${fieldData?.['Banner ID']}`] = 1;

        let tempArray = JSON.parse(window.localStorage.getItem('popUpBannerArray')) || [];

        tempArray.push(obj);

        window.localStorage.setItem('popUpBannerArray', JSON.stringify(tempArray));
      } else {
        if (
          JSON.parse(window.localStorage.getItem('popUpBannerArray')).find((obj) => {
            return Object.prototype.hasOwnProperty.call(obj, `${fieldData?.['Banner ID']}`);
          })
        ) {
          let tempArray = JSON.parse(window.localStorage.getItem('popUpBannerArray'));

          tempArray.forEach((obj) => {
            if (fieldData?.['Banner ID'] && Object.prototype.hasOwnProperty.call(obj, `${fieldData?.['Banner ID']}`)) {
              return {
                [`${fieldData?.['Banner ID']}`]: obj[`${fieldData?.['Banner ID']}`]++
              };
            }
          });

          window.localStorage.setItem('popUpBannerArray', JSON.stringify(tempArray));

          let tempArray2 = JSON.parse(window.localStorage.getItem('popUpBannerArray'));

          tempArray2.forEach((obj, index) => {
            if (Object.values(obj)[0] >= fieldData['Max Time To See'].value) {
              tempArray2.splice(index, 1);
              let cookieValue = document.cookie.match('(^|;)\\s*' + 'popupExpired' + '\\s*=\\s*([^;]+)')?.pop() || '';
              if (!cookieValue.includes(fieldData?.['Banner ID'])) {
                let now = new Date();
                let time = now.getTime();
                let expireTime = time + YEAR_TO_MILLISECONDS;

                if (!cookieValue.length) {
                  let cookieStr = `${fieldData?.['Banner ID']}=${Math.round(new Date().getTime() / 1000)}`;

                  cookieValue = cookieValue.concat(cookieStr);
                } else {
                  let cookieStr = `|${fieldData?.['Banner ID']}=${Math.round(new Date().getTime() / 1000)}`;

                  cookieValue = cookieValue.concat(cookieStr);
                }

                now.setTime(expireTime);

                document.cookie = `popupExpired=${cookieValue};expires=${now.toUTCString()}; path=/`;
              }
            }
          });

          window.localStorage.setItem('popUpBannerArray', JSON.stringify(tempArray2));
        } else {
          if (!document.cookie.includes(`${fieldData?.['Banner ID']}`)) {
            let obj = {};

            obj[`${fieldData?.['Banner ID']}`] = 1;

            let tempArray = JSON.parse(window.localStorage.getItem('popUpBannerArray'));

            tempArray.push(obj);

            window.localStorage.setItem('popUpBannerArray', JSON.stringify(tempArray));
          }
        }
      }
    }
  }, [fieldData]);

  const classRenderMobile = (data) => {
    return LIST_CLASS_NAME__MOBILE[data] || '';
  };

  const classRenderDesktop = (data) => {
    return LIST_CLASS_NAME__DESKTOP[data] || '';
  };

  const handleCloseModal = (isClickOutside) => {
    if (isClickOutside === IS_CLICK_OUTSIDE) {
      setShowPopupBanner(false);
    } else {
      setShowPopupBanner(false);
      // let popUpBannerArray = JSON.parse(window.sessionStorage.getItem('popUpBannerArray')) || [];

      // popUpBannerArray.push(`${fieldData?.['Banner ID']}`);

      // window.sessionStorage.setItem('popUpBannerArray', JSON.stringify(popUpBannerArray));
    }
    if (isNewsLetterPopup) window.sessionStorage.setItem('isShowNewsletterPopupNextTime', JSON.stringify(false));

    //INFO: handle tracking close popup banner event
    handleTrackCloseBannerEvent();
  };

  //INFO: handle tracking close popup banner event
  const handleTrackCloseBannerEvent = useCallback(() => {
    handleTrackingPopBannerEvent(fieldData, POPUP_BANNER_CLOSE_ACTION_NAME);
  }, [fieldData]);

  //INFO: handle tracking view popup banner event
  const handleTrackViewBannerEvent = useCallback(() => {
    handleTrackingPopBannerEvent(fieldData, POPUP_BANNER_VIEW_ACTION_NAME);
  }, [fieldData]);

  return fieldData && isShowBanner ? (
    <div className='popup-banner'>
      <ModalComponent
        aria-labelledby={'dialog'}
        cssClass={`popup-banner__content popup-banner__content--js ${isNewsLetterPopup ? 'newsletter-popup__content' : ''}`}
        isShow={showPopupBanner}
        onCloseHandler={(isClickOutside) => handleCloseModal(isClickOutside)}
      >
        <div className='main-banner text-white animated-slow fadeIn delay-100' onClick={handleTrackViewBannerEvent}>
          <div className={`main-banner__background ${noRatio ? 'no-ratio' : ''} ${playVideo ? 'hide-video-cover' : ''}`}>
            {fieldData['Auto play'].value ? (
              fieldData['Video'].value.href.length ? (
                <video className='popup-banner-video' controls muted playsInline loop autoPlay ref={videoRef}>
                  <source src={fieldData['Video'].value.href} type='video/mp4' />
                </video>
              ) : fieldData['Youtube Video ID'].value.length ? (
                <ReactPlayer
                  className='popup-banner-video'
                  controls={true}
                  width={'100%'}
                  height={'100%'}
                  muted={!fieldData['Enable audio'].value}
                  url={youtubeVideo}
                  loop={true}
                  playing={playIframe}
                />
              ) : fieldData['Vimeo Video ID'].value.length ? (
                <ReactPlayer
                  className='popup-banner-video'
                  controls={true}
                  width={'100%'}
                  height={'100%'}
                  muted={!fieldData['Enable audio'].value}
                  url={fieldData['Vimeo Video ID'].value}
                  loop={true}
                  playing={playIframe}
                />
              ) : (
                <>
                  {fieldData.Link.value.href.length ? (
                    <Link field={fieldData.Link}>
                      <ResponsiveImage
                        imageMobile={fieldData['Mobile Image']}
                        srcSet={[{ mw: 768, image: fieldData['Desktop Image'] }]}
                        attributes={{ alt: 'popup banner'}}
                      />
                    </Link>
                  ) : (
                    <ResponsiveImage
                      imageMobile={fieldData['Mobile Image']}
                      srcSet={[{ mw: 768, image: fieldData['Desktop Image'] }]}
                      attributes={{ alt: 'popup banner'}}
                    />
                  )}
                </>
              )
            ) : fieldData['Video'].value.href.length ? (
              <>
                {fieldData['Video Cover Image'].value.src.length ? (
                  <div className='main-banner__background__video-cover'>
                    <Image alt='popup banner' field={fieldData['Video Cover Image']} />
                    <div
                      className='video__contents__btn-play'
                      onClick={() => {
                        setPlayVideo(true);

                        videoRef.current.play();

                        if (!videoRef.current) {
                          return;
                        }

                        videoRef.current.defaultMuted = !fieldData['Enable audio'].value;

                        videoRef.current.muted = !fieldData['Enable audio'].value;
                      }}
                    >
                      <div className='btn-play-icon'>
                        <span className='play-icon'></span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <video className='popup-banner-video' controls muted playsInline loop ref={videoRef}>
                  <source src={fieldData['Video'].value.href} type='video/mp4' />
                </video>
              </>
            ) : fieldData['Youtube Video ID'].value.length ? (
              <>
                {fieldData['Video Cover Image'].value.src.length ? (
                  <div className='main-banner__background__video-cover'>
                    <Image alt='popup banner' field={fieldData['Video Cover Image']} />
                    <div
                      className='video__contents__btn-play'
                      onClick={() => {
                        setPlayVideo(true);

                        setPlayIFrame(true);
                      }}
                    >
                      <div className='btn-play-icon'>
                        <span className='play-icon'></span>
                      </div>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <ReactPlayer
                  className='popup-banner-video'
                  controls={true}
                  width={'100%'}
                  height={'100%'}
                  muted={!fieldData['Enable audio'].value}
                  url={youtubeVideo}
                  loop={true}
                  playing={playIframe}
                />
              </>
            ) : fieldData['Vimeo Video ID'].value.length ? (
              <>
                {fieldData['Video Cover Image'].value.src.length ? (
                  <div className='main-banner__background__video-cover'>
                    <Image alt='popup banner' field={fieldData['Video Cover Image']} />
                    {fieldData['Video Cover Image'].value.src.length ? (
                      <div
                        className='video__contents__btn-play'
                        onClick={() => {
                          setPlayVideo(true);

                          setPlayIFrame(true);
                        }}
                      >
                        <div className='btn-play-icon'>
                          <span className='play-icon'></span>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                ) : (
                  <></>
                )}
                <ReactPlayer
                  className='popup-banner-video'
                  controls={true}
                  width={'100%'}
                  height={'100%'}
                  muted={!fieldData['Enable audio'].value}
                  url={vimeoVideo}
                  loop={true}
                  playing={playIframe}
                />
              </>
            ) : (
              <>
                {fieldData.Link.value.href.length ? (
                  <Link field={fieldData.Link}>
                    <ResponsiveImage
                      imageMobile={fieldData['Mobile Image']}
                      srcSet={[{ mw: 768, image: fieldData['Desktop Image'] }]}
                      attributes={{ alt: 'popup banner'}}
                    />
                  </Link>
                ) : (
                  <ResponsiveImage
                    imageMobile={fieldData['Mobile Image']}
                    srcSet={[{ mw: 768, image: fieldData['Desktop Image'] }]}
                    attributes={{ alt: 'popup banner'}}
                  />
                )}
              </>
            )}
            {fieldData['Enable Countdown Timer']?.value ? (
              <div className='popup-banner__countdown-container'>
                <div
                  className={`popup-banner__countdown ${
                    currentWindowWidth >= IPAD_BREAKPOINT
                      ? `popup-banner__countdown-desktop ${classRenderDesktop(fieldData['Countdown Desktop Position'].value)}`
                      : `popup-banner__countdown-mobile ${classRenderMobile(fieldData['Countdown Mobile Position'].value)}`
                  }`}
                >
                  <ProductCountdownTimer
                    fontSize={{
                      dateMobileUnit: fieldData['Countdown Date Mobile Unit Font Size']?.value || '',
                      dateUnit: fieldData['Countdown Date Unit Font Size']?.value || '',
                      timerMobileText: fieldData['Countdown Timer Mobile Text Font Size']?.value || '',
                      timerText: fieldData['Countdown Timer Text Font Size']?.value || '',
                      titleMobileText: fieldData['Countdown Title Mobile Text Font Size']?.value || '',
                      titleText: fieldData['Countdown Title Text Font Size']?.value || ''
                    }}
                    colorCode={{
                      dateUnitText: fieldData['Countdown Date Unit Text Color Code']?.value || '',
                      timerText: fieldData['Countdown Timer Text Color Code']?.value || '',
                      titleText: fieldData['Countdown Title Text Color Code']?.value || ''
                    }}
                    text={{
                      title: fieldData['Countdown Title Text']?.value || '',
                      days: fieldData['Countdown Days Text']?.value || '',
                      hours: fieldData['Countdown Hours Text']?.value || '',
                      minutes: fieldData['Countdown Minutes Text']?.value || '',
                      seconds: fieldData['Countdown Seconds Text']?.value || ''
                    }}
                    position={{
                      countdownDesktop: fieldData['Countdown Desktop Position']?.value || '',
                      countdownMobile: fieldData['Countdown Mobile Position']?.value || ''
                    }}
                    startTime={fieldData['Start Time']?.value || ''}
                    endTime={fieldData['End Time']?.value || ''}
                    countdownBgImage={fieldData['Countdown Background Image']?.value?.src || ''}
                  />
                </div>
              </div>
            ) : (
              <></>
            )}
            {/*INFO: use for newsletter content start*/}
            {newsletterWithPropsChild}
            {/*INFO: use for newsletter content end*/}
          </div>
          {/*<div className='main-banner__content'>*/}
          {/*  <div className='container main-banner__content__container content-position content-middle content-center'>*/}
          {/*    <div className='main-banner__content__wrap content-wrapper'>*/}
          {/*      <h2 className='main-banner__content__title'>The Name Of Popup Banner</h2>*/}
          {/*      <div className='main-banner__content__desc'>*/}
          {/*        Look forward to a constantly improving massage experience as the OSIM AI technology analyzes your massage preferences in the cloud*/}
          {/*        to develop better and*/}
          {/*      </div>*/}
          {/*      <div className='main-banner__content__btn'>*/}
          {/*        <a href='' className='main-banner__content__btn__item btn btn-outline-white'>*/}
          {/*          Banner button*/}
          {/*        </a>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </ModalComponent>
    </div>
  ) : (
    ''
  );
};

PopupBanner.propTypes = {
  fields: Proptypes.any,
  isNewsLetterPopup: Proptypes.bool,
  children: Proptypes.any
};

export default PopupBanner;
