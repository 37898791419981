import { any, object } from 'prop-types';
import React, { useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { useFormatDate } from '@utils/customsHook/useFormatDate';
import { useWindowSize } from '@utils/customsHook/useWindowSize';
import Global from '@utils/global';

import ViewOfferButton from './ViewOfferButton';

const ProductInfoSection = ({ layoutData, cartData }) => {
  const windowSize = useWindowSize();
  const formatDate = useFormatDate();

  const productNames = useMemo(() => {
    return cartData?.ProductNames;
  }, [cartData]);

  const productNamesLength = useMemo(() => {
    return productNames?.length || 0;
  }, [productNames]);

  const offerFromText = useMemo(() => {
    const offerFieldText = layoutData?.['Offer From Label Text'];
    const staffName = cartData?.StaffName || layoutData?.['Staff Name']?.value || '';

    return {
      ...offerFieldText,
      value: offerFieldText?.value?.replace('{staff}', staffName)?.replace('{store_name}', cartData?.StoreName)
    };
  }, [cartData]);

  const expiredDate = useMemo(() => {
    const rawExpiredDate = cartData?.ExpiredDate;
    const convertJsonDate = rawExpiredDate ? Global.convertJSONDateToNumber(rawExpiredDate) : '';

    return convertJsonDate ? formatDate(convertJsonDate) : '';
  }, [cartData, formatDate]);

  return cartData ? (
    <>
      {windowSize < IPAD_BREAKPOINT ? (
        <Text field={offerFromText} tag='p' className='personalize-offer-widget__content-description offer-from__text' />
      ) : (
        <></>
      )}
      <div className='personalize-offer-widget__content'>
        <div className='content-image'>
          <div className='content-image__container'>
            <img src={cartData?.ProductSummary?.Image} alt={cartData?.ProductSummary?.ProductName} />
          </div>
        </div>
        <div className='content-wrapper'>
          {windowSize >= IPAD_BREAKPOINT ? <Text field={offerFromText} tag='p' className='personalize-offer-widget__content-description' /> : <></>}
          {productNamesLength === 1 ? (
            <h2 className='product-name'>{productNames[0]}</h2>
          ) : (
            <h2 className='product-name'>
              {layoutData?.['Display Product Name']?.value
                ?.replace('{DisplayName}', productNames[0])
                .replace('{ProductCount}', productNamesLength - 1)}
            </h2>
          )}
          <div className='product-price-offer'>
            <p className='personalize-offer-widget__content-description'>
              <Text field={layoutData?.['Total Label Text']} /> {cartData?.Total}
            </p>
            <p className='personalize-offer-widget__content-description'>
              <Text field={layoutData?.['Offer Valid Label Text']} /> {expiredDate}
            </p>
          </div>
          {windowSize >= IPAD_BREAKPOINT ? (
            <ViewOfferButton layoutData={layoutData} cartStatus={cartData?.Status} cartName={cartData?.CartName} staffId={cartData?.StaffId} />
          ) : (
            <></>
          )}
        </div>
      </div>
      {windowSize < IPAD_BREAKPOINT ? (
        <ViewOfferButton layoutData={layoutData} cartStatus={cartData?.Status} cartName={cartData?.CartName} staffId={cartData?.StaffId} />
      ) : (
        <></>
      )}
    </>
  ) : (
    <></>
  );
};

ProductInfoSection.propTypes = {
  layoutData: object,
  cartData: any
};

export default ProductInfoSection;
