import { object } from 'prop-types';
import React, { Fragment } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import CPInput from '../GenericComponents/CPForm/CPInput';
import CPSelect from '../GenericComponents/CPForm/CPSelect';
import { TYPE_CHECKBOX, TYPE_RADIO } from '../GenericComponents/CPForm/FormConstant';

const ReviewCustomerInformationShimmer = ({ layoutData }) => {
  const onChangeSelect = () => {};

  const generateDropdownOption = (data) => {
    let arr = data.map((item) => {
      return { value: item.fields.Answer.value, label: item.fields.Answer.value };
    });

    return arr;
  };

  return (
    <div className='cp-review__content__info skeleton__item'>
      <Text field={layoutData['Title']} className='cp-review__content__info__title' tag='h5' />
      <div className='cp-review__content__info__box'>
        <form className='cp-form cp-form--existing-cus'>
          <h4 className='text-line-35 w-30'></h4>
          <div className='cp-form__wrap'>
            <div className='cp-form__left'>
              <div className='form-group d-flex'>
                <div className='cp-form__icon-left'>
                  <Image field={layoutData['Mail Icon']} />
                </div>
                <div className='cp-form__group d-flex'>
                  <span className='cp-form__value-text mail-value text-line-35 w-30'></span>
                  <Image className='cp-icon' field={layoutData['Show Info Icon']} />
                </div>
              </div>
            </div>
            <div className='cp-form__right'>
              <div className='form-group d-flex'>
                <div className='cp-form__icon-left'>
                  <Image field={layoutData['Phone Icon']} />
                </div>
                <div className='cp-form__group d-flex'>
                  <span className='cp-form__value-text phone-value text-line-35 w-30'></span>
                  <Image className='cp-icon' field={layoutData['Show Info Icon']} />
                </div>
              </div>
              <div className='form-group form-group--mb-30 d-flex d-flex--space-between '>
                {/* <ul className='cp-form__social-icon'>
                  <li className='cp-form__social-icon__item'>
                    <Image field={layoutData['Message Button Icon']} />
                  </li>
                  <li className='cp-form__social-icon__item'>
                    <Image field={layoutData['Dial Button Icon']} />
                  </li>
                  <li className='cp-form__social-icon__item'>
                    <span className='cp-form__social-icon__item__text'>SMS</span>
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
          <div className='cp-form__wrap'>
            <div className='cp-form__left'>
              <div className='form-group d-flex'>
                <div className='cp-form__icon-left'>
                  <Image field={layoutData['Birthday Icon']} />
                </div>
                <div className='cp-form__group'>
                  <span className='cp-form__value-text text-line-35 w-30'></span>
                </div>
              </div>
              {layoutData.Questions.map((q, iq) => {
                return (
                  <Fragment key={iq}>
                    {iq % 2 ? (
                      <div className='form-group d-flex'>
                        <div className='cp-form__icon-left'>
                          <Image field={q.Image} />
                        </div>
                        <div className='cp-form__group'>
                          {q['Display Type'] == 'Drop Down' ? (
                            <CPSelect
                              selectId='selectId'
                              onChangeSelect={() => onChangeSelect()}
                              options={generateDropdownOption(q.Answers)}
                              selectPlaceholder='Select HouseholdSize'
                            />
                          ) : (
                            <>
                              {q.Answers.map((a, ia) => {
                                return (
                                  <div className='cp-form__group__item' key={ia}>
                                    <CPInput
                                      inputName={q['Display Type'] == 'Single Select' ? `input-radio-${q.Question}` : `input-radio-${a.id}`}
                                      isRectangle={true}
                                      inputType={
                                        q['Display Type'] == 'Single Select' ? TYPE_RADIO : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                      }
                                      inputId={`input-radio-${a.id}`}
                                      labelData={a.fields.Answer.value}
                                      layoutServiceData={a.fields}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Fragment key={iq}></Fragment>
                    )}
                  </Fragment>
                );
              })}
              {/* <div className='form-group d-flex'>
                          <div className='cp-form__icon-left'></div>
                          <div className='cp-form__group'>
                            <CPSelect selectId='selectId' onChangeSelect={onChangeSelect()} options={options} selectPlaceholder='Massage chair' />
                          </div>
                        </div> */}
            </div>
            <div className='cp-form__right'>
              {layoutData.Questions.map((q, iq) => {
                return (
                  <Fragment key={iq}>
                    {!(iq % 2) ? (
                      <div className='form-group d-flex'>
                        <div className='cp-form__icon-left'>
                          <Image field={q.Image} />
                        </div>
                        <div className='cp-form__group'>
                          {q['Display Type'] == 'Drop Down' ? (
                            <CPSelect
                              selectId='selectId'
                              onChangeSelect={() => onChangeSelect()}
                              options={generateDropdownOption(q.Answers)}
                              selectPlaceholder='Select HouseholdSize'
                            />
                          ) : (
                            <>
                              {q.Answers.map((a, ia) => {
                                return (
                                  <div className='cp-form__group__item' key={ia}>
                                    <CPInput
                                      inputName={q['Display Type'] == 'Single Select' ? `input-radio-${q.Question}` : `input-radio-${a.id}`}
                                      isRectangle={true}
                                      inputType={
                                        q['Display Type'] == 'Single Select' ? TYPE_RADIO : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                      }
                                      inputId={`input-radio-${a.id}`}
                                      labelData={a.fields.Answer.value}
                                      layoutServiceData={a.fields}
                                    />
                                  </div>
                                );
                              })}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <Fragment key={iq}></Fragment>
                    )}
                  </Fragment>
                );
              })}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

ReviewCustomerInformationShimmer.propTypes = {
  layoutData: object
};

export default ReviewCustomerInformationShimmer;
