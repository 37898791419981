import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getContactFailure, getContactSuccess } from '@redux/actions/campaignRegister/getContact';
import { GET_CONTACT_START } from '@redux/actions/campaignRegister/getContact/types';
import { get } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import { GET_CONTACT_API_ENDPOINT } from './config';

const getContactEpic = (action$) =>
  action$.pipe(
    ofType(GET_CONTACT_START),
    switchMap((action) => {
      const params = {
        MobileNumber: action.payload.mobileNumber
      };
      if (action.payload.emailAddress) params.EmailAddress = action.payload.emailAddress;
      if (String(action.payload.isBookExperience)) params.IsBookExperience = action.payload.isBookExperience;

      return from(get(GET_CONTACT_API_ENDPOINT, params)).pipe(
        map((res) => {
          if (res.data.StatusCode === 0) {
            return getContactSuccess(deepCopy(res.data.Result));
          } else {
            return getContactFailure(res.data || 'Failed');
          }
        }),
        catchError((error) => getContactFailure(error || 'Failed'))
      );
    })
  );

export default getContactEpic;
