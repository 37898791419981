import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/cPOverview';
import * as cPOverviewTypes from '@redux/actions/cPOverview/cPOverviewTypes';
import { post } from '@services/genericService';

import * as config from '../config';

const cpOverviewEpic = (action$) =>
  action$.pipe(
    ofType(cPOverviewTypes.GET_OVERVIEW),
    switchMap((action) => {
      const params = {
        PageNumber: action.payload.PageNumber,
        PageSize: action.payload.PageSize,
        Date: action.payload.Date,
        WithCurrentStore: action.payload.WithCurrentStore
      };

      return from(post(config.ACTION_CP_OVERVIEW, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.cPOverviewSuccess({
              ...res.data
            });
          } else {
            return actions.cPOverviewFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export default cpOverviewEpic;
