import './SubNavigation.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { getLayoutElementStyleSelector } from '@redux/reducers/layoutElementStyle/selectors';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import SubNavigationItem from './SubNavigationItem';

const SubNavigation = ({ fields, params }) => {
  const subNavRef = useRef(null);
  const items = fields?.items || [];
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const hideReviewTab = params?.['Hide Review Tabs']?.replace(/{|}/g, '').toLowerCase() || '';
  const excludeFromProductReviewSearch = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Exclude From Product Review Search']?.value;
  const { cookieBannerHeight, headerHeight } = useSelector(getLayoutElementStyleSelector);

  useEffect(() => {
    const onSetSubNavTopSpace = () => {
      const productDetailHeader = document.getElementById('product-detail-header');
      let subNavStyleTop = 0;
      if (productDetailHeader) subNavStyleTop = `${productDetailHeader?.offsetHeight + cookieBannerHeight}px`;
      else subNavStyleTop = `${headerHeight + cookieBannerHeight}px`;
      if (subNavRef.current) subNavRef.current.style.top = subNavStyleTop;
    };

    window.addEventListener('scroll', onSetSubNavTopSpace);

    window.addEventListener('resize', onSetSubNavTopSpace);

    onSetSubNavTopSpace();

    return () => {
      window.removeEventListener('scroll', onSetSubNavTopSpace);

      window.removeEventListener('resize', onSetSubNavTopSpace);
    };
  }, [subNavRef?.current?.style.top, cookieBannerHeight, headerHeight]);

  return !isClientTelingUser && items.length > 0 && (
    <div ref={subNavRef} className='sub-navigation'>
      <div className='sub-navigation__wrap'>
        <div className='container'>
          <ul className='sub-navigation__nav-tabs'>
            {items.map((item, index) =>
              item.id.toLowerCase() === hideReviewTab && excludeFromProductReviewSearch ? (
                ''
              ) : (
                <SubNavigationItem key={index} data={item}></SubNavigationItem>
              )
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

SubNavigation.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
};

export default SubNavigation;
