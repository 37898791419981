import { GET_MY_REWARD_WIDGET_INFO_FAILED, GET_MY_REWARD_WIDGET_INFO_START, GET_MY_REWARD_WIDGET_INFO_SUCCESS } from './types';

export const getMyRewardWidgetInfoStart = () => ({
  type: GET_MY_REWARD_WIDGET_INFO_START
});

export const getMyRewardWidgetInfoSuccess = (payload) => ({
  type: GET_MY_REWARD_WIDGET_INFO_SUCCESS,
  payload
});

export const getMyRewardWidgetInfoFailed = (payload) => ({
  type: GET_MY_REWARD_WIDGET_INFO_FAILED,
  payload
});
