import produce from 'immer';

import {
  CLOSE_CP_NOTIFICATION_POPUP,
  OPEN_CP_NOTIFICATION_POPUP,
  SET_CP_NOTIFICATION_POPUP_SPACING
} from '../../../actions/cpNotificationPopup/popupToggle/types';

const initialStates = {
  isOpen: false,
  spacing: null
};

const popupToggleReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case OPEN_CP_NOTIFICATION_POPUP:
      draft.isOpen = true;
      break;

    case CLOSE_CP_NOTIFICATION_POPUP:
      draft.isOpen = false;
      break;

    case SET_CP_NOTIFICATION_POPUP_SPACING:
      draft.spacing = action.payload;
      break;
    }

    return draft;
  });

export default popupToggleReducer;
