import Proptypes from 'prop-types';
import React, { useEffect } from 'react';
import { Fragment } from 'react';

import Message from '@components/Share/Message';

const BundleBOAdditionalOptionsList = ({ contentData, parentId, onAction, onToogleFnc, productId, uniqueProductId, additionalOptionIndex, disposalPolicyLabel, disposalPolicyPage}) => {
  useEffect(() => {
    if (contentData.length === 1) {
      onClickBuyingItemHandler(contentData[0].ProductId, parentId, productId, uniqueProductId, '000');
    }
  }, []);

  const onClickBuyingItemHandler = (itemId, parentId, productId, uniqueProductId, additionalOptionIndex) => {
    onAction(itemId, parentId, productId, uniqueProductId, additionalOptionIndex);

    onToogleFnc();
  };

  return (
    <div className='warranty-buying-list'>
      <div className='row'>
        {contentData.map((item, itemIndex) => {
          return (
            <div className='col-lg-6 warranty-buying-list__item-wrap' key={itemIndex}>
              <div
                className={'warranty-buying-list__item' + `${item.IsChoseAdditionalOption ? ' active' : ''}`}
                onClick={() => onClickBuyingItemHandler(item.ProductId, parentId, productId, uniqueProductId, `${additionalOptionIndex}${itemIndex}`)}
                key={itemIndex}
              >
                <span className='custom-radio-select warranty-buying-list__item__name'>{item.DisplayName}</span>
                <span className='addition-price warranty-buying-list__item__price'>
                  {item.Price.ListPrice === 0 ? (
                    <Fragment>
                      <Message messageCode='LBL-WarrantyFee' />
                    </Fragment>
                  ) : (
                    <Fragment>{item.Price.SellingPriceWithCurrency}</Fragment>
                  )}
                </span>
              </div>
            </div>
          );
        })}
      </div>
      {disposalPolicyLabel && disposalPolicyPage && (
        <a href={disposalPolicyPage} target='_blank' rel='noreferrer'>
          <span>{disposalPolicyLabel}</span>
        </a>
      )}
    </div>
  );
};

BundleBOAdditionalOptionsList.propTypes = {
  contentData: Proptypes.any,
  parentId: Proptypes.any,
  onAction: Proptypes.any,
  onToogleFnc: Proptypes.any,
  productId: Proptypes.string,
  uniqueProductId: Proptypes.string,
  additionalOptionIndex: Proptypes.string,
  disposalPolicyLabel: Proptypes.string,
  disposalPolicyPage: Proptypes.string
};

export default BundleBOAdditionalOptionsList;
