import React, { useState } from 'react';

import FilterPopup from './FilterPopup';

const FilterScheduleStoreBtn = () => {
  const [isShowPopup, setIsShowPopup] = useState(false);

  const handleClosePopup = () => {
    setIsShowPopup(false);
  };

  return (
    <>
      <div className='store-locator__search__btn__item store-locator__search__btn__item--filter' onClick={() => setIsShowPopup(true)}>
        <span className='store-locator-icon store-locator-icon--filter'></span>
      </div>
      <FilterPopup showFilter={isShowPopup} onCloseFilterPopup={handleClosePopup} />
    </>
  );
};

export default FilterScheduleStoreBtn;
