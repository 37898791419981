import './main-navigation.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import { behaviorCatalogueNavigation$ } from './CatalogueNavigationBehavior';
import MainNavigationSubItem from './MainNavigationSubItem';
import MainNavigationSubMenu from './MainNavigationSubMenu';

const MainNavigation = (props) => {
  const [dataFields] = useState(props.fields || null);
  const [checklabel, setChecklabel] = useState(' ');
  const [welcomeMessage, setMessage] = useState('');
  const shopByLabel = props?.fields?.['Shop By Label'] || { value: '' };

  const checkShowData = (check) => setChecklabel(check);

  const userInfoReducer = useSelector((state) => state.singleSignOnReducer.userInfoReducer);

  useEffect(() => {
    if (userInfoReducer.isLogin && dataFields) {
      const welcomeMessageTmp = deepCopy(dataFields['Welcome Message'] || { value: '' });

      welcomeMessageTmp.value = welcomeMessageTmp.value
        .replace('{user-first-name}', userInfoReducer.accountUser?.FirstName || '')
        .replace('{user-last-name}', userInfoReducer.accountUser?.LastName || '');

      setMessage(welcomeMessageTmp);
    }
  }, [userInfoReducer.accountUser, dataFields]);

  useEffect(() => {
    if (dataFields.Categories.length || dataFields.Needs.length || dataFields.Rental.length || dataFields.promotions.items.length) {
      behaviorCatalogueNavigation$.next(true);
    } else {
      behaviorCatalogueNavigation$.next(false);
    }
  }, [dataFields]);

  return dataFields ? (
    <div className='main-navigation'>
      {userInfoReducer.isLogin ? <p className='main-navigation__name-account'>{welcomeMessage.value}</p> : ''}

      <Text field={shopByLabel} tag='p' className='main-navigation__note normal-text'></Text>
      <div className='first-nav-list'>
        <div>
          <MainNavigationSubMenu
            class='category-label'
            contentData={dataFields.Categories}
            contentLabel={dataFields['Categories Label']}
            checkShowData={checkShowData}
            checklabel={checklabel}
          />
        </div>
        <div>
          <MainNavigationSubMenu
            contentData={dataFields.Needs}
            contentLabel={dataFields['Needs Label']}
            checkShowData={checkShowData}
            checklabel={checklabel}
          />
        </div>
        <div>
          <MainNavigationSubMenu
            contentData={dataFields.promotions?.items || []}
            contentLabel={dataFields['Promotions Label']}
            checkShowData={checkShowData}
            checklabel={checklabel}
            type='promotion'
          />
        </div>
        <div>
          {/* {
            dataFields['Extra Links'] && dataFields['Extra Links'].length > 0 && dataFields['Extra Links'].map((item, index) => {
              return (
                <MainNavigationSubItem contentData={item} key={index}></MainNavigationSubItem>
              );
            })
          } */}
          {dataFields?.extraLinks && Object.keys(dataFields?.extraLinks).length ? (
            Object.values(dataFields?.extraLinks).map((item, index) => {
              return <MainNavigationSubItem contentData={item} key={index} checklabel={checklabel} checkShowSubMenu={checkShowData} />;
            })
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

MainNavigation.propTypes = {
  fields: PropTypes.any
};

export default MainNavigation;
