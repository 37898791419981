import './BaseCouponItem.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

const BaseCouponItem = (props) => {
  const onOpenDetailHandler = () => {
    props.onOpenDetailHandler(props.data.Id);
  };

  const onApplyCouponCodeHandler = () => {
    props.onApplyCouponHandler({ data: props.data, actionType: 'apply' });
  };

  return (
    <Fragment>
      <div className='cart-promotion-code-item__infor-icon info-icon info-icon--small' onClick={() => onOpenDetailHandler()}></div>
      <div className='cart-promotion-code-item__main-info'>
        <h4 className='cart-promotion-code-item__name'>{props.data.Code}</h4>
        <p className='cart-promotion-code-item__description' dangerouslySetInnerHTML={{__html: props.data.Description}}></p>
      </div>
      <div className='cart-promotion-code-item__footer justify-space-between'>
        <div className='cart-promotion-code-item__footer__date'>
          <Text tag='span' field={props.dataFromLayout['Valid Until Label']} />
          <span> {global.formatDateCoupon(props.data['ValidityTo'])}</span>
        </div>
        <button className='cart-promotion-code-item__footer__button' onClick={() => onApplyCouponCodeHandler()}>
          <Text tag='span' field={props.dataFromLayout['Apply Button Text']} />
        </button>
      </div>
      <div className='left-light'></div>
    </Fragment>
  );
};

BaseCouponItem.propTypes = {
  onOpenDetailHandler: PropTypes.func,
  data: PropTypes.object,
  onApplyCouponHandler: PropTypes.func,
  index: PropTypes.number,
  myClassName: PropTypes.string,
  dataFromLayout: PropTypes.object
};

export default BaseCouponItem;
