import * as settingGlobalURLTypes from './settingGlobalURLTypes';

export const getSettingGlobalURL = (searchTerm) => ({
  type: settingGlobalURLTypes.GET_SETTING_GLOBAL_URL,
  searchTerm
});

export const getSettingGlobalURLSuccess = (payload) => ({
  type: settingGlobalURLTypes.GET_SETTING_GLOBAL_URL_SUCCESS,
  payload
});

export const getSettingGlobalURLFailed = (error) => ({
  type: settingGlobalURLTypes.GET_SETTING_GLOBAL_URL_FAILED,
  error
});
