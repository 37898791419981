import { CHINESE_LANGUAGES } from '@utils/constant';
import Global from '@utils/global';

export const convertDDMMYYYYStringToDate = (dateString) => {
  if (dateString) {
    const convertDateTime = new Date(dateString);
    const day = convertDateTime.getDate() ?? 0;
    const month = convertDateTime.getMonth() ?? 0;
    const year = convertDateTime.getFullYear() ?? 0;
    if (!day || !month || !year) return '';

    return new Date(+year, +month, +day) ?? '';
  }

  return '';
};

export const convertHKTWDate = ({ dateString, isHideYear = false }) => {
  const convertResult = Global.customDate(dateString);
  const [year, month, day] = convertResult.split('/');

  return (isHideYear ? '****' : year) + CHINESE_LANGUAGES.year + month + CHINESE_LANGUAGES.month + day + CHINESE_LANGUAGES.day;
};
