import { combineReducers } from 'redux';

import getLatestMyProductReducer from './getLatestMyProduct';
import getMyProductReducer from './getMyProduct';
import getUserProductDetails from './getUserProductDetails';

const productWarrantyReducer = combineReducers({
  getMyProductReducer,
  getUserProductDetails,
  getLatestMyProductReducer
});

export default productWarrantyReducer;
