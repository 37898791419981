import { any, bool, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import Select from 'react-select';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

const DateOfBirth = (props) => {
  const { setFieldValue, dobLabel, isLogin, accountUser, guestData, isRegister, defaultValue, disabled } = props;
  const [dayOptions, setDayOptions] = useState(null);
  const [monthOptions, setMonthOptions] = useState(null);
  const [yearOptions, setYearOptions] = useState(null);
  const [fullDoB, setFullDoB] = useState(false);
  const yearRef = useRef(null);
  const monthRef = useRef(null);
  const dayRef = useRef(null);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  let day = 1;
  let month = 1;
  let year = 1900;

  const [selectedDay, setSelectedDay] = useState(
    isLogin
      ? new Date(accountUser?.DoB).getDate()
        ? { label: new Date(accountUser?.DoB).getDate(), value: new Date(accountUser?.DoB).getDate() }
        : null
      : isRegister
        ? null
        : guestData
          ? new Date(guestData?.dob).getDate()
            ? { label: new Date(guestData?.dob).getDate(), value: new Date(guestData?.dob).getDate() }
            : null
          : null
  );

  const [selectedMonth, setSelectedMonth] = useState(
    isLogin
      ? new Date(accountUser?.DoB).getMonth() + 1
        ? { label: new Date(accountUser?.DoB).getMonth() + 1, value: new Date(accountUser?.DoB).getMonth() + 1 }
        : null
      : isRegister
        ? null
        : guestData
          ? new Date(guestData?.dob).getMonth() + 1
            ? { label: new Date(guestData?.dob).getMonth() + 1, value: new Date(guestData?.dob).getMonth() + 1 }
            : null
          : null
  );

  const [selectedYear, setSelectedYear] = useState(
    isLogin
      ? new Date(accountUser?.DoB).getFullYear()
        ? { label: new Date(accountUser?.DoB).getFullYear(), value: new Date(accountUser?.DoB).getFullYear() }
        : null
      : isRegister
        ? null
        : guestData
          ? new Date(guestData?.dob).getFullYear()
            ? { label: new Date(guestData?.dob).getFullYear(), value: new Date(guestData?.dob).getFullYear() }
            : null
          : null
  );

  // INFO: set date of birth data when login successful without reload page
  useEffect(() => {
    if (isLogin) {
      setSelectedDay(
        new Date(accountUser?.DoB).getDate()
          ? {
            label: new Date(accountUser?.DoB).getDate(),
            value: new Date(accountUser?.DoB).getDate()
          }
          : null
      );

      setSelectedMonth(
        new Date(accountUser?.DoB).getMonth() + 1
          ? { label: new Date(accountUser?.DoB).getMonth() + 1, value: new Date(accountUser?.DoB).getMonth() + 1 }
          : null
      );

      setSelectedYear(
        new Date(accountUser?.DoB).getFullYear()
          ? { label: new Date(accountUser?.DoB).getFullYear(), value: new Date(accountUser?.DoB).getFullYear() }
          : null
      );
    }
  }, [isLogin]);

  useEffect(() => {
    if (defaultValue) {
      setSelectedDay(
        new Date(defaultValue).getDate()
          ? {
            label: new Date(defaultValue).getDate(),
            value: new Date(defaultValue).getDate()
          }
          : null
      );

      setSelectedMonth(
        new Date(defaultValue).getMonth() + 1 ? { label: new Date(defaultValue).getMonth() + 1, value: new Date(defaultValue).getMonth() + 1 } : null
      );

      setSelectedYear(
        new Date(defaultValue).getFullYear() ? { label: new Date(defaultValue).getFullYear(), value: new Date(defaultValue).getFullYear() } : null
      );
    }
  }, []);

  useEffect(() => {
    let optionDay = [];
    let optionMonth = [];
    let optionYear = [];
    let date = new Date();
    for (day; day <= 31; day++) {
      let obj = {};

      obj.label = day;

      obj.value = day;

      optionDay.push(obj);
    }
    for (month; month <= 12; month++) {
      let obj = {};

      obj.label = month;

      obj.value = month;

      optionMonth.push(obj);
    }
    for (year; year <= date.getFullYear(); year++) {
      let obj = {};

      obj.label = year;

      obj.value = year;
      if (date.getFullYear() - year >= 18) optionYear.push(obj);
    }

    setDayOptions(optionDay);

    setMonthOptions(optionMonth);

    setYearOptions(optionYear.reverse());
  }, []);

  useEffect(() => {
    selectedDay && selectedMonth && selectedYear ? setFullDoB(true) : setFullDoB(false);
  }, [selectedDay, selectedMonth, selectedYear]);

  useDidUpdateEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      fullDoB ? setFieldValue('dob', Global.formatToISOStringHour(selectedDay, selectedMonth, selectedYear)) : setFieldValue('dob', '');
    } else setFieldValue('dob', '');
  }, [fullDoB, selectedDay, selectedMonth, selectedYear]);

  const onCountLeapYearHandler = (year) => {
    year = parseInt(year);
    if (year % 400 == 0) return true;
    if (year % 4 == 0 && year % 100 != 0) return true;

    return false;
  };

  const onChangeYear = (year) => {
    let optionDay = [];
    if (onCountLeapYearHandler(year.value)) {
      if (monthRef.current.state.value?.value === 2) {
        for (day; day <= 29; day++) {
          let obj = {};

          obj.label = day;

          obj.value = day;

          optionDay.push(obj);
        }

        setDayOptions(optionDay);
      }
    } else {
      if (monthRef.current.state.value?.value === 2) {
        if (dayRef.current.state.value?.value === 29) {
          setSelectedDay(null);
        }
        for (day; day <= 28; day++) {
          let obj = {};

          obj.label = day;

          obj.value = day;

          optionDay.push(obj);
        }

        setDayOptions(optionDay);
      }
    }

    setSelectedYear(year);
  };

  const onChangeMonth = (month) => {
    let optionDay = [];
    if (month.value === 2) {
      if (JSON.stringify(yearRef.current.state.value?.value)?.length) {
        if (onCountLeapYearHandler(yearRef.current.state.value?.value)) {
          selectedDay?.value > 29 && setSelectedDay(null);
          for (day; day <= 29; day++) {
            let obj = {};

            obj.label = day;

            obj.value = day;

            optionDay.push(obj);
          }
        } else {
          selectedDay?.value > 28 && setSelectedDay(null);
          for (day; day <= 28; day++) {
            let obj = {};

            obj.label = day;

            obj.value = day;

            optionDay.push(obj);
          }
        }
      } else {
        selectedDay?.value > 29 && setSelectedDay(null);
        for (day; day <= 29; day++) {
          let obj = {};

          obj.label = day;

          obj.value = day;

          optionDay.push(obj);
        }
      }
    } else if (month.value === 4 || month.value === 6 || month.value === 9 || month.value === 11) {
      selectedDay?.value > 30 && setSelectedDay(null);
      for (day; day <= 30; day++) {
        let obj = {};

        obj.label = day;

        obj.value = day;

        optionDay.push(obj);
      }
    } else {
      for (day; day <= 31; day++) {
        let obj = {};

        obj.label = day;

        obj.value = day;

        optionDay.push(obj);
      }
    }

    setDayOptions(optionDay);

    setSelectedMonth(month);
  };

  const onChangeDay = (day) => {
    setSelectedDay(day);
  };

  return dayOptions && monthOptions && yearOptions ? (
    <div className='form-group__birth'>
      <Text field={dobLabel} tag='label' />
      <Select
        id='day'
        name='dob'
        onChange={(day) => onChangeDay(day)}
        ref={dayRef}
        value={selectedDay}
        options={dayOptions}
        className='customization-dropdown'
        classNamePrefix='customization-dropdown'
        placeholder={messageObj['LBL-Day']}
        isSearchable={true}
        isDisabled={disabled}
      />
      <Select
        id='month'
        name='dob'
        onChange={(month) => onChangeMonth(month)}
        ref={monthRef}
        value={selectedMonth}
        options={monthOptions}
        className='customization-dropdown'
        classNamePrefix='customization-dropdown'
        placeholder={messageObj['LBL-Month']}
        isSearchable={true}
        isDisabled={disabled}
      />
      <Select
        id='year'
        name='dob'
        onChange={(option) => onChangeYear(option)}
        ref={yearRef}
        value={selectedYear}
        options={yearOptions}
        className='customization-dropdown'
        classNamePrefix='customization-dropdown'
        placeholder={messageObj['LBL-Year']}
        isSearchable={true}
        isDisabled={disabled}
      />
    </div>
  ) : (
    <></>
  );
};

DateOfBirth.propTypes = {
  setFieldValue: any,
  dobLabel: object,
  isLogin: bool,
  accountUser: object,
  guestData: object,
  isRegister: bool,
  defaultValue: any,
  disabled: bool
};

const mapStateToProps = (state) => {
  return {
    isLogin: state.singleSignOnReducer.userInfoReducer.isLogin || null,
    accountUser: state.singleSignOnReducer.userInfoReducer.accountUser || null,
    guestData: state.checkoutProcesstReducer.dataCheckoutReducer.infoUser || null
  };
};

export default connect(mapStateToProps)(DateOfBirth);
