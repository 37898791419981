import PropTypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react';

import { PromotionType } from '@utils/constant';

import CartAdditionalOptionItem from '../CartAdditionalOptionItem';
import CartRentalItem from '../CartRentalItem';
import CartTradeInItem from '../CartTradeInItem';
import CartWarrantyItem from '../CartWarrantyItem';

const PromotionCart = ({ promotionCartItem, freeGiftPomotion, dataSources }) => {
  return (
    <Fragment>
      {promotionCartItem.Type === PromotionType.Warranty && !promotionCartItem.RelationshipId ? (
        <CartWarrantyItem dataSources={dataSources} freeGiftPomotion={freeGiftPomotion} warrantyInfo={promotionCartItem} />
      ) : (
        ''
      )}
      {promotionCartItem.Type === PromotionType.TradeIn || promotionCartItem.Type === PromotionType.TradeInLoyalty ? (
        <CartTradeInItem dataSources={dataSources} freeGiftPomotion={freeGiftPomotion} tradeInInfo={promotionCartItem} />
      ) : (
        <></>
      )}
      {promotionCartItem.Type === PromotionType.AdditionalOptions || promotionCartItem.RelationshipId ? (
        <CartAdditionalOptionItem dataSources={dataSources} freeGiftPomotion={freeGiftPomotion} additionalOptionInfo={promotionCartItem} />
      ) : (
        <></>
      )}
      {promotionCartItem.Type === PromotionType.Rental ? <CartRentalItem dataSources={dataSources} rentalInfo={promotionCartItem} /> : <></>}
    </Fragment>
  );
};

PromotionCart.propTypes = {
  promotionCartItem: PropTypes.object,
  dataSources: PropTypes.object,
  freeGiftPomotion: PropTypes.any
};

export default PromotionCart;
