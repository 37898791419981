import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/compareProduct';
import * as compareProductTypes from '@redux/actions/compareProduct/compareProductTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from '../config';

export const getListProductsEpic = (action$) =>
  action$.pipe(
    ofType(compareProductTypes.GET_LIST_PRODUCT_START),
    switchMap((action) => {
      const params = {
        categoryId: action.payload.categoryId
      };

      return getAsObservable(config.ACTION_GET_LIST_PRODUCTS, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getListProductSuccess(deepCopy(res?.data));
          } else {
            return actions.getListProductFailed('Failed');
          }
        })
      );
    })
  );
