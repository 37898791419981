import {
  CREATE_BOOKING_EXPERIENCE_CONTACT_FAILED,
  CREATE_BOOKING_EXPERIENCE_CONTACT_START,
  CREATE_BOOKING_EXPERIENCE_CONTACT_SUCCESS
} from './types';

export const createBookingExperienceContactStart = (payload) => ({
  type: CREATE_BOOKING_EXPERIENCE_CONTACT_START,
  payload
});

export const createBookingExperienceContactSuccess = (payload) => ({
  type: CREATE_BOOKING_EXPERIENCE_CONTACT_SUCCESS,
  payload
});

export const createBookingExperienceContactFailed = (payload) => ({
  type: CREATE_BOOKING_EXPERIENCE_CONTACT_FAILED,
  payload
});
