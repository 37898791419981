export default class StoreFilterCityListModel {
  constructor() {
    this['Id'] = '';

    this['label'] = '';

    this['value'] = '';

    this['CityCode'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ? dataSource['Id'] : this['Id'];

      this['value'] = dataSource['CityCode'] ? dataSource['CityCode'] : this['value'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CityName')) {
      this['label'] = dataSource['CityName'] ? dataSource['CityName'] : this['label'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CityCode')) {
      this['CityCode'] = dataSource['CityCode'] ? dataSource['CityCode'] : this['CityCode'];
    }

    return this;
  }
}
