import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';

import { getSubmitAction } from '@redux/actions/unsubscribe';

// NOTE: onButtonClick is not a submit handler;
// it signals to the parent form which button invoked the submit action
// (which is important for multi-step forms where multiple submits can occur i.e. back/forward)

const CustomButton = ({ field, onButtonClick, submitButtonStatus }) => {
  const dispatch = useDispatch();

  return (
    <div className='signup-form__form--wrap-btn-submit'>
      <button
        type='submit'
        value={field.model.title}
        name={field.buttonField.name}
        id={field.buttonField.id}
        onClick={() => {
          onButtonClick(field.buttonField.name);

          dispatch(getSubmitAction(true));
        }}
        className='signup-form__form__btn btn btn-outline-CTA2'
        disabled={submitButtonStatus ? true : false}
      >
        {field.model.title}
      </button>
    </div>
  );
};

CustomButton.propTypes = {
  field: PropTypes.any,
  onButtonClick: PropTypes.any,
  submitButtonStatus: PropTypes.bool
};

const mapStateToProps = (state) => ({
  submitButtonStatus: state.unsubscribeReducer.submitButtonStatus
});

export default connect(mapStateToProps)(CustomButton);
