export default class AddressElementItemModel {
  constructor() {
    this.Condition = null;

    this['Display Name'] = '';

    this['Field Name'] = '';

    this.Type = '';

    this.IsRequired = false;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Condition') && dataSource.Condition?.fields) {
      this.Condition = dataSource.Condition.fields;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Display Name')) {
      this['Display Name'] = dataSource['Display Name'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Field Name')) {
      this['Field Name'] = dataSource['Field Name'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Type')) {
      this.Type = dataSource.Type;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Required')) {
      this.IsRequired = dataSource['Is Required']?.value;
    }

    return this;
  }
}
