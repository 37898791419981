import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ProductSearchResultFieldsModel {
  constructor() {
    this['Learn More Text'] = new SitecoreTextModel();

    this['Place Holder Text'] = new SitecoreTextModel();

    this['No Result Text'] = new SitecoreTextModel();

    this['Result Message'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Text')) {
      this['Learn More Text'].getData(dataSource['Learn More Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Place Holder Text')) {
      this['Place Holder Text'].getData(dataSource['Place Holder Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'No Result Text')) {
      this['No Result Text'].getData(dataSource['No Result Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Result Message')) {
      this['Result Message'].getData(dataSource['Result Message']);
    }
    
    return this;
  }
}
