import './SendOrderToCustomerModal.scss';

import { any, bool, func, object } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import PhoneNumberSelect from '@components/Share/PhoneNumberSelect';
import { Yup } from '@components/SingleSignOn/RegisterLogin/Register/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const ENTER_SMS_INPUT_NAME = 'enterSMS';
const ENTER_EMAIL_INPUT_NAME = 'enterEmail';

const SendOrderToCustomerModal = (props) => {
  const { isShow, onCloseHandler, onLayoutData, onHandleSave, onSetInputEnter, onInputEnter, typeEvent, fieldPhoneCode } = props;
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const [inputField, setInputField] = useState('');
  const [inputError, setInputError] = useState('');

  const areaCode = useMemo(() => {
    let areaCodesList = [];
    const isUseAreaCode = settingGlobal?.UseAreaCode || false;
    const areaCodes = settingGlobal?.AreaCodes || [];
    if (isUseAreaCode && areaCodes.length) {
      for (const item of areaCodes) {
        if (Object.keys(item).every((key) => item[key]))
          areaCodesList.push({
            value: item.value,
            label: item.label
          });
      }
    }

    return areaCodesList;
  }, [settingGlobal]);

  useEffect(() => {
    if (areaCode.length && !fieldPhoneCode.current) fieldPhoneCode.current = areaCode[0]?.value || '';
  }, [areaCode]);

  const handleChange = (evt) => {
    evt.preventDefault();
    const value = evt.target.value;
    const name = evt.target.name;

    // INFO: get to know which field user is inputting
    setInputField(name);

    if (name !== ENTER_SMS_INPUT_NAME) onSetInputEnter(value);
    if (name === ENTER_SMS_INPUT_NAME && (!value || new RegExp(settingGlobal?.PhoneNumberFormat).test(value))) {
      onSetInputEnter(value);
    }
  };

  const handleSave = useCallback(async () => {
    const schema = Yup.object({
      [ENTER_EMAIL_INPUT_NAME]: Yup.string().trim().email(messageObj?.['Msg_001.3']),
      [ENTER_SMS_INPUT_NAME]: Yup.string()
        .trim()
        .validatePhone({
          min: settingGlobal.PhoneNumberMinimumMust || settingGlobal.PhoneNumberMust,
          max: settingGlobal.PhoneNumberMust,
          prefixCode: fieldPhoneCode.current || '',
          errorMsg: messageObj?.['Msg_001.7']
        })
    });
    try {
      const isInputValid = await schema.validate({ [inputField]: onInputEnter });
      if (onHandleSave && isInputValid) onHandleSave();
    } catch (error) {
      setInputError(error?.message || '');
    }
  }, [onInputEnter, onHandleSave, inputField]);

  return (
    <ModalComponent isShow={isShow} onCloseHandler={onCloseHandler} titlePopup={''}>
      <form className='send-order-customer-modal'>
        <div className='form-group send-order-customer-modal__input'>
          {typeEvent?.category === onLayoutData?.Email.value ? (
            <div className={Global.renderDynamicClass(inputField === ENTER_EMAIL_INPUT_NAME && inputError, 'form-group', 'input-error-validate')}>
              <input
                id={ENTER_EMAIL_INPUT_NAME}
                className={'form-control'}
                name={ENTER_EMAIL_INPUT_NAME}
                type='text'
                onChange={handleChange}
                value={onInputEnter}
                placeholder={onLayoutData?.['Enter email']?.value}
              />
              <label htmlFor={ENTER_EMAIL_INPUT_NAME} className='form-group__label'>
                <Text field={onLayoutData?.['Enter email']} />
              </label>
              {typeEvent?.error && (
                <span className='error-validate'>
                  <Text field={onLayoutData?.['Invalid email']} />
                </span>
              )}
              {inputField === ENTER_EMAIL_INPUT_NAME ? <span className='error-validate'>{inputError}</span> : <></>}
            </div>
          ) : (
            <div
              className={
                areaCode.length
                  ? `${Global.renderDynamicClass(
                    inputField === ENTER_SMS_INPUT_NAME && inputError,
                    'form-group',
                    'input-error-validate'
                  )} form-group--group-select-input`
                  : Global.renderDynamicClass(inputField === ENTER_SMS_INPUT_NAME && inputError, 'form-group', 'input-error-validate')
              }
            >
              {areaCode.length ? (
                <PhoneNumberSelect setPhoneNumberCodeEvt={(code) => (fieldPhoneCode.current = code)} optionValues={areaCode} />
              ) : (
                <></>
              )}
              <div className='form-group--input'>
                <input
                  id={ENTER_SMS_INPUT_NAME}
                  className={'form-control'}
                  name={ENTER_SMS_INPUT_NAME}
                  type='text'
                  onChange={handleChange}
                  value={onInputEnter}
                  placeholder={onLayoutData?.['Enter sms']?.value}
                />
                <label htmlFor={ENTER_SMS_INPUT_NAME} className='form-group__label'>
                  <Text field={onLayoutData?.['Enter sms']} />
                </label>
                {typeEvent?.error && (
                  <span className='error-validate'>
                    <Text field={onLayoutData?.['Invalid sms']} />
                  </span>
                )}
                {inputField === ENTER_SMS_INPUT_NAME ? <span className='error-validate'>{inputError}</span> : <></>}
              </div>
            </div>
          )}
        </div>
        <div className='send-order-customer-modal__btn'>
          <button type='button' className='btn btn-primary btn-save' onClick={handleSave}>
            <Text field={onLayoutData?.Save} />
          </button>
        </div>
        <div className='send-order-customer-modal__btn'>
          <button type='button' className='btn-link btn-cancel' onClick={onCloseHandler}>
            <Text field={onLayoutData?.Cancel} />
          </button>
        </div>
      </form>
    </ModalComponent>
  );
};

SendOrderToCustomerModal.propTypes = {
  isShow: bool,
  onCloseHandler: func,
  onLayoutData: object,
  onHandleSave: any,
  onSetInputEnter: any,
  onInputEnter: any,
  typeEvent: object,
  fieldPhoneCode: any
};

export default SendOrderToCustomerModal;
