export const CHECK_LOGIN_STATUS = 'CHECK_LOGIN_STATUS';

export const LOGIN = 'LOGIN';

export const LOGIN_SOCIAL = 'LOGIN_SOCIAL';

export const LOGOUT = 'LOGOUT';

export const LOGIN_GOOGLE = 'LOGIN_GOOGLE';

export const LOGIN_FACEBOOK = 'LOGIN_FACEBOOK';

export const LOGIN_INSTAGRAM_SUCCESS = 'LOGIN_INSTAGRAM_SUCCESS';

export const LOGIN_INSTAGRAM_FAILED = 'LOGIN_INSTAGRAM_FAILED';

export const LOGIN_YAHOO_SUCCESS = 'LOGIN_YAHOO_SUCCESS';

export const LOGIN_YAHOO_FAILED = 'LOGIN_YAHOO_FAILED';

export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';

export const LOGOUT_FAILED = 'LOGOUT_FAILED';

export const LOGIN_FAILED = 'LOGIN_FAILED';

export const REGISTER_FAILED = 'REGISTER_FAILED';

export const REGISTER = 'REGISTER';

export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD';

export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';

export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';

export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';

export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

export const RESEND_MAIL_ACTIVE = 'RESEND_MAIL_ACTIVE';

export const RESEND_MAIL_ACTIVE_SUCCESS = 'RESEND_MAIL_ACTIVE_SUCCESS';

export const RESEND_MAIL_ACTIVE_FAILED = 'RESEND_MAIL_ACTIVE_FAILED';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';

export const GET_USER_PROFILE_SUCCESS = 'GET_USER_PROFILE_SUCCESS';

export const GET_USER_PROFILE_FAILED = 'GET_USER_PROFILE_FAILED';

export const TOOGLE_LINK_GOOGLE = 'TOOGLE_LINK_GOOGLE';

export const TOOGLE_LINK_FACEBOOK = 'TOOGLE_LINK_FACEBOOK';

//CP
export const CP_LOGIN = 'CP_LOGIN';

export const CP_LOGIN_SUCCESS = 'CP_LOGIN_SUCCESS';

export const CP_LOGIN_FAILED = 'CP_LOGIN_FAILED';

export const CHECK_CP_LOGIN_STATUS = 'CHECK_CP_LOGIN_STATUS';

export const CP_LOGOUT = 'CP_LOGOUT';

export const CP_LOGOUT_SUCCESS = 'CP_LOGOUT_SUCCESS';

export const CP_LOGOUT_FAILED = 'CP_LOGOUT_FAILED';

export const CP_VERIFY_LOGIN = 'CP_VERIFY_LOGIN';

export const CP_VERIFY_LOGIN_SUCCESS = 'CP_VERIFY_LOGIN_SUCCESS';

export const CP_VERIFY_LOGIN_FAILED = 'CP_VERIFY_LOGIN_FAILED';

export const RESEND_LOGIN_OTP = 'RESEND_LOGIN_OTP';

export const RESEND_LOGIN_OTP_SUCCESS = 'RESEND_LOGIN_OTP_SUCCESS';

export const RESEND_LOGIN_OTP_FAILED = 'RESEND_LOGIN_OTP_FAILED';

export const RESET_REGISTER_ERROR_MSG = 'RESET_REGISTER_ERROR_MSG';