import { object, string } from 'prop-types';
import React, { useMemo } from 'react';

import {
  SCHEDULE_APPOINTMENT_PARAM_NAME,
  SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE
} from '@components/BookingExperience/ScheduleAppointmentComponent/FormikStep/configs';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const ScheduleVisited = ({ layoutData, numberOfGuestValue, timeSlotBooked }) => {
  const changeScheduleBtnLink = useMemo(() => {
    const appointmentId = layoutData?.appointment?.Id;

    return layoutData?.['Change Schedule Book Experience Link']?.value?.href
      ? {
        value: {
          ...layoutData['Change Schedule Book Experience Link'].value,
          href: `${layoutData?.['Change Schedule Book Experience Link']?.value?.href}?${SCHEDULE_APPOINTMENT_PARAM_NAME}=${SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE}&appointment_id=${appointmentId}`
        }
      }
      : '';
  }, [layoutData]);

  return (
    <div className='appointment-detail__body__item appointment-detail__body__item--scheduled-visit'>
      <Text field={layoutData?.['Scheduled Visit Label']} tag='h3' className='appointment-detail__body__item__title' />
      <div className='appointment-detail__body__item__img'>
        <Image field={layoutData?.['Scheduled Visit Image']} />
      </div>
      <div className='appointment-detail__body__item__content'>
        <div className='appointment-detail__body__item__content__time'>
          <span>{Global.customDate(layoutData?.appointment?.Date)}</span>
          <span>{timeSlotBooked?.Name}</span>
          <span>{numberOfGuestValue}</span>
        </div>
        <h3 className='appointment-detail__body__item__content__name'>{layoutData?.appointment?.Store?.Name}</h3>
        <p className='appointment-detail__body__item__content__description'>{layoutData?.appointment?.Store?.Address?.Address}</p>
        <p className='appointment-detail__body__item__content__description'>{layoutData?.appointment?.Store?.PhoneNumber}</p>
      </div>
      {layoutData?.isUpComing ? (
        changeScheduleBtnLink ? (
          <Link className='appointment-detail__body__item__button btn btn-outline-primary' field={changeScheduleBtnLink}>
            <Text field={layoutData?.['Change Schedule Book Experience Text']} />
          </Link>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

ScheduleVisited.propTypes = {
  layoutData: object,
  numberOfGuestValue: string,
  timeSlotBooked: object
};

export default ScheduleVisited;
