import PropTypes from 'prop-types';
import React from 'react';

import { behaviorSubjectCheckboxList$ } from '../../../checkBoxBehavior';
import FormFieldError from '../../FormFieldError';

const CustomCheckboxList = (props) => {
  const { field, value, onChange, errors, tracker } = props;

  const handleOnChange = (field, originalFieldValue, changedElement, checked, callback) => {
    // we can have multiple selected values. So we need to push ALL the selected
    // values back up to the root form. This is done using an array, which the form
    // serializer knows how to expand into multiple values on post

    let value = originalFieldValue;
    let errorMessages = [];

    if (checked) {
      value.push(changedElement);

      if (value.length) {
        behaviorSubjectCheckboxList$.next({ status: true, field: field.valueField.name });

        errorMessages = [];
      }
    } else {
      value = value.filter((v) => v !== changedElement);

      if (!value.length) {
        behaviorSubjectCheckboxList$.next({ status: false, field: field.valueField.name });

        if (field.model.required) {
          errorMessages.push(`${field.model.title} is required`);
        }
      }
    }

    // (fieldName, fieldValue, isValid, validationErrors)
    callback(field.valueField.name, value, true, errorMessages);
  };

  return (
    <>
      {field.model.items.map((item, index) => (
        <div key={item.itemId} className='osim-checkbox'>
          <input
            type='checkbox'
            className='osim-checkbox-input'
            id={field.valueField.id + index}
            name={field.valueField.name}
            value={item.value}
            checked={value.some((v) => v === item.value)}
            onChange={(e) => handleOnChange(field, value, e.target.value, e.target.checked, onChange)}
            onFocus={() => tracker.onFocusField(field, value)}
            onBlur={() => tracker.onBlurField(field, value, errors)}
          />
          <label htmlFor={field.valueField.id + index} className='osim-checkbox-label'>
            {item.text}
          </label>
        </div>
      ))}
      <FormFieldError {...props} />
    </>
  );
};

CustomCheckboxList.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tracker: PropTypes.any,
  errors: PropTypes.any,
  children: PropTypes.any
};

export default CustomCheckboxList;
