import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import OmnichatTracking from '../Share/OmnichatTracking';
import CheckoutCreateAccount from './CheckoutCreateAccount';
import CheckoutThankyouFieldsModel from './model/CheckoutThankyouFieldsModel';
import TKPaymentDetail from './TKPaymentDetail';
import TKPaymentStatus from './TKPaymentStatus';

const CheckoutThankyou = (props) => {
  const { fields } = props;

  const [dataFields, setDataFields] = useState(null);
  const isRegisterSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer.accountUser?.IsRegisterNew);
  const isLoginSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const myAccountPageLink = useSelector((state) => state.settingGlobalReducer.settingGlobal?.MyAccountPageLink);
  const paymentDetail = useSelector((state) => state.checkoutProcesstReducer.paymentDetailReducer.paymentDetail);
  const isTWSite = SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OTW';
  const currentCart = useSelector((state) => state.myOrderReducer.orderByIdReducer.currentCart);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const isTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('HKOmnichat');
  const isTWTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('TWOmnichat');

  useEffect(() => {
    const checkoutThankyouFieldsModel = new CheckoutThankyouFieldsModel();

    setDataFields(checkoutThankyouFieldsModel.getData(fields || {}));
  }, []);

  useDidUpdateEffect(() => {
    if (isRegisterSuccess) {
      window.scrollTo(0, 0);

      window.location.href = myAccountPageLink || '/';
    }
  }, [isRegisterSuccess]);

  useDidUpdateEffect(() => {
    if (isTWSite) {
      //Tamedia
      if (window.taq) {
        window.taq('track', '8wLls6Qyqd0d', 'Purchase');
      }

      //Bridge well
      window._bwq = window._bwq || [];

      window._bwq.push([
        'trackSingle',
        '687-138G445F0MCC238',
        'Purchase',
        {
          transaction_id: paymentDetail?.OrderId,
          tags: ['buy_687']
        }
      ]);
    }
  }, [dataFields]);

  useEffect(() => {
    // INFO: Omnichat tracking
    const isAllowOmnichatTracking = currentSiteName === HK_SITE_NAME && isTurnOnOmnichat || currentSiteName === TW_SITE_NAME && isTWTurnOnOmnichat;
    if (isAllowOmnichatTracking && currentCart && paymentDetail) {
      let products = [];

      if (currentCart.Lines.length > 0) {
        currentCart.Lines.forEach((line) => {
          products.push({
            id: line?.ProductId || '',
            name: line?.DisplayName || '',
            brand: 'Osim',
            category: line?.CategoryName || '',
            variant: line.VariantId || '',
            quantity: parseInt(line?.Quantity) || '',
            price: line?.LineFinalTotalAmount.toString()
          });
        });
      }

      new OmnichatTracking().tracking('event', 'purchase', {
        transaction_id: paymentDetail?.OrderNumber || '',
        amount: paymentDetail.TotalAmount.toString() || '',
        currency: paymentDetail.Currency || '',
        items: products
      });
    }
  }, [paymentDetail, currentCart]);

  return dataFields ? (
    <div className='thankyou-payment'>
      <TKPaymentStatus dataFields={dataFields} />
      <TKPaymentDetail dataFields={dataFields} />
      {isLoginSuccess ? (
        <div className='thankyou-payment__button text-center'>
          <Link field={dataFields['Continue Shopping Link']} className='btn btn-primary'>
            <Text field={dataFields['Continue Shopping Label']} />
          </Link>
        </div>
      ) : (
        ''
      )}
      <CheckoutCreateAccount datafields={dataFields} />
    </div>
  ) : (
    <></>
  );
};

CheckoutThankyou.propTypes = {
  fields: Proptypes.any
};

export default CheckoutThankyou;
