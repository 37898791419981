export default class CartLineProperties {
  constructor() {
    this.Color = '';

    this.Size = '';

    this.Style = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Color:')) {
      this.Color = dataSource['Color:'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Size:')) {
      this.Size = dataSource['Size:'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Style:')) {
      this.Style = dataSource['Style:'];
    }

    return this;
  }
}
