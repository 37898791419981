import { ofType } from 'redux-observable';
import { concatMap,map } from 'rxjs/operators';

import AddressDropdownOptionModel from '@components/SlicingPage/MyAccount/MyAddress/models/AddressDropdownOptionModel';
import * as actions from '@redux/actions/myAddress';
import * as myAddressTypes from '@redux/actions/myAddress/myAddressTypes';
import {postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getAddressDataSourcesEpic = (action$) =>
  action$.pipe(
    ofType(myAddressTypes.GET_ADDRESS_DATASOURCES),
    concatMap((action) => {
      return postAsObservable(config.ACTION_GET_ADDRESS_DATASOURCES, action.payload.model).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.StatusCode === 200) {
            const addressDatasourcesCopy = deepCopy(res.data?.AddressDatasources);
            let response = [];

            addressDatasourcesCopy.map((sourcesItem => {
              response.push({...new AddressDropdownOptionModel().getData(sourcesItem || {}), Condition: action.payload.condition});
            }));

            return actions.getAddressDatasourcesSuccess({data: response, fieldName: action.payload.fieldName, isMacau: action?.payload?.isMacau || false});
          } else {
            return actions.getAddressDatasourcesFailed(res.data?.Error?.[0] || 'Failed');
          }
        })
      );
    })
  );
