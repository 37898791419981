import produce from 'immer';

import * as productTypes from '@redux/actions/product/productTypes';

import DefaultLocatorApiModel from './models/DefaultLocatorApiModel';

const initState = {
  defaultLocation: {},
  currentLocation: {},
  isLoading: false,
  error: ''
};

const defaultLocatorReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.GET_DEFAULT_LOCATOR:
      draft.isLoading = true;
      break;

    case productTypes.GET_DEFAULT_LOCATOR_SUCCESS:
      draft.isLoading = false;

      draft.currentLocation = action.payload.currentLocation;

      draft.defaultLocation = new DefaultLocatorApiModel().getData(action.payload.data?.Data || {});
      break;

    case productTypes.GET_DEFAULT_LOCATOR_FAILED:
      draft.isLoading = false;

      draft.error = action.error;
      break;
    }

    return draft;
  });

export default defaultLocatorReducer;
