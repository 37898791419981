import produce from 'immer';

import {
  GET_PRODUCT_COUNTDOWN_TIMER,
  GET_PRODUCT_COUNTDOWN_TIMER_FAILED,
  GET_PRODUCT_COUNTDOWN_TIMER_SUCCESS
} from '@redux/actions/productCountdownTimer/productCountdownTimerTypes';

const intitState = {
  error: '',
  productCountdownTimer: null,
  isLoading: false
};

const productCountdownTimerReducer = (state = intitState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_PRODUCT_COUNTDOWN_TIMER:
      draft.isLoading = true;
      break;

    case GET_PRODUCT_COUNTDOWN_TIMER_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const { data } = action.payload;

      draft.isLoading = false;

      draft.productCountdownTimer = {
        IsSuccess: data?.IsSuccess,
        Messages: data?.Messages[0],
        ProductCountdownTimer: {
          IsEnable: data?.ProductCountdownTimer?.IsEnable,
          StartTime: data?.ProductCountdownTimer?.StartTime,
          StartMessage: data?.ProductCountdownTimer?.StartMessage,
          AlternativeStartMessage: data?.ProductCountdownTimer?.AlternativeStartMessage,
          EndTime: data?.ProductCountdownTimer?.EndTime,
          EndMessage: data?.ProductCountdownTimer?.EndMessage,
          RemainingTime: data?.ProductCountdownTimer?.RemainingTime,
          ValidMessage: data?.ProductCountdownTimer?.ValidMessage,
          WasEndMessage: data?.ProductCountdownTimer?.WasEndMessage,
          TitleText: data?.ProductCountdownTimer?.TitleText,
          DaysText: data?.ProductCountdownTimer?.DaysText,
          HoursText: data?.ProductCountdownTimer?.HoursText,
          MinutesText: data?.ProductCountdownTimer?.MinutesText,
          SecondsText: data?.ProductCountdownTimer?.SecondsText,
          DesktopPosition: data?.ProductCountdownTimer?.DesktopPosition,
          MobilePosition: data?.ProductCountdownTimer?.MobilePosition,
          TitleTextColorCode: data?.ProductCountdownTimer?.TitleTextColorCode,
          CountdownTimerTextColorCode: data?.ProductCountdownTimer?.CountdownTimerTextColorCode,
          DateUnitColorCode: data?.ProductCountdownTimer?.DateUnitColorCode,
          TitleTextFontSize: data?.ProductCountdownTimer?.TitleTextFontSize,
          CountdownTimerTextFontSize: data?.ProductCountdownTimer?.CountdownTimerTextFontSize,
          DateUnitFontSize: data?.ProductCountdownTimer?.DateUnitFontSize,
          TitleTextMobileFontSize: data?.ProductCountdownTimer?.TitleTextMobileFontSize,
          CountdownTimerTextMobileFontSize: data?.ProductCountdownTimer?.CountdownTimerTextMobileFontSize,
          DateUnitMobileFontSize: data?.ProductCountdownTimer?.DateUnitMobileFontSize,
          BackgroundImageUrl: data?.ProductCountdownTimer?.BackgroundImageUrl
        }
      };

      break;

    case GET_PRODUCT_COUNTDOWN_TIMER_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default productCountdownTimerReducer;
