import './OrderDetails.scss';

import PropsType from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import CartSellerComponent from '@components/FulfillmentOptions/DeliveryComponent/CartSellerComponent';
import CouponAppliedComponent from '@components/FulfillmentOptions/DeliveryComponent/CouponAppliedComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {ENUM_STATUS} from '@utils/constant';

import { getMyOrdersById } from '../../../../redux/actions/myOrders';
import { deepCopy } from '../../../../utils/utility';
import OrderDetailModel from './model/OrderDetailModel';
import OrderDetailsShimmer from './OrderDetailsShimmer';
import OrderInfoComponent from './OrderInfo';
import PaymentMethod from './PaymentMethodComponent';
import SpecialRequestComponent from './SpecialRequestComponent';
import TotalOrder from './TotalOrder';

const NUM_REPLACE = '{num}';

const OrderDetails = ({ fields }) => {
  const [orderId, setOrderId] = useState('');
  const [layoutData, setLayoutData] = useState(null);
  const cartData = useSelector((state) => state.myOrderReducer.orderByIdReducer.currentCartCustom || null);
  const currentCart = useSelector((state) => state.myOrderReducer.orderByIdReducer.currentCart);
  const dispatch = useDispatch();

  useEffect(() => {
    const model = new OrderDetailModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useEffect(() => {
    const url = new URL(window.location.href);

    setOrderId(url.searchParams.get('OrderId'));

    dispatch(getMyOrdersById(url.searchParams.get('OrderId')));
  }, []);


  // const getClassForStatus = (statusName) => {
  //   return ENUM_STATUS.find(item => item.status === statusName)?.class || '';
  // };

  const calculatePackage = () => {
    let packageNumber = 0;

    if (cartData.Delivery.length > 0) packageNumber += 1;
    if (cartData.SFexpress.length > 0) packageNumber += 1;
    if (cartData.DeliveryBySFExpress.length > 0) packageNumber += 1;

    return packageNumber;
  };

  const convertStatusToClass = (status)=> {
    const lowerCaseStatus = status.toLowerCase();
    const snakeCaseStatus = lowerCaseStatus.replace(/\s+/g, '-');
    return snakeCaseStatus;
  };

  return (
    layoutData && currentCart &&
    cartData ? (
        <div className='orders-detail animated-slow fadeIn delay-100'>
          <div className='orders-detail__general-info'>
            <div className='container orders-detail__general-info__wrap'>
              <h2 className='orders-detail__general-info__id text-center'><Text field={layoutData['Order Label']}/>{currentCart?.TrackingNumber || ''}</h2>
              {layoutData?.['Refund Order Label']?.value.length && currentCart?.RefundOrderEntityId && currentCart?.RefundSitecoreOrderId ? (
                <div className='orders-detail__general-info__refund'>
                  <Text tag='span' field={layoutData['Refund Order Label']} className='orders-detail__general-info__refund__text'/>
                  <a href={`${window.location.pathname}?OrderId=${currentCart?.RefundOrderEntityId}`}>{currentCart?.RefundSitecoreOrderId}</a>
                </div>
              ) : (
                <></>
              )}
              <p>{currentCart.OrderDateStr}</p>
              <p className='orders-detail__general-info__package-quantity'>
                {calculatePackage() > 1 ? layoutData['Description For Multiple Packages'].value.replace(NUM_REPLACE, calculatePackage()) : layoutData['Description For Single Package'].value.replace(NUM_REPLACE, calculatePackage())}
              </p>
              <div className='orders-detail__general-info__status orders-widget__content__status'>
                <span className={`orders-widget__content__status__icon status--${currentCart.OrderStatus}`}></span>
                <span className='orders-widget__content__status__text'>{currentCart.OrderStatusDisplayName || currentCart.OrderStatus}</span>
              </div>
            </div>
          </div>
          <div className='orders-detail__info'>
            {cartData.Delivery?.length > 0 ? (
              <OrderInfoComponent
                page='review'
                type='delivery'
                cartData={cartData}
                layoutData={layoutData}
                fields={fields}
                data={cartData.Delivery}
                packageNumber={1}
              ></OrderInfoComponent>
            ) : null}
            {/* {cartData.DepositListProduct?.length > 0 ? (
              cartData.DepositListProduct.map((depositProduct, index)=> {
                return(
                  <OrderInfoComponent
                    key={index}
                    page='review'
                    type='deposit'
                    cartData={cartData}
                    layoutData={layoutData}
                    data={cartData.DepositListProduct}
                    depositProduct={depositProduct}
                  ></OrderInfoComponent>
                );
              })
            ) : null} */}
            {cartData.PickUpStore.map((item, ind) => {
              return (
                <OrderInfoComponent
                  page='review'
                  type='pick-up-store'
                  layoutData={layoutData}
                  data={item}
                  key={ind}
                  cartData={cartData}
                  packageNumber={cartData.Delivery?.length > 0 ? 2 : 1}
                ></OrderInfoComponent>
              );
            })}
            {cartData.SFexpress?.length > 0 ? (
              <OrderInfoComponent
                page='review'
                type='sf-express'
                cartData={cartData}
                layoutData={layoutData}
                data={cartData.SFexpress}
                packageNumber={(cartData.Delivery?.length > 0 ? 1 : 0) + (cartData.PickUpStore?.length > 0 ? 1 : 0) + 1}
              ></OrderInfoComponent>
            ) : null}
            {cartData.DeliveryBySFExpress?.length > 0 ? (
              <OrderInfoComponent
                page='review'
                type='delivery-by-sf-express'
                cartData={cartData}
                layoutData={layoutData}
                data={cartData.DeliveryBySFExpress}
                packageNumber={(cartData.Delivery?.length > 0 ? 1 : 0) + (cartData.PickUpStore?.length > 0 ? 1 : 0) + (cartData.SFexpress?.length > 0 ? 1 : 0) + 1}
              ></OrderInfoComponent>
            ) : null}
            {
              cartData?.IsPosOrder === false && cartData?.BillingAddress !== null && 
              <OrderInfoComponent page='review' cartData={cartData} layoutData={layoutData} type='billing-details'></OrderInfoComponent>
            }
            {cartData.Staff && cartData.Staff.StoreId && cartData.Staff.StaffId ? <CartSellerComponent layoutData={layoutData} cartData={cartData}></CartSellerComponent> : ''}
            <div className='container'>
              <div className='row'>
                <div className='col-md-6'></div> 
                <div className='col-md-6'>
                  {cartData.PromoCodes && cartData.PromoCodes.length > 0 ? <CouponAppliedComponent layoutData={layoutData} listCoupon={cartData.PromoCodes} /> : null}
                </div>
              </div>
            </div>

          </div>
          <div className='two-column11 bg-Sand orders-detail__summary'>
            <div className='container'>
              <div className='orders-detail__summary__wrap'>
                <div className='row'>
                  <div className='col-md-6 col-xl-5'>
                    <PaymentMethod orderId={orderId} layoutData={layoutData} cartData={cartData} currentCart={currentCart} />
                  </div>
                  <div className='col-md-6 col-xl-7'>
                    <TotalOrder cartData={currentCart} layoutData={layoutData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {
            currentCart.SpecialRequest && currentCart.SpecialRequest !== '' ? <SpecialRequestComponent layoutData={layoutData} cartData={currentCart}/> : null
          }
        </div>
      ) : (
        <OrderDetailsShimmer></OrderDetailsShimmer>
      )
  );
};

OrderDetails.propTypes = {
  fields: PropsType.any,
  page: PropsType.string
};

export default OrderDetails;
