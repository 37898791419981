import { format } from 'date-fns';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { bookAppointmentFailure, bookAppointmentSuccess } from '@redux/actions/campaignRegister/bookAppointment';
import { BOOK_APPOINTMENT_START } from '@redux/actions/campaignRegister/bookAppointment/types';
import { postAsObservable } from '@services/genericService';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE, MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';

import { BOOK_APPOINTMENT_ENDPOINT } from './config';
import { BookAppointmentStatus } from './responseStatus';

const bookAppointmentEpic = (action$) =>
  action$.pipe(
    ofType(BOOK_APPOINTMENT_START),
    switchMap((action) => {
      const params = {
        CampaignId: action.payload.campaignId,
        ContactId: action.payload.contactId,
        StoreId: action.payload.storeId,
        Date: action.payload.date ? format(new Date(action.payload.date), 'yyyy-MM-dd') : '',
        NumberOfGuests: action.payload.numberOfGuests,
        ContactAnswers: action.payload.contactAnswers,
        OsimAccountUsername: action.payload.osimAccountUsername,
        FirstName: action.payload.firstName,
        LastName: action.payload.lastName || DEFAULT_LAST_NAME_API_PAYLOAD_VALUE,
        MobileNumber: isAtSiteName(MY_SITE_NAME) ? `+${action.payload.mobileNumber}` : action.payload.mobileNumber,
        EmailAddress: action.payload.emailAddress,
        ReferralCode: action.payload.referralCode
      };

      return from(
        postAsObservable(BOOK_APPOINTMENT_ENDPOINT, params).pipe(
          map((res) => {
            if (BookAppointmentStatus.SuccessStatusCode.includes(res.data.StatusCode)) {
              return bookAppointmentSuccess(res.data);
            } else {
              return bookAppointmentFailure(res.data);
            }
          })
        )
      );
    }),
    catchError((error) => of(bookAppointmentFailure(error.message || 'Failed')))
  );

export default bookAppointmentEpic;
