import PublishedDateModel from '@models/PublishedDateModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class WellnessArticleItemFieldsModel {
  constructor() {
    this['Author'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Tags'] = [];

    this['TagNames'] = [];

    this['Description'] = new SitecoreTextModel();

    this['Time To Read'] = new SitecoreTextModel();

    this['PublishedDate'] = new PublishedDateModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Author')) {
      this['Author'].getData(dataSource['Author']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Tags')) {
      let newTags = [];

      if (dataSource['Tags']) {
        dataSource['Tags'].map(element => {
          newTags.push(element?.fields?.Title?.value || element || '');
        });
      }

      this['Tags'] = dataSource['Tags'] ? newTags : this['Tags'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TagNames')) {
      this['TagNames'] = dataSource['TagNames'] ? dataSource['TagNames'] : this['TagNames'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Time To Read')) {
      this['Time To Read'].getData(dataSource['Time To Read']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PublishedDate')) {
      this['PublishedDate'].getData(dataSource['PublishedDate']);
    }

    return this;
  }
}
