import './Pagination.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';

import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

const Pagination = (props) => {

  const { isReset, itemPerPage, totalItem, onChangePageHandler } = props;

  const [isReloading, setReloading] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: itemPerPage,
  });

  const totalPage = Math.ceil(totalItem / pagination.limit);

  useDidUpdateEffect(() => {
    onChangePageHandler(pagination.page);
  }, [pagination.page]);

  useDidUpdateEffect(() => {
    resetPagingFnc();
  }, [isReset]);

  const listPageRender = () => {
    if (totalPage < 5) {
      return [...Array(totalPage + 1).keys()].slice(1);
    } else if (pagination.page < 5) {
      return [1, 2, 3, 4, 5];
    } else if (pagination.page > totalPage - 3) {
      return [...Array(5).keys()].reverse()
        .map(v => totalPage - v);
    } else {
      return [pagination.page - 2, pagination.page - 1, pagination.page, pagination.page + 1, pagination.page + 2];
    }
  };

  const resetPagingFnc = () => {
    setReloading(true);

    setPagination(prev => ({
      ...prev,
      page: 1
    }));

    setTimeout(() => {
      setReloading(false);
    }, 100);
  };


  const onHandlePrev = () => {
    setPagination(prev => ({
      ...prev,
      page: pagination.page - 1,
    }));
  };

  const onHandleNext = () => {
    setPagination(prev => ({
      ...prev,
      page: pagination.page + 1,
    }));
  };

  const onHandleFirst = () => {
    setPagination({
      ...pagination,
      page: 1,
    });
  };

  const onHandleLast = () => {
    setPagination({
      ...pagination,
      page: totalPage
    });
  };

  const onHandleNumber = (value) => {
    setPagination({
      ...pagination,
      page: value
    });
  };

  return (
    <Fragment>
      {totalPage > 1
        ? (<Fragment>
          {!isReloading ? (
            listPageRender() && listPageRender().length ?
              (<div className='pagination' key={totalItem}>
                <ul className='pagination__list'>
                  <li className={pagination.page - 1 == 0 ? 'pagination__list__item disable' : 'pagination__list__item'} onClick={() => onHandleFirst()}>
                    <i className='icon-chevrons-left'></i>
                  </li>
                  <li className={pagination.page - 1 == 0 ? 'pagination__list__item disable' : 'pagination__list__item'} onClick={() => onHandlePrev()}>
                    <span className='icon-chevron-left chevron'></span>
                  </li>
                  {listPageRender() && listPageRender().length ? listPageRender().map((item, index) => {
                    return (
                      <li className={pagination.page === item ? 'pagination__list__item active' : 'pagination__list__item'} key={index} onClick={() => onHandleNumber(item)}>
                        <span>{item}</span>
                      </li>
                    );
                  }) : null}
                  <li className={pagination.page == totalPage ? 'pagination__list__item disable' : 'pagination__list__item'} onClick={() => onHandleNext()}>
                    <span className='icon-chevron-right chevron'></span>
                  </li>
                  <li className={pagination.page == totalPage ? 'pagination__list__item disable' : 'pagination__list__item'} onClick={() => onHandleLast()}>
                    <i className='icon-chevrons-right'></i>
                  </li>
                </ul>
              </div>) : null
          )
            : null}
        </Fragment>)
        : null
      }
    </Fragment>
  );
};

Pagination.propTypes = {
  isReset: PropTypes.any,
  itemPerPage: PropTypes.any,
  totalItem: PropTypes.any,
  onChangePageHandler: PropTypes.func
};

export default Pagination;