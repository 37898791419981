import './ReviewStarRating.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const formatStar = (rate) => {
  const starRate = rate.toFixed(1);
  const firstFormatRate = parseInt(starRate.toString().split('.')[0]);
  const lastFormatRate = parseInt(starRate.toString().split('.')[1]);
  let formatRate = firstFormatRate;

  switch (true) {
  case lastFormatRate < 3:
    formatRate = firstFormatRate;
    break;

  case lastFormatRate >= 3 && lastFormatRate < 8:
    formatRate = firstFormatRate + 0.5;
    break;

  case lastFormatRate >= 8:
    formatRate = firstFormatRate + 1;
    break;
  }

  return formatRate;
};

const ReviewStarRating = ({ disableRating = false, onRating = () => {}, defaultRate = 5, count = 5, rateName='' }) => {
  const [starRating, setStarRating] = useState(formatStar(defaultRate));

  useEffect(()=>{
    setStarRating(formatStar(defaultRate));
  },[defaultRate]);

  const onRatingHandler = (rate) => {
    // change star color
    setStarRating(rate);

    // onRating
    onRating({rate, rateName});
  };

  const onFillColorStar = (rate, starIndex) => {
    if (starIndex <= rate) {
      return 'rating-star-icon--filled';
    } else {
      if (rate + 0.5 === starIndex) {
        return 'rating-star-icon--half';
      }

      return '';
    }
  };

  const disableRatingHanler = (disableRating) => {
    if (disableRating) {
      return 'disable-rating';
    } else {
      return '';
    }
  };

  return (
    <ul className={`review-star-rating ${disableRatingHanler(disableRating)}`}>
      {Array.from({ length: count }, (_, i) => i).map((item) => (
        <li className={`rating-star-icon ${onFillColorStar(starRating, item + 1)}`} key={item} onClick={() => onRatingHandler(item + 1)}></li>
      ))}
    </ul>
  );
};

ReviewStarRating.propTypes = {
  disableRating: PropTypes.bool,
  onRating: PropTypes.func,
  defaultRate: PropTypes.number,
  count: PropTypes.number,
  rateName: PropTypes.string
};

export default ReviewStarRating;
