import FieldsModel from './FieldsModel';

export default class BuyNowLinkModel {
  constructor() {
    this['id'] = '';

    this['url'] = '';

    this['fields'] = new FieldsModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this['id'] = dataSource['id'] ? dataSource['id'] : this['id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'url')) {
      this['url'] = dataSource['url'] ? dataSource['url'] : this['url'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }

    return this;
  }
}
