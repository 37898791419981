import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { linkCustomerViewPage } from '@components/ClientPortal/CustomerProfiles/CustomerProfileContainer';
import { deepCopy } from '@utils/utility';

import BoxData from '../BoxData';
import CustomerProductInfo from '../CustomerProductInfo';
import CustomerProductModel from './model/CustomerProductModel';
import CustomerProductShimmer from './CustomerProductShimmer'

const MAX_SHOW_PRODUCT = 4;

const CustomerProduct = ({ fields }) => {
  const customerProducts = useSelector((state) => state.clientTellingPortalCustomer?.customerProducts);
  const [customerProductFields, setCustomerProductFields] = useState(null);

  useEffect(() => {
    const customerProductModel = new CustomerProductModel();

    setCustomerProductFields(customerProductModel.getData(deepCopy(fields) || {}));
  }, []);

  return (
    customerProductFields && (
      <div className='col-md-6 profiles__col'>
        {!customerProducts ? (
          <CustomerProductShimmer layoutData={customerProductFields}/>
        ) : (
        <BoxData 
          component='customer-products' 
          layoutData={customerProductFields} 
          data={customerProducts}
          linkPage={linkCustomerViewPage(customerProductFields)}
        >
          {
            customerProducts ? (
              customerProducts.map((data, index) => index < MAX_SHOW_PRODUCT ? <CustomerProductInfo key={data.ExternalCartLineId || index} data={data} /> : '')
            ) : ''
          }
        </BoxData>
        )}
      </div>
    )
  );
};

CustomerProduct.propTypes = {
  fields: object
};

export default CustomerProduct;
