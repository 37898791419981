import './ExpertPopupContent.scss';

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SimpleSquareItemFieldsModel from '../SimpleSquareTiles/Model/SimpleSquareItemFieldsModel';
import { deepCopy } from '@utils/utility';
import { Text, Image, RichText } from '@sitecore-jss/sitecore-jss-react';

const ExpertPopupContent = (props) => {
  const { expertPopupData } = props;
  const [expertPopupDataFields, setExpertPopupDataFields] = useState(null);

  useEffect(() => {
    const expertPopupDataFieldsModel = new SimpleSquareItemFieldsModel();

    setExpertPopupDataFields(expertPopupDataFieldsModel.getData(deepCopy(expertPopupData.fields || {})));
  }, []);

  return (
    expertPopupDataFields && (
      <div className='expert-popup'>
        <div className='expert-popup__info'>
          <div className='expert-popup__info__group'>
            <div className='expert-popup__info__image'>
              <Image media={expertPopupDataFields.Image} />
            </div>
            <div className='expert-popup__info__text'>
              <Text className='expert-popup__info__name' tag='h4' field={expertPopupDataFields.Name} />
              <Text className='expert-popup__info__designation' tag='h5' field={expertPopupDataFields.Position} />
              <RichText field={expertPopupDataFields.Experience} className='expert-popup__info__story expert-popup__info__story--desktop 1'/>
            </div>
          </div>
          <RichText field={expertPopupDataFields.Experience} className='expert-popup__info__story expert-popup__info__story--mobile'/>
        </div>
        <div className='expert-popup__content'>
          <Text field={expertPopupDataFields.Description} />
        </div>
      </div>
    )
  );
};

ExpertPopupContent.propTypes = {
  expertPopupData: PropTypes.object,
};

export default ExpertPopupContent;