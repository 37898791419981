// import SitecoreTextModel from '../../../../../models/SitecoreTextModel';
export default class LanguageListModel {
  constructor() {
    this['code'] = 'en';

    this['href'] = '/en/';

    this['name'] = 'English';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'code') && dataSource['code']) {
      this['code'] = dataSource['code'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'href') && dataSource['href']) {
      this['href'] = dataSource['href'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'name') && dataSource['name']) {
      this['name'] = dataSource['name'];
    }

    return this;
  }
}
