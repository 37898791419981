import PropTypes from 'prop-types';
import React from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const VerticalTabExtraLinkItem = ({ contentData, contentLabel }) => {
  const name = SitecoreContextFactoryService.getValueContextRouteItem('name');
  const isGA4 = Global.getIsGA4();

  //New Logic ON/OFF GA, GA4
  const handleGA4Event = () => {
    if (isGA4) {
      Global.handleGA4Navigation({
        labelItem: contentData.Title.value,
        pageTitle: name,
        labelCategory: contentData.Title.value,
        pageUrl: contentData.Link.value.href
      });
    }
  };

  return (
    <li className='header__sub-menu-nav__item'>
      <Link field={contentData.Link} onClick={handleGA4Event}>
        <Text field={contentData.Title} tag='h3' className='header__sub-menu-nav__item__title' />
      </Link>
    </li>
  );
};

VerticalTabExtraLinkItem.propTypes = {
  contentData: PropTypes.object,
  contentLabel: PropTypes.string
};

VerticalTabExtraLinkItem.defaultProps = {
  contentLabel: ''
};

export default VerticalTabExtraLinkItem;
