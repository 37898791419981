import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import { isStickyHeader$ } from '@components/Product/VariantDetail/ProductDetailHeader/handleStickyHeaderService';

const BuyingOptionsShimmer = () => {
  const [isBOHeaderSticky, setIsBOHeaderSticky] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  
  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
  
    window.addEventListener('scroll', onScroll);
  
    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);
  
  useEffect(() => {
    if (scrollTop > 0) {
      setIsBOHeaderSticky(true);

      isStickyHeader$.next(true);
    } else {
      setIsBOHeaderSticky(false);

      isStickyHeader$.next(false);
    }
  }, [scrollTop]);
    
  return (
    <Fragment>
      <div className={`buying-option-header${isBOHeaderSticky ? ' buying-option-header-sticky' : ''} skeleton__item`}>
        <div className='buying-option-header__wrap container'>
          <div className='buying-option-header__text'>
            <span className='buying-option-header__text__name'></span>
            <div className='buying-option-header__text__price'>
              <span className='buying-option-header__text__price__current'></span>
            </div>
          </div>
          <div className='buying-option-header__btn image-wrap'>
            <div className='buying-option-header__btn__item'>
              <span className='image-line'></span>
            </div>
          </div>
        </div>
      </div>
      <div className='buying-option skeleton__item'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6 buying-option__left'>
              <div className='image-wrap item__content__big-image'>
                <span className='image-line'></span>
              </div>
            </div>
            <div className='col-lg-6 buying-option__right'>
              <div className='buying-option__shimmer-item'>
                <span className='text-line item__content__title'></span>
                <span className='clear-5'></span>
                <div className='item__content'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='image-wrap item__content__image'>
                        <div className='image-line'></div>
                      </div>
                      <span className='clear-5'></span>
                    </div>
                    <div className='col-lg-6'>
                      <div className='image-wrap item__content__image'>
                        <div className='image-line'></div>
                      </div>
                      <span className='clear-5'></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='buying-option__shimmer-item'>
                <span className='text-line item__content__title'></span>
                <span className='clear-5'></span>
                <div className='item__content'>
                  <div className='row'>
                    <div className='col-lg-6'>
                      <div className='image-wrap item__content__image'>
                        <div className='image-line'></div>
                      </div>
                      <span className='clear-5'></span>
                    </div>
                    <div className='col-lg-6'>
                      <div className='image-wrap item__content__image'>
                        <div className='image-line'></div>
                      </div>
                      <span className='clear-5'></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='buying-option__shimmer-item'>
                <span className='text-line item__content__title'></span>
                <span className='clear-20'></span>
                <div className='item__content bo-gift-shimmer'>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                </div>
                <span className='clear-20 hidden-md-down'></span>
                <div className='item__content bo-gift-shimmer hidden-md-down'>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                  <div className='bo-gift-shimmer__item'>
                    <div className='image-wrap item__content__image'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-5'></span>
                    <div className='text-line'></div>
                  </div>
                </div>
              </div>
              <div className='buying-option__shimmer-item bo-payment-info'>
                <div className='bo-payment-info__wrap image-wrap'>
                  <div className='image-line'></div>
                </div>    
              </div>
              <div className='buying-option__shimmer-item buying-option__btn'>
                <span className='buying-option__btn__item'>
                  <span className='buying-option-header__btn__item'>
                    <span className='text-line'></span>
                  </span>
                </span>
                <span className='buying-option__btn__item'>
                  <span className='buying-option-header__btn__item'>
                    <span className='text-line'></span>
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default BuyingOptionsShimmer;