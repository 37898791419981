import AllowedOptionsFieldsModel from './AllowedOptionsFieldsModel';

export default class AllowdOptionsModel {
  constructor() {
    this['fields'] = new AllowedOptionsFieldsModel();

    this['id'] = '';

    this['url'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this['id'] = dataSource['id'] ?? this['id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'url')) {
      this['url'] = dataSource['url'] ?? this['url'];
    }

    return this;
  }
}
