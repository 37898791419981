import PropTypes from 'prop-types';
import React from 'react';

const HiddenField = (props) => {
  const { field, value, onChange, tracker, errors } = props;

  const handleOnChange = (field, fieldValue, callback) => {
    // (fieldName, fieldValue, isValid, validationErrors)
    callback(field.valueField.name, fieldValue, true, []);
  };

  return (
    <input type='hidden' id={field.valueField.id}
      name={field.valueField.name}
      value={value}
      onChange={(e) => handleOnChange(field, e.target.checked, onChange)}
      onFocus={() => tracker.onFocusField(field, value)}
      onBlur={() => tracker.onBlurField(field, value, errors)} />
  );
};

HiddenField.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tracker: PropTypes.any,
  errors: PropTypes.any,
  children: PropTypes.any
};

export default HiddenField;
