import ProductAvailabilityApiModel from './ProductAvailabilityApiModel';
import RefunableDepositProductModel from './RefunableDepositProductModel';
import VariantDetailApiModel from './VariantDetailApiModel';

export default class ProductDetailOverviewApiModel {
  constructor() {
    this.VariantDetail = [];

    this.VariantActiveDefault = null;

    this.ProductAvailability = [];

    this.LimitStockQuantity = '';

    this.LastLabel = '';

    this.LastFewUnitsLeftLabel = '';

    this.RefunableDepositProduct = [];

    this.PriceExtraInformation = '';

    this.VariantPropertyName = '';

    this.ExploreLabel = '';

    this.HasInstallmentPlan = false;

    this.InstallmentPeriods = [];

    this.Brand = [];

    this.BundleDealsButtonUrl = '';

    this.MainImage = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantDetail')) {
      dataSource['VariantDetail'].map((variantItem) => {
        this.VariantDetail.push(new VariantDetailApiModel().getData(variantItem, dataSource.CategoryName));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductAvailability')) {
      dataSource['ProductAvailability'].map((productAvailabilityItem) => {
        this.ProductAvailability.push(new ProductAvailabilityApiModel().getData(productAvailabilityItem));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastLabel') && dataSource.LastLabel) {
      this.LastLabel = dataSource.LastLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastFewUnitsLeftLabel')) {
      this.LastFewUnitsLeftLabel = dataSource.LastFewUnitsLeftLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LimitStockQuantity')) {
      this.LimitStockQuantity = dataSource.LimitStockQuantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RefunableDepositProduct')) {
      dataSource['RefunableDepositProduct'].map((refunableDepositProductItem) => {
        this.RefunableDepositProduct.push(new RefunableDepositProductModel().getData(refunableDepositProductItem));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantPropertyName')) {
      this.VariantPropertyName = dataSource['VariantPropertyName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExploreLabel')) {
      this.ExploreLabel = dataSource['ExploreLabel'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PriceExtraInformation')) {
      this.PriceExtraInformation = dataSource.PriceExtraInformation ?? this.PriceExtraInformation;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'HasInstallmentPlan')) {
      this.HasInstallmentPlan = dataSource.HasInstallmentPlan ?? this.HasInstallmentPlan;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentPeriods')) {
      this.InstallmentPeriods = dataSource.InstallmentPeriods ?? this.InstallmentPeriods;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Brand')) {
      this.Brand = dataSource.Brand ?? this.Brand;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleDealsButtonUrl')) {
      this.BundleDealsButtonUrl = dataSource.BundleDealsButtonUrl ?? this.BundleDealsButtonUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      this.MainImage = dataSource.Images?.[0] ?? this.MainImage;
    }

    return this;
  }
}
