import { object, string } from 'prop-types';
import React, { useMemo } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const BookExperienceCta = ({ layoutData, productId }) => {
  const bookExperienceUrl = useMemo(() => {
    const url = layoutData?.['Experience In Store Link']?.value;

    return url
      ? {
        value: {
          ...url,
          href: `${url?.href}?id=${productId}`
        }
      }
      : '';
  }, [layoutData, productId]);

  return layoutData?.['Experience In Store Text']?.value && bookExperienceUrl ? (
    <Link field={bookExperienceUrl} className='btn btn-outline-primary btn-book-experience'>
      <Text field={layoutData?.['Experience In Store Text']} />
    </Link>
  ) : (
    <></>
  );
};

BookExperienceCta.propTypes = {
  layoutData: object,
  productId: string
};

export default BookExperienceCta;
