import produce from 'immer';

// Import action types
import * as productTypes from '@redux/actions/product/productTypes';

const initState = {
  error: '',
  productInformation: null,
  isLoading: false
};

const productInformationReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.GET_PRODUCT_INFORMATION:
      draft.isLoading = true;

      break;

    case productTypes.GET_PRODUCT_INFORMATION_SUCCESS:
      draft.productInformation = action.payload.data;

      draft.isLoading = false;

      break; 

    case productTypes.GET_PRODUCT_INFORMATION_FAILED:
      draft.error = '';

      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default productInformationReducer;
