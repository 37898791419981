import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import { getAsObservable } from '@services/genericService';

import { getCategoryForRegisterWarrantyDataFailed, getCategoryForRegisterWarrantyDataSuccess } from '../../actions/categoryForRegisterWarranty';
import { GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA } from '../../actions/categoryForRegisterWarranty/categoryForRegisterWarranryTypes';
import { GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_ENDPOINT } from './config';

export const getCategoryForRegisterWarrantyDataEpic = (action$) =>
  action$.pipe(
    ofType(GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA),
    switchMap(() => {
      return getAsObservable(GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_ENDPOINT).pipe(
        map((res) => {
          if (res.status === 200) {
            return getCategoryForRegisterWarrantyDataSuccess(res.data.Categories);
          } else {
            return getCategoryForRegisterWarrantyDataFailed('Failed');
          }
        })
      );
    })
  );
