export const swiperParams = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  breakpoints: {
    1680: {
      slidesPerView: 6,
      spaceBetween: 32
    },
    1200: {
      slidesPerView: 5,
      spaceBetween: 24
    },
    1024: {
      slidesPerView: 5,
      spaceBetween: 16
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 16
    },
    320: {
      slidesPerView: 3,
      spaceBetween: 16
    }
  }
};
