export const ACTION_AUTO_COMPLETE = '/Search/GetAutoComplete';

// Type
export const ITEM_TYPE_ALL = 'All';

export const ITEM_TYPE_WELLNESS = 'WellnessArticle';

export const ITEM_TYPE_PRODUCT = 'Product';

export const ITEM_TYPE_POLICY = 'Policy';

// Auto Complete
export const COMMON_RESULT_NUMB = 5;