import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ProductWishlistModel {
  constructor() {
    this['Back To My Account Label'] = new SitecoreTextModel();

    this['Buy Options Label'] = new SitecoreTextModel();

    this['Experience In Store Label'] = new SitecoreTextModel();

    this['Remove Confirmation Message'] = new SitecoreTextModel();

    this['Learn More Label'] = new SitecoreTextModel();

    this['My WishList Title Label'] = new SitecoreTextModel();

    this['Share Your Wishlist Label'] = new SitecoreTextModel();

    this['Remove Button Label'] = new SitecoreTextModel();

    this['Back To My Account Link'] = new SitecoreLinkModel();

    this['Buy Options Link'] = new SitecoreLinkModel();

    this['Experience In Store Link'] = new SitecoreLinkModel();

    this['Learn More Link'] = new SitecoreLinkModel();

    this['Share Your Wishlist Link'] = new SitecoreLinkModel();

    this['Deposit Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Label')) {
      this['Deposit Label'].getData(dataSource['Deposit Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Back To My Account Label')) {
      this['Back To My Account Label'].getData(dataSource['Back To My Account Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Remove Button Label')) {
      this['Remove Button Label'].getData(dataSource['Remove Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Remove Confirmation Message')) {
      this['Remove Confirmation Message'].getData(dataSource['Remove Confirmation Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Options Label')) {
      this['Buy Options Label'].getData(dataSource['Buy Options Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Label')) {
      this['Learn More Label'].getData(dataSource['Learn More Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'My WishList Title Label')) {
      this['My WishList Title Label'].getData(dataSource['My WishList Title Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Share Your Wishlist Label')) {
      this['Share Your Wishlist Label'].getData(dataSource['Share Your Wishlist Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience In Store Label')) {
      this['Experience In Store Label'].getData(dataSource['Experience In Store Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Back To My Account Link')) {
      this['Back To My Account Link'].getData(dataSource['Back To My Account Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Options Link')) {
      this['Buy Options Link'].getData(dataSource['Buy Options Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience In Store Link')) {
      this['Experience In Store Link'].getData(dataSource['Experience In Store Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Link')) {
      this['Learn More Link'].getData(dataSource['Learn More Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Link')) {
      this['Learn More Link'].getData(dataSource['Learn More Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Share Your Wishlist Link')) {
      this['Share Your Wishlist Link'].getData(dataSource['Share Your Wishlist Link']);
    }

    return this;
  }
}
