import produce from 'immer';

import * as cartTypes from '@redux/actions/cartDetail/cartTypes';
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility';

import BundleBOSubLinesItemModel from './model/BundleBOSubLinesItemModel';

const initState = {
  cartLineIdToSuccessPage: null,
  SubLines: null,
  editSelectedFreeGift: null,
  EditQuantity: null,
  isLoading: false,
  isActionSuccess: false,
  isToggleChangePage: false,
  errorMsg: '',
  isDoneGetCart: false,
  isError: false
};

const bundleBOReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let newSubLines = [];

    switch (action.type) {
    case productTypes.GET_CURRENT_CART:

      draft.isDoneGetCart = false;

      draft.isLoading = true;
  
      break;
        
    case productTypes.GET_CURRENT_CART_SUCCESS:
      draft.isDoneGetCart = true;

      draft.isLoading = false;

      draft.errorMsg = '';

      draft.isError = false;

      if (action.filteredCartLine.length) {

        action.filteredCartLine[0].SubLines.map((element, elementIndex) => {
          newSubLines.push(new BundleBOSubLinesItemModel().getData(deepCopy(element) || {}, elementIndex));
        });

        draft.SubLines = newSubLines;

        draft.editSelectedFreeGift = action.filteredCartLine[0].PromotionCartLines;

        draft.EditQuantity = +action.filteredCartLine[0].Quantity;
      }

      break;

    case productTypes.GET_CURRENT_CART_FAILED:
      draft.errorMsg = action.error.errorMessage;

      draft.isError = true;

      draft.isDoneGetCart = true;
  
      draft.isLoading = true;
    
      break;

    case productTypes.ADD_TO_CART:

      draft.isLoading = true;
    
      break;

    case productTypes.ADD_TO_CART_SUCCESS:
      draft.isLoading = true;

      draft.isActionSuccess = true;

      draft.cartLineIdToSuccessPage = action.payload.CartLineId;

      draft.isToggleChangePage = !draft.isToggleChangePage;
  
      break;

    case productTypes.ADD_TO_CART_FAILED:
      draft.isLoading = false;
      
      draft.isActionSuccess = false;

      draft.errorMsg = action.error;

      draft.isError = true;

      draft.isToggleChangePage = !draft.isToggleChangePage;
  
      break;

    case cartTypes.UPDATE_ONE_CART_LINE:

      draft.isLoading = true;
      
      break;

    case cartTypes.UPDATE_ONE_CART_LINE_SUCCESS:
      draft.isLoading = false;

      draft.isActionSuccess = true;
  
      draft.isToggleChangePage = !draft.isToggleChangePage;
    
      break;

    case cartTypes.UPDATE_ONE_CART_LINE_FAILED:
      draft.isLoading = false;
      
      draft.isActionSuccess = false;

      draft.isError = true;

      draft.errorMsg = action.error;

      draft.isToggleChangePage = !draft.isToggleChangePage;
  
      break;

    case cartTypes.SET_ERROR_MESSENGER:
      draft.errorMsg = action.payload;
      break;
    }

    return draft;
  });

export default bundleBOReducer;
