import moment from 'moment';

export const UTC_DATE_TIME_FORMAT_PATTERN = 'yyyy-MM-DDTHH:mm:ss+00:00';

/** @param {string} dateString */
export const formatDateStringToMomentValue = (dateString) => moment(dateString);

/** @param {string} date */
export const formatDateToUTC = (date) => {
  let convertStringToMoment = date ? formatDateStringToMomentValue(date) : '';

  if (convertStringToMoment && moment.isMoment(convertStringToMoment) && !!convertStringToMoment.valueOf()) {
    convertStringToMoment = moment(convertStringToMoment);

    convertStringToMoment.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    });

    return convertStringToMoment.format(UTC_DATE_TIME_FORMAT_PATTERN);
  }

  return '';
};
