import SitecoreTextModel from '@models/SitecoreTextModel';

const MILESTONE_FIELDS_TEXT_ARR = ['Tier Lower Bound', 'Tier Upper Bound', 'Message', 'Enable', 'Start Time', 'End Time'];

class MilestoneFieldsModel {
  constructor() {
    MILESTONE_FIELDS_TEXT_ARR.map((item) => (this[item] = new SitecoreTextModel()));
  }

  getData(dataSource) {
    MILESTONE_FIELDS_TEXT_ARR.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}

export default class CartPromotionMilestoneModel {
  constructor() {
    this['Milestone'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Milestone')) {
      if (dataSource['Milestone']?.length) {
        this['Milestone'] = dataSource['Milestone'].map((item) => new MilestoneFieldsModel().getData(item?.fields || {}));
      }
    }

    return this;
  }
}
