import React from 'react';

const ProductDetailShimmer = () => {
  return (
    <div className='my-account-section my-product-widget skeleton__item product-warranty__detail-shimmer'>
      <div className='my-account-section__heading'>
        <p className='my-account-section__heading__label'>
          <span className='text-line-20'></span>
        </p>
        <p className='my-account-section__heading__label'>
          <span className='text-line-20'></span>
        </p>
      </div>
      <div className='product-warranty__detail-product__info'>
        <div className='product-warranty__detail-product__detail'>
          <span className='text-line-30'></span>
          <span className='clear-20'></span>
          <span className='text-line-30'></span>
          <span className='clear-20'></span>
          <span className='text-line-30'></span>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailShimmer;
