import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { concatMap, map, mergeMap, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/coupon';
import * as couponAction from '@redux/actions/coupon';
import * as couponTypes from '@redux/actions/coupon/couponTypes';
import * as cartActions from '@redux/actions/product';
import CartDetailService from '@redux/epic/product/cartDetail/service';
import { get, post } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getCouponDetailEpic = (action$) =>
  action$.pipe(
    ofType(couponTypes.GET_COUPON_DETAIL),
    switchMap((action) => {
      const params = {
        promotionId: action.payload.id
      };
      if (action.payload.code) params.couponId = action.payload.code;

      return from(get(config.GET, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getCouponDetailSuccess(deepCopy(res.data));
          } else {
            return actions.getCouponDetailFailed('Failed');
          }
        })
      );
    })
  );

export const applyCouponCodeEpic = (action$) =>
  action$.pipe(
    ofType(couponTypes.APPLY_COUPON_CODE),
    mergeMap((action) => {
      const params = {
        PromotionCode: action.payload.code || ''
      };

      if (action.payload.reCallPromotionsApplied) {
        return from(post(config.APPLY, params)).pipe(
          concatMap((res) => {
            if (res.status === 200) {
              let getCurrentCart;
              if (res.data.Success) {
                getCurrentCart = cartActions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);
              } else {
                getCurrentCart = cartActions.getCurrentCartFailed({ errorMessage: res.data.Errors[0], link: res.data.RedirectLink });
              }

              return merge(
                of(getCurrentCart),
                from(get(config.GET_APPLIED)).pipe(
                  map((res) => {
                    if (res.status === 200) {
                      return actions.getAppliedCouponListSuccess(deepCopy(res.data));
                    } else {
                      return actions.getAppliedCouponListFailed('Failed');
                    }
                  })
                )
              );
            } else {
              return actions.applyCouponCodeFailed('Failed');
            }
          })
        );
      } else {
        return from(post(config.APPLY, params)).pipe(
          map((res) => {
            if (res.status === 200) {
              if (res.data.Success) {
                return couponAction.applyCouponCodeSuccess(res.data.Success);
              } else {
                return cartActions.getCurrentCartFailed({ errorMessage: res.data.Errors, link: res.data.RedirectLink });
              }
            } else {
              return actions.applyCouponCodeFailed('Failed');
            }
          })
        );
      }
    })
  );

export const applySOCouponCodeEpic = (action$) =>
  action$.pipe(
    ofType(couponTypes.APPLY_SO_COUPON_CODE),
    mergeMap((action) => {
      const params = {
        PromotionCode: action.payload.code || ''
      };

      if (action.payload.reCallPromotionsApplied) {
        return from(post(config.APPLY_SO, params)).pipe(
          concatMap((res) => {
            if (res.status === 200) {
              let getCurrentCart;
              if (res.data.Success) {
                getCurrentCart = cartActions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);
              } else {
                getCurrentCart = cartActions.getCurrentCartFailed({ errorMessage: res.data.Errors[0], link: res.data.RedirectLink });
              }

              return merge(
                of(getCurrentCart),
                from(get(config.GET_APPLIED)).pipe(
                  map((res) => {
                    if (res.status === 200) {
                      return actions.getAppliedCouponListSuccess(deepCopy(res.data));
                    } else {
                      return actions.getAppliedCouponListFailed('Failed');
                    }
                  })
                )
              );
            } else {
              return actions.applySOCouponCodeFailed('Failed');
            }
          })
        );
      } else {
        return from(post(config.APPLY_SO, params)).pipe(
          map((res) => {
            if (res.status === 200) {
              if (res.data.Success) {
                return couponAction.applySOCouponCodeSuccess(res.data.Success);
              } else {
                return cartActions.getCurrentCartFailed({ errorMessage: res.data.Errors, link: res.data.RedirectLink });
              }
            } else {
              return actions.applySOCouponCodeFailed('Failed');
            }
          })
        );
      }
    })
  );

export const removeCouponCodeEpic = (action$) =>
  action$.pipe(
    ofType(couponTypes.REMOVE_COUPON_CODE),
    mergeMap((action) => {
      const params = {
        PromotionCode: action.payload
      };

      return from(post(config.REMOVE, params)).pipe(
        concatMap((res) => {
          if (res.status === 200) {
            let getCurrentCart;
            if (res.data.Success) {
              getCurrentCart = cartActions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);
            } else {
              getCurrentCart = cartActions.getCurrentCartFailed({ errorMessage: res.data.Errors[0] });
            }

            return merge(
              of(getCurrentCart),
              from(get(config.GET_APPLIED)).pipe(
                map((res) => {
                  if (res.status === 200) {
                    return actions.getAppliedCouponListSuccess(deepCopy(res.data));
                  } else {
                    return actions.getAppliedCouponListFailed('Failed');
                  }
                })
              )
            );
            // return actions.removeCouponCodeSuccess('Success');
          } else {
            return actions.removeCouponCodeFailed('Failed');
          }
        })
      );
    })
  );

export const removeSOCouponCodeEpic = (action$) =>
  action$.pipe(
    ofType(couponTypes.REMOVE_SO_COUPON_CODE_START),
    mergeMap((action) => {
      const params = {
        PromotionCode: action.payload.code,
        CartName: action.payload.cartId
      };

      return from(post(config.REMOVE_SO_COUPON_CODE, params)).pipe(
        concatMap((res) => {
          if (res.status === 200) {
            let getCurrentCart;
            if (res.data.Success) {
              getCurrentCart = cartActions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []);
            } else {
              getCurrentCart = cartActions.getCurrentCartFailed({ errorMessage: res.data.Errors[0] });
            }

            return merge(
              of(getCurrentCart),
              from(get(config.GET_APPLIED)).pipe(
                map((res) => {
                  if (res.status === 200) {
                    return actions.getAppliedCouponListSuccess(deepCopy(res.data));
                  } else {
                    return actions.getAppliedCouponListFailed('Failed');
                  }
                })
              )
            );
            // return actions.removeCouponCodeSuccess('Success');
          } else {
            return actions.removeCouponCodeFailed('Failed');
          }
        })
      );
    })
  );
