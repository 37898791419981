import produce from 'immer';

import DeliveryOptionsModel from '@components/SlicingPage/CartDetails/models/DeliveryOptionsModel';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as cartTypes from '@redux/actions/cartDetail/cartTypes';
import * as productTypes from '@redux/actions/product/productTypes';

import BOGetDataStatusModel from './BOGetDataStatusModel';

const initState = {
  cartLineIdToSuccessPage: null,
  productOverview: {
    value: ''
  },
  productName: '',
  ProductDetailUrl: '',
  productId: '',
  catalogName: '',
  isDoneGetData: false,
  isLoading: false,
  isLoadingCurrentCart: false,
  isActionSuccess: null,
  isToggleChangePage: false,
  isFindCartLine: null,
  deliveryOptions: [],
  labels: [],
  EditQuantity: null,
  errorMsg: '',
  isError: false,
  Acknowledgement: '',
  settingStockProduct: '',
  ProductPageId: '',
  ProductItemId: '',
  HasInstallmentPlan: false,
  CategoryName: null,
  InstallmentPeriods: null,
  DataBO: null,
  CurrencyCode: null,
  Discount: null,
  SellingPrice: null,
  ListPrice: null,
  AddOnDeals: [],
  BundleDealsButtonUrl: null
};

const BOGetDataStatusReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case buyingOptionsTypes.GET_BO_DATA:

      draft.isLoading = true;

      break;

    case buyingOptionsTypes.GET_BO_DATA_SUCCESS:
      draft.ProductDetailUrl = action.payload.ProductSummary.Url || '';

      draft.productOverview.value = action.payload.ProductSummary.ProductOverview || '';

      draft.productName = action.payload.ProductSummary.ProductName;

      draft.catalogName = action.payload.ProductSummary.CatalogName;

      draft.productId = action.payload.ProductSummary.ProductId;

      draft.currencySetting = action.payload.ProductSummary.CurrencyInformation;

      draft.isDoneGetData = true;

      draft.Acknowledgement = action.payload.ProductSummary.Acknowledgement;

      // INFO: new update from ticket https://jira.smartosc.com/browse/OSIM-6543 => to check deposit product using
      // Deposit !== null
      // draft.IsDeposit = action.payload.ProductSummary.Deposit.IsDeposit;
      draft.IsDeposit = action.payload.ProductSummary.Deposit !== null;

      draft.Deposit = action.payload.ProductSummary.Deposit;

      draft.isLoading = false;
      if (action.payload.ProductSummary.DeliveryOptions?.length) {
        action.payload.ProductSummary.DeliveryOptions.map(itemDelivery => {
          draft.deliveryOptions.push(new DeliveryOptionsModel().getData(itemDelivery || {}));
        });
      }

      draft.labels = action.payload.ProductSummary.Labels;

      draft.settingStockProduct = new BOGetDataStatusModel().getData(action.payload.ProductSummary || {});

      draft.ProductPageId = action.payload.ProductSummary.ProductPageId;

      draft.ProductItemId = action.payload.ProductSummary.ProductItemId;

      draft.HasInstallmentPlan = action.payload.ProductSummary?.HasInstallmentPlan;

      draft.CategoryName = action.payload.ProductSummary?.CategoryName;

      draft.InstallmentPeriods = action.payload.ProductSummary?.InstallmentPeriods;

      draft.DataBO = action.payload.ProductSummary;

      draft.CurrencyCode = action.payload.ProductSummary?.Price?.CurrencyCode;

      draft.Discount = action.payload.ProductSummary?.Price?.Discount;

      draft.SellingPrice = action.payload.ProductSummary?.Price?.SellingPrice;

      draft.ListPrice = action.payload.ProductSummary?.Price?.ListPrice;

      draft.AddOnDeals = action.payload.ProductSummary?.AddOnDeals;

      draft.BundleDealsButtonUrl = action.payload.ProductSummary?.BundleDealsButtonUrl;
      break;

    case buyingOptionsTypes.GET_BO_DATA_FAILED:

      draft.isDoneGetData = true;

      draft.errorMsg = action.error;

      draft.isError = true;

      draft.isLoading = false;

      break;

    case productTypes.GET_CURRENT_CART:

      draft.isLoadingCurrentCart = true;

      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:

      draft.isLoadingCurrentCart = false;

      if (action.filteredCartLine.length) {
        draft.isFindCartLine = true;

        draft.EditQuantity = +action.filteredCartLine[0].Quantity;
      } else {
        draft.isFindCartLine = false;
      }

      break;

    case productTypes.GET_CURRENT_SO_CART_SUCCESS:

      draft.isLoadingCurrentCart = false;

      if (action.filteredCartLine.length) {
        draft.isFindCartLine = true;

        draft.EditQuantity = +action.filteredCartLine[0].Quantity;
      } else {
        draft.isFindCartLine = false;
      }

      break;

    case productTypes.GET_CURRENT_CART_FAILED:

      draft.errorMsg = action.error.errorMessage;

      draft.isError = true;

      draft.isLoadingCurrentCart = false;

      break;

    case productTypes.ADD_TO_CART:

      draft.isLoading = true;

      break;

    case productTypes.ADD_TO_CART_SUCCESS:

      draft.isLoading = true;

      draft.isActionSuccess = true;

      draft.cartLineIdToSuccessPage = action.payload.CartLineId;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case productTypes.ADD_TO_CART_FAILED:

      draft.isLoading = false;

      draft.isActionSuccess = false;

      draft.isError = true;

      draft.errorMsg = action.error;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case productTypes.ADD_TO_SO_CART:

      draft.isLoading = true;

      break;

    case productTypes.ADD_TO_SO_CART_SUCCESS:

      draft.isLoading = true;

      draft.isActionSuccess = true;

      draft.cartLineIdToSuccessPage = action.payload.CartLineId;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case productTypes.ADD_TO_SO_CART_FAILED:

      draft.isLoading = false;

      draft.isActionSuccess = false;

      draft.isError = true;

      draft.errorMsg = action.error;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case cartTypes.UPDATE_ONE_CART_LINE:

      draft.isLoading = true;

      break;

    case cartTypes.UPDATE_ONE_CART_LINE_SUCCESS:

      draft.isLoading = true;

      draft.isActionSuccess = true;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case cartTypes.UPDATE_ONE_CART_LINE_FAILED:

      draft.isLoading = false;

      draft.isError = true;

      draft.errorMsg = action.error;

      draft.isActionSuccess = false;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case cartTypes.UPDATE_SO_CART_LINE:

      draft.isLoading = true;

      break;

    case cartTypes.UPDATE_SO_CART_LINE_SUCCESS:

      draft.isLoading = true;

      draft.isActionSuccess = true;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case cartTypes.UPDATE_SO_CART_LINE_FAILED:

      draft.isLoading = false;

      draft.isError = true;

      draft.errorMsg = action.error;

      draft.isActionSuccess = false;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case cartTypes.PREVENT_ADD_TRADE_IN_PRODUCT_TO_CART:
      draft.isError = true;

      draft.errorMsg = action.payload;
      break;

    case productTypes.ADD_TO_CART_V2:
      draft.isLoading = true;

      break;break;

    case productTypes.ADD_TO_SO_CART_V2:

      draft.isLoading = true;

      break;

    case productTypes.ADD_TO_SO_CART_V2_SUCCESS:

      draft.isLoading = true;

      draft.isActionSuccess = true;

      draft.cartLineIdToSuccessPage = action.payload.CartLineId;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;

    case productTypes.ADD_TO_SO_CART_V2_FAILED:

      draft.isLoading = false;

      draft.isActionSuccess = false;

      draft.isError = true;

      draft.errorMsg = action.error;

      draft.isToggleChangePage = !draft.isToggleChangePage;

      break;
    }

    return draft;
  });

export default BOGetDataStatusReducer;
