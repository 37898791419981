import './BookExperienceButtonComponent.scss';

import PropTypes from 'prop-types';
import React, {useEffect,useState} from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import BookExperienceButtonComponentModel from './models/BookExperienceButtonComponentModel';

const BookExperienceButtonComponent = ({fields})=> {

  const [dataSources, setDataSources] = useState(null);

  useEffect(()=> {
    setDataSources(new BookExperienceButtonComponentModel().getData(fields || {}));
  }, []);

  return dataSources ? (
    <div className='appointment-listing-button'>
      <Link field={dataSources?.['Button Link']} className='btn btn-outline-CTA2'>
        <Text field={dataSources?.['Button Text']}/>
      </Link>
    </div>
  ) : (<></>);
};

BookExperienceButtonComponent.propTypes = {
  fields: PropTypes.object
};

export default BookExperienceButtonComponent;