import Proptypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const MyProductWidgetShimmer = ({ myProductsWidgetFields }) => {
  return (
    myProductsWidgetFields && (
      <div className='my-account-section my-product-widget skeleton__item'>
        <div className='my-account-section__heading'>
          <div className='my-account-section__group'>
            <div className='my-account-section__heading__title'>
              <Text field={myProductsWidgetFields['My Products Label']} />
            </div>
            <span className='text-line-small'></span>
          </div>
          <p className='my-account-section__heading__label'>
            <span className='text-line-20'></span>
          </p>
        </div>
        <div className='my-product-widget__list'>
          <div className='my-product-widget__list__wrap'>
            <span className='text-line-30'></span>
            <span className='clear-20'></span>
            <span className='text-line-30'></span>
            <span className='clear-20'></span>
            <span className='text-line-30'></span>
            <span className='clear-20'></span>
            <span className='text-line-30'></span>
            <span className='clear-40'></span>
          </div>
        </div>
        <div className='my-product-widget__button__register text-center'>
          <span className='button-line'></span>
        </div>
      </div>
    )
  );
};

MyProductWidgetShimmer.propTypes = {
  myProductsWidgetFields: Proptypes.any
};

export default MyProductWidgetShimmer;
