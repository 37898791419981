import produce from 'immer';

// Import action types
import * as staticBundleDetailTypes from '@redux/actions/staticBundleDetail/staticBundleDetailTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  staticBundleDetail: {},
  error: '',
  prices: {},
  variantStatus: ''
};

const staticBundleDetailReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case staticBundleDetailTypes.GET_STATIC_BUNDLE_DETAIL_SUCCESS:
      draft.staticBundleDetail = deepCopy(action.payload);
      break;

    case staticBundleDetailTypes.GET_STATIC_BUNDLE_DETAIL_FAILED:
      draft.error = deepCopy(action.error);
      break;

    case staticBundleDetailTypes.GET_PROMOTION_PRICE:
      draft.prices = deepCopy(action.payload);
      break;

    case staticBundleDetailTypes.GET_VARIANT_STATUS:
      draft.variantStatus = action.payload;
      break;
    }


    return draft;
  });

export default staticBundleDetailReducer;
