import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { get } from '@services/genericService';

import * as config from './config';

const defaultLocatorEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_DEFAULT_LOCATOR),
    switchMap(action => { 
      
      return from(get(config.ACTION)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getDefaultLocatorSuccess({
              ...res, 
              currentLocation: action.payload
            });
          } else {
            return actions.getDefaultLocatorFailed('Failed');
          }
        })
      );
    })
  );

export default defaultLocatorEpic;
