import './InfoStockComponent.scss';

import PropTypes from 'prop-types';
import React from 'react';

import * as config from './config';

const InfoStockComponent = (props) => {
  const { className, children, tag, stock } = props;

  return stock && stock.LimitStockQuantity && stock.PurchasableLimit &&  stock.LimitStockQuantity >= stock.PurchasableLimit ? React.createElement(
    tag,
    {
      className: className,
    },
    children
  ) : '';
};


InfoStockComponent.defaultProps = {
  tag: config.NAME_TAG,
  className: config.DEFAULT_CLASS
};

InfoStockComponent.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.string,
  stock: PropTypes.object,
};

export default InfoStockComponent;
