import { combineEpics } from 'redux-observable';

import addFreeGiftToCartEpic from './addFreeGiftToCart';
import addPriorityToCartEpic from './addPriorityToCart';
import addWarrantyEpic from './addWarranty';
import { bookingExperienceEpic } from './bookingExperience';
import buyingOptionsEpic from './buyingOptions';
import campaignRegister from './campaignRegister';
import { getCategoryForRegisterWarrantyDataEpic } from './categoryForRegisterWarranty';
import checkoutProcessEpic from './checkoutProcess';
import clienttelingPortal from './clienttelingPortal';
import {
  endSessionEpic,
  getClientTellingPortalCustomerEpic,
  getContactPersonalInformationEpic,
  getCouponWalletEpic,
  removeCpProductInterest,
  searchClientTellingPortalCustomerEpic,
  updateCPCustomerContactEpic,
  updateCPCustomerContactInterestEpic
} from './clientTellingPortalCustomer';
import commonEpic from './common';
import compareProductEpic from './compareProducts';
import couponsListEpic from './coupon';
import { getCouponWalletListEpic } from './couponWallet';
import { cpCheckContactDuplicateEpic } from './cpCheckContactDuplicate';
import cPDashboard from './cPDashboard';
import cpNotificationPopupEpic from './cpNotificationPopup';
import { getCpProductInterestData } from './cpProductInterestData';
import eventBookingEpic from './eventBooking';
import { getFreeGiftDataEpic } from './getFreeGiftData';
import { getHelpMeDecide } from './helpMeDecide';
import loyaltyEpic from './loyalty';
import myAccountEpic from './myAccount';
import { getAddressDataSourcesEpic } from './myAddress';
import myAppointmentEpic from './myAppointment';
import myOrderEpic from './myOrders';
import { getMyReviewsEpic } from './myReviews';
import personalizeOfferEpic from './personalizeOffer';
import { checkPriorityDeliveryEpic } from './priorityDeliveryEpic';
import productEpic from './product';
import {getProductCatalogueEpic} from './productCatalogue';
import { getProductForRegisterWarrantyDataEpic } from './productForRegisterWarranty';
import productReviewEpic from './productReview';
import productWarrantyEpic from './productWarranty';
import { getLatestMyProductEpic } from './productWarranty/getLatestMyProduct';
import { getMyProductEpic } from './productWarranty/getMyProduct';
import { getUserProductDetailsEpic } from './productWarranty/GetUserProductDetails';
import submitPurchaseOnSpotEpic from './purchaseOnSpot';
import getSearchBoxEpic from './searchBox';
import settingGlobalEpic from './settingGlobal';
import settingGlobalURLEpic from './settingGlobalURL';
import singleSignOn from './singleSignOn';
import spinAndWinEpic from './spinAndWin';
import storeLocatorEpic from './storeLocator';
import { subscribeNewsletterEpic } from './subscribeNewsletterEpic';
import testEpic from './test';
import trackingGoal from './trackingGoal';
import unsubscribeEpic from './unsubscribe';
import updateFreeGiftToCartEpic from './updateFreeGiftToCart';
import pingEpic from './user';

export const rootEpic = combineEpics(
  addWarrantyEpic,
  pingEpic,
  checkoutProcessEpic,
  testEpic,
  productEpic,
  singleSignOn,
  storeLocatorEpic,
  unsubscribeEpic,
  couponsListEpic,
  buyingOptionsEpic,
  commonEpic,
  myOrderEpic,
  myAccountEpic,
  myAppointmentEpic,
  getAddressDataSourcesEpic,
  getSearchBoxEpic,
  submitPurchaseOnSpotEpic,
  settingGlobalEpic,
  settingGlobalURLEpic,
  getCouponWalletListEpic,
  getMyProductEpic,
  cPDashboard,
  getClientTellingPortalCustomerEpic,
  searchClientTellingPortalCustomerEpic,
  clienttelingPortal,
  getContactPersonalInformationEpic,
  endSessionEpic,
  updateCPCustomerContactEpic,
  getCouponWalletEpic,
  updateCPCustomerContactInterestEpic,
  productReviewEpic,
  getCpProductInterestData,
  getUserProductDetailsEpic,
  getLatestMyProductEpic,
  removeCpProductInterest,
  productWarrantyEpic,
  spinAndWinEpic,
  getCategoryForRegisterWarrantyDataEpic,
  getProductForRegisterWarrantyDataEpic,
  getMyReviewsEpic,
  campaignRegister,
  cpNotificationPopupEpic,
  compareProductEpic,
  bookingExperienceEpic,
  subscribeNewsletterEpic,
  checkPriorityDeliveryEpic,
  getFreeGiftDataEpic,
  getHelpMeDecide,
  addFreeGiftToCartEpic,
  updateFreeGiftToCartEpic,
  eventBookingEpic,
  loyaltyEpic,
  trackingGoal,
  addPriorityToCartEpic,
  personalizeOfferEpic,
  getProductCatalogueEpic,
  cpCheckContactDuplicateEpic
);
