import produce from 'immer';

// Import action types
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  productAlsoBought: {},
  isLoading: false,
  error: ''
};

const productAlsoBoughtlReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.GET_CROSS_SALE_ITEM:
      draft.isLoading = true;
      break;

    case productTypes.GET_CROSS_SALE_ITEM_SUCCESS:
      draft.isLoading = false;

      draft.productAlsoBought = deepCopy(action.payload);
      break;

    case productTypes.GET_CROSS_SALE_ITEM_FAILED:
      draft.isLoading = false;

      draft.error = deepCopy(action.error);
    }

    return draft;
  });

export default productAlsoBoughtlReducer;
