import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'POS Order ID Prefix',
  'POS Order Id Text',
  'Title',
  'Invoice Number Text',
  'Warning Title',
  'Warning Message',
  'Yes Label',
  'No Label',
  'Require Order Id Message',
  'Require Invoice Number Message'
];

export default class PurchaseOnTheSpotModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Icon'] = new SitecoreImageModel();
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon')) {
      this['Icon'].getData(dataSource['Icon']);
    }

    return this;
  }
}
