import produce from 'immer';

import RefunableDepositProductModel from '@components/Product/VariantDetail/ProductDetailOverview/models/RefunableDepositProductModel';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as productTypes from '@redux/actions/product/productTypes';
import { PROMO_RENTAL_TYPE } from '@utils/constant';
import { deepCopy } from '@utils/utility.js';

const initState = {
  rentalList: [],
  rentalActiveID: [],
  rentalActive: null,
  rentalSelectedLength: 0,
  productId: null,
  priceExtraInformation: ''
};

const getRentalByID = (rentalID, rentalArray) => {
  return rentalArray.find((item) => item.ProductId.toLowerCase() === rentalID.toLowerCase());
};

const BORentalsReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let newRentalList = [];

    let filterRentalFromCartLine = [];



    switch (action.type) {
    case buyingOptionsTypes.GET_BO_DATA_SUCCESS:
      if (action.payload.ProductSummary.RefunableDepositProduct?.length) {
        action.payload.ProductSummary.RefunableDepositProduct.map(element => {
          newRentalList.push(new RefunableDepositProductModel().getData(deepCopy(element) || {}));
        });

        draft.rentalList = deepCopy(newRentalList);
      }

      draft.priceExtraInformation = action.payload.ProductSummary.PriceExtraInformation || '';

      draft.productId = action.payload.ProductSummary.ProductId;
      break;

    case buyingOptionsTypes.ADD_CHOSEN_BO_RENTAL:
      draft.rentalActiveID = [action.payload];

      draft.rentalActive  = getRentalByID(action.payload, draft.rentalList);

      draft.rentalSelectedLength = draft.rentalActiveID.length;
      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:
      if (action.filteredCartLine.length) {

        filterRentalFromCartLine = action.filteredCartLine[0].PromotionCartLines?.filter(item => item.Type === PROMO_RENTAL_TYPE);

        if (filterRentalFromCartLine.length) {
          draft.rentalActiveID = [action.filteredCartLine[0].ProductId];
  
          draft.rentalActive  = getRentalByID(action.filteredCartLine[0]?.PromotionCartLines[0]?.ProductId, draft.rentalList) || null;
  
          draft.rentalSelectedLength = draft.rentalActiveID.length;

        }

      }
      break;
    }

    return draft;
  });

export default BORentalsReducer;
