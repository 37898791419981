import { ofType } from 'redux-observable';
import { from, merge } from 'rxjs';
import { concatMap, map, mergeMap } from 'rxjs/operators';

import { getMyRewardWidgetInfoFailed, getMyRewardWidgetInfoSuccess } from '@redux/actions/loyalty';
import { GET_MY_REWARD_WIDGET_INFO_START } from '@redux/actions/loyalty/myRewardWidgetInfo/types';
import { getAsObservable } from '@services/genericService';

import { GET_DISPLAY_STATUS_MEMBER_LOYALTY_ENDPOINT, GET_SPEND_AMOUNT_ENDPOINT } from './configs';

export const myRewardWidgetInfoEpic = (action$) =>
  action$.pipe(
    ofType(GET_MY_REWARD_WIDGET_INFO_START),
    mergeMap(() => {
      return from(getAsObservable(GET_SPEND_AMOUNT_ENDPOINT)).pipe(
        concatMap((getSpentAmountRes) => {
          if (getSpentAmountRes.status === 200 || getSpentAmountRes.statusText === 'OK') {
            return merge(
              from(getAsObservable(`${GET_DISPLAY_STATUS_MEMBER_LOYALTY_ENDPOINT}?spentamount=${getSpentAmountRes.data.SpentAmount}`)).pipe(
                map((getDisplayStatus) => {
                  if (getDisplayStatus.status === 200 || getDisplayStatus.statusText === 'OK') {
                    return getMyRewardWidgetInfoSuccess(getDisplayStatus.data);
                  } else {
                    return getMyRewardWidgetInfoFailed(getDisplayStatus.data || 'Failed');
                  }
                })
              )
            );
          } else {
            return getMyRewardWidgetInfoFailed(getSpentAmountRes.data || 'Failed');
          }
        })
      );
    })
  );
