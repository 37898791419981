import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';

import CartLineApiModel from './CartLineApiModel';
import DeliveryOptionsModel from './DeliveryOptionsModel';
import PaymentConfigurationModel from './PaymentConfigurationModel';

export default class CartDetailApiModel {
  constructor() {
    this.Errors = [];

    this.CartLineId = '';

    this.Lines = [];

    this.Subtotal = '';

    this.TaxTotal = '';

    this.SavingTotal = '';

    this.Total = '';

    this.OriginalTotal = '';

    this.ShippingTotal = '';

    this.TotalAmount = '';

    this.Discount = '';

    this.PromoCodes = [];

    this.Payments = [];

    this.Shipments = [];

    this.Party = null;

    this.DeliveryOptions = [];

    this.BillingAddress = {};

    this.Staff = {};

    this.IsFreeship = false;

    this.AppliedOffers = [];

    this.PaymentMethodName = '';

    this.PromoCodes = [];

    this.LineDiscountAmount = 0;

    this.SFExpressTotal = '';

    this.SFExpressTotalAmount = 0;

    this.IsInstallment = false;

    this.CardType = '';

    this.PaymentMethodName = '';

    this.DeliveryTotal = '';

    this.DeliveryTotalAmount = '';

    this.TrackingNumber = '';

    this.OrderStatus = '';

    this.OrderDateStr = '';

    this.OrderStatusDisplayName = '';

    this.SpecialRequest = '';

    this.LineFinalTotalAmount = 0;

    this.InstallmentPeriod = '';

    this.PaymentConfiguration = new PaymentConfigurationModel();

    this.RefundSitecoreOrderId = '';

    this.RefundOrderEntityId = '';

    this.SubtotalAmount = 0;

    this.Success = null;

    this.Currency = '';

    this.ShippingTotalAmount = 0;

    this.FreeGiftPopup = null;

    this.OsimSelectedPaymentOption = null;

    this.PriorityDelivery = null;

    this.IsPosOrder = false;

    this.CartStatus = '';

    this.CustomerName = '';

    this.CreatedDate = '';

    this.ExpiredDate = '';

    this.SalesOrderId = '';

    this.Name = '';

    this.Milestone = null;

    this.ContactId = null;

    this.SaleOrderContactId = '';

    this.CreatedDateWithFormat = '';

    this.ExpiredDateWithFormat = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SalesOrderId')) {
      this.SalesOrderId = dataSource.SalesOrderId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExpiredDate')) {
      this.ExpiredDate = dataSource.ExpiredDate;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CreatedDate')) {
      this.CreatedDate = dataSource.CreatedDate;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CustomerName')) {
      this.CustomerName = dataSource.CustomerName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CartStatus')) {
      this.CartStatus = dataSource.CartStatus;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsPosOrder')) {
      this.IsPosOrder = dataSource.IsPosOrder;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TrackingNumber')) {
      this.TrackingNumber = dataSource.TrackingNumber;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OrderStatus')) {
      this.OrderStatus = dataSource.OrderStatus;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OrderStatusDisplayName')) {
      this.OrderStatusDisplayName = dataSource.OrderStatusDisplayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SFExpressTotal')) {
      this.SFExpressTotal = dataSource.SFExpressTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SFExpressTotalAmount')) {
      this.SFExpressTotalAmount = dataSource.SFExpressTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DeliveryTotal')) {
      this.DeliveryTotal = dataSource.DeliveryTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DeliveryTotalAmount')) {
      this.DeliveryTotalAmount = dataSource.DeliveryTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Errors')) {
      this.Errors = dataSource.Errors ? dataSource.Errors : this['Errors'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CartLineId')) {
      this.CartLineId = dataSource.CartLineId ? dataSource.CartLineId : this.CartLineId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Lines')) {
      if (dataSource?.Lines?.length) {
        dataSource.Lines.map((item) => {
          this.Lines.push(new CartLineApiModel().getData(item || {}));
        });
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DeliveryOptions')) {
      if (dataSource?.DeliveryOptions?.length) {
        dataSource.DeliveryOptions.map((item) => {
          this.DeliveryOptions.push(new DeliveryOptionsModel().getData(item || {}));
        });
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Subtotal')) {
      this.Subtotal = dataSource.Subtotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TaxTotal')) {
      this.TaxTotal = dataSource.TaxTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total')) {
      this.Total = dataSource.Total;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ShippingTotal')) {
      this.ShippingTotal = dataSource.ShippingTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalAmount')) {
      this.TotalAmount = dataSource.TotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Discount')) {
      this.Discount = dataSource.Discount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromoCodes')) {
      this.PromoCodes = dataSource.PromoCodes;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Payments')) {
      this.Payments = dataSource.Payments;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Shipments')) {
      this.Shipments = dataSource.Shipments;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Party')) {
      this.Party = dataSource.Party;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OriginalTotal')) {
      this.OriginalTotal = dataSource.OriginalTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SavingTotal')) {
      this.SavingTotal = dataSource.SavingTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BillingAddress')) {
      const billingAddressRawData = { ...dataSource.BillingAddress };

      this.BillingAddress = {
        ...billingAddressRawData,
        PhoneNumber: billingAddressRawData?.PhoneNumber
          ? isAtSiteName(MY_SITE_NAME)
            ? `+${billingAddressRawData.PhoneNumber}`
            : billingAddressRawData.PhoneNumber
          : ''
      };
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Staff') && dataSource.Staff) {
      this.Staff = dataSource.Staff;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsFreeship') && dataSource.IsFreeship) {
      this.IsFreeship = dataSource.IsFreeship;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AppliedOffers') && dataSource.AppliedOffers) {
      this.AppliedOffers = dataSource.AppliedOffers;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PaymentMethodName') && dataSource.PaymentMethodName) {
      this.PaymentMethodName = dataSource.PaymentMethodName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromoCodes') && dataSource.PromoCodes) {
      this.PromoCodes = dataSource.PromoCodes;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineDiscountAmount') && dataSource.LineDiscountAmount) {
      this.LineDiscountAmount = dataSource.LineDiscountAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentPeriod') && dataSource.InstallmentPeriod) {
      this.InstallmentPeriod = dataSource.InstallmentPeriod;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsInstallment')) {
      this.IsInstallment = dataSource.IsInstallment;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CardType')) {
      this.CardType = dataSource.CardType;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineFinalTotalAmount')) {
      this.LineFinalTotalAmount = dataSource.LineFinalTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OrderDateStr') && dataSource.OrderDateStr !== '') {
      let orderDate = new Date(dataSource.OrderDateStr + 'Z');

      //INFO: en-SG is Singapore locale using for convert date to dd/mm/yyyy
      this.OrderDateStr = `${orderDate.toLocaleDateString('en-SG')} ${orderDate.toLocaleTimeString()}`;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SpecialRequest')) {
      this.SpecialRequest = dataSource.SpecialRequest;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PaymentConfiguration')) {
      this['PaymentConfiguration'].getData(dataSource['PaymentConfiguration']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SubtotalAmount')) {
      this.SubtotalAmount = dataSource.SubtotalAmount ?? this.SubtotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RefundSitecoreOrderId')) {
      this.RefundSitecoreOrderId = dataSource.RefundSitecoreOrderId ?? this.RefundSitecoreOrderId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RefundOrderEntityId')) {
      this.RefundOrderEntityId = dataSource.RefundOrderEntityId ?? this.RefundOrderEntityId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Success')) {
      this.Success = dataSource.Success ?? this.Success;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Currency')) {
      this.Currency = dataSource.Currency ?? this.Currency;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FreeGiftPopup')) {
      this.FreeGiftPopup = dataSource.FreeGiftPopup ?? this.FreeGiftPopup;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OsimSelectedPaymentOption')) {
      this.OsimSelectedPaymentOption = dataSource.OsimSelectedPaymentOption ?? this.OsimSelectedPaymentOption;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PriorityDelivery')) {
      this.PriorityDelivery = dataSource.PriorityDelivery ?? this.PriorityDelivery;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Milestone')) {
      this.Milestone = dataSource.Milestone ?? this.Milestone;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ContactId')) {
      this.ContactId = dataSource.ContactId ?? this.ContactId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SaleOrderContactId')) {
      this.SaleOrderContactId = dataSource.SaleOrderContactId ?? this.SaleOrderContactId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CreatedDateWithFormat')) {
      this.CreatedDateWithFormat = dataSource.CreatedDateWithFormat ?? this.CreatedDateWithFormat;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExpiredDateWithFormat')) {
      this.ExpiredDateWithFormat = dataSource.ExpiredDateWithFormat ?? this.ExpiredDateWithFormat;
    }

    return this;
  }
}
