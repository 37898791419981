import './SocialLoginsWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { useDispatch, useSelector } from 'react-redux';

import { toogleLinkFacebook, toogleLinkGoogle } from '@redux/actions/singleSignOn';
import {TYPE_FACEBOOK,TYPE_GOOGLE} from '@redux/epic/singleSignOn/config';
import {Text} from '@sitecore-jss/sitecore-jss-react';

import SocialLoginsWidgetModel from './models/SocialLoginsWidgetModel';

const SocialLoginsWidget = ({fields}) => {
  
  const [dataSources, setDataSources] = useState(null);

  useEffect(() => {
    setDataSources(new SocialLoginsWidgetModel().getData(fields || {}));
  }, []);

  const userInfoReducer = useSelector(state => state.singleSignOnReducer.userInfoReducer?.accountUser);
  const errMessage = useSelector(state => state.singleSignOnReducer.loginReducer.error?.errorMessage);
  const settingGlobal = useSelector(state => state.settingGlobalReducer.settingGlobal);

  const dispatch = useDispatch();
  
  const onLoginHandler = (response, type) => {
    if (response?.accessToken && type === TYPE_FACEBOOK) {      
      dispatch(toogleLinkFacebook({
        Type: TYPE_FACEBOOK,
        AccessToken: response.accessToken,
      }));
    }
    if (response?.accessToken && type === TYPE_GOOGLE) {      
      dispatch(toogleLinkGoogle({
        Type: TYPE_GOOGLE,
        AccessToken: response.accessToken,
      }));
    }
  };

  const onDisableLinkSocialHanler = (type) => {
    if (type === TYPE_FACEBOOK) {      
      dispatch(toogleLinkFacebook({
        Type: TYPE_FACEBOOK,
      }));
    }
    if (type === TYPE_GOOGLE) {      
      dispatch(toogleLinkGoogle({
        Type: TYPE_GOOGLE,
      }));
    }
  };

  const onCustomLoginFbHandler = () => {
    window.FB.login( (response) => {
      if (response.status === 'connected') {
        // Logged into your webpage and Facebook.
        onLoginHandler(response?.authResponse, TYPE_FACEBOOK);
      }
    }, { scope: 'public_profile,email' });
  };

  return dataSources ? (
    <div className='my-account-widget social-login-widget'>
      {settingGlobal?.UseFacebookLogin || settingGlobal?.UseGoogleLogin ? <div className='my-account-widget__heading'>
        <h4 className='my-account-widget__heading__title'><Text field={dataSources['Title']}/></h4>
      </div> : ''}
      <div className='my-account-widget__content social-login-widget__content'>
        {settingGlobal?.UseFacebookLogin ? <div className='social-login-widget__item'>
          <div className='social-login-widget__item__img'>
            <img  src={require('@assets/images/icon-facebook.png')} alt='icon social'/>
          </div>
          <div className='social-login-widget__item__text my-account-section__group'>
            <div className='social-login-widget__item__name'>
              <h5 className='social-login-widget__item__name__social'><Text field={dataSources['Facebook Text']}/></h5>
              {userInfoReducer.isLinkFacebook ? <span className='social-login-widget__item__name__user'>{userInfoReducer.FacebookUsername}</span>
                : <span className='social-login-widget__item__status--disable'><Text field={dataSources['Disbale Text']}/></span>}
            </div>
            {!userInfoReducer.isLinkFacebook ?
              <FacebookLogin
                version='10.0'
                isMobile={false}
                autoLoad={false}
                appId={dataSources['Facebook Login Settings']['App ID'].value}
                // callback={(response) => onLoginHandler(response, TYPE_FACEBOOK)}
                // cssClass='my-account-section__btn-link'
                render={() => (
                  <div onClick={onCustomLoginFbHandler} className='my-account-section__btn-link' isDisabled={false}>
                    <Text field={dataSources['Enable Text']} />
                  </div>
                )}
              /> : <span className='social-login-widget__item__status' onClick={() => onDisableLinkSocialHanler(TYPE_FACEBOOK)}>
                <Text field={dataSources['Disbale Text']}/>
              </span>}
          </div> 
        </div> : '' }
        {settingGlobal?.UseGoogleLogin ? <div className='social-login-widget__item'>
          <div className='social-login-widget__item__img'>
            <img  src={require('@assets/images/icon-google.png')} alt='icon social'/>
          </div>
          <div className='social-login-widget__item__text my-account-section__group'>
            <div className='social-login-widget__item__name'>
              <h5 className='social-login-widget__item__name__social'><Text field={dataSources['Google Text']}/></h5>
              {userInfoReducer.isLinkGoogle ? <span className='social-login-widget__item__name__user'>{userInfoReducer.GoogleUsername}</span>
                : <span className='social-login-widget__item__status--disable'><Text field={dataSources['Disbale Text']}/></span>}
            </div>
            {!userInfoReducer.isLinkGoogle ?
              <GoogleLogin
                clientId={dataSources['Google Login Settings']['App ID'].value}
                onSuccess={(response) => onLoginHandler(response, TYPE_GOOGLE)}
                onFailure={onLoginHandler}
                render={(renderProps) => (
                  <button className='my-account-section__btn-link' onClick={renderProps.onClick}>
                    <Text field={dataSources['Enable Text']} />
                  </button>
                )}
              /> : <span className='social-login-widget__item__status' onClick={() => onDisableLinkSocialHanler(TYPE_GOOGLE)}>
                <Text field={dataSources['Disbale Text']}/>
              </span>}
          </div> 
        </div> : ''}
        {errMessage && <div className='error-message'>{errMessage}</div>}
      </div> 
    </div>
  ) : (
    <div className='my-account-widget social-login-widget skeleton__item'>
      <div className='my-account-widget__heading my-account-section__group'>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
      </div>
      <div className='my-account-widget__content'>
        <span className='social-login-widget__item text-line-50'></span>
        <span className='social-login-widget__item text-line-50'></span>
      </div>
    </div>
  );
};

SocialLoginsWidget.propTypes = {
  fields: PropTypes.object
};

export default SocialLoginsWidget;