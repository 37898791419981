import { deepCopy } from '@utils/utility';

export default class EmbeddedHtmlCollectionModel {
  constructor() {
    this['embeddedHtmlComponents'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'embeddedHtmlComponents')) {
      this['embeddedHtmlComponents'] = deepCopy(dataSource['embeddedHtmlComponents']);
    }

    return this;
  }
}
