import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

export const getAnswerSelectedDisplayName = ({ answerValueSelected, questionsList = [] }) => {
  const massageOwnerAnswersList = questionsList?.find((question) => question?.['Question Code'] === 'MassageOwnershipType')?.Answers || [];

  const answerValueSelectedDataDetail = massageOwnerAnswersList.length
    ? massageOwnerAnswersList.find((answer) => answer?.fields?.Answer?.value === answerValueSelected)
    : null;

  return answerValueSelectedDataDetail ? answerValueSelectedDataDetail?.displayName?.toLowerCase() || '' : '';
};

export const useGenerateAnswerValues = () => {
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  const cpCustomerInfo = useMemo(() => {
    return clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo;
  }, [clientTellingPortalCustomer]);

  return useCallback(
    (questionCode, questionsList) => {
      const questionIdKey = `${questionCode}Id`;
      const answerIdsText = cpCustomerInfo?.[questionIdKey];
      let answerTextsList = [];
      if (questionsList?.length && answerIdsText) {
        const questionDetailData = questionsList.find((question) => question?.['Question Code'] === questionCode);
        if (questionDetailData) {
          const answersDataList = questionDetailData.Answers;
          const answerIdsList = answerIdsText.split(', ') || [];
          if (answerIdsList.length) {
            for (const answerId of answerIdsList) {
              const answerSelectedData = answersDataList.find((answer) => answer?.id === answerId);
              if (answerSelectedData) answerTextsList.push(answerSelectedData.fields?.Answer?.value);
            }
          }
        }
      }

      return answerTextsList.length ? answerTextsList.join(', ') : 'N/A';
    },
    [cpCustomerInfo]
  );
};
