import produce from 'immer';

import * as config from '@components/Search/ProductSearchResult/configs';
import ProductListingApiModel from '@components/Search/ProductSearchResult/model/ProductListingApiModel';
import * as commonTypes from '@redux/actions/common/commonTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  productList: null,
  facets: null,
  paramsRequest: null,
  filterLastest: null,
  productCount: null,
  resetPaging: false,
  error: '',
  isLoading: true,
  productCountTotalItemsSearch: 0
};

const deleteObjKey = (obj, prop) => {
  let res = Object.assign({}, obj);

  delete res[prop];

  return JSON.stringify(res);
};

const productSearchReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let tempProductList = null;

    switch (action.type) {
    // SEARCH
    case commonTypes.COMMON_SEARCH_PRODUCT:
      draft.isLoading = true;
      break;

    case commonTypes.COMMON_SEARCH_POLICY:
      draft.isLoading = true;
      break;

    case commonTypes.COMMON_SEARCH_WELLNESS:
      draft.isLoading = true;
      break;

    case commonTypes.COMMON_SEARCH_PRODUCT_FILTER:
      draft.isLoading = true;
      break;

    case commonTypes.COMMON_SEARCH_PRODUCT_SUCCESS:
      tempProductList = new ProductListingApiModel().getData(deepCopy(action.payload.responses.data));

      draft.productList = tempProductList.ChildProducts;

      draft.productCount = tempProductList.TotalItems;

      draft.productCountTotalItemsSearch = tempProductList.TotalItemsSearch;

      draft.resetPaging =
          deleteObjKey(draft.paramsRequest, 'Page') === deleteObjKey(action.payload.responses.config.params, 'Page')
            ? draft.resetPaging
            : !draft.resetPaging;

      // Pagination use this props
      draft.paramsRequest = deepCopy(action.payload.responses.config.params);

      // If not apply filter
      if (!action.payload?.isFilter && tempProductList.Facets?.length) {
        const maxPriceList = tempProductList.Facets.find((item) => item.Name === config.STEP_RANGE_MAX_PRICE);
        const minPriceList = tempProductList.Facets.find((item) => item.Name === config.STEP_RANGE_MIN_PRICE);

        const mathMaxPriceList = Math.max(...maxPriceList.Details.map((price) => +price.Value));
        const mathMinPriceList = Math.min(...minPriceList.Details.map((price) => +price.Value));

        draft.filterLastest = null;

        draft.facets = {
          Facets: tempProductList.Facets,
          Price: {
            min: mathMinPriceList,
            max: mathMaxPriceList !== mathMinPriceList ? mathMaxPriceList : mathMaxPriceList + 1
          }
        };
      } else {
        const paramsFilterRequest = deepCopy(action.payload.responses.config.params);

        //Filter Popup use this props
        draft.filterLastest = {
          ...paramsFilterRequest,
          Price: {
            min: paramsFilterRequest.PriceFrom,
            max: paramsFilterRequest.PriceFrom !== paramsFilterRequest.PriceTo ? paramsFilterRequest.PriceTo : paramsFilterRequest.PriceFrom + 1
          }
        };
      }

      draft.isLoading = false;

      break;

    case commonTypes.COMMON_SEARCH_PRODUCT_FAILED:
      draft.isLoading = false;

      draft.error = action.error;

      break;

      // AUTO COMPLETE
    case commonTypes.GET_AUTO_COMPLETE_PRODUCT:
      draft.isAutoCompleteLoading = true;

      break;

    case commonTypes.GET_AUTO_COMPLETE_PRODUCT_SUCCESS:
      draft.productSuggestionList = action.payload;

      draft.isAutoCompleteLoading = false;

      break;

    case commonTypes.GET_AUTO_COMPLETE_PRODUCT_FAILED:
      draft.error = action.error;

      draft.isAutoCompleteLoading = false;

      break;

    case commonTypes.COMMON_SEARCH_WELLNESS_SUCCESS:
      draft.isLoading = false;
      break;

    case commonTypes.COMMON_SEARCH_POLICY_SUCCESS:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default productSearchReducer;
