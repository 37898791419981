import PropTypes, { object } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Loading from '@components/Share/Loading';
import { DRAFT_STATUS_KEY } from '@redux/actions/cpSaleOrderStatuses/configs';
import { getCurrentSOCart } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { useRemoveSaleOrder } from './useRemoveSaleOrder';

const REMOVING_SO_CART_KEY = 'idle';
const REMOVED_SO_CART_FAILED_KEY = 'failed';
const REMOVED_SO_CART_SUCCESS_KEY = 'success';

const DeleteSaleOrderButton = ({ layoutData, cartData }) => {
  const dispatch = useDispatch();
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const removeSaleOrderService = useRemoveSaleOrder();
  const [removeSOCartStatus, setRemoveSOCartStatus] = useState('');
  const [previousUrl, setPreviousUrl] = useState('');

  useEffect(() => {
    if (document.referrer) setPreviousUrl(document.referrer);
  }, []);

  useDidUpdateEffect(() => {
    const redirectSOListingLink = layoutData?.['Redirect Sales Order Listing Link']?.value?.href;
    if (previousUrl && redirectSOListingLink && removeSOCartStatus === REMOVED_SO_CART_SUCCESS_KEY) {
      setTimeout(() => {
        if (previousUrl.includes(redirectSOListingLink)) {
          window.location.href = redirectSOListingLink;
        } else {
          if (isClientTelingUser) dispatch(getCurrentSOCart());
        }
      }, 1000);
    }
  }, [previousUrl, layoutData, removeSOCartStatus, isClientTelingUser]);

  const removeSOCartStatusMessage = useMemo(
    () => ({
      [REMOVED_SO_CART_SUCCESS_KEY]: layoutData?.['Delete Sales Order Success Text']?.value,
      [REMOVED_SO_CART_FAILED_KEY]: layoutData?.['Delete Sales Order Fail Text']?.value
    }),
    [layoutData]
  );

  const cartStatus = useMemo(() => {
    return cartData?.CartStatus;
  }, [cartData]);

  const isShowButton = useMemo(() => {
    return cartStatus === DRAFT_STATUS_KEY;
  }, [cartStatus]);

  const handleRemoveSaleOrder = useCallback(async () => {
    if (cartData?.Name) {
      setRemoveSOCartStatus(REMOVING_SO_CART_KEY);
      try {
        const params = {
          cartName: cartData.Name
        };
        const removeSOCartResponse = await removeSaleOrderService(params);

        if (removeSOCartResponse) setRemoveSOCartStatus(REMOVED_SO_CART_SUCCESS_KEY);
        else setRemoveSOCartStatus(REMOVED_SO_CART_FAILED_KEY);
      } catch (error) {
        setRemoveSOCartStatus(REMOVED_SO_CART_FAILED_KEY);
      }
    }
  }, [cartData]);

  return isShowButton ? (
    <div className='group-contents-cp__btn'>
      <button className='btn btn-outline-primary group-contents-cp__btn__text' onClick={handleRemoveSaleOrder}>
        <Text field={layoutData['Delete Sales Order As Draft Text']} />
      </button>
      {removeSOCartStatusMessage?.[removeSOCartStatus] ? (
        <span className='error-invalid'>{removeSOCartStatusMessage[removeSOCartStatus]}</span>
      ) : (
        <></>
      )}
      {removeSOCartStatus === REMOVING_SO_CART_KEY ? <Loading /> : <></>}
    </div>
  ) : (
    <></>
  );
};

DeleteSaleOrderButton.propTypes = {
  layoutData: object,
  cartData: PropTypes.any
};

const mapStateToProps = (state) => {
  const currentDefaultCartData = state.productReducer.cartDetailReducer?.currentCart || null;
  const clientSOCartData = state.personalizeOfferReducer.getClientSOCartReducer.data?.OsimCart || null;

  return {
    cartData: clientSOCartData || currentDefaultCartData
  };
};

export default connect(mapStateToProps)(DeleteSaleOrderButton);
