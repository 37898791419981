import { useCallback } from 'react';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getAsObservable } from '@services/genericService';

export const useCheckPOSAccount = () => {
  return useCallback(() => {
    return new Promise((resolve, reject) => {
      return from(getAsObservable('/Account/GetPOSByCustomerId'))
        .pipe(
          map((res) => {
            if (res.status === 200 || res.data) resolve(res.data);
          }),
          catchError((error) => reject(error || 'Failed'))
        )
        .subscribe();
    });
  }, []);
};
