import './ContentSharingPlaceholder.scss';

import Proptypes from 'prop-types';
import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { ARViewPopup } from '@components/Product/ContentSharingPlaceholder/ARViewPopup';
import ToggleWisList from '@components/Share/ToggleWisList';
import { setVariantSelected } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

const ContentSharingPlaceholder = (props) => {
  const dispatch = useDispatch();
  const { variantActiveDefault } = props;
  const [variantActive, setVariant] = useState(null);
  const productIdCurrent = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.fields?.ProductId?.value;
  const [productId] = useState(productIdCurrent);
  const isGA4 = Global.getIsGA4();
  const contextRouteItem = SitecoreContextFactoryService.getValueContextRouteItem('fields');
  const bundleProductId = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Bundle Detail Path']?.fields?.ProductId?.value;

  useDidUpdateEffect(() => {
    setVariant({
      ...variantActiveDefault,
      ProductId: productId
    });

    dispatch(setVariantSelected({
      ...variantActiveDefault,
      ProductId: productId
    }));
  }, [variantActiveDefault]);

  return (
    <>
      <div className='content-sharing-placeholder group-social'>
        {/* New Sharing Icon */}
        {contextRouteItem?.['Is AR Enabled']?.value ? (
          <div className='icons-component item-tooltip icons-component--AR'>
            {contextRouteItem?.['AR Modal Iframe Link']?.value ? (
              <ARViewPopup />
            ) : contextRouteItem?.['AR Link']?.value ? (
              <Link className='icons-component__link' field={contextRouteItem?.['AR Link']?.value}>
                <Image
                  className='icons-component__img icons-component__img--nomal'
                  field={contextRouteItem?.['AR Icon']}
                />
                <Image
                  className='icons-component__img icons-component__img--active'
                  field={contextRouteItem?.['AR Hover Icon']}
                />
              </Link>
            ) : (
              <>
                <Image
                  className='icons-component__img icons-component__img--nomal'
                  field={contextRouteItem?.['AR Icon']}
                />
                <Image
                  className='icons-component__img icons-component__img--active'
                  field={contextRouteItem?.['AR Hover Icon']}
                />
              </>
            )}
            {contextRouteItem?.['AR Tooltip']?.value ? (
              <div className='item-tooltip__label'>{contextRouteItem?.['AR Tooltip']?.value}</div>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        {contextRouteItem?.['Is 360 Enabled']?.value ? (
          <div className='icons-component item-tooltip icons-component--360'>
            {contextRouteItem?.['360 Link']?.value?.href ? (
              <Link className='icons-component__link' field={contextRouteItem?.['360 Link']?.value}>
                <Image className='icons-component__img icons-component__img--nomal' field={contextRouteItem?.['360 Icon']} />
                <Image className='icons-component__img icons-component__img--active' field={contextRouteItem?.['360 Hover Icon']} />
              </Link>
            ) : (
              <>
                <Image className='icons-component__img icons-component__img--nomal' field={contextRouteItem?.['360 Icon']} />
                <Image className='icons-component__img icons-component__img--active' field={contextRouteItem?.['360 Hover Icon']} />
              </>
            )}
            {contextRouteItem?.['360 Tooltip']?.value ? (
              <div className='item-tooltip__label'>{contextRouteItem?.['360 Tooltip']?.value}</div>
            ):(
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
        <ToggleWisList
          dataProduct={{
            ...variantActive,
            ProductId: productId || bundleProductId
          }}
        />
        <Placeholder name='osim-product-overview' rendering={props.rendering} />
      </div>
    </>
  );
};

ContentSharingPlaceholder.propTypes = {
  rendering: Proptypes.any,
  variantActiveDefault: Proptypes.object
};

const mapStateToProps = (state) => {
  return ({
    variantActiveDefault: state.productReducer.productDetailOverviewReducer.VariantActiveDefault,
  });
};

export default connect(mapStateToProps)(ContentSharingPlaceholder);
