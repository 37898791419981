import './ProductReview.scss';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import {
  getProductsName,
  reviewFormParams,
  serialNumberFormatChecker,
  submitProductReview,
  toggleReviewLogin
} from '@redux/actions/productReview';
import { getLatestReview } from '@redux/actions/productReview';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { HK_SITE_NAME, REFERENCE_NEW_PRODUCT, REFERENCE_PURCHASED_PRODUCT, REVIEW_TEXTAREA_TYPE, TW_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useWindowSize } from '@utils/customsHook/useWindowSize';
import { deepCopy } from '@utils/utility';

import ReviewStarRating from '../ReviewStarRating';
import UploadMultipleFiles from '../UploadMultipleFiles';
import ProductReviewModel from './model/ProductReviewModel';
import ReviewInput from './ReviewInput';

export const PAGE = 1;

export const PAGE_SIZE = 99999;

const ProductReview = ({ fields }) => {
  const DEFAULT_RATE = 5;
  const DEFAULT_RATE_DETAIL = 0;
  const MIN_RATE = 2;
  const REVIEW_RATE = 'REVIEW_RATE';
  const PRODUCT_QUALITY_RATE = 'PRODUCT_QUALITY_RATE';
  const CUSTOMER_SERVICE_RATE = 'CUSTOMER_SERVICE_RATE';
  const DELIVERY_SERVICE_RATE = 'DELIVERY_SERVICE_RATE';
  const HOME_PAGE_LINK = '/';
  const CURRENT_SCREEN_WIDTH = useWindowSize();

  const dispatch = useDispatch();
  const productNameRef = useRef(null);
  const listProductsName = useSelector((state) => state.productReviewReducer.productNameReducer.listProductsName);
  const filesUploadedStore = useSelector((state) => state.productReviewReducer.uploadListFilesReducer.filesUploadedStore);
  const latestReviewReducer = useSelector((state) => state.productReviewReducer.latestReviewReducer);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const reviewerName = latestReviewReducer?.latestReview?.Data?.[0]?.ReviewerName;
  const submitProductReviewReducer = useSelector((state) => state.productReviewReducer.submitProductReviewReducer);
  const thankYouMessage = submitProductReviewReducer.submitedData.ThankYouMessage || 'Thank you';
  const reviewIdAfterSubmitSuccess = submitProductReviewReducer.submitedData.ReviewId || '';
  const isDisableWriteReviewAfterSubmitSuccess = submitProductReviewReducer.submitedData.IsDisableWriteReview;
  const myProductIdAfterSubmitSuccess = submitProductReviewReducer.submitedData.MyProductId;
  const isShowLogin = submitProductReviewReducer.isShowLogin;
  const submitReviewFalse = submitProductReviewReducer.submitReviewFalse;
  const isCheckValidSerialNum = submitReviewFalse.IsFormatChecked;
  const formatCheckedData = submitProductReviewReducer.formatCheckedData;
  const isLoginAPISuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);

  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;

  const [layoutData, setLayoutData] = useState(null);
  const [selectProductName, setSelectProductName] = useState(null);
  const [isNullSelectProductName, setIsNullSelectProductName] = useState(false);
  const [isShowRatingDetail, setIsShowRatingDetail] = useState(false);
  const [recommendFriend, setRecommendFriend] = useState(null);
  const [reviewRate, setReviewRate] = useState(DEFAULT_RATE);
  const [productQualityRate, setProductQualityRate] = useState(null);
  const [customerServiceRate, setCustomerServiceRate] = useState(null);
  const [deliveryServiceRate, setDeliveryServiceRate] = useState(null);
  const [productNameOptions, setProductNameOptions] = useState([]);
  const [references, setReferences] = useState(REFERENCE_NEW_PRODUCT);
  const [productNameLoading, setProductNameLoading] = useState(true);
  const [isShowErrMess, setIsShowErrMess] = useState(false);
  const [isSerialNumFormatCheckedSuccess, setIsSerialNumFormatCheckedSuccess] = useState(false);
  const [isFormikFormSubmitSuccess, setIsFormikFormSubmitSuccess] = useState(false);
  const [isNotNullProductQualityRate, setIsNotNullProductQualityRate] = useState(true);
  const [isNotNullCustomerServiceRate, setIsNotNullCustomerServiceRate] = useState(true);
  const [isNotNullDeliveryServiceRate, setIsNotNullDeliveryServiceRate] = useState(true);
  const reviewStarPositionRef = useRef(null);
  const DEFAULT_MINIMUM_TITLE_CHAR = currentSiteName === HK_SITE_NAME || currentSiteName === TW_SITE_NAME ? 2 : 5;
  const DEFAULT_MINIMUM_NAME_CHAR = currentSiteName === HK_SITE_NAME || currentSiteName === TW_SITE_NAME ? 1 : 6;

  useEffect(() => {
    dispatch(getLatestReview());
  }, []);

  useEffect(() => {
    if (submitReviewFalse?.Messages?.[0]) {
      if (!isCheckValidSerialNum) {
        setIsShowErrMess(false);
      } else {
        setIsShowErrMess(true);
      }
    }
  }, [submitReviewFalse]);

  useEffect(() => {
    const model = new ProductReviewModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useEffect(() => {
    dispatch(
      getProductsName({
        page: PAGE,
        pageSz: PAGE_SIZE
      })
    );
  }, []);

  useEffect(() => {
    const referencesReview = new URL(window.location.href).searchParams.get('reference');
    if (referencesReview) {
      setReferences(referencesReview);
    }
  }, []);

  useEffect(() => {
    const thankYouMessageLocal = JSON.parse(window.localStorage.getItem('thankYouMessage')) || '';
    const reviewIdWriteReviewSuccessLocal = JSON.parse(window.localStorage.getItem('reviewIdWriteReviewSuccess'));
    const myProductIdReviewSuccessLocal = JSON.parse(window.localStorage.getItem('myProductIdAfterSubmitSuccess'));
    if (thankYouMessageLocal) {
      window.localStorage.removeItem('thankYouMessage');
    }
    if (reviewIdWriteReviewSuccessLocal) {
      window.localStorage.removeItem('reviewIdWriteReviewSuccess');
    }
    if (myProductIdReviewSuccessLocal) {
      window.localStorage.removeItem('myProductIdAfterSubmitSuccess');
    }

    window.localStorage.removeItem('isDisableWriteReviewAfterSubmitSuccess');
  }, []);

  useDidUpdateEffect(() => {
    if (submitProductReviewReducer.submitProductReviewSuccess) {
      window.localStorage.setItem('thankYouMessage', JSON.stringify(thankYouMessage));

      window.localStorage.setItem('reviewIdWriteReviewSuccess', JSON.stringify(reviewIdAfterSubmitSuccess));

      window.localStorage.setItem('isDisableWriteReviewAfterSubmitSuccess', JSON.stringify(isDisableWriteReviewAfterSubmitSuccess));

      myProductIdAfterSubmitSuccess && window.localStorage.setItem('myProductIdAfterSubmitSuccess', JSON.stringify(myProductIdAfterSubmitSuccess));
      if (fields?.['Review Submit Link']?.value?.href) {
        window.location.href = fields?.['Review Submit Link']?.value?.href;
      }
    }
  }, [submitProductReviewReducer.submitProductReviewSuccess]);

  useDidUpdateEffect(() => {
    const result = listProductsName?.reduce((init, product) => {
      init.push({
        label: product.ProductName,
        value: product.ProductName,
        ProductPageId: product.ProductPageId,
        CommerceProductId: product.CommerceProductId
      });

      return init;
    }, []);

    setProductNameOptions((prevState) => prevState.concat(result));

    const commerceProductId = new URL(window.location.href).searchParams.get('commerceProductId');

    const commerceProductList = result.filter((product) => product.CommerceProductId === commerceProductId);

    if (commerceProductId && commerceProductList.length) {
      setSelectProductName(commerceProductList[0]);
    }

    setProductNameLoading(false);
  }, [listProductsName]);

  useDidUpdateEffect(() => {
    setIsSerialNumFormatCheckedSuccess(formatCheckedData?.IsFormatChecked);
    //INFO: IsFormatChecked: true => format checked is success 
  }, [formatCheckedData]);

  useDidUpdateEffect(() => {
    if (isFormikFormSubmitSuccess) {
      if (formik.values.serialNumber) {
        if (isSerialNumFormatCheckedSuccess) {
          actionSubmitReview();
        }
      } else {
        actionSubmitReview();
      }
    }
  }, [isSerialNumFormatCheckedSuccess, isFormikFormSubmitSuccess]);

  useDidUpdateEffect(() => {
    window.scrollTo({
      top: 0, //INFO: scroll to top
      behavior: 'smooth'
    });
  }, [isShowLogin]);

  const REGEX_CHECK_YOUR_NAME = /^[ A-Za-z!$%^*()\\/\|_@./#&+-]*$/;
  const REGEX_CHECK_YOUR_NAME_CHINESE_LANGUAGE = /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g;
  const minimumTitleChar = Number(layoutData?.['Review Title Minimum Character Number']?.value) || DEFAULT_MINIMUM_TITLE_CHAR;
  const minimumNameChar = Number(layoutData?.['Your Name Minimum Character Number']?.value) || DEFAULT_MINIMUM_NAME_CHAR;

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      yourName: reviewerName || '',
      serialNumber: '',
      reviewTitle: '',
      reviewContent: '',
      recommendFriend: ''
    },
    validationSchema: Yup.object({
      reviewTitle: Yup.string()
        .required(<Message messageCode='Msg_022' />)
        .trim()
        .min(minimumTitleChar, layoutData?.['Review Title Minimum Character Message']?.value?.replace('<<number_character>>', minimumTitleChar))
        .trim(),
      yourName: Yup.string()
        .required(<Message messageCode='Msg_025' />)
        .trim()
        .min(minimumNameChar, layoutData?.['Your Name Minimum Character Message']?.value?.replace('<<number_character>>', minimumNameChar))
        .trim()
        .test('yourName', messageObj?.['Msg_033'], (value) => {
          if (value) {
            if (value.match(REGEX_CHECK_YOUR_NAME_CHINESE_LANGUAGE)) return true;
            else return value.match(REGEX_CHECK_YOUR_NAME);
          }
        })
    }),
    onSubmit: () => {
      if (selectProductName) {
        if (isShowRatingDetail) {
          if (isNotNullProductQualityRate && isNotNullCustomerServiceRate && isNotNullDeliveryServiceRate) {
            setIsFormikFormSubmitSuccess(true);
          }
        } else {
          setIsFormikFormSubmitSuccess(true);
        }
      }
    }
  });

  const onRating = (starRate) => {
    switch (starRate.rateName) {
    case REVIEW_RATE:
      setReviewRate(starRate.rate);
      if (starRate.rate <= MIN_RATE) {
        setIsShowRatingDetail(true);
      } else {
        setIsShowRatingDetail(false);
      }
      break;

    case PRODUCT_QUALITY_RATE:
      setProductQualityRate(starRate.rate);

      setIsNotNullProductQualityRate(true);
      break;

    case CUSTOMER_SERVICE_RATE:
      setCustomerServiceRate(starRate.rate);

      setIsNotNullCustomerServiceRate(true);
      break;

    case DELIVERY_SERVICE_RATE:
      setDeliveryServiceRate(starRate.rate);

      setIsNotNullDeliveryServiceRate(true);
      break;

    default:
      break;
    }
  };

  const onChangeMessage = (e) => {
    formik.handleChange(e);
  };

  const onChangeProduct = (product) => {
    setIsNullSelectProductName(false);

    if (product.value === 'Product Name') {
      setSelectProductName(null);
    } else {
      setSelectProductName(product);
    }
    
    const commerceProductSelectedId = product?.CommerceProductId.replace(/{|}/g, '');
    const commerceProductId = new URL(window.location.href).searchParams.get('commerceProductId')?.replace(/{|}/g, '');
    if (commerceProductSelectedId !== commerceProductId) {
      setReferences(REFERENCE_NEW_PRODUCT);
    }
  };

  const onChangeRecommend = (value) => {
    setRecommendFriend(value);
  };

  const checkReviewFormSubmit = () => {
    if (selectProductName) {
      setIsNullSelectProductName(false);
    } else {
      setIsNullSelectProductName(true);
    }

    // INFO: use for show validate message of review star
    if (isShowRatingDetail) {
      setIsNotNullProductQualityRate(Boolean(productQualityRate));

      setIsNotNullCustomerServiceRate(Boolean(customerServiceRate));

      setIsNotNullDeliveryServiceRate(Boolean(deliveryServiceRate));
    }
    if (!productQualityRate || !customerServiceRate || !deliveryServiceRate) {
      const HEADER_HEIGHT = CURRENT_SCREEN_WIDTH >= 768 ? 72 : 55;

      window.scrollTo({
        top: reviewStarPositionRef?.current.getBoundingClientRect().top + window.scrollY - HEADER_HEIGHT,
        behavior: 'smooth'
      });
    }

    if (formik.values.serialNumber && selectProductName && currentSiteName !== 'OHK') {
      dispatch(serialNumberFormatChecker({
        serialNo: formik.values.serialNumber,
        productId: selectProductName?.ProductPageId
      }));
    }

    if (!formik.values.serialNumber || currentSiteName === 'OHK') {
      setIsSerialNumFormatCheckedSuccess(true);
    }
  };

  const handleCancelSubmit = () => {
    const prevUrlPage = JSON.parse(window.sessionStorage.getItem('urlPage'))?.prevUrlPage;

    window.location.href = prevUrlPage || HOME_PAGE_LINK;
  };

  const actionSubmitReview = () => {
    // const referencesIdReview = new URL(window.location.href).searchParams.get('referenceId') || '';
    // const orderIdReview = new URL(window.location.href).searchParams.get('OrderId') || '';
    const myProductId = new URL(window.location.href).searchParams.get('MyProductId') || '';
    let formData = new window.FormData();

    const tmpParams = {
      ProductPageId: selectProductName?.ProductPageId,
      ProductName: selectProductName?.value,
      MediaFiles: filesUploadedStore,
      ReviewTitle: formik.values.reviewTitle,
      ReviewDescription: formik.values.reviewContent,
      IsRecommend: recommendFriend,
      ReviewerName: formik.values.yourName,
      ReviewRate: reviewRate,
      ProductQualityRate: reviewRate <= MIN_RATE ? productQualityRate : '',
      CustomerServiceRate: reviewRate <= MIN_RATE ? customerServiceRate : '',
      DeliveryServiceRate: reviewRate <= MIN_RATE ? deliveryServiceRate : '',
      References: references,
      MyProductId: myProductId
    };
    if (currentSiteName === 'OHK') tmpParams.OrderNumber = formik.values.serialNumber;
    if (currentSiteName !== 'OHK') tmpParams.ProductSerialNumber = formik.values.serialNumber.trim();

    let params = {...tmpParams};

    // if (referencesIdReview && references === REFERENCE_PURCHASED_PRODUCT) {
    //   params = {
    //     ...tmpParams,
    //     ReferenceId: referencesIdReview,
    //     OrderId: orderIdReview
    //   };
    // }

    for (let keyParams in params) {
      if (keyParams == 'MediaFiles') {
        params[keyParams].forEach((item, index) => {
          for (let keyItem in item) {
            formData.append(`MediaFiles[${index}][${keyItem}]`, item[keyItem]);
          }
        });
      } else {
        formData.append(keyParams, params[keyParams]);
      }
    }
    if (IsAuthenticated) {
      dispatch(submitProductReview(formData));
    } else {
      if (isLoginAPISuccess) {
        dispatch(submitProductReview(formData));
      } else {
        dispatch(toggleReviewLogin(true));

        dispatch(reviewFormParams(params));
      }
    }
  };

  return (
    layoutData && (
      <div>
        {!isShowLogin && (
          <>
            <div className='write-review-form'>
              <div className='container'>
                <form className='write-review-form__form' onSubmit={formik.handleSubmit}>
                  <div className='form-group'>
                    <Select
                      id='productName'
                      name='productName'
                      onChange={(product) => onChangeProduct(product)}
                      ref={productNameRef}
                      value={selectProductName}
                      options={productNameOptions}
                      className='customization-dropdown customization-dropdown--lg-gray'
                      classNamePrefix='customization-dropdown'
                      placeholder={layoutData['Product Name Placeholder Text'].value}
                      isSearchable={true}
                      isLoading={productNameLoading}
                    />
                    {isNullSelectProductName && (
                      <span className='error-validate'>
                        <Message messageCode='Msg_017' />
                      </span>
                    )}
                  </div>
                  <div className='form-group'>
                    <input
                      type='text'
                      id='serialNumber'
                      className='form-control form-control-lg'
                      name='serialNumber'
                      placeholder={layoutData['Product Serial Number Placeholder Text'].value}
                      onChange={formik.handleChange}
                      value={formik.values.serialNumber}
                    />
                    {formik.values.serialNumber
                      ? !formatCheckedData?.IsFormatChecked && <span className='error-validate'>{formatCheckedData?.Messages?.[0]}</span>
                      : ''}
                  </div>
                  <div className='form-group'>
                    <div className='write-review-form__form__rating'>
                      <ReviewStarRating rateName={REVIEW_RATE} defaultRate={DEFAULT_RATE} onRating={onRating} />
                    </div>
                    <div ref={reviewStarPositionRef} className={`write-review-form__form__rating-detail${isShowRatingDetail ? ' show' : ''} `}>
                      <Text field={layoutData['Low Rate Title']} className='write-review-form__form__rating-detail__label' tag='h4' />
                      <div className='write-review-form__form__rating-detail__list'>
                        <div className='rating-detail__container'>
                          <div className='rating-detail-item'>
                            <Text field={layoutData['Product Quality']} className='rating-detail-item__text' tag='p' />
                            <div className='rating-detail-item__star'>
                              <ReviewStarRating rateName={PRODUCT_QUALITY_RATE} defaultRate={DEFAULT_RATE_DETAIL} onRating={onRating} />
                            </div>
                          </div>
                          {!isNotNullProductQualityRate && (
                            <span className='error-validate'>
                              <Message messageCode='Msg_022_3' />
                            </span>
                          )}
                        </div>
                        <div className='rating-detail__container'>
                          <div className='rating-detail-item'>
                            <Text field={layoutData['Customer Service']} className='rating-detail-item__text' tag='p' />
                            <div className='rating-detail-item__star'>
                              <ReviewStarRating rateName={CUSTOMER_SERVICE_RATE} defaultRate={DEFAULT_RATE_DETAIL} onRating={onRating} />
                            </div>
                          </div>
                          {!isNotNullCustomerServiceRate && (
                            <span className='error-validate'>
                              <Message messageCode='Msg_022_4' />
                            </span>
                          )}
                        </div>
                        <div className='rating-detail__container'>
                          <div className='rating-detail-item'>
                            <Text field={layoutData['Delivery Service']} className='rating-detail-item__text' tag='p' />
                            <div className='rating-detail-item__star'>
                              <ReviewStarRating rateName={DELIVERY_SERVICE_RATE} defaultRate={DEFAULT_RATE_DETAIL} onRating={onRating} />
                            </div>
                          </div>
                          {!isNotNullDeliveryServiceRate && (
                            <span className='error-validate'>
                              <Message messageCode='Msg_022_5' />
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <UploadMultipleFiles
                      layoutData={layoutData}
                      imageSizeError={<Message messageCode='Msg_019_3' />}
                      videoSizeError={<Message messageCode='Msg_019_4' />}
                      imageFormatError={<Message messageCode='Msg_026_1' />}
                      videoFormatError={<Message messageCode='Msg_026_2' />}
                    />
                  </div>
                  <div className='form-group'>
                    <ReviewInput
                      inputMaxLength={Number(layoutData['Title Remaining Characters'].value)}
                      inputId={'reviewTitle'}
                      placeholder={layoutData['Review Title Placeholder Text'].value}
                      remainingText={layoutData['Remaining Characters Text'].value}
                      onChangeMessage={onChangeMessage}
                      inputValue={formik.values.reviewTitle}
                    />
                    {formik.errors.reviewTitle && formik.touched.reviewTitle && <span className='error-validate'>{formik.errors.reviewTitle}</span>}
                  </div>
                  <div className='form-group'>
                    <ReviewInput
                      type={REVIEW_TEXTAREA_TYPE}
                      inputMaxLength={Number(layoutData['Content Remaining Characters'].value)}
                      inputId={'reviewContent'}
                      placeholder={layoutData['Review Content Placeholder Text'].value}
                      remainingText={layoutData['Remaining Characters Text'].value}
                      onChangeMessage={onChangeMessage}
                      inputValue={formik.values.reviewContent}
                    />
                  </div>
                  <div className='form-group form-group--gender'>
                    <Text field={layoutData['Recommend Question Text']} tag='label' />
                    <div>
                      <div className='osim-radio'>
                        <input
                          type='radio'
                          id='yesRecommend'
                          name='recommendFriend'
                          value='true'
                          className='osim-radio-input'
                          onChange={(e) => onChangeRecommend(e.target.value)}
                        />
                        <label htmlFor='yesRecommend' className='osim-radio-label' />
                        <Text htmlFor='yesRecommend' className='osim-radio-title' field={layoutData['Yes Text']} tag='label' />
                      </div>

                      <div className='osim-radio'>
                        <input
                          type='radio'
                          id='noRecommend'
                          name='recommendFriend'
                          value='false'
                          className='osim-radio-input'
                          onChange={(e) => onChangeRecommend(e.target.value)}
                        />
                        <label htmlFor='noRecommend' className='osim-radio-label' />
                        <Text htmlFor='noRecommend' className='osim-radio-title' field={layoutData['No Text']} tag='label' />
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <ReviewInput
                      inputMaxLength={Number(layoutData['Your Name Remaining Characters'].value)}
                      inputId={'yourName'}
                      placeholder={layoutData['Your Name Placeholder Text'].value}
                      remainingText={layoutData['Remaining Characters Text'].value}
                      noteText={layoutData['Note Your Name Text'].value}
                      onChangeMessage={onChangeMessage}
                      inputValue={formik.values.yourName}
                    />
                    {formik.errors.yourName && formik.touched.yourName && <span className='error-validate'>{formik.errors.yourName}</span>}
                  </div>
                  <div className='form-group form-group--submit-agreement'>
                    <RichText 
                      field={layoutData?.['Submit Agreement']}
                      className='write-review-form__form__submit-agreement' 
                    />
                  </div>
                  <div className='form-group'>
                    <div className='write-review-form__form__btn text-center'>
                      <button type='submit' className='btn btn-primary' onClick={() => checkReviewFormSubmit()}>
                        {IsAuthenticated ? (
                          <Text field={layoutData['Submit Button Text']} />
                        ) : isLoginAPISuccess ? (
                          <Text field={layoutData['Submit Button Text']} />
                        ) : (
                          <Text field={layoutData['Continue With Login Button Text']} />
                        )}
                      </button>
                      <span className='btn-link-text link-nomal' onClick={() => handleCancelSubmit()}>
                        <Text field={layoutData['Cancel Button Text']} />
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
            {submitProductReviewReducer.isLoading ? <LoadingMask></LoadingMask> : null}
          </>
        )}
        {latestReviewReducer?.isLoading ? <LoadingMask></LoadingMask> : null}
        <ModalComponent isShow={isShowErrMess} onCloseHandler={() => setIsShowErrMess(false)}>
          <div className='submit-error text-center'>{submitReviewFalse?.Messages?.[0]}</div>
          <div className='submit-error__btn text-center'>
            <Link field={layoutData['My Products Button Link']} className='btn btn-primary'>
              <Text field={layoutData['My Products Button Text']} />
            </Link>
            <p
              className='btn-link-text link-normal'
              onClick={() => {
                setIsShowErrMess(false);

                setIsSerialNumFormatCheckedSuccess(false);
                if (isShowLogin) {
                  dispatch(toggleReviewLogin(false));
                }
              }}
            >
              <Text field={layoutData['Cancel Button Text']} />
            </p>
          </div>
        </ModalComponent>
      </div>
    )
  );
};

ProductReview.propTypes = {
  fields: PropTypes.object
};

export default ProductReview;
