import FacetsItemModel from './FacetsItemModel';

export default class FacetsApiModel {
  constructor() {
    this.Name = '';

    this.Details = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Details')) {
      if (dataSource['Details']?.length) {
        dataSource['Details'].map((item) => {
          this['Details'].push(new FacetsItemModel().getData(item || {}));
        });
      }
    }

    return this;
  }
}
