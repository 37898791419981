import './Cart.scss';

import PropTypes, { number, string } from 'prop-types';
import React, { Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { useGetIsAddedToCartValue } from '@components/BuyingOptions/hooks/useGetIsAddedToCartValue';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import OmnichatTracking from '@components/Share/OmnichatTracking';
import { validateInstallmentAndCheckout } from '@redux/actions/cartDetail';
import { applyCouponCode } from '@redux/actions/coupon';
import { editSOProductCartControls } from '@redux/actions/cpCartDetails';
import { VIEWED_STATUS_KEY } from '@redux/actions/cpSaleOrderStatuses/configs';
import { DRAFT_STATUS_KEY } from '@redux/actions/cpSaleOrderStatuses/configs';
import { getCurrentSOCart, getGiftingOption } from '@redux/actions/product';
import { getCartNameValue } from '@redux/epic/product/cartDetail/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Placeholder, Text } from '@sitecore-jss/sitecore-jss-react';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE,HK_SITE_NAME, REDIRECT_CHECKOUT_PARAM, TW_SITE_NAME } from '@utils/constant';
import { useDepositValidationCart } from '@utils/customsHook/useDepositValidationCart';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import global from '@utils/global';

import cpCartDetailsReducer from '../../../redux/reducers/cpCartDetails';
import CPPageHeader from '../../ClientPortal/CPPageHeader';
import SaveSOSuccessBody from '../../ClientPortal/SaveSaleOrderSuccess/SaveSOSuccessBody';
import FreeGiftItem from '../../FreeGiftPopup/FreeGiftItem';
import SelectingFreeGiftProvider from '../../FreeGiftPopup/SelectingFreeGiftProvider';
import LoadingMask from '../../Share/LoadingMask';
import { getUserFullNameWithoutFormat } from '../MyAccount/utils';
import CartDetailModal from './CartDetailModal/index';
import { behaviorSubjectCheckout$ } from './cartDetailSubject';
import CartEmty from './CartEmty';
import CartItem from './CartList/CartItem';
import CartListShimmer from './CartListShimmer';
import CartMilstone from './CartMilstone';
import { useCheckCTASOCart } from './hooks';
import { useDoTrackingCartEvents } from './hooks/useDoTrackingCartEvents';
import CartDetailModel from './models/CartDetailModel';

const QUANTITY = '{quantity}';

export const CART_DETAIL_PAGE_ID = 'cart-detail-section-page';

const CartDetails = (props) => {
  const dispatch = useDispatch();

  const {
    fields,
    currentCart,
    isLogin,
    cartSellerStatus,
    errorItemId,
    giftingOptions,
    isBlockInstallment,
    isGettingCartDelivery,
    isChangedDeliveryOption
  } = props;
  const [dataSources, setDatasources] = useState(null);
  const [totalQuantity, setTotal] = useState(0);
  const [firstTimeLoading, setFirstTimeLoading] = useState(true);
  const [isOutOfStock, setOutOfStock] = useState(false);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const isTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('HKOmnichat');
  const isTWTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('TWOmnichat');
  const isGA4 = Global.getIsGA4();
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const isAddedToCartValue = useGetIsAddedToCartValue();
  const doTrackingCartEvents = useDoTrackingCartEvents();
  const { search } = useLocation();
  const [isShow, setShow] = useState(false);
  const currentCartCustom = useSelector((state) => state?.productReducer?.cartDetailReducer?.currentCartCustom);
  const [isPopup, setIsPopup] = useState(false);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const cpSessionId = SitecoreContextFactoryService.getValueContextItem('cpSessionId') || '';
  const { isEditingSOCart } = useSelector((state) => state.cpCartDetailsReducer);
  const CartStatus = useSelector((state) => state?.productReducer?.cartDetailReducer?.currentCartCustom?.CartStatus);
  const contactData = useSelector((state) => state?.clientTellingPortalCustomer?.contacts[0]?.json_facet);
  const checkDisableCTASOCartService = useCheckCTASOCart();
  const isValidateDepositCart = useDepositValidationCart();

  const isDisabledSOCartButton = useMemo(() => {
    return checkDisableCTASOCartService(CartStatus);
  }, [CartStatus]);

  useEffect(() => {
    const isDepositFreeGift = currentCartCustom?.NormalListProduct?.every((item) => item.IsDepositFreeGift);

    if (currentCartCustom?.NormalListProduct?.length > 0 && currentCartCustom?.DepositListProduct?.length > 0 && isValidateDepositCart) {
      if(isDepositFreeGift == true) {
        setIsPopup(false);
      }else {
        setIsPopup(true);
      }
    } else if (currentCartCustom?.DepositListProduct?.length > 1) {
      setIsPopup(true);
    } else {
      setIsPopup(false);
    }
  }, [currentCartCustom, isValidateDepositCart]);

  const onShowHandler = () => {
    setShow(true);
  };

  const onCloseHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    setDatasources(new CartDetailModel().getData(fields || {}));
  }, []);

  useEffect(() => {
    dispatch(getGiftingOption());
  }, []);

  useEffect(() => {
    if (errorItemId && errorItemId !== '') {
      let element = document.getElementById(errorItemId);

      const y = element.getBoundingClientRect().top + window.pageYOffset - 150;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [errorItemId]);

  useEffect(() => {
    if (isAddedToCartValue) {
      // INFO: track all events that relate to cart
      doTrackingCartEvents();
    }
  }, [isAddedToCartValue, doTrackingCartEvents]);

  const checkInstallment = () => {
    dispatch(validateInstallmentAndCheckout(true));
  };

  const handleOnClickCheckOut = () => {
    if (!currentCart.Party) {
      behaviorSubjectCheckout$.next({ isForceSelectArea: true });

      let element = document.getElementById('area-error');
      const y = element.getBoundingClientRect().top + window.pageYOffset - 150;

      window.scrollTo({ top: y, behavior: 'smooth' });
    } else {
      behaviorSubjectCheckout$.next({ isForceSelectArea: false });
    }
    if (!cartSellerStatus) {
      let element = document.getElementById('cart-seller');

      if (element) {
        const y = element.getBoundingClientRect().top + window.pageYOffset - 150;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  const onHandleTrackingCode = () => {
    if (currentCart) {
      //Old Logic GA
      // INF: Google analytics
      // let products = [];

      // window.dataLayer = window.dataLayer || [];

      // if (currentCart.Lines.length) {
      //   currentCart.Lines.forEach((line) => {
      //     products.push({
      //       name: line?.DisplayName,
      //       id: line?.ProductId,
      //       price: line?.LineFinalTotalAmount.toString(),
      //       category: line?.CategoryName,
      //       quantity: parseInt(line?.Quantity)
      //     });
      //   });
      // }

      // let obj = {
      //   ecommerce: {
      //     checkout: {
      //       actionField: { step: 1 },
      //       products: products
      //     }
      //   },
      //   event: 'checkout',
      //   eventCallback: function () {
      //     window.location = !isLogin ? dataSources['Checkout Link'].Link.value.href : dataSources['Fulfillment Link'].Link.value.href;
      //   },
      //   eventTimeout: 2000
      // };

      // window.dataLayer.push(obj);
      // new GoogleAnalytic().ggGateWay('event', 'checkout', obj);

      //New Logic ON/OFF GA, GA4
      if (isGA4) {
        const cartLines = currentCart?.Lines.map((line) => ({
          ...line,
          itemListName:
            Global.getGA4Params()?.[line.VariantId] || SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Page Title']?.value
        }));

        const params = {
          ecommerce: {
            currency: currentCart?.Currency || '',
            value: currentCart?.TotalAmount,
            items: Global.getItems(cartLines || [], currentCart?.PromoCodes?.toString() || '', '', currentCart?.Currency || '')
          }
        };

        new GoogleAnalytic().gAnalytic4('event', 'begin_checkout', params);
      } else {
        let products = [];

        window.dataLayer = window.dataLayer || [];

        if (currentCart.Lines.length) {
          currentCart.Lines.forEach((line) => {
            products.push({
              name: line?.DisplayName,
              id: line?.ProductId,
              price: line?.LineFinalTotalAmount.toString(),
              category: line?.CategoryName,
              quantity: parseInt(line?.Quantity)
            });
          });
        }

        let obj = {
          ecommerce: {
            checkout: {
              actionField: { step: 1 },
              products: products
            }
          },
          event: 'checkout',
          eventCallback: function () {
            window.location = !isLogin ? dataSources['Checkout Link'].Link.value.href : dataSources['Fulfillment Link'].Link.value.href;
          },
          eventTimeout: 2000
        };

        window.dataLayer.push(obj);
      }
    }
  };

  const handleCheckoutTracking = () => {
    // INFO: Omnichat Tracing for HK or TW site
    if ((currentSiteName === HK_SITE_NAME && isTurnOnOmnichat || currentSiteName === TW_SITE_NAME && isTWTurnOnOmnichat) && currentCart) {
      let products = [];

      if (currentCart.Lines.length > 0) {
        currentCart.Lines.forEach((line) => {
          products.push({
            id: line?.ProductId || '',
            name: line?.DisplayName || '',
            brand: 'Osim',
            category: line?.CategoryName || '',
            variant: line.VariantId || '',
            quantity: parseInt(line?.Quantity) || '',
            price: line?.LineFinalTotalAmount.toString()
          });
        });
      }

      new OmnichatTracking().tracking('event', 'checkout', { items: products });
    }
  };

  const onHandleCheckoutTracking = () => {
    // INFO: Google analytics
    onHandleTrackingCode();

    // INFO: Omnichat Tracing for HK site
    handleCheckoutTracking();
  };

  const handleCheckoutButton = useCallback(
    (checkoutLink, fulfilmentLink) => {
      // INFO: func tracking
      onHandleCheckoutTracking();
      //INFO: redirect url
      if (!isLogin) {
        if (settingGlobal?.IsDisableGuestCheckout) {
          return (window.location.href = `${settingGlobal?.LoginLink}?${REDIRECT_CHECKOUT_PARAM}=${checkoutLink.value.href}`);
        } else {
          return (window.location.href = checkoutLink?.value?.href);
        }
      } else {
        return (window.location.href = fulfilmentLink?.value?.href);
      }
    },
    [isLogin, settingGlobal?.IsDisableGuestCheckout, settingGlobal?.LoginLink, currentCart]
  );

  const renderCheckOUtButton = () => {
    return isPopup ? (
      <>
        <button className='btn btn-primary' onClick={() => onShowHandler()}>
          <Text field={!isLogin ? dataSources['Checkout Link'].Title : dataSources['Fulfillment Link'].Title} />
        </button>
        <CartDetailModal isShow={isShow} onCloseHandler={onCloseHandler} ondataSources={dataSources} />
      </>
    ) : dataSources['Checkout Link'].Title.value || dataSources['Fulfillment Link'].Title.value ? (
      !cartSellerStatus ? (
        <button className='btn btn-primary' onClick={() => handleOnClickCheckOut()}>
          <Text field={!isLogin ? dataSources['Checkout Link'].Title : dataSources['Fulfillment Link'].Title} />
        </button>
      ) : isBlockInstallment ? (
        <button className='btn btn-primary' onClick={() => checkInstallment()}>
          <Text field={!isLogin ? dataSources['Checkout Link'].Title : dataSources['Fulfillment Link'].Title} />
        </button>
      ) : (
        <button
          className='btn btn-primary'
          onClick={() => handleCheckoutButton(dataSources['Checkout Link'].Link, dataSources['Fulfillment Link'].Link)}
        >
          <Text field={!isLogin ? dataSources['Checkout Link'].Title : dataSources['Fulfillment Link'].Title} />
        </button>
      )
    ) : !cartSellerStatus ? (
      <button className='btn btn-primary' onClick={() => handleOnClickCheckOut()} />
    ) : (
      <Link
        className='btn btn-primary'
        field={!isLogin ? dataSources['Checkout Link'].Link : dataSources['Fulfillment Link'].Link}
        onClick={() => onHandleCheckoutTracking()}
      />
    );
  };

  useDidUpdateEffect(() => {
    setFirstTimeLoading(false);
    if (currentCart.Lines?.length) {
      let couter = 0;
      const quantityArray = currentCart.Lines.map((lineItem) => +lineItem.Quantity);

      setTotal(quantityArray.reduce((total, quantity) => total + quantity));

      currentCart.Lines.map((lineItem) => {
        const lineStatus = lineItem.LineStatus.find((item) => item.Code === 'OutOfStock');
        if (lineStatus) {
          couter = couter + 1;
        }
      });

      setOutOfStock(couter ? true : false);
    } else {
      setTotal(0);
    }
  }, [currentCart]);

  const cartItemData = (cartItem) => {
    const giftingOption = giftingOptions?.GiftingOptions.find((gift) => gift.MainItemId === cartItem.ExternalCartLineId);

    return giftingOption ? { ...cartItem, giftingOption } : cartItem;
  };

  useDidUpdateEffect(() => {
    if (currentCart?.Lines) {
      //Old Logic GA
      // const params = {
      //   items: Global.getItemList(currentCart.Lines)
      // };

      // new GoogleAnalytic().ggGateWay('event', 'view_cart', params);

      //New Logic ON/OFF GA, GA4
      if (isGA4) {
        const params = {
          ecommerce: {
            currency: currentCart?.Currency || '',
            value: currentCart?.TotalAmount,
            items: Global.getItemsCart(currentCart?.Lines || [], currentCart?.PromoCodes?.toString() || '', '', currentCart?.Currency || '')
          }
        };

        new GoogleAnalytic().gAnalytic4('event', 'view_cart', params);
      } else {
        const params = {
          items: Global.getItemList(currentCart.Lines)
        };

        new GoogleAnalytic().ggGateWay('event', 'view_cart', params);
      }
    }
  }, [currentCart]);

  useEffect(() => {
    if (search) {
      const urlSearchParams = new URLSearchParams(search);
      const couponValue = urlSearchParams.get('couponCode');

      if (couponValue) {
        dispatch(applyCouponCode({ code: couponValue, reCallPromotionsApplied: true }));
      }
    }
  }, [search]);

  useEffect(() => {
    if(isClientTelingUser) {
      dispatch(getCurrentSOCart());
    }
  }, []);

  useEffect(() => {
    if (isClientTelingUser) {
      if (!isDisabledSOCartButton) dispatch(editSOProductCartControls({ isEdit: Boolean(cpSessionId) }));
      else dispatch(editSOProductCartControls({ isEdit: false }));
    }
  }, [cpSessionId, isClientTelingUser, isDisabledSOCartButton]);

  const isGettingDeliveryData = useMemo(() => {
    return !isChangedDeliveryOption ? isGettingCartDelivery : false;
  }, [isGettingCartDelivery, isChangedDeliveryOption]);

  const handleTitleCp = () => {
    if (dataSources && contactData) {
      const firstName = contactData?.Personal?.FirstName || '';
      const lastName = contactData?.Personal?.LastName || '';
      const fullName = getUserFullNameWithoutFormat({ firstName, lastName });
      const cartTitle = dataSources?.['CP Cart Title']?.value || '';
      const customerNameTitle = dataSources?.['CP Customer Name Title']?.value || '';

      // if (firstName || lastName) {
      // return `${cartTitle} ${firstName} ${lastName}`.trim();
      if (fullName) {
        return `${cartTitle} ${fullName}`.trim();
      } else {
        return `${cartTitle} ${customerNameTitle}`.trim();
      }
    }
  };

  return !firstTimeLoading && !isGettingCartDelivery && dataSources ? (
    <div id={CART_DETAIL_PAGE_ID} className='cart-detail animated-slow fadeIn delay-100'>
      {isClientTelingUser ? (
        <CPPageHeader
          title={handleTitleCp()}
          type={!isEditingSOCart && !isDisabledSOCartButton && currentCart?.Lines?.length ? 'button' : ''}
          onEdit={() => dispatch(editSOProductCartControls({ isEdit: true }))}
          layoutData={dataSources}
          isShowBackBtn={true}
          // type={'cart-icon'}
        />
      ) : (
        <></>
      )}
      <Fragment>
        {!isClientTelingUser ? (
          <div className='cart-head-info'>
            <div className='container'>
              <div className='cart-head-info__container'>
                <h4 className='cart-head-info__heading'>
                  <Text field={dataSources.Title} />
                </h4>
                <div className='cart-head-info__infor'>
                  <Text className='cart-head-info__infor__label' tag='span' field={dataSources['Cart Total Amount Label']} />
                  <span className='cart-head-info__infor__price'>{currentCart?.Total ?? '0.00'}</span>
                  <span className='cart-head-info__infor__quantity'>
                    (
                    {totalQuantity > 1
                      ? dataSources['Quantity Items Label'].value.replace(QUANTITY, totalQuantity)
                      : dataSources['Quantity Item Label'].value.replace(QUANTITY, totalQuantity)}
                    )
                  </span>
                </div>
                <CartMilstone milestoneData={dataSources?.['Promotion Milestone']} />
                <Placeholder name='osim-suggest-freeship-cart' rendering={props.rendering} />
                <div className='cart-head-info__button'>
                  {currentCart?.Lines?.length && !isOutOfStock ? (
                    renderCheckOUtButton()
                  ) : (
                    <button className='cart-head-info__link btn btn-disable'>
                      <Text field={!isLogin ? dataSources['Checkout Link'].Title : dataSources['Fulfillment Link'].Title} />
                    </button>
                  )}
                </div>
                {dataSources['Additional Link'].Title.value ? (
                  <Link className='cart-head-info__link btn-link' field={dataSources['Additional Link'].Link}>
                    <Text field={dataSources['Additional Link'].Title} />
                  </Link>
                ) : (
                  <Link className='cart-head-info__link btn-link' field={dataSources['Additional Link'].Link} />
                )}
              </div>
            </div>
          </div>
        ) : isDisabledSOCartButton ||
          (!cpSessionId && getCartNameValue() && currentCart?.CartStatus === VIEWED_STATUS_KEY) ||
          currentCart?.CartStatus === DRAFT_STATUS_KEY ? (
            <div className='cart-detail__cp-order-detail'>
              <SaveSOSuccessBody
                layoutData={dataSources}
                cartData={{
                  SalesOrderId: currentCart?.SalesOrderId || '',
                  CreatedDate: global.convertJSONDateToNumber(currentCart?.CreatedDate) || '',
                  CreatedDateWithFormat: currentCart?.CreatedDateWithFormat || '',
                  ExpiredDate: global.convertJSONDateToNumber(currentCart?.ExpiredDate) || '',
                  ExpiredDateWithFormat: currentCart?.ExpiredDateWithFormat || '',
                  CartLineLength: currentCart?.Lines?.length,
                  CartStatus: currentCart?.CartStatus || 0
                }}
                isShowOrderSummary={false}
              />
            </div>
          ) : (
            <></>
          )}
        {currentCart?.Lines?.length ? (
          <div className='cart-list'>
            <div className='container'>
              {currentCart.Lines.map((lineItem, index) => (
                <div className='cart-list__item' key={lineItem.ExternalCartLineId}>
                  {/*{lineItem.IsAutoAddFreeGiftPopup || lineItem.IsFreeGiftPopup ? (*/}
                  {/*  <FreeGiftItem*/}
                  {/*    key={index}*/}
                  {/*    dataSources={dataSources}*/}
                  {/*    freeGiftName={lineItem.DisplayName}*/}
                  {/*    freeGiftThumbnail={lineItem.Image}*/}
                  {/*    lineFinalTotal={lineItem.LineFinalTotal}*/}
                  {/*    productPageLink={lineItem.ProductPageLink}*/}
                  {/*    promotionTitle={lineItem.PromotionText}*/}
                  {/*    isEnableEdit={!lineItem.IsAutoAddFreeGiftPopup}*/}
                  {/*    isOutOfStock={lineItem.IsFreeGiftPopup && lineItem.StockStatus.Value === 2}*/}
                  {/*    isFreeGiftPopupCartPage={lineItem.IsFreeGiftPopupCartPage}*/}
                  {/*    freeGiftData={lineItem}*/}
                  {/*  />*/}
                  {/*) : (*/}
                  <CartItem
                    dataSources={dataSources}
                    deliveryOptions={currentCart.DeliveryOptions}
                    cartItemInfo={cartItemData({ ...lineItem, cartId: currentCart?.Name || '' })}
                    currencyInformation={giftingOptions?.CurrencyInformation}
                    lineId={lineItem.ExternalCartLineId}
                    indexGA4={index}
                  />
                  {/*)}*/}
                </div>
              ))}
            </div>
          </div>
        ) : (
          <CartEmty onDataSources={dataSources} />
        )}
        {isGettingCartDelivery && isChangedDeliveryOption ? <LoadingMask /> : <></>}
      </Fragment>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <CartListShimmer />
    </LoadingShimmer>
  );
};

CartDetails.propTypes = {
  fields: PropTypes.object,
  currentCart: PropTypes.object,
  giftingOptions: PropTypes.object,
  rendering: PropTypes.any,
  isLogin: PropTypes.any,
  isLoading: PropTypes.bool,
  cartSellerStatus: PropTypes.bool,
  errorItemId: PropTypes.string,
  isBlockInstallment: PropTypes.bool,
  isGettingCartDelivery: PropTypes.bool,
  isChangedDeliveryOption: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentCart: state.productReducer.cartDetailReducer.currentCart,
  giftingOptions: state.productReducer.cartDetailReducer.giftingOptions,
  showShippingAreaError: state.productReducer.cartDetailReducer.checkShippingArea,
  errorItemId: state.productReducer.cartDetailReducer.errorItemId,
  isLoading: state.productReducer.cartDetailReducer.isLoading,
  isLogin: state.singleSignOnReducer.userInfoReducer?.isLogin,
  cartSellerStatus: state.productReducer.cartDetailReducer.cartSellerStatus,
  isBlockInstallment: state.productReducer.cartDetailReducer.blockInstallment,
  isGettingCartDelivery: state.productReducer.cartDetailReducer.isGettingCartDelivery,
  isChangedDeliveryOption: state.productReducer.cartDetailReducer.isChangedDeliveryOption,
});

export default connect(mapStateToProps)(CartDetails);
