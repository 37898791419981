import { ofType } from 'redux-observable';
import { forkJoin } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';

import * as actions from '@redux/actions/compareProduct';
import * as compareProductTypes from '@redux/actions/compareProduct/compareProductTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from '../config';

const getListDataProducts = (productIds) => {
  const apiCalls = [];

  productIds.forEach((id) => apiCalls.push(getAsObservable(config.ACTION_GET_PRODUCT_INFO, { productPageId: id }).toPromise()));

  return forkJoin(apiCalls);
};

export const getListProductDefaultEpic = (action$) =>
  action$.pipe(
    ofType(compareProductTypes.GET_LIST_PRODUCT_DEFAULT_START),
    mergeMap((action) => {
      const listProductIds = action.listProductDefault;

      return getListDataProducts(listProductIds).pipe(
        map((res) => {
          const productCompareData = [];

          res.forEach((productInfo) => {
            if (productInfo.status === 200) {
              productCompareData.push({
                id: productInfo.data?.ProductSummary?.Id,
                productVariant: productInfo.data?.ProductSummary?.Variants,
                productInfoDetail: productInfo.data,
                productVariantActive: productInfo.data?.ProductSummary?.Variants[0]
              });
            }
          });
          if (productCompareData.length) {
            return actions.getListProductDefaultSuccess(deepCopy(productCompareData));
          } else {
            return actions.getListProductDefaultFailed('Failed');
          }
        })
      );
    })
  );
