import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/productReview';
import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const customerReviewsAverageEpic = (action$) =>
  action$.pipe(
    ofType(productReviewTypes.GET_CUSTOMER_REVIEWS_AVERAGE),
    switchMap((action) => {
      const params = {
        productId: action.payload.productId
      };

      return getAsObservable(config.ACTION_GET_CUSTOMER_REVIEWS_AVERAGE, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getCustomerReviewsAverageSuccess(deepCopy(res.data));
          } else {
            return actions.getCustomerReviewsAverageFailed('Failed');
          }
        })
      );
    })
  );
