export default class ListPriceModel {
  constructor() {
    this['Amount'] = 0;

    this['Price'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Amount')) {
      this['Amount'] = dataSource['Amount'] ? dataSource['Amount'] : this['Amount'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'] = dataSource['Price'] ? dataSource['Price'] : this['Price'];
    }

    return this;
  }
}
