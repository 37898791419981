import './CategoryProductItem.scss';

import Proptypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react';
import Global from '@utils/global';

const ProductItemOther = ({ inputData,  idxProdGA4 }) => {
  const urlImg = inputData.Images;
  let altImg = 'no image';
  if (urlImg) {
    altImg = Global.getQueryParams(urlImg, 'alt')
  }

  return (
    <Fragment>
      <div className='col-6 col-md-4 col-lg-3 product-detail-header__product-item--mobile'>
        <div className='product-detail-header__product-item'>
          <a className='product-detail-header__product-item__img' href={`${inputData.Path}?IndexGA4=${idxProdGA4}`}>
            {urlImg ? (
              <img src={urlImg} alt={altImg} />
            ) : (
              <></>
            )}
          </a>
          <h4 className='product-detail-header__product-item__name'>
            <a href={`${inputData.Path}?IndexGA4=${idxProdGA4}`}>{inputData.Name}</a>
          </h4>
        </div>
      </div>
      <div className='product-detail-header__product-item--desktop'>
        <div className='product-detail-header__product-item'>
          <a className='product-detail-header__product-item__img' href={`${inputData.Path}?IndexGA4=${idxProdGA4}`}>
            {urlImg ? (
              <img className='swiper-lazy animated' data-src={urlImg} alt={altImg} />
            ) : (
              <></>
            )}
            <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
          </a>
          <h4 className='product-detail-header__product-item__name'>
            <a href={`${inputData.Path}?IndexGA4=${idxProdGA4}`}>{inputData.Name}</a>
          </h4>
        </div>
      </div>
    </Fragment>
  );
};

ProductItemOther.propTypes = {
  inputData: Proptypes.object,
  idxProdGA4: Proptypes.number
};

export default ProductItemOther;
