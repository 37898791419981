import produce from 'immer';

import {
  GET_CATALOG_HEADER_NAVIGATION,
  GET_CATALOG_HEADER_NAVIGATION_FAILED,
  GET_CATALOG_HEADER_NAVIGATION_SUCCESS
} from '../../../actions/productReview/productReviewTypes';

const initialState = {
  isLoading: false,
  catalogHeaderData: [],
  error: null
};

const getCatalogHeaderNavigationReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_CATALOG_HEADER_NAVIGATION:
      draft.isLoading = true;
      break;

    case GET_CATALOG_HEADER_NAVIGATION_SUCCESS:
      draft.isLoading = false;

      draft.catalogHeaderData = action.payload;
      break;

    case GET_CATALOG_HEADER_NAVIGATION_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getCatalogHeaderNavigationReducer;
