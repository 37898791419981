import { combineReducers } from 'redux';

import getClientSOCartReducer from './getClientSOCartReducer';
import getLatestSOCartReducer from './getLatestSOCartReducer';
import getSOCartPagingReducer from './getSOCartPagingReducer';

export const personalizeOfferReducer = combineReducers({
  getLatestSOCartReducer,
  getSOCartPagingReducer,
  getClientSOCartReducer
});
