import produce from 'immer';

import CouponItemModel from '@components/SlicingPage/CartDetails/models/CouponItemModel';
import * as couponTypes from '@redux/actions/coupon/couponTypes';
import * as cartActions from '@redux/actions/product/productTypes';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

const initState = {
  baseCouponList: [],
  appliedCouponList: [],
  isLoading: false,
  couponDetail: {},
  appliedPromotionList: [],
  error: '',
  couponList: [],
  isLoadingMask: false,
  errMsg: '',
  success: null
};

const couponReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let tempCouponList = [];
    let tempAppliedList = [];

    switch (action.type) {
    case couponTypes.GET_BROWSE_COUPON_LIST:
      // draft.isLoading = true;
      break;

    case couponTypes.GET_BROWSE_COUPON_LIST_SUCCESS:
      action.payload.Promotions.map((item) => {
        tempCouponList.push(new CouponItemModel().getData(deepCopy(item) || {}));
      });

      draft.baseCouponList = tempCouponList;

      tempCouponList.sort((a, b) => {
        let tempa = a.ValidityTo.slice(6, a.ValidityTo.length - 2).concat(a.Code);
        let tempb = b.ValidityTo.slice(6, b.ValidityTo.length - 2).concat(b.Code);

        if (tempa < tempb) {
          return -1;
        }
        if (tempa > tempb) {
          return 1;
        }

        return 0;
      });

      draft.couponList = tempCouponList;
      break;

    case couponTypes.GET_BROWSE_COUPON_LIST_FAILED:
      draft.error = action.error;
      break;

    case couponTypes.GET_APPLIED_COUPON_LIST:
      draft.isLoading = true;
      break;

    case couponTypes.GET_APPLIED_COUPON_LIST_SUCCESS:
      tempAppliedList = action.payload.Promotions.filter((item) => item.Code);

      draft.appliedPromotionList = deepCopy(action.payload.Promotions);

      tempAppliedList.forEach((item) => {
        return new CouponItemModel().getData(deepCopy(item) || {});
      });

      draft.appliedCouponList = tempAppliedList;

      draft.isLoading = false;
      break;

    case couponTypes.GET_APPLIED_COUPON_LIST_FAILED:
      draft.error = action.error;
      break;

    case couponTypes.GET_COUPON_DETAIL:
      draft.isLoadingMask = true;
      break;

    case couponTypes.GET_COUPON_DETAIL_SUCCESS:
      draft.couponDetail = deepCopy(action.payload);

      draft.isLoadingMask = false;
      break;

    case couponTypes.GET_COUPON_DETAIL_FAILED:
      draft.error = action.error;

      draft.isLoadingMask = false;
      break;

    case couponTypes.APPLY_COUPON_CODE:
      draft.isLoading = true;
      break;

    case couponTypes.APPLY_COUPON_CODE_SUCCESS:
      draft.isLoading = false;

      draft.success = action.payload;
      break;

    case couponTypes.APPLY_COUPON_CODE_FAILED:
      draft.error = action.error;
      break;

    case couponTypes.APPLY_SO_COUPON_CODE:
      draft.isLoading = true;
      break;

    case couponTypes.APPLY_SO_COUPON_CODE_SUCCESS:
      draft.isLoading = false;

      draft.success = action.payload;
      break;

    case couponTypes.APPLY_SO_COUPON_CODE_FAILED:
      draft.error = action.error;
      break;

    case couponTypes.REMOVE_COUPON_CODE:
      draft.isLoading = true;
      break;

    case couponTypes.REMOVE_COUPON_CODE_SUCCESS:
      draft.isLoading = false;
      break;

    case couponTypes.REMOVE_COUPON_CODE_FAILED:
      draft.error = action.error;
      break;

    case couponTypes.REMOVE_COUPON_DETAIL:
      draft.couponDetail = {};
      break;

    case couponTypes.GET_ERROR_MSG:
      draft.errMsg = action.payload;
      break;

    case cartActions.GET_CURRENT_CART_FAILED:
      draft.isLoading = false;
      break;

    case couponTypes.REMOVE_SO_COUPON_CODE_START:
      draft.isLoading = true;
      break;
    }

    return draft;
  });

export default couponReducer;
