import './BOLoading.scss';

import React from 'react';


const BOLoading = () => {
  return (
    <div className='preloading loading'>
      <div className='load-icon'></div>
    </div>
  );
};

export default BOLoading;


