import './BOTradeIn.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useMemo, useState} from 'react';

import BuyingAccordion from '@components/Share/BuyingAccordion';
import { DEFAULT_SELECT_LIMIT, PROMO_TRADEIN_TYPE_LOYALTY,TRADEIN_EVENT_KEY } from '@utils/constant';
import { useQuery } from '@utils/customsHook/useQuery';

const BOTradeIn = (props) => {
  const { dataFields, tradeInList, tradeInActive, tradeInSelectedLength } = props;
  const [selectedItemLabel, setSelectedItemLabel] = useState('');
  const queryParam = useQuery();

  const isLoyalty = useMemo(() => {
    return JSON.parse(queryParam.get('isLoyalty') || false);
  }, [queryParam]);

  useEffect(() => {
    const priceLabel =
      isLoyalty || tradeInActive?.type === PROMO_TRADEIN_TYPE_LOYALTY
        ? `(-${tradeInActive?.selectedTradeInProduct?.TradeInLoyaltyPriceWithCurrency})`
        : tradeInActive?.selectedTradeInProduct?.PostBuyingOptionTradeInSellingPricePostWithCurrency;

    setSelectedItemLabel(`${tradeInActive?.selectedTradeInProduct.DisplayName} ${priceLabel}`);
  }, [isLoyalty, tradeInActive]);

  return tradeInList?.length ? (
    <div className='tradein-buying buying-option__group-info__line-bottom'>
      <BuyingAccordion
        data={tradeInList}
        headerType={'FIRSTTYPE'}
        eventKey={TRADEIN_EVENT_KEY}
        // isOpenItem={tradeInSelectedLength === DEFAULT_SELECT_LIMIT ? false : true}
        isDefaultTitle={tradeInList.length && tradeInActive && Object.keys(tradeInActive).length ? false : true}
        isOpenModal={false}
        isTradeInOptions={true}
        activeItemsId={tradeInActive}
        selectedItemsName={selectedItemLabel}
        labelText={dataFields['Trade In Label'].value}
        changeText={dataFields['Change Text'].value}
        dataFields={dataFields}
      />
    </div>
  ) : null;
};

BOTradeIn.propTypes = {
  dataFields: Proptypes.any,
  tradeInList: Proptypes.any,
  tradeInActive: Proptypes.any,
  tradeInSelectedLength: Proptypes.any
};

export default BOTradeIn;
