import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { getAsObservable } from '@services/genericService';

import * as config from '../config';

const cPLogoutEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.CP_LOGOUT),
    switchMap(() => {
      return getAsObservable(config.ACTION_CP_LOGOUT).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.cpLogoutSuccess(res.data);
          } else {
            return actions.cpLogoutFailed('Failed');
          }
        })
      );
    })
  );

export default cPLogoutEpic;
