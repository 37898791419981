import { CLEAR_VALIDATE_OTP_CODE_ERROR_MESSAGE, VALIDATE_OTP_CODE_FAILURE, VALIDATE_OTP_CODE_START, VALIDATE_OTP_CODE_SUCCESS } from './types';

export const validateOtpStart = (payload) => ({
  type: VALIDATE_OTP_CODE_START,
  payload
});

export const validateOtpSuccess = (payload) => ({
  type: VALIDATE_OTP_CODE_SUCCESS,
  payload
});

export const validateOtpFailure = (payload) => ({
  type: VALIDATE_OTP_CODE_FAILURE,
  payload
});

export const clearValidateOtpCodeErrorMessage = () => ({
  type: CLEAR_VALIDATE_OTP_CODE_ERROR_MESSAGE
});
