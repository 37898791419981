import * as myAppointmentTypes from './myAppointmentTypes';

export const cancelAppointment = (payload) => ({
  type: myAppointmentTypes.CANCEL_APPOINTMENT,
  payload
});

export const cancelAppointmentSuccess = (payload) => ({
  type: myAppointmentTypes.CANCEL_APPOINTMENT_SUCCESS,
  payload
});

export const cancelAppointmentFailed = (payload) => ({
  type: myAppointmentTypes.CANCEL_APPOINTMENT_FAILED,
  payload
});

export const updateAppointment = (payload) => ({
  type: myAppointmentTypes.UPDATE_APPOINTMENT,
  payload
});

export const updateAppointmentSuccess = (payload) => ({
  type: myAppointmentTypes.UPDATE_APPOINTMENT_SUCCESS,
  payload
});

export const updateAppointmentFailed = (payload) => ({
  type: myAppointmentTypes.UPDATE_APPOINTMENT_FAILED,
  payload
});