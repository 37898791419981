import SitecoreTextModel from '@models/SitecoreTextModel';

export default class FieldsModel {
  constructor() {
    this['Code'] = new SitecoreTextModel();

    this['Value'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Code')) {
      this['Code'].getData(dataSource['Code']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Value')) {
      this['Value'].getData(dataSource['Value']);
    }

    return this;
  }
}
