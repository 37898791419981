import { combineReducers } from 'redux';

import cartDetailReducer from './cartDetail';
import defaultLocatorReducer from './defaultLocator';
import freeGiftReducer from './freeGift';
import notifyProductStatusReducer from './notifyProductStatus';
import productAlsoBoughtlReducer from './productAlsoBought';
import productCountdownTimerReducer from './productCountdownTimer';
import productDetailHeaderReducer from './productDetailHeader';
import productDetailOverviewReducer from './productDetailOverview';
import productInformationReducer from './productInformation';
import productListReducer from './productList';
import productVariantDetail from './productVariantDetail';
import productWishlistReducer from './productWishlist';
import recentlyProductReducer from './recentlyProduct';
import specialDealReducer from './specialDeal';
import getStoreListReducer from './storesList';
import warrantyReducer from './warranty';

export const productReducer = combineReducers({
  productListReducer,
  productVariantDetail,
  productInformationReducer,
  productAlsoBoughtlReducer,
  freeGiftReducer,
  defaultLocatorReducer,
  getStoreListReducer,
  warrantyReducer,
  productWishlistReducer,
  productDetailHeaderReducer,
  productDetailOverviewReducer,
  notifyProductStatusReducer,
  specialDealReducer,
  cartDetailReducer,
  recentlyProductReducer,
  productCountdownTimerReducer
});
