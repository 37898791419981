import React from 'react';

import Loading from '@components/Share/Loading';

const ProductBannerShimmer = () => {

  return (
    <div className='product-banner skeleton__item'>
      <div className='product-banner__image'>
        <Loading></Loading>
      </div>
    </div>
  );
};

export default ProductBannerShimmer;