import PriceApiModel from '@components/Product/models/PriceApiModel';

import ProductSummaryModel from './ProductSummaryModel';

export default class ProductDetailPopUpModel {
  constructor() {
    this.Id = '';

    this.SitecoreId = '';

    this.Image = '';

    this.ImageURLs = [];

    this.DisplayName = '';

    this.ListPrice = '';

    this.SellingPrice = '';

    this.CurrencyCode = '';

    this.Price = new PriceApiModel();

    this.Description = '';

    this.ProductPageUrl = '';

    this.ProductSummary = new ProductSummaryModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this.Id = dataSource.Id;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SitecoreId')) {
      this.SitecoreId = dataSource.SitecoreId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image = dataSource.Image;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ImageURLs')) {
      this.ImageURLs = dataSource.ImageURLs;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayName')) {
      this.DisplayName = dataSource.DisplayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this.ListPrice = dataSource.ListPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice')) {
      this.SellingPrice = dataSource.SellingPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyCode')) {
      this.CurrencyCode = dataSource.CurrencyCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this.Price = dataSource.Price;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductPageUrl')) {
      this.ProductPageUrl = dataSource.ProductPageUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductSummary')) {
      this.ProductSummary.getData(dataSource.ProductSummary);
    }

    return this;
  }
}
