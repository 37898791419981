import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/loyalty/getCrmCustomer/getCrmCustomerTypes';
import { postAsObservable } from '@services/genericService';

import { getCrmCustomerFailed, getCrmCustomerSuccess } from '../../../actions/loyalty/getCrmCustomer';
import { GET_CRM_CUSTOMER } from './configs';

export const getCrmCustomerEpic = (action$) =>
  action$.pipe(
    ofType(actions.GET_CRM_CUSTOMER),
    switchMap((action) => {
      return postAsObservable(GET_CRM_CUSTOMER, action.payload).pipe(
        map((response) => {
          if (response.status === 200 || response.data.Success) {
            return getCrmCustomerSuccess(response.data);
          } else {
            return getCrmCustomerFailed('Failed');
          }
        })
      );
    })
  );

