import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/spinAndWin';
import * as spinAndWinTypes from '@redux/actions/spinAndWin/spinAndWinTypes';
import { getAsObservable } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

import * as config from '../config';

export const getAvailableLuckyDrawEpic = (action$) =>
  action$.pipe(
    ofType(spinAndWinTypes.GET_AVAILABLE_LUCKY_DRAW),
    switchMap((action) => {
      const language = SitecoreContextFactoryService.getValueContextItem('language');

      const params = {
        luckyDrawId: action.payload.luckyDrawId
      };

      if (language) params.language = language;

      return getAsObservable(config.ACTION_GET_AVAILABLE_LUCKY_DRAW, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getAvailableLuckyDrawSuccess(deepCopy(res?.data));
          } else {
            return actions.getAvailableLuckyDrawFailed('Failed');
          }
        })
      );
    })
  );
