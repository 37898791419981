import './BuyingBundleAccordion.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import BundleBOAdditionalOptionsList from '@components/BundleBuyingOptionLayout/BundleBOAdditionalOptions/BundleBOAdditionalOptionsList';
import BundleBOFreeGiftList from '@components/BundleBuyingOptionLayout/BundleBOFreeGifts/BundleBOFreeGiftList';
import BundleBOVariantsList from '@components/BundleBuyingOptionLayout/BundleBOVariants/BundleBOVariantsList';
import BundleBOWarrantyList from '@components/BundleBuyingOptionLayout/BundleBOWarranty/BundleBOWarrantyList';

// import BOWarrantyList from '@components/BuyingOptions/BOWarranty/BOWarrantyList';
import BundleBOFirstTypeHeader from './BundleBOFirstTypeHeader';
import BundleBOSecondTypeHeader from './BundleBOSecondTypeHeader';

const BuyingBundleAccordion = (props) => {
  const { 
    data, 
    parentId,
    headerType,
    eventKey, 
    isOpenItem,
    productId,
    uniqueProductId,
    additionalOptionIndex,

    onAction,
    selectedItemsName,

    labelText, 
    changeText,
    disposalPolicyLabel,
    disposalPolicyPage,
    outStockText,
    errorMsg,
    isShowError,
    dataText,

    variantSelected,
    freeGiftSelected
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  
  useEffect(() => {
    setIsOpen(isOpenItem);
  }, [isOpenItem]);

  const onToogleAccordion = () => {
    
    setIsOpen(!isOpen);
  };

  const listHeaderRender = (type, isOpen, labelText, changeText, selectedItemsName, errorMsg, isShowError) => {
    let ComponentType = listHeader[type];

    if (type === 'FIRSTTYPE') {
      return <ComponentType 
        isOpen={isOpen} 
        labelText={labelText} 
        changeText={changeText} 
        selectedItemsName={selectedItemsName}
        //extra Fnc
        onToogleFnc={onToogleAccordion}
        errorMsg={errorMsg}
        isShowError={isShowError}
        contentData={data}
      />;
    } else {
      return <ComponentType 
        isOpen={isOpen} 
        labelText={labelText} 
        changeText={changeText} 
        secondTypeItemsName={selectedItemsName}
        errorMsg={errorMsg}
        isShowError={isShowError}
        //extra Fnc
        onToogleFnc={onToogleAccordion}
        contentData={data}
      />;
    }

  };

  const listItemRender = (targetKey, data) => {
    
    let ComponentType = listItem[targetKey];

    return <ComponentType 
      parentId={parentId}
      contentData={data}
      dataText={dataText}
      onToogleFnc={onToogleAccordion}
      onAction={onAction}
      outStockText={outStockText}
      variantSelected={variantSelected}
      freeGiftSelected={freeGiftSelected}
      //extra
      productId={productId}
      uniqueProductId={uniqueProductId}
      additionalOptionIndex={additionalOptionIndex}
      disposalPolicyLabel={disposalPolicyLabel}
      disposalPolicyPage={disposalPolicyPage}
    />;
  };

  const listItem = {
    'VARIANT_BUNDLE_EVENT_KEY': BundleBOVariantsList,
    'WARRANTY_BUNDLE_EVENT_KEY': BundleBOWarrantyList,
    'FREEGIFT_BUNDLE_EVENT_KEY': BundleBOFreeGiftList,
    'ADDITIONAL_OPTIONS_EVENT_KEY': BundleBOAdditionalOptionsList
  };

  const listHeader = {
    'FIRSTTYPE' : BundleBOFirstTypeHeader,
    'SECONDTYPE' : BundleBOSecondTypeHeader,
  };

  return data.length && (
    <Accordion activeKey={isOpen === true ? eventKey : -1}>
      <div className='accordion-buying-option'>
        {listHeaderRender(headerType, isOpen, labelText, changeText, selectedItemsName, errorMsg, isShowError)}
        <Accordion.Collapse eventKey={eventKey}>
          {listItemRender(eventKey, data, onAction, disposalPolicyLabel, disposalPolicyPage)}
        </Accordion.Collapse>
      </div>
    </Accordion>
  );
};

BuyingBundleAccordion.propTypes = {
  data: PropTypes.any,
  parentId: PropTypes.any,
  headerType: PropTypes.any,
  eventKey: PropTypes.any,
  isOpenItem: PropTypes.any,
  productId: PropTypes.string,
  uniqueProductId: PropTypes.string,
  additionalOptionIndex: PropTypes.string,

  onAction: PropTypes.any,
  dataText: PropTypes.any,
  selectedItemsName: PropTypes.any, 

  labelText: PropTypes.any,
  changeText: PropTypes.any,
  disposalPolicyLabel: PropTypes.string,
  disposalPolicyPage: PropTypes.string,
  outStockText: PropTypes.any,
  errorMsg: PropTypes.any,
  isShowError: PropTypes.any,
  variantSelected: PropTypes.any,
  freeGiftSelected: PropTypes.any,
};

export default BuyingBundleAccordion;
