import React from 'react';

const CPSearchResultShimmer = () => {
  return (
    <tr className='skeleton__item'>
      <span className='text-line-50 w-100'></span>
    </tr>
  );
};

export default CPSearchResultShimmer;
