import produce from 'immer';

import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';

const initState = {
  storeAddressList: null,
  userAddressList: null,
  isLoading: false,
  countryDelivery: null,
  error: '',
  toogleCheck: false,
};

const addressListReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case checkoutProcessTypes.REMOVE_ADDRESS_BOOK:
      draft.isLoading = true;
      break;

    case checkoutProcessTypes.GET_STORE_ADDRESS_LIST:
      draft.isLoading = true;
      break;

    case checkoutProcessTypes.CHANGE_DEFAULT_ADDRESS_BOOK:
      draft.isLoading = true;
      break;

    case checkoutProcessTypes.GET_STORE_ADDRESS_LIST_SUCCESS:
      draft.isLoading = false;

      draft.storeAddressList = action.payload;
      break;

    case checkoutProcessTypes.GET_STORE_ADDRESS_LIST_FAILED:
      draft.isLoading = false;
      break;

    case checkoutProcessTypes.GET_BOOKED_ADDRESS:
      draft.isLoading = true;
      break;
      
    case checkoutProcessTypes.ADD_NEW_ADDRESS:
      draft.isLoading = true;
      break;

    case checkoutProcessTypes.GET_BOOKED_ADDRESS_SUCCESS:
      draft.isLoading = false;

      draft.userAddressList = action.payload;

      draft.error = '';

      draft.toogleCheck = !draft.toogleCheck;
      break;

    case checkoutProcessTypes.GET_COUNTRY_DELIVERY_SUCCESS:
      draft.isLoading = false;

      draft.countryDelivery = action.payload;
      break;

    case checkoutProcessTypes.GET_BOOKED_ADDRESS_FAILED:
      draft.isLoading = false;

      draft.error = action.error;

      draft.toogleCheck = !draft.toogleCheck;
    }

    return draft;
  });

export default addressListReducer;
