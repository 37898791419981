import { CHECK_PRIORITY_DELIVERY_FAILED, CHECK_PRIORITY_DELIVERY_START, CHECK_PRIORITY_DELIVERY_SUCCESS } from './priorityDeliveryTypes';

export const checkPriorityDeliveryStart = () => ({
  type: CHECK_PRIORITY_DELIVERY_START
});

export const checkPriorityDeliverySuccess = (payload) => ({
  type: CHECK_PRIORITY_DELIVERY_SUCCESS,
  payload
});

export const checkPriorityDeliveryFailed = (payload) => ({
  type: CHECK_PRIORITY_DELIVERY_FAILED,
  payload
});
