import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { useNavigateToStep } from '@components/ProductWarrantyRegistration/hooks/useNavigateToStep';
import { LOGIN_REGISTER_STEP } from '@components/ProductWarrantyRegistration/WarrantyGeneral';
import { addWarranty, setParamAPIData } from '@redux/actions/addWarranty';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

export const initialValues = {
  categoryItemId: '',
  serialNumber: '',
  orderNumber: '',
  datePurchase: '',
  product: {
    productId: '',
    hasSerialNumber: false
  }
};

const useWarrantyRegistrationFormik = (layoutData) => {
  const REGEX_CHECK_SERIAL_NUMBER = /^\d{4}[A-z]{1}(-)\d{2}(-)\d{6}$/g;
  const dispatch = useDispatch();
  const navigateToStep = useNavigateToStep();
  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');

  return useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnChange: false,
    validationSchema: Yup.object({
      categoryItemId: Yup.string().required(layoutData?.['Require Select Category Label']?.value || 'Please select category'),
      product: Yup.object().when('categoryItemId', (categoryItemId) => {
        if (categoryItemId) {
          return Yup.object().shape({
            productId: Yup.string().required(layoutData?.['Require Select Product Label']?.value || 'Please select product')
          });
        }
      }),
      serialNumber: Yup.string().when('product', (product) => {
        if (product.hasSerialNumber) {
          return Yup.string()
            .required(layoutData?.['Require Serial Number Message']?.value)
            .match(REGEX_CHECK_SERIAL_NUMBER, layoutData?.['Invalid Serial Number Message']?.value);
        }
      })
    }),
    onSubmit: (values) => {
      const paramAPIAddWarranty = {
        ProductId: values.product.productId,
        OrderNumber: values.orderNumber,
        MyProductType: 'Warranty Registration',
        MyProductId: ''
      };
      // Add property ProductSerialNumber if selectProduct.HasSerialNumber is truthy
      // sample serial number: '8008X-11-123456'
      if (values.product.hasSerialNumber) paramAPIAddWarranty.ProductSerialNumber = values.serialNumber;
      if (values.datePurchase) paramAPIAddWarranty.OrderDate = new Date(values.datePurchase).toLocaleDateString('en-SG');

      if (IsAuthenticated) {
        dispatch(addWarranty(paramAPIAddWarranty));
      } else {
        // INFO: send paramAPIAddWarranty state to WarrantyRegisterLogin component
        dispatch(setParamAPIData(paramAPIAddWarranty));

        //INFO: move to WarrantyRegisterLogin step
        navigateToStep(LOGIN_REGISTER_STEP);
      }
    }
  });
};

export default useWarrantyRegistrationFormik;
