import produce from 'immer';

import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';

const initState = {
  checkCustomerExist: false,
  isLoading: false,
  error: '',
  isToogleCheckGuest: false,
  isCheckEmailExistBp: null
};

const accountCheckingReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case checkoutProcessTypes.CHECK_COMMERCE_USER:
      draft.checkCustomerExist = false;

      draft.isToogleCheckGuest = false;

      draft.isLoading = true;
      break;

    case checkoutProcessTypes.CHECK_COMMERCE_USER_SUCCESS:
      draft.checkCustomerExist = action.payload;

      draft.isCheckEmailExistBp = action.payload;

      draft.isLoading = false;

      draft.isToogleCheckGuest = !draft.isToogleCheckGuest;
      break;

    case checkoutProcessTypes.CHECK_COMMERCE_USER_FAILED:
      draft.isLoading = false;

      draft.isToogleCheckGuest = !draft.isToogleCheckGuest;
    }

    return draft;
  });

export default accountCheckingReducer;
