import PropTypes from 'prop-types';
import React from 'react';

const LabelComponentForm = (props) => (
  <label className={props.field.model.cssClass} htmlFor={props.field.valueField.id}>
    {props.children}
    {props.field.model.title}
  </label>
);

LabelComponentForm.propTypes = {
  field: PropTypes.object,
  children: PropTypes.any,
};

export default LabelComponentForm;
