import { combineEpics } from 'redux-observable';

import { applyRewardEpic } from './applyReward';
import { getAvailableLuckyDrawEpic } from './getAvailableLuckyDraw';
import { getChanceToWinEpic } from './getChanceToWin';
import { getMyPrizeLuckyDrawEpic } from './getMyPrizeLuckyDraw';
import { getNumberSpinEmailEpic } from './getNumberSpinEmail';

const spinAndWinEpic = combineEpics(
  getAvailableLuckyDrawEpic,
  getNumberSpinEmailEpic,
  applyRewardEpic,
  getMyPrizeLuckyDrawEpic,
  getChanceToWinEpic
);

export default spinAndWinEpic;
