export const STAFF_LEAD_MANAGEMENT = 'STAFF_LEAD_MANAGEMENT';

export const STAFF_LEAD_MANAGEMENT_SUCCESS = 'STAFF_LEAD_MANAGEMENT_SUCCESS';

export const STAFF_LEAD_MANAGEMENT_FAILED = 'STAFF_LEAD_MANAGEMENT_FAILED';

export const MANAGER_LEAD_MANAGEMENT = 'MANAGER_LEAD_MANAGEMENT';

export const MANAGER_LEAD_MANAGEMENT_SUCCESS = 'MANAGER_LEAD_MANAGEMENT_SUCCESS';

export const MANAGER_LEAD_MANAGEMENT_FAILED = 'MANAGER_LEAD_MANAGEMENT_FAILED';

export const GET_SALE_STAFFS = 'GET_SALE_STAFFS';

export const GET_SALE_STAFFS_SUCCESS = 'GET_SALE_STAFFS_SUCCESS';

export const GET_SALE_STAFFS_FAILED = 'GET_SALE_STAFFS_FAILED';