import './BORental.scss';

import Proptypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import BuyingAccordion from '@components/Share/BuyingAccordion';
import { DEFAULT_SELECT_LIMIT, RENTAL_EVENT_KEY } from '@utils/constant';

const BORentals = ({ dataFields, rentalList, rentalActive, rentalSelectedLength, isShowError, setRef }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  return rentalList?.length ? (
    <Fragment>
      <div className='rental-buying buying-option__group-info__line-bottom' ref={setRef}>
        <BuyingAccordion
          data={rentalList}
          headerType={'FIRSTTYPE'}
          eventKey={RENTAL_EVENT_KEY}
          isOpenItem={rentalSelectedLength === DEFAULT_SELECT_LIMIT ? false : true}
          activeItemsId={rentalActive?.ProductId ? [rentalActive.ProductId] : []}
          selectedItemsName={`${dataFields['Refunable Deposit Label'].value} (${rentalActive?.Price?.SellingPriceWithCurrency})` || ''}
          labelText={dataFields['Refunable Deposit Label'].value}
          changeText={dataFields['Change Text'].value}
          errorMsg={messageObj?.Msg_018}
          isShowError={isShowError}
          dataFields={dataFields}
        />
      </div>
    </Fragment>
  ) : null;
};

BORentals.propTypes = {
  dataFields: Proptypes.any,
  rentalList: Proptypes.any,
  rentalActive: Proptypes.any,
  rentalSelectedLength: Proptypes.any,
  isShowError: Proptypes.any,

  setRef: Proptypes.any
};

export default BORentals;
