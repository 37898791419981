import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getSOCartPagingFailure, getSOCartPagingSuccess } from '@redux/actions/personalizeOffer/getSOCartPaging';
import { GET_SO_CART_PAGING_START } from '@redux/actions/personalizeOffer/getSOCartPaging/types';
import { getAsObservable } from '@services/genericService';

import { GET_SO_CART_PAGING_ENDPOINT } from './configs';

export const getSOCartPagingEpic = (action$) =>
  action$.pipe(
    ofType(GET_SO_CART_PAGING_START),
    switchMap((action) => {
      const params = {
        page: action.payload.page,
        pageSz: action.payload.pageSz
      };

      return getAsObservable(GET_SO_CART_PAGING_ENDPOINT, params).pipe(
        map((res) => {
          if (res.status === 200 || res.data?.Success) {
            return getSOCartPagingSuccess(res.data);
          } else {
            return getSOCartPagingFailure(res.data || 'Failed');
          }
        }),
        catchError((error) => of(getSOCartPagingFailure(error)))
      );
    })
  );
