import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { getTrackBackground,Range } from 'react-range';

const PlayerControl = forwardRef(({
  setRef,
  playing,
  played,
  isFullScreen,
  onPlayPause, 
  onSeek,
  onSeekMouseDown,
  onSeekMouseUpDrag,
  onToggleFullScreen
}, ref) => {

  return (
    <div className='video-banner__control' ref={setRef} style={{visibility: 'visible'}}>
      <div style={{
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }}>
        <Range
          step={0.1}
          min={0}
          max={100}
          values={[played * 100]}
          onChange={(value) => onSeek(value[0])}
          onFinalChange={(value) => onSeekMouseUpDrag(value[0])}
          renderTrack={({ props, children }) => (
            <div
              className = 'timeline-video'
              onMouseDown={props.onMouseDown}
              onTouchStart={props.onTouchStart}
            >
              <div 
                className = 'timeline-video__line'
                onMouseDown={onSeekMouseDown}
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values: [played * 100],
                    colors: ['#4098ff', 'hsla(0,0%,100%,.4)'],
                    min: 0,
                    max: 100
                  }),
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ props, isDragged }) => (
            <div
              className = 'timeline-video__line-active'
              {...props}
            >
              <div
                className = 'timeline-video__line-active-icon'
                style={{
                  backgroundColor: isDragged ? '#548BF4' : '#CCC'
                }}
              />
            </div>
          )}
        />
      </div>
      <div className='timeline-video__button-group'>
        {/* <button onClick={onPlayPause}>{playing ? 'Pause' : 'Play'} */}
        <div className='button-video-control' onClick={onPlayPause}>{playing ? 
          <img src={require('@assets/images/icons/pause-icon.png')} alt='icon-pause'/>
          : 
          <img src={require('@assets/images/icons/play-icon.png')} alt='icon-play'/>
        }
        </div>
        <div className={'button-video-control btn-full btn-fullscreen' + `${isFullScreen ? ' hidden' : ''}`} onClick={onToggleFullScreen}>
          <img src={require('@assets/images/icons/full-screen-icon.png')} alt='icon-full-screen'/>
        </div>
        <div className={'button-video-control btn-full btn-fullscreen-exit' + `${isFullScreen ? '' : ' hidden'}`} onClick={onToggleFullScreen}>
          <img src={require('@assets/images/icons/full-screen-icon.png')} alt='icon-full-screen'/>
        </div>
      </div>
    </div>
  );
});

PlayerControl.propTypes = {
  ref: PropTypes.any,
  setRef: PropTypes.any,
  fields: PropTypes.any,
  playing: PropTypes.any,
  played: PropTypes.any,
  isFullScreen: PropTypes.any,
  onSeek: PropTypes.any,
  onSeekMouseDown: PropTypes.any,
  onSeekMouseUpDrag: PropTypes.any,
  onMouseDown: PropTypes.any,
  onTouchStart: PropTypes.any,
  onPlayPause: PropTypes.any,
  onToggleFullScreen: PropTypes.any,
};

PlayerControl.displayName = 'PlayerControl';

export default PlayerControl;