import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import IconListItemModel from './IconListItemModel';

export default class IconListModel {
  constructor() {
    this['Background Color'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['items'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Color')) {
      this['Background Color'].getData(dataSource['Background Color']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let newItems = [];

      if (dataSource['items']) {
        dataSource['items'].map((element) => {
  
          newItems.push(new IconListItemModel().getData(deepCopy(element) || {}));
        });
      }

      this['items'] = dataSource['items'] ? newItems : this['items'];
    }

    return this;
  }
}
