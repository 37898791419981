import React from 'react';

import SelectGuest from './SelectGuest';
import SelectTimeSlot from './SelectTimeSlot';

const SelectTimeSlotAndGuest = () => {
  return (
    <div className='select-store__content__question'>
      <SelectTimeSlot />
      <SelectGuest />
    </div>
  );
};

export default SelectTimeSlotAndGuest;
