import produce from 'immer';

import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';

const initState = {
  isLoading: false,
  data: null,
};

const allOrdersReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myOrderTypes.GET_ALL_MY_ORDER:
      draft.isLoading = true;
      break;

    case myOrderTypes.GET_ALL_MY_ORDER_SUCCESS:
      draft.isLoading = false; 

      draft.data = action.payload;
      break;

    case myOrderTypes.GET_ALL_MY_ORDER_FAILED:
      draft.isLoading = false;      
      break;
    }

    return draft;
  });

export default allOrdersReducer;
