import * as checkoutProcessTypes from './checkoutProcessTypes';
import {SET_ADDRESS_VALID_ERROR_MESSAGE} from './checkoutProcessTypes';

export const checkCommerceUser = (payload) => ({
  type: checkoutProcessTypes.CHECK_COMMERCE_USER,
  payload
});

export const checkCommerceUserFailed = (error) => ({
  type: checkoutProcessTypes.CHECK_COMMERCE_USER_FAILED,
  error
});

export const checkCommerceUserSuccess = (payload) => ({
  type: checkoutProcessTypes.CHECK_COMMERCE_USER_SUCCESS,
  payload
});

export const setInfoUserCheckout = (payload) => ({
  type: checkoutProcessTypes.SET_INFO_USER_CHECKOUT,
  payload
});

export const getStoreAddressList = (payload) => ({
  type: checkoutProcessTypes.GET_STORE_ADDRESS_LIST,
  payload
});

export const getStoreAddressListFailed = (error) => ({
  type: checkoutProcessTypes.GET_STORE_ADDRESS_LIST_FAILED,
  error
});

export const getStoreAddressListSuccess = (payload) => ({
  type: checkoutProcessTypes.GET_STORE_ADDRESS_LIST_SUCCESS,
  payload
});

export const getChangeDefaultAddressBook = (payload) => ({
  type: checkoutProcessTypes.CHANGE_DEFAULT_ADDRESS_BOOK,
  payload
});

export const removeAddressBook = (payload) => ({
  type: checkoutProcessTypes.REMOVE_ADDRESS_BOOK,
  payload
});

export const getBookedAddress = (payload) => ({
  type: checkoutProcessTypes.GET_BOOKED_ADDRESS,
  payload
});

export const getBookedAddressFailed = (error) => ({
  type: checkoutProcessTypes.GET_BOOKED_ADDRESS_FAILED,
  error
});

export const getBookedAddressSuccess = (payload) => ({
  type: checkoutProcessTypes.GET_BOOKED_ADDRESS_SUCCESS,
  payload
});

export const updateBillingAddress = (payload) => ({
  type: checkoutProcessTypes.UPDATE_BILLING_ADDRESS,
  payload
});

export const getBillingData = (payload) => ({
  type: checkoutProcessTypes.GET_BILLING_DATA,
  payload
});

export const getBillingDataFailed = (error) => ({
  type: checkoutProcessTypes.GET_BILLING_DATA_FAILED,
  error
});

export const getBillingDataSuccess = (payload) => ({
  type: checkoutProcessTypes.GET_BILLING_DATA_SUCCESS,
  payload
});

export const getBalanceBillingData = (payload) => ({
  type: checkoutProcessTypes.GET_BALANCE_BILLING_DATA,
  payload
});

export const getBalanceBillingDataFailed = (error) => ({
  type: checkoutProcessTypes.GET_BALANCE_BILLING_DATA_FAILED,
  error
});

export const getBalanceBillingDataSuccess = (payload) => ({
  type: checkoutProcessTypes.GET_BALANCE_BILLING_DATA_SUCCESS,
  payload
});

export const submitOrder = (payload) => ({
  type: checkoutProcessTypes.SUBMIT_ORDER,
  payload
});

export const submitOrderFailed = (error) => ({
  type: checkoutProcessTypes.SUBMIT_ORDER_FAILED,
  error
});

export const submitOrderSuccess = (payload) => ({
  type: checkoutProcessTypes.SUBMIT_ORDER_SUCCESS,
  payload
});

export const submitOrderPayBalance = (payload) => ({
  type: checkoutProcessTypes.SUBMIT_ORDER_PAYBALANCE,
  payload
});

export const submitOrderPayBalanceFailed = (error) => ({
  type: checkoutProcessTypes.SUBMIT_ORDER_PAYBALANCE_FAILED,
  error
});

export const submitOrderPayBalanceSuccess = (payload) => ({
  type: checkoutProcessTypes.SUBMIT_ORDER_PAYBALANCE_SUCCESS,
  payload
});

export const updateBillingAddressFailed = (error) => ({
  type: checkoutProcessTypes.UPDATE_BILLING_ADDRESS_FAILED,
  error
});

export const updateBillingAddressSuccess = (payload) => ({
  type: checkoutProcessTypes.UPDATE_BILLING_ADDRESS_SUCCESS,
  payload
});

export const addNewAddress = (payload) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS,
  payload
});

export const addNewAddressFailed = (error) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS_FAILED,
  error
});

export const addNewAddressSuccess = (payload) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS_SUCCESS,
  payload
});

export const getPaymentDetail = (orderId) => ({
  type: checkoutProcessTypes.GET_PAYMENT_DETAIL,
  orderId
});

export const getPaymentDetailSuccess = (payload) => ({
  type: checkoutProcessTypes.GET_PAYMENT_DETAIL_SUCCESS,
  payload
});

export const getPaymentDetailFailed = (error) => ({
  type: checkoutProcessTypes.GET_PAYMENT_DETAIL_FAILED,
  error
});

export const getCountryDelivery = (orderId) => ({
  type: checkoutProcessTypes.GET_COUNTRY_DELIVERY,
  orderId
});

export const getCountryDeliverySuccess = (payload) => ({
  type: checkoutProcessTypes.GET_COUNTRY_DELIVERY_SUCCESS,
  payload
});

export const getCountryDeliveryFailed = (error) => ({
  type: checkoutProcessTypes.GET_COUNTRY_DELIVERY_FAILED,
  error
});

export const getOrderPaymentStatus = (orderId) => ({
  type: checkoutProcessTypes.GET_ORDER_PAYMENT_STATUS,
  orderId
});

export const getOrderPaymentStatusSuccess = (payload) => ({
  type: checkoutProcessTypes.GET_ORDER_PAYMENT_STATUS_SUCCESS,
  payload
});

export const getOrderPaymentStatusFailed = (error) => ({
  type: checkoutProcessTypes.GET_ORDER_PAYMENT_STATUS_FAILED,
  error
});

export const addNewAddressAndSetFulfill = (payload) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS_AND_SETFULFILL,
  payload
});

export const addNewAddressAndSetFulfillFailed = (error) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS_AND_SETFULFILL_FAILED,
  error
});

export const addNewAddressAndSetFulfillSuccess = (payload) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS_AND_SETFULFILL_SUCCESS,
  payload
});

export const addNewAddressAndSetBilling = (payload) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS_AND_SETBILLING,
  payload
});

export const addNewAddressAndSetBillingFailed = (error) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS_AND_SETBILLING_FAILED,
  error
});

export const addNewAddressAndSetBillingSuccess = (payload) => ({
  type: checkoutProcessTypes.ADD_NEW_ADDRESS_AND_SETBILLING_SUCCESS,
  payload
});

export const downloadInvoice = (payload) => ({
  type: checkoutProcessTypes.DOWNLOAD_INVOICE,
  payload
});

export const downloadInvoiceSuccess = (payload) => ({
  type: checkoutProcessTypes.DOWNLOAD_INVOICE_SUCCESS,
  payload
});

export const downloadInvoiceFail = (payload) => ({
  type: checkoutProcessTypes.DOWNLOAD_INVOICE_FAILED,
  payload
});

export const setAddressValidErrorMessage = ({ section, isValid }) => ({
  type: checkoutProcessTypes.SET_ADDRESS_VALID_ERROR_MESSAGE,
  payload: {
    section,
    isValid
  }
});