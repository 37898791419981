import Global from '@utils/global';

export default class PublishedDateModel {
  constructor() {
    this.value = '';

    this.editable = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this.value = dataSource.value ? Global.customDate(dataSource.value, false) : this.value;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'editable')) {
      this.editable = dataSource.editable ?? this.editable;
    }

    return this;
  }
}
