import global from '@utils/global';

export const COUPON_EVENT_NAME = 'coupon';

export const COUPON_EVENT_ATTEMPT_STATUS = 'Attempt';

export const COUPON_EVENT_SUCCESS_STATUS = 'Success';

export const COUPON_EVENT_FAILURE_STATUS = 'failure';

export const handleTrackingApplyCoupon = (couponStatus, couponCode = '') => {
  const isGA4 = global.getIsGA4();
  if (isGA4) {
    const object = {
      event: COUPON_EVENT_NAME,
      coupon_code: couponCode,
      status: couponStatus
    };

    window.dataLayer.push(object);
  }
};
