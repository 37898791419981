import './MonthDiscountedItems.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import MonthDiscountedItemsModel from './model/MonthDiscountedItemsModel';
import MonthDiscountedSwiper from './MonthDiscountedSwiper';

const MonthDiscountedItems = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new MonthDiscountedItemsModel();

    setLayoutData(model.getData(fields));
  }, []);
  
  return layoutData ? (
    <div className='month-discount-item' id={layoutData?.['Anchor ID']?.value}>
      <div className='container'>
        <Text tag='h3' field={layoutData['Title']} className='month-discount-item__title trade-in-special__title' />
        <div className='month-dicount-item__content trade-in-special__content'>
          <MonthDiscountedSwiper layoutData={layoutData} />
        </div>
      </div>
    </div>
  ) : (<></>);
};

MonthDiscountedItems.propTypes = {
  fields: object
};

export default MonthDiscountedItems;
