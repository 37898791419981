import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import { getHadNotificationsFailure, getHadNotificationsSuccess } from '@redux/actions/cpNotificationPopup/getHadNotifications';
import { GET_HAD_NOTIFICATION_START } from '@redux/actions/cpNotificationPopup/getHadNotifications/types';

import { fetchNotificationData } from '../notificationData/utils';

export const getHadNotificationEpic = (action$) =>
  action$.pipe(
    ofType(GET_HAD_NOTIFICATION_START),
    switchMap(async (action) => {
      const params = {
        page: action.payload?.page ?? 1,
        pageSize: action.payload?.pageSize ?? 10
      };

      const response = await new Promise((resolve) => {
        return fetchNotificationData(params).subscribe((response) => resolve(response));
      });

      if (response.StatusCode === 200 || response.Message === 'OK') {
        return getHadNotificationsSuccess(response?.HadNotification);
      } else {
        return getHadNotificationsFailure(response || 'Failed');
      }
    })
  );
