import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ACTION } from '@redux/epic/product/productList/config';
import { getAsObservable } from '@services/genericService';

export const getProductByCategory = async (categoryId) => {
  return await new Promise((resolve) => {
    getAsObservable(ACTION, { categoryId, isSupportCentre: true })
      .pipe(
        map((response) => {
          if (response.status === 200 || response.statusText.toLowerCase() === 'ok' || response.data) {
            return response.data;
          } else {
            return of({ error: true, message: `Error ${response.status}` });
          }
        }),
        catchError((error) => {
          return of({ error: true, message: error.message });
        })
      )
      .subscribe((data) => resolve(data));
  });
};
