import PropTypes from 'prop-types';
import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

import useCheckFunctionData from '../hooks/useCheckFunctionData';
import useCheckFunctionValue from '../hooks/useCheckFunctionValue';
import useCheckListProductFunctionData from '../hooks/useCheckListProductFunctionData';
import { handleGenerateFunctionValue } from '../utils';
import Layout from './Layout';

export const COMPARE_VALUE_ID = 'compare-value';

export const COMPARE_LABEL_ID = 'compare-label';

export const COMPARE_PRODUCT_FUNCTION_DETAIL_ITEM = 'compare-product-function-detail-item';

export const COMPARE_BENEFIT_DETAIL_ID = 'compare-benefit-detail';

export const COMPARE_BENEFIT_ITEM_LABEL = 'benefit-detail-item';

export const LIST_PRODUCT_FUNCTION = 'ListProductFunction';

export const LIST_BENEFIT_KEY = 'ListBenefit';

export const PRODUCT_SUMMARY_KEY = 'ProductSummary';

const ProductFunctionsDetail = ({ layoutData, isVisibleLabel, productFunctionData }) => {
  const checkFunctionData = useCheckFunctionData();
  const checkFunctionValue = useCheckFunctionValue();
  const checkListProductFunctionData = useCheckListProductFunctionData();
  // const checkMainFunctionData = useCheckMainFunctionData();

  return LIST_PRODUCT_FUNCTION === productFunctionData.functionLabel ? (
    <>
      {productFunctionData?.productFunction?.map((productFunction, index) =>
        checkListProductFunctionData(productFunction.Id) ? (
          <Layout key={index} isVisibleLabel={isVisibleLabel} functionLabel={productFunction?.FunctionLabel}>
            {/*{handleSortFunctionValue(productFunction.Functions)?.map((item, key) => (*/}
            {productFunction.Functions?.map((item, key) => (
              <div id={COMPARE_PRODUCT_FUNCTION_DETAIL_ITEM} key={key} className='product-function__detail-item'>
                {/*<p className='label'>{item?.Text}</p>*/}
                <p id={COMPARE_VALUE_ID} className='value'>
                  {handleGenerateFunctionValue(item?.Value)}
                </p>
              </div>
            ))}
          </Layout>
        ) : (
          <></>
        )
      )}
    </>
  ) : productFunctionData.functionLabel === LIST_BENEFIT_KEY ? (
    checkFunctionData(LIST_BENEFIT_KEY) ? (
      <Layout functionLabel={layoutData['Benefits Label'].value} isVisibleLabel={isVisibleLabel} detailElAriaLabel={COMPARE_BENEFIT_DETAIL_ID}>
        {productFunctionData?.productFunction.length > 0 ? (
          // handleSortBenefitsValue(mappedGeneralFunctionData?.productFunction)?.map((productFunction, index) => (
          productFunctionData?.productFunction?.map((productFunction, index) => (
            <div aria-label={COMPARE_BENEFIT_ITEM_LABEL} key={index} className='product-function__detail-item benefit'>
              <p className='label'>
                <img src={productFunction?.Icon} alt={`benefit-${productFunction?.Text}`} />
              </p>
              <RichText tag='p' className='value' field={{ value: handleGenerateFunctionValue(productFunction?.Text) }} />
            </div>
          ))
        ) : (
          <></>
        )}
      </Layout>
    ) : (
      <></>
    )
  ) : (
    // checkMainFunctionData(productFunctionData) ? (
    <Layout functionLabel={productFunctionData.functionLabel} isVisibleLabel={isVisibleLabel}>
      {productFunctionData?.productFunction.length > 0 &&
        // handleSortGeneralFunctionValue(productFunctionData?.productFunction)?.map((productFunction, index) =>
        productFunctionData?.productFunction?.map((productFunction, index) =>
          checkFunctionValue(productFunctionData?.functionLabel, productFunction?.Id) ? (
            <div id={COMPARE_PRODUCT_FUNCTION_DETAIL_ITEM} key={index} className='product-function__detail-item'>
              {productFunction?.FunctionValue ? (
                <p id={COMPARE_LABEL_ID} className='label'>
                  {productFunction?.FunctionName}
                </p>
              ) : (
                <p id={COMPARE_LABEL_ID} className='label'>
                  -
                </p>
              )}
              <p id={COMPARE_VALUE_ID} className='value'>
                {productFunction?.FunctionValue}
              </p>
            </div>
          ) : (
            <></>
          )
        )}
    </Layout>
    // ) : (
    //   <></>
    // )
  );
};

ProductFunctionsDetail.propTypes = {
  layoutData: PropTypes.object,
  isVisibleLabel: PropTypes.bool,
  productFunctionData: PropTypes.shape({
    functionLabel: PropTypes.string,
    productFunction: PropTypes.array
  })
};

export default ProductFunctionsDetail;
