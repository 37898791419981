import React, { useEffect } from 'react';
import { useState } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

const CPLanguageSelector = () => {
  const arrayLanguage = SitecoreContextFactoryService.getValueContextItem('cpAvailableLanguages') || [];
  const [isShowLanguage, setIsShowLanguage] = useState(false);
  const [languageSelector, setlanguageSelector] = useState(false);

  useEffect(() => {
    setlanguageSelector(SitecoreContextFactoryService.getNameCurrentCPLanguage());
  }, []);

  const onSelectLanguage = (language) => {
    setlanguageSelector(language);
  };

  return (
    <>
      <div className='cp-header__col language-selection' 
        onClick={() => setIsShowLanguage(!isShowLanguage)}>
        <span title={languageSelector || ''}>{languageSelector}</span>
        <span className={`arrow-icon-down ${isShowLanguage? 'popup-show':''}`}></span>
        {
          isShowLanguage ? (
            <div className='overlay-transparent' onClick={() => setIsShowLanguage(!isShowLanguage)}></div>
          ) : ''
        }
        <div className={`cp-popup infor-popup left-popup ${isShowLanguage? 'popup-show':''}`}>
          {
          arrayLanguage?.length ? (
            arrayLanguage.map((languageItem, languageItemIdx) => (
              <div className='group-name' key={languageItemIdx} onClick={() => onSelectLanguage(languageItem.name)}>
                <a href={languageItem.href}>
                  <h3 className='popup-sub-title'>{languageItem.name}</h3>
                </a>
              </div>
            ))
          ) : ''
          }
        </div>
      </div>
    </>
  );
};

export default CPLanguageSelector;