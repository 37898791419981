import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@utils/customsHook/useQuery';

import { STEP_QUERY_STRING_NAME } from '../WarrantyGeneral';

export const useNavigateToStep = () => {
  const query = useQuery();
  const history = useHistory();

  return useCallback(
    (step) => {
      query.set(STEP_QUERY_STRING_NAME, step);

      history.push({
        search: query.toString()
      });
    },
    [query, history]
  );
};
