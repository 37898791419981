import produce from 'immer';

import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';

const initState = {
  isLoading: false,
  listProductsName: null
};

const productNameReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productReviewTypes.GET_PRODUCTS_NAME:
      draft.isLoading = true;
      break;

    case productReviewTypes.GET_PRODUCTS_NAME_SUCCESS:
      draft.isLoading = false;

      draft.listProductsName = action.payload.Products;
      break;

    case productReviewTypes.GET_PRODUCTS_NAME_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default productNameReducer;
