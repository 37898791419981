import './CartMiniItem.scss';

import PropTypes from 'prop-types';
import React from 'react';

import Message from '@components/Share/Message';
import {Text} from '@sitecore-jss/sitecore-jss-react';

const CartMiniItem = ({data, dataSources}) => {
  
  return (
    <div className='cart-mini-item'>
      <div className='cart-mini-item__infor-item'>
        <div className='cart-mini-item__infor-item__image'>
          <img src={data.Image} alt={data.DisplayName}/>
        </div>
        <div className='cart-mini-item__infor-item__contents'>
          <h4 className='item-name'>{data.DisplayName}</h4>
          <div className='cart-mini-item__infor-price justify-space-between'>
            <p className='cart-mini-item__infor-quantity'><Text field={dataSources['Quantity Label']}/> {data.Quantity}</p>
            <div className='cart-mini-item__infor-price-group'>
              <p className='cart-mini-item__infor-price__total-amount'>{data.LineFinalTotalAmount !== 0 ? data.LineFinalPrice : <Message messageCode='LBL-Free'/>}</p>
              {data.LineFinalPrice !== data.LinePrice && !data?.Deposit ? <p className='cart-mini-item__infor-price-old'>{data.LinePrice }</p> : ''}
            </div>
          </div>
        </div>
      </div>
      {/* <div className='cart-mini-item__other-infor-item'>
        <p>Basic Warranty: 1 year of coverage</p>
        <p>Free Gift (2)</p>
      </div> */}
    </div>
  );
};

CartMiniItem.propTypes = {
  data: PropTypes.any,
  dataSources: PropTypes.object
};

export default CartMiniItem;
