import * as commonTypes from './commonTypes';

//AUTO COMPLETE ALL

export const getAutoCompleteAll = (searchTerm) => ({
  type: commonTypes.GET_AUTO_COMPLETE_ALL,
  searchTerm,
});

export const getAutoCompleteAllSuccess = (payload) => ({
  type: commonTypes.GET_AUTO_COMPLETE_ALL_SUCCESS,
  payload
});

export const getAutoCompleteAllFailed = (error) => ({
  type: commonTypes.GET_AUTO_COMPLETE_ALL_FAILED,
  error
});

//AUTO COMPLETE WELLNESS

export const getAutoCompleteWellness = (searchTerm) => ({
  type: commonTypes.GET_AUTO_COMPLETE_WELLNESS,
  searchTerm,
});

export const getAutoCompleteWellnessSuccess = (payload) => ({
  type: commonTypes.GET_AUTO_COMPLETE_WELLNESS_SUCCESS,
  payload
});

export const getAutoCompleteWellnessFailed = (error) => ({
  type: commonTypes.GET_AUTO_COMPLETE_WELLNESS_FAILED,
  error
});

//AUTO COMPLETE PRODUCT

export const getAutoCompleteProduct = (searchTerm) => ({
  type: commonTypes.GET_AUTO_COMPLETE_PRODUCT,
  searchTerm,
});

export const getAutoCompleteProductSuccess = (payload) => ({
  type: commonTypes.GET_AUTO_COMPLETE_PRODUCT_SUCCESS,
  payload,
});

export const getAutoCompleteProductFailed = (error) => ({
  type: commonTypes.GET_AUTO_COMPLETE_PRODUCT_FAILED,
  error
});

//AUTO COMPLETE POLICY

export const getAutoCompletePolicy = (searchTerm) => ({
  type: commonTypes.GET_AUTO_COMPLETE_POLICY,
  searchTerm,
});

export const getAutoCompletePolicySuccess = (payload) => ({
  type: commonTypes.GET_AUTO_COMPLETE_POLICY_SUCCESS,
  payload
});

export const getAutoCompletePolicyFailed = (error) => ({
  type: commonTypes.GET_AUTO_COMPLETE_POLICY_FAILED,
  error
});

//AUTO COMPLETE RESOURCE

export const getAutoCompleteResource = (searchTerm) => ({
  type: commonTypes.GET_AUTO_COMPLETE_RESOURCE,
  searchTerm,
});

export const getAutoCompleteResourceSuccess = (payload) => ({
  type: commonTypes.GET_AUTO_COMPLETE_RESOURCE_SUCCESS,
  payload
});

export const getAutoCompleteResourceFailed = (error) => ({
  type: commonTypes.GET_AUTO_COMPLETE_RESOURCE_FAILED,
  error
});

// COMMON SEARCH ALL

export const commonSearchAll = (searchTerm, page) => ({
  type: commonTypes.COMMON_SEARCH_ALL,
  searchTerm,
  page,
});

export const commonSearchAllSuccess = (payload, count) => ({
  type: commonTypes.COMMON_SEARCH_ALL_SUCCESS,
  payload,
  count
});

export const commonSearchAllFailed = (error) => ({
  type: commonTypes.COMMON_SEARCH_ALL_FAILED,
  error
});

// COMMON SEARCH WellNess

export const commonSearchWellness = (params) => ({
  type: commonTypes.COMMON_SEARCH_WELLNESS,
  params
});

export const commonSearchWellnessSuccess = (payload, count) => ({
  type: commonTypes.COMMON_SEARCH_WELLNESS_SUCCESS,
  payload,
  count
});

export const commonSearchWellnessFailed = (error) => ({
  type: commonTypes.COMMON_SEARCH_WELLNESS_FAILED,
  error
});

// COMMON SEARCH PRODUCT

export const commonSearchProduct = (payload) => ({
  type: commonTypes.COMMON_SEARCH_PRODUCT,
  payload
});

export const commonSearchProductFilter = (payload) => ({
  type: commonTypes.COMMON_SEARCH_PRODUCT_FILTER,
  payload
});

export const commonSearchProductSuccess = (payload) => ({
  type: commonTypes.COMMON_SEARCH_PRODUCT_SUCCESS,
  payload
});

export const commonSearchProductFailed = (error) => ({
  type: commonTypes.COMMON_SEARCH_PRODUCT_FAILED,
  error
});

// COMMON SEARCH POLICY

export const commonSearchPolicy = (params) => ({
  type: commonTypes.COMMON_SEARCH_POLICY,
  params
});

export const commonSearchPolicySuccess = (payload, count) => ({
  type: commonTypes.COMMON_SEARCH_POLICY_SUCCESS,
  payload,
  count
});

export const commonSearchPolicyFailed = (error) => ({
  type: commonTypes.COMMON_SEARCH_POLICY_FAILED,
  error
});

// COMMON SEARCH RESOURCE

export const commonSearchResource= (params) => ({
  type: commonTypes.COMMON_SEARCH_RESOURCE,
  params
});

export const commonSearchResourceSuccess = (payload, count) => ({
  type: commonTypes.COMMON_SEARCH_RESOURCE_SUCCESS,
  payload,
  count
});

export const commonSearchResourceFailed = (error) => ({
  type: commonTypes.COMMON_SEARCH_RESOURCE_FAILED,
  error
});
