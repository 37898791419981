import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as config from '@components/ClientPortal/config';
import LoadingMask from '@components/Share/LoadingMask';
import { endSession } from '@redux/actions/clientTellingPortalCustomer';
import {updateCPCustomerContact } from '@redux/actions/clientTellingPortalCustomer';
import { showCPWarningPopup } from '@redux/actions/cPStartDemo';
import {getCurrentSOCart} from '@redux/actions/product';
import { removeCurlyBrackets } from '@redux/epic/bookingExperience/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { DEFAULT_API_VALUE } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CPPageHeader from '../CPPageHeader';
import {ALL_STORES_ID} from '../GenericComponents/CPHeader/DemoSession/config';
import FinalReviewModel from './model/FinalReviewModel';
import WarningFinalReview from './WarningFinalReview';

const FinalReview = ({ fields, rendering }) => {
  const dispatch = useDispatch();
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const cpCustomerInformation = useSelector((state) => state.clientTellingPortalCustomer.customerInformation);
  const referPOS = useSelector((state) => state.clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS);
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore') || null;
  const currentSOCart = useSelector((state)=> state?.productReducer?.cartDetailReducer?.currentCart);
  const [layoutData, setLayoutData] = useState(null);
  const [isClickSaveContinue, setIsClickSaveContinue] = useState(false);
  const [isCheckPurchase, setIsCheckPurchase] = useState(referPOS);
  const [isWarning_005, setIsWarning_005] = useState(false);
  const [isWarning_006, setIsWarning_006] = useState(false);
  const [isWarning_007, setIsWarning_007] = useState(false);
  const [isWarning_008, setIsWarning_008] = useState(false);
  const [isWarning_009, setIsWarning_009] = useState(false);
  const [isWarning_010, setIsWarning_010] = useState(false);
  const [isWarning_011, setIsWarning_011] = useState(false);
  const [isWarning_012, setIsWarning_012] = useState(false);
  const [isWarning_013, setIsWarning_013] = useState(false);
  const [isRequirePOS, setIsRequirePOS] = useState(false);
  const [isRequireInvoiceNumber, setIsRequireInvoiceNumber] = useState(false);
  // const [getPOSOrderId] = useState(JSON.parse(window.localStorage.getItem('POSOrderIdLocal')) || null);
  // const [getInvoiceNumber] = useState(JSON.parse(window.localStorage.getItem('InvoiceNumberLocal')) || null);
  const [getPOSOrderId, setGetPOSOrderId] = useState(null);
  const [getInvoiceNumber, setGetInvoiceNumber] = useState(null);
  const [isCheckedAppointment, setIsCheckAppointment] = useState(null);

  const [isSelectOptionInPOS, setIsSelectOptionInPOS] = useState('');

  useEffect(() => {
    dispatch(getCurrentSOCart());

    const model = new FinalReviewModel();

    setLayoutData(model.getData({ ...fields } || {}));
  }, []);

  useEffect(() => {
    const getPOSOrderIdLocal = JSON.parse(window.localStorage.getItem('POSOrderIdLocal')) || null;
    const getInvoiceNumberLocal = JSON.parse(window.localStorage.getItem('InvoiceNumberLocal')) || null;
    const getByAppointmentLocal = JSON.parse(window.localStorage.getItem('ByAppointmentLocal')) || false;

    setGetPOSOrderId(getPOSOrderIdLocal);

    setGetInvoiceNumber(getInvoiceNumberLocal);

    setIsCheckAppointment(getByAppointmentLocal);
  }, []);

  useEffect(() => {
    clientTellingPortalCustomer.POSOrderId || getPOSOrderId
      ? setIsSelectOptionInPOS('POS')
      : !clientTellingPortalCustomer.POSOrderId && !getPOSOrderId && !clientTellingPortalCustomer.InvoiceNumber && !getInvoiceNumber
        ? setIsSelectOptionInPOS('POS')
        : setIsSelectOptionInPOS('nonPOS');
  }, [getPOSOrderId, getInvoiceNumber]);

  useEffect(() => {
    setIsCheckPurchase(referPOS);
  }, [referPOS]);

  useDidUpdateEffect(() => {
    if (isSelectOptionInPOS === 'POS') {
      setIsRequirePOS(!clientTellingPortalCustomer.POSOrderId);
    } else {
      setIsRequireInvoiceNumber(!clientTellingPortalCustomer.InvoiceNumber);
    }
  }, [clientTellingPortalCustomer.POSOrderId, clientTellingPortalCustomer.InvoiceNumber]);

  useDidUpdateEffect(() => {
    if (!clientTellingPortalCustomer.isLoadingEndSession) {
      window.localStorage.removeItem('POSOrderIdLocal');

      window.localStorage.removeItem('InvoiceNumberLocal');

      window.localStorage.removeItem('ByAppointmentLocal');

      window.location.href = layoutData['End Session Button Link'].value.href;
    }
  }, [clientTellingPortalCustomer.isLoadingEndSession]);

  useDidUpdateEffect(() => {
    if (
      isClickSaveContinue &&
      clientTellingPortalCustomer.isUpdateContactSuccess &&
      !clientTellingPortalCustomer.isDirtyAddNewAndEditProductInterest
    ) {
      setIsClickSaveContinue(false); //INFO: reset click button save & continue

      window.localStorage.setItem('POSOrderIdLocal', JSON.stringify(clientTellingPortalCustomer.POSOrderId.trim()));

      window.localStorage.setItem('InvoiceNumberLocal', JSON.stringify(clientTellingPortalCustomer.InvoiceNumber?.trim()));

      window.localStorage.setItem('ByAppointmentLocal', JSON.stringify(isCheckedAppointment));
      if (layoutData['Continue Session Button Link'].value.href) {
        window.location.href = layoutData['Continue Session Button Link'].value.href;
      } else {
        setIsClickSaveContinue(false); //INFO: reset click button save & continue
      }
    }
  }, [isClickSaveContinue, clientTellingPortalCustomer.isUpdateContactSuccess]);

  const checkProductInterestCaptured = () => {
    if (cpCustomerInformation.CPCustomerInterest.Products.length) {
      // return cpCustomerInformation.CPCustomerInterest.Products.some((p) => Global.removeEmptyObj(p?.Questions)?.every((quest) => quest?.Answer?.length));
      // code below use to check one of many product is captured
      // return cpCustomerInformation.CPCustomerInterest.Products.some((p) => p?.IsCaptured);
      // code below use to check all products is captured
      return cpCustomerInformation.CPCustomerInterest.Products.every((p) => p?.IsCaptured);
    }

    return;
  };

  const checkLifestyleCaptured = () => {
    if (
      !clientTellingPortalCustomer.tmpCustomerInformation.CPCustomerInfo.MaritalStatus &&
      !clientTellingPortalCustomer.tmpCustomerInformation.CPCustomerInfo.Gender&&
      !clientTellingPortalCustomer.tmpCustomerInformation.CPCustomerInfo.JobNature &&
      !clientTellingPortalCustomer.tmpCustomerInformation.CPCustomerInfo.HealthIssues &&
      // !clientTellingPortalCustomer.tmpCustomerInformation.CPCustomerInfo.MassageOwnership &&
      !clientTellingPortalCustomer.tmpCustomerInformation.CPCustomerInfo.HouseholdSize &&
      !clientTellingPortalCustomer.tmpCustomerInformation.CPCustomerInfo.MassageOwnershipType

    ) {
      return;
    }
      
    return true;
  };

  const actionEndSession = () => {
    let tmpData = deepCopy(clientTellingPortalCustomer.tmpCustomerInformation);
    const getPOSOrderId = window.localStorage.getItem('POSOrderIdLocal') || null;
    const getInvoiceNumber = window.localStorage.getItem('InvoiceNumberLocal') || null;
    const getByAppointment = window.localStorage.getItem('ByAppointmentLocal') || null;
    const storeId = SitecoreContextFactoryService.getValueContextItem('currentStore')?.storeId || '';

    const params = {
      ContactID: clientTellingPortalCustomer.customerInformation?.ContactID,
      PersonalInformation: {
        Gender: tmpData.PersonalInformation.Gender || DEFAULT_API_VALUE
      },
      CPCustomerInfo: {
        MaritalStatus: tmpData.CPCustomerInfo.MaritalStatus || DEFAULT_API_VALUE,
        JobNature: tmpData.CPCustomerInfo.JobNature || DEFAULT_API_VALUE,
        HealthIssues: tmpData.CPCustomerInfo.HealthIssues || DEFAULT_API_VALUE,
        HouseholdSize: tmpData.CPCustomerInfo.HouseholdSize || DEFAULT_API_VALUE,
        MassageOwnershipType: tmpData.CPCustomerInfo.MassageOwnershipType || DEFAULT_API_VALUE,
        MassageOwnership: tmpData.CPCustomerInfo.MassageOwnership || DEFAULT_API_VALUE,
        ReferPOS: tmpData.CPCustomerInfo.ReferPOS || DEFAULT_API_VALUE,
      },
      OrderId: clientTellingPortalCustomer.POSOrderId || getPOSOrderId || DEFAULT_API_VALUE,
      ByAppointment: clientTellingPortalCustomer.ByAppointment || isCheckedAppointment || getByAppointment || DEFAULT_API_VALUE,
      InvoiceNumber: clientTellingPortalCustomer.InvoiceNumber || getInvoiceNumber || DEFAULT_API_VALUE,
      CurrentStore: removeCurlyBrackets(storeId)
    };

    if (isCheckPurchase) {
      if (clientTellingPortalCustomer.POSOrderId || getPOSOrderId || clientTellingPortalCustomer.InvoiceNumber || getInvoiceNumber) {
        dispatch(endSession(params));
      } else {
        if (isSelectOptionInPOS === 'POS') {
          setIsRequirePOS(true);
        } else {
          setIsRequireInvoiceNumber(true);
        }
      }
    } else {
      dispatch(endSession(params));
    }

    setIsWarning_006(false);

    setIsWarning_007(false);

    setIsWarning_008(false);

    setIsWarning_010(false);

    setIsWarning_011(false);

    setIsWarning_012(false);

    setIsWarning_013(false);
  };

  const handleEndSession = () => {
    if (!(currentStore && currentStore.storeId !== ALL_STORES_ID)) {
      dispatch(showCPWarningPopup());
    } else {
      if (!checkProductInterestCaptured()) {
        setIsWarning_005(true);

        return;
      }

      if (clientTellingPortalCustomer.isDirtyAddNewAndEditProductInterest) {
        setIsWarning_009(true);

        return;
      }

      const isAnonymous = clientTellingPortalCustomer.contacts?.[0]?.contact_type === config.ANONYMOUS_LEAD;
      const isApiConditionMet = !currentSOCart?.SalesOrderId && !currentSOCart?.CartStatus && currentSOCart?.Lines?.length > 0;
      if (isAnonymous) {
        if (!checkLifestyleCaptured()) {
          if (isApiConditionMet) {
            setIsWarning_012(true);
  
            return;
          } else {
            setIsWarning_007(true);
  
            return;
          }
        } else {
          if (isApiConditionMet) {
            setIsWarning_013(true);

            return;
          } else {
            setIsWarning_008(true);
  
            return;
          }
        }
      } else {
        if (isApiConditionMet) {
          if (!checkLifestyleCaptured()) {
            setIsWarning_011(true);

            return;
          } else {
            setIsWarning_010(true);

            return;
          }
        } else if (!checkLifestyleCaptured()) {
          setIsWarning_006(true);

          return;
        }
      }

      actionEndSession();
    }
  };

  // const checkIsCaptured = (product) => {
  //   return Global.removeEmptyObj(product?.Questions).every((quest) => quest?.Answer?.length);
  // };

  const handleSaveContinue = () => {
    if (clientTellingPortalCustomer.isDirtyAddNewAndEditProductInterest) {
      setIsClickSaveContinue(false);

      setIsWarning_009(true);

      return;
    }

    setIsClickSaveContinue(true);

    let tmpData = deepCopy(clientTellingPortalCustomer.tmpCustomerInformation);
    let formData = new window.FormData();

    const params = {
      ContactID: clientTellingPortalCustomer.customerInformation?.ContactID,
      // Email: ' ',
      // PhoneNumber: ' ',
      // PrefixPhoneNumber: ' ',
      // DOB: ' ',
      PersonalInformation: {
        Gender: tmpData.PersonalInformation.Gender || DEFAULT_API_VALUE
      },
      CPCustomerInfo: {
        MaritalStatus: tmpData.CPCustomerInfo.MaritalStatus || DEFAULT_API_VALUE,
        MaritalStatusId: tmpData.CPCustomerInfo.MaritalStatusId || DEFAULT_API_VALUE,
        // Age: tmpData.CPCustomerInfo.Age || '',
        JobNature: tmpData.CPCustomerInfo.JobNature || DEFAULT_API_VALUE,
        JobNatureId: tmpData.CPCustomerInfo.JobNatureId || DEFAULT_API_VALUE,
        HealthIssues: tmpData.CPCustomerInfo.HealthIssues || DEFAULT_API_VALUE,
        HealthIssuesId: tmpData.CPCustomerInfo.HealthIssuesId || DEFAULT_API_VALUE,
        HouseholdSize: tmpData.CPCustomerInfo.HouseholdSize || DEFAULT_API_VALUE,
        HouseholdSizeId: tmpData.CPCustomerInfo.HouseholdSizeId || DEFAULT_API_VALUE,
        MassageOwnershipType: tmpData.CPCustomerInfo.MassageOwnershipType || DEFAULT_API_VALUE,
        MassageOwnershipTypeId: tmpData.CPCustomerInfo.MassageOwnershipTypeId || DEFAULT_API_VALUE,
        MassageOwnership: tmpData.CPCustomerInfo.MassageOwnership || DEFAULT_API_VALUE,
        MassageOwnershipId: tmpData.CPCustomerInfo.MassageOwnershipId || DEFAULT_API_VALUE,
        ReferPOS: tmpData.CPCustomerInfo.ReferPOS || DEFAULT_API_VALUE,
        GenderId: tmpData.PersonalInformation.GenderId || DEFAULT_API_VALUE,
        // AllowDirectMail: tmpData.CPCustomerInfo.AllowDirectMail || ' ',
        // AllowSms: tmpData.CPCustomerInfo.AllowSms || ' ',
        // AllowEmail: tmpData.CPCustomerInfo.AllowEmail || ' ',s
        // PDPAAgreement: tmpData.CPCustomerInfo.PDPAAgreement || ' '
      },
      // CPCustomerInterest: {
      //   Products: tmpData.CPCustomerInterest?.Products.map((product) => {
      //     return {
      //       ...product,
      //       IsCaptured: checkIsCaptured(product)
      //     };
      //   }) || ' '
      // }
    };

    for (let keyParams in params) {
      if (keyParams == 'PersonalInformation') {
        for (let keyChild in params[keyParams]) {
          formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
        }
      } else if (keyParams == 'CPCustomerInfo') {
        for (let keyChild in params[keyParams]) {
          formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
        }
      } else if (keyParams == 'CPCustomerInterest') {
        for (let keyChild in params[keyParams]) {
          params[keyParams][keyChild].forEach((item, index) => {
            for (let keyItem in item) {
              if (keyItem == 'Questions') {
                params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
                  for (let keyChildItem in childItem) {
                    formData.append(
                          `CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`,
                          childItem[keyChildItem]
                    );
                  }
                });
              } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
            }
          });
        }
      } else {
        formData.append(keyParams, params[keyParams]);
      }
    }

    dispatch(updateCPCustomerContact(formData));
  };

  const getPurchaseSelected = (data) => {
    setIsCheckPurchase(Boolean(data));
  };

  useDidUpdateEffect(() => {
    if (isSelectOptionInPOS === 'POS' || isSelectOptionInPOS === 'nonPOS') {
      setIsCheckPurchase(true);
    }
  }, [isSelectOptionInPOS]);

  return layoutData ? (
    <>
      <CPPageHeader title={layoutData['Title']} description={layoutData['Description']} />
      <div className='cp-review'>
        <div className='container tablet-container'>
          <div className='cp-review__wrap'>
            <div className='cp-review__content'>
              <Placeholder
                name='cp-final-review'
                rendering={rendering}
                isRequirePOS={isRequirePOS}
                isRequireInvoice={isRequireInvoiceNumber}
                getPurchaseSelected={getPurchaseSelected}
                setIsRequirePOS={setIsRequirePOS}
                setIsRequireInvoice={setIsRequireInvoiceNumber}
                setIsSelectOptionInPOS={setIsSelectOptionInPOS}
              />
            </div>
            <div className='cp-review__btn'>
              <div className='form-group cp-form__checkbox'>
                <div className='osim-checkbox'>
                  <input type='checkbox' id='cp-checkbox-1' className='osim-checkbox-input' checked={isCheckedAppointment} readOnly />
                  <label className='osim-checkbox-label' htmlFor='cp-checkbox-1' onClick={() => setIsCheckAppointment(!isCheckedAppointment)}>
                    <Text field={layoutData['By Appointment Label']}/>
                  </label>
                </div>
              </div>
              <button className='cp-review__btn__item btn btn-primary' onClick={() => handleEndSession()}>
                <Text field={layoutData['End Session Button Text']} />
              </button>
              <Text
                tag='span'
                className='cp-review__btn__item os-btn-link link-nomal'
                field={layoutData['Continue Session Button Text']}
                onClick={() => handleSaveContinue()}
              />
            </div>
          </div>
        </div>
      </div>
      {isWarning_005 && (
        <WarningFinalReview
          isShow={isWarning_005}
          onCloseModalHandler={() => setIsWarning_005(false)}
          onClickButtonlHandler={() => setIsWarning_005(false)}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['No Product Interest Message']}
          buttonText={layoutData['Cancel Button Text']}
        />
      )}
      {isWarning_006 && (
        <WarningFinalReview
          isShow={isWarning_006}
          onCloseModalHandler={() => setIsWarning_006(false)}
          onClickButtonlHandler={() => actionEndSession()}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['No Lifestyle Message']}
          buttonText={layoutData['End Session Warning Button Text']}
          linkText={layoutData['Cancel Button Text']}
        />
      )}
      {isWarning_007 && (
        <WarningFinalReview
          isShow={isWarning_007}
          onCloseModalHandler={() => setIsWarning_007(false)}
          onClickButtonlHandler={() => actionEndSession()}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['No PDPA and Lifestyle Message']}
          buttonText={layoutData['End Session Warning Button Text']}
          linkText={layoutData['Cancel Button Text']}
        />
      )}
      {isWarning_008 && (
        <WarningFinalReview
          isShow={isWarning_008}
          onCloseModalHandler={() => setIsWarning_008(false)}
          onClickButtonlHandler={() => actionEndSession()}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['Not Agreed PDPA Message']}
          buttonText={layoutData['End Session Warning Button Text']}
          linkText={layoutData['Cancel Button Text']}
        />
      )}
      {isWarning_009 && (
        <WarningFinalReview
          isShow={isWarning_009}
          onCloseModalHandler={() => setIsWarning_009(false)}
          onClickButtonlHandler={() => setIsWarning_009(false)}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['Save Confirmation Message']}
          buttonText={layoutData['Cancel Button Text']}
        />
      )}
      {isWarning_010 && (
        <WarningFinalReview
          isShow={isWarning_010}
          onCloseModalHandler={() => setIsWarning_010(false)}
          onClickButtonlHandler={() => actionEndSession()}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['Unsaved Products Message']}
          buttonText={layoutData['End Session Warning Button Text']}
          linkText={layoutData['Cancel Button Text']}
        />
      )}
      {isWarning_011 && (
        <WarningFinalReview
          isShow={isWarning_011}
          onCloseModalHandler={() => setIsWarning_011(false)}
          onClickButtonlHandler={() => actionEndSession()}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['Unsaved Products and No Lifestyle Message']}
          buttonText={layoutData['End Session Warning Button Text']}
          linkText={layoutData['Cancel Button Text']}
        />
      )}
      {isWarning_012 && (
        <WarningFinalReview
          isShow={isWarning_012}
          onCloseModalHandler={() => setIsWarning_012(false)}
          onClickButtonlHandler={() => actionEndSession()}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['Unsaved Products and No PDPA and Lifestyle Message']}
          buttonText={layoutData['End Session Warning Button Text']}
          linkText={layoutData['Cancel Button Text']}
        />
      )}
      {isWarning_013 && (
        <WarningFinalReview
          isShow={isWarning_013}
          onCloseModalHandler={() => setIsWarning_013(false)}
          onClickButtonlHandler={() => actionEndSession()}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['Unsaved Products and No PAPA']}
          buttonText={layoutData['End Session Warning Button Text']}
          linkText={layoutData['Cancel Button Text']}
        />
      )}
      {clientTellingPortalCustomer.isLoadingEndSession && <LoadingMask />}
    </>
  ) : (
    <></>
  );
};

FinalReview.propTypes = {
  rendering: object,
  fields: object
};

export default FinalReview;
