import SitecoreTextModel from '@models/SitecoreTextModel';

export default class TabFieldModel {
  constructor() {
    this['Search Type'] = new SitecoreTextModel();

    this['Text'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Search Type')) {
      this['Search Type'].getData(dataSource['Search Type']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Text')) {
      this['Text'].getData(dataSource['Text']);
    }

    return this;
  }
}
