import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import { convertEvalDate, formatTierAmountNumber } from '../utils';

const IsMemberLoyalty = ({ layoutData }) => {
  // const { myRewardInfo } = useSelector((state) => state.loyaltyReducer.getMyRewardWidgetInfoReducer);
  const dataCrm = useSelector((state) => state.loyaltyReducer.getCrmCustomerReducer.dataCrm);
  const [dataReward, setDataReward] = useState([]);
  const [urlImageTier, setUrlImageTier] = useState('');

  useEffect(() => {
    setDataReward(dataCrm?.Data);
  }, [dataCrm]);

  useEffect(() => {
    if (dataReward) {
      const dataImageTier = layoutData?.['ListImageTiers']?.find((item) => item.CodeLoyalty.toLowerCase() === dataReward?.crm_tier?.toLowerCase());

      if (dataImageTier) {
        setUrlImageTier(dataImageTier.UrlImage);
      }
    }
  }, [dataReward]);

  const evalDateValue = useMemo(() => {
    return dataReward?.crm_eval_date ? convertEvalDate(dataReward.crm_eval_date) : '';
  }, [dataReward?.crm_eval_date]);

  const crmAmountEval = useMemo(() => {
    const crmAmountEvalNumber = Number(dataReward?.crm_amt_retain || 0);
    const crmToFixed = global.roundUpDecimal(crmAmountEvalNumber);

    return formatTierAmountNumber(crmToFixed);
  }, [dataReward]);

  const crmAmountNextTier = useMemo(() => {
    const crmAmountEvalNumber = Number(dataReward?.crm_amt_next_tier || 0);
    const crmToFixed = global.roundUpDecimal(crmAmountEvalNumber);

    return formatTierAmountNumber(crmToFixed);
  }, [dataReward]);

  return (
    <>
      <div className='my-reward-widget__content-left'>
        <div className='reward-icon'>
          <div className='reward-icon__container'>
            <img src={urlImageTier} alt={dataReward?.customer_id} />
          </div>
        </div>
        <h4 className='member-elite'>{dataReward?.crm_tier}</h4>
        <Text tag='p' field={layoutData['Member ID Label']} className='member-id' />
        <p className='member-id__number'>{layoutData['Member ID']?.value.replace('{member_id}', dataReward?.customer_id)}</p>
      </div>
      <div className='my-reward-widget__content-right'>
        <Text tag='h4' field={layoutData['Your Status Label']} className='my-reward-widget__content-right__title' />
        <Text tag='p' field={{ value: layoutData['Review Level Date']?.value?.replace('{DateTimeReview}', evalDateValue) }} className='content' />
        {dataReward?.crm_tier ? (
          <RichText
            tag='p'
            field={{
              value: layoutData['Spend Level Member Label']?.value
                ?.replace('{SpentAmountNextStr}', crmAmountEval)
                .replace('{NameLoyaltyNext}', dataReward?.crm_tier)
            }}
            className='content'
          />
        ) : (
          <></>
        )}
        {dataReward?.crm_tier !== 'DIAMOND' ? (
          <RichText
            tag='p'
            field={{
              value: layoutData['Spend Level Member Upgrade Label']?.value
                ?.replace('{SpentAmountUpgrateNextStr}', crmAmountNextTier)
                .replace('{NameLoyaltyUpgrateNext}', dataReward?.crm_next_tier)
            }}
            className='content'
          />
        ) : (
          <></>
        )}
        <RichText tag='p' field={layoutData['View Current Privileges']} className='link' />
      </div>
    </>
  );
};

IsMemberLoyalty.propTypes = {
  layoutData: object
};

export default IsMemberLoyalty;
