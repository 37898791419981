// Trade In
export const GET_BO_DATA = 'GET_BO_DATA';

export const GET_BO_DATA_SUCCESS = 'GET_BO_DATA_SUCCESS';

export const GET_BO_DATA_FAILED = 'GET_BO_DATA_FAILED';

// Chose Variant
export const ADD_CHOSEN_BO_VARIANT = 'ADD_CHOSEN_BO_VARIANT';

// Chose Rental
export const ADD_CHOSEN_BO_RENTAL = 'ADD_CHOSEN_BO_RENTAL';

// Chose Additional Options
export const ADD_CHOSEN_BO_ADDITIONAL_OPTIONS = 'ADD_CHOSEN_BO_ADDITIONAL_OPTIONS';

// Chose Warranty
export const ADD_CHOSEN_BO_WARRANTY = 'ADD_CHOSEN_BO_WARRANTY';

// Chose FreeGift
export const ADD_CHOSEN_BO_FREEGIFTS = 'ADD_CHOSEN_BO_FREEGIFTS';

export const REMOVE_CHOSEN_BO_FREEGIFTS = 'REMOVE_CHOSEN_BO_FREEGIFTS';

export const REPLACE_CHOSEN_BO_FREEGIFTS = 'REPLACE_CHOSEN_BO_FREEGIFTS';

export const GET_FREE_GIFT_LIST = 'GET_FREE_GIFT_LIST';

// Trade In
export const ADD_CHOSEN_BO_TRADEIN = 'ADD_CHOSEN_BO_TRADEIN';

export const REMOVE_CHOSEN_BO_TRADEIN = 'REMOVE_CHOSEN_BO_TRADEIN';

export const UPLOAD_BO_TRADEIN_FILE = 'UPLOAD_BO_TRADEIN_FILE';

export const UPLOAD_BO_TRADEIN_FILE_SUCCESS = 'UPLOAD_BO_TRADEIN_FILE_SUCCESS';

export const UPLOAD_BO_TRADEIN_FILE_FAILED = 'UPLOAD_BO_TRADEIN_FILE_FAILED';

//Add To Cart
export const ADD_TO_CART = 'ADD_TO_CART';

// Get RelationShipId
export const GET_RELATIONSHIPID = 'GET_RELATIONSHIPID';

export const CLEAR_IMAGE_OF_TRADE_IN_PRODUCTS = 'CLEAR_IMAGE_OF_TRADE_IN_PRODUCTS';

// Deposit Freegift
export const ADD_DEPOSIT_FREE_GIFT = 'ADD_DEPOSIT_FREE_GIFT';