import PropTypes from 'prop-types';
import React from 'react';

const LabelItem = (props) => {
  return (
    <li className={`list-tags__item osim-label ${props.data.IsSpecial ? 'osim-label--highlight' : 'osim-label--default'} `}>{props.data.Name}</li>
  );
};

LabelItem.propTypes = {
  data: PropTypes.object
};

export default LabelItem;
