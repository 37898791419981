export default class CartSuggestFreeShipModel {
  constructor() {
    this.Description = '';

    this.IconFreeShip = '';

    this.PromotionId = '';

    this['Modal Close Label'] = '';

    this['Modal Title'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description ? dataSource.Description : this.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IconFreeShip')) {
      this.IconFreeShip = dataSource.IconFreeShip ? dataSource.IconFreeShip : this.IconFreeShip;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionId')) {
      this.PromotionId = dataSource.PromotionId ? dataSource.PromotionId : this.PromotionId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Modal Close Label')) {
      this['Modal Close Label'] = dataSource['Modal Close Label'] ? dataSource['Modal Close Label'] : this['Modal Close Label'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Modal Title')) {
      this['Modal Title'] = dataSource['Modal Title'] ? dataSource['Modal Title'] : this['Modal Title'];
    }

    return this;
  }
}
