//GET LIST PRODUCT
export const GET_LIST_PRODUCT_START = 'GET_LIST_PRODUCT_START';

export const GET_LIST_PRODUCT_SUCCESS = 'GET_LIST_PRODUCT_SUCCESS';

export const GET_LIST_PRODUCT_FAILED = 'GET_LIST_PRODUCT_FAILED';

//GET PRODUCT INFO
export const GET_PRODUCT_INFO_START = 'GET_PRODUCT_INFO_START';

export const GET_PRODUCT_INFO_SUCCESS = 'GET_PRODUCT_INFO_SUCCESS';

export const GET_PRODUCT_INFO_FAILED = 'GET_PRODUCT_INFO_FAILED';

//REMOVE PRODUCT
export const REMOVE_PRODUCT_COMPARE = 'REMOVE_PRODUCT_COMPARE';

//SET VARIANT PRODUCT
export const SET_VARIANT_PRODUCT_COMPARE = 'SET_VARIANT_PRODUCT_COMPARE';

//GET LIST PRODUCT DEFAULT
export const GET_LIST_PRODUCT_DEFAULT_START = 'GET_LIST_PRODUCT_DEFAULT_START';

export const GET_LIST_PRODUCT_DEFAULT_SUCCESS = 'GET_LIST_PRODUCT_DEFAULT_SUCCESS';

export const GET_LIST_PRODUCT_DEFAULT_FAILED = 'GET_LIST_PRODUCT_DEFAULT_FAILED';