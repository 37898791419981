import { bool, object } from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import useEditAppointmentFormContext from '../useEditAppointmentFormContext';

const SelectScentCP = ({ layoutData, pastAppointmentEdit }) => {
  const { formik } = useEditAppointmentFormContext();

  const scentOptions = useMemo(() => {
    if(layoutData?.scents) {
      const scentArr = layoutData?.scents?.map((scent) => ({
        value: scent.Id,
        label: scent.Name
      }));

      return scentArr;
    }
  }, [layoutData?.scents]);

  const handleSetScentSelected = () => {
    return scentOptions.find((item) => item.value === formik.values.scentId.value);
  };

  return scentOptions.length ? (
    <dl className='appointments-infor__item'>
      <Text field={layoutData?.['Select Scent Label']}  tag='dt' className='appointments-infor__item__label w-65' />
      <Select 
        defaultValue={handleSetScentSelected()}
        isSearchable={false}
        options={scentOptions}
        className='customization-dropdown'
        onChange={(option) => formik.setFieldValue('scentId.value', option.value)}
        classNamePrefix='customization-dropdown'
        isDisabled={pastAppointmentEdit}
      />
      {formik.errors.scentId && formik.touched.scentId &&
        (<span className='error-validate'>{formik.errors.scentId?.value}</span>)}
    </dl>
  ) : (<></>);
};

SelectScentCP.propTypes = {
  layoutData: object,
  pastAppointmentEdit: bool
};

export default SelectScentCP;
