import './AppointmentUpdate.scss';
import '../AppointmentsInfor/AppointmentsInfor.scss';

import React from 'react';
import { useState } from 'react';
import DatePicker from 'react-date-picker';
import Select from 'react-select';

import CPPageHeader from '../../CPPageHeader';
import AppointmentsButton from '../AppointmentsButton';

const AppointmentUpdate = () => {

  const [startDate, setStartDate] = useState(new Date());

  return (
    <>
      <CPPageHeader type='close-icon'/>
      <div className='appointment-update'>
        <div className='container tablet-container'>
          <form className='appointment-update__wrap'>
            <div className='appointment-update__content'>
              <div className='appointments-infor'>
                <div className='appointments-infor__group appointments-infor__group--border-bottom'>
                  <h4 className='appointments-infor__name'>Chew Colin</h4>
                  <div className='appointments-infor__item d-flex'>
                    <span className='appointments-infor__item__label'>Mobile No.</span>
                    <span className='appointments-infor__item__value'>+65 **** 4567</span>
                  </div>
                  <div className='appointments-infor__item d-flex'>
                    <span className='appointments-infor__item__label w-160'>Submission Date:</span>
                    <span className='appointments-infor__item__value'>22 Oct 2021</span>
                  </div>
                  <div className='appointments-infor__item d-flex d-flex--align-center'>
                    <span className='appointments-infor__item__label w-160'>Appointment Date:</span>
                    <div className='appointments-infor__item__value'>
                      <DatePicker selected={startDate} onChange={(date) => setStartDate(date)} />
                    </div>
                  </div>
                  <span className='appointments-infor__product'>New product - SMS</span>
                </div>
                <div className='appointments-infor__group appointments-infor__group--bottom'>
                  <div className='appointments-infor__group__left'>
                    <div className='appointments-infor__item d-flex d-flex--align-center d-flex--space-between max-w-320'>
                      <span className='appointments-infor__item__label w-65'>Status:</span>
                      <div className='appointments-infor__item__value'>
                        <Select
                          className='customization-dropdown'
                          classNamePrefix='customization-dropdown'
                        />
                      </div>
                    </div>
                    <div className='appointments-infor__item max-w-320'>
                      <div className='appointments-infor__item__value'>
                        <textarea className='form-control form-control-lg' id='message' placeholder='Note' rows='4'></textarea>
                      </div>
                    </div>
                    <div className='appointments-infor__item'>
                      <span className='appointments-infor__item__label'>What are your common body discomfort(s)?</span>
                      <div className='appointments-infor__item__value'>
                        <ul className='checkbox-list'>
                          <li className='checkbox-list__item'>
                            <div className='osim-checkbox'>
                              <input type='checkbox' id='cp-checkbox-1' className='osim-checkbox-input' checked='checked' />
                              <label className='osim-checkbox-label'>Stiff Neck & Shoulders</label>
                            </div>
                          </li>
                          <li className='checkbox-list__item'>
                            <div className='osim-checkbox'>
                              <input type='checkbox' id='cp-checkbox-2' className='osim-checkbox-input' />
                              <label className='osim-checkbox-label'>Upper Back Pain</label>
                            </div>
                          </li>
                          <li className='checkbox-list__item'>
                            <div className='osim-checkbox'>
                              <input type='checkbox' id='cp-checkbox-3' className='osim-checkbox-input' />
                              <label className='osim-checkbox-label'>Lower Back Pain</label>
                            </div>
                          </li>
                          <li className='checkbox-list__item'>
                            <div className='osim-checkbox'>
                              <input type='checkbox' id='cp-checkbox-4' className='osim-checkbox-input' />
                              <label className='osim-checkbox-label'>Stiff Neck & Shoulders</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='appointments-infor__item'>
                      <span className='appointments-infor__item__label'>Quality of sleep</span>
                      <div className='appointments-infor__item__value'>
                        <ul className='radio-list radio-list--flex'>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-1' />
                              <label className='osim-radio-label' htmlFor='cp-radio-1'></label>
                              <label className='osim-radio-title'>1</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-2' />
                              <label className='osim-radio-label' htmlFor='cp-radio-2'></label>
                              <label className='osim-radio-title'>2</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-3' />
                              <label className='osim-radio-label' htmlFor='cp-radio-3'></label>
                              <label className='osim-radio-title'>3</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-4' />
                              <label className='osim-radio-label' htmlFor='cp-radio-4'></label>
                              <label className='osim-radio-title'>4</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-5' />
                              <label className='osim-radio-label' htmlFor='cp-radio-5'></label>
                              <label className='osim-radio-title'>5</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className='appointments-infor__group__right'>
                    <div className='appointments-infor__item'>
                      <span className='appointments-infor__item__label'>How often do you go for message?</span>
                      <div className='appointments-infor__item__value'>
                        <ul className='radio-list'>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-6' />
                              <label className='osim-radio-label' htmlFor='cp-radio-6'></label>
                              <label className='osim-radio-title'>Sometimes</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-7' />
                              <label className='osim-radio-label' htmlFor='cp-radio-7'></label>
                              <label className='osim-radio-title'>Once a month</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-8' />
                              <label className='osim-radio-label' htmlFor='cp-radio-8'></label>
                              <label className='osim-radio-title'>Every few months</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-9' />
                              <label className='osim-radio-label' htmlFor='cp-radio-9'></label>
                              <label className='osim-radio-title'>Once a year</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='appointments-infor__item'>
                      <span className='appointments-infor__item__label'>What are your lifestyle concerns?</span>
                      <div className='appointments-infor__item__value'>
                        <ul className='checkbox-list'>
                          <li className='checkbox-list__item'>
                            <div className='osim-checkbox'>
                              <input type='checkbox' id='cp-checkbox-5' className='osim-checkbox-input' checked='checked' />
                              <label className='osim-checkbox-label'>Long hours sitting</label>
                            </div>
                          </li>
                          <li className='checkbox-list__item'>
                            <div className='osim-checkbox'>
                              <input type='checkbox' id='cp-checkbox-6' className='osim-checkbox-input' />
                              <label className='osim-checkbox-label'>Upper Back Pain</label>
                            </div>
                          </li>
                          <li className='checkbox-list__item'>
                            <div className='osim-checkbox'>
                              <input type='checkbox' id='cp-checkbox-7' className='osim-checkbox-input' />
                              <label className='osim-checkbox-label'>Lower Back Pain</label>
                            </div>
                          </li>
                          <li className='checkbox-list__item'>
                            <div className='osim-checkbox'>
                              <input type='checkbox' id='cp-checkbox-8' className='osim-checkbox-input' />
                              <label className='osim-checkbox-label'>Stiff Neck & Shoulders</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className='appointments-infor__item'>
                      <span className='appointments-infor__item__label'>Stress Level</span>
                      <div className='appointments-infor__item__value'>
                        <ul className='radio-list radio-list--flex'>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-10' />
                              <label className='osim-radio-label' htmlFor='cp-radio-10'></label>
                              <label className='osim-radio-title'>1</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-11' />
                              <label className='osim-radio-label' htmlFor='cp-radio-11'></label>
                              <label className='osim-radio-title'>2</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-12' />
                              <label className='osim-radio-label' htmlFor='cp-radio-12'></label>
                              <label className='osim-radio-title'>3</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-13' />
                              <label className='osim-radio-label' htmlFor='cp-radio-13'></label>
                              <label className='osim-radio-title'>4</label>
                            </div>
                          </li>
                          <li className='radio-list__item'>
                            <div className='osim-radio'>
                              <input className='osim-radio-input' type='radio' id='cp-radio-14' />
                              <label className='osim-radio-label' htmlFor='cp-radio-14'></label>
                              <label className='osim-radio-title'>5</label>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <AppointmentsButton/>
          </form>
        </div>
      </div>
    </>
  );
};

export default AppointmentUpdate;