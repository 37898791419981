export const GET = '/OsimCart/GetCurrentCart';

export const GET_CP = '/OsimCart/GetCurrentSOCart';

export const GET_CP_SO_CART_DETAIL = '/OsimCart/GetSOCart';

export const POST = '/OsimCart/AddToCart';

export const POST_CP = '/OsimCart/AddToSOCart';

export const ACTION_REMOVE_CART = '/OsimCart/RemoveCartLines';

export const ACTION_REMOVE_SO_CART = '/OsimCart/RemoveSOCartLines';

export const ACTION_UPDATE_CART = '/OsimCart/UpdateCartLines';

export const ACTION_UPDATE_SO_CART = '/OsimCart/UpdateSOCartLines';

export const SET_FULLFILLMENT = '/OsimCart/SetCartFulfillment';

export const SET_CART_LINE_FULLFILLMENT = '/OsimCart/SetCartLineFulfillment';

export const ACTION_CART_STRIP_ADS = '/Promotions/SuggestPromotionsCart';

export const ACTION_PICK_UP_POINT = '/StoreLocator/PickUpPoint';

export const ADD_STAFF = '/OsimCart/AddStaff';

export const GET_PROMOTION_BY_ID = '/Promotions/information';

export const BROWSE = '/promotions/GetAvailableCoupons';

export const GET_APPLIED = '/promotions/PromotionsApplied';

export const GET_CART_DELIVERY = '/OsimDelivery/SetDefaultShippingAddress';

export const POST_BO_TRADE_IN_FILE = '/OsimMedia/UploadFiles';

export const GET_GIFTING_OPTION_LINK = '/OsimCart/GetGiftingOption';

export const REMOVE_GIFTING_OPTION_LINK = '/OsimCart/RemoveGiftingOptionLine';

export const ACTION_UPDATE_CP_CUSTOMER_INTEREST = '/clientteling/update-contact-interest';

export const SAVE_SO_CART = '/OsimCart/SaveSOCart';

export const ADD_TO_CART_V2_ENDPOINT = '/OsimCart/AddLinesToCart';

export const ADD_TO_SO_CART_V2_ENDPOINT = '/OsimCart/AddLinesToSOCart';