import './ProductInterestPopup.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import QuickAccessPopup from '../QuickAccessPopup';
import ProductInterestAccordion from './ProductInterestAccordion';

const ProductInterestPopup = ({ closeAccessPopup, isCenter, layoutData, customerInterest }) => {
  const clientTellingPortalQuickAccess = useSelector((state) => state.clientTellingPortalQuickAccess.quickAccessLayoutService);

  return (
    <QuickAccessPopup
      closeAccessPopup={closeAccessPopup}
      title={<h2 className='qA-popup__title'>{layoutData?.['Product Interest Label'].value}</h2>}
      isCenter={isCenter}
      layoutData={layoutData}
    >
      <div className='product-interest'>
        <ProductInterestAccordion
          customerInterest={customerInterest}
          layoutData={layoutData}
          addNewProductLabel={<Text field={layoutData['Add New Product Label']} />}
          saveLabel={<Text field={clientTellingPortalQuickAccess['Save Button Label']} />}
          capturedLabel={<Text className='accordion-header__label' tag='span' field={layoutData?.['Captured Label']} />}
          editLabel={<Text field={clientTellingPortalQuickAccess['Edit Button Label']} />}
          cancelLabel={<Text field={clientTellingPortalQuickAccess['Cancel Button Label']} />}
          posLabel={<Text field={layoutData?.['Purchase On The Spot Label']} />}
          componentName='cp-qA-popup'
        />
      </div>
    </QuickAccessPopup>
  );
};

ProductInterestPopup.propTypes = {
  closeAccessPopup: PropTypes.func,
  isCenter: PropTypes.bool,
  layoutData: PropTypes.object,
  customerInterest: PropTypes.array
};

export default ProductInterestPopup;
