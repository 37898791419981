import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { removeAddressBook } from '@redux/actions/checkoutProcess';
import { clearAddressDatasources } from '@redux/actions/myAddress';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import EditAddressBookPopup from '../EditAddressBookPopup';
import InfoAddress from '../InfoAddress';

const AddressItem = (props) => {
  const { dataSources, dataAddress } = props;
  const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
  const dispatch = useDispatch();
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  const onRemoveAddressHandler = () => {
    dispatch(removeAddressBook({ externalId: dataAddress.ExternalId }));
  };

  const onCloseAddAddressHandler = () => {
    setShowAddAddressPopup(false);

    dispatch(clearAddressDatasources());
  };

  return (
    <div className='my-addresses__section'>
      <div className='my-addresses__section__content'>
        <div className='my-account-section__group'>
          <div className='my-addresses__section__content__name'>
            <h5 className='my-addresses__section__content__address-name'>{dataAddress.AddressName}</h5>
            {dataAddress.IsPrimary || dataAddress.IsBilling ? (
              <span className='my-addresses__status'>
                <Text field={dataSources['PopUp Default Label']} />
              </span>
            ) : (
              ''
            )}
          </div>
          {dataSources ? (
            <div className='my-addresses__section__content__btn-link'>
              <span className='my-account-section__btn-link' onClick={() => setShowAddAddressPopup(true)}>
                <Text field={dataSources['Edit Address Text']} />
              </span>
              <span className='my-account-section__btn-link' onClick={onRemoveAddressHandler}>
                <Text field={dataSources['Remove Address Text']} />
              </span>
            </div>
          ) : (
            ''
          )}
        </div>
        <p className='my-addresses__full-name addresses-info__line'>
          {`${settingGlobal?.FullNameFormat.replace('{LastName}', dataAddress.LastName).replace('{FirstName}', dataAddress.FirstName)}`}
        </p>
        <InfoAddress dataAddresses={dataAddress} dataSources={dataSources} />
      </div>
      {showAddAddressPopup ? (
        <div className='address-component'>
          <ModalComponent
            isShow={showAddAddressPopup}
            titlePopup={<Text field={dataSources['PopUp Edit Address Label']} tag='span' />}
            onCloseHandler={onCloseAddAddressHandler}
          >
            <EditAddressBookPopup
              setCloseEditAddressEvt={() => setShowAddAddressPopup(false)}
              addressCurrent={dataAddress}
              dataSources={dataSources}
            />
          </ModalComponent>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

AddressItem.propTypes = {
  dataSources: PropTypes.object,
  dataAddress: PropTypes.object,
  countryDelivery: PropTypes.object
};

export default AddressItem;
