import PropTypes from 'prop-types';
import React from 'react';

const AccessItem = ({ clickAccessItem, layoutData }) => {
  return <div onClick={() => clickAccessItem()}>{layoutData}</div>;
};

AccessItem.propTypes = {
  clickAccessItem: PropTypes.func,
  layoutData: PropTypes.object
};

export default AccessItem;
