import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError,map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';

const cpLoginEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.CP_LOGIN),
    switchMap((action) => {
      const params = {
        UserName: action.payload.UserName,
        PassWord: action.payload.PassWord,
        DeviceId: action.payload.DeviceId,
      };
      
      return postAsObservable(config.ACTION_CP_LOGIN, params).pipe(
        map((res) => {
          if (res.data?.Success) {
            return actions.cpLoginSuccess({
              ...res.data
            });
          } else {
            return actions.cpLoginFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.cpLoginFailed(error.message || 'Failed')))
      );
    })
  );

export default cpLoginEpic;
