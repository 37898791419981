import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SimpleTilesItemFieldsModel {
  constructor() {
    this['CTA Button Link'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();

    this.Image = new SitecoreImageModel();

    this.Title = new SitecoreTextModel();

    this.Description = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Link')) {
      this['CTA Button Link'].getData(dataSource['CTA Button Link']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'] = dataSource['CTA Button Text'];
    }
    
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image.getData(dataSource.Image);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    return this;
  }
}
