import './CPHeader.scss';

import Proptypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const CPHeader = (props) => {
  const { cookieBannerHeight } = useSelector((state) => state.layoutElementStyleReducer);

  return (
    <>
      <header className='cp-header stick'>
        <div style={{ top: `${cookieBannerHeight}px` }} className='cp-header__wrapper'>
          <div className='cp-header__container tablet-container'>
            <div className='cp-header__row'>
              <Placeholder name='osim-header-top' rendering={props.rendering} />
            </div>
          </div>
        </div>
      </header>
      <Placeholder name='osim-header-bottom' rendering={props.rendering} />
    </>
  );
};

CPHeader.propTypes = {
  rendering: Proptypes.any,
  handleSelectStore: Proptypes.func
};

export default CPHeader;
