import * as types from './cpShareProductTypes';

export const cpShareProductDetailStart = (payload) => ({
  type: types.CP_SHARE_PRODUCT_DETAIL,
  payload
});

export const cpShareProductDetailSuccess = (payload) => ({
  type: types.CP_SHARE_PRODUCT_DETAIL_SUCCESS,
  payload
});

export const cpShareProductDetailFailed = (payload) => ({
  type: types.CP_SHARE_PRODUCT_DETAIL_FAILED,
  payload
});
