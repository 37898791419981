import { any, oneOfType } from 'prop-types';
import React from 'react';
import { string } from 'yup';

const SingleQuestion = ({ labelText, ...props }) => {
  return (
    <div className='osim-radio osim-radio--mg-bottom'>
      <input {...props} type='radio' className='osim-radio-input' readOnly />
      <label htmlFor={props.id} className='osim-radio-label osim-radio-label--small' />
      <label htmlFor={props.id} className='osim-radio-title osim-radio-title--gray'>
        {labelText}
      </label>
    </div>
  );
};

SingleQuestion.propTypes = {
  labelText: oneOfType([string, any]),
  id: any
};

export default SingleQuestion;
