import './MemverEventDetail.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import EventBannerSwiper from './EventBannerSwiper';
import LocationSwiper from './LocationSwiper';
import MemberEventDetailModel from './Models/MemberEventDetailModel';

const MemberEventDetail = ({ fields }) => {
  const [fieldsData, setFieldsData] = useState(null);
  const [isExperiedDate, setIsExperiedDate] = useState(false);
  const currentSiteName = SitecoreContextFactoryService.getValueContextRouteItem('name');

  useEffect(() => {
    const memberEventDetailModel = new MemberEventDetailModel();

    setFieldsData(memberEventDetailModel.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    if (fieldsData) {
      if (!fieldsData?.EventId?.value) {
        window.location.href = '/404';
      }
      
      if (new Date(fieldsData?.['Registration End Time']?.slice(0, -6)).getTime() < new Date().getTime()) {
        setIsExperiedDate(true);
      } else {
        // if (new Date(fieldsData?.['Registration Start Time']?.slice(0, -6)).getTime() > new Date().getTime()) {
        //   setIsExperiedDate(true);
        // } else {
        setIsExperiedDate(false);
        // }
      }
    }
  }, [fieldsData]);

  return fieldsData ? (
    <div className='member-event__detail'>
      <div className='container'>
        <div className='member-event__detail-banner'>
          <div className='member-event__detail-banner__wrapper'>
            <Image field={fieldsData['Banner Image']} />
          </div>
        </div>
        <div className='member-event__detail-body'>
          <div className='member-event__detail-body__location'>
            <Text tag='h1' field={fieldsData['Title']} className='event-title' />
            <div className='event-location'>
              <Text tag='h4' field={fieldsData['When And Where Text']} className='event-section__title' />
              <div className='location'>
                {fieldsData.Location.length > 1 ? (
                  <LocationSwiper layoutData={fieldsData} />
                ) : (
                  <div className='location__container'>
                    <Text tag='h5' field={{ value: fieldsData.Location[0]?.['Title'] }} />
                    <Text
                      tag='p'
                      field={{
                        value: `${fieldsData.Location[0]?.['Date Slot']?.[0]?.['Event Date Format 1']} |
                          ${fieldsData.Location[0]?.['Date Slot']?.[0]?.['Time Slots']?.[0]?.['Time']}`
                      }}
                    />
                    <RichText tag='p' field={{ value: fieldsData.Location[0]?.['Address Details'] }} />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className='member-event__detail-body__about'>
            <Text tag='h4' field={fieldsData['About The Event Text']} className='event-section__title' />
            <RichText field={fieldsData?.['Description']} className='event-section__description' />
          </div>
        </div>
        {fieldsData['Gallery Images']?.length ? (
          <div className='member-event__detail-body__swiper'>
            <EventBannerSwiper imageData={fieldsData['Gallery Images']} />
          </div>
        ) : (
          ''
        )}
        {currentSiteName === 'Event Details' ? (
          fieldsData['IsAvailableToRegister']?.value ? (
            <>
              {fieldsData['IsRegistered']?.value ? 
                (<>
                  <RichText field={fieldsData?.['Registered Message']} className='member-event__detail-body__registered' />
                  <div className='member-event__detail-body__action'>
                    <a href={fieldsData['View Detail Link']?.value} className='btn btn-primary'>
                      <Text field={fieldsData['Registered Text Button']} />
                    </a>
                  </div>
                </>)
                : 
                (<div className='member-event__detail-body__action'>
                  <a href={fieldsData['Registration Link']?.value} className='btn btn-primary'>
                    <Text field={fieldsData['Unregistered Text Button']} />
                  </a>
                </div>)
              }
            </>
          ) : (
            isExperiedDate ?
              <Text field={fieldsData?.['Expired Message Text']} tag='p' className='error-message' />
              :
              <Text tag='h5' field={fieldsData['MessageDisplay']} className='event-registration__start' />
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

MemberEventDetail.propTypes = {
  fields: any
};

export default MemberEventDetail;
