import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import QALinkModel from './model/QALinkModel';

const QALink = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new QALinkModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  return layoutData ? (
    <li>
      <Link field={layoutData.Link}>
        <Image field={layoutData.Icon} />
      </Link>
    </li>
  ) : (
    <></>
  );
};

QALink.propTypes = {
  fields: object
};

export default QALink;
