import { object } from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { SG_SITE_NAME } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import { useCommissionFactoryOrderTrack } from './hooks/useCommissionFactoryOrderTrack';
import CommissionFactoryScriptModel from './models/CommissionFactoryScriptModel';

const CommissionFactoryScript = ({ fields }) => {
  const commissionFactoryTrackingOrder = useCommissionFactoryOrderTrack();
  const currentCart = useSelector((state) => state.myOrderReducer.orderByIdReducer.currentCart);
  const paymentDetail = useSelector((state) => state.checkoutProcesstReducer.paymentDetailReducer.paymentDetail);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const [layoutData, setLayoutData] = useState(null);
  const [isLoadedScript, setIsLoadedScript] = useState(false);

  useEffect(() => {
    const model = new CommissionFactoryScriptModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useLayoutEffect(() => {
    if (layoutData?.Script) {
      (async () => {
        await new Promise((resolve, reject) => {
          if (document.getElementById('commission-factory-script')) {
            resolve(true);
          }

          const commissionFactoryScript = document.createElement('script');

          commissionFactoryScript.type = 'text/javascript';

          commissionFactoryScript.id = 'commission-factory-script';

          commissionFactoryScript.src = layoutData.Script?.value;

          commissionFactoryScript.onload = () => {
            resolve(true);
          };

          commissionFactoryScript.onerror = () => {
            reject(false);
          };

          document.head.appendChild(commissionFactoryScript);
        })
          .then((response) => {
            if (response) setIsLoadedScript(true);
          })
          .catch((error) => {
            if (error) setIsLoadedScript(false);
          });
      })();
    }
  }, [layoutData]);

  useEffect(() => {
    // INFO: Commission Factory tracking order
    if (isLoadedScript && currentSiteName === SG_SITE_NAME && currentCart && paymentDetail) {
      (async () => {
        await commissionFactoryTrackingOrder(paymentDetail, currentCart);
      })();
    }
  }, [paymentDetail, currentCart, isLoadedScript]);

  return <></>;
};

CommissionFactoryScript.propTypes = {
  fields: object
};

export default CommissionFactoryScript;
