import { RESCHEDULE_EVENT_BOOKING_FAILED, RESCHEDULE_EVENT_BOOKING_START, RESCHEDULE_EVENT_BOOKING_SUCCESS } from './eventBookingRescheduleTypes';

export const rescheduleEventBookingStart = (payload) => ({
  type: RESCHEDULE_EVENT_BOOKING_START,
  payload
});

export const rescheduleEventBookingSuccess = (payload) => ({
  type: RESCHEDULE_EVENT_BOOKING_SUCCESS,
  payload
});

export const rescheduleEventBookingFailed = (payload) => ({
  type: RESCHEDULE_EVENT_BOOKING_FAILED,
  payload
});
