import { func, object } from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';

import { VIEW_OFFER_DETAIL_CART_ID_KEY } from '../../PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';
import { useCheckDeliveryAddressValid } from '../hooks';
import { useSetInvalidAddressErrorMessage } from './hooks';

const ContinuePaymentBtn = ({ layoutData, cartData, onHandleTrackingCode, onShowErrorMessage }) => {
  const query = useQuery();
  const setInvalidAddressErrMsg = useSetInvalidAddressErrorMessage();
  const checkDeliveryAddressValid = useCheckDeliveryAddressValid();

  const isDeliveryAddressValid = useMemo(() => {
    return checkDeliveryAddressValid?.isValid;
  }, [checkDeliveryAddressValid]);

  const isCartValid = useMemo(() => {
    return cartData?.isCartValid;
  }, [cartData]);

  const clientSOCartId = useMemo(() => {
    return query.get(VIEW_OFFER_DETAIL_CART_ID_KEY) || '';
  }, [query]);

  const continuePaymentLinkField = useMemo(() => {
    let linkFieldObject = layoutData?.['Continue Payment Button Link'];
    if (clientSOCartId) {
      const continueOfferLink = layoutData?.['Continue Offer Payment Button Link'];

      linkFieldObject = {
        ...continueOfferLink,
        value: {
          ...continueOfferLink?.value,
          href: `${continueOfferLink?.value?.href}?${query.toString()}`
        }
      };
    }

    return linkFieldObject;
  }, [clientSOCartId, layoutData, query]);

  useDidUpdateEffect(() => {
    // INFO: show error message for billing address and delivery address when cartData change
    if (cartData) {
      setInvalidAddressErrMsg();
    }
  }, [cartData]);

  const handleShowErrorMessage = useCallback(() => {
    // INFO: show error message for billing address and delivery address
    setInvalidAddressErrMsg();

    // INFO: show error message of other section
    onShowErrorMessage({ isScrollToTop: isDeliveryAddressValid });
  }, [onShowErrorMessage, setInvalidAddressErrMsg, isDeliveryAddressValid]);

  return isCartValid && isDeliveryAddressValid ? (
    <Link field={continuePaymentLinkField} className='btn btn-primary' onClick={onHandleTrackingCode}>
      <Text field={layoutData['Continue Payment Label']} />
    </Link>
  ) : (
    <button className='btn btn-primary' onClick={handleShowErrorMessage}>
      <Text field={layoutData['Continue Payment Label']} />
    </button>
  );
};

ContinuePaymentBtn.propTypes = {
  layoutData: object,
  cartData: object,
  onHandleTrackingCode: func,
  onShowErrorMessage: func
};

export default ContinuePaymentBtn;
