import produce from 'immer';

import StoreFilterProvinceListModel from '@components/Share/StoreLocator/model/StoreFilterProvinceListModel';
import * as storeLocatorTypes from '@redux/actions/storeLocator/storeLocatorTypes';
import { deepCopy } from '@utils/utility.js';

const initialState = {
  name: 'Filter Province Options',
  filterProvinceOptions: [],
  isLoading: false,
  errorMsg: ''
};

const filterProvinceOptionsReducer = (state = initialState, action) => 
  produce(state, draft => {
    let newProvinceOptions = [];
    switch (action.type) {
    case storeLocatorTypes.GET_FILTER_PROVINCE_LIST:

      draft.isLoading = true;
      break;

    case storeLocatorTypes.GET_FILTER_PROVINCE_LIST_SUCCESS:
      action.payload.map(element => {
        newProvinceOptions.push(new StoreFilterProvinceListModel().getData(deepCopy(element) || {}));
      });

      draft.filterProvinceOptions = newProvinceOptions;

      draft.isLoading = false;
      break;

    case storeLocatorTypes.GET_FILTER_PROVINCE_LIST_FAILED:
      draft.filterProvinceOptions = [];

      draft.isLoading = false;
      break;

    case storeLocatorTypes.CLEAR_FILTER_PROVINCE_LIST:
      draft.filterProvinceOptions = [];

      break;
    }

    return draft;
  });

export default filterProvinceOptionsReducer;