import './FreeGiftInfomationPopup.scss';

import PropTypes from 'prop-types';
import React from 'react';

import Global from '@utils/global';

const FreeGiftInfomationPopup = (props) => {
  const { freeGiftItem } = props;

  return (
    <div className='free-gift-information-pop-up'>
      <div className='free-gift-information-pop-up__header'>
        <div className='free-gift-information-pop-up__header__left'>
          <img className='free-gift-information-pop-up__header__left__img' src={freeGiftItem.ImageURLs[0]} />
        </div>
        <div className='free-gift-information-pop-up__header__right'>
          <h4 className='free-gift-information-pop-up__header__right__name'>{freeGiftItem.DisplayName}</h4>
          <span className='free-gift-information-pop-up__header__right__price'>
            {freeGiftItem.Currency}
            {Global.formatCurrency(freeGiftItem.ListPrice)}
          </span>
        </div>
      </div>
      <div className='free-gift-information-pop-up__main'>
        <p className='free-gift-information-pop-up__main__descriptions'>{freeGiftItem.Description}</p>
      </div>
      <a href={freeGiftItem.ProductPageUrl}>Learn More</a>
    </div>
  );
};

FreeGiftInfomationPopup.propTypes = {
  fields: PropTypes.any,
  handleClose: PropTypes.func,
  freeGiftItem: PropTypes.object
};

export default FreeGiftInfomationPopup;
