import PublishedDateModel from '@models/PublishedDateModel';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const textLabel = [
  'Author',
  'Breadcrumb Title',
  'Content',
  'Custom Metadata',
  'Description',
  'Share Article Text',
  'Time To Read',
  'Title'
];

export default class CarouselDetailSlideModel {
  constructor() {
    textLabel.map((item) => (this[item] = new SitecoreTextModel()));

    this['Banner'] = new SitecoreImageModel();

    this['Image'] = new SitecoreImageModel();

    this['PublishedDate'] = new PublishedDateModel();

    this['Exclude From Search'] = {};

    this['Is Authenticate'] = {};

    this['Is Video'] = {};

    this['Show Breadcrumb'] = {};
  }

  getData(dataSource) {
    textLabel.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner')) {
      this['Banner'].getData(dataSource['Banner']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PublishedDate')) {
      this['PublishedDate'].getData(dataSource['PublishedDate']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Exclude From Search') && dataSource['Exclude From Search']) {
      this['Exclude From Search'] = dataSource['Exclude From Search'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Authenticate') && dataSource['Is Authenticate']) {
      this['Is Authenticate'] = dataSource['Is Authenticate'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Video') && dataSource['Is Video']) {
      this['Is Video'] = dataSource['Is Video'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Show Breadcrumb') && dataSource['Show Breadcrumb']) {
      this['Show Breadcrumb'] = dataSource['Show Breadcrumb'];
    }

    return this;
  }
}
