import SpecialRelatedProductItemModel from '@components/SpecialRelatedProduct/models/SpecialRelatedProductItemModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

export default class ProductSpecialDealsModel {
  constructor() {
    this['Buy Now Button Label'] = new SitecoreTextModel();

    this['Buy Now Button Link'] = new SitecoreLinkModel();

    this['Current Product'] = '';

    this['Description'] = new SitecoreTextModel();

    this['SpecialDeal'] = [];

    this['Title'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Label')) {
      this['Buy Now Button Label'].getData(dataSource['Buy Now Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Link')) {
      this['Buy Now Button Link'].getData(dataSource['Buy Now Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Current Product')) {
      this['Current Product'] = dataSource['Current Product'] ? dataSource['Current Product'] : this['Current Product'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SpecialDeal')) {
      let newSpecialDeal = [];

      if (dataSource['SpecialDeal']?.length) {
        dataSource['SpecialDeal'].map((element) => {
  
          newSpecialDeal.push(new SpecialRelatedProductItemModel().getData(deepCopy(element) || {}, this['Buy Now Button Link']));
        });
      }

      this['SpecialDeal'] = dataSource['SpecialDeal'] ? newSpecialDeal : this['SpecialDeal'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}
