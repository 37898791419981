import './PurchaseOnSpot.scss';

import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import PurchaseOnSpotModel from './model/PurchaseOnSpotModel';
import CPPageHeader from '../../ClientPortal/CPPageHeader';
import * as Yup from 'yup';
import { getCurrentSOCart } from '@redux/actions/product';
import { submitPurchaseOnSpotStart } from '@redux/actions/submitPurchaseOnSpot';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import ProductListingShimmer from '../../CategoryLanding/ProductListing/ProductListingShimmer';

const PurchaseOnSpot = ({ fields }) => {
  const [dataSource, setDataSource] = useState(null);
  const dispatch = useDispatch();
  const cpSessionId = SitecoreContextFactoryService.getValueContextItem('cpSessionId');
  const contactData = useSelector((state) => state?.clientTellingPortalCustomer?.contacts[0]?.json_facet);
  const isSuccess = useSelector((state) => state?.submitPurchaseOnSpotReducer?.suggestionList?.IsSuccess);
  const totalData = useSelector((state)=> state?.productReducer?.cartDetailReducer?.currentCart?.Total);

  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const totalAmount = urlParams.get('transactionAmount');

  useEffect(() => {
    setDataSource(new PurchaseOnSpotModel().getData(fields));

    dispatch(getCurrentSOCart());
  }, []);

  const schema = useMemo(()=> {
    return Yup.object({
      transactionID: Yup.string()
        .required(dataSource?.['Input Valid POSTransactionID Message']?.value)
    });
  },[]);

  const initialValues = useMemo(()=> {
    return {
      firstName: contactData?.Personal?.FirstName,
      lastName: contactData?.Personal?.LastName,
      transactionID: '',
      transactionAmount: totalAmount ? totalAmount : totalData
    }
  }, [contactData]);

  const handleSubmit = (values)=> {
    dispatch(
      submitPurchaseOnSpotStart({
        SessionID: cpSessionId,
        OrderId: values.transactionID
      })
    );

    if (isSuccess) {
      window.location.href = `${dataSource['Button Submit Link']?.value?.href}?POSTransactionID=${values.transactionID}&&transactionAmount=${totalAmount ? totalAmount : totalData}`;
    }
  };

  return (
    dataSource ? (
      <>
        <CPPageHeader title={dataSource?.Title} />
        <div className='purchase-on-spot'>
          <div className='container'>
            <div className='purchase-on-spot__content'>
              <Formik initialValues={initialValues} validationSchema={schema} onSubmit={(values) => handleSubmit(values)} enableReinitialize={true}>
                {({ errors, touched, handleChange, values }) => (
                  <Form>
                    <div className='form-group'>
                      <input
                        id='firstName'
                        className={'form-control form-control-lg'}
                        name='firstName'
                        type='text'
                        value={values.firstName}
                        placeholder={dataSource['First Name Label']?.value}
                      />
                      <label htmlFor='firstName' className='form-group__label'>
                        <Text field={dataSource['First Name Label']} />
                      </label>
                    </div>
                    <div className='form-group'>
                      <input
                        id='lastName'
                        className={'form-control form-control-lg'}
                        name='lastName'
                        type='text'
                        value={values.lastName}
                        placeholder={dataSource['Last Name Label']?.value}
                      />
                      <label htmlFor='lastName' className='form-group__label'>
                        <Text field={dataSource['Last Name Label']} />
                      </label>
                    </div>
                    <div className='form-group'>
                      <input
                        id='transactionID'
                        className={'form-control form-control-lg'}
                        name='transactionID'
                        type='text'
                        onChange={handleChange}
                        value={values.transactionID}
                        placeholder={dataSource['POS Transaction ID Label']?.value}
                      />
                      <label htmlFor='transactionID' className='form-group__label'>
                        <Text field={dataSource['POS Transaction ID Label']} />
                      </label>
                      {errors.transactionID && touched.transactionID && (
                        <span className='error-validate'>{errors.transactionID}</span>
                      )}
                    </div>
                    <div className='form-group'>
                      <input
                        id='transactionAmount'
                        className={'form-control form-control-lg'}
                        name='transactionAmount'
                        type='text'
                        value={values.transactionAmount}
                        placeholder={dataSource['Transaction Amount Label']?.value}
                      />
                      <label htmlFor='transactionAmount' className='form-group__label'>
                        <Text field={dataSource['Transaction Amount Label']} />
                      </label>
                    </div>
                    <div className='form-group osim-account-form__form__btn'>
                      <button className='btn btn-primary purchase-on-spot__content__btn' type='submit'>
                        <Text field={dataSource['Button Submit Text']} />
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
              <div className='purchase-on-spot__link-back'>
                <Link field={dataSource['Button Back Link']} className='btn-link'>
                  <Text field={dataSource['Button Back Text']} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    ) : (
      <LoadingShimmer itemNumb={1}>
        <ProductListingShimmer></ProductListingShimmer>
      </LoadingShimmer>
    )
  );
};

PurchaseOnSpot.prototype = {
  fields: PropTypes.any
};

export default PurchaseOnSpot;
