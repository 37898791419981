import { number, object } from 'prop-types';
import React, { useMemo } from 'react';
import { FacebookEmbed, InstagramEmbed, TikTokEmbed, TwitterEmbed } from 'react-social-media-embed';

const EmbedElement = ({ itemData, width }) => {
  const TIKTOK_TYPE = 'Tiktok';
  const INSTAGRAM_TYPE = 'Instagram';
  const FACEBOOK_TYPE = 'Facebook';
  const TWITTER_TYPE = 'Twitter';

  const embedMediaType = useMemo(() => {
    return itemData?.['Embed Media Type']?.value;
  }, [itemData]);

  const embedMediaUrl = useMemo(() => {
    return itemData?.htmlContent?.value;
  }, [itemData]);

  const embedItemWidth = useMemo(() => {
    return width || '100%';
  }, [width]);

  return itemData ? (
    <div className='embedded-html__item-wrapper'>
      {embedMediaType === INSTAGRAM_TYPE ? (
        <InstagramEmbed placeholderSpinner url={embedMediaUrl} width={embedItemWidth} height='100%' />
      ) : embedMediaType === FACEBOOK_TYPE ? (
        <FacebookEmbed placeholderSpinner url={embedMediaUrl} width={embedItemWidth} height='100%' />
      ) : embedMediaType === TIKTOK_TYPE ? (
        <TikTokEmbed placeholderSpinner url={embedMediaUrl} width={embedItemWidth} height='100%' />
      ) : embedMediaType === TWITTER_TYPE ? (
        <TwitterEmbed placeholderSpinner url={embedMediaUrl} width={embedItemWidth} height='100%' />
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

EmbedElement.propTypes = {
  itemData: object,
  width: number
};

export default EmbedElement;
