import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Title',
  'General Description',
  'Specific Description',
  'View Product Button Label',
  'No Warranty Specific Description',
  'Account Specific Description',
  'Discover OSIM Products Label'
];

const LINK_ARR = ['View Product Button Link', 'Discover OSIM Products Link'];

const IMAGE_ARR = ['Tick Icon'];

const PLAIN_TEXT_ARR = ['Discover Button Label', 'Discover Button Link'];

const BOOLEAN_FIELD_ARR = ['HasWarranty', 'HasProductContent'];

export default class WarrantyRegistrationThankYouModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMAGE_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    PLAIN_TEXT_ARR.forEach((item) => {
      this[item] = '';
    });

    BOOLEAN_FIELD_ARR.forEach((item) => {
      this[item] = false;
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    PLAIN_TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    BOOLEAN_FIELD_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    return this;
  }
}
