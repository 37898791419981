import { arrayOf, bool, exact, string } from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

const DropdownQuestion = ({ answerData, ...props }) => {
  const selectOptions = useMemo(() => {
    return answerData.length
      ? answerData.map((ans) => ({
        value: ans.Id,
        label: ans.Text
      }))
      : [];
  }, [answerData]);

  return (
    <Select
      {...props}
      className='customization-dropdown customization-dropdown--lg-gray'
      classNamePrefix='customization-dropdown'
      options={selectOptions}
    />
  );
};

DropdownQuestion.propTypes = {
  answerData: arrayOf(
    exact({
      Id: string,
      Text: string,
      IsChecked: bool
    })
  )
};

export default DropdownQuestion;
