import './SaveSaleOrderSuccess.scss';

import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import CPPageHeader from '../CPPageHeader';
import SaveSaleOrderSuccessModel from './models/SaveSaleOrderSuccessModel';
import SaveSOSuccessBody from './SaveSOSuccessBody';
import SaveSOSuccessFooter from './SaveSOSuccessFooter';
import SaveSOSuccessHeader from './SaveSOSuccessHeader';

const SaveSaleOrderSuccess = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new SaveSaleOrderSuccessModel();

    setLayoutData(model.getData(fields || {}));
  }, []);

  const cartData = useMemo(() => {
    const saleOrderCart = layoutData?.SaleOrder?.Cart;

    return {
      SalesOrderId: saleOrderCart?.SalesOrderId || '',
      CreatedDate: saleOrderCart?.CreatedDate || '',
      CreatedDateWithFormat: saleOrderCart?.CreateDateWithFormat || '',
      ExpiredDate: saleOrderCart?.ExpiredDate || '',
      ExpiredDateWithFormat: saleOrderCart?.ExpiredDateWithFormat || '',
      CartLineLength: saleOrderCart?.Lines?.length || 0,
      CartStatus: saleOrderCart?.CartStatus || 0
    };
  }, [layoutData]);

  const orderDetail = useMemo(() => {
    const saleOrderSummary = layoutData?.SaleOrder?.SaleOrderSummary;

    return {
      DeliveryFee: saleOrderSummary?.DeliveryFee || '',
      Discount: saleOrderSummary?.Discount || '',
      SalesTax: saleOrderSummary?.SalesTax || '',
      Subtotal: saleOrderSummary?.Subtotal || '',
      Total: saleOrderSummary?.Total || ''
    };
  }, [layoutData]);

  return layoutData ? (
    <>
      <CPPageHeader title={layoutData?.['Header']} />
      <div className='save-so-success'>
        <div className='container tablet-container save-so-success-container'>
          <div className='save-so-success-wrapper'>
            <SaveSOSuccessHeader layoutData={layoutData} />
            <SaveSOSuccessBody layoutData={layoutData} cartData={cartData} orderDetail={orderDetail} />
            <SaveSOSuccessFooter layoutData={layoutData} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

SaveSaleOrderSuccess.propTypes = {
  fields: object
};

export default SaveSaleOrderSuccess;
