import './PopupEmailAlready.scss';

import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useQuery } from '@utils/customsHook/useQuery';

import { VIEW_OFFER_DETAIL_CART_ID_KEY } from '../../../PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';

const PopupEmailAlready = (props) => {
  const { inputData } = props;
  const query = useQuery();

  const onLoginTabHandler = () => {
    props.activeTabLoginEvt();
  };

  const continueAsGuestLink = useMemo(() => {
    const cartId = query.get(VIEW_OFFER_DETAIL_CART_ID_KEY);
    const linkField = inputData?.['Continue Guest Checkout  Button Link'];
    const clientSOCartLinkField = inputData?.['Continue to Offer Delivery Button Link'];
    let linkConverted = { ...linkField };
    if (cartId)
      linkConverted = {
        ...clientSOCartLinkField,
        value: {
          ...clientSOCartLinkField?.value,
          href: `${clientSOCartLinkField?.value?.href}?${query.toString()}`
        }
      };

    return linkConverted;
  }, [inputData, query]);

  return (
    <div className='popup-email-already'>
      {/* <p className='popup-email-already__description text-center'>
        <Text field={inputData['Email Already Exists Message']} />
      </p> */}
      <p className='popup-email-already__description text-center' dangerouslySetInnerHTML={{ __html: inputData['Email Already Exists Message'].value }}></p>
      <div className='popup-email-already__action'>
        <Text onClick={onLoginTabHandler} className='popup-email-already__action__btn popup-email-already__action__btn--login btn btn-outline-CTA1' field={inputData['Login Button Text']} tag='button' />
        { inputData['Continue Gues Checkout Button Text'].value ? (
          <Link className='popup-email-already__action__btn popup-email-already__action__btn--link' field={continueAsGuestLink} >
            <Text field={inputData['Continue Gues Checkout Button Text']} />
          </Link>
        ) : (
          <Link className='popup-email-already__action__btn popup-email-already__action__btn--link' field={continueAsGuestLink} />
        )}
      </div>
    </div>
  );
};

PopupEmailAlready.propTypes = {
  inputData: PropTypes.object,
  activeTabLoginEvt: PropTypes.func
};

export default PopupEmailAlready;
