import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import AccordionItemModel from './AccordionItemModel';

export default class AccordionModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['items'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let newItemsList = [];

      dataSource['items'].map((element) => {
        newItemsList.push(new AccordionItemModel().getData(deepCopy(element) || {}));
      });

      this['items'] = newItemsList;
    }

    return this;
  }
}
