import React from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';

const CreateBundleShimmer = () => {
  return (
    <div className='create-bundle skeleton__item' id='create-bundle'>
      <div className='container'>
        <h2 className='create-bundle__title bundle-title text-center experience-title'>
          <span className='text-line-heading-center w-40 hidden-xs-down'></span>
          <span className='text-line-heading-center w-80 hidden-md-up'></span>
        </h2>
        <p className='create-bundle__description bundle-description text-center'>
          <span className='text-line-center w-60 hidden-xs-down'></span>
          <span className='text-line-center w-100 hidden-md-up'></span>
        </p>
      </div>
      <LoadingShimmer itemNumb={3}>
        <div className='promotion-swiper'>
          <div className='container'>
            <h3 className='promotion-swiper__title text-center bundle-sub-title'>
              <span className='text-line-center w-40 hidden-xs-down'></span>
              <span className='text-line-center w-80 hidden-md-up'></span>
            </h3>
          </div>
          <div className='container hidden-xs-down'>
            <div className='promotion-swiper__container swiper-center'>
              <LoadingShimmer itemNumb={4}>
                <div
                  className='promotion-swiper__slider'
                >
                  <div className='promotion-swiper__slider__image image-wrap'>
                    <span className='image-line'></span>
                  </div>
                  <h4 className='promotion-swiper__slider__name'><span className='text-line'></span></h4>
                  <div className='promotion-swiper__slider__price'><span className='text-line'></span></div>
                </div>
              </LoadingShimmer>
            </div>
          </div>
          <div className='container hidden-md-up'>
            <div className='promotion-swiper__container swiper-center'>
              <LoadingShimmer itemNumb={3}>
                <div
                  className='promotion-swiper__slider'
                >
                  <div className='promotion-swiper__slider__image image-wrap'>
                    <span className='image-line'></span>
                  </div>
                  <h4 className='promotion-swiper__slider__name'><span className='text-line'></span></h4>
                  <div className='promotion-swiper__slider__price'><span className='text-line'></span></div>
                </div>
              </LoadingShimmer>
            </div>
          </div>
        </div>
      </LoadingShimmer>
    </div>
  );
};

export default CreateBundleShimmer;