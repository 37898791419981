import { combineEpics } from 'redux-observable';

import cPOverviewEpic from './cPOverview';

import { 
  getSOCartsEpic,
  getSOCartsActiveEpic, 
  getSOCartsDraftEpic, 
  getSOCartsViewedEpic, 
  getSOCartsCompletedEpic, 
  getSOCartsExpiredEpic 
} from './getSOCarts';

const cPDashboard = combineEpics(
  cPOverviewEpic,
  getSOCartsEpic,
  getSOCartsActiveEpic,
  getSOCartsDraftEpic,
  getSOCartsViewedEpic,
  getSOCartsCompletedEpic,
  getSOCartsExpiredEpic
);

export default cPDashboard;