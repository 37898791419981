export const STORE_ADDRESS = '/StoreLocator/SearchStores';

export const USER_ADDRESS = '/Address/GetAddressBookList';

export const UPDATE_BILLING = '/Checkout/UpdateBillingAddress';

export const ADD_NEW_ADDRESS = '/Address/ModifyAddressBook';

export const GET_COUNTRY_DELIVERY = '/OsimDelivery/GetCountryDelivery';

export const ACTION_CHANGE_DEFAULT_ADDRESS_BOOK = '/Address/ChangeDefaultAddressBook';

export const ACTION_REMOVE_ADDRESS_BOOK = '/Address/DeleteAddressBook';
