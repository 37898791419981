import * as cPStartDemoTypes from './cPStartDemoTypes.js';

export const cPStartDemo = (payload) => ({
  type: cPStartDemoTypes.POST_CP_START_DEMO,
  payload
});

export const cPStartDemoSuccess = (payload) => ({
  type: cPStartDemoTypes.POST_CP_START_DEMO_SUCCESS,
  payload
});

export const cPStartDemoFailed = (error) => ({
  type: cPStartDemoTypes.POST_CP_START_DEMO_FAILED,
  error
});

export const showCPWarningPopup = () => ({
  type: cPStartDemoTypes.SHOW_CP_WARNING_POPUP
});

export const hideCPWarningPopup = () => ({
  type: cPStartDemoTypes.HIDE_CP_WARNING_POPUP
});
