import React, { forwardRef, useCallback, useMemo, useState } from 'react';

import Loading from '@components/Share/Loading';

import { useTradeInUploadImage } from '../TradeInUploadImageProvider';
import { MAX_FILE_ALLOW_UPLOAD } from './configs';
import { useHandleUploadImage } from './hooks';

const UploadImageButton = forwardRef((_, ref) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleUploadImageHook = useHandleUploadImage();
  const { formikRefCurrent } = useTradeInUploadImage();
  const imageTradeInProduct = useMemo(() => formikRefCurrent.values.imageTradeInProduct, [formikRefCurrent.values]);

  const handleUploadImage = useCallback(
    async (event) => {
      setIsLoading(true);
      try {
        await handleUploadImageHook(event);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    },
    [handleUploadImageHook]
  );
  const handleResetInputValue = useCallback((event) => (event.target.value = null));

  return imageTradeInProduct.length < MAX_FILE_ALLOW_UPLOAD ? (
    <>
      {isLoading ? (
        <div className='image-uploaded uploading-image'>
          <div className='image-uploaded__content'>
            <Loading />
          </div>
        </div>
      ) : (
        <></>
      )}
      <div className='image-selection__input'>
        <div className='image-selection__image-default image-uploaded__content'>
          <img src={require('@assets/images/icons/upload-icon-small.png')} alt='icon upload' className='image-selection__image-default__icon' />
          <p className='image-selection__image-default__remain-number'>
            {imageTradeInProduct.length}/{MAX_FILE_ALLOW_UPLOAD}
          </p>
        </div>
        <input
          ref={ref}
          type='file'
          name='imageTradeInProduct'
          id='imageTradeInProduct'
          className='input-absolute'
          multiple
          disabled={isLoading}
          max={MAX_FILE_ALLOW_UPLOAD}
          onChange={handleUploadImage}
          onClick={handleResetInputValue}
          accept='image/x-png,image/gif,image/jpeg'
        />
      </div>
    </>
  ) : (
    <></>
  );
});

UploadImageButton.displayName = 'UploadImageButton';

export default UploadImageButton;
