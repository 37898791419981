import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const DownloadAppItemWidget = () => {
  return (
    <div className='product-warranty__detail-widget'>
      <div className='product-warranty__detail-header'>
        <h4 className='product-warranty__detail-header__text'>Download OSIM app</h4>
        <p className='product-warranty__detail-header__description'>Get the full wellness experience with OSIM app.</p>
      </div>
      <div className='product-warranty__detail-extend product-warranty__detail-extend__two__btn'>
        <button className='osim-account-form__form__btn__item btn btn-outline-CTA2'
          onClick={() => console.log('clicked')}>
          <Text field={{ value: 'IOS' }} />
        </button>
        <button className='osim-account-form__form__btn__item btn btn-outline-CTA2'
          onClick={() => console.log('clicked')}>
          <Text field={{ value: 'Android' }} />
        </button>
      </div>
    </div>
  );
};

export default DownloadAppItemWidget;