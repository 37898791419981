import LinkModel from '@models/LinkModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import TextModel from '@models/TextModel';

export default class WellnessArticlesModel {
  constructor() {
    this['Filter By Text'] = new TextModel();

    this['Hidden Text'] = new TextModel();

    this['Icon'] = new TextModel();

    this['Link'] = new SitecoreLinkModel();

    this['Title'] = new TextModel();

  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter By Text')) {
      this['Filter By Text'].getData(dataSource['Filter By Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Hidden Text')) {
      this['Hidden Text'].getData(dataSource['Hidden Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon')) {
      this['Icon'].getData(dataSource['Icon']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this['Link'].getData(dataSource['Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    
    return this;
  }
}
