import * as actions from './types';

export const addWarranty = (payload) => ({
  type: actions.ADD_WARRANTY,
  payload
});

export const addWarrantyFail = (payload) => ({
  type: actions.ADD_WARRANTY_FAILED,
  payload
});

export const addWarrantySuccess = (payload) => ({
  type: actions.ADD_WARRANTY_SUCCESS,
  payload
});

export const setParamAPIData = (payload) => ({
  type: actions.SET_PARAM_API_DATA,
  payload
});
