import { deepCopy } from '@utils/utility';

import WinnersItemModels from './WinnersItemModels';

export default class WinnersModels {
  constructor() {
    this.fields = {};
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'] = new WinnersItemModels().getData(deepCopy(dataSource['fields']) || {});
    }

    return this;
  }
}