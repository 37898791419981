import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/productReview';
import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';
import { post } from '@services/genericService';

import * as config from '../config';

export const postUploadListFilesEpic = (action$) =>
  action$.pipe(
    ofType(productReviewTypes.UPLOAD_LIST_FILES),
    switchMap((action) => {
      let formData = new window.FormData();

      action.payload.map((item, index) => {
        formData.append('file' + index , new window.File([item], item?.name));

        return formData;
      });

      return from(post(config.ACTION_UPLOAD_LIST_FILES, formData, { 'Content-Type': 'multipart/form-data' }, 'tradein')).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.uploadMultiFilesSuccess(res?.data);
          } else {
            return actions.uploadMultiFilesFailed(res);
          }
        })
      );
    })
  );
