import './ProductCatelogue.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ProductCatalogueModel from './model/ProductCatalogueModel';
import ProductCatalogueItem from './ProductCatalogueItem';

const ProductCatalogue = (props) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const model = new ProductCatalogueModel();

    setInputData(model.getData(deepCopy(props.fields) || {}));
  }, []);

  return (
    inputData && (
      <div className='product-catelogue'>
        <div className='container'>
          <div className='product-catelogue__description'>
            <div>
              <Text className='product-catelogue__description--title' tag='h6' field={inputData.Title} />
            </div>
            <Text className='product-catelogue__description--detail' tag='p' field={inputData.Description} />
          </div>
          <div className='product-catelogue__shop-by-categories'>
            <Text
              className='experience-title title-distance product-catelogue__shop-by-categories__title'
              tag='h2'
              field={inputData['Shop By Categories Label']}
            />
            <ul className='row header__sub-menu-nav__contents'>
              {inputData.Categories.map((item) => {
                return <ProductCatalogueItem key={item.id} data={item} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  );
};

ProductCatalogue.propTypes = {
  fields: PropTypes.object
};

export default ProductCatalogue;
