import './TabComponent.scss';

import Proptypes from 'prop-types';
import React from 'react';
import { Tabs } from 'react-bootstrap';

const TabComponent = (props) => {
  const { defaultTab } = props;
  // INFO: Follow https://react-bootstrap.github.io/components/tabs/#tab-content-props for 'props.chidren';

  return <Tabs defaultActiveKey={defaultTab}>{props.children}</Tabs>;
};

TabComponent.propTypes = {
  defaultTab: Proptypes.string,
  children: Proptypes.any
};

export default TabComponent;
