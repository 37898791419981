import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class FieldsModel {
  constructor() {
    this['Campaign Title'] = new SitecoreTextModel();

    this['Campaign Description'] = new SitecoreTextModel();

    this['Campaign QR Code'] = new SitecoreTextModel();

    this['Image'] = new SitecoreImageModel();

  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Campaign Title')) {
      this['Campaign Title'].getData(dataSource['Campaign Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Campaign Description')) {
      this['Campaign Description'].getData(dataSource['Campaign Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Campaign QR Code')) {
      this['Campaign QR Code'].getData(dataSource['Campaign QR Code']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }

    return this;
  }
}
