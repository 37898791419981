import './OfferDetails.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import CartDetailsSection from './CartDetailsSection';
import OfferDetailsModel from './models/OfferDetailsModel';
import OfferDetailsProvider from './OfferDetailsProvider';
import OfferDetailsWidget from './OfferDetailsWidget';

const OfferDetails = ({ fields }) => {
  const isLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer.isLogin);
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new OfferDetailsModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    <OfferDetailsProvider layoutData={layoutData}>
      <div className='offer-details'>
        {isLogin ? (
          <div className='container'>
            <Link className='my-account-section__back-link' field={layoutData['Back Link Button Url']}>
              {'< '} <Text field={layoutData['Back Link Button Label']} />
            </Link>
          </div>
        ) : (
          <></>
        )}
        <div className='container offer-details__container'>
          <OfferDetailsWidget />
          <CartDetailsSection />
        </div>
      </div>
    </OfferDetailsProvider>
  ) : (
    <></>
  );
};

OfferDetails.propTypes = {
  fields: object
};

export default OfferDetails;
