import axios from 'axios';
import qs from 'qs';
import { Observable } from 'rxjs';

import { settingGlobalBehavior$ } from '@redux/epic/settingGlobal/settingGlobalBehavior';

import config from '../temp/config';

const apiHost = config.sitecoreApiHost;
const apiKey = config.sitecoreApiKey;
const apiStem = 'api/osim';

axios.defaults.baseURL = apiHost;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error?.response?.status === 401) {
      settingGlobalBehavior$.next({ AuthorizationFailed: true });
    }

    return Promise.reject(error);
  }
);

export function get(action, payload = {}) {
  return axios.get(`/${apiStem}${action}?sc_apikey=${apiKey}`, {
    params: payload,
    withCredentials: true
  });
}

export function post(action, payload = {}, header = {}, type) {
  return axios.post(`/${apiStem}${action}?sc_apikey=${apiKey}`, type === 'tradein' ? payload : qs.stringify(payload), {
    headers: header,
    withCredentials: true
  });
}

export function getAsObservable(action, params) {
  return new Observable((observer) => {
    const cancelToken = axios.CancelToken.source();

    axios
      .get(`/${apiStem}${action}`, {
        cancelToken: cancelToken.token,
        params: params,
        paramsSerializer: (params) => {
          return qs.stringify(params);
        },
        withCredentials: true
      })
      .then(
        (result) => {
          observer.next(result);

          observer.complete();
        },
        (err) => {
          if (axios.isCancel(err)) {
            observer.complete();
          } else {
            observer.error(err);
          }
        }
      );

    return () => cancelToken.cancel();
  });
}

export function postAsObservable(action, payload = {}, header = {}) {
  return new Observable((observer) => {
    const cancelToken = axios.CancelToken.source();
    axios
      .post(`/${apiStem}${action}?sc_apikey=${apiKey}`, qs.stringify(payload), {
        headers: header,
        cancelToken: cancelToken.token,
        withCredentials: true
      })
      .then(
        (result) => {
          observer.next(result);
          observer.complete();
        },
        (err) => {
          if (axios.isCancel(err)) {
            observer.complete();
          } else {
            observer.error(err);
          }
        }
      );

    return () => cancelToken.cancel();
  });
}

export function customPostMethod({ action, payload = {}, header = {}, type, queryStringsObject }) {
  const queryString = window.location.search;
  const urlSearchParams = new URLSearchParams(queryString);

  urlSearchParams.set('sc_apikey', apiKey);
  if (queryStringsObject) {
    for (const [key, value] of Object.entries(queryStringsObject)) {
      if (value) urlSearchParams.set(key, value.toString());
    }
  }

  return axios.post(`/${apiStem}${action}?${urlSearchParams.toString()}`, type === 'tradein' ? payload : qs.stringify(payload), {
    headers: header,
    withCredentials: true
  });
}
