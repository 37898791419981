import { array } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { connect } from 'react-redux';

import CalendarPicker from '@components/CampaignRegisterComponent/CalendarPicker';
import { getTotalBookedDateOfStore,getTotalBookedTimeSlotOfDate } from '@redux/actions/bookingExperience/getTotalBookedTimeSlot';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import { useDisplayFormikMessage } from '../../../hooks/useDisplayFormikMessage';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { useScheduleAppointmentFormContext } from '../index';

const SelectDate = ({ totalDateBookedResult, searchResultList }) => {
  const { layoutData } = useScheduleAppointmentContext();
  const { formik } = useScheduleAppointmentFormContext();
  const displayFormikMessage = useDisplayFormikMessage();
  const dispatch = useDispatch();

  const disableFormItem = useMemo(() => {
    const storeId = formik.values.storeId;
    let isDisable = !storeId;
    if (storeId) {
      const storeData = searchResultList.find((store) => store.ItemId.toLowerCase() === storeId.toLowerCase());
      const timeSlotsData = storeData?.TimeSlots || [];

      isDisable = !timeSlotsData.length;
    }

    return isDisable;
  }, [formik.values.storeId, searchResultList]);

  const disabledDate = useMemo(() => {
    if (totalDateBookedResult.length) {
      return totalDateBookedResult?.map(date => new Date(date));
    }
  }, [totalDateBookedResult]);

  const handleSelectDate = (date) => {
    formik.setFieldValue('date', date);

    const fieldsToReset = ['timeSlot', 'guest'];
    
    fieldsToReset.forEach((field) => {
      formik.setFieldValue(field, '');
    });

    // Get Time Slot
    dispatch(getTotalBookedTimeSlotOfDate({
      storeId: formik.values.storeId,
      date: Global.formatDateYYYYMMDD(date)
    }));
  };

  useEffect(() => {
    if (layoutData?.appointment?.Store?.Id) {
      dispatch(getTotalBookedDateOfStore({
        storeId: `{${layoutData?.appointment?.Store?.Id}}`
      }));    
    }
  }, [layoutData]);

  return (
    <div className={`select-store__date ${disableFormItem ? 'be-schedule-appointment__disable-form' : ''}`}>
      <Text className='select-store__date__title' field={layoutData['Select Date Label']} tag='h4' />
      <CalendarPicker
        placeholderText='This only includes dates from 5 days ago to 5 days in the future'
        minDate={new Date()}
        selected={formik.values.date}
        excludeDates={disabledDate}
        onChange={(date) => {handleSelectDate(date);}}
      />
      <div className='form-group'>
        <span className='error-validate'>{displayFormikMessage(formik, 'date')}</span>
      </div>
    </div>
  );
};

SelectDate.propTypes = {
  totalDateBookedResult: array,
  searchResultList: array
};

const mapStateToProps = (state) => {
  return {
    totalDateBookedResult: state.bookingExperienceReducer.getTotalBookedTimeSlotReducer.totalDateBookedResult || [],
    searchResultList: state.storeLocatorReducer.allStoreReducer.searchResultList || []
  };
};

export default connect(mapStateToProps)(SelectDate);
