import { ofType } from 'redux-observable';
import { combineEpics } from 'redux-observable';
import {of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import ProductCardInterfaceWishListModel from '@components/Product/models/ProductCardInterfaceWishListModel';
import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { getAsObservable, postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

const modelWishList = (data) => {
  const response = [];

  deepCopy(data).map(wishListItem => {
    response.push(new ProductCardInterfaceWishListModel().getData(wishListItem || {}));
  });

  return response;
};

const getProductWishlistEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_PRODUCT_WISH_LIST, productTypes.GET_PRODUCT_WISH_LIST_USER_ID),
    switchMap((action) => {
      if (action.type === productTypes.GET_PRODUCT_WISH_LIST) {
        return getAsObservable(config.ACTION, action.payload).pipe(
          map((res) => {
            if (res.status === 200 && res.data.Success) {
              return actions.getProductWishlistSuccess(res.data.Lines?.length ? modelWishList(res.data.Lines) : []);
            } else {
              return actions.actionProductWishlistFailed({isGetWishList: true, message: res.data?.Errors?.[0] ?? 'Failed'});
            }
          }),
          catchError((error) => of(actions.actionProductWishlistFailed({isGetWishList: true, message: error.message ?? 'Failed'})))
        );
      } else {
        const params = {
          userId: action.payload,
          getfull: true
        };

        return getAsObservable(config.ACTION_WITH_USER_ID, params).pipe(
          map((res) => {
            if (res.status === 200 && res.data.Success) {
              return actions.getProductWishlistSuccess(res.data.Lines?.length ? modelWishList(res.data.Lines) : []);
            } else {
              return actions.actionProductWishlistFailed({isGetWishList: true, message: res.data?.Errors?.[0] ?? 'Failed'});
            }
          }),
          catchError((error) => of(actions.actionProductWishlistFailed({isGetWishList: true, message: error.message ?? 'Failed'})))
        );
      }
    })
  );

const removeProductWishlistEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.REMOVE_PRODUCT_WISH_LIST),
    switchMap((action) => {
      const params = {
        lineIds: action.payload.lineIds
      };

      return postAsObservable(config.ACTION_REMOVE_WISH_LIST, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            return actions.removeProductWishlistSuccess({lineIds: action.payload.lineIds});
          } else {
            return actions.actionProductWishlistFailed({isGetWishList: false, message: res.data?.Errors?.[0] ?? 'Failed'});
          }
        }),
        catchError((error) => of(actions.actionProductWishlistFailed({isGetWishList: false, message: error.message ?? 'Failed'})))
      );
    })
  );

const addProductWishlistEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.ADD_PRODUCT_WISH_LIST),
    switchMap(action => {
      const params = {
        productId: action.payload.productId,
        variantId: action.payload.variantId,
        quantity: action.payload.quantity,
      };

      return postAsObservable(config.ACTION_ADD_WISH_LIST, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success && !res.data?.Errors?.length) {
            return actions.getProductWishlistSuccess(res.data.Lines?.length ? modelWishList(res.data.Lines) : []);
          } else {
            return actions.actionProductWishlistFailed({isGetWishList: false, message: res.data?.Errors?.[0] ?? 'Failed'});
          }
        }),
        catchError((error) => of(actions.actionProductWishlistFailed({isGetWishList: false, message: error.message ?? 'Failed'})))
      );
    })
  );

const productWishlistEpic = combineEpics(
  getProductWishlistEpic, 
  removeProductWishlistEpic,
  addProductWishlistEpic
);

export default productWishlistEpic;
