import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXTARR = [
  'Apply Offer Button Text',
  'My Prizes Back Button Text',
  'My Prizes Title',
  'Apply Offer Back Button Text',
  'Apply Offer Title',
  'Cancel Button Text',
  'Current Log In',
  'Log In Again',
  'Log Out Button Text',
  'Chance To Win Back Button Text',
  'Chance To Win Title',
  'How To Play Back Button Text',
  'How To Play Description',
  'How To Play Title',
  'Chances To Win',
  'Edit Button Text',
  'Email Placeholder Text',
  'Number Of Chances',
  'Number of spin',
  'Spin Button Text',
  'Spin Wheel Title',
  'Spins Left Label',
  'View My Prizes Button Text',
  'Share Button Text',
  'Winners Title',
  'Prize Description',
  'Prize Name',
  'Spin Again Button Text',
  'You Won Apply Offer Button Text',
  'You Won Back Button Text',
  'You Won Title',
  'Banner Description',
  'Banner Title Text',
  'CTA Button Text',
  'CTA Button 2 Text',
  'Mobile Text Position',
  'Desktop Text Position',
  'Text Color',
  'Coupon Code Label',
  'To Label',
  'Valid From Label',
  'Error Lucky Draw Message',
  'Valid Until Label',
  'Apply Offer Message'
];

const LINKARR = [
  'My Prizes Back Button Link',
  'Apply Offer Back Button Link',
  'Cancel Button Link',
  'Chance To Win Back Button Link',
  'How To Play Back Button Link',
  'Spin Again Button Link',
  'You Won Apply Offer Button Link',
  'You Won Back Button Link',
  'CTA Button Link',
  'CTA Button 2 Link'
];

const IMGARR = [
  'Background Image',
  'Help Icon',
  'Prizes Icon',
  'Prize Image',
  'You Won Background Image',
  'Banner Background Image',
  'Mobile Banner Background Image'
];

export default class SpinAndWinPopupModels {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMGARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    this['LuckyDrawID'] = {};
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMGARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource,'LuckyDrawID')){
      this['LuckyDrawID'] = deepCopy(dataSource['LuckyDrawID']);
    }

    return this;
  }
}
