import './ProductSupportCTA.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import ProductSupportCTAModel from './models/ProductSupportCTAModel';
import ProductCategorySwiper from './ProductCategorySwiper';
import ProductSwiper from './ProductSwiper';
import { ProductSupportCTAContext } from './useProductSupportContext';

const ProductSupportCTA = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [productsData, setProductsData] = useState([]);

  useEffect(() => {
    let model = new ProductSupportCTAModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData && layoutData?.Categories?.length ? (
    <ProductSupportCTAContext.Provider value={{ productsData, setProductsData, layoutData }}>
      <div className='container product-support-cta'>
        <div className='product-support-cta__box'>
          <Text tag='h2' field={layoutData?.['Title']} className='title' />
          <Text tag='h5' field={layoutData?.['Description Title']} className='description-title' />
          <div className='product-support-cta__detail'>
            <ProductCategorySwiper sectionTitle={layoutData?.['Select Category Label']} categoryData={layoutData?.['Categories']} />
          </div>
          <div className='product-support-cta__detail'>
            {productsData.length ? <ProductSwiper sectionTitle={layoutData?.['Select Product Label']} /> : <></>}
          </div>
        </div>
      </div>
    </ProductSupportCTAContext.Provider>
  ) : (
    <></>
  );
};

ProductSupportCTA.propTypes = {
  fields: object
};

export default ProductSupportCTA;
