import './ProductAlsoBought.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import MissingDataApi from '@components/Share/MissingDataApi';
import SwipeComponent from '@components/Share/SwipeComponent';
import ProductDetailPopUpModel from '@models/apiModels/ProductDetailPopUpModel';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import ProductAlsoBoughtModel from './model/ProductAlsoBoughtModel';

const ProductAlsoBought = ({ fields, listProductAlsoBought }) => {
  const [inputData, setInputData] = useState(null);
  const productDetailPopUpModel = new ProductDetailPopUpModel();

  useEffect(() => {
    const productAlsoBoughtModel = new ProductAlsoBoughtModel();

    setInputData(productAlsoBoughtModel.getData(deepCopy(fields || {})));
  }, []);

  return inputData && (
    <div className='product-also-bought'>
      <div className='container product-also-bought__wrap'>
        <Text className='product-also-bought__title experience-title' field={inputData['Title']} tag='h2' />
        {listProductAlsoBought?.length ? (
          <div className='product-also-bought__list'>
            {/* <SwipeComponent> */}
            {listProductAlsoBought.map((crossSaleItem, crossSaleItemIdx) => {
              return (
                <div className='product-also-bought__list__item' key={crossSaleItemIdx}>
                  <div className='product-also-bought__list__item__image'>
                    <img className='product-also-bought-image' src={productDetailPopUpModel.getData(crossSaleItem).ImageURLs[0]} alt='Product Image' />
                  </div>
                  <div className='product-also-bought__list__item__content'>
                    <h3 className='product-also-bought__info__name'>{productDetailPopUpModel.getData(crossSaleItem).DisplayName}</h3>
                    <div className='product-also-bought__info__price'>
                      <div className='product-also-bought__info__selling-price'>
                        <span className='product-also-bought__info__selling-price__currency'>
                          {productDetailPopUpModel.getData(crossSaleItem).Currency}
                        </span>
                        <span className='product-also-bought__info__selling-price__value'>
                          {Global.formatCurrency(productDetailPopUpModel.getData(crossSaleItem).SellingPrice)}
                        </span>
                      </div>
                      <div className='product-also-bought__info__fixed-price'>
                        <span className='product-also-bought__info__fixed-price__currency'>
                          {productDetailPopUpModel.getData(crossSaleItem).Currency}
                        </span>
                        <span className='product-also-bought__info__fixed-price__value'>
                          {Global.formatCurrency(productDetailPopUpModel.getData(crossSaleItem).ListPrice)}
                        </span>
                      </div>
                    </div>
                    {/* <a href={crossSaleItem.ProductPageUrl} className='product-also-bought__info__learn-more'>
                        <Text field={inputData['Learn More Text']} />
                      </a> */}
                  </div>
                </div>
              );
              // </SwipeComponent>
            })}
          </div>
        ) : <MissingDataApi>No data</MissingDataApi>}
      </div>
    </div>
  );
};

ProductAlsoBought.propTypes = {
  fields: PropTypes.any,
  listProductAlsoBought: PropTypes.array
};

const mapStateToProps = (state) => ({
  listProductAlsoBought: state.productReducer.productInformationReducer?.productInformation?.ProductAlsoBought || []
});

export default connect(mapStateToProps)(ProductAlsoBought);