import './PromotionDetailBundleSwiper.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import SwipeComponent from '@components/Share/SwipeComponent';

import { useGetMemberUsualText } from '../../../utils/customsHook/useGetMemberUsualText';
import { QuestionTooltipIcon } from '../../ProductCardPrice/QuestionTooltipIcon';
import BundleItemModel from '../CreateBundle/model/BundleItemModel';

const REPLACE_STRING = '{num}';

const SLIDE_PER_VIEW_XL = 6;

const SLIDE_PER_VIEW_MD = 4;

const SCREEN_RESOLUTION_XL = 1200;

const PromotionDetailBundleSwiper = ({ type, listBundle, layoutData, bundleNumber, selectedItemHandler }) => {
  const calculateQuantityList = useSelector((state) => state.dynamicBundleReducer.calculateBundleQuantity || {});

  const [selectedItem, setSelectedItem] = useState({});
  const [isSwiperCenter, setIsSwiperCenter] = useState(false);

  const {
    memberText,
    usualText
  } = useGetMemberUsualText();

  const params = {
    lazy: true,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 16,
    watchOverflow: true,
    centerInsufficientSlides: true,
    breakpoints: {
      767: {
        slidesPerView: SLIDE_PER_VIEW_MD,
        spaceBetween: 30,
        navigation: {
          nextEl: `.promotion-swiper .${type}.swiper-button-next`,
          prevEl: `.promotion-swiper .${type}.swiper-button-prev`
        }
      },

      1200: {
        slidesPerView: SLIDE_PER_VIEW_XL,
        spaceBetween: 30,
        navigation: {
          nextEl: `.promotion-swiper .${type}.swiper-button-next`,
          prevEl: `.promotion-swiper .${type}.swiper-button-prev`
        }
      }
    }
  };

  const selectItemHandler = (item) => {
    selectedItemHandler(item, bundleNumber - 1);

    setSelectedItem(item);
  };

  const getWidth = () => {
    return document.body.offsetWidth;
  };

  const getSlidePerView = () => {
    if (getWidth() >= SCREEN_RESOLUTION_XL) {
      if (listBundle.length <= SLIDE_PER_VIEW_XL) {
        setIsSwiperCenter(true);
      } else setIsSwiperCenter(false);
    } else {
      if (listBundle.length <= SLIDE_PER_VIEW_MD) {
        setIsSwiperCenter(true);
      } else setIsSwiperCenter(false);
    }
  };

  useEffect(() => {
    getSlidePerView();

    window.addEventListener('resize', getSlidePerView);

    return () => window.removeEventListener('resize', getSlidePerView);
  }, [getWidth]);

  return (
    <div className='promotion-swiper'>
      <div className='container'>
        <h3 className='promotion-swiper__title text-center bundle-sub-title'>
          {layoutData['Product Title'].value.replace(REPLACE_STRING, bundleNumber)}
        </h3>
      </div>
      <div className='container'>
        <div className={`promotion-swiper__container${isSwiperCenter ? ' swiper-center' : ''}`}>
          <SwipeComponent param={params}>
            {listBundle.length ? (
              listBundle.map((item) => {
                const itemData = new BundleItemModel().getData(item);

                const isShowListPrice = itemData.Price.SellingPriceWithCurrency
                  === itemData.Price.ListPriceWithCurrency;

                return (
                  <div
                    className={`promotion-swiper__slider ${itemData.ProductId === selectedItem.ProductId ? 'selected' : ''} ${
                      calculateQuantityList[itemData.ProductId] <= 0 && itemData.ProductId !== selectedItem.ProductId ? 'out-stock' : ''
                    }`}
                    onClick={() => selectItemHandler(item)}
                    key={itemData.ProductId}
                  >
                    <div className='promotion-swiper__slider__image'>
                      <img className='swiper-lazy animated' data-src={item.Image} alt='x' />
                      <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
                    </div>
                    <h4 className='promotion-swiper__slider__name'>{itemData.ProductName}</h4>
                    <div className='promotion-swiper__slider__price'>
                      {!isShowListPrice ? (
                        <div className='promotion-swiper__slider__price__selling-price with-question-tooltip'>
                          <p className='price-with-member-text'>
                            <span className='member-text'>{memberText}</span>
                            <span className='member-price'>
                              <span>{itemData.Price.SellingPriceWithCurrency}</span>
                              <QuestionTooltipIcon />
                            </span>
                          </p>
                        </div>
                      ) : (
                        <span className='promotion-swiper__slider__price__selling-price'>{itemData.Price.SellingPriceWithCurrency}</span>
                      )}
                      {isShowListPrice ? (
                        <></>
                      ) : (
                        <span
                          className={[
                            'promotion-swiper__slider__price__list-price',
                            usualText ? 'with-usual-text' : ''
                          ].join(' ')}
                        >{usualText}{itemData.Price.ListPriceWithCurrency}</span>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div></div>
            )}
          </SwipeComponent>
          <div className={type + ' ' + 'swiper-button-next'}></div>
          <div className={type + ' ' + 'swiper-button-prev'}></div>
        </div>
      </div>
    </div>
  );
};

PromotionDetailBundleSwiper.propTypes = {
  listBundle: PropTypes.any,
  layoutData: PropTypes.any,
  type: PropTypes.string,
  bundleNumber: PropTypes.number,
  selectedItemHandler: PropTypes.func,
  calculateQuantityList: PropTypes.any
};

export default PromotionDetailBundleSwiper;
