export default class GoogleAnalytic {
  ggGateWay () {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push(arguments);
  }

  gAnalytic4() {
    window.dataLayer = window.dataLayer || [];

    window.dataLayer.push({ ecommerce: null });

    window.dataLayer.push(arguments);
  }
}