// getProductName
export const GET_PRODUCTS_NAME = 'GET_PRODUCTS_NAME';

export const GET_PRODUCTS_NAME_SUCCESS = 'GET_PRODUCTS_NAME_SUCCESS';

export const GET_PRODUCTS_NAME_FAILED = 'GET_PRODUCTS_NAME_FAILED';

// getProductReview
export const GET_PRODUCT_REVIEW = 'GET_PRODUCT_REVIEW';

export const GET_PRODUCT_REVIEW_SUCCESS = 'GET_PRODUCT_REVIEW_SUCCESS';

export const GET_PRODUCT_REVIEW_FAILED = 'GET_PRODUCT_REVIEW_FAILED';

// getCustomerReviewsAverage
export const GET_CUSTOMER_REVIEWS_AVERAGE = 'GET_CUSTOMER_REVIEWS_AVERAGE';

export const GET_CUSTOMER_REVIEWS_AVERAGE_SUCCESS = 'GET_CUSTOMER_REVIEWS_AVERAGE_SUCCESS';

export const GET_CUSTOMER_REVIEWS_AVERAGE_fAILED = 'GET_CUSTOMER_REVIEWS_AVERAGE_fAILED';

//UploadListFiles
export const UPLOAD_LIST_FILES = 'UPLOAD_LIST_FILES';

export const UPLOAD_LIST_FILES_SUCCESS = 'UPLOAD_LIST_FILES_SUCCESS';

export const UPLOAD_LIST_FILES_FAILED = 'UPLOAD_LIST_FILES_FAILED';

export const LIST_FILES_UPLOAD_STORE = 'LIST_FILES_UPLOAD_STORE';

//SubmitProductReview
export const SUBMIT_PRODUCT_REVIEW = 'SUBMIT_PRODUCT_REVIEW';

export const SUBMIT_PRODUCT_REVIEW_SUCCESS = 'SUBMIT_PRODUCT_REVIEW_SUCCESS';

export const SUBMIT_PRODUCT_REVIEW_FAILED = 'SUBMIT_PRODUCT_REVIEW_FAILED';

export const TOGGLE_REVIEW_LOGIN = 'TOGGLE_REVIEW_LOGIN';

export const REVIEW_FORM_PARAMS = 'REVIEW_FORM_PARAMS';

//Check serial number
export const SERIAL_NUMBER_FORMAT_CHECKER = 'SERIAL_NUMBER_FORMAT_CHECKER';

export const SERIAL_NUMBER_FORMAT_CHECKER_SUCCESS = 'SERIAL_NUMBER_FORMAT_CHECKER_SUCCESS';

export const SERIAL_NUMBER_FORMAT_CHECKER_FAILED = 'SERIAL_NUMBER_FORMAT_CHECKER_FAILED';

export const SERIAL_NUMBER_FORMAT_CHECKER_SETTING = 'SERIAL_NUMBER_FORMAT_CHECKER_SETTING';

export const SERIAL_NUMBER_FORMAT_CHECKER_SETTING_SUCCESS = 'SERIAL_NUMBER_FORMAT_CHECKER_SETTING_SUCCESS';

//GetLatestReview
export const GET_LATEST_REVIEW = 'GET_LATEST_REVIEW';

export const GET_LATEST_REVIEW_SUCCESS = 'GET_LATEST_REVIEW_SUCCESS';

export const GET_LATEST_REVIEW_FAILED = 'GET_LATEST_REVIEW_FAILED';

// GetCatalogHeaderNavigation for FullReviewListing page
export const GET_CATALOG_HEADER_NAVIGATION = 'GET_CATALOG_HEADER_NAVIGATION';

export const GET_CATALOG_HEADER_NAVIGATION_SUCCESS = 'GET_CATALOG_HEADER_NAVIGATION_SUCCESS';

export const GET_CATALOG_HEADER_NAVIGATION_FAILED = 'GET_CATALOG_HEADER_NAVIGATION_FAILED';

//GetProductReviewById
export const GET_PRODUCT_REVIEW_BY_ID = 'GET_PRODUCT_REVIEW_BY_ID';

export const GET_PRODUCT_REVIEW_BY_ID_SUCCESS = 'GET_PRODUCT_REVIEW_BY_ID_SUCCESS';

export const GET_PRODUCT_REVIEW_BY_ID_FAILED = 'GET_PRODUCT_REVIEW_BY_ID_FAILED';

//GetBundleElement
export const GET_BUNDLE_ELEMENT = 'GET_BUNDLE_ELEMENT';

export const GET_BUNDLE_ELEMENT_SUCCESS = 'GET_BUNDLE_ELEMENT_SUCCESS';

export const GET_BUNDLE_ELEMENT_FAILED = 'GET_BUNDLE_ELEMENT_FAILED';

//GetDynamicBundleElements
export const GET_DYNAMIC_BUNDLE_ELEMENT = 'GET_DYNAMIC_BUNDLE_ELEMENT';

export const GET_DYNAMIC_BUNDLE_ELEMENT_SUCCESS = 'GET_DYNAMIC_BUNDLE_ELEMENT_SUCCESS';

export const GET_DYNAMIC_BUNDLE_ELEMENT_FAILED = 'GET_DYNAMIC_BUNDLE_ELEMENT_FAILED';