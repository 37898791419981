import produce from 'immer';

import * as cpSendSaleOrderTypes from '@redux/actions/cpSendSaleOrder/cpSendSaleOrderTypes';

const initState = {
  isLoading: false,
  isSuccess: false,
  cPError: null,
  dataApi: null
};

const cpSendSaleOrderReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cpSendSaleOrderTypes.SEND_SALE_ORDER:
      draft.isLoading = false;

      draft.isSuccess = false;

      break;

    case cpSendSaleOrderTypes.SEND_SALE_ORDER_SUCCESS:
      draft.dataApi = action?.payload

      draft.isLoading = true

      draft.isSuccess = true
      
      break;
  
    case cpSendSaleOrderTypes.SEND_SALE_ORDER_FAILED:
      draft.isLoading = false;

      draft.isSuccess = false;
        
      draft.cPError = action.error;

      break;
    }

    return draft;
  });

export default cpSendSaleOrderReducer;
