import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import QuickNavigationItemModel from '../Model/QuickNavigationItemModel';

const QuickNavigationItem = (props) => {
  const { contentData } = props;
  const name = SitecoreContextFactoryService.getValueContextRouteItem('name');
  const [data, setData] = useState(null);
  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const navigationItemsModel = new QuickNavigationItemModel();

    setData(navigationItemsModel.getData(contentData));
  }, []);

  //New Logic ON/OFF GA, GA4
  const handleGA4Event = () => {
    if (isGA4) {
      Global.handleGA4Navigation({
        labelItem: data.Title.value,
        pageTitle: name, 
        labelCategory: data.Title.value,
        pageUrl: data.Link.value.href
      });
    }
  };

  return (
    data && (
      <li>
        {props.isLogin ? (
          <Link className='second-nav-link normal-text' field={data.Link} onClick={handleGA4Event}>
            <Text field={data.Title} />
          </Link>
        ) : (
          <></>
        )}
        {!props.isLogin && data['Is Authenticated'].value ? (
          <Link className='second-nav-link normal-text' field={props.loginLink} onClick={handleGA4Event}>
            <Text field={data.Title} />
          </Link>
        ) : (
          <></>
        )}
        {!props.isLogin && !data['Is Authenticated'].value ? (
          <Link className='second-nav-link normal-text' field={data.Link} onClick={handleGA4Event}>
            <Text field={data.Title} />
          </Link>
        ) : (
          <></>
        )}
      </li>
    )
  );
};

QuickNavigationItem.propTypes = {
  contentData: PropTypes.any,
  isLogin: PropTypes.bool,
  loginLink: PropTypes.object
};

const mapStateToProps = (state) => ({
  loginLink: state.unsubscribeReducer.loginLink || {},
  isLogin: state.singleSignOnReducer?.userInfoReducer?.isLogin || false
});

export default connect(mapStateToProps)(QuickNavigationItem);
