import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { get } from '@services/genericService';

import * as config from './config';

const getStoresListEpic = action$ =>
  action$.pipe(
    ofType(productTypes.GET_STORE_LIST),
    switchMap(action => {

      const params = {
        keyword: action.payload.keyword,
        latitude: action.payload.currentLatitude ?? action.payload.DefaultCenterLatitude,
        longitude: action.payload.currentLongitude ?? action.payload.DefaultCenterLongtitude,
        distance: action.payload.Distance
      };
      
      return from(get(config.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getStoresListSuccess(res);
          } else {
            return actions.getStoresListFailed('Failed');
          }
        })
      );
    })
  );

export default getStoresListEpic;
