import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import * as config from '@components/ClientPortal/GenericComponents/CPHeader/DemoSession/config';
import { getAppointmentList } from '@redux/actions/cpAppointmentListing';
import { cPOverview } from '@redux/actions/cPOverview';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

import CPPageHeader from '../../CPPageHeader';
import DashboardGroupLinks from '../DashboardGroupLinks';
import CPOverviewModel from '../Models/CPOverviewModel';

const CPOverview = ({ fields, cpOverviewData }) => {
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore') || null;
  const dispatch = useDispatch();
  const [cPOverviewFields, setCPOverviewFields] = useState(null);
  const leadStatus = useSelector((state) => state.cpAppointmentListingReducer.leadStatus);

  useEffect(() => {
    const cPSearchBarModel = new CPOverviewModel();

    setCPOverviewFields(cPSearchBarModel.getData(deepCopy(fields || {})));
  }, []);

  const getDate = (date) => {
    dispatch(
      cPOverview({
        PageNumber: 1,
        PageSize: 2147483647,
        Date: customeDate(date),
        WithCurrentStore: currentStore?.storeId === config.ALL_STORES_ID ? false : true
      })
    );

    dispatch(
      getAppointmentList({
        Date: customeDate(date),
        Status: leadStatus,
        WithCurrentStore: currentStore?.storeId === config.ALL_STORES_ID ? false : true
      })
    );
  };

  const checkNumber = (number) => (number < 10 ? '0' + number : number);

  const customeDate = (date) => {
    if (date) {
      const datePicker = new Date(date);
      const year = datePicker.getFullYear();
      const month = checkNumber(datePicker.getMonth() + 1);
      const day = checkNumber(datePicker.getDate());

      return year + '-' + month + '-' + day;
    }

    return;
  };

  useEffect(() => {
    dispatch(
      cPOverview({
        PageNumber: 1,
        PageSize: 2147483647,
        Date: customeDate(new Date()),
        WithCurrentStore: currentStore?.storeId === config.ALL_STORES_ID ? false : true
      })
    );
  }, []);

  return (
    cPOverviewFields && (
      <>
        <CPPageHeader
          type='date'
          title={cPOverviewFields['Overview Label']}
          getDate={getDate}
          taskListLabel={cPOverviewFields['Connect Task List Label']}
          taskListLink={cPOverviewFields['Connect Task List Link']}
        />
        <DashboardGroupLinks cPOverviewFields={cPOverviewFields} cpOverviewData={cpOverviewData} />
      </>
    )
  );
};

CPOverview.propTypes = {
  fields: PropTypes.any,
  cpOverviewData: PropTypes.any
};

const mapStateToProps = (state) => ({
  cpOverviewData: state.cpOverviewReducer.cpOverviewData,
});

export default connect(mapStateToProps)(CPOverview);
