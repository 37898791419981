import produce from 'immer';

import {
  CLEAR_VALIDATE_OTP_CODE_ERROR_MESSAGE,
  VALIDATE_OTP_CODE_FAILURE,
  VALIDATE_OTP_CODE_START,
  VALIDATE_OTP_CODE_SUCCESS
} from '@redux/actions/campaignRegister/validateOtp/types';

const initialState = {
  isLoading: false,
  validateResponse: null,
  error: null
};

const validateOtpReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case VALIDATE_OTP_CODE_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case VALIDATE_OTP_CODE_SUCCESS:
      draft.isLoading = false;

      draft.validateResponse = action.payload;
      break;

    case VALIDATE_OTP_CODE_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

    case CLEAR_VALIDATE_OTP_CODE_ERROR_MESSAGE:
      draft.error = null;
      break;
    }

    return draft;
  });

export default validateOtpReducer;
