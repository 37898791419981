export const CreateContactStatus = {
  SuccessStatusCode: [0, 25],
  WarningStatusCode: {
    25: 'Msg_041'
  },
  ErrorStatusCode: {
    4: 'Msg_038'
  },
  GeneralMsg: 'Msg_042'
};
