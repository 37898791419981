import './PhoneNumberSelect.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import Select from 'react-select';

const PhoneNumberSelect = ({ optionValues, currentValue, cssCustom, setPhoneNumberCodeEvt, isDisabled }) => {
  const [selectedOption, setSelectedOption] = useState(currentValue || optionValues?.[0]);

  const onChangePhoneCodeHandler = (option) => {
    setSelectedOption(option);

    setPhoneNumberCodeEvt(option.value);
  };

  useEffect(() => {
    if (currentValue) {
      const optionCurrent = optionValues.find((item) => item.value === currentValue);

      setSelectedOption(optionCurrent);
    }
  }, [currentValue]);

  const phoneNumberSelectClasses = useMemo(() => {
    let classDefault = cssCustom ? `form-group--select ${cssCustom}` : 'form-group--select';
    if (isDisabled) classDefault += ' phone-number-select__disabled';

    return classDefault;
  }, [cssCustom, isDisabled]);

  return (
    <div className={`${phoneNumberSelectClasses} phone-number-select`}>
      <Select
        options={optionValues}
        value={selectedOption}
        name='phone-number'
        className='customization-dropdown'
        classNamePrefix='customization-dropdown'
        isSearchable={false}
        isDisabled={isDisabled}
        onChange={(option) => onChangePhoneCodeHandler(option)}
      />
    </div>
  );
};

PhoneNumberSelect.propTypes = {
  optionValues: PropTypes.array,
  currentValue: PropTypes.string,
  cssCustom: PropTypes.string,
  setPhoneNumberCodeEvt: PropTypes.func,
  isDisabled: PropTypes.bool
};

export default PhoneNumberSelect;
