import './UniversalSearchBox.scss';

import { array, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import { POLICY_INIT_PAGE, POLICY_PAGESZ } from '@components/Search/PolicySearchResult/configs';
import { RESOURCE_SEARCH_PAGESZ } from '@components/Search/ProductSearchResult/configs';
import { behaviorSubjectSwitchTab$ } from '@components/Search/ProductSearchResult/switchTabBehavior';
import AutoCompleteUI from '@components/Share/AutoCompleteUI';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { WELL_BEING_INIT_PAGE, WELL_BEING_PAGESZ } from '@components/Wellness/WellnessListing/configs';
import {
  commonSearchPolicy,
  commonSearchProduct,
  commonSearchResource,
  commonSearchWellness,
  getAutoCompletePolicy,
  getAutoCompleteProduct,
  getAutoCompleteResource,
  getAutoCompleteWellness
} from '@redux/actions/common';
import { RESOURCE_SEARCH } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import UniversalSearchBoxModel from './model/UniversalSearchBoxModel';
import { behaviorSubjectWellnessObjectBehavior$ } from './wellnessObjectBehavior';

const UniversalSearchBox = (props) => {
  const {
    productParamsRequest,
    productSuggestionList,
    fields,
    wellnessParamsRequest,
    policyParamsRequest,
    wellnessSuggestionList,
    policySuggestionList,
    resourceParamsRequest,
    resourceSuggestionList
  } = props;

  const [dataFields, setDataFields] = useState(null);
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Product Search');
  const isGA4 = Global.getIsGA4();

  const onSearchHandler = (value) => {
    if (activeTab === 'Product Search') {
      if (value !== productParamsRequest?.Term) {
        dispatch(commonSearchProduct({ Term: value, dataSourcePath: props?.productParamsRequest?.dataSourcePath }));

        Global.genURLParamAndStayPage([{ key: 'Term', value: value }]);
      }
    }
    if (activeTab === 'Wellness Search') {
      if (value !== wellnessParamsRequest?.searchTerm) {
        dispatch(
          commonSearchWellness({
            searchTerm: value,
            tags: Global.onGetValueTagInParamsURL(Global.onGetKeyInParamUrl()),
            page: WELL_BEING_INIT_PAGE,
            pageSz: WELL_BEING_PAGESZ
          })
        );

        Global.genURLParamAndStayPage([{ key: 'Term', value: value }]);
      }
    }
    if (activeTab === 'Support Search') {
      if (value !== policyParamsRequest?.searchTerm) {
        dispatch(
          commonSearchPolicy({
            searchTerm: value,
            page: POLICY_INIT_PAGE,
            pageSz: POLICY_PAGESZ
          })
        );

        Global.genURLParamAndStayPage([{ key: 'Term', value: value }]);
      }
    }
    if (activeTab === RESOURCE_SEARCH) {
      if (value !== resourceParamsRequest?.searchTerm) {
        dispatch(
          commonSearchResource({
            searchTerm: value,
            page: WELL_BEING_INIT_PAGE,
            pageSz: RESOURCE_SEARCH_PAGESZ
          })
        );

        Global.genURLParamAndStayPage([{ key: 'Term', value: value }]);
      }
    }
  };

  const onSwitchTab = (type) => {
    behaviorSubjectSwitchTab$.next(type);
    let URLSearchTerm = Global.decodeURLQueryParams(Global.getQueryParamsNoLower(window.location.href, 'Term')) || '';

    if (type === 'Product Search') {
      if (URLSearchTerm !== productParamsRequest?.Term || activeTab !== 'Product Search') {
        dispatch(
          commonSearchProduct({
            Term: URLSearchTerm
          })
        );

        setActiveTab('Product Search');
      }
    }
    if (type === 'Wellness Search') {
      if (URLSearchTerm !== wellnessParamsRequest?.searchTerm || activeTab !== 'Wellness Search') {
        dispatch(
          commonSearchWellness({
            searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
            tags: Global.onGetValueTagInParamsURL(Global.onGetKeyInParamUrl()),
            page: WELL_BEING_INIT_PAGE,
            pageSz: WELL_BEING_PAGESZ
          })
        );

        setActiveTab('Wellness Search');
      }
    }
    if (type === 'Support Search') {
      if (URLSearchTerm !== policyParamsRequest?.searchTerm || activeTab !== 'Support Search') {
        dispatch(
          commonSearchPolicy({
            searchTerm: URLSearchTerm,
            page: POLICY_INIT_PAGE,
            pageSz: POLICY_PAGESZ
          })
        );

        setActiveTab('Support Search');
      }
    }
    if (type === RESOURCE_SEARCH) {
      if (URLSearchTerm !== resourceParamsRequest?.searchTerm || activeTab !== RESOURCE_SEARCH) {
        dispatch(
          commonSearchResource({
            searchTerm: URLSearchTerm,
            page: WELL_BEING_INIT_PAGE,
            pageSz: RESOURCE_SEARCH_PAGESZ
          })
        );

        setActiveTab(RESOURCE_SEARCH);
      }
    }
  };

  const onGetSuggestionListHandler = (type) => {
    if (type === 'Product Search') return getAutoCompleteProduct;
    if (type === 'Wellness Search') return getAutoCompleteWellness;
    if (type === 'Support Search') return getAutoCompletePolicy;
    if (type === RESOURCE_SEARCH) return getAutoCompleteResource;
  };

  const onSuggestionsListData = (type) => {
    if (type === 'Product Search') return productSuggestionList;
    if (type === 'Wellness Search') return wellnessSuggestionList;
    if (type === 'Support Search') return policySuggestionList;
    if (type === RESOURCE_SEARCH) return resourceSuggestionList;
  };

  useEffect(() => {
    const wellnessListingModel = new UniversalSearchBoxModel();

    setDataFields(wellnessListingModel.getData(deepCopy(fields) || {}));

    behaviorSubjectSwitchTab$.next(activeTab);
  }, []);

  useEffect(() => {
    behaviorSubjectWellnessObjectBehavior$.next({
      'Tags To Select': dataFields?.['Tags To Select'],
      'Filter By Label': dataFields?.['Filter By Label']
    });
  }, [dataFields]);

  //New Logic ON/OFF GA, GA4
  useDidUpdateEffect(() => {
    if (isGA4) {
      const params = {
        search_term: productParamsRequest?.Term
      };

      new GoogleAnalytic().gAnalytic4('event', 'search', params);
    }
  }, [productParamsRequest]);

  return (
    dataFields && (
      <div className='search-result'>
        <div className='search-result__heading'>
          <AutoCompleteUI
            placeholder={dataFields?.['PlaceHolder Text']?.value}
            searchTerm={productParamsRequest?.Term}
            suggestionsListData={onSuggestionsListData(activeTab)}
            onGetSuggestionListHandler={onGetSuggestionListHandler(activeTab)}
            onSearchHandler={onSearchHandler}
            activeTab={activeTab}
            onData={props}
          />
        </div>
        <div className='search-result__tabs'>
          <div className='container no-padding-right-mobile'>
            <Tabs
              onSelect={(tab) => onSwitchTab(tab)}
              defaultActiveKey={
                dataFields['Search Result Tabs'].find((tab) => tab?.fields?.['Search Type']?.value === 'Product Search')?.fields?.['Search Type']
                  ?.value
              }
            >
              {dataFields['Search Result Tabs'].length ? (
                dataFields['Search Result Tabs'].map((tab) => {
                  return <Tab eventKey={tab?.fields?.['Search Type']?.value} title={tab?.fields?.Text?.value} key={tab.id} />;
                })
              ) : (
                <></>
              )}
            </Tabs>
          </div>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  const productSearchReducer = state.commonSearchReducer.productSearchReducer;
  const wellnessSearchReducer = state.commonSearchReducer.wellnessSearchReducer;
  const policySearchReducer = state.commonSearchReducer.policySearchReducer;
  const resourceSearchReducer = state.commonSearchReducer.resourceSearchReducer;

  return {
    productSuggestionList: productSearchReducer.productSuggestionList || null,
    productParamsRequest: productSearchReducer.paramsRequest || null,

    wellnessSuggestionList: wellnessSearchReducer.wellnessSuggestionList || null,
    wellnessParamsRequest: wellnessSearchReducer.params || null,

    policySuggestionList: policySearchReducer.policySuggestionList || null,
    policyParamsRequest: policySearchReducer.params || null,

    resourceParamsRequest: resourceSearchReducer.params || null,
    resourceSuggestionList: resourceSearchReducer.resourceSuggestionList || null
  };
};

UniversalSearchBox.propTypes = {
  productParamsRequest: object,
  fields: object,
  productSuggestionList: array,
  wellnessParamsRequest: object,
  policyParamsRequest: object,
  wellnessSuggestionList: array,
  policySuggestionList: array,
  resourceParamsRequest: object,
  resourceSuggestionList: array,
  rendering: object
};

export default connect(mapStateToProps)(UniversalSearchBox);
