export const ICON_LIST_BACKGROUND = {
  SAND: 'Sand',
  TRANSPARENT: 'Transparent'
};

export const ICON_IN_ROW = {
  ONE_A_ROW: 'One-a-row',
  TWO_A_ROW: 'Two-a-row',
  THREE_A_ROW: 'Three-a-row',
};


export const formatClassItemInRow = (itemClass) => {
  const result = itemClass ? ` ${itemClass.toLowerCase()}` : '';

  return result;
};