import './WarrantyRegistrationThankYou.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { deepCopy } from '@utils/utility';

import WarrantyRegistrationThankYouModel from './models/WarrantyRegistrationThankYouModel';
import ProductPreviewSubmitted from './ProductPreviewSubmitted';
import ProductWarrantySubmitted from './ProductWarrantySubmitted';

const WarrantyRegistrationThankYou = ({ fields, isProductReview, productReviewSubmittedFields }) => {
  const [productWarrantyThankYouPageFields, setProductWarrantyThankYouPageFields] = useState(null);

  useEffect(() => {
    const productWarrantyThankYouPageModel = new WarrantyRegistrationThankYouModel();

    setProductWarrantyThankYouPageFields(productWarrantyThankYouPageModel.getData(deepCopy(fields || {})));
  }, []);

  return isProductReview && productReviewSubmittedFields ? (
    <ProductPreviewSubmitted layoutData={productReviewSubmittedFields} />
  ) : productWarrantyThankYouPageFields ? (
    <ProductWarrantySubmitted layoutData={productWarrantyThankYouPageFields} />
  ) : (
    <></>
  );
};

WarrantyRegistrationThankYou.propTypes = {
  fields: Proptypes.any,
  isProductReview: Proptypes.bool,
  productReviewSubmittedFields: Proptypes.any
};

export default WarrantyRegistrationThankYou;
