import { object } from 'prop-types';
import React, { Fragment, useCallback, useMemo } from 'react';

import {
  SCHEDULE_APPOINTMENT_PARAM_NAME,
  SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE
} from '@components/BookingExperience/ScheduleAppointmentComponent/FormikStep/configs';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const SelectionDetail = ({ layoutData }) => {
  const handleGetAnswerText = useCallback(
    (questionId, answerIds) => {
      const questionData = layoutData?.questions?.find((question) => question?.Id?.toLowerCase() === questionId?.toLowerCase());
      let answerText = [];
      if (questionData) {
        for (const id of answerIds) {
          const answerData = questionData?.AvailableAnswers?.find((answer) => answer?.Id?.toLowerCase() === id?.toLowerCase());
          if (answerData) answerText.push(answerData.Text);
        }
      }

      return answerText;
    },
    [layoutData?.questions]
  );

  const changeSelectionButtonLink = useMemo(() => {
    const appointmentId = layoutData?.appointment?.Id;
    const layoutLink = layoutData?.['Change Selection Link']?.value?.href;
    const changeSelectionLink = `${layoutLink}?${SCHEDULE_APPOINTMENT_PARAM_NAME}=${SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE}&appointment_id=${appointmentId}`;

    return layoutLink
      ? {
        value: {
          ...layoutData['Change Selection Link'].value,
          href: changeSelectionLink
        }
      }
      : '';
  }, [layoutData]);

  return layoutData?.appointment?.QuestionAnswers?.length ? (
    <div className='appointment-detail__body__item'>
      {layoutData?.appointment?.QuestionAnswers?.length ? (
        layoutData?.appointment?.QuestionAnswers?.map((ques, index) => {
          const label = layoutData?.['Selection Label']?.value?.replace('{number}', index + 1);
          const answerTextData = handleGetAnswerText(ques.QuestionId, ques.AnswerIds);

          return (
            <Fragment key={ques.QuestionId}>
              <h3 className='appointment-detail__body__item__title'>{label}</h3>
              {answerTextData.length ? (
                answerTextData.map((answer) => (
                  <Fragment key={answer}>
                    <h4 className='appointment-detail__body__item__content__title'>{answer}</h4>
                  </Fragment>
                ))
              ) : (
                <></>
              )}
            </Fragment>
          );
        })
      ) : (
        <></>
      )}
      {layoutData?.isUpComing ? (
        changeSelectionButtonLink ? (
          <a
            className='appointment-detail__body__item__button appointment-detail__body__item__button--m0 btn btn-outline-primary'
            href={changeSelectionButtonLink?.value?.href}
          >
            <Text field={layoutData?.['Change Selection Text']} />
          </a>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

SelectionDetail.propTypes = {
  layoutData: object
};

export default SelectionDetail;
