import { useCallback } from 'react';

import useMapUniqueProductFunction from './useMapUniqueProductFunction';

const useMapGeneralFunctionData = () => {
  const { uniqueFunctionName } = useMapUniqueProductFunction();

  return useCallback(
    (productFunctionData) => {
      const productFunctionDataTemp = { ...productFunctionData };
      const uniqueFunctionNameTemp = uniqueFunctionName[productFunctionDataTemp.functionLabel];
      if (uniqueFunctionNameTemp) {
        for (const functionItem of uniqueFunctionNameTemp) {
          const keywordToSearchItem = functionItem.Id || functionItem.FunctionName;
          if (!productFunctionDataTemp.productFunction.find((item) => item.Id === keywordToSearchItem || item.FunctionName === keywordToSearchItem)) {
            productFunctionDataTemp.productFunction = [...productFunctionDataTemp.productFunction, functionItem];
          }
        }
      }

      return productFunctionDataTemp?.productFunction;
    },
    [uniqueFunctionName]
  );
};

export default useMapGeneralFunctionData;
