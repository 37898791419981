import './CountdownTimer.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { deepCopy } from '@utils/utility';

import CountdownTimerItem from './CountdownTimerItem';
import CountdownTimerModel from './Model/CountdownTimerModel';

const CountdownTimer = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const countdownTimerModel = new CountdownTimerModel();

    setData(countdownTimerModel.getData(deepCopy(props.fields)));
  }, []);

  return (
    data && (
      <div className='countdown'>
        <CountdownTimerItem />
      </div>
    )
  );
};

CountdownTimer.propTypes = {
  fields: PropTypes.any
};

export default CountdownTimer;
