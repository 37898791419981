import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Connect Task List Label'];
const LINK_ARR = ['Connect Task List Link'];
const IMAGE_ARR = ['Connect Task Icon'];

export default class CPConnectTaskModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((link) => {
      this[link] = new SitecoreLinkModel();
    });

    IMAGE_ARR.forEach((link) => {
      this[link] = new SitecoreImageModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((text) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text].getData(dataSource[text]);
      }
    });

    LINK_ARR.forEach((link) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link].getData(dataSource[link]);
      }
    });

    IMAGE_ARR.forEach((image) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, image)) {
        this[image].getData(dataSource[image]);
      }
    });

    return this;
  }
}