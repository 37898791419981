import './ResourceSearchItem.scss';

import Proptypes from 'prop-types';
import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

const ResourceSearchItem = ({ dataFromAPI, dataFromLayout}) => {
  return dataFromAPI && dataFromLayout && (
    <div className='resource-search-item animated-slow fadeIn delay-100'>
      <div className='resource-search-item__image-wrap'>
        <a href={dataFromAPI.PageUrl} className='resource-search-item__image'>
          <img alt='Image' src={dataFromAPI.FeaturedImage} />
          {
            dataFromAPI.IsVideo ? (
              <div className='video__contents'>
                <div className='video__contents__btn-play'>
                  <div className='btn-play-icon'>
                    <span className='play-icon'></span>
                  </div>
                </div>
              </div>
            ) : ''
          }
        </a>
      </div>
      <div className='resource-search-item__content'>
        <a href={dataFromAPI.PageUrl} className='resource-search-item__content__title-link'>
          <h3 className='resource-search-item__content__title'>
            {dataFromAPI.Title}
          </h3>
        </a>
        <RichText className='resource-search-item__content__description' field={{value: dataFromAPI.Description}} />
        <a href={dataFromAPI.PageUrl} className='resource-search-item__content__link btn-link'>
          {dataFromLayout['Learn More Text'].value}
        </a>
      </div>
    </div>
  );
};

ResourceSearchItem.propTypes = {
  dataFromAPI: Proptypes.object,
  dataFromLayout: Proptypes.object,
};

export default ResourceSearchItem;
