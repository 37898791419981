import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreLinkModel from '@models/SitecoreTextModel';
import SitecoreImageModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Description', 'Section Title', 'Variant Text', 'View All Button Text'];

export default class CustomerProductPageModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
