import './FeaturedArticleItem.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const FeaturedArticleItem = ({ dataFields }) => {
  
  return dataFields && (
    <div className='featured-article-item'>
      <div className='featured-article-item__wrap'>
        <div className='featured-article-item__image'>
          <a href={dataFields.url} className='featured-article-item__image__link'>
            <img src={dataFields.FeaturedImage} alt={dataFields.fields['Title'].value ? dataFields.fields['Title'].value : 'feature item image'} className='featured-article-item__image__item'/>
          </a>
        </div>
      </div>
      {
        dataFields.fields.Tags ? (
          <ul className='featured-article-item__tags'>
            {dataFields.fields.Tags.map((item ,itemIdx) => {
              return (
                <span className='featured-article-item__tags__item' key={itemIdx}>{item}</span>
              );
            })}
          </ul>
        ) : ''
      }
      
      {
        dataFields.fields['Title'].value ? (
          <h3 className='featured-article-item__title'>
            <a href={dataFields.url} className='featured-article-item__link'>
              {dataFields.fields['Title'].value}
            </a>
          </h3>
        ) : ''
      }
      <div className='featured-article-item__sub-info'>
        {
          dataFields.fields['Time To Read'].value ? <p>{dataFields.fields['Time To Read'].value}</p> : ''
        }
        {
          dataFields.fields['PublishedDate'].value ? <Text tag='p' field={dataFields.fields['PublishedDate']}/> : <></>
        }
      </div>
    </div>
  );
};

FeaturedArticleItem.propTypes = {
  dataFields: PropTypes.any,
};

export default FeaturedArticleItem;
