export default class AddressDropdownOptionModel {
  constructor() {
    this['Display'] = '';

    this['label'] = '';

    this['value'] = '';

    this['Code'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Display')) {
      this['Display'] = dataSource['Display'];

      this['label'] = dataSource['Display'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Code')) {
      this['Code'] = dataSource['Code'];

      this['value'] = dataSource['Code'];
    }

    return this;
  }
}
