import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/spinAndWin';
import * as spinAndWinTypes from '@redux/actions/spinAndWin/spinAndWinTypes';
import { get, post } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

import * as config from '../config';

export const applyRewardEpic = (action$) =>
  action$.pipe(
    ofType(spinAndWinTypes.APPLY_REWARD),
    switchMap((action) => {
      const params = {
        sliceId: action.payload.SliceId,
        email: action.payload.Email
      };

      return from(post(config.ACTION_APPLY_REWARD, params)).pipe(
        concatMap((res) => {
          let getApplyReward;
          const language = SitecoreContextFactoryService.getValueContextItem('language');
          if (res.status == 200) {
            getApplyReward = actions.applyRewardSuccess(deepCopy(res?.data));
          } else {
            getApplyReward = actions.applyRewardFailed('Failed');
          }

          const paramsLuckyDraw = {
            luckyDrawId: action.payload.luckyDrawId
          };

          if (language) paramsLuckyDraw.language = language;

          return merge(
            of(getApplyReward),
            from(get(config.ACTION_GET_AVAILABLE_LUCKY_DRAW, paramsLuckyDraw)).pipe(
              map((response) => {
                if (response.status == 200) {
                  return actions.getAvailableLuckyDrawSuccess(deepCopy(response?.data));
                } else {
                  return actions.getAvailableLuckyDrawFailed('Failed');
                }
              })
            ),
            of(actions.getAvailableLuckyDrawFailed('Failed'))
          );
        })
      );
    })
  );
