import PropTypes from 'prop-types';
import React, { useEffect, useRef,useState } from 'react';

const CountdownTimerResend = ({ timmer, setEndTimer }) => {
  const [counter, setCounter] = useState(timmer);
  const id = useRef(null);

  useEffect(() => {
    id.current = window.setInterval(() => {
      setCounter((time) => time - 1);
    }, 1000);

    return () => window.clearInterval(id.current);
  }, []);

  useEffect(() => {
    if (counter === 0) {
      window.clearInterval(id.current);
      
      setEndTimer();
    }
  }, [counter]);

  return timmer && counter > 0 ? <span>{`(${counter} s)`}</span> : '';
};

CountdownTimerResend.propTypes = {
  timmer: PropTypes.number,
  setEndTimer: PropTypes.func
};

export default CountdownTimerResend;
