import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ProductInterestAccordion from '../QuickAccess/ProductInterestPopup/ProductInterestAccordion';
import ReviewProductInterestModel from './model/ReviewProductInterestModel';

const ReviewProductInterest = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new ReviewProductInterestModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  return layoutData ? (
    <div className='cp-review__content__info cp-review__content__info--product'>
      <div className='cp-review__content__info__box'>
        <Text className='cp-review__content__info__title' tag='h5' field={layoutData['Title']} />
      </div>
      <ProductInterestAccordion
        layoutData={layoutData}
        addNewProductLabel={<Text field={layoutData['New Product Text']} />}
        saveLabel={<Text field={layoutData['Save Button Text']} />}
        capturedLabel={<Text className='accordion-header__label' tag='span' field={layoutData?.['Captured Text']} />}
        editLabel={<Text field={layoutData?.['Edit Button Text']} />}
        cancelLabel='Cancel'
        posLabel={<Text field={layoutData?.['POS text']} />}
        componentName='cp-review'
      />
    </div>
  ) : (
    <></>
  );
};

ReviewProductInterest.propTypes = {
  fields: object
};

export default ReviewProductInterest;
