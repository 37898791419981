import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { getProductInfoStart } from '@redux/actions/compareProduct';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { productKeyStringArray } from '../configs';

const ProductSelect = ({ layoutData, componentNumber, categorySelected }) => {
  const dispatch = useDispatch();
  const { isLoading, productCompareData, listProducts } = useSelector((state) => state.compareProductReducer);
  const [productData, setProductData] = useState([]);

  useEffect(() => {
    const result = listProducts?.reduce((init, product) => {
      if (product.Text) {
        init.push({
          id: product.Id.toLowerCase().replace(/{|}/g, ''),
          value: product.Value,
          label: product.Text
        });
      }

      return init;
    }, []);

    setProductData(result);
  }, [listProducts]);

  const productSelectData = useMemo(() => {
    switch (componentNumber) {
    case 0:
      return productData?.filter((product) => product?.id !== productCompareData?.[1]?.id && product.id !== productCompareData?.[2]?.id);

    case 1:
      return productData?.filter((product) => product?.id !== productCompareData?.[0]?.id && product.id !== productCompareData?.[2]?.id);

    case 2:
      return productData?.filter((product) => product?.id !== productCompareData?.[0]?.id && product.id !== productCompareData?.[1]?.id);
    }
  }, [productCompareData, productData]);

  const handleDisabledSelect = () => {
    if (!categorySelected) {
      return true;
    } else {
      if (componentNumber === 1 && !productCompareData?.[0]) {
        return true;
      }
      if (componentNumber === 2 && !productCompareData?.[1]) {
        return true;
      }
    }
  };

  return (
    <div className='form-group'>
      <Select
        id={productKeyStringArray?.[componentNumber]}
        name={productKeyStringArray?.[componentNumber]}
        className='customization-dropdown'
        classNamePrefix='customization-dropdown'
        placeholder={layoutData['Choose Product Text'].value}
        value={productSelectData?.find((product) => product.id === productCompareData?.[componentNumber]?.id) || null}
        onChange={(selected) => {
          dispatch(getProductInfoStart({ productPageId: selected.id, componentNumber: componentNumber }));
        }}
        options={productSelectData}
        isDisabled={handleDisabledSelect()}
        isLoading={isLoading}
        noOptionsMessage={() => <Text field={layoutData?.['No Options Text']} tag='span' />}
      />
    </div>
  );
};

ProductSelect.propTypes = {
  layoutData: PropTypes.object,
  componentNumber: PropTypes.number,
  categorySelected: PropTypes.object,
  onChangeProductSelect: PropTypes.func
};

export default ProductSelect;
