import { useEffect, useRef } from 'react';

export const useDidUpdateEffect = (func, deps) => {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) func();
    else didMountRef.current = true;
  }, deps);
};


