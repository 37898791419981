import './ForgotEmail.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import ChangeEmail from './ChangeEmail';
import ForgotEmailModel from './models/ForgotEmailModel';
import VerifyThroughEmail from './VerifyThroughEmail';
import VerifyThroughOldEmail from './VerifyThroughOldEmail';

const ForgotEmail = ({fields}) => {
  const [forgotEmail, setForgotEmail] = useState({
    isVerifyThroughEmail: true,
    isVerifyOldEmail: false,
    isVerifyNewEmail: false,
  });
  const [dataSources, setDataSources] = useState(null);  
  const accountUser = useSelector((state) => state.singleSignOnReducer.userInfoReducer.accountUser);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  useEffect(() => {
    setDataSources(new ForgotEmailModel().getData(fields || {}));
  }, []);

  useDidUpdateEffect(() => {
    const user = SitecoreContextFactoryService.getValueContextItem('user');
    if(settingGlobal && !user) {
      window.location.href = settingGlobal?.LoginLink;
    }

  }, [settingGlobal]);

  const onVerifyOldEmailHandler = () => {
    setForgotEmail({
      ...forgotEmail,
      isVerifyThroughEmail: false,
      isVerifyOldEmail: true,
    });
  };

  return (
    <div className='forgot-email'>
      {dataSources && forgotEmail.isVerifyThroughEmail ? 
        <VerifyThroughEmail setVerifyOldEmail={onVerifyOldEmailHandler} dataSources={dataSources}/> 
        : ''}
      {dataSources && forgotEmail.isVerifyOldEmail ? 
        <VerifyThroughOldEmail 
          dataSources={dataSources} 
          emailUser={accountUser?.Email} 
          setInputNewEmailEvt={() => setForgotEmail({
            ...forgotEmail,
            isVerifyThroughEmail: false,
            isVerifyOldEmail: false,
            isVerifyNewEmail: true
          })}/> 
        : ''}
      {dataSources && forgotEmail.isVerifyNewEmail ? 
        <ChangeEmail dataSources={dataSources}/> 
        : ''}
    </div>
  );
};

ForgotEmail.propTypes = {
  accountUser: PropTypes.object,
  fields: PropTypes.object
};

export default ForgotEmail;
