import produce from 'immer';

import {
  UPDATE_BOOKING_EXPERIENCE_CONTACT_FAILURE,
  UPDATE_BOOKING_EXPERIENCE_CONTACT_START,
  UPDATE_BOOKING_EXPERIENCE_CONTACT_SUCCESS
} from '@redux/actions/bookingExperience/updateBookingExperienceContact/types';

const initialStates = {
  isLoading: false,
  updateResponse: null,
  error: null
};

const updateBookingExperienceContactReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case UPDATE_BOOKING_EXPERIENCE_CONTACT_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case UPDATE_BOOKING_EXPERIENCE_CONTACT_SUCCESS:
      draft.isLoading = false;

      draft.updateResponse = action.payload;
      break;

    case UPDATE_BOOKING_EXPERIENCE_CONTACT_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default updateBookingExperienceContactReducer;
