import produce from 'immer';

import {
  GET_NOTIFICATION_FAILURE,
  GET_NOTIFICATION_START,
  GET_NOTIFICATION_SUCCESS
} from '@redux/actions/cpNotificationPopup/notificationData/types';

const initialState = {
  isLoading: false,
  error: null,
  notificationLogs: []
};

const notificationDataReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_NOTIFICATION_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case GET_NOTIFICATION_SUCCESS:
      draft.isLoading = false;

      draft.notificationLogs = action.payload;
      break;

    case GET_NOTIFICATION_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default notificationDataReducer;
