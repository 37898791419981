import { bool, func, object } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { IS_CLICK_OUTSIDE } from '@components/Share/ModalComponent/constant';
import { cancelEventBookingStart } from '@redux/actions/eventBooking';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const CancelMyAppointmentModal = ({ layoutData, isShow, onCloseModal }) => {
  const dispatch = useDispatch();
  const { cancelResponse, error } = useSelector((state) => state.eventBookingReducer.cancelEventBookingReducer);

  useEffect(() => {
    if (cancelResponse && cancelResponse?.Success) {
      window.location.href = `/my-account/Member Events/Event Cancel?itemName=${layoutData?.['Title']?.value}`;
    }
  }, [cancelResponse, cancelResponse?.Success]);

  const handleCloseModal = (isClickOutside) => {
    if (isClickOutside === IS_CLICK_OUTSIDE) {
      onCloseModal();
    } else {
      onCloseModal();
    }
  };

  const handleCancelEventBooking = () => {
    const cancelEventData = {
      EventId: layoutData?.['EventId']?.value,
      UserName: SitecoreContextFactoryService.getValueContextItem('user')?.name
    };

    dispatch(cancelEventBookingStart(cancelEventData));
  };

  return (
    <ModalComponent
      isShow={isShow}
      onCloseHandler={handleCloseModal}
      titlePopup={layoutData['Title Cancel Popup'].value}
      cssClass='popup-banner__content my-appointment__modal'
    >
      <div className='modal-container'>
        <div className='modal-wrapper'>
          <div className='confirm-container'>
            <div className='confirm-wrapper'>
              <div className='confirm-question'>
                <Text tag='h5' field={layoutData['Content Cancel Popup']} />
              </div>
              <div className='confirm-cta'>
                <button className='btn btn-primary' onClick={onCloseModal}>
                  <Text field={layoutData['Do Not Cancel Button Text']} />
                </button>
                {error ? <span className='error-validate'>{error}</span> : <></>}
                <button className='btn btn-link' onClick={handleCancelEventBooking}>
                  <Text field={layoutData['Cancel Booking Button Text']} />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalComponent>
  );
};

CancelMyAppointmentModal.propTypes = {
  layoutData: object,
  isShow: bool,
  onCloseModal: func
};

export default CancelMyAppointmentModal;
