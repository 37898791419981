import './ContactUs.scss';

import React from 'react';

const ContactUs = () => {
  return (
    <div className='contact-form'>
      <div className='container'>
        <h2 className='contact-form__heading experience-title'>Have us contact you</h2>
        <form className='contact-form__content'>
          <div className='form-group'>
            <label htmlFor='firstName'>First Name *</label>
            <input type='text' className='form-control form-control-lg' id='firstName' placeholder='First Name'/>
          </div>
          <div className='form-group'>
            <label htmlFor='lastName'>Last Name *</label>
            <input type='text' className='form-control form-control-lg' id='lastName' placeholder='Last Name'/>
          </div>
          <div className='form-group'>
            <label htmlFor='email'>Email *</label>
            <input type='email' className='form-control form-control-lg' id='email' placeholder='Email'/>
          </div>
          <div className='form-group'>
            <label htmlFor='phone'>Phone Number *</label>
            <input type='text' className='form-control form-control-lg' id='phone' placeholder='Phone Number'/>
          </div>
          <div className='form-group'>
            <label htmlFor='message'>Message *</label>
            <textarea className='form-control form-control-lg' id='message' placeholder='Message' rows='4'></textarea>
          </div>
          <div className='form-check'>
            <input className='form-check-input' type='checkbox' value='' id='defaultCheck1' checked='checked'/>
            <label className='form-check-label' htmlFor='defaultCheck1'>I agree with OSIM's Terms and Conditions</label>
          </div>
          <div className='form-check'>
            <input className='form-check-input' type='checkbox' value='' id='defaultCheck2'/>
            <label className='form-check-label' htmlFor='defaultCheck2'>PDPA copy and terms goes here</label>
          </div>
          <div className='form-group contact-form__content__btn'>
            <button className='contact-form__content__btn__item btn btn-primary btn-block btn-lg'>SEND</button>
          </div>
        </form>
        <div className='contact-form__support'>
          <h2 className='contact-form__support__heading experience-title'>Self-Support</h2>
          <p className='contact-form__support__desc'>Explore support related topic while we get back to you as soon as possible.</p>
          <a href='#' className='contact-form__support__btn btn btn-outline-primary'>Explore Support Section</a>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;