import './EndDemo.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link,Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CPPageHeader from '../CPPageHeader';
import EndDemoModel from './Models/EndDemoModel';

const EndDemo = ({fields}) => {
  const [endDemoFields, setEndDemoFields] = useState(null);

  useEffect(() => {
    const endDemoModel = new EndDemoModel();

    setEndDemoFields(endDemoModel.getData(deepCopy(fields || {})));
  }, []);

  return endDemoFields && (
    <>
      <CPPageHeader title={endDemoFields.Title}/>
      <div className='info-box'>
        <div className='container tablet-container'>
          <div className='info-box__content'>
            <div className='info-box__content__image'>
              <Image field={endDemoFields.Image} />
            </div>
            <Text tag='p' className='info-box__content__text' field={endDemoFields.Description} />
            <div className='info-box__content__btn'>
              <Link field={endDemoFields['Button Link']} className='btn btn-primary'>
                <Text field={endDemoFields['Button Text']} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

EndDemo.propTypes = {
  fields: PropTypes.any,
};

export default EndDemo;