export default class BOGetDataStatusModel {
  constructor() {
    this.LastLabel = '';

    this.LastFewUnitsLeftLabel = '';

    this.LimitStockQuantity = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastFewUnitsLeftLabel')) {
      this.LastFewUnitsLeftLabel = dataSource.LastFewUnitsLeftLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LimitStockQuantity')) {
      this.LimitStockQuantity = dataSource.LimitStockQuantity;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastLabel') && dataSource.LastLabel) {
      this.LastLabel = dataSource.LastLabel;
    }

    return this;
  }
}
