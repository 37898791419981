import './SelectAddressPopup.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BookedAddressModel from './model/bookedAddressModel';

const SelectAddressPopup = ({ type, listAddress, selectAddress, idSelected, isDelivery, layoutData, deliveryType, cityCode }) => {
  const [selectedItem, setSelectedItem] = useState({});
  const [itemIdField, setItemIdField] = useState('');
  const [checkAddress, setCheckAddress] = useState(null);
  const [listShowAddress, setListShowAddress] = useState(null);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);

  useEffect(() => {
    if (type === 'user') {
      setItemIdField('ExternalId');
      if (isDelivery) {
        getListShowAddress('IsPrimary');

        setCheckAddress('IsPrimary');
      } else {
        getListShowAddress('IsBilling');

        setCheckAddress('IsBilling');
      }
    } else {
      setListShowAddress(listAddress);

      setItemIdField('ItemId');
    }
  }, []);

  const getListShowAddress = (field) => {
    let listShowAddress = [];

    let defaultAddress = listAddress.find(item => {
      return item[field];
    });

    if (defaultAddress) {
      const addresssModel = new BookedAddressModel();

      listShowAddress.push(addresssModel.getData(defaultAddress));
    }

    listAddress.forEach(item => {
      if ((defaultAddress && item.ExternalId !== defaultAddress.ExternalId) || !defaultAddress) {
        const addresssModel = new BookedAddressModel();

        listShowAddress.push(addresssModel.getData(item));
      }
    });

    setListShowAddress(listShowAddress);
  };

  return listShowAddress && (
    <div className='my-address'>
      <div className='my-address__list'>
        <div className='row'>
          {listShowAddress.map((item, ind) => {
            return (
              <div className='col-md-6 my-address__item' key={ind}>
                <div
                  className={`select-address ${item[itemIdField] === selectedItem[itemIdField] ? 'active' : ''} ${item[itemIdField] === idSelected ? 'selected' : ''} ${deliveryType == 'delivery-by-sf-express' && item?.['CityCode'] != cityCode ? 'disabled' : ''}`}
                  onClick={() => setSelectedItem(item)}
                >
                  {type === 'store' ? (
                    <>
                      <h5 className='select-address__type'>{item.ItemName}</h5>
                      {item.IsPrimary ? <small className='item-default'>{layoutData['Default Label'].value}</small> : ''}
                      <p>{item.TitleName}</p>
                      <p>{item.AddressName}</p>
                    </>
                  ) : (
                    <>
                      <h5 className='select-address__type'>{item.AddressName}</h5>
                      {item[checkAddress] ? <small className='item-default'>{layoutData['Default Label'].value}</small> : ''}
                      <p className='select-address__full-name'>{item.FirstName} {item.LastName}</p>
                      <p className='select-address__info-line' dangerouslySetInnerHTML={{ __html: item.FullAddress }}></p>
                      <p className='select-address__info-line'>{`${item.Country} ${item.Postcode}`}</p>
                      <p className='select-address__info-line'>{layoutData['Phone Number'].value}: {item.PhoneNumber}</p>
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className='my-address__btn text-center'>
        <button className='btn btn-outline-primary' onClick={() => selectAddress(selectedItem)}>
          {layoutData['PopUp Select Label'].value}
        </button>
      </div>
    </div>
  );
};

SelectAddressPopup.propTypes = {
  listAddress: PropTypes.array,
  selectAddress: PropTypes.func,
  type: PropTypes.string,
  idSelected: PropTypes.string,
  isDelivery: PropTypes.bool,
  layoutData: PropTypes.any,
  deliveryType: PropTypes.string,
  cityCode: PropTypes.string
};

export default SelectAddressPopup;
