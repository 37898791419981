import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export const useMilestoneData = (milestoneData) => {
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);

  const milestoneDataArray = useMemo(() => {
    return milestoneData?.Milestone || [];
  }, [milestoneData]);

  const milestoneDataAvailable = useMemo(() => {
    if (milestoneDataArray.length) {
      return milestoneDataArray.filter((milestone) => {
        if (milestone?.Enable?.value) {
          let inTime = false;
          const startTime = milestone?.['Start Time']?.value;
          const endTime = milestone?.['End Time']?.value;
          if (startTime && endTime) {
            const currentTime = new Date().getTime();
            const startTimeConvertToTime = new Date(startTime).getTime();
            const endTimeConvertToTime = new Date(endTime).getTime();

            if (currentTime >= startTimeConvertToTime && currentTime <= endTimeConvertToTime) inTime = true;
          }

          return inTime;
        }
      });
    }

    return [];
  }, [milestoneDataArray]);

  const currentSubtotalAmount = useMemo(() => {
    return currentCart?.SubtotalAmount || 0;
  }, [currentCart]);

  const currentTierProgress = useMemo(() => {
    let tierObject = null;
    if (milestoneDataAvailable.length) {
      const currentTierIndex = milestoneDataAvailable.findIndex((item) => {
        const tierUpperBoundValueString = item?.['Tier Upper Bound']?.value || 0;
        const tierLowerBoundValueString = item?.['Tier Lower Bound']?.value || 0;

        if (tierLowerBoundValueString && tierUpperBoundValueString) {
          const lowerBoundNumber = Number(tierLowerBoundValueString);
          const upperBoundNumber = Number(tierUpperBoundValueString);

          return currentSubtotalAmount >= lowerBoundNumber && currentSubtotalAmount < upperBoundNumber;
        }

        return -1;
      });
      if (currentTierIndex >= 0) tierObject = milestoneDataAvailable[currentTierIndex];
    }

    return tierObject;
  }, [currentSubtotalAmount, milestoneDataAvailable]);

  const isShowMilestoneProgressBar = useMemo(() => {
    return milestoneData && currentTierProgress;
  }, [milestoneData, currentTierProgress]);

  const tierUpperBoundValue = useMemo(() => {
    return currentTierProgress?.['Tier Upper Bound']?.value || 0;
  }, [currentTierProgress]);

  const cartMilestoneProgress = useMemo(() => {
    return (currentSubtotalAmount / Number(tierUpperBoundValue)) * 100;
  }, [currentSubtotalAmount, tierUpperBoundValue]);

  const messageConvertedData = useMemo(() => {
    const message = currentTierProgress?.Message;
    const nextTiers = tierUpperBoundValue - currentSubtotalAmount;

    return message?.value
      ? {
        ...message,
        value: message.value?.replace('{amount}', new Intl.NumberFormat('en-US').format(nextTiers))
      }
      : {
        value: ''
      };
  }, [currentSubtotalAmount, currentTierProgress, tierUpperBoundValue]);

  return useMemo(
    () => ({
      isShowMilestoneProgressBar,
      currentTierProgress,
      messageConvertedData,
      cartMilestoneProgress
    }),
    [isShowMilestoneProgressBar, currentTierProgress, messageConvertedData, cartMilestoneProgress]
  );
};
