const textLabel = [
  'Address1',
  'Address2',
  'AddressName',
  'Area',
  'AreaCode',
  'City',
  'CityCode',
  'Country',
  'CountryCode',
  'District',
  'DistrictCode',
  'ExternalId',
  'FirstName',
  'FullAddress',
  'LastName',
  'PhoneNumber',
  'Postcode',
  'State',
  'StateCode',
  'SubDistrict',
  'SubDistrictCode',
  'Suburb',
];


export default class BookedAddressModel {
  constructor() {
    textLabel.forEach((itemText) => {
      this[itemText] = '';
    });

    this.IsBilling = false;

    this.IsPrimary = false;
  }
  getData(dataSource) {
    textLabel.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText) && dataSource[itemText]) {
        this[itemText] = dataSource[itemText];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsBilling')) {
      this.IsBilling = dataSource.IsBilling;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsPrimary')) {
      this.IsPrimary = dataSource.IsPrimary;
    }

    return this;
  }
}