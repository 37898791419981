import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = ['Select Product Label', 'Sign In Label', 'Description'];

const LINKARR = [];

const IMGARR = ['Sign In Image', 'Select Product Image', 'Hightlighted Select Product Image', 'Hightlighted Sign In Image'];

export default class WarrantyRegistrationProcessModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMGARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMGARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
