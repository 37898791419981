import PropTypes from 'prop-types';
import React, { useState } from 'react';

import {REVIEW_INPUT_TYPE, REVIEW_TEXTAREA_TYPE} from '@utils/constant';

const ReviewInput = ({ 
  type=REVIEW_INPUT_TYPE, 
  inputMaxLength, inputId, 
  inputName=inputId, 
  placeholder, 
  remainingText, 
  noteText,
  onChangeMessage,
  inputValue
}) => {

  const limitCharacter = (string, maxLength) => string?.substring(0, maxLength);

  return (
    <>
      {
        type === REVIEW_TEXTAREA_TYPE ? (
          <textarea
            className='form-control form-control-lg'
            id={inputId}
            name={inputName}
            maxLength={inputMaxLength}
            value={limitCharacter(inputValue, inputMaxLength)}
            onChange={(e) => onChangeMessage(e)}
            placeholder={placeholder}
            rows='4'
          />
        ) : (
          <input 
            type='text' 
            id={inputId}
            name={inputName}
            className='form-control form-control-lg'
            maxLength={inputMaxLength}
            value={limitCharacter(inputValue, inputMaxLength)}
            onChange={(e) => onChangeMessage((e))}
            placeholder={placeholder} 
          />
        )
      }
      { noteText && <label className='label-note label-note--no-mb'>{noteText}</label>}
      <label htmlFor={inputId} className='label-note' >
        {remainingText.replace('{number}', inputMaxLength - inputValue?.length || 0)}
      </label>
    </>
  );
};

ReviewInput.propTypes = {
  type: PropTypes.string,
  inputMaxLength: PropTypes.number,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  placeholder: PropTypes.string,
  remainingText: PropTypes.string,
  noteText: PropTypes.string,
  onChangeMessage: PropTypes.func,
  inputValue: PropTypes.string
};     

export default ReviewInput;