import { BOOK_APPOINTMENT_FAILURE, BOOK_APPOINTMENT_START, BOOK_APPOINTMENT_SUCCESS } from './types';

export const bookAppointmentStart = (payload) => ({
  type: BOOK_APPOINTMENT_START,
  payload
});

export const bookAppointmentSuccess = (payload) => ({
  type: BOOK_APPOINTMENT_SUCCESS,
  payload
});

export const bookAppointmentFailure = (payload) => ({
  type: BOOK_APPOINTMENT_FAILURE,
  payload
});
