import './CenterBanner.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CenterBannerFieldsModel from './model/CenterBannerFieldsModel';

const CenterBanner = (props) => {
  const { fields } = props;
  const [centerBannerFields, setCenterBannerFields] = useState(null);

  useEffect(() => {
    const centerBannerFieldsModel = new CenterBannerFieldsModel();

    setCenterBannerFields(centerBannerFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return centerBannerFields ? (
    <div className='center-banner'>
      <div className='center-banner__container'>
        <div className='center-banner__item'>
          <Link field={centerBannerFields['Link']}>
            <ResponsiveImage 
              imageMobile={centerBannerFields['Mobile Background Image']}
              srcSet={[{ mw: 768, image: centerBannerFields['Background Image'] }]}
            />
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='main-banner skeleton__item'>
        <div className='main-banner__background main-banner__background--white'>
          <Loading></Loading>
        </div>
      </div>
    </LoadingShimmer>
  );
};

CenterBanner.propTypes = {
  fields: PropTypes.object
};

export default CenterBanner;
