import React, { createRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import SwipeComponent from '@components/Share/SwipeComponent';
import { getListProductDefaultStart, getListProductStart, removeProductCompare } from '@redux/actions/compareProduct';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useWindowSize } from '@utils/customsHook/useWindowSize';

import CompareProductItem from '../CompareProductItem';
import { paramsRecently, WIDTH_BOUNDARY } from '../configs';
import useCompareProduct from '../hooks/useCompareProduct';
import useCompareProductData from '../hooks/useCompareProductData';
import useSetBenefitDetailHeight from '../hooks/useSetBenefitDetailHeight';
import useSetFunctionValueHeight from '../hooks/useSetFunctionValueHeight';
import RecentlyProductItem from '../RecentlyProductItem';

const CompareProductLayout = () => {
  const compareItemRef = useRef([]);
  const dispatch = useDispatch();
  const { listProducts, listProductDefault } = useSelector((state) => state.compareProductReducer);
  const { layoutData, categorySelected, setCategorySelected, setProductVariantItemHeight } = useCompareProduct();
  const compareProductData = useCompareProductData();
  const CURRENT_WIDTH = useWindowSize();
  const setFunctionValueHeight = useSetFunctionValueHeight();
  const setBenefitDetailHeight = useSetBenefitDetailHeight();

  const [category, setCategory] = useState(null);
  const [listProductDefaultConvert, setListProductDefaultConvert] = useState([]);
  const [recentlyProductSelected, setRecentlyProductSelected] = useState(null);

  useEffect(() => {
    const result = layoutData?.['List Product Category']?.reduce((init, category) => {
      init.push({
        id: category.Id,
        value: category.Value,
        label: category.Text
      });

      return init;
    }, []);

    setCategory(result);

    const categoryPageId = new URLSearchParams(window.location.search).get('categoryPageId');

    const categoryNameFill = result?.find((category) => category?.id?.toLowerCase() === categoryPageId?.toLowerCase());

    if (categoryNameFill) {
      dispatch(getListProductStart({ categoryId: categoryNameFill.id }));

      setCategorySelected(categoryNameFill);
    }
  }, [layoutData]);

  useEffect(() => {
    const productPageIdUrl = new URLSearchParams(window.location.search).get('productPageId');
    let productIsInCategoryNameFill = null;
    let productIsInListProductDefault = null;

    if (productPageIdUrl) {
      productIsInCategoryNameFill = listProducts?.find((product) => product.Id.toLowerCase().replace(/{|}/g, '') === productPageIdUrl);

      productIsInListProductDefault = listProductDefault?.find((product) => product.toLowerCase().replace(/{|}/g, '') === productPageIdUrl);
    }
    if (recentlyProductSelected) {
      productIsInCategoryNameFill = listProducts?.find((product) => product.Id.toLowerCase().replace(/{|}/g, '') === recentlyProductSelected?.Id);

      productIsInListProductDefault = listProductDefault?.find(
        (product) => product.toLowerCase().replace(/{|}/g, '') === recentlyProductSelected?.Id
      );
    }

    if (categorySelected) {
      if (productIsInCategoryNameFill && !productIsInListProductDefault) {
        let listProductDefaultTmp = [...listProductDefault];

        listProductDefaultTmp[0] = productIsInCategoryNameFill.Id;

        setListProductDefaultConvert(listProductDefaultTmp);
      } else {
        setListProductDefaultConvert(listProductDefault);
      }
    }
  }, [listProducts, listProductDefault, recentlyProductSelected]);

  useEffect(() => {
    listProductDefaultConvert.length && dispatch(getListProductDefaultStart(listProductDefaultConvert));
  }, [listProductDefaultConvert]);

  useEffect(() => {
    if (CURRENT_WIDTH && CURRENT_WIDTH < WIDTH_BOUNDARY && compareProductData[2]) {
      dispatch(removeProductCompare(compareProductData[2]));
    }
  }, [CURRENT_WIDTH]);

  useEffect(() => {
    if (compareProductData.every((product) => product === null) && recentlyProductSelected) {
      setRecentlyProductSelected(null);
    }
  }, [compareProductData]);

  useLayoutEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [categorySelected]);

  const checkCurrentNull = useMemo(() => {
    return CURRENT_WIDTH > WIDTH_BOUNDARY ? compareItemRef.current.every((ref) => ref.current) : compareItemRef.current.some((ref) => ref.current);
  }, [CURRENT_WIDTH, compareItemRef?.current, compareProductData]);

  useLayoutEffect(() => {
    if (compareProductData?.length) {
      compareItemRef.current = compareProductData.map((_, index) => compareItemRef.current[index] ?? createRef());
    }
  }, [compareProductData]);

  useLayoutEffect(() => {
    if (checkCurrentNull) {
      const handleSetFunctionValueHeight = async (compareItemRef) => await setFunctionValueHeight(compareItemRef);

      document.addEventListener('resize', async () => {
        await handleSetFunctionValueHeight(compareItemRef);
      });

      (async () => await handleSetFunctionValueHeight(compareItemRef))();

      return () =>
        document.removeEventListener('resize', async () => {
          await handleSetFunctionValueHeight(compareItemRef);
        });
    }
  }, [compareItemRef.current, checkCurrentNull, compareProductData]);

  useLayoutEffect(() => {
    if (checkCurrentNull) {
      const handleSetBenefitDetailHeight = async () => await setBenefitDetailHeight(compareItemRef);

      document.addEventListener('resize', async () => {
        await handleSetBenefitDetailHeight(compareItemRef);
      });

      (async () => await handleSetBenefitDetailHeight(compareItemRef))();

      return () =>
        document.removeEventListener('resize', async () => {
          await handleSetBenefitDetailHeight(compareItemRef);
        });
    }
  }, [compareItemRef.current, checkCurrentNull, compareProductData]);

  const handleSelectCategory = (category) => {
    dispatch(getListProductStart({ categoryId: category.id }));

    // INFO: re-init product variant item data
    setProductVariantItemHeight([]);

    setCategorySelected(category);
  };

  const handleSelectRecentProduct = (product) => {
    const productCategory = category?.find((category) => category.value === product.CategoryName);

    // set state for category selected
    handleSelectCategory(productCategory);

    // set state recently product
    setRecentlyProductSelected(product);
  };

  return (
    <>
      <div className='container compare-product'>
        <div className='compare-product__select-category'>
          <div className='form-group'>
            <Select
              id='compare-category'
              name='compare-category'
              onChange={(category) => category !== categorySelected && handleSelectCategory(category)}
              value={categorySelected}
              options={category}
              className='customization-dropdown'
              classNamePrefix='customization-dropdown'
              placeholder={layoutData['Choose Category Text'].value}
              isLoading={!layoutData['List Product Category'].length}
              noOptionsMessage={() => <Text field={layoutData?.['No Options Text']} tag='span' />}
            />
          </div>
        </div>
        <div className='compare-product__container'>
          {compareProductData?.map((product, index) =>
            CURRENT_WIDTH && CURRENT_WIDTH < WIDTH_BOUNDARY && index === 2 ? (
              ''
            ) : (
              <CompareProductItem
                ref={compareItemRef.current[index]}
                layoutData={layoutData}
                key={index}
                componentNumber={index}
                categorySelected={categorySelected}
                productInfo={product}
              />
            )
          )}
        </div>
      </div>
      {compareProductData[0] ? (
        <div className='product-view-compare-product'>
          <div className='container compare-product'>
            <div className='compare-product__container'>
              {compareProductData?.map((product, index) =>
                CURRENT_WIDTH && CURRENT_WIDTH < WIDTH_BOUNDARY && index === 2 ? (
                  ''
                ) : (
                  <CompareProductItem
                    layoutData={layoutData}
                    key={index}
                    componentNumber={index}
                    categorySelected={categorySelected}
                    productInfo={product}
                    isRecently={true}
                  />
                )
              )}
            </div>
          </div>
        </div>
      ) : (
        <>
          <Text tag='p' className='compare-product__no-product' field={layoutData['No Products Text']} />
          {layoutData['Recently Viewed Products'].length ? (
            <div className='recently-compare-product'>
              <div className='recently-viewed-products animated-slow fadeIn delay-100'>
                <div className='container'>
                  <Text
                    field={layoutData['Recently Viewed Product Title']}
                    tag='h2'
                    editable={true}
                    className='recently-viewed-products__title experience-title text-center'
                  />
                  <div className='recently-viewed-products__swiper'>
                    {layoutData['Recently Viewed Products'] && (
                      <SwipeComponent param={paramsRecently}>
                        {layoutData['Recently Viewed Products'].map((item, index) => {
                          if (item.ProductId && item.ProductName) {
                            return (
                              <div className='swiper-slide recently-viewed-products__contents' key={index}>
                                <RecentlyProductItem
                                  product={item}
                                  layoutData={layoutData}
                                  onSelectRecentProduct={() => handleSelectRecentProduct(item)}
                                />
                              </div>
                            );
                          }
                        })}
                      </SwipeComponent>
                    )}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

export default CompareProductLayout;
