import Proptypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import InfoStockComponent from '@components/Share/InfoStockComponent';
import Message from '@components/Share/Message';
import { addChosenBOVariant } from '@redux/actions/buyingOptions';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { STOCK_QUANTITY } from '@utils/constant';
import { useGetMemberUsualText } from '@utils/customsHook/useGetMemberUsualText';
import Global from '@utils/global';

const BOVariantsList = ({ contentData, activeItemsId, isSelected, onToogleFnc, productData }) => {
  const dispatch = useDispatch();
  const settingStockProduct = useSelector((state) => state.buyingOptionReducer.BOGetDataStatusReducer.settingStockProduct);
  const priceExtraInformation = useSelector((state) => state.buyingOptionReducer.BORentalsReducer.priceExtraInformation);
  const isGA4 = Global.getIsGA4();
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const { memberText, usualText } = useGetMemberUsualText();

  useEffect(() => {
    if (contentData.length === 1) {
      onClickBuyingItemHandler(contentData[0]);
    }
  }, []);

  const onClickBuyingItemHandler = (item) => {
    dispatch(addChosenBOVariant(item.VariantId));

    if (productData) {
      productData.VariantId = activeItemsId?.[0];
    }

    // Push data to GA4
    if (isGA4) {
      let paramGA4 = {
        ecommerce: Global.handleSwitchVariant(productData, item)
      };

      new GoogleAnalytic().gAnalytic4('event', 'switch_variant', paramGA4);
    }

    onToogleFnc();
  };

  const isActiveItems = (listId, itemId) => {
    return listId.includes(itemId);
  };

  return (
    <div className={`${isClientTelingUser ? 'variant-buying-list-cp' : 'variant-buying-list'}`}>
      <div className='row'>
        {contentData.map((item, itemIndex) => {
          return (
            <div className={`${isClientTelingUser ? 'col-6' : 'col-lg-6'} variant-buying-list__item-wrap`} key={itemIndex}>
              <div
                className={
                  'variant-buying-list__item' +
                  `${!isSelected && isActiveItems(activeItemsId, item.VariantId) ? ' active' : ''}` +
                  `${item.Stock.FinalStatus.isAvaiable === false ? ' disable' : ''}`
                }
                onClick={() => onClickBuyingItemHandler(item)}
                key={itemIndex}
              >
                <div className='variant-buying-list__item__image'>
                  <img src={item.Images[0]} alt='image' />
                </div>
                <div className='variant-buying-list__item__group'>
                  {settingStockProduct ? (
                    <InfoStockComponent
                      stock={{
                        ...item.Stock,
                        LimitStockQuantity: item.LimitStockQuantity || settingStockProduct.LimitStockQuantity
                      }}
                    >
                      {item.LimitStockQuantity
                        ? item.LastLabel
                        : settingStockProduct.LimitStockQuantity
                          ? settingStockProduct.LastLabel.replace(STOCK_QUANTITY, item.Stock.PurchasableLimit)
                          : ''}
                    </InfoStockComponent>
                  ) : (
                    ''
                  )}
                  <span className='variant-buying-list__item__name'>{item.VariantPropertyValue}</span>
                  {item.Stock.FinalStatus.isAvaiable === false ? (
                    <Fragment>
                      <span className='variant-buying-list__item__status'>
                        <Message messageCode='LBL-OutOfStock' />
                      </span>
                    </Fragment>
                  ) : (
                    <Fragment>
                      <div className='variant-buying-list__item__price'>
                        {item.Price.SellingPriceWithCurrency === item.Price.ListPriceWithCurrency ? (
                          <span className='variant-buying-list__item__price__selling-price'>
                            {item.Price.SellingPriceWithCurrency} {priceExtraInformation}
                          </span>
                        ) : (
                          <Fragment>
                            <span className='variant-buying-list__item__price__selling-price'>
                              {memberText}{item.Price.SellingPriceWithCurrency} {priceExtraInformation}
                            </span>
                            {usualText ? (
                              <span
                                className={[
                                  'variant-buying-list__item__price__list-price',
                                  usualText ? 'usual-text' : ''
                                ].join(' ')}
                              >{usualText}{item.Price.ListPriceWithCurrency}</span>
                            ) : (
                              <del className='variant-buying-list__item__price__list-price'>{item.Price.ListPriceWithCurrency}</del>
                            )}
                          </Fragment>
                        )}
                      </div>
                    </Fragment>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

BOVariantsList.propTypes = {
  contentData: Proptypes.any,
  activeItemsId: Proptypes.any,
  onToogleFnc: Proptypes.any,
  isSelected: Proptypes.any,
  productData: Proptypes.any
};

export default BOVariantsList;
