import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { AddressService, config } from '@components/SlicingPage/MyAccount/MyAddress/AddressForMultiLanguage/service';
import { clearAddressDatasources, disableRequiredField } from '@redux/actions/myAddress';
import { getAddressDatasources } from '@redux/actions/myAddress';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';
const TEXT_URL_REPLACE = 'store-locator';

const StoreLocatorFilterPopupContent = (props) => {
  const { dataFields, setSubmitAddressEvt, addressCurrent, options, objMessages, isNotFoundAddress, subFieldNameArray } = props;
  const addressElements = dataFields['Address Settings']['Address Elements'];
  const dispatch = useDispatch();
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [fieldNameDropdownArr] = useState(addressElements.filter((address) => address.Type.value === config.TypeElement.Dropdown));
  const [optionDropdown, setOption] = useState({});
  const [valueSelected, setValueSelected] = useState({});

  const isGA4 = Global.getIsGA4();

  const formik = useFormik({
    initialValues: Object.assign(AddressService.initialValuesAddress(deepCopy(addressElements), addressCurrent)),
    validationSchema: AddressService.validateFileld(deepCopy(addressElements.filter((item) => item.IsRequired))),
    onSubmit: (values) => {
      //New Logic ON/OFF GA, GA4
      if (isGA4) {
        let params = {
          selected_category: window.location.pathname.replace(/[/]/g, '').replace(TEXT_URL_REPLACE, '') || 'all',
          selected_area: optionDropdown?.Area
        };

        new GoogleAnalytic().gAnalytic4('event', 'roadshow_filter', params);
      }

      let optionDropdownEdit = {};

      fieldNameDropdownArr.map((select) => {
        if (values[select[config.FieldNameModel].value]?.value) {
          optionDropdownEdit = Object.assign(optionDropdownEdit, {
            [select[config.FieldNameModel].value]: values[select[config.FieldNameModel].value].label,
            [`${select[config.FieldNameModel].value}Code`]: values[select[config.FieldNameModel].value].value
          });
        }
      });
      if (addressCurrent) {
        setSubmitAddressEvt({
          ...addressCurrent,
          ...values,
          ...optionDropdownEdit,
          ...optionDropdown
        });
      } else {
        const modelSubmit = {
          ...values,
          ...optionDropdownEdit,
          ...optionDropdown
        };

        setSubmitAddressEvt(modelSubmit);
      }
    },
    validateOnMount: true
  });

  useEffect(() => {
    if (fieldNameDropdownArr?.length) {
      dispatch(
        getAddressDatasources({
          model: {
            CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
            RootValue: '',
            RootType: '',
            ChildType: ''
          },
          fieldName: fieldNameDropdownArr[0][config.FieldNameModel].value,
          condition: null
        })
      );
      if (addressCurrent) {
        for (let index = 0; index < fieldNameDropdownArr.length - 1; index++) {
          if (addressCurrent[`${fieldNameDropdownArr[index][config.FieldNameModel].value}Code`]) {
            const rootValue = addressCurrent[`${fieldNameDropdownArr[index][config.FieldNameModel].value}Code`];
            const rootType = fieldNameDropdownArr[index][config.FieldNameModel].value;
            if (rootValue) {
              dispatch(
                getAddressDatasources({
                  model: {
                    CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
                    RootValue: rootValue,
                    RootType: rootType,
                    ChildType: ''
                  },
                  fieldName: fieldNameDropdownArr[index + 1][config.FieldNameModel].value,
                  condition: fieldNameDropdownArr[index].Condition
                })
              );
            } else {
              const optionMustReset = fieldNameDropdownArr.slice(index);

              dispatch(disableRequiredField(optionMustReset));
              break;
            }
          }
        }
      }
    }
  }, []);

  useDidUpdateEffect(() => {
    if (subFieldNameArray.length && isNotFoundAddress) {
      subFieldNameArray.map((item) => {
        formik.setFieldValue(item, 'item');
      });
    }
  }, [subFieldNameArray, isNotFoundAddress]);

  const onChangeAreaHandler = (params) => {
    const inputName = params.inputField[config.FieldNameModel].value;

    if (params.option.value !== formik.values[inputName]) {
      setIsFirstLoad(false);

      const optionCurrent = fieldNameDropdownArr.find((address) => address[config.FieldNameModel].value === inputName);
      let optionMustResetObj = {};
      const optionMustReset = fieldNameDropdownArr.filter((address) => address.index > params.index);

      formik.setFieldValue(inputName, params.option.value);

      if (!optionMustReset.length) {
        dispatch(disableRequiredField());

        setValueSelected({
          ...valueSelected,
          [inputName]: params.option
        });
      } else {
        dispatch(clearAddressDatasources(optionMustReset));

        dispatch(disableRequiredField(optionMustReset));

        optionMustReset.map((item) => {
          optionMustResetObj = Object.assign(optionMustResetObj, {
            [item[config.FieldNameModel].value]: '',
            [`${item[config.FieldNameModel].value}Code`]: ''
          });

          formik.setFieldValue([item[config.FieldNameModel].value], '');
        });

        setValueSelected({
          ...valueSelected,
          ...optionMustResetObj,
          [inputName]: params.option
        });
      }

      setOption({
        ...optionDropdown,
        [optionCurrent[config.FieldNameModel].value]: params.option.label,
        [`${optionCurrent[config.FieldNameModel].value}Code`]: params.option.value,
        ...optionMustResetObj
      });

      if (inputName !== fieldNameDropdownArr[fieldNameDropdownArr.length - 1][config.FieldNameModel].value) {
        const filterNameList = fieldNameDropdownArr.map((dropdownName) => dropdownName[config.FieldNameModel].value);
        const childTypeIndex = filterNameList.indexOf(inputName) + 1;

        dispatch(
          getAddressDatasources({
            model: {
              CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
              RootValue: params.option.value || '',
              RootType: inputName,
              ChildType: ''
            },
            fieldName: Object.keys(options).includes(params.fieldName)
              ? params.fieldName
              : fieldNameDropdownArr[childTypeIndex][config.FieldNameModel].value,
            condition: addressElements.filter((address) => address[config.FieldNameModel] === params.fieldName)?.Condition
          })
        );
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className='store-locator__modal' id='add-addresses'>
      <div className='user-addresses'>
        {addressElements.map((inputField, inputFieldIdx) => {
          if (inputField.Type.value === config.TypeElement.Text || inputField.Type.value === config.TypeElement.Phone) {
            return (
              <div className='form-group' key={inputFieldIdx}>
                <input
                  onChange={formik.handleChange}
                  value={formik.values[inputField[config.FieldNameModel].value]}
                  type='text'
                  className='form-control form-control-lg'
                  name={inputField[config.FieldNameModel].value}
                  placeholder={inputField['Display Name'].value}
                />
                {formik.errors[inputField[config.FieldNameModel].value] && formik.touched[inputField[config.FieldNameModel].value] && (
                  <span className='error-validate'>
                    {objMessages['Msg_16'].replace(config.FieldNameValidate, inputField[config.DisplayNameModel].value)}
                  </span>
                )}
              </div>
            );
          }
          if (inputField.Type.value === config.TypeElement.Dropdown) {
            const isDisableRequired = isNotFoundAddress && subFieldNameArray.indexOf(inputField[config.FieldNameModel].value) >= 0;

            return !isDisableRequired ? (
              <div className='form-group form-group--select' key={inputFieldIdx}>
                <Select
                  options={options[inputField[config.FieldNameModel].value]}
                  onChange={(optionCurrent) => onChangeAreaHandler({ option: optionCurrent, inputField: inputField, index: inputFieldIdx })}
                  value={
                    !valueSelected?.[inputField[config.FieldNameModel].value] && isFirstLoad
                      ? formik.values[inputField[config.FieldNameModel].value]
                      : valueSelected[inputField[config.FieldNameModel].value]
                  }
                  name={inputField[config.FieldNameModel].value}
                  placeholder={inputField['Display Name'].value}
                  className='customization-dropdown'
                  classNamePrefix='customization-dropdown'
                  isSearchable={false}
                  noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
                  // menuIsOpen={true}
                />
                {formik.errors[inputField[config.FieldNameModel].value] && formik.touched[inputField[config.FieldNameModel].value] && (
                  <span className='error-validate'>
                    {objMessages['Msg_16'].replace(config.FieldNameValidate, inputField[config.DisplayNameModel].value)}
                  </span>
                )}
              </div>
            ) : (
              <div className='form-group form-group--select' key={inputFieldIdx} id={inputField[config.FieldNameModel].value}>
                <Select
                  options={options[inputField[config.FieldNameModel].value]}
                  value=''
                  noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
                  placeholder={inputField['Display Name'].value}
                  className='customization-dropdown'
                  classNamePrefix='customization-dropdown'
                  isSearchable={false}
                  // menuIsOpen={true}
                />
              </div>
            );
          }
        })}
      </div>
      <div className='button-container'>
        <button className='btn btn-primary' type='submit'>
          <Text field={dataFields['Apply Filters Button Label']} />
        </button>
        <Text className='btn-clear-filter' onClick={() => setSubmitAddressEvt()} field={dataFields['Clear Filters Button Label']} tag='span' />
      </div>
    </form>
  );
};

StoreLocatorFilterPopupContent.propTypes = {
  dataFields: PropTypes.any,
  options: PropTypes.any,
  setSubmitAddressEvt: PropTypes.func,
  setEstimateShippingFeeEvt: PropTypes.func,
  addressCurrent: PropTypes.any,
  shippingFee: PropTypes.any,
  setCloseHandlerEvt: PropTypes.func,
  children: PropTypes.any,
  objMessages: PropTypes.object,
  isNotFoundAddress: PropTypes.bool,
  subFieldNameArray: PropTypes.any
};

const mapStateToProps = (state) => ({
  options: state.myAddressReducer.addressDatasourcesReducer.options,
  isNotFoundAddress: state.myAddressReducer.addressDatasourcesReducer.isNotFoundAddress,
  subFieldNameArray: state.myAddressReducer.addressDatasourcesReducer.subFieldNameArray,
  addressCurrent: state.storeLocatorReducer.allStoreReducer.filterObject || null,
  objMessages: state.getMessageReducer?.objMessages || null
});

export default connect(mapStateToProps)(StoreLocatorFilterPopupContent);
