import './CreateBundle.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { setListQuantityBundle, updateQuantityBundle } from '@redux/actions/dynamicBundle';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import PromotionDetailBundleSwiper from '../PromotionDetailBundleSwiper';
import BundleItems from './BundleItems';
import { behaviorCreateBundle$ } from './CreateBundleBehavior';
import CreateBundleShimmer from './CreateBundleShimmer';
import CreateBundleModel from './model/CreateBundleModel';

const CreateBundle = ({ fields }) => {
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);
  const [listBundleItem, setListBundleItem] = useState(null);
  const [listSelectedItem, setListSelectedItem] = useState([null, null, null]);

  const categoryName = SitecoreContextFactoryService.getValueContextRouteItem('displayName');
  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const model = new CreateBundleModel();

    setListBundleItem(fields['Dynamic Bundle Data']?.IncludedProducts || []);

    if (fields['Dynamic Bundle Data'] && fields['Dynamic Bundle Data'].IncludedProducts && fields['Dynamic Bundle Data'].Products) {
      let bundleQuantity = {};
      const data = deepCopy(fields['Dynamic Bundle Data']);

      data.IncludedProducts.forEach((item) => {
        let totalVariant = 0;

        if (item.Variants && item.Variants.length > 0) {
          item.Variants.forEach((variant) => {
            if (variant && variant.Stock) {
              totalVariant += !isNaN(variant.Stock.PurchasableLimit) ? variant.Stock.PurchasableLimit : 0;
            }
          });
        }

        const maxQuantity = data.Products.find((product) => {
          return product.ProductId === item.ProductId;
        })?.MaxQuantity;

        bundleQuantity[item.ProductId] = totalVariant < maxQuantity ? totalVariant : maxQuantity;
      });

      dispatch(setListQuantityBundle(bundleQuantity));
    }

    setLayoutData(model.getData(deepCopy(fields)));

    const listSelectedItems = [];
    for (var i = 0; i < model.ProductRows; i++) listSelectedItems.push(null);

    setListSelectedItem(listSelectedItems);
  }, []);

  const selectedItem = (item, number) => {
    const listSelect = deepCopy(listSelectedItem);

    listSelect[number] = item;

    dispatch(updateQuantityBundle(listSelect));

    setListSelectedItem(listSelect);
  };

  useDidUpdateEffect(() => {
    behaviorCreateBundle$.next({
      installment: layoutData['Dynamic Bundle Data'].InstallmentPeriods,
      sellingPrice: layoutData['Dynamic Bundle Data'].SellingPrice || layoutData['Dynamic Bundle Data'].ListingPrice
    });
  }, [layoutData]);

  useDidUpdateEffect(() => {
    if (layoutData['Dynamic Bundle Data']) {
      //Old Logic GA
      // let items = [];

      // items.push({
      //   id: layoutData['Dynamic Bundle Data']?.BundleId,
      //   name: layoutData['Dynamic Bundle Data']?.BundleName,
      //   list_name: 'Search Results',
      //   brand: 'Osim',
      //   list_position: 1,
      //   quantity: 1,
      //   price: layoutData['Dynamic Bundle Data']?.SellingPrice
      // });
      
      // let params = {
      //   items: items
      // };

      // new GoogleAnalytic().ggGateWay('event', 'view_item', params);

      //New Logic ON/OFF GA, GA4
      if (isGA4) {
        const coupon = layoutData['Dynamic Bundle Data']?.Coupon;
        const brand = layoutData['Dynamic Bundle Data']?.Brand;
        const currencyCode = layoutData['Currency']?.Name;
        const idxProdGA4 = Global.getIndexGA4Param();
        let dataConvert = [];

        dataConvert.push(layoutData['Dynamic Bundle Data']);

        let paramsViewItem = {
          ecommerce: {
            currency: currencyCode,
            value: layoutData['Dynamic Bundle Data']?.SellingPrice,
            items: Global.handleGA4ViewAndSelectItems(idxProdGA4, dataConvert, categoryName, coupon, brand, currencyCode)
          }
        };
  
        new GoogleAnalytic().gAnalytic4('event', 'view_item', paramsViewItem);
      } else {
        let items = [];

        items.push({
          id: layoutData['Dynamic Bundle Data']?.BundleId,
          name: layoutData['Dynamic Bundle Data']?.BundleName,
          list_name: 'Search Results',
          brand: 'Osim',
          list_position: 1,
          quantity: 1,
          price: layoutData['Dynamic Bundle Data']?.SellingPrice
        });
      
        let params = {
          items: items
        };

        new GoogleAnalytic().ggGateWay('event', 'view_item', params);
      }
    }
  }, [layoutData]);

  return layoutData && listBundleItem ? (
    <div className='create-bundle animated-slow fadeIn delay-100' id='create-bundle'>
      <div className='container'>
        <h2 className='create-bundle__title bundle-title text-center experience-title'>{layoutData['Title'].value}</h2>
        <p className='create-bundle__description bundle-description text-center'>
          <RichText field={layoutData['Description']} />
        </p>
      </div>
      {Array.from(Array(layoutData.ProductRows), (e, i) => (
        <PromotionDetailBundleSwiper
          layoutData={layoutData}
          selectedItemHandler={(item, number) => selectedItem(item, number)}
          listBundle={listBundleItem}
          bundleNumber={i + 1}
          type={`first${i + 1}-bundle`}
        ></PromotionDetailBundleSwiper>
      ))}
      <BundleItems layoutData={layoutData} selectedBundle={listSelectedItem}></BundleItems>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <CreateBundleShimmer></CreateBundleShimmer>
    </LoadingShimmer>
  );
};

CreateBundle.propTypes = {
  fields: PropTypes.any
};

export default CreateBundle;
