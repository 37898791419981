export default class BundleProductVariantLabelsModel {
  constructor() {
    this.Code = '';
          
    this.Name = '';
      
    this.IsSpecial = false;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Code')) {
      this.Code = dataSource.Code ? dataSource.Code : this.Code;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name ? dataSource.Name : this.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsSpecial')) {
      this.IsSpecial = dataSource.IsSpecial ? dataSource.IsSpecial : this.IsSpecial;
    }
      
    return this;
  }
}
      