import './ProductHubCarousel.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import CarouselModel from '@components/SlicingPage/About/Carousel/Model/CarouselModel';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import CarouselItem from '../CarouselItem';

const CarouselWithItemTitle = ({fields}) => {
  const carouselParams = {
    loop: true,
    centeredSlides: true,
    spaceBetween: 8,
    slidesPerView: 1.24,
    watchOverflow: true,
    shouldSwiperUpdate: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
  
    breakpoints: {
      768: {
        slidesPerView: 1.24,
        spaceBetween: 30
      },
  
      1200: {
        slidesPerView: 1.5,
        spaceBetween: 40
      }
    }
  };

  const [carouselWithItemTitleFields, setCarouselWithItemTitleFields] = useState(null);
  const [carouselWithItemTitleDesktop, setCarouselWithItemTitleDesktop] = useState(null);
  const [carouselWithItemTitleMobile, setCarouselWithItemTitleMobile] = useState(null);

  useEffect(() => {
    const carouselModel = new CarouselModel();

    setCarouselWithItemTitleFields(carouselModel.getData(deepCopy(fields) || {}));
  }, []);

  useDidUpdateEffect(() => {
    let mobileArr = [];
    let desktopArr = [];

    carouselWithItemTitleFields.items.forEach((item) => {
      if (item?.fields?.['Mobile Image']?.value?.src?.length) {
        mobileArr.push(item);
      }
      if (item?.fields?.Image?.value?.src?.length) {
        desktopArr.push(item);
      }
    });

    setCarouselWithItemTitleMobile(mobileArr);

    setCarouselWithItemTitleDesktop(desktopArr);
  }, [carouselWithItemTitleFields]);

  return carouselWithItemTitleFields && carouselWithItemTitleDesktop && carouselWithItemTitleMobile && (
    <div className='product-hub-carousel'>
      <div className='container'>
        <Text tag='h2' field={carouselWithItemTitleFields.Title} className='text-center section-title' />
      </div>
      <div className={'os-carousel animated-slow fadeIn delay-100 '}>
        <div className='os-carousel__list'>
          <div className='d-desktop'>
            <SwipeComponent param={carouselParams}>
              {
                carouselWithItemTitleDesktop?.length && carouselWithItemTitleDesktop.map((carouselItem) => (
                  <div key={carouselItem.id}>
                    <CarouselItem carouselItem={carouselItem}></CarouselItem>
                  </div>
                ))
              }
            </SwipeComponent>
          </div>
          <div className='d-mobi'>
            <SwipeComponent param={carouselParams}>
              {
              carouselWithItemTitleMobile?.length && carouselWithItemTitleMobile.map((carouselItem) => (
                  <div key={carouselItem.id}>
                    <CarouselItem carouselItem={carouselItem}></CarouselItem>
                  </div>
                ))
              }
            </SwipeComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

CarouselWithItemTitle.propTypes = {
  fields:  PropTypes.object
};

export default CarouselWithItemTitle;