import CartPromotionMilestoneModel from '@components/SlicingPage/CartDetails/model/CartPromotionMilestoneModel';
import LinkModelWithTitle from '@models/LinkModelWithTitle';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class MiniCartModel {
  constructor() {
    this['Quantity Label'] = new SitecoreTextModel();

    this['Continue Shopping Label'] = new SitecoreTextModel();

    this['Policy Free Shipping Label'] = new SitecoreTextModel();

    this['Total Label'] = new SitecoreTextModel();

    this['Cart No Item Label'] = new SitecoreTextModel();

    this['View Cart Link'] = new LinkModelWithTitle();

    this['Additional Link'] = new LinkModelWithTitle();

    this['Description'] = '';

    this['PromotionId'] = '';

    this['Promotion Milestone'] = new CartPromotionMilestoneModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Quantity Label')) {
      this['Quantity Label'].getData(dataSource['Quantity Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View Cart Link')) {
      this['View Cart Link'].getData(dataSource['View Cart Link'].fields);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Link')) {
      this['Additional Link'].getData(dataSource['Additional Link'].fields);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total Label')) {
      this['Total Label'].getData(dataSource['Total Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cart No Item Label')) {
      this['Cart No Item Label'].getData(dataSource['Cart No Item Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Policy Free Shipping Label')) {
      this['Policy Free Shipping Label'].getData(dataSource['Policy Free Shipping Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Continue Shopping Label')) {
      this['Continue Shopping Label'].getData(dataSource['Continue Shopping Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description ? dataSource.Description : this.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionId')) {
      this.PromotionId = dataSource.PromotionId ? dataSource.PromotionId : this.PromotionId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Promotion Milestone')) {
      this['Promotion Milestone'].getData(dataSource['Promotion Milestone']?.fields || {});
    }

    return this;
  }
}
