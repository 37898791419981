import produce from 'immer';

import * as clientTellingPortalCustomerTypes from '@redux/actions/clientTellingPortalCustomer/clientTellingPortalCustomerTypes';
import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';

const initState = {
  accountUser: '',
  isLogin: false,
  isCPLogin: false,
  cPUserData: '',
  dirtyCustomerLifestyle: false
};

const userInfoReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case singleSignOnTypes.LOGIN_SUCCESS:
      draft.isLogin = true;

      draft.accountUser = action.payload;
      break;

    case singleSignOnTypes.CP_LOGIN_SUCCESS:
      draft.isCPLogin = true;

      draft.cPUserData = { ...action.payload.Data };
      break;

    case singleSignOnTypes.CHECK_CP_LOGIN_STATUS:
      draft.isCPLogin = action.payload.user && !action.payload.isEditing;

      draft.cPUserData = action.payload.user && !action.payload.isEditing ? draft.cPUserData : '';
      break;

    case singleSignOnTypes.CHECK_LOGIN_STATUS:
      draft.isLogin = action.payload.user && !action.payload.isEditing;

      draft.accountUser = action.payload.user && !action.payload.isEditing ? draft.accountUser : '';
      break;

    case myAccountTypes.UPDATE_USER_PROFILE_SUCCESS:
      draft.accountUser = {
        ...draft.accountUser,
        ...action.payload.Data
      };
      break;

    case singleSignOnTypes.LOGOUT_SUCCESS:
      draft.isLogin = false;

      draft.accountUser = '';
      break;

    case singleSignOnTypes.CP_LOGOUT_SUCCESS:
      draft.isCPLogin = false;

      draft.cPUserData = '';
      break;

    case myAccountTypes.CHANGE_EMAIL_SUCCESS:
      draft.accountUser = {
        ...draft.accountUser,
        Email: action.payload.Email
      };
      break;

    case clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_SUCCESS:
      draft.dirtyCustomerLifestyle = true;
      break;

    case clientTellingPortalCustomerTypes.END_SESSION:
      draft.dirtyCustomerLifestyle = false;
      break;

    case myAccountTypes.UPDATE_PHONE_NUMBER_SUCCESS:
      draft.accountUser = {
        ...draft.accountUser,
        PhoneNumber: action.payload
      };
      break;
    }

    return draft;
  });

export default userInfoReducer;
