import React from 'react';

import { useQuery } from '@utils/customsHook/useQuery';

import { SCHEDULE_APPOINTMENT_PARAM_NAME, SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM, SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE } from './configs';
import ContactDetailsForm from './ContactDetailsForm';
import PersonalizeExperienceForm from './PersonalizeExperienceForm';
import ScheduleAppointmentForm from './ScheduleAppointmentForm';

const FormikStep = () => {
  const query = useQuery();

  let stepComp;
  switch (query.get(SCHEDULE_APPOINTMENT_PARAM_NAME)) {
  case SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE:
    stepComp = <PersonalizeExperienceForm />;
    break;

  case SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM:
    stepComp = <ContactDetailsForm />;
    break;
  default:
    stepComp = <ScheduleAppointmentForm />;
  }

  return stepComp;
};

export default FormikStep;
