import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ContactUsIconListItemFieldsModel {
  constructor() {
    this.Image = new SitecoreImageModel();

    this.Title = new SitecoreTextModel();

    this.isDisplay = false;
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image.getData(dataSource.Image);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    this.isDisplay = this.Image.value.src === '' && this.Title.value === '' ? false : true;

    return this;
  }
}
