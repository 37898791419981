import SignleSignOnModel from '@components/SingleSignOn/models/SignleSignOnModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import BooleanModel from '../../../PopupBanner/model/BooleanModel';

const TEXT_ARR = [
  'Call Policy Label',
  'Did Not Get Email Message',
  'Direct Mailing Subscription Label',
  'Email Already Exists Label',
  'Email Already Exists Message',
  'Email Subscription Label',
  'Login Button Label',
  'Forgot Password Label',
  'Login Button Text',
  'Login Email Label',
  'Login Introduction',
  'Login Password Label',
  'Login Phone Label',
  'Login Switch To Register Text',
  'Login Tab Label',
  'Phone Number Invalid Message',
  'Register Button Label',
  'Register Button Text',
  'Register DoB Label',
  'Register Email Label',
  'Register First Name Label',
  'Register Gender Label',
  'Register Introduction',
  'Register Last Name Label',
  'Register PDPA confirmation Label',
  'Register Password Label',
  'Register Phone Label',
  'Register Subscription Label',
  'Register Success Instruction',
  'Register Success Message',
  'Register Success Title',
  'Register Switch To Login Text',
  'Register Tab Label',
  'Resend Message',
  'SMS Subscription Label',
  'Send Email Policy Label',
  'Social Login Instruction',
  'Subscription Description'
];

const LINK_ARR = ['Forgot Password Link'];

const SOCIAL_ARR = ['Facebook Login Settings', 'Google Login Settings', 'Instagram Login Settings'];

const BOOL_ARR = ['Enable Subscription'];

export default class WarrantyRegisterLoginModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    SOCIAL_ARR.forEach((item) => {
      this[item] = new SignleSignOnModel();
    });

    BOOL_ARR.forEach((item) => {
      this[item] = new BooleanModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    SOCIAL_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    BOOL_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}
