import React from "react";
import PropTypes from 'prop-types';
import "./FilterByTradeInAvailable.scss";
import { PARAMS_SUBMIT } from '@components/CategoryLanding/ProductListing/config';

const FilterByTradeInAvailable = ({ checked, setChecked, dataSources }) => {
  
  return (
    <div className="filter-popup__section">
      <h3 className='filter-popup__section__heading'>
        {dataSources?.Name?.value}

      </h3>
      <label className={`tradein-toggle ${checked ? "tradein-toggle--checked" : ""}`}>
        <input
          type="checkbox"
          checked={checked}
          onChange={() => setChecked({ key: PARAMS_SUBMIT.TradeInAvailable })}
        />
        <div className="tradein-toggle__slider" />
        <small className="tradein-toggle__text"></small>
        <small className="tradein-toggle__text"></small>
      </label>
    </div>
  );
}
FilterByTradeInAvailable.propTypes = {
  checked: PropTypes.bool,
  setChecked: PropTypes.func,
  dataSources: PropTypes.any
};

export default FilterByTradeInAvailable