import produce from 'immer';

import {
  CREATE_BOOKING_EXPERIENCE_CONTACT_FAILED,
  CREATE_BOOKING_EXPERIENCE_CONTACT_START,
  CREATE_BOOKING_EXPERIENCE_CONTACT_SUCCESS
} from '@redux/actions/bookingExperience/createBookingExperienceContact/types';

const initialStates = {
  isLoading: false,
  error: null,
  contactData: null
};

const createBookingExperienceContactReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case CREATE_BOOKING_EXPERIENCE_CONTACT_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case CREATE_BOOKING_EXPERIENCE_CONTACT_SUCCESS:
      draft.isLoading = false;

      draft.contactData = action.payload;
      break;

    case CREATE_BOOKING_EXPERIENCE_CONTACT_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default createBookingExperienceContactReducer;
