import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import { Image } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CPSearchBarModel from '../Models/CPSearchBarModel';

const CPSearchBar = ({fields}) => {
  const inputSearchRef = useRef(null);
  const [isShowSearch, setIsShowSearch] = useState(false);
  const [cPSearchBarFields, setCPSearchBarFields] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    const cPSearchBarModel = new CPSearchBarModel();

    setCPSearchBarFields(cPSearchBarModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    if (isShowSearch) {
      setTimeout(() => {
        inputSearchRef.current.focus();
      }, 100);
    }
  }, [isShowSearch]);

  const headerSearchHandler = () => {
    window.location.href = Global.genURLParamAndChangePage(`${cPSearchBarFields?.Link.value.href}`, { term: searchValue });
  };

  const onEnterHandler = (event) => {
    if (event.key == 'Enter') {
      headerSearchHandler();
    }
  };

  return cPSearchBarFields && (
    <div className='cp-header__col cp-search-bar'>
      <div className='head-icon' 
        onClick={() => setIsShowSearch(!isShowSearch)}>
        <span>
          <Image field={cPSearchBarFields.Image} />
        </span>
      </div>
      <div className={`search-bar cp-popup ${isShowSearch ? 'popup-show' : ''}`}>
        <div className='search-bar__container'>
          <input type='search' id='' 
            className='search-bar__input'
            placeholder={cPSearchBarFields['Placeholder Text'].value}
            value={searchValue}
            onChange={(event) => setSearchValue(event.target.value)}
            onKeyDown={(event) => onEnterHandler(event)}
            ref={inputSearchRef}
          />
          <div className='search-bar__icon' 
            onClick={() => headerSearchHandler()}>
            <span>
              <Image field={cPSearchBarFields.Image} />
            </span>
          </div>
        </div>
      </div>
      {
        isShowSearch ? (
          <div className='cp-overlay' onClick={() => setIsShowSearch(!isShowSearch)}></div>
        ) : ''
      }
    </div>
  );
};

CPSearchBar.propTypes = {
  fields: PropTypes.any
};

export default CPSearchBar;