import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/myOrders';
import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import CartDetailApiModel from '../../../../components/SlicingPage/CartDetails/models/CartDetailApiModel';
import CartDetailService from '../../product/cartDetail/service';
import * as config from './config';

export const getOrderByIdEpic = (action$) =>
  action$.pipe(
    ofType(myOrderTypes.GET_ORDER_BY_ID),
    switchMap((action) => {
      const url = config.ACTION_GET_ORDER_BY_ID + `?orderId=${action.payload}`;

      return getAsObservable(url).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            let filteredLineItem = [];
            const cartLineId = action.cartLineId || null;
            const cartDetailApiModel = new CartDetailApiModel().getData(deepCopy(res.data));
            if (cartLineId) {
              const filterItem = cartDetailApiModel?.Lines?.filter((item) => item?.ExternalCartLineId === cartLineId)[0];

              filteredLineItem = filterItem ? [filterItem] : [];
            }

            return actions.getMyOrdersByIdSuccess(CartDetailService.customCurrentCart(res.data), filteredLineItem);
          } else {
            return actions.getMyOrdersByIdFailed('Failed');
          }
        })
      );
    })
  );