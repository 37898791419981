import SignleSignOnModel from '@components/SingleSignOn/models/SignleSignOnModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const textLabel = [
  'Call Policy Label',
  'Continue Button Text',
  'Continue to Delivery Button Text',
  'Email Already Exists Label',
  'Email Already Exists Message',
  'Email Label',
  'First Name Label',
  'Forgot Password Label',
  'Guest Checkout Label',
  'Last Name Label',
  'Login Button Text',
  'Login Email Label',
  'Customer Password Label',
  'Phone Number Label',
  'Please Enter Your Details Label',
  'Please Login Message',
  'Returning Customer Label',
  'Send Email Policy Label',
  'Continue Gues Checkout Button Text',
  'Forgot Password Text',
  'Back to Cart Button Text',
  'Customer Email Label',
  'DoB Label',
  'Gender Label',
  'Email Confirmation Label',
  'Email Confirmation Required Message',
  'Email Confirmation Not Match Message',
  'Register Account Button Text',
];

export default class AccountCheckingModel {
  constructor() {
    textLabel.forEach((itemText) => {
      this[itemText] = new SitecoreTextModel();
    });

    this['Login Button Link'] = new SitecoreLinkModel();

    this['Back to Cart Button Link'] = new SitecoreLinkModel();

    this['Continue Guest Checkout  Button Link'] = new SitecoreLinkModel();

    this['Forgot Password Link'] = new SitecoreLinkModel();

    this['Facebook Login Settings'] = new SignleSignOnModel();

    this['Google Login Settings'] = new SignleSignOnModel();

    this['Continue to Offer Delivery Button Link'] = new SitecoreLinkModel();

    this['Register Account Button Link'] = new SitecoreLinkModel();

    this['Continue to Delivery Button Link'] = new SitecoreLinkModel();
  }
  getData(dataSource) {
    textLabel.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Facebook Login Settings')) {
      this['Facebook Login Settings'].getData(dataSource['Facebook Login Settings']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Google Login Settings')) {
      this['Google Login Settings'].getData(dataSource['Google Login Settings']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Button Link')) {
      this['Login Button Link'].getData(dataSource['Login Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Back to Cart Button Link')) {
      this['Back to Cart Button Link'].getData(dataSource['Back to Cart Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Continue Guest Checkout  Button Link')) {
      this['Continue Guest Checkout  Button Link'].getData(dataSource['Continue Guest Checkout  Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Forgot Password Link')) {
      this['Forgot Password Link'].getData(dataSource['Forgot Password Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Continue to Offer Delivery Button Link')) {
      this['Continue to Offer Delivery Button Link'].getData(dataSource['Continue to Offer Delivery Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Account Button Link')) {
      this['Register Account Button Link'].getData(dataSource['Register Account Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Continue to Delivery Button Link')) {
      this['Continue to Delivery Button Link'].getData(dataSource['Continue to Delivery Button Link']);
    }

    return this;
  }
}
