import './SearchBoxArticle.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import AutoCompleteUI from '@components/Share/AutoCompleteUI';
import { getAutoCompletePolicy,getAutoCompleteResource,getAutoCompleteWellness } from '@redux/actions/common';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { RESOURCE_SEARCH,SUPPORT_SEARCH,WELLNESS_SEARCH } from '@utils/constant';
import Global from '@utils/global';

import WellnessFilterTag from '../WellnessFilterTag';
import SearchBoxArticleFieldsModel from './model/SearchBoxArticleFieldsModel';

const SearchBoxArticle = (props) => {
  const { 
    fields, 
    wellnessSuggestionList,
    policySuggestionList,
    params,
    resourceSuggestionList
  } = props;

  const tagList = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Tags To Select'] || [];

  const [ isWellness ] = useState(SitecoreContextFactoryService.getValueContextRouteItem('name') === 'Wellness Landing' ? true : false);

  const [ dataFields, setDataFields ] = useState(null);

  useEffect(() => {
    const searchBoxArticleFieldsModel = new SearchBoxArticleFieldsModel();

    setDataFields(searchBoxArticleFieldsModel.getData(fields || {}));
  }, []);

  const [searchParams, setSearchParams] = useState({
    searchTerm: '',
    tags: [],
  });

  const onSearchHandler = (value) => {
    window.location.href = Global.genURLParamAndChangePage(`${dataFields?.Link?.value?.href}`, { Term: value, Tags: searchParams.tags });
  };

  const onChangeTagsFilterHandler = (value) => {
    window.location.href = Global.genURLParamAndChangePage(`${dataFields?.Link?.value?.href}`, { Term: searchParams.searchTerm, Tags: value });
  };

  const getListSuggestion = () => {
    return params['Search Type'] === WELLNESS_SEARCH 
      ? wellnessSuggestionList 
      : params['Search Type'] === SUPPORT_SEARCH || !params['Search Type']
        ? policySuggestionList 
        : params['Search Type'] === RESOURCE_SEARCH 
          ? resourceSuggestionList
          : null ;
  };

  const getActionSuggest = () => {
    return params['Search Type'] === WELLNESS_SEARCH 
      ? getAutoCompleteWellness 
      : params['Search Type'] === SUPPORT_SEARCH || !params['Search Type']
        ? getAutoCompletePolicy 
        : params['Search Type'] === RESOURCE_SEARCH 
          ? getAutoCompleteResource
          : null;
  };

  return dataFields && (
    <Fragment>
      <div className='searchbox-article'>
        {
          dataFields.Title.value ? (
            <Text tag='h2' field={dataFields.Title} className='searchbox-article__title text-center section-title' />
          ) : ''
        }
        <div className='searchbox-article--tool'>
          <AutoCompleteUI
            searchTerm={searchParams?.searchTerm}
            placeholder={dataFields?.['Hidden Text']?.value}
            suggestionsListData={getListSuggestion()}
            onGetSuggestionListHandler={getActionSuggest()}
            onSearchHandler={onSearchHandler}
          >
            {searchParams?.tags?.length
              ? <span className='searchbox--autocomplete--tag selected'>
                {tagList.filter(item => item.id === searchParams.tags[0])[0]?.fields?.Title?.value || ''}
                <i className='x-close-tags icon-close' onClick={() => setSearchParams({...searchParams, tags: []})}></i>
              </span> 
              : null}
          </AutoCompleteUI>
          <WellnessFilterTag
            label={dataFields?.['Filter By Text']}
            tagList={tagList}
            activeTag={searchParams?.tags} 
            onChangeTagsFilterHandler={onChangeTagsFilterHandler} 
          />
          
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => {
  const commonSearchReducer = state.commonSearchReducer;
  const resourceSearchReducer = state.commonSearchReducer.resourceSearchReducer;

  return {
    wellnessSuggestionList: commonSearchReducer.wellnessSearchReducer.wellnessSuggestionList || null,
    policySuggestionList: commonSearchReducer.policySearchReducer.policySuggestionList || null,
    resourceSuggestionList: resourceSearchReducer.resourceSuggestionList || null,
  };
};

SearchBoxArticle.propTypes = {
  fields: PropTypes.any,
  params: PropTypes.any,
  wellnessSuggestionList: PropTypes.any,
  policySuggestionList: PropTypes.any,
  resourceSuggestionList: PropTypes.array
};

export default connect(mapStateToProps)(SearchBoxArticle);
