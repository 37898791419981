import './CartMilestone.scss';

import { object } from 'prop-types';
import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { useMilestoneData } from './useMilestoneData';

const CartMilestone = ({ milestoneData }) => {
  const { isShowMilestoneProgressBar, currentTierProgress, messageConvertedData, cartMilestoneProgress } = useMilestoneData(milestoneData);

  return isShowMilestoneProgressBar && currentTierProgress ? (
    <div className='cart-milestone'>
      <RichText field={messageConvertedData} tag='p' className='cart-milestone__message' />
      <div className='cart-milestone__progress-container'>
        <div className='cart-milestone__progress-body' style={{ '--progressWidth': `${cartMilestoneProgress}%` }}></div>
      </div>
    </div>
  ) : (
    <></>
  );
};

CartMilestone.propTypes = {
  milestoneData: object
};

export default CartMilestone;
