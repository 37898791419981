import produce from 'immer';

import {
  CREATE_CONTACT_FAILURE,
  CREATE_CONTACT_START,
  CREATE_CONTACT_SUCCESS
} from '@redux/actions/campaignRegister/createContact/types';

const initialState = {
  isLoading: false,
  error: null,
  createResponse: null
};

const createContactReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case CREATE_CONTACT_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case CREATE_CONTACT_SUCCESS:
      draft.isLoading = false;

      draft.createResponse = action.payload;
      break;

    case CREATE_CONTACT_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default createContactReducer;