import './CancelAppointment.scss';

import * as propType from 'prop-types';
import React from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const CancelAppointment = ({onlayoutData})=> {
  return (
    <div className='cancel-appointment'>
      <div className='container'>
        <div className='cancel-appointment__content'>
          <Text 
            field={{ value: onlayoutData?.['Cancel Successful Label']?.value.replace('{BookingID}', onlayoutData?.appointment?.BookingID) }} 
            tag='h2' 
            className='cancel-appointment__content__title'
          />
          <div className='cancel-appointment__content__time'>
            <Text field={{ value: Global.customDate(onlayoutData?.appointment?.['Date']) }} tag='span' />
            <Text field={{ value: onlayoutData?.appointment?.TimeSlotStr }} tag='span' />
          </div>
          {/* <Text field={ onlayoutData?.['Description Send Mail Text'] } tag='p' className='cancel-appointment__content__excerpts'/> */}
          <Link field={ onlayoutData?.['Button Book New Appointment Link'] } className='btn btn-primary cancel-appointment__content__link-appointment'>
            <Text field={ onlayoutData?.['Button Book New Appointment Text'] }/>
          </Link>
          <Text field={ onlayoutData?.['Explore Product Label']}  tag='p' className='cancel-appointment__content__info'/>
          <Link field={ onlayoutData?.['Button Explore Product Link'] } className='btn btn-link'>
            <Text field={ onlayoutData?.['Button Explore Product Text'] }/>
          </Link>
        </div>
      </div>
    </div>
  ); 
};

CancelAppointment.propTypes = {
  onlayoutData: propType.object
};

export default CancelAppointment;