import produce from 'immer';

import * as messageTypes from '@redux/actions/message/messageTypes';

const initState = {
};

const getMessageReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case messageTypes.GET_MESSAGES:
      draft.objMessages = action.payload;
      break;
    }

    return draft;
  });

export default getMessageReducer;
