import { combineEpics } from 'redux-observable';

import { getHadNotificationEpic } from './getHadNotification';
import { notificationDataEpic } from './notificationData';
import readAllNotificationEpic from './readAllNotification';
import readSingleNotificationEpic from './readSingleNotification';

const cpNotificationPopupEpic = combineEpics(notificationDataEpic, readSingleNotificationEpic, readAllNotificationEpic, getHadNotificationEpic);

export default cpNotificationPopupEpic;
