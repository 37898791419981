import './AppointmentsButton.scss';

import React from 'react';

const AppointmentsButton = () => {

  return (
    <div className='appointment-btn'>
      <button className='appointment-btn__item btn btn-os-orange btn-os-orange--text-primary'>Save</button>
      <a href='' className='appointment-btn__item btn btn-link'>Cancel</a>
    </div>
  );
};

export default AppointmentsButton;