import Proptypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { getNotifyStatusProduct, resetMessagePopupNotify } from '@redux/actions/product';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import NotifyMePopup from '../NotifyMePopup';

const ProductDetailHeaderDynamicBundle = ({ dataSources, isProductDetailSticky, isClientTelingUser, cookieBannerHeight}) => {
  const dispatch = useDispatch();
  const isNotifySuccess = useSelector((state) => state.productReducer.notifyProductStatusReducer.isNotifySuccess);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const [showNotifyMe, setShowNotifyMe] = useState(false);
  const [isShowNotifyMe, setIsShowNotifyMe] = useState(false);
  const [buyNowButtonLink, setBuyNowButtonLink] = useState(null);
  const [allowBuy, setAllowBuy] = useState(false);
  const [allReviewDetailsProduct, setAllReviewDetailsProduct] = useState(null);
  const [productNameBundle, setProductNameBundle] = useState(null);

  useEffect(() => {
    const allReviewDetailsProduct = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'));

    const showNotifyMe = window.sessionStorage.getItem('showNotifyMeDynamicBundle');

    const productNameBundle = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.productBundle?.bundleName;

    setAllReviewDetailsProduct(allReviewDetailsProduct);

    setShowNotifyMe(showNotifyMe);

    setProductNameBundle(productNameBundle);
  }, []);

  useDidUpdateEffect(() => {
    let temp = deepCopy(dataSources?.['Bundle Buy Now Link']);
    const allowBuy = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.productBundle?.allowBuy;

    temp.value.href = `${dataSources['Bundle Buy Now Link'].value.href}${allReviewDetailsProduct?.productBundle?.buyOptionLink}`;

    setBuyNowButtonLink(temp);

    setAllowBuy(allowBuy);
  }, [allReviewDetailsProduct?.productBundle]);

  // Reuse in DynamicBundleHeader
  const onSubmitNotifyHandler = (emailSubmit) => {
    dispatch(getNotifyStatusProduct({ payload: emailSubmit }));
  };

  // Reuse in DynamicBundleHeader
  const onClosePopupNotifyHandler = () => {
    setIsShowNotifyMe(false);

    dispatch(resetMessagePopupNotify());
  };

  // Reuse in DynamicBundleHeader
  const message = useMemo(() => {
    if (isNotifySuccess?.message) {
      if (isNotifySuccess.status) {
        return <p className='notify-success'>{isNotifySuccess.message}</p>;
      } else {
        return <p className='notify-faild'>{isNotifySuccess.message}</p>;
      }
    }

    return null;
  }, [isNotifySuccess]);

  return (
    <>
      {/* Reuse in ProductDetailHeader with data from Products DynamicBundle*/}
      <div id='product-detail-header'
        className={`product-detail-header${isProductDetailSticky ? ' product-detail-sticky' : ''} ${
          isClientTelingUser ? '--cp-product-detail' : ''
        } animated-slow fadeIn delay-100`}
        style={{ top: isProductDetailSticky ? `${cookieBannerHeight}px` : 'unset' }}
      >
        <div className='product-detail-header__wrap container'>
          <div className='product-detail-header__drop-down'>
            <div className='product-detail-header__product-name'>
              <span className='product-detail-header__product-name__text'>{productNameBundle}</span>
            </div>
            {allowBuy && (
              <div className='product-detail-header__price product-detail-header__price--mobile'>
                <>
                  <span className='product-detail-header__price__list-price'>
                    {Global.formatDynamicCurrency(allReviewDetailsProduct?.productBundle?.sellPrice, 'S$', 0, 'en-us', 2)}
                  </span>
                  {allReviewDetailsProduct?.productBundle?.sellPrice !== allReviewDetailsProduct?.productBundle?.listPrice ? (
                    <del className='product-detail-header__price__selling-price'>
                      {Global.formatDynamicCurrency(allReviewDetailsProduct?.productBundle?.listPrice, 'S$', 0, 'en-us', 2)}
                    </del>
                  ) : (
                    ''
                  )}
                </>
              </div>
            )}
          </div>
          <div className='product-detail-header__group'>
            {allowBuy && (
              <div className='product-detail-header__price product-detail-header__price--desktop'>
                <>
                  {allReviewDetailsProduct?.productBundle?.listPrice !== allReviewDetailsProduct?.productBundle?.sellPrice ? (
                    <del className='product-detail-header__price__selling-price'>
                      {Global.formatDynamicCurrency(
                        allReviewDetailsProduct?.productBundle?.listPrice,
                        allReviewDetailsProduct?.currency?.symbol,
                        allReviewDetailsProduct?.currency?.symbolPosition,
                        allReviewDetailsProduct?.currency?.currencyNumberFormatCulture,
                        allReviewDetailsProduct?.currency?.currencyDecimalDigits
                      )}
                    </del>
                  ) : (
                    ''
                  )}
                  <span className='product-detail-header__price__list-price'>
                    {Global.formatDynamicCurrency(
                      allReviewDetailsProduct?.productBundle?.sellPrice,
                      allReviewDetailsProduct?.currency?.symbol,
                      allReviewDetailsProduct?.currency?.symbolPosition,
                      allReviewDetailsProduct?.currency?.currencyNumberFormatCulture,
                      allReviewDetailsProduct?.currency?.currencyDecimalDigits
                    )}
                  </span>
                </>
              </div>
            )}
            <div className='promotion-detail-bundle__header__btn product-detail-header__btn'>
              {showNotifyMe == 'true' ? (
                <button className='promotion-detail-bundle__header__btn__item btn btn-outline-primary' onClick={() => setIsShowNotifyMe(true)}>
                  {messageObj?.['LBL-NotifyMe']}
                </button>
              ) : allowBuy ? (
                <Link field={buyNowButtonLink}>
                  <Text tag='span' className='promotion-detail-bundle__header__btn__item btn btn-primary' field={dataSources['Buy Now Text']} />
                </Link>
              ) : (
                <Text
                  tag='span'
                  className='promotion-detail-bundle__header__btn__item btn btn-primary disabled'
                  field={dataSources['Buy Now Text']}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Reuse in DynamicBundleHeader */}
      <ModalComponent
        isShow={isShowNotifyMe}
        titlePopup={<span>{messageObj?.['LBL-NotifyMe']}</span>}
        onCloseHandler={() => onClosePopupNotifyHandler()}
      >
        <NotifyMePopup inputData={{ productId: allReviewDetailsProduct?.productId }} notifyMeEvt={onSubmitNotifyHandler}>
          {message}
        </NotifyMePopup>
      </ModalComponent>
    </>
  );
};

ProductDetailHeaderDynamicBundle.propTypes = {
  dataSources: Proptypes.any,
  isProductDetailSticky: Proptypes.bool,
  isClientTelingUser: Proptypes.bool,
  cookieBannerHeight: Proptypes.number
};

export default ProductDetailHeaderDynamicBundle;
