import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getTotalBookedSlotsFailure, getTotalBookedSlotsSuccess } from '@redux/actions/campaignRegister/getTotalBookedSlots';
import { GET_TOTAL_BOOKED_SLOTS_START } from '@redux/actions/campaignRegister/getTotalBookedSlots/types';
import { get } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import { GET_BOOKED_SLOTS_ENDPOINT } from './config';

const getTotalBookedSlotsEpic = (action$) =>
  action$.pipe(
    ofType(GET_TOTAL_BOOKED_SLOTS_START),
    switchMap((action) => {
      const campaignId = `{${action.payload.campaignId}}`;
      const storeId = `{${action.payload.storeId}}`;
      const contactId = action.payload.contactId ? `{${action.payload.contactId}}` : '';

      const params = {
        campaignId: campaignId,
        storeId: storeId
      };
      if (contactId) params.contactId = contactId;

      return from(get(GET_BOOKED_SLOTS_ENDPOINT, params)).pipe(
        map((res) => {
          if (res.data.StatusCode === 0) {
            return getTotalBookedSlotsSuccess(deepCopy(res.data.Result));
          } else {
            return getTotalBookedSlotsFailure(res.data || 'Failed');
          }
        }),
        catchError((error) => getTotalBookedSlotsFailure(error || 'Failed'))
      );
    })
  );

export default getTotalBookedSlotsEpic;
