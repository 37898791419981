import * as dynamicBundleTypes from './dynamicBundleTypes';

export const setDynamicBundlePrice = (payload) => ({
  type: dynamicBundleTypes.SET_DYNAMIC_BUNDLE_PRICE,
  payload
});

export const setListQuantityBundle = (payload) => ({
  type: dynamicBundleTypes.SET_LIST_QUANTITY_BUNDLE,
  payload
});

export const updateQuantityBundle = (payload) => ({
  type: dynamicBundleTypes.UPDATE_QUANTITY_BUNDLE,
  payload
});

export const initBundleData = (payload) => ({
  type: dynamicBundleTypes.INIT_DYNAMIC_BUNDLE,
  payload
});