import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const LABEL_ARR = [
  'Title',
  'Button New Appointments Text',
  'View Label',
  'Upcoming Appointments Empty Text',
  'Past Appointments Empty Text',
  'Upcoming Appointments Label',
  'Past Appointments Label',
  'View Loyalty Label',
  'Guests Label'
];

const LINK_ARR = [
  'Button New Appointments Link',
  'View Link',
  'View Loyalty Link'
];

export default class MyAppointmentsModel {
  constructor() {
    LABEL_ARR.forEach(label => {
      this[label] = new SitecoreTextModel();
    });

    LINK_ARR.forEach(link => {
      this[link] = new SitecoreLinkModel();
    });

    this['upcomingAppointments'] = [];

    this['pastAppointments'] = [];
  }

  getData(dataSource) {
    LABEL_ARR.forEach(label => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, label)) {
        this[label] = dataSource[label] ?? '';
      }
    });

    LINK_ARR.forEach(link => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link] = dataSource[link] ?? '';
      }
    });
    
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'upcomingAppointments')) {
      this['upcomingAppointments'] = dataSource['upcomingAppointments'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'pastAppointments')) {
      this['pastAppointments'] = dataSource['pastAppointments'];
    }

    return this;
  }
}