import produce from 'immer';

// Import action types
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  recentlyProduct: [],
};

const recentlyProductReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.SET_RECENTTLY_PRODUCT:
      draft.recentlyProduct.unshift(deepCopy(action.payload));
      break;

    case productTypes.CHANGE_RECENTTLY_PRODUCT:
      draft.recentlyProduct = deepCopy(draft.recentlyProduct).filter(itemProduct => itemProduct.ProductId !== action.payload.ProductId);
      break; 
    }

    return draft;
  });

export default recentlyProductReducer;
