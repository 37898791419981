import './TaxMessageDetail.scss';

import React, { memo, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { taxMessageSelector } from '@redux/reducers/taxMessageDetail/selector';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const TaxMessageEl = () => {
  const { isEnable, taxMessageText } = useSelector(taxMessageSelector);

  const isShowEl = useMemo(() => {
    return isEnable && taxMessageText?.value;
  }, [isEnable, taxMessageText]);

  return isShowEl ? <RichText tag='span' field={taxMessageText} className='tax-message-detail-text' /> : <></>;
};

export default memo(TaxMessageEl);
