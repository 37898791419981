export default class DefaultLocatorApiModel {
  constructor() {
    this.Id = '';

    this.DefaultCenterLatitude = '';

    this.DefaultCenterLongtitude = '';

    this.GoogleMapAPIKey = '';

    this.Distance = '';

    this.BaiduMapAPIKey = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DefaultCenterLatitude')) {
      this['DefaultCenterLatitude'] = dataSource['DefaultCenterLatitude'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DefaultCenterLongtitude')) {
      this['DefaultCenterLongtitude'] = dataSource['DefaultCenterLongtitude'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GoogleMapAPIKey')) {
      this['GoogleMapAPIKey'] = dataSource['GoogleMapAPIKey'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Distance')) {
      this['Distance'] = dataSource['Distance'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BaiduMapAPIKey')) {
      this['BaiduMapAPIKey'] = dataSource['BaiduMapAPIKey'];
    }

    return this;
  }
}
