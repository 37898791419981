import './ReviewMedia.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { MEDIA_TRACKING_GOAL_ID, mediaStartTrackingGoal } from '@redux/actions/trackingGoal/mediaTracking/index';
import Global from '@utils/global';

import ReviewMediaPopup from '../ReviewMediaPopup';

const ReviewMedia = ({ mediaData, reviewItem, isShowFull=false }) => {
  const limitedImage = isShowFull ? 8 : 4;
  const limitShows = 3;
  const [showPopup, setShowPopup] = useState({visible: false, index: 0});

  const dispatch = useDispatch();

  const shouldShowMoreImage = (index) => {
    const hasMoreImage = mediaData?.length > limitShows;
    const isLastImage =  index === limitedImage - 1;

    return hasMoreImage && isLastImage;
  };

  const onClosePopupHandler = () => {
    setShowPopup({visible: false, index: 0});
  };

  const handleShowPopup = (mediaType, index) => {
    setShowPopup({visible: true, index: index});

    if (mediaType === 'video') {
      dispatch(mediaStartTrackingGoal({
        'GoalId': MEDIA_TRACKING_GOAL_ID,
        'UseTrackerAPI': false
      }));
    }
  };

  return mediaData?.length ? (
    <>
      <ul className='review-media__list'>
        {mediaData?.slice(0, limitedImage).map((media, index) => {
          return(
            <li 
              className={`review-media__item ${media.ContentType === 'video' ? 'media-item--video-type' : ''}`} 
              key={index} 
              onClick={() => handleShowPopup(media.ContentType, index)}
            >
              {media.ContentType === 'image' ? 
                <img src={media.Data} alt='x' />
                : 
                <video playsInline={true} muted='muted'>
                  <source src={media.Data} type={`video/${Global.videoType(media.Data)}`}/>
                </video>
              }
              {!isShowFull && shouldShowMoreImage(index) && <span className='review-media__item__number'>+{mediaData?.length - limitShows}</span>}
            </li>
          );
        })}
      </ul>
      <ModalComponent
        isShow={showPopup.visible}
        onCloseHandler={onClosePopupHandler}
        cssClass='review-media-popup'
        titlePopup='review image'
      >
        <ReviewMediaPopup mediaData={mediaData} reviewItem={reviewItem} index={showPopup.index}/>
      </ModalComponent>
    </>
  ) : <></>;
};

ReviewMedia.propTypes = {
  mediaData: PropTypes.any,
  reviewItem : PropTypes.any,
  isShowFull: PropTypes.bool
};

export default ReviewMedia;