export default class ValueModel {
  constructor() {
    this['value'] = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this['value'] = dataSource['value'];
    }

    return this;
  }
}
