import PropTypes from 'prop-types';
import React from 'react';

import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

const CartEmty = ({onDataSources}) => {  
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  return (
    <div className='container'>
      {isClientTelingUser ? (
        <div className='cart-detail__empty-cp'>
          <Text field={ onDataSources['CP Cart Empty Text'] } tag='p' className='cart-detail__empty-cp__notification'/>
          <Link field={onDataSources['Additional Link']?.Link} className='cart-detail__empty-cp__link'>
            <Text field={onDataSources['Additional Link']?.Title}/>
          </Link>
        </div>
      ) : (
        <div className='cart-detail__empty'>
          <Text field={ onDataSources['Cart Empty Text'] }/>
        </div>
      )}
    </div>
  );
};

CartEmty.propTypes = {
  onDataSources: PropTypes.object,
};

export default CartEmty;
