import './BOConfirmDemoProduct.scss';

import PropTypes from 'prop-types';
import React from 'react';

import Message from '@components/Share/Message';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const BOConfirmDemoProduct = ({ Acknowledgement, confirm, setConfirmHandler, setRef, isShowError }) => {
  return (
    <div className='confirm-demo-product'>
      <div className='osim-checkbox' ref={setRef}>
        <input
          type='checkbox'
          defaultChecked={confirm}
          onClick={() => setConfirmHandler(!confirm)}
          name='confirm-demo'
          id='confirm-demo'
          className='osim-checkbox-input'
        />
        <label htmlFor='confirm-demo' className='osim-checkbox-label'>
          <RichText field={{value: Acknowledgement}} />
        </label>
      </div>
      <span className={'error-validate' + `${isShowError ? ' active' : ''}`}>
        <Message messageCode='Msg_015' />
      </span>
    </div>
  );
};

BOConfirmDemoProduct.propTypes = {
  Acknowledgement: PropTypes.any,
  setConfirmHandler: PropTypes.func,
  confirm: PropTypes.bool,
  setRef: PropTypes.any,
  isShowError: PropTypes.any
};

export default BOConfirmDemoProduct;
