import './BundleKeyFeature.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import BundleKeyFeatureModel from './model/BundleKeyFeatureModel';

const BundleKeyFeature = (props) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const model = new BundleKeyFeatureModel();

    setInputData(model.getData(deepCopy(props.fields) || {}));
  }, []);

  return (
    inputData && (
      <Fragment>
        <div className='item-description'>
          <div className='container'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='item-description__image'>
                  <img src={require('../../assets/images/cart-item-image-bigger.png')} alt='x' />
                </div>
              </div>
              <div className='col-lg-6 item-description__contents-wrapper'>
                <div className='item-description__contents'>
                  <Text field={inputData['Title']} tag='h2' className='item-description__contents__title' />
                  <Text field={inputData['Description']} tag='p' className='item-description__contents__desription normal-text' />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='note-contents'>
          <div className='container note-contents__container'>
            <Text field={inputData['Title']} tag='h2' className='note-contents__title' />
            <Text field={inputData['Description']} tag='p' className='note-contents__description' />
            <div className='note-contents__button'>
              <Link field={inputData['Experience In Store Button Link']} className='btn btn-outline-CTA2'>
                <Text tag='span' field={inputData['Experience In Store Button Label']} />
              </Link>
            </div>
          </div>
        </div>
      </Fragment>
    )
  );
};

BundleKeyFeature.propTypes = {
  fields: PropTypes.object
};

export default BundleKeyFeature;
