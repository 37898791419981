import produce from 'immer';

import * as cPStartDemoTypes from '@redux/actions/cPStartDemo/cPStartDemoTypes';

const initState = {
  isLoading: false,
  isStartDemoSuccess: false,
  isShowWarningPopup: false,
  needRunSaveSOCartApi: false
};

const cPStartDemoReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cPStartDemoTypes.POST_CP_START_DEMO:
      draft.isLoading = true;

      draft.isStartDemoSuccess = false;

      draft.needRunSaveSOCartApi = false;
      break;

    case cPStartDemoTypes.POST_CP_START_DEMO_SUCCESS:
      draft.isLoading = false;

      draft.isStartDemoSuccess = true;

      draft.needRunSaveSOCartApi = action.payload.needRunSaveSOCartApi;
      break;

    case cPStartDemoTypes.POST_CP_START_DEMO_FAILED:
      draft.isLoading = false;

      draft.isStartDemoSuccess = false;

      draft.needRunSaveSOCartApi = false;

      draft.cPError = action.error;
      break;

    case cPStartDemoTypes.SHOW_CP_WARNING_POPUP:
      draft.isShowWarningPopup = true;
      break;

    case cPStartDemoTypes.HIDE_CP_WARNING_POPUP:
      draft.isShowWarningPopup = false;
      break;
    }

    return draft;
  });

export default cPStartDemoReducer;
