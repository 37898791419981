export default class ProductAvailabilityApiModel {
  constructor() {
    this.ItemId = '';

    this.Quantity = '';

    this.Name = '';

    this.AvailableDate = '';

    this.AvailabilityExpires = '';

    this.Type = '';

    this.StatusStock = '';

    this.IsAvailable = '';

    this.Policies = [];

    this.IsAlwaysAvailable = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId')) {
      this.ItemId = dataSource.ItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Quantity')) {
      this.Quantity = dataSource.Quantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Type')) {
      this.Type = dataSource.Type;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StatusStock')) {
      this.StatusStock = dataSource.StatusStock;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsAvailable')) {
      this.IsAvailable = dataSource.IsAvailable;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Policies')) {
      this.Policies = dataSource.Policies;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsAlwaysAvailable')) {
      this.IsAlwaysAvailable = dataSource.IsAlwaysAvailable;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AvailabilityExpires')) {
      this.AvailabilityExpires = dataSource.AvailabilityExpires;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AvailableDate')) {
      this.AvailableDate = dataSource.AvailableDate;
    }

    return this;
  }
}
