import { object } from 'prop-types';
import React from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import WarrantyRegistrationThankYouDescription from '../WarrantyRegistrationThankYouDescription';

const ProductPreviewSubmitted = ({ layoutData }) => {
  return (
    <div className='product-thank-you-page'>
      <div className='product-thank-you-page__top'>
        <Image
          className='product-thank-you-page__top__icon--success'
          field={layoutData['Review Success Icon']}
          alt={layoutData['Review Success Icon'].value.alt}
        />
        <h3 className='product-thank-you-page__top__title'>
          <Text field={layoutData['Review Success Text']} />
        </h3>
        <p className='product-thank-you-page__top__notification'>
          <Text field={layoutData['Review Success Description Text']} />
        </p>
      </div>
      <div className='product-thank-you-page__bottom'>
        <WarrantyRegistrationThankYouDescription isProductReview fieldData={layoutData} />
      </div>
    </div>
  );
};

ProductPreviewSubmitted.propTypes = {
  layoutData: object
};

export default ProductPreviewSubmitted;
