export default class BundlePriceCommonModel {
  constructor() {
    this['SellingPrice'] = 0;

    this['ListPrice'] = 0;

    this['CurrencyCode'] = '';

    this['SellingPriceWithCurrency'] = '';

    this['ListPriceWithCurrency'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice')) {
      this['SellingPrice'] = dataSource['SellingPrice'] ? dataSource['SellingPrice'] : this['SellingPrice'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this['ListPrice'] = dataSource['ListPrice'] ? dataSource['ListPrice'] : this['ListPrice'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyCode')) {
      this['CurrencyCode'] = dataSource['CurrencyCode'] ? dataSource['CurrencyCode'] : this['CurrencyCode'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPriceWithCurrency')) {
      this['SellingPriceWithCurrency'] = dataSource['SellingPriceWithCurrency'] ? dataSource['SellingPriceWithCurrency'] : this['SellingPriceWithCurrency'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPriceWithCurrency')) {
      this['ListPriceWithCurrency'] = dataSource['ListPriceWithCurrency'] ? dataSource['ListPriceWithCurrency'] : this['ListPriceWithCurrency'];
    }
    
    return this;
  }
}
