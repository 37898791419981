import './CartList.scss';

import PropTypes from 'prop-types';
import React from 'react';

import CartItem from './CartItem';

const CartList = (props) => {
  const { dataSources, cartLines } = props;

  return (
    <div className='cart-list'>
      <div className='container'>
        { cartLines?.length ? cartLines.map((lineItem, index) => (
          <div className='cart-list__item' key={lineItem.ExternalCartLineId}>
            <CartItem dataSources={dataSources} cartItemInfo={lineItem} indexGA4={index}/>
          </div>
        )) : ''}
      </div>
    </div>
  );
};

CartList.propTypes = {
  cartLines: PropTypes.array,
  dataSources: PropTypes.object
};

export default CartList;
