import './YourProductHubDetail.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import YourProductHubDetailModel from './model/YourProductHubDetailModel';
import ProductHubItem from './ProductHubItem';

const YourProductHubDetail = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new YourProductHubDetailModel();

    setLayoutData(model.getData({ fields }));
  }, []);

  return layoutData && layoutData?.fields?.['Your Product Hub Options']?.length ? (
    <>
      <div className='your-product-hub'>
        {layoutData.fields?.['Title'] && <Text field={layoutData.fields?.['Title']} className='your-product-hub__title' tag='h2' />}
        <div className='container'>
          {layoutData.fields?.['Your Product Hub Options']?.map((item) =>
            !item['Disable']?.value ? <ProductHubItem key={item.id} item={item} /> : <></>
          )}
        </div>
      </div>
    </>
  ) : (
    <></>
  );
};

YourProductHubDetail.propTypes = {
  fields: any
};

export default YourProductHubDetail;
