import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/myAppointment';
import * as myAppointmentTypes from '@redux/actions/myAppointment/myAppointmentTypes';
import { postAsObservable } from '@services/genericService';

import * as config from './config';

const updateAppointmentEpic = (action$) =>
  action$.pipe(
    ofType(myAppointmentTypes.UPDATE_APPOINTMENT),
    switchMap((action) => {
      return postAsObservable(config.ACTION_UPDATE_APPOINTMENT, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            if (res.data?.StatusCode === 0 || res.data?.StatusCode === 23) {
              return actions.updateAppointmentSuccess(res.data);
            } else {
              return actions.updateAppointmentFailed(res.data || 'Failed');
            }
          } else {
            return actions.updateAppointmentFailed(res.data?.StatusCode || 'Failed');
          }

        }),
        catchError((error) => of(actions.updateAppointmentFailed(error)))
      );
    })
  );

export default updateAppointmentEpic;
