import { useLayoutEffect, useState } from 'react';

export const useWindowSize = () => {
  const [currentWidth, setCurrentWidth] = useState(0);

  useLayoutEffect(() => {
    const updateSize = () => {
      setCurrentWidth(window.innerWidth);
    };

    window.addEventListener('resize', updateSize);

    updateSize();

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return currentWidth;
};
