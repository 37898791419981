import TextModel from '@models/TextModel';
import { deepCopy } from '@utils/utility';

import WellnessArticleItemModel from './WellnessArticleItemModel';

export default class WellnessArticleModel {
  constructor() {
    this['Articles'] = [];

    this['Title'] = new TextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Articles')) {
      let newArticles = [];

      if (dataSource['Articles']) {
        dataSource['Articles'].map(element => {
          newArticles.push(new WellnessArticleItemModel().getData(deepCopy(element) || {}));
        });
      }

      this['Articles'] = dataSource['Articles']?.length ? newArticles : this['Articles'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    
    return this;
  }
}
