import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CPVerifyLoginModel {
  constructor() {
    this['Background Image'] = new SitecoreImageModel();

    this['Logo'] = new SitecoreImageModel();

    this['Title'] = new SitecoreTextModel();

    this['Redirect If Not Need Verify'] = new SitecoreLinkModel();

    this['Verify Success Link'] = new SitecoreLinkModel();
   
    this['Send Code Button Text'] = new SitecoreTextModel();

    this['Verify Code Button Text'] = new SitecoreTextModel();

    this['Verify Code Text Placeholder'] = new SitecoreTextModel();

    this['First Time Disable Resend Button'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Image')) {
      this['Background Image'].getData(dataSource['Background Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Logo')) {
      this['Logo'].getData(dataSource['Logo']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Redirect If Not Need Verify')) {
      this['Redirect If Not Need Verify'].getData(dataSource['Redirect If Not Need Verify']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Verify Success Link')) {
      this['Verify Success Link'].getData(dataSource['Verify Success Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Send Code Button Text')) {
      this['Send Code Button Text'].getData(dataSource['Send Code Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Verify Code Button Text')) {
      this['Verify Code Button Text'].getData(dataSource['Verify Code Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Verify Code Text Placeholder')) {
      this['Verify Code Text Placeholder'].getData(dataSource['Verify Code Text Placeholder']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'First Time Disable Resend Button')) {
      this['First Time Disable Resend Button'].getData(dataSource['First Time Disable Resend Button']);
    }

    return this;
  }
}
