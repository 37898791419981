import { createContext } from 'react';

const initialStates = {
  layoutData: null,
  formik: null,
  isDisableFormSection: false
};
const SelectStoreAndDateContext = createContext(initialStates);

export default SelectStoreAndDateContext;
