import { object } from 'prop-types';
import React from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { useControlRichText } from './hooks/useControlRichText';

const AddToCartSuccess = ({ inputData }) => {
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const isShowRichText = useControlRichText();

  return isShowRichText ? (
    <div className={['rich-text-content add-to-cart-success', isClientTelingUser ? 'is-cp-cart' : ''].join(' ')}>
      <div className='container'>
        <RichText field={inputData.Text} />
      </div>
    </div>
  ) : (
    <></>
  );
};

AddToCartSuccess.propTypes = {
  inputData: object
};

export default AddToCartSuccess;
