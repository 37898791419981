import produce from 'immer';

import {
  GET_HAD_NOTIFICATION_FAILURE,
  GET_HAD_NOTIFICATION_START,
  GET_HAD_NOTIFICATION_SUCCESS
} from '@redux/actions/cpNotificationPopup/getHadNotifications/types';

const initialState = {
  isLoading: false,
  error: null,
  isHadNotifications: false
};

const getHadNotificationsReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_HAD_NOTIFICATION_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case GET_HAD_NOTIFICATION_SUCCESS:
      draft.isLoading = false;

      draft.isHadNotifications = action.payload;
      break;

    case GET_HAD_NOTIFICATION_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getHadNotificationsReducer;
