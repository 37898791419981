export const GET_CP_CUSTOMER = 'GET_CP_CUSTOMER';

export const GET_CP_CUSTOMER_SUCCESS = 'GET_CP_CUSTOMER_SUCCESS';

export const GET_CP_CUSTOMER_FAILED = 'GET_CP_CUSTOMER_FAILED';

export const SEARCH_CP_CUSTOMER = 'SEARCH_CP_CUSTOMER';

export const SEARCH_CP_CUSTOMER_SUCCESS = 'SEARCH_CP_CUSTOMER_SUCCESS';

export const SEARCH_CP_CUSTOMER_FAILED = 'SEARCH_CP_CUSTOMER_FAILED';

export const GET_CONTACT_PERSONAL_INFORMATION = 'GET_CONTACT_PERSONAL_INFORMATION';

export const GET_CONTACT_PERSONAL_INFORMATION_SUCCESS = 'GET_CONTACT_PERSONAL_INFORMATION_SUCCESS';

export const GET_CONTACT_PERSONAL_INFORMATION_FAILED = 'GET_CONTACT_PERSONAL_INFORMATION_FAILED';

export const SET_IS_ACTIVE_ACCESS = 'SET_IS_ACTIVE_ACCESS';

export const SET_IS_DEACTIVE_ACCESS = 'SET_IS_DEACTIVE_ACCESS';

export const SET_IS_ACTIVE_QA_CUSTOMER_BASIC_PROFILE = 'SET_IS_ACTIVE_QA_CUSTOMER_BASIC_PROFILE';

export const SET_IS_ACTIVE_QA_CUSTOMER_LIFESTYLE = 'SET_IS_ACTIVE_QA_CUSTOMER_LIFESTYLE';

export const SET_IS_ACTIVE_QA_PRODUCT_INTEREST_LISTING = 'SET_IS_ACTIVE_QA_PRODUCT_INTEREST_LISTING';

export const GET_QUICK_ACCESS_LAYOUT_SERVICE = 'GET_QUICK_ACCESS_LAYOUT_SERVICE';

export const UPDATE_CP_CUSTOMER_CONTACT = 'UPDATE_CP_CUSTOMER_CONTACT';

export const UPDATE_CP_CUSTOMER_CONTACT_SUCCESS = 'UPDATE_CP_CUSTOMER_CONTACT_SUCCESS';

export const UPDATE_CP_CUSTOMER_CONTACT_FAILED = 'UPDATE_CP_CUSTOMER_CONTACT_FAILED';

export const UPDATE_CP_CUSTOMER_CONTACT_INTEREST = 'UPDATE_CP_CUSTOMER_CONTACT_INTEREST';

export const UPDATE_CP_CUSTOMER_CONTACT_INTEREST_SUCCESS = 'UPDATE_CP_CUSTOMER_CONTACT_INTEREST_SUCCESS';

export const UPDATE_CP_CUSTOMER_CONTACT_INTEREST_FAILED = 'UPDATE_CP_CUSTOMER_CONTACT_INTEREST_FAILED';

export const END_SESSION = 'END_SESSION';

export const END_SESSION_SUCCESS = 'END_SESSION_SUCCESS';

export const END_SESSION_FAILED = 'END_SESSION_FAILED';

export const STORE_CP_CUSTOMER = 'STORE_CP_CUSTOMER';

export const REVERT_CP_CUSTOMER = 'REVERT_CP_CUSTOMER';

export const TRIGGER_POS_CHANGE_QUESTIONS = 'TRIGGER_POS_CHANGE_QUESTIONS';

export const STORE_QUESTION_PRODUCT_IN_PRODUCT_INTEREST = 'STORE_QUESTION_PRODUCT_IN_PRODUCT_INTEREST';

export const DIRTY_PRODUCT_INTEREST = 'DIRTY_PRODUCT_INTEREST';

export const GET_CUSTOMER_PRODUCTS = 'GET_CUSTOMER_PRODUCTS';

export const GET_CUSTOMER_PRODUCTS_SUCCESS = 'GET_CUSTOMER_PRODUCTS_SUCCESS';

export const GET_CUSTOMER_PRODUCTS_FAILED = 'GET_CUSTOMER_PRODUCTS_FAILED';

export const GET_COUPON_WALLET = 'GET_COUPON_WALLET';

export const GET_COUPON_WALLET_SUCCESS = 'GET_COUPON_WALLET_SUCCESS';

export const GET_COUPON_WALLET_FAILED = 'GET_COUPON_WALLET_FAILED';

export const GET_ORDER_ID = 'GET_ORDER_ID';

export const GET_ORDER_ID_SUCCESS = 'GET_ORDER_ID_SUCCESS';

export const GET_ORDER_ID_FAILED = 'GET_ORDER_ID_FAILED';

export const GET_LAYOUT_QUESTIONS = 'GET_LAYOUT_QUESTIONS';

export const CLEAR_INFORMATION = 'CLEAR_INFORMATION';

export const KEEP_LIFE_STYLE_DATA = 'KEEP_LIFE_STYLE_DATA';

export const GET_CREATE_PDPA_ERROR_CODE = 'GET_CREATE_PDPA_ERROR_CODE';

// remove product interest item
export const REMOVE_CP_PRODUCT_INTEREST = 'REMOVE_CP_PRODUCT_INTEREST';

export const REMOVE_CP_PRODUCT_INTEREST_SUCCESS = 'REMOVE_CP_PRODUCT_INTEREST_SUCCESS';

export const REMOVE_CP_PRODUCT_INTEREST_FAILED = 'REMOVE_CP_PRODUCT_INTEREST_FAILED';
