import BOAdditionalOptionsModel from '@components/BuyingOptions/BOAdditionalOptions/model/BOAdditionalOptionsModel';
import { deepCopy } from '@utils/utility';

import BundleProductVariantDetailsModel from './BundleProductVariantDetailsModel';
import BundleProductWarrantyModel from './BundleProductWarrantyModel';

export default class BundleProductsModel {
  constructor() {
    this['ItemId'] = '';

    this['ProductId'] = '';

    this['Catalog'] = '';

    this['UniqueProductId'] = '';

    this['Image'] = '';

    this['ProductName'] = '';

    this['ListPrice'] = '';

    this['ListPriceWithCurrency'] = '';

    this['SellingPrice'] = '';

    this['SellingPriceWithCurrency'] = '';

    this['CurrencyCode'] = '';

    this['Description'] = '';

    this['ProductPageLink'] = '';

    this['Warranty'] = [];

    this['Variants'] = [];

    this['isChoseVariant'] = false;

    this['isChoseWarranty'] = false;

    this['isChoseAdd'] = false;

    this['isProductPass'] = false;

    this['Image Active'] = '';

    this['Active Variant'] = null;

    this['Active Warranty'] = null;

    this['MaxQuantity'] = 0;

    this['VariantPropertyLabel'] = null;

    this['ChooseVariantValidationMessage'] = null;
	
    this['AdditionalOptions'] = [];

    this['DisposalPolicyLabel'] = '';

    this['DisposalPolicyPage'] = '';
  }
  getData(dataSource, parentIndex, SubLines) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId')) {
      this['ItemId'] = dataSource['ItemId'] ? dataSource['ItemId'] : this['ItemId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this['ProductId'] = dataSource['ProductId'] ? dataSource['ProductId'] : this['ProductId'];

      this['UniqueProductId'] = dataSource['ProductId'] ? `${dataSource['ProductId'] + parentIndex}` : this['UniqueProductId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Catalog')) {
      this['Catalog'] = dataSource['Catalog'] ? dataSource['Catalog'] : this['Catalog'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'] = dataSource['Image'] ? dataSource['Image'] : this['Image'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName')) {
      this['ProductName'] = dataSource['ProductName'] ? dataSource['ProductName'] : this['ProductName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this['ListPrice'] = dataSource['ListPrice'] ? dataSource['ListPrice'] : this['ListPrice'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPriceWithCurrency')) {
      this['ListPriceWithCurrency'] = dataSource['ListPriceWithCurrency'] ? dataSource['ListPriceWithCurrency'] : this['ListPriceWithCurrency'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice')) {
      this['SellingPrice'] = dataSource['SellingPrice'] ? dataSource['SellingPrice'] : this['SellingPrice'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPriceWithCurrency')) {
      this['SellingPriceWithCurrency'] = dataSource['SellingPriceWithCurrency']
        ? dataSource['SellingPriceWithCurrency']
        : this['SellingPriceWithCurrency'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyCode')) {
      this['CurrencyCode'] = dataSource['CurrencyCode'] ? dataSource['CurrencyCode'] : this['CurrencyCode'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'] = dataSource['Description'] ? dataSource['Description'] : this['Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductPageLink')) {
      this['ProductPageLink'] = dataSource['ProductPageLink'] ? dataSource['ProductPageLink'] : this['ProductPageLink'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantPropertyLabel')) {
      this['VariantPropertyLabel'] = dataSource['VariantPropertyLabel'] ? dataSource['VariantPropertyLabel'] : this['VariantPropertyLabel'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ChooseVariantValidationMessage')) {
      this['ChooseVariantValidationMessage'] = dataSource['ChooseVariantValidationMessage']
        ? dataSource['ChooseVariantValidationMessage']
        : this['ChooseVariantValidationMessage'];
    }
	
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Warranty')) {
      let newBundleProductWarranty = [];
      let newDataWarranty;

      dataSource['Warranty'].map((element) => {
        newBundleProductWarranty.push(new BundleProductWarrantyModel().getData(deepCopy(element) || {}, this['ItemId']));
      });

      // let activeWarrantyId = SubLines?.WarrantyId || deepCopy(newBundleProductWarranty)[0]?.ProductId || null;

      let activeWarrantyId = SubLines?.WarrantyId || null;

      newDataWarranty = newBundleProductWarranty.map((item) => {
        if (item.ProductId === activeWarrantyId) {
          return { ...item, UniqueProductId: this['UniqueProductId'], active: true };
        } else {
          return { ...item, UniqueProductId: this['UniqueProductId'], active: false };
        }
      });

      this['Warranty'] = newDataWarranty || this['Warranty'];

      this['isChoseWarranty'] = SubLines?.WarrantyId ? true : dataSource['Warranty']?.length ? false : true;

      this['Active Warranty'] = SubLines?.WarrantyId ? newDataWarranty.filter((item) => item.active === true)[0] : newDataWarranty[0] || null;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MaxQuantity')) {
      this['MaxQuantity'] = dataSource['MaxQuantity'] ? dataSource['MaxQuantity'] : this['MaxQuantity'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Variants')) {
      let newBundleProductVariantDetails = [];
      let newDataVariant;

      dataSource['Variants'].map((element) => {
        newBundleProductVariantDetails.push(new BundleProductVariantDetailsModel().getData(deepCopy(element) || {}, this['MaxQuantity']));
      });

      // let activeVariantId = SubLines?.VariantId || deepCopy(newBundleProductVariantDetails).filter(item => item.isAvaiable === true)[0]?.VariantId
      // || dataSource['Variants'][0].VariantId;

      let activeVariantId = SubLines?.VariantId || null;

      newDataVariant = newBundleProductVariantDetails.map((item) => {
        if (item.VariantId === activeVariantId) {
          return { ...item, UniqueProductId: this['UniqueProductId'], active: true };
        } else {
          return { ...item, UniqueProductId: this['UniqueProductId'], active: false };
        }
      });

      this['Variants'] = newDataVariant || this['Variants'];

      this['isChoseVariant'] = SubLines?.VariantId ? true : dataSource['Variants']?.length ? false : true;

      this['Active Variant'] = SubLines?.VariantId
        ? newDataVariant.filter((item) => item.active === true)[0] || newDataVariant.filter((item) => item.isAvaiable === true)[0]
        : newDataVariant[0] || null;
    }
    if (SubLines) {
      this['isProductPass'] = true;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AdditionalOptions')) {
      dataSource['AdditionalOptions'].map((element) => {
        this['AdditionalOptions'].push(new BOAdditionalOptionsModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisposalPolicyLabel')) {
      this['DisposalPolicyLabel'] = dataSource['DisposalPolicyLabel'] ? dataSource['DisposalPolicyLabel'] : this['DisposalPolicyLabel'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisposalPolicyPage')) {
      this['DisposalPolicyPage'] = dataSource['DisposalPolicyPage'] ? dataSource['DisposalPolicyPage'] : this['DisposalPolicyPage'];
    }

    return this;
  }
}
