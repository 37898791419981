import SitecoreTextModel from '@models/SitecoreTextModel';

import DynamibBundleDataModel from './DynamibBundleDataModel';

export default class DynamicBundleElementsModel {
  constructor() {
    this['Learn More Label'] = new SitecoreTextModel();

    this['Load More Label'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Dynamic Bundle Data'] = new DynamibBundleDataModel();

    this['TotalListPriceWithCurrency'] = '';

    this['TotalSellingPriceWithCurrency'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Label')) {
      this['Learn More Label'].getData(dataSource['Learn More Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Load More Label')) {
      this['Load More Label'].getData(dataSource['Load More Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Dynamic Bundle Data') &&  dataSource['Dynamic Bundle Data']) {
      this['Dynamic Bundle Data'] = dataSource['Dynamic Bundle Data'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalListPriceWithCurrency') && dataSource['TotalListPriceWithCurrency']) {
      this['TotalListPriceWithCurrency'] = dataSource['TotalListPriceWithCurrency'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalSellingPriceWithCurrency') && dataSource['TotalSellingPriceWithCurrency']) {
      this['TotalSellingPriceWithCurrency'] = dataSource['TotalSellingPriceWithCurrency'];
    }

    return this;
  }
}
