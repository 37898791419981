// Import dependencies, operators
import { combineEpics } from 'redux-observable';

import {
  addStaffEpic,
  addToCartEpic,
  addToCartV2Epic,
  addToSOCartEpic,
  addToSOCartV2Epic,
  getCartDeliveryEpic,
  getCartStripAdsEpic,
  getCurrentCartEpic,
  getCurrentSOCartEpic,
  getGiftingOptionEpic,
  getPickUpPointEpic,
  getPromotionByIdEpic,
  postBOTradeInFileEpic,
  removeCartEpic,
  removeGiftingOption,
  removeSOCartEpic,
  saveSOcartEpic,
  setCartFullfillment,
  setCartLineFullfillment,
  updateCartEpic,
  updateCPCustomerEpic,
  updateOneCartEpic,
  updateSOCartEpic
} from './cartDetail';
import defaultLocatorEpic from './defaultLocator';
import freeGiftEpic from './freeGift';
import { getNotifyProductStatusEpic } from './notifyProductStatus';
import productAlsoBoughtEpic from './productAlsoBought';
import productCountdownTimerEpic from './productCountdownTimer';
import { productDetailEpic } from './productDetail';
import { getProductDetailHeaderEpic } from './productDetailHeader';
import { productDetailOverviewEpic } from './productDetailOverview';
import { fetchProductInformation } from './productInformation';
import { getFilterProductListEpic, productListEpic } from './productList';
import productWishlistEpic from './productWishlist';
import specialDealEpic from './specialDeal';
import getStoresListEpic from './storesList';
import warrantyEpic from './warranty';

const productEpic = combineEpics(
  productListEpic,
  getFilterProductListEpic,
  fetchProductInformation,
  productAlsoBoughtEpic,
  freeGiftEpic,
  defaultLocatorEpic,
  getStoresListEpic,
  warrantyEpic,
  productWishlistEpic,
  getProductDetailHeaderEpic,
  productDetailOverviewEpic,
  getNotifyProductStatusEpic,
  specialDealEpic,
  getCurrentCartEpic,
  getCurrentSOCartEpic,
  getGiftingOptionEpic,
  removeGiftingOption,
  addToCartEpic,
  addToSOCartEpic,
  removeCartEpic,
  removeSOCartEpic,
  updateCartEpic,
  updateSOCartEpic,
  setCartFullfillment,
  setCartLineFullfillment,
  updateOneCartEpic,
  getCartStripAdsEpic,
  getPickUpPointEpic,
  addStaffEpic,
  getPromotionByIdEpic,
  getCartDeliveryEpic,
  productDetailEpic,
  postBOTradeInFileEpic,
  updateCPCustomerEpic,
  productCountdownTimerEpic,
  saveSOcartEpic,
  productCountdownTimerEpic,
  addToCartV2Epic,
  addToSOCartV2Epic
);

export default productEpic;
