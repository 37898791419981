import produce from 'immer';

import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';

import { deepCopy } from '../../../../utils/utility';

const initState = {
  currentCart: null,
  cartStripAds: null,
  currentCartCustom: null,
  isLoading: false,
  error: '',
  temporalCart: {},
  promotionData: null,
  DepositListProduct: null
};

const balanceByIdReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myOrderTypes.GET_BALANCE_BY_ID:
      draft.isLoading = true;

      draft.isRegister = null;
      break;

    case myOrderTypes.GET_BALANCE_BY_ID_SUCCESS:
      draft.currentCart = deepCopy(action.payload);

      draft.currentCartCustom = {
        // Delivery: action.payload.Lines.filter(item=> item?.Deposit?.IsDepositCartLine === false),
        Delivery: action.payload.Lines.filter((item) => item?.IsDepositFreeGift === false),
        PickUpStore: action.payload.Lines.filter((item) => !item.IsDelivery && !item.IsSFexpress && !item.IsDeliveryBySFExpress),
        SFexpress: action.payload.Lines.filter((item) => item.IsSFexpress),
        DeliveryBySFExpress: action.payload.Lines.filter((item) => item.IsDeliveryBySFExpress),
        BillingAddress: action.payload.BillingAddress,
        Party: action.payload.Party,
        IsDeliveryFee: action.payload.Lines.filter((line) => line.IsDelivery).length ? true : false,
        IsDeliveryPickupFee: action.payload.Lines.filter((line) => line.IsSFexpress).length ? true : false,
        Staff: action.payload.Staff,
        Payments: action.payload.Payments,
        AppliedOffers: action.payload.AppliedOffers,
        PaymentMethodName: action.payload.PaymentMethodName,
        PromoCodes: action.payload.PromoCodes,
        SFExpressTotal: action.payload.SFExpressTotal,
        InstallmentPeriod: action.payload.InstallmentPeriod,
        Shipments: action.payload.Shipments,
        // INFO: new update from ticket https://jira.smartosc.com/browse/OSIM-6543 => to check deposit product using
        // Deposit !== null
        // DepositListProduct: action.payload?.Lines.filter((item) => item?.Deposit?.IsDepositCartLine === true),
        // IsDeposit: action.payload?.Lines[0].Deposit?.IsDepositCartLine
        DepositListProduct: action.payload?.Lines.filter((item) => item?.Deposit !== null),
        IsDeposit: action.payload?.Lines?.[0]?.Deposit !== null
      };
      break;

    case myOrderTypes.GET_BALANCE_BY_ID_FAILED:
      draft.isLoading = false;
      break;


    case myOrderTypes.REMOVE_BALANCE_CART:
      draft.isLoading = true;

      draft.isRegister = null;
      break;

    case myOrderTypes.REMOVE_BALANCE_CART_SUCCESS:
      draft.currentCart = deepCopy(action.payload);

      draft.currentCartCustom = {
        Delivery: action.payload.Lines.filter(item=> !item?.Deposit),
        PickUpStore: action.payload.Lines.filter(item => !item.IsDelivery && !item.IsSFexpress && !item.IsDeliveryBySFExpress),
        SFexpress: action.payload.Lines.filter(item => item.IsSFexpress),
        DeliveryBySFExpress: action.payload.Lines.filter(item => item.IsDeliveryBySFExpress),
        BillingAddress: action.payload.BillingAddress,
        Party: action.payload.Party,
        IsDeliveryFee: action.payload.Lines.filter((line) => line.IsDelivery).length ? true : false,
        IsDeliveryPickupFee: action.payload.Lines.filter((line) => line.IsSFexpress).length ? true : false,
        Staff: action.payload.Staff,
        Payments: action.payload.Payments,
        AppliedOffers: action.payload.AppliedOffers,
        PaymentMethodName: action.payload.PaymentMethodName,
        PromoCodes: action.payload.PromoCodes,
        SFExpressTotal: action.payload.SFExpressTotal,
        InstallmentPeriod: action.payload.InstallmentPeriod,
        Shipments: action.payload.Shipments,
        // INFO: new update from ticket https://jira.smartosc.com/browse/OSIM-6543 => to check deposit product using
        // Deposit !== null
        // DepositListProduct: action.payload.Lines.filter(item=> item?.Deposit?.IsDepositCartLine === true)
        DepositListProduct: action.payload.Lines.filter(item=> item?.Deposit !== null)
      };
      break;

    case myOrderTypes.REMOVE_BALANCE_CART_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default balanceByIdReducer;
