import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SocialNavigationModel {
  constructor() {
    this.Title = new SitecoreTextModel();

    this.items = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title = dataSource.Title;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      this.items = dataSource.items ? dataSource.items : this.items;
    }

    return this;
  }
}
