import './MyProductReview.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLatestReview } from '@redux/actions/productReview';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ProductReviewContent from '../ProductReviewContent';
import MyProductReviewModels from './Models/MyProductReviewModels';
import MyProductReviewShimmer from './MyProductReviewShimmer';

const ProductReviewForPDP = ({ fields }) => {
  const dispatch = useDispatch();
  const latestReviewReducer = useSelector((state) => state.productReviewReducer.latestReviewReducer);
  const [myProductReviewFields, setMyProductReviewFields] = useState(null);

  const reviewLatest = latestReviewReducer?.latestReview?.Data?.[0];
  const isLoading = latestReviewReducer?.isLoading;

  useEffect(() => {
    const myProductReviewModels = new MyProductReviewModels();

    setMyProductReviewFields(myProductReviewModels.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    dispatch(getLatestReview());
  }, []);

  return (
    myProductReviewFields && !isLoading ? (
      <div className='my-product-review'>
        <div className='my-product-review__container'>
          <div className='my-account-section__group'>
            <Text tag='h4' className='my-product-review__title' field={myProductReviewFields['Product Review Title Text']} />
            {reviewLatest?.ReviewId ? (
              <Link field={myProductReviewFields['View All Link']}>
                <Text tag='span' className='my-account-section__btn-link enable' field={myProductReviewFields['View All Text']} />
              </Link>
            ) : (
              <Text tag='span' className='my-account-section__btn-link disable' field={myProductReviewFields['View All Text']} />
            )}
          </div>
          {reviewLatest?.ReviewId ? (
            <div className='my-product-review__content'>
              <ProductReviewContent reviewItem={reviewLatest} isNavigateToProductDetail={true} layoutData={myProductReviewFields} />
            </div>
          ) : (
            <Text tag='p' className='my-product-review__content-no-review' field={myProductReviewFields['No Review Text']} />
          )}
        </div>
        <div className='my-product-review__button'>
          <Link className='btn btn-outline-CTA2' field={myProductReviewFields['Write A Review Link']}>
            <Text field={myProductReviewFields['Write A Review Text']} />
          </Link>
        </div>
      </div>
    ) : (
      <MyProductReviewShimmer myProductReviewFields={myProductReviewFields}/>
    )
  );
};

ProductReviewForPDP.propTypes = {
  fields: PropTypes.any
};

export default ProductReviewForPDP;
