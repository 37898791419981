import './CarouselWithIconBottom.scss';

import { any, object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import ZoomSwiperComponent from '@components/SlicingPage/About/Carousel/ZoomSwiperComponent';
import CarouselWidthIconFieldsModel from '@components/SlicingPage/BrandStory/CarouselWidthIcon/Model/CarouselWidthIconFieldsModel';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

export const CarouselDesktop = ({ dataSources, gallerySwiperParams, thumbnailSwiperParams, thumbnailSwiperRef, gallerySwiperRef }) => {
  return (
    <div className='carousel-width-icon-bottom__container'>
      <div className='carousel-width-icon-bottom__gallery'>
        <SwipeComponent param={gallerySwiperParams} setRef={gallerySwiperRef}>
          {dataSources.items.map((thumnailItem, thumnailIdx) =>
            thumnailItem.fields.Image.value.src ? (
              <div className='carousel-width-icon-bottom__gallery__item' key={thumnailIdx}>
                <Image media={thumnailItem.fields.Image} className='d-desktop' />
              </div>
            ) : (
              <Fragment key={thumnailIdx}></Fragment>
            )
          )}
        </SwipeComponent>
      </div>
      <div className='carousel-width-icon-bottom__thumnail'>
        <div className='carousel-width-icon-bottom__thumnail__container container'>
          <SwipeComponent param={thumbnailSwiperParams} setRef={thumbnailSwiperRef}>
            {dataSources.items.map((thumnailItem, thumnailIdx) =>
              thumnailItem.fields.Image.value.src ? (
                <div className='carousel-width-icon-bottom__thumnail__item' key={thumnailIdx}>
                  <div className='carousel-width-icon-bottom__thumnail__item__image-line'>
                    <div className='carousel-width-icon-bottom__thumnail__item__image'>
                      <Image media={thumnailItem.fields.Icon} />
                    </div>
                  </div>
                </div>
              ) : (
                <Fragment key={thumnailIdx}></Fragment>
              )
            )}
          </SwipeComponent>
        </div>
      </div>
    </div>
  );
};

export const CarouselMobile = ({ dataSources, gallerySwiperParams, thumbnailSwiperParams, thumbnailSwiperRef, gallerySwiperRef }) => {
  return (
    <div className='carousel-width-icon-bottom__container'>
      <div className='carousel-width-icon-bottom__gallery'>
        <SwipeComponent param={gallerySwiperParams} setRef={gallerySwiperRef}>
          {dataSources.items.map((thumnailItem, thumnailIdx) => (
            <div className='carousel-width-icon-bottom__gallery__item' key={thumnailIdx}>
              <Image media={thumnailItem.fields['Mobile Image']} className='d-mobi' />
            </div>
          ))}
        </SwipeComponent>
      </div>
      <div className='carousel-width-icon-bottom__thumnail'>
        <div className='carousel-width-icon-bottom__thumnail__container container'>
          <SwipeComponent param={thumbnailSwiperParams} setRef={thumbnailSwiperRef}>
            {dataSources.items.map((thumnailItem, thumnailIdx) => (
              <div className='carousel-width-icon-bottom__thumnail__item' key={thumnailIdx}>
                <div className='carousel-width-icon-bottom__thumnail__item__image-line'>
                  <div className='carousel-width-icon-bottom__thumnail__item__image'>
                    <Image media={thumnailItem.fields.Icon} />
                  </div>
                </div>
              </div>
            ))}
          </SwipeComponent>
        </div>
      </div>
    </div>
  );
};

const CarouselWithIconBottom = ({ fields }) => {
  const [dataSources, setDataSources] = useState(null);
  const thumbnailSwiperRef = useRef(null);
  const gallerySwiperRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(null);
  const [displayingComponent, setDisplayingComponent] = useState(null);
  const [toggleGallery, setToggleGallery] = useState(false);
  const [slideTo, setSlideTo] = useState(0);
  const [carouselDesktop, setCarouselDesktop] = useState(null);
  const [carouselMobile, setCarouselMobile] = useState(null);

  const thumbnailSwiperParams = {
    spaceBetween: 20,
    slidesPerView: 6,
    slideToClickedSlide: true,
    centerInsufficientSlides: true,
    touchRatio: 0.2,
    breakpoints: {
      768: {
        slidesPerView: 8
      },

      1200: {
        slidesPerView: 12
      },

      1400: {
        slidesPerView: 14
      }
    },
    on: {
      click: () => {
        if (thumbnailSwiperRef?.current?.swiper && gallerySwiperRef?.current?.swiper) {
          const thumbnailSwiper = thumbnailSwiperRef.current.swiper;
          const gallerySwiper = gallerySwiperRef.current.swiper;
          const allChildren = Array.from(thumbnailSwiper.el.children[0].children);

          if (thumbnailSwiper.clickedSlide) {
            allChildren.forEach((itemChildren) => itemChildren.classList.remove('slide-selected'));

            thumbnailSwiper.clickedSlide.classList.add('slide-selected');

            gallerySwiper.slideToLoop(thumbnailSwiper.clickedIndex, 200, false);
          }
        }
      },
      imagesReady: () => {
        const allChildren = Array.from(thumbnailSwiperRef.current.swiper.el.children[0].children);

        allChildren[0].classList.add('slide-selected');
      }
    }
  };

  const gallerySwiperParams = {
    loop: true,
    centeredSlides: true,
    spaceBetween: 8,
    slidesPerView: 1.24,
    on: {
      slideChangeTransitionEnd: () => {
        if (gallerySwiperRef?.current?.swiper && thumbnailSwiperRef?.current?.swiper) {
          const thumbnailSwiper = thumbnailSwiperRef.current.swiper;
          const gallerySwiper = gallerySwiperRef.current.swiper;
          const slideTo = gallerySwiper.realIndex;
          const allChildren = Array.from(thumbnailSwiper.el.children[0].children);

          allChildren.forEach((itemChildren) => itemChildren.classList.remove('slide-selected'));

          allChildren[slideTo].classList.add('slide-selected');

          thumbnailSwiper.slideTo(slideTo, 200, false);
        }
      }
    },
    breakpoints: {
      768: {
        slidesPerView: 1.24,
        spaceBetween: 30
      },

      1200: {
        // slidesPerView: 2.13,
        slidesPerView: 1.5,
        spaceBetween: 40
      }
    }
  };

  useEffect(() => {
    setDataSources(new CarouselWidthIconFieldsModel().getData(fields || {}));
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useDidUpdateEffect(() => {
    if (windowWidth && windowWidth < 768) {
      setDisplayingComponent(false);
    }
    if (windowWidth && windowWidth >= 768) {
      setDisplayingComponent(
        dataSources.items.every((item) => {
          return item.fields?.Image?.value?.src === '';
        })
      );
    }
  }, [windowWidth]);

  useDidUpdateEffect(() => {
    let mobileArr = [];
    let desktopArr = [];

    dataSources.items.forEach((item) => {
      if (item?.fields?.['Mobile Image']?.value?.src?.length) {
        mobileArr.push(item);
      }
      if (item?.fields?.Image?.value?.src?.length) {
        desktopArr.push(item);
      }
    });

    setCarouselMobile(mobileArr);

    setCarouselDesktop(desktopArr);
  }, [dataSources]);

  useEffect(() => {
    let childNodes = gallerySwiperRef?.current?.firstChild?.childNodes;

    childNodes &&
      childNodes.forEach((ele) => {
        ele.addEventListener('click', () => {
          setToggleGallery(true);

          setSlideTo(ele.getAttribute('data-swiper-slide-index'));
        });
      });
  }, [carouselDesktop, carouselMobile, windowWidth]);

  const onCloseZoomImagesHandler = (slideIndex) => {
    setToggleGallery(false);

    setSlideTo(slideIndex);

    gallerySwiperRef.current.swiper.slideToLoop(slideIndex);
  };

  const layoutContentRender = () => {
    let ComponentType = windowWidth < 768 ? CarouselMobile : CarouselDesktop;

    return (
      <ComponentType
        dataSources={dataSources}
        gallerySwiperParams={gallerySwiperParams}
        thumbnailSwiperParams={thumbnailSwiperParams}
        thumbnailSwiperRef={thumbnailSwiperRef}
        gallerySwiperRef={gallerySwiperRef}
      />
    );
  };

  return dataSources ? (
    displayingComponent ? (
      <></>
    ) : (
      <div className='carousel-width-icon-bottom'>
        <div className='carousel-width-icon-bottom__heading'>
          <div className='container text-center'>
            <Text field={dataSources.Title} tag='h2' className='carousel-width-icon-bottom__heading__title experience-title' />
            <RichText field={dataSources.Description} tag='p' className='carousel-width-icon-bottom__heading__desc' />
          </div>
        </div>
        {layoutContentRender()}
        {toggleGallery ? (
          <ZoomSwiperComponent
            setCloseZoomImages={onCloseZoomImagesHandler}
            toggleGallery={toggleGallery}
            items={windowWidth < 768 ? carouselMobile : carouselDesktop}
            activeSlideKey={parseInt(slideTo)}
            windowWidth={windowWidth}
          />
        ) : (
          <></>
        )}
      </div>
    )
  ) : (
    <></>
  );
};

CarouselDesktop.propTypes = {
  dataSources: object,
  gallerySwiperParams: any,
  thumbnailSwiperParams: any,
  thumbnailSwiperRef: any,
  gallerySwiperRef: any
};

CarouselMobile.propTypes = {
  dataSources: object,
  gallerySwiperParams: any,
  thumbnailSwiperParams: any,
  thumbnailSwiperRef: any,
  gallerySwiperRef: any
};

CarouselWithIconBottom.propTypes = {
  fields: object
};

export default CarouselWithIconBottom;
