import React from 'react';

import ProductListing from '@components/CategoryLanding/ProductListing';

const CategoryLanding = () => {
  return (
    <div className='category-landing'>
      <ProductListing />
    </div>
  );
};

export default CategoryLanding;
