import PropTypes from 'prop-types';
import React from 'react';
import { QuestionItemStep2 } from './QuestionItemStep2/QuestionItemStep2'

export const QuestionsStep2 = ({ chooseAnswer, step, chooseQuestions, Answers }) => {

  const isActiveAnswer = (id) => {
    if (chooseQuestions?.step2?.activeAnswers?.includes(id)) {
      return true
    }
    else {
      return false
    }
  }
  const checkFullBody = () => {
    if (chooseQuestions?.step2?.activeAnswers?.includes('full-body')) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <div className={`question2-wrapper ${checkFullBody() ? 'question2-wrapper--fullbody' : ''}`}>
      <div className='question2-wrapper__image'>
        <img className='question2-wrapper__image__item' src={require('@assets/images/people.png')} alt='body image' />
        <img className='question2-wrapper__image__item question2-wrapper__image__item--full-body' src={require('@assets/images/people-full.png')} alt='full body image ' />
      </div>
      {Answers?.length > 0 && Answers.map((answer, index) => {
        return (
          <QuestionItemStep2 key={index} answer={answer} step={step} chooseAnswer={chooseAnswer} active={isActiveAnswer(answer.ID)} />
        )
      })}
    </div>
  );
};

QuestionsStep2.propTypes = {
  chooseAnswer: PropTypes.func,
  step: PropTypes.number,
  chooseQuestions: PropTypes.object,
  Answers: PropTypes.any,
};


