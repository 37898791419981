import SitecoreTextModel from '@models/SitecoreTextModel';

const STR_ARR = [
  'Title',
  'Description',
  'Shop By Categories Label',
  'Title In Store Label'
];

class ExploreOSIMProductsModel {
  constructor() { 
    STR_ARR.forEach((str) => {
      this[str] = new SitecoreTextModel();
    });

    this['Categories'] = [];
  }

  getData(dataSource) {
    STR_ARR.forEach((str) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, str)) {
        this[str] = dataSource[str];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Categories')) {
      this['Categories'] = dataSource['Categories'];
    }

    return this;
  }
}

export default ExploreOSIMProductsModel;