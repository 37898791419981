import { any } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const CustomerVoucherShimmer = ({ title }) => {
  return (
    <div className='box-data skeleton__item'>
      <div className='box-data__header'>
        <Text field={title} tag='h3' className='box-data__title' />
      </div>
      <div>
        {
          [...new Array(2)].map((val, index) => (
            <div key={index} className='voucher'>
              <span className='data-text text-line-25' />
              <h3 className='voucher__name data-text text-line-25'/>
              <p className='voucher__description data-text text-line-25'/>
            </div>
          ))
        }
      </div>
    </div>
  );
};

CustomerVoucherShimmer.propTypes = {
  title: any
};

export default CustomerVoucherShimmer;