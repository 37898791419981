import './ConfirmRemoveCart.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addProductWishlist } from '@redux/actions/product';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
const EVENT_REMOVE_ALL = 'REMOVE_ALL_FROM_CART';

const ConfirmRemoveCart = ({dataCartLineItem, confirmRemoveCartEvt, isWishListProduct, dataSources, addOrRemoveCartEventGA4}) => {
  const dispatch = useDispatch();
  const isLoginSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer.isLogin);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  const onAddWishListHandler = () => {
    if (isLoginSuccess) {
      dispatch(
        addProductWishlist({
          productId: dataCartLineItem.ProductId,
          variantId: dataCartLineItem.VariantId,
          quantity: dataCartLineItem.Quantity,
        })
      );

      confirmRemoveCartEvt();

      addOrRemoveCartEventGA4(EVENT_REMOVE_ALL);
    } else {
      window.location.href = `${settingGlobal?.LoginLink || '/login'}?from=${window.location.href}`;
    }
  };

  return (
    <div className='confirm-remove-cart'>
      {/* <p className='confirm-remove-cart__text'>
        <span>You are about to remove a product.</span> 
        <span>This will delete your product from cart </span>
        <span>Are you sure?</span>
      </p> */}
      <RichText className='confirm-remove-cart__text' field={dataSources['Remove Product Description']}/>
      {!isWishListProduct ? <button className='btn-add-wishlist btn-move-wishlist btn btn-outline-primary' onClick={onAddWishListHandler}>
        <Text field={dataSources['Move To Wishlist Label']}/>
      </button> : ''}
      <button 
        className='btn-remove-cart' 
        onClick={() => {
          confirmRemoveCartEvt();

          addOrRemoveCartEventGA4(EVENT_REMOVE_ALL);
        }}
      >
        <Text field={dataSources['Remove Product Label']}/>
      </button>
    </div>
  );
};

ConfirmRemoveCart.propTypes = {
  confirmRemoveCartEvt: PropTypes.func,
  dataCartLineItem: PropTypes.object,
  isWishListProduct: PropTypes.bool,
  dataSources: PropTypes.object,
  addOrRemoveCartEventGA4 : PropTypes.func,
};

export default ConfirmRemoveCart;
