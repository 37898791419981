import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TextArray = ['Review Level Date', 'Spend Level Member Label'];

export default class ShowInformationTiersModel {
  constructor() {
    TextArray.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });

    this['ListImageTiers'] = [];

    this['ListTitleTiers'] = [];
  }

  getData(dataSource) {
    TextArray.forEach((text) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text] = dataSource[text] ?? '';
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListImageTiers')) {
      this['ListImageTiers'] = deepCopy(dataSource['ListImageTiers']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListTitleTiers')) {
      this['ListTitleTiers'] = deepCopy(dataSource['ListTitleTiers']);
    }

    return this;
  }
}
