import { READ_ALL_NOTIFICATION_FAILURE, READ_ALL_NOTIFICATION_START, READ_ALL_NOTIFICATION_SUCCESS } from './types';

export const readAllNotificationStart = () => ({
  type: READ_ALL_NOTIFICATION_START
});

export const readAllNotificationSuccess = (payload) => ({
  type: READ_ALL_NOTIFICATION_SUCCESS,
  payload
});

export const readAllNotificationFailure = (payload) => ({
  type: READ_ALL_NOTIFICATION_FAILURE,
  payload
});
