import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError,map, switchMap } from 'rxjs/operators';

import UserInfoModel from '@components/SingleSignOn/models/UserInfoModel';
import { EVENT_METHOD } from '@components/SingleSignOn/RegisterLogin/utils';
import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';
import { generateRegisterLoginError } from '../utils';

export const googleEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.LOGIN_GOOGLE),
    switchMap((action) => {
      return postAsObservable(config.ACTION_LOGIN_SOCIAL, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            const userInfoModel = new UserInfoModel().getData(res.data?.Data || {});
            const enabledLink = res.data?.Enabled?.map(name => name.toLowerCase());

            return actions.loginSuccess({
              ...userInfoModel,
              Enabled: enabledLink || [],
              isLinkFacebook: enabledLink?.includes(config.TYPE_FACEBOOK),
              isLinkGoogle: enabledLink?.includes(config.TYPE_GOOGLE),
              FacebookUsername: res.data?.FacebookUsername || '',
              GoogleUsername: res.data?.FacebookUsername || ''
            });
          } else {
            return actions.loginFailed(generateRegisterLoginError(res.data?.Errors?.[0] || 'Failed', EVENT_METHOD.google));
          }
        }),
        catchError((error) => of(actions.loginFailed(error.message || 'Failed')))
      );
    })
  );

export const toogleLinkGoogleEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.TOOGLE_LINK_GOOGLE),
    switchMap((action) => {
      return postAsObservable(action.payload?.AccessToken ? config.ACTION_LOGIN_SOCIAL_ENABLE : config.ACTION_LOGIN_SOCIAL_DISABLE , action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            const userInfoModel = new UserInfoModel().getData(res.data?.Data || {});
            const enabledLink = res.data?.Enabled?.map(name => name.toLowerCase());

            return actions.loginSuccess({
              ...userInfoModel,
              Enabled: enabledLink || [],
              isLinkFacebook: enabledLink?.includes(config.TYPE_FACEBOOK),
              isLinkGoogle: enabledLink?.includes(config.TYPE_GOOGLE),
              FacebookUsername: res.data?.FacebookUsername || '',
              GoogleUsername: res.data?.GoogleUsername || '',
            });
          } else {
            return actions.loginFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.loginFailed(error.message || 'Failed')))
      );
    })
  );
