import PropTypes from 'prop-types';
import React from 'react';
import { Accordion } from 'react-bootstrap';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const TwoColumnImageWithAccodionItem = ({ data, activeKey, onToogleAccordion, subTitleFontSize, descriptionFontSize, subTitleColor, descriptionColor }) => {
  return (
    <>
      {data.fields.Title.value.length || data.fields.Description.value.length ? (
        <div className='accordion__info-list__item'>
          <div className='accordion__collapse'>
            <div className='accordion__collapse__title' onClick={() => onToogleAccordion(data.id.toLowerCase())}>
              <Text className='accordion__collapse__title__text' tag='span' field={data.fields['Title']} style={{ fontSize: subTitleFontSize+'px' || '', color: '#'+subTitleColor || ''}} />
              {activeKey !== data.id.toLowerCase() ? (
                <i className='accordion__collapse__title__icon icon--nomal icon-add'></i>
              ) : (
                <i className='accordion__collapse__title__icon icon--collapsed icon-remove'></i>
              )}
            </div>
            <div>
              <div className='accordion__wrap'>
                <Accordion.Collapse eventKey={data.id.toLowerCase()}>
                  <RichText tag='p' field={data.fields['Description']} style={{ fontSize: descriptionFontSize+'px' || '', color: '#'+descriptionColor || ''}} />
                </Accordion.Collapse>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

TwoColumnImageWithAccodionItem.propTypes = {
  accordion: PropTypes.string,
  data: PropTypes.object,
  activeKey: PropTypes.any,
  onToogleAccordion: PropTypes.any,
  subTitleFontSize: PropTypes.any,
  descriptionFontSize: PropTypes.any,
  subTitleColor: PropTypes.any,
  descriptionColor: PropTypes.any
};

export default TwoColumnImageWithAccodionItem;
