import './SendOrderToCustomerWarningModal.scss';

import { any, bool, func, object } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { updateCPCustomerContact } from '@redux/actions/clientTellingPortalCustomer';
import { cPStartDemo } from '@redux/actions/cPStartDemo';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import { useGenerateCPContactData } from '../hooks';

const SendOrderToCustomerWarningModal = ({ layoutData, dataUpdateContact, isWarningPopup, setWarningPopup, contactDuplicate, fieldPhoneCode }) => {
  const dispatch = useDispatch();
  const generateCPContactData = useGenerateCPContactData();
  const isDuplicatedEmail = useMemo(() => contactDuplicate?.isDuplicatedEmail, [contactDuplicate]);
  const isDuplicatedPhone = useMemo(() => contactDuplicate?.isDuplicatedPhone, [contactDuplicate]);
  const smsArray = useMemo(() => (contactDuplicate ? contactDuplicate.SMSArray : []), [contactDuplicate]);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  const areaCode = useMemo(() => {
    let areaCodesList = [];
    const areaCodes = settingGlobal?.AreaCodes || [];
    if (settingGlobal?.UseAreaCode && areaCodes.length) {
      for (const item of areaCodes) {
        if (Object.keys(item).every((key) => item[key]))
          areaCodesList.push({
            value: item.value,
            label: item.label
          });
      }
    }

    return areaCodesList;
  }, [settingGlobal]);

  const handleStartDemo = () => {
    dispatch(
      cPStartDemo({
        ContactId: dataUpdateContact?.[0]?.contactId,
        currentSessionContactId: dataUpdateContact?.[0]?.contactId,
        sessionId: SitecoreContextFactoryService.getValueContextItem('cpSessionId'),
        needRunSaveSOCartApi: true
      })
    );

    setWarningPopup(false);
  };

  const secondaryBtnLabel = useMemo(
    () => (isDuplicatedEmail ? layoutData?.['Create New Profile Duplicate Email Button'] : layoutData?.['Create New Profile Duplicate Phone Button']),
    [isDuplicatedPhone, isDuplicatedEmail, dataUpdateContact, layoutData]
  );

  const handleCreateNewProfile = useCallback(() => {
    if (dataUpdateContact.length) {
      if (isDuplicatedPhone) {
        const phoneNumber = smsArray.length ? smsArray[0]?.value : '';
        let formData = generateCPContactData(phoneNumber);
        const currentAreaCode = fieldPhoneCode?.current || '';
        if (areaCode.length && currentAreaCode) {
          const splitPhoneNumber = Global.splitPhoneNumberWithoutCode(true, phoneNumber, areaCode);

          formData = generateCPContactData(splitPhoneNumber.value || phoneNumber, '', currentAreaCode);
        }

        dispatch(updateCPCustomerContact(formData));
      }
    }

    setWarningPopup(false);
  }, [dataUpdateContact, isDuplicatedPhone, generateCPContactData, smsArray, areaCode, fieldPhoneCode]);

  const contactFullName = useMemo(() => {
    let fullName = dataUpdateContact?.[0]?.json_facet?.Personal?.FirstName || '';
    const lastName = dataUpdateContact?.[0]?.json_facet?.Personal?.LastName || '';
    if (lastName) fullName = `${fullName} ${lastName}`;

    return fullName?.trim();
  }, [dataUpdateContact]);

  return (
    <ModalComponent
      cssClass='warning-popup'
      isShow={isWarningPopup}
      onCloseHandler={() => {
        setWarningPopup(false);
      }}
    >
      <div className='warning-popup__content'>
        <Text className='warning-popup__content__title' field={layoutData['Warning Title']} tag='h4' />
        <Text className='warning-popup__content__desc' tag='p' field={layoutData['Warning Description']} />
        <div className='warning-popup__content__info'>
          <span className='warning-popup__content__info__line'>
            {contactFullName}
          </span>
          <span className='warning-popup__content__info__line'>{dataUpdateContact?.[0]?.json_facet?.Email?.PreferredEmail?.SmtpAddress || ''}</span>
          <span className='warning-popup__content__info__line'>
            {dataUpdateContact?.[0]?.json_facet?.PhoneNumbers?.PreferredPhoneNumber?.Number || ''}
          </span>
        </div>
        <div className='warning-popup__content__btn'>
          <span className='warning-popup__content__btn__item btn btn-primary' onClick={handleStartDemo}>
            <Text field={layoutData['Continue With Existing Button']} />
          </span>
          {contactDuplicate ? (
            <span className='warning-popup__content__btn__item os-btn-link' onClick={handleCreateNewProfile}>
              <Text field={secondaryBtnLabel} />
            </span>
          ) : (
            <></>
          )}
        </div>
      </div>
    </ModalComponent>
  );
};

SendOrderToCustomerWarningModal.propTypes = {
  layoutData: object,
  dataUpdateContact: object,
  setWarningPopup: func,
  isWarningPopup: bool,
  contactDuplicate: object,
  fieldPhoneCode: any
};

export default SendOrderToCustomerWarningModal;
