import { bool, object } from 'prop-types';
import React, { useMemo } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const SaveSOSuccessFooter = ({ layoutData, isOrderSent }) => {
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId') || '';

  const linkBtn = useMemo(() => {
    let btnValue = {
      text: layoutData?.['Back to sale orders'],
      href: layoutData?.['Sale orders listing page']
    };
    if (cpCustomerId)
      btnValue = {
        ...btnValue,
        text: layoutData?.['End session'],
        href: layoutData?.['End session link']
      };

    return btnValue;
  }, [layoutData, cpCustomerId]);

  const linkSentOrder = useMemo(() => {
    return `${layoutData?.['Send order to customer link']?.value?.href}?cartName=${layoutData?.SaleOrder?.Cart?.Name}`;
  }, [layoutData]);

  return (
    <div className='save-so-success__footer'>
      {isOrderSent ? (
        <Link field={linkBtn.href} className='btn btn-primary footer-btn'>
          <Text field={linkBtn.text} />
        </Link>
      ) : (
        <>
          <Link field={{ href: linkSentOrder }} className='btn btn-primary footer-btn'>
            <Text field={layoutData?.['Send order to customer']} />
          </Link>
          <Link field={linkBtn.href} className='link-nomal'>
            <Text field={linkBtn.text} />
          </Link>
        </>
      )}
    </div>
  );
};

SaveSOSuccessFooter.propTypes = {
  layoutData: object,
  isOrderSent: bool
};

export default SaveSOSuccessFooter;
