import * as searchBoxTypes from '@redux/actions/searchBox/searchBoxTypes';

export const getSearchBoxSuggestionList = (searchTerm) => ({
  type: searchBoxTypes.GET_SEARCH_BOX_SUGGESTION_LIST,
  searchTerm
});

export const getSearchBoxSuggestionListSuccess = (payload) => ({
  type: searchBoxTypes.GET_SEARCH_BOX_SUGGESTION_LIST_SUCCESS,
  payload
});

export const getSearchBoxSuggestionListFailed = (error) => ({
  type: searchBoxTypes.GET_SEARCH_BOX_SUGGESTION_LIST_FAILED,
  error
});
