import React from 'react';
import { Fragment } from 'react';

const WellnessListingItemShimmer = () => {

  return (
    <Fragment>
      <div className='featured-article-item'>
        <div className='featured-article-item__wrap'>
          <div className='featured-article-item__image image-wrap'>
            <span className='image-line'></span>
          </div>
        </div>
        <ul className='featured-article-item__tags'>
          <span className='featured-article-item__tags__item w-100'>
            <span className='transparent-wrap'>Text</span>
          </span>
        </ul>

        <h3 className='featured-article-item__title'>
          <span className='text-line'></span>
        </h3>

        <div className='featured-article-item__sub-info'>
          <span className='text-line-small'></span>
        </div>
      </div>
    </Fragment>
  );
};

export default WellnessListingItemShimmer;