import { SUBSCRIBE_MAIL_FAILED, SUBSCRIBE_MAIL_START, SUBSCRIBE_MAIL_SUCCESS } from './subscribeNewsletterTypes';

export const subscribeMailStart = (payload) => ({
  type: SUBSCRIBE_MAIL_START,
  payload
});

export const subscribeMailSuccess = (payload) => ({
  type: SUBSCRIBE_MAIL_SUCCESS,
  payload
});

export const subscribeMailFailed = (payload) => ({
  type: SUBSCRIBE_MAIL_FAILED,
  payload
});
