export default class MyOrdersLastestApi {
  constructor() {
    this['FirstProductInOrder'] = '';

    this['RemainingProductsInOrder'] = '';

    this['TotalOrderPrice'] = '';

    this['StatusDisplayName'] = '';

    this['StatusId'] = '';

    this['TrackingNumber'] = '';

    this['OrderDate'] = '';

    this['OrderId'] = '';

    this['TotalOrderPriceWithCurrency'] = '';

    this['PosOriginOrderId'] = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FirstProductInOrder')) {
      this['FirstProductInOrder'] = dataSource['FirstProductInOrder'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalOrderPriceWithCurrency')) {
      this['TotalOrderPriceWithCurrency'] = dataSource['TotalOrderPriceWithCurrency'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RemainingProductsInOrder')) {
      this['RemainingProductsInOrder'] = dataSource['RemainingProductsInOrder'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalOrderPrice')) {
      this['TotalOrderPrice'] = dataSource['TotalOrderPrice'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StatusDisplayName')) {
      this['StatusDisplayName'] = dataSource['StatusDisplayName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StatusId')) {
      this['StatusId'] = dataSource['StatusId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TrackingNumber')) {
      this['TrackingNumber'] = dataSource['TrackingNumber'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OrderDate')) {
      let orderDate = new Date(dataSource['OrderDate']);

      //INFO: en-SG is Singapore locale using for convert date to dd/mm/yyyy
      this['OrderDate'] = `${orderDate.toLocaleDateString('en-SG')} ${orderDate.toLocaleTimeString()}`;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OrderId')) {
      this['OrderId'] = dataSource['OrderId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PosOriginOrderId')) {
      this['PosOriginOrderId'] = dataSource['PosOriginOrderId'];
    }

    return this;
  }
}
