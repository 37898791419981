import produce from 'immer';

import VariantDetailApiModel from '@components/Product/VariantDetail/ProductDetailOverview/models/VariantDetailApiModel';
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  error: '',
  productVariantCurrent: new VariantDetailApiModel().getData({}),
  isLoading: false,
  categoryName: '',
  IsDeposit: '',
  Deposit: '',
  Price: ''

};

const productDetailHeaderReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.GET_PRODUCT_DETAIL_HEADER:
      draft.isLoading = true;

      break;

    case productTypes.GET_PRODUCT_DETAIL_HEADER_SUCCESS:

      draft.productVariantCurrent = new VariantDetailApiModel().getData(deepCopy(action.payload?.VariantDetail[0]));

      draft.categoryName =  action.payload?.CategoryName;

      draft.isLoading = false;

      // INFO: new update from ticket https://jira.smartosc.com/browse/OSIM-6543 => to check deposit product using
      // Deposit !== null
      // draft.IsDeposit = action.payload?.IsDeposit;
      draft.IsDeposit = action.payload?.Deposit !== null;

      draft.Deposit = action.payload?.Deposit;

      draft.Price = action.payload?.Price;

      break;

    case productTypes.GET_PRODUCT_DETAIL_HEADER_FAILED:
      draft.error = '';

      draft.isLoading = false;
      break;

    case productTypes.SET_VARIANT_PRODUCT_DETAIL_HEADER:
      draft.productVariantCurrent = action.payload;
      break;
    }

    return draft;
  });

export default productDetailHeaderReducer;
