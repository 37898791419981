import './AppointmentDetail.scss';

import { bool, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { getCpAppointmentDetail } from '@redux/actions/cpAppointmentDetail';
import { readSingleNotificationStart } from '@redux/actions/cpNotificationPopup/readSingleNotification';

import CPPageHeader from '../../CPPageHeader';
import {
  NOTIFICATION_ID_QUERY_STRING
} from '../../GenericComponents/CPHeader/CPHeaderIconList/NotificationsContent/NotificationItem';
import ActivityLog from '../ActivityLog';
import AppointmentsInfor from '../AppointmentsInfor';
import EditAppointmentForm from '../EditAppointmentForm';
import AppointmentDetailContext from './AppointmentDetailContext';
import AppointmentDetailModel from './model/AppointmentDetailModel';

const AppointmentDetail = (props) => {
  const { fields, isUpdateSuccess, appointmentResult, isLoading, isFetchSuccess } = props;
  const [isEditAppointment, setIsEditAppointment] = useState(false);
  const [layoutData, setLayoutData] = useState(null);
  const [appointmentDetail, setAppointmentDetail] = useState(null);
  const [statusCode, setStatusCode] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const model = new AppointmentDetailModel();

    setLayoutData(model.getData(fields));
  }, []);

  // Handle Fetch Appointment Detail on load page
  useEffect(() => {
    const urlSearch = window.location.search;
    const urlSearchParams = new URLSearchParams(urlSearch);
    const appointmentId = urlSearchParams.get('appointment_id');
    
    dispatch(
      getCpAppointmentDetail({
        appointmentId: appointmentId
      })
    );
  }, []);

  useEffect(() => {
    setAppointmentDetail(appointmentResult);
  }, [appointmentResult]);

  useEffect(() => {
    if (isUpdateSuccess) {
      setIsEditAppointment(false);
    }
  }, [isUpdateSuccess]);

  useEffect(() => {
    const search = window.location.search;
    const urlSearchParams = new URLSearchParams(search);
    const notificationId = urlSearchParams.get(NOTIFICATION_ID_QUERY_STRING);
    if (notificationId) dispatch(readSingleNotificationStart(notificationId));
  }, []);
  
  const handleEditDetail = () => setIsEditAppointment(!isEditAppointment);

  const handleCloseEditForm = () => {
    setIsEditAppointment(!isEditAppointment);

    setStatusCode(0);
  };

  return layoutData ? (
    <AppointmentDetailContext.Provider value={{ layoutData, appointmentDetail, statusCode, setStatusCode }}>
      <CPPageHeader
        title={layoutData?.['Title']}
        type={!isEditAppointment ? 'button' : 'close-icon'}
        onEdit={handleEditDetail}
        onClose={handleCloseEditForm}
        isShowBackBtn={true}
        layoutData={layoutData}
      />
      {isLoading && <LoadingMask />}
      {isFetchSuccess && (
        <div className='appointment-detail'>
          <div className='container appointment-detail__container'>
            {!isEditAppointment ? (
              <div className='appointment-detail__wrap'>
                <AppointmentsInfor />
                <ActivityLog />
              </div>
            ) : (
              <EditAppointmentForm onCloseHandler={handleCloseEditForm} />
            )}
          </div>
        </div>
      )}
    </AppointmentDetailContext.Provider>
  ) : (
    <></>
  );
};

AppointmentDetail.propTypes = {
  fields: object,
  isUpdateSuccess: bool,
  appointmentResult: object,
  isLoading: bool,
  isFetchSuccess: bool
};

const mapStateToProps = (state) => ({
  isUpdateSuccess: state.cpUpdateAppointmentReducer.isUpdateSuccess,
  appointmentResult: state.cpAppointmentDetailReducer.appointment,
  isLoading: state.cpAppointmentDetailReducer.isLoading,
  isFetchSuccess: state.cpAppointmentDetailReducer.isFetchSuccess
});

export default connect(mapStateToProps)(AppointmentDetail);
