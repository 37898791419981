import './ProductCountdownTimer.scss';

import { any, bool, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { useWindowSize } from '@utils/customsHook/useWindowSize';
import global from '@utils/global';

const ProductCountdownTimer = ({ fontSize, colorCode, text, startTime, endTime, countdownBgImage, className = '' }) => {
  const WHITE_COLOR_HEX = ['fff', 'ffffff'];
  const start = Date.parse(new Date(startTime));
  const end = Date.parse(new Date(endTime));
  const [days, setDays] = useState('');
  const [hours, setHours] = useState('');
  const [minutes, setMinutes] = useState('');
  const [seconds, setSeconds] = useState('');
  const currentWidth = useWindowSize();
  const [isShowCountdownTimer, setIsShowCountdownTimer] = useState(false);

  const checkWhiteColorHandler = (hexCode) => {
    return !WHITE_COLOR_HEX.includes(hexCode?.toLowerCase());
  };

  const dateUnitStyle = useMemo(() => {
    return {
      fontSize: currentWidth <= 768 ? `${fontSize?.dateMobileUnit?.replace('px', '')}px` : `${fontSize?.dateUnit?.replace('px', '')}px`,
      color:
        colorCode?.dateUnitText && checkWhiteColorHandler(colorCode?.dateUnitText?.replace('#', ''))
          ? `#${colorCode?.dateUnitText?.replace('#', '')}`
          : '' // INFO: ignore case user enter color code with #
    };
  }, [fontSize, currentWidth]);

  const timerStyle = useMemo(() => {
    return {
      fontSize: currentWidth <= 768 ? `${fontSize?.timerMobileText?.replace('px', '')}px` : `${fontSize?.timerText?.replace('px', '')}px`,
      color:
        colorCode?.timerText && checkWhiteColorHandler(colorCode?.timerText?.replace('#', '')) ? `#${colorCode?.timerText?.replace('#', '')}` : ''
    };
  }, [fontSize, currentWidth]);

  const titleStyle = useMemo(() => {
    return {
      fontSize: currentWidth <= 768 ? `${fontSize?.titleMobileText?.replace('px', '')}px` : `${fontSize?.titleText?.replace('px', '')}px`,
      color:
        colorCode?.titleText && checkWhiteColorHandler(colorCode?.titleText?.replace('#', '')) ? `#${colorCode?.titleText?.replace('#', '')}` : ''
    };
  }, [fontSize, currentWidth]);

  useEffect(() => {
    const onSetTimer = setInterval(() => {
      let now = Date.parse(new Date(Date.now()));
      let distance;
      if (now >= start && end >= now) {
        distance = end - now;
        const timeCount = countTimerHandler(distance);

        setDays(global.addZero(timeCount?.days));

        setHours(global.addZero(timeCount?.hours));

        setMinutes(global.addZero(timeCount?.minutes));

        setSeconds(global.addZero(timeCount?.seconds));

        setIsShowCountdownTimer(true);
      }
      if (end <= now) {
        setDays(global.addZero(0));

        setHours(global.addZero(0));

        setMinutes(global.addZero(0));

        setSeconds(global.addZero(0));

        setIsShowCountdownTimer(false);
      }
    }, 1000);

    return () => clearInterval(onSetTimer);
  }, []);

  const countTimerHandler = (distance) => {
    let days = Math.floor(distance / 86400000);
    let hours = Math.floor((distance - days * 86400000) / 3600000);
    let minutes = Math.floor((distance - days * 86400000 - hours * 3600000) / 60000);
    let seconds = Math.floor(distance - days * 86400000 - hours * 3600000 - minutes * 60000) / 1000;

    return { days, hours, minutes, seconds };
  };

  return isShowCountdownTimer ? (
    <div className={`countdown-timer ${className}`}>
      {/*<div className='countdown-timer__wrapper'>*/}
      <div className='countdown-timer__container' style={{ borderColor: titleStyle.color }}>
        {countdownBgImage ? (
          <div className='countdown-timer__bg'>
            <img src={countdownBgImage} className='countdown-timer__bg-item' alt='' />
          </div>
        ) : (
          <></>
        )}
        <p className='countdown-timer__title' style={titleStyle}>
          {text?.title || 'Counting down to launch!'}
        </p>
        <div className='countdown-timer__detail'>
          <div className='countdown-timer__detail-item' style={{ '--colon-color': titleStyle.color }}>
            <div className='timer-box' style={{ borderColor: titleStyle.color }}>
              <p style={timerStyle}>{days}</p>
            </div>
            <p className='countdown-timer__detail-label' style={dateUnitStyle}>
              {text?.days || 'Days'}
            </p>
          </div>
          <div className='countdown-timer__detail-item' style={{ '--colon-color': titleStyle.color }}>
            <div className='timer-box' style={{ borderColor: titleStyle.color }}>
              <p style={timerStyle}>{hours}</p>
            </div>
            <p className='countdown-timer__detail-label' style={dateUnitStyle}>
              {text?.hours || 'Hrs'}
            </p>
          </div>
          <div className='countdown-timer__detail-item' style={{ '--colon-color': titleStyle.color }}>
            <div className='timer-box' style={{ borderColor: titleStyle.color }}>
              <p style={timerStyle}>{minutes}</p>
            </div>
            <p className='countdown-timer__detail-label' style={dateUnitStyle}>
              {text?.minutes || 'Min'}
            </p>
          </div>
          <div className='countdown-timer__detail-item'>
            <div className='timer-box' style={{ borderColor: titleStyle.color }}>
              <p style={timerStyle}>{seconds}</p>
            </div>
            <p className='countdown-timer__detail-label' style={dateUnitStyle}>
              {text?.seconds || 'Sec'}
            </p>
          </div>
        </div>
      </div>
      {/*</div>*/}
    </div>
  ) : (
    <></>
  );
};

ProductCountdownTimer.propTypes = {
  className: string,
  fontSize: shape({
    dateMobileUnit: string,
    dateUnit: string,
    timerMobileText: string,
    timerText: string,
    titleMobileText: string,
    titleText: string
  }),
  colorCode: shape({
    dateUnitText: string,
    timerText: string,
    titleText: string
  }),
  text: shape({
    title: string,
    days: string,
    hours: string,
    minutes: string,
    second: string
  }),
  message: shape({
    startSoon: string,
    alternativeStartSoon: string,
    endSoon: string,
    valid: string,
    wasEnd: string
  }),
  position: shape({
    countdownDesktop: string,
    countdownMobile: string
  }),
  enableTimer: bool,
  startTime: any,
  endTime: any,
  countdownBgImage: string
};

export default ProductCountdownTimer;
