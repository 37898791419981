import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/myOrders';
import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getAllOrderEpic = (action$) =>
  action$.pipe(
    ofType(myOrderTypes.GET_ALL_MY_ORDER),
    switchMap((action) => {
      return getAsObservable(config.ACTION_GET_ALL_MY_ORDER).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.getMyOrdersSuccess(deepCopy(res.data?.Orders));
          } else {
            return actions.getMyOrdersFailed('Failed');
          }
        })
      );
    })
  );