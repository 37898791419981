import { useEffect, useState } from 'react';

import { IS_ADDED_TO_CART_SESSION_STORAGE_NAME } from '../configs';

export const useGetIsAddedToCartValue = () => {
  const [isAddedToCartValue, setIsAddedToCartValue] = useState(false);

  useEffect(() => {
    const isAddedToCartSessionValue = window.sessionStorage.getItem(IS_ADDED_TO_CART_SESSION_STORAGE_NAME);
    const isAddedToCartValue = isAddedToCartSessionValue ? JSON.parse(isAddedToCartSessionValue) : false;

    setIsAddedToCartValue(isAddedToCartValue);
  }, []);

  return isAddedToCartValue;
};
