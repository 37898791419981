import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import {clearAddressDatasources} from '@redux/actions/myAddress';
import { resetInputValue } from '@redux/actions/storeLocator';

import StoreLocatorFilterPopupContent from '../StoreLocatorFilterPopupContent';

const StoreLocatorFilterPopup = (props) => {
  const { dataSources, setCloseEditAddressEvt } = props;
  const dispatch = useDispatch();

  const onSubmmitAddAddressHandler = (params) => { 
    if (params) {
      setCloseEditAddressEvt({...params});    
    } else {
      setCloseEditAddressEvt();  
      
      dispatch(clearAddressDatasources());

      dispatch(resetInputValue());
    }

  };
  
  return dataSources?.['Address Settings']?.['Address Elements'] ? (
    <div className='address-component'>
      <StoreLocatorFilterPopupContent
        dataFields={dataSources}
        setSubmitAddressEvt={onSubmmitAddAddressHandler}
      >
      </StoreLocatorFilterPopupContent>
    </div> 
  ) : '';
};

StoreLocatorFilterPopup.propTypes = {
  dataSources: PropTypes.object,
  setCloseEditAddressEvt: PropTypes.func
};

export default StoreLocatorFilterPopup;
