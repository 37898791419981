import './CPDemoStatus.scss';

import { any } from 'prop-types';
import React, { useEffect, useMemo,useState } from 'react';
import { useSelector } from 'react-redux';

import { getUserFullNameWithoutFormat } from '@components/SlicingPage/MyAccount/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CPDemoStatusModel from './Models/CPDemoStatusModel';

const CPDemoStatus = ({ fields }) => {
  const [cPDemoFields, setCPDemoFields] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  useEffect(() => {
    const cPDemoStatus = new CPDemoStatusModel();

    setCPDemoFields(cPDemoStatus.getData(deepCopy(fields || {})));
  }, []);

  const accountFullName = useMemo(() => {
    const firstName = clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.FirstName || '';
    const lastName = clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.LastName || '';

    return getUserFullNameWithoutFormat({ firstName, lastName });
  }, [clientTellingPortalCustomer]);

  return cPDemoFields && SitecoreContextFactoryService.getValueContextItem('cpCustomerId') ? (
    SitecoreContextFactoryService.getValueContextItem('cpCustomerName') !== 'Anonymous' ? (
      <div className='cp-demo'>
        <div className='cp-demo__status'>
          <p className='cp-demo__status__content'>
            <span>{fields.Text.value.replace('[CustomerName]', '')}</span>
            <span className='customer-name'>{accountFullName || SitecoreContextFactoryService.getValueContextItem(
              'cpCustomerName')}</span>
          </p>
        </div>
      </div>
    ) : (
      <div className='cp-demo'>
        <div className='cp-demo__status'>
          <p className='cp-demo__status__content'>
            <Text tag='span' field={cPDemoFields['Text']} />
          </p>
        </div>
      </div>
    )
  ) : (
    <></>
  );
};

CPDemoStatus.propTypes = {
  fields: any
};

export default CPDemoStatus;
