import './TwoColumnImageWithAccodion.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import TwoColumnImageWithAccodionModel from './model/TwoColumnImageWithAccodion';
import TwoColumnImageWithAccodionItem from './TwoColumnImageWithAccodionItem';

const TwoColumnImageWithAccodion = (props) => {
  const titleFontsize = props.params?.['Title Font Size'] || '';
  const mobileTitleFontsize = props.params?.['Mobile Title Font Size'] || '';
  const subTitleFontsize = props.params?.['Subtitle Font Size'] || '';
  const mobileSubTitleFontsize = props.params?.['Mobile Subtitle Font Size'] || '';
  const descriptionFontsize = props.params?.['Description Font Size'] || '';
  const mobileDescriptionFontsize = props.params?.['Mobile Description Font Size'] || '';

  const [windowWidth, setWindowWidth] = useState(768);
  const [titleFontSize, setTitleFontSize] = useState(null);
  const [subTitleFontSize, setSubTitleFontSize] = useState(null);
  const [descriptionFontSize, setDescriptionFontSize] = useState(null);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth && windowWidth < IPAD_BREAKPOINT) {
      setTitleFontSize(mobileTitleFontsize);

      setSubTitleFontSize(mobileSubTitleFontsize);

      setDescriptionFontSize(mobileDescriptionFontsize);
    }
    if (windowWidth && windowWidth >= IPAD_BREAKPOINT) {
      setTitleFontSize(titleFontsize);

      setSubTitleFontSize(subTitleFontsize);

      setDescriptionFontSize(descriptionFontsize);
    }
  }, [windowWidth]);

  const [dataFields, setDataFields] = useState(null);

  const [activeKey, setActiveKey] = useState(-1);

  useEffect(() => {
    const model = new TwoColumnImageWithAccodionModel();

    setDataFields(model.getData(deepCopy(props.fields) || {}));
  }, []);

  const onToogleAccordion = (id) => {
    id === activeKey ? setActiveKey(-1) : setActiveKey(id);
  };

  return (
    dataFields && (
      <div
        className={`two-col-image-accordion ${
          props.params?.['Position Image'] === 'Image First' ? '' : 'two-col-image-accordion--accordion-left'
        } ${props.params?.['Text Color'] === 'Black' ? '' : 'two-col-image-accordion--text-light'
        } ${props.params['Is Not Full Width'] == 1 ? 'container not-full-width' : ''
        } ${props.params['Is Top Padding'] == 1 ? 'has-top-padding' : ''
        } ${props.params['Is Bottom Padding'] == 1 ? 'has-bottom-padding' : ''
        } ${props.params['Is Not Full Image On Mobile Version'] == 1 ? 'not-full-width--mobile' : ''
        }`}
        style={{
          lineHeight: props.params['Line Height']+'px' || '' 
        }}
      >
        <div className='two-col-image-accordion__wrap' style={{ backgroundColor: dataFields['Background Color'].fields.Value.value || ''}}>
          <div className='two-col-image-accordion__image'>
            <Image field={dataFields.Image}/>
          </div>
          <div className='two-col-image-accordion__accordion' style={{ backgroundColor: '#'+props.params['Background Content Color Code'] || ''}}>
            <div className='accordion-wrap'>
              <div className='accordion__heading'>
                {dataFields.items[0]?.fields?.Title?.value ? (
                  <Text tag='h2' className='accordion__heading__title experience-title' field={dataFields.items[0]?.fields?.Title} style={{ fontSize: titleFontSize+'px' || '', color: '#'+props.params['Title Color Code'] || ''}}/>
                ) : (
                  <></>
                )}
                {dataFields.items[0]?.fields?.Description?.value ? (
                  <RichText tag='p' className='accordion__heading__desc' field={dataFields.items[0]?.fields?.Description} />
                ) : (
                  <></>
                )}
              </div>
              <Accordion bsPrefix='accordion__info-list' activeKey={activeKey}>
                {dataFields.items[0]?.fields?.items?.map((item) => {
                  return <TwoColumnImageWithAccodionItem 
                    key={item.id} 
                    data={item} 
                    activeKey={activeKey} 
                    onToogleAccordion={onToogleAccordion} 
                    subTitleFontSize={subTitleFontSize} 
                    descriptionFontSize={descriptionFontSize}
                    subTitleColor={props.params['Subtitle Color Code']}
                    descriptionColor={props.params['Description Color Code']}
                  />;
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

TwoColumnImageWithAccodion.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object
};

export default TwoColumnImageWithAccodion;
