import '../../ArticleCarousel/ArticleCarousel.scss';

import PropTypes from 'prop-types';
import React from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import SitecoreImageModel from '@models/SitecoreImageModel';
import { Image} from '@sitecore-jss/sitecore-jss-react';

const CarouselImage = ({imageList}) => {
  const params = {
    slidesPerView: 1.5,
    centeredSlides: false,
    spaceBetween: 16,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    breakpoints: {
      992: {
        slidesPerView: 3,
        spaceBetween: 24,
        navigation: {
          nextEl: '.carousel-image .swiper-button-next',
          prevEl: '.carousel-image .swiper-button-prev',
        },
      },

      767: {
        slidesPerView: 2,
        spaceBetween: 24,
        navigation: {
          nextEl: '.carousel-image .swiper-button-next',
          prevEl: '.carousel-image .swiper-button-prev',
        },
      }
    }
  };

  return (
    <div className='carousel-image carousel-detail'>
      <div className='carousel-detail__list'>
        <SwipeComponent param={params} >
          {
            imageList.length ? (
              imageList.map((image) => (
                <div className='image-slide' key={image.id}>
                  <Image media={image.fields['Image'] || new SitecoreImageModel()}/>
                </div>
              ))
            ) : ''
          }
        </SwipeComponent>
        <div className='swiper-button-next'></div>
        <div className='swiper-button-prev'></div>
      </div>
      
    </div>
  );
};

CarouselImage.propTypes = {
  imageList: PropTypes.array
};

export default CarouselImage;
