import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/myAppointment';
import * as myAppointmentTypes from '@redux/actions/myAppointment/myAppointmentTypes';
import { postAsObservable } from '@services/genericService';

import * as config from './config';

const cancelAppointmentEpic = (action$) =>
  action$.pipe(
    ofType(myAppointmentTypes.CANCEL_APPOINTMENT),
    switchMap((action) => {
      return postAsObservable(config.ACTION_CANCEL_APPOINTMENT, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            if (res.data?.StatusCode === 0) {
              return actions.cancelAppointmentSuccess(res.data);
            } else {
              return actions.cancelAppointmentFailed(res.data?.StatusReason);
            }
          } else {
            return actions.cancelAppointmentFailed(res.data?.StatusCode || 'Failed');
          }

        }),
        catchError((error) => of(actions.cancelAppointmentFailed(error)))
      );
    })
  );

export default cancelAppointmentEpic;
