import GoogleAnalytic from '@components//Share/GoogleAnalytic';
import Global from '@utils/global';

export const EVENT_NAME = {
  login: 'login',
  register: 'sign_up'
};

export const EVENT_METHOD = {
  google: 'google',
  facebook: 'facebook',
  email: 'email'
};

export const EVENT_STATUS = {
  login: {
    attempt: 'login_attempt',
    success: 'login_success',
    failure: 'login_failure'
  },
  register: {
    attempt: 'signup_attempt',
    success: 'signup_success',
    failure: 'signup_failure'
  }
};

export const EVENT_MESSAGE = {
  success: 'success',
  fail: 'fail acknowledgement'
};

export const handleTrackingEventRegisterLogin = (eventName, status, message, method = EVENT_METHOD.email) => {
  const isGA4 = Global.getIsGA4();
  if (isGA4) {
    new GoogleAnalytic().gAnalytic4('event', eventName, {
      method: method,
      status: status,
      message: message
    });
  }
};
