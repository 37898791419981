import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { bookAppointmentStart } from '@redux/actions/campaignRegister/bookAppointment';
import { updateContactFailure, updateContactSuccess } from '@redux/actions/campaignRegister/updateContact';
import { UPDATE_CONTACT_START } from '@redux/actions/campaignRegister/updateContact/types';
import { updatePhoneNumberSuccess } from '@redux/actions/myAccount';
import { postAsObservable } from '@services/genericService';
import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import { formatDateToUTC } from '@utils/dateFormatV2';
import { deepCopy } from '@utils/utility';

import { UPDATE_CONTACT_API_ENDPOINT } from './config';

const updateContactEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_CONTACT_START),
    switchMap((action) => {
      const phoneNumberRawValue = action.payload.phoneNumber;

      const params = {
        ContactId: action.payload.contactId,
        FirstName: action.payload.firstName,
        LastName: action.payload.lastName,
        DateOfBirth: formatDateToUTC(action.payload.dob),
        AllowSMS: action.payload.allowSMS,
        AllowEmail: action.payload.allowEmail,
        AllowDirectMail: action.payload.allowDirectMail,
        MobileNumber: isAtSiteName(MY_SITE_NAME) ? `+${phoneNumberRawValue}` : phoneNumberRawValue
      };

      return postAsObservable(UPDATE_CONTACT_API_ENDPOINT, params).pipe(
        switchMap((res) => {
          if (res.data.StatusCode === 0) {
            if (action.payload.bookingValues.isUpdatePhoneNumber) {
              return of(
                updateContactSuccess(deepCopy(res.data.Result)),
                bookAppointmentStart(action.payload.bookingValues),
                updatePhoneNumberSuccess(res.data.Result?.MobileNumber)
              );
            }

            return of(updateContactSuccess(deepCopy(res.data.Result)), bookAppointmentStart(action.payload.bookingValues));
          } else {
            return of(updateContactFailure(res.data || 'Failed'));
          }
        }),
        catchError((error) => of(updateContactFailure(error || 'Failed')))
      );
    })
  );

export default updateContactEpic;
