import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { DEFAULT_POS_COUNTRY_CODE, MY_SITE_NAME } from '@utils/constant';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import Global from '@utils/global';

export const useGetCheckedPDPA = () => {
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  return useMemo(() => {
    return clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.PDPAAgreement ?? false;
  }, [clientTellingPortalCustomer]);
};

export const useMappingCustomerInfoData = () => {
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const isMySite = useIsAtSiteName(MY_SITE_NAME);
  const areaCodes = useMemo(() => settingGlobal?.AreaCodes || [], [settingGlobal]);

  const appendPlusForMYSiteValue = useCallback((value) => isMySite && value && !value.includes('+')
    ? `+${value}`
    : value || '', [isMySite]);

  const removePOSCode = useCallback((value) => {
    if (value && DEFAULT_POS_COUNTRY_CODE.includes(value?.toString())) {
      const posCodeExistInValue = DEFAULT_POS_COUNTRY_CODE.find((code) => value.includes(code));

      return value.replace(posCodeExistInValue, '');
    }

    return value || '';
  }, []);

  return useCallback((data) => {
    if (data) {
      const customerInfoPrefixPhoneNumber = removePOSCode(data?.customerInformation?.PrefixPhoneNumber || '');
      const customerInfoPhoneNumber = Global.splitPhoneNumberWithPOSCode(data?.customerInformation?.PhoneNumber) || '';

      const {
        code: customerInfoCodeValue,
        value: customerInfoPhoneNumberValue
      } = Global.splitPhoneNumberWithoutCode(true, appendPlusForMYSiteValue(customerInfoPhoneNumber), areaCodes);

      const defaultFullPhoneNumberValue = Global.splitPhoneNumberWithPOSCode(data?.PhoneNumber);

      const { value: fullPhoneNumber } = Global.splitPhoneNumberWithoutCode(
        true,
        appendPlusForMYSiteValue(defaultFullPhoneNumberValue),
        areaCodes
      );

      const customerDetailPrefixPhoneNumber = removePOSCode(data?.currentCustomerDetail?.PrefixPhoneNumber || '');

      const customerDetailPhoneNumber = Global.splitPhoneNumberWithPOSCode(data?.currentCustomerDetail?.PhoneNumber)
        || '';

      const {
        code: customerDetailCodeValue,
        value: customerDetailPhoneNumberValue
      } = Global.splitPhoneNumberWithoutCode(true, appendPlusForMYSiteValue(customerDetailPhoneNumber), areaCodes);

      if (data?.customerInformation) data = {
        ...data,
        customerInformation: {
          ...data?.customerInformation,
          PrefixPhoneNumber: appendPlusForMYSiteValue(customerInfoCodeValue) || customerInfoPrefixPhoneNumber,
          PhoneNumber: customerInfoPhoneNumberValue?.replace('+', '') || customerInfoPhoneNumber
        }
      };
      if (data?.currentCustomerDetail) data = {
        ...data,
        currentCustomerDetail: {
          ...data?.currentCustomerDetail,
          PrefixPhoneNumber: appendPlusForMYSiteValue(customerDetailCodeValue) || customerDetailPrefixPhoneNumber,
          PhoneNumber: customerDetailPhoneNumberValue?.replace('+', '') || customerDetailPhoneNumber
        }
      };

      return {
        ...data,
        PhoneNumber: fullPhoneNumber?.replace('+', '')
      };
    }

    return data;
  }, [areaCodes]);
};