import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { addWarrantyFail, addWarrantySuccess } from '@redux/actions/addWarranty';
import { ADD_WARRANTY } from '@redux/actions/addWarranty/types';
import { postAsObservable } from '@services/genericService';

import { ACTION_ADD_WARRANTY } from './configs';

const addWarrantyEpic = (action$) =>
  action$.pipe(
    ofType(ADD_WARRANTY),
    switchMap((action) => {
      return postAsObservable(ACTION_ADD_WARRANTY, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            return addWarrantySuccess(res.data);
          } else {
            return addWarrantyFail(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(addWarrantyFail(error.message || 'Failed')))
      );
    })
  );

export default addWarrantyEpic;
