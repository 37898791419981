import { deepCopy } from '@utils/utility';

import GatewaysModel from './GatewaysModel';

export default class PaymentConfigurationModel {
  constructor() {
    this.Gateways = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Gateways')) {
      dataSource['Gateways'].map((element) => {
        this['Gateways'].push(new GatewaysModel().getData(deepCopy(element) || {}));
      });
    }

    return this;
  }
}
