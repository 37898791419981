import Proptypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react';
import Global from '@utils/global';

const ProductVariantImage = ({inputData}) => {
  let altImg = 'no image';
  
  if (inputData) {
    altImg = Global.getQueryParams(inputData, 'alt');
  }

  return (
    <Fragment>
      <img className='swiper-lazy animated' data-src={inputData} alt={altImg} />
      <div className='swiper-lazy-preloader swiper-lazy-preloader-white'></div>
    </Fragment>
  );
};

ProductVariantImage.propTypes = {
  inputData: Proptypes.string,
};

export default ProductVariantImage;
