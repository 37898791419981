import './SearchFilterStoreBar.scss';

import { func } from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import FilterStoreBtn from './filterStoreBtn';

const initialStates = {
  // eslint-disable-next-line no-unused-vars
  onSearch: (keyword) => {},
  // eslint-disable-next-line no-unused-vars
  onFilter: (params) => {},
  onClearFilterArea: () => {}
};
const CampaignFilterStoreBarContext = createContext(initialStates);

export const useCampaignFilterStoreBarContext = () => useContext(CampaignFilterStoreBarContext);

const SearchFilterStoreBar = ({ onSearch, onFilter, onClearFilterArea }) => {
  const [keyword, setKeyWord] = useState('');

  const handleChangeSearchInput = (event) => {
    const value = event.target.value;

    setKeyWord(value);

    onSearch(value.toLowerCase());
  };

  const handleFilterArea = (params) => {
    onFilter(params);
  };

  return (
    <CampaignFilterStoreBarContext.Provider
      value={{
        onSearch,
        onFilter: handleFilterArea,
        onClearFilterArea
      }}
    >
      <div className='store-locator__content__list__search search-filter-bar'>
        <div className='store-locator__search__input'>
          <input
            type='text'
            className='form-control form-control-lg store-locator__search__input__item'
            value={keyword}
            onChange={handleChangeSearchInput}
          />
        </div>

        <div className='store-locator__search__btn'>
          <FilterStoreBtn />
        </div>
      </div>
    </CampaignFilterStoreBarContext.Provider>
  );
};

SearchFilterStoreBar.propTypes = {
  onSearch: func,
  onFilter: func,
  onClearFilterArea: func
};

export default SearchFilterStoreBar;
