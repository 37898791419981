import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class BookExperienceButtonComponentModel {
  constructor() {
    this['Button Text'] = new SitecoreTextModel();

    this['Button Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Text')) {
      this['Button Text'].getData(dataSource['Button Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Link')) {
      this['Button Link'].getData(dataSource['Button Link']);
    }

    return this;
  }
}