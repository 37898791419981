import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import {connect, useDispatch} from 'react-redux';

import BundleBuyingOptionLayout from '@components/BundleBuyingOptionLayout';
import BundleBuyingOptionLayoutShimmer from '@components/BundleBuyingOptionLayout/BundleBuyingOptionLayoutShimmer';
import BundleBOFieldsModel from '@components/BundleBuyingOptionLayout/model/BundleBOFieldsModel';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import {getCurrentSOCart} from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import {GA4_LOCAL_STORAGE_KEY} from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import { behaviorInstallment$ } from './InstallmentBehavior';

const ChosenBundle = (props) => {
  const { fields, SubLines, isDoneGetCart } = props;
  const dispatch = useDispatch();
  const isClientTellingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [isEditing, setEditing] = useState(false);
  const [dataFields, setDataFields] = useState(null);

  useEffect(() => {
    if (isClientTellingUser) {
      dispatch(getCurrentSOCart());
    }
  }, [isClientTellingUser]);

  useEffect(() => {
    const bundleBOFieldsModel = new BundleBOFieldsModel();

    if (isDoneGetCart) {
      if (SubLines) {
        setDataFields(bundleBOFieldsModel.getData(fields || {}, SubLines));

        setEditing(true);
      } else {
        setDataFields(bundleBOFieldsModel.getData(fields || {}));

        setEditing(false);
      }
    }

    if(!Global.getGA4Params()) {
      window.localStorage.setItem(GA4_LOCAL_STORAGE_KEY, JSON.stringify({}));
    }
  }, [isDoneGetCart]);

  useDidUpdateEffect(() => {
    behaviorInstallment$.next({ installment: dataFields['Chosen Bundle']['InstallmentPeriods'] });
  }, [dataFields]);

  return dataFields ? (
    <Fragment>
      <BundleBuyingOptionLayout dataBundle={dataFields} isEditing={isEditing}/>
    </Fragment>
  ) : <LoadingShimmer itemNumb={1}>
    <BundleBuyingOptionLayoutShimmer></BundleBuyingOptionLayoutShimmer>
  </LoadingShimmer>;
};

ChosenBundle.propTypes = {
  fields: Proptypes.any,
  SubLines: Proptypes.any,
  isDoneGetCart: Proptypes.any,
};

const mapStateToProps = (state) => {
  const bundleBOReducer = state.bundleBOReducer;
  const cartDetailReducer = state.productReducer.cartDetailReducer;

  return {
    SubLines: bundleBOReducer.SubLines || cartDetailReducer.SOCartSubLines || null,
    isDoneGetCart: bundleBOReducer.isDoneGetCart || cartDetailReducer.isDoneGetSOCart || false
  };
};


export default connect(mapStateToProps)(ChosenBundle);
