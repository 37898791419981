import './FulfillmentOptions.scss';

import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { config } from '@components/SlicingPage/MyAccount/MyAddress/AddressForMultiLanguage/service';
import { getPickUpPoint } from '@redux/actions/cartDetail';
import { getBookedAddress, getCountryDelivery, getStoreAddressList } from '@redux/actions/checkoutProcess';
import { disableRequiredField, getAddressDatasources } from '@redux/actions/myAddress';
import { getClientSOCartStart } from '@redux/actions/personalizeOffer/getClientSOCart';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { DELIVERY_OPTION, REDIRECT_CHECKOUT_PARAM } from '@utils/constant';
import { useDepositValidationCart } from '@utils/customsHook/useDepositValidationCart';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';
import global from '@utils/global';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import {VIEW_OFFER_DETAIL_CART_ID_KEY} from '../PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';
import CartDetailModal from '../SlicingPage/CartDetails/CartDetailModal/index';
import ContinuePaymentBtn from './ContinuePaymentBtn';
import DeliveryComponent from './DeliveryComponent';
import FulfillmentOptionsShimmer from './FulfillmentOptionsShimmer';
import FulfillmentOptionsModel from './model/FulfillmentOptionsModel';

const FulfillmentOptions = ({ cartData, fields, isLogin, cartValue, errorItemId }) => {
  const dispatch = useDispatch();
  const isTWSite = SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OTW';
  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');
  const [layoutData, setLayoutData] = useState(null);
  const [showError, setShowError] = useState(false);
  const [itemInValid, setItemInvalid] = useState(null);
  const [beginCheckout, setBeginCheckout] = useState(false);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const [fieldNameDropdownArr, setFieldNameDropdownArr] = useState();
  const isGA4 = Global.getIsGA4();
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const query = useQuery();
  const isValidateDepositCart = useDepositValidationCart();

  const clientSOCartId = useMemo(() => {
    return query.get(VIEW_OFFER_DETAIL_CART_ID_KEY) || '';
  }, [query]);

  const goBackLoggedLink = useMemo(() => {
    let hrefValue = layoutData?.['Back To Cart Link']?.value?.href;
    if (clientSOCartId) hrefValue = `${layoutData?.['Back To Offer Cart Link']?.value?.href}?${query.toString()}`;

    return hrefValue;
  }, [clientSOCartId, layoutData, query]);


  const currentCartCustom = useSelector((state) => state?.productReducer?.cartDetailReducer?.currentCartCustom);
  const [isShow, setShow] = useState(false);

  const onCloseHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    const model = new FulfillmentOptionsModel();

    setLayoutData(model.getData(deepCopy(fields)));

    if (isLogin) {
      dispatch(getBookedAddress());
    }

    dispatch(getCountryDelivery());

    dispatch(getStoreAddressList());

    dispatch(getPickUpPoint());
  }, []);

  useEffect(() => {
    // INFO: getClientSOCart for checkout if user checkout with SO cart
    const cartId = query.get(VIEW_OFFER_DETAIL_CART_ID_KEY);

    if (cartId) dispatch(getClientSOCartStart({ cartId: cartId }));
  }, []);

  useEffect(() => {
    if (errorItemId && errorItemId !== '') {
      let element = document.getElementById(errorItemId);

      const y = element.getBoundingClientRect().top + window.pageYOffset - 150;

      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }, [errorItemId]);

  useEffect(() => {
    if (cartValue && cartValue.Lines && cartValue.Lines.length > 0) {
      if (!beginCheckout) {
        setBeginCheckout(!beginCheckout);
      }
      if (isTWSite) {
        // BridgeWell
        window._bwq = window._bwq || [];

        window._bwq.push([
          'trackSingle',
          '687-138G445F0MCC238',
          'InitiateCheckout',
          {
            tags: ['checkout_687']
          }
        ]);
      }

      //Tamedia
      if (window.taq) {
        window.taq('track', '8wLls6Qyqd0d', 'InitiateCheckout');
      }
    }
  }, [cartValue]);

  useEffect(() => {
    if (cartData) {
      let itemInvalidId = '';
      if (cartData.IsDeliveryFee && cartData.Delivery?.[0]?.Address?.Address1 == '') {
        itemInvalidId = 'delivery';
      } else
      if (itemInvalidId === '' && cartData.PickUpStore.length > 0) {
        itemInvalidId = cartData.PickUpStore.find(item => {
          return item.Address?.ExpressStoreId === '';
        })?.ExternalCartLineId || '';
      } else
      if (itemInvalidId === '' && cartData.SFexpress.length > 0 && cartData.SFexpress.find(item => item.Address.ExpressStoreId === '')) {
        itemInvalidId = 'sf-express';
      } else if (cartData.IsDeliveryBySFExpressFee && cartData.DeliveryBySFExpress?.[0]?.Address?.Address1 == '') {
        itemInvalidId = 'delivery-by-sf-express';
      } else if ((itemInvalidId === '' && !cartData.BillingAddress) || (cartData.BillingAddress && !cartData.BillingAddress.FirstName)) {
        itemInvalidId = 'billing-details';
      }

      setItemInvalid(itemInvalidId);
    }
  }, [cartData]);

  useDidUpdateEffect(() => {
    setFieldNameDropdownArr(
      layoutData?.['Address Settings']?.['Address Elements'].filter((address) => address.Type.value === config.TypeElement.Dropdown)
    );
  }, [layoutData]);

  useDidUpdateEffect(() => {
    if (cartData?.DeliveryBySFExpress?.length) {
      dispatch(
        getAddressDatasources({
          model: {
            CountryCode: layoutData['Address Settings']['Country Settings']['Country Code'].value || 'SA',
            RootValue: '',
            RootType: '',
            ChildType: ''
          },
          fieldName: fieldNameDropdownArr?.[0]?.[config.FieldNameModel]?.value,
          condition: null,
          isMacau: true
        })
      );
      if (cartData?.DeliveryBySFExpress?.[0]?.Address) {
        for (let index = 0; index < fieldNameDropdownArr.length - 1; index++) {
          const rootValue =
            fieldNameDropdownArr[index][config.FieldNameModel].value == 'City' &&
            cartData.DeliveryBySFExpress?.[0]?.DeliveryOptions?.find(
              (option) => option?.ShippingOptionType?.Value == DELIVERY_OPTION.DeliveryBySFExpress
            )?.AddressRootCode;
          const rootType = fieldNameDropdownArr[index][config.FieldNameModel].value;
          if (rootValue) {
            dispatch(
              getAddressDatasources({
                model: {
                  CountryCode: layoutData['Address Settings']['Country Settings']['Country Code'].value || 'SA',
                  RootValue: rootValue,
                  RootType: rootType,
                  ChildType: ''
                },
                fieldName: fieldNameDropdownArr[index + 1][config.FieldNameModel].value,
                condition: fieldNameDropdownArr[index].Condition,
                isMacau: true
              })
            );
          } else {
            const optionMustReset = fieldNameDropdownArr.slice(index);

            dispatch(disableRequiredField(optionMustReset));
            break;
          }
        }
      }
    }
  }, [cartData?.DeliveryBySFExpress?.length]);

  useEffect(() => {
    if (!IsAuthenticated && settingGlobal?.IsDisableGuestCheckout) {
      const fromUrl = window.location.href;

      window.location.href = `${settingGlobal?.LoginLink}?${REDIRECT_CHECKOUT_PARAM}=${fromUrl}`;
    }
  }, [IsAuthenticated, settingGlobal?.IsDisableGuestCheckout]);

  const getPacakgeNumber = (ind, type) => {
    if (type === 'store') {
      return ind + (cartData.Delivery && cartData.Delivery.length > 0 ? 1 : 0) + 1;
    } else if (type === 'sf') {
      return ind + (cartData.Delivery && cartData.Delivery.length > 0 ? 1 : 0) + cartData.PickUpStore.length + 1;
    }
  };

  const showErrorMessage = ({ isScrollToTop = true }) => {
    setShowError(true);
    if (isScrollToTop) {
      if (itemInValid !== 'billing-details') {
        let element = document.getElementById(itemInValid);

        const y = element?.getBoundingClientRect()?.top + window.pageYOffset - 150;

        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  const onHandleTrackingCode = (evt) => {
    const isDepositFreeGift = currentCartCustom?.NormalListProduct?.every((item) => item.IsDepositFreeGift);
    if (currentCartCustom?.NormalListProduct?.length > 0 && currentCartCustom?.DepositListProduct?.length > 0 && isValidateDepositCart) {
      if(isDepositFreeGift == true) {
        setShow(false);
      }else {
        evt.preventDefault();

        setShow(true);
      }
    } else if (currentCartCustom?.DepositListProduct?.length > 1) {
      evt.preventDefault();

      setShow(true);
    } else {
      setShow(false);
    }

    //Old Logic GA
    // INF: Google analytics
    // window.dataLayer = window.dataLayer || [];
    // let products = [];

    // if (currentCart.Lines.length) {
    //   currentCart.Lines.forEach((line) => {
    //     products.push({
    //       name: line?.DisplayName,
    //       id: line?.ProductId,
    //       price: line?.LineFinalTotalAmount.toString(),
    //       category: line?.CategoryName,
    //       quantity: parseInt(line?.Quantity)
    //     });
    //   });
    // }

    // let obj = {
    //   ecommerce: {
    //     checkout: {
    //       actionField: { step: 2 },
    //       products: products
    //     }
    //   },
    //   event: 'checkout',
    //   eventCallback: function () {
    //     document.location = layoutData?.['Continue Payment Button Link']?.value?.href;
    //   }
    // };

    // window.dataLayer.push(obj);
    // new GoogleAnalytic().ggGateWay('event', 'checkout', obj);

    //New Logic ON/OFF GA, GA4
    if (isGA4) {
      if (currentCart?.Lines) {
        const cartLines = currentCart?.Lines.map((line) => ({
          ...line,
          itemListName:
            global.getGA4Params()?.[line.VariantId] || SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Page Title']?.value
        }));

        const params = {
          ecommerce: {
            currency: currentCart?.Currency || '',
            value: currentCart?.TotalAmount,
            coupon: currentCart?.PromoCodes?.toString(),
            shipping_tier: 'Ground',
            user_type: IsAuthenticated ? 'registered' : 'guest',
            order_type: currentCart?.Lines?.[0]?.DeliveryOptions?.[0]?.Name || '',
            items: global.getItems(cartLines, currentCart?.PromoCodes?.toString(), '', currentCart?.Currency || '')
          }
        };

        new GoogleAnalytic().gAnalytic4('event', 'add_shipping_info', params);
      }
    } else {
      window.dataLayer = window.dataLayer || [];
      let products = [];

      if (currentCart.Lines.length) {
        currentCart.Lines.forEach((line) => {
          products.push({
            name: line?.DisplayName,
            id: line?.ProductId,
            price: line?.LineFinalTotalAmount.toString(),
            category: line?.CategoryName,
            quantity: parseInt(line?.Quantity)
          });
        });
      }

      let obj = {
        ecommerce: {
          checkout: {
            actionField: { step: 2 },
            products: products
          }
        },
        event: 'checkout',
        eventCallback: function () {
          document.location = layoutData?.['Continue Payment Button Link']?.value?.href;
        }
      };

      window.dataLayer.push(obj);
    }
  };

  return layoutData && cartData ? (
    <div className='fulfillment-options animated-slow fadeIn delay-100'>
      <div className='fulfillment-options__group'>
        {cartData.Delivery?.length > 0 ? (
          <DeliveryComponent
            type='delivery'
            showError={showError}
            page='fulfillment'
            layoutData={layoutData}
            data={cartData.Delivery}
            packageNumber={1}
          ></DeliveryComponent>
        ) : null}
        {/* {cartData.DepositListProduct?.length > 0 ? (
              cartData?.DepositListProduct.map((depositProduct, index)=> {
                return(
                  <DeliveryComponent key={index} type='deposit' showError={showError} page='fulfillment' layoutData={layoutData} data={cartData.DepositListProduct} depositProduct={depositProduct}></DeliveryComponent>
                );
              })
            ) : null} */}
        {cartData.PickUpStore?.map((item, ind) => {
          return (
            <DeliveryComponent
              type='pick-up-store'
              cartData={cartData}
              page='fulfillment'
              showError={showError}
              layoutData={layoutData}
              data={item}
              key={ind}
              packageNumber={getPacakgeNumber(ind, 'store')}
            ></DeliveryComponent>
          );
        })}
        {cartData.SFexpress?.length > 0 ? (
          <DeliveryComponent
            type='sf-express'
            showError={showError}
            page='fulfillment'
            layoutData={layoutData}
            data={cartData.SFexpress}
            packageNumber={cartData.Delivery?.length > 0 ? 2 : 1}
          ></DeliveryComponent>
        ) : null}
        {cartData.DeliveryBySFExpress?.length > 0 ? (
          <DeliveryComponent
            type='delivery-by-sf-express'
            showError={showError}
            page='fulfillment'
            layoutData={layoutData}
            data={cartData.DeliveryBySFExpress}
            packageNumber={
              cartData.Delivery?.length > 0 && cartData.SFexpress?.length > 0
                ? 3
                : cartData.Delivery?.length > 0 || cartData.SFexpress?.length > 0
                  ? 2
                  : 1
            }
          ></DeliveryComponent>
        ) : null}
        <DeliveryComponent layoutData={layoutData} showError={showError} page='fulfillment' type='billing-details'></DeliveryComponent>
      </div>
      <div className='fulfillment-options__button account-checking__form__button'>
        <div className='account-checking__form__button__submit text-center'>
          <ContinuePaymentBtn
            layoutData={layoutData}
            cartData={cartData}
            onHandleTrackingCode={onHandleTrackingCode}
            onShowErrorMessage={showErrorMessage}
          />
          <CartDetailModal isShow={isShow} onCloseHandler={onCloseHandler} ondataSources={layoutData} />
        </div>
        {isLogin ? (
          <div className='account-checking__form__button__link text-center'>
            <a href={goBackLoggedLink} className='btn-link btn-link'>
              {layoutData['Back To Cart Label'].value}
            </a>
          </div>
        ) : (
          <div className='account-checking__form__button__link text-center'>
            <a href={layoutData['Back To Your Detail Button Link'].value.href} className='btn-link btn-link'>
              {layoutData['Back To Your Detail Label'].value}
            </a>
          </div>
        )}
      </div>
    </div>
  ) : (
    <div className='fulfillment-options'>
      <div className='fulfillment-options__group'>
        <FulfillmentOptionsShimmer></FulfillmentOptionsShimmer>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const currentCustomCart = state.productReducer.cartDetailReducer.currentCartCustom || null;
  const currentDefaultCartData = state.productReducer.cartDetailReducer?.currentCart || null;
  const clientSOCartData = state.personalizeOfferReducer.getClientSOCartReducer.data?.OsimCart || null;
  const clientSOCartCustomData = state.personalizeOfferReducer.getClientSOCartReducer.customData || null;

  return {
    cartData: clientSOCartCustomData || currentCustomCart,
    isLogin: state.singleSignOnReducer.userInfoReducer?.isLogin || false,
    cartValue: clientSOCartData || currentDefaultCartData,
    errorItemId: state.productReducer.cartDetailReducer.errorItemId
  };
};

FulfillmentOptions.propTypes = {
  cartData: PropTypes.object,
  fields: PropTypes.object,
  cartValue: PropTypes.object,
  page: PropTypes.string,
  errorItemId: PropTypes.string,
  isLogin: PropTypes.bool
};

export default connect(mapStateToProps)(FulfillmentOptions);
