import { object } from 'prop-types';
import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

const LocationStoreItem = ({ locationData }) => {
  return (
    <>
      <div className='store-item__name'>
        <p>{locationData.Title}</p>
      </div>
      <div className='store-item__address'>
        <RichText field={{ value: locationData?.['Address Details'] }} className='store-item__address__text' />
        <span className='store-item__address__icon'>
          <span className='store-locator-icon store-locator-icon--direction'></span>
        </span>
      </div>
      {/*INFO: uncomment code below when need to use code and phone number*/}
      {/*<div className='store-item__code'>*/}
      {/*  <span>569933</span>*/}
      {/*</div>*/}
      {/*<div className='store-item__phone'>*/}
      {/*  <span>6752 5849</span>*/}
      {/*</div>*/}
    </>
  );
};

LocationStoreItem.propTypes = {
  locationData: object
};

export default LocationStoreItem;
