import './ReviewCustomerInformation.scss';

import { object } from 'prop-types';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { getUserFullNameWithoutFormat } from '@components/SlicingPage/MyAccount/utils';
import { getContactPersonalInformation, storeCPCustomer } from '@redux/actions/clientTellingPortalCustomer';
import { NO_MASSAGE_OWNER_OPTION_KEY, NO_MASSAGE_OWNER_OPTION_TYPE } from '@redux/reducers/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useFormatEnDate } from '@utils/customsHook/useFormatDate';
import { convertHKTWDate } from '@utils/customsHook/useFormatDate/utils';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CPInput from '../GenericComponents/CPForm/CPInput';
import CPSelect from '../GenericComponents/CPForm/CPSelect';
import { TYPE_CHECKBOX, TYPE_RADIO } from '../GenericComponents/CPForm/FormConstant';
import { getAnswerSelectedDisplayName } from '../QuickAccess/CustomeInfoPopup/utils';
import { useMappingCustomerInfoData } from '../QuickAccess/CustomerAcknowledgePopup/hooks';
import ReviewCustomerInformationModel from './model/ReviewCustomerInformationModel';
import ReviewCustomerInformationShimmer from './ReviewCustomerInformationShimmer';

const ReviewCustomerInformation = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [phoneApperance, setPhoneApperance] = useState(false);
  const dispatch = useDispatch();
  const mappingCustomerInfoData = useMappingCustomerInfoData();
  const clientTellingPortalCustomerStates = useSelector((state) => state.clientTellingPortalCustomer);
  const clientTellingPortalCustomer = useMemo(() => mappingCustomerInfoData(clientTellingPortalCustomerStates), [clientTellingPortalCustomerStates]);
  const ReferPOS = clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS;
  const isSyncFromPos = clientTellingPortalCustomer?.contacts?.[0]?.isSyncFromPos;
  const [dirtyMassageOwnerShip, setDirtyMassageOwnnerShip] = useState(true);
  const [selectedMassageOwnerShip, setSelectedMassageOwnerShip] = useState([]);
  const [massageOwnershipValue, setMassageOwnershipValue] = useState(' ');
  const [massageOwnerShip, setMassageOwnerShip] = useState(false);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
  const formatEnDate = useFormatEnDate();

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  const onChangeSelect = (data) => {
    if (data.questionType == 'CPCustomerInfo-Single Select' && data.questionCode == 'MassageOwnershipType') {
      setDirtyMassageOwnnerShip(true);

      data.displayName?.toLowerCase() === 'no' ? setMassageOwnerShip(false) : setMassageOwnerShip(true);

      setSelectedMassageOwnerShip([]);
    }

    dispatch(
      storeCPCustomer({
        key: data.questionCode,
        value: data.value,
        type: data.questionType,
        answerId: data.answerId
      })
    );
  };

  const generateDropdownOption = (data) => {
    let arr = data.map((item) => {
      return { value: item.fields.Answer.value, label: item.fields.Answer.value };
    });

    return arr;
  };

  const getContactPersonal = (e, type) => {
    if (e.type == 'mousedown') {
      !clientTellingPortalCustomer[`${type}`] &&
        dispatch(getContactPersonalInformation({ id: SitecoreContextFactoryService.getValueContextItem('cpCustomerId'), type }));

      type == 'PhoneNumber' && setPhoneApperance(true);
    } else {
      type == 'PhoneNumber' && setPhoneApperance(false);
    }
  };

  const onSelectMassageOwnership = (option) => {
    setSelectedMassageOwnerShip(option);

    setMassageOwnershipValue(option?.map((item) => item.value).join(', ') || '');

    const selectedMultiOptionConvert = Global.onGetSelectedMultiOption(option || [], selectedMassageOwnerShip);

    dispatch(
      storeCPCustomer({
        key: 'MassageOwnership',
        value: selectedMultiOptionConvert?.value,
        type: 'CPCustomerInfo-Multi Select',
        answerId: selectedMultiOptionConvert?.answerId
      })
    );
  };

  useEffect(() => {
    const model = new ReviewCustomerInformationModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useEffect(() => {
    const cpCustomerInfo = clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo;
    const answerValueSelected = cpCustomerInfo?.MassageOwnershipType || '';

    const answerValueSelectedDisplayName = getAnswerSelectedDisplayName({
      answerValueSelected,
      questionsList: layoutData?.Questions || []
    });
    if (answerValueSelectedDisplayName === 'no') {
      setMassageOwnerShip(false);

      setDirtyMassageOwnnerShip(true);

      if (cpCustomerInfo?.MassageOwnership?.trim()) {
        dispatch(
          storeCPCustomer({
            type: NO_MASSAGE_OWNER_OPTION_TYPE,
            key: NO_MASSAGE_OWNER_OPTION_KEY
          })
        );
      }
    } else if (answerValueSelected?.length) {
      setMassageOwnerShip(true);

      setDirtyMassageOwnnerShip(true);

      const massageOwnershipValue = clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnership;
      if (massageOwnershipValue && massageOwnershipValue.length) {
        const splitMassageOwnerShipValue = massageOwnershipValue?.split(', ') || [];
        if (splitMassageOwnerShipValue.length) {
          let selectedMassageOwnerShipTemp = [];
          for (const [index, item] of splitMassageOwnerShipValue.filter((item) => item !== ' ').entries()) {
            selectedMassageOwnerShipTemp.push({
              value: item,
              label: item,
              answerId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipId?.split(', ')?.[index] || ''
            });
          }

          setSelectedMassageOwnerShip(selectedMassageOwnerShipTemp);
        }
      }
    }
  }, [clientTellingPortalCustomer.tmpCustomerInformation]);

  const onRemoveMassageOwnerTypeBtnClick = (item) => {
    let tempObj = deepCopy(selectedMassageOwnerShip);

    const indexOfItem = tempObj.findIndex((val) => val.value === item.value);

    tempObj.splice(indexOfItem, 1);

    setSelectedMassageOwnerShip(tempObj);

    setMassageOwnershipValue(tempObj?.map((item) => item.value).join(', ') || ' ');

    dispatch(
      storeCPCustomer({
        key: 'MassageOwnership',
        value: tempObj.map((item) => item.value).join(', ') || ' ',
        type: 'CPCustomerInfo-Multi RemoveMassageOwner'
      })
    );
  };

  const isHideCommunication = useMemo(() => {
    return layoutData?.['Is Communication Method Hidden']?.value ?? false;
  }, [layoutData]);

  const dobText = useMemo(() => {
    let value = 'N/A';
    const rawDob = clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.Birthdate;
    if (rawDob) {
      value = isConvertDate
        ? convertHKTWDate({
          dateString: rawDob,
          isHideYear: true
        })
        : formatEnDate({
          date: rawDob,
          isHideYear: true
        });
    }

    return value;
  }, [clientTellingPortalCustomer, isConvertDate]);

  const selectedOwnerTypeValuesList = useMemo(
    () => [...selectedMassageOwnerShip].sort((a, b) => a.label - b.label).reverse(),
    [selectedMassageOwnerShip]
  );

  const accountFullName = useMemo(() => {
    const firstName = clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.FirstName || '';
    const lastName = clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.LastName || '';

    return getUserFullNameWithoutFormat({ firstName, lastName });
  }, [clientTellingPortalCustomer]);

  return layoutData ? (
    <>
      {clientTellingPortalCustomer.isLoading ? (
        <ReviewCustomerInformationShimmer layoutData={layoutData} />
      ) : (
        <div className='cp-review__content__info'>
          <Text field={layoutData['Title']} className='cp-review__content__info__title' tag='h5' />
          <div className='cp-review__content__info__box'>
            <form className='cp-form cp-form--existing-cus'>
              <h4 className='customer-info__form__cus-name'>
                {accountFullName
                  ? accountFullName || ''
                  : clientTellingPortalCustomer?.contacts?.[0]?.contact_type !== 'PDPA lead' && 'Anonymous'}
              </h4>
              <div className='cp-form__wrap'>
                <div className='cp-form__left'>
                  <div className='form-group d-flex'>
                    <div className='cp-form__icon-left'>
                      <Image field={layoutData['Mail Icon']} />
                    </div>
                    <div className='cp-form__group d-flex'>
                      <span className='cp-form__value-text mail-value'>
                        {clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Email?.PreferredEmail?.SmtpAddress || 'N/A'}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='cp-form__right'>
                  <div className='form-group d-flex'>
                    <div className='cp-form__icon-left'>
                      <Image field={layoutData['Phone Icon']} />
                    </div>
                    <div className='cp-form__group d-flex'>
                      <span className='cp-form__value-text phone-value'>
                        {clientTellingPortalCustomer.PhoneNumber ||
                        clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.PhoneNumbers?.PreferredPhoneNumber?.Number
                          ? clientTellingPortalCustomer?.customerInformation?.PrefixPhoneNumber || ''
                          : ''}
                        {phoneApperance
                          ? clientTellingPortalCustomer.PhoneNumber
                          : clientTellingPortalCustomer?.customerInformation?.PhoneNumber || 'N/A'}
                      </span>
                      <span
                        className='cp-icon cp-icon--eye'
                        onMouseDown={(e) => getContactPersonal(e, 'PhoneNumber')}
                        onMouseUp={(e) => getContactPersonal(e, 'PhoneNumber')}
                      />
                    </div>
                  </div>
                  <div className='form-group form-group--mb-30 d-flex d-flex--space-between '>
                    <ul className='cp-form__social-icon'>
                      <li className='cp-form__social-icon__item cp-checkbox-icon'>
                        <label
                          className={
                            clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWeChat &&
                            'cp-checkbox-icon__icon is-checked'
                          }
                        >
                          <Image field={layoutData['Message Button Icon']} />
                        </label>
                      </li>
                      <li className='cp-form__social-icon__item cp-checkbox-icon'>
                        <label
                          className={
                            clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWhatsapp &&
                            'cp-checkbox-icon__icon is-checked'
                          }
                        >
                          <Image field={layoutData['Dial Button Icon']} />
                        </label>
                      </li>
                      {!isHideCommunication ? (
                        <li className='cp-form__social-icon__item cp-checkbox-icon'>
                          <label
                            className={
                              clientTellingPortalCustomer.customerInformation.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowSMS &&
                              'cp-checkbox-icon__icon is-checked'
                            }
                          >
                            <Image field={layoutData['SMS Icon']} />
                          </label>
                        </li>
                      ) : (
                        <></>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='cp-form__wrap'>
                <div className='cp-form__left'>
                  <div className='form-group d-flex'>
                    <div className='cp-form__icon-left'>
                      <Image field={layoutData['Birthday Icon']} />
                    </div>
                    <div className='cp-form__group'>
                      <span className='cp-form__value-text'>
                        {/*{clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.Birthdate*/}
                        {/*  ? ['0001'].every((item) => clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.Birthdate.includes(item))*/}
                        {/*    ? clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.Birthdate.replace('0001', '')*/}
                        {/*    : clientTellingPortalCustomer?.contacts?.[0]?.json_facet?.Personal?.Birthdate*/}
                        {/*  : 'N/A'}*/}
                        {dobText}
                      </span>
                    </div>
                  </div>
                  {layoutData.Questions.map((q, iq) => {
                    return (
                      <Fragment key={iq}>
                        {iq % 2 != 0 ? (
                          <>
                            <div className='form-group d-flex'>
                              <div className='cp-form__icon-left'>
                                <Image field={q.Image} />
                              </div>
                              <div className='cp-form__group'>
                                {q['Display Type'] == 'Drop Down' ? (
                                  <CPSelect
                                    selectId='selectId'
                                    onChangeSelect={onChangeSelect}
                                    options={generateDropdownOption(q.Answers)}
                                    selectPlaceholder={q?.['Question']}
                                    questionCode={q['Question Code']}
                                    questionType={q['Display Type']}
                                  />
                                ) : q['Question Code'] == 'Gender' && ReferPOS ? (
                                  <span key={iq} className='cp-form__value-text'>
                                    N/A
                                  </span>
                                ) : (
                                  <>
                                    {q.Answers.map((a, ia) => {
                                      return (
                                        <div className='cp-form__group__item' key={ia}>
                                          <CPInput
                                            inputName={q['Display Type'] == 'Single Select' ? `input-radio-${q.Question}` : `input-radio-${a.id}`}
                                            isRectangle={true}
                                            inputType={
                                              q['Display Type'] == 'Single Select' ? TYPE_RADIO : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                            }
                                            inputId={`input-radio-${a.id}`}
                                            labelData={a.fields.Answer.value}
                                            layoutServiceData={a.fields}
                                            inputChange={onChangeSelect}
                                            questionCode={q['Question Code']}
                                            questionType={q['Display Type']}
                                            isChecked={
                                              q['Question Code'] == 'Gender'
                                                ? clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.GenderId?.split(
                                                  ', '
                                                ).includes(a.id)
                                                  ? true
                                                  : false
                                                : clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}Id`]
                                                  ?.split(', ')
                                                  .includes(a.id)
                                                  ? true
                                                  : false
                                            }
                                            displayName={a?.displayName}
                                            answerId={a.id}
                                          />
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                            {q['Question Code'] == 'MassageOwnershipType' && dirtyMassageOwnerShip && massageOwnerShip ? (
                              <div className='form-group d-flex'>
                                <div className='cp-form__icon-left'></div>
                                <div className='cp-form__group'>
                                  {/*<Select*/}
                                  {/*  options={layoutData['Massage Ownership Types'].map((item) => {*/}
                                  {/*    return {*/}
                                  {/*      value: item.fields.Value.value,*/}
                                  {/*      label: item.fields.Value.value*/}
                                  {/*    };*/}
                                  {/*  })}*/}
                                  {/*  className='cp-select'*/}
                                  {/*  classNamePrefix='cp-select'*/}
                                  {/*  value={selectedMassageOwnerShip}*/}
                                  {/*  onChange={(option) => onSelectMassageOwnership(option)}*/}
                                  {/*  isSearchable={false}*/}
                                  {/*  isMulti*/}
                                  {/*  isDisabled={massageOwnerShip ? false : true}*/}
                                  {/*/>*/}
                                  <Select
                                    options={layoutData['Massage Ownership Types'].map((item) => {
                                      return {
                                        value: item.fields.Value.value,
                                        label: item.fields.Value.value
                                      };
                                    })}
                                    className='cp-select cp-select__customize'
                                    classNamePrefix='cp-select'
                                    value={selectedOwnerTypeValuesList}
                                    onChange={onSelectMassageOwnership}
                                    isSearchable={true}
                                    isMulti
                                    isDisabled={massageOwnerShip ? false : true}
                                  />
                                  <div className='cp-select__customize-selected__item'>
                                    {selectedOwnerTypeValuesList.length > 0 &&
                                      selectedOwnerTypeValuesList.map((val, index) => (
                                        <div key={index} className='item'>
                                          <p className='item-text'>{val.label}</p>
                                          <div className='item-remove__icon icon-close' onClick={() => onRemoveMassageOwnerTypeBtnClick(val)}></div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <Fragment key={iq}></Fragment>
                        )}
                      </Fragment>
                    );
                  })}
                  {/* <div className='form-group d-flex'>
                          <div className='cp-form__icon-left'></div>
                          <div className='cp-form__group'>
                            <CPSelect selectId='selectId' onChangeSelect={onChangeSelect()} options={options} selectPlaceholder='Massage chair' />
                          </div>
                        </div> */}
                </div>
                <div className='cp-form__right'>
                  {layoutData.Questions.map((q, iq) => {
                    return (
                      <Fragment key={iq}>
                        {iq % 2 == 0 ? (
                          <>
                            <div className='form-group d-flex'>
                              <div className='cp-form__icon-left'>
                                <Image field={q.Image} />
                              </div>
                              <div className='cp-form__group'>
                                {q['Display Type'] == 'Drop Down' ? (
                                  <CPSelect
                                    selectId='selectId'
                                    onChangeSelect={onChangeSelect}
                                    options={generateDropdownOption(q.Answers)}
                                    selectPlaceholder={q?.['Question']}
                                    questionCode={q['Question Code']}
                                    questionType={q['Display Type']}
                                  />
                                ) : q['Question Code'] == 'Gender' && ReferPOS ? (
                                  <span key={iq} className='cp-form__value-text'>
                                    N/A
                                  </span>
                                ) : (
                                  <>
                                    {q.Answers.map((a, ia) => {
                                      return (
                                        <div className='cp-form__group__item' key={ia}>
                                          <CPInput
                                            inputName={q['Display Type'] == 'Single Select' ? `input-radio-${q.Question}` : `input-radio-${a.id}`}
                                            isRectangle={true}
                                            inputType={
                                              q['Display Type'] == 'Single Select' ? TYPE_RADIO : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                            }
                                            inputId={`input-radio-${a.id}`}
                                            labelData={a.fields.Answer.value}
                                            layoutServiceData={a.fields}
                                            inputChange={onChangeSelect}
                                            questionCode={q['Question Code']}
                                            questionType={q['Display Type']}
                                            isDisabled={q['Question Code'] == 'Gender' ? isSyncFromPos || ReferPOS : false}
                                            isChecked={
                                              q['Question Code'] == 'Gender'
                                                ? clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.GenderId?.split(
                                                  ', '
                                                ).includes(a.id)
                                                  ? true
                                                  : false
                                                : clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}Id`]
                                                  ?.split(', ')
                                                  .includes(a.id)
                                                  ? true
                                                  : false
                                            }
                                            displayName={a?.displayName}
                                            answerId={a.id}
                                          />
                                        </div>
                                      );
                                    })}
                                  </>
                                )}
                              </div>
                            </div>
                            {q['Question Code'] == 'MassageOwnershipType' && dirtyMassageOwnerShip && massageOwnerShip ? (
                              <div className='form-group d-flex'>
                                <div className='cp-form__icon-left'></div>
                                <div className='cp-form__group'>
                                  {/*<Select*/}
                                  {/*  options={layoutData['Massage Ownership Types'].map((item) => {*/}
                                  {/*    return {*/}
                                  {/*      value: item.fields.Value.value,*/}
                                  {/*      label: item.fields.Value.value*/}
                                  {/*    };*/}
                                  {/*  })}*/}
                                  {/*  className='cp-select'*/}
                                  {/*  classNamePrefix='cp-select'*/}
                                  {/*  value={selectedMassageOwnerShip}*/}
                                  {/*  onChange={(option) => onSelectMassageOwnership(option)}*/}
                                  {/*  isSearchable={false}*/}
                                  {/*  isMulti*/}
                                  {/*  isDisabled={massageOwnerShip ? false : true}*/}
                                  {/*/>*/}
                                  <Select
                                    options={layoutData['Massage Ownership Types'].map((item) => {
                                      return {
                                        value: item.fields.Value.value,
                                        label: item.fields.Value.value,
                                        answerId: item.id
                                      };
                                    })}
                                    className='cp-select cp-select__customize'
                                    classNamePrefix='cp-select'
                                    value={selectedOwnerTypeValuesList}
                                    onChange={onSelectMassageOwnership}
                                    isSearchable={true}
                                    isMulti
                                    isDisabled={massageOwnerShip ? false : true}
                                  />
                                  <div className='cp-select__customize-selected__item'>
                                    {selectedOwnerTypeValuesList.length > 0 &&
                                      selectedOwnerTypeValuesList.map((val, index) => (
                                        <div key={index} className='item'>
                                          <p className='item-text'>{val.label}</p>
                                          <div className='item-remove__icon icon-close' onClick={() => onRemoveMassageOwnerTypeBtnClick(val)}></div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <></>
                            )}
                          </>
                        ) : (
                          <Fragment key={iq}></Fragment>
                        )}
                      </Fragment>
                    );
                  })}
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  ) : (
    <></>
  );
};

ReviewCustomerInformation.propTypes = {
  fields: object
};

export default ReviewCustomerInformation;
