import { useCallback } from 'react';

import { addCurlyBrackets } from '@redux/epic/bookingExperience/utils';

export const useTimeSlotGenerator = () => {
  return useCallback((timeSlotId, timeSlotData) => {
    return timeSlotData?.length
      ? timeSlotData.find((timeslot) => timeslot.Id.toLowerCase() === addCurlyBrackets(timeSlotId)?.toLowerCase())?.Name || ''
      : '';
  }, []);
};
