import './AbandonedCartWidget.scss';

import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { useWindowSize } from '@utils/customsHook/useWindowSize';

import LoadingMask from '../Share/LoadingMask';
import AbandonedCartWidgetModel from './models/AbandonedCartWidgetModel';
import ViewCartButton from './ViewCartButton';

const AbandonedCartWidget = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const { currentCart, isLoading } = useSelector((state) => state.productReducer.cartDetailReducer);
  const windowSize = useWindowSize();

  const firstProductInLines = useMemo(() => {
    return currentCart?.Lines?.filter((line) => !line.IsFreeGiftPopup)?.[0];
  }, [currentCart?.Lines]);

  const cartLinesLength = useMemo(() => {
    const quantityArray = currentCart?.Lines?.map((lineItem) => +lineItem.Quantity);

    return quantityArray?.reduce((total, quantity) => total + quantity, 0);
  }, [currentCart?.Lines]);

  useEffect(() => {
    let model = new AbandonedCartWidgetModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData && cartLinesLength && firstProductInLines ? (
    <div className='container abandoned-card-widget'>
      <div className='my-account-section'>
        <div className='my-account-section__heading animated-slow fadeIn delay-100 abandoned-card-widget__heading'>
          <div className='my-account-section__group'>
            <Text tag='h3' field={layoutData['Title']} className='my-account-section__heading__title' />
          </div>
        </div>
        {!isLoading ? (
          <>
            <div className='abandoned-card-widget__content'>
              <div className='content-image'>
                <div className='content-image__container'>
                  <img src={firstProductInLines?.Image} alt={firstProductInLines?.DisplayName} />
                </div>
              </div>
              <div className='content-wrapper'>
                {cartLinesLength === 1 ? (
                  <h2 className='product-name'>{firstProductInLines?.DisplayName}</h2>
                ) : (
                  <Text
                    tag='h2'
                    field={{
                      value: layoutData?.['Display Product Name']?.value
                        ?.replace('{DisplayName}', firstProductInLines?.DisplayName)
                        .replace('{ProductCount}', cartLinesLength - 1)
                    }}
                    className='product-name'
                  />
                )}
                <p className='abandoned-card-widget__content-description'>
                  <RichText field={{ value: layoutData?.['Description 1']?.value?.replace('{count_lines}', cartLinesLength) }} />{' '}
                  {windowSize >= IPAD_BREAKPOINT ? <ViewCartButton layoutData={layoutData} /> : <></>}
                </p>
              </div>
            </div>
            {windowSize < IPAD_BREAKPOINT ? <ViewCartButton layoutData={layoutData} /> : <></>}
          </>
        ) : (
          <LoadingMask />
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

AbandonedCartWidget.propTypes = {
  fields: object
};

export default AbandonedCartWidget;
