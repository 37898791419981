import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import SliderBannersItemModel from './SliderBannersItemModel';

export default class SliderBannersModel {
  constructor() {
    this['Auto Slide Time'] = new SitecoreTextModel();

    this['Bullets Color'] = new SitecoreTextModel();

    this['items'] = [];

    this['Slider Banner Id'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Auto Slide Time')) {
      this['Auto Slide Time'].getData(dataSource['Auto Slide Time']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Bullets Color')) {
      this['Bullets Color'].getData(dataSource['Bullets Color']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let newItemsList = [];

      dataSource['items'].map((element) => {
        newItemsList.push(new SliderBannersItemModel().getData(deepCopy(element) || {}));
      });

      this['items'] = newItemsList;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Slider Banner Id')) {
      this['Slider Banner Id'].getData(dataSource['Slider Banner Id']);
    }

    return this;
  }
}
