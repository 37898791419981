export const ACTION_RESEND_MAIL_ACTIVE = '/Account/sendActivationEmail';

export const  ACTION_LOGIN_SOCIAL = '/SocialAccount/login';

export const  ACTION_LOGIN_SOCIAL_ENABLE = '/socialaccount/enable';

export const  ACTION_LOGIN_SOCIAL_DISABLE = '/socialaccount/disable';

export const ACTION_LOGIN = '/Account/login';

export const ACTION_LOGOUT = '/Account/logout';

export const ACTION_REGISTER = '/Account/registration';

export const ACTION_REGISTER_ORDER = '/Account/RegisterCustomerFromOrder';

export const ACTION_CHANGE_PASSWORD = '/Account/SetPassword';

export const TYPE_GOOGLE = 'google';

export const TYPE_FACEBOOK = 'facebook';

export const ACTION_FORGOT_PASSWORD = '/Account/ForgotPassword';

export const RENDERING_ITEM_ID = '{0200DA86-ECA7-4E6A-A657-EE28E05865E5}';

export const ACTION_GET_USER_PROFILE = '/AccountProfile/GetProfile';

export const ACTION_CP_LOGIN = '/Account/cplogin';

export const ACTION_CP_LOGOUT = '/Account/logout';

export const ACTION_CP_VERIFY_LOGIN = '/Account/cpValidateAndLogin';

export const ACTION_RESEND_LOGIN_OTP = '/Account/cpResendLoginValidateCode';