export default class CurrencyModel {
  constructor() {
    this.Name = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this['Name'] = dataSource['Name'] ?? this.Name;
    }

    return this;
  }
}
  