import './ErrorComponent.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const ErrorComponent = (props) => {
  return (
    <div className='error-component animated-slow fadeIn delay-100'>
      {props.children}
    </div>
  );
};

ErrorComponent.propTypes = {
  props: Proptypes.any,
  children: Proptypes.any,
};

export default ErrorComponent;