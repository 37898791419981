import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

const CartAdditionalOptionItem = ({ dataSources, additionalOptionInfo, freeGiftPomotion }) => {
  const [allowedOption, setAllowedOption] = useState(null);

  useEffect(() => {
    setAllowedOption(
      dataSources?.['Additional Options Setting']?.fields?.['Allowed Options'].find((option) => {
        return option?.fields?.Relationship?.fields?.['Field Name']?.value === additionalOptionInfo.RelationshipId;
      })
    );
  }, []);

  return allowedOption ? (
    <div className='cart-products__item__additional-options justify-space-between'>
      <div className='cart-products__item__additional-options__contents'>
        <div className='product-info-icon--additional-options'>
          <Image field={allowedOption?.fields?.Icon} />
        </div>
        <span>{additionalOptionInfo.DisplayName} {+freeGiftPomotion > 1 ? `x ${freeGiftPomotion}` : ''}</span>
      </div>
      <div className='cart-products__item__warranty__price'>
        {additionalOptionInfo.LineFinalTotalAmount !== 0 ? additionalOptionInfo.LineTotal : <Text field={dataSources['Free Text']} />}
      </div>
    </div>
  ) : (
    <></>
  );
};

CartAdditionalOptionItem.propTypes = {
  additionalOptionInfo: PropTypes.object,
  dataSources: PropTypes.object,
  freeGiftPomotion: PropTypes.string
};

export default CartAdditionalOptionItem;
