import PropTypes, { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { hideSelectingFreeGiftPopup, showSelectingFreeGiftPopup } from '@redux/actions/controlSelectingFreeGiftPopup';
import { getFreeGiftDataStart, setFreeGiftDataNull } from '@redux/actions/getFreeGiftData';
import { promotionInfoService } from '@services/promotionInfoService';
import { useDeepEffect } from '@utils/customsHook/useDeepEffect';

import { SelectingFreeGiftContext } from './useSelectingFreeGift';

const SelectingFreeGiftProvider = ({ children, currentCart }) => {
  const dispatch = useDispatch();
  const { freeGiftData: freeGiftDataReducer } = useSelector((state) => state.getFreeGiftReducer);
  const [selectedFreeGift, setSelectedFreeGift] = useState([]);
  const [freeGiftFromCartLines, setFreeGiftFromCartLines] = useState([]);
  const [editingFreeGift, setEditingFreeGift] = useState([]);
  const [freeGiftDataSort, setFreeGiftDataSort] = useState([]);

  useDeepEffect(() => {
    const promotionIds = currentCart?.FreeGiftPopup?.PromotionIds;
    if (promotionIds?.length) {
      // INFO: reset freeGiftData data
      dispatch(setFreeGiftDataNull());

      // INFO: clear free-gift data before fetch new data
      setFreeGiftDataSort([]);

      // INFO: clear free-gift from cart lines data before fetch new data
      setFreeGiftFromCartLines([]);

      // INFO: get free gift by promotion ids
      dispatch(getFreeGiftDataStart(promotionIds));
    }
  }, [currentCart?.FreeGiftPopup?.PromotionIds]);

  useEffect(() => {
    if (currentCart?.Lines?.length) {
      const lines = currentCart.Lines;
      let freeGiftTempData = [];
      let freeGiftLinesTempData = [];

      for (const line of lines) {
        if (line.PromotionFreeGiftPopupId) {
          if (freeGiftLinesTempData.length) {
            const freeGiftExist = freeGiftLinesTempData.find(
              (item) => item.PromotionFreeGiftPopupId === line.PromotionFreeGiftPopupId && item.ProductId === line.ProductId
            );
            if (freeGiftExist) {
              const freeGiftExistIndex = freeGiftLinesTempData.indexOf(freeGiftExist);

              freeGiftLinesTempData[freeGiftExistIndex] = {
                ...freeGiftFromCartLines[freeGiftExistIndex],
                ...line
              };
            } else {
              freeGiftLinesTempData.push(line);
            }

            freeGiftTempData = [...freeGiftLinesTempData];
          } else {
            freeGiftTempData = [...freeGiftTempData, { ...line }];
          }
        }
      }

      setFreeGiftFromCartLines(freeGiftTempData);
    }
  }, [currentCart?.Lines]);

  useEffect(() => {
    const freeGiftData = freeGiftDataReducer?.Data?.FreeGifts || [];

    if (freeGiftData.length) {
      (async () => {
        let freeGiftDataSortTemp = [];
        for (let freeGift of freeGiftData) {
          if (!freeGiftDataSortTemp.find((item) => item.PromotionId === freeGift.PromotionId)) {
            const promotionInfoFetcher = await promotionInfoService(freeGift.PromotionId);

            freeGiftDataSortTemp = [
              ...freeGiftDataSortTemp,
              {
                PromotionId: freeGift.PromotionId,
                PromotionTitle: promotionInfoFetcher?.Promotion?.Title,
                slotData: [
                  {
                    Slot: freeGift.Slot,
                    products: [
                      {
                        ProductId: freeGift.ProductId,
                        ProductName: freeGift.ProductSummary.ProductName,
                        variantData: [...freeGift.Variants]
                      }
                    ]
                  }
                ]
              }
            ];
          } else {
            const promotionExistData = freeGiftDataSortTemp.find((product) => product.PromotionId === freeGift.PromotionId);
            const slotExistInData = promotionExistData.slotData.find((slot) => slot.Slot === freeGift.Slot);

            if (slotExistInData) {
              const productExistInSlot = slotExistInData.products.find((product) => product.ProductId === freeGift.ProductId);

              if (productExistInSlot) {
                productExistInSlot.variantData = [...productExistInSlot.variantData, ...freeGift.Variants];
              } else {
                slotExistInData.products = [
                  ...slotExistInData.products,
                  {
                    ProductId: freeGift.ProductId,
                    ProductName: freeGift.ProductSummary.ProductName,
                    variantData: [...freeGift.Variants]
                  }
                ];
              }
            } else {
              promotionExistData.slotData = [
                ...promotionExistData.slotData,
                {
                  Slot: freeGift.Slot,
                  products: [
                    {
                      ProductId: freeGift.ProductId,
                      ProductName: freeGift.ProductSummary.ProductName,
                      variantData: [...freeGift.Variants]
                    }
                  ]
                }
              ];
            }
          }
        }

        // INFO: setState for free gift data
        setFreeGiftDataSort(freeGiftDataSortTemp);
      })();
    }
  }, [freeGiftDataReducer]);

  useEffect(() => {
    let selectFreeGiftTemp = [];
    for (const item of freeGiftDataSort) {
      const promotionExistInCartLines = freeGiftFromCartLines.filter((freeGift) => freeGift.PromotionFreeGiftPopupId === item.PromotionId);

      if (promotionExistInCartLines.length) {
        for (const promotionItem of promotionExistInCartLines) {
          for (const slotItem of item.slotData) {
            const productExistInPromotion = slotItem.products.find((product) => product.ProductId === promotionItem.ProductId);

            if (productExistInPromotion) {
              const variantSelected = productExistInPromotion.variantData.find((variant) => variant.VariantId === promotionItem.VariantId);

              if (variantSelected) {
                selectFreeGiftTemp = [
                  ...selectFreeGiftTemp,
                  {
                    PromotionId: item.PromotionId,
                    ProductId: productExistInPromotion.ProductId,
                    LineUniqueId: promotionItem.ExternalCartLineId,
                    Slot: slotItem.Slot,
                    freeGiftInfo: productExistInPromotion,
                    variant: variantSelected
                  }
                ];
              }
            }
          }
        }
      }
    }

    setSelectedFreeGift(selectFreeGiftTemp);
  }, [freeGiftDataSort, freeGiftFromCartLines]);

  useEffect(() => {
    // INFO: show select gift popup when promotion changes
    const checkFreeGiftExistInCart = freeGiftDataSort.every((freeGift) => {
      const slotDataLength = freeGift?.slotData?.length;
      const freeGiftInCartLine = freeGiftFromCartLines.filter((item) => item.PromotionFreeGiftPopupId === freeGift.PromotionId) || [];

      return slotDataLength === freeGiftInCartLine.length;
    });

    if (checkFreeGiftExistInCart) dispatch(hideSelectingFreeGiftPopup());
    else dispatch(showSelectingFreeGiftPopup());
  }, [freeGiftFromCartLines, freeGiftDataSort]);

  // eslint-disable-next-line react/react-in-jsx-scope
  return (
    <SelectingFreeGiftContext.Provider
      value={{
        selectedFreeGift,
        setSelectedFreeGift,
        freeGiftFromCartLines,
        editingFreeGift,
        setEditingFreeGift,
        freeGiftDataSort,
        setFreeGiftDataSort
      }}
    >
      {children}
    </SelectingFreeGiftContext.Provider>
  );
};

SelectingFreeGiftProvider.propTypes = {
  children: any,
  currentCart: PropTypes.object
};

const mapStateToProps = (state) => ({
  currentCart: state.productReducer.cartDetailReducer.currentCart
});

export default connect(mapStateToProps)(SelectingFreeGiftProvider);
