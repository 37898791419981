import SitecoreTextModel from '@models/SitecoreTextModel';

import InstallmentPlanModel from './InstallmentPlanModel';

export default class ProductInstallmentCalculateModel {
  constructor() {
    this['Advance Placeholder'] = new SitecoreTextModel();

    this['Apply Filters Lable'] = new SitecoreTextModel();

    this['Button Label'] = new SitecoreTextModel();

    this['Clear Filters Lable'] = new SitecoreTextModel();

    this['Component Id'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['Monthly Payment Label'] = new SitecoreTextModel();

    this['More Infomation'] = new SitecoreTextModel();

    this['Term Placeholder'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Instalment Plan'] = [];

    this['Interest Text'] = '';

    this['Month Text'] = '';

    this['Product Instalment Plan Title'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Advance Placeholder')) {
      this['Advance Placeholder'].getData(dataSource['Advance Placeholder']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Apply Filters Lable')) {
      this['Apply Filters Lable'].getData(dataSource['Apply Filters Lable']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Label')) {
      this['Button Label'].getData(dataSource['Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Clear Filters Lable')) {
      this['Clear Filters Lable'].getData(dataSource['Clear Filters Lable']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Component Id')) {
      this['Component Id'].getData(dataSource['Component Id']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Monthly Payment Label')) {
      this['Monthly Payment Label'].getData(dataSource['Monthly Payment Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'More Infomation')) {
      this['More Infomation'].getData(dataSource['More Infomation']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Term Placeholder')) {
      this['Term Placeholder'].getData(dataSource['Term Placeholder']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Instalment Plan') && dataSource['Instalment Plan'].length > 0) {
      dataSource['Instalment Plan'].sort((a, b) => a.Month - b.Month);

      dataSource['Instalment Plan'].forEach(item => {
        this['Instalment Plan'].push(new InstallmentPlanModel().getData(item));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Interest Text')) {
      this['Interest Text'] = dataSource['Interest Text'] || '';
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Month Text')) {
      this['Month Text'] = dataSource['Month Text'] || '';
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Instalment Plan Title')) {
      this['Product Instalment Plan Title'] = dataSource['Product Instalment Plan Title'] || '';
    }

    return this;
  }
}
