import './ProductWishlist.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { getProductWishlistAboutUserId } from '@redux/actions/product';
import { getProductWishlist } from '@redux/actions/product';
import Global from '@utils/global';

import ProductWishlistModel from './models/ProductWishlistModel';
import {behaviorSubjectWishlist$} from './MyWishlistBehavior';
import MyWishListItemShimmer from './MyWishListItemShimmer';
import ProductWishlistItem from './ProductWishlistItem';

const MyWishlist = ({ fields, isLoginSuccess, listProductWishlist, isLoading }) => {
  const [inputData, setData] = useState(null);
  const dispatch = useDispatch();
  
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  useEffect(() => {
    setData(new ProductWishlistModel().getData(fields || {}));
    const userId = Global.getQueryParams(window.location.href, 'userId');

    behaviorSubjectWishlist$.next({isAlreadyWishList: true});

    dispatch(userId ? getProductWishlistAboutUserId(userId) : getProductWishlist({getFull: true}));

    if (!userId && !isLoginSuccess) {
      window.location.href = settingGlobal?.LoginLink || '/login';
    }
  }, []);

  return (
    inputData && !isLoading ? (
      <div className='wishlist-landing'>
        <div className='container'>
          <ul className='wishlist-landing__content__list animated-slow fadeIn delay-100'>
            {listProductWishlist?.length
              ? listProductWishlist.map((wishlistItem, wishlistIdx) => (
                <ProductWishlistItem
                  wishlistItem={wishlistItem}
                  key={wishlistIdx}
                  inputData={inputData}
                  isOnlyView={Global.getQueryParams(window.location.href, 'userId') ? true : false}
                />
              ))
              : ''}
          </ul>
        </div>
        {/* <div className='wishlist-landing__header__btn'>
          {inputData['Share Your Wishlist Label'].value ? (
            <Link className='wishlist-landing__header__btn__share btn btn-outline-CTA2' field={inputData['Share Your Wishlist Link']}>
              <Text field={inputData['Share Your Wishlist Label']} />
            </Link>
          ) : (
            <Link className='wishlist-landing__header__btn__share btn btn-outline-CTA2' field={inputData['Share Your Wishlist Link']} />
          )}
        </div> */}
      </div>
    ) : (
      <div className='wishlist-landing skeleton__item'>
        <div className='container'>
          <ul className='wishlist-landing__content__list'>
            <LoadingShimmer itemNumb={6}>
              <MyWishListItemShimmer></MyWishListItemShimmer>
            </LoadingShimmer>
          </ul>
        </div>
      </div>
    )
  );
};

MyWishlist.propTypes = {
  fields: PropTypes.object,
  isLoginSuccess: PropTypes.bool,
  listProductWishlist: PropTypes.array,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  listProductWishlist: state.productReducer.productWishlistReducer?.listProductWishlist || [],
  isLoading: state.productReducer.productWishlistReducer.isGetWishListLoading
});

export default connect(mapStateToProps)(MyWishlist);
