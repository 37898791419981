import './LoyaltyLandingPageTable.scss';

import { object } from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import LoyaltyTable from './LoyaltyTable/index';
import LoyaltyLandingPageTableModel from './model/LoyaltyLandingPageTableModel';
import useLoyaltyLandingPageTableData from './useLoyaltyLandingPageTableData';

const LoyaltyLandingPageTable = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  const { fieldArr, tierArr, btnViewMoreText } = useLoyaltyLandingPageTableData(layoutData);

  useEffect(() => {
    const model = new LoyaltyLandingPageTableModel();
  
    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? ( 
    <div className='loyalty-table'>
      <LoyaltyTable btnViewMoreText={btnViewMoreText} fieldArr={fieldArr} tierArr={tierArr}/>
    </div>
  ) : (<></>);
};

LoyaltyLandingPageTable.propTypes = {
  fields: object
};

export default LoyaltyLandingPageTable;