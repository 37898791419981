import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError,map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/cPStoreSelector';
import * as cPStoreSelectorTypes from '@redux/actions/cPStoreSelector/cPStoreSelectorTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';

const cpStoreSelectorEpic = (action$) =>
  action$.pipe(
    ofType(cPStoreSelectorTypes.CP_STORE_SELECTOR),
    switchMap((action) => {
      const params = {
        storeId: action.payload.storeId
      };
      
      return postAsObservable(config.ACTION_CP_STORE_SELECTOR, params).pipe(
        map((res) => {
          if (res.data?.Success) {
            return actions.cpStoreSelectorSuccess({
              ...res.data
            });
          } else {
            return actions.cpStoreSelectorFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.cpStoreSelectorFailed(error.message || 'Failed')))
      );
    })
  );

export default cpStoreSelectorEpic;
