import Proptypes from 'prop-types';
import React, { Fragment } from 'react';

const BOAddToCartButton = ({textFields, onClickAddHandler}) => {

  return (
    <Fragment>
      <span 
        className={'buying-option-header__btn__item btn btn-primary active'}
        onClick={onClickAddHandler}
      >
        {textFields}
      </span>
    </Fragment>
  );
};

BOAddToCartButton.propTypes = {
  textFields: Proptypes.any,
  onClickAddHandler: Proptypes.any,
};

export default BOAddToCartButton;