import '../CustomerProfiles.scss';

import Proptypes from 'prop-types';
import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const CUSTOMER_PARAM_ID = 'contactId';

export const linkCustomerViewPage = (fields) => {
  const contactId = new URL(window.location.href).searchParams.get(CUSTOMER_PARAM_ID) || '';
  const linkData = fields?.['Link']?.value.href || '';

  return {
    value: {
      href: contactId && linkData ? `${linkData}?${CUSTOMER_PARAM_ID}=${contactId}` : ''
    }
  };
};

const CustomerProfileContainer = (props) => {
  return (
    <div className='profiles'>
      <div className='container tablet-container'>
        <div className='row profiles__row'>
          <Placeholder name='cp-customer-profile' rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

CustomerProfileContainer.propTypes = {
  rendering: Proptypes.any
};

export default CustomerProfileContainer;