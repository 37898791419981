import './MyOrdersWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Share/Loading';
import { getLastestOrders } from '@redux/actions/myOrders';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import {ENUM_STATUS} from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import MyOrdersWidgetModel from './models/MyOrdersWidgetModel';

const DISPLAY_NAME = '{first-product-name}';
const LENGTH = '{other-product-count}';

const getClassForStatus = (statusName) => {
  return ENUM_STATUS.find((item) => item.status === statusName)?.class || '';
};

const MyOrdersWidget = ({ fields }) => {
  const dispatch = useDispatch();
  const [dataSources, setDataSources] = useState(null);
  const [productSummaryLabel, setProductSummaryLabel] = useState(null);
  const ordersLastest = useSelector((state) => state.myOrderReducer.lastestReducer?.lastestOrder);
  const isLoading = useSelector((state) => state.myOrderReducer.lastestReducer.isLoading);

  useDidUpdateEffect(() => {
    const productSummaryLabelTmp = ordersLastest.RemainingProductsInOrder <= 1 ? deepCopy(dataSources['Product Summary Label']) : deepCopy(dataSources['Product Summary Label With Other Product']);

    productSummaryLabelTmp.value = productSummaryLabelTmp.value
      .replace(DISPLAY_NAME, ordersLastest.FirstProductInOrder)
      .replace(LENGTH, ordersLastest.RemainingProductsInOrder);

    setProductSummaryLabel(productSummaryLabelTmp);
  }, [ordersLastest]);

  useEffect(() => {
    dispatch(getLastestOrders());

    setDataSources(new MyOrdersWidgetModel().getData(fields || {}));
  }, []);

  return dataSources ? (
    <div className='my-account-section orders-widget'>
      <div className='my-account-section__heading orders-widget__heading animated-slow fadeIn delay-100'>
        <div className='my-account-section__group'>
          <h3 className='my-account-section__heading__title'>
            <Text field={dataSources['Title']} />
          </h3>
          {ordersLastest ? (
            dataSources['View All Label'].value ? (
              <Link className='my-account-section__btn-link' field={dataSources['View All Link']}>
                <Text field={dataSources['View All Label']} />
              </Link>
            ) : (
              <Link className='my-account-section__btn-link' field={dataSources['View All Link']} />
            )
          ) : (
            <span className='my-account-section__btn-link disable'><Text field={dataSources['View All Label']} /></span>
          )}
        </div>
        {!ordersLastest && !isLoading ? <p className='my-account-section__heading__label'><Text field={dataSources['You Have No New Orders Label']}/></p> : ''}
      </div>
      {isLoading ? <Loading/> : ordersLastest ? (
        <div className='my-account-section__content orders-widget__content animated-slow fadeIn delay-100'>
          <div className='my-account-section__group'>
            <h4 className='orders-widget__content__id orders-widget__content__line'>
              <Text field={dataSources['Order Label']} />
              {ordersLastest.TrackingNumber}
            </h4>
            <a
              href={`${dataSources['View Details Link'].value.href}?OrderId=${ordersLastest.OrderId}`}
              className='my-account-section__content__view-detail orders-widget__content__view-detail'
            >
              {dataSources?.['View Details Text']?.value || ''}
            </a>
          </div>
          <span className='orders-widget__content__order-date orders-widget__content__line'>
            <Text field={dataSources['Order Placed Label']} />: {ordersLastest.OrderDate}
          </span>
          <h5 className='orders-widget__content__product orders-widget__content__line'>
            {productSummaryLabel ? <RichText field={productSummaryLabel} /> : ''}
          </h5>
          <span className='orders-widget__content__price orders-widget__content__line'>
            <Text field={dataSources['Order Total Label']} /> {ordersLastest.TotalOrderPriceWithCurrency}
          </span>
          <div className='orders-widget__content__status'>
            <div className={`orders-widget__content__status__icon status--${ordersLastest.StatusId}`}></div>
            <span className='orders-widget__content__status__text'>{ordersLastest.StatusDisplayName}</span>
          </div>
        </div>
      ) : (
        <div className='my-account-section__content my-account-section__content--empty animated-slow fadeIn delay-100'>
          {dataSources['Explore Osim Products Text'].value ? (
            <Link className='my-account-section__content__link-explore' field={dataSources['Explore Osim Products Link']}>
              <Text field={dataSources['Explore Osim Products Text']} />
            </Link>
          ) : (
            <Link className='my-account-section__content__link-explore' field={dataSources['Explore Osim Products Link']} />
          )}
        </div>
      )}
    </div>
  ) : (
    <div className='my-account-section orders-widget skeleton__item'>
      <div className='my-account-section__heading'>
        <div className='my-account-section__group'>
          <span className='text-line'></span>
          <span className='clear-10'></span>
          <span className='text-line'></span>
        </div>
        <p className='my-account-section__heading__label transparent-wrap'>
          <span className='text-line'></span>
        </p>
      </div>
      <div className='my-account-section__content'>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
      </div> 
    </div>
  );
};

MyOrdersWidget.propTypes = {
  fields: PropTypes.object
};

export default MyOrdersWidget;
