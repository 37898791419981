import { any, bool, string } from 'prop-types';
import React, { Fragment, useMemo } from 'react';

import { QuestionTooltipIcon } from '@components/ProductCardPrice/QuestionTooltipIcon';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useGetMemberUsualText } from '@utils/customsHook/useGetMemberUsualText';

const ProductHeaderPrice = ({
  price,
  isDeposit,
  depositLabel,
  listPriceWithCurrency,
  sellingPriceWithCurrency,
  depositPriceWithCurrency,
  wrapperClassName

}) => {
  const {
    memberText,
    usualText
  } = useGetMemberUsualText();

  const isShowSellingPrice = useMemo(
    () => listPriceWithCurrency !== sellingPriceWithCurrency && !isDeposit,
    [listPriceWithCurrency, sellingPriceWithCurrency, isDeposit]
  );

  const memberPromotionText = useMemo(
    () => memberText && isShowSellingPrice ? memberText : '',
    [memberText, isShowSellingPrice]
  );

  return (
    <div className={wrapperClassName}>
      {price ? (
        <>
          {isDeposit ? (
            <span className='product-detail-header__price__deposit'>{depositLabel}</span>
          ) : (
            <></>
          )}
          {isShowSellingPrice ? (
            usualText ? (
              <span className='product-detail-header__price__selling-price'>{usualText}{listPriceWithCurrency}</span>
            ) : (
              <del className='product-detail-header__price__selling-price'>{listPriceWithCurrency}</del>
            )
          ) : (
            <></>
          )}
          {isDeposit ? (
            <span className='product-detail-header__price__list-price'>{depositPriceWithCurrency}</span>
          ) : (
            <span
              className={[
                'product-detail-header__price__list-price',
                memberPromotionText ? 'with-tooltip' : ''
              ].join(' ')}
            >
              {memberPromotionText}{sellingPriceWithCurrency}{' '}
              {SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Price Extra Information']?.value}
              {memberPromotionText ? <QuestionTooltipIcon /> : <></>}
            </span>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

ProductHeaderPrice.propTypes = {
  price: any,
  isDeposit: bool,
  depositLabel: string,
  listPriceWithCurrency: string,
  sellingPriceWithCurrency: string,
  depositPriceWithCurrency: string,
  wrapperClassName: string
};

export default ProductHeaderPrice;