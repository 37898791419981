import './PaymentOptions.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import PaymentOptionsModel from './Model/PaymentOptionsModel';

const PaymentOptions = (props) => {
  const { fields } = props;
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const paymentOptionsModel = new PaymentOptionsModel();

    setLayoutData(paymentOptionsModel.getData(deepCopy(fields || {})));
  }, []);

  return (
    layoutData && layoutData['Items']?.length ? (
      <div className='payment-options'>
        <div className='container'>
          <div className='payment-options__container'>
            <Text tag='span' field={layoutData['Title']} className='payment-options__title' />
            {layoutData['Items'].map((val, index) => (
              <Image key={index} field={val.fields.Icon} className='image-item' />
            ))}
          </div>
        </div>
      </div>
    ) : ''
  );
};

PaymentOptions.propTypes = {
  fields: PropTypes.any
};

export default PaymentOptions;
