import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import UserInfoModel from '@components/SingleSignOn/models/UserInfoModel';
import * as actions from '@redux/actions/myAccount';
import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';
import { postAsObservable } from '@services/genericService';

import * as config from './config';

export const validateChangeEmailEpic = (action$) =>
  action$.pipe(
    ofType(myAccountTypes.VALIDATE_EMAIL),
    switchMap((action) => {
      return postAsObservable(config.ACTION_CHANGE_EMAIL, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            const userInfoModel = new UserInfoModel().getData(res.data?.Data || {});

            return actions.validateChangeEmailSuccess(userInfoModel);
          } else {
            return actions.validateChangeEmailFailed(res.data?.Errors?.[0]);
          }
        })
      );
    })
  );

export const changeEmailEpic = (action$) =>
  action$.pipe(
    ofType(myAccountTypes.CHANGE_EMAIL),
    switchMap((action) => {
      return postAsObservable(config.ACTION_CHANGE_EMAIL, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.changeEmailSuccess(res.data?.Data);
          } else {
            return actions.changeEmailFailed(res.data?.Errors?.[0]);
          }
        })
      );
    })
  );
  