import './WellnessDetailHeader.scss';

import React, { useEffect, useState } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import WellnessDetailHeaderFieldsModel from './Model/WellnessDetailHeaderFieldsModel';
import WellnessDetailHeaderTagItem from './WellnessDetailHeaderTagItem';

const WellnessDetailHeader = () => {
  const [wellnessDetailHeaderFields, setWellnessDetailHeaderFields] = useState(null);

  useEffect(() => {
    const wellnessDetailHeaderFieldsModel = new WellnessDetailHeaderFieldsModel();

    setWellnessDetailHeaderFields(wellnessDetailHeaderFieldsModel.getData(deepCopy(SitecoreContextFactoryService.getValueContextRouteItem('fields') || {})));
  }, []);

  return wellnessDetailHeaderFields && (
    <div className='wellness-detail-header'>
      <div className='container smaller-container'>
        {
          wellnessDetailHeaderFields.Tags ? (
            <ul className='featured-article-item__tags'>
              {
                wellnessDetailHeaderFields.Tags.map((tag, index) => {
                  return <WellnessDetailHeaderTagItem key={index} tag={tag}/>;
                })
              }
            </ul>
          ) : ''
        }
        <Text field={wellnessDetailHeaderFields.Title} tag='h2' className='wellness-detail-header__title'/>
        <Text field={wellnessDetailHeaderFields.Description} tag='p' className='wellness-detail-header__description'/>
        <div className='featured-article-item__sub-info'>
          <Text field={wellnessDetailHeaderFields['Time To Read']} tag='p'/>
          <Text field={wellnessDetailHeaderFields.PublishedDate} tag='p'/>
        </div>
      </div>
      {
        wellnessDetailHeaderFields.Banner.value.src ? (
          <div className='container no-padding-mobile'>
            <div className='wellness-detail-header__banner'>
              <Image media={wellnessDetailHeaderFields.Banner} />
            </div>
          </div>
        ) : ''
      }
    </div>
  );
};

export default WellnessDetailHeader;
