export default class ShowSalesTaxModel {
  constructor() {
    this.value = false;
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this.value = dataSource.value ?? this.value;
    }

    return this;
  }
}
