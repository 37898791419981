import { useCallback, useMemo } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { convertHKTWDate } from '@utils/customsHook/useFormatDate/utils';
import Global from '@utils/global';

export const useConvertBalanceDate = () => {
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  return useCallback(
    (date) => {
      if (date) {
        let dateConverted = Global.formatDateDDMMMYYYY(date);
        if (isConvertDate) {
          const convertJSONDate = Global.convertJSONDateToNumber(date);

          dateConverted = convertHKTWDate({ dateString: convertJSONDate });
        }

        return dateConverted;
      }

      return '';
    },
    [isConvertDate]
  );
};
