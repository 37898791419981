export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';

export const UPDATE_USER_PROFILE_FAILED = 'UPDATE_USER_PROFILE_FAILED';

export const EMAIL_VALIDATION_CODE = 'EMAIL_VALIDATION_CODE';

export const EMAIL_VALIDATION_CODE_SUCCESS = 'EMAIL_VALIDATION_CODE_SUCCESS';

export const EMAIL_VALIDATION_CODE_FAILED = 'EMAIL_VALIDATION_CODE_FAILED';

export const VALIDATE_EMAIL = 'VALIDATE_EMAIL';

export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';

export const VALIDATE_EMAIL_FAILED = 'VALIDATE_EMAIL_FAILED';

export const CHANGE_EMAIL = 'CHANGE_EMAIL';

export const CHANGE_EMAIL_SUCCESS = 'CHANGE_EMAIL_SUCCESS';

export const CHANGE_EMAIL_FAILED = 'CHANGE_EMAIL_FAILED';

export const CHANGE_PASSWORD_ACCOUNT = 'CHANGE_PASSWORD_ACCOUNT';

export const CHANGE_PASSWORD_ACCOUNT_SUCCESS = 'CHANGE_PASSWORD_ACCOUNT_SUCCESS';

export const CHANGE_PASSWORD_ACCOUNT_FAILED = 'CHANGE_PASSWORD_ACCOUNT_FAILED';

export const SUBSCRIBE_LETTER = 'SUBSCRIBE_LETTER';

export const SUBSCRIBE_LETTER_SUCCESS = 'SUBSCRIBE_LETTER_SUCCESS';

export const SUBSCRIBE_LETTER_FAILED = 'SUBSCRIBE_LETTER_FAILED';

export const GET_STATUS_SUBSCRIBE_LETTER = 'GET_STATUS_SUBSCRIBE_LETTER';

export const GET_STATUS_SUBSCRIBE_LETTER_SUCCESS = 'GET_STATUS_SUBSCRIBE_LETTER_SUCCESS';

export const GET_STATUS_SUBSCRIBE_LETTER_FAILED = 'GET_STATUS_SUBSCRIBE_LETTER_FAILED';

export const UPDATE_PHONE_NUMBER_SUCCESS = 'UPDATE_PHONE_NUMBER_SUCCESS';