// Import dependencies, operators
import { combineEpics } from 'redux-observable';

import {
  applyCouponCodeEpic,
  applySOCouponCodeEpic,
  getCouponDetailEpic,
  removeCouponCodeEpic,
  removeSOCouponCodeEpic
} from './couponDetail';
import { appliedCouponsListEpic,couponsListEpic } from './couponsList';

const couponEpic = combineEpics(
  couponsListEpic,
  getCouponDetailEpic,
  applyCouponCodeEpic,
  applySOCouponCodeEpic,
  removeCouponCodeEpic,
  appliedCouponsListEpic,
  removeSOCouponCodeEpic
);

export default couponEpic;
