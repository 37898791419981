import './CartItemDelivery.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';

import {useOfferDetails} from '@components/PersonalizedOffer/OfferDetails/OfferDetailsProvider';
import AdditionalFullFilmentOption from '@components/Share/AdditionalFullFilmentOption';
import ModalComponent from '@components/Share/ModalComponent';
import SFExpressPopup from '@components/SlicingPage/CartDetails/SFExpressPopup';
import { changeDeliveryOption } from '@redux/actions/cartDetail';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText,Text } from '@sitecore-jss/sitecore-jss-react';
import { DELIVERY_OPTION } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import {behaviorSubjectCartItem} from '../CartItemBehaviorSubject';

const CartItemDelivery = (props) => {
  const { dataSources, cartItemInfo, outOfStockData, statusUpdateCart } = props;
  const [isShow, setShow] = useState(false);
  const [isUpdateProcessing, setUpdateProcessing] = useState(false);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const { isEditingSOCart } = useSelector((state) => state.cpCartDetailsReducer);
  const { isClientSOCart } = useOfferDetails();
  const dispatch = useDispatch();

  const onSelectDeliveryHandler = (e) => {
    props.selectDeliveryEvt(e.currentTarget.value);

    // INFO: set state for isChangedDeliveryOption show loading mask or loading shimmer at cart detail page after
    // fetch API SetDefaultShippingAddress success
    dispatch(changeDeliveryOption());
  };

  const onCloseModalHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    const behaviorSubjectCartItemSubscription = behaviorSubjectCartItem.subscribe((params) => {
      setUpdateProcessing(params.cartLineIdEditCurrent === cartItemInfo.ExternalCartLineId ? true : false);
    });

    return () => {
      behaviorSubjectCartItemSubscription.unsubscribe();
    };
  }, [behaviorSubjectCartItem]);

  useDidUpdateEffect(() => {
    if(isUpdateProcessing && statusUpdateCart.indexOf('OutOfStock') < 0) {
      setUpdateProcessing(false);
    }
  }, [cartItemInfo, isUpdateProcessing]);

  return (
    <div className='cart-item-delivery'>
      <div className='cart-item-delivery__header'>
        <div className='cart-item-delivery__header__icon'>
          <div className='product-info-icon product-info-icon--delivery'></div>
        </div>
        <div className='cart-item-delivery__header__title'>
          <Text field={dataSources['Fulfillment Option Label']} />
        </div>
      </div>
      <div className='cart-item-delivery__option'>
        {cartItemInfo.DeliveryOptions.map((deliveryItem, deliveryIdx) => {
          return (
            <Fragment key={deliveryItem.ExternalId}>
              <div className={isUpdateProcessing  || outOfStockData  ? 'cart-item-delivery__option__group osim-radio disable' : 'cart-item-delivery__option__group osim-radio'}>
                <input
                  type='radio'
                  name={`delivery-${cartItemInfo.ExternalCartLineId}`}
                  checked={deliveryItem.ExternalId === cartItemInfo.DeliveryOptionId ? true : false}
                  onChange={onSelectDeliveryHandler}
                  id={`${cartItemInfo.ExternalCartLineId}-${deliveryIdx}`}
                  value={deliveryItem.ExternalId}
                  className='osim-radio-input'
                  disabled={isUpdateProcessing || outOfStockData || (isClientTelingUser && !isEditingSOCart) || isClientSOCart ? true : false}
                />
                <label htmlFor={`${cartItemInfo.ExternalCartLineId}-${deliveryIdx}`} className='osim-radio-label'></label>
                <label
                  htmlFor={`${cartItemInfo.ExternalCartLineId}-${deliveryIdx}`}
                  className='cart-item-delivery__option__text link-text link-text--gray-darker'
                >
                  {deliveryItem.Name}
                </label>
              </div>
              {deliveryItem.ShippingOptionType.Value === DELIVERY_OPTION.ShipItem ?
                <Fragment>
                  {cartItemInfo.IsInStockProduct ? <small className='smaller-text'>{cartItemInfo['InStockDeliveryLabel']}</small> :
                    cartItemInfo.IsOutStockProduct ? <small className='smaller-text'>{cartItemInfo['OutStockDeliveryLabel']}</small> :
                      cartItemInfo.IsPreOrderProduct ? <small className='smaller-text'>{cartItemInfo['PreOrderDeliveryLabel']}</small> :
                        cartItemInfo.IsBackOrderProduct ? <small className='smaller-text'>{cartItemInfo['BackOrderDeliveryLabel']}</small> : ''}
                  {!cartItemInfo.IsOutStockProduct ? <AdditionalFullFilmentOption inputData={SitecoreContextFactoryService.getValueContextRouteItem('fields')}/> : ''}
                </Fragment>
                : ''}
              {deliveryItem.ShippingOptionType.Value === DELIVERY_OPTION.SFExpress ?
                <Fragment>
                  <Text className='smaller-text smaller-text--sf-express' field={dataSources['Available Label']} tag='small'/>
                  {/* <Text className='smaller-text smaller-text--inline smaller-text--underline' onClick={() => setShow(true)} tag='small' field={dataSources['SF Express Station Label']} /> */}
                  <RichText className='smaller-text smaller-text--inline' tag='small' field={dataSources['SF Express Station Label']} />
                </Fragment>
                : ''}
              {deliveryItem.ShippingOptionType.Value === DELIVERY_OPTION.PickUpStore ?
                <small className='smaller-text' dangerouslySetInnerHTML={{ __html: deliveryItem.Description }}></small>
                : ''}
              {deliveryItem.ShippingOptionType.Value === DELIVERY_OPTION.DeliveryBySFExpress ?
                <small className='smaller-text' dangerouslySetInnerHTML={{ __html: deliveryItem.Description }}></small>
                : ''}
            </Fragment>
          );
        })}
      </div>
      <ModalComponent isShow={isShow} onCloseHandler={onCloseModalHandler} titlePopup={<Text field={dataSources['SF Express Popup Title']}/>}>
        <SFExpressPopup dataSources={dataSources} />
      </ModalComponent>
    </div>
  );
};

CartItemDelivery.propTypes = {
  dataSources: PropTypes.object,
  deliveryOptions: PropTypes.array,
  selectDeliveryEvt: PropTypes.func,
  cartItemInfo: PropTypes.object,
  outOfStockData: PropTypes.object,
  statusUpdateCart: PropTypes.any
};

const mapStateToProps = (state) => ({
  statusUpdateCart: state.productReducer.cartDetailReducer.statusUpdateCart
});

export default connect(mapStateToProps)(CartItemDelivery);

