import { deepCopy } from '@utils/utility';

import WellnessArticleItemFieldsModel from './WellnessArticleItemFieldsModel';

export default class WellnessArticleItemModel {
  constructor() {
    this['FeaturedImage'] = '';

    this['fields'] = new WellnessArticleItemFieldsModel();

    this['id'] = '';

    this['url'] = '/';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FeaturedImage')) {
      this['FeaturedImage'] = dataSource['FeaturedImage'] ? dataSource['FeaturedImage'] : this['FeaturedImage'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this['id'] = dataSource['id'] ? dataSource['id'] : this['id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'url')) {
      this['url'] = dataSource['url'] ? dataSource['url'] : this['url'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(deepCopy(dataSource['fields']));
    }

    return this;
  }
}
