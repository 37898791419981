import { GET_NOTIFICATION_FAILURE, GET_NOTIFICATION_START, GET_NOTIFICATION_SUCCESS } from './types';

export const getNotificationStart = (payload) => ({
  type: GET_NOTIFICATION_START,
  payload
});

export const getNotificationSuccess = (payload) => ({
  type: GET_NOTIFICATION_SUCCESS,
  payload
});

export const getNotificationFailure = (payload) => ({
  type: GET_NOTIFICATION_FAILURE,
  payload
});
