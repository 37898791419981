import { object } from 'prop-types';
import React, { createRef, useEffect, useMemo, useRef } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import SwipeComponent from '../../Share/SwipeComponent';
import { swiperParams } from './configs';
import { useSetTitleHeight } from './useSetTitleHeight';

const ProductAccessoriesBody = ({ layoutData }) => {
  const productItemTitleRef = useRef([]);
  const setTitleHeightValue = useSetTitleHeight();

  const productsData = useMemo(() => {
    return layoutData?.ProductAccessories?.filter(Boolean);
  }, [layoutData]);

  // INFO: initial ref that related with each item in productsData array
  if (productsData?.length) productItemTitleRef.current = productsData.map((_, index) => productItemTitleRef.current[index] ?? createRef());

  // INFO: make all title of each item has the same height
  useEffect(() => {
    window.addEventListener('resize', () => setTitleHeightValue(productItemTitleRef.current));

    setTitleHeightValue(productItemTitleRef.current);

    return () => window.removeEventListener('resize', () => setTitleHeightValue(productItemTitleRef.current));
  }, [productItemTitleRef.current]);

  return productsData?.length ? (
    <div className='product-accessories__content-body'>
      <div className='product-support-cta__swiper'>
        <div className='product-support-cta__swiper-container'>
          <SwipeComponent param={swiperParams}>
            {productsData.map((product, index) => (
              <div key={product?.ProductName} className='swiper-item product-accessories__swiper-item'>
                <div className='product-accessories__swiper-item__wrapper'>
                  <div className='swiper-image product-accessories__swiper-item__image'>
                    <img src={product?.ProductImage} alt={product?.ProductName} />
                  </div>
                  <h4 ref={productItemTitleRef.current[index]} className='swiper-name product-accessories__swiper-item__name'>
                    {product.ProductName}
                  </h4>
                  <div className='product-accessories__swiper-item__price'>
                    <p className='selling-price'>{product?.ProductSellingPrice}</p>
                    <p className='old-price'>{product?.ProductListPrice}</p>
                  </div>
                  <div className='product-accessories__swiper-item__cta'>
                    <a href={product?.BuyNowPageUrl} className='btn btn-primary buy-now-btn'>
                      <Text field={layoutData?.['Button Text']} />
                    </a>
                    <a href={product?.ProductPageUrl} className='btn btn-link learn-more-btn'>
                      <Text field={layoutData?.['Link Text']} />
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </SwipeComponent>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

ProductAccessoriesBody.propTypes = {
  layoutData: object
};

export default ProductAccessoriesBody;
