import * as cartTypes from './cartTypes';

export const removeCartLine = (payload) => ({
  type: cartTypes.REMOVE_CART_LINE,
  payload
});

export const removeCartLineSuccess = (payload) => ({
  type: cartTypes.REMOVE_CART_LINE_SUCCESS,
  payload
});

export const removeCartLineFailed = (error) => ({
  type: cartTypes.REMOVE_CART_LINE_FAILED,
  error
});

export const removeSOCartLine = (payload) => ({
  type: cartTypes.REMOVE_SO_CART_LINE,
  payload
});

export const removeSOCartLineSuccess = (payload) => ({
  type: cartTypes.REMOVE_SO_CART_LINE_SUCCESS,
  payload
});

export const removeSOCartLineFailed = (error) => ({
  type: cartTypes.REMOVE_SO_CART_LINE_FAILED,
  error
});

export const updateCartLine = (payload) => ({
  type: cartTypes.UPDATE_CART_LINE,
  payload
});

export const updateCartLineSuccess = (payload) => ({
  type: cartTypes.UPDATE_CART_LINE_SUCCESS,
  payload
});

export const updateCartLineFailed = (error) => ({
  type: cartTypes.UPDATE_CART_LINE_FAILED,
  error
});

export const updateSOCartLine = (payload) => ({
  type: cartTypes.UPDATE_SO_CART_LINE,
  payload
});

export const updateSOCartLineSuccess = (payload) => ({
  type: cartTypes.UPDATE_SO_CART_LINE_SUCCESS,
  payload
});

export const updateSOCartLineFailed = (error) => ({
  type: cartTypes.UPDATE_SO_CART_LINE_FAILED,
  error
});

export const setCartFullfillment = (payload) => ({
  type: cartTypes.SET_CART_FULLFILLMENT,
  payload
});

export const setCartFullfillmentSuccess = (payload) => ({
  type: cartTypes.SET_CART_FULLFILLMENT_SUCCESS,
  payload
});

export const setCartFullfillmentFailed = (error) => ({
  type: cartTypes.SET_CART_FULLFILLMENT_FAILED,
  error
});

export const setCartLineFullfillment = (payload) => ({
  type: cartTypes.SET_CART_LINE_FULLFILLMENT,
  payload
});

export const setCartLineFullfillmentSuccess = (payload) => ({
  type: cartTypes.SET_CART_LINE_FULLFILLMENT_SUCCESS,
  payload
});

export const setCartLineFullfillmentFailed = (error) => ({
  type: cartTypes.SET_CART_LINE_FULLFILLMENT_FAILED,
  error
});

export const updateOneCartLine = (payload) => ({
  type: cartTypes.UPDATE_ONE_CART_LINE,
  payload
});

export const updateOneCartLineSuccess = (payload) => ({
  type: cartTypes.UPDATE_ONE_CART_LINE_SUCCESS,
  payload
});

export const updateOneCartLineFailed = (error) => ({
  type: cartTypes.UPDATE_ONE_CART_LINE_FAILED,
  error
});

export const getCartStripAds = (payload) => ({
  type: cartTypes.GET_CART_STRIP_ADS,
  payload
});

export const getCartStripAdsSuccess = (payload) => ({
  type: cartTypes.GET_CART_STRIP_ADS_SUCCESS,
  payload
});

export const getCartStripAdsFailed = (error) => ({
  type: cartTypes.GET_CART_STRIP_ADS_FAILED,
  error
});

export const getPickUpPoint = (payload) => ({
  type: cartTypes.PICK_UP_POINT,
  payload
});

export const getPickUpPointSuccess = (payload) => ({
  type: cartTypes.PICK_UP_POINT_SUCCESS,
  payload
});

export const getPickUpPointFailed = (error) => ({
  type: cartTypes.PICK_UP_POINT_FAILED,
  error
});

export const addStaff = (payload) => ({
  type: cartTypes.ADD_STAFF,
  payload
});

export const addStaffSuccess = (payload) => ({
  type: cartTypes.ADD_STAFF_SUCCESS,
  payload
});

export const addStaffFailed = (error) => ({
  type: cartTypes.ADD_STAFF_FAILED,
  error
});

export const getCartDelivery = (payload) => ({
  type: cartTypes.GET_CART_DELIVERY,
  payload
});

export const getCartDeliverySuccess = (payload) => ({
  type: cartTypes.GET_CART_DELIVERY_SUCCESS,
  payload
});

export const getCartDeliveryFailed = (error) => ({
  type: cartTypes.GET_CART_DELIVERY_FAILED,
  error
});

export const getPromotionById = (payload) => ({
  type: cartTypes.GET_PROMOTION_BY_ID,
  payload
});

export const getPromotionByIdSuccess = (payload) => ({
  type: cartTypes.GET_PROMOTION_BY_ID_SUCCESS,
  payload
});

export const getPromotionByIdFailed = (error) => ({
  type: cartTypes.GET_PROMOTION_BY_ID_FAILED,
  error
});

export const setErrorMessenger = (payload) => ({
  type: cartTypes.SET_ERROR_MESSENGER,
  payload
});

export const updateCartSeller = (payload) => ({
  type: cartTypes.UPDATE_CART_SELLER,
  payload
});

export const preventAddTradeInProductToCart = (payload) => ({
  type: cartTypes.PREVENT_ADD_TRADE_IN_PRODUCT_TO_CART,
  payload
});

export const blockInstallment = (payload) => ({
  type: cartTypes.SET_BLOCK_INSTALLMENT,
  payload
});

export const validateInstallmentAndCheckout = (show) => ({
  type: cartTypes.BLOCK_INSTALLMENT_CHECK,
  payload : {show : show}
});

export const changeDeliveryOption = () => ({
  type: cartTypes.CHANGE_DELIVERY_OPTION
});
