import { object, string } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const ProductManualDownloadFooter = ({ buttonText, buttonLink }) => {
  return buttonText && buttonLink ? (
    <div className='simple-cta__content-footer'>
      <a href={buttonLink} className='btn btn-primary'>
        <Text field={buttonText} />
      </a>
    </div>
  ) : (
    <></>
  );
};

ProductManualDownloadFooter.propTypes = {
  buttonText: object,
  buttonLink: string
};

export default ProductManualDownloadFooter;
