import './PolicySearchResult.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';

import ErrorComponent from '@components/ErrorComponent';
import AutoCompleteUI from '@components/Share/AutoCompleteUI';
import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import Pagination from '@components/Share/Pagination';
import { commonSearchPolicy,getAutoCompletePolicy } from '@redux/actions/common';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { POLICY_INIT_PAGE, POLICY_PAGESZ } from './configs';
import ProductSearchResultFieldsModel from './model/ProductSearchResultFieldsModel';
import PolicySearchResultItem from './PolicySearchResultItem';
import PolicySearchResultListShimmer from './PolicySearchResultListShimmer';

const PolicySearchResult = (props) => {
  const {fields, policySearchData, policyCount, policySuggestionList, isLoading, errorMsg, isError} = props;

  const dispatch = useDispatch();

  const searchListContainer = useRef(null);
  
  const [isDoneScroll, setIsDoneScroll] = useState(false);

  const [headerHeight, setHeaderHeight] = useState(null);

  const [dataFields, setDataFields] = useState(null);
  
  useEffect(() => {
    const productSearchResultFieldsModel = new ProductSearchResultFieldsModel();
    
    setDataFields(productSearchResultFieldsModel.getData(deepCopy(fields) || {}));
  }, []);

  // Subscribe Header Offset Height
  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  const [params, setParams] = useState({
    searchTerm: '',
    page: POLICY_INIT_PAGE,
    pageSz: POLICY_PAGESZ,
    isDispatch: false
  });

  const [reset, setReset] = useState({
    pagingReset: false,
  });

  const setDoneScroll = () => {
    setIsDoneScroll(true);
  };

  useDidUpdateEffect(() => {
    dispatch(commonSearchPolicy(params));
  }, [params.isDispatch]);

  useDidUpdateEffect(() => {
    if(searchListContainer?.current) {
      Global.scrollToWithCallback(searchListContainer.current.offsetTop - headerHeight, setDoneScroll);
    }
  }, [params.page]);

  useEffect(() => {
    const URLSearchTerm = Global.decodeURLQueryParams(Global.getQueryParamsNoLower(window.location.href, 'Term')) || '';

    setParams({
      ...params,
      searchTerm: URLSearchTerm,
    });

    setIsDoneScroll(true);

    dispatch(
      commonSearchPolicy({
        searchTerm: URLSearchTerm,
        page: POLICY_INIT_PAGE,
        pageSz: POLICY_PAGESZ,
      })
    );
  }, []);

  const onSearchHandler = (value) => {
    setParams({
      ...params, 
      searchTerm: value,
      page: POLICY_INIT_PAGE,
      isDispatch: !params.isDispatch
    });

    setReset({pagingReset: !reset.pagingReset});

    Global.genURLParamAndStayPage([
      {key: 'Term', value: value}
    ]);
  };

  const onChangePageHandler = (pageNum) => {
    setDoneScroll(false);
    
    setParams({
      ...params,
      page: pageNum,
      isDispatch: !params.isDispatch
    });
  };

  return (
    <div className='search-result policy-result'>
      <div className='container'>
        <div className='search-result__heading'>
          <AutoCompleteUI
            searchTerm={params.searchTerm}
            placeholder={dataFields?.['Place Holder Text']?.value || ''}
            suggestionsListData={policySuggestionList}
            onGetSuggestionListHandler={getAutoCompletePolicy}
            onSearchHandler={onSearchHandler}
          />
        </div>

        {isError ? 
          <ErrorComponent>
            {errorMsg}
          </ErrorComponent>
          : (
            <Fragment>
              {!isLoading && dataFields && isDoneScroll ? (
                <div className='search-result__notice'>
                  {policyCount > 0 
                    ? <p className='search-result__notice__text'>{policyCount} {dataFields['Result Message'].value}</p> 
                    : <p className='search-result__notice__text'>{dataFields['No Result Text'].value}</p>}
                </div>
              ) : null}

              <div className='search-result__content' ref={searchListContainer}>
                {!isLoading && dataFields && isDoneScroll ? (
                  <ul className='policy-result__list animated-slow fadeIn delay-100'>
                    { policySearchData?.length
                      ? policySearchData.map((item) => {
                        return (
                          <PolicySearchResultItem key={item.ItemId} dataFromAPI={item} dataFields={dataFields} highlightText={params.searchTerm}/>
                        );
                      }) 
                      : null }
                  </ul>
                )
                  : (
                    <ul className='policy-result__list'>
                      <LoadingShimmer itemNumb={10}>
                        <PolicySearchResultListShimmer></PolicySearchResultListShimmer>
                      </LoadingShimmer>
                    </ul>
                  )
                }
                <Pagination 
                  isReset={reset.pagingReset}
                  itemPerPage={POLICY_PAGESZ} 
                  totalItem={policyCount} 
                  onChangePageHandler={onChangePageHandler}/>
              </div>
            </Fragment>
          )
        }
      </div>
    </div>
  ); 
};

const mapStateToProps = (state) => {
  const policySearchReducer = state.commonSearchReducer.policySearchReducer;

  return {
    policySearchData: policySearchReducer.policySearchData || null,
    policyCount: policySearchReducer.policyCount || 0,
    policySuggestionList: policySearchReducer.policySuggestionList || null,
    isLoading: policySearchReducer.isLoading || null,
    errorMsg: policySearchReducer.errorMsg || '',
    isError: policySearchReducer.isError || false,
  };
};

PolicySearchResult.propTypes = {
  fields: PropTypes.any,
  policySearchData: PropTypes.any,
  policyCount: PropTypes.any,
  policySuggestionList: PropTypes.any,
  isLoading: PropTypes.any,
  errorMsg: PropTypes.any,
  isError: PropTypes.any,
};

export default connect(mapStateToProps)(PolicySearchResult);