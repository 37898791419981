import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { catchError, concatMap, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/productReview';
import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';
import { get, post } from '@services/genericService';

import * as config from '../config';

export const submitProductReviewEpic = (action$) =>
  action$.pipe(
    ofType(productReviewTypes.SUBMIT_PRODUCT_REVIEW),
    switchMap((action) => {
      return from(post(config.ACTION_SUBMIT_PRODUCT_REVIEW, action.payload, { 'Content-Type': 'multipart/form-data' }, 'tradein')).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            return actions.submitProductReviewSuccess(res.data);
          } else {
            return actions.submitProductReviewFailed(res.data);
          }
        }),
        catchError((error) => of(actions.submitProductReviewFailed(error)))
      );
    })
  );

export const serialNumberFormatCheckerEpic = (action$) =>
  action$.pipe(
    ofType(productReviewTypes.SERIAL_NUMBER_FORMAT_CHECKER),
    switchMap((action) => {
      return from(get(config.ACTION_GET_SERIAL_NUMBER_CHECKER, action.payload)).pipe(
        map((res) => {
          if (res.status === 200 ) {
            return actions.serialNumberFormatCheckerSuccess(res.data);
          } else {
            return actions.serialNumberFormatCheckerFailed(res.data);
          }
        }),
        catchError((error) => of(actions.serialNumberFormatCheckerFailed(error)))
      );
    })
  );