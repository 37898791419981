import SitecoreTextModel from '@models/SitecoreTextModel';

import ItemModel from './ItemModel';

export default class ArticleCarouselModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();

    this['Articles'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Articles')) {
      if (dataSource['Articles']?.length) {
        dataSource['Articles'].map((item) => {
          this['Articles'].push(new ItemModel().getData(item || {}));
        });
      }
    }

    return this;
  }
}
