import PropTypes from 'prop-types';
import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { getLayoutElementStyleSelector } from '@redux/reducers/layoutElementStyle/selectors';

import ProductFunctionsDetail, { PRODUCT_SUMMARY_KEY } from '../ProductFunctionsDetail';
import ProductPreview from '../ProductPreview';
import ProductSelect from '../ProductSelect';

const CompareProductItem = forwardRef(({ layoutData, isRecently, componentNumber, categorySelected, productInfo }, ref) => {
  const DEFAULT_SPACING_FROM_SELECT_TO_HEADER = 14;
  const selectProductRef = useRef(null);
  const { cookieBannerHeight, headerHeight } = useSelector(getLayoutElementStyleSelector);
  const [isFreezeOnTop, setIsFreezeOnTop] = useState(false);

  useLayoutEffect(() => {
    const handleFreezeProductSelect = () => {
      const selectProductCurrent = selectProductRef?.current;
      const compareProductItemCurrent = ref?.current;
      const scrollY = window.scrollY;
      if (selectProductCurrent && compareProductItemCurrent) {
        const selectProductOffsetTop = selectProductCurrent.getBoundingClientRect()?.top;
        const compareProductItemWidth = compareProductItemCurrent.offsetWidth;

        const freezeSelectProduct = {
          top: `${headerHeight + cookieBannerHeight}px`,
          padding: `${DEFAULT_SPACING_FROM_SELECT_TO_HEADER}px 0`,
          width: `${compareProductItemWidth}px`,
        };
        if (scrollY >= Number.parseInt(selectProductOffsetTop)) {
          setIsFreezeOnTop(true);
          for (const key of Object.keys(freezeSelectProduct)) {
            selectProductCurrent.style[key] = freezeSelectProduct[key];
          }
        } else {
          setIsFreezeOnTop(false);

          selectProductCurrent.style.top = '';

          selectProductCurrent.style.paddingTop = 'unset';

          selectProductCurrent.style.paddingBottom = '';
        }
      }
    };

    window.addEventListener('scroll', handleFreezeProductSelect);

    window.addEventListener('resize', handleFreezeProductSelect);

    handleFreezeProductSelect();

    return () => {
      window.removeEventListener('scroll', handleFreezeProductSelect);

      window.removeEventListener('resize', handleFreezeProductSelect);
    };
  }, [cookieBannerHeight, headerHeight, selectProductRef, ref, productInfo]);

  return (
    <div ref={ref} className='compare-product__item'>
      {!isRecently && (
        <div ref={selectProductRef} className={`compare-product__item-select-product ${isFreezeOnTop ? 'freeze-on-top' : ''}`}>
          <ProductSelect layoutData={layoutData} categorySelected={categorySelected} componentNumber={componentNumber} />
        </div>
      )}
      <div className='compare-product__item-header'>
        {isRecently ? (
          <div className='product-preview'>
            {productInfo ? (
              <ProductPreview
                layoutData={layoutData}
                componentNumber={componentNumber}
                isDisabledElement={true}
                isEnableName={true}
                productInfo={productInfo}
              />
            ) : (
              <></>
            )}
          </div>
        ) : (
          <div className='product-preview'>
            {productInfo ? <ProductPreview layoutData={layoutData} componentNumber={componentNumber} productInfo={productInfo} /> : <></>}
          </div>
        )}
      </div>
      {!isRecently && productInfo ? (
        <>
          {Object.keys(productInfo?.productInfoDetail)
            ?.filter((key) => key !== PRODUCT_SUMMARY_KEY)
            ?.map((key, index) => (
              <div key={index} className='compare-product__item-body'>
                {productInfo?.productInfoDetail?.[`${key}`] ? (
                  <ProductFunctionsDetail
                    layoutData={layoutData}
                    isVisibleLabel={componentNumber === 0}
                    productFunctionData={{
                      functionLabel: key,
                      productFunction: productInfo?.productInfoDetail?.[`${key}`]
                    }}
                  />
                ) : (
                  <></>
                )}
              </div>
            ))}
        </>
      ) : (
        <></>
      )}
    </div>
  );
});

CompareProductItem.displayName = 'CompareProductItem';

CompareProductItem.propTypes = {
  layoutData: PropTypes.object,
  isRecently: PropTypes.bool,
  componentNumber: PropTypes.number,
  categorySelected: PropTypes.object,
  productInfo: PropTypes.any
};

export default CompareProductItem;
