import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Fragment } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { connect, useDispatch } from 'react-redux';

import { getCaptchaValue, resetCaptcha } from '@redux/actions/unsubscribe';

import FormFieldError from '../FormFieldError';

const CustomReCaptchaComponent = (props) => {
  const dispatch = useDispatch();
  const recaptchaRef = useRef();

  const onChangeHandler = (value) => {
    if (value) {
      dispatch(resetCaptcha(true));
    }

    dispatch(
      getCaptchaValue({
        [props?.field?.valueField?.name]: {
          itemId: props?.field?.valueField?.name || '',
          value: value || '',
          widgetId: recaptchaRef.current.getWidgetId(),
        }
      })
    );
  };

  return (
    <Fragment>
      <ReCAPTCHA ref={recaptchaRef} sitekey={props.field.model.captchaPublicKey} onChange={onChangeHandler} />
      {!props.resetCaptcha && <FormFieldError {...props} />}
    </Fragment>
  );
};

CustomReCaptchaComponent.propTypes = {
  field: PropTypes.object,
  onChange: PropTypes.func,
  resetCaptcha: PropTypes.bool
};

const mapStateToProps = (state) => ({
  resetCaptcha: state.unsubscribeReducer.resetCaptcha || false
});

export default connect(mapStateToProps)(CustomReCaptchaComponent);
