import * as buyingOptionsTypes from './buyingOptionsTypes';

export const getBuyingOptionsData = (productPageId, variantId, dataType) => ({
  type: buyingOptionsTypes.GET_BO_DATA,
  productPageId,
  variantId,
  dataType
});

export const getBuyingOptionsDataSuccess = (payload, variantId) => ({
  type: buyingOptionsTypes.GET_BO_DATA_SUCCESS,
  payload,
  variantId
});

export const getBuyingOptionsDataFailed = (error) => ({
  type: buyingOptionsTypes.GET_BO_DATA_FAILED,
  error
});

// BO VARIANT
export const addChosenBOVariant = (payload) => ({
  type: buyingOptionsTypes.ADD_CHOSEN_BO_VARIANT,
  payload
});

export const addChosenBORental = (payload) => ({
  type: buyingOptionsTypes.ADD_CHOSEN_BO_RENTAL,
  payload
});

// BO ADDITIONAL OPTIONS
export const addChosenAdditionalOptions = (payload) => ({
  type: buyingOptionsTypes.ADD_CHOSEN_BO_ADDITIONAL_OPTIONS,
  payload
});

// BO WARRANTY
export const addChosenBOWarranty = (payload) => ({
  type: buyingOptionsTypes.ADD_CHOSEN_BO_WARRANTY,
  payload
});

// BO TRADE IN
export const addChosenBOTradeIn = (payload) => ({
  type: buyingOptionsTypes.ADD_CHOSEN_BO_TRADEIN,
  payload
});

export const removeChosenBOTradeIn = () => ({
  type: buyingOptionsTypes.REMOVE_CHOSEN_BO_TRADEIN
});

// BO FREE GIFT
export const addChosenBOFreeGift = (Line, GiftId, ProductId, VariantId, VariantName, ProductIdx) => ({
  type: buyingOptionsTypes.ADD_CHOSEN_BO_FREEGIFTS,
  Line,
  GiftId,
  ProductId,
  VariantId,
  VariantName,
  ProductIdx
});

export const removeChosenBOFreeGift = (payload) => ({
  type: buyingOptionsTypes.REMOVE_CHOSEN_BO_FREEGIFTS,
  payload
});

export const replaceChosenBOFreeGift = (Line, GiftId, ProductId, VariantId, VariantName, ProductIdx) => ({
  type: buyingOptionsTypes.REPLACE_CHOSEN_BO_FREEGIFTS,
  Line,
  GiftId,
  ProductId,
  VariantId,
  VariantName,
  ProductIdx
});

export const getFreeGiftLisrt = (payload) => ({
  type: buyingOptionsTypes.GET_FREE_GIFT_LIST,
  payload
});

export const addToCart = (payload) => ({
  type: buyingOptionsTypes.ADD_TO_CART,
  payload
});

export const getRelationshipId = (payload) => ({
  type: buyingOptionsTypes.GET_RELATIONSHIPID,
  payload
});

export const clearImageTradeInProducts = () => ({
  type: buyingOptionsTypes.CLEAR_IMAGE_OF_TRADE_IN_PRODUCTS
});

export const uploadBOTradeInFile = (payload) => ({
  type: buyingOptionsTypes.UPLOAD_BO_TRADEIN_FILE,
  payload
});

export const uploadBOTradeInFileSuccess = (payload) => ({
  type: buyingOptionsTypes.UPLOAD_BO_TRADEIN_FILE_SUCCESS,
  payload
});

export const uploadBOTradeInFileFailed = (payload) => ({
  type: buyingOptionsTypes.UPLOAD_BO_TRADEIN_FILE_FAILED,
  payload
});

export const addDepositFreeGift = (payload) => ({
  type: buyingOptionsTypes.ADD_DEPOSIT_FREE_GIFT,
  payload
});