import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ProductRecommendationModel {
  constructor() {
    this['Cannot Find Product Text'] = new SitecoreTextModel();

    this['Help Me Decide Text'] = new SitecoreTextModel();

    this['Learn More Text'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cannot Find Product Text')) {
      this['Cannot Find Product Text'].getData(dataSource['Cannot Find Product Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Help Me Decide Text')) {
      this['Help Me Decide Text'].getData(dataSource['Help Me Decide Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Text')) {
      this['Learn More Text'].getData(dataSource['Learn More Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}
