import global from '@utils/global';

export const STAFF_SELECTION_EVENT_NAME = 'staff_selection';

export const handleTrackingStaffSelectionEvent = (storeId, staffId) => {
  const isGA4 = global.getIsGA4();
  if (isGA4) {
    const object = {
      event: STAFF_SELECTION_EVENT_NAME,
      store_id: storeId,
      staff_id: staffId
    };

    window.dataLayer.push(object);
  }
};
