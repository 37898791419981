import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import ProductCardPrice from '../../ProductCardPrice';

const SpecialRelatedProductItem = ({ data, dataFromLayout, isShowBuyNow, indexGA4 }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const [buyNowLink, setBuyNowLink] = useState(deepCopy(data['Buy Now Link']));

  const isGA4 = Global.getIsGA4();
  const itemLstName = dataFromLayout?.['Title']?.value;
  const categoryName = data?.CategoryName;

  useEffect(() => {
    let tempLink = deepCopy(buyNowLink);

    tempLink.value.href = `${data['Buy Now Link'].value.href}&LstName=${itemLstName}`;

    setBuyNowLink(tempLink);
  }, []);

  const handleGa4SelectItem = (action) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      let dataConvert = [];
  
      dataConvert.push(data);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(indexGA4, dataConvert, '', pdpPromoCode, '', '',  itemLstName)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }
  };

  return (
    <Fragment>
      <div className='product-deals__list__item__content'>
        <a href={`${data.Url}?IndexGA4=${indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}`} className='product-deals__list__item__image' onClick={() => handleGa4SelectItem('Image')}>
          <img className='product-deals-image swiper-lazy animated' data-src={data.Image} alt='product' />
          <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
        </a>
        <h3 className='product-deals__info__name' title={data.ProductName} onClick={() => handleGa4SelectItem('Image')}>
          <a href={`${data.Url}?IndexGA4=${indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}`} className='product-deals__info__name-link'>
            {data.ProductName}
          </a>
        </h3>
        <ProductCardPrice
          isBundle={data.IsBundle}
          bundleSellingPriceWithCurrency={data.Price?.SellingPriceWithCurrency}
          bundleListPriceWithCurrency={data.Price?.ListPriceWithCurrency}
          sellingPriceWithCurrency={data.Variants?.[0]?.Price?.SellingPriceWithCurrency}
          listPriceWithCurrency={data.Variants?.[0]?.Price?.ListPriceWithCurrency}
          mainPriceClassName='product-deals__info__price'
          oldPriceClassName='product-deals__info__old-price'
        />
      </div>
      {data?.IsBundle ? (
        <div className='product-deals__list__item__buy-btn'>
          <a className='btn btn-primary' href={data.Url}>
            {messageObj?.['LBL-LearnMore']}
          </a>
        </div>
      ) : isShowBuyNow['Is Show BuyNow'] && isShowBuyNow['Is Show BuyNow'] === '1' ? (
        <div className='product-deals__list__item__buy-btn'>
          <BuyButtonComponent
            productData={{
              ...data,
              productId: `${data.ProductId}|${data.RepresentativeVariantId}`
            }}
          >
            <Link field={buyNowLink} className='btn btn-primary' onClick={() => handleGa4SelectItem('Buy Now')}>
              <span>{data.Stock.PurchasableLabel}</span>
            </Link>
          </BuyButtonComponent>
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

SpecialRelatedProductItem.propTypes = {
  isShowBuyNow: PropTypes.object,
  data: PropTypes.object,
  dataFromLayout: PropTypes.object,
  indexGA4: PropTypes.number
};

export default SpecialRelatedProductItem;
