import './StoreFeature.scss';

import Proptypes from 'prop-types';
import React, { useEffect,useState} from 'react';

import { deepCopy } from '@utils/utility';

import InfoBoxWithImageModel from './model/InfoBoxWithImageModel';

const StoreFeature = (props) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const validateModel = new InfoBoxWithImageModel();

    setInputData(validateModel.getData(deepCopy(props.fields)));
  }, []);

  return inputData && (
    <div className='store-feature'>
      <div className='container'>
        <h2 className='experience-title store-feature__heading'>{inputData.Title.value}</h2>
      </div>
      <div className='store-feature__main'>
        <picture className='store-feature__main__background'>
          <source media='(min-width:1024px)' srcSet={require('../../../../assets/images/store-feature-desktop.png')} />
          <img src={require('../../../../assets/images/store-feature-mobile.png')} alt='feature banner' />
        </picture>
        <div className='store-feature__main__content'>
          <div className='container'>
            <div className='store-feature__main__content__wrap'>
              <h3 className='store-feature__main__content__title'>{inputData.Title.value}</h3>
              <p className='store-feature__main__content__desc' dangerouslySetInnerHTML={{ __html: inputData.Description.value }}></p>
              <div className='store-feature__main__content__btn'>
                <a className='btn-tem btn btn-outline-primary' href={inputData['CTA Button'].value.href}>{inputData['CTA Button Text'].value}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

StoreFeature.propTypes = {
  fields: Proptypes.object
};

export default StoreFeature;