import React from 'react';
import { Fragment } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';

const MyAddressShimmer = () => {

  return (
    <Fragment>
      <div className='my-addresses skeleton__item'>
        <div className='container'>
          <div className='my-addresses__wrap'>
            <div className='row'>
              <div className='col-lg-6'>
                <div className='my-addresses__section'>
                  <div className='my-account-widget__heading my-account-section__group'>
                    <span className='text-line'></span>
                    <span className='clear-10'></span>
                    <span className='text-line'></span>
                  </div>
                  <div className='my-account-widget__content'>
                    <span className='text-line-20'></span>
                    <span className='clear-5'></span>
                    <span className='text-line-20'></span>
                    <span className='clear-5'></span>
                    <span className='text-line-20'></span>
                    <span className='clear-5'></span>
                    <span className='text-line-20'></span>
                  </div>
                </div>
              </div>
              <div className='col-lg-6'>
                <div className='my-addresses__section'>
                  <div className='my-account-widget__heading my-account-section__group'>
                    <span className='text-line'></span>
                    <span className='clear-10'></span>
                    <span className='text-line'></span>
                  </div>
                  <div className='my-account-widget__content'>
                    <span className='text-line-20'></span>
                    <span className='clear-5'></span>
                    <span className='text-line-20'></span>
                    <span className='clear-5'></span>
                    <span className='text-line-20'></span>
                    <span className='clear-5'></span>
                    <span className='text-line-20'></span>
                  </div>
                </div>
              </div>
              <div className='col-12'>
                <div className='my-addresses__section'>
                  <div className='my-addresses__section__heading my-account-widget__heading'>
                    <span className='text-line'></span>
                  </div>
                  <div className='row'>
                    <LoadingShimmer itemNumb={6}>
                      <div className='col-lg-6'>
                        <div className='my-addresses__section'>
                          <div className='my-addresses__section__content'>
                            <div className='my-account-widget__content'>
                              <span className='text-line-20'></span>
                              <span className='clear-5'></span>
                              <span className='text-line-20'></span>
                              <span className='clear-5'></span>
                              <span className='text-line-20'></span>
                              <span className='clear-5'></span>
                              <span className='text-line-20'></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </LoadingShimmer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MyAddressShimmer;