import './ContactInformationWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {Link,Text} from '@sitecore-jss/sitecore-jss-react';

import { getUserFullName } from '../../utils';
import ContactInformationWidgetModel from './models/ContactInformationWidgetModel';

const ContactInformationWidget = ({fields}) => {
  const accountUser = useSelector((state) => state.singleSignOnReducer.userInfoReducer.accountUser) || null;
  const [dataSources, setDatSources] = useState(null);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  useEffect(() => {
    setDatSources(new ContactInformationWidgetModel().getData(fields || {}));
  }, []);

  const userFullName = useMemo(() => getUserFullName(accountUser, settingGlobal?.FullNameFormat), [settingGlobal, accountUser]);

  return dataSources && accountUser ? (
    <div className='my-account-widget contact-widget animated-slow fadeIn delay-100'>
      <div className='my-account-widget__heading my-account-section__group'>
        <h4 className='my-account-widget__heading__title'><Text field={dataSources['Title']}/></h4>
        {dataSources['Edit Text'].value ? (
          <Link className='my-account-section__btn-link' field={dataSources['Edit Link']}>
            <Text field={dataSources['Edit Text']} />
          </Link>
        ) : (
          <Link className='my-account-section__btn-link' field={dataSources['Edit Link']} />
        )}
      </div>
      <div className='my-account-widget__content contact-widget__content'>
        <span className='contact-widget__content__info'>
          <Text field={dataSources['Full Name Text']}/> {userFullName}
        </span>
        <span className='contact-widget__content__info'><Text field={dataSources['Email Text']}/> {accountUser.Email} </span>
        <span className='contact-widget__content__info'><Text field={dataSources['Phone Text']}/> {accountUser.PhoneNumber}</span>
      </div>
    </div>
  ) : (
    <div className='my-account-widget contact-widget skeleton__item'>
      <div className='my-account-widget__heading my-account-section__group'>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
      </div>
      <div className='my-account-widget__content contact-widget__content'>
        <span className='contact-widget__content__info text-line-20'></span>
        <span className='contact-widget__content__info text-line-20'></span>
        <span className='contact-widget__content__info text-line-20'></span>
      </div>
    </div>
  );
};

ContactInformationWidget.propTypes = {
  fields: PropTypes.object,
};

export default ContactInformationWidget;
