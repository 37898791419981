import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import VerticalTabItem from '../MainNavigationSubMenu/VerticalTabLayout/VerticalTabItem';

const MainNavigationSubMenu = (props) => {
  const contentData = props.contentData || [];
  const contentLabel = props.contentLabel || {value: ''};
  let label = props.checklabel;
  

  const [isShowSubMenuNav, setIsShowSubMenuNav] = useState(false);

  useEffect(() => {
    if (label === contentLabel.value) {
      setIsShowSubMenuNav(true);
    } else {
      setIsShowSubMenuNav(false);
    }
  }, [label]);

  const handleShowSubMenu = (data) => {
    setIsShowSubMenuNav(!isShowSubMenuNav);

    props.checkShowData(data);
  };
  
  return (
    contentData && contentData.length > 0 && (
      <div className={`${isShowSubMenuNav ? 'first-nav-item show-sub-menu-nav' : 'first-nav-item'} ${props.class ? props.class : ''}`}>
        <Text field={contentLabel} tag='h2' className={contentData.length > 0 ? 'arrow-icon-next first-nav-item__title' : 'first-nav-item__title'} onClick={() => handleShowSubMenu(contentLabel.value)}></Text>
        {
          contentData.length > 0 && (
            <div className='header__sub-menu-nav'>
              <div className='header__sub-menu-nav__inner'>
                <Text field={contentLabel} tag='h2' className='arrow-icon-prev first-nav-item__title' onClick={() => handleShowSubMenu(contentLabel.value)}></Text>
                <ul className='row header__sub-menu-nav__contents'>
                  {contentData.map((item, index) => 
                    <VerticalTabItem 
                      type={props.type} 
                      contentData={item} 
                      key={index} 
                      contentLabel={contentLabel.value}
                      creativeSlot={index}
                    />)}
                </ul>
              </div>
            </div>
          )
        }
      </div>
    )
  );
};

MainNavigationSubMenu.propTypes = {
  contentData: PropTypes.any,
  contentLabel: PropTypes.any,
  checkShowData: PropTypes.any,
  checklabel: PropTypes.string,
  type: PropTypes.string,
  class: PropTypes.string,
};

export default MainNavigationSubMenu;
