import Proptypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import SwipeComponent from '@components/Share/SwipeComponent';
import { PRODUCT_DETAIL_DISPLAY_IMAGES } from '@utils/constant';

import CustomPaginationSwiper from '../CustomPaginationSwiper';
import ProductVariantImage from '../ProductVariantImage';

const SwiperComponentZoom = ({variantActive, toggleGallery, activeSlideKey, setCloseZoomImages}) => {

  const [slideTo, setSlideTo] = useState(activeSlideKey);
  const imageSwiperRef = useRef(null);

  let params = {
    lazy: true,
    slidesPerView: PRODUCT_DETAIL_DISPLAY_IMAGES,
    centeredSlides: false,
    spaceBetween: 10,
    pagination: {
      el: '.swiper-pagination',
      type: 'custom',
      clickable: true
    },
    on: {
      slideChangeTransitionEnd: () => {
        setSlideTo(imageSwiperRef.current.swiper.realIndex);
      }
    },

    // eslint-disable-next-line react/display-name
    renderPagination: (swiper) => {
      const onSlideToHandler = (slideKey) => {
        setSlideTo(slideKey);
        
        imageSwiperRef.current.swiper.slideTo(slideKey);
      };

      return (
        <CustomPaginationSwiper  activeIndex={slideTo} setActiveKeyEvt={onSlideToHandler} prevSlideEvt={onSlideToHandler} nextSlideEvt={onSlideToHandler} swiper={swiper} />
      );
    }
  };

  const onCloseZomHandler = () => {
    setCloseZoomImages(slideTo);
  };
  
  useEffect(() => {
    imageSwiperRef.current.swiper.slideTo(slideTo);
  }, []);

  return (
    <ModalComponent cssClass='product-image-zoom' isShow={toggleGallery} onCloseHandler={onCloseZomHandler}>
      <SwipeComponent param={params} setRef={imageSwiperRef}>
        {variantActive.Images.map((itemImage, itemImageIdx) => (
          <div key={itemImageIdx} className='product-variant__wrap__item__img'>
            <ProductVariantImage inputData={itemImage} />
          </div>
        ))}
      </SwipeComponent>
    </ModalComponent>
  );
};

SwiperComponentZoom.propTypes = {
  setCloseZoomImages: Proptypes.func,
  toggleGallery: Proptypes.bool,
  variantActive: Proptypes.object,
  activeSlideKey: Proptypes.number
};



export default SwiperComponentZoom;
