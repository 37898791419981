import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import ModalNeedAddProduct from './ModalNeedAddProduct';
import BirthdayRewardsModel from './model/BirthdayRewardsModel';

const BirthdayRewards = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const [totalItem, setTotalItem] = useState(0);
  const [isNeedAddProduct, setIsNeedAddProduct] = useState(false);
  const [isShowPopUp, setIsShowPopUp] = useState(false);

  useEffect(() => {
    if (currentCart) {
      const quantityArray = currentCart.Lines.map((lineItem) => +lineItem.Quantity);

      setTotalItem(quantityArray.length ? quantityArray.reduce((total, quantity) => total + quantity) : 0);
    }
  }, [currentCart]);

  useEffect(() => {
    if (totalItem < 1) {
      setIsNeedAddProduct(true);
    } else {
      setIsNeedAddProduct(false);
    }
  }, [totalItem]);

  const handleShowPopupAddProduct = (ev, fields) => {
    const isBirthdayVoucher = fields?.['isBirthdayVoucher']?.value;
    if (isBirthdayVoucher && isNeedAddProduct) {
      ev.preventDefault();

      setIsShowPopUp(true);
    } else return;
  };

  const handleCloseModal = () => setIsShowPopUp(false);

  useEffect(() => {
    let model = new BirthdayRewardsModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    <div className='container my-reward__box birthday-rewards' id={layoutData?.['Anchor ID']?.value}>
      <Text tag='h3' field={layoutData['title']} className='my-reward__box-title' />
      <div className='my-reward__box-wrapper'>
        {layoutData['birthdayRewardOptions'].map(({ id, fields }) => (
          !fields.hidden ? (
            <div key={id} className='my-reward__box-item'>
              <div className='my-reward__box-item__description'>
                <div className='my-reward__box-item__description-icon'>
                  <div className='icon-wrapper'>
                    <Image field={fields['icon']} />
                  </div>
                </div>
                <div className='my-reward__box-item__description-detail'>
                  <Text tag='h4' field={fields['name']} className='title' />
                  <Text tag='h5' field={fields['description']} className='content' />
                </div>
              </div>
              <div className='my-reward__box-item__cta'>
                <a 
                  onClick={(ev) => handleShowPopupAddProduct(ev, fields)}
                  href={fields['ctaButtonUrl'].value?.href} 
                  className={`osim-account-form__form__btn__item btn btn-primary ${fields['ctaButtonUrl'].value?.href ? '' : 'disabled'}`}
                >
                  <Text field={fields['ctaButtonText']} />
                </a>
              </div>
            </div>
          ) : (<></>)
        ))}
      </div>
      <ModalNeedAddProduct isShowPopUp={isShowPopUp} layoutData={layoutData} handleCloseModal={handleCloseModal} />
    </div>
  ) : (
    <></>
  );
};

BirthdayRewards.propTypes = {
  fields: object
};

export default BirthdayRewards;
