import Proptypes from 'prop-types';
import React, { useEffect } from 'react';
import { Fragment } from 'react';
import { useDispatch } from 'react-redux';

import { addChosenBORental } from '@redux/actions/buyingOptions';

const BORentalsList = ({ contentData, activeItemsId, isSelected, onToogleFnc }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (contentData.length === 1) {
      onClickBuyingItemHandler(contentData[0].ProductId);
    }
  }, []);

  const onClickBuyingItemHandler = (itemID) => {
    dispatch(addChosenBORental(itemID));

    onToogleFnc();
  };

  const isActiveItems = (listId, itemId) => {
    return listId.includes(itemId);
  };

  return (
    <div className='rental-buying-list'>
      <div className='row'>
        {contentData.map((item, itemIndex) => {
          return (
            <div className='col-lg-6 rental-buying-list__item-wrap' key={itemIndex}>
              <div
                className={'rental-buying-list__item' + `${!isSelected && isActiveItems(activeItemsId, item.ProductId) ? ' active' : ''}`}
                onClick={() => onClickBuyingItemHandler(item.ProductId)}
                key={itemIndex}
              >
                <span className='addition-price rental-buying-list__item__price'>
                  {item.Price.SellingPriceWithCurrency === item.Price.ListPriceWithCurrency ? (
                    <span>{item.Price.SellingPriceWithCurrency}</span>
                  ) : (
                    <Fragment>
                      <span>{item.Price.SellingPriceWithCurrency}</span>
                      <del>{item.Price.ListPriceWithCurrency}</del>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

BORentalsList.propTypes = {
  contentData: Proptypes.any,
  activeItemsId: Proptypes.any,
  onToogleFnc: Proptypes.any,
  isSelected: Proptypes.any
};

export default BORentalsList;
