import { bool, object } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import ViewProductStatusBtn from './ViewProductStatusBtn';

const HasWarrantySection = ({ isLogin, fieldData }) => {
  return (
    <>
      <div className='product-thank-you-page__bottom__content'>
        <div className='product-thank-you-page__bottom__content--center'>
          {!isLogin ? <Text field={fieldData['Account Specific Description']} tag='p' className='guest-warranty' /> : <></>}
          <Text tag='p' field={fieldData['Specific Description']} />
        </div>
      </div>
      <div className='product-thank-you-page__bottom__link-view'>
        <ViewProductStatusBtn buttonLink={fieldData?.['View Product Button Link']} buttonText={fieldData['View Product Button Label']} />
      </div>
    </>
  );
};

HasWarrantySection.propTypes = {
  isLogin: bool,
  fieldData: object
};

export default HasWarrantySection;
