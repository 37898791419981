import './CPFilterPopup.scss';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { getSaleStaffs } from '@redux/actions/cpLeadManagement';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import FilterRange from '../../GenericComponents/FilterRange';

const IS_PURCHASE = 'true';
const IS_NOT_PURCHASE = 'false';
const ALL_STATUS = null;
const MINRANGE_LIMIT = 1;

const CPFilterPopup = ({ filterFields, onFilterSubmit }) => {
  const statusOptions = [
    { value: ALL_STATUS, label: filterFields['All Purchase Text'].value || '' },
    { value: IS_NOT_PURCHASE, label: filterFields['Not Spent Option Label'].value || IS_NOT_PURCHASE },
    { value: IS_PURCHASE, label: filterFields['Spent Option Label'].value || IS_PURCHASE }
  ];
  const dispatch = useDispatch();
  const MINRANGE = Number(filterFields['Min Total Spent'].value || 0);
  const MAXRANGE = Number(filterFields['Max Total Spent'].value || 0);
  const isCPManager = SitecoreContextFactoryService.getValueContextItem('isCPManager');
  const cpLeadManagementReducer = useSelector((state) => state.cpLeadManagementReducer);
  const FILTER_DEFAULT_DATE_RANGE = filterFields?.['Filter Default Date Rage']?.value;
  const MILLISECOND_PER_DAY = 86400000;
  const initialStateDateFilter = [new Date(new Date().valueOf() - FILTER_DEFAULT_DATE_RANGE * MILLISECOND_PER_DAY), new Date()];
  const [dateFilter, setDateFilter] = useState(initialStateDateFilter);
  const [isShowFilterPopup, setShowFilterPopup] = useState(false);
  const [saleStaffOptions, setSaleStaffOptions] = useState(null);
  const [staffSelected, setStaffSelected] = useState(null);
  const [statusSelected, setStatusSelected] = useState(statusOptions[0]);
  const [rangeData, setRangeData] = useState([MINRANGE, MAXRANGE]);
  const [isNotPurchase, setIsNotPurchase] = useState(statusSelected.value === IS_NOT_PURCHASE);
  const [isClearFilter, setClearFilter] = useState(false);
  const [minRangeValue, setMinRangeValue] = useState(MINRANGE);

  const onChangeStaffData = (staff) => setStaffSelected(staff);

  const onChangeRange = (rangeValue) => {
    setRangeData(rangeValue);

    setClearFilter(false);
  };

  const checkNumber = (number) => (number < 10 ? '0' + number : number);

  const customDateAPI = (apiDate) => {
    if (apiDate) {
      const datePicker = new Date(apiDate);
      const year = datePicker.getFullYear();
      const month = checkNumber(datePicker.getMonth() + 1);
      const day = checkNumber(datePicker.getDate());

      return year + '-' + month + '-' + day;
    }

    return;
  };

  const onHandleSubmit = (e) => {
    e.preventDefault();
    let params = {};

    if (statusSelected.value && !staffSelected.value) {
      if (isNotPurchase) {
        params = {
          FromDate: customDateAPI(dateFilter[0]),
          ToDate: customDateAPI(dateFilter[1]),
          IsSpent: statusSelected.value
        };
      } else {
        params = {
          FromDate: customDateAPI(dateFilter[0]),
          ToDate: customDateAPI(dateFilter[1]),
          MinTotalSpent: isNotPurchase ? '' : rangeData[0],
          MaxTotalSpent: isNotPurchase ? '' : rangeData[1],
          IsSpent: statusSelected.value
        };
      }
    } else if (!statusSelected.value && staffSelected.value) {
      params = {
        FromDate: customDateAPI(dateFilter[0]),
        ToDate: customDateAPI(dateFilter[1]),
        MinTotalSpent: isNotPurchase ? '' : rangeData[0],
        MaxTotalSpent: isNotPurchase ? '' : rangeData[1],
        StaffId: staffSelected.value
      };
    } else if (!statusSelected.value && !staffSelected.value) {
      params = {
        FromDate: customDateAPI(dateFilter[0]),
        ToDate: customDateAPI(dateFilter[1]),
        MinTotalSpent: isNotPurchase ? '' : rangeData[0],
        MaxTotalSpent: isNotPurchase ? '' : rangeData[1]
      };
    } else {
      if (isNotPurchase) {
        params = {
          FromDate: customDateAPI(dateFilter[0]),
          ToDate: customDateAPI(dateFilter[1]),
          IsSpent: statusSelected.value,
          StaffId: staffSelected.value
        };
      } else {
        params = {
          FromDate: customDateAPI(dateFilter[0]),
          ToDate: customDateAPI(dateFilter[1]),
          MinTotalSpent: isNotPurchase ? '' : rangeData[0],
          MaxTotalSpent: isNotPurchase ? '' : rangeData[1],
          IsSpent: statusSelected.value,
          StaffId: staffSelected.value
        };
      }
    }

    onFilterSubmit(params);

    setShowFilterPopup(false);
  };

  const onSelect = (item) => {
    setStatusSelected(item);

    setIsNotPurchase(item.value === IS_NOT_PURCHASE);

    if (item.value === IS_PURCHASE) {
      setMinRangeValue(MINRANGE > 0 ? MINRANGE : MINRANGE_LIMIT);
    } else {
      setMinRangeValue(MINRANGE);
    }
  };

  const onHandleClearData = () => {
    setDateFilter(initialStateDateFilter);

    setClearFilter(true);

    setIsNotPurchase(false);

    setStaffSelected(saleStaffOptions[0]);

    setStatusSelected(statusOptions[0]);
  };

  useEffect(() => {
    if (isCPManager) {
      dispatch(
        getSaleStaffs({
          managerId: JSON.parse(window.localStorage.getItem('cPUserData'))?.UserId || null
        })
      );
    }
  }, []);

  useEffect(() => {
    const staffOptions = [{ value: '', label: filterFields?.['All Filter Label']?.value || 'All' }];
    if (cpLeadManagementReducer.SaleStaffs) {
      cpLeadManagementReducer.SaleStaffs.map((staff) => {
        return staffOptions.push({ value: staff.StaffId, label: staff.StaffName });
      });
    }

    setSaleStaffOptions(staffOptions);

    setStaffSelected(staffOptions[0]);
  }, [filterFields, cpLeadManagementReducer.SaleStaffs]);

  return (
    filterFields && (
      <div className='cp-page-header__btn'>
        <span className='cp-icon cp-icon--filter' onClick={() => setShowFilterPopup(!isShowFilterPopup)}></span>
        <div className={`cp-filter-popup ${isShowFilterPopup ? 'show' : ''}`}>
          <form>
            <div className='cp-filter-popup__wrap'>
              <div className='cp-filter-popup__item filter-date'>
                <Text tag='span' className='cp-filter-popup__item__label' field={filterFields['Date Filter Label']}></Text>
                <div className='cp-filter-popup__item__value'>
                  <DateRangePicker
                    selected={dateFilter}
                    onChange={(date) => setDateFilter(date)}
                    format='dd MMM yyyy'
                    dayPlaceholder='dd'
                    monthPlaceholder='mm'
                    yearPlaceholder='yyyy'
                    value={dateFilter}
                    clearIcon={null}
                  />
                </div>
              </div>
              {isCPManager ? (
                <div className='cp-filter-popup__item'>
                  <Text tag='span' className='cp-filter-popup__item__label' field={filterFields['Sale Staff Filter Label']}></Text>
                  <div className='cp-filter-popup__item__value'>
                    <Select
                      id='sale-staff-select'
                      onChange={(data) => onChangeStaffData(data)}
                      value={staffSelected}
                      options={saleStaffOptions}
                      className='cp-select'
                      classNamePrefix='cp-select'
                      isSearchable={true}
                    />
                  </div>
                </div>
              ) : (
                ''
              )}
              <div className='cp-filter-popup__item'>
                <Text tag='span' className='cp-filter-popup__item__label' field={filterFields['Status Filter Label']}></Text>
                <div className='cp-filter-popup__item__value'>
                  <Select
                    id='status-select'
                    onChange={(data) => onSelect(data)}
                    value={statusSelected}
                    options={statusOptions}
                    className='cp-select'
                    classNamePrefix='cp-select'
                    isSearchable={true}
                  />
                </div>
              </div>
              <div className={`cp-filter-popup__item filter-range${isNotPurchase ? ' filter-range__disable' : ''}`}>
                <Text tag='span' className='cp-filter-popup__item__label' field={filterFields['Total Spent Filter Label']}></Text>
                <div className='cp-filter-popup__item__value'>
                  <FilterRange onChangeRange={onChangeRange} minRange={minRangeValue} maxRange={MAXRANGE} isClearFilter={isClearFilter} />
                </div>
              </div>
            </div>
            <div className='cp-filter-popup__btn'>
              <button type='submit' className='cp-filter-popup__btn__item btn btn-primary' onClick={onHandleSubmit}>
                <Text field={filterFields['Apply Button Text']}></Text>
              </button>
              <button type='button' className='cp-filter-popup__btn__item os-btn-link' onClick={() => onHandleClearData()}>
                <Text field={filterFields['Clear Button text']}></Text>
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

CPFilterPopup.propTypes = {
  filterFields: PropTypes.any,
  onFilterSubmit: PropTypes.any,
};

export default CPFilterPopup;