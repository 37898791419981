import * as staticBundleDetailTypes from './staticBundleDetailTypes';

export const getStaticBundleDetail = () => ({
  type: staticBundleDetailTypes.GET_STATIC_BUNDLE_DETAIL
});

export const getStaticBundleDetailSuccess = (payload) => ({
  type: staticBundleDetailTypes.GET_STATIC_BUNDLE_DETAIL_SUCCESS,
  payload
});

export const getStaticBundleDetailFailed = (error) => ({
  type: staticBundleDetailTypes.GET_STATIC_BUNDLE_DETAIL_FAILED,
  error
});

export const getPromotionPrice = (payload) => ({
  type: staticBundleDetailTypes.GET_PROMOTION_PRICE,
  payload
});

export const getVariantStatus = (payload) => ({
  type: staticBundleDetailTypes.GET_VARIANT_STATUS,
  payload
});
