import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { PAYMENT_PAGE_NAME } from '@components/FreeGiftPopup';
import { getFreeGiftDataFailed, getFreeGiftDataSuccess } from '@redux/actions/getFreeGiftData';
import { GET_FREE_GIFT_DATA_START } from '@redux/actions/getFreeGiftData/getFreeGiftDataTypes';
import { post } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import { GET_FREE_GIFT_DATA_ENDPOINT } from './configs';

export const getFreeGiftDataEpic = (action$) =>
  action$.pipe(
    ofType(GET_FREE_GIFT_DATA_START),
    switchMap((action) => {
      const pageName = SitecoreContextFactoryService.getValueContextItem('route')?.name;

      const params = {
        promotionIds: action.payload,
        IsPaymentPage: pageName === PAYMENT_PAGE_NAME
      };

      return from(post(GET_FREE_GIFT_DATA_ENDPOINT, params)).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            return getFreeGiftDataSuccess(res.data);
          } else {
            return getFreeGiftDataFailed(res.data);
          }
        }),
        catchError((error) => of(getFreeGiftDataFailed(error)))
      );
    })
  );
