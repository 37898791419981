import * as myOrderTypes from '../myOrders/myOrderTypes';

export const getMyOrders = (payload) => ({
  type: myOrderTypes.GET_ALL_MY_ORDER,
  payload
});

export const getMyOrdersSuccess = (payload) => ({
  type: myOrderTypes.GET_ALL_MY_ORDER_SUCCESS,
  payload
});

export const getMyOrdersFailed = (error) => ({
  type: myOrderTypes.GET_ALL_MY_ORDER_FAILED,
  error
});

export const getMyOrdersById = (payload) => ({
  type: myOrderTypes.GET_ORDER_BY_ID,
  payload
});

export const getMyOrdersByIdSuccess = (payload) => ({
  type: myOrderTypes.GET_ORDER_BY_ID_SUCCESS,
  payload
});

export const getMyOrdersByIdFailed = (payload) => ({
  type: myOrderTypes.GET_ORDER_BY_ID_FAILED,
  payload
});

export const getMyBalancesById = (payload) => ({
  type: myOrderTypes.GET_BALANCE_BY_ID,
  payload
});

export const getMyBalancesByIdSuccess = (payload) => ({
  type: myOrderTypes.GET_BALANCE_BY_ID_SUCCESS,
  payload
});

export const getMyBalancesByIdFailed = (payload) => ({
  type: myOrderTypes.GET_BALANCE_BY_ID_FAILED,
  payload
});

export const removeBalanceCart = (payload) => ({
  type: myOrderTypes.REMOVE_BALANCE_CART,
  payload
});

export const removeBalanceCartSuccess = (payload) => ({
  type: myOrderTypes.REMOVE_BALANCE_CART_SUCCESS,
  payload
});

export const removeBalanceCartFailed = (payload) => ({
  type: myOrderTypes.REMOVE_BALANCE_CART_FAILED,
  payload
});

export const getActivateOrders = (payload) => ({
  type: myOrderTypes.GET_ACTIVATE_ORDERS,
  payload
});

export const getActivateOrdersSuccess = (payload) => ({
  type: myOrderTypes.GET_ACTIVATE_ORDERS_SUCCESS,
  payload
});

export const getActivateOrdersFailed = (payload) => ({
  type: myOrderTypes.GET_ACTIVATE_ORDERS_FAILED,
  payload
});



export const getPastOrders = (payload) => ({
  type: myOrderTypes.GET_PAST_ORDERS,
  payload
});

export const getPastOrdersSuccess = (payload) => ({
  type: myOrderTypes.GET_PAST_ORDERS_SUCCESS,
  payload
});

export const getPastOrdersFailed = (payload) => ({
  type: myOrderTypes.GET_PAST_ORDERS_FAILED,
  payload
});


export const getLastestOrders = (payload) => ({
  type: myOrderTypes.GET_LASTEST_ORDERS,
  payload
});

export const getLastestOrdersSuccess = (payload) => ({
  type: myOrderTypes.GET_LASTEST_ORDERS_SUCCESS,
  payload
});

export const getLastestOrdersFailed = (payload) => ({
  type: myOrderTypes.GET_LASTEST_ORDERS_FAILED,
  payload
});