import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const FormFieldError = (props) => {
  if (props.fieldValidationErrorsComponent) {
    const CustomError = props.fieldValidationErrorsComponent;

    // strip the errors component from the custom component props
    // (prevents infinite loop rendering if someone reuses this component as a custom error component)
    const { fieldValidationErrorsComponent, ...errorsComponentProps } = props;

    return <CustomError {...errorsComponentProps} />;
  }

  const { errors } = props;

  if (!errors || errors.length === 0) {
    return null;
  }

  return (
    <div className='invalid'>
      {props.phoneNumber.itemValue === props.field.valueField.name ? (
        <p>{props.errMsg}</p>
      ) : (
        <p>{errors[0]}</p>
      )}
    </div>
  );
};

FormFieldError.propTypes = {
  fieldValidationErrorsComponent: PropTypes.any,
  errors: PropTypes.any,
  phoneNumber: PropTypes.any,
  field: PropTypes.object,
  errMsg: PropTypes.string
};

const mapStateToProps = (state) => ({
  phoneNumber: state.unsubscribeReducer.phoneNumber || '',
  errMsg: state.getMessageReducer.objMessages?.['Msg_001.7'] || ''
});

export default connect(mapStateToProps)(FormFieldError);
