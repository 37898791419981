import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import { GET_DATA } from '@redux/actions/test';
import { getDataFailed,getDataSuccess } from '@redux/actions/test';
import { getTest } from '@services/testService';

const testEpic = ($actions) =>
  $actions.pipe(
    ofType(GET_DATA),
    switchMap(() => {
      return from(getTest()).pipe(
        map((res) => {
          if (res.status === 200) {
            return getDataSuccess(res);
          } else {
            return getDataFailed('Failed');
          }
        })
      );
    })
  );

export default testEpic;
