import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/compareProduct';
import * as compareProductTypes from '@redux/actions/compareProduct/compareProductTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from '../config';

export const getProductsInfoEpic = (action$) =>
  action$.pipe(
    ofType(compareProductTypes.GET_PRODUCT_INFO_START),
    switchMap((action) => {
      const params = {
        productPageId: action.payload.productPageId
      };

      const componentNumber = {
        cNumber: action.payload.componentNumber
      };

      return getAsObservable(config.ACTION_GET_PRODUCT_INFO, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getProductInfoSuccess(deepCopy(res?.data), componentNumber);
          } else {
            return actions.getProductInfoFailed('Failed');
          }
        })
      );
    })
  );
