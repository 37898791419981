import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Accordion } from 'react-bootstrap';

import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const AccordionItem = ({ data, activeKey, onToogleAccordion, params }) => {
  const [headerHeight, setHeaderHeight] = useState(null);
  
  useEffect(() => {
    if (window.location.href.includes(data.id.toUpperCase())) {
      window.scrollTo(0, document.getElementById(data.id).offsetTop - document.getElementsByClassName('header__container')[0].offsetHeight);
    }
  }, []);

  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  useEffect(() => {
    Global.scrollWithHash(headerHeight);
  }, [data, headerHeight]);

  return (
    <div className='accordion__info-list__item' id={data.id}>
      <div className='accordion__collapse'>
        <div className='accordion__collapse__title' onClick={() => onToogleAccordion(data.id.toLowerCase())}>
          <Text className='accordion__collapse__title__text' tag='span' field={data.fields['Title']} />
          {activeKey !== data.id.toLowerCase() ? (
            params?.['Expand Type'] && params?.['Expand Type'] === 'Arrow' ? (
              <i className='accordion__collapse__title__icon icon--nomal icon-chevron-down-big'></i>
            ) : (
              <i className='accordion__collapse__title__icon icon--nomal icon-add'></i>
            )
          ) : params?.['Expand Type'] && params?.['Expand Type'] === 'Arrow' ? (
            <i className='accordion__collapse__title__icon icon--collapsed icon-chevron-down-big rotate'></i>
          ) : (
            <i className='accordion__collapse__title__icon icon--collapsed icon-remove'></i>
          )}
        </div>
        <div>
          <div className='accordion__wrap'>
            <Accordion.Collapse eventKey={data.id.toLowerCase()}>
              <Fragment>
                <RichText tag='p' field={data.fields['Description']} />
                {data.fields.Image.value.src !== '' ? (
                  <Link field={data.fields['Image Link']}>
                    <Image className="accordion__wrap__image" field={data.fields.Image} />
                  </Link>
                ) : (
                  <></>
                )}
                {data.fields['CTA Button Text'].value ? (
                  <Link className="btn-link btn-link--readmore" field={data.fields['CTA Button']}>
                    {data.fields['CTA Button Text'].value}
                  </Link>
                ) : (
                  <></>
                )}
              </Fragment>
            </Accordion.Collapse>
          </div>
        </div>
      </div>
    </div>
  );
};

AccordionItem.propTypes = {
  accordion: PropTypes.string,
  data: PropTypes.object,
  activeKey: PropTypes.any,
  onToogleAccordion: PropTypes.any,
  params: PropTypes.object
};

export default AccordionItem;
