import React, { useCallback, useMemo, useState } from 'react';

import Loading from '@components/Share/Loading';
import ModalComponent from '@components/Share/ModalComponent';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image } from '@sitecore-jss/sitecore-jss-react';

export const ARViewPopup = () => {
  const contextRouteItem = SitecoreContextFactoryService.getValueContextRouteItem('fields');
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const arModalIframeLink = useMemo(() => contextRouteItem?.['AR Modal Iframe Link']?.value || '', [contextRouteItem]);

  const handleOpenModal = useCallback(() => {
    /** INFO: open modal */
    setIsOpenModal(true);

    /** INFO: show loading component when open the modal */
    setIsIframeLoading(true);
  }, []);
  const handleCloseModal = useCallback(() => setIsOpenModal(false), []);
  const handleLoadFinish = useCallback(() => setIsIframeLoading(false), []);

  return arModalIframeLink ? (
    <>
      <button onClick={handleOpenModal} className='ar-view-modal__btn'>
        <Image
          className='icons-component__img icons-component__img--nomal'
          field={contextRouteItem?.['AR Icon']}
        />
        <Image
          className='icons-component__img icons-component__img--active'
          field={contextRouteItem?.['AR Hover Icon']}
        />
      </button>
      <ModalComponent isShow={isOpenModal} onCloseHandler={handleCloseModal} cssClass='ar-view-modal__wrapper'>
        <iframe
          id='modal-ar-frame'
          src={arModalIframeLink}
          onLoad={handleLoadFinish}
          className='ar-view-modal__iframe'
        ></iframe>
        {isIframeLoading ? <Loading classNameCustom='ar-view-modal__loading' /> : <></>}
      </ModalComponent>
    </>
  ) : (
    <></>
  );
};