import './MyAccount.scss';

import React from 'react';

const MyAccount = () => {
  return (
    <div>MyAccount</div>
  );
};

export default MyAccount;