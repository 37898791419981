import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import * as getProductCatalogueTypes from '@redux/actions/getProductCatalogue/getProductCatalogueTypes';
import * as actions from '@redux/actions/getProductCatalogue';
import { get } from '@services/genericService';
import * as config from './config';

export const getProductCatalogueEpic = (action$) =>
  action$.pipe(
    ofType(getProductCatalogueTypes.GET_PRODUCT_CATALOGUE),
    switchMap((action) => {
      const params = {
        sitename: action.payload.sitename
      };
      return from(get(config.PRODUCT_CATALOGUE, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getProductCatalogueSuccess(res.data);
          } else {
            return actions.getProductCatalogueFailed('Failed');
          }
        })
      );
    })
  );
