import SitecoreTextModel from '@models/SitecoreTextModel';

export default class FreeGiftModel {
  constructor() {
    this['Learn More Text'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Text')) {
      this['Learn More Text'].getData(dataSource['Learn More Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}
