export default class BundleBOSubLinesItemModel {
  constructor() {
    this['ProductId'] = '';

    this['VariantId'] = '';

    this['WarrantyId'] = '';

    this['UniqueProductId'] = '';
  }
  getData(dataSource, parentIndex) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this['ProductId'] = dataSource['ProductId'] ? dataSource['ProductId'] : this['ProductId'];

      this['UniqueProductId'] = dataSource['ProductId'] ? `${dataSource['ProductId'] + parentIndex}` : this['UniqueProductId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantId')) {
      this['VariantId'] = dataSource['VariantId'] ? dataSource['VariantId'] : this['VariantId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionCartLines')) {
      this['WarrantyId'] = dataSource['PromotionCartLines']?.length ? dataSource['PromotionCartLines'][0].ProductId : this['WarrantyId'];
    }
    
    return this;
  }
}
