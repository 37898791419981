import './style.scss';

import PropTypes from 'prop-types';
import React from 'react';

const PreviewComponent = (props) => (
  <div className='swiper-preview'>
    <div className='product-image'>
      <div className='product-image__content'>
        <img src={props.data.image.value.url} alt='product image' />
      </div>
      <div className='product-image__button'>
        <i className='product-image__button__item product-image__button__item--zoom icon-loop'></i>
        <i className='product-image__button__item product-image__button__item--share icon-search'></i>
      </div>
    </div>
    {/* <div className='swiper-preview__left'>
      <img className='swiper-preview__left__img' src={props.data.image.value.url} />
    </div> */}
    {/* <div className='swiper-preview__right'>
      <h3>Name: {props.data.name}</h3>
      <span>Price: {props.data.price}$</span>
      <p>{props.data.description}</p>
    </div> */}
  </div>
);

PreviewComponent.propTypes = {
  data: PropTypes.any
};

export default PreviewComponent;
