// Import dependencies, operators
import { combineReducers } from 'redux';

import activateOrdersReducer from './activateOrders';
import allOrdersReducer from './allOrders';
import balanceByIdReducer from './balanceById';
import lastestReducer from './lastestOrder';
import orderByIdReducer from './orderById';
import pastOrdersReducer from './pastOrders';

const myOrderReducer = combineReducers({
  allOrdersReducer,
  orderByIdReducer,
  activateOrdersReducer,
  pastOrdersReducer,
  lastestReducer,
  balanceByIdReducer
});

export default myOrderReducer;
