import './InfoHeader.scss';

import React from 'react';

const InfoHeader = () => {
  return <div className='info-header'>
    <h2 className='info-header__title'>John Doe’s Products List</h2>
    <p className='info-header__description'>Customer has 4 products</p>
  </div>;
};

export default InfoHeader;
