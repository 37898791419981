import './MyProductDetailReview.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProductReviewById } from '@redux/actions/productReview';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { REFERENCE_PURCHASED_PRODUCT } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import ProductReviewContent from '../ProductReviewContent';
import MyProductDetailReviewModels from './Models/MyProductDetailReviewModels';
import MyProductDetailReviewShimmer from './MyProductDetailReviewShimmer';

const MyProductDetailReview = ({ fields }) => {
  const DATA_IS_FALSE = 'false';

  const dispatch = useDispatch();
  const getProductReviewByIdReducer = useSelector((state) => state.productReviewReducer.getProductReviewByIdReducer);
  const productDetails = useSelector((state) => state.productWarrantyReducer.getUserProductDetails.productDetails);

  const [myProductDetailReviewFields, setMyProductDetailReviewFields] = useState(null);
  const [isDisableWriteReview, setIsDisableWriteReview] = useState(DATA_IS_FALSE);
  const reviewById = getProductReviewByIdReducer?.reviewById;
  const isLoading = getProductReviewByIdReducer?.isLoading;

  useEffect(() => {
    const myProductDetailReviewModels = new MyProductDetailReviewModels();

    setMyProductDetailReviewFields(myProductDetailReviewModels.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    const ReviewId = new URL(window.location.href).searchParams.get('ReviewId');

    const IsDisableWriteReview = new URL(window.location.href).searchParams.get('IsDisableWriteReview');

    IsDisableWriteReview && setIsDisableWriteReview(IsDisableWriteReview);

    ReviewId &&
      dispatch(
        getProductReviewById({
          reviewId: ReviewId
        })
      );
  }, []);

  const onClickReviewLink = () => {
    const commerceProductId = new URL(window.location.href).searchParams.get('commerceProductId');

    // window.sessionStorage.setItem('referencesReview', REFERENCE_PURCHASED_PRODUCT);
    if (myProductDetailReviewFields['Write A Review Link']?.value?.href) {
      let urlReview = myProductDetailReviewFields['Write A Review Link']?.value?.href;
      if (commerceProductId) {
        urlReview = `${urlReview}?commerceProductId=${commerceProductId.toUpperCase()}`;
        if (productDetails.OrderId) {
          // urlReview = `${urlReview}&reference=${REFERENCE_PURCHASED_PRODUCT}&referenceId=${productDetails.ReferenceId}&OrderId=${productDetails.OrderId}`;
          urlReview = `${urlReview}&reference=${REFERENCE_PURCHASED_PRODUCT}&MyProductId=${productDetails.MyProductId}`;
        }
      } else {
        if (productDetails.OrderId) {
          // urlReview = `${urlReview}?reference=${REFERENCE_PURCHASED_PRODUCT}&referenceId=${productDetails.ReferenceId}&OrderId=${productDetails.OrderId}`;
          urlReview = `${urlReview}?reference=${REFERENCE_PURCHASED_PRODUCT}&MyProductId=${productDetails.MyProductId}`;
        }
      }

      window.location.href = urlReview;
    }
  };

  return isDisableWriteReview == DATA_IS_FALSE && (myProductDetailReviewFields && !isLoading ? (
    <div className='my-product-detail-review'>
      <div className='my-product-detail-review__container'>
        <Text tag='h4' className='my-product-detail-review__title' field={myProductDetailReviewFields['Product Review Title Text']} />
        {reviewById ? (
          <div className='my-product-detail-review__content'>
            <ProductReviewContent isShowReadMore={false} reviewItem={reviewById} layoutData={myProductDetailReviewFields} />
          </div>
        ) : (
          <Text tag='p' className='my-product-detail-review__content-no-review' field={myProductDetailReviewFields['No Review Text']} />
        )}
      </div>
      {reviewById ? (
        ''
      ) : (
        <div className='my-product-detail-review__button'>
          <span className='btn btn-outline-CTA2' onClick={() => onClickReviewLink()}>
            <Text field={myProductDetailReviewFields['Write A Review Text']} />
          </span>
        </div>
      )}
    </div>
  ) : (
    <MyProductDetailReviewShimmer myProductDetailReviewFields={myProductDetailReviewFields} />
  ));
};

MyProductDetailReview.propTypes = {
  fields: PropTypes.any
};

export default MyProductDetailReview;
