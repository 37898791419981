import { deepCopy } from '@utils/utility';

import AdditionalOptionsProductsModel from './AdditionalOptionsProductsModel';

export default class BOAdditionalOptionsModel {
  constructor() {
    this.Products = [];

    this.RelationshipId = '';

    this.SectionItemId = '';

    this.Required = true;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products')) {
      dataSource['Products'] &&
        dataSource['Products'].map((element) => {
          this.Products.push(new AdditionalOptionsProductsModel().getData(deepCopy(element) || {}));
        });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RelationshipId')) {
      this['RelationshipId'] = dataSource['RelationshipId'] ?? this['RelationshipId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SectionItemId')) {
      this['SectionItemId'] = dataSource['SectionItemId'] ?? this['SectionItemId'];
    }

    return this;
  }
}
