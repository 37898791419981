import { object } from 'prop-types';
import React, { useMemo } from 'react';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';

import DownloadButton from './DownloadButton';

const ProductHubItem = ({ item }) => {
  const isShowButton = useMemo(() => {
    return item?.['Show Border Button']?.value;
  }, [item]);

  const isShowOnlyBorder = useMemo(() => {
    return item?.['Show Only Border Button']?.value;
  }, [item]);

  const isDisableButton = useMemo(() => {
    return item?.Disable?.value;
  }, [item]);

  const isDownloadButton = useMemo(() => {
    return item?.['Is Download']?.value;
  }, [item]);

  const btnClassNames = useMemo(() => {
    const baseClassName = ['your-product-hub__item__btn', 'btn'];
    if (isShowButton) baseClassName.push('btn-primary');
    if (isShowOnlyBorder) baseClassName.push('btn-outline-primary');
    if (!isShowOnlyBorder && !isShowButton) baseClassName.push('btn-link');
    if (isDisableButton) baseClassName.push('disabled');

    return baseClassName;
  }, [isShowButton, isShowOnlyBorder, isDisableButton]);

  return (
    <div className='your-product-hub__item'>
      <div className='your-product-hub__item__icon'>
        <Image field={item?.['Icon']} />
      </div>
      <Text field={item?.['Title']} className='your-product-hub__item__title' tag='h3' />
      <Text field={item?.['Description']} className='your-product-hub__item__desc' tag='p' />
      {item?.['Button Text']?.value ? (
        !isDownloadButton ? (
          <Link field={item?.['Button Link']} className={btnClassNames.join(' ')}>
            <Text field={item?.['Button Text']} />
          </Link>
        ) : (
          <DownloadButton href={item?.['File Url']} target={item?.Target ?? ''} className={btnClassNames.join(' ')}>
            <Text field={item?.['Button Text']} />
          </DownloadButton>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

ProductHubItem.propTypes = {
  item: object
};

export default ProductHubItem;
