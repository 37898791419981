import produce from 'immer';

// Import action types
import * as productTypes from '@redux/actions/product/productTypes';

const initState = {
  productWishlistCurrent: '',
};

const setProductWishlistCurrentReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.SET_PRODUCT_WISH_LIST_CURRENT:
      draft.productWishlistCurrent = action.payload;
      break;
    }

    return draft;
  });

export default setProductWishlistCurrentReducer;
