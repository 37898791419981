import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/storeLocator';
import * as storeLocatorTypes from '@redux/actions/storeLocator/storeLocatorTypes';
import { get } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

const allStoreEpic = (action$) =>
  action$.pipe(
    ofType(storeLocatorTypes.GET_STORE_LOCATOR),
    switchMap((action) => {
      const params = {
        ...action.payload,
        keyword: ''
      };

      return from(get(config.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getStoreLocatorSuccess(deepCopy(res.data.Stores));
          } else {
            return actions.getStoreLocatorFailed('Failed');
          }
        })
      );
    })
  );

export default allStoreEpic;
