import './SFExpressPopup.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';

import Loading from '@components/Share/Loading';
import {getPickUpPoint} from '@redux/actions/cartDetail';
import {Text} from '@sitecore-jss/sitecore-jss-react'
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import SFExpressPopupItem from './SFExpressPopupItem';

const SFExpressPopup = (props) => {
  const { pickUpPoints, pickUpPointsNetworkOptions, dataSources, objMessages } = props;
  const [pickUpPointFilter, setPickUpPoints] = useState([]);

  const [valueSelected, setValueSelected] = useState({
    Area: null,
    District: null,
    Network: null,
  });

  const [optionsPickup, setOptionsPickUp] = useState({
    pickUpPointsDistrictOptions: [],
    pickUpPointsAreaOptions: [],
  });

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPickUpPoint());
  }, []);

  const onSelectNetworkChange = (option) => {
    if (option && option.ItemId !== valueSelected?.Network?.ItemId) {
      setValueSelected({
        ...valueSelected,
        Network: option,
        Area: null,
        District: null,
      });
      let pickUpPointsAreaOptionsTmp = [];

      pickUpPoints.map(pickUpItem => {
        if(pickUpItem.NetworkCode === option.NetworkCode) {
          pickUpPointsAreaOptionsTmp.push({...pickUpItem, label: pickUpItem.AreaName, value: pickUpItem.AreaCode});
        }
      });

      setOptionsPickUp({
        ...optionsPickup,
        pickUpPointsAreaOptions: Global.getUniqueListBy(pickUpPointsAreaOptionsTmp, 'AreaCode'),
        pickUpPointsDistrictOptions: []
      });
    }
  };

  const onSelectAreaChange = (option) => {
    if (option && option.ItemId !== valueSelected?.Area?.ItemId) {
      setValueSelected({
        ...valueSelected,
        Area: option,
        District: null
      });
      let pickUpPointsDistrictOptionsTmp = [];

      pickUpPoints.map(pickUpItem => {
        if(pickUpItem.AreaCode === option.AreaCode && pickUpItem.NetworkCode === option.NetworkCode) {
          pickUpPointsDistrictOptionsTmp.push({...pickUpItem, label: pickUpItem.ProvinceName, value: pickUpItem.ProvinceCode});
        }
      });

      setOptionsPickUp({
        ...optionsPickup,
        pickUpPointsDistrictOptions: Global.getUniqueListBy(pickUpPointsDistrictOptionsTmp, 'ProvinceCode'),
      });
    }
  };

  const onSelectDistrictChange = (option) => {
    if (option && option.ItemId !== valueSelected?.Provine?.ItemId) {
      setValueSelected({
        ...valueSelected,
        District: option
      });
    }
  };

  useDidUpdateEffect(() => {
    let pickUpPointsFilter = [];
    if(valueSelected.District) {
      pickUpPointsFilter = pickUpPoints.filter(pickupItem => pickupItem.NetworkCode === valueSelected.Network.NetworkCode && pickupItem.AreaCode === valueSelected.Area.AreaCode && pickupItem.ProvinceCode === valueSelected.District.ProvinceCode);
    }

    setPickUpPoints(pickUpPointsFilter);
  }, [valueSelected]);

  return pickUpPoints?.length ? (
    <div className='sf-express'>
      <p className='sf-express__des'><Text field={dataSources['SF Express Popup Description']}/></p>
      <div className='store-locator__modal'>
        <div className='store-locator__modal__item'>
          <Select
            value={valueSelected.Network}
            options={pickUpPointsNetworkOptions}
            name='area-selections'
            placeholder={dataSources['SF Express NetWork Label'].value}
            className='customization-dropdown'
            classNamePrefix='customization-dropdown'
            onChange={(option) => onSelectNetworkChange(option)}
            noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
          />
        </div>
        <div className='store-locator__modal__item'>
          <Select
            value={valueSelected.Area}
            options={optionsPickup.pickUpPointsAreaOptions}
            name='area-selections'
            placeholder={dataSources['SF Express Area Label'].value}
            className='customization-dropdown'
            classNamePrefix='customization-dropdown'
            onChange={(option) => onSelectAreaChange(option)}
            noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
          />
        </div>
        <div className='store-locator__modal__item'>
          <Select
            value={valueSelected.District}
            options={optionsPickup.pickUpPointsDistrictOptions}
            name='provine-selections'
            placeholder={dataSources['SF Express District Label'].value}
            className='customization-dropdown'
            classNamePrefix='customization-dropdown'
            onChange={(option) => onSelectDistrictChange(option)}
            noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
          />
        </div>
      </div>
      {pickUpPointFilter.length ?
        <div className='sf-express__wrap'>
          {pickUpPointFilter.map(storeItem => 
            <SFExpressPopupItem inputData={storeItem} key={storeItem.ItemId}/>
          )}
        </div>: ''}
    </div>
  ) : <Loading/>;
};

SFExpressPopup.propTypes = {
  dataFields: PropTypes.object,
  options: PropTypes.object,
  onAreaChange: PropTypes.func,
  onProvinceChange: PropTypes.func,
  valueSelectedCurrent: PropTypes.object,
  pickUpPoints: PropTypes.array,
  pickUpPointsNetworkOptions: PropTypes.array,
  dataSources: PropTypes.object,
  objMessages: PropTypes.object
};

const mapStateToProps = (state) => ({
  pickUpPoints: state.sfExpressReducer?.pickUpPoints || [],
  pickUpPointsNetworkOptions: state.sfExpressReducer?.pickUpPointsNetworkOptions || [],
  objMessages: state.getMessageReducer?.objMessages || null
});

export default connect(mapStateToProps)(SFExpressPopup);
