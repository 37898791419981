import produce from 'immer';

import {
  GET_CLIENT_SO_CART_FAILURE,
  GET_CLIENT_SO_CART_START,
  GET_CLIENT_SO_CART_SUCCESS
} from '@redux/actions/personalizeOffer/getClientSOCart/types';

import { checkCartValid } from '../../product/cartDetail';

const initialStates = {
  isLoading: false,
  error: null,
  data: {
    OsimCart: null,
    SOCart: null
  },
  customData: null
};

const generateCustomData = (data) => {
  const osimCart = data?.OsimCart || null;

  return osimCart
    ? {
      Delivery: osimCart.Lines.filter((item) => item.IsDelivery),
      NormalListProduct: osimCart.Lines.filter((item) => !item?.Deposit),
      PickUpStore: osimCart.Lines.filter((item) => !item.IsDelivery && !item.IsSFexpress && !item?.IsDeliveryBySFExpress),
      SFexpress: osimCart.Lines.filter((item) => item.IsSFexpress),
      DeliveryBySFExpress: osimCart.Lines.filter((item) => item.IsDeliveryBySFExpress),
      BillingAddress: osimCart.BillingAddress,
      Party: osimCart.Party,
      IsDeliveryFee: osimCart.Lines.filter((line) => line.IsDelivery).length ? true : false,
      IsDeliveryBySFExpressFee: osimCart.IsDeliveryBySFExpressFee,
      Staff: osimCart.Staff,
      isCartValid: checkCartValid(osimCart),
      SFExpressTotal: osimCart.SFExpressTotal,
      DepositListProduct: osimCart.Lines.filter((item) => item?.Deposit),
      CartStatus: osimCart.CartStatus,
      CustomerName: osimCart.CustomerName
    }
    : null;
};

const getClientSOCartReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_CLIENT_SO_CART_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case GET_CLIENT_SO_CART_SUCCESS:
      draft.isLoading = false;

      draft.error = null;

      if (action.payload?.OsimCart) draft.data.OsimCart = action.payload.OsimCart;
      if (action.payload?.SOCart) draft.data.SOCart = action.payload.SOCart;

      draft.customData = generateCustomData(action.payload);
      break;

    case GET_CLIENT_SO_CART_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getClientSOCartReducer;
