import produce from 'immer';

import * as commonTypes from '@redux/actions/common/commonTypes';
import { deepCopy } from '@utils/utility';

import ResourceSearchApiModel from '../models/ResourceSearchApiModel';

const initState = {
  resourceSearchData: [],
  resourceSuggestionList: [],
  resourceCount: 0,
  error: '',
  isResourceLoading: false,
  isAutoCompleteResourceLoading: false,
  params: null,
};

const resourceSearchReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let newResourceSearchData = [];

    switch (action.type) {
    // SEARCH
    case commonTypes.COMMON_SEARCH_RESOURCE:
      draft.params = action.params;

      draft.isResourceLoading = true;
      break;

    case commonTypes.COMMON_SEARCH_RESOURCE_SUCCESS:
      if (action.payload) {
        action.payload.map(item => {
          newResourceSearchData.push(new ResourceSearchApiModel().getData(item || {}));
        });

        draft.resourceSearchData = deepCopy(newResourceSearchData);
      }

      draft.resourceCount = action.count;

      draft.isResourceLoading = false;
      break;
    
    case commonTypes.COMMON_SEARCH_RESOURCE_FAILED:
      draft.isResourceLoading = false;

      draft.error = action.error;

      break;

    // AUTO COMPLETE
    case commonTypes.GET_AUTO_COMPLETE_RESOURCE:

      draft.isAutoCompleteResourceLoading = true;

      break;

    case commonTypes.GET_AUTO_COMPLETE_RESOURCE_SUCCESS:
      draft.resourceSuggestionList = action.payload;

      draft.isAutoCompleteResourceLoading = false;

      break;
    
    case commonTypes.GET_AUTO_COMPLETE_RESOURCE_FAILED:

      draft.error = action.error;

      draft.isAutoCompleteResourceLoading = false;

      break;
    }

    return draft;
  });

export default resourceSearchReducer;
