import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/cpGetStoreList';
import { CP_GET_STORE_LIST } from '@redux/actions/cpGetStoreList/cpGetStoreListTypes';
import { get } from '@services/genericService';

import * as config from '../config';

export const cpGetStoreListEpic = (action$) =>
  action$.pipe(
    ofType(CP_GET_STORE_LIST),
    switchMap(() => {
      return from(get(config.ACTION_CP_GET_STORE_LIST)).pipe(
        map((res) => {
          if (res.data?.StatusCode == 200) {
            return actions.cpGetStoreListSuccess(res.data?.Stores);
          } else return actions.cpGetStoreListFailed('Failed');
        })
      );
    })
  );
