//GetUserProducts
export const GET_MY_PRODUCT = 'GET_MY_PRODUCT';

export const GET_MY_PRODUCT_SUCCESS = 'GET_MY_PRODUCT_SUCCESS';

export const GET_MY_PRODUCT_FAILED = 'GET_MY_PRODUCT_FAILED';

//GetUserProductDetails
export const GET_USER_PRODUCT_DETAILS = 'GET_USER_PRODUCT_DETAILS';

export const GET_USER_PRODUCT_DETAILS_SUCCESS = 'GET_USER_PRODUCT_DETAILS_SUCCESS';

export const GET_USER_PRODUCT_DETAILS_FAILED = 'GET_USER_PRODUCT_DETAILS_FAILED';

//GetLatestMyProduct
export const GET_LATEST_MY_PRODUCT = 'GET_LATEST_MY_PRODUCT';

export const GET_LATEST_MY_PRODUCT_SUCCESS = 'GET_LATEST_MY_PRODUCT_SUCCESS';

export const GET_LATEST_MY_PRODUCT_FAILED = 'GET_LATEST_MY_PRODUCT_FAILED';

//Remove my product
export const REMOVE_MY_PRODUCT = 'REMOVE_MY_PRODUCT';

export const REMOVE_MY_PRODUCT_SUCCESS = 'REMOVE_MY_PRODUCT_SUCCESS';

export const REMOVE_MY_PRODUCT_FAILED = 'REMOVE_MY_PRODUCT_FAILED';