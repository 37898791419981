import './OfferAppliedModal.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const OfferAppliedModal = (props) => {
  const { listPromotion } = props;

  return (
    <Fragment>
      <Text field={props.dataFields['Applied Offers Description']} tag='p' className='osim-popup__description' />
      {listPromotion.map((item) => {
        return (
          <div className='applied-promotion' key={item.Id}>
            <h4 className='applied-promotion__title'>{item.Title}</h4>
            <div className='applied-promotion__description' dangerouslySetInnerHTML={{__html: item.Description}}></div>
            {item.Code ? (
              <div className='btn-link' onClick={() => props.onAppliedOffersModalComponentHandler(true, item)}>
                <Text field={props.dataFields['Learn More Label']} />
              </div>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </Fragment>
  );
};

OfferAppliedModal.propTypes = {
  listPromotion: PropTypes.any,
  dataFields: PropTypes.object,
  onAppliedOffersModalComponentHandler: PropTypes.func
};

export default OfferAppliedModal;
