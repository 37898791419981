import SitecoreImageModel from '@models/SitecoreImageModel';

export default class ImageFieldsModel {
  constructor() {
    this.Image = new SitecoreImageModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }

    return this;
  }
}
