import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import CampaignQRCodeItemModel from '../CampaignQRCodeItem/Models/CampaignQRCodeItemModel';

export default class CampaignQRCodeModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();

    this['Campaign List'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Campaign List')) {
      dataSource['Campaign List'].map((item) => {
        this['Campaign List'].push(new CampaignQRCodeItemModel().getData(deepCopy(item) || {}));
      });

      
    }

    return this;
  }
}