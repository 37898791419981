import parse from 'html-react-parser';
import { bool, func, string } from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

const Subtitle = ({ text, onOpenModal, isLoginSuccess }) => {
  const [string, htmlTag] = parse(text);

  const handleOpenModal = () => {
    if (onOpenModal) onOpenModal();
  };

  return text && htmlTag ? (
    <h5 className='subtitle-text'>
      {string}{' '}
      {!isLoginSuccess
        ? React.cloneElement(htmlTag, {
          className: 'subtitle-btn',
          onClick: handleOpenModal
        })
        : ''}
    </h5>
  ) : (
    <></>
  );
};

Subtitle.propTypes = {
  text: string,
  onOpenModal: func,
  isLoginSuccess: bool
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin
});

export default connect(mapStateToProps)(Subtitle);
