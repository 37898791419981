import { GET_MY_REVIEWS_FAILED, GET_MY_REVIEWS_START, GET_MY_REVIEWS_SUCCESS } from './myReviewsTypes';

export const getMyReviewsStart = (pageSz = 10) => ({
  type: GET_MY_REVIEWS_START,
  payload: pageSz
});

export const getMyReviewsSuccess = (reviewsData) => ({
  type: GET_MY_REVIEWS_SUCCESS,
  payload: reviewsData
});

export const getMyReviewsFailed = (error) => ({
  type: GET_MY_REVIEWS_FAILED,
  payload: error
});
