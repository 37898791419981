import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const BundleBOFirstTypeHeader = ({ isOpen, labelText, changeText, selectedItemsName, onToogleFnc, errorMsg, isShowError, contentData }) => {
  const [activeChangeButton, setActiveChangeButton] = useState(false);

  useEffect(() => {
    contentData.length === 1 ? setActiveChangeButton(false) : setActiveChangeButton(true);
  }, []);

  return (
    <div className='accordion-buying-option__header'>
      <h3 className='bo-title-section'>{isOpen ? labelText : selectedItemsName}</h3>
      {activeChangeButton ? (
        <span className={'btn-change' + `${isOpen ? '' : ' active'}`} onClick={onToogleFnc}>
          {changeText}
        </span>
      ) : (
        <></>
      )}
      <span className={'error-msg' + `${isShowError ? ' active' : ''}`}>{errorMsg}</span>
    </div>
  );
};

BundleBOFirstTypeHeader.propTypes = {
  isOpen: PropTypes.any,
  labelText: PropTypes.any,
  changeText: PropTypes.any,
  selectedItemsName: PropTypes.any,
  onToogleFnc: PropTypes.any,
  errorMsg: PropTypes.any,
  isShowError: PropTypes.any,
  contentData: PropTypes.array
};

export default BundleBOFirstTypeHeader;
