import './ProductSpecialDealsItem.scss';

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

const ProductSpecialDealsItem = ({ dataFromLayout, dataFields, isShowBuyNow, newTab, indexGA4 }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const [buyNowLink, setBuyNowLink] = useState(deepCopy(dataFromLayout['Buy Now Link']));

  const isGA4 = Global.getIsGA4();
  const itemLstName = dataFields?.['Title']?.value;
  const categoryName = dataFromLayout?.CategoryName;

  useEffect(() => {
    let tempLink = deepCopy(buyNowLink);

    tempLink.value.href = `${dataFromLayout['Buy Now Link'].value.href}&LstName=${itemLstName}`;

    setBuyNowLink(tempLink);
  }, []);

  const handleGa4SelectItem = (action) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      let dataConvert = [];
  
      dataConvert.push(dataFromLayout);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(indexGA4, dataConvert, '', pdpPromoCode, '', '',  itemLstName)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }
  };

  return (
    <Fragment>
      <div className='product-deals__list__item__content'>
        <a
          href={`${dataFromLayout.Url}?IndexGA4=${indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}`}
          className='product-deals__list__item__image'
          target={newTab ? '_blank' : ''}
          rel='noreferrer'
          onClick={() => handleGa4SelectItem('Image')}
        >
          <img className='product-deals-image swiper-lazy animated' data-src={dataFromLayout.Image} alt='product' />
          <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
        </a>
        <h3 className='product-deals__info__name' title={dataFromLayout.ProductName}>
          <a
            href={`${dataFromLayout.Url}?IndexGA4=${indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}`}
            className='product-deals__info__name-link'
            target={newTab ? '_blank' : ''}
            rel='noreferrer'
            onClick={() => handleGa4SelectItem('Image')}
          >
            {dataFromLayout.ProductName}
          </a>
        </h3>
        <div className='product-deals__info__price'>
          <span className='price-value'>{dataFromLayout.Price?.SellingPriceWithCurrency}</span>
        </div>
        {dataFromLayout.Price?.SellingPriceWithCurrency !== dataFromLayout.Price?.ListPriceWithCurrency ? (
          <del className='product-deals__info__old-price'>
            <span className='price-value'>{dataFromLayout.Price?.ListPriceWithCurrency}</span>
          </del>
        ) : (
          <></>
        )}
      </div>
      {dataFromLayout?.IsBundle ? (
        isShowBuyNow['Is Show BuyNow'] && isShowBuyNow['Is Show BuyNow'] === '1' ? (
          <div className='product-deals__list__item__buy-btn'>
            <a className='btn btn-outline-CTA2' href={dataFromLayout.Url} target={newTab ? '_blank' : ''} rel='noreferrer'>
              {messageObj?.['LBL-LearnMore']}
            </a>
          </div>
        ) : (
          <></>
        )
      ) : isShowBuyNow['Is Show BuyNow'] && isShowBuyNow['Is Show BuyNow'] === '1' ? (
        <div className='product-deals__list__item__buy-btn'>
          <BuyButtonComponent productData={{ ...dataFromLayout, productId: `${dataFromLayout.ProductId}|${dataFromLayout.RepresentativeVariantId}` }}>
            <Link className='btn btn-primary' field={buyNowLink} onClick={() => handleGa4SelectItem('Buy Now')}>
              <span>{dataFromLayout.Stock.PurchasableLabel}</span>
            </Link>
          </BuyButtonComponent>
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

ProductSpecialDealsItem.propTypes = {
  dataFromLayout: PropTypes.object,
  dataFields: PropTypes.object,
  isShowBuyNow: PropTypes.object,
  newTab: PropTypes.bool,
  indexGA4: PropTypes.number
};

export default ProductSpecialDealsItem;
