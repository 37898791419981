import PropTypes from 'prop-types';
import React from 'react';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const EventCheckbox = ({ layoutData, arrCheckbox, item, handleUpdate, handleRemove, isCheckFieldDefault }) => {
  return (
    <div className='form-group-right'>
      {arrCheckbox?.length > 1 ? (
        !(item?.value === isCheckFieldDefault) && (
          <div className='form-group-right__box'>
            <button
              className='form-group-right__btn'
              type='button'
              onClick={() => {
                handleUpdate(item, 'edit');
              }}
            >
              <Text field={layoutData?.Edit} />
            </button>
            <button
              className='form-group-right__btn'
              type='button'
              onClick={() => {
                handleRemove();
              }}
            >
              <Text field={layoutData?.Remove} />
            </button>
          </div>
        )
      ) : (
        <>
          {item.value === '' || item.value === undefined ? (
            <button
              type='button'
              className='form-group-right__btn'
              onClick={() => {
                handleUpdate(item, 'add');
              }}
            >
              <Text field={layoutData?.['Add new']} />
            </button>
          ) : (
            <div className='form-group-right__box'>
              {!isCheckFieldDefault && (
                <>
                  <button
                    type='button'
                    className='form-group-right__btn'
                    onClick={() => {
                      handleUpdate(item, 'edit');
                    }}
                  >
                    <Text field={layoutData?.Edit} />
                  </button>
                  <button
                    type='button'
                    className='form-group-right__btn'
                    onClick={() => {
                      handleRemove();
                    }}
                  >
                    <Text field={layoutData?.Remove} />
                  </button>
                </>
              )}
              <button
                type='button'
                className='form-group-right__btn'
                onClick={() => {
                  handleUpdate(item, 'add');
                }}
              >
                <Text field={layoutData?.['Add new']} />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

EventCheckbox.prototype = {
  layoutData: PropTypes.string,
  arrCheckbox: PropTypes.array,
  item: PropTypes.string,
  handleUpdate: PropTypes.func,
  handleRemove: PropTypes.func,
  isCheckFieldDefault: PropTypes.any
};

export default EventCheckbox;
