import Proptypes from 'prop-types';
import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const TwoColumn41 = (props) => (
  <div className='two-column'>
    <Placeholder name='osim-col-wide-1' rendering={props.rendering} />
    <div className='group-lang-social'>
      <Placeholder name='osim-col-narrow-1' rendering={props.rendering} />
    </div>
  </div>
);

TwoColumn41.propTypes = {
  rendering: Proptypes.any
};

export default TwoColumn41;
