import { useSelector } from 'react-redux';
const LBL_LABEL = ['LBL-BackOrder', 'LBL-InStock', 'LBL-OutOfStock', 'LBL-PreOrder'];

const Message = ({ messageCode, labelShipping }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const isLabelStatus = LBL_LABEL.indexOf(messageCode) >=0 ? true : false;

  return !isLabelStatus ? messageObj?.[messageCode] || ''
    : messageObj?.[messageCode] ?  `${messageObj?.[messageCode]}${labelShipping ? `. ${labelShipping}` : '' }` : labelShipping || '';
};

export default Message;
