import './CookieBanner.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setCookieBannerHeight } from '@redux/actions/layoutElementStyle';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import CookieBannerModel from './models/CookieBannerModel';

const CookieBanner = ({ fields }) => {
  const dispatch = useDispatch();
  const cookieBannerRef = useRef(null);
  const [layoutData, setLayoutData] = useState(null);
  const [isShowCookieBanner, setIsShowCookieBanner] = useState(true);

  useEffect(() => {
    let model = new CookieBannerModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    const isAcceptedCookie = JSON.parse(window.localStorage.getItem('acceptedCookie'));

    if (isAcceptedCookie) {
      const expireInDate = Date.parse(new Date(isAcceptedCookie?.expireIn));
      const currentDate = Date.parse(new Date(Date.now()));
      if (currentDate <= expireInDate) {
        setIsShowCookieBanner(false);
      }
      if (currentDate > expireInDate) {
        setIsShowCookieBanner(true);

        window.localStorage.removeItem('acceptedCookie');
      }
    } else {
      setIsShowCookieBanner(true);
    }
  }, []);

  useLayoutEffect(() => {
    const handleSetCookieBannerHeight = () => {
      if (cookieBannerRef?.current) {
        const cookieBannerCurrent = cookieBannerRef.current;
        const cookieBannerHeight = cookieBannerCurrent.offsetHeight;

        dispatch(setCookieBannerHeight(cookieBannerHeight));
      } else {
        dispatch(setCookieBannerHeight(0));
      }
    };

    document.addEventListener('resize', handleSetCookieBannerHeight);

    handleSetCookieBannerHeight();

    return () => document.removeEventListener('resize', handleSetCookieBannerHeight);
  }, [cookieBannerRef, layoutData, isShowCookieBanner, layoutData?.['IsShow']]);

  const handleAcceptCookie = () => {
    const currentDate = new Date();
    const dayExpire = Number(layoutData?.['Day expires']?.value) || 30;

    // set expiration time from current date
    currentDate.setDate(currentDate.getDate() + dayExpire);

    setIsShowCookieBanner(false);

    window.localStorage.setItem(
      'acceptedCookie',
      JSON.stringify({
        acceptedCookie: true,
        expireIn: currentDate
      })
    );
  };

  const handleGenerateColor = (value) => (value ? `#${value.replace('#', '')}` : '');

  return layoutData && isShowCookieBanner && layoutData?.['IsShow'] ? (
    <div
      ref={cookieBannerRef}
      id='cookie-banner'
      className='cookie-banner'
      style={{
        backgroundColor: handleGenerateColor(layoutData['Background color'].value)
      }}
    >
      <div className='text-layer'></div>
      <div className='container'>
        <div className='cookie-banner__container'>
          <div className='cookie-banner__content' style={{ color: handleGenerateColor(layoutData['Text color'].value) }}>
            {layoutData['Title'].value ? <Text tag='h6' field={layoutData['Title']} className='title' /> : <></>}
            <RichText tag='p' field={layoutData['Content']} className='description' />
          </div>
          <div className='cookie-banner__cta'>
            <button className='btn btn-primary' onClick={handleAcceptCookie}>
              <Text field={layoutData['Text button']} />
            </button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

CookieBanner.propTypes = {
  fields: Proptypes.any
};

export default CookieBanner;
