import produce from 'immer';

import * as spinAndWinTypes from '@redux/actions/spinAndWin/spinAndWinTypes';

const initState = {
  isLoading: false,
  myPrizeLuckyDrawData: null
};

const getMyPrizeLuckyDrawReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case spinAndWinTypes.GET_MY_PRIZE_LUCKY_DRAW:
      draft.isLoading = true;
      break;

    case spinAndWinTypes.GET_MY_PRIZE_LUCKY_DRAW_SUCCESS:

      draft.isLoading = false;

      draft.myPrizeLuckyDrawData = action.payload.Promotions;
      break;

    case spinAndWinTypes.GET_MY_PRIZE_LUCKY_DRAW_FAILED:
      draft.isLoading = false;
      break;

    case spinAndWinTypes.CLEAR_DATA_INPUT_SPIN:
      draft.myPrizeLuckyDrawData = null;
      break;
    }

    return draft;
  });

export default getMyPrizeLuckyDrawReducer;
