import './PromotionDetailBundleList.scss';

import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import {deepCopy} from '@utils/utility';

import BundleItemModel from '../CreateBundle/model/BundleItemModel';
import DynamicBundleElementsModel from './model/DynamicBundleElementsModel';
import PromotionDetailBundleItem from './PromotionDetailBundleItem';

const BUNDLE_ITEM_TO_SHOW = 6;

const DynamicBundleElements = ({fields}) => {
  const [layoutData, setLayoutData] = useState(null);
  const [listBundleItem, setListBundleItem] = useState(null);
  const [itemToShow, setItemToShow] = useState(BUNDLE_ITEM_TO_SHOW);
  
  useEffect(() => {
    const model = new DynamicBundleElementsModel();

    setLayoutData(model.getData(deepCopy(fields)));

    setListBundleItem(fields['Dynamic Bundle Data']?.IncludedProducts || []);
  }, []);

  return layoutData && listBundleItem ? (
    <div className='promotion-detail-bundle-list animated-slow fadeIn delay-100'>
      <div className='container'>
        <h2 className='promotion-detail-bundle-list__title text-center bundle-title'>{layoutData['Title'].value}</h2>
        <div className={listBundleItem && listBundleItem.length < 4 ? 'row center-item' : 'row'}>
          {listBundleItem &&
            listBundleItem.length > 0 &&
            listBundleItem.slice(0, itemToShow).map((item, index) => {
              const itemData = new BundleItemModel().getData(item);

              return (
                <div className='col-md-6 col-xl-3 promotion-detail-bundle-list__col' key={index}>
                  <PromotionDetailBundleItem data={itemData} layoutData={layoutData}></PromotionDetailBundleItem>
                </div>
              );
            })}
        </div>
        {itemToShow <= listBundleItem.length ? (
          <div className='text-center'>
            <span
              onClick={() => setItemToShow(itemToShow + BUNDLE_ITEM_TO_SHOW)}
              className='promotion-detail-bundle-list__button btn btn-outline-CTA2'
            >
              {layoutData['Load More Label'].value}
            </span>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='promotion-detail-bundle-list skeleton__item' id='bundle-element'>
        <div className='container'>
          <h2 className='promotion-detail-bundle-list__title text-center bundle-title'>
            <span className='text-line-heading-center w-40 hidden-xs-down'></span>
            <span className='text-line-heading-center w-80 hidden-md-up'></span>
          </h2>
          <div className='row'>
            <LoadingShimmer itemNumb={4}>
              <div className='col-md-6 col-xl-3 promotion-detail-bundle-list__col'>
                <div className='promotion-detail-bundle-item'>
                  <div className='promotion-detail-bundle-item__contents'>
                    <div className='promotion-detail-bundle-item__thumb__picture image-wrap'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-10'></span>
                    <div className='text-line'></div>
                    <span className='clear-10'></span>
                    <div className='text-line'></div>
                    <span className='clear-10'></span>
                    <div className='text-line'></div>
                    <span className='clear-10'></span>
                    <div className='text-line'></div>
                  </div>
                </div>
              </div>
            </LoadingShimmer>
          </div>
        </div>
      </div>
    </LoadingShimmer>
  );
};

DynamicBundleElements.propTypes = {
  fields: PropTypes.any
};

export default DynamicBundleElements;
