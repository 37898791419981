import { useFormik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';

import { useScheduleAppointmentContext } from '../../hooks/useScheduleAppointmentContext';

export const usePersonalizeExperienceFormik = (onSubmit) => {
  const { layoutData, scheduleData } = useScheduleAppointmentContext();

  const initialValues = useMemo(() => {
    const additionalSelectionAns = scheduleData.personalizeExperience.additionalSelectionAns;
    if (!additionalSelectionAns.length && layoutData?.questions?.length) {
      for (const question of layoutData?.questions) {
        additionalSelectionAns.push({
          QuestionId: question.Id,
          IsRequired: question.IsRequired,
          AnswerIds: []
        });
      }
    }

    return {
      teaId: scheduleData.personalizeExperience.teaId,
      scentId: scheduleData.personalizeExperience.scentId,
      additionalSelectionAns: additionalSelectionAns
    };
  }, [layoutData, scheduleData]);

  return useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      additionalSelectionAns: Yup.array(
        Yup.object().shape({
          IsRequired: Yup.boolean().notRequired(),
          AnswerIds: Yup.array().when('IsRequired', {
            is: true,
            then: (rule) => rule.min(1, layoutData?.['Select Answers Message']?.value)
          })
        })
      )
    }),
    onSubmit: (values) => {
      if (onSubmit) {
        // INFO: remove IsRequired field
        for (const item of values.additionalSelectionAns) {
          delete item.IsRequired;
        }

        onSubmit(values);
      }
    }
  });
};
