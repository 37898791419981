import './AdditionalFullFilmentOption.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import AdditionalFullFilmentOptionModel from './model/AdditionalFullFilmentOptionModel';

const AdditionalFullFilmentOption = ({ inputData, cssCustom }) => {
  const [isShowSFExpress, setShowSFExpress] = useState(false);
  const [dataSources, setInputData] = useState('');

  const onCloseModalHandler = () => {
    setShowSFExpress(false);
  };

  useEffect(() => {
    setInputData(new AdditionalFullFilmentOptionModel().getData(inputData || {}));
  }, []);

  return dataSources ? (
    <Fragment>
      {dataSources?.['Additional Information Link']?.value?.href.length ? (
        <Link
          className={cssCustom ? `link-text-underline ${cssCustom}` : 'link-text-underline'}
          field={dataSources?.['Additional Information Link']}
        >
          <Text field={dataSources['Surcharge For Urgent Delivery Label']} />
        </Link>
      ) : (
        <span className={cssCustom ? `link-text-underline ${cssCustom}` : 'link-text-underline'} onClick={() => setShowSFExpress(true)}>
          <Text field={dataSources['Surcharge For Urgent Delivery Label']} />
        </span>
      )}
      <ModalComponent
        isShow={isShowSFExpress}
        onCloseHandler={onCloseModalHandler}
        titlePopup={<Text field={dataSources['Additional Information Title']} />}
      >
        <RichText className='additional-fullfilment__content' field={dataSources['Additional Information Text']} />
      </ModalComponent>
    </Fragment>
  ) : (
    ''
  );
};

AdditionalFullFilmentOption.propTypes = {
  inputData: PropTypes.object,
  cssCustom: PropTypes.string
};

export default AdditionalFullFilmentOption;
