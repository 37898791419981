import Proptypes from 'prop-types';
import React, { useState } from 'react';

import { PARAMS_SUBMIT } from '@components/CategoryLanding/ProductListing/config';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

const FilterProductByColor = (props) => {
  const { dataSources, colors, filterColorCurrent, isToogleClearFilter, isHelpMeDecide } = props;
  const [colorSelect, setSelect] = useState(filterColorCurrent?.length ? filterColorCurrent : []);

  useDidUpdateEffect(() => {
    setSelect([]);
  }, [isToogleClearFilter]);

  useDidUpdateEffect(() => {
    props.setSelectColorEvt({ key: PARAMS_SUBMIT.Colors, value: colorSelect });
  }, [colorSelect]);

  const onSelectColorHandler = (event) => {
    const colorSelectTmp = deepCopy(colorSelect);
    if (event.currentTarget.checked) {
      colorSelectTmp.push(event.currentTarget.value);
      setSelect(colorSelectTmp);
    } else {
      const newColorFilter = colorSelectTmp.filter((value) => value !== event.currentTarget.value);
      setSelect(newColorFilter);
    }
  };

  return colors?.Details.length ? (
    <div className='filter-popup__section'>
      <h3 className='filter-popup__section__heading'>
        {dataSources.Name.value}
      </h3>
      <ul className='filter-popup__section__color-list'>
        {colors.Details.map((colorItem, colorItemIdx) => (
          <li key={colorItem.Value}>
            <label
              title={colorItem.Name}
              className={`filter-popup__section__color-item ${colorSelect.indexOf(colorItem.Value) >= 0 ? 'is-select' : ''}`}
              key={colorItem.Value}
            >
              <input
                className='osim-checkbox-input'
                checked={colorSelect.indexOf(colorItem.Value) >= 0 ? true : false}
                onChange={(event) => onSelectColorHandler(event)}
                type='checkbox'
                value={colorItem.Value}
                id={`osim-checkbox-color-${colorItemIdx + 1}`}
              />
              <div
                className='filter-popup__section__color-item__block'
                htmlFor={`osim-checkbox-color-${colorItemIdx + 1}`}
                style={{
                  backgroundColor: colorItem.HexCode ? colorItem.HexCode : ''
                }}
              ></div>
            </label>
          </li>
        ))}
      </ul>
    </div>
  ) : (
    ''
  );
};

FilterProductByColor.propTypes = {
  dataSources: Proptypes.object,
  colors: Proptypes.object,
  setSelectColorEvt: Proptypes.func,
  filterColorCurrent: Proptypes.any,
  isHelpMeDecide: Proptypes.bool,
  isToogleClearFilter: Proptypes.bool
};

export default FilterProductByColor;
