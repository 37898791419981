import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'Deposit Usual Label',
  'Deposit Pre Order Label',
  'Deposit Label',
  'Deposit Instant Off Label',
  'Pay Deposit Label',
  'End At Label',
  'Balance Payment Label',
  'Payment Period Label',
  'Ship Out Details',
  'Term And Condition Label',
  'Term and Condition',
  'Ship Out Label',
  'To Text'
];

export default class ProductDetailOverviewModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });

    this['Explore Colors Label'] = new SitecoreTextModel();

    this['CTA Button'] = new SitecoreTextModel();

    this['Learn More Text'] = new SitecoreTextModel();

    this['CTA Button Label'] = new SitecoreTextModel();

    this['In Stock Label'] = new SitecoreTextModel();

    this['In Stock With BackOrder Label'] = new SitecoreTextModel();

    this['In Stock With PreOrder Label'] = new SitecoreTextModel();

    this['Out Stock Label'] = new SitecoreTextModel();

    this['Usual Price Text'] = new SitecoreTextModel();

    this['Deposit Label'] = new SitecoreTextModel();

    this['Refundable'] = new SitecoreTextModel();

    this['Rental Price Label'] = new SitecoreTextModel();

    this['Payment Gateways'] = [];
  }
  getData(dataSource) {
    TEXT_ARR.forEach((text) => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text].getData(dataSource[text]);
      }
    });
    
    const contextDataSource = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.fields;
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Explore Colors Label')) {
      this['Explore Colors Label'].getData(dataSource['Explore Colors Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Label')) {
      this['CTA Button Label'].getData(dataSource['CTA Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Text')) {
      this['Learn More Text'].getData(dataSource['Learn More Text']);
    }
    if (dataSource && contextDataSource && Object.prototype.hasOwnProperty.call(contextDataSource, 'In Stock Label')) {
      this['In Stock Label'].getData(contextDataSource['In Stock Label']);
    }
    if (dataSource && contextDataSource && Object.prototype.hasOwnProperty.call(contextDataSource, 'In Stock With BackOrder Label')) {
      this['In Stock With BackOrder Label'].getData(contextDataSource['In Stock With BackOrder Label']);
    }
    if (dataSource && contextDataSource && Object.prototype.hasOwnProperty.call(contextDataSource, 'In Stock With PreOrder Label')) {
      this['In Stock With PreOrder Label'].getData(contextDataSource['In Stock With PreOrder Label']);
    }
    if (dataSource && contextDataSource && Object.prototype.hasOwnProperty.call(contextDataSource, 'Out Stock Label')) {
      this['Out Stock Label'].getData(contextDataSource['Out Stock Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Usual Price Text')) {
      this['Usual Price Text'].getData(dataSource['Usual Price Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Label')) {
      this['Deposit Label'].getData(dataSource['Deposit Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Refundable')) {
      this['Refundable'].getData(dataSource['Refundable']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Rental Price Label')) {
      this['Rental Price Label'].getData(dataSource['Rental Price Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Payment Gateways')) {
      this['Payment Gateways'] = deepCopy(dataSource['Payment Gateways']);
    }

    return this;
  }
}
