import parse from 'html-react-parser';
import { string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { getCategoryForRegisterWarrantyData } from '@redux/actions/categoryForRegisterWarranty';

const CategorySelect = ({ defaultValue, ...props }) => {
  const dispatch = useDispatch();
  const categoryForRegisterData = useSelector((state) => state.categoryForRegisterWarrantyReducer.categoryDataForRegister);
  const [categoryOptions, setCategoryOptions] = useState([]);

  useEffect(() => {
    dispatch(getCategoryForRegisterWarrantyData());
  }, []);

  useEffect(() => {
    // custom category data array
    if (categoryForRegisterData.length) {
      const categoryOptionsTemps = [];
      for (const categoryItem of categoryForRegisterData) {
        categoryOptionsTemps.push({
          label: parse(categoryItem.CategoryName || ''),
          value: categoryItem.CategoryItemId
        });
      }

      setCategoryOptions(categoryOptionsTemps);
    }
  }, [categoryForRegisterData]);

  const categoryValue = useMemo(() => {
    return categoryOptions.find((item) => item.value === defaultValue);
  }, [defaultValue, categoryOptions]);

  return (
    <Select
      {...props}
      id='categoryItemId'
      name='categoryItemId'
      value={categoryValue}
      className='customization-dropdown'
      classNamePrefix='customization-dropdown'
      isSearchable={true}
      options={categoryOptions}
    />
  );
};

CategorySelect.propTypes = {
  defaultValue: string
};

export default CategorySelect;
