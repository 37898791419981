import './ShowInformationTiers.scss';

import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import {useDispatch, useSelector} from 'react-redux';

import { getPosCustomerId } from '@redux/actions/loyalty/getPosCustomerId';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import { convertEvalDate, formatTierAmountNumber } from '../MyRewardsWidget/utils';
import ShowInformationTiersModel from './models/ShowInformationTiersModel';

const ShowInformationTiers = ({ fields }) => {
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);
  const dataCrm = useSelector((state) => state.loyaltyReducer.getCrmCustomerReducer.dataCrm);
  const countryCode = SitecoreContextFactoryService.getValueContextItem('site');

  useEffect(() => {
    setLayoutData(new ShowInformationTiersModel().getData(fields));
  }, []);

  useEffect(() => {
    dispatch(
      getPosCustomerId({
        country: countryCode.name.replace('O', '')
      })
    );
  }, [countryCode]);

  const tierIcon = useMemo(() => {
    const listImageTiers = layoutData ? layoutData.ListImageTiers : [];

    return listImageTiers.length && dataCrm?.Data
      ? listImageTiers?.find((imageTier) => imageTier?.CodeLoyalty?.toLowerCase() === dataCrm.Data?.crm_tier?.toLowerCase())?.UrlImage
      : '';
  }, [dataCrm, layoutData]);

  const spendAmountDescription = useMemo(() => {
    const tierData = dataCrm?.Data;

    return layoutData && tierData
      ? layoutData['Spend Level Member Label']?.value
        ?.replace('{SpentAmountNextStr}', formatTierAmountNumber(global.roundUpDecimal(Number(tierData?.crm_amt_retain || 0))))
        ?.replace('{NameLoyaltyNext}', tierData?.crm_tier)
      : '';
  }, [dataCrm, layoutData]);

  const levelReviewDescription = useMemo(() => {
    const tierData = dataCrm?.Data;
    const evalDateConverted = tierData?.crm_eval_date_converted ? convertEvalDate(tierData?.crm_eval_date) : '';

    return layoutData && tierData ? layoutData['Review Level Date']?.value?.replace('{DateTimeReview}', evalDateConverted) : '';
  }, [dataCrm, layoutData]);

  const tierTitle = useMemo(() => {
    const tierData = dataCrm?.Data;
    const listTitleTiers = layoutData?.['ListTitleTiers'] || [];

    return listTitleTiers.length && tierData
      ? listTitleTiers.find((title) => title?.CodeLoyalty.toLowerCase() === dataCrm.Data?.crm_tier?.toLowerCase())?.Title
      : '';
  }, [layoutData, dataCrm]);

  return layoutData && dataCrm ? (
    <div className='container show-tier-information'>
      <div className='show-tier-information__header'>
        <div className='show-tier-information__header-icon'>
          <div className='show-tier-information__header-icon__wrapper'>
            <img src={tierIcon} alt='' />
          </div>
        </div>
        <h2 className='show-tier-information__header-text'>{tierTitle}</h2>
      </div>
      <div className='show-tier-information__description'>
        {spendAmountDescription ? (
          <RichText tag='p' className='show-tier-information__description-text' field={{ value: spendAmountDescription }} />
        ) : (
          <></>
        )}
        {levelReviewDescription ? <p className='show-tier-information__description-text'>{levelReviewDescription}</p> : <></>}
      </div>
    </div>
  ) : (
    <></>
  );
};

ShowInformationTiers.propTypes = {
  fields: object
};

export default ShowInformationTiers;
