import './EditAppointmentForm.scss';

import { func } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { cpUpdateAppointment } from '@redux/actions/cpUpdateAppointment';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import useAppointmentDetailContext from '../AppointmentDetail/useAppointmentDetailContext';
import AppointmentsInfor from '../AppointmentsInfor';
import useEditAppointmentFormik from '../useEditAppointmentFormik';
import EditAppointmentFormContext from './EditAppointmentFormContext';
import QuestionComponent from './QuestionComponent';
import SelectProductCP from './SelectProductCP';
import SelectScentCP from './SelectScentCP';
import SelectStatus from './SelectStatus';
import SelectTeaCP from './SelectTeaCP';

const EditAppointmentForm = ({ onCloseHandler }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.cpUpdateAppointmentReducer.isLoading);
  const endpointStatus = useSelector((state) => state.cpUpdateAppointmentReducer.EndpointStatus);
  const [previousValueCampaign, setPreviousValueCampaign] = useState(null);

  const { layoutData, appointmentDetail, statusCode, setStatusCode } = useAppointmentDetailContext();
  const isBookExperience = appointmentDetail?.IsBookExperience;

  const pastAppointmentEdit = useMemo(() => {
    return appointmentDetail?.PastAppointmentEdit;
  }, [appointmentDetail]);

  const handleSubmitEdit = (values) => {
    if (previousValueCampaign && JSON.stringify(previousValueCampaign) === JSON.stringify(values)) {
      if (!Object.keys(formik.errors).length) {
        onCloseHandler();
      }
    } else {
      setStatusCode(0);

      const paramAppointment = {
        'Id': layoutData?.appointmentDetail?.AppointmentItemDetail?.Id,
        'Date': values.date,
        'Status': values.status,
        'Notes': values.note,
        'StoreId': appointmentDetail?.StoreId,
        'CampaignId': appointmentDetail?.CampaignId,
        'NumberOfGuests': values?.numberOfGuest?.value,
        'RootStoreId': appointmentDetail?.RootStoreId,
        'Questions': values.contactAnswers,
        'ActivityLogs': {
          'LogStatus': values.status === 1 ? 2 : 1
        }
      };

      const paramExperience = {
        ...paramAppointment,
        'TimeSlotId': values?.timeSlotId?.value,
        'ProductId': values?.productId?.value,
        'IsBookExperience': isBookExperience,
      };

      if (layoutData?.teas?.length) {
        paramExperience.TeaId = values?.teaId?.value;
      }

      if (layoutData?.scents?.length) {
        paramExperience.ScentId = values?.scentId?.value;
      }

      // Call API Change
      dispatch(cpUpdateAppointment(isBookExperience ? paramExperience : paramAppointment));
    }
  };

  useDidUpdateEffect(() => {
    if (endpointStatus) {
      setStatusCode(endpointStatus);
    }
  }, [endpointStatus]);

  const formik = useEditAppointmentFormik(appointmentDetail, handleSubmitEdit);

  // Set previousValueCampaign
  useEffect(() => {
    if (appointmentDetail) {
      const contactAnswers = [];
      for (const question of appointmentDetail?.['Questions']) {
        let questionObj = {
          Id: question.Id,
          IsRequired: question.IsRequired ? true : false,
          Answers: []
        };
  
        for (const answer of question.Answers) {  
          questionObj.Answers.push({AnswerId: answer.AnswerId});
        }
  
        contactAnswers.push(questionObj);
      }

      setPreviousValueCampaign({
        'date': appointmentDetail?.AppointmentDate,
        'status': appointmentDetail?.LeadStatus,
        'note': appointmentDetail?.Notes,
        'contactAnswers': contactAnswers,
        'numberOfGuest': {
          value: appointmentDetail?.NumberOfGuests,
          isRequired: appointmentDetail?.IsBookExperience
        },
        'timeSlotId': {
          value: appointmentDetail?.TimeSlotId.toLowerCase(),
          isRequired: appointmentDetail?.IsBookExperience
        },
        'productId': {
          value: appointmentDetail?.ProductId.toLowerCase(),
          isRequired: appointmentDetail?.IsBookExperience
        },
        'teaId': {
          value: appointmentDetail?.TeaId.toLowerCase(),
          isRequired: appointmentDetail?.IsBookExperience
        },
        'scentId': {
          value: appointmentDetail?.ScentId.toLowerCase(),
          isRequired: appointmentDetail?.IsBookExperience
        },
      });
    }
  }, [appointmentDetail]);

  return (
    <EditAppointmentFormContext.Provider value={{ formik }}>
      <form onSubmit={formik.handleSubmit} className='appointment-detail__wrap edit-appointment-form'>
        <div className='appointments-infor'>
          <AppointmentsInfor
            isEdit={true}
            formik={formik}
            statusCode={statusCode}
            setStatusCode={setStatusCode}
            pastAppointmentEdit={pastAppointmentEdit}
          />
          <div className='appointments-infor__group'>
            <div className='row'>
              <div className='col-12 col-md-6 appointments-infor__group__item'>
                <SelectStatus formik={formik} layoutData={layoutData} />
                <SelectProductCP layoutData={layoutData} pastAppointmentEdit={pastAppointmentEdit} />
              </div>
              <div className='col-12 col-md-6 appointments-infor__group__item'>
                <SelectScentCP layoutData={layoutData} pastAppointmentEdit={pastAppointmentEdit} />
                <SelectTeaCP layoutData={layoutData} pastAppointmentEdit={pastAppointmentEdit} />
              </div>
              {appointmentDetail?.['Questions']?.map((question, idx) => (
                <div key={idx} className='col-12 col-md-6 appointments-infor__group__item'>
                  <QuestionComponent question={question} index={idx} pastAppointmentEdit={pastAppointmentEdit} />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className='edit-appointment-form__button-group'>
          <button className='btn btn-primary' type='submit'>
            {layoutData?.['Save Label']?.value}
          </button>
          <button className='btn btn-link' type='button' onClick={onCloseHandler}>
            {layoutData?.['Cancel Label']?.value}
          </button>
        </div>
      </form>
      {isLoading && <LoadingMask />}
    </EditAppointmentFormContext.Provider>
  );
};

EditAppointmentForm.propTypes = {
  onCloseHandler: func,
};

export default EditAppointmentForm;
