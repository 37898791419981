import produce from 'immer';

import * as cPStoreSelectorTypes from '@redux/actions/cPStoreSelector/cPStoreSelectorTypes';

const initState = {
  isLoading: false,
  isSuccess: false,
  errors: []
};

const cpStoreSelectorReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cPStoreSelectorTypes.CP_STORE_SELECTOR:
      draft.isLoading = true;

      draft.isSuccess = false;
      break;

    case cPStoreSelectorTypes.CP_STORE_SELECTOR_SUCCESS:
      draft.isLoading = false;

      draft.isSuccess = action.payload.Success;
      break;
  
    case cPStoreSelectorTypes.CP_STORE_SELECTOR_FAILED:
      draft.isLoading = false;
        
      draft.errors = action.error;
      break;
    }

    return draft;
  });

export default cpStoreSelectorReducer;
