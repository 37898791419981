import React, { useMemo } from 'react';

import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import ProductSelectSwiper from '../ProductSelectSwiper';

const SelectScent = () => {
  const { layoutData, setScheduleData } = useScheduleAppointmentContext();

  const scentInfoData = useMemo(() => {
    return layoutData?.Scents?.length
      ? layoutData.Scents.map((tea) => ({
        productName: tea?.fields?.Name,
        description: tea?.fields?.Description,
        productId: tea?.id,
        image: tea?.fields?.Image
      }))
      : [];
  }, [layoutData]);

  const initialScent = useMemo(() => {
    if (layoutData?.appointment) {
      const scentData = layoutData?.Scents;
      const scentSelectedId = layoutData?.appointment?.Scent?.Id;
      const scentExistInScentData = scentData?.find((scent) => scent.id === scentSelectedId);

      return scentData.indexOf(scentExistInScentData);
    }
  }, [layoutData?.Scents, layoutData?.appointment]);

  const handleSelectScent = (scentId) => {
    setScheduleData((prev) => ({
      ...prev,
      personalizeExperience: {
        ...prev.personalizeExperience,
        scentId: scentId
      }
    }));
  };

  return layoutData?.Scents?.length ? (
    <div className='personalize-experience__body-item'>
      <ProductSelectSwiper
        label={layoutData['Select Scent Label']}
        productInfoData={scentInfoData}
        initialSlide={initialScent}
        onSelectProduct={handleSelectScent}
      />
    </div>
  ) : (
    <></>
  );
};

export default SelectScent;
