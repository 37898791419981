import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CartCouponCodeModel {
  constructor() {
    this['Apply Button Text'] = new SitecoreTextModel();

    this['Apply Coupons Button Text'] = new SitecoreTextModel();

    this['Coupon Code Label'] = new SitecoreTextModel();

    this['Coupon Code Placeholder'] = new SitecoreTextModel();

    this['Coupon Detail Title'] = new SitecoreTextModel();

    this['Coupon List Title'] = new SitecoreTextModel();

    this['Select coupon Label'] = new SitecoreTextModel();

    this['To Label'] = new SitecoreTextModel();

    this['Valid Until Label'] = new SitecoreTextModel();

    this['Validity Label'] = new SitecoreTextModel();

    this['View More Coupon Label'] = new SitecoreTextModel();

    this['Hint Text'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View More Coupon Label')) {
      this['View More Coupon Label'].getData(dataSource['View More Coupon Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Apply Button Text')) {
      this['Apply Button Text'].getData(dataSource['Apply Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Apply Coupons Button Text')) {
      this['Apply Coupons Button Text'].getData(dataSource['Apply Coupons Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon Code Label')) {
      this['Coupon Code Label'].getData(dataSource['Coupon Code Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon Code Placeholder')) {
      this['Coupon Code Placeholder'].getData(dataSource['Coupon Code Placeholder']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon Detail Title')) {
      this['Coupon Detail Title'].getData(dataSource['Coupon Detail Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon List Title')) {
      this['Coupon List Title'].getData(dataSource['Coupon List Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Select coupon Label')) {
      this['Select coupon Label'].getData(dataSource['Select coupon Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'To Label')) {
      this['To Label'].getData(dataSource['To Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Valid Until Label')) {
      this['Valid Until Label'].getData(dataSource['Valid Until Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Validity Label')) {
      this['Validity Label'].getData(dataSource['Validity Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Hint Text')) {
      this['Hint Text'].getData(dataSource['Hint Text']);
    }

    return this;
  }
}
