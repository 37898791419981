import AddressPopupModel from '@components/SlicingPage/MyAccount/AddressesWidget/models/AddressPopupModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import AddressSettingModel from './AddressSettingModel';

export default class MyAddressModel {
  constructor() {
    this['Add New Address Button Text'] = new SitecoreTextModel();

    this['Address Book Edit Billing Text'] = new SitecoreTextModel();

    this['Billing Address Edit Billing Text'] = new SitecoreTextModel();

    this['Edit Address Text'] = new SitecoreTextModel();

    this['Edit Billing Text'] = new SitecoreTextModel();

    this['Edit Shipping Text'] = new SitecoreTextModel();

    this['Remove Address Text'] = new SitecoreTextModel();

    this['PopUp Select Default Billing Address Label'] = new SitecoreTextModel();

    this['Remove Confirmation Message'] = new SitecoreTextModel();

    this['PopUp Select Default Shipping Address Label'] = new SitecoreTextModel();

    this['PopUp Set As Default Button Text'] = new SitecoreTextModel();

    this['PopUp Update Address Button Text'] = new SitecoreTextModel();

    this['PopUp Add New Address Label'] = new SitecoreTextModel();

    this['DataSoures Popup'] = new AddressPopupModel();

    this['Shipping Address Title'] = new SitecoreTextModel();

    this['Address Book Title'] = new SitecoreTextModel();

    this['Billing Address Title'] = new SitecoreTextModel();

    this['Address Settings'] = new AddressSettingModel();

    this['PopUp Edit Address Label'] = new SitecoreTextModel();

    this['Phone Number Label'] = new SitecoreTextModel();

    this['PopUp Default Label'] = new SitecoreTextModel();

    this['PopUp Limit Character Address Message'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    this['DataSoures Popup'].getData(dataSource || {});
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Default Label')) {
      this['PopUp Default Label'].getData(dataSource['PopUp Default Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Settings')) {
      this['Address Settings'].getData(dataSource['Address Settings']?.fields || {}, true);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Phone Number Label')) {
      this['Phone Number Label'].getData(dataSource['Phone Number Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Edit Address Label')) {
      this['PopUp Edit Address Label'].getData(dataSource['PopUp Edit Address Label']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Shipping Address Title')) {
      this['Shipping Address Title'].getData(dataSource['Shipping Address Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Shipping Address Title')) {
      this['Shipping Address Title'].getData(dataSource['Shipping Address Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Book Title')) {
      this['Address Book Title'].getData(dataSource['Address Book Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Billing Address Title')) {
      this['Billing Address Title'].getData(dataSource['Billing Address Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add New Address Button Text')) {
      this['Add New Address Button Text'].getData(dataSource['Add New Address Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Book Edit Billing Text')) {
      this['Address Book Edit Billing Text'].getData(dataSource['Address Book Edit Billing Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Billing Address Edit Billing Text')) {
      this['Billing Address Edit Billing Text'].getData(dataSource['Billing Address Edit Billing Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Address Text')) {
      this['Edit Address Text'].getData(dataSource['Edit Address Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Billing Text')) {
      this['Edit Billing Text'].getData(dataSource['Edit Billing Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Shipping Text')) {
      this['Edit Shipping Text'].getData(dataSource['Edit Shipping Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Remove Address Text')) {
      this['Remove Address Text'].getData(dataSource['Remove Address Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Remove Confirmation Message')) {
      this['Remove Confirmation Message'].getData(dataSource['Remove Confirmation Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Select Default Billing Address Label')) {
      this['PopUp Select Default Billing Address Label'].getData(dataSource['PopUp Select Default Billing Address Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Select Default Shipping Address Label')) {
      this['PopUp Select Default Shipping Address Label'].getData(dataSource['PopUp Select Default Shipping Address Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Set As Default Button Text')) {
      this['PopUp Set As Default Button Text'].getData(dataSource['PopUp Set As Default Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Update Address Button Text')) {
      this['PopUp Update Address Button Text'].getData(dataSource['PopUp Update Address Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Add New Address Label')) {
      this['PopUp Add New Address Label'].getData(dataSource['PopUp Add New Address Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Limit Character Address Message')) {
      this['PopUp Limit Character Address Message'].getData(dataSource['PopUp Limit Character Address Message']);
    }

    return this;
  }
}
