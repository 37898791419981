import Proptypes from 'prop-types';
import React, { Fragment, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Message from '@components/Share/Message';
import { addChosenBOWarranty } from '@redux/actions/buyingOptions';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

const BOWarrantyList = ({ contentData, activeItemsId, isSelected, onToogleFnc }) => {
  const dispatch = useDispatch();
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  useEffect(() => {
    if (contentData.length === 1) {
      onClickBuyingItemHandler(contentData[0].ProductId);
    }
  }, []);

  const onClickBuyingItemHandler = (itemID) => {
    dispatch(addChosenBOWarranty(itemID));

    onToogleFnc();
  };

  const isActiveItems = (listId, itemId) => {
    return listId.includes(itemId);
  };

  return (
    contentData && (
      <div className={`${isClientTelingUser ? 'warranty-buying-list-cp' : 'warranty-buying-list'}`}>
        <div className='row'>
          {contentData.map((item, itemIndex) => {
            return (
              <div className={`${isClientTelingUser ? 'col-6' : 'col-lg-6'} warranty-buying-list__item-wrap`} key={itemIndex}>
                <div
                  className={'warranty-buying-list__item' + `${!isSelected && isActiveItems(activeItemsId, item.ProductId) ? ' active' : ''}`}
                  onClick={() => onClickBuyingItemHandler(item.ProductId)}
                  key={itemIndex}
                >
                  <span className='custom-radio-select warranty-buying-list__item__name'>{item.DisplayName}</span>
                  <span className='addition-price warranty-buying-list__item__price'>
                    {item.Price.ListPrice === 0 ? (
                      <Fragment>
                        <Message messageCode='LBL-WarrantyFee' />
                      </Fragment>
                    ) : (
                      <Fragment>{item.Price.ListPriceWithCurrency}</Fragment>
                    )}
                  </span>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
};

BOWarrantyList.propTypes = {
  contentData: Proptypes.any,
  activeItemsId: Proptypes.any,
  onToogleFnc: Proptypes.any,
  isSelected: Proptypes.any
};

export default BOWarrantyList;
