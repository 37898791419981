import produce from 'immer';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import FooterNavigationTextModel from '../../model/FooterNavigationTextModel';

const FooterNavigationTitle = (props) => {
  const [inputData, setInputData] = useState(null);
  const [list, setList] = useState(null);

  useEffect(() => {
    const title = new FooterNavigationTextModel();

    setInputData(title.getData(deepCopy(props.data.fields)));
  }, []);

  useEffect(() => {
    let tempField = deepCopy(props);

    tempField.data.isOpen = false;

    setList(tempField);
  }, []);

  const onCollapseHandler = () => {
    let nextState = produce(list, (draft) => {
      draft.data.isOpen = !list.data.isOpen;
    });

    setList(nextState);

    props.isCollapsed(nextState);
  };

  return (
    inputData && (
      <div className={'footer-navigation__title ' + (list.data.isOpen ? 'collapse-active' : '')} onClick={() => onCollapseHandler()}>
        <Text className='footer-navigation__title__text' field={inputData.Title} tag='h3' />
        <i className='footer-navigation__title__icon collapse__icon--default icon-add'></i>
        <i className='footer-navigation__title__icon collapse__icon--active icon-remove'></i>
      </div>
    )
  );
};

FooterNavigationTitle.propTypes = {
  data: PropTypes.object,
  isCollapsed: PropTypes.any
};

export default FooterNavigationTitle;
