import './LeadSearch.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image } from '@sitecore-jss/sitecore-jss-react';

const LeadSearch = ({
  leadManagementModelFields, 
  onSearchLeadManagement, 
  isResetSearch, 
  onChangeSearch
}) => {
  const [searchResult, setSearchResult] = useState('');

  useEffect(() => {
    if (isResetSearch) {
      setSearchResult('');
    } 
  }, [isResetSearch]);

  const onEnterHandler = (event) => {
    if (event.key == 'Enter') {
      onSearchLeadManagement(searchResult);
    }
  };

  return leadManagementModelFields && (
    <div className='lead-search'>
      <div className='container tablet-container'>
        <div className='lead-search__box'>
          <input 
            type='text'
            value={searchResult}
            className='lead-search__box__input'
            placeholder={leadManagementModelFields['Search Placeholder Text'].value}
            onChange={(e) => {
              setSearchResult(e.target.value);

              onChangeSearch(e.target.value);
            }}
            onKeyDown={(event) => onEnterHandler(event)}
          />
          <span className='lead-search__box__icon cp-icon' onClick={() => onSearchLeadManagement(searchResult)}>
            <Image field={leadManagementModelFields['Search Icon']} />
          </span>
        </div>
      </div>
    </div>
  );
};

LeadSearch.propTypes = {
  leadManagementModelFields: PropTypes.any,
  onSearchLeadManagement: PropTypes.any,
  isResetSearch: PropTypes.bool,
  onChangeSearch: PropTypes.any
};

export default LeadSearch;
