import ShippingOptionType from './ShippingOptionType';

export default class DeliveryOptionsModel {
  constructor() {
    this.ShopName = '';

    this.ShippingOptionType = new ShippingOptionType();

    this.Name = '';

    this.Description = '';

    this.ExternalId = '';

    this.IsDelivery = true;

    this.AddressRootCode = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ShopName')) {
      this.ShopName = dataSource.ShopName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExternalId')) {
      this.ExternalId = dataSource.ExternalId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ShippingOptionType')) {
      this.ShippingOptionType.getData(dataSource.ShippingOptionType || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.AddressRootCode = dataSource.AddressRootCode ?? this.AddressRootCode;
    }

    return this;
  }
}
