import './logo.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import LogoItem from './LogoItem/LogoItem';

const Logo = (props) => {
  const [data] = useState(props.fields || null);

  return data ? (
    <LogoItem contentData={data} />
  ) : '';
};

Logo.propTypes = {
  fields: PropTypes.any
};

export default Logo;
