import './SelectProduct.scss';

import { func, object, string } from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import Swiper from 'react-id-swiper';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useQuery } from '@utils/customsHook/useQuery';

import {
  SCHEDULE_APPOINTMENT_PARAM_NAME,
  SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE
} from '../../ScheduleAppointmentComponent/FormikStep/configs';

export const BE_PRODUCT_ID_QUERY_STRING_NAME = 'productId';

const SelectProduct = ({ layoutData, productId, setProductId }) => {
  const imageSwiperRef = useRef(null);
  const textSwiperRef = useRef(null);
  const url = window.location.href;
  const idUrl = url.substring(url.lastIndexOf('?id=') + 4);
  const [idxProductSelected, setIdxProductSelected] = useState(0);
  const query = useQuery();

  const handleSetProductId = () => {
    const imageSwiper = imageSwiperRef.current?.swiper;
    if (imageSwiper) {
      setProductId(layoutData?.['Products'][imageSwiper.realIndex]?.Id);
    }
  };

  useEffect(() => {
    return layoutData?.['Products']?.find((item, idx) => {
      if (item.Id === idUrl) {
        setIdxProductSelected(idx);
      }
    });
  }, [layoutData?.['Products']]);

  useEffect(() => {
    const imageSwiper = imageSwiperRef.current?.swiper;

    imageSwiper.slideToLoop(idxProductSelected);

    setProductId(idUrl);
  }, [idxProductSelected]);

  const paramSwiperImage = useMemo(() => {
    const productSelectedId = layoutData?.appointment?.Product?.Id;
    const productsData = layoutData?.Products;
    const productSelected = productsData?.find((product) => product?.Id?.toLowerCase() === productSelectedId?.toLowerCase());

    return {
      initialSlide: productSelected ? productsData.indexOf(productSelected) : 0,
      pagination: false,
      slidesPerView: layoutData?.['Products']?.length < 2 ? 1.25 : 2.25,
      centeredSlides: true,
      loop: true,
      breakpoints: {
        992: {
          slidesPerView: layoutData?.['Products']?.length < 3 ? layoutData?.['Products']?.length : 3,
          spaceBetween: 76
        }
      }
    };
  }, [layoutData?.appointment]);

  const paramSwiperText = useMemo(() => {
    const productSelectedId = layoutData?.appointment?.Product?.Id;
    const productsData = layoutData?.Products;
    const productSelected = productsData?.find((product) => product?.Id?.toLowerCase() === productSelectedId?.toLowerCase());

    return {
      initialSlide: productSelected ? productsData.indexOf(productSelected) : 0,
      pagination: false,
      slidesPerView: layoutData?.['Products']?.length < 3 ? layoutData?.['Products']?.length : 3,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      renderPrevButton: () => <button onClick={handleSetProductId} className='swiper-button-prev'></button>,
      renderNextButton: () => <button onClick={handleSetProductId} className='swiper-button-next'></button>,
      centeredSlides: true,
      loop: true,
      slideToClickedSlide: true,
      breakpoints: {
        992: {
          slidesPerView: layoutData?.['Products']?.length < 3 ? layoutData?.['Products']?.length : 3,
          spaceBetween: 76
        }
      }
    };
  }, [layoutData?.appointment]);

  useEffect(() => {
    const imageSwiper = imageSwiperRef.current?.swiper;
    const textSwiper = textSwiperRef.current?.swiper;

    if (imageSwiper?.controller && textSwiper?.controller) {
      const handleSlideChange = () => {
        setProductId(layoutData?.['Products'][imageSwiper.realIndex]?.Id);
      };

      imageSwiper.on('slideChange', handleSlideChange);

      imageSwiper.controller.control = textSwiper;

      textSwiper.controller.control = imageSwiper;

      return () => {
        imageSwiper.off('slideChange', handleSlideChange);
      };
    }
  }, []);

  useEffect(() => {
    const imageSwiper = imageSwiperRef.current?.swiper;
    if (imageSwiper) {
      setProductId(layoutData?.['Products'][imageSwiper.realIndex]?.Id);
    }
  }, [imageSwiperRef]);

  const linkBookExp = useMemo(() => {
    const appointmentId = query.get('appointment_id');
    const storeId = query.get('storeId');
    let link = `${layoutData?.['Button Book Now Link']?.value?.href}?${SCHEDULE_APPOINTMENT_PARAM_NAME}=${SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE}&${BE_PRODUCT_ID_QUERY_STRING_NAME}=${productId}`;
    if (appointmentId) link += `&appointment_id=${appointmentId}`;
    if (storeId) link += `&storeId=${storeId}`;

    return link;
  }, [productId, query]);

  return (
    <div id='select-product' className='select-product container'>
      <Text field={layoutData?.['Select Product Experience Text']} className='select-product__title' tag='h2' />
      <div className='select-product__swiper'>
        <Swiper {...paramSwiperImage} ref={imageSwiperRef}>
          {layoutData?.['Products']?.map((item) => (
            <div key={item?.Id} className='select-product__swiper__item'>
              <div className='select-product__swiper__item__image'>
                <Image field={{ src: item?.Image }} />
              </div>
            </div>
          ))}
        </Swiper>
        <Text field={layoutData?.['You Are Selecting Text']} className='select-product__swiper__item__content__subtitle' tag='p' />
        <Swiper {...paramSwiperText} ref={textSwiperRef}>
          {layoutData?.['Products']?.map((item) => (
            <div key={item?.Id} className='select-product__swiper__item'>
              <div className='select-product__swiper__item__content'>
                <Text field={{ value: item.Name }} className='select-product__swiper__item__content__title' tag='h5' />
                <Text field={{ value: item.Description }} className='select-product__swiper__item__content__desc' tag='p' />
              </div>
            </div>
          ))}
        </Swiper>
      </div>
      <Link field={{ href: linkBookExp }} className='btn btn-primary'>
        <Text field={layoutData?.['Button Book Now Text']} />
      </Link>
    </div>
  );
};

SelectProduct.propTypes = {
  layoutData: object,
  productId: string,
  setProductId: func
};

export default SelectProduct;
