import { object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import Global from '@utils/global';

const CountdownTimerCampaign = (props) => {
  const { endDate, 
    endText, 
    remainingDate, 
    startDate, 
    startText, 
    validText, 
    wasEndText, 
    countdownFormatSetting, 
    componentType, 
    alternativeStartText 
  } = props;
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [label, setLabel] = useState('');
  const [time, setTime] = useState('');
  const start = Date.parse(new Date(startDate));
  const remain = Date.parse(new Date(remainingDate));
  const end = Date.parse(new Date(endDate));

  // 0001-01-01T00:00:00Z
  // 2021-08-01T15:22:00Z
  // let start = Date.parse(new Date('2021-08-13T02:00:00Z'));
  // let remain = Date.parse(new Date('2021-08-13T03:00:00Z'));
  // let end = Date.parse(new Date('2021-08-13T03:00:00Z'));

  useEffect(() => {
    const onSetLabel = setInterval(() => {
      let now = Date.parse(new Date(Date.now()));
      let distance;
      if (now < start) {
        setLabel(isClientTelingUser? alternativeStartText : startText);

        distance = start - now;

        let days = Math.floor(distance / 86400000);
        let hours = Math.floor((distance - days * 86400000) / 3600000);
        let minutes = Math.floor((distance - days * 86400000 - hours * 3600000) / 60000);
        let seconds = Math.floor(distance - days * 86400000 - hours * 3600000 - minutes * 60000) / 1000;

        return setTime(
          `${countdownFormatSetting?.CountdownFormat.replace('{day}', days)
            .replace('{hour}', hours)
            .replace('{minute}', minutes)
            .replace('{second}', seconds)}`
        );
      }
      if (end < now) {
        if (endDate === '0001-01-01T00:00:00Z' || endDate === '0001-01-01T00:00:00.0000000Z') {
          componentType === 'CampaignBanner' && setLabel('');

          componentType === 'PromotionHeader' && setLabel(validText);

          componentType === 'PromotionBanner' &&
            setLabel(Global.onFormatPromotionValidMessageHandler(validText, startDate, endDate, countdownFormatSetting?.DateFormat));

          return setTime('');
        } else {
          let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

          setLabel(wasEndText);

          setTime(`${new Date(end).getDate()} ${monthArr[new Date(end).getMonth()]} ${new Date(end).getFullYear()}`);

          clearInterval();

          return;
        }
      }
      if (start < now) {
        if (now < remain) {
          componentType === 'CampaignBanner' && setLabel('');

          componentType === 'PromotionHeader' && setLabel(validText);

          componentType === 'PromotionBanner' &&
            setLabel(Global.onFormatPromotionValidMessageHandler(validText, startDate, endDate, countdownFormatSetting?.DateFormat));

          return setTime('');
        }
        if (remain < now) {
          componentType === 'CampaignBanner' && setLabel(endText);

          componentType === 'PromotionHeader' && setLabel(validText);

          componentType === 'PromotionBanner' && setLabel(endText);

          distance = end - now;

          let days = Math.floor(distance / 86400000);
          let hours = Math.floor((distance - days * 86400000) / 3600000);
          let minutes = Math.floor((distance - days * 86400000 - hours * 3600000) / 60000);
          let seconds = Math.floor(distance - days * 86400000 - hours * 3600000 - minutes * 60000) / 1000;

          return setTime(
            componentType === 'PromotionHeader'
              ? ''
              : `${countdownFormatSetting?.CountdownFormat.replace('{day}', days)
                .replace('{hour}', hours)
                .replace('{minute}', minutes)
                .replace('{second}', seconds)}`
          );
        }
      }
    }, 1000);

    return () => {
      clearInterval(onSetLabel);
    };
  }, [validText]);

  return (
    <Fragment>
      <span
        className={`${componentType === 'CampaignBanner' ? 'countdown__label' : ''} ${
          componentType === 'PromotionHeader' ? 'promotion-detail-bundle__header__text__name' : ''
        } ${componentType === 'PromotionBanner' ? 'promotion-detail-bundle__header__text__name' : ''}`}
      >
        {label}{' '}
        <span
          className={`${componentType === 'CampaignBanner' ? 'countdown__time' : ''} ${
            componentType === 'PromotionHeader' ? 'countdowntimer--number' : ''
          } ${componentType === 'PromotionBanner' ? 'countdowntimer--number' : ''}`}
        >
          {time}
        </span>
      </span>
    </Fragment>
  );
};

CountdownTimerCampaign.propTypes = {
  endDate: string,
  startDate: string,
  remainingDate: string,
  wasEndText: string,
  startText: string,
  validText: string,
  countdownFormatSetting: object,
  endText: string,
  componentType: string,
  alternativeStartText: string,
};

export default CountdownTimerCampaign;
