// GET COUPONS LIST
export const GET_BROWSE_COUPON_LIST = 'GET_BROWSE_COUPON_LIST';

export const GET_BROWSE_COUPON_LIST_SUCCESS = 'GET_BROWSE_COUPON_LIST_SUCCESS';

export const GET_BROWSE_COUPON_LIST_FAILED = 'GET_BROWSE_COUPON_LIST_FAILED';

// GET COUPON DETAIL
export const GET_COUPON_DETAIL = 'GET_COUPON_DETAIL';

export const GET_COUPON_DETAIL_SUCCESS = 'GET_COUPON_DETAIL_SUCCESS';

export const GET_COUPON_DETAIL_FAILED = 'GET_COUPON_DETAIL_FAILED';

export const REMOVE_COUPON_DETAIL = 'REMOVE_COUPON_DETAIL';

// APPLY COUPON CODE
export const APPLY_COUPON_CODE = 'APPLY_COUPON_CODE';

export const APPLY_COUPON_CODE_SUCCESS = 'APPLY_COUPON_CODE_SUCCESS';

export const APPLY_COUPON_CODE_FAILED = 'APPLY_COUPON_CODE_FAILED';

export const APPLY_SO_COUPON_CODE = 'APPLY_SO_COUPON_CODE';

export const APPLY_SO_COUPON_CODE_SUCCESS = 'APPLY_SO_COUPON_CODE_SUCCESS';

export const APPLY_SO_COUPON_CODE_FAILED = 'APPLY_SO_COUPON_CODE_FAILED';

// REMOVE COUPON CODE
export const REMOVE_COUPON_CODE = 'REMOVE_COUPON_CODE';

export const REMOVE_COUPON_CODE_SUCCESS = 'REMOVE_COUPON_CODE_SUCCESS';

export const REMOVE_COUPON_CODE_FAILED = 'REMOVE_COUPON_CODE_FAILED';

// GET APPLIED COUPON LIST
export const GET_APPLIED_COUPON_LIST = 'GET_APPLIED_COUPON_LIST';

export const GET_APPLIED_COUPON_LIST_SUCCESS = 'GET_APPLIED_COUPON_LIST_SUCCESS';

export const GET_APPLIED_COUPON_LIST_FAILED = 'GET_APPLIED_COUPON_LIST_FAILED';

export const GET_ERROR_MSG = 'GET_ERROR_MSG';

// REMOVE SO COUPON
export const REMOVE_SO_COUPON_CODE_START = 'REMOVE_SO_COUPON_CODE_START';

export const REMOVE_SO_COUPON_CODE_SUCCESS = 'REMOVE_SO_COUPON_CODE_SUCCESS';

export const REMOVE_SO_COUPON_CODE_FAILED = 'REMOVE_SO_COUPON_CODE_FAILED';
