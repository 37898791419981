import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import HeaderScriptModel from './model/HeaderScriptModel';

const HeaderScript = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [scriptEl, setScriptEl] = useState([]);
  const isTWSite = SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OTW';

  useEffect(() => {
    const model = new HeaderScriptModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  useDidUpdateEffect(() => {
    const dom = new window.DOMParser().parseFromString(layoutData?.Script?.value, 'text/html');
    const el = dom.documentElement.querySelectorAll(':not(html):not(head):not(body)');
    let temp = [];

    Array.from(el).forEach((item, index) => {
      temp.push(htmlStrToReactComponent(item, index));
    });

    setScriptEl(temp);
  }, [layoutData]);
  
  const htmlStrToReactComponent = (el, index) => {
    const TextContent = el?.textContent;
    const NodeName = el?.nodeName?.toLowerCase();
    const attributes = Object.fromEntries([...el.attributes].map(({ name, value }) => [name, value])) || '';

    return <NodeName {...attributes} key={index}>{TextContent}</NodeName>;
  };

  return (
    layoutData &&
    scriptEl && (
      <>
        <Helmet>
          {scriptEl.map((item) => {
            return item;
          })}
        </Helmet>
        {/* {isTWSite && <Helmet><script async src='https://www.googletagmanager.com/gtag/js?id=AW-796718007'></script></Helmet>} */}
        {/* {isTWSite && <Helmet><script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'AW-796718007');
          `}</script></Helmet>} */}
        {/* {isTWSite && <Helmet><script async src='https://www.googletagmanager.com/gtag/js?id=UA-12599373-5'></script></Helmet>} */}
        {/* {isTWSite && <Helmet><script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-12599373-5');
          `}</script></Helmet>} */}
      </>
    )
  );
};

HeaderScript.propTypes = {
  fields: object
};

export default HeaderScript;
