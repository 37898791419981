import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class MyOrdersWidgetModel {
  constructor() {
    this['View Details Link'] = new SitecoreLinkModel();

    this['View All Link'] = new SitecoreLinkModel();

    this['Title'] = new SitecoreTextModel();

    this['Product Summary Label'] = new SitecoreTextModel();

    this['Product Summary Label With Other Product'] = new SitecoreTextModel();

    this['View All Label'] = new SitecoreTextModel();

    this['Order Placed Label'] = new SitecoreTextModel();

    this['Order Total Label'] = new SitecoreTextModel();

    this['View Details Text'] = new SitecoreTextModel();

    this['Order Placed Label'] = new SitecoreTextModel();

    this['Order Label'] = new SitecoreTextModel();

    this['Explore Osim Products Link'] = new SitecoreLinkModel();

    this['Explore Osim Products Text'] = new SitecoreTextModel();

    this['You Have No New Orders Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'You Have No New Orders Label')) {
      this['You Have No New Orders Label'].getData(dataSource['You Have No New Orders Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Explore Osim Products Link')) {
      this['Explore Osim Products Link'].getData(dataSource['Explore Osim Products Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Explore Osim Products Text')) {
      this['Explore Osim Products Text'].getData(dataSource['Explore Osim Products Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View Details Link')) {
      this['View Details Link'].getData(dataSource['View Details Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View All Link')) {
      this['View All Link'].getData(dataSource['View All Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Summary Label')) {
      this['Product Summary Label'].getData(dataSource['Product Summary Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Summary Label With Other Product')) {
      this['Product Summary Label With Other Product'].getData(dataSource['Product Summary Label With Other Product']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View All Label')) {
      this['View All Label'].getData(dataSource['View All Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Order Placed Label')) {
      this['Order Placed Label'].getData(dataSource['Order Placed Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Order Total Label')) {
      this['Order Total Label'].getData(dataSource['Order Total Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Order Label')) {
      this['Order Label'].getData(dataSource['Order Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View Details Text')) {
      this['View Details Text'].getData(dataSource['View Details Text']);
    }

    return this;
  }
}
