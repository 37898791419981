import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const WightModel = ({ dataSources,isViewTitle }) => {
  return !isViewTitle ? (
    <div className='product-specification__collapse__content'>
      <div className='product-specification__collapse__content__list'>
        <div className='row'>
          {dataSources['Weight']['Gross Weight Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Weight']['Gross Weight Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Weight']['Gross Weight Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Weight']['Net Weight Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Weight']['Net Weight Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Weight']['Net Weight Value']} />
            </dd>
          </dl> : ''}
        </div>
      </div>
    </div>
  ) : <Text field={dataSources['Weight']['Weight Title Text']}/>;
};

WightModel.propTypes = {
  dataSources: PropTypes.object,
  isViewTitle: PropTypes.bool,
};

export default WightModel;
