import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { get } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as config from './config';

const productAlsoBoughtEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_CROSS_SALE_ITEM),
    switchMap(() => {
      const params = {
        rt: config.RELATION_SHIP_ID,
        cci: SitecoreContextFactoryService.getValueContextItem('itemId')
      };

      return from(get(config.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getCrossSaleSuccess(res);
          } else {
            return actions.getCrossSaleFailed('Failed');
          }
        })
      );
    })
  );

export default productAlsoBoughtEpic;
