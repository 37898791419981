import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CPAccountQuickAccessModels {
  constructor() {
    this['Change Password Label'] = new SitecoreTextModel();

    this['Log Out Label'] = new SitecoreTextModel();

    this['Select Store Label'] = new SitecoreTextModel();

    this['Welcome Message'] = new SitecoreTextModel();

    this['Change Password Link'] = new SitecoreLinkModel();

    this['Login Link'] = new SitecoreLinkModel();

    this['My Account Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Password Label')) {
      this['Change Password Label'].getData(dataSource['Change Password Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Log Out Label')) {
      this['Log Out Label'].getData(dataSource['Log Out Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Select Store Label')) {
      this['Select Store Label'].getData(dataSource['Select Store Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Welcome Message')) {
      this['Welcome Message'].getData(dataSource['Welcome Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Password Link')) {
      this['Change Password Link'].getData(dataSource['Change Password Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Link')) {
      this['Login Link'].getData(dataSource['Login Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'My Account Link')) {
      this['My Account Link'].getData(dataSource['My Account Link']);
    }

    return this;
  }
}
