import { from, of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { postAsObservable } from './genericService';

const ACTION_REVIEW_APPOINTMENT = '/booking-portal/Appointment/ReviewExperienceAppointment';

const reviewAppointmentService = (params) => {
  return from(postAsObservable(ACTION_REVIEW_APPOINTMENT, params)).pipe(
    map((res) => {
      if (res.status === 200) {
        return of(res.data);
      } else {
        return of(res.data);
      }
    }),
    concatMap((response) => response),
    catchError((error) => of(error.message || 'Failed'))
  );
};

export default reviewAppointmentService;