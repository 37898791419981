import { useCallback } from 'react';
import { catchError, map } from 'rxjs/operators';

import { postAsObservable } from '@services/genericService';

const SUBMIT_EMAIL_ENDPOINT = '/Coupon/SubmitEmail';

export const useSubmitEmail = () => {
  return useCallback((email) => {
    return new Promise((resolve, reject) => {
      return postAsObservable(SUBMIT_EMAIL_ENDPOINT, {
        email: email
      })
        .pipe(
          map((res) => resolve(res)),
          catchError((error) => reject(error))
        )
        .subscribe();
    });
  }, []);
};
