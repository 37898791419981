import { object } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';
import { deepCopy } from '@utils/utility';

import BoxData from '../BoxData';
import CustomerLastVisitShimmer from './CustomerLastVisitShimmer';
import CustomerLastVisitModel from './models/CustomerLastVisitModel';

const CustomerLastVisit = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const formatDateUtil = useFormatDate();

  useEffect(() => {
    const model = new CustomerLastVisitModel();

    setLayoutData(model.getData(deepCopy(fields), {}));
  }, []);

  return layoutData ? (
    <div className='col-md-6 profiles__col'>
      {clientTellingPortalCustomer.isLoading ? (
        <CustomerLastVisitShimmer layoutData={layoutData} />
      ) : (
        <BoxData component='last-visit' layoutData={layoutData} data={clientTellingPortalCustomer?.contacts}>
          <div className='box-data__row'>
            <div className='box-data__col-2'>
              <Text tag='div' className='box-data__title --sub' field={layoutData['Last Visit Label']} />
            </div>
            <div className='box-data__col-1'>
              <p className='box-data__notice'>{formatDateUtil(clientTellingPortalCustomer?.currentCustomerDetail?.LastVisit?.LastVisitDate)}</p>
            </div>
          </div>
          <div className='box-data__row'>
            <div className='box-data__col-2'>
              <Text tag='div' className='box-data__title --sub' field={layoutData['Product Interest Label']} />
            </div>
            <div className='box-data__col-1'>
              {clientTellingPortalCustomer?.currentCustomerDetail?.LastVisit?.ProductInterest?.map((item, index) => {
                return <Fragment key={index}>{index < 4 ? <p className='box-data__notice'>{item}</p> : <></>}</Fragment>;
              })}
            </div>
          </div>
          <div className='box-data__row'>
            <div className='box-data__col-2'>
              <Text tag='div' className='box-data__title --sub' field={layoutData['Total Spent Label']} />
            </div>
            <div className='box-data__col-1'>
              <p className='box-data__notice'>{clientTellingPortalCustomer?.currentCustomerDetail?.LastVisit?.TotalSpent ?? 'N/A'}</p>
            </div>
          </div>
        </BoxData>
      )}
    </div>
  ) : (
    <></>
  );
};

CustomerLastVisit.propTypes = {
  fields: object
};

export default CustomerLastVisit;
