import { func, object, string } from 'prop-types';
import React, { useState } from 'react';

import Loading from '@components/Share/Loading';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const CouponWalletListPopup = ({ layoutData, couponWalletData, getCouponDetailHandler, applySelectedCoupon, selectedCoupon }) => {
  const [selectedCouponItem, setSelectedCouponItem] = useState(selectedCoupon);
  const [errMsg, setErrMsg] = useState(false);

  return (
    <>
      {couponWalletData.isLoading ? (
        <Loading />
      ) : (
        <div className='offer-widget-modal'>
          <div className='offer-widget-modal__content'>
            {couponWalletData.couponWalletList.map((coupon) => {
              return (
                <div className='offer-widget__content__item coupon-popup__item' key={coupon.Id}>
                  <div
                    className={`cart-promotion-code-item ${selectedCouponItem == coupon.Code ? 'selected' : ''}`}
                    onClick={() => {
                      if (selectedCouponItem == coupon.Code) {
                        setSelectedCouponItem(selectedCoupon);
                      } else setSelectedCouponItem(coupon.Code);

                      setErrMsg(false);
                    }}
                  >
                    <div
                      className='cart-promotion-code-item__infor-icon info-icon info-icon--small'
                      onClick={(e) => {
                        e.stopPropagation();

                        getCouponDetailHandler(coupon, true);
                      }}
                    ></div>
                    <div className='cart-promotion-code-item__main-info'>
                      <div className='cart-promotion-code-item__group'>
                        <h4 className='cart-promotion-code-item__name'>{coupon.Title}</h4>
                        <Text tag='span' className='cart-promotion-code-item__hint-text' field={layoutData['Hint Text']} />
                        <span className='cart-promotion-code-item__code'>{coupon.Code}</span>
                      </div>
                      <p className='cart-promotion-code-item__description' dangerouslySetInnerHTML={{__html: coupon.Description}}></p>
                    </div>
                    <div className='cart-promotion-code-item__footer justify-space-between'>
                      <div className='cart-promotion-code-item__footer__date'>
                        <Text tag='span'field={layoutData['Vaild Until Label']} />
                        <span>{` ${Global.convertEpochTime(coupon.ValidityTo)}`}</span>
                      </div>
                    </div>
                    <div className='left-light'></div>
                  </div>
                </div>
              );
            })}
          </div>
          {errMsg ? <Text field={layoutData['Required Coupon Error Message']} tag='p' className='error-validate' /> : <></>}
          <div className='offer-widget-modal__btn'>
            <button
              className='btn btn-outline-CTA1'
              onClick={() => {
                if (selectedCouponItem) {
                  applySelectedCoupon(selectedCouponItem);
                } else {
                  setErrMsg(true);
                }
              }}
            >
              <Text field={layoutData['Apply Offer Text']} />
            </button>
          </div>
        </div>
      )}
    </>
  );
};

CouponWalletListPopup.propTypes = {
  layoutData: object,
  couponWalletData: object,
  getCouponDetailHandler: func,
  applySelectedCoupon: func,
  selectedCoupon: string
};

export default CouponWalletListPopup;
