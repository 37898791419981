import { bool, string } from 'prop-types';
import React, { Fragment } from 'react';

import { useGetMemberUsualText } from '@utils/customsHook/useGetMemberUsualText';

import { QuestionTooltipIcon } from '../../ProductCardPrice/QuestionTooltipIcon';

export const BOPrice = ({
  totalListPriceText,
  totalSellingPriceText,
  isDeposit,
  priceExtraInformation
}) => {
  const { memberText, usualText } = useGetMemberUsualText();

  return (
    totalSellingPriceText === totalListPriceText || isDeposit ? (
      <span className='bo-payment-info__price__current'>
        {totalSellingPriceText} {priceExtraInformation}
      </span>
    ) : (
      <>
        <span className={['bo-payment-info__price__current', memberText ? 'with-tooltip' : ''].join(' ')}>
          {memberText}{totalSellingPriceText} {priceExtraInformation}
          {memberText ? <QuestionTooltipIcon /> : <></>}
        </span>
        {usualText ? (
          <span className='bo-payment-info__price__old'>{usualText}{totalListPriceText}</span>
        ) : (
          <del className='bo-payment-info__price__old'>{totalListPriceText}</del>
        )}
      </>
    )
  );
};

BOPrice.propTypes = {
  totalSellingPriceText: string,
  totalListPriceText: string,
  priceExtraInformation: string,
  isDeposit: bool
};