import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import AddNewAddress from '../AddNewAddress';
import InfoAddress from '../InfoAddress';
import ChangeBillingAddress from './ChangeBillingAddress';

const BillingAddress = (props) => {
  const { dataSources, userAddressList } = props;
  const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
  const [billingAddressDefault, setBillingAddress] = useState(userAddressList.find((address) => address.IsBilling));
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  useDidUpdateEffect(() => {
    setBillingAddress(userAddressList.find((address) => address.IsBilling));

    setShowAddAddressPopup(false);
  }, [userAddressList]);

  return userAddressList?.length ? (
    <div className='my-addresses__section'>
      <div className='my-addresses__section__heading my-account-widget__heading my-account-section__group'>
        <h4 className='my-addresses__section__heading__title'>
          <Text field={dataSources['Billing Address Title']} />
        </h4>
        <span className='my-account-section__btn-link' onClick={() => setShowAddAddressPopup(true)}>
          <Text field={dataSources['Edit Billing Text']} />
        </span>
      </div>
      {billingAddressDefault ? (
        <div className='my-addresses__section__content'>
          <h5 className='my-addresses__section__content__address-name'>{billingAddressDefault.AddressName}</h5>
          <p className='my-addresses__full-name addresses-info__line'>
            {`${settingGlobal?.FullNameFormat.replace('{LastName}', billingAddressDefault.LastName).replace('{FirstName}', billingAddressDefault.FirstName)}`}
          </p>
          <InfoAddress dataAddresses={billingAddressDefault} dataSources={dataSources} />
        </div>
      ) : (
        ''
      )}
      <div className='address-component'>
        <ModalComponent
          isShow={showAddAddressPopup}
          titlePopup={dataSources ? <Text field={dataSources['PopUp Select Default Billing Address Label']} /> : ''}
          onCloseHandler={() => setShowAddAddressPopup(false)}
        >
          <ChangeBillingAddress dataSources={dataSources} userAddressList={userAddressList} />
        </ModalComponent>
      </div>
    </div>
  ) : (
    <div className='my-addresses__section my-address_section--empty'>
      <div className='my-addresses__section__heading my-account-widget__heading my-account-section__group'>
        <h4 className='my-addresses__section__heading__title'>
          <Text field={dataSources['Billing Address Title']} />
        </h4>
        <span className='my-account-section__btn-link disable'>
          <Text field={dataSources['Edit Billing Text']} />
        </span>
      </div>
      <AddNewAddress dataSources={dataSources} />
    </div>
  );
};

BillingAddress.propTypes = {
  dataSources: PropTypes.object,
  userAddressList: PropTypes.array
};

export default BillingAddress;
