import { any } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import Select from 'react-select';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

const DatePicker = ({ label, setFieldValue }) => {
  const [dayOptions, setDayOptions] = useState(null);
  const [monthOptions, setMonthOptions] = useState(null);
  const [yearOptions, setYearOptions] = useState(null);
  const [fullDoB, setFullDoB] = useState(false);
  const yearRef = useRef(null);
  const monthRef = useRef(null);
  const dayRef = useRef(null);
  let dayDefault = 1;
  let monthDefault = 1;
  let yearDefault = 1900;
  const MAXIMUM_DAY_IN_NORMAL_MONTH = 31;
  const MINIMUM_DAY_IN_NORMAL_MONTH = 30;
  const MONTH_IN_YEAR = 12;
  const DAY_OF_FEB_IN_LEAP_YEAR = 29;
  const DAY_OF_FEB_IN_NORMAL_YEAR = 28;

  const [selectedDay, setSelectedDay] = useState(null);

  const [selectedMonth, setSelectedMonth] = useState(null);

  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    let optionDay = [];
    let optionMonth = [];
    let optionYear = [];
    let date = new Date();
    for (dayDefault; dayDefault <= MAXIMUM_DAY_IN_NORMAL_MONTH; dayDefault++) {
      let obj = {};

      obj.label = dayDefault;

      obj.value = dayDefault;

      optionDay.push(obj);
    }
    for (monthDefault; monthDefault <= MONTH_IN_YEAR; monthDefault++) {
      let obj = {};

      obj.label = monthDefault;

      obj.value = monthDefault;

      optionMonth.push(obj);
    }
    for (yearDefault; yearDefault <= date.getFullYear(); yearDefault++) {
      let obj = {};

      obj.label = yearDefault;

      obj.value = yearDefault;

      optionYear.push(obj);
    }

    setDayOptions(optionDay);

    setMonthOptions(optionMonth);

    setYearOptions(optionYear.reverse());
  }, []);

  useEffect(() => {
    selectedDay && selectedMonth && selectedYear ? setFullDoB(true) : setFullDoB(false);
  }, [selectedDay, selectedMonth, selectedYear]);

  useDidUpdateEffect(() => {
    if (selectedDay && selectedMonth && selectedYear) {
      fullDoB ? setFieldValue(Global.formatToISOStringHour(selectedDay, selectedMonth, selectedYear)) : setFieldValue('');
    } else setFieldValue('');
  }, [fullDoB, selectedDay, selectedMonth, selectedYear]);

  const onCountLeapYearHandler = (year) => {
    year = parseInt(year);
    if (year % 400 == 0) return true;
    if (year % 4 == 0 && year % 100 != 0) return true;

    return false;
  };

  const onChangeYear = (year) => {
    let optionDay = [];
    if (onCountLeapYearHandler(year.value)) {
      if (monthRef.current.state.value?.value === 2) {
        for (dayDefault; dayDefault <= DAY_OF_FEB_IN_LEAP_YEAR; dayDefault++) {
          let obj = {};

          obj.label = dayDefault;

          obj.value = dayDefault;

          optionDay.push(obj);
        }

        setDayOptions(optionDay);
      }
    } else {
      if (monthRef.current.state.value?.value === 2) {
        if (dayRef.current.state.value?.value === DAY_OF_FEB_IN_LEAP_YEAR) {
          setSelectedDay(null);
        }
        for (dayDefault; dayDefault <= DAY_OF_FEB_IN_NORMAL_YEAR; dayDefault++) {
          let obj = {};

          obj.label = dayDefault;

          obj.value = dayDefault;

          optionDay.push(obj);
        }

        setDayOptions(optionDay);
      }
    }

    setSelectedYear(year);
  };

  const onChangeMonth = (month) => {
    let optionDay = [];
    if (month.value === 2) {
      if (JSON.stringify(yearRef.current.state.value?.value)?.length) {
        if (onCountLeapYearHandler(yearRef.current.state.value?.value)) {
          selectedDay?.value > DAY_OF_FEB_IN_LEAP_YEAR && setSelectedDay(null);
          for (dayDefault; dayDefault <= DAY_OF_FEB_IN_LEAP_YEAR; dayDefault++) {
            let obj = {};

            obj.label = dayDefault;

            obj.value = dayDefault;

            optionDay.push(obj);
          }
        } else {
          selectedDay?.value > DAY_OF_FEB_IN_NORMAL_YEAR && setSelectedDay(null);
          for (dayDefault; dayDefault <= DAY_OF_FEB_IN_NORMAL_YEAR; dayDefault++) {
            let obj = {};

            obj.label = dayDefault;

            obj.value = dayDefault;

            optionDay.push(obj);
          }
        }
      } else {
        selectedDay?.value > DAY_OF_FEB_IN_LEAP_YEAR && setSelectedDay(null);
        for (dayDefault; dayDefault <= DAY_OF_FEB_IN_LEAP_YEAR; dayDefault++) {
          let obj = {};

          obj.label = dayDefault;

          obj.value = dayDefault;

          optionDay.push(obj);
        }
      }
    } else if (month.value === 4 || month.value === 6 || month.value === 9 || month.value === 11) {
      selectedDay?.value > MINIMUM_DAY_IN_NORMAL_MONTH && setSelectedDay(null);
      for (dayDefault; dayDefault <= MINIMUM_DAY_IN_NORMAL_MONTH; dayDefault++) {
        let obj = {};

        obj.label = dayDefault;

        obj.value = dayDefault;

        optionDay.push(obj);
      }
    } else {
      for (dayDefault; dayDefault <= MAXIMUM_DAY_IN_NORMAL_MONTH; dayDefault++) {
        let obj = {};

        obj.label = dayDefault;

        obj.value = dayDefault;

        optionDay.push(obj);
      }
    }

    setDayOptions(optionDay);

    setSelectedMonth(month);
  };

  const onChangeDay = (day) => {
    setSelectedDay(day);
  };

  return (
    <div className='form-group__date__picker'>
      <Text field={label} tag='label' />
      <div className='form-group__date__picker-container'>
        <Select
          id='day'
          name='datePicker'
          onChange={(day) => onChangeDay(day)}
          ref={dayRef}
          value={selectedDay}
          options={dayOptions}
          className='customization-dropdown'
          classNamePrefix='customization-dropdown'
          placeholder={'Day'}
          isSearchable={true}
        />
        <Select
          id='month'
          name='datePicker'
          onChange={(month) => onChangeMonth(month)}
          ref={monthRef}
          value={selectedMonth}
          options={monthOptions}
          className='customization-dropdown'
          classNamePrefix='customization-dropdown'
          placeholder={'Month'}
          isSearchable={true}
        />
        <Select
          id='year'
          name='datePicker'
          onChange={(option) => onChangeYear(option)}
          ref={yearRef}
          value={selectedYear}
          options={yearOptions}
          className='customization-dropdown'
          classNamePrefix='customization-dropdown'
          placeholder={'Year'}
          isSearchable={true}
        />
      </div>
    </div>
  );
};

DatePicker.propTypes = {
  label: any,
  setFieldValue: any
};

export default DatePicker;