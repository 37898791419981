import './Voucher.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const Voucher = ({voucher, handleClickVoucher, isMultiplePopup=false, layoutData}) => {
  return voucher && (
    <div className='voucher'>
      <div className='voucher__body'>
        <h3 className='voucher__name'>{voucher?.Title || ''}</h3>
        <Text tag='p' className='voucher__hint-text' field={layoutData['Hint Text']}/>
        <p className='voucher__code'>{voucher?.Code || ''}</p>
        <div className='voucher__info' onClick={() => handleClickVoucher(voucher, isMultiplePopup)}>
          <span className='info-icon info-icon--black'></span>
        </div>
        <p className='voucher__description'>{voucher.Description || ''}</p>
      </div>
      <div className='voucher__footer'>
        <Text tag='span' field={layoutData['Vaild Until Label']}/>
        <span>{` ${Global.convertEpochTime(voucher?.ValidityTo)}`}</span>
      </div>
    </div>
  );
};

Voucher.propTypes = {
  voucher: PropTypes.any,
  handleClickVoucher: PropTypes.any,
  isMultiplePopup: PropTypes.bool,
  layoutData: PropTypes.any
};

export default Voucher;
