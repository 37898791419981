import './Title.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import TitleModel from './model/TitleModel';

const Title = (props) => {
  const [titleData, setTitleData] = useState(null);
  const textSize = (props.params && props.params['Text Size']) || '';

  const TitleText = {
    value: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Title']?.value 
    || SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Page Title']?.value 
    || ''
  };

  useEffect(() => {
    setTitleData(new TitleModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields') || {}));
  }, []);

  return titleData ? (
    TitleText?.value ? (
      <div className='container my-account-header__heading animated-slow fadeIn delay-100'>
        <h2 className={`${textSize == 'Large' ? 'experience-title' : 'my-account-header__heading__title'}`}>
          <Text field={TitleText} />
        </h2>
      </div>
    ) : <></>
  ) : (
    <div className='container my-account-header__heading skeleton__item'>
      <h2 className={`transparent-wrap ${textSize == 'Large' ? 'experience-title' : 'my-account-header__heading__title'}`}>
        {textSize == 'Large' 
          ?  <span className='text-line-heading-center w-40'></span>
          : <span className='text-line-small-center w-40'></span>}
      </h2>
    </div>
  );
};

Title.propTypes = {
  params: PropTypes.any,
};

export default Title;
