import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const IMAGE_ARR = [
  'Household Size Icon',
  'Owned Product Icon',
  'Show Info Icon',
  'Message Button Icon',
  'Phone Icon',
  'Tier Icon',
  'Mail Icon',
  'Job Nature Icon',
  'Health Issues Icon',
  'Gender Icon',
  'Dial Button Icon',
  'Marital Status Icon',
  'Birthday Icon',
  'Age Icon',
  'SMS Icon'
];
const TEXT_ARR = ['Is Communication Method Hidden'];

export default class CPContactInfoModel {
  constructor() {
    IMAGE_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
