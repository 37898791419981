import './CartSeller.scss';

import { useFormik } from 'formik';
import Proptypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import Message from '@components/Share/Message';
import { addStaff, updateCartSeller } from '@redux/actions/cartDetail';
import {removeCurlyBrackets} from '@redux/epic/bookingExperience/utils';
import {
  getClientSOCartSelector
} from '@redux/reducers/personalizeOffer/getClientSOCartReducer/getClientSOCartSelector';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import CartSellerModel from './model/CartSellerModel';
import { handleTrackingStaffSelectionEvent } from './utils';

const CartSeller = ({ fields }) => {
  const dispatch = useDispatch();
  const [isEdit, setIsEdit] = useState(false);
  const [showError, setShowError] = useState(false);
  const [layoutData, setLayoutData] = useState(null);
  const staffData = useSelector((state) => state.productReducer.cartDetailReducer.currentCart?.Staff);
  const isCart = useSelector((state)=> state?.productReducer?.cartDetailReducer?.currentCart?.Lines?.length);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const { data: clientSOCartData } = useSelector(getClientSOCartSelector);
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore');
  const user = SitecoreContextFactoryService.getValueContextItem('user');
  const { isEditingSOCart } = useSelector((state) => state.cpCartDetailsReducer);

  const isHasSOCart = useMemo(() => {
    return Object.keys(clientSOCartData).every((key)=> clientSOCartData[key]);
  }, [clientSOCartData]);

  useEffect(() => {
    const model = new CartSellerModel();

    setLayoutData(model.getData(fields || {}));
  }, []);

  useDidUpdateEffect(() => {
    if (isClientTelingUser && currentStore && user) {
      // formik.setFieldValue('staffId', `${user?.domain}\\${user?.name}`);
      formik.setFieldValue('staffId', user?.name);

      setTimeout(() => formik.setFieldTouched('staffId', true));

      formik.setFieldValue('storeId', removeCurlyBrackets(currentStore?.outletCode));

      setTimeout(() => formik.setFieldTouched('storeId', true));

      setIsEdit(true);
    } else if (staffData?.StaffId && staffData?.OutletCode) {
      formik.setFieldValue('staffId', staffData.StaffId);

      setTimeout(() => formik.setFieldTouched('staffId', true));

      formik.setFieldValue('storeId', removeCurlyBrackets(staffData.OutletCode));

      setTimeout(() => formik.setFieldTouched('storeId', true));

      setIsEdit(true);
    }
  }, [staffData, isClientTelingUser]);

  const initialValues = useMemo(() => {
    const soCartData= clientSOCartData?.SOCart?.Cart;
    const osimCartStaffData = clientSOCartData?.OsimCart?.Staff;
    let startId = removeCurlyBrackets(staffData?.StaffId) || '';

    if(isHasSOCart && soCartData) {
      startId = removeCurlyBrackets(soCartData?.StaffId);
    }

    if(isClientTelingUser && user) {
      // startId = `${user?.domain}\\${user?.name}`;
      startId = user?.name;
    }

    return {
      staffId: startId,
      storeId:
        removeCurlyBrackets(soCartData?.StoreId) ||
        removeCurlyBrackets(staffData?.StoreId) ||
        removeCurlyBrackets(currentStore?.outletCode) ||
        osimCartStaffData?.StoreId ||
        ''
    };
  }, [clientSOCartData, staffData,currentStore, isHasSOCart]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape(
      {
        storeId: Yup.string()
          .ensure()
          .when('staffId', (staff) => {
            if (staff && staff !== '') return Yup.string().required(<Message messageCode='Msg_002.6' />);
          }),
        staffId: Yup.string()
          .ensure()
          .when('storeId', (store) => {
            if (store && store !== '') return Yup.string().required(<Message messageCode='Msg_002.7' />);
          })
      },
      [['storeId', 'staffId']]
    ),
    onSubmit: (values) => {
      if (!isEdit) {
        if ((values.staffId.length > 0 && values.staffId.length > 0) || (!values.staffId.length === 0 && !values.staffId.length === 0)) {
          dispatch(addStaff(values));

          //INFO: tracking staff_selection GA4 event
          handleTrackingStaffSelectionEvent(values.storeId, values.staffId);

          setIsEdit(!isEdit);
        } else {
          setShowError(true);

          setTimeout(() => {
            setShowError(false);
          }, 3000);
        }
      } else {
        setIsEdit(!isEdit);
      }
    },
    validateOnMount: true
  });

  const clearInput = (type) => {
    setIsEdit(false);

    if (type === 'store') {
      dispatch(updateCartSeller({...formik.values, storeId: ''}));

      if (formik.values.staffId === '') {
        dispatch(addStaff({...formik.values, storeId: ''}));
      }

      formik.setFieldValue('storeId', '');
    } else {
      dispatch(updateCartSeller({...formik.values, staffId: ''}));

      if (formik.values.storeId === '') {
        dispatch(addStaff({...formik.values, staffId: ''}));
      }

      formik.setFieldValue('staffId', '');
    }
  };

  return (
    layoutData && (
      <>
        {(!isCart && isClientTelingUser) || (isClientTelingUser && !isEditingSOCart) ? (
          <></>
        ) : (
          <form onSubmit={formik.handleSubmit} className='cart-seller__form large-text cart-seller__form-custom'>
            <div className='container' id='cart-seller'>
              <div className='row'>
                <div className='col-md-6 col-12'>
                  <div className='cart-seller__form__group cart-promotion-codes__form__group'>
                    <label>{layoutData['Store ID Label'].value}</label>
                    <div className='form-input cart-promotion-codes__form__input cart-promotion-codes__form__input--full-width'>
                      <input
                        type='text'
                        className={
                          formik.values.storeId
                            ? 'form-control form-control-lg checkout-input__input input-valid'
                            : 'form-control form-control-lg checkout-input__input'
                        }
                        placeholder={layoutData['Store ID Placeholder'].value}
                        onChange={(event) => {
                          dispatch(updateCartSeller({ ...formik.values, storeId: event.target.value }));

                          formik.setFieldValue('storeId', event.target.value);
                        }}
                        value={formik.values.storeId}
                        id='storeId'
                        name='storeId'
                        readOnly={isEdit || isHasSOCart}
                      />
                      {!isEdit && formik.values.storeId !== '' && !isHasSOCart ? (
                        <i className='x-icon-clear icon-close' onClick={() => clearInput('store')}></i>
                      ) : (
                        ''
                      )}
                    </div>
                    {formik.errors.storeId ? (
                      <span className='error-validate'>{formik.errors.storeId}</span>
                    ) : showError ? (
                      <span className='error-validate'>
                        <Message messageCode='Msg_002.6' />
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='col-md-6 col-12'>
                  <div className='cart-seller__form__group cart-promotion-codes__form__group'>
                    <label>{layoutData['Staff ID Label'].value}</label>
                    <div className='form-input cart-promotion-codes__form__input'>
                      <input
                        type='text'
                        className={
                          formik.values.staffId
                            ? 'form-control form-control-lg checkout-input__input input-valid'
                            : 'form-control form-control-lg checkout-input__input'
                        }
                        placeholder={layoutData['Staff ID Placeholder'].value}
                        onChange={(event) => {
                          dispatch(updateCartSeller({ ...formik.values, staffId: event.target.value }));

                          formik.setFieldValue('staffId', event.target.value);
                        }}
                        value={formik.values.staffId}
                        id='staffId'
                        name='staffId'
                        readOnly={isEdit || isHasSOCart}
                      />
                      {!isEdit && formik.values.staffId !== '' && !isHasSOCart ? (
                        <i className='x-icon-clear icon-close' onClick={() => clearInput('staff')}></i>
                      ) : (
                        ''
                      )}
                      {!isHasSOCart ? (
                        !isEdit ? (
                          <button type='submit' className='btn btn-outline-CTA2'>
                            <Text field={layoutData['Apply Button Text']}></Text>
                          </button>
                        ) : (
                          <button type='submit' className='btn btn-outline-CTA2'>
                            <Text field={layoutData['Edit Button Text']}></Text>
                          </button>
                        )
                      ) : (
                        <></>
                      )}
                    </div>
                    {formik.errors.staffId ? (
                      <span className='error-validate'>{formik.errors.staffId}</span>
                    ) : showError ? (
                      <span className='error-validate'>
                        <Message messageCode='Msg_002.7' />
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </>
    )
  );
};

CartSeller.propTypes = {
  fields: Proptypes.any,
  cartData: Proptypes.any
};

export default CartSeller;
