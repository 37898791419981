import PropTypes from 'prop-types';
import React, {useEffect, useMemo, useState} from 'react';
import {connect, useSelector} from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import ImagePreviews from '../../../BuyingOptions/BOTradeIn/BOTradeInList/TradeInUploadImage/ImagesPreview';

const BuyingFirstTypeHeader = (props) => {
  const [activeChangeButton, setActiveChangeButton] = useState(false);
  const [isShowModal, setShowModal] = useState(null);
  const BOTradeIn = useSelector((state) => state.buyingOptionReducer.BOTradeInReducer);

  const {
    isOpen,
    labelText,
    changeText,
    firstTypeItemsName,
    onToogleFnc,
    errorMsg,
    isShowError,
    contentData,
    additionalOptionsRequired,
    isAdditionalOption,
    // TradeInOptions
    secondTypeItemsName,
    defaultTradeInTitle,
    isTradeInOptions,
    onShowTradeInOptionsModal,
    dataFields,
    tradeInActive
  } = props;
  let params = {
    centered: true
  };

  const tradeInImages = useMemo(() => tradeInActive?.values?.imageTradeInProduct || [], [tradeInActive]);

  useEffect(() => {
    if (contentData.length === 1) {
      if (isAdditionalOption && !additionalOptionsRequired) {
        setActiveChangeButton(true);
      } else setActiveChangeButton(false);
    } else setActiveChangeButton(true);
  }, []);

  return (
    <div className='accordion-buying-option__header'>
      <div>
        {isTradeInOptions ? (
          <h3 className='bo-title-section'>
            {defaultTradeInTitle ? (
              labelText
            ) : (
              <>
                <Text field={dataFields['Trade In Buying Option Post Label']} /> {firstTypeItemsName}
              </>
            )}
          </h3>
        ) : (
          <h3 className='bo-title-section'>{isOpen ? labelText : firstTypeItemsName}</h3>
        )}
        {secondTypeItemsName?.isSelectedTradeInOption && BOTradeIn?.uploadedFile ? (
          <span className='label-info-modal' onClick={() => setShowModal(true)}>
            {secondTypeItemsName?.values?.brand} - {secondTypeItemsName?.values?.productName} - {secondTypeItemsName?.values?.model}
          </span>
        ) : (
          <></>
        )}
      </div>
      {activeChangeButton ? (
        isTradeInOptions ? (
          <span className={'btn-change' + `${defaultTradeInTitle ? '' : ' active'}`} onClick={() => onShowTradeInOptionsModal({ status: 'change' })}>
            {changeText}
          </span>
        ) : (
          <span className={'btn-change' + `${isOpen ? '' : defaultTradeInTitle ? '' : ' active'}`} onClick={onToogleFnc}>
            {changeText}
          </span>
        )
      ) : isTradeInOptions ? (
        <span className={'btn-change' + `${defaultTradeInTitle ? '' : ' active'}`} onClick={() => onShowTradeInOptionsModal({ status: 'change' })}>
          {changeText}
        </span>
      ) : (
        <></>
      )}
      <span className={'error-msg' + `${isShowError ? ' active' : ''}`}>{errorMsg}</span>
      <ModalComponent isShow={isShowModal} cssClass={'tradein-popup'} onCloseHandler={() => setShowModal(false)} param={params}>
        <div className='tradein-content-modal'>
          <div className='tradein-content-modal__image'>
            {tradeInImages.length ? (
              tradeInImages.map((image, index) => (
                <div className='image-uploaded' key={index}>
                  <div className='image-uploaded__content'>
                    <img alt={image?.name || 'trade in upload image'} src={image.url} />
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <h3 className='tradein-content-modal__text'>
            {secondTypeItemsName?.values?.brand} - {secondTypeItemsName?.values?.productName} - {secondTypeItemsName?.values?.model}
          </h3>
        </div>
      </ModalComponent>
    </div>
  );
};

BuyingFirstTypeHeader.propTypes = {
  isOpen: PropTypes.any,
  labelText: PropTypes.any,
  changeText: PropTypes.any,
  firstTypeItemsName: PropTypes.any,
  onToogleFnc: PropTypes.any,
  errorMsg: PropTypes.any,
  isShowError: PropTypes.any,
  contentData: PropTypes.array,
  additionalOptionsRequired: PropTypes.bool,
  isAdditionalOption: PropTypes.bool,
  // TradeInOptions
  secondTypeItemsName: PropTypes.any,
  defaultTradeInTitle: PropTypes.bool,
  isTradeInOptions: PropTypes.bool,
  onShowTradeInOptionsModal: PropTypes.func,
  dataFields: PropTypes.object,
  tradeInActive: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    tradeInActive: state.buyingOptionReducer.BOTradeInReducer.tradeInActive || null
  };
};

export default connect(mapStateToProps)(BuyingFirstTypeHeader);
