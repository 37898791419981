import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import MyOrdersLastestApi from '@components/SlicingPage/MyAccount/MyOrders/model/MyOrdersLastestApi';
import * as actions from '@redux/actions/myOrders';
import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';
import { getAsObservable } from '@services/genericService';

import * as config from './config';

export const getLastestOrdersdEpic = (action$) =>
  action$.pipe(
    ofType(myOrderTypes.GET_LASTEST_ORDERS),
    switchMap(() => {
      return getAsObservable(config.ACTION_GET_LASTEST_ORDERS).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            const response = new MyOrdersLastestApi().getData(res.data?.Data || {});
            
            return actions.getLastestOrdersSuccess(response);
          } else {
            return actions.getLastestOrdersFailed('Failed');
          }
        })
      );
    })
  );