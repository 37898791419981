import PropTypes from 'prop-types';
import React, { useState } from 'react';

import FormFieldError from '../../FormFieldError';

const CustomRadioBoxList = (props) => {
  const { field, value, onChange, errors, tracker } = props;
  const otherFieldPrefix = '#Other#';
  const [showOtherTextBox, setShowOtherTextBox] = useState(false);

  const hasOtherOptions = field.model.otherOptionTitle != '' && field.model.otherOptionTitle != undefined;

  if (hasOtherOptions && !field.model.items.find(f => f.value && f.value.startsWith(otherFieldPrefix)))
    field.model.items.push({ itemId: otherFieldPrefix, value: otherFieldPrefix, text: field.model.otherOptionTitle });


  const onOtherInputChange = (field, item, textValue, callback) => {
    let errorMessages = [];
    if (!textValue && field.model.required) {
      errorMessages.push(`${field.model.title} is required`);
    }

    item.value = `${otherFieldPrefix}|${textValue}`;

    callback(field.valueField.name, [item.value], true, errorMessages);
  };

  const handleOnChange = (field, originalFieldValue, changedElement, checked, callback) => {
    // we can have multiple selected values. So we need to push ALL the selected
    // values back up to the root form. This is done using an array, which the form
    // serializer knows how to expand into multiple values on post

    let value = originalFieldValue;
    let errorMessages = [];

    if (checked) {
      value = [];

      value.push(changedElement);
      if (value.length) {
        errorMessages = [];
      }
    } else {
      value = value.filter((v) => v !== changedElement);

      if (!value.length) {
        if (field.model.required) {
          errorMessages.push(`${field.model.title} is required`);
        }
      }
    }

    setShowOtherTextBox(value && value[0].startsWith(otherFieldPrefix));

    callback(field.valueField.name, value, true, errorMessages);
  };

  return (
    <>
      {field.model.items.map((item, index) => (
        <div key={item.itemId} className={'osim-radiobox ' + (item.itemId == otherFieldPrefix ? 'osim-radiobox-other' : '')}  >
          <input
            type='radio'
            className='osim-radiobox-input'
            id={field.valueField.id + index}
            name={field.valueField.name}
            value={item.value}
            checked={value.some((v) => v === item.value || (v && v.startsWith(otherFieldPrefix) && item.itemId == otherFieldPrefix))}
            onChange={(e) => handleOnChange(field, value, e.target.value, e.target.checked, onChange)}
            onFocus={() => tracker.onFocusField(field, value)}
            onBlur={() => tracker.onBlurField(field, value, errors)}
          />
          <label htmlFor={field.valueField.id + index} className='osim-radiobox-label'>
            {item.text}
          </label>
          {item.itemId == otherFieldPrefix && showOtherTextBox ? <input type='text' onChange={(e) => onOtherInputChange(field, item, e.target.value, onChange)} /> : ''}
        </div>
      ))}
      <FormFieldError {...props} />
    </>
  );
};

CustomRadioBoxList.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tracker: PropTypes.any,
  errors: PropTypes.any,
  children: PropTypes.any
};

export default CustomRadioBoxList;
