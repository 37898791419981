import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Description',
  'Extend Warranty Button Label',
  'Title',
  'Pending Message',
  'Reject Message',
  'Approved Message',
  'Expired Message',
  'Register Warranty Button Label',
  'Contact Button Label',
  'Not Register Message',
  'Not Carry Warranty Message',
  'Extend Button Warranty Label'
];

const LINK_ARR = ['Extend Warranty Button Link', 'Register Warranty Button Link', 'Contact Button Link', 'Extend Button Warranty Link'];

export default class WarrantyDetailModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}
