import AdditionalOptionsSettingModel from '@components/BuyingOptions/model/AdditionalOptionsSettingModel';
import AddressSettingModel from '@components/SlicingPage/MyAccount/MyAddress/models/AddressSettingModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const textLabel = [
  'Billing Address Label',
  'Billing Delivery Fee Label',
  'Billing Discount Label',
  'Billing Edit Label',
  'Billing Remove Label',
  'Billing Sale Tax Label',
  'Billing Subtotal Label',
  'Billing Title',
  'Billing Total Label',
  'Billing Total Saving Label',
  'Coupon Code Label',
  'Delivery Add Label',
  'Delivery Address Label',
  'Delivery Edit Label',
  'Delivery Free Gift Label',
  'Delivery Free Label',
  'Delivery Fulfilment Option Label',
  'Delivery Gifting Option Label',
  'Delivery Package Title',
  'Delivery Product List Label',
  'Delivery Quantity Label',
  'Delivery Remove Label',
  'Delivery Subtotal Label',
  'Delivery You Saved Label',
  'Description Shipping',
  'Fulfilment Option Delivery Label',
  'Fulfilment Option Pick Up From Store Label',
  'Pick Up Fee Label',
  'SF Pickup Address Label',
  'SF Pickup Free Gift Label',
  'SF Pickup Free Label',
  'SF Pickup Gifting Option Label',
  'SF Pickup Package Title',
  'SF Pickup Product List Label',
  'SF Pickup Quantity Label',
  'SF Pickup Subtotal Label',
  'SF Pickup You Saved Label',
  'Special Deal Purchase with Purchase Label',
  'Staff ID Label',
  'Store ID Label',
  'Phone Number',
  'Please Select Address Message',
  'PopUp Accept Label',
  'PopUp Confirm Description',
  'PopUp Confirm Select Address Label',
  'PopUp Cancel Label',
  'No Address Message',
  'Pick Up Fee Label',
  'Select Area And District Label',
  'Offers Applied Label',
  'Applied Offers Title',
  'Free Gift Hint',
  'Gifting Option Label',
  'Gift Card Label',
  'Gift Wrap Label',
  'Delivery SF Express Package Label',
  'Free Text',
  'Sub Total Label',
  'Edit Label',
  'Free Gift Out Of Stock Message',
  'Add On Deal Label',
  'Deposit Payment Label',
  'Deposit Paid Label',
  'Extended Warranty Label',
  'Deposit Free Gift Label',
  'Delivery Free Label',
  'Total Deposit Label',
  'Balance Label',
  'Balance Payment Label',
  'Discount Label',
  'Deposit Free Gift Label',
  'Balance Free Gift Note Message',
  'Total Balance Payment Label',
  'From To Date Label',
  'Deposit Tag Label',
  'Pre-order Offer Tag Label',
  'Deposit Free Gift Note Messages',
  'Balance Free Gift Label'
];

const linkLable = ['Back To Your Detail Button Link', 'Continue Payment Button Link', 'Back To Cart Link'];

export default class CheckoutReviewModel {
  constructor() {
    textLabel.forEach((itemText) => {
      this[itemText] = new SitecoreTextModel();
    });

    linkLable.forEach((itemText) => {
      this[itemText] = new SitecoreLinkModel();
    });

    this['Area Shipping Fee Setting'] = new AddressSettingModel();

    this['Show Sales Tax'] = false;

    this['Learn More Label'] = new SitecoreTextModel();

    this['Valid Until Label'] = new SitecoreTextModel();

    this['Coupon Detail Title'] = new SitecoreTextModel();

    this['To Label'] = new SitecoreTextModel();

    this['Applied Offers Description'] = new SitecoreTextModel();

    this['Additional Options Setting'] = new AdditionalOptionsSettingModel();
  }
  getData(dataSource) {
    textLabel.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    linkLable.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Area Shipping Fee Setting')) {
      this['Area Shipping Fee Setting'].getData(dataSource['Area Shipping Fee Setting']?.fields || {});
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cart Configuration') && dataSource['Cart Configuration']?.fields) {
      this['Show Sales Tax'] = dataSource['Cart Configuration'].fields['Show Sales Tax']?.value;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Label')) {
      this['Learn More Label'].getData(dataSource['Learn More Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Valid Until Label')) {
      this['Valid Until Label'].getData(dataSource['Valid Until Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon Code Label')) {
      this['Coupon Code Label'].getData(dataSource['Coupon Code Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon Detail Title')) {
      this['Coupon Detail Title'].getData(dataSource['Coupon Detail Title'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'To Label')) {
      this['To Label'].getData(dataSource['To Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Applied Offers Description')) {
      this['Applied Offers Description'].getData(dataSource['Applied Offers Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Options Setting')) {
      this['Additional Options Setting'].getData(dataSource['Additional Options Setting']);
    }

    return this;
  }
}
