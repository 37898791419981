import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { subscribeMailFailed } from '@redux/actions/subscribeNewsletter';
import { SUBSCRIBE_MAIL_START } from '@redux/actions/subscribeNewsletter/subscribeNewsletterTypes';
import { post } from '@services/genericService';

import { subscribeMailSuccess } from '../../actions/subscribeNewsletter';
import { SUBSCRIBE_MAIL_ENDPOINT } from './config';

export const subscribeNewsletterEpic = (action$) =>
  action$.pipe(
    ofType(SUBSCRIBE_MAIL_START),
    switchMap((action) => {
      return from(post(SUBSCRIBE_MAIL_ENDPOINT, action.payload)).pipe(
        map((res) => {
          if (res.data.Success) {
            return subscribeMailSuccess(res.data);
          } else {
            return subscribeMailFailed(res.data);
          }
        }),
        catchError((error) => of(subscribeMailFailed(error)))
      );
    })
  );
