import { object } from 'prop-types';
import React from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

const PartnerSpecialsDetailLogo = ({ layoutData }) => {
  return (
    <>
      <Text tag='h2' field={layoutData?.['Logo Section Title']} className='partner-special-detail__title' />
      <div className='partner-special-detail__logo'>
        {layoutData['ListPartnerSpecials'].map((item) => (
          <div key={item?.Id} className='logo-wrapper'>
            <Image field={item?.['Partner Logo']}/>
          </div>
        ))}
      </div>
    </>
  );
};

PartnerSpecialsDetailLogo.propTypes = {
  layoutData: object
};

export default PartnerSpecialsDetailLogo;
