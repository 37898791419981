export default class CouponItemModel {
  constructor() {
    this['Id'] = '';

    this['FriendlyId'] = '';

    this['Title'] = '';

    this['Code'] = '';

    this['DisplayText'] = '';

    this['Discount'] = '';

    this['Validity'] = '';

    this['Description'] = '';

    this['ValidityTo'] = '';
  }
  getData(dataSource) {
    if (Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FriendlyId')) {
      this['FriendlyId'] = dataSource['FriendlyId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'] = dataSource['Title'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Code')) {
      this['Code'] = dataSource['Code'].toUpperCase();
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayText')) {
      this['DisplayText'] = dataSource['DisplayText'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Discount')) {
      this['Discount'] = dataSource['Discount'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Validity')) {
      this['Validity'] = dataSource['Validity'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'] = dataSource['Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ValidityTo')) {
      this['ValidityTo'] = dataSource['ValidityTo'];
    }

    return this;
  }
}
