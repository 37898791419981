import './ProductOverview.scss';

import React from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const ProductOverview = () => {
  const contentProductOverview = SitecoreContextFactoryService.getValueContextRouteItem('fields')['Product Overview'];

  return contentProductOverview ? <RichText className='product-overview' field={contentProductOverview} /> : '';
};

export default ProductOverview;
