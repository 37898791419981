import produce from 'immer';

import * as spinAndWinTypes from '@redux/actions/spinAndWin/spinAndWinTypes';

const initState = {
  isLoading: false,
  luckyDrawData: undefined
};

const getAvailableLuckyDrawReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case spinAndWinTypes.GET_AVAILABLE_LUCKY_DRAW:
      draft.isLoading = true;
      break;

    case spinAndWinTypes.GET_AVAILABLE_LUCKY_DRAW_SUCCESS:
      draft.isLoading = false;

      draft.luckyDrawData = action.payload.LuckyDraw;
      break;

    case spinAndWinTypes.GET_AVAILABLE_LUCKY_DRAW_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default getAvailableLuckyDrawReducer;
