import './OrderInfo.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { ENUM_PACKAGE_CLASS } from '@utils/constant';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import BillingAddressComponent from '../../../../FulfillmentOptions/DeliveryComponent/BillingAddressComponent';
import ProductListComponent from '../../../../FulfillmentOptions/DeliveryComponent/ProductListComponent';
import StoreAddressComponent from '../../../../FulfillmentOptions/DeliveryComponent/StoreAddressComponent';
import UserAddressComponent from '../../../../FulfillmentOptions/DeliveryComponent/UserAddressComponent';

const PACKAGE = '{seq}';

const OrderInfoComponent = ({ type, fields, layoutData, data, packageNumber, page, cartData, depositProduct }) => {
  const [packageTitle, setPackageTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [shipItems, setShipItems] = useState(null);
  const [sfExpress, setSfExpress] = useState(null);
  const hidePackageStatus = fields?.['Hide Package Status']?.value;

  useEffect(() => {
    let title = '';
    if (type === 'billing-details') {
      title = layoutData['Billing Title'].value;
    } else if (type === 'sf-express') {
      title = layoutData['SF Pickup Package Title'].value.replace(PACKAGE, packageNumber);
    } else if (type === 'delivery-by-sf-express') {
      title = layoutData['Delivery SF Express Package Label'].value.replace(PACKAGE, packageNumber);
    } else if (type === 'deposit') {
      title = 'Deposit';
    } else {
      title = layoutData['Delivery Package Title'].value.replace(PACKAGE, packageNumber);
    }

    setPackageTitle(title);

    setShipItems(findShipmentType(cartData?.Shipments, 'ShipItems') || cartData?.OrderStatus || null);

    setSfExpress(findShipmentType(cartData?.Shipments, 'SFExpress') || null);
  }, []);

  const getClassForPackage = (type) => {
    return ENUM_PACKAGE_CLASS.find((item) => item.type === type)?.class || '';
  };

  const findShipmentType = (shipments, deliveryType) => {
    return shipments.find((shipment) => {
      return shipment?.DeliveryType === deliveryType;
    });
  };

  return (
    layoutData && (
      <div className='delivery-component'>
        <div className='container'>
          <div className='delivery-component__header' onClick={() => setOpen(!open)}>
            <div className='delivery-component__header__icon'>
              <span className={`package-icon ${getClassForPackage(type)}`}></span>
            </div>
            <div className='delivery-component__header__text'>
              <h2 className='delivery-component__header__title'>{packageTitle}</h2>
              {(!hidePackageStatus && (type === 'deposit' || type === 'delivery' || type === 'delivery-by-sf-express')) ? (
                shipItems ? (
                  <div className='orders-detail__general-info__status orders-widget__content__status'>
                    <span className={`orders-widget__content__status__icon status--${shipItems?.ShipmentStatus?.Status || shipItems?.Status}`}></span>
                    <span className='orders-widget__content__status__text'>{shipItems && shipItems?.ShipmentStatus?.Status || shipItems?.StatusDisplayName}</span>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
              {type === 'sf-express' ? (
                sfExpress ? (
                  <div className='orders-detail__general-info__status orders-widget__content__status'>
                    {sfExpress?.ShipmentStatus?.Status ? (
                      <span className={`orders-widget__content__status__icon status--${sfExpress?.ShipmentStatus?.Status}`}></span>
                    ) : (
                      <></>
                    )}
                    <span className='orders-widget__content__status__text'>{sfExpress && sfExpress?.ShipmentStatus?.Status}</span>
                  </div>
                ) : (
                  <></>
                )
              ) : (
                <></>
              )}
            </div>
            <div className='delivery-component__header__icon-collapse'>
              <i className='icon-chevron-down-big'></i>
            </div>
          </div>
          <div className='delivery-component__wrapper'>
            <div className='delivery-container'>
              {type === 'billing-details' ? (
                <BillingAddressComponent
                  cartData={cartData}
                  layoutData={layoutData}
                  type={type}
                  page={page}
                  isMyOrder={true}
                ></BillingAddressComponent>
              ) : type === 'delivery' || type === 'delivery-by-sf-express' ? (
                <>
                  <UserAddressComponent cartData={cartData} layoutData={layoutData} type={type} page={page} isMyOrder={true} cartLineData={data}></UserAddressComponent>
                  <ProductListComponent layoutData={layoutData} type={type} page={page} productList={data}></ProductListComponent>
                </>
              ) : type === 'deposit' ? (
                <>
                  <UserAddressComponent cartData={cartData} layoutData={layoutData} type={type} page={page} isMyOrder={true} cartLineData={data}></UserAddressComponent>
                  <ProductListComponent layoutData={layoutData} type={type} page={page} productList={[]} productItem={depositProduct}></ProductListComponent>
                </>
              ) : (
                <>
                  <StoreAddressComponent cartData={cartData} layoutData={layoutData} data={data} type={type} page={page}></StoreAddressComponent>
                  <ProductListComponent layoutData={layoutData} type={type} page={'review'} productList={data}></ProductListComponent>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

OrderInfoComponent.propTypes = {
  type: PropTypes.string,
  data: PropTypes.any,
  depositProduct: PropTypes.any,
  fields: PropTypes.any,
  layoutData: PropTypes.any,
  cartData: PropTypes.any,
  packageNumber: PropTypes.number,
  page: PropTypes.string
};

export default OrderInfoComponent;
