import Proptypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import ToggleWisList from '@components/Share/ToggleWisList';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import BOShippingInfo from '../BOShippingInfo';
import BOLabelItem from './BOLabelItem';

const BOImages = (props) => {
  const { dataFields, variantActive, productId, deliveryOptions, productData, totalSellingPriceText, totalListPriceText } = props;
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const renderStatusLBL = (type) => {
    const statusLBLText = {
      InStock: 'LBL-InStock',
      PreOrderable: 'LBL-PreOrder',
      BackOrderable: 'LBL-BackOrder',
      OutOfStock: 'LBL-OutOfStock'
    };

    return statusLBLText[type] || '';
  };

  return variantActive ? (
    <Fragment>
      <div className={`${isClientTelingUser && 'col-xl-6'} bo-product-image`}>
        <div className='list-tags'>
          {variantActive.Labels.length ? (
            variantActive.Labels.map((item, index) => {
              if (index < 4) {
                return <BOLabelItem key={index} data={item} />;
              }
            })
          ) : (
            <></>
          )}
        </div>
        {isClientTelingUser ? (
          <div className='bo-product-image__box'>
            <div className='bo-product-image__picture'>
              <img src={`${variantActive.Images[0]}`} alt='Product Image' />
            </div>
            <div className='bo-product-image__info'>
              <span className='bo-product-image__info__title'>{productData?.ProductName}</span>
              {totalSellingPriceText !== '0' && totalListPriceText !== '0' ? (
                totalSellingPriceText === totalListPriceText ? (
                  <span className='bo-product-image__info__price-curent'>{totalSellingPriceText}</span>
                ) : (
                  <Fragment>
                    <span className='bo-product-image__info__price-curent'>{totalSellingPriceText}</span>
                    <del className='bo-product-image__info__price-old'>{totalListPriceText}</del>
                  </Fragment>
                )
              ) : (
                <></>
              )}
            </div>
          </div>
        ) : (
          <>
            <ToggleWisList
              dataProduct={{
                ProductId: productId,
                VariantPropertyValue: variantActive.VariantPropertyValue,
                itemListName: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Page Title']?.value,
                ...productData
              }}
            />
            <div className='bo-product-image__picture'>
              <img src={`${variantActive.Images[0]}`} alt='Product Image' />
            </div>
          </>
        )}
      </div>
      <BOShippingInfo
        dataTextFields={dataFields}
        statusLBL={messageObj[renderStatusLBL(variantActive.Stock.StockStatus.Name)]}
        deliveryStatus={variantActive.Stock.FinalStatus.DeliveryLabel}
        storePickUpLabel={dataFields['Store Pick Up Label'].value}
        deliveryOptions={deliveryOptions}
      />
    </Fragment>
  ) : null;
};

BOImages.propTypes = {
  dataFields: Proptypes.any,
  productId: Proptypes.any,
  variantActive: Proptypes.any,
  deliveryOptions: Proptypes.array,
  labels: Proptypes.array,
  productData: Proptypes.object,
  totalSellingPriceText: Proptypes.any,
  totalListPriceText: Proptypes.any
};

export default BOImages;
