import LinkModel from '@models/LinkModel';
import TextModel from '@models/TextModel';

export default class CountdownTimerModel {
  constructor() {
    this.ComingSoonLable = new TextModel();

    this.End = new TextModel();
    
    this.Link = new LinkModel();

    this.Start = new TextModel();

    this.FinishedLable = new TextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'End')) {
      this.End.getData(dataSource['End']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link') && dataSource.Link.value.href) {
      this.Link.getData(dataSource['Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Start')) {
      this.Start.getData(dataSource['Start']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ComingSoonLable')) {
      this.ComingSoonLable.getData(dataSource['ComingSoonLable']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FinishedLable')) {
      this.FinishedLable.getData(dataSource['FinishedLable']);
    }

    return this;
  }
}
