import AddressSettingModel from '@components/SlicingPage/MyAccount/MyAddress/models/AddressSettingModel';
import LinkModelWithTitle from '@models/LinkModelWithTitle';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const textLabel = [
  'Warning Details',
  'Close Button Text',
  'Deposit Close Popup Button Text',
  'Expiration Date Label',
  'Purchase On Spot Text',
  'Save Sales Order As Draft Text',
  'Send Order To Customer Text',
  'Tax Message Label',
  'Delete Sales Order As Draft Text',
  'Delete Sales Order Success Text',
  'Delete Sales Order Fail Text',
  'Expiration Date Label'
];

const linkLabel = [
  'Purchase On Spot Link',
  'Send Order To Customer Link',
  'Save Sales Order As Draft Link',
  'Redirect Sales Order Listing Link'
];

export default class CartSummaryModel {
  constructor() {
    textLabel.map((item) => (this[item] = new SitecoreTextModel()));

    linkLabel.map((item) => (this[item] = new SitecoreLinkModel()));

    this['Applied Coupons Message'] = new SitecoreTextModel();

    this['Area Selection Label'] = new SitecoreTextModel();

    this['Change Area Label'] = new SitecoreTextModel();

    this['City Selection Label'] = new SitecoreTextModel();

    this['Confirm Selection Label'] = new SitecoreTextModel();

    this['Delivery Fee Label'] = new SitecoreTextModel();

    this['Delivery Zone Instruction'] = new SitecoreTextModel();

    this['Delivery Zone Title'] = new SitecoreTextModel();

    this['Discount Label'] = new SitecoreTextModel();

    this['Estimated Delivery Fee Label'] = new SitecoreTextModel();

    this['Province Selection Label'] = new SitecoreTextModel();

    this['Select Area Label'] = new SitecoreTextModel();

    this['Subtotal Label'] = new SitecoreTextModel();

    this['Tax Label'] = new SitecoreTextModel();

    this['Total Label'] = new SitecoreTextModel();

    this['Total Saving Label'] = new SitecoreTextModel();

    this['Zip Code Label'] = new SitecoreTextModel();

    this['Select Area Text'] = new SitecoreTextModel();

    this['Pick Up Fee Label'] = new SitecoreTextModel();

    this['Checkout Link'] = new LinkModelWithTitle();

    this['Additional Link'] = new LinkModelWithTitle();

    this['Address Settings'] = new AddressSettingModel();

    this.showSelectArea = true;

    this['Fulfillment Link'] = new LinkModelWithTitle();

    this.IsFreeship = false;

    this['Enable Default Delivery'] = false;

    this['Show Sales Tax'] = false;

    this['Applied Offers Description'] = new SitecoreTextModel();

    this['Applied Offers Title'] = new SitecoreTextModel();

    this['Please Select Area Label'] = new SitecoreTextModel();

    this['Coupon Require Text'] = '';

    this['Please Select Area Label'] = new SitecoreTextModel();

    this['Pick Up Fee Label'] = new SitecoreTextModel();

    this['Learn More Label'] = new SitecoreTextModel();

    this['Valid Until Label'] = new SitecoreTextModel();

    this['Coupon Detail Title'] = new SitecoreTextModel();

    this['To Label'] = new SitecoreTextModel();

    this['Learn More Shipping Label'] = new SitecoreTextModel();

    this['SF Express Pick Up Fee Label'] = new SitecoreTextModel();

    this['Shipping Text'] = new SitecoreTextModel();

    this['Default Shipping Text'] = new SitecoreTextModel();

    this['Delivery Fee Information'] = new SitecoreTextModel();

    this['Popup Title Label'] = new SitecoreTextModel();

    this['Hint Text'] = new SitecoreTextModel();

    this['Edit Cart Label'] = new SitecoreTextModel();

    this['Continue To Checkout Label'] = new SitecoreTextModel();

    this['Installment Warning Title'] = new SitecoreTextModel();

    this['Enable Tax Message'] = false;
  }
  getData(dataSource) {
    textLabel.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    linkLabel.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Settings')) {
      this['Address Settings'].getData(dataSource['Address Settings']?.fields || {});
    }
    if (!dataSource['Address Settings'] || dataSource['Address Settings']?.fields['Address Elements'].length === 0) {
      this.showSelectArea = false;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Checkout Link')) {
      this['Checkout Link'].getData(dataSource['Checkout Link']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Link')) {
      this['Additional Link'].getData(dataSource['Additional Link']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Applied Coupons Message')) {
      this['Applied Coupons Message'].getData(dataSource['Applied Coupons Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Select Area Text')) {
      this['Select Area Text'].getData(dataSource['Select Area Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Area Selection Label')) {
      this['Area Selection Label'].getData(dataSource['Area Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Area Label')) {
      this['Change Area Label'].getData(dataSource['Change Area Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'City Selection Label')) {
      this['City Selection Label'].getData(dataSource['City Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Confirm Selection Label')) {
      this['Confirm Selection Label'].getData(dataSource['Confirm Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Fee Label')) {
      this['Delivery Fee Label'].getData(dataSource['Delivery Fee Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Zone Instruction')) {
      this['Delivery Zone Instruction'].getData(dataSource['Delivery Zone Instruction']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Zone Title')) {
      this['Delivery Zone Title'].getData(dataSource['Delivery Zone Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Discount Label')) {
      this['Discount Label'].getData(dataSource['Discount Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Estimated Delivery Fee Label')) {
      this['Estimated Delivery Fee Label'].getData(dataSource['Estimated Delivery Fee Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Province Selection Label')) {
      this['Province Selection Label'].getData(dataSource['Province Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Select Area Label')) {
      this['Select Area Label'].getData(dataSource['Select Area Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Subtotal Label')) {
      this['Subtotal Label'].getData(dataSource['Subtotal Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Tax Label')) {
      this['Tax Label'].getData(dataSource['Tax Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total Label')) {
      this['Total Label'].getData(dataSource['Total Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total Saving Label')) {
      this['Total Saving Label'].getData(dataSource['Total Saving Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Zip Code Label')) {
      this['Zip Code Label'].getData(dataSource['Zip Code Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Fulfillment Link')) {
      this['Fulfillment Link'].getData(dataSource['Fulfillment Link']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsFreeship')) {
      this.IsFreeship = dataSource.IsFreeship;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Enable Default Delivery')) {
      this['Enable Default Delivery'] = dataSource['Enable Default Delivery'].value;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cart Configuration') && dataSource['Cart Configuration']?.fields) {
      this['Show Sales Tax'] = dataSource['Cart Configuration'].fields['Show Sales Tax']?.value;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Applied Offers Description')) {
      this['Applied Offers Description'].getData(dataSource['Applied Offers Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Applied Offers Title')) {
      this['Applied Offers Title'].getData(dataSource['Applied Offers Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Pick Up Fee Label')) {
      this['Pick Up Fee Label'].getData(dataSource['Pick Up Fee Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Please Select Area Label')) {
      this['Please Select Area Label'].getData(dataSource['Please Select Area Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon Require Text')) {
      this['Coupon Require Text'] = dataSource['Coupon Require Text'] ?? this['Coupon Require Text'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Please Select Area Label')) {
      this['Please Select Area Label'].getData(dataSource['Please Select Area Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Pick Up Fee Label')) {
      this['Pick Up Fee Label'].getData(dataSource['Pick Up Fee Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Label')) {
      this['Learn More Label'].getData(dataSource['Learn More Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Valid Until Label')) {
      this['Valid Until Label'].getData(dataSource['Valid Until Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon Detail Title')) {
      this['Coupon Detail Title'].getData(dataSource['Coupon Detail Title'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'To Label')) {
      this['To Label'].getData(dataSource['To Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Fee Information')) {
      this['Delivery Fee Information'].getData(dataSource['Delivery Fee Information'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Shipping Text')) {
      this['Shipping Text'].getData(dataSource['Shipping Text'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Default Shipping Text')) {
      this['Default Shipping Text'].getData(dataSource['Default Shipping Text'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SF Express Pick Up Fee Label')) {
      this['SF Express Pick Up Fee Label'].getData(dataSource['SF Express Pick Up Fee Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Shipping Label')) {
      this['Learn More Shipping Label'].getData(dataSource['Learn More Shipping Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Popup Title Label')) {
      this['Popup Title Label'].getData(dataSource['Popup Title Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Hint Text')) {
      this['Hint Text'].getData(dataSource['Hint Text'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Cart Label')) {
      this['Edit Cart Label'].getData(dataSource['Edit Cart Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Continue To Checkout Label')) {
      this['Continue To Checkout Label'].getData(dataSource['Continue To Checkout Label'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Installment Warning Title')) {
      this['Installment Warning Title'].getData(dataSource['Installment Warning Title'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Enable Tax Message')) {
      this['Enable Tax Message'] = dataSource['Enable Tax Message'];
    }

    return this;
  }
}
