import './RegisterLogin.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from 'react-redux';
import {useLocation} from 'react-router-dom';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { PRODUCT_REVIEW_PAGE } from '@utils/constant';

import Login from './Login';
import LoginModel from './models/LoginModel';
import RegisterLoginModel from './models/RegisterLoginModel';
import RegisterModel from './models/RegisterModel';
import Register from './Register';

// import RegisterActiveMail from './RegisterActiveMail';
export const FROM_PAGE_QUERY_STRING_SEPARATOR_SYMBOL = '|';

export const REGISTER_TAB_KEY = 'osim-account-register';

export const LOGIN_TAB_KEY = 'osim-account-login';

const registerLoginTab = {
  Register: REGISTER_TAB_KEY,
  Login: LOGIN_TAB_KEY
};

const RegisterLogin = (props) => {
  const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');
  const submitProductReviewReducer = useSelector((state) => state.productReviewReducer.submitProductReviewReducer);
  const isShowLogin = submitProductReviewReducer.isShowLogin;
  const { search } = useLocation();
  const { fields } = props;
  const [tabCurrent, setTab] = useState(registerLoginTab.Login);
  const [dataSources, setDataSources] = useState(null);
  // const [emailRegister, setInfoRegister] = useState(null);

  useEffect(() => {
    setDataSources(new RegisterLoginModel().getData(fields || {}));
  }, []);

  useEffect(() => {
    if (search) {
      // eslint-disable-next-line no-unused-vars
      const [_, tabKey] = search.split(FROM_PAGE_QUERY_STRING_SEPARATOR_SYMBOL);
      if (tabKey) setTab(tabKey);
    }
  }, [search]);

  const isShowLoginComp = () => {
    if (templateName === PRODUCT_REVIEW_PAGE) {
      return isShowLogin;
    }

    return true;
  };

  return isShowLoginComp() ? (
    <div className='osim-account'>
      {/* {!isRegisterSuccess ?
        <div className='osim-account__tab'>
          <div className='container osim-account__tab__wrap no-padding-mobile'>
            <Tabs activeKey={tabCurrent} onSelect={tabCurrent => setTab(tabCurrent)}>
              <Tab eventKey={registerLoginTab.Register} title={dataSources ? <Text field={dataSources['Register Tab Label']}/> : ''}>
                <div className='osim-account__tab__content'>
                  <Register setEmailRegisterEvt={(data) => setInfoRegister(data)} inputData={new RegisterModel().getData(fields || {})} changeTabEvt={() => setTab(registerLoginTab.Login)}/>
                </div>
              </Tab>
              <Tab eventKey={registerLoginTab.Login} title={dataSources ? <Text field={dataSources['Login Tab Label']}/> : ''}>
                <div className='osim-account__tab__content'>
                  <Login inputData={new LoginModel().getData(fields || {})} changeTabEvt={() => setTab(registerLoginTab.Register)}/>
                </div>
              </Tab>
            </Tabs>
          </div>
        </div> :
        <div className='osim-account__tab one-last-step'>
          <RegisterActiveMail emailRegister={emailRegister} dataSources={dataSources['Register One Last Step']}/>
        </div>
      } */}
      <div className='osim-account__tab'>
        <div className='container osim-account__tab__wrap no-padding-mobile'>
          <Tabs activeKey={tabCurrent} onSelect={(tabCurrent) => setTab(tabCurrent)}>
            <Tab eventKey={registerLoginTab.Register} title={dataSources ? <Text field={dataSources['Register Tab Label']} /> : ''}>
              <div className='osim-account__tab__content'>
                <Register
                  // setEmailRegisterEvt={(data) => setInfoRegister(data)}
                  inputData={new RegisterModel().getData(fields || {})}
                  changeTabEvt={() => setTab(registerLoginTab.Login)}
                />
              </div>
            </Tab>
            <Tab eventKey={registerLoginTab.Login} title={dataSources ? <Text field={dataSources['Login Tab Label']} /> : ''}>
              <div className='osim-account__tab__content'>
                <Login inputData={new LoginModel().getData(fields || {})} changeTabEvt={() => setTab(registerLoginTab.Register)} />
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

RegisterLogin.propTypes = {
  fields: PropTypes.object
};

export default RegisterLogin;
