import { any, object, string } from 'prop-types';
import React, { useMemo } from 'react';

import CartItemFreeGift from '@components/SlicingPage/CartDetails/CartList/CartItem/CartItemFreeGift';

const ProductWithDepositFreeGift = ({ layoutData, page, data }) => {
  const isShowFreeGiftInfo = useMemo(() => {
    return !(page === 'review' || page === 'balance-payment');
  }, [page]);

  return isShowFreeGiftInfo ? (
    <CartItemFreeGift
      freeGiftNumbers={data?.Quantity}
      dataSources={{
        ...layoutData,
        ['Free Gift Label']: layoutData?.['Delivery Free Gift Label']
      }}
      promotionFreeGifts={data?.DepositPromotionCartLines}
      isInDeliveryPage={true}
    />
  ) : (
    <></>
  );
};

ProductWithDepositFreeGift.propTypes = {
  layoutData: object,
  page: string,
  data: any
};

export default ProductWithDepositFreeGift;
