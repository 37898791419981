import './SpinWinPopup.scss';

import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { useSelector } from 'react-redux';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import { SpinWinBannerContext } from '../Spin&WinBanner';
import SpinWinContext from '../SpinAndWinContext';

const SpinWinPopup = ({ setIsShowModalAfterSpin }) => {
  const { handleApplyCoupon } = useContext(SpinWinContext);
  const { layoutData, arrayLuckyDrawCustom, prizeNumber } = useContext(SpinWinBannerContext);
  const couponSpinData = useSelector((state) => state.spinAndWinReducer.applyCouponSpinReducer.couponData);

  return (
    <div className='spinwin-popup'>
      <div className='spinwin-popup__banner'>
        <div className='spinwin-popup__banner__bg'>
          <Image field={layoutData['You Won Background Image']} />
        </div>
        <div className='spinwin-popup__banner__content'>
          <Text tag='h2' className='spinwin-popup__banner__content__title' field={layoutData['You Won Title']} />
          <div className='spinwin-popup__banner__content__img'>
            <Image field={layoutData['Prize Image']}/>
            {/*<img src={require('@assets/images/voucher-img.png')} alt='voucher-img' />*/}
          </div>
          <h3 className='spinwin-popup__banner__content__prize'>{arrayLuckyDrawCustom?.[prizeNumber].SliceName}</h3>
        </div>
      </div>
      <div className='spinwin-popup__group'>
        <div className='spinwin-popup__prize-desc'>
          <p>{layoutData?.['Prize Description']?.value?.replace('{CouponDescription}', arrayLuckyDrawCustom?.[prizeNumber].SliceName)}</p>
        </div>
        <div className='spinwin-popup__btn text-center'>
          <div className='spinwin-popup__btn__item'>
            <button type='button' className='btn btn-primary' onClick={() => handleApplyCoupon(couponSpinData)}>
              <Text tag='span' field={layoutData['Apply Offer Button Text']} />
            </button>
          </div>
          <div className='spinwin-popup__btn__item'>
            <Text tag='span' className='btn-link-text' onClick={() => setIsShowModalAfterSpin(false)} field={layoutData['Spin Again Button Text']} />
          </div>
        </div>
      </div>
    </div>
  );
};

SpinWinPopup.propTypes = {
  onHandlerShowSpinWinPopup: PropTypes.any,
  setIsShowModalAfterSpin: PropTypes.any
};

export default SpinWinPopup;
