import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import ProductModel from './ProductModel';

export default class ProductAddedToCardModel {
  constructor() {
    this['CTA Button'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();

    this['Product'] = new ProductModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'].getData(dataSource['CTA Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product')) {
      this['Product'].getData(dataSource['Product']);
    }

    return this;
  }
}
