import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import { getCatalogHeaderNavigationFailed, getCatalogHeaderNavigationSuccess } from '@redux/actions/productReview';
import { GET_CATALOG_HEADER_NAVIGATION } from '@redux/actions/productReview/productReviewTypes';
import { getAsObservable } from '@services/genericService';

import { GET_CATALOG_HEADER_NAVIGATION_ENDPOINT } from './config';

export const getCatalogHeaderNavigationEpic = (action$) =>
  action$.pipe(
    ofType(GET_CATALOG_HEADER_NAVIGATION),
    switchMap((action) => {
      return getAsObservable(`${GET_CATALOG_HEADER_NAVIGATION_ENDPOINT}{${action.payload}}`).pipe(
        map((res) => {
          if (res.status === 200) {
            return getCatalogHeaderNavigationSuccess(res.data);
          } else {
            return getCatalogHeaderNavigationFailed('Failed');
          }
        })
      );
    })
  );
