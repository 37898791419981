import produce from 'immer';

import * as cpAppointmentTypes from '@redux/actions/cpAppointmentListing/cpAppointmentListingTypes';

const initState = {
  isLoading: false,
  isSuccess: false,
  result: {},
  isFailed: false,
  leadStatus: ''
};

const cpAppointmentListingReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cpAppointmentTypes.GET_APPOINTMENT_LIST:
      draft.isLoading = true;

      draft.isSuccess = false;

      draft.result = {};

      draft.leadStatus = action.payload.Status;

      draft.isFailed = false;
      break;

    case cpAppointmentTypes.GET_APPOINTMENT_LIST_SUCCESS:
      draft.isLoading = false;

      draft.isSuccess = true;

      draft.result = action.payload;

      draft.isFailed = false;
      break;

    case cpAppointmentTypes.GET_APPOINTMENT_LIST_FAILED:
      draft.isLoading = false;

      draft.isSuccess = false;

      draft.result = {};

      draft.isFailed = true;
      break;
    }

    return draft;
  });

export default cpAppointmentListingReducer;