import PropTypes from 'prop-types';
import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';


const RawText = ({fields}) => {

  return <RichText field={fields?.['Text'] || {}} />;
};

RawText.propTypes = {
  fields: PropTypes.object
};

export default RawText;
