import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'EventId',
  'Item Name',
  'Title',
  'Description',
  'From Time',
  'To Time',
  'Multiple Date And Time Text',
  'Change Button Text',
  'Cancel Button Text',
  'Select Location Label',
  'Select Date Label',
  'Select Time Label',
  'Q And A Label',
  'Change Button Link',
  'Cancel Button Link',
  'EventId Chosed',
  'Time SlotId Chosed',
  'Time Slot Selected',
  'Location Selected',
  'Location Title',
  'Select Number Label',
  'Just Me Label',
  'Just Me Value',
  'Me Plus Label',
  'Me Plus Value',
  'Date Selected',
  'Email ',
  'PhoneNumber',
  'FirstName',
  'LastName',
  'Expired Message Text',
  'Field Required Message Text'
];
const IMG_ARR = ['Thumnail Image', 'Banner Image'];

export default class MemberEventChangeModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    IMG_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    this['From Date'] = '';

    this['To Date'] = '';

    this['Gallery Images'] = [];

    this['Location'] = [];

    this['Question and Answer'] = [];

    this['Member Tier'] = [];

    this['Number Of Guest'] = null;

    this['Answers Id'] = [];

    this['EventDetails'] = null;

    this['SubmitedDate'] = null;
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMG_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'From Date')) {
      this['From Date'] = deepCopy(dataSource['From Date']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'To Date')) {
      this['To Date'] = deepCopy(dataSource['To Date']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Gallery Images')) {
      this['Gallery Images'] = deepCopy(dataSource['Gallery Images']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Location')) {
      this['Location'] = deepCopy(dataSource['Location']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Question and Answer')) {
      this['Question and Answer'] = deepCopy(dataSource['Question and Answer']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Member Tier')) {
      this['Member Tier'] = deepCopy(dataSource['Member Tier']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Number Of Guest')) {
      this['Number Of Guest'] = deepCopy(dataSource['Number Of Guest']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Answers Id')) {
      this['Answers Id'] = deepCopy(dataSource['Answers Id']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'EventDetails')) {
      this['EventDetails'] = deepCopy(dataSource['EventDetails']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SubmitedDate')) {
      this['SubmitedDate'] = deepCopy(dataSource['SubmitedDate']);
    }

    return this;
  }
}
