import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';

export default class HeaderLogoModel {
  constructor() {
    this.Image = new SitecoreImageModel();

    this['Second Image'] = new SitecoreImageModel();

    this.Link = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image.getData(dataSource.Image);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second Image')) {
      this['Second Image'].getData(dataSource['Second Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this.Link.getData(dataSource.Link);
    }

    return this;
  }
}
