import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import NotifyModel from '@models/NotifyModel';
import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { post } from '@services/genericService';

import * as configs from './config';

export const getNotifyProductStatusEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_NOTIFY_STATUS_PRODUCT),
    switchMap((action) => {
      const params = {
        email: action.payload.payload.email,
        productId: action.payload.payload.productId,
      };
      
      return from(post(configs.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getNotifyStatusProductSuccess(new NotifyModel().getData(res.data));
          } else {
            return actions.getNotifyStatusProductFailed(new NotifyModel().getData(res.data));
          }
        })
      );
    })
  );
