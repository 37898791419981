import './CartSellerComponent.scss';

import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import {removeCurlyBrackets} from '@redux/epic/bookingExperience/utils';

const CartSellerComponent = ({cartData, layoutData}) => {
  const staffData = useMemo(() => {
    const staff = cartData.Staff;

    return {
      storeId: removeCurlyBrackets(staff?.StoreId || ''),
      staffId: staff?.StaffId || ''
    };
  }, [cartData]);

  return (
    <div className='container'>
      <div className='row'>
        <div className='col-md-6'>
          <dl className='cart-seller'>
            <dt className='cart-seller__title'>{layoutData['Store ID Label'].value}</dt>
            <dd className='cart-seller__description'>{staffData.storeId}</dd>
          </dl>
        </div>
        <div className='col-md-6'>
          <dl className='cart-seller'>
            <dt className='cart-seller__title'>{layoutData['Staff ID Label'].value}</dt>
            <dd className='cart-seller__description'>{staffData.staffId}</dd>
          </dl>
        </div>
      </div>
    </div>
  );
};

CartSellerComponent.propTypes = {
  cartData: PropTypes.any,
  layoutData: PropTypes.any,
};

export default CartSellerComponent;
