import { createContext, useContext } from 'react';

const initialContextValue = {
  layoutData: null,
  categorySelected: null,
  setCategorySelected: () => {},
  infoSellingPriceLabelHeight: [],
  setInfoSellingPriceLabelHeight: () => {},
  productNameLabelHeight: [],
  setProductNameLabelHeight: () => {},
  productVariantItemHeight: [],
  setProductVariantItemHeight: () => {},
  productTitleItemHeight: [],
  setProductTitleItemHeight: () => {}
};

export const CompareProductsContext = createContext(initialContextValue);

const useCompareProduct = () => {
  return useContext(CompareProductsContext);
};

export default useCompareProduct;
