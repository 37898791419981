import { useFormik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { HK_SITE_NAME } from '@utils/constant';
import { convertDDMMYYYYStringToDate } from '@utils/customsHook/useFormatDate/utils';
import Global from '@utils/global';

const useEditAppointmentFormik = (appointmentDetail, onSubmit) => {
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');

  const isConvertDate = useMemo(() => {
    return currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK';
  }, [currentSiteName, currentLanguage]);

  const initialValues = useMemo(() => {
    const contactAnswers = [];
    if (appointmentDetail?.['Questions']?.length) {
      for (const question of appointmentDetail?.['Questions']) {
        let questionObj = {
          Id: question.Id,
          IsRequired: question.IsRequired ? true : false,
          Answers: []
        };

        for (const answer of question.Answers) {
          questionObj.Answers.push({ AnswerId: answer.AnswerId });
        }

        contactAnswers.push(questionObj);
      }
    }
    let appointmentDate = appointmentDetail?.['AppointmentDate'];
    if (isConvertDate) {
      const dateConverted = convertDDMMYYYYStringToDate(appointmentDate);

      appointmentDate = dateConverted ? Global.customDate(dateConverted) : '';
    }

    return {
      date: appointmentDate,
      status: appointmentDetail?.['LeadStatus'],
      note: appointmentDetail?.['Notes'] || '',
      contactAnswers: contactAnswers,
      numberOfGuest: {
        value: appointmentDetail?.NumberOfGuests,
        isRequired: appointmentDetail?.IsBookExperience
      },
      timeSlotId: {
        value: appointmentDetail?.TimeSlotId.toLowerCase(),
        isRequired: appointmentDetail?.IsBookExperience
      },
      productId: {
        value: appointmentDetail?.ProductId.toLowerCase(),
        isRequired: appointmentDetail?.IsBookExperience
      },
      teaId: {
        value: appointmentDetail?.TeaId.toLowerCase(),
        isRequired: appointmentDetail?.IsBookExperience
      },
      scentId: {
        value: appointmentDetail?.ScentId.toLowerCase(),
        isRequired: appointmentDetail?.IsBookExperience
      }
    };
  }, [appointmentDetail, isConvertDate]);

  const schemaValidate = Yup.object().shape({
    contactAnswers: Yup.array().of(
      Yup.object().shape({
        IsRequired: Yup.boolean().notRequired(),
        Answers: Yup.array().when('IsRequired', {
          is: true,
          then: (rule) => rule.min(1)
        })
      })
    ),
    date: Yup.string().trim().required(),
    status: Yup.string().trim().required(),
    note: Yup.string().trim().notRequired(),
    numberOfGuest: Yup.object().shape({
      isRequired: Yup.boolean().notRequired(),
      value: Yup.number().when('isRequired', {
        is: true,
        then: (rule) => rule.required()
      })
    }),
    timeSlotId: Yup.object().shape({
      isRequired: Yup.boolean().notRequired(),
      value: Yup.string().when('isRequired', {
        is: true,
        then: (rule) => rule.trim().required()
      })
    }),
    productId: Yup.object().shape({
      isRequired: Yup.boolean().notRequired(),
      value: Yup.string().when('isRequired', {
        is: true,
        then: (rule) => rule.trim().required()
      })
    }),
    teaId: Yup.object().shape({
      isRequired: Yup.boolean().notRequired(),
      value: Yup.string().when('isRequired', {
        is: true,
        then: (rule) => rule.trim().required()
      })
    }),
    scentId: Yup.object().shape({
      isRequired: Yup.boolean().notRequired(),
      value: Yup.string().when('isRequired', {
        is: true,
        then: (rule) => rule.trim().required()
      })
    })
  });

  return useFormik({
    initialValues: initialValues,
    validationSchema: schemaValidate,
    validateOnChange: false,
    onSubmit: (values) => {
      if (onSubmit) {
        onSubmit(values);
      }
    }
  });
};

export default useEditAppointmentFormik;
