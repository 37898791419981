import { element, func, object, shape } from 'prop-types';
import React, { createContext, useContext } from 'react';

const initialValues = {
  dataFields: null,
  selectedImage: null,
  fileSizeExceedMaximumLimit: null,
  formikRefCurrent: null,
  setSelectedImage: () => {},
  handleTestImageType: () => {}
};
const TradeInUploadImageContext = createContext(initialValues);

export const useTradeInUploadImage = () => useContext(TradeInUploadImageContext);

export const TradeInUploadImageProvider = ({ children, values }) => {
  return (
    <TradeInUploadImageContext.Provider
      value={{
        ...values
      }}
    >
      {children}
    </TradeInUploadImageContext.Provider>
  );
};

TradeInUploadImageProvider.propTypes = {
  children: element,
  values: shape({
    dataFields: object,
    selectedImage: object,
    fileSizeExceedMaximumLimit: object,
    formikRefCurrent: object,
    setSelectedImage: func,
    handleTestImageType: func
  })
};
