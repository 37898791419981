export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj || {}));
}

export function getComponentName(data) {
  const newComponentNameArray = data.map(item => item.componentName);

  return newComponentNameArray;
}

export function deepCompare(obj1, obj2) {
  return deepCopy(obj1) === deepCopy(obj2);
}

export function uniqWith(arr, fn) {
  return arr.filter((element, index) => arr.findIndex((step) => fn(element, step)) === index);
}