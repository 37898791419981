import './PersonalizedOffersWidget.scss';

import { object } from 'prop-types';
import React from 'react';

import PersonalizedOffersWidgetBody from './PersonalizedOffersWidgetBody';
import PersonalizedOffersWidgetProvider from './PersonalizedOffersWidgetProvider';

const PersonalizedOffersWidget = ({ fields }) => {
  return (
    <PersonalizedOffersWidgetProvider fields={fields}>
      <PersonalizedOffersWidgetBody />
    </PersonalizedOffersWidgetProvider>
  );
};

PersonalizedOffersWidget.propTypes = {
  fields: object
};

export default PersonalizedOffersWidget;
