import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { GoogleOAuthProvider } from '@react-oauth/google';

import CustomGoogleAuthButton from './CustomGoogleAuthButton';

const GoogleSSO = ({ dataFields, useGoogleLogin }) => {
  return dataFields && useGoogleLogin ? (
    <div className='osim-account-form__social-login__btn sigle-sign-on-google'>
      <GoogleOAuthProvider clientId={dataFields['App ID'].value}>
        <CustomGoogleAuthButton loginText={dataFields['Login Label']} />
      </GoogleOAuthProvider>
    </div>
  ) : (
    ''
  );
};

const mapStateToProps = (state) => ({
  dataUser: state.singleSignOnReducer.userInfoReducer?.dataUser || [],
  useGoogleLogin: state.settingGlobalReducer.settingGlobal?.UseGoogleLogin
});

GoogleSSO.propTypes = {
  dataFields: PropTypes.object,
  setAuthenGoogleEvt: PropTypes.func,
  useFacebookLogin: PropTypes.bool
};

export default connect(mapStateToProps)(GoogleSSO);
