import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { IS_ADDED_TO_CART_SESSION_STORAGE_NAME } from '@components/BuyingOptions/configs';
import { useSetIsAddedToCartValue } from '@components/BuyingOptions/hooks/useSetIsAddedToCartValue';

export const useControlRichText = () => {
  const initialAddedToCartStatus = {
    status: false,
    isCartEmpty: false
  };
  const renderTimes = useRef(0);
  const setIsAddedToCartSessionValue = useSetIsAddedToCartValue();
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const isGetCartDelivery = useSelector((state) => state.productReducer.cartDetailReducer.isGetCartDelivery);
  const [isShowRichText, setIsShowRichText] = useState(false);

  useEffect(() => {
    if (currentCart) {
      const isAddedToCartSessionValue = window.sessionStorage.getItem(IS_ADDED_TO_CART_SESSION_STORAGE_NAME);
      const isAddedToCartValue = isAddedToCartSessionValue ? JSON.parse(isAddedToCartSessionValue) : initialAddedToCartStatus;

      (async () => {
        return new Promise((resolve) => {
          if (isAddedToCartValue.isCartEmpty) {
            if (isGetCartDelivery && renderTimes.current < 3) {
              renderTimes.current = renderTimes.current + 1;
            }
            if (!isGetCartDelivery && renderTimes.current < 1) {
              renderTimes.current = renderTimes.current + 1;
            }
          } else {
            if (renderTimes.current < 1) renderTimes.current = renderTimes.current + 1;
          }

          resolve(renderTimes.current);
        })
          .then((response) => {
            if ((isGetCartDelivery && response === 3) || (!isGetCartDelivery && response === 1)) {
              setIsShowRichText(isAddedToCartValue.status);
            }
          })
          .finally(() => {
            if (
              isAddedToCartValue.status &&
              ((isGetCartDelivery && renderTimes.current === 3) || (!isGetCartDelivery && renderTimes.current === 1))
            ) {
              setIsAddedToCartSessionValue(false);
            }
          });
      })();
    }
  }, [currentCart, isGetCartDelivery]);

  return isShowRichText;
};
