import { UPDATE_FREE_GIFT_FAILED, UPDATE_FREE_GIFT_START, UPDATE_FREE_GIFT_SUCCESS } from './updateFreeGiftToCartTypes';

export const updateFreeGiftToCartStart = (payload) => ({
  type: UPDATE_FREE_GIFT_START,
  payload
});

export const updateFreeGiftToCartSuccess = () => ({
  type: UPDATE_FREE_GIFT_SUCCESS
});

export const updateFreeGiftToCartFailure = (payload) => ({
  type: UPDATE_FREE_GIFT_FAILED,
  payload
});
