import PropTypes from 'prop-types';
import React, { useEffect, useMemo,useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import NotifyMePopup from '@components/Product/VariantDetail/ProductDetailHeader/NotifyMePopup';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import SwipeComponent from '@components/Share/SwipeComponent';
import { setDynamicBundlePrice } from '@redux/actions/dynamicBundle';
import { getNotifyStatusProduct, resetMessagePopupNotify } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import Global from '@utils/global';

const BundleItems = ({ selectedBundle, layoutData }) => {
  const allowBuy = useSelector((state) => state.dynamicBundleReducer.allowBuy);
  const dispatch = useDispatch();
  const [buyOptionLink, setBuyOptionLink] = useState('');
  const [showNotifyMeButton, setShowNotifyMeButton] = useState(false);
  const [isShowNotifyMe, setIsShowNotifyMe] = useState(false);

  const isNotifySuccess = useSelector((state) => state.productReducer.notifyProductStatusReducer.isNotifySuccess);
  const dynamicBundleReducer = useSelector((state) => state.dynamicBundleReducer);

  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const startTime = new Date(SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value).toISOString();

    const today = new Date().toISOString();

    setShowNotifyMeButton(today < startTime);
  }, []);

  useEffect(() => {
    if (selectedBundle) {
      let isValid = true;

      selectedBundle.forEach((item) => {
        if (!item) {
          isValid = false;
        }
      });

      if (isValid) {
        const bundlePageId = SitecoreContextFactoryService.getValueContextRouteItem('itemId');

        const categoryName = SitecoreContextFactoryService.getValueContextRouteItem('displayName');

        const listSelectedId = selectedBundle.map(s => s.ProductId).join('|');

        setBuyOptionLink(`${layoutData['Buy Now Button Link'].value.href}?bundlePageId=${bundlePageId}&selectedBundleItemIds=${listSelectedId}&LstName=${categoryName}`);
      }

      dispatch(setDynamicBundlePrice(selectedBundle));
    }
  }, [selectedBundle]);

  const message = useMemo(() => {
    if (isNotifySuccess?.message) {
      if (isNotifySuccess.status) {
        return <p className='notify-success'>{isNotifySuccess.message}</p>;
      } else {
        return <p className='notify-faild'>{isNotifySuccess.message}</p>;
      }
    }
    
    return null;
  }, [isNotifySuccess]);

  const onSubmitNotifyHandler = (emailSubmit) => {
    dispatch(getNotifyStatusProduct({ payload: emailSubmit }));
  };

  const onClosePopupNotifyHandler = () => {
    setIsShowNotifyMe(false);

    dispatch(resetMessagePopupNotify());
  };

  const params = {
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 16,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    breakpoints: {

      767: {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
          nextEl: 'bundle-items .swiper-button-next',
          prevEl: 'bundle-items .swiper-button-prev'
        }
      },

      1200: {
        slidesPerView: 6,
        spaceBetween: 32,
        centerInsufficientSlides: true,
        navigation: {
          nextEl: 'bundle-items .swiper-button-next',
          prevEl: 'bundle-items .swiper-button-prev'
        }
      },
    }
  };

  const handleGa4SelectItem = (action) =>{
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode') || layoutData['Dynamic Bundle Data']?.Coupon;
      const categoryName = SitecoreContextFactoryService.getValueContextRouteItem('displayName');
      const currencyCode = layoutData['Currency']?.Name;
      const idxProdGA4 = Global.getIndexGA4Param();

      const dataBundleGA4 = [
        {
          BundleId: layoutData?.['Dynamic Bundle Data']?.BundleId,
          BundleName: layoutData?.['Dynamic Bundle Data']?.BundleName,
          Price: {
            ListPrice: dynamicBundleReducer?.listingPrice,
            SellingPrice: dynamicBundleReducer?.sellingPrice
          },
          VariantPropertyValue: 'Dynamic Bundle',
          Stock: {
            StockQuantity: 1
          }
        }
      ];

      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(idxProdGA4, dataBundleGA4, categoryName, pdpPromoCode, '', currencyCode)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }
  };

  return (
    <div className='create-bundle__items'>
      <div className='container'>
        <div className='promotion-swiper__container'>
          <h2 className='create-bundle__items__title bundle-title text-center experience-title'>{layoutData['Your Bundle Items Label'].value}</h2>
          <SwipeComponent param={params}>
            {selectedBundle.map((item, ind) => {
              return (
                <div className='create-bundle__items__slider' key={ind}>
                  {item ? (
                    <>
                      <div className='create-bundle__items__slider__image'>
                        <img src={item.Image} alt='x' />
                      </div>
                      <h4 className='create-bundle__items__slider__name bundle-description'>{item.ProductName}</h4>
                    </>
                  ) : (
                    <div className='create-bundle__items__slider'>
                      <div className='create-bundle__items__slider__border'>
                        <span className='flex-between'>{layoutData['Select Product Above Label'].value}</span>
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </SwipeComponent>
          <div className='create-bundle__items__button text-center'>
            {showNotifyMeButton ? (
              <button className='btn btn-outline-primary' onClick={() => setIsShowNotifyMe(true)}><Message messageCode='LBL-OutOfStock' /></button>
            ) : (
              <>
                <a href={buyOptionLink} className={`btn btn-primary ${allowBuy ? '' : 'disabled'}`} onClick={() => handleGa4SelectItem('Buy Now')}>
                  {layoutData['Buy Now Button Label'].value}
                </a>
              </>
            )}
          </div>
        </div>
      </div>
      <ModalComponent isShow={isShowNotifyMe} titlePopup={<span><Message messageCode='LBL-OutOfStock' /></span>} onCloseHandler={() => onClosePopupNotifyHandler()}>
        <NotifyMePopup
          inputData={{ productId: SitecoreContextFactoryService.getValueContextRouteItem('itemId') }}
          notifyMeEvt={onSubmitNotifyHandler}
        >
          {message}
        </NotifyMePopup>
      </ModalComponent>
    </div>
  );
};

BundleItems.propTypes = {
  selectedBundle: PropTypes.any,
  layoutData: PropTypes.any,
  listBundleItem: PropTypes.any,
};

export default BundleItems;
