import produce from 'immer';

import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';

import { deepCopy } from '../../../../utils/utility';

const initState = {
  currentCart: null,
  cartStripAds: null,
  currentCartCustom: null,
  isLoading: false,
  error: '',
  temporalCart: {},
  promotionData: null,
  DepositListProduct: null,
  IsPosOrder: false
};

const orderByIdReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myOrderTypes.GET_ORDER_BY_ID:
      draft.isLoading = true;

      draft.isRegister = null;
      break;

    case myOrderTypes.GET_ORDER_BY_ID_SUCCESS:
      draft.currentCart = deepCopy(action.payload);

      draft.currentCartCustom = {
        // Delivery: action.payload.Lines.filter(item => item.IsDelivery),
        Delivery: action.payload.Lines.filter(item=> item.IsDelivery && item?.IsDepositFreeGift === false),
        PickUpStore: action.payload.Lines.filter(item => !item.IsDelivery && !item.IsSFexpress && !item.IsDeliveryBySFExpress),
        SFexpress: action.payload.Lines.filter(item => item.IsSFexpress),
        DeliveryBySFExpress: action.payload.Lines.filter(item => item.IsDeliveryBySFExpress),
        BillingAddress: action.payload.BillingAddress,
        Party: action.payload.Party,
        IsDeliveryFee: action.payload.Lines.filter((line) => line.IsDelivery).length ? true : false,
        IsDeliveryPickupFee: action.payload.Lines.filter((line) => line.IsSFexpress).length ? true : false,
        Staff: action.payload.Staff,
        Payments: action.payload.Payments,
        AppliedOffers: action.payload.AppliedOffers,
        PaymentMethodName: action.payload.PaymentMethodName,
        PromoCodes: action.payload.PromoCodes,
        SFExpressTotal: action.payload.SFExpressTotal,
        InstallmentPeriod: action.payload.InstallmentPeriod,
        Shipments: action.payload.Shipments,
        DepositListProduct: action.payload.Lines.filter(item=> item?.Deposit),
        IsPosOrder: action.payload.IsPosOrder,
        OrderStatus: {
          Status: action.payload.OrderStatus,
          StatusDisplayName: action.payload.OrderStatusDisplayName,
          ColorCode: action.payload.Lines?.[0]?.Color
        }
      };
      break;

    case myOrderTypes.GET_ORDER_BY_ID_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default orderByIdReducer;
