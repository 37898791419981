import * as compareProductTypes from './compareProductTypes';

//GET LIST PRODUCT
export const getListProductStart = (payload) => ({
  type: compareProductTypes.GET_LIST_PRODUCT_START,
  payload
});

export const getListProductSuccess = (payload) => ({
  type: compareProductTypes.GET_LIST_PRODUCT_SUCCESS,
  payload
});

export const getListProductFailed = (payload) => ({
  type: compareProductTypes.GET_LIST_PRODUCT_FAILED,
  payload
});

// GET PRODUCT INFO
export const getProductInfoStart = (payload) => ({
  type: compareProductTypes.GET_PRODUCT_INFO_START,
  payload
});

export const getProductInfoSuccess = (payload, componentNumber) => ({
  type: compareProductTypes.GET_PRODUCT_INFO_SUCCESS,
  payload,
  componentNumber
});

export const getProductInfoFailed = (payload) => ({
  type: compareProductTypes.GET_PRODUCT_INFO_FAILED,
  payload: payload
});

// REMOVE PRODUCT
export const removeProductCompare = (productData) => ({
  type: compareProductTypes.REMOVE_PRODUCT_COMPARE,
  productData
});

// SET VARIANT PRODUCT
export const setVariantProductCompare = (componentNumber, variantItem) => ({
  type: compareProductTypes.SET_VARIANT_PRODUCT_COMPARE,
  componentNumber,
  variantItem
});

// GET LIST PRODUCT DEFAULT
export const getListProductDefaultStart = (listProductDefault) => ({
  type: compareProductTypes.GET_LIST_PRODUCT_DEFAULT_START,
  listProductDefault
});

export const getListProductDefaultSuccess = (payload) => ({
  type: compareProductTypes.GET_LIST_PRODUCT_DEFAULT_SUCCESS,
  payload
});

export const getListProductDefaultFailed = (err) => ({
  type: compareProductTypes.GET_LIST_PRODUCT_DEFAULT_FAILED,
  err
});