import './BannerWithText.scss';

import {bool, object, string} from 'prop-types';
import React from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const BannerWithText = (props) => {
  const { srcImage, srcImageMobile, title, description, btnText, btnLink, btnClassType, isHideBookNowButton } = props;

  return (
    <div className='banner'>
      <div className='banner__bg'>
        <div className='banner__bg__overlay'></div>
        <ResponsiveImage
          imageMobile={srcImageMobile}
          srcSet={[{ mw: 768, image: srcImage }]}
          attributes={{
            className: 'banner__bg__image'
          }}
        />
      </div>
      <div className='banner__content'>
        {title ?
          (
            <Text field={title} className='banner__content__title' tag='h2' />
          )
          :
          (<></>)
        }
        {description ?
          (
            <RichText field={description} className='banner__content__desc' />
          )
          :
          (<></>)
        }
        {btnLink && !isHideBookNowButton ?
          (
            <Link field={{ href: btnLink }} className={`banner__content__link btn ${btnClassType}`}>
              <Text field={btnText} />
            </Link>
          )
          :
          (<></>)
        }
      </div>
    </div>
  );
};

BannerWithText.propTypes = {
  srcImage: object,
  srcImageMobile: object,
  title: object,
  description: object,
  btnText: object,
  btnLink: string,
  btnClassType: string,
  isHideBookNowButton: bool
};

export default BannerWithText;
