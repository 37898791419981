import { number, object } from 'prop-types';
import React, { Fragment } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

const WellBeingProductItem = ({ data, dataFields, indexGA4 }) => {
  const [buyNowLink, setBuyNowLink] = useState(deepCopy(data['Buy Now Link']));

  const isGA4 = Global.getIsGA4();
  const itemLstName = dataFields?.['Title']?.value;
  const categoryName = data?.CategoryName;

  useEffect(() => {
    let tempLink = deepCopy(buyNowLink);

    tempLink.value.href = `${data['Buy Now Link'].value.href}&LstName=${itemLstName}`;

    setBuyNowLink(tempLink);
  }, []);

  const handleGa4SelectItem = (action) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      let dataConvert = [];
  
      dataConvert.push(data);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(indexGA4, dataConvert, '', pdpPromoCode, '', '',  itemLstName)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }
  };

  return (
    <Fragment>
      <div className='well-being-product-item__container'>
        <div className='well-being-product-item__thumb'>
          <a href={`${data['Url']}?IndexGA4=${indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}`} onClick={() => handleGa4SelectItem('Image')}>
            <img className='well-being-product-item__thumb__img swiper-lazy animated' data-src={data['Image']} alt={data['ProductName'] ? data['ProductName'] : 'well being product item thumb'} />
            <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
          </a>
        </div>
        <h3 className='well-being-product-item__name'>
          <a className='well-being-product-item__name__link' href={`${data['Url']}?IndexGA4=${indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}`} onClick={() => handleGa4SelectItem('Image')}>
            {data['ProductName']}
          </a>
        </h3>
        {data.Price.SellingPriceWithCurrency === data.Price.ListPriceWithCurrency ? (
          <div className='well-being-product-item__selling-price'>{data.Price.SellingPriceWithCurrency}</div>
        ) : (
          <Fragment>
            <div className='well-being-product-item__selling-price'>{data.Price.SellingPriceWithCurrency}</div>
            <div className='well-being-product-item__list-price'>{data.Price.ListPriceWithCurrency}</div>
          </Fragment>
        )}
      </div>
      <div className='well-being-product-item__button text-center'>
        <BuyButtonComponent
          productData={{
            ...data,
            productId: `${data.Id}|${data.RepresentativeVariantId}`
          }}
        >
          <Link className='btn btn-primary' field={buyNowLink} onClick={() => handleGa4SelectItem('Buy Now')}>
            <span>{data.Stock?.PurchasableLabel}</span>
          </Link>
        </BuyButtonComponent>
      </div>
    </Fragment>
  );
};

WellBeingProductItem.propTypes = {
  data: object,
  dataFields: object,
  indexGA4: number
};

export default WellBeingProductItem;
