import './ProductDetailSpec.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRef } from 'react';
import { Accordion } from 'react-bootstrap';

import ResponsiveImage from '@components/ResponsiveImage';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import ProductDetaiSpecModel from './models/ProductDetaiSpecModel';

const ProductDetailSpec = ({ params, fields }) => {
  const componentId = (params && params?.['Component Id']) || '';
  const [activeKey, setActiveKey] = useState(1);
  const [dataSources, setDataSources] = useState();
  const refAccordion = useRef();

  useEffect(() => {
    setDataSources(new ProductDetaiSpecModel().getData(fields || {}));
    const dimensionImage = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Chair Dimension Image'];
    if(!dimensionImage?.value?.src) {
      setActiveKey(2);
    }
  }, []);

  const onToogleAccordionHandler = (index) => {
    index === activeKey ? setActiveKey(-1) : setActiveKey(index);
  };

  return dataSources ?  (
    <div className='product-specification' id={componentId}>
      <div className='container'>
        <h2 className='experience-title product-specification__heading'>
          <Text field={dataSources['Title']}/>
        </h2>
        <div className='product-specification__info-list'>
          <Accordion activeKey={activeKey} ref={refAccordion}>
            {SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Chair Dimension Image']?.value.src ?
              <div className='product-specification__info-list__item' data-show={activeKey === 1 ? 'true' : 'false'}>
                <div className='product-specification__collapse'>
                  <div className='product-specification__collapse__title' onClick={() => onToogleAccordionHandler(1)}>
                    <Text tag='span' className='product-specification__collapse__title__text' field={SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Chair Dimension Title Text']}/>
                    {activeKey !==  1 ? (
                      <i className='product-specification__collapse__title__icon icon--nomal icon-add'></i>
                    ) : (
                      <i className='product-specification__collapse__title__icon icon--nomal icon-remove'></i>
                    )}
                  </div>
                  <div className='model-spec__wrap'>
                    <Accordion.Collapse eventKey={1}>
                      <div className='product-specification__collapse__content'>
                        <p className='product-specification__collapse__content__measurement'>
                          <Text field={SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Measurement Text']}/>
                        </p>
                        <div className='product-specification__image-list'>
                          <div className='product-specification__image-list__item'>
                            <ResponsiveImage
                              imageMobile={SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Chair Dimension Image Mobile']}
                              srcSet={[
                                { 
                                  mw: 768, 
                                  image: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Chair Dimension Image']
                                }
                              ]}
                            />
                          </div>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </div>
              </div> : ''}
            {dataSources['Product Specifications'].Sections.length ? dataSources['Product Specifications'].Sections.map((specItem, index) => (
              <div className='product-specification__info-list__item' key={index}>
                <div className='product-specification__collapse'>
                  <div className='product-specification__collapse__title' onClick={() => onToogleAccordionHandler(index + 2)}>
                    <span className='product-specification__collapse__title__text'>{specItem.Name}</span>                    
                    {activeKey !==  index + 2 ? (
                      <i className='product-specification__collapse__title__icon icon--nomal icon-add'></i>
                    ) : (
                      <i className='product-specification__collapse__title__icon icon--nomal icon-remove'></i>
                    )}
                  </div>
                  <div className='model-spec__wrap'>
                    <Accordion.Collapse eventKey={index + 2}>
                      <div className='product-specification__collapse__content'>
                        <div className='product-specification__collapse__content__list'>
                          <div className='row'>
                            {specItem.Attributes?.length
                              ? specItem.Attributes.map((attributeItem, attributeItemIdx) => (
                                <dl className='info-content__item col-12 col-sm-6' key={attributeItemIdx}>
                                  <dt className='info-content__item__label'>{attributeItem.Name}</dt>
                                  <dd className='info-content__item__value'>{attributeItem.Value}</dd>
                                </dl>
                              )) : ''}
                          </div>
                        </div>
                      </div>
                    </Accordion.Collapse>
                  </div>
                </div>
              </div>
            )) : ''}
          </Accordion>
        </div>
      </div>
    </div>
  ) : '';
};

ProductDetailSpec.propTypes = {
  params: PropTypes.any,
  fields: PropTypes.object
};

export default ProductDetailSpec;
