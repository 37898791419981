import './WarrantyRegistrationProcess.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import { useCurrentWarrantyRegistrationStep } from '../hooks/useCurrentWarrantyRegistrationStep';
import { LOGIN_REGISTER_STEP, SELECT_PRODUCT_STEP } from '../WarrantyGeneral';
import WarrantyRegistrationProcessModel from './Model/WarrantyRegistrationProcessModel';

const WarrantyRegistrationProcess = ({ fields }) => {
  const currentStep = useCurrentWarrantyRegistrationStep();
  const [warrantyRegistrationProcessFields, setWarrantyRegistrationProcessFields] = useState(null);

  useEffect(() => {
    const warrantyRegistrationProcessModel = new WarrantyRegistrationProcessModel();

    setWarrantyRegistrationProcessFields(warrantyRegistrationProcessModel.getData(deepCopy(fields || {})));
  }, []);

  const iconList = useMemo(() => {
    const iconArr = [];
    const selectProductIcon = warrantyRegistrationProcessFields?.['Hightlighted Select Product Image'];
    const signInIcon = warrantyRegistrationProcessFields?.['Sign In Image'];
    if (selectProductIcon) {
      iconArr.push({
        icon: { ...selectProductIcon },
        label: { ...warrantyRegistrationProcessFields?.['Select Product Label'] },
        stepKey: SELECT_PRODUCT_STEP
      });
    }
    if (signInIcon) {
      iconArr.push({
        icon: { ...signInIcon },
        label: { ...warrantyRegistrationProcessFields?.['Sign In Label'] },
        stepKey: LOGIN_REGISTER_STEP
      });
    }

    return iconArr;
  }, [warrantyRegistrationProcessFields]);

  const conditionFocusItem = useMemo(() => {
    return iconList.findIndex((item) => item.stepKey === currentStep);
  }, [iconList, currentStep]);

  return (
    warrantyRegistrationProcessFields && (
      <div className='product-registration-step'>
        <div className='product-registration-step__icon'>
          {iconList.map((item, index) => (
            <div
              key={item.stepKey}
              className={`product-registration-step__icon__item ${
                currentStep === item.stepKey ? 'active' : index < conditionFocusItem ? 'focus' : ''
              }`}
            >
              <div className='icon-wrapper'>
                <Image className='icon-img' field={item.icon} alt={item.icon?.value?.alt} />
              </div>
              <Text tag='span' field={item.label} className='icon-text' />
            </div>
          ))}
        </div>
        {/*<Text tag='p' field={warrantyRegistrationProcessFields['Description']} className='product-registration-step__description' />*/}
      </div>
    )
  );
};

WarrantyRegistrationProcess.propTypes = {
  fields: Proptypes.any
};

export default WarrantyRegistrationProcess;
