import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsActiveQACustomerLifestyle } from '@redux/actions/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import AccessItem from '../AccessItem';
import CustomeInfoPopup from '../CustomeInfoPopup';
import QAWarningPopup from '../QAWarningPopup';
import QACustomerLifestyleModel from './model/QACustomerLifestyleModel';

const QACustomerLifestyle = ({ fields, dataModel }) => {
  const [layoutData, setLayoutData] = useState(null);
  const clientTellingPortalQuickAccess = useSelector((state) => state.clientTellingPortalQuickAccess);
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId') || '';
  const dispatch = useDispatch();
  const [warningPopup, setWarningPopup] = useState(false);

  useEffect(() => {
    const model = new QACustomerLifestyleModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  const handleClickProductInterest = () => {
    if (cpCustomerId) {
      dispatch(setIsActiveQACustomerLifestyle(!clientTellingPortalQuickAccess.isActiveQACustomerLifestyle));
    } else {
      setWarningPopup(true);
    }
  };

  const closeAccessPopup = () => {
    dispatch(setIsActiveQACustomerLifestyle(false));
  };

  return layoutData ? (
    <>
      <li className={clientTellingPortalQuickAccess.isActiveQACustomerLifestyle ? 'active-access' : ''}>
        <AccessItem clickAccessItem={handleClickProductInterest} layoutData={<Image field={layoutData['Lifestyle Icon']} />} />
        <CustomeInfoPopup closeAccessPopup={closeAccessPopup} layoutData={layoutData} dataModel={dataModel} />
      </li>
      <QAWarningPopup warningPopup={warningPopup} setWarningPopup={() => setWarningPopup(false)} dataModel={dataModel} />
    </>
  ) : (
    <></>
  );
};

QACustomerLifestyle.propTypes = {
  fields: object,
  dataModel: object
};

export default QACustomerLifestyle;
