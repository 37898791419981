import { useCallback } from 'react';

const useRemoveDuplicateFunction = () => {
  return useCallback((data) => {
    const uniqueIds = [];

    return data.filter((item) => {
      const isDuplicate = uniqueIds.includes(item?.Id);

      if (!isDuplicate) {
        uniqueIds.push(item?.Id);

        return true;
      }

      return false;
    });
  }, []);
};

export default useRemoveDuplicateFunction;
