import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import { useProductWarrantyStatusData } from './hooks';
import WarrantyDetailModel from './models/WarrantyDetailModel';

const WarrantyDetail = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const productWarrantyStatusContent = useProductWarrantyStatusData(layoutData);

  useEffect(() => {
    const model = new WarrantyDetailModel();

    setLayoutData(model.getData({ ...fields } || {}));
  }, []);

  return (
    <div className='product-warranty__detail-widget border-top__0'>
      <div className='product-warranty__detail-header'>
        <h4 className='product-warranty__detail-header__text'>
          <Text field={layoutData?.['Title']} />
        </h4>
        <p className='product-warranty__detail-header__description'>
          <Text field={productWarrantyStatusContent.statusText} />
        </p>
      </div>
      <div className='product-warranty__detail-extend'>
        {productWarrantyStatusContent.buttonData.text ? (
          <Link className='btn btn-outline-CTA2' field={productWarrantyStatusContent.buttonData.link}>
            <Text field={productWarrantyStatusContent.buttonData.text} />
          </Link>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

WarrantyDetail.propTypes = {
  fields: any
};

export default WarrantyDetail;
