import AddressSettingModel from '@components/SlicingPage/MyAccount/MyAddress/models/AddressSettingModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import AddressPopupModel from './AddressPopupModel';

export default class AddressesWidgetModel {
  constructor() {
    this['Add New Address Text'] = new SitecoreTextModel();

    this['Add New Address Button Text'] = new SitecoreTextModel();

    this['Default Shipping Sddress Label'] = new SitecoreTextModel();

    this['Empty Address Text'] = new SitecoreTextModel();

    this['Manage Label'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['You Have Not Added An Address Label'] = new SitecoreTextModel();

    this['Manage Link'] = new SitecoreLinkModel();

    this['DataSoures Popup'] = new AddressPopupModel();

    this['Address Settings'] = new AddressSettingModel();

    this['Phone Number Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    this['DataSoures Popup'].getData(dataSource);
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add New Address Text')) {
      this['Add New Address Text'].getData(dataSource['Add New Address Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Phone Number Label')) {
      this['Phone Number Label'].getData(dataSource['Phone Number Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Settings')) {
      this['Address Settings'].getData(dataSource['Address Settings']?.fields || {}, true);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Default Shipping Sddress Label')) {
      this['Default Shipping Sddress Label'].getData(dataSource['Default Shipping Sddress Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Empty Address Text')) {
      this['Empty Address Text'].getData(dataSource['Empty Address Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Manage Label')) {
      this['Manage Label'].getData(dataSource['Manage Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'You Have Not Added An Address Label')) {
      this['You Have Not Added An Address Label'].getData(dataSource['You Have Not Added An Address Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Manage Link')) {
      this['Manage Link'].getData(dataSource['Manage Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add New Address Button Text')) {
      this['Add New Address Button Text'].getData(dataSource['Add New Address Button Text']);
    }

    return this;
  }
}
