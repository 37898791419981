import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import BannerWithText from '@components/BannerWithText/index';

import { SCHEDULE_APPOINTMENT_PARAM_NAME, SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE } from '../ScheduleAppointmentComponent/FormikStep/configs';
import BookExperienceComponentModel from './model/BookExperienceComponentModel';
import SelectProduct from './SelectProduct/index';

const BookExperienceComponent = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [productId, setProductId] = useState(null);
  const [linkBookExp, setLinkBookExp] = useState('');

  useEffect(() => {
    var model = new BookExperienceComponentModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    if (productId) {
      setLinkBookExp(
        `${layoutData?.['Button Book Now Link']?.value?.href}?${SCHEDULE_APPOINTMENT_PARAM_NAME}=${SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE}&productId=${productId}`
      );
    }
  }, [productId]);

  const isHideBookNowButton = useMemo(() => {
    return !layoutData?.['Products']?.length;
  }, [layoutData]);

  return layoutData ? (
    <div className='book-experience'>
      <BannerWithText
        srcImage={layoutData?.['Desktop Background']}
        srcImageMobile={layoutData?.['Mobile Background']}
        title={layoutData?.['Title']}
        description={layoutData?.['Description']}
        btnText={layoutData?.['Button Experience Now Text']}
        btnLink={'#select-product'}
        btnClassType={'btn-outline-white'}
        isHideBookNowButton={isHideBookNowButton}
      />
      {!isHideBookNowButton ? <SelectProduct layoutData={layoutData} productId={productId} setProductId={setProductId} /> : <></>}
    </div>
  ) : (
    <></>
  );
};

BookExperienceComponent.propTypes = {
  fields: object
};

export default BookExperienceComponent;
