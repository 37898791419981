import { UPDATE_CONTACT_FAILURE, UPDATE_CONTACT_START, UPDATE_CONTACT_SUCCESS } from './types';

export const updateContactStart = (payload) => ({
  type: UPDATE_CONTACT_START,
  payload
});

export const updateContactSuccess = (payload) => ({
  type: UPDATE_CONTACT_SUCCESS,
  payload
});

export const updateContactFailure = (payload) => ({
  type: UPDATE_CONTACT_FAILURE,
  payload
});
