import SitecoreTextModel from '@models/SitecoreTextModel';

export default class WellnessListingModel {
  constructor() {
    this['Filter By Text'] = new SitecoreTextModel();

    this['Hidden Text'] = new SitecoreTextModel();

    this['No Result Text'] = new SitecoreTextModel();

    this['Show unread articles on top'] = new SitecoreTextModel();

    this['Show product related articles on top'] = new SitecoreTextModel();

    this['Show latest articles on top'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter By Text')) {
      this['Filter By Text'].getData(dataSource['Filter By Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Hidden Text')) {
      this['Hidden Text'].getData(dataSource['Hidden Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'No Result Text')) {
      this['No Result Text'].getData(dataSource['No Result Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Show unread articles on top')) {
      this['Show unread articles on top'].getData(dataSource['Show unread articles on top']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Show product related articles on top')) {
      this['Show product related articles on top'].getData(dataSource['Show product related articles on top']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Show latest articles on top')) {
      this['Show latest articles on top'].getData(dataSource['Show latest articles on top']);
    }

    return this;
  }
}
