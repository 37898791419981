import produce from 'immer';

const initialState = {
  name: 'User State',
  authentication: null,
  logginError: null,
  loginSuccess: false,
  userDetail: [],
  isUserDetailLoading: false,
};

const usersReducer = (state = initialState, action) => 
  produce(state, draft => {
    switch (action.type) {
    case 'GET_USER_DETAIL_BY_ID':
      draft.productDetail = [];

      draft.isDetailLoading = true;
      break;
    }

    return draft;
  });


export default usersReducer;