import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import { Link,RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import SitecoreContextFactoryService from '../../../../services/sitecoreContextFactoryService';
import BookExperienceCta from '../../../CategoryLanding/ProductListing/ProductListingItem/BookExperienceCta';
import ProductCardPrice from '../../../ProductCardPrice';

const ProductSearchResultItem = ({ dataFromAPI, dataFromLayout, handleOpenPopupLogin }) => {
  const [buyNowLink] = useState(`${dataFromLayout['Buy Now Link'].Link.value.href}?productPageId=${dataFromAPI.Id || ''}`);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const isUserLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer.isLogin);

  const handleCheckDeposit = (event) => {
    if (!isUserLogin && dataFromAPI.IsDeposit) {
      event.preventDefault();
  
      handleOpenPopupLogin(buyNowLink);
    }
  };

  return (
    dataFromAPI &&
    dataFromLayout && (
      <article className='article article--horizontal product-item--horizontal animated-slow fadeIn delay-100'>
        <a href={dataFromAPI.Url} className='article__image'>
          <img alt='Image' src={dataFromAPI.Image} />
        </a>
        <div className='article__content'>
          <h3 className='article__content__title'>
            <a href={dataFromAPI.Url} className='article__content__title-link'>
              {dataFromAPI.ProductName}
            </a>
          </h3>
          {dataFromAPI?.IsDeposit ? (
            <span className='article__content__price--main'>{dataFromAPI?.Deposit?.DepositPriceWithCurrency}</span>
          ) : (
            <>
              <div className='article__content__price'>
                <ProductCardPrice
                  mainPriceClassName='article__content__price--main'
                  oldPriceClassName='article__content__price--old'
                  sellingPriceWithCurrency={dataFromAPI.Price.SellingPriceWithCurrency}
                  listPriceWithCurrency={dataFromAPI.Price.ListPriceWithCurrency}
                />
              </div>
            </>
          )}
          {/* <p className='article__content__description'>{dataFromAPI.Description}</p> */}
          <RichText className='article__content__description' field={{ value: dataFromAPI.Description }} />
          <a
            href={dataFromAPI.Url}
            className={`article__content__link btn-link${dataFromAPI.IsBundle ? ' bundle-link' : ''}`}
          >
            {dataFromLayout['Learn More Text'].value}
          </a>
          {dataFromAPI.BundleDealsButtonUrl ? (
            <a className='btn-link article__content__link' href={dataFromAPI.BundleDealsButtonUrl}>
              <span>{messageObj?.['LBL_BundleDealsButtonUrl']}</span>
            </a>
          ) : (
            <></>
          )}
          <div className='article__content__action'>
            {dataFromAPI?.IsBookExperience ? (
              dataFromLayout?.['Booking Experience Button Link']?.value?.href && dataFromLayout?.['Booking Experience Button Text']?.value ? (
                <Link
                  field={{ href: `${dataFromLayout?.['Booking Experience Button Link']?.value?.href}?id=${dataFromAPI?.Id}` }}
                  tag='a'
                  className='btn btn-outline-primary'
                >
                  <Text field={dataFromLayout?.['Booking Experience Button Text']} />
                </Link>
              ) : (
                <></>
              )
            ) : (
              <></>
            )}
            <BuyButtonComponent
              productData={{
                ...dataFromAPI,
                productId: `${dataFromAPI.ProductId}|${dataFromAPI.RepresentativeVariantId}`
              }}
            >
              {dataFromAPI.IsBundle ? (
                <a className='btn btn-outline-CTA2' href={dataFromAPI.Url}>
                  {messageObj?.['LBL-LearnMore']}
                </a>
              ) : (
                <a href={buyNowLink} onClick={handleCheckDeposit} className='btn btn-primary'>
                  {dataFromAPI?.IsDeposit ? <Text field={dataFromLayout?.['Deposit Button Text']} /> : dataFromAPI.Stock?.PurchasableLabel}
                </a>
              )}
            </BuyButtonComponent>
          </div>
        </div>
      </article>
    )
  );
};

ProductSearchResultItem.propTypes = {
  dataFromAPI: PropTypes.any,
  dataFromLayout: PropTypes.any,
  handleOpenPopupLogin: PropTypes.func,
};

export default ProductSearchResultItem;
