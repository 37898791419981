import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import { SG_SITE_NAME } from '../../constant';
import { useIsAtSiteName } from '../useIsAtSiteName';

export const useGetMemberUsualText = () => {
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const isAtSGCountry = useIsAtSiteName(SG_SITE_NAME);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const isAllowShowText = useMemo(() => isAtSGCountry && !isClientTelingUser, [isAtSGCountry, isClientTelingUser]);

  return useMemo(() => (
    {
      memberText: isAllowShowText && settingGlobal?.['MemberPriceText'] ? `${settingGlobal?.['MemberPriceText']} ` : '',
      usualText: isAllowShowText && settingGlobal?.['UsualPriceText'] ? `${settingGlobal?.['UsualPriceText']} ` : ''
    }
  ), [settingGlobal, isAllowShowText]);
};