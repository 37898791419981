import produce from 'immer';

import {
  GET_CP_PRODUCT_INTEREST_DATA,
  GET_CP_PRODUCT_INTEREST_DATA_FAILED,
  GET_CP_PRODUCT_INTEREST_DATA_SUCCESS
} from '../../actions/cpProductInterestData/cpProductInterestDataTypes';

const initialState = {
  isLoading: false,
  cpProductInterestData: [],
  cPError: null
};

const cpProductInterestDataReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_CP_PRODUCT_INTEREST_DATA:
      draft.isLoading = true;
      break;

    case GET_CP_PRODUCT_INTEREST_DATA_SUCCESS:
      draft.isLoading = false;

      draft.cpProductInterestData = action.payload;
      break;

    case GET_CP_PRODUCT_INTEREST_DATA_FAILED:
      draft.isLoading = false;

      draft.cPError = action.payload;
      break;
    }

    return draft;
  });

export default cpProductInterestDataReducer;
