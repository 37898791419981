import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const BackrestMassageUnit = ({ dataSources, isViewTitle }) => {
  return !isViewTitle ? (
    <div className='product-specification__collapse__content'>
      <div className='product-specification__collapse__content__list'>
        <div className='row'>
          {dataSources['Backrest Massage Unit']['Kneading Speed Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Backrest Massage Unit']['Kneading Speed Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Backrest Massage Unit']['Kneading Speed Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Backrest Massage Unit']['Tapping Speed Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Backrest Massage Unit']['Tapping Speed Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Backrest Massage Unit']['Tapping Speed Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Backrest Massage Unit']['Rolling Width Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Backrest Massage Unit']['Rolling Width Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Backrest Massage Unit']['Rolling Width Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Backrest Massage Unit']['Warm air Temperature Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Backrest Massage Unit']['Warm air Temperature Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Backrest Massage Unit']['Warm air Temperature Value']} />
            </dd>
          </dl> : ''}
        </div>
      </div>
    </div>
  ) : <Text field={dataSources['Backrest Massage Unit']['Backrest Massage Unit Title Text']}/>;
};

BackrestMassageUnit.propTypes = {
  dataSources: PropTypes.object,
  isViewTitle: PropTypes.bool
};

export default BackrestMassageUnit;
