import React, { useMemo } from 'react';

const useAppointmentListingContext = (layoutData) => {
  const dataTable = useMemo(() => {
    if (layoutData) {
      return {
        listTabs: [
          {
            label: layoutData?.['New Status Label']?.value,
            slug: function () {
              return this?.label?.replaceAll(' ', '-').toLowerCase();
            },
            status: '0'
          },
          {
            label: layoutData?.['Never Pick Up Status Label']?.value,
            slug: function () {
              return this?.label?.replaceAll(' ', '-').toLowerCase();
            },
            status: '4'
          },
          {
            label: layoutData?.['Confirmed Status Label']?.value,
            slug: function () {
              return this?.label?.replaceAll(' ', '-').toLowerCase();
            },
            status: '5'
          },
          {
            label: layoutData?.['Reschedule Status Label']?.value,
            slug: function () {
              return this?.label?.replaceAll(' ', '-').toLowerCase();
            },
            status: '2'
          },
          {
            label: layoutData?.['Trial Complete Status Label']?.value,
            slug: function () {
              return this?.label?.replaceAll(' ', '-').toLowerCase();
            },
            status: '3'
          },
          {
            label: `${layoutData?.['Lost Status Label']?.value}`,
            slug: function () {
              return this?.label?.replaceAll(' ', '-').toLowerCase();
            },
            status: '1'
          },
          {
            label: layoutData?.['All Filter Label']?.value,
            slug: function () {
              return this?.label?.replaceAll(' ', '-').toLowerCase();
            }
          }
        ],
        dataTableColumns : [
          {
            key: 'TimeSlotStr',
            label: layoutData?.['Time Slot Label']?.value
          },
          {
            key: 'SubmissionDate',
            label: layoutData?.['Submission Date Lable']?.value,
          },
          {
            key: 'CustomerName',
            label: layoutData?.['Name Label']?.value,
          },
          {
            key: 'CustomerMobileNumber',
            label: layoutData?.['Mobile Label']?.value,
          },
          {
            key: 'CampaignName',
            label: layoutData?.['Campaign Name Label']?.value,
          },
          {
            key: 'StoreName',
            label: layoutData?.['Store Name Label']?.value,
          },
          {
            key: 'LeadStatus',
            label: layoutData?.['Lead Status Label']?.value,
            render: (item) => {
              const leadStatusClassName = item.LeadStatus === 1
                ? 'appointments-listing__content-status__lost'
                : item.LeadStatus === 3
                  ? 'appointments-listing__content-status__trial'
                  : 'appointments-listing__content-status';
              
              let leadStatusName = '';
              
              switch (item.LeadStatus) {
              case 0:
                leadStatusName = `${layoutData?.['New Status Label']?.value}`;
                break;
    
              case 1: 
                leadStatusName = `${layoutData?.['Lost Status Label']?.value}`;
                break;
    
              case 2: 
                leadStatusName = `${layoutData?.['Reschedule Status Label']?.value}`;
                break;
    
              case 3: 
                leadStatusName = `${layoutData?.['Trial Complete Status Label']?.value}`;
                break;
    
              case 4: 
                leadStatusName = `${layoutData?.['Never Pick Up Status Label']?.value}`;
                break;
    
              case 5: 
                leadStatusName = `${layoutData?.['Confirmed Status Label']?.value}`;
                break;
              }
    
              return <span className={leadStatusClassName}>{leadStatusName}</span>;
            }
          },
          {
            key: 'Notes',
            label: layoutData?.['Notes Label']?.value,
          }
        ] 
      };
    }
  }, [layoutData]);

  return dataTable;
};

export default useAppointmentListingContext;