import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { SITE_HK_CHINESE_LANGUAGE, SITE_TW_CHINESE_LANGUAGE } from '@utils/constant';

const CPStoreSelector = ({ fields, selectStoreLabel, handleSelectStore }) => {
  const ALL_STORE_ID_VALUE = 'AllStores';
  //INFO: get cpCustomerId to know is runing startDemo
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId') || '';
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language') || '';

  const storesList = useMemo(() => {
    const defaultStoresList = fields?.Stores || [];
    const languagesIgnoreSort = [SITE_HK_CHINESE_LANGUAGE, SITE_TW_CHINESE_LANGUAGE];
    if (defaultStoresList.length && !languagesIgnoreSort.includes(currentLanguage)) {
      return defaultStoresList.sort((a, b) => {
        if (a.StoreId === ALL_STORE_ID_VALUE || b.StoreId === ALL_STORE_ID_VALUE) return 0;
        if (a.StoreName < b.StoreName) return -1;
        if (a.StoreName > b.StoreName) return 1;

        return 0;
      });
    }

    return defaultStoresList;
  }, [fields, currentLanguage]);

  return storesList.length ? (
    <div className='group-name'>
      <Text className='popup-sub-title' tag='h3' field={selectStoreLabel} />
      <ul className={`list-store${cpCustomerId ? ' list-store__disable' : ''}`}>
        {
          storesList.map((store, index) => (
            <li className='group-name__link' key={store.StoreId || index} onClick={() => handleSelectStore(store)}>
              {store.StoreName}
            </li>
          ))
        }
      </ul>
    </div>
  ) : '';
};

CPStoreSelector.propTypes = {
  fields: PropTypes.any,
  selectStoreLabel: PropTypes.any,
  handleSelectStore: PropTypes.func
};

export default CPStoreSelector;