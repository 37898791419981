import produce from 'immer';

import {
  READ_SINGLE_NOTIFICATION_FAILURE,
  READ_SINGLE_NOTIFICATION_START,
  READ_SINGLE_NOTIFICATION_SUCCESS
} from '@redux/actions/cpNotificationPopup/readSingleNotification/types';

const initialStates = {
  isLoading: false,
  error: null,
  readNotificationResponse: null
};

const readSingleNotificationReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case READ_SINGLE_NOTIFICATION_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case READ_SINGLE_NOTIFICATION_SUCCESS:
      draft.isLoading = false;

      draft.readNotificationResponse = action.payload;
      break;

    case READ_SINGLE_NOTIFICATION_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default readSingleNotificationReducer;
