import './CPConnectTask.scss';

import { object } from 'prop-types';
import React, { useMemo } from 'react';

import { Image, Link } from '@sitecore-jss/sitecore-jss-react';

import CPConnectTaskModel from './CPConnectTaskModel';

const CPConnectTask = ({ fields }) => {
  const layoutData = useMemo(() => new CPConnectTaskModel().getData(fields), [fields]);

  return layoutData && layoutData['Connect Task List Link']?.value?.href && layoutData['Connect Task Icon']?.value?.src
    ? (
      <div className='cp-header__col cp-connect-task'>
        <Link
          title={layoutData['Connect Task List Label']?.value || ''}
          field={layoutData['Connect Task List Link']}
          className='head-icon cp-connect-task__link'
        >
          <Image field={layoutData['Connect Task Icon']} />
        </Link>
      </div>
    )
    : (
      <></>
    );
};

CPConnectTask.propTypes = {
  fields: object
};

export default CPConnectTask;