import Proptypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import NotifyMePopup from '@components/Product/VariantDetail/ProductDetailHeader/NotifyMePopup';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import { getNotifyStatusProduct } from '@redux/actions/product';
import { resetMessagePopupNotify } from '@redux/actions/product';


const mapStateToProps = (state) => ({
  variantActive: state.productReducer.productDetailHeaderReducer.productVariantCurrent,
  isNotifySuccess: state.productReducer.notifyProductStatusReducer.isNotifySuccess || {}
});

const connector = connect(mapStateToProps);

export const DefautPopup = (props) => {
  const [isShowNotifyMe, setShow] = useState(false);

  const dispatch = useDispatch();

  const { isNotifySuccess, isPopupShow, setIsPopupShow, popUpState } = props;

  useEffect(() => {
    setShow(isPopupShow);
  }, [isPopupShow]);

  const onClosePopupNotifyHandler = () => {
    setShow(false);

    setIsPopupShow(false);

    dispatch(resetMessagePopupNotify());
  };

  const onSubmitNotifyHandler = (emailSubmit) => {
    dispatch(
      getNotifyStatusProduct({ payload: emailSubmit })
    );
  };

  const message = useMemo(() => {
    if (Object.keys(isNotifySuccess).length) {
      if (isNotifySuccess.status) {
        return <p className='notify-success'>{<Message messageCode='LBL-NotifyMeSuccessMessage'/>}</p>;
      } else {
        return <p className='notify-faild'>{<Message messageCode='LBL-NotifyMeFailMessage'/>}</p>;
      }
    }
    
    return null;
  }, [isNotifySuccess]);

  return (
    <ModalComponent
      isShow={isShowNotifyMe}
      titlePopup={<span><Message messageCode='LBL-NotifyMe' /></span>}
      onCloseHandler={onClosePopupNotifyHandler}
    >
      <NotifyMePopup inputData={popUpState} notifyMeEvt={onSubmitNotifyHandler}>

        {message}

      </NotifyMePopup>
    </ModalComponent>
  );
};


DefautPopup.propTypes = {
  fields: Proptypes.object,
  popUpState: Proptypes.any,
  variantActive: Proptypes.object,
  isNotifySuccess: Proptypes.any,
  isPopupShow: Proptypes.bool,
  setIsPopupShow: Proptypes.func
};

export default function withPopup(Component, PopUpComponent = connector(DefautPopup)) {
  const WithPopup = (props) => {
    const [isPopupShow, setIsPopupShow] = useState(false);
    const [popUpState, setpopUpState] = useState(null);

    return (
      <>
        <Component {...props} {...{ isPopupShow, setIsPopupShow, popUpState, setpopUpState }} />
        <PopUpComponent {...props} {...{ isPopupShow, setIsPopupShow, popUpState, setpopUpState }} />
      </>
    );
  };

  return WithPopup;
}