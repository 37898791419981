import './StoreHeader.scss';

import React, {useEffect,useState} from 'react';

import TextModel from '@models/TextModel';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

const StoreHeader = (props) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    setInputData({
      title: new TextModel().getData({value: SitecoreContextFactoryService.getValueContextRouteItem('fields').Name.value}),
      description: new TextModel().getData({value: SitecoreContextFactoryService.getValueContextRouteItem('fields').Description.value})
    });
  }, []);

  return inputData ? (
    <div className='store-header animated-slow fadeIn delay-100'>
      <div className='container'>
        <div className='store-header__heading'>
          <h2 className='experience-title store-header__heading__title'>{inputData.title.value}</h2>
          <p className='store-header__heading__desc'>{inputData.description.value}</p>
        </div>
      </div>
    </div>
  ) : (
    <div className='store-header skeleton__item'>
      <div className='container'>
        <div className='store-header__heading'>
          <h2 className='experience-title store-header__heading__title'><span className='text-line-heading-center w-50'></span></h2>
          <p className='store-header__heading__desc'><span className='text-line-center w-80'></span></p>
        </div>
      </div>
    </div>
  );
};

export default StoreHeader;
