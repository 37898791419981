import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LIST_PRODUCT_FUNCTION, PRODUCT_SUMMARY_KEY } from '../ProductFunctionsDetail';
import useMapGeneralFunctionData from './useMapGeneralFunctionData';
import useMapListProductFunctionData from './useMapListProductFunctionData';

const useMapProductCompareData = () => {
  const { productCompareData } = useSelector((state) => state.compareProductReducer);
  const mappedGeneralFunctionData = useMapGeneralFunctionData();
  const mappedListProductFunctionData = useMapListProductFunctionData();

  return useCallback(() => {
    const productCompareDataTemp = [...productCompareData];

    for (const productCompareItem of productCompareDataTemp) {
      const indexOfProductCompareItem = productCompareDataTemp.indexOf(productCompareItem);
      const productInfoDetail = productCompareItem?.productInfoDetail;

      if (productInfoDetail) {
        for (const key of Object.keys(productInfoDetail)) {
          if (key !== PRODUCT_SUMMARY_KEY) {
            const functionData = {
              functionLabel: key,
              productFunction: productInfoDetail[key]
            };

            productCompareDataTemp[indexOfProductCompareItem] = {
              ...productCompareDataTemp[indexOfProductCompareItem],
              productInfoDetail: {
                ...productCompareDataTemp[indexOfProductCompareItem].productInfoDetail,
                [key]: key !== LIST_PRODUCT_FUNCTION ? mappedGeneralFunctionData(functionData) : mappedListProductFunctionData(functionData)
              }
            };
          }
        }
      }
    }

    return productCompareDataTemp;
  }, [productCompareData]);
};

export default useMapProductCompareData;
