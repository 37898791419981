import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CountryModel {
  constructor() {
    this['Name'] = new SitecoreTextModel();

    this['Country Code'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this['Name'].getData(dataSource['Name']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Country Code')) {
      this['Country Code'].getData(dataSource['Country Code']);
    }

    return this;
  }
}
