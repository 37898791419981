import { bool, func } from 'prop-types';
import React, { useCallback } from 'react';

import { useScheduleAppointmentContext } from '@components/BookingExperience/ScheduleAppointmentComponent/hooks/useScheduleAppointmentContext.js';
import ModalComponent from '@components/Share/ModalComponent';
import StoreLocatorFilterPopupContent from '@components/Share/StoreLocator/StoreLocatorContent/StoreLocatorBar/StoreLocatorFilterIcon/StoreLocatorFilterPopupContent';

import { useFilterStoreBarContext } from '../index';

const FilterPopup = ({ showFilter, onCloseFilterPopup }) => {
  const { layoutData } = useScheduleAppointmentContext();
  const { onClearFilterArea, onFilter } = useFilterStoreBarContext();

  const handleSubmitFilter = useCallback(
    (params) => {
      if (params) {
        if (onFilter) onFilter(params);
      } else {
        if (onClearFilterArea) onClearFilterArea();
      }
      if (onCloseFilterPopup) onCloseFilterPopup();
    },
    [onCloseFilterPopup, onClearFilterArea]
  );

  return (
    <ModalComponent
      isShow={showFilter}
      titlePopup={layoutData?.['Filter Stores Label']?.value}
      onCloseHandler={onCloseFilterPopup}
      cssClass='search-filter-bar__filter-popup'
    >
      <div className='address-component'>
        <StoreLocatorFilterPopupContent dataFields={layoutData} setSubmitAddressEvt={handleSubmitFilter} />
      </div>
    </ModalComponent>
  );
};

FilterPopup.propTypes = {
  showFilter: bool,
  onCloseFilterPopup: func
};

export default FilterPopup;
