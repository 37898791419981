import PropTypes from 'prop-types';
import React from 'react';

import FormFieldError from '../../FormFieldError';

const CustomRangeRadioBoxList = (props) => {
  const { field, value, onChange } = props;
  const startText = field.model.startText;
  const endText = field.model.endText;


  const onSelect = (field, item, callback) => {
    if(item.value == value) return;

    callback(field.valueField.name, item.value, true, []);
  };

  return (
    <>
      <div className='osim-range-radiobox'>
        <div className='osim-ratebar'>
          <div className='satisfied'>
            <p>
              {startText}
            </p>
          </div>
          <div className='best'>
            <p>
              {endText}
            </p>
          </div>
        </div>
        <ul className='rate-box'>
          {field.model.items.map((item) => (
            <li className={item.value == value ? 'active' : ''} onClick={() => onSelect(field, item, onChange)} key={item.itemId}>{item.text}</li>
          ))}
        </ul>
        <FormFieldError {...props} />
      </div>
    </>
  );
};

CustomRangeRadioBoxList.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tracker: PropTypes.any,
  errors: PropTypes.any,
  children: PropTypes.any
};

export default CustomRangeRadioBoxList;
