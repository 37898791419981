import { combineEpics } from 'redux-observable';

import { trackingEmailGoalEpic } from './emailTrackingGoal';
import { mediaTrackingGoalEpic } from './mediaTrackingGoal';
import { productTrackingGoalEpic } from './productTrakingGoal';

const trackingGoal = combineEpics(
  mediaTrackingGoalEpic,
  productTrackingGoalEpic,
  trackingEmailGoalEpic
);

export default trackingGoal;