import './CheckBox.scss';

import React from 'react';

const CheckBox = () => {
  return (
    <label className='checkbox-custom'>
      <input className='checkbox-custom__input' type='checkbox' />
      <span className='checkmark'></span>
    </label>
  );
};

export default CheckBox;
