import { combineEpics } from 'redux-observable';

import { getCrmCustomerEpic } from './getCrmCustomer';
import { getPosCustomerIdEpic } from './getPosCustomerId';
import { myRewardWidgetInfoEpic } from './myRewardWidgetInfo';

const loyaltyEpic = combineEpics(
  myRewardWidgetInfoEpic,
  getCrmCustomerEpic,
  getPosCustomerIdEpic
);

export default loyaltyEpic;
