import PropTypes from 'prop-types';
import React from 'react';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';

const FeaturedTilesItem = ({ data }) => {
  return (
    data && (
      <div className='featured-tiles__content'>
        <div className='featured-tiles__content__image'>
          <Link field={data.fields.Link} className='featured-tiles__content__image__link' aria-label='featured-tiles content image link'>
            <Image media={data.fields.Image} className='featured-tiles__content__image__item' />
          </Link>
        </div>
        <div className='featured-tiles__content__text'>
          <h3 className='featured-tiles__content__title'>
            <Link field={data.fields.Link} className='featured-tiles__link'>
              <Text field={data.fields.Title} tag='span' />
            </Link>
          </h3>
          <div className='featured-tiles__content__info'>
            <p className='featured-tiles__content__info__sub-info'>
              <Text field={data.fields['Reading Duration']} tag='span' />
            </p>
            <Text tag='p' className='featured-tiles__content__info__sub-info' field={data.fields.Updated}/>
          </div>
        </div>
      </div>
    )
  );
};

FeaturedTilesItem.propTypes = {
  data: PropTypes.object
};

export default FeaturedTilesItem;
