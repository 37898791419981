import { any } from 'prop-types';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { connect } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import {
  useGoTrackEmarsys,
  useTrackingAddToCartEvent,
  useTrackingCartEvent,
  useTrackingCategoryEvent,
  useTrackingPurchaseEvent,
  useTrackingSetEmailEvent
} from './hooks';
import EmarsysScriptModel from './models/EmarsysScriptModel';
import { checkScriptExist, LOAD_EMARSYS_SCRIPT_SUCCESS } from './utils';

const THANK_YOU_PAGE_NAME = 'thankyou';
const EMARSYS_TRACKING_ORDER_NUMBER = 'emarsys-order-number';

const EmarsysScript = ({ fields, currentCart, userInfoReducer, orderNumber, cartLinesAfterPurchase }) => {
  const trackingSetEmailEvent = useTrackingSetEmailEvent();
  const trackingCategoryEvent = useTrackingCategoryEvent();
  const trackingCartEvent = useTrackingCartEvent();
  const pushEmarsysEvent = useGoTrackEmarsys();
  const trackingViewEvent = useTrackingAddToCartEvent();
  const trackingPurchaseEvent = useTrackingPurchaseEvent();
  const [layoutData, setLayoutData] = useState();
  const [isLoadedScript, setIsLoadedScript] = useState(false);

  const productIdCurrent = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.fields?.ProductId?.value;
  const isTurnOnEmarsysTracking = SitecoreContextFactoryService.getValueContextItem('Is Emarsys On');
  const pageName = SitecoreContextFactoryService.getValueContextRouteItem('name');
  const categoryNameCurrent = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Category Source']?.displayName;

  useEffect(() => {
    const model = new EmarsysScriptModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useLayoutEffect(() => {
    if (layoutData?.Script?.value && (isTurnOnEmarsysTracking === 'true' || isTurnOnEmarsysTracking === true)) {
      (async () => {
        const response = await checkScriptExist(layoutData?.Script?.value);
        if (response === LOAD_EMARSYS_SCRIPT_SUCCESS) setIsLoadedScript(true);
      })();
    }
  }, [layoutData, isTurnOnEmarsysTracking]);

  const handleTrackingRequireEvents = () => {
    trackingSetEmailEvent(userInfoReducer);

    trackingCartEvent(currentCart?.Lines);
  };

  // INFO: tracking setEmail and cart event
  useDidUpdateEffect(() => {
    if (
      isLoadedScript &&
      userInfoReducer &&
      currentCart?.Lines &&
      !productIdCurrent &&
      !categoryNameCurrent &&
      !orderNumber &&
      pageName !== THANK_YOU_PAGE_NAME
    ) {
      handleTrackingRequireEvents();

      pushEmarsysEvent();
    }
  }, [isLoadedScript, productIdCurrent, orderNumber, cartLinesAfterPurchase, userInfoReducer, currentCart, categoryNameCurrent]);

  // INFO: tracking category event
  useDidUpdateEffect(() => {
    if (isLoadedScript && categoryNameCurrent && currentCart && userInfoReducer) {
      handleTrackingRequireEvents();

      trackingCategoryEvent(categoryNameCurrent);

      pushEmarsysEvent();
    }
  }, [isLoadedScript, categoryNameCurrent, currentCart, userInfoReducer]);

  // INFO: tracking view product event
  useDidUpdateEffect(() => {
    if (isLoadedScript && productIdCurrent && currentCart && userInfoReducer) {
      if (currentCart && userInfoReducer) handleTrackingRequireEvents();

      trackingViewEvent(productIdCurrent);

      pushEmarsysEvent();
    }
  }, [isLoadedScript, productIdCurrent, currentCart, userInfoReducer]);

  // INFO: tracking purchase event
  useDidUpdateEffect(() => {
    if (isLoadedScript && orderNumber && cartLinesAfterPurchase && currentCart && userInfoReducer && pageName === THANK_YOU_PAGE_NAME) {
      const currentOrderNumber = window.localStorage.getItem(EMARSYS_TRACKING_ORDER_NUMBER);
      if (currentOrderNumber === orderNumber) window.localStorage.setItem(EMARSYS_TRACKING_ORDER_NUMBER, orderNumber);
      if (!currentOrderNumber || currentOrderNumber !== orderNumber) {
        window.localStorage.setItem(EMARSYS_TRACKING_ORDER_NUMBER, orderNumber);

        handleTrackingRequireEvents();

        trackingPurchaseEvent(orderNumber, cartLinesAfterPurchase);

        pushEmarsysEvent();
      }
    }
  }, [isLoadedScript, orderNumber, cartLinesAfterPurchase, currentCart, userInfoReducer, pageName]);

  return <div></div>;
};

EmarsysScript.propTypes = {
  fields: any,
  currentCart: any,
  userInfoReducer: any,
  orderNumber: any,
  cartLinesAfterPurchase: any
};

const mapStateToProps = (state) => {
  return {
    currentCart: state.productReducer.cartDetailReducer.currentCart,
    userInfoReducer: state.singleSignOnReducer.userInfoReducer,
    orderNumber: state.checkoutProcesstReducer.paymentDetailReducer.paymentDetail?.OrderNumber,
    cartLinesAfterPurchase: state.myOrderReducer.orderByIdReducer?.currentCart?.Lines
  };
};

export default connect(mapStateToProps)(EmarsysScript);
