import * as types from './types';

// action get time slot can't book of date
export const getTotalBookedTimeSlotOfDate = (payload) => ({
  type: types.GET_TOTAL_BOOKED_TIMESLOT_OF_DATE,
  payload
});

export const getTotalBookedTimeSlotOfDateSuccess = (payload) => ({
  type: types.GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_SUCCESS,
  payload
});

export const getTotalBookedTimeSlotOfDateFailed = (payload) => ({
  type: types.GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_FAILED,
  payload
});

// action delete timeslot result when click select store
export const deleteTimeSlotOfDateResponse = () => ({
  type: types.DELETE_RESULT_TOTAL_TIMESLOT_OF_DATE,
});

// action get date can't book of store
export const getTotalBookedDateOfStore = (payload) => ({
  type: types.GET_TOTAL_BOOKED_DATE_OF_STORE,
  payload
});

export const getTotalBookedDateOfStoreSuccess = (payload) => ({
  type: types.GET_TOTAL_BOOKED_DATE_OF_STORE_SUCCESS,
  payload
});

export const getTotalBookedDateOfStoreFailed = (payload) => ({
  type: types.GET_TOTAL_BOOKED_DATE_OF_STORE_FAILED,
  payload
});