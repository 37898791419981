import { any, object } from 'prop-types';
import React from 'react';
import DatePicker from 'react-date-picker';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const DatePurchase = ({ label, value, ...props }) => {
  return (
    <div className={`warranty-registration__form-date__item ${value ? 'react-date-picker__active' : ''}`}>
      <Text tag='label' field={label} />
      <DatePicker {...props} value={value} format='dd MMM yyyy' dayPlaceholder='dd' monthPlaceholder='mm' yearPlaceholder='yyyy' viewMode='months' />
      {/*<DatePicker label={{ value: 'Date of purchase' }} setFieldValue={setDatePurchase} />*/}
    </div>
  );
};

DatePurchase.propTypes = {
  label: object,
  value: any
};

export default DatePurchase;
