export default class PoliciesItemApiModel {
  constructor() {
  
    this.Description = '';
  
    this.ItemId = '';
  
    this.ItemType = '';

    this.PageUrl = '/';
  
    this.PolicyGroup = '';
  
    this.Tags = [];
  
    this.Title = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description ? dataSource.Description : this.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId')) {
      this.ItemId = dataSource.ItemId ? dataSource.ItemId : this.ItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemType')) {
      this.ItemType = dataSource.ItemType ? dataSource.ItemType: this.ItemType;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PageUrl')) {
      this.PageUrl = dataSource.PageUrl ? dataSource.PageUrl: this.PageUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PolicyGroup')) {
      this.PolicyGroup = dataSource.PolicyGroup ? dataSource.PolicyGroup: this.PolicyGroup;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Tags')) {
      this.Tags = dataSource.Tags ? dataSource.Tags : this['Tags'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title = dataSource.Title ? dataSource.Title: this.Title;
    }
      
    return this;
  }
}
  