import * as couponTypes from '@redux/actions/coupon/couponTypes';

import {REMOVE_SO_COUPON_CODE_FAILED, REMOVE_SO_COUPON_CODE_START, REMOVE_SO_COUPON_CODE_SUCCESS} from './couponTypes';

export const getBrowseCouponList = (payload) => ({
  type: couponTypes.GET_BROWSE_COUPON_LIST,
  payload
});

export const getBrowseCouponListSuccess = (payload) => ({
  type: couponTypes.GET_BROWSE_COUPON_LIST_SUCCESS,
  payload
});

export const getBrowseCouponListFailed = (error) => ({
  type: couponTypes.GET_BROWSE_COUPON_LIST_FAILED,
  error
});

export const getCouponDetail = (payload) => ({
  type: couponTypes.GET_COUPON_DETAIL,
  payload
});

export const getCouponDetailSuccess = (payload) => ({
  type: couponTypes.GET_COUPON_DETAIL_SUCCESS,
  payload
});

export const getCouponDetailFailed = (error) => ({
  type: couponTypes.GET_COUPON_DETAIL_FAILED,
  error
});

export const applyCouponCode = (payload) => ({
  type: couponTypes.APPLY_COUPON_CODE,
  payload
});

export const applyCouponCodeSuccess = (payload) => ({
  type: couponTypes.APPLY_COUPON_CODE_SUCCESS,
  payload
});

export const applyCouponCodeFailed = (error) => ({
  type: couponTypes.APPLY_COUPON_CODE_FAILED,
  error
});

export const applySOCouponCode = (payload) => ({
  type: couponTypes.APPLY_SO_COUPON_CODE,
  payload
});

export const applySOCouponCodeSuccess = (payload) => ({
  type: couponTypes.APPLY_SO_COUPON_CODE_SUCCESS,
  payload
});

export const applySOCouponCodeFailed = (error) => ({
  type: couponTypes.APPLY_SO_COUPON_CODE_FAILED,
  error
});

export const removeCouponCode = (payload) => ({
  type: couponTypes.REMOVE_COUPON_CODE,
  payload
});

export const removeCouponCodeSuccess = (payload) => ({
  type: couponTypes.REMOVE_COUPON_CODE_SUCCESS,
  payload
});

export const removeCouponCodeFailed = (error) => ({
  type: couponTypes.REMOVE_COUPON_CODE_FAILED,
  error
});

export const getAppliedCouponList = () => ({
  type: couponTypes.GET_APPLIED_COUPON_LIST
});

export const getAppliedCouponListSuccess = (payload) => ({
  type: couponTypes.GET_APPLIED_COUPON_LIST_SUCCESS,
  payload
});

export const getAppliedCouponListFailed = (error) => ({
  type: couponTypes.GET_APPLIED_COUPON_LIST_FAILED,
  error
});

export const removeCouponDetail = () => ({
  type: couponTypes.REMOVE_COUPON_DETAIL
});

export const getErrorMsg = (payload) => ({
  type: couponTypes.GET_ERROR_MSG,
  payload
});

export const removeSOCouponCodeStart = (payload) => ({
  type: couponTypes.REMOVE_SO_COUPON_CODE_START,
  payload
});

export const removeSOCouponCodeSuccess = (payload) => ({
  type: couponTypes.REMOVE_SO_COUPON_CODE_SUCCESS,
  payload
});

export const removeSOCouponCodeFailed = (error) => ({
  type: couponTypes.REMOVE_SO_COUPON_CODE_FAILED,
  error
});
