import './SpinWheel.scss';

import React, { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '../../Share/ModalComponent';
import { SpinWinBannerContext } from '../Spin&WinBanner';
import SpinWinPopup from '../SpinWinPopup';

const SpinWheel = () => {
  const luckyDrawReducer = useSelector((state) => state.spinAndWinReducer.getAvailableLuckyDrawReducer);
  const isSuccess = useSelector((state) => state.spinAndWinReducer.applyCouponSpinReducer.isSuccess);
  const luckyDrawList = luckyDrawReducer?.luckyDrawData?.Slices || [];

  const { spinContainerRef, prizeColorData, DEG_PER_ITEM, isShowModalAfterSpin, setIsShowModalAfterSpin } = useContext(SpinWinBannerContext);

  const wheelMaskConicBackground = useMemo(() => {
    const ratioPerItem = 100 / luckyDrawList?.length;
    let conicBackgroundDetail = '';
    const itemSeparate = ', ';
    for (let i = 0; i < luckyDrawList?.length; i++) {
      conicBackgroundDetail += `${i !== 0 ? itemSeparate : ''}${prizeColorData[i].backgroundColor} ${i * ratioPerItem}% ${(i + 1) * ratioPerItem}%`;
    }

    return `conic-gradient(${conicBackgroundDetail})`;
  }, [luckyDrawList]);

  return (
    <>
      <div className='spin-wheel'>
        <div className='spin-wheel__shadow'></div>
        <div className='spin-wheel__border'>
          <img src={require('../../../assets/images/spin-wheel-border.png')} alt='' />
        </div>
        <div className='spin-wheel__arrow'>
          <img src={require('../../../assets/images/spin-wheel-arrow.png')} alt='' />
        </div>
        <div ref={spinContainerRef} className='spin-wheel__container'>
          <div
            style={{
              background: `${wheelMaskConicBackground}`,
              transform: `rotate(-${DEG_PER_ITEM / 2}deg)`
            }}
            className='spin-wheel__mask'
          ></div>
          <div>
            {luckyDrawList.map((luckyDraw, index) => (
              <div
                key={index}
                className='spin-wheel__prize'
                style={{
                  transform: index > 0 ? `rotate(-${DEG_PER_ITEM * index}deg)` : ''
                }}
              >
                <div
                  className='spin-wheel__prize__shape'
                  style={{
                    color: `${prizeColorData[index > 0 ? luckyDrawList.length - index : 0].textColor}`
                  }}
                >
                  <p className='spin-wheel__prize__label'>{luckyDraw?.SliceName}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
        <button className='spin-wheel__btn'>
          <img src={require('../../../assets/images/spin-wheel-button-center.png')} alt='' />
        </button>
      </div>
      <ModalComponent isShow={isSuccess && isShowModalAfterSpin} onCloseHandler={() => setIsShowModalAfterSpin(false)} cssClass='spin-win-popup'>
        <SpinWinPopup setIsShowModalAfterSpin={setIsShowModalAfterSpin} />
      </ModalComponent>
    </>
  );
};

export default SpinWheel;
