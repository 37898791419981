import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TextArray = [
  'New Tier',
  'Spend Benefits Label',
  'Button Text'
];

const LinkArray = [
  'Button Link'
];


export default class TierBenefitModel {
  constructor() {
    TextArray.forEach(text => {
      this[text] = new SitecoreTextModel();
    });

    LinkArray.forEach(link=> {
      this[link] = new SitecoreLinkModel();
    });

    this['Tiers'] = [];
  }

  getData(dataSource) {
    TextArray.forEach(text => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text] = dataSource[text] ?? '';
      }
    });


    LinkArray.forEach(link => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link] = dataSource[link];
      }
    });

    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource,'Tiers')){
      this['Tiers'] = deepCopy(dataSource['Tiers']);
    }

    return this;
  }
}