import qs from 'qs';
import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import {
  VIEW_OFFER_DETAIL_CART_ID_KEY
} from '@components/PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';
import * as actions from '@redux/actions/checkoutProcess';
import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';
import { get, post } from '@services/genericService';
import { customPostMethod } from '@services/genericService';

import { getCartIdParam } from '../../personalizeOffer/utils';
import * as config from './config';

export const getBillingDataEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.GET_BILLING_DATA),
    switchMap(() => {
      const clientSOCartId = getCartIdParam();

      const params = {};
      if (clientSOCartId) params.cartId = clientSOCartId;

      return from(get(config.GET_BILLING_DATA, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getBillingDataSuccess(res.data);
          } else {
            return actions.getBillingDataFailed('Failed');
          }
        })
      );
    })
  );

export const getBalanceBillingDataEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.GET_BALANCE_BILLING_DATA),
    switchMap(() => {
      return from(get(config.GET_BALANCE_BILLING_DATA)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getBalanceBillingDataSuccess(res.data);
          } else {
            return actions.getBalanceBillingDataFailed('Failed');
          }
        })
      );
    })
  );

export const submitOrderEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.SUBMIT_ORDER),
    switchMap((action) => {
      const clientSOCartId = getCartIdParam();
      let submitOrderEndpoint = config.SUBMIT_ORDER;
      let postMethod = null;

      if (clientSOCartId) {
        postMethod = customPostMethod({
          action: submitOrderEndpoint,
          payload: action.payload,
          queryStringsList: {
            cartId: clientSOCartId
          }
        });
      } else{
        postMethod = post(submitOrderEndpoint, action.payload);
      }

      return from(postMethod).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.submitOrderSuccess(res.data);
          } else {
            return actions.submitOrderFailed(res.data?.Errors || 'Failed');
          }
        })
      );
    })
  );

export const submitOrderPayBalanceEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.SUBMIT_ORDER_PAYBALANCE),
    switchMap((action) => {
      return from(post(config.SUBMIT_ORDER_PAYBALANCE, action.payload)).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.submitOrderPayBalanceSuccess(res.data);
          } else {
            return actions.submitOrderPayBalanceFailed(res.data?.Errors || 'Failed');
          }
        })
      );
    })
  );

export const getOrderPaymentStatusEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.GET_ORDER_PAYMENT_STATUS),
    switchMap((action) => {
      return from(get(config.PAYMENT_STATUS, action)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getOrderPaymentStatusSuccess(res.data);
          } else {
            return actions.getOrderPaymentStatusFailed('Failed');
          }
        })
      );
    })
  );
