import './BOFreeGifts.scss';

import Proptypes from 'prop-types';
import React, { Fragment, useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BuyingAccordion from '@components/Share/BuyingAccordion';
import { FREEGIFT_EVENT_KEY } from '@utils/constant';

const BOFreeGifts = (props) => {
  const { dataFields, freeGiftsList, freeGiftsActiveID, freeGiftsSelectedID, freeGiftsSelectedLength, isShowError, setRef, onFreeGiftData } = props;
  const { freeGiftDepositSelected } = useSelector((state) => state.buyingOptionReducer.BOFreeGiftsReducer);
  const [isOpenSelectDepositFreeGift, setIsOpenSelectDepositFreeGift] = useState(false);

  useEffect(() => {
    setIsOpenSelectDepositFreeGift(!Object.entries(onFreeGiftData).every(([key, value]) => (value?.length ? freeGiftDepositSelected?.[key] : true)));
  }, [freeGiftDepositSelected, onFreeGiftData]);

  const handleToggleSelectDepositFreeGift = useCallback(() => {
    setIsOpenSelectDepositFreeGift(!isOpenSelectDepositFreeGift);
  }, [isOpenSelectDepositFreeGift]);

  return freeGiftsList?.length ? (
    <Fragment>
      <div className='freegift-buying buying-option__group-info__line-bottom' ref={setRef}>
        <BuyingAccordion
          data={freeGiftsList}
          headerType={'SECONDTYPE'}
          eventKey={FREEGIFT_EVENT_KEY}
          isOpenItem={freeGiftsSelectedLength >= freeGiftsList.length ? false : true}
          activeItemsId={freeGiftsActiveID.length ? freeGiftsActiveID : []}
          selectedItemsId={freeGiftsSelectedID}
          labelText={dataFields['Free Gifts Label'].value}
          changeText={dataFields['Change Text'].value}
          errorMsg={dataFields['Please Select Your Free Gift Message'].value}
          isShowError={isShowError}
          dataFields={dataFields}
        />
      </div>
    </Fragment>
  ) : onFreeGiftData?.IsDeposit ? (
    <Fragment>
      <div className='freegift-buying buying-option__group-info__line-bottom' ref={setRef}>
        <BuyingAccordion
          data={freeGiftsList}
          headerType={'TUESTYPE'}
          eventKey={FREEGIFT_EVENT_KEY}
          isOpenItem={isOpenSelectDepositFreeGift}
          // activeItemsId={freeGiftsActiveID.length ? freeGiftsActiveID : []}
          selectedItemsId={freeGiftsSelectedID}
          labelText={dataFields['Free Gifts Label'].value}
          changeText={dataFields['Change Text'].value}
          errorMsg={dataFields['Please Select Your Free Gift Message'].value}
          isShowError={isShowError}
          dataFields={dataFields}
          onFreeGiftData={onFreeGiftData}
          onToggleSelectDepositFreeGift={handleToggleSelectDepositFreeGift}
        />
      </div>
    </Fragment>
  ) : null;
};

BOFreeGifts.propTypes = {
  dataFields: Proptypes.any,
  freeGiftsList: Proptypes.any,
  freeGiftsActiveID: Proptypes.any,
  freeGiftsSelectedID: Proptypes.any,
  freeGiftsSelectedLength: Proptypes.any,
  isShowError: Proptypes.any,

  setRef: Proptypes.any,
  onFreeGiftData: Proptypes.any
};

export default BOFreeGifts;
