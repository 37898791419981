import { useCallback } from 'react';

import { COMPARE_BENEFIT_DETAIL_ID } from '../ProductFunctionsDetail';

const useSetBenefitDetailHeight = () => {
  return useCallback(async (compareItemRef) => {
    return new Promise((resolve) => {
      let benefitElDOM = [];

      for (const compareItem of compareItemRef.current) {
        if (compareItem.current) {
          const benefitEl = compareItem.current.querySelector(`[aria-label="${COMPARE_BENEFIT_DETAIL_ID}"]`);
          if (benefitEl) {
            // INFO: reset style when init
            benefitEl.style.height = 'fit-content';

            benefitEl.style.minHeight = 'unset';

            // INFO: push data to next step
            benefitElDOM.push(benefitEl);

          }
        }
      }

      return resolve(benefitElDOM);
    }).then((response) => {
      let highestElValue = 0;

      const setBenefitElHeight = (clientRectHeight) => {
        for (const el of response) {
          if (clientRectHeight >= highestElValue) {
            highestElValue = clientRectHeight;

            el.style.minHeight = `${clientRectHeight}px`;
          }
        }
      };
      if (response.length) {
        for (const el of response) {
          // eslint-disable-next-line no-undef
          new IntersectionObserver(([entry]) => setBenefitElHeight(entry.boundingClientRect.height)).observe(el);
        }
      }
    });
  }, []);
};

export default useSetBenefitDetailHeight;
