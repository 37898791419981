import PublishedDateModel from '@models/PublishedDateModel';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

export default class SimpleTilesItemFieldsModel {
  constructor() {
    this.Image = new SitecoreImageModel();

    this.Link = new SitecoreLinkModel();

    this['Reading Duration'] = new SitecoreTextModel();

    this.Title = new SitecoreTextModel();

    this.Updated = new PublishedDateModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image.getData(dataSource.Image);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this.Link.getData(dataSource.Link);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Reading Duration')) {
      this['Reading Duration'].getData(dataSource['Reading Duration']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Updated')) {
      this.Updated.getData(deepCopy(dataSource.Updated));
    }

    return this;
  }
}
