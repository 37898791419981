import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { linkCustomerViewPage } from '@components/ClientPortal/CustomerProfiles/CustomerProfileContainer';
import { getProductWishlistAboutUserId } from '@redux/actions/product';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import BoxData from '../BoxData';
import ProductInterestModel from '../ProductInterest/model/ProductInterestModel';
import CustomerWishlistShimmer from './CustomerWishlistShimmer'
import Global from '@utils/global';

const MAX_SHOW_WISHLIST = 4;

const CustomerWishlist = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const productWishlist = useSelector((state) => state.productReducer.productWishlistReducer);
  const dispatch = useDispatch();
  useEffect(() => {
    const model = new ProductInterestModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  useDidUpdateEffect(() => {
    if (clientTellingPortalCustomer?.externalId) {
      dispatch(getProductWishlistAboutUserId(clientTellingPortalCustomer.externalId));
    }
  }, [clientTellingPortalCustomer.externalId]);

  // const generateDate = (params) => {
  //   let date = new Date(params);
  //   let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  //   return `${date.getDate() || ''} ${monthArr[date.getMonth()] || ''} ${date.getFullYear() || ''}`;
  // };

  const renderDateCreated = (dateCreated) => {
    const dateObject = new Date(Number.parseInt(dateCreated?.replace(/Date|\(|\/|\)/gi, '')));

    const convertDate = Global.customDate(dateObject).replace(',', '');

    return convertDate;
  };

  return layoutData ? (
    <div className='col-md-6 profiles__col'>
      {
        ((clientTellingPortalCustomer.externalId && !productWishlist?.listProductWishlist) || clientTellingPortalCustomer.isLoading) ? (
          <CustomerWishlistShimmer layoutData={layoutData} />
        ) : (
      <BoxData 
        component='customer-wishlist' 
        layoutData={layoutData} 
        data={productWishlist.listProductWishlist}
        linkPage={linkCustomerViewPage(layoutData)}
      >
        {productWishlist.listProductWishlist.length ? (
          productWishlist.listProductWishlist.map((p, index) => {
            return index < MAX_SHOW_WISHLIST ? (
              <div className='box-data__row' key={p?.ExternalWishListLineId || index}>
                <div className='box-data__col-1'>
                  <div className='box-data__title --sub'>{p?.Product?.ProductName}</div>
                  <p className='box-data__notice'>{`${layoutData['Added On Text'].value} ${renderDateCreated(p?.DateCreated)}`}</p>
                </div>
                <div className='box-data__col-2'></div>
              </div>
            ) : '';
          })
        ) : (
          <></>
        )}
      </BoxData>
        )
      }
    </div>
  ) : (
    <></>
  );
};

CustomerWishlist.propTypes = {
  fields: object
};

export default CustomerWishlist;
