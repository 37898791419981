import './LimitedReviewListing.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import ProductReviewContent from '../ProductReviewContent';
import LimitedReviewListingShimmer from './LimitedReviewListingShimmer';

const LimitedReviewListing = ({ fieldsData, dataApiReviewListing, productId, productName, productImg, dynamicBundleName, commerceId }) => {
  const TEMPLATE_NAME_STATIC_BUNDLE = 'Bundle Product Page';
  const TEMPLATE_NAME_DYNAMIC_BUNDLE = 'Dynamic Bundle';
  const getProductReviewReducer = useSelector((state) => state.productReviewReducer.getProductReviewReducer);
  const staticBundleDetailReducer = useSelector((state) => state.staticBundleDetailReducer);
  const dynamicBundleReducer = useSelector((state) => state.dynamicBundleReducer);

  const onClickSeeAllReviewLink = () => {
    const isBundleProduct =
      SitecoreContextFactoryService.getValueContextRouteItem('templateName') == TEMPLATE_NAME_STATIC_BUNDLE ||
      SitecoreContextFactoryService.getValueContextRouteItem('templateName') == TEMPLATE_NAME_DYNAMIC_BUNDLE;
    const bundleType = SitecoreContextFactoryService.getValueContextRouteItem('templateName');
    const productPageId = SitecoreContextFactoryService.getValueContextRouteItem('itemId');
    const bundlePageId = SitecoreContextFactoryService.getValueContextRouteItem('itemId');

    const allReviewDetailsProduct = {
      productId: productId,
      itemId: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.id,
      couponCode: new URLSearchParams(window.location.search).get('cCode'),
      hidePriceAndCTA: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Hide Price And CTA']?.value,
      commerceProductId: commerceId ? commerceId : SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.id.toUpperCase(),
      productName: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.displayName,
      productLink: window.location.href,
      productBundle: {
        variantStatus: staticBundleDetailReducer?.variantStatus,
        bundleName: bundleType == TEMPLATE_NAME_DYNAMIC_BUNDLE ? dynamicBundleName : staticBundleDetailReducer?.prices?.BundleName,
        listPrice:
          bundleType == TEMPLATE_NAME_DYNAMIC_BUNDLE ? dynamicBundleReducer?.listingPrice : staticBundleDetailReducer?.prices?.ListPrice?.Price,
        sellPrice:
          bundleType == TEMPLATE_NAME_DYNAMIC_BUNDLE ? dynamicBundleReducer?.sellingPrice : staticBundleDetailReducer?.prices?.SellPrice?.Price,
        buyOptionLink: dynamicBundleReducer?.buyOptionLink,
        allowBuy: dynamicBundleReducer?.allowBuy
      },
      bundleElementActive: {
        elementName: productName,
        elementImg: productImg
      },
      currency: {
        symbol: dynamicBundleReducer?.currency?.Symbol,
        symbolPosition: dynamicBundleReducer?.currency?.SymbolPosition,
        currencyNumberFormatCulture: dynamicBundleReducer?.currency?.CurrencyNumberFormatCulture,
        currencyDecimalDigits: dynamicBundleReducer?.currency?.NumberFormat?.CurrencyDecimalDigits
      }
    };

    window.sessionStorage.setItem('allReviewDetailsProduct', JSON.stringify(allReviewDetailsProduct));

    if (fieldsData['See All Reviews Button Link']?.value?.href) {
      window.location.href =
        fieldsData['See All Reviews Button Link']?.value?.href +
        '?isBundleProduct=' +
        isBundleProduct +
        (isBundleProduct && bundlePageId
          ? '&bundlePageId=' + bundlePageId + '&bundleType=' + bundleType
          : productPageId
            ? '&productPageId=' + productPageId
            : '');
    }
  };

  const LIMIT_DISPLAY_OF_REVIEWS = 3;

  return fieldsData && !getProductReviewReducer.isLoading ? (
    dataApiReviewListing?.length ? (
      <div className='review-listing review-listing--limited'>
        <ul className='review-listing__list'>
          {dataApiReviewListing?.map((review, index) => (
            <li className='review-listing__item' key={index}>
              <ProductReviewContent reviewItem={review} layoutData={fieldsData} />
            </li>
          ))}
        </ul>
        {getProductReviewReducer?.productReviews?.TotalRecords > LIMIT_DISPLAY_OF_REVIEWS && (
          <div className='review-listing__btn text-center'>
            <span
              className='review-listing__btn__item review-listing__btn__item--large btn btn-outline-CTA2'
              onClick={() => onClickSeeAllReviewLink()}
            >
              <i className='icon-chevron-down-big'></i>
              <Text tag='span' field={fieldsData['See All Reviews Button Text']} />
            </span>
          </div>
        )}
      </div>
    ) : (
      ''
    )
  ) : (
    <LimitedReviewListingShimmer />
  );
};

LimitedReviewListing.propTypes = {
  fieldsData: PropTypes.any,
  dataApiReviewListing: PropTypes.any,
  productId: PropTypes.string,
  productName: PropTypes.string,
  productImg: PropTypes.string,
  dynamicBundleName: PropTypes.string,
  commerceId: PropTypes.string
};

export default LimitedReviewListing;
