import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SignleSignOnModel {
  constructor() {
    this['App ID'] = new SitecoreTextModel();

    this['App Secret'] = new SitecoreTextModel();

    this['Client ID'] = new SitecoreTextModel();

    this['Redirect Uri'] = new SitecoreTextModel();

    this['Login Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'App ID')) {
      this['App ID'].getData(dataSource['App ID']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'App Secret')) {
      this['App Secret'].getData(dataSource['App Secret']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Client ID')) {
      this['Client ID'].getData(dataSource['Client ID']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Redirect Uri')) {
      this['Redirect Uri'].getData(dataSource['Redirect Uri']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Label')) {
      this['Login Label'].getData(dataSource['Login Label']);
    }
    
    return this;
  }
}
