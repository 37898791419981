import './VariantBuying.scss';

import Proptypes from 'prop-types';
import React, { Fragment, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import BuyingAccordion from '@components/Share/BuyingAccordion';
import { DEFAULT_SELECT_LIMIT, VARIANT_EVENT_KEY } from '@utils/constant';
import global from '@utils/global';

import { useConvertBalanceDate } from '../../Product/VariantDetail/ProductDetailOverview/ProductVariantInfo/utils';
import CountdownTimerItem from '../../Share/CountdownTimer/CountdownTimerItem/index';
import TermsConditionsModals from './TermsConditionsModals';

const BOVariants = (props) => {
  const {
    dataFields,
    variantList,
    variantActive,
    variantSelectedLength,
    chooseVariantLabel,
    validationMessage,
    isShowError,
    setRef,
    productData
  } = props;

  const [isShow, setShow] = useState(false);
  const isDeposit = useSelector((state)=> state?.buyingOptionReducer?.BOGetDataStatusReducer?.IsDeposit);
  const objDeposit = useSelector((state)=> state?.buyingOptionReducer?.BOGetDataStatusReducer?.Deposit);
  const convertBalanceDateService = useConvertBalanceDate();

  const onShowHandler = () => {
    setShow(true);
  };

  const onCloseHandler = () => {
    setShow(false);
  };

  let chooseYourVariantLabel = chooseVariantLabel || dataFields['Choose Your Color Label'].value;
  let validationMsg = validationMessage || dataFields['Please Select Your Color Message'].value;
  const startBalanceDate = useMemo(() => convertBalanceDateService(objDeposit?.BalanceStartDate), [objDeposit]);
  const endBalanceDate = useMemo(() => convertBalanceDateService(objDeposit?.BalanceEndDate), [objDeposit]);

  return variantList?.length ? (
    <Fragment>
      <div className='variant-buying buying-option__group-info__line-bottom' ref={setRef}>
        <BuyingAccordion
          data={variantList}
          headerType={'FIRSTTYPE'}
          eventKey={VARIANT_EVENT_KEY}
          isOpenItem={variantSelectedLength === DEFAULT_SELECT_LIMIT ? false : true}
          activeItemsId={variantActive?.VariantId ? [variantActive.VariantId] : []}
          selectedItemsName={variantActive?.VariantPropertyValue || ''}
          labelText={chooseYourVariantLabel}
          changeText={dataFields['Change Text'].value}
          errorMsg={validationMsg}
          isShowError={isShowError}
          dataFields={dataFields}
          productData={productData}
        />
        {isDeposit && (
          <div className='pay-deposit'>
            <div className='pay-deposit__box'>
              <div className='pay-deposit__number pay-deposit__number--before'>1</div>
              <div className='pay-deposit__content'>
                <span className='pay-deposit__content__info'>{`${dataFields?.['Pay Deposit Label']?.value}: `}</span>
                <span className='pay-deposit__content__info pay-deposit__content__info--fontRegular '>{`${dataFields?.['Pay Deposit End At Label']?.value} `}</span>
                <CountdownTimerItem
                  startTime={parseInt(`/Date(${new Date().getTime()})/`.match(/\d+/)[0])}
                  endTime={parseInt((objDeposit?.DepositEndDate).match(/\d+/)[0])}
                  remainingTime={parseInt(`/Date(${new Date().getTime()})/`.match(/\d+/)[0])}
                  dataFromLayout={''}
                  startMsg={''}
                  endMsg={''}
                  onAction={''}
                  isTranslateDateTimeLetters={true}
                />
              </div>
            </div>
            <div className='pay-deposit__box'>
              <div className='pay-deposit__number pay-deposit__number--before'>2</div>
              <div className='pay-deposit__content'>
                <div>
                  <span className='pay-deposit__content__info'>{`${dataFields?.['Balance Payment Label']?.value}: `}</span>
                  <span className='pay-deposit__content__info pay-deposit__content__info--fontRegular'>{objDeposit?.BalancePriceWithCurrency}</span>
                </div>
                <div>
                  <span className='pay-deposit__content__info'>{`${dataFields?.['Payment Period Label']?.value}: `}</span>
                  <span className='pay-deposit__content__info pay-deposit__content__info--fontRegular'>
                    {`${startBalanceDate} ${dataFields?.['To Text']?.value} ${endBalanceDate}`}
                  </span>
                </div>
              </div>
            </div>
            <div className='pay-deposit__box'>
              <div className='pay-deposit__number'>3</div>
              <div className='pay-deposit__content'>
                <span className='pay-deposit__content__info'>{`${dataFields?.['Ship Out Label']?.value}: `}</span>
                <span className='pay-deposit__content__info pay-deposit__content__info--fontRegular'>
                  {dataFields?.['Ship Out Details']?.value.replace('{ShipOut}', objDeposit?.ShipOut)}
                </span>
              </div>
            </div>
            <div className='pay-deposit__popup'>
              <span className='pay-deposit__popup__info' onClick={onShowHandler}>
                {dataFields?.['Terms And Conditions Label']?.value}
              </span>
            </div>
            <TermsConditionsModals isShow={isShow} onCloseHandler={onCloseHandler} ondataSources={dataFields} />
          </div>
        )}
      </div>
    </Fragment>
  ) : null;
};

BOVariants.propTypes = {
  dataFields: Proptypes.any,
  variantList: Proptypes.any,
  variantActive: Proptypes.any,
  variantSelectedLength: Proptypes.any,
  isShowError: Proptypes.any,
  variantPropertyName: Proptypes.any,
  chooseVariantLabel: Proptypes.any,
  validationMessage: Proptypes.any,
  setRef: Proptypes.any
};

export default BOVariants;
