import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import FieldsModel from './FieldsModel';

export default class StripAdsFieldsModel {
  constructor() {
    this.Content = new SitecoreTextModel();

    this['Background Color'] = new SitecoreTextModel();

    this['Text Color'] = new SitecoreTextModel();

    this.items = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Content')) {
      this.Content.getData(dataSource.Content || {});
      if(dataSource.Content?.value) {
        this['items'].unshift(new FieldsModel().getData(deepCopy(dataSource)));
      }
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let newItemsList = [];

      dataSource['items'].map((element) => {
        newItemsList.push(new FieldsModel().getData(deepCopy(element?.fields || {})));
      });

      this['items'] = this['items'].concat(newItemsList);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Color')) {
      this['Background Color'].getData(dataSource['Background Color']?.fields?.Value || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Text Color')) {
      this['Text Color'].getData(dataSource['Text Color'] || {});
    }

    return this;
  }
}
