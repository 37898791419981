import './NewsletterPreferenceWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import {getStatusSubscribe, getSubscribe} from '@redux/actions/myAccount';
import {Text} from '@sitecore-jss/sitecore-jss-react';

import NewsletterPreferenceWidgetModel from './models/NewsletterPreferenceWidgetModel';

const NewsletterPreferenceWidget = ({fields, isSubscribe, errorMessage, isLoading, newsletterList}) => {
  const [dataSource, setDataSource] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setDataSource(new NewsletterPreferenceWidgetModel().getData(fields || {}));

    dispatch(getStatusSubscribe({listId: fields?.['Store to List']?.id || ''}));
  }, []);

  const onToggleSubscribeHandler = () => {
    dispatch(getSubscribe({
      params: {
        isSubscribe: !isSubscribe,
        listId: newsletterList || ''
      },
    }));
  };

  return dataSource && newsletterList && !isLoading ? (
    <div className='my-account-widget newsletter-widget animated-slow fadeIn delay-100'>
      <div className='my-account-widget__heading'>
        <h4 className='my-account-widget__heading__title'>
          <Text field={dataSource['Title']}/>
        </h4>
      </div>
      <div className='my-account-widget__content newsletter-widget__content'>
        <button className='my-account-section__content__btn-add btn btn-outline-CTA2' onClick={onToggleSubscribeHandler}>
          <Text field={isSubscribe ? dataSource['Unsubscribe Button Text'] : dataSource['Subscribe Button Text']}/>
        </button> 
        {errorMessage ? <span className='error-validate'>{errorMessage}</span> : ''}
      </div>
    </div>
  ) : (
    <div className='my-account-widget newsletter-widget skeleton__item'>
      <div className='my-account-widget__heading my-account-section__group'>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
      </div>
      <div className='my-account-widget__content newsletter-widget__content'>
        <span className='text-line-40-center w-80'></span>
      </div>
    </div>
  );
};

NewsletterPreferenceWidget.propTypes = {
  fields: PropTypes.object,
  isSubscribe: PropTypes.any,
  errorMessage: PropTypes.string,
  isLoading: PropTypes.bool,
  newsletterList: PropTypes.string,
};

const mapStateToProps = (state) => ({
  isSubscribe : state.singleSignOnReducer.newsletterSubscribeReducer.isSubscribe,
  errorMessage : state.singleSignOnReducer.newsletterSubscribeReducer.errorMessage,
  isLoading : state.singleSignOnReducer.newsletterSubscribeReducer.isLoading,
  newsletterList: state.settingGlobalReducer.settingGlobal?.NewsletterList
});

export default connect(mapStateToProps)(NewsletterPreferenceWidget);