import './CampaignProductListing.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

import CampaignProductListingItem from './CampaignProductListingItem';
import CampaignProductListingShimmer from './CampaignProductListingShimmer';
import CampaignProductListingModel from './model/CampaignProductListingModel';

const CampaignProductListing = (props) => {
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const model = new CampaignProductListingModel();

    setInputData(model.getData(deepCopy(props.fields) || {}));
  }, []);
  return inputData ? (
    <div className={`campaign__listing animated-slow fadeIn delay-100${isClientTelingUser ? ' --cp-view' : ''}`}>
      <div className={`container${isClientTelingUser ? ' tablet-container' : ''}`}>
        <div className='campaign__listing__group'>
          {
            isClientTelingUser ? (
            inputData?.Products?.map((item, index) => (
              <CampaignProductListingItem
                customClass='article article--horizontal article--horizontal--w50 campaign-item--horizontal'
                data={item}
                dataFromLayout={inputData}
                key={item.ProductId || index}
                indexGA4={index}
              />
            ))
            ) : (
            inputData?.Products?.map((item, index) => {
              if (index % 2 === 0) {
                return (
                  <CampaignProductListingItem
                    customClass='article article--horizontal article--horizontal--w50 campaign-item--horizontal'
                    data={item}
                    dataFromLayout={inputData}
                    key={item.ProductId}
                    indexGA4={index}
                  />
                );
              } else {
                return (
                  <CampaignProductListingItem
                    customClass='article article--horizontal article--horizontal--w50 article--horizontal--reverse campaign-item--horizontal'
                    data={item}
                    dataFromLayout={inputData}
                    key={item.ProductId}
                    indexGA4={index}
                  />
                );
              }
            })
            )
          }
        </div>
      </div>
    </div>
  ) : (
    <div className='campaign__listing skeleton__item'>
      <div className='container'>
        <LoadingShimmer itemNumb={3}>
          <CampaignProductListingShimmer></CampaignProductListingShimmer>
        </LoadingShimmer>
      </div>
    </div>
  );
};

CampaignProductListing.propTypes = {
  fields: PropTypes.object
};

export default CampaignProductListing;
