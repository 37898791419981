
import * as action from './getCrmCustomerTypes';

export const getCrmCustomer = (payload) => ({
  type: action.GET_CRM_CUSTOMER,
  payload
});

export const getCrmCustomerSuccess = (payload) => ({
  type: action.GET_CRM_CUSTOMER_SUCCESS,
  payload
});

export const getCrmCustomerFailed = (payload) => ({
  type: action.GET_CRM_CUSTOMER_FAILED,
  payload
});
