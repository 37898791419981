import './CartMini.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { hamburgerOffset } from '@components/Share/Header/handleHamburgerService';
import { searchBoxOffset } from '@components/Share/Header/SearchBox/handleSearchBoxService';
import Loading from '@components/Share/Loading';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import CartMilstone from '../../../SlicingPage/CartDetails/CartMilstone';
import CartMiniItem from './CartMiniItem';
import CartMiniTotalAmount from './CartMiniTotalAmount';
import { miniCartOffset$ } from './handleMiniCartService';
import MiniCartModel from './models/MiniCartModel';

const MiniCart = (props) => {
  const { fields } = props;
  const [dataSources, setDataSources] = useState(null);
  const [isShowMiniCart, setIsShowMiniCart] = useState(false);
  const [totalItem, setTotal] = useState(0);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const [cartLink, setCartLink] = useState(false);

  useEffect(() => {
    if (isShowMiniCart) {
      setTimeout(() => {
        Global.stopScrollingPage();
      }, 200);
    }

    return () => Global.scrollingPage();
  }, [isShowMiniCart]);

  useEffect(() => {
    let data = new MiniCartModel().getData(fields || {});

    setDataSources(data);
    let cartLinkData = data['View Cart Link'].Link;
    let cartLink = `${cartLinkData?.value?.href}`;

    cartLinkData.value.href = cartLink;

    setCartLink(cartLinkData);
  }, []);

  useEffect(() => {
    if (currentCart) {
      const quantityArray = currentCart.Lines.map((lineItem) => +lineItem.Quantity);

      setTotal(quantityArray.length ? quantityArray.reduce((total, quantity) => total + quantity) : 0);
    }
  }, [currentCart]);

  const handleShowMiniCart = () => {
    setIsShowMiniCart(!isShowMiniCart);

    miniCartOffset$.next('check:ShowSubMenu');
  };

  useEffect(() => {
    const hamburgerOffsetSubscription = hamburgerOffset.subscribe((item) => item.length && setIsShowMiniCart(false));

    return () => {
      hamburgerOffsetSubscription.unsubscribe();
    };
  }, [hamburgerOffset]);

  useEffect(() => {
    const searchBoxOffsetSubscription = searchBoxOffset.subscribe((item) => item.length && setIsShowMiniCart(false));

    return () => {
      searchBoxOffsetSubscription.unsubscribe();
    };
  }, [searchBoxOffset]);

  return dataSources ? (
    <Fragment>
      <div
        className={isShowMiniCart ? 'header__right-contents__mini-cart header-icon-bg show-mini-cart' : 'header__right-contents__mini-cart header-icon-bg'}
        onClick={() => handleShowMiniCart()}
      >
        {totalItem !== 0 ? (
          <div className='header__right-contents__mini-cart__quantity'>
            {totalItem !== null ? <span className='product-quantity'>{totalItem}</span> : ''}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className={isShowMiniCart ? 'header__right-contents__mini-cart__contents show-mini-cart' : 'header__right-contents__mini-cart__contents'}>
        {currentCart ? (
          <div className='cart-mini'>
            {currentCart.Lines?.length ? (
              <div className='cart-mini__group'>
                {dataSources['Description'] ? (
                  <div className='cart-mini__header'>
                    <p dangerouslySetInnerHTML={{ __html: dataSources['Description'] }}></p>
                  </div>
                ) : (
                  <></>
                )}
                <div className='cart-mini__main'>
                  <ul className='cart-mini__list-item'>
                    {currentCart.Lines.map((itemCart) => {
                      return (
                        <li className='cart-mini__item' key={itemCart.ExternalCartLineId}>
                          <CartMiniItem data={itemCart} dataSources={dataSources} />
                        </li>
                      );
                    })}
                    {currentCart.Lines.length >= 2 ? <div className='overlay-bottom'></div> : ''}
                  </ul>
                </div>
              </div>
            ) : (
              <div className='cart-mini__header cart-emty'>
                <p>
                  <Text field={dataSources['Cart No Item Label']} />
                </p>
              </div>
            )}
            <div className='cart-mini__footer'>
              <CartMilstone milestoneData={dataSources?.['Promotion Milestone']}/>
              <CartMiniTotalAmount totalItem={totalItem} data={currentCart} dataSources={dataSources} />
              <div className='cart-mini__button'>
                {dataSources['View Cart Link'].Title.value ? (
                  <Link className='btn btn-primary' field={cartLink}>
                    <Text field={dataSources['View Cart Link'].Title} />
                  </Link>
                ) : (
                  <Link className='btn btn-primary' field={dataSources['View Cart Link'].Link} />
                )}
              </div>
              {dataSources['Additional Link'].Title.value ? (
                <Link className='cart-mini__link btn-link' field={dataSources['Additional Link'].Link}>
                  <Text field={dataSources['Additional Link'].Title} />
                </Link>
              ) : (
                <Link className='cart-mini__link btn-link' field={dataSources['Additional Link'].Link} />
              )}
            </div>
          </div>
        ) : (
          <Loading />
        )}
      </div>
      {isShowMiniCart && (
        <div className='overlay-black' onClick={() => setIsShowMiniCart(false)}>
          {/* <div className='overlay-black__header'></div> */}
          <div className='overlay-black__body'></div>
        </div>
      )}
    </Fragment>
  ) : (
    ''
  );
};

MiniCart.propTypes = {
  fields: PropTypes.object
};

export default MiniCart;
