import { UPDATE_BOOKING_EXPERIENCE_FAILED, UPDATE_BOOKING_EXPERIENCE_START, UPDATE_BOOKING_EXPERIENCE_SUCCESS } from './types';

export const updateBookingExperienceStart = (payload) => ({
  type: UPDATE_BOOKING_EXPERIENCE_START,
  payload
});

export const updateBookingExperienceSuccess = (payload) => ({
  type: UPDATE_BOOKING_EXPERIENCE_SUCCESS,
  payload
});

export const updateBookingExperienceFailed = (payload) => ({
  type: UPDATE_BOOKING_EXPERIENCE_FAILED,
  payload
});
