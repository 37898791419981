import React from 'react';

import { createDefaultFieldFactory, FieldTypes } from '@sitecore-jss/sitecore-jss-react-forms';
import { CUSTOMIZED_CHECKBOX_ID, SITECOREFORM_RECAPTCHA_ID } from '@utils/constant';

import CustomReCaptchaComponent from '../CustomReCaptchaComponent';
import CustomButton from '../FieldTypes/CustomButton';
import CustomCheckbox from '../FieldTypes/CustomCheckbox';
import CustomCheckboxList from '../FieldTypes/CustomCheckboxList';
import CustomDropdownList from '../FieldTypes/CustomDropdownList';
import CustomInputTypeFile from '../FieldTypes/CustomInputTypeFile';
import CustomLabel from '../FieldTypes/CustomLabel';
import CustomMultipleLineText from '../FieldTypes/CustomMultipleLineText';
import CustomRadioBoxList from '../FieldTypes/CustomRadioboxList';
import CustomRangeRadioBoxList from '../FieldTypes/CustomRangeRadioList';
import CustomResponseMsg from '../FieldTypes/CustomResponseMsg';
import CustomRichTextCheckbox from '../FieldTypes/CustomRichTextCheckbox';
import CustomRichTextFormField from '../FieldTypes/CustomRichTextFormField';
import CustomSingleLineText from '../FieldTypes/CustomSingleLineText';
import CustomTelephone from '../FieldTypes/CustomTelephone';
import EmailValidator from '../FieldTypes/EmailValidator';
import HiddenField from '../FieldTypes/HiddenField';
import FormFieldError from '../FormFieldError';

const customFieldFactory = createDefaultFieldFactory();

customFieldFactory.setComponent(FieldTypes.Button, (props) => {
  return (
    // <div className='signup-form__form--wrap-btn-submit'>
    //   <button
    //     className='signup-form__form__btn btn btn-outline-CTA2'
    //     type='submit'
    //     name={props.field.buttonField.name}
    //     id={props.field.buttonField.id}
    //   >
    //     {props.field.buttonField.value}
    //   </button>
    // </div>
    <CustomButton {...props} />
  );
});

customFieldFactory.setComponent(FieldTypes.Email, (props) => {
  return <EmailValidator {...props} />;
});

customFieldFactory.setComponent(SITECOREFORM_RECAPTCHA_ID, (props) => {
  return (
    <div className='form__field--captcha'>
      <CustomReCaptchaComponent {...props} />
    </div>
  );
});

customFieldFactory.setComponent(CUSTOMIZED_CHECKBOX_ID, (props) => {
  return <CustomRichTextCheckbox {...props} />;
});

customFieldFactory.setComponent(FieldTypes.SingleLineText, (props) => {
  return <CustomSingleLineText {...props} />;
});

customFieldFactory.setComponent(FieldTypes.Telephone, (props) => {
  return <CustomTelephone {...props} />;
});

customFieldFactory.setComponent(FieldTypes.MultipleLineText, (props) => {
  return <CustomMultipleLineText {...props} />;
});

customFieldFactory.setComponent(FieldTypes.Checkbox, (props) => {
  return <CustomCheckbox {...props} />;
});

customFieldFactory.setComponent(FieldTypes.DropdownList, (props) => {
  return <CustomDropdownList {...props} />;
});

customFieldFactory.setComponent(FieldTypes.TextField, (props) => {
  return <CustomResponseMsg {...props} />;
});

customFieldFactory.setComponent(FieldTypes.CheckboxList, (props) => {
  return <CustomCheckboxList {...props} />;
});

customFieldFactory.setComponent(FieldTypes.RadioButtonList, (props) => {
  return <CustomRadioBoxList {...props} />;
});

const radioButtonListWithOptionId = '{F384CCC9-C1C1-489B-895F-0B11BB9C7253}';

customFieldFactory.setComponent(radioButtonListWithOptionId, (props) => {
  return <CustomRadioBoxList {...props} />;
});

const rangeRadioListId = '{1B494E40-E170-4FDD-B372-D35CB58DC45A}';

customFieldFactory.setComponent(rangeRadioListId, (props) => {
  return <CustomRangeRadioBoxList {...props} />;
});

const hidenFieldId = '{F847B473-41C0-43FA-9177-8B67E2ACE935}';

customFieldFactory.setComponent(hidenFieldId, (props) => {
  return <HiddenField {...props} />;
});

const richTextId = '{F6ABF2D4-D864-48D7-A464-75025A42162E}';

customFieldFactory.setComponent(richTextId, (props) => {
  return <CustomRichTextFormField {...props} />;
});

const inputTypeFileId = '{7E9A0903-A52C-4843-BBE1-5B26BD162BED}';

customFieldFactory.setComponent(inputTypeFileId, (props) => {
  return <CustomInputTypeFile {...props} />;
});

export default customFieldFactory;
