import { ofType } from 'redux-observable';
import { of } from 'rxjs';
// import { from } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import UserInfoModel from '@components/SingleSignOn/models/UserInfoModel';
import { EVENT_METHOD } from '@components/SingleSignOn/RegisterLogin/utils';
import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { postAsObservable } from '@services/genericService';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE } from '@utils/constant';
import { formatDateToUTC } from '@utils/dateFormatV2';

import * as config from '../config';
import { generateRegisterLoginError } from '../utils';

const registerEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.REGISTER),
    switchMap((action) => {
      const params = action.payload?.IsRegisterAfterOrder
        ? {
          password: action.payload.Password,
          orderNumber: action.payload.OrderId,
          ReCaptchaRequest: action.payload.ReCaptchaRequest,
          ReceiveSms: action.payload.ReceiveSms,
          ReceiveEmail: action.payload.ReceiveEmail,
          ReceiveDirectMail: action.payload.ReceiveDirectMail,
          Subscribed: action.payload.Subscribed
        }
        : {
          FirstName: action.payload.FirstName,
          LastName: action.payload.LastName || DEFAULT_LAST_NAME_API_PAYLOAD_VALUE,
          Email: action.payload.Email,
          PhoneNumber: action.payload.PhoneNumber,
          Password: action.payload.Password,
          ReCaptchaRequest: action.payload.ReCaptchaRequest,
          Gender: action.payload.Gender,
          DOB: formatDateToUTC(action.payload['DOB']),
          ReceiveSms: action.payload.ReceiveSms,
          ReceiveDirectMail: action.payload.ReceiveDirectMail,
          ReceiveEmail: action.payload.ReceiveEmail,
          Subscribed: action.payload.Subscribed,
          ReceiveOsim: action.payload.ReceiveOsim,
          ReceivePdpa: action.payload.ReceivePdpa
        };

      return postAsObservable(action.payload?.IsRegisterAfterOrder ? config.ACTION_REGISTER_ORDER : config.ACTION_REGISTER, params).pipe(
        switchMap((res) => {
          const userInfoModel = new UserInfoModel().getData(res.data?.Data || {});
          const enabledLink = res.data?.Enabled?.map((name) => name.toLowerCase());
          if (res.status === 200 && res.data?.Success) {
            return of(
              actions.regiterSuccess(res.data?.Success),
              actions.loginSuccess({
                ...userInfoModel,
                Enabled: enabledLink || [],
                isLinkFacebook: enabledLink?.includes(config.TYPE_FACEBOOK),
                isLinkGoogle: enabledLink?.includes(config.TYPE_GOOGLE),
                FacebookUsername: res.data?.FacebookUsername || '',
                GoogleUsername: res.data?.GoogleUsername || '',
                IsRegisterNew: true,
                type: EVENT_METHOD.email
              })
            );
          } else {
            return of(actions.registerFailed(generateRegisterLoginError(res.data?.Errors?.[0], EVENT_METHOD.email)));
          }
        }),
        catchError((error) => of(actions.registerFailed(error.message || 'Failed')))
      );
    })
  );

export default registerEpic;
