import { ofType } from 'redux-observable';
import { from,of } from 'rxjs';
import { catchError,map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/bookingExperience/getTotalBookedTimeSlot';
import * as types from '@redux/actions/bookingExperience/getTotalBookedTimeSlot/types';
import { get } from '@services/genericService';

import * as configs from './config';

export const getTotalBookedTimeSlotOfDateEpic = (action$) =>
  action$.pipe(
    ofType(types.GET_TOTAL_BOOKED_TIMESLOT_OF_DATE || types.GET_TOTAL_BOOKED_DATE_OF_STORE),
    switchMap((action) => {

      const params = {
        StoreId: action.payload.storeId,
        Date: action.payload.date
      };
      
      return from(get(configs.ACTION_GET_TOTAL_BOOKED_TIMESLOT, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getTotalBookedTimeSlotOfDateSuccess(res?.data);
          } else {

            return actions.getTotalBookedTimeSlotOfDateFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getTotalBookedTimeSlotOfDateFailed(error.message || 'Failed')))
      );
    })
  );

export const getTotalBookedDateOfStoreEpic = (action$) =>
  action$.pipe(
    ofType(types.GET_TOTAL_BOOKED_DATE_OF_STORE),
    switchMap((action) => {

      const params = {
        StoreId: action.payload.storeId
      };
      
      return from(get(configs.ACTION_GET_TOTAL_BOOKED_TIMESLOT, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getTotalBookedDateOfStoreSuccess(res?.data);
          } else {

            return actions.getTotalBookedDateOfStoreFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getTotalBookedDateOfStoreFailed(error.message || 'Failed')))
      );
    })
  );
