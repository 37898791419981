import './CampaignBanner.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import CountdownTimerCampaign from '@components/Share/CountdownTimer/CountdownTimerCampaign';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CampaignBannerModel from './model/CampaignBannerModel';

const CampaignBanner = (props) => {
  const [inputData, setInputData] = useState(null);
  const [validString, setValidString] = useState(null);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const countdownFormatSettingObj = useSelector((state) => state.settingGlobalURLReducer.settingGlobalURL);

  useEffect(() => {
    const model = new CampaignBannerModel();

    setInputData(model.getData(deepCopy(props.fields) || {}));
  }, []);

  useDidUpdateEffect(() => {
    setValidString(
      Global.onFormatPromotionValidMessageHandler(
        SitecoreContextFactoryService.getValueContextRouteItem('fields')['Valid Message']?.value,
        inputData.startDate,
        inputData.endDate,
        countdownFormatSettingObj?.['CountdownFormatSetting']?.DateFormat
      )
    );
  }, [countdownFormatSettingObj]);

  return inputData && countdownFormatSettingObj ? (
    <div className={`campaign animated-slow fadeIn delay-100${isClientTelingUser ? ' --cp-view' : ''}`}>
      <div className={`container${isClientTelingUser ? ' tablet-container' : ''}`}>
        <h2 className='campaign__title'>{inputData.title}</h2>
        {validString ? <p className={isClientTelingUser ? 'campaign__label' : 'campaign__valid-text'}>{validString}</p> : <></>}
        {
          !isClientTelingUser ? (
            <p className='campaign__countdown'>
              <CountdownTimerCampaign
                endDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value || ''}
                endText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Soon Message']?.value || ''}
                remainingDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Time Remaining']?.value || ''}
                startDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value || ''}
                startText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Soon Message']?.value || ''}
                validText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Valid Message']?.value || ''}
                wasEndText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Was End Message']?.value || ''}
                countdownFormatSetting={countdownFormatSettingObj?.['CountdownFormatSetting']}
                componentType='CampaignBanner'
              />
            </p>
          ) : ''
        }
        {inputData.bannerImageSrc !== '' ? (
          inputData['clickHyperLink'].length ? (
            <a href={inputData['clickHyperLink']} className='campaign__image-link' target={inputData['generalLinkTarget']}>
              <img className='campaign__image' alt={inputData.title ? inputData.title : 'banner campaign image'} src={inputData.bannerImageSrc} />
            </a>
          ) : (
            <img className='campaign__image' alt={inputData.title ? inputData.title : 'banner campaign image'} src={inputData.bannerImageSrc} />
          )
        ) : null}
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='campaign skeleton__item'>
        <div className='container'>
          <h2 className='campaign__title'>
            <span className='text-line-heading-center'></span>
          </h2>
          <p className='campaign__valid-text'>
            <span className='text-line-center w-50'></span>
          </p>
          <div className='campaign__image image-wrap'>
            <span className='image-line'></span>
          </div>
        </div>
      </div>
    </LoadingShimmer>
  );
};

CampaignBanner.propTypes = {
  fields: PropTypes.object
};

export default CampaignBanner;
