import produce from 'immer';

import BOTradeInModel from '@components/BuyingOptions/BOTradeIn/model/BOTradeInModel';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as productTypes from '@redux/actions/product/productTypes';
import { PROMO_TRADEIN_TYPE, PROMO_TRADEIN_TYPE_LOYALTY } from '@utils/constant';
import { deepCopy } from '@utils/utility';

const initState = {
  tradeInList: [],
  tradeInActiveID: [],
  tradeInActive: null,
  tradeInSelectedLength: 0,
  isTradeInOptionsFlag: false,
  uploadedFile: [],
  isUploadingImage: false
};

function getTradeInByID(tradeInID, tradeInArray) {
  return tradeInArray.find((item) => item.Id === tradeInID);
}

const BOTradeInReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let newTradeInList = [];
    let newTradeInActive = null;

    switch (action.type) {
    case buyingOptionsTypes.UPLOAD_BO_TRADEIN_FILE:
      draft.isUploadingImage = true;
      break;

    case buyingOptionsTypes.GET_BO_DATA_SUCCESS:
      if (action.payload.ProductSummary?.TradeIn) {
        action.payload.ProductSummary.TradeIn.map((element) => {
          newTradeInList.push(new BOTradeInModel().getData(deepCopy(element) || {}));
        });

        draft.tradeInList = deepCopy(newTradeInList);
      }

      break;

    case buyingOptionsTypes.ADD_CHOSEN_BO_TRADEIN:
      // draft.tradeInActiveID = [action.payload];

      // draft.tradeInActive = getTradeInByID(action.payload, draft.tradeInList);
      draft.tradeInActive = { ...action.payload };

      draft.isTradeInOptionsFlag = true;
      // draft.tradeInSelectedLength = draft.tradeInActiveID.length;

      break;

    case buyingOptionsTypes.REMOVE_CHOSEN_BO_TRADEIN:
      draft.tradeInActiveID = [];

      draft.tradeInActive = null;

      draft.tradeInSelectedLength = draft.tradeInActiveID.length;

      draft.isTradeInOptionsFlag = false;
      break;

    case buyingOptionsTypes.CLEAR_IMAGE_OF_TRADE_IN_PRODUCTS:
      draft.isTradeInOptionsFlag = false;
      break;

    case buyingOptionsTypes.UPLOAD_BO_TRADEIN_FILE_SUCCESS:
      draft.uploadedFile = action.payload;

      draft.isUploadingImage = false;
      break;

    case buyingOptionsTypes.UPLOAD_BO_TRADEIN_FILE_FAILED:
      draft.isUploadingImage = false;
      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:
      newTradeInActive = action?.filteredCartLine[0]?.PromotionCartLines.find((promotionCartLine) => {
        return promotionCartLine.Type === PROMO_TRADEIN_TYPE || promotionCartLine.Type === PROMO_TRADEIN_TYPE_LOYALTY;
      });

      if (draft.tradeInList.length && newTradeInActive) {
        draft.tradeInActive = {
          values: {
            imageTradeInProduct: [...newTradeInActive?.TradeInImage],
            brand: newTradeInActive?.TradeInProductBrand,
            productName: newTradeInActive?.TradeInProductName,
            model: newTradeInActive?.TradeInProductModel,
            agreement: true
          },
          selectedTradeInProduct:
            draft.tradeInList &&
            draft.tradeInList.filter((item) => {
              return item.ProductId === newTradeInActive.ProductId;
            })[0],
          isSelectedTradeInOption: true,
          type: newTradeInActive.Type
        };

        draft.isTradeInOptionsFlag = true;

        draft.uploadedFile = newTradeInActive?.TradeInImage;
      }
    }

    return draft;
  });

export default BOTradeInReducer;
