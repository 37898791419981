import './PartnerSpecial.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import PartnerSpecialModel from './models/PartnerSpecialModel';
import PartPartnerSpecialDetailsSwiper from './PartnerSpecialDetailsSwiper';
import PartnerSpecicalModal from './PartnerSpecialModal';

const PartnerSpecial = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [isShowModal, setIsShowModal] = useState(false);
  const [partnerDataShow, setPartnerDataShow] = useState(null);

  useEffect(() => {
    let model = new PartnerSpecialModel();

    setLayoutData(model.getData(fields));
  }, []);

  const handleOpenModalPartner = (item) => {
    setIsShowModal(true);

    setPartnerDataShow(item);
  };

  const handleCloseModalPartner = () => {
    setIsShowModal(false);
  };

  return layoutData?.['ListPartnerSpecials']?.length ? (
    <div className='container my-reward__box partner-special' id={layoutData?.['Anchor ID']?.value}>
      <Text tag='h3' field={ layoutData?.['Title'] } className='my-reward__box-title' />
      <Text
        tag='h5'
        field={ layoutData?.['Description'] }
        className='partner-special__subtitle'
      />
      <div className='partner-special__details'>
        <PartPartnerSpecialDetailsSwiper handleOpenModalPartner={handleOpenModalPartner} partnerData={layoutData?.['ListPartnerSpecials']} />
      </div>
      <PartnerSpecicalModal 
        isShow={isShowModal}
        handleCloseModalPartner={handleCloseModalPartner}
        partnerData={partnerDataShow}
        layoutData={layoutData}
      />
      {layoutData?. ['View All Button Text']?.value && 
      (
        <div className='partner-special__cta'>
          <Link field={ layoutData?.['View All Button Link'] } className={`osim-account-form__form__btn__item btn btn-primary ${layoutData?.['View All Button Link']?.value?.href ? '' : 'disabled'}`}>
            <Text field={ layoutData?. ['View All Button Text'] } />
          </Link>
        </div>
      )}
    </div>
  ) : (
    <></>
  );
};

PartnerSpecial.propTypes = {
  fields: object
};

export default PartnerSpecial;
