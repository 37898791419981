import './PersonalProfileWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import LogoutComponent from '@components/SingleSignOn/LogoutComponent';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import { getUserFullName } from '../utils';
import PersonalProfileWidgetModel from './models/PersonalProfileWidgetModel';

const PersonalProfileWidget = ({ fields }) => {
  const [dataSources, setDataSources] = useState(null);
  const infoUser = useSelector((state) => state.singleSignOnReducer.userInfoReducer.accountUser) || null;
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  useEffect(() => {
    setDataSources(new PersonalProfileWidgetModel().getData(fields || {}));
  }, []);

  const userFullName = useMemo(() => getUserFullName(infoUser, settingGlobal?.FullNameFormat), [settingGlobal, infoUser]);


  return dataSources ? (
    <div className='my-account-section profile-widget'>
      <div className='my-account-section__heading my-account-section__group profile-widget__heading animated-slow fadeIn delay-100'>
        <h3 className='my-account-section__heading__title'>
          <Text field={dataSources['Title']} />
        </h3>
        {dataSources['Edit Label'].value ? (
          <Link className='my-account-section__btn-link' field={dataSources['Edit Link']}>
            <Text field={dataSources['Edit Label']} />
          </Link>
        ) : (
          <Link className='my-account-section__btn-link' field={dataSources['Edit Link']} />
        )}
      </div>
      <div className='my-account-section__content profile-widget__content animated-slow fadeIn delay-100'>
        {infoUser ? (
          <div className='profile-widget__content__info'>
            <h4 className='profile-widget__content__info__name'>{userFullName}</h4>
            <span className='profile-widget__content__info__email'>{infoUser.Email}</span>
          </div>
        ) : (
          ''
        )}
        <div className='profile-widget__content__setting'>
          {dataSources['Account Settings Label'].value ? (
            <Link className='profile-widget__content__setting__link' field={dataSources['Account Settings Link']}>
              <Text field={dataSources['Account Settings Label']} />
            </Link>
          ) : (
            <Link className='profile-widget__content__setting__link' field={dataSources['Account Settings Link']} />
          )}
          {dataSources['Newsletter Preference Label'].value ? (
            <Link className='profile-widget__content__setting__link' field={dataSources['Newsletter Preference Link']}>
              <Text field={dataSources['Newsletter Preference Label']} />
            </Link>
          ) : (
            <Link className='profile-widget__content__setting__link' field={dataSources['Newsletter Preference Link']} />
          )}
          <div className='profile-widget__content__setting__btn-signout'>
            <LogoutComponent>
              <Text field={dataSources['Sign Out Label']} />
            </LogoutComponent>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='my-account-section profile-widget skeleton__item'>
      <div className='my-account-section__heading'>
        <div className='my-account-section__group'>
          <span className='text-line'></span>
          <span className='clear-10'></span>
          <span className='text-line'></span>
        </div>
        <p className='my-account-section__heading__label transparent-wrap'>
          <span className='text-line'></span>
        </p>
      </div>
      <div className='my-account-section__content'>
        <div className='profile-widget__content__info'>
          <span className='text-line'></span>
          <span className='clear-10'></span>
          <span className='text-line'></span>
        </div>
        <div className='profile-widget__content__setting'>
          <span className='clear-10'></span>
          <span className='text-line'></span>
          <span className='clear-10'></span>
          <span className='text-line'></span>
          <span className='clear-15'></span>
          <span className='text-line-40-center w-50'></span>
        </div>
      </div>
    </div>
  );
};

PersonalProfileWidget.propTypes = {
  fields: PropTypes.object
};

export default PersonalProfileWidget;
