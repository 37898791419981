import * as myAddressTypes from './myAddressTypes';

export const getAddressDatasources = (payload) => ({
  type: myAddressTypes.GET_ADDRESS_DATASOURCES,
  payload
});

export const getAddressDatasourcesSuccess = (payload) => ({
  type: myAddressTypes.GET_ADDRESS_DATASOURCES_SUCCESS,
  payload
});

export const getAddressDatasourcesFailed = (error) => ({
  type: myAddressTypes.GET_ADDRESS_DATASOURCES_FAILED,
  error
});

export const clearAddressDatasources = (payload) => ({
  type: myAddressTypes.CLEAR_ADDRESS_DATASOURCES,
  payload
});

export const disableRequiredField = (payload) => ({
  type: myAddressTypes.DISABLE_REQUIRED_FIELD,
  payload
});
