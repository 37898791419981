import { ofType } from 'redux-observable';
import { switchMap } from 'rxjs/operators';

import { getNotificationFailure, getNotificationSuccess } from '@redux/actions/cpNotificationPopup/notificationData';
import { GET_NOTIFICATION_START } from '@redux/actions/cpNotificationPopup/notificationData/types';

import { fetchNotificationData } from './utils';

export const notificationDataEpic = (action$) =>
  action$.pipe(
    ofType(GET_NOTIFICATION_START),
    switchMap(async (action) => {
      const params = {
        page: action.payload?.page ?? 1,
        pageSize: action.payload?.pageSize ?? 10
      };

      const response = await new Promise((resolve) => {
        return fetchNotificationData(params).subscribe((response) => resolve(response));
      });

      if (response.StatusCode === 200 || response.Message === 'OK') {
        return getNotificationSuccess(response);
      } else {
        return getNotificationFailure(response || 'Failed');
      }
    })
  );
