import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

import { useDisplayFormikMessage } from '../../../hooks/useDisplayFormikMessage';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { useContactDetailFormLayoutContext } from '../FormLayout';

const PolicyAndTermCheckbox = () => {
  const { layoutData } = useScheduleAppointmentContext();
  const { formik } = useContactDetailFormLayoutContext();
  const displayErrorMessage = useDisplayFormikMessage();

  return !layoutData?.appointment ? (
    <>
      <div className='form-group'>
        <div className='osim-checkbox'>
          <input
            type='checkbox'
            id='agreePolicy'
            name='agreePolicy'
            className='osim-checkbox-input'
            readOnly
            defaultChecked={formik.values.agreePolicy}
            onChange={(event) => formik.setFieldValue('agreePolicy', event.target.checked)}
          />
          <RichText
            field={layoutData?.['Terms And Conditions Agreement Text']}
            tag='label'
            htmlFor='agreePolicy'
            className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'
          />
        </div>
        <span className='error-validate'>{displayErrorMessage(formik, 'agreePolicy')}</span>
      </div>

      <div className='form-group'>
        <div className='osim-checkbox'>
          <input
            type='checkbox'
            id='acceptPDDA'
            name='acceptPDDA'
            className='osim-checkbox-input'
            readOnly
            defaultChecked={formik.values.acceptPDDA}
            onChange={(event) => formik.setFieldValue('acceptPDDA', event.target.checked)}
          />
          <RichText
            field={layoutData?.['PDPA Copy And Terms Text']}
            tag='label'
            htmlFor='acceptPDDA'
            className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'
          />
        </div>
      </div>

      {/*<div className='form-group'>*/}
      {/*  <div className='osim-checkbox'>*/}
      {/*    <input*/}
      {/*      type='checkbox'*/}
      {/*      id='allowCreateAccount'*/}
      {/*      name='allowCreateAccount'*/}
      {/*      className='osim-checkbox-input'*/}
      {/*      readOnly*/}
      {/*      defaultChecked={formik.values.allowCreateAccount}*/}
      {/*      onChange={(event) => formik.setFieldValue('allowCreateAccount', event.target.checked)}*/}
      {/*    />*/}
      {/*    <RichText*/}
      {/*      field={layoutData?.['Create Osim Account Text']}*/}
      {/*      tag='label'*/}
      {/*      htmlFor='allowCreateAccount'*/}
      {/*      className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
    </>
  ) : (
    <></>
  );
};

export default PolicyAndTermCheckbox;
