import './BOAddOnDeal.scss';

import { any, func, object } from 'prop-types';
import React, { createContext, forwardRef, useContext, useEffect, useMemo, useState } from 'react';
import { Accordion } from 'react-bootstrap';
import { connect } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import AddOnDealProductList from './AddOnDealProductList';
import { initialStates } from './configs';
import { calcTotalListPrice, calcTotalSellingPrice } from './useAddOnDealReducer/actions';

export const ADD_ON_DEAL_ACCORDION_KEY = 'ADD_ON_DEAL_ACCORDION_KEY';
const BOAddOnDealContext = createContext(initialStates);

export const useBOAddOnDeal = () => useContext(BOAddOnDealContext);

const BOAddOnDeal = forwardRef(({ layoutData, addOnDealsData, setAddOnDealProductAdded, dispatchAddOnDealData, variantActive }, ref) => {
  // const addOnDealProductData = useSelector((state) => state.buyingOptionReducer.BOGetDataStatusReducer?.AddOnDeals);
  // const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const [addOnProduct, setAddOnProduct] = useState([]);

  const addOnDealsDataConverted = useMemo(() => {
    return addOnDealsData.length
      ? addOnDealsData
        .map((productItem) => ({
          ...productItem,
          MainItemConverted: productItem?.MainItem ? productItem.MainItem.split('|') : []
        }))
        .filter(({ MainItemConverted }) => MainItemConverted.includes(variantActive.VariantId) || !MainItemConverted?.[2])
      : [];
  }, [addOnDealsData, variantActive]);

  // useEffect(() => {
  //   if (addOnDealProductData?.length) {
  //     const lines = currentCart?.Lines || [];
  //
  //     for (const productItem of addOnDealProductData) {
  //       const checkProductExistOnCart = lines.find(
  //         (line) =>
  //           line.ProductId === productItem.ProductId && line.VariantId === productItem.VariantId && line.ProductPageId === productItem.ProductPageId
  //       );
  //       if (checkProductExistOnCart) setAddOnProduct((prev) => [...prev, productItem]);
  //     }
  //   }
  // }, [currentCart]);
  useEffect(() => {
    let productTotalSellPrice = 0;
    let productTotalListPrice = 0;
    if (addOnDealsDataConverted.length && addOnProduct.length) {
      for (const productItem of addOnProduct) {
        productTotalSellPrice += productItem.SellPrice;

        productTotalListPrice += productItem.ListPrice;
      }
    }

    dispatchAddOnDealData(calcTotalListPrice(productTotalListPrice));

    dispatchAddOnDealData(calcTotalSellingPrice(productTotalSellPrice));
  }, [addOnProduct, addOnDealsDataConverted]);

  useEffect(() => {
    // INFO: remove current add on deal value when selected product does not have add on deal product data
    if (!addOnDealsDataConverted.length) setAddOnDealProductAdded([]);
  }, [addOnDealsDataConverted]);

  return addOnDealsDataConverted?.length ? (
    <BOAddOnDealContext.Provider
      value={{
        addOnProduct,
        setAddOnProduct,
        layoutData,
        setAddOnDealProductAdded,
        dispatchAddOnDealData
      }}
    >
      <div ref={ref} className='variant-buying add-on-deal buying-option__group-info__line-bottom'>
        <Accordion activeKey={ADD_ON_DEAL_ACCORDION_KEY}>
          <div className='accordion-buying-option'>
            <div className='accordion-buying-option__header'>
              <Text tag='h3' field={layoutData?.['Add On Deal Label']} className='bo-title-section' />
            </div>
            <Accordion.Collapse eventKey={ADD_ON_DEAL_ACCORDION_KEY}>
              <AddOnDealProductList addOnDealsData={addOnDealsDataConverted} />
            </Accordion.Collapse>
          </div>
        </Accordion>
      </div>
    </BOAddOnDealContext.Provider>
  ) : (
    <></>
  );
});

BOAddOnDeal.displayName = 'BOAddOnDeal';

BOAddOnDeal.propTypes = {
  addOnDealsData: any,
  layoutData: object,
  setAddOnDealProductAdded: func,
  dispatchAddOnDealData: func,
  variantActive: object
};

const mapStateToProps = (state) => ({
  addOnDealsData: state.buyingOptionReducer.BOGetDataStatusReducer?.AddOnDeals,
  variantActive: state.buyingOptionReducer.BOVariantsReducer?.variantActive
});

export default connect(mapStateToProps)(BOAddOnDeal);
