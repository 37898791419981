import './Button.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ButtonModel from './model/ButtonModel';

const Button = (props) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const model = new ButtonModel();

    setInputData(model.getData(deepCopy(props.fields) || {}));
  }, []);

  return (
    inputData && (
      <div className='button-component text-center'>
        <Link field={inputData['Url Button']} className='btn btn-outline-CTA2'>
          <Text tag='span' field={inputData['Label Button']} />
        </Link>
      </div>
    )
  );
};

Button.propTypes = {
  fields: PropTypes.object
};

export default Button;
