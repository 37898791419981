import PriceModel from '@components/BundleElements/model/PriceModel';

export default class RefundableDepositProductsModel {
  constructor() {
    this['Price'] = new PriceModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    
    return this;
  }
}
