export const changeActiveItem = (array, activeId, key) => {
  let newArray;
  
  newArray = array.map(item => {
    if (item[key] === activeId) {
      return {...item, active: true};
    } else {
      return {...item, active: false};
    }
  });
  
  return newArray;
};

export const findMaxValue = (array) => {
  let newArr = [];

  const minPrice = Math.min(...array.map(item => item?.Price.SellingPrice));

  newArr = array.filter(item =>
    item?.Price.SellingPrice === minPrice);
  
  return newArr[0];
};

export const checkPassValidate = (array, freeGiftValid, data) => {
  const onCheckAdditionalOptions = (data) => {
    return data.every((options) => {
      return options.AdditionalOptions.every((additionalOption) => {
        return additionalOption.Products.some((option) => {
          return option.IsChoseAdditionalOption;
        });
      });
    });
  };

  let checker = array.every(item => item.isProductPass === true) && freeGiftValid && onCheckAdditionalOptions(data);
  
  return checker;
};

export const sumTotal = (array, key, isUseActiveKey = false) => {
  return  array.filter((item) => (isUseActiveKey ? item?.active : item !== null)).reduce((sum, { Price }) => sum + Price[key], 0);
};

export const combinedItems = (items) => {
  let combinedItems = [];

  if (items.length) {
    combinedItems = items.reduce((arr, item) => {
      let found = false;
  
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].VariantId === item.VariantId) {
          found = true;
  
          arr[i].count++;
        }
      }
      if (!found) {
        item['count'] = 1;
  
        arr.push(item);
      }
  
      return arr;
    }, []);
  }

  return combinedItems;
};