import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';

import EmailExistNotiModal from './EmailExistNotiModal';
import EnterOTPForm from './EnterOTPForm';
import UserInfoForm from './UserInfoForm';

const RegisterEventForm = () => {
  const getOtp = useSelector((state) => state.campaignRegisterReducer.getOtpCodeReducer);
  const isCheckingEmailExist = useSelector((state) => state.checkoutProcesstReducer.accountCheckingReducer.isLoading);

  const isGetOtpSuccess = useMemo(() => {
    return getOtp.getOtpCodeResult?.Result === 'Success' || getOtp.getOtpCodeResult?.StatusCode === 0;
  }, [getOtp.getOtpCodeResult]);

  return (
    <>
      <UserInfoForm isGetOtpSuccess={isGetOtpSuccess} />
      {isGetOtpSuccess ? <EnterOTPForm /> : <></>}
      {getOtp.isLoading || isCheckingEmailExist ? <LoadingMask /> : <></>}
      <EmailExistNotiModal />
    </>
  );
};

export default RegisterEventForm;
