import produce from 'immer';

import * as dynamicBundleTypes from '@redux/actions/dynamicBundle/dynamicBundleTypes';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

const initState = {
  listingPrice: '',
  sellingPrice: '-',
  allowBuy: false,
  buyOptionLink: '',
  initialListProduct: {},
  calculateBundleQuantity: {},
  fixedPrice: false,
  productRows: 0,
  originalSellingPrice :0,
  currency: {},
};

const calculateBundlePrice = (data) => {
  let isValid = true;
  let listingPrice = 0;
  let sellingPrice = 0;
  let buyOptionLink = '';

  data.forEach(item => {
    if (!item) {
      isValid = false;
    }
    if (item) {
      listingPrice += item.Price.ListPrice;

      sellingPrice += item.Price.SellingPrice;
    }
  });

  if (isValid) {
    const bundlePageId = SitecoreContextFactoryService.getValueContextRouteItem('itemId');

    const listSelectedId =  data.map(s => s.ProductId).join('|');
    let freeItem = data[0];

    data.forEach(item => {
      if (item.Price.SellingPrice < freeItem.Price.SellingPrice) {
        freeItem = item;
      }
    });

    sellingPrice -= freeItem.Price.SellingPrice;

    buyOptionLink = `?bundlePageId=${bundlePageId}&selectedBundleItemIds=${listSelectedId}`;
  }

  return {
    listingPrice,
    sellingPrice,
    isValid,
    buyOptionLink
  };
};

const dynamicBundleReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case dynamicBundleTypes.INIT_DYNAMIC_BUNDLE: {
      const dynamicBundleData = action.payload['Dynamic Bundle Data'];
      const currency = action.payload['Currency'];
      if(dynamicBundleData?.DynamicBundleOption){

        draft.fixedPrice = dynamicBundleData.DynamicBundleOption.IsFixedPrice;

        draft.productRows = dynamicBundleData.DynamicBundleOption.ProductRows;
        
        draft.originalSellingPrice = dynamicBundleData.SellingPrice;

        draft.currency = currency;
      }
      else draft.productRows = 3;
      break;
    }

    case dynamicBundleTypes.SET_LIST_QUANTITY_BUNDLE:
      draft.initialListProduct = action.payload;

      draft.calculateBundleQuantity = action.payload;

      break;

    case dynamicBundleTypes.UPDATE_QUANTITY_BUNDLE:
      draft.calculateBundleQuantity = deepCopy(state.initialListProduct);

      action.payload.forEach(item => {
        if (item) {
          draft.calculateBundleQuantity[item.ProductId] -= 1;
        }
      });
      break;

    case dynamicBundleTypes.SET_DYNAMIC_BUNDLE_PRICE: {
      const bundlePriceCalculation = calculateBundlePrice(action.payload);

      draft.allowBuy = bundlePriceCalculation.isValid;

      draft.buyOptionLink = bundlePriceCalculation.buyOptionLink;


      if(!draft.fixedPrice){
        draft.listingPrice = calculateBundlePrice(action.payload).listingPrice;

        draft.sellingPrice = calculateBundlePrice(action.payload).sellingPrice;
      }
      else if(draft.allowBuy){
        draft.listingPrice = calculateBundlePrice(action.payload).listingPrice;

        draft.sellingPrice = draft.originalSellingPrice || 0;
      }
      else{
        draft.listingPrice = 0;
        
        draft.sellingPrice = 0;
      }
      break;
    }
    }

    return draft;
  });

export default dynamicBundleReducer;