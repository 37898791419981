import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { cancelEventBookingFailed, cancelEventBookingSuccess } from '@redux/actions/eventBooking';
import { CANCEL_EVENT_BOOKING_START } from '@redux/actions/eventBooking/cancelEventBooking/cancelEventBookingTypes';
import { postAsObservable } from '@services/genericService';

import { CANCEL_EVENT_BOOKING_ENDPOINT } from './config';

const cancelEventBookingEpic = (action$) =>
  action$.pipe(
    ofType(CANCEL_EVENT_BOOKING_START),
    switchMap((action) => {
      return postAsObservable(CANCEL_EVENT_BOOKING_ENDPOINT, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            return cancelEventBookingSuccess(res.data);
          } else {
            return cancelEventBookingFailed(res.data?.Message?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(cancelEventBookingFailed(error || 'Failed')))
      );
    })
  );

export default cancelEventBookingEpic;
