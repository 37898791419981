import './TimelinePanel.scss';

import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { Image, RichText,Text } from '@sitecore-jss/sitecore-jss-react';

import { deepCopy } from '../../../../../utils/utility';
import TimeLinePanelItemModel from './model/TimeLinePanelItemModel';
import TimeLinePanelLayoutModel from './model/TimeLinePanelLayoutModel';

const TimelinePanel = ({ fields }) => {

  const [layoutData, setLayoutData] = useState(null);
  
  useEffect(() => {
    const model = new TimeLinePanelLayoutModel();

    setLayoutData(model.getData(fields));
  }, []);

  const params = {
    slidesPerView: 1.6,
    watchOverflow: true,
    breakpoints: {
      768: {
        slidesPerView: 3
      },

      992: {
        slidesPerView: 4
      },

      1200: {
        slidesPerView: 6
      }
    },

    shouldSwiperUpdate: true
  };
  const timeLineItems = fields.items || [];

  const getYear = (date) => {
    return {
      value: new Date(date).getFullYear()
    };
  };

  return layoutData && (
    <div className='timeline-panel'>
      <div className='timeline-panel__heading os-section-heading'>
        <div className='container text-center'>
          <h2 className='timeline-panel__heading__title os-section-heading__title experience-title'><Text field={layoutData['Title']} /></h2>
          <p className='timeline-panel__heading__desc os-section-heading__desc'><RichText field={layoutData['Description']} /></p>
        </div>
      </div>
      <div className='timeline-panel__content'>
        <div className='timeline-panel__list'>
          <SwipeComponent param={params}>
            {
              timeLineItems.length ? timeLineItems.map((time, index) => {
                const validationModel = new TimeLinePanelItemModel();
                const inputData = validationModel.getData(deepCopy(time.fields || {}));

                return (
                  <div className='timeline-panel__item' key={index}>
                    <div className='timeline-panel__item__top'>
                      <div className='timeline-panel__item__image'>
                        <Image media={inputData.Image} />
                      </div>
                      <Text field={inputData.Description} className='timeline-panel__item__des' tag='p' />
                    </div>
                    <div className='timeline-panel__item__line'>
                      <div className='timeline-panel-icon'>
                        <div className='timeline-panel-icon__item'></div>
                        <Text field={getYear(inputData.Milestone.value)} className='timeline-panel__item__year' tag='div' />
                      </div>
                    </div>
                    <div className='timeline-panel__item__bottom'>
                      <div className='timeline-panel__item__image'><Image media={inputData.Image} /></div>
                      <Text field={inputData.Description} className='timeline-panel__item__des' tag='p' />
                    </div>
                  </div>
                );
              }) : null
            }
          </SwipeComponent>
        </div>
      </div>
    </div>
  );
};

TimelinePanel.propTypes = {
  fields: PropTypes.any
};

export default TimelinePanel;
