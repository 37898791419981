import './CartItemFreeGift.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { BALANCE_FREEGIFT_TYPE, DEPOSIT_FREEGIFT_TYPE, PROMO_FREEGIFT_TYPE } from '@utils/constant';

const CartItemFreeGift = ({ freeGiftNumbers, promotionFreeGifts, dataSources, isInDeliveryPage = false }) => {
  const freeGiftTypes = useMemo(() => {
    return [DEPOSIT_FREEGIFT_TYPE, BALANCE_FREEGIFT_TYPE, PROMO_FREEGIFT_TYPE];
  }, []);

  const isShowFreeGift = useMemo(() => {
    return promotionFreeGifts.some((freeGift) => freeGiftTypes.includes(freeGift.Type));
  }, [promotionFreeGifts, freeGiftTypes]);

  const handleGenerateFreeGiftText = useCallback(
    (freeGiftItem) => {
      const freeGiftItemType = freeGiftItem?.Type;
      const freeGiftDisplayName = freeGiftItem?.DisplayName || '';
      const depositProductFreeGiftTypes = [DEPOSIT_FREEGIFT_TYPE, BALANCE_FREEGIFT_TYPE];
      let freeGiftText = freeGiftDisplayName;

      if (depositProductFreeGiftTypes.includes(freeGiftItemType)) {
        const depositGiftLabel = dataSources?.['Deposit Gift']?.value || '';
        const balanceGiftLabel = dataSources?.['Balance Gift']?.value || '';

        if (freeGiftItemType === DEPOSIT_FREEGIFT_TYPE) freeGiftText = depositGiftLabel + ' ' + freeGiftDisplayName;
        if (freeGiftItemType === BALANCE_FREEGIFT_TYPE) freeGiftText = balanceGiftLabel + ' ' + freeGiftDisplayName;
      }

      return freeGiftText;
    },
    [dataSources]
  );

  return isShowFreeGift ? (
    <div className={['cart-item-free-gift', isInDeliveryPage ? 'pseudo-border' : ''].join(' ')}>
      <div className='cart-item-free-gift__header'>
        <div className='cart-item-free-gift__header__icon'>
          <div className='product-info-icon product-info-icon--free-gift'></div>
        </div>
        <div className='justify-space-between cart-item-free-gift__header__contents'>
          <p>
            <Text field={dataSources['Free Gift Label']} /> {+freeGiftNumbers > 1 ? <span>{`x ${freeGiftNumbers}`}</span> : ''}
          </p>
          <span className='cart-item-free-gift__price'>
            <Text field={dataSources['Free Text']} />
          </span>
        </div>
      </div>

      <div className='cart-item-free-gift__body small-text'>
        {promotionFreeGifts.map((freeGiftItem, freeGiftItemIdx) =>
          freeGiftTypes.includes(freeGiftItem.Type) ? <p key={freeGiftItemIdx}>{handleGenerateFreeGiftText(freeGiftItem)}</p> : <></>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

CartItemFreeGift.propTypes = {
  promotionFreeGifts: PropTypes.array,
  dataSources: PropTypes.object,
  freeGiftNumbers: PropTypes.string,
  isInDeliveryPage: PropTypes.bool
};

export default CartItemFreeGift;
