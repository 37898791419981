import './CartHeadInfo.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const CartHeadInfo = (props) => {
  const { dataSources, getCurentCartData } = props;

  return (
    <div className='cart-head-info'>
      <div className='container cart-head-info__container'>
        <h4 className='cart-head-info__heading'>
          <Text field={dataSources.Title} />
        </h4>
        <div className='cart-head-info__infor'>
          <Text className='cart-head-info__infor__label' tag='span' field={dataSources['Cart Total Amount Label']} />
          <span className='cart-head-info__infor__price'>{getCurentCartData.Total}</span>
          <span className='cart-head-info__infor__quantity'>
            ({getCurentCartData.Lines.length} {getCurentCartData.Lines.length > 1 ? 'items' : 'item'})
          </span>
        </div>
        <div className='cart-head-info__button'>
          {dataSources['Checkout Link'].Title.value ? (
            <Link className='cart-head-info__button__item btn btn-primary' field={dataSources['Checkout Link'].Link}>
              <Text field={dataSources['Checkout Link'].Title} />
            </Link>
          ) : (
            <Link className='cart-head-info__button__item btn btn-primary' field={dataSources['Checkout Link'].Link} />
          )}
        </div>
        {dataSources['Checkout Link'].Title.value ? (
          <Link className='cart-head-info__link btn-link' field={dataSources['Additional Link'].Link}>
            <Text field={dataSources['Additional Link'].Title} />
          </Link>
        ) : (
          <Link className='cart-head-info__link btn-link' field={dataSources['Additional Link'].Link} />
        )}
      </div>
    </div>
  );
};

CartHeadInfo.propTypes = {
  dataFromLayout: PropTypes.object,
  getCurentCartData: PropTypes.object,
  dataSources: PropTypes.object
};

export default CartHeadInfo;
