import { combineEpics } from 'redux-observable';

import cpAppointmentDetailEpic from './cpAppointmentDetail';
import {getAppointmentListingEpic} from './cpAppointmentListing';
import { cpGetStoreListEpic } from './cpGetStoreList';
import {
  cpManagerLeadManagementEpic, 
  cpStaffLeadManagementEpic, 
  getSaleStaffsEpic
} from './cpLeadManagement';
import cpShareProductEpic from './cpShareProduct';
import cpStartDemoEpic from './cpStartDemo';
import cpStoreSelectorEpic from './cpStoreSelector';
import cpUpdateAppointmentEpic from './cpUpdateAppointment';
import cpSendSaleOrderEpic from './cpSendSaleOrder';

const clienttelingPortal = combineEpics(
  cpStoreSelectorEpic,
  cpStartDemoEpic,
  cpStaffLeadManagementEpic,
  cpManagerLeadManagementEpic,
  cpUpdateAppointmentEpic,
  cpAppointmentDetailEpic,
  cpGetStoreListEpic,
  cpShareProductEpic,
  getAppointmentListingEpic,
  getSaleStaffsEpic,
  cpSendSaleOrderEpic
);

export default clienttelingPortal;