import './ReviewMediaPopup.scss';

import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import Global from '@utils/global';

import ReviewStarRating from '../ReviewStarRating';

const ReviewMediaPopup = ({ mediaData, index, reviewItem }) => {
  const [thumbnailActive, setThumbnailActive] = useState(index);
  const swipePreviewRef = useRef(null);

  const reviewSubmitDate = new Date(Number.parseInt(reviewItem?.ReviewSubmitDate?.replace(/Date|\(|\/|\)/gi, '')));
  const convertReviewSubmitDate = Global.customDate(reviewSubmitDate);

  const swipePreviewParams = {
    slidesPerView: 1,
    initialSlide: index,
    spaceBetween: 0,
    loop: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    navigation: {
      nextEl: `${mediaData?.length <= 1 ? 'swiper-button-hidden' : '.swiper-button-next'}`,
      prevEl: `${mediaData?.length <= 1 ? 'swiper-button-hidden' : '.swiper-button-prev'}`
    },
    renderPrevButton: () => (
      <div
        className={`${mediaData?.length <= 1 ? 'swiper-button-hidden' : 'swiper-button-prev'}`}
        onClick={() => (thumbnailActive === 0 ? setThumbnailActive(mediaData.length - 1) : setThumbnailActive((prevState) => prevState - 1))}
      />
    ),
    renderNextButton: () => (
      <div
        className={`${mediaData?.length <= 1 ? 'swiper-button-hidden' : 'swiper-button-next'}`}
        onClick={() => (thumbnailActive === mediaData.length - 1 ? setThumbnailActive(0) : setThumbnailActive((prevState) => prevState + 1))}
      />
    ),
    on: {
      slideChange: () => {
        //INFO: This code will run after slide change
        const getSlideActive = swipePreviewRef.current.querySelector('.swiper-slide-active');
        const getVideoActive = getSlideActive?.querySelector('video');
        if (getVideoActive) {
          getVideoActive.pause();

          getVideoActive.currentTime = 0;
        }
      },
      slideChangeTransitionEnd: () => {
        const slideActiveIndex = swipePreviewRef.current.swiper.realIndex;

        setThumbnailActive(slideActiveIndex);
      }
    },
  };


  const activeThumbnailItem = (index) => {
    if(thumbnailActive === index) {
      return ' active';
    }

    return '';
  };

  return (
    <div className='review-media-popup__content'>
      <div className='review-media-popup__content__info-desktop'>
        <div className='review-media-popup__content__rating'>
          <ReviewStarRating disableRating defaultRate={reviewItem?.ReviewRate} />
        </div>
        <h3 className='review-media-popup__content__review-title'>{reviewItem?.ReviewTitle}</h3>
        <div className='review-media-popup__content__info product-review-content__info'>
          <span className='product-review-content__info__author'>{reviewItem?.ReviewerName}</span>
          <span className='product-review-content__info__date'>{convertReviewSubmitDate}</span>
        </div>
      </div>
      <div className='review-media-popup__content__media-slide'>
        <div className='review-media-popup__content__media-slide__preview'>
          <SwipeComponent param={swipePreviewParams} setRef={swipePreviewRef}>
            {mediaData?.map((mediaItem, index) => {
              return (
                <div className='media-slide-item' key={index}>
                  {mediaItem.ContentType === 'image' ? 
                    <img src={mediaItem.Data} alt='x' />
                    : 
                    <video playsInline={true} muted='muted' controls>
                      <source src={mediaItem.Data} type={`video/${Global.videoType(mediaItem.Data)}`}/>
                    </video>
                  }
                </div>
              );
            })}
          </SwipeComponent>
        </div>
        <div className='review-media-popup__content__media-slide__thumbnail'>
          {mediaData?.map((mediaItem, index) => {
            return (
              <div className={`media-thumnail-item${activeThumbnailItem(index)} ${mediaItem.type === 'video' ? ' media-item--video-type' : ''}`} key={index} onClick={() => {
                setThumbnailActive(index);

                if (swipePreviewRef.current) {
                  swipePreviewRef.current.swiper.slideTo(index + 1, 200, false);
                }
              }}>
                {mediaItem.ContentType === 'image' ? 
                  <img src={mediaItem.Data} alt='x' />
                  : 
                  <video playsInline={true} muted='muted'>
                    <source src={mediaItem.Data} type={`video/${Global.videoType(mediaItem.Data)}`}/>
                  </video>
                }
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

ReviewMediaPopup.propTypes = {
  mediaData: PropTypes.any,
  index: PropTypes.number,
  reviewItem: PropTypes.any
};

export default ReviewMediaPopup;