import BooleanModel from '@components/PopupBanner/model/BooleanModel';
import SignleSignOnModel from '@components/SingleSignOn/models/SignleSignOnModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class RegisterModel {
  constructor() {
    this['Register Introduction'] = new SitecoreTextModel();

    this['Register First Name Label'] = new SitecoreTextModel();

    this['Register Last Name Label'] = new SitecoreTextModel();

    this['Register Phone Label'] = new SitecoreTextModel();

    this['Register Email Label'] = new SitecoreTextModel();

    this['Register Button Label'] = new SitecoreTextModel();

    this['Login Button Text'] = new SitecoreTextModel();

    this['Register Tab Label'] = new SitecoreTextModel();

    this['Register Password Label'] = new SitecoreTextModel();

    this['Register Switch To Login Text'] = new SitecoreTextModel();

    this['Register PDPA confirmation Label'] = new SitecoreTextModel();

    this['Register Subscription Label'] = new SitecoreTextModel();

    this['Facebook Login Settings'] = new SignleSignOnModel();

    this['Google Login Settings'] = new SignleSignOnModel();

    this['Enable Subscription'] = new BooleanModel();

    this['Subscription Description'] = new SitecoreTextModel();

    this['SMS Subscription Label'] = new SitecoreTextModel();

    this['Email Subscription Label'] = new SitecoreTextModel();

    this['Direct Mailing Subscription Label'] = new SitecoreTextModel();

    this['Register DoB Label'] = new SitecoreTextModel();

    this['Register Email Confirmation Label'] = new SitecoreTextModel();

    this['Register Email Confirmation Required Message'] = new SitecoreTextModel();

    this['Register Email Confirmation Not Match Message'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Facebook Login Settings')) {
      this['Facebook Login Settings'].getData(dataSource['Facebook Login Settings']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Google Login Settings')) {
      this['Google Login Settings'].getData(dataSource['Google Login Settings']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Introduction')) {
      this['Register Introduction'].getData(dataSource['Register Introduction']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register First Name Label')) {
      this['Register First Name Label'].getData(dataSource['Register First Name Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Last Name Label')) {
      this['Register Last Name Label'].getData(dataSource['Register Last Name Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Phone Label')) {
      this['Register Phone Label'].getData(dataSource['Register Phone Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Email Label')) {
      this['Register Email Label'].getData(dataSource['Register Email Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Button Label')) {
      this['Register Button Label'].getData(dataSource['Register Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Button Text')) {
      this['Login Button Text'].getData(dataSource['Login Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Password Label')) {
      this['Register Password Label'].getData(dataSource['Register Password Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Tab Label')) {
      this['Register Tab Label'].getData(dataSource['Register Tab Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Tab Label')) {
      this['Register Switch To Login Text'].getData(dataSource['Register Switch To Login Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register PDPA confirmation Label')) {
      this['Register PDPA confirmation Label'].getData(dataSource['Register PDPA confirmation Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Subscription Label')) {
      this['Register Subscription Label'].getData(dataSource['Register Subscription Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Enable Subscription')) {
      this['Enable Subscription'].getData(dataSource['Enable Subscription']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Subscription Description')) {
      this['Subscription Description'].getData(dataSource['Subscription Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SMS Subscription Label')) {
      this['SMS Subscription Label'].getData(dataSource['SMS Subscription Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Email Subscription Label')) {
      this['Email Subscription Label'].getData(dataSource['Email Subscription Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Direct Mailing Subscription Label')) {
      this['Direct Mailing Subscription Label'].getData(dataSource['Direct Mailing Subscription Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register DoB Label')) {
      this['Register DoB Label'].getData(dataSource['Register DoB Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Email Confirmation Label')) {
      this['Register Email Confirmation Label'].getData(dataSource['Register Email Confirmation Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Email Confirmation Required Message')) {
      this['Register Email Confirmation Required Message'].getData(dataSource['Register Email Confirmation Required Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Email Confirmation Not Match Message')) {
      this['Register Email Confirmation Not Match Message'].getData(dataSource['Register Email Confirmation Not Match Message']);
    }

    return this;
  }
}
