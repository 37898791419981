import { element } from 'prop-types';
import React from 'react'; // import { useGenerateDownloadProductLink } from './hooks';

// import { useGenerateDownloadProductLink } from './hooks';

const DownloadButton = ({ children, ...props }) => {
  // const downloadFileLink = useGenerateDownloadProductLink();

  return <a {...props}>{children}</a>;
};

DownloadButton.propTypes = {
  children: element
};

export default DownloadButton;
