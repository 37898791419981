import { REGISTER_EVENT_BOOKING_FAILED, REGISTER_EVENT_BOOKING_START, REGISTER_EVENT_BOOKING_SUCCESS } from './eventBookingRegisterTypes';

export const registerEventBookingStart = (payload) => ({
  type: REGISTER_EVENT_BOOKING_START,
  payload
});

export const registerEventBookingSuccess = (payload) => ({
  type: REGISTER_EVENT_BOOKING_SUCCESS,
  payload
});

export const registerEventBookingFailed = (payload) => ({
  type: REGISTER_EVENT_BOOKING_FAILED,
  payload
});
