import { GET_TOTAL_BOOKED_SLOTS_FAILURE, GET_TOTAL_BOOKED_SLOTS_START, GET_TOTAL_BOOKED_SLOTS_SUCCESS } from './types';

export const getTotalBookedSlotsStart = (payload) => ({
  type: GET_TOTAL_BOOKED_SLOTS_START,
  payload
});

export const getTotalBookedSlotsSuccess = (payload) => ({
  type: GET_TOTAL_BOOKED_SLOTS_SUCCESS,
  payload
});

export const getTotalBookedSlotsFailure = (payload) => ({
  type: GET_TOTAL_BOOKED_SLOTS_FAILURE,
  payload
});
