import { any } from 'prop-types';
import React from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import useSelectStoreAndDateContext from '../useSelectStoreAndDateContext';
import CreateAccountCheckbox from './CreateAccountCheckbox';

const PolicyCheckbox = ({ layoutData }) => {
  const { formik, isDisableFormSection } = useSelectStoreAndDateContext();

  return (
    <div className='select-store__policy'>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <div className='form-group'>
            <div className='osim-checkbox'>
              <input
                type='checkbox'
                id='agree'
                name='agree'
                className='osim-checkbox-input'
                readOnly
                defaultChecked={formik.values.agreePolicy}
                onChange={(event) => formik.setFieldValue('agreePolicy', event.target.checked)}
                disabled={isDisableFormSection}
              />
              <RichText
                field={layoutData?.termsAndConditionsAgreement}
                tag='label'
                htmlFor='agree'
                className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'
              />
            </div>
            {formik.errors.agreePolicy && formik.touched.agreePolicy && <span className='error-validate'>{formik.errors.agreePolicy}</span>}
          </div>
          <div className='form-group'>
            <div className='osim-checkbox'>
              <input
                type='checkbox'
                id='receiveBooking'
                name='receiveBooking'
                className='osim-checkbox-input'
                readOnly
                defaultChecked={formik.values.allowReceiveConfirmSMS}
                disabled
              />
              <Text
                field={layoutData?.smsConfirmationOptionText}
                tag='label'
                htmlFor='receiveBooking'
                className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'
              />
            </div>
          </div>
          <CreateAccountCheckbox checkboxText={layoutData?.createOsimAccountOptionText} />
          <div className='select-store__policy__option'>
            <Text field={layoutData?.receivingMarketingInformationText} />
            <div className='select-store__policy__option__list'>
              <div className='form-group'>
                <div className='osim-checkbox'>
                  <input
                    type='checkbox'
                    id={layoutData?.smsMarketingChannelLabel.value}
                    name={layoutData?.smsMarketingChannelLabel.value}
                    className='osim-checkbox-input'
                    checked={formik.values.allowReceiveMarketingSMS || false}
                    onChange={(event) => formik.setFieldValue('allowReceiveMarketingSMS', event.target.checked)}
                    disabled={isDisableFormSection}
                  />
                  <Text
                    field={layoutData?.smsMarketingChannelLabel}
                    tag='label'
                    htmlFor={layoutData?.smsMarketingChannelLabel.value}
                    className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='osim-checkbox'>
                  <input
                    type='checkbox'
                    id={layoutData?.emailMarketingChannelLabel.value}
                    name={layoutData?.emailMarketingChannelLabel.value}
                    className='osim-checkbox-input'
                    checked={formik.values.allowReceiveMarketingEmail || false}
                    onChange={(event) => formik.setFieldValue('allowReceiveMarketingEmail', event.target.checked)}
                    disabled={isDisableFormSection}
                  />
                  <Text
                    field={layoutData?.emailMarketingChannelLabel}
                    tag='label'
                    htmlFor={layoutData?.emailMarketingChannelLabel.value}
                    className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'
                  />
                </div>
              </div>
              <div className='form-group'>
                <div className='osim-checkbox'>
                  <input
                    type='checkbox'
                    id={layoutData?.directMailingMarketingChannelLabel.value}
                    name={layoutData?.directMailingMarketingChannelLabel.value}
                    className='osim-checkbox-input'
                    checked={formik.values.allowReceiveMarketingDirectMail || false}
                    onChange={(event) => formik.setFieldValue('allowReceiveMarketingDirectMail', event.target.checked)}
                    disabled={isDisableFormSection}
                  />
                  <Text
                    field={layoutData?.directMailingMarketingChannelLabel}
                    tag='label'
                    htmlFor={layoutData?.directMailingMarketingChannelLabel.value}
                    className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PolicyCheckbox.propTypes = {
  layoutData: any
};

export default PolicyCheckbox;
