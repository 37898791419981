import PropTypes from 'prop-types';
import React from 'react';

import { Image, Text} from '@sitecore-jss/sitecore-jss-react';

const Deminsion = ({ dataSources, isViewTitle }) => {
  return !isViewTitle ? (
    <div className='product-specification__collapse__content'>
      <div className='product-specification__image-list'>
        <div className='product-specification__image-list__item'>
          <Image field={dataSources['Chair Dimension']['Chair Dimension Image']} />
        </div>
      </div>
    </div>
  ) : <Text field={dataSources['Chair Dimension']['Chair Dimension Title Text']}/>;
};

Deminsion.propTypes = {
  dataSources: PropTypes.object,
  isViewTitle: PropTypes.bool,
};

export default Deminsion;
