import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class AppListModel {
  constructor() {
    this['App Url'] = new SitecoreTextModel();

    this['CTA Image'] = new SitecoreImageModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'App Url')) {
      this['App Url'].getData(dataSource['App Url']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Image')) {
      this['CTA Image'].getData(dataSource['CTA Image']);
    }

    return this;
  }
}
