import { CHECK_CONTACT_DUPLICATE_FAILURE, CHECK_CONTACT_DUPLICATE_START, CHECK_CONTACT_DUPLICATE_SUCCESS } from './types';

export const checkContactDuplicateStart = (payload) => ({
  type: CHECK_CONTACT_DUPLICATE_START,
  payload
});

export const checkContactDuplicateSuccess = (payload) => ({
  type: CHECK_CONTACT_DUPLICATE_SUCCESS,
  payload
});

export const checkContactDuplicateFailure = (payload) => ({
  type: CHECK_CONTACT_DUPLICATE_FAILURE,
  payload
});
