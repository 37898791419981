import React from 'react';
import { Popover } from 'react-bootstrap';

const PopoverItem = (popoverTitle, popoverContent, headerBgColor, headerTextColor, bodyBgColor, bodyTextColor) => {
  return (
    <Popover id='popover-basic'>
      <Popover.Title
        as='h3'
        className='popover-item__title'
        style={{
          backgroundColor: headerBgColor ? `#${headerBgColor?.replace('#', '')}` : '#f0f0f0',
          color: headerTextColor ? `#${headerTextColor?.replace('#', '')}` : '#2a2a2a'
        }}
      >
        {popoverTitle}
      </Popover.Title>
      <Popover.Content
        className='popover-item__content'
        dangerouslySetInnerHTML={{ __html: popoverContent }}
        style={{
          backgroundColor: bodyBgColor ? `#${bodyBgColor?.replace('#', '')}` : '#ffffff',
          color: bodyTextColor ? `#${bodyTextColor?.replace('#', '')}` : '#212529'
        }}
      ></Popover.Content>
    </Popover>
  );
};

export default PopoverItem;
