export const scheduleDataInitialState = {
  scheduleExperience: {
    productId: '',
    storeId: '',
    date: undefined,
    timeSlot: '',
    guest: 0
  },
  personalizeExperience: {
    teaId: '',
    scentId: '',
    additionalSelectionAns: []
  },
  contactDetail: {
    firstName: '',
    lastName: '',
    emailAddress: '',
    dob: '',
    phonePrefix: '',
    mobileNumber: '',
    mobileNumberWithPrefix: ''
  },
  policyAndTermCheckbox: {
    agreePolicy: false,
    acceptPDDA: false
  }
};
