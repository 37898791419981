export const ACTION_GET_CP_CUSTOMER = '/clientteling/get-contact';

export const ACTION_SEARCH_CP_CUSTOMER = '/clientteling/search-contacts';

export const ACTION_GET_CONTACT_PERSONAL_INFORMATION = '/clientteling/get-contact-personal';

export const ACTION_UPDATE_CP_CUSTOMER = '/clientteling/update-contact';

export const END_SESSION = '/clientteling/end-session';

export const ACTION_GET_CUSTOMER_PRODUCTS = '/OrderLines/GetCustomerProducts';

export const ACTION_GET_COUPON_LIST = '/promotions/GetCouponWallet';

export const ACTION_UPDATE_CP_CUSTOMER_INTEREST = '/clientteling/update-contact-interest';

export const CP_REMOVE_PRODUCT_INTEREST_ENDPOINT = '/clientteling/remove-contact-interest';
