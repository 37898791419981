import SiteCoreLinkModel from '@models/SitecoreLinkModel';
import SiteCoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'All Filter Label',
  'Lost Status Label',
  'Never Pick Up Status Label',
  'New Status Label',
  'Reschedule Status Label',
  'Trial Complete Status Label',
  'Number Booked Text',
  'Title Text',
  'Campaign Name Label',
  'Lead Status Label',
  'Mobile Label',
  'Name Label',
  'Notes Label',
  'Store Name Label',
  'Submission Date Lable',
  'Hide Date Filter',
  'Confirmed Status Label',
  'Time Slot Label'
];

export default class AppointmentListingModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SiteCoreTextModel();
    });

    this['Appointment Detail Page'] = new SiteCoreLinkModel();

    this['IsSaleManager'] = false;
  }

  getData(dataSources) {
    TEXT_ARR.forEach((text) => {
      if(dataSources && Object.prototype.hasOwnProperty.call(dataSources, text)) {
        this[text] = dataSources[text] ?? '';
      }
    });

    if(dataSources && Object.prototype.hasOwnProperty.call(dataSources, 'Appointment Detail Page')) {
      this['Appointment Detail Page'] = dataSources['Appointment Detail Page'];
    }
    
    if(dataSources && Object.prototype.hasOwnProperty.call(dataSources, 'IsSaleManager')) {
      this['IsSaleManager'] = dataSources['IsSaleManager'];
    }

    return this;
  }
}