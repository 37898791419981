import PropTypes from 'prop-types';
import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { connect, useDispatch } from 'react-redux';

import { loginFacebook } from '@redux/actions/singleSignOn';
import { loginFailed } from '@redux/actions/singleSignOn';
import { TYPE_FACEBOOK } from '@redux/epic/singleSignOn/config';
import { generateRegisterLoginError } from '@redux/epic/singleSignOn/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { EVENT_METHOD } from '../RegisterLogin/utils';

const FacebookSSO = ({ dataFields, useFacebookLogin }) => {
  const dispatch = useDispatch();

  const onFacebookLoginHandler = (response, type) => {
    if (response?.accessToken) {
      dispatch(
        loginFacebook({
          Type: type,
          AccessToken: response.accessToken
        })
      );
    }
  };

  const onCustomLoginFbHandler = () => {
    window.FB.login( (response) => {
      if (response.status === 'connected') {
        // Logged into your webpage and Facebook.
        onFacebookLoginHandler(response?.authResponse, TYPE_FACEBOOK);
      }
      if (response.status === 'unknown' || !response.authResponse) {
        dispatch(loginFailed(generateRegisterLoginError('', EVENT_METHOD.facebook)));
      }
    }, { scope: 'public_profile,email' });
  };

  return dataFields && useFacebookLogin ? (
    <div className='osim-account-form__social-login__btn sigle-sign-on-facebook'>
      <FacebookLogin
        version='10.0'
        isMobile={false}
        appId={dataFields['App ID'].value}
        autoLoad={false}
        // icon={<img className='btn-outline-with-icon__img' src={require('@assets/images/icons/facebook-icon.png')} alt='icon' />}
        // callback={(response) => onFacebookLoginHandler(response, TYPE_FACEBOOK)}
        // className='sigle-sign-on-facebook__login'
        // cssClass='btn btn-outline-with-icon'
        // textButton={<Text field={dataFields['Login Label']} tag='span' />}
        render={() => (
          <div onClick={onCustomLoginFbHandler} className='sigle-sign-on-facebook__login'>
            <button className='btn btn-outline-with-icon'>
              <img className='btn-outline-with-icon__img' src={require('@assets/images/icons/facebook-icon.png')} alt='icon' />
              <Text field={dataFields['Login Label']} />
            </button>
          </div>
        )}
      />
    </div>
  ) : (
    ''
  );
};

const mapStateToProps = (state) => ({
  dataUser: state.singleSignOnReducer.userInfoReducer?.dataUser || [],
  useFacebookLogin: state.settingGlobalReducer.settingGlobal?.UseFacebookLogin
});

FacebookSSO.propTypes = {
  dataFields: PropTypes.object,
  setAuthenFaceBookEvt: PropTypes.func,
  useFacebookLogin: PropTypes.bool
};

export default connect(mapStateToProps)(FacebookSSO);
