import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import DiscoverProductButton from '../DiscoverProductButton';
import HasWarrantySection from './HasWarrantySection';
import NotHasWarrantySection from './NotHasWarrantySection';
import ProductPreviewSection from './ProductPreviewSection';

const WarrantyRegistrationThankYouDescription = ({ fieldData, isProductReview }) => {
  const { isLogin } = useSelector((state) => state.singleSignOnReducer.userInfoReducer);
  // const [stars, setStars] = useState(3);
  // const [thankYouMess, setThankYouMess] = useState('');
  const [reviewIdWriteReviewSuccessLocal, setReviewIdWriteReviewSuccessLocal] = useState('');
  const [viewProductDetail, setViewProductDetail] = useState('');

  useEffect(() => {
    // Comments to cancel redirect page when there is no thank you message
    // const thankYouMessage = JSON.parse(window.localStorage.getItem('thankYouMessage')) || '';
    // if (thankYouMessage) {
    //   setThankYouMess(thankYouMessage);
    // } else {
    //   window.location.href = fieldData?.['Write Review Link']?.value?.href || LINK_TO_HOMEPAGE;
    // }
    const reviewIdWriteReviewSuccessLocal = JSON.parse(window.localStorage.getItem('reviewIdWriteReviewSuccess'));
    const isDisableWriteReviewLocal = JSON.parse(window.localStorage.getItem('isDisableWriteReviewAfterSubmitSuccess'));
    const myProductIdReviewLocal = JSON.parse(window.localStorage.getItem('myProductIdAfterSubmitSuccess'));
    if (reviewIdWriteReviewSuccessLocal) {
      setReviewIdWriteReviewSuccessLocal(reviewIdWriteReviewSuccessLocal);
    }

    setViewProductDetail(
      `${fieldData['See Review In Product Page Link']?.value?.href}?&ReviewId=${reviewIdWriteReviewSuccessLocal}&IsDisableWriteReview=${isDisableWriteReviewLocal}&MyProductId=${myProductIdReviewLocal}`
    );
  }, [fieldData['See Review In Product Page Link']?.value?.href]);

  return isProductReview ? (
    <ProductPreviewSection
      viewProductDetail={viewProductDetail}
      fieldData={fieldData}
      reviewIdWriteReviewSuccessLocal={reviewIdWriteReviewSuccessLocal}
    />
  ) : fieldData ? (
    <>
      {!fieldData?.HasWarranty ? (
        <HasWarrantySection fieldData={fieldData} isLogin={isLogin} />
      ) : (
        <NotHasWarrantySection fieldData={fieldData} isLogin={isLogin} />
      )}
      <DiscoverProductButton fieldData={fieldData} />
    </>
  ) : (
    <></>
  );
};

WarrantyRegistrationThankYouDescription.propTypes = {
  fieldData: Proptypes.any,
  isProductReview: Proptypes.bool
};

export default WarrantyRegistrationThankYouDescription;
