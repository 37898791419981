import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = [
  'Feature Description',
  'Feature Title'
];

const IMGARR = [
  'Feature Image'
];

export default class FeaturesBarFieldsModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    IMGARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMGARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
