import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class FieldsModel {
  constructor() {
    this.Description = new SitecoreTextModel();

    this.Image = new SitecoreImageModel();

    this.Title = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}
