import Proptypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { getTrackBackground,Range } from 'react-range';

import * as config from '@components/CategoryLanding/ProductListing/config';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

const FilterRange = ({onChangeRange, minRange, maxRange, isClearFilter}) => {
  const STEP = 1;
  const MIN = minRange;
  const MAX = maxRange;
  const [values, setValues] = useState([MIN, MAX]);

  const minTotalPriceRef = useRef();
  const maxTotalPriceRef = useRef();

  const [data, setData] = useState({
    minChange: false,
    maxChange: false
  });

  useDidUpdateEffect(() => {
    const distanceBetweenElements = minTotalPriceRef.current.offsetWidth / 2 + maxTotalPriceRef.current.offsetWidth / 2;
    const distanceBetweenElementsCurrent = Global.getDistanceBetweenElements(minTotalPriceRef.current, maxTotalPriceRef.current).toFixed(0);
    if (+distanceBetweenElementsCurrent <= distanceBetweenElements) {
      setData({
        ...data,
        minChange: data.maxChange ? false : true
      });
    } else {
      setData({
        ...data,
        minChange: false,
        maxChange: false
      });
    }
  }, [values[0]]);

  useDidUpdateEffect(() => {
    const distanceBetweenElements = minTotalPriceRef.current.offsetWidth / 2 + maxTotalPriceRef.current.offsetWidth / 2;
    const distanceBetweenElementsCurrent = Global.getDistanceBetweenElements(minTotalPriceRef.current, maxTotalPriceRef.current).toFixed(0);
    if (+distanceBetweenElementsCurrent <= distanceBetweenElements) {
      setData({
        ...data,
        maxChange: data.minChange ? false : true
      });
    } else {
      setData({
        ...data,
        minChange: false,
        maxChange: false
      });
    }
  }, [values[1]]);

  useDidUpdateEffect(() => {
    setValues([MIN, MAX]);
  }, [isClearFilter]);

  useDidUpdateEffect(() => {
    setValues([minRange, MAX]);
  }, [minRange]);

  return (
    <Range
      values={values}
      step={STEP}
      min={MIN}
      max={MAX}
      onChange={(values) => {
        setValues(values);

        onChangeRange(values);
      }}
      renderTrack={({ props, children, disabled }) => (
        <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}>
          <div
            className='input-range__line'
            ref={props.ref}
            style={{
              background: getTrackBackground({
                values: values,
                colors: disabled ? config.STEP_LINE_BACKGROUND_DISABLED : config.STEP_LINE_BACKGROUND_ACTIVE,
                min: MIN,
                max: MAX
              })
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ index, props }) => (
        <div
          className={`input-range ${index === 0 ? 'min-value' : index === 1 ? 'max-value' : ''}`}
          {...props}
        >
          <span className={`input-range__curent ${(data.maxChange && index === 1) || (data.minChange && index === 0) ? 'is-hidden' : ''}`}
            ref={index === 0 ? minTotalPriceRef : index === 1 ? maxTotalPriceRef : ''}
          >
            {MIN + 1 == MAX && index === 1 ? (values[index] - 1).toFixed(2) : values[index].toFixed(2)}
          </span>
          {index === 1 && data.maxChange ? <span className='input-range__curent change-position'>{values[1].toFixed(2)}</span> : ''}
          {index === 0 && data.minChange ? <span className='input-range__curent change-position'>{values[0].toFixed(2)}</span> : ''}
        </div>
      )}
    />
  );
};

FilterRange.propTypes = {
  onMouseDown: Proptypes.any,
  onTouchStart: Proptypes.any,
  ref: Proptypes.any,
  onChangeRange: Proptypes.any,
  maxRange: Proptypes.number,
  minRange: Proptypes.number,
  isClearFilter: Proptypes.bool,
};

export default FilterRange;