export default class ResourceSearchApiModel {
  constructor() {
  
    this.Description = '';

    this.FeaturedImage = '';

    this.IsVideo = false;
  
    this.ItemId = '';
  
    this.ItemType = '';

    this.PageUrl = '/';
  
    this.Title = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description ? dataSource.Description : this.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FeaturedImage')) {
      this.FeaturedImage = dataSource.FeaturedImage || this.FeaturedImage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsVideo')) {
      this.IsVideo = dataSource.IsVideo || this.IsVideo;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId')) {
      this.ItemId = dataSource.ItemId ? dataSource.ItemId : this.ItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemType')) {
      this.ItemType = dataSource.ItemType ? dataSource.ItemType: this.ItemType;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PageUrl')) {
      this.PageUrl = dataSource.PageUrl ? dataSource.PageUrl: this.PageUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title = dataSource.Title ? dataSource.Title: this.Title;
    }
      
    return this;
  }
}
  