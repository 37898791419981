import React from 'react';

import { FREEGIFT_SLIDE_DISPLAY_ITEM, FREEGIFT_SLIDE_DISPLAY_ITEM_DEVICE } from '@utils/constant';

export const GIFT_NUMBER = '{seq}';

export let params = {
  lazy: true,
  shouldSwiperUpdate: true,
  rebuildOnUpdate: true,
  slidesPerView: FREEGIFT_SLIDE_DISPLAY_ITEM,
  watchOverflow: true,
  spaceBetween: 24,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  // eslint-disable-next-line react/display-name
  renderPrevButton: () => <span className='swiper-button swiper-button-prev icon-chevron-left'></span>,
  // eslint-disable-next-line react/display-name
  renderNextButton: () => <span className='swiper-button swiper-button-next icon-chevron-right'></span>,

  breakpoints: {
    1024: {
      slidesPerView: FREEGIFT_SLIDE_DISPLAY_ITEM
      // spaceBetween: 15
    },
    768: {
      slidesPerView: FREEGIFT_SLIDE_DISPLAY_ITEM_DEVICE
      // spaceBetween: 10
    },
    320: {
      slidesPerView: FREEGIFT_SLIDE_DISPLAY_ITEM_DEVICE
      // spaceBetween: 5
    }
  }
};
