import Proptypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import JotformEmbed from 'react-jotform-embed';

import JotFormModel from './model/JotFormModel';

const JotForm = ({fields}) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new JotFormModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData && (
    <JotformEmbed src={`https://form.jotform.com/${layoutData['Form Id'].value}`} />
  );
};

JotForm.propTypes = {
  fields: Proptypes.any,
};

export default JotForm;
