/* eslint-disable no-fallthrough */
import produce from 'immer';

import { GET_CURRENT_CART, GET_CURRENT_CART_FAILED, GET_CURRENT_CART_SUCCESS } from '@redux/actions/product/productTypes';

import {
  ADD_PRIORITY_TO_CART,
  ADD_PRIORITY_TO_CART_FAILED,
  ADD_PRIORITY_TO_CART_SUCCESS
} from '../../actions/addPriorityToCart/types';

const initState = {
  isLoading: false,
  data: null,
  error: null
};

const addPriorityToCartReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      
    case GET_CURRENT_CART:

    case ADD_PRIORITY_TO_CART:
      draft.isLoading = true;
      break;

    case GET_CURRENT_CART_SUCCESS:

    case ADD_PRIORITY_TO_CART_SUCCESS:
      draft.isLoading = false;
      break;

    case GET_CURRENT_CART_FAILED:

    case ADD_PRIORITY_TO_CART_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default addPriorityToCartReducer;
