import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SwipeComponent from '@components/Share/SwipeComponent';
import {getCartStripAds} from '@redux/actions/cartDetail';
import { deepCopy } from '@utils/utility';

import CartStripAdsModel from '../models/CartStripAdsModel';

const CartStripAds = ({fields}) => {
  const [isShow, setIsShow] = useState(true);
  const [cartStripAdsFields, setCartStripAdsFields] = useState('');
  const dispatch = useDispatch();

  const params = {
    direction: 'vertical',
    slidesPerView: 1,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    loop: true,
    speed: 2000,
    autoHeight: true,
  };

  const autoplaySwiper = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  };
  
  const classCustom = (textColor) => {
    return textColor === 'Black' ? 'strip-ads__content text-dark' : 'strip-ads__content';
  };

  const styleInline = (backgroundColorSetting) => {
    return backgroundColorSetting ? {
      backgroundColor: backgroundColorSetting,
    } : {};
  };

  useEffect(() => {
    const cartStripAdsFieldsModel = new CartStripAdsModel();

    setCartStripAdsFields(cartStripAdsFieldsModel.getData(deepCopy(fields || {})));

    dispatch(getCartStripAds());
  }, []);

  const cartStripAds = useSelector(state => state.productReducer.cartDetailReducer.cartStripAds);
  
  return cartStripAds?.length && isShow ?
    <div className='strip-ads strip-ads--cart'>
      <div className='strip-ads__wrap'>
        {/* <div className='container'> */}
        <SwipeComponent param={cartStripAds.length > 1 ? {...params, ...autoplaySwiper} : params}>
          {cartStripAds.map((stripAdsItem, stripAdsIdx) => 
            <div className={classCustom(cartStripAdsFields?.['Text Color']?.value)} style={styleInline(cartStripAdsFields?.['Background Color']?.value)} key={stripAdsIdx}>
              <div dangerouslySetInnerHTML={{ __html: stripAdsItem.Description }}></div>
            </div>
          )}
        </SwipeComponent>  
        {/* </div> */}
        <span className='strip-ads__close-btn' onClick={() => setIsShow(false)}>
          <i className='strip-ads__close-btn__icon icon-close'></i>
        </span>
      </div>
    </div> : '';
};

CartStripAds.propTypes = {
  fields: PropTypes.object,
};

export default CartStripAds;
