import SitecoreTextModel from '@models/SitecoreTextModel';

const LABEL_ARR = ['Title', 'Description'];

export default class FieldsModel {
  constructor() {
    LABEL_ARR.forEach((label) => {
      this[label] = new SitecoreTextModel();
    });

    this['App List'] = [];
  }

  getData(dataSource) {
    LABEL_ARR.forEach((label) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, label)) {
        this[label] = dataSource[label] ?? this.label;        
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'App List')) {
      this['App List'] = dataSource['App List'];
    }

    return this;
  }
}