
export default class DetailPagesModel {
  constructor() {
    this['url'] = '';

    this['id'] = '';

    this['fields'] = {};
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'url')) {
      this['url'] =dataSource['url'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this['id'] =dataSource['id'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'] =dataSource['fields'];
    }

    return this;
  }
}
