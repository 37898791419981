import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import ProductCardPrice from '@components/ProductCardPrice';
import SwipeComponent from '@components/Share/SwipeComponent';
import { removeProductCompare, setVariantProductCompare } from '@redux/actions/compareProduct';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { getImageUrl } from '@utils/getImageUrl';
import { deepCopy } from '@utils/utility';

import useCompareProduct from '../hooks/useCompareProduct';

// import { CompareProductsContext } from '../index';

const ProductReview = ({ layoutData, isDisabledElement, isEnableName, productInfo, componentNumber }) => {
  const dispatch = useDispatch();
  // const mounted = useRef(false);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  // const { productCompareData } = useSelector((state) => state.compareProductReducer);
  // const [categorySelected, setCategorySelected] = useContext(CompareProductsContext);
  const [variantActive, setVariantActive] = useState(null);
  const [buyNowLink, setBuyNowLink] = useState(null);
  let variantParams = {
    slidesPerView: 3,
    spaceBetween: 0
  };
  const sellingPriceRef = useRef(null);
  const variantItemRef = useRef(null);
  const productTitleRef = useRef(null);

  const {
    infoSellingPriceLabelHeight,
    setInfoSellingPriceLabelHeight,
    productVariantItemHeight,
    setProductVariantItemHeight,
    productTitleItemHeight,
    setProductTitleItemHeight
  } = useCompareProduct();

  const isOCNSite = useMemo(() => {
    return SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OCN';
  }, [SitecoreContextFactoryService]);

  const productSummary = useMemo(() => {
    return productInfo?.productInfoDetail?.ProductSummary;
  }, [productInfo?.productInfoDetail?.ProductSummary]);

  const [isShowedMainImage, setIsShowedMainImage] = useState(false);

  useEffect(() => {
    setVariantActive(productInfo?.productVariantActive);
  }, [productInfo]);

  useEffect(() => {
    let tempLink = deepCopy(layoutData['Buy Now Link']);

    tempLink.value.href = `${layoutData['Buy Now Link'].value.href}?productPageId=${productSummary?.Id || ''}${
      productSummary?.IsRentalProduct ? '&datatype=RefunableDeposit' : ''
    }&dataType=TradeIn`;

    setBuyNowLink(tempLink);
  }, [productSummary]);

  useEffect(() => {
    // INFO: add all height of info selling price into state from context
    if (sellingPriceRef?.current?.offsetHeight) {
      setInfoSellingPriceLabelHeight((prev) => [
        ...prev,
        {
          componentNumber,
          height: sellingPriceRef.current.offsetHeight
        }
      ]);
    }
  }, [sellingPriceRef?.current?.offsetHeight]);

  useEffect(() => {
    // INFO: set info selling price height base on the highest height
    const handleSetInfoSellingHeight = () => {
      const height = infoSellingPriceLabelHeight.sort((prev, next) => next.height - prev.height)?.[0]?.height;
      if (sellingPriceRef.current) sellingPriceRef.current.style.height = `${height}px`;
    };

    window.addEventListener('resize', () => handleSetInfoSellingHeight());

    handleSetInfoSellingHeight();

    window.removeEventListener('resize', () => handleSetInfoSellingHeight());
  }, [infoSellingPriceLabelHeight]);

  useEffect(() => {
    // INFO: add all height of product variant item into state from context
    if (variantItemRef?.current) {
      const variantNames = variantItemRef.current.getElementsByTagName('p');
      let highestVariantName = 0;
      for (const item of variantNames) {
        const itemHeight = item.offsetHeight;
        if (itemHeight > highestVariantName) highestVariantName = itemHeight;
      }

      setProductVariantItemHeight((prev) => [
        ...prev,
        {
          componentNumber,
          height: highestVariantName
        }
      ]);
    }
  }, [variantItemRef?.current, isDisabledElement]);

  useEffect(() => {
    // INFO: set product variant item height base on the highest height
    if (productVariantItemHeight.length) {
      const handleSetVariantItemHeight = () => {
        const highestItem = productVariantItemHeight?.sort((prev, next) => next.height - prev.height)?.[0]?.height;
        if (variantItemRef?.current) {
          const variantNames = variantItemRef.current.getElementsByTagName('p');

          for (const item of variantNames) {
            item.style.height = `${highestItem}px`;
          }
        }
      };

      window.addEventListener('resize', () => handleSetVariantItemHeight());

      handleSetVariantItemHeight();

      window.removeEventListener('resize', () => handleSetVariantItemHeight());
    }
  }, [productVariantItemHeight, componentNumber]);

  useEffect(() => {
    // INFO: add all height of product title into state from context
    if (productTitleRef?.current) {
      const productTitleEl = productTitleRef.current;

      setProductTitleItemHeight((prev) => [
        ...prev,
        {
          componentNumber,
          height: productTitleEl.offsetHeight
        }
      ]);
    }
  }, [productTitleRef?.current, isEnableName]);

  useEffect(() => {
    // INFO: set product title height base on the highest height
    if (productTitleItemHeight.length) {
      const handleSetProductTitleHeight = () => {
        const highestItem = productTitleItemHeight?.sort((prev, next) => next.height - prev.height)?.[0]?.height;
        if (productTitleRef?.current) {
          const productTitleEl = productTitleRef.current;

          productTitleEl.style.minHeight = `${highestItem}px`;
        }
      };

      window.addEventListener('resize', () => handleSetProductTitleHeight());

      handleSetProductTitleHeight();

      window.removeEventListener('resize', () => handleSetProductTitleHeight());
    }
  }, [productTitleItemHeight, componentNumber]);

  // useEffect(() => {
  //   // INFO: check component is unmounted or not. If yes, set category as default (null) === remove all product compare
  //   mounted.current = true;

  //   return () => {
  //     mounted.current = false;

  //     if (productCompareData.indexOf(null) === 1) {
  //       setCategorySelected(null);
  //     }
  //   };
  // }, [productCompareData]);

  const imageSrc = useMemo(() => {
    if (variantActive) {
      if (!isShowedMainImage) {
        setIsShowedMainImage(true);

        return getImageUrl(productSummary?.Image, variantActive.Images?.[0]);
      } else {
        return variantActive.Images?.[0];
      }
    }

    return '';
  }, [variantActive]);

  const handleVariantClick = (variantItem) => {
    setVariantActive(variantItem);

    // INFO: re-init product variant item data
    setProductVariantItemHeight([]);

    dispatch(setVariantProductCompare(componentNumber, variantItem));
  };

  const handleRemoveProduct = (data) => {
    dispatch(removeProductCompare(data));
  };

  return (
    <div className='compare-products-item'>
      <div className='compare-products-item__img'>
        <img src={imageSrc} alt='product-image' />
      </div>
      {isDisabledElement ? (
        ''
      ) : (
        <>
          <div ref={variantItemRef} className='compare-products-item__variant'>
            <SwipeComponent param={variantParams}>
              {productInfo?.productVariant?.map((variantItem, index) => {
                return (
                  <div key={index}>
                    <div
                      className={`variant__item ${variantItem.VariantId === variantActive?.VariantId ? 'is-active' : ''}`}
                      onClick={() => handleVariantClick(variantItem)}
                    >
                      <img src={variantItem.Images[0]} alt='variant-image' />
                      <p>{variantItem.VariantPropertyValue}</p>
                    </div>
                  </div>
                );
              })}
            </SwipeComponent>
          </div>
          <span onClick={() => handleRemoveProduct(productInfo)} className='compare-products-item__remove-btn'>
            <i className='icon-close'></i>
          </span>
        </>
      )}
      {isEnableName ? (
        <p ref={productTitleRef} className='compare-products-item__info-name'>
          {variantActive?.VariantName}
        </p>
      ) : (
        <></>
      )}
      <div ref={sellingPriceRef} className='compare-products-item__info'>
        <ProductCardPrice
          mainPriceClassName='info-selling-price'
          oldPriceClassName='info-list-price'
          sellingPriceWithCurrency={variantActive?.Price?.SellingPriceWithCurrency}
          listPriceWithCurrency={variantActive?.Price?.ListPriceWithCurrency}
        />
      </div>
      <div className='compare-products-item__btn'>
        {!isOCNSite ? (
          <BuyButtonComponent
            productData={{
              ...variantActive,
              productId: `${productSummary?.ProductId}|${variantActive?.VariantId}`,
              isCompare: true,
              compareUrl: productSummary?.Url,
              compareName: layoutData['Learn More Text'].value
            }}
          >
            {productSummary?.IsBundle ? (
              <a href={productSummary?.Url}>
                <span>{layoutData['Learn More Text'].value} &gt;</span>
              </a>
            ) : (
              <>
                <a className='button-compare btn btn-primary' href={buyNowLink?.value?.href}>
                  {productSummary?.IsRentalProduct ? messageObj['LBL-RentNowLabel'] : productSummary?.Stock?.PurchasableLabel}
                </a>
                <a href={productSummary?.Url}>
                  <span>{layoutData['Learn More Text'].value} &gt;</span>
                </a>
              </>
            )}
          </BuyButtonComponent>
        ) : (
          <a href={productSummary?.Url} className='learn-more-btn'>
            <span>{layoutData['Learn More Text'].value} &gt;</span>
          </a>
        )}
      </div>
    </div>
  );
};

ProductReview.propTypes = {
  layoutData: PropTypes.object,
  isDisabledElement: PropTypes.bool,
  isEnableName: PropTypes.bool,
  productInfo: PropTypes.any,
  componentNumber: PropTypes.number
};

export default ProductReview;
