import SitecoreTextModel from '@models/SitecoreTextModel';

export default class FooterNavigationTextModel {
  constructor() {
    this.Title = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    return this;
  }
}
