import './CartItemTotalPrice.scss';

import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import { useConvertBalanceDate } from '../../../../../Product/VariantDetail/ProductDetailOverview/ProductVariantInfo/utils';


const CartItemTotalPrice = ({ subTotal, dataSources, data }) => {
  const convertBalanceDateService = useConvertBalanceDate();

  const isDeposit = useMemo(() => {
    return data?.Deposit !== null;
  }, [data]);
  const depositData = useMemo(() => data?.Deposit, [data]);
  const startBalanceDate = useMemo(() => convertBalanceDateService(depositData?.BalanceStartDate), [depositData]);
  const endBalanceDate = useMemo(() => convertBalanceDateService(depositData?.BalanceEndDate), [depositData]);

  return (
    <div className='cart-item-total-price'>
      <div className='cart-item-total-price__subtotal'>
        <p className='cart-item-total-price__title'>
          <Text field={dataSources['Sub Total Label']} />
        </p>
        <p className='cart-item-total-price__total-price'>
          {subTotal.IsEditable ? (
            subTotal.LineFinalTotal
          ) : subTotal.PromotionCartLines.length > 0 && subTotal.PromotionCartLines[0].LineFinalTotalAmount > 0 ? (
            subTotal.LineFinalTotal
          ) : subTotal.GiftingOptionTotalAmout > 0 ? (
            subTotal.LineFinalTotal
          ) : (
            <Text field={dataSources['Free Text']} />
          )}
        </p>
      </div>
      {isDeposit && (
        <div className='cart-item-total-price__deposit'>
          <div className='cart-item-total-price__deposit__item cart-item-total-price__deposit__item--pre-order'>
            <Text className='title' field={dataSources?.['Pre Order Label']} tag='span' />
            <span className='info'>{depositData?.PreoderPriceWithCurrency}</span>
          </div>
          <div className='cart-item-total-price__deposit__item cart-item-total-price__deposit__item--payment'>
            <span className='title'>{`${dataSources?.['Balance Payment Label']?.value}: `}</span>
            <span className='info'>{depositData?.BalancePriceWithCurrency}</span>
          </div>
          <div className='cart-item-total-price__deposit__item cart-item-total-price__deposit__item--payment'>
            <span className='title'>{`${dataSources?.['Payment Period Label']?.value}: `}</span>
            <span className='info'>
              {`${(dataSources?.['Payment Period Details']?.value).replace('{StartDate}', startBalanceDate).replace('{EndDate}', endBalanceDate)}`}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

CartItemTotalPrice.propTypes = {
  subTotal: PropTypes.object,
  dataSources: PropTypes.object,
  data: PropTypes.any
};

export default CartItemTotalPrice;
