import { object } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const OrderNumberInput = ({ inputLabel, ...props }) => {
  return (
    <div className='warranty-registration__form-input__item'>
      <input {...props} type='text' id='orderNumber' className='form-control input-item' name='orderNumber' placeholder={inputLabel.value} />
      <label htmlFor='orderNumber' className='form-group__label'>
        <Text field={inputLabel} />
      </label>
    </div>
  );
};

OrderNumberInput.propTypes = {
  inputLabel: object
};

export default OrderNumberInput;
