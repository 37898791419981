import produce from 'immer';

import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';

const initState = {
  isLoading: false,
  error: {
    type: '',
    errorMessage: ''
  },
  isCPLogin: false,
  cPError: null,
  isCPLoginSuccess: false,
  isCPLogoutLoading: false,
  isCPVerifyLoginSuccess: false,
  cPVerifyLoginError: null,
  cPResendLoginOTPError: null,
  cPResendLoginOTPSuccess: false,
  isCPLogoutSuccess: false
};

const loginReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case singleSignOnTypes.LOGIN:
      draft.isLoading = true;

      draft.error = null;
      break;

    case singleSignOnTypes.LOGIN_FACEBOOK:
      draft.isLoading = true;

      draft.error = null;
      break;

    case singleSignOnTypes.TOOGLE_LINK_FACEBOOK:
      draft.isLoading = true;

      draft.error = null;
      break;

    case singleSignOnTypes.TOOGLE_LINK_GOOGLE:
      draft.isLoading = true;

      draft.error = null;
      break;

    case singleSignOnTypes.LOGIN_GOOGLE:
      draft.isLoading = true;

      draft.error = null;
      break;

    case singleSignOnTypes.LOGIN_SUCCESS:
      draft.isLoading = false;

      break;

    case singleSignOnTypes.LOGIN_FAILED:

      draft.isLoading = false;

      draft.error = {
        type: action.error.type,
        errorMessage: action.error.errorMessage
      };

      break;

    case singleSignOnTypes.LOGOUT:
      draft.isLoading = true;
      break;

    case singleSignOnTypes.LOGOUT_FAILED:
      draft.isLoading = false;
      break;

    case singleSignOnTypes.CP_LOGIN:
      draft.isCPLogin = true;

      draft.cPError = null;
      break;

    case singleSignOnTypes.CP_LOGIN_SUCCESS:
      draft.isCPLogin = false;

      draft.isCPLoginSuccess = true;
      break;

    case singleSignOnTypes.CP_LOGIN_FAILED:

      draft.isCPLogin = false;

      draft.isCPLoginSuccess = false;

      draft.cPError = action.error;
      break;

    case singleSignOnTypes.CP_LOGOUT:
      draft.isCPLogoutLoading = true;

      draft.isCPLogoutSuccess = false;
      break;

    case singleSignOnTypes.CP_LOGOUT_SUCCESS:
      draft.isCPLogoutLoading = false;

      draft.isCPLogoutSuccess = true;
      break;

    case singleSignOnTypes.CP_LOGOUT_FAILED:
      draft.isCPLogoutLoading = false;

      draft.isCPLogoutSuccess = false;

      draft.cPError = action.error;
      break;

    case singleSignOnTypes.CP_VERIFY_LOGIN:
      draft.isCPLogin = true;
      break;

    case singleSignOnTypes.CP_VERIFY_LOGIN_SUCCESS:
      draft.isCPLogin = false;

      draft.isCPVerifyLoginSuccess = true;
      break;

    case singleSignOnTypes.CP_VERIFY_LOGIN_FAILED:
      draft.isCPLogin = false;

      draft.isCPVerifyLoginSuccess = false;

      draft.cPVerifyLoginError = action.error;
      break;

    case singleSignOnTypes.RESEND_LOGIN_OTP:
      draft.isCPLogin = true;

      draft.cPResendLoginOTPSuccess = false;
      break;

    case singleSignOnTypes.RESEND_LOGIN_OTP_SUCCESS:
      draft.isCPLogin = false;

      draft.cPResendLoginOTPSuccess = true;
      break;

    case singleSignOnTypes.RESEND_LOGIN_OTP_FAILED:
      draft.isCPLogin = false;

      draft.cPResendLoginOTPSuccess = false;

      draft.cPResendLoginOTPError = action.error;
      break;
    }

    return draft;
  });

export default loginReducer;
