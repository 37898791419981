import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import * as TIMERULE from '@utils/constant';

import { CHINESE_LANGUAGES, HK_SITE_NAME, SITE_CHINESE_LANGUAGE_KEY, TW_SITE_NAME } from '../../../../utils/constant';

const CountdownTimerItem = ({ isTranslateDateTimeLetters = false, ...props }) => {
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');

  const [state, setState] = useState({
    preMsg: '',
    message: '',
    isDisplay: false
  });

  const [showCountdown, setShowCountdown] = useState(null);

  // let start = new Date('2021-01-29T12:21:00Z');
  // let end = new Date('2021-01-29T12:23:00Z');
  // let remaining = new Date('2021-01-29T12:22:00Z');
  // let remaining = new Date('');
  let start = new Date(props.startTime);
  let end = new Date(props.endTime);
  let remaining = new Date(props.remainingTime);

  let now = new Date(Date.now());

  let monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'];

  let endMinute = end.getMinutes();
  let endHour = end.getHours();
  let endDate = end.getDate();
  let endMonth = monthArr[end.getMonth()];
  let endYear = end.getFullYear();

  start = Date.parse(start);

  end = Date.parse(end);

  now = Date.parse(now);
  var timerID;

  useEffect(() => {
    timerID = setInterval(() => setNewTime(), 1000);

    return () => {
      clearInterval(timerID);
    };
  }, []);

  useEffect(() => {
    if (end === 978307204000 || (start === 978307204000 && end === 978307204000)) {
      setShowCountdown(false);
    } else {
      setShowCountdown(true);
    }
  }, []);

  const dateTimeLetters = useMemo(() => {
    let dateTimeLetters = {
      day: 'D',
      hour: 'h',
      minute: 'm',
      second: 's'
    };
    const isConvertToHKSite = currentSiteName === HK_SITE_NAME && currentLanguage === SITE_CHINESE_LANGUAGE_KEY;
    const isTWSite = currentSiteName === TW_SITE_NAME;
    if (isTranslateDateTimeLetters && (isConvertToHKSite || isTWSite)) {
      for (const key of Object.keys(dateTimeLetters)) {
        dateTimeLetters[key] = CHINESE_LANGUAGES[key];
      }
    }

    return dateTimeLetters;
  }, [isTranslateDateTimeLetters, currentLanguage, currentSiteName]);

  const setNewTime = () => {
    if (start && end) {
      if (end >= start) {
        now = now + 1000;

        var distance = end - now;

        if (distance < 0) {
          //AFTER END PROMOTION
          clearInterval(timerID);

          setState((prev) => ({
            ...prev,
            preMsg: '',
            message: `Promotion was ended at ${endMinute}m ${endHour}h ${endDate} ${endMonth} ${endYear}`,
            isDisplay: false
          }));

          return;
        }

        if (now < start) {
          //BEFORE START PROMOTION
          let countDown = start - now;

          let remainDate = Math.floor(countDown / TIMERULE._DAY);
          let remainHour = Math.floor((countDown % TIMERULE._DAY) / TIMERULE._HOUR);
          let remainMinutes = Math.floor((countDown % TIMERULE._HOUR) / TIMERULE._MINUTE);
          let remainSeconds = Math.floor((countDown % TIMERULE._MINUTE) / TIMERULE._SECOND);

          setState((prev) => ({
            ...prev,
            preMsg: `${props.startMsg} `,
            message: `${remainDate && remainDate}${dateTimeLetters.day} ${remainHour && remainHour}${dateTimeLetters.hour} ${remainMinutes && remainMinutes}${dateTimeLetters.minute} ${
              remainSeconds && remainSeconds
            }${dateTimeLetters.second}`,
            isDisplay: false
          }));

          props.onAction && props.onAction(false);
        } else {
          if (remaining >= start && remaining <= end) {
            if (remaining <= now) {
              //AFTER MARKER AND BEFORE END
              let countDown = end - now;

              let remainDate = Math.floor(countDown / TIMERULE._DAY);
              let remainHour = Math.floor((countDown % TIMERULE._DAY) / TIMERULE._HOUR);
              let remainMinutes = Math.floor((countDown % TIMERULE._HOUR) / TIMERULE._MINUTE);
              let remainSeconds = Math.floor((countDown % TIMERULE._MINUTE) / TIMERULE._SECOND);

              setState((prev) => ({
                ...prev,
                preMsg: `${props.endMsg} `,
                message: `${remainDate && remainDate}${dateTimeLetters.day} ${remainHour && remainHour}${dateTimeLetters.hour} ${remainMinutes && remainMinutes}${dateTimeLetters.minute} ${
                  remainSeconds && remainSeconds
                }${dateTimeLetters.second}`,
                isDisplay: false
              }));

              props.onAction && props.onAction(true);
            } else {
              //AFTER START AND BEFORE MARKER
              setState((prev) => ({
                ...prev,
                isDisplay: true
              }));

              props.onAction && props.onAction(true);
            }
          } else {
            setState((prev) => ({
              ...prev,
              preMsg: props.productName,
              message: '',
              isDisplay: true
            }));
          }

          props.onAction && props.onAction(true);
        }
      }
      if (end < start) {
        now = now + 1000;
        if (now < end) {
          if (now < remaining) {
            setState((prev) => ({
              ...prev,
              isDisplay: true
            }));
          }
          if (now >= remaining && now <= end) {
            let countDown = end - now;

            let remainDate = Math.floor(countDown / TIMERULE._DAY);
            let remainHour = Math.floor((countDown % TIMERULE._DAY) / TIMERULE._HOUR);
            let remainMinutes = Math.floor((countDown % TIMERULE._HOUR) / TIMERULE._MINUTE);
            let remainSeconds = Math.floor((countDown % TIMERULE._MINUTE) / TIMERULE._SECOND);

            setState((prev) => ({
              ...prev,
              preMsg: `${props.endMsg} `,
              message: `${remainDate && remainDate}${dateTimeLetters.day} ${remainHour && remainHour}${dateTimeLetters.hour} ${remainMinutes && remainMinutes}${dateTimeLetters.minute} ${
                remainSeconds && remainSeconds
              }${dateTimeLetters.second}`,
              isDisplay: false
            }));
          }
          if (now > end) {
            clearInterval(timerID);

            setState((prev) => ({
              ...prev,
              message: `Promotion was ended at ${endMinute}m ${endHour}h ${endDate} ${endMonth} ${endYear}`,
              isDisplay: false
            }));

            return;
          }
        }
        if (now > end) {
          clearInterval(timerID);

          setState((prev) => ({
            ...prev,
            message: `Promotion was ended at ${endMinute}m ${endHour}h ${endDate} ${endMonth} ${endYear}`,
            isDisplay: false
          }));

          return;
        }
      }
    }
  };

  return (
    <Fragment>
      {state.isDisplay ? (
        <Fragment>
          {now < remaining ? (
            //Display text after begin and before remaining
            <Fragment>
              <span className='promotion-detail-bundle__header__text__name'>{props.productName}</span>
            </Fragment>
          ) : (
            // <Text tag='span' className='promotion-detail-bundle__header__text__name' field={props.dataFromLayout['Promo Starting In Text']}/>
            <Fragment>
              <span className='promotion-detail-bundle__header__text__name'>
                {showCountdown ? state.preMsg : ''}
                {showCountdown ? <span className='countdowntimer--number'>{state.message}</span> : <></>}
              </span>
            </Fragment>
          )}
        </Fragment>
      ) : (
        // Display text before start, after remaining and before end, after end
        <Fragment>
          <span className='promotion-detail-bundle__header__text__name'>
            {showCountdown ? state.preMsg : ''}
            {showCountdown ? <span className='countdowntimer--number'>{state.message}</span> : <></>}
          </span>
        </Fragment>
      )}
    </Fragment>
  );
};

CountdownTimerItem.propTypes = {
  productName: PropTypes.string,
  startTime: PropTypes.string,
  endTime: PropTypes.string,
  remainingTime: PropTypes.string,
  dataFromLayout: PropTypes.object,
  startMsg: PropTypes.string,
  endMsg: PropTypes.string,
  onAction: PropTypes.any,
  isTranslateDateTimeLetters: PropTypes.bool
};

export default CountdownTimerItem;
