import './Link.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LinkModelWithTitle from '@models/LinkModelWithTitle';

const Link = ({fields}) => {
  const [linkData, setLinkData] = useState(null);

  useEffect(() => {

    setLinkData(new LinkModelWithTitle().getData(fields || {}));
  }, []);

  return (
    linkData ? (
      <div className='container'>
        <a className='my-account-section__back-link' href={linkData['Link'].value.href}>
          {linkData['Title'].value}
        </a>
      </div>
      // <div className='store-link'>
      //   <p className='store-link__back-link'>
      //     <i className='store-link__back-link__icon icon-chevron-left-big'></i>
      //     <a className='store-link__back-link__text' href={linkData['Link'].value.href}>
      //       {linkData['Title'].value}
      //     </a>
      //   </p>
      // </div>
    ) : (
      <div className='container'>
        <span className='my-account-section__back-link skeleton__item'>
          <div className='transparent-wrap'>
            <span className='text-line w-40'></span>
          </div>
        </span>
      </div>
    )
  );
};

Link.propTypes = {
  fields: PropTypes.object
};

export default Link;
