import './MemberEventRegisteredOrChanged.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import MemberEventRegisteredOrChangedModel from './model/MemberEventRegisteredOrChangedModel';

const MemberEventRegisteredOrChanged = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    let model = new MemberEventRegisteredOrChangedModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    <div className='registered-or-changed'>
      <div className='registered-or-changed__opacity'></div>
      <div className='registered-or-changed__background'>
        <Image field={layoutData['Banner Image']} />
      </div>
      <div className='container registered-or-changed__content'>
        <div className='registered-or-changed__content-head'>
          <div className='head__icon'>
            <Image field={layoutData['Success Icon']} />
          </div>
          <Text tag='h2' field={layoutData['Success Title']} className='head__title' />
        </div>
        <div className='registered-or-changed__content-details'>
          {/*<p className='details__code'>{layoutData['Location']?.Title}</p>*/}
          <Text tag='p' field={layoutData['Title']} className='details__title' />
          <div className='details__schedule'>
            <Text
              tag='span'
              field={{ value: layoutData['Date Selected']?.value }}
              className='details__schedule__date'
            />
            <Text tag='span' field={layoutData['Time Slots']} className='details__schedule__time' />
            <span className='details__schedule__guest'>
              <Text field={{ value: layoutData['Number Of Guest'] }} /> <Text field={layoutData['Guest Label']} />
            </span>
          </div>
          <p className='details__name'>{layoutData['Location']?.Title}</p>
          <RichText field={{ value: layoutData['Location']?.['Address Details'] }} className='details__location' />
          <Text tag='p' field={layoutData['Event Confirmation Text']} className='details__notification' />
          <Text tag='p' field={layoutData['See You Text']} className='details__see-you-soon' />
        </div>
        <div className='registered-or-changed__content-button'>
          <Link field={{ href: layoutData['View Detail Button Link'].value }}>
            <Text field={layoutData['View Detail Button Text']} />
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

MemberEventRegisteredOrChanged.propTypes = {
  fields: PropTypes.object,
  pageType: PropTypes.string
};

export default MemberEventRegisteredOrChanged;
