import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAddressValidErrorMessage } from '@redux/actions/checkoutProcess';
import { getLayoutElementStyleSelector } from '@redux/reducers/layoutElementStyle/selectors';

import { useCheckDeliveryAddressValid } from '../hooks';
import { BILLING_ADDRESS_COMPONENT, BILLING_ADDRESS_SECTION_ID, USER_ADDRESS_COMPONENT, USER_ADDRESS_SECTION_ID } from './configs';

export const useSetInvalidAddressErrorMessage = () => {
  const dispatch = useDispatch();
  const checkDeliveryAddressValid = useCheckDeliveryAddressValid();
  const { headerHeight } = useSelector(getLayoutElementStyleSelector);

  const isAddressValid = useMemo(() => {
    return checkDeliveryAddressValid?.isAddressValid;
  }, [checkDeliveryAddressValid]);

  const isBillingAddressValid = useMemo(() => {
    return checkDeliveryAddressValid?.isBillingAddressValid;
  }, [checkDeliveryAddressValid]);

  return useCallback(() => {
    let params = {
      section: '',
      isValid: true
    };
    let scrollToSectionId = '';
    if (!isAddressValid) {
      scrollToSectionId = USER_ADDRESS_SECTION_ID;

      params = {
        section: USER_ADDRESS_COMPONENT,
        isValid: false
      };
    } else if (!isBillingAddressValid) {
      scrollToSectionId = BILLING_ADDRESS_SECTION_ID;

      params = {
        section: BILLING_ADDRESS_COMPONENT,
        isValid: false
      };
    }

    // INFO: dispatch error message display when address invalid
    dispatch(setAddressValidErrorMessage(params));

    // INFO: scroll to invalid address section
    if (scrollToSectionId) {
      const sectionEl = document.getElementById(scrollToSectionId);
      const sectionElOffsetTop = sectionEl?.offsetTop;

      window.scrollTo({
        top: sectionElOffsetTop - headerHeight,
        behavior: 'smooth'
      });
    }
  }, [isAddressValid, isBillingAddressValid, headerHeight]);
};
