export default class PickUpPointsModelItem {
  constructor() {
    this.ItemId = '';

    this.AreaName = '';

    this.AreaCode = '';

    this.ProvinceCode = '';

    this.ProvinceName = '';

    this.ItemName = '';

    this.TitleName = '';

    this.AddressName = '';

    this.label = '';

    this.value = '';

    this.NetworkCode = '';

    this.NetworkName = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NetworkCode')) {
      this.NetworkCode = dataSource.NetworkCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NetworkName')) {
      this.NetworkName = dataSource.NetworkName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId')) {
      this.ItemId = dataSource.ItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AreaName')) {
      this.AreaName = dataSource.AreaName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AreaCode')) {
      this.AreaCode = dataSource.AreaCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProvinceCode')) {
      this.ProvinceCode = dataSource.ProvinceCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProvinceName')) {
      this.ProvinceName = dataSource.ProvinceName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemName')) {
      this.ItemName = dataSource.ItemName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TitleName')) {
      this.TitleName = dataSource.TitleName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AddressName')) {
      this.AddressName = dataSource.AddressName;
    }

    return this;
  }
}
