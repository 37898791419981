import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { productTrackingGoalFailed, productTrackingGoalSuccess } from '@redux/actions/trackingGoal/productTracking';
import { PRODUCT_START_TRACKING_GOAL } from '@redux/actions/trackingGoal/productTracking/productTrangkingTypes';
import { postAsObservable } from '@services/genericService';

import * as config from './config';

export const productTrackingGoalEpic = (action$) =>
  action$.pipe(
    ofType(PRODUCT_START_TRACKING_GOAL),
    switchMap((action) => {
      return postAsObservable(config.API_TRACKING_GOAL, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            return productTrackingGoalSuccess(action.payload); 
          } else {
            return productTrackingGoalFailed('Failed');
          }
        }),
        catchError((error) => of(productTrackingGoalFailed(error)))
      );
    })
  );
