import { combineEpics } from 'redux-observable';

import { changeEmailEpic,validateChangeEmailEpic } from './changeEmail';
import {changePasswordEpic} from './changePassword';
import { getStatusNewsletterSubscribeEpic, newsletterSubscribeEpic } from './newsletterSubscribe';
import { updateInfoEpic } from './updateInfo';
import { verificationEmailEpic } from './verificationEmail';

const myAccountEpic = combineEpics(
  updateInfoEpic,
  verificationEmailEpic,
  validateChangeEmailEpic,
  changeEmailEpic,
  changePasswordEpic,
  getStatusNewsletterSubscribeEpic,
  newsletterSubscribeEpic
);

export default myAccountEpic;
