export default class ChangeAreaModel {
  constructor(model) {
    model.map((fieldName) => {
      this[fieldName] = '';
      
      this[`${fieldName}Code`] = '';
    });
  }
  getData(model, dataSource) {
    model.map((fieldName) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, fieldName)) {
        this[fieldName] = dataSource[fieldName];

        this[`${fieldName}Code`] = dataSource?.[`${fieldName}Code`] || '';
      }
    })
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsBilling')) {
      this.IsBilling = dataSource.IsBilling;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsPrimary')) {
      this.IsPrimary = dataSource.IsPrimary;
    }

    return this;
  }
}
