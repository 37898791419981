import PropTypes from 'prop-types';
import React from 'react';

const WellnessListingItem = ({item}) => {

  return item && (
    <div className='featured-article-item'>
      <div className='featured-article-item__wrap'>
        <div className='featured-article-item__image'>
          <a href={item['PageUrl']} className='featured-article-item__image__link'>
            <img src={item['FeaturedImage']} alt={item['Title'] ? item['Title'] : 'feature item image'} className='featured-article-item__image__item'/>
          </a>
        </div>
      </div>
      {
        item.Tags ? (
          <ul className='featured-article-item__tags'>
            {item.TagNames?.map((item ,itemIdx) => {
              return (
                <span className={'featured-article-item__tags__item'} key={itemIdx}>{item}</span>
              );
            })}
          </ul>
        ) : null
      }
      {
        item['Title'] ? (
          <h3 className='featured-article-item__title'>
            <a href={item['PageUrl']} className='featured-article-item__link'>
              {item['Title']}
            </a>
          </h3>
        ) : ''
      }
      
      <div className='featured-article-item__sub-info'>
        {
          item['TimeToRead'] ? <p>{item['TimeToRead']}</p> : ''
        }
        {
          item['PublishedDate'] ? <p>{item['PublishedDate']}</p> : ''
        }
      </div>
    </div>
  );
};

WellnessListingItem.propTypes = {
  item: PropTypes.any,
};

export default WellnessListingItem;