import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = ['Background Color', 'Title', 'Description Title', 'CTA Button Text', ''];
const LINK_ARR = ['CTA Button Link'];

export default class ContactUsCTAModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['items'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      this['items'] = deepCopy(dataSource['items']);
    }

    return this;
  }
}
