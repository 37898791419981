export default class BundleProductVariantStockModel {
  constructor() {
    this.Purchasable= false;
        
    this.PurchasableLabel = '';
    
    this.PurchasableLimit = 0;
    
    this.StockQuantity = 0;
    
    this.StockStatus = {
      Value: 0,
      Name: 'OutOfStock'
    };
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Purchasable')) {
      this.Purchasable = dataSource.Purchasable ? dataSource.Purchasable : this.Purchasable;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PurchasableLabel')) {
      this.PurchasableLabel = dataSource.PurchasableLabel ? dataSource.PurchasableLabel : this.PurchasableLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PurchasableLimit')) {
      this.PurchasableLimit = dataSource.PurchasableLimit ? dataSource.PurchasableLimit : this.PurchasableLimit;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StockQuantity')) {
      this.StockQuantity = dataSource.StockQuantity ? dataSource.StockQuantity : this.StockQuantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StockStatus')) {
      this.StockStatus = dataSource.StockStatus ? dataSource.StockStatus : this.StockStatus;
  
      this.FinalStatus = renderDeliveryLabel(this.StockStatus.Name);
    }
    
    
    return this;
  }
}
    
export const renderDeliveryLabel = (Type) => {
  
  const listLabel = {
    'InStock': 'In Stock Label',
    'PreOrderable': 'In Stock With PreOrder Label',
    'BackOrderable': 'In Stock With BackOrder Label',
    'OutOfStock' : 'Out Stock Label',
  };
  
  const listAvaiable = {
    'InStock': true,
    'PreOrderable': true,
    'BackOrderable': true,
    'OutOfStock' : false,
  };
  
  return { 
    DeliveryLabel: listLabel[Type],
    isAvaiable: listAvaiable[Type],
  };
};
    