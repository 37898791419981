import SitecoreTextModel from '@models/SitecoreTextModel';

export default class WhyChooseOsimModels {
  constructor() {
    this['reasonsToBuy'] = [];

    this['title'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'reasonsToBuy')) {
      this['reasonsToBuy'] = dataSource['reasonsToBuy'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'title')) {
      this['title'] = dataSource['title'] ?? '';
    }

    return this;
  }
}