import produce from 'immer';

import { deepCopy } from '@utils/utility';

import {
  GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA,
  GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_FAILED,
  GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_SUCCESS
} from '../../actions/productForRegisterWarranty/categoryForRegisterWarrantyTypes';

const initialState = {
  isLoading: false,
  productDataForRegister: [],
  error: null
};

const productForRegisterWarrantyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA:
      draft.isLoading = true;

      draft.productDataForRegister = [];
      break;

    case GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_SUCCESS:
      draft.isLoading = false;

      draft.productDataForRegister = deepCopy(
        action.payload.map((val) => {
          return {
            ProductName: val.ProductName,
            ProductPageId: val.ProductPageId.replace(/[{}]/gm, ''),
            CommerceProductId: val.CommerceProductId.replace(/[{}]/gm, ''),
            HasSerialNumber: val.HasSerialNumber
          };
        })
      );
      break;

    case GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default productForRegisterWarrantyReducer;
