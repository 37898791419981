import React from 'react';
import { Fragment } from 'react';

const SimpleCardsShimmer = () => {
  
  return (
    <Fragment>
      <div className='simple-card skeleton__item'>
        <div className='simple-card__content'>
          <div className='container'>
            <h2 className='simple-card__heading experience-title'>
              <span className='text-line'></span>
            </h2>
            <div className='simple-card__list'>
              <div className='row justify-content-center'>
                <div className='col-md-6 col-lg-3'>
                  <div className='simple-card__item'>
                    <div className='simple-card__item__image image-wrap'>
                      <span className='image-line'></span>
                    </div>
                    <div className='simpled-card__item__text'>
                      <span className='text-line'></span>
                      <div className='clear-10'></div>
                      <span className='text-line'></span>
                      <div className='clear-5'></div>
                      <span className='text-line'></span>
                      <div className='clear-5'></div>
                      <span className='text-line'></span>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3'>
                  <div className='simple-card__item'>
                    <div className='simple-card__item__image image-wrap'>
                      <span className='image-line'></span>
                    </div>
                    <div className='simpled-card__item__text'>
                      <span className='text-line'></span>
                      <div className='clear-10'></div>
                      <span className='text-line'></span>
                      <div className='clear-5'></div>
                      <span className='text-line'></span>
                      <div className='clear-5'></div>
                      <span className='text-line'></span>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3'>
                  <div className='simple-card__item'>
                    <div className='simple-card__item__image image-wrap'>
                      <span className='image-line'></span>
                    </div>
                    <div className='simpled-card__item__text'>
                      <span className='text-line'></span>
                      <div className='clear-10'></div>
                      <span className='text-line'></span>
                      <div className='clear-5'></div>
                      <span className='text-line'></span>
                      <div className='clear-5'></div>
                      <span className='text-line'></span>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-lg-3'>
                  <div className='simple-card__item'>
                    <div className='simple-card__item__image image-wrap'>
                      <span className='image-line'></span>
                    </div>
                    <div className='simpled-card__item__text'>
                      <span className='text-line'></span>
                      <div className='clear-10'></div>
                      <span className='text-line'></span>
                      <div className='clear-5'></div>
                      <span className='text-line'></span>
                      <div className='clear-5'></div>
                      <span className='text-line'></span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SimpleCardsShimmer;