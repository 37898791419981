import './WarrantyProductItem.scss';

import Proptypes from 'prop-types';
import React, { useMemo } from 'react';

import ReviewStarRating from '@components/ReviewPage/ReviewStarRating';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { REFERENCE_PURCHASED_PRODUCT } from '@utils/constant';
import Global from '@utils/global';

import { checkIsHideRegisterWarrantyBtn } from '../../MyProductsWidget/utils';

// import { showWarrantyDateLabel } from '../../WarrantyGeneral';

const WarrantyProductItem = ({ fieldData, isProductsWidget, productItem }) => {
  // const expiredDate = new Date(Number.parseInt(productItem?.Warranty?.ExpiredDate?.replace(/Date|\(|\/|\)/gi, '')));
  const convertOrderDate = useMemo(() => {
    const orderDate = new Date(Number.parseInt(productItem?.ProductSubmitDate?.replace(/Date|\(|\/|\)/gi, '')));

    return Global.customDate(orderDate, false);
  }, [productItem]);
  // const convertExpiredDate = Global.customDate(expiredDate);

  const isProductReview = useMemo(() => {
    return productItem?.ReviewId;
  }, [productItem]);

  const isHideRegisterWarrantyBtn = useMemo(() => {
    const warrantyStatus = productItem?.Warranty?.Status;
    const hasWarranty = !productItem?.HasWarranty;

    return checkIsHideRegisterWarrantyBtn({ warrantyStatus, hasWarranty });
  }, [productItem]);
  // const renderWarrantyDateLabel = () => {
  //   if (productItem?.Warranty?.Status === 'Pending') {
  //     return <p className='warranty-product__warranty'>{fieldData['Warranty Pending Label'].value}</p>;
  //   } else if (productItem?.Warranty?.Status === 'Expired') {
  //     return <p className='warranty-product__warranty'>{showWarrantyDateLabel(fieldData['Warranty Expired Label'].value, convertExpiredDate)}</p>;
  //   } else if (productItem?.Warranty?.Status === 'Rejected') {
  //     return <p className='warranty-product__warranty'>{fieldData['Warranty Invalid Label'].value}</p>;
  //   } else {
  //     return <p className='warranty-product__warranty'>{showWarrantyDateLabel(fieldData['Warranty Till Label'].value, convertExpiredDate)}</p>;
  //   }
  // };

  const onClickReviewLink = () => {
    const commerceProductId = productItem?.CommerceProductId;

    if (productItem && fieldData['Write Review Link']?.value?.href) {
      let urlReview = fieldData['Write Review Link']?.value?.href;
      if (commerceProductId) {
        urlReview = `${urlReview}?commerceProductId=${commerceProductId.toUpperCase()}`;
        if (productItem.OrderId) {
          // urlReview = `${urlReview}&reference=${REFERENCE_PURCHASED_PRODUCT}&referenceId=${productItem.ReferenceId}&OrderId=${productItem.OrderId}`;
          urlReview = `${urlReview}&reference=${REFERENCE_PURCHASED_PRODUCT}&MyProductId=${productItem.MyProductId}`;
        }
      } else {
        if (productItem.OrderId) {
          // urlReview = `${urlReview}?reference=${REFERENCE_PURCHASED_PRODUCT}&referenceId=${productItem.ReferenceId}&OrderId=${productItem.OrderId}`;
          urlReview = `${urlReview}&reference=${REFERENCE_PURCHASED_PRODUCT}&MyProductId=${productItem.MyProductId}`;
        }
      }

      window.location.href = urlReview;
    }
  };

  const detailLink = (rootLink) => {
    return rootLink
      ? {
        value: {
          ...rootLink?.value,
          href:
              rootLink?.value?.href +
              '?' +
              (productItem?.ReviewId ? '&ReviewId=' + productItem?.ReviewId : '') +
              (productItem?.ReviewId
                ? ''
                : productItem?.CommerceProductId
                  ? '&commerceProductId=' + productItem?.CommerceProductId?.toUpperCase()
                  : '') +
              // (productItem?.OrderId ? '&OrderId=' + productItem?.OrderId : '') +
              // (productItem?.ReferenceId ? '&ExternalCartLineId=' + productItem?.ReferenceId : '') +
              // (productItem?.ProductType ? '&ProductType=' + productItem?.ProductType : '') +
              ('&IsDisableWriteReview=' + productItem?.IsDisableWriteReview) +
              (productItem?.MyProductId ? '&MyProductId=' + productItem?.MyProductId : '')
        }
      }
      : '';
  };

  return fieldData ? (
    <div className='warranty-product'>
      <div className='warranty-product__wrap'>
        {isProductsWidget ? (
          <div className='my-account-section__group'>
            <div className='warranty-product__group'>
              <p className='warranty-product__order-id'>
                <Text field={fieldData['Order Number Label']} />
                {`: ${productItem?.OrderNumber ? productItem?.OrderNumber : fieldData['Unknown Text']?.value}`}
              </p>
              <p className='warranty-product__order-date'>
                <Text field={fieldData['Order Date Label']} />
                {`: ${productItem?.ProductSubmitDate ? convertOrderDate : fieldData['Unknown Text']?.value}`}
              </p>
            </div>
            <Link className='my-account-section__btn-link enable' field={detailLink(fieldData['Product Detail Link'])}>
              <Text field={fieldData['View Details Label']} />
            </Link>
          </div>
        ) : (
          <div className='warranty-product__group'>
            <p className='warranty-product__order-id'>
              <Text field={fieldData['Order Label']} />
              {`: ${productItem?.OrderNumber ? productItem?.OrderNumber : fieldData['Unknown Text']?.value}`}
            </p>
            <p className='warranty-product__order-date'>
              <Text field={fieldData['Date Label']} />
              {`: ${productItem?.ProductSubmitDate ? convertOrderDate : fieldData['Unknown Text']?.value}`}
            </p>
          </div>
        )}

        <div className='warranty-product__info'>
          <div className='warranty-product__info__left'>
            <div className='warranty-product__image'>
              <Link field={detailLink(fieldData['View Details Link'] || fieldData['Product Detail Link'])}>
                <img src={productItem?.ImageUrl} alt='image' />
              </Link>
            </div>
            <div className='warranty-product__rating d-desktop'>
              <ul className='warranty-product__rating__star'>
                {/* <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-full'></i>
                </li>
                <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-full'></i>
                </li>
                <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-full'></i>
                </li>
                <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-empty'></i>
                </li>
                <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-empty'></i>
                </li> */}
                <ReviewStarRating disableRating defaultRate={isProductReview ? productItem?.ProductReviewRate || 1 : 0} />
              </ul>
              {!productItem?.IsDisableWriteReview && !isProductReview && (
                <span className='warranty-product__rating__review text-blue' onClick={() => onClickReviewLink()}>
                  <Text field={fieldData['Write Review Label']} />
                </span>
              )}
            </div>
          </div>
          <div className='warranty-product__info__right'>
            <Link field={detailLink(fieldData['View Details Link'] || fieldData['Product Detail Link'])}>
              <h3 className='warranty-product__name'>{productItem?.ProductName}</h3>{' '}
            </Link>
            {productItem?.Color ? (
              <p className='warranty-product__color'>
                <Text field={fieldData['Color Label']} />
                {`: ${productItem?.Color}`}
              </p>
            ) : (
              ''
            )}
            {!isHideRegisterWarrantyBtn ? (
              <Link className='warranty-product__warranty warranty-product__warranty--register' field={fieldData['Warranty Registation Link']}>
                <Text field={fieldData['Register Warranty Label']} />
              </Link>
            ) : (
              <></>
            )}
            <div className='warranty-product__rating d-mobi'>
              <ul className='warranty-product__rating__star'>
                {/* <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-full'></i>
                </li>
                <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-full'></i>
                </li>
                <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-full'></i>
                </li>
                <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-empty'></i>
                </li>
                <li className='warranty-product__rating__star__item'>
                  <i className='icon-star-empty'></i>
                </li> */}
                <ReviewStarRating disableRating defaultRate={isProductReview ? productItem?.ProductReviewRate || 1 : 0} />
              </ul>
              {productItem?.IsDisableWriteReview == false && !isProductReview && (
                <span className='warranty-product__rating__review text-blue' onClick={() => onClickReviewLink()}>
                  <Text field={fieldData['Write Review Label']} />
                </span>
              )}
            </div>
            <div className='warranty-product__view-btn d-desktop'>
              <Link field={detailLink(fieldData['View Details Link'] || fieldData['Product Detail Link'])}>
                <Text tag='span' className='btn btn-outline-CTA2' field={fieldData['View Details Label']} />
              </Link>
            </div>
          </div>
        </div>
        <div className='warranty-product__view-btn d-mobi text-center'>
          <Link field={detailLink(fieldData['View Details Link'] || fieldData['Product Detail Link'])}>
            <Text tag='span' className='btn btn-outline-CTA2' field={fieldData['View Details Label']} />
          </Link>
        </div>
      </div>
    </div>
  ) : (
    ''
  );
};

WarrantyProductItem.propTypes = {
  fieldData: Proptypes.any,
  isProductsWidget: Proptypes.any,
  productItem: Proptypes.any
};

export default WarrantyProductItem;
