import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { post } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as config from './config';

const specialDealEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_SPECIAL_DEAL),
    switchMap(() => {
      
      const params = {
        productId: SitecoreContextFactoryService.getValueContextRouteItem('fields')['Commerce Product Source']['fields'].ProductId.value,
        dataType: ['SpecialDeal'],
        itemId: SitecoreContextFactoryService.getValueContextRouteItem('itemId'),
        sortBy:1,
        sortOrder:0
      };

      return from(post(config.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getSpecialDealSuccess(res.data.SpecialDeal);
          } else {
            return actions.getSpecialDealFailed('Failed');
          }
        })
      );
    })
  );

export default specialDealEpic;
