import { GET_CLIENT_SO_CART_FAILURE, GET_CLIENT_SO_CART_START, GET_CLIENT_SO_CART_SUCCESS } from './types';

export const getClientSOCartStart = ({ cartId }) => ({
  type: GET_CLIENT_SO_CART_START,
  payload: {
    cartId: cartId
  }
});

export const getClientSOCartSuccess = (payload) => ({
  type: GET_CLIENT_SO_CART_SUCCESS,
  payload
});

export const getClientSOCartFailure = (payload) => ({
  type: GET_CLIENT_SO_CART_FAILURE,
  payload
});
