import PropTypes from 'prop-types';
import React from 'react';
import { SummarytItem } from './SummaryItem/SummarytItem'

export const SummaryPage = ({ results, nextStep, gotoFirstStep, dataFromLayout }) => {
  return (
    <>
      <div className='help-me-decide__summary'>
        {results?.length > 0 && results.map((result, index) => {
          return (
            <SummarytItem key={index} summarytItemData={result} />
          )
        })}
      </div>
      <div className='help-me-decide__button help-me-decide__button--summary'>
        <button className='btn btn-link help-me-decide__button__left' onClick={gotoFirstStep}>{dataFromLayout['Start Again Text']?.value}</button>
        <button className='btn btn-secondary help-me-decide__button__right' onClick={nextStep} >{dataFromLayout['View Results Text']?.value}</button>
      </div >
    </>
  );
};

SummaryPage.propTypes = {
  results: PropTypes.any,
  nextStep: PropTypes.func,
  gotoFirstStep: PropTypes.func,
  dataFromLayout: PropTypes.any
};


