import './DownloadOSIMAppDetail.scss';

import React, { useEffect,useState } from 'react';
import { useSelector } from 'react-redux';
import { propTypes } from 'react-bootstrap/esm/Image';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';

import DownloadOSIMAppDetailModel from './model/DownloadOSIMAppDetailModel';

const DownloadOSIMAppDetail = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const IsDisableDownloadOsimApp = useSelector((state)=> state?.productWarrantyReducer?.getUserProductDetails?.productDetails?.IsDisableDownloadOsimApp);

  useEffect(() => {
    const model = new DownloadOSIMAppDetailModel();

    setLayoutData(model.getData({ fields }));
  }, []);

  return layoutData && !IsDisableDownloadOsimApp && layoutData?.fields?.['App List']?.length ? (
    <div className='download-osim-widget'>
      <div className='download-osim-header'>
        <Text field={layoutData?.fields?.['Title']} className='download-osim-header__text' tag='h3' />
        <Text field={layoutData?.fields?.['Description']} className='download-osim-header__description' tag='p' />
      </div>
      <div className='download-osim-extend'>
        {layoutData.fields['App List'].map((item) => {
          return (
            <div className='download-osim-extend__item' key={item?.id}>
              {item.fields?.['Icon']?.value ? <>
                <div className='download-osim-extend__item__image'>
                  <Image field={item.fields?.['Icon']} />
                </div>
              </> : <></>}
              <Link field={item?.fields?.['Button Link']}
                className='download-osim-extend__item__link btn btn-outline-CTA2'
              >
                <Text field={item?.fields?.['Button Text']} />
              </Link>
            </div>
          );}
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

DownloadOSIMAppDetail.propTypes = {
  fields: propTypes.any
};

export default DownloadOSIMAppDetail;