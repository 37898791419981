import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = ['Name', 'Area', 'Gift'];

export default class WinnersItemModels {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
