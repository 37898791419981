import './PopUpCouponItem.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

const PopUpCouponItem = (props) => {
  const onOpenDetailListHandler = (e) => {
    props.onOpenDetailListHandler(props.data.Id, e);
  };

  const onSelectCouponHandler = () => {
    props.onSelectCouponHandler(props.data);
  };

  return (
    <div className='coupon-popup__item' onClick={() => onSelectCouponHandler()}>
      <div className={`cart-promotion-code-item ${props.data.Code === props?.isSelected?.Code ? 'applied' : 'no-applied'}`}>
        <div className='cart-promotion-code-item__infor-icon info-icon info-icon--small' onClick={(e) => onOpenDetailListHandler(e)}></div>
        <div className='cart-promotion-code-item__main-info'>
          <h4 className='cart-promotion-code-item__name'>{props.data.Code}</h4>
          <p className='cart-promotion-code-item__description' dangerouslySetInnerHTML={{__html: props.data.Description}}></p>
        </div>
        <div className='cart-promotion-code-item__footer justify-space-between'>
          <div className='cart-promotion-code-item__footer__date'>
            <Text tag='span' field={props.dataFromLayout['Valid Until Label']} />
            {/* fixed coupon display 12/10/2022 */}
            <span> {global.formatDateCoupon(props.data['ValidityTo'])}</span>
          </div>
        </div>
        <div className='left-light'></div>
      </div>
    </div>
  );
};

PopUpCouponItem.propTypes = {
  onOpenDetailListHandler: PropTypes.func,
  data: PropTypes.object,
  onSelectCouponHandler: PropTypes.func,
  isSelected: PropTypes.object,
  dataFromLayout: PropTypes.object
};

export default PopUpCouponItem;
