import { bool, object } from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import useEditAppointmentFormContext from '../useEditAppointmentFormContext';

const SelectTeaCP = ({ layoutData, pastAppointmentEdit }) => {
  const { formik } = useEditAppointmentFormContext();

  const teaOptions = useMemo(() => {
    if(layoutData?.teas) {
      const teaArr = layoutData?.teas?.map((tea) => ({
        value: tea.Id,
        label: tea.Name
      }));

      return teaArr;
    }
  }, [layoutData?.teas]);

  const handleSetTeaSelected = () => {
    return teaOptions.find((item) => item.value === formik.values.teaId.value);
  };

  return teaOptions.length ? (
    <dl className='appointments-infor__item'>
      <Text field={layoutData?.['Select Tea Label']}  tag='dt' className='appointments-infor__item__label w-65' />
      <Select 
        defaultValue={handleSetTeaSelected()}
        isSearchable={false}
        options={teaOptions}
        className='customization-dropdown'
        onChange={(option) => formik.setFieldValue('teaId.value', option.value)}
        classNamePrefix='customization-dropdown'
        isDisabled={pastAppointmentEdit}
      />
      {formik.errors.teaId && formik.touched.teaId &&
        (<span className='error-validate'>{formik.errors.teaId?.value}</span>)}
    </dl>
  ) : (<></>);
};

SelectTeaCP.propTypes = {
  layoutData: object,
  pastAppointmentEdit: bool
};

export default SelectTeaCP;
