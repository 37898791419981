import SitecoreTextModel from '@models/SitecoreTextModel';

import RegisterOneLastStepModel from './RegisterOneLastStepModel';

export default class RegisterLoginModel {
  constructor() {
    this['Login Tab Label'] = new SitecoreTextModel();

    this['Register Tab Label'] = new SitecoreTextModel();

    this['Register One Last Step'] = new RegisterOneLastStepModel();
  }
  getData(dataSource) {
    this['Register One Last Step'].getData(dataSource);
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Tab Label')) {
      this['Login Tab Label'].getData(dataSource['Login Tab Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Tab Label')) {
      this['Register Tab Label'].getData(dataSource['Register Tab Label']);
    }

    return this;
  }
}
