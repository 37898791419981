import './ProductReviewListing.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCustomerReviewsAverage, getProductReview } from '@redux/actions/productReview';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import FullReviewListing from '../FullReviewListing';
import ReviewsSummary from '../ReviewsSummary';
import ProductReviewListingModels from './Models/ProductReviewListingModels';

const ProductReviewListing = ({ fields }) => {
  const TEMPLATE_NAME_STATIC_BUNDLE = 'Product Review Listing Page';
  const dispatch = useDispatch();
  const customerReviewsAverageReducer = useSelector((state) => state.productReviewReducer.customerReviewsAverageReducer);
  const [productReviewListingFields, setProductReviewListingFields] = useState(null);
  const [productNameSingle, setProductNameSingle] = useState(null);
  const [productNameBundle, setProductNameBundle] = useState(null);
  const [productNameBundleActive, setProductNameBundleActive] = useState(null);
  const [productImgBundleActive, setProductImgBundleActive] = useState(null);

  const dataCustomerReviewsAverage = customerReviewsAverageReducer?.customerReviewsAverage?.Data;

  useEffect(() => {
    const productNameSingle = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.productName;
    const productNameBundle = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.productBundle?.bundleName;
    const productNameBundleActive = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.bundleElementActive?.elementName;
    const productImgBundleActive = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.bundleElementActive?.elementImg;

    setProductNameSingle(productNameSingle);

    setProductNameBundle(productNameBundle);

    setProductNameBundleActive(productNameBundleActive);

    setProductImgBundleActive(productImgBundleActive);
  }, []);

  useEffect(() => {
    const productReviewListingModels = new ProductReviewListingModels();

    setProductReviewListingFields(productReviewListingModels.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    const allReviewDetailsProduct = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'));
    const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');

    dispatch(
      getCustomerReviewsAverage({
        productId: allReviewDetailsProduct.productId
      })
    );

    if (templateName !== TEMPLATE_NAME_STATIC_BUNDLE) {
      dispatch(
        getProductReview({
          productId: allReviewDetailsProduct.productId,
          page: 1,
          pageSize: 3
        })
      );
    }
  }, []);

  const onClickBackToProduct = () => {
    window.location.href = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.productLink;
  };

  return (
    productReviewListingFields && (
      <div className='productReviewListing'>
        <div className='container'>
          <div className='productReviewListing__link'>
            <a className='my-account-section__back-link' onClick={() => onClickBackToProduct()}>
              <Text
                field={{
                  value: productReviewListingFields?.['Back To Button Text']?.value?.replace('{ProductName}', productNameSingle || productNameBundle)
                }}
              />
            </a>
          </div>
          {productNameBundleActive && productImgBundleActive && (
            <div className='productReviewListing__info'>
              <div className='productReviewListing__info__image'>
                <img src={productImgBundleActive} alt='imgBundleElement' />
              </div>
              <div className='productReviewListing__info__name'>
                <p>{productNameBundleActive}</p>
              </div>
            </div>
          )}
          <div className='productReviewListing__content'>
            <ReviewsSummary dataApiReviewsSummary={dataCustomerReviewsAverage} fieldsData={productReviewListingFields} />
            <FullReviewListing fieldsData={productReviewListingFields} />
          </div>
        </div>
      </div>
    )
  );
};

ProductReviewListing.propTypes = {
  fields: PropTypes.any
};

export default ProductReviewListing;
