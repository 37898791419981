export default class CartLineStatusModel {
  constructor() {
    this.Code = '';

    this.Errors = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Code')) {
      this.Code = dataSource.Code;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Errors')) {
      this.Errors = dataSource.Errors;
    }

    return this;
  }
}
