import './OSIMBenefit.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import SimpleTilesFieldsModel from './Model/SimpleTilesFieldsModel';
import SimpleTilesItem from './SimpleTilesItem';

const OSIMBenefit = (props) => {
  const {fields } = props;
  const [oSIMBenefitFields, setOSIMBenefitFields] = useState(null);

  useEffect(() => {
    const simpleTilesFieldsModel = new SimpleTilesFieldsModel();

    setOSIMBenefitFields(simpleTilesFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return oSIMBenefitFields ? (
    <div className='OSIM-benefit animated-slow fadeIn delay-100'>
      <div className='container'>
        <div className='OSIM-benefit__title'>
          <Text field={oSIMBenefitFields.Title} tag='h2' className='experience-title'/>
        </div>
        <div className='OSIM-benefit__list'>
          <div className='row'>
            {
              oSIMBenefitFields['items'].length > 0 && oSIMBenefitFields['items'].map((item) => {
                return (
                  <div className='col-md-4' key={item.id}>
                    <SimpleTilesItem fields={item}/>
                  </div>
                );
              })
            }
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='OSIM-benefit skeleton__item'>
      <div className='container'>
        <div className='OSIM-benefit__title'>
          <span className='text-line-40-center w-40'></span>
        </div>
        <div className='OSIM-benefit__list'>
          <div className='row'>
            <LoadingShimmer itemNumb={9}>
              <div className='col-md-4'>
                <div className='OSIM-benefit__item'>
                  <div className='OSIM-benefit__item__icon'>
                    <div className='image-wrap'>
                      <span className='image-line'></span>
                    </div>
                  </div>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <div className='OSIM-benefit__item__button'>
                    <span className='text-line'></span>
                  </div>
                </div>
              </div>
            </LoadingShimmer>
          </div>
        </div>
      </div>
    </div>
  );
};

OSIMBenefit.propTypes = {
  fields: PropTypes.object
};

export default OSIMBenefit;
