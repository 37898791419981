import './ProductItemView.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Link,Text } from '@sitecore-jss/sitecore-jss-react';
import { DECIMAL_COUNT_DEFAULT } from '@utils/constant';
import Global from '@utils/global';

const ProductItemView = ({ productInfomation, dataSourcesLayout }) => {

  return (
    <div className='swiper-slide product-item-view'>
      <div className='product-item-view__content-wrapper'>
        <div className='product-item-view__thumb'>
          <img className='product-item-view__thumb__img swiper-lazy animated' data-src={productInfomation['ProductImage']} />
          <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
        </div>
        <h4 className='product-item-view__name'>{productInfomation['ProductName']}</h4>
      </div>
      <div className='product-item-view__content-wrapper'>
        <div className='product-item-view__selling-price'>
          {productInfomation['CurrencyCode']}
          {Global.formatCurrency(productInfomation['SellingPrice'], DECIMAL_COUNT_DEFAULT)}
        </div>
        <div className='product-item-view__list-price'>
          {productInfomation['CurrencyCode']}
          {Global.formatCurrency(productInfomation['ListPrice'], DECIMAL_COUNT_DEFAULT)}
        </div>
        {dataSourcesLayout['Buy Now Button Text'].value ? (
          <Link className='product-item-view__buy-now btn btn-os-orange' field={dataSourcesLayout['Buy Now Button Link']}>
            <Text field={dataSourcesLayout['Buy Now Button Text']} />
          </Link>
        ) : (
          <Link className='product-item-view__buy-now btn btn-os-orange' field={dataSourcesLayout['Buy Now Button Link']} />
        )}
      </div>
    </div>
  );
};

ProductItemView.propTypes = {
  productInfomation: PropTypes.object,
  dataSourcesLayout: PropTypes.object
};

export default ProductItemView;
