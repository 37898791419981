import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/clientTellingPortalCustomer';
import {
  getCreatePDPAErrorCode,
  keepLifeStyleData,
  removeCpProductInterestFailed,
  removeCpProductInterestSuccess
} from '@redux/actions/clientTellingPortalCustomer';
import * as clientTellingPortalCustomerTypes from '@redux/actions/clientTellingPortalCustomer/clientTellingPortalCustomerTypes';
import {
  REMOVE_CP_PRODUCT_INTEREST
} from '@redux/actions/clientTellingPortalCustomer/clientTellingPortalCustomerTypes';
import * as configCartDetail from '@redux/epic/product/cartDetail/config';
import { get, getAsObservable, post } from '@services/genericService';

import * as config from './config';
import { CP_REMOVE_PRODUCT_INTEREST_ENDPOINT } from './config';

export const getClientTellingPortalCustomerEpic = (action$) =>
  action$.pipe(
    ofType(clientTellingPortalCustomerTypes.GET_CP_CUSTOMER),
    concatMap((action) => {
      const params = {
        contactId: action.payload.id,
        sessionId: action.payload.sessionId
      };

      if (action.payload.isGetCustomerProduct) {
        return from(get(config.ACTION_GET_CP_CUSTOMER, params)).pipe(
          concatMap((res) => {
            let getCPCustomerList;

            if (res.status == 200 && res.data.status_code == 200) {
              getCPCustomerList = actions.getCPCustomerSuccess({ data: res?.data?.contacts, fromURL: action.payload.fromURL });
            } else {
              getCPCustomerList = actions.getCPCustomerFailed('Failed');
            }

            const params = {
              customerId: getCPCustomerList?.payload.data[0]?.contactId || ''
            };

            return merge(
              of(getCPCustomerList),
              from(get(config.ACTION_GET_CUSTOMER_PRODUCTS, params)).pipe(
                map((res) => {
                  if (res.status == 200) {
                    return actions.getCustomerProductsSuccess({
                      data: res?.data,
                      externalId:getCPCustomerList?.payload.data[0]?.externalId || ''
                    });
                  } else return actions.getCustomerProductsFailed('Failed');
                })
              )
            );
          })
        );
      } else {
        return from(get(config.ACTION_GET_CP_CUSTOMER, params)).pipe(
          map((res) => {
            if (res.status == 200) {
              return actions.getCPCustomerSuccess({ data: res?.data?.contacts, fromURL: action.payload.fromURL });
            } else {
              return actions.getCPCustomerFailed('Failed');
            }
          })
        );
      }
    })
  );

export const searchClientTellingPortalCustomerEpic = (action$) =>
  action$.pipe(
    ofType(clientTellingPortalCustomerTypes.SEARCH_CP_CUSTOMER),
    switchMap((action) => {
      const params = {
        term: action.payload.term,
        page: action.payload.page,
        pageSz: action.payload.pageSz
      };

      return getAsObservable(config.ACTION_SEARCH_CP_CUSTOMER, params).pipe(
        map((res) => {
          if (res.status == 200 && res.data.status_code == 200) {
            return actions.searchCPCustomerSuccess(res?.data);
          }
        })
      );
    })
  );

export const getContactPersonalInformationEpic = (action$) =>
  action$.pipe(
    ofType(clientTellingPortalCustomerTypes.GET_CONTACT_PERSONAL_INFORMATION),
    switchMap((action) => {
      const params = {
        ContactId: action.payload.id,
        Property: action.payload.type
      };

      return from(get(config.ACTION_GET_CONTACT_PERSONAL_INFORMATION, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            if (action.payload.type == 'Email') return actions.getContactPersonalInformationSuccess({ data: res.data, type: action.payload.type });
            if (action.payload.type == 'PhoneNumber')
              return actions.getContactPersonalInformationSuccess({ data: res.data, type: action.payload.type });
            if (action.payload.type == 'DOB') return actions.getContactPersonalInformationSuccess({ data: res.data, type: action.payload.type });
          } else {
            return actions.getContactPersonalInformationFailed('Failed');
          }
        })
      );
    })
  );

export const endSessionEpic = (action$) =>
  action$.pipe(
    ofType(clientTellingPortalCustomerTypes.END_SESSION),
    switchMap((action) => {
      return from(post(config.END_SESSION, action.payload)).pipe(
        map((res) => {
          if ((res.status = 200 && res.data.status_code == 200)) {
            return actions.endSessionSuccess(res);
          }
        })
      );
    })
  );

export const updateCPCustomerContactEpic = (action$) =>
  action$.pipe(
    ofType(clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT),
    switchMap((action) => {
      return from(post(config.ACTION_UPDATE_CP_CUSTOMER, action.payload, { 'Content-Type': 'multipart/form-data' }, 'tradein')).pipe(
        concatMap((res) => {
          let getProductInterestList;

          if (res.status == 200 && res.data.success && res.data.status_code == 200 && !res.data.error_code) {
            getProductInterestList = actions.updateCPCustomerContactSuccess(res.data?.contacts);
          } else {
            getProductInterestList = actions.updateCPCustomerContactFailed(res.data?.contacts);
          }

          const params = {
            contactId: action.payload.get('ContactID')
          };

          return merge(
            of(getProductInterestList),
            from(get(config.ACTION_GET_CP_CUSTOMER, params)).pipe(
              map((response) => {
                if (response.status === 200 && !res?.data.error_code) {
                  action.payload?.needReload && window.location.reload();

                  return actions.getCPCustomerSuccess({ data: response?.data?.contacts, fromURL: false });
                } else {
                  return actions.getCPCustomerFailed('Failed');
                }
              })
            ),
            of(actions.getCreatePDPAErrorCode(res.data.error_code))
          );
        })
      );
    })
  );

export const updateCPCustomerContactInterestEpic = (action$) =>
  action$.pipe(
    ofType(clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_INTEREST),
    switchMap((action) => {
      return from(post(configCartDetail.ACTION_UPDATE_CP_CUSTOMER_INTEREST, action.payload, { 'Content-Type': 'multipart/form-data' }, 'tradein')).pipe(
        concatMap((res) => {
          let getProductInterestList;

          if (res.status == 200 && res.data.success && res.data.status_code == 200 && !res.data.error_code) {
            getProductInterestList = actions.updateCPCustomerContactInterestSuccess(res.data?.contacts);
          } else {
            getProductInterestList = actions.updateCPCustomerContactInterestFailed(res.data?.contacts);
          }

          const params = {
            contactId: action.payload.get('ContactID'),
            sessionId: action.payload.get('SessionId')
          };

          return merge(
            of(getProductInterestList),
            from(get(config.ACTION_GET_CP_CUSTOMER, params)).pipe(
              map((response) => {
                if (response.status === 200 && !res?.data.error_code) {
                  action.payload?.needReload && window.location.reload();

                  return actions.getCPCustomerSuccess({ data: response?.data?.contacts, fromURL: false });
                } else {
                  return actions.getCPCustomerFailed('Failed');
                }
              })
            )
          );
        })
      );
    })
  );

export const getCouponWalletEpic = (action$) =>
  action$.pipe(
    ofType(clientTellingPortalCustomerTypes.GET_COUPON_WALLET),
    switchMap((action) => {
      const params = {
        userId: action.payload.externalId,
        email: action.payload.email
      };

      return from(get(config.ACTION_GET_COUPON_LIST, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getCouponWalletSuccess(res.data);
          } else {
            return actions.getCouponWalletFailed('Failed');
          }
        })
      );
    })
  );

// remove product interest
export const removeCpProductInterest = (action$) =>action$.pipe(
  ofType(REMOVE_CP_PRODUCT_INTEREST),
  switchMap((action) => {
    const removeProductParam = {
      ContactId: action.payload.ContactId,
      SessionId: action.payload.SessionId,
      ProductId: action.payload.ProductId
    };

    return from(post(CP_REMOVE_PRODUCT_INTEREST_ENDPOINT, removeProductParam)).pipe(
      concatMap((res) => {
        let removeProductInterest;

        if (res.status === 200 && res.data.success) {
          removeProductInterest = removeCpProductInterestSuccess(res.data.message);
        } else {
          removeProductInterest = removeCpProductInterestFailed(res.data.message);
        }

        const params = {
          contactId: action.payload.ContactId,
          sessionId: action.payload.SessionId
        };

        return merge(
          of(removeProductInterest),
          from(get(config.ACTION_GET_CP_CUSTOMER, params)).pipe(
            map((response) => {
              if (response.status === 200 && !res?.data.error_code) {
                action.payload?.needReload && window.location.reload();

                return actions.getCPCustomerSuccess({ data: response?.data?.contacts, fromURL: false, isRemovingProduct: action.payload.isRemovingProduct });
              } else {
                return actions.getCPCustomerFailed('Failed');
              }
            })
          )
        );
      })
    );
  })
);
