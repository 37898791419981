import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ProductCatalogueItemModel from './model/ProductCatalogueItemModel';

const ProductCatalogueItem = (props) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const model = new ProductCatalogueItemModel();

    setInputData(model.getData(deepCopy(props.data) || {}));
  }, []);

  return (
    inputData && (
      <li className='header__sub-menu-nav__item'>
        <a href={inputData.url} className='main-navigation__item__link'>
          <div className='header__sub-menu-nav__item__image'>
            <Image field={inputData.fields['Menu Image']} />
          </div>
          <Text className='header__sub-menu-nav__item__title product-catelogue__shop-by-categories--title' tag='h3' field={inputData.fields.Title} />
        </a>
      </li>
    )
  );
};

ProductCatalogueItem.propTypes = {
  data: PropTypes.object
};

export default ProductCatalogueItem;
