import './FreeGift.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import MissingDataApi from '@components/Share/MissingDataApi';
import ModalComponent from '@components/Share/ModalComponent';
import SwipeComponent from '@components/Share/SwipeComponent';
import ProductDetailPopUpModel from '@models/apiModels/ProductDetailPopUpModel';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import FreeGiftInfomationPopup from './FreeGiftInfomationPopup';
import FreeGiftModel from './models/FreeGiftModel';

const FreeGift = ({ fields, listFreeGift }) => {
  const [inputData, setInputData] = useState(null);
  const [freeGiftItem, setFreeGiftItem] = useState(null);
  const [isShow, setShow] = useState(false);
  const productDetailPopUpModel = new ProductDetailPopUpModel();

  const onShowHandler = (data) => {
    setShow(true);

    setFreeGiftItem(data);
  };

  const onCloseHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    
    const freeGiftModel = new FreeGiftModel();

    setInputData(freeGiftModel.getData(deepCopy(fields || {})));
  }, []);

  return inputData && (
    <div className='free-gift'>
      <Text className='free-gift__lable' field={inputData['Title']} tag='p' />
      <div className='free-gift__list'>
        {listFreeGift?.length ? 
          <SwipeComponent>
            {listFreeGift.map((freeGiftItem, freeGiftIdx) => {
              return (
                <div className='free-gift__list__item' key={freeGiftIdx}>
                  <div className='free-gift__list__item__image'>
                    <img className='free-gift-image swiper-lazy animated' data-src={productDetailPopUpModel.getData(freeGiftItem).ImageURLs[0]} alt='free gift' />
                    <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
                  </div>
                  <div className='free-gift__list__item__content'>
                    <span className='free-gift__content__title'>{productDetailPopUpModel.getData(freeGiftItem).DisplayName}</span>
                    <Text
                      className='free-gift__content__learn-more'
                      field={inputData['Learn More Text']}
                      tag='span'
                      onClick={() => onShowHandler(productDetailPopUpModel.getData(freeGiftItem))}
                    />
                  </div>
                </div>
              );
            })}
          </SwipeComponent>
          : <MissingDataApi>No data</MissingDataApi>
        }
        <ModalComponent isShow={isShow} onCloseHandler={onCloseHandler}>
          <FreeGiftInfomationPopup freeGiftItem={freeGiftItem} />
        </ModalComponent>
      </div>
    </div>
  );
};

FreeGift.propTypes = {
  fields: PropTypes.any,
  listFreeGift: PropTypes.array
};

const mapStateToProps = (state) => ({
  listFreeGift: state.productReducer.productInformationReducer?.productInformation?.FreeGift?.FreeGifts || []
});

export default connect(mapStateToProps)(FreeGift);
