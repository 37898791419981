import { GET_SCHEDULE_OTP_CODE_FAILURE, GET_SCHEDULE_OTP_CODE_START, GET_SCHEDULE_OTP_CODE_SUCCESS } from './types';

export const getScheduleOtpCodeStart = (payload) => ({
  type: GET_SCHEDULE_OTP_CODE_START,
  payload
});

export const getScheduleOtpCodeSuccess = (payload) => ({
  type: GET_SCHEDULE_OTP_CODE_SUCCESS,
  payload
});

export const getScheduleOtpCodeFailure = (payload) => ({
  type: GET_SCHEDULE_OTP_CODE_FAILURE,
  payload
});
