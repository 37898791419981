// import './BuyButtonComponent.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import Message from '@components/Share/Message';
import withPopup from '@HOC/WithPopup';
// import { PRODUCT_STATUS } from '@utils/constant';

// const NOTIFY_ME = 'Notify me';

const BuyButtonComponent = React.memo(function BuyButtonComponent(props) {
  const { productData, children, setIsPopupShow, setpopUpState, productId } = props;
  const [productBId, setProductBId] = useState('');
  const [type, setType] = useState(null);
  const isLink = productData?.dataSources?.['Notify Button Redirect']?.value;
  const isCNSite = SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OCN';

  useEffect(() => {

    const productIdBtn = 
    productData?.productId ||
    productId || '';

    if (type) {
      setType(type);
    }

    if (productIdBtn) {
      setProductBId(productIdBtn);
    }
  }, [productData]);

  if (productData?.IsBundle) {
    return children;
  } else
  if (productData.Stock?.Purchasable) {
    return children;
  }


  return productData.Stock?.PurchasableLabel && (
    <>
      { isLink && isCNSite ? (
        productData?.dataSources?.['Notify Text']?.value && (
          <Link field={productData?.dataSources?.['Notify Button Link']} className='show-notify-me btn btn-primary'>
            <Text field={productData?.dataSources?.['Notify Text']} />
          </Link>
        )
      ) : (
        <div
          className='show-notify-me btn btn-primary'
          onClick={() => {
            setIsPopupShow(true);

            setpopUpState({ productId: productBId });
          }}
        >
          {/* {productData.Stock?.PurchasableLabel} */}
          <Message messageCode='LBL-NotifyMe' />
        </div>
      )}
      {productData?.isCompare && <a href={productData?.compareUrl}>
        <span>{productData?.compareName} &gt;</span>
      </a>}
    </>
  );
});

BuyButtonComponent.propTypes = {
  productData: PropTypes.any,
  productId: PropTypes.any,
  children: PropTypes.any,
  setIsPopupShow: PropTypes.func,
  setpopUpState: PropTypes.func,
};

export default withPopup(BuyButtonComponent);