import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TextArray = [
  'Title'
];

export default class TierBenefitAnchorModel {
  constructor() {
    TextArray.forEach(text => {
      this[text] = new SitecoreTextModel();
    });

    this['Anchors'] = [];
  }

  getData(dataSource) {
    TextArray.forEach(text => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text] = dataSource[text] ?? '';
      }
    });

    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource,'Anchors')){
      this['Anchors'] = deepCopy(dataSource['Anchors']);
    }

    return this;
  }
}