import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getScheduleOtpCodeStart } from '@redux/actions/bookingExperience/getScheduleOtpCode';

import { useScheduleAppointmentContext } from './useScheduleAppointmentContext';

export const useGetScheduleOtpCode = () => {
  const dispatch = useDispatch();
  const { scheduleData } = useScheduleAppointmentContext();

  return useCallback(
    (mobileNumber) => {
      const params = {
        productId: scheduleData.scheduleExperience.productId,
        mobileNumber: mobileNumber ?? (scheduleData.contactDetail.mobileNumberWithPrefix || scheduleData.contactDetail.mobileNumber)
      };

      dispatch(getScheduleOtpCodeStart(params));
    },
    [scheduleData]
  );
};
