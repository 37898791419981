import Proptypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { getTrackBackground, Range } from 'react-range';
import { useSelector } from 'react-redux';

import * as config from '@components/Search/ProductSearchResult/configs';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

const ProductSearchResultFilterPrice = (props) => {
  const { dataSources, filterPrices, prices, isToogleClearFilter } = props;
  const [price, setPrice] = useState(filterPrices ? [filterPrices.min, filterPrices.max] : [prices.min, prices.max]);

  const positionRangeCurrent = useSelector((state) => state.productReducer.productListReducer.rangePosition);

  const [data, setData] = useState({
    minChange: false,
    maxChange: false,
    ...positionRangeCurrent
  });
  const minPriceRef = useRef();
  const maxPriceRef = useRef();

  const onSelectRangePriceHandler = (priceRange) => {
    props.setSelectRangeEvt({ key: config.PARAMS_SUBMIT.RangePrice, value: priceRange.sort((a, b) => a - b) });
  };

  useDidUpdateEffect(() => {
    setPrice([prices.min, prices.max]);
  }, [isToogleClearFilter]);

  useDidUpdateEffect(() => {
    props.setPositionCurrentEvt(data);
  }, [data]);

  useDidUpdateEffect(() => {
    const distanceBetweenElements = minPriceRef.current.offsetWidth / 2 + maxPriceRef.current.offsetWidth / 2;
    const distanceBetweenElementsCurrent = Global.getDistanceBetweenElements(minPriceRef.current, maxPriceRef.current).toFixed(0);
    if (+distanceBetweenElementsCurrent <= distanceBetweenElements) {
      setData({
        ...data,
        minChange: data.maxChange ? false : true
      });
    } else {
      setData({
        ...data,
        minChange: false,
        maxChange: false
      });
    }
  }, [price[0]]);

  useDidUpdateEffect(() => {
    const distanceBetweenElements = minPriceRef.current.offsetWidth / 2 + maxPriceRef.current.offsetWidth / 2;
    const distanceBetweenElementsCurrent = Global.getDistanceBetweenElements(minPriceRef.current, maxPriceRef.current).toFixed(0);
    if (+distanceBetweenElementsCurrent <= distanceBetweenElements) {
      setData({
        ...data,
        maxChange: data.minChange ? false : true
      });
    } else {
      setData({
        ...data,
        minChange: false,
        maxChange: false
      });
    }
  }, [price[1]]);

  const generateStep = (number) => {
    let numb = number;
    let string = numb.toString();

    if (string.length >= 8) {

      return Math.pow(10, parseInt(string.length - 7));
    } else {
      return config.STEP_RANGE_FILTER;
    }
  };

  return (
    <div className='filter-popup__section'>
      <h3 className='filter-popup__section__heading'>{dataSources.Name.value}</h3>
      <div className='filter-popup__section__price-range'>
        <Range
          disabled={prices.min + 1 === prices.max ? true : false}
          step={generateStep(prices.max)}
          min={prices.min}
          max={prices.max}
          values={price.sort((a, b) => a - b)}
          onChange={(values) => setPrice(values)}
          onFinalChange={onSelectRangePriceHandler}
          renderTrack={({ props, children, disabled }) => (
            <div onMouseDown={props.onMouseDown} onTouchStart={props.onTouchStart}>
              <div
                className='input-range__line'
                ref={props.ref}
                style={{
                  background: getTrackBackground({
                    values: price,
                    colors: disabled ? config.STEP_LINE_BACKGROUND_DISABLED : config.STEP_LINE_BACKGROUND_ACTIVE,
                    min: prices.min,
                    max: prices.max
                  })
                }}
              >
                {children}
              </div>
            </div>
          )}
          renderThumb={({ index, props }) => (
            <div
              className={`input-range ${filterPrices?.max === prices.max && index === 0 ? 'input-range--index' : ''} ${
                price[1].toFixed(2) === prices.min && index === 1 ? 'input-range--index' : ''
              }${index === 0 ? 'min-value' : index === 1 ? 'max-value' : ''}`}
              {...props}
            >
              <span
                className={`input-range__curent ${(data.maxChange && index === 1) || (data.minChange && index === 0) ? 'is-hidden' : ''}`}
                ref={index === 0 ? minPriceRef : index === 1 ? maxPriceRef : ''}
              >
                {prices.min + 1 == prices.max && index === 1 ? (price[index] - 1).toFixed(2) : price[index].toFixed(2)}
              </span>
              {index === 1 && data.maxChange ? <span className='input-range__curent change-position'>{price[1].toFixed(2)}</span> : ''}
              {index === 0 && data.minChange ? <span className='input-range__curent change-position'>{price[0].toFixed(2)}</span> : ''}
            </div>
          )}
        />
      </div>
    </div>
  );
};

ProductSearchResultFilterPrice.propTypes = {
  dataSources: Proptypes.object,
  colors: Proptypes.object,
  setSelectRangeEvt: Proptypes.func,
  prices: Proptypes.object,
  filterPrices: Proptypes.object,
  onMouseDown: Proptypes.any,
  onTouchStart: Proptypes.any,
  ref: Proptypes.any,
  isToogleClearFilter: Proptypes.bool,
  setPositionCurrentEvt: Proptypes.func
};

export default ProductSearchResultFilterPrice;
