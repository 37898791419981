import SitecoreTextModel from '@models/SitecoreTextModel';

export default class TotalOrder {
  constructor() {
    this.Condition = null;

    this['Delivery Subtotal Label'] =  new SitecoreTextModel();

    this['Billing Discount Label'] =  new SitecoreTextModel();

    this['Billing Delivery Fee Label'] =  new SitecoreTextModel();

    this['Billing Sale Tax Label'] = new SitecoreTextModel();

    this['Billing Total Label'] = new SitecoreTextModel();

    this['Billing Total Saving Label'] = new SitecoreTextModel();

    this['Show Sales Tax'] = false;    
  }
  getData(dataSource) {
    this['Delivery Subtotal Label'] = dataSource['Delivery Subtotal Label'] ? dataSource['Delivery Subtotal Label'] : this['Delivery Subtotal Label'];

    this['Billing Discount Label'] = dataSource['Billing Discount Label'] ? dataSource['Billing Discount Label'] : this['Billing Discount Label'];

    this['Billing Delivery Fee Label'] = dataSource['Billing Delivery Fee Label'] ? dataSource['Billing Delivery Fee Label'] : this['Billing Delivery Fee Label'];

    this['Billing Sale Tax Label'] = dataSource['Billing Sale Tax Label'] ? dataSource['Billing Sale Tax Label'] : this['Billing Sale Tax Label'];

    this['Billing Total Label'] = dataSource['Billing Total Label'] ? dataSource['Billing Total Label'] : this['Billing Total Label'];

    this['Billing Total Saving Label'] = dataSource['Billing Total Saving Label'] ? dataSource['Billing Total Saving Label'] : this['Billing Total Saving Label'];

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cart Configuration') && dataSource['Cart Configuration']?.fields) {
      this['Show Sales Tax'] = dataSource['Cart Configuration'].fields['Show Sales Tax']?.value;
    }

    return this;
  }
}
