import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SocialMediaItemModel {
  constructor() {
    this.items = [];

    this['Script Library'] = new SitecoreTextModel();

    this['Copy Link Label'] = new SitecoreTextModel();

    this['Capied Label'] = new SitecoreTextModel();

    this['Share On Label'] = new SitecoreTextModel();

  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      this.items = dataSource.items;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Script Library')) {
      this['Script Library'].getData(dataSource['Script Library']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Capied Label')) {
      this['Capied Label'].getData(dataSource['Capied Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Copy Link Label')) {
      this['Copy Link Label'].getData(dataSource['Copy Link Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Share On Label')) {
      this['Share On Label'].getData(dataSource['Share On Label']);
    }

    return this;
  }
}
