import * as propType from 'prop-types';
import React, {useEffect,useState} from 'react';
import { useSelector } from 'react-redux';

import AppointmentDetailContent from './AppointmentDetailContent/index';
import CancelAppointment from './CancelAppointment/index';
import AppointmentDetailsModel from './models/AppointmentDetailsModel';

const AppointmentDetails = ({fields})=> {
  const [dataSource, setDataSources] = useState(null);
  const isCancelSuccess = useSelector((state) => state.myAppointmentReducer?.cancelAppointmentReducer?.isCancelSuccess);

  useEffect(()=> {
    setDataSources(new AppointmentDetailsModel().getData(fields));
  }, []);

  return dataSource ? (
    <>
      {isCancelSuccess ? (
        <>
          <CancelAppointment onlayoutData={dataSource}/>
        </>
      ) : (
        <AppointmentDetailContent layoutData={dataSource} isCancelSuccess={isCancelSuccess} />
      )}
    </>
  ) : <></>;
};

AppointmentDetails.propTypes = {
  fields: propType.object
};

export default AppointmentDetails;