import { any } from 'prop-types';
import React from 'react';

import { Image } from '@sitecore-jss/sitecore-jss-react';

const AnimatedBannerWithImagesItem = ({ fields }) => {
  return (
    <div className='animated-banner__background-track'>
      {
        fields.map((val, index) => (
          <div key={index} className='animated-banner__background-slide'>
            <Image field={val.fields.Image} />
          </div>
        ))
      }
    </div>
  );
};

AnimatedBannerWithImagesItem.propTypes = {
  fields: any
};

export default AnimatedBannerWithImagesItem;