import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Description',
  'Category Label',
  'Title',
  'Find Serial Number Button Label',
  'Order Number Label',
  'Product Label',
  'Product Serial Number Label',
  'Register Warranty Button Label',
  'Invalid Serial Number Message',
  'Require Serial Number Message',
  'Date Of Purchase Label',
  'Require Select Category Label',
  'Require Select Product Label',
  'My Products Button Text',
  'Cancel Button Text'
];

const LINK_ARR = ['Back Button Link', 'Find Serial Number Button Link', 'Warranty Registration Thank You Link', 'My Products Button Link'];

export default class WarrantyRegistrationModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}
