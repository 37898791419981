import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SimpleSquareFieldsModel {
  constructor() {

    this.Description = new SitecoreTextModel();

    this['Popup Title'] = new SitecoreTextModel();

    this.Title = new SitecoreTextModel();

    this.items = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Popup Title')) {
      this['Popup Title'].getData(dataSource['Popup Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      this.items = dataSource.items;
    }

    return this;
  }
}
