export default class ConfigComponentModel {
  constructor() {
    this['Background Color'] = '';

    this['Column Count'] = '';

    this['Show Buy Option'] = '';

    this['Is Show BuyNow'] = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Color')) {
      this['Background Color'] = dataSource['Background Color'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Column Count')) {
      this['Column Count'] = dataSource['Column Count'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Show Buy Option')) {
      this['Show Buy Option'] = dataSource['Show Buy Option'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Show BuyNow')) {
      this['Is Show BuyNow'] = dataSource['Is Show BuyNow'];
    }

    return this;
  }
}
