import SortOptionModel from './SortOptionModel';

export default class SearchSettingModel {
  constructor() {
    this['Sort Options'] = [];

    this['Facet Fields'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sort Options')) {
      if (dataSource['Sort Options']?.length) {
        dataSource['Sort Options'].map(item => {
          this['Sort Options'].push(new SortOptionModel().getData(item.fields || {}));
        });
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Facet Fields')) {
      if (dataSource['Facet Fields']?.length) {        
        dataSource['Facet Fields'].map(item => {
          const facetItem = new SortOptionModel().getData(item?.fields || {});

          this['Facet Fields'].push(facetItem);
        });
      }
    }
    
    return this;
  }
}
