import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const FootrestMassageUnit = ({ dataSources, isViewTitle }) => {
  return !isViewTitle ? (
    <div className='product-specification__collapse__content'>
      <div className='product-specification__collapse__content__list'>
        <div className='row'>
          {dataSources['Footrest Massage Unit']['Kneading And Rolling Speed Value'] ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Footrest Massage Unit']['Kneading And Rolling Speed Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Footrest Massage Unit']['Kneading And Rolling Speed Value']} />
            </dd> 
          </dl> : ''}
          {dataSources['Footrest Massage Unit']['Kneading And Rolling Speed Value'] ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Footrest Massage Unit']['Kneading And Rolling Speed Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Footrest Massage Unit']['Kneading And Rolling Speed Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Footrest Massage Unit']['Vibration Speed Value'] ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Footrest Massage Unit']['Vibration Speed Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Footrest Massage Unit']['Vibration Speed Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Footrest Massage Unit']['Warm Air Temperature Value'] ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Footrest Massage Unit']['Warm Air Temperature Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Footrest Massage Unit']['Warm Air Temperature Value']} />
            </dd>
          </dl> : ''}
        </div>
      </div>
    </div>
  ) : <Text field={dataSources['Footrest Massage Unit']['Footrest Massage Unit Title Text']}/>;
};

FootrestMassageUnit.propTypes = {
  dataSources: PropTypes.object,
  isViewTitle: PropTypes.bool
};

export default FootrestMassageUnit;
