import { combineReducers } from 'redux';

import changePasswordReducer from './changePassword';
import forgotEmailReducer from './forgotEmail';
import loginReducer from './login';
import newsletterSubscribeReducer from './newsletterSubscribe';
import registerReducer from './register';
import { renewPasswordReducer,  } from './renewPassword';
import updateAccountReducer from './updateAccount';
import userInfoReducer from './userInfo';

export const singleSignOnReducer = combineReducers({
  userInfoReducer,
  renewPasswordReducer,
  registerReducer,
  forgotEmailReducer,
  changePasswordReducer,
  loginReducer,
  updateAccountReducer,
  newsletterSubscribeReducer
});
