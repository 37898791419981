import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';

const STRINGARR = [
  'Address1',
  'Address2',
  'Address3',
  'AddressName',
  'Area',
  'AreaCode',
  'AreaFullAddress',
  'City',
  'CityCode',
  'ContentEncoding',
  'Country',
  'CountryCode',
  'Data',
  'District',
  'DistrictCode',
  'ExpressStoreId',
  'FirstName',
  'FullAddress',
  'LastName',
  'MaxJsonLength',
  'Name',
  'PickUpStoreId',
  'Postcode',
  'RegionCode',
  'State',
  'StateCode',
  'Street',
  'SubDistrict',
  'SubDistrictCode',
  'ZipPostalCode',
  'ExternalId'
];

const BOOLEANARR = ['HasErrors', 'HasInfo', 'HasWarnings', 'IsPrimary', 'Success'];

export default class CartLineAddressModel {
  constructor() {
    STRINGARR.forEach((item) => {
      this[item] = '';
    });

    BOOLEANARR.forEach((item) => {
      this[item] = '';
    });

    this.Errors = [];

    this.Info = [];

    this.Latitude = 0;

    this.Longitude = 0;

    this.PhoneNumber = '';
  }
  getData(dataSource) {
    STRINGARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    BOOLEANARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Errors')) {
      this.Errors = dataSource.Errors ?? this.Errors;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Info')) {
      this.Info = dataSource.Info ?? this.Info;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Latitude')) {
      this.Latitude = dataSource.Latitude ?? this.Latitude;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Longitude')) {
      this.Longitude = dataSource.Longitude ?? this.Longitude;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PhoneNumber')) {
      this.PhoneNumber = dataSource.PhoneNumber ? (isAtSiteName(MY_SITE_NAME) ? `+${dataSource.PhoneNumber}` : dataSource.PhoneNumber) : '';
    }

    return this;
  }
}
