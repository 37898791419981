export const EXITING_CUSTOMER = 'Existing customer';

export const PDPA_LEAD = 'PDPA lead';

export const ANONYMOUS_LEAD = 'Anonymous lead';

export const ANSWER_TYPE = 'Others';

export const ANSWER_WITH_BRACKET_TYPE = `{${ANSWER_TYPE}}: `;

export const QUESTION_TYPE = 'CPCustomerInfo-Multi Select';

export const ANSWER_TO_CHECK = [ANSWER_TYPE, ANSWER_WITH_BRACKET_TYPE];

export const SEARCH_PARAMS_CART_NAME_KEY = 'cartName';

export const SEARCH_PARAMS_CART_ID_KEY = 'cartId';
