import { bool } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';

import DateOfBirth from '@components/SingleSignOn/DateOfBirth';
import { GetOtpStatus } from '@redux/epic/campaignRegister/getOtpCode/responseStatus';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import PhoneNumberSelect from '../../../Share/PhoneNumberSelect';
import { useCampaignContext } from '../../useCampaignContext';
import UpdatePhoneNumberText from './UpdatePhoneNumberText';
import useRegisterUserInfoFormik from './useRegisterUserInfoFormik';

const UserInfoForm = ({ isGetOtpSuccess, isLoginSuccess }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const { getOtpCodeResult, error } = useSelector((state) => state.campaignRegisterReducer.getOtpCodeReducer);
  const { layoutData } = useCampaignContext();
  const accountUserInfo = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.accountUser);
  const formik = useRegisterUserInfoFormik(layoutData?.['campaign']?.Id, layoutData);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  const useAreaCode = useMemo(() => {
    return settingGlobal?.UseAreaCode;
  }, [settingGlobal]);

  const areaCodes = useMemo(() => {
    return settingGlobal?.AreaCodes || [];
  }, [settingGlobal]);

  const isDisablePhoneInput = useMemo(() => {
    return isLoginSuccess && accountUserInfo?.PhoneNumber;
  }, [isLoginSuccess, accountUserInfo]);

  const errorMsgCode = useMemo(() => {
    let msgCode = '';
    if (error) {
      msgCode = GetOtpStatus.ErrorStatusCode[error.StatusCode] || GetOtpStatus.GeneralMsg;
    }

    return msgCode;
  }, [error]);

  const handleInputPhoneChange = useCallback(
    (event) => {
      const phoneNumberWithoutPrefix = event.target.value;
      // const phoneNumberWithPrefix = `${formik.values.phonePrefix || ''}${phoneNumberWithoutPrefix}`;
      if (!phoneNumberWithoutPrefix || new RegExp(settingGlobal.PhoneNumberFormat).test(phoneNumberWithoutPrefix)) {
        formik.setFieldValue('phoneNumber', phoneNumberWithoutPrefix);
      }
    },
    [settingGlobal, formik]
  );

  return (
    <form onSubmit={formik.handleSubmit} className='register-event__form__content'>
      <div className='register-event__form__content__section'>
        <div className={global.renderDynamicClass(formik.errors.firstName && formik.touched.firstName, 'form-group', 'input-error-validate')}>
          <input
            type='text'
            placeholder={layoutData.firstNamePlaceholderText.value}
            className={global.renderDynamicClass(formik.values.firstName, 'form-control form-control-lg', 'input-valid')}
            name='firstName'
            id='firstName'
            autoComplete='off'
            onChange={formik.handleChange}
            value={formik.values.firstName}
            disabled={isLoginSuccess}
          />
          {formik.errors.firstName && formik.touched.firstName && <span className='error-validate'>{formik.errors.firstName}</span>}
        </div>

        {/*<div className={global.renderDynamicClass(formik.errors.lastName && formik.touched.lastName, 'form-group', 'input-error-validate')}>*/}
        {/*  <input*/}
        {/*    type='text'*/}
        {/*    placeholder={layoutData.lastNamePlaceholderText.value}*/}
        {/*    className={global.renderDynamicClass(formik.values.lastName, 'form-control form-control-lg', 'input-valid')}*/}
        {/*    name='lastName'*/}
        {/*    id='lastName'*/}
        {/*    autoComplete='off'*/}
        {/*    onChange={formik.handleChange}*/}
        {/*    value={formik.values.lastName}*/}
        {/*    disabled={isLoginSuccess}*/}
        {/*  />*/}
        {/*  {formik.errors.lastName && formik.touched.lastName && <span className='error-validate'>{formik.errors.lastName}</span>}*/}
        {/*</div>*/}

        <div className={global.renderDynamicClass(formik.errors.dob && formik.touched.dob, 'form-group', 'input-error-validate')}>
          <DateOfBirth setFieldValue={formik.setFieldValue} dobLabel={layoutData.dateOfBirthLabel} isRegister={true} disabled={isLoginSuccess} />
          {/*{formik.errors.dob && formik.touched.dob && <span className='error-validate'>{formik.errors.dob}</span>}*/}
        </div>

        <div className={global.renderDynamicClass(formik.errors.email && formik.touched.email, 'form-group', 'input-error-validate')}>
          <input
            placeholder={layoutData.emailPlaceholderText.value}
            className={global.renderDynamicClass(formik.values.email, 'form-control form-control-lg', 'input-valid')}
            name='email'
            id='email'
            autoComplete='off'
            onChange={formik.handleChange}
            value={formik.values.email}
            disabled={isLoginSuccess}
          />
          {formik.errors.email && formik.touched.email && <span className='error-validate'>{formik.errors.email}</span>}
        </div>

        <div className='form-group contact-detail__body-phone-number-input'>
          <div
            className={
              useAreaCode
                ? `${global.renderDynamicClass(
                  formik.errors.phoneNumber && formik.touched.phoneNumber,
                  'form-group',
                  'input-error-validate'
                )} form-group--group-select-input`
                : global.renderDynamicClass(formik.errors.phoneNumber && formik.touched.phoneNumber, 'form-group', 'input-error-validate')
            }
          >
            {useAreaCode ? (
              <PhoneNumberSelect
                setPhoneNumberCodeEvt={(code) => formik.setFieldValue('phonePrefix', code)}
                optionValues={areaCodes}
                currentValue={formik.values.phonePrefix}
                isDisabled={isDisablePhoneInput}
              />
            ) : (
              <></>
            )}
            <div className='form-group--input'>
              <input
                placeholder={layoutData.mobileNumberPlaceholderText.value}
                className={global.renderDynamicClass(formik.values.phoneNumber, 'form-control form-control-lg', 'input-valid')}
                name='phoneNumber'
                id='phoneNumber'
                autoComplete='off'
                onChange={handleInputPhoneChange}
                value={formik.values.phoneNumber}
                disabled={isDisablePhoneInput}
              />
              {formik.errors.phoneNumber && formik.touched.phoneNumber && <span className='error-validate'>{formik.errors.phoneNumber}</span>}
              {errorMsgCode ? <span className='error-validate'>{messageObj?.[errorMsgCode]}</span> : <></>}
              {getOtpCodeResult?.StatusCode === 3 ? (
                <span className='error-validate'>{messageObj?.[GetOtpStatus.WarningStatusCode[getOtpCodeResult.StatusCode]]}</span>
              ) : (
                <></>
              )}
            </div>
          </div>
          {accountUserInfo?.PhoneNumber && formik.values.phoneNumber ? <UpdatePhoneNumberText /> : <></>}
        </div>
      </div>
      {!isGetOtpSuccess ? (
        <button type='submit' className='btn btn-primary'>
          <Text field={layoutData.getOTPButtonText} />
        </button>
      ) : (
        <></>
      )}
    </form>
  );
};

UserInfoForm.propTypes = {
  isGetOtpSuccess: bool,
  isLoginSuccess: bool
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin
});

export default connect(mapStateToProps)(UserInfoForm);
