import { any } from 'prop-types';
import React, { forwardRef, useMemo } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE, DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD } from '@utils/constant';
import { useFormatDateTime } from '@utils/customsHook/useFormatDate';

import useNotiIconTitle from './useNotiIconTitle';

export const NOTIFICATION_ID_QUERY_STRING = 'notification_id';

const NotificationItem = forwardRef(({ layoutData, notification }, ref) => {
  const notificationTitleIcon = useNotiIconTitle(notification.LogStatus, layoutData);
  const formatDateTimeUtil = useFormatDateTime();

  const notificationDateTime = useMemo(() => {
    const notiDate = new Date(notification.Date);

    return formatDateTimeUtil(notiDate);
  }, [notification]);

  const appointmentDetailUrl = useMemo(() => {
    const notificationId = !notification?.Readed ? `&${NOTIFICATION_ID_QUERY_STRING}=${notification?.Id}` : '';

    return `${layoutData?.['Link Appointment Detail']?.value?.split('Home')[1]}?appointment_id=${notification?.AppointmentId}${notificationId}`;
  }, [notification?.AppointmentId, notification?.Id, notification?.Readed]);

  const notificationItemReadStatus = useMemo(() => {
    return notification?.Readed ? 'notification-popup__item-read' : 'notification-popup__item-unread';
  }, [notification?.Readed]);

  return (
    <div ref={ref} className={`notification-popup__item ${notificationItemReadStatus}`}>
      <div className='notification-popup__item-title'>
        <div className='noti-dot'></div>
        <div className='title-icon'>
          <div className='title-icon__wrapper'>
            <Image field={notificationTitleIcon.icon} className='title-icon__image' />
          </div>
        </div>
        <div className='title-text'>
          <p className='title'>{`${notificationTitleIcon.title} ${notification?.UserName?.replace(
            DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD,
            ''
          )}`}</p>
          <p className='timestamp'>{notificationDateTime}</p>
        </div>
      </div>
      <div className='notification-popup__item-link'>
        <a href={appointmentDetailUrl} className='view-detail-link'>
          <Text field={layoutData?.['View detail Label']} />
        </a>
      </div>
    </div>
  );
});

NotificationItem.displayName = 'NotificationItem';

NotificationItem.propTypes = {
  layoutData: any,
  notification: any
};

export default NotificationItem;
