import ItemsFieldsModel from '@components/SlicingPage/About/Carousel/Model/ItemFieldsModel';

const stringArr = ['displayName', 'id', 'name', 'url'];

export default class FieldsModel {
  constructor() {
    stringArr.forEach((item) => {
      this[item] = '';
    });

    this.fields = new ItemsFieldsModel();
  }

  getData(dataSource) {
    stringArr.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }

    return this;
  }
}
