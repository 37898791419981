import { any, array, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { behaviorSubjectSubmitForm } from '@components/Form/formBehavior';
import { getPhoneNumber } from '@redux/actions/unsubscribe';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import FormFieldError from '../../FormFieldError';
import CustomLabel from '../CustomLabel';

const CustomTelephone = (props) => {
  const [label, setLabel] = useState(false);
  const [border, setBorder] = useState(true);
  const [prefixPhoneNumber, setPrefixPhoneNumber] = useState(null);
  const { field, value, onChange, tracker, errors } = props;
  const phoneNumberFormat = useSelector((state) => state.settingGlobalReducer.settingGlobal?.PhoneNumberFormat);
  const phoneNumberMust = parseInt(useSelector((state) => state.settingGlobalReducer.settingGlobal?.PhoneNumberMust));
  const phoneNumberMinimumMust = parseInt(useSelector((state) => state.settingGlobalReducer.settingGlobal?.PhoneNumberMinimumMust));
  const maximumPhoneDigits = useSelector((state) => state.settingGlobalReducer.settingGlobal?.MaximumPhoneDigits) || '';
  const minimumPhoneDigits = useSelector((state) => state.settingGlobalReducer.settingGlobal?.MinimumPhoneDigits) || '';
  const useAreaCode = useSelector((state) => state.settingGlobalReducer.settingGlobal?.UseAreaCode) || '';
  const areaCodes = useSelector((state) => state.settingGlobalReducer.settingGlobal?.AreaCodes) || [];
  const dispatch = useDispatch();

  useEffect(() => {
    const behaviorSubjectSubmitFormSubscription = behaviorSubjectSubmitForm.subscribe((params) => {
      setLabel(params.label);

      if (areaCodes.length && !prefixPhoneNumber) setPrefixPhoneNumber(areaCodes?.[0]);
    });

    return () => {
      behaviorSubjectSubmitFormSubscription.unsubscribe();
    };
  }, [behaviorSubjectSubmitForm]);

  useDidUpdateEffect(() => {
    if (areaCodes.length && !prefixPhoneNumber) setPrefixPhoneNumber(areaCodes?.[0]);
  }, [areaCodes]);

  const handleOnChangeDropDowm = (newValue) => {
    setPrefixPhoneNumber(newValue);
  };

  const handleOnChange = (field, fieldValue, callback) => {
    let valid = true;
    let errorMessages = [];
    let patt = new RegExp('^[0-9]*$');
    let contactUsPatt = new RegExp(phoneNumberFormat);
    let notNumber = new RegExp('[^0-9]');

    setLabel(fieldValue.length === 0 ? false : true);

    if (props.phoneNumberId?.fieldIdField?.id === field.fieldIdField.id) {
      if (patt.test(fieldValue)) {
        if (field.model.required && !fieldValue) {
          valid = false;

          setBorder(false);

          errorMessages.push(`${field.model.title} is required`);
        } else {
          setBorder(true);
        }

        if (fieldValue) {
          if (minimumPhoneDigits && maximumPhoneDigits) {
            if (fieldValue.length > maximumPhoneDigits || fieldValue.length < minimumPhoneDigits) {
              valid = false;

              setBorder(false);

              errorMessages.push(props.errMsg);
            } else {
              valid = true;

              setBorder(true);

              errorMessages = [];
            }
          } else {
            if (minimumPhoneDigits) {
              if (fieldValue.length < minimumPhoneDigits) {
                valid = false;

                setBorder(false);

                errorMessages.push(props.errMsg);
              } else {
                valid = true;

                setBorder(true);

                errorMessages = [];
              }
            }
            if (maximumPhoneDigits) {
              if (fieldValue.length > maximumPhoneDigits) {
                valid = false;

                setBorder(false);

                errorMessages.push(props.errMsg);
              } else {
                valid = true;

                setBorder(true);

                errorMessages = [];
              }
            }
          }
        } else {
          valid = true;

          setBorder(true);

          errorMessages = [];
        }

        dispatch(
          getPhoneNumber({
            itemValue: field?.valueField?.name || '',
            value: fieldValue
          })
        );
      } else {
        valid = false;

        setBorder(false);

        errorMessages.push(props.errMsg);
      }

      callback(
        field.valueField.name,
        useAreaCode ? prefixPhoneNumber.label + fieldValue.replace(/\D/g, '') : fieldValue.replace(/\D/g, ''),
        valid,
        errorMessages
      );
    } else {
      // const valuePhoneWithPrefix = `${prefixPhoneNumber?.value || ''}${fieldValue}`;

      if (contactUsPatt.test(fieldValue)) {
        if (field.model.required && !fieldValue) {
          valid = false;

          setBorder(false);

          errorMessages.push(`${field.model.title} is required`);
        } else {
          setBorder(true);
        }
        if (phoneNumberMinimumMust && phoneNumberMust) {
          if (fieldValue.length === 0) {
            valid = true;

            setBorder(true);

            errorMessages = [];
          } else if (fieldValue && fieldValue.length < phoneNumberMinimumMust) {
            valid = false;

            setBorder(false);

            errorMessages.push(props.errMsg);
          } else {
            valid = true;

            setBorder(true);

            errorMessages = [];
          }
        }
        if (
          (phoneNumberMinimumMust && !phoneNumberMust && fieldValue && fieldValue.length !== phoneNumberMinimumMust) ||
          (!phoneNumberMinimumMust && phoneNumberMust && fieldValue && fieldValue.length !== phoneNumberMust)
        ) {
          valid = false;

          setBorder(false);

          errorMessages.push(props.errMsg);
        }

        dispatch(
          getPhoneNumber({
            itemValue: field?.valueField?.name || '',
            value: fieldValue
          })
        );
      } else if (notNumber.test(fieldValue) && fieldValue.length - 1 !== phoneNumberMust) {
        valid = false;

        setBorder(false);

        errorMessages.push(props.errMsg);
      } else if (fieldValue.length === 0) {
        setBorder(true);

        dispatch(
          getPhoneNumber({
            itemValue: field?.valueField?.name || '',
            value: fieldValue
          })
        );
      }

      callback(
        field.valueField.name,
        useAreaCode
          ? prefixPhoneNumber.label + fieldValue.toString().slice(0, phoneNumberMust).replace(/\D/g, '')
          : fieldValue.toString().slice(0, phoneNumberMust).replace(/\D/g, ''),
        valid,
        errorMessages
      );
    }
  };

  return (
    <div className={`${useAreaCode ? 'form-group form-group--group-select-input' : 'form-group'}`}>
      {useAreaCode ? (
        <div className='form-group--select'>
          <Select
            defaultValue={areaCodes?.[0]}
            options={areaCodes}
            value={prefixPhoneNumber}
            name='area-selections'
            placeholder=''
            className='customization-dropdown'
            classNamePrefix='customization-dropdown'
            isSearchable={true}
            onChange={(option) => handleOnChangeDropDowm(option)}
            // onFocus={() => tracker.onFocusField(field, value)}
            // onBlur={() => tracker.onBlurField(field, value, errors)}
            // noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
          />
        </div>
      ) : (
        <></>
      )}
      <div className={Global.renderDynamicClass(!border, 'form-group--input', 'input-error-validate')}>
        <input
          type='tel'
          className={Global.renderDynamicClass(label, 'form-control form-control-lg', 'input-valid')}
          id={field.valueField.id}
          name={field.valueField.name}
          value={props.phoneNumber.value}
          placeholder={field.model.placeholderText}
          onChange={(e) => handleOnChange(field, e.target.value, onChange)}
          onFocus={() => tracker.onFocusField(field, value)}
          onBlur={() => tracker.onBlurField(field, value, errors)}
        />
        <CustomLabel {...props} />
        <FormFieldError {...props} />
      </div>
    </div>
  );
};

CustomTelephone.propTypes = {
  field: object,
  value: string,
  onChange: func,
  tracker: object,
  errors: array,
  phoneNumber: any,
  errMsg: string,
  phoneNumberId: object
};

const mapStateToProps = (state) => ({
  phoneNumber: state.unsubscribeReducer.phoneNumber || '',
  errMsg: state.getMessageReducer.objMessages?.['Msg_001.7'] || '',
  phoneNumberId: state.unsubscribeReducer.phoneNumberId || {}
});

export default connect(mapStateToProps)(CustomTelephone);
