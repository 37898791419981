import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import ItemModel from '../Models/ItemModel';

const textLabel = [
  'Description',
  'Title'
];

export default class MobileAppModel {
  constructor() {
    textLabel.map((item) => (this[item] = new SitecoreTextModel()));

    this['App Image'] = new SitecoreImageModel();

    this['App List'] = [];

    this['App Screenshots'] = [];
  }

  getData(dataSource) {
    textLabel.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'App Image')) {
      this['App Image'].getData(dataSource['App Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'App List')) {
      if (dataSource['App List']?.length) {
        dataSource['App List'].map((item) => {
          this['App List'].push(new ItemModel().getData(item || {}));
        });
      }
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'App Screenshots')) {
      if (dataSource['App Screenshots']?.length) {
        dataSource['App Screenshots'].map((item) => {
          this['App Screenshots'].push(new ItemModel().getData(item || {}));
        });
      }
    }

    return this;
  }
}
