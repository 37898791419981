import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const textLabel = [
  'Button Text',
  'Description',
  'Title'
];

export default class ProductManualModel {
  constructor() {
    textLabel.map((item) => (this[item] = new SitecoreTextModel()));

    this['Icon'] = new SitecoreImageModel();

    this['File'] = {};  
  }

  getData(dataSource) {
    textLabel.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon')) {
      this['Icon'].getData(dataSource['Icon']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'File')) {
      this['File'] = dataSource['File'];
    }

    return this;
  }
}
