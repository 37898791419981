import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import ValueModel from './ValueModel';

export default class FieldsModel {
  constructor() {
    this['Value'] = new ValueModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Value')) {
      this['Value'].getData(dataSource['Value']);
    }

    return this;
  }
}
