import { bool, object } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const SelectTimeSlots = (props) => {
  const { formik, disabled, layoutData } = props;
  const storeAppointment = useSelector((state) => state.cpAppointmentDetailReducer.store);
  const timeSlotArr = storeAppointment?.TimeSlots?.TimeSlots;

  return (
    <div className='col-12 col-md-6'>
      <dl className='appointments-infor__item'>
        <Text field={layoutData?.['Select Time Slot Label']} tag='dt' className='appointments-infor__item__label' />
        <dd className='appointments-infor__item__option appointments-infor__item__option--time'>
          {timeSlotArr?.map((time, index) => {
            const timeSlotId = time.Id?.replace(/{|}/g, '').toLowerCase();

            return (
              <div key={index} className={`radio-box radio-box--time ${disabled ? 'select-store__date__option__list__item--disabled' : ''}`}>
                <input
                  className='radio-box__input'
                  name='time'
                  type='radio'
                  id={timeSlotId}
                  defaultChecked={formik.values.timeSlotId?.value === timeSlotId}
                  onChange={() => formik.setFieldValue('timeSlotId.value', timeSlotId)}
                  disabled={disabled}
                />
                <label htmlFor={timeSlotId} className='radio-box__label'>
                  {time?.Value.split(' ')[0]}
                </label>
              </div>
            );
          })}
        </dd>
      </dl>
      {formik?.errors?.timeSlotId && formik?.touched?.timeSlotId && <span className='error-validate'>{formik?.errors?.timeSlotId?.value}</span>}
    </div>
  );
};

SelectTimeSlots.propTypes = {
  formik: object,
  disabled: bool,
  layoutData: object
};

export default SelectTimeSlots;
