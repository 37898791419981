
import './PaymentDetail.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { getPaymentDetail } from '@redux/actions/checkoutProcess';
import { getMyOrdersById } from '@redux/actions/myOrders';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import global from '@utils/global';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import TaxMessageEl from '../../TaxMessageDetail/TaxMessageEl';

const TKPaymentDetail = (props) => {
  const { dataFields, paymentDetail, orderData } = props;
  const [fullPayment, setFullPayment] = useState(null);
  const dispatch = useDispatch();
  const [orderDetail, setOrderDetail] = useState(null);
  const isTWSite = SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OTW';
  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');
  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const fullPath = window.location.href;
    const orderId = global.getQueryParams(fullPath, 'orderId') || null;

    dispatch(getPaymentDetail(orderId));
  }, []);

  useDidUpdateEffect(() => {
    if (paymentDetail && paymentDetail.OrderId) {
      dispatch(getMyOrdersById(paymentDetail.OrderId));
    }

    let temp = deepCopy(dataFields['View Order Detail Link']);

    temp.value.href = temp.value.href.concat(`?OrderId=${paymentDetail?.OrderId}`);

    setOrderDetail(deepCopy(temp));
  }, [paymentDetail]);

  useEffect(() => {
    if (orderData) {
      //Old Logic GA
      // let orderId = global.getQueryParams(window.location.href, 'orderId') || null;

      // window.dataLayer = window.dataLayer || [];

      // // INF: Google analytics
      // let products = [];
      // if (orderData.Lines) {
      //   orderData.Lines.forEach((line) => {
      //     products.push({
      //       name: line?.DisplayName,
      //       id: line?.ProductId,
      //       price: line?.LineFinalTotalAmount,
      //       category: line?.CategoryName,
      //       quantity: parseInt(line?.Quantity)
      //     });
      //   });
      // }
      // let obj = {
      //   ecommerce: {
      //     purchase: {
      //       actionField: {
      //         id: orderId, // Transaction ID. Required for purchases
      //         revenue: paymentDetail.TotalAmount.toString(), // Total transaction value (incl. tax and shipping)
      //         shipping: paymentDetail.ShippingTotalAmount.toString(),
      //         coupon: ''
      //       },
      //       products: products
      //     }
      //   }
      // };

      // window.dataLayer.push(obj);

      // let params = {
      //   items: global.getItemList(orderData.Lines),
      //   affiliation: 'Osim',
      //   transaction_id: paymentDetail?.OrderNumber,
      //   shipping: orderData.ShippingTotalAmount || 0,
      //   value: orderData.TotalAmount || 0,
      //   tax: orderData.TaxTotalAmount || 0,
      //   currency: paymentDetail?.Currency || '',
      // };

      // new GoogleAnalytic().ggGateWay('event', 'purchase', params);

      //New Logic ON/OFF GA, GA4
      if (isGA4) {
        const purchaseParams = {
          ecommerce: {
            transaction_id: paymentDetail?.OrderNumber,
            affiliation: 'OSIM',
            value: orderData.TotalAmount || 0,
            tax: orderData.TaxTotalAmount || 0,
            shipping: orderData.ShippingTotalAmount || 0,
            currency: paymentDetail?.Currency || '',
            coupon: orderData?.PromoCodes?.toString() || '',
            user_type: IsAuthenticated ? 'registered' : 'guest',
            order_type: orderData?.Lines?.[0]?.DeliveryOptions?.[0]?.Name || '',
            shipping_tier: 'Ground',
            payment_type: orderData?.PaymentMethodName,
            items: global.getItems(orderData?.Lines || [], orderData?.PromoCodes?.toString() || '', null, paymentDetail?.Currency)
          }
        };

        new GoogleAnalytic().gAnalytic4('event', 'purchase', purchaseParams);
      } else {
        let orderId = global.getQueryParams(window.location.href, 'orderId') || null;

        window.dataLayer = window.dataLayer || [];

        // INF: Google analytics
        let products = [];
        if (orderData.Lines) {
          orderData.Lines.forEach((line) => {
            products.push({
              name: line?.DisplayName,
              id: line?.ProductId,
              price: line?.LineFinalTotalAmount,
              category: line?.CategoryName,
              quantity: parseInt(line?.Quantity)
            });
          });
        }
        let obj = {
          ecommerce: {
            purchase: {
              actionField: {
                id: orderId, // Transaction ID. Required for purchases
                revenue: paymentDetail.TotalAmount.toString(), // Total transaction value (incl. tax and shipping)
                shipping: paymentDetail.ShippingTotalAmount.toString(),
                coupon: ''
              },
              products: products
            }
          }
        };

        window.dataLayer.push(obj);

        let params = {
          items: global.getItemList(orderData.Lines),
          affiliation: 'Osim',
          transaction_id: paymentDetail?.OrderNumber,
          shipping: orderData.ShippingTotalAmount || 0,
          value: orderData.TotalAmount || 0,
          tax: orderData.TaxTotalAmount || 0,
          currency: paymentDetail?.Currency || ''
        };

        new GoogleAnalytic().ggGateWay('event', 'purchase', params);
      }

      // checkout behavior
      let checkoutParams = {
        items: global.getItemList(orderData.Lines),
        checkout_step: 4,
        checkout_option: 'order review'
      };

      new GoogleAnalytic().ggGateWay('event', 'checkout_progress', checkoutParams);

      if (isTWSite) {
        // GA HK
        let params = {
          send_to: 'AW-773603377/hx-cCMPO2N0CELGA8fAC',
          value: orderData.TotalAmount || 0,
          currency: 'TWD',
          transaction_id: paymentDetail?.OrderNumber
        };

        new GoogleAnalytic().ggGateWay('event', 'conversion', params);

        //Yahoo
        window.dotq = window.dotq || [];

        window.dotq.push({
          projectId: '10000',
          properties: {
            pixelId: '10148586',
            qstrings: {
              et: 'custom',
              ea: '01ButtonCart_20210728'
            }
          }
        });

        // Tamedia
        if (window.taq) {
          window.taq('track', '8wLls6Qyqd0d', 'Purchase');
        }

        //Bridge well
        window._bwq = window._bwq || [];

        window._bwq.push([
          'trackSingle',
          '687-138G445F0MCC238',
          'Purchase',
          {
            transaction_id: '%訂單編號%',
            tags: ['buy_687']
          }
        ]);

        //Facebook pixel
        if (window.fbq) {
          window.fbq('track', 'Purchase', { currency: 'TWD', value: orderData.TotalAmount || 0 });
        }
      }
    }
  }, [orderData]);

  useDidUpdateEffect(() => {
    const isInstallment = paymentDetail?.IsInstallment;
    const temp = isInstallment ? deepCopy(dataFields['Paid In Installment Label']) : deepCopy(dataFields['Paid In Full Label']);
    const paymentsList = orderData?.Payments || [];
    if (paymentsList.length) {
      const paymentString = paymentsList
        .map((paymentItem) => (paymentItem.PaymentMethodName || '') + (paymentItem.CardType ? ` - ${paymentItem.CardType}` : ''))
        .join(', ');

      temp.value = `${temp.value} ${paymentString}`;
    } else {
      paymentDetail?.CardType
        ? (temp.value = `${temp.value} ${paymentDetail?.PaymentMethod} - ${paymentDetail?.CardType}`)
        : (temp.value = `${temp.value} ${paymentDetail?.PaymentMethod}`);
    }

    setFullPayment(deepCopy(temp));
  }, [paymentDetail, orderData]);

  return dataFields && paymentDetail && fullPayment && orderDetail ? (
    <div className='payment-detail thankyou-payment-comp animated-slow fadeIn delay-100'>
      <div className='container thankyou-payment-comp__wrap'>
        <dl className='payment-detail__item'>
          <dt className='payment-detail__item__label text-regular'>
            <Text tag='span' field={dataFields['Confirmation Will Be Sent To Label']} />
          </dt>
          <dd className='payment-detail__item__value'>{paymentDetail.UserEmail}</dd>
        </dl>
        <dl className='payment-detail__item'>
          <dt className='payment-detail__item__label text-regular'>
            <Text tag='span' field={dataFields['Order Number Label']} />
          </dt>
          <dd className='payment-detail__item__value'>{paymentDetail.OrderNumber}</dd>
        </dl>
        <dl className='payment-detail__item'>
          <dt className='payment-detail__item__label text-regular'>
            <Text tag='span' field={dataFields['Delivery Detail Label']} />
          </dt>
          {props.isLogin ? (
            <dd className='payment-detail__item__value'>
              <Link field={orderDetail}>
                <Text field={dataFields['View Order Detail In My Account Page Text']} tag='span' />
              </Link>
            </dd>
          ) : (
            <dd className='payment-detail__item__value'>
              <RichText field={dataFields['Description Delivery Detail']} tag='span' />
            </dd>
          )}
        </dl>
        <dl className='payment-detail__item'>
          <dt className='payment-detail__item__label text-regular'>
            <Text tag='span' field={dataFields['Payment Method Label']} />
          </dt>
          <dd className='payment-detail__item__value'>
            <Text tag='span' field={fullPayment} />
          </dd>
        </dl>
        <div className='payment-detail__item payment-detail__item--summary'>
          <Text tag='h4' className='payment-detail__item__title text-regular' field={dataFields['Summary Label']} />
          <div className='payment-detail__item__line'>
            <Text tag='span' className='payment-detail__item__line__label' field={dataFields['Subtotal Label']} />
            <span className='payment-detail__item__line__value'>{paymentDetail.Subtotal}</span>
          </div>
          <div className='payment-detail__item__line'>
            <Text tag='span' className='payment-detail__item__line__label' field={dataFields['Discount Label']} />
            <span className='payment-detail__item__line__value'>-{paymentDetail.Discount}</span>
          </div>
          <div className='payment-detail__item__line'>
            <Text tag='span' className='payment-detail__item__line__label' field={dataFields['Delivery Fee Label']} />
            <span className='payment-detail__item__line__value'>{paymentDetail.ShippingTotal}</span>
          </div>
          {dataFields['Show Sales Tax'] ? (
            <div className='payment-detail__item__line'>
              <span className='payment-detail__item__line__label'>{dataFields['Sale Tax Label'].value}</span>
              <span className='payment-detail__item__line__value'>{paymentDetail.TaxTotal}</span>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className='payment-detail__total'>
          <Text tag='h4' className='payment-detail__total__label text-regular' field={dataFields['Total Label']} />
          <h4 className='payment-detail__total__value text-regular'>{paymentDetail.Total}</h4>
        </div>
        <div>
          <TaxMessageEl />
        </div>
        {/* <div className='payment-detail__item__line'>
            <span className='payment-detail__item__line__label'>{dataFields['Discount Label'].value}</span>
            <span className='payment-detail__item__line__value'>-{paymentDetail.Discount}</span>
          </div> */}
      </div>
    </div>
  ) : (
    <div className='payment-detail thankyou-payment-comp skeleton__item'>
      <div className='container thankyou-payment-comp__wrap'>
        <dl className='payment-detail__item'>
          <dt className='payment-detail__item__label text-regular'>
            <span className='text-line-30'></span>
          </dt>
          <dd className='payment-detail__item__value'>
            <span className='text-line-24'></span>
          </dd>
        </dl>
        <dl className='payment-detail__item'>
          <dt className='payment-detail__item__label text-regular'>
            <span className='text-line-30'></span>
          </dt>
          <dd className='payment-detail__item__value'>
            <span className='text-line-24'></span>
          </dd>
        </dl>
        <dl className='payment-detail__item'>
          <dt className='payment-detail__item__label text-regular'>
            <span className='text-line-30'></span>
          </dt>
          <dd className='payment-detail__item__value'>
            <span className='text-line-24'></span>
          </dd>
        </dl>
        <dl className='payment-detail__item'>
          <dt className='payment-detail__item__label text-regular'>
            <span className='text-line-30'></span>
          </dt>
          <dd className='payment-detail__item__value'>
            <span className='text-line-24'></span>
          </dd>
        </dl>
        <div className='payment-detail__item payment-detail__item--summary'>
          <h4 className='payment-detail__item__title text-regular'>
            <span className='text-line-30'></span>
          </h4>
          <div className='payment-detail__item__line'>
            <span className='text-line-24'></span>
          </div>
          <div className='payment-detail__item__line'>
            <span className='text-line-24'></span>
          </div>
          <div className='payment-detail__item__line'>
            <span className='text-line-24'></span>
          </div>
        </div>
        <div className='payment-detail__total'>
          <span className='text-line-30'></span>
        </div>
      </div>
    </div>
  );
};

TKPaymentDetail.propTypes = {
  dataFields: Proptypes.any,
  orderData: Proptypes.any,
  paymentDetail: Proptypes.any,
  isLogin: Proptypes.bool
};

const mapStateToProps = (state) => {
  const PaymentDetailReducer = state.checkoutProcesstReducer.paymentDetailReducer;

  return {
    paymentDetail: PaymentDetailReducer.paymentDetail || null,
    orderData: state.myOrderReducer.orderByIdReducer.currentCart,
    isLogin: state.singleSignOnReducer.userInfoReducer.isLogin || null
  };
};

export default connect(mapStateToProps)(TKPaymentDetail);
