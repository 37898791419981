import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CommissionFactoryScriptModel {
  constructor() {
    this['Script'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Script')) {
      this['Script'].getData(dataSource['Script']);
    }

    return this;
  }
}
