import produce from 'immer';

import * as config from '@components/CategoryLanding/ProductListing/config';
import ProductListingApiModel from '@components/CategoryLanding/ProductListing/model/ProductListingApiModel';
// Import action types
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  productList: null,
  facets: null,
  filterLastest: null,
  error: '',
  isLoading: false,
  rangePosition: {}
};

const productListReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let tempProductList = null;
    switch (action.type) {
    case productTypes.GET_PRODUCT_LIST:
      draft.isLoading = true;
      break;

    case productTypes.GET_FILTER_PRODUCT_LIST:
      draft.isLoading = true;
      break;
      
    case productTypes.GET_PRODUCT_LIST_SUCCESS:
      tempProductList = new ProductListingApiModel().getData(deepCopy(action.payload.responses.data));

      draft.productList = tempProductList.ChildProducts;
      if(tempProductList.Facets?.length) {
        if (!action.payload?.isFilter) {
          const maxPriceList = tempProductList.Facets.find(item => item.Name === config.STEP_RANGE_MAX_PRICE);
          const minPriceList = tempProductList.Facets.find(item => item.Name === config.STEP_RANGE_MIN_PRICE);
          
          const mathMaxPriceList = Math.max(...maxPriceList.Details.map(price => +price.Value));
          const mathMinPriceList = Math.min(...minPriceList.Details.map(price => +price.Value));

          draft.filterLastest = null;

          draft.facets = {
            Facets: tempProductList.Facets,
            Price: {
              min:  mathMinPriceList,
              max:  mathMaxPriceList !== mathMinPriceList ? mathMaxPriceList : mathMaxPriceList + 1,
            }
          };
        } else {
          const paramsRequest = deepCopy(action.payload.responses.config.params);

          draft.filterLastest = {...paramsRequest, Price: {min: paramsRequest.PriceFrom, max:  paramsRequest.PriceFrom !==  paramsRequest.PriceTo ?  paramsRequest.PriceTo :  paramsRequest.PriceTo}};
        }
      }
      
      draft.isLoading = false;
      break;

    case productTypes.GET_PRODUCT_LIST_FAILED:
      draft.isLoading = false;

      draft.productList = {};
      
      draft.error = deepCopy(action.error);
      break;

    case productTypes.SET_RANGE_PRICE_POSITION:
      draft.rangePosition = action.payload;
    }

    return draft;
  });

export default productListReducer;
