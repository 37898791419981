import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Date Appointments',
  'Description Selected Product',
  'Name Selected Product',
  'Phone Number Label',
  'Scheduled Visit Label',
  'Selected Product Label',
  'Total Guests',
  'Cancel Successful Label',
  'Explore Product Label',
  'Button Explore Product Text',
  'Email Contact',
  'Name Contact',
  'Phone Contact',
  'Address Label',
  'Button Cancel Appointment Text',
  'Button Change Schedule Text',
  'Button Do Not Cancel Modal Text',
  'Button Review Experience Text',
  'Button Yes Cancel Booking Text',
  'Button Book New Appointment Text',
  'Description Cancel Appointment Model',
  'Description Reason Cancel',
  'ID Appointment',
  'Store Label',
  'Title',
  'Title Cancel Appointment Modal',
  'Button Review Experience Text',
  'Contact Label',
  'Description Send Mail Text',
  'Button New Appointments Text',
  'Your Appointment Cancelled Text',
  'Cancel Appointment Failed Text',
  'Change Product Text',
  'Change Schedule Book Experience Text',
  'Number Of Guest Text',
  'Personlized Experience Label',
  'Appointment Details Label',
  'Selection Label',
  'Change Selection Text',
  'Change Contact Text',
  'Button Review Book Experience Text'
];

const LINK_ARR = [
  'Button Cancel Appointment Link',
  'Button Change Schedule Link',
  'Button Yes Cancel Booking Link',
  'Button Review Experience Link',
  'Button Explore Product Link',
  'Button New Appointments Link',
  'Button Book New Appointment Link',
  'Icon',
  'Booked Icon',
  'Change Product Link',
  'Change Schedule Book Experience Link',
  'Change Selection Link',
  'Change Contact Link',
  'Button Review Book Experience Link'
];

const IMG_ARR = ['BackgroundDesktop', 'BackgroundMobile', 'Scheduled Visit Image'];

export default class AppointmentDetailsModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((link) => {
      this[link] = new SitecoreLinkModel();
    });

    IMG_ARR.forEach((img) => {
      this[img] = new SitecoreImageModel();
    });

    this['List Reason Cancel'] = [];

    this['campaign'] = {};

    this['appointment'] = {};

    this['contact'] = {};

    this['isUpComing'] = false;

    this['questions'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((text) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text] = dataSource[text] ?? '';
      }
    });

    LINK_ARR.forEach((link) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link] = dataSource[link];
      }
    });

    IMG_ARR.forEach((img) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, img)) {
        this[img] = dataSource[img];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'List Reason Cancel')) {
      this['List Reason Cancel'] = dataSource['List Reason Cancel'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'campaign')) {
      this['campaign'] = dataSource['campaign'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'appointment')) {
      this['appointment'] = dataSource['appointment'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'contact')) {
      this['contact'] = dataSource['contact'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'isUpComing')) {
      this['isUpComing'] = dataSource['isUpComing'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'questions')) {
      this['questions'] = dataSource['questions'];
    }

    return this;
  }
}
