import produce from 'immer';

import * as settingGlobalTypes from '@redux/actions/settingGlobal/settingGlobalTypes';

const initState = {
  settingGlobal: null,
  error: '',
  isLoading: false,
};

const settingGlobalReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case settingGlobalTypes.GET_SETTING_GLOBAL:
      break;

    case settingGlobalTypes.GET_SETTING_GLOBAL_SUCCESS:
      draft.settingGlobal = action.payload;
      break;
    
    case settingGlobalTypes.GET_SETTING_GLOBAL_FAILED:
      draft.error = action.error;
    }

    return draft;
  });


export default settingGlobalReducer;
