import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Active',
  'Back to sale orders',
  'Completed',
  'Create and expire date',
  'Draft',
  'Expired',
  'Header',
  'Package count',
  'Send order to customer',
  'Viewed',
  'Sale order is saved',
  'End session',
  'Messaging platform',
  'Email',
  'Sms',
  'Summary',
  'Subtotal',
  'Delivery fee',
  'Discount',
  'Sales tax',
  'Order id',
  'Success message',
  'Customer name text'
];
const LINK_ARR = [
  'End session link',
  'Back to sale orders link'
];
const IMAGE_ARR = ['Success icon'];
const PLAIN_OBJECT = ['SaleOrder'];

export default class SendSaleOrderSuccessModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMAGE_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    PLAIN_OBJECT.forEach((item) => {
      this[item] = null;
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    PLAIN_OBJECT.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = { ...dataSource[item] };
      }
    });

    return this;
  }
}
