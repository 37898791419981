import produce from 'immer';

import * as unsubscribeTypes from '@redux/actions/unsubscribe/unsubscribeTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  isLoading: true,
  isUnsubscribe: false,
  captchaObj: {},
  selectedSelectionValue: null,
  resetCaptcha: false,
  resMsg: null,
  phoneNumber: {
    itemValue: '',
    value: ''
  },
  phoneNumberId: {},
  submitButtonStatus: false,
  loginLink: {}
};

const unsubscribeReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let tempObj = {};
    let tempDropDown = {};
    switch (action.type) {
    case unsubscribeTypes.UN_SUBSCRIBE_NEWS_LETTER_SUCCESS:
      draft.isLoading = false;

      draft.isUnsubscribe = true;
      break;

    case unsubscribeTypes.UN_SUBSCRIBE_NEWS_LETTER_FAILED:
      draft.isLoading = false;

      draft.isUnsubscribe = false;
      break;

    case unsubscribeTypes.GET_CAPTCHA_VALUE:
      draft.captchaObj = {
        ...draft.captchaObj,
        ...action.payload
      };
      break;

    case unsubscribeTypes.GET_SELECTED_SELECTION_VALUE:
      tempObj = deepCopy(action.payload);

      tempDropDown = deepCopy(draft.selectedSelectionValue);

      tempDropDown[Object.keys(tempObj)[0]] = deepCopy(Object.values(tempObj)[0]);

      draft.selectedSelectionValue = deepCopy(tempDropDown);
      break;

    case unsubscribeTypes.RESET_CAPTCHA:
      draft.resetCaptcha = action.payload;
      break;
      
    case unsubscribeTypes.GET_RESPONSE_MESSAGE:
      draft.resMsg = action.payload;
      break;

    case unsubscribeTypes.GET_PHONE_NUMBER:
      draft.phoneNumber = deepCopy(action.payload);
      break;

    case unsubscribeTypes.SET_SUBMIT_BUTTON_STATUS:
      draft.submitButtonStatus = action.payload;
      break;

    case unsubscribeTypes.SET_LOGIN_LINK:
      draft.loginLink = action.payload;
      break;

    case unsubscribeTypes.GET_PHONENUMBER_ID:
      draft.phoneNumberId = action.payload;
      break;

    case unsubscribeTypes.GET_SUBMIT_ACTION:
      draft.isLoading = !action.payload;
      break;
    }
    


    return draft;
  });

export default unsubscribeReducer;
