import produce from 'immer';

import {
  CLEAR_VALIDATE_SCHEDULE_OTP_CODE_ERROR_MESSAGE,
  VALIDATE_SCHEDULE_OTP_CODE_FAILURE,
  VALIDATE_SCHEDULE_OTP_CODE_START,
  VALIDATE_SCHEDULE_OTP_CODE_SUCCESS
} from '@redux/actions/bookingExperience/validateScheduleOtp/types';

const initialState = {
  isLoading: false,
  validateScheduleOtpResponse: null,
  error: null
};

const validateScheduleOtpReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case VALIDATE_SCHEDULE_OTP_CODE_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case VALIDATE_SCHEDULE_OTP_CODE_SUCCESS:
      draft.isLoading = false;

      draft.validateScheduleOtpResponse = action.payload;
      break;

    case VALIDATE_SCHEDULE_OTP_CODE_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

    case CLEAR_VALIDATE_SCHEDULE_OTP_CODE_ERROR_MESSAGE:
      draft.error = null;
      break;
    }

    return draft;
  });

export default validateScheduleOtpReducer;
