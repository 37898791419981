import './TwoColumnCTAButton.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Image,Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import TwoColumnCTAButtonFieldsModel from './Model/TwoColumnCTAButtonFieldsModel';
import TwoColumnCTAButtonShimmer from './TwoColumnCTAButtonShimmer';

const TwoColumnCTAButton = (props) => {
  const {fields } = props;

  const [twoColumnCTAButtonFields, setTwoColumnCTAButtonFields] = useState(null);

  useEffect(() => {
    const twoColumnCTAButtonFieldsModel = new TwoColumnCTAButtonFieldsModel();

    setTwoColumnCTAButtonFields(twoColumnCTAButtonFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return twoColumnCTAButtonFields ? (
    <div className='two-column-CTA-Button animated-slow fadeIn delay-100'>
      <div className='container'>
        <div className='row'>
          <div className='col-6 two-column-CTA-Button__col'>
            <div className='two-column-CTA-Button__contents'>
              <div className='two-column-CTA-Button__contents__image'>
                <Image media={twoColumnCTAButtonFields['First Button Icon']}/>
              </div>
              <p className='two-column-CTA-Button__contents__title'>
                {twoColumnCTAButtonFields['First CTA Button Text'].value
                  ? (
                    <Link field={twoColumnCTAButtonFields['First CTA Button']}>
                      <Text field={twoColumnCTAButtonFields['First CTA Button Text']} tag='span' />
                    </Link>
                  )
                  : (<Link field={twoColumnCTAButtonFields['First CTA Button']}></Link>)}

                {/* <i className='icon-chevron-right-big'></i> */}
              </p>
            </div>
          </div>
          <div className='col-6 two-column-CTA-Button__col'>
            <div className='two-column-CTA-Button__contents'>
              <div className='two-column-CTA-Button__contents__image'>
                <Image media={twoColumnCTAButtonFields['Second Button Icon']}/>
              </div>
              <p className='two-column-CTA-Button__contents__title'>
                {twoColumnCTAButtonFields['Second CTA Button Text'].value
                  ? (
                    <Link field={twoColumnCTAButtonFields['Second CTA Button']}>
                      <Text field={twoColumnCTAButtonFields['Second CTA Button Text']} tag='span' />
                    </Link>
                  )
                  : ( <Link field={twoColumnCTAButtonFields['Second CTA Button']}></Link>)}
                {/* <i className='icon-chevron-right-big'></i> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
    : <LoadingShimmer itemNumb={1}>
      <TwoColumnCTAButtonShimmer></TwoColumnCTAButtonShimmer>
    </LoadingShimmer>;
};

TwoColumnCTAButton.propTypes = {
  fields: PropTypes.object
};

export default TwoColumnCTAButton;