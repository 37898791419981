import produce from 'immer';

import * as productTypes from '@redux/actions/product/productTypes';

const initState = {
  isNotifySuccess: null,
  isLoading: null,
};

const notifyProductStatusReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.GET_NOTIFY_STATUS_PRODUCT:
      draft.isLoading = true;
      
      draft.isNotifySuccess = null;

      break;

    case productTypes.GET_NOTIFY_STATUS_PRODUCT_SUCCESS:

      draft.isNotifySuccess = action.payload;

      draft.isLoading = false;

      break; 

    case productTypes.GET_NOTIFY_STATUS_PRODUCT_FAILED:
      draft.isNotifySuccess = false;
      
      draft.isLoading = false;
      break;

    case productTypes.RESET_NOTIFY_MESSAGE_POPUP:
      draft.isNotifySuccess = null;
        
      draft.isLoading = null;
      break;

    }

    return draft;
  });

export default notifyProductStatusReducer;
