import { any, object } from 'prop-types';
import React from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const MonthDiscountedSwiperItem = ({ item, buyNowLink, buyNowText }) => {
  return (
    <>
      <div className='item'>
        <div className='item__content'>
          <Link className='item__content__link' field={{ href: item?.['Url']}}>
            <img src={item?.['Image']} />
          </Link>
          <h3 className='item__content__title'>
            <Link className='item__content__title__name' field={{ href: item?.['Url']}}>
              <Text field={{ value: item?.['ProductName'] }} />
            </Link>
          </h3>
          <div className='item__content__price'>
            <Text tag='p' className='item__content__price__text item__content__price__text--new' field={{ value: item?.['Price']?.SellingPriceWithCurrency }} />
            {
              item?.Price?.PriceCard > 0 ? (
                <Text tag='del' className='item__content__price__text item__content__price__text--card' field={{ value: item?.['Price']?.PriceCardWithCurrency }} />
              ) : (
                <Text tag='del' className='item__content__price__text item__content__price__text--card' field={{ value: item?.['Price']?.ListPriceWithCurrency }} />
              )
            }
            <Text tag='del' className='item__content__price__text item__content__price__text--cost' field={{ value: item?.['Price']?.ListPriceWithCurrency }} />
          </div>
        </div>
        <div className='item__btn'>
          <Link field={{ href: `${buyNowLink}?productPageId=${item?.Id}`}} className='item__button btn btn-primary'>
            <Text field={buyNowText} />
          </Link>
        </div>
      </div>
    </>
  );
};

MonthDiscountedSwiperItem.propTypes = {
  item: object,
  buyNowLink: any,
  buyNowText: any
};
 
export default MonthDiscountedSwiperItem;
