import React from 'react';
import { Fragment } from 'react';

const MyWishListItemShimmer = () => {

  return (
    <Fragment>
      <article className='article article--horizontal product-item--horizontal skeleton__item'>
        <div className='article__image image-wrap'>
          <div className='image-line'></div>
        </div>
        <div className='article__content'>
          <span className='text-line text-line-header'></span>
          <span className='clear-10'></span>
          <span className='text-line'></span>
          <span className='clear-10'></span>
          <span className='text-line hidden-md-down'></span>
          <span className='clear-10 hidden-md-down'></span>
          <span className='text-line hidden-md-down'></span>
          <span className='clear-10 hidden-md-down'></span>
          <span className='text-line hidden-md-down'></span>
        </div>
        <div className='clear-20 hidden-md-down'></div>
      </article>
    </Fragment>
  );
};

export default MyWishListItemShimmer;