import './StripAds.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import StripAdsFieldsModel from './Model/StripAdsFieldsModel';
// import SwipeComponent from '../SwipeComponent';

const StripAds = ({ fields }) => {
  const [stripAdsFields, setStripAdsFields] = useState(null);
  const [isShow, setIsShow] = useState(true);
  
  const params = {
    direction: 'vertical',
    slidesPerView: 1,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    loop: true,
    speed: 2000,
    autoHeight: true,
  };

  const classCustom = (textColor) => {
    return textColor === 'Black' ? 'strip-ads__content text-dark' : 'strip-ads__content';
  };

  const styleInline = (backgroundColorSetting) => {
    return backgroundColorSetting ? {
      backgroundColor: backgroundColorSetting,
    } : {};
  };

  const autoplaySwiper = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  };

  useEffect(() => {
    const stripAdsFieldsModel = new StripAdsFieldsModel();

    setStripAdsFields(stripAdsFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return stripAdsFields && stripAdsFields.items?.length && isShow ? (
    <div className='strip-ads'>
      <div className='strip-ads__wrap'>
        {/* <div className='container'> */}
        <SwipeComponent param={stripAdsFields.items.length > 1 ? {...params, ...autoplaySwiper} : params}>
          {stripAdsFields.items.map((stripAdsItem, stripAdsIdx) => {
            return (
              <div className={classCustom(stripAdsItem['Text Color'].value || stripAdsFields['Text Color'].value)} style={styleInline(stripAdsItem['Background Color'].value || stripAdsFields['Background Color'].value)} key={stripAdsIdx}>
                <RichText field={stripAdsItem.Content} />
              </div>
            );
          })}
        </SwipeComponent>
        {/* </div> */}
        <span className='strip-ads__close-btn' onClick={() => setIsShow(false)}>
          <i className='strip-ads__close-btn__icon icon-close'></i>
        </span>
      </div>
    </div>
  ) : (
    <></>
  );
};

StripAds.propTypes = {
  fields: PropTypes.object,
};

export default StripAds;
