const colorData = [
  {
    backgroundColor: '#103a59',
    textColor: '#ebe4d2'
  },
  {
    backgroundColor: '#9f44aa',
    textColor: '#ffd980'
  },
  {
    backgroundColor: '#00b7ad',
    textColor: '#fcefcb'
  },
  {
    backgroundColor: '#77e3f1',
    textColor: '#423e43'
  },
  {
    backgroundColor: '#faebc9',
    textColor: '#867a5e'
  },
  {
    backgroundColor: '#f7bd45',
    textColor: '#5b4b25'
  },
  {
    backgroundColor: '#00bcb1',
    textColor: '#fcefcb'
  },
  {
    backgroundColor: '#f92c2a',
    textColor: '#ffd980'
  }
];

const prizeColorData = colorData.map((color) => ({
  backgroundColor: color.backgroundColor,
  textColor: color.textColor
}));

export default prizeColorData;
