import { SET_COOKIE_BANNER_HEIGHT, SET_HEADER_HEIGHT } from './types';

export const setCookieBannerHeight = (payload) => ({
  type: SET_COOKIE_BANNER_HEIGHT,
  payload
});

export const setHeaderHeight = (payload) => ({
  type: SET_HEADER_HEIGHT,
  payload
});
