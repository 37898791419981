import produce from 'immer';

import StoreLocatorListModel from '@components/Share/StoreLocator/model/StoreLocatorListModel';
import * as storeLocatorTypes from '@redux/actions/storeLocator/storeLocatorTypes';
import { SUGGESTED_ADDRESS_NUMBER } from '@utils/constant';
import { deepCopy } from '@utils/utility.js';

const initialState = {
  storeLocatorList: [],
  searchResultList: [],
  suggestionSearchList: [],
  dropdownList: [],
  sortedList: [],
  nonFilteredList: [],
  isLoading: false,
  errorMsg: '',
  activeTab: 'store-locator-listing',
  selectedTab: null,
  hoveredTab: null,
  tokenTab: false,
  myCurrentLocation: {},
  filterObject: null,
  isReset: false,
  searchInput: ''
};

const sortAlphabetFnc = (a, b) => {
  if (a.Name < b.Name) {
    return -1;
  }
  if (a.Name > b.Name) {
    return 1;
  }

  return 0;
};

//clear null and return only Id value
const cleanObj = (target) => {
  return Object.keys(target)
    .filter((key) => target[key])
    .reduce((newObj, key) => {
      newObj[key] = target[key]['Id'];

      return newObj;
    }, {});
};

//compare object with compare condition
const shallowEqual = (obj, check) => {
  const keycheck = Object.keys(check);
  let couter = 0;

  keycheck.map((keyFilter) => {
    if (check[keyFilter] === obj[keyFilter]) {
      couter = couter + 1;
    } else {
      return false;
    }
  });

  return keycheck.length === couter;
};

const deepCompare = (obj, conditionObj) => {
  const keycheck = Object.keys(conditionObj);

  keycheck.every((key) => {
    return conditionObj[key] === obj[key];
  });

  return;
};

const filterStore = (currentArray, filterCondition) => {
  let tempArray = [];

  currentArray.forEach((item) => {
    return tempArray.push(deepCompare(item, filterCondition));
  });
};

const removeEmpty = (obj) => {
  Object.entries(obj).forEach((key) => (!key[1] ? delete obj[key[0]] : 0));

  return obj;
};

const allStoreReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    let tempArray = [];
    let tempSearchResultArr = [];
    let tempDropdownArray = [];

    switch (action.type) {
    case storeLocatorTypes.GET_STORE_LOCATOR:
      draft.isLoading = true;
      break;

    case storeLocatorTypes.GET_STORE_LOCATOR_SUCCESS:
      action.payload.map((element) => {
        tempArray.push(new StoreLocatorListModel().getData(deepCopy(element)));
      });

      // tempArray.sort(sortAlphabetFnc);

      draft.storeLocatorList = tempArray;

      draft.searchResultList = tempArray;

      draft.suggestionSearchList = tempArray;

      draft.dropdownList = tempArray;

      draft.sortedList = tempArray;

      draft.nonFilteredList = tempArray;

      draft.isLoading = false;
      break;

    case storeLocatorTypes.GET_STORE_LOCATOR_FAILED:
      draft.storeLocatorList = [];

      draft.searchResultList = [];

      draft.suggestionSearchList = [];

      draft.dropdownList = [];

      draft.sortedList = [];

      draft.nonFilteredList = [];

      draft.isLoading = false;

      draft.errorMsg = action.payload;
      break;

    case storeLocatorTypes.SET_LOCATED_STORE_LIST:
      tempDropdownArray = action.payload.slice(0, SUGGESTED_ADDRESS_NUMBER);

      draft.searchResultList = action.payload;

      draft.suggestionSearchList = action.payload;

      draft.dropdownList = tempDropdownArray;

      draft.sortedList = action.payload;

      draft.nonFilteredList = action.payload;
      break;

    case storeLocatorTypes.SEARCH_STORE_LOCATOR:
      tempDropdownArray = action.payload.selectedStore.slice(0, SUGGESTED_ADDRESS_NUMBER);

      draft.searchResultList = action.payload.selectedStore;

      draft.nonFilteredList = action.payload.selectedStore;

      draft.searchInput = action.payload.searchInput;

      draft.dropdownList = tempDropdownArray;
      break;

    case storeLocatorTypes.SET_ACTIVE_TAB:
      draft.activeTab = deepCopy(action.payload);
      break;

    case storeLocatorTypes.SET_SELECTED_STORE_ITEM:
      draft.selectedTab = deepCopy(action.payload);

      draft.tokenTab = true;
      break;

    case storeLocatorTypes.SET_HOVERED_STORE_ITEM:
      if (JSON.stringify(action.payload) === JSON.stringify(draft.selectedTab)) {
        draft.hoveredTab = deepCopy(draft.selectedTab);
      } else {
        if (action.payload !== null) {
          draft.hoveredTab = deepCopy(action.payload);

          draft.tokenTab = false;
        } else if (JSON.stringify(draft.selectedTab) === JSON.stringify(draft.hoveredTab)) {
          if (draft.tokenTab) {
            draft.hoveredTab = deepCopy(draft.selectedTab);
          } else {
            draft.hoveredTab = null;
          }
        } else {
          draft.hoveredTab = null;
        }
      }
      break;

    case storeLocatorTypes.GET_MY_CURRENT_LOCATION:
      draft.myCurrentLocation = action.payload;
      break;

    case storeLocatorTypes.APPLY_FILTER_STORE:
      draft.suggestionSearchList = draft.sortedList.filter((item) => shallowEqual(item, removeEmpty(action.payload)));

      tempSearchResultArr = draft.searchInput
        ? draft.sortedList?.filter(
          (item) =>
            item.ItemName.toLowerCase().indexOf(draft.searchInput.toLowerCase()) > -1 ||
            item.Street.toLowerCase().indexOf(draft.searchInput.toLowerCase()) > -1
        )
        : draft.sortedList;

      draft.searchResultList = tempSearchResultArr.filter((item) => shallowEqual(item, removeEmpty(action.payload)));
      
      draft.nonFilteredList = tempSearchResultArr.filter((item) => shallowEqual(item, removeEmpty(action.payload)));

      tempDropdownArray = draft.searchInput
        ? draft.sortedList?.filter(
          (item) =>
            item.ItemName.toLowerCase().indexOf(draft.searchInput.toLowerCase()) > -1 ||
            item.Street.toLowerCase().indexOf(draft.searchInput.toLowerCase()) > -1
        )
        : [];

      tempDropdownArray = tempDropdownArray.slice(0, SUGGESTED_ADDRESS_NUMBER);

      draft.dropdownList = tempDropdownArray.filter((item) => shallowEqual(item, removeEmpty(action.payload)));

      draft.filterObject = deepCopy(action.payload);
      break;

    case storeLocatorTypes.REMOVE_APPLY_FILTER_STORE:
      draft.searchResultList = draft.sortedList;
      
      draft.nonFilteredList = draft.sortedList;

      draft.suggestionSearchList = draft.sortedList;

      draft.filterObject = null;
      break;

    case storeLocatorTypes.RESET_INPUT_VALUE:
      draft.isReset = !draft.isReset;

      draft.searchInput = '';
      break;

    case storeLocatorTypes.GET_STORE_LOCATOR_TYPE_FILTER:
      if (action.payload === 'stores') {
        tempSearchResultArr = draft.nonFilteredList;

        draft.searchResultList = tempSearchResultArr.filter((store) => {
          return store.StoreType === 'Store Detail';
        });
      }
      if (action.payload === 'roadshows') {
        tempSearchResultArr = draft.nonFilteredList;

        draft.searchResultList = tempSearchResultArr.filter((store) => {
          return store.StoreType === 'Road Show Page';
        });
      }
      if (action.payload === 'all') {
        draft.searchResultList = draft.nonFilteredList;
      }
    }

    return draft;
  });

export default allStoreReducer;
