import ProductCardInterfaceModel from '@components/Product/models/ProductCardInterfaceModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

export default class FeaturedProductsFieldsModel {
  constructor() {
    this['Buy Now Button Link'] = new SitecoreLinkModel();

    this['Buy Now Button Text'] = new SitecoreTextModel();

    this['CTA Button'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();

    this['Deposit Button Text'] = new SitecoreTextModel();

    this['Deposit Login Button Text'] = new SitecoreTextModel();

    this['Deposit Login Button Link'] = new SitecoreLinkModel();

    this.Title = new SitecoreTextModel();

    this['Featured Products'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Link')) {
      this['Buy Now Button Link'].getData(dataSource['Buy Now Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Text')) {
      this['Buy Now Button Text'].getData(dataSource['Buy Now Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'].getData(dataSource['CTA Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Button Text')) {
      this['Deposit Button Text'].getData(dataSource['Deposit Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Login Button Text')) {
      this['Deposit Login Button Text'].getData(dataSource['Deposit Login Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Login Button Link')) {
      this['Deposit Login Button Link'].getData(dataSource['Deposit Login Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Featured Products')) {
      let newItemsList = [];

      dataSource['Featured Products'].map((element) => {
        newItemsList.push(new ProductCardInterfaceModel().getData(deepCopy(element) || {}, this['Buy Now Button Link']));
      });

      this['Featured Products'] = newItemsList;
    }

    return this;
  }
}
