import produce from 'immer';

import * as submitPurchaseOnSpotTypes from '@redux/actions/submitPurchaseOnSpot/submitPurchaseOnSpotTypes';

const initState = {
  suggestionList: [],
  error: '',
  isLoading: false,
};

const submitPurchaseOnSpotReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case submitPurchaseOnSpotTypes.SUBMIT_PURCHASE_ON_SPOT_START:
      break;

    case submitPurchaseOnSpotTypes.SUBMIT_PURCHASE_ON_SPOT_SUCCESS:
      draft.suggestionList = action.payload;
      break;
    
    case submitPurchaseOnSpotTypes.SUBMIT_PURCHASE_ON_SPOT_FAILED:
      draft.error = action.error;
    }

    return draft;
  });

export default submitPurchaseOnSpotReducer;
