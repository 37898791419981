import AdditionalOptionsSettingModel from '@components/BuyingOptions/model/AdditionalOptionsSettingModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class BundleBuyingOptionFieldsModel {
  constructor() {
    this['Error Message'] = new SitecoreTextModel();

    this['Error Modal Title'] = new SitecoreTextModel();

    this['Add To Cart Fail Page'] = new SitecoreLinkModel();

    this['Add To Cart Success Page'] = new SitecoreLinkModel();

    this['Add To Cart Text'] = new SitecoreTextModel();

    this['Add Trade In Text'] = new SitecoreTextModel();

    this['Confirm Modal Text'] = new SitecoreTextModel();

    this['Free Gift Label'] = new SitecoreTextModel();

    this['CTA Button'] = new SitecoreLinkModel();

    this['CTA Button Label'] = new SitecoreTextModel();

    this['Change Text'] = new SitecoreTextModel();

    this['Choose Your Free Gift Label'] = new SitecoreTextModel();

    this['Choose Your Color Label'] = new SitecoreTextModel();

    this['Delivery Label'] = new SitecoreTextModel();

    this['Edit Cart Line Text'] = new SitecoreTextModel();

    this['Free Gifts Label'] = new SitecoreTextModel();

    this['Fulfillment Details Label'] = new SitecoreTextModel();

    this['Gift Header Label'] = new SitecoreTextModel();

    this['In Stock Label'] = new SitecoreTextModel();

    this['In Stock With BackOrder Label'] = new SitecoreTextModel();

    this['In Stock With PreOrder Label'] = new SitecoreTextModel();

    this['Out Stock Label'] = new SitecoreTextModel();

    this['Shipping Message'] = new SitecoreTextModel();

    this['Store Availability Message'] = new SitecoreTextModel();

    this['Store Pick Up Label'] = new SitecoreTextModel();

    this['Trade In Instruction'] = new SitecoreTextModel();

    this['Trade In Label'] = new SitecoreTextModel();

    this['Warranty Label'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Out Stock Text'] = new SitecoreTextModel();

    this['Please Select Your Color Message'] = new SitecoreTextModel();

    this['Please Select Your Warranty Message'] = new SitecoreTextModel();

    this['Please Select Your Free Gift Message'] = new SitecoreTextModel();

    this['SF Express Station Label'] = new SitecoreTextModel();

    this['Available Label'] = new SitecoreTextModel();

    this['SF Express Popup Title'] = new SitecoreTextModel();

    this['SF Express Popup Description'] = new SitecoreTextModel();

    this['SF Express Area Label'] = new SitecoreTextModel();

    this['SF Express District Label'] = new SitecoreTextModel();

    this['SF Express NetWork Label'] = new SitecoreTextModel();

    this['Back To Product Detail Label'] = new SitecoreTextModel();

    this['Additional Options Setting'] = new AdditionalOptionsSettingModel();

    this['Cart Link Page'] = new SitecoreLinkModel();

    this['Start Session Prompt Text'] = new SitecoreTextModel();

    this['Start Session Text'] = new SitecoreTextModel();

    this['Back Text'] = new SitecoreTextModel();

    this['Add To Sales Order Text'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Error Modal Title')) {
      this['Error Modal Title'].getData(dataSource['Error Modal Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add To Cart Success Page')) {
      this['Add To Cart Success Page'].getData(dataSource['Add To Cart Success Page']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Free Gift Label')) {
      this['Free Gift Label'].getData(dataSource['Free Gift Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Choose Your Free Gift Label')) {
      this['Choose Your Free Gift Label'].getData(dataSource['Choose Your Free Gift Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add To Cart Fail Page')) {
      this['Add To Cart Fail Page'].getData(dataSource['Add To Cart Fail Page']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add To Cart Text')) {
      this['Add To Cart Text'].getData(dataSource['Add To Cart Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add Trade In Text')) {
      this['Add Trade In Text'].getData(dataSource['Add Trade In Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Confirm Modal Text')) {
      this['Confirm Modal Text'].getData(dataSource['Confirm Modal Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Label')) {
      this['CTA Button Label'].getData(dataSource['CTA Button Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Text')) {
      this['Change Text'].getData(dataSource['Change Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Choose Your Color Label')) {
      this['Choose Your Color Label'].getData(dataSource['Choose Your Color Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Label')) {
      this['Delivery Label'].getData(dataSource['Delivery Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Cart Line Text')) {
      this['Edit Cart Line Text'].getData(dataSource['Edit Cart Line Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Free Gifts Label')) {
      this['Free Gifts Label'].getData(dataSource['Free Gifts Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Fulfillment Details Label')) {
      this['Fulfillment Details Label'].getData(dataSource['Fulfillment Details Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Gift Header Label')) {
      this['Gift Header Label'].getData(dataSource['Gift Header Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'In Stock Label')) {
      this['In Stock Label'].getData(dataSource['In Stock Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'In Stock With BackOrder Label')) {
      this['In Stock With BackOrder Label'].getData(dataSource['In Stock With BackOrder Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'In Stock With PreOrder Label')) {
      this['In Stock With PreOrder Label'].getData(dataSource['In Stock With PreOrder Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'In Stock With PreOrder Label')) {
      this['In Stock With PreOrder Label'].getData(dataSource['In Stock With PreOrder Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Out Stock Label')) {
      this['Out Stock Label'].getData(dataSource['Out Stock Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Shipping Message')) {
      this['Shipping Message'].getData(dataSource['Shipping Message']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Store Availability Message')) {
      this['Store Availability Message'].getData(dataSource['Store Availability Message']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Store Pick Up Label')) {
      this['Store Pick Up Label'].getData(dataSource['Store Pick Up Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Trade In Instruction')) {
      this['Trade In Instruction'].getData(dataSource['Trade In Instruction']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Trade In Label')) {
      this['Trade In Label'].getData(dataSource['Trade In Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Warranty Label')) {
      this['Warranty Label'].getData(dataSource['Warranty Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Out Stock Text')) {
      this['Out Stock Text'].getData(dataSource['Out Stock Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Error Message')) {
      this['Error Message'].getData(dataSource['Error Message']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Please Select Your Color Message')) {
      this['Please Select Your Color Message'].getData(dataSource['Please Select Your Color Message']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Please Select Your Warranty Message')) {
      this['Please Select Your Warranty Message'].getData(dataSource['Please Select Your Warranty Message']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Please Select Your Free Gift Message')) {
      this['Please Select Your Free Gift Message'].getData(dataSource['Please Select Your Free Gift Message']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SF Express Station Label')) {
      this['SF Express Station Label'].getData(dataSource['SF Express Station Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Available Label')) {
      this['Available Label'].getData(dataSource['Available Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SF Express Popup Title')) {
      this['SF Express Popup Title'].getData(dataSource['SF Express Popup Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SF Express Popup Description')) {
      this['SF Express Popup Description'].getData(dataSource['SF Express Popup Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SF Express Area Label')) {
      this['SF Express Area Label'].getData(dataSource['SF Express Area Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SF Express District Label')) {
      this['SF Express District Label'].getData(dataSource['SF Express District Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SF Express NetWork Label')) {
      this['SF Express NetWork Label'].getData(dataSource['SF Express NetWork Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Back To Product Detail Label')) {
      this['Back To Product Detail Label'].getData(dataSource['Back To Product Detail Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Options Setting')) {
      this['Additional Options Setting'].getData(dataSource['Additional Options Setting']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cart Link Page')) {
      this['Cart Link Page'].getData(dataSource['Cart Link Page']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Start Session Prompt Text')) {
      this['Start Session Prompt Text'].getData(dataSource['Start Session Prompt Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Start Session Text')) {
      this['Start Session Text'].getData(dataSource['Start Session Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Back Text')) {
      this['Back Text'].getData(dataSource['Back Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add To Sales Order Text')) {
      this['Add To Sales Order Text'].getData(dataSource['Add To Sales Order Text']);
    }

    return this;
  }
}
