import * as types from './productTrangkingTypes';

export const productStartTrackingGoal = (payload) => ({
  type: types.PRODUCT_START_TRACKING_GOAL,
  payload
});

export const productTrackingGoalSuccess = (payload) => ({
  type: types.PRODUCT_TRACKING_GOAL_SUCCESS,
  payload
});

export const productTrackingGoalFailed = (payload) => ({
  type: types.PRODUCT_TRACKING_GOAL_FAILED,
  payload
});