import './ProductListComponent.scss';

import PropTypes from 'prop-types';
import React from 'react';

import FreeGiftItem from '../../../FreeGiftPopup/FreeGiftItem';
import ProductListItem from './ProductListItem';

const ProductListComponent = ({type, productList, layoutData, page, productItem}) => {
  return (
    <div className='product-list large-text'>
      <h3 className='delivery-item-title'>
        {type === 'delivery' || type === 'deposit'
          ? layoutData['Delivery Product List Label'].value
          : type === 'sf-express'
            ? layoutData['SF Pickup Product List Label'].value
            : ''}
      </h3>
      {type === 'pick-up-store' ? (
        <>
          <ProductListItem layoutData={layoutData} data={productList} page={page}></ProductListItem>
        </>
      ) : type === 'deposit' ? (
        <>
          {/*{productItem?.IsAutoAddFreeGiftPopup || productItem?.IsFreeGiftPopup*/}
          {/*  ? productItem && (*/}
          {/*    <FreeGiftItem*/}
          {/*      key={productItem.ExternalCartLineId}*/}
          {/*      dataSources={layoutData}*/}
          {/*      freeGiftName={productItem.DisplayName}*/}
          {/*      freeGiftThumbnail={productItem.Image}*/}
          {/*      lineFinalTotal={productItem.LineFinalTotal}*/}
          {/*      productPageLink={productItem.ProductPageLink}*/}
          {/*      promotionTitle={productItem.PromotionText}*/}
          {/*      isEnableEdit={!productItem.IsAutoAddFreeGiftPopup}*/}
          {/*      isOutOfStock={productItem.IsFreeGiftPopup && productItem.StockStatus.Value === 2}*/}
          {/*      page={page}*/}
          {/*    ></FreeGiftItem>*/}
          {/*  )*/}
          {/*  : productItem && (*/}
          {productItem ? (
            <ProductListItem layoutData={layoutData} key={productItem.ExternalCartLineId} data={productItem} page={page}></ProductListItem>
          ) : (
            <></>
          )}
          {/*)}*/}
        </>
      ) : (
        <>
          {productList.map(
            (item) => (
              // item.IsAutoAddFreeGiftPopup || item.IsFreeGiftPopup ? (
              //   <FreeGiftItem
              //     key={item.ExternalCartLineId}
              //     dataSources={layoutData}
              //     freeGiftName={item.DisplayName}
              //     freeGiftThumbnail={item.Image}
              //     lineFinalTotal={item.LineFinalTotal}
              //     productPageLink={item.ProductPageLink}
              //     promotionTitle={item.PromotionText}
              //     isEnableEdit={!item.IsAutoAddFreeGiftPopup}
              //     isOutOfStock={item.IsFreeGiftPopup && item.StockStatus.Value === 2}
              //     page='delivery'
              //     isFreeGiftPopupCartPage={item.IsFreeGiftPopupCartPage}
              //   ></FreeGiftItem>
              // ) : (
              <ProductListItem layoutData={layoutData} key={item.ExternalCartLineId} data={item} page={page}></ProductListItem>
            )
            // )
          )}
        </>
      )}
    </div>
  );
};

ProductListComponent.propTypes = {
  type: PropTypes.string,
  productList: PropTypes.any,
  productItem: PropTypes.any,
  layoutData: PropTypes.any,
  page: PropTypes.string,
};

export default ProductListComponent;
