import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { getAsObservable } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as config from './config';

export const productListEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_PRODUCT_LIST),
    switchMap((action) => {
      const params = {
        categoryId: SitecoreContextFactoryService.getValueContextItem('itemId')
      };
      if (String(action.payload.sortByPiv)) params.SortByPIV = action.payload.sortByPiv;

      return getAsObservable(config.ACTION, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getProductListSuccess({
              responses: res,
              isFilter: false
            });
          } else {
            return actions.getProductListFailed('Failed');
          }
        })
      );
    })
  );

export const getFilterProductListEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_FILTER_PRODUCT_LIST),
    switchMap((action) => {
      return getAsObservable(config.ACTION, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getProductListSuccess({
              responses: res,
              isFilter: true
            });
          } else {
            return actions.getProductListFailed('Failed');
          }
        })
      );
    })
  );
