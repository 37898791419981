export const GetScheduleOtpStatus = {
  SuccessStatusCode: [0, 3],
  WarningStatusCode: {
    3: 'Msg_040'
  },
  ErrorStatusCode: {
    18: 'Msg_043'
  },
  GeneralMsg: 'Msg_042'
};
