import './PaymentSuccessful.scss';

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const TKPaymentStatus = ({ dataFields }) => {

  return dataFields && (
    <Fragment>
      <div className='payment-success'>
        <div className='container text-center'>
          <div className='payment-success__image'>
            <img src={require('@assets/images/success-icon.png')} alt='green tick' />
          </div>
          <Text field={dataFields['Payment Successfull Label']} tag='h3' editable={true} className='payment-success__text' />
          <Text field={dataFields['Thank You For Your Order Label']} tag='h3' editable={true} className='payment-success__thankyou' />
          <Text field={dataFields['Processing Now Text']} tag='p' editable={true} className='payment-success__desc text-center' />
        </div>
      </div>
    </Fragment>
  );
};

TKPaymentStatus.propTypes = {
  dataFields: PropTypes.object
};

export default TKPaymentStatus;
