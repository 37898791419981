import './LoadingMore.scss';

import React from 'react';

const LoadingMore = () => {
  return (
    <div className='lds-ellipsis'>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingMore;
