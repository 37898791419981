import produce from 'immer';

import * as spinAndWinTypes from '@redux/actions/spinAndWin/spinAndWinTypes';

const initState = {
  isLoading: false,
  couponData: null,
  isSuccess: null,
};

const applyCouponSpinReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case spinAndWinTypes.APPLY_REWARD:
      draft.isLoading = true;
      break;

    case spinAndWinTypes.APPLY_REWARD_SUCCESS:
      draft.isLoading = false;

      draft.isSuccess = action.payload.IsSuccess;

      draft.couponData = action.payload.Coupon;
      break;

    case spinAndWinTypes.APPLY_REWARD_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default applyCouponSpinReducer;
