import PropTypes from 'prop-types';
import React  from 'react';

import {RichText,Text} from '@sitecore-jss/sitecore-jss-react';

const VerifyThroughEmail = ({dataSources, setVerifyOldEmail}) => {

  return (
    <div className='contact-info'>
      <div className='container'>
        <div className='contact-info__wrap'>
          <div className='security-verification text-center'>
            <div className='security-verification__image'>
              <img src={require('@assets/images/security-icon.png')} alt='security'/>
            </div>
            <RichText className='security-verification__info' field={dataSources['Verify Email Message']}/>
            <div className='contact-info__btn text-center'>
              <div className='contact-info__btn__item'>
                <button className='btn btn-outline-CTA2' onClick={() => setVerifyOldEmail()}>
                  <Text field={dataSources['Verify Email Button Text']}/>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

VerifyThroughEmail.propTypes = {
  dataSources: PropTypes.object,
  setVerifyOldEmail: PropTypes.func
};

export default VerifyThroughEmail;
