import '../../Banner/MainBanner.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef,useState } from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { LAYOUT_FULL, LIST_CLASS_NAME__DESKTOP, LIST_CLASS_NAME__MOBILE, TEXT_COLOR_WHITE } from '@utils/constant';

const SliderBannersItem = ({ data }) => {
  const [windowWidth, setWindowWidth] = useState(1920);
  const refVideo = useRef(null);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    if (!refVideo.current) {
      return;
    }

    refVideo.current.defaultMuted = !data.fields['Enable Audio'];

    refVideo.current.muted = !data.fields['Enable Audio'];

  }, [data.fields]);

  const classRenderMobile = (data) => {
    return LIST_CLASS_NAME__MOBILE[data] || '';
  };

  const classRenderDesktop = (data) => {
    return LIST_CLASS_NAME__DESKTOP[data] || '';
  };

  const renderLink = () => {
    return (
      <>
        {data.fields['Banner Click Hyperlink'].value.href.length ? (
          <Link field={data.fields['Banner Click Hyperlink']}>
            <div
              className={
                data.fields['Text Color']?.value === TEXT_COLOR_WHITE
                  ? 'main-banner text-white animated-slow fadeIn delay-100'
                  : 'main-banner animated-slow fadeIn delay-100'
              }
            >
              <div className={data.fields['Layout'] == LAYOUT_FULL ? 'main-banner__background no-ratio' : 'main-banner__background'}>
                {windowWidth > 768 && data.fields['Background video'].value.href.length ? (
                  <video className='slider-video d-desktop' playsInline muted='muted' loop ref={refVideo} autoPlay>
                    <source src={data.fields['Background video'].value.href} type='video/mp4' />
                  </video>
                ) : (
                  <></>
                )}
                {windowWidth < 768 && data.fields['Mobile Background Video'].value.href.length ? (
                  <video className='slider-video d-mobi' playsInline muted='muted' loop ref={refVideo} autoPlay>
                    <source src={data.fields['Mobile Background Video'].value.href} type='video/mp4' />
                  </video>
                ) : (
                  <></>
                )}
                {!data.fields['Background video'].value.href.length ? (
                  <ResponsiveImage
                    imageMobile={data.fields['Mobile Background Image']}
                    srcSet={[{ mw: 768, image: data.fields['Background Image'] }]}
                  />
                ) : (
                  <></>
                )}
              </div>
              <div className='main-banner__content'>
                <div
                  className={
                    'container main-banner__content__container content-position ' +
                    classRenderMobile(data.fields['Mobile Text Position'].value) +
                    ' ' +
                    classRenderDesktop(data.fields['Desktop Text Position'].value)
                  }
                >
                  <div className='main-banner__content__wrap content-wrapper'>
                    {data.fields.Icon?.value?.src || data.fields['Second Icon']?.value?.src ? (
                      <div className='main-banner__content__logo'>
                        {data.fields.Icon.value.src ? (
                          <div className='main-banner__content__logo__image'>
                            <Image media={data.fields.Icon} />
                          </div>
                        ) : (
                          <></>
                        )}
                        {data.fields['Second Icon'].value.src ? (
                          <div className='main-banner__content__logo__image'>
                            <Image media={data.fields['Second Icon']} />
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {data.fields['Sub Title'].value ? (
                      <div className='main-banner__content__sub-title'>
                        <Text field={data.fields['Sub Title']} tag='span' />
                      </div>
                    ) : (
                      <></>
                    )}
                    <Text field={data.fields.Title} tag='h2' className='main-banner__content__title' />
                    <RichText className='main-banner__content__desc' field={data.fields.Description} />
                    {data.fields['CTA Hyperlink Button Text'].value ? (
                      <Link
                        className={data.fields['Text Color']?.value === TEXT_COLOR_WHITE ? 'btn-link btn-link-white' : 'btn-link'}
                        field={data.fields['CTA Hyperlink Button']}
                      >
                        <Text field={data.fields['CTA Hyperlink Button Text']} />
                      </Link>
                    ) : (
                      <></>
                    )}
                    {data.fields['CTA Button Text'].value ? (
                      <div className='main-banner__content__btn'>
                        <Link
                          className={
                            data.fields['Text Color']?.value === TEXT_COLOR_WHITE
                              ? 'main-banner__content__btn__item btn btn-outline-white'
                              : 'main-banner__content__btn__item btn btn-outline-primary'
                          }
                          field={data.fields['CTA Button']}
                        >
                          <Text field={data.fields['CTA Button Text']} />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ) : (
          <div
            className={
              data.fields['Text Color']?.value === TEXT_COLOR_WHITE
                ? 'main-banner text-white animated-slow fadeIn delay-100'
                : 'main-banner animated-slow fadeIn delay-100'
            }
          >
            <div className={data.fields['Layout'] == LAYOUT_FULL ? 'main-banner__background no-ratio' : 'main-banner__background'}>
              {windowWidth > 768 && data.fields['Background video'].value.href.length ? (
                <video className='slider-video d-desktop' playsInline muted='muted' loop ref={refVideo} autoPlay>
                  <source src={data.fields['Background video'].value.href} type='video/mp4' />
                </video>
              ) : (
                <></>
              )}
              {windowWidth < 768 && data.fields['Mobile Background Video'].value.href.length ? (
                <video className='slider-video d-mobi' playsInline muted='muted' loop ref={refVideo} autoPlay>
                  <source src={data.fields['Mobile Background Video'].value.href} type='video/mp4' />
                </video>
              ) : (
                <></>
              )}
              {!data.fields['Background video'].value.href.length ? (
                <ResponsiveImage
                  imageMobile={data.fields['Mobile Background Image']}
                  srcSet={[{ mw: 768, image: data.fields['Background Image'] }]}
                />
              ) : (
                <></>
              )}
            </div>
            <div className='main-banner__content'>
              <div
                className={
                  'container main-banner__content__container content-position ' +
                  classRenderMobile(data.fields['Mobile Text Position'].value) +
                  ' ' +
                  classRenderDesktop(data.fields['Desktop Text Position'].value)
                }
              >
                <div className='main-banner__content__wrap content-wrapper'>
                  {data.fields.Icon?.value?.src || data.fields['Second Icon']?.value?.src ? (
                    <div className='main-banner__content__logo'>
                      {data.fields.Icon.value.src ? (
                        <div className='main-banner__content__logo__image'>
                          <Image media={data.fields.Icon} />
                        </div>
                      ) : (
                        <></>
                      )}
                      {data.fields['Second Icon'].value.src ? (
                        <div className='main-banner__content__logo__image'>
                          <Image media={data.fields['Second Icon']} />
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  ) : (
                    <></>
                  )}
                  {data.fields['Sub Title'].value ? (
                    <div className='main-banner__content__sub-title'>
                      <Text field={data.fields['Sub Title']} tag='span' />
                    </div>
                  ) : (
                    <></>
                  )}
                  <Text field={data.fields.Title} tag='h2' className='main-banner__content__title' />
                  <RichText className='main-banner__content__desc' field={data.fields.Description} />
                  {data.fields['CTA Hyperlink Button Text'].value ? (
                    <Link
                      className={data.fields['Text Color']?.value === TEXT_COLOR_WHITE ? 'btn-link btn-link-white' : 'btn-link'}
                      field={data.fields['CTA Hyperlink Button']}
                    >
                      <Text field={data.fields['CTA Hyperlink Button Text']} />
                    </Link>
                  ) : (
                    <></>
                  )}
                  {data.fields['CTA Button Text'].value ? (
                    <div className='main-banner__content__btn'>
                      <Link
                        className={
                          data.fields['Text Color']?.value === TEXT_COLOR_WHITE
                            ? 'main-banner__content__btn__item btn btn-outline-white'
                            : 'main-banner__content__btn__item btn btn-outline-primary'
                        }
                        field={data.fields['CTA Button']}
                      >
                        <Text field={data.fields['CTA Button Text']} />
                      </Link>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  return windowWidth ? renderLink() : <></>;
};

SliderBannersItem.propTypes = {
  data: PropTypes.object
};

export default SliderBannersItem;
