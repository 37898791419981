import * as storeLocatorTypes from './storeLocatorTypes';

//GET STORE LOCATOR
export const getStoreLocator = (payload) => ({
  type: storeLocatorTypes.GET_STORE_LOCATOR,
  payload
});

export const getStoreLocatorSuccess = (payload) => ({
  type: storeLocatorTypes.GET_STORE_LOCATOR_SUCCESS,
  payload
});

export const getStoreLocatorFailed = (payload) => ({
  type: storeLocatorTypes.GET_STORE_LOCATOR_FAILED,
  payload
});

//SEARCH STORE LOCATOR
export const searchStoreLocator = (payload) => ({
  type: storeLocatorTypes.SEARCH_STORE_LOCATOR,
  payload
});

//GET AREA
export const getFilterAreaList = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_AREA_LIST,
  payload
});

export const getFilterAreaListSuccess = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_AREA_LIST_SUCCESS,
  payload
});

export const getFilterAreaListFailed = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_AREA_LIST_FAILED,
  payload
});

//GET PROVINCE
export const getFilterProvinceList = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_PROVINCE_LIST,
  payload
});

export const getFilterProvinceListSuccess = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_PROVINCE_LIST_SUCCESS,
  payload
});

export const getFilterProvinceListFailed = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_PROVINCE_LIST_FAILED,
  payload
});

export const clearFilterProvinceList = (payload) => ({
  type: storeLocatorTypes.CLEAR_FILTER_PROVINCE_LIST,
  payload
});

//GET CITY
export const getFilterCityList = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_CITY_LIST,
  payload
});

export const getFilterCityListSuccess = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_CITY_LIST_SUCCESS,
  payload
});

export const getFilterCityListFailed = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_PROVINCE_LIST_FAILED,
  payload
});

export const clearFilterCityList = (payload) => ({
  type: storeLocatorTypes.CLEAR_FILTER_CITY_LIST,
  payload
});

//GET ZIP CODE
export const getFilterZipCode = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_ZIP_CODE,
  payload
});

export const getFilterZipCodeSuccess = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_ZIP_CODE_SUCCESS,
  payload
});

export const getFilterZipCodeFailed = (payload) => ({
  type: storeLocatorTypes.GET_FILTER_ZIP_CODE_FAILED,
  payload
});

//GET Estimate shipping fee
export const getEstimateShippingFee = (payload) => ({
  type: storeLocatorTypes.GET_ESTIMATE_SHIPPING_FEE,
  payload
});

export const getEstimateShippingFeeSuccess = (payload) => ({
  type: storeLocatorTypes.GET_ESTIMATE_SHIPPING_FEE_SUCCESS,
  payload
});

export const getEstimateShippingFeeFailed = (payload) => ({
  type: storeLocatorTypes.GET_ESTIMATE_SHIPPING_FEE_FAILED,
  payload
});

export const setActiveTab = (payload) => ({
  type: storeLocatorTypes.SET_ACTIVE_TAB,
  payload
});

export const setSelectedStoreItem = (payload) => ({
  type: storeLocatorTypes.SET_SELECTED_STORE_ITEM,
  payload
});

export const setHoveredStoreItem = (payload) => ({
  type: storeLocatorTypes.SET_HOVERED_STORE_ITEM,
  payload
});

export const getMyCurrentLocation = (payload) => ({
  type: storeLocatorTypes.GET_MY_CURRENT_LOCATION,
  payload
});

export const applyFilterStore = (payload) => ({
  type: storeLocatorTypes.APPLY_FILTER_STORE,
  payload
});

export const removeApplyFilterStore = (payload) => ({
  type: storeLocatorTypes.REMOVE_APPLY_FILTER_STORE,
  payload
});

export const resetInputValue = () => ({
  type: storeLocatorTypes.RESET_INPUT_VALUE
});

export const setLocatedStoreList = (payload) => ({
  type: storeLocatorTypes.SET_LOCATED_STORE_LIST,
  payload
});

export const getStoreLocatorTypeFilter = (payload) => ({
  type: storeLocatorTypes.GET_STORE_LOCATOR_TYPE_FILTER,
  payload
});
