import { object } from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import { SIMPLE_CTA_CONTAINER_BOX_ELEMENT, useTruncateDescription } from '../SimpleCTA/hooks/useTruncateDescription';
import ProductManualDownloadModel from './models/ProductManualDownloadModel';
import ProductManualDownloadFooter from './ProductManualDownloadFooter';

const ProductManualDownload = ({ fields }) => {
  const descriptionElRef = useRef(null);
  const truncateDescription = useTruncateDescription();
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    setLayoutData(new ProductManualDownloadModel().getData(fields));
  }, []);

  useLayoutEffect(() => {
    const descriptionElement = descriptionElRef?.current;
    if (layoutData && descriptionElement) {
      const truncateDescriptionArgument = {
        descriptionElement: descriptionElement
      };

      document.addEventListener('resize', () => truncateDescription(truncateDescriptionArgument));

      truncateDescription(truncateDescriptionArgument);

      return () => document.removeEventListener('resize', () => truncateDescription(truncateDescriptionArgument));
    }
  }, [descriptionElRef, truncateDescription, layoutData]);

  return layoutData ? (
    <div id={SIMPLE_CTA_CONTAINER_BOX_ELEMENT} className='container simple-cta'>
      <div className='support-centre-box'>
        <div className='support-centre-box__wrapper simple-cta__container'>
          <div className='simple-cta__content'>
            <div className='simple-cta__content-icon'>
              <div className='simple-cta__content-icon__wrapper'>
                <Image field={layoutData?.Icon} />
              </div>
            </div>

            <div className='simple-cta__content-body'>
              <Text tag='h3' field={layoutData?.Title} className='support-centre__title' />
              <p ref={descriptionElRef} className='support-centre__description'>
                <Text field={layoutData?.Description} />
              </p>
            </div>

            <ProductManualDownloadFooter buttonText={layoutData?.['Button Text']} buttonLink={layoutData?.ProductManual} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

ProductManualDownload.propTypes = {
  fields: object
};

export default ProductManualDownload;
