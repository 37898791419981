import './TierBenefitAnchor.scss';

import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import TierBenefitAnchorModel from './model/TierBenefitAnchorModel';


const TierBenefitAnchor = ({fields})=> {
  const PADDING_TOP = 72;
  const dataCrm = useSelector((state) => state.loyaltyReducer?.getCrmCustomerReducer?.dataCrm?.Data?.crm_tier);
  const [dataSource, setDataSources] = useState(null);

  useEffect(()=> {
    setDataSources(new TierBenefitAnchorModel().getData(fields));
  }, []);

  const handleScrollTargert = (target) => {
    if (target) {
      let targetComponent = document.querySelector(target);
      let offsetTop = targetComponent?.offsetTop - PADDING_TOP;
  
      window.scroll({ top: offsetTop, left: 0, behavior: 'smooth' });
    }
  };

  return dataSource ? (
    <div className='container my-reward__box tierBenefit-anchor'>
      <Text field={{ value: dataSource?.Title?.value.replace('{Rank}', dataCrm) }} tag='h3' className='my-reward__box-title tierBenefit-anchor__title'/>
      <ul className='tierBenefit-anchor__list'>
        {
          dataSource?.Anchors.map((item, index)=> {
            return (
              <li key={index} className='tierBenefit-anchor__list__item' onClick={()=>handleScrollTargert(`#${item?.['Anchor ID'].value}`)}>
                <Text field={item?.Title}/>
              </li>
            );
          })
        }
      </ul>
    </div>
  ) : (<></>);
};

TierBenefitAnchor.propTypes = {
  fields: PropTypes.object
};

export default TierBenefitAnchor;