import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import { getAsObservable } from '@services/genericService';

import { getProductForRegisterWarrantyDataFailed, getProductForRegisterWarrantyDataSuccess } from '../../actions/productForRegisterWarranty';
import { GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA } from '../../actions/productForRegisterWarranty/categoryForRegisterWarrantyTypes';
import { GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_ENDPOINT } from './config';

export const getProductForRegisterWarrantyDataEpic = (action$) =>
  action$.pipe(
    ofType(GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA),
    switchMap((action) => {
      const categoryId = action.payload;

      return getAsObservable(`${GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_ENDPOINT}?categoryId=${categoryId}`).pipe(
        map((res) => {
          if (res.status === 200) {
            return getProductForRegisterWarrantyDataSuccess(res.data.Products);
          } else {
            return getProductForRegisterWarrantyDataFailed('Failed');
          }
        })
      );
    })
  );
