import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { CART_PAGE_NAME } from '@components/FreeGiftPopup/FreeGiftItem';
import { addFreeGiftToCartFailure, addFreeGiftToCartSuccess } from '@redux/actions/addFreeGiftToCart';
import { ADD_FREE_GIFT_TO_CART_START } from '@redux/actions/addFreeGiftToCart/addFreeGiftToCartTypes';
import { getCurrentCart } from '@redux/actions/product';
import { postAsObservable } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import { ADD_FREE_GIFT_TO_CART_ENDPOINT } from './config';

const addFreeGiftToCartEpic = (action$) =>
  action$.pipe(
    ofType(ADD_FREE_GIFT_TO_CART_START),
    switchMap((action) => {
      const currentPageName = SitecoreContextFactoryService.getValueContextRouteItem('name');

      const params = {
        IsCartPage: currentPageName?.toLowerCase() === CART_PAGE_NAME,
        FreeGiftPopups: [...action.payload.FreeGiftPopups]
      };

      return from(
        postAsObservable(ADD_FREE_GIFT_TO_CART_ENDPOINT, params).pipe(
          switchMap((res) => {
            if (res.status === 200 || res.data.Success) {
              // return addFreeGiftToCartSuccess(res.data);
              return of(getCurrentCart(), addFreeGiftToCartSuccess());
            } else {
              return of(addFreeGiftToCartFailure(res.data?.Errors?.[0] || 'Failed'));
            }
          }),
          catchError((error) => of(addFreeGiftToCartFailure(error || 'Failed')))
        )
      );
    })
  );

export default addFreeGiftToCartEpic;
