import * as cpUpdateAppointmentTypes from './cpUpdateAppointmentTypes';

export const cpUpdateAppointment = (payload) => ({
  type: cpUpdateAppointmentTypes.CP_UPDATE_APPOINTMENT,
  payload
});

export const cpUpdateAppointmentSuccess = (payload) => ({
  type: cpUpdateAppointmentTypes.CP_UPDATE_APPOINTMENT_SUCCESS,
  payload
});

export const cpUpdateAppointmentFailed = (payload) => ({
  type: cpUpdateAppointmentTypes.CP_UPDATE_APPOINTMENT_FAILED,
  payload
});