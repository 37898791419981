import { combineReducers } from 'redux';

import BOAdditionalOptionsReducer from './BOAdditionalOptions';
import BOFreeGiftsReducer from './BOFreeGifts';
import BOGetDataStatusReducer from './BOGetDataStatus';
import BORentalsReducer from './BORental';
import BOTradeInReducer from './BOTradeIn';
import BOVariantsReducer from './BOVariants';
import BOWarrantyReducer from './BOWarranty';

export const buyingOptionReducer = combineReducers({
  BOGetDataStatusReducer,
  BOVariantsReducer,
  BOWarrantyReducer,
  BOFreeGiftsReducer,
  BOTradeInReducer,
  BORentalsReducer,
  BOAdditionalOptionsReducer
});
