export default class FormDataModel {
  constructor() {
    this.antiForgeryToken = '';

    this.fields = [];

    this.formItemId = '';

    this.formSessionId = '';

    this.htmlPrefix = '';

    this.metadata = '';

    this.pageItemId = '';

    this.contextItemId = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'antiForgeryToken')) {
      this.antiForgeryToken = dataSource.antiForgeryToken;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'formItemId')) {
      this.formItemId = dataSource.formItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'formSessionId')) {
      this.formSessionId = dataSource.formSessionId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'htmlPrefix')) {
      this.htmlPrefix = dataSource.htmlPrefix;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'metadata')) {
      this.metadata = dataSource.metadata;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'pageItemId')) {
      this.pageItemId = dataSource.pageItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      dataSource.fields.map(modelItem => {
        this.fields.push({...modelItem, formItemId: dataSource.formItemId.id});
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'contextItemId')) {
      this.contextItemId = dataSource.contextItemId;
    }

    return this;
  }
}
