import './quick-navigation.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { behaviorQuickNavigation$ } from './QuickNavigationBehaviror';
import QuickNavigationItem from './QuickNavigationItem';

const QuickNavigation = (props) => {
  const [data] = useState(props.fields?.items || null);

  useEffect(() => {
    if (data?.length ) {
      behaviorQuickNavigation$.next(true);
    } else {
      behaviorQuickNavigation$.next(false);
    }
  }, []);

  return data ? (
    <ul className='second-nav-list'>
      {data.map((item, index) => {
        return <QuickNavigationItem contentData={item.fields} key={index}></QuickNavigationItem>;
      })}
    </ul>
  ) : (
    ''
  );
};

QuickNavigation.propTypes = {
  fields: PropTypes.any
};

export default QuickNavigation;
