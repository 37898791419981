import {
  CLEAR_VALIDATE_SCHEDULE_OTP_CODE_ERROR_MESSAGE,
  VALIDATE_SCHEDULE_OTP_CODE_FAILURE,
  VALIDATE_SCHEDULE_OTP_CODE_START,
  VALIDATE_SCHEDULE_OTP_CODE_SUCCESS
} from './types';

export const validateScheduleOtpStart = (payload) => ({
  type: VALIDATE_SCHEDULE_OTP_CODE_START,
  payload
});

export const validateScheduleOtpSuccess = (payload) => ({
  type: VALIDATE_SCHEDULE_OTP_CODE_SUCCESS,
  payload
});

export const validateScheduleOtpFailure = (payload) => ({
  type: VALIDATE_SCHEDULE_OTP_CODE_FAILURE,
  payload
});

export const clearValidateScheduleOtpCodeErrorMessage = () => ({
  type: CLEAR_VALIDATE_SCHEDULE_OTP_CODE_ERROR_MESSAGE
});
