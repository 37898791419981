import { combineReducers } from 'redux';

import bookAppointmentReducer from './bookAppointment';
import createContactReducer from './createContact';
import getContactReducer from './getContact';
import getOtpCodeReducer from './getOtpCode';
import getTotalBookedSlotsReducer from './getTotalBookedSlots';
import updateContactReducer from './updateContact';
import validateOtpReducer from './validateOtp';

export const campaignRegisterReducer = combineReducers({
  getOtpCodeReducer,
  validateOtpReducer,
  createContactReducer,
  bookAppointmentReducer,
  getTotalBookedSlotsReducer,
  getContactReducer,
  updateContactReducer
});
