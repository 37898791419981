import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT,
  LIST_CLASS_NAME__DESKTOP,
  LIST_CLASS_NAME__MOBILE } from '@utils/constant';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CountDownBannerModel from '../Model/CountDownBannerModel';

const CountDownBanner = ({fields}) => {
  const COLOR_DEFAULT = '#fff';
  const FONT_SIZE_DEFAULT = '16px';
  const TIME_END = '00';

  const [isShowCountdown, setIsShowCountdown] = useState(false);
  const [timeCountdown, setTimeCountdown] = useState(null);
  const [countDownBannerFields, setCountDownBannerFields] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isDesktop, setIsDesktop] = useState(windowWidth >= IPAD_BREAKPOINT);

  useEffect(() => {
    const countDownBannerModel = new CountDownBannerModel();

    setCountDownBannerFields(countDownBannerModel.getData(deepCopy(fields)));
  }, [fields]);

  useEffect(() => {
    const start = Date.parse(new Date(countDownBannerFields?.['Start Time']?.value));
    const remain = Date.parse(new Date(countDownBannerFields?.['Time Remaining']?.value));
    const end = Date.parse(new Date(countDownBannerFields?.['End Time']?.value));
  
    const onSetLabel = setInterval(() => {
      let now = Date.parse(new Date(Date.now()));
      let distance;
      if (now >= end) {
        setIsShowCountdown(false);

        clearInterval(onSetLabel);

        return setTimeCountdown(null);
      }

      if (start < now) {
        if (now < remain) {
          setIsShowCountdown(false);

          clearInterval(onSetLabel);

          return setTimeCountdown('');
        }
        if (remain <=  now) {
          setIsShowCountdown(true);

          distance = end - now;
  
          let days = Global.addZero(Math.floor(distance / 86400000));
          let hours = Global.addZero(Math.floor((distance - days * 86400000) / 3600000));
          let minutes = Global.addZero(Math.floor((distance - days * 86400000 - hours * 3600000) / 60000));
          let seconds = Global.addZero(Math.floor(distance - days * 86400000 - hours * 3600000 - minutes * 60000) / 1000);
  
          return setTimeCountdown({days, hours, minutes, seconds});
        }
      }
  
      return setTimeCountdown(null);
    }, 1000);
  
    return () => {
      clearInterval(onSetLabel);
    };
  }, [countDownBannerFields]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    setIsDesktop(windowWidth >= IPAD_BREAKPOINT);
  }, [windowWidth]);

  const classRenderMobile = (data) => {
    return LIST_CLASS_NAME__MOBILE[data] || '';
  };

  const classRenderDesktop = (data) => {
    return LIST_CLASS_NAME__DESKTOP[data] || '';
  };

  return countDownBannerFields && countDownBannerFields['Enable Countdown Timer'].value ? (
    isShowCountdown && (
      <div className='countdown-banner main-banner__content'>
        <div className={
          'container main-banner__content__container content-position ' +
          classRenderMobile(countDownBannerFields['Countdown Mobile Position'].value) +
          ' ' +
          classRenderDesktop(countDownBannerFields['Countdown Desktop Position'].value)
        }>
          <div className='main-banner__content__wrap content-wrapper'>
            <Text 
              tag='h3' 
              field={countDownBannerFields['Countdown Title Text']} className='countdown-banner__title' 
              style={{
                color: countDownBannerFields['Countdown Title Text Color Code'].value || COLOR_DEFAULT, 
                fontSize: isDesktop 
                  ? countDownBannerFields['Countdown Title Text Font Size'].value + 'px' || FONT_SIZE_DEFAULT
                  : countDownBannerFields['Countdown Title Mobile Text Font Size'].value + 'px' || FONT_SIZE_DEFAULT}}
            />
            <div className='countdown-banner__time' style={{
              color: countDownBannerFields['Countdown Timer Text Color Code'].value || COLOR_DEFAULT, 
              fontSize: isDesktop 
                ? countDownBannerFields['Countdown Timer Text Font Size'].value + 'px' || FONT_SIZE_DEFAULT 
                : countDownBannerFields['Countdown Timer Mobile Text Font Size'].value + 'px' || FONT_SIZE_DEFAULT}}
            >
              <p className='time-number'>
                <span>{timeCountdown?.days || TIME_END}</span>
                <Text 
                  tag='span' 
                  field={countDownBannerFields['Countdown Days Text']} 
                  className='time-unit'
                  style={{
                    color: countDownBannerFields['Countdown Date Unit Text Color Code'].value || COLOR_DEFAULT, 
                    fontSize: isDesktop 
                      ? countDownBannerFields['Countdown Date Unit Font Size'].value + 'px' || FONT_SIZE_DEFAULT 
                      : countDownBannerFields['Countdown Date Mobile Unit Font Size'].value + 'px' || FONT_SIZE_DEFAULT}}
                />
              </p>
              <p>:</p>
              <p className='time-number'>
                <span>{timeCountdown?.hours || TIME_END}</span>
                <Text 
                  tag='span' 
                  field={countDownBannerFields['Countdown Hours Text']} 
                  className='time-unit'
                  style={{
                    color: countDownBannerFields['Countdown Date Unit Text Color Code'].value || COLOR_DEFAULT, 
                    fontSize: isDesktop 
                      ? countDownBannerFields['Countdown Date Unit Font Size'].value + 'px' || FONT_SIZE_DEFAULT 
                      : countDownBannerFields['Countdown Date Mobile Unit Font Size'].value + 'px' || FONT_SIZE_DEFAULT}}
                />

              </p>
              <p>:</p>
              <p className='time-number'>
                <span>{timeCountdown?.minutes || TIME_END}</span>
                <Text 
                  tag='span' 
                  field={countDownBannerFields['Countdown Minutes Text']} 
                  className='time-unit'
                  style={{
                    color: countDownBannerFields['Countdown Date Unit Text Color Code'].value || COLOR_DEFAULT, 
                    fontSize: isDesktop 
                      ? countDownBannerFields['Countdown Date Unit Font Size'].value + 'px' || FONT_SIZE_DEFAULT 
                      : countDownBannerFields['Countdown Date Mobile Unit Font Size'].value + 'px' || FONT_SIZE_DEFAULT}}
                />

              </p>
              <p>:</p>
              <p className='time-number'>
                <span>{timeCountdown?.seconds || TIME_END}</span>
                <Text 
                  tag='span' 
                  field={countDownBannerFields['Countdown Seconds Text']} 
                  className='time-unit'
                  style={{
                    color: countDownBannerFields['Countdown Date Unit Text Color Code'].value || COLOR_DEFAULT, 
                    fontSize: isDesktop 
                      ? countDownBannerFields['Countdown Date Unit Font Size'].value + 'px' || FONT_SIZE_DEFAULT 
                      : countDownBannerFields['Countdown Date Mobile Unit Font Size'].value + 'px' || FONT_SIZE_DEFAULT}}
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    )
  ) : '';
};

CountDownBanner.propTypes = {
  fields: PropTypes.any
};

export default CountDownBanner;