import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import AppListModel from '../../Models/AppListModel';

const ListApp = ({productApp}) => {
  const [listAppFields, setListAppFields] = useState(null);

  useEffect(() => {
    const appListModel = new AppListModel();

    setListAppFields(appListModel.getData(deepCopy(productApp.fields)));
  }, []);

  return listAppFields && (
    <a href={listAppFields['App Url'].value}>
      <Image media={listAppFields['CTA Image']}/>
    </a>
  );
};

ListApp.propTypes = {
  productApp: PropTypes.object
};

export default ListApp;
