import { array, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

const LoyaltyTableContent = ({ fieldArr, tierData, btnViewMoreText }) => {
  const [showMore, setShowMore] = useState(null);

  const [isShowMore, setIsShowMore] = useState(false);

  const handleShowMoreBenefit = (id) => {
    setShowMore(id);
  };

  useEffect(() => {
    if (showMore === tierData?.tier?.value) {
      setIsShowMore(true);
    } else {
      setIsShowMore(false);
    }
  }, [showMore, tierData]);

  return (
    <div className='card-body'>
      {/* Title Spent Qualifier show in mobile */}
      <Text field={{ value: tierData?.['spent-min-value'] }} className='card-body__title' tag='p' />

      {/* Card body desktop */}
      <ul className='card-body__list'>
        {fieldArr?.map((rowData) => {
          const { key } = rowData;
          const value = tierData?.[key];

          if (typeof value === 'boolean' && value === true) {
            return (
              <li className='card-body__list__item' key={key}>
                <span className='icon-check'></span>
              </li>
            );
          }

          return (
            <li className='card-body__list__item' key={key}>
              <span>{value}</span>
            </li>
          );
        })}
      </ul>

      {/* Card body mobile */}
      <ul className='card-body__list'>
        {fieldArr?.map((rowData) => {
          const { key, label, icon } = rowData;

          if (tierData?.[key]) {
            return (
              <li
                className={`card-body__list__item card-body__list__item--mobile ${isShowMore ? 'active' : ''}`}
                key={key}
              >
                {icon && <Image field={icon} className='card-body__list__item__icon' />}
                <span>{label}</span>
              </li>
            );
          }
        })}

        {/* Button show more */}
        <div className={`card-body__list__button ${isShowMore ? 'hidden' : ''}`}>
          <button onClick={() => handleShowMoreBenefit(tierData?.tier?.value)} className='btn btn-link'>
            <Text field={{ value: btnViewMoreText }} />
            <span className='icon-arrow-down'></span>
          </button>
        </div>
      </ul>
    </div>
  );
};

LoyaltyTableContent.propTypes = {
  fieldArr: array,
  tierData: object,
  btnViewMoreText: string
};

export default LoyaltyTableContent;
