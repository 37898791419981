import PropTypes from 'prop-types';
import React from 'react';

import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const CarouselWidthIconBottom = ({ data }) => {
  return (
    <div className='swiper-slide carousel-width-icon__item'>
      <div className='carousel-width-icon__item__image' data-image={true}>
        <Image media={data.Image} />
      </div>
      <div className='carousel-width-icon__item__content text-center'>
        <Text className='carousel-width-icon__item__title experience-title' tag='h2' field={data['Item Title']} />
        <RichText className='carousel-width-icon__item__text' field={data.Description} />
      </div>
    </div>
  );
};

CarouselWidthIconBottom.propTypes = {
  data: PropTypes.any
};

export default CarouselWidthIconBottom;
