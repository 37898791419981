import { any, object } from 'prop-types';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import * as config from '@components/ClientPortal/GenericComponents/CPHeader/DemoSession/config';
import Loading from '@components/Share/Loading';
import { getAppointmentList } from '@redux/actions/cpAppointmentListing';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import DataTable from '../DataTable';
import useAppointmentListingContext from '../useAppointmentListingContext';

const AppointmentsListingTable = ({ layoutData, setStatusTab, resultAppoint, dateFilter }) => {
  const isLoading = useSelector((state) => state.cpAppointmentListingReducer.isLoading);
  const contextAppointment = useAppointmentListingContext(layoutData);
  const [currentTab, setCurrentTab] = useState(contextAppointment?.listTabs[contextAppointment?.listTabs?.length - 1]?.slug());
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore') || null;
  const dispatch = useDispatch();

  const handleChangeTab = (currentTab) => {
    setCurrentTab(currentTab);

    const status = contextAppointment?.listTabs?.filter(tab => tab.slug() === currentTab)[0].status;

    setStatusTab(status);

    const url = new URL(window.location.href);
    const urlSearchParams = url.searchParams;
    const dateFilterParams = urlSearchParams.get('date-filter');

    dispatch(getAppointmentList({
      'Date': dateFilterParams || dateFilter,
      'Status': status,
      'WithCurrentStore': currentStore?.storeId === config.ALL_STORES_ID ? false : true
    }));
  };


  return (
    <div className='appointments-listing'>
      <div className='container tablet-container'>
        <div className='appointments-listing__wrap'>
          <Tabs activeKey={currentTab} onSelect={(currentTab) => handleChangeTab(currentTab)} className='appointments-listing__filter-list'>
            {contextAppointment?.listTabs.map((tab) => (
              <Tab key={tab.slug()} eventKey={tab.slug()} title={tab.label} tabClassName='appointments-listing__filter-item'>
                <div className='appointments-listing__content'>
                  {!isLoading ? (
                    <DataTable layoutData={layoutData} columns={contextAppointment?.dataTableColumns} data={resultAppoint?.Appointments?.Items} />
                  ) : (
                    <Loading />
                  )}
                </div>
              </Tab>
            ))}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

AppointmentsListingTable.propTypes = {
  layoutData: object,
  dateFilter: any,
  setStatusTab: any,
  resultAppoint: object,
};

export default AppointmentsListingTable;
