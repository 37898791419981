import produce from 'immer';

import { GET_MY_REVIEWS_FAILED, GET_MY_REVIEWS_START, GET_MY_REVIEWS_SUCCESS } from '@redux/actions/myReviews/myReviewsTypes';

const initState = {
  isLoading: false,
  myReviewsData: null,
  totalRecord: null,
  error: null
};

const getMyReviewsReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_MY_REVIEWS_START:
      draft.isLoading = true;
      break;

    case GET_MY_REVIEWS_SUCCESS:
      draft.isLoading = false;

      draft.myReviewsData = action.payload?.Data;

      draft.totalRecord = action.payload?.Total;
      break;

    case GET_MY_REVIEWS_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default getMyReviewsReducer;
