import '../CustomerProductList/CustomerProductList.scss';

import React from 'react';

import CPProductListing from '../CustomerProductList/CPProductListing';
import InfoHeader from '../CustomerProductList/InfoHeader';

const CustomeWishlist = () => {
  return <div className='Custome-wishlist customer-product-list'>
    <div className='container tablet-container'>
      <InfoHeader />
      <CPProductListing />
    </div>
  </div>;
};

export default CustomeWishlist;
