import React, { useMemo } from 'react';

import { Image } from '@sitecore-jss/sitecore-jss-react';
import { useQuery } from '@utils/customsHook/useQuery';

import {
  SCHEDULE_APPOINTMENT_PARAM_NAME,
  SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM,
  SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE,
  SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE,
  SCHEDULE_APPOINTMENT_STEP_SELECT_PRODUCT
} from '../FormikStep/configs';
import { useScheduleAppointmentContext } from '../hooks/useScheduleAppointmentContext';

const ProgressBar = () => {
  const { layoutData } = useScheduleAppointmentContext();
  const query = useQuery();

  const stepList = useMemo(() => {
    const teasData = layoutData?.Teas || [];
    const scentsData = layoutData?.Scents || [];
    const questionsData = layoutData?.questions || [];

    let defaultStepList = {
      [SCHEDULE_APPOINTMENT_STEP_SELECT_PRODUCT]: {
        stepNumber: 1,
        icon: layoutData['Product Icon']
      },
      [SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE]: {
        stepNumber: 2,
        icon: layoutData['Schedule Appointment Icon']
      },
      [SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM]: {
        stepNumber: 4,
        icon: layoutData['Contact Details Icon']
      }
    };

    if (teasData.length || scentsData.length || questionsData.length) {
      defaultStepList = {
        ...defaultStepList,
        [SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE]: {
          stepNumber: 3,
          icon: layoutData['Personalize Experience Icon']
        }
      };
    }

    return Object.fromEntries(Object.entries(defaultStepList).sort(([, a], [, b]) => a.stepNumber - b.stepNumber));
  }, [layoutData]);

  const activeStepIndex = useMemo(() => {
    const stepName = query.get(SCHEDULE_APPOINTMENT_PARAM_NAME);
    const stepListEntries = Object.entries(stepList);
    const currentStepData = stepListEntries.find(([key]) => key === stepName);

    return stepListEntries.indexOf(currentStepData);
  }, [query, stepList]);

  return (
    <div className='be-schedule-appointment__progress-bar'>
      {Object.entries(stepList).map(([key, value], index) => (
        <div
          key={key}
          className={`be-schedule-appointment__progress-bar__item ${activeStepIndex > index ? 'active' : activeStepIndex === index ? 'current-step' : ''}`}
        >
          <div className='be-schedule-appointment__progress-bar__item-wrapper'>
            <Image field={value.icon} className='image-icon' />
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProgressBar;
