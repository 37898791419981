import './ModalNeedAddProduct.scss';

import { bool, func, object } from 'prop-types';
import React from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const ModalNeedAddProduct = (props) => {
  const { isShowPopUp, layoutData, handleCloseModal } = props;

  return (
    <>
      <ModalComponent
        isShow={isShowPopUp}
        onCloseHandler={handleCloseModal}
        titlePopup={layoutData?.['Notification Label']?.value}
      >
        <div className='modal-add-product container'>
          <Text field={layoutData?.['Description']} tag='h5' className='modal-add-product__sub-title' />
          <Link field={layoutData?.['Button Shop Now Link']} className='modal-add-product__btn btn btn-primary'>
            <Text field={layoutData?.['Button Shop Now Text']} />
          </Link>
        </div>
      </ModalComponent>
    </>
  );
};

ModalNeedAddProduct.propTypes = {
  isShowPopUp: bool,
  layoutData: object,
  handleCloseModal: func
};

export default ModalNeedAddProduct;
