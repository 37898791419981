import SitecoreTextModel from '@models/SitecoreTextModel';

const STR_ARR = [
  'Logo Section Title',
  'Name Section Title',
];

export default class PartnerSpecialsLandingModel {
  constructor() {
    STR_ARR.forEach((str) => {
      this[str] = new SitecoreTextModel();
    });

    this['ListPartnerSpecials'] = [];
  }

  getData(dataSource) {
    STR_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPartnerSpecials')) {
      this['ListPartnerSpecials'] = dataSource['ListPartnerSpecials'];
    }
  
    return this;
  }
}