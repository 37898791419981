import './VideoPlayer.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import screenfull from 'screenfull';

import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import PlayerControls from './PlayerControl';

const VideoPlayer = (props) => {

  const { dataFields } = props;

  const playerRef = useRef(null);

  const controlsRef = useRef(null);

  const playerContainerRef = useRef(null);

  const [state, setState] = useState({
    playing: true,
    played: 0,
    seeking: false,
    isFullScreen: false,
  });

  const [count, setCount] = useState(0);

  const { playing, played, seeking, isFullScreen } = state;

  useDidUpdateEffect(() => {
    if (played === 1) {
      setState({...state, playing: false});
    }
  }, [played]);

  const onPlayPauseHandler = () => {
    setState({
      ...state,
      playing: !state.playing
    });
  };

  const onProgressHandler = (changeState) => {
    if (controlsRef?.current) {
      if (count > 3) {
        controlsRef.current.style.visibility = 'hidden';
  
        setCount(0);
      }
      
      if (controlsRef.current.style.visibility == 'visible') {
        
        setCount(count + 1);
  
      }
  
      if (!seeking) {
        setState({...state, played: changeState.played});
      }
    }
  };

  const onSeekChangeHandler = (newValue) => {
    setState({ ...state, played: parseFloat(newValue / 100) });
  };

  const onSeekMouseDownHandler = () => {

    setState({ ...state, seeking: true, playing: false });
  };

  const onSeekMouseUpDragHandler = (newValue) => {
    setState({ ...state, seeking: false, playing: true });

    playerRef.current.seekTo(newValue / 100, 'fraction');
  };

  const onToggleFullScreenHandler = () => {
    screenfull.toggle(playerContainerRef.current);

    setState({ ...state, isFullScreen: !state.isFullScreen });
  };

  const onMouseMoveHandler = () => {

    if (controlsRef?.current) {
      controlsRef.current.style.visibility = 'visible';
  
      setCount(0);
    }
  
  };

  // const onMouseLeaveHandler = () => {
  //   controlsRef.current.style.visibility = 'hidden';

  //   setCount(0);
  // };

  return (
    <Fragment>
      <div className='video-player'>
        <div 
          ref={playerContainerRef} 
          className='video-player__container'
          onMouseMove={onMouseMoveHandler}
          // onMouseLeave={onMouseLeaveHandler}
        >
          <ReactPlayer
            ref={playerRef}
            className='video-player__player'
            controls={true}
            width={'100%'}
            height={'100%'}
            url={dataFields['Link Video'] ?? dataFields?.['Video CTA Button Link']?.value?.href}
            playing={playing}
            onProgress={onProgressHandler}
          />

          {/* iframe */}
          {/* <iframe src="https://player.vimeo.com/video/453888458?autoplay=1&loop=1&title=0&byline=0&portrait=0" width="1920" height="1080" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe> */}

          {dataFields['Is Local Video'] === true ? (
            <PlayerControls
              setRef={controlsRef}
              playing={playing}
              played={played}
              isFullScreen={isFullScreen}
              onPlayPause={onPlayPauseHandler}
              onSeek={onSeekChangeHandler}
              onSeekMouseDown={onSeekMouseDownHandler}
              onSeekMouseUpDrag={onSeekMouseUpDragHandler}
              onToggleFullScreen={onToggleFullScreenHandler}
            />
          ) : null}
          
        </div>
      </div>
    </Fragment>
  );
};

VideoPlayer.propTypes = {
  dataFields: PropTypes.any,
};

export default VideoPlayer;