import './NotifyMePopup.scss';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { Fragment,useState } from 'react';
import { useSelector } from 'react-redux';
import * as Yup from 'yup';

import Loading from '@components/Share/Loading';
import Message from '@components/Share/Message';
// import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const NotifyMePopup = (props) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const { inputData } = props;
  const [hideInfo, setHideInfo] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(<Message messageCode='Msg_001.3' />)
        .required(<Message messageCode='Msg_001.3' />)
    }),
    onSubmit: (values) => {
      setHideInfo(true);

      props.notifyMeEvt({ email: values.email, productId: inputData.productId });
    },
    validateOnMount: true
  });

  return (
    <Fragment>
      <div className='notify-me-pop-up'>
        {
          !hideInfo ? (
            <p className='notify-me-pop-up__sub-title text-center'><Message messageCode='PopUp-Description' /></p>
          ) : ''
        }
        <form onSubmit={formik.handleSubmit} className='notify-me__form' id='notify-me__form__form'>
          {
            !hideInfo ? (
              <div className='form-group notify-me__form-input'>
                <input
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  type='text'
                  id='email'
                  className='form-control form-control-lg'
                  name='email'
                  placeholder={messageObj['LBL-NotifyMeEmail']}
                />
                <label htmlFor='email' className='form-group__label'><Message messageCode='LBL-NotifyMeEmail'/></label>
                {formik.errors.email && formik.touched.email && <span className='error-validate'>{formik.errors.email}</span>}
              </div>
            ) : ''
          }
          {
            hideInfo && !props.children ? <Loading></Loading> : props.children
          }
          {
            !hideInfo ? (
              <div className='notify-me__form__btn text-center'>
                <button className='notify-me__form__btn__item btn btn-primary'>
                  <span><Message messageCode='LBL-NotifyMeSubmit'/></span>
                </button>
              </div>
            ) : ''
          }
        </form>
      </div>
    </Fragment>
  );
};

NotifyMePopup.propTypes = {
  inputData: PropTypes.object,
  notifyMeEvt: PropTypes.func,
  isError: PropTypes.any,
  isNotifySuccess: PropTypes.any,
  children: PropTypes.any
};

export default NotifyMePopup;
