import PropTypes from 'prop-types';
import React from 'react';

import * as config from './config';

const InstagramAuthorization = (props) => {
  const { className, buttonText, children, tag, type } = props;

  const onAuthorizationHandler = () => {
    const { appID, scope, redirectUri, state } = props;
    
    window.location.href = `${config.API_AUTHENTICATION_INSTAGRAM}?app_id=${appID}&redirect_uri=${redirectUri}&scope=${scope}&response_type=${config.RESPONSE_TYPE}&state=${state}`;
  };
  
  return React.createElement(
    tag,
    {
      className: className,
      onClick: onAuthorizationHandler,
      style: className ? {} : config.STYLE_DEFAULT,
      type
    },
    children || buttonText
  );
};

InstagramAuthorization.defaultProps = {
  buttonText: config.BUTTON_TEXT,
  scope: config.SCOPE_DEFAULT,
  tag: config.NAME_TAG,
  type: config.TYPE,
  state: config.REDIRECT_DEFAULT,
};

InstagramAuthorization.propTypes = {
  appID: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  scope: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
};

export default InstagramAuthorization;
