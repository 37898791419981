import './StoreLocatorContent.scss';

import produce from 'immer';
import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { setActiveTab, setHoveredStoreItem, setSelectedStoreItem } from '@redux/actions/storeLocator';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import BaiDuMapContainer from './BaiduMap/BaiduMap';
import GoogleMapContainer from './GoogleMap';
import * as config from './GoogleMap/config';
import StoreLocatorBar from './StoreLocatorBar';
import StoreLocatorList from './StoreLocatorList';

const TEXT_URL_REPLACE = 'store-locator';

export const StoreLocatorDesktop = (props) => {
  const { dataFromLayout } = props;

  return (
    <div className='store-locator__content--desktop'>
      <div className='row'>
        <div className='col-lg-4 store-locator__list'>
          <div className='store-locator__content__list'>
            <StoreLocatorBar dataFromLayout={dataFromLayout} onUseMyLocationHandler={props.onUseMyLocationHandler} onResetMap={props.onResetMap} />
            <StoreLocatorList dataFromLayout={dataFromLayout} onClickStoreHandler={props.onClickStoreHandler} />
          </div>
        </div>
        <div className='col-lg-8'>
          {!SitecoreContextFactoryService.getValueContextRouteItem('fields').IsChina?.value ? (
            <div className='map-wraper'>
              <GoogleMapContainer
                markerIcon={dataFromLayout['Icon Marker'].value}
                initConfig={props.initConfig}
                onIdle={props.onIdle}
                onClickStoreHandler={props.onClickStoreHandler}
                onHoverStoreHandler={props.onHoverStoreHandler}
              />
            </div>
          ) : (
            <BaiDuMapContainer
              markerIcon={dataFromLayout['Icon Marker'].value}
              initConfig={props.initConfig}
              onClickStoreHandler={props.onClickStoreHandler}
              onHoverStoreHandler={props.onHoverStoreHandler}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const StoreLocatorMobile = (props) => {
  const { dataFromLayout } = props;

  const dispatch = useDispatch();

  return (
    <div className='store-locator__content--mobile'>
      <Tabs onSelect={(e) => dispatch(setActiveTab(e))} activeKey={props.activeTab}>
        <Tab eventKey='store-locator-listing' title={<Text field={dataFromLayout['List Label For Mobile']} />}>
          <div className='store-locator__tab-content'>
            <StoreLocatorBar dataFromLayout={dataFromLayout} onUseMyLocationHandler={props.onUseMyLocationHandler} onResetMap={props.onResetMap} />
            <StoreLocatorList
              dataFromLayout={dataFromLayout}
              onClickStoreHandler={props.onClickStoreHandler}
              onUseMyLocationHandler={props.onUseMyLocationHandler}
            />
          </div>
        </Tab>
        <Tab eventKey='store-locator-map' title={<Text field={dataFromLayout['Map Label For Mobile']} />}>
          <div className='store-locator__tab-content'>
            {!SitecoreContextFactoryService.getValueContextRouteItem('fields').IsChina?.value ? (
              <div className='map-wraper'>
                <GoogleMapContainer
                  markerIcon={dataFromLayout['Icon Marker'].value}
                  initConfig={props.initConfig}
                  onClickStoreHandler={props.onClickStoreHandler}
                  onIdle={props.onIdle}
                />
              </div>
            ) : (
              <BaiDuMapContainer
                markerIcon={dataFromLayout['Icon Marker'].value}
                initConfig={props.initConfig}
                onClickStoreHandler={props.onClickStoreHandler}
              />
            )}
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

const StoreLocatorContent = (props) => {
  const { dataFromLayout, dataStore, activeTab } = props;
  const [windowWidth, setWindowWidth] = useState(1920);
  const dispatch = useDispatch();

  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [initConfig, setInitConfig] = useState({
    key: false,
    activeMarkerId: null,
    hoveredMarkderId: null,
    zoom: config.MARKER_ZOOM,
    center: {
      lat: parseFloat(SitecoreContextFactoryService.getValueContextRouteItem('fields').Latitude.value),
      lng: parseFloat(SitecoreContextFactoryService.getValueContextRouteItem('fields').Longitude.value)
    }
  });

  const onClickStoreHandler = (value, isList) => {
    dispatch(setActiveTab('store-locator-map'));

    dispatch(setSelectedStoreItem(value));

    dispatch(setHoveredStoreItem(value));

    if (value) {
      setInitConfig({
        ...initConfig,
        key: !initConfig.key,
        activeMarkerId: value.ItemId,
        zoom: config.MARKER_ZOOM,
        hoveredMarkderId: value.ItemId,
        center: {
          lat: value.Latitude,
          lng: value.Longitude
        }
      });
    }

    !isList && (document.getElementById(`${value.ItemId}`).parentNode.scrollTop = document.getElementById(`${value.ItemId}`).offsetTop);

    handleGA4RoadshowMap(value);
  };

  //New Logic ON/OFF GA, GA4
  const handleGA4RoadshowMap = (data) => {
    if (isGA4) {
      let params = {
        selected_id: data?.ItemId,
        selected_name: data?.ItemName,
        selected_category: window.location.pathname.replace(/[/]/g, '').replace(TEXT_URL_REPLACE, '') || 'all'
      };

      //GA4
      new GoogleAnalytic().gAnalytic4('event', 'roadshow_map', params);
    }
  };

  const onUseMyLocationHandler = (value) => {
    dispatch(setActiveTab('store-locator-map'));

    if (value && value.length > 0) {
      setInitConfig({
        ...initConfig,
        key: !initConfig.key,
        activeMarkerId: value.ItemId,
        hoveredMarkderId: value.ItemId,
        zoom: config.MARKER_ZOOM,
        center: {
          lat: value[0].Latitude,
          lng: value[0].Longitude
        }
      });
    } else {
      setInitConfig({
        ...initConfig,
        key: !initConfig.key,
        activeMarkerId: value.ItemId,
        hoveredMarkderId: value.ItemId,
        center: {
          lat: value.Latitude,
          lng: value.Longitude
        }
      });
    }
  };

  const onResetMap = () => {
    setInitConfig({
      ...initConfig,
      zoom: config.MARKER_ZOOM,
      center: {
        lat: parseFloat(SitecoreContextFactoryService.getValueContextRouteItem('fields').Latitude.value),
        lng: parseFloat(SitecoreContextFactoryService.getValueContextRouteItem('fields').Longitude.value)
      }
    });
  };

  const onHoverStoreHandler = (value) => {
    dispatch(setHoveredStoreItem(value));

    if (value !== null) {
      setInitConfig({
        ...initConfig,
        activeMarkerId: value.ItemId
      });
    } else {
      setInitConfig({
        ...initConfig,
        activeMarkerId: initConfig.hoveredMarkderId
      });
    }
  };

  const onIdle = (targetRef) => {
    const newCenter = targetRef.getCenter();
    const newCenterLat = newCenter.lat();
    const newCenterLng = newCenter.lng();

    if (targetRef) {
      if (initConfig.center.lat !== newCenterLat || initConfig.center.lng !== newCenterLng || initConfig.zoom !== targetRef.getZoom()) {
        let newDefaultConfig = produce(initConfig, (draf) => {
          draf.zoom = targetRef.getZoom();

          draf.center.lat = targetRef.getCenter().lat();

          draf.center.lng = targetRef.getCenter().lng();

          return draf;
        });

        setInitConfig(newDefaultConfig);
      }
    }
  };

  const listLayoutContent = {
    Desktop: StoreLocatorDesktop,
    Mobile: StoreLocatorMobile
  };

  const layoutContentRender = () => {
    let ComponentType = listLayoutContent[`${windowWidth > 768 ? 'Desktop' : 'Mobile'}`];

    return (
      <ComponentType
        dataFromLayout={dataFromLayout}
        dataStore={dataStore}
        initConfig={initConfig}
        onClickStoreHandler={onClickStoreHandler}
        onIdle={onIdle}
        activeTab={activeTab}
        onHoverStoreHandler={onHoverStoreHandler}
        onUseMyLocationHandler={onUseMyLocationHandler}
        onResetMap={onResetMap}
      />
    );
  };

  return <Fragment>{layoutContentRender()}</Fragment>;
};

StoreLocatorContent.propTypes = {
  dataFromLayout: PropTypes.any,
  dataStore: PropTypes.any,
  activeTab: PropTypes.string
};

StoreLocatorDesktop.propTypes = {
  dataFromLayout: PropTypes.any,
  initConfig: PropTypes.any,
  onClickStoreHandler: PropTypes.any,
  onIdle: PropTypes.any,
  onHoverStoreHandler: PropTypes.func,
  onUseMyLocationHandler: PropTypes.func,
  onResetMap: PropTypes.func
};

StoreLocatorMobile.propTypes = {
  initConfig: PropTypes.any,
  onClickStoreHandler: PropTypes.any,
  onIdle: PropTypes.any,
  activeTab: PropTypes.string,
  onUseMyLocationHandler: PropTypes.func,
  dataFromLayout: PropTypes.any,
  onResetMap: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    activeTab: state.storeLocatorReducer.allStoreReducer.activeTab || ''
  };
};

export default connect(mapStateToProps)(StoreLocatorContent);
