import produce from 'immer';

import * as getSOCarts from '@redux/actions/getSOCarts/getSOCartsTypes';

const initState = {
  isLoading: false,
  activeError : null,
  draftError: null,
  viewedError: null,
  completedError: null,
  expiredError: null,
  activeCount : [],
  draftCount: [],
  viewedCount: [],
  completedCount: [],
  expiredCount: [],
  dataApi: ''
};

const getSOCartsReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case getSOCarts.GET_SO_CARTS:
      draft.isLoading = true;

      break;

    case getSOCarts.GET_SO_CARTS_SUCCESS:
      draft.isLoading = false;

      draft.dataApi =  action.payload;

      break;
  
    case getSOCarts.GET_SO_CARTS_FAILED:
      draft.isLoading = false;
        
      draft.activeError = action.payload.Errors;

      break;

    case getSOCarts.GET_SO_CARTS_ACTIVE:
      draft.isLoading = true;

      break;

    case getSOCarts.GET_SO_CARTS_ACTIVE_SUCCESS:
      draft.isLoading = false;

      draft.activeCount =  action.payload.Count;

      break;
  
    case getSOCarts.GET_SO_CARTS_ACTIVE_FAILED:
      draft.isLoading = false;
        
      draft.activeError = action.payload.Errors;

      break;
    
    case getSOCarts.GET_SO_CARTS_DRAFT:
      draft.isLoading = true;

      break;

    case getSOCarts.GET_SO_CARTS_DRAFT_SUCCESS:
      draft.isLoading = false;

      draft.draftCount =  action.payload.Count;

      break;
  
    case getSOCarts.GET_SO_CARTS_DRAFT_FAILED:
      draft.isLoading = false;
        
      draft.draftError = action.payload.Errors;

      break;
    

    case getSOCarts.GET_SO_CARTS_VIEWED:
      draft.isLoading = true;

      break;

    case getSOCarts.GET_SO_CARTS_VIEWED_SUCCESS:
      draft.isLoading = false;

      draft.viewedCount =  action.payload.Count;

      break;
  
    case getSOCarts.GET_SO_CARTS_VIEWED_FAILED:
      draft.isLoading = false;
        
      draft.viewedError = action.payload.Errors;

      break;
    

    case getSOCarts.GET_SO_CARTS_COMPLETED:
      draft.isLoading = true;

      break;

    case getSOCarts.GET_SO_CARTS_COMPLETED_SUCCESS:
      draft.isLoading = false;

      draft.completedCount =  action.payload.Count;

      break;
  
    case getSOCarts.GET_SO_CARTS_COMPLETED_FAILED:
      draft.isLoading = false;
        
      draft.completedError = action.payload.Errors;

      break;
    

    case getSOCarts.GET_SO_CARTS_EXPIRED:
      draft.isLoading = true;

      break;

    case getSOCarts.GET_SO_CARTS_EXPIRED_SUCCESS:
      draft.isLoading = false;

      draft.expiredCount =  action.payload.Count;

      break;
  
    case getSOCarts.GET_SO_CARTS_EXPIRED_FAILED:
      draft.isLoading = false;
        
      draft.expiredError = action.payload.Errors;

      break;
    }

    return draft;
  });

export default getSOCartsReducer;
