import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/settingGlobalURL';
import * as settingGlobalURLTypes from '@redux/actions/settingGlobalURL/settingGlobalURLTypes';
import { get } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as configs from './config';
import settingGlobalURLModel from './settingGlobalURLModel';

const settingGlobalURLEpic = (action$) =>
  action$.pipe(
    ofType(settingGlobalURLTypes.GET_SETTING_GLOBAL_URL),
    switchMap(() => {
      const cacheKey = 'contentSettings_' + SitecoreContextFactoryService.getNameCurrentLanguage();
      const cachedEntry = window.localStorage.getItem(cacheKey);
      if (cachedEntry) {
        const cachedSetting = JSON.parse(cachedEntry);
        if (cachedSetting && cachedSetting['ExpireDate'] > new Date().getTime())
          return of(cachedSetting).pipe(
            map((res) => {
              return actions.getSettingGlobalURLSuccess(res);
            })
          );
      }

      return from(get(configs.ACTION_GET_SETTING)).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            const response = new settingGlobalURLModel().getData(res.data?.Data || {});

            window.localStorage.setItem(cacheKey, JSON.stringify(response));

            return actions.getSettingGlobalURLSuccess(response);
          } else {
            return actions.getSettingGlobalURLFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getSettingGlobalURLFailed(error.message || 'Failed')))
      );
    })
  );

export default settingGlobalURLEpic;
