import './ProductManual.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import { MEDIA_TRACKING_GOAL_ID, mediaStartTrackingGoal } from '@redux/actions/trackingGoal/mediaTracking/index';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import ProductManualModel from '../Models/ProductManualModel';

const ProductManual = ({fields}) => {
  const [productManualFields, setProductManualFields] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [isLinkMedia, setIsLinkMedia] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    const productManualModel = new ProductManualModel();

    setProductManualFields(productManualModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  useEffect(() => {
    Global.scrollWithHash(headerHeight);
  }, [productManualFields, headerHeight]);

  const handleTrackingGoal = () => {
    if (isLinkMedia) {
      dispatch(mediaStartTrackingGoal({
        'GoalId': MEDIA_TRACKING_GOAL_ID,
        'UseTrackerAPI': false
      }));
    } else return;
  };

  useEffect(() => {
    if (productManualFields?.File?.value?.src) {
      const strUrl = productManualFields.File?.value?.src;
      if (strUrl.endsWith('.pdf') || strUrl.endsWith('.mp4')) {
        setIsLinkMedia(true);
      }
    }

  }, [productManualFields?.File?.value?.src]);

  return productManualFields && (
    <div id='productmanual' className='product-manual'>
      <div className='container'>
        <Text tag='h2' className='text-center section-title' field={productManualFields['Title']} />
        <div className='product-manual__image text-center'>
          <Image media={productManualFields['Icon']}/>
        </div>
        <Link 
          field={{ href: productManualFields.File?.value?.src || '' }} 
          onClick={handleTrackingGoal} 
          className='product-manual__link link-text text-center'
        >
          <Text field={productManualFields['Button Text']} />
        </Link>
      </div>
    </div>
  );
};

ProductManual.propTypes = {
  fields: PropTypes.object
};

export default ProductManual;
