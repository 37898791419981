import './SpinWinHeading.scss';

import React from 'react';
import { useContext } from 'react';

import HelpIcon from '@components/SpinAndWin/HelpIcon';
import PrizesIcon from '@components/SpinAndWin/PrizesIcon';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { SpinWinBannerContext } from '../Spin&WinBanner';

const SpinWinHeading = () => {
  const { layoutData } = useContext(SpinWinBannerContext);

  return (
    <div className='spin-win-heading'>
      {/* <h2 className='spin-win-heading__title'>Free Prize Draw</h2> */}
      <Text tag='h2' className='spin-win-heading__title' field={layoutData['Spin Wheel Title']} />
      <div className='spin-win-heading__icon'>
        <HelpIcon />
        <PrizesIcon />
      </div>
    </div>
  );
};

export default SpinWinHeading;
