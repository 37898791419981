import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { registerEventBookingFailed, registerEventBookingSuccess } from '@redux/actions/eventBooking';
import { REGISTER_EVENT_BOOKING_START } from '@redux/actions/eventBooking/eventBookingRegister/eventBookingRegisterTypes';
import { postAsObservable } from '@services/genericService';

import { EVENT_BOOKING_REGISTER_ENDPOINT } from './config';

const eventBookingRegisterEpic = (action$) =>
  action$.pipe(
    ofType(REGISTER_EVENT_BOOKING_START),
    switchMap((action) => {
      return postAsObservable(EVENT_BOOKING_REGISTER_ENDPOINT, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            return registerEventBookingSuccess(res.data);
          } else {
            return registerEventBookingFailed(res.data?.Message?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(registerEventBookingFailed(error || 'Failed')))
      );
    })
  );

export default eventBookingRegisterEpic;
