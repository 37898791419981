import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Lost Status Label',
  'Never Pick Up Status Label',
  'New Status Label',
  'Reschedule Status Label',
  'Trial Complete Status Label',
  'Appointment Date Lable',
  'Mobile No Lable',
  'Note Lable',
  'Status Lable',
  'Submission Date Lable',
  'Button Edit',
  'Title',
  'Save Label',
  'Cancel Label',
  'Back Label',
  'Connect Task List Label',
  'booked Label',
  'changed Label',
  'canceled Label',
  'Activity Log Label',
  'AppointmentConfirmed Label',
  'Duplicate Appointment',
  'Invalid Date Selection',
  '14',
  'Max Slots Reached',
  'At Label',
  'Just Me Button Text',
  'Me Plus1 Placeholder Text',
  'No Of Guests Label',
  'Select Product Label',
  'Select Tea Label',
  'Select Scent Label',
  'Select Time Slot Label'
];

export default class AppointmentDetailModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });

    this['appointmentDetail'] = {};

    this['products'] = [];

    this['teas'] = [];

    this['scents'] = [];

    this['Connect Task List Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    TEXT_ARR.forEach((text) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text] = dataSource[text] ?? '';
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'appointmentDetail')) {
      this['appointmentDetail'] = dataSource['appointmentDetail'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'products')) {
      this['products'] = dataSource['products'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'teas')) {
      this['teas'] = dataSource['teas'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'scents')) {
      this['scents'] = dataSource['scents'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Connect Task List Link')) {
      this['Connect Task List Link'].getData(dataSource['Connect Task List Link']);
    }

    return this;
  }
}