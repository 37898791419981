import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'CTA Button Position',
  'Content Position',
  'CTA Button Text',
  'Description',
  'Background Color Code',
  'CTA Button Link',
  'Title',
  'Start Soon Message',
  'Start Time',
  'Time Remaining',
  'End Soon Message',
  'End Time',
  'Valid Message',
  'Was End Message',
  'Enable Countdown Timer',
  'Alternative Start Soon Message',
  'Countdown Days Text',
  'Countdown Title Text',
  'Countdown Hours Text',
  'Countdown Minutes Text',
  'Countdown Seconds Text',
  'Countdown Title Text Font Size',
  'Countdown Timer Text Font Size',
  'Countdown Date Unit Font Size',
  'Countdown Title Mobile Text Font Size',
  'Countdown Timer Mobile Text Font Size',
  'Countdown Date Mobile Unit Font Size',
  'Countdown Desktop Position',
  'Countdown Title Text Color Code',
  'Countdown Timer Text Color Code',
  'Countdown Date Unit Text Color Code',
  'Countdown Mobile Position',
  'Is Visible',
  'Title Color Code',
  'Slide Up End Time',
  'Slide Up Launch Time'
];

const IMG_ARR = ['Countdown Background Image', 'Background Image', 'Slide Up Desktop Image', 'Slide Up Mobile Image'];

export default class SliceUpBannerModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    IMG_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMG_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
