import TagsModel from '@components/Wellness/WellnessDetailHeader/Model/TagsModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import TabsModel from './TabsModel';

export default class UniversalSearchBoxModel {
  constructor() {
    this['PlaceHolder Text'] = new SitecoreTextModel();

    this['Filter By Label'] = new SitecoreTextModel();

    this['Search Result Tabs'] = [];

    this['Tags To Select'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PlaceHolder Text')) {
      this['PlaceHolder Text'].getData(dataSource['PlaceHolder Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter By Label')) {
      this['Filter By Label'].getData(dataSource['Filter By Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Search Result Tabs')) {
      dataSource['Search Result Tabs'].map((element) => {
        this['Search Result Tabs'].push(new TabsModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Tags To Select')) {
      dataSource['Tags To Select'].map((element) => {
        this['Tags To Select'].push(new TagsModel().getData(deepCopy(element) || {}));
      });
    }

    return this;
  }
}
