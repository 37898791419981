import produce from 'immer';

import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';

const initState = {
  isLoading: false,
  error: '',
  lastestOrder: null
};

const lastestReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myOrderTypes.GET_LASTEST_ORDERS:
      draft.isLoading = true;

      break;

    case myOrderTypes.GET_LASTEST_ORDERS_SUCCESS:
      draft.isLoading = false;

      draft.lastestOrder = action.payload;
      break;

    case myOrderTypes.GET_LASTEST_ORDERS_FAILED:
      draft.isLoading = false;      
      break;
    }

    return draft;
  });

export default lastestReducer;
