import PropTypes from 'prop-types';
import React from 'react';
import { useRef } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';

const NavigationBundleProduct = ({ arrayBundle, onChangeProductBundle }) => {
  const swipeNavRef = useRef(null);

  const swipeNavParams = {
    slidesPerView: 2,
    centeredSlides: true,
    spaceBetween: 0,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    on: {
      slideChangeTransitionStart: () => {
        const indexActive = swipeNavRef.current.swiper.realIndex;

        const elActiveOfArrayBundle = arrayBundle?.find((el, index) => {
          return index === indexActive;
        });

        onChangeProductBundle(
          elActiveOfArrayBundle?.Name ? elActiveOfArrayBundle?.Name : elActiveOfArrayBundle?.ProductName,
          elActiveOfArrayBundle?.ImageUrl ? elActiveOfArrayBundle?.ImageUrl : elActiveOfArrayBundle?.Image,
          elActiveOfArrayBundle?.ProductId,
          elActiveOfArrayBundle?.CommerceId
            ? elActiveOfArrayBundle?.CommerceId?.replace(/{|}/g, '')
            : elActiveOfArrayBundle?.CommerceProductId?.replace(/{|}/g, '')
        );
      }
    }
  };

  return arrayBundle?.length ? (
    <div className='productReviewForPDP__navigation'>
      <SwipeComponent param={swipeNavParams} setRef={swipeNavRef}>
        {arrayBundle.map((product, index) => {
          return (
            <div key={index}>
              <div className='productReviewForPDP__navigation__item'>
                <img src={product.ImageUrl ? product.ImageUrl : product.Image} alt='img' />
                <p>{product.Name ? product.Name : product.ProductName}</p>
              </div>
            </div>
          );
        })}
      </SwipeComponent>
    </div>
  ) : (
    ''
  );
};

NavigationBundleProduct.propTypes = {
  arrayBundle: PropTypes.array,
  onChangeProductBundle: PropTypes.func,
  onChangeCommerceId: PropTypes.func
};

export default NavigationBundleProduct;
