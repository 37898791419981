import Global from '@utils/global';

export default class WellnessArticleApiModel {
  constructor() {
    this.Author = '';

    this.Description = '';

    this.FeaturedImage = '';

    this.ItemId = '';

    this.ItemType = '';

    this.ItemUrl = '';

    this.PageUrl = '/';

    this.PublishedDate = '';

    this.Tags = [];

    this.TagNames = [];

    this.TimeToRead = '';

    this.Title = '';

    this.PublishedDateAlt = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Author')) {
      this.Author = dataSource.Author ? dataSource.Author : this.Author;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description ? dataSource.Description : this.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FeaturedImage')) {
      this.FeaturedImage = dataSource.FeaturedImage ? dataSource.FeaturedImage : this.FeaturedImage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId')) {
      this.ItemId = dataSource.ItemId ? dataSource.ItemId : this.ItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemType')) {
      this.ItemType = dataSource.ItemType ? dataSource.ItemType : this.ItemType;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemUrl')) {
      this.ItemUrl = dataSource.ItemUrl ? dataSource.ItemUrl : this.ItemUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PageUrl')) {
      this.PageUrl = dataSource.PageUrl ? dataSource.PageUrl : this.PageUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PublishedDate')) {
      this.PublishedDate = dataSource.PublishedDate ? Global.formatEpochDate(dataSource.PublishedDate, false) : this.PublishedDate;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Tags')) {
      this.Tags = dataSource.Tags ? dataSource.Tags : this['Tags'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TagNames')) {
      this.TagNames = dataSource.TagNames ? dataSource.TagNames : this['TagNames'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TimeToRead')) {
      this.TimeToRead = dataSource.TimeToRead ? dataSource.TimeToRead : this.TimeToRead;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title = dataSource.Title ? dataSource.Title : this.Title;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PublishedDateAlt')) {
      this.PublishedDateAlt = dataSource.PublishedDateAlt ? dataSource.PublishedDateAlt : this.PublishedDateAlt;
    }

    return this;
  }
}
