import { element, object } from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { getSOCartPagingSelector } from '@redux/reducers/personalizeOffer/getSOCartPagingReducer/getSOCartPagingSelector';

import { DEFAULT_FETCH_OFFER_LISTING_PAGE_SIZE, DEFAULT_OFFER_LISTING_PAGE } from '../configs';
import { useFetchOfferListing } from '../hooks';
import PersonalizedOffersWidgetModel from '../models/PersonalizedOffersWidgetModel';

const initialStates = {
  layoutData: null,
  offerListingData: [],
  currentPage: DEFAULT_OFFER_LISTING_PAGE,
  setCurrentPage: () => {},
  isLoading: false,
  totalPage: 0
};
const PersonalizedOffersWidgetContext = createContext(initialStates);

export const usePersonalizedOffersWidget = () => useContext(PersonalizedOffersWidgetContext);

const PersonalizedOffersWidgetProvider = ({ fields, children }) => {
  const { data, isLoading } = useSelector(getSOCartPagingSelector);
  const [layoutData, setLayoutData] = useState(null);
  const fetchDataService = useFetchOfferListing();
  const [currentPage, setCurrentPage] = useState(DEFAULT_OFFER_LISTING_PAGE);

  useEffect(() => {
    const model = new PersonalizedOffersWidgetModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    fetchDataService(currentPage);
  }, []);

  const cartData = useMemo(() => {
    return data.Carts;
  }, [data]);

  const totalItems = useMemo(() => {
    return data.Count;
  }, [data]);

  const totalPage = useMemo(() => {
    return Math.ceil(totalItems / DEFAULT_FETCH_OFFER_LISTING_PAGE_SIZE);
  }, [totalItems]);

  return layoutData ? (
    <PersonalizedOffersWidgetContext.Provider
      value={{
        layoutData: layoutData,
        offerListingData: cartData,
        currentPage: currentPage,
        setCurrentPage: setCurrentPage,
        isLoading: isLoading,
        totalPage: totalPage
      }}
    >
      {children}
    </PersonalizedOffersWidgetContext.Provider>
  ) : (
    <></>
  );
};

PersonalizedOffersWidgetProvider.propTypes = {
  fields: object,
  children: element
};

export default PersonalizedOffersWidgetProvider;
