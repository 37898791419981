import PickUpPointsModelItem from './PickUpPointsModelItem';

export default class PickUpPointsModel {
  constructor() {
    this.PickUpPoints = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PickUpPoints')) {
      if(dataSource.PickUpPoints?.length) {
        dataSource.PickUpPoints.map(pickupItem => {
          this.PickUpPoints.push(new PickUpPointsModelItem().getData(pickupItem || {}));
        });
      }
    }
    
    return this;
  }
}
