import './SpecialRequestComponent.scss';

import PropTypes from 'prop-types';
import React from 'react';

const SpecialRequestComponent = ({cartData, layoutData}) => {

  return (
    <div className='container'>
      <div className='orders-detail__summary__wrap'>
        <div className='row'>
          <div className='col-md-6 col-xl-5'></div>
          <div className='col-md-6 col-xl-7'>
            <dl className='special-request'>
              <dt className='special-request__title'>{layoutData['Special Request Label'].value}</dt>
              <dd className='special-request__description'>{cartData.SpecialRequest}</dd>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
};

SpecialRequestComponent.propTypes = {
  cartData: PropTypes.any,
  layoutData: PropTypes.any,
};

export default SpecialRequestComponent;
