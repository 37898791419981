import produce from 'immer';

import {
  GET_TOTAL_BOOKED_SLOTS_FAILURE,
  GET_TOTAL_BOOKED_SLOTS_START,
  GET_TOTAL_BOOKED_SLOTS_SUCCESS
} from '@redux/actions/campaignRegister/getTotalBookedSlots/types';

const initialStates = {
  isLoading: false,
  bookedSlots: [],
  error: null
};

const getTotalBookedSlotsReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_TOTAL_BOOKED_SLOTS_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case GET_TOTAL_BOOKED_SLOTS_SUCCESS:
      draft.isLoading = false;

      draft.bookedSlots = action.payload;
      break;

    case GET_TOTAL_BOOKED_SLOTS_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getTotalBookedSlotsReducer;
