import * as helpMeDecideTypes from './types';

export const getHelpMeDecide = (payload) => ({
    type: helpMeDecideTypes.GET_HELP_ME_DECIDE,
    payload
});

export const getHelpMeDecideSuccess = (payload) => ({
    type: helpMeDecideTypes.GET_HELP_ME_DECIDE_SUCCESS,
    payload
});

export const getHelpMeDecideFailed = (payload) => ({
    type: helpMeDecideTypes.GET_HELP_ME_DECIDE_FAILED,
    payload
});

export const setRangePricePosition = (payload) => ({
    type: helpMeDecideTypes.SET_RANGE_PRICE_POSITION,
    payload
});
