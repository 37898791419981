import './TermsConditionsModal.scss';

import { any, bool, func } from 'prop-types';
import React from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const TermsConditionsModal = (props) => {
  const { isShow, onCloseHandler, ondataSources } = props;

  return (
    <ModalComponent isShow={isShow} onCloseHandler={onCloseHandler} titlePopup={ondataSources?.['Term And Condition Label']?.value}>
      <div className='termsconditions-modal'>
        <RichText field={ondataSources?.['Term and Condition']} tag='p' className='termsconditions-modal__content'/>
      </div>
    </ModalComponent>
  );
};

TermsConditionsModal.propTypes = {
  isShow: bool,
  onCloseHandler: func,
  ondataSources: any
};

export default TermsConditionsModal;
