import Proptypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import Message from '@components/Share/Message';
import { addChosenAdditionalOptions } from '@redux/actions/buyingOptions';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const BOAdditionalOptionsList = ({ contentData, dataFields, activeItemsId, onToogleFnc, additionalOptionsData, additionalOptionsRequired }) => {
  const dispatch = useDispatch();
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  useEffect(() => {
    if (contentData.length === 1 && additionalOptionsRequired) {
      onClickBuyingItemHandler(contentData[0].ProductId, additionalOptionsData);
    }
  }, []);

  const onClickBuyingItemHandler = (itemID, relationshipId) => {
    dispatch(addChosenAdditionalOptions({ itemID, relationshipId, additionalOptionsRequired, toogleFnc: onToogleFnc }));
  };

  const isActiveItems = (listId, itemId) => {
    return listId.includes(itemId);
  };

  return (
    contentData.length && (
      <div className={`${isClientTelingUser ? 'warranty-buying-list-cp' : 'warranty-buying-list'}`}>
        <div className='row'>
          {contentData.map((item, itemIndex) => {
            return (
              <div className={`${isClientTelingUser ? 'col-6' : 'col-lg-6'} warranty-buying-list__item-wrap`} key={itemIndex}>
                <div
                  className={'warranty-buying-list__item' + `${isActiveItems(activeItemsId, item.ProductId) ? ' active' : ''}`}
                  onClick={() => onClickBuyingItemHandler(item.ProductId, additionalOptionsData)}
                  key={itemIndex}
                >
                  <span className='custom-radio-select warranty-buying-list__item__name'>{item.DisplayName}</span>
                  <span className='addition-price warranty-buying-list__item__price'>
                    {item.Price?.ListPrice === 0 ? <Message messageCode='LBL-WarrantyFee' /> : `${item.Price?.ListPriceWithCurrency}`}
                  </span>
                  {isActiveItems(activeItemsId, item.ProductId) ? <span className='check-mark'></span> : ''}
                </div>
              </div>
            );
          })}
        </div>
        {dataFields['Disposal Policy Page']?.value && dataFields['Disposal Policy Label']?.value && (
          <a href={dataFields['Disposal Policy Page']?.value} target='_blank' rel='noreferrer'>
            <Text tag='span' field={dataFields['Disposal Policy Label']} />
          </a>
        )}
      </div>
    )
  );
};

BOAdditionalOptionsList.propTypes = {
  contentData: Proptypes.any,
  dataFields: Proptypes.any,
  activeItemsId: Proptypes.any,
  onToogleFnc: Proptypes.any,
  additionalOptionsData: Proptypes.string,
  additionalOptionsRequired: Proptypes.bool
};

export default BOAdditionalOptionsList;
