import React, { useEffect, useState } from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CategoryBannerModel from './models/CategoryBannerModel';

const CategoryBanner = () => {
  const [dataSources, setDataSources] = useState(null);

  useEffect(() => {
    setDataSources(new CategoryBannerModel().getData(deepCopy(SitecoreContextFactoryService.getValueContextRouteItem('fields') || {})));
  }, []);

  return dataSources ? (
    <div
      className={`main-banner category-banner ${
        dataSources['Banner Text Color'].value === 'Black' ? '' : 'text-white'
      } animated-slow fadeIn delay-100`}
    >
      <div className='main-banner__background'>
        <ResponsiveImage 
          imageMobile={dataSources['Mobile Banner Image']}
          srcSet={[{ mw: 768, image: dataSources['Banner Image'] }]}
        />
      </div>
      <div className='main-banner__content'>
        <div className='container main-banner__content__container content-position content-middle content-center'>
          <div className='main-banner__content__wrap content-wrapper'>
            <h1 className='main-banner__content__title'>
              <Text field={dataSources['Title']} />
            </h1>
            <RichText className='main-banner__content__desc' field={dataSources['Description']} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='main-banner category-banner text-white skeleton__item'>
        <div className='main-banner__background main-banner__background--white'>
          <Loading></Loading>
        </div>
      </div>
    </LoadingShimmer>
  );
};

export default CategoryBanner;
