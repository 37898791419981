import CouponWalletItemModel from './CouponWalletItemModel';

export default class CouponWalletListModel {
  constructor() {
    this['Promotions'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Promotions')) {
      if (dataSource['Promotions']?.length) {
        dataSource['Promotions'].map((item) => {
          this['Promotions'].push(new CouponWalletItemModel().getData(item || {}));
        });
      }
    }

    return this;
  }
}
