import SitecoreTextModel from '@models/SitecoreTextModel';

export default class PaymentProcessingModel {
  constructor() {
    this['Message'] = new SitecoreTextModel();
   
    this['Title'] = new SitecoreTextModel();

  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Message')) {
      this['Message'].getData(dataSource['Message']);
    }

    return this;
  }
}
