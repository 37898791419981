import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import SimpleTilesItemFieldsModel from '../Model/SimpleTilesItemFieldsModel';

const SimpleTilesItem = (props) => {
  const { fields } = props;

  const [simpleTilesItemFields, setSimpleTilesItemFields] = useState(null);

  useEffect(() => {
    const simpleTilesItemFieldsModel = new SimpleTilesItemFieldsModel();

    setSimpleTilesItemFields(simpleTilesItemFieldsModel.getData(deepCopy(fields.fields || {})));
  }, []);

  return simpleTilesItemFields && (
    <div className='OSIM-benefit__item'>
      <div className='OSIM-benefit__item__icon'>
        <Image media={simpleTilesItemFields.Image} />
      </div>
      <Text field={simpleTilesItemFields.Title} tag='p' className='OSIM-benefit__item__title' />
      <RichText field={simpleTilesItemFields.Description} className='OSIM-benefit__item__description' />
      <div className='OSIM-benefit__item__button'>
        <Link field={simpleTilesItemFields['CTA Button Link']} className='btn-link'>
          <Text field={simpleTilesItemFields['CTA Button Text']} tag='span' />
        </Link>
      </div>
    </div>
  );
};

SimpleTilesItem.propTypes = {
  fields: PropTypes.object
};

export default SimpleTilesItem;
