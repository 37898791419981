import { object, string } from 'prop-types';
import React from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const ProductPreviewSection = ({ reviewIdWriteReviewSuccessLocal, viewProductDetail, fieldData }) => {
  return (
    <>
      <div className='product-thank-you-page__bottom__content'>
        {/* {stars > 4 ? (
          <div className='product-thank-you-page__bottom__content--center higher-four-star'>
            We at OSIM know you had many options to choose from, we thank you for choosing us.
          </div>
        ) : stars > 2 ? (
          <div className='product-thank-you-page__bottom__content--center'>
            Your review will help us improve our ability to provide you with the best shopping experience.
          </div>
        ) : (
          <div className='product-thank-you-page__bottom__content--center lower-two-star'>
            <Text tag='p' field={fieldData['Respond To Your Review Text']} />
          </div>
        )} */}

        {/* <div className='product-thank-you-page__bottom__content--center'>{thankYouMess}</div> */}
      </div>
      <div className='product-thank-you-page__bottom__link-view'>
        {reviewIdWriteReviewSuccessLocal ? (
          <Link
            field={{
              value: {
                href: viewProductDetail
              }
            }}
          >
            <Text tag='span' field={fieldData['See Review In Product Page Text']} />
          </Link>
        ) : (
          <Text tag='span' field={fieldData['See Review In Product Page Text']} />
        )}
      </div>
      <button className='product-thank-you-page__bottom__button btn btn-outline-CTA1'>
        <Link field={fieldData['See Lastest Offers Button Link']}>
          <Text field={fieldData['See Lastest Offers Button Text']} />
        </Link>
      </button>
    </>
  );
};

ProductPreviewSection.propTypes = {
  reviewIdWriteReviewSuccessLocal: string,
  viewProductDetail: string,
  fieldData: object
};

export default ProductPreviewSection;
