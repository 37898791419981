import { CREATE_CONTACT_FAILURE, CREATE_CONTACT_START, CREATE_CONTACT_SUCCESS } from './types';

export const createContactStart = (payload) => ({
  type: CREATE_CONTACT_START,
  payload
});

export const createContactSuccess = (payload) => ({
  type: CREATE_CONTACT_SUCCESS,
  payload
});

export const createContactFailure = (payload) => ({
  type: CREATE_CONTACT_FAILURE,
  payload
});
