import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { useGetScheduleOtpCode } from '../../../hooks/useGetScheduleOtpCode';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import LoginModal from '../LoginModal';

const EmailExistModal = ({ isCheckEmailExistBp, isLoginSuccess }) => {
  const { layoutData } = useScheduleAppointmentContext();
  const getOtpService = useGetScheduleOtpCode();
  const [isShow, setIsShow] = useState(false);
  const [isShowLoginModal, setIsShowLoginModal] = useState(false);

  useDidUpdateEffect(() => {
    if (isCheckEmailExistBp) setIsShow(true);
    else getOtpService();
  }, [isCheckEmailExistBp]);

  useDidUpdateEffect(() => {
    if (isLoginSuccess) setIsShow(false);
  }, [isLoginSuccess]);
  const handleCloseModal = () => setIsShow(false);
  const handleCloseLoginModal = () => setIsShowLoginModal(false);

  const handleContinueAsGuest = () => {
    getOtpService();

    handleCloseModal();
  };

  return (
    <>
      <ModalComponent
        isShow={isShow}
        onCloseHandler={handleCloseModal}
        titlePopup={<Text field={layoutData['Email Already Exists Label']} tag='span' />}
      >
        <div className='popup-email-already'>
          <p
            className='popup-email-already__description text-center'
            dangerouslySetInnerHTML={{ __html: layoutData?.['Email Already Exists Message']?.value }}
          />
          <div className='popup-email-already__action'>
            <Text
              onClick={() => setIsShowLoginModal(true)}
              className='popup-email-already__action__btn popup-email-already__action__btn--login btn btn-outline-CTA1'
              field={layoutData?.['Login Button Text']}
              tag='button'
            />
            <button className='btn btn-link popup-email-already__action__btn popup-email-already__action__btn--link' onClick={handleContinueAsGuest}>
              <Text field={layoutData?.['Continue Guest Checkout Button Text']} />
            </button>
          </div>
        </div>
      </ModalComponent>
      <LoginModal isOpenModal={isShowLoginModal} onClose={handleCloseLoginModal} />
    </>
  );
};

EmailExistModal.propTypes = {
  isCheckEmailExistBp: PropTypes.bool,
  isLoginSuccess: PropTypes.bool
};

const mapStateToProp = (state) => ({
  isCheckEmailExistBp: state.checkoutProcesstReducer.accountCheckingReducer?.isCheckEmailExistBp,
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin
});

export default connect(mapStateToProp)(EmailExistModal);
