const TEXT_ARR = [
  'Continue Session Button Text', 
  'Description', 
  'End Session Button Text', 
  'Title',
  'Cancel Button Text',
  'End Session Warning Button Text',
  'No Lifestyle Message',
  'No PDPA and Lifestyle Message',
  'No Product Interest Message',
  'Not Agreed PDPA Message',
  'Save Confirmation Message',
  'Warning Title',
  'By Appointment Label',
  'Unsaved Products Message',
  'Unsaved Products and No Lifestyle Message',
  'Unsaved Products and No PDPA and Lifestyle Message',
  'Unsaved Products and No PAPA'
];
const LINK_ARR = ['Continue Session Button Link', 'End Session Button Link'];

export default class FinalReviewModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = '';
    });

    LINK_ARR.forEach((item) => {
      this[item] = '';
    });
  }
  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}
