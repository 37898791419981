import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = [
  'Warranty Till Label',
  'You Have X Products Label',
  'My Products Label',
  'View All Label',
  'View Details Label',
  'Register Warranty Label',
  'Write Review Label',
  'Register Product and Warranty Button Label',
  'Color Label',
  'Order Number Label',
  'Order Date Label',
  'Unknown Text'
];

const LINKARR = [
  'View All Link',
  'Register Product and Warranty Button Link',
  'Write Review Link',
  'Product Detail Link',
  'Warranty Registation Link',
];

export default class MyProductsWidgetModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
