import FieldsModel from './FieldsModel';

export default class PromotionListingItemModel {
  constructor() {
    this['displayName'] = '';

    this['id'] = '';

    this['name'] = '';

    this['fields'] = new FieldsModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'displayName')) {
      this['displayName'] = dataSource['displayName'] ? dataSource['displayName'] : this['displayName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this['id'] = dataSource['id'] ? dataSource['id'] : this['id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'name')) {
      this['name'] = dataSource['name'] ? dataSource['name'] : this['name'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }

    return this;
  }
}
