import React from 'react';
import { Fragment } from 'react';

const ProductSearchResultNoticeShimmer = () => {

  return (
    <Fragment>
      <div className='search-result__notice skeleton__item'>
        <span className='text-line search-result__notice__text w-30'></span>
        <span className='text-line filter-button transparent w-30'></span>   
      </div>
    </Fragment>
  );
};

export default ProductSearchResultNoticeShimmer;