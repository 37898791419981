import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/cpAppointmentListing';
import * as cpAppointmentListingTypes from '@redux/actions/cpAppointmentListing/cpAppointmentListingTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';

export const getAppointmentListingEpic = (action$) =>
  action$.pipe(
    ofType(cpAppointmentListingTypes.GET_APPOINTMENT_LIST),
    switchMap((action) => {
      return postAsObservable(config.ACTION_GET_APPOINTMENT_LIST, action.payload).pipe(
        map((res) => {
          if (res.data.StatusCode == 200 || res.data.StatusCode == 0) {
            return actions.getAppointmentListSuccess(res.data);
          } else return actions.getAppointmentListFailed('Failed');
        })
      );
    })
  );
