import './PromotionDetailBundle.scss';

import React from 'react';

import CreateBundle from './CreateBundle';
import DynamicBundleElements from './DynamicBundleElements';
// import PromotionDetailBundleBanner from './PromotionDetailBundleBanner';
// import PromotionDetailBundleHeader from './PromotionDetailBundleHeader';

const PromotionDetailBundle = () => {
  
  return (
    <div className='promotion-detail-bundle'>
      {/* <PromotionDetailBundleHeader></PromotionDetailBundleHeader> */}
      {/* <PromotionDetailBundleBanner textBottom={true} textWhite={true}></PromotionDetailBundleBanner> */}
      <CreateBundle></CreateBundle>
      <DynamicBundleElements></DynamicBundleElements>
    </div>
  );
};

export default PromotionDetailBundle;
