import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/myOrders';
import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import CartDetailApiModel from '../../../../components/SlicingPage/CartDetails/models/CartDetailApiModel';
import CartDetailService from '../../product/cartDetail/service';
import * as config from './config';

export const removeBalanceCart = (action$) =>
  action$.pipe(
    ofType(myOrderTypes.REMOVE_BALANCE_CART),
    switchMap((action) => {
      const url = config.ACTION_REMOVE_BALANCE_CART;

      const param = {
        orderId: action.payload.orderId,
        lineIds: action.payload.lineIds
      };

      return getAsObservable(url, param).pipe(
        map((res) => {
          if (res.status === 200) {
            let filteredLineItem = [];
            const cartLineId = action.cartLineId || null;
            const cartDetailApiModel = new CartDetailApiModel().getData(deepCopy(res.data));
            if (cartLineId) {
              const filterItem = cartDetailApiModel?.Lines?.filter((item) => item?.ExternalCartLineId === cartLineId)[0];

              filteredLineItem = filterItem ? [filterItem] : [];
            }

            return actions.removeBalanceCartSuccess(CartDetailService.customCurrentCart(res.data), filteredLineItem);
          } else {
            return actions.removeBalanceCartFailed('Failed');
          }
        })
      );
    })
  );