import { any,func, object, string } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const DesktopCouponWalletItem = ({ data, getCouponDetailHandler, selectedCoupon, layoutData }) => {
  return (
    <div className='offer-widget__content__item coupon-popup__item'>
      <div className={`cart-promotion-code-item ${selectedCoupon == data.Code ? 'selected' : ''}`}>
        <div
          className='cart-promotion-code-item__infor-icon info-icon info-icon--small'
          onClick={() => {
            getCouponDetailHandler(data, false);
          }}
        ></div>
        <div className='cart-promotion-code-item__main-info'>
          <div className='cart-promotion-code-item__group'>
            <h4 className='cart-promotion-code-item__name'>{data.Title}</h4>
            <Text tag='span' className='cart-promotion-code-item__hint-text' field={layoutData['Hint Text']} />
            <span className='cart-promotion-code-item__code'>{data.Code}</span>
          </div>
          <p className='cart-promotion-code-item__description' dangerouslySetInnerHTML={{__html: data.Description}}></p>
        </div>
        <div className='cart-promotion-code-item__footer justify-space-between'>
          <div className='cart-promotion-code-item__footer__date'>
            <Text tag='span'field={layoutData['Vaild Until Label']} />
            <span>{` ${Global.formatDateCoupon(data.ValidityTo)}`}</span>
          </div>
        </div>
        <div className='left-light'></div>
      </div>
    </div>
  );
};

DesktopCouponWalletItem.propTypes = {
  data: object,
  getCouponDetailHandler: func,
  selectedCoupon: string,
  layoutData: any
};

export default DesktopCouponWalletItem;
