import './ApplyOfferPopup.scss';

import PropTypes from 'prop-types';
import React from 'react';

import LogoutComponent from '@components/SingleSignOn/LogoutComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const ApplyOfferPopup = ({ onCancelPopup, layoutData, userIsSpinning, infoUserLogin }) => {

  return (
    <div className='apply-offer-popup'>
      <Text tag='h2' className='apply-offer-popup__title text-center' field={layoutData['Apply Offer Title']} />
      <div className='apply-offer-popup__group'>
        <div className='apply-offer-popup__desc'>
          <p>{layoutData?.['Apply Offer Message']?.value?.replace('{currentEmail}', infoUserLogin?.Email).replace('{suggestEmail}', userIsSpinning?.Email)}</p>
        </div>
        <div className='apply-offer-popup__btn text-center'>
          <div className='apply-offer-popup__btn__item'>
            <LogoutComponent isSpinWinPage={true}>
              <Text tag='span' field={layoutData['Log Out Button Text']} />
            </LogoutComponent>
          </div>
          <div className='apply-offer-popup__btn__item'>
            <Text 
              tag='span' 
              className='btn-link-text' 
              onClick={() => onCancelPopup(false)} 
              field={layoutData['Cancel Button Text']} 
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ApplyOfferPopup.propTypes = {
  onCancelPopup: PropTypes.any,
  layoutData: PropTypes.any,
  userIsSpinning: PropTypes.object,
  infoUserLogin: PropTypes.object,
};

export default ApplyOfferPopup;
