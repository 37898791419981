import Proptypes from 'prop-types';
import React, { useEffect } from 'react';
import { Fragment } from 'react';

import Message from '@components/Share/Message';

const BundleBOVariantsList = ({ contentData, parentId, onAction, onToogleFnc, variantSelected }) => {
  useEffect(() => {
    if (contentData.length === 1) {
      onClickBuyingItemHandler(contentData[0].VariantId, parentId);
    }
  }, []);

  const onClickBuyingItemHandler = (itemId, parentId) => {
    onAction(itemId, parentId);

    onToogleFnc();
  };

  const isDisableItem = (item, variantSelected) => {

    const itemCount = variantSelected?.filter(variant => variant.VariantId === item.VariantId)[0]?.count || 0;

    if (item.MaxQuantity - itemCount === 0) {
      return true;
    } else {
      return false;
    }
  };
  
  return (
    <Fragment>
      {contentData?.length ? (
        <div className='variant-buying-list'>
          <div className='row'>
            {contentData.map((item, itemIndex) => {

              return (
                <div className='col-lg-6 variant-buying-list__item-wrap' key={itemIndex}>
                  <div
                    className={'variant-buying-list__item' + 
                  `${item.active ? ' active' : ''}` + 
                  `${isDisableItem(item, variantSelected) && !item.active ? ' disable' : ''}` + 
                  `${item.isAvaiable ? '' : ' disable'}`}
                    onClick={() => onClickBuyingItemHandler(item.VariantId, parentId)}
                    key={itemIndex}
                  >
                    <div className='variant-buying-list__item__image'>
                      <img src={item.Images[0]} alt='image'/>
                    </div>
                    <div className='variant-buying-list__item__group'>
                      <span className='variant-buying-list__item__name'>{item.VariantPropertyValue}</span>
                      {!item.isAvaiable 
                        ? <Fragment>
                          <span className='variant-buying-list__item__status'><Message messageCode='LBL-OutOfStock' /></span>
                        </Fragment> 
                        : <Fragment>
                          <div className='variant-buying-list__item__price'>
                            {item.Price.ListPrice === item.Price.SellingPrice ? (
                              <span className='variant-buying-list__item__price__selling-price'>{item.Price.SellingPriceWithCurrency}</span>
                            ) : (
                              <Fragment>
                                <span className='variant-buying-list__item__price__selling-price'>{item.Price.SellingPriceWithCurrency}</span>
                                <del className='variant-buying-list__item__price__list-price'>{item.Price.ListPriceWithCurrency}</del>
                              </Fragment>
                            )}
                          </div>
                        </Fragment>}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ) : null }
    </Fragment>
  );
};

BundleBOVariantsList.propTypes = {
  contentData: Proptypes.any,
  parentId: Proptypes.any,
  onAction: Proptypes.any,
  onToogleFnc: Proptypes.any,
  variantSelected: Proptypes.any,
};

export default BundleBOVariantsList;
