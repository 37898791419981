import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import { getMyReviewsFailed, getMyReviewsSuccess } from '@redux/actions/myReviews';
import { GET_MY_REVIEWS_START } from '@redux/actions/myReviews/myReviewsTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import { GET_MY_REVIEWS_ENDPOINT } from './config';

export const getMyReviewsEpic = (action$) =>
  action$.pipe(
    ofType(GET_MY_REVIEWS_START),
    switchMap((action) => {
      return getAsObservable(`${GET_MY_REVIEWS_ENDPOINT}?pageSz=${action.payload}`).pipe(
        map((res) => {
          if (res.status === 200 || res.data.Success) {
            return getMyReviewsSuccess(deepCopy(res.data));
          } else {
            return getMyReviewsFailed('Failed');
          }
        })
      );
    })
  );
