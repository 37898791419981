import './SliderBanners.scss';

import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import SwipeComponent from '@components/Share/SwipeComponent';
import { TEXT_COLOR_WHITE } from '@utils/constant';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

import SliderBannersModel from './Model/SliderBannersModel';
import SliderBannersItem from './SliderBannersItem';

const SliderBanners = (props) => {
  const [dataFields, setDataFields] = useState(null);

  useEffect(() => {
    const sliderBannersModel = new SliderBannersModel();

    setDataFields(sliderBannersModel.getData(deepCopy(props.fields) || {}));
  }, []);

  const autoSlideTime = useMemo(() => {
    return dataFields ? dataFields?.['Auto Slide Time']?.value : 0;
  }, [dataFields]);

  const bannerSlidersParams = {
    loop: true,
    spaceBetween: 0,
    slidesPerView: 1,
    watchOverflow: true,
    speed: 350,
    autoplay: autoSlideTime
      ? {
        delay: parseInt(autoSlideTime * 1000),
        disableOnInteraction: false
      }
      : false,

    breakpoints: {
      768: {
        speed: 750
      },
      1200: {
        speed: 1000
      }
    },

    pagination: {
      el: '.swiper-pagination',
      clickable: true
    }
  };

  const sliderBannerIdValue = useMemo(() => {
    return dataFields?.['Slider Banner Id']?.value || `slider-banner-${props?.rendering?.uid ?? 'id'}`;
  }, [dataFields?.['Slider Banner Id']?.value]);

  const itemsData = useMemo(() => {
    return (
      dataFields?.['items']?.filter((item) =>
        global.checkElementVisible(item?.fields?.['Banner Launch Time']?.value, item?.fields?.['Banner End Time']?.value)
      ) || []
    );
  }, [dataFields]);

  return dataFields ? (
    <div
      id={sliderBannerIdValue.toString()}
      className={dataFields['Bullets Color']?.value === TEXT_COLOR_WHITE ? 'slider-banners slider-banners--bullets-white' : 'slider-banners'}
    >
      <SwipeComponent param={bannerSlidersParams} shouldSwiperUpdate={true}>
        {itemsData.map((item) => {
          return (
            <div className='slider-banners__item' key={item.id}>
              <SliderBannersItem data={item} />
            </div>
          );
        })}
      </SwipeComponent>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='main-banner skeleton__item'>
        <div className='main-banner__background main-banner__background--white'>
          <Loading></Loading>
        </div>
      </div>
    </LoadingShimmer>
  );
};

SliderBanners.propTypes = {
  fields: object,
  rendering: object
};

export default SliderBanners;
