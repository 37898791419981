import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ResourceListingModel {
  constructor() {
    this['Filter By Text'] = new SitecoreTextModel();

    this['Hidden Text'] = new SitecoreTextModel();

    this['Learn More Text'] = new SitecoreTextModel();

    this['No Result Text'] = new SitecoreTextModel();

    this['Result Message'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter By Text')) {
      this['Filter By Text'].getData(dataSource['Filter By Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Hidden Text')) {
      this['Hidden Text'].getData(dataSource['Hidden Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Text')) {
      this['Learn More Text'].getData(dataSource['Learn More Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'No Result Text')) {
      this['No Result Text'].getData(dataSource['No Result Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Result Message')) {
      this['Result Message'].getData(dataSource['Result Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    
    return this;
  }
}
