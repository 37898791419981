import SitecoreTextModel from '@models/SitecoreTextModel';
import LinkModelWithTitle from '@models/LinkModelWithTitle';

export default class PaymentFailFieldsModel {
  constructor() {

    this['Additional Link'] = new LinkModelWithTitle();

    this['Continue Payment Link'] = new LinkModelWithTitle();

    this['Instruction Message'] = new SitecoreTextModel();

    this['Payment Fail Text'] = new SitecoreTextModel();

  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Link')) {
      this['Additional Link'].getData(dataSource['Additional Link'].fields);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Continue Payment Link')) {
      this['Continue Payment Link'].getData(dataSource['Continue Payment Link'].fields);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Instruction Message')) {
      this['Instruction Message'].getData(dataSource['Instruction Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Payment Fail Text')) {
      this['Payment Fail Text'].getData(dataSource['Payment Fail Text']);
    }

    return this;
  }
}
