import './AutomaticallyAddedGift.scss';

import { any, object } from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hideSelectingFreeGiftPopup, showSelectingFreeGiftPopup } from '@redux/actions/controlSelectingFreeGiftPopup';
import { getFreeGiftDataStart, setFreeGiftDataNull } from '@redux/actions/getFreeGiftData';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import FreeGiftPopupLayout from '../FreeGiftPopupLayout';
import { useScrollToSections } from '../hooks';
import FreeGiftPopupModel from '../models/FreeGiftPopupModel';

const AutomaticallyAddedGift = ({ currentCart, ...props }) => {
  const dispatch = useDispatch();
  const { freeGiftData } = useSelector((state) => state.getFreeGiftReducer);
  const [fieldData, setFieldData] = useState(null);
  const scrollToSections = useScrollToSections();

  useEffect(() => {
    const freeGiftPopupModel = new FreeGiftPopupModel();

    setFieldData(freeGiftPopupModel.getData(deepCopy(props?.fields) || {}));
  }, []);

  useEffect(() => {
    const promotionIds = currentCart?.FreeGiftPopup?.PromotionIds;
    if (promotionIds?.length) {
      // INFO: reset freeGiftData data
      dispatch(setFreeGiftDataNull());

      // INFO: get free-gift data
      dispatch(getFreeGiftDataStart(promotionIds));

      // INFO: open free-gift popup layout
      dispatch(showSelectingFreeGiftPopup());
    }
  }, [currentCart?.FreeGiftPopup?.PromotionIds]);

  const handleViewCart = useCallback(() => {
    dispatch(hideSelectingFreeGiftPopup());

    scrollToSections();
  }, []);

  const handleClosePopup = useCallback(() => {
    dispatch(hideSelectingFreeGiftPopup());

    scrollToSections();
  }, [scrollToSections]);

  return fieldData && freeGiftData?.Data?.FreeGifts.length ? (
    <div className='automatically-added-free-gift'>
      <FreeGiftPopupLayout onClosePopup={handleClosePopup}>
        <div className='automatically-added-free-gift__body'>
          <div className='popup-title'>
            <Text tag='h6' field={fieldData?.['Title']} className='title' />
          </div>
          <div className='popup-description'>
            <div className='description-body'>
              <RichText
                field={{ value: fieldData?.['Auto Add Free Gift Label'].value.replace('{freegift_quantity}', freeGiftData?.Data?.FreeGifts.length) }}
                className='content'
              />
              <div className='free-gift'>
                {freeGiftData?.Data?.FreeGifts.map((gift) => (
                  <p key={gift.ProductId} className='gift-name'>
                    {gift.DisplayName}
                  </p>
                ))}
              </div>
            </div>
            <div className='description-cta'>
              <button className='btn btn-primary' onClick={handleViewCart}>
                <Text field={fieldData?.['View Cart Label']} />
              </button>
            </div>
          </div>
        </div>
      </FreeGiftPopupLayout>
    </div>
  ) : (
    <></>
  );
};

AutomaticallyAddedGift.propTypes = {
  currentCart: object,
  fields: any
};

export default AutomaticallyAddedGift;
