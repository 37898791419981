import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class TwoColumnImageModel {
  constructor() {
    this['Left Image'] = new SitecoreImageModel();

    this['Right Image'] = new SitecoreImageModel();

    this['Left Title'] = new SitecoreTextModel();

    this['Right Title'] = new SitecoreTextModel();

    this['Left Sub Title'] = new SitecoreTextModel();

    this['Right Sub Title'] = new SitecoreTextModel();

    this['Left Text Content'] = new SitecoreTextModel();

    this['Right Text Content'] = new SitecoreTextModel();

    this['Left Icon'] = new SitecoreImageModel();

    this['Right Icon'] = new SitecoreImageModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Left Image')) {
      this['Left Image'].getData(dataSource['Left Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Right Image')) {
      this['Right Image'].getData(dataSource['Right Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Left Title')) {
      this['Left Title'].getData(dataSource['Left Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Right Title')) {
      this['Right Title'].getData(dataSource['Right Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Left Sub Title')) {
      this['Left Sub Title'].getData(dataSource['Left Sub Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Right Sub Title')) {
      this['Right Sub Title'].getData(dataSource['Right Sub Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Left Text Content')) {
      this['Left Text Content'].getData(dataSource['Left Text Content']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Right Text Content')) {
      this['Right Text Content'].getData(dataSource['Right Text Content']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Left Icon')) {
      this['Left Icon'].getData(dataSource['Left Icon']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Right Icon')) {
      this['Right Icon'].getData(dataSource['Right Icon']);
    }

    return this;
  }
}
