import { deepCopy } from '@utils/utility';

import BundlePriceCommonModel from './BundlePriceCommonModel';
import BundleProductVariantLabelsModel from './BundleProductVariantLabelsModel';
import BundleProductVariantStockModel from './BundleProductVariantStockModel';

export default class BundleProductVariantDetailsModel {
  constructor() {
    this['active'] = false;

    this['VariantId'] = '';

    this['ProductName'] = '';

    this['VariantName'] = '';

    this['Price'] = new BundlePriceCommonModel();

    this['MobileBannerImage'] = '';

    this['Color'] = '';

    this['Images'] = '';

    this['Stock'] = new BundleProductVariantStockModel();

    this['Labels'] = [];

    this['isAvaiable'] = false;

    this['MaxQuantity'] = 0;

    this['VariantPropertyValue'] = '';
  }
  getData(dataSource, productMaxQuantity) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantId')) {
      this['VariantId'] = dataSource['VariantId'] ? dataSource['VariantId'] : this['VariantId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName')) {
      this['ProductName'] = dataSource['ProductName'] ? dataSource['ProductName'] : this['ProductName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MobileBannerImage')) {
      this['MobileBannerImage'] = dataSource['MobileBannerImage'] ? dataSource['MobileBannerImage'] : this['MobileBannerImage'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Color')) {
      this['Color'] = dataSource['Color'] ? dataSource['Color'] : this['Color'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      this['Images'] = dataSource['Images'] ? dataSource['Images'] : this['Images'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantPropertyValue')) {
      this['VariantPropertyValue'] = dataSource['VariantPropertyValue'] ? dataSource['VariantPropertyValue'] : this['Color'];
    }
    else this['VariantPropertyValue'] = this['Color'];
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock')) {
      this['Stock'].getData(dataSource['Stock'] || {});

      this['isAvaiable'] = this['Stock'].FinalStatus.isAvaiable;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MaxQuantity')) {
      let newVariantMaxQuantity = dataSource['MaxQuantity'] <= 0 ? null : dataSource['MaxQuantity'];

      let newProductMaxQuantity = productMaxQuantity <= 0 ? null : productMaxQuantity;

      this['MaxQuantity'] = newVariantMaxQuantity && newVariantMaxQuantity < this['Stock'].PurchasableLimit ? newVariantMaxQuantity : this['Stock'].PurchasableLimit
      || newProductMaxQuantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels')) {
      let newLabels = [];

      if (dataSource['Labels']) {
        dataSource['Labels'].map((element) => {
          newLabels.push(new BundleProductVariantLabelsModel().getData(deepCopy(element) || {}));
        });
      }

      this['Labels'] = dataSource['Labels'] ? newLabels : this['Labels'];
    }
      
    return this;
  }
}
