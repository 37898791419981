import * as spinAndWinTypes from './spinAndWinTypes';

//Get Available Lucky Draw
export const getAvailableLuckyDraw = (payload) => ({
  type: spinAndWinTypes.GET_AVAILABLE_LUCKY_DRAW,
  payload
});
  
export const getAvailableLuckyDrawSuccess = (payload) => ({
  type: spinAndWinTypes.GET_AVAILABLE_LUCKY_DRAW_SUCCESS,
  payload
});
  
export const getAvailableLuckyDrawFailed = (error) => ({
  type: spinAndWinTypes.GET_AVAILABLE_LUCKY_DRAW_FAILED,
  error
});

//Get Number Of Spin By Email:
export const getNumberSpinEmail = (payload) => ({
  type: spinAndWinTypes.GET_NUMBER_SPIN_EMAIL,
  payload
});
  
export const getNumberSpinEmailSuccess = (payload) => ({
  type: spinAndWinTypes.GET_NUMBER_SPIN_EMAIL_SUCCESS,
  payload
});
  
export const getNumberSpinEmailFailed = (error) => ({
  type: spinAndWinTypes.GET_NUMBER_SPIN_EMAIL_FAILED,
  error
});

//Apply reward :
export const applyReward = (payload) => ({
  type: spinAndWinTypes.APPLY_REWARD,
  payload
});
  
export const applyRewardSuccess = (payload) => ({
  type: spinAndWinTypes.APPLY_REWARD_SUCCESS,
  payload
});
  
export const applyRewardFailed = (error) => ({
  type: spinAndWinTypes.APPLY_REWARD_FAILED,
  error
});

//Get My Prize Lucky Draw
export const getMyPrizeLuckyDraw = (payload) => ({
  type: spinAndWinTypes.GET_MY_PRIZE_LUCKY_DRAW,
  payload
});
    
export const getMyPrizeLuckyDrawSuccess = (payload) => ({
  type: spinAndWinTypes.GET_MY_PRIZE_LUCKY_DRAW_SUCCESS,
  payload
});
    
export const getMyPrizeLuckyDrawFailed = (error) => ({
  type: spinAndWinTypes.GET_MY_PRIZE_LUCKY_DRAW_FAILED,
  error
});

//Get Chance To Win
export const getChanceToWin = (payload) => ({
  type: spinAndWinTypes.GET_CHANCE_TO_WIN,
  payload
});
    
export const getChanceToWinSuccess = (payload) => ({
  type: spinAndWinTypes.GET_CHANCE_TO_WIN_SUCCESS,
  payload
});
    
export const getChanceToWinFailed = (error) => ({
  type: spinAndWinTypes.GET_CHANCE_TO_WIN_FAILED,
  error
});

// Clear Data Input Spin
export const clearDataInputSpin = () => ({
  type: spinAndWinTypes.CLEAR_DATA_INPUT_SPIN
});