import PropTypes from 'prop-types';
import React from 'react';

const MissingDataApi = (props) => {
  return <div className='missing-data-api'>{props.children}</div>;
};

MissingDataApi.propTypes = {
  children: PropTypes.any
};

export default MissingDataApi;
