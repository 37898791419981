import SitecoreTextModel from '@models/SitecoreTextModel';

import DetailPagesModel from '../Models/DetailPagesModel';

export default class ProductResourceListingModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();

    this['Detail Pages'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Detail Pages')) {
      if (dataSource['Detail Pages']?.length) {
        dataSource['Detail Pages'].map((item) => {
          this['Detail Pages'].push(new DetailPagesModel().getData(item || {}));
        });
      }
    }

    return this;
  }
}
