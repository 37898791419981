import produce from 'immer';

import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';

const initState = {
  isLoading: false,
  latestReview: null
};

const latestReviewReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productReviewTypes.GET_LATEST_REVIEW:
      draft.isLoading = true;
      break;

    case productReviewTypes.GET_LATEST_REVIEW_SUCCESS:
      draft.isLoading = false;

      draft.latestReview = action.payload;
      break;

    case productReviewTypes.GET_LATEST_REVIEW_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default latestReviewReducer;