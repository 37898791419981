import './SlideUpBanner.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import { useWindowSize } from '../../utils/customsHook/useWindowSize';
import global from '../../utils/global';
import ProductCountdownTimer from '../ProductCountdownTimer';
import LoadingMask from '../Share/LoadingMask';
import SliceUpBannerModel from './Models/SliceUpBannerModel';

const CONTENT_POSITION = {
  'Content First': 'content-first',
  'Countdown First': 'countdown-first'
};

const CTA_BUTTON_POSITION = {
  'Bottom Left': 'cta-button__bottom-left',
  'Bottom Right': 'cta-button__bottom-right',
  'Bottom Center': 'cta-button__bottom-center',
  'Middle Right': 'cta-button__middle-right',
  'Top Right': 'cta-button__top-right',
  'Middle-Bottom Right': 'cta-button__middle-bottom__right'
};

const SlideUpBanner = ({ fields }) => {
  const [isShowSlideUpBanner, setIsShowSlideUpBanner] = useState(false);
  const [isCloseSlideUp, setIsCloseSlideUp] = useState(false);
  const [fieldData, setFieldData] = useState(null);
  const [isValidSlideUpLaunchTime, setIsValidSlideUpLaunchTime] = useState(false);
  const currentScreenWidth = useWindowSize();
  const CTA_POSITION_RIGHT = ['Middle Right', 'Top Right', 'Middle-Bottom Right'];

  useEffect(() => {
    const slideUpBannerModel = new SliceUpBannerModel();

    setFieldData(slideUpBannerModel.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    if (fieldData) {
      const isVisible = global.checkElementVisible(fieldData?.['Slide Up Launch Time'].value, fieldData?.['Slide Up End Time'].value);

      setIsValidSlideUpLaunchTime(isVisible);
    }
  }, [fieldData]);

  useEffect(() => {
    const isVisibleSession = window.sessionStorage.getItem('isShowSlideUpAgain');
    if (isValidSlideUpLaunchTime) {
      if (isVisibleSession) {
        setIsShowSlideUpBanner(JSON.parse(isVisibleSession));
      } else {
        setIsShowSlideUpBanner(true);
      }
    }
  }, [isValidSlideUpLaunchTime]);

  const generateContentPositionHandler = (key) => {
    return key ? `${CONTENT_POSITION[key]}` : '';
  };

  const generateCTAButtonPositionHandler = (key) => {
    return key ? CTA_BUTTON_POSITION[key] : '';
  };

  const onCloseSlideUpBannerBtnClick = () => {
    setIsCloseSlideUp(true);

    setTimeout(() => {
      setIsShowSlideUpBanner(false);
    }, 500);

    window.sessionStorage.setItem('isShowSlideUpAgain', JSON.stringify(fieldData?.['Is Visible']?.value));
  };

  return fieldData ? (
    <>
      {isShowSlideUpBanner ? (
        <div 
          className={`slideup-banner ${isCloseSlideUp ? 'closed-slideup' : ''}`} 
          style={{ backgroundColor: fieldData?.['Background Color Code']?.value ? `#${fieldData?.['Background Color Code']?.value.replace('#', '')}` : '' }}
        >
          <div className='slideup-banner__button-close'>
            <span className='close-button' onClick={onCloseSlideUpBannerBtnClick}>
              <i className='icon-close'></i>
            </span>
          </div>
          {fieldData?.['Slide Up Desktop Image'] || fieldData?.['Slide Up Mobile Image'] ? (
            <div className='slideup-banner__background'>
              <Image
                field={currentScreenWidth >= 768 ? fieldData?.['Slide Up Desktop Image'] : fieldData?.['Slide Up Mobile Image']}
                className='background-item'
              />
            </div>
          ) : (
            <></>
          )}
          <div className='container'>
            <div
              className={`slideup-banner__container ${fieldData?.['Enable Countdown Timer']?.value ? 'slideup-banner__with-countdown' : ''} ${
                CTA_POSITION_RIGHT.some((postion) => postion === fieldData?.['CTA Button Position'].value) ? 'cta-position__right' : ''
              }`}
            >
              <div className='slideup-banner__content-container'>
                <div className={`slideup-banner__left ${generateContentPositionHandler(fieldData?.['Content Position']?.value)}`}>
                  <div className='slideup-banner__content-detail'>
                    <Text
                      tag='p'
                      field={fieldData?.['Title']}
                      className='slideup-banner__left-title'
                      style={{ color: fieldData?.['Title Color Code']?.value ? `#${fieldData?.['Title Color Code']?.value.replace('#', '')}` : '' }}
                    />
                    <RichText tag='p' field={fieldData?.['Description']} className='slideup-banner__left-content' />
                  </div>
                  {fieldData?.['Enable Countdown Timer']?.value ? (
                    <div className='slideup-banner__countdown'>
                      <ProductCountdownTimer
                        fontSize={{
                          dateMobileUnit: fieldData?.['Countdown Date Mobile Unit Font Size']?.value || '',
                          dateUnit: fieldData?.['Countdown Date Unit Font Size']?.value || '',
                          timerMobileText: fieldData?.['Countdown Timer Mobile Text Font Size']?.value || '',
                          timerText: fieldData?.['Countdown Timer Text Font Size']?.value || '',
                          titleMobileText: fieldData?.['Countdown Title Mobile Text Font Size']?.value || '',
                          titleText: fieldData?.['Countdown Title Text Font Size']?.value || ''
                        }}
                        colorCode={{
                          dateUnitText: fieldData?.['Countdown Date Unit Text Color Code']?.value || '',
                          timerText: fieldData?.['Countdown Timer Text Color Code']?.value || '',
                          titleText: fieldData?.['Countdown Title Text Color Code']?.value || ''
                        }}
                        text={{
                          title: fieldData?.['Countdown Title Text']?.value || '',
                          days: fieldData?.['Countdown Days Text']?.value || '',
                          hours: fieldData?.['Countdown Hours Text']?.value || '',
                          minutes: fieldData?.['Countdown Minutes Text']?.value || '',
                          seconds: fieldData?.['Countdown Seconds Text']?.value || ''
                        }}
                        position={{
                          countdownDesktop: fieldData?.['Countdown Desktop Position']?.value || '',
                          countdownMobile: fieldData?.['Countdown Mobile Position']?.value || ''
                        }}
                        startTime={fieldData?.['Start Time']?.value || ''}
                        endTime={fieldData?.['End Time']?.value || ''}
                        countdownBgImage={fieldData?.['Countdown Background Image']?.value?.src || ''}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                {fieldData?.['CTA Button Text']?.value && fieldData?.['CTA Button Link']?.value?.href ? (
                  <div className={`slideup-banner__right ${generateCTAButtonPositionHandler(fieldData?.['CTA Button Position'].value)}`}>
                    <Link className='osim-account-form__form__btn__item btn btn-primary' field={fieldData?.['CTA Button Link']}>
                      <Text field={fieldData?.['CTA Button Text']} />
                    </Link>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </>
  ) : (
    <LoadingMask />
  );
};

SlideUpBanner.propTypes = {
  fields: Proptypes.any
};

export default SlideUpBanner;
