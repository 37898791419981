import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { getCpAppointmentDetail } from '@redux/actions/cpAppointmentDetail';
import { cpUpdateAppointmentFailed, cpUpdateAppointmentSuccess } from '@redux/actions/cpUpdateAppointment';
import * as cpUpdateAppointmentTypes from '@redux/actions/cpUpdateAppointment/cpUpdateAppointmentTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';

const cpUpdateAppointmentEpic = (action$) =>
  action$.pipe(
    ofType(cpUpdateAppointmentTypes.CP_UPDATE_APPOINTMENT),
    switchMap((action) => {
      const paramDetail = {
        'appointmentId': action.payload?.Id
      };

      return postAsObservable(config.ACTION_CP_UPDATE_APPOINTMENT, action.payload).pipe(
        switchMap((res) => {
          if (res.data.StatusCode == 200) {
            return of(cpUpdateAppointmentSuccess(res.data), getCpAppointmentDetail(paramDetail));
          } else {
            return of(cpUpdateAppointmentFailed(res.data?.EndpointStatus || 'Failed'));
          }
        }),
        catchError((error) => of(cpUpdateAppointmentFailed(error.message || 'Failed')))
      );
    })
  );

export default cpUpdateAppointmentEpic;
