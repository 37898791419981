import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { deepCopy } from '@utils/utility';

import CatalogsProductModel from './CatalogsProductModel';


const TEXT_ARR = [
  'Login Messages',
  'Login Button Text',
  'CP Buy Options Text',
  'Notify Button Redirect'
];

const LINK_ARR = [
  'Login Button Link',
  'CP Buy Options Link',
  'Notify Button Link'
];

export default class ProductDetailHeaderModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((link) => {
      this[link] = new SitecoreLinkModel();
    });

    this['Buy Now Text'] = new SitecoreTextModel();

    this['Buy Now Link'] = new SitecoreLinkModel();

    this['Bundle Buy Now Link'] = new SitecoreLinkModel();

    this['Notify Text'] = new SitecoreTextModel();

    this['Compare Text'] = new SitecoreTextModel();

    this['Compare Link'] = new SitecoreLinkModel();

    this['Notify Popup - Title'] = new SitecoreTextModel();

    this['Notify Popup - Instruction'] = new SitecoreTextModel();

    this['Notify Popup - Email Address Placeholder'] = new SitecoreTextModel();

    this['Notify Popup - Submit Text'] = new SitecoreTextModel();

    this['Deposit Button Text'] = new SitecoreTextModel();

    this['Deposit Button Link'] = new SitecoreLinkModel();

    this['Deposit Label'] = new SitecoreTextModel();

    this['Product Detail Header'] = [];

    this['Product Catalog Current'] = {};

    this['Is Show Compare Product Button'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    TEXT_ARR.forEach((text) => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text].getData(dataSource[text]);
      }
    });

    LINK_ARR.forEach((link) => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link].getData(dataSource[link]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Text')) {
      this['Buy Now Text'].getData(dataSource['Buy Now Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Link')) {
      const queryParams = new URLSearchParams(window.location.search);

      this['Buy Now Link'].getData(dataSource['Buy Now Link'], `productPageId=${queryParams?.get('productPageId') || SitecoreContextFactoryService.getValueContextRouteItem('itemId')}`);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Bundle Buy Now Link')) {
      this['Bundle Buy Now Link'].getData(dataSource['Bundle Buy Now Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Notify Text')) {
      this['Notify Text'].getData(dataSource['Notify Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Compare Text')) {
      this['Compare Text'].getData(dataSource['Compare Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Compare Link')) {
      this['Compare Link'].getData(dataSource['Compare Link'], `productPageId=${SitecoreContextFactoryService.getValueContextRouteItem('itemId')}`);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Notify Popup - Title')) {
      this['Notify Popup - Title'].getData(dataSource['Notify Popup - Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Notify Popup - Instruction')) {
      this['Notify Popup - Instruction'].getData(dataSource['Notify Popup - Instruction']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Notify Popup - Email Address Placeholder')) {
      this['Notify Popup - Email Address Placeholder'].getData(dataSource['Notify Popup - Email Address Placeholder']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Notify Popup - Submit Text')) {
      this['Notify Popup - Submit Text'].getData(dataSource['Notify Popup - Submit Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Detail Header') && dataSource['Product Detail Header']?.length) {
      dataSource['Product Detail Header'].map(catalogItem => {
        this['Product Detail Header'].push(new CatalogsProductModel().getData(deepCopy(catalogItem)));
      });
      const catalogCurrent = this['Product Detail Header'].find(item => item.isActive);

      this['Product Catalog Current'] = new CatalogsProductModel().getData(deepCopy(catalogCurrent || {}));
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Button Text')) {
      this['Deposit Button Text'].getData(dataSource['Deposit Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Button Link')) {
      const queryParams = new URLSearchParams(window.location.search);
      
      this['Deposit Button Link'].getData(dataSource['Deposit Button Link'], `productPageId=${queryParams?.get('productPageId') || SitecoreContextFactoryService.getValueContextRouteItem('itemId')}`);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Label')) {
      this['Deposit Label'].getData(dataSource['Deposit Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Show Compare Product Button')) {
      this['Is Show Compare Product Button'].getData(dataSource['Is Show Compare Product Button']);
    }

    return this;
  }
}
