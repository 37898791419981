import SitecoreTextModel from '@models/SitecoreTextModel';

export default class HelpMeDecideModel {

    constructor() {
        this['Next Button text'] = new SitecoreTextModel();
        this['Previous Button Text'] = new SitecoreTextModel();
        this['Start Again Text'] = new SitecoreTextModel();
        this['Full Body Text'] = new SitecoreTextModel();
        this['View Results Text'] = new SitecoreTextModel();
    }
    
    getData(dataSource) {
        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Next Button text')) {
            this['Next Button text'].getData(dataSource['Next Button text']);
        }
        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Previous Button Text')) {
            this['Previous Button Text'].getData(dataSource['Previous Button Text']);
        }
        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Start Again Text')) {
            this['Start Again Text'].getData(dataSource['Start Again Text']);
        }
        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Full Body Text')) {
            this['Full Body Text'].getData(dataSource['Full Body Text']);
        }
        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Full Body Text')) {
            this['View Results Text'].getData(dataSource['View Results Text']);
        }
        return this;
    }
}
