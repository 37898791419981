import produce from 'immer';

import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';

const initState = {
  isLoading: false,
  isRenewPassword: false,
  errorMessage: '' 
};

const changePasswordReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case singleSignOnTypes.CHANGE_PASSWORD:
      draft.isLoading = true;
  
      draft.errorMessage = '';
      break;
  
    case singleSignOnTypes.CHANGE_PASSWORD_SUCCESS:
      draft.isLoading = false;
  
      draft.isRenewPassword = true;
      break;
        
    case singleSignOnTypes.CHANGE_PASSWORD_FAILED:
      draft.isLoading = false;
    
      draft.isRenewPassword = false;

      draft.errorMessage = action.error;
      break;
    }

    return draft;
  });

export default changePasswordReducer;
