import produce from 'immer';

import * as searchBoxTypes from '@redux/actions/searchBox/searchBoxTypes';

const initState = {
  suggestionList: [],
  error: '',
  isLoading: false,
};

const searchBoxReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case searchBoxTypes.GET_SEARCH_BOX_SUGGESTION_LIST:
      break;

    case searchBoxTypes.GET_SEARCH_BOX_SUGGESTION_LIST_SUCCESS:
      draft.suggestionList = action.payload;
      break;
    
    case searchBoxTypes.GET_SEARCH_BOX_SUGGESTION_LIST_FAILED:
      draft.error = action.error;
    }

    return draft;
  });

export default searchBoxReducer;
