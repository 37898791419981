import './ProductDescription.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TextModel from '@models/TextModel';

const ProductDescription = ({ contentData }) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const productDescriptionModel = new TextModel();

    setData(productDescriptionModel.getData(contentData));
  }, []);

  return data && <h4 className='product-description'>{data.value}</h4>;
};

ProductDescription.propTypes = {
  contentData: Proptypes.any
};

export default ProductDescription;
