import './PlainHTML.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

/**
 * Demonstrates usage of a Rich Text (HTML) content field within JSS.
 */
const PlainHTML = (props) => {
  const { fields } = props;

  return fields ? (
    <RichText field={props.fields.Text} /> 
  ) : '';
};

PlainHTML.propTypes = {
  fields: PropTypes.object
};

export default PlainHTML;
