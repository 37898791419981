import CartDetailApiModel from '@components/SlicingPage/CartDetails/models/CartDetailApiModel';
import GiftingOptionsApiModel from '@components/SlicingPage/CartDetails/models/GiftingOptionsApiModel';
import { DELIVERY_OPTION } from '@utils/constant';
import { deepCopy } from '@utils/utility';

const CartDetailService = {
  customCurrentCart: (data) => {
    const cartDetailApiModel = new CartDetailApiModel().getData(deepCopy(data || {}));

    cartDetailApiModel.Lines.map((itemCart) => {
      if (!itemCart.DeliveryOptionId && itemCart.DeliveryOptions?.length) {
        const shipItem = itemCart.DeliveryOptions.find((deliveryItem) => deliveryItem.ShippingOptionType.Value === DELIVERY_OPTION.ShipItem)
        || itemCart.DeliveryOptions[0];

        itemCart.DeliveryOptionId = shipItem.ExternalId;
      }
      const deliveryItem = itemCart.DeliveryOptions.find((itemDelivery) => itemDelivery.ExternalId === itemCart.DeliveryOptionId);

      itemCart.IsDelivery = deliveryItem?.ShippingOptionType?.Value === DELIVERY_OPTION.ShipItem ? true : false;

      itemCart.IsSFexpress = deliveryItem?.ShippingOptionType?.Value === DELIVERY_OPTION.SFExpress ? true : false;

      itemCart.IsDeliveryBySFExpress = deliveryItem?.ShippingOptionType?.Value === DELIVERY_OPTION.DeliveryBySFExpress ? true : false;
    });

    cartDetailApiModel.IsDeliveryFee = cartDetailApiModel.Lines.filter((line) => line.IsDelivery).length ? true : false;

    cartDetailApiModel.IsDeliveryPickupFee = cartDetailApiModel.Lines.filter((line) => line.IsSFexpress).length ? true : false;

    cartDetailApiModel.IsDeliveryBySFExpressFee = cartDetailApiModel.Lines.filter((line) => line.IsDeliveryBySFExpress).length ? true : false;

    cartDetailApiModel.Lines = CartDetailService.sortCartLine(cartDetailApiModel.Lines);
    
    return cartDetailApiModel;
  },
  sortCartLine: (arr) => {
    if (arr.length < 2) return arr;
    const allCart = [];
  
    const pwp = arr.filter(pwpItem => pwpItem.MasterId);

    const product = arr.filter(productItem => !productItem.MasterId);
    let pwpItemAll = [];

    product.map(productItem => {
      const pwpItem = pwp.filter(pwpItem => (pwpItem.MasterId === productItem.ExternalCartLineId));

      pwpItemAll.push(...pwpItem);

      productItem.IsParentForPwP = pwpItem.length ? true : false;
      
      allCart.push(productItem, ...pwpItem);
    });    
    
    const pwpOnlyMasterId =  pwp.filter(item => !pwpItemAll.find(pwpFilterItem => pwpFilterItem && pwpFilterItem.ExternalCartLineId === item.ExternalCartLineId ));

    pwpOnlyMasterId.map(pwpOnlyMasterItem => {
      pwpOnlyMasterItem.isOnlyMasterId = true;
    });
    if(pwpOnlyMasterId.length) {
      allCart.push(...pwpOnlyMasterId);
    }

    return allCart;
  },

  customGiftingOptions: (data) => new GiftingOptionsApiModel().getData(deepCopy(data || {}))
};

export default CartDetailService;
