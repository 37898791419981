import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/myAccount';
import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';
import { postAsObservable } from '@services/genericService';

import * as config from './config';

export const changePasswordEpic = (action$) =>
  action$.pipe(
    ofType(myAccountTypes.CHANGE_PASSWORD_ACCOUNT),
    switchMap((action) => {
      return postAsObservable(config.ACTION_CHANGE_PASSWORD, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.changePasswordAccountSuccess(res.data);
          } else {
            return actions.changePasswordAccountFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );
  