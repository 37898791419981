import './CartPromotionCodes.scss';

import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import SwipeComponent from '@components/Share/SwipeComponent';
import { setErrorMessenger } from '@redux/actions/cartDetail';
import {
  applyCouponCode,
  applySOCouponCode,
  getBrowseCouponList,
  getCouponDetail,
  removeCouponCode,
  removeCouponDetail,
  removeSOCouponCodeStart
} from '@redux/actions/coupon';
import {
  getClientSOCartSelector
} from '@redux/reducers/personalizeOffer/getClientSOCartReducer/getClientSOCartSelector';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

import {useOfferDetails} from '../../../PersonalizedOffer/OfferDetails/OfferDetailsProvider';
import AppliedCouponItem from './AppliedCouponItem';
import BaseCouponItem from './BaseCouponItem';
import CartCouponCodeModel from './model/CartCouponCodeModel';
import PopUpCouponItem from './PopUpCouponItem';
import {
  COUPON_EVENT_ATTEMPT_STATUS,
  COUPON_EVENT_FAILURE_STATUS,
  COUPON_EVENT_SUCCESS_STATUS,
  handleTrackingApplyCoupon
} from './utils';

const CartPromotionCodes = (props) => {
  const [inputData, setInputData] = useState(null);
  const [couponDetailIsShowed, setCouponDetailIsShowed] = useState(false);
  const [popUpDetailCouponList, setPopUpDetailCouponList] = useState(false);
  const [modalIsList, setModalIsList] = useState(false);
  const [availableCouponList, setAvailableCouponList] = useState(null);
  const [visibleAvailableCouponList, setVisibleAvailableCouponList] = useState(null);
  const [invisibleAvailableCouponList, setInvisibleAvailableCouponList] = useState(null);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [clearInputButtonStatus, setClearInputButtonStatus] = useState(props.isError);
  const dispatch = useDispatch();
  const [couponCodeValue, setCouponCodeValue] = useState('');
  const isCart = useSelector((state)=> state?.productReducer?.cartDetailReducer?.currentCart?.Lines?.length);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const { data: clientSOCartData } = useSelector(getClientSOCartSelector);
  const { isEditingSOCart } = useSelector((state) => state.cpCartDetailsReducer);

  useEffect(() => {
    const cartCouponCodeModel = new CartCouponCodeModel();

    setInputData(cartCouponCodeModel.getData(deepCopy(props.fields || {})));
  }, []);

  useEffect(() => {
    if (props.currentCart) {
      if (!isClientTelingUser) {
        dispatch(getBrowseCouponList());
      } else {
        dispatch(getBrowseCouponList({ cartId: props.currentCart?.Name || '' }));
      }
    }
  }, [props.currentCart, isClientTelingUser]);

  useDidUpdateEffect(() => {
    let tempList = props.couponList.filter((item) => {
      return props.appliedCouponList.every((appliedItem) => {
        return appliedItem.Code.toLowerCase() !== item.Code.toLowerCase();
      });
    });

    setAvailableCouponList(tempList);
  }, [props.appliedCouponList]);

  useEffect(() => {
    let tempList = props.couponList.filter((item) => {
      return props.appliedCouponList.every((appliedItem) => {
        return appliedItem.Code.toLowerCase() !== item.Code.toLowerCase();
      });
    });

    setAvailableCouponList(tempList);
  }, [props.couponList]);

  useDidUpdateEffect(() => {
    if (props?.errorMsg && !couponCodeValue) {
      handleTrackingApplyCoupon(COUPON_EVENT_ATTEMPT_STATUS);
    }
    if (props?.errorMsg && couponCodeValue) {
      handleTrackingApplyCoupon(COUPON_EVENT_FAILURE_STATUS, couponCodeValue);
    }
  }, [props?.errorMsg, couponCodeValue]);

  useDidUpdateEffect(() => {
    if (props?.appliedCouponList) {
      const couponCodeIsApplied = props?.appliedCouponList.find((coupon) => coupon.Code.toLowerCase() === couponCodeValue.toLowerCase());
      if (couponCodeIsApplied) {
        handleTrackingApplyCoupon(COUPON_EVENT_SUCCESS_STATUS, couponCodeIsApplied.Code.toUpperCase());
      }
    }
  }, [props?.appliedCouponList, couponCodeValue]);

  useDidUpdateEffect(() => {
    if (availableCouponList && availableCouponList.length >= 4) {
      let visible = deepCopy(availableCouponList).slice(0, 3);
      let invisible = deepCopy(availableCouponList);

      invisible.splice(0, 3);

      setVisibleAvailableCouponList(deepCopy(visible));

      setInvisibleAvailableCouponList(deepCopy(invisible));
    } else {
      setVisibleAvailableCouponList(availableCouponList);

      setInvisibleAvailableCouponList([]);
    }
  }, [availableCouponList]);

  const onGetCouponInputHandler = (e) => {
    e.preventDefault();

    let value = e.target[0].value.toUpperCase();

    // let tempList = deepCopy(availableCouponList);

    // tempList.length
    //   ? tempList.find((item) => {
    //     if (item.Code === value) {
    //       return dispatch(applyCouponCode(value), setErrorMsg(null));
    //     } else {
    //       return setErrorMsg('No coupon code available!');
    //     }
    //   })
    //   : setErrorMsg('No coupon code available!');

    if (value) {
      document.querySelector('#couponCodeValue').blur();

      setCouponCodeValue(value);

      if(isClientTelingUser) {
        dispatch(applySOCouponCode({ code: value, reCallPromotionsApplied: true }));
      } else {
        dispatch(applyCouponCode({ code: value, reCallPromotionsApplied: true }));
      }
    } else {
      dispatch(setErrorMessenger(props.errorCouponMsg));

      setCouponCodeValue('');

      setTimeout(() => {
        dispatch(setErrorMessenger(''));
      }, 3000);
    }
  };

  const onOpenDetailHandler = (couponData) => {
    dispatch(removeCouponDetail());

    const params = {
      id: couponData
    };

    dispatch(getCouponDetail(params));

    setCouponDetailIsShowed(true);

    setModalIsList(false);
  };

  const onOpenDetailListHandler = (couponData, e) => {
    e.stopPropagation();

    dispatch(removeCouponDetail());

    const params = {
      id: couponData
    };

    dispatch(getCouponDetail(params));

    setPopUpDetailCouponList(true);

    setCouponDetailIsShowed(false);
  };

  const onOpenListHandler = () => {
    setCouponDetailIsShowed(true);

    setModalIsList(true);
  };

  const onApplyCouponHandler = (params) => {
    //INFO: remove current error message before proceed an action
    dispatch(setErrorMessenger(''));
    if (params.actionType === 'apply') {
      if (isClientTelingUser) {
        dispatch(applySOCouponCode({ code: params.data.Code, reCallPromotionsApplied: true }));
      } else {
        dispatch(applyCouponCode({ code: params.data.Code, reCallPromotionsApplied: true }));
      }

      setPopUpDetailCouponList(false);

      setSelectedCoupon(null);

      setCouponDetailIsShowed(false);

      // INFO: set applied coupon code for checking when applied success
      setCouponCodeValue(params.data.Code);
    } else if (params.actionType === 'remove') {
      if (!isClientTelingUser) {
        dispatch(removeCouponCode(params.data.Code));
      } else {
        dispatch(removeSOCouponCodeStart({ code: params.data.Code, cartId: props.currentCart?.Name || '' }));
      }
    }
  };

  const onSelectCouponHandler = (params) => {
    let tempList = deepCopy(invisibleAvailableCouponList);

    tempList.forEach((item) => {
      if (item.Code === params.Code) {
        setSelectedCoupon(item);
      }
    });
  };

  const onClosePopUpHandler = () => {
    setSelectedCoupon(null);

    setCouponDetailIsShowed(false);
  };

  const onClearInputHandler = () => {
    setClearInputButtonStatus(false);

    document.querySelector('#couponCodeValue').value = '';

    dispatch(setErrorMessenger(''));
  };

  const onClosePopUpDetailCouponListHandler = () => {
    setPopUpDetailCouponList(false);

    setCouponDetailIsShowed(true);
  };

  const params = {
    slidesPerView: 1.4,
    centeredSlides: false,
    spaceBetween: 15,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    breakpoints: {
      1366: {
        slidesPerView: 4,
        spaceBetween: 40
      },

      1200: {
        slidesPerView: 4,
        spaceBetween: 30
      },

      767: {
        slidesPerView: 2.2,
        spaceBetween: 30
      }
    }
  };

  const onCovertEpochToDate = (param) => {
    let MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let date = new Date(parseInt(param.replace(/\D/g, '')));

    return `${date.getDate()} ${MONTH_NAMES[date.getMonth()]} ${date.getFullYear()}`;
  };

  return (
    inputData && (
      <Fragment>
        <div className='cart-promotion-codes large-text'>
          <div className='container'>
            {(!isCart && isClientTelingUser) || (clientSOCartData.OsimCart && clientSOCartData.SOCart) || (isClientTelingUser && !isEditingSOCart) ? (
              <></>
            ) : (
              <>
                <div className='cart-promotion-codes__form'>
                  <div className='row'>
                    <div className='col-md-6'></div>
                    <div className='col-md-6'>
                      <form className='cart-promotion-codes__form__group' onSubmit={(e) => onGetCouponInputHandler(e)}>
                        <Text tag='label' field={inputData['Coupon Code Label']} />
                        <div className='cart-promotion-codes__form__input'>
                          <input
                            className='form-control form-control-lg checkout-input__input'
                            type='text'
                            name='couponCode'
                            placeholder={inputData['Coupon Code Placeholder'].value}
                            id='couponCodeValue'
                            onChange={(e) => (e.target.value.length ? setClearInputButtonStatus(true) : setClearInputButtonStatus(false))}
                          />
                          {clearInputButtonStatus ? <i className='icon-close' onClick={() => onClearInputHandler()} /> : <></>}
                          {/* <input type='submit' value={inputData['Apply Button Text'].value} className='btn btn-outline-CTA2' /> */}
                          <button className='btn btn-outline-CTA2' type='submit'>
                            <Text field={inputData['Apply Button Text']}></Text>
                          </button>
                        </div>
                        <span className='error-validate'>{props.errorMsg}</span>
                      </form>
                    </div>
                    {/* <div className='col-md-6'></div>
                    <div className='col-md-6'>{errorMsg && <p>{errorMsg}</p>}</div> */}
                  </div>
                </div>
                <div className='cart-promotion-codes__list-carts'>
                  {availableCouponList?.length ? (
                    <Text className='cart-promotion-codes__list-carts__description normal-text' tag='p' field={inputData['Select coupon Label']} />
                  ) : (
                    <></>
                  )}
                  {visibleAvailableCouponList?.length ? (
                    <SwipeComponent param={params}>
                      {visibleAvailableCouponList.map((item) => {
                        return (
                          <div className='swiper-slide cart-promotion-code-item' key={item.Code}>
                            <BaseCouponItem
                              onOpenDetailHandler={onOpenDetailHandler}
                              data={item}
                              onApplyCouponHandler={onApplyCouponHandler}
                              dataFromLayout={inputData}
                            />
                          </div>
                        );
                      })}
                      {invisibleAvailableCouponList?.length ? (
                        <div className='swiper-slide cart-promotion-code-view-more'>
                          <span className='flex-between' onClick={() => onOpenListHandler()}>
                            <Text field={inputData['View More Coupon Label']} />
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </SwipeComponent>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            )}
            <div className='cart-promotion-codes__list-cart-tags'>
              <ul className='cart-promotion-codes__list-cart-tags__group'>
                {props?.appliedCouponList &&
                  props?.appliedCouponList?.map((item) => {
                    return <AppliedCouponItem key={item.Id} onApplyCouponHandler={onApplyCouponHandler} data={item} />;
                  })}
              </ul>
            </div>
          </div>
        </div>
        <ModalComponent
          isShow={couponDetailIsShowed}
          onCloseHandler={() => onClosePopUpHandler()}
          titlePopup={
            modalIsList ? <Text tag='span' field={inputData['Coupon List Title']} /> : <Text tag='span' field={inputData['Coupon Detail Title']} />
          }
        >
          {modalIsList ? (
            //LIST MODAL
            <Fragment>
              <div className='osim-popup__body coupon-popup__content'>
                {invisibleAvailableCouponList?.length &&
                  invisibleAvailableCouponList.map((item) => {
                    return (
                      <PopUpCouponItem
                        key={item.Id}
                        onOpenDetailListHandler={onOpenDetailListHandler}
                        data={item}
                        onSelectCouponHandler={onSelectCouponHandler}
                        dataFromLayout={inputData}
                        isSelected={selectedCoupon}
                      />
                    );
                  })}
              </div>
              <div className='osim-popup__footer coupon-popup__action'>
                <button
                  className='btn btn-outline-primary'
                  onClick={() => onApplyCouponHandler({ data: selectedCoupon, actionType: 'apply' })}
                  disabled={selectedCoupon === null ? true : false}
                >
                  <Text tag='span' field={inputData['Apply Coupons Button Text']} />
                </button>
              </div>
            </Fragment>
          ) : (
            // DETAIL MODAL
            <Fragment>
              {props.isLoadingMask ? (
                <Fragment>
                  <div className='osim-popup__body coupon-popup__info skeleton__item'>
                    <h4 className='coupon-popup__info-title'>
                      <span className='text-line-40-center w-60'></span>
                    </h4>
                    <div className='coupon-popup__info-text'>
                      <span className='text-line-27'></span>
                    </div>
                    <span className='coupon-popup__info-validity'>
                      <span className='text-line-27'></span>
                    </span>
                  </div>
                  <div className='osim-popup__footer coupon-popup__action'>
                    <span className='text-line-50 w-30'></span>
                  </div>
                </Fragment>
              ) : (
                <Fragment>
                  {props.couponDetail?.IsSingleCoupon ? (
                    <>
                      <div className='osim-popup__body coupon-popup__info--single-coupon'>
                        <h3 className='coupon-popup__info-title'>{props.couponDetail?.Title}</h3>
                        <div className={`coupon-popup__banner${props.couponDetail?.BackgroundImage ? '' : ' coupon-popup__banner--no-image'}`}>
                          {props.couponDetail?.BackgroundImage ? (
                            <div className='coupon-popup__banner__bg'>
                              <img src={props.couponDetail?.BackgroundImage} alt='banner' />
                            </div>
                          ) : (
                            ''
                          )}
                          <div className='coupon-popup__banner__content'>
                            <p
                              className='coupon-popup__banner__content__desc'
                              dangerouslySetInnerHTML={{ __html: props.couponDetail?.Description }}
                            ></p>
                            <span className='coupon-popup__banner__content__date'>
                              {props.couponDetail?.ValidFromToText.replace('{from}', onCovertEpochToDate(props.couponDetail?.ValidityFrom)).replace(
                                '{to}',
                                onCovertEpochToDate(props.couponDetail?.ValidityTo)
                              )}
                            </span>
                            <h5 className='coupon-popup__banner__content__code'>
                              {props.couponDetail?.CouponText.replace('{coupon}', '')}
                              <b>{props.couponDetail?.Code}</b>
                            </h5>
                          </div>
                        </div>
                        <div className='coupon-popup__list'>
                          {props.couponDetail?.Benefits.map((benefit, index) => {
                            return (
                              <div className='coupon-popup__item' key={index}>
                                <div className='coupon-popup__item__image'>
                                  <a href={benefit?.Link}>
                                    <img src={benefit?.Image} alt='banner' />
                                  </a>
                                </div>
                                <div className='coupon-popup__item__content'>
                                  <a href={benefit?.Link}>
                                    <h4 className='coupon-popup__item__content__title'>{benefit?.Title}</h4>
                                  </a>
                                  <h5 className='coupon-popup__item__content__sub-title'>{benefit?.SubTitle}</h5>
                                  <p className='coupon-popup__item__content__desc' dangerouslySetInnerHTML={{ __html: benefit?.Description }}></p>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className='osim-popup__footer coupon-popup__action'>
                        <button className='btn btn-primary' onClick={() => onApplyCouponHandler({ data: props.couponDetail, actionType: 'apply' })}>
                          {props.couponDetail?.ButtonText}
                        </button>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className='osim-popup__body coupon-popup__info'>
                        <h4 className='coupon-popup__info-title'>{props.couponDetail.Title}</h4>
                        <div className='text-center'>
                          <Text tag='span' className='coupon-popup__hint-text' field={inputData['Hint Text']} />
                          <span className='coupon-popup__code'>{props.couponDetail.Code}</span>
                        </div>
                        <div className='coupon-popup__info-text' dangerouslySetInnerHTML={{ __html: props.couponDetail.Description }}></div>
                        <span className='coupon-popup__info-validity'>
                          <Text tag='strong' field={inputData['Validity Label']} />
                          {/* fixed coupon display 12/10/2022 */}
                          <span>: {global.formatDateCoupon(props.couponDetail.ValidityFrom)} </span>
                          <Text tag='span' field={inputData['To Label']} />
                          <span> {global.formatDateCoupon(props.couponDetail.ValidityTo)} </span>
                        </span>
                      </div>
                      <div className='osim-popup__footer coupon-popup__action'>
                        <button
                          className='btn btn-outline-primary'
                          onClick={() => onApplyCouponHandler({ data: props.couponDetail, actionType: 'apply' })}
                        >
                          <Text tag='span' field={inputData['Apply Coupons Button Text']} />
                        </button>
                      </div>
                    </>
                  )}
                </Fragment>
              )}
            </Fragment>
          )}
        </ModalComponent>
        {/* DETAIL MODAL AFTER OPENED LIST MODAL */}
        <ModalComponent
          isShow={popUpDetailCouponList}
          onCloseHandler={() => onClosePopUpDetailCouponListHandler()}
          titlePopup={<Text tag='span' field={inputData['Coupon Detail Title']} />}
        >
          <Fragment>
            {props.isLoadingMask ? (
              <Fragment>
                <div className='osim-popup__body coupon-popup__info skeleton__item'>
                  <h4 className='coupon-popup__info-title'>
                    <span className='text-line-40-center w-60'></span>
                  </h4>
                  <div className='coupon-popup__info-text'>
                    <span className='text-line-27'></span>
                  </div>
                  <span className='coupon-popup__info-validity'>
                    <span className='text-line-27'></span>
                  </span>
                </div>
                <div className='osim-popup__footer coupon-popup__action'>
                  <span className='text-line-50 w-30'></span>
                </div>
              </Fragment>
            ) : (
              <Fragment>
                {props.couponDetail?.IsSingleCoupon ? (
                  <>
                    <div className='osim-popup__body coupon-popup__info--single-coupon'>
                      <h3 className='coupon-popup__info-title'>{props.couponDetail?.Title}</h3>
                      <div className={`coupon-popup__banner${props.couponDetail?.BackgroundImage ? '' : ' coupon-popup__banner--no-image'}`}>
                        {props.couponDetail?.BackgroundImage ? (
                          <div className='coupon-popup__banner__bg'>
                            <img src={props.couponDetail?.BackgroundImage} alt='banner' />
                          </div>
                        ) : (
                          ''
                        )}
                        <div className='coupon-popup__banner__content'>
                          <p
                            className='coupon-popup__banner__content__desc'
                            dangerouslySetInnerHTML={{ __html: props.couponDetail?.Description }}
                          ></p>
                          <span className='coupon-popup__banner__content__date'>
                            {props.couponDetail?.ValidFromToText.replace('{from}', onCovertEpochToDate(props.couponDetail?.ValidityFrom)).replace(
                              '{to}',
                              onCovertEpochToDate(props.couponDetail?.ValidityTo)
                            )}
                          </span>
                          <h5 className='coupon-popup__banner__content__code'>
                            {props.couponDetail?.CouponText.replace('{coupon}', '')}
                            <b>{props.couponDetail?.Code}</b>
                          </h5>
                        </div>
                      </div>
                      <div className='coupon-popup__list'>
                        {props.couponDetail?.Benefits.map((benefit, index) => {
                          return (
                            <div className='coupon-popup__item' key={index}>
                              <div className='coupon-popup__item__image'>
                                <a href={benefit?.Link}>
                                  <img src={benefit?.Image} alt='banner' />
                                </a>
                              </div>
                              <div className='coupon-popup__item__content'>
                                <a href={benefit?.Link}>
                                  <h4 className='coupon-popup__item__content__title'>{benefit?.Title}</h4>
                                </a>
                                <h5 className='coupon-popup__item__content__sub-title'>{benefit?.SubTitle}</h5>
                                <p className='coupon-popup__item__content__desc' dangerouslySetInnerHTML={{ __html: benefit?.Description }}></p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div className='osim-popup__footer coupon-popup__action'>
                      <button className='btn btn-primary' onClick={() => onApplyCouponHandler({ data: props.couponDetail, actionType: 'apply' })}>
                        {props.couponDetail?.ButtonText}
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className='osim-popup__body coupon-popup__info'>
                      <h4 className='coupon-popup__info-title'>
                        {props.couponDetail.Code}
                        <span> [{props.couponDetail.Code}]</span>
                      </h4>
                      <div className='coupon-popup__info-text' dangerouslySetInnerHTML={{ __html: props.couponDetail?.Description }}></div>
                      <span className='coupon-popup__info-validity'>
                        {/* fixed coupon display 12/10/2022 */}
                        <Text tag='strong' field={inputData['Validity Label']} />
                        <span>: {global.formatDateCoupon(props.couponDetail.ValidityFrom)} </span>
                        <Text tag='span' field={inputData['To Label']} />
                        <span> {global.formatDateCoupon(props.couponDetail.ValidityTo)} </span>
                      </span>
                    </div>
                    <div className='osim-popup__footer coupon-popup__action'>
                      <button
                        className='btn btn-outline-primary'
                        onClick={() => onApplyCouponHandler({ data: props.couponDetail, actionType: 'apply' })}
                      >
                        <Text tag='span' field={inputData['Apply Coupons Button Text']} />
                      </button>
                    </div>
                  </>
                )}
              </Fragment>
            )}
          </Fragment>
        </ModalComponent>
        {props.isLoading ? <LoadingMask /> : <></>}
      </Fragment>
    )
  );
};

CartPromotionCodes.propTypes = {
  fields: PropTypes.object,
  couponList: PropTypes.array,
  couponDetail: PropTypes.object,
  appliedCouponList: PropTypes.array,
  isLoading: PropTypes.bool,
  errorMsg: PropTypes.string,
  isError: PropTypes.bool,
  isLoadingMask: PropTypes.bool,
  errorCouponMsg: PropTypes.string,
  currentCart: PropTypes.object,
};

const mapStateToProps = (state) => ({
  couponList: state.couponReducer?.couponList || [],
  couponDetail: state.couponReducer?.couponDetail?.Promotion || {},
  appliedCouponList: state.couponReducer?.appliedCouponList || [],
  isLoading: state.couponReducer?.isLoading || false,
  errorMsg: state.bundleBOReducer?.errorMsg || '',
  isError: state.bundleBOReducer?.isError || null,
  isLoadingMask: state.couponReducer?.isLoadingMask || false,
  errorCouponMsg: state.couponReducer?.errMsg || '',
  currentCart: state.productReducer.cartDetailReducer.currentCart,
});

export default connect(mapStateToProps)(CartPromotionCodes);
