import parse from 'html-react-parser';
import { string } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const ProductWarrantySelect = ({ defaultValue, ...props }) => {
  const productForRegisterData = useSelector((state) => state.productForRegisterWarrantyReducer.productDataForRegister);
  const [productOptions, setProductOptions] = useState([]);

  useEffect(() => {
    // custom product data array
    if (productForRegisterData.length) {
      let productOptionsTemp = [];
      for (const productItem of productForRegisterData) {
        productOptionsTemp.push({
          label: parse(productItem.ProductName || ''),
          value: productItem.ProductPageId,
          CommerceProductId: productItem.CommerceProductId,
          HasSerialNumber: productItem.HasSerialNumber
        });
      }

      setProductOptions(productOptionsTemp);
    }
  }, [productForRegisterData]);

  const productValue = useMemo(() => {
    return defaultValue ? productOptions.find((item) => item.value === defaultValue) : '';
  }, [defaultValue, productOptions]);

  return (
    <Select
      {...props}
      id='productId'
      name='productId'
      value={productValue}
      options={productOptions}
      className='customization-dropdown'
      classNamePrefix='customization-dropdown'
      isSearchable={true}
    />
  );
};

ProductWarrantySelect.propTypes = {
  defaultValue: string
};

export default ProductWarrantySelect;
