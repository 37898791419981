// import './CustomerProfiles.scss';

import React from 'react';

// import ContactInfor from './ContactInfor';
// import CustomerLastVisit from './CustomerLastVisit';
// import CustomerProducts from './CustomerProducts';
// import CustomerWishlist from './CustomerWishlist';
// import ProductInterest from './ProductInterest';
// import VoucherAvailable from './VoucherAvailable';

const CustomerProfiles = () => {
  return <>
    {/* <div className='profiles'>
      <div className='container tablet-container'>
        <div className='customer'>
          <h2 className='customer__name'>John Doe</h2>
          <div className='customer__rank'>
            <img src={require('@assets/images/CP-images/silver-rank.PNG')} alt='sliver rank' />
          </div>
        </div>
        <ContactInfor/>
        <div className='row profiles__row'>
          <div className='col-md-6 profiles__col'>
            <CustomerProducts />
          </div>
          <div className='col-md-6 profiles__col'>
            <CustomerWishlist />
          </div>
          <div className='col-md-6 profiles__col'>
            <ProductInterest />
          </div>
          <div className='col-md-6 profiles__col'>
            <VoucherAvailable />
          </div>
          <div className='col-md-6 profiles__col'>
            <CustomerLastVisit />
          </div>
        </div>
      </div>
    </div> */}
  </>;
};

export default CustomerProfiles;
