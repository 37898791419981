import { object } from 'prop-types';
import React, { useMemo } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const ProductSelected = ({ layoutData }) => {
  const changeProductBtnLink = useMemo(() => {
    const appointmentId = layoutData?.appointment?.Id;

    return layoutData['Change Product Link']?.value?.href
      ? {
        value: {
          ...layoutData['Change Product Link'].value,
          href: `${layoutData?.['Change Product Link']?.value?.href}?appointment_id=${appointmentId}`
        }
      }
      : '';
  }, [layoutData]);

  return (
    <div className='appointment-detail__body__item appointment-detail__body__item--selected-product'>
      <Text field={layoutData?.['Selected Product Label']} tag='h3' className='appointment-detail__body__item__title' />
      <div className='appointment-detail__body__item__img'>
        <div className='appointment-detail__body__item__img-wrapper'>
          <img src={layoutData?.appointment?.Product?.Image} alt={layoutData?.appointment?.Product?.Name} />
        </div>
      </div>
      <div className='appointment-detail__body__item__content'>
        <h4 className='appointment-detail__body__item__content__title'>{layoutData?.appointment?.Product?.Name}</h4>
        <div
          className='appointment-detail__body__item__content__description'
          dangerouslySetInnerHTML={{ __html: layoutData?.appointment?.Product?.Description }}
        ></div>
      </div>
      {layoutData?.isUpComing ? (
        changeProductBtnLink ? (
          <Link field={changeProductBtnLink} className='appointment-detail__body__item__button btn btn-outline-primary'>
            <Text field={layoutData?.['Change Product Text']} />
          </Link>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </div>
  );
};

ProductSelected.propTypes = {
  layoutData: object
};

export default ProductSelected;
