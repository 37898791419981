import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

const BundleBOSecondTypeHeader = ({ isOpen, labelText, changeText, secondTypeItemsName, onToogleFnc, errorMsg, isShowError, contentData }) => {
  const [activeChangeButton, setActiveChangeButton] = useState(false);

  useEffect(() => {
    if (
      contentData.every((line) => {
        return line.items.length === 1;
      })
    ) {
      setActiveChangeButton(false);
    } else setActiveChangeButton(true);
  }, []);

  return (
    <Fragment>
      <div className='accordion-buying-option__header'>
        <h3 className='bo-title-section'>{labelText}</h3>
        {activeChangeButton ? (
          <span className={`btn-change${isOpen ? '' : ' active'}`} onClick={onToogleFnc}>
            {changeText}
          </span>
        ) : (
          <></>
        )}
        <span className={'error-msg' + `${isShowError ? ' active' : ''}`}>{errorMsg}</span>
      </div>
      {!isOpen ? (
        <div>
          {secondTypeItemsName.map((item, itemIdx) => {
            return (
              <div className='' key={itemIdx}>
                {item.DisplayName}
              </div>
            );
          })}
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

BundleBOSecondTypeHeader.propTypes = {
  isOpen: PropTypes.any,
  labelText: PropTypes.any,
  changeText: PropTypes.any,
  secondTypeItemsName: PropTypes.any,
  onToogleFnc: PropTypes.any,
  errorMsg: PropTypes.any,
  isShowError: PropTypes.any,
  contentData: PropTypes.array
};

export default BundleBOSecondTypeHeader;
