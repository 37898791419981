// import './OsimCheckBox.scss';

import PropTypes from 'prop-types';
import React from 'react';

const OsimCheckBox = (props) => {
  const { isChecked, onToogleChkBoxFnc } = props;

  return (
    <span
      className={`osim-checkbox${isChecked ? ' checked': ''}`}
    >
      <input
        id={'chkboxid'}
        type='checkbox'
        className='osim-checkbox-input'
        checked={isChecked}
        onChange={onToogleChkBoxFnc}
      />
      <label
        htmlFor={'chkboxid'}
        className='osim-checkbox-label'
      >
        {props.children}
      </label>
    </span>
  );
};

OsimCheckBox.propTypes = {
  isChecked: PropTypes.any,
  children: PropTypes.any,
  onToogleChkBoxFnc: PropTypes.any,
};

export default OsimCheckBox;
