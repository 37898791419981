import './ProductInterestItem.scss';

import { any, bool, string } from 'prop-types';
import React, { useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { ANSWER_WITH_BRACKET_TYPE } from '../../config';
import ProductInterestItemQuestion from './ProductInterestItemQuestionArea';

const ProductInterestItem = ({
  layoutData,
  productName,
  productSubName,
  productBadge,
  productImg,
  productPurchase,
  isPurchaseOnSpot,
  questionData
}) => {
  const [isExpandQuestionArea, setIsExpandQuestionArea] = useState(false);

  const onExpandQuestionArea = () => {
    setIsExpandQuestionArea(!isExpandQuestionArea);
  };

  return (
    <div className='product-interest__item'>
      <div className='product-interest__item__container'>
        <div className='product-interest__item__info'>
          <div className='product-interest__item__info__left'>
            <div className='product-interest__item__image'>
              <img src={productImg} alt='' />
            </div>
          </div>
          <div className='product-interest__item__info-right'>
            <div className='product-interest__item__title'>
              <h5 className='product-interest__item__title__name'>{productName}</h5>
              {productSubName && <h5 className='product-interest__item__title__name'>{productSubName}</h5>}
            </div>
            <div className='product-interest__item__badge'>
              {productBadge &&
                productBadge.map(
                  (val, index) =>
                    'Answer' in val && (
                      <span key={index} className='product-interest__item__badge__item'>
                        {val?.Answer?.includes(ANSWER_WITH_BRACKET_TYPE)
                          ? val?.Answer?.replaceAll(ANSWER_WITH_BRACKET_TYPE, '')?.trim()
                          : val?.Answer}
                      </span>
                    )
                )}
            </div>
            <div className='product-interest__item__captured__date'>
              <p className='product-interest__item__captured__date__text'>
                <Text field={layoutData['Captured On Text']} /> {productPurchase}
              </p>
            </div>
          </div>
        </div>
        <div className='product-interest__item__question'>
          {isExpandQuestionArea && (
            <div className='product-interest__item__question__container'>
              <ProductInterestItemQuestion
                isPurchaseOnSpot={isPurchaseOnSpot}
                questionData={questionData}
                purchaseOnTheSpotLabel={layoutData['Purchase On The Spot Label']}
              />
            </div>
          )}
          <div className='product-interest__item__question__navigate'>
            <i
              className={`icon-chevron-down-big ${isExpandQuestionArea && 'product-interest__item__question__navigate__active'}`}
              onClick={onExpandQuestionArea}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProductInterestItem.propTypes = {
  layoutData: any,
  productImg: string,
  productName: string,
  productSubName: string,
  productBadge: any,
  productPurchase: string,
  isPurchaseOnSpot: bool,
  questionData: any
};

export default ProductInterestItem;
