import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import InstagramAuthorization from '@components/Share/InstagramAuthorization';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import SignleSignOnModel from '../models/SignleSignOnModel';

const InstagramSSO = ({fields, isLogin}) => {
  const [inputData, setInputData] = useState('');

  useEffect(() => {
    setInputData(new SignleSignOnModel().getData(fields || {}));
  }, []);

  //INFO: add 'className' to custom styles for button InstagramAuthorization'. Props 'state' use(optional) to nagative link you want if login success;
  return inputData && !isLogin ? (
    <div className='sigle-sign-on-instagram'>
      <InstagramAuthorization
        appID={inputData['App ID'].value}
        redirectUri={inputData['Redirect Uri'].value}
        state={window.location.href}
      >
        <Text field={inputData['Login Label']}/>  
      </InstagramAuthorization>
    </div>
  ) : '';
};

InstagramSSO.propTypes = {
  fields: PropTypes.object,
  isLogin: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  dataUser: state.singleSignOnReducer.userInfoReducer?.dataUser || [],
  isLogin: state.singleSignOnReducer.userInfoReducer?.isLogin ?? false,
});

export default connect(mapStateToProps)(InstagramSSO);
