import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Button Label', 'Description 1', 'Description 2', 'Description 3', 'Display Product Name', 'Icon Product', 'Title'];
const LINK_ARR = ['Button View Cart'];

export default class AbandonedCartWidgetModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
