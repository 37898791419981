import SitecoreImageModel from '@models/SitecoreImageModel';
import { deepCopy } from '@utils/utility';

import AccordionBackgroundColor from './AccordionBackgroundColor';
import TwoColumnImageWithAccodionItemModel from './TwoColumnImageWithAccodionItemModel';

export default class TwoColumnImageWithAccodionModel {
  constructor() {
    this['Background Color'] = new AccordionBackgroundColor();

    this['Image'] = new SitecoreImageModel();

    this['items'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Color')) {
      this['Background Color'].getData(dataSource['Background Color']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let newItemsList = [];

      dataSource['items'].map((element) => {
        newItemsList.push(new TwoColumnImageWithAccodionItemModel().getData(deepCopy(element) || {}));
      });

      this['items'] = newItemsList;
    }

    return this;
  }
}
