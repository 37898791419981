import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/productReview';
import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getBundleElementEpic = (action$) =>
  action$.pipe(
    ofType(productReviewTypes.GET_BUNDLE_ELEMENT),
    switchMap((action) => {
      const params = {
        bundlePageId: action.payload.bundlePageId,
      };

      return getAsObservable(config.ACTION_GET_BUNDLE_ELEMENT, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.GetBundleElementSuccess(deepCopy(res.data));
          } else {
            return actions.GetBundleElementFailed('Failed');
          }
        })
      );
    })
  );
