import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect,useDispatch } from 'react-redux';

import { unsubscribe } from '@redux/actions/unsubscribe';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import Global from '@utils/global';

const Unsubscribe = ({isLoading, isUnsubscribe}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(
      unsubscribe({
        email: Global.getQueryParams(window.location.href, 'email'),
        listId: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Unsubscribe From Contact List']?.['id']
      })
    );
  }, []);

  return  (
    <div className='container'>{ !isLoading 
      ? isUnsubscribe 
        ? <span>Unsubscribe Successful</span> 
        : <span>Unsubscribe Failed</span>
      : 'Loading'}
    </div>
  );
};

function mapStateToProps(state) {
  return ({
    isUnsubscribe: state.unsubscribeReducer.isUnsubscribe,
    isLoading: state.unsubscribeReducer.isLoading,
  });
}

Unsubscribe.propTypes = {
  isUnsubscribe: PropTypes.bool,
  isLoading: PropTypes.bool
};

export default connect(mapStateToProps)(Unsubscribe);
