import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import Message from '@components/Share/Message';
import { loginSuccess } from '@redux/actions/singleSignOn';
import Global from '@utils/global';

const Authentication = ({fields}) => {
  const dispatch = useDispatch();
  const [isErrorLogin, setErrorLogin] = useState(false);

  useEffect(() => {
    if (fields?.LoginStatus) {
      dispatch(loginSuccess(fields?.CommerceUser));

      // INFO: Back again previous page 
      window.location.href = decodeURIComponent(Global.getQueryParams(window.location.href, 'state'));
      
    } else {
      setErrorLogin(true);
    }
  }, []);

  return isErrorLogin ?  (
    <div className='authentication-loading'>
      <Message messageCode='Msg_005'/>
    </div>
  ) : 'Loading...';
};

Authentication.propTypes = {
  fields: PropTypes.object,
  isLogin: PropTypes.bool,
};

export default Authentication;
