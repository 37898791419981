import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CPHeaderIconModel from '../../Models/CPHeaderIconModel';
import CPHeaderNotiIcon from '../CPHeaderNotiIcon';

const NOTIFICATIONS = 'Notification';

const CPHeaderIcon = ({ data }) => {
  const [cPHeaderIconFields, setCPHeaderIconFields] = useState(null);

  useEffect(() => {
    const cPHeaderIconModel = new CPHeaderIconModel();

    setCPHeaderIconFields(cPHeaderIconModel.getData(deepCopy(data.fields || {})));
  }, []);

  return (
    cPHeaderIconFields &&
    (data.name === NOTIFICATIONS ? (
      <CPHeaderNotiIcon cPHeaderIconFields={cPHeaderIconFields} />
    ) : (
      <li className='head-icon'>
        {cPHeaderIconFields.Link.value.href ? (
          <Link field={cPHeaderIconFields.Link}>
            <Image field={cPHeaderIconFields.Image} />
          </Link>
        ) : (
          <span>
            <Image field={cPHeaderIconFields.Image} />
          </span>
        )}
      </li>
    ))
  );
};

CPHeaderIcon.propTypes = {
  data: Proptypes.object
};

export default CPHeaderIcon;
