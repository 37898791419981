import { useCallback } from 'react';

import useRemoveDuplicateFunction from './useRemoveDuplicateFunction';

const useSortListProductFunction = () => {
  const handleRemoveDuplicateFunction = useRemoveDuplicateFunction();

  const handleMergeListProductFunction = useCallback((compareData) => {
    const listProductFunctionMerged = {};
    // INFO: merge all product function data into one array
    for (const compareDataItem of compareData) {
      const productInfoDetail = compareDataItem?.productInfoDetail;
      if (productInfoDetail) {
        const listProductFunction = productInfoDetail?.ListProductFunction || [];
        if (listProductFunction.length) {
          for (const productFunctionItem of listProductFunction) {
            const checkFunctionExist = listProductFunctionMerged?.[productFunctionItem.Id];
            if (!checkFunctionExist?.length) {
              listProductFunctionMerged[productFunctionItem.Id] = [...productFunctionItem.Functions];
            } else {
              listProductFunctionMerged[productFunctionItem.Id] = [
                ...listProductFunctionMerged[productFunctionItem.Id],
                ...productFunctionItem.Functions
              ];
            }
          }
        }
      }
    }

    return listProductFunctionMerged;
  }, []);

  const handleGenerateUniqueListProductFunction = useCallback((compareData) => {
    const mergedListProductFunction = handleMergeListProductFunction(compareData);
    for (const key of Object.keys(mergedListProductFunction)) {
      mergedListProductFunction[key] = [...handleRemoveDuplicateFunction(mergedListProductFunction[key])];
    }

    return mergedListProductFunction;
  }, []);

  return useCallback((compareData, listProductFunction) => {
    const uniqueProductListFunction = handleGenerateUniqueListProductFunction(compareData);
    const functionSortedData = [];
    if (listProductFunction?.length) {
      for (const listProductFunctionItem of listProductFunction) {
        const functionUnique = uniqueProductListFunction[listProductFunctionItem.Id];
        const functionDataTemp = [];

        for (const functionItem of listProductFunctionItem.Functions) {
          const indexOfFunctionItemInUniqueList = functionUnique.findIndex((item) => item.Id === functionItem.Id);

          functionDataTemp[indexOfFunctionItemInUniqueList] = { ...functionItem };
        }

        functionSortedData.push({
          ...listProductFunctionItem,
          Functions: [...functionDataTemp]
        });
      }
    }

    return functionSortedData;
  }, []);
};

export default useSortListProductFunction;
