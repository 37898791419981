import PropsType from 'prop-types';
import React, { useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import useCompareProduct from '../../hooks/useCompareProduct';
import { handleGenerateFunctionItemLabel } from '../../utils';

export const PRODUCT_FUNCTION_ID = 'compare-product-function';

export const DIMENSION_FUNCTION_KEY = 'Dimension';

export const MODEL_SPECIFICATION_FUNCTION_KEY = 'ModelSpecification';

export const WEIGHT_FUNCTION_KEY = 'Weight';

const Layout = ({ children, functionLabel, isVisibleLabel, detailElAriaLabel = '' }) => {
  const { layoutData } = useCompareProduct();

  const generalFunctionLabel = useMemo(() => {
    return {
      [DIMENSION_FUNCTION_KEY]: layoutData?.['Dimension Label'],
      [MODEL_SPECIFICATION_FUNCTION_KEY]: layoutData?.['Model Specification Label'],
      [WEIGHT_FUNCTION_KEY]: layoutData?.['Weight Label']
    };
  }, [layoutData]);

  return (
    <div id={PRODUCT_FUNCTION_ID} className='product-function'>
      <div className='product-function__label'>
        <h6 className={`label ${isVisibleLabel ? 'visible' : ''}`}>
          {generalFunctionLabel?.[functionLabel] ? (
            <Text field={generalFunctionLabel[functionLabel]} />
          ) : (
            handleGenerateFunctionItemLabel(functionLabel)
          )}
        </h6>
        {isVisibleLabel && <div className='divider'></div>}
      </div>
      <div aria-label={detailElAriaLabel} className='product-function__detail'>
        <div className='product-function__detail-container'>{children}</div>
      </div>
    </div>
  );
};

Layout.propTypes = {
  children: PropsType.any,
  functionLabel: PropsType.string,
  isVisibleLabel: PropsType.bool,
  detailElAriaLabel: PropsType.string
};

export default Layout;
