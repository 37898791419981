import { useCallback } from 'react';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getAsObservable } from '@services/genericService';

export const useUpdateSOCartViewedStatus = () => {
  return useCallback(
    async (cartName) =>
      await new Promise((resolve, reject) => {
        const UPDATE_SO_VIEWED_STATUS_ENDPOINT = '/OsimCart/ChangeSOCartStatusToViewed';

        return from(getAsObservable(UPDATE_SO_VIEWED_STATUS_ENDPOINT, { cartName }))
          .pipe(
            map((res) => {
              if (res.status === 200) return resolve(true);
            }),
            catchError((error) => reject(error || 'Failed'))
          )
          .subscribe();
      }),
    []
  );
};
