import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/cpShareProduct';
import * as cpShareProductTypes from '@redux/actions/cpShareProduct/cpShareProductTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';

const cpShareProductEpic = (action$) =>
  action$.pipe(
    ofType(cpShareProductTypes.CP_SHARE_PRODUCT_DETAIL),
    switchMap((action) => {
      return postAsObservable(config.ACTION_CP_SHARE_PRODUCT_DETAIL, action.payload).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.cpShareProductDetailSuccess(res.data);
          } else {
            return actions.cpShareProductDetailFailed('Failed');
          }
        })
      );
    })
  );

export default cpShareProductEpic;
