import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import SubNavigationItemFieldsModel from '../Model/SubNavigationItemFieldsModel';

const PADDING_TOP = 114;
const INDEX_TEXT = 0;

const SubNavigationItem = ({data}) => {
  const [subNavigationItemFields, setSubNavigationItemFields] = useState(null);

  useEffect(() => {
    const subNavigationItemFieldsModel = new SubNavigationItemFieldsModel();
    
    setSubNavigationItemFields(subNavigationItemFieldsModel.getData(deepCopy(data.fields || {})));

  }, []);

  const isIdTarget = (id) => {
    let checkIdTarget = id?.charAt(INDEX_TEXT);

    return checkIdTarget === '#';
  };

  const getHashUrl = (item) => {
    return item?.Link?.value?.href || '';
  };

  const handleScrollTargert = (target) => {
    if (target) {
      let targetComponent = document.querySelector(target);
      let offsetTop = targetComponent?.offsetTop - PADDING_TOP;
  
      window.scroll({ top: offsetTop, left: 0, behavior: 'smooth' });
    }
  };

  useEffect(() => {
    setTimeout(() => {
      if (subNavigationItemFields) {
        let windowHash = window.location.hash || '';
        if (windowHash == getHashUrl(subNavigationItemFields)) {
          handleScrollTargert(getHashUrl(subNavigationItemFields));
        }
      }
    }, 500);
    
  }, [subNavigationItemFields]);

  return subNavigationItemFields ? (
    <Fragment>
      {
        isIdTarget(subNavigationItemFields.Link.value.href) ? (
          <li className='sub-navigation__nav-tabs__nav-item animated-slow fadeIn delay-100' onClick={() => handleScrollTargert(subNavigationItemFields.Link.value.href)}>
            <Text field={subNavigationItemFields.Title} />
          </li>
        ) : (
          <li className='sub-navigation__nav-tabs__nav-item animated-slow fadeIn delay-100'>
            <Link field={subNavigationItemFields.Link}>
              <Text field={subNavigationItemFields.Title} />
            </Link>
          </li>
        )
      }
    </Fragment>
  )
    : <LoadingShimmer itemNumb={1}>
      <li className='sub-navigation__nav-tabs__nav-item skeleton__item'>
        <span className='text-line'></span>
      </li>
    </LoadingShimmer>;
};

SubNavigationItem.propTypes = {
  data: PropTypes.object
};

export default SubNavigationItem;
