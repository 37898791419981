import './SpinAndWinPopUp.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import { clearDataInputSpin, getAvailableLuckyDraw } from '@redux/actions/spinAndWin';
import { removeCurlyBrackets } from '@redux/epic/bookingExperience/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import SpinAndWinPopupModels from '../Models/SpinAndWinPopupModels';
import SpinAndWinComponent from '../SpinAndWinComponent';

const SpinAndWinPopUp = ({ fields }) => {
  const dispatch = useDispatch();
  const luckyDraw = useSelector((state) => state.spinAndWinReducer?.getAvailableLuckyDrawReducer?.luckyDrawData);
  const isLoading = useSelector((state) => state.spinAndWinReducer?.getAvailableLuckyDrawReducer?.isLoading);
  const [isShowSpinPopup, setIsShowSpinPopup] = useState(true);
  const [spinAndWinPopupFields, setSpinAndWinPopupFields] = useState(null);
  const [isHiddenSpinPopUp, setIsHiddenSpinPopUp] = useState(false);

  const luckyDrawId = useMemo(() => {
    const luckyDrawId = spinAndWinPopupFields?.['LuckyDrawID']?.fields?.LuckyDrawID?.value;

    return luckyDrawId ? removeCurlyBrackets(luckyDrawId) : '';
  }, [spinAndWinPopupFields]);

  useEffect(() => {
    const spinAndWinPopupModel = new SpinAndWinPopupModels();

    setSpinAndWinPopupFields(spinAndWinPopupModel.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    if (luckyDrawId) {
      dispatch(
        getAvailableLuckyDraw({
          luckyDrawId: luckyDrawId
        })
      );
    }
  }, [luckyDrawId]);

  const quantityCheck = (arr) => {
    return arr.every((item) => item.Quantity == 0);
  };

  useDidUpdateEffect(() => {
    if (luckyDraw) {
      if (luckyDraw.Id) {
        if (quantityCheck(luckyDraw.Slices)) {
          setIsHiddenSpinPopUp(true);
        } else {
          setIsHiddenSpinPopUp(false);
        }
      } else {
        setIsHiddenSpinPopUp(true);
      }
    } else {
      setIsHiddenSpinPopUp(true);
    }
  }, [luckyDraw]);

  // const classRenderMobile = (data) => {
  //   return LIST_CLASS_NAME__MOBILE[data] || '';
  // };
  //
  // const classRenderDesktop = (data) => {
  //   return LIST_CLASS_NAME__DESKTOP[data] || '';
  // };

  const handleShowPopupAndClearData = () => {
    setIsShowSpinPopup(false);

    dispatch(clearDataInputSpin());

    window.sessionStorage.removeItem('emailLogin');
  };

  return (
    spinAndWinPopupFields &&
    (isLoading ? (
      <LoadingMask />
    ) : isHiddenSpinPopUp ? (
      <Text tag='h2' className='spin-error-lucky-draw text-center' field={spinAndWinPopupFields['Error Lucky Draw']} />
    ) : luckyDraw ? (
      <>
        {/*<div className='main-banner spin-and-win-popup animated-slow fadeIn delay-100'>*/}
        {/*  <div className='main-banner__background'>*/}
        {/*    <Image media={spinAndWinPopupFields['Banner Background Image']} className='d-desktop' />*/}
        {/*    <Image media={spinAndWinPopupFields['Mobile Banner Background Image']} className='d-mobi' />*/}
        {/*  </div>*/}
        {/*  <div className='main-banner__content' style={{ color: spinAndWinPopupFields['Text Color'].value || '#2a2a2a' }}>*/}
        {/*    <div*/}
        {/*      className={*/}
        {/*        'container main-banner__content__container content-position ' +*/}
        {/*        classRenderMobile(spinAndWinPopupFields['Mobile Text Position'].value) +*/}
        {/*        ' ' +*/}
        {/*        classRenderDesktop(spinAndWinPopupFields['Desktop Text Position'].value)*/}
        {/*      }*/}
        {/*    >*/}
        {/*      <div className='main-banner__content__wrap content-wrapper'>*/}
        {/*        <Text tag='h2' field={spinAndWinPopupFields['Banner Title Text']} className='main-banner__content__title' />*/}
        {/*        <RichText className='main-banner__content__desc' field={spinAndWinPopupFields['Banner Description']} />*/}
        {/*        <div className='main-banner__content__group-button'>*/}
        {/*          {spinAndWinPopupFields['Banner Title Text'].value && (*/}
        {/*            <div className='main-banner__content__btn'>*/}
        {/*              <button type='button' className='btn btn-primary' onClick={() => setIsShowSpinPopup(true)}>*/}
        {/*                <Text field={spinAndWinPopupFields['CTA Button Text']} />*/}
        {/*              </button>*/}
        {/*            </div>*/}
        {/*          )}*/}
        {/*          {spinAndWinPopupFields['CTA Button 2 Text'].value && (*/}
        {/*            <Link field={spinAndWinPopupFields['CTA Button 2 Link']} className='btn-link'>*/}
        {/*              <Text field={spinAndWinPopupFields['CTA Button 2 Text']}></Text>*/}
        {/*            </Link>*/}
        {/*          )}*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        <ModalComponent isShow={isShowSpinPopup} cssClass={'spin-and-win-popup'} onCloseHandler={handleShowPopupAndClearData}>
          <SpinAndWinComponent fields={fields} isSpinAndWinPopUp />
        </ModalComponent>
      </>
    ) : (
      <></>
    ))
  );
};

SpinAndWinPopUp.propTypes = {
  fields: PropTypes.any
};

export default SpinAndWinPopUp;
