import IsAuthenticatedModel from '@components/Share/FooterNavigation/model/IsAuthenticatedModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ItemsFieldsModel {
  constructor() {
    this.Link = new SitecoreLinkModel();

    this.Title = new SitecoreTextModel();

    this['Is Authenticated'] = new IsAuthenticatedModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this.Link.getData(dataSource.Link);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Authenticated')) {
      this['Is Authenticated'].getData(dataSource['Is Authenticated']);
    }

    return this;
  }
}
