import React from 'react';

const ViewMyPrizesButtonShimmer = () => {
  return (
    <div className='view-my-prizes skeleton__item'>
      <div className='view-my-prizes__loading'>
        <span className='text-line-20-center' />
      </div>
    </div>
  );
};

export default ViewMyPrizesButtonShimmer;