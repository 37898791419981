import { bool, func, object, string } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import VerticalTabExtraLinkItem from '../MainNavigationSubMenu/VerticalTabLayout/VerticalTabExtraLinkItem';
import MainNavigationItemsModel from '../Model/MainNavigationItemsModel';

const MainNavigationSubItem = ({ contentData, isLogin, loginLink, checklabel, checkShowSubMenu, type }) => {
  const name = SitecoreContextFactoryService.getValueContextRouteItem('name');
  const [data, setData] = useState(null);
  const [isShowSubMenuNav, setIsShowSubMenuNav] = useState(false);
  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const navigationItemsModel = new MainNavigationItemsModel();

    setData(navigationItemsModel.getData(deepCopy(contentData || {})));
  }, []);

  useEffect(() => {
    if (checklabel === contentData.Title.value) {
      setIsShowSubMenuNav(true);
    } else {
      setIsShowSubMenuNav(false);
    }
  }, [checklabel]);

  const handleShowSubMenu = (title) => {
    setIsShowSubMenuNav(!isShowSubMenuNav);

    checkShowSubMenu(title);
  };

  //New Logic ON/OFF GA, GA4
  const handleGA4Event = () => {
    if (isGA4) {
      Global.handleGA4Navigation({
        labelItem: data.Title.value,
        pageTitle: name,
        labelCategory: data.Title.value,
        pageUrl: data.Link.value.href
      });
    }
  };

  return (
    data && (
      <>
        {data.items.length ? (
          <div className={`${isShowSubMenuNav ? 'first-nav-item show-sub-menu-nav' : 'first-nav-item'}`}>
            <Text field={data.Title} tag='h2' className='arrow-icon-next first-nav-item__title' onClick={() => handleShowSubMenu(data.Title.value)}></Text>
            <div className='header__sub-menu-nav header__sub-menu-nav--extra-links'>
              <div className='header__sub-menu-nav__inner'>
                <Text
                  field={data.Title}
                  tag='h2'
                  className='arrow-icon-prev first-nav-item__title'
                  onClick={() => handleShowSubMenu(data.Title.value)}
                ></Text>
                <ul className='row header__sub-menu-nav__contents'>
                  {data.items.map((item, index) => (
                    <VerticalTabExtraLinkItem type={type} contentLabel={data.Title.value} contentData={item} key={index} />
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ) : data.Title?.value ? (
          <div className='first-nav-item'>
            <Link field={data.Link} onClick={handleGA4Event}>
              <Text field={data.Title} tag='h2' className='first-nav-item__title' />
            </Link>
          </div>
        ) : (
          <div className='first-nav-item'>
            <Link className='main-navigation__item__link' field={data.Link} />
          </div>
        )}
        {/* {!isLogin && data['Is Authenticated'].value ? (
          contentData.items.length ? (
            <></>
          ) : data.Title?.value ? (
            <Link field={loginLink}>
              <Text field={data.Title} tag='h2' className='first-nav-item__title' />
            </Link>
          ) : (
            <Link className='main-navigation__item__link' field={loginLink} />
          )
        ) : (
          <></>
        )} */}
      </>
    )
  );
};

MainNavigationSubItem.propTypes = {
  contentData: object,
  isLogin: bool,
  loginLink: object,
  checklabel: string,
  checkShowSubMenu: func,
  type: string
};

const mapStateToProps = (state) => ({
  loginLink: state.unsubscribeReducer.loginLink || {},
  isLogin: state.singleSignOnReducer?.userInfoReducer?.isLogin || false
});

export default connect(mapStateToProps)(MainNavigationSubItem);
