import { object } from 'prop-types';
import React from 'react';

import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const ProductAccessoriesHeader = ({ layoutData }) => {
  return (
    <div className='product-accessories__content-header'>
      <div className='product-accessories__content-header__icon'>
        <div className='product-accessories__content-header__icon-wrapper'>
          <Image field={layoutData?.Image} />
        </div>
      </div>
      <div className='product-accessories__content-header__text'>
        <Text tag='h3' field={layoutData?.Title} className='support-centre__title' />
        <RichText tag='h5' field={layoutData?.Description} className='support-centre__description' />
      </div>
    </div>
  );
};

ProductAccessoriesHeader.propTypes = {
  layoutData: object
};

export default ProductAccessoriesHeader;
