export const GET_ALL_MY_ORDER = 'GET_ALL_MY_ORDER';

export const GET_ALL_MY_ORDER_SUCCESS = 'GET_ALL_MY_ORDER_SUCCESS';

export const GET_ALL_MY_ORDER_FAILED = 'GET_ALL_MY_ORDER_FAILED';

export const GET_ORDER_BY_ID = 'GET_ORDER_BY_ID';

export const GET_ORDER_BY_ID_SUCCESS = 'GET_ORDER_BY_ID_SUCCESS';

export const GET_ORDER_BY_ID_FAILED = 'GET_ORDER_BY_ID_FAILED';

export const GET_BALANCE_BY_ID = 'GET_BALANCE_BY_ID';

export const GET_BALANCE_BY_ID_SUCCESS = 'GET_BALANCE_BY_ID_SUCCESS';

export const GET_BALANCE_BY_ID_FAILED = 'GET_BALANCE_BY_ID_FAILED';

export const REMOVE_BALANCE_CART = 'REMOVE_BALANCE_CART';

export const REMOVE_BALANCE_CART_SUCCESS = 'REMOVE_BALANCE_CART_SUCCESS';

export const REMOVE_BALANCE_CART_FAILED = 'REMOVE_BALANCE_CART_FAILED';

export const GET_ACTIVATE_ORDERS = 'GET_ACTIVATE_ORDERS';

export const GET_ACTIVATE_ORDERS_SUCCESS = 'GET_ACTIVATE_ORDERS_SUCCESS';

export const GET_ACTIVATE_ORDERS_FAILED = 'GET_ACTIVATE_ORDERS_FAILED';

export const GET_PAST_ORDERS = 'GET_PAST_ORDERS';

export const GET_PAST_ORDERS_SUCCESS = 'GET_PAST_ORDERS_SUCCESS';

export const GET_PAST_ORDERS_FAILED = 'GET_PAST_ORDERS_FAILED';

export const GET_LASTEST_ORDERS = 'GET_LASTEST_ORDERS';

export const GET_LASTEST_ORDERS_SUCCESS = 'GET_LASTEST_ORDERS_SUCCESS';

export const GET_LASTEST_ORDERS_FAILED = 'GET_LASTEST_ORDERS_FAILED';