import { useFormik } from 'formik';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Yup } from '@components/SingleSignOn/RegisterLogin/Register/utils';
import { getUserFullNameWithoutFormat } from '@components/SlicingPage/MyAccount/utils';
import { updateBookingExperienceStart } from '@redux/actions/bookingExperience/updateBookingExperience';
import { checkCommerceUser } from '@redux/actions/checkoutProcess';
import global from '@utils/global';

import { checkInitialValuesEmpty } from '../../../helpers';
import { useGetScheduleOtpCode } from '../../../hooks/useGetScheduleOtpCode';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { useGenerateBookExperienceParams, useSetScheduleData } from './hooks';

export function useContactDetailFormik() {
  const dispatch = useDispatch();
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const accountUserInfo = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.accountUser);
  // const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const { scheduleData, layoutData } = useScheduleAppointmentContext();
  const setScheduleDataService = useSetScheduleData();
  const getOtpService = useGetScheduleOtpCode();
  const generateBookExperienceParams = useGenerateBookExperienceParams();

  const useAreaCode = useMemo(() => {
    return settingGlobal?.UseAreaCode;
  }, [settingGlobal]);

  const areaCodes = useMemo(() => {
    return settingGlobal?.AreaCodes || [];
  }, [settingGlobal]);

  const mobileNumberConvert = useMemo(() => {
    let defaultValue = scheduleData.contactDetail.mobileNumber || accountUserInfo?.PhoneNumber || '';
    const defaultPrefixCode = useAreaCode && areaCodes.length ? areaCodes[0].value : '';
    const phoneFormat = global.splitPhoneNumberWithoutCode(useAreaCode, defaultValue, areaCodes);

    return {
      prefix: phoneFormat.code || defaultPrefixCode,
      mobileNumber: phoneFormat.value
    };
  }, [scheduleData, accountUserInfo, areaCodes, useAreaCode]);

  const initialValues = useMemo(() => {
    const isScheduleContactDetailEmpty = checkInitialValuesEmpty(scheduleData)?.length;

    const firstName = !isScheduleContactDetailEmpty
      ? scheduleData.contactDetail.firstName || ''
      : accountUserInfo?.FirstName || '';

    const lastName = !isScheduleContactDetailEmpty
      ? scheduleData.contactDetail.lastName || ''
      : accountUserInfo?.LastName || '';

    return {
      firstName: getUserFullNameWithoutFormat({ firstName, lastName }),
      // lastName: scheduleData.contactDetail.lastName || accountUserInfo?.LastName || '',
      emailAddress: scheduleData.contactDetail.emailAddress || accountUserInfo?.Email || '',
      dob: scheduleData.contactDetail.dob || accountUserInfo?.DoB || '',
      phonePrefix: scheduleData.contactDetail.phonePrefix || mobileNumberConvert.prefix || '',
      mobileNumber: scheduleData.contactDetail.mobileNumber || mobileNumberConvert.mobileNumber || '',
      agreePolicy: scheduleData.policyAndTermCheckbox.agreePolicy || true,
      acceptPDDA: scheduleData.policyAndTermCheckbox.acceptPDDA
    };
  }, [scheduleData, accountUserInfo, mobileNumberConvert, useAreaCode, areaCodes]);

  return useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .required(layoutData?.['Input Valid FirstName Message']?.value)
        .matches(global.nameValidateRegex(), layoutData?.['Input Valid FirstName Message']?.value)
        .trim(),
      // lastName: Yup.string()
      //   .required(layoutData?.['Input Valid LastName Message']?.value)
      //   .matches(global.nameValidateRegex(), layoutData?.['Input Valid LastName Message']?.value)
      //   .trim(),
      emailAddress: Yup.string()
        .notRequired()
        .test('email_check', layoutData?.['Input Valid Email Address Message']?.value, (value) => {
          if (value) {
            return Yup.string().email().isValidSync(value);
          }

          return true;
        }),
      dob: Yup.string()
        .notRequired()
        .test('date_check', layoutData?.['Input Valid Birth Date Message']?.value, (value) => {
          if (value) {
            return Yup.date().isValidSync(value);
          }

          return true;
        }),
      mobileNumber: Yup.string().when('check_mobile_number', () => {
        return !layoutData?.appointment?.MobileNumber
          ? Yup.string()
            .required(layoutData?.['Input Valid Mobile Number Message']?.value)
            .trim()
            .validatePhone({
              min: settingGlobal?.PhoneNumberMinimumMust || settingGlobal?.PhoneNumberMust,
              max: settingGlobal?.PhoneNumberMust,
              internalPrefixKey: 'phonePrefix',
              errorMsg: layoutData?.['Input Valid Mobile Number Message']?.value
            })
          : Yup.string()
            .notRequired()
            .validatePhone({
              min: settingGlobal?.PhoneNumberMinimumMust || settingGlobal?.PhoneNumberMust,
              max: settingGlobal?.PhoneNumberMust,
              internalPrefixKey: 'phonePrefix',
              errorMsg: layoutData?.['Input Valid Mobile Number Message']?.value
            });
      }),
      agreePolicy: Yup.bool().oneOf([true], layoutData?.['Agree Terms And Conditions Message']?.value)
    }),
    onSubmit: (values) => {
      // INFO: set schedule data state
      setScheduleDataService(values);

      const mobilePhoneValue = `${values.phonePrefix}${values.mobileNumber}`;

      if (!layoutData?.appointment) {
        // INFO: start check email exist (if user not login) or get otp
        if (!accountUserInfo && values.emailAddress) {
          dispatch(checkCommerceUser(values.emailAddress));
        } else {
          getOtpService(mobilePhoneValue);
        }
      } else {
        const appointmentId = layoutData?.appointment?.Id;

        // const contactData = {
        //   firstName: layoutData?.contact?.FirstName,
        //   lastName: layoutData?.contact?.LastName,
        //   mobileNumber: layoutData?.contact?.MobileNumber,
        //   emailAddress: layoutData?.contact?.EmailAddress,
        //   dob: layoutData?.contact?.DateOfBirth
        // };

        const contactDetailParams = {
          firstName: values.firstName,
          // lastName: values.lastName,
          mobileNumber: mobilePhoneValue,
          emailAddress: values.emailAddress,
          dob: values.dob
        };

        const params = generateBookExperienceParams(contactDetailParams);
        if (appointmentId) params.appointmentId = appointmentId;

        // if (Object.keys(contactDetailParams).some((key) => contactDetailParams[key] !== contactData[key])) {
        //   const updateContactParams = {
        //     contactData: {
        //       ...contactDetailParams,
        //       contactId: layoutData?.contact?.Id,
        //       allowSMS: layoutData?.contact?.AllowSMS,
        //       allowEmail: layoutData?.contact?.AllowEmail,
        //       allowDirectMail: layoutData?.contact?.AllowDirectMail
        //     },
        //     updateBookingData: { ...params }
        //   };
        //
        //   dispatch(updateBookingExperienceContactStart(updateContactParams));
        // } else {
        dispatch(updateBookingExperienceStart(params));
        // }
      }
    }
  });
}
