import './FilterPopup.scss';

import Proptypes from 'prop-types';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import FilterByFeature from '@components/CategoryLanding/FilterPopup/FilterByFeature';
import FilterByPrice from '@components/CategoryLanding/FilterPopup/FilterByPrice';
import FilterProductByColor from '@components/CategoryLanding/FilterPopup/FilterProductByColor';
import * as config from '@components/CategoryLanding/ProductListing/config';
import SortOptionModel from '@components/CategoryLanding/ProductListing/model/SortOptionModel';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { getHelpMeDecide, setRangePricePosition } from '@redux/actions/helpMeDecide';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import { PARAMS_SUBMIT } from '../config';
import FeaturesAndFunctionNew from './FeaturesAndFunctionNew/FeaturesAndFunctionNew';
import FilterByTradeInAvailable from './FilterByTradeInAvailable/FilterByTradeInAvailable';

const FilterPopup = (props) => {
  const { dataSources, dataFromAPI, sPageURL, params, setParams, setReset, reset } = props;
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [areasOfConcern, setAreasOfConcern] = useState({});
  const [isToogleClearFilter, setToogleClearFilter] = useState(false);
  const filterLastest = useSelector((state) => state.helpMeDecideReducer.filterLastest);
  const rangePosition = useSelector((state) => state.helpMeDecideReducer.rangePosition);

  const isGA4 = Global.getIsGA4();

  const [facetSetting] = useState(dataSources['Search Settings']['Facet Fields']);

  const [filterObj, setFilterProduct] = useState({
    CategoryId: '',
    SortBy: dataSources['Search Settings']['Sort Options'][0],
    Price: dataFromAPI.Price,
    Features: [],
    Colors: [],
    AreasOfConcern: [],
    TradeInAvailable: false,
  });

  useEffect(() => {
    if (filterLastest) {
      setFilterProduct({
        ...filterLastest,
        SortBy: new SortOptionModel().getData(
          dataSources['Search Settings']['Sort Options'].find((item) => item.Value.value === filterLastest.SortBy)
        ), Colors: filterLastest.Colors,
        Features: filterLastest.Features,
        AreasOfConcern: filterLastest.areasOfConcern,
        TradeInAvailable: (filterLastest.TradeInAvailable === 'true'),
        positionCurrent: rangePosition,

      });
    }
  }, []);

  useEffect(() => {
    if (dataSources['Areas Of Concern'] && dataSources['Areas Of Concern']?.length > 0) {
      const newData = dataSources['Areas Of Concern']?.map((item) => {
        return {
          Value: item.Key,
          Name: item.Name,
        };
      });

      setAreasOfConcern({ Details: newData });
    }
  }, []);


  const onSelectFilterHandler = (params) => {
    setOpen(false);

    if (params.key === PARAMS_SUBMIT.SortBy) {
      setFilterProduct({
        ...filterObj,
        [params.key]: params.value
      });
    }
    if (params.key === PARAMS_SUBMIT.RangePrice) {
      setFilterProduct({
        ...filterObj,
        Price: {
          min: params.value[0],
          max: params.value[1],
        }
      });
    }
    if (params.key === PARAMS_SUBMIT.Colors) {
      setFilterProduct({
        ...filterObj,
        [params.key]: params.value
      });
    }
    if (params.key === PARAMS_SUBMIT.TradeInAvailable) {
      setFilterProduct({
        ...filterObj,
        [params.key]: !filterObj.TradeInAvailable
      });
    }
    if (params.key === PARAMS_SUBMIT.AreasOfConcern) {
      setFilterProduct({
        ...filterObj,
        [params.key]: params.value
      });
    }
    if (params.key === PARAMS_SUBMIT.Features) {
      setFilterProduct({
        ...filterObj,
        [params.key]: params.value
      });
    }
  };


  // INF: Google analytics
  const renderFacetSetting = (facetSetting) => {
    let category_select = [dataSources['Sort By Label'].value];

    facetSetting.forEach((item) => {
      category_select.push(item.Name.value);
    });

    return category_select;
  };

  // INF: Google analytics
  const filterColorNameByColor = (Colors) => {
    const dataColor = dataFromAPI.Facets.find((item) => item.Name === config.STEP_RANGE_COLOR);

    let arrColorName = [];

    Colors.forEach((item) => {
      const found = dataColor.Details.find(element => element.Value == item);

      arrColorName.push(found.Name);
    });

    return arrColorName;
  };

  //New Logic ON/OFF GA, GA4
  const filterByHandlerGA4 = () => {
    if (isGA4) {
      // window.dataLayer = window.dataLayer || [];

      const params = { event: 'filter_by' };

      const category_select = renderFacetSetting(facetSetting);

      const selected = Object.values({
        Term: filterObj.SortBy.Name,
        Price: [filterObj.Price?.min, filterObj.Price?.min + 1 === filterObj.Price?.max ? filterObj.Price?.min : filterObj.Price?.max],
        Colors: filterObj.Colors?.length ? filterColorNameByColor(filterObj.Colors) : '',
        Features: filterObj.Features?.length ? filterObj.Features : ''
      });

      category_select.forEach((value, index) => {
        params[`selected_category${index + 1}`] = value;

        params[`selected_item${index + 1}`] = selected[index];
      });

      params['page_type'] = SitecoreContextFactoryService.getValueContextRouteItem('name');

      // window.dataLayer.push(params);

      new GoogleAnalytic().gAnalytic4('event', 'filter_by', params);
    }
  };

  const sorByHandlerGA4 = (value) => {
    if (isGA4) {
      let params = {
        sort_by_click: value,
        page_type: SitecoreContextFactoryService.getValueContextRouteItem('name')
      };

      new GoogleAnalytic().gAnalytic4('event', 'sort_by', params);
    }
  };

  const convertParamsUrl = (idList, filterData) => {
    return `${idList}&${qs.stringify(filterData)}`;

  };

  function removeEmptyOrNullProperties(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value === null || value === '' || value === undefined || value === false) {
          delete obj[key];
        } else if (typeof value === 'object') {

          removeEmptyOrNullProperties(value);
        }
      }
    }

    return obj;
  }


  const onApplyFilterHandler = () => {
    setToogleClearFilter(false);

    dispatch(setRangePricePosition(filterObj.positionCurrent));

    const paramUrl = convertParamsUrl(sPageURL, removeEmptyOrNullProperties({
      SortBy: filterObj.SortBy?.Value.value,
      PriceFrom: filterObj.Price?.min,
      TradeInAvailable: filterObj.TradeInAvailable,
      areasOfConcern: filterObj.AreasOfConcern?.length ? filterObj.AreasOfConcern : '',
      PriceTo: filterObj.Price?.min + 1 === filterObj.Price?.max ? filterObj.Price?.min + 1 : filterObj.Price?.max,
      Features: filterObj.Features?.length ? filterObj.Features : '',
      Colors: filterObj.Colors?.length ? filterObj.Colors : '',
      page: 1,
      pageSize: params.pageSize
    }))
      ;

    setParams({
      ...params,
      SortBy: filterObj.SortBy?.Value.value,
      PriceFrom: filterObj.Price?.min,
      TradeInAvailable: filterObj.TradeInAvailable,
      areasOfConcern: filterObj.AreasOfConcern?.length ? filterObj.AreasOfConcern : '',
      PriceTo: filterObj.Price?.min + 1 === filterObj.Price?.max ? filterObj.Price?.min : filterObj.Price?.max,
      Features: filterObj.Features?.length ? filterObj.Features : '',
      Colors: filterObj.Colors?.length ? filterObj.Colors : '',
      page: 1,
    });

    setReset({ pagingReset: !reset.pagingReset });

    dispatch(getHelpMeDecide({ params: paramUrl, isFilter: true }));

    filterByHandlerGA4();

    props.appplyFilterEvt();
  };

  const onClearFilterHandler = () => {
    setToogleClearFilter(!isToogleClearFilter);

    setFilterProduct({
      ...filterObj,
      SortBy: dataSources['Search Settings']['Sort Options'][0],
      Price: dataFromAPI.Price,
      Colors: [],
      Features: [],
      AreasOfConcern: [],
      TradeInAvailable: false,
      positionCurrent: {}
    });
  };

  const onChangePositionHandler = (position) => {
    setFilterProduct({
      ...filterObj,
      positionCurrent: position
    });
  };

  return (
    <div className='filter-popup'>
      {/* <div className='filter-popup__title'>
        <Text field={dataSources['Filter Title']} />
      </div> */}
      <div className='filter-popup__content'>
        <div className='filter-popup__section'>
          <h3 className='filter-popup__section__heading'>
            <Text field={dataSources['Sort By Label']} />
          </h3>
          {dataSources['Search Settings']['Sort Options'].length ?
            <div className='filter-popup__section__dropdown'>
              <div className='filter-popup__section__dropdown__selected' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                <span className='sort-type-selected'>
                  <Text field={filterObj.SortBy.Name} />
                </span>
                <i className='icon-chevron-down-big'></i>
              </div>
              <ul
                className={`filter-popup__section__dropdown__list ${isOpen ? 'is-open' : ''}`}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
              >
                {dataSources['Search Settings']['Sort Options'].map((itemType, itemTypeIdx) => (
                  <li
                    className={`filter-popup__section__dropdown__item ${itemType.Value.value === filterObj.SortBy.Value.value ? 'is-selected' : ''}`}
                    key={itemTypeIdx}
                    onClick={() => {
                      onSelectFilterHandler({ key: PARAMS_SUBMIT.SortBy, value: itemType });

                      sorByHandlerGA4(itemType.Name.value);
                    }}
                  >
                    <Text field={itemType.Name} />
                  </li>
                ))}
              </ul>
            </div> : <></>
          }
        </div>
        {isFinite(dataFromAPI.Price.max) && isFinite(dataFromAPI.Price.min) && facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Price) ?
          <FilterByPrice
            filterPrices={filterLastest?.Price}
            isToogleClearFilter={isToogleClearFilter}
            prices={dataFromAPI.Price}
            setSelectRangeEvt={onSelectFilterHandler}
            dataSources={facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Price)}
            setPositionCurrentEvt={onChangePositionHandler}
          /> : ''}
        {dataFromAPI?.Facets.find(facetItem => facetItem?.Name === config.STEP_RANGE_COLOR) && facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Color) ?
          <FilterProductByColor
            filterColorCurrent={filterLastest?.Colors}
            isToogleClearFilter={isToogleClearFilter}
            isHelpMeDecide
            setSelectColorEvt={onSelectFilterHandler}
            colors={dataFromAPI.Facets.find((item) => item.Name === config.STEP_RANGE_COLOR)}
            dataSources={facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Color)}
          /> : ''}
        {facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.TradeIn) &&
          <FilterByTradeInAvailable checked={filterObj.TradeInAvailable} setChecked={onSelectFilterHandler} dataSources={facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.TradeIn)}
          ></FilterByTradeInAvailable>}
        {areasOfConcern?.Details && areasOfConcern?.Details.length > 0 && facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.AreasOfConcern) ?
          <FilterByFeature
            filterFeatureCurrent={filterLastest?.areasOfConcern}
            dataSources={facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.AreasOfConcern)}
            isAreas
            features={areasOfConcern}
            setSelectFilterEvt={onSelectFilterHandler}
            isToogleClearFilter={isToogleClearFilter}
          /> : ''}

        {dataFromAPI?.Facets.find(facetItem => facetItem?.Name === config.STEP_RANGE_FEATURE) && facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Feature) ?
          <FeaturesAndFunctionNew
            filterFeatureCurrent={filterLastest?.Features}
            features={dataFromAPI.Facets.find((item) => item.Name === config.STEP_RANGE_FEATURE)}
            isToogleClearFilter={isToogleClearFilter}
            setSelectFilterEvt={onSelectFilterHandler}
            dataSources={facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Feature)}
          />
          : ''}
      </div>
      <div className='filter-popup__btn-wrap'>
        <div className='filter-popup__btn-wrap__item'>
          <span className='filter-popup__btn-wrap__item__apply btn btn-outline-CTA1' onClick={onApplyFilterHandler}>
            <Text field={dataSources['Apply Filter Text']} />
          </span>
        </div>
        <div className='filter-popup__btn-wrap__item__clear filter-popup__btn-wrap__item'>
          <span className='btn-link' onClick={onClearFilterHandler}>
            <Text field={dataSources['Clear Filter Text']} />
          </span>
        </div>
      </div>
    </div>
  );
};

FilterPopup.propTypes = {
  dataSources: Proptypes.object,
  params: Proptypes.object,
  reset: Proptypes.object,
  setParams: Proptypes.func,
  setReset: Proptypes.func,
  dataFromAPI: Proptypes.any,
  sPageURL: Proptypes.any,
  appplyFilterEvt: Proptypes.func
};

export default FilterPopup;
