import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import ProductFieldModel from './ProductFieldModel';

export default class RecentlyViewedProductsModel {
  constructor() {
    this['Buy Now Link'] = new SitecoreLinkModel();

    this['Buy Now Text'] = new SitecoreTextModel();

    this['Experience In Store Text'] = new SitecoreTextModel();

    this['Learn More Text'] = new SitecoreTextModel();

    this['Product Field'] = [];

    this['Title'] = new SitecoreTextModel();

    this['Deposit Button Text'] = new SitecoreTextModel();

    this['Deposit Login Button Text'] = new SitecoreTextModel();

    this['Deposit Login Button Link'] = new SitecoreLinkModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Link')) {
      this['Buy Now Link'].getData(dataSource['Buy Now Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Text')) {
      this['Buy Now Text'].getData(dataSource['Buy Now Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience In Store Text')) {
      this['Experience In Store Text'].getData(dataSource['Experience In Store Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Text')) {
      this['Learn More Text'].getData(dataSource['Learn More Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Field')) {
      let newProductField = [];

      dataSource['Product Field'].map((product) => {
        newProductField.push(new ProductFieldModel().getData(deepCopy(product) || {}));
      });

      this['Product Field'] = dataSource['Product Field'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Button Text')) {
      this['Deposit Button Text'].getData(dataSource['Deposit Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Login Button Text')) {
      this['Deposit Login Button Text'].getData(dataSource['Deposit Login Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Login Button Link')) {
      this['Deposit Login Button Link'].getData(dataSource['Deposit Login Button Link']);
    }

    return this;
  }
}
