import './BuyingOptionAccordion.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import BOAdditionalOptionsList from '@components/BuyingOptions/BOAdditionalOptions/BOAdditionalOptionsList';
import BOFreeGiftsList from '@components/BuyingOptions/BOFreeGifts/BOFreeGiftsList';
import BORentalList from '@components/BuyingOptions/BORentals/BORentalsList';
import BOTradeInContent from '@components/BuyingOptions/BOTradeIn/BOTradeInContent';
import BOVariantsList from '@components/BuyingOptions/BOVariants/BOVariantsList';
import BOWarrantyList from '@components/BuyingOptions/BOWarranty/BOWarrantyList';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import BuyingFirstTypeHeader from './BuyingFirstTypeHeader';
import BuyingSecondTypeHeader from './BuyingSecondTypeHeader';
import BuyingTueTypeHeader from './BuyingTueTypeHeader';

const BuyingAccordion = (props) => {
  const {
    data,
    headerType,
    eventKey,
    isOpenItem,

    activeItemsId,
    selectedItemsId,
    selectedItemsName,

    labelText,
    changeText,
    dataFields,
    errorMsg,
    isShowError,
    additionalOptionsData,
    additionalOptionsRequired,

    // TradinOpions
    isDefaultTitle,
    isOpenModal,
    isTradeInOptions,
    productData,

    onFreeGiftData,
    onToggleSelectDepositFreeGift
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [defaultTradeInTitle, isDefaultTradeInTitle] = useState(isDefaultTitle);
  const [tradeInPopUp, isOpenTradeInPopUp] = useState(isOpenModal);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  useEffect(() => {
    setIsOpen(isOpenItem);
  }, [isOpenItem]);

  const onToogleAccordion = () => {
    setIsOpen(!isOpen);
    if (onToggleSelectDepositFreeGift) onToggleSelectDepositFreeGift();
  };

  const onShowTradeInOptionsModal = (obj) => {
    if (obj.status === 'submit') {
      isDefaultTradeInTitle(false);

      isOpenTradeInPopUp(false);
    }
    if (obj.status === 'change') {
      isOpenTradeInPopUp(true);
    }
    if (obj.status === 'toggleTradeInOptionsPopup') {
      isOpenTradeInPopUp(!tradeInPopUp);
    }
    if (obj.status === 'remove') {
      isDefaultTradeInTitle(true);

      isOpenTradeInPopUp(false);
    }
  };

  const listHeaderRender = (type, isOpen, labelText, changeText, selectedItemsName, errorMsg, isShowError, data, isAdditionalOption, onFreeGiftData) => {
    let ComponentType = listHeader[type];

    return (
      <ComponentType
        isOpen={isOpen}
        labelText={labelText}
        changeText={changeText}
        firstTypeItemsName={selectedItemsName}
        secondTypeItemsName={activeItemsId}
        additionalOptionsRequired={additionalOptionsRequired}
        isAdditionalOption={isAdditionalOption}
        onToogleFnc={onToogleAccordion}
        errorMsg={errorMsg}
        isShowError={isShowError}
        contentData={data}
        // TradeInOptions
        defaultTradeInTitle={defaultTradeInTitle}
        isTradeInOptions={isTradeInOptions}
        onShowTradeInOptionsModal={onShowTradeInOptionsModal}
        dataFields={dataFields}
        productData={productData}
        onFreeGiftData={onFreeGiftData}
      />
    );
  };

  const listItemRender = (targetKey, data, activeItemsId, dataFields, additionalOptionsData, additionalOptionsRequired, onFreeGiftData) => {
    let ComponentType = listItem[targetKey];

    return (
      <ComponentType
        contentData={data}
        dataFields={dataFields}
        activeItemsId={activeItemsId}
        onToogleFnc={onToogleAccordion}
        isOpen={isOpen}
        //extra
        isSelected={isOpenItem}
        selectedItemsId={selectedItemsId}
        additionalOptionsData={additionalOptionsData}
        additionalOptionsRequired={additionalOptionsRequired}
        // TradeInOptions
        tradeInPopUp={tradeInPopUp}
        onShowTradeInOptionsModal={onShowTradeInOptionsModal}
        productData={productData}
        onFreeGiftData={onFreeGiftData}
      />
    );
  };

  const listItem = {
    VARIANT_EVENT_KEY: BOVariantsList,
    WARRANTY_EVENT_KEY: BOWarrantyList,
    FREEGIFT_EVENT_KEY: BOFreeGiftsList,
    TRADEIN_EVENT_KEY: BOTradeInContent,
    RENTAL_EVENT_KEY: BORentalList,
    ADDITIONAL_OPTIONS_KEY: BOAdditionalOptionsList
  };

  const listHeader = {
    FIRSTTYPE: BuyingFirstTypeHeader,
    SECONDTYPE: BuyingSecondTypeHeader,
    TUESTYPE: BuyingTueTypeHeader
  };

  return data.length ? (
    <Accordion activeKey={isOpen === true ? eventKey : defaultTradeInTitle ? eventKey : -1}>
      <div className={`${isClientTelingUser ? 'accordion-buying-option-cp' : 'accordion-buying-option'}`}>
        {listHeaderRender(headerType, isOpen, labelText, changeText, selectedItemsName, errorMsg, isShowError, data, eventKey == 'ADDITIONAL_OPTIONS_KEY')}
        <Accordion.Collapse eventKey={eventKey}>
          {listItemRender(eventKey, data, activeItemsId, dataFields, additionalOptionsData, additionalOptionsRequired)}
        </Accordion.Collapse>
      </div>
    </Accordion>
  ) : onFreeGiftData?.IsDeposit ? (
    <Accordion activeKey={isOpen === true ? eventKey : defaultTradeInTitle ? eventKey : -1}>
      <div className={`${isClientTelingUser ? 'accordion-buying-option-cp' : 'accordion-buying-option'}`}>
        {listHeaderRender(headerType, isOpen, labelText, changeText, selectedItemsName, errorMsg, isShowError, data, eventKey == 'ADDITIONAL_OPTIONS_KEY', onFreeGiftData)}
        <Accordion.Collapse eventKey={eventKey}>
          {listItemRender(eventKey, data, activeItemsId, dataFields, additionalOptionsData, additionalOptionsRequired, onFreeGiftData)}
        </Accordion.Collapse>
      </div>
    </Accordion>
  ) : (
    <></>
  );
};

BuyingAccordion.propTypes = {
  data: PropTypes.any,
  headerType: PropTypes.any,
  eventKey: PropTypes.any,
  isOpenItem: PropTypes.any,

  activeItemsId: PropTypes.any,
  selectedItemsId: PropTypes.any,
  selectedItemsName: PropTypes.any,

  labelText: PropTypes.any,
  changeText: PropTypes.any,
  dataFields: PropTypes.any,
  errorMsg: PropTypes.any,
  isShowError: PropTypes.any,
  additionalOptionsData: PropTypes.string,
  additionalOptionsRequired : PropTypes.bool,

  // TradinOptions
  isDefaultTitle: PropTypes.bool,
  isOpenModal: PropTypes.bool,
  isTradeInOptions: PropTypes.bool,
  isGA4: PropTypes.bool,
  productData: PropTypes.any,

  onFreeGiftData: PropTypes.any,
  onToggleSelectDepositFreeGift: PropTypes.func
};

export default BuyingAccordion;
