import './CustomerProductList.scss';

import React from 'react';

import CPProductListing from './CPProductListing';
import InfoHeader from './InfoHeader';

const CustomerProductList = () => {
  return <div className='customer-product-list'>
    <div className='container tablet-container'>
      <InfoHeader />
      <CPProductListing />
    </div>
  </div>;
};

export default CustomerProductList;
