import { any } from 'prop-types';
import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';

import { CreateScheduleContactStatus } from '@redux/epic/bookingExperience/createBookingExperienceContact/responseStatus';
import { GetScheduleOtpStatus } from '@redux/epic/bookingExperience/getScheduleOtpCode/responseStatus';
import { ScheduleAppointStatus } from '@redux/epic/bookingExperience/scheduleAppointment/responseStatus';
import { UpdateBookingExperienceStatus } from '@redux/epic/bookingExperience/updateBookingExperience/responseStatus';

const ErrorHandlerComp = ({ getOtpError, createContactError, scheduleError, createScheduleContactResponse, updateBookingExperienceError }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const getOtpErrorCode = useMemo(() => {
    let msgCode = '';
    if (getOtpError) {
      msgCode = GetScheduleOtpStatus.ErrorStatusCode[getOtpError.StatusCode] || GetScheduleOtpStatus.GeneralMsg;
    }

    return msgCode;
  }, [getOtpError]);

  const scheduleAppointmentErrorMsgCode = useMemo(() => {
    return scheduleError ? ScheduleAppointStatus.ErrorStatusCode[scheduleError?.StatusCode] || ScheduleAppointStatus.GeneralMsg : '';
  }, [scheduleError]);

  const createScheduleContactMsgCode = useMemo(() => {
    return createContactError ? CreateScheduleContactStatus.ErrorStatusCode[createContactError.StatusCode] : CreateScheduleContactStatus.GeneralMsg;
  }, [createContactError]);

  const createScheduleContactWarningMsgCode = useMemo(() => {
    return createScheduleContactResponse
      ? Object.keys(CreateScheduleContactStatus.WarningStatusCode).includes(createScheduleContactResponse?.StatusCode)
        ? createScheduleContactResponse?.StatusCode
        : ''
      : '';
  }, [createScheduleContactResponse]);

  const updateAppointmentErrorMsgCode = useMemo(() => {
    return updateBookingExperienceError
      ? UpdateBookingExperienceStatus.ErrorStatusCode[updateBookingExperienceError?.StatusCode] || UpdateBookingExperienceStatus.GeneralMsg
      : '';
  }, [updateBookingExperienceError]);

  return scheduleAppointmentErrorMsgCode || createScheduleContactMsgCode || createScheduleContactWarningMsgCode || updateAppointmentErrorMsgCode ? (
    <div className='contact-detail__body'>
      {scheduleError ? <span className='error-validate schedule-error-message'>{messageObj?.[scheduleAppointmentErrorMsgCode]}</span> : <></>}
      {createContactError ? <span className='error-validate schedule-error-message'>{messageObj?.[createScheduleContactMsgCode]}</span> : <></>}
      {getOtpErrorCode ? <span className='error-validate schedule-error-message'>{messageObj?.[getOtpErrorCode]}</span> : <></>}
      {createScheduleContactWarningMsgCode ? (
        <span className='error-validate schedule-error-message'>
          {messageObj?.[CreateScheduleContactStatus.WarningStatusCode[createScheduleContactWarningMsgCode]]}
        </span>
      ) : (
        <></>
      )}
      {updateAppointmentErrorMsgCode ? (
        <span className='error-validate schedule-error-message'>{messageObj?.[updateAppointmentErrorMsgCode]}</span>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

ErrorHandlerComp.propTypes = {
  getOtpError: any,
  createContactError: any,
  scheduleError: any,
  createScheduleContactResponse: any,
  updateBookingExperienceError: any
};

const mapStateToProps = (state) => ({
  getOtpError: state.bookingExperienceReducer.getScheduleOtpCodeReducer.error,
  createContactError: state.bookingExperienceReducer.createBookingExperienceContactReducer.error,
  scheduleError: state.bookingExperienceReducer.scheduleAppointmentReducer.error,
  createScheduleContactResponse: state.bookingExperienceReducer.createBookingExperienceContactReducer.contactData,
  updateBookingExperienceError: state.bookingExperienceReducer.updateBookingExperienceReducer.error
});

export default connect(mapStateToProps)(ErrorHandlerComp);
