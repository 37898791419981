import './AppointmentBookedComponent.scss';

import { any } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import ResponsiveImage from '@components/ResponsiveImage';
import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

import AppointmentBookedComponentModel from './models/AppointmentBookedComponentModel';
import RegisterAccountForm from './RegisterAccountForm';

const AppointmentBookedComponent = ({ fields }) => {
  // const viewLabelLink =
  // `${dataSource?.ctaButtonLink?.value?.href}?campaign_id=${dataSource?.appointment?.Campaign?.Id}&appointment_id=${dataSource?.appointment?.Id}`;
  const [dataSource, setDataSources] = useState(null);
  const isLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);

  const formatDate = {
    month: 'short',
    day: 'numeric',
    year: 'numeric'
  };
  const formatDateUtil = useFormatDate();

  useEffect(() => {
    setDataSources(new AppointmentBookedComponentModel().getData(fields));
  }, []);

  const viewAppointDetailLink = useMemo(() => {
    return isLogin
      ? {
        value: {
          ...dataSource?.ctaButtonLink?.value,
          href: `${dataSource?.ctaButtonLink?.value?.href}?campaign_id=${dataSource?.appointment?.Campaign?.Id}&appointment_id=${dataSource?.appointment?.Id}`
        }
      }
      : '';
  }, [isLogin, dataSource]);

  const campaignUid = useMemo(() => {
    return dataSource?.campaign && dataSource?.campaign?.['Uid'] ? dataSource?.campaign?.['Uid'] : '';
  }, [dataSource]);

  const productName = useMemo(() => {
    return dataSource?.appointment?.IsBookExperience ? dataSource?.appointment?.ProductName || dataSource?.appointment?.Product?.Name : '';
  }, [dataSource]);

  const appointmentDate = useMemo(() => formatDateUtil(dataSource?.appointment?.Date), [dataSource]);

  return dataSource ? (
    <>
      <div className='success-registered-guest'>
        <div className='success-registered-guest__background'>
          <ResponsiveImage
            imageMobile={dataSource?.backgroundMobile}
            srcSet={[
              {
                mw: 768,
                image: dataSource?.backgroundDesktop
              }
            ]}
          />
        </div>
        <div className='success-registered-guest__content'>
          <div className='container success-registered-guest__content__container'>
            <div className='success-registered-guest__content__wrap'>
              <Image field={dataSource?.icon} className='success-registered-guest__content__wrap__icon' />
              <Text field={dataSource?.title} tag='h2' className='success-registered-guest__content__wrap__title' />
              <span className='success-registered-guest__content__wrap__code'>{`#${dataSource?.appointment?.BookingID}`}</span>
              {campaignUid ? <span className='success-registered-guest__content__wrap__uid'>{campaignUid}</span> : <></>}
              {productName ? <span className='success-registered-guest__content__wrap__uid'>{productName}</span> : <></>}
              <div className='success-registered-guest__content__wrap__time'>
                <span>{appointmentDate}</span>
                {dataSource?.appointment?.IsBookExperience ? (
                  <>
                    <Text field={{ value: dataSource?.appointment?.TimeSlotStr }} tag='span' />
                    <Text field={{ value: `${dataSource?.appointment?.NumberOfGuests} ${dataSource?.['guestsLabel']?.value}` }} tag='span' />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className='success-registered-guest__content__wrap__address'>
                <Text
                  field={{ value: dataSource?.appointment?.Store?.Name }}
                  tag='h3'
                  className='success-registered-guest__content__wrap__address__title'
                />
                <Text
                  field={{ value: dataSource?.appointment?.Store?.Address?.Address }}
                  tag='p'
                  className='success-registered-guest__content__wrap__address__description'
                />
                <Text
                  field={{ value: dataSource?.appointment?.Store?.Address?.PostalCode }}
                  tag='p'
                  className='success-registered-guest__content__wrap__address__description'
                />
                {dataSource?.appointment?.Store?.PhoneNumber ? (
                  <Text
                    field={{ value: `+${dataSource?.appointment?.Store?.PhoneNumber}` }}
                    tag='p'
                    className='success-registered-guest__content__wrap__address__description'
                  />
                ) : (
                  <></>
                )}
              </div>
              <RichText field={dataSource?.description} tag='div' className='success-registered-guest__content__wrap__excerpts' />
              {viewAppointDetailLink ? (
                // <Link field={{href: viewLabelLink}} className='success-registered-guest__content__wrap__link-view'>
                <Link field={viewAppointDetailLink} className='success-registered-guest__content__wrap__link-view'>
                  <Text field={{ value: dataSource?.ctaButtonLink?.value?.text }} />
                </Link>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
      <RegisterAccountForm dataSource={dataSource} />
      <div className='appointment-booked-form__link'>
        <Link field={dataSource?.continueShoppingLink} className='btn btn-outline-primary'>
          <Text field={dataSource?.continueShoppingText} />
        </Link>
      </div>
    </>
  ) : (
    <></>
  );
};

AppointmentBookedComponent.propTypes = {
  fields: any
};

export default AppointmentBookedComponent;
