import { object } from 'prop-types';
import React from 'react';

import { Image } from '@sitecore-jss/sitecore-jss-react';

const CPContactInfoShimmer = ({ layoutData }) => {
  return (
    <div className='contact-infor box-data skeleton__item'>
      <div className='row'>
        <div className='col-md-4'>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Mail Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Phone Icon']} className='cp-icon' />
            </div>
            <div className='data-place phone-value'>
              <span className='data-text text-line-25 w-30'></span>

              <Image field={layoutData['Show Info Icon']} className='cp-icon' />
            </div>
          </div>
          <div className='flex-content box-data__social-icon'>
            <Image field={layoutData['Message Button Icon']} className='cp-icon' />
            <Image field={layoutData['Dial Button Icon']} className='cp-icon' />
          </div>
        </div>
        <div className='col-md-4'>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Gender Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Marital Status Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Birthday Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Age Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Household Size Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Owned Product Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Job Nature Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
          <div className='flex-content'>
            <div className='icon-place'>
              <Image field={layoutData['Health Issues Icon']} className='cp-icon' />
            </div>
            <div className='data-place'>
              <span className='data-text text-line-25 w-30'></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

CPContactInfoShimmer.propTypes = {
  layoutData: object
};

export default CPContactInfoShimmer;
