import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class BundleKeyFeatureModel {
  constructor() {
    this['Description'] = new SitecoreTextModel();

    this['Experience In Store Button Label'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Experience In Store Button Link'] = new SitecoreLinkModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience In Store Button Label')) {
      this['Experience In Store Button Label'].getData(dataSource['Experience In Store Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience In Store Button Link')) {
      this['Experience In Store Button Link'].getData(dataSource['Experience In Store Button Link']);
    }

    return this;
  }
}
