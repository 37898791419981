import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Link, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import MainHeaderMenuFieldsModel from './Model/HeaderMenuFieldsModel';

const HeaderMenu = (props) => {
  const { checkActive, fields, headerRef, headerContainerRef } = props;
  const headerMenuRef = useRef(null);
  const [dataBanner, setDataBanner] = useState({});

  const isHKCNSite =
    SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OHK' ||
    SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OCN';

  useEffect(() => {
    const headerMenuFieldsModel = new MainHeaderMenuFieldsModel();

    setDataBanner(headerMenuFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    const handleSetHeaderMenuSpaceTop = () => {
      let headerOffsetTopVariable = 0;

      if (headerRef.current.getBoundingClientRect().top > 0) headerOffsetTopVariable = headerRef.current.getBoundingClientRect().top;
      else headerOffsetTopVariable = headerContainerRef.current.offsetTop;

      if (headerMenuRef.current && headerMenuRef.current.offsetLeft < 0) headerMenuRef.current.style.top = `${headerOffsetTopVariable + headerRef.current.offsetHeight}px`;
    };

    window.addEventListener('scroll', handleSetHeaderMenuSpaceTop);

    window.addEventListener('resize', handleSetHeaderMenuSpaceTop);

    handleSetHeaderMenuSpaceTop();

    return () => {
      window.removeEventListener('scroll', handleSetHeaderMenuSpaceTop);

      window.removeEventListener('resize', handleSetHeaderMenuSpaceTop);
    };
  }, [headerMenuRef?.current, headerMenuRef?.current?.offsetLeft, headerRef?.current, headerContainerRef?.current?.offsetHeight]);

  return (
    <nav ref={headerMenuRef} className={checkActive ? 'header-menu header__menu-nav show-menu-nav' : 'header-menu header__menu-nav'}>
      <div className={`header__menu-nav__contents ${isHKCNSite ? 'header__menu-nav__contents--alt-site' : ''}`}>
        <div className='header__menu-nav__contents__list-nav'>
          <Placeholder name='osim-header-menu' rendering={props.rendering} />
        </div>
      </div>
      <div className='header__menu-nav__banner'>
        {dataBanner && (
          <>
            {dataBanner['First Banner']?.value?.src ? (
              <Link field={dataBanner['First Link']} className='header__menu-nav__banner__images'>
                <Image media={dataBanner['First Banner']} />
              </Link>
            ) : (
              ''
            )}
            {dataBanner['Second Image']?.value?.src ? (
              <Link field={dataBanner['Second Link']} className='header__menu-nav__banner__images'>
                <Image media={dataBanner['Second Image']} />
              </Link>
            ) : (
              ''
            )}
          </>
        )}
      </div>
    </nav>
  );
};

HeaderMenu.propTypes = {
  fields: PropTypes.any,
  rendering: PropTypes.any,
  checkActive: PropTypes.bool,
  headerRef: PropTypes.any,
  headerContainerRef: PropTypes.any
};

export default HeaderMenu;
