import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CPSearchBarModel {
  constructor() {
    this['Image'] = new SitecoreImageModel();

    this['Link'] = new SitecoreLinkModel();

    this['Placeholder Text'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this['Link'].getData(dataSource['Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Placeholder Text')) {
      this['Placeholder Text'].getData(dataSource['Placeholder Text']);
    }

    return this;
  }
}
