import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class AccountQuickAccessFieldsModel {
  constructor() {
    this['Login Link'] = new SitecoreLinkModel();
    
    this['My Account Link'] = new SitecoreLinkModel();

    this['Welcome Message'] = new SitecoreTextModel();
  }

  getData(dataSource, params) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Link')) {
      this['Login Link'].getData(dataSource['Login Link'], params);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'My Account Link')) {
      this['My Account Link'].getData(dataSource['My Account Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Welcome Message')) {
      this['Welcome Message'].getData(dataSource['Welcome Message']);
    }

    return this;
  }
}
