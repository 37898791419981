import produce from 'immer';

import StoreFilterZipCodeModel from '@components/Share/StoreLocator/model/StoreFilterZipCodeModel';
import * as storeLocatorTypes from '@redux/actions/storeLocator/storeLocatorTypes';
import { deepCopy } from '@utils/utility.js';

const initialState = {
  name: 'Filter Zip Code Options',
  filterZipCodeOptions: [],
  isLoading: false,
  errorMsg: ''
};

const filterZipCodeOptionsReducer = (state = initialState, action) => 
  produce(state, draft => {
    let newZipCodeOptions = [];
    switch (action.type) {
    case storeLocatorTypes.GET_FILTER_ZIP_CODE:

      draft.isLoading = true;
      break;

    case storeLocatorTypes.GET_FILTER_ZIP_CODE_SUCCESS:
      action.payload.map(element => {
        newZipCodeOptions.push(new StoreFilterZipCodeModel().getData(deepCopy(element)));
      });

      draft.filterZipCodeOptions = newZipCodeOptions;

      draft.isLoading = false;
      break;

    case storeLocatorTypes.GET_FILTER_ZIP_CODE_FAILED:
      draft.filterZipCodeOptions = [];

      draft.isLoading = false;
      break;
    }

    return draft;
  });


export default filterZipCodeOptionsReducer;