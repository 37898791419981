import { array } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getTotalBookedTimeSlotOfDate } from '@redux/actions/bookingExperience/getTotalBookedTimeSlot';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import { useDisplayFormikMessage } from '../../../hooks/useDisplayFormikMessage';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { useScheduleAppointmentFormContext } from '../index';

const SelectTimeSlot = ({ totalTimeSlotBookedResult }) => {
  const { layoutData } = useScheduleAppointmentContext();
  const { formik } = useScheduleAppointmentFormContext();
  const displayFormikMessage = useDisplayFormikMessage();
  const dispatch = useDispatch();

  useEffect(() => {
    if (layoutData?.appointment?.Store?.Id && layoutData?.appointment?.Date) {
      dispatch(getTotalBookedTimeSlotOfDate({
        storeId: `{${layoutData?.appointment?.Store?.Id}}`,
        date: Global.formatDateYYYYMMDD(layoutData?.appointment?.Date)
      }));    
    }
  }, [layoutData]);

  const timeslotData = useMemo(() => {
    return totalTimeSlotBookedResult;
  }, [totalTimeSlotBookedResult]);

  return timeslotData?.length ? (
    <div
      className={`schedule-experience__body-item__timeslot-select ${
        !(formik.values.date && formik.values.storeId) ? 'be-schedule-appointment__disabled-form' : ''
      }`}
    >
      <Text className='select-store__content__list__title' tag='h4' field={layoutData['Select Time Slot Label']} />
      <div className='schedule-experience__body-item__timeslot-select__wrapper'>
        {timeslotData.map((timeSlot) => {
          const itemId = `optionScheduleTimeSlot-${timeSlot.Id}`;

          return (
            <div key={timeSlot.Id} className={`select-store__date__option__list__item ${timeSlot?.IsTimeSlotExist ? 'select-store__date__option__list__item--disabled': ''}`}>
              <div className='form-group'>
                <input
                  id={itemId}
                  value={timeSlot.Id}
                  type={'radio'}
                  checked={formik.values.timeSlot?.toLowerCase() === timeSlot.Id?.toLowerCase()}
                  name='timeSlotOptions'
                  onChange={(event) => formik.setFieldValue('timeSlot', event.target.value)}
                  disabled={timeSlot?.IsTimeSlotExist}
                />
                <label htmlFor={itemId}>{timeSlot.Name}</label>
              </div>
            </div>
          );
        })}
      </div>
      <span className='error-validate'>{displayFormikMessage(formik, 'timeSlot')}</span>
    </div>
  ) : (
    <></>
  );
};

SelectTimeSlot.propTypes = {
  totalTimeSlotBookedResult: array,
};

const mapStateToProps = (state) => {
  return {
    totalTimeSlotBookedResult: state.bookingExperienceReducer.getTotalBookedTimeSlotReducer.totalTimeSlotBookedResult || [],
  };
};

export default connect(mapStateToProps)(SelectTimeSlot);
