import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import CurrencyModel from './CurrencyModel';
import DynamicBundleDataModel from './DynamicBundleDataModel';

export default class CreateBundleModel {
  constructor() {
    this['Buy Now Button Label'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['Buy Now Button Link'] = new SitecoreLinkModel();

    this['Product Title'] = new SitecoreTextModel();

    this['Select Product Above Label'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Your Bundle Items Label'] = new SitecoreTextModel();

    this.ProductRows = 3;

    this['Dynamic Bundle Data'] = new DynamicBundleDataModel();

    this['Currency'] = new CurrencyModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Label')) {
      this['Buy Now Button Label'].getData(dataSource['Buy Now Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Title')) {
      this['Product Title'].getData(dataSource['Product Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Select Product Above Label')) {
      this['Select Product Above Label'].getData(dataSource['Select Product Above Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Your Bundle Items Label')) {
      this['Your Bundle Items Label'].getData(dataSource['Your Bundle Items Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Link')) {
      this['Buy Now Button Link'].getData(dataSource['Buy Now Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Dynamic Bundle Data')) {
      const dynamicBundleData = dataSource['Dynamic Bundle Data'];
      if(dynamicBundleData.DynamicBundleOption && dynamicBundleData.DynamicBundleOption.ProductRows){
        this.ProductRows = dynamicBundleData.DynamicBundleOption.ProductRows;
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Dynamic Bundle Data')) {
      this['Dynamic Bundle Data'].getData(dataSource['Dynamic Bundle Data']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Currency')) {
      this['Currency'].getData(dataSource['Currency']);
    }

    return this;
  }
}
