import './BOWarranty.scss';

import Proptypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import BuyingAccordion from '@components/Share/BuyingAccordion';
import { DEFAULT_SELECT_LIMIT, WARRANTY_EVENT_KEY } from '@utils/constant';

const BOWarranty = (props) => {
  const { dataFields, warrantyList, warrantyActive, warrantySelectedLength, isShowError, setRef } = props;

  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  return warrantyList?.length ? (
    <Fragment>
      <div className='warranty-buying buying-option__group-info__line-bottom' ref={setRef}>
        <BuyingAccordion
          data={warrantyList}
          headerType={'FIRSTTYPE'}
          eventKey={WARRANTY_EVENT_KEY}
          isOpenItem = {warrantySelectedLength === DEFAULT_SELECT_LIMIT ? false : true}
          
          activeItemsId={warrantyActive?.ProductId ? [warrantyActive.ProductId] : []}
          selectedItemsName = {`${warrantyActive?.DisplayName} (${warrantyActive?.Price.ListPrice === 0 ? messageObj['LBL-WarrantyFee'] || '' : warrantyActive?.Price?.ListPriceWithCurrency})`}

          labelText={dataFields['Warranty Label'].value} 
          changeText={dataFields['Change Text'].value}
          errorMsg={dataFields['Please Select Your Warranty Message'].value}
          isShowError={isShowError}
          dataFields={dataFields}
        />
      </div>
    </Fragment>
  ) : null;
};

BOWarranty.propTypes = {
  dataFields: Proptypes.any,
  warrantyList: Proptypes.any,
  warrantyActive: Proptypes.any,
  warrantySelectedLength: Proptypes.any,
  isShowError: Proptypes.any,

  setRef: Proptypes.any,
};

export default BOWarranty;
