import CountdownFormatSettingModel from '@components/PromotionListing/model/CountdownFormatSettingModel';

export default class SettingGlobalURLModel {
  constructor() {
    this['WellnessLandingURL'] = '';

    this['WellnessListingURL'] = '';

    this['BuyingOptionURL'] = '';

    this['CountdownFormatSetting'] = new CountdownFormatSettingModel();

    this['ExpireDate'] = new Date().getTime() + (15 * 60 * 1000);
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'WellnessLandingURL')) {
      this['WellnessLandingURL'] = dataSource['WellnessLandingURL'] ? dataSource['WellnessLandingURL'] : this['BuyingOptionURL'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'WellnessListingURL')) {
      this['WellnessListingURL'] = dataSource['WellnessListingURL'] ? dataSource['WellnessListingURL'] : this['WellnessListingURL'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BuyingOptionURL')) {
      this['BuyingOptionURL'] = dataSource['BuyingOptionURL'] ? dataSource['BuyingOptionURL'] : this['BuyingOptionURL'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CountdownFormatSetting')) {
      this['CountdownFormatSetting'].getData(dataSource['CountdownFormatSetting']);
    }

    return this;
  }
}
