import './AutoScrollingBanners.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import Marquee from './Marquee';
import AutoScrollingBannersModel from './model/AutoScrollingBannersModel';

const AutoScrollingBanners = ({ fields }) => {
  const [autoScrollingBannersFields, setAutoScrollingBannersFields] = useState(null);

  useEffect(() => {
    const autoScrollingBannersModel = new AutoScrollingBannersModel();

    setAutoScrollingBannersFields(autoScrollingBannersModel.getData(deepCopy(fields || {})));
  }, []);

  const arrayItemBanner = autoScrollingBannersFields?.['Banner Items'];

  return (
    autoScrollingBannersFields && (
      <div className='auto-scrolling-banners'>
        <div className='auto-scrolling-banners__header'>
          <p className='auto-scrolling-banners__header__content'>
            <Text field={autoScrollingBannersFields['Title']} />
          </p>
        </div>
        <div className='auto-scrolling-banners__container'>
          <Marquee arrayMarquee={arrayItemBanner} />
        </div>
        <div className='auto-scrolling-banners__header__button'>
          <button className='btn btn-primary'>
            <Link field={autoScrollingBannersFields['CTA Button Link']}>
              <Text field={autoScrollingBannersFields['CTA Button']} />
            </Link>
          </button>
        </div>
      </div>
    )
  );
};

AutoScrollingBanners.propTypes = {
  fields: PropTypes.any
};

export default AutoScrollingBanners;
