import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';

export class UserAddressesListModel {
  #listAddress = [];

  constructor(listAddress) {
    if (listAddress) this.#listAddress = [...listAddress];
  }

  get getListAddress() {
    let addressesListResult = [];
    if (this.#listAddress.length) {
      for (const item of this.#listAddress) {
        const { PhoneNumber, ...restData } = item;

        addressesListResult.push({
          ...restData,
          PhoneNumber: PhoneNumber ? (isAtSiteName(MY_SITE_NAME) ? `+${PhoneNumber}` : PhoneNumber) : '',
          MYSitePhoneNumber: PhoneNumber || ''
        });
      }
    }

    return addressesListResult;
  }
}
