import { SUBMIT_PURCHASE_ON_SPOT_START, SUBMIT_PURCHASE_ON_SPOT_SUCCESS, SUBMIT_PURCHASE_ON_SPOT_FAILED } from './submitPurchaseOnSpotTypes';

export const submitPurchaseOnSpotStart = (payload) => ({
  type: SUBMIT_PURCHASE_ON_SPOT_START,
  payload
});

export const submitPurchaseOnSpotSuccess = (payload) => ({
  type: SUBMIT_PURCHASE_ON_SPOT_SUCCESS,
  payload
});

export const submitPurchaseOnSpotFailed = (payload) => ({
  type: SUBMIT_PURCHASE_ON_SPOT_FAILED,
  payload
});