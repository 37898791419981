import * as cpSendSaleOrderTypes from './cpSendSaleOrderTypes';

export const sendSaleOrder = (payload) => ({
  type: cpSendSaleOrderTypes.SEND_SALE_ORDER,
  payload
});

export const sendSaleOrderSuccess = (payload) => ({
  type: cpSendSaleOrderTypes.SEND_SALE_ORDER_SUCCESS,
  payload
});

export const sendSaleOrderFailed = (error) => ({
  type: cpSendSaleOrderTypes.SEND_SALE_ORDER_FAILED,
  error
});