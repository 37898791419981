import TextModel from '@models/TextModel';

export default class ProductVariantFieldsModel {
  constructor() {
    this.Color = new TextModel();

    this.Description = new TextModel();

    this.Images = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Color')) {
      this['Color'].getData(dataSource['Color']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      this.Images = dataSource.Images ? dataSource.Images : this.Images;
    }

    return this;
  }
}
