const stringArray = [
  'Area',
  'AreaCode',
  'AreaId',
  'City',
  'CityCode',
  'CityId',
  'Country',
  'CountryCode',
  'CountryId',
  'District',
  'DistrictCode',
  'DistrictId',
  'FullAddress',
  'HeroImage',
  'InventorySetId',
  'ItemId',
  'ItemName',
  'Name',
  'OpeningHours',
  'Phone',
  'Postcode',
  'State',
  'StateCode',
  'StateId',
  'Street',
  'SubDistrict',
  'SubDistrictCode',
  'SubDistrictId',
  'StoreType',
  'DetailPageURL',
  'Description'
];

export default class StoreLocatorListModel {
  constructor() {
    stringArray.forEach((item) => {
      this[item] = '';
    });

    this['Latitude'] = 0;

    this['Longitude'] = 0;

    this['ExcludeProducts'] = [];

    this['TimeSlots'] = [];
  }
  getData(dataSource) {
    stringArray.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this.item;
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Latitude')) {
      this['Latitude'] = dataSource['Latitude'] ? dataSource['Latitude'] : this['Latitude'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Longitude')) {
      this['Longitude'] = dataSource['Longitude'] ? dataSource['Longitude'] : this['Longitude'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TimeSlots')) {
      this['TimeSlots'] = dataSource['TimeSlots']
        ? dataSource['TimeSlots'].TimeSlots?.length
          ? dataSource['TimeSlots'].TimeSlots
          : []
        : this['TimeSlots'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExcludeProducts')) {
      this['ExcludeProducts'] = dataSource['ExcludeProducts'];
    }

    return this;
  }
}
