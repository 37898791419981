import CountdownFormatSettingModel from '@components/PromotionListing/model/CountdownFormatSettingModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import { deepCopy } from '../../../utils/utility';

export default class DynamicBundleHeaderModel {
  constructor() {
    this['Buy Now Button Label'] = new SitecoreTextModel();

    this['CTA Button Label'] = new SitecoreTextModel();

    this['CTA Button Link'] = new SitecoreLinkModel();

    this['Get Notified Button Link'] = new SitecoreLinkModel();

    this['Buy Now Button Link'] = new SitecoreLinkModel();

    this['Get Notified Button Label'] = new SitecoreTextModel();

    this['Valid From Label'] = new SitecoreTextModel();

    this['TotalListPriceWithCurrency'] = '';

    this['TotalSellingPriceWithCurrency'] = '';

    this['Dynamic Bundle Data'] = [];

    this.Currency = {};

    this['countdownFormatSetting'] = new CountdownFormatSettingModel();

    this['Payment Gateways'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Label')) {
      this['Buy Now Button Label'].getData(dataSource['Buy Now Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Label')) {
      this['CTA Button Label'].getData(dataSource['CTA Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Get Notified Button Label')) {
      this['Get Notified Button Label'].getData(dataSource['Get Notified Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Valid From Label')) {
      this['Valid From Label'].getData(dataSource['Valid From Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Link')) {
      this['Buy Now Button Link'].getData(dataSource['Buy Now Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Link')) {
      this['CTA Button Link'].getData(dataSource['CTA Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Get Notified Button Link')) {
      this['Get Notified Button Link'].getData(dataSource['Get Notified Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Get Notified Button Link') && dataSource.TotalListPriceWithCurrency) {
      this['TotalListPriceWithCurrency'] = dataSource.TotalListPriceWithCurrency;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalSellingPriceWithCurrency') && dataSource.TotalSellingPriceWithCurrency) {
      this['TotalSellingPriceWithCurrency'] = dataSource.TotalSellingPriceWithCurrency;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Dynamic Bundle Data') && dataSource['Dynamic Bundle Data']) {
      this['Dynamic Bundle Data'] = dataSource['Dynamic Bundle Data'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Currency') && dataSource['Currency']) {
      this['Currency'] = dataSource['Currency'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'countdownFormatSetting')) {
      this['countdownFormatSetting'].getData(dataSource['countdownFormatSetting']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Payment Gateways')) {
      this['Payment Gateways'] = deepCopy(dataSource['Payment Gateways']);
    }

    return this;
  }
}
