import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { post } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as configs from './config';

export const getProductDetailHeaderEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_PRODUCT_DETAIL_HEADER),
    switchMap((action) => {
      const params = {
        productId: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.fields?.ProductId?.value,
        itemId: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.id,
        dataType: action.payload.dataType,
        sortBy:1,
        sortOrder:0
      };
      
      return from(post(configs.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.getProductDetailHeaderSuccess(res.data);
          } else {

            return actions.getProductDetailHeaderFailed('Failed');
          }
        })
      );
    })
  );
