import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';

import { getHadNotificationsStart } from '@redux/actions/cpNotificationPopup/getHadNotifications';
import { readAllNotificationFailure, readAllNotificationSuccess } from '@redux/actions/cpNotificationPopup/readAllNotification';
import { READ_ALL_NOTIFICATION_START } from '@redux/actions/cpNotificationPopup/readAllNotification/types';
import { postAsObservable } from '@services/genericService';

import { defaultFetchLogsParams, DELAY_TIME_TO_CALL_ACTIONS } from '../configs';
import { READ_ALL_NOTIFICATION_ENDPOINT } from './configs';

const readAllNotificationEpic = (action$) =>
  action$.pipe(
    ofType(READ_ALL_NOTIFICATION_START),
    switchMap(() => {
      return postAsObservable(READ_ALL_NOTIFICATION_ENDPOINT).pipe(
        switchMap((res) => {
          if (res.data.StatusCode === 200) {
            return of(readAllNotificationSuccess(res.data), getHadNotificationsStart(defaultFetchLogsParams)).pipe(delay(DELAY_TIME_TO_CALL_ACTIONS));
          } else {
            return of(readAllNotificationFailure(res.data.Message || 'Failed'));
          }
        }),
        catchError((error) => of(readAllNotificationFailure(error || 'Failed')))
      );
    })
  );

export default readAllNotificationEpic;
