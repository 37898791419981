import SitecoreTextModel from '@models/SitecoreTextModel';

export default class AnswerFieldsModel {
  constructor() {
    this.Answer = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Answer')) {
      this['Answer'].getData(dataSource['Answer']);
    }

    return this;
  }
}
