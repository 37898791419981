import { combineEpics } from 'redux-observable';

import cpLoginEpic from './cpLogin';
import cPLogoutEpic from './cPLogout';
import cPResendLoginOTP from './cPResendLoginOTP';
import cpVerifyLoginEpic from './cpVerifyLogin';
import {facebookEpic, toogleLinkFacebookEpic} from './facebook';
import {googleEpic, toogleLinkGoogleEpic} from './google';
import loginEpic from './login';
import logoutEpic from './logout';
import registerEpic from './register';
import renwePasswordEpic from './renewPassword';
import resendMailActiveEpic from './resendMailActive';
import userProfileEpic from './userProfile';

const singleSignOn = combineEpics(
  facebookEpic,
  googleEpic, 
  loginEpic,
  registerEpic,
  renwePasswordEpic,
  logoutEpic,
  resendMailActiveEpic,
  userProfileEpic,
  toogleLinkGoogleEpic,
  toogleLinkFacebookEpic,
  cpLoginEpic,
  cPLogoutEpic,
  cpVerifyLoginEpic,
  cPResendLoginOTP
);

export default singleSignOn;