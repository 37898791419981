import produce from 'immer';

import * as cartDetailTypes from '@redux/actions/cartDetail/cartTypes';
import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';
import * as productTypes from '@redux/actions/product/productTypes';

const initState = {
  isLoadingStoreAddress: false,
  isLoadingUserAddress: false,
  isLoadingBillingAddress: false,
  setCartLineFulfillmentError: null
};

const updateCartReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cartDetailTypes.SET_CART_FULLFILLMENT:
      draft.isLoadingUserAddress = true;

      draft.isLoadingStoreAddress = true;
      break;

    case cartDetailTypes.SET_CART_LINE_FULLFILLMENT:
      draft.setCartLineFulfillmentError = null;

      draft.isLoadingUserAddress = true;

      draft.isLoadingStoreAddress = true;
      break;

    case checkoutProcessTypes.UPDATE_BILLING_ADDRESS:
      draft.isLoadingBillingAddress = true;
      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:
      draft.isLoadingStoreAddress = false;

      draft.isLoadingUserAddress = false;

      draft.isLoadingBillingAddress = false;
      break;

    case cartDetailTypes.SET_CART_FULLFILLMENT_FAILED:
      draft.isLoadingUserAddress = false;

      draft.isLoadingStoreAddress = false;
      break;

    case cartDetailTypes.SET_CART_LINE_FULLFILLMENT_FAILED:
      draft.setCartLineFulfillmentError = action.error;

      draft.isLoadingUserAddress = false;

      draft.isLoadingStoreAddress = false;
      break;

    case checkoutProcessTypes.UPDATE_BILLING_ADDRESS_FAILED:
      draft.isLoadingBillingAddress = false;
      break;

    case productTypes.TURN_UPDATE_ADDRESS_LOADING_MASK_OFF:
      draft.isLoadingUserAddress=false;

      draft.isLoadingBillingAddress = false;
      break;
    }

    return draft;
  });

export default updateCartReducer;
