import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class StoreOverviewModel {
  constructor() {
    this['Book Experience Button Label'] = new SitecoreTextModel();

    this['Book Experience Button Link'] = new SitecoreLinkModel();

    this['Contact Info Label'] = new SitecoreTextModel();

    this['Email Us Text'] = new SitecoreTextModel();

    this['Get Directions Text'] = new SitecoreTextModel();

    this['Opening Hours Label'] = new SitecoreTextModel();

    this['Store Locator Data'] = {};

    this.FullAddress = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Book Experience Button Label')) {
      this['Book Experience Button Label'].getData(dataSource['Book Experience Button Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Book Experience Button Link')) {
      this['Book Experience Button Link'].getData(dataSource['Book Experience Button Link']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Contact Info Label')) {
      this['Contact Info Label'].getData(dataSource['Contact Info Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Email Us Text')) {
      this['Email Us Text'].getData(dataSource['Email Us Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Get Directions Text')) {
      this['Get Directions Text'].getData(dataSource['Get Directions Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Opening Hours Label')) {
      this['Opening Hours Label'].getData(dataSource['Opening Hours Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FullAddress') && dataSource.FullAddress) {
      this.FullAddress = dataSource.FullAddress;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Store Locator Data') && dataSource['Store Locator Data']) {
      this['Store Locator Data'] = dataSource['Store Locator Data'];
    }

    return this;
  }
}
