import * as myAccountTypes from '../myAccount/myAccountTypes';

export const updateUserProfile = (payload) => ({
  type: myAccountTypes.UPDATE_USER_PROFILE,
  payload
});

export const updateUserProfileSuccess = (payload) => ({
  type: myAccountTypes.UPDATE_USER_PROFILE_SUCCESS,
  payload
});

export const updateUserProfileFailed = (error) => ({
  type: myAccountTypes.UPDATE_USER_PROFILE_FAILED,
  error
});

export const verificationCodeEmail = (payload) => ({
  type: myAccountTypes.EMAIL_VALIDATION_CODE,
  payload
});

export const verificationCodeEmailSuccess = (payload) => ({
  type: myAccountTypes.EMAIL_VALIDATION_CODE_SUCCESS,
  payload
});

export const verificationCodeEmailFailed = (error) => ({
  type: myAccountTypes.EMAIL_VALIDATION_CODE_FAILED,
  error
});

export const validateChangeEmail = (payload) => ({
  type: myAccountTypes.VALIDATE_EMAIL,
  payload
});

export const validateChangeEmailSuccess = (payload) => ({
  type: myAccountTypes.VALIDATE_EMAIL_SUCCESS,
  payload
});

export const validateChangeEmailFailed = (error) => ({
  type: myAccountTypes.VALIDATE_EMAIL_FAILED,
  error
});

export const changeEmail = (payload) => ({
  type: myAccountTypes.CHANGE_EMAIL,
  payload
});

export const changeEmailSuccess = (payload) => ({
  type: myAccountTypes.CHANGE_EMAIL_SUCCESS,
  payload
});

export const changeEmailFailed = (error) => ({
  type: myAccountTypes.CHANGE_EMAIL_FAILED,
  error
});


export const changePasswordAccount = (payload) => ({
  type: myAccountTypes.CHANGE_PASSWORD_ACCOUNT,
  payload
});

export const changePasswordAccountSuccess = (payload) => ({
  type: myAccountTypes.CHANGE_PASSWORD_ACCOUNT_SUCCESS,
  payload
});

export const changePasswordAccountFailed = (error) => ({
  type: myAccountTypes.CHANGE_PASSWORD_ACCOUNT_FAILED,
  error
});

export const getSubscribe = (payload) => ({
  type: myAccountTypes.SUBSCRIBE_LETTER,
  payload
});

export const getSubscribeSuccess = (payload) => ({
  type: myAccountTypes.SUBSCRIBE_LETTER_SUCCESS,
  payload
});

export const getSubscribeFailed = (error) => ({
  type: myAccountTypes.SUBSCRIBE_LETTER_FAILED,
  error
});

export const getStatusSubscribe = (payload) => ({
  type: myAccountTypes.GET_STATUS_SUBSCRIBE_LETTER,
  payload
});

export const getStatusSubscribeSuccess = (payload) => ({
  type: myAccountTypes.GET_STATUS_SUBSCRIBE_LETTER_SUCCESS,
  payload
});

export const getStatusSubscribeFailed = (error) => ({
  type: myAccountTypes.GET_STATUS_SUBSCRIBE_LETTER_FAILED,
  error
});

export const updatePhoneNumberSuccess = (payload) => ({
  type: myAccountTypes.UPDATE_PHONE_NUMBER_SUCCESS,
  payload
});