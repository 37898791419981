import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SupportCentreWidgetModel {
  constructor() {
    this['Go To OSIM Cares Link'] = new SitecoreLinkModel();

    this['Go To OSIM Cares Label'] = new SitecoreTextModel();

    this['Support Centre Content'] = new SitecoreTextModel();

    this['Support Centre Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Go To OSIM Cares Link')) {
      this['Go To OSIM Cares Link'].getData(dataSource['Go To OSIM Cares Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Go To OSIM Cares Label')) {
      this['Go To OSIM Cares Label'].getData(dataSource['Go To OSIM Cares Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Support Centre Content')) {
      this['Support Centre Content'].getData(dataSource['Support Centre Content']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Support Centre Label')) {
      this['Support Centre Label'].getData(dataSource['Support Centre Label']);
    }

    return this;
  }
}
