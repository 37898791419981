import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ForgotEmailModel {
  constructor() {
    this['Send Code Text'] = new SitecoreTextModel();

    this['Verify Code Button Text'] = new SitecoreTextModel();

    this['Verify Email Code Placeholder'] = new SitecoreTextModel();

    this['Verify Email Message'] = new SitecoreTextModel();

    this['Verify Email Button Text'] = new SitecoreTextModel();

    this['Save Change Button Text'] = new SitecoreTextModel();

    this['New Email Label'] = new SitecoreTextModel();

    this['Change Email Success Page'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Email Success Page')) {
      this['Change Email Success Page'].getData(dataSource['Change Email Success Page']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Verify Code Button Text')) {
      this['Verify Code Button Text'].getData(dataSource['Verify Code Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Send Code Text')) {
      this['Send Code Text'].getData(dataSource['Send Code Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Verify Email Message')) {
      this['Verify Email Message'].getData(dataSource['Verify Email Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Verify Email Code Placeholder')) {
      this['Verify Email Code Placeholder'].getData(dataSource['Verify Email Code Placeholder']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Verify Email Button Text')) {
      this['Verify Email Button Text'] = dataSource['Verify Email Button Text'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Save Change Button Text')) {
      this['Save Change Button Text'] = dataSource['Save Change Button Text'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'New Email Label')) {
      this['New Email Label'] = dataSource['New Email Label'];
    }

    return this;
  }
}
