import { GET_OTP_CODE_FAILURE, GET_OTP_CODE_START, GET_OTP_CODE_SUCCESS } from './types';

export const getOtpCodeStart = (payload) => ({
  type: GET_OTP_CODE_START,
  payload
});

export const getOtpCodeSuccess = (payload) => ({
  type: GET_OTP_CODE_SUCCESS,
  payload
});

export const getOtpCodeFailure = (payload) => ({
  type: GET_OTP_CODE_FAILURE,
  payload
});
