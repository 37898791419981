import { object } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { setTaxMessageStart } from '@redux/actions/taxMessageDetail';
import { deepCopy } from '@utils/utility';

import TaxMessageDetailModel from './TaxMessageDetailModel';

const TaxMessageDetail = ({ fields }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const layoutData = new TaxMessageDetailModel().getData(deepCopy(fields));
    if (layoutData) {
      dispatch(
        setTaxMessageStart({
          isEnable: layoutData?.['Enable Tax Message']?.value,
          taxMessageText: layoutData?.['Tax Message Label']
        })
      );
    }
  }, [fields]);

  return <></>;
};

TaxMessageDetail.propTypes = {
  fields: object
};

export default TaxMessageDetail;
