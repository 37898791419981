import produce from 'immer';

import * as myAddressTypes from '@redux/actions/myAddress/myAddressTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  isLoading: false,
  options: {},
  subFieldNameArray: [],
  isNotFoundAddress: false,
  optionsForMacau: {}
};

const addressDatasourcesReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myAddressTypes.GET_ADDRESS_DATASOURCES:
      draft.isLoading = true;

      draft.isNotFoundAddress = false;
      break;

    case myAddressTypes.GET_ADDRESS_DATASOURCES_SUCCESS:
      draft.isLoading = false; 

      draft.isNotFoundAddress = false;

      if (action.payload.isMacau) {
        draft.optionsForMacau = {
          ...draft.optionsForMacau,
          [action.payload.fieldName]: action.payload.data
        };
      } else {
        draft.options = {
          ...draft.options,
          [action.payload.fieldName]: action.payload.data
        };
      }
      break;

    case myAddressTypes.GET_ADDRESS_DATASOURCES_FAILED:
      draft.isLoading = false;  

      draft.isNotFoundAddress = true;
      break;

    case myAddressTypes.CLEAR_ADDRESS_DATASOURCES:
      if(action.payload) {
        let optionsTmp = deepCopy(draft.options);

        action.payload.map((dataSourceItem) => {
          let fieldName = dataSourceItem['Field Name']?.value;
          if(optionsTmp[fieldName]) {
            delete optionsTmp[fieldName];
          }
        });

        draft.options = optionsTmp;

      } else {
        draft.subFieldNameArray.length = 0;

        draft.options = {};      
      }
      break;

    case myAddressTypes.DISABLE_REQUIRED_FIELD:
      if(action.payload) {
        draft.subFieldNameArray = action.payload.map(item => item.IsRequired && item['Field Name']?.value);
      } else {
        draft.subFieldNameArray.length = 0;
      }
      break;
    }

    return draft;
  });

export default addressDatasourcesReducer;
