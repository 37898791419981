import produce from 'immer';

import * as compareProductTypes from '@redux/actions/compareProduct/compareProductTypes';
import { deepCopy } from '@utils/utility';

import { mapDynamicProductFunctions, mapListProductFunction, mapProductData } from './utils';

const initState = {
  listProducts: [],
  listProductDefault: [],
  isLoading: false,
  productCompareData: [...new Array(3)],
  error: null
};

export const compareProductReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    // GET LIST PRODUCT
    case compareProductTypes.GET_LIST_PRODUCT_START:
      draft.error = null;

      draft.isLoading = true;
      break;

    case compareProductTypes.GET_LIST_PRODUCT_SUCCESS:
      draft.isLoading = false;

      draft.productCompareData = initState.productCompareData;

      draft.listProducts = action.payload.ListProducts;

      draft.listProductDefault = action.payload.ListProductDefault;
      break;

    case compareProductTypes.GET_LIST_PRODUCT_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

      // GET LIST PRODUCT DEFAULT
    case compareProductTypes.GET_LIST_PRODUCT_DEFAULT_START:
      draft.error = null;

      draft.isLoading = true;
      break;

    case compareProductTypes.GET_LIST_PRODUCT_DEFAULT_SUCCESS:
      action.payload = [...mapProductData(action.payload)];

      mapDynamicProductFunctions(action.payload);

      mapListProductFunction(action.payload);

      draft.productCompareData = action.payload;

      draft.isLoading = false;
      break;

    case compareProductTypes.GET_LIST_PRODUCT_DEFAULT_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

      // GET PRODUCT INFO
    case compareProductTypes.GET_PRODUCT_INFO_START:
      draft.error = null;

      draft.isLoading = true;
      break;

    case compareProductTypes.GET_PRODUCT_INFO_SUCCESS:
      draft.isLoading = false;
      /* eslint-disable no-case-declarations */
      const productIndex = action.componentNumber.cNumber;
      if (productIndex >= 0 && action.payload?.ProductSummary) {
        const productCompareDataClone = deepCopy(draft.productCompareData);

        productCompareDataClone[productIndex] = {
          id: action.payload?.ProductSummary?.Id,
          productVariant: action.payload?.ProductSummary?.Variants,
          productInfoDetail: action.payload,
          productVariantActive: action.payload?.ProductSummary?.Variants[0]
        };

        mapDynamicProductFunctions(productCompareDataClone);

        mapListProductFunction(productCompareDataClone);

        draft.productCompareData = productCompareDataClone;
      }
      break;

    case compareProductTypes.GET_PRODUCT_INFO_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

      // REMOVE PRODUCT
    case compareProductTypes.REMOVE_PRODUCT_COMPARE:
      /* eslint-disable no-case-declarations */
      const productCompareDataClone = deepCopy(draft.productCompareData);

      const productNeedRemoveIndex = productCompareDataClone.findIndex((p) => p.id === action.productData.id);

      productCompareDataClone.splice(productNeedRemoveIndex, 1);

      productCompareDataClone[2] = null;

      mapDynamicProductFunctions(productCompareDataClone);

      draft.productCompareData = productCompareDataClone;
      break;

      // SET VARIANT REMOVE PRODUCT
    case compareProductTypes.SET_VARIANT_PRODUCT_COMPARE:
      draft.productCompareData[action.componentNumber].productVariantActive = deepCopy(action.variantItem);
      break;
    }

    return draft;
  });

export default compareProductReducer;
