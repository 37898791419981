import PriceModel from '@components/BundleElements/model/PriceModel';
import ProductCardStockModel from '@components/Product/models/ProductCardStockModel';
import VariantDetailApiModel from '@components/Product/VariantDetail/ProductDetailOverview/models/VariantDetailApiModel';
import LabelsModel from '@models/LabelsModel';
import { deepCopy } from '@utils/utility';

export default class ProductFieldModel {
  constructor() {
    this['CatalogName'] = '';

    this['Description'] = '';

    this['Id'] = '';

    this['Image'] = '';

    this['Labels'] = [];

    this['Price'] = new PriceModel();

    this['ProductId'] = '';

    this['ProductName'] = '';

    this['ProductOverview'] = '';

    this['RepresentativeVariantId'] = '';

    this['Stock'] = new ProductCardStockModel();

    this['Url'] = '';

    this['Variants'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CatalogName')) {
      this['CatalogName'] = dataSource['CatalogName'] ? dataSource['CatalogName'] : this['CatalogName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'] = dataSource['Description'] ? dataSource['Description'] : this['Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ? dataSource['Id'] : this['Id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'] = dataSource['Image'] ? dataSource['Image'] : this['Image'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels')) {
      let tempLabels = [];

      dataSource['Labels'] &&
        dataSource['Labels'].map((label) => {
          tempLabels.push(new LabelsModel().getData(deepCopy(label) || {}));
        });

      this['Labels'] = tempLabels;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this['ProductId'] = dataSource['ProductId'] ? dataSource['ProductId'] : this['ProductId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName')) {
      this['ProductName'] = dataSource['ProductName'] ? dataSource['ProductName'] : this['ProductName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductOverview')) {
      this['ProductOverview'] = dataSource['ProductOverview'] ? dataSource['ProductOverview'] : this['ProductOverview'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RepresentativeVariantId')) {
      this['RepresentativeVariantId'] = dataSource['RepresentativeVariantId']
        ? dataSource['RepresentativeVariantId']
        : this['RepresentativeVariantId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock')) {
      this['Stock'].getData(dataSource['Stock']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Url')) {
      this['Url'] = dataSource['Url'] ? dataSource['Url'] : this['Url'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Variants')) {
      let tempVariants = [];

      dataSource['Variants'] &&
        dataSource['Variants'].map((variant) => {
          tempVariants.push(new VariantDetailApiModel().getData(deepCopy(variant) || {}));
        });

      this['Variants'] = tempVariants;
    }

    return this;
  }
}
