import Proptypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import BuyingBundleAccordion from '@components/Share/BuyingBundleAccordion';

import { WARRANTY_BUNDLE_EVENT_KEY } from '../config';

const BundleBOWarranty = ({dataItem, dataText, onSelectWarrantyHandler, checkValidate}) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  return (
    <Fragment>
      {dataItem.Warranty.length ? (
        <BuyingBundleAccordion
          parentId={dataItem.UniqueProductId}
          data={dataItem.Warranty}
          headerType={'FIRSTTYPE'}
          eventKey={WARRANTY_BUNDLE_EVENT_KEY}
          isOpenItem = {dataItem.isChoseWarranty === true ? false : true}

          onAction = {onSelectWarrantyHandler}
          selectedItemsName = {`${dataItem['Active Warranty'].DisplayName} (${dataItem['Active Warranty'].Price.ListPrice === 0 ? messageObj['LBL-WarrantyFee'] || '' : dataItem['Active Warranty'].Price.ListPriceWithCurrency})`}

          labelText={dataText['Warranty Label'].value} 
          changeText={dataText['Change Text'].value}
          outStockText= {dataText['Out Stock Text'].value}
          errorMsg={dataText['Please Select Your Warranty Message'].value}
          isShowError={checkValidate && !dataItem.isChoseWarranty}
        />
      ) : null}
    </Fragment>
  );
};

BundleBOWarranty.propTypes = {
  dataItem: Proptypes.any,
  dataText: Proptypes.any,
  onSelectWarrantyHandler: Proptypes.any,
  checkValidate: Proptypes.any,
};

export default BundleBOWarranty;