import AddressSettingModel from '@components/SlicingPage/MyAccount/MyAddress/models/AddressSettingModel';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const stringArray = [
  'Apply Filters Button Label',
  'Area Selection Label',
  'Book Experience Button Label',
  'City Selection Label',
  'Clear Filters Button Label',
  'Description',
  'Filter Allow Pick-up Label',
  'Filter City Label',
  'Filter Province Label',
  'Filter Stores Label',
  'Load More Button Label',
  'Location Search Box Placeholder',
  'Message Notification',
  'Province Selection Label',
  'Roadshows Label',
  'Stores Label',
  'Title',
  'Visit Store Button Label',
  'Visit Store Button Link',
  'No Store Satisfying The Selected Fillters Label',
  'Today Label',
  'List Label For Mobile',
  'Map Label For Mobile',
  'All Label',
  'Stores Label',
  'Roadshows Label'
];

const linkArray = [
  'Book Experience Button Link',
  'Visit Store Button Link',
  'Store Locator Link',
  'Stores Link',
  'Roadshows Link'
];

export default class StoreLocatorFieldsModel {
  constructor() {
    this['Icon Marker'] = new SitecoreImageModel();

    this['Address Settings'] = new AddressSettingModel();

    stringArray.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    linkArray.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });
  }
  getData(dataSource) {
    stringArray.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(deepCopy(dataSource[item]) || {});
      }
    });

    linkArray.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon Marker')) {
      this['Icon Marker'].getData(dataSource['Icon Marker']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Settings')) {
      this['Address Settings'].getData(dataSource['Address Settings']?.fields || {});
    }

    return this;
  }
}
