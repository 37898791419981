import React from 'react';
import { Fragment } from 'react';

const StoreLocatorShimmer = () => {

  return (
    <Fragment>
      <div className='store-locator skeleton__item'>
        <div className='store-locator__heading'>
          <div className='container'>
            <h2 className='page-title store-locator__heading__title'>
              <span className='text-line-center w-20'></span>
            </h2>
            <p className='store-locator__heading__desc'>
              <span className='text-line-center w-40'></span>
            </p>
          </div>
        </div>
        <div className='store-locator__content'>
          <div className='container'>
            <div className='store-locator__content--desktop'>
              <div className='row'>
                <div className='col-lg-4 store-locator__list'>
                  <div className='store-locator__content__list'>
                    <div className='store-locator__content__list__search'>
                      <span className='text-line'></span>
                    </div>
                    <div className='store-locator__content__list__store'>
                      <div className='image-wrap'>
                        <span className='image-line'></span>
                      </div>
                      <span className='clear-10'></span>
                      <div className='image-wrap'>
                        <span className='image-line'></span>
                      </div>
                      <span className='clear-10'></span>
                      <div className='image-wrap'>
                        <span className='image-line'></span>
                      </div>
                      <span className='clear-10'></span>
                      <div className='image-wrap'>
                        <span className='image-line'></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-lg-8'>
                  <div className='react-google-maps-api image-wrap'>
                    <span className='image-line'></span>
                  </div>
                </div>
              </div>
            </div>

            <div className='store-locator__content--mobile'>
              <div className='nav nav-tabs'>
                <span className='text-line'></span>
              </div>
              <div className='tab-content'>
                <div className='store-locator__tab-content'>
                  <div className='image-wrap'>
                    <span className='image-line'></span>
                  </div>
                  <span className='clear-10'></span>
                  <div className='image-wrap'>
                    <span className='image-line'></span>
                  </div>
                  <span className='clear-10'></span>
                  <div className='image-wrap'>
                    <span className='image-line'></span>
                  </div>
                  <span className='clear-10'></span>
                  <div className='image-wrap'>
                    <span className='image-line'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default StoreLocatorShimmer;