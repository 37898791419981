import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CartStripAdsModel {
  constructor() {    
    this['Background Color'] = new SitecoreTextModel();

    this['Text Color'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Color')) {
      this['Background Color'].getData(dataSource['Background Color']?.fields?.Value || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Text Color')) {
      this['Text Color'].getData(dataSource['Text Color'] || {});
    }

    return this;
  }
}
