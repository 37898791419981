import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getProductReview } from '@redux/actions/productReview';
import { getCatalogHeaderNavigation } from '@redux/actions/productReview';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useWindowSize } from '@utils/customsHook/useWindowSize';

import ProductReviewContent from '../ProductReviewContent';

const FullReviewListing = ({ fieldsData }) => {
  const CURRENT_PAGE = 1;
  const PAGE_STEP = 1;
  const CURRENT_PAGE_SIZE = 10;
  const PAGE_SIZE_STEP = 10;
  const LIMIT_DISPLAY_OF_REVIEW = 10;
  const NEXT_PAGE = 'NEXT_PAGE';
  const PREVIOUS_PAGE = 'PREVIOUS_PAGE';
  const SHOW_MORE_10 = 'SHOW_MORE_10';
  const PAGE_BOUNDARY = 2;
  const WIDTH_BOUNDARY = 1200;
  const CURRENT_WIDTH = useWindowSize();
  const DATA_IS_FALSE = 'false';

  const dispatch = useDispatch();
  const getProductReviewReducer = useSelector((state) => state.productReviewReducer.getProductReviewReducer);
  const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);
  const [currentPageSize, setCurrentPageSize] = useState(CURRENT_PAGE_SIZE);
  const [totalRecords, setTotalRecords] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(null);
  const [hasPreviousPage, setHasPreviousPage] = useState(null);

  const arrProductReview = getProductReviewReducer?.productReviews?.Items;

  useEffect(() => {
    const allReviewDetailsProduct = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'));

    dispatch(
      getProductReview({
        productId: allReviewDetailsProduct?.productId,
        page: CURRENT_PAGE,
        pageSize: CURRENT_PAGE_SIZE
      })
    );
  }, []);

  useEffect(() => {
    const productReview = getProductReviewReducer?.productReviews;
    if (productReview) {
      setTotalRecords(productReview?.TotalRecords);

      setHasNextPage(productReview?.HasNextPage);

      setHasPreviousPage(productReview?.HasPreviousPage);
    }
  }, [getProductReviewReducer?.productReviews]);

  // CASE: Get data for dropdown menu of single product
  useEffect(() => {
    const isBundleProduct = new URLSearchParams(window.location.search).get('isBundleProduct');
    const productPageId = new URLSearchParams(window.location.search).get('productPageId');

    if (isBundleProduct == DATA_IS_FALSE) {
      dispatch(getCatalogHeaderNavigation(productPageId));
    }
  }, []);

  // CASE: Get data when resize page
  useEffect(() => {
    const allReviewDetailsProduct = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'));

    if (CURRENT_WIDTH && currentPage >= PAGE_BOUNDARY) {
      if (CURRENT_WIDTH < WIDTH_BOUNDARY) {
        dispatch(
          getProductReview({
            productId: allReviewDetailsProduct?.productId,
            page: CURRENT_PAGE,
            pageSize: currentPageSize
          })
        );

        window.scrollTo(0, 0);
      } else if (CURRENT_WIDTH >= WIDTH_BOUNDARY) {
        dispatch(
          getProductReview({
            productId: allReviewDetailsProduct?.productId,
            page: currentPage,
            pageSize: CURRENT_PAGE_SIZE
          })
        );

        window.scrollTo(0, 0);
      }
    }
  }, [CURRENT_WIDTH]);

  const handleClickChangePage = (actionsChange) => {
    const allReviewDetailsProduct = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'));

    if (actionsChange === NEXT_PAGE && hasNextPage) {
      dispatch(
        getProductReview({
          productId: allReviewDetailsProduct?.productId,
          page: currentPage + PAGE_STEP,
          pageSize: CURRENT_PAGE_SIZE
        })
      );

      window.scrollTo(0, 0);

      setCurrentPage((prevState) => prevState + PAGE_STEP);

      setCurrentPageSize((prevState) => prevState + PAGE_SIZE_STEP);
    }

    if (actionsChange === PREVIOUS_PAGE && hasPreviousPage) {
      dispatch(
        getProductReview({
          productId: allReviewDetailsProduct?.productId,
          page: currentPage - PAGE_STEP,
          pageSize: CURRENT_PAGE_SIZE
        })
      );

      window.scrollTo(0, 0);

      setCurrentPage((prevState) => prevState - PAGE_STEP);

      setCurrentPageSize((prevState) => prevState - PAGE_SIZE_STEP);
    }

    if (actionsChange === SHOW_MORE_10 && hasNextPage) {
      dispatch(
        getProductReview({
          productId: allReviewDetailsProduct?.productId,
          page: CURRENT_PAGE,
          pageSize: currentPageSize + PAGE_SIZE_STEP
        })
      );

      setCurrentPage((prevState) => prevState + PAGE_STEP);

      setCurrentPageSize((prevState) => prevState + PAGE_SIZE_STEP);
    }
  };

  return (
    fieldsData && (
      <div className='review-listing review-listing--full'>
        <ul className='review-listing__list'>
          {arrProductReview?.map((review, index) => (
            <li className='review-listing__item' key={index}>
              <ProductReviewContent reviewItem={review} layoutData={fieldsData} />
            </li>
          ))}
        </ul>
        {totalRecords > LIMIT_DISPLAY_OF_REVIEW && (
          <>
            <div className='review-listing__btn review-listing__btn--mobile text-center'>
              <button
                className={`review-listing__btn__item review-listing__btn__item--large btn btn-outline-CTA2 ${!hasNextPage && 'disable-event'}`}
                onClick={() => handleClickChangePage(SHOW_MORE_10)}
              >
                <i className='icon-chevron-down-big'></i>
                <Text tsg='span' field={fieldsData['Show More Text']} />
              </button>
            </div>
            <div className='review-listing__btn review-listing__btn--desktop'>
              <button
                className={`review-listing__btn__item btn btn-outline-CTA2 ${!hasPreviousPage && 'disable-event'}`}
                onClick={() => handleClickChangePage(PREVIOUS_PAGE)}
              >
                <i className='icon-chevron-left-big'></i>
                <Text field={fieldsData['Previous Page Button Text']} />
              </button>
              <button
                className={`review-listing__btn__item btn btn-outline-CTA2 ${!hasNextPage && 'disable-event'}`}
                onClick={() => handleClickChangePage(NEXT_PAGE)}
              >
                <Text field={fieldsData['Next Page Button Text']} />
                <i className='icon-chevron-right-big'></i>
              </button>
            </div>
          </>
        )}
      </div>
    )
  );
};

FullReviewListing.propTypes = {
  fieldsData: PropTypes.any
};

export default FullReviewListing;
