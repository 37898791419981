import { object } from 'prop-types';
import React from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import WarrantyRegistrationThankYouDescription from '../WarrantyRegistrationThankYouDescription';

const ProductWarrantySubmitted = ({ layoutData }) => {
  return (
    <div className='product-thank-you-page'>
      <div className='product-thank-you-page__top'>
        <Image className='product-thank-you-page__top__icon--success' field={layoutData['Tick Icon']} alt={layoutData['Tick Icon'].value.alt} />
        <h3 className='product-thank-you-page__top__title'>
          <Text field={layoutData['Title']} />
        </h3>
        <p className='product-thank-you-page__top__notification'>
          <Text field={layoutData['General Description']} />
        </p>
      </div>
      <div className='product-thank-you-page__bottom'>
        <WarrantyRegistrationThankYouDescription fieldData={layoutData} />
      </div>
    </div>
  );
};

ProductWarrantySubmitted.propTypes = {
  layoutData: object
};

export default ProductWarrantySubmitted;
