import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { behaviorSubjectSubmitForm } from '@components/Form/formBehavior';
import Global from '@utils/global';

import FormFieldError from '../../FormFieldError';
import CustomLabel from '../CustomLabel';

const CustomMultipleLineText = (props) => {
  const [label, setLabel] = useState(false);
  const [border, setBorder] = useState(true);
  const { field, value, onChange, tracker, errors } = props;

  useEffect(() => {
    const behaviorSubjectSubmitFormSubscription = behaviorSubjectSubmitForm.subscribe((params) => {
      setLabel(params.label);
    });

    return () => {
      behaviorSubjectSubmitFormSubscription.unsubscribe();
    };
  }, [behaviorSubjectSubmitForm]);

  const handleOnChange = (field, fieldValue, callback) => {
    let valid = true;
    const errorMessages = [];

    setLabel(fieldValue.length === 0 ? false : true);

    // custom client validation logic here
    if (field.model.required && !fieldValue) {
      valid = false;

      setBorder(false);

      errorMessages.push(`${field.model.title} is required`);
    } else {
      setBorder(true);
    }

    callback(field.valueField.name, fieldValue, valid, errorMessages);
  };

  return (
    <div className={Global.renderDynamicClass(!border, 'form-group', 'input-error-validate')}>
      <textarea
        className={Global.renderDynamicClass(label, 'form-control form-control-lg', 'input-valid')}
        id={field.valueField.id}
        name={field.valueField.name}
        value={value}
        rows={field.model.rows}
        maxLength={field.model.maxLength}
        placeholder={field.model.placeholderText}
        onChange={(e) => handleOnChange(field, e.target.value, onChange)}
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
      />
      <CustomLabel {...props} />
      <FormFieldError {...props} />
    </div>
  );
};

CustomMultipleLineText.propTypes = {
  field: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tracker: PropTypes.any,
  errors: PropTypes.any
};

export default CustomMultipleLineText;
