import './WellBeingProducts.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import SwipeComponent from '@components/Share/SwipeComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import WellBeingProductsModel from './model/WellBeingProductsModel';
import WellBeingProductItem from './WellBeingProductItem';

const MIN_ITEM_SWIPER = 3;

const WellBeingProducts = ({ fields }) => {
  const data = fields['Well-being Products'] || [];
  const [dataFields, setDataFields] = useState(null);

  useEffect(() => {
    const wellBeingProductsModel = new WellBeingProductsModel();

    setDataFields(wellBeingProductsModel.getData(deepCopy(fields) || {}));
  }, []);

  const params = {
    lazy: true,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 15,
    // rebuildOnUpdate: true,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    breakpoints: {
      992: {
        slidesPerView: 4,
        spaceBetween: 30,
        navigation: {
          nextEl: '.well-being-products .swiper-button-next',
          prevEl: '.well-being-products .swiper-button-prev'
        }
      },
      767: {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
          nextEl: '.well-being-products .swiper-button-next',
          prevEl: '.well-being-products .swiper-button-prev'
        }
      }
    }
  };

  const setClassCenter = () => {
    if (dataFields['Well-being Products'].length > 0 && dataFields['Well-being Products'].length < MIN_ITEM_SWIPER) {
      return 'center-item';
    } else if (dataFields['Well-being Products'].length == MIN_ITEM_SWIPER) {
      return 'center-item-desktop';
    } else {
      return '';
    }
  };

  return dataFields ? (
    <div className='well-being-products animated-slow fadeIn delay-100'>
      <div className='container no-padding-right-mobile'>
        <Text field={dataFields.Title} className='well-being-products__title text-center' tag='h2' />
        <div className={'well-being-products__swiper ' + setClassCenter()}>
          <SwipeComponent param={params}>
            {dataFields['Well-being Products'].length ? (
              dataFields['Well-being Products'].map((productItem, index) => {
                return (
                  <div className='swiper-slide well-being-product-item' key={productItem.Id}>
                    <WellBeingProductItem data={productItem} dataFields={dataFields} indexGA4={index}/>
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </SwipeComponent>
          {data.length > 4 ? (
            <>
              <div className='swiper-button-next'></div>
              <div className='swiper-button-prev'></div>
            </>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className='well-being-products skeleton__item'>
      <div className='container'>
        <h2 className='well-being-products__title text-center'>
          <span className='text-line-center w-50'></span>
        </h2>
        <div className='well-being-products__swiper'>
          <LoadingShimmer itemNumb={2}>
            <div className='skeleton__item__container'>
              <div className='image-wrap'>
                <span className='image-line'></span>
              </div>
              <span className='clear-20'></span>
              <span className='text-line'></span>
              <span className='clear-15'></span>
              <span className='text-line'></span>
              <span className='clear-15'></span>
              <span className='button-line-center w-50'></span>
            </div>
          </LoadingShimmer>
          <div className='skeleton__item__container hidden-xs-down'>
            <div className='image-wrap'>
              <span className='image-line'></span>
            </div>
            <span className='clear-20'></span>
            <span className='text-line'></span>
            <span className='clear-15'></span>
            <span className='text-line'></span>
            <span className='clear-15'></span>
            <span className='button-line-center w-50'></span>
          </div>
          <div className='skeleton__item__container hidden-md-down'>
            <div className='image-wrap'>
              <span className='image-line'></span>
            </div>
            <span className='clear-20'></span>
            <span className='text-line'></span>
            <span className='clear-15'></span>
            <span className='text-line'></span>
            <span className='clear-15'></span>
            <span className='button-line-center w-50'></span>
          </div>
        </div>
      </div>
    </div>
  );
};

WellBeingProducts.propTypes = {
  fields: PropTypes.object
};

export default WellBeingProducts;
