import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TextArray = [
  'title',
  'description',
  'appointment',
  'youAreOneStepText',
  'youAreOneStepDescription',
  'termsAndConditionsAgreementText',
  'pdpaCopyAndTermsText',
  'registerAccountText',
  'continueShoppingText',
  'emailAddressText',
  'dateOfBirthText',
  'genderText',
  'passwordText',
  'receivingMarketingInformationText',
  'smsMarketingChannelLabel',
  'emailMarketingChannelLabel',
  'directMailingMarketingChannelLabel',
  'guestsLabel'
];

const LinkArray = [
  'icon',
  'backgroundDesktop',
  'backgroundMobile',
  'ctaButtonLink',
  'registerAccountLink',
  'continueShoppingLink',
  'ctaButtonLink'
];

export default class AppointmentBookedComponentModel {
  constructor() {
    TextArray.forEach((text)=> {
      this[text] = new SitecoreTextModel();
    });

    LinkArray.forEach((link)=> {
      this[link] = new SitecoreLinkModel();
    });

    this['appointment'] = {};

    this['campaign'] = {};

    this['smsMarketingChannelChecked'] = false;
  }

  getData(dataSource) {
    TextArray.forEach((text)=> {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text] = dataSource[text] ?? '';
      }
    });

    LinkArray.forEach((link)=> {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link] = dataSource[link] ?? '';
      }
    });

    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'appointment')) {
      this['appointment'] = dataSource['appointment'];
    }

    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'campaign')) {
      this['campaign'] = dataSource['campaign'];
    }

    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'smsMarketingChannelChecked')) {
      this['smsMarketingChannelChecked'] = dataSource['smsMarketingChannelChecked'];
    }
  
    return this;
  }
}
