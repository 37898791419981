import global from '@utils/global';

import GoogleAnalytic from '../Share/GoogleAnalytic';

export const POPUP_BANNER_EVENT_NAME = 'popup_banner';

export const POPUP_BANNER_CLOSE_ACTION_NAME = 'close';

export const POPUP_BANNER_VIEW_ACTION_NAME = 'view';

export const POPUP_BANNER_TYPE_IMAGE = 'image type';

export const POPUP_BANNER_TYPE_VIDEO = 'video type';

export const handleTrackingPopBannerEvent = (fieldData, action) => {
  const isGA4 = global.getIsGA4();
  if (isGA4) {
    const bannerType =
      fieldData?.['Video']?.value?.href?.length || fieldData['Youtube Video ID'].value.length || fieldData['Vimeo Video ID'].value.length
        ? POPUP_BANNER_TYPE_VIDEO
        : POPUP_BANNER_TYPE_IMAGE;
    const pageUrl = window.location.href;

    new GoogleAnalytic().gAnalytic4('event', POPUP_BANNER_EVENT_NAME, {
      banner_name: fieldData?.['Banner Name'] || '',
      banner_type: bannerType,
      page_url: pageUrl,
      page_type: '',
      action: action
    });
  }
};
