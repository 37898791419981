import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { linkCustomerViewPage } from '@components/ClientPortal/CustomerProfiles/CustomerProfileContainer';
import { useFormatDate } from '@utils/customsHook/useFormatDate';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { ANSWER_WITH_BRACKET_TYPE } from '../../config';
import BoxData from '../BoxData';
import ProductInterestModel from './model/ProductInterestModel';
import ProductInterestShimmer from './ProductInterestShimmer';

const MAX_PRODUCT_INTEREST = 4;
const SOCIAL_ANSWER_QUESTION_CODE = 'HDCGTKTP';

const ProductInterest = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  const cpCustomerProduct = clientTellingPortalCustomer?.currentCustomerDetail?.CPCustomerInterest?.Products;
  const formatDateUtil = useFormatDate();

  useEffect(() => {
    const model = new ProductInterestModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  const checkProductInterestCaptured = (product) => {
    let requiredQuestions = {};
    let requiredQuestionsHaveAnswer = {};

    const questionsAfterClearEmptyObj = Global.removeEmptyObj(product.Questions);

    questionsAfterClearEmptyObj.forEach((val) => {
      if (val?.IsRequired) {
        requiredQuestions['count'] = (requiredQuestions['count'] || 0) + 1;
        if (val?.Answer?.length) {
          requiredQuestionsHaveAnswer['count'] = (requiredQuestionsHaveAnswer['count'] || 0) + 1;
        }
      }
    });

    return 'count' in requiredQuestions ? requiredQuestions.count === requiredQuestionsHaveAnswer.count : false;

    // return Global.removeEmptyObj(product?.Questions)?.length &&
    // Global.removeEmptyObj(product?.Questions).every((quest) => quest?.Answer?.length);
  };

  const getProductInterest = () => {
    // return cpCustomerProduct?.filter((product) => checkProductInterestCaptured(product));
    return cpCustomerProduct?.filter((product) => checkProductInterestCaptured(product));
  };

  const socialNetworkAnswerSelected = (product) => {
    const findSocialQuestion = product?.Questions?.find((question) => question.QuestionCode === SOCIAL_ANSWER_QUESTION_CODE);

    return findSocialQuestion?.Answer?.includes(ANSWER_WITH_BRACKET_TYPE)
      ? findSocialQuestion?.Answer?.replaceAll(ANSWER_WITH_BRACKET_TYPE, '')?.trim()
      : findSocialQuestion?.Answer;
  };

  return layoutData ? (
    <div className='col-md-6 profiles__col'>
      {!cpCustomerProduct ? (
        <ProductInterestShimmer title={layoutData?.['Section Title']} />
      ) : (
        <BoxData
          component='product-interest'
          layoutData={layoutData}
          data={getProductInterest()}
          isShowNotice={false}
          linkPage={linkCustomerViewPage(layoutData)}
        >
          {getProductInterest()?.map((p, index) => {
            return index < MAX_PRODUCT_INTEREST ? (
              <div className='box-data__row' key={p?.ProductId}>
                <div className='box-data__col-1'>
                  <div className='box-data__title --sub'>{p?.ProductName}</div>
                  <p className='box-data__notice'>{`${layoutData['Captured On Text'].value} ${formatDateUtil(p?.DateCreated)}`}</p>
                </div>
                <div className='box-data__col-2'>
                  {socialNetworkAnswerSelected(p) ? (
                    <div className='highlight-text highlight-text--radius'>
                      <span>{socialNetworkAnswerSelected(p)}</span>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              ''
            );
          })}
        </BoxData>
      )}
    </div>
  ) : (
    <></>
  );
};

ProductInterest.propTypes = {
  fields: object
};

export default ProductInterest;
