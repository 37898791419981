import { ofType } from 'redux-observable';
import { from, merge, of } from 'rxjs';
import { concatMap, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/coupon';
import * as couponTypes from '@redux/actions/coupon/couponTypes';
import { get } from '@services/genericService';
import {getAsObservable} from '@services/genericService';
import { deepCopy } from '@utils/utility';

import {getCartNameValue} from '../../product/cartDetail/utils';
import * as config from './config';

export const couponsListEpic = (action$) =>
  action$.pipe(
    ofType(couponTypes.GET_BROWSE_COUPON_LIST),
    concatMap((action) => {
      let params = {};
      if (action.payload?.cartId) params.cartId = action.payload.cartId;

      return from(getAsObservable(config.BROWSE, params)).pipe(
        concatMap((res) => {
          if (res.status === 200) {
            let tempMap;
            if (res.data.Success) {
              tempMap = actions.getBrowseCouponListSuccess(deepCopy(res.data));
            } else {
              tempMap = actions.getBrowseCouponListFailed('Failed');
            }
            let params = {};
            const cartId = getCartNameValue();
            if (cartId) params.cartId = cartId;

            return merge(
              of(tempMap),
              from(get(config.GET_APPLIED, params)).pipe(
                map((res) => {
                  if (res.status === 200) {
                    return actions.getAppliedCouponListSuccess(deepCopy(res.data));
                  } else {
                    return actions.getAppliedCouponListFailed('Failed');
                  }
                })
              )
            );
          }
        })
      );
    })
  );

export const appliedCouponsListEpic = (action$) =>
  action$.pipe(
    ofType(couponTypes.GET_APPLIED_COUPON_LIST),
    switchMap(() => {
      let params = {};
      const cartId = getCartNameValue();
      if (cartId) params.cartId = cartId;

      return from(get(config.GET_APPLIED, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getAppliedCouponListSuccess(deepCopy(res.data));
          } else {
            return actions.getAppliedCouponListFailed('Failed');
          }
        })
      );
    })
  );
