import { combineReducers } from 'redux';

import applyCouponSpinReducer from './applyCouponSpin';
import getAvailableLuckyDrawReducer from './getAvailableLuckyDraw';
import getChanceToWinReducer from './getChanceToWin';
import getMyPrizeLuckyDrawReducer from './getMyPrizeLuckyDraw';
import getNumberSpinEmailReducer from './getNumberSpinEmail';

const spinAndWinReducer = combineReducers({
  getAvailableLuckyDrawReducer,
  getNumberSpinEmailReducer,
  getMyPrizeLuckyDrawReducer,
  getChanceToWinReducer,
  applyCouponSpinReducer
});

export default spinAndWinReducer;
