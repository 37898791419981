import './Marquee.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

import { Image, Link } from '@sitecore-jss/sitecore-jss-react';

const LINK_DEFAULT = {
  value: {
    id: '',
    href: '',
    text: '',
    src: ''
  }
};

const IMG_DEFAULT = {
  value: {
    src: '',
    alt: 'No images'
  }
};

const Marquee = ({ arrayMarquee }) => {
  const marqueeRef = useRef(null);
  const marqueeContentRef = useRef(null);

  const renderMarqueeItem = () => {
    const styles = marqueeRef.current.style;

    const computed = window.getComputedStyle(marqueeRef.current).getPropertyValue('--marquee-elements-displayed');

    styles.setProperty('--marquee-elements', arrayMarquee?.length);

    for (let i = 0; i < computed; i++) {
      marqueeContentRef.current.appendChild(marqueeContentRef.current.children[i].cloneNode(true));
    }
  };

  useEffect(() => {
    if (marqueeContentRef.current) renderMarqueeItem();
  }, [marqueeContentRef.current]);

  return arrayMarquee && arrayMarquee.length ? (
    <div className='marquee' ref={marqueeRef}>
      <ul className='marquee-content' ref={marqueeContentRef}>
        {arrayMarquee?.map((marqueeItem, index) => {
          return (
            <li key={index} className='marquee-content__item'>
              {marqueeItem?.fields?.['Banner Click Hyperlink']?.value?.href == '' ? (
                <Image field={marqueeItem?.fields?.['Background Image']?.value ? marqueeItem?.fields?.['Background Image'] : IMG_DEFAULT} />
              ) : (
                <Link title='marquee content link' field={marqueeItem?.fields?.['Banner Click Hyperlink']?.value ? marqueeItem?.fields?.['Banner Click Hyperlink'] : LINK_DEFAULT}>
                  <Image field={marqueeItem?.fields?.['Background Image']?.value ? marqueeItem?.fields?.['Background Image'] : IMG_DEFAULT} />
                </Link>
              )}
            </li>
          );
        })}
      </ul>
    </div>
  ) : (
    <></>
  );
};

Marquee.propTypes = {
  arrayMarquee: PropTypes.any
};

export default Marquee;
