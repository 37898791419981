// Using bootstrap is completely optional. It's used here to provide a clean layout for samples,
// without needing extra CSS in the sample app. Remove it in package.json as well if it's removed here.
// import 'bootstrap/dist/css/bootstrap.css';
import '@assets/app.css';

import PropTypes, { object } from 'prop-types';
import React, { useEffect, useLayoutEffect, useMemo } from 'react';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { getMessage } from '@redux/actions/message';
import {getSettingGlobal} from '@redux/actions/settingGlobal';
import {getSettingGlobalURL} from '@redux/actions/settingGlobalURL';
import { getUserProfile } from '@redux/actions/singleSignOn';
import { checkCPLoginStatus,checkLoginStatus } from '@redux/actions/singleSignOn';
import {settingGlobalBehavior} from '@redux/epic/settingGlobal/settingGlobalBehavior';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Placeholder, Text, VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
/*
  APP LAYOUT
  This is where the app's HTML structure and root placeholders should be defined.

  All routes share this root layout by default (this could be customized in RouteHandler),
  but components added to inner placeholders are route-specific.
*/

const Layout = (props) => {
  const dispatch = useDispatch();
  const accountUser = useSelector((state) => state.singleSignOnReducer.userInfoReducer.accountUser);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const user = SitecoreContextFactoryService.getValueContextItem('user');
  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');
  const customerId = SitecoreContextFactoryService.getValueContextItem('CustomerId');
  const isGA4 = Global.getIsGA4();
  const contextRouteItem = SitecoreContextFactoryService.getValueContextRouteItem('fields');
  const h1TagValue = contextRouteItem?.['Title'] || contextRouteItem?.['Page Title'];
  const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');

  const htmlMetadata = useMemo(() => {
    const htmlMetadataString = contextRouteItem?.['Html Metadata']?.value;

    return htmlMetadataString ? htmlMetadataString.split('&') : [];
  }, [contextRouteItem]);

  useEffect(() => {
    if(!settingGlobal) {
      dispatch(getSettingGlobal());
    }
  }, [settingGlobal]);

  useEffect(() => {    
    settingGlobalBehavior.subscribe((params) => {
      if(params.AuthorizationFailed) {
        window.location.href = (settingGlobal?.LoginLink || '/login') + `?from=${window.location.href}`;
      }
    });    
  }, [settingGlobalBehavior]);

  useEffect(() => {        
    dispatch(getSettingGlobalURL());

    dispatch(checkLoginStatus({user: user,  isEditing: props.isEditing}));

    dispatch(checkCPLoginStatus({user: user,  isEditing: props.isEditing}));

    dispatch(getMessage(props.messages));

    if (user && !props.isEditing && (!accountUser || (accountUser && !accountUser.Username.includes(user?.name)))) {
      dispatch(getUserProfile());
    }
  }, []);

  //New Logic ON/OFF GA, GA4
  useEffect(() => {
    if (isGA4) {
      let params = {
        user_id: user?.name || null,
        customer_id: customerId,
        user_type: IsAuthenticated ? 'registered' : 'guest'
      };
      
      new GoogleAnalytic().gAnalytic4('event', 'user_id', params);
    }
  }, []);

  useLayoutEffect(() => {
    if (htmlMetadata.length) {
      const htmlTag = document.getElementsByTagName('html')?.[0];
      if (htmlTag) {
        for (const metadata of htmlMetadata) {
          const metadataConvert = metadata.split('=');

          htmlTag.setAttribute(metadataConvert[0], metadataConvert[1]);
        }
      }
    }
  }, [htmlMetadata]);

  return (
    <React.Fragment>
      {/* react-helmet enables setting <head> contents, like title and OG meta tags */}

      {/*
      VisitorIdentification is necessary for Sitecore Analytics to determine if the visitor is a robot.
      If Sitecore XP (with xConnect/xDB) is used, this is required or else analytics will not be collected for the JSS app.
      For XM (CMS-only) apps, this should be removed.

      VI detection only runs once for a given analytics ID, so this is not a recurring operation once cookies are established.
    */}
      <VisitorIdentification />

      {/* root placeholder for the app, which we add components to using route data */}
      <div className={`main-container ${props.isEditing ? 'edit-mode' : ''}`}>
        {(templateName !== 'Product Page' && templateName !== 'Category Page') && (
          <Text tag='h1' field={h1TagValue} className='visually-hidden' />
        )}
        <Placeholder name='osim-top-head' rendering={props.route} />
        <Placeholder name='osim-head' rendering={props.route} />
        <Placeholder name='osim-header' rendering={props.route} />
        <div>
          <Placeholder name='osim-main-top' rendering={props.route} />
          <Placeholder name='osim-main' rendering={props.route} />
          <Placeholder name='osim-main-bottom' rendering={props.route} />
        </div>
        <Placeholder name='osim-footer' rendering={props.route} />
        <Placeholder name='osim-end' rendering={props.route} />
      </div>
    </React.Fragment>
  );
};

Layout.propTypes = {
  route: PropTypes.object,
  isEditing: PropTypes.any,
  messages: object
};

export default Layout;
