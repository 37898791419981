export const DEFAULT_CURRENT_LOGS_PAGE = 0;

export const DEFAULT_LOG_PER_VIEW = 10;

export const DELAY_TIME_TO_CALL_ACTIONS=3000;

export const defaultFetchLogsParams = {
  page: DEFAULT_CURRENT_LOGS_PAGE + 1,
  pageSize: DEFAULT_LOG_PER_VIEW
};
