import React from 'react';

const RichTextShimmer = () => {
  return (
    <div className='rich-text-content skeleton__item'>
      <div className='container'>
        <div className='add-to-cart__body image-wrap'>
          <div className='image-line'></div>
        </div>
      </div>
    </div>
  );
};

export default RichTextShimmer;
