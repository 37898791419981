import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import PhoneNumberSelect from '@components/Share/PhoneNumberSelect';
import ChangeAreaModel from '@components/SlicingPage/CartDetails/CartSummary/model/ChangeAreaModel';
import { clearAddressDatasources, disableRequiredField, getAddressDatasources } from '@redux/actions/myAddress';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { DELIVERY_OPTION, MAXIMUM_ADDRESS_LENGTH } from '@utils/constant';
import { SG_SITE_NAME, TH_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import { addressKeys } from './configs';
import { AddressService, config } from './service';

const AddressForMultiLanguage = (props) => {
  const {
    dataFields,
    setSubmitAddressEvt,
    isShippingAddressFullfillment,
    addressCurrent,
    options,
    optionsForMacau,
    settingGlobal,
    addressListReducer,
    objMessages,
    isNotFoundAddress,
    subFieldNameArray,
    isAddNew,
    isLoadingGetDataSource,
    cartLineData,
    type,
    macauAddressCurrent
  } = props;
  const addressElements = dataFields['Address Settings']['Address Elements'];
  const dispatch = useDispatch();

  const [fieldNameDropdownArr] = useState(addressElements.filter((address) => address.Type.value === config.TypeElement.Dropdown));
  const [fieldNameRequired] = useState(addressElements.filter((item) => item.IsRequired));
  const phoneField = addressElements.find((address) => address.Type.value === config.TypeElement.Phone);
  const [valueSelected, setValueSelected] = useState({});
  const [optionDropdown, setOption] = useState({});
  const [pevSubmitCount, setSubmitCount] = useState(0);
  const fieldPhoneCode = useRef(settingGlobal.AreaCodes[0].value);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;

  const [modelSubmit, setModelSubmit] = useState({
    isSubmit: false,
    errMessage: ''
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const formik = useFormik({
    initialValues: Object.assign(
      AddressService.initialValuesAddress(
        addressElements,
        addressCurrent,
        dataFields?.['Area Shipping Fee Setting']?.['Address Elements'],
        settingGlobal,
        cartLineData,
        type,
        optionsForMacau,
        options
      )
    ),
    validationSchema: AddressService.validateFileld(fieldNameRequired, settingGlobal, isNotFoundAddress, messageObj, options, addressCurrent),
    onSubmit: (values) => handleSubmit(values),
    validateOnMount: true,
    enableReinitialize: isFirstLoad
  });

  const addressReachMaxAllowLength = useMemo(() => {
    const values = formik.values;
    let invalidAddress = {};

    Object.keys(values).forEach((key) => {
      if (addressKeys.includes(key)) {
        if (values[key].length > MAXIMUM_ADDRESS_LENGTH) invalidAddress[key] = dataFields?.['PopUp Limit Character Address Message']?.value;
      }
    });

    return invalidAddress;
  }, [formik.values, dataFields]);

  function handleSubmit(values) {
    let isSubmit = true;
    if (currentSiteName === SG_SITE_NAME && Object.keys(addressReachMaxAllowLength).length) isSubmit = false;

    if (isSubmit) {
      // INFO: not allow submi when address length larger than 50
      let optionDropdownEdit = {};
      const listFieldName = addressElements.map((fielName) => fielName[config.FieldNameModel].value);
      const addressModel = new ChangeAreaModel(listFieldName).getData(listFieldName, addressCurrent);

      setModelSubmit({
        ...modelSubmit,
        isSubmit: true
      });

      fieldNameDropdownArr.map((select) => {
        if (values[select[config.FieldNameModel].value]?.value) {
          optionDropdownEdit = Object.assign(optionDropdownEdit, {
            [select[config.FieldNameModel].value]: values[select[config.FieldNameModel].value].label,
            [`${select[config.FieldNameModel].value}Code`]: values[select[config.FieldNameModel].value].value
          });
        }
      });

      setSubmitAddressEvt({
        ...addressModel,
        ...values,
        ...optionDropdown,
        ...optionDropdownEdit,
        [`${phoneField[config.FieldNameModel].value}`]: `${fieldPhoneCode.current}${values[phoneField[config.FieldNameModel].value]}`
      });
    }
  }

  useEffect(() => {
    if (fieldNameDropdownArr?.length) {
      dispatch(
        getAddressDatasources({
          model: {
            CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
            RootValue: '',
            RootType: '',
            ChildType: '',
            RootParentValue: ''
          },
          fieldName: fieldNameDropdownArr[0][config.FieldNameModel].value,
          condition: null,
          isMacau: type == 'delivery-by-sf-express' ? true : false
        })
      );
      if (addressCurrent) {
        for (let index = 0; index < fieldNameDropdownArr.length - 1; index++) {
          const rootValue =
            fieldNameDropdownArr[index][config.FieldNameModel].value == 'City' && type == 'delivery-by-sf-express'
              ? macauAddressCurrent?.DeliveryOptions?.find((option) => option?.ShippingOptionType?.Value == DELIVERY_OPTION.DeliveryBySFExpress)
                ?.AddressRootCode
              : addressCurrent[`${fieldNameDropdownArr[index][config.FieldNameModel].value}Code`];
          const rootType = fieldNameDropdownArr[index][config.FieldNameModel].value;
          const rootParentValue = addressCurrent[`${fieldNameDropdownArr?.[index-1]?.[config.FieldNameModel]?.value}Code`] || '';

          if (rootValue) {
            dispatch(
              getAddressDatasources({
                model: {
                  CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
                  RootValue: rootValue,
                  RootType: rootType,
                  ChildType: '',
                  RootParentValue: rootParentValue
                },
                fieldName: fieldNameDropdownArr[index + 1][config.FieldNameModel].value,
                condition: fieldNameDropdownArr[index].Condition
              })
            );
          } else {
            const optionMustReset = fieldNameDropdownArr.slice(index);

            dispatch(disableRequiredField(optionMustReset));
            break;
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    // INFO: initial data for address selected
    if (!Object.keys(valueSelected).length && Object.keys(options).length) {
      let valueSelectedTemp = {};
      for (const [key, optionObject] of Object.entries(options)) {
        if (optionObject && formik.values[key]) {
          const optionData = optionObject.find((option) => option.value === formik.values[key].value);

          if (optionData) {
            valueSelectedTemp[key] = { ...optionData };
          }
        }
      }

      setValueSelected(valueSelectedTemp);
    }
  }, [formik.values, options]);

  // useEffect(() => {
  //   if (subFieldNameArray.length && isNotFoundAddress) {
  //     subFieldNameArray.map((item) => {
  //       formik.setFieldValue(item, 'sdf');
  //     });
  //   }
  // }, [subFieldNameArray, isNotFoundAddress]);

  useDidUpdateEffect(() => {
    if (Object.keys(formik.errors).length && !formik.isSubmitting && formik.submitCount > pevSubmitCount && !formik.isValid) {
      setSubmitCount(formik.submitCount);
      const keys = Object.keys(formik.errors);
      const errorElement = document.getElementById(keys[0]);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
      }
    }
  }, [formik]);

  useEffect(() => {
    const currentCode = formik.values[`${phoneField[config.FieldNameModel].value}Code`];
    if (currentCode) {
      fieldPhoneCode.current = currentCode;
    }
  }, [formik.values[`${phoneField[config.FieldNameModel].value}Code`]]);

  useDidUpdateEffect(() => {
    if (addressListReducer.error) {
      setModelSubmit({
        ...modelSubmit,
        isSubmit: false,
        errMessage: addressListReducer.error
      });
    }
  }, [addressListReducer.toogleCheck]);

  // useDidUpdateEffect(() => {
  //   if (subFieldNameArray.length && isNotFoundAddress) {
  //     subFieldNameArray.map((item) => {
  //       formik.setFieldValue(item, 'item');
  //     });
  //   }
  // }, [subFieldNameArray, isNotFoundAddress]);

  const onChangeAreaHandler = (params) => {
    const inputName = params.inputField[config.FieldNameModel].value;
    if (params.option.value !== formik.values[inputName]) {
      setIsFirstLoad(false);
      let optionMustResetObj = {};

      const optionCurrent = fieldNameDropdownArr.find((address) => address[config.FieldNameModel].value === inputName);
      const optionMustReset = fieldNameDropdownArr.filter((address) => address.index > params.index);
      if (!optionMustReset.length) {
        dispatch(disableRequiredField());

        setValueSelected({
          ...valueSelected,
          [inputName]: params.option
        });
      } else {
        dispatch(disableRequiredField(optionMustReset));

        dispatch(clearAddressDatasources(optionMustReset));

        optionMustReset.map((item) => {
          optionMustResetObj = Object.assign(optionMustResetObj, {
            [item[config.FieldNameModel].value]: '',
            [`${item[config.FieldNameModel].value}Code`]: ''
          });

          formik.setFieldValue([item[config.FieldNameModel].value], '');
        });

        setValueSelected({
          ...valueSelected,
          ...optionMustResetObj,
          [inputName]: params.option
        });
      }

      setOption({
        ...optionDropdown,
        [optionCurrent[config.FieldNameModel].value]: params.option.label,
        [`${optionCurrent[config.FieldNameModel].value}Code`]: params.option.value,
        ...optionMustResetObj
      });

      if (inputName !== fieldNameDropdownArr[fieldNameDropdownArr.length - 1][config.FieldNameModel].value) {
        const filterNameList = fieldNameDropdownArr.map((dropdownName) => dropdownName[config.FieldNameModel].value);
        const childTypeIndex = filterNameList.indexOf(inputName) + 1;
        let rootAddressParentValue='';

        for (const fieldItem of fieldNameDropdownArr) {
          const fieldNameValue = fieldItem[config.FieldNameModel]?.value || '';

          if (fieldNameValue === params.inputField?.[config.FieldNameModel]?.value) {
            if (fieldItem.Condition) {
              const conditionFieldNameValue = fieldItem.Condition[config.FieldNameModel]?.value || '';
              if (valueSelected?.[conditionFieldNameValue]) {
                rootAddressParentValue = valueSelected[conditionFieldNameValue]?.value || '';
              }
            }
          }
        }

        dispatch(
          getAddressDatasources({
            model: {
              CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
              RootValue: params.option.value,
              RootType: inputName,
              ChildType: '',
              RootParentValue: rootAddressParentValue
            },
            fieldName: Object.keys(options).includes(params.fieldName)
              ? params.fieldName
              : fieldNameDropdownArr[childTypeIndex][config.FieldNameModel].value,
            condition: addressElements.filter((address) => address[config.FieldNameModel] === params.fieldName)?.Condition
          })
        );
      }

      formik.setFieldValue(inputName, params.option.value);
    }
  };

  const onHandleTelephoneChange = (evt, fieldName) => {
    let telephone = evt.target.value;

    if (!telephone || new RegExp(settingGlobal.PhoneNumberFormat).test(telephone)) {
      formik.setFieldValue(fieldName, telephone);
    }
  };

  const onHandleZipcodeChange = (evt, fieldName) => {
    let zipcode = evt.target.value;

    if (!zipcode || new RegExp(settingGlobal.ZipCodeNumberFormat).test(zipcode)) {
      formik.setFieldValue(fieldName, zipcode);
    }
  };

  const handleAddressInput = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;

    if (addressKeys.includes(inputName) && currentSiteName !== TH_SITE_NAME) {
      if (!addressReachMaxAllowLength[inputName]) {
        if (inputValue.length <= MAXIMUM_ADDRESS_LENGTH) formik.setFieldValue(inputName, inputValue);
      } else {
        formik.setFieldValue(inputName, inputValue);
      }
    } else {
      formik.setFieldValue(inputName, inputValue);
    }
  };

  const isDisableThirdAddress = useCallback(
    (key) => {
      if (key === addressKeys[2]) {
        return !formik.values?.[addressKeys[1]];
      }
    },
    [formik, addressKeys]
  );

  return (
    <form onSubmit={formik.handleSubmit} className='store-locator__modal store-locator__modal--has-scroll' id='add-addresses'>
      {!modelSubmit.isSubmit && modelSubmit.errMessage ? <span className='error-message text-center'>{modelSubmit.errMessage}</span> : ''}
      <div className='user-addresses'>
        {addressElements.map((inputField, inputFieldIdx) => {
          const key = inputField[config.FieldNameModel].value;

          const isValid = formik.errors[key] && formik.touched[key];
          if (inputField.Type.value === config.TypeElement.Text) {
            return (
              <div className={Global.renderDynamicClass(isValid, 'form-group', 'input-error-validate')} key={inputFieldIdx} id={key}>
                <input
                  onChange={handleAddressInput}
                  value={formik.values[key]}
                  type='text'
                  className={Global.renderDynamicClass(formik.values[key], 'form-control form-control-lg', 'input-valid')}
                  name={key}
                  placeholder={inputField['Display Name'].value}
                  disabled={isDisableThirdAddress(key)}
                />
                <label htmlFor={key} className='form-group__label'>
                  {inputField['Display Name'].value}
                </label>
                {isValid && (
                  <span className='error-validate'>
                    {objMessages['Msg_16'].replace(config.FieldNameValidate, inputField[config.DisplayNameModel].value)}
                  </span>
                )}
                {addressReachMaxAllowLength[key] ? <span className='error-validate'>{addressReachMaxAllowLength[key]}</span> : <></>}
              </div>
            );
          }
          if (inputField.Type.value === config.TypeElement.Phone) {
            const cssClass = Global.renderDynamicClass(isValid, 'form-group', 'input-error-validate');

            return (
              <div className={settingGlobal.UseAreaCode ? `${cssClass} form-group--group-select-input` : cssClass} key={inputFieldIdx} id={key}>
                {settingGlobal.UseAreaCode ? (
                  <PhoneNumberSelect
                    optionValues={settingGlobal.AreaCodes}
                    setPhoneNumberCodeEvt={(code) => (fieldPhoneCode.current = code)}
                    currentValue={formik.values[`${key}Code`]}
                  />
                ) : (
                  ''
                )}
                <div className='form-group--input'>
                  <input
                    onChange={(evt) => onHandleTelephoneChange(evt, key)}
                    value={formik.values[key]}
                    type='text'
                    className={Global.renderDynamicClass(formik.values[key], 'form-control form-control-lg', 'input-valid')}
                    name={key}
                    placeholder={inputField['Display Name'].value}
                  />

                  <label htmlFor={key} className='form-group__label'>
                    {inputField['Display Name'].value}
                  </label>
                  {isValid && (
                    <span className='error-validate'>
                      {!formik.values[key] ? (
                        objMessages['Msg_16'].replace(config.FieldNameValidate, inputField[config.DisplayNameModel].value)
                      ) : (
                        <Message messageCode='Msg_001.7' />
                      )}
                    </span>
                  )}
                </div>
              </div>
            );
          }
          if (inputField.Type.value === config.TypeElement.Zipcode) {
            return (
              <div className={Global.renderDynamicClass(isValid, 'form-group', 'input-error-validate')} key={inputFieldIdx} id={key}>
                <input
                  onChange={(evt) => onHandleZipcodeChange(evt, key)}
                  value={formik.values[key]}
                  type='text'
                  className={Global.renderDynamicClass(formik.values[key], 'form-control form-control-lg', 'input-valid')}
                  name={key}
                  placeholder={inputField['Display Name'].value}
                />
                <label htmlFor={key} className='form-group__label'>
                  {inputField['Display Name'].value}
                </label>
                {isValid && (
                  <span className='error-validate'>
                    {!formik.values[key] ? (
                      objMessages['Msg_16'].replace(config.FieldNameValidate, inputField[config.DisplayNameModel].value)
                    ) : (
                      <Message messageCode='Msg_002.3' />
                    )}
                  </span>
                )}
              </div>
            );
          }
          if (inputField.Type.value === config.TypeElement.Dropdown) {
            const isDisableRequired = isNotFoundAddress && subFieldNameArray.indexOf(key) >= 0;

            return !isDisableRequired ? (
              <div
                className={Global.renderDynamicClass(isValid, 'form-group form-group--select', 'input-error-validate')}
                key={inputFieldIdx}
                id={key}
              >
                <Select
                  options={type == 'delivery-by-sf-express' ? (key == 'City' ? [] : optionsForMacau?.[key]) : options?.[key]}
                  onChange={(optionCurrent) => {
                    onChangeAreaHandler({
                      option: optionCurrent,
                      inputField: inputField,
                      index: inputFieldIdx,
                    });
                  }}
                  noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
                  value={!valueSelected?.[key] && isFirstLoad ? formik.values[key] : valueSelected[key]}
                  name={key}
                  placeholder={inputField['Display Name'].value}
                  className='customization-dropdown'
                  classNamePrefix='customization-dropdown'
                  isSearchable={false}
                  // menuIsOpen={true}
                />
                {isValid && (
                  <span className='error-validate'>
                    {objMessages['Msg_16'].replace(config.FieldNameValidate, inputField[config.DisplayNameModel].value)}
                  </span>
                )}
              </div>
            ) : (
              <div className='form-group form-group--select' key={inputFieldIdx} id={key}>
                <Select
                  options={type == 'delivery-by-sf-express' ? (key == 'City' ? [] : optionsForMacau?.[key]) : options?.[key]}
                  value=''
                  noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
                  placeholder={inputField['Display Name'].value}
                  className='customization-dropdown'
                  classNamePrefix='customization-dropdown'
                  isSearchable={false}
                  // menuIsOpen={true}
                />
              </div>
            );
          }
        })}
        {isShippingAddressFullfillment ? (
          <div className='auto-address checkout-checkbox'>
            <input
              type='checkbox'
              id='auto-billing-address'
              onChange={formik.handleChange}
              value={formik.values.IsBilling}
              name='IsBilling'
              className='auto-address__input checkout-checkbox__input'
            />
            <label htmlFor='auto-billing-address' className='checkout-checkbox__label'>
              <Text field={dataFields['DataSoures Popup']['PopUp Use this for Billing Address Label']} />
            </label>
          </div>
        ) : (
          ''
        )}
      </div>
      <div className='button-container'>
        <button className='btn btn-outline-CTA1' type={modelSubmit.isSubmit ? 'button' : 'submit'}>
          {!addressCurrent || isAddNew ? (
            <Text field={dataFields['DataSoures Popup']['PopUp Add New Address Button Text']} />
          ) : (
            <Text field={dataFields['DataSoures Popup']['PopUp Update Address Button Text']} />
          )}
        </button>
      </div>
      {addressListReducer.isLoading ? <LoadingMask /> : ''}
      {isLoadingGetDataSource ? <LoadingMask /> : ''}
    </form>
  );
};

AddressForMultiLanguage.propTypes = {
  dataFields: PropTypes.any,
  options: PropTypes.any,
  optionsForMacau: PropTypes.object,
  setSubmitAddressEvt: PropTypes.func,
  addressCurrent: PropTypes.object,
  isShippingAddressFullfillment: PropTypes.bool,
  addressListReducer: PropTypes.object,
  settingGlobal: PropTypes.object,
  objMessages: PropTypes.object,
  isNotFoundAddress: PropTypes.bool,
  isAddNew: PropTypes.bool,
  subFieldNameArray: PropTypes.array,
  isLoadingGetDataSource: PropTypes.bool,
  cartLineData: PropTypes.array,
  type: PropTypes.string,
  macauAddressCurrent: PropTypes.object
};

const mapStateToProps = (state) => ({
  options: state.myAddressReducer.addressDatasourcesReducer.options,
  optionsForMacau: state.myAddressReducer.addressDatasourcesReducer.optionsForMacau || {},
  isNotFoundAddress: state.myAddressReducer.addressDatasourcesReducer.isNotFoundAddress,
  subFieldNameArray: state.myAddressReducer.addressDatasourcesReducer.subFieldNameArray,
  isLoadingGetDataSource: state.myAddressReducer.addressDatasourcesReducer.isLoading,
  addressListReducer: state.checkoutProcesstReducer.addressListReducer,
  settingGlobal: state.settingGlobalReducer.settingGlobal,
  objMessages: state.getMessageReducer?.objMessages || null
});

export default connect(mapStateToProps)(AddressForMultiLanguage);
