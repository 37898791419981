import './ProductReviewItemWidget.scss';

import React from 'react';

import ProductReviewContent from '@components/ReviewPage/ProductReviewContent';

const ProductReviewItemWidget = () => {
  return (
    <div className='container'>
      <div className='col-md-6'>
        <div className='my-account-section product-review-widget'>
          <div className='my-account-section__heading product-review-widget__heading animated-slow fadeIn delay-100'>
            <div className='my-account-section__group'>
              <h3 className='my-account-section__heading__title'>Product Review</h3>
              <a href='' className='my-account-section__btn-link'>
                View All
              </a>
              {/* <span className='my-account-section__btn-link disable'>View All</span> */}
            </div>
            {/* <p className='my-account-section__heading__label'>This product does not have any review.</p> */}
          </div>
          <div className='my-account-section__content product-review-widget__content animated-slow fadeIn delay-100'>
            <ProductReviewContent/>
            <div className='product-review-widget__content__btn text-center'>
              <button className='my-account-section__content__btn-add btn btn-outline-CTA2'>write a review</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductReviewItemWidget;