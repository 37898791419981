import { bool, object } from 'prop-types';
import React from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import ViewProductStatusBtn from './ViewProductStatusBtn';

const NotHasWarrantySection = ({ isLogin, fieldData }) => {
  return (
    <>
      <div className='product-thank-you-page__bottom__content'>
        <div className='product-thank-you-page__bottom__content--left'>
          {!isLogin ? <Text field={fieldData['Account Specific Description']} tag='p' className='guest-no-warranty' /> : <></>}
          <RichText field={fieldData['No Warranty Specific Description']} />
        </div>
      </div>
      {/* {!isLogin ? (
              <div className='product-thank-you-page__bottom__link-view'>
                <a href='#'>View your product in My Product</a>
              </div>
            ) : ( */}
      <div className='product-thank-you-page__bottom__link-view'>
        <ViewProductStatusBtn buttonLink={fieldData?.['View Product Button Link']} buttonText={fieldData['View Product Button Label']} />
      </div>
      {/* )} */}
    </>
  );
};

NotHasWarrantySection.propTypes = {
  isLogin: bool,
  fieldData: object
};

export default NotHasWarrantySection;
