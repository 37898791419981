import produce from 'immer';

import { BOOK_APPOINTMENT_FAILURE, BOOK_APPOINTMENT_START, BOOK_APPOINTMENT_SUCCESS } from '@redux/actions/campaignRegister/bookAppointment/types';

const initialState = {
  isLoading: false,
  error: null,
  bookAppointmentResponse: null
};

const bookAppointmentReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case BOOK_APPOINTMENT_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case BOOK_APPOINTMENT_SUCCESS:
      draft.isLoading = false;

      draft.bookAppointmentResponse = action.payload;
      break;

    case BOOK_APPOINTMENT_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default bookAppointmentReducer;
