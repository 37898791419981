import './DashboardLink.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSOCartsActive, getSOCartsCompleted, getSOCartsDraft, getSOCartsExpired, getSOCartsViewed } from '@redux/actions/getSOCarts';
import { removeCurlyBrackets } from '@redux/epic/bookingExperience/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const TOTAL_APPOINTMENTS = 'TotalAppointments';
const TOTAL_WALK_IN = 'TotalWalkIn';
const LEAD_MANAGEMENTS = 'LeadManagements';
const SALE_ORDERS = 'SaleOrders';

const DashboardLink = ({ elementData, cpOverviewData, onCountSOCart }) => {
  const dispatch = useDispatch();
  const cpAppointmentListingData = useSelector((state) => state.cpAppointmentListingReducer?.result);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore');
  const isCPManager = SitecoreContextFactoryService.getValueContextItem('isCPManager');

  const currentStaffId = useMemo(() => {
    if (typeof window !== 'undefined' && !isCPManager) {
      return JSON.parse(window.localStorage.getItem('cPUserData'))?.UserId || null;
    }

    return '';
  }, [isCPManager]);

  const totalAccount =
    onCountSOCart?.draftCount + onCountSOCart?.activeCount + onCountSOCart?.viewedCount + onCountSOCart?.completedCount + onCountSOCart?.expiredCount;

  const getLink = useMemo(() => {
    const linkNameArr = ['Total Appointments Link', 'Total Walk In Link', 'Lead Managements Link', 'Total sale orders link'];

    return linkNameArr.map((linkName) => elementData.fields[`${linkName}`]).find((link) => link.value.href);
  }, [elementData]);

  const getTypeOverview = useMemo(() => elementData.fields['Overview Element Type'].value, [elementData]);

  const checkNumber = useCallback((number) => (number < 10 ? '0' + number : number), []);

  useEffect(() => {
    if (cpAppointmentListingData?.Appointments) {
      setAppointmentCount(cpAppointmentListingData?.Appointments?.TotalRecords);
    }
  }, [cpAppointmentListingData?.Appointments]);

  useEffect(() => {
    if (getTypeOverview === SALE_ORDERS) {
      dispatch(
        getSOCartsActive({
          statuses: 'Active',
          loadentities: false,
          storeIds: currentStore?.outletCode ? removeCurlyBrackets(currentStore?.outletCode) : '',
          staffIds: currentStaffId
        })
      );

      dispatch(
        getSOCartsDraft({
          statuses: 'Draft',
          loadentities: false,
          storeIds: currentStore?.outletCode ? removeCurlyBrackets(currentStore?.outletCode) : '',
          staffIds: currentStaffId
        })
      );

      dispatch(
        getSOCartsViewed({
          statuses: 'Viewed',
          loadentities: false,
          storeIds: currentStore?.outletCode ? removeCurlyBrackets(currentStore?.outletCode) : '',
          staffIds: currentStaffId
        })
      );

      dispatch(
        getSOCartsCompleted({
          statuses: 'Completed',
          loadentities: false,
          storeIds: currentStore?.outletCode ? removeCurlyBrackets(currentStore?.outletCode) : '',
          staffIds: currentStaffId
        })
      );

      dispatch(
        getSOCartsExpired({
          statuses: 'Expired',
          loadentities: false,
          storeIds: currentStore?.outletCode ? removeCurlyBrackets(currentStore?.outletCode) : '',
          staffIds: currentStaffId
        })
      );
    }
  }, [getTypeOverview]);

  return (
    elementData &&
    (getTypeOverview === TOTAL_APPOINTMENTS ? (
      <a href={getLink?.value?.href} className={`dashboard-link ${getLink?.value?.href ? '' : '--disable-link'}`}>
        <Text className='dashboard-link__name' tag='p' field={elementData.fields['Title']} />
        {cpOverviewData.TotalAppointments && (
          <p className='dashboard-link__number'>
            {`${checkNumber(appointmentCount) || ''}/${checkNumber(cpOverviewData.TotalAppointments.Total) || ''}`}
          </p>
        )}
        <p className='dashboard-link__description'>
          {elementData.fields['Product Demo Ed Label'].value ? (
            <span className='dashboard-link__description'>
              {`${cpOverviewData.TotalAppointments?.ProductDemo || 0} `}
              <Text field={elementData.fields['Product Demo Ed Label']} />
            </span>
          ) : (
            ''
          )}
        </p>
      </a>
    ) : getTypeOverview === TOTAL_WALK_IN ? (
      <a href={getLink?.value?.href} className={`dashboard-link ${getLink?.value?.href ? '' : '--disable-link'}`}>
        <Text className='dashboard-link__name' tag='p' field={elementData.fields['Title']} />
        {cpOverviewData.TotalWalkIn && <p className='dashboard-link__number'>{checkNumber(cpOverviewData.TotalWalkIn.WalkIn) || ''}</p>}
        <p className='dashboard-link__description'>
          {elementData.fields['Product Demo Ed Label'].value ? (
            <span className='dashboard-link__description'>
              {`${cpOverviewData.TotalWalkIn?.ProductDemo || 0} `}
              <Text field={elementData.fields['Product Demo Ed Label']} />
            </span>
          ) : (
            ''
          )}
        </p>
      </a>
    ) : getTypeOverview === LEAD_MANAGEMENTS ? (
      <a href={getLink?.value?.href} className={`dashboard-link ${getLink?.value?.href ? '' : '--disable-link'}`}>
        <Text className='dashboard-link__name' tag='p' field={elementData.fields['Title']} />
        {cpOverviewData.LeadManagements && (
          <p className='dashboard-link__number'>{checkNumber(cpOverviewData.LeadManagements.LeadManagement) || ''}</p>
        )}
        <p className='dashboard-link__description'>
          {elementData.fields['Apointments Label'].value ? (
            <span className='dashboard-link__description'>
              {`${cpOverviewData.LeadManagements?.Appointments || 0} `}
              <Text field={elementData.fields['Apointments Label']} />
            </span>
          ) : (
            ''
          )}
          {elementData.fields['PDPA Lead Label'].value ? (
            <span className='dashboard-link__description'>
              {`${cpOverviewData.LeadManagements?.PDPALead || 0} `}
              <Text field={elementData.fields['PDPA Lead Label']} />
            </span>
          ) : (
            ''
          )}
          {elementData.fields['Anonymous Lead Label'].value ? (
            <span className='dashboard-link__description'>
              {`${cpOverviewData.LeadManagements?.AnonymousLead || 0} `}
              <Text field={elementData.fields['Anonymous Lead Label']} />
            </span>
          ) : (
            ''
          )}
          {elementData.fields['Existing Customer Label'].value ? (
            <span className='dashboard-link__description'>
              {`${cpOverviewData.LeadManagements?.ExistingCustomer || 0} `}
              <Text field={elementData.fields['Existing Customer Label']} />
            </span>
          ) : (
            ''
          )}
        </p>
      </a>
    ) : getTypeOverview === SALE_ORDERS ? (
      <a href={getLink?.value?.href} className={`dashboard-link ${getLink?.value?.href ? '' : '--disable-link'}`}>
        <Text className='dashboard-link__name' tag='p' field={elementData.fields['Title']} />
        <p className='dashboard-link__number'>{totalAccount || ''}</p>
        <p className='dashboard-link__description'>
          {elementData?.fields?.['Not sent']?.value && (
            <span className='dashboard-link__description'>
              <Text
                field={{
                  value: elementData.fields['Not sent']?.value.replace('{notSent}', onCountSOCart?.draftCount || 0)
                }}
              />
            </span>
          )}
          {elementData?.fields?.['Sent']?.value && (
            <span className='dashboard-link__description'>
              <Text
                field={{
                  value: elementData.fields['Sent']?.value.replace('{sent}', onCountSOCart?.activeCount || 0)
                }}
              />
            </span>
          )}
          {elementData?.fields?.['Viewed by customer']?.value && (
            <span className='dashboard-link__description'>
              <Text
                field={{
                  value: elementData.fields['Viewed by customer']?.value.replace('{viewdByCustomer}', onCountSOCart?.viewedCount || 0)
                }}
              />
            </span>
          )}
          {elementData?.fields?.['Completed']?.value && (
            <span className='dashboard-link__description'>
              <Text
                field={{
                  value: elementData.fields['Completed']?.value.replace('{completed}', onCountSOCart?.completedCount || 0)
                }}
              />
            </span>
          )}
          {elementData?.fields?.['Expired']?.value && (
            <span className='dashboard-link__description'>
              <Text
                field={{
                  value: elementData.fields['Expired']?.value.replace('{expired}', onCountSOCart?.expiredCount || 0)
                }}
              />
            </span>
          )}
        </p>
      </a>
    ) : (
      ''
    ))
  );
};

DashboardLink.propTypes = {
  elementData: PropTypes.any,
  cpOverviewData: PropTypes.any,
  onCountSOCart: PropTypes.any
};

export default DashboardLink;
