export default class FacetsItemModel {
  constructor() {
    this.Value = '';

    this.Count = '';

    this.HexCode = '';

    this.Name = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Value')) {
      this.Value = dataSource.Value;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Count')) {
      this.Count = dataSource.Count;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'HexCode')) {
      this.HexCode = dataSource.HexCode;
    }

    return this;
  }
}
