import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LabelsModel from '@models/LabelsModel';
import { deepCopy } from '@utils/utility';

const ProductListingItemLi = (props) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const model = new LabelsModel();

    setInputData(model.getData(deepCopy(props.data) || {}));
  }, []);

  return (
    inputData && (
      <li className={`category-product__tag osim-label ${inputData.IsSpecial ? 'osim-label--highlight' : 'osim-label--default'}`}>
        {inputData.Name}
      </li>
    )
  );
};

ProductListingItemLi.propTypes = {
  data: PropTypes.object
};

export default ProductListingItemLi;
