/* eslint-disable no-undef */
import 'swiper/css/swiper.css';
import './style.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';

import SwipeComponent from '../SwipeComponent/index';
import PreviewComponent from './PreviewComponent/index';
import ThumbnailComponent from './ThumbnailComponent/index';

const SwipeWithThumbnailComponent = (props) => {
  const paramsThumbnail = {
    slidesPerView: 4.5,
    spaceBetween: 8,
    // slideToClickedSlide: true,
    centeredSlides: false,
    preventClicksPropagation: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
      clickable: true
    }
  };
  const [previewItem, setpreviewItem] = useState(props.data[0]);

  const handleClick = function (item) {
    setpreviewItem(item);
  };

  return (
    <div className='related-swipe'>
      <PreviewComponent data={previewItem} />
      <SwipeComponent param={paramsThumbnail}>
        {props.data.map((item) => {
          return (
            <div key={item.id} onClick={() => handleClick(item)}>
              <ThumbnailComponent active={previewItem.id === item.id} item={item} key={item.id} />
            </div>
          );
        })}
      </SwipeComponent>
    </div>
  );
};

SwipeWithThumbnailComponent.propTypes = {
  data: PropTypes.any
};

export default SwipeWithThumbnailComponent;
