import { combineReducers } from 'redux';

import createBookingExperienceContactReducer from './createBookingExperienceContact';
import getScheduleOtpCodeReducer from './getScheduleOtpCode';
import getTotalBookedTimeSlotReducer from './getTotalBookedTimeSlot';
import scheduleAppointmentReducer from './scheduleAppointment';
import updateBookingExperienceReducer from './updateBookingExperience';
import updateBookingExperienceContactReducer from './updateBookingExperienceContact';
import validateScheduleOtpReducer from './validateScheduleOtp';

export const bookingExperienceReducer = combineReducers({
  scheduleAppointmentReducer,
  createBookingExperienceContactReducer,
  getScheduleOtpCodeReducer,
  validateScheduleOtpReducer,
  updateBookingExperienceContactReducer,
  updateBookingExperienceReducer,
  getTotalBookedTimeSlotReducer
});
