import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { postAsObservable } from '@services/genericService';

import { trackingEmailGoalFailed, trackingEmailGoalSuccess } from '../../../actions/trackingGoal/emailTracking';
import { START_TRACKING_EMAIL } from '../../../actions/trackingGoal/emailTracking/emailTrackingTypes';
import * as config from '../config';

export const trackingEmailGoalEpic = (action$) =>
  action$.pipe(
    ofType(START_TRACKING_EMAIL),
    switchMap((action) => {
      return postAsObservable(config.API_TRACKING_GOAL_EMAIL, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            return trackingEmailGoalSuccess(action.payload); 
          } else {
            return trackingEmailGoalFailed('Failed');
          }
        }),
        catchError((error) => of(trackingEmailGoalFailed(error)))
      );
    })
  );