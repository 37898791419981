import { combineReducers } from 'redux';

import accountCheckingReducer from './accountChecking';
import addressListReducer from './addressList';
import dataCheckoutReducer from './dataCheckout';
import paymentReducer from './payment';
import paymentDetailReducer from './paymentDetail';
import setAddressValidErrorMessageReducer from './setAddressValidErrorMessage';
import updateCartReducer from './updateCart';

export const checkoutProcesstReducer = combineReducers({
  accountCheckingReducer,
  dataCheckoutReducer,
  addressListReducer,
  paymentReducer,
  paymentDetailReducer,
  updateCartReducer,
  setAddressValidErrorMessageReducer
});
