import './AddressesWidget.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Loading from '@components/Share/Loading';
import AddNewAddress from '@components/SlicingPage/MyAccount/MyAddress/AddNewAddress';
import {getBookedAddress} from '@redux/actions/checkoutProcess';
import {Link,Text} from '@sitecore-jss/sitecore-jss-react';

import AddressesDefault from './AddressesDefault';
import AddressesWidgetModel from './models/AddressesWidgetModel';

const AddressesWidget = (props) => {
  const {fields, userAddressList, isLoading} = props;
  const [dataSources, setDatasources] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setDatasources(new AddressesWidgetModel().getData(fields || {}));

    dispatch(getBookedAddress());
  }, []);

  return dataSources ? (
    <div className='my-account-section addresses-widget'>
      <div className='my-account-section__heading addresses-widget__heading animated-slow fadeIn delay-100'>
        <div className='my-account-section__group'>
          <h3 className='my-account-section__heading__title'><Text field={dataSources['Title']}/></h3>
          {dataSources['Manage Label'].value ? (
            <Link className='my-account-section__btn-link' field={dataSources['Manage Link']}>
              <Text field={dataSources['Manage Label']} />
            </Link>
          ) : (
            <Link className='my-account-section__btn-link' field={dataSources['Manage Link']} />
          )}
        </div>
        { userAddressList?.length  ? <p className='my-account-section__heading__label'><Text field={dataSources['Default Shipping Sddress Label']}/></p> : ''}
      </div>
      {isLoading ? <Loading/> : !userAddressList?.length ?
        <Fragment>
          <div className='my-account-section__content addresses-widget__content animated-slow fadeIn delay-100'>
            <p className='addresses-widget__content__info__line no-address'><Text field={dataSources['Empty Address Text']}/></p>
          </div> 
          <AddNewAddress dataSources={dataSources}/>
        </Fragment>
        : <AddressesDefault dataSources={userAddressList.find(addresses => addresses.IsPrimary)} dataSourcesLayout={dataSources}/>
      }
    </div>
  ) : (
    <div className='my-account-section addresses-widget skeleton__item'>
      <div className='my-account-section__heading wishlist-widget__heading'>
        <div className='my-account-section__group'>
          <span className='text-line'></span>
          <span className='clear-10'></span>
          <span className='text-line'></span>
        </div>
        <p className='my-account-section__heading__label'>
          <span className='text-line'></span>
        </p>
      </div>
      <div className='my-account-section__content'>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
      </div> 
    </div>
  );
};

AddressesWidget.propTypes = {
  fields: PropTypes.object,
  accountUser: PropTypes.object,
  userAddressList: PropTypes.array,
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  userAddressList: state.checkoutProcesstReducer.addressListReducer.userAddressList || [],
  isLoading: state.checkoutProcesstReducer.addressListReducer.isLoading,
  accountUser: state.singleSignOnReducer.userInfoReducer.accountUser,
});

export default connect(mapStateToProps)(AddressesWidget);