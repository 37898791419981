import { any, func } from 'prop-types';
import React,{ useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useQuery } from '@utils/customsHook/useQuery';

import { useScheduleAppointmentFormContext } from '..';

const StoreInfoItem = ({ storeData, handleSelectStore, handleFetchTotalBookedDateOfStore }) => {
  const [storeStatus, setStoreStatus] = useState({ idStore: '', isHidden: false});
  const { formik } = useScheduleAppointmentFormContext();
  const query = useQuery();
  const storeIdSelected = query.get('storeId');
  const productIdSelected = query.get('productId');
  
  useEffect(() => {
    const excludeProducts = storeData.ExcludeProducts.find(
      (product) => product.Id.toLowerCase() === productIdSelected?.toLowerCase()
    );

    const isHidden = excludeProducts ? true : false;
    const idStore = storeData.ItemId;

    setStoreStatus({ idStore, isHidden });
  }, [storeData, productIdSelected]);

  useEffect(() => {
    const lowerCaseStoreIdSelected = storeIdSelected?.toLowerCase();
    const lowerCaseStoreIdStatus = storeStatus.idStore.toLowerCase();

    if (lowerCaseStoreIdSelected === lowerCaseStoreIdStatus && !storeStatus.isHidden) {
      formik.setFieldValue('storeId', storeIdSelected);

      handleFetchTotalBookedDateOfStore(storeIdSelected);
    }
  }, [storeStatus, storeIdSelected]);

  return (
    <div className={`select-store__content__list__group__item form-group ${storeStatus.isHidden ? 'hidden' : ''}`}>
      <input 
        checked={formik.values.storeId?.toLowerCase() === storeData.ItemId?.toLowerCase()}
        onChange={handleSelectStore}
        id={storeData.ItemId}
        value={storeData.ItemId} 
        name='storeSchedule' 
        type={'radio'} 
      />
      <label className='select-store__content__list__group__item__label' htmlFor={storeData.ItemId}>
        <Text field={{ value: storeData.Name }} tag='h5' className='title select-store__content__list__group__item__title' />
        <p>{storeData.FullAddress}</p>
        {storeData.Postcode ? <p>{storeData.Postcode}</p> : <></>}
        {storeData.Phone ? <p>{storeData.Phone}</p> : <></>}
      </label>
    </div>
  );
};

StoreInfoItem.propTypes = {
  storeData: any,
  handleSelectStore: func,
  handleFetchTotalBookedDateOfStore: func,
};

export default StoreInfoItem;
