import {
  GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA,
  GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_FAILED,
  GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_SUCCESS
} from './categoryForRegisterWarranryTypes';

export const getCategoryForRegisterWarrantyData = () => ({
  type: GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA
});

export const getCategoryForRegisterWarrantyDataSuccess = (payload) => ({
  type: GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_SUCCESS,
  payload
});

export const getCategoryForRegisterWarrantyDataFailed = (payload) => ({
  type: GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_FAILED,
  payload
});
