import { useCallback } from 'react';

import { COMPARE_LABEL_ID, COMPARE_PRODUCT_FUNCTION_DETAIL_ITEM, COMPARE_VALUE_ID } from '../ProductFunctionsDetail';
import { PRODUCT_FUNCTION_ID } from '../ProductFunctionsDetail/Layout';

const useSetFunctionValueHeight = () => {
  const handleCreateTwoDimensionalArray = (compareItemRef) => {
    const compareProductFunctionArray = [];
    let lengthiestProductFunctionItem = 0;
    // INFO: create two-dimensional array to ensure each element in one row
    for (const ref of compareItemRef.current) {
      if (ref.current) {
        const compareProductFunctionEl = ref?.current?.querySelectorAll(`#${PRODUCT_FUNCTION_ID}`);
        if (compareProductFunctionEl.length > lengthiestProductFunctionItem) lengthiestProductFunctionItem = compareProductFunctionEl.length;

        compareProductFunctionArray.push(compareProductFunctionEl);
      }
    }

    return { compareProductFunctionArray, lengthiestProductFunctionItem };
  };

  const handleGetLengthiestProductDetailItem = (compareProductFunctionArray, lengthiestProductFunctionItem) => {
    let lengthiestProductDetailItem = 0;
    const productFunctionDetailArray = [];
    for (const productFunctionItemIndex of Array.from(Array(lengthiestProductFunctionItem).keys())) {
      for (const [index] of compareProductFunctionArray.entries()) {
        if (compareProductFunctionArray[index][productFunctionItemIndex]) {
          const productFunctionDetailEl = compareProductFunctionArray?.[index]?.[productFunctionItemIndex]?.querySelectorAll(
            `#${COMPARE_PRODUCT_FUNCTION_DETAIL_ITEM}`
          );
          if (productFunctionDetailEl.length > lengthiestProductDetailItem) lengthiestProductDetailItem = productFunctionDetailEl.length;

          productFunctionDetailArray.push(productFunctionDetailEl);
        }
      }
    }

    return { productFunctionDetailArray, lengthiestProductDetailItem };
  };

  const handleGetCompareItemElement = (lengthiestProductDetailItem, productFunctionDetailArray) => {
    const compareLabelElArray = [];
    const compareValueElArray = [];
    for (const productFuncDetailItemIndex of Array.from(Array(lengthiestProductDetailItem).keys())) {
      for (const [productFuncDetailIndex] of productFunctionDetailArray.entries()) {
        const compareLabelEl = productFunctionDetailArray?.[productFuncDetailIndex]?.[productFuncDetailItemIndex]?.querySelector(
          `#${COMPARE_LABEL_ID}`
        );

        const compareValueEl = productFunctionDetailArray?.[productFuncDetailIndex]?.[productFuncDetailItemIndex]?.querySelector(
          `#${COMPARE_VALUE_ID}`
        );
        if (compareLabelEl) {
          compareLabelElArray.push(compareLabelEl);
        }
        if (compareValueEl) {
          compareValueElArray.push(compareValueEl);
        }
      }
    }

    return { compareLabelElArray, compareValueElArray };
  };

  return useCallback((compareItemRef) => {
    const { lengthiestProductFunctionItem, compareProductFunctionArray } = handleCreateTwoDimensionalArray(compareItemRef);

    return new Promise((resolve) => {
      const { lengthiestProductDetailItem, productFunctionDetailArray } = handleGetLengthiestProductDetailItem(
        compareProductFunctionArray,
        lengthiestProductFunctionItem
      );
      const { compareLabelElArray, compareValueElArray } = handleGetCompareItemElement(lengthiestProductDetailItem, productFunctionDetailArray);

      for (const compareLabelEl of compareLabelElArray) {
        compareLabelEl.style.height = 'fit-content';
      }

      for (const compareValueEl of compareValueElArray) {
        compareValueEl.style.height = 'fit-content';
      }

      return resolve(true);
    }).then((response) => {
      if (response) {
        for (const productFunctionItemIndex of Array.from(Array(lengthiestProductFunctionItem).keys())) {
          let lengthiestProductDetailItem = 0;
          const productFunctionDetailArray = [];
          for (const [index] of compareProductFunctionArray.entries()) {
            if (compareProductFunctionArray[index][productFunctionItemIndex]) {
              const productFunctionDetailEl = compareProductFunctionArray?.[index]?.[productFunctionItemIndex]?.querySelectorAll(
                `#${COMPARE_PRODUCT_FUNCTION_DETAIL_ITEM}`
              );
              if (productFunctionDetailEl.length > lengthiestProductDetailItem) lengthiestProductDetailItem = productFunctionDetailEl.length;

              productFunctionDetailArray.push(productFunctionDetailEl);
            }
          }
          for (const productFuncDetailItemIndex of Array.from(Array(lengthiestProductDetailItem).keys())) {
            let highestCompareLabel = 0;
            const compareLabelElArray = [];

            let highestCompareValue = 0;
            const compareValueElArray = [];

            for (const [productFuncDetailIndex] of productFunctionDetailArray.entries()) {
              const compareLabelEl = productFunctionDetailArray?.[productFuncDetailIndex]?.[productFuncDetailItemIndex]?.querySelector(
                `#${COMPARE_LABEL_ID}`
              );
              if (compareLabelEl) {
                compareLabelElArray.push(compareLabelEl);
                const compareLabelHeight = compareLabelEl.offsetHeight;
                if (compareLabelHeight > highestCompareLabel) highestCompareLabel = compareLabelHeight;
              }

              const compareValueEl = productFunctionDetailArray?.[productFuncDetailIndex]?.[productFuncDetailItemIndex]?.querySelector(
                `#${COMPARE_VALUE_ID}`
              );
              if (compareValueEl) {
                compareValueElArray.push(compareValueEl);
                const compareValueHeight = compareValueEl.offsetHeight;
                if (compareValueHeight > highestCompareValue) highestCompareValue = compareValueHeight;
              }
            }

            for (const compareLabelEl of compareLabelElArray) {
              compareLabelEl.style.height = `${highestCompareLabel}px`;
            }
            for (const compareValueEl of compareValueElArray) {
              compareValueEl.style.height = `${highestCompareValue}px`;
            }
          }
        }
      }
    });
  }, []);
};

export default useSetFunctionValueHeight;
