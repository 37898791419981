import SitecoreTextModel from '@models/SitecoreTextModel';

import StepHeaderModel from './StepHeaderModel';

export default class CheckoutStepsModel {
  constructor() {
    this['Checkout Steps'] = []; 

    this['Delivery Title'] = new SitecoreTextModel();

    this['Review and Payment Title'] = new SitecoreTextModel();

    this['Your Detail Title'] = new SitecoreTextModel();

  }
  getData(dataSource) {

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Checkout Steps')) {
      if (dataSource['Checkout Steps']?.length) {
        dataSource['Checkout Steps'].map((item) => {
          this['Checkout Steps'].push(new StepHeaderModel().getData(item.fields || {}));
        });
      }
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Title')) {
      this['Delivery Title'].getData(dataSource['Delivery Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Review and Payment Title')) {
      this['Review and Payment Title'].getData(dataSource['Review and Payment Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Your Detail Title')) {
      this['Your Detail Title'].getData(dataSource['Your Detail Title']);
    }

    return this;
  }
}
