import './Typography.scss';

import React from 'react';

const Typography = () => (
  <div className='core-typo'>
    <div className='container'>
      <div className='docs-section'>
        <h2 className='name-title' id='example-colors'>Typography</h2>
        <div className='row example-row example-type'>
          <div className='col-lg-6'>
            <h3 className='sub-title'>Heading Text</h3>
            <h1>h1. Heading <small>Font-size: 50px</small></h1>
            <h2>h2. Heading <small>Font-size: 40px</small></h2>
            <h3>h3. Heading <small>Font-size: 32px</small></h3>
            <h4>h4. Heading <small>Font-size: 24px</small></h4>
            <h5>h5. Heading <small>Font-size: 20px</small></h5>
            <h6>h6. Heading <small>Font-size: 18px</small></h6>
            <div className='alert alert-warning'>
              <p><strong>TIPS:</strong> Create lighter, secondary text in any heading with a generic<code>&lt;small&gt;</code> tag or the<code>.small</code> class.</p>
            </div>
          </div>
          <div className='col-lg-6'>
            <h3 className='sub-title'>Example body text</h3>
            <p className='lead'>This is a <strong>lead paragraph.</strong><br/>Make a paragraph stand out by adding<code>.lead</code></p>
            <p>Maecenas sed diam eget risus varius blandit sit amet non magna. Donec id elit non mi porta gravida at eget metus.</p>
            <p>Nullam quis risus eget <a href='#'>urna mollis ornare</a> vel eu leo. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nullam id dolor id nibh ultricies vehicula.</p>
            <p>The following snippet of text is <strong>rendered as bold text</strong></p>
            <p>The following snippet of text is<em>rendered as italicized text</em></p>
            <p>An abbreviation of the word attribute is <abbr title='attribute'>attr</abbr></p>
          </div>
        </div>

        <div className='row example-row example-type'>
          <div className='col-lg-4'>
            <h3 className='sub-title'>Address</h3><address><strong>Company Name</strong><br/> 795 Folsom Ave, Suite 600<br/> San Francisco, CA 94107<br/><abbr title='Phone'>P:</abbr> (123) 456-7890</address><address><strong>Full Name</strong><br/><abbr title='Phone'>P:</abbr> (123) 456-7890<br/><span id='cloake4e2bbc0ee69e95e9a438ec93c97c721'><a href='mailto:first.last@example.com'>first.last@example.com</a></span></address></div>
          <div
            className='col-lg-4'>
            <h3 className='sub-title'>Emphasis classes</h3>
            <p className='text-muted'>This is a<code>.mute</code> paragraph.</p>
            <p className='text-primary'>This is a<code>.text-primary</code> paragraph.</p>
            <p className='text-warning'>This is a<code>.text-warning</code> paragraph.</p>
            <p className='text-danger'>This is a<code>.text-danger</code> paragraph.</p>
            <p className='text-success'>This is a<code>.text-success</code> paragraph.</p>
            <p className='text-info'>This is a<code>.text-info</code> paragraph.</p>
          </div>
          <div className='col-lg-4'>
            <h3 className='sub-title'>Alignment classes</h3>
            <p className='text-left'>This is a left aligned text<code>.text-left</code></p>
            <p className='text-center'>This is a center aligned text<code>.text-center</code></p>
            <p className='text-right'>This is a right aligned text<code>.text-right</code></p>
            <p className='text-justify'>This is a justify aligned text which is often used in Book Design, Magazine or special Typo Pages. Create a justify aligned text with<code>.text-justify</code> class.</p>
          </div>
        </div>
      </div>
      <div className='docs-section'>
        <h2 className='name-title' id='example-colors'>Blockquotes</h2>
        <div className='row example-row example-type'>
          <div className='col-lg-12'>
            <blockquote>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer posuere erat a ante.</p><small>Quote&apos;s author in<cite title='Source Title'>Source Title</cite></small></blockquote>
          </div>
        </div>
      </div>

      <div className='docs-section'>
        <h2 className='name-title' id='example-color'>Color Swatches</h2>
        <h3 className='sub-title'>Brand Colors</h3>
        <div className='row example-row example-colors'>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-primary'>
              <dl><dt>#f37021</dt>
                <dd>Brand Primary</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-secondary'>
              <dl><dt>#0072bc</dt>
                <dd>Brand Secondary</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-green'>
              <dl><dt>#00ae4dc</dt>
                <dd>Brand Green</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-blue'>
              <dl><dt>#0072bc</dt>
                <dd>Brand Blue</dd>
              </dl>
            </div>
          </div>
        </div>
        <h3 className='sub-title'>Orange scale Levels</h3>
        <div className='row example-row example-colors'>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-orange-darker'>
              <dl><dt>#df6116</dt>
                <dd>Orange darker</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-orange-dark'>
              <dl><dt>#f37021</dt>
                <dd>Orange dark</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-orange text-dark'>
              <dl><dt>#fee6d3</dt>
                <dd>Orange</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-orange-lighter text-dark'>
              <dl><dt>#feeee4</dt>
                <dd>Orange lighter</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-orange-lightest text-dark'>
              <dl><dt>#fef2e9</dt>
                <dd>Orange lightest</dd>
              </dl>
            </div>
          </div>
        </div>
        <h3 className='sub-title'>Brown scale Levels</h3>
        <div className='row example-row example-colors'>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-brown text-dark'>
              <dl><dt>#e0dac9</dt>
                <dd>Brown</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-brown-lighter text-dark'>
              <dl><dt>#f6f4ef</dt>
                <dd>Brown lighter</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-os-brown-lightest text-dark'>
              <dl><dt>#fcfbfa</dt>
                <dd>Brown lightest</dd>
              </dl>
            </div>
          </div>
        </div>
        <h3 className='sub-title'>Grayscale Levels</h3>
        <div className='row example-row example-colors'>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-gray-darker'>
              <dl><dt>#2a2a2a</dt>
                <dd>Gray Darker</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-gray-dark'>
              <dl><dt>#707070</dt>
                <dd>Gray Dark</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-gray'>
              <dl><dt>#aaaaaa</dt>
                <dd>Gray</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-gray-light'>
              <dl><dt>#dddddd</dt>
                <dd>Gray Light</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-gray-lighter'>
              <dl><dt>#e6e6e6</dt>
                <dd>Gray Lighter</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item color-gray-lightest'>
              <dl><dt>#f5f5f7</dt>
                <dd>Gray Lightest</dd>
              </dl>
            </div>
          </div>
        </div>
        <h3 className='sub-title'>Gradient</h3>
        <div className='row example-row example-colors'>
          <div className='col-6 col-md-2'>
            <div className='swatch-item gradient-default text-dark'>
              <dl>Gradient Default<dt></dt>
                <dd>#ffffff - #dce6f5</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item gradient-primary text-dark'>
              <dl><dt>Gradient Primary</dt>
                <dd>#aceff2 - #d4e2f1 - #fee6d3</dd>
              </dl>
            </div>
          </div>
          <div className='col-6 col-md-2'>
            <div className='swatch-item gradient-secondary'>
              <dl><dt>Gradient Secondary</dt>
                <dd>#a8c7f5 - #d49ad1 - #eb8199</dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div className='docs-section'>
        <h2 className='name-title' id='example-colors'>Buttons</h2>
        <div className='row example-row example-type'>
          <div className='col-md-12'>
            <div className='example-button clearfix'>
              <a className='btn btn-default' href='#'>Button Default</a>
              <a className='btn btn-primary' href='#'>Button Primary</a>
              <a className='btn btn-secondary' href='#'>Button Secondary</a>
              <a className='btn btn-os-green' href='#'>Button Green</a>
              <a className='btn btn-os-blue' href='#'>Button Blue</a>
              <a className='btn btn-disable' href='#'>Button Disable</a>
            </div>
            <div className='example-button clearfix'>
              <a className='btn btn-os-orange-darker' href='#'>Button Orange darker</a>
              <a className='btn btn-os-orange-dark' href='#'>Button Orange dark</a>
              <a className='btn btn-os-orange' href='#'>Button Orange</a>
              <a className='btn btn-os-orange btn-os-orange--text-primary' href='#'>Button Orange</a>
              <a className='btn btn-os-orange-lighter' href='#'>Button Orange Lighter</a>
              <a className='btn btn-os-orange-lightest' href='#'>Button Orange Lightest</a>
            </div>
            <div className='example-button clearfix'>
              <a className='btn btn-os-brown' href='#'>Button Brown</a>
              <a className='btn btn-os-brown-lighter' href='#'>Button Brown Lighter</a>
              <a className='btn btn-os-brown-lightest' href='#'>Button Brown Lightest</a>
            </div>
            <div className='example-button clearfix'>
              <a className='btn btn-outline-default' href='#'>Button Outline Default</a>
              <a className='btn btn-outline-primary' href='#'>Button Outline Primary</a>
              <a className='btn btn-outline-secondary' href='#'>Button Outline Secondary</a>
            </div>
            <div className='example-button clearfix'>
              <a className='btn btn-outline-CTA1' href='#'>Button Outline CTA 1</a>
              <a className='btn btn-outline-CTA2' href='#'>Button Outline CTA 2</a>
            </div>
            <div className='example-button clearfix'>
              <a className='btn btn-outline-with-icon' href='#'>
                <img className='btn-outline-with-icon__img' src='https://upload-icon.s3.us-east-2.amazonaws.com/uploads/icons/png/2659939281579738432-512.png' alt='icon'/>
                Button With Icon
              </a>
              <a className='btn-link' href='#'>Button Link</a>
            </div>
          </div>
        </div>
      </div>
      <div className='docs-section'>
        <h2 className='name-title'>Tags</h2>
        <div className='row example-row example-type'>
          <div className='col-lg-12'>
            <span className='osim-tag'>Tag name</span>
          </div>
        </div>
      </div>
      <div className='docs-section'>
        <h2 className='name-title'>Selection Controls</h2>
        <div className='row example-row example-type'>
          <div className='col-lg-12'>
            <h3>Checkbox</h3>
            <div className='osim-checkbox'>
              <input className='osim-checkbox-input' type='checkbox' id='osim-checkbox-1' />
              <label className='osim-checkbox-label' htmlFor='osim-checkbox-1'>Check box</label>
            </div>
            <div className='osim-checkbox'>
              <input className='osim-checkbox-input' type='checkbox' checked='checked' id='osim-checkbox-2' />
              <label className='osim-checkbox-label' htmlFor='osim-checkbox-2'>Check box</label>
            </div>
          </div>
          <div className='col-lg-12'>
            <h3>Radio Button</h3>
            <div className='osim-radio'>
              <input className='osim-radio-input' type='radio' id='osim-radio-1' />
              <label className='osim-radio-label' htmlFor='osim-radio-1'></label>
            </div>
            <div className='osim-radio'>
              <input className='osim-radio-input' type='radio' checked='checked' id='osim-radio-2' />
              <label className='osim-radio-label' htmlFor='osim-radio-2'></label>
            </div>
          </div>
        </div>
      </div>
      <div className='docs-section'>
        <h2 className='name-title'>Label Status</h2>
        <div className='row example-row example-type'>
          <div className='col-md-2'>
            <span className='osim-label osim-label--new'>NEW</span>
          </div>
          <div className='col-md-2'>
            <span className='osim-label osim-label--gift'>Free Gift</span>
          </div>
          <div className='col-md-2'>
            <span className='osim-label osim-label--discount'>Discount 20%</span>
          </div>
          <div className='col-md-2'>
            <span className='osim-label osim-label--add-card'>Added to Cart</span>
          </div>
          <div className='col-md-2'>
            <span className='osim-label osim-label--out-stock'>Out of Stock</span>
          </div>
        </div>
        <div className='row example-row example-type'>
          <div className='col-md-2'>
            <span className='osim-label osim-label--highlight'>NEW</span>
          </div>
          <div className='col-md-2'>
            <span className='osim-label osim-label--default'>Free Gift</span>
          </div>
        </div>
      </div>
      <div className='docs-section'>
        <h2 className='name-title'>Feature Icons</h2>
        <div className='row example-row example-type'>
          <div className='col-md-3'>
            <div className='feature-icon'>
              <span className='feature-icon__icon'>
                <i className='icon-user'></i>
              </span>
              <span className='feature-icon__label'>Login</span>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='feature-icon'>
              <span className='feature-icon__icon'>
                <i className='icon-cart'></i>
              </span>
              <span className='feature-icon__label'>Cart</span>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='feature-icon'>
              <span className='feature-icon__icon'>
                <i className='icon-list'></i>
              </span>
              <span className='feature-icon__label'>View Product Catalogue</span>
            </div>
          </div>
        </div>
      </div>
      <div className='docs-section'>
        <div>
          <h3 id='example-typo'>Inputs</h3>
        </div>
        <div className='row example-row example-forms example-inputs'>
          <div className='col-md-3'>
            <div className='form-group has-success'><label className='control-label' htmlFor='inputSuccess'>Input success</label><input className='form-control' id='inputSuccess' type='text' /></div>
          </div>
          <div className='col-md-3'>
            <div className='form-group has-error'><label className='control-label' htmlFor='inputError'>Input error</label><input className='form-control' id='inputError' type='text' /></div>
          </div>
          <div className='col-md-3'>
            <div className='form-group has-warning'><label className='control-label' htmlFor='inputWarning'>Input warning</label><input className='form-control' id='inputWarning' type='text' /></div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'><label className='control-label' htmlFor='disabledInput'>Disabled input</label><input className='form-control' id='disabledInput' disabled='disabled' type='text' /></div>
          </div>
        </div>
      </div>
      <div className='docs-section'>
        <h2 className='name-title' id='example-indicators'>Indicators</h2>
        <h3 className='sub-title'>Alerts and Notifications</h3>
        <div className='row example-row example-indicators'>
          <div className='col-md-6'>
            <div className='alert alert-danger'>
              <h4>Error message title</h4>
              <p>Cras ac dignissim mauris. Duis odio diam, rutrum ut est at, scelerisque malesuada risus.</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='alert alert-success'>
              <h4>Sussess message title</h4>
              <p>Cras ac dignissim mauris. Duis odio diam, rutrum ut est at, scelerisque malesuada risus.</p>
            </div>
          </div>
        </div>
        <div className='row example-row example-indicators'>
          <div className='col-md-6'>
            <div className='alert alert-info'>
              <h4>Info message title</h4>
              <p>Cras ac dignissim mauris. Duis odio diam, rutrum ut est at, scelerisque malesuada risus.</p>
            </div>
          </div>
          <div className='col-md-6'>
            <div className='alert alert-warning'>
              <h4>Warning message title</h4>
              <p>Cras ac dignissim mauris. Duis odio diam, rutrum ut est at, scelerisque malesuada risus.</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Typography;
