export const CANCEL_APPOINTMENT = 'CANCEL_APPOINTMENT';

export const CANCEL_APPOINTMENT_SUCCESS = 'CANCEL_APPOINTMENT_SUCCESS';

export const CANCEL_APPOINTMENT_FAILED = 'CANCEL_APPOINTMENT_FAILED';

export const UPDATE_APPOINTMENT = 'UPDATE_APPOINTMENT';

export const UPDATE_APPOINTMENT_SUCCESS = 'UPDATE_APPOINTMENT_SUCCESS';

export const UPDATE_APPOINTMENT_FAILED = 'UPDATE_APPOINTMENT_FAILED';