import PropTypes from 'prop-types';
import React from 'react';

const FeaturedProductItemLi = ({ labelItem }) => {  
  return <li className={`list-tags__item osim-label ${labelItem.IsSpecial ? 'osim-label--highlight' : 'osim-label--default'}`}>{labelItem.Name}</li>;
};

FeaturedProductItemLi.propTypes = {
  labelItem: PropTypes.object
};

export default FeaturedProductItemLi;
