import {
  DEFAULT_LAST_NAME_API_PAYLOAD_VALUE,
  DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD,
  TW_SITE_NAME
} from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';

/**
 * @param {object} infoUser
 * @param {string} fullNameFormatRawText */
export const getUserFullName = (infoUser, fullNameFormatRawText = '') => {
  const firstName = infoUser?.FirstName || '';
  let lastName = infoUser?.LastName || DEFAULT_LAST_NAME_API_PAYLOAD_VALUE;
  if (firstName) fullNameFormatRawText = fullNameFormatRawText?.replace('{FirstName}', firstName);
  if (lastName === DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD) {
    lastName =
      lastName.replace(DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD, '');
  }

  fullNameFormatRawText = fullNameFormatRawText?.replace('{LastName}', lastName);

  return fullNameFormatRawText?.trim();
};

/**
 * @param {string} firstName
 * @param {string} lastName
 */
export const getUserFullNameWithoutFormat = ({ firstName = '', lastName = DEFAULT_LAST_NAME_API_PAYLOAD_VALUE }) => {
  const isTWSite = isAtSiteName(TW_SITE_NAME);
  if (lastName === DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD) {
    lastName =
      lastName.replace(DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD, '');
  }

  return (
    !isTWSite ? `${firstName} ${lastName}` : `${lastName} ${firstName}`
  )?.trim();
};
