import { func, object, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const RequireLoginPopup = ({ onClose, layoutData, emailRequire = '' }) => {
  const requireLoginText = useMemo(() => {
    return {
      ...layoutData?.['Guest Apply Offer Message'],
      value: layoutData?.['Guest Apply Offer Message']?.value?.replace('{suggestEmail}', emailRequire)
    };
  }, [layoutData?.['Guest Apply Offer Message'], emailRequire]);

  const loginUrl = useMemo(() => {
    return layoutData?.['Login Link']?.value?.href
      ? `${layoutData['Login Link'].value.href}?from=${window.location.href}`
      : `/login?from=${window.location.href}`;
  }, [layoutData['Login Link']]);

  const handleNavigateToLoginPage = useCallback(() => {
    window.location.href = loginUrl;
  }, [loginUrl]);

  return (
    <div className='apply-offer-popup'>
      <Text tag='h2' className='apply-offer-popup__title text-center' field={layoutData['Apply Offer Title']} />
      <div className='apply-offer-popup__group'>
        <div className='apply-offer-popup__desc'>
          <Text tag='p' field={requireLoginText} />
        </div>
        <div className='apply-offer-popup__btn text-center'>
          <div className='apply-offer-popup__btn__item'>
            <button className='btn btn-primary' onClick={handleNavigateToLoginPage}>
              <Text tag='span' field={layoutData['Login Text']} />
            </button>
          </div>
          <div className='apply-offer-popup__btn__item'>
            <Text tag='span' className='btn-link-text' onClick={onClose} field={layoutData['Cancel Button Text']} />
          </div>
        </div>
      </div>
    </div>
  );
};

RequireLoginPopup.propTypes = {
  emailRequire: string,
  onClose: func,
  layoutData: object
};

export default RequireLoginPopup;
