import SitecoreTextModel from '@models/SitecoreTextModel';

export default class RegisterOneLastStepModel {
  constructor() {
    this['Did Not Get Email Message'] = new SitecoreTextModel();

    this['Register Success Instruction'] = new SitecoreTextModel();

    this['Register Success Message'] = new SitecoreTextModel();

    this['Register Success Title'] = new SitecoreTextModel();

    this['Resend Message'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Did Not Get Email Message')) {
      this['Did Not Get Email Message'].getData(dataSource['Did Not Get Email Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Success Instruction')) {
      this['Register Success Instruction'].getData(dataSource['Register Success Instruction']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Success Title')) {
      this['Register Success Title'].getData(dataSource['Register Success Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Resend Message')) {
      this['Resend Message'].getData(dataSource['Resend Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Success Message')) {
      this['Register Success Message'].getData(dataSource['Register Success Message']);
    }

    return this;
  }
}
