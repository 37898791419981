import './ArticleCarousel.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import SwipeComponent from '@components/Share/SwipeComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CarouselDetailSlide from '../CarouselDetailSlide';
import ArticleCarouselModel from '../Models/ArticleCarouselModel';

const ArticleCarousel = ({fields, rendering, params}) => {
  const [articleCarouselFields, setArticleCarouselFields] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(null);

  useEffect(() => {
    const articleCarouselModel = new ArticleCarouselModel();

    setArticleCarouselFields(articleCarouselModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  useEffect(() => {
    Global.scrollWithHash(headerHeight);
  }, [articleCarouselFields, headerHeight]);

  const paramsSwipe = {
    slidesPerView: (articleCarouselFields && articleCarouselFields.Articles?.length > 1) ? 1.2 : 1,
    centeredSlides: false,
    spaceBetween: 18,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    breakpoints: {
      992: {
        slidesPerView: 3,
        navigation: {
          nextEl: `.article-carousel .uid${rendering.uid || ''}.swiper-button-next`,
          prevEl: `.article-carousel .uid${rendering.uid || ''}.swiper-button-prev`,
        },
      },

      767: {
        slidesPerView: 2,
        spaceBetween: 40,
        navigation: {
          nextEl: `.article-carousel .uid${rendering.uid || ''}.swiper-button-next`,
          prevEl: `.article-carousel .uid${rendering.uid || ''}.swiper-button-prev`,
        },
      }
    }
  };

  return articleCarouselFields && (
    <div 
      id={params['Specific Section Id'] || '' }
      className={`article-carousel carousel-detail ${articleCarouselFields.Articles.length && articleCarouselFields.Articles.length > 1 ? '' : '--one-slide'}`}>
      <div className='container'>
        <Text tag='h2' field={articleCarouselFields.Title} className='text-center section-title' />
        <div className='carousel-detail__list'>
          <SwipeComponent param={paramsSwipe} >
            {
              articleCarouselFields.Articles.length && 
              articleCarouselFields.Articles.map((article) => (
                <div key={article.id}>
                  <CarouselDetailSlide article={article}></CarouselDetailSlide>
                </div>
              ))
            }
            
          </SwipeComponent>
          <div className={`uid${rendering.uid || ''} swiper-button-next`}></div>
          <div className={`uid${rendering.uid || ''} swiper-button-prev`}></div>
        </div>
      </div>
    </div>
  );
};

ArticleCarousel.propTypes = {
  fields: PropTypes.object,
  rendering: PropTypes.object,
  params: PropTypes.object
};

export default ArticleCarousel;
