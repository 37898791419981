import React from 'react';
import { Fragment } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import SwipeComponent from '@components/Share/SwipeComponent';

const FeaturedTilesShimmer = () => {
  const params = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 18,
    watchOverflow: true,
    autoHeight: false,
    breakpoints : {
      768: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },

      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },

      1400: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },

    shouldSwiperUpdate: true
  };

  return (
    <div className='featured-tiles'>
      <div className='container featured-tiles__wrap'>
        <div className='featured-tiles__heading skeleton__item'>
          <div className='text-line w-20'></div>
          <div className='clear-10'></div>
          <div className='text-line w-50'></div>
          <div className='clear-10'></div>
          <div className='text-line w-30'></div>
        </div>
        <div className='featured-tiles__list skeleton__item'>
          <SwipeComponent param={params}>
            <div className='featured-tiles__item'>
              <div className='featured-tiles__content first-item'>
                <div className='image-wrap'>
                  <div className='image-line'></div>
                </div>
                <p className='featured-tiles__content__text text-container'>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                </p>
              </div>
            </div>
            <div className='featured-tiles__item'>
              <div className='featured-tiles__content second-item'>
                <div className='image-wrap'>
                  <div className='image-line'></div>
                </div>
                <p className='featured-tiles__content__text text-container'>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                </p>
              </div>
            </div>
            <div className='featured-tiles__item'>
              <div className='featured-tiles__content third-item'>
                <div className='image-wrap'>
                  <div className='image-line'></div>
                </div>
                <p className='featured-tiles__content__text text-container'>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                  <span className='clear-10'></span>
                  <span className='text-line'></span>
                </p>
              </div>
            </div>
          </SwipeComponent>
        </div>
      </div>
    </div>
  );
};

export default FeaturedTilesShimmer;