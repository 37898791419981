import { useMemo } from 'react';

const useLoyaltyLandingPageTableData = (layoutData) => {
  const loyaltyTableData =
    useMemo(() => {
      if (layoutData?.['Tiers']?.length) {
        // Set field Array in side left Table
        const fieldArr = [];
        const arrayFields = layoutData?.['Tiers']?.[0]?.['Display Fields'] || [];

        if (arrayFields) {
          for (let i = 0; i < arrayFields.length; i++) {
            const field = arrayFields[i];

            if (field?.['Display Name'] && (field?.['Icon'] != {} || field?.['Icon'] != null) /** cần check null field Icon nữa */) {
              fieldArr.push({
                label: field?.['Display Name'],
                icon: field?.['Icon'],
                key: (field?.['Display Name'] || '').replaceAll(' ', '-').toLowerCase()
              });
            }
          }
        }
        // const fieldArr = (layoutData?.['Tiers']?.[0]?.['Display Fields'] || []).map((field) => {
        //   console.log("field?.['Display Name']", field?.['Display Name']);
        //   let obj = {};
        //   if (field?.['Display Name']) {
        //     obj = {
        //       label: field?.['Display Name'],
        //       icon: field?.['Icon'],
        //       key: (field?.['Display Name'] || '').replaceAll(' ', '-').toLowerCase()
        //     };
        //   }
        //   return obj;
        // });

        // Set Tier Array
        const tierArr = (layoutData?.['Tiers'] || []).map((tier) => {
          const displayFields = tier?.['Display Fields'] || [];

          const objTier = {
            icon: tier?.['Icon'],
            tier: tier?.Tier,
            'spent-min-value': tier?.['Spent Min Value']?.value
          };

          displayFields.map((item) => {
            const displayName = item?.['Display Name']?.replaceAll(' ', '-').toLowerCase();
            const value = item?.['Value']?.value;

            return (objTier[displayName] = value);
          });

          return objTier;
        });

        tierArr.reverse();

        const btnViewMoreText = layoutData?.['Tiers']?.[0]?.['Button Text View More Benefits']?.value;

        return { fieldArr, tierArr, btnViewMoreText };
      }
    }, [layoutData?.['Tiers']]) || [];

  return loyaltyTableData;
};

export default useLoyaltyLandingPageTableData;
