import './BannerWithRichTextOverlay.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import BannerWithRichTextOverlayFieldsModel from './Model/BannerWithRichTextOverlayFieldsModel';

const BannerWithRichTextOverlay = ({ fields }) => {
  const [bannerFields, setBannerFields] = useState(null);

  useEffect(() => {
    const bannerWithRichTextOverlayFieldsModel = new BannerWithRichTextOverlayFieldsModel();

    setBannerFields(bannerWithRichTextOverlayFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return (
    bannerFields && (
      <Fragment>
        {bannerFields['Background Image'].value.src ? (
          <div className='banner-with-rich-text-overlay container no-padding-mobile'>
            <div className='banner-with-rich-text-overlay__wrapper'>
              <div className='banner-with-rich-text-overlay__banner'>
                <ResponsiveImage
                  imageMobile={bannerFields['Mobile Background Image']}
                  srcSet={[ { mw: 768, image: bannerFields['Background Image'] } ]}
                />
              </div>
              <div className='banner-with-rich-text-overlay__contents'>
                <div className='banner-with-rich-text-overlay__contents__container'>
                  <Text field={bannerFields.Title} tag='h3' className='banner-with-rich-text-overlay__contents__title' />
                  <RichText className='banner-with-rich-text-overlay__contents__description' field={bannerFields.Description} />
                </div>
              </div>
            </div>

            {bannerFields['CTA Button Text'].value ? (
              <div className='banner-with-rich-text-overlay__button text-center'>
                <Link field={bannerFields['CTA Button Link']}>
                  <Text field={bannerFields['CTA Button Text']} />
                </Link>
              </div>
            ) : (
              ''
            )}
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    )
  );
};

BannerWithRichTextOverlay.propTypes = {
  fields: PropTypes.object
};

export default BannerWithRichTextOverlay;
