import { any } from 'prop-types';
import React, { useEffect, useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import DatePickerEvent from '../../DatePickerEvent';
import useSelectStoreAndDateContext from '../useSelectStoreAndDateContext';
import { generateDateRange, MILLISECOND_PER_DAY } from '../utils';
import useSortExcludeDate from './useSortExcludeDate';

const SelectPreferDate = ({ layoutData }) => {
  const { formik, isDisableFormSection } = useSelectStoreAndDateContext();

  // const numberOfGuest = useMemo(() => {
  //   return [layoutData?.justMeButtonText || layoutData?.['Just Me Label'], layoutData?.mePlus1PlaceholderText || layoutData?.['Me And Others']];
  // }, [layoutData]);

  const dateRange = useMemo(() => {
    return generateDateRange(layoutData?.campaign?.Stores, formik.values.storeId);
  }, [layoutData?.campaign?.Stores, formik.values.storeId]);

  const excludedDates = useSortExcludeDate(dateRange, layoutData?.campaign, formik.values.storeId, null, layoutData?.appointment?.Date);

  useEffect(() => {
    const firstDateRangeItem = dateRange?.[0];
    const startTime = firstDateRangeItem?.start?.getTime();
    const endTime = firstDateRangeItem?.end?.getTime();
    const totalDay = (endTime - startTime) / MILLISECOND_PER_DAY;
    let selectedDate = formik.values.date;

    for (let i = 0; i <= totalDay; i++) {
      if (selectedDate.getTime() >= startTime && selectedDate.getTime() <= endTime) {
        const checkExistInExcludedDate = excludedDates.find((date) => date === selectedDate.getTime());
        if (checkExistInExcludedDate) {
          selectedDate.setDate(selectedDate.getDate() + 1);
        } else {
          formik.setFieldValue('date', selectedDate);
          break;
        }
      }
    }
  }, [excludedDates.length]);

  return (
    <div className='col-12 col-lg-4'>
      <div className='select-store__date'>
        <div className={isDisableFormSection ? 'be-schedule-appointment__disable-form' : ''}>
          <Text className='select-store__date__title' field={layoutData.selectDateLabel || layoutData?.['Select A Preferred Date Label']} tag='h4' />
          <DatePickerEvent
            excludeDates={excludedDates}
            selected={formik.values.date}
            includeDateIntervals={dateRange}
            onChange={(date) => formik.setFieldValue('date', date)}
            availableText={layoutData?.availableTranslationText?.value}
            notAvailableText={layoutData?.notAvailableTranslationText?.value}
            selectedText={layoutData?.selectedTranslationText?.value}
          />
        </div>
        {/*<div className='select-store__date__option'>*/}
        {/*  <Text field={layoutData?.numberOfGuestsLabel || layoutData?.['Number Of Guest']} />*/}
        {/*  <div className='select-store__date__option__list'>*/}
        {/*    {numberOfGuest.map((option, index) => (*/}
        {/*      <div key={index} className='select-store__date__option__list__item'>*/}
        {/*        <div className='form-group'>*/}
        {/*          <input*/}
        {/*            id={option.value}*/}
        {/*            value={index + 1}*/}
        {/*            type={'radio'}*/}
        {/*            defaultChecked={formik.values.numberOfGuests === index + 1}*/}
        {/*            name='optionGuest'*/}
        {/*            onChange={(event) => formik.setFieldValue('numberOfGuests', +event.target.value)}*/}
        {/*          />*/}
        {/*          <Text field={option} tag='label' htmlFor={option.value} />*/}
        {/*        </div>*/}
        {/*      </div>*/}
        {/*    ))}*/}
        {/*  </div>*/}
        {/*</div>*/}
      </div>
    </div>
  );
};

SelectPreferDate.propTypes = {
  layoutData: any
};

export default SelectPreferDate;
