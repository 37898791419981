import BackgroundColorCodeModel from '@components/TwoColumnWithTextImage/model/BackgroundColorCodeModel';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import SimpleCardItemModel from '../SimpleCards/SimpleCardItem/model/SimpleCardItemModel';

export default class SimpleCardsModel {
  constructor() {
    this.Title = new SitecoreTextModel();

    this.items = [];

    this['Background Image'] = new SitecoreImageModel();

    this['Mobile Background Image'] = new SitecoreImageModel();

    this.Description = new SitecoreTextModel();

    this['Background Color'] = new BackgroundColorCodeModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let items = [];

      dataSource['items'].map((item) => {
        items.push(new SimpleCardItemModel().getData(deepCopy(item) || {}));
      });

      this['items'] = items;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Image')) {
      this['Background Image'].getData(dataSource['Background Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Background Image')) {
      this['Mobile Background Image'].getData(dataSource['Mobile Background Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Color')) {
      this['Background Color'].getData(dataSource['Background Color']);
    }

    return this;
  }
}
