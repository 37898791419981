import './WhyChooseOsim.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';

import WhyChooseOsimModels from './model/WhyChooseOsimModels';

const WhyChooseOsim = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const models = new WhyChooseOsimModels();

    setLayoutData(models.getData(fields));
  }, []);

  return layoutData?.['reasonsToBuy'].length ? (
    <div className='container'>
      <div className='why-choose-osim'>
        <Text field={ layoutData?.['title'] } tag='h2' className='why-choose-osim__title' />
        <div className='why-choose-osim__content'>
          {layoutData?.['reasonsToBuy'].length ? (
            <div className='row'>
              {layoutData?.['reasonsToBuy']?.map((item) => 
                <div key={item?.id} className='why-choose-osim__content__item col-12 col-md-4'>
                  <div className='why-choose-osim__content__item__icon'>
                    <Image field={ item?.fields?.icon } />
                  </div>
                  <div className='why-choose-osim__content__item__text'>
                    <Text field={ item?.fields?.reason } tag='p' />
                  </div>
                  <Link field={{ href: item?.url }} className='btn btn-link'>
                    <Text field={ item?.fields?.ctaText } />
                  </Link>
                </div>
              )}
            </div>
          ) : (<></>)}
        </div>
      </div>
    </div>
  ) : (<></>);
};

WhyChooseOsim.propTypes = {
  fields: object
};

export default WhyChooseOsim;