import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { IS_ADDED_TO_CART_SESSION_STORAGE_NAME } from '../configs';

export const useSetIsAddedToCartValue = () => {
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);

  return useCallback(
    (value) => {
      const addedToCartParams = {
        isCartEmpty: !currentCart?.Lines.length,
        status: value ?? false
      };

      return window.sessionStorage.setItem(IS_ADDED_TO_CART_SESSION_STORAGE_NAME, JSON.stringify(addedToCartParams));
    },
    [currentCart]
  );
};
