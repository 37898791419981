// INFO: reference doc here: https://github.com/MomenSherif/react-oauth#usegooglelogin-both-implicit--authorization-code-flow
import { object } from 'prop-types';
import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useGoogleLogin } from '@react-oauth/google';
import { loginFailed, loginGoogle } from '@redux/actions/singleSignOn';
import { TYPE_GOOGLE } from '@redux/epic/singleSignOn/config';
import { generateRegisterLoginError } from '@redux/epic/singleSignOn/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { EVENT_METHOD } from '../RegisterLogin/utils';

// const CODE_COOKIE_ENVIRONMENT_NOT_ENABLED = 'idpiframe_initialization_failed';
const CODE_POP_UP_CLOSE = 'popup_closed_by_user';

const CustomGoogleAuthButton = ({ loginText }) => {
  const dispatch = useDispatch();
  // const [disabledCookie, setCookies] = useState(false);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const handleLoginSuccess = useCallback((tokenResponse) => {
    if (tokenResponse?.['access_token']) {
      dispatch(
        loginGoogle({
          Type: TYPE_GOOGLE,
          AccessToken: tokenResponse['access_token']
        })
      );
    }
  }, []);

  const handleLoginFailed = useCallback((errorType) => {
    // if (errorType === CODE_COOKIE_ENVIRONMENT_NOT_ENABLED) {
    //   setCookies(true);
    // }
    // if (errorType === CODE_POP_UP_CLOSE && disabledCookie) {
    if (errorType === CODE_POP_UP_CLOSE) {
      dispatch(loginFailed(generateRegisterLoginError(messageObj['Msg_003'], EVENT_METHOD.google)));
    }
  }, []);

  const login = useGoogleLogin({
    onSuccess: (tokenResponse) => handleLoginSuccess(tokenResponse),
    onError: () => handleLoginFailed(CODE_POP_UP_CLOSE),
    onNonOAuthError: () => handleLoginFailed(CODE_POP_UP_CLOSE)
  });

  return (
    <button className='btn btn-outline-with-icon' onClick={login}>
      <img className='btn-outline-with-icon__img' src={require('@assets/images/icons/google-icon.png')} alt='icon' />
      <Text field={loginText} />
    </button>
  );
};

CustomGoogleAuthButton.propTypes = {
  loginText: object
};

export default CustomGoogleAuthButton;
