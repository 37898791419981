import SignleSignOnModel from '@components/SingleSignOn/models/SignleSignOnModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SocialLoginsWidgetModel {
  constructor() {
    this['Facebook Text'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Google Text'] = new SitecoreTextModel();

    this['Enable Text'] = new SitecoreTextModel();

    this['Disbale Text'] = new SitecoreTextModel();

    this['Facebook Login Settings'] = new SignleSignOnModel();

    this['Google Login Settings'] = new SignleSignOnModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Facebook Login Settings')) {
      this['Facebook Login Settings'].getData(dataSource['Facebook Login Settings']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Google Login Settings')) {
      this['Google Login Settings'].getData(dataSource['Google Login Settings']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Facebook Text')) {
      this['Facebook Text'].getData(dataSource['Facebook Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Enable Text')) {
      this['Enable Text'].getData(dataSource['Enable Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Google Text')) {
      this['Google Text'].getData(dataSource['Google Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Disbale Text')) {
      this['Disbale Text'].getData(dataSource['Disbale Text']);
    }

    return this;
  }
}
