// Import dependencies, operators
import { combineEpics } from 'redux-observable';

import allStoreEpic from './allStore';
import { filterAreaListEpic, filterCityListEpic,filterProvinceListEpic,filterZipCodeEpic,getShippingFeeEpic} from './filterOptions';

const storeLocatorEpic = combineEpics(
  allStoreEpic,
  filterAreaListEpic,
  filterProvinceListEpic,
  filterCityListEpic,
  filterZipCodeEpic,
  getShippingFeeEpic
);

export default storeLocatorEpic;
