import './ProductDetailHeader.scss';

import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import Helmet from 'react-helmet';
import { connect, useDispatch, useSelector } from 'react-redux';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import SwipeComponent from '@components/Share/SwipeComponent';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import { checkIsProductDetail, getProductDetailSingle, updateCPCustomer } from '@redux/actions/product';
import { getLayoutElementStyleSelector } from '@redux/reducers/layoutElementStyle/selectors';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

import LoginRegisterModal from '../../../CategoryLanding/LoginRegisterModal/index';
import CategoryProductItem from './CategoryProductItem';
import { isStickyHeader$ } from './handleStickyHeaderService';
import ProductDetailHeaderModel from './model/ProductDetailHeaderModel';
import ProductDetailHeaderBundle from './ProductDetailHeaderBundle';
import ProductDetailHeaderDynamicBundle from './ProductDetailHeaderDynamicBundle';
import ProductHeaderPrice from './ProductHeaderPrice';


const NUMBER_TO_SLIDE = 10;

const ProductDetailHeader = (props) => {
  const TEMPLATE_NAME_STATIC_BUNDLE = 'Bundle Product Page';
  const dispatch = useDispatch();
  const { fields, variantActive, refunableDepositProduct, productName, categoryName } = props;
  const [dataSources, setDataSources] = useState(null);
  const [productId, setProductId] = useState(null);
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [hidePriceAndCTA, setHidePriceAndCTA] = useState(null);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const layoutQuestions = useSelector((state) => state.clientTellingPortalQuickAccess.layoutQuestions);
  const [isProductDetailSticky, setIsProductDetailSticky] = useState(false);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId');
  const commerceProductSourceId = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.id;
  const cpSessionId = SitecoreContextFactoryService.getValueContextItem('cpSessionId') || '';
  const [couponCode, setCouponCode] = useState(null);
  const productDetailHeaderModel = new ProductDetailHeaderModel();
  const productDetailHeaderSelector = useSelector((state) => state.getCatalogHeaderNavigationReducer?.catalogHeaderData);
  const [productDetailHeaderData, setProductDetailHeaderData] = useState(null);
  const [isBundleProduct, setIsBundleProduct] = useState(null);
  const [bundleType, setBundleType] = useState(null);
  const isGA4 = Global.getIsGA4();
  const isDeposit = useSelector((state) => state?.productReducer?.productDetailHeaderReducer?.IsDeposit);
  const isLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const priceDeposit = useSelector((state) => state?.productReducer?.productDetailHeaderReducer?.Deposit);
  const [isShow, setShow] = useState(false);
  const [compareLink, setCompareLink] = useState(null);
  const [buyNowLink, setBuyNowLink] = useState(null);
  const [urlLoginPage, setUrlLoginPage] = useState({});
  const { cookieBannerHeight } = useSelector(getLayoutElementStyleSelector);

  const isShowCompareButton = useMemo(() => {
    return fields ? fields['Is Show Compare Product Button']?.value : false;
  }, [fields]);

  const onShowHandler = () => {
    setShow(true);
    let tempLink = new SitecoreLinkModel();

    tempLink.value.href = `
    ${dataSources?.['Login Button Link']?.value?.href}?from=${dataSources?.['Deposit Button Link']?.value.href}`;

    setUrlLoginPage(tempLink);
  };

  const onCloseHandler = () => {
    setShow(false);
  };
  const productPageId = useMemo(() => SitecoreContextFactoryService.getValueContextRouteItem('itemId'), []);

  // Difference between bundle product and single product on fullReviewPage
  useEffect(() => {
    const isBundleProduct = new URLSearchParams(window.location.search).get('isBundleProduct');
    const bundleType = new URLSearchParams(window.location.search).get('bundleType');

    setIsBundleProduct(isBundleProduct);

    setBundleType(bundleType);
  }, []);

  useEffect(() => {
    dispatch(getProductDetailSingle());

    const hidePriceAndCTA = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.hidePriceAndCTA;

    setHidePriceAndCTA(hidePriceAndCTA);

    setProductId(SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.fields?.ProductId?.value);

    const dataModel = productDetailHeaderModel.getData(deepCopy(fields || {}));

    const categoryName = new URLSearchParams(window.location.search).get('CategoryName');

    dataModel['Buy Now Link'].value.href = `${dataModel['Buy Now Link'].value.href}&variantId=${variantActive?.VariantId || ''}&LstName=${categoryName || ''
      }`;

    dataModel['Compare Link'].value.href = `${dataModel['Compare Link'].value.href}&categoryName=${categoryName || ''}`;

    setDataSources(dataModel);
  }, []);

  useEffect(() => {
    dispatch(checkIsProductDetail(true));
  }, []);

  // CASE: Get data for dropdown menu of single product on fullReviewPage
  useDidUpdateEffect(() => {
    setProductDetailHeaderData(productDetailHeaderSelector?.ProductDetailHeader);
  }, [productDetailHeaderSelector?.ProductDetailHeader]);

  useDidUpdateEffect(() => {
    if (cpCustomerId) {
      dispatch(
        updateCPCustomer({
          ContactID: cpCustomerId,
          CPCustomerInterest: {
            Products: [
              {
                SessionId: cpSessionId,
                ProductId: commerceProductSourceId,
                ProductPageId: productPageId,
                Questions: getDefaultQuestions()
              }
            ]
          }
        })
      );
    }
  }, [layoutQuestions]);

  useDidUpdateEffect(() => {
    const dataModel = productDetailHeaderModel.getData(deepCopy(fields || {}));
    const queryParams = new URLSearchParams(window.location.search);
    const categoryName = queryParams.get('CategoryName');
    const indexGA4 = global.getIndexGA4Param();

    dataModel['Buy Now Link'].value.href = `${dataModel['Buy Now Link'].value.href}&variantId=${variantActive.VariantId}${refunableDepositProduct?.RefunableDepositProduct.length ? '&datatype=RefunableDeposit' : ''
      }${queryParams?.get('cCode') ? '&cCode=' + queryParams?.get('cCode') : ''}&LstName=${categoryName || ''}${indexGA4 ? `&IndexGA4=${indexGA4}` : ''
      }`;

    dataModel['Compare Link'].value.href = `${dataModel['Compare Link'].value.href}&categoryName=${categoryName}`;

    setCouponCode(queryParams?.get('cCode'));

    setDataSources(dataModel);
  }, [variantActive, refunableDepositProduct]);

  const [scrollTop, setScrollTop] = useState(0);

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (scrollTop > 0) {
      setIsProductDetailSticky(true);

      isStickyHeader$.next(true);
    } else {
      setIsProductDetailSticky(false);

      isStickyHeader$.next(false);
    }
  }, [scrollTop]);

  useEffect(() => {
    if (dataSources) {
      let tempLink = deepCopy(dataSources['Compare Link']);
      let buyNowLink = `${dataSources['CP Buy Options Link']?.value?.href}?productPageId=${SitecoreContextFactoryService.getValueContextRouteItem(
        'itemId'
      )}`;
      const search = window.location.search;
      const query = new URLSearchParams(search);
      const categoryPageId = query.get('CategoryPageId');
      const categoryName = query.get('CategoryName');

      tempLink.value.href = `${dataSources['Compare Link'].value.href}?productPageId=${SitecoreContextFactoryService.getValueContextRouteItem('itemId') || ''
        }&categoryName=${categoryName || ''}&categoryPageId=${categoryPageId}`;

      setCompareLink(tempLink);

      setBuyNowLink(buyNowLink);
    }
  }, [dataSources]);

  const onToogleMenu = () => {
    (dataSources['Product Detail Header'].length || productDetailHeaderData) && setMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    if (isMenuOpen) {
      Global.stopScrollingPage();
    }

    return () => {
      Global.scrollingPage();
    };
  }, [isMenuOpen]);

  let params = {
    lazy: {
      loadPrevNext: true
    },
    slidesPerView: 10,
    centeredSlides: false,
    spaceBetween: 50,
    watchOverflow: true,
    watchSlidesVisibility: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },

    breakpoints: {
      992: {
        spaceBetween: 50,
        slidesPerView: 5,
        slidesPerColumn: 2
      },

      1400: {
        spaceBetween: 80,
        slidesPerView: 5,
        slidesPerColumn: 2
      }
    }
  };

  const getDefaultQuestions = () => {
    if (layoutQuestions) {
      const listQuestionNoPOS = layoutQuestions.filter((quest) => quest['Question Type'] === 'Non-POS' || quest['Question Type'] === 'Both');

      return listQuestionNoPOS?.map((question) => {
        return {
          Question: question.Question,
          QuestionCode: question['Question Code'],
          IsPosRelated: question['POS Related'] === '1' ? true : false,
          IsRequired: question['Is Required'] === '1',
          QuestionType: question['Question Type']
        };
      });
    }

    return;
  };

  const handleGa4SelectItem = (ev, action) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      const indexGA4 = queryParams.get('IndexGA4');
      const categoryName = queryParams.get('CategoryName');
      const itemLstName = queryParams.get('LstName');

      let dataConvert = [];

      dataConvert.push(variantActive);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(indexGA4, dataConvert, categoryName, pdpPromoCode, '', '', itemLstName)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }

    if (!isLogin) {
      ev.preventDefault();

      onShowHandler();
    }
  };

  return (
    dataSources &&
    //Header Product Bundle on allReviewPage
    (isBundleProduct == 'true' ? (
      bundleType == TEMPLATE_NAME_STATIC_BUNDLE ? (
        //Static Bundle
        <ProductDetailHeaderBundle
          dataSources={dataSources}
          isProductDetailSticky={isProductDetailSticky}
          isClientTelingUser={isClientTelingUser}
          couponCode={couponCode}
          setCouponCode={setCouponCode}
          cookieBannerHeight={cookieBannerHeight}
        />
      ) : (
        //Dynamic Bundle
        <ProductDetailHeaderDynamicBundle
          dataSources={dataSources}
          isProductDetailSticky={isProductDetailSticky}
          isClientTelingUser={isClientTelingUser}
          cookieBannerHeight={cookieBannerHeight}
        />
      )
    ) : //Header Product Single on PDP and allReviewPage
      variantActive?.VariantId !== '' ? (
        <div
          id='product-detail-header'
          className={`product-detail-header${isProductDetailSticky ? ' product-detail-sticky' : ''} ${isClientTelingUser ? '--cp-product-detail' : ''
            } animated-slow fadeIn delay-100`}
          style={{ top: isProductDetailSticky ? `${cookieBannerHeight}px` : 'unset' }}
        >
          {couponCode ? (
            <Helmet>
              <meta name='robots' content='noindex' />
            </Helmet>
          ) : (
            <></>
          )}
          <div className='product-detail-header__wrap container'>
            <div className='product-detail-header__drop-down' onClick={() => onToogleMenu()}>
              <div className='product-detail-header__product-name'>
                <span className='product-detail-header__product-name__text'>
                  {productName}
                  {dataSources['Product Detail Header']?.length || productDetailHeaderData?.length ? (
                    <i
                      className={`${isMenuOpen == true
                        ? 'product-detail-header__drop-down__arrow icon-chevron-down-big rotate'
                        : 'product-detail-header__drop-down__arrow icon-chevron-down-big'
                        }`}
                    ></i>
                  ) : (
                    <></>
                  )}
                </span>
              </div>
              <ProductHeaderPrice
                price={variantActive?.Price}
                depositLabel={dataSources?.['Deposit Label']?.value}
                sellingPriceWithCurrency={variantActive?.Price?.SellingPriceWithCurrency}
                listPriceWithCurrency={variantActive?.Price?.ListPriceWithCurrency}
                isDeposit={isDeposit}
                wrapperClassName='product-detail-header__price product-detail-header__price--mobile'
              />
            </div>
            {SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Hide Price And CTA']?.value === false || !hidePriceAndCTA ? (
              <div className='product-detail-header__group'>
                <ProductHeaderPrice
                  price={variantActive?.Price}
                  isDeposit={isDeposit}
                  depositLabel={dataSources?.['Deposit Label']?.value}
                  listPriceWithCurrency={variantActive?.Price?.ListPriceWithCurrency}
                  sellingPriceWithCurrency={variantActive?.Price?.SellingPriceWithCurrency}
                  depositPriceWithCurrency={priceDeposit?.DepositPriceWithCurrency}
                  wrapperClassName='product-detail-header__price product-detail-header__price--desktop'
                />

                {/*{isClientTelingUser ? (*/}
                {/*  dataSources?.['CP Buy Options Text']?.value ? (*/}
                {/*    <div className='product-detail-header__btn'>*/}
                {/*      <Link className='btn btn-primary' field={{ href: buyNowLink }} onClick={() => handleGa4SelectItem('Buy Options')}>*/}
                {/*        {isDeposit ? <Text field={dataSources?.['Deposit Button Text']} /> : <Text field={dataSources['CP Buy Options Text']} />}*/}
                {/*      </Link>*/}
                {/*    </div>*/}
                {/*  ) : (*/}
                {/*    <></>*/}
                {/*  )*/}
                {/*) : (*/}
                <div className='product-detail-header__btn'>
                  <BuyButtonComponent
                    productData={{
                      ...variantActive,
                      productId: `${productId}|${variantActive?.VariantId}`,
                      dataSources
                    }}
                  >
                    {/* {dataSources['Buy Now Text'].value ? (
                    <Link
                      className='product-detail-header__btn__item product-detail-header__btn__item--buy-now btn btn-primary'
                      field={dataSources['Buy Now Link']}
                    >
                      <Text field={dataSources['Buy Now Text']} />
                    </Link>
                  ) : (
                    <Link
                      className='product-detail-header__btn__item product-detail-header__btn__item--buy-now btn btn-primary'
                      field={dataSources['Buy Now Link']}
                    />
                  )} */}
                    {isDeposit ? (
                      <>
                        {/* {isLogin ? ( */}
                        <Link
                          className='product-detail-header__btn__item product-detail-header__btn--pay-deposit-now btn btn-primary'
                          field={dataSources?.['Deposit Button Link']}
                          onClick={(event) => handleGa4SelectItem(event, 'Buy Now')}
                        >
                          <Text field={dataSources?.['Deposit Button Text']} />
                        </Link>
                        <LoginRegisterModal isShow={isShow} onCloseHandler={onCloseHandler} dataLayout={dataSources} urlLoginPage={urlLoginPage} />
                        {/* ) : (
                            <>
                              <span className='product-detail-header__btn__item product-detail-header__btn--pay-deposit-now btn btn-primary' onClick={onShowHandler}>{dataSources?.['Deposit Button Text']?.value}</span>
                            </>
                          ) } */}
                      </>
                    ) : (
                      <Link
                        className='product-detail-header__btn__item product-detail-header__btn__item--buy-now btn btn-primary'
                        field={dataSources['Buy Now Link']}
                        onClick={() => handleGa4SelectItem('Buy Now')}
                      >
                        {refunableDepositProduct?.RefunableDepositProduct.length
                          ? messageObj['LBL-RentNowLabel']
                          : variantActive.Stock?.PurchasableLabel}
                      </Link>
                    )}
                  </BuyButtonComponent>
                </div>
                {/*)}*/}
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className={`product-detail-header__drop-down__menu ${isMenuOpen == true ? 'show' : ''}`}>
            {dataSources['Product Detail Header'].length > 0 ? (
              <div className='container'>
                <div className='product-detail-header__drop-down__menu__list-product product-detail-header__drop-down__menu__list-product--mobile'>
                  <div className='row'>
                    {dataSources['Product Detail Header'].map((productItem, productIdx) => (
                      <CategoryProductItem key={productIdx} inputData={productItem} idxProdGA4={productIdx} />
                    ))}
                  </div>
                </div>
                <div
                  className={`product-detail-header__drop-down__menu__list-product product-detail-header__drop-down__menu__list-product--desktop ${dataSources['Product Detail Header'].length <= NUMBER_TO_SLIDE ? 'hidden-swiper-button-disabled' : ''
                    }`}
                >
                  <SwipeComponent param={params}>
                    {dataSources['Product Detail Header'].map((productItem, productIdx) => (
                      <div key={productIdx}>
                        <CategoryProductItem inputData={productItem} idxProdGA4={productIdx} />
                      </div>
                    ))}
                  </SwipeComponent>
                </div>
                {isShowCompareButton ? (
                  dataSources['Compare Link'].value ? (
                    <div className='product-detail-header__drop-down__menu__btn'>
                      <Link className='btn btn-outline-CTA1' field={compareLink}>
                        <Text field={dataSources['Compare Text']} />
                      </Link>
                    </div>
                  ) : (
                    <div className='product-detail-header__drop-down__menu__btn'>
                      <Link className='btn btn-outline-CTA1' field={dataSources['Compare Link']} />
                    </div>
                  )
                ) : (
                  <></>
                )}
              </div>
            ) : (
              productDetailHeaderData?.length > 0 && (
                <div className='container'>
                  <div className='product-detail-header__drop-down__menu__list-product product-detail-header__drop-down__menu__list-product--mobile'>
                    <div className='row'>
                      {productDetailHeaderData.map((productItem, productIdx) => (
                        <CategoryProductItem key={productIdx} inputData={productItem} idxProdGA4={productIdx} />
                      ))}
                    </div>
                  </div>
                  <div
                    className={`product-detail-header__drop-down__menu__list-product product-detail-header__drop-down__menu__list-product--desktop ${productDetailHeaderData?.length <= NUMBER_TO_SLIDE ? 'hidden-swiper-button-disabled' : ''
                      }`}
                  >
                    <SwipeComponent param={params}>
                      {productDetailHeaderData?.map((productItem, productIdx) => (
                        <div key={productIdx}>
                          <CategoryProductItem inputData={productItem} idxProdGA4={productIdx} />
                        </div>
                      ))}
                    </SwipeComponent>
                  </div>
                  {isShowCompareButton ? (
                    dataSources['Compare Link'].value ? (
                      <div className='product-detail-header__drop-down__menu__btn'>
                        <Link className='btn btn-outline-CTA1' field={compareLink}>
                          <Text field={dataSources['Compare Text']} />
                        </Link>
                      </div>
                    ) : (
                      <div className='product-detail-header__drop-down__menu__btn'>
                        <Link className='btn btn-outline-CTA1' field={dataSources['Compare Link']} />
                      </div>
                    )
                  ) : (
                    <></>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      ) : (
        <LoadingShimmer itemNumb={1}>
          <div className={`product-detail-header${isProductDetailSticky ? ' product-detail-sticky' : ''}  skeleton__item`}>
            <div className='product-detail-header__wrap container'>
              <div className='product-detail-header__drop-down'>
                <div className='product-detail-header__product-name'>
                  <span className='product-detail-header__product-name__text'></span>
                </div>
              </div>
              <div className='product-detail-header__group'>
                <div className='product-detail-header__btn image-wrap'>
                  <span className='image-line'></span>
                </div>
              </div>
            </div>
          </div>
        </LoadingShimmer>
      ))
  );
};

ProductDetailHeader.propTypes = {
  fields: Proptypes.object,
  variantActive: Proptypes.object,
  isNotifySuccess: Proptypes.bool,
  refunableDepositProduct: Proptypes.object,
  productName: Proptypes.string,
  categoryName: Proptypes.string
};

const mapStateToProps = (state) => ({
  variantActive: state.productReducer.productDetailHeaderReducer.productVariantCurrent,
  categoryName: state.productReducer.productDetailHeaderReducer.categoryName,
  isNotifySuccess: state.productReducer.notifyProductStatusReducer.isNotifySuccess,
  refunableDepositProduct: state.productReducer.productDetailOverviewReducer.productDetailOverview,
  productName: state.productReducer?.productDetailOverviewReducer?.VariantActiveDefault?.ProductName
});

export default connect(mapStateToProps)(ProductDetailHeader);
