import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ProductCatalogueModel {
  constructor() {
    this['Categories'] = [];

    this['Description'] = new SitecoreTextModel();

    this['Shop By Categories Label'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Categories')) {
      this['Categories'] = dataSource['Categories'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'] = dataSource['Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Shop By Categories Label')) {
      this['Shop By Categories Label'] = dataSource['Shop By Categories Label'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'] = dataSource['Title'];
    }

    return this;
  }
}
