import './Video.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import ResponsiveImage from '@components/ResponsiveImage';
import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import ModalComponent from '@components/Share/ModalComponent';
import VideoPlayer from '@components/Share/VideoPlayer';
import { MEDIA_TRACKING_GOAL_ID, mediaStartTrackingGoal } from '@redux/actions/trackingGoal/mediaTracking/index';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import VideoModel from './model/VideoModel';

const Video = (props) => {
  const {
    fields,
    params,
    rendering: { uid }
  } = props;

  const dispatch = useDispatch();

  const isNotFullWidth = params?.['Is Video Not Full Width'];

  const componentId = (params && params['Component Id']) || '';

  const [dataFields, setDataFields] = useState(null);

  const [isShow, setShow] = useState(false);

  useEffect(() => {
    const videoModel = new VideoModel();

    setDataFields(videoModel.getData(deepCopy(fields) || {}));
  }, []);

  const onShowModalHandler = () => {
    setShow(true);

    dispatch(mediaStartTrackingGoal({
      'GoalId': MEDIA_TRACKING_GOAL_ID,
      'UseTrackerAPI': false
    }));
  };

  const onCloseModalHandler = () => {
    setShow(false);
  };

  const videoIdValue = useMemo(() => {
    return dataFields?.['Video Id']?.value || `video-${uid ?? 'id'}`;
  }, [dataFields?.['Video Id']?.value]);

  return dataFields ? (
    <Fragment>
      {dataFields['Background Video'].value.href !== '' ||
      dataFields['Cover Image']?.value?.src !== '' ||
      dataFields['Cover Mobile Image']?.value?.src !== '' ? (
          <div className={`video animated-slow fadeIn delay-100'${isNotFullWidth == 1 ? ' container not-full-width' : ''}`} id={videoIdValue}>
            {dataFields['Background Video'].value.href !== '' ? (
              <div className='video__background--video'>
                <video playsInline={true} loop='loop' muted='muted' preload='none' autoPlay>
                  <source src={dataFields['Background Video'].value.href} type='video/mp4' />
                </video>
              </div>
            ) : (
              <div className='video__background'>
                <ResponsiveImage
                  imageMobile={dataFields['Cover Mobile Image']}
                  srcSet={[ { mw: 768, image: dataFields['Cover Image'] } ]} 
                  attributes={{
                    alt: dataFields['Cover Image']?.value?.alt
                  }}
                />
              </div>
            )}
            <div className='video__contents'>
              <Text field={dataFields.Title} tag='h2' className='video__contents__title' />
              <RichText field={dataFields.Description} className='video__contents__text' />
              <div className='video__contents__btn-play'>
                <div className='btn-play-icon' onClick={onShowModalHandler}>
                  <span className='play-icon'></span>
                </div>
              </div>
              <ModalComponent isShow={isShow} onCloseHandler={onCloseModalHandler} cssClass={'video-popup'}>
                <VideoPlayer dataFields={dataFields} />
              </ModalComponent>
              {/* <div className='video__contents__btn-play video__contents__btn-play--dark'>
          <div className='btn-play-icon' >
            <span className='play-icon'></span>
          </div>
        </div> */}
            </div>
          </div>
        ) : (
          <></>
        )}
    </Fragment>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='video skeleton__item'>
        <div className='video__background'>
          <Loading></Loading>
        </div>
      </div>
    </LoadingShimmer>
  );
};

Video.propTypes = {
  fields: PropTypes.any,
  params: PropTypes.any,
  rendering: PropTypes.object
};

export default Video;
