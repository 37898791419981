import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/unsubscribe';
import * as unsunscribeTypes from '@redux/actions/unsubscribe/unsubscribeTypes';
import { post } from '@services/genericService';

const ACTION = '/Contact/Unsubscribe';

const unsubscribeEpic = (action$) =>
  action$.pipe(
    ofType(unsunscribeTypes.UN_SUBSCRIBE_NEWS_LETTER),
    switchMap(action => {
      
      return from(post(ACTION, action.payload)).pipe(
        map((res) => {
          if (res.status === 200 && res?.data) {
            return actions.unsubscribeSuccess(res?.data);
          } else {
            return actions.unsubscribeFailed('Failed');
          }
        })
      );
    })
  );

export default unsubscribeEpic;
