import produce from 'immer';

import * as singleSignOnTypes from '@redux/actions/myAppointment/myAppointmentTypes';

const initState = {
  isLoading: false,
  isUpdated: false,
  isUpdateFailed: false,
  result: {},
  statusCode: null
};

const updateAppointmentReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case singleSignOnTypes.UPDATE_APPOINTMENT:
      draft.isLoading = true;

      break;

    case singleSignOnTypes.UPDATE_APPOINTMENT_SUCCESS:
      draft.isLoading = false;

      draft.isUpdated = true;

      draft.statusCode = action.payload?.StatusCode;

      draft.result = action.payload.Result;
      break;

    case singleSignOnTypes.UPDATE_APPOINTMENT_FAILED:
      draft.isLoading = false;

      draft.isUpdateFailed = true;

      draft.statusCode = action.payload?.StatusCode;
      break;
    }

    return draft;
  });

export default updateAppointmentReducer;
