import { object } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const IsNotMemberLoyalty = ({ layoutData }) => {
  const { myRewardInfo } = useSelector((state) => state.loyaltyReducer.getMyRewardWidgetInfoReducer);

  return (
    <>
      <div className='my-reward-widget__content-left'>
        <Text tag='h5' field={layoutData['Not Member Label']} />
        <RichText tag='h5' field={layoutData['Become Member Text']} />
      </div>
      <div className='my-reward-widget__content-right'>
        <Text tag='h4' field={layoutData['Your Status Label']} className='my-reward-widget__content-right__title' />
        <Text
          tag='h5'
          field={{ value: layoutData['Spend Member Label']?.value?.replace('{SpentAmountStr}', myRewardInfo?.SpentAmountStr) }}
          className='content'
        />
        <RichText tag='h5' field={layoutData['See Benefits']} />
      </div>
    </>
  );
};

IsNotMemberLoyalty.propTypes = {
  layoutData: object
};

export default IsNotMemberLoyalty;
