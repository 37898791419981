import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const CartRentalItem = ({ dataSources, rentalInfo }) => {
  return (
    <div className='cart-products__item__rental justify-space-between'>
      <div className='cart-products__item__rental__contents'>
        <div className='product-info-icon product-info-icon--rental'></div>
        <Text tag='span' field={dataSources['Deposit Label']} />
      </div>
      <div className='cart-products__item__rental__price'>{rentalInfo.LineFinalTotalAmount !== 0 ? rentalInfo.LineTotal 
        : <Text field={dataSources['Free Text']} />}</div>
    </div>
  );
};

CartRentalItem.propTypes = {
  rentalInfo: PropTypes.object,
  dataSources: PropTypes.object
};

export default CartRentalItem;
