import {
  GET_CP_PRODUCT_INTEREST_DATA,
  GET_CP_PRODUCT_INTEREST_DATA_FAILED,
  GET_CP_PRODUCT_INTEREST_DATA_SUCCESS,
  REMOVE_CP_PRODUCT_INTEREST, REMOVE_CP_PRODUCT_INTEREST_FAILED,
  REMOVE_CP_PRODUCT_INTEREST_SUCCESS
} from './cpProductInterestDataTypes';

// get product interest data
export const getCpProductInterestData = (payload) => ({
  type: GET_CP_PRODUCT_INTEREST_DATA,
  payload
});

export const getCpProductInterestDataSuccess = (payload) => ({
  type: GET_CP_PRODUCT_INTEREST_DATA_SUCCESS,
  payload
});

export const getCpProductInterestDataFailed = (payload) => ({
  type: GET_CP_PRODUCT_INTEREST_DATA_FAILED,
  payload
});