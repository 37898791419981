import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = ['Select Category Label', 'Title', 'Description Title', 'Select Product Label'];

export default class ProductSupportCTAModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Categories'] = [];

    this['Product Support Detail Page'] = null;
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Categories')) {
      this['Categories'] = dataSource['Categories'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Support Detail Page')) {
      this['Product Support Detail Page'] = deepCopy(dataSource['Product Support Detail Page']);
    }

    return this;
  }
}
