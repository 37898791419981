import * as types from './cpGetStoreListTypes';

export const cpGetStoreList = ((payload) => ({
  type: types.CP_GET_STORE_LIST,
  payload
}));

export const cpGetStoreListSuccess = ((payload) => ({
  type: types.CP_GET_STORE_LIST_SUCCESS,
  payload
}));

export const cpGetStoreListFailed = ((payload) => ({
  type: types.CP_GET_STORE_LIST_FAILED,
  payload
}));