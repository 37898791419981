import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import HighlightedText from '@components/Share/HighlightedText';

const PolicySearchResultItem = ({dataFromAPI, dataFields, highlightText}) => {

  return (
    <Fragment>
      <li className='policy-result__item'>
        <h2 className='policy-result__item__title'>{dataFromAPI['PolicyGroup']}</h2>
        <h4 className='policy-result__item__question'>{dataFromAPI['Title']}</h4>
        <p className='policy-result__item__answer'>
          <HighlightedText text={dataFromAPI['Description']} hightlightText={highlightText}></HighlightedText>
        </p>
        <a href={`${dataFromAPI['PageUrl']}?itemId=${dataFromAPI.ItemId}`} className='policy-result__item__learn-more btn-link'>{dataFields['Learn More Text'].value}</a>
      </li>
    </Fragment>
  );
};

PolicySearchResultItem.propTypes = {
  dataFromAPI: PropTypes.any,
  dataFields: PropTypes.any,
  highlightText: PropTypes.any,
};

export default PolicySearchResultItem;