import PropTypes from 'prop-types';
import React from 'react';

const RecentlyProductItem = ({ product, layoutData, onSelectRecentProduct }) => {
  return (
    <>
      <div className='recently-viewed-products__content-wrapper'>
        <a className='recently-viewed-products__image' href={product.Url}>
          <img className='recently-viewed-products__image__item' src={product?.Variants?.[0]?.Images[0]} alt='Product Image' title='No title' />
        </a>
        <ul className='list-tags'>
          {product?.Variants?.[0]?.Labels?.length ? (
            product?.Variants?.[0]?.Labels?.map((item) => {
              return (
                <li className={`list-tags__item osim-label ${item.IsSpecial ? 'osim-label--highlight' : 'osim-label--default'}`} key={item.Code}>
                  {item.Name}
                </li>
              );
            })
          ) : (
            <></>
          )}
        </ul>
        <h4 className='recently-viewed-products__name'>
          <a href={product.Url}>{product?.ProductName}</a>
        </h4>
        <div className='recently-viewed-products__selling-price'>
          {product?.IsBundle ? product?.Price?.SellingPriceWithCurrency : product?.Variants?.[0]?.Price?.SellingPriceWithCurrency}
        </div>
        {product?.IsBundle ? (
          product?.Price?.SellingPriceWithCurrency !== product?.Price?.ListPriceWithCurrency ? (
            <div className='recently-viewed-products__list-price'>{product?.Price?.ListPriceWithCurrency}</div>
          ) : (
            <></>
          )
        ) : product?.Variants?.[0]?.Price?.SellingPriceWithCurrency !== product?.Variants?.[0]?.Price?.ListPriceWithCurrency ? (
          <div className='recently-viewed-products__list-price'>{product?.Variants?.[0]?.Price?.ListPriceWithCurrency}</div>
        ) : (
          <></>
        )}
      </div>
      <div className='recently-viewed-products__content-wrapper recently-viewed-products__btn-group'>
        <button className='btn btn-primary' onClick={onSelectRecentProduct}>
          {layoutData['Add To Compare Text'].value}
        </button>
      </div>
    </>
  );
};

RecentlyProductItem.propTypes = {
  product: PropTypes.object,
  layoutData: PropTypes.object,
  onSelectRecentProduct: PropTypes.func
};

export default RecentlyProductItem;
