import ProductCardInterfaceModel from '@components/Product/models/ProductCardInterfaceModel';

import FacetsApiModel from './FacetsApiModel';

export default class ProductListingApiModel {
  constructor() {
    this.TotalItems = '';

    this.SearchKeyword = '';

    this.ShowNoResultMessage = '';

    this.ChildProducts = [];

    this.Facets = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalItems')) {
      this.TotalItems = dataSource.TotalItems;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalItems')) {
      this.TotalItems = dataSource.TotalItems;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ShowNoResultMessage')) {
      this.ShowNoResultMessage = dataSource.ShowNoResultMessage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ChildProducts')) {
      if (dataSource['ChildProducts']?.length) {
        dataSource['ChildProducts'].map((item) => {
          this['ChildProducts'].push(new ProductCardInterfaceModel().getData(item || {}));
        });
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Facets')) {
      if (dataSource['Facets']?.length) {
        dataSource['Facets'].map((item) => {
          this['Facets'].push(new FacetsApiModel().getData(item || {}));
        });
      }
    }

    return this;
  }
}
