import * as productTypes from '@redux/actions/product/productTypes.js';

import { ADD_TO_SO_CART_V2, ADD_TO_SO_CART_V2_FAILED, ADD_TO_SO_CART_V2_SUCCESS } from './productTypes';

export const setRangePricePosition = (payload) => ({
  type: productTypes.SET_RANGE_PRICE_POSITION,
  payload
});

export const getFilterProductList = (payload) => ({
  type: productTypes.GET_FILTER_PRODUCT_LIST,
  payload
});

export const getProductList = (payload) => ({
  type: productTypes.GET_PRODUCT_LIST,
  payload
});

export const getProductListSuccess = (payload) => ({
  type: productTypes.GET_PRODUCT_LIST_SUCCESS,
  payload
});

export const getProductListFailed = (error) => ({
  type: productTypes.GET_PRODUCT_LIST_FAILED,
  error
});

//Product Information

export const getProductInformation = (payload) => ({
  type: productTypes.GET_PRODUCT_INFORMATION,
  payload
});

export const getProductInformationSuccess = (payload) => ({
  type: productTypes.GET_PRODUCT_INFORMATION_SUCCESS,
  payload
});

export const getProductInformationFailed = (error) => ({
  type: productTypes.GET_PRODUCT_INFORMATION_FAILED,
  error
});

//Product Detail
export const setVariantProductDetailOverviewActive = (payload) => ({
  type: productTypes.SET_PRODUCT_DETAIL_OVERVIEW_ACTIVE,
  payload
});

export const setVariantProductDetailHeader = (payload) => ({
  type: productTypes.SET_VARIANT_PRODUCT_DETAIL_HEADER,
  payload
});

export const setRecentlyProduct = (payload) => ({
  type: productTypes.SET_RECENTTLY_PRODUCT,
  payload
});

export const changeRecentlyProduct = (payload) => ({
  type: productTypes.CHANGE_RECENTTLY_PRODUCT,
  payload
});

export const setStatusProductDetailVariant = (payload) => ({
  type: productTypes.SET_STATUS_PRODUCT_DETAIL_VARIANT,
  payload
});

export const setProductDetailVariant = (payload, productName, Price) => ({
  type: productTypes.SET_PRODUCT_DETAIL_VARIANT,
  payload,
  productName,
  Price
});

export const changeProductDetailVariant = (payload) => ({
  type: productTypes.CHANGE_PRODUCT_DETAIL_VARIANT,
  payload
});

export const setProductDetailImages = (payload) => ({
  type: productTypes.SET_PRODUCT_DETAIL_IMAGES,
  payload
});

export const getProductDetailHeader = (payload) => ({
  type: productTypes.GET_PRODUCT_DETAIL_HEADER,
  payload
});

export const getProductDetailHeaderSuccess = (payload) => ({
  type: productTypes.GET_PRODUCT_DETAIL_HEADER_SUCCESS,
  payload
});

export const getProductDetailHeaderFailed = (error) => ({
  type: productTypes.GET_PRODUCT_DETAIL_HEADER_FAILED,
  error
});

export const getProductDetailOverview = (payload) => ({
  type: productTypes.GET_PRODUCT_DETAIL_OVERVIEW,
  payload
});

export const getProductDetailOverviewSuccess = (payload) => ({
  type: productTypes.GET_PRODUCT_DETAIL_OVERVIEW_SUCCESS,
  payload
});

export const getProductDetailOverviewFailed = (error) => ({
  type: productTypes.GET_PRODUCT_DETAIL_OVERVIEW_FAILED,
  error
});

export const setVariantSelected = (payload) => ({
  type: productTypes.GET_VARIANT_SELECTED,
  payload
});

export const resetMessagePopupNotify = (payload) => ({
  type: productTypes.RESET_NOTIFY_MESSAGE_POPUP,
  payload
});

export const getNotifyStatusProduct = (payload) => ({
  type: productTypes.GET_NOTIFY_STATUS_PRODUCT,
  payload
});

export const getNotifyStatusProductSuccess = (payload) => ({
  type: productTypes.GET_NOTIFY_STATUS_PRODUCT_SUCCESS,
  payload
});

export const getNotifyStatusProductFailed = (error) => ({
  type: productTypes.GET_NOTIFY_STATUS_PRODUCT_FAILED,
  error
});

export const getProductDetailStaticBundleOverview = (payload) => ({
  type: productTypes.GET_PRODUCT_DETAIL_STATIC_BUNDLE_OVERVIEW,
  payload
});

//Product Also Bought
export const getCrossSale = () => ({
  type: productTypes.GET_CROSS_SALE_ITEM
});

export const getCrossSaleSuccess = (payload) => ({
  type: productTypes.GET_CROSS_SALE_ITEM_SUCCESS,
  payload
});

export const getCrossSaleFailed = (error) => ({
  type: productTypes.GET_CROSS_SALE_ITEM_FAILED,
  error
});

// Free Gift
export const getFreeGift = (payload) => ({
  type: productTypes.GET_FREE_GIFT,
  payload
});

export const getFreeGiftSuccess = (payload) => ({
  type: productTypes.GET_FREE_GIFT_SUCCESS,
  payload
});

export const getFreeGiftFailed = (error) => ({
  type: productTypes.GET_FREE_GIFT_FAILED,
  error
});

// Special Deal
export const getSpecialDeal = () => ({
  type: productTypes.GET_SPECIAL_DEAL
});

export const getSpecialDealSuccess = (payload) => ({
  type: productTypes.GET_SPECIAL_DEAL_SUCCESS,
  payload
});

export const getSpecialDealFailed = (error) => ({
  type: productTypes.GET_SPECIAL_DEAL_FAILED,
  error
});

// Shipping Option - Buying Otion
export const getDefaultLocator = (payload) => ({
  type: productTypes.GET_DEFAULT_LOCATOR,
  payload
});

export const getDefaultLocatorSuccess = (payload) => ({
  type: productTypes.GET_DEFAULT_LOCATOR_SUCCESS,
  payload
});

export const getDefaultLocatorFailed = (error) => ({
  type: productTypes.GET_DEFAULT_LOCATOR_FAILED,
  error
});

export const getStoresList = (payload) => ({
  type: productTypes.GET_STORE_LIST,
  payload
});

export const getStoresListSuccess = (payload) => ({
  type: productTypes.GET_STORE_LIST_SUCCESS,
  payload
});

export const getStoresListFailed = (error) => ({
  type: productTypes.GET_STORE_LIST_FAILED,
  error
});

export const setStoreSelected = (payload) => ({
  type: productTypes.SET_SELECTED_STORE,
  payload
});

// Warranty Product
export const getWarranty = () => ({
  type: productTypes.GET_WARRANTY
});

export const getWarrantySuccess = (payload) => ({
  type: productTypes.GET_WARRANTY__SUCCESS,
  payload
});

export const getWarrantyFailed = (error) => ({
  type: productTypes.GET_WARRANTY__FAILED,
  error
});

export const setDefaultWarranty = (payload) => ({
  type: productTypes.SET_DEFAULT_WARRANTY,
  payload
});

export const setActiveWarranty = (payload) => ({
  type: productTypes.SET_ACTIVE_WARRANTY,
  payload
});

export const setSelectedWarranty = (payload) => ({
  type: productTypes.SET_SELECTED_WARRANTY,
  payload
});

export const getProductWishlist = (payload) => ({
  type: productTypes.GET_PRODUCT_WISH_LIST,
  payload
});

export const addProductWishlist = (payload) => ({
  type: productTypes.ADD_PRODUCT_WISH_LIST,
  payload
});

export const getProductWishlistAboutUserId = (payload) => ({
  type: productTypes.GET_PRODUCT_WISH_LIST_USER_ID,
  payload
});

export const getProductWishlistSuccess = (payload) => ({
  type: productTypes.GET_PRODUCT_WISH_LIST_SUCCESS,
  payload
});

export const addProductWishlistSuccess = (payload) => ({
  type: productTypes.ADD_PRODUCT_WISH_LIST_SUCCESS,
  payload
});

export const removeProductWishlist = (payload) => ({
  type: productTypes.REMOVE_PRODUCT_WISH_LIST,
  payload
});

export const removeProductWishlistSuccess = (payload) => ({
  type: productTypes.REMOVE_PRODUCT_WISH_LIST_SUCCESS,
  payload
});

export const actionProductWishlistFailed = (error) => ({
  type: productTypes.ACTION_PRODUCT_WISH_LIST_FAILED,
  error
});

export const addToCart = (payload) => ({
  type: productTypes.ADD_TO_CART,
  payload
});

export const addToCartSuccess = (payload) => ({
  type: productTypes.ADD_TO_CART_SUCCESS,
  payload
});

export const addToCartFailed = (error) => ({
  type: productTypes.ADD_TO_CART_FAILED,
  error
});

export const addToSOCart = (payload) => ({
  type: productTypes.ADD_TO_SO_CART,
  payload
});

export const addToSOCartSuccess = (payload) => ({
  type: productTypes.ADD_TO_SO_CART_SUCCESS,
  payload
});

export const addToSOCartFailed = (error) => ({
  type: productTypes.ADD_TO_SO_CART_FAILED,
  error
});

export const getCurrentCart = (cartLineId) => ({
  type: productTypes.GET_CURRENT_CART,
  cartLineId
});

export const getCurrentCartSuccess = (payload, filteredCartLine) => ({
  type: productTypes.GET_CURRENT_CART_SUCCESS,
  payload,
  filteredCartLine
});

export const getCurrentCartFailed = (error) => ({
  type: productTypes.GET_CURRENT_CART_FAILED,
  error
});

export const getCurrentSOCart = (cartLineId) => ({
  type: productTypes.GET_CURRENT_SO_CART,
  cartLineId
});

export const getCurrentSOCartSuccess = (payload, filteredCartLine) => ({
  type: productTypes.GET_CURRENT_SO_CART_SUCCESS,
  payload,
  filteredCartLine
});

export const getCurrentSOCartFailed = (error) => ({
  type: productTypes.GET_CURRENT_SO_CART_FAILED,
  error
});

export const setProductWishlistCurrent = (payload) => ({
  type: productTypes.SET_PRODUCT_WISH_LIST_CURRENT,
  payload
});

export const getProductDetailSingle = () => ({
  type: productTypes.GET_PRODUCT_DETAIL_SINGLE
});

export const getGiftingOption = () => ({
  type: productTypes.GET_GIFTING_OPTION
});

export const getGiftingOptionSuccess = (payload) => ({
  type: productTypes.GET_GIFTING_OPTION_SUCCESS,
  payload,
});

export const getGiftingOptionFailed = (error) => ({
  type: productTypes.GET_GIFTING_OPTION_FAILED,
  error
});

export const actionRemoveGiftingOption = (payload) => ({
  type: productTypes.REMOVE_GIFTING_OPTION,
  payload
});

export const saveSOCart = (payload) => ({
  type: productTypes.SAVE_SO_CART,
  payload
});

export const saveSOCartSuccess = (payload, filteredCartLine) => ({
  type: productTypes.SAVE_SO_CART_SUCCESS,
  payload
});

export const saveSOCartFailed = (error) => ({
  type: productTypes.SAVE_SO_CART_FAILED,
  error
});

//cp update cp customer
export const updateCPCustomer = (payload) => ({
  type: productTypes.UPDATE_CP_CUSTOMER,
  payload
});

export const updateCPCustomerSuccess = (payload) => ({
  type: productTypes.UPDATE_CP_CUSTOMER_SUCCESS,
  payload
});

export const updateCPCustomerFailed = (error) => ({
  type: productTypes.UPDATE_CP_CUSTOMER_FAILED,
  error
});

export const checkIsProductDetail = (payload) => ({
  type: productTypes.IS_PRODUCT_DETAIL,
  payload
});

export const addToCartV2 = (payload) => ({
  type: productTypes.ADD_TO_CART_V2,
  payload
});

export const turnUpdateAddressLoadingMaskOff = () => ({
  type: productTypes.TURN_UPDATE_ADDRESS_LOADING_MASK_OFF
});

export const addToSOCartV2 = (payload) => ({
  type: productTypes.ADD_TO_SO_CART_V2,
  payload
});

export const addToSOCartV2Success = (payload) => ({
  type: productTypes.ADD_TO_SO_CART_V2_SUCCESS,
  payload
});

export const addToSOCartV2Failed = (error) => ({
  type: productTypes.ADD_TO_SO_CART_V2_FAILED,
  error
});