import PropTypes from 'prop-types';
import React, { useEffect } from 'react';

import { behaviorIconNavigation$ } from './IconNavigationBehavior';
import IconNavigationItem from './IconNavigationItem';

const IconNavigation = (props) => {
  const data = props.fields?.items || [];

  useEffect(() => {
    if (data.length) {
      behaviorIconNavigation$.next(true);
    } else {
      behaviorIconNavigation$.next(false);
    }
  }, []);

  return (
    data.length > 0 && (
      <ul className='quick-nav-list'>
        {data.map((item) => {
          return <IconNavigationItem contentData={item.fields} key={item.id}></IconNavigationItem>;
        })}
      </ul>
    )
  );
};

IconNavigation.propTypes = {
  fields: PropTypes.any
};

export default IconNavigation;
