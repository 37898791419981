import './SingleFeaturedProduct.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { deepCopy } from '@utils/utility';

import SingleFeaturedProductModel from './model/SingleFeaturedProductModel';

const SingleFeaturedProduct = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new SingleFeaturedProductModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  return (
    layoutData && (
      <div className='single-featured-product'>
        <div className='container single-featured-product__container'>
          <div className='single-featured-product__row'>
            <div className='single-featured-product__col'>
              <div className='single-featured-product__image flex-between'>
                <img src={layoutData.ImageURLs || ''} alt='x' />
              </div>
            </div>
            <div className='single-featured-product__col'>
              <div className='single-featured-product__contents'>
                {layoutData.ProductName ? (
                  <h3 className='single-featured-product__contents__title'>
                    <a href={layoutData.ProductPageUrl || ''}>{layoutData.ProductName}</a>
                  </h3>
                ) : (
                  <></>
                )}
                {layoutData['LearnMoreText'] ? (
                  <div className='single-featured-product__contents__button'>
                    <a href={layoutData.ProductPageUrl || ''} className='btn-link'>
                      {layoutData['LearnMoreText']}
                    </a>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

SingleFeaturedProduct.propTypes = {
  fields: object
};

export default SingleFeaturedProduct;
