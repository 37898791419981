import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

class MonthDiscountedItemsModel {
  constructor() {
    this['Buy Now Button Link'] = new SitecoreLinkModel();

    this['Buy Now Button Text'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Products'] = [];

    this['ListProducts'] = [];

    this['Anchor ID'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Link')) {
      this['Buy Now Button Link'] = dataSource['Buy Now Button Link'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Text')) {
      this['Buy Now Button Text'] = dataSource['Buy Now Button Text'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'] = dataSource['Title'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products')) {
      this['Products'] = dataSource['Products'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListProducts')) {
      this['ListProducts'] = dataSource['ListProducts'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Anchor ID')) {
      this['Anchor ID'] = dataSource['Anchor ID'];
    }
    
    return this;
  }
}
 
export default MonthDiscountedItemsModel;