import './SearchBoxInput.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { propTypes } from 'react-bootstrap/esm/Image';
import { connect, useDispatch } from 'react-redux';

import { getSearchBoxSuggestionList } from '@redux/actions/searchBox';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import * as configs from '../configs';
import AutoCompleteSuggestionList from './SearchBoxInputSuggestList';

const SearchBoxInput = ({
  isShowSearchBar, 
  initSuggestionList,
  newSuggestionList,
  onSearchHandler,
  onToggleSearchBar,
  placeHolderText,
  onDataSource }) => {

  const dispatch = useDispatch();
 
  const searchContainer = useRef(null);

  const inputRef = useRef(null);

  const searchResultRef = useRef(null);

  const [search, setSearch] = useState('');

  const [suggestionList, setSuggestionList] = useState(initSuggestionList);

  const [cursor, setCursor] = useState(configs.INIT_CURSOR_NUMB);

  // Auto Focus and Clear Text
  useDidUpdateEffect(() => {
    if (isShowSearchBar) {
      setSearch('');

      setTimeout(() => {
        inputRef.current.focus();
      }, 100);
    }
  }, [isShowSearchBar]);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => handleClickOutside(e));

    return () => {
      document.addEventListener('mousedown', (e) => handleClickOutside(e));
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchContainer.current && !searchContainer.current.contains(event.target)) {
      onToggleSearchBar(false);
    }
  };

  //get Suggestion List when typing
  useEffect(() => {
    if (search.trim().length >= 3) {
      dispatch(getSearchBoxSuggestionList({
        value: search,
        dataSourcePath: onDataSource
      }));
    } else {
      search.trim().length === 0 
        ? setSuggestionList(initSuggestionList)
        : setSuggestionList([]);
    }
  }, [search]);

  // set newSuggestionList
  useEffect(() => {
    if (search.trim().length >= 3) {
      setSuggestionList(newSuggestionList);
    }
  }, [newSuggestionList]);

  const onChangeHandler = (event) => {
    setSearch(event.target.value);
  };

  const onKeyDownHandler = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();

      if (suggestionList.length) {
        setCursor(prevCursor => prevCursor < suggestionList.length - 1 ? prevCursor + 1 : prevCursor);
      }
    }

    if (event.key == 'ArrowUp') {
      event.preventDefault();

      setCursor(prevCursor => (prevCursor > 0 ? prevCursor - 1 : -1));
    }

    if (event.key == 'Escape') {
      event.preventDefault();

      onToggleSearchBar();
    }

    if (event.key == 'Enter') {
      event.preventDefault();
      
      if (cursor >= 0) {
        setSearch(suggestionList[cursor]);
  
        onSearchHandler(suggestionList[cursor]);
  
      } else {
        setSearch(search);

        onSearchHandler(search);
      }

      onToggleSearchBar(false);
    }
  };

  return (
    <div className='searchbox--autocomplete header__search__input__wrapper' ref={searchContainer}>
      <form className='autocomplete--form' action='.'>
        <input
          ref={inputRef}
          type='search'
          name='search-autocomplete'
          className='autocomplete--input'
          placeholder={placeHolderText}
          onChange={(event) => onChangeHandler(event)}
          onKeyDown={(event) => onKeyDownHandler(event)}
          value={search || ''}
          autoComplete='off'
        />
      </form>

      <div className={'header__search__suggestions' + `${isShowSearchBar && suggestionList.length ? ' visible' : ''}`}>
        <ul className='header__search__suggestions__list' ref={ searchResultRef }>
          {suggestionList.map((item, itemIdx) => {
            return (
              <AutoCompleteSuggestionList
                key={itemIdx}
                index={itemIdx}
                onSelectItem={() => {
                  onToggleSearchBar(false);

                  setSearch(item);

                  onSearchHandler(item);
                }}
                isHighlighted={cursor === itemIdx ? true : false}
                data={item}
              />
            );
          })}
        </ul>
      </div>
    </div>
  );
};

SearchBoxInput.propTypes = {
  isShowSearchBar: PropTypes.any,
  initSuggestionList: PropTypes.any,
  newSuggestionList: PropTypes.any,

  onToggleSearchBar: PropTypes.func, 
  onSearchHandler: PropTypes.func,
  placeHolderText: PropTypes.any,
  onDataSource: PropTypes.any
};

const mapStateToProps = (state) => {
  const searchBoxReducer = state.searchBoxReducer;

  return {
    newSuggestionList: searchBoxReducer.suggestionList || [],
  };
};

export default connect(mapStateToProps)(SearchBoxInput);