import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import Select from 'react-select';

import { getSelectedSelectionValue } from '@redux/actions/unsubscribe';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import CustomLabel from '../../FieldTypes/CustomLabel';
import FormFieldError from '../../FormFieldError';

const CustomDropdownList = (props) => {
  const [inputData, setInputData] = useState(null);
  const { field, value, onChange, tracker, errors, objMessages } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    let temp = deepCopy(field);

    temp.model.items.forEach((item) => {
      item.label = item.text;

      item.selected = false;
    });

    setInputData(deepCopy(temp));
  }, []);

  useDidUpdateEffect(() => {
    dispatch(
      getSelectedSelectionValue({
        [field?.valueField?.name]: {
          itemId: field?.valueField?.name || '',
          value: null
        }
      })
    );
  }, [inputData]);

  const handleOnChange = (field, newValue, callback) => {
    let valid = true;
    const errorMessages = [];

    // custom client validation logic here
    if (field?.valueField?.name === props.selectedSelectionValue?.[field?.valueField?.name]?.itemId) {
      if (field.model.required && !newValue) {
        valid = false;

        errorMessages.push(`${field.model.title} is required`);
      }

      inputData.model.items.forEach((item) => {
        if (item.itemId === newValue.itemId) {
          item.selected = true;

          callback(field.valueField.name, [newValue], valid, errorMessages);
        } else item.selected = false;
      });

      dispatch(
        getSelectedSelectionValue({
          [field?.valueField?.name]: {
            ...props.selectedSelectionValue[field?.valueField?.name],
            value: newValue
          }
        })
      );

      callback(field.valueField.name, [newValue], valid, errorMessages);
    }
  };

  return (
    inputData && (
      <div className='form-group form-group--select'>
        <CustomLabel {...props} />
        <Select
          options={inputData.model.items}
          value={props.selectedSelectionValue?.[field.valueField?.name]?.value}
          name='area-selections'
          placeholder={inputData?.model?.title}
          className='customization-dropdown'
          classNamePrefix='customization-dropdown'
          isSearchable={true}
          onChange={(option) => handleOnChange(field, option, onChange)}
          onFocus={() => tracker.onFocusField(field, value)}
          onBlur={() => tracker.onBlurField(field, value, errors)}
          noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
        />
        <FormFieldError {...props} />
      </div>
    )
  );
};

CustomDropdownList.propTypes = {
  field: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tracker: PropTypes.any,
  errors: PropTypes.any,
  selectedSelectionValue: PropTypes.object,
  objMessages: PropTypes.object
};

const mapStateToProps = (state) => ({
  selectedSelectionValue: state.unsubscribeReducer.selectedSelectionValue || {},
  objMessages: state.getMessageReducer?.objMessages || null
});

export default connect(mapStateToProps)(CustomDropdownList);
