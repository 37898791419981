import PriceApiModel from '@components/Product/models/PriceApiModel';

export default class BOWarrantyModel {
  constructor() {
    this.CurrencyCode = '';

    this.Description = '';

    this.DisplayName = '';

    this.Images = [];

    this.ItemId = '';

    this.ProductId = '';
    
    this.ProductOverview = '';
    
    this.ProductPageUrl = '';

    this.Price = new PriceApiModel();

    this.ListPrice = 0;
    
    this.SellingPrice = 0;

    this.SellingPriceWithCurrency = '';

    this.ListPriceWithCurrency = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyCode')) {
      this.CurrencyCode = dataSource?.Price?.CurrencyCode ? dataSource.Price.CurrencyCode : this.CurrencyCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description ? dataSource.Description : this.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayName')) {
      this.DisplayName = dataSource.DisplayName ? dataSource.DisplayName : this.DisplayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      this.Images = dataSource.Images ? dataSource.Images : this.Images;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId')) {
      this.ItemId = dataSource.ItemId ? dataSource.ItemId : this.ItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this.ProductId = dataSource.ProductId ? dataSource.ProductId : this.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductOverview')) {
      this.ProductOverview = dataSource.ProductOverview ? dataSource.ProductOverview : this.ProductOverview;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductPageUrl')) {
      this.ProductPageUrl = dataSource.ProductPageUrl ? dataSource.ProductPageUrl : this.ProductPageUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }

    return this;
  }
}
