import './BOShippingInfo.scss';

import Proptypes from 'prop-types';
import React, { useState } from 'react';
import { Fragment } from 'react';

import AdditionalFullFilmentOption from '@components/Share/AdditionalFullFilmentOption';
import ModalComponent from '@components/Share/ModalComponent';
import SFExpressPopup from '@components/SlicingPage/CartDetails/SFExpressPopup';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText,Text } from '@sitecore-jss/sitecore-jss-react';
import { DELIVERY_OPTION } from '@utils/constant';

const BOShippingInfo = (props) => {
  const { deliveryOptions, deliveryStatus, statusLBL, dataTextFields } = props;
  const [isShowSFExpress, setShowSFExpress] = useState(false);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  const onCloseModalHandler = () => {
    setShowSFExpress(false);
  };

  return (
    <div className={`${isClientTelingUser ? 'col-xl-6 shipping-info-cp' : 'shipping-info'}`}>
      <div className='shipping-info__wrap'>
        {deliveryOptions?.length
          ? deliveryOptions.map((deliveryItem) => (
            <div className='shipping-info__item shipping-info--delivery' key={deliveryItem.ExternalId}>
              <div className='shipping-info__item__icon'>
                {DELIVERY_OPTION.ShipItem === deliveryItem.ShippingOptionType.Value ?
                  <div className='fulfillment-icon fulfillment-icon--ship-item'></div> : ''}
                {DELIVERY_OPTION.PickUpStore === deliveryItem.ShippingOptionType.Value ?
                  <div className='fulfillment-icon fulfillment-icon--pickup-store'></div> : ''}
                {DELIVERY_OPTION.SFExpress === deliveryItem.ShippingOptionType.Value ?
                  <div className='fulfillment-icon fulfillment-icon--sf-express'></div> : ''}
                {DELIVERY_OPTION.DeliveryBySFExpress === deliveryItem.ShippingOptionType.Value ?
                  <div className='fulfillment-icon fulfillment-icon--delivery-sf-express'></div> : ''}
              </div>
              <div className='shipping-info__item__text'>
                <span className='shipping-info__item__text__title'>{deliveryItem.Name}</span>
                {/* <span className='shipping-info__item__text__desc'>{DELIVERY_OPTION.ShipItem !== deliveryItem.ShippingOptionType.Value ? deliveryItem.Description : null}</span> */}

                {DELIVERY_OPTION.SFExpress === deliveryItem.ShippingOptionType.Value ?
                  <Fragment>
                    <span className='shipping-info__item__text__desc'>
                      <Text className='smaller-text' field={dataTextFields['Available Label']} tag='span'/>
                      <span className='shipping-info__item__text__desc'>
                        {/* <Text className='smaller-text--inline smaller-text--underline' onClick={() => setShowSFExpress(true)} tag='span' field={dataTextFields['SF Express Station Label']} /> */}
                        <RichText field={dataTextFields['SF Express Station Label']} />
                      </span>
                    </span>
                  </Fragment> : null
                } 

                {DELIVERY_OPTION.ShipItem === deliveryItem.ShippingOptionType.Value ?
                  <Fragment>
                    <span className='shipping-info__item__text__desc'>{statusLBL}{!deliveryStatus ? '' : '. '}{deliveryStatus}</span>
                    <AdditionalFullFilmentOption inputData={dataTextFields['Additional Fulfillment Info']}/>
                  </Fragment> : null }
                {DELIVERY_OPTION.PickUpStore === deliveryItem.ShippingOptionType.Value ?
                  <span className='shipping-info__item__text__desc link-store'>3 Stores</span> : null}
                {DELIVERY_OPTION.DeliveryBySFExpress === deliveryItem.ShippingOptionType.Value ?
                  <Fragment>
                    <span className='shipping-info__item__text__desc'>
                      <span className='smaller-text'>{deliveryItem?.Description}</span>
                    </span>
                  </Fragment> : null
                } 
              </div>
            </div>
          ))
          : ''}
      </div>
      <ModalComponent isShow={isShowSFExpress} onCloseHandler={onCloseModalHandler} titlePopup={<Text field={dataTextFields['SF Express Popup Title']}/>}>
        <SFExpressPopup dataSources={dataTextFields}/>
      </ModalComponent>
    </div>
  );
};

BOShippingInfo.propTypes = {
  deliveryOptions: Proptypes.array,
  deliveryStatus: Proptypes.any,
  statusLBL: Proptypes.any,
  dataTextFields: Proptypes.any,
};

export default BOShippingInfo;
