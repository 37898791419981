import { bool, number, object } from 'prop-types';
import React, { useCallback } from 'react';
import Select from 'react-select';

import { deepCopy } from '@utils/utility';

import useEditAppointmentFormContext from '../useEditAppointmentFormContext';

const QuestionComponent = ({ question, index, pastAppointmentEdit }) => {
  const { formik } = useEditAppointmentFormContext();

  const handleSelectAnswer = useCallback(
    (typeQuestion, questionId, answerIds) => {
      const questionAnswersData = deepCopy(formik.values.contactAnswers);
      const questionAnswering = questionAnswersData.find((question) => question.Id === questionId);

      if (typeQuestion.toLowerCase() === 'multi select') {
        if (questionAnswering?.Answers.length) {
          let checkAnswer = questionAnswering?.Answers.find(ans => ans.AnswerId === answerIds.toLowerCase());

          if(checkAnswer) {
            if (checkAnswer.AnswerId === answerIds.toLowerCase()) {
              let indexAnswerId = questionAnswering.Answers.findIndex((ansId) => ansId.AnswerId === answerIds.toLowerCase());

              questionAnswering.Answers.splice(indexAnswerId, 1);
            }
          } else {
            questionAnswering.Answers.push({
              AnswerId: answerIds.toLowerCase()
            });
          }
        } else questionAnswering.Answers.push({
          AnswerId: answerIds.toLowerCase()
        });
      } else {
        if (questionAnswering?.Answers.length) {
          questionAnswering.Answers.splice(0, 1, {
            AnswerId: answerIds.toLowerCase()
          });
        }
        else questionAnswering.Answers.push({
          AnswerId: answerIds.toLowerCase()
        });
      }

      formik.setFieldValue('contactAnswers', questionAnswersData);
    },
    [formik.values.contactAnswers]
  );

  const handleGenerateDefaultCheck = (typeQuestion, questionId, answerIds) => {
    const questionData = formik.values.contactAnswers.find((question) => question.Id === questionId);
    let checkAnswer;
    if (typeQuestion.toLowerCase() === 'drop down') {
      checkAnswer = question.AllAnswers.find((ans) => ans.AnswerId.toLowerCase() === questionData.Answers[0]?.AnswerId);

      if (checkAnswer) {
        return {
          value: checkAnswer.AnswerId,
          label: checkAnswer.AnswerName
        };
      }
    } else {
      checkAnswer = questionData?.Answers.find(ans => ans.AnswerId === answerIds.toLowerCase());
      if (checkAnswer) {
        return checkAnswer.AnswerId === answerIds.toLowerCase();
      } else {
        return false;
      }
    }
  };

  // Handle Generate Answer To Select component
  const handleGenerateAnswerSelect = (answers) => {
    let listAnswer = [];
    for (const ans of answers) {
      listAnswer.push({
        value: ans.AnswerId,
        label: ans.AnswerName
      });
    }

    return listAnswer;
  };

  return (
    <dl className='appointments-infor__item'>
      <dt className='appointments-infor__item__label'>{question?.Question}</dt>
      <dd
        className={`appointments-infor__item__value ${
          question?.DisplayTypes.toLowerCase() === 'multi select'
            ? 'edit-appointment-form__item-checkbox'
            : question?.DisplayTypes.toLowerCase() === 'single select'
              ? 'edit-appointment-form__item-radio'
              : ''
        }`}
      >
        {question?.DisplayTypes.toLowerCase() === 'drop down' ? (
          <>
            <Select
              options={handleGenerateAnswerSelect(question?.AllAnswers)}
              onChange={(option) => handleSelectAnswer(question?.DisplayTypes, question.Id, option.value)}
              isSearchable={false}
              defaultValue={handleGenerateDefaultCheck(question?.DisplayTypes, question.Id)}
              classNamePrefix='customization-dropdown'
              className='customization-dropdown'
              isDisabled={pastAppointmentEdit}
            />
          </>
        ) : (
          question?.AllAnswers?.map((ans, idx) =>
            question?.DisplayTypes.toLowerCase() === 'multi select' ? (
              <div key={idx} className='osim-checkbox'>
                <input
                  type='checkbox'
                  className='osim-checkbox-input'
                  value={ans.AnswerName}
                  onChange={() => handleSelectAnswer(question?.DisplayTypes, question.Id, ans.AnswerId)}
                  checked={handleGenerateDefaultCheck(question?.DisplayTypes, question.Id, ans.AnswerId)}
                  disabled={pastAppointmentEdit}
                />
                <label className='osim-checkbox-label' onClick={() => handleSelectAnswer(question?.DisplayTypes, question.Id, ans.AnswerId)}>
                  {ans.AnswerName}
                </label>
              </div>
            ) : (
              <div key={idx} className='form-group form-group--gender select-store__content__question__item__answer'>
                <div className='osim-radio osim-radio--mg-bottom'>
                  <input
                    className='osim-radio-input'
                    type='radio'
                    checked={handleGenerateDefaultCheck(question?.DisplayTypes, question.Id, ans.AnswerId)}
                    onChange={() => handleSelectAnswer(question?.DisplayTypes, question.Id, ans.AnswerId)}
                    disabled={pastAppointmentEdit}
                  />
                  <label className='osim-radio-label osim-radio-label--small' />
                  <label
                    className='osim-radio-title osim-radio-title--gray'
                    onClick={() => handleSelectAnswer(question?.DisplayTypes, question.Id, ans.AnswerId)}
                  >
                    {ans.AnswerName}
                  </label>
                </div>
              </div>
            )
          )
        )}
        {formik.errors.contactAnswers?.[index]?.Answers && formik.touched.contactAnswers?.[index]?.Id && (
          <span className='error-validate'>{formik.errors.contactAnswers?.[index]?.Answers}</span>
        )}
      </dd>
    </dl>
  );
};

QuestionComponent.propTypes = {
  question: object,
  index: number,
  pastAppointmentEdit: bool
};

export default QuestionComponent;
