import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Message from '@components/Share/Message';
import { login } from '@redux/actions/singleSignOn';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import global from '@utils/global';

import { useCampaignContext } from '../useCampaignContext';

const LoginForm = ({ error, isLoginSuccess }) => {
  const dispatch = useDispatch();
  const { layoutData } = useCampaignContext();

  const formik = useFormik({
    initialValues: {
      emailLogin: '',
      passwordLogin: ''
    },
    validationSchema: Yup.object({
      passwordLogin: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .trim()
        .matches(global.formatPassword(MINUMUM_CHARACTERS_PASSWORD), <Message messageCode='Msg_001.5' />),
      emailLogin: Yup.string()
        .email(<Message messageCode='Msg_001.3' />)
        .required(<Message messageCode='Msg_001.3' />)
        .trim()
    }),
    onSubmit: (values) => {
      dispatch(
        login({
          UserName: values.emailLogin,
          PassWord: values.passwordLogin
        })
      );
    },
    validateOnMount: true
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className='form-group'>
        {error?.errorMessage && !isLoginSuccess ? (
          <span className='error-validate'>
            {error === 'Failed' ? <Message messageCode='Msg_003' /> : <RichText field={{ value: error.errorMessage }} />}
          </span>
        ) : (
          ''
        )}
      </div>
      <div className={global.renderDynamicClass(formik.errors.emailLogin && formik.touched.emailLogin, 'form-group', 'input-error-validate')}>
        <input
          onChange={formik.handleChange}
          value={formik.values.emailLogin}
          type='text'
          id='emailLogin'
          className={global.renderDynamicClass(formik.values['emailLogin'], 'form-control form-control-lg', 'input-valid')}
          name='emailLogin'
          placeholder={layoutData.emailPlaceholderText.value}
        />
        <label htmlFor='emailLogin' className='form-group__label'>
          <Text field={layoutData.emailPlaceholderText} />
        </label>
        {formik.errors.emailLogin && formik.touched.emailLogin && <span className='error-validate'>{formik.errors.emailLogin}</span>}
      </div>
      <div className={global.renderDynamicClass(formik.errors.passwordLogin && formik.touched.passwordLogin, 'form-group', 'input-error-validate')}>
        <input
          onChange={formik.handleChange}
          value={formik.values.passwordLogin}
          type='password'
          className={global.renderDynamicClass(formik.values['passwordLogin'], 'form-control form-control-lg', 'input-valid')}
          id='passwordLogin'
          placeholder={layoutData.passwordPlaceholderText.value}
          autoComplete='off'
        />
        <label htmlFor='passwordLogin' className='form-group__label'>
          <Text field={layoutData.passwordPlaceholderText} />
        </label>
        {formik.errors.passwordLogin && formik.touched.passwordLogin && <span className='error-validate'>{<Message messageCode='Msg_001.5' />}</span>}
      </div>
      <div className='form-group osim-account-form__form__forgot-pass text-right'>
        {layoutData['forgotPasswordLink']?.value?.text ? (
          <Link field={layoutData['forgotPasswordLink']}>
            <Text field={{ value: layoutData['forgotPasswordLink']?.value?.text }} />
          </Link>
        ) : (
          <Link field={layoutData['Forgot Password Link']} />
        )}
      </div>
      <div className='form-group osim-account-form__form__btn'>
        <button className='osim-account-form__form__btn__item btn btn-primary'>
          <Text field={layoutData.loginButtonText} />
        </button>
      </div>
    </form>
  );
};

LoginForm.propTypes = {
  error: PropTypes.any,
  isLoginSuccess: PropTypes.bool
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  error: state.singleSignOnReducer.loginReducer?.error
});

export default connect(mapStateToProps)(LoginForm);
