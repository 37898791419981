import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { deepCopy } from '@utils/utility';

import CartSuggestDetailModal from './CartSuggestDetailModal';
import CartSuggestFreeShipModel from './model/CartSuggestFreeShipModel';

const CartSuggestFreeShip = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [showDetail, setShowDetail] = useState(false);


  useEffect(() => {
    const model = new CartSuggestFreeShipModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  return (
    <>
      {
        layoutData && layoutData.Description ? (
          <div className='cart-summary__free-shipping__wrapper'>
            <div className='cart-summary__free-shipping__contents'>
              <div className='cart-summary__free-shipping__contents__icon'>
                <img src={layoutData.IconFreeShip} alt='shipping-icon' />
              </div>
              <p className='cart-summary__free-shipping__contents__text normal-text' dangerouslySetInnerHTML={{ __html: layoutData['Description'] }}></p>
              <div onClick={() => setShowDetail(true)} className='cart-summary__free-shipping__contents__infor-icon info-icon'></div>
            </div>
            <ModalComponent
              isShow={showDetail}
              onCloseHandler={() => setShowDetail(false)}
              titlePopup={layoutData['Modal Title']}
            >
              <CartSuggestDetailModal closeModalHandler={() => setShowDetail(false)} layoutData={layoutData} promotionId={layoutData.PromotionId}/>
            </ModalComponent>
          </div>
        ) : ''
      }
    </>
     
  );
};

CartSuggestFreeShip.propTypes = {
  fields: PropTypes.any
};

export default CartSuggestFreeShip;
