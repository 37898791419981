
export default class InstallmentPlanModel {
  constructor() {
    this['BankList'] = '';

    this['InstalmentName'] = '';

    this['InterestRate'] = '';

    this['Month'] = '';

    this['MonthlyInstalment'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BankList')) {
      this['BankList'] = dataSource['BankList'] || '';
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InterestRate')) {
      this['InterestRate'] = dataSource['InterestRate'] || '';

    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Month')) {
      this['Month'] = dataSource['Month'] || '';

    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstalmentName')) {
      this['InstalmentName'] = dataSource['InstalmentName'] || '';

    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MonthlyInstalment')) {
      this['MonthlyInstalment'] = dataSource['MonthlyInstalment'] || '';

    }

    return this;
  }
}
