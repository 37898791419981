import produce from 'immer';

import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';

const initState = {
  isLoading: false,
  customerReviewsAverage: null
};

const customerReviewsAverageReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productReviewTypes.GET_CUSTOMER_REVIEWS_AVERAGE:
      draft.isLoading = true;
      break;

    case productReviewTypes.GET_CUSTOMER_REVIEWS_AVERAGE_SUCCESS:
      draft.isLoading = false;

      draft.customerReviewsAverage = action.payload;
      break;

    case productReviewTypes.GET_CUSTOMER_REVIEWS_AVERAGE_fAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default customerReviewsAverageReducer;