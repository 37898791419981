import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { deepCopy } from '@utils/utility';
import SimpleSquareItemFieldsModel from '../Model/SimpleSquareItemFieldsModel';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';

const SimpleSquareItem = (props) => {
  const { simpleSquareItem, onShowModal } = props;
  const [simpleSquareItemFields, setSimpleSquareItemFields] = useState(null);

  useEffect(() => {
    const simpleSquareItemFieldsModel = new SimpleSquareItemFieldsModel();

    setSimpleSquareItemFields(simpleSquareItemFieldsModel.getData(deepCopy(simpleSquareItem.fields || {})));
  }, []);

  return (
    simpleSquareItemFields && (
      <div className='our-wellness-team__content__item text-center' onClick={onShowModal}>
        <div className='our-wellness-team__content__item__img'>
          <Image media={simpleSquareItemFields.Image} />
        </div>
        <Text className='our-wellness-team__content__item__name' tag='h4' field={simpleSquareItemFields.Name} />
        <Text className='our-wellness-team__content__item__designation' tag='span' field={simpleSquareItemFields.Position} />
      </div>
    )
  );
};

SimpleSquareItem.propTypes = {
  simpleSquareItem: PropTypes.object,
  onShowModal: PropTypes.func,
};

export default SimpleSquareItem;