import { useFormik } from 'formik';
import { bool, func, object, string } from 'prop-types';
import React from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import Message from '@components/Share/Message';
import FacebookSSO from '@components/SingleSignOn/FacebookSSO';
import GoogleSSO from '@components/SingleSignOn/GoogleSSO';
import { login } from '@redux/actions/singleSignOn';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import Global from '@utils/global';

const WarrantyLoginForm = (props) => {
  const dispatch = useDispatch();
  const { layoutData, isLoginSuccess, error } = props;

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required(<Message messageCode='Msg_001.5' />)
        .trim()
        .matches(Global.formatPassword(MINUMUM_CHARACTERS_PASSWORD), <Message messageCode='Msg_001.5' />),
      email: Yup.string()
        .email(<Message messageCode='Msg_001.3' />)
        .required(<Message messageCode='Msg_001.3' />)
        .trim()
    }),
    onSubmit: (values) => {
      dispatch(
        login({
          UserName: values.email,
          PassWord: values.password
        })
      );
    },
    validateOnMount: true
  });

  return layoutData ? (
    <div className='account-login'>
      <div className='osim-account-form__social-login text-center'>
        <FacebookSSO isLogin={false} dataFields={layoutData?.['Facebook Login Settings']?.fields} />
        <GoogleSSO isLogin={false} dataFields={layoutData?.['Google Login Settings']?.fields} />
        {error?.errorMessage && !isLoginSuccess ? (
          <span className='error-validate'>
            {error === 'Failed' ? <Message messageCode='Msg_003' /> : <RichText field={{ value: error.errorMessage }} />}
          </span>
        ) : (
          ''
        )}
      </div>
      {layoutData['Login Introduction'].value ? (
        <p className='osim-account-form__title'>
          <Text field={layoutData['Login Introduction']} />
        </p>
      ) : (
        ''
      )}
      <form onSubmit={formik.handleSubmit} className='osim-account-form__form' id='wlf-osim-account-form__form'>
        <div className={Global.renderDynamicClass(formik.errors.email && formik.touched.email, 'form-group', 'input-error-validate')}>
          <input
            onChange={formik.handleChange}
            value={formik.values.email}
            type='text'
            id='wlf-email'
            className={Global.renderDynamicClass(formik.values['email'], 'form-control form-control-lg', 'input-valid')}
            name='email'
            placeholder={layoutData['Login Email Label'].value}
          />
          <label htmlFor='wlf-email' className='form-group__label'>
            <Text field={layoutData['Login Email Label']} />
          </label>
          {formik.errors.email && formik.touched.email && <span className='error-validate'>{formik.errors.email}</span>}
        </div>
        <div className={Global.renderDynamicClass(formik.errors.password && formik.touched.password, 'form-group', 'input-error-validate')}>
          <input
            onChange={formik.handleChange}
            value={formik.values.password}
            type='password'
            className={Global.renderDynamicClass(formik.values['password'], 'form-control form-control-lg', 'input-valid')}
            id='wlf-password'
            placeholder={layoutData['Login Password Label'].value}
            name='password'
          />
          <label htmlFor='wlf-password' className='form-group__label'>
            <Text field={layoutData['Login Password Label']} />
          </label>
          {formik.errors.password && formik.touched.password && <span className='error-validate'>{<Message messageCode='Msg_001.5' />}</span>}
        </div>
        <div className='form-group osim-account-form__form__forgot-pass text-right'>
          {layoutData['Forgot Password Label'].value ? (
            <Link field={layoutData['Forgot Password Link']}>
              <Text field={layoutData['Forgot Password Label']} />
            </Link>
          ) : (
            <Link field={layoutData['Forgot Password Link']} />
          )}
        </div>
        <div className='form-group osim-account-form__form__btn'>
          <button type='submit' className='osim-account-form__form__btn__item btn btn-primary'>
            <Text field={layoutData['Login Button Label']} />
          </button>
        </div>
        <div className='form-group'>
          <p className='text-center'>
            <Text field={layoutData['Login Switch To Register Text']} />
            <a href='#' onClick={() => props.changeTabEvt()}>
              <Text field={layoutData['Register Button Text']} />
            </a>
          </p>
        </div>
      </form>
    </div>
  ) : (
    <></>
  );
};

WarrantyLoginForm.propTypes = {
  layoutData: object,
  changeTabEvt: func,
  error: string,
  isLoginSuccess: bool
};

const mapStateToProps = (state) => ({
  error: state.singleSignOnReducer.loginReducer?.error
});

export default connect(mapStateToProps)(WarrantyLoginForm);
