import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import AddressSettingModel from '../../SlicingPage/MyAccount/MyAddress/models/AddressSettingModel';

const TEXT_ARR = [
  'confirmPasswordPlaceholderText',
  'createOsimAccountOptionText',
  'directMailingMarketingChannelLabel',
  'emailMarketingChannelLabel',
  'justMeButtonText',
  'mePlus1PlaceholderText',
  'numberOfGuestsLabel',
  'passwordPlaceholderText',
  'receivingMarketingInformationText',
  'registerButtonText',
  'selectDateLabel',
  'selectStoreLabel',
  'smsConfirmationOptionText',
  'smsMarketingChannelLabel',
  'termsAndConditionsAgreement',
  'title',
  'selectedTranslationText',
  'aprilTranslationText',
  'augustTranslationText',
  'availableTranslationText',
  'decemberTranslationText',
  'februaryTranslationText',
  'fridayTranslationText',
  'januaryTranslationText',
  'julyTranslationText',
  'juneTranslationText',
  'marchTranslationText',
  'mayTranslationText',
  'mondayTranslationText',
  'notAvailableTranslationText',
  'novemberTranslationText',
  'octoberTranslationText',
  'saturdayTranslationText',
  'septemberTranslationText',
  'sundayTranslationText',
  'thursdayTranslationText',
  'tuesdayTranslationText',
  'wednesdayTranslationText',
  'dateOfBirthLabel',
  'dayPlaceholderText',
  'emailPlaceholderText',
  'enterOTPPlaceholderText',
  'firstNamePlaceholderText',
  'getOTPButtonText',
  'lastNamePlaceholderText',
  'mobileNumberPlaceholderText',
  'mobileRegisteredMessage',
  'monthPlaceholderText',
  'otpIncorrectMessage',
  'otpSentMessage',
  'otpTimeoutMessage',
  'proceedOTPButtonText',
  'resendOTPLinkLabel',
  'subtitle',
  'yearPlaceholderText',
  'buttonText',
  'registerEventScreenTitle',
  'registerEventScreenSubtitle',
  'loginTitle',
  'loginButtonText',
  'forgotPasswordLabel',
  'agreeTermsAndConditionsMessage',
  'inputValidBirthDateMessage',
  'inputValidEmailAddressMessage',
  'inputValidLastNameMessage',
  'inputValidFirstNameMessage',
  'inputValidMobileNumberMessage',
  'selectAnswersMessage',
  'registerEventScreenSubtitleAfterLogin',
  'registerButtonId',
  'otpVerificationExceededMessage',
  'inputValidOTPMessage',
  'updateMobileNumberMessage',
  'tellUsMoreLabel',
  'emailAlreadyExistsLabel',
  'emailAlreadyExistsMessage',
  'continueGuestCheckoutButtonText',
  'filterStoresLabel',
  'clearFiltersButtonLabel',
  'noStoreSatisfyingTheSelectedFilltersLabel',
  'applyFiltersButtonLabel',
  'areaLabel',
  'pleaseEnterAreaLabel',
  'selectStoreErrorMessage',
  'Apply Filters Button Label',
  'Clear Filters Button Label'
];

const LINK_ARR = ['forgotPasswordLink'];

export default class CampaignRegisterComponentModel {
  constructor() {
    TEXT_ARR.forEach((itemText) => {
      this[itemText] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((itemText) => {
      this[itemText] = new SitecoreLinkModel();
    });

    this['campaign'] = null;

    this['Address Settings'] = new AddressSettingModel();
  }

  getData(dataSource) {
    TEXT_ARR.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    LINK_ARR.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'campaign')) {
      this['campaign'] = deepCopy(dataSource['campaign']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Settings')) {
      this['Address Settings'].getData(dataSource['Address Settings']?.fields || {}, true);
    }

    return this;
  }
}
