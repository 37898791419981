import './iconItem.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';

const IconItem = (props) => {
  const { contentData } = props;

  return contentData.isDisplay ? (
    <div className='list-icon__item'>
      <div className='list-icon__item__image'>
        <Image field={contentData['Image']} />
      </div>
      <div className='list-icon__item__text'>
        <RichText field={contentData['Title']} />
      </div>
    </div>
  ) : null;
};

IconItem.propTypes = {
  contentData: PropTypes.any
};

export default IconItem;
