//Get Available Lucky Draw
export const GET_AVAILABLE_LUCKY_DRAW = 'GET_AVAILABLE_LUCKY_DRAW';

export const GET_AVAILABLE_LUCKY_DRAW_SUCCESS = 'GET_AVAILABLE_LUCKY_DRAW_SUCCESS';

export const GET_AVAILABLE_LUCKY_DRAW_FAILED = 'GET_AVAILABLE_LUCKY_DRAW_FAILED';

export const GET_NUMBER_SPIN_EMAIL = 'GET_NUMBER_SPIN_EMAIL';

export const GET_NUMBER_SPIN_EMAIL_SUCCESS = 'GET_NUMBER_SPIN_EMAIL_SUCCESS';

export const GET_NUMBER_SPIN_EMAIL_FAILED = 'GET_NUMBER_SPIN_EMAIL_FAILED';

export const APPLY_REWARD = 'APPLY_REWARD';

export const APPLY_REWARD_SUCCESS = 'APPLY_REWARD_SUCCESS';

export const APPLY_REWARD_FAILED = 'APPLY_REWARD_FAILED';

//Get My Prize Lucky Draw
export const GET_MY_PRIZE_LUCKY_DRAW = 'GET_MY_PRIZE_LUCKY_DRAW';

export const GET_MY_PRIZE_LUCKY_DRAW_SUCCESS = 'GET_MY_PRIZE_LUCKY_DRAW_SUCCESS';

export const GET_MY_PRIZE_LUCKY_DRAW_FAILED = 'GET_MY_PRIZE_LUCKY_DRAW_FAILED';

//Get Chance To Win
export const GET_CHANCE_TO_WIN = 'GET_CHANCE_TO_WIN';

export const GET_CHANCE_TO_WIN_SUCCESS = 'GET_CHANCE_TO_WIN_SUCCESS';

export const GET_CHANCE_TO_WIN_FAILED = 'GET_CHANCE_TO_WIN_FAILED';

//// Clear Data Input Spin
export const CLEAR_DATA_INPUT_SPIN = 'CLEAR_DATA_INPUT_SPIN';