import { createContext } from 'react';


const initialStates = {
  layoutData: null,
  appointmentDetail: null
};

const AppointmentDetailContext = createContext(initialStates);

export default AppointmentDetailContext;