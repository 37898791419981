import { useCallback } from 'react';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { post } from '@services/genericService';

export const useRemoveSaleOrder = () => {
  return useCallback(
    ({ cartName }) =>
      new Promise((resolve, reject) => {
        return from(post('/OsimCart/DeleteDraftSaleOrder', { cartName }))
          .pipe(
            map((res) => {
              if (res.status === 200) {
                resolve(res.data);
              }
            }),
            catchError((error) => reject(error))
          )
          .subscribe();
      }),
    []
  );
};
