import produce from 'immer';

import * as cpLeadManagementTypes from '@redux/actions/cpLeadManagement/cpLeadManagementTypes';

const initState = {
  isLoading: false,
  errors: null,
  leadManagements: null,
  SaleStaffs: null,
  isSuccess: false
};

const cpLeadManagementReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cpLeadManagementTypes.MANAGER_LEAD_MANAGEMENT:
      draft.isLoading = true;

      draft.isSuccess = false;
      break;

    case cpLeadManagementTypes.MANAGER_LEAD_MANAGEMENT_SUCCESS:
      draft.isLoading = false;

      draft.isSuccess = true;

      draft.leadManagements = action.payload;
      break;
  
    case cpLeadManagementTypes.MANAGER_LEAD_MANAGEMENT_FAILED:
      draft.isLoading = false;

      draft.isSuccess = false;
        
      draft.errors = action.error;
      break;

    case cpLeadManagementTypes.STAFF_LEAD_MANAGEMENT:
      draft.isLoading = true;

      draft.isSuccess = false;
      break;

    case cpLeadManagementTypes.STAFF_LEAD_MANAGEMENT_SUCCESS:
      draft.isLoading = false;

      draft.isSuccess = true;

      draft.leadManagements = action.payload;
      break;
  
    case cpLeadManagementTypes.STAFF_LEAD_MANAGEMENT_FAILED:
      draft.isLoading = false;

      draft.isSuccess = false;
        
      draft.errors = action.error;
      break;

    case cpLeadManagementTypes.GET_SALE_STAFFS:
      break;

    case cpLeadManagementTypes.GET_SALE_STAFFS_SUCCESS:

      draft.SaleStaffs = action.payload.SaleStaffs;
      break;
  
    case cpLeadManagementTypes.GET_SALE_STAFFS_FAILED:
        
      draft.errors = action.error;
      break;
    }

    return draft;
  });

export default cpLeadManagementReducer;
