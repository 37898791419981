import QuestionModel from '@components/ClientPortal/ReviewCustomerInformation/model/QuestionModel';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import PhoneCountryCodeModel from './PhoneCountryCodeModel';

const IMAGE_ARR = [
  'Basic Profile Icon',
  'Birthday Icon',
  'Dial Button Icon',
  'Gender Icon',
  'Lifestyle Icon',
  'Married Icon',
  'Message Button Icon',
  'SMS Icon',
  'Mail Icon',
  'Phone Icon'
];

const TEXT_ARR = [
  'Add New',
  'Continue With Existing Button',
  'Create New Profile Button',
  'Email Label',
  'First Name Label',
  'Last name Label',
  'Phone Number Label',
  'Signature Label',
  'Warning Description',
  'Warning Title',
  'Pop Up Title',
  'View Profile Link',
  'View Profile Label',
  'Is Communication Method Hidden',
  'Direct Mailing Hidden'
];

export default class QACustomerLifestyleModel {
  constructor() {
    IMAGE_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Questions'] = [];

    this['Phone Country Code'] = [];

    this['Massage Ownership Types'] = [];
  }
  getData(dataSource) {
    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Questions')) {
      dataSource['Questions'].map((element) => {
        this['Questions'].push(new QuestionModel().getData(deepCopy(element) || {}));
      });
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Phone Country Code')) {
      dataSource['Phone Country Code'].map((element) => {
        this['Phone Country Code'].push(new PhoneCountryCodeModel().getData(deepCopy(element) || {}));
      });
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Massage Ownership Types')) {
      dataSource['Massage Ownership Types'].map((element) => {
        this['Massage Ownership Types'].push({
          id: element?.id,
          ...new PhoneCountryCodeModel().getData(deepCopy(element) || {}),
        });
      });
    }

    return this;
  }
}
