import produce from 'immer';

import { SET_ADDRESS_VALID_ERROR_MESSAGE } from '@redux/actions/checkoutProcess/checkoutProcessTypes';

const initStates = {
  section: '',
  isValid: true
};

const setAddressValidErrorMessageReducer = (state = initStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case SET_ADDRESS_VALID_ERROR_MESSAGE:
      draft.section = action.payload.section;

      draft.isValid = action.payload.isValid;
      break;
    }

    return draft;
  });

export default setAddressValidErrorMessageReducer;
