import { useCallback } from 'react';
import { useSelector } from 'react-redux';

const useCheckFunctionData = () => {
  const { productCompareData } = useSelector((state) => state.compareProductReducer);

  return useCallback(
    (functionKey) => {
      return (
        productCompareData?.length &&
        productCompareData.some((item) => {
          return item?.productInfoDetail?.[functionKey]?.length;
        })
      );
    },
    [productCompareData]
  );
};

export default useCheckFunctionData;
