import { of } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';

import { postAsObservable } from '@services/genericService';

import { POPUP_DATA_LOGS_ENDPOINT } from './configs';

export const fetchNotificationData = (params) => {
  return postAsObservable(POPUP_DATA_LOGS_ENDPOINT, params).pipe(
    map((res) => {
      return of(res.data);
    }),
    concatMap((response) => response),
    catchError((error) => of(error))
  );
};
