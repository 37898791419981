import './FreeGiftItem.scss';

import { any, bool, object, oneOfType, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { showSelectingFreeGiftPopup } from '@redux/actions/controlSelectingFreeGiftPopup';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import Message from '../../Share/Message';

export const CART_PAGE_NAME = 'cart';

const FreeGiftItem = ({
  dataSources,
  freeGiftName,
  freeGiftThumbnail,
  lineFinalTotal,
  productPageLink,
  promotionTitle,
  isEnableEdit,
  isOutOfStock = false,
  isFreeGiftPopupCartPage = false,
  freeGiftData
}) => {
  const DELIVERY_PAGE_NAME = 'delivery';
  const dispatch = useDispatch();
  const pageName = SitecoreContextFactoryService.getValueContextRouteItem('name')?.toLowerCase();

  const isInCartPage = useMemo(() => {
    return pageName?.toLowerCase() === CART_PAGE_NAME;
  }, [pageName]);

  const isEnableEditButton = useMemo(() => {
    let isShowButton = false;
    if (isEnableEdit) {
      if (pageName === CART_PAGE_NAME) isShowButton = isFreeGiftPopupCartPage;
      if (pageName !== CART_PAGE_NAME && pageName !== DELIVERY_PAGE_NAME) isShowButton = !isFreeGiftPopupCartPage;
    }

    return isShowButton;
  }, [isEnableEdit, pageName, isFreeGiftPopupCartPage]);
  const handleOpenSelectingFreeGiftPopup = () => dispatch(showSelectingFreeGiftPopup());

  return (
    <>
      <div className={`free-gift-item ${!isInCartPage ? 'product-list__item' : 'cart-item'}`}>
        <div className={`${!isInCartPage ? 'delivery-row' : 'row'}`}>
          <div className={`${!isInCartPage ? 'delivery-col-left' : 'col-lg-6'}`}>
            <div className='cart-item__container'>
              <div className='row cart-item__image-row'>
                <div className='col-12 cart-item__image'>
                  <a href={productPageLink}>
                    <img src={freeGiftThumbnail} alt={freeGiftName} />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className={`${pageName !== CART_PAGE_NAME ? 'delivery-col-right' : 'col-lg-6'} free-gift-item__info`}>
            <div className='cart-item-information'>
              {isOutOfStock ? (
                <Text tag='p' field={dataSources?.['Free Gift Out Of Stock Message']} className='error-message' />
              ) : (
                <p className='special-deal-message'>{promotionTitle}</p>
              )}
              <div className='cart-item-information__body'>
                <h2 className='cart-item-information__body__title'>
                  <a href={productPageLink}>{freeGiftName}</a>
                </h2>
                {isEnableEditButton ? (
                  <span className='cart-item-information__body__remove-btn' onClick={handleOpenSelectingFreeGiftPopup}>
                    {pageName !== CART_PAGE_NAME ? <Text field={dataSources['Edit Label']} /> : <Text field={dataSources['Edit Button Text']} />}
                  </span>
                ) : (
                  <></>
                )}
                <div className='cart-item-information__body__total-amount'>
                  <strong>
                    <Text field={dataSources?.['Free Text']} />
                  </strong>
                </div>
                <div className='product-information'>
                  <div className='product-information__quantity'>
                    <div className='product-information__quantity__inner disable'>
                      <button type='button' className='button-minus disable'>
                        <i className='icon-remove'></i>
                      </button>
                      <input className='product-information__quantity__current' type='text' name='quantity' value={freeGiftData?.Quantity} disabled />
                      <button type='button' className='button-add disable'>
                        <i className='icon-add'></i>
                      </button>
                    </div>
                  </div>
                  <div className='product-information__price'>
                    <strong className='smaller-text smaller-text--bold'>
                      {/*{freeGiftData.IsEditable ? freeGiftData.LineFinalPrice : <Text field={dataSources['Free Text']} />}*/}
                      <Text field={dataSources['Free Text']} />
                    </strong>
                    {freeGiftData.LineFinalPrice !== freeGiftData.LinePrice || !freeGiftData.IsEditable ? (
                      <span className='smaller-text smaller-text--light text-line-through'>{freeGiftData.LinePrice}</span>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
              <div className='product-information__status'>
                {freeGiftData?.IsInStockProduct ? <span><Message messageCode='LBL-InStock' labelShipping={freeGiftData?.['InStockDeliveryLabel']}/></span> : ''}
                {freeGiftData?.IsOutStockProduct ? <span><Message messageCode='LBL-OutOfStock' labelShipping={freeGiftData?.['OutStockDeliveryLabel']}/>.&nbsp;</span> : ''}
                {freeGiftData?.IsPreOrderProduct ? <span><Message messageCode='LBL-PreOrder' labelShipping={freeGiftData?.['PreOrderDeliveryLabel']}/>.&nbsp;</span> : ''}
                {freeGiftData?.IsBackOrderProduct ? <span><Message messageCode='LBL-BackOrder' labelShipping={freeGiftData?.['BackOrderDeliveryLabel']}/>.&nbsp;</span> : ''}
              </div>
            </div>
            <div className='cart-item-total-price'>
              <div className='cart-item-total-price__subtotal'>
                <p className='cart-item-total-price__title'>
                  {dataSources?.['Sub Total']?.value ? (
                    <Text field={dataSources?.['Sub Total']} />
                  ) : (
                    <Text field={dataSources?.['Sub Total Label']} />
                  )}
                </p>
                <p className='cart-item-total-price__total-price'>{lineFinalTotal}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

FreeGiftItem.propTypes = {
  dataSources: object,
  freeGiftName: string,
  freeGiftThumbnail: string,
  lineFinalTotal: string,
  productPageLink: string,
  promotionTitle: string,
  isEnableEdit: bool,
  isOutOfStock: bool,
  isFreeGiftPopupCartPage: bool,
  freeGiftData: oneOfType([object, any])
};

export default FreeGiftItem;
