import produce from 'immer';

import { UPDATE_CONTACT_FAILURE, UPDATE_CONTACT_START, UPDATE_CONTACT_SUCCESS } from '../../../actions/campaignRegister/updateContact/types';

const initialStates = {
  isLoading: false,
  updateResponse: null,
  error: null
};

const updateContactReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case UPDATE_CONTACT_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case UPDATE_CONTACT_SUCCESS:
      draft.isLoading = false;

      draft.updateResponse = action.payload;
      break;

    case UPDATE_CONTACT_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default updateContactReducer;
