import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import FieldsModel from './FieldsModel';

export default class CarouselWidthIconFieldsModel {
  constructor() {
    this['Background Image'] = new SitecoreImageModel();

    this['CTA Button Link'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();

    this.Title = new SitecoreTextModel();

    this.Description = new SitecoreTextModel();

    this.items = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Image')) {
      this['Background Image'].getData(dataSource['Background Image'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Link')) {
      this['CTA Button Link'].getData(dataSource['CTA Button Link'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'].getData(dataSource['CTA Button Text'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let newItemsList = [];

      dataSource['items'].map((element) => {
        newItemsList.push(new FieldsModel().getData(deepCopy(element) || {}));
      });

      this['items'] = newItemsList;
    }

    return this;
  }
}
