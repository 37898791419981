import ProductCardStockStatusModel from '@components/Product/models/ProductCardStockStatusModel';
import { PromotionType } from '@utils/constant';
import { STOCK_QUANTITY } from '@utils/constant';

import CartLineAddressModel from './CartLineAddressModel';
import CartLineProperties from './CartLineProperties';
import CartLineStatusModel from './CartLineStatusModel';
import DeliveryOptionsModel from './DeliveryOptionsModel';
import PromotionCartApiModel from './PromotionCartApiModel';

const TypeProduct = {
  PreOrderable: 'PreOrderable',
  BackOrderable: 'BackOrderable',
  InStock: 'InStock',
  OutOfStock: 'OutOfStock'
};

export default class CartLineApiModel {
  constructor() {
    this.ExternalCartLineId = '';

    this.CategoryName = '';

    this.ProductPageId = '';

    this.ProductPageLink = '';

    this.Address = new CartLineAddressModel();

    this.Catalog = '';

    this.Image = '';

    this.DisplayName = '';

    this.ProductUrl = '';

    this.Quantity = '';

    this.LinePrice = '';

    this.LineTotal = '';

    this.LineFinalTotal = '';

    this.LineFinalPrice = '';

    this.LineDiscount = '';

    this.ProductId = '';

    this.VariantId = '';

    this.PromotionCartLines = [];

    this.PromotionText = '';

    this.Properties = new CartLineProperties();

    this.Type = '';

    this.DeliveryOptionId = '';

    this.PromotionFreeGift = [];

    this.DeliveryOptions = [];

    this.LineDiscountAmount = null;

    this.MasterId = null;

    this.SubLines = [];

    this.IsEditable = true;

    this.LineStatus = [];

    this.StockStatus = new ProductCardStockStatusModel();

    this.BackOrderDeliveryLabel = '';

    this.OutStockDeliveryLabel = '';

    this.PreOrderDeliveryLabel = '';

    this.InStockDeliveryLabel = '';

    this.LineFinalTotalAmount = 0;

    this.SafetyStockLimitation = 0;

    this.StockQuantity = 0;

    this.LastLabel = '';

    this.MissingShipping = false;

    this.PurchasableLimit = '';

    this.VariantPropertyValue = '';

    this.HasInstallmentPlan = false;

    this.GiftCardId = '';

    this.GiftWrapId = '';

    this.GiftCardMessage = '';

    this.GiftingOptionLines = [];

    this.GiftingOptionTotal = '';

    this.GiftingOptionTotalAmout = 0;
    
    this.BlockInstallment = false;
    
    this.LinePriceAmount = 0;

    this.IsAutoAddFreeGiftPopup = false;

    this.IsFreeGiftPopup = false;

    this.PromotionFreeGiftPopupId = '';

    this.LineFinalPriceAmount = 0;

    this.CategoryShowPriorityDelivery = false;

    this.Deposit = '';

    this.Package = '';

    this.OrderId = '';

    this.IsFreeGiftPopupCartPage = false;

    this.Color = '';

    this.IsDepositFreeGift = false;

    this.DepositFreeGifts = '';

    this.LineTotal = '';

    this.DepositPromotionCartLines = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineTotal')) {
      this.LineTotal = dataSource.LineTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DepositFreeGifts')) {
      this.DepositFreeGifts = dataSource.DepositFreeGifts;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsDepositFreeGift')) {
      this.IsDepositFreeGift = dataSource.IsDepositFreeGift;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Color')) {
      this.Color = dataSource.Color;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OrderId')) {
      this.OrderId = dataSource.OrderId;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit')) {
      this.Deposit = dataSource.Deposit;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Package')) {
      this.Package = dataSource.Package;
    }
    
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BackOrderDeliveryLabel')) {
      this.BackOrderDeliveryLabel = dataSource.BackOrderDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OutStockDeliveryLabel')) {
      this.OutStockDeliveryLabel = dataSource.OutStockDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CategoryName')) {
      this.CategoryName = dataSource.CategoryName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PreOrderDeliveryLabel')) {
      this.PreOrderDeliveryLabel = dataSource.PreOrderDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionText')) {
      this.PromotionText = dataSource.PromotionText;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InStockDeliveryLabel')) {
      this.InStockDeliveryLabel = dataSource.InStockDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StockStatus') && dataSource.StockStatus) {
      this.StockStatus.getData(dataSource.StockStatus);
      if (this.StockStatus.Name === TypeProduct.InStock) {
        this.IsInStockProduct = true;
      }
      if (this.StockStatus.Name === TypeProduct.OutOfStock) {
        this.IsOutStockProduct = true;
      }
      if (this.StockStatus.Name === TypeProduct.PreOrderable) {
        this.IsPreOrderProduct = true;
      }
      if (this.StockStatus.Name === TypeProduct.BackOrderable) {
        this.IsBackOrderProduct = true;
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsEditable')) {
      this.IsEditable = dataSource.IsEditable;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineStatus')) {
      if (dataSource?.LineStatus?.length) {
        this.LineStatus = [];

        dataSource.LineStatus.map((item) => {
          this.LineStatus.push(new CartLineStatusModel().getData(item || {}));
        });
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MasterId')) {
      this.MasterId = dataSource.MasterId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineFinalTotal')) {
      this.LineFinalTotal = dataSource.LineFinalTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineDiscountAmount')) {
      this.LineDiscountAmount = dataSource.LineDiscountAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExternalCartLineId')) {
      this.ExternalCartLineId = dataSource.ExternalCartLineId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DeliveryOptions')) {
      if (dataSource.DeliveryOptions?.length) {
        dataSource.DeliveryOptions.map((itemDelivery) => {
          this.DeliveryOptions.push(new DeliveryOptionsModel().getData(itemDelivery || {}));
        });
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExternalCartLineId')) {
      this.ExternalCartLineId = dataSource.ExternalCartLineId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image = dataSource.Image;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayName')) {
      this.DisplayName = dataSource.DisplayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductUrl')) {
      this.ProductUrl = dataSource.ProductUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Quantity')) {
      this.Quantity = dataSource.Quantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LinePrice')) {
      this.LinePrice = dataSource.LinePrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineFinalPrice')) {
      this.LineFinalPrice = dataSource.LineFinalPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineTotal')) {
      this.LineTotal = dataSource.LineTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineDiscount')) {
      this.LineDiscount = dataSource.LineDiscount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this.ProductId = dataSource.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantId')) {
      this.VariantId = dataSource.VariantId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionCartLines')) {
      if (dataSource.PromotionCartLines?.length) {
        dataSource.PromotionCartLines.map((promotionItem) => {
          this.PromotionCartLines.push(new PromotionCartApiModel().getData(promotionItem || {}));
        });

        this.PromotionFreeGift = this.PromotionCartLines.filter((freeGiftItem) => freeGiftItem.Type === PromotionType.FreeGift);
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Properties')) {
      this.Properties = new CartLineProperties().getData(dataSource.Properties || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address')) {
      this.Address = new CartLineAddressModel().getData(dataSource.Address || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Type')) {
      this.Type = dataSource.Type;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Catalog')) {
      this.Catalog = dataSource.Catalog;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DeliveryOptionId')) {
      this.DeliveryOptionId = dataSource.DeliveryOptionId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductPageId')) {
      this.ProductPageId = dataSource.ProductPageId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductPageLink')) {
      this.ProductPageLink = dataSource.ProductPageLink;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SubLines')) {
      this.SubLines = dataSource.SubLines;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineFinalTotalAmount')) {
      this.LineFinalTotalAmount = dataSource.LineFinalTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StockQuantity')) {
      this.StockQuantity = dataSource.StockQuantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SafetyStockLimitation')) {
      this.SafetyStockLimitation = dataSource.SafetyStockLimitation;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MissingShipping')) {
      this.MissingShipping = dataSource.MissingShipping;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastLabel') && dataSource.LastLabel) {
      this.LastLabel = dataSource.LastLabel.replace(STOCK_QUANTITY, dataSource?.PurchasableLimit || 0);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PurchasableLimit')) {
      this.PurchasableLimit = dataSource.PurchasableLimit;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantPropertyValue')) {
      this.VariantPropertyValue = dataSource.VariantPropertyValue;
    } else this.VariantPropertyValue = this.Properties['Color'];
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'HasInstallmentPlan')) {
      this.HasInstallmentPlan = dataSource.HasInstallmentPlan ?? this.HasInstallmentPlan;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentPeriods')) {
      this.InstallmentPeriods = dataSource.InstallmentPeriods ?? this.InstallmentPeriods;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GiftCardId') && dataSource.GiftCardId) {
      this.GiftCardId = dataSource.GiftCardId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GiftWrapId') && dataSource.GiftWrapId) {
      this.GiftWrapId = dataSource.GiftWrapId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GiftCardMessage') && dataSource.GiftCardMessage) {
      this.GiftCardMessage = dataSource.GiftCardMessage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GiftingOptionLines')) {
      if (dataSource?.GiftingOptionLines?.length) {
        this.GiftingOptionLines = [];

        dataSource.GiftingOptionLines.map((item) => {
          this.GiftingOptionLines.push(item || {});
        });
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GiftingOptionTotal') && dataSource.GiftingOptionTotal) {
      this.GiftingOptionTotal = dataSource.GiftingOptionTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GiftingOptionTotalAmout') && dataSource.GiftingOptionTotalAmout) {
      this.GiftingOptionTotalAmout = dataSource.GiftingOptionTotalAmout;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BlockInstallment')) {
      this.BlockInstallment = dataSource.BlockInstallment;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LinePriceAmount')) {
      this.LinePriceAmount = dataSource.LinePriceAmount ?? this.LinePriceAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsAutoAddFreeGiftPopup')) {
      this.IsAutoAddFreeGiftPopup = dataSource.IsAutoAddFreeGiftPopup ?? this.IsAutoAddFreeGiftPopup;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsFreeGiftPopup')) {
      this.IsFreeGiftPopup = dataSource.IsFreeGiftPopup ?? this.IsFreeGiftPopup;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionFreeGiftPopupId')) {
      this.PromotionFreeGiftPopupId = dataSource.PromotionFreeGiftPopupId ?? this.PromotionFreeGiftPopupId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineFinalPriceAmount')) {
      this.LineFinalPriceAmount = dataSource.LineFinalPriceAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CategoryShowPriorityDelivery')) {
      this.CategoryShowPriorityDelivery = dataSource.CategoryShowPriorityDelivery;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsFreeGiftPopupCartPage')) {
      this.IsFreeGiftPopupCartPage = dataSource.IsFreeGiftPopupCartPage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionCartLines')) {
      const promotionCartLines = dataSource?.PromotionCartLines || [];
      if (promotionCartLines.length) {
        this.DepositPromotionCartLines = promotionCartLines
          .filter((item) => item.Type === PromotionType.DepositGift || item.Type === PromotionType.BalanceGift)
          ?.sort((prev, next) => prev?.Type - next?.Type);
      }
    }

    return this;
  }
}
