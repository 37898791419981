import { CALC_TOTAL_LIST_PRICE, CALC_TOTAL_SELLING_PRICE } from './types';

export const calcTotalListPrice = (payload) => ({
  type: CALC_TOTAL_LIST_PRICE,
  payload
});

export const calcTotalSellingPrice = (payload) => ({
  type: CALC_TOTAL_SELLING_PRICE,
  payload
});
