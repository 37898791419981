import { GET_LATEST_SO_CART_FAILURE, GET_LATEST_SO_CART_START, GET_LATEST_SO_CART_SUCCESS } from './types';

export const getLatestSOCartStart = () => ({
  type: GET_LATEST_SO_CART_START
});

export const getLatestSOCartSuccess = (payload) => ({
  type: GET_LATEST_SO_CART_SUCCESS,
  payload
});

export const getLatestSOCartFailure = (payload) => ({
  type: GET_LATEST_SO_CART_FAILURE,
  payload
});
