import './CouponAppliedComponent.scss';

import PropTypes from 'prop-types';
import React from 'react';

const CouponAppliedComponent = ({ listCoupon, layoutData }) => {
  return (
    <div className='coupon-component'>
      <label className='coupon-component__title cart-seller__title'>{layoutData['Coupon Code Label'].value}</label>
      {listCoupon.map((item, ind) => {
        return (
          <span className='coupon-component__item' key={ind}>
            {item}
          </span>
        );
      })}
    </div>
  );
};

CouponAppliedComponent.propTypes = {
  listCoupon: PropTypes.any,
  layoutData: PropTypes.any,
};

export default CouponAppliedComponent;
