import * as getSOCartsTypes from './getSOCartsTypes.js';

export const getSOCarts = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS,
  payload
});
export const getSOCartsSuccess = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_SUCCESS,
  payload
});
export const getSOCartsFailed = (error) => ({
  type: getSOCartsTypes.GET_SO_CARTS_FAILED,
  error
});

export const getSOCartsActive = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_ACTIVE,
  payload
});
export const getSOCartsActiveSuccess = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_ACTIVE_SUCCESS,
  payload
});
export const getSOCartsActiveFailed = (error) => ({
  type: getSOCartsTypes.GET_SO_CARTS_ACTIVE_FAILED,
  error
});

export const getSOCartsDraft = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_DRAFT,
  payload
});
export const getSOCartsDraftSuccess = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_DRAFT_SUCCESS,
  payload
});
export const getSOCartsDraftFailed = (error) => ({
  type: getSOCartsTypes.GET_SO_CARTS_DRAFT_FAILED,
  error
});

export const getSOCartsViewed = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_VIEWED,
  payload
});
export const getSOCartsViewedSuccess = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_VIEWED_SUCCESS,
  payload
});
export const getSOCartsViewedFailed = (error) => ({
  type: getSOCartsTypes.GET_SO_CARTS_VIEWED_FAILED,
  error
});

export const getSOCartsCompleted = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_COMPLETED,
  payload
});
export const getSOCartsCompletedSuccess = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_COMPLETED_SUCCESS,
  payload
});
export const getSOCartsCompletedFailed = (error) => ({
  type: getSOCartsTypes.GET_SO_CARTS_COMPLETED_FAILED,
  error
});

export const getSOCartsExpired = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_EXPIRED,
  payload
});
export const getSOCartsExpiredSuccess = (payload) => ({
  type: getSOCartsTypes.GET_SO_CARTS_EXPIRED_SUCCESS,
  payload
});
export const getSOCartsExpiredFailed = (error) => ({
  type: getSOCartsTypes.GET_SO_CARTS_EXPIRED_FAILED,
  error
});