import PriceModel from '@components/BundleElements/model/PriceModel';

let STRINGARR = [
  'Brand',
  'CurrencyCode',
  'Description',
  'DisplayName',
  'ItemId',
  'ProductId',
  'ProductOverview',
  'ProductPageId',
  'ProductPageUrl',
  'TradeInSellingPriceWithCurrency',
  'TradeInLoyaltyPriceWithCurrency',
  'VariantId',
  'PostBuyingOptionTradeInSellingPricePostWithCurrency'
];

export default class BOTradeInModel {
  constructor() {
    STRINGARR.forEach((item) => {
      this[item] = '';
    });

    this.ImageURLs = [];

    this.ListPrice = 0;

    this.Price = new PriceModel();

    this.SellingPrice = 0;

    this.LoyaltyPrice = 0;
  }
  getData(dataSource) {
    STRINGARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ImageURLs')) {
      this.ImageURLs = dataSource.ImageURLs ?? this.ImageURLs;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this.ListPrice = dataSource.ListPrice ?? this.ListPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this.Price.getData(dataSource.Price);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice')) {
      this.SellingPrice = dataSource.SellingPrice ?? this.SellingPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LoyaltyPrice')) {
      this.LoyaltyPrice = dataSource.LoyaltyPrice ?? this.LoyaltyPrice;
    }

    return this;
  }
}
