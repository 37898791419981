import './ProductWishlistItem.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import ToggleWisList from '@components/Share/ToggleWisList';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

const ProductWishlistItem = (props) => {
  const { inputData, wishlistItem, isOnlyView } = props;
  const [buyNowLink, setBuyNowLink] = useState(null);

  const textButton = useMemo(()=> {
    if(wishlistItem?.Product?.IsDeposit) {
      return inputData?.['Deposit Label']?.value;
    }else {
      return wishlistItem?.Product.Stock?.PurchasableLabel;
    }
  }, [wishlistItem])

  useEffect(() => {
    const buyNowTmp = deepCopy(inputData['Buy Options Link']);

    buyNowTmp.value.href = `${buyNowTmp.value.href}?productPageId=${wishlistItem.Product.Id || ''}`;

    setBuyNowLink(buyNowTmp);
  }, []);

  return (
    <li className='wishlist-product-item'>
      <article className='article article--horizontal wishlist-item--horizontal'>
        <div href={wishlistItem.Product.Url} className='article__image'>
          <a href={wishlistItem.Product.Url} className='article__image-link'>
            <img src={wishlistItem.Product.Image} alt={wishlistItem.Product.DisplayName} />
          </a>
          {!isOnlyView ? <ToggleWisList dataProduct={wishlistItem?.Product}/> : 
            <div className='heart active'>
              <span className='heart__icon'>
                <span></span>
              </span>
            </div>}
        </div>
        <div className='article__content'>
          <h3 className='article__content__title'>
            <a href={wishlistItem.Url} className='article__content__title-link'>
              {wishlistItem.Product.ProductName}
            </a>
          </h3>
          <div className='article__content__price'>
            {wishlistItem?.Product?.IsDeposit ? (
              <span className='article__content__price--main'>{wishlistItem?.Product?.Deposit?.DepositPriceWithCurrency}</span>
            ) : (
              <>
                <span className='article__content__price--main'>{wishlistItem.Product.Price.SellingPriceWithCurrency}</span>
                {wishlistItem.Product.Price.SellingPriceWithCurrency !== wishlistItem.Product.Price.ListPriceWithCurrency &&
                  <span className='article__content__price--old'>{wishlistItem.Product.Price.ListPriceWithCurrency}</span>
                }
              </>
            )}
          </div>
          <p className='article__content__description'>{wishlistItem.Product.Description}</p>
          {inputData['Learn More Label'].value ? (
            <a className='article__content__link btn-link' href={wishlistItem.Product.Url}>
              <Text field={inputData['Learn More Label']} />
            </a>
          ) : (
            <a className='article__content__link btn-link' href={wishlistItem.Product.Url}></a>
            // <Link className='article__content__link btn-link' field={inputData['Learn More Link']} />
          )}
          <div className='article__content__action'>
            {/* {inputData['Experience In Store Label'].value ? (
              <Link className='btn btn-outline-CTA2' field={inputData['Experience In Store Link']}>
                <Text field={inputData['Experience In Store Label']} />
              </Link>
            ) : (
              <Link className='btn btn-outline-CTA2' field={inputData['Experience In Store Link']} />
            )} */}
            {
              !wishlistItem.Product?.IsBundle ?  <BuyButtonComponent productData={{
                ...wishlistItem.Product,
                productId: `${wishlistItem.Product.ProductId}|${wishlistItem.Product.RepresentativeVariantId}`
              }}>
                {buyNowLink ? <Link className='btn btn-primary' field={buyNowLink}>
                  {textButton}
                </Link> : ''}
              </BuyButtonComponent> : null
            }
          </div>
        </div>
      </article>
    </li>
  );
};

ProductWishlistItem.propTypes = {
  inputData: PropTypes.object,
  wishlistItem: PropTypes.object,
  removeWishlistEvt: PropTypes.func,
  isOnlyView: PropTypes.bool
};

export default ProductWishlistItem;
