import produce from 'immer';

import { ADD_WARRANTY, ADD_WARRANTY_FAILED, ADD_WARRANTY_SUCCESS, SET_PARAM_API_DATA } from '../../actions/addWarranty/types';

const initState = {
  isLoading: false,
  isSuccess: false,
  error: null,
  paramAPIData: null,
  addWarrantyProductSuccessRes: null
};

const addWarrantyReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case ADD_WARRANTY:
      draft.isLoading = true;

      draft.error = null;

      draft.isSuccess = false;
      break;

    case ADD_WARRANTY_SUCCESS:
      draft.isLoading = false;

      draft.isSuccess = true;

      draft.addWarrantyProductSuccessRes = action.payload;
      break;

    case ADD_WARRANTY_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

    case SET_PARAM_API_DATA:
      draft.paramAPIData = { ...action.payload };
      break;
    }

    return draft;
  });

export default addWarrantyReducer;
