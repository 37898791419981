import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { updateBookingExperienceFailed, updateBookingExperienceSuccess } from '@redux/actions/bookingExperience/updateBookingExperience';
import { UPDATE_BOOKING_EXPERIENCE_START } from '@redux/actions/bookingExperience/updateBookingExperience/types';
import { postAsObservable } from '@services/genericService';

import { addCurlyBrackets, handleGenerateAppointmentParams } from '../utils';
import { UPDATE_EXPERIENCE_ENDPOINT } from './config';
import { UpdateBookingExperienceStatus } from './responseStatus';

const updateBookingExperienceEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_BOOKING_EXPERIENCE_START),
    switchMap((action) => {
      const params = handleGenerateAppointmentParams(action.payload);
      if (action.payload.appointmentId) params.AppointmentId = addCurlyBrackets(action.payload.appointmentId);

      return from(
        postAsObservable(UPDATE_EXPERIENCE_ENDPOINT, params).pipe(
          map((res) => {
            if (UpdateBookingExperienceStatus.SuccessStatusCode.includes(res.data.StatusCode)) {
              return updateBookingExperienceSuccess(res.data);
            } else {
              return updateBookingExperienceFailed(res.data);
            }
          })
        )
      );
    }),
    catchError((error) => of(updateBookingExperienceFailed(error.message || 'Failed')))
  );

export default updateBookingExperienceEpic;
