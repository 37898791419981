import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '../../actions/helpMeDecide';
import { GET_HELP_ME_DECIDE } from '../../actions/helpMeDecide/types';
import { getAsObservable } from '../../../services/helpMeDecideService';

export const getHelpMeDecide = (action$) => {
    return action$.pipe(
        ofType(GET_HELP_ME_DECIDE),
        switchMap((action) => {
            return getAsObservable(action.payload.params).pipe(
                map((res) => {
                    if (res.status === 200) {
                        return actions.getHelpMeDecideSuccess({
                            responses: res,
                            isFilter: action.payload.isFilter
                        });
                    } else {
                        return actions.getHelpMeDecideFailed('Failed');
                    }
                })
            );
        })
    );
}


