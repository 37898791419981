import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import { config } from '../AddressForMultiLanguage/service';
import AddressElementItemModel from './AddressElementItemModel';
import CountryModel from './CountryModel';

export default class AddressSettingModel {
  constructor() {
    this['Address Format'] = new SitecoreTextModel();

    this['Address Elements'] = [new AddressElementItemModel().getData({})];

    this['Country Settings'] = new CountryModel();
  }
  getData(dataSource, isCheckHiddenAddressName) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Country Settings')) {
      this['Country Settings'].getData(dataSource['Country Settings']?.fields?.['Countries-Regions']?.[0]?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Format')) {
      this['Address Format'].getData(dataSource['Address Format']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Elements') && dataSource['Address Elements']?.length) {
      this['Address Elements'].length = 0;

      let addressNameIdx;

      dataSource['Address Elements'].some( (element, elementIdx) =>  {
        const obj = new AddressElementItemModel().getData(element?.fields || {});

        if (obj?.[config.FieldNameModel]?.value === 'AddressName') {
          addressNameIdx = elementIdx;

          return true;
        }
      });

      dataSource['Address Elements'].map((element, elementIdx) => {
        const obj = new AddressElementItemModel().getData(element?.fields || {});

        dataSource['Address Elements'].some( (element, elementIdx) =>  {
          if (element?.[config.FieldNameModel]?.value === 'AddressName') {
            addressNameIdx = elementIdx;
  
            return true;
          }
        });
        if (isCheckHiddenAddressName) {
          const user = SitecoreContextFactoryService.getValueContextItem('user');

          if (user) {
            this['Address Elements'].push({ ...obj, index: elementIdx });
          }
          if (!user && obj[config.FieldNameModel]?.value !== 'AddressName') {
            this['Address Elements'].push({ ...obj, index: addressNameIdx >= 0 && elementIdx > addressNameIdx ? elementIdx - 1 : elementIdx });
          }
        } else {
          this['Address Elements'].push({ ...obj, index: elementIdx });
        }
      });
      //   if(dataSource['Address Elements']?.length) {
      //     dataSource['Address Elements'].map((element, elementIdx) => {
      //       const obj = new AddressElementItemModel().getData(element?.fields || {});
      //       if (user) {
      //         this['Address Elements'].push({...obj, index: elementIdx});
      //       }
      //       if(!user && obj[config.FieldNameModel]?.value !== 'AddressName') {
      //         this['Address Elements'].push({...obj, index: elementIdx - 1});
      //       }
      //     });
      //   } else {
      //     const obj = this['Address Elements'].push(new AddressElementItemModel().getData({}));
      //     if (user) {
      //       this['Address Elements'].push(obj);
      //     }
      //     if(!user &&  obj[config.FieldNameModel]?.value !== 'AddressName') {
      //       this['Address Elements'].push(obj);
      //     }
      //   }
    }

    return this;
  }
}
