import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SortOptionModel {
  constructor() {
    this.Name = new SitecoreTextModel();

    this.Value = new SitecoreTextModel();

  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name.getData(dataSource.Name);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Value')) {
      this.Value.getData(dataSource.Value);
    }
    
    return this;
  }
}
