import './MemberEventCancel.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import MemberEventCancelModel from './models/MemberEventCancelModel';

const MemberEventCancel = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    let model = new MemberEventCancelModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    <div className='container member-event-cancel'>
      <Text tag='h3' field={layoutData['Title']} className='member-event-cancel__title' />
      <div className='member-event-cancel__cta'>
        <Link field={{ href: layoutData['Explore Button Link'].value }} className='btn btn-primary'>
          <Text field={layoutData['Explore Button Text']} />
        </Link>
        <Link field={{ href: layoutData['My Appointments Button Link'].value }} className='btn btn-link'>
          <Text field={layoutData['My Appointments Button Text']} />
        </Link>
      </div>
    </div>
  ) : (
    <></>
  );
};

MemberEventCancel.propTypes = {
  fields: object
};

export default MemberEventCancel;
