export default class LoyaltyLandingPageTableModel {
  constructor() {
    this['Tiers'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, ['Tiers'])) {
      this['Tiers'] = dataSource['Tiers'];
    }

    return this;
  }
}