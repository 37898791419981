import produce from 'immer';

import ProductDetailOverviewApiModel from '@components/Product/VariantDetail/ProductDetailOverview/models/ProductDetailOverviewApiModel';
// Import action types
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  error: '',
  productDetailOverview: null,
  productDetailStaticBundleOverview: null,
  VariantActiveDefault: null,
  isLoading: false,
  isGetDataSuccess: false,
  variantPropertyName: 'Color',
  variantSelected: null
};

const productDetailOverviewReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.GET_PRODUCT_DETAIL_OVERVIEW:
      draft.isLoading = true;

      break;

    case productTypes.GET_PRODUCT_DETAIL_OVERVIEW_SUCCESS:
      draft.productDetailOverview = deepCopy(new ProductDetailOverviewApiModel().getData(deepCopy(action.payload)));

      //INFO: set default variant active is first item

      draft.VariantActiveDefault = draft.productDetailOverview.VariantDetail[0];

      draft.isLoading = false;

      draft.isGetDataSuccess = true;
      if (draft.productDetailOverview.Scent) draft.variantPropertyName = 'Scent';
      if (draft.productDetailOverview.Design) draft.variantPropertyName = 'Design';
      break;

    case productTypes.GET_PRODUCT_DETAIL_OVERVIEW_FAILED:
      draft.error = '';

      draft.isLoading = false;
      break;

    case productTypes.SET_PRODUCT_DETAIL_OVERVIEW_ACTIVE:
      draft.VariantActiveDefault = deepCopy(action.payload);
      break;

    case productTypes.GET_VARIANT_SELECTED:
      draft.variantSelected = deepCopy(action.payload);

      break;

    case productTypes.GET_PRODUCT_DETAIL_STATIC_BUNDLE_OVERVIEW:
      draft.productDetailStaticBundleOverview = deepCopy(action.payload);

      break;
    }

    return draft;
  });

export default productDetailOverviewReducer;
