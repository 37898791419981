import './LoadingShimmer.scss';

import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const LoadingShimmer = (props) => {
  const { itemNumb } = props;

  const renderSkeletonItem = (number) => {
    let index = 0;
    let items = [];
    
    while (index < number) {
      index++;

      items.push(index);
    }

    return items;
  };

  return (
    <>
      { renderSkeletonItem(itemNumb).map((item, index) => {
        return (
          <Fragment key={index}>
            {props.children}
          </Fragment>
        );
      })}
    </>
  );
};

LoadingShimmer.propTypes = {
  itemNumb: PropTypes.any,
  children: PropTypes.any
};

export default LoadingShimmer;