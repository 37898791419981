import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import WinnersModels from './WinnersModels';

const TEXTARR = [
  'Title Text',
  'From Text',
  'Won Text',
  'Share Button Text',
];

const LINKARR = [
  'Share Button Link'
];

export default class SpinWinnersModels {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['Winners'] = [];
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Winners')) {
      if(dataSource['Winners'].length){
        dataSource['Winners'].map((item) => {
          this['Winners'].push(new WinnersModels().getData(item || {}));
        });
      }
    }

    return this;
  }
}
