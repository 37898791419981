import React from 'react';

const ReviewsSummaryShimmer = () => {
  return (
    <div className='reviews-summary skeleton__item'>
      <h2 className='reviews-summary__title'>
        <span className='text-line-heading-center' />
      </h2>
      <div className='reviews-summary__rating'>
        <span className='text-line-30-center' />
      </div>
      <div className='reviews-summary__rating'>
        <span className='text-line-20-center' />
      </div>
      <p className='reviews-summary__note'>
        <span className='text-line-20-center' />
      </p>
      <p className='reviews-summary__label'>
        <span className='text-line-20-center' />
      </p>
      <p className='reviews-summary__note-share'>
        <span className='text-line-20-center' />
      </p>
      <div className='reviews-summary__btn'>
        <span className='button-line-center' />
      </div>
    </div>
  );
};

export default ReviewsSummaryShimmer;
