import './header.scss';
import 'react-toastify/dist/ReactToastify.css';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';

import { behaviorSubjectWishlist } from '@components/Product/MyWishlist/MyWishlistBehavior';
import { miniCartOffset } from '@components/Share/Header/MiniCart/handleMiniCartService';
import { searchBoxOffset } from '@components/Share/Header/SearchBox/handleSearchBoxService';
import { setHeaderHeight } from '@redux/actions/layoutElementStyle';
import { getCurrentCart, getProductWishlist } from '@redux/actions/product';
import { getLayoutElementStyleSelector } from '@redux/reducers/layoutElementStyle/selectors';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { HK_SITE_NAME } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useOnScreen, useOnScreenV2 } from '@utils/customsHook/useOnScreen';
import Global from '@utils/global';

import { isStickyHeader } from '../../Product/VariantDetail/ProductDetailHeader/handleStickyHeaderService';
import { mainBannerOffset } from '../../SlicingPage/HomePage/Banner/handleBannerService';
import Message from '../Message';
import OmnichatTracking from '../OmnichatTracking';
import { behaviorCatalogueNavigation } from './CatalogueNavigation/CatalogueNavigationBehavior';
import Hamburger from './Hambuger';
import { hamburgerOffset$ } from './handleHamburgerService';
import { headerSubject$ } from './headerHeightService';
import { behaviorIconNavigation } from './IconNavigation/IconNavigationBehavior';
import { behaviorQuickNavigation } from './QuickNavigation/QuickNavigationBehaviror';

const Header = (props) => {
  const headerRef = useRef(null);
  const overlayRef = useRef(null);
  const headerContainerRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(null);
  const [isActive, setActive] = useState(false);
  const [isShowOverlay, setIsShowOverlay] = useState(false);
  const [isAddClassHeaderDark, setIsAddClassHeaderDark] = useState(false);
  const [isStickyHeaderLocal, setIsStickyHeaderLocal] = useState(false);
  const [bannersArray, setBannersArray] = useState([]);
  const productWishlistReducer = useSelector((state) => state.productReducer.productWishlistReducer);
  const [hamburgerMenu, setHamburgerMenu] = useState(null);
  const dispatch = useDispatch();
  const user = SitecoreContextFactoryService.getValueContextItem('user');
  const [windowScrollY, setWindowScrollY] = useState(0);
  const [headerOffsetTop, setHeaderOffsetTop] = useState(0);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const isTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('HKOmnichat');
  const { cookieBannerHeight } = useSelector(getLayoutElementStyleSelector);
  const isOnScreen = useOnScreen(headerContainerRef);

  let currentScroll = 0;

  useEffect(() => {
    if (isActive) {
      Global.stopScrollingPage();
    }

    return () => {
      Global.scrollingPage();
    };
  }, [isActive]);

  useEffect(() => {
    dispatch(getCurrentCart());

    const handleScrollToTop = () => {
      window.scrollTo(0, 0);
    };

    window.addEventListener('onload', handleScrollToTop);

    return () => {
      window.removeEventListener('onload', handleScrollToTop);
    };
  }, []);

  useEffect(() => {
    const behaviorSubjectWishlistSubscription = behaviorSubjectWishlist.subscribe((params) => {
      if (user && !params?.isAlreadyWishList) {
        dispatch(getProductWishlist());
      }
    });

    return () => {
      behaviorSubjectWishlistSubscription.unsubscribe();
    };
  }, [behaviorSubjectWishlist]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, [bannersArray]);

  const handleClickHambuger = () => {
    document.getElementById('');

    setActive(!isActive);

    setIsShowOverlay(!isShowOverlay);

    hamburgerOffset$.next('check:ShowMiniCart');
  };

  const resetShowData = () => {
    setIsShowOverlay(false);

    setActive(false);
  };

  useEffect(() => {
    const mainBannerOffsetSubscription = mainBannerOffset.subscribe((item) => {
      if (item && item.offsetBottom > 0) {
        setBannersArray((oldArray) => [...oldArray, item]);
      }
    });

    return () => {
      mainBannerOffsetSubscription.unsubscribe();
    };
  }, [mainBannerOffset]);

  useDidUpdateEffect(() => {
    if (productWishlistReducer.error && !productWishlistReducer.isGetWishList) {
      toast.error(<Message messageCode='Msg_013' />);
    }
  }, [productWishlistReducer.error]);

  const onScroll = () => {
    currentScroll = window.scrollY;
    let isDarkMode = false;

    for (let bannerIndex = 0; bannerIndex < bannersArray.length; bannerIndex++) {
      if (currentScroll >= bannersArray[bannerIndex].offsetTop && currentScroll <= bannersArray[bannerIndex].offsetBottom) {
        isDarkMode = true;
        break;
      }
    }
    if (isDarkMode) {
      setIsAddClassHeaderDark(true);
    } else {
      setIsAddClassHeaderDark(false);
    }
  };

  useEffect(() => {
    const isStickyHeaderSubscription = isStickyHeader.subscribe((val) => {
      setIsStickyHeaderLocal(val);
    });

    return () => {
      isStickyHeaderSubscription.unsubscribe();
    };
  }, [isStickyHeader]);

  useEffect(() => {
    const miniCartOffsetSubscription = miniCartOffset.subscribe(() => resetShowData());

    return () => {
      miniCartOffsetSubscription.unsubscribe();
    };
  }, [miniCartOffset]);

  useEffect(() => {
    const searchBoxOffsetSubscription = searchBoxOffset.subscribe((item) => item.length && resetShowData());

    return () => {
      searchBoxOffsetSubscription.unsubscribe();
    };
  }, [searchBoxOffset]);

  //get header Height Calculate Scroll To
  useEffect(() => {
    setTimeout(() => {
      headerSubject$.next(headerRef.current.offsetHeight);
    }, 350);
  }, [windowWidth]);

  useEffect(() => {
    let arr = [];

    const behaviorCatalogueNavigationSubscription = behaviorCatalogueNavigation.subscribe((params) => {
      arr.push(typeof params == 'boolean' ? params : null);
    });

    const behaviorQuickNavigationSubscription = behaviorQuickNavigation.subscribe((params) => {
      arr.push(typeof params == 'boolean' ? params : null);
    });

    const behaviorIconNavigationSubscription = behaviorIconNavigation.subscribe((params) => {
      arr.push(typeof params == 'boolean' ? params : null);
    });

    arr.every((item) => !item) ? setHamburgerMenu(false) : setHamburgerMenu(true);

    return () => {
      behaviorCatalogueNavigationSubscription.unsubscribe();

      behaviorQuickNavigationSubscription.unsubscribe();

      behaviorIconNavigationSubscription.unsubscribe();
    };
  }, [behaviorCatalogueNavigation, behaviorQuickNavigation, behaviorIconNavigation]);

  useEffect(() => {
    //INFO: save previous url page
    const urlPath = window.location.pathname;
    const prevUrlPath = JSON.parse(window.sessionStorage.getItem('urlPage'))?.urlPage || '';

    let urlRedirect = {
      urlPage: urlPath,
      prevUrlPage: prevUrlPath
    };

    window.sessionStorage.setItem('urlPage', JSON.stringify(urlRedirect));
  }, []);

  useEffect(() => {
    const onSetVariableForWindowScrollY = () => {
      // const dynamicStripAdsHeight = document.getElementById('dynamicStripAdsTop')?.offsetHeight || 0;
      const cookieBannerElement = document.getElementById('cookie-banner');
      const headerOffsetTopVariable = headerRef?.current?.offsetTop;

      setHeaderOffsetTop(headerOffsetTopVariable);

      if (cookieBannerElement) {
        let cookieBannerHeight = cookieBannerElement.offsetHeight;

        window.scrollY >= headerOffsetTopVariable - cookieBannerHeight ? setWindowScrollY(window.scrollY) : setWindowScrollY(0);
      } else {
        window.scrollY >= headerOffsetTopVariable ? setWindowScrollY(window.scrollY) : setWindowScrollY(0);
      }
    };

    window.addEventListener('scroll', onSetVariableForWindowScrollY);

    onSetVariableForWindowScrollY();

    return () => window.removeEventListener('scroll', onSetVariableForWindowScrollY);
  }, [headerRef?.current?.offsetTop]);

  useDidUpdateEffect(() => {
    const handleSetOverlaySpaceTop = () => {
      let headerOffsetTopVariable = 0;

      if (headerRef.current.getBoundingClientRect().top > 0) headerOffsetTopVariable = headerRef.current.getBoundingClientRect().top;
      else headerOffsetTopVariable = headerContainerRef.current.offsetTop;

      if (overlayRef.current) overlayRef.current.style.top = `${headerOffsetTopVariable + headerRef.current.offsetHeight}px`;
    };

    window.addEventListener('scroll', handleSetOverlaySpaceTop);

    window.addEventListener('resize', handleSetOverlaySpaceTop);

    handleSetOverlaySpaceTop();

    return () => {
      window.removeEventListener('scroll', handleSetOverlaySpaceTop);

      window.removeEventListener('resize', handleSetOverlaySpaceTop);
    };
  }, [overlayRef?.current, headerRef?.current?.getBoundingClientRect()?.top, headerContainerRef.current]);

  // useEffect(() => { INFO: uncomment if have some modification
  //   // INFO: Omnichat Tracing for HK site
  //   if (currentSiteName === HK_SITE_NAME && isTurnOnOmnichat) {
  //     const singleSignOn = JSON.parse(window.localStorage.getItem('persist:singleSignOnReducer'));
  //
  //     if (singleSignOn) {
  //       const userInfoReducer = JSON.parse(singleSignOn.userInfoReducer);
  //       if (userInfoReducer?.accountUser) {
  //         new OmnichatTracking().trackingLoggedUser(userInfoReducer?.accountUser);
  //       }
  //     }
  //   }
  // }, []);

  useLayoutEffect(() => {
    const handleSetHeaderHeight = () => {
      if (headerRef?.current) {
        const headerHeight = headerRef.current.offsetHeight;

        if (isOnScreen) dispatch(setHeaderHeight(headerHeight));
        else dispatch(setHeaderHeight(0));
      } else {
        dispatch(setHeaderHeight(0));
      }
    };

    document.addEventListener('resize', handleSetHeaderHeight);

    document.addEventListener('scroll', handleSetHeaderHeight);

    handleSetHeaderHeight();

    return () => document.removeEventListener('resize', handleSetHeaderHeight);
  }, [headerRef, isOnScreen]);

  return (
    <Fragment>
      <header
        ref={headerRef}
        className={
          isAddClassHeaderDark
            ? `header ${windowScrollY > 0 || headerOffsetTop === 0 ? 'stick' : ''} header--dark-mode${isStickyHeaderLocal ? ' non-sticky-detail-product' : ''
            }`
            : `header ${windowScrollY > 0 || headerOffsetTop === 0 ? 'stick' : ''}${isStickyHeaderLocal ? ' non-sticky-detail-product' : ''}`
        }
      >
        <div ref={headerContainerRef} className='header__container' style={{ top: `${cookieBannerHeight}px` }}>
          <div className='header__row'>
            <div className='header__col'>
              <div className='header__col'>{hamburgerMenu ? <Hamburger isActive={isActive} onClickFnc={handleClickHambuger} /> : <></>}</div>
            </div>
            <div className='header__col header__col--center'>
              <Placeholder name='osim-header-top' rendering={props.rendering} checkActive={isShowOverlay} headerRef={headerRef} headerContainerRef={headerContainerRef} />
            </div>
            <div className='header__col header__col--right'>
              <Placeholder name='osim-header-bottom' rendering={props.rendering} />
            </div>
          </div>
        </div>
        {isShowOverlay && (
          <div ref={overlayRef} className='overlay-black' onClick={resetShowData}>
            {/* <div className='overlay-black__header'></div> */}
            <div className='overlay-black__body'></div>
          </div>
        )}
      </header>
      {productWishlistReducer.error && !productWishlistReducer.isGetWishList ? (
        <ToastContainer
          className='toastify-custom'
          position='top-right'
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          pauseOnHover
        />
      ) : (
        ''
      )}
    </Fragment>
  );
};

Header.propTypes = {
  rendering: PropTypes.any
};

export default Header;
