import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import { getCpProductInterestDataFailed, getCpProductInterestDataSuccess } from '@redux/actions/cpProductInterestData';
import { GET_CP_PRODUCT_INTEREST_DATA } from '@redux/actions/cpProductInterestData/cpProductInterestDataTypes';

import {
  getAsObservable,
} from '../../../services/genericService';
import {
  CP_GET_PRODUCT_INTEREST_DATA_ENDPOINT,
} from './config';

export const getCpProductInterestData = (action$) =>
  action$.pipe(
    ofType(GET_CP_PRODUCT_INTEREST_DATA),
    switchMap((action) => {
      const { page, pageSz } = action.payload;

      return getAsObservable(`${CP_GET_PRODUCT_INTEREST_DATA_ENDPOINT}?page=${page}&pageSz=${pageSz}`).pipe(
        map((res) => {
          if (res.status === 200) {
            return getCpProductInterestDataSuccess(res.data.Products);
          } else {
            return getCpProductInterestDataFailed('Failed');
          }
        })
      );
    })
  );