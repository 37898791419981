import Proptypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react';

const ZoomSwiperComponentItem = ({ inputData }) => {
  return (
    <Fragment>
      <div className='swiper-zoom-container'>
        <img className='swiper-lazy animated' data-src={inputData} alt='no alt' />
        <div className='swiper-lazy-preloader swiper-lazy-preloader-white'></div>
      </div>
    </Fragment>
  );
};

ZoomSwiperComponentItem.propTypes = {
  inputData: Proptypes.string
};

export default ZoomSwiperComponentItem;
