import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Save Button Label', 
  'Edit Button Label', 
  'Cancel Button Label', 
  'Confirm Button Label',
  'Session Not Started Warning Description',
  'Session Not Started Warning Title',
  'Start Session Button Text',
  'Back Button',
  'Store Selection Warning Description'
];

export default class QuickAccessModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
