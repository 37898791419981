import { useFormik } from 'formik';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import { getOtpCodeStart } from '@redux/actions/campaignRegister/getOtpCode';
import { clearValidateOtpCodeErrorMessage, validateOtpStart } from '@redux/actions/campaignRegister/validateOtp';
import { GetOtpStatus } from '@redux/epic/campaignRegister/getOtpCode/responseStatus';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { MY_SITE_NAME } from '@utils/constant';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import global from '@utils/global';

import { useCampaignContext } from '../../useCampaignContext';

const EnterOTPForm = () => {
  const dispatch = useDispatch();
  const { layoutData, userRegisterInfo } = useCampaignContext();
  const { error: getOtpErrorMessage } = useSelector((state) => state.campaignRegisterReducer.getOtpCodeReducer);
  const { error: validateOtpError, isLoading } = useSelector((state) => state.campaignRegisterReducer.validateOtpReducer);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const isLoginSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const accountUserInfo = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.accountUser);
  const [isShowResendMessage, setIsShowResendMessage] = useState(false);
  const isAtMYSite = useIsAtSiteName(MY_SITE_NAME);

  const formik = useFormik({
    initialValues: {
      otpCode: ''
    },
    validationSchema: Yup.object().shape({
      otpCode: Yup.string().required(layoutData?.inputValidOTPMessage?.value)
    }),
    onSubmit: (values) => {
      // INFO: hide resend OTP message when submit
      setIsShowResendMessage(false);
      if (values.otpCode) {
        const params = {
          ...userRegisterInfo,
          phoneNumber: isAtMYSite ? `+${userRegisterInfo.phoneNumber}` : userRegisterInfo.phoneNumber,
          optCode: values.otpCode,
          campaignId: layoutData?.['campaign']?.Id,
          isLoginSuccess: isLoginSuccess,
          isMissingPhoneNumber: Boolean(accountUserInfo?.PhoneNumber)
        };

        dispatch(validateOtpStart(params));
      }
    }
  });
  const handleClearValidateOtpErrMsg = () => dispatch(clearValidateOtpCodeErrorMessage());

  const handleResendOtpCode = useCallback(() => {
    if (validateOtpError) handleClearValidateOtpErrMsg();

    // INFO: show resend OTP message
    setIsShowResendMessage(true);

    const params = {
      campaignId: layoutData?.['campaign']?.Id,
      phoneNumber: isAtMYSite ? `+${userRegisterInfo.phoneNumber}` : userRegisterInfo.phoneNumber
    };

    dispatch(getOtpCodeStart(params));
  }, [validateOtpError, isAtMYSite]);

  const getOtpErrorMsgCode = useMemo(() => {
    let msgCode = '';
    if (getOtpErrorMessage) {
      msgCode = GetOtpStatus.ErrorStatusCode[getOtpErrorMessage.StatusCode] || GetOtpStatus.GeneralMsg;
    }

    return msgCode;
  }, [getOtpErrorMessage]);

  const validateOtpErrorMessage = useMemo(() => {
    if (validateOtpError) {
      if (validateOtpError?.StatusCode === 2) return layoutData?.['otpTimeoutMessage']?.value;
      if (validateOtpError?.StatusCode === 1) return layoutData?.['otpIncorrectMessage']?.value;
      if (validateOtpError?.StatusCode === 32) return layoutData?.['otpVerificationExceededMessage']?.value;
    }
  }, [
    validateOtpError,
    layoutData?.otpIncorrectMessage?.value,
    layoutData?.otpTimeoutMessage?.value,
    layoutData?.otpVerificationExceededMessage?.value
  ]);

  const handleChangeOtpInput = (event) => {
    // INFO: hide resend OTP message when enter otp input
    setIsShowResendMessage(false);

    // INFO: hide validate error message
    handleClearValidateOtpErrMsg();

    formik.setFieldValue('otpCode', event.target.value);
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit} className='register-event__form__content__section otp-input-section'>
        <Text tag='p' field={layoutData.otpSentMessage} className='section-label' />
        <div className={global.renderDynamicClass(formik.errors.otpCode && formik.touched.otpCode, 'form-group', 'input-error-validate')}>
          <input
            type='text'
            placeholder={layoutData.enterOTPPlaceholderText.value}
            id='otpCode'
            name='otpCode'
            onChange={handleChangeOtpInput}
            className={global.renderDynamicClass(formik.values['otpCode'], 'form-control form-control-lg', 'input-valid')}
          />
          {formik.errors.otpCode && formik.touched.otpCode ? (
            <span className='error-validate'>{formik.errors.otpCode}</span>
          ) : getOtpErrorMsgCode ? (
            <span className='error-validate'>{messageObj?.[getOtpErrorMsgCode]}</span>
          ) : validateOtpErrorMessage ? (
            <span className='error-validate'>{validateOtpErrorMessage}</span>
          ) : isShowResendMessage ? (
            <Text tag='span' field={layoutData.otpSentMessage} className='error-validate' />
          ) : (
            <></>
          )}
        </div>
        <div className='form-group resend-otp'>
          <button type='button' className='btn btn-link resend-otp__btn' onClick={handleResendOtpCode}>
            <Text field={layoutData.resendOTPLinkLabel} />
          </button>
        </div>
        <button type='submit' className='btn btn-primary'>
          <Text field={layoutData.proceedOTPButtonText} />
        </button>
      </form>
      {isLoading ? <LoadingMask /> : <></>}
    </>
  );
};

export default EnterOTPForm;
