import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import CartDetailApiModel from '@components/SlicingPage/CartDetails/models/CartDetailApiModel';
import { getBalanceBillingData } from '@redux/actions/checkoutProcess';
import * as actions from '@redux/actions/myOrders';
import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';
import { postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import CartDetailService from '../../product/cartDetail/service';
import * as config from './config';

export const getBalanceByIdEpic = (action$) =>
  action$.pipe(
    ofType(myOrderTypes.GET_BALANCE_BY_ID),
    switchMap((action) => {
      const url = config.ACTION_GET_BALANCE_BY_ID;

      const param = {
        orderId: action.payload.orderId,
        shopName: action.payload.shopName
      };

      return postAsObservable(url, param).pipe(
        switchMap((res) => {
          if (res.status === 200 && res.data.Success) {
            let filteredLineItem = [];
            const cartLineId = action.cartLineId || null;
            const cartDetailApiModel = new CartDetailApiModel().getData(deepCopy(res.data));
            if (cartLineId) {
              const filterItem = cartDetailApiModel?.Lines?.filter((item) => item?.ExternalCartLineId === cartLineId)[0];

              filteredLineItem = filterItem ? [filterItem] : [];
            }

            return of(actions.getMyBalancesByIdSuccess(CartDetailService.customCurrentCart(res.data), filteredLineItem), getBalanceBillingData());
          } else {
            return of(actions.getMyBalancesByIdFailed('Failed'));
          }
        })
      );
    })
  );