import produce from 'immer';

import StoreFilterAreaListModel from '@components/Share/StoreLocator/model/StoreFilterAreaListModel';
import * as storeLocatorTypes from '@redux/actions/storeLocator/storeLocatorTypes';
import { deepCopy } from '@utils/utility.js';

const initialState = {
  name: 'Filter Area Options',
  filterAreaOptions: [],
  isLoading: false,
  errorMsg: ''
};

const filterAreaOptionsReducer = (state = initialState, action) => 
  produce(state, draft => {
    let newAreaOptions = [];
    switch (action.type) {
    case storeLocatorTypes.GET_FILTER_AREA_LIST:

      draft.isLoading = true;
      break;

    case storeLocatorTypes.GET_FILTER_AREA_LIST_SUCCESS:
      action.payload.map(element => {
        newAreaOptions.push(new StoreFilterAreaListModel().getData(deepCopy(element)));
      });

      draft.filterAreaOptions = newAreaOptions;

      draft.isLoading = false;
      break;

    case storeLocatorTypes.GET_FILTER_AREA_LIST_FAILED:
      draft.filterAreaOptions = [];

      draft.isLoading = false;
      break;
    }

    return draft;
  });


export default filterAreaOptionsReducer;