import PropTypes from 'prop-types';
import React from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const ConfirmRemoveAddress = ({dataSources, confirmRemoveAddressEvt}) => {
  return  (
    <div className='confirm-remove-address text-center'>
      <p className='confirm-remove-message__text'>
        {dataSources ? <RichText field={dataSources['Remove Confirmation Message']}/> : ''}
      </p>
      <button className='confirm-remove-message__btn btn btn-outline-primary' onClick={() => confirmRemoveAddressEvt()}>
        {dataSources ? <Text field={dataSources['Remove Address Text']}/> : ''}
      </button>
    </div>
  );
};

ConfirmRemoveAddress.propTypes = {
  confirmRemoveAddressEvt: PropTypes.func,
  dataSources: PropTypes.object,
};

export default ConfirmRemoveAddress;
