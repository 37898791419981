import './CartSuggestDetail.scss';

import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Share/Loading';
import { getPromotionById } from '@redux/actions/cartDetail';

const CartSuggestDetailModal = ({ promotionId, layoutData, closeModalHandler }) => {
  const dispatch = useDispatch();
  const promotionData = useSelector((state) => state.productReducer.cartDetailReducer.promotionData);

  useEffect(() => {
    dispatch(getPromotionById({ promotionId: promotionId }));
  }, []);

  return (
    <div className='suggest-freeship'>
      {promotionData ? (
        <>
          <h3 className='suggest-freeship__title text-center'>{promotionData.Title}</h3>
          <p className='suggest-freeship__description'>{promotionData.Description}</p>
          <div className='suggest-freeship__btn text-center'>
            <button onClick={() => closeModalHandler()} className='btn btn-outline-primary'>{layoutData['Modal Close Label']}</button>
          </div>
        </>
      ) : (
        <Loading></Loading>
      )}
    </div>
  );
};

CartSuggestDetailModal.propTypes = {
  promotionId: PropTypes.string,
  layoutData: PropTypes.any,
  closeModalHandler: PropTypes.func
};

export default CartSuggestDetailModal;
