import './ProductHubInfo.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ProductHubInforModel from '../Models/ProductHubInforModel';

const MAX_ITEM = 5;

export const AnchorLink = ({linkList, pageData, linkColor}) => (
  linkList.length ? (
    <div className='col-6'>
      <ul className='product-hub-info__group-link'>
        {
          linkList.map((anchorLink) => (
            <li key={anchorLink.id}>
              <a 
                href={pageData['url'] + anchorLink.fields['Value']?.value || ''} 
                className='product-hub-info__link'
                style={{color: linkColor}}
              >
                <Text field={anchorLink.fields['Title']}/>
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  ) : ''
);

const ProductHubInfo = ({productResource}) => {
  const [productHubInforFields, ProductHubInforFields] = useState(null);

  useEffect(() => {
    const productHubInforModel = new ProductHubInforModel();

    ProductHubInforFields(productHubInforModel.getData(deepCopy(productResource.fields || {})));
  }, []);

  return productHubInforFields && (
    <div className='product-hub-info'>
      <div className='product-hub-info__image'>
        <ResponsiveImage
          imageMobile={productHubInforFields['Mobile Image']}
          srcSet={[ { mw: 768, image: productHubInforFields['Image'] } ]}
        />
      </div>
      <div className='product-hub-info__contents'>
        <a href={productResource['url']}>
          <Text 
            tag='h3' 
            field={productHubInforFields['Title']} 
            className='product-hub-info__title'
            style={{color: productHubInforFields['Title Color'].value || '#2a2a2a'}}
          />
        </a>
        <Text 
          tag='p' 
          field={productHubInforFields['Description']} 
          className='product-hub-info__description'
          style={{color: productHubInforFields['Text Color'].value || '#2a2a2a'}}
        />
        {
          productHubInforFields['Anchor Link List'].length ? (
            <div className='row product-hub-info__list-links'>
              {/* //INFO: max item to show is 5 for one col*/}
              <AnchorLink
                linkList={productHubInforFields['Anchor Link List'].slice(0, MAX_ITEM)}
                pageData={productResource}
                linkColor={productHubInforFields['Link Color'].value || '#0072bc'}
              />
              <AnchorLink
                linkList={productHubInforFields['Anchor Link List'].slice(MAX_ITEM)}
                pageData={productResource}
                linkColor={productHubInforFields['Link Color'].value || '#0072bc'}
              />
            </div>
          ) : ''
        }
      </div>
    </div>
  );
};

AnchorLink.propTypes = {
  linkList: PropTypes.array,
  pageData: PropTypes.object,
  linkColor: PropTypes.string
};

ProductHubInfo.propTypes = {
  productResource: PropTypes.object
};

export default ProductHubInfo;