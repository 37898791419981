import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/productWarranty';
import * as productWarrantyTypes from '@redux/actions/productWarranty/productWarrantyTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getUserProductDetailsEpic = (action$) =>
  action$.pipe(
    ofType(productWarrantyTypes.GET_USER_PRODUCT_DETAILS),
    switchMap((action) => {
      const params = {
        // ProductType:action.payload.productType,
        // OrderId: action.payload.orderId,
        // ExternalCartLineId: action.payload.externalCartLineId,
        // ReviewId: action.payload.reviewId
        MyProductId: action.payload.myProductId
      };

      return getAsObservable(config.ACTION_GET_USER_PRODUCT_DETAILS, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getUserProductDetailsSuccess(deepCopy(res.data));
          } else {
            return actions.getUserProductDetailsFailed('Failed');
          }
        })
      );
    })
  );

export const removeMyProductEpic = (action$) =>
  action$.pipe(
    ofType(productWarrantyTypes.REMOVE_MY_PRODUCT),
    switchMap((action) => {
      const params = {
        myProductId: action.payload.myProductId,
        warrantyId: action.payload.warrantyId
      };

      return getAsObservable(config.ACTION_REMOVE_MY_PRODUCT, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data.IsSuccess) {
            return actions.removeMyProductSuccess(res.data);
          } else {
            return actions.removeMyProductFailed(res.data?.Messages?.[0] || 'Failed');
          }
        })
      );
    })
  );
