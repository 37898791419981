import { any, func, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { 
  // clearInformation, 
  getOrderID 
} from '@redux/actions/clientTellingPortalCustomer';
import {updateCPCustomerContact } from '@redux/actions/clientTellingPortalCustomer';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { DEFAULT_API_VALUE } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import { keepLifeStyleData } from '../../../redux/actions/clientTellingPortalCustomer';
// import WarningFinalReview from '../FinalReview/WarningFinalReview';
import { TYPE_RADIO, TYPE_TEXT } from '../GenericComponents/CPForm/FormConstant';
import PurchaseOnTheSpotModel from './model/PurchaseOnTheSpotModel';

const PurchaseOnTheSpot = ({ fields, isRequirePOS, getPurchaseSelected, isRequireInvoice, setIsSelectOptionInPOS, setIsRequirePOS, setIsRequireInvoice }) => {
  const referPOS = useSelector((state) => state.clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  const [layoutData, setLayoutData] = useState(null);
  const [POS, setPOS] = useState(false);
  const [isPOSPurchase, setIsPOSPurchase] = useState(false);
  const [getPOSOrderId, setGetPOSOrderId] = useState(null);
  const [getInvoiceNumber, setGetInvoiceNumber] = useState(null);

  const [activePurchaseType, setActivePurchaseType] = useState('');
  const [purchaseValue, setPurchaseValue] = useState(null);
  const [invoiceValue, setInvoiceValue] = useState(null);
  // const [isWarning_010, setIsWarning_010] = useState(false);
  const [isRequirePOSOrderId, setIsRequirePOSOrderId] = useState(isRequirePOS);
  const [isRequireInvoiceNumber, setIsRequireInvoiceNumber] = useState(isRequireInvoice);
  const dispatch = useDispatch();

  const onChangePOS = (type) => {
    setActivePurchaseType(type);

    setIsSelectOptionInPOS(type);

    if (type === 'POS') {
      setInvoiceValue('');

      setIsRequireInvoice(false);

      window.localStorage.removeItem('InvoiceNumberLocal');
    } else {
      setPurchaseValue('');

      setIsRequirePOS(false);

      window.localStorage.removeItem('POSOrderIdLocal');
    }

    dispatch(
      getOrderID({
        referPOS: activePurchaseType == 'POS' || activePurchaseType == 'nonPOS' ? false : true,
        orderId: purchaseValue || '',
        invoiceNumber: invoiceValue || ''
      })
    );
  };

  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const POSTransactionID = urlParams.get('POSTransactionID');
  
  // const  clearDataDemo = () => {
  //   let tmpData = deepCopy(clientTellingPortalCustomer.tmpCustomerInformation);
  //   let formData = new window.FormData();

  //   const params = {
  //     ContactID: clientTellingPortalCustomer.customerInformation?.ContactID,
  //     Email: DEFAULT_API_VALUE,
  //     PhoneNumber: DEFAULT_API_VALUE,
  //     PrefixPhoneNumber: DEFAULT_API_VALUE,
  //     DOB: DEFAULT_API_VALUE,
  //     PersonalInformation: {
  //       Gender: DEFAULT_API_VALUE,
  //       FirstName: DEFAULT_API_VALUE,
  //       LastName: DEFAULT_API_VALUE,
  //     },
  //     CPCustomerInfo: {
  //       MaritalStatus: DEFAULT_API_VALUE,
  //       JobNature: tmpData.CPCustomerInfo.JobNature || DEFAULT_API_VALUE,
  //       HealthIssues: tmpData.CPCustomerInfo.HealthIssues || DEFAULT_API_VALUE,
  //       HouseholdSize: tmpData.CPCustomerInfo.HouseholdSize || DEFAULT_API_VALUE,
  //       MassageOwnershipType: tmpData.CPCustomerInfo.MassageOwnershipType || DEFAULT_API_VALUE,
  //       MassageOwnership: tmpData.CPCustomerInfo.MassageOwnership || DEFAULT_API_VALUE,
  //       AllowDirectMail: false,
  //       AllowSms: false,
  //       AllowEmail: false,
  //       PDPAAgreement: false,
  //       ReferPOS: true,
  //       SignatureImageUrl: DEFAULT_API_VALUE,
  //     },
  //   };

  //   for (let keyParams in params) {
  //     if (keyParams == 'PersonalInformation') {
  //       for (let keyChild in params[keyParams]) {
  //         formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
  //       }
  //     } else if (keyParams == 'CPCustomerInfo') {
  //       for (let keyChild in params[keyParams]) {
  //         formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
  //       }
  //     } else if (keyParams == 'CPCustomerInterest') {
  //       for (let keyChild in params[keyParams]) {
  //         params[keyParams][keyChild].forEach((item, index) => {
  //           for (let keyItem in item) {
  //             if (keyItem == 'Questions') {
  //               params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
  //                 for (let keyChildItem in childItem) {
  //                   formData.append(
  //                         `CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`,
  //                         childItem[keyChildItem]
  //                   );
  //                 }
  //               });
  //             } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
  //           }
  //         });
  //       }
  //     } else {
  //       formData.append(keyParams, params[keyParams]);
  //     }
  //   }

  //   dispatch(updateCPCustomerContact(formData));
  // };

  // const onChangePurchaseOnTheSpot = () => {
  //   setPOS(true);

  //   setIsWarning_010(false);

  //   // dispatch(clearInformation());
  //   clearDataDemo();
  // };

  // const savePurchaseOnSpotToLocal = (isOPurchase, posOrderId)=>{}

  useEffect(() => {
    const model = new PurchaseOnTheSpotModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    const getPOSOrderIdLocal = JSON.parse(window.localStorage.getItem('POSOrderIdLocal')) || null;
    const getInvoiceNumberLocal = JSON.parse(window.localStorage.getItem('InvoiceNumberLocal')) || null;

    setGetPOSOrderId(getPOSOrderIdLocal);

    setGetInvoiceNumber(getInvoiceNumberLocal);
  }, []);

  useEffect(() => {
    clientTellingPortalCustomer.POSOrderId || getPOSOrderId
      ? setActivePurchaseType('POS')
      : !clientTellingPortalCustomer.POSOrderId && !getPOSOrderId && !clientTellingPortalCustomer.InvoiceNumber && !getInvoiceNumber
        ? setActivePurchaseType('POS')
        : setActivePurchaseType('nonPOS');
  }, [getPOSOrderId, getInvoiceNumber]);

  useEffect(() => {
    setPurchaseValue(clientTellingPortalCustomer.POSOrderId || getPOSOrderId || '');

    setInvoiceValue(clientTellingPortalCustomer.InvoiceNumber || getInvoiceNumber || '');
  }, [getPOSOrderId, getInvoiceNumber]);

  useEffect(() => {
    dispatch(
      getOrderID({
        referPOS: POS,
        orderId: purchaseValue || '',
        invoiceNumber: invoiceValue || ''
      })
    );
  }, [POS]);

  useDidUpdateEffect(() => {
    return (getPOSOrderId || getInvoiceNumber) && setIsPOSPurchase(true);
  }, [getOrderID, getInvoiceNumber, getPOSOrderId]);

  useDidUpdateEffect(() => {
    setIsRequirePOSOrderId(isRequirePOS);

    setIsRequireInvoiceNumber(isRequireInvoice);
  }, [isRequirePOS, isRequirePOSOrderId, isRequireInvoice, isRequireInvoiceNumber]);

  useDidUpdateEffect(() => {
    setPOS(referPOS);
  }, [referPOS]);

  useDidUpdateEffect(() => {
    dispatch(
      getOrderID({
        // referPOS: activePurchaseType == 'POS' ? true : false,
        // orderId: activePurchaseType == 'POS' ? `${layoutData['POS Order ID Prefix'].value}${purchaseValue}` : purchaseValue
        referPOS: referPOS,
        orderId: purchaseValue,
        invoiceNumber: invoiceValue
      })
    );
  }, [purchaseValue, invoiceValue]);

  return layoutData ? (
    <>
      <div className='cp-review__content__info cp-review__content__info--purchase'>
        <div className='cp-review__content__info__box'>
          <Text className='cp-review__content__info__title' field={layoutData['Title']} tag='h5' />
          <div className='cp-form'>
            <div className='cp-form__wrap'>
              <div className='cp-form__left'>
                <div className='form-group'>
                  <Text className='cp-form__label' field={layoutData['Title']} tag='p' />
                  <div className='cp-form__group cp-form__group--w-100 cp-form__group--item-w-50'>
                    <div className='cp-form__group__item'>
                      <div className='cp-input type-select --rectangle'>
                        <input
                          disabled={false}
                          type={TYPE_RADIO}
                          id='input-radio-1'
                          name='input-radio'
                          checked={POSTransactionID || POS || isPOSPurchase}
                          onClick={() => setIsPOSPurchase(true)}
                          readOnly
                          value={1}
                          onChange={(e) => getPurchaseSelected(e.target.value)}
                        />
                        <label htmlFor='input-radio-1' className={`cp-input__label ${POS ? 'label-active' : ''}`}>
                          <Text tag='span' field={layoutData['Yes Label']} />
                        </label>
                      </div>
                    </div>
                    <div className='cp-form__group__item'>
                      <div className='cp-input type-select --rectangle'>
                        <input
                          disabled={false}
                          type={TYPE_RADIO}
                          id='input-radio-2'
                          name='input-radio'
                          checked={POSTransactionID || POS ? false : !isPOSPurchase}
                          onClick={() => {
                            setIsPOSPurchase(false);
                          }}
                          readOnly
                          value={''}
                          onChange={(e) => {
                            window.localStorage.removeItem('POSOrderIdLocal');

                            window.localStorage.removeItem('InvoiceNumberLocal');

                            setInvoiceValue('');

                            setPurchaseValue('');

                            getPurchaseSelected(e.target.value);
                          }}
                        />
                        <label htmlFor='input-radio-2' className={`cp-input__label ${POS ? 'disable-event' : ''}`}>
                          <Text tag='span' field={layoutData['No Label']} />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {POS || isPOSPurchase ? (
              <>
                <div className='cp-form__wrap'>
                  <div className='cp-form__left'>
                    <div className='form-group'>
                      <div className='osim-radio'>
                        <input
                          className='osim-radio-input'
                          type='radio'
                          id='pos-order-id'
                          name='final-review-pos'
                          readOnly
                          checked={activePurchaseType === 'POS'}
                        />
                        <label className='osim-radio-label' onClick={() => onChangePOS('POS')} />
                        <Text className='osim-radio-title' field={layoutData['POS Order Id Text']} tag='label' />
                      </div>
                    </div>
                  </div>
                  <div className='cp-form__right'>
                    <div className='form-group'>
                      <div className='osim-radio'>
                        <input
                          className='osim-radio-input'
                          type='radio'
                          id='invoice-no'
                          name='final-review-pos'
                          checked={activePurchaseType === 'nonPOS'}
                          readOnly
                        />
                        <label className='osim-radio-label' htmlFor='invoice-no' onClick={() => onChangePOS('nonPOS')} />
                        <Text
                          className='osim-radio-title'
                          field={layoutData['Invoice Number Text']}
                          tag='label'
                          htmlFor='invoice-no'
                          onClick={() => onChangePOS('nonPOS')}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='cp-form__wrap'>
                  <div className='cp-form__left'>
                    <div className='form-group'>
                      {/* <div className='cp-form__group__left'>
                        <Text className='cp-form__group__left__text' tag='span' field={layoutData['POS Order ID Prefix']} />
                      </div> */}
                      <div className='cp-form__group__right'>
                        <div className='cp-input type-select type-select__checkbox'>
                          <input
                            type={TYPE_TEXT}
                            disabled={activePurchaseType !== 'POS'}
                            onChange={(e) => {
                              setPurchaseValue(e.target.value);

                              setIsRequirePOSOrderId(false);

                              dispatch(keepLifeStyleData());
                            }}
                            value={POSTransactionID || purchaseValue}
                          />
                        </div>
                        {/*<Image className='cp-icon' field={layoutData['Icon']} />*/}
                      </div>
                      {isRequirePOSOrderId ? <Text className='error-mess' field={layoutData['Require Order Id Message']} tag='span' /> : ''}
                    </div>
                  </div>
                  <div className='cp-form__right'>
                    <div className='form-group'>
                      <div className='cp-input type-select type-select__checkbox'>
                        <input
                          type={TYPE_TEXT}
                          placeholder='Invoice No.'
                          disabled={activePurchaseType !== 'nonPOS'}
                          onChange={(e) => {
                            setInvoiceValue(e.target.value);

                            setIsRequireInvoiceNumber(false);

                            dispatch(keepLifeStyleData());
                          }}
                          value={invoiceValue}
                        />
                      </div>
                      {isRequireInvoiceNumber ? <Text className='error-mess' field={layoutData['Require Invoice Number Message']} tag='span' /> : ''}
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      {/* {
        <WarningFinalReview
          isShow={isWarning_010}
          onCloseModalHandler={() => setIsWarning_010(false)}
          onClickButtonlHandler={() => onChangePurchaseOnTheSpot()}
          popupTitle={layoutData['Warning Title']}
          popupContents={layoutData['Warning Message']}
          buttonText={{value: 'Yes'}}
          linkText={{value: 'Cancel'}}
        />
      } */}
    </>
  ) : (
    <></>
  );
};

PurchaseOnTheSpot.propTypes = {
  fields: object,
  isRequirePOS: any,
  getPurchaseSelected: any,
  isRequireInvoice: any,
  setIsRequirePOS: any,
  setIsRequireInvoice: any,
  setIsSelectOptionInPOS: any
};

export default PurchaseOnTheSpot;
