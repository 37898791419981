import produce from 'immer';

import {
  GET_MY_REWARD_WIDGET_INFO_FAILED,
  GET_MY_REWARD_WIDGET_INFO_START,
  GET_MY_REWARD_WIDGET_INFO_SUCCESS
} from '@redux/actions/loyalty/myRewardWidgetInfo/types';

const initialState = {
  isLoading: false,
  error: null,
  myRewardInfo: {
    CodeLoyalty: '',
    NameLoyalty: '',
    IsMember: false,
    UrlImage: '',
    Username: '',
    SpentAmount: 0,
    SpentAmountStr: '',
    SpentAmountNext: 0,
    SpentAmountNextStr: '',
    CodeLoyaltyNext: '',
    NameLoyaltyNext: '',
    DateTimeReview: '',
    MessageError: null,
  }
};

const getMyRewardWidgetInfoReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_MY_REWARD_WIDGET_INFO_START:
      draft.isLoading = true;
      break;

    case GET_MY_REWARD_WIDGET_INFO_SUCCESS:
      draft.isLoading = false;

      Object.keys(draft.myRewardInfo).forEach((key) => (draft.myRewardInfo[key] = action.payload[key]));
      break;

    case GET_MY_REWARD_WIDGET_INFO_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getMyRewardWidgetInfoReducer;
