import { bool, object } from 'prop-types';
import React, { useMemo } from 'react';
import Select from 'react-select';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import useEditAppointmentFormContext from '../useEditAppointmentFormContext';

const SelectProductCP = ({ layoutData, pastAppointmentEdit }) => {
  const { formik } = useEditAppointmentFormContext();

  const productOptions = useMemo(() => {
    if (layoutData?.products) {
      const productArr = layoutData?.products?.map((product) => ({
        value: product.Id,
        label: product.Name
      }));

      return productArr;
    }
  }, [layoutData?.products]);

  const handleSetProductSelected = () => {
    return productOptions.find((item) => item.value === formik.values.productId.value);
  };

  return productOptions.length ? (
    <dl className='appointments-infor__item'>
      <Text field={layoutData?.['Select Product Label']} tag='dt' className='appointments-infor__item__label w-65' />
      <Select
        defaultValue={handleSetProductSelected()}
        isSearchable={false}
        options={productOptions}
        className='customization-dropdown'
        onChange={(option) => formik.setFieldValue('productId.value', option.value)}
        classNamePrefix='customization-dropdown'
        isDisabled={pastAppointmentEdit}
      />
      {formik.errors.productId && formik.touched.productId && <span className='error-validate'>{formik.errors.productId?.value}</span>}
    </dl>
  ) : (
    <></>
  );
};

SelectProductCP.propTypes = {
  layoutData: object,
  pastAppointmentEdit: bool
};

export default SelectProductCP;
