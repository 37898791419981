import { any } from 'prop-types';
import React from 'react';

import AddOnDealProductItem from './AddOnDealProductItem';

const AddonDealProductList = ({ addOnDealsData }) => {
  return (
    <div className='variant-buying-list add-on-deal-list'>
      <div className='row'>
        {addOnDealsData.map((product) => (
          <AddOnDealProductItem key={product.ProductName} product={product} />
        ))}
      </div>
    </div>
  );
};

AddonDealProductList.propTypes = {
  addOnDealsData: any
};

export default AddonDealProductList;
