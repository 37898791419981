import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import AddToSalesOrderPopup from '@components/BuyingOptions/BOPaymentInfo/AddToSalesOrderPopup';
import { behaviorInstallment } from '@components/ChosenBundle/InstallmentBehavior';
import { getCartNameValue } from '@redux/epic/product/cartDetail/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { BOPrice } from '../../BuyingOptions/BOPaymentInfo/BOPrice';
import BundleBOShippingInfo from '../BundleBOShippingInfo';

const BundleBOPaymentInfo = (props) => {
  const {
    dataTextFields,
    totalSellingPriceText,
    totalListPriceText,
    deliveryOptions,
    bundleDeliveryStatus,
    isDynamic,
    totalSellingPrice,
    paymentGateways,
    onAddToCartHandler
  } = props;
  const [installment, setInstallment] = useState(null);
  const [installmentCartLevel, setInstallmentCartLevel] = useState(null);
  const [installmentProductLevel, setInstallmentProductLevel] = useState(null);
  const [installmentCartLevelAndProductLevel, setInstallmentCartLevelAndProductLevel] = useState(null);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId');
  const [isShow, setShow] = useState(false);
  const [currentCartName, setCurrentCartName] = useState('');

  useEffect(() => {
    const cartName = getCartNameValue();

    setCurrentCartName(cartName);
  }, []);

  useEffect(() => {
    const behaviorInstallmentSubscription = behaviorInstallment.subscribe((params) => {
      setInstallment(params.installment);
    });

    return () => {
      behaviorInstallmentSubscription.unsubscribe();
    };
  }, [behaviorInstallment]);

  useDidUpdateEffect(() => {
    if (currentCart && installment) {
      setInstallmentCartLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
        currentCart?.PaymentConfiguration?.Gateways.filter(
          (gateway) => gateway?.SupportInstallment && gateway?.InstallmentCartLevel && !gateway?.InstallmentProductLevel && gateway?.InstallmentPeriods.length
        )
      );

      setInstallmentProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
        currentCart?.PaymentConfiguration?.Gateways.filter(
          (gateway) => gateway?.SupportInstallment && gateway?.InstallmentProductLevel && !gateway?.InstallmentCartLevel && installment?.length
        )
      );

      setInstallmentCartLevelAndProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
        currentCart?.PaymentConfiguration?.Gateways.filter(
          (gateway) => gateway?.SupportInstallment && gateway?.InstallmentProductLevel && gateway?.InstallmentCartLevel && installment?.length
        )
      );
    }
  }, [currentCart, installment]);

  const onShowHandler = () => {
    setShow(true);
  };

  const onCloseHandler = () => {
    setShow(false);
  };

  return (
    dataTextFields && (
      <Fragment>
        <div className='bo-payment-info'>
          <div className='bo-payment-info__wrap'>
            <div className='bo-payment-info__price'>
              <BOPrice totalSellingPriceText={totalSellingPriceText} totalListPriceText={totalListPriceText} />
            </div>
            {paymentGateways?.Gateways?.map((gateway) => {
              return (
                <>
                  {(!gateway?.InstallmentMaxCartAmount && totalSellingPrice >= gateway?.InstallmentMinCartAmount) || (gateway?.InstallmentMaxCartAmount && totalSellingPrice >= gateway?.InstallmentMinCartAmount && totalSellingPrice < gateway?.InstallmentMaxCartAmount) ? (
                    isDynamic ? (
                      <span className='product-variant-info__content__installment' key={gateway?.Id}>
                        {gateway?.InstallmentDynamicBundleLevelText.replace(
                          '{number_of_payments}',
                          gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period).replace(
                          '${min}',
                          Math.round(
                            (totalSellingPrice / gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) * 1000
                          ) / 1000
                        ).replace('{logo}', '')}
                        {gateway?.InstallmentDynamicBundleLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                          <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                        ) : (
                          <></>
                        )}
                      </span>
                    ) : (
                      <span className='product-variant-info__content__installment' key={gateway?.Id}>
                        {gateway?.InstallmentProductLevelText.replace(
                          '{number_of_payments}',
                          // Global.onGetMinInstallmentPeriodsInPDPAndBO(productDetailOverview?.InstallmentPeriods, gateway?.InstallmentPeriods)?.Period
                          gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period
                        )
                          .replace(
                            '${installment_amount}',
                            Math.round(
                              (totalSellingPrice /
                                // Global.onGetMinInstallmentPeriodsInPDPAndBO(productDetailOverview?.InstallmentPeriods, gateway?.InstallmentPeriods)?.Period) *
                                gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) *
                              1000
                            ) / 1000
                          )
                          .replace('{logo}', '')}
                        {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                          <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                        ) : (
                          <></>
                        )}
                      </span>
                    )
                  ) : (
                    <></>
                  )}
                  {totalSellingPrice < gateway?.InstallmentMinCartAmount ? (
                    <span className='product-variant-info__content__installment' key={gateway?.Id}>
                      {gateway?.InstallmentCartLevelText.replace('${amount}', gateway?.InstallmentMinCartAmount - totalSellingPrice).replace(
                        '{logo}',
                        ''
                      )}
                      {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                        <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                      ) : (
                        <></>
                      )}
                    </span>
                  ) : (
                    <></>
                  )}
                </>
              );
            })}
            {/*{installmentProductLevel?.map((gateway) => {*/}
            {/*  return (*/}
            {/*    <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
            {/*      {gateway?.InstallmentProductLevelText.replace(*/}
            {/*        '{number_of_payments}',*/}
            {/*        installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]*/}
            {/*      )*/}
            {/*        .replace(*/}
            {/*          '${installment_amount}',*/}
            {/*          Math.round((totalSellingPrice / installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) * 1000) / 1000*/}
            {/*        )*/}
            {/*        .replace('{logo}', '')}*/}
            {/*      {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
            {/*        <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
            {/*      ) : (*/}
            {/*        <></>*/}
            {/*      )}*/}
            {/*    </span>*/}
            {/*  );*/}
            {/*})}*/}
            {/*{installmentCartLevelAndProductLevel?.map((gateway) => {*/}
            {/*  return (*/}
            {/*    <>*/}
            {/*      {(!gateway?.InstallmentMaxCartAmount && totalSellingPrice >= gateway?.InstallmentMinCartAmount) || (gateway?.InstallmentMaxCartAmount && totalSellingPrice >= gateway?.InstallmentMinCartAmount && totalSellingPrice < gateway?.InstallmentMaxCartAmount) ? (*/}
            {/*        isDynamic ? (*/}
            {/*          <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
            {/*            {gateway?.InstallmentDynamicBundleLevelText.replace(*/}
            {/*              '{number_of_payments}',*/}
            {/*              gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period).replace(*/}
            {/*              '${min}',*/}
            {/*              Math.round(*/}
            {/*                (totalSellingPrice / gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) * 1000*/}
            {/*              ) / 1000*/}
            {/*            ).replace('{logo}', '')}*/}
            {/*            {gateway?.InstallmentDynamicBundleLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
            {/*              <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
            {/*            ) : (*/}
            {/*              <></>*/}
            {/*            )}*/}
            {/*          </span>*/}
            {/*        ) : (*/}
            {/*          <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
            {/*            {gateway?.InstallmentProductLevelText.replace(*/}
            {/*              '{number_of_payments}',*/}
            {/*              installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]*/}
            {/*            )*/}
            {/*              .replace(*/}
            {/*                '${installment_amount}',*/}
            {/*                Math.round((totalSellingPrice / installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) * 1000) / 1000*/}
            {/*              )*/}
            {/*              .replace('{logo}', '')}*/}
            {/*            {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
            {/*              <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
            {/*            ) : (*/}
            {/*              <></>*/}
            {/*            )}*/}
            {/*          </span>*/}
            {/*        )*/}
            {/*      ) : (*/}
            {/*        <></>*/}
            {/*      )}*/}
            {/*      <>*/}
            {/*        {totalSellingPrice < gateway?.InstallmentMinCartAmount ? (*/}
            {/*          <span className='product-variant-info__content__installment' key={gateway?.Id}>*/}
            {/*            {gateway?.InstallmentCartLevelText.replace('${amount}', gateway?.InstallmentMinCartAmount - totalSellingPrice).replace(*/}
            {/*              '{logo}',*/}
            {/*              ''*/}
            {/*            )}*/}
            {/*            {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (*/}
            {/*              <img className='installment-img' src={gateway?.PdpLogoItemUrl} />*/}
            {/*            ) : (*/}
            {/*              <></>*/}
            {/*            )}*/}
            {/*          </span>*/}
            {/*        ) : (*/}
            {/*          <></>*/}
            {/*        )}*/}
            {/*      </>*/}
            {/*    </>*/}
            {/*  );*/}
            {/*})}*/}
            {isClientTelingUser ? (
              <div className='bo-payment-info__btn'>
                <div className='bo-payment-info__btn__item'>
                  <button onClick={cpCustomerId || currentCartName ? onAddToCartHandler : onShowHandler} className='btn btn-outline-primary item-btn'>
                    <Text field={dataTextFields['Add To Sales Order Text']} />
                  </button>
                  {/*<Link*/}
                  {/*  field={{ href: `${dataFields['Purchase On Spot Link']?.value?.href}?transactionAmount=${totalSellingPriceText}` }}*/}
                  {/*  className='btn btn-primary item-btn'*/}
                  {/*>*/}
                  {/*  <Text field={dataFields['Purchase On Spot Text']} />*/}
                  {/*</Link>*/}
                </div>
                <div className='bo-payment-info__btn__link'>
                  <Link field={dataTextFields['CTA Button']} className='btn-link'>
                    <Text field={dataTextFields['CTA Button Label']} />
                  </Link>
                </div>
                <AddToSalesOrderPopup isShow={isShow} onCloseHandler={onCloseHandler} ondataSources={dataTextFields} />
              </div>
            ) : (
              <div className='bo-payment-info__fulfillment'>
                <h3 className='bo-payment-info__fulfillment__title bo-title-section'>{dataTextFields['Fulfillment Details Label'].value}</h3>
                <BundleBOShippingInfo
                  dataTextFields={dataTextFields}
                  deliveryStatus={bundleDeliveryStatus['Delivery Status'].value}
                  storePickUpLabel={dataTextFields['Store Pick Up Label'].value}
                  deliveryOptions={deliveryOptions}
                />
              </div>
            )}
          </div>
        </div>
      </Fragment>
    )
  );
};

BundleBOPaymentInfo.propTypes = {
  dataTextFields: Proptypes.any,
  children: Proptypes.any,
  variantActive: Proptypes.any,
  totalSellingPriceText: Proptypes.any,
  totalListPriceText: Proptypes.any,
  deliveryOptions: Proptypes.array,
  bundleDeliveryStatus: Proptypes.any,
  isDynamic: Proptypes.bool,
  totalSellingPrice: Proptypes.number,
  paymentGateways: Proptypes.object,
  onAddToCartHandler: Proptypes.func
};

export default BundleBOPaymentInfo;
