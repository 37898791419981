import produce from 'immer';

import {
  CANCEL_EVENT_BOOKING_FAILED,
  CANCEL_EVENT_BOOKING_START,
  CANCEL_EVENT_BOOKING_SUCCESS
} from '@redux/actions/eventBooking/cancelEventBooking/cancelEventBookingTypes';

const initialState = {
  isLoading: false,
  cancelResponse: null,
  error: null
};

const cancelEventBookingReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case CANCEL_EVENT_BOOKING_START:
      draft.isLoading = true;
      break;

    case CANCEL_EVENT_BOOKING_SUCCESS:
      draft.isLoading = false;

      draft.cancelResponse = action.payload;
      break;

    case CANCEL_EVENT_BOOKING_FAILED:
      draft.isLoading = false;

      draft.error = `${action.payload}`;
      break;
    }

    return draft;
  });

export default cancelEventBookingReducer;
