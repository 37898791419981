import './WellnessContents.scss';

import React from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

const WellnessContents = (props) => {
  const mainTitle = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Well-Being Title']?.value || 'BE Missing Text Field';
  
  return (
    <div className='wellness-contents'>
      <h2 className='wellness-contents__title text-center'>{mainTitle}</h2>
    </div>
  );
};

export default WellnessContents;
