import produce from 'immer';

import { EDIT_SO_PRODUCT_CART_CONTROLS } from '@redux/actions/cpCartDetails/types';

const initialStates = {
  isEditingSOCart: false
};

const cpCartDetailsReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case EDIT_SO_PRODUCT_CART_CONTROLS:
      draft.isEditingSOCart = action.payload.isEdit;
      break;
    }

    return draft;
  });

export default cpCartDetailsReducer;
