import * as propType from 'prop-types';
import React, { useMemo, useState } from 'react';

import {
  SCHEDULE_APPOINTMENT_PARAM_NAME,
  SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE
} from '@components/BookingExperience/ScheduleAppointmentComponent/FormikStep/configs';
import ResponsiveImage from '@components/ResponsiveImage';
import ModalComponent from '@components/Share/ModalComponent';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import CancelAppointmentModal from '../CancelAppointmentModal';

const AppointmentBanner = ({ layoutData, isCancelSuccess, timeSlotBooked, numberOfGuestValue, isBookExperience }) => {
  const [isShowModal, setIsShowModal] = useState(false);

  useDidUpdateEffect(() => {
    isCancelSuccess && setIsShowModal(false);
  }, [isCancelSuccess]);
  const handleOpenModal = () => setIsShowModal(true);

  const handleCloseModal = () => setIsShowModal(!isShowModal);

  const changeScheduleBtnLink = useMemo(() => {
    const appointmentId = layoutData?.appointment?.Id;
    const campaignId = layoutData?.campaign?.Id;
    if (!isBookExperience) {
      return layoutData?.['Button Change Schedule Link']?.value?.href
        ? {
          value: {
            ...layoutData['Button Change Schedule Link'].value,
            href: `${layoutData?.['Button Change Schedule Link']?.value?.href}?campaign_id=${campaignId}&appointment_id=${appointmentId}`
          }
        }
        : '';
    } else {
      return layoutData?.['Change Schedule Book Experience Link']?.value?.href
        ? {
          value: {
            ...layoutData['Change Schedule Book Experience Link'].value,
            href: `${layoutData?.['Change Schedule Book Experience Link']?.value?.href}?${SCHEDULE_APPOINTMENT_PARAM_NAME}=${SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE}&appointment_id=${appointmentId}`
          }
        }
        : '';
    }
  }, [layoutData, isBookExperience]);

  const campaignUid = useMemo(() => {
    return layoutData?.campaign && layoutData?.campaign?.['Uid'] ? layoutData?.campaign?.['Uid'] : '';
  }, [layoutData]);

  const productName = useMemo(() => {
    return layoutData?.appointment?.IsBookExperience ? layoutData?.appointment?.ProductName || layoutData?.appointment?.Product?.Name : '';
  }, [layoutData]);

  return (
    <div className='appointment-detail__header'>
      <div className='appointment-detail__header__background'>
        <ResponsiveImage
          imageMobile={layoutData?.['BackgroundMobile']}
          srcSet={[ { mw: 768, image: layoutData?.['BackgroundDesktop'] } ]}
        />
      </div>
      <div className='appointment-detail__header__content'>
        <div className='container appointment-detail__header__content__container'>
          <div className='appointment-detail__header__content__wrap'>
            <Image field={layoutData?.['Booked Icon']} className='appointment-detail__header__content__wrap__icon' />
            <span className='appointment-detail__header__content__wrap__code'>{`#${layoutData?.appointment?.['BookingID']}`}</span>
            {campaignUid ? <span className='appointment-detail__header__content__wrap__campaign-uid'>{campaignUid}</span> : <></>}
            {productName ? <span className='appointment-detail__header__content__wrap__campaign-uid'>{productName}</span> : <></>}
            <div className='appointment-detail__header__content__wrap__time'>
              <Text field={{ value: Global.customDate(layoutData?.appointment?.Date) }} tag='span' />
              {isBookExperience ? (
                <>
                  <span>{timeSlotBooked?.Name}</span>
                  <span>{numberOfGuestValue}</span>
                </>
              ) : (
                <></>
              )}
            </div>
            <Text field={{ value: layoutData?.appointment?.Store?.Name }} tag='h3' className='appointment-detail__header__content__wrap__title' />
            <div className='appointment-detail__header__content__wrap__description'>
              <Text field={{ value: layoutData?.appointment?.Store?.Address?.Address }} tag='p' className='item' />
              <Text field={{ value: layoutData?.appointment?.Store?.Address?.PostalCode }} tag='p' className='item' />
              {layoutData?.appointment?.Store?.PhoneNumber ? (
                <Text field={{ value: `+${layoutData?.appointment?.Store?.PhoneNumber}` }} tag='p' className='item' />
              ) : (
                <></>
              )}
            </div>
            {layoutData?.appointment?.Status === 1 ? (
              <Text
                field={{
                  value: layoutData?.['Your Appointment Cancelled Text']?.value.replace('{BookingID}', layoutData?.appointment?.['BookingID'])
                }}
                tag='p'
                className='item'
              />
            ) : (
              <>
                {layoutData?.isUpComing ? (
                  <>
                    <Link field={changeScheduleBtnLink} className='btn btn-outline-white appointment-detail__header__content__wrap__link-change'>
                      {isBookExperience ? (
                        <Text field={layoutData?.['Change Schedule Book Experience Text']} />
                      ) : (
                        <Text field={layoutData?.['Button Change Schedule Text']} />
                      )}
                    </Link>
                    <br />
                    <button onClick={handleOpenModal} className='btn btn-link appointment-detail__header__content__wrap__btn-cancel'>
                      <Text field={layoutData?.['Button Cancel Appointment Text']} />
                    </button>
                  </>
                ) : (
                  <>
                    {isBookExperience ? (
                      layoutData?.['Button Review Book Experience Link']?.value?.href && layoutData?.['Button Review Book Experience Text']?.value ? (
                        <a
                          href={`${layoutData?.['Button Review Book Experience Link']?.value?.href}?appointment_id=${layoutData?.appointment?.Id}`}
                          className={`btn btn-outline-white appointment-detail__header__content__wrap__link-change ${
                            layoutData?.appointment?.IsReviewExperience ? 'disable' : ''
                          }`}
                        >
                          <Text field={layoutData?.['Button Review Book Experience Text']} />
                        </a>
                      ) : (
                        <></>
                      )
                    ) : layoutData?.['Button Review Experience Link']?.value?.href && layoutData?.['Button Review Experience Text']?.value ? (
                      <a
                        href={`${layoutData?.['Button Review Experience Link']?.value?.href}?appointment_id=${layoutData?.appointment?.Id}`}
                        className={`btn btn-outline-white appointment-detail__header__content__wrap__link-change ${
                          layoutData?.appointment?.IsReviewExperience ? 'disable' : ''
                        }`}
                      >
                        <Text field={layoutData?.['Button Review Experience Text']} />
                      </a>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
            <ModalComponent isShow={isShowModal} onCloseHandler={handleCloseModal} titlePopup={layoutData?.['Title Cancel Appointment Modal']?.value}>
              <div className='appointment-detail__header__content__wrap__cancel-modal'>
                <CancelAppointmentModal layoutData={layoutData} onCloseModal={handleCloseModal} />
              </div>
            </ModalComponent>
          </div>
        </div>
      </div>
    </div>
  );
};

AppointmentBanner.propTypes = {
  layoutData: propType.object,
  isCancelSuccess: propType.bool,
  timeSlotBooked: propType.object,
  numberOfGuestValue: propType.string,
  isBookExperience: propType.bool
};

export default AppointmentBanner;
