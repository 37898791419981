import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'Subscribe Button Label',
  'No Thanks Button Label',
  'Title',
  'Description',
  'Email Placeholder Text',
  'Thank You Message',
  'Empty Email Validation Message'
];

export default class NewsletterModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Contact List'] = null;

    this['IsSubscribed'] = false;
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Contact List')) {
      this['Contact List'] = deepCopy(dataSource['Contact List']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsSubscribed')) {
      this['IsSubscribed'] = deepCopy(dataSource['IsSubscribed']);
    }

    return this;
  }
}
