import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useCheckDeliveryAddressValid } from '@components/FulfillmentOptions/hooks';
import PaymentMethod from '@components/SlicingPage/PaymentMethod';

import PaymentMethodModel from './model/PaymentMethodModel';

const CheckoutPayment = ({ fields }) => {
  const checkDeliveryAddressValid = useCheckDeliveryAddressValid();
  const depositListProduct = useSelector((state)=> state?.myOrderReducer?.balanceByIdReducer?.currentCartCustom?.DepositListProduct);
  let [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    let paymentMethodModel = new PaymentMethodModel();

    setLayoutData(paymentMethodModel.getData(fields));
  }, []);

  //   Check conditions
  // - If the product is normal, it will go to the checkout page
  // - If the product is deposited, it will be transferred to the PayPalance page
  useEffect(() => {
    if (layoutData && checkDeliveryAddressValid && !checkDeliveryAddressValid.isValid && depositListProduct?.length < 1) {
      window.location.href = layoutData?.['Back To' + ' Fulfilment Link']?.value?.href;
    }
  }, [layoutData, checkDeliveryAddressValid, depositListProduct]);

  return layoutData ? (
    <Fragment>
      <PaymentMethod layoutData={layoutData} />
    </Fragment>
  ) : (
    ''
  );
};

CheckoutPayment.propTypes = {
  fields: PropTypes.any
};

export default CheckoutPayment;
