export default class CountdownFormatSettingModel {
  constructor() {
    this['CountdownFormat'] = '{day}D {hour}H {minute}M {second}S';

    this['DateFormat'] = '{Day} {Month} {Year}';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CountdownFormat')) {
      this['CountdownFormat'] = dataSource['CountdownFormat'] ?? this['CountdownFormat'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DateFormat')) {
      this['DateFormat'] = dataSource['DateFormat'].length ? dataSource['DateFormat'] : this['DateFormat'];
    }

    return this;
  }
}
