import PropTypes from 'prop-types';
import React from 'react';

import { MassageAreaDefaultOfQuestion2 } from '@components/HelpMeDecide/config';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

export const QuestionItemStep2 = ({ answer, chooseAnswer, active, step }) => {
  const { ID, Name, MassageArea, AvailableNextAnswers } = answer;

  const checkMesagaArea = () => {

    if ((MassageArea && MassageAreaDefaultOfQuestion2.find((item) => item.toLocaleLowerCase() === MassageArea.toLocaleLowerCase())) || ID === 'full-body') {
      return true;
    }
    else {
      return false;
    }
  };

  const convertClassName = (name) => {
    if (ID === 'full-body') {
      return 'full-body';
    } else {
      return name?.toLowerCase().replaceAll(' ', '-');
    }
  };

  return Name && checkMesagaArea() && (
    <div className={`question2-wrapper__item question2-wrapper__item--${convertClassName(MassageArea)} ${active ? 'question2-wrapper__item--active' : ''}`} onClick={() => { chooseAnswer(step, ID, AvailableNextAnswers); }}>
      <div className='question2-wrapper__item__position'>
        <RichText className='question2-wrapper__item__text' field={{ value: Name }}></RichText>
      </div>
    </div>
  );
};

QuestionItemStep2.propTypes = {
  answer: PropTypes.object,
  chooseAnswer: PropTypes.func,
  active: PropTypes.bool,
  step: PropTypes.number
};

