import './DownloadOsimApp.scss';

import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import DownloadOsimAppModel from './models/DownloadOsimAppModel';

const DownloadOsimApp = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  const isDisableDownloadOsimApp = useMemo(() => {
    return layoutData?.Disabled;
  }, [layoutData]);

  useEffect(() => {
    setLayoutData(new DownloadOsimAppModel().getData(fields));
  }, []);

  return layoutData && !isDisableDownloadOsimApp && layoutData?.ProductImage ? (
    <div className='container'>
      <div className='support-centre-box no-border-bottom-mobile'>
        <div className='support-centre-box__wrapper download-osim-app'>
          <div className='download-osim-app__image'>
            <div className='download-osim-app__image-wrapper'>
              <img src={layoutData.ProductImage} alt='Product Image' />
            </div>
          </div>
          <div className='download-osim-app__content'>
            <Text tag='h3' field={layoutData?.Title} className='download-osim-app__content-title' />
            <RichText tag='h5' field={layoutData?.Description} className='download-osim-app__content-subtitle' />
            <div className='download-osim-app__content-qr-code'>
              <div className='download-osim-app__content-qr-code__item'>
                {layoutData?.IOSQRImage ? (
                  <div className='qr-code-image'>
                    <div className='qr-code-image__wrapper'>
                      <img src={layoutData.IOSQRImage} alt='IOS QR Image' />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className='qr-code-cta'>
                  <a href={layoutData?.IOSButtonLink} className='btn btn-outline-primary' target='_blank' rel='noreferrer'>
                    <Text field={layoutData?.IOSButtonText} />
                  </a>
                </div>
              </div>
              <div className='download-osim-app__content-qr-code__item'>
                {layoutData?.AndroidQRImage ? (
                  <div className='qr-code-image'>
                    <div className='qr-code-image__wrapper'>
                      <img src={layoutData.AndroidQRImage} alt='Android QR Image' />
                    </div>
                  </div>
                ) : (
                  <></>
                )}
                <div className='qr-code-cta'>
                  <a href={layoutData?.AndroidButtonLink} className='btn btn-outline-primary' target='_blank' rel='noreferrer'>
                    <Text field={layoutData?.AndroidButtonText} />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

DownloadOsimApp.propTypes = {
  fields: object
};

export default DownloadOsimApp;
