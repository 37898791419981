import CartStripAdsApiItemModel from './CartStripAdsApiItemModel';

export default class CartStripAdsApiModel {
  constructor() {
    this.Data = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Data')) {
      if(dataSource.Data?.length) {
        dataSource.Data.map(item => {
          const stripAdsItem = new CartStripAdsApiItemModel().getData(item || {});

          this.Data.push(stripAdsItem);
        });
      }
    }

    return this;
  }
}
