export const MassageAreaDefaultOfQuestion2 = [
  'Upper Head',
  'Lower Head',
  'Hair - Scalp',
  'Eyes',
  'Face',
  'Neck',
  'Shoulders',
  'Upper Arm',
  'Lower Arm',
  'Hand',
  'Upper Back',
  'Lower Back - Lumbar',
  'Waist',
  'Hips',
  'Thighs',
  'Butt',
  'Calves',
  'Feet',
];