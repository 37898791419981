export const ACTION_CP_STORE_SELECTOR = '/CPStore/updateCurrentStore';

export const ACTION_CP_START_DEMO = '/clientteling/start-session';

export const ACTION_STAFF_LEAD_MANAGEMENT = '/clientteling/get-staff-lead-management';

export const ACTION_MANAGER_LEAD_MANAGEMENT = '/clientteling/get-manager-lead-management';

export const ACTION_GET_SALE_STAFFS = '/clientteling/get-sale-staffs';

export const ACTION_GET_APPOINTMENT_LIST = '/clientteling/get-appointment-listing';

export const ACTION_CP_UPDATE_APPOINTMENT = '/clientteling/Update-Appointment-Detail';

export const ACTION_CP_GET_APPOINTMENT_DETAIL = '/clientteling/get-appointment-Detail';

export const ACTION_CP_GET_STORE_LIST = '/StoreLocator/SearchStores';

export const ACTION_CP_SHARE_PRODUCT_DETAIL = '/ClientTeling/share-product-detail';

export const ACTION_CP_SEND_SALE_ORDER = '/ClientTeling/send-sale-order';