import './CompareProduct.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingMask from '@components/Share/LoadingMask';
import { deepCopy } from '@utils/utility';

import CompareProductLayout from './CompareProductLayout';
import { CompareProductsContext } from './hooks/useCompareProduct';
import CompareProductsModel from './Model/CompareProductsModel';

const CompareProducts = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [categorySelected, setCategorySelected] = useState(null);
  const [infoSellingPriceLabelHeight, setInfoSellingPriceLabelHeight] = useState([]);
  const [productNameLabelHeight, setProductNameLabelHeight] = useState([]);
  const [productVariantItemHeight, setProductVariantItemHeight] = useState([]);
  const [productTitleItemHeight, setProductTitleItemHeight] = useState([]);

  useEffect(() => {
    const model = new CompareProductsModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  return layoutData ? (
    <CompareProductsContext.Provider
      value={{
        layoutData,
        categorySelected,
        setCategorySelected,
        infoSellingPriceLabelHeight,
        setInfoSellingPriceLabelHeight,
        productNameLabelHeight,
        setProductNameLabelHeight,
        productVariantItemHeight,
        setProductVariantItemHeight,
        productTitleItemHeight,
        setProductTitleItemHeight
      }}
    >
      <CompareProductLayout />
    </CompareProductsContext.Provider>
  ) : (
    <LoadingMask />
  );
};

CompareProducts.propTypes = {
  fields: PropTypes.object
};

export default CompareProducts;
