import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Enable Tax Message', 'Tax Message Label'];

export default class TaxMessageDetailModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((text) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text] = dataSource[text] ?? '';
      }
    });

    return this;
  }
}
