import parse from 'html-react-parser';
import React, { useLayoutEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useCampaignContext } from '../../useCampaignContext';

export const FROM_CAMPAIGN_PAGE_QUERY_STRING = '?from=';

const UpdatePhoneNumberText = () => {
  const { layoutData } = useCampaignContext();
  const aTagRef = useRef(null);
  const { pathname } = useLocation();

  const updatePhoneNumberText = useMemo(() => {
    return layoutData?.updateMobileNumberMessage?.value;
  }, [layoutData]);

  const [text, aTag] = useMemo(() => {
    return parse(updatePhoneNumberText);
  }, [updatePhoneNumberText]);

  useLayoutEffect(() => {
    if (aTagRef?.current) {
      const aTagCurrent = aTagRef?.current;
      const aTagHrefValue = aTagCurrent.getAttribute('href');

      aTagCurrent.setAttribute('href', aTagHrefValue + FROM_CAMPAIGN_PAGE_QUERY_STRING + pathname);
    }
  }, [aTag, aTagRef, pathname]);

  return (
    <p className='change-phone-number-link'>
      {text} {React.cloneElement(aTag, { ref: aTagRef })}
    </p>
  );
};

export default UpdatePhoneNumberText;
