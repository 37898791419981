import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import SearchSettingModel from './SearchSettingModel';

const sitecoreTextModelArr = [
  'Buy Now Text',
  'Clear Filter Text',
  'Experience In Store Text',
  'Filter Text',
  'Filter Title',
  'Sort By Label',
  'Colors Label',
  'Price Range Label',
  'Features Label',
  'Compare Text',
  'Apply Filter Text',
  'Deposit Label',
  'Refundable',
  'Rental Price Label',
  'Learn More Text',
  'Sort By PIV',
  'Deposit Label',
  'Login Messages',
  'Login Button Text',
  'Deposit Product Tag Label',
  'Experience In Store Text',
  'CP Buy Options Text'
];

const sitecoreLinkModelArr = [
  'Experience In Store Link',
  'Compare Link',
  'Buy Now Link',
  'Deposit Link',
  'Login Button Link',
  'Experience In Store Link',
  'CP Buy Options Link'
];

export default class ProductListingModel {
  constructor() {
    sitecoreTextModelArr.map((item) => {
      this[item] = new SitecoreTextModel();
    });

    sitecoreLinkModelArr.map((link) => {
      this[link] = new SitecoreLinkModel();
    });

    this['Search Settings'] = new SearchSettingModel();

    this['Compare Icon'] = new SitecoreImageModel();

    this['Filter Icon'] = new SitecoreImageModel();
  }
  getData(dataSource) {
    sitecoreTextModelArr.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    sitecoreLinkModelArr.map((link) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link].getData(dataSource[link]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Search Settings')) {
      this['Search Settings'].getData(dataSource['Search Settings'].fields);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Compare Icon')) {
      this['Compare Icon'].getData(dataSource['Compare Icon']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter Icon')) {
      this['Filter Icon'].getData(dataSource['Filter Icon']);
    }

    return this;
  }
}
