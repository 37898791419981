import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import { deepCopy } from '../../../utils/utility';
import AdditionalOptionsSettingModel from './AdditionalOptionsSettingModel';

const listSitecoreText = [
  'Error Modal Title',
  'Add To Cart Text',
  'Add Trade In Text',
  'Confirm Modal Text',
  'CTA Button Label',
  'Change Text',
  'Choose Your Color Label',
  'Delivery Label',
  'Edit Cart Line Text',
  'Free Gifts Label',
  'Fulfillment Details Label',
  'Gift Header Label',
  'In Stock Label',
  'In Stock With BackOrder Label',
  'In Stock With PreOrder Label',
  'Out Stock Label',
  'Shipping Message',
  'Store Availability Message',
  'Store Pick Up Label',
  'Trade In Instruction',
  'Trade In Label',
  'Warranty Label',
  'Error Message',
  'Please Select Your Color Message',
  'Please Select Your Free Gift Message',
  'Please Select Your Warranty Message',
  'SF Express Station Label',
  'Available Label',
  'SF Express Popup Title',
  'SF Express Popup Description',
  'SF Express Area Label',
  'SF Express District Label',
  'SF Express NetWork Label',
  'Back To Product Detail Label',
  'Term And Condition',
  'Error Message Term And Condition',
  'Refunable Deposit Label',
  'Trade In Description Text',
  'Trade In Popup Title',
  'Provide Details Text',
  'Add Trade In Button',
  'Add Trade In Popup Button',
  'Select Trade In Product Title',
  'Upload Image Of Trade In Product Title',
  'Provide Information On The Trade In Product Title',
  'File Size Requirement Text',
  'Agree with Term Condition',
  'Select Product Label',
  'Upload Image Label',
  'Provide Info Label',
  'Change Image Label',
  'Trade-in Value Label',
  'File Size Exeed Maximum Limit Label',
  'Maximum Allow File Size Label',
  'Trade In Buying Option Post Label',
  'File Type Is Not Correct Label',
  'Remove Trade In Option Label',
  'Learn More Label',
  'Term And Condition Label',
  'Disposal Policy Label',
  'Disposal Policy Page',
  'Loyalty Trade-in Value Label',
  'Pay Deposit Label',
  'Balance Payment Label',
  'Payment Period Label',
  'Payment Period Details',
  'Ship Out Label',
  'Ship Out Details',
  'Terms And Conditions Label',
  'Terms And Conditions Details',
  'Pay Deposit End At Label',
  'Add To Sales Order Text',
  'Purchase On Spot Text',
  'Start Session Prompt Text',
  'Start Session Text',
  'Back Text',
  'Add On Deal Label',
  'Add Label',
  'Remove Label',
  'Deposit Label',
  'Balance Gift Label',
  'Deposit Gift Label',
  'Login Button Text',
  'Brand Label',
  'Model Label',
  'Product Name Label',
  'Require Upload File Text',
  'Max Upload File Text',
  'To Text'
];

const listSitecoreLink = ['Purchase On Spot Link', 'Login Button Link'];

export default class BuyingOptionsFieldsModel {
  constructor() {
    listSitecoreText.map((item) => {
      this[item] = new SitecoreTextModel();
    });

    listSitecoreLink.map((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Add To Cart Success Page'] = new SitecoreLinkModel();

    this['Add To Cart Fail Page'] = new SitecoreLinkModel();

    this['CTA Button'] = new SitecoreLinkModel();

    // this['Disposal Policy Page'] = new SitecoreLinkModel();

    this['Additional Fulfillment Info'] = {};

    this['Additional Options Setting'] = new AdditionalOptionsSettingModel();

    this['Term And Condition'] = new SitecoreTextModel();

    this['Error Message Term And Condition'] = new SitecoreTextModel();

    this['Refunable Deposit Label'] = new SitecoreTextModel();

    this['Term Condition Link'] = new SitecoreLinkModel();

    this['Learn More Link'] = new SitecoreLinkModel();

    this['Payment Gateways'] = [];
  }

  getData(dataSource) {
    listSitecoreText.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    listSitecoreLink.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Fulfillment Info')) {
      this['Additional Fulfillment Info'] = dataSource['Additional Fulfillment Info']?.fields;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add To Cart Success Page')) {
      this['Add To Cart Success Page'].getData(dataSource['Add To Cart Success Page']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Add To Cart Fail Page')) {
      this['Add To Cart Fail Page'].getData(dataSource['Add To Cart Fail Page']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Disposal Policy page')) {
      this['Disposal Policy page'].getData(dataSource['Disposal Policy page']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Options Setting')) {
      this['Additional Options Setting'].getData(dataSource['Additional Options Setting']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Term Condition Link')) {
      this['Term Condition Link'].getData(dataSource['Term Condition Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Link')) {
      this['Learn More Link'].getData(dataSource['Learn More Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Payment Gateways')) {
      this['Payment Gateways'] = deepCopy(dataSource['Payment Gateways']);
    }

    return this;
  }
}
