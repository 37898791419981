import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class DemoSessionModel {
  constructor() {
    this['End Demo Label'] = new SitecoreTextModel();

    this['Start Demo Label'] = new SitecoreTextModel();

    this['Start Demo Link'] = new SitecoreLinkModel();

    this['End Demo Link'] = new SitecoreLinkModel();

    this['Title'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['Button Text'] = new SitecoreTextModel();

    this['Start Demo With Customer Profile Text'] = new SitecoreTextModel();

    this['Yes Button Label'] = new SitecoreTextModel();

    this['No Button Label'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'End Demo Label')) {
      this['End Demo Label'].getData(dataSource['End Demo Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Start Demo Label')) {
      this['Start Demo Label'].getData(dataSource['Start Demo Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Start Demo Link')) {
      this['Start Demo Link'].getData(dataSource['Start Demo Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'End Demo Link')) {
      this['End Demo Link'].getData(dataSource['End Demo Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Text')) {
      this['Button Text'].getData(dataSource['Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Start Demo With Customer Profile Text')) {
      this['Start Demo With Customer Profile Text'].getData(dataSource['Start Demo With Customer Profile Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Yes Button Label')) {
      this['Yes Button Label'].getData(dataSource['Yes Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'No Button Label')) {
      this['No Button Label'].getData(dataSource['No Button Label']);
    }

    return this;
  }
}
