import { GET_HAD_NOTIFICATION_FAILURE, GET_HAD_NOTIFICATION_START, GET_HAD_NOTIFICATION_SUCCESS } from './types';

export const getHadNotificationsStart = (payload) => ({
  type: GET_HAD_NOTIFICATION_START,
  payload
});

export const getHadNotificationsSuccess = (payload) => ({
  type: GET_HAD_NOTIFICATION_SUCCESS,
  payload
});

export const getHadNotificationsFailure = (payload) => ({
  type: GET_HAD_NOTIFICATION_FAILURE,
  payload
});
