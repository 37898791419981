import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const SeatMassageUnit = ({ dataSources, isViewTitle }) => {
  return !isViewTitle ? (
    <div className='product-specification__collapse__content'>
      <div className='product-specification__collapse__content__list'>
        <div className='row'>
          {dataSources['Seat Massage Unit']['Rolling Distance Seat Massage Value'] ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Seat Massage Unit']['Rolling Distance Seat Massage Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Seat Massage Unit']['Rolling Distance Seat Massage Value']} />
            </dd>
          </dl> : ''}
        </div>
      </div>
    </div>
  ) : <Text field={dataSources['Seat Massage Unit']['Seat Massage Unit Title Text']}/>;
};

SeatMassageUnit.propTypes = {
  dataSources: PropTypes.object,
  isViewTitle: PropTypes.bool
};

export default SeatMassageUnit;
