import produce from 'immer';

import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';

const initState = {
  isLoading: false,
  isChangePassword: null,
  errorMessage: null
};

const changePasswordReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myAccountTypes.CHANGE_PASSWORD_ACCOUNT:
      draft.isLoading = true;

      draft.isChangePassword = null;

      draft.errorMessage = '';

      break;

    case myAccountTypes.CHANGE_PASSWORD_ACCOUNT_SUCCESS:
      draft.isLoading = false; 

      draft.isChangePassword = true;
      break;

    case myAccountTypes.CHANGE_PASSWORD_ACCOUNT_FAILED:
      draft.isLoading = false;
      
      draft.errorMessage = action.error;
      break;
    }

    return draft;
  });

export default changePasswordReducer;
