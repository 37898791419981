import './ProductImages.scss';

import FsLightbox from 'fslightbox-react';
import Proptypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { INITIAL_INDEX, PRODUCT_DETAIL_DISPLAY_IMAGES } from '@utils/constant';

import ProductImagesItem from './ProductImagesItem';
import ProductImagesMenu from './ProductImagesMenu';

const ProductImages = ({ variantSelectedImages }) => {
  const imageSwiperRef = useRef(null);

  const [toggleGallery, setToggleGallery] = useState(false);

  const [imageIndex, setImageIndex] = useState(INITIAL_INDEX);

  useEffect(() => {
    setImageIndex(INITIAL_INDEX);
  }, [variantSelectedImages]);

  let params = {
    lazy: true,
    slidesPerView: PRODUCT_DETAIL_DISPLAY_IMAGES,
    on: {
      slideChangeTransitionEnd: () => {
        setImageIndex(imageSwiperRef.current.swiper.activeIndex);
      }
    }
  };

  const onHandleClickZoom = () => {
    setToggleGallery(!toggleGallery);
  };

  return variantSelectedImages && variantSelectedImages.length ? (
    <div className='product-image'>
      <div className='product-image__content'>
        <SwipeComponent key={variantSelectedImages} param={params} setRef={imageSwiperRef}>
          {variantSelectedImages.map((item, itemIdx) => {
            return (
              <div key={itemIdx}>
                <ProductImagesItem dataContent={item} />
              </div>
            );
          })}
        </SwipeComponent>
      </div>

      <FsLightbox
        key={variantSelectedImages}
        toggler={toggleGallery}
        slide={imageIndex}
        customSources={variantSelectedImages.map((item, itemIdx) => (
          <div className='light-box-item' key={itemIdx}>
            <ProductImagesItem dataContent={item} />
          </div>
        ))}
      />

      <ProductImagesMenu onHandleClickZoom={onHandleClickZoom} />
    </div>
  ) : (
    ''
  );
};

ProductImages.propTypes = {
  variantSelectedImages: Proptypes.any
};

export default ProductImages;
