import './Coupon.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const Coupon = ({ listPromotions, layoutData }) => {
  const [listCoupon, setListCoupon] = useState([]);

  useEffect(() => {
    if (listPromotions && listPromotions.length > 0) {
      const listCoupon = listPromotions.filter((item) => {
        return item.Code !== '';
      });
  
      setListCoupon(listCoupon);
    }
  }, [listPromotions]);

  return listCoupon && listCoupon.length > 0 &&(
    <div className='coupon-code coupon-code__list-cart-tags'>
      <div className='container coupon-code__container'>
        <div className='row'>
          <div className='col-md-6'></div>
          <div className='col-md-6'>
            <h2 className='coupon-code__title coupon-component__title cart-seller__title'><Text field={layoutData['Coupon Code Label']} /></h2>
            <ul className='coupon-code__list-cart-tags__group'>
              {listCoupon.map(item => {
                return (
                  <li className='coupon-code__list-cart-tags__group__row' key={item.Code}>
                    <div className='coupon-code__list-cart-tags__group__item'>
                      <span>{item.Code}</span>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

Coupon.propTypes = {
  listPromotions: PropTypes.any,
  layoutData: PropTypes.any,
};

const mapStateToProps = (state) => ({
  listPromotions: state.couponReducer.appliedCouponList || {}
});

export default connect(mapStateToProps)(Coupon);
