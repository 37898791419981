import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch } from 'react-redux';

import {resendMailActive} from '@redux/actions/singleSignOn';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';


const RegisterActiveMail = ({dataSources, emailRegister}) => {
  const dispatch = useDispatch();

  return dataSources ? (
    <div className='register-last-step'>
      <h4 className='register-last-step__title'><Text field={dataSources['Register Success Title']}/></h4>
      <div className='register-last-step__wrap'>
        <p className='register-last-step__message-success'>
          <RichText field={dataSources['Register Success Message']}/>
        </p>
        <p className='register-last-step__email'>{emailRegister}</p>
        <p className='register-last-step__instruction'><Text field={dataSources['Register Success Instruction']}/></p>
        <p className='register-last-step__resend-mail'>
          <span><Text field={dataSources['Did Not Get Email Message']}/></span> 
          <span onClick={() => dispatch(resendMailActive({email: emailRegister}))}><Text field={dataSources['Resend Message']}/></span>
        </p>
      </div>
    </div>
  ) : '';
};

RegisterActiveMail.propTypes = {
  dataSources: PropTypes.object,
  emailRegister: PropTypes.any,
};

export default RegisterActiveMail;
