export const REMOVE_ADDRESS_BOOK = 'REMOVE_ADDRESS_BOOK';

export const CHANGE_DEFAULT_ADDRESS_BOOK = 'CHANGE_DEFAULT_ADDRESS_BOOK';

export const CHECK_COMMERCE_USER = 'CHECK_COMMERCE_USER';

export const CHECK_COMMERCE_USER_SUCCESS = 'CHECK_COMMERCE_USER_SUCCESS';

export const CHECK_COMMERCE_USER_FAILED = 'CHECK_COMMERCE_USER_FAILED';

export const SET_INFO_USER_CHECKOUT = 'SET_INFO_USER_CHECKOUT';

export const GET_STORE_ADDRESS_LIST = 'GET_STORE_ADDRESS_LIST';

export const GET_STORE_ADDRESS_LIST_SUCCESS = 'GET_STORE_ADDRESS_LIST_SUCCESS';

export const GET_STORE_ADDRESS_LIST_FAILED = 'GET_STORE_ADDRESS_LIST_FAILED';

export const GET_BOOKED_ADDRESS = 'GET_BOOKED_ADDRESS';

export const GET_BOOKED_ADDRESS_SUCCESS = 'GET_BOOKED_ADDRESS_SUCCESS';

export const GET_BOOKED_ADDRESS_FAILED = 'GET_BOOKED_ADDRESS_FAILED';

export const UPDATE_BILLING_ADDRESS = 'UPDATE_BILLING_ADDRESS';

export const UPDATE_BILLING_ADDRESS_SUCCESS = 'UPDATE_BILLING_ADDRESS_SUCCESS';

export const UPDATE_BILLING_ADDRESS_FAILED = 'UPDATE_BILLING_ADDRESS_FAILED';

export const ADD_NEW_ADDRESS = 'ADD_NEW_ADDRESS';

export const ADD_NEW_ADDRESS_SUCCESS = 'ADD_NEW_ADDRESS_SUCCESS';

export const ADD_NEW_ADDRESS_FAILED = 'ADD_NEW_ADDRESS_FAILED';

export const GET_BILLING_DATA = 'GET_BILLING_DATA';

export const GET_BILLING_DATA_SUCCESS = 'GET_BILLING_DATA_SUCCESS';

export const GET_BILLING_DATA_FAILED = 'GET_BILLING_DATA_FAILED';

export const GET_BALANCE_BILLING_DATA = 'GET_BALANCE_BILLING_DATA';

export const GET_BALANCE_BILLING_DATA_SUCCESS = 'GET_BALANCE_BILLING_DATA_SUCCESS';

export const GET_BALANCE_BILLING_DATA_FAILED = 'GET_BALANCE_BILLING_DATA_FAILED';

export const SUBMIT_ORDER = 'SUBMIT_ORDER';

export const SUBMIT_ORDER_SUCCESS = 'SUBMIT_ORDER_SUCCESS';

export const SUBMIT_ORDER_FAILED = 'SUBMIT_ORDER_FAILED';

export const SUBMIT_ORDER_PAYBALANCE = 'SUBMIT_ORDER_PAYBALANCE';

export const SUBMIT_ORDER_PAYBALANCE_SUCCESS = 'SUBMIT_ORDER_PAYBALANCE_SUCCESS';

export const SUBMIT_ORDER_PAYBALANCE_FAILED = 'SUBMIT_ORDER_PAYBALANCE_FAILED';

export const GET_PAYMENT_DETAIL = 'GET_PAYMENT_DETAIL';

export const GET_PAYMENT_DETAIL_SUCCESS = 'GET_PAYMENT_DETAIL_SUCCESS';

export const GET_PAYMENT_DETAIL_FAILED = 'GET_PAYMENT_DETAIL_FAILED';

export const GET_COUNTRY_DELIVERY = 'GET_COUNTRY_DELIVERY';

export const GET_COUNTRY_DELIVERY_SUCCESS = 'GET_COUNTRY_DELIVERY_SUCCESS';

export const GET_COUNTRY_DELIVERY_FAILED = 'GET_COUNTRY_DELIVERY_FAILED';

export const GET_ORDER_PAYMENT_STATUS = 'GET_ORDER_PAYMENT_STATUS';

export const GET_ORDER_PAYMENT_STATUS_SUCCESS = 'GET_ORDER_PAYMENT_STATUS_SUCCESS';

export const GET_ORDER_PAYMENT_STATUS_FAILED = 'GET_ORDER_PAYMENT_STATUS_FAILED';

export const ADD_NEW_ADDRESS_AND_SETFULFILL = 'ADD_NEW_ADDRESS_AND_SETFULFILL';

export const ADD_NEW_ADDRESS_AND_SETFULFILL_SUCCESS = 'ADD_NEW_ADDRESS_AND_SETFULFILL_SUCCESS';

export const ADD_NEW_ADDRESS_AND_SETFULFILL_FAILED = 'ADD_NEW_ADDRESS_AND_SETFULFILL_FAILED';

export const ADD_NEW_ADDRESS_AND_SETBILLING = 'ADD_NEW_ADDRESS_AND_SETBILLING';

export const ADD_NEW_ADDRESS_AND_SETBILLING_SUCCESS = 'ADD_NEW_ADDRESS_AND_SETBILLING_SUCCESS';

export const ADD_NEW_ADDRESS_AND_SETBILLING_FAILED = 'ADD_NEW_ADDRESS_AND_SETBILLING_FAILED';

export const DOWNLOAD_INVOICE = 'DOWNLOAD_INVOICE';

export const DOWNLOAD_INVOICE_SUCCESS = 'DOWNLOAD_INVOICE_SUCCESS';

export const DOWNLOAD_INVOICE_FAILED = 'DOWNLOAD_INVOICE_FAILED';

export const SET_ADDRESS_VALID_ERROR_MESSAGE = 'SET_ADDRESS_VALID_ERROR_MESSAGE';