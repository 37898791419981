import { CANCEL_EVENT_BOOKING_FAILED, CANCEL_EVENT_BOOKING_START, CANCEL_EVENT_BOOKING_SUCCESS } from './cancelEventBookingTypes';

export const cancelEventBookingStart = (payload) => ({
  type: CANCEL_EVENT_BOOKING_START,
  payload
});

export const cancelEventBookingSuccess = (payload) => ({
  type: CANCEL_EVENT_BOOKING_SUCCESS,
  payload
});

export const cancelEventBookingFailed = (payload) => ({
  type: CANCEL_EVENT_BOOKING_FAILED,
  payload
});
