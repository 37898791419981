export default class IncludedProductLabelItemModel {
  constructor() {
    this['Code'] = '';

    this['IsSpecial'] = false;

    this['Name'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Code')) {
      this['Code'] = dataSource['Code'] ? dataSource['Code'] : this['Code'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this['Name'] = dataSource['Name'] ? dataSource['Name'] : this['Name'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsSpecial')) {
      this['IsSpecial'] = dataSource['IsSpecial'] ? dataSource['IsSpecial'] : this['IsSpecial'];
    }

    return this;
  }
}
