import * as Yup from 'yup';

function validatePhone({ isRequire = false, prefixCode = '', errorMsg, min, max, internalPrefixKey = null }) {
  return this.test('validate-phone', errorMsg, function (value) {
    const { path, createError } = this;
    // const internalPrefixValue = internalPrefixKey ? this.parent?.[internalPrefixKey] || null : null;
    // const prefixCodeRawValue = (internalPrefixValue ?? prefixCode) || '';

    if (isRequire && !value) {
      return createError({
        path,
        errorMsg
      });
    }

    if (value) {
      // const prefixCodeValue = prefixCodeRawValue?.replace('+', '') || '';
      // const phoneNumberValue = `${prefixCodeValue}${value}`;
      const phoneNumberLength = value.length;
      if (min && phoneNumberLength < min) {
        return createError({
          path,
          errorMsg
        });
      }
      if (max && phoneNumberLength > max) {
        return createError({
          path,
          errorMsg
        });
      }
    }

    return true;
  });
}

Yup.addMethod(Yup.string, 'validatePhone', validatePhone);

export { Yup };
