import React from 'react';
import { Fragment } from 'react';

const CampaignProductListingShimmer = () => {
  return (
    <Fragment>
      <article className='article article--horizontal article--horizontal--w50 campaign-item--horizontal'>
        <div className='article__image image-wrap'>
          <span className='image-line'></span>
        </div>
        <div className='article__content'>
          <h3 className='article__content__title'>
            <span className='text-line-heading'></span>
          </h3>
          <div className='article__content__price'>
            <span className='text-line'></span>
            <span className='clear-10'></span>
            <span className='text-line'></span>
            <span className='clear-10'></span>
            <span className='text-line'></span>
            <span className='clear-10 hidden-xs-down'></span>
            <span className='text-line hidden-xs-down'></span>
          </div>
          <div className='article__content__action'>
            <span className='text-line-heading w-50'></span>
          </div>
        </div>
      </article>
      <article className='article article--horizontal article--horizontal--w50 article--horizontal--reverse campaign-item--horizontal'>
        <div className='article__image image-wrap'>
          <span className='image-line'></span>
        </div>
        <div className='article__content'>
          <h3 className='article__content__title'>
            <span className='text-line-heading'></span>
          </h3>
          <div className='article__content__price'>
            <span className='text-line'></span>
            <span className='clear-10'></span>
            <span className='text-line'></span>
            <span className='clear-10'></span>
          </div>
          <p className='article__content__description'></p>
          <div className='article__content__action'>
            <span className='text-line-heading w-50'></span>
          </div>
        </div>
      </article>
    </Fragment>
  );
};

export default CampaignProductListingShimmer;