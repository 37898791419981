import './ScheduleAppointmentComponent.scss';

import React, { useEffect } from 'react';

import { useQuery } from '@utils/customsHook/useQuery';

import FormikStep from './FormikStep';
import { SCHEDULE_APPOINTMENT_PARAM_NAME } from './FormikStep/configs';
import ProgressBar from './ProgressBar';

const Layout = () => {
  const query = useQuery();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [query.get(SCHEDULE_APPOINTMENT_PARAM_NAME)]);

  return (
    <div className='be-schedule-appointment container'>
      <ProgressBar />
      <FormikStep />
    </div>
  );
};

export default Layout;
