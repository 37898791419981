import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/spinAndWin';
import * as spinAndWinTypes from '@redux/actions/spinAndWin/spinAndWinTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from '../config';

export const getNumberSpinEmailEpic = (action$) =>
  action$.pipe(
    ofType(spinAndWinTypes.GET_NUMBER_SPIN_EMAIL),
    switchMap((action) => {

      const params = {
        email: action.payload.email,
        luckyDrawId: action.payload.luckyDrawId
      };

      return getAsObservable(config.ACTION_GET_NUMBER_SPIN_EMAIL, params).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getNumberSpinEmailSuccess(deepCopy(res?.data));
          }
        })
      );
    })
  );