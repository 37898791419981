import produce from 'immer';

import * as singleSignOnTypes from '@redux/actions/myAppointment/myAppointmentTypes';

const initState = {
  isLoading: false,
  isCancelSuccess: false,
  isCancelFailed: false,
  statusCode: null
};

const cancelAppointmentReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case singleSignOnTypes.CANCEL_APPOINTMENT:
      draft.isLoading = true;

      break;

    case singleSignOnTypes.CANCEL_APPOINTMENT_SUCCESS:
      draft.isLoading = false;

      draft.isCancelSuccess = true;

      draft.statusCode = action.payload?.StatusCode;
      break;

    case singleSignOnTypes.CANCEL_APPOINTMENT_FAILED:
      draft.isLoading = false;
      
      draft.isCancelFailed = true;

      draft.statusCode = action.payload?.StatusCode;
      break;
    }

    return draft;
  });

export default cancelAppointmentReducer;
