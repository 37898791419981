import './AutoCompleteUI.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

const AutoCompleteUI = (props) => {
  const {
    searchTerm,
    placeholder,
    onSearchHandler, 
    onGetSuggestionListHandler, 
    suggestionsListData,
    onData,

    //Extra For Wellness
    onResetTerm,
    onChangeSearchTerm,
  } = props;

  const dispatch = useDispatch();

  const searchContainer = useRef(null);

  const searchResultRef = useRef(null);

  const inputRef = useRef(null);

  const [search, setSearch] = useState(searchTerm);

  const [suggestionsList, setSuggestionsList] = useState(suggestionsListData);

  const [cursor, setCursor] = useState(-1);

  const [isVisible, setVisible] = useState(false);

  useEffect(() => {
    document.addEventListener('mousedown', (e) => handleClickOutside(e));

    return () => {
      document.addEventListener('mousedown', (e) => handleClickOutside(e));
    };
  }, []);

  const handleClickOutside = (event) => {
    if (searchContainer.current && !searchContainer.current.contains(event.target)) {

      hideSuggestion();
    }
  };

  const showSuggestion = () => {
    setVisible(true);
  };

  const hideSuggestion = () => {
    setVisible(false);
  };

  //If have URL Param set search value = URL Param
  useEffect(() => {
    setSearch(searchTerm);
  }, [searchTerm]);

  //get AutoComplete List when text >= 3
  useEffect(() => {
    if (search?.trim().length >= 3) {
      dispatch(onGetSuggestionListHandler({value: search, dataSourcePath: onData?.rendering.dataSource || ''}));
    } else {
      setSuggestionsList([]);
    }

    if (onChangeSearchTerm) {
      onChangeSearchTerm(search);
    }

    setCursor(-1);
  }, [search]);

  // set newSuggestionList
  useEffect(() => {
    setSuggestionsList(suggestionsListData);
  }, [suggestionsListData]);

  const onChangeHandler = (value) => {
    setSearch(value);
  };

  // when user using keyboard
  const onKeyDownHandler = (event) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();

      if (isVisible && suggestionsList?.length) {
        setCursor(prevCursor => prevCursor < suggestionsList.length - 1 ? prevCursor + 1 : prevCursor);
      }
    }

    if (event.key == 'ArrowUp') {
      event.preventDefault();

      if (isVisible && suggestionsList?.length) {
        setCursor(prevCursor => (prevCursor > 0 ? prevCursor - 1 : -1));
      }
    }

    if (event.key == 'Escape') {
      event.preventDefault();
      
      hideSuggestion();
    }

    if (event.key == 'Enter') {
      event.preventDefault();

      if (cursor >= 0) {
        setSearch(suggestionsList[cursor]);
  
        onSearchHandler(suggestionsList[cursor]);
  
      } else {
        setSearch(search);

        onSearchHandler(search);
      }

      setTimeout(() => {
        hideSuggestion();

        if (inputRef?.current) {
          inputRef.current.blur();
        }
      }, 50);
    }
  };

  return (
    <div className='searchbox--autocomplete'>
      <div className='container smaller-container'>
        <div className='searchbox--autocomplete--wrap' ref={searchContainer}>
          {props.children}
          <div className='flex-between searchbox--autocomplete--input'>
            <form className='autocomplete--form' action='.'>
              <input
                ref={inputRef}
                type='search'
                name='search-autocomplete'
                className='autocomplete--input'
                placeholder={placeholder}
                onFocus={showSuggestion}
                onClick={showSuggestion}
                onChange={(event) => onChangeHandler(event.target.value)}
                onKeyDown={(event) => onKeyDownHandler(event)}
                value={search || ''}
                autoComplete='off'
              />
            </form>
            <i className={'x-icon-clear icon-close' + `${search?.length ? ' visible' : ''}`} onClick={onResetTerm ? () => onResetTerm() : () => setSearch('')}></i>
            <span className='x-icon-search' onClick={() => {
              onSearchHandler(search);

              hideSuggestion();
            }}>
            </span>
          </div>

          <div className={'autocomplete--suggestion' + (isVisible && suggestionsList?.length ? ' visible' : '')}>
            <ul className='autocomplete--suggestion--list' ref={ searchResultRef }>
              {suggestionsList?.length 
                ? suggestionsList.map((item, itemIdx) => {
                  return (
                    <li
                      key={itemIdx}
                      className={'autocomplete--suggestion--list__item' + `${cursor === itemIdx ? ' highlighted' : ''}`}
                      onClick={() => {
                        inputRef.current.blur();

                        hideSuggestion();

                        onChangeHandler(item);

                        onSearchHandler(item);
                      }}
                    >
                      {item}
                    </li>
                  );
                })
                : <li
                  className='autocomplete--suggestion--list__item'
                >
                  No Data
                </li> }
            </ul>
          </div>
        </div>
      </div>
      
    </div>
  );
};

AutoCompleteUI.propTypes = {
  children: PropTypes.any,
  searchTerm: PropTypes.any,
  placeholder: PropTypes.any,
  onResetTerm: PropTypes.func,
  onSearchHandler: PropTypes.func,
  onChangeSearchTerm: PropTypes.func,
  onGetSuggestionListHandler: PropTypes.func,
  suggestionsListData: PropTypes.any,
  onData: PropTypes.any
};

export default AutoCompleteUI;