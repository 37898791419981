import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/productReview';
import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getLatestReviewEpic = (action$) =>
  action$.pipe(
    ofType(productReviewTypes.GET_LATEST_REVIEW),
    switchMap(() => {
      return getAsObservable(config.ACTION_GET_LATEST_REVIEW).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getLatestReviewSuccess(deepCopy(res.data));
          } else {
            return actions.getLatestReviewFailed('Failed');
          }
        })
      );
    })
  );
