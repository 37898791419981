import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/myOrders';
import * as myOrderTypes from '@redux/actions/myOrders/myOrderTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getActivateOrdersEpic = (action$) =>
  action$.pipe(
    ofType(myOrderTypes.GET_ACTIVATE_ORDERS),
    switchMap((action) => {
      return getAsObservable(config.ACTION_GET_ACTIVATE_ORDERS).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.getActivateOrdersSuccess(deepCopy(res.data?.Data));
          } else {
            return actions.getActivateOrdersFailed('Failed');
          }
        })
      );
    })
  );