import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import { behaviorSubjectSubmitForm } from '../../../formBehavior';

const CustomResponseMsg = (props) => {
  const [data, setData] = useState(null);
  const [formItemIdCurrent, setFormItemId] = useState(null);
  const Tag = props.field.model.htmlTag || 'p';

  useEffect(() => {
    const behaviorSubjectSubmitFormSubscription = behaviorSubjectSubmitForm.subscribe((params) => {
      setData(params);
    });

    return () => {
      behaviorSubjectSubmitFormSubscription.unsubscribe();
    };
  }, [behaviorSubjectSubmitForm]);

  useEffect(() => {
    setFormItemId(props.field.formItemId);
  }, []);

  return data?.formItemId ? (
    <Fragment>
      {data.isSuccess && props.field.model.cssClass === 'form-message-success' && data.formItemId === formItemIdCurrent ? (
        <Tag className={props.field.model.cssClass}>{props.field.model.text}</Tag>
      ) : (
        ''
      )}
      {!data.isSuccess && props.field.model.cssClass === 'form-message-error' && data.formItemId === formItemIdCurrent ? (
        <Tag className={props.field.model.cssClass}>{props.field.model.text}</Tag>
      ) : (
        ''
      )}
      {props.field.model.cssClass !== 'form-message-success' && props.field.model.cssClass !== 'form-message-error' ? <Tag className={props.field.model.cssClass}>{props.field.model.text}</Tag> : <></>}
    </Fragment>
  ) : (
    <Fragment>
      {props.field.model.cssClass !== 'form-message-success' && props.field.model.cssClass !== 'form-message-error' ? <Tag className={props.field.model.cssClass}>{props.field.model.text}</Tag> : <></>}
    </Fragment>
  );
};

CustomResponseMsg.propTypes = {
  field: PropTypes.object
};

export default CustomResponseMsg;
