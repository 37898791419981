import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class TimeLinePanelItemModel {
  constructor() {

    this.Description = new SitecoreTextModel();

    this.Milestone = new SitecoreTextModel();

    this.Image = new SitecoreImageModel();

  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Milestone')) {
      this.Milestone.getData(dataSource.Milestone);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image.getData(dataSource.Image);
    }


    return this;
  }
}
