import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import FieldsModel from './FieldsModel';

export default class DynamicStripAdsFieldsModel {
  constructor() {
    this.content = new SitecoreTextModel();

    this.backgroundColor = '';

    this.textColor = '';

    this.stripAdsContent = [];

    this.isTopStripAds = '';

    this.ID = '';

    this.name = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'content')) {
      this.content.getData(dataSource.content || {});
      if (dataSource.content?.value) {
        this.stripAdsContent.unshift(new FieldsModel().getData(deepCopy(dataSource)));
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'backgroundColor')) {
      this.backgroundColor = dataSource.backgroundColor;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'textColor')) {
      this.textColor = dataSource.textColor;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'stripAdsContent')) {
      let newItemsList = [];

      Object.keys(dataSource.stripAdsContent).map((key) => {
        newItemsList.push(new FieldsModel().getData(deepCopy(dataSource.stripAdsContent[key]) || {}));
      });

      this.stripAdsContent = this.stripAdsContent.concat(newItemsList);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'isTopStripAds')) {
      this.isTopStripAds = dataSource.isTopStripAds;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ID')) {
      this.ID = dataSource.ID;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'name')) {
      this.name = dataSource.name;
    }

    return this;
  }
}
