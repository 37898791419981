import PropTypes from 'prop-types';
import React from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import { Link,RichText } from '@sitecore-jss/sitecore-jss-react';

const CarouselItem = ({ carouselItem }) => {
  return carouselItem.fields && (
    <div className='os-carousel__item'>
      <Link field={carouselItem.fields.Link}>
        <ResponsiveImage
          imageMobile={carouselItem.fields['Mobile Image']}
          srcSet={[ { mw: 768, image: carouselItem.fields.Image} ]}
        />
      </Link>
      <Link field={carouselItem.fields.Link}>
        <RichText tag='h3' className='os-carousel__item__title' field={carouselItem.fields['Item Title']} />
      </Link>
      <RichText tag='p' className='os-carousel__item__description normal-text' field={carouselItem.fields['Description']} />
    </div>
  );
};

CarouselItem.propTypes = {
  carouselItem: PropTypes.object
};

export default CarouselItem;