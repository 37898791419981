import { useCallback } from 'react';

import { generateCartItemObject } from './utils';

export const useGoTrackEmarsys = () => {
  return useCallback(() => {
    // eslint-disable-next-line no-undef
    ScarabQueue.push(['go']);
  });
};

export const useTrackingSetEmailEvent = () => {
  return useCallback((userInfoReducer) => {
    const email = userInfoReducer?.accountUser?.Email;
    // eslint-disable-next-line no-undef
    if (email) ScarabQueue.push(['setEmail', email]);
  }, []);
};

export const useTrackingCategoryEvent = () => {
  return useCallback((categoryName) => {
    // eslint-disable-next-line no-undef
    if (categoryName) ScarabQueue.push(['category', categoryName]);
  }, []);
};

export const useTrackingCartEvent = () => {
  return useCallback((currentLines) => {
    const cartItemList = [];
    if (currentLines?.length) {
      for (const item of currentLines) {
        cartItemList.push(generateCartItemObject(item));
      }
    }

    // eslint-disable-next-line no-undef
    ScarabQueue.push(['cart', cartItemList]);
  }, []);
};

export const useTrackingAddToCartEvent = () => {
  return useCallback((productId) => {
    // eslint-disable-next-line no-undef
    if (productId) ScarabQueue.push(['view', productId]);
  }, []);
};

export const useTrackingPurchaseEvent = () => {
  return useCallback((orderNumber, cartLines) => {
    if (orderNumber && cartLines?.length) {
      const items = [];
      for (const item of cartLines) {
        items.push(generateCartItemObject(item));
      }

      // eslint-disable-next-line no-undef
      ScarabQueue.push([
        'purchase',
        {
          orderId: orderNumber,
          items: [...items]
        }
      ]);
    }
  }, []);
};
