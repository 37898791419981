import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import CouponWalletListModel from '@components/CouponWallet/model/CouponWalletListModel';
import * as actions from '@redux/actions/couponWallet';
import * as couponWalletTypes from '@redux/actions/couponWallet/couponWalletTypes';
import { get } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getCouponWalletListEpic = (action$) =>
  action$.pipe(
    ofType(couponWalletTypes.GET_COUPON_WALLET_LIST),
    switchMap((action) => {      
      return from(get(config.ACTION_GET_COUPON_WALLET_LIST, action.payload)).pipe(
        map((res) => {
          if (res.status == 200 && res?.data?.Success) {
            const dataSources = new CouponWalletListModel().getData(deepCopy(res.data));

            return actions.getCouponWalletListSuccess(dataSources);
          } else return actions.getCouponWalletListFailed('Failed');
        })
      );
    })
  );
