import './NewsletterPopup.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import PopupBanner from '../PopupBanner';
import NewsletterForm from './components/NewsletterForm';
import NewsletterModel from './models/NewsletterModel';

const NewsletterPopup = ({ fields }) => {
  const [fieldData, setFieldData] = useState(null);
  const [isShowPopup, setIsShowPopup] = useState(true);

  useEffect(() => {
    let model = new NewsletterModel();

    setFieldData(model.getData(fields));
  }, []);

  useEffect(() => {
    const isShowPopupLocalVar = window.sessionStorage.getItem('isShowNewsletterPopupNextTime');

    isShowPopupLocalVar && setIsShowPopup(JSON.parse(isShowPopupLocalVar));
  }, []);

  return fieldData && isShowPopup && !JSON.parse(fieldData?.['IsSubscribed']) ? (
    <div className='newsletter-popup'>
      <PopupBanner fields={fields} isNewsLetterPopup={true}>
        <NewsletterForm fieldData={fieldData} />
      </PopupBanner>
    </div>
  ) : (
    <></>
  );
};

NewsletterPopup.propTypes = {
  fields: Proptypes.any
};

export default NewsletterPopup;
