import produce from 'immer';

import * as cartTypes from '@redux/actions/cartDetail/cartTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  pickUpPoints: null,
  isLoading: false,
  error: '',
  // pickUpPointsAreaOptions: [],
  // pickUpPointsDistrictOptions: [],
  pickUpPointsNetworkOptions: [],
};

const sfExpressReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cartTypes.PICK_UP_POINT:
      draft.isLoading = true;
      
      draft.pickUpPoints = null;
      break;

    case cartTypes.PICK_UP_POINT_SUCCESS:
      draft.isLoading = false;

      draft.pickUpPoints = action.payload;

      deepCopy(action.payload).map((item) => {
        // if (!draft.pickUpPointsAreaOptions.find(option => option.AreaCode === item.AreaCode && item.AreaCode)) {
        //   draft.pickUpPointsAreaOptions.push({...item, label: item.AreaName, value: item.AreaCode});
        // }
        // if (!draft.pickUpPointsDistrictOptions.find(option => option.ProvinceCode === item.ProvinceCode && item.ProvinceCode)) {
        //   draft.pickUpPointsDistrictOptions.push({...item, label: item.ProvinceName, value: item.ProvinceCode});
        // }
        if (!draft.pickUpPointsNetworkOptions.find(option => option.NetworkCode === item.NetworkCode && item.NetworkCode)) {
          draft.pickUpPointsNetworkOptions.push({...item, label: item.NetworkName, value: item.NetworkCode});
        }
      });
      
      break;

    case cartTypes.PICK_UP_POINT_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

    }

    return draft;
  });

export default sfExpressReducer;
