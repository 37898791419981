import './GoogleMap.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';

import { GoogleMap, InfoWindow, Marker, useLoadScript } from '@react-google-maps/api';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import mapStyles from './mapStyles';

const options = {
  styles: mapStyles
};

const GoogleMapContainer = (props) => {
  const [mapRef, setMapRef] = useState();

  const { markerIcon, initConfig, onClickStoreHandler, onIdle, hoveredTab, onHoverStoreHandler } = props;

  const onMapLoad = useCallback((map) => {
    setMapRef(map);
  }, []);

  const GGkey = SitecoreContextFactoryService.getValueContextRouteItem('fields')['Google Map API Key'].value;

  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: 'AIzaSyD9G3yU6CBFBMhuClWTKYsxZIDk5D44Szs'
  });

  const google = window.google;

  if (loadError) return 'Error loading maps';
  if (!isLoaded) return 'Loading Maps';

  return (
    <GoogleMap
      mapContainerClassName='react-google-maps-api'
      zoom={initConfig.zoom}
      center={initConfig.center}
      onLoad={(map) => onMapLoad(map)}
      options={options}
      onIdle={() => onIdle(mapRef)}
      clickableIcons={false}
    >
      {props.searchResultList?.length ? (
        props.searchResultList.map((marker) => {
          return (
            <Marker
              key={marker.ItemId}
              position={{
                lat: marker.Latitude,
                lng: marker.Longitude
              }}
              icon={{
                url: `${markerIcon.src}`,
                scaledSize: JSON.stringify(marker) === JSON.stringify(hoveredTab) ? new google.maps.Size(50, 45) : new google.maps.Size(39, 36)
              }}
              onClick={() => onClickStoreHandler(marker, false)}
              onMouseOver={() => onHoverStoreHandler(marker)}
              onMouseOut={() => onHoverStoreHandler(null)}
            >
              {JSON.stringify(marker) === JSON.stringify(hoveredTab) ? (
                <InfoWindow>
                  <div className='info-window'>
                    <h4>{marker.Name}</h4>
                    <p>{marker.AddressStreet}</p>
                    <p>{marker.Phone}</p>
                  </div>
                </InfoWindow>
              ) : (
                <></>
              )}
            </Marker>
          );
        })
      ) : (
        <></>
      )}
    </GoogleMap>
  );
};

GoogleMapContainer.propTypes = {
  google: PropTypes.any,
  markerIcon: PropTypes.any,
  initConfig: PropTypes.any,
  onClickStoreHandler: PropTypes.any,
  onIdle: PropTypes.any,
  hoveredTab: PropTypes.object,
  onHoverStoreHandler: PropTypes.func,
  searchResultList: PropTypes.array
};

const mapStateToProp = (state) => {
  return {
    searchResultList: state.storeLocatorReducer.allStoreReducer.searchResultList || [],
    hoveredTab: state.storeLocatorReducer.allStoreReducer.hoveredTab
  };
};

export default connect(mapStateToProp)(GoogleMapContainer);
