import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setIsActiveQACustomerBasicProfile } from '@redux/actions/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import AccessItem from '../AccessItem';
import CustomerAcknowledgePopup from '../CustomerAcknowledgePopup';
import QAWarningPopup from '../QAWarningPopup';
import QACustomerBasicProfileModel from './model/QACustomerBasicProfileModel';

const QACustomerBasicProfile = ({ fields, dataModel }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [warningPopup, setWarningPopup] = useState(false);
  const clientTellingPortalQuickAccess = useSelector((state) => state.clientTellingPortalQuickAccess);
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId') || '';
  const dispatch = useDispatch();

  useEffect(() => {
    const model = new QACustomerBasicProfileModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  const handleClickQACustomeBasicProfile = () => {
    if (cpCustomerId) {
      dispatch(setIsActiveQACustomerBasicProfile(!clientTellingPortalQuickAccess.isActiveQACustomerBasicProfile));
    } else {
      setWarningPopup(true);
    }
  };

  const closeAccessPopup = () => {
    dispatch(setIsActiveQACustomerBasicProfile(false));
  };

  return layoutData ? (
    <>
      <li className={clientTellingPortalQuickAccess.isActiveQACustomerBasicProfile ? 'active-access' : ''}>
        <AccessItem clickAccessItem={handleClickQACustomeBasicProfile} layoutData={<Image field={layoutData['Basic Profile Icon']} />} />
        <CustomerAcknowledgePopup closeAccessPopup={closeAccessPopup} isCenter={false} layoutData={layoutData} />
      </li>
      <QAWarningPopup warningPopup={warningPopup} setWarningPopup={() => setWarningPopup(false)} dataModel={dataModel} />
    </>
  ) : (
    <></>
  );
};

QACustomerBasicProfile.propTypes = {
  fields: object,
  dataModel: object
};

export default QACustomerBasicProfile;
