export const productKeyStringArray = ['productOne', 'productTwo', 'productThree'];

export const WIDTH_BOUNDARY = 768;

export const paramsRecently = {
  lazy: true,
  rebuildOnUpdate: true,
  slidesPerView: 2.5,
  centeredSlides: false,
  spaceBetween: 20,
  shouldSwiperUpdate: true,
  watchOverflow: true,
  breakpoints: {
    768: {
      slidesPerView: 3,
      spaceBetween: 40,
      navigation: {
        nextEl: '.recently-viewed-products .swiper-button-next',
        prevEl: '.recently-viewed-products .swiper-button-prev'
      }
    }
  }
};
