import * as types from './mediaTrackingTypes';

export const MEDIA_TRACKING_GOAL_ID = '{C815035A-242C-4D2D-8289-D7B832BD7A57}';

export const mediaStartTrackingGoal = (payload) => ({
  type: types.MEDIA_START_TRACKING_GOAL,
  payload
});

export const mediaTrackingGoalSuccess = (payload) => ({
  type: types.MEDIA_TRACKING_GOAL_SUCCESS,
  payload
});

export const mediaTrackingGoalFailed = (payload) => ({
  type: types.MEDIA_TRACKING_GOAL_FAILED,
  payload
});