

export default class GiftingOptionsApiModel {
  constructor() {
    this.GiftingOptions = [];

    this.CurrencyInformation = {};
  }
  
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'GiftingOptions')) {
      this.GiftingOptions = dataSource.GiftingOptions ? dataSource.GiftingOptions : this['GiftingOptions'];
    }
    
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyInformation') && dataSource.CurrencyInformation) {
      this.CurrencyInformation = dataSource.CurrencyInformation;
    }

    return this;
  }
}
