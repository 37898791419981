import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  DEPOSIT_FREE_GIFT_KEY
} from '../../../BuyingOptions/BOFreeGifts/BOFreeGiftsList/FreeGiftDeposit';

const BuyingTueTypeHeader = ({ isOpen, labelText, changeText, secondTypeItemsName, onToogleFnc, errorMsg, isShowError, onFreeGiftData }) => {
  const { freeGiftDepositSelected, freeGiftData } = useSelector((state) => state.buyingOptionReducer.BOFreeGiftsReducer);
  const [activeChangeButton, setActiveChangeButton] = useState(false);

  const productSelectedData = useMemo(() => {
    let data = [];
    const depositFreeGiftSelectedKeys = Object.keys(freeGiftDepositSelected);
    if (secondTypeItemsName && secondTypeItemsName?.length) data = [...secondTypeItemsName];

    if (depositFreeGiftSelectedKeys.length) {
      for (const key of depositFreeGiftSelectedKeys) {
        data.push({
          ...freeGiftDepositSelected[key],
          order: key === DEPOSIT_FREE_GIFT_KEY ? 1 : 2
        });
      }
    }

    return data.sort((prev, next) => prev?.order - next?.order);
  }, [secondTypeItemsName, freeGiftDepositSelected]);

  useEffect(() => {
    if (!Object.keys(freeGiftDepositSelected).length) {
      setActiveChangeButton(false);
    } else {
      setActiveChangeButton(true);
    }
  }, [freeGiftDepositSelected]);

  return (
    <Fragment>
      {freeGiftData?.BalanceFreeGifts.length > 0 || freeGiftData?.DepositFreeGifts.length > 0 ? (
        <div className='accordion-buying-option__header'>
          <h3 className='bo-title-section'>{labelText}</h3>
          {activeChangeButton ? (
            <span className={`btn-change${isOpen ? '' : ' active'}`} onClick={onToogleFnc}>
              {changeText}
            </span>
          ) : (
            <></>
          )}
          <span className={'error-msg' + `${isShowError ? ' active' : ''}`}>{errorMsg}</span>
        </div>
      ) : (
        <></>
      )}
      {!isOpen ? (
        productSelectedData.map((item, itemIdx) => (
          <div className='free-gift-selected' key={itemIdx}>
            {'VariantName' in item ? item.VariantName : 'DisplayName' in item ? item.DisplayName : ''}
          </div>
        ))
      ) : (
        <></>
      )}
    </Fragment>
  );
};

BuyingTueTypeHeader.propTypes = {
  isOpen: PropTypes.any,
  labelText: PropTypes.any,
  changeText: PropTypes.any,
  secondTypeItemsName: PropTypes.any,
  onToogleFnc: PropTypes.any,
  errorMsg: PropTypes.any,
  isShowError: PropTypes.any,
  contentData: PropTypes.array,
  onFreeGiftData: PropTypes.object
};

export default BuyingTueTypeHeader;
