import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = [
  'Customer Recommend Percent',
  'See All Reviews Button Text',
  'Share With Other Customer Text',
  'Write A Review Button Text',
  'Customer Reviews Title Text',
  'Out Of Text',
  'Ratings Text',
  'Review This Product Text',
  'Recommend Text',
  'Not Recommend Text',
  'Read More Text',
  'Read Less Text'
];

const LINKARR = [
  'See All Reviews Button Link',
  'Write A Review Link',
];

export default class ProductReviewForPDPModels {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
