import QuestionModel from '@components/ClientPortal/ReviewCustomerInformation/model/QuestionModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import SitecoreImageModel from '../../../../models/SitecoreImageModel';

const IMAGE_ARR = ['Icon', 'Remove Product Icon'];

const TEXT_ARR = [
  'New Product Text', 
  'Title', 
  'Captured Text', 
  'Edit Button Text', 
  'POS text', 
  'Save Button Text',
  'No Button Text',
  'Require Product Warning Text',
  'Warning Message',
  'Warning Title',
  'Yes Button Text',
  'Have Not Save Warning Message',
  'Other Reason Label',
  'Require Other Reason Text',
  'Upsell Select Product Label',
  'Select Product Label',
  'Remove Product Message'
];

export default class ReviewProductInterestModel {
  constructor() {
    IMAGE_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Questions'] = [];

    this['Accessories Product'] = [];
  }

  getData(dataSource) {
    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Questions')) {
      Global.removeEmptyObj(dataSource['Questions']).map((element) => {
        this['Questions'].push(new QuestionModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Accessories Product')) {
      let newItemsList = [];

      dataSource['Accessories Product'].map((element) => {
        newItemsList.push(deepCopy(element));
      });

      this['Accessories Product'] = newItemsList;
    }

    return this;
  }
}
