import { deepCopy } from '@utils/utility';

import InstallmentPeriodsModel from './InstallmentPeriodsModel';
import PaymentOptionModel from './PaymentOptionModel';

const STR_ARR = ['CartText', 'Id', 'InstallmentCartLevelText', 'InstallmentProductLevelText', 'Name', 'PdpLogoItemUrl', 'InstallmentDynamicBundleLevelText'];
const BOOL_ARR = ['InstallmentCartLevel', 'InstallmentProductLevel', 'SupportFullPayment', 'SupportInstallment'];

export default class GatewaysModel {
  constructor() {
    STR_ARR.forEach((item) => {
      this[item] = '';
    });

    BOOL_ARR.forEach((item) => {
      this[item] = false;
    });

    this.InstallmentMaxCartAmount = 0;

    this.InstallmentMinCartAmount = 0;

    this.InstallmentPeriods = [];

    this.PaymentOption = new PaymentOptionModel();
  }

  getData(dataSource) {
    STR_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    BOOL_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentMaxCartAmount')) {
      this['InstallmentMaxCartAmount'] = dataSource['InstallmentMaxCartAmount'] ?? this.InstallmentMaxCartAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentMinCartAmount')) {
      this['InstallmentMinCartAmount'] = dataSource['InstallmentMinCartAmount'] ?? this.InstallmentMinCartAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentPeriods')) {
      dataSource['InstallmentPeriods'].map((element) => {
        this['InstallmentPeriods'].push(new InstallmentPeriodsModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PaymentOption')) {
      this['PaymentOption'].getData(dataSource['PaymentOption']);
    }

    return this;
  }
}
