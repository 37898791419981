export const LOAD_EMARSYS_SCRIPT_SUCCESS = 'loaded';

export const LOAD_EMARSYS_SCRIPT_FAILED = 'errored';

export const checkScriptExist = (emarsysSrc) => {
  return new Promise((resolve, reject) => {
    if (document.getElementById('scarab-js-api')) {
      resolve(LOAD_EMARSYS_SCRIPT_SUCCESS);
    }

    const emarsysScript = document.createElement('script');

    emarsysScript.type = 'text/javascript';

    emarsysScript.id = 'scarab-js-api';

    emarsysScript.src = emarsysSrc;

    emarsysScript.onload = () => {
      resolve(LOAD_EMARSYS_SCRIPT_SUCCESS);
    };

    emarsysScript.onerror = () => {
      reject(LOAD_EMARSYS_SCRIPT_FAILED);
    };

    document.head.appendChild(emarsysScript);
  });
};

export const generateCartItemObject = (data) => {
  const quantity = Number.parseInt(data.Quantity ?? '0');

  return {
    item: data.ProductId ?? '',
    price: (data.LineFinalPriceAmount || data.LinePriceAmount) * quantity,
    quantity: quantity
  };
};
