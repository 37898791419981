import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/productWarranty';
import * as productWarrantyTypes from '@redux/actions/productWarranty/productWarrantyTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from '../config';

export const getMyProductEpic = (action$) =>
  action$.pipe(
    ofType(productWarrantyTypes.GET_MY_PRODUCT),
    switchMap(() => {
      return getAsObservable(config.ACTION_GET_MY_PRODUCT).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getMyProductSuccess(deepCopy(res.data));
          } else {
            return actions.getMyProductFailed('Failed');
          }
        })
      );
    })
  );
