import produce from 'immer';

import * as cPOverviewTypes from '@redux/actions/cPOverview/cPOverviewTypes';

const initState = {
  isLoading: false,
  cpOverviewData: {},
  cPError: null
};

const cpOverviewReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cPOverviewTypes.GET_OVERVIEW:
      draft.isLoading = true;
      break;

    case cPOverviewTypes.GET_OVERVIEW_SUCCESS:
      draft.isLoading = false;

      draft.cpOverviewData = {...action.payload};
      break;
  
    case cPOverviewTypes.GET_OVERVIEW_FAILED:
      draft.isLoading = false;
        
      draft.cPError = action.error;
      break;
    }

    return draft;
  });

export default cpOverviewReducer;
