export default class PriceApiModel {
  constructor() {
    this.SellingPrice = 0;

    this.ListPrice = 0;

    this.CurrencyCode = '';

    this.SellingPriceWithCurrency = '';

    this.ListPriceWithCurrency = '';

    this.Discount = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice')) {
      this.SellingPrice = dataSource.SellingPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this.ListPrice = dataSource.ListPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyCode')) {
      this.CurrencyCode = dataSource.CurrencyCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPriceWithCurrency')) {
      this.SellingPriceWithCurrency = dataSource.SellingPriceWithCurrency;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPriceWithCurrency')) {
      this.ListPriceWithCurrency = dataSource.ListPriceWithCurrency;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Discount')) {
      this.Discount = dataSource.Discount;
    }

    return this;
  }
}
