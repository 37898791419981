import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';

export default class CPLogoModels {
  constructor() {
    this['Image'] = new SitecoreImageModel();

    this['Link'] = new SitecoreLinkModel();

    this['Second Image'] = new SitecoreImageModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this['Link'].getData(dataSource['Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second Image')) {
      this['Second Image'].getData(dataSource['Second Image']);
    }

    return this;
  }
}
