import ShippingOptionType from './ShippingOptionType';

export default class BundleDeliveryOptionsModel {
  constructor() {
    this['Description'] = '';

    this['ExternalId'] = '';

    this['Name'] = '';

    this['ShippingOptionType'] = new ShippingOptionType();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'] = dataSource['Description'] ? dataSource['Description'] : this['Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExternalId')) {
      this['ExternalId'] = dataSource['ExternalId'] ? dataSource['ExternalId'] : this['ExternalId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this['Name'] = dataSource['Name'] ? dataSource['Name'] : this['Name'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ShippingOptionType')) {
      this['ShippingOptionType'].getData(dataSource['ShippingOptionType']);
    }

    return this;
  }
}
