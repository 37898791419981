import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'View All Button Text',
  'Description',
  'See More Button Text',
  'Title',
  'Anchor ID'
];

export default class PartnerSpecialModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['View All Button Link'] = new SitecoreLinkModel();

    this['ListPartnerSpecials'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View All Button Link')) {
      this['View All Button Link'] = deepCopy(dataSource['View All Button Link']);
    }
    
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPartnerSpecials')) {
      this['ListPartnerSpecials'] = deepCopy(dataSource['ListPartnerSpecials']);
    }

    return this;
  }
}
