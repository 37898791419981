import SitecoreTextModel from '@models/SitecoreTextModel';

export default class TitleModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();
  }
  
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}
