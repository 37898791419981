import Proptypes from 'prop-types';
import React from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { Link,Text } from '@sitecore-jss/sitecore-jss-react';

const WarningFinalReview = ({
  isShow, 
  onCloseModalHandler,
  onClickButtonlHandler,
  popupTitle, 
  popupContents,
  buttonText,
  linkText,
  linkHref
}) => {
  return (
    <ModalComponent 
      cssClass='warning-popup' 
      isShow={isShow} 
      onCloseHandler={onCloseModalHandler}
    >
      <div className='warning-popup__content'>
        <h4 className='warning-popup__content__title'><Text field={popupTitle}/></h4>
        <p className='warning-popup__content__desc'><Text field={popupContents}/></p>
        
        <div className='warning-popup__content__btn'>
          {
            buttonText ? (
              <button 
                type='button' 
                onClick={() => onClickButtonlHandler()}
                className='warning-popup__content__btn__item btn btn-primary'>
                <Text field={buttonText} />
              </button>
            ): null
          }
          {
            linkText ? (
              linkHref ? (
                <Link field={linkHref}>
                  <Text field={linkText} />
                </Link>
              ): (
                <Text 
                  tag='span' 
                  className='warning-popup__content__btn__item os-btn-link link-nomal' 
                  field={linkText} 
                  onClick={() => onCloseModalHandler()}
                />
              )
            ) : ''
          }
        </div>
      </div>
    </ModalComponent>
  );
};

WarningFinalReview.propTypes = {
  isShow: Proptypes.bool,
  onCloseModalHandler: Proptypes.func,
  onClickButtonlHandler: Proptypes.func,
  popupTitle: Proptypes.object,
  popupContents: Proptypes.object,
  buttonText: Proptypes.object,
  linkText: Proptypes.any,
  linkHref: Proptypes.any,
};

export default WarningFinalReview;