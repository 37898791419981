import './ActivityLog.scss';

import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDateTime } from '@utils/customsHook/useFormatDate';

import useAppointmentDetailContext from '../AppointmentDetail/useAppointmentDetailContext';

const ActivityLog = () => {
  const { layoutData, appointmentDetail } = useAppointmentDetailContext();
  const formatDateTimeUtil = useFormatDateTime();

  return appointmentDetail?.ActivityLogs?.length ? (
    <div className='activity-log'>
      <Text field={layoutData?.['Activity Log Label']} tag='h5' className='activity-log__title' />
      <div className='activity-log__content'>
        {appointmentDetail?.ActivityLogs?.map((activity, idx) => {
          return (
            <div key={idx} className='activity-log__content__group activity-log__content__group--border-bottom'>
              <Text
                field={{ value: `${activity?.UserName} ${activity?.LogMessage}` }}
                tag='p'
                className='activity-log__content__line' />
              <Text
                field={{ value: formatDateTimeUtil(activity?.Date)}}
                tag='p'
                className='activity-log__content__line' />
            </div>
          );
        })}
      </div>
    </div>
  ) : (<></>);
};

export default ActivityLog;