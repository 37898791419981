import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import RelationshipModel from './RelationshipModel';

export default class AllowedOptionsFieldsModel {
  constructor() {
    this['Icon'] = new SitecoreImageModel();

    this['Relationship'] = new RelationshipModel();

    this['Title'] = new SitecoreTextModel();

    this['Validation Message'] = new SitecoreTextModel();

    this.Required = true;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon')) {
      this['Icon'].getData(dataSource['Icon']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Relationship')) {
      this['Relationship'].getData(dataSource['Relationship']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Validation Message')) {
      this['Validation Message'].getData(dataSource['Validation Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Required')) {
      this.Required = dataSource.Required.value;
    }

    return this;
  }
}
