import './CartDetailModal.scss';

import { any, bool, func } from 'prop-types';
import React from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Message from '@components/Share/Message';

const CartDetailModal = (props) => {
  const { isShow, onCloseHandler, ondataSources } = props;

  return (
    <ModalComponent isShow={isShow} onCloseHandler={onCloseHandler} titlePopup={''}>
      <div className='cart-detail-modal'>
        <p className='cart-detail-modal__content'><Message messageCode='Msg_045' /></p>
        <button className='btn btn-outline-primary cart-detail-modal__button' onClick={onCloseHandler}> 
          {
            ondataSources?.['Close Button Text'] && 
            <Text field={ondataSources?.['Close Button Text']} />
          }
          {
            ondataSources?.['Deposit Close Popup Button Text'] && 
            <Text field={ondataSources?.['Deposit Close Popup Button Text']} />
          }
        </button>
      </div>
    </ModalComponent>
  );
};

CartDetailModal.propTypes = {
  isShow: bool,
  onCloseHandler: func,
  ondataSources: any
};

export default CartDetailModal;
