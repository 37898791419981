import produce from 'immer';

import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';

const initState = {
  isLoading: false,
  isRegister: null,
  errorMessage: '',
  registerError: {
    type: '',
    errorMessage: ''
  },
  isRegisteringNewAccount: false
};

const registerReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case singleSignOnTypes.REGISTER:
      draft.isLoading = true;

      draft.isRegister = null;

      draft.errorMessage = '';

      draft.isRegisteringNewAccount = true;

      break;

    case singleSignOnTypes.REGISTER_SUCCESS:
      draft.isLoading = false;

      draft.isRegister = true;
      break;

    case singleSignOnTypes.REGISTER_FAILED:
      draft.isLoading = false;

      draft.isRegister = false;

      draft.errorMessage = action.error.errorMessage;

      draft.registerError = {
        type: action.error.type,
        errorMessage: action.error.errorMessage
      };

      draft.isRegisteringNewAccount = false;
      break;

    case singleSignOnTypes.RESET_REGISTER_ERROR_MSG:
      draft.errorMessage = '';
      break;
    }

    return draft;
  });

export default registerReducer;
