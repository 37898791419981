import { object } from 'prop-types';
import React, { useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

export const DEFAULT_EMPTY_ID = '00000000-0000-0000-0000-000000000000';

const TeaAndScentSelected = ({ layoutData }) => {
  const teaData = useMemo(() => {
    return layoutData?.appointment?.Tea;
  }, [layoutData]);

  const scentData = useMemo(() => {
    return layoutData?.appointment?.Scent;
  }, [layoutData]);

  return teaData?.Id !== DEFAULT_EMPTY_ID || scentData?.Id !== DEFAULT_EMPTY_ID ? (
    <div className='appointment-detail__body__item appointment-detail__body__item--scheduled-visit'>
      <Text field={layoutData?.['Personlized Experience Label']} tag='h3' className='appointment-detail__body__item__title' />
      {teaData?.Id !== DEFAULT_EMPTY_ID ? (
        <div className='appointment-detail__body__item__box'>
          <div className='appointment-detail__body__item__img'>
            <div className='appointment-detail__body__item__img-wrapper'>
              <img src={layoutData?.appointment?.Tea?.Image?.Url} alt={layoutData?.appointment?.Tea?.Name} />
            </div>
          </div>
          <div className='appointment-detail__body__item__content'>
            <h4 className='appointment-detail__body__item__content__title'>{layoutData?.appointment?.Tea?.Name}</h4>
            <div
              className='appointment-detail__body__item__content__description'
              dangerouslySetInnerHTML={{ __html: layoutData?.appointment?.Tea?.Description }}
            ></div>
          </div>
        </div>
      ) : (
        <></>
      )}
      {scentData?.Id !== DEFAULT_EMPTY_ID ? (
        <div className='appointment-detail__body__item__box'>
          <div className='appointment-detail__body__item__img'>
            <div className='appointment-detail__body__item__img-wrapper'>
              <img src={layoutData?.appointment?.Scent?.Image?.Url} alt={layoutData?.appointment?.Scent?.Name} />
            </div>
          </div>
          <div className='appointment-detail__body__item__content'>
            <h4 className='appointment-detail__body__item__content__title'>{layoutData?.appointment?.Scent?.Name}</h4>
            <div
              className='appointment-detail__body__item__content__description'
              dangerouslySetInnerHTML={{ __html: layoutData?.appointment?.Scent?.Description }}
            ></div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  ) : (
    <></>
  );
};

TeaAndScentSelected.propTypes = {
  layoutData: object
};

export default TeaAndScentSelected;
