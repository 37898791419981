import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Completed', 'Expired', 'Draft', 'Active', 'Viewed', 'Deleted'];

export default class SaleOrderStatusesModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
