import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreImageModel from '@models/SitecoreImageModel';

const TEXT_ARR = [
  'Apply',
  'From',
  'Header',
  'Reset',
  'Search order',
  'Status',
  'To',
  'Total order',
  'Active orders',
  'Open orders',
  'Past orders',
  'View details',
  'Order',
  'Order placed',
  'Store name',
  'Total',
  'Package count',
  'No sale order',
  'Filter default date range',
  'Min total',
  'Max total',
  'All status',
  'Date',
  'Order to expire',
  'Expiry Date',
  'Sales Order Value',
  'Sale Staff',
  'Store',
  'Category',
  'Select Staff',
  'Select Store',
  'Select Category',
  'Select Status',
  'Select date',
  'Today',
  'This week',
  'This month',
  'Customize',
  'Number Records Label',
  'No Result Label',
  'No Result Description Label'
];

const LINK_ARR = [
  'View details page'
];

const IMG_ARR = [
  'Search icon',
  'Filter icon'
];

export default class SaleOrdersListingModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMG_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    this['Statuses'] = [];

    this['Stores'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMG_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    })

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Statuses')) {
      this['Statuses'] = dataSource['Statuses'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stores')) {
      this['Stores'] = dataSource['Stores'];
    }

    return this;
  }
}
