import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'Add To Compare Text',
  'Benefits Label',
  'Buy Now Text',
  'Choose Category Text',
  'Choose Product Text',
  'Dimension Label',
  'Learn More Text',
  'Model Specification Label',
  'No Products Text',
  'Recently Viewed Product Title',
  'Title',
  'Weight Label',
  'Model Specification Key',
  'Dimension Key',
  'Weight Key',
  'No Options Text'
];

const LINK_ARR = ['Buy Now Link', 'Learn More Link'];

export default class CompareProductsModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['List Product Category'] = [];

    this['Recently Viewed Products'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'List Product Category')) {
      let newItemsList = [];

      dataSource['List Product Category'].map((element) => {
        newItemsList.push(deepCopy(element));
      });

      this['List Product Category'] = newItemsList;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Recently Viewed Products')) {
      let newItemsList = [];

      dataSource['Recently Viewed Products'].map((element) => {
        newItemsList.push(deepCopy(element));
      });

      this['Recently Viewed Products'] = newItemsList;
    }

    return this;
  }
}
