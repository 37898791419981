import '../BORentals/BORental.scss';

import Proptypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import BuyingAccordion from '@components/Share/BuyingAccordion';
import { ADDITIONAL_OPTIONS_KEY } from '@utils/constant';

const BOAdditionalOptions = ({ dataFields, additionalOptionsList, additionalOptionsActive, isShowError, setRef }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const onGetOptionPrice = (productsArray, productId) => {
    let additionalOption = null;

    if (productsArray.length) {
      additionalOption = productsArray.find((product) => {
        return product.ProductId === productId;
      });
    }

    return `${additionalOption?.DisplayName} (${
      additionalOption?.SellingPrice === 0 ? messageObj['LBL-WarrantyFee'] || '' : additionalOption?.Price?.ListPriceWithCurrency
    })`;
  };

  const getAdditionalOptionSetting = (optionName) => {
    return dataFields['Additional Options Setting'].fields['Allowed Options'].find(
      (option) => option.fields.Relationship.fields['Field Name'].value === optionName
    );
  };

  const shouldValidate = (optionName) => {
    if (!getAdditionalOptionSetting(optionName).fields.Required) return false;

    return isShowError?.checkValidate ? (isShowError?.validateList?.[optionName]?.length ? false : true) : false;
  };

  const shouldOpenItem = (optionName) => {
    const isEditAndHasValue = additionalOptionsActive[optionName] !== undefined;
    const isRequired = getAdditionalOptionSetting(optionName).fields.Required;

    if (!isRequired && !isEditAndHasValue) return true;

    return additionalOptionsActive?.[optionName]?.length ? false : true;
  };

  const getActiveItemsId = (optionName) => {
    return additionalOptionsActive?.[additionalOptionsList[optionName].RelationshipId]
      ? [additionalOptionsActive?.[additionalOptionsList[optionName].RelationshipId]]
      : [];
  };

  return (
    <Fragment>
      {Object.keys(additionalOptionsList).map((additionalOption, index) => {
        return (
          <div
            className='warranty-buying buying-option__group-info__line-bottom'
            ref={index == 0 ? setRef : null}
            key={additionalOptionsList[additionalOption].RelationshipId}
          >
            <BuyingAccordion
              data={additionalOptionsList[additionalOption].Products}
              headerType={'FIRSTTYPE'}
              eventKey={ADDITIONAL_OPTIONS_KEY}
              isOpenItem={shouldOpenItem(additionalOption)}
              activeItemsId={getActiveItemsId(additionalOption)}
              selectedItemsName={onGetOptionPrice(additionalOptionsList?.[additionalOption]?.Products, additionalOptionsActive?.[additionalOption])}
              // selectedItemsName={dataFields['Additional Options Setting'].fields['Allowed Options'].map((option) => {
              //   if (Object.keys(additionalOptionsActive).includes(option.fields.Relationship.fields['Field Name'].value)) {
              //     return `${additionalOptionsActive?.[option.fields.Relationship.fields['Field Name'].value]} ${additionalOptionsList[
              //       additionalOption
              //     ].Products.find((product) => {
              //       return
              //     })}`;
              //   }
              // })}
              additionalOptionsRequired={getAdditionalOptionSetting(additionalOption).fields.Required}
              labelText={getAdditionalOptionSetting(additionalOption).fields.Title.value}
              changeText={dataFields['Change Text'].value}
              errorMsg={getAdditionalOptionSetting(additionalOption).fields['Validation Message'].value}
              dataFields={dataFields}
              additionalOptionsData={additionalOptionsList[additionalOption].RelationshipId}
              isShowError={shouldValidate(additionalOption)}
            />
          </div>
        );
      })}
    </Fragment>
  );
};

BOAdditionalOptions.propTypes = {
  dataFields: Proptypes.any,
  additionalOptionsList: Proptypes.any,
  additionalOptionsActive: Proptypes.any,
  isShowError: Proptypes.object,
  setRef: Proptypes.any
};

export default BOAdditionalOptions;
