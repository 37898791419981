import produce from 'immer';

import {
  SCHEDULE_APPOINTMENT_FAILED,
  SCHEDULE_APPOINTMENT_START,
  SCHEDULE_APPOINTMENT_SUCCESS
} from '@redux/actions/bookingExperience/scheduleAppointment/types';

const initialStates = {
  isLoading: false,
  error: null,
  scheduleAppointmentResponse: null
};

const scheduleAppointmentReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case SCHEDULE_APPOINTMENT_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case SCHEDULE_APPOINTMENT_SUCCESS:
      draft.isLoading = false;

      draft.scheduleAppointmentResponse = action.payload;
      break;

    case SCHEDULE_APPOINTMENT_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default scheduleAppointmentReducer;
