import './WarrantyProductList.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Share/Loading';
import { getMyProduct } from '@redux/actions/productWarranty';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import MyProductsModel from './models/MyProductsModel';
import WarrantyProductItem from './WarrantyProductItem';

const MyProducts = ({ fields }) => {
  const dispatch = useDispatch();
  const [myProductsFields, setMyProductsFields] = useState(null);
  const { isLoading, data: arrayProduct } = useSelector((state) => state.productWarrantyReducer.getMyProductReducer);

  useEffect(() => {
    const myProductsModel = new MyProductsModel();

    setMyProductsFields(myProductsModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    dispatch(getMyProduct());
  }, []);

  const renderWarrantyProductItem = () => {
    return arrayProduct?.length
      ? arrayProduct?.map((productItem, index) => {
        return <WarrantyProductItem key={index} fieldData={myProductsFields} productItem={productItem} />;
      })
      : '';
  };

  return myProductsFields && !isLoading ? (
    <div className='warranty-product-page'>
      <div className='container'>
        <div className='warranty-product-page__row warranty-product-page__button'>
          <div className='warranty-product-page__col warranty-product-page__button__register'>
            <Link className='btn btn-primary' field={myProductsFields['Register Product And Warranty Button Link']}>
              <Text field={myProductsFields['Register Product And Warranty Button Label']} />
            </Link>
          </div>
          <div className='warranty-product-page__col warranty-product-page__button__back'>
            <Link className='my-account-section__back-link' field={myProductsFields['Back To My Account Link']}>
              {'< '} <Text field={myProductsFields['Back To My Account Text']} />
            </Link>
          </div>
          <div className='warranty-product-page__col col-empty'></div>
        </div>
      </div>
      <div className='warranty-product-page__list'>
        <div className='container no-padding-mobile'>
          <div className='warranty-product-page__list__wrap'>{renderWarrantyProductItem()}</div>
        </div>
      </div>
    </div>
  ) : (
    <Loading />
  );
};

MyProducts.propTypes = {
  fields: Proptypes.any
};

export default MyProducts;
