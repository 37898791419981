import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class BannerFieldsModel {
  constructor() {
    this.Title = new SitecoreTextModel();

    this['Background Image'] = new SitecoreImageModel();

    this.Description = new SitecoreTextModel();

    this['CTA Button'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();
  }

  getData(dataSource) {

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Image')) {
      this['Background Image'].getData(dataSource['Background Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'].getData(dataSource['CTA Button Text']);
    }

    return this;
  }
}
