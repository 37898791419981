import './CartItemGiftingOption.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { connect, useDispatch } from 'react-redux';

import { actionRemoveGiftingOption } from '@redux/actions/product';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import ListItemSwiper from '../ListItemSwiper';

const CartItemGiftingOption = (props) => {
  const {dataSources, itemGiftingOption, cartItemData, currencyInfor, onAddSelection, isLoading, lineId} = props;

  const dispatch = useDispatch();

  const quantityItem = Number(cartItemData.Quantity) > 1 ? cartItemData.Quantity : '';
  const listGiftWraps = itemGiftingOption?.GiftWraps || [];
  const listGiftCards = itemGiftingOption?.GiftCards || [];
  const giftWrapSelectedId = cartItemData.GiftWrapId;
  const giftCardSelectedId = cartItemData.GiftCardId;
  const symbolCurrency = currencyInfor?.Symbol || '';
  const giftCardSelectedMessage = cartItemData.GiftCardMessage;
  const MAX_MESSAGE = dataSources['Character Limit']?.value || 0;
  const GIFT_WRAP = 'gift-warp';
  const GIFT_CARD = 'gift-card';
  const GIFT_ADD = 'Add';
  const GIFT_CANCEL = 'Cancel';
  const GIFT_EDIT = 'Edit';
  const GIFT_CLOSE = 'Close';
  const giftWrapRef = useRef(null);
  const giftCardRef = useRef(null);

  const [isOpen, setOpen] = useState(false);
  const [messageData, setMessageData] = useState(decodeURI(giftCardSelectedMessage) || '');
  const [isShowMessage, setIsShowMessage] = useState(Boolean(giftCardSelectedId));
  const [giftStatus, setGiftStatus] = useState('');

  const giftSelected = (itemList, itemSelectedId) => itemList.find(item => item.ItemId === itemSelectedId);
  const giftWrapSelected = giftSelected(listGiftWraps, giftWrapSelectedId);
  const giftCardSelected = giftSelected(listGiftCards, giftCardSelectedId);
  const isEdit = giftWrapSelected || giftCardSelected;
  const [priceGiftWarp, setPriceGiftWarp] = useState(giftWrapSelected ? (Number(giftWrapSelected?.PriceAmount) || 0) : '');
  const [priceGiftCard, setPriceGiftCard] = useState(giftCardSelected ? (Number(giftCardSelected?.PriceAmount) || 0) : '');
  const [giftCardId, setGiftCardId] = useState(giftCardSelectedId);
  const [giftWrapId, setGiftWrapId] = useState(giftWrapSelectedId);
  const [isUpdateGift, setIsUpdateGift] = useState(false);

  const resetData = () => {
    setIsShowMessage(Boolean(giftCardSelectedId));

    setMessageData(decodeURI(giftCardSelectedMessage || ''));

    setPriceGiftWarp(giftWrapSelected ? (Number(giftWrapSelected?.PriceAmount) || 0) : '');

    setPriceGiftCard(giftCardSelected ? (Number(giftCardSelected?.PriceAmount) || 0) : '');

    setGiftCardId(giftCardSelectedId);

    setGiftWrapId(giftWrapSelectedId);
  };

  useEffect(() => {
    if (isEdit) {
      isOpen ? setGiftStatus(GIFT_CLOSE) : setGiftStatus(GIFT_EDIT);
    } else {
      isOpen ? setGiftStatus(GIFT_CANCEL) : setGiftStatus(GIFT_ADD);
    }

    if (!isOpen) {
      resetData();
    }
  }, [isLoading, isOpen, itemGiftingOption]);

  const getDataGift = (element) => {
    if (element) {
      if (element.type === GIFT_WRAP) {
        element.isActive ? setPriceGiftWarp(Number(element.PriceAmount)) : setPriceGiftWarp('');
      } else {
        element.isActive ? setPriceGiftCard(Number(element.PriceAmount)) : setPriceGiftCard('');
        
        setIsShowMessage(element.isActive);
      }
    }

    if (element.type === GIFT_WRAP) {
      element.isActive ? setGiftWrapId(element.ItemId) : setGiftWrapId('');
    }

    if (element.type === GIFT_CARD) {
      element.isActive ? setGiftCardId(element.ItemId) : setGiftCardId('');
    }
  };

  useEffect(() => {
    if (isEdit) {
      if (giftCardId || giftWrapId) {
        if (giftCardId !== giftCardSelectedId 
          || giftWrapId !== giftWrapSelectedId 
          || messageData !== decodeURI(giftCardSelectedMessage)
        ) {
          setIsUpdateGift(true);
        } else {
          setIsUpdateGift(false);
        }
      } else {
        setIsUpdateGift(false);
      }
    }
  }, [giftCardId, giftWrapId, messageData, isLoading]);

  const changeMessage = (event) => {
    setMessageData(decodeURI(event.target.value));
  };

  const limitCharacter = (string, maxLength) => string.substring(0, maxLength);

  const handleAddSelection = () => {
    const maxMessage = Number(MAX_MESSAGE);
    if (MAX_MESSAGE) {
      if (messageData.length > maxMessage) {
        let newMessage = limitCharacter(messageData, maxMessage);

        setMessageData(decodeURI(newMessage));
      }
    }

    const giftOption = {
      giftCardId, 
      giftWrapId, 
      giftCardMessage: giftCardId ? encodeURI(messageData) : ''
    };

    onAddSelection(giftOption);

    setTimeout(() => { //INFO: delay Collapse gifting option
      setOpen(false);
    }, 500);
  };

  const handleRemoveSelection = () => {
    const cartLineId = cartItemData.ExternalCartLineId;

    dispatch(actionRemoveGiftingOption({cartLineId}));

    setTimeout(() => { //INFO: delay Collapse gifting option
      setOpen(false);
    }, 500);
  };

  const giftingTotalPrice = () => {
    if (priceGiftWarp ==='' && priceGiftCard === '') {
      return '';
    } else {
      const totalPrice = (priceGiftWarp || 0) + (priceGiftCard || 0);
  
      return totalPrice > 0 
        ? symbolCurrency + global.formatCurrency(quantityItem ? quantityItem*totalPrice : totalPrice) 
        : <Text field={dataSources['Free Text']} />;
    }
  };

  return (itemGiftingOption?.GiftWraps?.length 
    || itemGiftingOption?.GiftCards?.length)
    ? (
      <div className='gifting-option large-text'>
        <div aria-controls='option-1' aria-expanded={isOpen} className=''>
          <div className='gifting-option__header'>
            <div className='gifting-option__icon'>
              <span className='product-info-icon product-info-icon--gifting'></span>
            </div>
            <div className='gifting-option__contents'>
              <div className='justify-space-between'>
                <h4 className='gifting-option__contents--title'>
                  <Text tag='span' field={dataSources['Gifting Option Label']} />
                  {' '}
                  {(giftCardId || giftWrapId) && quantityItem ? <span>{`x ${quantityItem}`}</span> : ''}
                </h4>
                <span onClick={() => setOpen(!isOpen)} className='link-text'>{giftStatus}</span>
              </div>
            </div>
          </div>
          {
            !isOpen && isEdit && (
              <div className='gifting-option__body'>
                <div className='gifting-option__body--name-item'>
                  {
                    giftWrapSelected && (<p>
                      {giftWrapSelected.ItemName}
                      {' '}
                      <Text field={dataSources['Gift Wrap Label']} />
                    </p>)
                  }
                  {
                    giftCardSelected && (
                      <>
                        <p>
                          {giftCardSelected.ItemName}
                          {' '}
                          <Text field={dataSources['Gift Card Label']} />
                        </p>
                        {messageData ? <p className='smaller-text smaller-text--lighter'>{messageData}</p> : ''}
                      </>
                    )
                  }
                </div>
                {
                  giftWrapSelected || giftCardSelected
                    ? (
                      <div className='normal-text'>
                        {giftingTotalPrice()}
                      </div>
                    )
                    : <></>
                }
              </div>
            )
          }
        </div>
        <Collapse in={isOpen}>
          <div id='option-1' className='gifting-option__collapse'>
            <div className='gifting-option__collapse__header justify-space-between'>
              <Text tag='p' className='gifting-option__collapse__description' field={dataSources['Select Gifting Option Label']} />
              <p className='gifting-option__collapse__price normal-text'>
                {giftingTotalPrice()}
              </p>
            </div>
            {
            itemGiftingOption?.GiftWraps?.length 
              ? (
                <div className='gifting-option__collapse__group' >
                  <div className='gifting-option__collapse__title link-text link-text--gray-darker'>
                    <Text field={dataSources['Gift Wrap Label']} />
                  </div>
                  <ListItemSwiper 
                    data={listGiftWraps} 
                    type={GIFT_WRAP} 
                    dataCallBack={getDataGift}
                    giftSelectedId={giftWrapSelectedId}
                    isOpen={isOpen}
                    giftSwiperRef={giftWrapRef}
                    lineId={lineId}
                  ></ListItemSwiper>
                </div>
              )
              : <></>
            }

            {
            itemGiftingOption?.GiftCards?.length
              ? (
                <Fragment>
                  <div className='gifting-option__collapse__group'>
                    <div className='gifting-option__collapse__title link-text link-text--gray-darker'>
                      <Text field={dataSources['Gift Card Label']} />
                    </div>
                    <ListItemSwiper 
                      data={listGiftCards} 
                      type={GIFT_CARD} 
                      dataCallBack={getDataGift}
                      giftSelectedId={giftCardSelectedId}
                      isOpen={isOpen}
                      giftSwiperRef={giftCardRef}
                      lineId={lineId}
                    ></ListItemSwiper>
                  </div>
                  <div className='gifting-option__collapse__textarea'>
                    {
                      isShowMessage
                        ? <>
                          <textarea name='' id='' placeholder={dataSources['Gift Message Label'].value} maxLength={MAX_MESSAGE} value={limitCharacter(messageData, Number(MAX_MESSAGE))} onChange={changeMessage}></textarea>
                          <small className='noti-text'>
                            ({messageData.length} /{' '}
                            <Text field={dataSources['Character Limit']} />
                            {' '}
                            <Text field={dataSources['Characters Label']} />
                            )
                          </small>
                        </>
                        : '' 
                    }
                  </div>
                </Fragment>
              )
              : <></>
            }
          
            <div className='gifting-option__collapse__button'>
              {
                isEdit
                  ? (
                    <Fragment>
                      <button type='button' className='btn btn-link' onClick={() => handleRemoveSelection()}>
                        <Text field={dataSources['Remove Button Text']} />
                      </button>
                      <button type='button' className={`btn btn-outline-CTA2 btn-save ${isUpdateGift ? '' : 'disabled'}`} onClick={() => handleAddSelection()}>
                        <Text field={dataSources['Save Gift Label']} />
                      </button>
                    </Fragment>
                  )
                  : (
                    <Fragment>
                      <button type='button' className={`btn btn-outline-CTA2 ${giftCardId || giftWrapId ? '' : 'disabled'}`} onClick={() => handleAddSelection()}>
                        <Text field={dataSources['Add Selection Label']} />
                      </button>
                    </Fragment>
                  )
              }
            </div>
          </div>
        </Collapse>
      </div>
    ) 
    : <></>;
};

CartItemGiftingOption.propTypes = {
  dataSources: PropTypes.object,
  itemGiftingOption: PropTypes.object,
  currencyInfor: PropTypes.object,
  cartItemData: PropTypes.any,
  onAddSelection: PropTypes.any,
  isLoading: PropTypes.bool,
  lineId: PropTypes.string
};

const mapStateToProps = (state) => ({
  isLoading: state.productReducer.cartDetailReducer.isLoading
});

// export default CartItemGiftingOption;
export default connect(mapStateToProps)(CartItemGiftingOption);