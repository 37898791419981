import './LoginRegisterModal.scss';

import { bool, func, object } from 'prop-types';
import React from 'react';

import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const LoginRegisterModal = (props) => {
  const { isShow, onCloseHandler, dataLayout, urlLoginPage } = props;

  return (
    <ModalComponent isShow={isShow} onCloseHandler={onCloseHandler} titlePopup={''}>
      <div className='login-register-modal'>
        {dataLayout?.['Login Messages'] ? 
          <Text field={dataLayout?.['Login Messages']} className='login-register-modal__text' tag='p' />
          : <p className='login-register-modal__text'>
            <Message messageCode='Msg_044' />
          </p>
        }
        <Link field={urlLoginPage?.value ? urlLoginPage : { href: urlLoginPage }} className='btn btn-primary'>
          <Text field={dataLayout?.['Login Button Text'] || dataLayout?.['Deposit Login Button Text']} />
        </Link>
      </div>
    </ModalComponent>
  );
};

LoginRegisterModal.propTypes = {
  isShow: bool,
  onCloseHandler: func,
  dataLayout: object,
  urlLoginPage: object,
};

export default LoginRegisterModal;
