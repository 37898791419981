import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { clearAddressDatasources } from '@redux/actions/myAddress';
import { applyFilterStore, removeApplyFilterStore } from '@redux/actions/storeLocator';

import StoreLocatorFilterPopup from './StoreLocatorFilterPopup';

const StoreLocatorFilterIcon = ({ dataFromLayout }) => {
  const dispatch = useDispatch();

  const [showFilter, setShowFilter] = useState(false);

  const onFilterReducerHandler = (params) => {
    setShowFilter(false);
    if (params) {
      dispatch(applyFilterStore({ ...params }));
    } else {
      dispatch(removeApplyFilterStore());
    }
  };

  const onCloseFilterPopup = () => {
    setShowFilter(false);

    dispatch(clearAddressDatasources());
  };

  return (
    <Fragment>
      <div className='store-locator__search__btn__item store-locator__search__btn__item--filter' onClick={() => setShowFilter(true)}>
        <span className='store-locator-icon store-locator-icon--filter'></span>
      </div>

      {showFilter ? (
        <ModalComponent isShow={showFilter} titlePopup={dataFromLayout['Filter Stores Label'].value} onCloseHandler={onCloseFilterPopup}>
          <StoreLocatorFilterPopup setCloseEditAddressEvt={onFilterReducerHandler} dataSources={dataFromLayout} />
        </ModalComponent>
      ) : (
        ''
      )}
    </Fragment>
  );
};

StoreLocatorFilterIcon.propTypes = {
  dataFromLayout: PropTypes.any
};

export default StoreLocatorFilterIcon;
