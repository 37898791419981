export default class ProductSummaryModel {
  constructor() {
    this.Image = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image = dataSource.Image ?? this.Image;
    }

    return this;
  }
}
