export default class StoreFilterZipCodeModel {
  constructor() {
    this['Id'] = '';

    this['label'] = '';

    this['ZipCodeName'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ? dataSource['Id'] : this['Id'];

      this['value'] = dataSource['Id'] ? dataSource['Id'] : this['value'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ZipCodeName')) {
      this['label'] = dataSource['ZipCodeName'] ? dataSource['ZipCodeName'] : this['label'];
      
      this['ZipCodeName'] = dataSource['ZipCodeName'] ? dataSource['ZipCodeName'] : this['ZipCodeName'];
    }

    return this;
  }
}