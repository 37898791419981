import React from 'react';
import { Fragment } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';

const FeaturedProductsShimmer = () => {

  return (
    <Fragment>
      <div className='featured-products'>
        <div className='container'>
          <div className='featured-products__heading skeleton__item'>
            <h2 className='experience-title featured-products__heading__title'>
              <span className='text-line w-30'></span>
            </h2>
          </div>
          <div className='featured-products__list'>
            <div className='row'>  
              <LoadingShimmer itemNumb={3}>
                <div className='col-md-6 col-xl-4 skeleton__item'>
                  <div className='featured-product-item__wrap'>
                    <div className='image-wrap'>
                      <div className='image-line'></div>
                    </div>
                    <div className='clear-20'></div>
                    <p className='text-container'>
                      <span className='text-line'></span>
                    </p>
                  </div>
                </div>
              </LoadingShimmer>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default FeaturedProductsShimmer;