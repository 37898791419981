import axios from 'axios';
import { Observable } from 'rxjs';
import config from '../temp/config';

const apiHost = config.sitecoreApiHost;
const apiStem = 'api/osim/HelpMeDecide/GetResults';
axios.defaults.baseURL = apiHost;

axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
export function getAsObservable(params) {
    return new Observable((observer) => {
        const cancelToken = axios.CancelToken.source();
        axios.get(`/${apiStem}?${params}`)
            .then(
                (result) => {
                    observer.next(result);
                    observer.complete();
                },
                (err) => {
                    if (axios.isCancel(err)) {
                        observer.complete();
                    } else {
                        observer.error(err);
                    }
                }
            );

        return () => cancelToken.cancel();
    });
}