import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import DetailPagesModel from '../Models/DetailPagesModel';

const textLabel = [
  'Description',
  'Title',
  'Link Color',
  'Text Color',
  'Title Color'
];

export default class ProductHubInforModel {
  constructor() {
    textLabel.map((item) => (this[item] = new SitecoreTextModel()));

    this['Image'] = new SitecoreImageModel();

    this['Mobile Image'] = new SitecoreImageModel();

    this['Anchor Link List'] = [];
  }

  getData(dataSource) {
    textLabel.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Image')) {
      this['Mobile Image'].getData(dataSource['Mobile Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Anchor Link List')) {
      if (dataSource['Anchor Link List']?.length) {
        dataSource['Anchor Link List'].map((item) => {
          this['Anchor Link List'].push(new DetailPagesModel().getData(item || {}));
        });
      }
    }

    return this;
  }
}
