import './ViewMyPrizesButton.scss';

import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import MyPrizesPopup from '@components/SpinAndWin/MyPrizesPopup';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import ViewMyPrizesButtonShimmer from './ViewMyPrizesButtonShimmer';

const ViewMyPrizesButton = ({ layoutData }) => {
  const myPrizesData = useSelector((state) => state.spinAndWinReducer.getMyPrizeLuckyDrawReducer.myPrizeLuckyDrawData) || [];
  const isLoading = useSelector((state) => state.spinAndWinReducer.getMyPrizeLuckyDrawReducer.isLoading);
  const [showPopup, setShowPopup] = useState(false);

  const handleClosePopup = (data) => setShowPopup(data);

  return isLoading 
    ? (
      <ViewMyPrizesButtonShimmer />
    ) 
    : myPrizesData.length > 0 
      ? (
        <div className='view-my-prizes'>
          <div className='container text-center'>
            <Text
              tag='span'
              className='view-my-prizes__btn btn-link-text'
              onClick={() => setShowPopup(true)}
              field={layoutData['View My Prizes Button Text']}
            />
          </div>
          <ModalComponent isShow={showPopup} onCloseHandler={() => setShowPopup(false)} titlePopup={layoutData?.['My Prizes Title']?.value}>
            <MyPrizesPopup
              applyOfferButtonText={layoutData?.['Apply Offer Button Text']?.value}
              myPrizesData={myPrizesData}
              onClosePopup={handleClosePopup}
              layoutData={layoutData}
            />
          </ModalComponent>
        </div>
      ) 
      : (
        ''
      );
};

ViewMyPrizesButton.propTypes = {
  layoutData: PropTypes.any
};

export default ViewMyPrizesButton;
