import produce from 'immer';

import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';

const initState = {
  isLoading: false,
  isSendCodeSuccess: null,
  isValidEmail: null,
  isChangeEmail: null,
  errMessage: null,
};

const forgotEmailReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myAccountTypes.EMAIL_VALIDATION_CODE:
      draft.isLoading = true;

      draft.isSendCodeSuccess = null;

      draft.errMessage = null;
      break;

    case myAccountTypes.EMAIL_VALIDATION_CODE_SUCCESS:
      draft.isLoading = false; 

      draft.isSendCodeSuccess = true;
      break;

    case myAccountTypes.EMAIL_VALIDATION_CODE_FAILED:
      draft.isLoading = false;

      draft.errMessage = action.error;
      break;

    case myAccountTypes.VALIDATE_EMAIL:
      draft.isLoading = true;

      draft.isValidEmail = null;

      draft.errMessage=null;
      break;
  
    case myAccountTypes.VALIDATE_EMAIL_SUCCESS:
      draft.isLoading = false; 
  
      draft.isValidEmail = true;
      break;
  
    case myAccountTypes.VALIDATE_EMAIL_FAILED:
      draft.isLoading = false; 
      
      draft.errMessage = action.error;
      break;

    case myAccountTypes.CHANGE_EMAIL: 
      draft.isLoading = true;

      draft.isChangeEmail = null;

      draft.errMessage = null;
      break;

    case myAccountTypes.CHANGE_EMAIL_SUCCESS:
      draft.isLoading = false; 
    
      draft.isChangeEmail = true;
      break;

    case myAccountTypes.CHANGE_EMAIL_FAILED: 
      draft.isLoading = false;

      draft.errMessage = action.error;
      break;
    }

    return draft;
  });

export default forgotEmailReducer;
