import './WellnessFilterTag.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const WellnessFilterTag = (props) => {
  const { label, tagList, activeTag, onChangeTagsFilterHandler } = props;

  const onClickTagHandler = (value) => {
    onChangeTagsFilterHandler(value);
  };

  return (
    tagList?.length > 0 && (
      <div className='wellness-filter-tag container smaller-container no-padding-right-mobile'>
        <Text className='wellness-filter-tag__text' tag='label' field={label} />
        <ul className='wellness-filter-tag__list'>
          {tagList.map((item, index) => {
            return (
              <li
                key={index}
                className={`wellness-filter-tag__list__item ${activeTag.includes(item.id) ? ' selected' : ''}`}
                onClick={() => onClickTagHandler(item.id || '')}
              >
                {item.fields?.Title?.value || ''}
              </li>
            );
          })}
        </ul>
      </div>
    )
  );
};

WellnessFilterTag.propTypes = {
  label: PropTypes.any,
  tagList: PropTypes.any,
  activeTag: PropTypes.any,
  onChangeTagsFilterHandler: PropTypes.func
};

export default WellnessFilterTag;
