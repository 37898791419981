import './PersonalizedOfferWidget.scss';

import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Share/Loading';
import { getLatestSOCartStart } from '@redux/actions/personalizeOffer/getLatestSOCart';
import { getLatestSOCartSelector } from '@redux/reducers/personalizeOffer/getLatestSOCartReducer/getLatestSOCartSelector';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import PersonalizedOfferWidgetModel from './models/PersonalizedOfferWidgetModel';
import ProductInfoSection from './ProductInfoSection';

const PersonalizedOfferWidget = ({ fields }) => {
  const dispatch = useDispatch();
  const { isLoading, data } = useSelector(getLatestSOCartSelector);
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new PersonalizedOfferWidgetModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    dispatch(getLatestSOCartStart());
  }, []);

  const cartsData = useMemo(() => {
    return data?.Carts;
  }, [data]);

  const latestCartData = useMemo(() => {
    return cartsData?.[0];
  }, [cartsData]);

  return layoutData ? (
    <div className='container personalize-offer-widget'>
      <div className='my-account-section'>
        <div className='my-account-section__heading animated-slow fadeIn delay-100 personalize-offer-widget__heading'>
          <div className='my-account-section__group'>
            <Text tag='h3' field={layoutData['Title']} className='my-account-section__heading__title' />
            {data?.Carts?.length ? (
              <Link field={layoutData?.['View All Button Link']} className='my-account-section__btn-link enable'>
                <Text field={layoutData?.['View All Button Text']} />
              </Link>
            ) : (
              <Text tag='span' field={layoutData?.['View All Button Text']} className='my-account-section__btn-link disable' />
            )}
          </div>
        </div>
        {!isLoading ? <ProductInfoSection layoutData={layoutData} cartData={latestCartData} /> : <Loading />}
      </div>
    </div>
  ) : (
    <></>
  );
};

PersonalizedOfferWidget.propTypes = {
  fields: object
};

export default PersonalizedOfferWidget;
