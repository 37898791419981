import * as clientTellingPortalCustomerTypes from '@redux/actions/clientTellingPortalCustomer/clientTellingPortalCustomerTypes';

import { GET_CREATE_PDPA_ERROR_CODE } from './clientTellingPortalCustomerTypes';

export const getCPCustomer = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_CP_CUSTOMER,
  payload
});

export const getCPCustomerSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_CP_CUSTOMER_SUCCESS,
  payload
});

export const getCPCustomerFailed = () => ({
  type: clientTellingPortalCustomerTypes.GET_CP_CUSTOMER_FAILED
});

export const searchCPCustomer = (payload) => ({
  type: clientTellingPortalCustomerTypes.SEARCH_CP_CUSTOMER,
  payload
});

export const searchCPCustomerSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.SEARCH_CP_CUSTOMER_SUCCESS,
  payload
});

export const searchCPCustomerFailed = () => ({
  type: clientTellingPortalCustomerTypes.SEARCH_CP_CUSTOMER_FAILED
});

export const getContactPersonalInformation = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_CONTACT_PERSONAL_INFORMATION,
  payload
});

export const getContactPersonalInformationSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_CONTACT_PERSONAL_INFORMATION_SUCCESS,
  payload
});

export const getContactPersonalInformationFailed = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_CONTACT_PERSONAL_INFORMATION_FAILED,
  payload
});

export const setIsActiveAccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.SET_IS_ACTIVE_ACCESS,
  payload
});

export const setIsDeactiveAccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.SET_IS_DEACTIVE_ACCESS,
  payload
});

export const setIsActiveQACustomerBasicProfile = (payload) => ({
  type: clientTellingPortalCustomerTypes.SET_IS_ACTIVE_QA_CUSTOMER_BASIC_PROFILE,
  payload
});

export const setIsActiveQACustomerLifestyle = (payload) => ({
  type: clientTellingPortalCustomerTypes.SET_IS_ACTIVE_QA_CUSTOMER_LIFESTYLE,
  payload
});

export const setIsActiveQAProductInterestListing = (payload) => ({
  type: clientTellingPortalCustomerTypes.SET_IS_ACTIVE_QA_PRODUCT_INTEREST_LISTING,
  payload
});

export const getQuickAccessLayoutService = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_QUICK_ACCESS_LAYOUT_SERVICE,
  payload
});

export const updateCPCustomerContact = (payload) => ({
  type: clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT,
  payload
});

export const updateCPCustomerContactSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_SUCCESS,
  payload
});

export const updateCPCustomerContactFailed = (payload) => ({
  type: clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_FAILED,
  payload
});

export const updateCPCustomerContactInterest = (payload) => ({
  type: clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_INTEREST,
  payload
});

export const updateCPCustomerContactInterestSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_INTEREST_SUCCESS,
  payload
});

export const updateCPCustomerContactInterestFailed = (payload) => ({
  type: clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_INTEREST_FAILED,
  payload
});

export const endSession = (payload) => ({
  type: clientTellingPortalCustomerTypes.END_SESSION,
  payload
});

export const endSessionSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.END_SESSION_SUCCESS,
  payload
});

export const endSessionFailed = (payload) => ({
  type: clientTellingPortalCustomerTypes.END_SESSION_FAILED,
  payload
});

export const storeCPCustomer = (payload) => ({
  type: clientTellingPortalCustomerTypes.STORE_CP_CUSTOMER,
  payload
});

export const revertCPCustomer = (payload) => ({
  type: clientTellingPortalCustomerTypes.REVERT_CP_CUSTOMER,
  payload
});

export const triggerPOSChangeQuestions = (payload) => ({
  type: clientTellingPortalCustomerTypes.TRIGGER_POS_CHANGE_QUESTIONS,
  payload
});

export const storeQuestionProductInProductInterest = (payload) => ({
  type: clientTellingPortalCustomerTypes.STORE_QUESTION_PRODUCT_IN_PRODUCT_INTEREST,
  payload
});

export const dirtyProductInterest = (payload) => ({
  type: clientTellingPortalCustomerTypes.DIRTY_PRODUCT_INTEREST,
  payload
});

export const getCustomerProducts = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_CUSTOMER_PRODUCTS,
  payload
});

export const getCustomerProductsSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_CUSTOMER_PRODUCTS_SUCCESS,
  payload
});

export const getCustomerProductsFailed = () => ({
  type: clientTellingPortalCustomerTypes.GET_CUSTOMER_PRODUCTS_FAILED
});

export const getCouponWallet = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_COUPON_WALLET,
  payload
});

export const getCouponWalletSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_COUPON_WALLET_SUCCESS,
  payload
});

export const getCouponWalletFailed = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_COUPON_WALLET_FAILED,
  payload
});

export const getOrderID = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_ORDER_ID,
  payload
});

export const getOrderIDSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_ORDER_ID_SUCCESS,
  payload
});

export const getOrderIDFailed = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_ORDER_ID_FAILED,
  payload
});

export const getLayoutQuestions = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_LAYOUT_QUESTIONS,
  payload
});

export const clearInformation = (payload) => ({
  type: clientTellingPortalCustomerTypes.CLEAR_INFORMATION,
  payload
});

export const keepLifeStyleData = () => ({
  type: clientTellingPortalCustomerTypes.KEEP_LIFE_STYLE_DATA
});

export const getCreatePDPAErrorCode = (payload) => ({
  type: clientTellingPortalCustomerTypes.GET_CREATE_PDPA_ERROR_CODE,
  payload
});

// remove product interest item
export const removeCpProductInterestStart = (payload) => ({
  type: clientTellingPortalCustomerTypes.REMOVE_CP_PRODUCT_INTEREST,
  payload
});

export const removeCpProductInterestSuccess = (payload) => ({
  type: clientTellingPortalCustomerTypes.REMOVE_CP_PRODUCT_INTEREST_SUCCESS,
  payload
});

export const removeCpProductInterestFailed = (payload) => ({
  type: clientTellingPortalCustomerTypes.REMOVE_CP_PRODUCT_INTEREST_FAILED,
  payload
});

