import './SelectStoreAndDate.scss';

import { any, bool, func } from 'prop-types';
import React, { useMemo, useRef } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useSelector } from 'react-redux';

import Message from '@components/Share/Message';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import PolicyCheckbox from './PolicyCheckbox';
import QuestionsSelect from './QuestionsSelect';
import SelectPreferDate from './SelectPreferDate';
import SelectStore from './SelectStore';
import SelectStoreAndDateContext from './SelectStoreAndDateContext';
import useSelectStoreAndDateFormik from './useSelectStoreAndDateFormik';

const SelectStoreAndDate = ({ layoutData, onSubmit, isOnUpdateScreen = false, isShowBtnCancel = false }) => {
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const isUpdateFailed = useSelector((state) => state.myAppointmentReducer.updateAppointmentReducer.isUpdateFailed);
  const statusCode = useSelector((state) => state.myAppointmentReducer.updateAppointmentReducer.statusCode);

  const handleGenerateErrorMessage = (statusCode) => {
    let messageError;
    if (statusCode && (statusCode !== 0 || statusCode !== 23)) {
      switch (statusCode) {
      case 11:
        messageError = <Message messageCode='Msg_037' />;
        break;

      case 13:
        messageError = <Message messageCode='Msg_039' />;
        break;

      case 15:
        messageError = <Message messageCode='Msg_036' />;
        break;

      default:
        messageError = <Message messageCode='Msg_042' />;
        break;
      }
    }

    return messageError;
  };

  const recaptchaRef = useRef(null);

  const handleSubmitForm = (values) => {
    values.captcha && recaptchaRef.current.reset();

    values.captcha &&
      setTimeout(() => {
        formik.setFieldValue('captcha', '');
      }, 1000);

    onSubmit(values);
  };
  const formik = useSelectStoreAndDateFormik(layoutData, handleSubmitForm);

  const isDisableFormSection = useMemo(() => {
    return !formik.values.storeId;
  }, [formik.values]);

  const handleDisableSubmitButton = useMemo(() => {
    let isDisable=false;
    if (layoutData?.appointment) {
      const bookedDate = new Date(layoutData?.appointment?.Date);
      const formikDate = formik.values.date;
      const storeId = layoutData?.appointment?.Store?.Id;

      isDisable = bookedDate.getTime() === formikDate.getTime() && storeId === formik.values.storeId;
    }

    return isDisable || isDisableFormSection;
  }, [layoutData?.appointment, formik.values.date, formik.values.storeId, isDisableFormSection]);


  return (
    <SelectStoreAndDateContext.Provider
      value={{
        layoutData: layoutData,
        formik: formik,
        isDisableFormSection: isDisableFormSection
      }}
    >
      <form onSubmit={formik.handleSubmit} className='select-store'>
        <Text className='select-store__title' field={layoutData?.title || layoutData?.['Select Store And Date Text']} tag='h3' />
        <div className='select-store__content'>
          <div className={`row ${isOnUpdateScreen ? 'justify-center' : ''}`}>
            {layoutData?.campaign?.Stores?.length ? (
              <>
                <SelectStore />
                <SelectPreferDate layoutData={layoutData} />
              </>
            ) : (
              <></>
            )}
            {!isOnUpdateScreen ? <QuestionsSelect /> : <></>}
          </div>
        </div>
        {!isOnUpdateScreen ? <PolicyCheckbox layoutData={layoutData} /> : <></>}
        {formik.values.allowCreateAccount ? (
          <div className='form-group form-group--captcha'>
            <ReCAPTCHA
              sitekey={settingGlobal['CaptchaPublicKey']}
              onChange={(value) => formik.setFieldValue('captcha', value || '')}
              ref={recaptchaRef}
            />
            {formik.errors.captcha && formik.touched.captcha && <span className='error-validate'>{formik.errors.captcha}</span>}
          </div>
        ) : (
          <></>
        )}
        {isUpdateFailed && <span className='error-message'>{handleGenerateErrorMessage(statusCode)}</span>}
        <div className='select-store__submit'>
          <button
            type='submit'
            className={['btn btn-primary', handleDisableSubmitButton ? 'btn-disable' : ''].join(' ')}
            disabled={handleDisableSubmitButton}
          >
            <Text field={layoutData?.registerButtonText || layoutData?.['Button Change Schedule Text']} />
          </button>
        </div>
        {isShowBtnCancel && (
          <div className='select-store__cancel'>
            <a
              href={`${layoutData?.['Button Appointment Details Link']?.value?.href}?campaign_id=${layoutData?.appointment?.Campaign?.Id}&appointment_id=${layoutData?.appointment?.Id}`}
              className='btn btn-link'
            >
              <Text field={layoutData?.['Button Cancel Text']} />
            </a>
          </div>
        )}
      </form>
      {formik.errors.storeId && formik.touched.storeId && <span className='error-validate'>{formik.errors.storeId}</span>}
    </SelectStoreAndDateContext.Provider>
  );
};

SelectStoreAndDate.propTypes = {
  layoutData: any,
  onSubmit: func,
  isOnUpdateScreen: bool,
  isShowBtnCancel: bool
};

export default SelectStoreAndDate;
