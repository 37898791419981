import SitecoreTextModel from '@models/SitecoreTextModel';

export default class JotFormModel {
  constructor() {
    this['Form Id'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Form Id')) {
      this['Form Id'].getData(dataSource['Form Id']);
    }

    return this;
  }
}