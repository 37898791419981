import { object } from 'prop-types';
import React from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';

import TradeInSpecialRewardsItem from '../TradeInSpecialRewardsItem/index';

const TradeInSpecialRewardsSwiper = ({ layoutData }) => {

  const swipeParams = {
    lazy: true,
    slidesPerView: 2.5,
    spaceBetween: 18,
    navigation: {
      nextEl: '.swiper-button.swiper-button-next',
      prevEl: '.swiper-button.swiper-button-prev'
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
        spaceBetween: 80,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 80,
      }
    }
  };

  return (
    <div className='trade-in-special__details-swiper'>
      <SwipeComponent param={swipeParams}>
        {layoutData?.['TradeInSpecialListing']?.map((item, idx) => (
          <div key={idx} className='trade-in-special__details-swiper__item'>
            <TradeInSpecialRewardsItem item={item} buyNowLink={layoutData?.['Buy Now Link']?.value?.href} buyNowText={layoutData?.['Buy Now Text']} />
          </div>
        ))}
      </SwipeComponent>
    </div>
  );
};

TradeInSpecialRewardsSwiper.propTypes = {
  layoutData: object
};

export default TradeInSpecialRewardsSwiper;