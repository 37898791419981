import React from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';

const CartListShimmer = () => {
  return (
    <div className='cart-detail'>
      <div className='cart-head-info skeleton__item'>
        <div className='container'>
          <div className='cart-head-info__container'>
            <h4 className='cart-head-info__heading'>
              <span className='text-line-center w-30'></span>
            </h4>
            <div className='cart-head-info__infor'>
              <span className='text-line-center w-50'></span>
              <span className='clear-5'></span>
              <span className='text-line-center w-80'></span>
            </div>
            <div className='cart-head-info__button'>
              <span className='text-line-heading-center w-30'></span>
              <span className='clear-5'></span>
              <div className='transparent-wrap'>
                <span className='text-line-heading-center w-30'></span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='cart-list skeleton__item'>
        <div className='container'>
          <LoadingShimmer itemNumb={4}>
            <div className='cart-list__item'>
              <div className='cart-item'>
                <div className='row'>
                  <div className='col-lg-6'>
                    <div className='image-wrap'>
                      <div className='image-line'></div>
                    </div>
                    <div className='clear-20 hidden-md-up'></div>
                  </div>
                  <div className='col-lg-6'>
                    <div className='cart-item-information'>
                      <div className='text-line-heading'></div>
                      <div className='clear-10'></div>
                      <div className='text-line-heading'></div>
                      <div className='clear-10'></div>
                      <div className='text-line-heading'></div>
                      <div className='clear-10'></div>
                      <div className='text-line-heading'></div>
                      <div className='clear-10 hidden-md-down'></div>
                      <div className='text-line-heading hidden-md-down'></div>
                      <div className='clear-10 hidden-md-down'></div>
                      <div className='text-line-heading hidden-md-down'></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </LoadingShimmer>
        </div>
      </div>
    </div>
  );
};

export default CartListShimmer;