import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Low Rate Title',
  'Product Quality',
  'Customer Service',
  'Delivery Service',
  'Cancel Button Text',
  'Content Remaining Characters',
  'Note Your Name Text',
  'Product Name Placeholder Text',
  'Product Serial Number Placeholder Text',
  'Recommend Question Text',
  'Review Content Placeholder Text',
  'Review Title Placeholder Text',
  'Submit Button Text',
  'Suggest Update Image Text',
  'Title Remaining Characters',
  'Yes Text',
  'No Text',
  'Your Name Placeholder Text',
  'Your Name Remaining Characters',
  'Remaining Characters Text',
  'Continue With Login Button Text',
  'My Products Button Text',
  'Review Title Minimum Character Message',
  'Review Title Minimum Character Number',
  'Your Name Minimum Character Message',
  'Your Name Minimum Character Number',
  'Submit Agreement'
];

const LINK_ARR = [
  'Review Submit Link',
  'Cancel Button Link',
  'My Products Button Link'
];

export default class ProductReviewModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}