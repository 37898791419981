import { ofType } from 'redux-observable';
import { from,of } from 'rxjs';
import { catchError,map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/buyingOptions';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import { get } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as configs from './config';

const getBuyingOptionsDataEpic = (action$) =>
  action$.pipe(
    ofType(buyingOptionsTypes.GET_BO_DATA),
    switchMap((action) => {
      const queryParams = new URLSearchParams(window.location.search);
      const params = {
        productPageId: action.productPageId,
        cartLineId: action.cartLineId,
        dataType: action.dataType,
        couponCode: queryParams?.get('cCode')
      };
      
      return from(get(configs.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getBuyingOptionsDataSuccess(deepCopy(res?.data) || {}, action.variantId);
          } else {

            return actions.getBuyingOptionsDataFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getBuyingOptionsDataFailed(error.message || 'Failed')))
      );
    })
  );

export default getBuyingOptionsDataEpic; 
