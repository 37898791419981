import produce from 'immer';

import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';

const initState = {
  isLoading: false,
  paymentData: null,
  redirectUrl: null,
  submitOrderStatus: null,
  orderStatus: null,
  submitOrderError: null
};

const paymentReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case checkoutProcessTypes.GET_BILLING_DATA_SUCCESS:
      draft.paymentData = action.payload;

      draft.paymentData.isEmpty = !draft.paymentData || 
      ((!draft.paymentData.PayInFulls || draft.paymentData.PayInFulls.length == 0) && (!draft.paymentData.PayInInstallments ||
        draft.paymentData.PayInInstallments.length == 0));
      break;

    case checkoutProcessTypes.GET_BALANCE_BILLING_DATA_SUCCESS:
      draft.paymentData = action.payload;

      draft.paymentData.isEmpty = !draft.paymentData || 
      ((!draft.paymentData.PayInFulls || draft.paymentData.PayInFulls.length == 0) && (!draft.paymentData.PayInInstallments ||
        draft.paymentData.PayInInstallments.length == 0));
      break;

    case checkoutProcessTypes.SUBMIT_ORDER:
      draft.isLoading = true;
      break;
    
    case checkoutProcessTypes.SUBMIT_ORDER_SUCCESS:
      draft.redirectUrl = action.payload;
      
      // draft.isLoading = false;
      break;
    
    case checkoutProcessTypes.SUBMIT_ORDER_FAILED:
      draft.submitOrderError = action.error;
      
      draft.isLoading = false;
      break;

    case checkoutProcessTypes.SUBMIT_ORDER_PAYBALANCE:
      draft.isLoading = true;
      break;
    
    case checkoutProcessTypes.SUBMIT_ORDER_PAYBALANCE_SUCCESS:
      draft.redirectUrl = action.payload;
      
      // draft.isLoading = false;
      break;
    
    case checkoutProcessTypes.SUBMIT_ORDER_PAYBALANCE_FAILED:
      draft.submitOrderError = action.error;
      
      draft.isLoading = false;
      break;

    case checkoutProcessTypes.GET_ORDER_PAYMENT_STATUS_SUCCESS:
      draft.orderStatus = action.payload;
      break;
   
    }

    return draft;
  });

export default paymentReducer;
