import produce from 'immer';

import * as settingGlobalURLTypes from '@redux/actions/settingGlobalURL/settingGlobalURLTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  settingGlobalURL: null,
  error: '',
  isLoading: false,
};

const settingGlobalURLReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case settingGlobalURLTypes.GET_SETTING_GLOBAL_URL:
      break;

    case settingGlobalURLTypes.GET_SETTING_GLOBAL_URL_SUCCESS:
      draft.settingGlobalURL = deepCopy(action.payload);
      break;
    
    case settingGlobalURLTypes.GET_SETTING_GLOBAL_URL_FAILED:
      draft.error = action.error;
    }

    return draft;
  });


export default settingGlobalURLReducer;
