import './StoreLocatorList.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';

import { setHoveredStoreItem } from '@redux/actions/storeLocator';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import StoreLocatorListItem from './StoreLocatorListItem';

const StoreLocatorList = (props) => {
  const [windowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();

  const onClickStoreHandler = (selectedStore) => {
    props.onClickStoreHandler(selectedStore, true);
  };

  // useEffect(() => {
  //   console.log('My: ', props.searchResultList);
  // }, [props.searchResultList]);

  return (
    props.searchResultList && (
      <div className='store-locator__content__list__store'>
        {props.searchResultList.length ? (
          <Fragment>
            {props.searchResultList.map((item) => {
              {
                if (windowWidth > 768) {
                  return (
                    <div className={`store-item${JSON.stringify(item) === JSON.stringify(props.selectedTab) ? ' store-item--activated--hovered' : JSON.stringify(item) === JSON.stringify(props.hoveredTab) ? ' store-item--hovered' : '' }`}
                      key={item.ItemId}
                      onClick={() => onClickStoreHandler(item)}
                      onMouseEnter={() => dispatch(setHoveredStoreItem(item))}
                      onMouseLeave={() => dispatch(setHoveredStoreItem(null))}
                      id={item.ItemId}
                    >
                      <StoreLocatorListItem data={item} dataFromLayout={props.dataFromLayout} />
                    </div>
                  );
                } else {
                  return (
                    <div className={`store-item${JSON.stringify(item) === JSON.stringify(props.selectedTab) ? ' store-item--activated--hovered' : JSON.stringify(item) === JSON.stringify(props.hoveredTab) ? ' store-item--hovered' : '' }`}
                      key={item.ItemId}
                      onClick={() => onClickStoreHandler(item)}
                      id={item.ItemId}
                    >
                      <StoreLocatorListItem data={item} dataFromLayout={props.dataFromLayout} />
                    </div>
                  );
                }
              }
            })}
          </Fragment>
        ) : (
          <span>{props.errMsgObj['Msg_007']}</span>
        )}
      </div>
    )
  );
};

StoreLocatorList.propTypes = {
  onClickStoreHandler: PropTypes.any,
  selectedTab: PropTypes.object,
  hoveredTab: PropTypes.object,
  searchResultList: PropTypes.array,
  dataFromLayout: PropTypes.object,
  errMsgObj: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    searchResultList: state.storeLocatorReducer.allStoreReducer.searchResultList || [],
    selectedTab: state.storeLocatorReducer.allStoreReducer.selectedTab || {},
    hoveredTab: state.storeLocatorReducer.allStoreReducer.hoveredTab || {},
    errMsgObj: state.getMessageReducer.objMessages || {}
  };
};

export default connect(mapStateToProps)(StoreLocatorList);
