import './MyWishlistWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {behaviorSubjectWishlist$} from '@components/Product/MyWishlist/MyWishlistBehavior';
import Loading from '@components/Share/Loading';
import { getProductWishlist } from '@redux/actions/product';
import {Link,RichText,Text} from '@sitecore-jss/sitecore-jss-react';

import MyWishlistWidgetModel from './models/MyWishlistWidgetModel';
const NUMBER_WISHLIST = '{num}';

const MyWishlistWidget = ({fields}) => {
  const [dataSources, setDatasources] = useState(null);
  
  const wishListProducts = useSelector((state) => state.productReducer.productWishlistReducer?.listProductWishlist || []);
  const isLoading = useSelector((state) => state.productReducer.productWishlistReducer?.isGetWishListLoading);
  const dispatch = useDispatch();

  useEffect(() => {
    setDatasources(new MyWishlistWidgetModel().getData(fields || {}));
    
    behaviorSubjectWishlist$.next({isAlreadyWishList: true});

    dispatch(getProductWishlist({getFull: true}));
    
  }, []);

  return dataSources && !isLoading ? (
    <div className='my-account-section wishlist-widget'>
      <div className='my-account-section__heading wishlist-widget__heading animated-slow fadeIn delay-100'>
        <div className='my-account-section__group'>
          <h3 className='my-account-section__heading__title'><Text field={dataSources['Title']}/></h3>
          {wishListProducts.length ? dataSources['View All Text'].value ? (
            <Link className='my-account-section__btn-link' field={dataSources['View All Link']}>
              <Text field={dataSources['View All Text']} />
            </Link>
          ) : (
            <Link className='my-account-section__btn-link' field={dataSources['View All Link']} />
          ) : (
            <span className='my-account-section__btn-link disable'><Text field={dataSources['View All Text']} /></span>
          )}
        </div>
        <p className='my-account-section__heading__label'>
          {wishListProducts?.length ? <RichText field={{...dataSources['Product Summary Label'], value: dataSources['Product Summary Label'].value.replace(NUMBER_WISHLIST, wishListProducts.length)}}/> : <Text field={dataSources['You Have 0 Products In Your Wishlist Label']}/>}
        </p>
      </div>
      {wishListProducts.length ?
        <div className='my-account-section__content wishlist-widget__content animated-slow fadeIn delay-100'>
          <div className='wishlist-widget__content__product-info'>
            <div className='wishlist-widget__content__product-info__image'>
              <img src={wishListProducts[0].Product.Image}/>
            </div>
            <div className='wishlist-widget__content__product-info__text'>
              <h4 className='wishlist-widget__content__product-info__name'>{wishListProducts[0].Product.ProductName} </h4>
              {/* <h4 className='wishlist-widget__content__product-info__color'>(New Colours)</h4> */}
              {wishListProducts[0]?.Product?.IsDeposit ? (
                <span className='price--selling'>{wishListProducts[0]?.Product?.Deposit?.DepositPriceWithCurrency}</span>
              ) : (
                <div className='wishlist-widget__content__product-info__price'>
                  <span className='price--selling'>{wishListProducts[0].Product.Price.SellingPriceWithCurrency}</span>
                  {wishListProducts[0].Product.Price.SellingPriceWithCurrency !== wishListProducts[0].Product.Price.ListPriceWithCurrency && (
                    <span className='price--old'>{wishListProducts[0].Product.Price.ListPriceWithCurrency}</span>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* <p className='my-account-section__heading__label my-account-section__heading__label--mobile d-mobi'> */}
          {/* <Text field={dataSources['Product Summary Label']}/> */}
          {/* <Text field={{...dataSources['Product Summary Label'], value: dataSources['Product Summary Label'].value.replace(NUMBER_WISHLIST, wishListProducts.length)}}/>
          </p> */}
        </div> : 
        <div className='my-account-section__content my-account-section__content--empty animated-slow fadeIn delay-100'>
          {dataSources['Explore Osim Products Text'].value ? (
            <Link className='my-account-section__content__link-explore' field={dataSources['Explore Osim Products Link']}>
              <Text field={dataSources['Explore Osim Products Text']} />
            </Link>
          ) : (
            <Link className='my-account-section__content__link-explore' field={dataSources['Explore Osim Products Link']} />
          )}
        </div>}
    </div>
  ) : (
    <div className='my-account-section wishlist-widget skeleton__item'>
      <div className='my-account-section__heading transparent-wrap'>
        <div className='my-account-section__group'>
          <span className='text-line'></span>
          <span className='clear-10'></span>
          <span className='text-line'></span>
        </div>
        <p className='my-account-section__heading__label'>
          <span className='text-line'></span>
        </p>
      </div>
      <div className='my-account-section__content wishlist-widget__content transparent-wrap'>
        <div className='wishlist-widget__content__product-info'>
          <div className='wishlist-widget__content__product-info__image image-wrap'>
            <span className='image-line'></span>
          </div>
          <div className='wishlist-widget__content__product-info__text'>
            <span className='text-line'></span>
            <span className='clear-10'></span>
            <span className='text-line'></span>
            <span className='clear-10'></span>
            <span className='text-line'></span>
          </div>
        </div>
      </div>
      <Loading></Loading>
    </div>
  );
};

MyWishlistWidget.propTypes = {
  fields: PropTypes.object,
};



export default MyWishlistWidget;
