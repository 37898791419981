import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { useDisplayFormikMessage } from '../../../hooks/useDisplayFormikMessage';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { useScheduleAppointmentFormContext } from '../index';

const SelectGuest = () => {
  const { layoutData } = useScheduleAppointmentContext();
  const { formik } = useScheduleAppointmentFormContext();
  const displayFormikMessage = useDisplayFormikMessage();

  return (
    <div
      className={`schedule-experience__body-item__guest-select ${
        !(formik.values.date && formik.values.storeId) ? 'be-schedule-appointment__disable-form' : ''
      }`}
    >
      <Text className='select-store__content__list__title' tag='h4' field={layoutData['Number Of Guests Label']} />
      <div className='schedule-experience__body-item__guest-select__wrapper'>
        {[...Array(2)].map((_, index) => {
          const itemValue = index + 1;
          const itemId = `optionScheduleGuest-${index}`;

          return (
            <div key={index} className='select-store__date__option__list__item'>
              <div className='form-group'>
                <input
                  id={itemId}
                  value={itemValue}
                  type={'radio'}
                  checked={formik.values.guest === itemValue}
                  name='optionScheduleGuest'
                  onChange={(event) => formik.setFieldValue('guest', +event.target.value)}
                />
                <Text
                  field={index === 0 ? layoutData['Just Me Button Text'] : layoutData['Me Plus1 Placeholder Text']}
                  tag='label'
                  htmlFor={itemId}
                />
              </div>
            </div>
          );
        })}
      </div>
      <span className='error-validate'>{displayFormikMessage(formik, 'guest')}</span>
    </div>
  );
};

export default SelectGuest;
