export default class PromotionCartApiModel {
  constructor() {
    this.ExternalCartLineId = '';

    this.Catalog = '';

    this.Image = '';

    this.DisplayName = '';

    this.ProductUrl = '';

    this.Quantity = '';

    this.LinePrice = '';

    this.LineFinalPrice = '';

    this.LineTotal = '';

    this.LineFinalPrice = '';

    this.LineDiscount = '';

    this.LineDiscountAmount = 0;

    this.ProductId = '';

    this.PromotionCartLines = [];

    this.Type = '';

    this.VariantId = '';

    this.DisplayName = '';

    this.ItemId = '';

    this.LineUniqueId = '';

    this.Slot = '';

    this.LineDiscountAmount = 0;

    this.LineFinalTotalAmount = 0;

    this.RelationshipId = '';

    this.TradeInImage = '';

    this.TradeInProductBrand = '';

    this.TradeInProductName = '';

    this.TradeInProductModel = '';

    this.TradeInProductPrice = '';

    this.LinePriceWithQuantity = '';

    this.LinePriceWithQuantityAmount = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExternalCartLineId')) {
      this.ExternalCartLineId = dataSource.ExternalCartLineId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image = dataSource.Image;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayName')) {
      this.DisplayName = dataSource.DisplayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductUrl')) {
      this.ProductUrl = dataSource.ProductUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Quantity')) {
      this.Quantity = dataSource.Quantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LinePrice')) {
      this.LinePrice = dataSource.LinePrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineFinalPrice')) {
      this.LineFinalPrice = dataSource.LineFinalPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineTotal')) {
      this.LineTotal = dataSource.LineTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineDiscount')) {
      this.LineDiscount = dataSource.LineDiscount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineDiscountAmount')) {
      this.LineDiscountAmount = dataSource.LineDiscountAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this.ProductId = dataSource.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionCartLines')) {
      this.PromotionCartLines = dataSource.PromotionCartLines;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Type')) {
      this.Type = dataSource.Type;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayName')) {
      this.DisplayName = dataSource.DisplayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantId')) {
      this.VariantId = dataSource.VariantId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineUniqueId')) {
      this.LineUniqueId = dataSource.LineUniqueId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Catalog')) {
      this.Catalog = dataSource.Catalog;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Slot')) {
      this.Slot = dataSource.Slot;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineDiscountAmount')) {
      this.LineDiscountAmount = dataSource.LineDiscountAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LineFinalTotalAmount')) {
      this.LineFinalTotalAmount = dataSource.LineFinalTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RelationshipId')) {
      this.RelationshipId = dataSource.RelationshipId ?? this.RelationshipId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TradeInImage')) {
      this.TradeInImage =
        dataSource.TradeInImage?.split(';')?.map((url) => ({
          url
        })) ?? this.TradeInImage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TradeInProductBrand')) {
      this.TradeInProductBrand = dataSource.TradeInProductBrand ?? this.TradeInProductBrand;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TradeInProductName')) {
      this.TradeInProductName = dataSource.TradeInProductName ?? this.TradeInProductName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TradeInProductModel')) {
      this.TradeInProductModel = dataSource.TradeInProductModel ?? this.TradeInProductModel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TradeInProductPrice')) {
      this.TradeInProductPrice = dataSource.TradeInProductPrice ?? this.TradeInProductPrice;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LinePriceWithQuantity')) {
      this.LinePriceWithQuantity = dataSource.LinePriceWithQuantity ?? this.LinePriceWithQuantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LinePriceWithQuantityAmount')) {
      this.LinePriceWithQuantityAmount = dataSource.LinePriceWithQuantityAmount ?? this.LinePriceWithQuantityAmount;
    }

    return this;
  }
}
