import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'When And Where Text',
  'About The Event Text',
  'Register Title',
  'Button Label',
  'Date Of Birth Label',
  'Email Label',
  'First Name Label',
  'Last Name Label',
  'Phone Label',
  'Title',
  'Title Detail',
  'Link Back Label',
  'Register Button Label',
  'Select Date Label',
  'Select Location Label',
  'Select Number Label',
  'Select Time Label',
  'Q And A Label',
  'Just Me Label',
  'Just Me Value',
  'Me Plus Label',
  'Me Plus Value',
  'Error Message Text',
  'Expired Message Text',
  'Help Message Text',
  'Help Link Text',
  'Not Available Message Text'
];
const LINK_ARR = ['Link Back Url', 'Help Link Url'];

export class EventRegistrationModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['EventDetails'] = null;

    this['UserDetails'] = {};
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'EventDetails')) {
      this['EventDetails'] = deepCopy(dataSource['EventDetails']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'UserDetails')) {
      this['UserDetails'] = deepCopy(dataSource['UserDetails']);
    }

    return this;
  }
}
