import { useCallback } from 'react';

import useMapUniqueProductFunction from './useMapUniqueProductFunction';

const useMapListProductFunctionData = () => {
  const { uniqueProductFunctionData } = useMapUniqueProductFunction();

  return useCallback(
    (productFunctionData) => {
      const productFunctionDataTemp = { ...productFunctionData };
      let productFunctionDataResult = [];
      const uniqueProductFunctionTemp = { ...uniqueProductFunctionData };

      for (const [key, uniqueProductFuncValue] of Object.entries(uniqueProductFunctionTemp)) {
        const productFunctionItemExist = { ...productFunctionDataTemp.productFunction.find((item) => item.Id === key) };
        if (Object.keys(productFunctionItemExist)?.length) {
          for (const item of uniqueProductFuncValue) {
            if (!productFunctionItemExist.Functions.find((functionItem) => functionItem.Id === item.Id)) {
              productFunctionItemExist.Functions = [...productFunctionItemExist.Functions, item];
            }
          }

          const indexOfProductFunctionItem = productFunctionDataTemp.productFunction.findIndex((item) => item.Id === productFunctionItemExist.Id);

          productFunctionDataResult[indexOfProductFunctionItem] = { ...productFunctionItemExist };
        }
      }

      return productFunctionDataResult;
    },
    [uniqueProductFunctionData]
  );
};

export default useMapListProductFunctionData;
