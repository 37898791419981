import produce from 'immer';

import * as commonTypes from '@redux/actions/common/commonTypes';
import { deepCopy } from '@utils/utility';

import PoliciesItemApiModel from '../models/PoliciesItemApiModel';

const initState = {
  policySearchData: [],
  policySuggestionList: [],
  policyCount: 0,
  errorMsg: '',
  isError: false,
  isLoading: false,
  isAutoCompleteLoading: false,
  params: null
};

const policySearchReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let newPolicySearchData = [];

    switch (action.type) {
    // SEARCH
    case commonTypes.COMMON_SEARCH_POLICY:
      draft.params = action.params;

      draft.isLoading = true;
      break;

    case commonTypes.COMMON_SEARCH_POLICY_SUCCESS:
      if (action.payload) {
        action.payload.map(item => {
          newPolicySearchData.push(new PoliciesItemApiModel().getData(item || {}));
        });

        draft.policySearchData = deepCopy(newPolicySearchData);
      }

      draft.policyCount = action.count;

      draft.isLoading = false;
      break;
    
    case commonTypes.COMMON_SEARCH_POLICY_FAILED:
      draft.isError = true;

      draft.errorMsg = action.error;

      draft.isLoading = false;

      break;

    // AUTO COMPLETE
    case commonTypes.GET_AUTO_COMPLETE_POLICY:

      draft.isAutoCompleteLoading = true;

      break;

    case commonTypes.GET_AUTO_COMPLETE_POLICY_SUCCESS:
      draft.policySuggestionList = action.payload;

      draft.isAutoCompleteLoading = false;

      break;
    
    case commonTypes.GET_AUTO_COMPLETE_POLICY_FAILED:
      // draft.isError = true;

      // draft.errorMsg = action.error;

      draft.isAutoCompleteLoading = false;

      break;
    }

    return draft;
  });

export default policySearchReducer;
