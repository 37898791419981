import './CPDashboard.scss';

import React from 'react';

import AppointmentsListing from '../Appointments/AppointmentListing';
import CPPageHeader from '../CPPageHeader';
import DashboardGroupLinks from './DashboardGroupLinks';

const CPDashboard = () => {
  return (
    <div className='dashboard'>
      <CPPageHeader type='date'></CPPageHeader>
      <DashboardGroupLinks />
      <div className='container tablet-container'>
        <h2 className='dashboard__title'>Appointments</h2>
      </div>
      <AppointmentsListing></AppointmentsListing>
    </div>
  );
};

export default CPDashboard;
