import './RecentlyViewedProducts.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import LoginRegisterModal from '@components/CategoryLanding/LoginRegisterModal';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import SwipeComponent from '@components/Share/SwipeComponent';
import ToggleWisList from '@components/Share/ToggleWisList';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import RecentlyViewedProductsModel from './model/RecentlyViewedProductsModel';
import RecentlyViewedProductItem from './RecentlyViewedProductItem';
import RecentlyViewedProductsShimmer from './RecentlyViewedProductsShimmer';

const RecentlyViewedProducts = (props) => {
  const [inputData, setInputData] = useState(null);
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false);
  const [urlLoginPage, setUrlLoginPage] = useState({});

  useEffect(() => {
    const recentlyViewedProducts = new RecentlyViewedProductsModel();

    setInputData(recentlyViewedProducts.getData(deepCopy(props.fields || {})));
  }, []);

  const params = {
    lazy: true,
    rebuildOnUpdate: true,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 18,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 40,
        navigation: {
          nextEl: '.recently-viewed-products .swiper-button-next',
          prevEl: '.recently-viewed-products .swiper-button-prev'
        }
      }
    }
  };

  const handleClosePopupLogin = () => setIsShowLoginPopup(false);
  
  const handleOpenPopupLogin = (buyNowLink) => {
    setIsShowLoginPopup(true);
    let tempLink = deepCopy(buyNowLink);
    
    tempLink.value.href = `${inputData?.['Deposit Login Button Link']?.value?.href}?from=${buyNowLink.value.href}`;

    setUrlLoginPage(tempLink);
  };

  return inputData ? (
    <Fragment>
      {inputData['Product Field'].length ? (
        <div className='recently-viewed-products animated-slow fadeIn delay-100'>
          <div className='container'>
            <Text field={inputData['Title']} tag='h2' editable={true} className='recently-viewed-products__title experience-title text-center' />
            <div className='recently-viewed-products__swiper'>
              {inputData['Product Field'] ? (
                <Fragment>
                  <SwipeComponent param={params}>
                    {inputData['Product Field'].map((item, index) => {
                      if (item.ProductId && item.ProductName) {
                        return (
                          <div className='swiper-slide recently-viewed-products__contents' key={index}>
                            <RecentlyViewedProductItem dataFromAPI={item} dataFromLayout={inputData} indexGA4={index} handleOpenPopupLogin={handleOpenPopupLogin} />
                            <ToggleWisList
                              dataProduct={{
                                ...item,
                                itemListName: inputData?.['Title']?.value,
                                indexGA4: index
                              }}
                            />
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </SwipeComponent>
                  {/* <div className='swiper-button-next'></div>
                  <div className='swiper-button-prev'></div> */}
                </Fragment>
              ) : (
                ''
              )}
            </div>
          </div>
          <LoginRegisterModal 
            isShow={isShowLoginPopup} 
            onCloseHandler={handleClosePopupLogin} 
            dataLayout={inputData} 
            urlLoginPage={urlLoginPage} 
          />
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <RecentlyViewedProductsShimmer></RecentlyViewedProductsShimmer>
    </LoadingShimmer>
  );
};

RecentlyViewedProducts.propTypes = {
  fields: PropTypes.object,
  recentlyViewedProductsListStore: PropTypes.array
};

export default RecentlyViewedProducts;
