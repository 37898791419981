import PropTypes from 'prop-types';
import React from 'react';

const AutoCompleteSuggestionList = ({index, data, onSelectItem, isHighlighted}) => {

  return (
    <li 
      onClick={onSelectItem}
      className={'header__search__suggestions__item' + `${isHighlighted ? ' highlight' : ''}`}
      style={{ transitionDelay: 0.3 + index * 0.02 + 's' }}
    >
      <span>{data}</span>
    </li>
  );
};

AutoCompleteSuggestionList.propTypes = {
  index: PropTypes.any,
  data: PropTypes.any,
  onSelectItem: PropTypes.any,
  isHighlighted: PropTypes.any,
};

export default AutoCompleteSuggestionList;