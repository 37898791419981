import {
  HIDE_DELIVERY_PACKAGES_SECTION,
  HIDE_SELECTING_FREE_GIFT_POPUP,
  SHOW_DELIVERY_PACKAGES_SECTION,
  SHOW_SELECTING_FREE_GIFT_POPUP
} from './controlSelectingFreeGiftPopupTypes';

export const showSelectingFreeGiftPopup = () => ({
  type: SHOW_SELECTING_FREE_GIFT_POPUP,
  payload: true
});

export const hideSelectingFreeGiftPopup = () => ({
  type: HIDE_SELECTING_FREE_GIFT_POPUP,
  payload: false
});

export const showDeliveryPackagesSection = () => ({
  type: SHOW_DELIVERY_PACKAGES_SECTION,
  payload: true
});

export const hideDeliveryPackagesSection = () => ({
  type: HIDE_DELIVERY_PACKAGES_SECTION,
  payload: false
});
