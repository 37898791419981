import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import BuyNowLinkModel from './BuyNowLinkModel';
import SearchSettingModel from './SearchSettingModel';

export default class ProductListingModel {
  constructor() {
    this['Apply Filter Text'] = new SitecoreTextModel();

    this['Buy Now Link'] = new BuyNowLinkModel();

    this['Clear Filter Text'] = new SitecoreTextModel();

    this['Filter Text'] = new SitecoreTextModel();

    this['Filter Title'] = new SitecoreTextModel();

    this['Learn More Text'] = new SitecoreTextModel();

    this['No Result Text'] = new SitecoreTextModel();

    this['Result Message'] = new SitecoreTextModel();

    this['Search Settings'] = new SearchSettingModel();

    this['Sort By Label'] = new SitecoreTextModel();

    this['Booking Experience Button Text'] = new SitecoreTextModel();
    
    this['Booking Experience Button Link'] = new SitecoreLinkModel();

    //Missing

    this['Price Range Label'] = new SitecoreTextModel();

    this['Colors Label'] = new SitecoreTextModel();

    this['Features And Functions Label'] = new SitecoreTextModel();

    this['Deposit Button Text'] = new SitecoreTextModel();

    this['Deposit Login Button Text'] = new SitecoreTextModel();

    this['Deposit Login Button Link'] = new SitecoreLinkModel();
  }
  getData(dataSource) {
    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Button Text')) {
      this['Deposit Button Text'].getData(dataSource['Deposit Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Login Button Text')) {
      this['Deposit Login Button Text'].getData(dataSource['Deposit Login Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit Login Button Link')) {
      this['Deposit Login Button Link'].getData(dataSource['Deposit Login Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Apply Filter Text')) {
      this['Apply Filter Text'].getData(dataSource['Apply Filter Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Link')) {
      this['Buy Now Link'].getData(dataSource['Buy Now Link'].fields);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Clear Filter Text')) {
      this['Clear Filter Text'].getData(dataSource['Clear Filter Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter Text')) {
      this['Filter Text'].getData(dataSource['Filter Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter Title')) {
      this['Filter Title'].getData(dataSource['Filter Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Text')) {
      this['Learn More Text'].getData(dataSource['Learn More Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'No Result Text')) {
      this['No Result Text'].getData(dataSource['No Result Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Result Message')) {
      this['Result Message'].getData(dataSource['Result Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Search Settings')) {
      this['Search Settings'].getData(dataSource['Search Settings'].fields);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sort By Label')) {
      this['Sort By Label'].getData(dataSource['Sort By Label']);
    }
    //Missing
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price Range Label')) {
      this['Price Range Label'].getData(dataSource['Price Range Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Colors Label')) {
      this['Colors Label'].getData(dataSource['Colors Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price Range Label')) {
      this['Features And Functions Label'].getData(dataSource['Features And Functions Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Booking Experience Button Text')) {
      this['Booking Experience Button Text'].getData(dataSource['Booking Experience Button Text']);
    }
    
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Booking Experience Button Link')) {
      this['Booking Experience Button Link'].getData(dataSource['Booking Experience Button Link']);
    }

    return this;
  }
}
