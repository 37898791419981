export const MILLISECOND_PER_DAY = 86400000;

export const ALLSTOREID = 'AllStores';

export const EXPIRYDATE = 'expiryDate';

export const DATE = 'date';

export const SALESORDERVALUE = 'salesOrderValue';

export const SALESTAFF = 'saleStaff';

export const STORE = 'store';

export const STATUS = 'status';

export const CATEGORY = 'category';

export const EXPIRED_DATE_TODAY_ID = 'EXPIRED_DATE_TODAY_ID';

export const EXPIRED_DATE_THIS_WEEK_ID = 'EXPIRED_DATE_THIS_WEEK_ID';

export const EXPIRED_DATE_THIS_MONTH_ID = 'EXPIRED_DATE_THIS_MONTH_ID';

export const EXPIRED_DATE_CUSTOMIZE_ID = 'EXPIRED_DATE_CUSTOMIZE_ID';