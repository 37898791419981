import './DeliverySpecialRewards.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';

import DeliverySpecialRewardsModel from './model/DeliverySpecialRewardsModel';

const DeliverySpecialRewards = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    let model = new DeliverySpecialRewardsModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    layoutData['Delivery Special Options']?.length ? (
      <div className='container my-reward__box delivery-special' id={layoutData?.['Anchor ID']?.value}>
        <Text tag='h3' field={layoutData['Title']} className='my-reward__box-title' />
        <div className='my-reward__box-wrapper'>
          {layoutData['Delivery Special Options'].map(({ id, fields }) => (
            <div key={id} className='my-reward__box-item'>
              <div className='my-reward__box-item__description'>
                <div className='my-reward__box-item__description-icon'>
                  <div className='icon-wrapper'>
                    <Image field={fields['Icon']} />
                  </div>
                </div>
                <div className='my-reward__box-item__description-detail'>
                  <Text tag='h4' field={fields['Title']} className='title' />
                  <Text tag='h5' field={fields['Content']} className='content' />
                </div>
              </div>
              <div className='my-reward__box-item__cta'>
                {fields['Button Link']?.value?.url ? (
                  <a href={fields['Button Link']?.value?.url} className='osim-account-form__form__btn__item btn btn-primary'>
                    <Text field={fields['Button Label']} />
                  </a>
                ) : (
                  <Link field={fields['Button Link']} className='osim-account-form__form__btn__item btn btn-primary'>
                    <Text field={fields['Button Label']} />
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    ) : (
      <></>
    )
  ) : (
    <></>
  );
};

DeliverySpecialRewards.propTypes = {
  fields: object
};

export default DeliverySpecialRewards;
