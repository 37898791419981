import './RelatedSpecialDeal.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SwipeComponent from '@components/Share/SwipeComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import ProductSpecialDealsModel from './model/ProductSpecialDealsModel';
import ProductSpecialDealsItem from './ProductSpecialDealsItem';

const RelatedSpecialDeal = (props) => {
  const [inputData, setInputData] = useState(null);
  const componentId = (props.params && props.params['Component Id']) || '';
  const newTab = props.params && props.params['Open In'] !== '' ? props.params['Open In'] !== 'Current Tab' : true;
  const [currProduct, setCurrProduct] = useState(null);

  const params = {
    lazy: true,
    slidesPerView: 2.5,
    // centeredSlides: false,
    spaceBetween: 18,
    // shouldSwiperUpdate: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    navigation: {
      nextEl: '.product-deals__list .swiper-button-next',
      prevEl: '.product-deals__list .swiper-button-prev'
    },
    breakpoints: {
      768: {
        slidesPerView: 4,
        spaceBetween: 40
      },

      1200: {
        slidesPerView: 4,
        spaceBetween: 100,
      },

      1600: {
        slidesPerView: 4,
        spaceBetween: 150,
      }
    }
  };

  useEffect(() => {
    const productSpecialDeals = new ProductSpecialDealsModel();

    setInputData(productSpecialDeals.getData(deepCopy(props.fields || {})));
  }, []);

  useDidUpdateEffect(() => {
    let temp = deepCopy(inputData.Description);

    temp.value = temp.value.replace('{product-name}', inputData['Current Product']);

    setCurrProduct(deepCopy(temp));
  }, [inputData]);

  return (
    inputData &&
    inputData.SpecialDeal.length > 0 &&
    currProduct && (
      <div className='product-deals' id={componentId}>
        <div className='container'>
          <div className='product-deals__title'>
            <Text field={inputData['Title']} tag='h2' editable={true} />
          </div>
          <div className='product-deals__description'>
            <Text field={currProduct} tag='p' editable={true} />
          </div>
          <div className='product-deals__list'>
            <SwipeComponent param={params}>
              {inputData.SpecialDeal.map((item, index) => {
                return (
                  <div className='product-deals__list__item' key={item.Id}>
                    <ProductSpecialDealsItem dataFromLayout={item} dataFields={inputData} isShowBuyNow={props.params} newTab={newTab} indexGA4={index}/>
                  </div>
                );
              })}
            </SwipeComponent>
            {/* <div className='swiper-button-next'></div>
            <div className='swiper-button-prev'></div> */}
          </div>
        </div>
      </div>
    )
  );
};

RelatedSpecialDeal.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.any,
  productSpecialDealsList: PropTypes.any
};

const mapStateToProps = (state) => {
  return {
    productSpecialDealsList: state.productReducer.specialDealReducer?.specialDeals || null
  };
};

export default connect(mapStateToProps)(RelatedSpecialDeal);
