import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';

export default class FieldsItemModel {
  constructor() {
    this.Image = new SitecoreImageModel();

    this.TargetUrl = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TargetUrl')) {
      this['TargetUrl'].getData(dataSource['TargetUrl']);
    }

    return this;
  }
}