
import React, { useState } from 'react'
import PropTypes from 'prop-types';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';
import { PARAMS_SUBMIT } from '@components/CategoryLanding/ProductListing/config';
import './FeaturesAndFunctionPopup.scss'
const FeaturesAndFunctionPopup = ({ closeModal, features, isToogleClearFilter, setSelectFilterEvt, filterFeatureCurrent }) => {
	const [featureFilter, setFeature] = useState(filterFeatureCurrent?.length ? filterFeatureCurrent : []);
	const onToogleFeatureHandler = (event) => {
		const featureFilterCopy = deepCopy(featureFilter);
		if (event.currentTarget.checked) {
			featureFilterCopy.push(event.currentTarget.value);

			setFeature(featureFilterCopy);
		} else {
			const newFeatureFilter = featureFilterCopy.filter(value => value !== event.currentTarget.value);

			setFeature(newFeatureFilter);
		}
	};

	useDidUpdateEffect(() => {

		setSelectFilterEvt({ key: PARAMS_SUBMIT.Features, value: featureFilter });
	}, [featureFilter]);

	useDidUpdateEffect(() => {
		setFeature([]);
	}, [isToogleClearFilter]);

	return features?.Details.length ? (
		<div className='filter-popup__section__checkbox-list'>
			<ul className='filter-popup__section__checkbox-list__group'>
				{features.Details.map((feature, featureIdx) => (
					<li className='filter-popup__section__checkbox-item' key={featureIdx + '1'}>
						<div className='osim-checkbox'>
							<input className='osim-checkbox-input' checked={featureFilter.includes(feature.Value) ? true : false} value={feature.Value} onChange={(event) => onToogleFeatureHandler(event)} type='checkbox' id={`osim-checkbox-${featureIdx + "aaa"}`} />
							<label className='osim-checkbox-label' htmlFor={`osim-checkbox-${featureIdx + "aaa"}`}>{feature.Value}</label>
						</div>
					</li>
				))}
			</ul>
		</div>
	) : null;
}

FeaturesAndFunctionPopup.propTypes = {
	closeModal: PropTypes.any,
	features: PropTypes.any,
	filterFeatureCurrent: PropTypes.any,

	isToogleClearFilter: PropTypes.bool,
	setSelectFilterEvt: PropTypes.func,
};

export default FeaturesAndFunctionPopup