import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'Title',
  'Anchor ID'
];

export default class DeliverySpecialRewardsModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Delivery Special Options'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Special Options')) {
      this['Delivery Special Options'] = deepCopy(dataSource['Delivery Special Options']);
    }

    return this;
  }
}
