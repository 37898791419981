import { any, bool, exact, number, object, string } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Share/Loading';
import { getCurrentSOCart } from '@redux/actions/product';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

import OrderDetail from './OrderDetail';
import { getDateRichText } from './utils';

const SaveSOSuccessBody = ({ layoutData, cartData, orderDetail, isShowOrderSummary = true, isOrderSent }) => {
  const dispatch = useDispatch();
  const { soCartStatuses } = useSelector((state) => state.cpSaleOrderStatusesReducer);
  const { currentCart: currentSOCart, isLoading } = useSelector((state) => state.productReducer.cartDetailReducer);
  const formatDateUtil = useFormatDate();

  useEffect(() => {
    if (!currentSOCart) dispatch(getCurrentSOCart());
  }, [currentSOCart]);

  const orderIdText = useMemo(() => {
    const orderIdTextValue = layoutData?.['Order id']?.value;

    return {
      ...layoutData?.['Order id'],
      value: orderIdTextValue?.replace('{orderId}', isOrderSent ? layoutData?.SaleOrder?.Cart?.SalesOrderId : cartData.SalesOrderId)
    };
  }, [cartData, layoutData]);

  const createdExpiredOrderText = useMemo(() => {
    const createAndExpireTextValue = layoutData?.['Create and expire date']?.value;

    return {
      ...layoutData?.['Create and expire date'],
      value: createAndExpireTextValue
        ?.replace(
          '{createdAt}',
          isOrderSent
            ? formatDateUtil(layoutData?.SaleOrder?.Cart?.CreateDateWithFormat)
            : getDateRichText(formatDateUtil(cartData.CreatedDateWithFormat))
        )
        ?.replace(
          '{expriredAt}',
          isOrderSent
            ? formatDateUtil(layoutData?.SaleOrder?.Cart?.ExpiredDateWithFormat)
            : getDateRichText(formatDateUtil(cartData.ExpiredDateWithFormat))
        )
    };
  }, [cartData, layoutData]);

  const packagesNumberText = useMemo(() => {
    const packagesCountTextValue = layoutData?.['Package count']?.value;
    const lines = currentSOCart?.Lines || [];
    const packagesNumber = lines.reduce((prev, next) => prev + Number(next?.Quantity ?? 0), 0);

    return {
      ...layoutData?.['Package count'],
      value: packagesCountTextValue?.replace('{lineNumber}', packagesNumber)
    };
  }, [currentSOCart, layoutData]);

  const sentMailStatusText = useMemo(() => {
    const statusData = {
      0: layoutData?.['Draft'],
      1: layoutData?.['Active'],
      2: layoutData?.['Viewed'],
      3: layoutData?.['Completed'],
      4: layoutData?.['Expired']
    };

    return isOrderSent ? statusData?.[layoutData?.SaleOrder?.Cart?.CartStatus]?.value : soCartStatuses?.[cartData.CartStatus];
  }, [layoutData, soCartStatuses, cartData]);

  return !isLoading ? (
    <div className='save-so-success__body'>
      <div className='order-info'>
        <Text tag='h6' field={orderIdText} className='order-info__id' />
        <RichText tag='p' field={createdExpiredOrderText} className='order-info__description created-expired' />
        <Text tag='p' field={packagesNumberText} className='order-info__description' />
        <p className='order-info__description'>{sentMailStatusText}</p>
      </div>
      {isShowOrderSummary && <OrderDetail layoutData={layoutData} cartData={cartData} isOrderSent={isOrderSent} orderDetail={orderDetail} />}
    </div>
  ) : (
    <div className='save-so-success__body-loading'>
      <Loading />
    </div>
  );
};

SaveSOSuccessBody.propTypes = {
  layoutData: object,
  isOrderSent: bool,
  cartData: exact({
    SalesOrderId: string,
    CreatedDate: any,
    CreatedDateWithFormat: any,
    ExpiredDate: any,
    ExpiredDateWithFormat: any,
    CartLineLength: number,
    CartStatus: number
  }),
  orderDetail: exact({
    DeliveryFee: string,
    Discount: string,
    SalesTax: string,
    Subtotal: string,
    Total: string
  }),
  isShowOrderSummary: bool
};

export default SaveSOSuccessBody;
