import './languageSelector.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { fromEvent } from 'rxjs';

import ModalComponent from '@components/Share/ModalComponent';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { deepCopy } from '../../../../utils/utility';
import LanguageItem from './LanguageItem';
import LanguageSelectorModel from './model/LanguageSelectorModel';

const LanguageSelector = (props) => {
  const { fields } = props;
  const [inputData, setInputData] = useState(null);
  const [isOpen, setOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(null);
  const [languagePopup, setLanguagePopup] = useState(false);
  const [language, setLanguage] = useState(null);

  useEffect(() => {
    const inputData = deepCopy(fields || {});
    const languageSelectorModel = new LanguageSelectorModel();

    setInputData(languageSelectorModel.getData(inputData));
  }, []);

  useEffect(() => {
    const clickSubscription = fromEvent(document, 'click')
      .pipe()
      .subscribe((event) => {
        const langugeClass = ['footer-languages__content__seleted__name', 'icon-chevron-down-big collapse-show'];
        const { className } = event.target;
        if (!langugeClass.includes(className)) {
          setOpen(false);
        }
      });

    return () => clickSubscription.unsubscribe();
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    setLanguage(SitecoreContextFactoryService.getNameCurrentLanguage());
  }, []);

  const onLanguageSelectionHandler = () => {
    if (windowWidth < 992) {
      setLanguagePopup(true);
    } else {
      setOpen(!isOpen);
    }
  };

  const onCloseLanguagePopupHandler = () => {
    setLanguagePopup(false);
  };

  const onGetLanguageHandler = (name, href) => {
    let searchParams = window.location.search;

    setLanguage(name);

    setOpen(false);

    setLanguagePopup(false);

    window.sessionStorage.removeItem('persist:settingGlobalReducer');

    setTimeout(() => {
      window.location.href = searchParams !== '' ? `${href}${searchParams}` : href;
    }, 500);
  };

  return language && inputData ? (
    <Fragment>
      {SitecoreContextFactoryService.getAvailableLanguages().length === 1 || !SitecoreContextFactoryService.getAvailableLanguages()[0]?.code ? (
        <></>
      ) : (
        <Fragment>
          <div className='footer-languages'>
            <div className='container'>
              <div className='footer-languages__title'>
                <Text className='footer-languages__title__text' field={inputData['Language Label']} tag='span' />
                <Fragment>
                  {SitecoreContextFactoryService.getAvailableLanguages().length === 1 &&
                    SitecoreContextFactoryService.getAvailableLanguages()[0].code ? (
                      <div className='footer-languages__content__seleted' role="group" aria-expanded={isOpen}>
                        <span className='footer-languages__content__seleted__name'>{language}</span>
                      </div>
                    ) : (
                      <div
                        className='footer-languages__content__seleted'
                        onClick={() => onLanguageSelectionHandler()}
                        role="group"
                        aria-expanded={isOpen}
                      >
                        <span className='footer-languages__content__seleted__name'>{language}</span>
                        <span className={isOpen ? 'icon-chevron-down-big collapse-show' : 'icon-chevron-down-big'}></span>
                      </div>
                    )}
                </Fragment>
              </div>
              <div className='footer-languages__content'>
                <Collapse in={isOpen}>
                  <ul className='footer-languages__content__menu language-selector' id='language-selector-menu'>
                    {SitecoreContextFactoryService.getAvailableLanguages().map((languageItem, languageItemIdx) => (
                      <LanguageItem contentData={languageItem} key={languageItemIdx} onGetLanguageHandler={onGetLanguageHandler}></LanguageItem>
                    ))}
                  </ul>
                </Collapse>
              </div>
              {isOpen ? <div className='overlay-collapse' onClick={() => setOpen(!isOpen)}></div> : ''}
            </div>
          </div>
          <ModalComponent
            isShow={languagePopup}
            titlePopup={<Text className='footer-languages__title__text' field={inputData['Language Label']} tag='span' />}
            onCloseHandler={onCloseLanguagePopupHandler}
          >
            <div className='footer-languages__popup-content footer-languages__content'>
              <ul className='footer-languages__content__menu language-selector' id='language-selector-menu'>
                {SitecoreContextFactoryService.getAvailableLanguages().map((languageItem, languageItemIdx) => (
                  <LanguageItem contentData={languageItem} key={languageItemIdx} onGetLanguageHandler={onGetLanguageHandler}></LanguageItem>
                ))}
              </ul>
            </div>
          </ModalComponent>
        </Fragment>
      )}
    </Fragment>
  ) : (
    ''
  );
};

LanguageSelector.propTypes = {
  fields: PropTypes.any
};

export default LanguageSelector;
