import './WellnessListing.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { Fragment } from 'react';
import { connect, useDispatch } from 'react-redux';

import AutoCompleteUI from '@components/Share/AutoCompleteUI';
import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import Pagination from '@components/Share/Pagination';
import { commonSearchWellness, getAutoCompleteWellness } from '@redux/actions/common';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import WellnessFilterTag from '../WellnessFilterTag';
import { WELL_BEING_INIT_PAGE, WELL_BEING_PAGESZ } from './configs';
import WellnessListingModel from './model/WellnessListingModel';
import WellnessListingItem from './WellnessListingItem';
import WellnessListingItemShimmer from './WellnessListingItemShimmer';

const WellnessListing = (props) => {
  const { fields, wellnessSearchData, wellnessCount, wellnessSuggestionList, isLoading } = props;
  const dispatch = useDispatch();
  const wellnessListContainer = useRef(null);
  const tagList = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Tags To Select'] || [];
  const [dataFields, setDataFields] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [isDoneScroll, setIsDoneScroll] = useState(false);

  useEffect(() => {
    const wellnessListingModel = new WellnessListingModel();

    setDataFields(wellnessListingModel.getData(deepCopy(fields) || {}));
  }, []);

  const sortUnread = () => dataFields?.['Show unread articles on top']?.value ?? false;
  const sortProductRelated = () => dataFields?.['Show product related articles on top']?.value ?? false;

  const [params, setParams] = useState({
    searchTerm: '',
    tags: [],
    page: WELL_BEING_INIT_PAGE,
    pageSz: WELL_BEING_PAGESZ,
    isDispatch: false,
    sortDate: false
  });

  const [reset, setReset] = useState({
    pagingReset: false
  });

  const setDoneScroll = () => {
    setIsDoneScroll(true);
  };

  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  useDidUpdateEffect(() => {
    dispatch(commonSearchWellness(params));
  }, [params.isDispatch]);

  useDidUpdateEffect(() => {
    if (wellnessListContainer?.current) {
      Global.scrollToWithCallback(wellnessListContainer.current.offsetTop - headerHeight, setDoneScroll);
    }
  }, [params.page]);

  useEffect(() => {
    if (dataFields !== null) {
      setParams((prevState) => ({
        ...prevState,
        searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
        tags: Global.onGetValueTagInParamsURL(Global.onGetKeyInParamUrl()),
        sortUnread: sortUnread(),
        sortProductRelated: sortProductRelated(),
        sortDate: dataFields?.['Show latest articles on top']?.value ?? false
      }));

      setIsDoneScroll(true);

      dispatch(
        commonSearchWellness({
          searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
          tags: Global.onGetValueTagInParamsURL(Global.onGetKeyInParamUrl()),
          page: WELL_BEING_INIT_PAGE,
          pageSz: WELL_BEING_PAGESZ,
          sortUnread: sortUnread(),
          sortProductRelated: sortProductRelated(),
          sortDate: dataFields?.['Show latest articles on top']?.value ?? false
        })
      );
    }
  }, [dataFields]);

  const onChangeSearchTerm = (value) => {
    setParams(prevState => ({
      ...prevState,
      searchTerm: value
    }));
  };

  const onChangeTagsFilterHandler = (value) => {
    let tempParams = { ...params };
    if (tempParams.tags.length && tempParams.tags.includes(value)) {
      tempParams.tags = tempParams.tags.filter((item) => item !== value);
    } else {
      let tempTagsArr = [...tempParams.tags];

      tempTagsArr.push(value);

      tempParams.tags = [...tempTagsArr];
    }

    setParams({ ...tempParams, isDispatch: !params.isDispatch });

    setReset({ pagingReset: !reset.pagingReset });
    let url = new URL(window.location);

    Object.keys(Global.onGetKeyInParamUrl()).forEach((key) => {
      url.searchParams.delete(key);
    });
    if (tempParams.searchTerm) {
      url.searchParams.set('Term', tempParams.searchTerm);
    }
    if (tempParams.tags.length) {
      tempParams.tags.forEach((tag, index) => {
        url.searchParams.set(`Tag${index + 1}`, tag);
      });
    }

    // window.history.replaceState({ additionalInformation: 'Updated the URL with JS' }, 'Title', url);
    window.history.pushState({}, null, url);
    // window.location = url;
  };

  const onSearchHandler = (value) => {
    setParams(prevState => ({
      ...prevState,
      searchTerm: value,
      page: WELL_BEING_INIT_PAGE,
      isDispatch: !params.isDispatch
    }));

    setReset({ pagingReset: !reset.pagingReset });

    Global.genURLParamAndStayPage([{ key: 'Term', value: value }]);
  };

  const onResetTerm = () => {
    setParams(prevState => ({
      ...prevState,
      searchTerm: ''
    }));
  };

  const onChangePageHandler = (pageNum) => {
    setDoneScroll(false);

    setParams((prevState) => ({
      ...prevState,
      page: pageNum,
      isDispatch: !params.isDispatch
    }));
  };

  return (
    <div className='wellness-listing'>
      <div className='searchbox-article'>
        <div className='searchbox-article--tool'>
          <AutoCompleteUI
            //Extra For Wellness
            onResetTerm={onResetTerm}
            onChangeSearchTerm={onChangeSearchTerm}
            searchTerm={params?.searchTerm}
            placeholder={dataFields?.['Hidden Text']?.value || ''}
            suggestionsListData={wellnessSuggestionList}
            onGetSuggestionListHandler={getAutoCompleteWellness}
            onSearchHandler={onSearchHandler}
          ></AutoCompleteUI>
          <WellnessFilterTag
            label={dataFields?.['Filter By Text']}
            tagList={tagList}
            activeTag={params.tags}
            onChangeTagsFilterHandler={onChangeTagsFilterHandler}
          />
        </div>
      </div>

      <div className='container' ref={wellnessListContainer}>
        <div className='row'>
          {!isLoading && isDoneScroll && dataFields ? (
            <Fragment>
              {wellnessSearchData?.length ? (
                wellnessSearchData.map((item, itemIdx) => {
                  return (
                    <div className='col-md-4 featured-article__item animated-slow fadeIn delay-100' key={itemIdx}>
                      <WellnessListingItem item={item} />
                    </div>
                  );
                })
              ) : (
                <span>{dataFields['No Result Text'].value}</span>
              )}
            </Fragment>
          ) : (
            <LoadingShimmer itemNumb={6}>
              <div className='col-md-4 featured-article__item skeleton__item'>
                <WellnessListingItemShimmer></WellnessListingItemShimmer>
              </div>
            </LoadingShimmer>
          )}
        </div>
      </div>

      <Pagination isReset={reset.pagingReset} itemPerPage={WELL_BEING_PAGESZ} totalItem={wellnessCount} onChangePageHandler={onChangePageHandler} />
    </div>
  );
};

const mapStateToProps = (state) => {
  const wellnessSearchReducer = state.commonSearchReducer.wellnessSearchReducer;

  return {
    wellnessSearchData: wellnessSearchReducer.wellnessSearchData || null,
    wellnessCount: wellnessSearchReducer.wellnessCount || 0,
    wellnessSuggestionList: wellnessSearchReducer.wellnessSuggestionList || null,
    isLoading: wellnessSearchReducer.isLoading || null
  };
};

WellnessListing.propTypes = {
  fields: PropTypes.any,
  wellnessSearchData: PropTypes.any,
  wellnessCount: PropTypes.any,
  wellnessSuggestionList: PropTypes.any,
  isLoading: PropTypes.any
};

export default connect(mapStateToProps)(WellnessListing);
