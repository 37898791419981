import './DatePickerEvent.scss';
import 'react-datepicker/dist/react-datepicker.css';

import { string } from 'prop-types';
import React, { useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import CalendarPicker from '../CalendarPicker';

const DatePickerEvent = ({ availableText, notAvailableText, selectedText, ...props }) => {
  const guideList = useMemo(() => {
    return [
      {
        Title: availableText ?? 'Available',
        Color: '#717171',
        'Has Border': false
      },
      {
        Title: notAvailableText ?? 'Not Available',
        Color: '#d4d4d4',
        'Has Border': false
      },
      {
        Title: selectedText ?? 'Selected',
        Color: '#fff',
        'Has Border': true
      }
    ];
  }, [availableText, notAvailableText, selectedText]);

  return (
    <>
      <CalendarPicker {...props} placeholderText='This only includes dates from 5 days ago to 5 days in the future' />
      <div className='select-store__date__guide'>
        {guideList.map((item, idx) => (
          <div className='select-store__date__guide__item' key={idx}>
            <span
              style={{
                backgroundColor: item['Color'],
                border: item['Has Border'] && '1px solid'
              }}
              className='select-store__date__guide__item__square'
            ></span>
            <Text field={{ value: item.Title }} />
          </div>
        ))}
      </div>
    </>
  );
};

DatePickerEvent.propTypes = {
  availableText: string,
  notAvailableText: string,
  selectedText: string
};

export default DatePickerEvent;
