import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import StepHeaderStepModel from './StepHeaderStepModel';

export default class StepHeaderModel {
  constructor() {
    this['Process Content'] = new SitecoreTextModel();

    this['Process Icon'] = new SitecoreImageModel();

    this['Step'] = new StepHeaderStepModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Process Content')) {
      this['Process Content'].getData(dataSource['Process Content']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Process Icon')) {
      this['Process Icon'].getData(dataSource['Process Icon']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Step')) {
      this['Step'].getData(dataSource['Step']);
    }

    return this;
  }
}
