import * as cPStoreSelectorTypes from './cPStoreSelectorTypes.js';

export const cpStoreSelector = (payload) => ({
  type: cPStoreSelectorTypes.CP_STORE_SELECTOR,
  payload
});

export const cpStoreSelectorSuccess = (payload) => ({
  type: cPStoreSelectorTypes.CP_STORE_SELECTOR_SUCCESS,
  payload
});

export const cpStoreSelectorFailed = (error) => ({
  type: cPStoreSelectorTypes.CP_STORE_SELECTOR_FAILED,
  error
});