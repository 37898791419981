import './LeadManagementNoResult.scss';

import { object } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const LeadManagementNoResult = ({ layoutData }) => {
  return (
    <div className='lead-management-no-result'>
      <p className='lead-management-no-result__descrition'>
        {layoutData?.['Number Records Label']?.value?.replace('{number}', '0')}
      </p>
      <div className='search-no-result'>
        <div className='search-no-result__content'>
          <div className='search-no-result__content__image'>
            <img src={require('@assets/images/CP-images/icon-search-big.png')} alt='icon search' />
          </div>
          <Text tag='h5' field={layoutData?.['No Result Label']} className='search-no-result__content__label text-center' />
          <Text tag='p' field={layoutData?.['No Result Description Label']} className='search-no-result__content__desc text-center' />
        </div>
      </div>
    </div>
  );
};

LeadManagementNoResult.propTypes = {
  layoutData: object
};

export default LeadManagementNoResult;