import produce from 'immer';

import * as getProductCatalogueTypes from '@redux/actions/getProductCatalogue/getProductCatalogueTypes';

const initState = {
  isLoading: false,
  dataCatalog: null
};

const getProductCatalogueReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
      case getProductCatalogueTypes.GET_PRODUCT_CATALOGUE:
        draft.isLoading = true;
        break;

      case getProductCatalogueTypes.GET_PRODUCT_CATALOGUE_SUCCESS:
        draft.isLoading = false;

        draft.dataCatalog = action.payload;
        break;

      case getProductCatalogueTypes.GET_PRODUCT_CATALOGUE_FAILED:
        draft.isLoading = false;
        break;
    }

    return draft;
  });

export default getProductCatalogueReducer;
