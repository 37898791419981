export default class TextModel {
  constructor() {
    this.value = ''; 
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this.value = dataSource.value ? dataSource.value : this.value;
    }

    return this;
  }
}