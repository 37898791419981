import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXTARR = ['Title', 'CTA Button'];

const LINKARR = ['CTA Button Link'];

export default class AutoScrollingBannersModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['Banner Items'] = [];
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Items')) {
      let newItemsList = [];

      dataSource['Banner Items'].map((element) => {
        newItemsList.push(deepCopy(element));
      });

      this['Banner Items'] = newItemsList;
    }

    return this;
  }
}
