import { object } from 'prop-types';
import React, { useState, useCallback } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { swiperParams } from '../configs';
import { useProductSupportContext } from '../useProductSupportContext';

const ProductSwiper = ({ sectionTitle }) => {
  const PRODUCT_QUERY_STRING_NAME = 'product';
  const COMMERCE_PRODUCT_QUERY_STRING_NAME = 'commerceProductId';
  const { productsData, layoutData } = useProductSupportContext();
  const [activeCategory, setActiveCategory] = useState(null);

  const productSupportDetailLink = useCallback(
    (productId, commerceProductId) => {
      setActiveCategory(productId)
      const supportDetailUrl = layoutData?.['Product Support Detail Page']?.url || '';

      return productId && commerceProductId && supportDetailUrl
        ? (window.location.href = `${supportDetailUrl}?${PRODUCT_QUERY_STRING_NAME}=${productId}&${COMMERCE_PRODUCT_QUERY_STRING_NAME}=${commerceProductId}`) : '';
    },
    [layoutData?.['Product Support Detail Page']]
  );

  return (
    <div className='product-support-cta__swiper'>
      <Text tag='h4' field={sectionTitle} className='product-support-cta__swiper-title' />
      <div className='product-support-cta__swiper-container'>
        <SwipeComponent param={swiperParams}>
          {productsData.map((product) => (
            <div key={product.Id} className='swiper-item' onClick={() => productSupportDetailLink(product.Id, product.CommerceProductId)}>
              <div className={`swiper-image ${activeCategory === product.Id  ? 'active' : ''}`}>
                <img src={product.Image} alt={product.ProductName} />
              </div>
              <h3 className='swiper-name'>{product.ProductName}</h3>
            </div>
          ))}
        </SwipeComponent>
      </div>
    </div>
  );
};

ProductSwiper.propTypes = {
  sectionTitle: object
};

export default ProductSwiper;
