import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import BackgroundColorCodeModel from './BackgroundColorCodeModel';
import TwoColumnWithTextImageItemsModel from './TwoColumnWithTextImageItemsModel';

export default class TwoColumnWithTextImageModel {
  constructor() {
    this['Background Color Code'] = new BackgroundColorCodeModel();

    this['Description'] = new SitecoreTextModel();

    this['Image'] = new SitecoreImageModel();

    this['Description Image'] = new SitecoreImageModel();

    this['Title'] = new SitecoreTextModel();

    this['Video Link Label'] = new SitecoreTextModel();

    this['Video'] = new SitecoreTextModel();

    this['Vimeo video ID'] = new SitecoreTextModel();

    this['Youtube video ID'] = new SitecoreTextModel();

    this['Link Video'] = '';

    this['Video Type'] = null;

    this['Is Local Video'] = false;

    this['items'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Color Code')) {
      this['Background Color Code'].getData(dataSource['Background Color Code']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description Image')) {
      this['Description Image'].getData(dataSource['Description Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video Link Label')) {
      this['Video Link Label'].getData(dataSource['Video Link Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video')) {
      this['Video'].getData(dataSource['Video']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Vimeo video ID')) {
      this['Vimeo video ID'].getData(dataSource['Vimeo video ID']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Youtube video ID')) {
      this['Youtube video ID'].getData(dataSource['Youtube video ID']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video') && Object.prototype.hasOwnProperty.call(dataSource, 'Youtube video ID') && Object.prototype.hasOwnProperty.call(dataSource, 'Vimeo video ID')) {
      this['Link Video'] =
        this['Video'].value?.href !== ''
          ? this['Video'].value.href
          : null || this['Youtube video ID'].value !== ''
            ? `www.youtube.com/watch?v=${this['Youtube video ID'].value}`
            : null || this['Vimeo video ID'].value !== ''
              ? `https://vimeo.com/${this['Vimeo video ID'].value}`
              : null;

      this['Video Type'] =
        this['Video'].value?.href !== ''
          ? 'Local'
          : null || this['Youtube video ID'].value !== ''
            ? 'Youtube'
            : null || this['Vimeo video ID'].value !== ''
              ? 'Vimeo'
              : null;

      this['Is Local Video'] = this['Video'].value?.href !== '' ? true :  false;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let tempItems = [];

      if (dataSource['items']) {
        dataSource['items'].map((ele) => {
          tempItems.push(new TwoColumnWithTextImageItemsModel().getData(deepCopy(ele) || {}));
        });
      }

      this['items'] = tempItems;
    }

    return this;
  }
}
