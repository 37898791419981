import './MyAddress.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { getBookedAddress } from '@redux/actions/checkoutProcess';

import AddressBook from './AddressBook';
import BillingAddress from './BillingAddress';
import MyAddressModel from './models/MyAddressModel';
import MyAddressShimmer from './MyAddressShimmer';
import ShippingAddress from './ShippingAddress';

const MyAddress = (props) => {
  const {userAddressList, fields, isLoading} = props;
  const dispatch = useDispatch();
  const [dataSources, setDataSources] = useState(null);
  
  useEffect(() => {
    setDataSources(new MyAddressModel().getData(fields || {}));

    dispatch(getBookedAddress());
  }, []);
  
  return dataSources && !isLoading ? (
    <div className='my-addresses animated-slow fadeIn delay-100'>
      <div className='container'>
        <div className='my-addresses__wrap'>
          <div className='row'>
            <div className='col-lg-6'>
              <ShippingAddress dataSources={dataSources} userAddressList={userAddressList}/> 
            </div>
            <div className='col-lg-6'>
              <BillingAddress dataSources={dataSources} userAddressList={userAddressList}/>
            </div>
            <div className='col-12'>
              <AddressBook dataSources={dataSources} userAddressList={userAddressList}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <MyAddressShimmer></MyAddressShimmer>
  );
};

MyAddress.propTypes = {
  userAddressList: PropTypes.array,
  accountUser: PropTypes.any,
  fields: PropTypes.object,
  isLoading: PropTypes.any
};

const mapStateToProps = (state) => ({
  userAddressList: state.checkoutProcesstReducer.addressListReducer.userAddressList || [],
  isLoading: state.checkoutProcesstReducer.addressListReducer.isLoading,
  accountUser: state.singleSignOnReducer.userInfoReducer.accountUser,
});

export default connect(mapStateToProps)(MyAddress);
