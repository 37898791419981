import Proptypes from 'prop-types';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Fragment } from 'react';
import { useMemo } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { getNotifyStatusProduct, resetMessagePopupNotify } from '@redux/actions/product';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import NotifyMePopup from '../NotifyMePopup';

const ProductDetailHeaderBundle = ({ dataSources, isProductDetailSticky, isClientTelingUser, couponCode, setCouponCode, cookieBannerHeight }) => {
  const dispatch = useDispatch();
  const isNotifySuccess = useSelector((state) => state.productReducer.notifyProductStatusReducer.isNotifySuccess);
  const [buyNowBtn, setBuyNowBtn] = useState(null);
  const [isShowNotifyMe, setIsShowNotifyMe] = useState(false);
  const [buyNowButtonLink, setBuyNowButtonLink] = useState(dataSources?.['Bundle Buy Now Link']);
  const [allReviewDetailsProduct, setAllReviewDetailsProduct] = useState(null);
  const [productNameBundle, setProductNameBundle] = useState(null);

  useEffect(() => {
    const allReviewDetailsProduct = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'));

    const productNameBundle = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'))?.productBundle?.bundleName;

    const buyNowBtn = window.sessionStorage.getItem('buyNowBtnStaticBundle');

    setAllReviewDetailsProduct(allReviewDetailsProduct);

    setProductNameBundle(productNameBundle);

    setBuyNowBtn(buyNowBtn);
  }, []);

  // Reuse in PromotionHeader
  useDidUpdateEffect(() => {
    let temp = deepCopy(dataSources?.['Bundle Buy Now Link']);
    const queryParams = new URLSearchParams(window.location.search);

    temp.value.href = `${dataSources['Bundle Buy Now Link'].value.href}?bundlePageId=${queryParams.get('bundlePageId')}${
      queryParams?.get('cCode') ? '&cCode=' + queryParams?.get('cCode') : ''
    }`;

    setCouponCode(queryParams?.get('cCode'));

    setBuyNowButtonLink(temp);
  }, [allReviewDetailsProduct]);

  // Reuse in PromotionHeader
  const onClosePopupNotifyHandler = () => {
    setIsShowNotifyMe(false);

    dispatch(resetMessagePopupNotify());
  };

  // Reuse in PromotionHeader
  const onSubmitNotifyHandler = (emailSubmit) => {
    dispatch(getNotifyStatusProduct({ payload: emailSubmit }));
  };

  // Reuse in PromotionHeader
  const message = useMemo(() => {
    if (isNotifySuccess?.message) {
      if (isNotifySuccess.status) {
        return <p className='notify-success'>{isNotifySuccess.message}</p>;
      } else {
        return <p className='notify-faild'>{isNotifySuccess.message}</p>;
      }
    }

    return null;
  }, [isNotifySuccess]);

  return (
    <>
      {/* Reuse in ProductDetailHeader with data from Products Bundle*/}
      <div id='product-detail-header'
        className={`product-detail-header${isProductDetailSticky ? ' product-detail-sticky' : ''} ${
          isClientTelingUser ? '--cp-product-detail' : ''
        } animated-slow fadeIn delay-100`}
        style={{ top: isProductDetailSticky ? `${cookieBannerHeight}px` : 'unset' }}
      >
        {couponCode ? (
          <Helmet>
            <meta name='robots' content='noindex' />
          </Helmet>
        ) : (
          <></>
        )}
        <div className='product-detail-header__wrap container'>
          <div className='product-detail-header__drop-down'>
            <div className='product-detail-header__product-name'>
              <span className='product-detail-header__product-name__text'>{productNameBundle}</span>
            </div>
            <div className='product-detail-header__price product-detail-header__price--mobile'>
              <Fragment>
                <span className='product-detail-header__price__list-price'>{allReviewDetailsProduct?.productBundle?.sellPrice}</span>
                {allReviewDetailsProduct?.productBundle?.sellPrice !== allReviewDetailsProduct?.productBundle?.listPrice ? (
                  <del className='product-detail-header__price__selling-price'>{allReviewDetailsProduct?.productBundle?.listPrice}</del>
                ) : (
                  ''
                )}
              </Fragment>
            </div>
          </div>
          <div className='product-detail-header__group'>
            <div className='product-detail-header__price product-detail-header__price--desktop'>
              <Fragment>
                {allReviewDetailsProduct?.productBundle?.listPrice !== allReviewDetailsProduct?.productBundle?.sellPrice ? (
                  <del className='product-detail-header__price__selling-price'>{allReviewDetailsProduct?.productBundle?.listPrice}</del>
                ) : (
                  ''
                )}
                <span className='product-detail-header__price__list-price'>{allReviewDetailsProduct?.productBundle?.sellPrice}</span>
              </Fragment>
            </div>
            <div className='promotion-detail-bundle__header__btn product-detail-header__btn'>
              {buyNowBtn === 'getNotify' ? (
                <Text
                  tag='span'
                  className='promotion-detail-bundle__header__btn__item btn btn-primary'
                  field={dataSources['Notify Text']}
                  onClick={() => setIsShowNotifyMe(true)}
                />
              ) : (
                <>
                  {allReviewDetailsProduct?.variantStatus === 'outofstock' ? (
                    <Text
                      tag='span'
                      className='promotion-detail-bundle__header__btn__item btn btn-primary disabled'
                      field={dataSources['Buy Now Text']}
                    />
                  ) : (
                    <Link field={buyNowButtonLink}>
                      {buyNowBtn === 'buyNow' ? (
                        <Text tag='span' className='promotion-detail-bundle__header__btn__item btn btn-primary' field={dataSources['Buy Now Text']} />
                      ) : (
                        <span className='promotion-detail-bundle__header__btn__item btn btn-primary'>{buyNowBtn}</span>
                      )}
                    </Link>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* Reuse in PromotionHeader */}
      <ModalComponent
        isShow={isShowNotifyMe}
        titlePopup={buyNowBtn === 'getNotify' ? <span>Get notified</span> : <span>Notify me</span>}
        onCloseHandler={() => onClosePopupNotifyHandler()}
      >
        <NotifyMePopup inputData={{ productId: allReviewDetailsProduct?.productId }} notifyMeEvt={onSubmitNotifyHandler}>
          {message}
        </NotifyMePopup>
      </ModalComponent>
    </>
  );
};

ProductDetailHeaderBundle.propTypes = {
  dataSources: Proptypes.any,
  isProductDetailSticky: Proptypes.bool,
  isClientTelingUser: Proptypes.bool,
  couponCode: Proptypes.string,
  setCouponCode: Proptypes.any,
  cookieBannerHeight: Proptypes.number
};

export default ProductDetailHeaderBundle;
