import './StoreOverview.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import TextModel from '@models/TextModel';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import DataSourceModel from './model/DataSourceModel';
import StoreHeaderModel from './model/StoreOverviewModel';
import StoreOverviewShimmer from './StoreOverviewShimmer';

const StoreOverview = (props) => {
  const { fields } = props;
  const [contactInfo, setContactInfo] = useState(null);
  const [openHour, setOpenHour] = useState(null);
  const [overviewData, setOverviewData] = useState(null);
  const [mail, setMail] = useState(null);
  const [storeAddress, setStoreAddresss] = useState({});
  const [dataSources, setDataSources] = useState(null);
  const [site] = useState(SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OTW');
  const [storeId, setStoreId] = useState(null);

  const [myLocation, setMyLocation] = useState({
    latitude: null,
    longitude: null
  });

  useEffect(() => {
    const urlSearchParam = new URLSearchParams(window.location.search);

    setStoreId(urlSearchParam.get('storeId'));
  }, []);

  useEffect(() => {
    setDataSources(new DataSourceModel().getData(deepCopy(SitecoreContextFactoryService.getValueContextRouteItem('fields') || {})));

    setMail(SitecoreContextFactoryService.getValueContextRouteItem('fields').Email.value);
    const overviewModel = new StoreHeaderModel();

    setOverviewData(overviewModel.getData(deepCopy(fields)));

    setContactInfo({
      address: new TextModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields').Address),
      city: new TextModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields').City?.fields?.Name),
      state: new TextModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields').State?.fields?.Name),
      postCode: new TextModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields').Postcode),
      phone: new TextModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields').Phone)
    });

    let openingHour = SitecoreContextFactoryService.getValueContextRouteItem('fields')['Opening Hours'].value.split('&');

    openingHour = openingHour.map((item) => {
      return decodeURIComponent(item).split('=');
    });

    setOpenHour(openingHour);

    setStoreAddresss({
      lat: new TextModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields').Latitude),
      long: new TextModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields').Longitude)
    });

    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        setMyLocation({
          ...myLocation,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      () => {
        setMyLocation({
          ...myLocation,
          latitude: null,
          longitude: null
        });
      }
    );
  }, []);

  const onDirectHandler = (e) => {
    e.stopPropagation();

    window.open(
      `https://www.google.com/maps/dir/${myLocation.latitude},${myLocation.longitude}/${storeAddress.lat.value},${storeAddress.long.value}`,
      '_blank'
    );
  };

  return contactInfo && overviewData ? (
    <div className='store-overview animated-slow fadeIn delay-100'>
      <div className='store-overview__content'>
        <div className='store-overview__content__image'>
          <Image media={dataSources['Hero Image']} className='store-overview__content__image__item' />
        </div>
        <div className='store-overview__content__info'>
          <div className='container'>
            <div className='store-overview__content__info__wrap'>
              <div className='row'>
                <div className='col-md-6 col-xl-12 col-xxl-6'>
                  <div className='store-overview__content__info__contact'>
                    <h3 className='contact__title'>{overviewData['Contact Info Label'].value}</h3>
                    <div className='contact-info-group'>
                      <span className='contact__item contact__address'>{overviewData['Store Locator Data'].FullAddress}</span>
                      {site ? <></> : <span className='contact__item contact__code'>{contactInfo.postCode.value}</span>}
                      <a className='contact__item contact__phone' href={`tel:${contactInfo.phone.value}`}>
                        {contactInfo.phone.value}
                      </a>
                    </div>
                    <div className='store-overview__content__info__actions '>
                      {overviewData['Book Experience Button Link']?.value?.href && overviewData['Book Experience Button Label']?.value ? (
                        <Link
                          field={{ href: `${overviewData['Book Experience Button Link']?.value?.href}${storeId ? `?storeId=${storeId}` : ''}` }}
                          className='actions__book-btn btn btn-primary'
                        >
                          <Text field={overviewData['Book Experience Button Label']} />
                        </Link>
                      ) : (
                        <></>
                      )}
                      <div className='actions__wrap-link'>
                        <span className='actions__link' onClick={(e) => onDirectHandler(e)}>
                          {overviewData['Get Directions Text'].value}
                        </span>
                        {mail ? (
                          <a className='actions__link' href={`mailto:${mail}`}>
                            {overviewData['Email Us Text'].value}
                          </a>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-xl-12 col-xxl-6'>
                  <div className='store-overview__content__info__opening-hours'>
                    <h3 className='opening-hours__title'> {overviewData['Opening Hours Label'].value}</h3>
                    <div className='opening-hours__list'>
                      {openHour.map((item, index) => {
                        return (
                          <dl className='opening-hours__item opening-hours__item--active' key={index}>
                            <dt className='opening-hours__item__day'>{item[0]}</dt>
                            <dd className='opening-hours__item__time'>{item[1]}</dd>
                          </dl>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <StoreOverviewShimmer></StoreOverviewShimmer>
  );
};

StoreOverview.propTypes = {
  fields: PropTypes.object
};

export default StoreOverview;
