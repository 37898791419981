import QuestionModel from '@components/ClientPortal/ReviewCustomerInformation/model/QuestionModel';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import PhoneCountryCodeModel from '../../QACustomerLifestyle/model/PhoneCountryCodeModel';

const ICON_ARR = [
  'Basic Profile Icon',
  'Birthday Icon',
  'Dial Button Icon',
  'Gender Icon',
  'Lifestyle Icon',
  'Married Icon',
  'Message Button Icon',
  'SMS Icon',
  'Mail Icon',
  'Phone Icon'
];

const TEXT_ARR = [
  'Add New',
  'Continue With Existing Button',
  'Create New Profile Button',
  'Email Label',
  'First Name Label',
  'Last Name Label',
  'Phone Number Label',
  'Signature Label',
  'Warning Description',
  'Warning Title',
  'Close Button Label',
  'Confirm Button Label',
  'Email Validation Text',
  'First Name Validation Text',
  'Gender Validation Text',
  'Marital Status Validation Text',
  'PAPA Agreement Message',
  'PDPA Validation Text',
  'Phone Validation Text',
  'Signature Validation Text',
  'Direct Mailing Label',
  'Mail Label',
  'Refer POS Label',
  'SMS Label',
  'Signature Clear Label',
  'Signature Trim Label',
  'Communication Channel Validation Text',
  'Duplicated Email Message',
  'Signature Here Label',
  'Confirm Marketing Agreement Message',
  'Is Signature Hidden',
  'Is Communication Method Hidden',
  'Is Communication Method Required',
  'Is Last Name Required',
  'Direct Mailing Hidden',
  'Create New Profile Dupplicated Phone Button'
];

export default class QACustomerBasicProfileModel {
  constructor() {
    ICON_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Questions'] = [];

    this['Massage Ownership Types'] = [];

    this['Phone Country Code'] = [];
  }
  getData(dataSource) {
    ICON_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Questions')) {
      dataSource['Questions'].map((e) => {
        this['Questions'].push(new QuestionModel().getData(deepCopy(e) || {}));
      });
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Massage Ownership Types')) {
      dataSource['Massage Ownership Types'].map((e) => {
        this['Massage Ownership Types'].push(new PhoneCountryCodeModel().getData(deepCopy(e) || {}));
      });
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Phone Country Code')) {
      dataSource['Phone Country Code'].map((element) => {
        this['Phone Country Code'].push(new PhoneCountryCodeModel().getData(deepCopy(element) || {}));
      });
    }

    return this;
  }
}
