import PropTypes from 'prop-types';
import React from 'react';

import FormFieldError from '../../FormFieldError';

const CustomRichTextCheckbox = (props) => {
  const { field, value, onChange, tracker, errors, children } = props;

  const handleOnChange = (field, fieldValue, callback) => {
    // (fieldName, fieldValue, isValid, validationErrors)
    callback(field.valueField.name, fieldValue, true, []);
  };

  return (
    <div className='osim-checkbox'>
      <input
        type='checkbox'
        className='osim-checkbox-input'
        id={field.valueField.id}
        name={field.valueField.name}
        value='true'
        checked={value}
        onChange={(e) => handleOnChange(field, e.target.checked, onChange)}
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
      />
      <label htmlFor={field.valueField.id} className='osim-checkbox-label'>
        {children}
        <span dangerouslySetInnerHTML={{ __html: field.model.titleRichText }}></span>
      </label>
      <FormFieldError {...props} />
    </div>
  );
};

CustomRichTextCheckbox.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.any,
  tracker: PropTypes.any,
  errors: PropTypes.any,
  children: PropTypes.any
};

export default CustomRichTextCheckbox;
