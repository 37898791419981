import PublishedDateModel from '@models/PublishedDateModel';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import TagsModel from './TagsModel';

export default class WellnessDetailHeaderFieldsModel {
  constructor() {
    this.Tags = [];

    this.Title = new SitecoreTextModel();

    this.Description = new SitecoreTextModel();

    this['Time To Read'] = new SitecoreTextModel();

    this.PublishedDate = new PublishedDateModel();

    this.Banner = new SitecoreImageModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Tags')) {
      let tempTags = [];

      dataSource['Tags'].map((ele) => {
        tempTags.push(new TagsModel().getData(deepCopy(ele) || {}));
      });

      this.Tags = tempTags;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Time To Read')) {
      this['Time To Read'].getData(dataSource['Time To Read']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PublishedDate')) {
      this.PublishedDate.getData(dataSource.PublishedDate);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner')) {
      this.Banner.getData(dataSource.Banner);
    }

    return this;
  }
}
