import SitecoreTextModel from '@models/SitecoreTextModel';

export default class TimeLinePanelLayoutModel {
  constructor() {
    this['Description'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}