import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXTARR = [
  'Title',
  'View More Text',
  'View Detail Text Button',
  'Register Now Text Button',
  'Registered Text',
  'Cant Register Message',
  'Upcoming Title',
  'Happening Now Title',
  'Expired Message Text'
];

export default class MemberEventListingModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['HappeningNow'] = [];

    this['Upcoming'] = [];
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'HappeningNow')) {
      let newItemsList = [];

      dataSource['HappeningNow'] &&
        dataSource['HappeningNow'].map((element) => {
          newItemsList.push(deepCopy(element));
        });

      this['HappeningNow'] = newItemsList;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Upcoming')) {
      let newItemsList = [];

      dataSource['Upcoming'] &&
        dataSource['Upcoming'].map((element) => {
          newItemsList.push(deepCopy(element));
        });

      this['Upcoming'] = newItemsList;
    }

    return this;
  }
}
