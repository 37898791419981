import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/checkoutProcess';
import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';
import { get } from '@services/genericService';

import * as config from './config';

export const getPaymentDetailEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.GET_PAYMENT_DETAIL),
    switchMap((action) => {
      const params = {
        orderId: action.orderId
      };

      return from(get(config.PAYMENT_DETAIL, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getPaymentDetailSuccess(res.data);
          } else {
            return actions.getPaymentDetailFailed('Failed');
          }
        })
      );
    })
  );

export const downloadInvoiceEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.DOWNLOAD_INVOICE),
    switchMap((action) => {
      const params = {
        orderId: action.payload
      };

      return from(
        get(config.DOWNLOAD_INVOICE, params, {
          responseType: 'blob'
        })
      ).pipe(
        map((res) => {
          // const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');

          // link.href = url;

          link.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(new Blob(res.data)));

          link.setAttribute('download', '2134');

          document.body.appendChild(link);

          link.click();

          link.parentNode.removeChild(link);

          if (res.status === 200) {
            return actions.downloadInvoiceSuccess();
          } else {
            return actions.downloadInvoiceFail('Failed');
          }
        })
      );
    })
  );
