import React from 'react';
import { Fragment } from 'react';

const TwoColumnCTAButtonShimmer = () => {

  return (
    <Fragment>
      <div className='two-column-CTA-Button'>
        <div className='container'>
          <div className='row'>
            <div className='col-6 two-column-CTA-Button__col skeleton__item'>
              <div className='two-column-CTA-Button__contents'>
                <div className='two-column-CTA-Button__contents__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <span className='text-line w-50'></span>
              </div>
            </div>
            <div className='col-6 two-column-CTA-Button__col skeleton__item'>
              <div className='two-column-CTA-Button__contents'>
                <div className='two-column-CTA-Button__contents__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <span className='text-line w-50'></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default TwoColumnCTAButtonShimmer;