import { object } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const CustomerProductShimmer = ({ layoutData }) => {
  return (
    <div className='box-data skeleton__item'>
      <div className='box-data__header'>
        <div className='box-data__col-1'>
          <Text tag='h3' field={layoutData?.['Section Title']} className='box-data__title' />
        </div>
      </div>
      {[...new Array(4)].map((item, index) => {
        return (
          <div key={index} className='box-data__header'>
            <span className='data-text text-line-25'></span>
          </div>
        );
      })}
    </div>
  );
};

CustomerProductShimmer.propTypes = {
  layoutData: object
};

export default CustomerProductShimmer;
