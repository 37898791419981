import produce from 'immer';

import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as clientTellingPortalCustomerTypes
from '@redux/actions/clientTellingPortalCustomer/clientTellingPortalCustomerTypes';
import Global from '@utils/global';

import { DEFAULT_API_VALUE } from '../../../utils/constant';

export const NO_MASSAGE_OWNER_OPTION_KEY = 'MassageOwnership';

export const NO_MASSAGE_OWNER_OPTION_TYPE = 'NoMassageOwnership';

const initState = {
  isCheckPOSFinal: false,
  isLoading: true,
  isLoadingSearchResult: true,
  contacts: [],
  searchResults: [],
  searchResultsLength: 0,
  Email: '',
  PhoneNumber: '',
  DOB: '',
  isLoadingEndSession: false,
  customerInformation: {},
  tmpCustomerInformation: {},
  tmpCustomerInformationQA: {},
  isDirtyAddNewAndEditProductInterest: false,
  errorData: null,
  customerProducts: null,
  currentCustomerDetail: {},
  externalId: '',
  cpCustomerVoucher: null,
  isUpdateContactSuccess: false,
  isUpdateContactInterestLoading: false,
  POSOrderId: '',
  isUpdateContactLoading: false,
  isKeepLifeStyleData: false,
  InvoiceNumber: '',
  createPDPAErrorCode: null,
  removeProductInterestStatus: null,
  isRemoveProductInterestLoading: false,
  isGettingContactPersonalInfo: false
};

const onSetCPCustomerInterest = (products) =>
  products?.map((product) => {
    return {
      ProductId: Global.onFormatProductId(product?.ProductId),
      ProductPageId: Global.onFormatProductId(product?.ProductPageId),
      Questions: product?.Questions,
      ProductName: product?.ProductName,
      DateCreated: product?.DateCreated,
      Image: product?.Image,
      IsCaptured: product?.IsCaptured,
      IsPos: product?.IsPos,
      SessionId: product?.SessionId
    };
  });

const onChangeQuestionPackForSpecificProductInterest = (arr) => {
  return arr.map((item) => {
    return {
      Question: item.Question,
      Answer: '',
      IsPosRelated: item['POS Related'] === '1' ? true : false,
      IsRequired: item['Is Required'] === '1',
      QuestionCode: item['Question Code']
    };
  });
};

const onRenderCustomerInformation = (data) => {
  let tmpObject = {};

  if (data?.[0]?.json_facet?.CPCustomerInfo) {
    for (const key in data?.[0].json_facet.CPCustomerInfo) {
      tmpObject[`${key}`] = data?.[0].json_facet.CPCustomerInfo[key] || '';
    }

    tmpObject.contact_type = data?.[0].contact_type;
  }

  return {
    isSyncFromPos: data?.[0]?.isSyncFromPos ?? false,
    ContactID: data?.[0]?.contactId || '',
    Email: data?.[0]?.json_facet?.Email?.PreferredEmail?.SmtpAddress || '',
    RealEmail: data?.[0]?.json_facet?.Email?.PreferredRealEmail?.SmtpAddress || '',
    PhoneNumber: data?.[0]?.json_facet?.PhoneNumbers?.PreferredPhoneNumber?.Number || '',
    PrefixPhoneNumber: data?.[0]?.json_facet?.PhoneNumbers?.PreferredPhoneNumber?.CountryCode || '',
    DOB: data?.[0]?.json_facet?.Personal?.Birthdate || '',
    PersonalInformation: {
      FirstName: data?.[0]?.json_facet?.Personal?.FirstName || '',
      LastName: data?.[0]?.json_facet?.Personal?.LastName || '',
      Gender: data?.[0]?.json_facet?.Personal?.Gender || ''
    },
    CPCustomerInfo: tmpObject,
    CPCustomerInterest: {
      Products: onSetCPCustomerInterest(data?.[0]?.json_facet?.CPCustomerInterest?.Products) || []
    },
    LastVisit: data?.[0]?.json_facet?.LastVisit
  };
};

const clientTellingPortalCustomerReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case clientTellingPortalCustomerTypes.GET_CP_CUSTOMER:
      draft.isLoading = true;
      break;

    case clientTellingPortalCustomerTypes.GET_CP_CUSTOMER_SUCCESS:
      draft.isLoading = false;
      if (action.payload.isRemovingProduct) {
        draft.isRemoveProductInterestLoading = false;
      }
      if (action.payload.fromURL) {
        draft.currentCustomerDetail = onRenderCustomerInformation(action.payload.data, draft.currentCustomerDetail);

        draft.contacts = action.payload.data;
      } else {
        draft.contacts = action.payload.data;

        draft.customerInformation = onRenderCustomerInformation(action.payload.data, draft.customerInformation);

        if (!draft.isKeepLifeStyleData) {
          draft.tmpCustomerInformation = onRenderCustomerInformation(action.payload.data, draft.tmpCustomerInformation);
        } else {
          draft.isKeepLifeStyleData = false;
        }
      }
      break;

    case clientTellingPortalCustomerTypes.SEARCH_CP_CUSTOMER:
      draft.isLoadingSearchResult = true;
      break;

    case clientTellingPortalCustomerTypes.SEARCH_CP_CUSTOMER_SUCCESS:
      draft.isLoadingSearchResult = false;

      draft.searchResultsLength = action.payload?.results;

      draft.searchResults = action.payload?.contacts;
      break;

    case clientTellingPortalCustomerTypes.GET_CONTACT_PERSONAL_INFORMATION:
      draft.isGettingContactPersonalInfo = true;
      break;

    case clientTellingPortalCustomerTypes.GET_CONTACT_PERSONAL_INFORMATION_SUCCESS:
      draft.isGettingContactPersonalInfo = false;
      if (action.payload.type == 'Email') {
        draft.Email = action.payload.data;
      }
      if (action.payload.type == 'PhoneNumber') {
        draft.PhoneNumber = action.payload.data;
      }
      if (action.payload.type == 'DOB') {
        draft.DOB = action.payload.data;
      }
      break;

    case clientTellingPortalCustomerTypes.END_SESSION:
      draft.isLoadingEndSession = true;
      break;

    case clientTellingPortalCustomerTypes.END_SESSION_SUCCESS:
      draft.isLoadingEndSession = false;
      break;

    case clientTellingPortalCustomerTypes.STORE_CP_CUSTOMER:
      if (action.payload.type == 'CPCustomerInfo-Multi Select') {
        draft.tmpCustomerInformation = {
          ...draft.tmpCustomerInformation,
          CPCustomerInfo: {
            ...draft.tmpCustomerInformation.CPCustomerInfo,
            [`${action.payload.key}`]: Global.onSetValueMultiSelect(
                draft.tmpCustomerInformation.CPCustomerInfo?.[`${action.payload.key}`],
                action.payload.value
            ),
            [`${action.payload.key}Id`]: Global.onSetValueMultiSelect(
              draft.tmpCustomerInformation.CPCustomerInfo?.[`${action.payload.key}Id`],
              action.payload.answerId
            )
          }
        };
      } else if (action.payload.type === 'CPCustomerInfo-Multi RemoveMassageOwner') {
        draft.tmpCustomerInformation = {
          ...draft.tmpCustomerInformation,
          CPCustomerInfo: {
            ...draft.tmpCustomerInformation.CPCustomerInfo,
            [`${action.payload.key}`]: Global.onSetValueMultiSelect(null, action.payload.value)
          }
        };
      } else if (action.payload.type === NO_MASSAGE_OWNER_OPTION_TYPE) {
        draft.tmpCustomerInformation = {
          ...draft.tmpCustomerInformation,
          CPCustomerInfo: {
            ...draft.tmpCustomerInformation.CPCustomerInfo,
            [`${action.payload.key}`]: DEFAULT_API_VALUE,
            [`${action.payload.key}Id`]: DEFAULT_API_VALUE,
          }
        };
      } else {
        if (action.payload.key == 'Gender') {
          draft.tmpCustomerInformation = {
            ...draft.tmpCustomerInformation,
            PersonalInformation: {
              ...draft.tmpCustomerInformation.PersonalInformation,
              [`${action.payload.key}`]: action.payload.value,
              [`${action.payload.key}Id`]: action.payload.answerId,
            }
          };
        }

        draft.tmpCustomerInformation = {
          ...draft.tmpCustomerInformation,
          CPCustomerInfo: {
            ...draft.tmpCustomerInformation.CPCustomerInfo,
            [`${action.payload.key}`]: action.payload.value,
            [`${action.payload.key}Id`]: action.payload.answerId,
          }
        };
      }
      break;

    case clientTellingPortalCustomerTypes.REVERT_CP_CUSTOMER:
      draft.tmpCustomerInformation = draft.customerInformation;
      break;

    case clientTellingPortalCustomerTypes.TRIGGER_POS_CHANGE_QUESTIONS:
      return produce(state, (draft) => {
        draft.isDirtyAddNewAndEditProductInterest = true;

        draft.customerInformation.CPCustomerInterest.Products.find((product) => product.ProductId == action.payload.productId).Questions =
          onChangeQuestionPackForSpecificProductInterest(action.payload.questionsArr);

        if ('isPos' in action.payload) {
          draft.customerInformation.CPCustomerInterest.Products.find((product) => product.ProductId == action.payload.productId).IsPos =
            action.payload.isPos;
        }
      });

    case clientTellingPortalCustomerTypes.STORE_QUESTION_PRODUCT_IN_PRODUCT_INTEREST:
      if (action.payload.type == 'CPCustomerInfo-Multi Select') {
        return produce(state, (draft) => {
          draft.isDirtyAddNewAndEditProductInterest = true;

          draft.customerInformation.CPCustomerInterest.Products.find((product) => product.ProductId == action.payload.productId).Questions.find(
            (quest) => quest.QuestionCode == action.payload.key
          ).Answer = Global.onSetValueMultiSelect(
            draft.customerInformation.CPCustomerInterest.Products.find((product) => product.ProductId == action.payload.productId).Questions.find(
              (quest) => quest.QuestionCode == action.payload.key
            ).Answer,
            action.payload.value
          );

          draft.customerInformation.CPCustomerInterest.Products.find((product) => product.ProductId == action.payload.productId).Questions.find(
            (quest) => quest.QuestionCode == action.payload.key
          ).AnswerId = Global.onSetValueMultiSelect(
            draft.customerInformation.CPCustomerInterest.Products.find((product) => product.ProductId == action.payload.productId).Questions.find(
              (quest) => quest.QuestionCode == action.payload.key
            ).AnswerId,
            action.payload.answerId
          );
        });
      } else {
        return produce(state, (draft) => {
          draft.isDirtyAddNewAndEditProductInterest = true;

          draft.customerInformation.CPCustomerInterest.Products.find((product) => product.ProductId == action.payload.productId).Questions.find(
            (quest) => quest.QuestionCode == action.payload.key
          ).Answer = action.payload.value;

          draft.customerInformation.CPCustomerInterest.Products.find((product) => product.ProductId == action.payload.productId).Questions.find(
            (quest) => quest.QuestionCode == action.payload.key
          ).AnswerId = action.payload.answerId;
        });
      }

    case clientTellingPortalCustomerTypes.DIRTY_PRODUCT_INTEREST:
      draft.isDirtyAddNewAndEditProductInterest = action.payload;
      break;

    case clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT:
      draft.isUpdateContactLoading = true;

      draft.isUpdateContactSuccess = false;
      break;

    case clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_SUCCESS:
      draft.isUpdateContactLoading = false;

      draft.isUpdateContactSuccess = true;
      break;

    case clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_FAILED:
      draft.isUpdateContactLoading = false;

      draft.errorData = action.payload;

      draft.isUpdateContactSuccess = false;
      break;

    case clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_INTEREST:
      draft.isUpdateContactInterestLoading = true;
      break;

    case clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_INTEREST_SUCCESS:
      draft.isUpdateContactInterestLoading = false;
      break;

    case clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_INTEREST_FAILED:
      draft.isUpdateContactInterestLoading = false;
      break;

    case clientTellingPortalCustomerTypes.GET_CUSTOMER_PRODUCTS:
      draft.isLoading = true;

      break;

    case clientTellingPortalCustomerTypes.GET_CUSTOMER_PRODUCTS_SUCCESS:
      draft.isLoading = false;

      draft.customerProducts = action.payload.data;

      draft.externalId = action.payload.externalId;
      break;

    case clientTellingPortalCustomerTypes.GET_CUSTOMER_PRODUCTS_FAILED:
      draft.isLoading = false;

      draft.customerInformation = draft.tmpCustomerInformationQA;
      break;

    case clientTellingPortalCustomerTypes.GET_ORDER_ID:
      draft.customerInformation = {
        ...draft.customerInformation,
        CPCustomerInfo: {
          ...draft.customerInformation.CPCustomerInfo,
          ReferPOS: action.payload.referPOS
        }
      };

      draft.POSOrderId = action.payload.orderId;

      draft.InvoiceNumber = action.payload.invoiceNumber;

      if (!draft.isKeepLifeStyleData) {
        draft.tmpCustomerInformation = {
          ...draft.tmpCustomerInformation,
          CPCustomerInfo: {
            ...draft.customerInformation.CPCustomerInfo,
            ...draft.tmpCustomerInformation.CPCustomerInfo,
            ReferPOS: action.payload.referPOS
          }
        };
      } else {
        draft.isKeepLifeStyleData = false;
      }
      break;

    case clientTellingPortalCustomerTypes.SET_IS_ACTIVE_QA_CUSTOMER_BASIC_PROFILE:
      draft.tmpCustomerInformationQA = draft.customerInformation;
      break;

    case buyingOptionsTypes.UPLOAD_BO_TRADEIN_FILE_SUCCESS:
      draft.customerInformation = {
        ...draft.customerInformation,
        CPCustomerInfo: {
          ...draft.customerInformation.CPCustomerInfo,
          SignatureImageUrl: action.payload?.[0] || ''
        }
      };

      draft.tmpCustomerInformation = {
        ...draft.customerInformation,
        CPCustomerInfo: {
          ...draft.customerInformation.CPCustomerInfo,
          SignatureImageUrl: action.payload
        }
      };
      break;

    case clientTellingPortalCustomerTypes.GET_COUPON_WALLET:
      draft.isLoading = true;
      break;

    case clientTellingPortalCustomerTypes.GET_COUPON_WALLET_SUCCESS:
      draft.isLoading = false;

      draft.cpCustomerVoucher = action.payload.Promotions;
      break;

    case clientTellingPortalCustomerTypes.GET_COUPON_WALLET_FAILED:
      draft.isLoading = false;
      break;

    case clientTellingPortalCustomerTypes.CLEAR_INFORMATION:
      draft.isCheckPOSFinal = true;

      draft.contacts[0].json_facet = {};

      draft.PhoneNumber = '';

      draft.tmpCustomerInformation.PersonalInformation = {};

      draft.tmpCustomerInformation.CPCustomerInfo = {};
      break;

    case clientTellingPortalCustomerTypes.KEEP_LIFE_STYLE_DATA:
      draft.isKeepLifeStyleData = !draft.isKeepLifeStyleData;
      break;

    case clientTellingPortalCustomerTypes.GET_CREATE_PDPA_ERROR_CODE:
      draft.createPDPAErrorCode = action.payload;
      break;

    case clientTellingPortalCustomerTypes.REMOVE_CP_PRODUCT_INTEREST:
      draft.isRemoveProductInterestLoading = true;
      break;

    case clientTellingPortalCustomerTypes.REMOVE_CP_PRODUCT_INTEREST_SUCCESS:
      draft.removeProductInterestStatus = action.payload;
      break;

    case clientTellingPortalCustomerTypes.REMOVE_CP_PRODUCT_INTEREST_FAILED:
      draft.removeProductInterestStatus = action.payload;
      break;
    }

    return draft;
  });

export default clientTellingPortalCustomerReducer;
