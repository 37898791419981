import './SpinWinners.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import SpinWinnersModels from './Models/SpinWinnersModels';

const SpinWinners = ({ fields }) => {
  const luckyDrawReducer = useSelector((state) => state.spinAndWinReducer.getAvailableLuckyDrawReducer);
  const luckyDraw = luckyDrawReducer?.luckyDrawData;
  const [spinWinnersFields, setSpinWinnersFields] = useState(null);
  const [isHiddenWinner, setIsHiddenWinner] = useState(false);

  useEffect(() => {
    const spinWinnersModels = new SpinWinnersModels();

    setSpinWinnersFields(spinWinnersModels.getData(deepCopy(fields) || {}));
  }, []);

  const quantityCheck = (arr) => {
    return arr.every((item) => item.Quantity == 0);
  };

  useEffect(() => {
    if (luckyDraw) {
      if (luckyDraw.Id) {
        if (quantityCheck(luckyDraw.Slices)) {
          setIsHiddenWinner(true);
        } else {
          setIsHiddenWinner(false);
        }
      } else {
        setIsHiddenWinner(true);
      }
    } else {
      setIsHiddenWinner(true);
    }
  }, [luckyDraw]);

  return (
    spinWinnersFields && !isHiddenWinner && (
      <div className='spin-winner'>
        <div className='container'>
          <div className='spin-winner__wrap text-center'>
            {spinWinnersFields['Winners'].length > 0 && (
              <div className='spin-winner__box'>
                <Text tag='h2' className='spin-winner__box__title' field={spinWinnersFields['Title Text']} />
                <div className='spin-winner__box__info'>
                  {spinWinnersFields['Winners'].map((item, index) => {
                    return (
                      <div className='winner-info-item' key={index}>
                        <div className='winner-info-item__left'>
                          <Text tag='span' className='winner-info-item__name' field={item.fields['Name']} />
                          <span>&nbsp;</span>
                          <Text tag='span' className='winner-info-item__address' field={spinWinnersFields['From Text']} />
                          <span>&nbsp;</span>
                          <Text tag='span' className='winner-info-item__address' field={item.fields['Area']} />
                        </div>
                        <div className='winner-info-item__right'>
                          <Text tag='span' className='winner-info-item__gift' field={spinWinnersFields['Won Text']} />
                          <span>&nbsp;</span>
                          <Text tag='span' field={item.fields['Gift']} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {/*<div className='spin-winner__btn-share'>*/}
            {/*  <a href='' className='btn btn-outline-CTA2'>*/}
            {/*    <span className='spin-winner__btn-share__icon'></span>*/}
            {/*    <Link field={spinWinnersFields['Share Button Text']}>*/}
            {/*      <Text tag='span' field={spinWinnersFields['Share Button Text']} />*/}
            {/*    </Link>*/}
            {/*  </a>*/}
            {/*</div>*/}
          </div>
        </div>
      </div>
    )
  );
};

SpinWinners.propTypes = {
  fields: PropTypes.any
};

export default SpinWinners;
