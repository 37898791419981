import './MyProductsWidget.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getLatestMyProduct } from '@redux/actions/productWarranty/';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import WarrantyProductItem from '../MyProducts/WarrantyProductItem/index';
import MyProductsWidgetModel from './models/MyProductsWidgetModel';
import MyProductWidgetShimmer from './MyProductWidgetShimmer';
import { checkIsHideRegisterWarrantyBtn } from './utils';

const MyProductsWidget = ({ fields }) => {
  const dispatch = useDispatch();
  const [myProductsWidgetFields, setMyProductsWidgetFields] = useState(null);
  const { productQuantity, isLoading, data } = useSelector((state) => state.productWarrantyReducer.getLatestMyProductReducer);

  const lastestProduct = useMemo(() => {
    return data?.LatestMyProduct;
  }, [data]);

  useEffect(() => {
    const myProductsWidgetModel = new MyProductsWidgetModel();

    setMyProductsWidgetFields(myProductsWidgetModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    dispatch(getLatestMyProduct());
  }, []);

  const isHideRegisterButton = useMemo(() => {
    const warrantyStatus = lastestProduct?.Warranty?.Status;
    const hasWarranty = !lastestProduct?.HasWarranty;
    const enablePosIntegration = lastestProduct?.EnablePosIntegration;

    return checkIsHideRegisterWarrantyBtn({ warrantyStatus, hasWarranty, enablePosIntegration });
  }, [lastestProduct]);

  return myProductsWidgetFields && !isLoading ? (
    <div className='my-account-section my-product-widget'>
      <div className='my-account-section__heading'>
        <div className='my-account-section__group'>
          <div className='my-account-section__heading__title'>
            <Text field={myProductsWidgetFields['My Products Label']} />
          </div>
          {productQuantity ? (
            <Link className='my-account-section__btn-link enable' field={myProductsWidgetFields['View All Link']}>
              <Text field={myProductsWidgetFields['View All Label']} />
            </Link>
          ) : (
            <Text tag='span' className='my-account-section__btn-link disable' field={myProductsWidgetFields['View All Label']} />
          )}
        </div>
        <p className='my-account-section__heading__label'>
          <Text field={{ value: myProductsWidgetFields['You Have X Products Label'].value.replace('{num}', productQuantity) }} />
        </p>
      </div>
      {productQuantity && lastestProduct ? (
        <>
          <div className='my-product-widget__list'>
            <div className='my-product-widget__list__wrap'>
              <WarrantyProductItem fieldData={myProductsWidgetFields} isProductsWidget={true} productItem={lastestProduct} />
            </div>
          </div>
          {!isHideRegisterButton ? (
            <div className='my-product-widget__button__register text-center'>
              <Link className='btn btn-primary' field={myProductsWidgetFields['Register Product and Warranty Button Link']}>
                <Text field={myProductsWidgetFields['Register Product and Warranty Button Label']} />
              </Link>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div className='my-product-widget__button__register text-center'>
          <Link className='btn btn-primary' field={myProductsWidgetFields['Register Product and Warranty Button Link']}>
            <Text field={myProductsWidgetFields['Register Product and Warranty Button Label']} />
          </Link>
        </div>
      )}
    </div>
  ) : (
    <MyProductWidgetShimmer myProductsWidgetFields={myProductsWidgetFields} />
  );
};

MyProductsWidget.propTypes = {
  fields: Proptypes.any
};

export default MyProductsWidget;
