import SitecoreTextModel from '@models/SitecoreTextModel';

import { deepCopy } from '../../../utils/utility';
import BundleBODataModel from './BundleBODataModel';
import BundleBOTextFieldsModel from './BundleBOTextFieldsModel';
import CurrencyModel from './CurrencyModel';

export default class BundleBOFieldsModel {
  constructor() {
    this['Chosen Bundle'] = new BundleBODataModel();

    this['Text Data'] = new BundleBOTextFieldsModel();

    this['Is Dynamic'] = false;

    this['Currency'] = new CurrencyModel();

    this['In Stock Label'] = new SitecoreTextModel();

    this['In Stock With BackOrder Label'] = new SitecoreTextModel();

    this['In Stock With PreOrder Label'] = new SitecoreTextModel();

    this['Out Stock Label'] = new SitecoreTextModel();

    this['Url'] = '';

    this['Payment Gateways'] = [];
  }
  getData(dataSource, SubLines) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Url')) {
      this['Url'] = dataSource['Url'] ? dataSource['Url'] : this['Url'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Chosen Bundle')) {
      this['Chosen Bundle'].getData(dataSource['Chosen Bundle'], SubLines, this['Url']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Text Data')) {
      this['Text Data'].getData(dataSource['Text Data']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Dynamic')) {
      this['Is Dynamic'] = dataSource['Is Dynamic'] ? dataSource['Is Dynamic'] : this['Is Dynamic'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Currency')) {
      this['Currency'] = dataSource['Currency'] ? dataSource['Currency'] : this['Currency'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'In Stock Label')) {
      this['In Stock Label'].getData(dataSource['In Stock Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'In Stock With BackOrder Label')) {
      this['In Stock With BackOrder Label'].getData(dataSource['In Stock With BackOrder Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'In Stock With PreOrder Label')) {
      this['In Stock With PreOrder Label'].getData(dataSource['In Stock With PreOrder Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Out Stock Label')) {
      this['Out Stock Label'].getData(dataSource['Out Stock Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Payment Gateways')) {
      this['Payment Gateways'] = deepCopy(dataSource['Payment Gateways']);
    }

    return this;
  }
}
