import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class EndDemoModel {
  constructor() {

    this['Image'] = new SitecoreImageModel();

    this['Button Text'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Button Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Text')) {
      this['Button Text'].getData(dataSource['Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Link')) {
      this['Button Link'].getData(dataSource['Button Link']);
    }

    return this;
  }
}
