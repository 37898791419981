import SitecoreImageModel from '@models/SitecoreImageModel';
import { deepCopy } from '@utils/utility';

import AnswerModel from './AnswerModel';

export default class QuestionModel {
  constructor() {
    this['Answers'] = [];

    this['Display Type'] = '';

    this['Image'] = new SitecoreImageModel();

    this['Is Required'] = '';

    this['POS Related'] = '';

    this['Question'] = '';

    this['Question Code'] = '';

    this['Question Type']='';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Answers')) {
      dataSource['Answers'].map((element) => {
        this.Answers.push(new AnswerModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Display Type')) {
      this['Display Type'] = dataSource['Display Type'] ?? this['Display Type'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Required')) {
      this['Is Required'] = dataSource['Is Required'] ?? this['Is Required'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'POS Related')) {
      this['POS Related'] = dataSource['POS Related'] ?? this['POS Related'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Question')) {
      this['Question'] = dataSource['Question'] ?? this['Question'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Question Code')) {
      this['Question Code'] = dataSource['Question Code'] ?? this['Question Code'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Question Type')) {
      this['Question Type'] = dataSource['Question Type'] ?? this['Question Type'];
    }

    return this;
  }
}
