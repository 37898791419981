import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'EventId',
  'Item Name',
  'Title',
  'Description',
  'From Time',
  'To Time',
  'Multiple Date And Time Text',
  'Success Title',
  'Event Confirmation Text',
  'See You Text',
  'View Event Detail Button Text',
  'Guest Label',
  'View Detail Button Text',
  'View Detail Button Link',
  'Time Slots',
  'Date Selected'
];
const IMG_ARR = ['Thumnail Image', 'Banner Image', 'Success Icon'];
const LINK_ARR = ['View Event Detail Button Link'];

export default class MemberEventRegisteredOrChangedModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    IMG_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['From Date'] = '';

    this['To Date'] = '';

    this['Gallery Images'] = [];

    this['Location'] = [];

    this['Question and Answer'] = [];

    this['Member Tier'] = [];

    this['Number Of Guest'] = null;
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMG_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'From Date')) {
      this['From Date'] = deepCopy(dataSource['From Date']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'To Date')) {
      this['To Date'] = deepCopy(dataSource['To Date']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Gallery Images')) {
      this['Gallery Images'] = deepCopy(dataSource['Gallery Images']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Location')) {
      this['Location'] = deepCopy(dataSource['Location']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Question and Answer')) {
      this['Question and Answer'] = deepCopy(dataSource['Question and Answer']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Member Tier')) {
      this['Member Tier'] = deepCopy(dataSource['Member Tier']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Number Of Guest')) {
      this['Number Of Guest'] = deepCopy(dataSource['Number Of Guest']);
    }

    return this;
  }
}
