import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import QuestionModel from './QuestionModel';

const ICON_ARR = [
  'Dial Button Icon',
  'Job Nature Icon',
  'Health Issues Icon',
  'Phone Icon',
  'Tier Icon',
  'Mail Icon',
  'Show Info Icon',
  'Message Button Icon',
  'Gender Icon',
  'Marital Status Icon',
  'Birthday Icon',
  'Age Icon',
  'Household Size Icon',
  'Owned Product Icon',
  'SMS Icon'
];

export default class ReviewCustomerInformationModel {
  constructor() {
    ICON_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    this['Title'] = new SitecoreTextModel();

    this.Questions = [];

    this['Massage Ownership Types'] = [];
  }

  getData(dataSource) {
    ICON_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Questions')) {
      dataSource['Questions'].map((element) => {
        this.Questions.push(new QuestionModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Massage Ownership Types')) {
      dataSource['Massage Ownership Types'].map((element) => {
        this['Massage Ownership Types'].push(deepCopy(element) || {});
      });
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Communication Method Hidden')) {
      this['Is Communication Method Hidden'] = dataSource['Is Communication Method Hidden'];
    }

    return this;
  }
}
