const STRINGARRAY = ['Id', 'FriendlyId', 'Title', 'Code', 'DisplayText', 'Discount', 'Validity', 'ValidityTo', 'Description', 'RedirectLink'];

export default class CouponWalletItemModel {
  constructor() {
    STRINGARRAY.map((item) => {
      this[item] = '';
    });
  }

  getData(dataSource) {
    STRINGARRAY.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}
