import produce from 'immer';

import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';

const initState = {
  isLoading: false,
  submitProductReviewSuccess: false,
  submitedData: {},
  isShowLogin: false,
  reviewFormParams: null,
  submitReviewFalse: '',
  formatCheckedData: null,
};

const submitProductReviewReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productReviewTypes.SUBMIT_PRODUCT_REVIEW:
      draft.isLoading = true;

      draft.submitProductReviewSuccess = false;
      
      draft.submitReviewFalse = '';
      break;

    case productReviewTypes.SUBMIT_PRODUCT_REVIEW_SUCCESS:
      draft.isLoading = false;

      draft.submitProductReviewSuccess = true;

      draft.submitedData = action.payload;
      break;

    case productReviewTypes.SUBMIT_PRODUCT_REVIEW_FAILED:
      draft.isLoading = false;

      draft.submitProductReviewSuccess = false;

      draft.submitReviewFalse = action.error;
      break;

    case productReviewTypes.TOGGLE_REVIEW_LOGIN:
      draft.isShowLogin = action.payload;
      break;
      
    case productReviewTypes.REVIEW_FORM_PARAMS:
      draft.reviewFormParams = action.payload;
      break;

    case productReviewTypes.SERIAL_NUMBER_FORMAT_CHECKER:
      draft.isLoading = true;
      
      draft.formatCheckedData = null;
      break;

    case productReviewTypes.SERIAL_NUMBER_FORMAT_CHECKER_SUCCESS:
      draft.isLoading = false;
  
      draft.formatCheckedData = action.payload;
      break;
  
    case productReviewTypes.SERIAL_NUMBER_FORMAT_CHECKER_FAILED:
      draft.isLoading = false;
  
      draft.formatCheckedData = action.error;
      break;
    
    }

    return draft;
  });

export default submitProductReviewReducer;
