import produce from 'immer';

import {
  GET_CRM_CUSTOMER_FAILED,
  GET_CRM_CUSTOMER_SUCCESS
} from '@redux/actions/loyalty/getCrmCustomer/getCrmCustomerTypes';
import {
  GET_POS_CUSTOMER_ID
} from '@redux/actions/loyalty/getPosCustomerId/getPosCustomerIdTypes';

const initialState = {
  isLoading: false,
  error: null,
  dataCrm: []
};

const getCrmCustomerReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_POS_CUSTOMER_ID:
      draft.isLoading = true;
      break;

    case GET_CRM_CUSTOMER_SUCCESS:
      draft.isLoading = false;

      draft.dataCrm = action.payload;
      break;

    case GET_CRM_CUSTOMER_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getCrmCustomerReducer;
