import produce from 'immer';

import * as productTypes from '@redux/actions/product/productTypes';

const initState = {
  isLoading: false,
  warrantySelected: null,
  warrantyList: null,
};

const warrantyReducer = (state = initState, action) =>
  produce(state, draft => {
    switch (action.type) {
    case productTypes.SET_ACTIVE_WARRANTY:
      draft.warrantyList = action.payload.warrantyListCurrent;

      draft.warrantyList.forEach((itemWarranty, itemWarrantyIdx) => {
        if (itemWarrantyIdx === action.payload.indexSelected) {
          itemWarranty.isActive = true;
        } else {
          itemWarranty.isActive = false;
        }
      });
      break;

    case productTypes.SET_SELECTED_WARRANTY: 

      draft.warrantyList.forEach((itemWarranty, itemWarrantyIdx) => {
        if (itemWarranty.isActive) {
          itemWarranty.isSelected = true;

          itemWarranty.isDefaultWarranty = itemWarrantyIdx === 0 ? true: false;

          draft.warrantySelected = itemWarranty;
          
        } else {
          itemWarranty.isDefaultWarranty = false;
          
          itemWarranty.isSelected = false;
        }
      });
      break;
    }

    return draft;

  });

export default warrantyReducer;
