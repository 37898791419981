import {
  UPDATE_BOOKING_EXPERIENCE_CONTACT_FAILURE,
  UPDATE_BOOKING_EXPERIENCE_CONTACT_START,
  UPDATE_BOOKING_EXPERIENCE_CONTACT_SUCCESS
} from './types';

export const updateBookingExperienceContactStart = (payload) => ({
  type: UPDATE_BOOKING_EXPERIENCE_CONTACT_START,
  payload
});

export const updateBookingExperienceContactSuccess = (payload) => ({
  type: UPDATE_BOOKING_EXPERIENCE_CONTACT_SUCCESS,
  payload
});

export const updateBookingExperienceContactFailure = (payload) => ({
  type: UPDATE_BOOKING_EXPERIENCE_CONTACT_FAILURE,
  payload
});
