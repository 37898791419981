import Proptypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import BOTradeInList from '../BOTradeInList';

const BOTradeInContent = ({ contentData, dataFields, activeItemsId, onToogleFnc, tradeInPopUp, onShowTradeInOptionsModal }) => {
  const BOTradeInReducer = useSelector((state) => state.buyingOptionReducer.BOTradeInReducer);
  let params = {
    centered: true
  };

  return (
    contentData && (
      <div className='tradein-buying-content'>
        {BOTradeInReducer?.tradeInActive ? (
          <></>
        ) : (
          <>
            <Text tag='p' field={dataFields['Trade In Description Text']} />
            <button className='btn btn-outline-CTA2' onClick={() => onShowTradeInOptionsModal({ status: 'toggleTradeInOptionsPopup' })}>
              {dataFields['Add Trade In Button'].value}
            </button>
          </>
        )}
        <ModalComponent
          isShow={tradeInPopUp}
          onCloseHandler={() => onShowTradeInOptionsModal({ status: 'toggleTradeInOptionsPopup' })}
          param={params}
        >
          <BOTradeInList
            contentData={contentData}
            activeItemsId={activeItemsId}
            onToogleFnc={onToogleFnc}
            // onCloseModalHandler={() => setShow(false)}
            dataFields={dataFields}
            onShowTradeInOptionsModal={onShowTradeInOptionsModal}
          />
        </ModalComponent>
      </div>
    )
  );
};

BOTradeInContent.propTypes = {
  contentData: Proptypes.any,
  activeItemsId: Proptypes.any,
  onToogleFnc: Proptypes.any,
  limit: Proptypes.any,
  dataFields: Proptypes.any,
  // TradeInOptions
  tradeInPopUp: Proptypes.bool,
  onShowTradeInOptionsModal: Proptypes.func
};

export default BOTradeInContent;
