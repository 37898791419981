import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ProductBannerFieldsModel {
  constructor() {
    this['Background Image'] = new SitecoreImageModel();

    this['Banner Click Hyperlink'] = new SitecoreLinkModel();

    this['CTA Button'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();

    this.Description = new SitecoreTextModel();

    this['Mobile Background Image'] = new SitecoreImageModel();

    this['Sub Title'] = new SitecoreTextModel();

    this.Title = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Image')) {
      this['Background Image'] = dataSource['Background Image'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Click Hyperlink')) {
      this['Banner Click Hyperlink'] = dataSource['Banner Click Hyperlink'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'] = dataSource['CTA Button'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'] = dataSource['CTA Button Text'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Background Image')) {
      this['Mobile Background Image'] = dataSource['Mobile Background Image'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sub Title')) {
      this['Sub Title'] = dataSource['Sub Title'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title = dataSource.Title;
    }

    return this;
  }
}
