import './MobileApp.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import MobileAppModel from '../Models/MobileAppModel';
import CarouselImage from './CarouselImage';
import ListApp from './ListApp';

const MobileApp = ({fields}) => {
  const [mobileAppFields, setMobileAppFields] = useState(null);
  const [headerHeight, setHeaderHeight] = useState(null);

  useEffect(() => {
    const mobileAppModel = new MobileAppModel();

    setMobileAppFields(mobileAppModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  useEffect(() => {
    Global.scrollWithHash(headerHeight);
  }, [mobileAppFields, headerHeight]);

  return mobileAppFields && (
    <div id='mobileapp' className='mobile-app'>
      <div className='container'>
        <Text tag='h2' field={mobileAppFields.Title} className='text-center section-title' />
        <div className='mobile-app__image text-center'>
          <Image media={mobileAppFields['App Image']}/>
        </div>
        <Text tag='p' field={mobileAppFields.Description} className='mobile-app__link text-center' />
        <div className='list-app'>
          <div className='row'>
            {
              mobileAppFields['App List'].length ? (
                mobileAppFields['App List'].map((app) => (
                  <div className='col-4' key={app.id}>
                    <ListApp productApp={app}></ListApp>
                  </div>
                ))
              ) : ''
            }
          </div>
        </div>
        <div className='carousel-image'>
          <CarouselImage imageList={mobileAppFields['App Screenshots']}></CarouselImage>
        </div>
      </div>
    </div>
  );
};

MobileApp.propTypes = {
  fields: PropTypes.object
};

export default MobileApp;
