import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ItemsFieldsModel {
  constructor() {
    this['Description'] = new SitecoreTextModel();

    this['Icon'] = new SitecoreImageModel();

    this['Icon Title'] = new SitecoreTextModel();

    this['Image'] = new SitecoreImageModel();

    this['Item Title'] = new SitecoreTextModel();

    this['Link'] = new SitecoreLinkModel();

    this['Mobile Image'] = new SitecoreImageModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon')) {
      this['Icon'].getData(dataSource['Icon']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon Title')) {
      this['Icon Title'].getData(dataSource['Icon Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Item Title')) {
      this['Item Title'].getData(dataSource['Item Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this['Link'].getData(dataSource['Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Image')) {
      this['Mobile Image'].getData(dataSource['Mobile Image']);
    }

    return this;
  }
}
