import './PasswordManagementWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link,Text } from '@sitecore-jss/sitecore-jss-react';

import PasswordManagementWidgetModel from './models/PasswordManagementWidgetModel';

const PasswordManagementWidget = ({fields}) => {
  const [dataSources, setDataSources] = useState(null);
  
  useEffect(() => {
    setDataSources(new PasswordManagementWidgetModel().getData(fields || {}));
  }, []);

  return dataSources ? (
    <div className='my-account-widget password-widget animated-slow fadeIn delay-100'>
      <div className='my-account-widget__heading my-account-section__group'>
        <h4 className='my-account-widget__heading__title'><Text field={dataSources['Title']}/></h4>
        {dataSources['Update Text'].value ? (
          <Link className='my-account-section__btn-link' field={dataSources['Update Link']}>
            <Text field={dataSources['Update Text']} />
          </Link>
        ) : (
          <Link className='my-account-section__btn-link' field={dataSources['Update Link']} />
        )}
      </div>
      <div className='my-account-widget__content password-widget__content'>
        <span className='password-widget__content__label'><Text field={dataSources['Current Password Label']}/></span>
        <span className='password-widget__content__value'> • • • • • • • •</span>
      </div>
    </div>
  ) : (
    <div className='my-account-widget contact-widget skeleton__item'>
      <div className='my-account-widget__heading my-account-section__group'>
        <span className='text-line'></span>
        <span className='clear-10'></span>
        <span className='text-line'></span>
      </div>
      <div className='my-account-widget__content'>
        <span className='text-line-20'></span>
      </div>
    </div>
  );
};

PasswordManagementWidget.propTypes = {
  fields: PropTypes.object
};

export default PasswordManagementWidget;
