import produce from 'immer';

import {
  RESCHEDULE_EVENT_BOOKING_FAILED,
  RESCHEDULE_EVENT_BOOKING_START,
  RESCHEDULE_EVENT_BOOKING_SUCCESS
} from '@redux/actions/eventBooking/eventBookingReschedule/eventBookingRescheduleTypes';

const initialState = {
  isLoading: false,
  rescheduleResponse: null,
  error: null
};

const eventBookingRescheduleReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case RESCHEDULE_EVENT_BOOKING_START:
      draft.isLoading = true;
      break;

    case RESCHEDULE_EVENT_BOOKING_SUCCESS:
      draft.isLoading = false;

      draft.rescheduleResponse = action.payload;
      break;

    case RESCHEDULE_EVENT_BOOKING_FAILED:
      draft.isLoading = false;

      draft.error = `${action.payload}`;
      break;
    }

    return draft;
  });

export default eventBookingRescheduleReducer;
