import Proptypes from 'prop-types';
import React from 'react';

import CPHeaderIcon from './CPHeaderIcon';

const CPHeaderIconList = ({fields}) => {
  
  return (
    <div className='cp-header__col header-icon-list content-right'>
      <ul className='icon-group'>
        {
          fields?.items?.length ? (
            fields.items.map((data, index) => (
              <CPHeaderIcon
                key={data.id || index}
                data={data}/>
            ))
          ) : ''
        }
      </ul>
    </div>
  );
};

CPHeaderIconList.propTypes = {
  fields: Proptypes.object
};


export default CPHeaderIconList;