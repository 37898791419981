import produce from 'immer';

import BOAdditionalOptionsModel from '@components/BuyingOptions/BOAdditionalOptions/model/BOAdditionalOptionsModel';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as productTypes from '@redux/actions/product/productTypes';
import { PROMO_ADDITIONAL_TYPE } from '@utils/constant';
import { deepCopy } from '@utils/utility.js';

const initState = {
  additionalOptionsList: {},
  additionalOptionsActive: {},
  relationshipIdArray: []
};

const BOAdditionalOptionsReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let newAdditionalOptionsList = [];
    let tmpAdditionalOptionsObj = {};
    let filterAdditionalOptionsFromCartLine = [];

    switch (action.type) {
    case buyingOptionsTypes.GET_BO_DATA_SUCCESS:
      if (action.payload.ProductSummary.AdditionalOptions?.length) {
        action.payload.ProductSummary.AdditionalOptions.map((element) => {
          newAdditionalOptionsList.push(new BOAdditionalOptionsModel().getData(deepCopy(element) || {}));
        });

        newAdditionalOptionsList.forEach((item) => {
          if (draft.relationshipIdArray.includes(item.RelationshipId)) {
            tmpAdditionalOptionsObj[item.RelationshipId] = item;
          }
        });

        draft.additionalOptionsList = deepCopy(tmpAdditionalOptionsObj);
      }
      break;

    case buyingOptionsTypes.ADD_CHOSEN_BO_ADDITIONAL_OPTIONS:{
      const isRequired = action.payload.additionalOptionsRequired;

      if(isRequired){
        draft.additionalOptionsActive[action.payload.relationshipId] = action.payload.itemID;

        draft.additionalOptionsList[action.payload.relationshipId].selectedOption = action.payload.itemID;

        action.payload.toogleFnc();
      }
      else{
        if(draft.additionalOptionsActive[action.payload.relationshipId])
          delete draft.additionalOptionsActive[action.payload.relationshipId];
        else {
          draft.additionalOptionsActive[action.payload.relationshipId] = action.payload.itemID;
          
          action.payload.toogleFnc();
        }
        
        if(draft.additionalOptionsList[action.payload.relationshipId].selectedOption)
          draft.additionalOptionsList[action.payload.relationshipId].selectedOption = undefined;
        else draft.additionalOptionsList[action.payload.relationshipId].selectedOption = action.payload.itemID;
      }

      break;
    }
    
    case buyingOptionsTypes.GET_RELATIONSHIPID:
      draft.relationshipIdArray = action.payload;
      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:
      if (action.filteredCartLine.length) {
        filterAdditionalOptionsFromCartLine = action.filteredCartLine[0].PromotionCartLines?.filter((item) => item.Type === PROMO_ADDITIONAL_TYPE);

        if (filterAdditionalOptionsFromCartLine.length) {
          filterAdditionalOptionsFromCartLine.forEach((option) => {
            draft.additionalOptionsActive[option.RelationshipId] = option.ProductId;
          });
        }
      }

      break;

    case productTypes.GET_CURRENT_SO_CART_SUCCESS:
      if (action.filteredCartLine.length) {
        filterAdditionalOptionsFromCartLine = action.filteredCartLine[0].PromotionCartLines?.filter((item) => item.Type === PROMO_ADDITIONAL_TYPE);

        if (filterAdditionalOptionsFromCartLine.length) {
          filterAdditionalOptionsFromCartLine.forEach((option) => {
            draft.additionalOptionsActive[option.RelationshipId] = option.ProductId;
          });
        }
      }

      break;
    }

    return draft;
  });

export default BOAdditionalOptionsReducer;
