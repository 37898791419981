import './CustomPaginationSwiper.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

const CustomPaginationSwiper = (props) => {
  const { swiper, activeIndex } = props;
  const [activeSlideKey, setSlideKey] = useState(+activeIndex);

  useEffect(() => {
    setSlideKey(activeIndex);
  }, [activeIndex]);

  const onSlideToHandler = (slideKey) => {
    setSlideKey(+slideKey);

    props.setActiveKeyEvt(+slideKey);
  };

  const onNextSlideHandler = () => {
    if (swiper?.loop) {
      if (activeSlideKey === swiper.children.length - 1) {
        props.nextSlideEvt(0);

        setSlideKey(0);
      } else {
        props.nextSlideEvt(activeSlideKey + 1);

        setSlideKey(activeSlideKey + 1);
      }
    } else if (activeSlideKey < swiper.children.length - 1 && !swiper?.loop) {
      props.nextSlideEvt(activeSlideKey + 1);

      setSlideKey(activeSlideKey + 1);
    }
  };

  const onPrevSlideHandler = () => {
    if (swiper?.loop) {
      if (activeSlideKey === 0) {
        props.prevSlideEvt(swiper.children.length - 1);

        setSlideKey(swiper.children.length - 1);
      } else {
        props.prevSlideEvt(activeSlideKey - 1);

        setSlideKey(activeSlideKey - 1);
      }
    } else if (activeSlideKey !== 0 && !swiper?.loop) {
      setSlideKey(activeSlideKey - 1);

      props.prevSlideEvt(activeSlideKey - 1);
    }
  };

  return swiper.children?.length > 1 ? (
    <div className='custom-pagination'>
      <span className={`custom-pagination__prev ${(activeSlideKey === 0 && !swiper?.loop) ? 'is-disable': ''}`} onClick={onPrevSlideHandler}>
        <i className='icon-chevron-left-big'></i>
      </span>
      <div className='custom-pagination-bullets'>
        {swiper.children.map((slideItem) => (
          <span className={`custom-pagination-bullets__icon ${activeSlideKey === +slideItem.key ? 'is-active' : ''}`} onClick={() => onSlideToHandler(slideItem.key)} key={slideItem.key}>
            {slideItem.key}
          </span>
        ))}
      </div>
      <span className={`custom-pagination__next ${(activeSlideKey === (swiper.children.length - 1) && !swiper?.loop) ? 'is-disable': ''}`} onClick={onNextSlideHandler}>
        <i className='icon-chevron-right-big'></i>
      </span>
    </div>
  ) : '';
};

CustomPaginationSwiper.propTypes = {
  swiper: Proptypes.object,
  prevSlideEvt: Proptypes.func,
  setActiveKeyEvt: Proptypes.func,
  nextSlideEvt: Proptypes.func,
  activeIndex: Proptypes.any
};

export default CustomPaginationSwiper;
