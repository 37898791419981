import { deepCopy } from '@utils/utility';

import IncludedProductModel from './IncludedProductModel';
import ListPriceModel from './ListPriceModel';

export default class BundleElementsModel {
  constructor() {
    this['BundleId'] = '';

    this['BundleName'] = '';

    this['DiscountPercent'] = 0;

    this['IncludedProducts'] = [];

    this['ListPrice'] = new ListPriceModel();

    this['SellPrice'] = new ListPriceModel();

    this['SplitQty'] = false;

    this['InstallmentPeriods'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleId')) {
      this['BundleId'] = dataSource['BundleId'] ? dataSource['BundleId'] : this['BundleId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleName')) {
      this['BundleName'] = dataSource['BundleName'] ? dataSource['BundleName'] : this['BundleName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DiscountPercent')) {
      this['DiscountPercent'] = dataSource['DiscountPercent'] ? dataSource['DiscountPercent'] : this['DiscountPercent'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IncludedProducts')) {
      let newIncludedProducts = [];

      if (dataSource['IncludedProducts']) {
        dataSource['IncludedProducts'].map((ele) => {
          newIncludedProducts.push(new IncludedProductModel().getData(deepCopy(ele)));
        });
      }

      this['IncludedProducts'] = dataSource['IncludedProducts'] ? newIncludedProducts : this['IncludedProducts'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this['ListPrice'].getData(dataSource['ListPrice']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellPrice')) {
      this['SellPrice'].getData(dataSource['SellPrice']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SplitQty')) {
      this['SplitQty'] = dataSource['SplitQty'] ? dataSource['SplitQty'] : this['SplitQty'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentPeriods')) {
      this['InstallmentPeriods'] = dataSource['InstallmentPeriods'] ?? this['InstallmentPeriods'];
    }

    return this;
  }
}
