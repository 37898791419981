import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Title',
  'CP Cart Title',
  'CP Cart Empty Text',
  'Cart Total Amount Label',
  'Cart Total Quantity Message',
  'Deposit Label',
  'Check Out Text',
  'CTA Button',
  'CTA Button Text',
  'Remove Button Text',
  'Edit Button Text',
  'Cancel Button Text',
  'Saved Message',
  'Free Gift Label',
  'Gifting Option Label',
  'Fulfillment Option Label',
  'Delivery Label',
  'Store Pick Up Label',
  'Store Availability Message',
  'Shipping Message',
  'Sub Total Label',
  'Cart Empty Text',
  'Free Gift Hint',
  'Free Text',
  'Special Deal Label',
  'SF Express Station Label',
  'Available Label',
  'SF Express Popup Title',
  'SF Express Popup Description',
  'Quantity Items Label',
  'Quantity Item Label',
  'SF Express NetWork Label',
  'SF Express Area Label',
  'SF Express District Label',
  'Free Gift Number Label',
  'Trade In Label',
  'Select Gifting Option Label',
  'Gift Wrap Label',
  'Gift Card Label',
  'Gift Message Label',
  'Characters Label',
  'Add Selection Label',
  'Save Gift Label',
  'Remove Gift Label',
  'Add Gifting Option Label',
  'Close label',
  'Offer From Label',
  'Offer Valid Label',
  'Back Link Button Label',
  'Back Link Button Url',
  'Free Gift Out Of Stock Message',
  'Remove Product Title',
  'Remove Product Description',
  'Move To Wishlist Label',
  'Remove Product Label',
  'Character Limit',
  'Balance Payment Label',
  'Payment Period Label',
  'Payment Period Details',
  'Pre Order Label',
  'Pay Deposit Label',
  'Warning Details',
  'Close Button Text',
  'Staff Name'
];
const LINK_ARR = ['Guest Checkout Page'];
const IMG_ARR = ['CP Cart Icon'];

const OTHERS_FIELDS_ARR = [
  'Buying Option Page',
  'Buying Option Link',
  'Checkout Link',
  'Additional Link',
  'Fulfillment Link',
  'Bundle Buying Option Link',
  'Address Settings',
  'Additional Options Setting'
];

export default class OfferDetailsModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMG_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    OTHERS_FIELDS_ARR.forEach((item) => {
      this[item] = null;
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMG_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    OTHERS_FIELDS_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    return this;
  }
}
