import React, { createContext, useContext, useEffect } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { useNavigateNextStep } from '../../hooks/useNavigateNextStep';
import { useScheduleAppointmentContext } from '../../hooks/useScheduleAppointmentContext';
import { SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM, SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE } from '../configs';
import AdditionalSelection from './AdditionalSelection';
import SelectScent from './SelectScent';
import SelectTea from './SelectTea';
import { usePersonalizeExperienceFormik } from './usePersonalizeExperienceFormik';

const initialStates = {
  formik: {}
};
const PersonalizeExperienceFormContext = createContext(initialStates);

export const usePersonalizeExpFormContext = () => useContext(PersonalizeExperienceFormContext);

const PersonalizeExperienceForm = () => {
  const { layoutData, scheduleData, setScheduleData } = useScheduleAppointmentContext();
  const navigateNextStep = useNavigateNextStep();

  useEffect(() => {
    if (!layoutData?.appointment && !Object.keys(scheduleData.scheduleExperience).every((key) => scheduleData.scheduleExperience[key]))
      navigateNextStep(SCHEDULE_APPOINTMENT_STEP_SCHEDULE_EXPERIENCE);
  }, [scheduleData.scheduleExperience, layoutData]);

  const handleConfirmSelection = (values) => {
    setScheduleData((prev) => ({
      ...prev,
      personalizeExperience: {
        ...prev.personalizeExperience,
        additionalSelectionAns: [...values.additionalSelectionAns]
      }
    }));

    navigateNextStep(SCHEDULE_APPOINTMENT_STEP_CONTACT_FORM);
  };
  const formik = usePersonalizeExperienceFormik(handleConfirmSelection);

  return (
    <PersonalizeExperienceFormContext.Provider value={{ formik: formik }}>
      <div className='be-schedule-appointment__personalize-experience'>
        <Text className='be-schedule-appointment__title' field={layoutData['Personalize Experience Title']} tag='h2' />
        <form onSubmit={formik.handleSubmit} className='personalize-experience__body'>
          <SelectTea />
          <SelectScent />
          <AdditionalSelection />
          <div className='be-schedule-appointment__submit-btn'>
            <button type='submit' className='btn btn-primary'>
              <Text field={layoutData['Button Confirm Selection Text']} />
            </button>
          </div>
        </form>
      </div>
    </PersonalizeExperienceFormContext.Provider>
  );
};

export default PersonalizeExperienceForm;
