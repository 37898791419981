import './StoreLocator.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getStoreLocator } from '@redux/actions/storeLocator';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import LoadingShimmer from '../LoadingShimmer';
import StoreLocatorFieldsModel from './model/StoreLocatorFieldsModel';
import {ROADSHOW_LISTING_PAGE,STORE_LISTING_PAGE, STORE_LOCATOR} from './storeConstant';
import StoreLocatorContent from './StoreLocatorContent';
import StoreLocatorShimmer from './StoreLocatorShimmer';

const StoreLocator = (props) => {
  const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');

  const [dataFromLayout, setDataFromLayout] = useState(null);

  const isLoading = useSelector((state) => state.storeLocatorReducer.allStoreReducer.isLoading);

  const dispatch = useDispatch();

  useEffect(() => {
    switch(templateName) {
    case STORE_LOCATOR:
      dispatch(getStoreLocator());
      break;

    case STORE_LISTING_PAGE:
      dispatch(getStoreLocator({isStoreSearch: true}));
      break;

    case ROADSHOW_LISTING_PAGE:
      dispatch(getStoreLocator({isStoreSearch: false}));
      break;
    default:
    }
  }, []);

  useEffect(() => {
    const model = new StoreLocatorFieldsModel();

    setDataFromLayout(model.getData(props.fields || {}));
  }, []);

  return dataFromLayout && !isLoading ? (
    <div className='store-locator'>
      <div className='store-locator__heading animated-slow fadeIn 100'>
        <div className='container'>
          <Text className='page-title store-locator__heading__title' field={dataFromLayout['Title']} tag='h2' />
          <RichText className='store-locator__heading__desc' field={dataFromLayout['Description']} tag='p' />
        </div>
      </div>
      <div className='store-locator__content animated-slow fadeIn delay-100'>
        <div className='container'>
          <StoreLocatorContent dataFromLayout={dataFromLayout}/>
        </div>
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <StoreLocatorShimmer></StoreLocatorShimmer>
    </LoadingShimmer>
  );
};

StoreLocator.propTypes = {
  fields: PropTypes.any
};

export default StoreLocator;
