import React, { useState } from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import HowToPlayPopup from '@components/SpinAndWin/HowToPlayPopup';

const HelpIcon = () => {
  const [showPopup, setShowPopup] = useState(false);

  const onClosePopupHandler = () => {
    setShowPopup(false);
  };

  return (
    <>
      <div className='spin-wheel-icon spin-wheel-icon--help' onClick={() => setShowPopup(true)}>
      </div>
      <ModalComponent
        isShow={showPopup}
        onCloseHandler={onClosePopupHandler}
      >
        <HowToPlayPopup/>
      </ModalComponent>
    </>
  );
};

export default HelpIcon;