import Proptypes from 'prop-types';
import React, { useEffect } from 'react';
import { Fragment } from 'react';

import Message from '@components/Share/Message';

const BundleBOVariantsList = ({ contentData, parentId, onAction, onToogleFnc }) => {
  useEffect(() => {
    if (contentData.length === 1) {
      onClickBuyingItemHandler(contentData[0].ProductId, parentId);
    }
  }, []);

  const onClickBuyingItemHandler = (itemId, parentId) => {
    onAction(itemId, parentId);

    onToogleFnc();
  };
  
  return (
    <div className='warranty-buying-list'>
      <div className='row'>
        {contentData.map((item, itemIndex) => {
          return (
            <div className='col-lg-6 warranty-buying-list__item-wrap' key={itemIndex}>
              <div
                className={'warranty-buying-list__item' + 
                `${item.active ? ' active' : ''}`}
                onClick={() => onClickBuyingItemHandler(item.ProductId, parentId)}
                key={itemIndex}
              >
                <span className='custom-radio-select warranty-buying-list__item__name'>{item.DisplayName}</span>
                <span className='addition-price warranty-buying-list__item__price'>
                  {item.Price.ListPrice === 0 
                    ? <Fragment><Message messageCode='LBL-WarrantyFee' /></Fragment> 
                    : <Fragment>{item.Price.SellingPriceWithCurrency}</Fragment>}
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

BundleBOVariantsList.propTypes = {
  contentData: Proptypes.any,
  parentId: Proptypes.any,
  onAction: Proptypes.any,
  onToogleFnc: Proptypes.any,
};

export default BundleBOVariantsList;
