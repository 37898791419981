import * as productWarrantyTypes from './productWarrantyTypes';

//GetMyProducts
export const getMyProduct = () => ({
  type: productWarrantyTypes.GET_MY_PRODUCT
});

export const getMyProductSuccess = (payload) => ({
  type: productWarrantyTypes.GET_MY_PRODUCT_SUCCESS,
  payload
});

export const getMyProductFailed = (error) => ({
  type: productWarrantyTypes.GET_MY_PRODUCT_FAILED,
  error
});

//GetUserProductDetails
export const getUserProductDetails = (payload) => ({
  type: productWarrantyTypes.GET_USER_PRODUCT_DETAILS,
  payload
});

export const getUserProductDetailsSuccess = (payload) => ({
  type: productWarrantyTypes.GET_USER_PRODUCT_DETAILS_SUCCESS,
  payload
});

export const getUserProductDetailsFailed = (error) => ({
  type: productWarrantyTypes.GET_USER_PRODUCT_DETAILS_FAILED,
  error
});

//GetLatestMyProduct
export const getLatestMyProduct = (payload) => ({
  type: productWarrantyTypes.GET_LATEST_MY_PRODUCT,
  payload
});

export const getLatestMyProductSuccess = (payload) => ({
  type: productWarrantyTypes.GET_LATEST_MY_PRODUCT_SUCCESS,
  payload
});

export const getLatestMyProductFailed = (error) => ({
  type: productWarrantyTypes.GET_LATEST_MY_PRODUCT_FAILED,
  error
});

//Remove my product
export const removeMyProduct = (payload) => ({
  type: productWarrantyTypes.REMOVE_MY_PRODUCT,
  payload
});

export const removeMyProductSuccess = (payload) => ({
  type: productWarrantyTypes.REMOVE_MY_PRODUCT_SUCCESS,
  payload
});

export const removeMyProductFailed = (error) => ({
  type: productWarrantyTypes.REMOVE_MY_PRODUCT_FAILED,
  error
});