import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/cPStartDemo';
import * as cPStartDemoTypes from '@redux/actions/cPStartDemo/cPStartDemoTypes';
import { post } from '@services/genericService';

import * as config from '../config';

const cpStartDemoEpic = (action$) =>
  action$.pipe(
    ofType(cPStartDemoTypes.POST_CP_START_DEMO),
    switchMap((action) => {
      // const params = {
      //   ContactId: action.payload.ContactId
      // };
      const { needRunSaveSOCartApi, ...payload } = action.payload;

      return from(post(config.ACTION_CP_START_DEMO, payload)).pipe(
        map((res) => {
          if (res.status == 200 && res.data.IsSuccess) {
            const responseData = {
              ...res.data,
              needRunSaveSOCartApi: needRunSaveSOCartApi ?? false
            };

            return actions.cPStartDemoSuccess({
              ...responseData
            });
          } else {
            return actions.cPStartDemoFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export default cpStartDemoEpic;
