import './MainBanner.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import ModalComponent from '@components/Share/ModalComponent';
import VideoPlayer from '@components/Share/VideoPlayer';
import CountDownBanner from '@components/SlicingPage/HomePage/Banner/CountDownBanner';
import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT, LAYOUT_FULL, LIST_CLASS_NAME__DESKTOP, LIST_CLASS_NAME__MOBILE, TEXT_COLOR_WHITE } from '@utils/constant';
import { useQuery } from '@utils/customsHook/useQuery';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { mainBannerOffset$ } from './handleBannerService';
import BannerFieldsModel from './Model/BannerFieldsModel';

const MainBanner = (props) => {
  const {
    fields,
    params,
    rendering: { uid }
  } = props;
  const [windowWidth, setWindowWidth] = useState(1920);
  const bannerTextPositionDesktop = params?.['Desktop Text Position'] || '';
  const bannerTextPositionMobi = params?.['Mobile Text Position'] || '';
  const bannerTextColor = params?.['Text Color'] || '';
  const bannerTextColorCode = params?.['Title Text Colors Code'] || '';
  const bannerTextAlign = params?.['Desktop Text Align'] || '';
  const bannerTextLineHeight = params?.['Line Height'] || '';
  const bannerTitleFontsize = params?.['Font Size'] || '';
  const bannerMobileTitleFontsize = params?.['Mobile Font Size'] || '';
  const bannerDescriptionFontsize = params?.['Description Font Size'] || '';
  const bannerMobileDescriptionFontsize = params?.['Mobile Description Font Size'] || '';
  const bannerLayout = params?.Layout || '';
  const isSpecial = params?.['Is Special'];
  const isNotFullWidth = params?.['Is Not Full Width'];
  const [storeId, setStoreId] = useState(null);

  const [bannerFields, setBannerFields] = useState(null);
  const mainBannerElement = useRef(null);

  const [titleFontSize, setTitleFontSize] = useState(null);
  const [descriptionFontSize, setDescriptionFontSize] = useState(null);

  const [bannerOffsets, setBannerOffsets] = useState({
    offsetTop: 0,
    offsetBottom: 0
  });

  const [isShow, setShow] = useState(false);
  const [isShowBanner, setIsShowBanner] = useState(false);
  const query = useQuery();

  const onShowModalHandler = () => {
    setShow(true);
  };

  const onCloseModalHandler = () => {
    setShow(false);
  };

  useEffect(() => {
    const urlSearchParam = new URLSearchParams(window.location.search);

    setStoreId(urlSearchParam.get('storeId'));
  }, []);

  useEffect(() => {
    const bannerFieldsModel = new BannerFieldsModel();

    setBannerFields(bannerFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  const classRenderMobile = (data) => {
    return LIST_CLASS_NAME__MOBILE[data] || '';
  };

  const classRenderDesktop = (data) => {
    return LIST_CLASS_NAME__DESKTOP[data] || '';
  };

  useEffect(() => {
    if (mainBannerElement.current) {
      setBannerOffsets({
        offsetTop: mainBannerElement.current.offsetTop,
        offsetBottom: mainBannerElement.current.offsetTop + mainBannerElement.current.offsetHeight
      });
    }
  }, [bannerFields]);

  useEffect(() => {
    if (isSpecial?.toString() === '1') {
      mainBannerOffset$.next(bannerOffsets);
    }
  }, [bannerOffsets]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth && windowWidth < IPAD_BREAKPOINT) {
      setTitleFontSize(bannerMobileTitleFontsize);

      setDescriptionFontSize(bannerMobileDescriptionFontsize);
    }
    if (windowWidth && windowWidth >= IPAD_BREAKPOINT) {
      setTitleFontSize(bannerTitleFontsize);

      setDescriptionFontSize(bannerDescriptionFontsize);
    }
  }, [windowWidth]);

  useEffect(() => {
    if (bannerFields) {
      const isVisible = global.checkElementVisible(bannerFields?.['Banner Launch Time'].value, bannerFields?.['Banner End Time'].value);

      setIsShowBanner(isVisible);
    }
  }, [bannerFields]);

  const renderVideo = (background) => {
    if (bannerFields['Enable Audio']) {
      return (
        <video className={`slider-video ${background.includes('Mobile') ? 'd-mobile' : 'd-desktop'}`} playsInline loop autoPlay>
          <source src={bannerFields[background].value.href} type='video/mp4' />
        </video>
      );
    } else {
      return (
        <video className={`slider-video ${background.includes('Mobile') ? 'd-mobile' : 'd-desktop'}`} playsInline loop muted='muted' autoPlay>
          <source src={bannerFields[background].value.href} type='video/mp4' />
        </video>
      );
    }
  };

  const bannerIdValue = useMemo(() => {
    return bannerFields?.['Banner Id']?.value || `banner-${uid ?? 'id'}`;
  }, [bannerFields?.['Banner Id']?.value]);

  const ctaButtonHref = useMemo(() => {
    const ctaButtonValue = bannerFields?.['CTA Button']?.value;
    const ctaButtonHref = ctaButtonValue?.href;
    const ctaButtonQueryString = ctaButtonValue?.querystring;
    if (storeId) query.set('storeId', storeId);
    if (ctaButtonQueryString) query.set('from', ctaButtonQueryString);

    return {
      value: {
        ...ctaButtonValue,
        href: `${ctaButtonHref}${query.toString() ? `?${query.toString()}` : ''}`
      }
    };
  }, [bannerFields, storeId, query]);

  const renderComponent = () => {
    return (
      <>
        {bannerFields['Banner Click Hyperlink'].value.href.length ? (
          <Link field={bannerFields['Banner Click Hyperlink']}>
            <div id={bannerIdValue}
              className={`main-banner animated-slow fadeIn delay-100${bannerTextColor === TEXT_COLOR_WHITE ? ' text-white' : ''}${
                bannerTextAlign === 'Center'
                  ? ' text-align-center'
                  : bannerTextAlign === 'Left'
                    ? ' text-align-left'
                    : bannerTextAlign === 'Right'
                      ? ' text-align-right'
                      : ''
              }${isNotFullWidth == 1 ? ' container not-full-width' : ''}`}
              ref={mainBannerElement}
            >
              <div className={bannerLayout == LAYOUT_FULL ? 'main-banner__background no-ratio' : 'main-banner__background'}>
                {windowWidth > 768 && bannerFields['Background video'].value.href.length > 0 && renderVideo('Background video')}
                {windowWidth < 768 && bannerFields['Mobile Background Video'].value.href.length > 0 && renderVideo('Mobile Background Video')}
                {!bannerFields['Background video'].value.href.length && <ResponsiveImage
                  imageMobile={bannerFields['Mobile Background Image']}
                  srcSet={[{ mw: 768, image: bannerFields['Background Image'] }]}
                />}
              </div>
              <div className='main-banner__content'>
                <div
                  className={
                    'container main-banner__content__container content-position ' +
                    classRenderMobile(bannerTextPositionMobi) +
                    ' ' +
                    classRenderDesktop(bannerTextPositionDesktop)
                  }
                >
                  <div
                    className='main-banner__content__wrap content-wrapper'
                    style={{ color: '#' + bannerTextColorCode, lineHeight: bannerTextLineHeight + 'px' }}
                  >
                    {bannerFields.Icon?.value?.src || bannerFields['Second Icon']?.value?.src ? (
                      <div className='main-banner__content__logo'>
                        {bannerFields.Icon.value.src ? (
                          <div className='main-banner__content__logo__image'>
                            <Image media={bannerFields.Icon} />
                          </div>
                        ) : (
                          ''
                        )}

                        {bannerFields['Second Icon'].value.src ? (
                          <div className='main-banner__content__logo__image'>
                            <Image media={bannerFields['Second Icon']} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {bannerFields['Sub Title'].value ? (
                      <div className='main-banner__content__sub-title'>
                        <Text field={bannerFields['Sub Title']} tag='span' />
                      </div>
                    ) : (
                      <></>
                    )}
                    <Text
                      field={bannerFields.Title}
                      tag='h2'
                      className='main-banner__content__title'
                      style={{ color: '#' + bannerTextColorCode, fontSize: titleFontSize + 'px' }}
                    />
                    <RichText
                      className='main-banner__content__desc'
                      field={bannerFields.Description}
                      style={{ fontSize: descriptionFontSize + 'px' }}
                    />
                    {bannerFields['CTA Hyperlink Button Text'].value ? (
                      <Link
                        className={bannerTextColor === TEXT_COLOR_WHITE ? 'btn-link btn-link-white' : 'btn-link'}
                        field={bannerFields['CTA Hyperlink Button']}
                      >
                        <Text field={bannerFields['CTA Hyperlink Button Text']} />
                      </Link>
                    ) : (
                      <></>
                    )}
                    {bannerFields['CTA Button Text'].value && bannerFields['CTA Button'].value ? (
                      <div className='main-banner__content__btn'>
                        <Link
                          className={
                            bannerFields['CTA Button Color'].value.toLowerCase() === 'primary' ?
                              'main-banner__content__btn__item btn btn-primary'
                              : (
                                bannerTextColor === TEXT_COLOR_WHITE
                                  ? 'main-banner__content__btn__item btn btn-outline-white'
                                  : 'main-banner__content__btn__item btn btn-outline-primary'
                              )
                          }
                          field={ctaButtonHref}
                        >
                          <Text field={bannerFields['CTA Button Text']} />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <CountDownBanner fields={fields} />
            </div>
          </Link>
        ) : (
          <>
            <div id={bannerIdValue}
              className={`main-banner animated-slow fadeIn delay-100${bannerTextColor === TEXT_COLOR_WHITE ? ' text-white' : ''}${
                bannerTextAlign === 'Center'
                  ? ' text-align-center'
                  : bannerTextAlign === 'Left'
                    ? ' text-align-left'
                    : bannerTextAlign === 'Right'
                      ? ' text-align-right'
                      : ''
              }${isNotFullWidth == 1 ? ' container not-full-width' : ''}`}
              ref={mainBannerElement}
            >
              <div className={bannerLayout == LAYOUT_FULL ? 'main-banner__background no-ratio' : 'main-banner__background'}>
                {windowWidth > 768 && bannerFields['Background video'].value.href.length > 0 && renderVideo('Background video')}
                {windowWidth < 768 && bannerFields['Mobile Background Video'].value.href.length > 0 && renderVideo('Mobile Background Video')}
                {!bannerFields['Background video'].value.href.length && <ResponsiveImage
                  imageMobile={bannerFields['Mobile Background Image']}
                  srcSet={[{ mw: 768, image: bannerFields['Background Image'] }]}
                />}
              </div>
              <div className='main-banner__content'>
                <div
                  className={
                    'container main-banner__content__container content-position ' +
                    classRenderMobile(bannerTextPositionMobi) +
                    ' ' +
                    classRenderDesktop(bannerTextPositionDesktop)
                  }
                >
                  <div
                    className='main-banner__content__wrap content-wrapper'
                    style={{ color: '#' + bannerTextColorCode, lineHeight: bannerTextLineHeight + 'px' }}
                  >
                    {bannerFields.Icon?.value?.src || bannerFields['Second Icon']?.value?.src ? (
                      <div className='main-banner__content__logo'>
                        {bannerFields.Icon.value.src ? (
                          <div className='main-banner__content__logo__image'>
                            <Image media={bannerFields.Icon} />
                          </div>
                        ) : (
                          ''
                        )}

                        {bannerFields['Second Icon'].value.src ? (
                          <div className='main-banner__content__logo__image'>
                            <Image media={bannerFields['Second Icon']} />
                          </div>
                        ) : (
                          ''
                        )}
                      </div>
                    ) : (
                      <></>
                    )}
                    {bannerFields['Sub Title'].value ? (
                      <div className='main-banner__content__sub-title'>
                        <Text field={bannerFields['Sub Title']} tag='span' />
                      </div>
                    ) : (
                      <></>
                    )}
                    <Text
                      field={bannerFields.Title}
                      tag='h2'
                      className='main-banner__content__title'
                      style={{ color: '#' + bannerTextColorCode, fontSize: titleFontSize + 'px' }}
                    />
                    <RichText
                      className='main-banner__content__desc'
                      field={bannerFields.Description}
                      style={{ fontSize: descriptionFontSize + 'px' }}
                    />
                    {bannerFields['CTA Hyperlink Button Text'].value ? (
                      <Link
                        className={bannerTextColor === TEXT_COLOR_WHITE ? 'btn-link btn-link-white' : 'btn-link'}
                        field={bannerFields['CTA Hyperlink Button']}
                      >
                        <Text field={bannerFields['CTA Hyperlink Button Text']} />
                      </Link>
                    ) : (
                      <></>
                    )}
                    {bannerFields['CTA Button Text'].value ? (
                      <div className='main-banner__content__btn'>
                        <Link
                          className={
                            bannerFields['CTA Button Color'].value.toLowerCase() === 'primary' ?
                              'main-banner__content__btn__item btn btn-primary'
                              : (
                                bannerTextColor === TEXT_COLOR_WHITE
                                  ? 'main-banner__content__btn__item btn btn-outline-white'
                                  : 'main-banner__content__btn__item btn btn-outline-primary'
                              )
                          }
                          field={ctaButtonHref}
                        >
                          <Text field={bannerFields['CTA Button Text']} />
                        </Link>
                      </div>
                    ) : (
                      <></>
                    )}
                    {bannerFields['Enable Video CTA Button'] ? (
                      <div className='main-banner__content__btn'>
                        <div className='btn-play-video'>
                          <div className='video__contents__btn-play btn-play-video__icon'>
                            <div className='btn-play-icon' onClick={onShowModalHandler}>
                              <span className='play-icon'></span>
                            </div>
                          </div>
                          {bannerFields['Video CTA Button Text'] ? (
                            <Text field={bannerFields['Video CTA Button Text']} tag='span' className='btn-play-video__text' />
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
              </div>
              <CountDownBanner fields={fields} />
            </div>
            <ModalComponent isShow={isShow} onCloseHandler={onCloseModalHandler} cssClass={'video-popup'}>
              <VideoPlayer dataFields={bannerFields} />
            </ModalComponent>
          </>
        )}
      </>
    );
  };

  return bannerFields && windowWidth ? (
    isShowBanner ? (
      renderComponent()
    ) : (
      <></>
    )
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='main-banner skeleton__item'>
        <div className='main-banner__background main-banner__background--white'>
          <Loading></Loading>
        </div>
      </div>
    </LoadingShimmer>
  );
};

MainBanner.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  rendering: PropTypes.object
};

export default MainBanner;
