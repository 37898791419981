import './CPProductInfo.scss';

import React from 'react';

const CPProductInfo = () => {
  return <div className='cp-product'>
    <div className='cp-product__left'>
      <div className='cp-product__image'>
        <img src={require('@assets/images/cart-item-image-bigger.png')} alt='' />
      </div>
    </div>
    <div className='cp-product__right'>
      <div className='cp-product__name'>
        <span>uDivine V Massage Sofa</span>
        <span>New Color Limited Edition</span>
      </div>
      <p className='cp-product__color'>Color: <span>Blue Ocean</span></p>
      <p className='cp-product__price'>
        <span>$8,4999.00</span>
        <span className='old-price'>S$7,499.00</span>
      </p>
      <p className='cp-product__warranty'>Warranty till 20 Feb 2021</p>
    </div>
  </div>;
};

export default CPProductInfo;
