import Proptypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import { cPStartDemo } from '@redux/actions/cPStartDemo';
import { hideCPWarningPopup, showCPWarningPopup } from '@redux/actions/cPStartDemo';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import DemoSessionModel from '../Models/DemoSessionModel';
import * as config from './config';

export  const WarningPopup = ({
  isShow,
  onCloseModalHandler,
  popupTitle,
  popupContents,
  buttonText
}) => {
  return (
    <ModalComponent
      cssClass='warning-popup'
      isShow={isShow}
      onCloseHandler={onCloseModalHandler}
    >
      <div className='warning-popup__content'>
        <h4 className='warning-popup__content__title'><Text field={popupTitle}/></h4>
        <p className='warning-popup__content__desc'><Text field={popupContents}/></p>
        {
          buttonText ? (
            <div className='warning-popup__content__btn'>
              <button
                type='button'
                onClick={() => onCloseModalHandler()}
                className='warning-popup__content__btn__item btn btn-primary'>
                <Text field={buttonText} />
              </button>
            </div>
          ): null
        }

      </div>
    </ModalComponent>
  );
};

const DemoSession = ({fields}) => {
  const dispatch = useDispatch();
  const cPStartDemoReducer = useSelector((state) => state.cPStartDemoReducer);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const cpPersonalInformation = clientTellingPortalCustomer.currentCustomerDetail?.PersonalInformation;
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore') || null;
  //INFO: get cpCustomerId to know is runing startDemo
  const cpCustomerId = SitecoreContextFactoryService.getValueContextItem('cpCustomerId') || '';
  const isWarningStartDemo = useSelector((state) => state.cPStartDemoReducer.isShowWarningPopup);
  const [demoSessionFields, setDemoSessionFields] = useState(null);
  const [isWarningStartDemoWithUser, setIsWarningStartDemoWithUser] = useState(false);

  useEffect(() => {
    const demoSessionModel = new DemoSessionModel();

    setDemoSessionFields(demoSessionModel.getData(deepCopy(fields || {})));
  }, []);

  useDidUpdateEffect(() => {
    if (cPStartDemoReducer.isStartDemoSuccess && !cPStartDemoReducer.needRunSaveSOCartApi) {
      reloadPage();
    }
  }, [cPStartDemoReducer]);

  const onCloseModalHandler = () => {
    dispatch(hideCPWarningPopup());
  };

  const handleStartDemo = () => {
    if (currentStore && currentStore.storeId !== config.ALL_STORES_ID) {
      if (new URL(window.location.href).searchParams.get(config.CONTACT_ID) || cpCustomerId) {
        setIsWarningStartDemoWithUser(true);
      } else {
        dispatch(
          cPStartDemo({
            ContactId: '',
            currentSessionContactId: '',
            sessionId: ''
          })
        );
      }
    } else {
      dispatch(showCPWarningPopup());
    }
  };

  const handleEndDemo = () => {
    if (currentStore && currentStore.storeId !== config.ALL_STORES_ID) {
      if (demoSessionFields?.['End Demo Link'].value.href) {
        window.location.href = demoSessionFields['End Demo Link'].value.href;
      }
    } else {
      dispatch(showCPWarningPopup());
    }
  };

  const reloadPage = ()  => {
    window.location.reload();
  };

  const customerName = useMemo(() => cpPersonalInformation?.FirstName || '', [cpPersonalInformation]);

  return (
    demoSessionFields && (
      <>
        <div className='cp-header__col button-session'>
          {!cpCustomerId ? (
            <button className='btn-demo' onClick={() => handleStartDemo()}>
              <Text field={demoSessionFields['Start Demo Label']} />
            </button>
          ) : (
            <button className='btn-demo --end' onClick={() => handleEndDemo()}>
              <Text field={demoSessionFields['End Demo Label']} />
            </button>
          )}
        </div>
        <div className='cp-header__col store-name'>
          <span title={currentStore?.storeId !== config.ALL_STORES_ID ? currentStore?.storeName : ''}>
            {currentStore?.storeId !== config.ALL_STORES_ID ? currentStore?.storeName : ''}
          </span>
        </div>
        <WarningPopup
          isShow={isWarningStartDemo}
          onCloseModalHandler={onCloseModalHandler}
          popupTitle={demoSessionFields.Title}
          popupContents={demoSessionFields.Description}
          buttonText={demoSessionFields['Button Text']}
        />
        <ModalComponent cssClass='warning-popup' isShow={isWarningStartDemoWithUser} onCloseHandler={() => setIsWarningStartDemoWithUser(false)}>
          <div className='warning-popup__content demo-session__custom__popup'>
            {cpPersonalInformation?.FirstName || cpPersonalInformation?.LastName ? (
              <h4 className='warning-popup__content__title'>
                <span
                  dangerouslySetInnerHTML={{
                    __html: demoSessionFields['Start Demo With Customer Profile Text'].value.replace(
                      '[Customer Name]',
                      `<span class='cp-customer__name'>${customerName}</span>`
                    )
                  }}
                />
                {/*<span className='cp-customer__name'>{cpPersonalInformation?.FirstName} {cpPersonalInformation?.LastName}?</span>*/}
              </h4>
            ) : (
              <Text tag='h4' className='warning-popup__content__title' field={demoSessionFields['Start Demo With Customer Profile Text']} />
            )}
            <div className='warning-popup__content__btn'>
              <button
                type='button'
                onClick={() => {
                  dispatch(
                    cPStartDemo({
                      ContactId: new URL(window.location.href).searchParams.get(config.CONTACT_ID) || cpCustomerId,
                      currentSessionContactId: clientTellingPortalCustomer?.contacts?.[0]?.contactId,
                      sessionId: SitecoreContextFactoryService.getValueContextItem('cpSessionId')
                    })
                  );
                }}
                className='warning-popup__content__btn__item btn btn-primary'
              >
                <Text field={demoSessionFields['Yes Button Label']} />
              </button>
              <span
                className='warning-popup__content__btn__item os-btn-link link-nomal'
                onClick={() => {
                  dispatch(
                    cPStartDemo({
                      ContactId: '',
                      currentSessionContactId: '',
                      sessionId: ''
                    })
                  );
                }}
              >
                <Text field={demoSessionFields['No Button Label']} />
              </span>
            </div>
          </div>
        </ModalComponent>
        {cPStartDemoReducer.isLoading && <LoadingMask />}
      </>
    )
  );
};

WarningPopup.propTypes = {
  isShow: Proptypes.bool,
  onCloseModalHandler: Proptypes.func,
  popupTitle: Proptypes.object,
  popupContents: Proptypes.object,
  buttonText: Proptypes.object,
};

DemoSession.propTypes = {
  storeSelectedData: Proptypes.any,
  fields: Proptypes.any,
};

export default DemoSession;
