import './HelpMeDecide.scss';

import { object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import HelpMeDecideModel from './model/HelpMeDecideModel';
import { QuestionStep } from './QuestionStep/QuestionStep';
import { SummaryPage } from './SummaryPage/SummaryPage';


const HelpMeDecide = ({ fields }) => {
  const { Description, Title, Questions } = fields;
  const [step, setStep] = useState(1);
  const [chooseQuestions, setChooseQuestions] = useState({});
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const model = new HelpMeDecideModel();

    setInputData(model.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

  }, [step]);

  const nextStep = () => {
    setStep(step + 1);

  };

  const preStep = () => {

    setStep(step - 1);
  };

  const gotoFirstStep = () => {

    setStep(1);

    setChooseQuestions({});
  };

  const addFullBodyOption = () => {
    const newData = renderItem(2)?.Answers?.map((item) => {
      return item.ID;
    });

    return newData || [];
  };

  const getUrl = (params) => {
    const allIds = [];

    if (params?.step2?.activeAnswers && params?.step1?.activeAnswers && params?.step3?.activeAnswers) {
      const dataStep2 = params?.step2?.activeAnswers?.filter((idFilter) => idFilter !== 'full-body');
      const dataStep1 = params?.step1?.activeAnswers || [];
      const dataStep3 = params?.step3?.activeAnswers || [];

      dataStep2.map((id) => allIds.push(id));

      dataStep1.map((id) => allIds.push(id));

      dataStep3.map((id) => allIds.push(id));
    }
    const queryParams = allIds.map(id => `ids={${id.toUpperCase()}}`).join('&');
    const url = queryParams;

    return url;
  };

  const redirectToResultPage = () => {
    window.location.href = `/help-me-decide/result?${getUrl(chooseQuestions)}`;
  };

  const remoteFullBodyOption = () => {
    return [];
  };

  const renderValueStep = (step) => {
    let newData = [];
    if (step === 1) {
      newData = renderItem(step).Answers.filter(({ ID }) => chooseQuestions?.step1?.activeAnswers.includes(ID))?.map((item) => item.Name);
    }
    else if (step === 2) {
      newData = renderItem(step).Answers.filter(({ ID }) => chooseQuestions?.step2?.activeAnswers.includes(ID) && ID !== 'full-body')?.map((item) => item.Name);
    }
    else if (step === 3) {
      newData = renderItem(step).Answers.filter(({ ID }) => chooseQuestions?.step3?.activeAnswers.includes(ID))?.map((item) => item.Name);
    }

    return step === 3 ? newData.join(' ') : newData.join(', ');
  };

  const getSummarysValue = () => {
    const resultData = [];

    const dataStep1 = {
      title: renderItem(1)?.Title,
      value: renderValueStep(1),
    };

    const dataStep2 = {
      title: renderItem(2)?.Title,
      value: renderValueStep(2),
    };

    const dataStep3 = {
      title: renderItem(3)?.Title,
      value: renderValueStep(3)
    };

    resultData.push(dataStep1, dataStep2, dataStep3);

    return resultData;
  };

  const addOrRemoteIdStep2 = (activeAnswers = [], id) => {
    const findId = activeAnswers.includes(id);
    const findIdFullBody = activeAnswers.includes(id);
    if (id === 'full-body') {
      return !findIdFullBody ? addFullBodyOption() : remoteFullBodyOption();
    }
    else {
      if (activeAnswers?.length === renderItem(2)?.Answers?.length) {
        return activeAnswers.filter(answer => answer !== id && answer !== 'full-body');
      }
      else if (!findId && !findIdFullBody && activeAnswers?.length + 2 === renderItem(2)?.Answers?.length) {
        const newData = activeAnswers;

        newData.push(id, 'full-body');

        return newData || [];
      }
      else {
        return findId
          ? activeAnswers.filter(answer => answer !== id)
          : [...activeAnswers, id];
      }
    }
  };

  const chooseAnswerByType = (id, type, activeAnswers = []) => {
    const findId = activeAnswers.includes(id);
    if (type === 'Multiple') {
      return findId
        ? activeAnswers.filter(answer => answer !== id)
        : [...activeAnswers, id];
    }
    else if (type === 'Single') {
      return [id];
    }

  };

  const mergeAvailableNextAnswers = (availableNextAnswers) => {
    if (chooseQuestions.step1?.availableNextAnswersStep2 && chooseQuestions?.step1.availableNextAnswersStep2.length > 0) {
      const newData = chooseQuestions.step1.availableNextAnswersStep2.concat(availableNextAnswers);

      return newData;
    }
    else {
      return availableNextAnswers;
    }
  };

  const chooseAnswer = (step, id, availableNextAnswers, type) => {
    if (step === 1) {
      setChooseQuestions({ ...chooseQuestions, step1: { activeAnswers: chooseAnswerByType(id, type, chooseQuestions?.step1?.activeAnswers), availableNextAnswersStep2: mergeAvailableNextAnswers(availableNextAnswers), }, step2: {} });
    }
    if (step === 2) {
      setChooseQuestions({
        ...chooseQuestions, step2: {
          availableNextAnswersStep3: availableNextAnswers, activeAnswers: addOrRemoteIdStep2(chooseQuestions?.step2?.activeAnswers, id)
        }, step3: {}
      });
    }
    if (step === 3) {
      setChooseQuestions({ ...chooseQuestions, step3: { activeAnswers: chooseAnswerByType(id, type, chooseQuestions?.step3?.activeAnswers) } });
    }
  };

  const renderItem = (stepNumber) => {
    const questions = Questions?.length !== 0 && Questions.filter((_, index) => index === stepNumber - 1)[0] || [];
    if (stepNumber === 2 && chooseQuestions?.step1?.availableNextAnswersStep2?.length > 0) {
      const { Answers } = questions;

      const fullFields = {
        ID: 'full-body',
        Name: inputData['Full Body Text'].value,
        MassageArea: inputData['Full Body Text'].value
      };
      const answersStep2 = Answers.filter(({ ID }) => chooseQuestions?.step1?.availableNextAnswersStep2.includes(ID));

      return { ...questions, Answers: [...answersStep2, fullFields] };
    }
    else {
      return questions;
    }
  };

  const getResultsValue = () => {
    redirectToResultPage();
  };

  const renderStep = useMemo(() => {
    switch (step) {
    case 1:
      return (<QuestionStep item={renderItem(step)} nextStep={nextStep} step={step} chooseQuestions={chooseQuestions} chooseAnswer={chooseAnswer} dataFromLayout={inputData}
      />);

    case 2:
      return (<QuestionStep item={renderItem(step)} nextStep={nextStep} gotoFirstStep={gotoFirstStep} preStep={preStep} step={step} chooseQuestions={chooseQuestions} chooseAnswer={chooseAnswer} dataFromLayout={inputData}
      />);

    case 3:
      return (<QuestionStep item={renderItem(step)} nextStep={nextStep} gotoFirstStep={gotoFirstStep} preStep={preStep} step={step} chooseQuestions={chooseQuestions} chooseAnswer={chooseAnswer} dataFromLayout={inputData}
      />);

    case 4:
      return (<SummaryPage results={getSummarysValue()} gotoFirstStep={gotoFirstStep} nextStep={getResultsValue} dataFromLayout={inputData}
      />);
    default:
      return null;
    }
  }, [step, chooseQuestions, inputData]);

  return fields && inputData && (
    <div className='container'>
      <div className='help-me-decide__heading'>
        <Text field={Title} tag='h2' className='help-me-decide__heading__title' />
        <Text field={Description} className='help-me-decide__heading__description' tag='p' />
      </div>
      {renderStep}
    </div>

  );
};

HelpMeDecide.propTypes = {
  fields: object
};

export default HelpMeDecide;
