
import React from 'react'
// import ModalComponent from '@components/Share/ModalComponent';
import FeaturesAndFunctionPopup from './FeaturesAndFunctionPopup/FeaturesAndFunctionPopup'
import Proptypes from 'prop-types';

const FeaturesAndFunctionNew = ({ features, isToogleClearFilter, setSelectFilterEvt, filterFeatureCurrent, dataSources }) => {
  return features.Details && features.Details?.length > 0 ? (
    <>
      <div className="filter-popup__section filter-popup__section--features">
        <h3 className='filter-popup__section__heading' >
          {dataSources.Name.value}
        </h3>
        <FeaturesAndFunctionPopup features={features} isToogleClearFilter={isToogleClearFilter} setSelectFilterEvt={setSelectFilterEvt} filterFeatureCurrent={filterFeatureCurrent} />
      </div>
      {/* <ModalComponent isShow={open} onCloseHandler={closeModal} titlePopup={<>Sort & Filter</>} isHigh >
            <FeaturesAndFunctionPopup closeModal={closeModal} />
        </ModalComponent> */}
    </>
  ) : <></>;
}
FeaturesAndFunctionNew.propTypes = {
  features: Proptypes.any,
  isToogleClearFilter: Proptypes.bool,
  setSelectFilterEvt: Proptypes.func,
  filterFeatureCurrent: Proptypes.any,
  dataSources: Proptypes.any

};

export default FeaturesAndFunctionNew