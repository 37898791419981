import { object } from 'prop-types';
import React from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';

import MonthDiscountedSwiperItem from './MonthDiscountedSwiperItem';

const MonthDiscountedSwiper = ({ layoutData }) => {
  
  const swipeParams = {
    lazy: true,
    slidesPerView: 2.5,
    spaceBetween: 18,
    navigation: {
      nextEl: '.swiper-button.swiper-button-next',
      prevEl: '.swiper-button.swiper-button-prev'
    },
    breakpoints: {
      1200: {
        slidesPerView: 4,
        spaceBetween: 80,
      },
      768: {
        slidesPerView: 3,
        spaceBetween: 80,
      }
    }
  };

  return (
    <div className='month-dicount-item__content__swiper trade-in-special__details-swiper'>
      <SwipeComponent param={swipeParams}>
        {layoutData?.['ListProducts']?.map((item, idx) => (
          <div key={idx} className='month-dicount-item__content__swiper__item trade-in-special__details-swiper__item'>
            <MonthDiscountedSwiperItem item={item} buyNowLink={layoutData?.['Buy Now Button Link']?.value?.href} buyNowText={layoutData?.['Buy Now Button Text']} />
          </div>
        ))}
      </SwipeComponent>
    </div>
  );
};

MonthDiscountedSwiper.propTypes = {
  layoutData: object
};

export default MonthDiscountedSwiper;
