import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'title',
  'Anchor ID',
  'Description',
  'Button Shop Now Text',
  'Notification Label'
];

export default class BirthdayRewardsModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Button Shop Now Link'] = new SitecoreLinkModel();

    this['birthdayRewardOptions'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Shop Now Link')) {
      this['Button Shop Now Link'] = deepCopy(dataSource['Button Shop Now Link']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'birthdayRewardOptions')) {
      this['birthdayRewardOptions'] = deepCopy(dataSource['birthdayRewardOptions']);
    }

    return this;
  }
}
