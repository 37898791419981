import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/productReview';
import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const getProductReviewByIdEpic = (action$) =>
  action$.pipe(
    ofType(productReviewTypes.GET_PRODUCT_REVIEW_BY_ID),
    switchMap((action) => {
      const params = {
        reviewId: action.payload.reviewId,
      };

      return getAsObservable(config.ACTION_GET_PRODUCT_REVIEW_BY_ID, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getProductReviewByIdSuccess(deepCopy(res.data));
          } else {
            return actions.getProductReviewByIdFailed('Failed');
          }
        })
      );
    })
  );
