import './SpinWinBanner.scss';

import PropTypes from 'prop-types';
import React, { createContext, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ResponsiveImage from '@components/ResponsiveImage';
import ViewMyPrizesButton from '@components/SpinAndWin/ViewMyPrizesButton';
import { applyReward } from '@redux/actions/spinAndWin';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import SpinWinHeading from '../Spin&WinHeading';
import SpinForm from '../SpinForm';
import SpinWheel from '../SpinWheel';
import prizeColorData from './PrizeColorData';

export const SpinWinBannerContext = createContext(null);

const SpinWinBanner = ({ layoutData, luckyDrawId, userSpin, isSpinAndWinPopUp }) => {
  const DEG_OF_CIRCLE = 360;
  const TIME_TO_SHOW_RESULT = 2500;
  const WIDTH_SHOW_HORIZONTAL_SPIN = 1200;
  const NUMBER_OF_REVOLUTIONS = 3;
  const spinContainerRef = useRef();
  const dispatch = useDispatch();
  const luckyDrawReducer = useSelector((state) => state.spinAndWinReducer.getAvailableLuckyDrawReducer);
  const numberSpinOfEmail = useSelector((state) => state.spinAndWinReducer.getNumberSpinEmailReducer) || {};
  const [numberSpinRound, setNumberSpinRound] = useState(DEG_OF_CIRCLE * NUMBER_OF_REVOLUTIONS);
  const [isShowModalAfterSpin, setIsShowModalAfterSpin] = useState(false);
  const [isDisabledSpinBtn, setIsDisabledSpinBtn] = useState(false);
  const [arrayLuckyDrawCustom, setArrayLuckyDrawCustom] = useState(null);
  const [prizeNumber, setPrizeNumber] = useState(0);
  const [spinSuccess, setSpinSuccess] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const [isShowHorizontalSpin, setIsShowHorizontalSpin] = useState(false);
  const arrayLuckyDraw = luckyDrawReducer?.luckyDrawData?.Slices;

  const DEG_PER_ITEM = useMemo(() => {
    return DEG_OF_CIRCLE / arrayLuckyDraw?.length;
  }, [DEG_OF_CIRCLE, arrayLuckyDraw]);

  const userIsSpinningInitialState = {
    SliceId: null,
    Email: ''
  };
  const [userIsSpinning, setUserIsSpinning] = useState(userIsSpinningInitialState);
  const [maxRangeRandom, setMaxRangeRandom] = useState(0);

  useDidUpdateEffect(() => {
    setIsDisabledSpinBtn(numberSpinOfEmail.isLoading);
  }, [numberSpinOfEmail.isLoading]);

  useEffect(() => {
    customPercentSpin(arrayLuckyDraw);
  }, [arrayLuckyDraw]);

  useEffect(() => {
    userSpin({ ...userIsSpinning });
  }, [userIsSpinning]);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth) {
      setIsShowHorizontalSpin(windowWidth >= WIDTH_SHOW_HORIZONTAL_SPIN);
    } else {
      setIsShowHorizontalSpin(false);
    }
  }, [windowWidth]);

  const customPercentSpin = (arr) => {
    const newArr = arr?.map((item) => ({
      id: item.Id,
      percent: Math.round(item.Percent)
    }));
    const filterArr = newArr?.filter((item) => item.percent > 0);
    let pre = 1;
    let current = 1;

    const tmpArr = filterArr?.map((item) => {
      let tmpObj = {};

      current = pre + item.percent;

      tmpObj = {
        id: item.id,
        hitRate: [pre, current]
      };

      pre = current + 1;

      return tmpObj;
    });

    setMaxRangeRandom(current);

    const resultArr = arr?.map((item) => {
      const tmpObj = tmpArr?.find((tmpItem) => item.Id === tmpItem.id);

      return tmpObj ? { ...item, hitRate: tmpObj.hitRate } : { ...item, hitRate: [0, 0] };
    });

    setArrayLuckyDrawCustom(resultArr);
  };

  const getIndexByRatio = (ratioData) => {
    const randomNumber = Math.floor(Math.random() * maxRangeRandom) + 1; //Range random [1, maxRangeRandom]
    for (let item of ratioData) {
      if (randomNumber >= item.hitRate[0] && randomNumber <= item.hitRate[1]) {
        return ratioData.indexOf(item);
      }
    }
  };

  const onSpinBtnClick = () => {
    const newPrizeNumber = getIndexByRatio(arrayLuckyDrawCustom);

    if (newPrizeNumber >= 0) {
      const positionOfNewPrizeNumber = DEG_PER_ITEM * newPrizeNumber;
      const degNeedToSpin = numberSpinRound + positionOfNewPrizeNumber;
      const ItemByRatio = arrayLuckyDrawCustom[newPrizeNumber];

      setSpinSuccess(false);

      dispatch(
        applyReward({
          SliceId: ItemByRatio.Id,
          Email: userIsSpinning.Email,
          luckyDrawId: luckyDrawId
        })
      );

      setUserIsSpinning({ ...userIsSpinning });

      return new Promise((resolve) => {
        setIsDisabledSpinBtn(true);

        spinContainerRef.current.style.transform = `rotate(${degNeedToSpin}deg)`;

        if (newPrizeNumber === 0) {
          setPrizeNumber(0);
        } else {
          setPrizeNumber(newPrizeNumber);
        }

        return setInterval(() => {
          resolve({ degNeedToSpin, positionOfNewPrizeNumber });
        }, TIME_TO_SHOW_RESULT);
      }).then((response) => {
        setIsShowModalAfterSpin(true);

        setSpinSuccess(true);

        setIsDisabledSpinBtn(false);

        setNumberSpinRound(response.degNeedToSpin + (DEG_OF_CIRCLE - response.positionOfNewPrizeNumber) + DEG_OF_CIRCLE * NUMBER_OF_REVOLUTIONS);
      });
    }
  };

  return (
    <div className='spin-win-banner'>
      <div className='spin-win-banner__background'>
        <ResponsiveImage
          imageMobile={layoutData['Background Image']}
        />
      </div>

      <div className='spin-win-banner__content'>
        <div className='container'>
          <div className='spin-win-banner__content__wrap'>
            <SpinWinBannerContext.Provider
              value={{
                layoutData,
                luckyDrawId,
                arrayLuckyDrawCustom,
                prizeNumber,
                spinContainerRef,
                prizeColorData,
                DEG_PER_ITEM,
                isDisabledSpinBtn,
                isShowModalAfterSpin,
                setIsShowModalAfterSpin,
                userIsSpinningInitialState,
                userIsSpinning,
                setUserIsSpinning,
                onSpinBtnClick
              }}
            >
              {isSpinAndWinPopUp && isShowHorizontalSpin ? (
                <>
                  <SpinWheel />
                  <div className='spin-win-popup-form'>
                    <SpinWinHeading />
                    <SpinForm spinSuccess={spinSuccess} />
                    <ViewMyPrizesButton layoutData={layoutData} />
                  </div>
                </>
              ) : (
                <>
                  <SpinWinHeading />
                  <SpinWheel />
                  <SpinForm spinSuccess={spinSuccess} />
                  {isSpinAndWinPopUp && <ViewMyPrizesButton layoutData={layoutData} />}
                </>
              )}
            </SpinWinBannerContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

SpinWinBanner.propTypes = {
  layoutData: PropTypes.any,
  userSpin: PropTypes.any,
  isSpinAndWinPopUp: PropTypes.any,
  luckyDrawId: PropTypes.string
};

export default SpinWinBanner;
