import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CPLoginModel {
  constructor() {
    this['Background Image'] = new SitecoreImageModel();

    this['Logo'] = new SitecoreImageModel();

    this['Button Text'] = new SitecoreTextModel();

    this['Password Text Placeholder'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['User Text Placeholder'] = new SitecoreTextModel();

    this['Login Success Link'] = new SitecoreLinkModel();

    this['Verify Login Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Image')) {
      this['Background Image'].getData(dataSource['Background Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Logo')) {
      this['Logo'].getData(dataSource['Logo']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Text')) {
      this['Button Text'].getData(dataSource['Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Password Text Placeholder')) {
      this['Password Text Placeholder'].getData(dataSource['Password Text Placeholder']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'User Text Placeholder')) {
      this['User Text Placeholder'].getData(dataSource['User Text Placeholder']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Login Success Link')) {
      this['Login Success Link'].getData(dataSource['Login Success Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Verify Login Link')) {
      this['Verify Login Link'].getData(dataSource['Verify Login Link']);
    }

    return this;
  }
}
