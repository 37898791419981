export default class LinkModel {
  constructor() {
    this.id = '';

    this.href = '';

    this.text = '';

    this.src = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'href')) {
      this.href = dataSource.href.length ? dataSource.href : this.href;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'src')) {
      this.src = dataSource.src.length ? dataSource.src : this.src;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'text')) {
      this.text = dataSource.text;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this.id = dataSource.id;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'linktype')) {
      this.linktype = dataSource.linktype;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'class')) {
      this.class = dataSource.class;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'target')) {
      this.target = dataSource.target;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'querystring')) {
      this.querystring = dataSource.querystring;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'anchor')) {
      this.anchor = dataSource.anchor;
    }

    return this;
  }
}
