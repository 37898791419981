import Proptypes from 'prop-types';
import React from 'react';

const ProductDetailHeaderMenu = ({ isMenuOpen }) => {
  return (
    <div className={`product-detail-header__drop-down__menu ${isMenuOpen == true ? 'show' : ''}`}>
      <ul className='drop-down__menu__list'>
        <li>Item 1</li>
        <li>Item 2</li>
        <li>Item 3</li>
      </ul>
    </div>
  );
};

ProductDetailHeaderMenu.propTypes = {
  isMenuOpen: Proptypes.any
};

export default ProductDetailHeaderMenu;
