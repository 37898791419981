import PriceModel from './PriceModel';

export default class BundleItemModel {
  constructor() {
    this.Currency = '';

    this.Description = '';

    this.Image = '';

    this.ItemId = '';

    this.Id = '';

    this.ProductName = '';

    this.Name = '';

    this.ProductId = '';

    this.Url = '';

    this.Price = new PriceModel();

    this.Labels = [];

    this.Stock = {};

  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Currency') && dataSource['Currency']) {
      this.Currency = dataSource.Currency;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description') && dataSource['Description']) {
      this.Description = dataSource.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image') && dataSource['Image']) {
      this.Image = dataSource.Image;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId') && dataSource['ItemId']) {
      this.ItemId = dataSource.ItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id') && dataSource['Id']) {
      this.Id = dataSource.Id;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name') && dataSource['Name']) {
      this.Name = dataSource.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName') && dataSource['ProductName']) {
      this.ProductName = dataSource.ProductName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId') && dataSource['ProductId']) {
      this.ProductId = dataSource.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Url') && dataSource['Url']) {
      this.Url = dataSource.Url;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price') && dataSource['Price']) {
      this.Price.getData(dataSource.Price);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels') && dataSource['Labels']) {
      this.Labels = dataSource.Labels;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock') && dataSource['Stock']) {
      this.Stock = dataSource.Stock;
    }

    return this;
  }
}
