import PropTypes from 'prop-types';
import React, {useMemo} from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const BoxData = ({
  children,
  component,
  layoutData,
  data,
  isShowNotice=true,
  onClickView,
  linkPage,
  totalRecord
}) => {
  const dataLength = useMemo(() => {
    return totalRecord ?? (data?.length || 0);
  }, [data, totalRecord]);

  return (
    <div className={`${component || ''} box-data`}>
      <div className='box-data__header'>
        <div className='box-data__row'>
          <div className='box-data__col-1'>
            <Text field={layoutData?.['Section Title']} tag='h3' className='box-data__title' />
            {
              (isShowNotice && dataLength && layoutData?.['Description']?.value) ? (
                <p className='box-data__notice'>{`${layoutData['Description'].value.replace('{products-total}', dataLength || 0) || ''}`}</p>
              ) : ''
            }
          </div>
          {
            dataLength ? (
            linkPage?.value.href || layoutData?.['Link']?.value.href
              ? (
                layoutData?.['View All Button Text']?.value
                  ? (
                    <div className='box-data__col-2 text-right'>
                      <Link className='box-data__link' field={linkPage || layoutData['Link']}>
                        <Text field={layoutData['View All Button Text']} />
                      </Link>
                    </div>
                  )
                  : ''
              )
              : (
                layoutData?.['View All Button Text']?.value
                  ? (
                    <div className='box-data__col-2 text-right'>
                      <span onClick={() => onClickView()} className='box-data__link'>
                        <Text field={layoutData['View All Button Text']} />
                      </span>
                    </div>
                  )
                  : ''
              )
            ) : ''
          }
        </div>
      </div>
      <div className='box-data__body'>{
        dataLength
          ? children
          : (
            layoutData?.['No Data Text']?.value ? (
              <div className='box-data__no-data'>
                <Text tag='p'className='box-data__notice' field={layoutData?.['No Data Text']} />
              </div>
            ) : ''
          )
      }
      </div>
    </div>
  );
};

BoxData.propTypes = {
  children: PropTypes.any,
  component: PropTypes.string,
  layoutData: PropTypes.any,
  data: PropTypes.any,
  isShowNotice: PropTypes.bool,
  onClickView: PropTypes.any,
  linkPage: PropTypes.any,
  totalRecord: PropTypes.number
};

export default BoxData;
