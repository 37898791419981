import '../SaveSaleOrderSuccess/SaveSaleOrderSuccess.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE } from '@utils/constant';

import ProductListingShimmer from '../../CategoryLanding/ProductListing/ProductListingShimmer';
import CPPageHeader from '../CPPageHeader';
import SaveSOSuccessBody from '../SaveSaleOrderSuccess/SaveSOSuccessBody';
import SaveSOSuccessFooter from '../SaveSaleOrderSuccess/SaveSOSuccessFooter';
import SaveSOSuccessHeader from '../SaveSaleOrderSuccess/SaveSOSuccessHeader';
import SendSaleOrderSuccessModel from './models/SendSaleOrderSuccessModel';

const SendSaleOrderSuccess = ({ fields }) => {
  const contactData = useSelector((state) => state?.clientTellingPortalCustomer?.contacts[0]?.json_facet);
  const [layoutData, setLayoutData] = useState(null);
  const isOrderSent = true;

  useEffect(() => {
    const model = new SendSaleOrderSuccessModel();

    setLayoutData(model.getData(fields || {}));
  }, []);

  const handleTitleCp = () => {
    if (layoutData && contactData) {
      const firstName = contactData?.Personal?.FirstName || '';
      // const lastName = contactData?.Personal?.LastName || '';
      const cartTitle = layoutData?.['Header']?.value || '';
      const customerNameTitle = layoutData?.['Customer name text']?.value || '';

      // if (firstName || lastName) {
      // return `${cartTitle} ${firstName} ${lastName}`.trim();
      if (firstName) {
        return `${cartTitle} ${firstName.replace(DEFAULT_LAST_NAME_API_PAYLOAD_VALUE, '')}`.trim();
      } else {
        return `${cartTitle} ${customerNameTitle}`.trim();
      }
    }
  };

  return layoutData ? (
    <>
      <CPPageHeader title={handleTitleCp()} />
      <div className='save-so-success'>
        <div className='container tablet-container save-so-success-container'>
          <div className='save-so-success-wrapper'>
            <SaveSOSuccessHeader layoutData={layoutData} />
            <SaveSOSuccessBody layoutData={layoutData} isOrderSent={isOrderSent} />
            <SaveSOSuccessFooter layoutData={layoutData} isOrderSent={isOrderSent} />
          </div>
        </div>
      </div>
    </>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <ProductListingShimmer></ProductListingShimmer>
    </LoadingShimmer>
  );
};

SendSaleOrderSuccess.propTypes = {
  fields: object
};

export default SendSaleOrderSuccess;
