import './TwoColumnImage.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import TwoColumnImageModel from './model/TwoColumnImageModel';

const TwoColumnImage = (props) => {
  const titleFontsize = props.params?.['Font Size'] || '';
  const mobileTitleFontsize = props.params?.['Mobile Font Size'] || '';
  const subTitleFontsize = props.params?.['SubTitle Font Size'] || '';
  const mobileSubTitleFontsize = props.params?.['Mobile SubTitle Font Size'] || '';
  const descriptionFontsize = props.params?.['Description Font Size'] || '';
  const mobileDescriptionFontsize = props.params?.['Mobile Description Font Size'] || '';

  const [data, setData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(768);
  const [titleFontSize, setTitleFontSize] = useState(null);
  const [subTitleFontSize, setSubTitleFontSize] = useState(null);
  const [descriptionFontSize, setDescriptionFontSize] = useState(null);

  useState(() => {
    const model = new TwoColumnImageModel();

    setData(model.getData(deepCopy(props.fields) || {}));
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth && windowWidth < IPAD_BREAKPOINT) {
      setTitleFontSize(mobileTitleFontsize);

      setSubTitleFontSize(mobileSubTitleFontsize);

      setDescriptionFontSize(mobileDescriptionFontsize);
    }
    if (windowWidth && windowWidth >= IPAD_BREAKPOINT) {
      setTitleFontSize(titleFontsize);

      setSubTitleFontSize(subTitleFontsize);

      setDescriptionFontSize(descriptionFontsize);
    }
  }, [windowWidth]);

  return (
    data && (
      <div 
        className={`two-col-image ${
          props.params['Text Align'] == 'Center' ? 'text-align-center' : props.params['Text Align'] == 'Left' ? 'text-align-left' : props.params['Text Align'] == 'Right' ? 'text-align-right' : '' 
        } ${props.params['Is Not Full Width'] == 1 ? 'container not-full-width' : ''
        } ${props.params['Is Top Padding'] == 1 ? 'has-top-padding' : ''
        } ${props.params['Is Bottom Padding'] == 1 ? 'has-bottom-padding' : ''
        } ${props.params['Subtitle Bold'] == 1 ? 'subtitle-bold' : ''
        } ${data['Left Image']?.value?.src?.length || data['Right Image']?.value?.src?.length ? '' : 'no-img-bg'}`}
        style={{
          lineHeight: props.params['Line Height']+'px' || ''
        }}
      >
        <div className='two-col-image__item' style={{
          backgroundColor: '#'+props.params['1st Background Color Code'] || 'transparent'
        }}>
          <div className='two-col-image__item__background'>
            <Image field={data['Left Image']} />
          </div>
          <div className='two-col-image__item__wrap'>
            <Text className='two-col-image__item__title' tag='h2' field={data['Left Title']} style={{ fontSize: titleFontSize+'px' || ''}}/>
            {/* <Text className='two-col-image__item__title' tag='h2' field={data['Left Title']} style={{ fontSize: fontSize+'px' || ''}}/> */}
            {data['Left Icon']?.value?.src ? 
              <div className={`two-col-image__item__img ${
                props.params['Image Align'] == 'Center' ? 'two-col-image__item__img--center' : props.params['Image Align'] == 'Left' ? 'two-col-image__item__img--left' : props.params['Image Align'] == 'Right' ? 'two-col-image__item__img--right' : '' 
              }`}>
                <Image field={data['Left Icon']} />
              </div>
              : <></>
            }
            <div className='two-col-image__item__content'>
              <Text className='two-col-image__item__content__sub-title' tag='h5' field={data['Left Sub Title']} style={{ fontSize: subTitleFontSize+'px' || ''}}/>
              <RichText className='two-col-image__item__content__rich-text' tag='div' field={data['Left Text Content']} style={{ fontSize: descriptionFontSize+'px' || ''}} />
            </div>
          </div>
        </div>
        <div className='two-col-image__item' style={{
          backgroundColor: '#'+props.params['2nd Background Color Code'] || 'transparent'
        }}>
          <div className='two-col-image__item__background'>
            <Image field={data['Right Image']} />
          </div>
          <div className='two-col-image__item__wrap'>
            <Text className='two-col-image__item__title' tag='h2' field={data['Right Title']} style={{ fontSize: titleFontSize+'px' || ''}}/>
            {data['Right Icon']?.value?.src ?
              <div className={`two-col-image__item__img ${
                props.params['Image Align'] == 'Center' ? 'two-col-image__item__img--center' : props.params['Image Align'] == 'Left' ? 'two-col-image__item__img--left' : props.params['Image Align'] == 'Right' ? 'two-col-image__item__img--right' : '' 
              }`}>
                <Image field={data['Right Icon']} />
              </div>
              : <></>
            }
            <div className='two-col-image__item__content'>
              <Text className='two-col-image__item__content__sub-title' tag='h5' field={data['Right Sub Title']} style={{ fontSize: subTitleFontSize+'px' || ''}}/>
              <RichText className='two-col-image__item__content__rich-text' tag='div' field={data['Right Text Content']} style={{ fontSize: descriptionFontSize+'px' || ''}} />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

TwoColumnImage.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.any
};

export default TwoColumnImage;
