import PropTypes from 'prop-types';
import React from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

export const SummarytItem = ({ key, summarytItemData }) => {
  const { value, title } = summarytItemData;
 
  return (
    <div className='help-me-decide__summary__item' key={key} >
      <h3 className='help-me-decide__summary__item__question'>{title}</h3>
      <RichText className='help-me-decide__summary__item__anser' field={{ value: value }}></RichText>
    </div>
  );
};

SummarytItem.propTypes = {
  key: PropTypes.any,
  summarytItemData: PropTypes.any
};

