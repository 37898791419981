import './FeaturedProducts.scss';

import { object } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import LoginRegisterModal from '@components/CategoryLanding/LoginRegisterModal';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import FeaturedProductItem from './FeaturedProductItem';
import FeaturedProductsShimmer from './FeaturedProductsShimmer';
import FeaturedProductsFieldsModel from './Model/FeaturedProductsFieldsModel';

const FeaturedProducts = (props) => {
  const { fields, params } = props;
  const [featuredProducts, setFeaturedProducts] = useState(null);
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false);
  const [urlLoginPage, setUrlLoginPage] = useState({});
  const isGA4 = Global.getIsGA4();
  const elementRef = useRef();

  useEffect(() => {
    const featuredProductsFieldsModel = new FeaturedProductsFieldsModel();

    setFeaturedProducts(featuredProductsFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  // New Logic ON/OFF GA, GA4
  useEffect(() => {
    if (featuredProducts?.['Featured Products'] && isGA4) {
      const paramsGA4 = {
        ecommerce: {
          items: Global.handleGA4ViewItems(featuredProducts?.['Featured Products'], '', '', '', '', featuredProducts?.['Title']?.value)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'view_item_list', paramsGA4);
    }
  }, [featuredProducts?.['Featured Products']]);

  const handleClosePopupLogin = () => setIsShowLoginPopup(false);
  
  const handleOpenPopupLogin = (buyNowLink) => {
    setIsShowLoginPopup(true);
    let tempLink = deepCopy(buyNowLink);
    
    tempLink.value.href = `${featuredProducts?.['Deposit Login Button Link']?.value?.href}?from=${buyNowLink.value.href}`;

    setUrlLoginPage(tempLink);
  };

  return featuredProducts ? (
    <div ref={elementRef} className='featured-products animated-slow fadeIn delay-100'>
      <div className='container'>
        <div className='featured-products__heading'>
          <Text field={featuredProducts.Title} tag='h2' className='experience-title featured-products__heading__title' />
          {featuredProducts['CTA Button Text'].value ? (
            <Link field={featuredProducts['CTA Button']} className='featured-products__heading__link btn btn-link btn-link--primary'>
              <Text field={featuredProducts['CTA Button Text']} tag='span' />
            </Link>
          ) : (
            <></>
          )}
        </div>
        <div className='featured-products__list'>
          <div className='row'>
            {' '}
            {featuredProducts['Featured Products'].length ? (
              featuredProducts['Featured Products'].map((featureProduct, index) => {
                return (
                  <div className='col-md-6 col-xl-4' key={index}>
                    <FeaturedProductItem featureProducts={featureProduct} fieldsData={featuredProducts} params={params} indexGA4={index} handleOpenPopupLogin={handleOpenPopupLogin} />
                  </div>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <LoginRegisterModal 
        isShow={isShowLoginPopup} 
        onCloseHandler={handleClosePopupLogin} 
        dataLayout={featuredProducts} 
        urlLoginPage={urlLoginPage} 
      />
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <FeaturedProductsShimmer></FeaturedProductsShimmer>
    </LoadingShimmer>
  );
};

FeaturedProducts.propTypes = {
  fields: object,
  params: object
};

export default FeaturedProducts;
