import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const STR_ARR = [
  'Button Book Now Text',
  'Select Product Experience Text',
  'Title',
  'You Are Selecting Text',
  'Button Experience Now Text',
  'Description'
];

const LINK_ARR = ['Button Book Now Link', 'Button Experience Now Link'];

export default class BookExperienceComponentModel {
  constructor() {
    STR_ARR.forEach((str) => {
      this[str] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((link) => {
      this[link] = new SitecoreLinkModel();
    });

    this['Mobile Background'] = new SitecoreImageModel();

    this['Desktop Background'] = new SitecoreImageModel();

    this['Products'] = [];

    this['appointment'] = null;
  }

  getData(dataSource) {
    STR_ARR.forEach((str) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, str)) {
        this[str] = dataSource[str];
      }
    });

    LINK_ARR.forEach((link) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link] = dataSource[link];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Background')) {
      this['Mobile Background'] = dataSource['Mobile Background'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Desktop Background')) {
      this['Desktop Background'] = dataSource['Desktop Background'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products')) {
      this['Products'] = dataSource['Products'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'appointment')) {
      this['appointment'] = dataSource['appointment'];
    }

    return this;
  }
}
