import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getOtpCodeStart } from '@redux/actions/campaignRegister/getOtpCode';
import { MY_SITE_NAME } from '@utils/constant';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';

export const useGetOtp = () => {
  const dispatch = useDispatch();
  const isAtMYSite = useIsAtSiteName(MY_SITE_NAME);

  return useCallback(({ campaignId, phoneNumber }) => {
    const params = {
      campaignId: campaignId,
      phoneNumber: isAtMYSite ? `+${phoneNumber}` : phoneNumber
    };

    dispatch(getOtpCodeStart(params));
  }, []);
};
