import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class LeadManagementModel {
  constructor() {
    this['Search Icon'] = new SitecoreImageModel();

    this['Filter Icon'] = new SitecoreImageModel();

    this['Date Label'] = new SitecoreTextModel();

    this['Datetime Format'] = new SitecoreTextModel();

    this['Product Interest Label'] = new SitecoreTextModel();

    this['Purchased Label'] = new SitecoreTextModel();

    this['Search Placeholder Text'] = new SitecoreTextModel();

    this['Store Label'] = new SitecoreTextModel();

    this['Title Text'] = new SitecoreTextModel();

    this['Total Spent Label'] = new SitecoreTextModel();

    this['View Detail Text'] = new SitecoreTextModel();

    this['Apply Button Text'] = new SitecoreTextModel();

    this['Clear Button text'] = new SitecoreTextModel();

    this['Date Filter Label'] = new SitecoreTextModel();

    this['Not Spent Option Label'] = new SitecoreTextModel();

    this['Sale Staff Filter Label'] = new SitecoreTextModel();

    this['Spent Option Label'] = new SitecoreTextModel();

    this['Staff Name Label'] = new SitecoreTextModel();

    this['Status Filter Label'] = new SitecoreTextModel();

    this['Total Spent Filter Label'] = new SitecoreTextModel();

    this['Max Total Spent'] = new SitecoreTextModel();

    this['Min Total Spent'] = new SitecoreTextModel();

    this['All Purchase Text'] = new SitecoreTextModel();

    this['By Appointment Label'] = new SitecoreTextModel();

    this['Filter Default Date Rage'] = new SitecoreTextModel();

    this['Number Records Label'] = new SitecoreTextModel();

    this['No Result Description Label'] = new SitecoreTextModel();

    this['No Result Label'] = new SitecoreTextModel();

    this['All Filter Label'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Search Icon')) {
      this['Search Icon'].getData(dataSource['Search Icon']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter Icon')) {
      this['Filter Icon'].getData(dataSource['Filter Icon']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Date Label')) {
      this['Date Label'].getData(dataSource['Date Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Datetime Format')) {
      this['Datetime Format'].getData(dataSource['Datetime Format']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Interest Label')) {
      this['Product Interest Label'].getData(dataSource['Product Interest Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Purchased Label')) {
      this['Purchased Label'].getData(dataSource['Purchased Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Search Placeholder Text')) {
      this['Search Placeholder Text'].getData(dataSource['Search Placeholder Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Store Label')) {
      this['Store Label'].getData(dataSource['Store Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title Text')) {
      this['Title Text'].getData(dataSource['Title Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total Spent Label')) {
      this['Total Spent Label'].getData(dataSource['Total Spent Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View Detail Text')) {
      this['View Detail Text'].getData(dataSource['View Detail Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Apply Button Text')) {
      this['Apply Button Text'].getData(dataSource['Apply Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Clear Button text')) {
      this['Clear Button text'].getData(dataSource['Clear Button text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Date Filter Label')) {
      this['Date Filter Label'].getData(dataSource['Date Filter Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Not Spent Option Label')) {
      this['Not Spent Option Label'].getData(dataSource['Not Spent Option Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sale Staff Filter Label')) {
      this['Sale Staff Filter Label'].getData(dataSource['Sale Staff Filter Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Spent Option Label')) {
      this['Spent Option Label'].getData(dataSource['Spent Option Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Staff Name Label')) {
      this['Staff Name Label'].getData(dataSource['Staff Name Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Status Filter Label')) {
      this['Status Filter Label'].getData(dataSource['Status Filter Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total Spent Filter Label')) {
      this['Total Spent Filter Label'].getData(dataSource['Total Spent Filter Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Max Total Spent')) {
      this['Max Total Spent'].getData(dataSource['Max Total Spent']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Min Total Spent')) {
      this['Min Total Spent'].getData(dataSource['Min Total Spent']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'All Purchase Text')) {
      this['All Purchase Text'].getData(dataSource['All Purchase Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'By Appointment Label')) {
      this['By Appointment Label'].getData(dataSource['By Appointment Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter Default Date Rage')) {
      this['Filter Default Date Rage'].getData(dataSource['Filter Default Date Rage']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Number Records Label')) {
      this['Number Records Label'].getData(dataSource['Number Records Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'No Result Description Label')) {
      this['No Result Description Label'].getData(dataSource['No Result Description Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'No Result Label')) {
      this['No Result Label'].getData(dataSource['No Result Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'All Filter Label')) {
      this['All Filter Label'].getData(dataSource['All Filter Label']);
    }

    return this;
  }
}
