import produce from 'immer';

import { GET_DATA, GET_DATA_FAILED,GET_DATA_SUCCESS } from '@redux/actions/test';

const initState = {
  name: 'Test',
  data: '',
  isLoading: false
};

const testReducer = (state = initState, action) =>
  produce(state, (draf) => {
    switch (action.type) {
    case GET_DATA:
      draf.isLoading = true;
      break;

    case GET_DATA_SUCCESS:
      draf.isLoading = false;

      draf.data = action.payload;
      break;

    case GET_DATA_FAILED:
      draf.isLoading = true;

      draf.data = action.error;
    }

    return draf;
  });

export default testReducer;
