import './AppointmentListing.scss';

import { object } from 'prop-types';
import React, { useEffect,useState } from 'react';
// import { Pagination } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import * as config from '@components/ClientPortal/GenericComponents/CPHeader/DemoSession/config';
import { getAppointmentList } from '@redux/actions/cpAppointmentListing';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import CPPageHeader from '../../CPPageHeader';
import AppointmentsListingTable from './AppointmentsListingTable/index';
import AppointmentListingModel from './model/AppointmentListingModel';

const AppointmentsListing = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [statusTab, setStatusTab] = useState(null);
  const resultAppoint = useSelector((state) => state.cpAppointmentListingReducer.result);
  const currentStore = SitecoreContextFactoryService.getValueContextItem('currentStore') || null;
  const pageName = SitecoreContextFactoryService.getValueContextRouteItem('displayName');
  const appointmentListPageName = 'appointment listing';
  const [dateFilter, setDateFilter] = useState(null);

  const checkNumber =(number) => number < 10 ? '0'+number : number;

  const customeDate = (date) => {
    if (date) {
      const datePicker = new Date(date);
      const year = datePicker.getFullYear();
      const month = checkNumber(datePicker.getMonth() + 1);
      const day = checkNumber(datePicker.getDate());

      return year + '-' + month + '-' + day;
    }

    return;
  };

  const dispatch = useDispatch();

  useEffect(() => {
    const model = new AppointmentListingModel();

    setLayoutData(model.getData(fields));
    const url = new URL(window.location.href);
    const urlSearchParams = url.searchParams;
    const dateFilterParams = urlSearchParams.get('date-filter');

    if (dateFilterParams) {
      setDateFilter(dateFilterParams);
    } else {
      setDateFilter(customeDate(new Date()));
    }
  }, []);

  useEffect(() => {
    if (dateFilter) {
      dispatch(getAppointmentList({
        'Date': dateFilter,
        'Status': statusTab,
        'WithCurrentStore': currentStore?.storeId === config.ALL_STORES_ID ? false : true
      }));
    }
  }, [dateFilter]);

  const handleGetDate = (date) => {
    setDateFilter(customeDate(date));
  };

  // const handleChangePage = (idxPage) => {
  // dispatch(getAppointmentList({
  //   'Date': dateFilter,
  //   'Status': statusTab,
  //   'Page': idxPage
  // }));
  // };

  return layoutData ? (
    <>
      <CPPageHeader 
        title={layoutData?.['Title Text']} 
        description={{ value: `${pageName.toLowerCase() === appointmentListPageName ? (
          layoutData?.['IsSaleManager'] 
            ? layoutData?.['Number Booked Text']?.value?.
              replace('[NumberBooked]', resultAppoint?.Appointments?.TotalRecords ?? 0).
              replace('[TotalSlot]', resultAppoint?.TotalSlot ?? 0)
            : `${resultAppoint?.Appointments?.TotalRecords} ${layoutData?.['Title Text']?.value}`
        ) : ''}` }} 
        type={layoutData?.['Hide Date Filter'].value ? '' : 'date'} 
        getDate={handleGetDate} 
      />
      <AppointmentsListingTable 
        layoutData={layoutData} 
        setStatusTab={setStatusTab} 
        resultAppoint={resultAppoint} 
        dateFilter={dateFilter}
      />
      {/* <Pagination>
        {Array.from({ length: 10 }, (_, i) => i).map((number) => (
          <Pagination.Item key={number} onClick={handleChangePage(number)}>
            {number + 1}
          </Pagination.Item>
        ))}
      </Pagination> */}
    </>
  ) : (<></>);
};

AppointmentsListing.propTypes = {
  fields: object
};

export default AppointmentsListing;
