import './OtherWellnessContent.scss';

import React, { useState } from 'react';
// import { useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';

// import { getRelatedWellnessByTags } from '@redux/actions/wellness';
// import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
// import { Text } from '@sitecore-jss/sitecore-jss-react';

// import WellnessContentItem from '../WellnessContentItem';
// import OtherWellnessContentModel from './models/OtherWellnessContentModel';

const OtherWellnessContents = () => {
  // const dispatch = useDispatch();
  // const [dataSourcesLayout, setDatasources] = useState(null);
  // const wellnessArr = useSelector((state) => state.wellnessReducer.getWellnessByTagsReducer?.otherWellnessData);

  // useEffect(() => {
  //   setDatasources(new OtherWellnessContentModel().getData(SitecoreContextFactoryService.getValueContextRouteItem('fields') || {}));

  //   dispatch(getRelatedWellnessByTags());
  // }, []);

  return (
    <div className='other-wellness-contents'>
      {/* <div className='container'>
        { dataSourcesLayout ?
          <h2 className='other-wellness-contents__title text-center'>
            <Text field={dataSourcesLayout['Other Wellness Content Title']}/>
          </h2>
          : '' }
        <div className='other-wellness-contents__content'> 
          <div className='row'>
            {wellnessArr?.length
              ? wellnessArr.map((wellnessItem, wellnessIdx) => {
                return (
                  <div className='col-md-6 col-lg-4 other-wellness-contents__item' key={wellnessIdx}>
                    <WellnessContentItem wellnessItemInfomation={wellnessItem} />
                  </div>
                );
              })
              : ''}
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default OtherWellnessContents;
