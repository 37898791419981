import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class StoreExperienceModel {
  constructor() {
    this['Button Book Experience Text'] = new SitecoreTextModel();

    this['Button Book Experience Link'] = new SitecoreLinkModel();

    this.Title = new SitecoreTextModel();

    this['List Store Experiences'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Book Experience Text')) {
      this['Button Book Experience Text'].getData(dataSource['Button Book Experience Text']);
    }

    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Book Experience Link')) {
      this['Button Book Experience Link'].getData(dataSource['Button Book Experience Link']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'List Store Experiences')) {
      this['List Store Experiences'] = dataSource['List Store Experiences'];
    }

    return this;
  }
}