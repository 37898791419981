import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ContactInformationModel {
  constructor() {
    this.Title = new SitecoreTextModel();

    this['Change Text'] = new SitecoreTextModel();

    this['Save Change Text'] = new SitecoreTextModel();

    this['Phone Number Label'] = new SitecoreTextModel();

    this['Last Name Label'] = new SitecoreTextModel();

    this['First Name Label'] = new SitecoreTextModel();

    this['DoB Label'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Text')) {
      this['Change Text'].getData(dataSource['Change Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Phone Number Label')) {
      this['Phone Number Label'].getData(dataSource['Phone Number Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Save Change Text')) {
      this['Save Change Text'].getData(dataSource['Save Change Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Last Name Label')) {
      this['Last Name Label'] = dataSource['Last Name Label'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'First Name Label')) {
      this['First Name Label'] = dataSource['First Name Label'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DoB Label')) {
      this['DoB Label'].getData(dataSource['DoB Label']);
    }

    return this;
  }
}
