import './TwoColumnImageWithTextImage.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import ModalComponent from '@components/Share/ModalComponent';
import VideoPlayer from '@components/Share/VideoPlayer';
import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import TwoColumnWithTextImageModel from './model/TwoColumnWithTextImageModel';

const TwoColumnWithTextImage = (props) => {
  const [inputData, setInputData] = useState(null);
  const [isShow, setShow] = useState(false);
  const [windowWidth, setWindowWidth] = useState(768);

  const titleFontsize = props.params?.['Font Size'] || '';
  const mobileTitleFontsize = props.params?.['Mobile Font Size'] || '';
  const descriptionFontsize = props.params?.['Description Font Size'] || '';
  const mobileDescriptionFontsize = props.params?.['Mobile Description Font Size'] || '';

  const [titleFontSize, setTitleFontSize] = useState(null);
  const [descriptionFontSize, setDescriptionFontSize] = useState(null);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth && windowWidth < IPAD_BREAKPOINT) {
      setTitleFontSize(mobileTitleFontsize);

      setDescriptionFontSize(mobileDescriptionFontsize);
    }
    if (windowWidth && windowWidth >= IPAD_BREAKPOINT) {
      setTitleFontSize(titleFontsize);

      setDescriptionFontSize(descriptionFontsize);
    }
  }, [windowWidth]);

  useEffect(() => {
    const model = new TwoColumnWithTextImageModel();

    setInputData(model.getData(deepCopy(props.fields) || {})); 
  }, []);

  const onShowModalHandler = () => {
    setShow(true);
  };

  const onCloseModalHandler = () => {
    setShow(false);
  };

  return inputData ? (
    <div
      className={`two-col-image-text ${
        props.params['Position Image'] === 'Image First' ? 'two-col-image-text--content-left' : 'two-col-image-text--content-right'
      } ${props.params['Text Color'] === 'White' ? 'two-col-image-text--text-light' : ''
      } ${props.params['Is Not Full Width'] == 1 ? 'container not-full-width' : ''
      } ${props.params['Is Top Padding'] == 1 ? 'has-top-padding' : ''
      } ${props.params['Is Bottom Padding'] == 1 ? 'has-bottom-padding' : ''
      } ${props.params['Text Align'] == 'Center' ? 'text-align-center' : props.params['Text Align'] == 'Left' ? 'text-align-left' : props.params['Text Align'] == 'Right' ? 'text-align-right' : '' }`}
      style={{
        color: '#'+props.params['Title Text Color Code'] || '',
        lineHeight: props.params['Line Height']+'px' || ''
      }}
    >
      <div className='two-col-image-text__wrap' style={{
        backgroundColor: inputData['Background Color Code'].fields.Value.value || 'transparent'
      }}>
        <div className='two-col-image-text__image'style={{
          backgroundColor: '#'+props.params['1st Background Color Code'] || 'transparent'
        }}>
          <Image field={inputData.Image} />
        </div>
        <div className='two-col-image-text__content'style={{
          backgroundColor: '#'+props.params['2nd Background Color Code'] || 'transparent'
        }}>
          <Text className='two-col-image-text__content__title' tag='h2' field={inputData.Title} style={{ fontSize: titleFontSize+'px' || ''}}/>
          <div className='two-col-image-text__content__desc' style={{ fontSize: descriptionFontSize+'px' || ''}}>
            <RichText tag='p' field={inputData.Description} />
          </div>
          <div className='two-col-image-text__content__img-desc'>
            <Image field={inputData['Description Image']} />
          </div>
          {inputData.items.length ? (
            <ul className='two-col-image-text__content__list'>
              {inputData.items.map((item) => {
                return (
                  <li className='two-col-image-text__content__list-item' key={item.id}>
                    <div className='two-col-image-text__content__list-item__img'>
                      <Image field={item.fields.Image} />
                    </div>
                    <Text className='two-col-image-text__content__list-item__title' tag='h4' field={item.fields.Title} />
                    <RichText className='two-col-image-text__content__list-item__txt' tag='p' field={item.fields.Description} />
                  </li>
                );
              })}
            </ul>
          ) : (
            <></>
          )}
          {inputData['Video Type'] ? (
            <Fragment>
              <div className='btn btn-outline-with-icon' onClick={onShowModalHandler}>
                <span className='video-icon'></span>
                <Text className='btn-text' tag='span' field={inputData['Video Link Label']} />
              </div>
              <ModalComponent isShow={isShow} onCloseHandler={onCloseModalHandler} cssClass={'video-popup'}>
                <VideoPlayer dataFields={inputData} />
              </ModalComponent>
            </Fragment>
          ) : null}
        </div>
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div
        className={`two-col-image-text skeleton__item ${
          props.params['Position Image'] === 'Image First' ? 'two-col-image-text--content-left' : 'two-col-image-text--content-right'
        } ${props.params['Text Color'] === 'White' ? 'two-col-image-text--text-light' : ''} `}
      >
        <div className='two-col-image-text__image image-wrap'>
          <span className='image-line'></span>
        </div>
        <div className='two-col-image-text__content'>
          <span className='text-line'></span>
          <span className='clear-8'></span>
          <span className='text-line'></span>
          <span className='clear-8'></span>
          <span className='text-line'></span>
          <span className='clear-8'></span>
          <span className='text-line'></span>
        </div>
      </div>
    </LoadingShimmer>
  );
};

TwoColumnWithTextImage.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.any
};

export default TwoColumnWithTextImage;
