import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CategoryBannerModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['Mobile Banner Image'] = new SitecoreImageModel();

    this['Banner Image'] = new SitecoreImageModel();

    this['Banner Text Color'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Banner Image')) {
      this['Mobile Banner Image'].getData(dataSource['Mobile Banner Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Image')) {
      this['Banner Image'].getData(dataSource['Banner Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Text Color')) {
      this['Banner Text Color'].getData(dataSource['Banner Text Color']);
    }

    return this;
  }
}
