import AdditionalOptionsSettingModel from '@components/BuyingOptions/model/AdditionalOptionsSettingModel';
import LinkModelWithTitle from '@models/LinkModelWithTitle';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import CartPromotionMilestoneModel from '../model/CartPromotionMilestoneModel';

const textLabel = [
  'Trade In Label',
  'Add Gifting Option Label',
  'Add Selection Label',
  'Available Label',
  'CTA Button Text',
  'Cancel Button Text',
  'Cart Empty Text',
  'Cart Total Amount Label',
  'Cart Total Quantity Message',
  'Character Limit',
  'Characters Label',
  'Check Out Text',
  'Delivery Label',
  'Deposit Label',
  'Edit Button Text',
  'Free Gift Hint',
  'Free Gift Label',
  'Free Gift Number Label',
  'Free Text',
  'Fulfillment Option Label',
  'Gift Card Label',
  'Gift Message Label',
  'Gift Wrap Label',
  'Gifting Option Label',
  'Move To Wishlist Label',
  'Quantity Item Label',
  'Quantity Items Label',
  'Remove Button Text',
  'Remove Gift Label',
  'Remove Product Description',
  'Remove Product Label',
  'Remove Product Title',
  'SF Express Area Label',
  'SF Express District Label',
  'SF Express NetWork Label',
  'SF Express Popup Description',
  'SF Express Popup Title',
  'SF Express Station Label',
  'Save Gift Label',
  'Saved Message',
  'Select Gifting Option Label',
  'Shipping Message',
  'Special Deal Label',
  'Store Availability Message',
  'Store Pick Up Label',
  'Sub Total Label',
  'Title',
  'Free Gift Out Of Stock Message',
  'Pre Order Label',
  'Balance Payment Label',
  'Payment Period Label',
  'Payment Period Details',
  'Pay Deposit Label',
  'Warning Details',
  'Close Button Text',
  'CP Cart Empty Text',
  'CP Cart Title',
  'Close Button Text',
  'Add On Deal Label',
  'CP Cart Edit Order',
  'Create and expire date',
  'Order id',
  'Package count',
  'CP Customer Name Title',
  'Milestone Message',
  'Back Label',
  'Deposit Gift',
  'Balance Gift'
];

const imageLable = [
  'CP Cart Icon'
];

export default class CartDetailModel {
  constructor() {
    textLabel.map((item) => (this[item] = new SitecoreTextModel()));

    imageLable.map((item) => (this[item] = new SitecoreImageModel()));

    this['Buying Option Page Link'] = '';

    this['CTA Button'] = new SitecoreLinkModel();

    this['Fulfillment Link'] = new LinkModelWithTitle();

    this['Checkout Link'] = new LinkModelWithTitle();

    this['Additional Link'] = new LinkModelWithTitle();

    this['Bundle Buying Option Link'] = new LinkModelWithTitle();

    this['Buying Option Link'] = new LinkModelWithTitle();

    this['Edit Bundle Buying Option Link'] = '';

    this.showSelectArea = true;

    this['Additional Options Setting'] = new AdditionalOptionsSettingModel();

    this['Promotion Milestone'] = new CartPromotionMilestoneModel();
  }
  getData(dataSource) {
    textLabel.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    imageLable.map((item) => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (!dataSource['Address Settings'] || dataSource['Address Settings']?.fields['Address Elements'].length === 0) {
      this.showSelectArea = false;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Fulfillment Link')) {
      this['Fulfillment Link'].getData(dataSource['Fulfillment Link']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Checkout Link')) {
      this['Checkout Link'].getData(dataSource['Checkout Link']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Link')) {
      this['Additional Link'].getData(dataSource['Additional Link']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Bundle Buying Option Link')) {
      this['Bundle Buying Option Link'].getData(dataSource['Bundle Buying Option Link']?.fields || {});

      this['Edit Bundle Buying Option Link'] = this['Bundle Buying Option Link'].Link.value.href;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buying Option Link')) {
      this['Buying Option Link'].getData(dataSource['Buying Option Link']?.fields || {});

      this['Buying Option Page Link'] = this['Buying Option Link'].Link.value.href;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Options Setting')) {
      this['Additional Options Setting'].getData(dataSource['Additional Options Setting']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Promotion Milestone')) {
      this['Promotion Milestone'].getData(dataSource['Promotion Milestone']?.fields || {});
    }

    return this;
  }
}
