import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getClientSOCartFailure, getClientSOCartSuccess } from '@redux/actions/personalizeOffer/getClientSOCart';
import { GET_CLIENT_SO_CART_START } from '@redux/actions/personalizeOffer/getClientSOCart/types';
import CartDetailService from '@redux/epic/product/cartDetail/service';
import { get } from '@services/genericService';

import { GET_CLIENT_SO_CART_ENDPOINT } from './configs';

export const getClientSOCartEpic = (action$) =>
  action$.pipe(
    ofType(GET_CLIENT_SO_CART_START),
    switchMap((action) => {
      const params = {
        cartId: action.payload.cartId
      };

      return from(get(GET_CLIENT_SO_CART_ENDPOINT, params)).pipe(
        map((res) => {
          if (res.status === 200 || res.data?.Success) {
            return getClientSOCartSuccess({
              ...res.data,
              OsimCart: CartDetailService.customCurrentCart(res.data.OsimCart)
            });
          } else {
            return getClientSOCartFailure(res.data || 'Failed');
          }
        }),
        catchError((error) => of(getClientSOCartFailure(error)))
      );
    })
  );
