import LinkModel from '@models/LinkModel';

export default class VideoLinkModel {
  constructor() {
    this.value = new LinkModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this['value'].getData(dataSource['value']);
    }

    return this;
  }
}
