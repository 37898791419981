import * as cpLeadManagementTypes from './cpLeadManagementTypes.js';

export const staffLeadManagement = (payload) => ({
  type: cpLeadManagementTypes.STAFF_LEAD_MANAGEMENT,
  payload
});

export const staffLeadManagementSuccess = (payload) => ({
  type: cpLeadManagementTypes.STAFF_LEAD_MANAGEMENT_SUCCESS,
  payload
});

export const staffLeadManagementFailed = (error) => ({
  type: cpLeadManagementTypes.STAFF_LEAD_MANAGEMENT_FAILED,
  error
});

export const managerLeadManagement = (payload) => ({
  type: cpLeadManagementTypes.MANAGER_LEAD_MANAGEMENT,
  payload
});

export const managerLeadManagementSuccess = (payload) => ({
  type: cpLeadManagementTypes.MANAGER_LEAD_MANAGEMENT_SUCCESS,
  payload
});

export const managerLeadManagementFailed = (error) => ({
  type: cpLeadManagementTypes.MANAGER_LEAD_MANAGEMENT_FAILED,
  error
});

export const getSaleStaffs = (payload) => ({
  type: cpLeadManagementTypes.GET_SALE_STAFFS,
  payload
});

export const getSaleStaffsSuccess = (payload) => ({
  type: cpLeadManagementTypes.GET_SALE_STAFFS_SUCCESS,
  payload
});

export const getSaleStaffsFailed = (error) => ({
  type: cpLeadManagementTypes.GET_SALE_STAFFS_FAILED,
  error
});