import AdditionalOptionsSettingModel from '@components/BuyingOptions/model/AdditionalOptionsSettingModel';
import AddressPopupModel from '@components/SlicingPage/MyAccount/AddressesWidget/models/AddressPopupModel';
import AddressSettingModel from '@components/SlicingPage/MyAccount/MyAddress/models/AddressSettingModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const textLabel = [
  'Back To Your Detail Label',
  'Billing Add New Address Label',
  'Billing Address Label',
  'Billing Same as Delivery Address Label',
  'Billing Same as Delivery SF Express Address Label',
  'Billing See More Address Label',
  'Billing Title',
  'Continue Payment Label',
  'Default Label',
  'Delivery Add New Address Label',
  'Delivery Address Label',
  'Delivery Package Title',
  'Delivery Product List Label',
  'Delivery See More Address Label',
  'Free Gift Label',
  'Gifting Option Label',
  'No Address Message',
  'Add New Address Button Text',
  'PopUp Add New Address Button Text',
  'PopUp Add New Address Label',
  'PopUp Address Line 1 Label',
  'PopUp Address Line 2 Label',
  'PopUp Address Name Label',
  'PopUp Area Selection Label',
  'PopUp City Selection Label',
  'PopUp Default Label',
  'PopUp Province Selection Label',
  'PopUp Select Address Label',
  'PopUp Select Label',
  'PopUp Use this for Billing Address Label',
  'PopUp Zip Portal Code Label',
  'Quantity Label',
  'SF Pickup Address Label',
  'SF Pickup Package Title',
  'SF Pickup Product List Label',
  'SF Pickup See More Address Label',
  'SF Express Address Placeholder',
  'Save Label',
  'Edit Label',
  'Special Deal Purchase with Purchase Label',
  'Delivery You Saved Label',
  'Delivery Quantity Label',
  'Delivery Free Gift Label',
  'Delivery Free Label',
  'Back To Cart Label',
  'Phone Number',
  'Please Select Address Message',
  'PopUp Accept Label',
  'PopUp Confirm Description',
  'PopUp Confirm Select Address Label',
  'PopUp Cancel Label',
  'Pick Up Fee Label',
  'SF Express Station Label',
  'Select Area And District Label',
  'SF Express Area Label',
  'SF Express District Label',
  'SF Express NetWork Label',
  'Delivery Product List Label',
  'SF Pickup Product List Label',
  'PopUp Select Label',
  'Free Gift Hint',
  'Gift Card Label',
  'Gift Wrap Label',
  'Delivery SF Express Package Label',
  'Eligible For Priority Delivery Label',
  'Priority Delivery Member Label',
  'Check Priority Delivery',
  'Priority Delivery Label',
  'Image Note Priority Delivery',
  'Note Priority Delivery Label',
  'Priority Delivery Info',
  'Free Text',
  'Sub Total Label',
  'Deposit Label',
  'Add On Deal Label',
  'Incomplete Address Message',
  'Close Button Text',
  'PopUp Limit Character Address Message',
  'Deposit Gift',
  'Balance Gift'
];

const linkLable = [
  'Back To Your Detail Button Link',
  'Continue Payment Button Link',
  'Back To Cart Link',
  'Continue Offer Payment Button Link',
  'Back To Offer Cart Link'
];

export default class FulfillmentOptionsModel {
  constructor() {
    textLabel.forEach((itemText) => {
      this[itemText] = new SitecoreTextModel();
    });

    linkLable.forEach((itemText) => {
      this[itemText] = new SitecoreLinkModel();
    });

    this['Address Settings'] = new AddressSettingModel();

    this['Area Shipping Fee Setting'] = null;

    this['DataSoures Popup'] = new AddressPopupModel();

    this['Additional Options Setting'] = new AdditionalOptionsSettingModel();

    this['Hide Priority Delivery'] = false;
  }
  getData(dataSource) {
    textLabel.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    linkLable.forEach((itemText) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, itemText)) {
        this[itemText].getData(dataSource[itemText]);
      }
    });

    this['DataSoures Popup'].getData(dataSource);

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Address Settings')) {
      this['Address Settings'].getData(dataSource['Address Settings']?.fields || {}, true);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Area Shipping Fee Setting') && dataSource['Area Shipping Fee Setting']) {
      let model = new AddressSettingModel();

      this['Area Shipping Fee Setting'] = model.getData(dataSource['Area Shipping Fee Setting']?.fields || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Options Setting')) {
      this['Additional Options Setting'].getData(dataSource['Additional Options Setting']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Hide Priority Delivery')) {
      this['Hide Priority Delivery'] = dataSource['Hide Priority Delivery'];
    }

    return this;
  }
}
