import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';

export default class CenterBannerFieldsModel {
  constructor() {
    this['Background Image'] = new SitecoreImageModel();

    this['Mobile Background Image'] = new SitecoreImageModel();

    this['Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Image')) {
      this['Background Image'].getData(dataSource['Background Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Background Image')) {
      this['Mobile Background Image'].getData(dataSource['Mobile Background Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this['Link'].getData(dataSource['Link']);
    }

    return this;
  }
}