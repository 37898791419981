import produce from 'immer';

import BOWarrantyModel  from '@components/BuyingOptions/BOWarranty/model/BOWarrantyModel';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as productTypes from '@redux/actions/product/productTypes';
import { PROMO_WARRANTY_TYPE } from '@utils/constant';
import { deepCopy } from '@utils/utility.js';

const initState = {
  warrantyList: [],
  warrantyActiveID: [],
  warrantyActive: null,
  warratySelectedLength: 0,
};

function getWarrantyByID(warrantyID, warrantyArray) {
  return warrantyArray.find((item) => item.ProductId === warrantyID);
}

const BOWarrantyReducer = (state = initState, action) =>
  produce(state, draft => {
    
    let newWarrantyList = [];

    let filterWarrantyFromCartLine = [];

    switch (action.type) {
    case buyingOptionsTypes.GET_BO_DATA_SUCCESS:
      if (action.payload.ProductSummary.Warranty?.length) {
        action.payload.ProductSummary.Warranty.map(element => {
          newWarrantyList.push(new BOWarrantyModel().getData(deepCopy(element) || {}));
        });

        draft.warrantyList = deepCopy(newWarrantyList);

        draft.warrantyActiveID = [draft.warrantyList[0].ProductId];

        draft.warrantyActive = getWarrantyByID(draft.warrantyActiveID[0], draft.warrantyList);
      }

      break;
      
    case buyingOptionsTypes.ADD_CHOSEN_BO_WARRANTY:

      draft.warrantyActiveID = [action.payload];

      draft.warrantyActive = getWarrantyByID(action.payload, draft.warrantyList);
  
      draft.warrantySelectedLength = draft.warrantyActiveID.length;

      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:

      if (action.filteredCartLine.length) {

        filterWarrantyFromCartLine = action.filteredCartLine[0].PromotionCartLines?.filter(item => item.Type === PROMO_WARRANTY_TYPE);

        if (filterWarrantyFromCartLine.length) {
          
          draft.warrantyActiveID = [filterWarrantyFromCartLine[0].ProductId];
  
          draft.warrantyActive = getWarrantyByID(draft.warrantyActiveID[0], draft.warrantyList);
    
          draft.warrantySelectedLength = draft.warrantyActiveID.length;
        }
      }

      break;

    case productTypes.GET_CURRENT_SO_CART_SUCCESS:

      if (action.filteredCartLine.length) {

        filterWarrantyFromCartLine = action.filteredCartLine[0].PromotionCartLines?.filter(item => item.Type === PROMO_WARRANTY_TYPE);

        if (filterWarrantyFromCartLine.length) {

          draft.warrantyActiveID = [filterWarrantyFromCartLine[0].ProductId];

          draft.warrantyActive = getWarrantyByID(draft.warrantyActiveID[0], draft.warrantyList);

          draft.warrantySelectedLength = draft.warrantyActiveID.length;
        }
      }

      break;
    }

    return draft;

  });

export default BOWarrantyReducer;
