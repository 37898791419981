import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const WellnessDetailHeaderTagItem = ({ tag }) => {
  const WellnessURL = useSelector((state) => state.settingGlobalURLReducer?.settingGlobalURL?.WellnessListingURL || '');

  return (
    <li className='wellness-detail-header__tag-item'>
      <a href={`${WellnessURL}?Tags=${tag.id}`} className='featured-article-item__tags__item'>
        <Text field={tag.fields.Title} />
      </a>
    </li>
  );
};

WellnessDetailHeaderTagItem.propTypes = {
  tag: PropTypes.object
};

export default WellnessDetailHeaderTagItem;
