import { useCallback, useMemo } from 'react';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { ACTION_UPLOAD_LIST_FILES } from '@redux/epic/productReview/config';
import { post } from '@services/genericService';

import { useTradeInUploadImage } from '../TradeInUploadImageProvider';
import { MAX_FILE_ALLOW_UPLOAD } from './configs';
import { isFilesSizeValid, isFilesTypeValid } from './utils';

export const useUploadImageService = () => {
  const uploadImageService = useCallback((formData) => {
    return new Promise((resolve, reject) => {
      return from(post(ACTION_UPLOAD_LIST_FILES, formData, { 'Content-Type': 'multipart/form-data' }, 'tradein'))
        .pipe(
          map((res) => {
            if (res.status === 200) {
              let imageUploadedUrl = [];
              if (res.data?.length) {
                for (const item of res.data) {
                  if (item?.Data?.Data) imageUploadedUrl.push(item.Data.Data);
                }
              }

              return resolve(imageUploadedUrl);
            } else {
              console.error(res);

              return reject([]);
            }
          }),
          catchError((error) => {
            console.error(error);

            return reject([]);
          })
        )
        .subscribe();
    });
  }, []);

  return useCallback(async (files) => {
    let formData = new window.FormData();
    const filesArr = Array.from(files);
    for (const [index, item] of filesArr.entries()) {
      formData.append('file' + index, new window.File([item], item?.name));
    }

    const uploadImageResponse = await uploadImageService(formData);

    return uploadImageResponse.length
      ? filesArr.map((file, index) => ({
        name: file.name,
        size: file.size,
        type: file.type,
        url: uploadImageResponse[index]
      }))
      : [];
  }, []);
};

export const useHandleUploadImage = () => {
  const { selectedImage, setSelectedImage, dataFields, formikRefCurrent } = useTradeInUploadImage();
  const uploadImageService = useUploadImageService();
  const imageTradeInProduct = useMemo(() => formikRefCurrent.values.imageTradeInProduct, [formikRefCurrent.values]);

  return useCallback(
    async (event) => {
      const files = event.target.files;
      const filesArray = Array.from(files);
      const isFileSizeValidCheck = isFilesSizeValid(filesArray);
      const isFilesTypeValidCheck = isFilesTypeValid(filesArray);
      const isNumberFilesUploadValid = imageTradeInProduct.length + filesArray.length <= MAX_FILE_ALLOW_UPLOAD;

      if (isFileSizeValidCheck && isFilesTypeValidCheck && isNumberFilesUploadValid) {
        const uploadFilesResponse = await uploadImageService(files);
        if (uploadFilesResponse.length) {
          setSelectedImage(filesArray.length ? filesArray : selectedImage);

          formikRefCurrent.setFieldValue('imageTradeInProduct', [...imageTradeInProduct, ...uploadFilesResponse]);

          // INFO: remove error message of imageTradeInProduct
          formikRefCurrent.setFieldError('imageTradeInProduct', '');

          formikRefCurrent.setTouched({
            imageTradeInProduct: false
          });
        }
      } else {
        if (!isNumberFilesUploadValid) formikRefCurrent.setFieldError('imageTradeInProduct', dataFields?.['Max Upload File Text']?.value);
        if (!isFileSizeValidCheck) formikRefCurrent.setFieldError('imageTradeInProduct', dataFields?.['Maximum Allow File Size Label']?.value);
        if (!isFilesTypeValidCheck) formikRefCurrent.setFieldError('imageTradeInProduct', dataFields?.['File Type Is Not Correct Label']?.value);

        // INFO: set touched to show error message
        formikRefCurrent.setTouched({
          imageTradeInProduct: true
        });
      }
    },
    [imageTradeInProduct, formikRefCurrent, dataFields]
  );
};
