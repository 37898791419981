import { func } from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

import FilterScheduleStoreBtn from './filterStoreScheduleBtn';

const initialStates = {
  // eslint-disable-next-line no-unused-vars
  onSearch: (keyword) => {},
  // eslint-disable-next-line no-unused-vars
  onFilter: (params) => {},
  onClearFilterArea: () => {}
};
const SearchScheduleFilterStoreBarContext = createContext(initialStates);

export const useFilterStoreBarContext = () => useContext(SearchScheduleFilterStoreBarContext);

const SearchScheduleFilterStoreBar = ({ onSearch, onFilter, onClearFilterArea }) => {
  const [keyword, setKeyWord] = useState('');

  const handleChangeSearchInput = (event) => {
    const value = event.target.value;

    setKeyWord(value);

    onSearch(value.toLowerCase());
  };

  return (
    <SearchScheduleFilterStoreBarContext.Provider
      value={{
        onSearch,
        onFilter,
        onClearFilterArea
      }}
    >
      <div className='store-locator__content__list__search search-filter-bar'>
        <div className='store-locator__search__input'>
          <input
            type='text'
            className='form-control form-control-lg store-locator__search__input__item'
            value={keyword}
            onChange={handleChangeSearchInput}
          />
        </div>

        <div className='store-locator__search__btn'>
          <FilterScheduleStoreBtn />
        </div>
      </div>
    </SearchScheduleFilterStoreBarContext.Provider>
  );
};

SearchScheduleFilterStoreBar.propTypes = {
  onSearch: func,
  onFilter: func,
  onClearFilterArea: func
};

export default SearchScheduleFilterStoreBar;
