import * as cpAppointmentListingTypes from './cpAppointmentListingTypes';

export const getAppointmentList = ((payload) => ({
  type: cpAppointmentListingTypes.GET_APPOINTMENT_LIST,
  payload
}));

export const getAppointmentListSuccess = ((payload) => ({
  type: cpAppointmentListingTypes.GET_APPOINTMENT_LIST_SUCCESS,
  payload
}));

export const getAppointmentListFailed = ((payload) => ({
  type: cpAppointmentListingTypes.GET_APPOINTMENT_LIST_FAILED,
  payload
}));