import { func, object } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const CouponWalletDetailPopup = ({ layoutData, couponDetailData, applySelectedCoupon }) => {
  const onCovertEpochToDate = (param) => {
    let MONTH_NAMES = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    let date = new Date(parseInt(param.replace(/\D/g, '')));

    return `${date.getDate()} ${MONTH_NAMES[date.getMonth()]} ${date.getFullYear()}`;
  };

  return (
    <>
      {couponDetailData.isLoadingMask ? (
        <>
          <div className='osim-popup__body coupon-popup__info skeleton__item'>
            <h4 className='coupon-popup__info-title'>
              <span className='text-line-40-center w-60'></span>
            </h4>
            <div className='coupon-popup__info-text'>
              <span className='text-line-27'></span>
            </div>
            <span className='coupon-popup__info-validity'>
              <span className='text-line-27'></span>
            </span>
          </div>
          <div className='osim-popup__footer coupon-popup__action'>
            <span className='text-line-50 w-30'></span>
          </div>
        </>
      ) : (
        <>
          {couponDetailData?.couponDetail?.Promotion?.IsSingleCoupon ? (
            <>
              <div className='osim-popup__body coupon-popup__info--single-coupon'>
                <h3 className='coupon-popup__info-title'>{couponDetailData?.couponDetail?.Promotion?.Title}</h3>
                <div className={
                  `coupon-popup__banner${
                  couponDetailData?.couponDetail?.Promotion?.BackgroundImage ? '' : ' coupon-popup__banner--no-image'
                  }`
                }>
                  {
                    couponDetailData?.couponDetail?.Promotion?.BackgroundImage
                      ? (
                        <div className='coupon-popup__banner__bg'>
                          <img src={couponDetailData?.couponDetail?.Promotion?.BackgroundImage} alt='banner' />
                        </div>
                      )
                      : ''
                  }
                  <div className='coupon-popup__banner__content'>
                    <p className='coupon-popup__banner__content__desc' dangerouslySetInnerHTML={{__html: couponDetailData?.couponDetail?.Promotion?.Description}}></p>
                    <span className='coupon-popup__banner__content__date'>
                      {couponDetailData?.couponDetail?.Promotion?.ValidFromToText.replace(
                        '{from}',
                        onCovertEpochToDate(couponDetailData?.couponDetail?.Promotion?.ValidityFrom)
                      ).replace('{to}', onCovertEpochToDate(couponDetailData?.couponDetail?.Promotion?.ValidityTo))}
                    </span>
                    <h5 className='coupon-popup__banner__content__code'>
                      {couponDetailData?.couponDetail?.Promotion?.CouponText.replace('{coupon}','')}
                      <b>{couponDetailData?.couponDetail?.Promotion?.Code}</b>
                    </h5>
                  </div>
                </div>
                <div className='coupon-popup__list'>
                  {couponDetailData?.couponDetail?.Promotion?.Benefits.map((benefit, index) => {
                    return (
                      <div className='coupon-popup__item' key={index}>
                        <div className='coupon-popup__item__image'>
                          <a href={benefit?.Link}>
                            <img src={benefit?.Image} alt='banner' />
                          </a>
                        </div>
                        <div className='coupon-popup__item__content'>
                          <a href={benefit?.Link}>
                            <h4 className='coupon-popup__item__content__title'>{benefit?.Title}</h4>
                          </a>
                          <h5 className='coupon-popup__item__content__sub-title'>{benefit?.SubTitle}</h5>
                          <p className='coupon-popup__item__content__desc' dangerouslySetInnerHTML={{__html: benefit?.Description}}></p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className='osim-popup__footer coupon-popup__action'>
                <button className='btn btn-primary' onClick={() => applySelectedCoupon(couponDetailData?.couponDetail?.Promotion?.Code)}>
                  {couponDetailData?.couponDetail?.Promotion?.ButtonText}
                </button>
              </div>
            </>
          ) : (
            <>
              <div className='osim-popup__body coupon-popup__info'>
                <h4 className='coupon-popup__info-title'>{couponDetailData?.couponDetail?.Promotion?.Title}</h4>
                <div className='text-center'>
                  <Text tag='span' className='coupon-popup__hint-text' field={layoutData['Hint Text']} />
                  <span className='coupon-popup__code'>{couponDetailData?.couponDetail?.Promotion?.Code}</span>
                </div>
                <div className='coupon-popup__info-text' dangerouslySetInnerHTML={{ __html: couponDetailData?.couponDetail?.Promotion?.Description }}></div>
                <span className='coupon-popup__info-validity'>
                  <Text tag='strong' field={layoutData['Valid From Label']} />
                  <span>: {onCovertEpochToDate(couponDetailData?.couponDetail?.Promotion?.ValidityFrom)} </span>
                  <Text tag='span' field={layoutData['To Label']} />
                  <span> {onCovertEpochToDate(couponDetailData?.couponDetail?.Promotion?.ValidityTo)}</span>
                </span>
              </div>
              <div className='osim-popup__footer coupon-popup__action'>
                <button className='btn btn-outline-primary' onClick={() => applySelectedCoupon(couponDetailData?.couponDetail?.Promotion?.Code)}>
                  <Text field={layoutData['Apply Offer Text']} />
                </button>
              </div>
            </>
          )}
        </>
      )}
    </>
  );
};

CouponWalletDetailPopup.propTypes = {
  layoutData: object,
  couponDetailData: object,
  applySelectedCoupon: func
};

export default CouponWalletDetailPopup;
