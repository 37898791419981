import './CPSelect.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { QUESTION_TYPE_EDIT_UPSELL } from '../FormConstant';

const CPSelect = ({
  selectId,
  selectName,
  selectPlaceholder,
  options,
  selectClass,
  onChangeSelect,
  questionCode,
  questionType,
  isResetProductSelection,
  isDisabled,
  defaultValue,
  toggleResetDropdownQuestion
}) => {
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const [selectedData, setSelectedData] = useState(defaultValue || null);

  const onChangeSelectedData = (data) => {
    setSelectedData(data.data);

    questionType == 'Add-New-Product-Interest'
      ? onChangeSelect({
        value: data.value,
        questionType: questionType,
        commerceProductId: data.data.commerceProductId || '',
        productPageId: data.data.productPageId || ''
      })
      : onChangeSelect({
        value: data.value,
        questionCode: data.questionCode,
        questionType: data.questionType,
        commerceProductId: data.data.commerceProductId || '',
        productPageId: data.data.productPageId || ''
      });
  };

  const onGenerateSelectedOption = (data) => {
    return {
      value: data,
      label: data
    };
  };

  useEffect(() => {
    if (isResetProductSelection == 'QuickAccessPrefixPhoneNumber') {
      setSelectedData(options[0]);
    } else {
      setSelectedData(null);
    }
  }, [isResetProductSelection]);

  useEffect(() => {
    setSelectedData(options?.[0]);
    if (questionType == 'Add-New-Product-Interest') {
      setSelectedData(null);
    }
    if (questionType == 'Drop Down' && isResetProductSelection != 'QuickAccessPrefixPhoneNumber') {
      if (clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HouseholdSize?.length) {
        setSelectedData(onGenerateSelectedOption(clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HouseholdSize));
      } else setSelectedData(null);
    }
    if (questionType === QUESTION_TYPE_EDIT_UPSELL) {
      if(defaultValue.value && defaultValue.label){
        setSelectedData(defaultValue);
      } else {
        setSelectedData(null);
      }
    }
  }, [clientTellingPortalCustomer.tmpCustomerInformation]);

  useDidUpdateEffect(() => {
    setSelectedData(defaultValue);
  }, [toggleResetDropdownQuestion]);

  return (
    <Select
      open={true}
      id={selectId}
      name={selectName}
      onChange={(data) =>
        onChangeSelectedData({
          data: data,
          questionCode: questionCode,
          questionType: `CPCustomerInfo-${questionType}`,
          value: data.value
        })
      }
      value={selectedData}
      options={options}
      className={`cp-select ${selectClass}`}
      classNamePrefix='cp-select'
      placeholder={selectPlaceholder}
      isSearchable={questionType == 'Add-New-Product-Interest' ? true : false}
      isDisabled={isDisabled}
    />
  );
};

CPSelect.propTypes = {
  selectId: PropTypes.string,
  selectName: PropTypes.string,
  selectPlaceholder: PropTypes.string,
  selectDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  options: PropTypes.array,
  selectClass: PropTypes.string,
  onChangeSelect: PropTypes.func,
  questionCode: PropTypes.string,
  questionType: PropTypes.string,
  isResetProductSelection: PropTypes.any,
  isDisabled: PropTypes.bool,
  toggleResetDropdownQuestion: PropTypes.bool,
  defaultValue: PropTypes.object
};

export default CPSelect;
