import React, { useMemo, useRef, useState } from 'react';

import LoadingMask from '@components/Share/LoadingMask';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useOnScreen } from '@utils/customsHook/useOnScreen';

import { useFetchOfferListing } from '../hooks';
import { usePersonalizedOffersWidget } from '../PersonalizedOffersWidgetProvider';

const LazyLoading = () => {
  const { currentPage, setCurrentPage, totalPage, isLoading } = usePersonalizedOffersWidget();
  const fetchOfferListingService = useFetchOfferListing();
  const loadingContainerRef = useRef();
  const isOnScreen = useOnScreen(loadingContainerRef);
  const [isStartToFetch, setIsStartToFetch] = useState(false);

  useDidUpdateEffect(() => {
    if (isOnScreen) {
      if (!isStartToFetch) {
        setIsStartToFetch(true);
      } else {
        const nextPage = currentPage + 1;
        if (nextPage <= totalPage) {
          fetchOfferListingService(nextPage);

          setCurrentPage(nextPage);
        }
      }
    }
  }, [isOnScreen]);

  const isFetchingNewData = useMemo(() => {
    return Boolean(isLoading && isOnScreen && isStartToFetch);
  }, [isLoading, isOnScreen, isStartToFetch]);

  return (
    <div
      aria-label={`personalized-offer-listing-busy-${isFetchingNewData}`}
      ref={loadingContainerRef}
      className='personalized-offer-listing__lazy-loading'
    >
      {isLoading ? <LoadingMask /> : <></>}
    </div>
  );
};

export default LazyLoading;
