import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const FooterNavigationItem = (props) => {
  return <Fragment>{props.children}</Fragment>;
};

FooterNavigationItem.propTypes = {
  children: PropTypes.any
};

export default FooterNavigationItem;
