import './OfferWidget.scss';

import { object } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import Loading from '@components/Share/Loading';
import LoadingMask from '@components/Share/LoadingMask';
import ModalComponent from '@components/Share/ModalComponent';
import SwipeComponent from '@components/Share/SwipeComponent';
import { applyCouponCode, getCouponDetail } from '@redux/actions/coupon';
import { getCouponWalletList } from '@redux/actions/couponWallet';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import CouponWalletDetailPopup from './CouponWalletDetailPopup';
import CouponWalletListPopup from './CouponWalletListPopup';
import DesktopCouponWalletItem from './DesktopCouponWalletItem';
import MobileCouponWalletItem from './MobileCouponWalletItem';
import CouponWalletModel from './model/CouponWalletModel';

const CouponWallet = ({ fields, couponWalletData, couponDetailData }) => {
  const [layoutData, setLayoutData] = useState(null);
  const [isShowModal, setShowModal] = useState(null);
  const [isShowCouponDetail, setShowCouponDetail] = useState(false);
  const [displayFlag, setDisplayFlag] = useState(false);
  const dispatch = useDispatch();
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [errMsg, setErrMsg] = useState('');

  const swipeParams = {
    slidesPerView: 1.3,
    spaceBetween: 16,
    watchOverflow: true,
    centerInsufficientSlides: true,
    repsponsive: [
      {
        breakpoint: 1200,
        settings: 'unslick'
      }
    ]
  };

  const getCouponDetailHandler = (couponData, bool) => {
    bool ? setDisplayFlag(false) : setDisplayFlag(true);

    setShowCouponDetail(true);

    const params = {
      id: couponData.Id,
      code: couponData.Code
    };

    dispatch(getCouponDetail(params));
  };

  const applySelectedCoupon = (code) => {
    setSelectedCoupon(code);

    setShowModal(false);

    setShowCouponDetail(false);

    dispatch(applyCouponCode({ code: code, reCallPromotionsApplied: false }));
  };

  useEffect(() => {
    const model = new CouponWalletModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useEffect(() => {
    if(layoutData?.Personalization) {
      dispatch(getCouponWalletList({personalizationValue: layoutData?.Personalization?.value}));
    }
  }, [layoutData?.Personalization]);

  useDidUpdateEffect(() => {
    if (couponWalletData?.redirectLink) {
      window.location.href = couponWalletData?.redirectLink;
    } else if (couponWalletData?.errors) {
      setErrMsg(couponWalletData?.errors?.[0]);
    }

    setSelectedCoupon(null);
  }, [couponWalletData?.errors, couponWalletData?.redirectLink]);

  useDidUpdateEffect(() => {
    if (couponDetailData?.success) {
      window.location.href = layoutData?.['Cart Page Link']?.value?.href?.length ? layoutData?.['Cart Page Link']?.value?.href : '/product';
    }
  }, [couponDetailData?.success]);

  return layoutData ? (
    <div className='my-account-section offer-widget'>
      <div className='my-account-section__heading offer-widget__heading animated-slow fadeIn delay-100'>
        <div className='my-account-section__group'>
          <Text className='my-account-section__heading__title' field={layoutData['Title']} tag='h3' />
          {couponWalletData?.couponWalletList.length > 3 ? (
            <Text
              tag='span'
              className='my-account-section__btn-link'
              field={layoutData['View All Text']}
              onClick={() => {
                setShowModal(true);
              }}
            />
          ) : (
            <></>
          )}
        </div>
        {errMsg ? <span className='error-validate'>{errMsg}</span> : <></>}
      </div>
      <>
        {couponWalletData.isLoading ? (
          <Loading />
        ) : (
          <div className='my-account-section__content offer-widget__content animated-slow fadeIn delay-100'>
            <div className='offer-widget__content__list offer-widget__content__list--mobile'>
              <SwipeComponent param={swipeParams}>
                {couponWalletData?.couponWalletList.map((coupon) => {
                  return (
                    <div className='offer-widget__content__item coupon-popup__item' key={coupon.Id}>
                      <MobileCouponWalletItem 
                        data={coupon} 
                        getCouponDetailHandler={getCouponDetailHandler} 
                        selectedCoupon={selectedCoupon} 
                        layoutData={layoutData}
                      />
                    </div>
                  );
                })}
              </SwipeComponent>
            </div>
            <div className='offer-widget__content__list offer-widget__content__list--desktop'>
              {couponWalletData?.couponWalletList.map((coupon, index) => {
                return (
                  <>
                    {index < 4 ? (
                      <DesktopCouponWalletItem
                        data={coupon}
                        getCouponDetailHandler={getCouponDetailHandler}
                        key={coupon.Id}
                        selectedCoupon={selectedCoupon}
                        layoutData={layoutData}
                      />
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        )}
      </>
      <ModalComponent
        isShow={isShowModal}
        cssClass={'offer-widget-popup'}
        onCloseHandler={() => setShowModal(false)}
        titlePopup='Offers Just For You'
      >
        <CouponWalletListPopup
          couponWalletData={couponWalletData}
          layoutData={layoutData}
          getCouponDetailHandler={getCouponDetailHandler}
          applySelectedCoupon={applySelectedCoupon}
          selectedCoupon={selectedCoupon}
          setSelectedCoupon={(code) => setSelectedCoupon(code)}
        />
      </ModalComponent>
      <ModalComponent
        isShow={isShowCouponDetail}
        onCloseHandler={() => {
          setShowCouponDetail(false);

          displayFlag ? setShowModal(false) : setShowModal(true);
        }}
        titlePopup={<Text tag='span' field={layoutData['Coupon Info Text']} />}
      >
        <CouponWalletDetailPopup couponDetailData={couponDetailData} layoutData={layoutData} applySelectedCoupon={applySelectedCoupon} />
      </ModalComponent>
      {couponDetailData?.isLoading ? <LoadingMask /> : <></>}
    </div>
  ) : (
    <></>
  );
};

const mapStateToProps = (state) => ({
  couponWalletData: state.couponWalletReducer || {},
  couponDetailData: state.couponReducer || {}
});

CouponWallet.propTypes = {
  fields: object,
  couponWalletData: object,
  couponDetailData: object
};

export default connect(mapStateToProps)(CouponWallet);
