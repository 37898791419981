import * as cpAppointmentDetailTypes from './cpAppointmentDetailTypes';

export const getCpAppointmentDetail = (payload) => ({
  type: cpAppointmentDetailTypes.CP_GET_APPOINTMENT_DETAIL,
  payload
});

export const getCpAppointmentDetailSuccess = (payload) => ({
  type: cpAppointmentDetailTypes.CP_GET_APPOINTMENT_DETAIL_SUCCESS,
  payload
});

export const getCpAppointmentDetailFailed = (payload) => ({
  type: cpAppointmentDetailTypes.CP_GET_APPOINTMENT_DETAIL_FAILED,
  payload
});