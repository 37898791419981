import './PurchaseOnSpotCompleted.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Text, Link, Image } from '@sitecore-jss/sitecore-jss-react';
import PurchaseOnSpotCompletedModel from './model/PurchaseOnSpotCompletedModel';
import CPPageHeader from '../../..//ClientPortal/CPPageHeader';

const PurchaseOnSpotCompleted = ({ fields }) => {
  const [dataSource, setDataSource] = useState(null);
  const contactData = useSelector((state) => state?.clientTellingPortalCustomer?.contacts[0]?.json_facet);
  
  const url = window.location.search;
  const urlParams = new URLSearchParams(url);
  const POSTransactionID = urlParams.get('POSTransactionID');
  const transactionAmount = urlParams.get('transactionAmount');

  useEffect(() => {
    setDataSource(new PurchaseOnSpotCompletedModel().getData(fields));
  }, []);

  return (
    dataSource && (
      <>
        <CPPageHeader title={{ value: `${dataSource['Title']?.value.replace('{CustomerName}', `${contactData?.Personal?.FirstName} ${contactData?.Personal?.LastName}`)}` }} />
        <div className='purchase-on-spot-completed'>
          <div className='container'>
            <div className='purchase-on-spot-completed__body'>
              <div className='purchase-on-spot-completed__icon'>
                <Image field={dataSource['Icon Purchase Completed']}/>
              </div>
              <div className='purchase-on-spot-completed__content'>
                <Text field={dataSource['Purchase Completed Label']} tag='h2' className='purchase-on-spot-completed__content__title'/>
                <Text field={dataSource['Description Purchase Completed Label']} tag='p' className='purchase-on-spot-completed__content__subtitle'/>
                <Text field={dataSource['Customer Name Label']} tag='p' className='purchase-on-spot-completed__content__lable-bold'/>
                <Text field={{ value: `${contactData?.Personal?.FirstName} ${contactData?.Personal?.LastName}` }} tag='p' className='purchase-on-spot-completed__content__lable'/>
                <Text field={dataSource['POS Details Label']} tag='p' className='purchase-on-spot-completed__content__lable-bold'/>
                <div className='purchase-on-spot-completed__content__item'>
                  <Text field={dataSource['POS Transaction ID Label']} tag='p' className='purchase-on-spot-completed__content__lable'/>
                  <Text field={{ value: POSTransactionID }} tag='p' className='purchase-on-spot-completed__content__lable'/>
                </div>              
                <div className='purchase-on-spot-completed__content__item'>
                  <Text field={dataSource['Transaction Amount Label']} tag='p' className='purchase-on-spot-completed__content__lable'/>
                  <Text field={{ value: transactionAmount }} tag='p' className='purchase-on-spot-completed__content__lable'/>
                </div>              
              </div>
              <Text field={dataSource['Message Please Process And Review Label']} tag='p' className='purchase-on-spot-completed__content__lable-bold'/>
              <div className='purchase-on-spot-completed__btn'>
                <Link field={{ href: `${dataSource['Button Review Link']?.value?.href}?POSTransactionID=${POSTransactionID}` }} className='btn btn-primary'>
                  <Text field={dataSource['Button Review Text']}/>
                </Link>
              </div>
              <div className='purchase-on-spot-completed__link'>
                <Link field={dataSource['Button Continue Session Link']} className='btn-link'>
                  <Text field={dataSource['Button Continue Session Text']}/>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

PurchaseOnSpotCompleted.prototype = {
  fields: PropTypes.any
};

export default PurchaseOnSpotCompleted;
