import './PromotionListing.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import PromotionListingModel from './model/PromotionListingModel';
import PromotionListingItem from './PromotionListingItem';

const PromotionListing = (props) => {
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [inputData, setInputData] = useState(null);
  const isSiteNameValid = SitecoreContextFactoryService.getValueContextItem('site')?.name !== 'OCN';
  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const model = new PromotionListingModel();

    setInputData(model.getData(deepCopy(props.fields) || {}));
  }, []);

  //Old Logic GA
  // useDidUpdateEffect(() => {
  //   // INF: Google analytis
  //   const params = {
  //     promotions: getItemList(inputData?.items)
  //   };

  //   new GoogleAnalytic().ggGateWay('event', 'view_promotion', params);
  // }, [inputData]);

  //New Logic ON/OFF GA, GA4
  useDidUpdateEffect(() => {
    if (isGA4) {
      isSiteNameValid &&
        setTimeout(() => {
          const paramsGA4 = {
            ecommerce: {
              items: Global.getItemsPromotion(inputData?.items)
            }
          };

          new GoogleAnalytic().gAnalytic4('event', 'view_promotion', paramsGA4);
        }, 4000);
    } else {
      const params = {
        promotions: getItemList(inputData?.items)
      };

      new GoogleAnalytic().ggGateWay('event', 'view_promotion', params);
    }
  }, [inputData]);

  const getItemList = (list) => {
    let itemList = [];

    list.forEach((item) => {
      itemList.push({
        id: item.id,
        name: item.name,
      });
    });

    return itemList;
  };

  return inputData ? (
    <div className={`promotion-listing animated-slow fadeIn delay-100 ${isClientTelingUser ? 'cp-promotion' : ''}`}>
      <div className='container smaller-container'>
        <h3 className='promotion-listing__title text-center'>
          <Text field={inputData['Promotion List Title']} />
        </h3>
        <ul className='promotion-listing__list'>
          {inputData.items?.map((item, index) => {
            return (
              <li key={item.id}>
                <PromotionListingItem dataFromLayout={inputData} data={item} creativeSlot={index}/>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  ) : (
    <div className='promotion-listing skeleton__item'>
      <div className='container smaller-container'>
        <h3 className='promotion-listing__title text-center'>
          <span className='text-line-center w-50'></span>
        </h3>
        <ul className='promotion-listing__list'>
          <LoadingShimmer itemNumb={6}>
            <li>
              <div className='promotion-listing-item'>
                <div className='row'>
                  <div className='col-md-6'>
                    <div className='promotion-listing-item__image image-wrap'>
                      <span className='image-line'></span>
                    </div>
                  </div>
                  <div className='col-md-6 promotion-listing-item__contents-wrapper'>
                    <div className='promotion-listing-item__contents'>
                      <span className='text-line-heading'></span>
                      <div className='clear-10'></div>
                      <span className='text-line'></span>
                      <div className='clear-10'></div>
                      <span className='text-line'></span>
                      <div className='clear-10'></div>
                      <span className='text-line hidden-xs-down'></span>
                      <div className='clear-10 hidden-xs-down'></div>
                      <span className='text-line-heading w-50'></span>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </LoadingShimmer>
        </ul>
      </div>
    </div>
  );
};

PromotionListing.propTypes = {
  fields: PropTypes.object
};

export default PromotionListing;
