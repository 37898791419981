import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CPLogoModel from '../Models/CPLogoModels';

const CPLogo = ({fields}) => {
  const [cPLogoFields, setCPLogoFields] = useState(null);

  useEffect(() => {
    const cPLogoModel = new CPLogoModel();

    setCPLogoFields(cPLogoModel.getData(deepCopy(fields || {})));
  }, []);

  return cPLogoFields && (
    <div className='cp-header__col content-center cp-header__logo'>
      <div className='main-logo-wrap'>
        <Link field={cPLogoFields.Link} className='main-logo' title='main logo'>
          <Image field={cPLogoFields.Image} />
        </Link>
      </div>
    </div>
  );
};

CPLogo.propTypes = {
  fields: Proptypes.object
};

export default CPLogo;