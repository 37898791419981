import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const AddressesDefault = ({ dataSources, dataSourcesLayout }) => {
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  return dataSources ? (
    <div className='my-account-section__content addresses-widget__content'>
      <div className='addresses-widget__content__info'>
        <h5 className='addresses-widget__content__info__address-name'>{dataSources.AddressName}</h5>
        <p className='addresses-widget__content__info__full-name addresses-widget__content__info__line'>
          {`${settingGlobal?.FullNameFormat.replace('{LastName}', dataSources.LastName).replace('{FirstName}', dataSources.FirstName)}`}
        </p>
        <span className='addresses-widget__content__info__line' dangerouslySetInnerHTML={{ __html: dataSources.FullAddress }}></span>
        <span className='addresses-widget__content__info__line'>
          <Text field={dataSourcesLayout['Phone Number Label']} />: {dataSources?.PhoneNumber || ''}
        </span>
      </div>
    </div>
  ) : (
    ''
  );
};

AddressesDefault.propTypes = {
  dataSources: PropTypes.object,
  dataSourcesLayout: PropTypes.object
};

export default AddressesDefault;
