import React, { useEffect, useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { useNavigateNextStep } from '../../hooks/useNavigateNextStep';
import { useScheduleAppointmentContext } from '../../hooks/useScheduleAppointmentContext';
import { SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE } from '../configs';
import ContactDetailSubtitle from './ContactDetailSubtitle';
import ErrorHandler from './ErrorHandlerComp';
import ContactDetailFormLayout from './FormLayout';
import InputForm from './InputForm';
import OTPInputHandler from './OTPInputHandler';
import PolicyAndTermCheckbox from './PolicyAndTermCheckbox';

const ContactDetailsForm = () => {
  const { layoutData, scheduleData } = useScheduleAppointmentContext();
  const navigateNextStep = useNavigateNextStep();

  const checkPersonalizeData = useMemo(() => {
    const personalizeData = scheduleData.personalizeExperience;
    const teasData = [...layoutData?.Teas];
    const scentsData = [...layoutData?.Scents];
    const questionsData = [...layoutData?.questions];

    return Boolean(
      (teasData.length && !personalizeData.teaId) ||
        (scentsData.length && !personalizeData.scentId) ||
        (questionsData.length && !personalizeData.additionalSelectionAns.length)
    );
  }, [layoutData, scheduleData.personalizeExperience]);

  useEffect(() => {
    if (!layoutData?.appointment && checkPersonalizeData) {
      navigateNextStep(SCHEDULE_APPOINTMENT_STEP_PERSONALIZE_EXPERIENCE);
    }
  }, [scheduleData.personalizeExperience, layoutData, checkPersonalizeData]);

  return (
    <div className='be-schedule-appointment__contact-detail'>
      <Text className='be-schedule-appointment__title' field={layoutData['Contact Details Title']} tag='h2' />
      <ContactDetailSubtitle />
      <ContactDetailFormLayout>
        <InputForm />
        <PolicyAndTermCheckbox />
      </ContactDetailFormLayout>
      <OTPInputHandler />
      <ErrorHandler />
    </div>
  );
};

export default ContactDetailsForm;
