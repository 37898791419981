import { useMemo } from 'react';

const useNotiIconTitle = (notificationLogStatus, layoutData) => {
  return useMemo(() => {
    let titleIcon = {
      title: '',
      icon: null
    };
    switch (notificationLogStatus) {
    case 1:
      titleIcon.title = layoutData?.['Changed Status']?.value;

      titleIcon.icon = layoutData?.['Add Icon'];
      break;

    case 2:
      titleIcon.title = layoutData?.['Cancelled Status']?.value;

      titleIcon.icon = layoutData?.['Minus Icon'];
      break;
    default:
      titleIcon.title = layoutData?.['Booked Status']?.value;

      titleIcon.icon = layoutData?.['Add Icon'];
    }

    return titleIcon;
  }, [layoutData, notificationLogStatus]);
};

export default useNotiIconTitle;
