import PropTypes from 'prop-types';
import React from 'react';

import {Text} from '@sitecore-jss/sitecore-jss-react';

const InfoAddress = (props) => {
  const { dataAddresses, dataSources } = props;

  return dataSources ? (
    <div className='my-addresses__section__content__info'>
      <p className='addresses-info__line' dangerouslySetInnerHTML={{ __html: dataAddresses.FullAddress }}></p>
      <p className='addresses-info__line'>
        <Text field={dataSources['Phone Number Label']}/>: {dataAddresses?.PhoneNumber || ''}
      </p>
    </div>
  ) : '';
};

InfoAddress.propTypes = {
  dataAddresses: PropTypes.object,
  dataSources: PropTypes.object
};

export default InfoAddress;
