import './CPInput.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { TYPE_CHECKBOX, TYPE_RADIO, TYPE_TEXT } from '../FormConstant';

const CPInput = ({
  inputType = TYPE_TEXT,
  inputId,
  inputName,
  inputClass,
  inputPlacehoder,
  isChecked,
  isDisabled,
  isRectangle,
  labelData,
  inputValue,
  layoutServiceData,
  inputChange,
  questionCode,
  questionType,
  questionValue,
  productId,
  isAddNew,
  displayName = '',
  answerId = ''
}) => {
  return (
    <div
      className={`cp-input ${
        inputType === TYPE_RADIO 
          ? 'type-select type-select__checkbox' 
          : inputType === TYPE_CHECKBOX 
            ? 'type-select' 
            : ''
      } ${isRectangle ? '--rectangle' : ''}`}
    >
      <input
        disabled={isDisabled}
        checked={isChecked}
        type={inputType}
        id={inputId}
        name={inputName}
        className={inputClass}
        placeholder={inputPlacehoder}
        value={inputValue}
        onChange={(event) =>
          inputChange({
            value: labelData,
            questionCode: questionCode,
            questionType: `CPCustomerInfo-${questionType}`,
            productId: productId,
            questionValue: questionValue,
            isAddNew: isAddNew,
            displayName: displayName,
            answerId: answerId,
            event: event
          })
        }
      />
      {labelData ? (
        <label htmlFor={inputId} className='cp-input__label'>
          {layoutServiceData?.value?.length ? <Text field={layoutServiceData} /> : <span>{labelData}</span>}
        </label>
      ) : (
        ''
      )}
    </div>
  );
};

CPInput.propTypes = {
  inputType: PropTypes.string,
  inputId: PropTypes.string,
  inputName: PropTypes.string,
  inputClass: PropTypes.string,
  inputPlacehoder: PropTypes.string,
  isDisabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  isRectangle: PropTypes.bool,
  labelData: PropTypes.string,
  inputValue: PropTypes.string,
  layoutServiceData: PropTypes.object,
  inputChange: PropTypes.func,
  questionCode: PropTypes.string,
  questionType: PropTypes.string,
  questionValue: PropTypes.string,
  productId: PropTypes.string,
  isAddNew: PropTypes.bool,
  displayName: PropTypes.string,
  answerId: PropTypes.string
};

export default CPInput;
