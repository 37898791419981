import PropTypes from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import AddressForMultiLanguage from '@components/SlicingPage/MyAccount/MyAddress/AddressForMultiLanguage';
import { addNewAddress } from '@redux/actions/checkoutProcess';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

const EditAddressBookPopup = (props) => {
  const { dataSources, addressCurrent, setCloseEditAddressEvt } = props;
  const dispatch = useDispatch();
  const addressListReducer = useSelector(state => state.checkoutProcesstReducer.addressListReducer);
  
  const onSubmmitAddAddressHandler = (params) => {    
    dispatch(addNewAddress({
      ...params, 
      FullAddress: null,
      ExternalId: addressCurrent?.ExternalId,
      CountryCode: dataSources['Address Settings']['Country Settings']['Country Code'].value || 'SA',
      Country: dataSources['Address Settings']['Country Settings']['Country Code'].value || 'SA',
    }));
  };

  useDidUpdateEffect(() => {
    if (!addressListReducer.error) {
      setCloseEditAddressEvt();
    }
  }, [addressListReducer.toogleCheck]);
  
  return dataSources?.['Address Settings']?.['Address Elements'] ? (
    <div className='address-component'>
      <AddressForMultiLanguage
        dataFields={dataSources}
        setSubmitAddressEvt={onSubmmitAddAddressHandler}
        addressCurrent={addressCurrent}
      />
    </div> 
  ) : '';
};

EditAddressBookPopup.propTypes = {
  dataSources: PropTypes.object,
  addressCurrent: PropTypes.object,
  setCloseEditAddressEvt: PropTypes.func
};

export default EditAddressBookPopup;
