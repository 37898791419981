import './Breadcrumb.scss';

import { object } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import { deepCopy } from '@utils/utility';

import BreadCrumbModel from './model/BreadCrumbModel';

const Breadcrumb = ({ fields, params }) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const breadCrumbModel = new BreadCrumbModel();

    setInputData(breadCrumbModel.getData(deepCopy(fields || {})));
  }, []);

  return (
    inputData && (
      <Fragment>
        {fields?.Ancestors?.length ? (
          <div className='os-breadcrumb'>
            <div className='container'>
              <div
                className={`${
                  params['Is Show Icon'] && params['Is Show Icon'] === '1' ? 'os-breadcrumb__wrap os-breadcrumb--has-icon' : 'os-breadcrumb__wrap'
                }`}
              >
                {inputData.Ancestors.map((item, index) => {
                  if (index === 0) {
                    return (
                      <Fragment key={index}>
                        {item.Title.length ? (
                          <span className='os-breadcrumb__item' key={index}>
                            <a className='os-breadcrumb__item__link' href={item.PageUrl}>
                              {params['Is Show Icon'] && params['Is Show Icon'] === '1' ? (
                                <span className='os-breadcrumb__item__icon'></span>
                              ) : (
                                <Fragment>{item.Title}</Fragment>
                              )}
                            </a>
                          </span>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    );
                  } else {
                    return (
                      <Fragment key={index}>
                        {item.Title.length ? (
                          <span className='os-breadcrumb__item' key={index}>
                            <a className='os-breadcrumb__item__link' href={item.PageUrl}>
                              {item.Title}{' '}
                            </a>
                          </span>
                        ) : (
                          <></>
                        )}
                      </Fragment>
                    );
                  }
                })}
                {inputData.Ancestors.length && inputData['CurrentPageTitle'].length ? (
                  <span className='os-breadcrumb__item'>
                    <span className='os-breadcrumb__item__link'>{inputData['CurrentPageTitle']}</span>
                  </span>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        ) : (
          <></>
        )}
      </Fragment>
    )
  );
};

Breadcrumb.propTypes = {
  fields: object,
  params: object
};

export default Breadcrumb;
