import produce from 'immer';

import {
  UPDATE_BOOKING_EXPERIENCE_FAILED,
  UPDATE_BOOKING_EXPERIENCE_START,
  UPDATE_BOOKING_EXPERIENCE_SUCCESS
} from '@redux/actions/bookingExperience/updateBookingExperience/types';

const initialStates = {
  isLoading: false,
  error: null,
  updateBookingExperienceResponse: null
};

const updateBookingExperienceReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case UPDATE_BOOKING_EXPERIENCE_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case UPDATE_BOOKING_EXPERIENCE_SUCCESS:
      draft.isLoading = false;

      draft.updateBookingExperienceResponse = action.payload;
      break;

    case UPDATE_BOOKING_EXPERIENCE_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default updateBookingExperienceReducer;
