import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SharingFieldsModel {
  constructor() {
    this['Data Network'] = new SitecoreTextModel();
   
    this['Title'] = new SitecoreTextModel();

  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Data Network')) {
      this['Data Network'].getData(dataSource['Data Network']);
    }

    return this;
  }
}
