import '../WellnessArticle/WellnessArticle.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import WellnessArticleModel from '../WellnessArticle/model/WellnessArticleModel';
import WellnessArticleItem from '../WellnessArticle/WellnessArticleItem';


const LatestArticle = (props) => {
  const { fields } = props;

  const [dataLink, setDataLink] = useState(null);
  
  const seeAllButtonText = SitecoreContextFactoryService.getValueContextRouteItem('fields')['See All Button Text'] || null;

  const [dataFields, setDataFields] = useState(null);

  useEffect(() => {
    const dataLinkModel = new SitecoreLinkModel();

    const wellnessArticlesModel = new WellnessArticleModel();

    setDataLink(dataLinkModel.getData(deepCopy(SitecoreContextFactoryService.getValueContextRouteItem('fields')['See All Button Link']) || {}));

    setDataFields(wellnessArticlesModel.getData(deepCopy(fields) || {}));
  }, []);

  return dataFields && dataLink ? (
    <div className='featured-article animated-slow fadeIn delay-100'>
      <div className='container'>
        <h2 className='featured-article__title experience-title text-center'>{dataFields['Title'].value}</h2>
        <div className='row'>
          {
            dataFields.Articles.map((item , itemIdx) => {
              return (
                <div className='col-md-4 featured-article__item' key={itemIdx}>
                  <WellnessArticleItem dataFields={item}/>
                </div>
              );
            })
          }
        </div>

        <div className='featured-article__button text-center'>
          <Link 
            field={dataLink}
            className='btn btn-outline-CTA2'>
            {seeAllButtonText 
              ? <Text field={seeAllButtonText}></Text>
              : <span></span>
            }
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <div className='featured-article skeleton__item'>
      <div className='container'>
        <h2 className='featured-article__title experience-title text-center'>
          <span className='text-line-center w-50'></span>
        </h2>
        <div className='row'>
          <LoadingShimmer itemNumb={6}>
            <div className='col-md-4 featured-article__item'>
              <div className='featured-article-item'>
                <div className='featured-article-item__wrap'>
                  <div className='featured-article-item__image image-wrap'>
                    <span className='image-line'></span>
                  </div>
                </div>
                <ul className='featured-article-item__tags'>
                  <span className='featured-article-item__tags__item w-100'>
                    <span className='transparent-wrap'>Text</span>
                  </span>
                </ul>

                <h3 className='featured-article-item__title'>
                  <span className='text-line'></span>
                </h3>

                <div className='featured-article-item__sub-info'>
                  <span className='text-line-small'></span>
                </div>
              </div>
            </div>
          </LoadingShimmer>
        </div>

        <div className='featured-article__button text-center'>
          <span className='text-line-heading-center w-50'></span>
        </div>
      </div>
    </div>
  );
};

LatestArticle.propTypes = {
  fields: PropTypes.any
};

export default LatestArticle;