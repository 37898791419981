import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = ['Description', 'Link Text', 'Title', 'Button Text'];
const LINK_ARR = ['Link Url', 'Button Link Url'];
const IMAGE_ARR = ['Image'];
const PLAIN_CUSTOM_ARRAY = ['ProductAccessories'];

export default class ProductAccessoriesModel {
  constructor() {
    TEXT_ARR.forEach((item) => (this[item] = new SitecoreTextModel()));

    LINK_ARR.forEach((item) => (this[item] = new SitecoreTextModel()));

    IMAGE_ARR.forEach((item) => (this[item] = new SitecoreImageModel()));

    PLAIN_CUSTOM_ARRAY.forEach((item) => (this[item] = []));
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    PLAIN_CUSTOM_ARRAY.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = deepCopy(dataSource[item]);
      }
    });

    return this;
  }
}
