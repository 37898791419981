import './PromotionListingItem.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { useSelector } from 'react-redux';

import CountdownTimerCampaign from '@components/Share/CountdownTimer/CountdownTimerCampaign';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const PromotionListingItem = ({ dataFromLayout, data, creativeSlot }) => {
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [description, setDescription] = useState(null);
  const countdownFormatSettingObj = useSelector((state) => state.settingGlobalURLReducer.settingGlobalURL);
  const isSiteNameValid = SitecoreContextFactoryService.getValueContextItem('site')?.name !== 'OCN';
  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    let tempDesc = data.fields.description;

    setDescription(html_substr(tempDesc, dataFromLayout['Maximum Characters To Display Description'].value));
  }, []);

  const html_substr = (str, count) => {
    let div = document.createElement('div');

    div.innerHTML = str;

    const track = (el) => {
      if (count > 0) {
        let len = el.data.length;

        count -= len;
        if (count <= 0) {
          el.data = el.substringData(0, el.data.length + count);
        }
      } else {
        el.data = '';
      }
    };

    const walk = (el, fn) => {
      let node = el.firstChild,
        oldNode;
      do {
        if (node?.nodeType === 3) {
          fn(node);
        } else if (node?.nodeType === 1) {
          walk(node, fn);
        }
      } while ((node = node?.nextSibling) && count > 0);
      //remove remaining nodes
      while (node) {
        oldNode = node;

        node = node?.nextSibling;

        el.removeChild(oldNode);
      }
    };

    walk(div, track);

    return div.innerHTML;
  };

  //New Logic ON/OFF GA, GA4
  const handlePromotionSelected = () => {
    if(isGA4 && isSiteNameValid){
      let dataConvert = [];

      dataConvert.push(data);

      const params = {
        ecommerce:{
          items: Global.getItemsPromotion(dataConvert, creativeSlot)
        }
      };
  
      new GoogleAnalytic().gAnalytic4('event', 'select_promotion', params);
    }
  };

  return (
    <Fragment>
      {countdownFormatSettingObj ? (
        <div className='promotion-listing-item'>
          <div className='row'>
            <div className='col-md-6'>
              <a href={data.fields.url} className='promotion-listing-item__image'>
                <img src={data.fields.thumbnail} alt={data.fields.alternativeStartText ? data.fields.alternativeStartText : 'promotion image'} />
              </a>
            </div>
            <div className='col-md-6 promotion-listing-item__contents-wrapper'>
              <div className='promotion-listing-item__contents'>
                <h2 className='promotion-listing-item__contents__title'>
                  <a href={data.fields.url} onClick={handlePromotionSelected}>{data.fields.title}</a>
                </h2>
                {
                  description ? (
                    <p className='promotion-listing-item__contents__desription' dangerouslySetInnerHTML={{ __html: description || '' }} />
                  ) : ''
                }
                <div className='promotion-listing-item__contents__link'>
                  <a onClick={handlePromotionSelected} href={data.fields.url} className={isClientTelingUser ? 'btn btn-outline-primary' : 'btn btn-outline-CTA2'}>
                    <span>
                      <Text field={dataFromLayout['Button Text']} />
                    </span>
                  </a>
                </div>
                <p className='promotion-listing-item__contents__date'>
                  {Global.onFormatPromotionValidMessageHandler(
                    data.fields?.validMessage,
                    data.fields?.startDate,
                    data.fields?.endDate,
                    countdownFormatSettingObj?.['CountdownFormatSetting']?.DateFormat
                  )}
                </p>
                <p className='promotion-listing-item__contents__countdown'>
                  <CountdownTimerCampaign
                    endDate={data.fields?.endDate}
                    endText={data.fields?.endText}
                    remainingDate={data.fields?.remainingDate}
                    startDate={data.fields?.startDate}
                    startText={data.fields?.startText}
                    validText={data.fields?.validMessage}
                    wasEndText={data.fields?.wasEndText}
                    alternativeStartText={data.fields?.alternativeStartText}
                    countdownFormatSetting={countdownFormatSettingObj?.['CountdownFormatSetting']}
                    componentType='CampaignBanner'
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}
    </Fragment>
  );
};

PromotionListingItem.propTypes = {
  dataFromLayout: PropTypes.object,
  data: PropTypes.object,
  creativeSlot: PropTypes.number
};

export default PromotionListingItem;
