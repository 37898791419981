import Proptypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { getProductInformation } from '@redux/actions/product';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const ProductContainer = (props) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProductInformation());
  }, []);

  return (
    <div className='product-container'>
      <Placeholder name='osim-product-detail' rendering={props.rendering} />
    </div>
  );
};

ProductContainer.propTypes = {
  rendering: Proptypes.any
};

export default ProductContainer;
