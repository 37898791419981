import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError,map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/common';
import * as commonTypes from '@redux/actions/common/commonTypes';
import { postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as configs from '../config';

export const getAutoCompleteAllEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.GET_AUTO_COMPLETE_ALL),
    switchMap((action) => {
  
      const params = {
        searchTerm: action.searchTerm,
        resultNumber: configs.COMMON_RESULT_NUMB,
        itemType: configs.ITEM_TYPE_ALL
      };

      return postAsObservable(configs.ACTION_AUTO_COMPLETE, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getAutoCompleteAllSuccess(deepCopy(res.data) || {});
          } else {
            return actions.getAutoCompleteAllFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getAutoCompleteAllFailed(error)))
      );
    })
  );

export const getAutoCompleteWellnessEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.GET_AUTO_COMPLETE_WELLNESS),
    switchMap((action) => {

      const params = {
        searchTerm: action.searchTerm.value,
        resultNumber: configs.COMMON_RESULT_NUMB,
        itemType : configs.ITEM_TYPE_WELLNESS,
        dataSourcePath: action.searchTerm.dataSourcePath
      };

      return postAsObservable(configs.ACTION_AUTO_COMPLETE, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getAutoCompleteWellnessSuccess(deepCopy(res.data) || {});
          } else {
            return actions.getAutoCompleteWellnessFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getAutoCompleteWellnessFailed(error)))
      );
    })
  );

export const getAutoCompleteProductEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.GET_AUTO_COMPLETE_PRODUCT),
    switchMap((action) => {

      const params = {
        searchTerm: action.searchTerm.value,
        resultNumber: configs.COMMON_RESULT_NUMB,
        itemType : configs.ITEM_TYPE_PRODUCT,
        dataSourcePath: action.searchTerm.dataSourcePath
      };

      return postAsObservable(configs.ACTION_AUTO_COMPLETE, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getAutoCompleteProductSuccess(deepCopy(res.data) || {});
          } else {
            return actions.getAutoCompleteProductFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getAutoCompleteProductFailed(error)))
      );
    })
  );

export const getAutoCompletePolicyEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.GET_AUTO_COMPLETE_POLICY),
    switchMap((action) => {

      const params = {
        searchTerm: action.searchTerm,
        resultNumber: configs.COMMON_RESULT_NUMB,
        itemType : configs.ITEM_TYPE_POLICY
      };

      return postAsObservable(configs.ACTION_AUTO_COMPLETE, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getAutoCompletePolicySuccess(deepCopy(res.data) || {});
          } else {
            return actions.getAutoCompletePolicyFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getAutoCompletePolicyFailed(error)))
      );
    })
  );

export const getAutoCompleteResourceEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.GET_AUTO_COMPLETE_RESOURCE),
    switchMap((action) => {

      const params = {
        searchTerm: action.searchTerm,
        resultNumber: configs.COMMON_RESULT_NUMB,
        itemType : configs.ITEM_TYPE_RESOURCE
      };

      return postAsObservable(configs.ACTION_AUTO_COMPLETE, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getAutoCompleteResourceSuccess(deepCopy(res.data) || {});
          } else {
            return actions.getAutoCompleteResourceFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getAutoCompleteResourceFailed(error)))
      );
    })
  );
