export const UN_SUBSCRIBE_NEWS_LETTER = 'UN_SUBSCRIBE_NEWS_LETTER';

export const UN_SUBSCRIBE_NEWS_LETTER_SUCCESS = 'UN_SUBSCRIBE_NEWS_LETTER_SUCCESS';

export const UN_SUBSCRIBE_NEWS_LETTER_FAILED = 'UN_SUBSCRIBE_NEWS_LETTER_FAILED';

// Captcha value
export const GET_CAPTCHA_VALUE = 'GET_CAPTCHA_VALUE';

// Get Dropdown Value
export const GET_SELECTED_SELECTION_VALUE = 'GET_SELECTED_SELECTION_VALUE';

// Reset Captcha
export const RESET_CAPTCHA = 'RESET_CAPTCHA';

// Response Message
export const GET_RESPONSE_MESSAGE = 'GET_RESPONSE_MESSAGE';

export const GET_PHONE_NUMBER = 'GET_PHONE_NUMBER';

export const SET_SUBMIT_BUTTON_STATUS = 'SET_SUBMIT_BUTTON_STATUS';

export const SET_LOGIN_LINK = 'SET_LOGIN_LINK';

export const GET_PHONENUMBER_ID = 'GET_PHONENUMBER_ID';

export const GET_SUBMIT_ACTION = 'GET_SUBMIT_ACTION';
