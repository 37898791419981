import PriceModel from '@components/BundleElements/model/PriceModel';
import { deepCopy } from '@utils/utility';

export default class RefunableDepositProductModel {
  constructor() {
    this.CurrencyCode = '';

    this.Description = '';

    this.DisplayName = '';

    this.ItemId = '';

    this.ListPrice = 0;

    this.Price = new PriceModel();

    this.ProductId = '';

    this.ProductOverview = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyCode')) {
      this.CurrencyCode = dataSource.CurrencyCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayName')) {
      this.DisplayName = dataSource.DisplayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemId')) {
      this.ItemId = dataSource.ItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this.ListPrice = dataSource.ListPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this.ProductId = dataSource.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductOverview')) {
      this.ProductOverview = dataSource.ProductOverview;
    }

    return this;
  }
}
