import './GuestCheckout.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import FacebookSSO from '@components/SingleSignOn/FacebookSSO';
import GoogleSSO from '@components/SingleSignOn/GoogleSSO';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';

import { VIEW_OFFER_DETAIL_CART_ID_KEY } from '../../../PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';
import FormGuestCheckout from './FormGuestCheckout';

const GuestCheckout = (props) => {
  const { inputData, checkCustomerExist, isToogleCheckGuest, isLoading } = props;
  const query = useQuery();

  useDidUpdateEffect(() => {
    if (checkCustomerExist) {
      props.alreadyEmailEvt(true);
    } else {
      const cartId = query.get(VIEW_OFFER_DETAIL_CART_ID_KEY);
      if (cartId) {
        window.location.href = `${inputData?.['Continue to Offer Delivery Button Link']?.value?.href}?${query.toString()}`;
      } else {
        window.location.href = inputData['Login Button Link'].value.href;
      }
    }
  }, [isToogleCheckGuest]);

  return (
    <div className='guest-checkout account-checking__tab__content'>
      <div className='account-checking__tab__content__body'>
        <FacebookSSO isLogin={false} dataFields={inputData['Facebook Login Settings']}/>
        <GoogleSSO isLogin={false} dataFields={inputData['Google Login Settings']}/>
        <FormGuestCheckout inputData={inputData} />
      </div>
      {isLoading && !checkCustomerExist  ? <LoadingMask/> : ''}
    </div>
  );
};

GuestCheckout.propTypes = {
  inputData: PropTypes.object,
  checkCustomerExist: PropTypes.any,
  alreadyEmailEvt: PropTypes.func,
  isToogleCheckGuest: PropTypes.bool,
  isLoading: PropTypes.bool
};

const mapStateToProp = state => ({
  checkCustomerExist: state.checkoutProcesstReducer.accountCheckingReducer?.checkCustomerExist,
  isLoading: state.checkoutProcesstReducer.accountCheckingReducer.isLoading,
  isToogleCheckGuest: state.checkoutProcesstReducer.accountCheckingReducer?.isToogleCheckGuest
});

export default connect(mapStateToProp)(GuestCheckout);
