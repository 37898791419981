const strArr = ['ImageURLs', 'LearnMoreText', 'ProductId', 'ProductName', 'ProductPageUrl', 'SitecoreId'];

export default class SingleFeaturedProductModel {
  constructor() {
    strArr.forEach((item) => {
      this[item] = '';
    });
  }
  getData(dataSource) {
    strArr.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}
