import { deepCopy } from '@utils/utility';

import BreadCrumbItemModel from './BreadCrumbItemModel';

export default class BreadCrumbModel {
  constructor() {
    this['CurrentPageTitle'] = '';

    this['Ancestors'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrentPageTitle')) {
      this['CurrentPageTitle'] = dataSource['CurrentPageTitle'] ?? this['CurrentPageTitle'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Ancestors')) {
      let newAncestorsList = [];

      dataSource['Ancestors'].map((element) => {
        newAncestorsList.push(new BreadCrumbItemModel().getData(deepCopy(element) || {}));
      });

      this['Ancestors'] = newAncestorsList;
    }

    return this;
  }
}
