import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

const FormFieldAllError = (props) => {
  return (
    <Fragment>
      {props.formErrors.map((item, itemIdx) => (
        <div className='error-invalid' key={`formError-${itemIdx}`}>
          {item}
        </div>
      ))}
    </Fragment>
  );
};

FormFieldAllError.propTypes = {
  formErrors: PropTypes.any
};

export default FormFieldAllError;
