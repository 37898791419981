import { element, object } from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { ACTIVE_STATUS_KEY } from '@redux/actions/cpSaleOrderStatuses/configs';
import { getClientSOCartStart } from '@redux/actions/personalizeOffer/getClientSOCart';
import { getClientSOCartSelector } from '@redux/reducers/personalizeOffer/getClientSOCartReducer/getClientSOCartSelector';
import { useQuery } from '@utils/customsHook/useQuery';

import useCheckDisablePurchaseButton from '../hooks/useCheckDisablePurchaseButton';
import { VIEW_OFFER_DETAIL_CART_ID_KEY } from '../hooks/useGenerateViewOfferDetailLink';
import { useUpdateSOCartViewedStatus } from './hooks';

const initialStates = {
  offerDetailFields: null,
  soCartData: null,
  osimCartData: null,
  isGettingClientSOCart: false,
  isClientSOCart: false,
  isDisablePurchaseButton: false
};
const OfferDetailsContext = createContext(initialStates);

export const useOfferDetails = () => useContext(OfferDetailsContext);

const OfferDetailsProvider = ({ children, layoutData }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const { isLoading: isGettingClientSOCart, data: clientSOCartData } = useSelector(getClientSOCartSelector);
  const checkDisablePurchaseButton = useCheckDisablePurchaseButton();
  const updateSOCartViewedStatusService = useUpdateSOCartViewedStatus();
  const cartId = useMemo(() => query.get(VIEW_OFFER_DETAIL_CART_ID_KEY), [query]);

  useEffect(() => {
    if (cartId) dispatch(getClientSOCartStart({ cartId: cartId }));
  }, [cartId]);

  useEffect(() => {
    const isActiveSOCart = clientSOCartData?.SOCart?.Cart?.Status === ACTIVE_STATUS_KEY;
    if (cartId && isActiveSOCart) {
      (async () => {
        try {
          await updateSOCartViewedStatusService(cartId);
        } catch (error) {
          console.log(error);
        }
      })();
    }
  }, [cartId, clientSOCartData]);

  const osimCartData = useMemo(() => {
    return clientSOCartData?.OsimCart ?? null;
  }, [clientSOCartData]);

  const soCartData = useMemo(() => {
    return clientSOCartData?.SOCart?.Cart ?? null;
  }, [clientSOCartData]);

  const isDisablePurchaseButton = useMemo(() => {
    return checkDisablePurchaseButton(soCartData?.Status);
  }, [soCartData]);

  return (
    <>
      <OfferDetailsContext.Provider
        value={{
          isGettingClientSOCart: isGettingClientSOCart,
          osimCartData: osimCartData,
          soCartData: soCartData,
          offerDetailFields: layoutData,
          isClientSOCart: Boolean(clientSOCartData),
          isDisablePurchaseButton: isDisablePurchaseButton
        }}
      >
        {children}
      </OfferDetailsContext.Provider>
      {isGettingClientSOCart ? <LoadingMask /> : <></>}
    </>
  );
};

OfferDetailsProvider.propTypes = {
  layoutData: object,
  children: element
};

export default OfferDetailsProvider;
