import './RegistrationErrorPopup.scss';

import { any, bool, exact, func, object,oneOfType, string } from 'prop-types';
import React from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const RegistrationErrorPopup = ({ isOpen, onClose, popupTitle, errorMessage, primaryButton, secondaryButton }) => {
  return (
    <ModalComponent isShow={isOpen} onCloseHandler={onClose} titlePopup={popupTitle} cssClass='registration-error-popup'>
      <div className='popup-email-already registration-error-popup__body'>
        <p className='popup-email-already__description text-center'>{errorMessage}</p>
        <div className='popup-email-already__action'>
          <button
            className='popup-email-already__action__btn popup-email-already__action__btn--login btn btn-outline-CTA1'
            onClick={primaryButton.btnOnClick}
          >
            <Text field={primaryButton.btnText} />
          </button>
          <button
            className='btn btn-link popup-email-already__action__btn popup-email-already__action__btn--link'
            onClick={secondaryButton.btnOnClick}
          >
            <Text field={secondaryButton.btnText} />
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

RegistrationErrorPopup.propTypes = {
  isOpen: bool,
  onClose: func,
  popupTitle: oneOfType([any, string]),
  errorMessage: string,
  primaryButton: exact({
    btnText: object,
    btnOnClick: func
  }),
  secondaryButton: exact({
    btnText: object,
    btnOnClick: func
  })
};

export default RegistrationErrorPopup;
