import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

const CustomRichTextFormField = (props) => {
  const htmlText = useMemo(() => {
    return props?.field?.model?.html;
  }, [props]);

  return htmlText ? <div dangerouslySetInnerHTML={{ __html: htmlText }}></div> : <></>;
};

CustomRichTextFormField.propTypes = {
  field: PropTypes.object
};

export default CustomRichTextFormField;
