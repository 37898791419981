import './CPLogin.scss';
import '../GenericComponents/CPHeader/CPHeader.scss';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { cpLogin } from '@redux/actions/singleSignOn';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import CPLoginModel from './Models/CPLoginModel';

const CPLogin = ({fields, isCPLogin, isCPLoginSuccess, cPError, cPUserData}) => {
  const dispatch = useDispatch();
  const uniqueID = uuidv4();
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [cPLoginFields, setCPLoginFields] = useState(null);
  const [isShow, setIsShow] = useState(false);
  const [browserId, setBrowserId] = useState('');
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const formik = useFormik({
    initialValues: {
      user: '',
      password: '',
    },
    validationSchema: Yup.object({
      user: Yup.string()
        .required(<Message messageCode='Msg_16'/>).trim(),
      password: Yup.string()
        .required(<Message messageCode='Msg_001.5' />).trim()
        .matches  (Global.formatPassword(MINUMUM_CHARACTERS_PASSWORD), messageObj?.['Msg_001.5']),
    }),
    onSubmit: (values) => {
      dispatch(cpLogin({
        UserName: values.user.trim(),
        PassWord: values.password,
        DeviceId: browserId || ''
      })
      );
    }
  });

  useEffect(() => {
    const getLocalBrowserId = window.localStorage.getItem('LocalBrowserId') || null;
    if (getLocalBrowserId) {
      setBrowserId(getLocalBrowserId);
    } else {
      window.localStorage.setItem('LocalBrowserId', uniqueID);

      setBrowserId(uniqueID);
    }
  }, []);

  useEffect(() => {
    if (isClientTelingUser && cPLoginFields) {
      window.location.href = cPLoginFields?.['Login Success Link'].value.href;
    }
  }, [cPLoginFields]);

  useEffect(() => {
    const cPLoginModel = new CPLoginModel();

    setCPLoginFields(cPLoginModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    if (cPError && !isCPLoginSuccess) {
      setIsShow(true);
    }
  }, [cPError, isCPLoginSuccess]);

  useDidUpdateEffect(() => {
    const urlPageSesion =JSON.parse(window.sessionStorage.getItem('urlPage'));

    if (isCPLoginSuccess) {
      const getCPUserData = window.localStorage.getItem('cPUserData') || null;
      if (getCPUserData) {
        window.localStorage.removeItem('cPUserData');
      }

      window.localStorage.setItem('cPUserData', JSON.stringify({...cPUserData}));

      if (cPUserData?.NeedValidateLogin) {
        if (cPLoginFields?.['Verify Login Link'].value.href) {
          window.location.href = cPLoginFields?.['Verify Login Link'].value.href;
        }
      } else {
        if (urlPageSesion?.prevUrlPage) {
          window.location.href = urlPageSesion.prevUrlPage;
        } else {
          if (cPLoginFields?.['Login Success Link'].value.href) {
            window.location.href = cPLoginFields?.['Login Success Link'].value.href;
          }
        }
      }
    }
  }, [isCPLoginSuccess, cPLoginFields, cPUserData]);

  return cPLoginFields && (
    <>
      <header className='cp-login-header cp-header'>
        <div className='cp-header__wrapper'>
          <div className='cp-header__container'>
            <span className='cp-login-header__logo'>
              <Image field={cPLoginFields.Logo} />
            </span>
          </div>
        </div>
      </header>
      <div className='cp-login-content'>
        <div className='cp-login-content__background'>
          <Image field={cPLoginFields['Background Image']} />
        </div>
        <div className='cp-login-content__form-wrap'>
          <form onSubmit={formik.handleSubmit} className='cp-login-content__form'>
            <Text className='cp-login-content__form__title' tag='h2' field={cPLoginFields['Title']} />
            {/* Sign In */}
            { isShow
              ? <span className='cp-login-content__false error-validate'>
                {/* <Message messageCode='Msg_003'/> */}
                {cPError}
              </span> 
              : ''}
            <div className='cp-login-content__form__main'>
              <div className={`form-group ${formik.errors.user && formik.touched.user ? 'input-error-validate' : ''}`}>
                <input type='text' 
                  id='user' 
                  onChange={(e) => {
                    setIsShow(false);

                    formik.handleChange(e);
                  }
                  }
                  value={formik.values.user}
                  className='form-control form-control-lg' 
                  name='user' 
                  placeholder={cPLoginFields['User Text Placeholder'].value}/>
                {formik.errors.user && formik.touched.user && (
                  <span className='error-validate'>
                    {formik.errors.user}
                  </span>
                )}
                <label htmlFor='user' className='form-group__label'>
                  <Text field={cPLoginFields['User Text Placeholder']} />
                </label>
              </div>
              <div className={`form-group ${formik.errors.password && formik.touched.password ? 'input-error-validate' : ''}`}>
                <input type='password' 
                  className='form-control form-control-lg' 
                  id='password' 
                  onChange={(e) => {
                    setIsShow(false);

                    formik.handleChange(e);
                  }
                  }
                  value={formik.values.password}
                  placeholder={cPLoginFields['Password Text Placeholder'].value}/>
                <label htmlFor='password' className='form-group__label'>
                  <Text field={cPLoginFields['Password Text Placeholder']} />
                </label>
                {formik.errors.password && formik.touched.password && (
                  <span className='error-validate'>
                    {formik.errors.password}
                  </span>
                )}
              </div>
              <div className='form-group cp-login-content__form__btn'>
                <button type='submit' className='cp-login-content__form__btn__item btn btn-primary'>
                  <Text field={cPLoginFields['Button Text']} />
                </button>
              </div>
            </div>

            {/* Sign In OTP */}
            {/* <p className='cp-login-content__form__desc'>Please input the OTP code sent to your sales manager</p>
            <div className='cp-login-content__form__main'>
              <div className='form-group'>
                <input type='text' id='otp' className='form-control form-control-lg' name='otp' placeholder='OTP'/>
                <label for='otp' className='form-group__label'>OTP</label>
              </div>
              <div className='form-group cp-login-content__form__btn'>
                <button className='cp-login-content__form__btn__item btn btn-primary'>Send OTP</button>
              </div>
            </div> */}

            {/* Resend OTP */}
            {/* <p className='cp-login-content__form__desc'>Please input the OTP code sent to your sales manager.</p>
            <div className='cp-login-content__form__main'>
              <div className='form-group'>
                <input type='text' id='otp' className='form-control form-control-lg' name='otp' placeholder='OTP'/>
                <label for='otp' className='form-group__label'>OTP</label>
              </div>
              <div className='form-group cp-login-content__form__btn'>
                <button className='cp-login-content__form__btn__item btn btn-primary'>submit otp</button>
                <a href='' className='cp-login-content__form__btn__item os-btn-link'>Resend OTP</a>
              </div>
            </div> */}

            {/* Sign In locked */}
            {/* <p className='cp-login-content__form__info'>Your account is temporarily locked. Please contact your manager to unlock it.</p>
            <div className='cp-login-content__form__main'>
              <div className='form-group cp-login-content__form__btn'>
                <button className='cp-login-content__form__btn__item btn btn-primary'>back to login</button>
              </div>
            </div> */}
          </form>
        </div>
      </div>
      {isCPLogin ? <LoadingMask/> : ''}
    </>
  );
};

CPLogin.propTypes = {
  fields: PropTypes.any,
  isCPLogin: PropTypes.bool,
  isCPLoginSuccess: PropTypes.bool,
  cPError: PropTypes.string,
  cPUserData:PropTypes.any
};

const mapStateToProps = (state) => ({
  isCPLogin: state.singleSignOnReducer.loginReducer?.isCPLogin,
  isCPLoginSuccess: state.singleSignOnReducer.loginReducer?.isCPLoginSuccess,
  cPUserData: state.singleSignOnReducer.userInfoReducer?.cPUserData,
  cPError: state.singleSignOnReducer.loginReducer?.cPError,
});

export default connect(mapStateToProps)(CPLogin);
