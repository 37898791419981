import { combineEpics } from 'redux-observable';

import cancelAppointmentEpic from './cancelAppointment';
import updateAppointmentEpic from './updateAppointment';

const myAppointmentEpic = combineEpics(
  cancelAppointmentEpic,
  updateAppointmentEpic
);

export default myAppointmentEpic;