import {
  GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA,
  GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_FAILED,
  GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_SUCCESS
} from './categoryForRegisterWarrantyTypes';

export const getProductForRegisterWarrantyData = (payload) => ({
  type: GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA,
  payload
});

export const getProductForRegisterWarrantyDataSuccess = (payload) => ({
  type: GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_SUCCESS,
  payload
});

export const getProductForRegisterWarrantyDataFailed = (payload) => ({
  type: GET_PRODUCT_FOR_REGISTER_WARRANTY_DATA_FAILED,
  payload
});
