import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';

export default class HeaderMenuFieldsModel {
  constructor() {
    this['First Banner'] = new SitecoreImageModel();

    this['First Link'] = new SitecoreLinkModel();

    this['Second Image'] = new SitecoreImageModel();

    this['Second Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'First Banner')) {
      this['First Banner'].getData(dataSource['First Banner']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'First Link')) {
      this['First Link'].getData(dataSource['First Link']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second Image')) {
      this['Second Image'].getData(dataSource['Second Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second Link')) {
      this['Second Link'].getData(dataSource['Second Link']);
    }

    return this;
  }
}
