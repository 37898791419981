import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSaleStaffs } from '@redux/actions/cpLeadManagement';
import { getProductCatalogue } from '@redux/actions/getProductCatalogue';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
// import LoadingMask from '@components/Share/LoadingMask';
import { deepCopy } from '@utils/utility';

import CPPageHeader from '../CPPageHeader';
import { ALLSTOREID,INIT_PAGE, LEAD_MANAGEMENT_PAGES } from './configs';
import CPPagingSaleOrderListing from './CPPagingSaleOrderListing';
import SaleOrdersListingModel from './Models/SaleOrdersListingModel';
import OrdersListing from './OrdersListing';
import OrdersSearch from './OrdersSearch';

const SaleOrdersListing = ({ fields }) => {
  const dispatch = useDispatch();
  const countData = useSelector((state) => state?.getSOCartsReducer?.dataApi?.Count);
  const ordersListing = useSelector((state) => state?.getSOCartsReducer?.dataApi?.Carts);
  const isGettingData = useSelector((state) => state?.getSOCartsReducer?.isLoading);
  const sitename = SitecoreContextFactoryService.getValueContextItem('site');

  const [layoutData, setLayoutData] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [isFilter, setIsFilter] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [keywordSearch, setKeywordSearch] = useState('');
  const [dataFilter, setDataFilter] = useState('');
  const [itemSkip, setItemSkip] = useState(0);
  const [isResetPaging, setResetPaging] = useState(false);

  const [pagination, setPagination] = useState({
    page: INIT_PAGE,
    limit: LEAD_MANAGEMENT_PAGES
  });

  const currentStoreId = () => {
    const storeId = SitecoreContextFactoryService.getValueContextItem('currentStore');

    return storeId?.storeId !== ALLSTOREID ? storeId : '';
  };

  const filterSubmit = (evt, data) => {
    evt.preventDefault();

    setDataFilter(data);

    setItemSkip(0);

    setIsFilter(true);

    setIsReset(false);
  };

  const searchOrdersListing = (searchResult) => {
    if (searchResult == '') {
      setIsSearch(false);
    } else {
      setIsSearch(true);
    }

    setKeywordSearch(searchResult);
  };

  const changePageHandler = (pageNumber) => {
    setItemSkip((pageNumber - 1) * 10);

    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    setLayoutData(new SaleOrdersListingModel().getData(deepCopy(fields)));

    dispatch(getSaleStaffs({ managerId: JSON.parse(window.localStorage.getItem('cPUserData'))?.UserId || null }));

    dispatch(getProductCatalogue({ sitename: sitename?.name }));
  }, []);

  return layoutData ? (
    <>
      <CPPageHeader
        title={layoutData?.Header?.value.replace('[Staff]', 'Test')}
        type='filter-orders'
        filterFields={layoutData}
        onFilterSubmit={filterSubmit}
        onSetIsReset={setIsReset}
        currentStoreId={currentStoreId()}
      />
      <OrdersSearch onLayoutData={layoutData} onSearchOrdersListing={searchOrdersListing} />
      <OrdersListing
        onLayoutData={layoutData}
        onIsSearch={isSearch}
        onIsFilter={isFilter}
        onIsReset={isReset}
        onKeywordSearch={keywordSearch}
        onDataFilter={dataFilter}
        onSearchOrdersListing={searchOrdersListing}
        onOrdersListing={ordersListing}
        onItemSkip={itemSkip}
        setItemSkip={setItemSkip}
        setResetPaging={setResetPaging}
        currentStoreId={currentStoreId()}
      />
      {!isGettingData ? (
        <CPPagingSaleOrderListing
          isReset={isResetPaging}
          totalItem={countData}
          pagination={pagination}
          setPagination={setPagination}
          onChangePageHandler={changePageHandler}
        />
      ) : (
        <></>
      )}
      {/*{isGettingData ? <LoadingMask /> : <></>}*/}
    </>
  ) : (
    <></>
  );
};

SaleOrdersListing.propTypes = {
  fields: PropTypes.any
};

export default SaleOrdersListing;
