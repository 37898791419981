import SitecoreTextModel from '@models/SitecoreTextModel';

export default class FieldsModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();

    this['Your Product Hub Options'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'] = dataSource['Title'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Your Product Hub Options')) {
      this['Your Product Hub Options'] = dataSource['Your Product Hub Options'];
    }

    return this;
  }
}