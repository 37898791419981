import './CPHeaderNotiIcon.scss';

import { any } from 'prop-types';
import React, { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getHadNotificationsStart } from '@redux/actions/cpNotificationPopup/getHadNotifications';
import { closeCpNotificationPopup, openCpNotificationPopup } from '@redux/actions/cpNotificationPopup/popupToggle';
import { defaultFetchLogsParams } from '@redux/epic/cpNotificationPopup/configs';
import { Image } from '@sitecore-jss/sitecore-jss-react';

const CPHeaderNotiIcon = ({ cPHeaderIconFields }) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector((state) => state.cpNotificationPopupReducer.popupToggleReducer);
  const { isHadNotifications } = useSelector((state) => state.cpNotificationPopupReducer.getHadNotificationsReducer);
  const { notificationLogs } = useSelector((state) => state.cpNotificationPopupReducer.notificationDataReducer);
  const iconRef = useRef(null);

  // INFO: uncomment if need in the future
  // const windowWidth = useWindowSize();
  // useEffect(() => {
  //   const handleSetCpPopupPosition = () => {
  //     const iconWidth = iconRef.current.offsetWidth;
  //     const iconOffsetLeft = iconRef?.current?.offsetLeft;
  //     const iconSpacingFromRight = windowWidth - iconOffsetLeft - iconWidth;
  //
  //     dispatch(setCpNotificationPopupSpacing(iconSpacingFromRight));
  //   };
  //
  //   window.addEventListener('resize', () => handleSetCpPopupPosition());
  //
  //   handleSetCpPopupPosition();
  //
  //   return () => window.removeEventListener('resize', () => handleSetCpPopupPosition());
  // }, [iconRef, windowWidth]);

  useEffect(() => {
    dispatch(getHadNotificationsStart(defaultFetchLogsParams));
  }, []);

  const handleOpenPopup = useCallback(() => (isOpen ? dispatch(closeCpNotificationPopup()) : dispatch(openCpNotificationPopup())), [isOpen]);
  const handleClosePopup = () => dispatch(closeCpNotificationPopup());

  return (
    <>
      {isOpen ? <div className='overlay-transparent' onClick={handleClosePopup}></div> : ''}
      <li ref={iconRef} className={`head-icon cp-header-noti-icon ${isHadNotifications ? 'had-notification' : ''}`} onClick={handleOpenPopup}>
        <Image field={cPHeaderIconFields.Image} />
      </li>
    </>
  );
};

CPHeaderNotiIcon.propTypes = {
  cPHeaderIconFields: any
};

export default CPHeaderNotiIcon;
