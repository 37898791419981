import parse from 'html-react-parser';
import { bool } from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import LoginModal from '../LoginModal';

const ContactDetailSubtitle = ({ isLoginSuccess }) => {
  const { layoutData } = useScheduleAppointmentContext();
  const [string, htmlTag] = parse(layoutData?.['Subtitle']?.value);
  const [isOpenLoginModal, setIsOpenLoginModal] = useState(false);

  const handleOpenLoginModal = () => setIsOpenLoginModal(true);
  const handleCloseLoginModal = () => setIsOpenLoginModal(false);

  return layoutData?.['Subtitle']?.value && htmlTag ? (
    <>
      <div className='subtitle'>
        {!isLoginSuccess ? (
          <h5 className='subtitle-text'>
            {string}{' '}
            {React.cloneElement(htmlTag, {
              className: 'subtitle-btn',
              onClick: handleOpenLoginModal
            })}
          </h5>
        ) : (
          <Text tag='h5' field={layoutData?.['Subtitle After Login Text']} className='subtitle-text' />
        )}
      </div>
      <LoginModal isOpenModal={isOpenLoginModal} onClose={handleCloseLoginModal} />
    </>
  ) : (
    <></>
  );
};

ContactDetailSubtitle.propTypes = {
  isLoginSuccess: bool
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin
});

export default connect(mapStateToProps)(ContactDetailSubtitle);
