import './ProductWarrantyDetail.scss';

import React from 'react';

import ProductDetail from '../ProductDetail';
import WarrantyDetail from '../WarrantyDetail';
import DownloadOSIMAppDetail from './DownloadOSIMAppDetail';
import ProductReviewItemWidget from './ProductReviewItemWidget';

const ProductWarrantyDetail = () => {
  return (
    <div className='container product-warranty__detail'>
      <div className='product-warranty__detail-container'>
        <div className='product-warranty__detail-item'>
          <ProductDetail />
        </div>
        <div className='product-warranty__detail-item'>
          <WarrantyDetail />
        </div>
        <div className='product-warranty__detail-item'>
          <ProductReviewItemWidget />
        </div>
        <div className='product-warranty__detail-item'>
          <DownloadOSIMAppDetail />
        </div>
      </div>
    </div>
  );
};

export default ProductWarrantyDetail;