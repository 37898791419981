import { object, string } from 'prop-types';
import React, { useCallback } from 'react';

import { deepCopy } from '@utils/utility';

import { useSelectingFreeGiftUtils } from '../../hooks';
import useSelectingFreeGift from '../../SelectingFreeGiftProvider/useSelectingFreeGift';

const FreeGiftSelectionItem = ({ productInfo, freeGiftName, ...props }) => {
  const { selectedFreeGift, setSelectedFreeGift, editingFreeGift, setEditingFreeGift } = useSelectingFreeGift();
  const { handleCheckFreeGiftExistInCartLine, handleCheckFreeGiftExistInSelectedFreeGift } = useSelectingFreeGiftUtils();

  const handleCheckVariantIsSelected = useCallback(
    (productId, variantId) => {
      let isVariantChecked;
      if (selectedFreeGift.length) {
        const freeGiftExist = handleCheckFreeGiftExistInSelectedFreeGift(productInfo.PromotionId, productId, variantId);

        isVariantChecked = freeGiftExist && freeGiftExist?.variant?.VariantId === variantId && freeGiftExist?.ProductId === productId;
      } else {
        const freeGiftExist = handleCheckFreeGiftExistInCartLine(productInfo.PromotionId, productId, variantId);

        isVariantChecked = freeGiftExist && freeGiftExist?.VariantId === variantId;
      }

      return isVariantChecked;
    },
    [productInfo, selectedFreeGift]
  );

  const handleRemoveFreeGiftFromEditingList = (promotionId) => {
    const editingFreeGiftTemp = deepCopy(editingFreeGift);

    editingFreeGiftTemp.splice(editingFreeGiftTemp.indexOf(editingFreeGiftTemp.find((freeGift) => freeGift.PromotionId === promotionId)), 1);

    setEditingFreeGift(editingFreeGiftTemp);
  };

  const handleSelectFreeGift = (freeGiftInfo, variant) => {
    let selectedFreeGiftTemp = [...selectedFreeGift];
    const promotionExist = selectedFreeGiftTemp.filter((freeGiftItem) => freeGiftItem.PromotionId === freeGiftInfo.PromotionId);
    // INFO: add gift to selectedFreeGift
    if (!selectedFreeGiftTemp.length || !promotionExist.length) {
      selectedFreeGiftTemp.push({
        Slot: productInfo.Slot,
        PromotionId: freeGiftInfo.PromotionId,
        ProductId: freeGiftInfo.ProductId,
        freeGiftInfo: freeGiftInfo,
        variant: variant
      });
    } else {
      const slotExistInPromotion = promotionExist.find((promotionItem) => promotionItem.Slot === productInfo.Slot);

      if (slotExistInPromotion) {
        const indexOfSlot = selectedFreeGiftTemp.indexOf(slotExistInPromotion);

        selectedFreeGiftTemp[indexOfSlot] = {
          ...selectedFreeGiftTemp[indexOfSlot],
          ProductId: freeGiftInfo.ProductId,
          freeGiftInfo: freeGiftInfo,
          variant: variant
        };
      } else {
        selectedFreeGiftTemp = [
          ...selectedFreeGiftTemp,
          {
            Slot: productInfo.Slot,
            PromotionId: freeGiftInfo.PromotionId,
            ProductId: freeGiftInfo.ProductId,
            freeGiftInfo: freeGiftInfo,
            variant: variant
          }
        ];
      }
    }

    setSelectedFreeGift(selectedFreeGiftTemp);

    // INFO: remove gift that is added to selectedFreeGift
    handleRemoveFreeGiftFromEditingList(freeGiftInfo.PromotionId);
  };

  return (
    <div {...props} className='free-gift__selection-item'>
      <p className='free-gift__selection-title'>{freeGiftName}</p>
      <div className='free-gift__selection-variant'>
        {productInfo.products.map((product) =>
          product.variantData.map((variant) => (
            <div
              key={variant.VariantId}
              className='free-gift__selection-variant__item'
              onClick={() => handleSelectFreeGift({ ...product, PromotionId: productInfo.PromotionId }, variant)}
            >
              <div className={`variant-image ${handleCheckVariantIsSelected(product.ProductId, variant.VariantId) ? 'variant-image__active' : ''}`}>
                <img src={variant.Images[0]} alt={variant.VariantName} />
              </div>
              <p className='variant-name'>{variant.VariantName}</p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

FreeGiftSelectionItem.propTypes = {
  productInfo: object,
  freeGiftName: string
};

export default FreeGiftSelectionItem;
