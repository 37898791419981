import BundlePriceCommonModel from './BundlePriceCommonModel';

export default class BundleProductWarrantyModel {
  constructor() {
    this['active'] = false;

    this['ProductId'] = '';

    this['Price'] = new BundlePriceCommonModel();

    this['DisplayName'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'active')) {
      this['active'] = dataSource['active'] ? dataSource['active'] : this['active'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this['ProductId'] = dataSource['ProductId'] ? dataSource['ProductId'] : this['ProductId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayName')) {
      this['DisplayName'] = dataSource['DisplayName'] ? dataSource['DisplayName'] : this['DisplayName'];
    }

    return this;
  }
}
