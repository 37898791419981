import Proptypes from 'prop-types';
import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const CPFooter = (props) => {
  return (
    <Placeholder name='osim-footer-main' rendering={props.rendering} />
  );
};

CPFooter.propTypes = {
  rendering: Proptypes.any
};

export default CPFooter;