import { addDays } from 'date-fns';

export const MILLISECOND_PER_DAY = 1000 * 60 * 60 * 24;

const getNearestAvailableDate = (date, totalDay) => {
  let resultDate;
  let totalDate = 0;
  const currentDate = new Date(new Date().toDateString());

  for (let i = 0; i <= totalDay; i++) {
    if (date.getTime() < currentDate.getTime()) {
      date.setDate(date.getDate() + 1);
    } else {
      totalDate = totalDay - i;

      resultDate = date;
      break;
    }
  }

  return { resultDate, totalDate };
};

export const generateDateRange = (stores, storeId) => {
  const dateRangesData = stores?.find((store) => store.Id === storeId)?.DateRanges;

  let dateRangesResult = [];
  if (dateRangesData?.length) {
    for (const date of dateRangesData) {
      const startDate = new Date(date.From);
      const endDate = new Date(date.To);
      const totalDay = (endDate.getTime() - startDate.getTime()) / MILLISECOND_PER_DAY;
      const availableStartDate = getNearestAvailableDate(startDate, totalDay);
      if (availableStartDate.resultDate) {
        dateRangesResult.push({
          start: availableStartDate.resultDate,
          end: addDays(startDate, availableStartDate.totalDate)
        });
      }
    }
  }

  return dateRangesResult.length ? dateRangesResult : null;
};
