import Proptypes from 'prop-types';
import React, { Fragment } from 'react';

import BuyingBundleAccordion from '@components/Share/BuyingBundleAccordion';

import { VARIANT_BUNDLE_EVENT_KEY } from '../config';

const BundleBOVariants = ({dataItem, variantSelected, dataText, onSelectVariantHandler, checkValidate}) => {
  return dataItem?.Variants?.length ? (
    <Fragment>
      <BuyingBundleAccordion
        parentId={dataItem.UniqueProductId}
        data={dataItem.Variants}
        headerType={'FIRSTTYPE'}
        eventKey={VARIANT_BUNDLE_EVENT_KEY}
        isOpenItem = {dataItem.isChoseVariant === true ? false : true}

        onAction = {onSelectVariantHandler}
        selectedItemsName = {dataItem['Active Variant']?.VariantPropertyValue}

        labelText={dataItem.VariantPropertyLabel ? dataItem.VariantPropertyLabel : dataText['Choose Your Color Label'].value} 
        changeText={dataText['Change Text'].value}
        outStockText={dataText['Out Stock Text'].value}
        errorMsg={dataItem.ChooseVariantValidationMessage || dataText['Please Select Your Color Message'].value}
        isShowError={checkValidate && !dataItem.isChoseVariant}

        variantSelected={variantSelected}
      />
    </Fragment>
  ) : null;
};

BundleBOVariants.propTypes = {
  dataItem: Proptypes.any,
  dataText: Proptypes.any,
  onSelectVariantHandler: Proptypes.any,
  checkValidate: Proptypes.any,
  variantSelected: Proptypes.any,
};

export default BundleBOVariants;