import './Carousel.scss';

import { any, array, object } from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import CarouselModel from './Model/CarouselModel';
import ZoomSwiperComponent from './ZoomSwiperComponent';

export const CarouselDesktop = ({ contentData, params, carouselSwiperRef }) => {
  return (
    <SwipeComponent param={params} setRef={carouselSwiperRef}>
      {contentData.map((itemImage, indexImage) => {
        return (
          <div className='os-carousel__item' key={indexImage}>
            <Image media={itemImage.fields.Image} className='d-desktop' />
            <Text tag='h4' className='os-carousel__item__title' field={itemImage.fields['Item Title']} />
          </div>
        );
      })}
    </SwipeComponent>
  );
};

export const CarouselMobile = ({ contentData, params, carouselSwiperRef }) => {
  return (
    <SwipeComponent param={params} setRef={carouselSwiperRef}>
      {contentData.map((itemImage, indexImage) => {
        return (
          <div className='os-carousel__item' key={indexImage}>
            <Image media={itemImage.fields['Mobile Image']} className='d-mobi' />
            <Text tag='h4' className='os-carousel__item__title' field={itemImage.fields['Item Title']} />
          </div>
        );
      })}
    </SwipeComponent>
  );
};

const Carousel = ({ fields, params }) => {
  const [contentData, setContentData] = useState(null);
  const [windowWidth, setWindowWidth] = useState(null);
  const [displayingComponent, setDisplayingComponent] = useState(null);
  const [slideTo, setSlideTo] = useState(0);
  const [toggleGallery, setToggleGallery] = useState(false);
  const carouselSwiperRef = useRef(null);
  const [carouselDesktop, setCarouselDesktop] = useState(null);
  const [carouselMobile, setCarouselMobile] = useState(null);

  useEffect(() => {
    const model = new CarouselModel();

    setContentData(model.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useDidUpdateEffect(() => {
    let mobileArr = [];
    let desktopArr = [];

    contentData.items.forEach((item) => {
      if (item?.fields?.['Mobile Image']?.value?.src?.length) {
        mobileArr.push(item);
      }
      if (item?.fields?.Image?.value?.src?.length) {
        desktopArr.push(item);
      }
    });

    setCarouselMobile(mobileArr);

    setCarouselDesktop(desktopArr);
  }, [contentData]);

  useEffect(() => {
    let childNodes = carouselSwiperRef?.current?.firstChild?.childNodes;

    childNodes &&
      childNodes.forEach((ele) => {
        ele.addEventListener('click', () => {
          setToggleGallery(true);

          setSlideTo(ele.getAttribute('data-swiper-slide-index'));
        });
      });
  }, [carouselDesktop, carouselMobile, windowWidth]);

  useDidUpdateEffect(() => {
    if (windowWidth && windowWidth < 768) {
      setDisplayingComponent(false);
    }
    if (windowWidth && windowWidth >= 768) {
      setDisplayingComponent(
        contentData.items.every((item) => {
          return item.fields?.Image?.value?.src === '';
        })
      );
    }
  }, [windowWidth]);

  const carouselParams = {
    loop: true,
    centeredSlides: true,
    spaceBetween: 8,
    slidesPerView: 1.24,
    watchOverflow: true,
    shouldSwiperUpdate: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    on: {
      slideChangeTransitionEnd: () => {
        setSlideTo(carouselSwiperRef.current.swiper.realIndex);
      }
    },

    breakpoints: {
      768: {
        slidesPerView: 1.24,
        spaceBetween: 30
      },

      1200: {
        slidesPerView: 1.5,
        spaceBetween: 40
      }
    }
  };

  const layoutContentRender = () => {
    let ComponentType = windowWidth < 768 ? CarouselMobile : CarouselDesktop;

    return (
      <ComponentType
        contentData={windowWidth < 768 ? carouselMobile : carouselDesktop}
        params={carouselParams}
        carouselSwiperRef={carouselSwiperRef}
      />
    );
  };

  const onCloseZoomImagesHandler = (slideIndex) => {
    setToggleGallery(false);

    setSlideTo(slideIndex);

    carouselSwiperRef.current.swiper.slideToLoop(slideIndex);
  };

  return contentData && carouselMobile && carouselDesktop ? (
    displayingComponent ? (
      <></>
    ) : (
      <div
        className={`os-carousel animated-slow fadeIn delay-100 ${params?.['Text Color'] === 'White' ? 'os-carousel--text-light' : ''} ${
          params['Indicator Color'] === 'White' ? 'os-carousel--indicator-light' : ''
        }`}
        style={{ backgroundColor: params['Background Color Code'] ? '#' + params['Background Color Code'] : '' }}
      >
        <div className='container'>
          <div className='os-carousel__wrap'>
            <Text field={contentData.Title} tag='h2' className='os-carousel__heading experience-title title-distance'></Text>
            <Text field={contentData.Description} tag='p' className='os-carousel__description'></Text>
          </div>
        </div>
        <div className='os-carousel__list'>{layoutContentRender()}</div>
        {toggleGallery ? (
          <ZoomSwiperComponent
            setCloseZoomImages={onCloseZoomImagesHandler}
            toggleGallery={toggleGallery}
            items={windowWidth < 768 ? carouselMobile : carouselDesktop}
            activeSlideKey={parseInt(slideTo)}
            windowWidth={windowWidth}
          />
        ) : (
          <></>
        )}
      </div>
    )
  ) : (
    <div className='os-carousel skeleton__item'>
      <div className='container'>
        <div className='os-carousel__wrap'>
          <h2 className='os-carousel__heading experience-title title-distance'>
            <span className='text-line-40-center w-40'></span>
          </h2>
          <p className='os-carousel__description'>
            <span className='text-line-24-center w-80'></span>
          </p>
        </div>
      </div>
      <div className='os-carousel__list'>
        <div className='os-carousel__item'>
          <div className='image-wrap'>
            <span className='image-line'></span>
          </div>
          <span className='clear-20'></span>
          <span className='text-line-center w-10'></span>
        </div>
      </div>
    </div>
  );
};

CarouselDesktop.propTypes = {
  contentData: array,
  params: object,
  carouselSwiperRef: any
};

CarouselMobile.propTypes = {
  contentData: array,
  params: object,
  carouselSwiperRef: any
};

Carousel.propTypes = {
  fields: object,
  params: object
};

export default Carousel;
