import './FeaturedTiles.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import SwipeComponent from '@components/Share/SwipeComponent';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import FeaturedTilesItem from './FeaturedTilesItem';
import FeaturedTilesShimmer from './FeaturedTilesShimmer';
import FeaturedTilesFieldsModel from './Model/FeaturedTilesFieldsModel';

const FeaturedTiles = (props) => {
  const params = {
    slidesPerView: 1.2,
    centeredSlides: false,
    spaceBetween: 18,
    watchOverflow: true,
    autoHeight: false,
    breakpoints : {
      768: {
        slidesPerView: 2.2,
        spaceBetween: 30,
      },

      1024: {
        slidesPerView: 3,
        spaceBetween: 30,
      },

      1400: {
        slidesPerView: 3,
        spaceBetween: 40,
      },
    },

    shouldSwiperUpdate: true
  };

  const {fields } = props;
  const [featuredTilesFields, setFeaturedTilesFields] = useState(null);

  useEffect(() => {
    const featuredTilesFieldsModel = new FeaturedTilesFieldsModel();

    setFeaturedTilesFields(featuredTilesFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return featuredTilesFields ? (
    <div className='featured-tiles animated-slow fadeIn delay-100'>
      <div className='container featured-tiles__wrap'>
        <div className='featured-tiles__heading'>
          <Text field={featuredTilesFields.Title} tag='h2' className='experience-title featured-tiles__heading__title'/>
          <Text field={featuredTilesFields.Description} tag='p' className='featured-tiles__heading__desc'/>
          <div className='featured-tiles__heading__btn-view-all'>
            <Link className='btn-view-all btn btn-outline-CTA2' field={featuredTilesFields['CTA Button']}>
              <Text field={featuredTilesFields['CTA Button Text']} tag='span' />
            </Link>
          </div>
        </div>
        <div className='featured-tiles__list'>
          <SwipeComponent param={params}>
            {
              featuredTilesFields['Featured Tile Items'].length ? (
                featuredTilesFields['Featured Tile Items'].map((featuredTileItem, index) => {
                  return (
                    <div className='featured-tiles__item' key={index}>
                      <FeaturedTilesItem data={featuredTileItem}></FeaturedTilesItem>
                    </div>
                  );
                })
              ) : <></>
            }
          </SwipeComponent>
        </div>
      </div>
    </div>
  )
    : <LoadingShimmer itemNumb={1}>
      <FeaturedTilesShimmer></FeaturedTilesShimmer>
    </LoadingShimmer>;
};

FeaturedTiles.propTypes = {
  fields: PropTypes.object
};

export default FeaturedTiles;