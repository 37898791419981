import { combineReducers } from 'redux';

import cancelAppointmentReducer from './cancelAppointment';
import updateAppointmentReducer from './updateAppointment';

const myAppointmentReducer = combineReducers({
  cancelAppointmentReducer,
  updateAppointmentReducer
});

export default myAppointmentReducer;
