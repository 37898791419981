import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

export default class CatalogsProductModel {
  constructor() {
    this.ID = '';

    this.Name = '';

    this.Path = '';

    this.Images = '';

    this.isActive = false;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ID')) {
      this.ID = dataSource.ID;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ID') && dataSource.ID.toLowerCase().includes(SitecoreContextFactoryService.getValueContextRouteItem('itemId').toLowerCase())) {
      this.isActive = true;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Path')) {
      this.Path = dataSource.Path;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      this.Images = dataSource.Images;
    }

    return this;
  }
}
