import { bool, object } from 'prop-types';
import React, { useMemo } from 'react';

const SimpleCtaContentFooter = ({ buttonText, buttonLink, isButtonPrimary = false, linkText, linkUrl }) => {
  const buttonValue = useMemo(() => {
    let buttonValueTemp = {
      isShow: false,
      href: '',
      text: '',
      isPrimary: isButtonPrimary
    };
    const text = buttonText?.value;
    const href = buttonLink?.value?.href;
    if (text && href) {
      buttonValueTemp = {
        ...buttonValueTemp,
        isShow: true,
        text: text,
        href: href
      };
    }

    return buttonValueTemp;
  }, [buttonLink, buttonText, isButtonPrimary]);

  const linkValue = useMemo(() => {
    let linkValueTemp = {
      isShow: false,
      href: '',
      text: ''
    };
    const text = linkText?.value;
    const href = linkUrl?.value?.href;

    if (text && href) {
      linkValueTemp = {
        ...linkValueTemp,
        isShow: true,
        text: text,
        href: href
      };
    }

    return linkValueTemp;
  }, [linkText, linkUrl]);

  return (
    <div className='simple-cta__content-footer'>
      {buttonValue.isShow ? (
        <a href={buttonValue.href} className={`btn ${buttonValue.isPrimary ? 'btn-primary' : 'btn-outline-primary'}`}>
          {buttonValue.text}
        </a>
      ) : (
        <></>
      )}
      {linkValue.isShow ? (
        <a href={linkValue.href} className='btn btn-link'>
          {linkValue.text}
        </a>
      ) : (
        <></>
      )}
    </div>
  );
};

SimpleCtaContentFooter.propTypes = {
  buttonText: object,
  buttonLink: object,
  isButtonPrimary: bool,
  linkText: object,
  linkUrl: object
};

export default SimpleCtaContentFooter;
