import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';

import * as cartDetailActions from '@redux/actions/cartDetail';
import * as actions from '@redux/actions/checkoutProcess';
import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';
import { getClientSOCartSuccess } from '@redux/actions/personalizeOffer/getClientSOCart';
import * as cartActions from '@redux/actions/product';
import { turnUpdateAddressLoadingMaskOff } from '@redux/actions/product';
import { get, post, postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import { getCartIdParam } from '../../personalizeOffer/utils';
import CartDetailService from '../../product/cartDetail/service';
import * as config from './config';
import { UserAddressesListModel } from './models';

const removeRedundantField = (item) => {
  const tempObj = deepCopy(item);

  delete tempObj.FullAddress;

  return tempObj;
};

export const getStoreAddressListEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.GET_STORE_ADDRESS_LIST),
    switchMap(() => {
      return from(get(config.STORE_ADDRESS)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getStoreAddressListSuccess(res.data?.Stores);
          } else {
            return actions.getStoreAddressListFailed('Failed');
          }
        })
      );
    })
  );

export const getUserAddressListEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.GET_BOOKED_ADDRESS),
    switchMap(() => {
      return from(get(config.USER_ADDRESS)).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.StatusCode === 200) {

            return actions.getBookedAddressSuccess(new UserAddressesListModel(res.data?.ListAddress).getListAddress);
          } else {
            return actions.getBookedAddressFailed(res.data?.Message || 'Failed');
          }
        }),
        catchError((error) => of(actions.getBookedAddressFailed(error)))
      );
    })
  );

export const updateBillingAddressEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.UPDATE_BILLING_ADDRESS),
    switchMap((action) => {
      if (action.payload) {
        const clientSOCartId = getCartIdParam();

        const params = {
          ...action.payload,
          PhoneNumber: action.payload?.PhoneNumber?.replace('+', '') || ''
        };
        if (clientSOCartId) params.CartId = clientSOCartId;

        return from(post(config.UPDATE_BILLING, params)).pipe(
          switchMap((res) => {
            if (res.status === 200 && res.data.Success) {
              if (clientSOCartId) {
                return of(getClientSOCartSuccess({ OsimCart: CartDetailService.customCurrentCart(res.data) }), turnUpdateAddressLoadingMaskOff());
              }

              return of(cartActions.getCurrentCartSuccess(CartDetailService.customCurrentCart(res.data), []));
            } else {
              return of(actions.updateBillingAddressFailed('Failed'));
            }
          }),
          catchError((error) => of(cartActions.getCurrentCartSuccess(error?.message || 'Failed'), turnUpdateAddressLoadingMaskOff()))
        );
      }
    })
  );

export const addNewAddressEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.ADD_NEW_ADDRESS),
    switchMap((action) => {
      return postAsObservable(config.ADD_NEW_ADDRESS, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.StatusCode === 200) {
            return actions.getBookedAddressSuccess(new UserAddressesListModel(res.data?.ListAddress).getListAddress);
          } else {
            return actions.getBookedAddressFailed(res.data?.Message || 'Failed');
          }
        }),
        catchError((error) => of(actions.getBookedAddressFailed(error)))
      );
    })
  );

export const changeDefaultAddressEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.CHANGE_DEFAULT_ADDRESS_BOOK),
    switchMap((action) => {
      return from(post(config.ACTION_CHANGE_DEFAULT_ADDRESS_BOOK, action.payload)).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.StatusCode === 200) {
            return actions.getBookedAddressSuccess(new UserAddressesListModel(res.data?.ListAddress).getListAddress);
          } else {
            return actions.getBookedAddressFailed(res.data?.Message || 'Failed');
          }
        }),
        catchError((error) => of(actions.getBookedAddressFailed(error)))
      );
    })
  );

export const getCountryDeliveryEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.GET_COUNTRY_DELIVERY),
    switchMap(() => {
      return from(get(config.GET_COUNTRY_DELIVERY)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getCountryDeliverySuccess(res.data.Country);
          } else {
            return actions.getCountryDeliveryFailed('Failed');
          }
        })
      );
    })
  );

export const removeAddressBookEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.REMOVE_ADDRESS_BOOK),
    switchMap((action) => {
      return postAsObservable(config.ACTION_REMOVE_ADDRESS_BOOK, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.StatusCode === 200) {
            return actions.getBookedAddressSuccess(new UserAddressesListModel(res.data?.ListAddress).getListAddress);
          } else {
            return actions.getBookedAddressFailed(res.data?.Message || 'Failed');
          }
        }),
        catchError((error) => of(actions.getBookedAddressFailed(error)))
      );
    })
  );

export const addNewAddressWithSetFulfillmentEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.ADD_NEW_ADDRESS_AND_SETFULFILL),
    switchMap((action) => {
      return postAsObservable(config.ADD_NEW_ADDRESS, action.payload.params).pipe(
        mergeMap((res) => [
          actions.getBookedAddressSuccess(new UserAddressesListModel(res.data?.ListAddress).getListAddress),
          cartDetailActions.setCartLineFullfillment({
            updateCartLineFulfillmentPayload: removeRedundantField({
              ...res.data?.ModifiedAddress,
              cartLineIds: action.payload.cartLineIds,
              deliveryOptionId: action.payload.deliveryOptionId,
              ...(action.payload.SameAsDeliveryAddress && { SameAsDeliveryAddress: action.payload.SameAsDeliveryAddress })
            })
          })
        ]),
        catchError((error) => of(actions.addNewAddressFailed(error)))
      );
    })
  );

export const addNewAddressWithSetBillingEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.ADD_NEW_ADDRESS_AND_SETBILLING),
    switchMap((action) => {
      return postAsObservable(config.ADD_NEW_ADDRESS, action.payload).pipe(
        mergeMap((res) => [
          actions.getBookedAddressSuccess(new UserAddressesListModel(res.data?.ListAddress).getListAddress),
          actions.updateBillingAddress(removeRedundantField(res.data?.ModifiedAddress))
        ]),
        catchError((error) => of(actions.addNewAddressFailed(error)))
      );
    })
  );
