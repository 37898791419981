import { GET_FREE_GIFT_DATA_FAILED, GET_FREE_GIFT_DATA_START, GET_FREE_GIFT_DATA_SUCCESS, SET_FREE_GIFT_DATA_NULL } from './getFreeGiftDataTypes';

export const getFreeGiftDataStart = (payload) => ({
  type: GET_FREE_GIFT_DATA_START,
  payload
});

export const getFreeGiftDataSuccess = (payload) => ({
  type: GET_FREE_GIFT_DATA_SUCCESS,
  payload
});

export const getFreeGiftDataFailed = (payload) => ({
  type: GET_FREE_GIFT_DATA_FAILED,
  payload
});

export const setFreeGiftDataNull = () => ({
  type: SET_FREE_GIFT_DATA_NULL
});
