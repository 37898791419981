import produce from 'immer';

import QuickAccessModel from '@components/ClientPortal/QuickAccess/model/QuickAccessModel';
import * as clientTellingPortalCustomerTypes from '@redux/actions/clientTellingPortalCustomer/clientTellingPortalCustomerTypes';

const initState = {
  isActiveAccess: false,
  isActiveQACustomerBasicProfile: false,
  isActiveQACustomerLifestyle: false,
  isActiveQAProductInterestListing: false,
  quickAccessLayoutService: new QuickAccessModel(),
  contacts: null,
  isExistingCustomerInformation: false,
  layoutQuestions: null
};

const clientTellingPortalQuickAccessReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case clientTellingPortalCustomerTypes.SET_IS_ACTIVE_QA_CUSTOMER_BASIC_PROFILE:
      draft.isActiveQACustomerBasicProfile = action.payload;

      draft.isActiveQACustomerLifestyle = false;

      draft.isActiveQAProductInterestListing = false;
      break;

    case clientTellingPortalCustomerTypes.SET_IS_ACTIVE_QA_CUSTOMER_LIFESTYLE:
      draft.isActiveQACustomerLifestyle = action.payload;

      draft.isActiveQACustomerBasicProfile = false;

      draft.isActiveQAProductInterestListing = false;
      break;

    case clientTellingPortalCustomerTypes.SET_IS_ACTIVE_QA_PRODUCT_INTEREST_LISTING:
      draft.isActiveQAProductInterestListing = action.payload;

      draft.isActiveQACustomerBasicProfile = false;

      draft.isActiveQACustomerLifestyle = false;
      break;

    case clientTellingPortalCustomerTypes.SET_IS_ACTIVE_ACCESS:
      draft.isActiveAccess = action.payload;
      break;

    case clientTellingPortalCustomerTypes.SET_IS_DEACTIVE_ACCESS:
      draft.isActiveQACustomerBasicProfile = false;

      draft.isActiveQACustomerLifestyle = false;

      draft.isActiveQAProductInterestListing = false;
      break;

    case clientTellingPortalCustomerTypes.GET_QUICK_ACCESS_LAYOUT_SERVICE:
      draft.quickAccessLayoutService = { ...action.payload };
      break;

    case clientTellingPortalCustomerTypes.UPDATE_CP_CUSTOMER_CONTACT_SUCCESS:
      draft.contacts = action.payload;

      draft.isActiveQACustomerBasicProfile = false;

      draft.isExistingCustomerInformation = true;
      break;

    case clientTellingPortalCustomerTypes.GET_LAYOUT_QUESTIONS:
      draft.layoutQuestions = action.payload;
      break;
    }

    return draft;
  });

export default clientTellingPortalQuickAccessReducer;
