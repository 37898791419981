import produce from 'immer';

import {
  HIDE_DELIVERY_PACKAGES_SECTION,
  HIDE_SELECTING_FREE_GIFT_POPUP,
  SHOW_DELIVERY_PACKAGES_SECTION,
  SHOW_SELECTING_FREE_GIFT_POPUP
} from '../../actions/controlSelectingFreeGiftPopup/controlSelectingFreeGiftPopupTypes';

const initState = {
  isShow: true,
  isShowDeliveryPackagesSection: false
};

const controlSelectingFreeGiftPopupReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case SHOW_SELECTING_FREE_GIFT_POPUP:
      draft.isShow = action.payload;
      break;

    case HIDE_SELECTING_FREE_GIFT_POPUP:
      draft.isShow = action.payload;
      break;

    case SHOW_DELIVERY_PACKAGES_SECTION:
      draft.isShowDeliveryPackagesSection = action.payload;
      break;

    case HIDE_DELIVERY_PACKAGES_SECTION:
      draft.isShowDeliveryPackagesSection = action.payload;
      break;
    }

    return draft;
  });

export default controlSelectingFreeGiftPopupReducer;
