import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import IconNavigationFieldsModel from '../Model/IconNavigationFieldsModel';

const IconNavigationItem = (props) => {
  const { contentData } = props;
  const [data, setData] = useState(null);
  const isGA4 = Global.getIsGA4();
  const name = SitecoreContextFactoryService.getValueContextRouteItem('name');

  useEffect(() => {
    const iconNavigationFieldsModel = new IconNavigationFieldsModel();

    setData(iconNavigationFieldsModel.getData(contentData));
  }, []);

  //New Logic ON/OFF GA, GA4
  const handleGA4Event = () => {
    if (isGA4) {
      Global.handleGA4Navigation({
        labelItem: data.Title.value,
        pageTitle: name,
        labelCategory: data.Title.value,
        pageUrl: data.Link.value.href
      });
    }
  };

  return (
    data && (
      <li className='quick-nav-item'>
        {props.isLogin ? (
          <Link className='quick-nav-item__link' field={data.Link} onClick={handleGA4Event}>
            <div className='quick-nav-item__image'>
              <Image media={data.Image} />
            </div>
            <Text field={data.Title} tag='span' />
          </Link>
        ) : (
          <></>
        )}
        {!props.isLogin && data['Is Authenticated'].value ? (
          <Link className='quick-nav-item__link' field={props.loginLink} onClick={handleGA4Event}>
            <div className='quick-nav-item__image'>
              <Image media={data.Image} />
            </div>
            <Text field={data.Title} tag='span' />
          </Link>
        ) : (
          <></>
        )}
        {!props.isLogin && !data['Is Authenticated'].value ? (
          <Link className='quick-nav-item__link' field={data.Link} onClick={handleGA4Event}>
            <div className='quick-nav-item__image'>
              <Image media={data.Image} />
            </div>
            <Text field={data.Title} tag='span' />
          </Link>
        ) : (
          <></>
        )}
      </li>
    )
  );
};

IconNavigationItem.propTypes = {
  contentData: PropTypes.any,
  isLogin: PropTypes.bool,
  loginLink: PropTypes.object
};

const mapStateToProps = (state) => ({
  loginLink: state.unsubscribeReducer.loginLink || {},
  isLogin: state.singleSignOnReducer?.userInfoReducer?.isLogin || false
});

export default connect(mapStateToProps)(IconNavigationItem);
