export default class StepHeaderStepModel {
  constructor() {
    this.value = '1';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this.value = dataSource.value;
    }
    
    return this;
  }
}
