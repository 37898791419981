export default class ImageModel {
  constructor() {
    this.src = '';

    this.alt = 'No images';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'src')) {
      this.src = dataSource.src ? dataSource.src : this.src;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'alt')) {
      this.alt = dataSource.alt;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'width')) {
      this.width = dataSource.width;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'height')) {
      this.height = dataSource.height;
    }
    
    return this;
  }
}
