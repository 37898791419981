import * as singleSignOnTypes from './singleSignOnTypes.js';
import { RESET_REGISTER_ERROR_MSG } from './singleSignOnTypes.js';

export const resendMailActive = (payload) => ({
  type: singleSignOnTypes.RESEND_MAIL_ACTIVE,
  payload
});

export const resendMailActiveSuccess = (payload) => ({
  type: singleSignOnTypes.RESEND_MAIL_ACTIVE_SUCCESS,
  payload
});

export const resendMailActiveFailed = (payload) => ({
  type: singleSignOnTypes.RESEND_MAIL_ACTIVE_FAILED,
  payload
});

export const login = (payload) => ({
  type: singleSignOnTypes.LOGIN,
  payload
});

export const logout = () => ({
  type: singleSignOnTypes.LOGOUT,
});

export const register = (payload) => ({
  type: singleSignOnTypes.REGISTER,
  payload
});

export const regiterSuccess = (payload) => ({
  type: singleSignOnTypes.REGISTER_SUCCESS,
  payload
});

export const registerFailed = (error) => ({
  type: singleSignOnTypes.REGISTER_FAILED,
  error
});

export const loginGoogle = (payload) => ({
  type: singleSignOnTypes.LOGIN_GOOGLE,
  payload
});

export const loginFacebook = (payload) => ({
  type: singleSignOnTypes.LOGIN_FACEBOOK,
  payload
});

export const loginSuccess = (payload) => ({
  type: singleSignOnTypes.LOGIN_SUCCESS,
  payload
});

export const loginFailed = (error) => ({
  type: singleSignOnTypes.LOGIN_FAILED,
  error
});

export const logoutSuccess = (payload) => ({
  type: singleSignOnTypes.LOGOUT_SUCCESS,
  payload
});

export const logoutFailed = (error) => ({
  type: singleSignOnTypes.LOGOUT_FAILED,
  error
});

export const forgotPassword = (payload) => ({
  type: singleSignOnTypes.FORGOT_PASSWORD,
  payload
});

export const forgotPasswordSuccess = (payload) => ({
  type: singleSignOnTypes.FORGOT_PASSWORD_SUCCESS,
  payload
});

export const forgotPasswordFailed = (error) => ({
  type: singleSignOnTypes.FORGOT_PASSWORD_FAILED,
  error
});

export const changePassword = (payload) => ({
  type: singleSignOnTypes.CHANGE_PASSWORD,
  payload
});

export const changePasswordSuccess = (payload) => ({
  type: singleSignOnTypes.CHANGE_PASSWORD_SUCCESS,
  payload
});

export const changePasswordFailed = (error) => ({
  type: singleSignOnTypes.CHANGE_PASSWORD_FAILED,
  error
});

export const checkLoginStatus = (payload) => ({
  type: singleSignOnTypes.CHECK_LOGIN_STATUS,
  payload
});

export const getUserProfile = (payload) => ({
  type: singleSignOnTypes.GET_USER_PROFILE,
  payload
});

export const getUserProfileSuccess = (payload) => ({
  type: singleSignOnTypes.GET_USER_PROFILE_SUCCESS,
  payload
});

export const getUserProfileFailed = (error) => ({
  type: singleSignOnTypes.GET_USER_PROFILE_FAILED,
  error
});

export const toogleLinkGoogle = (payload) => ({
  type: singleSignOnTypes.TOOGLE_LINK_GOOGLE,
  payload
});

export const toogleLinkFacebook = (payload) => ({
  type: singleSignOnTypes.TOOGLE_LINK_FACEBOOK,
  payload
});

//CP
export const cpLogin = (payload) => ({
  type: singleSignOnTypes.CP_LOGIN,
  payload
});

export const cpLoginSuccess = (payload) => ({
  type: singleSignOnTypes.CP_LOGIN_SUCCESS,
  payload
});

export const cpLoginFailed = (error) => ({
  type: singleSignOnTypes.CP_LOGIN_FAILED,
  error
});

export const checkCPLoginStatus = (payload) => ({
  type: singleSignOnTypes.CHECK_CP_LOGIN_STATUS,
  payload
});

export const cpLogout = () => ({
  type: singleSignOnTypes.CP_LOGOUT,
});

export const cpLogoutSuccess = (payload) => ({
  type: singleSignOnTypes.CP_LOGOUT_SUCCESS,
  payload
});

export const cpLogoutFailed = (error) => ({
  type: singleSignOnTypes.CP_LOGOUT_FAILED,
  error
});

export const cpVerifyLogin = (payload) => ({
  type: singleSignOnTypes.CP_VERIFY_LOGIN,
  payload
});

export const cpVerifyLoginSuccess = (payload) => ({
  type: singleSignOnTypes.CP_VERIFY_LOGIN_SUCCESS,
  payload
});

export const cpVerifyLoginFailed = (error) => ({
  type: singleSignOnTypes.CP_VERIFY_LOGIN_FAILED,
  error
});

export const resendLoginValidateCode = (payload) => ({
  type: singleSignOnTypes.RESEND_LOGIN_OTP,
  payload
});

export const resendLoginValidateCodeSuccess = (payload) => ({
  type: singleSignOnTypes.RESEND_LOGIN_OTP_SUCCESS,
  payload
});

export const resendLoginValidateCodeFailed = (error) => ({
  type: singleSignOnTypes.RESEND_LOGIN_OTP_FAILED,
  error
});

export const resetRegisterErrorMsg = () => ({
  type: RESET_REGISTER_ERROR_MSG
});
