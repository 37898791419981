import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXT_ARR = [
  'EventId',
  'Item Name',
  'Title',
  'Description',
  'From Time',
  'To Time',
  'Multiple Date And Time Text',
  'Change Button Text',
  'Cancel Button Text',
  'Learn More Button Text',
  'QAndA Text',
  'Scheduled Event Text',
  'Your Contact Text',
  'Back To Text',
  'User Name',
  'User Email',
  'User Phone',
  'Time Slots',
  'Link After Cancellation',
  'TitleMyAppointment',
  'Guest Label',
  'Title Cancel Popup',
  'Content Cancel Popup',
  'Do Not Cancel Button Text',
  'Cancel Booking Button Text',
  'Event Date',
  'Event Canceled Message',
  'IsPassed',
  'Learn More Button Link'
];
const IMG_ARR = ['Thumnail Image', 'Banner Image', 'Contact Details Image'];
const LINK_ARR = ['Cancel Booking Button Link', 'Back To Link', 'Cancel Booking Button Link'];

export default class MyAppointmentModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMG_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    this['Change Button Link'] = '';

    this['From Date'] = '';

    this['To Date'] = '';

    this['Gallery Images'] = [];

    this['Location'] = null;

    this['Question and Answer'] = [];

    this['Member Tier'] = [];

    this['Number Of Guest'] = '';

    this['Answers Id'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMG_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Change Button Link')) {
      this['Change Button Link'] = deepCopy(dataSource['Change Button Link']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'From Date')) {
      this['From Date'] = deepCopy(dataSource['From Date']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'To Date')) {
      this['To Date'] = deepCopy(dataSource['To Date']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Gallery Images')) {
      this['Gallery Images'] = deepCopy(dataSource['Gallery Images']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Location')) {
      this['Location'] = deepCopy(dataSource['Location']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Question and Answer')) {
      this['Question and Answer'] = deepCopy(dataSource['Question and Answer']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Member Tier')) {
      this['Member Tier'] = deepCopy(dataSource['Member Tier']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Number Of Guest')) {
      this['Number Of Guest'] = deepCopy(dataSource['Number Of Guest']);
    }
    
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Answers Id')) {
      this['Answers Id'] = deepCopy(dataSource['Answers Id']);
    }

    return this;
  }
}
