import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { get } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as config from './config';

const warrantyEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_WARRANTY),
    switchMap(() => {
      const params = {
        rt: config.rt,
        cci: SitecoreContextFactoryService.getValueContextRouteItem('itemId')
      };

      return from(get(config.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getWarrantySuccess(res);
          } else {
            return actions.getWarrantyFailed('Failed');
          }
        })
      );
    })
  );

export default warrantyEpic;
