import produce from 'immer';

import {
  ADD_FREE_GIFT_TO_CART_FAILURE,
  ADD_FREE_GIFT_TO_CART_START,
  ADD_FREE_GIFT_TO_CART_SUCCESS
} from '../../actions/addFreeGiftToCart/addFreeGiftToCartTypes';

const initState = {
  isLoading: false,
  isAddFreeGiftSuccess: false,
  error: null
};

const addFreeGiftToCartReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case ADD_FREE_GIFT_TO_CART_START:
      draft.isLoading = true;

      draft.isAddFreeGiftSuccess = false;
      break;

    case ADD_FREE_GIFT_TO_CART_SUCCESS:
      draft.isLoading = false;

      draft.isAddFreeGiftSuccess = true;
      break;

    case ADD_FREE_GIFT_TO_CART_FAILURE:
      draft.isLoading = false;

      draft.isAddFreeGiftSuccess = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default addFreeGiftToCartReducer;
