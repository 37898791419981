import { bool, object } from 'prop-types';
import React from 'react';

const AnimatedBannerWithVideo = (props) => {
  const { fields,isMute } = props;

  return (
    <div className='animated-banner__background__video'>
      <video className='slider-video' playsInline loop autoPlay muted={isMute}>
        <source src={fields.value.href} type='video/mp4' />
      </video>
    </div>
  );
};

AnimatedBannerWithVideo.propTypes = {
  fields: object,
  params: object,
  isMute: bool
};

export default AnimatedBannerWithVideo;