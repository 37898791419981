import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { setSaleOrderStatusesText } from '@redux/actions/cpSaleOrderStatuses';

import SaleOrderStatusesModel from './models/SaleOrderStatusesModel';

const SaleOrderStatuses = ({ fields }) => {
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const saleOrderStatusesModel = new SaleOrderStatusesModel();

    setLayoutData(saleOrderStatusesModel.getData(fields));
  }, []);

  useEffect(() => {
    if (layoutData) {
      const soStatuses = {
        draft: layoutData?.['Draft']?.value || 'Draft',
        viewed: layoutData?.['Viewed']?.value || 'Viewed',
        active: layoutData?.['Active']?.value || 'Active',
        completed: layoutData?.['Completed']?.value || 'Completed',
        expired: layoutData?.['Expired']?.value || 'Expired',
        deleted: layoutData?.['Deleted']?.value || 'Deleted',
      };

      dispatch(setSaleOrderStatusesText(soStatuses));
    }
  }, [layoutData]);

  return <></>;
};

SaleOrderStatuses.propTypes = {
  fields: object
};

export default SaleOrderStatuses;
