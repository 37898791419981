import './PaymentFail.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import PaymentFailFieldsModel from './Model/PaymentFailFieldsModel';

const PaymentFail = (props) => {
  const { fields } = props;
  const [paymentFailFields, setPaymentFailFields] = useState(null);

  useEffect(() => {
    const paymentFailFieldsModel = new PaymentFailFieldsModel();

    setPaymentFailFields(paymentFailFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return (
    paymentFailFields && (
      <div className='payment-fail'>
        <div className='payment-fail__content'>
          <img src={require('@assets/images/fail-icon.png')} alt='fail-icon' className='payment-fail__content__icon' />
          <Text field={paymentFailFields['Payment Fail Text']} tag='h3' className='payment-fail__content__text' />
          <RichText field={paymentFailFields['Instruction Message']} tag='h3' className='payment-fail__content__instruction-message' />
        </div>
        <div className='payment-fail__btn'>
          <Link field={paymentFailFields['Continue Payment Link'].Link} className='payment-fail__btn__continue-payment btn btn-primary'>
            <Text field={paymentFailFields['Continue Payment Link'].Title}></Text>
          </Link>
          <Link className='payment-fail__btn__back-homepage btn-link btn-link--back' field={paymentFailFields['Additional Link'].Link}>
            <Text field={paymentFailFields['Additional Link'].Title} />
          </Link>
        </div>
      </div>
    )
  );
};

PaymentFail.propTypes = {
  fields: object
};

export default PaymentFail;
