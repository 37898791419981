import './Popup.scss';

import PropTypes from 'prop-types';
import React from 'react';

const Popup = (props) => {
  const onCloseModalHandler = () => {
    props.closeModalEvent();
  };

  return (
    <div className={'osim-popup ' + (props.isOpened ? 'show-popup' : '')}>
      <div className='container'>
        <div className='osim-popup__wrap'>
          <div className='osim-popup__close-btn' onClick={() => onCloseModalHandler()}>
            <i className='icon-close'></i>
          </div>
          {props.children}
        </div>
      </div>
    </div>
  );
};

Popup.propTypes = {
  children: PropTypes.any,
  isOpened: PropTypes.bool,
  closeModalEvent: PropTypes.func
};

export default Popup;
