import { combineReducers } from 'redux';

import customerReviewsAverageReducer from './customerReviewsAverage';
import getBundleElementReducer from './getBundleElement';
import getDynamicBundleElementReducer from './getDynamicBundleElement';
import latestReviewReducer from './getLatestReview';
import getProductReviewByIdReducer from './getProductReviewById';
import getProductReviewReducer from './getProductReviews';
import productNameReducer from './productName';
import submitProductReviewReducer from './submitProductReview';
import uploadListFilesReducer from './uploadListFiles';

const productReviewReducer = combineReducers({
  productNameReducer,
  getProductReviewReducer,
  uploadListFilesReducer,
  customerReviewsAverageReducer,
  submitProductReviewReducer,
  latestReviewReducer,
  getProductReviewByIdReducer,
  getBundleElementReducer,
  getDynamicBundleElementReducer
});

export default productReviewReducer;
