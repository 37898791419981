import { useFormik } from 'formik';
import { any } from 'prop-types';
import React, { useMemo } from 'react';
import { connect, useDispatch } from 'react-redux';
import * as Yup from 'yup';

import { clearValidateScheduleOtpCodeErrorMessage, validateScheduleOtpStart } from '@redux/actions/bookingExperience/validateScheduleOtp';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import { useDisplayFormikMessage } from '../../../hooks/useDisplayFormikMessage';
import { useGetScheduleOtpCode } from '../../../hooks/useGetScheduleOtpCode';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';

const OTPInputHandler = ({ getOtpCodeResult, validateOtpError }) => {
  const dispatch = useDispatch();
  const { layoutData, scheduleData } = useScheduleAppointmentContext();
  const displayErrorMessage = useDisplayFormikMessage();
  const getOtpService = useGetScheduleOtpCode();

  const formik = useFormik({
    initialValues: { otpCode: '' },
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      otpCode: Yup.string().required(layoutData?.['Input Valid OTP Message']?.value)
    }),
    onSubmit: (values) => {
      const params = {
        productId: scheduleData.scheduleExperience.productId,
        mobileNumber: scheduleData.contactDetail.mobileNumberWithPrefix || scheduleData.contactDetail.mobileNumber,
        optCode: values.otpCode,
        emailAddress: scheduleData.contactDetail.emailAddress
      };

      dispatch(validateScheduleOtpStart(params));
    }
  });

  const handleResendOtpCode = () => {
    getOtpService();

    dispatch(clearValidateScheduleOtpCodeErrorMessage());

    formik.resetForm();
  };

  const validateOtpErrorMessage = useMemo(() => {
    if (validateOtpError) {
      if (validateOtpError?.StatusCode === 2) return layoutData?.['OTP Timeout Message']?.value;
      if (validateOtpError?.StatusCode === 1) return layoutData?.['OTP Incorrect Message']?.value;
      if (validateOtpError?.StatusCode === 32) return layoutData?.['OTP Verification Exceeded Message']?.value;
    }
  }, [
    validateOtpError,
    layoutData?.['OTP Incorrect Message']?.value,
    layoutData?.['OTP Timeout Message']?.value,
    layoutData?.['OTP Verification Exceeded Message']?.value
  ]);

  return getOtpCodeResult ? (
    <form onSubmit={formik.handleSubmit} className='contact-detail__body'>
      <div className='register-event__form__content__section otp-input-section'>
        <Text tag='p' field={layoutData?.['OTP Sent Message']} className='section-label' />
        <div className={global.renderDynamicClass(formik.errors.otpCode && formik.touched.otpCode, 'form-group', 'input-error-validate')}>
          <input
            type='text'
            placeholder={layoutData?.['Enter OTP Placeholder Text']?.value}
            className={global.renderDynamicClass(formik.values.otpCode, 'form-control form-control-lg', 'input-valid')}
            name='otpCode'
            id='otpCode'
            autoComplete='off'
            onChange={formik.handleChange}
            value={formik.values.otpCode}
          />
          <span className='error-validate'>{displayErrorMessage(formik, 'otpCode') || validateOtpErrorMessage}</span>
        </div>
        <div className='form-group resend-otp'>
          <button type='button' className='btn btn-link resend-otp__btn' onClick={handleResendOtpCode}>
            <Text field={layoutData?.['Resend OTP Link Label']} />
          </button>
        </div>
        <div className='be-schedule-appointment__submit-btn'>
          <button type='submit' className='btn btn-primary'>
            <Text field={layoutData?.['Book Experience Text']} />
          </button>
        </div>
      </div>
    </form>
  ) : (
    <></>
  );
};

OTPInputHandler.propTypes = {
  getOtpCodeResult: any,
  validateOtpError: any
};

const mapStateToProps = (state) => ({
  getOtpCodeResult: state.bookingExperienceReducer.getScheduleOtpCodeReducer.getScheduleOtpCodeResult,
  validateOtpError: state.bookingExperienceReducer.validateScheduleOtpReducer.error
});

export default connect(mapStateToProps)(OTPInputHandler);
