import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import {
  getMyCurrentLocation,
  getStoreLocatorTypeFilter,
  removeApplyFilterStore,
  resetInputValue,
  searchStoreLocator,
  setLocatedStoreList
} from '@redux/actions/storeLocator';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import {ROADSHOW_LISTING_PAGE,STORE_LISTING_PAGE, STORE_LOCATOR} from '../../storeConstant';
import StoreLocatorFilterIcon from './StoreLocatorFilterIcon';
import StoreLocatorSearch from './StoreLocatorSearch';

const StoreLocatorBar = (props) => {
  const { dataFromLayout } = props;
  const dispatch = useDispatch();
  const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');
  // const [selectedTab, setSelectedTab] = useState(dataFromLayout['All Label']?.value);
  const [selectedTab, setSelectedTab] = useState(window.location.hash.substr(1) || 'all');

  const [myLocation, setMyLocation] = useState({
    latitude: null,
    longitude: null
  });

  const isGA4 = Global.getIsGA4();

  const tabList = [
    {
      name: dataFromLayout['All Label'],
      token: 'all',
      link: dataFromLayout['Store Locator Link']
    },
    {
      name: dataFromLayout['Stores Label'],
      token: 'stores',
      link: dataFromLayout['Stores Link']
    },
    {
      name: dataFromLayout['Roadshows Label'],
      token: 'roadshows',
      link: dataFromLayout['Roadshows Link']
    }
  ];

  useEffect(() => {
    window.navigator.geolocation.getCurrentPosition(
      (position) => {
        setMyLocation({
          ...myLocation,
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      () => {
        setMyLocation({
          ...myLocation,
          latitude: null,
          longitude: null
        });
      },
      { maximumAge: 0, timeout: Infinity, enableHighAccuracy: true }
    );
  }, [props.storeLocatorList]);

  useEffect(() => {
    switch(templateName) {
    case STORE_LOCATOR:
      setSelectedTab('all');
      break;
  
    case STORE_LISTING_PAGE:
      setSelectedTab('stores');
      break;
  
    case ROADSHOW_LISTING_PAGE:
      setSelectedTab('roadshows');
      break;
    default:
    }
  }, [selectedTab]);

  useDidUpdateEffect(() => {
    if (myLocation.latitude) {
      // onSortDistanceStoreHandler();

      dispatch(getMyCurrentLocation(myLocation));
    }

    dispatch(getStoreLocatorTypeFilter(selectedTab));
  }, [myLocation]);

  const onUseMyLocationHandlerFunc = () => {
    if (myLocation.latitude) {
      let minDistance = Math.min.apply(
        Math,
        props.storeLocatorList.map((item) => onCalculateDistanceHandler(item))
      );

      let nearestStore = props.storeLocatorList.filter((item) => onCalculateDistanceHandler(item) === minDistance);

      props.onUseMyLocationHandler(nearestStore);

      setSelectedTab('all');

      onSortDistanceStoreHandler();
    } else {
      window.alert(`${dataFromLayout['Message Notification'].value}`);

      // onSortABCStoreHandler();

      dispatch(resetInputValue());
    }

    dispatch(removeApplyFilterStore());
  };

  const onCalculateDistanceHandler = (location) => {
    return Math.sqrt(Math.abs(Math.pow(location.Latitude - myLocation.latitude, 2) - Math.pow(location.Longitude - myLocation.longitude, 2)));
  };

  const onSortDistanceStoreHandler = () => {
    let temp = props.storeLocatorList.map((item) => {
      let param = Object.assign({}, item);

      param.distance = onCalculateDistanceHandler(item);

      return param;
    });

    temp.sort((a, b) => {
      if (a.distance < b.distance) {
        return -1;
      }
      if (a.distance > b.distance) {
        return 1;
      }
      if (a.distance === b.distance) {
        if (a.Name > b.Name) {
          return 1;
        } else {
          return -1;
        }
      }

      return 0;
    });

    dispatch(setLocatedStoreList(temp));

    dispatch(resetInputValue());
  };

  // const onSortABCStoreHandler = () => {
  //   let temp = props.storeLocatorList;

  //   [...temp].sort((a, b) => {
  //     return b.Name - a.Name;
  //   });

  //   dispatch(setLocatedStoreList(temp));
  // };

  const onTypeStoreFilterHandler = (data) => {
    roadshowInteractionGA4(data);

    setSelectedTab(data?.token);
    if (data?.token !== 'roadshows') {
      dispatch(getStoreLocatorTypeFilter(data?.token));
    }
  };

  const onSearchHandler = (inputValue) => {
    if (!inputValue.searchInput) {
      props.onResetMap();
    }

    setSelectedTab('all');

    dispatch(searchStoreLocator(inputValue));
  };

  // const isFilterStorePage = () => templateName !== STORE_LOCATOR;

  //New Logic ON/OFF GA, GA4
  const roadshowInteractionGA4 = (data) => {
    if (isGA4) {
      let params = {
        click_text: data?.name?.value,
        switch_from: selectedTab,
        switch_to: data?.name?.value
      };

      new GoogleAnalytic().gAnalytic4('event', 'roadshow_interaction', params);
    }
  };

  return (
    <>
      <div className='store-locator__content__list__search'>
        <div className='store-locator__search__input'>
          <StoreLocatorSearch dataFromLayout={dataFromLayout} onSearch={onSearchHandler} />
        </div>
        <div className='store-locator__search__btn'>
          <div className='store-locator__search__btn__item store-locator__search__btn__item--share' onClick={() => onUseMyLocationHandlerFunc()}>
            <span className='store-locator-icon store-locator-icon--share'></span>
          </div>
          <StoreLocatorFilterIcon dataFromLayout={props.dataFromLayout} />
        </div>
      </div>
      {
        // !isFilterStorePage() ? (
        <ul className='wellness-filter-tag__list store-locator__content__list__tag'>
          {tabList.map((item, index) => {
            return (
              item?.name?.value ?
                <li className='wellness-filter-tag__list__item-wrap' key={index} onClick={() => onTypeStoreFilterHandler(item)}>
                  {item.link ? 
                    <Link className={`wellness-filter-tag__list__item ${selectedTab === item.token && 'selected'}`} field={item.link}>
                      <Text field={item.name} />
                    </Link>
                    : 
                    <Text className={`wellness-filter-tag__list__item ${selectedTab === item.token && 'selected'}`} tag='span' field={item.name} />
                  }
                </li>
                : ''
            );
          })}
          {/* <li className='wellness-filter-tag__list__item-wrap'>
              <span className='wellness-filter-tag__list__item selected'>Stores</span>
            </li>
            <li className='wellness-filter-tag__list__item-wrap'>
              <span className='wellness-filter-tag__list__item'>Roadshow</span>
            </li> */}
        </ul>
        // ) : ''
      }
    </>
  );
};

StoreLocatorBar.propTypes = {
  dataFromLayout: PropTypes.object,
  storeLocatorList: PropTypes.array,
  onUseMyLocationHandler: PropTypes.func,
  onResetMap: PropTypes.func
};

const mapStateToProps = (state) => {
  return {
    storeLocatorList: state.storeLocatorReducer.allStoreReducer.storeLocatorList || []
  };
};

export default connect(mapStateToProps)(StoreLocatorBar);
