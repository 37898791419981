import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Fragment } from 'react';
import Helmet from 'react-helmet';
import { connect, useDispatch, useSelector } from 'react-redux';

import { behaviorBundleElements } from '@components/BundleElements/BundleElementsBehavior';
import { behaviorInstallment } from '@components/BundleElements/InstallmentBehavior';
import { isStickyHeader$ } from '@components/Product/VariantDetail/ProductDetailHeader/handleStickyHeaderService';
import NotifyMePopup from '@components/Product/VariantDetail/ProductDetailHeader/NotifyMePopup';
import ResponsiveImage from '@components/ResponsiveImage';
import CountdownTimerCampaign from '@components/Share/CountdownTimer/CountdownTimerCampaign';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import ModalComponent from '@components/Share/ModalComponent';
import LinkModelWithTitle from '@models/LinkModelWithTitle';
import { getNotifyStatusProduct, resetMessagePopupNotify } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import {Link, Placeholder, Text} from '@sitecore-jss/sitecore-jss-react';
import { LIST_CLASS_NAME__DESKTOP, LIST_CLASS_NAME__MOBILE, TEXT_COLOR_WHITE } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import ProductHeaderPrice from '../Product/VariantDetail/ProductDetailHeader/ProductHeaderPrice';
import BannerImgModel from './model/BannerImgModel';
import PromotionHeaderModel from './model/PromotionHeaderModel';

const PromotionHeader = (props) => {
  const [inputData, setInputData] = useState(null);
  const [extraLink, setExtraLink] = useState(new LinkModelWithTitle().getData({}));
  const [buyNowButtonLink, setBuyNowButtonLink] = useState(deepCopy(props.fields['Buy Now Button Link']));
  const [bannerImg, setBannerImg] = useState(null);
  const [isPromotionHeaderSticky, setIsPromotionHeaderSticky] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);
  const [buyNowBtn, setBuyNowBtn] = useState(null);
  const [isShowNotifyMe, setIsShowNotifyMe] = useState(false);
  const [scrollBundleEle, setScrollBundleEle] = useState(deepCopy(props.fields['CTA Button Link']));
  const isNotifySuccess = useSelector((state) => state.productReducer.notifyProductStatusReducer.isNotifySuccess);
  const dispatch = useDispatch();
  const bannerTextPositionDesktop = props.params['Desktop Text Position'] || '';
  const bannerTextPositionMobi = props.params['Mobile Text Position'] || '';
  const bannerTextColor = props.params['Text Color'] || '';
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const countdownFormatSettingObj = useSelector((state) => state.settingGlobalURLReducer.settingGlobalURL);
  const currentCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const [quickInfo, setQuickInfo] = useState(null);
  const [installment, setInstallment] = useState(null);
  const [sellingPrice, setSellingPrice] = useState(null);
  const [installmentCartLevel, setInstallmentCartLevel] = useState(null);
  const [installmentProductLevel, setInstallmentProductLevel] = useState(null);
  const [installmentCartLevelAndProductLevel, setInstallmentCartLevelAndProductLevel] = useState(null);
  const [couponCode, setCouponCode] = useState(null);
  const isGA4 = Global.getIsGA4();

  const classRenderDesktop = (data) => {
    return LIST_CLASS_NAME__DESKTOP[data] || '';
  };

  const classRenderMobile = (data) => {
    return LIST_CLASS_NAME__MOBILE[data] || '';
  };

  useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  useEffect(() => {
    if (scrollTop > 0) {
      setIsPromotionHeaderSticky(true);

      isStickyHeader$.next(true);
    } else {
      setIsPromotionHeaderSticky(false);

      isStickyHeader$.next(false);
    }
  }, [scrollTop]);

  useEffect(() => {
    const model = new PromotionHeaderModel();

    setInputData(model.getData(deepCopy(props.fields) || {}));

    const modelFields = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Extra Link'];

    setExtraLink(new LinkModelWithTitle().getData(modelFields?.fields || {}));
  }, []);

  useEffect(() => {
    const model = new BannerImgModel();

    setBannerImg(model.getData(deepCopy(SitecoreContextFactoryService.getValueContextRouteItem('fields')) || {}));
  }, []);

  useEffect(() => {
    let temp = deepCopy(buyNowButtonLink);
    const queryParams = new URLSearchParams(window.location.search);
    const categoryName = queryParams.get('CategoryName');

    temp.value.href = `${props.fields['Buy Now Button Link'].value.href}?bundlePageId=${SitecoreContextFactoryService.getValueContextRouteItem('itemId')}${
      queryParams?.get('cCode') ? '&cCode=' + queryParams?.get('cCode') : ''
    }&LstName=${categoryName || ''}`;

    setCouponCode(queryParams?.get('cCode'));

    setBuyNowButtonLink(temp);
  }, []);

  useEffect(() => {
    let temp = deepCopy(scrollBundleEle);

    temp.value.href = '#bundle-element';

    setScrollBundleEle(deepCopy(temp));
  }, []);

  useEffect(() => {
    const behaviorBundleElementsSubscription = behaviorBundleElements.subscribe((params) => {
      setQuickInfo(params);
    });

    return () => {
      behaviorBundleElementsSubscription.unsubscribe();
    };
  }, [behaviorBundleElements]);

  useEffect(() => {
    const behaviorInstallmentSubscription = behaviorInstallment.subscribe((params) => {
      setInstallment(params.installment);

      setSellingPrice({ ...params.sellingPrice });
    });

    return () => {
      behaviorInstallmentSubscription.unsubscribe();
    };
  }, [behaviorInstallment]);

  useDidUpdateEffect(() => {
    if (currentCart) {
      setInstallmentCartLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) => gateway?.SupportInstallment && gateway?.InstallmentCartLevel && !gateway?.InstallmentProductLevel && gateway?.InstallmentPeriods.length
          )
      );

      setInstallmentProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) => gateway?.SupportInstallment && gateway?.InstallmentProductLevel && !gateway?.InstallmentCartLevel && installment?.length
          )
      );

      setInstallmentCartLevelAndProductLevel(
        currentCart?.PaymentConfiguration?.Gateways.length &&
          currentCart?.PaymentConfiguration?.Gateways.filter(
            (gateway) => gateway?.SupportInstallment && gateway?.InstallmentProductLevel && gateway?.InstallmentCartLevel && installment?.length
          )
      );
    }
  }, [currentCart, installment, sellingPrice]);

  useEffect(() => {
    // NOW < START || (END !== DEFAULT && END < NOW)
    if (
      Date.parse(new Date(Date.now())) < Date.parse(SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value) ||
      (SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value !== '0001-01-01T00:00:00Z' &&
        SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value < Date.parse(new Date(Date.now()))) ||
      (SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value !== '0001-01-01T00:00:00.0000000Z' &&
        SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value < Date.parse(new Date(Date.now())))
    ) {
      setBuyNowBtn('getNotify');
    } else {
      props.variantStatus === 'outofstock' ? setBuyNowBtn('buyNow') : props.variantStatus === 'buyNow' ? setBuyNowBtn('buyNow') : setBuyNowBtn(props.variantStatus);
    }
  }, [props.variantStatus]);

  useEffect(()=>{
    window.sessionStorage.setItem('buyNowBtnStaticBundle', buyNowBtn);
  },[buyNowBtn]);

  const message = useMemo(() => {
    if (isNotifySuccess?.message) {
      if (isNotifySuccess.status) {
        return <p className='notify-success'>{isNotifySuccess.message}</p>;
      } else {
        return <p className='notify-faild'>{isNotifySuccess.message}</p>;
      }
    }

    return null;
  }, [isNotifySuccess]);

  const onClosePopupNotifyHandler = () => {
    setIsShowNotifyMe(false);

    dispatch(resetMessagePopupNotify());
  };

  const onSubmitNotifyHandler = (emailSubmit) => {
    dispatch(getNotifyStatusProduct({ payload: emailSubmit }));
  };

  const handleGa4SelectItem = (action) =>{
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      const indexGA4 = queryParams.get('IndexGA4');
      const categoryName = queryParams.get('CategoryName');
      const itemLstName = queryParams.get('LstName');

      let dataConvert = [];

      dataConvert.push(props.productDetailStaticBundleOverview);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(indexGA4, dataConvert, categoryName, pdpPromoCode, '', '', itemLstName)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }
  };

  return props.prices && inputData && buyNowButtonLink && bannerImg && countdownFormatSettingObj && buyNowBtn ? (
    <Fragment>
      {
        couponCode ? (
          <Helmet>
            <meta name='robots' content='noindex'/>
          </Helmet>
        ):(<></>)
      }
      <div
        className={`promotion-detail-bundle__header${
          isPromotionHeaderSticky ? ' promotion-detail-bundle__header-sticky' : ''
        } animated-slow fadeIn delay-100`}
      >
        <div className='container promotion-detail-bundle__header__wrap'>
          <div className='promotion-detail-bundle__header__text'>
            <CountdownTimerCampaign
              endDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value || ''}
              endText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Soon Message']?.value || ''}
              remainingDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Time Remaining']?.value || ''}
              startDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value || ''}
              startText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Soon Message']?.value || ''}
              validText={props.prices?.BundleName}
              wasEndText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Was End Message']?.value || ''}
              countdownFormatSetting={countdownFormatSettingObj?.['CountdownFormatSetting']}
              componentType='PromotionHeader'
            />
            <ProductHeaderPrice
              price={props?.prices}
              sellingPriceWithCurrency={props.prices.SellPrice?.Price}
              listPriceWithCurrency={props.prices.ListPrice?.Price}
              wrapperClassName='promotion-detail-bundle__header__text__price'
            />
          </div>
          <div className='promotion-detail-bundle__header__btn'>
            {buyNowBtn === 'getNotify' ? (
              <Text
                tag='span'
                className='promotion-detail-bundle__header__btn__item btn btn-primary'
                field={inputData['Get Notified Button Label']}
                onClick={() => setIsShowNotifyMe(true)}
              />
            ) : (
              <>
                {props.variantStatus === 'outofstock' ? (
                  <Text
                    tag='span'
                    className='promotion-detail-bundle__header__btn__item btn btn-primary disabled'
                    field={inputData['Buy Now Button Label']}
                  />
                ) : (
                  <Link field={buyNowButtonLink}>
                    {buyNowBtn === 'buyNow' ? (
                      <Text
                        tag='span'
                        className='promotion-detail-bundle__header__btn__item btn btn-primary'
                        field={inputData['Buy Now Button Label']}
                        onClick={() => handleGa4SelectItem('Buy Now')}
                      />
                    ) : (
                      <span className='promotion-detail-bundle__header__btn__item btn btn-primary'>{buyNowBtn}</span>
                    )}
                  </Link>
                )}
              </>
            )}
          </div>
        </div>
      </div>

      <Placeholder name='osim-product-main-top' rendering={props.rendering}/>

      <div className={bannerTextColor === TEXT_COLOR_WHITE ? 'promotion-detail-bundle__banner text-white' : 'promotion-detail-bundle__banner'}>
        <ResponsiveImage
          cssClassPicture='promotion-detail-bundle__banner__image'
          imageMobile={bannerImg['Mobile Banner Image']}
          srcSet={[ { mw: 768, image: bannerImg['Banner Image']} ]}
        />
        <div className='promotion-detail-bundle__banner__wrapper-contents'>
          <div
            className={
              'container content-position ' + classRenderMobile(bannerTextPositionMobi) + ' ' + classRenderDesktop(bannerTextPositionDesktop)
            }
          >
            <div className='promotion-detail-bundle__banner__contents content-wrapper'>
              <h2 className='promotion-detail-bundle__banner__contents__title'>{props.prices?.BundleName}</h2>
              {SitecoreContextFactoryService.getValueContextRouteItem('fields')['Page Description']?.value ? (
                <p className='promotion-detail-bundle__banner__contents__description'>
                  {SitecoreContextFactoryService.getValueContextRouteItem('fields')['Page Description']?.value}
                </p>
              ) : (
                <></>
              )}
              {!inputData['CTA Button Label'].value.length ? (
                <></>
              ) : (
                <div className='promotion-detail-bundle__banner__contents__btn'>
                  <Link className='promotion-detail-bundle__banner__contents__btn__item btn btn-outline-primary' field={scrollBundleEle}>
                    <Text tag='span' field={inputData['CTA Button Label']} />
                  </Link>
                </div>
              )}
              <CountdownTimerCampaign
                endDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Time']?.value || ''}
                endText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['End Soon Message']?.value || ''}
                remainingDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Time Remaining']?.value || ''}
                startDate={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Time']?.value || ''}
                startText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Start Soon Message']?.value || ''}
                validText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Valid Message']?.value || ''}
                wasEndText={SitecoreContextFactoryService.getValueContextRouteItem('fields')['Was End Message']?.value || ''}
                countdownFormatSetting={countdownFormatSettingObj?.['CountdownFormatSetting']}
                componentType='PromotionBanner'
              />
              {installmentCartLevel?.map((gateway) => {
                return (
                  <>
                    {(!gateway?.InstallmentMaxCartAmount && sellingPrice?.Amount >= gateway?.InstallmentMinCartAmount) || (gateway?.InstallmentMaxCartAmount && sellingPrice?.Amount >= gateway?.InstallmentMinCartAmount && sellingPrice?.Amount < gateway?.InstallmentMaxCartAmount) ? (
                      <span className='product-variant-info__content__installment' key={gateway?.Id}>
                        {gateway?.InstallmentProductLevelText.replace(
                          '{number_of_payments}',
                          gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period
                        )
                          .replace(
                            '${installment_amount}',
                            Math.round(
                              (sellingPrice?.Amount / gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) * 1000
                            ) / 1000
                          )
                          .replace('{logo}', '')}
                        {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                          <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                        ) : (
                          <></>
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                    {sellingPrice?.Amount < gateway?.InstallmentMinCartAmount ? (
                      <span className='product-variant-info__content__installment' key={gateway?.Id}>
                        {gateway?.InstallmentCartLevelText.replace('${amount}', gateway?.InstallmentMinCartAmount - sellingPrice?.Amount).replace(
                          '{logo}',
                          ''
                        )}
                        {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                          <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                        ) : (
                          <></>
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
              {installmentProductLevel?.map((gateway) => {
                return (
                  <span className='product-variant-info__content__installment' key={gateway?.Id}>
                    {gateway?.InstallmentProductLevelText.replace(
                      '{number_of_payments}',
                      installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]
                    )
                      .replace(
                        '${installment_amount}',
                        Math.round((sellingPrice?.Amount / installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) * 1000) / 1000
                      )
                      .replace('{logo}', '')}
                    {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                      <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                    ) : (
                      <></>
                    )}
                  </span>
                );
              })}
              {installmentCartLevelAndProductLevel?.map((gateway) => {
                return (
                  <>
                    {(!gateway?.InstallmentMaxCartAmount && sellingPrice?.Amount >= gateway?.InstallmentMinCartAmount) || (gateway?.InstallmentMaxCartAmount && sellingPrice?.Amount >= gateway?.InstallmentMinCartAmount && sellingPrice?.Amount < gateway?.InstallmentMaxCartAmount) ? (
                      <span className='product-variant-info__content__installment' key={gateway?.Id}>
                        {gateway?.InstallmentProductLevelText.replace(
                          '{number_of_payments}',
                          installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]
                        )
                          .replace(
                            '${installment_amount}',
                            Math.round((sellingPrice?.Amount / installment?.slice().sort((a, b) => a?.Period - b?.Period)?.[0]) * 1000) / 1000
                          )
                          .replace('{logo}', '')}
                        {gateway?.InstallmentProductLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                          <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                        ) : (
                          <></>
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                    {sellingPrice?.Amount < gateway?.InstallmentMinCartAmount ? (
                      <span className='product-variant-info__content__installment' key={gateway?.Id}>
                        {gateway?.InstallmentCartLevelText.replace('${amount}', gateway?.InstallmentMinCartAmount - sellingPrice?.Amount).replace(
                          '{logo}',
                          ''
                        )}
                        {gateway?.InstallmentCartLevelText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                          <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                        ) : (
                          <></>
                        )}
                      </span>
                    ) : (
                      <></>
                    )}
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <ModalComponent
        isShow={isShowNotifyMe}
        titlePopup={buyNowBtn === 'getNotify' ? <span>Get notified</span> : <span>Notify me</span>}
        onCloseHandler={() => onClosePopupNotifyHandler()}
      >
        <NotifyMePopup
          inputData={{ productId: SitecoreContextFactoryService.getValueContextRouteItem('itemId') }}
          notifyMeEvt={onSubmitNotifyHandler}
        >
          {message}
        </NotifyMePopup>
      </ModalComponent>
    </Fragment>
  ) : (
    <LoadingShimmer itemNumb={1}>
      {
        couponCode ? (
          <Helmet>
            <meta name='robots' content='noindex'/>
          </Helmet>
        ):(<></>)
      }
      <Fragment>
        <div className={`promotion-detail-bundle__header${isPromotionHeaderSticky ? ' promotion-detail-bundle__header-sticky' : ''} skeleton__item`}>
          <div className='container promotion-detail-bundle__header__wrap'>
            <div className='promotion-detail-bundle__header__text'></div>
            <div className='promotion-detail-bundle__header__btn'>
              <span className='promotion-detail-bundle__header__btn__item btn image-wrap'>
                <span className='image-line'></span>
              </span>
            </div>
          </div>
        </div>
        <div className='promotion-detail-bundle__banner skeleton__item'>
          <Loading></Loading>
        </div>
      </Fragment>
    </LoadingShimmer>
  );
};

PromotionHeader.propTypes = {
  fields: PropTypes.object,
  prices: PropTypes.object,
  variantStatus: PropTypes.string,
  params: PropTypes.object,
  productDetailStaticBundleOverview: PropTypes.object,
  rendering: PropTypes.object,
};

const mapStateToProps = (state) => ({
  prices: state.staticBundleDetailReducer?.prices || {},
  variantStatus: state.staticBundleDetailReducer?.variantStatus || '',
  productDetailStaticBundleOverview: state.productReducer.productDetailOverviewReducer.productDetailStaticBundleOverview,
});

export default connect(mapStateToProps)(PromotionHeader);
