import './PaymentProcessing.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Loading from '@components/Share/Loading';
import { getOrderPaymentStatus } from '@redux/actions/checkoutProcess';
import global from '@utils/global';

import PaymentProcessingModel from './model/PaymentProcessingModel';

const PaymentProcessing = ({ fields }) => {
  const orderStatus = useSelector((state) => state.checkoutProcesstReducer.paymentReducer.orderStatus);
  const dispatch = useDispatch();
  var timerID;
  const [layoutData, setLayoutData] = useState(null);

  const getOrderId = () => {
    const fullPath = window.location.href;
    const orderIdString = global.getQueryParams(fullPath, 'orderId') || global.getQueryParams(fullPath, 'ref') || '';

    return orderIdString.toLowerCase().replace(new RegExp(/b$/), '');
  };

  useEffect(() => {
    const model = new PaymentProcessingModel();

    setLayoutData(model.getData(fields));

    timerID = setInterval(() => getStatus(getOrderId()), 5000);
  }, []);

  useEffect(() => {
    if (orderStatus) {
      if (orderStatus.PaymentStatus === 'Successful' || orderStatus.PaymentStatus === 'Pending Balance Payment') {
        clearInterval(timerID);

        window.location.href = `/checkout/thankyou?orderId=${getOrderId()}`;
      } else if (orderStatus.PaymentStatus !== 'Waiting For Payment') {
        clearInterval(timerID);

        window.location.href = '/checkout/fail';
      }
    }
  }, [orderStatus]);

  const getStatus = (orderId) => {
    dispatch(getOrderPaymentStatus(orderId));
  };

  return (
    layoutData && (
      <div className='payment-processing'>
        <Loading></Loading>
        <h2 className='payment-processing__title'>{layoutData['Title'].value}</h2>
        <p className='payment-processing__description'>{layoutData['Message'].value}</p>
      </div>
    )
  );
};

PaymentProcessing.propTypes = {
  fields: PropTypes.any
};

export default PaymentProcessing;
