import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Not sent',
  'Sent',
  'Viewed by customer',
  'Completed',
  'Expired'
];
const LINK_ARR = [
  'Total sale orders link'
];

export default class DashboardLinkModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['Overview Element Type'] = new SitecoreTextModel();

    this['Product Demo Ed Label'] = new SitecoreTextModel();

    this['PDPA Lead Label'] = new SitecoreTextModel();

    this['Anonymous Lead Label'] = new SitecoreTextModel();

    this['Apointments Label'] = new SitecoreTextModel();

    this['Existing Customer Label'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Total Appointments Link'] = new SitecoreLinkModel();

    this['Total Walk In Link'] = new SitecoreLinkModel();

    this['Lead Managements Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Overview Element Type')) {
      this['Overview Element Type'].getData(dataSource['Overview Element Type']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Demo Ed Label')) {
      this['Product Demo Ed Label'].getData(dataSource['Product Demo Ed Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PDPA Lead Label')) {
      this['PDPA Lead Label'].getData(dataSource['PDPA Lead Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Anonymous Lead Label')) {
      this['Anonymous Lead Label'].getData(dataSource['Anonymous Lead Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Apointments Label')) {
      this['Apointments Label'].getData(dataSource['Apointments Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Existing Customer Label')) {
      this['Existing Customer Label'].getData(dataSource['Existing Customer Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total Appointments Link')) {
      this['Total Appointments Link'].getData(dataSource['Total Appointments Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total Walk In Link')) {
      this['Total Walk In Link'].getData(dataSource['Total Walk In Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Lead Managements Link')) {
      this['Lead Managements Link'].getData(dataSource['Lead Managements Link']);
    }

    return this;
  }
}
