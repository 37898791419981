const stringArr = [
  'Catalog',
  'Currency',
  'Description',
  'DisplayName',
  'ItemId',
  'ListPrice',
  'Price',
  'ProductId',
  'ProductOverview',
  'ProductPageId',
  'ProductPageUrl',
  'SellingPrice'
];

export default class AdditionalOptionsProductsModel {
  constructor() {
    stringArr.map((item) => {
      this[item] = '';
    });

    this.ImageURLs = [];

    this.IsChoseAdditionalOption = false;
  }
  getData(dataSource) {
    stringArr.map((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ImageURLs')) {
      this['ImageURLs'] = dataSource['ImageURLs'] ?? this['ImageURLs'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsChoseAdditionalOption')) {
      this['IsChoseAdditionalOption'] = dataSource['IsChoseAdditionalOption'] ?? this['IsChoseAdditionalOption'];
    }

    return this;
  }
}
