import './ProductInstallmentCalculate.scss';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import * as Yup from 'yup';

import Message from '@components/Share/Message';
import {productStartTrackingGoal} from '@redux/actions/trackingGoal/productTracking/index';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ProductInstallmentCalculateModel from './model/ProductInstallmentCalculateModel';

const PRODUCT_INSTALLMENT_CALCULATE_ID = 'ProductInstallmentCalculate';

const ProductInstallmentCalculate = ({ fields }) => {
// const ProductInstallmentCalculate = ({ fields, params }) => {
  const dispatch = useDispatch();
  // const componentId = (params && params['Component Id']) || '';
  const [inputData, setInputData] = useState(null);
  const [installmentList, setInstallmentList] = useState([]);
  const [selectedInstallment, setSelectedInstallment] = useState([]);
  const [valueCalculated, setValueCalculated] = useState('S$0.00');
  const [showTable, setShowTable] = useState(false);
  const objMessages = useSelector(state => state.getMessageReducer?.objMessages);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    const model = new ProductInstallmentCalculateModel();

    setInputData(model.getData(deepCopy(fields)));
    const array = [];
    const installmentArray = fields?.['Installment Time'];
    const timeText = fields?.['Time Text'] ? fields?.['Time Text']?.value : 'Month';

    if (installmentArray) {
      installmentArray.forEach((item) => {
        const obj = {
          value: item,
          label: `${item} ${timeText}`
        };

        array.push(obj);
      });

      setInstallmentList(array.sort((a, b) => a.value - b.value));
    }

    let showTableCalculate = SitecoreContextFactoryService.getValueContextRouteItem('fields')['Display Instalment Plan Table']?.value || false;

    setShowTable(showTableCalculate);
  }, []);

  const onApplyAllFilter = () => {
    dispatch(productStartTrackingGoal(
      {
        GoalId: '{6EE3F21C-D52B-42B4-815B-BE2F0020BDDB}',
        ProductId: `{${SitecoreContextFactoryService.getValueContextItem('itemId')}}`,
        UseTrackerAPI: false
      }
    ));

    if (selectedInstallment && formik.values.inputPrice) {
      const calculated = Number(formik.values.inputPrice) / Number(selectedInstallment.value);

      setValueCalculated(`S$${calculated.toFixed(2)}`);
    }
  };

  const onClearFilterHandler = () => {
    formik.resetForm();

    setSelectedInstallment(null);

    setValueCalculated('S$0.00');
  };

  const formik = useFormik({
    initialValues: {
      inputPrice: ''
    },
    validationSchema: Yup.object({
      inputPrice: Yup.string().required(<Message messageCode='Msg_001.3' />)
    }),
    validateOnMount: true
  });

  const isActive = useMemo(() => (location?.hash || '') === `#${PRODUCT_INSTALLMENT_CALCULATE_ID}`, [location?.hash]);

  const handleCloseModal = useCallback((event) => {
    event.preventDefault();

    history.push({ hash: '' });
  }, []);

  return inputData && isActive ? (
    <div className='fake-modal' id={PRODUCT_INSTALLMENT_CALCULATE_ID}>
      <div className='installment'>
        {showTable ? (
          <div className='installment__title'>{inputData['Product Instalment Plan Title']}</div>
        ) : (
          <div className='installment__title'>{inputData['Title'].value}</div>
        )}
        <a href='#' className='osim-popup__close-btn' onClick={handleCloseModal}>
          <i className='icon-close'></i>
        </a>
        {showTable ? (
          <div className='installment__table'>
            {inputData['Instalment Plan'].map((item, ind) => {
              return (
                <div className='installment__table__record' key={ind}>
                  <div className='installment__table__record__item installment__table__record__item--name'>
                    <span>{`${item.Month}${inputData['Month Text']} ${item.InterestRate}${inputData['Interest Text']}`}</span>
                  </div>
                  <div className='installment__table__record__item installment__table__record__item--interest-rate'>
                    <span>{item.MonthlyInstalment}</span>
                  </div>
                  <div className='installment__table__record__item installment__table__record__item--bank-list'>
                    <span>{item.BankList}</span>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            <form onSubmit={formik.handleSubmit} className='notify-me__form installment__wrap'>
              <Text className='installment__description' field={inputData['Description']}></Text>
              <div className='installment__form'>
                <div className='form-group'>
                  <input
                    type='text'
                    className='form-control form-control-lg'
                    name='inputPrice'
                    value={formik.values.inputPrice}
                    onChange={(e) => {
                      e.preventDefault();
                      const { value } = e.target;
                      if (!isNaN(value)) {
                        formik.setFieldValue('inputPrice', value);
                      }
                    }}
                    placeholder={inputData['Advance Placeholder'].value}
                  />
                </div>
                <div className='form-group'>
                  <Select
                    value={selectedInstallment}
                    options={installmentList}
                    name='select'
                    placeholder='Term'
                    className='customization-dropdown'
                    classNamePrefix='customization-dropdown'
                    onChange={(option) => setSelectedInstallment(option)}
                    noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
                  />
                </div>
                <h4 className='installment__form__label'>
                  <Text field={inputData['Monthly Payment Label']} />
                </h4>
                <div className='form-group'>
                  <input type='text' id='calculate' className='form-control form-control-lg' readOnly value={valueCalculated}></input>
                </div>
              </div>
              <RichText field={inputData['More Infomation']} />
            </form>
            <div className='installment__footer text-center'>
              <button className='btn btn-outline-primary' onClick={() => onApplyAllFilter()}>
                {inputData['Apply Filters Lable'].value}
              </button>
              <span className='btn-clear-filter' onClick={onClearFilterHandler}>
                {inputData['Clear Filters Lable'].value}
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

ProductInstallmentCalculate.propTypes = {
  fields: PropTypes.any,
  params: PropTypes.any
};

export default ProductInstallmentCalculate;
