import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { addNewAddress } from '@redux/actions/checkoutProcess';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { MY_SITE_NAME } from '@utils/constant';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';

import InfoAddress from '../../InfoAddress';

const ChangeShippingAddress = (props) => {
  const { userAddressList, dataSources } = props;
  const dispatch = useDispatch();
  const [externalIdCurrent, setExternalId] = useState(userAddressList.find((item) => item.IsPrimary)?.ExternalId);
  const isLoading = useSelector(state => state.checkoutProcesstReducer.addressListReducer.isLoading);
  const isAtMYSite = useIsAtSiteName(MY_SITE_NAME);

  const onHandlerSeclectShiping = (externalId) => {
    const addressCurrent = userAddressList.find((item) => item.ExternalId === externalId);

    if (addressCurrent) {
      const { MYSitePhoneNumber, ...restData } = addressCurrent;
      if (isAtMYSite) restData.PhoneNumber = MYSitePhoneNumber;

      dispatch(
        addNewAddress({
          ...restData,
          IsPrimary: true,
          FullAddress: null
        })
      );
    }
  };

  return userAddressList ? (
    <div className='my-address'>
      <div className='my-address__list'>
        <div className='row'>
          {userAddressList.map((address) => (
            <div className='col-md-6 my-address__item' key={address.ExternalId}>
              <div
                className={address.ExternalId === externalIdCurrent ? 'select-address selected' : 'select-address'}
                onClick={() => setExternalId(address.ExternalId)}
              >
                <h5 className='select-address__type'>{address.AddressName}</h5>
                <p className='select-address__full-name'>{address.FirstName} {address.LastName}</p>
                <InfoAddress dataAddresses={address} dataSources={dataSources} />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className='my-address__btn text-center'>
        <button className='btn btn-outline-primary' onClick={() => onHandlerSeclectShiping(externalIdCurrent)}>
          <Text field={dataSources['PopUp Set As Default Button Text']} />
        </button>
      </div>
      {isLoading && <LoadingMask/>}
    </div>
  ) : (
    ''
  );
};

ChangeShippingAddress.propTypes = {
  userAddressList: PropTypes.array,
  dataSources: PropTypes.object
};

export default ChangeShippingAddress;
