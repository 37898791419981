import React, { useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import ChanceToWinPopup from '@components/SpinAndWin/ChanceToWinPopup';
import { getChanceToWin } from '@redux/actions/spinAndWin';

import { SpinWinBannerContext } from '../Spin&WinBanner';

const PrizesIcon = () => {
  const dispatch = useDispatch();
  const luckyDrawReducer = useSelector((state) => state.spinAndWinReducer.getAvailableLuckyDrawReducer);
  const getChanceToWinReducer = useSelector((state) => state.spinAndWinReducer.getChanceToWinReducer);
  const [showPopup, setShowPopup] = useState(false);

  const { layoutData } = useContext(SpinWinBannerContext);
  const couponList = getChanceToWinReducer?.chanceToWinData;
  const luckyDrawId = luckyDrawReducer?.luckyDrawData?.Id;

  const onClosePopupHandler = () => {
    setShowPopup(false);
  };

  const onShowPopupHandler = () => {
    setShowPopup(true);

    dispatch(
      getChanceToWin({
        luckyDrawId: luckyDrawId
      })
    );
  };

  return (
    <>
      <div className='spin-wheel-icon spin-wheel-icon--prize' onClick={onShowPopupHandler}></div>
      <ModalComponent isShow={showPopup} onCloseHandler={onClosePopupHandler} titlePopup={layoutData?.['Chance To Win Title']?.value}>
        <ChanceToWinPopup couponList={couponList} />
      </ModalComponent>
    </>
  );
};

export default PrizesIcon;
