import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError,map, switchMap} from 'rxjs/operators';

import * as actions from '@redux/actions/myAccount';
import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';
import { getAsObservable,postAsObservable } from '@services/genericService';

import * as config from './config';

export const newsletterSubscribeEpic = (action$) =>
  action$.pipe(
    ofType(myAccountTypes.SUBSCRIBE_LETTER),
    switchMap((action) => {
      return postAsObservable(config.ACTION_SUBSCRIBE, action.payload.params).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.getSubscribeSuccess(action.payload.params.isSubscribe);
          } else {
            return actions.getSubscribeFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.getSubscribeFailed(error.message || 'Failed')))
      );
    })
  );

export const getStatusNewsletterSubscribeEpic = (action$) =>
  action$.pipe(
    ofType(myAccountTypes.GET_STATUS_SUBSCRIBE_LETTER),
    switchMap((action) => {
      return getAsObservable(config.ACTION_GET_STATUS_SUBSCRIBE, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.getStatusSubscribeSuccess(res.data?.Data?.Status);
          } else {
            return actions.getStatusSubscribeFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.getStatusSubscribeFailed(error.message || 'Failed')))
      );
    })
  );