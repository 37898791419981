import produce from 'immer';

import {
  UPDATE_FREE_GIFT_FAILED,
  UPDATE_FREE_GIFT_START,
  UPDATE_FREE_GIFT_SUCCESS
} from '../../actions/updateFreeGiftToCart/updateFreeGiftToCartTypes';

const initState = {
  isLoading: false,
  isUpdateFreeGiftSuccess: false,
  error: null
};

const updateFreeGiftToCartReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case UPDATE_FREE_GIFT_START:
      draft.isLoading = true;

      draft.isUpdateFreeGiftSuccess = false;
      break;

    case UPDATE_FREE_GIFT_SUCCESS:
      // draft.data = action.payload;
      draft.isUpdateFreeGiftSuccess = true;

      draft.isLoading = false;
      break;

    case UPDATE_FREE_GIFT_FAILED:
      draft.isLoading = false;

      draft.isUpdateFreeGiftSuccess = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default updateFreeGiftToCartReducer;
