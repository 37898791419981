import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, RichText } from '@sitecore-jss/sitecore-jss-react';

const ContactUsIconListItem = ({data}) => {

  return data.fields.isDisplay ? (
    <div className='col-md-6 col-xl-4'>
      <div className='contact-icon-list__item'>
        <div className='contact-icon-list__item__image'>
          {/* <img src={require('../../../../assets/Osim-AI-icon.png')} alt='Icon'/> */}
          <Image media={data.fields.Image}/>
        </div>
        <div className='contact-icon-list__item__text'>
          {/* <h4 className='contact-icon-list__item__text__title'>Whatsapp Us</h4>
          <a className='contact-icon-list__item__text__link' href='#'>+65 9011 1902</a> */}
          <RichText field={data.fields.Title} />
        </div>
      </div>
    </div>
  ) : null;
};

ContactUsIconListItem.propTypes = {
  data: PropTypes.any
};

export default ContactUsIconListItem;
