import Proptypes from 'prop-types';
import React, { Fragment } from 'react';

import ToggleWisList from '@components/Share/ToggleWisList';

import BundleBOLabels from './BundleBOLabels';

const BundleBOImages = (props) => {
  const { productId, variantActive } = props;
  
  return variantActive ? (
    <Fragment>
      <div className='bo-product-image'>
        <div className='list-tags'>
          {variantActive.Labels.length ? (
            variantActive.Labels.map((item, index) => {
              if (index < 4) {
                return <BundleBOLabels key={index} data={item} />;
              }
            })
          ) : (
            <></>
          )}
        </div>
        <ToggleWisList
          dataProduct={{ProductId: productId , VariantId: variantActive.VariantId}}
        />
        <div className='bo-product-image__picture'>
          <img src={`${variantActive.Images[0]}`} alt='Product Image'></img>
        </div>
      </div>
    </Fragment>
  ) : null;
};

BundleBOImages.propTypes = {
  dataFields: Proptypes.any,
  productId: Proptypes.any,
  variantActive: Proptypes.any,
};

export default BundleBOImages;