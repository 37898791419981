export default class StoreFilterAreaListModel {
  constructor() {
    this['Id'] = '';

    this['AreaName'] = '';

    this['AreaCode'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ? dataSource['Id'] : this['Id'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AreaName')) {
      this['AreaName'] = dataSource['AreaName'] ? dataSource['AreaName'] : this['AreaName'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'AreaCode')) {
      this['AreaCode'] = dataSource['AreaCode'] ? dataSource['AreaCode'] : this['AreaCode'];
    }

    return this;
  }
}
