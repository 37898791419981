export const DRAFT_STATUS_KEY = 0;

export const ACTIVE_STATUS_KEY = 1;

export const VIEWED_STATUS_KEY = 2;

export const COMPLETED_STATUS_KEY = 3;

export const EXPIRED_STATUS_KEY = 4;

export const DELETED_STATUS_KEY = 5;
