import SitecoreTextModel from '@models/SitecoreTextModel';

export default class AddressPopupModel {
  constructor() {
    this['PopUp Add New Address Button Text'] = new SitecoreTextModel();

    this['PopUp Add New Address Label'] = new SitecoreTextModel();

    this['PopUp Address Line 1 Label'] = new SitecoreTextModel();

    this['PopUp Address Line 2 Label'] = new SitecoreTextModel();

    this['PopUp Address Name Label'] = new SitecoreTextModel();

    this['PopUp Area Selection Label'] = new SitecoreTextModel();

    this['PopUp City Selection Label'] = new SitecoreTextModel();

    this['PopUp Full Name Label'] = new SitecoreTextModel();
    
    this['PopUp Province Selection Label'] = new SitecoreTextModel();

    this['PopUp Use this for Billing Address Label'] = new SitecoreTextModel();

    this['PopUp Zip Portal Code Label'] = new SitecoreTextModel();

    this['Area Selection Label'] = new SitecoreTextModel();
    
    this['Province Selection Label'] = new SitecoreTextModel();

    this['City Selection Label'] = new SitecoreTextModel();

    this['Zip Code Label'] = new SitecoreTextModel();

    this['PopUp Edit Address Label'] = new SitecoreTextModel();

    this['PopUp Update Address Button Text'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Update Address Button Text')) {
      this['PopUp Update Address Button Text'].getData(dataSource['PopUp Update Address Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Edit Address Label')) {
      this['PopUp Edit Address Label'].getData(dataSource['PopUp Edit Address Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Add New Address Button Text')) {
      this['PopUp Add New Address Button Text'].getData(dataSource['PopUp Add New Address Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Add New Address Label')) {
      this['PopUp Add New Address Label'].getData(dataSource['PopUp Add New Address Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Address Line 1 Label')) {
      this['PopUp Address Line 1 Label'].getData(dataSource['PopUp Address Line 1 Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Address Line 2 Label')) {
      this['PopUp Address Line 2 Label'].getData(dataSource['PopUp Address Line 2 Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Address Name Label')) {
      this['PopUp Address Name Label'].getData(dataSource['PopUp Address Name Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Area Selection Label')) {
      this['PopUp Area Selection Label'].getData(dataSource['PopUp Area Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp City Selection Label')) {
      this['PopUp City Selection Label'].getData(dataSource['PopUp City Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Full Name Label')) {
      this['PopUp Full Name Label'].getData(dataSource['PopUp Full Name Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Province Selection Label')) {
      this['PopUp Province Selection Label'].getData(dataSource['PopUp Province Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Use this for Billing Address Label')) {
      this['PopUp Use this for Billing Address Label'].getData(dataSource['PopUp Use this for Billing Address Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PopUp Zip Portal Code Label')) {
      this['PopUp Zip Portal Code Label'].getData(dataSource['PopUp Zip Portal Code Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Area Selection Label')) {
      this['Area Selection Label'].getData(dataSource['Area Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Province Selection Label')) {
      this['Province Selection Label'].getData(dataSource['Province Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'City Selection Label')) {
      this['City Selection Label'].getData(dataSource['City Selection Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Zip Code Label')) {
      this['Zip Code Label'].getData(dataSource['Zip Code Label']);
    }

    return this;
  }
}
