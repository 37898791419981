import { useFormik } from 'formik';
import PropsType from 'prop-types';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import { subscribeMailStart } from '@redux/actions/subscribeNewsletter';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const NewsletterForm = ({ fieldData, setShowPopupBanner }) => {
  const dispatch = useDispatch();
  const { isLoading, subscribeSuccess } = useSelector((state) => state.subscribeNewsletterReducer);

  const formik = useFormik({
    initialValues: {
      emailInput: ''
    },
    validationSchema: Yup.object({
      emailInput: Yup.string()
        .email(fieldData?.['Empty Email Validation Message'].value)
        .required(fieldData?.['Empty Email Validation Message'].value)
        .trim()
    }),
    onSubmit: (values) => {
      if (values.emailInput) {
        const subscribeData = {
          listId: `{${fieldData['Contact List'].id.replace(/[{}]/g, '')}}`,
          email: values.emailInput
        };

        dispatch(subscribeMailStart(subscribeData));
      }
    },
    validateOnMount: true
  });

  const handleClosePopup = () => {
    setShowPopupBanner(false);

    window.sessionStorage.setItem('isShowNewsletterPopupNextTime', JSON.stringify(false));
  };

  return (
    <div className='newsletter-popup__container'>
      <div className='newsletter-popup__wrapper'>
        <RichText tag='h6' field={fieldData['Title']} className='newsletter-popup__title' />
        <RichText tag='p' field={fieldData['Description']} className='newsletter-popup__description' />
        <form onSubmit={formik.handleSubmit} className='osim-account-form__form newsletter-popup__form'>
          <div
            className={Global.renderDynamicClass(
              formik.errors.emailInput && formik.touched.emailInput,
              'form-group email-input',
              'input-error-validate'
            )}
          >
            {subscribeSuccess ? <Text tag='span' className='error-validate subscribe-success' field={fieldData['Thank You Message']} /> : <></>}
            <input
              type='text'
              id='emailInput'
              name='emailInput'
              value={formik.values.emailInput}
              onChange={formik.handleChange}
              className={Global.renderDynamicClass(formik.values['emailLogin'], 'form-control form-control-lg', 'input-valid')}
              placeholder={fieldData['Email Placeholder Text'].value}
            />
            {formik.errors.emailInput && formik.touched.emailInput && <span className='error-validate'>{formik.errors.emailInput}</span>}
          </div>
          <div className='form-group osim-account-form__form__btn'>
            <button type='submit' className='osim-account-form__form__btn__item btn btn-primary'>
              <Text field={fieldData['Subscribe Button Label']} />
            </button>
          </div>
          <div className='form-group'>
            <Text tag='a' field={fieldData['No Thanks Button Label']} onClick={handleClosePopup} />
          </div>
        </form>
      </div>
      {isLoading ? <LoadingMask /> : <></>}
    </div>
  );
};

NewsletterForm.propTypes = {
  fieldData: PropsType.object,
  setShowPopupBanner: PropsType.any
};

export default NewsletterForm;
