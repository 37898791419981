import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Button Text', 'Description Title', 'Title', 'Link Text', 'IsButtonPrimary'];
const LINK_ARR = ['Button Url', 'Link Url'];
const IMAGE_ARR = ['Image'];

export default class SimpleCTAModel {
  constructor() {
    TEXT_ARR.forEach((item) => (this[item] = new SitecoreTextModel()));

    LINK_ARR.forEach((item) => (this[item] = new SitecoreLinkModel()));

    IMAGE_ARR.forEach((item) => (this[item] = new SitecoreImageModel()));
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
