import { bool, exact, object, string } from 'prop-types';
import React, { useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';

const OrderDetail = ({ layoutData, orderDetail, cartData, isOrderSent }) => {
  const isAtHKSite = useIsAtSiteName(HK_SITE_NAME);
  const isAtTWSite = useIsAtSiteName(TW_SITE_NAME);

  const subtotalText = useMemo(() => {
    return isOrderSent ? layoutData?.SaleOrder?.SaleOrderSummary?.Subtotal : orderDetail?.Subtotal;
  }, [layoutData]);

  const deliveryFeeText = useMemo(() => {
    return isOrderSent ? layoutData?.SaleOrder?.SaleOrderSummary?.DeliveryFee : orderDetail?.DeliveryFee;
  }, [layoutData]);

  const discountText = useMemo(() => {
    return isOrderSent ? layoutData?.SaleOrder?.SaleOrderSummary?.Discount : orderDetail?.Discount;
  }, [layoutData]);

  const salesTaxText = useMemo(() => {
    return isOrderSent ? layoutData?.SaleOrder?.SaleOrderSummary?.SalesTax : orderDetail?.SalesTax;
  }, [layoutData]);

  const totalText = useMemo(() => {
    return isOrderSent ? layoutData?.SaleOrder?.SaleOrderSummary?.Total : orderDetail?.Total;
  }, [layoutData, cartData]);

  const emailArr = useMemo(() => {
    return layoutData?.SaleOrder?.Cart?.SendSaleOrderInfo?.EmailAddresses;
  }, [layoutData]);

  const smsArr = useMemo(() => {
    return layoutData?.SaleOrder?.Cart?.SendSaleOrderInfo?.PhoneNumbers;
  }, [layoutData]);
  
  const isShowTaxText = useMemo(() => !isAtHKSite && !isAtTWSite, [isAtHKSite, isAtTWSite]);

  return (
    <div className='order-detail'>
      {isOrderSent && (
        <div className='order-detail__box'>
          <Text tag='p' field={layoutData?.['Messaging platform']} className='order-detail__title' />
          {emailArr?.length > 0 &&
            emailArr.map((item, index) => {
              return (
                <div key={index} className='payment-detail__item__line order-detail__item-line'>
                  <Text tag='span' field={layoutData?.Email} className='payment-detail__item__line__label order-detail__item-line__label' />
                  <span className='payment-detail__item__line__value order-detail__item-line__value'>{item}</span>
                </div>
              );
            })}
          {smsArr?.length > 0 &&
            smsArr.map((item, index) => {
              return (
                <div key={index} className='payment-detail__item__line order-detail__item-line'>
                  <Text tag='span' field={layoutData?.Sms} className='payment-detail__item__line__label order-detail__item-line__label' />
                  <span className='payment-detail__item__line__value order-detail__item-line__value'>{item}</span>
                </div>
              );
            })}
        </div>
      )}
      <Text tag='p' field={layoutData?.['Summary']} className='order-detail__title' />
      <div className='payment-detail__item__line order-detail__item-line'>
        <Text tag='span' field={layoutData?.['Subtotal']} className='payment-detail__item__line__label order-detail__item-line__label' />
        <span className='payment-detail__item__line__value order-detail__item-line__value'>{subtotalText}</span>
      </div>
      <div className='payment-detail__item__line order-detail__item-line'>
        <Text tag='span' field={layoutData?.['Delivery fee']} className='payment-detail__item__line__label order-detail__item-line__label' />
        <span className='payment-detail__item__line__value order-detail__item-line__value'>{deliveryFeeText}</span>
      </div>
      <div className='payment-detail__item__line order-detail__item-line'>
        <Text tag='span' field={layoutData?.['Discount']} className='payment-detail__item__line__label order-detail__item-line__label' />
        <span className='payment-detail__item__line__value order-detail__item-line__value'>{discountText}</span>
      </div>
      {isShowTaxText ? (
        <div className='payment-detail__item__line order-detail__item-line'>
          <Text tag='span' field={layoutData?.['Sales tax']} className='payment-detail__item__line__label order-detail__item-line__label' />
          <span className='payment-detail__item__line__value order-detail__item-line__value'>{salesTaxText}</span>
        </div>
      ) : (
        <></>
      )}
      <div className='payment-detail__total order-detail__total'>
        <Text tag='h4' field={layoutData?.['Subtotal']} className='payment-detail__total__label text-regular order-detail__total-label' />
        <h4 className='payment-detail__total__value text-regular order-detail__total-value'>{totalText}</h4>
      </div>
    </div>
  );
};

OrderDetail.propTypes = {
  layoutData: object,
  cartData: object,
  isOrderSent: bool,
  orderDetail: exact({
    DeliveryFee: string,
    Discount: string,
    SalesTax: string,
    Subtotal: string,
    Total: string
  })
};

export default OrderDetail;
