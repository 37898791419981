import produce from 'immer';

import BOVariantsModel from '@components/BuyingOptions/BOVariants/model/BOVariantsModel';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility.js';

const initState = {
  variantList: [],
  variantActiveID: [],
  variantActive: null,
  variantSelectedLength: 0,
  variantPropertyName : '',
  chooseVariantLabel : '',
  validationMessage : ''
};

function getVariantByID(variantID, variantArray) {
  return variantArray.find((item) => item?.VariantId?.toLowerCase() === variantID?.replace('%20', ' ')?.toLowerCase());
}

const BOVariantsReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let newVariantList = [];

    switch (action.type) {
    case buyingOptionsTypes.GET_BO_DATA_SUCCESS:

      if (action.payload.ProductSummary.Variants?.length) {
        action.payload.ProductSummary.Variants.map(element => {
          newVariantList.push(new BOVariantsModel().getData(deepCopy(element) || {}));
        });

        draft.variantList = deepCopy(newVariantList);
        
        draft.variantActiveID = [action.variantId || draft.variantList.filter(item => item.Stock.FinalStatus.isAvaiable === true)[0]?.VariantId || draft.variantList[0]?.VariantId];

        draft.variantActive = getVariantByID(draft.variantActiveID[0], draft.variantList);

        draft.variantPropertyName = action.payload.ProductSummary.VariantPropertyName;

        draft.chooseVariantLabel = action.payload.ProductSummary.ChooseVariantLabel;

        draft.validationMessage = action.payload.ProductSummary.ChooseVariantValidationMessage;
        
        draft.isDoneData = true;
      }

      break;

    case buyingOptionsTypes.ADD_CHOSEN_BO_VARIANT:

      draft.variantActiveID = [action.payload];

      draft.variantActive  = getVariantByID(action.payload, draft.variantList);

      draft.variantSelectedLength = draft.variantActiveID.length;

      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:
      if (action.filteredCartLine.length) {
        draft.variantActiveID = [action.filteredCartLine[0].VariantId];

        draft.variantActive  = getVariantByID(action.filteredCartLine[0].VariantId, draft.variantList);

        draft.variantSelectedLength = draft.variantActiveID.length;
      }

      break;

    case productTypes.GET_CURRENT_SO_CART_SUCCESS:
      if (action.filteredCartLine.length) {
        draft.variantActiveID = [action.filteredCartLine[0].VariantId];

        draft.variantActive  = getVariantByID(action.filteredCartLine[0].VariantId, draft.variantList);

        draft.variantSelectedLength = draft.variantActiveID.length;
      }

      break;
    }


    return draft;
  });

export default BOVariantsReducer;
