import { arrayOf, func, number, object, shape, string } from 'prop-types';
import React, { useEffect, useMemo, useRef } from 'react';
import Swiper from 'react-id-swiper';

import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import { useScheduleAppointmentContext } from '../../hooks/useScheduleAppointmentContext';

const ProductSelectSwiper = ({ label, productInfoData, initialSlide = 0, onSelectProduct }) => {
  const imageSwiperRef = useRef(null);
  const textSwiperRef = useRef(null);
  const { layoutData } = useScheduleAppointmentContext();

  const paramSwiperImage = useMemo(() => {
    return {
      initialSlide: initialSlide,
      pagination: false,
      slidesPerView: productInfoData?.length < 2 ? 1.25 : 2.25,
      centeredSlides: true,
      loop: true,
      breakpoints: {
        992: {
          slidesPerView: 3,
          spaceBetween: 76
        }
      }
    };
  }, [initialSlide]);

  const paramSwiperText = useMemo(() => {
    return {
      initialSlide: initialSlide,
      pagination: false,
      slidesPerView: productInfoData?.length < 3 ? productInfoData?.length : 3,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      renderPrevButton: () => <button onClick={handleSelectProduct} className='swiper-button-prev'></button>,
      renderNextButton: () => <button onClick={handleSelectProduct} className='swiper-button-next'></button>,
      centeredSlides: true,
      loop: true,
      slideToClickedSlide: true
    };
  }, [initialSlide]);

  useEffect(() => {
    const imageSwiper = imageSwiperRef.current?.swiper;
    const textSwiper = textSwiperRef.current?.swiper;

    if (imageSwiper?.controller && textSwiper?.controller) {
      const handleSlideChange = () => {
        onSelectProduct(productInfoData[imageSwiper.realIndex]?.productId);
      } ;

      imageSwiper.on('slideChange', handleSlideChange);

      imageSwiper.controller.control = textSwiper;

      textSwiper.controller.control = imageSwiper;
      
      return () => {
        imageSwiper.off('slideChange',handleSlideChange);
      };
    }
  }, []);

  useEffect(() => {
    const imageSwiper = imageSwiperRef.current?.swiper;
    if (imageSwiper && onSelectProduct) {
      onSelectProduct(productInfoData[imageSwiper.realIndex]?.productId);
    }
  }, [imageSwiperRef]);

  const handleSelectProduct = () => {
    const imageSwiper = imageSwiperRef.current?.swiper;
    if (imageSwiper && onSelectProduct) {
      onSelectProduct(productInfoData[imageSwiper.realIndex]?.productId);
    }
  };

  return (
    <div className='select-product container product-select-swiper'>
      <Text field={label} className='select-store__content__list__title' tag='h4' />
      <div className='select-product__swiper'>
        {productInfoData?.length ? (
          <Swiper {...paramSwiperImage} ref={imageSwiperRef}>
            {productInfoData.map((product, idx) => (
              <div key={idx} className='select-product__swiper__item'>
                <div className='select-product__swiper__item__image'>
                  <Image field={product.image} alt='booking-experience-select-tea-image' />
                </div>
              </div>
            ))}
          </Swiper>
        ) : (
          <></>
        )}
        <Text field={layoutData?.['You Are Selecting Text']} className='select-product__swiper__item__content__subtitle' tag='p' />
        {productInfoData?.length ? (
          <Swiper {...paramSwiperText} ref={textSwiperRef}>
            {productInfoData?.map((item, idx) => (
              <div key={idx} className='select-product__swiper__item'>
                <div className='select-product__swiper__item__content'>
                  <Text field={item.productName} className='select-product__swiper__item__content__title' tag='h5' />
                  <RichText field={item.description} className='select-product__swiper__item__content__desc' tag='p' />
                </div>
              </div>
            ))}
          </Swiper>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

ProductSelectSwiper.propTypes = {
  label: object,
  productInfoData: arrayOf(
    shape({
      productName: object,
      description: object,
      productId: string,
      image: object
    })
  ),
  initialSlide: number,
  onSelectProduct: func
};

export default ProductSelectSwiper;
