import { createContext, useContext } from 'react';

const initialContextValues = {
  productsData: [],
  setProductsData: () => {},
  layoutData: null
};

export const ProductSupportCTAContext = createContext(initialContextValues);

export const useProductSupportContext = () => {
  return useContext(ProductSupportCTAContext);
};
