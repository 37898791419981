import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import BooleanModel from './BooleanModel';

const TEXT_ARR = [
  'Countdown Date Mobile Unit Font Size',
  'Countdown Date Unit Font Size',
  'Countdown Timer Mobile Text Font Size',
  'Countdown Timer Text Font Size',
  'Countdown Title Mobile Text Font Size',
  'Countdown Title Text Font Size',
  'Countdown Date Unit Text Color Code',
  'Countdown Timer Text Color Code',
  'Countdown Title Text Color Code',
  'Countdown Title Text',
  'Countdown Days Text',
  'Countdown Hours Text',
  'Countdown Minutes Text',
  'Countdown Seconds Text',
  'Start Soon Message',
  'Alternative Start Soon Message',
  'End Soon Message',
  'Valid Message',
  'Was End Message',
  'Countdown Desktop Position',
  'Countdown Mobile Position',
  'Enable Countdown Timer',
  'Start Time',
  'End Time',
];

const IMG_ARR = ['Countdown Background Image'];

export default class PopupBannerModel {
  constructor() {
    this['Auto play'] = new BooleanModel();

    this['Desktop Image'] = new SitecoreImageModel();

    this['Enable audio'] = new BooleanModel();

    this['Mobile Image'] = new SitecoreImageModel();

    this['Video'] = new SitecoreLinkModel();

    this['Video Cover Image'] = new SitecoreImageModel();

    this['Vimeo Video ID'] = new SitecoreTextModel();

    this['Youtube Video ID'] = new SitecoreTextModel();

    this['Link'] = new SitecoreLinkModel();

    this['Max Time To See'] = new SitecoreTextModel();

    this['Banner ID'] = '';

    this['Banner Name'] = '';

    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    IMG_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Auto play')) {
      this['Auto play'].getData(dataSource['Auto play']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Desktop Image')) {
      this['Desktop Image'].getData(dataSource['Desktop Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Enable audio')) {
      this['Enable audio'].getData(dataSource['Enable audio']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Image')) {
      this['Mobile Image'].getData(dataSource['Mobile Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video')) {
      this['Video'].getData(dataSource['Video']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video Cover Image')) {
      this['Video Cover Image'].getData(dataSource['Video Cover Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Vimeo Video ID')) {
      this['Vimeo Video ID'].getData(dataSource['Vimeo Video ID']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Youtube Video ID')) {
      this['Youtube Video ID'].getData(dataSource['Youtube Video ID']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this['Link'].getData(dataSource['Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Max Time To See')) {
      this['Max Time To See'].getData(dataSource['Max Time To See']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner ID')) {
      this['Banner ID'] = dataSource['Banner ID'] ?? this['Banner ID'];
    }

    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMG_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Name')) {
      this['Banner Name'] = dataSource['Banner Name'] ?? this['Banner Name'];
    }

    return this;
  }
}
