export const STYLE_DEFAULT = {
  display: 'inline-block',
  background: 'linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)',
  color: '#fff',
  width: 200,
  paddingTop: 10,
  paddingBottom: 10,
  borderRadius: 2,
  border: '1px solid transparent',
  fontSize: 16,
  fontWeight: 'bold',
  fontFamily: '"proxima-nova", "Helvetica Neue", Arial, Helvetica, sans-serif'
};

export const RESPONSE_TYPE = 'code';

export const BUTTON_TEXT = 'Login with Wechat';

export const NAME_TAG = 'button';

export const TYPE = 'button';

export const LANGUAGE = 'en-us';

export const REDIRECT_DEFAULT = '/';

export const SCOPE_DEFAULT = 'snsapi_login';

export const API_AUTHENTICATION_WECHAT = 'https://open.weixin.qq.com/connect/qrconnect';