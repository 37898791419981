import './BalanceDetailModals.scss';

import { any, bool, func } from 'prop-types';
import React from 'react';
import {useDispatch} from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { Link,Text } from '@sitecore-jss/sitecore-jss-react';

import {removeBalanceCart} from '../../../../../redux/actions/myOrders';

const BalanceDetailModals = (props) => {
  const { isShow, onCloseHandler, onlayoutData, onExternalCartLineId } = props;
  const dispatch = useDispatch();

  const handleRemoveProduct = ()=> {
    const url = new URL(window.location.href);
    const orderId = url.searchParams.get('OrderId');

    dispatch(removeBalanceCart({
      orderId : orderId,
      lineIds: onExternalCartLineId
    }));
  };

  return (
    <ModalComponent isShow={isShow} onCloseHandler={onCloseHandler} titlePopup={onlayoutData?.['Popup Title']?.value}>
      <div className='balance-detail-modal'>
        <Text field={onlayoutData?.Messages} tag='p' className='balance-detail-modal__info'/>
        <div className='balance-detail-modal__button'>
          <button className='btn btn-outline-primary balance-detail-modal__button__yes' onClick={handleRemoveProduct}>
            <Text field={onlayoutData?.['Yes Button Label']} />
          </button>
        </div>
        <div className='balance-detail-modal__button'>
          <Link field={{href: '#'}} className='btn-link balance-detail-modal__button__no' onClick={onCloseHandler}>
            <Text field={onlayoutData?.['No Button Label']} />
          </Link>
        </div>
      </div>
    </ModalComponent>
  );
};

BalanceDetailModals.propTypes = {
  isShow: bool,
  onCloseHandler: func,
  onlayoutData: any,
  onExternalCartLineId: any
};

export default BalanceDetailModals;
