import { deepCopy } from '@utils/utility';

export const handleGenerateFunctionItemLabel = (string) => string?.replace(/([A-Z])/g, ' $1').trim();

export const handleGenerateFunctionValue = (functionValue) => functionValue || '-';

export const handleSortGeneralFunctionValue = (generalFunctionData) => {
  let generalFunction = [...deepCopy(generalFunctionData)];

  return generalFunction.sort((a, b) => (a.FunctionName > b.FunctionName ? 1 : a.FunctionName < b.FunctionName ? -1 : 0));
};

export const handleSortFunctionValue = (functionsData) => {
  let functions = [...deepCopy(functionsData)];

  return functions.sort((a, b) => (a.Id > b.Id ? 1 : a.Id < b.Id ? -1 : 0));
};

export const handleSortBenefitsValue = (functionsData) => {
  let functions = [...deepCopy(functionsData)];

  return functions.sort((a, b) => (a.Text > b.Text ? 1 : a.Text < b.Text ? -1 : 0));
};
