import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { updateBookingExperienceStart } from '@redux/actions/bookingExperience/updateBookingExperience';
import {
  updateBookingExperienceContactFailure,
  updateBookingExperienceContactSuccess
} from '@redux/actions/bookingExperience/updateBookingExperienceContact';
import { UPDATE_BOOKING_EXPERIENCE_CONTACT_START } from '@redux/actions/bookingExperience/updateBookingExperienceContact/types';
import { postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import { UPDATE_BOOKING_EXPERIENCE_CONTACT_API_ENDPOINT } from './config';

const updateBookingExperienceContactEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_BOOKING_EXPERIENCE_CONTACT_START),
    switchMap((action) => {
      const contactData = action.payload.contactData;

      const params = {
        ContactId: contactData.contactId,
        FirstName: contactData.firstName,
        LastName: contactData.lastName,
        DateOfBirth: contactData.dob,
        AllowSMS: contactData.allowSMS,
        AllowEmail: contactData.allowEmail,
        AllowDirectMail: contactData.allowDirectMail,
        MobileNumber: contactData.phoneNumber
      };

      return postAsObservable(UPDATE_BOOKING_EXPERIENCE_CONTACT_API_ENDPOINT, params).pipe(
        switchMap((res) => {
          if (res.data.StatusCode === 0) {
            return of(
              updateBookingExperienceContactSuccess(deepCopy(res.data.Result)),
              updateBookingExperienceStart(action.payload.updateBookingData)
            );
          } else {
            return of(updateBookingExperienceContactFailure(res.data || 'Failed'));
          }
        }),
        catchError((error) => of(updateBookingExperienceContactFailure(error || 'Failed')))
      );
    })
  );

export default updateBookingExperienceContactEpic;
