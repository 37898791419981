import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/myAccount';
import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';
import { postAsObservable } from '@services/genericService';

import * as config from './config';

export const verificationEmailEpic = (action$) =>
  action$.pipe(
    ofType(myAccountTypes.EMAIL_VALIDATION_CODE),
    switchMap((action) => {
      return postAsObservable(config.ACTION_EMAIL_VALIDATION_CODE, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.verificationCodeEmailSuccess(res.data);
          } else {
            return actions.verificationCodeEmailFailed(res.data?.Errors?.[0]);
          }
        })
      );
    })
  );