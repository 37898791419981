import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError,map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/searchBox/';
import * as searchBoxTypes from '@redux/actions/searchBox/searchBoxTypes';
import { postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as configs from './config';

const getSearchBoxEpic = (action$) =>
  action$.pipe(
    ofType(searchBoxTypes.GET_SEARCH_BOX_SUGGESTION_LIST),
    switchMap((action) => {
  
      const params = {
        searchTerm: action.searchTerm.value,
        resultNumber: configs.COMMON_RESULT_NUMB,
        itemType : configs.ITEM_TYPE_PRODUCT,
        dataSourcePath: action.searchTerm.dataSourcePath
      };

      return postAsObservable(configs.ACTION_AUTO_COMPLETE, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getSearchBoxSuggestionListSuccess(deepCopy(res.data) || {});
          } else {
            return actions.getSearchBoxSuggestionListFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getSearchBoxSuggestionListFailed(error || 'Failed')))
      );
    })
  );

export default getSearchBoxEpic;
