import { combineEpics } from 'redux-observable';

import {getActivateOrdersEpic} from './activateOrders';
import { getAllOrderEpic } from './allOrders';
import {getBalanceByIdEpic} from './balanceById';
import {getLastestOrdersdEpic} from './lastestOrder';
import {getOrderByIdEpic } from './orderById';
import {getPastOrdersEpic} from './pastOrders';
import {removeBalanceCart} from './removeBalanceCart';

const myOrderEpic = combineEpics(
  getAllOrderEpic,
  getOrderByIdEpic,
  getActivateOrdersEpic,
  getPastOrdersEpic,
  getLastestOrdersdEpic,
  getBalanceByIdEpic,
  removeBalanceCart
);

export default myOrderEpic;
