import { deepCopy } from '@utils/utility';

import IncludedProductLabelItemModel from './IncludedProductLabelItemModel';
import IncludedProductStockModel from './IncludedProductStockModel';
import IncludedProductVariantModel from './IncludedProductVariantModel';
import PriceModel from './PriceModel';

export default class IncludedProductModel {
  constructor() {
    this['CatalogName'] = '';

    this['CategoryName'] = '';

    this['Description'] = '';

    this['Id'] = '';

    this['Image'] = '';

    this['Labels'] = [];

    this['Price'] = new PriceModel();

    this['ProductId'] = '';

    this['ProductName'] = '';

    this['ProductOverview'] = '';

    this['Quantity'] = 0;

    this['RepresentativeVariantId'] = '';

    this['Stock'] = new IncludedProductStockModel();

    this['Url'] = '';

    this['Variants'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CatalogName')) {
      this['CatalogName'] = dataSource['CatalogName'] ? dataSource['CatalogName'] : this['CatalogName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CategoryName')) {
      this['CategoryName'] = dataSource['CategoryName'] ? dataSource['CategoryName'] : this['CategoryName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'] = dataSource['Description'] ? dataSource['Description'] : this['Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ? dataSource['Id'] : this['Id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'] = dataSource['Image'] ? dataSource['Image'] : this['Image'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels')) {
      let newLabels = [];

      if (dataSource['Labels']) {
        dataSource['Labels'].map((ele) => {
          newLabels.push(new IncludedProductLabelItemModel().getData(deepCopy(ele)));
        });
      }

      this['Labels'] = dataSource['Labels'] ? newLabels : this['Labels'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this['ProductId'] = dataSource['ProductId'] ? dataSource['ProductId'] : this['ProductId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName')) {
      this['ProductName'] = dataSource['ProductName'] ? dataSource['ProductName'] : this['ProductName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductOverview')) {
      this['ProductOverview'] = dataSource['ProductOverview'] ? dataSource['ProductOverview'] : this['ProductOverview'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Quantity')) {
      this['Quantity'] = dataSource['Quantity'] ? dataSource['Quantity'] : this['Quantity'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RepresentativeVariantId')) {
      this['RepresentativeVariantId'] = dataSource['RepresentativeVariantId']
        ? dataSource['RepresentativeVariantId']
        : this['RepresentativeVariantId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock')) {
      this['Stock'].getData(dataSource['Stock']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Url')) {
      this['Url'] = dataSource['Url'] ? dataSource['Url'] : this['Url'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Variants')) {
      let newVariants = [];

      if (dataSource['Variants']) {
        dataSource['Variants'].map((ele) => {
          newVariants.push(new IncludedProductVariantModel().getData(deepCopy(ele)));
        });
      }

      this['Variants'] = dataSource['Variants'] ? newVariants : this['Variants'];
    }

    return this;
  }
}
