import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import BannerClickHyperLinkModel from './BannerClickHyperLinkModel';

export default class BannerFieldsModel {
  constructor() {
    this['Background Image'] = new SitecoreImageModel();

    this['Background video'] = { value: { href: '' } };

    this['Enable Audio'] = false;

    this['Mobile Background Video'] = { value: { href: '' } };

    this['CTA Button'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();

    this['CTA Button Color'] = new SitecoreTextModel();

    this.Description = new SitecoreTextModel();

    this.Icon = new SitecoreImageModel();

    this['Main Image'] = new SitecoreImageModel();

    this['Mobile Background Image'] = new SitecoreImageModel();

    this['Second Icon'] = new SitecoreImageModel();

    this['Sub Title'] = new SitecoreTextModel();

    this.Title = new SitecoreTextModel();

    this['CTA Hyperlink Button'] = new SitecoreLinkModel();

    this['Banner Click Hyperlink'] = new BannerClickHyperLinkModel();

    this['CTA Hyperlink Button Text'] = new SitecoreTextModel();

    this['Layout'] = new SitecoreTextModel();

    this['Desktop Text Position'] = new SitecoreTextModel();

    this['Mobile Text Position'] = new SitecoreTextModel();

    this['Text Color'] = new SitecoreTextModel();

    this['Enable Video CTA Button'] = false;

    this['Video CTA Button Text'] = new SitecoreTextModel();

    this['Video CTA Button Link'] = new SitecoreLinkModel();

    this['Banner Launch Time'] = new SitecoreTextModel();

    this['Banner End Time'] = new SitecoreTextModel();

    this['Banner Id'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Image')) {
      this['Background Image'].getData(dataSource['Background Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background video')) {
      this['Background video'] = dataSource['Background video'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Enable Audio')) {
      this['Enable Audio'] = dataSource['Enable Audio'].value;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Background Video')) {
      this['Mobile Background Video'] = dataSource['Mobile Background Video'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'].getData(dataSource['CTA Button Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Color')) {
      this['CTA Button Color'].getData(dataSource['CTA Button Color']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Icon')) {
      this.Icon.getData(dataSource.Icon);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Main Image')) {
      this['Main Image'].getData(dataSource['Main Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Background Image')) {
      this['Mobile Background Image'].getData(dataSource['Mobile Background Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second Icon')) {
      this['Second Icon'].getData(dataSource['Second Icon']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sub Title')) {
      this['Sub Title'].getData(dataSource['Sub Title']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Hyperlink Button')) {
      this['CTA Hyperlink Button'].getData(dataSource['CTA Hyperlink Button']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Click Hyperlink')) {
      this['Banner Click Hyperlink'].getData(dataSource['Banner Click Hyperlink']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Hyperlink Button Text')) {
      this['CTA Hyperlink Button Text'].getData(dataSource['CTA Hyperlink Button Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Layout')) {
      this['Layout'].getData(dataSource['Layout']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Desktop Text Position')) {
      this['Desktop Text Position'].getData(dataSource['Desktop Text Position']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Text Position')) {
      this['Mobile Text Position'].getData(dataSource['Mobile Text Position']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Text Color')) {
      this['Text Color'].getData(dataSource['Text Color']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video CTA Button Text')) {
      this['Video CTA Button Text'].getData(dataSource['Video CTA Button Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Enable Video CTA Button')) {
      this['Enable Video CTA Button'] = dataSource['Enable Video CTA Button'].value;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video CTA Button Link')) {
      this['Video CTA Button Link'].getData(dataSource['Video CTA Button Link']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Launch Time')) {
      this['Banner Launch Time'].getData(dataSource['Banner Launch Time']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner End Time')) {
      this['Banner End Time'].getData(dataSource['Banner End Time']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Id')) {
      this['Banner Id'].getData(dataSource['Banner Id']);
    }

    return this;
  }
}
