import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Applied Offers Description',
  'Applied Offers Title',
  'Balance Free Gift Label',
  'Balance Label',
  'Balance Payment Label',
  'Billing Address Label',
  'Billing Delivery Fee Label',
  'Billing Discount Label',
  'Billing Sale Tax Label',
  'Billing Subtotal Label',
  'Billing Title',
  'Billing Total Label',
  'Billing Total Saving Label',
  'Coupon Detail Title',
  'Delivery Free Gift Label',
  'Delivery Free Label',
  'Delivery Gifting Option Label',
  'Delivery Package Title',
  'Delivery Product List Label',
  'Delivery Quantity Label',
  'Delivery SF Express Package Label',
  'Delivery Subtotal Label',
  'Delivery You Saved Label',
  'Deposit Free Gift Label',
  'Deposit Paid Label',
  'Deposit Payment Label',
  'Deposit Tag Label',
  'Description For Multiple Packages',
  'Description For Single Package',
  'Discount Description',
  'Discount Label',
  'Download Invoice Label',
  'Extended Warranty Label',
  'Free Gift Hint',
  'From To Date Label',
  'Gift Card Label',
  'Gift Warp Label',
  'Gifting Option Free Label',
  'Gifting Option Label',
  'Learn More Label',
  'Learn More Label',
  'Paid With Label',
  'Pay In Full Label',
  'Pay In Installment Label',
  'PayBalance Button Text',
  'Pick Up Fee Label',
  'Pickup Fee Label',
  'SF Pickup Address Label',
  'SF Pickup Free Gift Label',
  'SF Pickup Free Label',
  'SF Pickup Gifting Option Label',
  'SF Pickup Package Title',
  'SF Pickup Product List Label',
  'SF Pickup Quantity Label',
  'SF Pickup Subtotal Label',
  'SF Pickup You Saved Label',
  'Special Deal Purchase with Purchase Label',
  'Special Request Label',
  'Staff ID Label',
  'Store ID Label',
  'Track Package Label',
  'Trade In Label',
  'Valid Until Label',
  'Payment Method Label',
  'Total Balance Payment Label',
  'Total Deposit Label',
  'Delivery Address Label',
  'Phone Number',
  'Popup Title',
  'Messages',
  'Yes Button Label',
  'No Button Label',
  'Remove Label',
  'Empty Messages',
  'Pre-order Offer Tag Label',
  'Completed Label',
  'Deposit Free Gift Note Messages',
  'Balance Free Gift Note Message',
];

const LINK_ARR = [
  'PayBalance Button Link',
  'Track Package Link'
];

export default class OrderDetailModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((link) => {
      this[link] = new SitecoreLinkModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((text) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text].getData(dataSource[text]);
      }
    });

    LINK_ARR.forEach((link) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link].getData(dataSource[link]);
      }
    });

    return this;
  }
}
