import './AppointmentsInfor.scss';

import { bool, func, number, object } from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE, DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD, HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { useFormatDate } from '@utils/customsHook/useFormatDate';
import Global from '@utils/global';

import useAppointmentDetailContext from '../AppointmentDetail/useAppointmentDetailContext';
import SelectGuests from '../EditAppointmentForm/SelectTimeSlotsAndGuest/SelectGuests';
import SelectTimeSlots from '../EditAppointmentForm/SelectTimeSlotsAndGuest/SelectTimeSlots';

const AppointmentsInfor = ({ isEdit, formik, statusCode, setStatusCode, pastAppointmentEdit }) => {
  const [isShowDetail, setIsShowDetail] = useState(false);
  const [isShowCalendar, setIsShowCalendar] = useState(false);
  const { layoutData, appointmentDetail } = useAppointmentDetailContext();
  const [messageError, setMessageError] = useState('');
  const [productSelectedLabel, setProductSelectedLabel] = useState('');
  const [teaSelectedLabel, setTeaSelectedLabel] = useState('');
  const [scentSelectedLabel, setScentSelectedLabel] = useState('');
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
  const formatDateUtil = useFormatDate();

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  // Create LeadStatus array Text
  const leadStatus = [
    layoutData?.['New Status Label']?.value,
    layoutData?.['Lost Status Label']?.value,
    layoutData?.['Reschedule Status Label']?.value,
    layoutData?.['Trial Complete Status Label']?.value,
    layoutData?.['Never Pick Up Status Label']?.value,
    layoutData?.['AppointmentConfirmed Label']?.value
  ];

  useEffect(() => {
    if (layoutData) {
      switch (statusCode) {
      case 11:
        setMessageError(appointmentDetail?.IsBookExperience ? messageObj?.Msg_031 : layoutData?.['Duplicate Appointment']?.value);
        break;

      case 13:
        setMessageError(layoutData?.['Invalid Date Selection']?.value);
        break;

      case 14:
        setMessageError(layoutData?.[14]?.value);
        break;

      case 15:
        setMessageError(layoutData?.['Max Slots Reached']?.value);
        break;

      case 40:
        setMessageError(messageObj?.Msg_029);
        break;

      default:
        setMessageError('');
        break;
      }
    }
  }, [statusCode]);

  useEffect(() => {
    if (layoutData && appointmentDetail) {
      const productSelected = layoutData?.products?.find((item) => item.Id === appointmentDetail?.ProductId?.toLowerCase());
      const teaSelected = layoutData?.teas?.find((item) => item.Id === appointmentDetail?.TeaId?.toLowerCase());
      const scentSelected = layoutData?.scents?.find((item) => item.Id === appointmentDetail?.ScentId?.toLowerCase());

      setProductSelectedLabel(productSelected?.Name);

      setTeaSelectedLabel(teaSelected?.Name);

      setScentSelectedLabel(scentSelected?.Name);
    }
  }, [layoutData, appointmentDetail]);

  const getFormattedDate = (date) => {
    var year = date.getFullYear();

    var month = (1 + date.getMonth()).toString();

    month = month.length > 1 ? month : '0' + month;

    var day = date.getDate().toString();

    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };

  const submissionDate = useMemo(() => formatDateUtil(appointmentDetail?.['SubmissionDate']), [appointmentDetail?.['SubmissionDate'], Global]);

  const appointmentDate = useMemo(() => formatDateUtil(appointmentDetail?.['AppointmentDate']), [appointmentDetail?.['AppointmentDate'], Global]);

  const appointmentSlot = useMemo(() => {
    const atText = layoutData?.['At Label']?.value;
    let slotValue = appointmentDetail?.AppointmentSlot;
    if (!isConvertDate) slotValue = atText + slotValue;

    return slotValue;
  }, [isConvertDate, appointmentDetail, layoutData]);

  const customerName = useMemo(() => appointmentDetail?.['CustomerName']?.replace(DEFAULT_LAST_NAME_API_PAYLOAD_VALUE_OLD, ''), [appointmentDetail]);

  return (
    <div className='appointments-infor'>
      <div className='appointments-infor__group appointments-infor__group--border-bottom d-flex'>
        <div className={isEdit ? 'col-12 col-md-6' : ''}>
          <Text field={{ value: customerName }} tag='h4' className='appointments-infor__name' />
          <dl className='appointments-infor__item d-flex'>
            <dt className='appointments-infor__item__label'>{layoutData?.['Mobile No Lable']?.value}</dt>
            <dd className='appointments-infor__item__value d-flex d-flex--align-center'>
              <span>
                {isShowDetail
                  ? `+${appointmentDetail?.['CustomerMobileNumber']}`
                  : `+${appointmentDetail?.['CustomerMobileNumber']?.replace(appointmentDetail?.['CustomerMobileNumber'].substr(2, 4), '****')}`}
              </span>
              <span className='cp-icon cp-icon--eye' onMouseDown={() => setIsShowDetail(true)} onMouseUp={() => setIsShowDetail(false)}></span>
            </dd>
          </dl>
          <dl className='appointments-infor__item d-flex'>
            <Text field={layoutData?.['Submission Date Lable']} tag='dt' className='appointments-infor__item__label' />
            <dd className='appointments-infor__item__value'>{submissionDate}</dd>
          </dl>
          <dl className='appointments-infor__item d-flex d-flex-wrap'>
            <Text field={layoutData?.['Appointment Date Lable']} tag='dt' className='appointments-infor__item__label' />
            {isEdit ? (
              <>
                <dd className='appointments-infor__item__value appointments-infor__item__value--date-edit'>
                  <DatePicker
                    selected={new Date(formik?.values?.date)}
                    onChange={(date) => {
                      formik.setFieldValue('date', date);

                      setIsShowCalendar(false);

                      setStatusCode(0);
                    }}
                    dateFormat='yyyy/MM/dd'
                    calendarClassName='date-picker-os'
                    readOnly
                    onInputClick={() => setIsShowCalendar(!isShowCalendar)}
                    onClickOutside={() => setIsShowCalendar(false)}
                    open={isShowCalendar}
                    disabled={pastAppointmentEdit}
                  />
                </dd>
              </>
            ) : (
              <>
                <Text field={{ value: appointmentDate }} className='appointments-infor__item__value appointments-infor__item__value--pr-8' tag='dd' />
                {appointmentDetail?.IsBookExperience && (
                  <Text field={{ value: appointmentSlot }} className='appointments-infor__item__value' tag='dd' />
                )}
              </>
            )}
          </dl>
          {appointmentDetail?.IsBookExperience && (
            <dl className='appointments-infor__item d-flex'>
              <Text field={layoutData?.['No Of Guests Label']} className='appointments-infor__item__label' tag='dt' />
              {isEdit ? (
                <SelectGuests formik={formik} disabled={pastAppointmentEdit} layoutData={layoutData}/>
              ) : (
                <Text
                  field={{
                    value: `${
                      appointmentDetail?.NumberOfGuests === 1
                        ? layoutData?.['Just Me Button Text']?.value
                        : layoutData?.['Me Plus1 Placeholder Text']?.value
                    }`
                  }}
                  className='appointments-infor__item__value'
                  tag='dd'
                />
              )}
              {formik?.errors?.numberOfGuest && formik?.touched?.numberOfGuest && (
                <span className='error-validate'>{formik?.errors?.numberOfGuest?.value}</span>
              )}
            </dl>
          )}
          {isEdit && (
            <>
              {messageError && (
                <span className='error-validate'>
                  {`${
                    !appointmentDetail?.IsBookExperience && statusCode === 13 ? getFormattedDate(new Date(formik.values.date)) : ''
                  } ${messageError}`}
                </span>
              )}
              {formik?.errors?.date && formik?.touched?.date && <span className='error-validate'>{formik?.errors?.date}</span>}
            </>
          )}
        </div>
        {isEdit && appointmentDetail?.IsBookExperience && <SelectTimeSlots formik={formik} disabled={pastAppointmentEdit} layoutData={layoutData} />}
      </div>
      {!isEdit && (
        <>
          <div className='appointments-infor__group'>
            <dl className='appointments-infor__item d-flex'>
              <Text field={layoutData?.['Status Lable']} tag='dt' className='appointments-infor__item__label w-65' />
              <dd className='appointments-infor__item__value appointments-status--blue'>{leadStatus[appointmentDetail?.LeadStatus]}</dd>
            </dl>
            <dl className='appointments-infor__item d-flex'>
              <Text field={layoutData?.['Note Lable']} tag='dt' className='appointments-infor__item__label w-65' />
              <dd className='appointments-infor__item__value'>{appointmentDetail?.['Notes']}</dd>
            </dl>
            {appointmentDetail?.IsBookExperience && (
              <>
                {layoutData?.products.length ? (
                  <dl className='appointments-infor__item d-flex'>
                    <Text field={layoutData?.['Select Product Label']} className='appointments-infor__item__label' tag='dt' />
                    <Text field={{ value: productSelectedLabel }} className='appointments-infor__item__value' tag='dd' />
                  </dl>
                ) : (
                  <></>
                )}
                {layoutData?.teas.length ? (
                  <dl className='appointments-infor__item d-flex'>
                    <Text field={layoutData?.['Select Tea Label']} className='appointments-infor__item__label' tag='dt' />
                    <Text field={{ value: teaSelectedLabel }} className='appointments-infor__item__value' tag='dd' />
                  </dl>
                ) : (
                  <></>
                )}
                {layoutData?.scents.length ? (
                  <dl className='appointments-infor__item d-flex'>
                    <Text field={layoutData?.['Select Scent Label']} className='appointments-infor__item__label' tag='dt' />
                    <Text field={{ value: scentSelectedLabel }} className='appointments-infor__item__value' tag='dd' />
                  </dl>
                ) : (
                  <></>
                )}
              </>
            )}
            {appointmentDetail?.['Questions']?.map((ques) => (
              <dl key={ques.Id} className='appointments-infor__item'>
                <Text field={{ value: ques.Question }} tag='dt' className='appointments-infor__item__label' />
                {ques.Answers?.map((ans) => (
                  <dd key={ans.AnswerId} className='appointments-infor__item__value'>
                    {ans.AnswerName}
                  </dd>
                ))}
              </dl>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

AppointmentsInfor.propTypes = {
  isEdit: bool,
  formik: object,
  statusCode: number,
  setStatusCode: func,
  pastAppointmentEdit: bool
};

export default AppointmentsInfor;
