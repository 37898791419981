import React, { useCallback } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import useSelectStoreAndDateContext from '../useSelectStoreAndDateContext';
import DropdownQuestion from './DropdownQuestion';
import MultiSelectQuestion from './MultiSelectQuestion';
import { QuestionTypes } from './QuestionTypes';
import SingleQuestion from './SingleQuestion';

const QuestionsSelect = () => {
  const { layoutData, formik, isDisableFormSection } = useSelectStoreAndDateContext();
  const { QuestionsAndAvailableAnswers } = layoutData?.['campaign'];

  const handleSelectAnswer = useCallback(
    (questionType, questionId, answerId) => {
      const questionAnswersData = deepCopy(formik.values.contactAnswers);
      const questionAnswering = questionAnswersData.find((question) => question.QuestionId === questionId);
      if (questionType === QuestionTypes.SINGLE_QUESTION || questionType === QuestionTypes.DROPDOWN_QUESTION) {
        if (questionAnswering?.AnswerIds.length) questionAnswering.AnswerIds.splice(0, 1, answerId);
        else questionAnswering.AnswerIds.push(answerId);
      } else {
        if (!questionAnswering.AnswerIds.includes(answerId)) questionAnswering.AnswerIds.push(answerId);
        else questionAnswering.AnswerIds.splice(questionAnswering.AnswerIds.indexOf(answerId), 1);
      }

      formik.setFieldValue('contactAnswers', questionAnswersData);
    },
    [formik.values.contactAnswers]
  );

  const handleGenerateDefaultCheck = (questionId, answerId) => {
    const questionData = formik.values.contactAnswers.find((question) => question.QuestionId === questionId);

    return questionData?.AnswerIds.includes(answerId);
  };

  return (
    <div
      className={['col-12 col-lg-4 select-store__content__question', isDisableFormSection ? 'be-schedule-appointment__disable-form' : ''].join(' ')}
    >
      <Text className='select-store__content__list__title' tag='h4' field={layoutData.tellUsMoreLabel} />
      {QuestionsAndAvailableAnswers && QuestionsAndAvailableAnswers.length ? (
        QuestionsAndAvailableAnswers.map((ques, index) =>
          ques.DisplayType === QuestionTypes.SINGLE_QUESTION ? (
            <div key={ques.Id} className='select-store__content__question__item'>
              <Text className='select-store__content__question__item__title' tag='h5' field={{ value: ques.Question }} />
              <div className='form-group form-group--gender select-store__content__question__item__answer'>
                {ques.AvailableAnswers.map((ans) => (
                  <SingleQuestion
                    labelText={ans.Text}
                    key={ans.Id}
                    name={ques.Id}
                    id={ans.Id}
                    defaultChecked={handleGenerateDefaultCheck(ques.Id, ans.Id)}
                    onChange={() => handleSelectAnswer(ques.DisplayType, ques.Id, ans.Id)}
                    disabled={isDisableFormSection}
                  />
                ))}
                {formik.errors.contactAnswers?.[index]?.AnswerIds && formik.touched.contactAnswers?.[index]?.AnswerIds && (
                  <span className='error-validate'>{formik.errors.contactAnswers?.[index]?.AnswerIds}</span>
                )}
              </div>
            </div>
          ) : ques.DisplayType === QuestionTypes.MULTI_QUESTION ? (
            <div key={ques.Id} className='select-store__content__question__item'>
              <Text className='select-store__content__question__item__title' tag='h5' field={{ value: ques.Question }} />
              <div className='form-group form-group--gender select-store__content__question__item__answer'>
                {ques.AvailableAnswers.map((ans) => (
                  <MultiSelectQuestion
                    labelText={ans.Text}
                    key={ans.Id}
                    id={ans.Id}
                    name={ans.Id}
                    onChange={() => handleSelectAnswer(ques.DisplayType, ques.Id, ans.Id)}
                    disabled={isDisableFormSection}
                  />
                ))}
                {formik.errors.contactAnswers?.[index]?.AnswerIds && formik.touched.contactAnswers?.[index]?.AnswerIds && (
                  <span className='error-validate'>{formik.errors.contactAnswers?.[index]?.AnswerIds}</span>
                )}
              </div>
            </div>
          ) : (
            <div key={ques.Id} className='select-store__content__question__item'>
              <Text className='select-store__content__question__item__title' tag='h5' field={{ value: ques.Question }} />
              <div className='form-group form-group--gender select-store__content__question__item__answer'>
                <DropdownQuestion
                  id={ques.Id}
                  name={ques.Id}
                  placeholder={ques.Question}
                  answerData={ques.AvailableAnswers}
                  onChange={(answer) => handleSelectAnswer(ques.DisplayType, ques.Id, answer.value)}
                  disabled={isDisableFormSection}
                />
                {formik.errors.contactAnswers?.[index]?.AnswerIds && formik.touched.contactAnswers?.[index]?.AnswerIds && (
                  <span className='error-validate'>{formik.errors.contactAnswers?.[index]?.AnswerIds}</span>
                )}
              </div>
            </div>
          )
        )
      ) : (
        <></>
      )}
    </div>
  );
};

export default QuestionsSelect;
