import IncludedProductVariantModel from '@components/BundleElements/model/IncludedProductVariantModel';
import PriceModel from '@components/BundleElements/model/PriceModel';
import ProductCardStockModel from '@components/Product/models/ProductCardStockModel';
import LabelsModel from '@models/LabelsModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import { deepCopy } from '@utils/utility';

const stringArr = ['CatalogName', 'Description', 'Image', 'ProductId', 'ProductName', 'ProductOverview', 'RepresentativeVariantId', 'Url'];

export default class SpecialRelatedProductItemModel {
  constructor() {
    stringArr.forEach((item) => {
      this[item] = '';
    });

    this.IsBundle = false;

    this.ExternalWishListLineId = '';

    this['Id'] = '';

    this['CategoryName'] = '';

    this['Labels'] = [];

    this['Price'] = new PriceModel();

    this['Stock'] = new ProductCardStockModel();

    this['Variants'] = [];

    this['Buy Now Link'] = new SitecoreLinkModel();
  }
  getData(dataSource, buyNowLink) {
    stringArr.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ?? this['Id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CategoryName')) {
      this['CategoryName'] = dataSource['CategoryName'] ?? this['CategoryName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels')) {
      let newLabelList = [];

      dataSource['Labels'].map((element) => {
        newLabelList.push(new LabelsModel().getData(deepCopy(element) || {}));
      });

      this['Labels'] = newLabelList;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock')) {
      this.Stock.getData(dataSource.Stock || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsBundle')) {
      this.IsBundle = dataSource.IsBundle;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Variants')) {
      let newVariantsList = [];

      dataSource['Variants'].map((element) => {
        newVariantsList.push(new IncludedProductVariantModel().getData(deepCopy(element) || {}));
      });

      this['Variants'] = newVariantsList;
    }

    let tempLink = deepCopy(buyNowLink);

    tempLink.value.href = `${buyNowLink.value.href}?productPageId=${this['Id']}`;

    this['Buy Now Link'] = deepCopy(tempLink);

    return this;
  }
}
