//Store Locator
export const GET_STORE_LOCATOR = 'GET_STORE_LOCATOR';

export const GET_STORE_LOCATOR_SUCCESS = 'GET_STORE_LOCATOR_SUCCESS';

export const GET_STORE_LOCATOR_FAILED = 'GET_STORE_LOCATOR_FAILED';

//Search Store Locator
export const SEARCH_STORE_LOCATOR = 'SEARCH_STORE_LOCATOR';

//Get Filter AREA List
export const GET_FILTER_AREA_LIST = 'GET_FILTER_AREA_LIST';

export const GET_FILTER_AREA_LIST_SUCCESS = 'GET_FILTER_AREA_LIST_SUCCESS';

export const GET_FILTER_AREA_LIST_FAILED = 'GET_FILTER_AREA_LIST_FAILED';

//Get Filter PROVINCE List
export const GET_FILTER_PROVINCE_LIST = 'GET_FILTER_PROVINCE_LIST';

export const GET_FILTER_PROVINCE_LIST_SUCCESS = 'GET_FILTER_PROVINCE_LIST_SUCCESS';

export const GET_FILTER_PROVINCE_LIST_FAILED = 'GET_FILTER_PROVINCE_LIST_FAILED';

export const CLEAR_FILTER_PROVINCE_LIST = 'CLEAR_FILTER_PROVINCE_LIST';

//Get Filter CITY List
export const GET_FILTER_CITY_LIST = 'GET_FILTER_CITY_LIST';

export const GET_FILTER_CITY_LIST_SUCCESS = 'GET_FILTER_CITY_LIST_SUCCESS';

export const GET_FILTER_CITY_LIST_FAILED = 'GET_FILTER_CITY_LIST_FAILED';

export const CLEAR_FILTER_CITY_LIST = 'CLEAR_FILTER_CITY_LIST';

//Get Filter Zip Code List
export const GET_FILTER_ZIP_CODE = 'GET_FILTER_ZIP_CODE';

export const GET_FILTER_ZIP_CODE_SUCCESS = 'GET_FILTER_ZIP_CODE_SUCCESS';

export const GET_FILTER_ZIP_CODE_FAILED = 'GET_FILTER_ZIP_CODE_FAILED';

//Get estimate shipping fee
export const GET_ESTIMATE_SHIPPING_FEE = 'GET_ESTIMATE_SHIPPING_FEE';

export const GET_ESTIMATE_SHIPPING_FEE_SUCCESS = 'GET_ESTIMATE_SHIPPING_FEE_SUCCESS';

export const GET_ESTIMATE_SHIPPING_FEE_FAILED = 'GET_ESTIMATE_SHIPPING_FEE_FAILED';

// Set active tab
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

// Set selected tab
export const SET_SELECTED_STORE_ITEM = 'SET_SELECTED_STORE_ITEM';

// Set hovered tab
export const SET_HOVERED_STORE_ITEM = 'SET_HOVERED_STORE_ITEM';

// GET MY CURRENT LOCATION
export const GET_MY_CURRENT_LOCATION = 'GET_MY_CURRENT_LOCATION';

// APPLY FILTER STORE
export const APPLY_FILTER_STORE = 'APPLY_FILTER_STORE';

export const REMOVE_APPLY_FILTER_STORE = 'REMOVE_APPLY_FILTER_STORE';

// RESET INPUT VALUE
export const RESET_INPUT_VALUE = 'RESET_INPUT_VALUE';

// SET LOCATED LIST
export const SET_LOCATED_STORE_LIST = 'SET_LOCATED_STORE_LIST';

// STORE LOCATOR TYPE
export const GET_STORE_LOCATOR_TYPE_FILTER = 'GET_STORE_LOCATOR_TYPE_FILTER';
