const numberArr = [
  'CurrencyDecimalDigits',
  'CurrencyNegativePattern',
  'CurrencyPositivePattern',
  'DigitSubstitution',
  'NumberDecimalDigits',
  'NumberNegativePattern',
  'PercentDecimalDigits',
  'PercentNegativePattern',
  'PercentPositivePattern'
];

const stringArr = [
  'CurrencyDecimalSeparator',
  'CurrencyGroupSeparator',
  'CurrencySymbol',
  'NaNSymbol',
  'NegativeInfinitySymbol',
  'NegativeSign',
  'NumberDecimalSeparator',
  'NumberGroupSeparator',
  'PerMilleSymbol',
  'PercentDecimalSeparator',
  'PercentGroupSeparator',
  'PercentSymbol',
  'PositiveInfinitySymbol',
  'PositiveSign'
];

export default class NumberFormatModel {
  constructor() {
    numberArr.forEach((item) => {
      this[item] = 0;
    });

    stringArr.forEach((item) => {
      this[item] = '';
    });

    this['CurrencyGroupSizes'] = [];

    this['IsReadOnly'] = false;

    this['NativeDigits'] = [];

    this['NumberGroupSizes'] = [];

    this['PercentGroupSizes'] = [];
  }
  getData(dataSource) {
    numberArr.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    stringArr.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyGroupSizes')) {
      this['CurrencyGroupSizes'] = dataSource['CurrencyGroupSizes'] ?? this['CurrencyGroupSizes'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsReadOnly')) {
      this['IsReadOnly'] = dataSource['IsReadOnly'] ?? this['IsReadOnly'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NativeDigits')) {
      this['NativeDigits'].getData(dataSource['NativeDigits']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NumberGroupSizes')) {
      this['NumberGroupSizes'] = dataSource['NumberGroupSizes'] ?? this['NumberGroupSizes'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PercentGroupSizes')) {
      this['PercentGroupSizes'] = dataSource['PercentGroupSizes'] ?? this['PercentGroupSizes'];
    }

    return this;
  }
}
