import React, { useEffect, useState } from 'react';

import { deepCopy } from '@utils/utility';

import CartSuggestFreeShipModel from '../CartSuggestFreeShip/model/CartSuggestFreeShipModel';

const CarDetailSuggestFreeShip = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new CartSuggestFreeShipModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  return layoutData && (
    <div>
      <p dangerouslySetInnerHTML={{ __html: layoutData['Description'] }}></p>
    </div>
  );
};

export default CarDetailSuggestFreeShip;
