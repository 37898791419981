import './SimpleSquareTiles.scss';

import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import ModalComponent from '@components/Share/ModalComponent';
import ExpertPopupContent from '@components/SlicingPage/About/OurWellnessTeam/ExpertPopupContent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import SimpleSquareFieldsModel from './Model/SimpleSquareFieldsModel';
import SimpleSquareItem from './SimpleSquareItem';

const SimpleSquareTiles = (props) => {
  const { fields } = props;
  const [isShow, setShow] = useState(false);
  const [simpleSquareFields, setSimpleSquareFields] = useState(null);
  const [expertPopupData, setExpertPopupData] = useState(null);

  useEffect(() => {
    const simpleSquareFieldsModel = new SimpleSquareFieldsModel();

    setSimpleSquareFields(simpleSquareFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  const onShowModalHandler = (item) => {
    setExpertPopupData(item);

    setShow(true);
  };

  const onCloseModalHandler = () => {
    setShow(false);
  };

  return (
    simpleSquareFields ? (
      <div className='our-wellness-team animated-slow fadeIn delay-100'>
        <div className='container'>
          <div className='our-wellness-team__heading text-center'>
            <Text className='our-wellness-team__heading__title experience-title' tag='h2' field={simpleSquareFields.Title} />
            <Text className='our-wellness-team__heading__desc' tag='p' field={simpleSquareFields.Description} />
          </div>
          <div className='our-wellness-team__content'>
            <div className='row'>
              {simpleSquareFields.items.map((item, index) => {
                return (
                  <div className='col-6 col-lg-3' key={index}>
                    <SimpleSquareItem simpleSquareItem={item} onShowModal={() => onShowModalHandler(item)}></SimpleSquareItem>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <ModalComponent isShow={isShow} onCloseHandler={onCloseModalHandler} titlePopup={simpleSquareFields['Popup Title'].value}>
          <ExpertPopupContent expertPopupData={expertPopupData}/>
        </ModalComponent>
      </div>
    ) : (
      <div className='our-wellness-team skeleton__item'>
        <div className='container'>
          <div className='our-wellness-team__heading text-center'>
            <h2 className='our-wellness-team__heading__title experience-title'>
              <span className='text-line-40-center w-40'></span>
            </h2>
            <p className='our-wellness-team__heading__desc'><span className='text-line-24-center w-80'></span></p>
          </div>
          <div className='our-wellness-team__content'>
            <div className='row'>
              <LoadingShimmer itemNumb={4}>
                <div className='col-6 col-lg-3'>
                  <div className='our-wellness-team__content__item text-center'>
                    <div className='our-wellness-team__content__item__img image-wrap'>
                      <span className='image-line'></span>
                    </div>
                    <span className='text-line'></span>
                    <span className='clear-10'></span>
                    <span className='text-line-20'></span>
                  </div>
                </div>
              </LoadingShimmer>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default SimpleSquareTiles;