import React from 'react';
import { Fragment } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';

const OrderDetailsShimmer = () => {

  return (
    <Fragment>
      <div className='orders-detail skeleton__item'>
        <div className='orders-detail__general-info'>
          <div className='container orders-detail__general-info__wrap'>
            <span className='text-line-50-center w-30'></span>
            <span className='clear-8'></span>
            <span className='text-line-center w-60'></span>
            <span className='clear-8'></span>
            <span className='text-line-25-center w-30'></span>
          </div>
        </div>
        <div className='orders-detail__info'>
          <div className='delivery-component'>
            <div className='container'>
              <div className='delivery-component__header'>
                <span className='text-line-35 w-30'></span>
              </div>
              <div className='delivery-component__wrapper'>
                <div className='delivery-container'>
                  <div className='address-component'>
                    <span className='delivery-item-title text-line-30'></span>
                    <div className='delivery-row address-component__row'>
                      <div className='delivery-col-left'>
                        <span className='text-line-24'></span>
                        <span className='clear-8'></span>
                        <span className='text-line-22'></span>
                        <span className='clear-8'></span>
                        <span className='text-line-22'></span>
                        <span className='clear-8'></span>
                        <span className='text-line-22'></span>
                      </div>
                      <div className='delivery-col-right delivery-col-right--padding-left'>
                        <span className='text-line-24'></span>
                        <span className='clear-8'></span>
                        <span className='text-line-22'></span>
                        <span className='clear-8'></span>
                        <span className='text-line-22'></span>
                        <span className='clear-8'></span>
                        <span className='text-line-22'></span>
                      </div>
                    </div>
                  </div>
                  <div className='product-list large-text'>
                    <span className='delivery-item-title text-line-30'></span>
                    <LoadingShimmer itemNumb={4}>
                      <div className='product-list__item'>
                        <div className='delivery-row'>
                          <div className='delivery-col-left'>
                            <div className='product-list__image image-wrap'>
                              <span className='image-line'></span>
                            </div>
                          </div>
                          <div className='delivery-col-right'>
                            <span className='text-line-50'></span>
                            <span className='clear-8'></span>
                            <span className='text-line-50'></span>
                            <span className='clear-8'></span>
                            <span className='text-line-50'></span>
                            <span className='clear-8'></span>
                            <span className='text-line-50'></span>
                          </div>
                        </div>
                      </div>
                    </LoadingShimmer>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default OrderDetailsShimmer;