
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { post } from '@services/genericService';

import * as config from '../config';

const cPResendLoginOTP = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.RESEND_LOGIN_OTP),
    switchMap((action) => {
      const params = {
        UserName: action.payload.UserName
      };

      return from(post(config.ACTION_RESEND_LOGIN_OTP, params)).pipe(
        map((res) => {
          if (res.status == 200 && res.data?.Success) {
            return actions.resendLoginValidateCodeSuccess({
              ...res.data
            });
          } else {
            return actions.resendLoginValidateCodeFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.resendLoginValidateCodeFailed(error.message || 'Failed')))
      );
    })
  );

export default cPResendLoginOTP;
