import produce from 'immer';

import * as productWarrantyTypes from '@redux/actions/productWarranty/productWarrantyTypes';

const initState = {
  isLoading: false,
  data: null,
  productQuantity: null
};

const getLatestMyProductReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productWarrantyTypes.GET_LATEST_MY_PRODUCT:
      draft.isLoading = true;
      break;

    case productWarrantyTypes.GET_LATEST_MY_PRODUCT_SUCCESS:
      draft.isLoading = false;

      draft.data = action.payload;

      draft.productQuantity = action.payload.TotalCount;
      break;

    case productWarrantyTypes.GET_LATEST_MY_PRODUCT_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default getLatestMyProductReducer;
