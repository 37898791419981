import produce from 'immer';

import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';

const initState = {
  isLoading: false,
  errorMessage: '',
  isSubscribe: false,
};

const newsletterSubscribeReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myAccountTypes.SUBSCRIBE_LETTER:
      draft.isLoading = true;

      draft.errorMessage = '';

      break;

    case myAccountTypes.SUBSCRIBE_LETTER_SUCCESS:
      draft.isLoading = false; 

      draft.isSubscribe = action.payload;
      break;

    case myAccountTypes.SUBSCRIBE_LETTER_FAILED:
      draft.isLoading = false;
      
      draft.errorMessage = action.error;
      break;

    case myAccountTypes.GET_STATUS_SUBSCRIBE_LETTER:
      draft.isLoading = true;

      draft.errorMessage = '';
      break;

    case myAccountTypes.GET_STATUS_SUBSCRIBE_LETTER_SUCCESS:
      draft.isLoading = false; 

      draft.isSubscribe = action.payload;
      break;

    case myAccountTypes.GET_STATUS_SUBSCRIBE_LETTER_FAILED:
      draft.isLoading = false;
    
      draft.errorMessage = action.error;
      break;
    }

    return draft;
  });

export default newsletterSubscribeReducer;
