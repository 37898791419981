import * as getProductCatalogueTypes from './getProductCatalogueTypes.js';

export const getProductCatalogue = (payload) => ({
  type: getProductCatalogueTypes.GET_PRODUCT_CATALOGUE,
  payload
});
export const getProductCatalogueSuccess = (payload) => ({
  type: getProductCatalogueTypes.GET_PRODUCT_CATALOGUE_SUCCESS,
  payload
});
export const getProductCatalogueFailed = (error) => ({
  type: getProductCatalogueTypes.GET_PRODUCT_CATALOGUE_FAILED,
  error
});
