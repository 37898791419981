import Proptypes from 'prop-types';
import React, { useState } from 'react';

import { PARAMS_SUBMIT } from '@components/CategoryLanding/ProductListing/config';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

const FilterByFeature = (props) => {
  const { dataSources, features, setSelectFilterEvt, isToogleClearFilter, filterFeatureCurrent, isAreas } = props;
  const [featureFilter, setFeature] = useState(filterFeatureCurrent?.length ? filterFeatureCurrent : []);

  const onToogleFeatureHandler = (event) => {
    const featureFilterCopy = deepCopy(featureFilter);
    if (event.currentTarget.checked) {
      featureFilterCopy.push(event.currentTarget.value);

      setFeature(featureFilterCopy);
    } else {
      const newFeatureFilter = featureFilterCopy.filter(value => value !== event.currentTarget.value);

      setFeature(newFeatureFilter);
    }
  };

  useDidUpdateEffect(() => {
    if (isAreas) {
      setSelectFilterEvt({ key: PARAMS_SUBMIT.AreasOfConcern, value: featureFilter });
    }
    else {
      setSelectFilterEvt({ key: PARAMS_SUBMIT.Features, value: featureFilter });
    }
  }, [featureFilter]);

  useDidUpdateEffect(() => {
    setFeature([]);
  }, [isToogleClearFilter]);

  return features?.Details.length ? (
    <div className='filter-popup__section filter-popup__section--features'>
      <h3 className='filter-popup__section__heading'>
        {dataSources?.Name?.value}
      </h3>
      <div className='filter-popup__section__checkbox-list'>
        <ul className='filter-popup__section__checkbox-list__group'>
          {features.Details.map((feature, featureIdx) => (
            <li className='filter-popup__section__checkbox-item' key={featureIdx}>
              <div className='osim-checkbox'>
                <input className='osim-checkbox-input' checked={featureFilter.includes(feature.Value) ? true : false} value={feature.Value} onChange={(event) => onToogleFeatureHandler(event)} type='checkbox' id={`osim-checkbox-${featureIdx + 1}`} />
                <label className='osim-checkbox-label' htmlFor={`osim-checkbox-${featureIdx + 1}`}>{isAreas ? feature.Name:  feature.Value}</label>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  ) : <></>;
};

FilterByFeature.propTypes = {
  isAreas: Proptypes.bool,
  dataSources: Proptypes.object,
  isToogleClearFilter: Proptypes.bool,
  features: Proptypes.object,
  setSelectFilterEvt: Proptypes.func,
  filterFeatureCurrent: Proptypes.any
};

export default FilterByFeature;
