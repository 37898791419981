import produce from 'immer';

import { SUBSCRIBE_MAIL_FAILED, SUBSCRIBE_MAIL_START, SUBSCRIBE_MAIL_SUCCESS } from '@redux/actions/subscribeNewsletter/subscribeNewsletterTypes';

const initialState = {
  isLoading: false,
  subscribeSuccess: false,
  error: null
};

export const subscribeNewsletterReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case SUBSCRIBE_MAIL_START:
      draft.isLoading = true;
      break;

    case SUBSCRIBE_MAIL_SUCCESS:
      draft.isLoading = false;

      draft.subscribeSuccess = action.payload.Success;
      break;

    case SUBSCRIBE_MAIL_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });
