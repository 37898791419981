import '../UserAddressComponent/AddressComponent.scss';

import PropTypes from 'prop-types';
import React, {useEffect, useRef, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import { setCartLineFullfillment } from '@redux/actions/cartDetail';
import {
  checkAddressErrorMessageSelector
} from '@redux/reducers/checkoutProcess/setAddressValidErrorMessage/selector';
import { getLayoutElementStyleSelector } from '@redux/reducers/layoutElementStyle/selectors';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import SelectAddressPopup from '../UserAddressComponent/SelectAddressPopup';

const StoreAddressComponent = ({
  type,
  storeAddress,
  layoutData,
  page,
  data,
  pickUpPoints,
  showError,
  isLoading,
  pickUpPointsNetworkOptions,
  cartData,
  cartValue,
  objMessages
}) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);
  const [addressSfExpress, setAddressSfExpress] = useState('');
  const [listAddress, setListAddress] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [dirtySaveAddressBtn, setDirtySaveAddressBtn] = useState(false);
  const [triggerMouseMove, setTriggerMouseMove] = useState(false);

  const [valueSelected, setValueSelected] = useState({
    Area: null,
    Province: null,
    Network: null
  });

  const [optionsPickup, setOptionsPickUp] = useState({
    pickUpPointsDistrictOptions: [],
    pickUpPointsAreaOptions: []
  });
  const sfAddressInputRef=useRef(null);
  const { headerHeight } = useSelector(getLayoutElementStyleSelector);
  const { section: sectionToShowMessage, isValid: isDeliveryAddressValid } = useSelector(checkAddressErrorMessageSelector);

  const onClosePopupHandler = () => {
    setShowPopup(false);
  };

  const selectAddressHandler = () => {
    setDirtySaveAddressBtn(true);

    if (!isEdit && addressSfExpress) {
      const listId = data.map((item) => {
        return item.ExternalCartLineId;
      });

      const updateCartLineFulfillmentPayload = {
        CartLineIds: listId,
        DeliveryOptionId: data[0].DeliveryOptionId,
        Address1: addressSfExpress
      };

      dispatch(setCartLineFullfillment({ updateCartLineFulfillmentPayload: updateCartLineFulfillmentPayload }));

      setIsEdit(!isEdit);
    }

    if (isEdit && addressSfExpress) {
      setIsEdit(!isEdit);
    }
  };

  const onSelectNetworkChange = (option) => {
    if (option && option.ItemId !== valueSelected?.Network?.ItemId) {
      setValueSelected({
        ...valueSelected,
        Network: option,
        Area: null,
        District: null
      });
      let pickUpPointsAreaOptionsTmp = [];

      pickUpPoints.map((pickUpItem) => {
        if (pickUpItem.NetworkCode === option.NetworkCode) {
          pickUpPointsAreaOptionsTmp.push({ ...pickUpItem, label: pickUpItem.AreaName, value: pickUpItem.AreaCode });
        }
      });

      setOptionsPickUp({
        ...optionsPickup,
        pickUpPointsAreaOptions: Global.getUniqueListBy(pickUpPointsAreaOptionsTmp, 'AreaCode'),
        pickUpPointsDistrictOptions: []
      });
    }
  };

  const onSelectAreaChange = (option) => {
    if (option && option.ItemId !== valueSelected?.Area?.ItemId) {
      setValueSelected({
        ...valueSelected,
        Area: option,
        District: null
      });
      let pickUpPointsDistrictOptionsTmp = [];

      pickUpPoints.map((pickUpItem) => {
        if (pickUpItem.AreaCode === option.AreaCode && pickUpItem.NetworkCode === option.NetworkCode) {
          pickUpPointsDistrictOptionsTmp.push({ ...pickUpItem, label: pickUpItem.ProvinceName, value: pickUpItem.ProvinceCode });
        }
      });

      setOptionsPickUp({
        ...optionsPickup,
        pickUpPointsDistrictOptions: Global.getUniqueListBy(pickUpPointsDistrictOptionsTmp, 'ProvinceCode')
      });
    }
  };

  const onSelectDistrictChange = (option) => {
    if (option && option.ItemId !== valueSelected?.Provine?.ItemId) {
      setValueSelected({
        ...valueSelected,
        District: option
      });
    }
  };


  const hasMouseCheck = () => {
    window.removeEventListener('mousemove', hasMouseCheck, false);

    setTriggerMouseMove(false);

    const listId = data.map((item) => {
      return item.ExternalCartLineId;
    });

    const updateCartLineFulfillmentPayload = {
      CartLineIds: listId,
      DeliveryOptionId: data[0].DeliveryOptionId,
      Address1: addressSfExpress
    };

    dispatch(setCartLineFullfillment({ updateCartLineFulfillmentPayload: updateCartLineFulfillmentPayload }));
  };

  useDidUpdateEffect(() => {
    let pickUpPointsFilter = [];
    if (valueSelected.District) {
      pickUpPointsFilter = pickUpPoints.filter(
        (pickupItem) =>
          pickupItem.NetworkCode === valueSelected.Network.NetworkCode &&
          pickupItem.AreaCode === valueSelected.Area.AreaCode &&
          pickupItem.ProvinceCode === valueSelected.District.ProvinceCode
      );
    }

    setListAddress(pickUpPointsFilter);
  }, [valueSelected]);

  useDidUpdateEffect(() => {
    window.addEventListener('mousemove', hasMouseCheck, false);
  }, [triggerMouseMove]);

  useEffect(() => {
    setAddressSfExpress(data[0]?.Address?.Address1);
  }, [data]);

  useDidUpdateEffect(() => {
    // INFO: !sectionToShowMessage && isDeliveryAddressValids set priority level to show error message
    if ((showError || dirtySaveAddressBtn) && !addressSfExpress && sfAddressInputRef?.current && !sectionToShowMessage && isDeliveryAddressValid) {
      const storeAddressErrorMsgEl = sfAddressInputRef.current;

      window.scrollTo({
        top: storeAddressErrorMsgEl.offsetTop - headerHeight,
        behavior: 'smooth'
      });
    }
  }, [showError, dirtySaveAddressBtn, addressSfExpress, sfAddressInputRef, headerHeight, sectionToShowMessage, isDeliveryAddressValid]);

  console.log({isLoading});

  return (
    storeAddress &&
    cartData && (
      <div ref={sfAddressInputRef} className='address-component'>
        <h3 className='delivery-item-title'>
          {type === 'sf-express'
            ? layoutData['SF Pickup Address Label'].value
            : type === 'pick-up-store'
              ? layoutData['SF Pickup Address Label'].value
              : layoutData['Delivery Address Label'].value}
        </h3>
        <div className='delivery-row address-component__row'>
          <div className='delivery-col-left'>
            {data[0]?.Address ? (
              <div className='address-component__line2'>
                {page !== 'review' ? (
                  <div className='area-selected'>
                    <p className='area-selected__content'>{layoutData['Pick Up Fee Label'].value}</p>
                    <span className='area-selected__price'>
                      {cartValue.SFExpressTotalAmount === 0 ? <Message messageCode='LBL-Free' /> : cartValue.SFExpressTotal}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span style={{'overflow-wrap': 'break-word'}} >{data[0].Address.Address1}</span>
                  </div>
                )}
                <RichText className='smaller-text smaller-text--inline' tag='small' field={layoutData['SF Express Station Label']} />
              </div>
            ) : null}
          </div>
          {page === 'review' ? (
            ''
          ) : (
            <div className='delivery-col-right delivery-col-right--padding-left'>
              {type === 'sf-express' ? (
                <div className='sf-express'>
                  {/* <p className='sf-express__des'>{layoutData['Select Area And District Label'].value}</p>
                  {
                    showError && invalidAddress ? <p className='warning-message'>{layoutData['Please Select Address Message'].value}</p> : ''
                  } */}
                  <div className='store-locator__modal'>
                    <div className='form-input cart-promotion-codes__form__input'>
                      <input
                        type='text'
                        className={
                          addressSfExpress
                            ? 'form-control form-control-lg checkout-input__input input-valid'
                            : 'form-control form-control-lg checkout-input__input '
                        }
                        placeholder={layoutData['SF Express Address Placeholder'].value}
                        onChange={(event) => {
                          setAddressSfExpress(event.target.value);

                          setTriggerMouseMove(true);
                        }}
                        value={addressSfExpress}
                        rows='1'
                        style={{ width: '100%' }}
                      />
                    </div>
                  </div>
                  {(showError || dirtySaveAddressBtn) && !addressSfExpress ? (
                    <p className='warning-message'>{layoutData['Please Select Address Message'].value}</p>
                  ) : (
                    ''
                  )}
                </div>
              ) : null}
              {listAddress && listAddress.length > 3 ? (
                <p onClick={() => setShowPopup(true)} className='address-component__button-see-more'>
                  {layoutData['SF Pickup See More Address Label'].value}
                </p>
              ) : null}
              {isLoading ? <LoadingMask></LoadingMask> : ''}
            </div>
          )}
        </div>
        <ModalComponent
          isShow={showPopup}
          titlePopup={<Text field={layoutData['PopUp Select Address Label']} tag='span' />}
          onCloseHandler={onClosePopupHandler}
        >
          <SelectAddressPopup
            layoutData={layoutData}
            type='store'
            selectAddress={(event) => selectAddressHandler(event)}
            listAddress={type === 'pick-up-store' ? storeAddress : pickUpPoints}
          ></SelectAddressPopup>
        </ModalComponent>
      </div>
    )
  );
};

const mapStateToProps = (state) => ({
  storeAddress: state.checkoutProcesstReducer.addressListReducer?.storeAddressList || {},
  pickUpPointsAreaOptions: state.sfExpressReducer?.pickUpPointsAreaOptions || [],
  pickUpPointsProvinceOptions: state.sfExpressReducer?.pickUpPointsProvinceOptions || [],
  pickUpPointsNetworkOptions: state.sfExpressReducer?.pickUpPointsNetworkOptions || [],
  pickUpPoints: state.sfExpressReducer?.pickUpPoints || [],
  isLoading: state.checkoutProcesstReducer.updateCartReducer.isLoadingStoreAddress,
  cartValue: state.productReducer.cartDetailReducer?.currentCart,
  objMessages: state.getMessageReducer?.objMessages || null
});

StoreAddressComponent.propTypes = {
  type: PropTypes.string,
  storeAddress: PropTypes.object,
  layoutData: PropTypes.object,
  pickUpPoints: PropTypes.array,
  pickUpPointsProvinceOptions: PropTypes.array,
  pickUpPointsAreaOptions: PropTypes.array,
  page: PropTypes.string,
  data: PropTypes.object,
  showError: PropTypes.bool,
  isLoading: PropTypes.bool,
  cartValue: PropTypes.object,
  pickUpPointsNetworkOptions: PropTypes.array,
  cartData: PropTypes.any,
  objMessages: PropTypes.object
};

export default connect(mapStateToProps)(StoreAddressComponent);
