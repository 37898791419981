import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { scheduleAppointmentFailed, scheduleAppointmentSuccess } from '@redux/actions/bookingExperience/scheduleAppointment';
import { SCHEDULE_APPOINTMENT_START } from '@redux/actions/bookingExperience/scheduleAppointment/types';
import { postAsObservable } from '@services/genericService';

import { handleGenerateAppointmentParams } from '../utils';
import { SCHEDULE_APPOINTMENT_ENDPOINT } from './config';
import { ScheduleAppointStatus } from './responseStatus';

const scheduleAppointmentEpic = (action$) =>
  action$.pipe(
    ofType(SCHEDULE_APPOINTMENT_START),
    switchMap((action) => {
      const params = handleGenerateAppointmentParams(action.payload);

      return from(
        postAsObservable(SCHEDULE_APPOINTMENT_ENDPOINT, params).pipe(
          map((res) => {
            if (ScheduleAppointStatus.SuccessStatusCode.includes(res.data.StatusCode)) {
              return scheduleAppointmentSuccess(res.data);
            } else {
              return scheduleAppointmentFailed(res.data);
            }
          })
        )
      );
    }),
    catchError((error) => of(scheduleAppointmentFailed(error.message || 'Failed')))
  );

export default scheduleAppointmentEpic;
