import { any } from 'prop-types';
import React from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';

const EventBannerSwiper = ({ imageData }) => {
  let params = {
    loop: true,
    lazy: true,
    slidesPerView: 1.24,
    spaceBetween: 16,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      992: {
        slidesPerView: 1,
        spaceBetween: 0
      }
    }
  };

  return (
    <div className='event-banner__swiper'>
      <div className='event-banner__swiper-container'>
        <SwipeComponent param={params}>
          {imageData &&
            imageData.map((item, index) => (
              <div key={index} className='swiper-image__container'>
                <img className='swiper-lazy animated' data-src={item?.['Gallery Image']} />
                <div className='swiper-lazy-preloader swiper-lazy-preloader-white'></div>
              </div>
            ))}
        </SwipeComponent>
      </div>
    </div>
  );
};

EventBannerSwiper.propTypes = {
  imageData: any
};

export default EventBannerSwiper;
