export default class BOVariantAvailabilityModel {
  constructor() {
    
    this.IsAvailable = false;

    this.PurchasableLimit = 0;

    this.Quantity = 0;

    this.StatusStock = 'OutOfStock';
  }
  getData(dataSource) {
    if (Object.prototype.hasOwnProperty.call(dataSource, 'IsAvailable')) {
      this.IsAvailable = dataSource.IsAvailable ? dataSource.IsAvailable : this.IsAvailable;
    }
    if (Object.prototype.hasOwnProperty.call(dataSource, 'PurchasableLimit')) {
      this.PurchasableLimit = dataSource.PurchasableLimit ? dataSource.PurchasableLimit : this.PurchasableLimit;
    }
    if (Object.prototype.hasOwnProperty.call(dataSource, 'Quantity')) {
      this.Quantity = dataSource.Quantity ? dataSource.Quantity : this.Quantity;
    }
    if (Object.prototype.hasOwnProperty.call(dataSource, 'StatusStock')) {
      this.StatusStock = dataSource.StatusStock ? dataSource.StatusStock : this.StatusStock;

      this.FinalStatus = renderFinalStatus(this.StatusStock);
    }

    return this;
  }
}

export const renderFinalStatus = (Type) => {

  const listLabel = {
    'InStock': 'InStock',
    'PreOrderable': 'InStock',
    'BackOrderable': 'InStock',
    'OutOfStock' : 'OutOfStock',
  };

  const listAvaiable = {
    'InStock': true,
    'PreOrderable': true,
    'BackOrderable': true,
    'OutOfStock' : false,
  };

  return { 
    Status: listLabel[Type],
    isAvaiable: listAvaiable[Type],
  };
};
  