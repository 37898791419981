import './ProductReviewSubmitted.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { deepCopy } from '@utils/utility';

import WarrantyRegistrationThankYou from '../../ProductWarrantyRegistration/WarrantyRegistrationThankYou';
import ReviewSubmittedModel from './models/ProductReviewSubmittedModel';

const ProductReviewSubmitted = ({ fields }) => {
  const [productReviewSubmittedFields, setProductReviewSubmittedFields] = useState(null);

  useEffect(() => {
    const productReviewSubmittedModel = new ReviewSubmittedModel();

    setProductReviewSubmittedFields(productReviewSubmittedModel.getData(deepCopy(fields || {})));
  }, []);

  return (
    productReviewSubmittedFields && (
      <div className='product-review-submitted'>
        <WarrantyRegistrationThankYou isProductReview={true} productReviewSubmittedFields={productReviewSubmittedFields} />
      </div>
    )
  );
};

ProductReviewSubmitted.propTypes = {
  fields: Proptypes.any
};

export default ProductReviewSubmitted;
