import global from '@utils/global';

import GoogleAnalytic from '../GoogleAnalytic';

export const STRIP_ADS_EVENT_CLOSE_ACTION = 'close';

export const STRIP_ADS_EVENT_VIEW_ACTION = 'view';

export const STRIP_ADS_EVENT_NAME = 'strip_ads';

export const handleTrackingStripAdsEvent = (action, stripAdsId = '', stripAdsName = '') => {
  const isGA4 = global.getIsGA4();
  if (isGA4) {
    new GoogleAnalytic().gAnalytic4('event', STRIP_ADS_EVENT_NAME, {
      action: action,
      strip_ad_id: stripAdsId,
      strip_ad_name: stripAdsName
    });
  }
};
