import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/checkoutProcess';
import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';
import { postAsObservable } from '@services/genericService';

import * as config from './config';

const accountCheckingEpic = (action$) =>
  action$.pipe(
    ofType(checkoutProcessTypes.CHECK_COMMERCE_USER),
    switchMap((action) => {
      const params = {
        email: action.payload
      };

      return postAsObservable(config.ACTION, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.checkCommerceUserSuccess(res.data?.Data);
          } else {
            return actions.checkCommerceUserFailed('Failed');
          }
        })
      );
    })
  );

export default accountCheckingEpic;
