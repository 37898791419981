import produce from 'immer';

import * as cartDetailTypes from '@redux/actions/couponWallet/couponWalletTypes';
import * as cartActions from '@redux/actions/product/productTypes';

const initState = {
  couponWalletList: [],
  isLoading: false,
  redirectLink: '',
  errors: []
};

const couponWalletReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cartDetailTypes.GET_COUPON_WALLET_LIST:
      draft.isLoading = true;
      break;

    case cartDetailTypes.GET_COUPON_WALLET_LIST_SUCCESS:
      draft.couponWalletList = action.payload.Promotions;

      draft.isLoading = false;
      break;

    case cartActions.GET_CURRENT_CART_FAILED:
      draft.errors = action.error.errorMessage;

      draft.redirectLink = action.error.link;
    }

    return draft;
  });

export default couponWalletReducer;
