export const GET_SO_CARTS = 'GET_SO_CARTS';
export const GET_SO_CARTS_SUCCESS = 'GET_SO_CARTS_SUCCESS';
export const GET_SO_CARTS_FAILED = 'GET_SO_CARTS_FAILED';

export const GET_SO_CARTS_ACTIVE = 'GET_SO_CARTS_ACTIVE';
export const GET_SO_CARTS_ACTIVE_SUCCESS = 'GET_SO_CARTS_ACTIVE_SUCCESS';
export const GET_SO_CARTS_ACTIVE_FAILED = 'GET_SO_CARTS_ACTIVE_FAILED';

export const GET_SO_CARTS_DRAFT = 'GET_SO_CARTS_DRAFT';
export const GET_SO_CARTS_DRAFT_SUCCESS = 'GET_SO_CARTS_DRAFT_SUCCESS';
export const GET_SO_CARTS_DRAFT_FAILED = 'GET_SO_CARTS_DRAFT_FAILED';

export const GET_SO_CARTS_VIEWED = 'GET_SO_CARTS_VIEWED';
export const GET_SO_CARTS_VIEWED_SUCCESS = 'GET_SO_CARTS_VIEWED_SUCCESS';
export const GET_SO_CARTS_VIEWED_FAILED = 'GET_SO_CARTS_VIEWED_FAILED';

export const GET_SO_CARTS_COMPLETED = 'GET_SO_CARTS_COMPLETED';
export const GET_SO_CARTS_COMPLETED_SUCCESS = 'GET_SO_CARTS_COMPLETED_SUCCESS';
export const GET_SO_CARTS_COMPLETED_FAILED = 'GET_SO_CARTS_COMPLETED_FAILED';

export const GET_SO_CARTS_EXPIRED = 'GET_SO_CARTS_EXPIRED';
export const GET_SO_CARTS_EXPIRED_SUCCESS = 'GET_SO_CARTS_EXPIRED_SUCCESS';
export const GET_SO_CARTS_EXPIRED_FAILED = 'GET_SO_CARTS_EXPIRED_FAILED';