import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import Loading from '../../../Share/Loading';
import DashboardLink from '../DashboardLink';

const DashboardGroupLinks = ({ cPOverviewFields, cpOverviewData }) => {
  const countSOCart = useSelector((state) => state?.getSOCartsReducer);
  const isOverviewBoxLoading = useSelector((state) => state.cpOverviewReducer.isLoading);

  return (
    <div className='dashboard-group-links'>
      <div className='container tablet-container'>
        <div className='row'>
          {cPOverviewFields['Overview Elements']
            ? cPOverviewFields['Overview Elements'].map((elementData, index) => (
              <div className='col-md-4' key={elementData.id || index}>
                {!isOverviewBoxLoading ? (
                  <DashboardLink elementData={elementData} cpOverviewData={cpOverviewData} onCountSOCart={countSOCart} />
                ) : (
                  <div className='dashboard-link'>
                    <Loading />
                  </div>
                )}
              </div>
            ))
            : ''}
        </div>
      </div>
    </div>
  );
};

DashboardGroupLinks.propTypes = {
  cPOverviewFields: PropTypes.any,
  cpOverviewData: PropTypes.any
};

export default DashboardGroupLinks;
