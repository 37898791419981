import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TextArray = [
  'Title',
  'View All Label',
  'View Label',
  'No Appointment Text',
  'View Loyalty Label',
  'Guests Label'
];

const LinkArray = [
  'View All Link',
  'View Link',
  'View Loyalty Link'
];

export default class MyAppointmentsWidgetModel {
  constructor() {
    TextArray.forEach(text => {
      this[text] = new SitecoreTextModel();
    });

    LinkArray.forEach(link=> {
      this[link] = new SitecoreLinkModel();
    });

    this['myAppointment'] = {};
  }

  getData(dataSource) {
    TextArray.forEach(text => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text] = dataSource[text] ?? '';
      }
    });


    LinkArray.forEach(link => {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link] = dataSource[link] ?? '';
      }
    });

    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource,'myAppointment')){
      this['myAppointment'] = deepCopy(dataSource['myAppointment']);
    }

    return this;
  }
}