import { object } from 'prop-types';
import React from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

const SaveSOSuccessHeader = ({ layoutData }) => {
  return (
    <div className='save-so-success__header'>
      <div className='header-icon'>
        <div className='header-icon-wrapper'>
          <Image field={layoutData?.['Success icon']} className='header-icon-img' />
        </div>
      </div>
      <Text tag='h6' field={layoutData?.['Sale order is saved']} className='header-title' />
      <Text tag='p' field={layoutData?.['Success message']} className='header-description' />
    </div>
  );
};

SaveSOSuccessHeader.propTypes = {
  layoutData: object
};

export default SaveSOSuccessHeader;
