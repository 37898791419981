import { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LIST_BENEFIT_KEY, LIST_PRODUCT_FUNCTION, PRODUCT_SUMMARY_KEY } from '../ProductFunctionsDetail';
import useMapProductCompareData from './useMapProductCompareData';
import useSortGeneralProductFunction from './useSortGeneralProductFunction';
import useSortListProductFunction from './useSortListProductFunction';

const useCompareProductData = () => {
  const { productCompareData } = useSelector((state) => state.compareProductReducer);
  const mappedProductCompareData = useMapProductCompareData();
  const sortListProductFunction = useSortListProductFunction();
  const sortGeneralProductFunction = useSortGeneralProductFunction();

  const handleSortCompareProductData = useCallback((compareData) => {
    const compareDataTemp = [...compareData];

    for (const compareDataItem of compareDataTemp) {
      if (compareDataItem?.productInfoDetail) {
        const productInfoDetail = compareDataItem.productInfoDetail;

        for (const key of Object.keys(productInfoDetail)) {
          if (key !== PRODUCT_SUMMARY_KEY && key !== LIST_PRODUCT_FUNCTION && key !== LIST_BENEFIT_KEY) {
            if (productInfoDetail?.[key]) {
              const generalProductFunctionSortedData = [...sortGeneralProductFunction(compareData, productInfoDetail[key], key)];

              compareDataItem.productInfoDetail[key] = [...generalProductFunctionSortedData];
            }
          }
          if (key === LIST_PRODUCT_FUNCTION && productInfoDetail?.ListProductFunction) {
            const listProductFunctionSortedData = [...sortListProductFunction(compareData, productInfoDetail.ListProductFunction)];

            compareDataItem.productInfoDetail.ListProductFunction = [...listProductFunctionSortedData];
          }
        }
      }
    }

    return [...compareDataTemp];
  }, []);

  return useMemo(() => {
    const compareData = mappedProductCompareData();

    return handleSortCompareProductData(compareData);
  }, [productCompareData, mappedProductCompareData]);
};

export default useCompareProductData;
