import SitecoreTextModel from '@models/SitecoreTextModel';
import TextModel from '@models/TextModel';

import VideoCoverImageModel from './VideoCoverImageModel';
import VideoLinkModel from './VideoLinkModel';

export default class VideoModel {
  constructor() {
    this ['Background Video'] = new VideoLinkModel();

    this['Cover Image'] = new VideoCoverImageModel();

    this['Cover Mobile Image'] = new VideoCoverImageModel();

    this['Description'] = new TextModel();

    this['Title'] = new TextModel();

    this['Video'] = new VideoLinkModel();

    this['Vimeo video ID'] = new TextModel();

    this['Youtube video ID'] = new TextModel();

    this['Link Video'] = '';

    this['Is Local Video'] = true;

    this['Video Id'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Video')) {
      this['Background Video'].getData(dataSource['Background Video']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cover Image')) {
      this['Cover Image'].getData(dataSource['Cover Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cover Mobile Image')) {
      this['Cover Mobile Image'].getData(dataSource['Cover Mobile Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video')) {
      this['Video'].getData(dataSource['Video']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Vimeo video ID')) {
      this['Vimeo video ID'].getData(dataSource['Vimeo video ID']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Youtube video ID')) {
      this['Youtube video ID'].getData(dataSource['Youtube video ID']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video') && Object.prototype.hasOwnProperty.call(dataSource, 'Youtube video ID') && Object.prototype.hasOwnProperty.call(dataSource, 'Vimeo video ID')) {
      this['Link Video'] =
        this['Video'].value?.href !== ''
          ? this['Video'].value.href
          : null || this['Youtube video ID'].value !== ''
            ? `www.youtube.com/watch?v=${this['Youtube video ID'].value}`
            : null || this['Vimeo video ID'].value !== ''
              ? `https://vimeo.com/${this['Vimeo video ID'].value}`
              : null;

      this['Is Local Video'] = this['Video'].value?.href !== '' ? true : false;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video Id')) {
      this['Video Id'].getData(dataSource['Video Id']);
    }
    
    return this;
  }
}
