import BuyNowLinkModel from '@components/CampaignProductListing/model/BuyNowLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Title', 'Buy Now Text', 'Anchor ID'];

export default class TradeInSpecialRewardsModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Buy Now Link'] = new BuyNowLinkModel();

    this['TradeInSpecialListing'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Link')) {
      this['Buy Now Link'] = dataSource['Buy Now Link'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TradeInSpecialListing')) {
      this['TradeInSpecialListing'] = dataSource['TradeInSpecialListing'];
    }

    return this;
  }
}
