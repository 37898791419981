import SitecoreImageModel from '@models/SitecoreImageModel';

export default class DataSourceModel {
  constructor() {
    this['Hero Image'] = new SitecoreImageModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Hero Image')) {
      this['Hero Image'].getData(dataSource['Hero Image']);
    }
    
    return this;
  }
}
