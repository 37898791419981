import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import UserInfoModel from '@components/SingleSignOn/models/UserInfoModel';
import * as actions from '@redux/actions/myAccount';
import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';
import { postAsObservable } from '@services/genericService';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE } from '@utils/constant';
import { formatDateToUTC } from '@utils/dateFormatV2';

import * as config from './config';

export const updateInfoEpic = (action$) =>
  action$.pipe(
    ofType(myAccountTypes.UPDATE_USER_PROFILE),
    switchMap((action) => {
      const params = {
        ...action.payload,
        LastName: action.payload.LastName || DEFAULT_LAST_NAME_API_PAYLOAD_VALUE,
        DoB: formatDateToUTC(action.payload.DoB)
      };

      return postAsObservable(config.ACTION_UPDATE_USERPROFILE, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            const userInfoModel = new UserInfoModel().getData(res.data?.Data || {});

            return actions.updateUserProfileSuccess({
              Data: userInfoModel,
              Message: res.data?.Info?.[0]
            });
          } else {
            return actions.updateUserProfileFailed({Message: res.data?.Errors?.[0] || 'Failed'});
          }
        })
      );
    })
  );