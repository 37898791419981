import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';

import CPOverviewElementModel from '../Models/CPOverviewElementModel';

export default class CPOverviewModel {
  constructor() {
    this['Overview Elements'] = [];

    this['Overview Label'] = new SitecoreTextModel();
    this['Connect Task List Label'] = new SitecoreTextModel();
    this['Connect Task List Link'] = new SitecoreLinkModel();

  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Overview Elements')) {
      if (dataSource['Overview Elements']?.length) {
        dataSource['Overview Elements'].map((item) => {
          this['Overview Elements'].push(new CPOverviewElementModel().getData(item || {}));
        });
      }
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Overview Label')) {
      this['Overview Label'].getData(dataSource['Overview Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Connect Task List Label')) {
      this['Connect Task List Label'].getData(dataSource['Connect Task List Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Connect Task List Link')) {
      this['Connect Task List Link'].getData(dataSource['Connect Task List Link']);
    }

    return this;
  }
}