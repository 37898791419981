import React, { useLayoutEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';

import useSelectStoreAndDateContext from '../useSelectStoreAndDateContext';
import { generateDateRange } from '../utils';
import SearchFilterStoreBar from './searchFilterStoreBar';

const SelectStore = () => {
  const SELECT_STORE_TITLE_ID = 'select-store-title';
  const selectStoreContainerRef = useRef(null);
  const { layoutData, formik } = useSelectStoreAndDateContext();
  const { Stores } = layoutData?.['campaign'];
  const { headerHeight, cookieBannerHeight } = useSelector((state) => state.layoutElementStyleReducer);
  const [keyword, setKeyWord] = useState('');
  const [filterAreaValue, setFilterAreaValue] = useState(null);

  useLayoutEffect(() => {
    if (selectStoreContainerRef?.current) {
      const MARGIN_TOP = 20;
      const selectStoreElCurrent = selectStoreContainerRef.current;
      const selectStoreElBounding = selectStoreElCurrent?.getBoundingClientRect();

      const selectStoreTitleEl = selectStoreElCurrent.querySelector(`#${SELECT_STORE_TITLE_ID}`);
      const selectStoreTitleComputedStyle = window.getComputedStyle(selectStoreTitleEl);
      const selectStoreTitleElHeight = selectStoreTitleEl?.offsetHeight || 0;

      const selectStoreTitleElMarginBottom =
        Number.parseFloat(selectStoreTitleComputedStyle?.getPropertyValue('margin-bottom')?.replace('px', '')) || 0;

      if (selectStoreElBounding)
        window.scroll({
          top:
            selectStoreElBounding?.top +
            window.scrollY -
            (headerHeight + cookieBannerHeight + selectStoreTitleElHeight + selectStoreTitleElMarginBottom + MARGIN_TOP),
          behavior: 'smooth'
        });
    }
  }, [selectStoreContainerRef]);

  const storeData = useMemo(() => {
    const filterData = Stores.filter((store) =>
      // eslint-disable-next-line no-unused-vars
      filterAreaValue ? Object.entries(filterAreaValue)?.filter(([key, value]) => value)?.every(([key, value]) => store?.Address?.[key] === value) : true
    );

    if (keyword && filterAreaValue) return [...filterData].filter((store) => store?.Name?.toLowerCase()?.includes(keyword));
    if (filterAreaValue) return [...filterData];

    return Stores.filter((store) => store?.Name?.toLowerCase().includes(keyword));
  }, [Stores, keyword, filterAreaValue]);
  const handleSearchStore = (keyword) => setKeyWord(keyword);

  const handleFilterArea = (params) => {
    setFilterAreaValue(params);
  };

  const handleClearFilter = () => setFilterAreaValue(null);

  return (
    <div ref={selectStoreContainerRef} className='col-12 col-lg-4 select-store__content__list'>
      <Text
        id={SELECT_STORE_TITLE_ID}
        className='select-store__content__list__title'
        tag='h4'
        field={layoutData.selectStoreLabel || layoutData?.['Select A Store Label']}
      />
      <div className='select-store__content__list__group'>
        <SearchFilterStoreBar onSearch={handleSearchStore} onFilter={handleFilterArea} onClearFilterArea={handleClearFilter} />
        {Stores && storeData.length ? (
          storeData?.map((store) =>
            generateDateRange(storeData, store.Id)?.length ? (
              <div key={store?.Id} className='select-store__content__list__group__item form-group'>
                <input
                  id={store?.Id}
                  value={store?.Id}
                  defaultChecked={formik.values.storeId === store?.Id}
                  name='store'
                  type={'radio'}
                  onChange={(event) => formik.setFieldValue('storeId', event.target.value)}
                />
                <label className='select-store__content__list__group__item__label' htmlFor={store?.Id}>
                  <Text field={{ value: store?.Name }} tag='h5' className='title select-store__content__list__group__item__title' />
                  <RichText field={{ value: store?.Address?.Address }} tag='p' />
                  {store?.Address?.PostalCode ? <Text field={{ value: store?.Address?.PostalCode }} tag='p' /> : <></>}
                  {store?.PhoneNumber ? <Text field={{ value: `+${store?.PhoneNumber}` }} tag='p' /> : <></>}
                </label>
              </div>
            ) : (
              <></>
            )
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default SelectStore;
