// Auto Complete
export const GET_AUTO_COMPLETE_ALL = 'GET_AUTO_COMPLETE_ALL';

export const GET_AUTO_COMPLETE_WELLNESS = 'GET_AUTO_COMPLETE_WELLNESS';

export const GET_AUTO_COMPLETE_PRODUCT = 'GET_AUTO_COMPLETE_PRODUCT';

export const GET_AUTO_COMPLETE_POLICY = 'GET_AUTO_COMPLETE_POLICY';

export const GET_AUTO_COMPLETE_RESOURCE = 'GET_AUTO_COMPLETE_RESOURCE';

// Auto Complete Success
export const GET_AUTO_COMPLETE_ALL_SUCCESS = 'GET_AUTO_COMPLETE_ALL_SUCCESS';

export const GET_AUTO_COMPLETE_WELLNESS_SUCCESS = 'GET_AUTO_COMPLETE_WELLNESS_SUCCESS';

export const GET_AUTO_COMPLETE_PRODUCT_SUCCESS = 'GET_AUTO_COMPLETE_PRODUCT_SUCCESS';

export const GET_AUTO_COMPLETE_POLICY_SUCCESS = 'GET_AUTO_COMPLETE_POLICY_SUCCESS';

export const GET_AUTO_COMPLETE_RESOURCE_SUCCESS = 'GET_AUTO_COMPLETE_RESOURCE_SUCCESS';

// Auto Complete Failed
export const GET_AUTO_COMPLETE_ALL_FAILED = 'GET_AUTO_COMPLETE_ALL_FAILED';

export const GET_AUTO_COMPLETE_WELLNESS_FAILED = 'GET_AUTO_COMPLETE_WELLNESS_FAILED';

export const GET_AUTO_COMPLETE_PRODUCT_FAILED = 'GET_AUTO_COMPLETE_PRODUCT_FAILED';

export const GET_AUTO_COMPLETE_POLICY_FAILED = 'GET_AUTO_COMPLETE_POLICY_FAILED';

export const GET_AUTO_COMPLETE_RESOURCE_FAILED = 'GET_AUTO_COMPLETE_RESOURCE_FAILED';

// Search Common
export const COMMON_SEARCH_ALL = 'COMMON_SEARCH_ALL';

export const COMMON_SEARCH_WELLNESS = 'COMMON_SEARCH_WELLNESS';

export const COMMON_SEARCH_PRODUCT = 'COMMON_SEARCH_PRODUCT';

export const COMMON_SEARCH_PRODUCT_FILTER = 'COMMON_SEARCH_PRODUCT_FILTER';

export const COMMON_SEARCH_POLICY = 'COMMON_SEARCH_POLICY';

export const COMMON_SEARCH_RESOURCE = 'COMMON_SEARCH_RESOURCE';

// Search Common Success
export const COMMON_SEARCH_ALL_SUCCESS = 'COMMON_SEARCH_ALL_SUCCESS';

export const COMMON_SEARCH_WELLNESS_SUCCESS = 'COMMON_SEARCH_WELLNESS_SUCCESS';

export const COMMON_SEARCH_PRODUCT_SUCCESS = 'COMMON_SEARCH_PRODUCT_SUCCESS';

export const COMMON_SEARCH_POLICY_SUCCESS = 'COMMON_SEARCH_POLICY_SUCCESS';

export const COMMON_SEARCH_RESOURCE_SUCCESS = 'COMMON_SEARCH_RESOURCE_SUCCESS';

// Search Common Failed
export const COMMON_SEARCH_ALL_FAILED = 'COMMON_SEARCH_ALL_FAILED';

export const COMMON_SEARCH_WELLNESS_FAILED = 'COMMON_SEARCH_WELLNESS_FAILED';

export const COMMON_SEARCH_PRODUCT_FAILED = 'COMMON_SEARCH_PRODUCT_FAILED';

export const COMMON_SEARCH_POLICY_FAILED = 'COMMON_SEARCH_POLICY_FAILED';

export const COMMON_SEARCH_RESOURCE_FAILED = 'COMMON_SEARCH_RESOURCE_FAILED';