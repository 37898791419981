import './ListItemSwiper.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useMemo } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';

const ListItemSwiper = (props) => {
  const {data, type, dataCallBack, giftSelectedId, isOpen, giftSwiperRef, lineId} = props;
  const [dataItem, setDataItem] = useState([]);

  useMemo(() => {
    let newData = data ? data.map(item => {
      return {
        ...item,
        isActive: item.ItemId === giftSelectedId,
      };
    }) : [];

    setDataItem(newData);
  }, [data, isOpen]);

  useEffect(() => {
    let itemSelectedInd = data.findIndex(element => element.ItemId === giftSelectedId);

    itemSelectedInd > -1 && giftSwiperRef.current.swiper.slideTo(itemSelectedInd, 100, false);
  }, []);

  const params = {
    slidesPerView: 3.5,
    centeredSlides: false,
    spaceBetween: 25,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    breakpoints: {
      1200: {
        slidesPerView: 5,
        spaceBetween: 27,
        navigation: {
          nextEl: `.list-item-swiper .${type}${lineId}.swiper-button-next`,
          prevEl: `.list-item-swiper .${type}${lineId}.swiper-button-prev`,
        },
      },

      767: {
        slidesPerView: 5,
        spaceBetween: 27,
        navigation: {
          nextEl: `.list-item-swiper .${type}${lineId}.swiper-button-next`,
          prevEl: `.list-item-swiper .${type}${lineId}.swiper-button-prev`,
        },
      }
    }
  };

  const selectItem = (item, index) => {
    let listItems =  dataItem && dataItem.map((element, idx) => {
      if(idx === index){
        if (element.isActive) {
          element.isActive = false;
        } else {
          element.isActive = true;
        }
      }else{
        element.isActive = false;
      }

      return element;
    });

    setDataItem(listItems);
    
    dataCallBack({
      type,
      ...item
    });
  };

  return (
    <div className='list-item-swiper'>
      <SwipeComponent param={params} setRef={giftSwiperRef}>
        {
          dataItem?.length ? dataItem.map((item, index) => {
            return (
              <div 
                className={`list-item-swiper__slider ${item.isActive ? 'selected' : ''} ${item.IsOutOfStock ? 'disabled' : ''}`} 
                onClick={() => selectItem(item, index)} 
                key={item.ItemId}>
                <div className='list-item-swiper__slider__image'>
                  <img src={item.Image} alt={item.ItemName} />
                  {
                    item.isActive ? (
                      <span className='check-mark'></span>
                    ) : ''
                  }
                </div>
                <p className='gift-name'>{item.ItemName}</p>
                <div className='gift-price'>{Number(item.PriceAmount) > 0 ? item.Price : 'Free'}</div>
              </div>
            );
          }) : (<div></div>)
        }
      </SwipeComponent>
      <div className={type + lineId +' '+ 'swiper-button-next'}></div>
      <div className={type + lineId +' '+ 'swiper-button-prev'}></div>
    </div>
  );
};

ListItemSwiper.propTypes = {
  data: PropTypes.any,
  dataCallBack: PropTypes.any,
  type: PropTypes.string,
  giftSelectedId: PropTypes.string,
  isOpen: PropTypes.bool,
  giftSwiperRef: PropTypes.any,
  lineId: PropTypes.string
};

export default ListItemSwiper;
