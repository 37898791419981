import PropTypes from 'prop-types';
import React from 'react';

const CustomerProductInfo = ({data}) => {
  return (
    <div className='box-data__row'>
      {/* <div className='box-data__col-2'>
        <div className='box-data__title --sub'>uCozy 3D</div>
      </div>
      <div className='box-data__col-1'>
        <div className='highlight-text'>
          <span>Warranty till 20 Feb 2021</span>
        </div>
      </div> */}
      <div className='box-data__col-1'>
        <div className='box-data__title --sub'>{data.DisplayName}</div>
      </div>
    </div>
  );
};

CustomerProductInfo.propTypes = {
  data: PropTypes.any,
};

export default CustomerProductInfo;