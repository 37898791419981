import { useCallback } from 'react';

import { useQuery } from '@utils/customsHook/useQuery';

export const VIEW_OFFER_DETAIL_CART_ID_KEY = 'cartId';

export const VIEW_OFFER_DETAIL_STAFF_ID_KEY = 'staff';

const useGenerateViewOfferDetailLink = () => {
  const query = useQuery();

  return useCallback(
    ({ cartName, staffId, viewDetailLinkObject }) => {
      const linkValue = viewDetailLinkObject?.value || '';
      const linkHref = linkValue?.href || '';
      if (cartName) query.set(VIEW_OFFER_DETAIL_CART_ID_KEY, cartName);
      if (staffId) query.set(VIEW_OFFER_DETAIL_STAFF_ID_KEY, staffId);

      return {
        ...viewDetailLinkObject,
        value: {
          ...linkValue,
          href: `${linkHref}?${query.toString()}`
        }
      };
    },
    [query]
  );
};

export default useGenerateViewOfferDetailLink;
