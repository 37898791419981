import PropTypes from 'prop-types';
import React, {useMemo, useState} from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const CartTradeInItem = ({ dataSources, tradeInInfo, freeGiftPomotion }) => {
  const [isShowModal, setShowModal] = useState(null);
  let params = {
    centered: true
  };

  const tradeInImages = useMemo(() => {
    return tradeInInfo?.TradeInImage || [];
  }, [tradeInInfo]);

  return (
    <div className='cart-products__item__trade-in justify-space-between'>
      <div className='cart-products__item__trade-in__contents'>
        <div className='product-info-icon product-info-icon--trade-in'></div>
        <div className='cart-products__item__trade-in__contents__wrap'>
          <div className='flex-between flex-between--space-between'>
            <span className='option-name'>
              <Text field={dataSources['Trade In Label']} /> {tradeInInfo.DisplayName} {+freeGiftPomotion > 1 ? `x ${freeGiftPomotion}` : ''}{' '}
            </span>
            <span>{tradeInInfo.LineFinalTotalAmount !== 0 ? tradeInInfo.TradeInProductPrice : ''}</span>
          </div>
          <span className='link-text-underline link-text-underline--lg' onClick={() => setShowModal(true)}>
            {tradeInInfo?.TradeInProductBrand} - {tradeInInfo?.TradeInProductName} - {tradeInInfo?.TradeInProductModel}
          </span>
        </div>
        {/* <div>
          <span onClick={() => setShowModal(true)}>
            {secondTypeItemsName?.values?.brand} - {secondTypeItemsName?.values?.productName} - {secondTypeItemsName?.values?.model}
          </span>
        </div> */}
      </div>
      <ModalComponent isShow={isShowModal} cssClass={'tradein-popup'} onCloseHandler={() => setShowModal(false)} param={params}>
        <div className='tradein-content-modal'>
          <div className='tradein-content-modal__image'>
            {tradeInImages.length ? (
              tradeInImages.map((image, index) => (
                <div className='image-uploaded' key={index}>
                  <div className='image-uploaded__content'>
                    <img alt='trade in upload image' src={image.url} />
                  </div>
                </div>
              ))
            ) : (
              <></>
            )}
          </div>
          <h3 className='tradein-content-modal__text'>
            {tradeInInfo?.TradeInProductBrand} - {tradeInInfo?.TradeInProductName} - {tradeInInfo?.TradeInProductModel}
          </h3>
        </div>
      </ModalComponent>
    </div>
  );
};

CartTradeInItem.propTypes = {
  tradeInInfo: PropTypes.object,
  freeGiftPomotion: PropTypes.any,
  dataSources: PropTypes.object
};

export default CartTradeInItem;
