import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { filesUploadedStore,uploadMultiFiles } from '@redux/actions/productReview';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import VideoFile from './VideoFile';

const UploadMultipleFiles = ({layoutData, imageSizeError, videoSizeError, imageFormatError, videoFormatError}) => {
  const dispatch = useDispatch();
  const uploadListFilesReducer = useSelector((state) => state.productReviewReducer.uploadListFilesReducer);
  const uploadMultiFilesData = uploadListFilesReducer.uploadMultiFilesData;
  const [fileSizeErr, setFileSizeErr] = useState(null);
  const [fileFormatErr, setFileFormatErr] = useState(null);
  const [filesSelectedAPI, setFilesSelectedAPI] = useState([]);
  const [uploadFilesReview, setUploadFilesReview] = useState([]);

  const FILES_FORMAT = '.jpg, .jpeg, .png, .mp4, .mov, .mkv, .webm, video/*';
  const IMAGE_FORMATS_REGEX =  /(jpg|jpeg|png)$/;
  const VIDEO_FORMATS_REGEX =  /(quicktime|mp4|x-matroska|webm)$/; //(mov|mp4|mkv|webm)
  const IMAGE_TYPE = 'image';
  const VIDEO_TYPE = 'video';
  const IS_IMAGE_TYPE = 'isImage';
  const IS_VIDEO_TYPE = 'isVideo';
  const IMAGE_SIZE = 5 * 1048576; //5MB (~5.242.880)
  const VIDEO_SIZE = 30 * 1000000; //30MB
  const MAX_FILES = 8;

  useDidUpdateEffect(() => {
    let newFilesSelected = deepCopy(filesSelectedAPI || []);
    if (uploadMultiFilesData?.length) {
      setFilesSelectedAPI(newFilesSelected?.concat(uploadMultiFilesData));
    }
  },[uploadMultiFilesData]);

  useEffect(() => {
    if (filesSelectedAPI?.length > MAX_FILES) {
      setUploadFilesReview(filesSelectedAPI.slice(0, MAX_FILES));
    } else {
      setUploadFilesReview(filesSelectedAPI);
    }
  }, [filesSelectedAPI]);

  useEffect(() =>{
    dispatch(filesUploadedStore(formatUploadFilesAPI(uploadFilesReview)));
  }, [uploadFilesReview]);
  
  const checkTypeFile = (file) => {
    if (file?.type?.includes(IMAGE_TYPE)) {
      return IS_IMAGE_TYPE;
    }
    if (file?.type?.includes(VIDEO_TYPE)) {
      return IS_VIDEO_TYPE;
    }

    return '';
  };

  const checkSizeFile = (file) => {
    if (checkTypeFile(file) === IS_IMAGE_TYPE) {
      return {
        isStandadSize: file.size <= IMAGE_SIZE,
        type: IS_IMAGE_TYPE
      };
    }
    if (checkTypeFile(file) === IS_VIDEO_TYPE) {
      return {
        isStandadSize: file.size <= VIDEO_SIZE,
        type: IS_VIDEO_TYPE
      };
    }

    return {
      type: IS_IMAGE_TYPE
    };
  };

  const checkFormatsFile = (file) => {
    if (checkTypeFile(file) === IS_IMAGE_TYPE) {
      return {
        isStandadFormat: IMAGE_FORMATS_REGEX.test(file.type.toLowerCase()),
        type: IS_IMAGE_TYPE
      };
    }
    if (checkTypeFile(file) === IS_VIDEO_TYPE) {
      return {
        isStandadFormat: VIDEO_FORMATS_REGEX.test(file.type.toLowerCase()),
        type: IS_VIDEO_TYPE
      };
    }

    return {
      type: IS_IMAGE_TYPE
    };
  };

  const validationFiles = (fileData) => {
    if (fileData?.length > 0) {
      let newFileData = Object.values(fileData).slice(0, MAX_FILES);
      let standadFiles = [];

      newFileData.forEach(function loop(file) {
        if (!checkFormatsFile(file)?.isStandadFormat) {
          setFileFormatErr(checkFormatsFile(file).type || null);

          loop.stop = true;

          return standadFiles;
        }  else {
          setFileFormatErr(null);
        }

        if (!checkSizeFile(file)?.isStandadSize) {
          setFileSizeErr(checkSizeFile(file)?.type);

          loop.stop = true;

          return standadFiles;
        } else {
          setFileSizeErr(null);
        }

        if(loop.stop){ 
          return; //INFO: Stop forEach loop
        } else {
          standadFiles.push(file);
        }
      });

      return standadFiles;
    }

    return;
  };

  const handleUploadFiles = (fileData) => {
    if (fileData?.length) {
      dispatch(uploadMultiFiles(validationFiles(fileData)));
    }
  };

  const handleRemoveFile = (index) => {
    const newFilesSelectedAPI = deepCopy(filesSelectedAPI);

    newFilesSelectedAPI.splice(index, 1);

    setFilesSelectedAPI(newFilesSelectedAPI);
  };

  const formatUploadFilesAPI = (files) => {
    return files?.length ? files.map((file) => {
      return {
        Data: file.Data.Data,
        ContentType: Global.isImage(file.Data?.Data) ? IMAGE_TYPE : VIDEO_TYPE
      };
    }) : [];
  };

  return (
    <>
      <div className='write-review-form__form__upload-media'>
        <div className='write-review-form__form__upload-media__wrap'>
          {
            uploadFilesReview?.length ? uploadFilesReview.map((file, index) => {
              return (
                <div className={
                  `upload-media${Global.isImage(file.Data?.Data) ? ' upload-media--video-type' : ''}`
                } key={index}>
                  <span className='upload-media__remove-btn' onClick={() => handleRemoveFile(index)}>
                    <i className='icon-close'></i>
                  </span>
                  <div className='upload-media__box'>
                    {
                      Global.isImage(file.Data?.Data) ? (
                        <img 
                          className='upload-media__box__media' 
                          src={file.Data?.Data} 
                          alt='file uploaded' />
                      ) : (
                        <VideoFile videoSrc={file.Data?.Data} />
                      )
                    }
                    
                  </div>
                </div>
              );
            }) : ''
          }
          {
            uploadFilesReview?.length < MAX_FILES ? (
              <div className='upload-media'>
                <div className='upload-media__box'>
                  <img className='upload-media__box__icon' src={require('@assets/images/icons/upload-icon-small.png')} alt='icon upload' />
                  <span className='upload-media__box__number'>{`${uploadFilesReview?.length || 0}/${MAX_FILES}`}</span>
                </div>
                <input
                  type='file'
                  name='reviewMedia'
                  id='reviewMedia'
                  className='input-absolute'
                  accept={FILES_FORMAT}
                  // multiple
                  onChange={(event) => handleUploadFiles(event.target.files)}
                  onClick={(event)=> {
                    event.target.value = null;
                  }}
                />
              </div>
            ) : ''
          }
        
        </div>
        <Text tag='p' field={layoutData['Suggest Update Image Text']} className='write-review-form__form__upload-media__info text-center' />
        {
          fileSizeErr ? (
            fileSizeErr === IS_VIDEO_TYPE ? (
              <span className='error-validate text-center'>{videoSizeError}</span>
            ) : (
              <span className='error-validate text-center'>{imageSizeError}</span>
            )
          ) 
            : ''
        }
        {
          fileFormatErr ? (
            fileFormatErr === IS_VIDEO_TYPE ? (
              <span className='error-validate text-center'>{videoFormatError}</span>
            ) : (
              <span className='error-validate text-center'>{imageFormatError}</span>
            )
          ) 
            : ''
        }
      </div>
      {
        uploadListFilesReducer.isLoading ?  <LoadingMask></LoadingMask> : null
      }
    </>
  );
};

UploadMultipleFiles.propTypes = {
  layoutData: PropTypes.object,
  imageSizeError: PropTypes.any,
  videoSizeError: PropTypes.any,
  videoFormatError: PropTypes.any,
  imageFormatError: PropTypes.any,
};

export default UploadMultipleFiles;