import './RelatedFreeGift.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import ResponsiveImage from '@components/ResponsiveImage';
import SwipeComponent from '@components/Share/SwipeComponent';
import ProductDetailPopUpModel from '@models/apiModels/ProductDetailPopUpModel';
import { getProductDetailSingle } from '@redux/actions/product';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

// import FreeGiftModel from './models/FreeGiftModel';

const RelatedFreeGift = ({ fields, listFreeGift, params, variantActiveDefault }) => {
  const componentId = params && params['Component Id'] || '';
  const dispatch = useDispatch();
  const [inputData, setInputData] = useState(null);
  const [freeGiftByVariant, setFreeGiftByVariant] = useState(null);
  const productDetailPopUpModel = new ProductDetailPopUpModel();

  useEffect(() => {
    dispatch(getProductDetailSingle());

    setInputData(deepCopy(fields || {}));

    setFreeGiftByVariant(listFreeGift.FreeGifts);
  }, []);

  useEffect(() => {
    if (variantActiveDefault && variantActiveDefault.VariantId !== '') {
      let findFreeGift = listFreeGift.VariantFreeGifts?.find(item => item.VariantId === variantActiveDefault.VariantId);
      if (findFreeGift) {
        setFreeGiftByVariant(findFreeGift.FreeGifts);
      } else {
        setFreeGiftByVariant(listFreeGift.FreeGifts);
      }
    }
  }, [variantActiveDefault, listFreeGift]);

  let swiperParams = {
    lazy: true,
    rebuildOnUpdate: true,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 24,
    watchOverflow: true,
    centerInsufficientSlides: true,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },

    breakpoints : {
      768: {
        spaceBetween: 30,
        slidesPerView: 3,
      },
      1200: {
        spaceBetween: 40,
        slidesPerView: 4,
      },
    },
  };

  return (
    inputData && freeGiftByVariant?.length > 0 && (
      <div className='related-gift' id={componentId}>
        <picture className='related-gift__background'>
          <ResponsiveImage
            imageMobile={fields['Mobile Background Image']}
            srcSet={[ { mw: 768, image: fields['Background Image']} ]}
          />
        </picture>
        <div className='related-gift__content'>
          <div className='container'>
            <Text className='related-gift__title experience-title' field={fields['Title']} tag='h2' />
            <div className='related-gift__list'>
              <SwipeComponent param={swiperParams}>
                {freeGiftByVariant.map((freeGiftItem, freeGiftIdx) => {
                  return (
                    <div className='related-gift__list__item' key={freeGiftIdx}>
                      <div className='related-gift__list__item__image'>
                        <img className='related-gift-image swiper-lazy animated' src={productDetailPopUpModel.getData(freeGiftItem).ProductSummary.Image} alt='free gift' />
                        <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
                      </div>
                      <h3 className='related-gift__list__item__name'>{productDetailPopUpModel.getData(freeGiftItem).DisplayName}</h3>
                    </div>
                  );
                })}
              </SwipeComponent>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

RelatedFreeGift.propTypes = {
  fields: PropTypes.any,
  params: PropTypes.any,
  variantActiveDefault: PropTypes.any,
  listFreeGift: PropTypes.object
};

const mapStateToProps = (state) => ({
  listFreeGift: state.productReducer.freeGiftReducer?.freeGifts || {},
  variantActiveDefault: state.productReducer.productDetailOverviewReducer.VariantActiveDefault,
});

export default connect(mapStateToProps)(RelatedFreeGift);
