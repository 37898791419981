import produce from 'immer';

import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';

const initState = {
  isLoading: false,
  bundleElement: null
};

const getBundleElementReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productReviewTypes.GET_BUNDLE_ELEMENT:
      draft.isLoading = true;
      break;

    case productReviewTypes.GET_BUNDLE_ELEMENT_SUCCESS:
      draft.isLoading = false;

      draft.bundleElement = action.payload.BundleElements;
      break;

    case productReviewTypes.GET_BUNDLE_ELEMENT_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default getBundleElementReducer;
