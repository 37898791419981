import './ContactUsCTA.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import ContactUsCTAModel from './model/ContactUsCTAModel';

const ContactUsCTA = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new ContactUsCTAModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    <div className='container contact-us-cta'>
      <div className='contact-us-cta__box'>
        <Text tag='h2' field={layoutData['Title']} className='title' />
        <Text tag='h5' field={layoutData['Description Title']} className='description-title' />
        <div className='row contact-us-cta__detail'>
          {layoutData['items'].map((item) => (
            <div key={item.id} className='col-md-6 col-xl-4 contact-us-cta__item'>
              <div className='icon'>
                <div className='icon-wrapper'>
                  <img src={item.fields?.Image?.value?.src} alt='' />
                </div>
              </div>
              <div className='content'>
                <div dangerouslySetInnerHTML={{ __html: item.fields?.Title?.value }}></div>
              </div>
            </div>
          ))}
        </div>
        <div className='contact-us-cta__cta'>
          <Link field={layoutData['CTA Button Link']} className='btn btn-outline-primary'>
            <Text field={layoutData['CTA Button Text']} />
          </Link>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

ContactUsCTA.propTypes = {
  fields: object
};

export default ContactUsCTA;
