import { useMemo } from 'react';

import { useQuery } from '@utils/customsHook/useQuery';

import { SELECT_PRODUCT_STEP, STEP_QUERY_STRING_NAME } from '../WarrantyGeneral';

export const useCurrentWarrantyRegistrationStep = () => {
  const query = useQuery();

  return useMemo(() => {
    return query.get(STEP_QUERY_STRING_NAME) || SELECT_PRODUCT_STEP;
  }, [query]);
};
