import './TradeInSpecialRewards.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import TradeInSpecialRewardsModel from './Models/TradeInSpecialRewardsModel';
import TradeInSpecialRewardsSwiper from './TradeInSpecialRewardsSwiper/index';

const TradeInSpecialRewards = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    let model = new TradeInSpecialRewardsModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData?.['TradeInSpecialListing']?.length ? (
    <div className='trade-in-special' id={layoutData?.['Anchor ID']?.value}>
      <div className='container'>
        <Text tag='h3' field={layoutData['Title']} className='trade-in-special__title' />
        <div className='trade-in-special__content'>
          <TradeInSpecialRewardsSwiper layoutData={layoutData} />
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

TradeInSpecialRewards.propTypes = {
  fields: object
};

export default TradeInSpecialRewards;