import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { connect, useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import LoadingMask from '../Share/LoadingMask';
import AutomaticallyAddedGift from './AutomaticallyAddedGift';
import { useScrollToSections } from './hooks';
import SelectingFreeGift from './SelectingFreeGift';
import SelectingFreeGiftProvider from './SelectingFreeGiftProvider';

export const PAYMENT_PAGE_NAME = 'review-payment';

const FreeGiftPopup = (props) => {
  const { fields, currentCart } = props;
  const pageName = SitecoreContextFactoryService.getValueContextItem('route')?.name;
  const { isLoading: isAddingFreeGift, isAddFreeGiftSuccess } = useSelector((state) => state.addFreeGiftToCartReducer);
  const { isLoading: isUpdatingFreeGift, isUpdateFreeGiftSuccess } = useSelector((state) => state.updateFreeGiftToCartReducer);
  const scrollToSection = useScrollToSections();

  useDidUpdateEffect(() => {
    if (isAddFreeGiftSuccess || isUpdateFreeGiftSuccess) scrollToSection();
  }, [isAddFreeGiftSuccess, scrollToSection, isUpdateFreeGiftSuccess]);

  const hasFreeGiftPopup = useMemo(() => {
    const freeGiftPopup = currentCart?.FreeGiftPopup;

    return pageName === PAYMENT_PAGE_NAME ? freeGiftPopup?.HasPaymentFreeGift : freeGiftPopup?.HasFreeGiftPopup;
  }, [pageName, currentCart?.FreeGiftPopup]);

  const isAutoAddFreeGift = useMemo(() => {
    const freeGiftPopup = currentCart?.FreeGiftPopup;

    return pageName === PAYMENT_PAGE_NAME ? freeGiftPopup?.IsAutoAddPaymentFreeGift : freeGiftPopup?.IsAutoAddFreeGift;
  }, [pageName, currentCart?.FreeGiftPopup]);

  return hasFreeGiftPopup ? (
    <>
      {isAutoAddFreeGift ? (
        <AutomaticallyAddedGift fields={fields} currentCart={currentCart} />
      ) : (
        <SelectingFreeGiftProvider>
          <SelectingFreeGift fields={fields} />
        </SelectingFreeGiftProvider>
      )}
      {isAddingFreeGift || isUpdatingFreeGift ? <LoadingMask /> : <></>}
    </>
  ) : (
    <></>
  );
};

FreeGiftPopup.propTypes = {
  fields: PropTypes.object,
  currentCart: PropTypes.object
};

const mapStateToProps = (state) => ({
  currentCart: state.productReducer.cartDetailReducer.currentCart
});

export default connect(mapStateToProps)(FreeGiftPopup);
