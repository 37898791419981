/* eslint-disable no-fallthrough */
import produce from 'immer';

import { 
  DELETE_RESULT_TOTAL_TIMESLOT_OF_DATE,
  GET_TOTAL_BOOKED_DATE_OF_STORE,
  GET_TOTAL_BOOKED_DATE_OF_STORE_FAILED,
  GET_TOTAL_BOOKED_DATE_OF_STORE_SUCCESS,
  GET_TOTAL_BOOKED_TIMESLOT_OF_DATE, 
  GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_FAILED, 
  GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_SUCCESS
} from '@redux/actions/bookingExperience/getTotalBookedTimeSlot/types';

const initialStates = {
  isLoading: false,
  error: null,
  totalDateBookedResult: null,
  totalTimeSlotBookedResult: null
};

const getTotalBookedTimeSlotReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_TOTAL_BOOKED_DATE_OF_STORE:

    case GET_TOTAL_BOOKED_TIMESLOT_OF_DATE:
      draft.isLoading = true;

      draft.error = null;
      break;

    case GET_TOTAL_BOOKED_DATE_OF_STORE_SUCCESS:
      draft.isLoading = false;

      draft.totalDateBookedResult = action.payload?.Result?.ListDateExist;
      break;

    case GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_SUCCESS:
      draft.isLoading = false;

      draft.totalTimeSlotBookedResult = action.payload?.Result?.TimeSlots;
      break;

    case GET_TOTAL_BOOKED_DATE_OF_STORE_FAILED:
        
    case GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

    case DELETE_RESULT_TOTAL_TIMESLOT_OF_DATE:
      draft.totalTimeSlotBookedResult = null;
      break;
    }

    return draft;
  });

export default getTotalBookedTimeSlotReducer;
