import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SimpleTilesFieldsModel {
  constructor() {
    this['items'] = [];

    this.Title = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      this['items'] = dataSource['items'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    return this;
  }
}
