import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/submitPurchaseOnSpot/';
import * as submitPurchaseOnSpotTypes from '@redux/actions/submitPurchaseOnSpot/submitPurchaseOnSpotTypes';
import { postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as configs from './config';

const submitPurchaseOnSpotEpic = (action$) =>
  action$.pipe(
    ofType(submitPurchaseOnSpotTypes.SUBMIT_PURCHASE_ON_SPOT_START),
    switchMap((action) => {
      return postAsObservable(configs.ACTION_SUBMIT_PURCHASE_ON_SPOT, action.payload).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.submitPurchaseOnSpotSuccess(deepCopy(res.data) || {});
          } else {
            return actions.submitPurchaseOnSpotFailed('Failed');
          }
        }),
        catchError((error) => of(actions.submitPurchaseOnSpotFailed(error || 'Failed')))
      );
    })
  );

export default submitPurchaseOnSpotEpic;
