import './EmbeddedHtmlCollection.scss';

import { object } from 'prop-types';
import React, { createRef, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { useWindowSize } from '@utils/customsHook/useWindowSize';

import EmbedElement from './EmbedElement';
import EmbeddedHtmlCollectionModel from './models/EmbeddedHtmlCollectionModel';

const EmbeddedHtmlCollection = ({ fields }) => {
  const SWIPER_BREAKPOINT_SMALL = 320;
  const SWIPER_BREAKPOINT_MEDIUM = 768;
  const SWIPER_BREAKPOINT_LARGE = 1440;
  const SWIPER_SPACE_BETWEEN_SMALL_BREAKPOINT = 12;
  const SWIPER_SPACE_BETWEEN_MEDIUM_BREAKPOINT = 50;
  const SWIPER_SPACE_BETWEEN_LARGE_BREAKPOINT = 80;
  const itemWrapperRef = useRef([]);
  const windowSize = useWindowSize();
  const [layoutData, setLayoutData] = useState(null);
  const [wrapperWidth, setWrapperWidth] = useState(0);

  const setAlignCenter = useMemo(() => {
    const embeddedHtmlLength = layoutData?.['embeddedHtmlComponents']?.length;

    return embeddedHtmlLength <= 2 && windowSize >= 768 ? 'align-center-swiper' : '';
  }, [windowSize, layoutData?.['embeddedHtmlComponents']?.length]);

  const embeddedHtmlComponents = useMemo(() => {
    return layoutData?.['embeddedHtmlComponents'] || [];
  }, [layoutData]);

  const checkRefCurrentNull = useMemo(() => {
    return itemWrapperRef.current.every((ref) => ref.current);
  }, [itemWrapperRef?.current, embeddedHtmlComponents]);

  // INFO: initial ref that related with each item in embeddedHtmlComponents array
  if (embeddedHtmlComponents?.length) {
    itemWrapperRef.current = embeddedHtmlComponents.map((_, index) => itemWrapperRef.current[index] ?? createRef());
  }

  useEffect(() => {
    let model = new EmbeddedHtmlCollectionModel();

    setLayoutData(model.getData(fields));
  }, []);

  useLayoutEffect(() => {
    if (checkRefCurrentNull && windowSize) {
      const handleGetWrapperItemWidth = () => {
        for (const elItem of itemWrapperRef.current) {
          const elItemCurrent = elItem?.current;
          const elOffsetWidth = elItemCurrent.offsetWidth;
          if (elOffsetWidth) setWrapperWidth(elOffsetWidth);
        }
      };

      window.addEventListener('resize', handleGetWrapperItemWidth);

      handleGetWrapperItemWidth();

      return () => window.removeEventListener('resize', handleGetWrapperItemWidth);
    }
  }, [itemWrapperRef.current, checkRefCurrentNull, windowSize]);

  const params = {
    lazy: true,
    pagination: {
      el: '.swiper-pagination',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    breakpoints: {
      [SWIPER_BREAKPOINT_SMALL]: {
        slidesPerView: 1,
        spaceBetween: SWIPER_SPACE_BETWEEN_SMALL_BREAKPOINT
      },
      [SWIPER_BREAKPOINT_MEDIUM]: {
        slidesPerView: 2,
        spaceBetween: SWIPER_SPACE_BETWEEN_MEDIUM_BREAKPOINT
      },
      [SWIPER_BREAKPOINT_LARGE]: {
        slidesPerView: 3,
        spaceBetween: SWIPER_SPACE_BETWEEN_LARGE_BREAKPOINT
      }
    }
  };

  return layoutData && layoutData?.['embeddedHtmlComponents'].length ? (
    <div className='container embedded-html'>
      <div className={`embedded-html__wrapper ${setAlignCenter}`}>
        <SwipeComponent param={params} className='embedded-html__swiper-container'>
          {embeddedHtmlComponents.map((embedItem, index) => (
            <div ref={itemWrapperRef.current[index]} key={embedItem.id} className='embedded-html__item'>
              <EmbedElement itemData={embedItem?.fields} width={wrapperWidth} />
            </div>
          ))}
        </SwipeComponent>
      </div>
    </div>
  ) : (
    <></>
  );
};

EmbeddedHtmlCollection.propTypes = {
  fields: object
};

export default EmbeddedHtmlCollection;
