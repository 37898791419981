import { combineReducers } from 'redux';

import getHadNotificationsReducer from './getHadNotifications';
import notificationDataReducer from './notificationData';
import popupToggleReducer from './popupToggle';
import readAllNotificationReducer from './readAllNotification';
import readSingleNotificationReducer from './readSingleNotification';

export const cpNotificationPopupReducer = combineReducers({
  popupToggleReducer,
  notificationDataReducer,
  readSingleNotificationReducer,
  readAllNotificationReducer,
  getHadNotificationsReducer
});
