import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import SocialNavigationItemModel from '../model/SocialNavigationItemModel';

const SocialNavigationItem = (props) => {
  const [fields, setFields] = useState(null);

  useEffect(() => {
    const fields = new SocialNavigationItemModel();

    setFields(fields.getData(deepCopy(props.data.fields)));
  }, []);

  return (
    fields && (
      <Link field={fields.Link} className='social-navigation__item' aria-label='social-navigation item link'>
        <Image field={fields.Image}  className='social-navigation__item__image' editable={true} />
      </Link>
    )
  );
};

SocialNavigationItem.propTypes = {
  data: Proptypes.object
};

export default SocialNavigationItem;
