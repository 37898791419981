
export default class PriceModel {
  constructor() {
    this.CurrencyCode = '';

    this.Discount = 0;

    this.ListPrice = 0;

    this.ListPriceWithCurrency = '';

    this.SellingPrice = 0;

    this.SellingPriceWithCurrency = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyCode') && dataSource['CurrencyCode']) {
      this.CurrencyCode = dataSource.CurrencyCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Discount') && dataSource['Discount']) {
      this.Discount = dataSource.Discount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice') && dataSource['ListPrice']) {
      this.ListPrice = dataSource.ListPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPriceWithCurrency') && dataSource['ListPriceWithCurrency']) {
      this.ListPriceWithCurrency = dataSource.ListPriceWithCurrency;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice') && dataSource['SellingPrice']) {
      this.SellingPrice = dataSource.SellingPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPriceWithCurrency') && dataSource['SellingPriceWithCurrency']) {
      this.SellingPriceWithCurrency = dataSource.SellingPriceWithCurrency;
    }

    return this;
  }
}
