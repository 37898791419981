import PropTypes from 'prop-types';
import React from 'react';

import {Text} from '@sitecore-jss/sitecore-jss-react';

import AddNewAddress from '../AddNewAddress';
import AddressItem from '../AddressItem';

const AddressBook = (props) => {
  const { userAddressList, dataSources} = props;

  return dataSources ? (
    <div className='my-addresses__section'>
      <div className='my-addresses__section__heading my-account-widget__heading'>
        <h4 className='my-addresses__section__heading__title'>
          <Text field={dataSources['Address Book Title']}/>
        </h4>
      </div>
      <div className='row'>
        {userAddressList.map((addressItem) => (
          <div className='col-lg-6' key={addressItem.ExternalId}>
            <AddressItem dataSources={dataSources} dataAddress={addressItem} />
          </div>
        ))}
      </div>
      <AddNewAddress dataSources={dataSources}/>
    </div>
  ) : '';
};

AddressBook.propTypes = {
  userAddressList: PropTypes.array,
  dataSources: PropTypes.object,
};

export default AddressBook;
