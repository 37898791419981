import { ofType } from 'redux-observable';
// import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';

const resendMailActiveEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.RESEND_MAIL_ACTIVE),
    switchMap((action) => {
      
      return postAsObservable(config.ACTION_RESEND_MAIL_ACTIVE, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.resendMailActiveSuccess(res.data);
          } else {
            return actions.resendMailActiveFailed('Failed');
          }
        })
      );
    })
  );

export default resendMailActiveEpic;
