import { bool, object } from 'prop-types';
import React, { useMemo } from 'react';

const SelectGuests = (props) => {
  const { formik, disabled, layoutData } = props;

  const arrOptionsGuest = useMemo(() => {
    return [
      {
        id: 'just-me',
        label: layoutData?.['Just Me Button Text']?.value
      },
      {
        id: 'me+1',
        label: layoutData?.['Me Plus1 Placeholder Text']?.value
      }
    ];
  }, [layoutData]);

  return (
    <dd className='appointments-infor__item__option d-flex'>
      {arrOptionsGuest?.map((option, idx) => (
        <div key={option.id} className='radio-box'>
          <input
            className='radio-box__input'
            name='optionGuest'
            type='radio'
            id={option.id}
            value={idx + 1}
            defaultChecked={formik?.values?.numberOfGuest?.value === idx + 1}
            onChange={(ev) => formik.setFieldValue('numberOfGuest.value', +ev.target.value)}
            disabled={disabled}
          />
          <label htmlFor={option.id} className='radio-box__label'>
            {option.label}
          </label>
        </div>
      ))}
      {formik.errors.numberOfGuest && formik.touched.numberOfGuest && <span className='error-validate'>{formik.errors.numberOfGuest?.value}</span>}
    </dd>
  );
};

SelectGuests.propTypes = {
  formik: object,
  disabled: bool,
  layoutData: object
};

export default SelectGuests;
