import parse from 'html-react-parser';
import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import SitecoreContextFactory from '@libs/SitecoreContextFactory';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import config from '@temp/config';

const Metadata = () => {
  const [metaData, setMetadata] = useState(null);
  const [pageTitle, setPageTitle] = useState(null);

  const itemFields = SitecoreContextFactoryService.getValueContextRouteItem('fields');
  const url = `${config.sitecoreApiHost}/${SitecoreContextFactory.context.path || ''}`;

  if (!pageTitle) {
    setPageTitle(itemFields['Page Title']?.value || SitecoreContextFactoryService.getValueContextRouteItem('displayName'));
  }

  let customMeta = [];
  if (itemFields['Custom Metadata'] && itemFields['Custom Metadata'].value) {
    itemFields['Custom Metadata'].value.split('&').map((item) => {
      customMeta.push(decodeURIComponent(item).split('='));
    });
  }
  if (itemFields['Page Description'] && itemFields['Page Description'].value && customMeta.filter((f) => f[0] == 'description').length == 0) {
    const description = itemFields['Page Description'].value;

    customMeta.push(['description', description]);
  }
  if (itemFields['Page Tags'] && itemFields['Page Tags'].value && customMeta.filter((f) => f[0] == 'keywords').length == 0) {
    const tags = itemFields['Page Tags'].value;

    customMeta.push(['keywords', tags]);
  }

  if (!metaData && customMeta) {
    setMetadata(customMeta);
  }

  const currentSiteName = useMemo(() => {
    return SitecoreContextFactoryService.getValueContextItem('site')?.name;
  }, []);

  const isHomepage = useMemo(() => {
    return SitecoreContextFactoryService.getValueContextRouteItem('templateName') === 'Home Page';
  }, []);

  const openGraphSiteName = useMemo(() => itemFields?.OpenGraphSiteName?.value, [itemFields]);

  const defaultOgSiteName = useMemo(() => {
    return openGraphSiteName || `OSIM ${currentSiteName}`;
  }, [currentSiteName, openGraphSiteName]);

  const siteName = useMemo(() => {
    return openGraphSiteName || customMeta?.find((item) => (item?.[0] ?? '') === 'site_name')?.[1] || defaultOgSiteName;
  }, [customMeta, defaultOgSiteName, openGraphSiteName]);

  return (
    metaData &&
    pageTitle && (
      <>
        <Helmet>
          <title>{pageTitle}</title>
          <meta charSet='UTF-8' />
          {customMeta.map((item, index) => {
            return item[0] == 'fb:app_id' || item[0] === 'og:site_name' ? (
              <meta key={index} property={item[0]} content={item[1]} />
            ) : (
              <meta key={index} name={item[0]} content={item[1]} />
            );
          })}
        </Helmet>
        <Helmet>
          {customMeta.filter((f) => f[0] == 'og:description').length == 0 && (
            <meta
              property='og:description'
              content={
                itemFields?.OpenGraphDescription?.value ||
                (customMeta.find((f) => f[0] == 'description') ? customMeta.find((f) => f[0] == 'description')[1] : '')
              }
            />
          )}
          {customMeta.filter((f) => f[0] == 'og:image').length == 0 && (
            <meta property='og:image' content={itemFields?.OpenGraphImageUrl?.value?.src} />
          )}
          {customMeta.filter((f) => f[0] == 'og:title').length == 0 && (
            <meta property='og:title' content={itemFields?.OpenGraphTitle?.value || pageTitle} />
          )}

          {customMeta.filter((f) => f[0] == 'og:site_name').length == 0 && <meta property='og:site_name' content={defaultOgSiteName} />}

          {customMeta.filter((f) => f[0] == 'og:type').length == 0 && <meta property='og:type' content='website' />}

          {customMeta.filter((f) => f[0] == 'og:url').length == 0 && <meta property='og:url' content={url} />}
        </Helmet>
        {isHomepage ? (
          <Helmet>
            <script type='application/ld+json'>
              {parse(`
               {
                  "@context" : "https://schema.org",
                  "@type" : "WebSite",
                  "name" : "${siteName}",
                  "url" : "${config.sitecoreApiHost}"
                }
            `)}
            </script>
          </Helmet>
        ) : (
          <></>
        )}
      </>
    )
  );
};

Metadata.propTypes = {
  fields: PropTypes.object
};

export default Metadata;
