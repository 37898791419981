import { ofType } from 'redux-observable';
import { combineEpics } from 'redux-observable';
// import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { postAsObservable } from '@services/genericService';

import * as config from '../config';

const forgotPasswordEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.FORGOT_PASSWORD),
    switchMap((action) => {
      const params = {
        Email: action.payload.Email,
      };
      
      return postAsObservable(config.ACTION_FORGOT_PASSWORD, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.forgotPasswordSuccess(res.data);
          } else {
            return actions.forgotPasswordFailed(res.data?.Errors[0] || 'Failed');
          }
        })
      );
    })
  );

const changePasswordEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.CHANGE_PASSWORD),
    switchMap((action) => {
      const params = {
        token: action.payload.token,
        newPassword: action.payload.newPassword
      };
      
      return postAsObservable(config.ACTION_CHANGE_PASSWORD, params).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            return actions.changePasswordSuccess(res.data);
          } else {
            return actions.changePasswordFailed(res.data?.Errors[0] || 'Failed');
          }
        })
      );
    })
  );

const renwePasswordEpic = combineEpics(
  changePasswordEpic,
  forgotPasswordEpic,
);

export default renwePasswordEpic;
