import PropTypes from 'prop-types';
import React from 'react';

import * as config from './config';

const YahooAuthorization = (props) => {
  const { className, buttonText, children, tag, type } = props;

  const onAuthorizationHandler = () => {
    const { clientID, redirectUri, state } = props;
    
    window.location.href = `${config.API_AUTHENTICATION_YAHOO}?client_id=${clientID}&redirect_uri=${redirectUri}&response_type=${config.RESPONSE_TYPE}&state=${state}&language=${config.LANGUAGE}`;
  };
  
  return React.createElement(
    tag,
    {
      className: className,
      onClick: onAuthorizationHandler,
      style: className ? {} : config.STYLE_DEFAULT,
      type
    },
    children || buttonText
  );
};


YahooAuthorization.defaultProps = {
  buttonText: config.BUTTON_TEXT,
  tag: config.NAME_TAG,
  type: config.TYPE,
  state: config.REDIRECT_DEFAULT
};

YahooAuthorization.propTypes = {
  clientID: PropTypes.string.isRequired,
  redirectUri: PropTypes.string.isRequired,
  buttonText: PropTypes.string,
  scope: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node,
  tag: PropTypes.string,
  type: PropTypes.string,
  state: PropTypes.string,
};

export default YahooAuthorization;
