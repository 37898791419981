import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';

export default class UserInfoModel {
  constructor() {
    this.Email = '';

    this.FirstName = '';

    this.LastName = '';

    this.PhoneNumber = '';
    
    this.Username = '';

    this.DoB = '';

    this.Gender = '';

    this.MYSitePhoneNumber = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FirstName')) {
      this.FirstName = dataSource.FirstName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Email')) {
      this.Email = dataSource.Email;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastName')) {
      this.LastName = dataSource.LastName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PhoneNumber')) {
      if (isAtSiteName(MY_SITE_NAME)) {
        this.MYSitePhoneNumber = dataSource.PhoneNumber;

        this.PhoneNumber = dataSource.PhoneNumber ? `+${dataSource.PhoneNumber}` : '';
      } else {
        this.PhoneNumber = dataSource.PhoneNumber || '';
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Username')) {
      this.Username = dataSource.Username;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DoB')) {
      this.DoB = dataSource.DoB;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Gender')) {
      this.Gender = dataSource.Gender;
    }

    return this;
  }
}
