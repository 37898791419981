import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class PersonalProfileWidgetModel {
  constructor() {
    this['Sign Out Link'] = new SitecoreLinkModel();

    this['Account Settings Link'] = new SitecoreLinkModel();

    this['Newsletter Preference Link'] = new SitecoreLinkModel();

    this['Edit Link'] = new SitecoreLinkModel();

    this['Edit Label'] = new SitecoreTextModel();

    this['Newsletter Preference Label'] = new SitecoreTextModel();

    this['Sign Out Label'] = new SitecoreTextModel();

    this['Account Settings Label'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Account Settings Link')) {
      this['Account Settings Link'].getData(dataSource['Account Settings Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Newsletter Preference Link')) {
      this['Newsletter Preference Link'].getData(dataSource['Newsletter Preference Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sign Out Link')) {
      this['Sign Out Link'].getData(dataSource['Sign Out Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Link')) {
      this['Edit Link'].getData(dataSource['Edit Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Label')) {
      this['Edit Label'].getData(dataSource['Edit Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Newsletter Preference Label')) {
      this['Newsletter Preference Label'].getData(dataSource['Newsletter Preference Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sign Out Label')) {
      this['Sign Out Label'].getData(dataSource['Sign Out Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Account Settings Label')) {
      this['Account Settings Label'].getData(dataSource['Account Settings Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}
