import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ButtonModel {
  constructor() {
    this['Label Button'] = new SitecoreTextModel();

    this['Url Button'] = new SitecoreLinkModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Label Button')) {
      this['Label Button'].getData(dataSource['Label Button']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Url Button')) {
      this['Url Button'].getData(dataSource['Url Button']);
    }

    return this;
  }
}
