import { arrayOf, bool, shape, string } from 'prop-types';
import React, { useCallback } from 'react';

import DropdownQuestion from '@components/CampaignRegisterComponent/SelectStoreAndDate/QuestionsSelect/DropdownQuestion';
import MultiSelectQuestion from '@components/CampaignRegisterComponent/SelectStoreAndDate/QuestionsSelect/MultiSelectQuestion';
import SingleQuestion from '@components/CampaignRegisterComponent/SelectStoreAndDate/QuestionsSelect/SingleQuestion';
import { deepCopy } from '@utils/utility';

import { usePersonalizeExpFormContext } from '../index';
import { QuestionTypes } from './QuestionTypes';

const SelectionItem = ({ label, questionData }) => {
  const { formik } = usePersonalizeExpFormContext();

  const handleSelectAnswer = useCallback(
    (questionType, questionId, answerId) => {
      const questionAnswersData = deepCopy(formik.values.additionalSelectionAns);
      const questionAnswering = questionAnswersData.find((question) => question.QuestionId === questionId);
      if (questionType === QuestionTypes.SINGLE_QUESTION || questionType === QuestionTypes.DROPDOWN_QUESTION) {
        if (questionAnswering?.AnswerIds.length) questionAnswering.AnswerIds.splice(0, 1, answerId);
        else questionAnswering.AnswerIds.push(answerId);
      } else {
        if (!questionAnswering.AnswerIds.includes(answerId)) questionAnswering.AnswerIds.push(answerId);
        else questionAnswering.AnswerIds.splice(questionAnswering.AnswerIds.indexOf(answerId), 1);
      }

      formik.setFieldValue('additionalSelectionAns', questionAnswersData);
    },
    [formik.values.additionalSelectionAns]
  );

  const handleGenerateDefaultCheck = (questionId, answerId) => {
    const questionData = formik.values.additionalSelectionAns.find((question) => question.QuestionId === questionId);

    return questionData?.AnswerIds.includes(answerId);
  };

  return questionData ? (
    <div className='personalize-experience__body-item'>
      <div className='additional-selection'>
        {label ? <h4 className='select-store__content__list__title'>{label}</h4> : <></>}
        <div className='additional-selection__body'>
          <p className='additional-selection__body-question__title'>{questionData.Question}</p>
          {questionData.DisplayType === QuestionTypes.SINGLE_QUESTION ? (
            <div className='form-group form-group--gender additional-selection__body-answer__radio'>
              {questionData.AvailableAnswers?.length ? (
                questionData.AvailableAnswers.map((answer) => (
                  <SingleQuestion
                    labelText={answer.Text}
                    key={answer.Id}
                    name={questionData.Id}
                    id={answer.Id}
                    checked={handleGenerateDefaultCheck(questionData.Id, answer.Id)}
                    onChange={() => handleSelectAnswer(questionData.DisplayType, questionData.Id, answer.Id)}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          ) : questionData.DisplayType === QuestionTypes.MULTI_QUESTION ? (
            <div className='form-group form-group--gender select-store__content__question__item__answer additional-selection__body-answer__checkbox'>
              {questionData.AvailableAnswers?.length ? (
                questionData.AvailableAnswers.map((answer) => (
                  <MultiSelectQuestion
                    key={answer.Id}
                    labelText={answer.Text}
                    id={answer.Id}
                    name={answer.Id}
                    onChange={() => handleSelectAnswer(questionData.DisplayType, questionData.Id, answer.Id)}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          ) : (
            <div className='form-group form-group--gender select-store__content__question__item__answer additional-selection__body-answer__dropdown'>
              <DropdownQuestion
                key={questionData.Id}
                name={questionData.Id}
                // placeholder={questionData.Question}
                answerData={questionData.AvailableAnswers}
                onChange={(answer) => handleSelectAnswer(questionData.DisplayType, questionData.Id, answer.value)}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

SelectionItem.propTypes = {
  label: string,
  questionData: shape({
    Id: string,
    Question: string,
    DisplayType: string,
    IsRequired: bool,
    AvailableAnswers: arrayOf(
      shape({
        Id: string,
        Text: string,
        IsChecked: bool
      })
    )
  })
};

export default SelectionItem;
