export default class DynamicBundleDataModel {
  constructor() {
    this.SellingPrice = 0;

    this.ListingPrice = 0;

    this['InstallmentPeriods'] = [];

    this.BundleId = '';

    this.BundleName = '';

    this.Coupon = '';

    this.Brand = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice')) {
      this['SellingPrice'] = dataSource['SellingPrice'] ?? this.SellingPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListingPrice')) {
      this['ListingPrice'] = dataSource['ListingPrice'] ?? this.ListingPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentPeriods')) {
      this['InstallmentPeriods'] = dataSource['InstallmentPeriods'] ?? this['InstallmentPeriods'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleId')) {
      this['BundleId'] = dataSource['BundleId'] ?? this.BundleId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleName')) {
      this['BundleName'] = dataSource['BundleName'] ?? this.BundleName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon')) {
      this['Coupon'] = dataSource['Coupon'] ?? this.Coupon;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Brand')) {
      this['Brand'] = dataSource['Brand'] ?? this.Brand;
    }

    return this;
  }
}
