import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const STR_ARR = [
  'Comming Event Text',
  'Comming Month Events Text',
  'No Event Message Text',
  'Title',
  'View All Label',
];

export default class MemberEventsWidgetModel {
  constructor() {
    STR_ARR.forEach((str) => {
      this[str] = new SitecoreTextModel();
    });

    this['View All Link'] = new SitecoreLinkModel();
  }

  getData(dataSource) {
    STR_ARR.forEach((str) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, str)) {
        this[str] = dataSource[str];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View All Link')) {
      this['View All Link'] = dataSource['View All Link'];
    }

    return this;
  }
}