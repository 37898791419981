import './ProductPrice.scss';

import Proptypes from 'prop-types';
import React from 'react';

import { DECIMAL_COUNT_DEFAULT } from '@utils/constant';
import Global from '@utils/global';

const ProductPrice = ({ BasePrice, SellingPrice }) => {
  return (
    <div className='product-price'>
      <h4 className='product-price__current-price'>{Global.formatCurrency(BasePrice, DECIMAL_COUNT_DEFAULT)}</h4>
      <del className='product-price__old-price'>{Global.formatCurrency(SellingPrice, DECIMAL_COUNT_DEFAULT)}</del>
    </div>
  );
};

ProductPrice.propTypes = {
  BasePrice: Proptypes.any,
  SellingPrice: Proptypes.any
};

export default ProductPrice;
