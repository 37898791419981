export default class ProductInfoModel {
  constructor() {
    this.CurrencyCode = '';

    this.ProductId = '';

    this.ProductImage = '';

    this.ProductName = '';

    this.SellingPrice = '';

    this.SitecoreId = '';

    this.ListPrice = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyCode')) {
      this.CurrencyCode = dataSource.CurrencyCode;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this.ProductId = dataSource.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductImage')) {
      this.ProductImage = dataSource.ProductImage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName')) {
      this.ProductName = dataSource.ProductName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice')) {
      this.SellingPrice = dataSource.SellingPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SitecoreId')) {
      this.SitecoreId = dataSource.SitecoreId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this.ListPrice = dataSource.ListPrice;
    }

    return this;
  }
}
