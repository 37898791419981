import { SCHEDULE_APPOINTMENT_FAILED, SCHEDULE_APPOINTMENT_START, SCHEDULE_APPOINTMENT_SUCCESS } from './types';

export const scheduleAppointmentStart = (payload) => ({
  type: SCHEDULE_APPOINTMENT_START,
  payload
});

export const scheduleAppointmentSuccess = (payload) => ({
  type: SCHEDULE_APPOINTMENT_SUCCESS,
  payload
});

export const scheduleAppointmentFailed = (payload) => ({
  type: SCHEDULE_APPOINTMENT_FAILED,
  payload
});
