import './ProductCardPrice.scss';

import { bool, string } from 'prop-types';
import React, { useMemo } from 'react';

import { useGetMemberUsualText } from '@utils/customsHook/useGetMemberUsualText';

import { QuestionTooltipIcon } from './QuestionTooltipIcon';

const ProductCardPrice = ({
  sellingPriceWithCurrency,
  listPriceWithCurrency,
  isRentalProduct,
  rentalPriceLabel,
  isBundle,
  bundleSellingPriceWithCurrency,
  bundleListPriceWithCurrency,
  isDeposit,
  depositLabel,
  depositTagLabel,
  depositPriceWithCurrency,
  depositSellingPriceWithCurrency,
  priceExtraInformation,
  refundableLabel,
  mainPriceClassName = '',
  oldPriceClassName = ''
}) => {
  const {
    memberText,
    usualText
  } = useGetMemberUsualText();

  const memberPriceClassName = useMemo(() => (
    [memberText ? 'product-card-price__member' : '', mainPriceClassName].join(' ')
  ), [mainPriceClassName, memberText]);

  const usualPriceClassName = useMemo(() => (
    [usualText ? 'product-card-price__usual' : '', oldPriceClassName].join(' ')
  ), [oldPriceClassName, usualText]);

  const mainPrice = useMemo(
    () => isBundle ? bundleSellingPriceWithCurrency : sellingPriceWithCurrency,
    [isBundle, bundleSellingPriceWithCurrency, sellingPriceWithCurrency]
  );

  return (
    <>
      {isRentalProduct || isDeposit ? (
        <>
          <div className={mainPriceClassName}>
            {isRentalProduct ? <span className='product-price__label'>{rentalPriceLabel} </span> : <></>}
            {isDeposit ? (
              <span className='product-price__amount'>{depositPriceWithCurrency}</span>
            ) : (
              <span className='product-price__amount'>
                {sellingPriceWithCurrency} {priceExtraInformation}
              </span>
            )}
            {isRentalProduct ? (
              <p className='product-card-price__deposit-price'>
                <span className='category-product__main__deposit'>{depositLabel}</span>
                <span className='category-product__main__deposit'> {depositSellingPriceWithCurrency} </span>
                <span className='category-product__main__deposit'>{refundableLabel}</span>
              </p>
            ) : (
              <></>
            )}
          </div>
          {listPriceWithCurrency !== sellingPriceWithCurrency ? (
            <div className={oldPriceClassName}>
              {isDeposit ? (
                <span className='product-price__deposit'>{depositTagLabel}</span>
              ) : (
                <span className='product-price__amount'>{listPriceWithCurrency}</span>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <>
          <div className={memberPriceClassName}>
            {memberText && (
              isBundle || listPriceWithCurrency !== sellingPriceWithCurrency
            ) ? (
                <span className='product-card-price__member-wrapper'>
                  {`${memberText}${mainPrice}`}
                  <QuestionTooltipIcon />
                </span>
              ) : (
                <span>
                  {mainPrice}
                </span>
              )}
          </div>
          <div className={usualPriceClassName}>
            {isBundle ? (
              bundleListPriceWithCurrency !== bundleSellingPriceWithCurrency ? (
                <span
                  className={usualText ? '' : 'product-price__amount'}
                >{usualText}{bundleListPriceWithCurrency}</span>
              ) : (
                <></>
              )
            ) : listPriceWithCurrency !== sellingPriceWithCurrency ? (
              <span className={usualText ? '' : 'product-price__amount'}>{usualText}{listPriceWithCurrency}</span>
            ) : (
              <></>
            )}
          </div>
        </>
      )}
    </>
  );
};

ProductCardPrice.propTypes = {
  sellingPriceWithCurrency: string,
  listPriceWithCurrency: string,
  isRentalProduct: bool,
  rentalPriceLabel: string,
  isBundle: bool,
  bundleSellingPriceWithCurrency: string,
  bundleListPriceWithCurrency: string,
  isDeposit: bool,
  depositLabel: string,
  depositTagLabel: string,
  depositPriceWithCurrency: string,
  depositSellingPriceWithCurrency: string,
  refundableLabel: string,
  priceExtraInformation: string,
  mainPriceClassName: string,
  oldPriceClassName: string
};

export default ProductCardPrice;