import produce from 'immer';

import { SET_COOKIE_BANNER_HEIGHT, SET_HEADER_HEIGHT } from '@redux/actions/layoutElementStyle/types';

const initialStates = {
  cookieBannerHeight: 0,
  headerHeight: 0
};

const layoutElementStyleReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case SET_COOKIE_BANNER_HEIGHT:
      draft.cookieBannerHeight = action.payload;
      break;

    case SET_HEADER_HEIGHT:
      draft.headerHeight = action.payload;
      break;
    }

    return draft;
  });

export default layoutElementStyleReducer;
