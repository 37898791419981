import './FeaturedProductItem.scss';

import { func, number, object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import ProductCardPrice from '@components/ProductCardPrice';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import ToggleWisList from '@components/Share/ToggleWisList';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import FeaturedProductItemLi from './FeaturedProductItemLi';

const FeaturedProductItem = ({ featureProducts, fieldsData, params, indexGA4, handleOpenPopupLogin }) => {
  const [buyNowLink, setBuyNowLink] = useState(deepCopy(fieldsData['Buy Now Button Link']));
  const [purchaseLabel, setPurchaseLabel] = useState('');
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const isUserLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer.isLogin);
  
  const isGA4 = Global.getIsGA4();
  const itemLstName = fieldsData?.['Title']?.value;
  const categoryName = featureProducts?.CategoryName;

  useEffect(() => {
    let tempLink = deepCopy(buyNowLink);

    tempLink.value.href = `${fieldsData['Buy Now Button Link'].value.href}?productPageId=${featureProducts.Id || ''}&LstName=${itemLstName}&IndexGA4=${indexGA4}`;

    setBuyNowLink(tempLink);

    featureProducts.IsDeposit
      ? setPurchaseLabel(fieldsData['Deposit Button Text']?.value)
      : setPurchaseLabel(featureProducts.Stock?.PurchasableLabel);
  }, []);

  const handleGa4SelectItem = (action, ev) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      let dataConvert = [];
  
      dataConvert.push(featureProducts);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(indexGA4, dataConvert, '', pdpPromoCode, '', '',  itemLstName)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }

    // Handle show pop-up login
    if (!isUserLogin && featureProducts.IsDeposit) {
      ev.preventDefault();

      handleOpenPopupLogin(buyNowLink);
    }
  };

  return (
    <div className='featured-product-item'>
      <div className='featured-product-item__wrap'>
        <div className='featured-product-item__main'>
          <ul className='list-tags'>
            {featureProducts.Labels?.length > 0 &&
              featureProducts.Labels.map((labelItem, labelIdx) => {
                return <FeaturedProductItemLi labelItem={labelItem} key={labelIdx} />;
              })}
          </ul>
          <ToggleWisList dataProduct={{ ...featureProducts, itemListName: fieldsData?.['Title']?.value, indexGA4: indexGA4 }} />
          <a
            className='featured-product-item__image'
            href={`${featureProducts.Url}?IndexGA4=${indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}&CategoryPageId=${featureProducts.CategoryPageId}`}
            onClick={() => handleGa4SelectItem('Image')}
          >
            <img className='featured-product-item__image__item' src={featureProducts.Image} alt='No Image' title='No title' />
          </a>
          <h3 className='featured-product-item__main__name'>
            <a
              className='featured-product-item__main__name__link'
              href={`${featureProducts.Url}?IndexGA4=${indexGA4}&CategoryName=${categoryName}&LstName=${itemLstName}&CategoryPageId=${featureProducts.CategoryPageId}`}
              onClick={() => handleGa4SelectItem('Image')}
            >
              {featureProducts.ProductName}
            </a>
          </h3>
          <div className='group-price-icon'>
            <ProductCardPrice
              sellingPriceWithCurrency={featureProducts.Variants?.[0]?.Price?.SellingPriceWithCurrency}
              listPriceWithCurrency={featureProducts.Variants?.[0]?.Price?.ListPriceWithCurrency}
              isBundle={featureProducts.IsBundle}
              bundleSellingPriceWithCurrency={featureProducts.Price?.SellingPriceWithCurrency}
              bundleListPriceWithCurrency={featureProducts.Price?.ListPriceWithCurrency}
              mainPriceClassName='featured-product-item__main__price'
              oldPriceClassName='featured-product-item__main__old-price'
            />
          </div>
        </div>
        <div className='featured-product-item__btn'>
          {params?.['Hide Buy Button'] === 'Show' || !params?.['Hide Buy Button'] ? (
            featureProducts?.IsBundle ? (
              <a className='featured-product-item__btn__buy-options btn btn-primary btn-block' href={featureProducts.Url}>
                {messageObj?.['LBL-LearnMore']}
              </a>
            ) : (
              <BuyButtonComponent
                productData={{
                  ...featureProducts,
                  productId: `${featureProducts.ProductId}|${featureProducts.RepresentativeVariantId}`
                }}
              >
                {/* {fieldsData['Buy Now Button Text'].value ? (
                  <Link className='featured-product-item__btn__buy-options btn btn-primary btn-block' field={buyNowLink}>
                    <Text field={fieldsData['Buy Now Button Text']} />
                  </Link>
                ) : (
                  <Link className='featured-product-item__btn__buy-options btn btn-primary btn-block' field={buyNowLink} />
                )} */}
                <Link
                  className='featured-product-item__btn__buy-options btn btn-primary btn-block'
                  field={buyNowLink}
                  onClick={(ev) => handleGa4SelectItem('Buy Now', ev)}
                >
                  {purchaseLabel}
                </Link>
              </BuyButtonComponent>
            )
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

FeaturedProductItem.propTypes = {
  featureProducts: object,
  fieldsData: object,
  params: object,
  indexGA4: number,
  handleOpenPopupLogin: func
};

export default FeaturedProductItem;
