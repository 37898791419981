export const GET = '/promotions/information';

export const APPLY = '/OsimCart/ApplyCouponCode';

export const APPLY_SO = '/OsimCart/ApplySOCouponCode';

export const REMOVE = '/OsimCart/RemoveCouponCode';

export const BROWSE = '/promotions/GetAvailableCoupons';

export const GET_APPLIED = '/promotions/PromotionsApplied';

export const REMOVE_SO_COUPON_CODE = '/OsimCart/RemoveSOCouponCode';
