import { object } from 'prop-types';
import React, { useCallback, useEffect, useMemo } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { PROMO_DEFAULT_QUANTITY } from '@utils/constant';

import { useBOAddOnDeal } from '../index';

const MAX_PRODUCT_NAME_STRING = 27;

const AddOnDealProductItem = ({ product }) => {
  const { addOnProduct, setAddOnProduct, layoutData, setAddOnDealProductAdded } = useBOAddOnDeal();

  useEffect(() => {
    setAddOnDealProductAdded(addOnProduct);
  }, [addOnProduct]);

  const productIsAdded = useMemo(() => {
    return addOnProduct.find(
      (item) =>
        item.ProductId === product?.ProductId &&
        item.VariantId === product?.VariantId &&
        item.ProductItemId === product?.ProductItemId &&
        item.VariantItemId === product?.VariantItemId
    );
  }, [product, addOnProduct]);

  const handleAddProductToAddOnDeal = useCallback(() => {
    const productData = {
      ProductId: product?.ProductId,
      VariantId: product?.VariantId,
      ProductPageId: product?.ProductPageId,
      ProductItemId: product?.ProductItemId,
      VariantItemId: product?.VariantItemId,
      CatalogName: product?.CataLogName,
      Quantity: PROMO_DEFAULT_QUANTITY,
      ListPrice: product?.ListPrice,
      SellPrice: product?.SellPrice
    };

    setAddOnProduct((prev) => [...prev, productData]);
  }, [product]);

  const handleRemoveProductFromAddOnDeal = useCallback(() => {
    if (productIsAdded) {
      const addOnProductTemp = [...addOnProduct];
      const indexOfProduct = addOnProductTemp.indexOf(productIsAdded);

      addOnProductTemp.splice(indexOfProduct, 1);

      setAddOnProduct(addOnProductTemp);
    }
  }, [product, addOnProduct, productIsAdded]);

  const productNameRawString = useMemo(() => product?.ProductName, [product]);

  const productUrl = useMemo(() => {
    return product?.Url || '';
  }, [product]);

  const AddOnDealInfoWrapperEl = useMemo(() => {
    return productUrl ? 'a' : 'div';
  }, [productUrl]);

  return (
    <div className='col-sm-6 col-md-4 col-lg-6 variant-buying-list__item-wrap'>
      <div className='add-on-deal-list__item__wrapper'>
        <AddOnDealInfoWrapperEl
          href={productUrl}
          target='_blank'
          className={`variant-buying-list__item add-on-deal-list__item ${productIsAdded ? 'add-on-deal-list__item-active' : ''}`}
          rel='noreferrer'
        >
          <span className='add-on-deal-list__item__name'>{productNameRawString}</span>
          <div className='add-on-deal-list__item__detail'>
            <div className='variant-buying-list__item__image add-on-deal-list__item__image'>
              <img src={product.Image} alt='image' />
            </div>
            <div className='variant-buying-list__item__group add-on-deal-list__item__group'>
              {product.VariantPropertyName ? <span className='add-on-deal-list__item__variant'>{product.VariantPropertyName}</span> : <></>}
              <div className='variant-buying-list__item__price add-on-deal-list__item__price'>
                {product.SellingPriceWithCurrency === product.ListPriceWithCurrency ? (
                  <span className='variant-buying-list__item__price__selling-price'>{product.SellingPriceWithCurrency}</span>
                ) : (
                  <>
                    <span className='variant-buying-list__item__price__selling-price'>{product.SellingPriceWithCurrency}</span>
                    <del className='variant-buying-list__item__price__list-price'>{product.ListPriceWithCurrency}</del>
                  </>
                )}
              </div>
            </div>
          </div>
        </AddOnDealInfoWrapperEl>

        <div className='add-on-deal-list__item__button'>
          {!productIsAdded ? (
            <button className='btn btn-primary add-on-deal-list__item__button-custom' onClick={handleAddProductToAddOnDeal}>
              <Text field={layoutData?.['Add Label']} />
            </button>
          ) : (
            <button className='btn btn-primary add-on-deal-list__item__button-custom' onClick={handleRemoveProductFromAddOnDeal}>
              <Text field={layoutData?.['Remove Label']} />
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

AddOnDealProductItem.propTypes = {
  product: object
};

export default AddOnDealProductItem;
