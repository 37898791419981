import CountdownFormatSettingModel from '@components/PromotionListing/model/CountdownFormatSettingModel';

export default class CampaignBannerModel {
  constructor() {
    this['bannerImageSrc'] = '';

    this['description'] = '';

    this['endDate'] = '';

    this['startDate'] = '';

    this['title'] = '';

    this['validMessage'] = '';

    this['countdownFormatSetting'] = new CountdownFormatSettingModel();

    this['clickHyperLink'] = '';

    this['generalLinkTarget'] = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'bannerImageSrc')) {
      this['bannerImageSrc'] = dataSource['bannerImageSrc'] ? dataSource['bannerImageSrc'] : this['bannerImageSrc'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'description')) {
      this['description'] = dataSource['description'] ? dataSource['description'] : this['description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'endDate')) {
      this['endDate'] = dataSource['endDate'] ? dataSource['endDate'] : this['endDate'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'startDate')) {
      this['startDate'] = dataSource['startDate'] ? dataSource['startDate'] : this['startDate'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'title')) {
      this['title'] = dataSource['title'] ? dataSource['title'] : this['title'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'validMessage')) {
      this['validMessage'] = dataSource['validMessage'] ? dataSource['validMessage'] : this['validMessage'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'countdownFormatSetting')) {
      this['countdownFormatSetting'].getData(dataSource['countdownFormatSetting']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'clickHyperLink')) {
      this['clickHyperLink'] = dataSource['clickHyperLink'] ?? this['clickHyperLink'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'generalLinkTarget')) {
      this['generalLinkTarget'] = dataSource['generalLinkTarget'] ?? this['generalLinkTarget'];
    }

    return this;
  }
}
