import Proptypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import SwipeComponent from '@components/Share/SwipeComponent';
import { addChosenBOFreeGift, replaceChosenBOFreeGift } from '@redux/actions/buyingOptions';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { GIFT_NUMBER, params } from './configs';
import FreeGiftDeposit from './FreeGiftDeposit';
import { isActiveItems, isDisableItems } from './utils';

const BOFreeGiftsList = (props) => {
  const { contentData, activeItemsId, selectedItemsId, onToogleFnc, isOpen, dataFields, onFreeGiftData } = props;
  const freeGiftSwiperRef = useRef(null);
  const [keyDynamic, setKeyDynamic] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isOpen) setKeyDynamic(!keyDynamic);
  }, [isOpen]);

  useEffect(() => {
    if (
      contentData.every((line) => {
        return line.items.length === 1;
      })
    ) {
      contentData.forEach((line, lineIdx) => {
        onClickBuyingItemHandler(
          line.type,
          line.items[0].GiftId,
          line.items[0].ProductId,
          line.items[0].VariantId,
          line.items[0].VariantName !== '' ? line.items[0].VariantName : line.items[0].DisplayName,
          lineIdx
        );
      });
    }
  }, []);

  useDidUpdateEffect(() => {
    if (selectedItemsId.length === contentData.length || selectedItemsId.length === onFreeGiftData?.DepositFreeGifts.length) {
      onToogleFnc();
    }
  }, [selectedItemsId]);

  const onClickBuyingItemHandler = (lineId, giftId, productId, variantId, variantName, lineIdx) => {
    if (selectedItemsId.some((item) => item.Line === lineId)) {
      dispatch(replaceChosenBOFreeGift(lineId, giftId, productId, variantId, variantName, lineIdx));
    } else {
      dispatch(addChosenBOFreeGift(lineId, giftId, productId, variantId, variantName, lineIdx));
    }
  };

  return contentData?.length ? (
    <div className='freegift-buying-list'>
      {contentData.map((line, lineIdx) => {
        return (
          <div className='freegift-buying-list__item bo-free-gift__group' key={lineIdx}>
            <p className='item__name bo-free-gift__group__title'>{dataFields['Gift Header Label'].value.replace(GIFT_NUMBER, lineIdx + 1)}</p>
            <SwipeComponent param={params} setRef={freeGiftSwiperRef}>
              {line.items.map((item, itemIdx) => {
                return (
                  <div key={itemIdx}>
                    <div
                      className={
                        'item__variant bo-free-gift__item' +
                        `${isActiveItems(activeItemsId, item.GiftId) ? ' active' : ''}` +
                        `${
                          isDisableItems(activeItemsId, item.Quantity, item.VariantId) && !isActiveItems(activeItemsId, item.GiftId) ? ' disable' : ''
                        }`
                      }
                      onClick={() =>
                        onClickBuyingItemHandler(
                          line.type,
                          item.GiftId,
                          item.ProductId,
                          item.VariantId,
                          item.VariantName !== '' ? item.VariantName : item.DisplayName,
                          lineIdx
                        )
                      }
                    >
                      <div className='item__variant__image bo-free-gift__item__image'>
                        <img className='swiper-lazy animated' src={`${item.VariantImages}`} alt='Product Image' />
                        <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
                      </div>
                      <div
                        className='item__variant__name bo-free-gift__item__name'
                        title={item.VariantName !== '' ? item.VariantName : item.DisplayName}
                      >
                        {item.VariantName !== '' ? item.VariantName : item.DisplayName}
                      </div>
                    </div>
                  </div>
                );
              })}
            </SwipeComponent>
          </div>
        );
      })}
    </div>
  ) : onFreeGiftData?.IsDeposit ? (
    <FreeGiftDeposit ref={freeGiftSwiperRef} dataFields={dataFields} onFreeGiftData={onFreeGiftData} />
  ) : (
    <></>
  );
};

BOFreeGiftsList.propTypes = {
  contentData: Proptypes.any,
  activeItemsId: Proptypes.any,
  selectedItemsId: Proptypes.any,
  selectedLength: Proptypes.any,
  onToogleFnc: Proptypes.any,
  isOpen: Proptypes.any,
  dataFields: Proptypes.any,
  onFreeGiftData: Proptypes.any
};

export default BOFreeGiftsList;
