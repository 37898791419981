import AccordionBackgroundColorFieldsValueModel from './AccordionBackgroundColorFieldsValueModel';

export default class AccordionBackgroundColorFieldsModel {
  constructor() {
    this['Value'] = new AccordionBackgroundColorFieldsValueModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Value')) {
      this['Value'].getData(dataSource['Value']);
    }

    return this;
  }
}
