import { ofType } from 'redux-observable';
// import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import UserInfoModel from '@components/SingleSignOn/models/UserInfoModel';
import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { getAsObservable } from '@services/genericService';

import * as config from '../config';

const userProfileEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.GET_USER_PROFILE),
    switchMap(() => {
      return getAsObservable(config.ACTION_GET_USER_PROFILE).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            const userInfoModel = new UserInfoModel().getData(res.data?.Data || {});
            const enabledLink = res.data?.Enabled?.map(name => name.toLowerCase());

            return actions.loginSuccess({
              ...userInfoModel,
              Enabled: enabledLink || [],
              isLinkFacebook: enabledLink?.includes(config.TYPE_FACEBOOK),
              isLinkGoogle: enabledLink?.includes(config.TYPE_GOOGLE),
              FacebookUsername: res.data?.FacebookUsername || '',
              GoogleUsername: res.data?.FacebookUsername || '',
            });
          } else {
            return actions.getUserProfileFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export default userProfileEpic;
