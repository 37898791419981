import './Accordion.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Accordion } from 'react-bootstrap';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';
import { deepCopy } from '@utils/utility';

import AccordionItem from './AccordionItem';
import AccordionModel from './model/AccordionModel';

const AccordionComponent = (props) => {
  const [dataFields, setDataFields] = useState(null);

  const [activeKey, setActiveKey] = useState(-1);

  useEffect(() => {
    const accordionModel = new AccordionModel();

    setDataFields(accordionModel.getData(deepCopy(props.fields) || {}));
  }, []);

  useEffect(() => {
    const fullPath = window.location.href;

    const itemId = global.getQueryParams(fullPath, 'itemId') || null;

    setActiveKey(itemId && itemId !== '' ? global.removeUselessWords(itemId, ['{', '}']) : -1);
  }, []);

  const onToogleAccordion = (id) => {
    id === activeKey ? setActiveKey(-1) : setActiveKey(id);
  };

  return dataFields ? (
    <div
      id='faq'
      className={`accordion animated-slow fadeIn delay-100 ${
        props.params?.Width && props.params?.Width === 'Wide' ? 'accordion--wide' : ''
      } ${
        props.params?.['Has Boundary']?.length ? 'accordion--has-border' : ''
      } ${
        props.params['Items Has Underline'] && props.params['Items Has Underline'] === '0' ? '' : 'accordion--under-line'
      }`
      }
    >
      <div className='container'>
        <div className='accordion-wrap'>
          <Text tag='h2' className='experience-title accordion__heading' field={dataFields['Title']} />
          {/* {dataFields['Description'].value ? (
            <p className='accordion__description'>{dataFields['Description'].value}</p>
          ) : <></>
          } */}
          <RichText tag='div' className='accordion__description' field={dataFields['Description']} />
          {dataFields?.['items'].length ? (
            <Accordion bsPrefix='accordion__info-list' activeKey={activeKey}>
              {dataFields?.['items']?.map((item) => {
                return <AccordionItem key={item.id} data={item} activeKey={activeKey} onToogleAccordion={onToogleAccordion} params={props.params} />;
              })}
            </Accordion>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='accordion'>
        <div className='container'>
          <div className='accordion-wrap'>
            <h2 className='experience-title accordion__heading skeleton__item'>
              <span className='text-line-40-center w-40'></span>
            </h2>
            <div className='accordion__info-list'>
              <LoadingShimmer itemNumb={8}>
                <div className='accordion__info-list__item skeleton__item'>
                  <div className='accordion__collapse'>
                    <div className='accordion__collapse__title'>
                      <span className='text-line'></span>
                      <div className='accordion__collapse__title__text'></div>
                    </div>
                  </div>
                </div>
              </LoadingShimmer>
            </div>
          </div>
        </div>
      </div>
    </LoadingShimmer>
  );
};

AccordionComponent.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object
};

export default AccordionComponent;
