import './BundleElements.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { getProductDetailStaticBundleOverview } from '@redux/actions/product';
import { getPromotionPrice, getVariantStatus } from '@redux/actions/staticBundleDetail';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { useGetMemberUsualText } from '../../utils/customsHook/useGetMemberUsualText';
import { QuestionTooltipIcon } from '../ProductCardPrice/QuestionTooltipIcon';
import { behaviorBundleElements$ } from './BundleElementsBehavior';
import { behaviorInstallment$ } from './InstallmentBehavior';
import LabelItem from './LabelItem';
import BundleElementsModel from './model/BundleElementsModel';
import TextDataModel from './model/TextDataModel';

const BundleElements = (props) => {
  const { params } = props;
  const [inputData, setInputData] = useState(null);
  const [textData, setTextData] = useState(null);
  const [displayPriceGroup, setDisplayPriceGroup] = useState(null);
  const [discountContent, setDiscountContent] = useState(null);
  const dispatch = useDispatch();
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const categoryName = SitecoreContextFactoryService.getValueContextRouteItem('displayName');
  const isGA4 = Global.getIsGA4();

  const {
    memberText,
    usualText
  } = useGetMemberUsualText();

  useEffect(() => {
    const model = new BundleElementsModel();

    setInputData(model.getData(deepCopy(props.fields['Bundle Data']) || {}));
  }, []);

  useEffect(() => {
    behaviorBundleElements$.next(params && params);
  }, []);

  useEffect(() => {
    const model = new TextDataModel();

    setTextData(model.getData(deepCopy(props.fields['Text Data']) || {}));
  }, []);

  useEffect(() => {
    setDisplayPriceGroup(
      inputData?.IncludedProducts.every((product) => {
        return !product?.Price?.SellingPriceWithCurrency;
      })
    );
  }, [inputData]);

  useDidUpdateEffect(() => {
    let temp = deepCopy(inputData);

    delete temp.BundleId;

    delete temp.Products;

    delete temp.IncludedProducts;

    dispatch(getPromotionPrice(temp));

    behaviorInstallment$.next({ installment: inputData['InstallmentPeriods'], sellingPrice: inputData['SellPrice'] });
  }, [inputData]);

  useDidUpdateEffect(() => {
    const onGetStatusButton = (data) => {
      if (
        data?.IncludedProducts.some((product) => {
          return product?.Variants.every((variant) => {
            return variant?.Stock?.StockStatus?.Value === 2;
          });
        })
      ) {
        return 'outofstock';
      } else {
        if (
          data?.IncludedProducts.some((product) => {
            return product?.Variants.some((variant) => {
              return variant?.Stock?.StockStatus?.Value === 3;
            });
          })
        ) {
          return messageObj['LBL-PreOrder'];
        }
        if (
          data?.IncludedProducts.some((product) => {
            return product?.Variants.some((variant) => {
              return variant?.Stock?.StockStatus?.Value === 4;
            });
          })
        ) {
          return messageObj['LBL-BackOrder'];
        }
      }

      return 'buyNow';
    };

    dispatch(getVariantStatus(onGetStatusButton(inputData)));
  }, [inputData]);

  useDidUpdateEffect(() => {
    let temp = deepCopy(inputData);
    let tempDiscountText = deepCopy(textData['Discount Text']);

    temp = Math.round(temp.DiscountPercent);

    tempDiscountText.value = tempDiscountText.value.replace('{percent} ', temp);

    setDiscountContent(tempDiscountText);
  }, [inputData, textData]);

  //Old Logic GA
  // useDidUpdateEffect(() => {
  //   let items = [];
    
  //   items.push({
  //     id: inputData?.BundleId,
  //     name: inputData?.BundleName,
  //     list_name: 'Search Results',
  //     brand: 'Osim',
  //     list_position: 1,
  //     quantity: 1,
  //     price: inputData?.SellingPrice
  //   });

  //   let params = {
  //     items: items
  //   };

  //   new GoogleAnalytic().ggGateWay('event', 'view_item', params);
  // }, [inputData]);

  //New Logic ON/OFF GA, GA4
  useDidUpdateEffect(() => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      const idxProdGA4 = Global.getIndexGA4Param();
      let dataConvert = [];
  
      dataConvert.push(inputData);
  
      let paramsViewItem = {
        ecommerce: {
          currency: inputData?.IncludedProducts?.[0]?.Price?.CurrencyCode,
          value: inputData?.SellPrice?.Amount,
          items: Global.handleGA4ViewAndSelectItems(idxProdGA4, dataConvert, categoryName, pdpPromoCode )
        }
      };
    
      new GoogleAnalytic().gAnalytic4('event', 'view_item', paramsViewItem);
    } else {
      let items = [];
  
      items.push({
        id: inputData?.BundleId,
        name: inputData?.BundleName,
        list_name: 'Search Results',
        brand: 'Osim',
        list_position: 1,
        quantity: 1,
        price: inputData?.SellingPrice
      });
        
      let params = {
        items: items
      };
  
      new GoogleAnalytic().ggGateWay('event', 'view_item', params);
    }

    dispatch(getProductDetailStaticBundleOverview(inputData));
  }, [inputData]);

  return inputData && discountContent && textData && displayPriceGroup !== null ? (
    <div className='promotion-detail-bundle-list animated-slow fadeIn delay-100' id='bundle-element'>
      <div className='container'>
        <Text tag='h2' className='promotion-detail-bundle-list__title text-center bundle-title' field={textData['Title']} />
        <div className={inputData.IncludedProducts?.length < 4 ? 'row center-item' : 'row'}>
          {inputData.IncludedProducts?.map((item, index) => {
            const memberTextPromotion = (memberText &&
              item.Price?.SellingPriceWithCurrency !==
              item.Price?.ListPriceWithCurrency) ? memberText : '';

            return (
              <Fragment key={index}>
                {inputData.SplitQty ? (
                  <Fragment>
                    {new Array(item.Quantity).fill(0).map((product, productIndex) => {
                      return (
                        <Fragment key={productIndex}>
                          {productIndex === item.Quantity - 1 ? (
                            <div className='col-md-6 col-xl-3 promotion-detail-bundle-list__col'>
                              <div className='promotion-detail-bundle-item'>
                                <div className='promotion-detail-bundle-item__contents'>
                                  <div className='promotion-detail-bundle-item__thumb'>
                                    {params?.['Quick Info'] !== '1' && item.Url.length ? (
                                      <a className='promotion-detail-bundle-item__thumb__picture' target='_blank' rel='noreferrer' href={item.Url}>
                                        <img src={item.Image} alt='no image' />
                                      </a>
                                    ) : (
                                      <img src={item.Image} alt='no image' />
                                    )}
                                  </div>
                                  <ul className='list-tags'>
                                    {item.Labels.map((item, index) => {
                                      if (index < 4) {
                                        return <LabelItem data={item} key={index} />;
                                      }
                                    })}
                                  </ul>
                                  <div className='promotion-detail-bundle-item__info'>
                                    {params?.['Quick Info'] !== '1' && item.Url.length ? (
                                      <h3 className='promotion-detail-bundle-item__name bundle-sub-title'>
                                        <a target='_blank' rel='noreferrer' href={item.Url}>
                                          {item.ProductName}
                                        </a>
                                      </h3>
                                    ) : (
                                      <h3 className='promotion-detail-bundle-item__name bundle-sub-title'>{item.ProductName}</h3>
                                    )}
                                    {displayPriceGroup ? (
                                      <></>
                                    ) : (
                                      <div className='promotion-detail-bundle-item__price-group'>
                                        {item.Url.length ? (
                                          <div
                                            className={[
                                              'promotion-detail-bundle-item__selling-price',
                                              memberTextPromotion ? 'with-question-tooltip' : ''
                                            ].join(' ')}
                                          >
                                            {memberTextPromotion}{item.Price?.SellingPriceWithCurrency}{' '}
                                            {/* {inputData?.DiscountPercent ? <Text tag='span' field={discountContent} /> : <></>} */}
                                            {memberTextPromotion ? <QuestionTooltipIcon /> : (<></>)}
                                          </div>
                                        ) : (
                                          <></>
                                        )}
                                        {item.Price?.SellingPriceWithCurrency === item.Price?.ListPriceWithCurrency ? (
                                          <></>
                                        ) : (
                                          usualText ? (
                                            <span className='promotion-detail-bundle-item__list-price usual-text'>{usualText}{item.Price?.ListPriceWithCurrency}</span>
                                          ) : (
                                            <del className='promotion-detail-bundle-item__list-price'>{item.Price?.ListPriceWithCurrency}</del>
                                          )
                                        )}
                                      </div>
                                    )}
                                    {params?.['Quick Info'] !== '1' ? (
                                      <p className='promotion-detail-bundle-item__description bundle-description'>{item.Description}</p>
                                    ) : (
                                      <></>
                                    )}
                                  </div>
                                </div>
                                {params?.['Quick Info'] !== '1' && item.Url.length ? (
                                  <div className='promotion-detail-bundle-item__link'>
                                    <a className='btn-link' target='_blank' rel='noreferrer' href={item.Url}>
                                      <Text tag='span' field={textData['Learn More Label']} />
                                    </a>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          ) : (
                            <div className='col-md-6 col-xl-3 promotion-detail-bundle-list__col'>
                              <div className='promotion-detail-bundle-item'>
                                <div className='promotion-detail-bundle-item__contents'>
                                  <div className='promotion-detail-bundle-item__thumb'>
                                    {params?.['Quick Info'] !== '1' && item.Url.length ? (
                                      <a className='promotion-detail-bundle-item__thumb__picture' target='_blank' rel='noreferrer' href={item.Url}>
                                        <img src={item.Image} alt='no image' />
                                      </a>
                                    ) : (
                                      <img src={item.Image} alt='no image' />
                                    )}
                                  </div>
                                  <ul className='list-tags'>
                                    {item.Labels.map((item, index) => {
                                      if (index < 4) {
                                        return <LabelItem data={item} key={index} />;
                                      }
                                    })}
                                  </ul>
                                  {params?.['Quick Info'] !== '1' && item.Url.length ? (
                                    <h3 className='promotion-detail-bundle-item__name bundle-sub-title'>
                                      <a target='_blank' rel='noreferrer' href={item.Url}>
                                        {item.ProductName}
                                      </a>
                                    </h3>
                                  ) : (
                                    <h3 className='promotion-detail-bundle-item__name bundle-sub-title'>{item.ProductName}</h3>
                                  )}
                                  {displayPriceGroup ? (
                                    <></>
                                  ) : (
                                    <div className='promotion-detail-bundle-item__price-group'>
                                      {item.Url.length ? (
                                        <div
                                          className={[
                                            'promotion-detail-bundle-item__selling-price',
                                            memberTextPromotion ? 'with-question-tooltip' : ''
                                          ].join(' ')}
                                        >
                                          {memberTextPromotion}{item.Price?.SellingPriceWithCurrency}
                                          {memberTextPromotion ? <QuestionTooltipIcon /> : (<></>)}
                                        </div>
                                      ) : (
                                        <></>
                                      )}
                                      {item.Price?.SellingPriceWithCurrency === item.Price?.ListPriceWithCurrency ? (
                                        <></>
                                      ) : (
                                        usualText ? (
                                          <span className='promotion-detail-bundle-item__list-price usual-text'>{usualText}{item.Price?.ListPriceWithCurrency}</span>
                                        ) : (
                                          <del className='promotion-detail-bundle-item__list-price'>{item.Price?.ListPriceWithCurrency}</del>
                                        )
                                      )}
                                    </div>
                                  )}
                                  {params?.['Quick Info'] !== '1' ? (
                                    <p className='promotion-detail-bundle-item__description bundle-description'>{item.Description}</p>
                                  ) : (
                                    <></>
                                  )}
                                </div>
                                {params?.['Quick Info'] !== '1' && item.Url.length ? (
                                  <div className='promotion-detail-bundle-item__link'>
                                    <a className='btn-link' target='_blank' rel='noreferrer' href={item.Url}>
                                      <Text tag='span' field={textData['Learn More Label']} />
                                    </a>
                                  </div>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </div>
                          )}
                        </Fragment>
                      );
                    })}
                  </Fragment>
                ) : (
                  <div className='col-md-6 col-xl-3 promotion-detail-bundle-list__col'>
                    <div className='promotion-detail-bundle-item'>
                      <div className='promotion-detail-bundle-item__contents'>
                        <div className='promotion-detail-bundle-item__thumb'>
                          {params?.['Quick Info'] !== '1' && item.Url.length ? (
                            <a target='_blank' href={item.Url} className='promotion-detail-bundle-item__thumb__picture' rel='noreferrer'>
                              <img src={item.Image} alt='no image' />
                            </a>
                          ) : (
                            <img src={item.Image} alt='no image' />
                          )}
                        </div>
                        <ul className='list-tags'>
                          {item.Labels.map((item, index) => {
                            if (index < 4) {
                              return <LabelItem data={item} key={index} />;
                            }
                          })}
                        </ul>
                        {params?.['Quick Info'] !== '1' && item.Url.length ? (
                          <h3 className='promotion-detail-bundle-item__name bundle-sub-title'>
                            <a target='_blank' href={item.Url} rel='noreferrer'>
                              {item.ProductName}
                            </a>
                          </h3>
                        ) : (
                          <h3 className='promotion-detail-bundle-item__name bundle-sub-title'>{item.ProductName}</h3>
                        )}
                        {displayPriceGroup ? (
                          <></>
                        ) : (
                          <div className='promotion-detail-bundle-item__price-group'>
                            {item.Url.length ? (
                              <div className={['promotion-detail-bundle-item__selling-price',
                                memberTextPromotion
                                  ? 'with-question-tooltip'
                                  : ''
                              ].join(' ')}
                              >
                                {memberTextPromotion}{item.Price?.SellingPriceWithCurrency}
                                {memberTextPromotion ? <QuestionTooltipIcon /> : (
                                  <></>
                                )}
                              </div>
                            ) : (
                              <></>
                            )}
                            {item.Price?.SellingPriceWithCurrency === item.Price?.ListPriceWithCurrency ? (
                              <></>
                            ) : (
                              usualText ? (
                                <span className='promotion-detail-bundle-item__list-price usual-text'>{usualText}{item.Price?.ListPriceWithCurrency}</span>
                              ) : (
                                <del className='promotion-detail-bundle-item__list-price'>{item.Price?.ListPriceWithCurrency}</del>
                              )
                            )}
                          </div>
                        )}
                        {params?.['Quick Info'] !== '1' ? (
                          <p className='promotion-detail-bundle-item__description bundle-description'>{item.Description}</p>
                        ) : (
                          <></>
                        )}
                      </div>
                      {params?.['Quick Info'] !== '1' && item.Url.length ? (
                        <div className='promotion-detail-bundle-item__link'>
                          <a target='_blank' href={item.Url} className='btn-link' rel='noreferrer'>
                            <Text tag='span' field={textData['Learn More Label']} />
                          </a>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
        {/* <div className='text-center'>
            <span className='promotion-detail-bundle-list__button btn btn-outline-CTA2'>LOAD MORE</span>
          </div> */}
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='promotion-detail-bundle-list skeleton__item' id='bundle-element'>
        <div className='container'>
          <h2 className='promotion-detail-bundle-list__title text-center bundle-title'>
            <span className='text-line-heading-center w-40 hidden-xs-down'></span>
            <span className='text-line-heading-center w-80 hidden-md-up'></span>
          </h2>
          <div className='row'>
            <LoadingShimmer itemNumb={4}>
              <div className='col-md-6 col-xl-3 promotion-detail-bundle-list__col'>
                <div className='promotion-detail-bundle-item'>
                  <div className='promotion-detail-bundle-item__contents'>
                    <div className='promotion-detail-bundle-item__thumb__picture image-wrap'>
                      <div className='image-line'></div>
                    </div>
                    <span className='clear-10'></span>
                    <div className='text-line'></div>
                    <span className='clear-10'></span>
                    <div className='text-line'></div>
                    <span className='clear-10'></span>
                    <div className='text-line'></div>
                    <span className='clear-10'></span>
                    <div className='text-line'></div>
                  </div>
                </div>
              </div>
            </LoadingShimmer>
          </div>
        </div>
      </div>
    </LoadingShimmer>
  );
};

BundleElements.propTypes = {
  fields: PropTypes.object,
  staticBundleDetail: PropTypes.object,
  params: PropTypes.object
};

export default BundleElements;
