import produce from 'immer';

import * as storeLocatorTypes from '@redux/actions/storeLocator/storeLocatorTypes';

const initialState = {
  shippingFee: '',
  isLoading: false,
  errorMsg: ''
};

const estimateShippingFeeReducer = (state = initialState, action) => 
  produce(state, draft => {
    switch (action.type) {
    case storeLocatorTypes.GET_ESTIMATE_SHIPPING_FEE:

      draft.isLoading = true;
      break;

    case storeLocatorTypes.GET_ESTIMATE_SHIPPING_FEE_SUCCESS:
      draft.shippingFee = action.payload;

      draft.isLoading = false;
      break;

    case storeLocatorTypes.GET_ESTIMATE_SHIPPING_FEE_FAILED:
      draft.shippingFee = '';

      draft.isLoading = false;
      break;
    }

    return draft;
  });


export default estimateShippingFeeReducer;