import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Description', 'Title', 'IOSButtonText', 'AndroidButtonText'];
const PLAIN_TEXT_ARR = ['ProductImage', 'IOSQRImage', 'AndroidQRImage', 'IOSButtonLink', 'AndroidButtonLink'];
const BOOLEAN_FIELD_ARR = ['Disabled'];

export default class DownloadOsimAppModel {
  constructor() {
    TEXT_ARR.forEach((item) => (this[item] = new SitecoreTextModel()));

    PLAIN_TEXT_ARR.forEach((item) => (this[item] = ''));

    BOOLEAN_FIELD_ARR.forEach((item) => (this[item] = false));
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    PLAIN_TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    BOOLEAN_FIELD_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    return this;
  }
}
