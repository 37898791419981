import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = [
  'Review Success Text',
  'Review Success Description Text',
  'Review Fail Text',
  'Review Fail Description Text',
  'Respond To Your Review Text',
  'See Review In Product Page Text',
  'See Lastest Offers Button Text',
  'See Review In Product Page Link'
];

const LINKARR = ['See Lastest Offers Button Link', 'Write Review Link'];

const IMAGEARR = ['Review Success Icon'];

export default class ReviewSubmittedModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMAGEARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMAGEARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
