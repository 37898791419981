import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import SpinCouponInfoPopup from '@components/SpinAndWin/SpinCouponInfoPopup';
import { getCouponDetail } from '@redux/actions/coupon';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const SpinCouponItem = ({ 
  layoutData,
  coupon, 
  applyOfferButtonText, 
  selectedCoupon, 
  setSelectedCoupon, 
  isChanceToWin 
}) => {
  const dispatch = useDispatch();
  const [showPopup, setShowPopup] = useState(false);

  const couponValidToDate = new Date(Number.parseInt(coupon?.ValidityTo?.replace(/Date|\(|\/|\)/gi, '')));
  const convertCouponValidToDate = Global.customDate(couponValidToDate);

  const onClosePopupHandler = () => {
    setShowPopup(false);
  };

  const getCouponDetailHandler = () => {
    let params={
      id: coupon.Id
    };
    if (coupon?.Code) params.code = coupon.Code;

    dispatch(getCouponDetail(params));

    setShowPopup(true);
  };

  return (
    <div className='spin-coupon-item'>
      {isChanceToWin ? (
        <div className='cart-promotion-code-item'>
          <div className='cart-promotion-code-item__infor-icon info-icon info-icon--small' onClick={getCouponDetailHandler}></div>
          <div className='cart-promotion-code-item__main-info'>
            <div className='cart-promotion-code-item__group'>
              <h4 className='cart-promotion-code-item__name'>{coupon?.Title}</h4>
              {coupon?.Code && (
                <>
                  <Text tag='p' className='cart-promotion-code-item__hint-text' field={layoutData['Coupon Code Label']} />
                  <span className='cart-promotion-code-item__code'>{coupon?.Code}</span>
                </>
              )}
              <p className='cart-promotion-code-item__description'>{coupon?.Description}</p>
            </div>
          </div>
          <div className='cart-promotion-code-item__footer justify-space-between'>
            <div className='cart-promotion-code-item__footer__date'>
              <span>
                <Text field={layoutData['Valid Until Label']} /> {convertCouponValidToDate}
              </span>
            </div>
          </div>
          <div className='left-light'></div>
        </div>
      ) : (
        <div
          className={`cart-promotion-code-item ${selectedCoupon?.Code == coupon.Code ? 'selected' : ''}`}
          onClick={() => setSelectedCoupon(coupon)}
        >
          <div className='cart-promotion-code-item__infor-icon info-icon info-icon--small' onClick={getCouponDetailHandler}></div>
          <div className='cart-promotion-code-item__main-info'>
            <div className='cart-promotion-code-item__group'>
              <h4 className='cart-promotion-code-item__name'>{coupon?.Title}</h4>
              {coupon?.Code && (
                <>
                  <Text tag='p' className='cart-promotion-code-item__hint-text' field={layoutData['Coupon Code Label']} />
                  <span className='cart-promotion-code-item__code'>{coupon?.Code}</span>
                </>
              )}
              <p className='cart-promotion-code-item__description'>{coupon?.Description}</p>
            </div>
          </div>
          <div className='cart-promotion-code-item__footer justify-space-between'>
            <div className='cart-promotion-code-item__footer__date'>
              <Text tag='span' field={layoutData['Valid Until Label']} />
              <span>{convertCouponValidToDate}</span>
            </div>
          </div>
          <div className='left-light'></div>
        </div>
      )}
      <ModalComponent isShow={showPopup} onCloseHandler={onClosePopupHandler} titlePopup='COUPON INFO'>
        <SpinCouponInfoPopup layoutData={layoutData} coupon={coupon} applyOfferButtonText={applyOfferButtonText} isChanceToWin={isChanceToWin} />
      </ModalComponent>
    </div>
  );
};

SpinCouponItem.propTypes = {
  layoutData: PropTypes.any,
  coupon: PropTypes.object,
  applyOfferButtonText: PropTypes.string,
  selectedCoupon: PropTypes.any,
  setSelectedCoupon: PropTypes.any,
  isChanceToWin: PropTypes.bool
};

export default SpinCouponItem;
