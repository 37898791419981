import { Form, Formik } from 'formik';
import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { array, boolean, object, string } from 'yup';

import { addChosenBOTradeIn, removeChosenBOTradeIn } from '@redux/actions/buyingOptions';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { PROMO_TRADEIN_TYPE_LOYALTY } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';

import TradeInUploadImage from './TradeInUploadImage';

const BOTradeInList = ({ contentData, activeItemsId, onToogleFnc, onCloseModalHandler, dataFields, onShowTradeInOptionsModal }) => {
  // const [selectedTradeInProduct, setSelectedTradeInProduct] = useState(activeItemsId?.length ? activeItemsId[0] : null);
  const BOTradeIn = useSelector((state) => state.buyingOptionReducer.BOTradeInReducer);
  const [selectedTradeInProduct, setSelectedTradeInProduct] = useState(null);
  const [expansivePopup, setExpansivePopup] = useState(false);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const queryParam = useQuery();

  const isLoyalty = useMemo(() => {
    return JSON.parse(queryParam.get('isLoyalty') || false);
  }, [queryParam]);

  const initialValues = useMemo(
    () => ({
      selectedTradeInProduct: {
        ProductId: BOTradeIn?.tradeInActive?.isSelectedTradeInOption ? BOTradeIn?.tradeInActive?.selectedTradeInProduct?.ProductId || '' : ''
        // VariantId: BOTradeIn?.tradeInActive?.isSelectedTradeInOption ? BOTradeIn?.tradeInActive?.selectedTradeInProduct?.VariantId || '' : ''
      },
      imageTradeInProduct: BOTradeIn?.tradeInActive?.values?.imageTradeInProduct || [],
      brand: BOTradeIn?.tradeInActive?.isSelectedTradeInOption ? BOTradeIn?.tradeInActive?.values?.brand || '' : '',
      productName: BOTradeIn?.tradeInActive?.isSelectedTradeInOption ? BOTradeIn?.tradeInActive?.values?.productName || '' : '',
      model: BOTradeIn?.tradeInActive?.isSelectedTradeInOption ? BOTradeIn?.tradeInActive?.values?.model || '' : '',
      agreement: BOTradeIn?.tradeInActive?.isSelectedTradeInOption ? BOTradeIn?.tradeInActive?.values?.agreement || false : false
    }),
    [BOTradeIn]
  );

  const validationSchema = object().shape({
    selectedTradeInProduct: object({
      ProductId: string().required()
      // VariantId: string().required()
    }),
    // selectedTradeInProduct: object({}).required('Select trade-in product is required'),
    imageTradeInProduct: array().required(dataFields?.['Require Upload File Text']?.value),
    brand: string().required('Brand field is required'),
    productName: string().required('Product Name field is required'),
    model: string().required('Model field is required'),
    agreement: boolean().oneOf([true], 'Terms and Conditions Agreement is required')
  });

  useEffect(() => {
    setSelectedTradeInProduct(BOTradeIn?.tradeInActive?.selectedTradeInProduct || null);
  }, []);

  useDidUpdateEffect(() => {
    if (selectedTradeInProduct) {
      setExpansivePopup(true);
    }
  }, [selectedTradeInProduct]);

  const handleSubmit = (values) => {
    const params = {
      values: {
        imageTradeInProduct: values.imageTradeInProduct,
        brand: values.brand,
        productName: values.productName,
        model: values.model,
        agreement: values.agreement
      },
      // values: values,
      selectedTradeInProduct: selectedTradeInProduct,
      isSelectedTradeInOption: true
    };
    if (activeItemsId && activeItemsId.type) params.type = activeItemsId.type;

    dispatch(addChosenBOTradeIn(params));

    onShowTradeInOptionsModal({ status: 'submit' });
  };

  const onRemoveTradeInOptions = () => {
    onShowTradeInOptionsModal({ status: 'remove' });

    dispatch(removeChosenBOTradeIn());
  };

  return (
    contentData && (
      <Formik
        className='tradein-buying-popup'
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
        enableReinitialize={true}
        innerRef={formikRef}
      >
        {({ errors, touched, handleChange, values, setFieldValue, isValid, dirty }) => {
          return (
            <Form>
              <div className='tradein-buying-popup__heading'>
                <Text field={dataFields['Trade In Popup Title']} tag='h3' />
                <Text field={dataFields['Provide Details Text']} className='tradein-buying-popup__heading__desc' tag='p' />
              </div>
              {/* Step Add Trade-In */}
              <div className='tradein-buying-popup__step'>
                <div className='tradein-buying-popup__step__item'>
                  <div className='tradein-buying-popup__step__item__line'>
                    <div className='tradein-step-number'>
                      <span className='tradein-step-number__value'>1</span>
                    </div>
                  </div>
                  <Text className='tradein-buying-popup__step__item__text' field={dataFields['Select Product Label']} tag='span' />
                </div>
                <div className='tradein-buying-popup__step__item'>
                  <div className='tradein-buying-popup__step__item__line'>
                    <div className='tradein-step-number'>
                      <span className='tradein-step-number__value'>2</span>
                    </div>
                  </div>
                  <Text className='tradein-buying-popup__step__item__text' field={dataFields['Upload Image Label']} tag='span' />
                </div>
                <div className='tradein-buying-popup__step__item'>
                  <div className='tradein-buying-popup__step__item__line'>
                    <div className='tradein-step-number'>
                      <span className='tradein-step-number__value'>3</span>
                    </div>
                  </div>
                  <Text className='tradein-buying-popup__step__item__text' field={dataFields['Provide Info Label']} tag='span' />
                </div>
              </div>
              {/* Learn more about Trade-In */}
              <div className='tradein-buying-popup__learn-more text-center'>
                {dataFields['Learn More Link']?.value?.href.length ? (
                  <Link field={dataFields['Learn More Link']}>
                    <Text field={dataFields['Learn More Label']} />
                  </Link>
                ) : (
                  <Text field={dataFields['Learn More Label']} tag='span' />
                )}
              </div>
              {/* 1. Select trade-in product */}
              <div className='tradein-buying-popup__list step-select-product'>
                <div className='tradein-step-section'>
                  <div className='tradein-step-section__title'>
                    <div className='tradein-step-section__title__number tradein-step-number'>
                      <span className='tradein-step-number__value'>1</span>
                    </div>
                    <Text tag='h4' className='tradein-step-section__title__label' field={dataFields['Select Trade In Product Title']} />
                  </div>
                  <div className='tradein-step-section__content'>
                    {contentData.map((item) => {
                      return (
                        <Fragment key={item.ProductId}>
                          <div
                            className={`tradein-buying-popup__step1-item ${selectedTradeInProduct?.ProductId === item.ProductId ? 'active' : ''}`}
                            onClick={() => {
                              setSelectedTradeInProduct(item);

                              setFieldValue('selectedTradeInProduct', { ProductId: item.ProductId });

                              setFieldValue('brand', item.Brand.toLowerCase() === 'osim' ? item.Brand.toUpperCase() : '');
                            }}
                          >
                            <div className='tradein-buying-popup__step1-item__radio osim-radio'>
                              <input
                                type='radio'
                                name={'trade-in-' + item.ProductId}
                                id={'trade-in-' + item.ProductId}
                                className='osim-radio-input'
                                checked={selectedTradeInProduct?.ProductId === item.ProductId ? true : false}
                                readOnly
                              />
                              <label htmlFor={'trade-in-' + item.ProductId} className='osim-radio-label'></label>
                            </div>
                            <div className='tradein-buying-popup__step1-item__info'>
                              <h5 className='tradein-buying-popup__step1-item__info__name'>{item.DisplayName}</h5>
                              {isLoyalty || activeItemsId?.type === PROMO_TRADEIN_TYPE_LOYALTY ? (
                                <>
                                  <h5 className='tradein-buying-popup__step1-item__info__downprice'>
                                    <Text field={dataFields['Loyalty Trade-in Value Label']} />
                                    <span className='text-bold'> {item.TradeInLoyaltyPriceWithCurrency}</span>
                                  </h5>
                                  <del className='tradein-buying-popup__step1-item__info__downprice'>
                                    <Text field={dataFields['Trade-in Value Label']} />
                                    <span className='text-bold'> {item.TradeInSellingPriceWithCurrency}</span>
                                  </del>
                                </>
                              ) : (
                                <h5 className='tradein-buying-popup__step1-item__info__downprice'>
                                  <Text field={dataFields['Trade-in Value Label']} />
                                  <span className='text-bold'> {item.TradeInSellingPriceWithCurrency}</span>
                                </h5>
                              )}
                              <p className='tradein-buying-popup__step1-item__info__desc'>{item.Description}</p>
                            </div>
                          </div>
                        </Fragment>
                      );
                    })}
                    {errors.selectedTradeInProduct && touched.selectedTradeInProduct && (
                      <span className='error-validate'>Select trade-in product is required</span>
                    )}
                  </div>
                </div>
                {expansivePopup ? (
                  <Fragment>
                    {/* 2. Upload image of trade-in product */}
                    <TradeInUploadImage dataFields={dataFields} formikRefCurrent={formikRef.current} />

                    {/* 3. Provide information on the trade-in product */}
                    <div className='tradein-step-section step-provide-info'>
                      <div className='tradein-step-section__title'>
                        <div className='tradein-step-section__title__number tradein-step-number'>
                          <span className='tradein-step-number__value'>3</span>
                        </div>
                        <Text
                          tag='h4'
                          className='tradein-step-section__title__label'
                          field={dataFields['Provide Information On The Trade In Product Title']}
                        />
                      </div>
                      <div className='tradein-step-section__content'>
                        <div className='form-group'>
                          <input
                            placeholder={dataFields?.['Brand Label']?.value}
                            onChange={handleChange}
                            value={selectedTradeInProduct.Brand.toLowerCase() === 'osim' ? selectedTradeInProduct.Brand.toUpperCase() : values.brand}
                            id='brand'
                            name='brand'
                            className='form-control form-control-lg'
                            disabled={selectedTradeInProduct?.Brand.toLowerCase() === 'osim' ? true : false}
                          />
                          {errors.brand && touched.brand && <span className='error-validate'>{errors.brand}</span>}
                        </div>
                        <div className='form-group'>
                          <input
                            placeholder={dataFields?.['Product Name Label']?.value}
                            onChange={handleChange}
                            value={values.productName}
                            id='productName'
                            name='productName'
                            className='form-control form-control-lg'
                          />
                          {errors.productName && touched.productName && <span className='error-validate'>{errors.productName}</span>}
                        </div>
                        <div className='form-group'>
                          <input
                            placeholder={dataFields?.['Model Label']?.value}
                            onChange={handleChange}
                            value={values.model}
                            id='model'
                            name='model'
                            className='form-control form-control-lg'
                          />
                          {errors.model && touched.model && <span className='error-validate'>{errors.model}</span>}
                        </div>
                      </div>
                    </div>
                    {/* Terms and Conditions */}
                    <div className='tradein-buying-popup__checkbox'>
                      <div className='osim-checkbox'>
                        <input
                          className='osim-checkbox-input'
                          type='checkbox'
                          id='agreement'
                          name='agreement'
                          onChange={handleChange}
                          checked={values.agreement}
                        />
                        <label className='osim-checkbox-label' htmlFor='agreement'>
                          {dataFields?.['Term Condition Link']?.value?.href.length ? (
                            <>
                              <Text field={dataFields['Agree with Term Condition']} />{' '}
                              <Link field={dataFields['Term Condition Link']}>
                                <Text field={dataFields['Term And Condition Label']} />
                              </Link>
                            </>
                          ) : (
                            <>
                              <Text field={dataFields['Agree with Term Condition']} /> <Text field={dataFields['Term And Condition Label']} />
                            </>
                          )}
                        </label>
                        {errors.agreement && touched.agreement && <span className='error-validate'>{errors.agreement}</span>}
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <></>
                )}
              </div>
              <div className='tradein-buying-popup__btn'>
                <button
                  className={`btn btn-primary ${BOTradeIn?.isTradeInOptionsFlag ? (isValid ? '' : 'disabled ') : dirty && isValid ? '' : 'disabled'}`}
                  type='submit'
                >
                  <Text field={dataFields['Add Trade In Popup Button']} />
                </button>
                {BOTradeIn?.tradeInActive ? (
                  <Text
                    field={dataFields['Remove Trade In Option Label']}
                    className='tradein-buying-popup__btn__link'
                    onClick={() => onRemoveTradeInOptions()}
                    tag='span'
                  />
                ) : (
                  <></>
                )}
              </div>
            </Form>
          );
        }}
      </Formik>
    )
  );
};

BOTradeInList.propTypes = {
  contentData: Proptypes.any,
  activeItemsId: Proptypes.any,
  onToogleFnc: Proptypes.any,
  onCloseModalHandler: Proptypes.any,
  dataFields: Proptypes.object,
  // TradeInOptions
  onShowTradeInOptionsModal: Proptypes.func
};

export default BOTradeInList;
