import { deepCopy } from '@utils/utility';

import BOFreeGiftsModel from '../../BuyingOptions/BOFreeGifts/model/BOFreeGiftsModel';
import BundleBOPriceModel from './BundleBOPriceModel';
import BundleDeliveryOptionsModel from './BundleDeliveryOptionsModel';
import BundleProductsModel from './BundleProductsModel';

export default class BundleBODataModel {
  constructor() {
    this['BundleId'] = '';

    this['BundleName'] = '';

    this['DeliveryOptions'] = [];

    this['Errors'] = [];

    this['FreeGift'] = [];

    this['Price'] = new BundleBOPriceModel();
    
    this['Products'] = [];

    this['Active Variant All'] = [];

    this['Active Warranty All'] = [];

    this['Validate Product Pass'] = [];

    this['Url'] = '';

    this.IsFixedPrice = false;

    this['InstallmentPeriods'] = [];

    this['Coupon'] = [];

    this['Brand'] = [];
  }
  filterFreeGift(data) {
    if (data?.length) {
      let newFreeGiftsList = [];

      let newFilteredOutStockFreeGift = [];

      data.map((element) => {
        newFreeGiftsList.push(new BOFreeGiftsModel().getData(deepCopy(element) || {}));
      });

      newFilteredOutStockFreeGift = Array.from(new Set(newFreeGiftsList.filter((item) => item.Quantity > 0)));

      const newFilteredFreeGift = Array.from(new Set(newFilteredOutStockFreeGift.map((item) => item.Slot)))
        .map((itemSlot) => {
          return {
            type: itemSlot,
            items: newFilteredOutStockFreeGift.filter((item) => item.Slot === itemSlot)
          };
        })
        .sort(function (a, b) {
          return a.type - b.type;
        });

      return newFilteredFreeGift;
    }

    return [];
  }

  getData(dataSource, SubLines, Url) {
    if (dataSource) {
      this['Url'] = Url;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleId')) {
      this['BundleId'] = dataSource['BundleId'] ? dataSource['BundleId'] : this['BundleId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleName')) {
      this['BundleName'] = dataSource['BundleName'] ? dataSource['BundleName'] : this['BundleName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products')) {
      let newProducts = [];

      if (dataSource['Products']) {
        let filterSubLines = null;

        dataSource['Products'].map((element, elementIndex) => {
          if (SubLines) {
            filterSubLines = SubLines.filter((item) => item.UniqueProductId === `${element['ProductId'] + elementIndex}`)[0];
          }

          newProducts.push(new BundleProductsModel().getData(deepCopy(element) || {}, elementIndex, filterSubLines));
        });
      }

      this['Products'] = dataSource['Products'] ? newProducts : this['Products'];

      this['Active Variant All'] = this['Products'].map((item) => item['Active Variant']);

      this['Active Warranty All'] = this['Products'].map((item) => item['Active Warranty']);

      this['Validate Product Pass'] = this['Products'].map((item) => {
        return {
          isProductPass: item['isProductPass'],
          UniqueProductId: item['UniqueProductId'],
          UniqueVariantId: `${item['UniqueProductId']}VariantID`,
          UniqueWarrantyId: `${item['UniqueProductId']}WarrantyID`,
          UniqueAddID: `${item['UniqueProductId']}UniqueAddID`
        };
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DeliveryOptions')) {
      let newDeliveryOptions = [];

      if (dataSource['DeliveryOptions']) {
        dataSource['DeliveryOptions'].map((element, elementIndex) => {
          newDeliveryOptions.push(new BundleDeliveryOptionsModel().getData(deepCopy(element) || {}, elementIndex));
        });
      }

      this['DeliveryOptions'] = dataSource['DeliveryOptions'] ? newDeliveryOptions : this['DeliveryOptions'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'FreeGift') && dataSource.FreeGift.FreeGifts.length > 0) {
      this.FreeGift = this.filterFreeGift(dataSource.FreeGift.FreeGifts);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DynamicBundleOption')) {
      const dynamicBundleOption = dataSource['DynamicBundleOption'];
      if (dynamicBundleOption?.IsFixedPrice) this.IsFixedPrice = true;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InstallmentPeriods')) {
      this['InstallmentPeriods'] = dataSource['InstallmentPeriods'] ?? this['InstallmentPeriods'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Coupon')) {
      this['Coupon'] = dataSource['Coupon'] ?? this['Coupon'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Brand')) {
      this['Brand'] = dataSource['Brand'] ?? this['Brand'];
    }

    return this;
  }
}
