import './CPProductListing.scss';

import React from 'react';

import CPProductInfo from '../CPProductInfo';

const CPProductListing = () => {
  return <div className='cp-product-listing'>
    <div className='cp-product-listing__container'>
      <CPProductInfo />
      <CPProductInfo />
      <CPProductInfo />
    </div>
  </div>;
};

export default CPProductListing;
