import '../TwoColumn.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import FAQFrequentlyCTAModel from './model/FAQFrequentlyCTAModel';

const FAQFrequentlyCTA = ({fields})=> {
  const [dataSources, setDataSources] = useState(null);
  
  useEffect(()=> {
    setDataSources(new FAQFrequentlyCTAModel().getData(fields));
  },[]);

  return dataSources && (
    <div className='osim-support-section warranty-registration'>
      <Text field={dataSources.Title} tag='h3' className='osim-support-section__title'/>
      <Text field={dataSources['Description Title']} tag='p' className='osim-support-section__info'/>
      <div className='osim-support-section__button'>
        <Link field={dataSources['Button Url']} className='btn btn-primary'>
          <Text field={dataSources['Button Text']}/>
        </Link>
      </div>
    </div>
  );
};

FAQFrequentlyCTA.propTypes = {
  fields: Proptypes.object
};

export default FAQFrequentlyCTA;