import Proptypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react';

const ProductImagesItem = ({ dataContent }) => {
  return dataContent ? (
    <Fragment>
      <img data-src={dataContent} className='product-image__content__item__image swiper-lazy animated' />
      <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
    </Fragment>
  ) : null;
};

ProductImagesItem.propTypes = {
  dataContent: Proptypes.any
};

export default ProductImagesItem;
