import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import PromotionListingItemModel from '../PromotionListingItem/model/PromotionListingItemModel';
import CountdownFormatSettingModel from './CountdownFormatSettingModel';

export default class PromotionListingModel {
  constructor() {
    this['Maximum Characters To Display Description'] = '';

    this['Button Text'] = new SitecoreTextModel();

    this['Promotion List Title'] = new SitecoreTextModel();

    this['items'] = [];

    this['countdownFormatSetting'] = new CountdownFormatSettingModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Maximum Characters To Display Description')) {
      this['Maximum Characters To Display Description'] =
        dataSource['Maximum Characters To Display Description'] ?? this['Maximum Characters To Display Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Button Text')) {
      this['Button Text'].getData(dataSource['Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Promotion List Title')) {
      this['Promotion List Title'].getData(dataSource['Promotion List Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      dataSource['items'].map((element) => {
        this['items'].push(new PromotionListingItemModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'countdownFormatSetting')) {
      this['countdownFormatSetting'].getData(dataSource['countdownFormatSetting']);
    }

    return this;
  }
}
