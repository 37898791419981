import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getTotalBookedSlotsStart } from '@redux/actions/campaignRegister/getTotalBookedSlots';

const useSortExcludeDate = (dateRange, campaign, storeId, contactId, bookedDate = null) => {
  const dispatch = useDispatch();
  const { bookedSlots } = useSelector((state) => state.campaignRegisterReducer.getTotalBookedSlotsReducer);

  useEffect(() => {
    if (storeId) {
      const payload = {
        campaignId: campaign?.Id,
        storeId: storeId
      };
      if (contactId) payload.contactId = contactId;

      dispatch(getTotalBookedSlotsStart(payload));
    }
  }, [campaign?.Id, storeId]);

  return useMemo(() => {
    const store = campaign?.Stores?.find((store) => store.Id === storeId);
    const slotEachDay = store?.SlotsEachDay;
    const excludedDates = [];
    const dateArray = [];
    const millisecondsPerDay = 86400000; // INFO: 24 * 60 * 60 * 1000

    const convertToDateArray = (objectDate) => {
      for (let i = objectDate?.start.getTime(); i <= objectDate?.end.getTime(); i += millisecondsPerDay) {
        dateArray.push(new Date(i));
      }
    };

    // INFO: convert objects array into date array
    if (dateRange) {
      for (const date of dateRange) {
        convertToDateArray(date);
      }
    }

    // INFO: find out slot date
    for (const date of dateArray) {
      const dateInBookedSlots = bookedSlots.find((slot) => new Date(slot.Date).getTime() === date.getTime());
      if (dateInBookedSlots?.TotalBookedSlots >= slotEachDay) {
        excludedDates.push(date.getTime());
      }
    }

    // INFO: remove booked date value from excluded dates data if yes
    if (bookedDate) {
      const convertBookedDate = new Date(bookedDate)?.getTime();
      const indexOfBookedDate = excludedDates.indexOf(convertBookedDate);

      if (indexOfBookedDate >= 0) excludedDates.splice(indexOfBookedDate, 1);
    }

    return excludedDates;
  }, [bookedSlots, dateRange, campaign?.Stores, storeId, bookedDate]);
};

export default useSortExcludeDate;
