import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TextModel = [
  'Agree Label',
  'Back To Fulfilment Label',
  'Confirm And Pay Label',
  'Pay In Full Label',
  'Pay In Instalments Label',
  'Payment Method Label',
  'Power By Label',
  'Read More Label',
  'Receive Offers Label',
  'Secondary Terms And Conditions Label',
  'Special Request Description',
  'Special Request Label',
  'Special Request Placeholder Text',
  'Special Request Maximum',
  'Special Request Maximum Error Message',
  'Terms And Conditions Label',
  'Disable Confirm Button From',
  'Disable Confirm Button To',
  'Close Button Text'
];

const LinkModel = [
  'Back To Fulfilment Link',
  'Confirm And Pay Link',
  'Secondary Terms And Conditions Link',
  'Terms And Conditions Link',
  'Back To Fulfilment Offer Link'
];

export default class PaymentMethodModel {
  constructor() {
    TextModel.map(item => this[item] = new SitecoreTextModel());

    LinkModel.map(item => this[item] = new SitecoreLinkModel());
  }
  getData(dataSource) {
    TextModel.map(item => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LinkModel.map(item => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
