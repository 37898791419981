import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { getCrmCustomer } from '@redux/actions/loyalty/getCrmCustomer';
import * as actions from '@redux/actions/loyalty/getPosCustomerId/getPosCustomerIdTypes';
import { getAsObservable } from '@services/genericService';

import { getPosCustomerIdFailed, getPosCustomerIdSuccess } from '../../../actions/loyalty/getPosCustomerId';
import { GET_POS_CUSTOMER_ID } from './configs';

export const getPosCustomerIdEpic = (action$) =>
  action$.pipe(
    ofType(actions.GET_POS_CUSTOMER_ID),
    switchMap((action) => {
      return getAsObservable(GET_POS_CUSTOMER_ID).pipe(
        switchMap((response) => {
          if (response.status === 200 || response.data.Success) {
            const params = {
              'country': action.payload.country,
              'by': 'customer',
              'search_val': response.data
            };

            return of(getPosCustomerIdSuccess(response.data), getCrmCustomer(params));
          } else {
            return getPosCustomerIdFailed('Failed');
          }
        }),
        catchError((error) => of(getPosCustomerIdFailed(error.message || 'Failed')))
      );
    })
  );

