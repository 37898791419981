import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import AddressForMultiLanguage from '@components/SlicingPage/MyAccount/MyAddress/AddressForMultiLanguage';
import { addNewAddress, addNewAddressAndSetBilling } from '@redux/actions/checkoutProcess';
import {clearAddressDatasources} from '@redux/actions/myAddress';
import {Text} from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

const AddNewAddress = (props) => {
  const { isShippingAddressFullfillment, dataSources, addressCurrent, setAddAddressEvt,withSetBilling,needCheckArea,toggleSameAsDeliveryEvt,isAddNew, cartLineData, type, macauAddressCurrent } = props;
  const dispatch = useDispatch();
  const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
  const isLogin = useSelector(state => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const addressListReducer = useSelector(state => state.checkoutProcesstReducer.addressListReducer);
  
  const onSubmmitAddAddressHandler = (params) => {    
    const isFistTime = addressListReducer.userAddressList?.length ? false : true;
    if (isLogin) {
      if (needCheckArea) {
        setAddAddressEvt({
          ...params,
          IsPrimary: isFistTime,
          IsBilling: isFistTime,
          CountryCode: dataSources['Address Settings']['Country Settings']['Country Code'].value || 'SA',
          Country: dataSources['Address Settings']['Country Settings']['Name'].value || 'Sample'
        }, params.IsBilling);
        
        setShowAddAddressPopup(false);
      } else {
        const addNewAddressParam = {
          ...params,
          IsPrimary: isFistTime,
          IsBilling: isFistTime,
          CountryCode: dataSources['Address Settings']['Country Settings']['Country Code'].value || 'SA',
          Country: dataSources['Address Settings']['Country Settings']['Name'].value || 'Sample'
        };
        if (withSetBilling) {
          dispatch(addNewAddressAndSetBilling(addNewAddressParam));
          
          toggleSameAsDeliveryEvt();
        } else {
          dispatch(addNewAddress(addNewAddressParam));
        }
      }
    } else {
      setShowAddAddressPopup(false);
      
      setAddAddressEvt({
        ...params,
        IsBilling: false,
        IsPrimary: false,
        CountryCode: dataSources['Address Settings']['Country Settings']['Country Code'].value || 'SA',
        Country: dataSources['Address Settings']['Country Settings']['Name'].value || 'Sample'
      }, isShippingAddressFullfillment ? params.IsBilling : true);
    }
  };

  const onCloseAddressesHandler = () => {
    setShowAddAddressPopup(false);
    
    dispatch(clearAddressDatasources());
  };
  
  useDidUpdateEffect(() => {
    if (!addressListReducer.error) {
      setShowAddAddressPopup(false);
    }
  }, [addressListReducer.toogleCheck]);

  return (
    <Fragment>
      <div className='my-addresses__section__btn-add'>
        <button
          href='#'
          className='my-account-section__content__btn-add btn btn-outline-CTA2'
          onClick={() => {
            setShowAddAddressPopup(true);
            
            dispatch(clearAddressDatasources());
          }}
        >
          <Text field={dataSources['Add New Address Button Text']} />
        </button>
      </div>
      {dataSources?.['Address Settings']?.['Address Elements'] ?
        <div className='address-component'>
          <ModalComponent
            isShow={showAddAddressPopup}
            titlePopup={<Text field={dataSources['DataSoures Popup']['PopUp Add New Address Label']} tag='span' />}
            onCloseHandler={onCloseAddressesHandler}
          >
            <AddressForMultiLanguage
              dataFields={dataSources}
              isAddNew={isAddNew}
              setSubmitAddressEvt={onSubmmitAddAddressHandler}
              addressCurrent={addressCurrent}
              isShippingAddressFullfillment={isShippingAddressFullfillment}
              cartLineData={cartLineData}
              type={type}
              macauAddressCurrent={macauAddressCurrent}
            />
          </ModalComponent>
        </div> : ''}
    </Fragment>
  );
};

AddNewAddress.propTypes = {
  useThisShippingAddress: PropTypes.bool,
  dataSources: PropTypes.object,
  isShippingAddressFullfillment: PropTypes.bool,
  needSetFulfillment: PropTypes.bool,
  needUpdateBilling: PropTypes.bool,
  withSetBilling: PropTypes.bool,
  withSetFulfillMent: PropTypes.bool,
  needCheckArea: PropTypes.bool,
  isAddNew: PropTypes.bool,
  addressCurrent: PropTypes.object,
  setAddAddressEvt: PropTypes.func,
  setSameAsDelivery: PropTypes.func,
  toggleSameAsDeliveryEvt: PropTypes.func,
  cartLineData: PropTypes.array,
  type: PropTypes.string,
  macauAddressCurrent: PropTypes.object
};

export default AddNewAddress;
