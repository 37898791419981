export const MAP_KEY = 'GTrnXa5hwXGwgQnTBG28SHBubErMKm3f';

export const MAP_VERSION = '3.0';

export const MAP_ZOOM_DEFAULT = 0;

export const MAP_ZOOM_WHEN_HOVER = 10;

export const MAP_ZOOM_SELECTED = 20;

