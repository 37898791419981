import { useCallback } from 'react';

import { useTradeInUploadImage } from '../TradeInUploadImageProvider';

export const useRemoveTradeInImage = () => {
  const { formikRefCurrent } = useTradeInUploadImage();

  return useCallback(
    (imageUrl) => {
      const imageTradeInProduct = [...formikRefCurrent.values.imageTradeInProduct];
      const indexOfImageUrl = imageTradeInProduct.findIndex((image) => image.url === imageUrl);

      if (indexOfImageUrl >= 0) imageTradeInProduct.splice(indexOfImageUrl, 1);

      formikRefCurrent.setFieldValue('imageTradeInProduct', imageTradeInProduct);
    },
    [formikRefCurrent]
  );
};
