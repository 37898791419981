import SitecoreTextModel from '@models/SitecoreTextModel';

const textArr = [
  'Back To Login Text',
  'Confirm New Password Label',
  'Instruction',
  'New Password Label',
  'Reset Password Button Label',
  'Reset Password Fail Description',
  'Reset Password Fail Instruction',
  'Reset Password Successful Description',
  'Reset Password Successful Instruction',
  'Successful Instruction'
];

export default class NewPasswordModel {
  constructor() {
    textArr.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['NewpasswordInfo'] = [];
  }
  getData(dataSource) {
    textArr.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this.item;
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NewpasswordInfo')) {
      this['NewpasswordInfo'] = dataSource['NewpasswordInfo'];
    }

    return this;
  }
}
