import React from 'react';
import { Fragment } from 'react';

const ProductListingShimmer = () => {

  return (
    <Fragment>
      <div className='category-landing'>
        <div className='container'>
          <div className='product-action'>
            <div className='row'>
              <div className='col-6 two-column-CTA-Button__col skeleton__item'>
                <div className='two-column-CTA-Button__contents'>
                  <div className='two-column-CTA-Button__contents__image image-wrap'>
                    <div className='image-line'></div>
                  </div>
                  <span className='text-line w-50'></span>
                </div>
              </div>
              <div className='col-6 two-column-CTA-Button__col skeleton__item'>
                <div className='two-column-CTA-Button__contents'>
                  <div className='two-column-CTA-Button__contents__image image-wrap'>
                    <div className='image-line'></div>
                  </div>
                  <span className='text-line w-50'></span>
                </div>
              </div>
            </div>
          </div>
          <div className='category-landing--view category-landing--list-view skeleton__item'>
            <div className='swiper-slide category-landing__product category-product'>
              <div className='category-product__wrap'>
                <div className='category-product__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <div className='category-product__main'>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                </div>
              </div>
            </div>
            <div className='swiper-slide category-landing__product category-product'>
              <div className='category-product__wrap'>
                <div className='category-product__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <div className='category-product__main'>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                </div>
              </div>
            </div>
            <div className='swiper-slide category-landing__product category-product'>
              <div className='category-product__wrap'>
                <div className='category-product__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <div className='category-product__main'>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                </div>
              </div>
            </div>
            <div className='swiper-slide category-landing__product category-product'>
              <div className='category-product__wrap'>
                <div className='category-product__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <div className='category-product__main'>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                </div>
              </div>
            </div>
            <div className='swiper-slide category-landing__product category-product'>
              <div className='category-product__wrap'>
                <div className='category-product__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <div className='category-product__main'>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                </div>
              </div>
            </div>
            <div className='swiper-slide category-landing__product category-product'>
              <div className='category-product__wrap'>
                <div className='category-product__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <div className='category-product__main'>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                </div>
              </div>
            </div>
            <div className='swiper-slide category-landing__product category-product'>
              <div className='category-product__wrap'>
                <div className='category-product__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <div className='category-product__main'>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                </div>
              </div>
            </div>
            <div className='swiper-slide category-landing__product category-product'>
              <div className='category-product__wrap'>
                <div className='category-product__image image-wrap'>
                  <div className='image-line'></div>
                </div>
                <div className='category-product__main'>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                  <span className='text-line'></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ProductListingShimmer;