import produce from 'immer';

import {
  GET_LATEST_SO_CART_FAILURE,
  GET_LATEST_SO_CART_START,
  GET_LATEST_SO_CART_SUCCESS
} from '@redux/actions/personalizeOffer/getLatestSOCart/types';

const initialStates = {
  isLoading: false,
  error: null,
  data: null
};

const getLatestSOCartReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_LATEST_SO_CART_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case GET_LATEST_SO_CART_SUCCESS:
      draft.isLoading = false;

      draft.data = action.payload;
      break;

    case GET_LATEST_SO_CART_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getLatestSOCartReducer;
