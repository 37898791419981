import React from 'react';

import ProductReviewItemWidget from '@components/ProductWarrantyRegistration/ProductWarrantyDetail/ProductReviewItemWidget';
import FullReviewListing from '@components/ReviewPage/FullReviewListing';
import LimitedReviewListing from '@components/ReviewPage/LimitedReviewListing';
import ProductReview from '@components/ReviewPage/ProductReview';
import ReviewsSummary from '@components/ReviewPage/ReviewsSummary';

const ProductPage = () => {
  return (
    <>
      <ProductReview />
      <ReviewsSummary />
      <ProductReviewItemWidget/>
      <LimitedReviewListing/>
      <FullReviewListing/>
    </>
  );
};

export default ProductPage;
