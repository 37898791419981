import './SpinForm.scss';

import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getMyPrizeLuckyDraw, getNumberSpinEmail } from '@redux/actions/spinAndWin';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { EMAIL_USER_SPIN_SESSION_STORAGE_NAME } from '../configs';
import { SpinWinBannerContext } from '../Spin&WinBanner';
import { useSubmitEmail } from './useSubmitEmail';

const SpinForm = ({ spinSuccess }) => {
  const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

  const dispatch = useDispatch();

  const { layoutData, luckyDrawId, isDisabledSpinBtn, onSpinBtnClick, setUserIsSpinning, userIsSpinningInitialState } =
    useContext(SpinWinBannerContext);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const numberSpinOfEmail = useSelector((state) => state.spinAndWinReducer.getNumberSpinEmailReducer) || {};
  const { accountUser } = useSelector((state) => state.singleSignOnReducer.userInfoReducer);
  const submitEmailService = useSubmitEmail();
  const [emailInput, setEmailInput] = useState('');
  const validateEmailInputInitialState = { status: true, msg: '' };
  const [isValidEmailInput, setIsValidEmailInput] = useState(validateEmailInputInitialState);
  const [isDisableEmailInput, setIsDisableEmailInput] = useState(false);

  useEffect(() => {
    const emailLogin = accountUser?.Email || JSON.parse(window.sessionStorage.getItem(EMAIL_USER_SPIN_SESSION_STORAGE_NAME)) || '';
    if (emailLogin) {
      setEmailInput(emailLogin);

      dispatch(
        getNumberSpinEmail({
          email: emailLogin,
          luckyDrawId: luckyDrawId
        })
      );

      dispatch(
        getMyPrizeLuckyDraw({
          email: emailLogin,
          luckyDrawId: luckyDrawId
        })
      );
    }
  }, []);

  useEffect(() => {
    if (numberSpinOfEmail.isGetNumSpinSuccess) {
      setIsDisableEmailInput(numberSpinOfEmail.isGetNumSpinSuccess);

      setUserIsSpinning({ Email: emailInput });

      numberSpinOfEmail.numberSpin <= 0 &&
        setIsValidEmailInput({
          status: false,
          msg: '*This email has reached the spin limit!'
        });
    }
  }, [numberSpinOfEmail.isGetNumSpinSuccess]);

  useDidUpdateEffect(() => {
    if (spinSuccess) {
      dispatch(
        getNumberSpinEmail({
          email: emailInput,
          luckyDrawId: luckyDrawId
        })
      );

      dispatch(
        getMyPrizeLuckyDraw({
          email: emailInput,
          luckyDrawId: luckyDrawId
        })
      );
    }
  }, [spinSuccess]);

  const handleCheckEmailSpin = async () => {
    if (emailInput) {
      if (emailRegex.test(emailInput)) {
        dispatch(
          getNumberSpinEmail({
            email: emailInput,
            luckyDrawId: luckyDrawId
          })
        );

        dispatch(
          getMyPrizeLuckyDraw({
            email: emailInput,
            luckyDrawId: luckyDrawId
          })
        );

        setIsValidEmailInput(validateEmailInputInitialState);

        // INFO: submit email coupon
        await submitEmailService(emailInput);
      } else {
        setIsValidEmailInput({ status: false, msg: messageObj?.['Msg_001.3'] });
      }
    }
  };

  const onEditEmailBtnClick = () => {
    setIsDisableEmailInput(false);

    setUserIsSpinning(userIsSpinningInitialState);
  };

  return (
    <div className='spin-form'>
      <div className='spin-form__input'>
        <div className='spin-form__input__group'>
          <input
            type='text'
            id='email'
            className='form-control form-control-lg'
            name='email'
            placeholder={layoutData?.['Email Placeholder Text']?.value}
            disabled={isDisableEmailInput}
            onChange={(e) => {
              let emailIsEnter = e.target.value.trim();

              setEmailInput(emailIsEnter);
            }}
            value={emailInput}
          />
          {isDisableEmailInput && (
            <Text tag='span' className='spin-form__input__edit btn-link-text' onClick={onEditEmailBtnClick} field={layoutData['Edit Button Text']} />
          )}
        </div>
        {isDisableEmailInput ? (
          (emailInput && !isValidEmailInput.status) ||
          (numberSpinOfEmail.isGetNumSpinSuccess && numberSpinOfEmail.numberSpin <= 0 && numberSpinOfEmail.isErrorMsg) ? (
              <span className='error-validate'>{isValidEmailInput.msg}</span>
            ) : (
              ''
            )
        ) : emailInput && !isValidEmailInput.status ? (
          <span className='error-validate'>{isValidEmailInput.msg}</span>
        ) : (
          ''
        )}
      </div>
      <div className='spin-form__info text-center'>
        {isDisableEmailInput ? (
          numberSpinOfEmail.isGetNumSpinSuccess &&
          numberSpinOfEmail.numberSpin > 0 && (
            <span className='spin-form__info__line'>
              {layoutData?.['Spins Left Label']?.value} {numberSpinOfEmail.numberSpin}
            </span>
          )
        ) : (
          <span className='spin-form__info__line'>{`${layoutData?.['Number of spin']?.value} ${layoutData?.['Chances To Win']?.value}`}</span>
        )}
      </div>
      <div className={'spin-form__button text-center'}>
        <button
          type='button'
          className='btn btn-primary'
          onClick={async (e) => {
            e.preventDefault();

            if (isDisableEmailInput && numberSpinOfEmail.isGetNumSpinSuccess && numberSpinOfEmail.numberSpin > 0) {
              onSpinBtnClick();
            } else {
              await handleCheckEmailSpin();
            }
          }}
          disabled={isDisabledSpinBtn || !emailInput}
        >
          {isDisableEmailInput && numberSpinOfEmail.isGetNumSpinSuccess && numberSpinOfEmail.numberSpin > 0 ? (
            <Text tag='span' field={layoutData['Spin Button Text']} />
          ) : (
            <Text tag='span' field={layoutData['Email Check Label']} />
          )}
        </button>
      </div>
    </div>
  );
};

SpinForm.propTypes = {
  spinSuccess: PropTypes.bool
};

export default SpinForm;
