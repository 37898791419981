import './CPPaging.scss';

import { any, func } from 'prop-types';
import React, { useState } from 'react';

import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

const CPPaging = ({ isReset, itemPerPage, totalItem, onChangePageHandler }) => {
  const [isReloading, setReloading] = useState(false);

  const [pagination, setPagination] = useState({
    page: 1,
    limit: itemPerPage
  });

  const totalPage = Math.ceil(totalItem / pagination.limit);

  useDidUpdateEffect(() => {
    resetPagingFnc();
  }, [isReset]);

  const listPageRender = () => {
    if (totalPage < 5) {
      return [...Array(totalPage + 1).keys()].slice(1);
    } else if (pagination.page < 5) {
      return [1, 2, 3, 4, 5];
    } else if (pagination.page > totalPage - 3) {
      return [...Array(5).keys()].reverse().map((v) => totalPage - v);
    } else {
      return [pagination.page - 2, pagination.page - 1, pagination.page, pagination.page + 1, pagination.page + 2];
    }
  };

  const resetPagingFnc = () => {
    setReloading(true);

    setPagination((prev) => ({
      ...prev,
      page: 1
    }));

    setTimeout(() => {
      setReloading(false);
    }, 100);
  };

  const onHandlePrev = () => {
    setPagination((prev) => ({
      ...prev,
      page: pagination.page - 1
    }));

    onChangePageHandler(pagination.page - 1);
  };

  const onHandleNext = () => {
    setPagination((prev) => ({
      ...prev,
      page: pagination.page + 1
    }));

    onChangePageHandler(pagination.page + 1);
  };

  const onHandleFirst = () => {
    setPagination({
      ...pagination,
      page: 1
    });

    onChangePageHandler(1);
  };

  const onHandleLast = () => {
    setPagination({
      ...pagination,
      page: totalPage
    });

    onChangePageHandler(totalPage);
  };

  const onHandleNumber = (value) => {
    setPagination({
      ...pagination,
      page: value
    });

    onChangePageHandler(value);
  };

  return (
    <>
      {totalPage > 1 ? (
        <>
          {!isReloading ? (
            listPageRender() && listPageRender().length ? (
              <ul className='cp-paging' key={totalItem}>
                <li
                  className={pagination.page - 1 == 0 ? 'cp-paging__item prev-button disabled' : 'cp-paging__item prev-button'}
                  onClick={pagination.page <= 1 ? null : () => onHandleFirst()}
                >
                  <i className='icon-chevrons-left'></i>
                </li>
                <li 
                  className={pagination.page - 1 == 0 ? 'cp-paging__item disabled' : 'cp-paging__item'} 
                  onClick={pagination.page <= 1 ? null : () => onHandlePrev()}
                >
                  <span className='icon-chevron-left chevron'></span>
                </li>
                {listPageRender() && listPageRender().length ? listPageRender().map((item, index) => {
                  return (
                    <li
                      className={pagination.page === item ? 'cp-paging__item active' : 'cp-paging__item'}
                      key={index}
                      onClick={() => onHandleNumber(item)}
                    >
                      <span>{item}</span>
                    </li>
                  );
                })
                  : null}
                <li 
                  className={pagination.page == totalPage ? 'cp-paging__item disabled' : 'cp-paging__item'} 
                  onClick={pagination.page >= totalPage ? null : () => onHandleNext(pagination)}
                >
                  <span className='icon-chevron-right chevron'></span>
                </li>
                <li 
                  className={pagination.page == totalPage ? 'cp-paging__item disabled' : 'cp-paging__item'} 
                  onClick={pagination.page >= totalPage ? null : () => onHandleLast()}
                >
                  <i className='icon-chevrons-right'></i>
                </li>
              </ul>
            ) : null
          ) : null}
        </>
      ) : null}
    </>
  );
};

CPPaging.propTypes = {
  isReset: any,
  isResetPaging: any,
  itemPerPage: any,
  totalItem: any,
  onChangePageHandler: func,
};

export default CPPaging;
