import FieldsModel from './FieldsModel';

export default class TwoColumnImageWithAccodionItemModel {
  constructor() {
    this['displayName'] = '';

    this['fields'] = new FieldsModel();

    this['id'] = '';

    this['name'] = '';

    this['url'] = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'displayName')) {
      this['displayName'] = dataSource['displayName'] ? dataSource['displayName'] : this['displayName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this['id'] = dataSource['id'] ? dataSource['id'].toLowerCase() : this['id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'name')) {
      this['name'] = dataSource['name'] ? dataSource['name'] : this['name'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'url')) {
      this['url'] = dataSource['url'] ? dataSource['url'] : this['url'];
    }

    return this;
  }
}
