import PropTypes from 'prop-types';
import React from 'react';

const SFExpressPopupItem = ({ inputData }) => {
  return (
    <div className='sf-express__item'>
      <h4 className='sf-express__item__name-store'>{inputData.TitleName}</h4>
      <p className='sf-express__item__address-store'>{inputData.AddressName}</p>
    </div>
  );
};

SFExpressPopupItem.propTypes = {
  inputData: PropTypes.object
};

export default SFExpressPopupItem;
