import './BalanceDetails.scss';

import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import {Text} from '@sitecore-jss/sitecore-jss-react';

import { getMyBalancesById } from '../../../../redux/actions/myOrders';
import OrderInfoComponent from '../../MyAccount/OrderDetails/OrderInfo';
import PaymentMethod from '../../MyAccount/OrderDetails/PaymentMethodComponent';
import TotalOrder from '../../MyAccount/OrderDetails/TotalOrder';
import BalanceDetailModel from './model/BalanceDetailModel';

const BalanceDetails = ({fields})=> {
  const cartData = useSelector((state) => state.myOrderReducer.balanceByIdReducer.currentCartCustom || null);
  const currentCart = useSelector((state) => state.myOrderReducer.balanceByIdReducer.currentCart || null);
  const [dataSource, setDataSource] = useState(null);
  const dispatch = useDispatch();

  useEffect(()=> {
    setDataSource(new BalanceDetailModel().getData(fields));
  }, []);

  useEffect(()=> {
    const url = new URL(window.location.href);
    const orderId = url.searchParams.get('OrderId');
    const context = SitecoreContextFactoryService.getValueContextItem('site');
    const site = context?.name;

    dispatch(getMyBalancesById({
      orderId : orderId,
      shopName: site
    }));
  }, []);

  return(dataSource && cartData &&
    <>
      {
        cartData?.DepositListProduct.length > 0 ? (
          <div className='balance-payment'>
            <div className='balance-payment__info'>
              {/* {
                cartData.DepositListProduct.map((depositProduct, index)=> {
                  return(
                    <OrderInfoComponent
                      key={index}
                      page='balance-payment'
                      type='deposit'
                      cartData={cartData}
                      layoutData={dataSource}
                      data={cartData.DepositListProduct}
                      depositProduct={depositProduct}
                    ></OrderInfoComponent>
                  );
                })
              } */}
              {cartData.Delivery?.length > 0 ? (
              <OrderInfoComponent
                page='balance-payment'
                type='delivery'
                cartData={cartData}
                layoutData={dataSource}
                fields={fields}
                data={cartData.Delivery}
                packageNumber={1}
              ></OrderInfoComponent>
            ) : null}
            </div>
          </div>
        ) : (
          <div className='container'>
            <div className='balance-payment__empty'>
              <Text field={dataSource?.['Empty Messages']}/>
            </div>
          </div>
        )
      }
      <div className='balance-payment__summary '>
        <div className='container'>
          <div className='balance-payment__summary__wrap'>
            <div className='row balance-payment__summary__column-reverse'>
              <div className='col-md-6 col-xl-5'>
                <PaymentMethod orderId={''} layoutData={dataSource} cartData={cartData} currentCart={currentCart} />
              </div>
              <div className='col-md-6 col-xl-7'>
                <TotalOrder cartData={currentCart} layoutData={dataSource} />
              </div>
            </div>
          </div>
        </div>
      </div>
    
    </>
  );
};

BalanceDetails.propTypes = {
  fields: PropTypes.any
};

export default BalanceDetails;