import './ContactUsIconList.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ContactUsIconListItem from '../ContactUsIconListItem';
import ContactUsIconListFieldsModel from '../Model/ContactUsIconListFieldsModel';

const ContactUsIconList = ({fields}) => {

  const [contactUsIconList, setContactUsIconListFields] = useState(null);

  useEffect(() => {
    const contactUsIconListFieldsModel = new ContactUsIconListFieldsModel();

    setContactUsIconListFields(contactUsIconListFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return contactUsIconList ? (
    <div className='contact-icon-list animated-slow fadeIn delay-100'>
      <div className='container'>
        <div className='contact-icon-list__wrap'>
          <Text field={contactUsIconList.Title} tag='h2' className='contact-icon-list__heading experience-title'/>
          <div className='contact-icon-list__main'>
            <div className='row'>
              {
                contactUsIconList.items.length > 0 ? contactUsIconList.items.map((item, index) => {
                  return (
                    <ContactUsIconListItem data={item} key={index}></ContactUsIconListItem>
                  );
                }): ''
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='contact-icon-list skeleton__item'>
      <div className='container'>
        <div className='contact-icon-list__wrap'>
          <h2 className='contact-icon-list__heading experience-title'>
            <span className='text-line-40-center w-40'></span>
          </h2>
          <div className='contact-icon-list__main'>
            <div className='row'>
              <LoadingShimmer itemNumb={4}>
                <div className='col-md-6 col-xl-4'>
                  <div className='contact-icon-list__item'>
                    <div className='contact-icon-list__item__image'>
                      <div className='image-wrap'>
                        <span className='image-line'></span>
                      </div>
                    </div>
                    <div className='contact-icon-list__item__text'>
                      <span className='text-line'></span>
                      <span className='clear-5'></span>
                      <span className='text-line-20'></span>
                    </div>
                  </div>
                </div>
              </LoadingShimmer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

ContactUsIconList.propTypes = {
  fields: PropTypes.object
};

export default ContactUsIconList;