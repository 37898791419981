import { object } from 'prop-types';
import React, { useCallback, useRef, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import ImagePreviews from './ImagesPreview';
import { TradeInUploadImageProvider } from './TradeInUploadImageProvider';
import UploadImageButton from './UploadImageButton';
import UploadImageErrorMessage from './UploadImageErrorMessage';

const TradingUploadImage = ({ dataFields, formikRefCurrent }) => {
  const imageInputRef = useRef();
  const [selectedImage, setSelectedImage] = useState([]);
  const [fileSizeExceedMaximumLimit, setFileSizeExceedMaximumLimit] = useState(dataFields['File Size Exeed Maximum Limit Label']);

  const handleTestImageType = useCallback((path) => {
    let patt = new RegExp(/(^image\/)*(png|img|jpe?g|gif)/);

    return patt.test(path);
  }, []);

  return (
    <TradeInUploadImageProvider
      values={{
        dataFields,
        formikRefCurrent,
        selectedImage,
        fileSizeExceedMaximumLimit,
        handleTestImageType,
        setSelectedImage
      }}
    >
      <div className='tradein-step-section step-upload-image'>
        <div className='tradein-step-section__title'>
          <div className='tradein-step-section__title__number tradein-step-number'>
            <span className='tradein-step-number__value'>2</span>
          </div>
          <Text tag='h4' className='tradein-step-section__title__label' field={dataFields['Upload Image Of Trade In Product Title']} />
        </div>
        <div className='tradein-step-section__content'>
          <div className='image-selection'>
            <div className='image-selection__wrap'>
              {/*{selectedImage && selectedImage?.size < FILE_SIZE && handleTestImageType(selectedImage?.type) ? <ImagePreviews /> : <></>}*/}
              <ImagePreviews />
              <UploadImageButton ref={imageInputRef} setFileSizeExceedMaximumLimit={setFileSizeExceedMaximumLimit} />
            </div>
            <Text field={dataFields['File Size Requirement Text']} tag='span' className='image-selection__desc' />
          </div>
          <UploadImageErrorMessage />
        </div>
      </div>
    </TradeInUploadImageProvider>
  );
};

TradingUploadImage.propTypes = {
  dataFields: object,
  formikRefCurrent: object
};

export default TradingUploadImage;
