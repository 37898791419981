import produce from 'immer';

import BOFreeGiftsModel from '@components/BuyingOptions/BOFreeGifts/model/BOFreeGiftsModel';
import * as buyingOptionsTypes from '@redux/actions/buyingOptions/buyingOptionsTypes';
import * as productTypes from '@redux/actions/product/productTypes';
import { PROMO_FREEGIFT_TYPE } from '@utils/constant';
import { deepCopy } from '@utils/utility.js';

const initState = {
  freeGiftsList: [],
  variantFreeGifts: [],
  productFreeGifts: [],
  freeGiftsActiveID: [],
  freeGiftsSelectedID: [],
  freeGiftsSelectedLength: 0,
  freeGiftData: [],
  freeGiftDepositSelected: {}
};

function filterFreeGift(data) {
  if (data?.length) {
    let newFreeGiftsList = [];

    let newFilteredOutStockFreeGift = [];

    data.map(element => {
      newFreeGiftsList.push(new BOFreeGiftsModel().getData(deepCopy(element) || {}));
    });

    newFilteredOutStockFreeGift = Array.from(new Set(newFreeGiftsList.filter(item => item.Quantity > 0)));

    const newFilteredFreeGift = Array.from(new Set(newFilteredOutStockFreeGift.map(item => item.Slot))).map(itemSlot => {
      return {
        type: itemSlot,
        items: newFilteredOutStockFreeGift.filter((item) => item.Slot === itemSlot),
      };
    }).sort(function(a, b) {
      return a.type - b.type;
    });

    return newFilteredFreeGift;
  }

  return [];
}

const BOFreeGiftsReducer = (state = initState, action) =>
  produce(state, draft => {

    // let newFreeGiftsList = [];
    let newSelectedFreeGift = {};

    // let initFreeGiftActiveID = [];
    let newFilterActiveFreeGift = [];
    let newFilterSelectedFreeGift = [];

    let filterFreeGiftsFromCartLine = [];

    // let newFilteredFreeGift = [];

    // let newFilteredOutStockFreeGift = [];

    switch (action.type) {
    case buyingOptionsTypes.GET_BO_DATA_SUCCESS:
      draft.productFreeGifts = filterFreeGift(action.payload.ProductSummary?.FreeGifts?.FreeGifts);

      draft.freeGiftsList = filterFreeGift(action.payload.ProductSummary?.FreeGifts?.FreeGifts);

      if (action.payload.ProductSummary?.FreeGifts?.VariantFreeGifts?.length) {
        let variantFreeGiftTmp = [];

        action.payload.ProductSummary.FreeGifts.VariantFreeGifts.map((variantItem) => {
          variantFreeGiftTmp.push({
            variantId: variantItem?.VariantId,
            freeGiftList: filterFreeGift(variantItem?.FreeGifts)
          });
        });

        draft.variantFreeGifts = variantFreeGiftTmp;
      }

      draft.freeGiftData = {
        BalanceFreeGifts: action.payload.ProductSummary?.Deposit?.BalanceFreeGifts,
        DepositFreeGifts: action.payload.ProductSummary?.Deposit?.DepositFreeGifts,
        // INFO: new update from ticket https://jira.smartosc.com/browse/OSIM-6543 => to check deposit product using
        // Deposit !== null
        // IsDeposit: action.payload.ProductSummary?.Deposit?.IsDeposit
        IsDeposit: action.payload.ProductSummary?.Deposit !== null
      };

      // if (action.payload.FreeGifts?.FreeGifts?.length) {

      //   action.payload.FreeGifts.FreeGifts.map(element => {
      //     newFreeGiftsList.push(new BOFreeGiftsModel().getData(deepCopy(element) || {}));
      //   });

      //   newFilteredOutStockFreeGift = Array.from(new Set(newFreeGiftsList.filter(item => item.Status !== 'OutOfStock')));

      //   newFilteredFreeGift = Array.from(new Set(newFilteredOutStockFreeGift.map(item => item.Slot))).map(itemSlot => {
      //     return {
      //       type: itemSlot,
      //       items: newFilteredOutStockFreeGift.filter((item) => item.Slot === itemSlot),
      //     };
      //   }).sort(function(a, b) {
      //     return a.type - b.type;
      //   });

      //   draft.freeGiftsList = deepCopy(newFilteredFreeGift);
      // }

      break;

    case buyingOptionsTypes.ADD_CHOSEN_BO_FREEGIFTS:
      newSelectedFreeGift = {
        Line: action.Line,
        GiftId: action.GiftId,
        ProductId: action.ProductId,
        VariantId: action.VariantId || null,
        VariantName: action.VariantName,
        ItemIdx: action.ProductIdx
      };

      draft.freeGiftsSelectedID = [...draft.freeGiftsSelectedID, newSelectedFreeGift].sort(function (a, b) {
        return a.ItemIdx - b.ItemIdx;
      });

      newFilterActiveFreeGift = draft.freeGiftsActiveID.filter((item) => item.Line !== action.Line);

      draft.freeGiftsActiveID = [...newFilterActiveFreeGift, newSelectedFreeGift].sort(function (a, b) {
        return a.ItemIdx - b.ItemIdx;
      });

      draft.freeGiftsSelectedLength = draft.freeGiftsSelectedID.length;

      break;

    case buyingOptionsTypes.REPLACE_CHOSEN_BO_FREEGIFTS:
      newSelectedFreeGift = {
        Line: action.Line,
        GiftId: action.GiftId,
        ProductId: action.ProductId,
        VariantId: action.VariantId || null,
        VariantName: action.VariantName,
        ItemIdx: action.ProductIdx
      };

      newFilterSelectedFreeGift = draft.freeGiftsSelectedID.filter((item) => item.Line !== action.Line);

      draft.freeGiftsSelectedID = [...newFilterSelectedFreeGift, newSelectedFreeGift].sort(function (a, b) {
        return a.ItemIdx - b.ItemIdx;
      });

      newFilterActiveFreeGift = draft.freeGiftsActiveID.filter((item) => item.Line !== action.Line);

      draft.freeGiftsActiveID = [...newFilterActiveFreeGift, newSelectedFreeGift].sort(function (a, b) {
        return a.ItemIdx - b.ItemIdx;
      });

      draft.freeGiftsSelectedLength = draft.freeGiftsSelectedID.length;

      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:
      if (action.filteredCartLine.length) {
        filterFreeGiftsFromCartLine = action.filteredCartLine[0].PromotionCartLines?.filter((item) => item.Type === PROMO_FREEGIFT_TYPE);

        if (filterFreeGiftsFromCartLine.length) {
          draft.freeGiftsSelectedID = deepCopy(
            filterFreeGiftsFromCartLine.map((item, itemIdx) => {
              return {
                Line: item.Slot,
                GiftId: `${item.Slot + item.ProductId + (item.VariantId || '')}`,
                ProductId: item.ProductId,
                VariantId: item.VariantId || null,
                VariantName: item.DisplayName,
                ItemIdx: itemIdx
              };
            })
          );

          draft.freeGiftsActiveID = draft.freeGiftsSelectedID;

          draft.freeGiftsSelectedLength = draft.freeGiftsSelectedID.length;
        }
      }

      break;

    case buyingOptionsTypes.GET_FREE_GIFT_LIST:
      if (draft.variantFreeGifts.length) {
        draft.freeGiftsActiveID.length = 0;

        draft.freeGiftsSelectedID.length = 0;

        draft.freeGiftsSelectedLength = 0;
        const variantFreeGiftsCopy = deepCopy(draft.variantFreeGifts);
        const variantFreeGift = variantFreeGiftsCopy.find((variantItem) => variantItem.variantId.toLowerCase() === action.payload.toLowerCase());

        draft.freeGiftsList = variantFreeGift ? variantFreeGift.freeGiftList : draft.productFreeGifts;

        break;
      }

      draft.freeGiftsList = draft.productFreeGifts;
      break;

    case buyingOptionsTypes.ADD_DEPOSIT_FREE_GIFT:
      draft.freeGiftDepositSelected = { ...action.payload };
      break;
    }

    return draft;

  });

export default BOFreeGiftsReducer;
