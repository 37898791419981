import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/cpAppointmentDetail';
import * as cpAppointmentDetailTypes from '@redux/actions/cpAppointmentDetail/cpAppointmentDetailTypes';
import { getAsObservable, postAsObservable } from '@services/genericService';

import * as config from '../config';

const cpAppointmentDetailEpic = (action$) =>
  action$.pipe(
    ofType(cpAppointmentDetailTypes.CP_GET_APPOINTMENT_DETAIL),
    switchMap((action) => {
      return postAsObservable(config.ACTION_CP_GET_APPOINTMENT_DETAIL, action.payload).pipe(
        switchMap((res) => {
          if (res.data.StatusCode == 200) {
            const appointmentData = res.data?.AppointmentItemDetail;

            if (appointmentData?.IsBookExperience) {
              
              return getAsObservable(config.ACTION_CP_GET_STORE_LIST).pipe(
                map((response) => {

                  if (response.data?.StatusCode == 200) {
                    const storeList = response.data?.Stores;
                    const storeAppointment = storeList.find((store) => store?.ItemId.replace(/{|}/g, '').toLowerCase() === appointmentData?.StoreId);

                    return actions.getCpAppointmentDetailSuccess({appointment: appointmentData, storeAppointment: storeAppointment});
                  } else {
                    return actions.getCpAppointmentDetailFailed('Failed');
                  }

                })
              );
            } else {
              return of(actions.getCpAppointmentDetailSuccess({appointment: appointmentData}));
            }
          } else {
            return of(actions.getCpAppointmentDetailFailed('Failed'));
          }
        })
      );
    })
  );

export default cpAppointmentDetailEpic;
