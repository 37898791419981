import SitecoreLinkModel from '@models/SitecoreLinkModel';
import TextModel from '@models/TextModel';

export default class BuyNowLinkModel {
  constructor() {
    this.Link = new SitecoreLinkModel();

    this.Title = new TextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this['Link'].getData(dataSource['Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}
