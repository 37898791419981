import produce from 'immer';

import { GET_CONTACT_FAILURE, GET_CONTACT_START, GET_CONTACT_SUCCESS } from '@redux/actions/campaignRegister/getContact/types';

const initialStates = {
  isLoading: false,
  error: null,
  contactData: null
};

const getContactReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_CONTACT_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case GET_CONTACT_SUCCESS:
      draft.isLoading = false;

      draft.contactData = action.payload;
      break;

    case GET_CONTACT_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getContactReducer;
