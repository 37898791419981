import { useCallback } from 'react';

import { LIST_BENEFIT_KEY, LIST_PRODUCT_FUNCTION, PRODUCT_SUMMARY_KEY } from '../ProductFunctionsDetail';
import useRemoveDuplicateFunction from './useRemoveDuplicateFunction';

const useSortGeneralProductFunction = () => {
  const handleRemoveDuplicateFunction = useRemoveDuplicateFunction();

  const handleMergeGeneralProductFunction = useCallback((compareData) => {
    const generalProductFunctionMerged = {};
    for (const compareDataItem of compareData) {
      const productInfoDetail = compareDataItem?.productInfoDetail;
      if (productInfoDetail) {
        for (const key of Object.keys(productInfoDetail)) {
          if (key !== PRODUCT_SUMMARY_KEY && key !== LIST_PRODUCT_FUNCTION && key !== LIST_BENEFIT_KEY) {
            if (!generalProductFunctionMerged?.[key]) {
              generalProductFunctionMerged[key] = [...productInfoDetail[key]];
            } else {
              generalProductFunctionMerged[key] = [...generalProductFunctionMerged[key], ...productInfoDetail[key]];
            }
          }
        }
      }
    }

    return generalProductFunctionMerged;
  }, []);

  const handleGenerateUniqueGeneralFunction = useCallback((compareData) => {
    const mergedGeneralFunctionList = handleMergeGeneralProductFunction(compareData);
    for (const key of Object.keys(mergedGeneralFunctionList)) {
      mergedGeneralFunctionList[key] = [...handleRemoveDuplicateFunction(mergedGeneralFunctionList[key])];
    }

    return mergedGeneralFunctionList;
  }, []);

  return useCallback((compareData, productFunction, functionKey) => {
    const functionSortedData = [];
    const uniqueGeneralFunction = handleGenerateUniqueGeneralFunction(compareData);

    if (productFunction?.length) {
      for (const productFunctionItem of productFunction) {
        const functionUnique = uniqueGeneralFunction[functionKey];
        const indexOfFunctionItem = functionUnique?.findIndex((item) => item.Id === productFunctionItem.Id);

        functionSortedData[indexOfFunctionItem] = { ...productFunctionItem };
      }
    }

    return functionSortedData;
  }, []);
};

export default useSortGeneralProductFunction;
