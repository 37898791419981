import './CarouselDetailSlide.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image,Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CarouselDetailSlideModel from '../Models/CarouselDetailSlideModel';

const CarouselDetailSlide = ({article}) => {
  const [carouselSlideFields, setCarouselSlideFields] = useState(null);

  useEffect(() => {
    const carouselSlideModel = new CarouselDetailSlideModel();

    setCarouselSlideFields(carouselSlideModel.getData(deepCopy(article.fields)));
  }, []);

  return carouselSlideFields && (
    <div className='carousel-detail-slide'>
      <a href={article.url} className='carousel-detail-slide__image'>
        <Image media={carouselSlideFields['Image']}/>
        {
          carouselSlideFields['Is Video'].value ? (
            <div className='video__contents'>
              <div className='video__contents__btn-play'>
                <div className='btn-play-icon'>
                  <span className='play-icon'></span>
                </div>
              </div>
            </div>
          ) : ''
        }
      </a>
      <div className='carousel-detail-slide__contents'>
        <a href={article.url}>
          <Text tag='h2' field={carouselSlideFields.Title} className='carousel-detail-slide__title' />
        </a>
        <small className='carousel-detail-slide__text'>
          {
            carouselSlideFields['Is Video'].value ? '' : (
              <Text field={carouselSlideFields['Time To Read']} tag='span'/>
            )
          }
          <Text field={carouselSlideFields.PublishedDate} tag='span'/>
        </small>
      </div>
    </div>
  );
};

CarouselDetailSlide.propTypes = {
  article: PropTypes.object
};

export default CarouselDetailSlide;
