import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getGiftingOption } from '@redux/actions/product';

import CartEmty from '../../../SlicingPage/CartDetails/CartEmty';
import CartItem from '../../../SlicingPage/CartDetails/CartList/CartItem';
import { useOfferDetails } from '../OfferDetailsProvider';

const CartDetailsSection = () => {
  const { offerDetailFields, osimCartData } = useOfferDetails();
  const dispatch = useDispatch();
  const giftingOptions = useSelector((state) => state.productReducer.cartDetailReducer.giftingOptions);

  useEffect(() => {
    dispatch(getGiftingOption());
  }, []);

  const cartItemData = (cartItem) => {
    const giftingOption = giftingOptions?.GiftingOptions.find((gift) => gift.MainItemId === cartItem.ExternalCartLineId);

    return giftingOption ? { ...cartItem, giftingOption } : cartItem;
  };

  return (
    <div className='cart-list offer-details__listing'>
      {osimCartData?.Lines?.length ? (
        osimCartData.Lines.map((cartItem) => (
          <div key={cartItem.ExternalCartLineId} className='cart-list__item'>
            <CartItem
              isUseForSOCart={true}
              dataSources={offerDetailFields}
              deliveryOptions={osimCartData?.DeliveryOptions}
              currencyInformation={giftingOptions?.CurrencyInformation}
              cartItemInfo={cartItemData(cartItem)}
              lineId={cartItem?.ExternalCartLineId}
            />
          </div>
        ))
      ) : (
        <CartEmty onDataSources={offerDetailFields} />
      )}
    </div>
  );
};

export default CartDetailsSection;
