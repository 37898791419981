export const GET_TOTAL_BOOKED_TIMESLOT_OF_DATE = 'GET_TOTAL_BOOKED_TIMESLOT_OF_DATE';

export const GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_SUCCESS = 'GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_SUCCESS';

export const GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_FAILED = 'GET_TOTAL_BOOKED_TIMESLOT_OF_DATE_FAILED';

export const DELETE_RESULT_TOTAL_TIMESLOT_OF_DATE = 'DELETE_RESULT_TOTAL_TIMESLOT_OF_DATE';

export const GET_TOTAL_BOOKED_DATE_OF_STORE = 'GET_TOTAL_BOOKED_DATE_OF_STORE';

export const GET_TOTAL_BOOKED_DATE_OF_STORE_SUCCESS = 'GET_TOTAL_BOOKED_DATE_OF_STORE_SUCCESS';

export const GET_TOTAL_BOOKED_DATE_OF_STORE_FAILED = 'GET_TOTAL_BOOKED_DATE_OF_STORE_FAILED';