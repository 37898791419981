import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import global from '@utils/global';

import WarrantyStatus from './models/WarrantyStatus';

export const useProductWarrantyStatusData = (layoutData) => {
  const { productDetails } = useSelector((state) => state.productWarrantyReducer.getUserProductDetails);

  const expiredDate = useMemo(() => {
    const expiredDateConverted = new Date(Number.parseInt(productDetails?.Warranty?.ExpiredDate?.replace(/Date|\(|\/|\)/gi, '')));

    return expiredDateConverted ? global.customDate(expiredDateConverted, false) : '';
  }, [productDetails]);

  const warrantyStatus = useMemo(() => {
    const pendingMessage = layoutData?.['Pending Message'];
    const rejectMessage = layoutData?.['Reject Message'];
    const approvedMessage = layoutData?.['Approved Message'];
    const expiredMessage = layoutData?.['Expired Message'];

    return {
      [WarrantyStatus.PENDING_STATUS]: pendingMessage,
      [WarrantyStatus.REJECT_STATUS]: rejectMessage,
      [WarrantyStatus.APPROVED_STATUS]: { value: approvedMessage?.value?.replace('{expiring-date}', expiredDate) },
      [WarrantyStatus.EXPIRED_STATUS]: { value: expiredMessage?.value?.replace('{expiring-date}', expiredDate) }
    };
  }, [layoutData, expiredDate]);

  const productHasWarranty = useMemo(() => {
    return productDetails?.HasWarranty;
  }, [productDetails]);

  return useMemo(() => {
    let content = {
      statusText: null,
      buttonData: {
        text: null,
        link: null
      }
    };
    const productWarrantyData = productDetails?.Warranty;

    if (!productHasWarranty) {
      content = { ...content, statusText: { ...layoutData?.['Not Carry Warranty Message'] } };
    }
    if (!productWarrantyData) {
      content = {
        ...content,
        statusText: { ...layoutData?.['Not Register Message'] },
        buttonData: {
          text: { ...layoutData?.['Register Warranty Button Label'] },
          link: { ...layoutData?.['Register Warranty Button Link'] }
        }
      };
    }
    if (productWarrantyData) {
      const productWarrantyStatus = productWarrantyData?.Status;

      content = {
        ...content,
        statusText: { ...warrantyStatus[productWarrantyStatus] }
      };
      switch (productWarrantyStatus) {
      case WarrantyStatus.PENDING_STATUS:
        content = {
          ...content,
          buttonData: {
            text: null,
            link: null
          }
        };
        break;

      case WarrantyStatus.REJECT_STATUS:
        content = {
          ...content,
          buttonData: {
            text: { ...layoutData?.['Contact Button Label'] },
            link: { ...layoutData?.['Contact Button Link'] }
          }
        };
        break;
      default: // INFO: in case approved and rejected
        content = {
          ...content,
          buttonData: {
            text: { ...layoutData?.['Extend Button Warranty Label'] },
            link: { ...layoutData?.['Extend Button Warranty Link'] }
          }
        };
      }
    }

    return content;
  }, [productHasWarranty, productDetails, layoutData, warrantyStatus]);
};
