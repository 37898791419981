import produce from 'immer';

// Import action types
import * as productTypes from '@redux/actions/product/productTypes';

const initState = {
  storesList: null,
  storeSelected: null,
  isLoading: false,
  error: ''
};

const getStoreListReducer = (state = initState, action) =>
  produce(state, draft => {
    switch (action.type) {
    case productTypes.GET_STORE_LIST:
      draft.isLoading = true;
      break;

    case productTypes.GET_STORE_LIST_SUCCESS:
      draft.isLoading = false;

      draft.storesList = action.payload.data.Stores;
      if (draft.storeSelected && action.payload.data.Stores?.length) {
        draft.storesList.forEach(storesItem => {
          if (storesItem.Latitude === draft.storeSelected.Latitude && storesItem.Longitude === draft.storeSelected.Longitude) {
            storesItem.isSelected = true;
          }
        });
      } 
      break;

    case productTypes.GET_STORE_LIST_FAILED:
      draft.isLoading = false;

      draft.error = action.error;
      break;

    case productTypes.SET_SELECTED_STORE:
      draft.storesList.forEach((storeItem, storeItemIdx) => {
        storeItem.isSelected = storeItemIdx === action.payload  ? true : false;
      });

      draft.storeSelected = draft.storesList.find((storeItem) => storeItem.isSelected);
      break;
    }

    return draft;
  });

export default getStoreListReducer;
