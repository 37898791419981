export default class SitecoreTextModel {
  constructor() {
    this.value = '';

    this.editable = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this.value = dataSource.value ?? this.value;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'editable')) {
      this.editable = dataSource.editable ?? this.editable;
    }
    
    return this;
  }
}
