import 'swiper/css/swiper.css';
import './style.scss';

import PropTypes from 'prop-types';
import React from 'react';
import Swiper from 'react-id-swiper';

// eslint-disable-next-line no-unused-vars
const SwipeComponent = ({ setRef, activeSlideKey, ...props }) => {
  let params = {
    lazy: true,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 15,
    pagination: {
      el: '.swiper-pagination',
      type: 'progressbar',
      clickable: true
    }
  };
  if (props.param) {
    params = props.param;
  }

  return (
    <Swiper {...props} {...params} ref={setRef}>
      {props.children}
    </Swiper>
  );
};

SwipeComponent.propTypes = {
  data: PropTypes.any,
  children: PropTypes.any,
  param: PropTypes.any,
  setRef: PropTypes.any,
  activeSlideKey: PropTypes.string
};

export default SwipeComponent;
