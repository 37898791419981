const strArr = [
  'alternativeStartText',
  'bannerImageSrc',
  'description',
  'endDate',
  'endText',
  'remainingDate',
  'startDate',
  'startText',
  'thumbnail',
  'title',
  'url',
  'validMessage',
  'validText',
  'wasEndText',
  'creativeName'
];

export default class FieldsModel {
  constructor() {
    strArr.forEach((item) => {
      this[item] = '';
    });
  }

  getData(dataSource) {
    strArr.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}
