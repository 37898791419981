import { bool, func, object } from 'prop-types';
import React, { Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import CPInput from '@components/ClientPortal/GenericComponents/CPForm/CPInput';
import CPSelect from '@components/ClientPortal/GenericComponents/CPForm/CPSelect';
import { getUserFullNameWithoutFormat } from '@components/SlicingPage/MyAccount/utils';
import {
  getContactPersonalInformation,
  revertCPCustomer,
  storeCPCustomer,
  updateCPCustomerContact
} from '@redux/actions/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { DEFAULT_API_VALUE } from '@utils/constant';
import { HK_SITE_NAME, TW_SITE_NAME } from '@utils/constant';
import { useFormatEnDate } from '@utils/customsHook/useFormatDate';
import { convertHKTWDate } from '@utils/customsHook/useFormatDate/utils';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { TYPE_CHECKBOX, TYPE_RADIO } from '../../../GenericComponents/CPForm/FormConstant';
import { useMappingCustomerInfoData } from '../../CustomerAcknowledgePopup/hooks';
import { getAnswerSelectedDisplayName, useGenerateAnswerValues } from '../utils';

const ExistingCustomerLifestyle = ({ layoutData, data, editCustomerLifestyle, onEditCustomerLifestyle }) => {
  const mappingCustomerInfoData = useMappingCustomerInfoData();
  const [toggleResetDropdownQuestion, setToggleResetDropdownQuestion] = useState(false);
  const [dirtyMassageOwnerShip, setDirtyMassageOwnnerShip] = useState(false);
  const [selectedMassageOwnerShip, setSelectedMassageOwnerShip] = useState([]);
  const [massageOwnershipValue, setMassageOwnershipValue] = useState('');
  const [massageOwnerShip, setMassageOwnerShip] = useState(false);
  const clientTellingPortalQuickAccess = useSelector((state) => state.clientTellingPortalQuickAccess);
  const clientTellingPortalCustomerStates = useSelector((state) => state.clientTellingPortalCustomer);
  const clientTellingPortalCustomer = useMemo(() => mappingCustomerInfoData(clientTellingPortalCustomerStates), [clientTellingPortalCustomerStates]);
  const ReferPOS = clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.ReferPOS;
  const isSyncFromPos = clientTellingPortalCustomer?.contacts?.[0]?.isSyncFromPos;
  // const dirtyCustomerLifestyle = useSelector((state) => state.singleSignOnReducer.userInfoReducer.dirtyCustomerLifestyle);
  const dispatch = useDispatch();
  const [phoneNumberDisplay, setPhoneNumberDisplay] = useState(false);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const currentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
  const formatEnDate = useFormatEnDate();
  const generateAnswerValuesService = useGenerateAnswerValues();

  const isConvertDate = useMemo(() => {
    return (currentSiteName === HK_SITE_NAME && currentLanguage === 'zh-HK') || currentSiteName === TW_SITE_NAME;
  }, [currentSiteName, currentLanguage]);

  const isSMSCommunicationHidden = useMemo(() => {
    return layoutData?.['Is Communication Method Hidden']?.value ?? false;
  }, [layoutData]);

  const generateDropdownOption = (data) => {
    let arr = data.map((item) => {
      return {
        value: item.fields.Answer.value,
        label: item.fields.Answer.value
      };
    });

    return arr;
  };

  const onChangeSelect = (data) => {
    if (editCustomerLifestyle) {
      if (data.questionType == 'CPCustomerInfo-Single Select' && data.questionCode == 'MassageOwnershipType') {
        setDirtyMassageOwnnerShip(true);

        data.displayName?.toLowerCase() === 'no' ? setMassageOwnerShip(false) : setMassageOwnerShip(true);

        setSelectedMassageOwnerShip([]);
      }

      dispatch(
        storeCPCustomer({
          key: data.questionCode,
          value: data.value,
          type: data.questionType,
          answerId: data.answerId
        })
      );
    }
  };

  const generateDefaultSelectedOption = (data) => {
    return { value: data, label: data };
  };

  const onSelectMassageOwnership = (option) => {
    setSelectedMassageOwnerShip(option);

    setMassageOwnershipValue(option?.map((item) => item.value).join(', ') || ' ');

    const selectedMultiOptionConvert = Global.onGetSelectedMultiOption(option || [], selectedMassageOwnerShip);

    dispatch(
      storeCPCustomer({
        key: 'MassageOwnership',
        value: selectedMultiOptionConvert?.value,
        type: 'CPCustomerInfo-Multi Select',
        answerId: selectedMultiOptionConvert?.answerId
      })
    );
  };

  const onUpdateCustomerLifestyle = () => {
    let formData = new window.FormData();

    const params = {
      ContactID: clientTellingPortalCustomer.tmpCustomerInformation?.ContactID,
      // Email: ' ',
      // PhoneNumber: ' ',
      // PrefixPhoneNumber: clientTellingPortalCustomer.tmpCustomerInformation?.PrefixPhoneNumber,
      // DOB: ' ',
      PersonalInformation: {
        // FirstName: clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.FirstName,
        // LastName: clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.LastName,
        Gender: clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.Gender || DEFAULT_API_VALUE
      },
      CPCustomerInfo: {
        MaritalStatus: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MaritalStatus || DEFAULT_API_VALUE,
        MaritalStatusId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MaritalStatusId || DEFAULT_API_VALUE,
        // Age: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.FirstName?.Age || ' ',
        JobNature: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.JobNature || DEFAULT_API_VALUE,
        JobNatureId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.JobNatureId || DEFAULT_API_VALUE,
        HealthIssues: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HealthIssues || DEFAULT_API_VALUE,
        HealthIssuesId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HealthIssuesId || DEFAULT_API_VALUE,
        HouseholdSize: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HouseholdSize || DEFAULT_API_VALUE,
        HouseholdSizeId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.HouseholdSizeId || DEFAULT_API_VALUE,
        MassageOwnershipType: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipType || DEFAULT_API_VALUE,
        MassageOwnershipTypeId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipTypeId || DEFAULT_API_VALUE,
        MassageOwnership: massageOwnershipValue || DEFAULT_API_VALUE,
        MassageOwnershipId: massageOwnershipValue.length ? clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipId || DEFAULT_API_VALUE : DEFAULT_API_VALUE,
        // AllowDirectMail: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.AllowDirectMail || false,
        // AllowSms: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.AllowSms || false,
        // AllowEmail: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.AllowEmail || false,
        // PDPAAgreement: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.PDPAAgreement || false,
        // Gender: clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.Gender,
        // SignatureImageUrl: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.SignatureImageUrl || '',
        ReferPOS: clientTellingPortalCustomer.customerInformation?.CPCustomerInfo?.ReferPOS || DEFAULT_API_VALUE,
        GenderId:
          clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.GenderId ||
          clientTellingPortalCustomer.tmpCustomerInformation?.PersonalInformation?.GenderId ||
          DEFAULT_API_VALUE
      }
      // CPCustomerInterest: {
      //   Products: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInterest?.Products || ' '
      // }
    };

    for (let keyParams in params) {
      if (keyParams == 'PersonalInformation') {
        for (let keyChild in params[keyParams]) {
          formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
        }
      } else if (keyParams == 'CPCustomerInfo') {
        for (let keyChild in params[keyParams]) {
          formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
        }
      } else if (keyParams == 'CPCustomerInterest') {
        for (let keyChild in params[keyParams]) {
          params[keyParams][keyChild].forEach((item, index) => {
            for (let keyItem in item) {
              if (keyItem == 'Questions') {
                params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
                  for (let keyChildItem in childItem) {
                    formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`, childItem[keyChildItem]);
                  }
                });
              } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
            }
          });
        }
      } else {
        formData.append(keyParams, params[keyParams]);
      }
    }

    dispatch(updateCPCustomerContact(formData));

    onEditCustomerLifestyle();
  };

  // useEffect(() => {
  //   if (clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnership != 'null') {
  //     setSelectedMassageOwnerShip(
  //        clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnership?.length
  //          ? clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnership?.split(', ').map((item) => {
  //            return {
  //              value: item,
  //              label: item
  //            };
  //          })
  //          : []
  //     );

  //     setDirtyMassageOwnnerShip(true);
  //   }
  // }, []);

  useEffect(() => {
    const answerValueSelected = clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipType || '';

    const answerValueSelectedDisplayName = getAnswerSelectedDisplayName({
      answerValueSelected,
      questionsList: layoutData?.Questions || []
    });
    if (answerValueSelectedDisplayName === 'no') {
      setMassageOwnerShip(false);

      setDirtyMassageOwnnerShip(true);
    } else if (answerValueSelected?.length) {
      setMassageOwnerShip(true);

      setDirtyMassageOwnnerShip(true);

      const massageOwnershipValue = clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnership;
      if (massageOwnershipValue && massageOwnershipValue.length) {
        const splitMassageOwnerShipValue = massageOwnershipValue?.split(', ') || [];
        if (splitMassageOwnerShipValue.length) {
          let selectedMassageOwnerShipTemp = [];
          for (const [index, item] of splitMassageOwnerShipValue.filter((item) => item !== ' ').entries()) {
            selectedMassageOwnerShipTemp.push({
              value: item,
              label: item,
              answerId: clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.MassageOwnershipId?.split(', ')?.[index] || ''
            });
          }

          setSelectedMassageOwnerShip(selectedMassageOwnerShipTemp);
        }
      }
    }
  }, [clientTellingPortalCustomer]);

  const getContactPersonal = (e, type) => {
    if (e.type == 'mousedown') {
      !clientTellingPortalCustomer[`${type}`] &&
        dispatch(
          getContactPersonalInformation({
            id: clientTellingPortalCustomer.customerInformation?.ContactID,
            type
          })
        );

      setPhoneNumberDisplay(true);
    } else {
      setPhoneNumberDisplay(false);
    }
  };

  const onRemoveMassageOwnerTypeBtnClick = (item) => {
    let tempObj = deepCopy(selectedMassageOwnerShip);

    const indexOfItem = tempObj.findIndex((val) => val.value === item.value);

    tempObj.splice(indexOfItem, 1);

    setSelectedMassageOwnerShip(tempObj);

    setMassageOwnershipValue(tempObj?.map((item) => item.value).join(', ') || ' ');

    dispatch(
      storeCPCustomer({
        key: 'MassageOwnership',
        value: tempObj?.map((item) => item.value).join(', ') || ' ',
        type: 'CPCustomerInfo-Multi RemoveMassageOwner'
      })
    );
  };

  const dobText = useMemo(() => {
    let value = 'N/A';
    const rawDob = data?.DOB;
    if (rawDob) {
      value = isConvertDate
        ? convertHKTWDate({
          dateString: rawDob,
          isHideYear: true
        })
        : formatEnDate({
          date: rawDob,
          isHideYear: true
        });
    }

    return value;
  }, [data, isConvertDate]);

  const generateAnswerValues = useCallback(
    (questionCode) => {
      const questionsList = layoutData?.Questions;

      return generateAnswerValuesService(questionCode, questionsList);
    },
    [layoutData, clientTellingPortalCustomer]
  );

  const selectedOwnerTypeValuesList = useMemo(
    () => [...selectedMassageOwnerShip].sort((a, b) => a.label - b.label).reverse(),
    [selectedMassageOwnerShip]
  );

  const accountFullName = useMemo(() => {
    const firstName = data?.PersonalInformation?.FirstName || '';
    const lastName = data?.PersonalInformation?.LastName || '';

    return getUserFullNameWithoutFormat({ firstName, lastName });
  }, [data]);

  return (
    <div className='customer-info__form cp-form cp-form--existing-cus'>
      <h4 className='customer-info__form__cus-name'>
        {accountFullName
          ? accountFullName
          : clientTellingPortalCustomer?.contacts?.[0]?.contact_type !== 'PDPA lead' && 'Anonymous'}
      </h4>
      <div className='cp-form__wrap'>
        <div className='cp-form__left'>
          <div className='form-group d-flex'>
            <div className='cp-form__icon-left'>
              <Image field={layoutData['Mail Icon']} />
            </div>
            <span className='cp-form__value-text'>{data.Email || 'N/A'}</span>
          </div>
        </div>
        <div className='cp-form__right'>
          <div className='form-group d-flex'>
            <div className='cp-form__icon-left'>
              <Image field={layoutData['Phone Icon']} />
            </div>
            <div className='cp-form__group d-flex'>
              {data?.PhoneNumber?.length ? (
                <>
                  <span className='cp-form__value-text phone-value'>
                    {data?.PrefixPhoneNumber}
                    {clientTellingPortalCustomer?.PhoneNumber && phoneNumberDisplay ? clientTellingPortalCustomer?.PhoneNumber : data?.PhoneNumber}
                  </span>
                  <span
                    className='cp-icon cp-icon--eye'
                    onMouseDown={(e) => getContactPersonal(e, 'PhoneNumber')}
                    onMouseUp={(e) => getContactPersonal(e, 'PhoneNumber')}
                  />
                </>
              ) : (
                <span className='cp-form__value-text phone-value'>N/A</span>
              )}
            </div>
          </div>
          <div className='form-group form-group--mb-30 d-flex d-flex--space-between'>
            <ul className='cp-form__social-icon'>
              <li className='cp-form__social-icon__item cp-checkbox-icon'>
                <label className={data?.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWeChat && 'cp-checkbox-icon__icon is-checked'}>
                  <Image field={layoutData['Message Button Icon']} />
                </label>
              </li>
              <li className='cp-form__social-icon__item cp-checkbox-icon'>
                <label className={data?.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowWhatsapp && 'cp-checkbox-icon__icon is-checked'}>
                  <Image field={layoutData['Dial Button Icon']} />
                </label>
              </li>
              {!isSMSCommunicationHidden ? (
                <li className='cp-form__social-icon__item cp-checkbox-icon'>
                  <label className={data?.CPCustomerInfo?.CommunicationChannels?.[0]?.AllowSMS && 'cp-checkbox-icon__icon is-checked'}>
                    <Image field={layoutData['SMS Icon']} />
                  </label>
                </li>
              ) : (
                <></>
              )}
            </ul>
          </div>
        </div>
      </div>
      <div className='cp-form__wrap'>
        <div className='cp-form__left'>
          <div className='form-group d-flex'>
            <div className='cp-form__icon-left'>
              <Image field={layoutData['Birthday Icon']} />
            </div>
            <div className='cp-form__group'>
              <span className='cp-form__value-text'>
                {/*{data?.DOB ? (['0001'].every((item) => data?.DOB.includes(item)) ? data?.DOB.replace('0001', '') : data?.DOB) : 'N/A'}*/}
                {dobText}
              </span>
            </div>
          </div>
          {layoutData.Questions.map((q, iq) => {
            return (
              <Fragment key={iq}>
                {iq % 2 == 0 ? (
                  <>
                    <div className='form-group d-flex'>
                      <div className='cp-form__icon-left'>
                        <Image field={q.Image} />
                      </div>
                      <div className='cp-form__group'>
                        {q['Display Type'] == 'Drop Down' ? (
                          <>
                            {editCustomerLifestyle ? (
                              <CPSelect
                                selectId='selectId'
                                onChangeSelect={onChangeSelect}
                                options={generateDropdownOption(q.Answers)}
                                selectPlaceholder={q?.['Question'] || 'Select HouseholdSize'}
                                questionCode={q['Question Code']}
                                questionType={q['Display Type']}
                                isDisabled={editCustomerLifestyle ? false : true}
                                defaultValue={generateDefaultSelectedOption(
                                  clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}`]
                                )}
                                toggleResetDropdownQuestion={toggleResetDropdownQuestion}
                              />
                            ) : (
                              <span key={iq} className='cp-form__value-text'>
                                {clientTellingPortalCustomer?.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}`]}
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {editCustomerLifestyle ? (
                              q['Question Code'] == 'Gender' && ReferPOS ? (
                                <span key={iq} className='cp-form__value-text'>
                                  N/A
                                </span>
                              ) : (
                                <>
                                  {q.Answers.map((a, ia) => {
                                    return (
                                      <div className='cp-form__group__item' key={ia}>
                                        {q['Question Code'] == 'Gender'}
                                        <CPInput
                                          inputName={q['Display Type'] == 'Single Select' ? `input-radio-${q.Question}` : `input-radio-${a.id}`}
                                          isRectangle={true}
                                          inputType={
                                            q['Display Type'] == 'Single Select' ? TYPE_RADIO : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                          }
                                          inputId={`input-radio-${a.id}`}
                                          labelData={a.fields.Answer.value}
                                          layoutServiceData={a.fields}
                                          inputChange={onChangeSelect}
                                          questionCode={q['Question Code']}
                                          questionType={q['Display Type']}
                                          isChecked={
                                            q['Question Code'] == 'Gender'
                                              ? clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}Id`]
                                                ?.split(', ')
                                                .includes(a.id)
                                                ? true
                                                : false
                                              : clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}Id`]
                                                ?.split(', ')
                                                .includes(a.id)
                                                ? true
                                                : false
                                          }
                                          displayName={a?.displayName}
                                          answerId={a?.id}
                                        // isDisabled={editCustomerLifestyle ? false : true}
                                        />
                                      </div>
                                    );
                                  })}
                                </>
                              )
                            ) : (
                              // <span key={iq} className='cp-form__value-text'>
                              //   {q['Question Code'] == 'Gender'
                              //     ? clientTellingPortalCustomer?.customerInformation?.PersonalInformation?.Gender
                              //       ? clientTellingPortalCustomer?.customerInformation?.PersonalInformation?.Gender
                              //       : 'N/A'
                              //     : clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.[`${q?.['Question Code']}`]
                              //       ? clientTellingPortalCustomer?.customerInformation?.CPCustomerInfo?.[`${q?.['Question Code']}`]
                              //       : 'N/A'}
                              // </span>
                              <span key={iq} className='cp-form__value-text'>
                                {generateAnswerValues(q['Question Code'])}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {q['Question Code'] == 'MassageOwnershipType' && dirtyMassageOwnerShip ? (
                      <div className='form-group d-flex'>
                        <div className='cp-form__icon-left'></div>
                        <div className='cp-form__group'>
                          {editCustomerLifestyle ? (
                            // <Select
                            //   options={layoutData['Massage Ownership Types'].map((item) => {
                            //     return {
                            //       value: item.fields.Value.value,
                            //       label: item.fields.Value.value
                            //     };
                            //   })}
                            //   className='cp-select'
                            //   classNamePrefix='cp-select'
                            //   value={selectedMassageOwnerShip}
                            //   onChange={(option) => onSelectMassageOwnership(option)}
                            //   isSearchable={false}
                            //   isMulti
                            //   isDisabled={editCustomerLifestyle && massageOwnerShip ? false : true}
                            // />
                            <>
                              <Select
                                options={layoutData['Massage Ownership Types'].map((item) => {
                                  return {
                                    value: item.fields.Value.value,
                                    label: item.fields.Value.value,
                                    answerId: item.id
                                  };
                                })}
                                className='cp-select cp-select__customize'
                                classNamePrefix='cp-select'
                                value={selectedOwnerTypeValuesList}
                                onChange={onSelectMassageOwnership}
                                isSearchable={true}
                                isMulti
                                isDisabled={editCustomerLifestyle && massageOwnerShip ? false : true}
                                placeholder={q?.['Question'] || 'Select Massage Ownership'}
                              />
                              <div className='cp-select__customize-selected__item'>
                                {selectedOwnerTypeValuesList?.length > 0 &&
                                  // selectedMassageOwnerShip.sort((a, b) => a.label - b.label).reverse().map((val, index) => (
                                  selectedOwnerTypeValuesList.map((val, index) => (
                                    <div key={index} className='item'>
                                      <p className='item-text'>{val.label}</p>
                                      <div className='item-remove__icon icon-close' onClick={() => onRemoveMassageOwnerTypeBtnClick(val)}></div>
                                    </div>
                                  ))}
                              </div>
                            </>
                          ) : (
                            <>
                              {selectedMassageOwnerShip.length !== 0 && (
                                // <span>({selectedMassageOwnerShip ? selectedMassageOwnerShip.sort((a, b) => a.value - b.value).reverse().map((item) => item.value).join(',') : 'N/A'})</span>
                                <span>({selectedOwnerTypeValuesList.length ? selectedOwnerTypeValuesList.map((item) => item.value).join(',') : 'N/A'})</span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Fragment key={iq} />
                )}
              </Fragment>
            );
          })}
          {/* <div className='form-group d-flex'>
            <div className='cp-form__icon-left'></div>
            <div className='cp-form__group'>
              <CPSelect selectId='selectId' onChangeSelect={onChangeSelect} options={options} selectPlaceholder='Massage chair' />
            </div>
          </div> */}
        </div>
        <div className='cp-form__right'>
          {layoutData.Questions.map((q, iq) => {
            return (
              <Fragment key={iq}>
                {iq % 2 != 0 ? (
                  <>
                    <div className='form-group d-flex'>
                      <div className='cp-form__icon-left'>
                        <Image field={q.Image} />
                      </div>
                      <div className='cp-form__group'>
                        {q['Display Type'] == 'Drop Down' ? (
                          <>
                            {editCustomerLifestyle ? (
                              <CPSelect
                                selectId='selectId'
                                onChangeSelect={onChangeSelect}
                                options={generateDropdownOption(q.Answers)}
                                selectPlaceholder={q?.['Question'] || 'Select HouseholdSize'}
                                questionCode={q['Question Code']}
                                questionType={q['Display Type']}
                                isDisabled={editCustomerLifestyle ? false : true}
                                defaultValue={generateDefaultSelectedOption(
                                  clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}`]
                                )}
                                toggleResetDropdownQuestion={toggleResetDropdownQuestion}
                              />
                            ) : (
                              <span key={iq} className='cp-form__value-text'>
                                {clientTellingPortalCustomer?.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}`]
                                  ? clientTellingPortalCustomer?.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}`]
                                  : 'N/A'}
                              </span>
                            )}
                          </>
                        ) : (
                          <>
                            {editCustomerLifestyle ? (
                              q['Question Code'] == 'Gender' && ReferPOS ? (
                                <span key={iq} className='cp-form__value-text'>
                                  N/A
                                </span>
                              ) : (
                                <>
                                  {q.Answers.map((a, ia) => {
                                    return (
                                      <div className='cp-form__group__item' key={ia}>
                                        <CPInput
                                          inputName={q['Display Type'] == 'Single Select' ? `input-radio-${q.Question}` : `input-radio-${a.id}`}
                                          isRectangle={true}
                                          inputType={
                                            q['Display Type'] == 'Single Select' ? TYPE_RADIO : q['Display Type'] == 'Multi Select' && TYPE_CHECKBOX
                                          }
                                          inputId={`input-radio-${a.id}`}
                                          labelData={a.fields.Answer.value}
                                          layoutServiceData={a.fields}
                                          inputChange={onChangeSelect}
                                          questionCode={q['Question Code']}
                                          questionType={q['Display Type']}
                                          isDisabled={q['Question Code'] == 'Gender' ? isSyncFromPos || ReferPOS : false}
                                          isChecked={
                                            q['Question Code'] == 'Gender'
                                              ? clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}Id`]
                                                ?.split(', ')
                                                .includes(a.id)
                                                ? true
                                                : false
                                              : clientTellingPortalCustomer.tmpCustomerInformation?.CPCustomerInfo?.[`${q['Question Code']}Id`]
                                                ?.split(', ')
                                                .includes(a.id)
                                                ? true
                                                : false
                                          }
                                          displayName={a?.displayName}
                                          answerId={a?.id}
                                        // isDisabled={editCustomerLifestyle ? false : true}
                                        />
                                      </div>
                                    );
                                  })}
                                </>
                              )
                            ) : (
                              <span key={iq} className='cp-form__value-text'>
                                {generateAnswerValues(q['Question Code'])}
                              </span>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    {q['Question Code'] == 'MassageOwnershipType' && dirtyMassageOwnerShip ? (
                      <div className='form-group d-flex'>
                        <div className='cp-form__icon-left'></div>
                        <div className='cp-form__group'>
                          {editCustomerLifestyle ? (
                            <>
                              {/*<Select*/}
                              {/*  options={layoutData['Massage Ownership Types'].map((item) => {*/}
                              {/*    return {*/}
                              {/*      value: item.fields.Value.value,*/}
                              {/*      label: item.fields.Value.value*/}
                              {/*    };*/}
                              {/*  })}*/}
                              {/*  className='cp-select'*/}
                              {/*  classNamePrefix='cp-select'*/}
                              {/*  value={selectedMassageOwnerShip}*/}
                              {/*  onChange={(option) => onSelectMassageOwnership(option)}*/}
                              {/*  isSearchable={false}*/}
                              {/*  isMulti*/}
                              {/*  isDisabled={massageOwnerShip ? false : true}*/}
                              {/*/>*/}
                              <Select
                                options={layoutData['Massage Ownership Types'].map((item) => {
                                  return {
                                    value: item.fields.Value.value,
                                    label: item.fields.Value.value,
                                    answerId: item.id
                                  };
                                })}
                                className='cp-select cp-select__customize'
                                classNamePrefix='cp-select'
                                value={selectedOwnerTypeValuesList}
                                onChange={onSelectMassageOwnership}
                                isSearchable={true}
                                isMulti
                                isDisabled={editCustomerLifestyle && massageOwnerShip ? false : true}
                                placeholder={q?.['Question'] || 'Select Massage Ownership'}
                              />
                              <div className='cp-select__customize-selected__item'>
                                {selectedOwnerTypeValuesList.length > 0 &&
                                  selectedOwnerTypeValuesList
                                    .map((val, index) => (
                                      <div key={index} className='item'>
                                        <p className='item-text'>{val.label}</p>
                                        <div className='item-remove__icon icon-close' onClick={() => onRemoveMassageOwnerTypeBtnClick(val)}></div>
                                      </div>
                                    ))}
                              </div>
                            </>
                          ) : (
                            <>
                              {selectedMassageOwnerShip.length !== 0 && (
                                // <span>({selectedMassageOwnerShip ? selectedMassageOwnerShip.sort((a, b) => a.value - b.value)
                                <span>({selectedOwnerTypeValuesList.length ? selectedOwnerTypeValuesList.map((item) => item.value).join(',') : 'N/A'})</span>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <Fragment key={iq} />
                )}
              </Fragment>
            );
          })}
        </div>
        {clientTellingPortalCustomer?.contacts[0]?.contact_type !== 'Anonymous lead' && (
          <div className='cp-form__bottom'>
            <a
              href={`${layoutData['View Profile Link'].value.href}?contactId=${clientTellingPortalCustomer?.contacts[0]?.contactId}`}
              className='link-nomal text-blue'
            >
              <Text field={layoutData['View Profile Label']} />
            </a>
          </div>
        )}
      </div>
      {editCustomerLifestyle ? (
        <div className='cp-form__button'>
          <button
            className='cp-form__button__item btn btn-outline-primary'
            onClick={() => {
              setToggleResetDropdownQuestion(!toggleResetDropdownQuestion);

              onEditCustomerLifestyle();

              dispatch(revertCPCustomer());
            }}
          >
            <Text field={clientTellingPortalQuickAccess.quickAccessLayoutService?.['Cancel Button Label']} />
          </button>
          <button className='cp-form__button__item btn btn-os-orange btn-os-orange--text-primary' onClick={() => onUpdateCustomerLifestyle()}>
            <Text field={clientTellingPortalQuickAccess.quickAccessLayoutService?.['Save Button Label']} />
          </button>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

ExistingCustomerLifestyle.propTypes = {
  layoutData: object,
  data: object,
  editCustomerLifestyle: bool,
  onEditCustomerLifestyle: func
};

export default ExistingCustomerLifestyle;
