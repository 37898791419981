import SitecoreTextModel from '@models/SitecoreTextModel';

export default class FieldsModel {
  constructor() {
    this['content'] = new SitecoreTextModel();
    
    this.backgroundColor = '';

    this.textColor = '';

    this.ID = '';

    this.name = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'content')) {
      this.content.getData(dataSource['content']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'backgroundColor')) {
      this.backgroundColor = dataSource.backgroundColor;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'textColor')) {
      this.textColor = dataSource.textColor;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ID')) {
      this.ID = dataSource.ID;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'name')) {
      this.name = dataSource.name;
    }

    return this;
  }
}
