import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class FieldsModel {
  constructor() {
    this['Banner Image'] = new SitecoreImageModel();

    this['Breadcrumb Title'] = new SitecoreTextModel();

    this['Commerce Category Source'] = new SitecoreLinkModel();

    this['Custom Metadata'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['Image'] = new SitecoreImageModel();

    this['Menu Image'] = new SitecoreImageModel();

    this['Mobile Banner Image'] = new SitecoreImageModel();

    this['Page Description'] = new SitecoreTextModel();

    this['Page Design'] = '';

    this['Page Tags'] = new SitecoreTextModel();

    this['Page Title'] = new SitecoreTextModel();

    this['Product Features To Select'] = [];

    this['Title'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Image')) {
      this['Banner Image'].getData(dataSource['Banner Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Breadcrumb Title')) {
      this['Breadcrumb Title'].getData(dataSource['Breadcrumb Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Commerce Category Source')) {
      this['Commerce Category Source'].getData(dataSource['Commerce Category Source']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Custom Metadata')) {
      this['Custom Metadata'].getData(dataSource['Custom Metadata']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Menu Image')) {
      this['Menu Image'].getData(dataSource['Menu Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Banner Image')) {
      this['Mobile Banner Image'].getData(dataSource['Mobile Banner Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Page Description')) {
      this['Page Description'].getData(dataSource['Page Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Page Design')) {
      this['Page Design'] = dataSource['Page Design'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Page Tags')) {
      this['Page Tags'].getData(dataSource['Page Tags']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Page Title')) {
      this['Page Title'].getData(dataSource['Page Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Features To Select')) {
      this['Product Features To Select'] = dataSource['Product Features To Select'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }

    return this;
  }
}
