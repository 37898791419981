import './CustomerInterestPage.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ProductInterestItem from '@components/ClientPortal/GenericComponents/ProductInterestItem';
import { getCPCustomer } from '@redux/actions/clientTellingPortalCustomer';
import { useFormatDate } from '@utils/customsHook/useFormatDate';

import LoadingMask from '../../Share/LoadingMask';
import CPPageHeader from '../CPPageHeader';
import CustomerInterestPageModel from './Model/CustomerInterestPageModel';

const CustomerInterestPage = ({ fields }) => {
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);
  const clientProductInterest = clientTellingPortalCustomer?.currentCustomerDetail?.CPCustomerInterest?.Products;
  const formatDateUtil = useFormatDate();

  useEffect(() => {
    const model = new CustomerInterestPageModel();
    let url = new URL(window.location.href);

    setLayoutData(model.getData({ ...fields } || {}));

    dispatch(
      getCPCustomer({
        id: url.searchParams.get('contactId'),
        fromURL: true
      })
    );
  }, []);

  return layoutData ? (
    clientTellingPortalCustomer.isLoading ? (
      <LoadingMask />
    ) : (
      <>
        <CPPageHeader
          title={{
            value: `${clientTellingPortalCustomer?.currentCustomerDetail?.PersonalInformation?.FirstName} ${clientTellingPortalCustomer?.currentCustomerDetail?.PersonalInformation?.LastName}'s ${layoutData['Section Title']?.value}`
          }}
          description={{
            value: `${layoutData['Description'].value.replace('{products-total}', clientProductInterest?.length)}`
          }}
        />
        <div className='cip-review'>
          <div className='container tablet-container'>
            <div className='cip-review__wrap'>
              {clientProductInterest?.length > 0 ? (
                clientProductInterest.map((val, index) => (
                  <div key={index} className='cip-review__product-item'>
                    <ProductInterestItem
                      layoutData={layoutData}
                      productImg={val.Image}
                      productName={val.ProductName}
                      // productSubName={val.productSubName}
                      productBadge={val.Questions}
                      productPurchase={formatDateUtil(val.DateCreated)}
                      isPurchaseOnSpot={val.IsPos}
                      questionData={val.Questions}
                    />
                  </div>
                ))
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </>
    )
  ) : (
    <></>
  );
};

CustomerInterestPage.propTypes = {
  fields: any
};

export default CustomerInterestPage;
