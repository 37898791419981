import './SubmitReviewProcess.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import SubmitReviewProcessModel from '../model/SubmitReviewProcessModel';

const SubmitReviewProcess = ({ fields }) => {
  const isInLoginProcess = useSelector((state) => state.productReviewReducer.submitProductReviewReducer.isShowLogin);
  const isLoginAPISuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const [layoutData, setLayoutData] = useState(null);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);

  // const pageTitle = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Page Title']?.value
  //   ? SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Page Title']
  //   : { value: '' };

  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');

  useEffect(() => {
    const model = new SubmitReviewProcessModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useEffect(() => {
    if (!IsAuthenticated && isLoginAPISuccess) {
      setIsLoginSuccess(true);
    }
  }, [isLoginAPISuccess, IsAuthenticated]);

  return layoutData && !isLoginSuccess ? (
    <>
      {/* <div className='container my-account-header__heading animated-slow fadeIn delay-100'>
          <Text tag='h2' field={pageTitle} className='my-account-header__heading__title' />
        </div> */}
      <div className='product-registration-step submit-review-process-step'>
        <div className='submit-review-process-step__container'>
          <div className='product-registration-step__icon submit-review-process-step__icon'>
            <div className={`product-registration-step__icon__item ${isInLoginProcess ? 'focus' : 'active'}`}>
              <div className='icon-wrapper'>
                <Image className='icon-product-img' field={layoutData['Write Review Icon']} alt={layoutData['Write Review Icon'].value.alt} />
              </div>
              <Text tag='p' field={layoutData['Write Review Text']} className='icon-text' />
            </div>

            <div className={`product-registration-step__icon__line ${isInLoginProcess && 'line-focus'}`}></div>

            <div className={`product-registration-step__icon__item ${isInLoginProcess && 'active'}`}>
              <div className='icon-wrapper'>
                <Image className='icon-login-img' field={layoutData['Login Icon']} alt={layoutData['Login Icon'].value.alt} />
              </div>
              <Text tag='p' field={layoutData['Login Text']} className='icon-text' />
            </div>
          </div>
        </div>
        <Text
          tag='p'
          field={layoutData['Process Details']}
          className='product-registration-step__description submit-review-process-step__description'
        />
      </div>
    </>
  ) : (
    ''
  );
};

SubmitReviewProcess.propTypes = {
  fields: object
};

export default SubmitReviewProcess;
