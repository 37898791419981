import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class SimpleSquareFieldsModel {
  constructor() {

    this.Description = new SitecoreTextModel();

    this.Experience = new SitecoreTextModel();

    this.Image = new SitecoreImageModel();

    this.Name = new SitecoreTextModel();

    this.Position = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience')) {
      this.Experience.getData(dataSource.Experience);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this.Image.getData(dataSource.Image);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name.getData(dataSource.Name);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Position')) {
      this.Position.getData(dataSource.Position);
    }

    return this;
  }
}
