import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class AdditionalFullFilmentOptionModel {
  constructor() {
    this['Additional Information Link'] = new SitecoreLinkModel();

    this['Additional Information Text'] = new SitecoreTextModel();

    this['Additional Information Title'] = new SitecoreTextModel();

    this['Surcharge For Urgent Delivery Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Information Link')) {
      this['Additional Information Link'].getData(dataSource['Additional Information Link']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Information Text')) {
      this['Additional Information Text'].getData(dataSource['Additional Information Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Additional Information Title')) {
      this['Additional Information Title'].getData(dataSource['Additional Information Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Surcharge For Urgent Delivery Label')) {
      this['Surcharge For Urgent Delivery Label'].getData(dataSource['Surcharge For Urgent Delivery Label']);
    }

    return this;
  }
}
