import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { post } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as configs from './config';

let pdpStream;

export const productDetailEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_PRODUCT_DETAIL_SINGLE),
    switchMap(() => {
      if (pdpStream) return pdpStream;
      const queryParams = new URLSearchParams(window.location.search);
      const allReviewDetailsProduct = JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct'));
      const valueContextRouteFields = SitecoreContextFactoryService.getValueContextRouteItem('fields');

      const productId =
        valueContextRouteFields?.['Commerce Product Source']?.fields?.ProductId?.value ||
        valueContextRouteFields?.['Bundle Detail Path']?.fields?.ProductId?.value ||
        allReviewDetailsProduct?.productId;

      const itemId =
        valueContextRouteFields?.['Commerce Product Source']?.id ||
        valueContextRouteFields?.['Bundle Detail Path']?.id ||
        allReviewDetailsProduct?.itemId;

      const params = {
        productId: productId,
        itemId: itemId,
        couponCode: queryParams?.get('cCode') || allReviewDetailsProduct?.cCode,
        productPageId: SitecoreContextFactoryService.getValueContextRouteItem('itemId')
      };

      pdpStream = from(post(configs.ACTION, params)).pipe(
        mergeMap((res) => [processProductDetailOverview(res), processFreeGift(res), processProductDetailHeader(res), processProductInformation(res)])
      );

      return pdpStream;
    })
  );

const processProductDetailOverview = (res) => {
  if (res.status === 200 && res.data?.Success) {
    return actions.getProductDetailOverviewSuccess(res.data);
  } else {
    return actions.getProductDetailOverviewFailed('Failed');
  }
};

const processFreeGift = (res) => {
  if (res.status === 200) {
    return actions.getFreeGiftSuccess(res?.data.FreeGiftList);
  } else {
    return actions.getFreeGiftFailed('Failed');
  }
};

const processProductDetailHeader = (res) => {
  if (res.status === 200 && res.data?.Success) {
    return actions.getProductDetailHeaderSuccess(res.data);
  } else {
    return actions.getProductDetailHeaderFailed('Failed');
  }
};

const processProductInformation = (res) => {
  if (res.status === 200) {
    return actions.getProductInformationSuccess(res);
  } else {
    return actions.getProductInformationFailed('Failed');
  }
};
