import './BackToTop.scss';

import React, { useEffect, useState } from 'react';

const heightToShow = 56;
let currentScroll = 0;

const BackToTop = () => {
  const [isShow, setIsShow] = useState(false);
  const [messengerIcon, setMessengerIcon] = useState(null);

  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };

  }, []);

  useEffect(() => {
    let script = window.document.getElementsByTagName('script');

    setMessengerIcon(
      Object.values(script).every((item) => {
        return item?.nextElementSibling?.className !== 'fb-customerchat';
      })
    );
  }, []);

  const backToTopSmooth = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const onScroll = () => {
    currentScroll = window.scrollY;

    if (currentScroll > heightToShow) {
      setIsShow(true);
    } else {
      setIsShow(false);
    }
  };

  return messengerIcon !== null ? (
    <span role='img' aria-label='Back to top icon' className={`${isShow ? 'back-to-top show' : 'back-to-top'} ${messengerIcon ? '' : ' has-social-icon'}`} onClick={() => { backToTopSmooth(); }}></span >
  ) : (
    <></>
  );
};

export default BackToTop;
