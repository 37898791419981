/*eslint no-undef: "off"*/
import { useCallback } from 'react';

import global from '@utils/global';

import { getSignatureTokenService } from '../services/getSignatureTokenService';

export const useCommissionFactoryOrderTrack = () => {
  return useCallback(async (paymentDetail, currentCart) => {
    const currentCartLines = currentCart?.Lines || [];
    const promoCodes = currentCart?.PromoCodes || [];
    const signatureData = await getSignatureTokenService(paymentDetail?.OrderNumber);

    (function (a, b, c) {
      a[b] =
        a[b] ||
        function () {
          (a[b].q = a[b].q || []).push(arguments);
        };

      a[c] = a[b];
    })(window, 'CommissionFactory', 'cf');

    cf('set', 'order', paymentDetail?.OrderNumber || '');

    cf('set', 'amount', paymentDetail?.TotalAmount?.toString() || '');

    if (promoCodes.length) {
      for (const coupon of promoCodes) {
        cf('set', 'coupon', coupon || '');
      }
    } else {
      cf('set', 'coupon', '');
    }

    cf('set', 'currency', paymentDetail.Currency || '');

    cf('set', 'date', global.formatDateYYYYMMDD(new Date()) || '');

    cf('set', 'signature', signatureData?.signature || '');

    cf('set', 'timestamp', signatureData?.timestamp || '');

    if (currentCartLines.length) {
      for (const cartItem of currentCartLines) {
        cf('add', 'items', {
          sku: cartItem?.ProductId || '',
          price: cartItem?.LineFinalTotalAmount?.toString() || '',
          quantity: parseInt(cartItem?.Quantity) || ''
        });
      }
    }

    cf('track');
  }, []);
};
