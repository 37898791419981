import { any } from 'prop-types';
import React from 'react';
import { Accordion } from 'react-bootstrap';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { useSelectingFreeGiftUtils } from '../../hooks';
import useSelectingFreeGift from '../../SelectingFreeGiftProvider/useSelectingFreeGift';
import FreeGiftSelectionItem from '../FreeGiftSelectionItem';

export const FreeGiftSelectionBody = ({ fieldData }) => {
  const { handleGenerateAccordionKey, handleSetActiveKey, handleGenerateFreeGiftInfo } = useSelectingFreeGiftUtils();
  const { freeGiftDataSort, setEditingFreeGift } = useSelectingFreeGift();

  const handleEditFreeGift = (freeGift) => {
    setEditingFreeGift((prevState) => [...prevState, freeGift]);
  };

  return (
    <div className='free-gift__selection'>
      {freeGiftDataSort.map((freeGift, index) => (
        <Accordion
          key={handleGenerateAccordionKey(freeGift.PromotionId, index)}
          activeKey={handleSetActiveKey(freeGift.PromotionId, index)}
          className='free-gift__accordion'
        >
          {handleSetActiveKey(freeGift.PromotionId, index) !== handleGenerateAccordionKey(freeGift.PromotionId, index) ? (
            <div className='free-gift__accordion-header'>
              <div className='free-gift__accordion-title'>
                <p className='title'>{freeGift.PromotionTitle}</p>
                {handleGenerateFreeGiftInfo(freeGift.PromotionId).map((name) => (
                  <p key={name} className='gift-name'>
                    {name}
                  </p>
                ))}
              </div>
              <button className='free-gift__accordion-btn' onClick={() => handleEditFreeGift(freeGift)}>
                <Text field={fieldData?.['Edit Free Gift Label']} />
              </button>
            </div>
          ) : (
            <></>
          )}
          <Accordion.Collapse eventKey={handleGenerateAccordionKey(freeGift.PromotionId, index)} className='free-gift__accordion-body'>
            <div className='free-gift__selection-container'>
              <div className='free-gift__selection-condition__title'>
                <p>{freeGift.PromotionTitle}</p>
              </div>
              {freeGift.slotData.length ? (
                freeGift.slotData.map((slotItem) => (
                  <FreeGiftSelectionItem
                    key={`${freeGift.PromotionId}-${slotItem.Slot}`}
                    productInfo={{
                      products: [...slotItem.products],
                      Slot: slotItem.Slot,
                      PromotionId: freeGift.PromotionId
                    }}
                    freeGiftName={fieldData?.['Free Gift Line Title']?.value?.replace('{promotion_title}', slotItem.Slot)}
                  />
                ))
              ) : (
                <></>
              )}
            </div>
          </Accordion.Collapse>
        </Accordion>
      ))}
    </div>
  );
};

FreeGiftSelectionBody.displayName = 'FreeGiftSelectionBody';

FreeGiftSelectionBody.propTypes = {
  fieldData: any
};

export default FreeGiftSelectionBody;
