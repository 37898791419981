import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ContactInformationWidgetModel {
  constructor() {
    this.Title = new SitecoreTextModel();

    this['Phone Text'] = new SitecoreTextModel();

    this['Email Text'] = new SitecoreTextModel();

    this['Edit Text'] = new SitecoreTextModel();

    this['Edit Link'] = new SitecoreLinkModel();

    this['Full Name Text'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Phone Text')) {
      this['Phone Text'].getData(dataSource['Phone Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Text')) {
      this['Edit Text'].getData(dataSource['Edit Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Email Text')) {
      this['Email Text'].getData(dataSource['Email Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Link')) {
      this['Edit Link'] = dataSource['Edit Link'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Full Name Text')) {
      this['Full Name Text'] = dataSource['Full Name Text'];
    }

    return this;
  }
}
