import './CartItemInfor.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useMemo,useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import { CART_PAGE_NAME } from '@components/FreeGiftPopup/FreeGiftItem';
import {useOfferDetails} from '@components/PersonalizedOffer/OfferDetails/OfferDetailsProvider';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import InfoStockComponent from '@components/Share/InfoStockComponent';
import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import { showSelectingFreeGiftPopup } from '@redux/actions/controlSelectingFreeGiftPopup';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { GA4_LOCAL_STORAGE_KEY, PromotionType } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { behaviorSubjectCartItem } from '../CartItemBehaviorSubject';
import CartItemFreeGift from '../CartItemFreeGift';
import CartItemGiftingOption from '../CartItemGiftingOption';
import ConfirmRemoveCart from '../ConfirmRemoveCart';
import PromotionCart from '../PromotionCart';

const SAVE_AMOUNT = '{saved-amount}';
const NAME_REPLACE = '{0}';
const EVENT_ADD = 'ADD_TO_CART';
const EVENT_REMOVE = 'REMOVE_FROM_CART';
const EVENT_REMOVE_ALL = 'REMOVE_ALL_FROM_CART';

const CartItemInfor = (props) => {
  const {
    data,
    dataSources,
    errorUpdateCart,
    isLoading,
    statusUpdateCart,
    outOfStockData,
    listProductWishlist,
    showShippingAreaError,
    objMessages,
    tradeInOption,
    currencyInfor,
    giftingOption,
    lineId,
    indexGA4
  } = props;
  const dispatch = useDispatch();
  const [isShow, setShow] = useState(false);
  const [quantity, setQuantity] = useState(+data.Quantity);
  const [saveMessage, setSaveMessage] = useState(null);
  const [isUpdateProcessing, setUpdateProcessing] = useState(false);
  const [isWishListProduct, setWishListProduct] = useState(false);
  const [errTradeInOption, setErrTradeInOption] = useState('');
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const isGA4 = Global.getIsGA4();
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const pageName = SitecoreContextFactoryService.getValueContextRouteItem('name')?.toLowerCase();
  const { isEditingSOCart } = useSelector((state) => state.cpCartDetailsReducer);
  const { osimCartData } = useOfferDetails();

  const isDeposit = useMemo(() => {
    return data?.Deposit !== null;
  }, [data]);

  useEffect(() => {
    const tempSaveMessage = deepCopy(dataSources['Saved Message']);

    tempSaveMessage.value = tempSaveMessage.value.replace(SAVE_AMOUNT, data.LineDiscount);

    setSaveMessage(tempSaveMessage);
    // If line has trade-in options => set quantity to 1
    if (data?.PromotionCartLines.some((line) => line.Type === PromotionType.TradeIn && parseInt(data.Quantity) !== 1)) {
      props.quantityItemEvt(1);

      setErrTradeInOption(messageObj?.['Msg_021']);
    }
  }, [data]);

  useDidUpdateEffect(() => {
    setQuantity(+data.Quantity);
    if(isUpdateProcessing && statusUpdateCart.indexOf('OutOfStock') < 0) {
      setUpdateProcessing(false);
    }
  }, [data]);

  useEffect(() => {
    const behaviorSubjectCartItemSubscription = behaviorSubjectCartItem.subscribe((params) => {
      setUpdateProcessing(params.cartLineIdEditCurrent === data.ExternalCartLineId ? true : false);
    });

    return () => {
      behaviorSubjectCartItemSubscription.unsubscribe();
    };
  }, [behaviorSubjectCartItem]);

  useEffect(() => {
    if(listProductWishlist?.length) {
      const wishList = listProductWishlist.find(wishListItem => (wishListItem.Product.ProductId === data.ProductId));

      setWishListProduct(wishList || false);
    }
  }, [listProductWishlist]);

  const setQuantityItem = (type, isEditable) => {
    if (isEditable) {
      let quantityItem = 0;

      quantityItem = type ? quantity + 1 : quantity - 1;

      props.quantityItemEvt(quantityItem);
    }
  };

  const onRemoveCartLineHandler = (isAddWishList) => {
    setShow(false);

    props.removeCartLineEvt(isAddWishList);
  };

  const containRefunableDeposit = (promotionCartLines) => {
    promotionCartLines.some((promotionCartLine) => {
      return promotionCartLine.Type === 7;
    });
  };

  const onSelectGiftingOption = (giftData) => {
    giftingOption(giftData);
  };

  const addOrRemoveCartEventGA4 = (event, isQuantity) => {
    const paramGA4 = Global.getGA4Params();
    const lstKeyFound = Object.keys(paramGA4).find(lstName => lstName === data?.VariantId);
    const lstNameItem = paramGA4[lstKeyFound];
    //New Logic ON/OFF GA, GA4
    if (isGA4) {
      let dataConvert = [];

      dataConvert.push(data);

      const discount = data?.LineDiscountAmount / Number(data?.Quantity);
      const discountAll = data?.LineDiscountAmount;

      const params = {
        ecommerce: {
          currency: currencyInfor?.Name,
          value: data?.LineFinalTotalAmount - data?.LineFinalTotalAmount / Number(data?.Quantity),
          items: Global.getItemsCart(dataConvert, '', isQuantity, currencyInfor?.Name, indexGA4, discount, '', lstNameItem)
        }
      };

      if (event == EVENT_ADD) {
        params.ecommerce.value = data?.LineFinalTotalAmount + data?.LineFinalTotalAmount / Number(data?.Quantity);

        new GoogleAnalytic().gAnalytic4('event', 'add_to_cart', params);
      }

      if (event == EVENT_REMOVE) {
        new GoogleAnalytic().gAnalytic4('event', 'remove_from_cart', params);
      }

      if (event == EVENT_REMOVE_ALL) {
        const paramsRemoveAll = {
          ecommerce: {
            currency: currencyInfor?.Name,
            // value: data?.LineFinalTotalAmount,
            items: Global.getItemsCart(dataConvert, '', data?.Quantity, currencyInfor?.Name, indexGA4, discountAll, 'REMOVE_ALL', lstNameItem)
          }
        };

        new GoogleAnalytic().gAnalytic4('event', 'remove_from_cart', paramsRemoveAll);

        delete paramGA4[lstKeyFound];

        window.localStorage.setItem(GA4_LOCAL_STORAGE_KEY, JSON.stringify(paramGA4));
      }
    }
  };
  const handleOpenSelectingFreeGiftPopup = () => dispatch(showSelectingFreeGiftPopup());

  return (
    <Fragment>
      <div className='cart-item-information'>
        {showShippingAreaError && data.MissingShipping ? (
          <div className='error-message'>{objMessages['Msg_014'].replace(NAME_REPLACE, data.DisplayName)}</div>
        ) : (
          ''
        )}
        {errTradeInOption ? <div className='error-message'>{objMessages?.['Msg_021']}</div> : ''}
        {errorUpdateCart && isUpdateProcessing ? <span className='error-message'>{errorUpdateCart}</span> : ''}
        {outOfStockData ? <p className='error-message'>{outOfStockData.Errors[0]}</p> : ''}
        {data.Type === PromotionType.PwP && !data?.isOnlyMasterId ? (
          <p className='special-deal-message'>
            <Text field={dataSources['Special Deal Label']} />
          </p>
        ) : (
          ''
        )}
        {!data.IsEditable && !data?.IsFreeGiftPopup ? (
          <p className='special-deal-message'>
            <Text field={dataSources['Free Gift Hint']} />
          </p>
        ) : (
          ''
        )}
        {data.PromotionText !== '' ? <p className='special-deal-message'>{data.PromotionText}</p> : ''}
        {data?.Type === PromotionType.AddOnDeals ? (
          <Text field={dataSources?.['Add On Deal Label']} tag='p' className='special-deal-message' />
        ) : (
          <></>
        )}
        <InfoStockComponent
          stock={{ LimitStockQuantity: data.SafetyStockLimitation, StockQuantity: data.StockQuantity, PurchasableLimit: data.PurchasableLimit }}
        >
          {data.LastLabel}
        </InfoStockComponent>
        <div className='cart-item-information__body'>
          <h2 className='cart-item-information__body__title'>
            <a href={`${data.ProductPageLink}?IndexGA4=${props?.indexGA4}`}>{data.DisplayName}</a>
          </h2>
          {!isClientTelingUser && data.IsEditable && !osimCartData ? (
            <span className='cart-item-information__body__remove-btn' onClick={() => setShow(true)}>
              <Text field={dataSources['Remove Button Text']} />
            </span>
          ) : (
            ''
          )}
          {data.IsFreeGiftPopup && !data?.IsAutoAddFreeGiftPopup && data.IsFreeGiftPopupCartPage && !osimCartData ? (
            <span className='cart-item-information__body__remove-btn edit-free-gift-btn' onClick={handleOpenSelectingFreeGiftPopup}>
              {pageName !== CART_PAGE_NAME ? <Text field={dataSources['Edit Label']} /> : <Text field={dataSources['Edit Button Text']} />}
            </span>
          ) : (
            <></>
          )}
          <div className='cart-item-information__body__total-amount'>
            <strong>{data.IsEditable ? data.LineTotal : <Text field={dataSources['Free Text']} />}</strong>
            {!data?.IsFreeGiftPopup && !isDeposit && (
              <span className='smaller-text smaller-text--light'>{data.LineDiscountAmount && saveMessage ? <Text field={saveMessage} /> : ''}</span>
            )}
            {isDeposit && <Text className='deposit-text' field={dataSources?.['Pay Deposit Label']} tag='span' />}
          </div>
          <div className='product-information'>
            <div className='product-information__quantity'>
              <div
                className={
                  data.IsEditable && !outOfStockData && !tradeInOption && !osimCartData
                    ? isLoading && isUpdateProcessing || isClientTelingUser && !isEditingSOCart
                      ? 'product-information__quantity__inner disable'
                      : 'product-information__quantity__inner'
                    : 'product-information__quantity__inner disable'
                }
              >
                <button
                  type='button'
                  className={quantity === 1 ? 'button-minus disable' : 'button-minus'}
                  onClick={() => {
                    setQuantityItem(false, data.IsEditable);

                    addOrRemoveCartEventGA4(EVENT_REMOVE, 1);
                  }}
                >
                  <i className='icon-remove'></i>
                </button>
                <input className='product-information__quantity__current' type='text' name='quantity' value={quantity} disabled />
                <button
                  type='button'
                  className={isUpdateProcessing ? 'button-add disable' : 'button-add'}
                  onClick={() => {
                    setQuantityItem(true, data.IsEditable);

                    addOrRemoveCartEventGA4(EVENT_ADD, 1);
                  }}
                >
                  <i className='icon-add'></i>
                </button>
              </div>
            </div>
            {!isDeposit && (
              <div className='product-information__price'>
                <strong className='smaller-text smaller-text--bold'>
                  {data.IsEditable ? data.LineFinalPrice : <Text field={dataSources['Free Text']} />}
                </strong>
                {data.LineFinalPrice !== data.LinePrice || !data.IsEditable ? (
                  <span className='smaller-text smaller-text--light text-line-through'>{data.LinePrice}</span>
                ) : (
                  ''
                )}
              </div>
            )}
          </div>
        </div>
        <div className='product-information__status'>
          {data.IsInStockProduct ? (
            <span>
              <Message messageCode='LBL-InStock' labelShipping={data['InStockDeliveryLabel']} />
            </span>
          ) : (
            ''
          )}
          {data.IsOutStockProduct ? (
            <span>
              <Message messageCode='LBL-OutOfStock' labelShipping={data['OutStockDeliveryLabel']} />
              .&nbsp;
            </span>
          ) : (
            ''
          )}
          {data.IsPreOrderProduct ? (
            <span>
              <Message messageCode='LBL-PreOrder' labelShipping={data['PreOrderDeliveryLabel']} />
              .&nbsp;
            </span>
          ) : (
            ''
          )}
          {data.IsBackOrderProduct ? (
            <span>
              <Message messageCode='LBL-BackOrder' labelShipping={data['BackOrderDeliveryLabel']} />
              .&nbsp;
            </span>
          ) : (
            ''
          )}
        </div>
        <div className='cart-item-information__footer'>
          {/* {data.IsEditable && !outOfStockData ? (
            <a
              href={`${data.SubLines?.length ? dataSources['Edit Bundle Buying Option Link'] : dataSources['Buying Option Page Link']}?${
                data.SubLines?.length ? 'bundlePageId' : 'productPageId'
              }=${data.ProductPageId ?? ''}&&cartLineId=${data.ExternalCartLineId ?? ''}${data.PromotionCartLines[0]?.Type == 7 ? '&datatype=RefunableDeposit' : ''}`}
              className='cart-item-information__footer__edit-btn link-text'
            >
              <Text field={dataSources['Edit Button Text']} />
            </a>
          ) : (
            ''
          )} */}
          {data.VariantPropertyValue ? (
            <div className='item-color'>
              {!isClientTelingUser && data.IsEditable && !outOfStockData && !osimCartData ? (
                <a
                  href={`${data.SubLines?.length ? dataSources['Edit Bundle Buying Option Link'] : dataSources['Buying Option Page Link']}?${
                    data.SubLines?.length ? 'bundlePageId' : 'productPageId'
                  }=${data.ProductPageId ?? ''}&&cartLineId=${data.ExternalCartLineId ?? ''}${
                    containRefunableDeposit(data.PromotionCartLines) ? '&datatype=RefunableDeposit' : ''
                  }`}
                  className='cart-item-information__footer__edit-btn link-text'
                >
                  <Text field={dataSources['Edit Button Text']} />
                </a>
              ) : (
                ''
              )}
              <strong className='cart-item-information__footer__color'>{data.VariantPropertyValue}</strong>
            </div>
          ) : (
            <></>
          )}

          {data.PromotionCartLines?.length && !data.SubLines?.length
            ? data.PromotionCartLines.map((promotionItem, promotionIdx) => (
              <PromotionCart dataSources={dataSources} freeGiftPomotion={data.Quantity} promotionCartItem={promotionItem} key={promotionIdx} />
            ))
            : ''}
          {data.SubLines?.length ? (
            <ol className='cart-products'>
              {data.SubLines.map((item) => {
                return (
                  <li className='cart-products__item' key={item.ExternalCartLineId}>
                    <div className='cart-products__item__header'>
                      <div className='cart-products__item__product-name'>{item.DisplayName}</div>
                      <div className='cart-products__item__color-name'>{item.VariantPropertyValue}</div>
                    </div>
                    {item.PromotionCartLines && item.PromotionCartLines.length > 0
                      ? item.PromotionCartLines.map((promotionItem, promotionIdx) => (
                        <PromotionCart
                          dataSources={dataSources}
                          freeGiftPomotion={data.Quantity}
                          promotionCartItem={promotionItem}
                          key={promotionIdx}
                        />
                      ))
                      : ''}
                  </li>
                );
              })}

              {data.IsEditable && !outOfStockData && !osimCartData && !isClientTelingUser ? (
                <a
                  href={`${data.SubLines?.length ? dataSources['Edit Bundle Buying Option Link'] : dataSources['Buying Option Page Link']}?${
                    data.SubLines?.length ? 'bundlePageId' : 'productPageId'
                  }=${data.ProductPageId ?? ''}&cartLineId=${data.ExternalCartLineId ?? ''}${
                    containRefunableDeposit(data.PromotionCartLines) ? '&datatype=RefunableDeposit' : ''
                  }`}
                  className='cart-item-information__footer__edit-btn link-text'
                >
                  <Text field={dataSources['Edit Button Text']} />
                </a>
              ) : (
                ''
              )}
            </ol>
          ) : (
            ''
          )}
          {data?.PromotionFreeGift?.length ? (
            <CartItemFreeGift dataSources={dataSources} freeGiftNumbers={data.Quantity} promotionFreeGifts={data.PromotionFreeGift} />
          ) : data.DepositFreeGifts.length ? (
            <CartItemFreeGift dataSources={dataSources} freeGiftNumbers={data.Quantity} promotionFreeGifts={data.DepositFreeGifts} />
          ) : data.DepositPromotionCartLines.length ? (
            <CartItemFreeGift dataSources={dataSources} freeGiftNumbers={data.Quantity} promotionFreeGifts={data.DepositPromotionCartLines} />
          ) : (
            <></>
          )}

          <CartItemGiftingOption
            dataSources={dataSources}
            cartItemData={data}
            itemGiftingOption={data.giftingOption}
            currencyInfor={currencyInfor}
            onAddSelection={onSelectGiftingOption}
            lineId={lineId}
          />
        </div>
      </div>
      <ModalComponent
        isShow={isShow}
        classNameCustom='custom-class'
        titlePopup={<Text field={dataSources['Remove Product Title']} />}
        onCloseHandler={() => setShow(false)}
      >
        <ConfirmRemoveCart
          dataSources={dataSources}
          isWishListProduct={isWishListProduct}
          dataCartLineItem={data}
          confirmRemoveCartEvt={onRemoveCartLineHandler}
          addOrRemoveCartEventGA4={addOrRemoveCartEventGA4}
        />
      </ModalComponent>
    </Fragment>
  );
};

CartItemInfor.propTypes = {
  data: PropTypes.any,
  dataSources: PropTypes.object,
  currencyInfor: PropTypes.object,
  quantityItemEvt: PropTypes.func,
  removeCartLineEvt: PropTypes.func,
  errorUpdateCart: PropTypes.string,
  isLoading: PropTypes.bool,
  showShippingAreaError: PropTypes.bool,
  statusUpdateCart: PropTypes.any,
  outOfStockData: PropTypes.object,
  listProductWishlist: PropTypes.any,
  objMessages: PropTypes.any,
  tradeInOption: PropTypes.bool,
  giftingOption: PropTypes.any,
  lineId: PropTypes.string,
  indexGA4: PropTypes.number
};

const mapStateToProps = (state) => ({
  errorUpdateCart: state.productReducer.cartDetailReducer.errorUpdateCart,
  showShippingAreaError: state.productReducer.cartDetailReducer.checkShippingArea,
  isLoading: state.productReducer.cartDetailReducer.isLoading,
  statusUpdateCart: state.productReducer.cartDetailReducer.statusUpdateCart,
  listProductWishlist: state.productReducer.productWishlistReducer.listProductWishlist,
  objMessages: state.getMessageReducer?.objMessages || null
});

export default connect(mapStateToProps)(CartItemInfor);
