import { array, bool, func, number } from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';

import CustomPaginationSwiper from '@components/Product/VariantDetail/ProductDetailOverview/CustomPaginationSwiper';
import ModalComponent from '@components/Share/ModalComponent';
import SwipeComponent from '@components/Share/SwipeComponent';
import { PRODUCT_DETAIL_DISPLAY_IMAGES } from '@utils/constant';

import ZoomSwiperComponentItem from './ZoomSwiperComponentItem';

const ZoomSwiperComponent = ({ toggleGallery, setCloseZoomImages, activeSlideKey, items, windowWidth }) => {
  const [slideTo, setSlideTo] = useState(activeSlideKey);
  const imageSwiperRef = useRef(null);

  let params = {
    loop: true,
    lazy: true,
    slidesPerView: PRODUCT_DETAIL_DISPLAY_IMAGES,
    centeredSlides: true,
    spaceBetween: 10,
    zoom: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'custom',
      clickable: true
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    on: {
      slideChangeTransitionEnd: () => {
        setSlideTo(imageSwiperRef.current.swiper.realIndex);
      }
    },

    // eslint-disable-next-line react/display-name
    renderPagination: (swiper) => {
      const onSlideToHandler = (slideKey) => {
        setSlideTo(slideKey);

        imageSwiperRef.current.swiper.slideToLoop(slideKey);
      };

      return (
        <CustomPaginationSwiper
          activeIndex={slideTo}
          setActiveKeyEvt={onSlideToHandler}
          prevSlideEvt={onSlideToHandler}
          nextSlideEvt={onSlideToHandler}
          swiper={swiper}
        />
      );
    }
  };

  useEffect(() => {
    imageSwiperRef.current.swiper.slideToLoop(activeSlideKey);
  }, [activeSlideKey]);

  return (
    <ModalComponent
      cssClass='product-image-zoom product-image-zoom--carousel'
      isShow={toggleGallery}
      onCloseHandler={() => setCloseZoomImages(slideTo)}
    >
      <SwipeComponent param={params} setRef={imageSwiperRef}>
        {items.map((item, index) => (
          <div key={index}>
            <ZoomSwiperComponentItem inputData={windowWidth > 767 ? item.fields.Image.value.src : item.fields['Mobile Image'].value.src} />
          </div>
        ))}
      </SwipeComponent>
    </ModalComponent>
  );
};

ZoomSwiperComponent.propTypes = {
  toggleGallery: bool,
  setCloseZoomImages: func,
  activeSlideKey: number,
  items: array,
  windowWidth: number
};

export default ZoomSwiperComponent;
