import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = [
  'Date Label',
  'Order Label',
  'Register Warranty Label',
  'View Details Label',
  'Warranty Expired Label',
  'Warranty Invalid Label',
  'Warranty Pending Label',
  'Warranty Till Label',
  'Write Review Label',
  'Color Label',
  'Back To My Account Text',
  'Register Product And Warranty Button Label',
  'Unknown Text'
];

const LINKARR = [
  'View Details Link',
  'Warranty Registation Link',
  'Write Review Link',
  'Back To My Account Link',
  'Register Product And Warranty Button Link'
];

export default class MyProductsModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
