import { combineEpics } from 'redux-observable';

import accountCheckingEpic from './accountChecking';
import {addNewAddressEpic,addNewAddressWithSetBillingEpic,addNewAddressWithSetFulfillmentEpic,changeDefaultAddressEpic,getCountryDeliveryEpic,getStoreAddressListEpic, getUserAddressListEpic, removeAddressBookEpic, updateBillingAddressEpic} from './fulfillmentDetail';
import {getBillingDataEpic, getBalanceBillingDataEpic, getOrderPaymentStatusEpic,submitOrderEpic, submitOrderPayBalanceEpic} from './payment';
import { downloadInvoiceEpic,getPaymentDetailEpic } from './paymentDetail';

const checkoutProcessEpic = combineEpics(
  accountCheckingEpic,
  getStoreAddressListEpic,
  getUserAddressListEpic,
  updateBillingAddressEpic,
  getBillingDataEpic,
  getBalanceBillingDataEpic,
  submitOrderEpic,
  submitOrderPayBalanceEpic,
  getPaymentDetailEpic,
  addNewAddressEpic,
  getCountryDeliveryEpic,
  changeDefaultAddressEpic,
  removeAddressBookEpic,
  getOrderPaymentStatusEpic,
  addNewAddressWithSetFulfillmentEpic,
  addNewAddressWithSetBillingEpic,
  downloadInvoiceEpic
);

export default checkoutProcessEpic;
