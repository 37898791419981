import './ViewAllSaleOrderModal.scss';

import React from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { useCustomerSaleOrders } from '../index';
import SaleOrderItem from '../SaleOrderItem';
import { useGenerateProductNames } from '../SaleOrderItem/hooks';
import LoadMoreBtn from './LoadMoreBtn';

const ViewAllSaleOrderModal = () => {
  const { layoutData, isShowModal, ordersListing, onCloseModal } = useCustomerSaleOrders();
  const generateProductNamesUtil = useGenerateProductNames();

  return ordersListing.length ? (
    <ModalComponent
      cssClass='view-all-so-modal'
      isShow={isShowModal}
      onCloseHandler={onCloseModal}
      titlePopup={layoutData?.['Section' + ' Title']?.value}
    >
      <div className='box-data view-all-so-modal__box'>
        <div className='box-data__body view-all-so-modal__box-body'>
          {ordersListing.map((orderItem) => {
            const productNames = generateProductNamesUtil(orderItem?.ProductNames, layoutData?.['Package count']?.value);

            return (
              <div key={orderItem?.CartName} className='view-all-so-modal__box-body__item'>
                <SaleOrderItem
                  isViewDetail={true}
                  layoutData={layoutData}
                  orderItem={{
                    ProductNames: orderItem?.ProductNames,
                    ExpiredDate: orderItem?.ExpiredDateWithFormat,
                    Status: orderItem?.Status,
                    SalesOrderId: orderItem?.SalesOrderId,
                    StoreName: orderItem?.StoreName,
                    CartName: orderItem?.CartName,
                    StaffName: orderItem?.StaffName
                  }}
                />
                <div className='box-data__title --sub'>{productNames}</div>
                <p className='sale-order-price'>
                  <Text field={layoutData?.['Total']} /> {orderItem?.Total}
                </p>
              </div>
            );
          })}
        </div>
        <div className='view-all-so-modal__box-footer'>
          <LoadMoreBtn />
        </div>
      </div>
    </ModalComponent>
  ) : (
    <></>
  );
};

export default ViewAllSaleOrderModal;
