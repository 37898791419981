import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { rescheduleEventBookingFailed, rescheduleEventBookingSuccess } from '@redux/actions/eventBooking';
import { RESCHEDULE_EVENT_BOOKING_START } from '@redux/actions/eventBooking/eventBookingReschedule/eventBookingRescheduleTypes';
import { postAsObservable } from '@services/genericService';

import { EVENT_BOOKING_RESCHEDULE_ENDPOINT } from './config';

const eventBookingRescheduleEpic = (action$) =>
  action$.pipe(
    ofType(RESCHEDULE_EVENT_BOOKING_START),
    switchMap((action) => {
      return postAsObservable(EVENT_BOOKING_RESCHEDULE_ENDPOINT, action.payload).pipe(
        map((res) => {
          if (res.status === 200 && res.data.Success) {
            console.log(res);

            return rescheduleEventBookingSuccess(res.data);
          } else {
            return rescheduleEventBookingFailed(res.data?.Message?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(rescheduleEventBookingFailed(error || 'Failed')))
      );
    })
  );

export default eventBookingRescheduleEpic;
