import UserInfoModel from '@components/SingleSignOn/models/UserInfoModel';

export default class TKPaymentDataModel {
  constructor() {
    this.CardType = null;

    this.ContentEncoding = null;

    this.ContentType = null;

    this.CustomerInfo = new UserInfoModel();

    this.Data = null;

    this.Discount = '';

    this.DiscountAmount = 0;

    this.HasErrors = false;

    this.HasInfo = false;

    this.HasWarnings = false;

    this.IsInstallment = false;

    this.OrderId = '';

    this.OrderNumber = '';

    this.OriginalTotal = '';

    this.OriginalTotalAmount = 0;

    this.PaymentMethod = '';

    this.RecursionLimit = null;

    this.SavingTotal = '';

    this.SavingTotalAmount = 0;

    this.ShippingTotal = '';

    this.ShippingTotalAmount = 0;

    this.Subtotal = '';

    this.SubtotalAmount = 0;

    this.TaxTotal = '';

    this.TaxTotalAmount = 0;

    this.Total = '';

    this.TotalAmount = 0;

    this.UserEmail = '';

    this.Currency = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CardType')) {
      this.CardType = dataSource.CardType ?? this.CardType;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ContentEncoding')) {
      this.ContentEncoding = dataSource.ContentEncoding ?? this.ContentEncoding;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ContentType')) {
      this.ContentType = dataSource.ContentType ?? this.ContentType;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CustomerInfo')) {
      this.CustomerInfo.getData(dataSource.CustomerInfo || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Data')) {
      this.Data = dataSource.Data ?? this.Data;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Discount')) {
      this.Discount = dataSource.Discount ?? this.Discount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DiscountAmount')) {
      this.DiscountAmount = dataSource.DiscountAmount ?? this.DiscountAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'HasErrors')) {
      this.HasErrors = dataSource.HasErrors ?? this.HasErrors;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'HasInfo')) {
      this.HasInfo = dataSource.HasInfo ?? this.HasInfo;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'HasWarnings')) {
      this.HasWarnings = dataSource.HasWarnings ?? this.HasWarnings;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsInstallment')) {
      this.IsInstallment = dataSource.IsInstallment ?? this.IsInstallment;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OrderId')) {
      this.OrderId = dataSource.OrderId ?? this.OrderId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OrderNumber')) {
      this.OrderNumber = dataSource.OrderNumber ?? this.OrderNumber;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OriginalTotal')) {
      this.OriginalTotal = dataSource.OriginalTotal ?? this.OriginalTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OriginalTotalAmount')) {
      this.OriginalTotalAmount = dataSource.OriginalTotalAmount ?? this.OriginalTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PaymentMethod')) {
      this.PaymentMethod = dataSource.PaymentMethod ?? this.PaymentMethod;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RecursionLimit')) {
      this.RecursionLimit = dataSource.RecursionLimit ?? this.RecursionLimit;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SavingTotal')) {
      this.SavingTotal = dataSource.SavingTotal ?? this.SavingTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SavingTotalAmount')) {
      this.SavingTotalAmount = dataSource.SavingTotalAmount ?? this.SavingTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ShippingTotal')) {
      this.ShippingTotal = dataSource.ShippingTotal ?? this.ShippingTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ShippingTotalAmount')) {
      this.ShippingTotalAmount = dataSource.ShippingTotalAmount ?? this.ShippingTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Subtotal')) {
      this.Subtotal = dataSource.Subtotal ?? this.Subtotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SubtotalAmount')) {
      this.SubtotalAmount = dataSource.SubtotalAmount ?? this.SubtotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TaxTotal')) {
      this.TaxTotal = dataSource.TaxTotal ?? this.TaxTotal;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TaxTotalAmount')) {
      this.TaxTotalAmount = dataSource.TaxTotalAmount ?? this.TaxTotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total')) {
      this.Total = dataSource.Total ?? this.Total;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'TotalAmount')) {
      this.TotalAmount = dataSource.TotalAmount ?? this.TotalAmount;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'UserEmail')) {
      this.UserEmail = dataSource.UserEmail ?? this.UserEmail;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Currency')) {
      this.Currency = dataSource.Currency ?? this.Currency;
    }

    return this;
  }
}
