import { any, bool } from 'prop-types';
import React, { useEffect, useState} from 'react';

import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

const EventItem = (props) => {
  const { fieldsData, data, isUpcoming } = props;
  const [isExperiedDate, setIsExperiedDate] = useState(false);

  useEffect(() => {
    if (data) {
      if (new Date(data?.['Registration End Time']?.slice(0, -6)).getTime() < new Date().getTime()) {
        setIsExperiedDate(true);
      } else {
        if (new Date(data?.['Registration Start Time']?.slice(0, -6)).getTime() > new Date().getTime()) {
          setIsExperiedDate(true);
        } else {
          setIsExperiedDate(false);
        }
      }
    }
  }, [data]);

  return (
    <article className='article article--horizontal member-event__item animated-slow fadeIn delay-100'>
      <div className='article__image'>
        <a href={data['DetailLink']}>
          <Image field={data?.['Thumnail Image']} />
        </a>
        {data.IsRegistered && (
          <ul className='category-product__list-tag article__list-tag list-tag'>
            <Text tag='li' className='category-product__tag osim-label osim-label--default' field={fieldsData['Registered Text']} />
          </ul>
        )}
      </div>
      <div className='article__content'>
        <h3 className='article__content__title'>
          <a href={data['DetailLink']} className='article__content__title-link'>
            <Text field={{ value: data.Title }} />
          </a>
        </h3>
        <a href={data['DetailLink']} className='article__content__link btn-link bundle-link'>
          <Text field={fieldsData['View More Text']} />
        </a>
        <div>
          <div className='article__content__detail'>
            <RichText className='article__content__detail-day' field={{ value: data['Multiple Date And Time Text'] }} />
            <p className='article__content__detail-location'>
              {/* {data.Location?.map((item, index) => ( */}
              <span>{`${data.Location?.[0]?.Title} ${data.Location?.length > 1 ? `and ${data.Location?.length - 1} more` : ''}`}</span>
              {/* ))} */}
            </p>
          </div>
          <RichText className='article__content__description' field={{ value: data.Description }} />
        </div>
        {isUpcoming ? (
          <Text tag='p' className='article__content__cant-register' 
            field={{ 
              value: `${fieldsData?.['Cant Register Message']?.value.replace('{dateTime}', Global.formatDateMMDDYYYY(new Date(data?.['Registration Start Time']?.slice(0, -6))))}` 
            }} 
          />
        ) : (
          data.IsRegistered ? (
            <div className='article__content__action'>
              <a href={data['DetailLink']} className='btn btn-outline-CTA1'>
                <Text field={fieldsData['View Detail Text Button']} />
              </a>
            </div>
          ) : (
            isExperiedDate
              ? (
                <Text field={fieldsData?.['Expired Message Text']} tag='p' className='article__content__cant-register' />
              ) : (
                <div className='article__content__action'>
                  <a href={data['DetailLink']} className='btn btn-primary'>
                    <Text field={fieldsData['Register Now Text Button']} />
                  </a>
                </div>
              )
          )
        )}
      </div>
    </article>
  );
};

EventItem.propTypes = {
  fieldsData: any,
  data: any,
  isUpcoming: bool
};

export default EventItem;
