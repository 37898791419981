import { number, object, string } from 'prop-types';
import React, { useMemo } from 'react';

import { EXPIRED_STATUS_KEY } from '@redux/actions/cpSaleOrderStatuses/configs';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import useGenerateViewOfferDetailLink from '../../hooks/useGenerateViewOfferDetailLink';

const ViewOfferButton = ({ layoutData, cartStatus, cartName, staffId }) => {
  const generateViewDetailLink = useGenerateViewOfferDetailLink();

  const isCartExpired = useMemo(() => {
    return cartStatus === EXPIRED_STATUS_KEY;
  }, [cartStatus]);

  const viewDetailLink = useMemo(() => {
    return generateViewDetailLink({
      viewDetailLinkObject: layoutData?.['View Offer Button Link'],
      cartName: cartName,
      staffId: staffId
    });
  }, [layoutData]);

  return !isCartExpired ? (
    <Link field={viewDetailLink} className='btn btn-primary view-cart-button'>
      <Text field={layoutData?.['View Offer Button Text']} />
    </Link>
  ) : (
    <Text tag='p' field={layoutData?.['Offer Invalid Label Text']} className='personalize-offer-widget__content-expired-text' />
  );
};

ViewOfferButton.propTypes = {
  layoutData: object,
  cartStatus: number,
  cartName: string,
  staffId: string
};

export default ViewOfferButton;
