import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import ProductDetailPopUpModel from '@models/apiModels/ProductDetailPopUpModel';
import { Text } from '@sitecore-jss/sitecore-jss-react';

const ProductRecommendationItem = (props) => {
  const [dataFields, setDataFields] = useState(null);

  useEffect(() => {
    const productRecommendationItemModel = new ProductDetailPopUpModel();

    setDataFields(productRecommendationItemModel.getData(props.dataFromAPI || {}));
  }, []);

  return (
    dataFields && (
      <Fragment>
        <div className='product-recommendation__list__item__image'>
          <img className='product-recommendation-image' src={dataFields.ImageURLs[0]} alt='product recommendation' />
        </div>
        <div className='product-recommendation__list__item__content'>
          <h3 className='product-recommendation__info__name'>{dataFields.DisplayName}</h3>
          <p className='product-recommendation__info__selling-price'>${dataFields.ListPrice}</p>
          <del className='product-recommendation__info__fixed-price'>${dataFields.SellingPrice}</del>
          {/* <a href={dataFields.ProductPageUrl} className='product-recommendation__content__learn-more'>
            <Text field={props.dataFromLayout['Learn More Text']} editable={true} />
          </a> */}
        </div>
      </Fragment>
    )
  );
};

ProductRecommendationItem.propTypes = {
  dataFromAPI: PropTypes.object,
  dataFromLayout: PropTypes.object
};

export default ProductRecommendationItem;
