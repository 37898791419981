import './ProductReviewForPDP.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GetBundleElement, getCustomerReviewsAverage, getDynamicBundleElement, getProductReview } from '@redux/actions/productReview';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import LimitedReviewListing from '../LimitedReviewListing';
import ReviewsSummary from '../ReviewsSummary';
import ProductReviewForPDPModels from './Models/ProductReviewForPDPModels';
import NavigationBundleProduct from './NavigationBundleProduct';

const ProductReviewForPDP = ({ fields }) => {
  const TEMPLATE_NAME_STATIC_BUNDLE = 'Bundle Product Page';
  const TEMPLATE_NAME_DYNAMIC_BUNDLE = 'Dynamic Bundle';
  const TEMPLATE_NAME_PRODUCT_PAGE = 'Product Page';
  const dispatch = useDispatch();
  const getBundleElementReducer = useSelector((state) => state.productReviewReducer.getBundleElementReducer);
  const getDynamicBundleElementReducer = useSelector((state) => state.productReviewReducer.getDynamicBundleElementReducer);
  const customerReviewsAverageReducer = useSelector((state) => state.productReviewReducer.customerReviewsAverageReducer);
  const getProductReviewReducer = useSelector((state) => state.productReviewReducer.getProductReviewReducer);
  const [productReviewForPDPFields, setProductReviewForPDPFields] = useState(null);
  const [arrBundleElement, setArrBundleElement] = useState(null);
  const [productName, setProductName] = useState(null);
  const [productImg, setProductImg] = useState(null);
  const [productId, setProductId] = useState(null);
  const [commerceId, setCommerceId] = useState(null);

  const dataCustomerReviewsAverage = customerReviewsAverageReducer?.customerReviewsAverage?.Data;
  const dataProductReview = getProductReviewReducer?.productReviews?.Items;
  const dynamicBundleName = getDynamicBundleElementReducer?.bundleName;

  const excludeFromProductReviewSearch =
    SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Exclude From Product Review Search']?.value;

  const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');

  useEffect(() => {
    const productReviewForPDPModels = new ProductReviewForPDPModels();

    setProductReviewForPDPFields(productReviewForPDPModels.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');
    const productIdSingle = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.fields?.ProductId?.value;
    const bundlePageId = SitecoreContextFactoryService.getValueContextRouteItem('itemId');

    if (templateName == TEMPLATE_NAME_STATIC_BUNDLE) {
      dispatch(
        GetBundleElement({
          bundlePageId: bundlePageId
        })
      );
    } else if (templateName == TEMPLATE_NAME_DYNAMIC_BUNDLE) {
      dispatch(
        getDynamicBundleElement({
          bundlePageId: bundlePageId
        })
      );
    } else {
      setProductId(productIdSingle);
    }
  }, []);

  //StaticBundleElement
  useDidUpdateEffect(() => {
    setArrBundleElement(getBundleElementReducer?.bundleElement?.filter((product) => product.IsExcludedFromReviewSearch === false));
  }, [getBundleElementReducer]);

  //DynamicBundleElement
  useDidUpdateEffect(() => {
    setArrBundleElement(getDynamicBundleElementReducer?.bundleElement?.filter((product) => product.IsExcludedFromReviewSearch === false));
  }, [getDynamicBundleElementReducer]);

  //Set Info First Product in arrayProduct
  useDidUpdateEffect(() => {
    setProductId(arrBundleElement?.[0]?.ProductId);

    setCommerceId(
      arrBundleElement?.[0]?.CommerceId
        ? arrBundleElement?.[0]?.CommerceId?.replace(/{|}/g, '')
        : arrBundleElement?.[0]?.CommerceProductId?.replace(/{|}/g, '')
    );

    setProductName(arrBundleElement?.[0]?.Name ? arrBundleElement?.[0]?.Name : arrBundleElement?.[0]?.ProductName);

    setProductImg(arrBundleElement?.[0]?.ImageUrl ? arrBundleElement?.[0]?.ImageUrl : arrBundleElement?.[0]?.Image);
  }, [arrBundleElement]);

  useDidUpdateEffect(() => {
    if (productId) {
      dispatch(
        getCustomerReviewsAverage({
          productId: productId
        })
      );

      dispatch(
        getProductReview({
          productId: productId,
          page: 1,
          pageSize: 3
        })
      );
    }
  }, [productId]);

  const onChangeProductBundle = (productName, productImg, productId, commerceId) => {
    setProductName(productName);

    setProductImg(productImg);

    setProductId(productId);

    setCommerceId(commerceId);
  };

  return (
    productReviewForPDPFields &&
    !excludeFromProductReviewSearch && (
      <div id='ProductReviews' className='productReviewForPDP'>
        <div className='container'>
          {arrBundleElement && <NavigationBundleProduct arrayBundle={arrBundleElement} onChangeProductBundle={onChangeProductBundle} />}
          <div className='productReviewForPDP__content'>
            <ReviewsSummary dataApiReviewsSummary={dataCustomerReviewsAverage} fieldsData={productReviewForPDPFields} commerceId={commerceId} />
            <LimitedReviewListing
              dataApiReviewListing={dataProductReview}
              fieldsData={productReviewForPDPFields}
              productName={productName}
              productImg={productImg}
              productId={productId}
              dynamicBundleName={dynamicBundleName}
              commerceId={commerceId}
            />
          </div>
        </div>
      </div>
    )
  );
};

ProductReviewForPDP.propTypes = {
  fields: PropTypes.any
};

export default ProductReviewForPDP;
