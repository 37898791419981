import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError,map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/settingGlobal';
import * as settingGlobalTypes from '@redux/actions/settingGlobal/settingGlobalTypes';
import { get } from '@services/genericService';

import * as configs from './config';
import SettingGlobalModel from './settingGlobalModel';

const settingGlobalEpic = (action$) =>
  action$.pipe(
    ofType(settingGlobalTypes.GET_SETTING_GLOBAL),
    switchMap(() => {
      return from(get(configs.ACTION_GET_SETTING)).pipe(
        map((res) => {
          if (res.status === 200 && res.data?.Success) {
            const response = new SettingGlobalModel().getData(res.data?.Data || {});

            return actions.getSettingGlobalSuccess(response);
          } else {
            return actions.getSettingGlobalFailed('Failed');
          }
        }),
        catchError((error) => of(actions.getSettingGlobalFailed(error.message || 'Failed')))
      );
    })
  );

export default settingGlobalEpic;
