import { any, oneOfType } from 'prop-types';
import React from 'react';
import { string } from 'yup';

const MultiSelectQuestion = ({ labelText, ...props }) => {
  return (
    <div className='osim-checkbox'>
      <input {...props} type='checkbox' className='osim-checkbox-input' />
      <label htmlFor={props.id} className='osim-checkbox-label osim-checkbox-label--small osim-checkbox-label--gray'>
        {labelText}
      </label>
    </div>
  );
};

MultiSelectQuestion.propTypes = {
  labelText: oneOfType([string, any]),
  id: any
};

export default MultiSelectQuestion;
