import './ProductSearchResult.scss';

import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import LoginRegisterModal from '@components/CategoryLanding/LoginRegisterModal';
import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import ModalComponent from '@components/Share/ModalComponent';
import Pagination from '@components/Share/Pagination';
import { behaviorSubjectWellnessObjectBehavior } from '@components/UniversalSearchBox/wellnessObjectBehavior';
import WellnessFilterTag from '@components/Wellness/WellnessFilterTag';
import { WELL_BEING_INIT_PAGE, WELL_BEING_PAGESZ } from '@components/Wellness/WellnessListing/configs';
import WellnessListingItem from '@components/Wellness/WellnessListing/WellnessListingItem';
import WellnessListingItemShimmer from '@components/Wellness/WellnessListing/WellnessListingItemShimmer';
import {
  commonSearchPolicy,
  commonSearchProduct,
  commonSearchProductFilter,
  commonSearchResource,
  commonSearchWellness
} from '@redux/actions/common';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { RESOURCE_SEARCH } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import { POLICY_INIT_PAGE, POLICY_PAGESZ } from '../PolicySearchResult/configs';
import PolicySearchResultItem from '../PolicySearchResult/PolicySearchResultItem';
import PolicySearchResultListShimmer from '../PolicySearchResult/PolicySearchResultListShimmer';
import { PRODUCT_SEARCH_INIT_PAGE, PRODUCT_SEARCH_PAGESZ, RESOURCE_SEARCH_PAGESZ } from './configs';
import ProductListingModel from './model/ProductListingModel';
import ProductSearchResultFilterPopup from './ProductSearchResultFilterPopup';
import ProductSearchResultItem from './ProductSearchResultItem';
import ProductSearchResultListShimmer from './ProductSearchResultListShimmer';
import ProductSearchResultNoticeShimmer from './ProductSearchResultNoticeShimmer';
import ResourceSearchItem from './ResourceSearchItem';
import { behaviorSubjectSwitchTab } from './switchTabBehavior';

const ProductSearchResult = (props) => {
  const {
    productsList,
    paramsRequest,
    filterLastest,
    productCount,
    productCountTotalItemsSearch,
    resetPaging,
    facets,
    isLoading,
    wellnessCount,
    policyCount,
    wellnessSearchData,
    policySearchData,
    resourceSearchData,
    resourceCount,
    isResourceLoading
  } = props;

  const [wellnessParams, setWellnessParams] = useState({
    searchTerm: '',
    tags: [],
    page: WELL_BEING_INIT_PAGE,
    pageSz: WELL_BEING_PAGESZ,
    isDispatch: false
  });

  const [supportParams, setSupportParams] = useState({
    searchTerm: '',
    page: POLICY_INIT_PAGE,
    pageSz: POLICY_PAGESZ,
    isDispatch: false
  });

  const [resourceParams, setResourceParams] = useState({
    searchTerm: '',
    page: PRODUCT_SEARCH_INIT_PAGE,
    pageSz: RESOURCE_SEARCH_PAGESZ,
    isDispatch: false
  });

  const dispatch = useDispatch();
  const searchListContainer = useRef(null);
  const [inputData, setInputData] = useState(null);
  const [isShowFilter, setShowFilter] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(null);
  const [isDoneScroll, setIsDoneScroll] = useState(false);
  const [isChangePage, setIsChangePage] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [totalResult, setTotalResult] = useState(null);
  const [wellnessData, setWellnessData] = useState(null);
  const [isShowLoginPopup, setIsShowLoginPopup] = useState(false);
  const [urlLoginPage, setUrlLoginPage] = useState({});

  const [resetWellness, setResetWellness] = useState({
    pagingReset: false
  });

  const [resetSupport] = useState({
    pagingReset: false
  });
  const [currentPage, setCurrentPage] = useState(resourceParams.page);

  const totalProductSearchResult = useMemo(() => {
    // let total = productCount;
    // if (currentPage > PRODUCT_SEARCH_INIT_PAGE || productCount >= RESOURCE_SEARCH_PAGESZ) total = productCountTotalItemsSearch;

    return productCountTotalItemsSearch;
  }, [productCountTotalItemsSearch, productCount, currentPage]);

  useEffect(() => {
    const productListingModel = new ProductListingModel();

    setInputData(productListingModel.getData(deepCopy(props.fields) || {}));
  }, []);

  useEffect(() => {
    const URLSearchTerm = Global.decodeURLQueryParams(Global.getQueryParamsNoLower(window.location.href, 'Term')) || '';

    setIsDoneScroll(true);

    dispatch(
      commonSearchProduct({
        Term: URLSearchTerm,
        dataSourcePath: props.rendering.dataSource
      })
    );

    setWellnessParams({
      ...wellnessParams,
      searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
      tags: Global.onGetValueTagInParamsURL(Global.onGetKeyInParamUrl())
    });
  }, []);

  useDidUpdateEffect(() => {
    if (searchListContainer?.current) {
      Global.scrollToWithCallback(searchListContainer.current.offsetTop - headerHeight, () => setIsDoneScroll(true));
    }
  }, [isChangePage]);

  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  useEffect(() => {
    const behaviorSubjectSwitchtabSubscription = behaviorSubjectSwitchTab.subscribe((params) => {
      setActiveTab(params);
    });

    return () => {
      behaviorSubjectSwitchtabSubscription.unsubscribe();
    };
  }, [behaviorSubjectSwitchTab]);

  useEffect(() => {
    const behaviorSubjectWellnessObjectSubscription = behaviorSubjectWellnessObjectBehavior.subscribe((params) => {
      setWellnessData({ ...params });
    });

    return () => {
      behaviorSubjectWellnessObjectSubscription.unsubscribe();
    };
  }, [behaviorSubjectWellnessObjectBehavior]);

  useEffect(() => {
    activeTab == 'Product Search' && setTotalResult(totalProductSearchResult);

    activeTab == 'Wellness Search' && setTotalResult(wellnessCount);

    activeTab == 'Support Search' && setTotalResult(policyCount);

    activeTab == RESOURCE_SEARCH && setTotalResult(resourceCount);
  }, [activeTab, totalProductSearchResult, wellnessCount, policyCount, resourceCount]);

  useDidUpdateEffect(() => {
    dispatch(commonSearchWellness(wellnessParams));
  }, [wellnessParams.isDispatch]);

  useDidUpdateEffect(() => {
    dispatch(commonSearchPolicy(supportParams));
  }, [supportParams.isDispatch]);

  useDidUpdateEffect(() => {
    dispatch(commonSearchResource(resourceParams));
  }, [resourceParams.isDispatch]);

  const onChangePageHandler = (value) => {
    setIsDoneScroll(true);

    setIsChangePage(!isChangePage);

    // INFO: set state for the current page to know which page is currently
    setCurrentPage(value);
    if (filterLastest) {
      dispatch(commonSearchProductFilter({ ...paramsRequest, Page: value }));
    } else {
      activeTab == 'Product Search' && dispatch(commonSearchProduct({ ...paramsRequest, Page: value }));

      if (activeTab == 'Wellness Search') {
        setWellnessParams({
          ...wellnessParams,
          page: value,
          searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
          isDispatch: !wellnessParams.isDispatch
        });
      }
      if (activeTab == 'Support Search') {
        setSupportParams({
          ...supportParams,
          page: value,
          searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
          isDispatch: !supportParams.isDispatch
        });
      }

      if (activeTab == RESOURCE_SEARCH) {
        setResourceParams({
          ...resourceParams,
          page: value,
          searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
          isDispatch: !resourceParams.isDispatch
        });
      }
    }
  };

  const onChangeTagsFilterHandler = (value) => {
    let tempParams = { ...wellnessParams };
    if (tempParams.tags.length && tempParams.tags.includes(value)) {
      tempParams.tags = tempParams.tags.filter((item) => item !== value);
    } else {
      let tempTagsArr = [...tempParams.tags];

      tempTagsArr.push(value);

      tempParams.tags = [...tempTagsArr];
    }

    setWellnessParams({
      ...tempParams,
      searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
      isDispatch: !wellnessParams.isDispatch
    });

    setResetWellness({ pagingReset: !resetWellness.pagingReset });
    let url = new URL(window.location);

    Object.keys(Global.onGetKeyInParamUrl()).forEach((key) => {
      url.searchParams.delete(key);
    });

    if (tempParams.searchTerm) {
      url.searchParams.set('Term', Global.onGetKeyInParamUrl()?.Term?.[0] || '');
    }
    if (tempParams.tags.length) {
      tempParams.tags.forEach((tag, index) => {
        url.searchParams.set(`Tag${index + 1}`, tag);
      });
    }

    // window.history.replaceState({ additionalInformation: 'Updated the URL with JS' }, 'Title', url);
    window.history.pushState({}, null, url);
    // window.location = url;
  };

  const handleClosePopupLogin = () => setIsShowLoginPopup(false);

  const handleOpenPopupLogin = (buyNowLink) => {
    setIsShowLoginPopup(true);

    setUrlLoginPage(`${inputData?.['Deposit Login Button Link']?.value?.href}?from=${buyNowLink}`);
  };

  return (
    <div className='search-result'>
      <div className='container'>
        {!isLoading && isDoneScroll && inputData ? (
          <div className='search-result__notice'>
            {totalResult > 0 ? (
              <p className='search-result__notice__text'>
                {totalResult} <Text field={inputData['Result Message']} />
              </p>
            ) : (
              <Text className='search-result__notice__text' field={inputData['No Result Text']} tag='p' />
            )}
            {activeTab == 'Product Search' && productCount > 0 ? (
              <Text className='btn btn-outline-CTA2' field={inputData['Filter Text']} tag='span' onClick={() => setShowFilter(true)} />
            ) : null}
          </div>
        ) : (
          <LoadingShimmer itemNumb={1}>
            <ProductSearchResultNoticeShimmer></ProductSearchResultNoticeShimmer>
          </LoadingShimmer>
        )}
        {activeTab == 'Product Search' ? (
          <>
            <div className='search-result__content' ref={searchListContainer}>
              {!isLoading && isDoneScroll && inputData ? (
                <Fragment>
                  {productsList?.map((item) => {
                    return (
                      <ProductSearchResultItem
                        key={item.ProductId}
                        dataFromAPI={item}
                        dataFromLayout={inputData}
                        handleOpenPopupLogin={handleOpenPopupLogin}
                      />
                    );
                  })}
                </Fragment>
              ) : (
                <LoadingShimmer itemNumb={10}>
                  <ProductSearchResultListShimmer></ProductSearchResultListShimmer>
                </LoadingShimmer>
              )}
              <Pagination
                isReset={resetPaging}
                itemPerPage={PRODUCT_SEARCH_PAGESZ}
                totalItem={totalProductSearchResult}
                onChangePageHandler={onChangePageHandler}
              />
              <LoginRegisterModal
                isShow={isShowLoginPopup}
                onCloseHandler={handleClosePopupLogin}
                dataLayout={inputData}
                urlLoginPage={urlLoginPage}
              />
            </div>
            {inputData && facets && productCount > 0 ? (
              <ModalComponent isShow={isShowFilter} onCloseHandler={() => setShowFilter(false)}>
                <ProductSearchResultFilterPopup dataSources={inputData} dataFromAPI={facets} appplyFilterEvt={() => setShowFilter(false)} />
              </ModalComponent>
            ) : null}
          </>
        ) : (
          <></>
        )}
        {activeTab === 'Wellness Search' ? (
          <div className='wellness-listing'>
            <div className='searchbox-article'>
              <div className='searchbox-article--tool'>
                <WellnessFilterTag
                  label={wellnessData?.['Filter By Label']}
                  tagList={wellnessData?.['Tags To Select']}
                  activeTag={wellnessParams.tags}
                  onChangeTagsFilterHandler={onChangeTagsFilterHandler}
                />
              </div>
            </div>
            <div className='container' ref={searchListContainer}>
              <div className='row'>
                {!isLoading && isDoneScroll && inputData ? (
                  <Fragment>
                    {wellnessSearchData.map((item, itemIdx) => {
                      return (
                        <div className='col-md-4 featured-article__item animated-slow fadeIn delay-100' key={itemIdx}>
                          <WellnessListingItem item={item} />
                        </div>
                      );
                    })}
                  </Fragment>
                ) : (
                  <LoadingShimmer itemNumb={6}>
                    <div className='col-md-4 featured-article__item skeleton__item'>
                      <WellnessListingItemShimmer></WellnessListingItemShimmer>
                    </div>
                  </LoadingShimmer>
                )}
              </div>
            </div>
            <Pagination
              isReset={resetWellness.pagingReset}
              itemPerPage={WELL_BEING_PAGESZ}
              totalItem={wellnessCount}
              onChangePageHandler={onChangePageHandler}
            />
          </div>
        ) : (
          <></>
        )}
        {activeTab == 'Support Search' ? (
          <div className='search-result__content' ref={searchListContainer}>
            {!isLoading && inputData && isDoneScroll ? (
              <ul className='policy-result__list animated-slow fadeIn delay-100'>
                {policySearchData.map((item) => {
                  return (
                    <PolicySearchResultItem key={item.ItemId} dataFromAPI={item} dataFields={inputData} highlightText={supportParams.searchTerm} />
                  );
                })}
              </ul>
            ) : (
              <ul className='policy-result__list'>
                <LoadingShimmer itemNumb={10}>
                  <PolicySearchResultListShimmer></PolicySearchResultListShimmer>
                </LoadingShimmer>
              </ul>
            )}
            <Pagination
              isReset={resetSupport.pagingReset}
              itemPerPage={POLICY_PAGESZ}
              totalItem={policyCount}
              onChangePageHandler={onChangePageHandler}
            />
          </div>
        ) : (
          <></>
        )}
        {activeTab == RESOURCE_SEARCH ? (
          <>
            <div className='resource-search search-result__content' ref={searchListContainer}>
              {!isResourceLoading && isDoneScroll && inputData ? (
                <Fragment>
                  {resourceSearchData?.map((item) => {
                    return <ResourceSearchItem key={item.ItemId} dataFromAPI={item} dataFromLayout={inputData} />;
                  })}
                </Fragment>
              ) : (
                <LoadingShimmer itemNumb={RESOURCE_SEARCH_PAGESZ}>
                  <ProductSearchResultListShimmer></ProductSearchResultListShimmer>
                </LoadingShimmer>
              )}
              <Pagination isReset={false} itemPerPage={RESOURCE_SEARCH_PAGESZ} totalItem={resourceCount} onChangePageHandler={onChangePageHandler} />
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

ProductSearchResult.propTypes = {
  fields: Proptypes.any,
  productsList: Proptypes.array,
  productSuggestionList: Proptypes.any,
  paramsRequest: Proptypes.any,
  filterLastest: Proptypes.any,
  productCount: Proptypes.any,
  productCountTotalItemsSearch: Proptypes.number,
  resetPaging: Proptypes.any,
  facets: Proptypes.any,
  isLoading: Proptypes.any,
  resourceCount: Proptypes.number,

  wellnessCount: Proptypes.number,
  wellnessSearchData: Proptypes.array,

  policyCount: Proptypes.number,
  policySearchData: Proptypes.array,

  resourceSearchData: Proptypes.array,
  isResourceLoading: Proptypes.bool,
  rendering: Proptypes.any
};

const mapStateToProps = (state) => {
  const productSearchReducer = state.commonSearchReducer.productSearchReducer;
  const wellnessSearchReducer = state.commonSearchReducer.wellnessSearchReducer;
  const policySearchReducer = state.commonSearchReducer.policySearchReducer;
  const resourceSearchReducer = state.commonSearchReducer.resourceSearchReducer;

  return {
    productsList: productSearchReducer.productList || null,
    productSuggestionList: productSearchReducer.productSuggestionList || null,
    paramsRequest: productSearchReducer.paramsRequest || null,
    filterLastest: productSearchReducer.filterLastest || null,
    productCount: productSearchReducer.productCount || 0,
    productCountTotalItemsSearch: productSearchReducer.productCountTotalItemsSearch,
    resetPaging: productSearchReducer.resetPaging || false,
    facets: productSearchReducer.facets || null,
    isLoading: productSearchReducer.isLoading || null,

    wellnessCount: wellnessSearchReducer.wellnessCount || 0,
    wellnessSearchData: wellnessSearchReducer.wellnessSearchData || [],

    policyCount: policySearchReducer.policyCount || 0,
    policySearchData: policySearchReducer.policySearchData || null,

    resourceCount: resourceSearchReducer.resourceCount || 0,
    resourceSearchData: resourceSearchReducer.resourceSearchData || null,
    isResourceLoading: resourceSearchReducer.isResourceLoading || null
  };
};

export default connect(mapStateToProps)(ProductSearchResult);
