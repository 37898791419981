import './CompareProductsForPDP.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CompareProductsForPDPModel from './Model/CompareProductsForPDPModel';

const CompareProductsForPDP = ({ fields, productDetailOverview }) => {
  const { categoryName } = useSelector((state) => state.productReducer.productDetailHeaderReducer);
  const [layoutData, setLayoutData] = useState(null);
  const [compareLink, setCompareLink] = useState(null);

  useEffect(() => {
    const model = new CompareProductsForPDPModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useEffect(() => {
    if (layoutData) {
      let tempLink = deepCopy(layoutData?.['CTA Button Link']);
      const search = window.location.search;
      const query = new URLSearchParams(search);
      const categoryPageId = query.get('CategoryPageId') || productDetailOverview?.CategoryPageId;

      tempLink.value.href = `${layoutData['CTA Button Link'].value.href}?productPageId=${
        SitecoreContextFactoryService.getValueContextRouteItem('itemId') || ''
      }&categoryName=${categoryName || ''}&categoryPageId=${categoryPageId}`;

      setCompareLink(tempLink);
    }
  }, [layoutData, categoryName, productDetailOverview]);

  return (
    layoutData && (
      <div className='container'>
        <div className='compareProductsForPDP__compare-btn'>
          <a className='btn btn-outline-CTA1' href={compareLink?.value?.href}>
            <Text tag='span' field={layoutData['CTA Button Text']} />
          </a>
        </div>
      </div>
    )
  );
};

CompareProductsForPDP.propTypes = {
  fields: PropTypes.object,
  productDetailOverview: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    productDetailOverview: state.productReducer.productInformationReducer?.productInformation
  };
};

export default connect(mapStateToProps)(CompareProductsForPDP);
