import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Back to sale orders',
  'Delivery fee',
  'Discount',
  'End session',
  'Sales tax',
  'Subtotal',
  'Summary',
  'Messaging platform',
  'Sale order sent',
  'Email',
  'Header',
  'Success message',
  'Sms',
  'Sale order is saved',
  'Order id',
  'Create and expire date',
  'Package count',
  'Send order to customer'
];
const LINK_ARR = ['Sale orders listing page', 'End session link', 'Send order to customer link'];
const IMAGE_ARR = ['Success icon'];
const PLAIN_OBJECT = ['SaleOrder'];

export default class SaveSaleOrderSuccessModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMAGE_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    PLAIN_OBJECT.forEach((item) => {
      this[item] = null;
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    PLAIN_OBJECT.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = { ...dataSource[item] };
      }
    });

    return this;
  }
}
