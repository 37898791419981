export default class IncludedProductStockStatusModel {
  constructor() {
    this['Name'] = '';

    this['Properties'] = {};

    this['Value'] = 0;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this['Name'] = dataSource['Name'] ? dataSource['Name'] : this['Name'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Properties')) {
      this['Properties'] = dataSource['Properties'] ? dataSource['Properties'] : this['Properties'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Value')) {
      this['Value'] = dataSource['Value'] ? dataSource['Value'] : this['Value'];
    }

    return this;
  }
}
