import produce from 'immer';

import {
  GET_SO_CART_PAGING_FAILURE,
  GET_SO_CART_PAGING_START,
  GET_SO_CART_PAGING_SUCCESS
} from '@redux/actions/personalizeOffer/getSOCartPaging/types';

const initialStates = {
  isLoading: false,
  error: null,
  data: {
    Carts: [],
    Count: 0
  }
};

const getSOCartPagingReducer = (state = initialStates, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_SO_CART_PAGING_START:
      draft.isLoading = true;

      draft.error = null;
      break;

    case GET_SO_CART_PAGING_SUCCESS:
      draft.isLoading = false;

      draft.data = {
        Count: action.payload?.Count || 0,
        Carts: [...state.data.Carts, ...(action.payload?.Carts || [])]
      };
      break;

    case GET_SO_CART_PAGING_FAILURE:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default getSOCartPagingReducer;
