import './ReturningCustomer.scss';

import PropTypes from 'prop-types';
import React from 'react';

import FacebookSSO from '@components/SingleSignOn/FacebookSSO';
import GoogleSSO from '@components/SingleSignOn/GoogleSSO';

import FormReturningCustomer from './FormReturningCustomer';

const ReturningCustomer = ({inputData}) => {
  return (
    <div className='returning-customer account-checking__tab__content'>
      <div className='account-checking__tab__content__body'>
        <FacebookSSO isLogin={false} dataFields={inputData['Facebook Login Settings']}/>
        <GoogleSSO isLogin={false} dataFields={inputData['Google Login Settings']}/>
        <FormReturningCustomer inputData={inputData}/>
      </div>
    </div>
  );
};

ReturningCustomer.propTypes = {
  inputData: PropTypes.object,
};

export default ReturningCustomer;