import './SupportCentreWidget.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import SupportCentreWidgetModel from './models/SupportCentreWidgetModel';

const SupportCentreWidget = ({ fields }) => {
  const [dataSources, setDataSources] = useState(null);

  useEffect(() => {
    setDataSources(new SupportCentreWidgetModel().getData(fields || {}));
  }, []);
  
  return dataSources ? (
    <div className='my-account-section support-widget'>
      <Text field={dataSources['Support Centre Label']} tag='h3' className='support-widget__heading'></Text>
      <Text field={dataSources['Support Centre Content']} tag='p' className='support-widget__desc'></Text>
      <div className='support-widget__btn text-center'>
        <Link field={dataSources['Go To OSIM Cares Link']} className='support-widget__btn__item btn btn-outline-primary'>
          <Text field={dataSources['Go To OSIM Cares Label']}></Text>
        </Link>
      </div>
    </div>
  ) : (
    <div className='my-account-section support-widget skeleton__item'>
      <h3 className='text-line w-30 support-widget__heading'></h3>
      <p className='support-widget__desc'>
        <span className='text-line-20'></span>
        <span className='clear-4'></span>
        <span className='text-line-20'></span>
        <span className='clear-4'></span>
        <span className='text-line-20'></span>
      </p>
      <div className='support-widget__btn text-center'>
        <span className='text-line-40-center w-50'></span>
      </div>
    </div>
  );
};

SupportCentreWidget.propTypes = {
  fields: PropTypes.object
};

export default SupportCentreWidget;