import PropTypes from 'prop-types';
import React, { Fragment } from 'react';

import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';

const ChangePasswordSuccess = ({ dataSources }) => {
  return dataSources ? (
    <Fragment>
      <RichText field={dataSources['Successful Message']} />
      <div className='contact-info__wrap'>
        <div className='contact-info__form text-center'>
          <Text tag='p' className='security-verification__info text-center' field={dataSources['Change Password Message']}/>
          <div className='contact-info__btn text-center'>
            {dataSources['Back To Account Link'].Title.value ? (
              <Link className='btn btn-primary' field={dataSources['Back To Account Link'].Link}>
                <Text field={dataSources['Back To Account Link'].Title} />
              </Link>
            ) : (
              <Link className='btn btn-primary' field={dataSources['Back To Account Link'].Link} />
            )}
          </div>
        </div>
      </div>
    </Fragment>
  ) : '';
};

ChangePasswordSuccess.propTypes = {
  dataSources: PropTypes.object
};

export default ChangePasswordSuccess;
