import './QuickAccessPopup.scss';

import PropTypes from 'prop-types';
import React from 'react';

const QuickAccessPopup = ({ children, closeAccessPopup, isCenter, isEditable, title, onEditCustomerLifestyle, editButtonText='Edit' }) => {
  return (
    <div className={`qA-popup ${isCenter ? 'qA-popup--center' : ''}`}>
      <div className={title ? 'qA-popup__header' : 'qA-popup__header__no-border'}>
        <div className={`qA-popup__close-btn ${!title && 'qA-popup__close-btn-right'}`} onClick={() => closeAccessPopup()}>
          <div className='close-icon'>
            <span></span>
            <span></span>
          </div>
        </div>
        {title}
        <span className='qA-popup__description' onClick={onEditCustomerLifestyle}>
          {isEditable ? editButtonText : ''}
        </span>
      </div>
      <div className={`qA-popup__body ${!title && 'qA-popup__body__QACustomerBasicProfile'}`}>{children}</div>
    </div>
  );
};

QuickAccessPopup.propTypes = {
  children: PropTypes.any,
  closeAccessPopup: PropTypes.func,
  isCenter: PropTypes.bool,
  isEditable: PropTypes.bool,
  title: PropTypes.any,
  onEditCustomerLifestyle: PropTypes.func,
  editButtonText: PropTypes.string
};

export default QuickAccessPopup;
