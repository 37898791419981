import './AppointmentBooked.scss';

// import PropTypes from 'prop-types';
import React from 'react';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
// import { deepCopy } from '@utils/utility';

// import AppointmentBookedModel from './model/AppointmentBookedModel';

const AppointmentBooked = ()=> {
  // const [appointmentBooked, SetAppointmentBooked] = useState(null);
  
  // useEffect(()=> {
  //   const appointmentBooked = new AppointmentBooked();

  //   SetAppointmentBooked(appointmentBooked.getData(deepCopy(fields)));
  // }, []);

  return (
    <div className='appointment-booked'>
      <div className='container'>
        <div className='appointment-booked__content'>
          <Image field={{src: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTvPyji40I7DUJeo48b3RPADDxP3MIgv62TWw&usqp=CAU'}} className='appointment-booked__content__icon'/>
          <Text field={{value: 'Appointment Booked'}} tag='h2' className='appointment-booked__content__title'/>
          <Text field={{value: '#xxxxxx'}} tag='span' className='appointment-booked__content__code'/>
          <div className='appointment-booked__content__time'>
            <Text field={{value:'12 Dec 2020'}} tag='span'/>
            <Text field={{value:'11:00 am'}} tag='span'/>
            <Text field={{value:'2 Guest(s)'}} tag='span'/>
          </div>
          <div className='appointment-booked__content__address'>
            <Text field={{value: 'OSIM Plaza Singapura'}} tag='h3' className='appointment-booked__content__address__title'/>
            <Text field={{value: '68 Orchard Road, #03-09 Plaza Singapura, Singapore +65 63393201'}} tag='p' className='appointment-booked__content__address__description'/>
          </div>
          <Text field={{value: 'A booking confirmation will be sent to you via email.'}} tag='p' className='appointment-booked__content__excerpts'/>
          <Text field={{value: 'See you soon!'}} tag='p' className='appointment-booked__content__text'/>
          <Link field={{href: '#'}} className='appointment-booked__content__link-view'>
            <Text field={{value: 'View Appointment Detail'}}/>
          </Link>
        </div>
      </div>
    </div>
  );
};

// FeaturedTiles.propTypes = {
//   fields: PropTypes.object
// };

export default AppointmentBooked;
