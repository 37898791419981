import PropTypes from 'prop-types';
import React from 'react';

import SpinAndWinComponent from './SpinAndWinComponent';

const SpinAndWin = ({ fields}) => <SpinAndWinComponent fields={fields}/>;

SpinAndWin.propTypes = {
  fields: PropTypes.any,
};

export default SpinAndWin;
