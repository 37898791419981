import React from 'react';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';

export const QuestionItem = ({ answer, chooseAnswer, active, step, Type }) => {
  const { ID, Name, AvailableNextAnswers } = answer

  return Name && (
    <div className={`help-me-decide__anser__item  ${active && 'help-me-decide__anser__item--active'}`} onClick={() => { chooseAnswer(step, ID, AvailableNextAnswers, Type) }}>
      <RichText className='help-me-decide__anser__item__text' field={{ value: Name }}></RichText>
    </div>
  );
};

QuestionItem.propTypes = {
  answer: PropTypes.object,
  chooseAnswer: PropTypes.func,
  active: PropTypes.bool,
  step: PropTypes.number,
  Type: PropTypes.any
};

