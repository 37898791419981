export default class DynamibBundleDataModel {
  constructor() {
    this['BundleId'] = '';

    this['BundleName'] = '';

    this['Products'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleId')) {
      this['BundleId'] = dataSource['BundleId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BundleName')) {
      this['BundleName'] = dataSource['BundleName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products')) {
      this['Products'] = dataSource['Products'];
    }

    return this;
  }
}
