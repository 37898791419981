import FeaturesBarFieldsModel from './FeaturesBarFieldsModel';

export default class FeaturesBarModel {
  constructor() {
    this['fields'] = new FeaturesBarFieldsModel;
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }

    return this;
  }
}
