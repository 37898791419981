import CountdownFormatSettingModel from '@components/PromotionListing/model/CountdownFormatSettingModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class PromotionHeaderModel {
  constructor() {
    this['Buy Now Button Label'] = new SitecoreTextModel();

    this['Buy Now Button Link'] = new SitecoreLinkModel();

    this['CTA Button Label'] = new SitecoreTextModel();

    this['CTA Button Link'] = new SitecoreLinkModel();

    this['Get Notified Button Label'] = new SitecoreTextModel();

    this['Get Notified Button Link'] = new SitecoreLinkModel();

    this['List Price'] = new SitecoreTextModel();

    this['Promo Starting In Text'] = new SitecoreTextModel();

    this['Promotion Name Label'] = new SitecoreTextModel();

    this['Selling Price'] = new SitecoreTextModel();

    this['Valid From Label'] = new SitecoreTextModel();

    this['countdownFormatSetting'] = new CountdownFormatSettingModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Label')) {
      this['Buy Now Button Label'].getData(dataSource['Buy Now Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Link')) {
      this['Buy Now Button Link'].getData(dataSource['Buy Now Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Label')) {
      this['CTA Button Label'].getData(dataSource['CTA Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Link')) {
      this['CTA Button Link'].getData(dataSource['CTA Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Get Notified Button Label')) {
      this['Get Notified Button Label'].getData(dataSource['Get Notified Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Get Notified Button Link')) {
      this['Get Notified Button Link'].getData(dataSource['Get Notified Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'List Price')) {
      this['List Price'].getData(dataSource['List Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Promo Starting In Text')) {
      this['Promo Starting In Text'].getData(dataSource['Promo Starting In Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Promotion Name Label')) {
      this['Promotion Name Label'].getData(dataSource['Promotion Name Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Selling Price')) {
      this['Selling Price'].getData(dataSource['Selling Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Valid From Label')) {
      this['Valid From Label'].getData(dataSource['Valid From Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'countdownFormatSetting')) {
      this['countdownFormatSetting'].getData(dataSource['countdownFormatSetting']);
    }

    return this;
  }
}
