import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = [
  'End Time',
  'Start Time',
  'Time Remaining',
  'Countdown Days Text',
  'Countdown Desktop Position',
  'Countdown Hours Text',
  'Countdown Minutes Text',
  'Countdown Mobile Position',
  'Countdown Seconds Text',
  'Countdown Timer Text Color Code',
  'Countdown Title Text',
  'Countdown Title Text Color Code',
  'Enable Countdown Timer',
  'Countdown Date Unit Font Size',
  'Countdown Timer Text Font Size',
  'Countdown Title Text Font Size',
  'Countdown Timer Mobile Text Font Size',
  'Countdown Title Mobile Text Font Size',
  'Countdown Date Mobile Unit Font Size',
  'Countdown Date Unit Text Color Code'
];

export default class CountDownBannerModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
