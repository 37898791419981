import React from 'react';
import { Fragment } from 'react';

const CheckoutReviewShimmer = () => {

  return (
    <Fragment>
      <div className='delivery-component review-payment'>
        <div className='container'>
          <div className='delivery-component__header'>
            <span className='text-line-35 w-30'></span>
          </div>
        </div>
      </div>
      <div className='delivery-component'>
        <div className='container'>
          <div className='delivery-component__header'>
            <span className='text-line-35 w-30'></span>
          </div>
          <div className='delivery-component__wrapper'>
            <div className='delivery-container'>
              <div className='address-component'>
                <span className='delivery-item-title text-line-30'></span>
                <div className='delivery-row address-component__row'>
                  <div className='delivery-col-left'>
                    <span className='text-line-24'></span>
                    <span className='clear-8'></span>
                    <span className='text-line-22'></span>
                    <span className='clear-8'></span>
                    <span className='text-line-22'></span>
                    <span className='clear-8'></span>
                    <span className='text-line-22'></span>
                  </div>
                  <div className='delivery-col-right delivery-col-right--padding-left'>
                    <span className='text-line-24'></span>
                    <span className='clear-8'></span>
                    <span className='text-line-22'></span>
                    <span className='clear-8'></span>
                    <span className='text-line-22'></span>
                    <span className='clear-8'></span>
                    <span className='text-line-22'></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='cart-total large-text'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-6'></div>
            <div className='col-lg-6 cart-total__total-amount cart-summary__total-amount'>
              <ul className='cart-summary__group'>
                <span className='text-line-33'></span>
                <span className='clear-16'></span>
                <span className='text-line-33'></span>
                <span className='clear-16'></span>
                <span className='text-line-33'></span>
              </ul>
              <ul className='cart-total__total-amount__last-list'>
                <span className='text-line-50'></span>
                <span className='clear-16'></span>
                <span className='text-line-24'></span>
                <span className='clear-16'></span>
                <span className='text-line-33'></span>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default CheckoutReviewShimmer;