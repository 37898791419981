import './HelpMeDecideResult.scss';

import { bool, object } from 'prop-types';
import qs from 'qs';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import ModalComponent from '@components/Share/ModalComponent';
import Pagination from '@components/Share/Pagination';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useQuery } from '@utils/customsHook/useQuery';
import { deepCopy } from '@utils/utility';

import { getHelpMeDecide } from '../../redux/actions/helpMeDecide';
import { HELP_ME_DECIDE_FIRST_PAGE, HELP_ME_DECIDE_PAGE_SIZE } from './config';
import FilterPopup from './FilterPopup/FilterPopup';
import HelpMeDecideResultShimmer from './HelpMeDecideResultShimmer/HelpMeDecideResultShimmer';
import ItemResult from './ItemResult/ItemResult';
import ItemResultModel from './model/ItemResultModel';

const HelpMeDecideResult = (props) => {
  const [inputData, setInputData] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const [reset, setReset] = useState({
    pagingReset: false,
  });
  const [params, setParams] = useState({});
  const { fields, isLoading } = props;
  const filterLastest = useSelector((state) => state.helpMeDecideReducer);
  const dispatch = useDispatch();
  var sPageURL = useQuery();

  useEffect(() => {
    const productListingModel = new ItemResultModel();

    setInputData(productListingModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, [params.page]);

  useEffect(() => {
    const productListingModel = new ItemResultModel();

    setInputData(productListingModel.getData(deepCopy(fields || {})));
  }, []);

  const convertParamsUrl = (idList, filterData) => {
    return `${idList}&${qs.stringify(filterData)}`;

  };

  function removeEmptyOrNullProperties(obj) {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (value === null || value === '' || value === undefined || value === false) {
          delete obj[key];
        } else if (typeof value === 'object') {
          removeEmptyOrNullProperties(value);
        }
      }
    }

    return obj;
  }

  useEffect(() => {
    if (inputData) {
      setParams({
        CategoryId: '',
        SortBy: inputData['Search Settings']['Sort Options'][0],
        Price: '',
        Features: [],
        Colors: [],
        AreasOfConcern: [],
        TradeInAvailable: false,
        pageSize: HELP_ME_DECIDE_PAGE_SIZE,
        page: HELP_ME_DECIDE_FIRST_PAGE,
      });
    }
  }, [inputData]);

  useEffect(() => {
    if (sPageURL) {
      fetchData(`${sPageURL}&page=${HELP_ME_DECIDE_FIRST_PAGE}&pageSize=${HELP_ME_DECIDE_PAGE_SIZE}`);
    }
  }, [sPageURL]);

  const closeModal = () => {
    setOpenModal(false);
  };

  const fetchData = async (params) => {
    dispatch(getHelpMeDecide({ params: params, isFilter: false }));
  };

  const onChangePageHandler = (pageNum) => {
    dispatch(getHelpMeDecide({
      params: convertParamsUrl(sPageURL, removeEmptyOrNullProperties({
        ...params,
        page: pageNum
      })), isFilter: false
    }));

    setParams({
      ...params,
      page: pageNum,
    });
  };



  return fields && inputData && (
    <div className='container'>
      <div className='help-me-decide-result__heading'>
        <Text field={fields.Title} tag='h2' className='help-me-decide-result__heading__title' />
      </div>

      {!isLoading ?

        (<>
          <div className='help-me-decide-result__bar'>
            {filterLastest?.TotalItems > 0 ? <><p className='help-me-decide-result__bar__count'>{filterLastest?.TotalItems + ' ' + inputData['Products Found Text'].value}</p>
            </> : <p className='help-me-decide-result__bar__count'>{inputData['No Products Text'].value}</p>}
            {(filterLastest?.TotalItems > 0 || isOpenFilter) && <span className='help-me-decide-result__bar__sort btn btn-outline-CTA2' onClick={() => {
              setIsOpenFilter(true);

              setOpenModal(true);
            }}>{inputData['Filter Title'].value}</span>
            }
          </div>
          <div className='help-me-decide-result__list'>
            {filterLastest?.productList?.map((result, index) => {
              return (
                <ItemResult customClass='article article--horizontal'
                  dataFromLayout={inputData} data={result}
                  key={index}
                  indexGA4={index}
                />
              );
            })}

          </div ></>)
        : <LoadingShimmer itemNumb={10}>
          <HelpMeDecideResultShimmer />
        </LoadingShimmer>}
      <Pagination
        isReset={reset.pagingReset}
        itemPerPage={HELP_ME_DECIDE_PAGE_SIZE}
        totalItem={filterLastest?.TotalItems}
        onChangePageHandler={onChangePageHandler} />
      <ModalComponent isShow={openModal} onCloseHandler={closeModal} titlePopup={<>{inputData['Filter Title'].value}</>}>
        <FilterPopup params={params} reset={reset} setParams={setParams} setReset={setReset} sPageURL={sPageURL} dataSources={inputData} dataFromAPI={filterLastest.facets} appplyFilterEvt={closeModal} />
      </ModalComponent>
    </div >
  );
};

HelpMeDecideResult.propTypes = {
  fields: object,
  isLoading: bool
};


const mapStateToProps = (state) => {
  const helpMeDecideReducer = state.helpMeDecideReducer;

  return {
    isLoading: helpMeDecideReducer.isLoading || null,
  };
};


export default connect(mapStateToProps)(HelpMeDecideResult);
