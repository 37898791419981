import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getOtpCodeFailure, getOtpCodeSuccess } from '@redux/actions/campaignRegister/getOtpCode';
import { GET_OTP_CODE_START } from '@redux/actions/campaignRegister/getOtpCode/types';
import { post } from '@services/genericService';

import { GET_OTP_CODE_API_ENDPOINT } from './config';
import { GetOtpStatus } from './responseStatus';

const getOtpCodeEpic = (action$) =>
  action$.pipe(
    ofType(GET_OTP_CODE_START),
    switchMap((action) => {
      const params = {
        CampaignId: action.payload.campaignId,
        MobileNumber: action.payload.phoneNumber
      };

      return from(post(GET_OTP_CODE_API_ENDPOINT, params)).pipe(
        map((res) => {
          if (GetOtpStatus.SuccessStatusCode.includes(res.data.StatusCode)) {
            return getOtpCodeSuccess(res.data);
          } else {
            return getOtpCodeFailure(res.data || 'Failure');
          }
        }),
        catchError((error) => of(getOtpCodeFailure(error.messages || 'Failure')))
      );
    })
  );

export default getOtpCodeEpic;
