import { deepCopy } from '@utils/utility';

import DashboardLinkModel from '../Models/DashboardLinkModel';

export default class CPOverviewElementModel {
  constructor() {
    this.fields = {};

    this.id = '';

    this.url = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {  
      this['fields'] = new DashboardLinkModel().getData(deepCopy(dataSource['fields']) || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this['id'] = dataSource['id'] ? dataSource['id'].toLowerCase() : this['id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'url')) {
      this['url'] = dataSource['url'] ? dataSource['url'].toLowerCase() : this['url'];
    }

    return this;
  }
}