import { STOCK_QUANTITY } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import BOVariantLabelsModel from './BOVariantLabelsModel';
import BOVariantsAvailabilityModel from './BOVariantsAvailabilityModel';
import BOVariantsPriceModel from './BOVariantsPriceModel';
import BOVariantStockModel from './BOVariantStockModel';

export default class BOVariantModel {
  constructor() {
    this.BannerImage = '';

    this.Color = '';

    this.Images = [];

    this.Labels = [];

    this.MobileBannerImage = '';

    this.Price = new BOVariantsPriceModel();

    this.Stock = new BOVariantStockModel();

    this.ProductAvailability = new BOVariantsAvailabilityModel();

    this.ProductOverview = '';

    this.VariantId = '';

    this.VariantName = '';

    this.BackOrderDeliveryLabel = '';

    this.PreOrderDeliveryLabel = '';

    this.InStockDeliveryLabel = '';

    this.OutStockDeliveryLabel = '';

    this.LastLabel = '';

    this.LastFewUnitsLeftLabel = '';

    this.LimitStockQuantity = '';

    this.VariantItemId = '';

    this.VariantPropertyValue = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BannerImage')) {
      this.BannerImage = dataSource.BannerImage ? dataSource.BannerImage : this.BannerImage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Color')) {
      this.Color = dataSource.Color ? dataSource.Color : this.Color;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      this.Images = dataSource.Images ? dataSource.Images : this.Images;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels')) {
      let newLabels = [];

      if (dataSource['Labels']) {
        dataSource['Labels'].map((element) => {
          newLabels.push(new BOVariantLabelsModel().getData(deepCopy(element) || {}));
        });
      }

      this['Labels'] = dataSource['Labels'] ? newLabels : this['Labels'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MobileBannerImage')) {
      this.MobileBannerImage = dataSource.MobileBannerImage ? dataSource.MobileBannerImage : this.MobileBannerImage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price'] || {});
    }
    if (Object.prototype.hasOwnProperty.call(dataSource, 'ProductAvailability')) {
      this['ProductAvailability'].getData(dataSource['ProductAvailability'][0] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductOverview')) {
      this.ProductOverview = dataSource.ProductOverview ? dataSource.ProductOverview : this.ProductOverview;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantId')) {
      this.VariantId = dataSource.VariantId ? dataSource.VariantId : this.VariantId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantName')) {
      this.VariantName = dataSource.VariantName ? dataSource.VariantName : this.VariantName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantItemId')) {
      this.VariantItemId = dataSource.VariantItemId ? dataSource.VariantItemId : this.VariantItemId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BackOrderDeliveryLabel')) {
      this.BackOrderDeliveryLabel = dataSource.BackOrderDeliveryLabel ? dataSource.BackOrderDeliveryLabel : this.BackOrderDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PreOrderDeliveryLabel')) {
      this.PreOrderDeliveryLabel = dataSource.PreOrderDeliveryLabel ? dataSource.PreOrderDeliveryLabel : this.PreOrderDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InStockDeliveryLabel')) {
      this.InStockDeliveryLabel = dataSource.InStockDeliveryLabel ? dataSource.InStockDeliveryLabel : this.InStockDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OutStockDeliveryLabel')) {
      this.OutStockDeliveryLabel = dataSource.OutStockDeliveryLabel ? dataSource.OutStockDeliveryLabel : this.OutStockDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock')) {
      this['Stock'].getData(
        dataSource['Stock'] || {},
        this.BackOrderDeliveryLabel,
        this.PreOrderDeliveryLabel,
        this.InStockDeliveryLabel,
        this.OutStockDeliveryLabel
      );
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastLabel') && dataSource.LastLabel) {
      this.LastLabel = dataSource.LastLabel.replace(STOCK_QUANTITY, dataSource?.Stock?.PurchasableLimit || 0);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastFewUnitsLeftLabel')) {
      this.LastFewUnitsLeftLabel = dataSource.LastFewUnitsLeftLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LimitStockQuantity')) {
      this.LimitStockQuantity = dataSource.LimitStockQuantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantPropertyValue')) {
      this.VariantPropertyValue = dataSource.VariantPropertyValue;
    } else this.VariantPropertyValue = dataSource.Color;

    return this;
  }
}
