import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Title', 'Explore Button Text', 'My Appointments Button Text', 'Explore Button Link', 'My Appointments Button Link'];

export default class MemberEventCancelModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
