import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import BundleBuyingOptionLayout from '@components/BundleBuyingOptionLayout';
import BundleBOFieldsModel from '@components/BundleBuyingOptionLayout/model/BundleBOFieldsModel';
import LoadingMask from '@components/Share/LoadingMask';

const BundleBuyingOption = (props) => {
  const { fields, SubLines, isDoneGetCart } = props;
  const [isEditing, setEditing] = useState(false);
  const [dataFields, setDataFields] = useState(null);

  useEffect(() => {
    const bundleBOFieldsModel = new BundleBOFieldsModel();

    if (isDoneGetCart === true) {
      if (SubLines) {
        setDataFields(bundleBOFieldsModel.getData(fields || {}, SubLines));

        setEditing(true);
      } else {
        setDataFields(bundleBOFieldsModel.getData(fields || {}));

        setEditing(false);
      }
    }
  }, [isDoneGetCart]);

  return dataFields ? (
    <Fragment>
      <BundleBuyingOptionLayout dataBundle={dataFields} isEditing={isEditing}/>
    </Fragment>
  ) : <LoadingMask/>;
};

BundleBuyingOption.propTypes = {
  fields: Proptypes.any,
  SubLines: Proptypes.any,
  isDoneGetCart: Proptypes.any,
};

const mapStateToProps = (state) => {
  const bundleBOReducer = state.bundleBOReducer;

  return {
    SubLines:bundleBOReducer.SubLines || null,
    isDoneGetCart: bundleBOReducer.isDoneGetCart || false,
  };
};


export default connect(mapStateToProps)(BundleBuyingOption);
