import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LIST_BENEFIT_KEY, LIST_PRODUCT_FUNCTION } from '../ProductFunctionsDetail';

const useCheckFunctionValue = () => {
  const { productCompareData } = useSelector((state) => state.compareProductReducer);

  return useCallback(
    (functionLabel, functionId) => {
      return (
        productCompareData?.length &&
        productCompareData.some((item) => {
          if (functionLabel !== LIST_PRODUCT_FUNCTION && functionLabel !== LIST_BENEFIT_KEY) {
            const productInfoDetail = item?.productInfoDetail;
            const functionData = productInfoDetail?.[functionLabel] ?? [];

            return (
              functionData.length &&
              functionData.some((functionItem) => functionItem.Id === functionId && functionItem.FunctionName && functionItem.FunctionValue)
            );
          }
        })
      );
    },
    [productCompareData]
  );
};

export default useCheckFunctionValue;
