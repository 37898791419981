import './SaleOrdersListingNoResult.scss';

import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const SaleOrdersListingNoResult = ({ layoutData }) => {
  return (
    <div className='sale-orders-no-result'>
      <p className='sale-orders-no-result__descrition'>
        {layoutData?.['Number Records Label']?.value?.replace('{number}', '0')}
      </p>
      <div className='search-no-result'>
        <div className='search-no-result__content'>
          <div className='search-no-result__content__image'>
            <img src={require('@assets/images/CP-images/icon-search-big.png')} alt='icon search' />
          </div>
          <Text tag='h5' field={layoutData?.['No Result Label']} className='search-no-result__content__label text-center' />
          <Text tag='p' field={layoutData?.['No Result Description Label']} className='search-no-result__content__desc text-center' />
        </div>
      </div>
    </div>
  );
};

SaleOrdersListingNoResult.propTypes = {
  layoutData: PropTypes.any
};

export default SaleOrdersListingNoResult;