export default class ProductModel {
  constructor() {
    this.ImageURLs = '';

    this.ProductId = '';

    this.ProductName = '';

    this.OriginProductName = '';

    this.ProductPageUrl = '';

    this.SitecoreId = '';

    this.SubLinesImages = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ImageURLs')) {
      this.ImageURLs = dataSource.ImageURLs;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this.ProductId = dataSource.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName')) {
      this.ProductName = dataSource.ProductName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OriginProductName')) {
      this.OriginProductName = dataSource.OriginProductName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductPageUrl')) {
      this.ProductPageUrl = dataSource.ProductPageUrl;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SitecoreId')) {
      this.SitecoreId = dataSource.SitecoreId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SubLinesImages')) {
      this.SubLinesImages = dataSource.SubLinesImages;
    }

    return this;
  }
}
