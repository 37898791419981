import { object } from 'prop-types';
import React, { useMemo } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useQuery } from '@utils/customsHook/useQuery';

import { MY_PRODUCT_ID_SEARCH_PARAMS_KEY } from '../../WarrantyRegistration';

const ViewProductStatusBtn = ({ buttonText, buttonLink }) => {
  const query = useQuery();

  const viewProductDetailHref = useMemo(() => {
    const myProductId = query.get(MY_PRODUCT_ID_SEARCH_PARAMS_KEY);

    return {
      ...buttonLink,
      value: {
        ...buttonLink?.value,
        href: `${buttonLink?.value?.href}?${MY_PRODUCT_ID_SEARCH_PARAMS_KEY}=${myProductId}`
      }
    };
  }, [query, buttonLink]);

  return (
    <Link field={viewProductDetailHref}>
      <Text field={buttonText} />
    </Link>
  );
};

ViewProductStatusBtn.propTypes = {
  buttonText: object,
  buttonLink: object
};

export default ViewProductStatusBtn;
