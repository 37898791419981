import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Notifications Label',
  'Mark all as read Label',
  'View detail Label',
  'Booked Status',
  'Cancelled Status',
  'Changed Status',
  'Appointment Label',
  'Load More Label'
];
const IMG_ARR = ['Add Icon', 'Minus Icon'];

export default class NotificationsContentModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    IMG_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    this['Link Appointment Detail'] = '';
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMG_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link Appointment Detail')) {
      this['Link Appointment Detail'] = dataSource['Link Appointment Detail'];
    }

    return this;
  }
}
