import { ofType } from 'redux-observable';
// import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/singleSignOn';
import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';
import { getAsObservable } from '@services/genericService';

import * as config from '../config';

const logoutEpic = (action$) =>
  action$.pipe(
    ofType(singleSignOnTypes.LOGOUT),
    switchMap(() => {
      return getAsObservable(config.ACTION_LOGOUT).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.logoutSuccess(res.data);
          } else {
            return actions.logoutFailed('Failed');
          }
        })
      );
    })
  );

export default logoutEpic;
