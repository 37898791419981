import parse from 'html-react-parser';
import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { useQuery } from '@utils/customsHook/useQuery';

import { BE_PRODUCT_ID_QUERY_STRING_NAME } from '../../../../BookExperienceComponent/SelectProduct';
import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { SCHEDULE_APPOINTMENT_PARAM_NAME } from '../../configs';

export const SCHEDULE_DATA_SESSION_STORAGE_NAME = 'bookingExperienceScheduleData';

export const FROM_BOOKING_EXPERIENCE_PAGE_QUERY_STRING = '?from=';

const UpdateBEPhoneNumberText = () => {
  const { layoutData, scheduleData } = useScheduleAppointmentContext();
  const aTagRef = useRef(null);
  const { pathname } = useLocation();
  const query = useQuery();

  const updatePhoneNumberText = useMemo(() => {
    return layoutData?.['Update Mobile Number Message']?.value;
  }, [layoutData]);

  const [text, aTag] = useMemo(() => {
    return parse(updatePhoneNumberText);
  }, [updatePhoneNumberText]);

  useEffect(() => {
    // INFO: remove schedule local data when update profile success
    window.sessionStorage.removeItem(SCHEDULE_DATA_SESSION_STORAGE_NAME);
  }, []);

  useLayoutEffect(() => {
    if (aTagRef?.current) {
      const aTagCurrent = aTagRef?.current;
      const aTagHrefValue = aTagCurrent.getAttribute('href');
      const productId = query.get(BE_PRODUCT_ID_QUERY_STRING_NAME);
      const scheduleCurrentStep = query.get(SCHEDULE_APPOINTMENT_PARAM_NAME);

      aTagCurrent.setAttribute(
        'href',
        aTagHrefValue +
          FROM_BOOKING_EXPERIENCE_PAGE_QUERY_STRING +
          pathname +
          `?${BE_PRODUCT_ID_QUERY_STRING_NAME}=${productId}` +
          `&${SCHEDULE_APPOINTMENT_PARAM_NAME}=${scheduleCurrentStep}`
      );
    }
  }, [aTag, aTagRef, pathname, query]);

  const handleUpdateProfile = useCallback(() => {
    window.sessionStorage.setItem(SCHEDULE_DATA_SESSION_STORAGE_NAME, JSON.stringify(scheduleData));
  }, [scheduleData]);

  return (
    <p className='change-phone-number-link' onClick={handleUpdateProfile}>
      {text} {React.cloneElement(aTag, { ref: aTagRef })}
    </p>
  );
};

export default UpdateBEPhoneNumberText;
