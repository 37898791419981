import { any } from 'prop-types';
import React  from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const CustomerLastVisitShimmer = ({ layoutData }) => {
  return (
    <div className='box-data skeleton__item'>
      <div className='box-data__header'>
        <Text field={layoutData['Section Title']} tag='h3' className='box-data__title' />
      </div>
      <div className='last-visit'>
        <div className='box-data__row'>
          <div className='box-data__col-2'>
            <Text tag='div' className='box-data__title --sub' field={layoutData['Last Visit Label']} />
          </div>
          <div className='box-data__col-1'>
            <p className='box-data__notice data-text text-line-25'/>
          </div>
        </div>
        <div className='box-data__row'>
          <div className='box-data__col-2'>
            <Text tag='div' className='box-data__title --sub' field={layoutData['Product Interest Label']} />
          </div>
          <div className='box-data__col-1'>
            <p className='box-data__notice data-text text-line-25'/>
          </div>
        </div>
        <div className='box-data__row'>
          <div className='box-data__col-2'>
            <Text tag='div' className='box-data__title --sub' field={layoutData['Total Spent Label']} />
          </div>
          <div className='box-data__col-1'>
            <p className='box-data__notice data-text text-line-25'/>
          </div>
        </div>
      </div>
    </div>
  );
};

CustomerLastVisitShimmer.propTypes = {
  layoutData: any
};

export default CustomerLastVisitShimmer;