import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import { configureStore } from '@redux/store';
import { SitecoreContext } from '@sitecore-jss/sitecore-jss-react';

import SitecoreContextFactory from './lib/SitecoreContextFactory';
import RouteHandler from './RouteHandler';
// import { ApolloProvider } from 'react-apollo';
import componentFactory from './temp/componentFactory';

// This is the main JSX entry point of the app invoked by the renderer (server or client rendering).
// By default the app's normal rendering is delegated to <RouteHandler> that handles the loading of JSS route data.

// support languages in the URL prefix
// e.g. /da-DK/path, or /en/path, or /path
let store;
let persistor;
let isSSR = true;
let __JSS_STATE__ = null;
let ssrRawJson = null;
if (typeof document !== 'undefined') {
  ssrRawJson = document.getElementById('__JSS_STATE__');

  __JSS_STATE__ = JSON.parse(ssrRawJson.innerHTML);

  isSSR = __JSS_STATE__ ? __JSS_STATE__.sitecore.context.pageEditing : false;
}

store = configureStore(isSSR);
if (!isSSR) {
  persistor = persistStore(store);
}

export const routePatterns = [
  '/:lang([a-z]{2}-[A-Z]{2})/:sitecoreRoute*',
  '/:lang([a-z]{2})/:sitecoreRoute*',
  '/:sitecoreRoute*'
];

// wrap the app with:
// ApolloProvider: provides an instance of Apollo GraphQL client to the app to make Connected GraphQL queries.
//    Not needed if not using connected GraphQL.
// SitecoreContext: provides component resolution and context services via withSitecoreContext
// Router: provides a basic routing setup that will resolve Sitecore item routes and allow for language URL prefixes.
const AppRoot = ({ path, Router, messages }) => {
  const routeRenderFunction = (props) => <RouteHandler route={props} isEditing={isSSR} messages={messages}/>;
  if (isSSR) {
    return (
      <Provider store={store}>
        <SitecoreContext
          componentFactory={componentFactory}
          contextFactory={SitecoreContextFactory}
        >
          <Router location={path} context={{}}>
            <Switch>
              {routePatterns.map((routePattern) => (
                <Route
                  key={routePattern}
                  path={routePattern}
                  render={routeRenderFunction}
                />
              ))}
            </Switch>
          </Router>
        </SitecoreContext>
      </Provider>
    );
  } else {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <SitecoreContext
            componentFactory={componentFactory}
            contextFactory={SitecoreContextFactory}
          >
            <Router location={path} context={{}}>
              <Switch>
                {routePatterns.map((routePattern) => (
                  <Route
                    key={routePattern}
                    path={routePattern}
                    render={routeRenderFunction}
                  />
                ))}
              </Switch>
            </Router>
          </SitecoreContext>
        </PersistGate>
      </Provider>
    );
  }
};

AppRoot.propTypes = {
  path: PropTypes.string,
  Router: PropTypes.func
};

export default AppRoot;
