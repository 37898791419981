import { useReducer } from 'react';

import { CALC_TOTAL_LIST_PRICE, CALC_TOTAL_SELLING_PRICE } from './types';

const initialStates = {
  totalSellingPrice: 0,
  totalListPrice: 0
};

const reducer = (state, action) => {
  switch (action.type) {
  case CALC_TOTAL_SELLING_PRICE: {
    return {
      ...state,
      totalSellingPrice: action.payload
    };
  }

  case CALC_TOTAL_LIST_PRICE: {
    return {
      ...state,
      totalListPrice: action.payload
    };
  }
  }
  throw Error('unknown action: ' + action.type);
};

const useAddOnDealReducer = () => useReducer(reducer, initialStates);

export default useAddOnDealReducer;
