import { uniqWith } from '@utils/utility';

// INFO: ensure the compare data has three items in compare data array
export const mapProductData = (productsData) => {
  const tempData = [];
  for (let i = 0; i < 3; i++) {
    if (productsData[i]) tempData.push(productsData[i]);
    else tempData.push(undefined);
  }

  return tempData;
};

// INFO: convert data api
export const mapDynamicProductFunctions = (products) => {
  if (products[0] && products[0].productInfoDetail.ListProductFunction) {
    const validProducts = products.filter(Boolean);

    const functionLabels = validProducts[0].productInfoDetail.ListProductFunction.map((f) => f.FunctionLabel);

    functionLabels.forEach((label) => {
      const functions = validProducts.reduce((init, current) => {
        const productFunctions = current.productInfoDetail.ListProductFunction.filter((p) => p.FunctionLabel === label)[0];

        if (productFunctions?.Functions) init.push(...productFunctions.Functions.filter((f) => f.Text !== ''));

        return init;
      }, []);

      const functionUniques = uniqWith(functions, (a, b) => a.KeyRelated === b.KeyRelated);

      const functionNames = functionUniques.map((f) => f.KeyRelated);

      validProducts.forEach((p) => {
        let productFunctions = p.productInfoDetail.ListProductFunction.filter((p) => p.FunctionLabel === label)?.[0]?.Functions;

        if (productFunctions) {
          productFunctions = productFunctions.reduce((init, current) => {
            if (current.Text !== '') {
              init.push(current);
            }

            return init;
          }, []);

          functionNames.forEach((functionName) => {
            if (!productFunctions.some((f) => f.KeyRelated === functionName)) {
              if (label === 'Massage Programs') {
                productFunctions.push({ Text: 'No. of programs', Value: '-', KeyRelated: functionName });
              } else {
                productFunctions.push({
                  Id: functionUniques.find((fnc) => fnc.KeyRelated === functionName)?.Id,
                  Text: functionUniques.find((fnc) => fnc.KeyRelated === functionName)?.Text,
                  Value: '',
                  KeyRelated: functionName
                });
              }
            }
          });

          p.productInfoDetail.ListProductFunction.filter((p) => p.FunctionLabel === label)[0].Functions = productFunctions;
        }
      });
    });
  }
};

// INFO: ensure all item in compare data has the same function of ListProductFunction
const generateUniqueListProductFunction = (data) => {
  const productListFunctionData = [];
  for (const product of data) {
    if (product) {
      const productInfoDetail = product.productInfoDetail;
      if (Object.keys(productInfoDetail).length && productInfoDetail.ListProductFunction) {
        for (const item of productInfoDetail.ListProductFunction) {
          productListFunctionData.push({
            Id: item.Id,
            FunctionLabel: item.FunctionLabel
          });
        }
      }
    }
  }

  let uniqueFunction = [];
  for (const functionItem of productListFunctionData) {
    const isDuplicate = uniqueFunction.find((uniqueItem) => uniqueItem.Id === functionItem.Id);
    if (!isDuplicate) uniqueFunction.push(functionItem);
  }

  return uniqueFunction;
};

export const mapListProductFunction = (data) => {
  const productCompareTempData = [...data];
  const uniqueProductFunctionInList = [...generateUniqueListProductFunction(data)];

  for (const product of productCompareTempData) {
    if (product) {
      const productInfoDetail = product.productInfoDetail;

      if (Object.keys(productInfoDetail)?.length && productInfoDetail?.ListProductFunction) {
        let listProductFunction = productInfoDetail.ListProductFunction;
        const listProductFunctionSortedTemp = [];

        for (const [index, functionItem] of uniqueProductFunctionInList.entries()) {
          const functionExistInList = listProductFunction.find((item) => item.Id === functionItem.Id);
          if (!functionExistInList) {
            listProductFunctionSortedTemp[index] = {
              ...functionItem,
              Functions: []
            };
          } else {
            listProductFunctionSortedTemp[index] = { ...functionExistInList };
          }
        }

        productInfoDetail.ListProductFunction = [...listProductFunctionSortedTemp];
      }
    }
  }

  return productCompareTempData;
};
