import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getUserProductDetails } from '@redux/actions/productWarranty';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import { useDidUpdateEffect } from '../../../utils/customsHook/useDidUpdateEffect';
import ProductDetailModel from './models/ProductDetailModel';
import ProductDetailShimmer from './ProductDetailShimmer';

const ProductDetail = ({ fields }) => {
  const dispatch = useDispatch();
  const getUserProductDetailsReducer = useSelector((state) => state.productWarrantyReducer.getUserProductDetails);
  const [layoutData, setLayoutData] = useState(null);
  // const [orderDate, setOrderDate] = useState(null);

  const [productDetails, setProductDetails] = useState(null);
  // useEffect(() => {
  //   productDetails?.Warranty?.OrderDate
  //     ? setOrderDate(new Date(Number.parseInt(productDetails?.Warranty?.OrderDate?.replace(/Date|\(|\/|\)/gi, ''))))
  //     : setOrderDate(new Date(Number.parseInt(productDetails?.ProductReview?.ReviewSubmitDate?.replace(/Date|\(|\/|\)/gi, ''))));
  //   // : setOrderDate('Unknown');
  // }, [productDetails]);

  const orderDate = new Date(Number.parseInt(productDetails?.ProductSubmitDate?.replace(/Date|\(|\/|\)/gi, '')));
  const convertOrderDate = Global.customDate(orderDate, false);

  useEffect(() => {
    const model = new ProductDetailModel();

    setLayoutData(model.getData({ ...fields } || {}));
  }, []);

  useEffect(() => {
    // const productType = new URL(window.location.href).searchParams.get('ProductType');
    // const reviewId = new URL(window.location.href).searchParams.get('ReviewId');
    // const orderId = new URL(window.location.href).searchParams.get('OrderId');
    // const externalCartLineId = new URL(window.location.href).searchParams.get('ExternalCartLineId');
    const myProductId = new URL(window.location.href).searchParams.get('MyProductId');

    dispatch(
      getUserProductDetails({
        // productType: productType,
        // orderId: orderId,
        // externalCartLineId: externalCartLineId,
        // reviewId: reviewId
        myProductId: myProductId
      })
    );
  }, []);

  useDidUpdateEffect(() => {
    const myProductId = new URL(window.location.href).searchParams.get('MyProductId');
    if (getUserProductDetailsReducer?.productDetails.MyProductId === myProductId) {
      setProductDetails(getUserProductDetailsReducer?.productDetails);
    }
  }, [getUserProductDetailsReducer?.productDetails]);

  return productDetails ? (
    <div className='product-warranty__detail-widget'>
      <div className='product-warranty__detail-order__info'>
        <p className='product-warranty__detail-order__number'>
          <p className='product-warranty__detail-order__number'>
            <p className='product-warranty__detail-order__number'>
              <Text field={layoutData?.['Order Number Label']} />
              {productDetails?.OrderNumber ? <span className='order-number'>{productDetails?.OrderNumber}</span> : layoutData['Unknown Text']?.value}
            </p>
          </p>
        </p>
        <p className='product-warranty__detail-order__divider'>|</p>
        <p className='product-warranty__detail-order__placed'>
          <Text field={layoutData?.['Order Placed Label']} />{' '}
          {productDetails?.ProductSubmitDate ? convertOrderDate : layoutData['Unknown Text']?.value}
        </p>
      </div>
      <div className='product-warranty__detail-product__info'>
        <div className='product-warranty__detail-product__image'>
          <img src={productDetails?.ImageUrl && productDetails?.ImageUrl} alt='img' />
        </div>
        <div className='product-warranty__detail-product__detail'>
          <p className='product-title'>{productDetails?.ProductName && productDetails?.ProductName}</p>
          {productDetails?.Color ? (
            <p className='product-color'>
              <Text field={layoutData?.['Color Label']} />:<span> {productDetails?.Color}</span>
            </p>
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  ) : (
    <ProductDetailShimmer />
  );
};

ProductDetail.propTypes = {
  fields: any
};

export default ProductDetail;
