import SitecoreTextModel from '@models/SitecoreTextModel';

export default class OsimRichTextModelModel {
  constructor() {
    this['Text'] = new SitecoreTextModel();

    this['Remove Default Container'] = new SitecoreTextModel();

    this['IsCartPage'] = false;
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Text')) {
      this['Text'].getData(dataSource['Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Remove Default Container')) {
      this['Remove Default Container'].getData(dataSource['Remove Default Container']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsCartPage')) {
      this['IsCartPage'] = dataSource['IsCartPage'];
    }

    return this;
  }
}
