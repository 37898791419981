import { GET_SO_CART_PAGING_FAILURE, GET_SO_CART_PAGING_START, GET_SO_CART_PAGING_SUCCESS } from './types';

export const getSOCartPagingStart = ({ page, pageSz }) => ({
  type: GET_SO_CART_PAGING_START,
  payload: {
    page,
    pageSz
  }
});

export const getSOCartPagingSuccess = (payload) => ({
  type: GET_SO_CART_PAGING_SUCCESS,
  payload
});

export const getSOCartPagingFailure = (payload) => ({
  type: GET_SO_CART_PAGING_FAILURE,
  payload
});
