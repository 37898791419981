import { combineReducers } from 'redux';

import allStoreReducer from './allStore';
import estimateShippingFeeReducer from './estimateShippingFee';
import filterAreaOptionsReducer from './filterAreaOptions';
import filterCityOptionsReducer from './filterCityOptions';
import filterProvinceOptionsReducer from './filterProvinceOptions';
import filterZipCodeOptionsReducer from './filterZipCodeOptions';

export const storeLocatorReducer = combineReducers({
  allStoreReducer,
  filterAreaOptionsReducer,
  filterCityOptionsReducer,
  filterProvinceOptionsReducer,
  filterZipCodeOptionsReducer,
  estimateShippingFeeReducer
});
