import ImageModel from '@models/ImageModel';

export default class VideoCoverImageMode {
  constructor() {
    this.value = new ImageModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this['value'].getData(dataSource['value']);
    }

    return this;
  }
}
