import './SimpleCTA.scss';

import { object } from 'prop-types';
import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import { SIMPLE_CTA_CONTAINER_BOX_ELEMENT, useTruncateDescription } from './hooks/useTruncateDescription';
import SimpleCTAModel from './models/SimpleCTAModel';
import SimpleCTAContentFooter from './SimpleCTAContentFooter';

const SimpleCTA = ({ fields }) => {
  const descriptionElRef = useRef(null);
  const truncateDescription = useTruncateDescription();
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    setLayoutData(new SimpleCTAModel().getData(fields));
  }, []);

  useLayoutEffect(() => {
    const descriptionElement = descriptionElRef?.current;
    if (layoutData && descriptionElement) {
      const truncateDescriptionArgument = {
        descriptionElement: descriptionElement
      };

      document.addEventListener('resize', () => truncateDescription(truncateDescriptionArgument));

      truncateDescription(truncateDescriptionArgument);

      return () => document.removeEventListener('resize', () => truncateDescription(truncateDescriptionArgument));
    }
  }, [descriptionElRef, truncateDescription, layoutData]);

  return layoutData ? (
    <div id={SIMPLE_CTA_CONTAINER_BOX_ELEMENT} className='container simple-cta'>
      <div className='support-centre-box'>
        <div className='support-centre-box__wrapper simple-cta__container'>
          <div className='simple-cta__content'>
            <div className='simple-cta__content-icon'>
              <div className='simple-cta__content-icon__wrapper'>
                <Image field={layoutData?.Image} />
              </div>
            </div>

            <div className='simple-cta__content-body'>
              <Text tag='h3' field={layoutData?.Title} className='support-centre__title' />
              <p ref={descriptionElRef} className='support-centre__description'>
                <Text field={layoutData?.['Description Title']} />
              </p>
            </div>

            <SimpleCTAContentFooter
              buttonText={layoutData?.['Button Text']}
              buttonLink={layoutData?.['Button Url']}
              isButtonPrimary={layoutData?.IsButtonPrimary?.value}
              linkText={layoutData?.['Link Text']}
              linkUrl={layoutData?.['Link Url']}
            />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

SimpleCTA.propTypes = {
  fields: object
};

export default SimpleCTA;
