import './socialNavigation.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { deepCopy } from '@utils/utility';

import SocialNavigationModel from './model/SocialNavigationModel';
import SocialNavigationItem from './SocialNavigationItem';

const SocialNavigation = (props) => {
  const [fields, setFields] = useState(null);

  useEffect(() => {
    const fields = new SocialNavigationModel();

    setFields(fields.getData(deepCopy(props.fields || {})));
  }, []);

  return fields && props.fields ? (
    <div className='social-navigation'>
      <div className='container'>
        {fields.items.map((item) => {
          return <SocialNavigationItem key={item.id} data={item} />;
        })}
      </div>
    </div>
  ) : '';
};

SocialNavigation.propTypes = {
  fields: PropTypes.object
};

export default SocialNavigation;
