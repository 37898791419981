import produce from 'immer';

import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';
import { formatDateToUTC } from '@utils/dateFormatV2';

const initState = {
  infoUser: null
};

const dataCheckoutReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case checkoutProcessTypes.SET_INFO_USER_CHECKOUT:
      draft.infoUser = {
        ...action.payload,
        dob: formatDateToUTC(action.payload.dob)
      };
      break;
    }

    return draft;
  });

export default dataCheckoutReducer;
