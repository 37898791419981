
import * as action from './getPosCustomerIdTypes';

export const getPosCustomerId = (payload) => ({
  type: action.GET_POS_CUSTOMER_ID,
  payload
});

export const getPosCustomerIdSuccess = (payload) => ({
  type: action.GET_POS_CUSTOMER_ID_SUCCESS,
  payload
});

export const getPosCustomerIdFailed = (payload) => ({
  type: action.GET_POS_CUSTOMER_ID_FAILED,
  payload
});
