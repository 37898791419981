import { useFormik } from 'formik';
import { useMemo } from 'react';
import * as Yup from 'yup';

import { useScheduleAppointmentContext } from '../../hooks/useScheduleAppointmentContext';

export const useScheduleAppointmentFormik = (onSubmit) => {
  const { scheduleData, layoutData } = useScheduleAppointmentContext();

  const initialValues = useMemo(() => {
    return {
      storeId: scheduleData.scheduleExperience.storeId,
      date: scheduleData.scheduleExperience.date,
      timeSlot: scheduleData.scheduleExperience.timeSlot,
      guest: scheduleData.scheduleExperience.guest
    };
  }, [scheduleData.scheduleExperience]);

  return useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validationSchema: Yup.object().shape({
      storeId: Yup.string().required(layoutData?.['Select Store Error Message']?.value),
      date: Yup.string().required(layoutData?.['Select Date Error Message']?.value),
      timeSlot: Yup.string().required(layoutData?.['Select TimeSlot Error Message']?.value),
      guest: Yup.number().min(1, layoutData?.['Select Guest Error Message']?.value).required(layoutData?.['Select Guest Error Message']?.value)
    }),
    onSubmit: (values) => {
      if (onSubmit) onSubmit(values);
    }
  });
};
