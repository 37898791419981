import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import AddNewAddress from '../AddNewAddress';
import InfoAddress from '../InfoAddress';
import ChangeShippingAddress from './ChangeShippingAddress';

const ShippingAddress = (props) => {
  const { dataSources, userAddressList } = props;
  const [showAddAddressPopup, setShowAddAddressPopup] = useState(false);
  const [shippingAddressDefault, setShippingAddress] = useState(userAddressList.find((address) => address.IsPrimary));
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  useDidUpdateEffect(() => {
    setShippingAddress(userAddressList.find((address) => address.IsPrimary));

    setShowAddAddressPopup(false);
  }, [userAddressList]);

  return userAddressList?.length ? (
    <div className='my-addresses__section'>
      <div className='my-addresses__section__heading my-account-widget__heading my-account-section__group'>
        <h4 className='my-addresses__section__heading__title'>{dataSources ? <Text field={dataSources['Shipping Address Title']} /> : ''}</h4>
        <span className='my-account-section__btn-link' onClick={() => setShowAddAddressPopup(true)}>
          {dataSources ? <Text field={dataSources['Edit Shipping Text']} /> : ''}
        </span>
      </div>
      {shippingAddressDefault ? (
        <div className='my-addresses__section__content'>
          <h5 className='my-addresses__section__content__address-name'>{shippingAddressDefault.AddressName}</h5>
          <p className='my-addresses__full-name addresses-info__line'>{`${settingGlobal?.FullNameFormat.replace('{LastName}', shippingAddressDefault.LastName).replace('{FirstName}',shippingAddressDefault.FirstName)}`}</p>
          <InfoAddress dataAddresses={shippingAddressDefault} dataSources={dataSources} />
        </div>
      ) : (
        ''
      )}
      <div className='address-component'>
        <ModalComponent
          isShow={showAddAddressPopup}
          titlePopup={dataSources ? <Text field={dataSources['PopUp Select Default Shipping Address Label']} /> : ''}
          onCloseHandler={() => setShowAddAddressPopup(false)}
        >
          <ChangeShippingAddress dataSources={dataSources} userAddressList={userAddressList} />
        </ModalComponent>
      </div>
    </div>
  ) : (
    <div className='my-addresses__section my-address_section--empty'>
      <div className='my-addresses__section__heading my-account-widget__heading my-account-section__group'>
        <h4 className='my-addresses__section__heading__title'>{dataSources ? <Text field={dataSources['Shipping Address Title']} /> : ''}</h4>
        <span className='my-account-section__btn-link disable'>{dataSources ? <Text field={dataSources['Edit Shipping Text']} /> : ''}</span>
      </div>
      <AddNewAddress dataSources={dataSources} />
    </div>
  );
};

ShippingAddress.propTypes = {
  dataSources: PropTypes.object,
  userAddressList: PropTypes.array
};

export default ShippingAddress;
