import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/storeLocator';
import * as storeLocatorTypes from '@redux/actions/storeLocator/storeLocatorTypes';
import { get } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as config from './config';

export const filterAreaListEpic = (action$) =>
  action$.pipe(
    ofType(storeLocatorTypes.GET_FILTER_AREA_LIST),
    switchMap(() => {
      const params = {
      };

      return from(get(config.ACTION_AREA, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getFilterAreaListSuccess(deepCopy(res.data.Areas));
          } else {
            return actions.getFilterAreaListFailed('Failed');
          }
        })
      );
    })
  );

export const filterProvinceListEpic = (action$) =>
  action$.pipe(
    ofType(storeLocatorTypes.GET_FILTER_PROVINCE_LIST),
    switchMap((action) => {
      const params = {
        areaCode: action.payload
      };

      return from(get(config.ACTION_PROVINCE, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getFilterProvinceListSuccess(deepCopy(res.data.Provinces));
          } else {
            return actions.getFilterProvinceListFailed('Failed');
          }
        })
      );
    })
  );

export const filterCityListEpic = (action$) =>
  action$.pipe(
    ofType(storeLocatorTypes.GET_FILTER_CITY_LIST),
    switchMap((action) => {

      return from(get(config.ACTION_CITY, action.payload)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getFilterCityListSuccess(deepCopy(res.data.Cities));
          } else {
            return actions.getFilterCityListFailed('Failed');
          }
        })
      );
    })
  );

export const filterZipCodeEpic = (action$) =>
  action$.pipe(
    ofType(storeLocatorTypes.GET_FILTER_ZIP_CODE),
    switchMap((action) => {

      return from(get(config.ACTION_ZIPCODE, action.payload)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getFilterZipCodeSuccess(deepCopy(res.data.ZipCodes));
          } else {
            return actions.getFilterZipCodeFailed('Failed');
          }
        })
      );
    })
  );

export const getShippingFeeEpic = (action$) =>
  action$.pipe(
    ofType(storeLocatorTypes.GET_ESTIMATE_SHIPPING_FEE),
    switchMap((action) => {

      return from(get(config.GET_SHIPPING_FEE, action.payload)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getEstimateShippingFeeSuccess(deepCopy(res.data.ShippingFee));
          } else {
            return actions.getEstimateShippingFeeFailed('Failed');
          }
        })
      );
    })
  );

