export default class StoreFilterProvinceListModel {
  constructor() {
    this['Id'] = '';

    this['label'] = '';

    this['value'] = '';

    this['ProvinceCode'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ? dataSource['Id'] : this['Id'];

      this['value'] = dataSource['ProvinceCode'] ? dataSource['ProvinceCode'] : this['value'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProvinceName')) {
      this['label'] = dataSource['ProvinceName'] ? dataSource['ProvinceName'] : this['label'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProvinceCode')) {
      this['ProvinceCode'] = dataSource['ProvinceCode'] ? dataSource['ProvinceCode'] : this['ProvinceCode'];
    }

    return this;
  }
}
