import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { validateOtpFailure, validateOtpSuccess } from '@redux/actions/campaignRegister/validateOtp';
import { VALIDATE_OTP_CODE_START } from '@redux/actions/campaignRegister/validateOtp/types';
import { postAsObservable } from '@services/genericService';

import { getContactStart } from '../../../actions/campaignRegister/getContact';
import { VALIDATE_OTP_CODE_ENDPOINT } from './config';

const validateOtpEpic = (action$) =>
  action$.pipe(
    ofType(VALIDATE_OTP_CODE_START),
    switchMap((action) => {
      const params = {
        CampaignId: action.payload.campaignId,
        MobileNumber: action.payload.phoneNumber,
        OTP: action.payload.optCode
      };

      return postAsObservable(VALIDATE_OTP_CODE_ENDPOINT, params).pipe(
        switchMap((res) => {
          if (res.data.StatusCode === 0) {
            const getContactPayload = {
              emailAddress: action.payload.email
            };
            if (action.payload.isMissingPhoneNumber) {
              getContactPayload.mobileNumber = action.payload.phoneNumber;
            }
            if (action.payload.isLoginSuccess) {
              return of(validateOtpSuccess(res.data), getContactStart(getContactPayload));
            } else {
              return of(validateOtpSuccess(res.data));
            }
          } else {
            return of(validateOtpFailure(res.data || 'Failed'));
          }
        })
      );
    }),
    catchError((error) => of(validateOtpFailure(error.message || 'Failed')))
  );

export default validateOtpEpic;
