import PropTypes from 'prop-types';
import React from 'react';

const BundleBOLabels = ({data}) => {
  return (
    <span className={`list-tags__item osim-label ${data.IsSpecial ? 'osim-label--highlight' : 'osim-label--default'} `}>{data.Name}</span>
  );
};

BundleBOLabels.propTypes = {
  data: PropTypes.object
};

export default BundleBOLabels;
