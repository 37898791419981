import { bool, func, number, object, string } from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { GA4_LOCAL_STORAGE_KEY } from '@utils/constant';
import { useQuery } from '@utils/customsHook/useQuery';
import global from '@utils/global';

import { useCheckCTASOCart } from '../../../hooks';
import { EVENT_ADD, EVENT_REMOVE, EVENT_REMOVE_ALL } from '../configs';
import ConfirmRemoveCart from '../ConfirmRemoveCart';

const CPEditProductBtn = ({
  cartItemInfo,
  dataSources,
  currencyInformation,
  indexGA4,
  isClientTelingUser,
  isShowModal,
  isWishListProduct,
  onOpenModal,
  onCloseModal,
  onRemoveCartLine
}) => {
  const history = useHistory();
  const query = useQuery();
  const checkDisableCTASOCartService = useCheckCTASOCart();
  const { isEditingSOCart } = useSelector((state) => state.cpCartDetailsReducer);
  const CartStatus = useSelector((state) => state?.productReducer?.cartDetailReducer?.currentCartCustom?.CartStatus);

  const isGA4 = useMemo(() => {
    return global.getIsGA4();
  }, []);

  const isDisabledSOCartButton = useMemo(() => {
    return checkDisableCTASOCartService(CartStatus);
  }, [CartStatus]);

  const containRefunableDeposit = (promotionCartLines) =>
    promotionCartLines.some((promotionCartLine) => {
      return promotionCartLine.Type === 7;
    });

  const addOrRemoveCartEventGA4 = (event, isQuantity) => {
    const paramGA4 = global.getGA4Params();
    const lstKeyFound = Object.keys(paramGA4).find((lstName) => lstName === cartItemInfo?.VariantId);
    const lstNameItem = paramGA4[lstKeyFound];
    //New Logic ON/OFF GA, GA4
    if (isGA4) {
      let dataConvert = [];

      dataConvert.push(cartItemInfo);

      const discount = cartItemInfo?.LineDiscountAmount / Number(cartItemInfo?.Quantity);
      const discountAll = cartItemInfo?.LineDiscountAmount;

      const params = {
        ecommerce: {
          currency: currencyInformation?.Name,
          value: cartItemInfo?.LineFinalTotalAmount - cartItemInfo?.LineFinalTotalAmount / Number(cartItemInfo?.Quantity),
          items: global.getItemsCart(dataConvert, '', isQuantity, currencyInformation?.Name, indexGA4, discount, '', lstNameItem)
        }
      };

      if (event === EVENT_ADD) {
        params.ecommerce.value = cartItemInfo?.LineFinalTotalAmount + cartItemInfo?.LineFinalTotalAmount / Number(cartItemInfo?.Quantity);

        new GoogleAnalytic().gAnalytic4('event', 'add_to_cart', params);
      }

      if (event === EVENT_REMOVE) {
        new GoogleAnalytic().gAnalytic4('event', 'remove_from_cart', params);
      }

      if (event === EVENT_REMOVE_ALL) {
        const paramsRemoveAll = {
          ecommerce: {
            currency: currencyInformation?.Name,
            // value: cartItemInfo?.LineFinalTotalAmount,
            items: global.getItemsCart(
              dataConvert,
              '',
              cartItemInfo?.Quantity,
              currencyInformation?.Name,
              indexGA4,
              discountAll,
              'REMOVE_ALL',
              lstNameItem
            )
          }
        };

        new GoogleAnalytic().gAnalytic4('event', 'remove_from_cart', paramsRemoveAll);

        delete paramGA4[lstKeyFound];

        window.localStorage.setItem(GA4_LOCAL_STORAGE_KEY, JSON.stringify(paramGA4));
      }
    }
  };

  const onEditProduct = useCallback(() => {
    if (dataSources && cartItemInfo) {
      const { SubLines, ProductPageId, ExternalCartLineId, PromotionCartLines } = cartItemInfo;
      const moveToPath = SubLines?.length ? dataSources['Edit Bundle Buying Option Link'] : dataSources['Buying Option Page Link'];
      const searchParamsObject = {};
      if (ProductPageId) {
        if (SubLines?.length) searchParamsObject.bundlePageId = ProductPageId;
        else searchParamsObject.productPageId = ProductPageId;
      }
      if (ExternalCartLineId) searchParamsObject.cartLineId = ExternalCartLineId;
      if (containRefunableDeposit(PromotionCartLines)) searchParamsObject.datatype = 'RefunableDeposit';
      if (Object.keys(searchParamsObject)) {
        for (const [key, value] of Object.entries(searchParamsObject)) {
          query.set(key, value);
        }
      }

      window.location.href = `${moveToPath}?${query.toString()}`;
    }
  }, [dataSources, cartItemInfo]);

  const isShowComponent = useMemo(() => {
    return isClientTelingUser && isEditingSOCart && !isDisabledSOCartButton && cartItemInfo?.IsEditable;
  }, [isClientTelingUser, isEditingSOCart, isDisabledSOCartButton, cartItemInfo]);

  return isShowComponent ? (
    <>
      <div className='col-12 cart-item__btn'>
        <div className='cart-item__btn-edit'>
          <button onClick={onEditProduct} className='btn btn-primary cart-item__btn-edit'>
            <Text field={dataSources['Edit Button Text']} />
          </button>
        </div>
        <button className='btn-link cart-item__btn-remove' onClick={onOpenModal}>
          <Text field={dataSources['Remove Button Text']} />
        </button>
      </div>
      <ModalComponent
        isShow={isShowModal}
        classNameCustom='custom-class'
        titlePopup={<Text field={dataSources['Remove Product Title']} />}
        onCloseHandler={onCloseModal}
      >
        <ConfirmRemoveCart
          dataSources={dataSources}
          isWishListProduct={isWishListProduct}
          dataCartLineItem={cartItemInfo}
          confirmRemoveCartEvt={onRemoveCartLine}
          addOrRemoveCartEventGA4={addOrRemoveCartEventGA4}
        />
      </ModalComponent>
    </>
  ) : (
    <></>
  );
};

CPEditProductBtn.propTypes = {
  cartItemInfo: object,
  dataSources: object,
  currencyInformation: object,
  indexGA4: number,
  isClientTelingUser: string,
  isShowModal: bool,
  isWishListProduct: bool,
  onOpenModal: func,
  onCloseModal: func,
  onRemoveCartLine: func
};

export default CPEditProductBtn;
