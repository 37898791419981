import IncludedProductStockStatusModel from './IncludedProductStockStatusModel';

export default class IncludedProductStockModel {
  constructor() {
    this['Purchasable'] = false;

    this['PurchasableLabel'] = '';

    this['PurchasableLimit'] = 0;

    this['StockQuantity'] = 0;

    this['StockStatus'] = new IncludedProductStockStatusModel();

    this.LimitStockQuantity = 0;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Purchasable')) {
      this['Purchasable'] = dataSource['Purchasable'] ? dataSource['Purchasable'] : this['Purchasable'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PurchasableLabel')) {
      this['PurchasableLabel'] = dataSource['PurchasableLabel'] ? dataSource['PurchasableLabel'] : this['PurchasableLabel'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PurchasableLimit')) {
      this['PurchasableLimit'] = dataSource['PurchasableLimit'] ? dataSource['PurchasableLimit'] : this['PurchasableLimit'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StockQuantity')) {
      this['StockQuantity'] = dataSource['StockQuantity'] ? dataSource['StockQuantity'] : this['StockQuantity'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StockStatus')) {
      this['StockStatus'].getData(dataSource['StockStatus']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LimitStockQuantity')) {
      this.LimitStockQuantity = dataSource.LimitStockQuantity;
    }

    return this;
  }
}
