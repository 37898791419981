import produce from 'immer';

import {
  GET_FREE_GIFT_DATA_FAILED,
  GET_FREE_GIFT_DATA_START,
  GET_FREE_GIFT_DATA_SUCCESS,
  SET_FREE_GIFT_DATA_NULL
} from '@redux/actions/getFreeGiftData/getFreeGiftDataTypes';

const initState = {
  isLoading: false,
  freeGiftData: null,
  error: null
};

const getFreeGiftReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_FREE_GIFT_DATA_START:
      draft.isLoading = true;
      break;

    case GET_FREE_GIFT_DATA_SUCCESS:
      draft.isLoading = false;

      draft.freeGiftData = action.payload;
      break;

    case GET_FREE_GIFT_DATA_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;

    case SET_FREE_GIFT_DATA_NULL:
      draft.freeGiftData = null;
      break;
    }

    return draft;
  });

export default getFreeGiftReducer;
