import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { deepCopy } from '../../../utils/utility';

const AnimatedBannerWithImages = ({ fields, children }) => {
  const [imageToShow, setImageToShow] = useState([]);
  const BASE_IMAGE_NEED = 14;

  const onDuplicateImageFields = (data) => {
    let imageFields = deepCopy(data);
    let imageFieldsSize = imageFields.length;

    imageFieldsSize++;

    if (imageFieldsSize < BASE_IMAGE_NEED) {
      imageFields = imageFields.concat(imageFields);

      setImageToShow(imageFields);

      onDuplicateImageFields(imageFields);
    }

    return imageFields;
  };

  useEffect(() => {
    onDuplicateImageFields(fields);
  }, [fields]);

  return React.cloneElement(children, { fields: imageToShow });
};

AnimatedBannerWithImages.propTypes = {
  fields: any,
  children: any
};

export default AnimatedBannerWithImages;
