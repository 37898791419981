import './ExploreOSIMProducts.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';

import ExploreOSIMProductsModel from './model/ExploreOSIMProductsModel';

const ExploreOSIMProducts = (props) => {
  const { fields } = props;
  const MAXIMUM_SLIDES_DESKTOP = 5;
  const MAXIMUM_SLIDES_TABLET = 4;

  const params = {
    slidesPerView: 2.7,
    centeredSlides: false,
    spaceBetween: 50,
    watchOverflow: true,
    loop: true,
    navigation: {
      nextEl: '.swiper-button--small.swiper-button-next',
      prevEl: '.swiper-button--small.swiper-button-prev',
    },
    breakpoints: {
      1400: {
        slidesPerView: MAXIMUM_SLIDES_DESKTOP
      },

      992: {
        slidesPerView: MAXIMUM_SLIDES_TABLET
      },

      768: {
        slidesPerView: MAXIMUM_SLIDES_TABLET -1
      }
    },
    shouldSwiperUpdate: true
  };

  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new ExploreOSIMProductsModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    <div className='explore-product'>
      <div className='container'> 
        <Text field={ layoutData?.['Title In Store Label']} tag='h2' className='experience-title explore-product__heading' />
      </div>
      <div className='explore-product__slide'>
        <SwipeComponent param={params}>
          {layoutData?.['Categories']?.map((item, index) => (
            <div className='explore-product__slide__item' key={index}>
              <div className='explore-product__slide__item__image'>
                <Link field={{ href: item?.url }}>
                  <Image field={item?.fields?.['Menu Image']} className='explore-product-image' />
                </Link>
              </div>
              <Link field={{ href: item?.url }}>
                <Text field={item?.fields?.Title} tag='h5' className='explore-product__slide__item__text' />
              </Link>
            </div>
          ))}
        </SwipeComponent>
      </div>
    </div>
  ) : (<></>);
};

ExploreOSIMProducts.propTypes = {
  fields: PropTypes.object
};

export default ExploreOSIMProducts;
