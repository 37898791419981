export const STYLE_DEFAULT = {
  display: 'inline-block',
  background: 'linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%)',
  color: '#fff',
  width: 200,
  paddingTop: 10,
  paddingBottom: 10,
  borderRadius: 2,
  border: '1px solid transparent',
  fontSize: 16,
  fontWeight: 'bold',
  fontFamily: '"proxima-nova", "Helvetica Neue", Arial, Helvetica, sans-serif'
};

export const RESPONSE_TYPE = 'code';

export const SCOPE_DEFAULT = 'user_profile';

export const BUTTON_TEXT = 'Login with Instagram';

export const NAME_TAG = 'button';

export const TYPE = 'button';

export const REDIRECT_DEFAULT = '/';

export const API_AUTHENTICATION_INSTAGRAM = 'https://api.instagram.com/oauth/authorize';
