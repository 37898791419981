import './GiftUnavailable.scss';

import PropTypes from 'prop-types';
import React from 'react';

const GiftUnavailable = ({onHandlerGiftUnavailable}) => {
  return (
    <div className='gift-unavailable-popup'>
      <h2 className='gift-unavailable-popup__title text-center'>GIFT UNAVAILABLE</h2>
      <div className='gift-unavailable-popup__group'>
        <div className='gift-unavailable-popup__desc'>
          <p>You cannot redeem the gift and your previous spin is still reserved. Please try spinning again.</p>
        </div>
        <div className='gift-unavailable-popup__btn text-center'>
          <div className='gift-unavailable-popup__btn__item'>
            <button type='button' className='btn btn-primary'>Spin again</button>
          </div>
          <div className='gift-unavailable-popup__btn__item'>
            <span className='btn-link-text' onClick={() => onHandlerGiftUnavailable(false)}>Cancel</span>
          </div>
        </div>
      </div>
    </div>
  );
};

GiftUnavailable.propTypes = {
  onHandlerGiftUnavailable: PropTypes.any
};

export default GiftUnavailable;