export default class ProductCardStockStatusModel {
  constructor() {
    this.Value = '';

    this.Name = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Value')) {
      this.Value = dataSource.Value;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name;
    }

    return this;
  }
}
