import produce from 'immer';

import ProductVariantModel from '@components/Product/VariantDetail/model/ProductVariantModel';
// Import action types
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  basePrice: '',
  currencyCode: '',
  allVariants: [],
  isSetData: false
};

function updateVariantSelected(variantName, variantArray) {
  return variantArray.find((variant) => variant.name === variantName);
}

const productVariantDetail = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.SET_STATUS_PRODUCT_DETAIL_VARIANT:
      draft.isSetData = true;
      break;

    case productTypes.SET_PRODUCT_DETAIL_VARIANT:
      draft.basePrice = deepCopy(action.Price.SellingPrice || '');

      draft.currencyCode = deepCopy(action.Price.CurrencyCode || '');

      draft.allVariants = deepCopy(action.Price.VariantPrice || []);

      if (draft.allVariants?.length) {
        draft.allVariants = action.payload.map((item) => ({
          ...item,
          ...draft.allVariants.find(
            (variant) => variant.VariantId === item.name
          )
        }));
      } else {
        draft.allVariants = action.payload.map((item) => ({
          ...item,
          ListPrice: '',
          SellingPrice: '',
        }));
      }

      draft.variantSelected = new ProductVariantModel().getData(updateVariantSelected(action.productName, draft.allVariants));
      break;

    case productTypes.CHANGE_PRODUCT_DETAIL_VARIANT:
      draft.variantSelected = deepCopy(
        updateVariantSelected(action.payload, draft.allVariants)
      );
    }

    return draft;
  });

export default productVariantDetail;
