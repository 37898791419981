import SitecoreTextModel from '@models/SitecoreTextModel';

import ProductSpecificationsModel from './ProductSpecificationsModel';


export default class ProductDetaiSpecModel {
  constructor() {
    this['Product Specifications'] = new ProductSpecificationsModel();
    
    this['Title'] = new SitecoreTextModel();

  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Specifications')) {      
      this['Product Specifications'].getData(dataSource['Product Specifications'] || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {      
      this['Title'].getData(dataSource['Title'] || {});
    }

    return this;
  }
}
