import './MemberEventsListing.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import EventItem from './EventItem';
import MemberEventListingModel from './Models/MemberEventListingModel';

const MemberEventListing = ({ fields }) => {
  const [fieldsData, setFieldsData] = useState(null);

  useEffect(() => {
    const memberEventListingModel = new MemberEventListingModel();

    setFieldsData(memberEventListingModel.getData(deepCopy(fields) || {}));
  }, []);

  return fieldsData ? (
    <div className='member-event'>
      <div className='container'>
        <div className='container my-account-header__heading animated-slow fadeIn delay-100'>
          <Text tag='h4' className='my-account-header__heading__title' field={fieldsData?.['Title']}/>
        </div>
        {fieldsData?.HappeningNow.length ? (
          <div className='member-event__wrap'>
            <div className='member-event__title'>
              <Text className='title' field={fieldsData['Happening Now Title']} />
            </div>
            {fieldsData?.HappeningNow.map((item) => <EventItem key={item.EventId} fieldsData={fieldsData} data={item} />)}
          </div>
        ) : (<></>)}
        {fieldsData?.Upcoming.length ? (
          <div className='member-event__wrap'>
            <div className='member-event__title title-upcoming'>
              <Text className='title' field={fieldsData?.['Upcoming Title']} />
            </div>
            {fieldsData?.Upcoming.map((item) => <EventItem key={item.EventId} fieldsData={fieldsData} data={item} isUpcoming={true} />)}
          </div>
        ) : (<></>)}
      </div>
    </div>
  ) : (
    <></>
  );
};

MemberEventListing.propTypes = {
  fields: any
};

export default MemberEventListing;
