import React from 'react';

const ReviewsSummaryShimmer = () => {
  return (
    <div className='review-listing review-listing--limited skeleton__item'>
      <div className='review-listing__list'>
        <div className='review-listing__item'>
          <div className='product-review-content'>
            <div className='product-review-content__star-rating'>
              <span className='text-line-30' />
            </div>
            <div className='product-review-content__review-title'>
              <span className='text-line-20' />
            </div>
            <div className='product-review-content__info'>
              <span className='text-line-20' />
            </div>
            <div className='product-review-content__review-detail'>
              {/* <span className='product-review-content__review-detail__quote-icon quote-icon' /> */}
              <p>
                <span className='text-line-20' />
              </p>
              <p>
                <span className='text-line-20' />
              </p>
              <p>
                <span className='text-line-20' />
              </p>
              <p>
                <span className='text-line-20' />
              </p>
              <p>
                <span className='text-line-20' />
              </p>
              <p>
                <span className='text-line-20' />
              </p>
              <p>
                <span className='text-line-20' />
              </p>
            </div>
            <div className='product-review-content__media'>
              <div className='review-media__list'>
                <div className='review-media__item'>
                  <span className='image-line' />
                </div>
                <div className='review-media__item'>
                  <span className='image-line' />
                </div>
                <div className='review-media__item'>
                  <span className='image-line' />
                </div>
              </div>
            </div>
            <div className='product-review-content__review-recommend'>
              <span className='text-line-20' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewsSummaryShimmer;
