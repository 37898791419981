import QuestionModel from '@components/ClientPortal/ReviewCustomerInformation/model/QuestionModel';
import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

const IMAGE_ARR = ['Icon', 'Remove Product Icon'];

const TEXT_ARR = [
  'Add New Product Label', 
  'Captured Label', 
  'Product Interest Label', 
  'Purchase On The Spot Label', 
  'Viewed Product Label',
  'No Button Text',
  'Require Product Warning Text',
  'Warning Message',
  'Warning Title',
  'Yes Button Text',
  'Have Not Save Warning Message',
  'Other Reason Label',
  'Require Other Reason Text',
  'Upsell Select Product Label',
  'Select Product Label',
  'Remove Product Message'
];

export default class QAProductInterestListingModel {
  constructor() {
    IMAGE_ARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['Questions'] = [];

    this['Accessories Product'] = [];
  }
  getData(dataSource) {
    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Questions')) {
      Global.removeEmptyObj(dataSource['Questions']).map((element) => {
        this['Questions'].push(new QuestionModel().getData(deepCopy(element) || {}));
      });
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Accessories Product')) {
      let newItemsList = [];

      dataSource['Accessories Product'].map((element) => {
        newItemsList.push(deepCopy(element));
      });

      this['Accessories Product'] = newItemsList;
    }

    return this;
  }
}
