import SitecoreTextModel from '@models/SitecoreTextModel';

export default class NewsletterPreferenceWidgetModel {
  constructor() {
    this['Unsubscribe Button Text'] = new SitecoreTextModel();

    this['Subscribe Button Text'] = new SitecoreTextModel();

    this['Store to List'] = {};

    this['Title'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (Object.prototype.hasOwnProperty.call(dataSource, 'Unsubscribe Button Text')) {
      this['Unsubscribe Button Text'].getData(dataSource['Unsubscribe Button Text'] || {});
    }
    if (Object.prototype.hasOwnProperty.call(dataSource, 'Subscribe Button Text')) {
      this['Subscribe Button Text'].getData(dataSource['Subscribe Button Text'] || {});
    }
    if (Object.prototype.hasOwnProperty.call(dataSource, 'Store to List')) {
      this['Store to List'] = dataSource['Store to List'];
    }
    if (Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title'] || {});
    }

    return this;
  }
}
