import produce from 'immer';

import * as myAccountTypes from '@redux/actions/myAccount/myAccountTypes';

const initState = {
  isLoading: false,
  message: null,
  isError: null,
  isUpdateSuccess: false
};

const updateAccountReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case myAccountTypes.UPDATE_USER_PROFILE:
      draft.isLoading = true;

      draft.isError = null;

      draft.isUpdateSuccess = false;
      break;

    case myAccountTypes.UPDATE_USER_PROFILE_SUCCESS:
      draft.isLoading = false;

      draft.isUpdateSuccess = true;

      draft.message = action.payload.Message;
      break;

    case myAccountTypes.UPDATE_USER_PROFILE_FAILED:
      draft.isLoading = false;

      draft.message = action.error.Message;

      draft.isError = true;
      break;
    }

    return draft;
  });

export default updateAccountReducer;
