import { deepCopy } from '@utils/utility';

import ThreeColumnBannerItemModel from './ThreeColumnBannerItemModel';

export default class ThreeColumnBannerFieldsModel {
  constructor() {
    this.Images = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      let imagesTemp = [];

      dataSource['Images'].map((item) => {
        imagesTemp.push(new ThreeColumnBannerItemModel().getData(deepCopy(item) || {}));
      });

      this['Images'] = imagesTemp;
    }

    return this;
  }
}
