import './MyOrders.scss';

import PropsType from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Fragment } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { getActivateOrders, getPastOrders } from '@redux/actions/myOrders';
import { RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import {ENUM_STATUS} from '@utils/constant';
import { deepCopy } from '@utils/utility';

import MyOrderModel from './model/MyOrderModel';
import MyOrdersLastestApi from './model/MyOrdersLastestApi';
import MyOrdersItemShimmer from './MyOrdersItemShimmer';

const TAB_EVENT = {
  ACTIVE_ORDERS_TAB_EVENT: 'active-orders',
  PAST_ORDERS_TAB_EVENT: 'past-orders'
};
const DISPLAY_NAME = '{first-product-name}';
const LENGTH = '{other-product-count}';

const MyOrders = ({ fields }) => {
  const [inputData, setInputData] = useState(null);
  const [tabEvent, setTabEvent] = useState(TAB_EVENT.ACTIVE_ORDERS_TAB_EVENT);
  const dispatch = useDispatch();

  const activeOrdersData = useSelector(
    (state) => state.myOrderReducer.activateOrdersReducer.data || [],
  );

  const isLoadingActice = useSelector((state) => state.myOrderReducer.activateOrdersReducer?.isLoading);

  const isLoadingPast = useSelector((state) => state.myOrderReducer.pastOrdersReducer?.isLoading);

  const pastOrdersData = useSelector(
    (state) => state.myOrderReducer.pastOrdersReducer.data || []
  );

  const [tabContent, setTabContent] = useState([]);

  useEffect(() => {
    const model = new MyOrderModel();
    
    setInputData(model.getData(deepCopy(fields)));

  }, []);

  useMemo(() => {
    setTabContent([
      {
        eventKey: TAB_EVENT.ACTIVE_ORDERS_TAB_EVENT,
        title: inputData?.['Active Orders Tab Label']?.value || ''
      },
      {
        eventKey: TAB_EVENT.PAST_ORDERS_TAB_EVENT,
        title: inputData?.['Past Orders Tab Label']?.value || ''
      }
    ]);

  }, [inputData]);

  useEffect(() => {
    if (tabEvent === TAB_EVENT.ACTIVE_ORDERS_TAB_EVENT) {
      dispatch(getActivateOrders());
    }
    if (tabEvent === TAB_EVENT.PAST_ORDERS_TAB_EVENT) {
      dispatch(getPastOrders());
    }
  }, [tabEvent]);

  const getClassForStatus = (statusName) => {
    return ENUM_STATUS.find(item => item.status === statusName)?.class || '';
  };

  const tabData = useMemo(() => {
    return tabEvent === TAB_EVENT.ACTIVE_ORDERS_TAB_EVENT ? activeOrdersData : pastOrdersData;

  }, [activeOrdersData, pastOrdersData]);

  const getProductName = (firstProduct, remainProduct) => {
    const productSummaryLabelTmp = remainProduct <= 0 ? deepCopy(inputData['Product Summary Label']) : deepCopy(inputData['Product Summary Label With Other Product']);

    productSummaryLabelTmp.value = productSummaryLabelTmp.value
      .replace(DISPLAY_NAME, firstProduct)
      .replace(LENGTH, remainProduct);
    
    return productSummaryLabelTmp;
  };

  return inputData && (
    <div className='my-orders'>
      <div className='my-orders__tabs'>
        <Tabs onSelect={setTabEvent}>
          {
            tabContent.map((tab, index) => {
              return (
                <Tab key={index} eventKey={tab.eventKey} title={tab.title}>
                  <div className='my-orders__tabs__content'>
                    <div className='container'>
                      {!isLoadingActice && !isLoadingPast ? (
                        <Fragment>
                          {
                            tabData.length ? tabData.map((item, index) => {
                              let itemOrder = new MyOrdersLastestApi().getData(item);

                              return (
                                <div
                                  key={index}
                                  className={['my-orders__tabs__content__item-wrapper', itemOrder?.PosOriginOrderId ? 'line-indent' : ''].join(' ')}
                                >
                                  <div className='my-orders__tabs__content__item my-account-section__content orders-widget__content animated-slow fadeIn delay-100'>
                                    <div className='my-account-section__group'>
                                      <h4 className='orders-widget__content__id orders-widget__content__line'>
                                        {inputData['Order Label'].value}#{itemOrder.TrackingNumber}
                                      </h4>
                                      <a
                                        href={`/my-account/my-orders/Order-Detail?OrderId=${itemOrder.OrderId}`}
                                        className='my-account-section__content__view-detail orders-widget__content__view-detail'
                                      >
                                        {inputData?.['View Details Text']?.value || ''}
                                      </a>
                                    </div>
                                    <span className='orders-widget__content__order-date orders-widget__content__line'>
                                      {inputData['Order Placed Label'].value}: {itemOrder.OrderDate || ''}
                                    </span>
                                    <h5 className='orders-widget__content__product orders-widget__content__line'>
                                      <RichText field={getProductName(itemOrder.FirstProductInOrder, itemOrder.RemainingProductsInOrder)} />
                                    </h5>
                                    {inputData?.['Refer With Order Label']?.value?.length && item?.RefundOrderId && item?.RefundOrderEntityId ? (
                                      <div className='orders-widget__content__refund orders-widget__content__line'>
                                        <Text
                                          className='orders-widget__content__refund__text'
                                          tag='span'
                                          field={inputData['Refer With Order Label']}
                                        />
                                        <a href={`${window.location.pathname}/Order-Detail?OrderId=${item?.RefundOrderEntityId}`}>
                                          {item?.RefundOrderId}
                                        </a>
                                      </div>
                                    ) : (
                                      <></>
                                    )}
                                    <span className='orders-widget__content__price orders-widget__content__line'>
                                      {inputData['Order Total Label'].value} {itemOrder.TotalOrderPriceWithCurrency}
                                    </span>
                                    <div className='orders-widget__content__status'>
                                      <span className={`orders-widget__content__status__icon status--${itemOrder.StatusId}`}></span>
                                      <span className='orders-widget__content__status__text'>{itemOrder.StatusDisplayName || ''}</span>
                                    </div>
                                  </div>
                                </div>
                              );
                            }) : <p>{inputData['Have No Order Label'].value} {tab.title}</p>
                          }
                        </Fragment>
                      ) : (
                        <LoadingShimmer itemNumb={10}>
                          <MyOrdersItemShimmer></MyOrdersItemShimmer>
                        </LoadingShimmer>
                      )}
                    </div>
                  </div>
                </Tab>
              );
            })
          }
        </Tabs>
      </div>
    </div>
  );
};

MyOrders.propTypes = {
  fields: PropsType.any
};

export default MyOrders;