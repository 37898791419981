import Proptypes from 'prop-types';
import React, { Fragment } from 'react';

import BuyingBundleAccordion from '@components/Share/BuyingBundleAccordion';

import { FREEGIFT_BUNDLE_EVENT_KEY } from '../config';

const BundleBOFreeGifts = ({dataItem, freeGiftSelected, dataText, onSelectFreeGiftHandler, checkValidate, isEditing}) => {

  return dataItem.length > 0 ? (
    <Fragment>
      <BuyingBundleAccordion
        data={dataItem}
        headerType={'SECONDTYPE'}
        eventKey={FREEGIFT_BUNDLE_EVENT_KEY}
        isOpenItem = {isEditing ? false : true}

        onAction = {onSelectFreeGiftHandler}
        selectedItemsName = {freeGiftSelected}
        dataText={dataText}
        labelText={dataText['Choose Your Free Gift Label'].value} 
        changeText={dataText['Change Text'].value}
        outStockText={dataText['Out Stock Text'].value}
        errorMsg={dataText['Please Select Your Free Gift Message'].value}
        isShowError={checkValidate && freeGiftSelected.length !== dataItem.length}

        freeGiftSelected={freeGiftSelected}
      />
    </Fragment>
  ) : null;
};

BundleBOFreeGifts.propTypes = {
  dataItem: Proptypes.any,
  dataText: Proptypes.any,
  onSelectFreeGiftHandler: Proptypes.any,
  checkValidate: Proptypes.any,
  freeGiftSelected: Proptypes.any,
  isEditing: Proptypes.bool,
};

export default BundleBOFreeGifts;