import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const ModelSpec = ({ dataSources, isViewTitle }) => {
  return !isViewTitle ? (
    <div className='product-specification__collapse__content'>
      <div className='product-specification__collapse__content__list'>
        <div className='row'>
          {dataSources['Model Spec']['Model Number Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Model Spec']['Model Number Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Model Spec']['Model Number Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Model Spec']['Voltage Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Model Spec']['Voltage Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Model Spec']['Voltage Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Model Spec']['Power Consumption Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Model Spec']['Power Consumption Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Model Spec']['Power Consumption Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Model Spec']['Automatic Massage Programmes Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Model Spec']['Automatic Massage Programmes Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Model Spec']['Automatic Massage Programmes Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Model Spec']['Auto Timer Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Model Spec']['Auto Timer Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Model Spec']['Auto Timer Value']} />
            </dd>
          </dl> : ''}
          {dataSources['Model Spec']['Reclining Angle Value'].value ? <dl className='info-content__item col-12 col-sm-6'>
            <dt className='info-content__item__label'><Text field={dataSources['Model Spec']['Reclining Angle Text']} /></dt>
            <dd className='info-content__item__value'>
              <Text field={dataSources['Model Spec']['Reclining Angle Value']} />
            </dd>
          </dl> : ''}
        </div>
      </div>
    </div>
  ) : <Text field={dataSources['Model Spec']['Model Specifications Title Text']}/>;
};

ModelSpec.propTypes = {
  dataSources: PropTypes.object,
  isViewTitle: PropTypes.bool
};

export default ModelSpec;
