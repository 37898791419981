import SitecoreTextModel from '@models/SitecoreTextModel';

export default class RelationshipFieldsModel {
  constructor() {
    this['Field Name'] = new SitecoreTextModel();

    this['Relation Title'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Field Name')) {
      this['Field Name'].getData(dataSource['Field Name']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Relation Title')) {
      this['Relation Title'].getData(dataSource['Relation Title']);
    }

    return this;
  }
}
