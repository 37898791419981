import './OrdersSearch.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image } from '@sitecore-jss/sitecore-jss-react';

const OrdersSearch = ({
  onLayoutData, 
  onSearchOrdersListing
}) => {
  const [searchResult, setSearchResult] = useState('');

  const onEnterHandler = (event) => {
    if (event.key == 'Enter') {
      onSearchOrdersListing(searchResult);
    }
  };

  return onLayoutData && (
    <div className='order-search'>
      <div className='container tablet-container'>
        <div className='order-search__box'>
          <input 
            type='text'
            value={searchResult}
            className='order-search__box__input'
            placeholder={onLayoutData['Search order']?.value}
            onChange={(e) => {
              setSearchResult(e.target.value);
            }}
            onKeyDown={(event) => onEnterHandler(event)}
          />
          <span className='order-search__box__icon cp-icon' onClick={() => onSearchOrdersListing(searchResult)}>
            <Image field={onLayoutData?.['Search icon']} />
          </span>
        </div>
      </div>
    </div>
  );
};

OrdersSearch.propTypes = {
  onLayoutData: PropTypes.any,
  onSearchOrdersListing: PropTypes.any,
  onisResetSearch: PropTypes.bool
  // onChangeSearch: PropTypes.any
};

export default OrdersSearch;
