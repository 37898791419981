import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';

import { getHadNotificationsStart } from '@redux/actions/cpNotificationPopup/getHadNotifications';
import { readSingleNotificationFailure, readSingleNotificationSuccess } from '@redux/actions/cpNotificationPopup/readSingleNotification';
import { READ_SINGLE_NOTIFICATION_START } from '@redux/actions/cpNotificationPopup/readSingleNotification/types';
import { postAsObservable } from '@services/genericService';

import { defaultFetchLogsParams, DELAY_TIME_TO_CALL_ACTIONS } from '../configs';
import { READ_SINGLE_NOTIFICATION_ENDPOINT } from './configs';

const readSingleNotificationEpic = (action$) =>
  action$.pipe(
    ofType(READ_SINGLE_NOTIFICATION_START),
    switchMap((action) => {
      const params = {
        IdNotification: action.payload
      };

      return postAsObservable(READ_SINGLE_NOTIFICATION_ENDPOINT, params).pipe(
        switchMap((res) => {
          if (res.data.StatusCode === 200) {
            return of(readSingleNotificationSuccess(res.data), getHadNotificationsStart(defaultFetchLogsParams)).pipe(
              delay(DELAY_TIME_TO_CALL_ACTIONS)
            );
          } else {
            return of(readSingleNotificationFailure(res.data.Message || 'Failed'));
          }
        }),
        catchError((error) => of(readSingleNotificationFailure(error || 'Failed')))
      );
    })
  );

export default readSingleNotificationEpic;
