export default class PromotionCartUpdateModel {
  constructor() {
    this.LineUniqueId = '';

    this.CatalogName = '';

    this.ProductId = '';

    this.VariantId = '';

    this.Type = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExternalCartLineId')) {
      this.LineUniqueId = dataSource.ExternalCartLineId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this.ProductId = dataSource.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantId')) {
      this.VariantId = dataSource.VariantId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Type')) {
      this.Type = dataSource.Type;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Catalog')) {
      this.CatalogName = dataSource.Catalog;
    }

    return this;
  }
}
