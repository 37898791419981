import React from 'react';

import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';

const StoreOverviewShimmer = () => {
  return (
    <div className='store-overview skeleton__item'>
      <div className='store-overview__content'>
        <div className='store-overview__content__image'>
          <div className='image-wrap'>
            <Loading></Loading>
          </div>
        </div>
        <div className='store-overview__content__info'>
          <div className='container'>
            <div className='store-overview__content__info__wrap'>
              <div className='row'>
                <div className='col-md-6 col-xl-12 col-xxl-6'>
                  <div className='store-overview__content__info__contact'>
                    <h3 className='contact__title'>
                      <span className='text-line w-40'></span>
                    </h3>
                    <div className='contact-info-group'>
                      <span className='contact__item contact__address'>
                        <span className='text-line'></span>
                      </span>
                      <span className='contact__item contact__code'><span className='text-line'></span></span>
                      <div className='contact__item contact__phone'><span className='text-line'></span></div>
                    </div>
                    <div className='store-overview__content__info__actions '>
                      <div className='actions__wrap-link'>
                        <span className='text-line w-40 hidden-md-down'></span>
                        <span className='text-line w-40 hidden-md-down'></span>
                        <span className='text-line-center w-40 hidden-lg-up'></span>
                        <span className='clear-10 hidden-lg-up'></span>
                        <span className='text-line-center w-40 hidden-lg-up'></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-md-6 col-xl-12 col-xxl-6'>
                  <div className='store-overview__content__info__opening-hours'>
                    <h3 className='opening-hours__title'><span className='text-line w-40'></span></h3>
                    <div className='opening-hours__list'>
                      <LoadingShimmer itemNumb={7}>
                        <dl className='opening-hours__item opening-hours__item--active'>
                          <dt className='opening-hours__item__day'><span className='text-line w-50'></span></dt>
                          <dd className='opening-hours__item__time'><span className='text-line'></span></dd>
                        </dl>
                      </LoadingShimmer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreOverviewShimmer;