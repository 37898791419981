import PropTypes from 'prop-types';
import React from 'react';
import { Fragment } from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

const CarouselWidthIconTop = ({ thumbIndex, index, data }) => {
  return data ? (
    <div className='swiper-slide carousel-width-icon__item'>
      {data.Icon?.value?.src?.length > 0 && (
        <Fragment>
          <div className='carousel-width-icon__item__image'>
            <Image data-id={index} media={data.Icon} />
          </div>
          <Text field={data['Icon Title']} tag='p' className='carousel-width-icon__item__text' />
        </Fragment>
      )}
    </div>
  ) : (
    ''
  );
};

CarouselWidthIconTop.propTypes = {
  thumbIndex: PropTypes.any,
  index: PropTypes.any,
  data: PropTypes.any
};

export default CarouselWidthIconTop;
