import './StoreLocatorSearch.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { SUGGESTED_ADDRESS_NUMBER } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
const TEXT_URL_REPLACE = 'store-locator';

const StoreLocatorSearch = (props) => {
  const [state, setState] = useState({
    // The active selection's index
    activeSuggestion: -1,
    // The suggestions that match the user's input
    filteredSuggestions: props.dropdownList,
    // Whether or not the suggestion list is shown
    showSuggestions: false,
    // PUT THE STORE THAT USER FOCUS TO STATE OR PUT THE STORES THAT CONTAIN THER USER'S INPUT
    selectedStore: null,
    // CONTAIN THE TEXT THAT USER TYPE BEFORE
    inputText: '',
    // CONTAIN THE STORE'S NAME
    selectedText: '',
    // TO CONTROL THE STORE THAT USER SELECTED BY USING MOUSE
    isSelectedToggle: true,
    message: ''
  });

  const isGA4 = Global.getIsGA4();

  useDidUpdateEffect(() => {
    setState((prevState) => ({
      ...prevState,
      selectedText: '',
      selectedStore: props.suggestionSearchList
    }));
  }, [props.isReset]);

  useDidUpdateEffect(() => {
    setState((prevState) => ({
      ...prevState,
      filteredSuggestions: props.dropdownList
    }));
  }, [props.dropdownList]);

  // TRIGGER SEARCH ENGINE
  useDidUpdateEffect(() => {
    onSearchHandler();
  }, [state.isSelectedToggle]);

  // Event fired when the input value is changed
  const onChangeHandler = (e) => {
    const userInput = e.currentTarget.value;

    //RETURNS SUGGESTION RESULTS ARRAY THAT CONTAIN THE USER'S INPUT
    const filteredSuggestions = userInput
      ? props.suggestionSearchList?.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(userInput.toLowerCase()) > -1 || item.Street.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      )
      : [];

    // Update the user input and filtered suggestions, reset the active suggestion and make sure the suggestions are shown
    if (userInput) {
      let tempList = filteredSuggestions.slice(0, SUGGESTED_ADDRESS_NUMBER);

      setState((prevState) => ({
        ...prevState,
        activeSuggestion: -1,
        filteredSuggestions: tempList,
        showSuggestions: true,
        selectedStore: filteredSuggestions,
        inputText: userInput,
        selectedText: userInput
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        filteredSuggestions: [],
        selectedStore: props.suggestionSearchList,
        inputText: userInput,
        selectedText: userInput
      }));
    }
  };

  // Event fired when the user clicks on a suggestion
  const onMouseDownHandler = (suggestion) => {
    // Update the user input and reset the rest of the state
    setState((prevState) => ({
      ...prevState,
      activeSuggestion: -1,
      showSuggestions: false,
      filteredSuggestions: [suggestion],
      selectedStore: [suggestion],
      inputText: suggestion.Name,
      selectedText: suggestion.Name,
      isSelectedToggle: !state.isSelectedToggle
    }));
  };

  // Event fired when the user presses a key down
  const onKeyDownHandler = (e) => {
    const userInput = e.currentTarget.value;

    //RETURNS SUGGESTION RESULTS ARRAY THAT CONTAIN THE USER'S INPUT
    const filteredSuggestions = userInput
      ? props.suggestionSearchList.filter(
        (item) =>
          item.Name.toLowerCase().indexOf(userInput.toLowerCase()) > -1 || item.Street.toLowerCase().indexOf(userInput.toLowerCase()) > -1
      )
      : [];

    // User pressed the enter key, update the input and close the suggestions
    if (e.keyCode === 13) {
      if (userInput) {
        let tempList = filteredSuggestions.slice(0, SUGGESTED_ADDRESS_NUMBER);

        setState((preState) => ({
          ...preState,
          activeSuggestion: -1,
          showSuggestions: false,
          filteredSuggestions: tempList,
          selectedStore: filteredSuggestions,
          inputText: state.selectedText,
          selectedText: state.selectedText,
          isSelectedToggle: !state.isSelectedToggle,
          message: filteredSuggestions.length ? '' : '0 store satisfying the selected filters'
        }));
      } else {
        setState((preState) => ({
          ...preState,
          activeSuggestion: -1,
          showSuggestions: false,
          filteredSuggestions: [],
          selectedStore: props.suggestionSearchList,
          inputText: state.selectedText,
          isSelectedToggle: !state.isSelectedToggle,
          message: ''
        }));
      }

      document.querySelector('.store-locator__search__input__item').blur();
    }
    // User pressed the up arrow, decrease the index
    else if (e.keyCode === 38) {
      if (state.activeSuggestion === -1 || state.activeSuggestion === 0) {
        setState((prevState) => ({
          ...prevState,
          activeSuggestion: -1,
          selectedStore: state.filteredSuggestions,
          selectedText: state.inputText
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          activeSuggestion: state.activeSuggestion - 1,
          selectedStore: [state.filteredSuggestions[state.activeSuggestion - 1]],
          selectedText: state.filteredSuggestions[state.activeSuggestion - 1].Name
        }));
      }
    }
    // User pressed the down arrow, increase the index
    else if (e.keyCode === 40) {
      if (!state.filteredSuggestions.length) {
        setState((prevState) => ({
          ...prevState,
          activeSuggestion: -1,
          selectedStore: []
        }));
      } else if (state.activeSuggestion === state.filteredSuggestions.length - 1) {
        setState((prevState) => ({
          ...prevState,
          selectedStore: [state.filteredSuggestions[state.activeSuggestion]],
          selectedText: state.selectedText
        }));
      } else {
        setState((prevState) => ({
          ...prevState,
          activeSuggestion: state.activeSuggestion + 1,
          selectedStore: [state.filteredSuggestions[state.activeSuggestion + 1]],
          selectedText: state.filteredSuggestions[state.activeSuggestion + 1].Name
        }));
      }
    }
  };

  // PASS DATA INTO SEARCH FUNCTION
  const onSearchHandler = () => {
    props.onSearch({selectedStore: state.selectedStore, searchInput: state.inputText});
    //New Logic ON/OFF GA, GA4
    if (isGA4) {
      let params = {
        search_term: state.inputText,
        selected_category: window.location.pathname.replace(/[/]/g, '').replace(TEXT_URL_REPLACE, '') || 'all'
      };

      new GoogleAnalytic().gAnalytic4('event', 'roadshow_search', params);
    }
  };

  // SEARCH BY CLICKING SEARCH ICON
  const onSearchByClickIconHandler = () => {
    if (state.selectedText) {
      setState((prevState) => ({
        ...prevState,
        activeSuggestion: -1,
        showSuggestions: false,
        filteredSuggestions: state.filteredSuggestions,
        selectedStore: state.selectedStore,
        selectedText: state.selectedText,
        isSelectedToggle: !state.isSelectedToggle,
        message: state.filteredSuggestions.length ? '' : '0 store satisfying the selected filters'
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        activeSuggestion: -1,
        showSuggestions: false,
        filteredSuggestions: state.filteredSuggestions,
        selectedStore: props.suggestionSearchList,
        selectedText: state.selectedText,
        isSelectedToggle: !state.isSelectedToggle,
        message: ''
      }));
    }
  };

  const SuggestionsListComponent = () => {
    if (state.showSuggestions && state.filteredSuggestions.length) {
      return (
        <ul className='suggestions'>
          {state.filteredSuggestions.map((suggestion, index) => {
            return (
              <li
                className={index === state.activeSuggestion ? 'suggestion-active' : ''}
                key={suggestion.ItemId}
                onMouseEnter={() =>
                  setState((prevState) => ({
                    ...prevState,
                    activeSuggestion: index
                  }))
                }
                onMouseLeave={() =>
                  setState((prevState) => ({
                    ...prevState,
                    activeSuggestion: -1
                  }))
                }
                onMouseDown={() => onMouseDownHandler(suggestion)}
              >
                <ul>
                  <li>{suggestion.Name}</li>
                  <li>{suggestion.Street}</li>
                </ul>
              </li>
            );
          })}
        </ul>
      );
    } else {
      return (
        <Fragment></Fragment>
      );
    }
  };

  return (
    <Fragment>
      <input
        type='text'
        // placeholder={props.dataFromLayout['Location Search Box Placeholder'].value}
        onChange={(event) => onChangeHandler(event)}
        onKeyDown={(event) => onKeyDownHandler(event)}
        onFocus={() => {
          if (state.selectedText) {
            setState((prevState) => ({
              ...prevState,
              showSuggestions: true
            }));
          } else {
            setState((prevState) => ({
              ...prevState,
              showSuggestions: false
            }));
          }
        }}
        onBlur={() =>
          setState((prevState) => ({
            ...prevState,
            showSuggestions: false
          }))
        }
        value={state.selectedText}
        className='form-control form-control-lg store-locator__search__input__item'
      />
      {/* <i className='icon-search store-locator__search__input__icon' onClick={() => onSearchByClickIconHandler()} /> */}
      <SuggestionsListComponent />
    </Fragment>
  );
};

StoreLocatorSearch.propTypes = {
  dataFromLayout: PropTypes.any,
  suggestionSearchList: PropTypes.array,
  onSearch: PropTypes.func,
  isReset: PropTypes.bool,
  dropdownList: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    suggestionSearchList: state.storeLocatorReducer.allStoreReducer.suggestionSearchList || [],
    isReset: state.storeLocatorReducer.allStoreReducer.isReset,
    dropdownList: state.storeLocatorReducer.allStoreReducer.dropdownList || []
  };
};

export default connect(mapStateToProps)(StoreLocatorSearch);
