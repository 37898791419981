import produce from 'immer';

import * as cpUpdateAppointmentTypes from '@redux/actions/cpUpdateAppointment/cpUpdateAppointmentTypes';

const initState = {
  isLoading: false,
  isUpdateSuccess: false,
  EndpointStatus: ''
};

const cpUpdateAppointmentReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cpUpdateAppointmentTypes.CP_UPDATE_APPOINTMENT:
      draft.isLoading = true;

      draft.isUpdateSuccess = false;

      draft.EndpointStatus = '';
      break;

    case cpUpdateAppointmentTypes.CP_UPDATE_APPOINTMENT_SUCCESS:
      draft.isLoading = false;

      draft.isUpdateSuccess = true;

      draft.EndpointStatus = '';
      break;
  
    case cpUpdateAppointmentTypes.CP_UPDATE_APPOINTMENT_FAILED:
      draft.isLoading = false;

      draft.EndpointStatus = action.payload;
      break;
    }

    return draft;
  });

export default cpUpdateAppointmentReducer;
