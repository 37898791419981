import produce from 'immer';

import TKPaymentDataModel from '@components/CheckoutThankyou/model/TKPaymentDataModel';
import * as checkoutProcessTypes from '@redux/actions/checkoutProcess/checkoutProcessTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  paymentDetail: null,
  isLoading: false,
  error: ''
};

const paymentDetailReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case checkoutProcessTypes.GET_PAYMENT_DETAIL:
      draft.isLoading = true;
      break;

    case checkoutProcessTypes.GET_PAYMENT_DETAIL_SUCCESS:
      draft.paymentDetail = new TKPaymentDataModel().getData(deepCopy(action.payload) || {});

      draft.isLoading = false;

      break;

    case checkoutProcessTypes.GET_PAYMENT_DETAIL_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default paymentDetailReducer;
