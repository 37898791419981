import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Text'];
const PLAIN_TEXT_ARR = ['Title'];

export default class SupportCenterTitleModel {
  constructor() {
    TEXT_ARR.forEach((item) => (this[item] = new SitecoreTextModel()));

    PLAIN_TEXT_ARR.forEach((item) => (this[item] = ''));
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    PLAIN_TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    return this;
  }
}
