import produce from 'immer';

import * as productWarrantyTypes from '@redux/actions/productWarranty/productWarrantyTypes';

const initState = {
  isLoading: false,
  productDetails: null,
  isRemoveLoading: false,
  isRemoveMyProductSuccess: false,
  removeMyProductErrorMessage: ''
};

const getUserProductDetailsReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productWarrantyTypes.GET_USER_PRODUCT_DETAILS:
      draft.isLoading = true;
      break;

    case productWarrantyTypes.GET_USER_PRODUCT_DETAILS_SUCCESS:
      draft.isLoading = false;

      draft.productDetails = action.payload;
      break;

    case productWarrantyTypes.GET_USER_PRODUCT_DETAILS_FAILED:
      draft.isLoading = false;
      break;

    case productWarrantyTypes.REMOVE_MY_PRODUCT:
      draft.isRemoveLoading = true;

      draft.isRemoveMyProductSuccess = false;

      draft.removeMyProductErrorMessage = '';
      break;

    case productWarrantyTypes.REMOVE_MY_PRODUCT_SUCCESS:
      draft.isRemoveLoading = false;

      draft.isRemoveMyProductSuccess = true;
      break;

    case productWarrantyTypes.REMOVE_MY_PRODUCT_FAILED:
      draft.isRemoveLoading = false;

      draft.isRemoveMyProductSuccess = false;

      draft.removeMyProductErrorMessage = action.error;
      break;
    }

    return draft;
  });

export default getUserProductDetailsReducer;
