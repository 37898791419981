import produce from 'immer';

import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';

const initState = {
  isLoading: false,
  reviewById: null
};

const getProductReviewByIdReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productReviewTypes.GET_PRODUCT_REVIEW_BY_ID:
      draft.isLoading = true;
      break;

    case productReviewTypes.GET_PRODUCT_REVIEW_BY_ID_SUCCESS:
      draft.isLoading = false;

      draft.reviewById = action.payload;
      break;

    case productReviewTypes.GET_PRODUCT_REVIEW_BY_ID_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default getProductReviewByIdReducer;
