import './Login.scss';

import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';

import LoadingMask from '@components/Share/LoadingMask';
import Message from '@components/Share/Message';
import OmnichatTracking from '@components/Share/OmnichatTracking';
import FacebookSSO from '@components/SingleSignOn/FacebookSSO';
import GoogleSSO from '@components/SingleSignOn/GoogleSSO';
import {
  COUPON_CODE_SESSION_STORAGE_NAME,
  EMAIL_USER_SPIN_SESSION_STORAGE_NAME,
  SPIN_URL_SESSION_STORAGE_NAME
} from '@components/SpinAndWin/configs';
import { applyCouponCode } from '@redux/actions/coupon';
import { submitProductReview } from '@redux/actions/productReview';
import { login } from '@redux/actions/singleSignOn';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { MINUMUM_CHARACTERS_PASSWORD, TW_SITE_NAME } from '@utils/constant';
import { PRODUCT_REVIEW_PAGE } from '@utils/constant';
import { HK_SITE_NAME } from '@utils/constant';
import { REDIRECT_CHECKOUT_PARAM } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';
import Global from '@utils/global';

import { EVENT_MESSAGE, EVENT_METHOD, EVENT_NAME, EVENT_STATUS, handleTrackingEventRegisterLogin } from '../utils';

const Login = (props) => {
  const { inputData, isLoading, isLoginSuccess, error, isRegisterSuccess, accountUserInfo, couponDetailData, couponWalletData } = props;
  const dispatch = useDispatch();
  const templateName = SitecoreContextFactoryService.getValueContextRouteItem('templateName');
  const reviewFormParams = useSelector((state) => state.productReviewReducer.submitProductReviewReducer.reviewFormParams);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const isTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('HKOmnichat');
  const isTWTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('TWOmnichat');

  const [pevSubmitCount, setSubmitCount] = useState(0);
  const [locationSpinPage, setLocationSpinPage] = useState('');
  const [userSpinEmail, setUserSpinEmail] = useState('');
  const query = useQuery();

  useEffect(() => {
    const locationSpinPage = JSON.parse(window.sessionStorage.getItem(SPIN_URL_SESSION_STORAGE_NAME)) || '';

    setLocationSpinPage(locationSpinPage);
  }, []);

  useEffect(() => {
    const userSpinEmailSessionValue = window.sessionStorage.getItem(EMAIL_USER_SPIN_SESSION_STORAGE_NAME);
    const userSpinEmailValue = userSpinEmailSessionValue ? JSON.parse(userSpinEmailSessionValue) : '';

    setUserSpinEmail(userSpinEmailValue);
  }, []);

  useEffect(() => {
    if (isLoginSuccess && !isRegisterSuccess) {
      if (templateName === PRODUCT_REVIEW_PAGE) {
        //INFO: if is register of product review page => submit productReview
        let formData = new window.FormData();
        for (let keyParams in reviewFormParams) {
          if (keyParams == 'MediaFiles') {
            reviewFormParams[keyParams].forEach((item, index) => {
              for (let keyItem in item) {
                formData.append(`MediaFiles[${index}][${keyItem}]`, item[keyItem]);
              }
            });
          } else {
            formData.append(keyParams, reviewFormParams[keyParams]);
          }
        }

        dispatch(submitProductReview(formData));
      } else {
        // INFO: Back again homepage page if 'from' is undefined
        const fromTo = query.get('from');
        const isAddWishList = Global.getQueryParamsNoLower(window.location.href, 'isAddWishList');
        const redirectToCheckoutLink = Global.getQueryParamsNoLower(window.location.href, REDIRECT_CHECKOUT_PARAM);
        if (locationSpinPage !== fromTo || accountUserInfo?.Email !== userSpinEmail) {
          if (!redirectToCheckoutLink) window.location.href = !isAddWishList ? fromTo || '/' : `${fromTo}&isAddWishList=${isAddWishList}`;
          else window.location.href = redirectToCheckoutLink;
        }

        // //INFO: Set email to autofill in SpinAndWin page after login
        if (accountUserInfo?.Email !== userSpinEmail) {
          window.sessionStorage.setItem(EMAIL_USER_SPIN_SESSION_STORAGE_NAME, JSON.stringify(accountUserInfo?.Email || ''));
        }
      }
    }
  }, [isLoginSuccess, accountUserInfo, userSpinEmail]);

  useDidUpdateEffect(() => {
    // INFO: Omnichat Tracing for HK or TW site
    if (isLoginSuccess && (currentSiteName === HK_SITE_NAME && isTurnOnOmnichat || currentSiteName === TW_SITE_NAME && isTWTurnOnOmnichat)) {
      const loggedInUserInfo = {
        Id: accountUserInfo?.Id || '',
        Email: accountUserInfo?.Email || '',
        PhoneNumber: accountUserInfo?.PhoneNumber || '',
        FirstName: accountUserInfo?.FirstName || '',
        ProfilePic: accountUserInfo?.ProfilePic || ''
      };

      new OmnichatTracking().trackingLoggedUser(loggedInUserInfo);
    }
  }, [isLoginSuccess]);

  useDidUpdateEffect(() => {
    //INFO: tracking login event GA4 when login success
    if (isLoginSuccess) {
      let eventMethod = EVENT_METHOD.email;
      if (accountUserInfo.isLinkFacebook) eventMethod = EVENT_METHOD.facebook;
      if (accountUserInfo.isLinkGoogle) eventMethod = EVENT_METHOD.google;

      handleTrackingEventRegisterLogin(EVENT_NAME.login, EVENT_STATUS.login.success, EVENT_MESSAGE.success, eventMethod);
    }
  }, [isLoginSuccess, accountUserInfo]);

  useDidUpdateEffect(() => {
    //INFO: tracking login event GA4 when login fail
    if (error?.type) handleTrackingEventRegisterLogin(EVENT_NAME.login, EVENT_STATUS.login.failure, EVENT_MESSAGE.fail, error.type);
  }, [error]);

  const formik = useFormik({
    initialValues: {
      emailLogin: '',
      passwordLogin: '',
    },
    validationSchema: Yup.object({
      passwordLogin: Yup.string()
        .required(<Message messageCode='Msg_001.5' />).trim()
        .matches(Global.formatPassword(MINUMUM_CHARACTERS_PASSWORD), <Message messageCode='Msg_001.5' />),
      emailLogin: Yup.string().email(<Message messageCode='Msg_001.3' />).required(<Message messageCode='Msg_001.3' />).trim()
    }),
    onSubmit: values => {
      //INFO: setEmailSpin when login from spin page
      // const fromTo = query.get('from');
      // if (locationSpinPage == fromTo) {
      //   setEmailSpin(values.emailLogin);
      // }

      dispatch(login({
        UserName: values.emailLogin,
        PassWord: values.passwordLogin,
      })
      );
    },
    validateOnMount: true
  });

  useDidUpdateEffect(() => {
    if (Object.keys(formik.errors).length && !formik.isSubmitting && formik.submitCount > pevSubmitCount && !formik.isValid) {
      setSubmitCount(formik.submitCount);

      //INFO: tracking login event GA4 when login attempt
      handleTrackingLoginAttempt();

      window.scrollTo(0, 0);
    }
  }, [formik]);

  useDidUpdateEffect(() => {
    if (!isLoading && error) {
      window.scrollTo(0, 0);
    }
  }, [isLoading]);

  useDidUpdateEffect(() => {
    const fromQueryString = query.get('from');

    if (locationSpinPage?.toLowerCase() === fromQueryString?.toLowerCase() && isLoginSuccess && accountUserInfo?.Email === userSpinEmail) {
      const couponValueFromSessionStorage = window.sessionStorage.getItem(COUPON_CODE_SESSION_STORAGE_NAME);
      const couponValue = couponValueFromSessionStorage ? JSON.parse(couponValueFromSessionStorage) : '';

      if (couponValue) dispatch(applyCouponCode({ code: couponValue, reCallPromotionsApplied: false }));
    }
  }, [locationSpinPage, isLoginSuccess, accountUserInfo, userSpinEmail]);

  useDidUpdateEffect(() => {
    if (couponWalletData?.redirectLink) {
      window.location.href = couponWalletData?.redirectLink;
    } else if (couponWalletData?.errors?.[0]) {
      window.location.href = inputData?.['Products Link']?.value?.href || '#';
    }

    // INFO: remove coupon code when applied fail
    handleRemoveCouponSessionStorageValue();
  }, [couponWalletData?.errors, couponWalletData?.redirectLink]);

  useDidUpdateEffect(() => {
    if (couponDetailData?.success) {
      // INFO: remove coupon code when applied success
      handleRemoveCouponSessionStorageValue();

      window.location.href = inputData?.['You Won Apply Offer Button Link']?.value?.href || '#';
    }
  }, [couponDetailData?.success]);

  const handleRemoveCouponSessionStorageValue = useCallback(() => {
    window.sessionStorage.removeItem(COUPON_CODE_SESSION_STORAGE_NAME);
  }, []);

  const handleTrackingLoginAttempt = () => {
    handleTrackingEventRegisterLogin(EVENT_NAME.login, EVENT_STATUS.login.attempt, EVENT_MESSAGE.fail);
  };

  return (
    <div className='account-login'>
      <div className='osim-account-form__social-login text-center'>
        <FacebookSSO isLogin={false} dataFields={inputData['Facebook Login Settings']} />
        <GoogleSSO isLogin={false} dataFields={inputData['Google Login Settings']} />
        {error?.errorMessage && !isLoginSuccess ? (
          <span className='error-validate'>
            {error === 'Failed' ? <Message messageCode='Msg_003' /> : <RichText field={{ value: error.errorMessage }} />}
          </span>
        ) : (
          ''
        )}
      </div>
      {inputData['Login Introduction'].value ? (
        <p className='osim-account-form__title'>
          <Text field={inputData['Login Introduction']} />
        </p>
      ) : (
        ''
      )}
      <form onSubmit={formik.handleSubmit} className='osim-account-form__form'>
        <div className={Global.renderDynamicClass(formik.errors.emailLogin && formik.touched.emailLogin, 'form-group', 'input-error-validate')}>
          <input
            onChange={formik.handleChange}
            value={formik.values.emailLogin}
            type='text'
            id='emailLogin'
            className={Global.renderDynamicClass(formik.values['emailLogin'], 'form-control form-control-lg', 'input-valid')}
            name='emailLogin'
            placeholder={inputData['Login Email Label'].value}
          />
          <label htmlFor='emailLogin' className='form-group__label'>
            <Text field={inputData['Login Email Label']} />
          </label>
          {formik.errors.emailLogin && formik.touched.emailLogin && <span className='error-validate'>{formik.errors.emailLogin}</span>}
        </div>
        <div className={Global.renderDynamicClass(formik.errors.passwordLogin && formik.touched.passwordLogin, 'form-group', 'input-error-validate')}>
          <input
            onChange={formik.handleChange}
            value={formik.values.passwordLogin}
            type='password'
            className={Global.renderDynamicClass(formik.values['passwordLogin'], 'form-control form-control-lg', 'input-valid')}
            id='passwordLogin'
            placeholder={inputData['Login Password Label'].value}
            autoComplete='off'
          />
          <label htmlFor='passwordLogin' className='form-group__label'>
            <Text field={inputData['Login Password Label']} />
          </label>
          {formik.errors.passwordLogin && formik.touched.passwordLogin && (
            <span className='error-validate'>{<Message messageCode='Msg_001.5' />}</span>
          )}
        </div>
        <div className='form-group osim-account-form__form__forgot-pass text-right'>
          {inputData['Forgot Password Label'].value ? (
            <Link field={inputData['Forgot Password Link']}>
              <Text field={inputData['Forgot Password Label']} />
            </Link>
          ) : (
            <Link field={inputData['Forgot Password Link']} />
          )}
        </div>
        <div className='form-group osim-account-form__form__btn'>
          <button className='osim-account-form__form__btn__item btn btn-primary'>
            <Text field={inputData['Login Button Label']} />
          </button>
        </div>
        <div className='form-group'>
          <p className='text-center'>
            <Text field={inputData['Login Switch To Register Text']} />
            <a href='#' onClick={() => props.changeTabEvt()}>
              <Text field={inputData['Register Button Text']} />
            </a>
          </p>
        </div>
      </form>
      {isLoading || couponDetailData?.isLoading ? <LoadingMask /> : ''}
    </div>
  );
};

Login.propTypes = {
  inputData: PropTypes.object,
  changeTabEvt: PropTypes.func,
  error: PropTypes.any,
  isLoginSuccess: PropTypes.bool,
  isLoading: PropTypes.bool,
  isRegisterSuccess: PropTypes.bool,
  accountUserInfo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  couponDetailData: PropTypes.object,
  couponWalletData: PropTypes.object
};

const mapStateToProps = (state) => ({
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin,
  // isRegisterSuccess: state.singleSignOnReducer.userInfoReducer.accountUser?.IsRegisterNew,
  isRegisterSuccess: state.singleSignOnReducer.registerReducer?.isRegister,
  isLoading: state.singleSignOnReducer.loginReducer?.isLoading,
  error: state.singleSignOnReducer.loginReducer?.error,
  accountUserInfo: state.singleSignOnReducer.userInfoReducer?.accountUser,
  couponDetailData: state.couponReducer || {},
  couponWalletData: state.couponWalletReducer || {}
});

export default connect(mapStateToProps)(Login);
