import React, { Fragment } from 'react';

import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import { usePersonalizeExpFormContext } from '../index';
import SelectionItem from './SelectionItem';

const AdditionalSelection = () => {
  const { layoutData } = useScheduleAppointmentContext();
  const { formik } = usePersonalizeExpFormContext();

  return layoutData?.questions?.length ? (
    layoutData.questions.map((question, index) => {
      const label = layoutData?.['Selection Label']?.value?.replace('{number}', index + 1);

      return (
        <Fragment key={question.Id}>
          <SelectionItem label={label} questionData={question} />
          {formik.errors.additionalSelectionAns?.[index]?.AnswerIds && formik.touched.additionalSelectionAns?.[index]?.AnswerIds && (
            <span className='error-validate'>{formik.errors.additionalSelectionAns?.[index]?.AnswerIds}</span>
          )}
        </Fragment>
      );
    })
  ) : (
    <></>
  );
};

export default AdditionalSelection;
