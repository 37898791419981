import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { CART_PAGE_NAME } from '@components/FreeGiftPopup/FreeGiftItem';
import { getCurrentCart } from '@redux/actions/product';
import { updateFreeGiftToCartFailure, updateFreeGiftToCartSuccess } from '@redux/actions/updateFreeGiftToCart';
import { UPDATE_FREE_GIFT_START } from '@redux/actions/updateFreeGiftToCart/updateFreeGiftToCartTypes';
import { postAsObservable } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import { UPDATE_FREE_GIFT_TO_CART_ENDPOINT } from './config';

const updateFreeGiftToCartEpic = (action$) =>
  action$.pipe(
    ofType(UPDATE_FREE_GIFT_START),
    switchMap((action) => {
      const currentPageName = SitecoreContextFactoryService.getValueContextRouteItem('name');

      const params = {
        IsCartPage: currentPageName?.toLowerCase() === CART_PAGE_NAME,
        FreeGiftPopups: [...action.payload.FreeGiftPopups]
      };

      return from(
        postAsObservable(UPDATE_FREE_GIFT_TO_CART_ENDPOINT, params).pipe(
          switchMap((res) => {
            if (res.status === 200 || res.data.Success) {
              return of(getCurrentCart(), updateFreeGiftToCartSuccess());
            } else {
              return of(updateFreeGiftToCartFailure(res.data?.Errors?.[0] || 'Failed'));
            }
          }),
          catchError((error) => of(updateFreeGiftToCartFailure(error || 'Failed')))
        )
      );
    })
  );

export default updateFreeGiftToCartEpic;
