import './ProductResourceListing.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ProductResourceListingModel from '../Models/ProductResourceListingModel';
import ProductHubInfo from '../ProductHubInfo';

const ProductResourceListing = ({fields}) => {
  const [productResourceListingFields, setProductResourceListingFields] = useState(null);

  useEffect(() => {
    const productResourceListingModel = new ProductResourceListingModel();

    setProductResourceListingFields(productResourceListingModel.getData(deepCopy(fields || {})));
  }, []);

  return productResourceListingFields && (
    <div className='product-hub-list'>
      <div className='container container--custome'>
        <Text tag='h2' field={productResourceListingFields.Title} className='text-center section-title' />
        <div className='product-hub-row'>
          {
            productResourceListingFields['Detail Pages'].length ?
              productResourceListingFields['Detail Pages'].map((productResource, index) => (
                <div className='product-hub-col' key={productResource.id+index}>
                  <ProductHubInfo productResource={productResource}></ProductHubInfo>
                </div>
              ))
              : ''
          }
        </div>
      </div>
    </div>
  );
};

ProductResourceListing.propTypes = {
  fields: PropTypes.object
};

export default ProductResourceListing;