import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Button Text',
  'Description Title',
  'Title'
];

const LINK_ARR = [
  'Button Url'
];

export default class WarrantyRegistrationCTAModel {
  constructor() {
    TEXT_ARR.forEach((text)=> {
      this[text] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((link)=> {
      this[link] = new SitecoreLinkModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((text)=> {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text].getData(dataSource[text]);
      }
    });

    LINK_ARR.forEach((link)=> {
      if(dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link].getData(dataSource[link]);
      }
    });

    return this;
  }
}