import SitecoreTextModel from '@models/SitecoreTextModel';

export default class ForgotPasswordModel {
  constructor() {
    this['Email Label'] = new SitecoreTextModel();

    this['Instruction'] = new SitecoreTextModel();

    this['Phone Label'] = new SitecoreTextModel();

    this['Reset Password Button Label'] = new SitecoreTextModel();

    this['Second Instruction'] = new SitecoreTextModel();

    this['Successful Instruction'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Email Label')) {
      this['Email Label'].getData(dataSource['Email Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Instruction')) {
      this['Instruction'].getData(dataSource['Instruction']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Phone Label')) {
      this['Phone Label'].getData(dataSource['Phone Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Reset Password Button Label')) {
      this['Reset Password Button Label'].getData(dataSource['Reset Password Button Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second Instruction')) {
      this['Second Instruction'].getData(dataSource['Second Instruction']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Successful Instruction')) {
      this['Successful Instruction'].getData(dataSource['Successful Instruction']);
    }

    return this;
  }
}
