import { ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';

import { checkPriorityDeliveryFailed, checkPriorityDeliverySuccess } from '@redux/actions/priorityDelivery';
import { CHECK_PRIORITY_DELIVERY_START } from '@redux/actions/priorityDelivery/priorityDeliveryTypes';
import { getAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import { PRIORITY_DELIVERY_ENDPOINT } from './config';

export const checkPriorityDeliveryEpic = (action$) =>
  action$.pipe(
    ofType(CHECK_PRIORITY_DELIVERY_START),
    switchMap(() => {
      return getAsObservable(`${PRIORITY_DELIVERY_ENDPOINT}`).pipe(
        map((response) => {
          if (response.status === 200 || response.data.Success) {
            return checkPriorityDeliverySuccess(deepCopy(response.data));
          } else {
            return checkPriorityDeliveryFailed('Failed');
          }
        })
      );
    })
  );
