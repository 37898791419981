export const REMOVE_CART_LINE = 'REMOVE_CART_LINE';

export const REMOVE_CART_LINE_SUCCESS = 'REMOVE_CART_LINE_SUCCESS';

export const REMOVE_CART_LINE_FAILED = 'REMOVE_CART_LINE_FAILED';

export const REMOVE_SO_CART_LINE = 'REMOVE_SO_CART_LINE';

export const REMOVE_SO_CART_LINE_SUCCESS = 'REMOVE_SO_CART_LINE_SUCCESS';

export const REMOVE_SO_CART_LINE_FAILED = 'REMOVE_SO_CART_LINE_FAILED';

export const UPDATE_CART_LINE = 'UPDATE_CART_LINE';

export const UPDATE_CART_LINE_SUCCESS = 'UPDATE_CART_LINE_SUCCESS';

export const UPDATE_CART_LINE_FAILED = 'UPDATE_CART_LINE_FAILED';

export const UPDATE_SO_CART_LINE = 'UPDATE_SO_CART_LINE';

export const UPDATE_SO_CART_LINE_SUCCESS = 'UPDATE_SO_CART_LINE_SUCCESS';

export const UPDATE_SO_CART_LINE_FAILED = 'UPDATE_SO_CART_LINE_FAILED';

export const SET_CART_FULLFILLMENT = 'SET_CART_FULLFILLMENT';

export const SET_CART_FULLFILLMENT_SUCCESS = 'SET_CART_FULLFILLMENT_SUCCESS';

export const SET_CART_FULLFILLMENT_FAILED = 'SET_CART_FULLFILLMENT_FAILED';

export const SET_CART_LINE_FULLFILLMENT = 'SET_CART_LINE_FULLFILLMENT';

export const SET_CART_LINE_FULLFILLMENT_SUCCESS = 'SET_CART_LINE_FULLFILLMENT_SUCCESS';

export const SET_CART_LINE_FULLFILLMENT_FAILED = 'SET_CART_LINE_FULLFILLMENT_FAILED';

export const UPDATE_ONE_CART_LINE = 'UPDATE_ONE_CART_LINE';

export const UPDATE_ONE_CART_LINE_SUCCESS = 'UPDATE_ONE_CART_LINE_SUCCESS';

export const UPDATE_ONE_CART_LINE_FAILED = 'UPDATE_ONE_CART_LINE_FAILED';

export const GET_CART_STRIP_ADS = 'GET_CART_STRIP_ADS';

export const GET_CART_STRIP_ADS_SUCCESS = 'GET_CART_STRIP_ADS_SUCCESS';

export const GET_CART_STRIP_ADS_FAILED = 'GET_CART_STRIP_ADS_FAILED';

export const PICK_UP_POINT = 'PICK_UP_POINT';

export const PICK_UP_POINT_SUCCESS = 'PICK_UP_POINT_SUCCESS';

export const PICK_UP_POINT_FAILED = 'PICK_UP_POINT_FAILED';

export const ADD_STAFF = 'ADD_STAFF';

export const ADD_STAFF_SUCCESS = 'ADD_STAFF_SUCCESS';

export const ADD_STAFF_FAILED = 'ADD_STAFF_FAILED';

export const GET_CART_DELIVERY = 'GET_CART_DELIVERY';

export const GET_CART_DELIVERY_SUCCESS = 'GET_CART_DELIVERY_SUCCESS';

export const GET_CART_DELIVERY_FAILED = 'GET_CART_DELIVERY_FAILED';

export const GET_PROMOTION_BY_ID = 'GET_PROMOTION_BY_ID';

export const GET_PROMOTION_BY_ID_SUCCESS = 'GET_PROMOTION_BY_ID_SUCCESS';

export const GET_PROMOTION_BY_ID_FAILED = 'GET_PROMOTION_BY_ID_FAILED';

export const SET_ERROR_MESSENGER = 'SET_ERROR_MESSENGER';

export const UPDATE_CART_SELLER = 'UPDATE_CART_SELLER';

export const PREVENT_ADD_TRADE_IN_PRODUCT_TO_CART = 'PREVENT_ADD_TRADE_IN_PRODUCT_TO_CART';

export const SET_BLOCK_INSTALLMENT = 'SET_BLOCK_INSTALLMENT';

export const BLOCK_INSTALLMENT_CHECK = 'BLOCK_INSTALLMENT_CHECK';


export const CHANGE_DELIVERY_OPTION = 'CHANGE_DELIVERY_OPTION';
