import './AppointmentDetail.scss';

import * as propType from 'prop-types';
import React, { useMemo } from 'react';

import { addCurlyBrackets } from '@redux/epic/bookingExperience/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import AppointmentBanner from './AppointmentBanner';
import ContactDetail from './ContactDetail';
import ProductSelected from './ProductSelected';
import ScheduleVisited from './ScheduleVisited';
import SelectionDetail from './SelectionDetail';
import TeaAndScentSelected from './TeaAndScentSelected';

const AppointmentDetailContent = ({ layoutData, isCancelSuccess }) => {
  const timeSlotBooked = useMemo(() => {
    const timeSlotId = layoutData?.appointment?.TimeSlot?.Id;

    return layoutData?.appointment?.Store?.TimeSlots?.TimeSlots?.find(
      (timeSlot) => timeSlot.Id?.toLowerCase() === addCurlyBrackets(timeSlotId)?.toLowerCase()
    );
  }, [layoutData]);

  const numberOfGuestValue = useMemo(() => {
    const numberOfGuestText = layoutData?.['Number Of Guest Text']?.value;

    return numberOfGuestText?.replace('{numberOfGuest}', layoutData?.appointment?.NumberOfGuests);
  }, [layoutData]);

  const isBookExperience = useMemo(() => {
    return layoutData?.appointment?.IsBookExperience;
  }, [layoutData]);

  return (
    <>
      {/* Appointment detail header */}
      <AppointmentBanner
        isBookExperience={isBookExperience}
        layoutData={layoutData}
        isCancelSuccess={isCancelSuccess}
        timeSlotBooked={timeSlotBooked}
        numberOfGuestValue={numberOfGuestValue}
      />

      {/* Appointment detail body */}
      <div className='appointment-detail__body'>
        <div className='container'>
          {isBookExperience ? <Text field={layoutData?.['Appointment Details Label']} tag='h2' className='appointment-detail__body__title' /> : <></>}

          {/* Select Product */}
          {isBookExperience ? <ProductSelected layoutData={layoutData} /> : <></>}

          {/* Scheduled Visit */}
          {isBookExperience ? (
            <ScheduleVisited layoutData={layoutData} numberOfGuestValue={numberOfGuestValue} timeSlotBooked={timeSlotBooked} />
          ) : (
            <></>
          )}

          {/* Personalized experiences */}
          {isBookExperience ? <TeaAndScentSelected layoutData={layoutData} /> : <></>}

          {/* Select Detail */}
          {isBookExperience ? <SelectionDetail layoutData={layoutData} /> : <></>}

          {/* Button Change Schedule */}
          {/* {layoutData?.isUpComing ? (
            <div className='appointment-detail__body__item appointment-detail__body__item--button'>
              <button onClick={handleOpenModal} className='btn btn-link appointment-detail__header__content__wrap__btn-cancel'>
                <Text field={layoutData?.['Button Cancel Appointment Text']}/>
              </button>
            </div>
          ) : (<></>)} */}

          {/* Contact Detail */}
          <ContactDetail layoutData={layoutData} />
        </div>
      </div>
    </>
  );
};

AppointmentDetailContent.propTypes = {
  layoutData: propType.object,
  isCancelSuccess: propType.bool
};

export default AppointmentDetailContent;
