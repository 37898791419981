import { GET_CONTACT_FAILURE, GET_CONTACT_START, GET_CONTACT_SUCCESS } from './types';

export const getContactStart = (payload) => ({
  type: GET_CONTACT_START,
  payload
});

export const getContactSuccess = (payload) => ({
  type: GET_CONTACT_SUCCESS,
  payload
});

export const getContactFailure = (payload) => ({
  type: GET_CONTACT_FAILURE,
  payload
});
