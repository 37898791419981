import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Enable send by WeChat',
  'Send sale order',
  'Additional text',
  'Back to sale cart',
  'Creation and Expiration text',
  'Enable send by emails',
  'Enable send by SMS',
  'Enable send by WhatsApp',
  'Header',
  'Number of packages text',
  'Firstname',
  'Lastname',
  'Email',
  'Sms',
  'WhatsApp',
  'WeChat',
  'Edit',
  'Remove',
  'Add new',
  'Enter email',
  'Enter sms',
  'Save',
  'Cancel',
  'Invalid email',
  'Invalid sms',
  'Required Selection',
  'Customer name text',
  'Warning Title',
  'Warning Description',
  'Continue With Existing Button',
  'Create New Profile Button',
  'Create New Profile Duplicate Email Button',
  'Create New Profile Duplicate Phone Button',
  'EmailLabel',
  'SmsLabel',
  'WhatsAppLabel',
  'WeChatLabel',
];

const LINK_ARR = [
  'Send sale order link',
  'Back to sale cart link'
];

export default class SendOrderToCustomerModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['Cart'] = '';
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cart')) {
      this['Cart'] = dataSource['Cart'];
    }

    return this;
  }
}
