import { deepCopy } from '@utils/utility';

import AllowedOptionsModel from './AllowedOptionsModel';

export default class AdditionalOptionsFieldsModel {
  constructor() {
    this['Allowed Options'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Allowed Options')) {
      dataSource['Allowed Options'] &&
        dataSource['Allowed Options'].map((element) => {
          this['Allowed Options'].push(new AllowedOptionsModel().getData(deepCopy(element) || {}));
        });
    }

    return this;
  }
}
