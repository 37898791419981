import { useMemo } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

/** @param {string} siteName */
export const isAtSiteName = (siteName) => {
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;

  return currentSiteName === siteName;
};

/** @param {string} siteName */
export const useIsAtSiteName = (siteName) => {
  return useMemo(() => isAtSiteName(siteName), [siteName]);
};
