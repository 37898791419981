import './style.scss';

import PropType from 'prop-types';
import React from 'react';

const ThumbnailComponent = (props) => (
  <div className={`swiper-thumbnail-contain ${props.active ? 'active' : ''}`}>
    <img className='swiper-thumbnail-contain__img' src={props.item.image.value.url} />
  </div>
);

ThumbnailComponent.propTypes = {
  item: PropType.any,
  active: PropType.bool
};

export default ThumbnailComponent;
