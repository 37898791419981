import './TwoColumn.scss';
import '@components/ProductWarrantyRegistration/ProductWarrantyDetail/ProductWarrantyDetail.scss';

import Proptypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

const TwoColumn11 = (props) => {
  const componentId = props.params && props.params['Component Id'] || '';
  const isCart = useSelector((state)=> state?.productReducer?.cartDetailReducer?.currentCart?.Lines?.length);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  return !isCart && isClientTelingUser ? (
    ''
  ) : (
    <div className={`two-column11 bg-${props.params['Background Color'] || 'default'} ${props.params['Class'] || ''}`}
      id={componentId}>
      <div className='container'>
        <div className='row'>
          <div className='col-md-6 col-12'>
            <Placeholder name='osim-col-wide-1' rendering={props.rendering} />
          </div>
          <div className='col-md-6 col-12'>
            <Placeholder name='osim-col-wide-2' rendering={props.rendering} />
          </div>
        </div>
      </div>
    </div>
  );
};

TwoColumn11.propTypes = {
  rendering: Proptypes.any,
  params: Proptypes.any
};

export default TwoColumn11;
