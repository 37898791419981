import { ADD_FREE_GIFT_TO_CART_FAILURE, ADD_FREE_GIFT_TO_CART_START, ADD_FREE_GIFT_TO_CART_SUCCESS } from './addFreeGiftToCartTypes';

export const addFreeGiftToCartStart = (payload) => ({
  type: ADD_FREE_GIFT_TO_CART_START,
  payload
});

export const addFreeGiftToCartSuccess = () => ({
  type: ADD_FREE_GIFT_TO_CART_SUCCESS
});

export const addFreeGiftToCartFailure = (payload) => ({
  type: ADD_FREE_GIFT_TO_CART_FAILURE,
  payload
});
