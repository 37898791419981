import Proptypes from 'prop-types';
import React, {useEffect, useRef } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { FREEGIFT_SLIDE_DISPLAY_ITEM, FREEGIFT_SLIDE_DISPLAY_ITEM_DEVICE } from '@utils/constant';

const BundleBOFreeGiftList = ({ contentData, onAction, onToogleFnc, freeGiftSelected, dataText  }) => {

  const freeGiftSwiperRef = useRef(null);

  useEffect(() => {
    if (
      contentData.every((line) => {
        return line.items.length === 1;
      })
    ) {
      contentData.forEach((line) => {
        onAction(line.type, line.items?.[0].ProductId, line.items?.[0].VariantId, line.item?.[0].VariantName !== '' ? line.items?.[0].VariantName : line.items?.[0].DisplayName);
      });
    }
  }, []);
  
  useEffect(() => {
    if (freeGiftSelected.length === contentData.length) {
      onToogleFnc();
    }
  }, [freeGiftSelected]);

  const isActiveItems = (itemId) => {

    return freeGiftSelected.findIndex((itemInList) => itemInList.VariantId === itemId) !== -1;
  };

  let params = {
    // lazy: true,
    observer: true,
    observeParents: true,
    shouldSwiperUpdate: true,
    slidesPerView: FREEGIFT_SLIDE_DISPLAY_ITEM,
    watchOverflow: true,
    spaceBetween: 24,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // eslint-disable-next-line react/display-name
    renderPrevButton: () => <span className='swiper-button swiper-button-prev icon-chevron-left'></span>,
    // eslint-disable-next-line react/display-name
    renderNextButton: () => <span className='swiper-button swiper-button-next icon-chevron-right'></span>,

    breakpoints: {
      1024: {
        slidesPerView: FREEGIFT_SLIDE_DISPLAY_ITEM,
        // spaceBetween: 15
      },
      768: {
        slidesPerView: FREEGIFT_SLIDE_DISPLAY_ITEM_DEVICE,
        // spaceBetween: 10
      },
      320: {
        slidesPerView: FREEGIFT_SLIDE_DISPLAY_ITEM_DEVICE,
        // spaceBetween: 5
      }
    }
  };

  return contentData?.length && (
    <div className='freegift-buying-list'>
      {contentData.map((line, lineIdx) => {
        return (
          <div className='freegift-buying-list__item bo-free-gift__group'
            key={lineIdx}
          >
            <p className='item__name bo-free-gift__group__title'>{dataText['Free Gifts Label'].value} {lineIdx + 1}</p>
            <SwipeComponent param={params} setRef={freeGiftSwiperRef}>
              { line.items.map((item, itemIdx) => {
                return (
                  <div key={itemIdx}>
                    <div
                      className={
                        `item__variant bo-free-gift__item
                          ${isActiveItems(item.VariantId) ? ' active' : ''}`
                      }
                      onClick={() => onAction(line.type, item.ProductId, item.VariantId, item.VariantName !== '' ? item.VariantName : item.DisplayName)}
                    >
                      <div className='item__variant__image bo-free-gift__item__image'>
                        <img className='' src={`${item.VariantImages}`} alt='Product Image'/>
                      </div>
                      <div className='item__variant__name bo-free-gift__item__name' title={item.VariantName !== '' ? item.VariantName : item.DisplayName}>
                        {item.VariantName !== '' ? item.VariantName : item.DisplayName}
                      </div>
                    </div>
                  </div>
                );
              }) }
            </SwipeComponent>
          </div>
        );
      })}
    </div>
  );
};

BundleBOFreeGiftList.propTypes = {
  contentData: Proptypes.any,
  activeItemsId: Proptypes.any,
  freeGiftSelected: Proptypes.any,
  selectedLength: Proptypes.any,
  onToogleFnc: Proptypes.any,
  isOpen: Proptypes.any,
  dataText: Proptypes.any,
  onAction: Proptypes.func,
};

export default BundleBOFreeGiftList;
