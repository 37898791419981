import SitecoreTextModel from '@models/SitecoreTextModel';

import { deepCopy } from '../../../utils/utility';

export default class PaymentOptionsModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();

    this['Items'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Items')) {
      let newItemsList = [];

      dataSource['Items'].map((element) => {
        newItemsList.push(deepCopy(element));
      });

      this['Items'] = newItemsList;
    }

    return this;
  }
}
