import './ProductItemViewAll.scss';

import { any, bool } from 'prop-types';
import React from 'react';

import Global from '@utils/global';

const ProductItemViewAll = ({ isWishList, ProductsItem, ProductFields }) => {
  const { DisplayName, Color, Image } = ProductsItem;

  const dateObject = new Date(Number.parseInt(ProductsItem?.DateCreated?.replace(/Date|\(|\/|\)/gi, '')));

  const convertDate = Global.customDate(dateObject).replace(',', '');

  return (
    <>
      <div className='product-item-view-all'>
        <div className='product-item-view-all__img'>
          {isWishList ? <img src={ProductsItem.Product.Image} alt='Product Image' /> : <img src={Image} alt='Product Image' />}
        </div>
        <div className='product-item-view-all__content'>
          {isWishList ? (
            <>
              <h4 className='product-item-view-all__content-title'>{ProductsItem?.Product?.ProductName}</h4>
              {ProductsItem?.Product?.Price.ListPrice > ProductsItem?.Product?.Price.SellingPrice ? (
                <p className='product-item-view-all__content-price'>
                  {ProductsItem?.Product?.Price?.SellingPriceWithCurrency}{' '}
                  <span>
                    <s>{ProductsItem?.Product?.Price?.ListPriceWithCurrency}</s>
                  </span>
                </p>
              ) : (
                <p className='product-item-view-all__content-price'>{ProductsItem?.Product?.Price?.SellingPriceWithCurrency} </p>
              )}
              <p className='product-item-view-all__content-addon'>
                {ProductFields['Added On Text'].value} <span>{convertDate}</span>
              </p>
            </>
          ) : (
            <>
              <h4 className='product-item-view-all__content-title'>{DisplayName}</h4>
              <p className='product-item-view-all__content-color'>
                {ProductFields['Variant Text'].value}:<span>{Color}</span>
              </p>
              {/* <p className='product-item-view-all__content-warranty'>Warranty till 20 Feb 2021</p> */}
            </>
          )}
        </div>
      </div>
    </>
  );
};

ProductItemViewAll.propTypes = {
  ProductsItem: any,
  ProductFields: any,
  isWishList: bool
};

export default ProductItemViewAll;
