import PropTypes from 'prop-types';
import React from 'react';

const FormWrapper = ({ title, children }) => {
  return (
    <>
      <h1 className='form-title'>{title}</h1>
      {children}
    </>
  );
};

FormWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node
};

export default FormWrapper;
