export const generateSmsArray = (values, layoutData) =>
  values
    ?.filter((item) => item.category === layoutData?.Sms?.value)
    ?.map((item) => {
      return item;
    });

export const generateEmailArray = (values, layoutData) =>
  values
    ?.filter((item) => item.category === layoutData?.Email?.value)
    ?.map((item) => {
      return item;
    });
