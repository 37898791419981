import './CampaignQRCode.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import QRCode from 'react-qr-code';

import { Image, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CPPageHeader from '../CPPageHeader';
import CampaignQRCodeItem from './CampaignQRCodeItem';
import CampaignQRCodeModel from './Models/CampaignQRCodeModel';

const CampaignQRCode = ({ fields }) => {
  const [campaignQRCodeData, setCampaignQRCodeModel] = useState(null);

  useEffect(() => {
    setCampaignQRCodeModel(new CampaignQRCodeModel().getData(deepCopy(fields || {})));
  }, []);

  return campaignQRCodeData ? (
    <div className='campaign-QRCode'>
      <CPPageHeader title={campaignQRCodeData['Title']} />
      <div className='campaign-QRCode__list category-landing category-landing__client-teling animated-slow fadeIn delay-100'>
        <div className='container tablet-container'>
          <div className='category-landing--view category-landing--list-view --cp-view'>
            {campaignQRCodeData['Campaign List'].map((item) => {
              return (
                <div className='category-landing__product category-product campaign-QRCode__item' key={item.id}>
                  <CampaignQRCodeItem>
                    <div className='category-product__wrap'>
                      <div className='category-product__contents'>
                        <div className='category-product__image'>
                          <Image field={item.fields['Image']} className='category-product__image__item'/>
                        </div>
                        <div className='category-product__main'>
                          <h3 className='category-product__main__name'>
                            <Text field={item.fields['Campaign Title']} />
                          </h3>
                          <div className='category-product__main__QRCode'>
                            <div className='category-product__main__QRCode__wrap'>
                              <QRCode value={item.fields['Campaign QR Code'].value} size='105' />
                            </div>
                          </div>
                          <RichText tag='div' className='category-product__main__sort-decs' field={item.fields['Campaign Description']} />
                        </div>
                      </div>
                    </div>
                  </CampaignQRCodeItem>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

CampaignQRCode.propTypes = {
  fields: PropTypes.any
};

export default CampaignQRCode;
