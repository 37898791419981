import './CPFilterPopupOrders.scss';

import PropTypes from 'prop-types';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import { removeCurlyBrackets } from '@redux/epic/bookingExperience/utils';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

import FilterRange from '../../GenericComponents/FilterRange';
import { CATEGORY, DATE, EXPIRED_DATE_CUSTOMIZE_ID, EXPIRED_DATE_THIS_MONTH_ID, EXPIRED_DATE_THIS_WEEK_ID, EXPIRED_DATE_TODAY_ID, EXPIRYDATE, MILLISECOND_PER_DAY, SALESORDERVALUE, SALESTAFF, STATUS, STORE } from './configs';

const CPFilterPopupOrders = ({ filterFields, onFilterSubmit, onSetIsReset, currentStoreId }) => {
  const salesStaffData = useSelector((state) => state?.cpLeadManagementReducer?.SaleStaffs);
  const categoryData = useSelector((state) => state?.getProductCatalogueReducer?.dataCatalog);
  const MINRANGE = Number(filterFields?.['Min total'].value || 0);
  const MAXRANGE = Number(filterFields?.['Max total'].value || 10000);
  const FILTER_DEFAULT_DATE_RANGE = filterFields?.['Filter default date range'].value || 10;
  const initialStateDateFilter = [new Date(new Date().valueOf() - FILTER_DEFAULT_DATE_RANGE * MILLISECOND_PER_DAY), new Date()];
  const isCPManager = SitecoreContextFactoryService.getValueContextItem('isCPManager');
  const { soCartStatuses } = useSelector((state) => state.cpSaleOrderStatusesReducer);

  const getDataToArr = (arr, newArr) => {
    if (arr?.length) {
      arr.map((item) => {
        const store = {
          id: removeCurlyBrackets(item?.Id) || removeCurlyBrackets(item?.StoreId) || item?.StaffId || '',
          value: item?.StaffName || item?.StoreName || item?.Value || item || '',
          label: item?.StaffName || item?.StoreName || item?.Text || item || ''
        };

        newArr.push(store);
      });
    }
  };

  const expiryDateOptions = [
    { id: '', value: '', label: filterFields?.['Select date']?.value },
    { id: EXPIRED_DATE_TODAY_ID, value: filterFields?.['Today']?.value, label: filterFields?.['Today']?.value },
    { id: EXPIRED_DATE_THIS_WEEK_ID, value: filterFields?.['This week']?.value, label: filterFields?.['This week']?.value },
    { id: EXPIRED_DATE_THIS_MONTH_ID, value: filterFields?.['This month']?.value, label: filterFields?.['This month']?.value },
    { id: EXPIRED_DATE_CUSTOMIZE_ID, value: filterFields?.['Customize']?.value, label: filterFields?.['Customize']?.value }
  ];

  const saleStaffOptions = [{ id: '', value: '', label: filterFields?.['Select Staff']?.value }];

  getDataToArr(salesStaffData, saleStaffOptions);

  const storeOptions = useMemo(() => {
    const defaultValues = [
      {
        id: '',
        value: '',
        label: filterFields?.['Select Store']?.value
      }
    ];
    const storesList = filterFields?.Stores || [];
    if (storesList.length) {
      for (const item of storesList) {
        defaultValues.push({
          id: item?.StoreId || '',
          value: item?.OutletId || '',
          label: item?.StoreName
        });
      }
    }

    return defaultValues;
  }, [filterFields]);

  const statusOptions = useMemo(() => {
    const statusOptionsList = [{ value: '', label: filterFields?.['Select Status']?.value }];
    const statusFromLayoutData = filterFields?.Statuses || [];
    if (statusFromLayoutData.length) {
      for (const [index, item] of Object.entries(soCartStatuses)) {
        const value = statusFromLayoutData?.[index];

        statusOptionsList.push({
          id: index,
          value: value,
          label: item || value
        });
      }
    }

    return statusOptionsList;
  }, [soCartStatuses, filterFields]);

  // getDataToArr(filterFields?.Statuses, statusOptions);

  const categoryOptions = [{ id: '', value: '', label: filterFields?.['Select Category']?.value }];

  getDataToArr(categoryData, categoryOptions);

  const [isShowFilterPopup, setShowFilterPopup] = useState(false);
  const [isClearFilter, setIsClearFilter] = useState(false);

  const [dataFilter, setDataFilter] = useState({
    expiryDate: expiryDateOptions[0],
    date: '',
    dateUTC: '',
    salesOrderValue: [MINRANGE, MAXRANGE],
    saleStaff: saleStaffOptions[0],
    store: currentStoreId
      ? {
        id: currentStoreId?.storeId,
        value: currentStoreId?.outletCode,
        label: currentStoreId?.storeName,
        outletCode: currentStoreId?.outletCode
      }
      : storeOptions[0],
    status: statusOptions[0],
    category: [categoryOptions[0]]
  });

  // current date time
  const getCurrentDateTime = () => {
    const currentTime = new Date();
    let currentDateTime = '';

    let startOfDay = new Date(currentTime);

    startOfDay.setUTCHours(0, 0, 0, 0);
    let endOfDay = new Date(currentTime);

    endOfDay.setUTCHours(23, 59, 59, 999);

    return currentDateTime = [startOfDay, endOfDay];
  };

  // Current week time
  const getCurrentWeekTime = () => {
    const currentTime = new Date();
    const currentDayOfWeek = currentTime.getDay();
    let currentWeekTime = '';

    let startOfWeek = new Date(currentTime);

    startOfWeek.setUTCDate(currentTime.getDate() + 1 - currentDayOfWeek);

    startOfWeek.setUTCHours(0, 0, 0, 0);
    let endOfWeek = new Date(currentTime);

    endOfWeek.setUTCDate(startOfWeek.getDate() + 6);

    endOfWeek.setUTCHours(23, 59, 59, 999);

    return currentWeekTime = [startOfWeek, endOfWeek];
  };

  // Current month time
  const getCurrentMontTime = () => {
    const currentTime = new Date();
    let currentMontTime = '';

    let startOfMonth = new Date(currentTime);

    startOfMonth.setUTCDate(1);

    startOfMonth.setUTCHours(0, 0, 0, 0);
    let endOfMonth = new Date(currentTime);

    endOfMonth.setUTCMonth(currentTime.getMonth() + 1, 0);

    endOfMonth.setUTCHours(23, 59, 59, 999);

    return currentMontTime = [startOfMonth, endOfMonth];
  };

  // Current customize defau time
  const getCurrentCustomizeDefaultTime = (value) => {
    if (value) {
      let currentCustomizeTime = '';

      let startOfCustomize = new Date(value[0]);

      startOfCustomize.setUTCHours(0, 0, 0, 0);
      let endOfCustomize = new Date(value[1]);

      endOfCustomize.setUTCHours(23, 59, 59, 999);

      return currentCustomizeTime = [startOfCustomize, endOfCustomize];
    }
  };

  // Current customize time
  const getCurrentCustomizeTime = (value) => {
    if (value) {
      let currentCustomizeTime = '';

      let startOfCustomize = new Date(value[0]);

      startOfCustomize.setUTCHours(0, 0, 0, 0);

      startOfCustomize.setUTCDate(startOfCustomize.getDate() + 1);
      let endOfCustomize = new Date(value[1]);

      endOfCustomize.setUTCHours(23, 59, 59, 999);

      currentCustomizeTime = [startOfCustomize, endOfCustomize];

      return currentCustomizeTime = [startOfCustomize, endOfCustomize];
    }
  };

  const handleGetFilter = (value, type) => {
    let dateValue;
    if (value?.value === filterFields?.['Today']?.value) {
      dateValue = getCurrentDateTime();
    } else if (value?.value === filterFields?.['This week']?.value) {
      dateValue = getCurrentWeekTime();
    } else if (value?.value === filterFields?.['This month']?.value) {
      dateValue = getCurrentMontTime();
    } else if (value?.value === filterFields?.['Customize']?.value) {
      dateValue = initialStateDateFilter;
    } else {
      dateValue = '';
    }

    switch (type) {
      case EXPIRYDATE:
        setDataFilter({
          ...dataFilter,
          expiryDate: value,
          date: dateValue,
          dateUTC: getCurrentCustomizeDefaultTime(dateValue)
        });
        break;

      case DATE:
        setDataFilter({
          ...dataFilter,
          date: value,
          dateUTC: getCurrentCustomizeTime(value)
        });
        break;

      case SALESORDERVALUE:
        setDataFilter({
          ...dataFilter,
          salesOrderValue: value
        });
        break;

      case SALESTAFF:
        setDataFilter({
          ...dataFilter,
          saleStaff: value
        });
        break;

      case STORE:
        setDataFilter({
          ...dataFilter,
          store: value
        });
        break;

      case STATUS:
        setDataFilter({
          ...dataFilter,
          status: value
        });
        break;

      case CATEGORY:
        const found = dataFilter.category.some((obj) => {
          return obj?.value === value?.value;
        });

        if (found) {
          break;
        } else {
          dataFilter.category.push(value);

          setDataFilter({
            ...dataFilter
          });
          break;
        }
      default:
        setDataFilter(dataFilter);
        break;
    }
  };

  const handleRemoveItemCategory = (evt, id) => {
    evt.preventDefault();
    const newArray = dataFilter?.category?.filter((item) => item.id !== id);

    setDataFilter({
      ...dataFilter,
      category: newArray
    });
  };

  const onHandleReset = () => {
    onSetIsReset(true);

    setIsClearFilter(!isClearFilter);

    setDataFilter({
      expiryDate: expiryDateOptions[0],
      date: '',
      dateUTC: '',
      salesOrderValue: ['', ''],
      saleStaff: saleStaffOptions[0],
      store: currentStoreId
        ? {
          id: currentStoreId?.storeId,
          value: currentStoreId?.outletCode,
          label: currentStoreId?.storeName,
          outletCode: currentStoreId?.outletCode
        }
        : storeOptions[0],
      status: statusOptions[0],
      category: [categoryOptions[0]]
    });
  };

  const onHandleApply = (evt) => {
    onFilterSubmit(evt, dataFilter);

    setShowFilterPopup(false);
  };

  return (
    filterFields && (
      <div className='cp-page-header__btn'>
        <span className='cp-icon cp-icon--filter' onClick={() => setShowFilterPopup(!isShowFilterPopup)}></span>
        <div className={`cp-filter-popup-orders ${isShowFilterPopup ? 'show' : ''}`}>
          <form>
            <div className='cp-filter-popup-orders__wrap'>
              <div className='cp-filter-popup-orders__item'>
                <Text tag='span' className='cp-filter-popup-orders__item__label' field={filterFields['Expiry Date']}></Text>
                <div className='cp-filter-popup-orders__item__value'>
                  <Select
                    id='expiry-date'
                    onChange={(data) => handleGetFilter(data, EXPIRYDATE)}
                    value={dataFilter?.expiryDate}
                    options={expiryDateOptions}
                    className='cp-select'
                    classNamePrefix='cp-select'
                    isSearchable={true}
                  />
                </div>
              </div>
              {dataFilter?.expiryDate?.id === EXPIRED_DATE_CUSTOMIZE_ID && (
                <div className='cp-filter-popup-orders__item filter-date'>
                  <Text tag='span' className='cp-filter-popup-orders__item__label' field={filterFields['Date']} />
                  <div className='cp-filter-popup-orders__item__value'>
                    <DateRangePicker
                      selected={dataFilter?.date}
                      onChange={(date) => handleGetFilter(date, DATE)}
                      format='dd MMM yyyy'
                      dayPlaceholder='dd'
                      monthPlaceholder='mm'
                      yearPlaceholder='yyyy'
                      value={dataFilter?.date}
                      clearIcon={null}
                    />
                  </div>
                </div>
              )}
              <div className={'cp-filter-popup-orders__item filter-range'}>
                <Text tag='span' className='cp-filter-popup-orders__item__label' field={filterFields['Sales Order Value']} />
                <div className='cp-filter-popup-orders__item__value'>
                  <FilterRange
                    onChangeRange={(data) => handleGetFilter(data, SALESORDERVALUE)}
                    minRange={MINRANGE}
                    maxRange={MAXRANGE}
                    isClearFilter={isClearFilter}
                  />
                </div>
              </div>
              {isCPManager && (
                <>
                  <div className='cp-filter-popup-orders__item'>
                    <Text tag='span' className='cp-filter-popup-orders__item__label' field={filterFields['Sale Staff']}></Text>
                    <div className='cp-filter-popup-orders__item__value'>
                      <Select
                        id='sale-staff'
                        onChange={(data) => handleGetFilter(data, SALESTAFF)}
                        value={dataFilter?.saleStaff}
                        options={saleStaffOptions}
                        className='cp-select'
                        classNamePrefix='cp-select'
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className='cp-filter-popup-orders__item'>
                    <Text tag='span' className='cp-filter-popup-orders__item__label' field={filterFields['Store']}></Text>
                    <div className='cp-filter-popup-orders__item__value'>
                      <Select
                        id='store'
                        onChange={(data) => handleGetFilter(data, STORE)}
                        value={dataFilter?.store}
                        options={storeOptions}
                        className='cp-select'
                        classNamePrefix='cp-select'
                        isSearchable={true}
                        isDisabled={currentStoreId ? true : false}
                      />
                    </div>
                  </div>
                </>
              )}
              <div className='cp-filter-popup-orders__item'>
                <Text tag='span' className='cp-filter-popup-orders__item__label' field={filterFields['Status']} />
                <div className='cp-filter-popup-orders__item__value'>
                  <Select
                    id='status'
                    onChange={(data) => handleGetFilter(data, STATUS)}
                    value={dataFilter?.status}
                    options={statusOptions}
                    className='cp-select'
                    classNamePrefix='cp-select'
                    isSearchable={true}
                  />
                </div>
              </div>
              <div className='cp-filter-popup-orders__item'>
                <Text tag='span' className='cp-filter-popup-orders__item__label' field={filterFields['Category']}></Text>
                <div className='cp-filter-popup-orders__item__value'>
                  <Select
                    id='category'
                    onChange={(data) => handleGetFilter(data, CATEGORY)}
                    value={dataFilter?.category}
                    options={categoryOptions}
                    className='cp-select'
                    classNamePrefix='cp-select'
                    isSearchable={true}
                  />
                </div>
              </div>
              {dataFilter?.category?.length > 0 && (
                <div className='cp-filter-popup-orders__item-category'>
                  {dataFilter?.category?.map((item) => {
                    return (
                      item?.value && (
                        <div key={item.id} className='cp-filter-popup-orders__item-category__value'>
                          {item?.value}
                          <button
                            className='icon-close cp-filter-popup-orders__item-category__btn'
                            onClick={(evt) => handleRemoveItemCategory(evt, item?.id)}
                          ></button>
                        </div>
                      )
                    );
                  })}
                </div>
              )}
            </div>
            <div className='cp-filter-popup-orders__btn'>
              <button type='button' className='cp-filter-popup-orders__btn__item btn btn-outline-primary' onClick={() => onHandleReset()}>
                <Text field={filterFields?.Reset} />
              </button>
              <button type='submit' className='cp-filter-popup-orders__btn__item btn btn-primary' onClick={onHandleApply}>
                <Text field={filterFields?.Apply} />
              </button>
            </div>
          </form>
        </div>
      </div>
    )
  );
};

CPFilterPopupOrders.propTypes = {
  filterFields: PropTypes.any,
  onFilterSubmit: PropTypes.any,
  onSetIsReset: PropTypes.any,
  currentStoreId: PropTypes.any
};

export default CPFilterPopupOrders;
