import './PartnerSpecialDetail.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import PartnerSpecialsLandingModel from './models/PartnerSpecialsLandingModel';
import PartnerSpecialDetailAccordion from './PartnerSpecialDetailAccordion';
import PartnerSpecialsDetailLogo from './PartnerSpecialsDetailLogo';

const PartnerSpecialsLanding = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new PartnerSpecialsLandingModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    <div className='container partner-special-detail'>
      <PartnerSpecialsDetailLogo layoutData={layoutData} />
      <PartnerSpecialDetailAccordion layoutData={layoutData} />
    </div>
  ) : (<></>);
};

PartnerSpecialsLanding.propTypes = {
  fields: object
};

export default PartnerSpecialsLanding;
