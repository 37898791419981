import './RichText.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import { CART_DETAIL_PAGE_NAME } from '../../SlicingPage/CartDetails/configs';
import AddToCartSuccess from './AddToCartSuccess';
import OsimRichTextModelModel from './model/OsimRichTextModelModel';
import RichTextShimmer from './RichTextShimmer';

/**
 * Demonstrates usage of a Rich Text (HTML) content field within JSS.
 */
const OsimRichText = (props) => {
  const { fields } = props;
  const [inputData, setInputData] = useState(null);
  const currentPageName = SitecoreContextFactoryService.getValueContextRouteItem('name');

  useEffect(() => {
    const model = new OsimRichTextModelModel();

    setInputData(model.getData(deepCopy(fields) || {}));
  }, []);

  const isCartPage = useMemo(() => {
    return inputData?.IsCartPage?.value;
  }, [inputData]);

  const isInCartDetailPage = useMemo(() => {
    return isCartPage && currentPageName?.toLowerCase() === CART_DETAIL_PAGE_NAME;
  }, [isCartPage, currentPageName]);

  return inputData ? (
    inputData?.['Remove Default Container']?.value === true || inputData?.['Remove Default Container']?.value === 'true' ? (
      <RichText field={inputData.Text} />
    ) : isInCartDetailPage ? (
      <AddToCartSuccess inputData={inputData} />
    ) : (
      <div className='rich-text-content'>
        <div className='container'>
          <RichText field={inputData.Text} />
        </div>
      </div>
    )
  ) : (
    <RichTextShimmer />
  );
};

OsimRichText.propTypes = {
  fields: PropTypes.object
};

export default OsimRichText;
