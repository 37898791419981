import * as settingGlobalTypes from '@redux/actions/settingGlobal/settingGlobalTypes';

export const getSettingGlobal = (searchTerm) => ({
  type: settingGlobalTypes.GET_SETTING_GLOBAL,
  searchTerm
});

export const getSettingGlobalSuccess = (payload) => ({
  type: settingGlobalTypes.GET_SETTING_GLOBAL_SUCCESS,
  payload
});

export const getSettingGlobalFailed = (error) => ({
  type: settingGlobalTypes.GET_SETTING_GLOBAL_FAILED,
  error
});
