import { combineReducers } from 'redux';

import cancelEventBookingReducer from './cancelEventBooking';
import eventBookingRegisterReducer from './eventBookingRegister';
import eventBookingRescheduleReducer from './eventBookingReschedule';

export const eventBookingReducer = combineReducers({
  eventBookingRegisterReducer,
  cancelEventBookingReducer,
  eventBookingRescheduleReducer
});
