import IsAuthenticatedModel from '@components/Share/FooterNavigation/model/IsAuthenticatedModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import ExtraLinkItemModel from './ExtraLinkItemModel';

export default class MainNavigationItemsModel {
  constructor() {
    this.Link = new SitecoreLinkModel();

    this.Title = new SitecoreTextModel();

    this['Is Authenticated'] = new IsAuthenticatedModel();

    this['items'] = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link') && dataSource.Link.value.href) {
      this.Link.getData(dataSource['Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Is Authenticated')) {
      this['Is Authenticated'].getData(dataSource['Is Authenticated']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      dataSource['items'].map((element) => {
        this['items'].push(new ExtraLinkItemModel().getData(deepCopy(element) || {}));
      });
    }

    return this;
  }
}
