import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { RichText } from '@sitecore-jss/sitecore-jss-react';

import SupportCenterTitleModel from './models/SupportCenterTitleModel';

const SupportCenterTitle = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    setLayoutData(new SupportCenterTitleModel().getData(fields));
  }, []);
  
  return layoutData ? (
    <div className='container my-account-header__heading animated-slow fadeIn delay-100'>
      <RichText tag='h4' field={{ value: layoutData?.Title }} className='my-account-header__heading__title' />
    </div>
  ) : (
    <></>
  );
};

SupportCenterTitle.propTypes = {
  fields: object
};

export default SupportCenterTitle;
