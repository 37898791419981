import ProductCardInterfaceModel from './ProductCardInterfaceModel';

export default class ProductCardInterfaceWishListModel {
  constructor() {
    this.ExternalWishListLineId = '';

    this.DateCreated = '';

    this.Product = new ProductCardInterfaceModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ExternalWishListLineId')) {
      this.ExternalWishListLineId = dataSource.ExternalWishListLineId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DateCreated')) {
      this.DateCreated = dataSource.DateCreated;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product')) {
      this.Product.getData(dataSource.Product || {});
    }

    return this;
  }
}
