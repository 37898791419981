import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {LEAD_MANAGEMENT_PAGESZ} from '@components/ClientPortal/LeadManagement/configs';
import * as actions from '@redux/actions/cpLeadManagement';
import * as cpLeadManagementTypes from '@redux/actions/cpLeadManagement/cpLeadManagementTypes';
import { post } from '@services/genericService';

import * as config from '../config';

export const cpStaffLeadManagementEpic = (action$) =>
  action$.pipe(
    ofType(cpLeadManagementTypes.STAFF_LEAD_MANAGEMENT),
    switchMap((action) => {
      const params = {
        Term: action.payload.Term,
        PageNumber: action.payload.PageNumber,
        PageSize: LEAD_MANAGEMENT_PAGESZ,
        Date: action.payload.Date,
        MinTotalSpent: action.payload.MinTotalSpent,
        MaxTotalSpent: action.payload.MaxTotalSpent,
        IsSpent: action.payload.IsSpent,
        FromDate: action.payload.FromDate,
        ToDate: action.payload.ToDate
      };

      return from(post(config.ACTION_STAFF_LEAD_MANAGEMENT, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.staffLeadManagementSuccess({
              ...res.data.LeadManagements
            });
          } else {
            return actions.staffLeadManagementFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.staffLeadManagementFailed(error.message || 'Failed')))
      );
    })
  );

export const cpManagerLeadManagementEpic = (action$) =>
  action$.pipe(
    ofType(cpLeadManagementTypes.MANAGER_LEAD_MANAGEMENT),
    switchMap((action) => {
      const params = {
        PageSize: LEAD_MANAGEMENT_PAGESZ,
        ...action.payload
      };

      return from(post(config.ACTION_MANAGER_LEAD_MANAGEMENT, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.managerLeadManagementSuccess({
              ...res.data.LeadManagements
            });
          } else {
            return actions.managerLeadManagementFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.managerLeadManagementFailed(error.message || 'Failed')))
      );
    })
  );

export const getSaleStaffsEpic = (action$) =>
  action$.pipe(
    ofType(cpLeadManagementTypes.GET_SALE_STAFFS),
    switchMap((action) => {
      const params = {
        managerId: action.payload.managerId,
      };

      return from(post(config.ACTION_GET_SALE_STAFFS, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getSaleStaffsSuccess({
              ...res.data
            });
          } else {
            return actions.getSaleStaffsFailed(res.data?.Errors?.[0] || 'Failed');
          }
        }),
        catchError((error) => of(actions.getSaleStaffsFailed(error.message || 'Failed')))
      );
    })
  );
 
