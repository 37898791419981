import './ProductVariantInfo.scss';

import Proptypes from 'prop-types';
import React from 'react';

const ProductVariantInfoShimmer = (props) => {
  const { dataContent } = props;

  return (
    <div className='product-variant-info skeleton__item'>
      <div className='product-variant-info__content'>
        <h2 className='product-variant-info__content__name'>
          <span className='text-line'></span>
        </h2>
        <div className='product-variant-info__content__price'>
          <span className='product-variant-info__content__selling-price'>
            <span className='text-line'></span>
          </span>
        </div>
        <span className='product-variant-info__content__description'><span className='text-line'></span></span>
      </div>
    </div>
  );
};

ProductVariantInfoShimmer.propTypes = {
  dataContent: Proptypes.any,
  children: Proptypes.any
};

export default ProductVariantInfoShimmer;
