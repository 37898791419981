import Proptypes from 'prop-types';
import React, { forwardRef, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import SwipeComponent from '@components/Share/SwipeComponent';
import { addDepositFreeGift } from '@redux/actions/buyingOptions';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { params } from '../configs';

export const BALANCE_FREE_GIFT_KEY = 'BalanceFreeGifts';

export const DEPOSIT_FREE_GIFT_KEY = 'DepositFreeGifts';

const FreeGiftDeposit = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const { dataFields, onFreeGiftData } = props;
  const { freeGiftDepositSelected } = useSelector((state) => state.buyingOptionReducer.BOFreeGiftsReducer);

  const handleSelectFreeGift = useCallback(
    (freeGiftKey, freeGiftData) => {
      const freeGiftSelectTemp = { ...freeGiftDepositSelected };

      freeGiftSelectTemp[freeGiftKey] = { ...freeGiftData };

      dispatch(addDepositFreeGift(freeGiftSelectTemp));
    },
    [freeGiftDepositSelected]
  );

  const depositFreeGiftData = useMemo(() => {
    const data = [];
    if (Object.keys(onFreeGiftData).length) {
      for (const [key, value] of Object.entries(onFreeGiftData)) {
        if (key === DEPOSIT_FREE_GIFT_KEY) {
          data.push({
            key: key,
            order: 1,
            freeGiftData: [...value],
            label: dataFields?.['Deposit Gift Label']
          });
        }
        if (key === BALANCE_FREE_GIFT_KEY) {
          data.push({
            key: key,
            order: 2,
            freeGiftData: [...value],
            label: dataFields?.['Balance Gift Label']
          });
        }
      }
    }

    return data.sort((a, b) => a.order - b.order);
  }, [onFreeGiftData, dataFields]);

  return depositFreeGiftData.length ? (
    <div className='freegift-buying-list'>
      {depositFreeGiftData.map(({ freeGiftData, label, key }, index) =>
        freeGiftData.length ? (
          <div key={index} className='freegift-buying-list__item bo-free-gift__group'>
            <Text field={label} className='item__name bo-free-gift__group__title' />
            <SwipeComponent param={params} setRef={ref}>
              {freeGiftData.map((item) => (
                <div
                  key={item?.ProductId}
                  className={
                    `item__variant bo-free-gift__item ${
                      freeGiftDepositSelected?.[key]?.ProductId === item?.ProductId && freeGiftDepositSelected?.[key]?.VariantId === item?.VariantId
                        ? ' active'
                        : ''
                    }`
                    //+ `${isDisableItems(activeItemsId, item.Quantity, item.VariantId) && !isActiveItems(activeItemsId, '') ? ' disable' : ''}`
                  }
                  onClick={() => handleSelectFreeGift(key, item)}
                >
                  <div className='item__variant__image bo-free-gift__item__image'>
                    <img className='swiper-lazy animated' src={`${item.Image}`} alt='Product Image' />
                    <div className='swiper-lazy-preloader swiper-lazy-preloader-white' />
                  </div>
                  <div className='item__variant__name bo-free-gift__item__name' title={item.Name !== '' ? item.Name : item.DisplayName}>
                    {item.Name !== '' ? item.Name : item.DisplayName}
                  </div>
                </div>
              ))}
            </SwipeComponent>
          </div>
        ) : (
          <></>
        )
      )}
    </div>
  ) : (
    <></>
  );
});

FreeGiftDeposit.displayName = 'FreeGiftDeposit';

FreeGiftDeposit.propTypes = {
  dataFields: Proptypes.any,
  onFreeGiftData: Proptypes.any
};

export default FreeGiftDeposit;
