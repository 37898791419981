import produce from 'immer';

import * as singleSignOnTypes from '@redux/actions/singleSignOn/singleSignOnTypes';

const initState = {
  isLoading: false,
  isMessage: false,
  isForgotPassword: false,
  errMessage: '',
};

const forgotPasswordReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case singleSignOnTypes.FORGOT_PASSWORD:
      draft.isLoading = true;

      draft.isMessage = false;

      draft.errMessage = '';

      break;

    case singleSignOnTypes.FORGOT_PASSWORD_SUCCESS:
      draft.isLoading = false;

      draft.isMessage = true;

      draft.isForgotPassword = true;
      break;
      
    case singleSignOnTypes.FORGOT_PASSWORD_FAILED:
      draft.isLoading = false;

      draft.isMessage = true;

      draft.isForgotPassword = false;

      draft.errMessage = action.error;
      break;

    }

    return draft;
  });

export default forgotPasswordReducer;
