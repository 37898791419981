import Proptypes from 'prop-types';
import React, { Fragment } from 'react';

import Global from '@utils/global';

const HighlightedText = ({text, hightlightText}) => {

  if (!hightlightText.trim()) {
    return <span>{text}</span>;
  }
  const regex = new RegExp(`(${Global.escapeRegex(hightlightText)})`, 'gi');
  const parts = text.split(regex);

  return (
    <Fragment>
      {parts.filter(part => part).map((part, i) => (
        regex.test(part) ? <b key={i}>{part}</b> : <span key={i}>{part}</span>
      ))}
    </Fragment>
  );
};

HighlightedText.propTypes = {
  hightlightText: Proptypes.any,
  text: Proptypes.any
};

export default HighlightedText;