import './Loading.scss';

import PropTypes from 'prop-types'; 
import React from 'react';

const Loading = ({classNameCustom}) => {
  return (
    <div className={classNameCustom ? `${classNameCustom} loading` : 'loading'}>
      <div className='loading__default'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  );
};

Loading.propTypes = {
  classNameCustom: PropTypes.string
};

export default Loading;