import produce from 'immer';

import * as commonTypes from '@redux/actions/common/commonTypes';
import { deepCopy } from '@utils/utility';

import WellnessArticleApiModel from '../models/WellnessArticleApiModel';

const initState = {
  wellnessSearchData: [],
  wellnessSuggestionList: [],
  wellnessCount: 0,
  error: '',
  isLoading: false,
  isAutoCompleteLoading: false,
  params: null
};

const wellnessSearchReducer = (state = initState, action) =>
  produce(state, (draft) => {
    let newWellnessSearchData = [];

    switch (action.type) {
    // SEARCH
    case commonTypes.COMMON_SEARCH_WELLNESS:
      draft.params = action.params;

      draft.isLoading = true;
      break;

    case commonTypes.COMMON_SEARCH_WELLNESS_SUCCESS:
      if (action.payload) {
        action.payload.map(item => {
          newWellnessSearchData.push(new WellnessArticleApiModel().getData(item || {}));
        });

        draft.wellnessSearchData = deepCopy(newWellnessSearchData);
      }

      draft.wellnessCount = action.count;

      draft.isLoading = false;
      break;
    
    case commonTypes.COMMON_SEARCH_WELLNESS_FAILED:
      draft.isLoading = false;

      draft.error = action.error;

      break;

    // AUTO COMPLETE
    case commonTypes.GET_AUTO_COMPLETE_WELLNESS:

      draft.isAutoCompleteLoading = true;

      break;

    case commonTypes.GET_AUTO_COMPLETE_WELLNESS_SUCCESS:
      draft.wellnessSuggestionList = action.payload;

      draft.isAutoCompleteLoading = false;

      break;
    
    case commonTypes.GET_AUTO_COMPLETE_WELLNESS_FAILED:

      draft.error = action.error;

      draft.isAutoCompleteLoading = false;

      break;
    }

    return draft;
  });

export default wellnessSearchReducer;
