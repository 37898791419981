import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { behaviorSubjectSubmitForm } from '@components/Form/formBehavior';
import Global from '@utils/global';

import FormFieldError from '../../FormFieldError';
// import Message from '@components/Share/Message';
import CustomLabel from '../CustomLabel';

const EmailValidator = (props) => {
  // const { tracker, field, errors } = props;
  // console.log(tracker, 'tracker');
  // const onChange = (e) => {
  //   const newValue = e.target.value;
  //   let valid = true;
  //   let errorMessages = [];
  //   let patt = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,17}$');
  //   if (field.model.required && !newValue) {
  //     valid = false;
  //     // errorMessages.push(<Message messageCode='Msg_001.3'/>);
  //     errorMessages.push(field.model.validationDataModels?.[0]?.message);
  //   }
  //   if (field.model.required && !patt.test(newValue) && newValue) {
  //     valid = false;
  //     errorMessages.push(field.model.validationDataModels?.[0]?.message);
  //   }
  //   props.onChange(field.valueField.name, e.target.value, valid, errorMessages);
  // };
  // const onFocus = (e) => {
  //   tracker.onFocusField(field, e.target.value);
  // };
  // const onBlur = (e) => {
  //   tracker.onBlurField(field, e.target.value, errors);
  // };
  // return (
  //   <div className={`form-group form__field form__field--email signup-form__form__input ${props.errors.length ? 'form-field--errors' : ''}`}>
  //     {/* {field.model.title ? <LabelComponentForm {...props} /> : ''} */}
  //     <input
  //       className={`form-control-lg ${field.model.cssClass ? field.model.cssClass : 'signup-form__form__input__email'}`}
  //       onChange={onChange}
  //       onFocus={onFocus}
  //       onBlur={onBlur}
  //       placeholder={field.model.placeholderText}
  //       type='email'
  //       name={field.valueField.name}
  //       id={field.valueField.id}
  //       form='novalidatedform'
  //     />
  //     <label htmlFor={field.valueField?.id} className='form-group__label'>
  //       {field.model?.title}
  //     </label>
  //     <FormFieldError {...props} />
  //     <form id='novalidatedform' noValidate />
  //   </div>
  // );
  const [label, setLabel] = useState(false);
  const [border, setBorder] = useState(true);
  const { field, value, onChange, tracker, errors } = props;

  useEffect(() => {
    const behaviorSubjectSubmitFormSubscription = behaviorSubjectSubmitForm.subscribe((params) => {
      setLabel(params.label);
    });

    return () => {
      behaviorSubjectSubmitFormSubscription.unsubscribe();
    };
  }, [behaviorSubjectSubmitForm]);

  function handleOnChange(field, fieldValue, callback) {
    let valid = true;
    const errorMessages = [];

    setLabel(fieldValue.length === 0 ? false : true);

    // custom client validation logic here
    if (field.model.required && !fieldValue) {
      valid = false;

      setBorder(false);

      errorMessages.push(`${field.model.title} is required`);
    } else {
      setBorder(true);
    }

    callback(field.valueField.name, fieldValue, valid, errorMessages);
  }

  return (
    <div className={Global.renderDynamicClass(!border, 'form-group', 'input-error-validate')}>
      <input
        type='email'
        className={Global.renderDynamicClass(label, 'form-control form-control-lg', 'input-valid')}
        id={field.valueField.id}
        name={field.valueField.name}
        value={value}
        maxLength={field.model.maxLength}
        placeholder={field.model.placeholderText}
        onChange={(e) => handleOnChange(field, e.target.value, onChange)}
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
        form='novalidatedform'
      />
      <CustomLabel {...props} />
      <FormFieldError {...props} />
      <div id='novalidatedform' noValidate />
    </div>
  );
};

EmailValidator.propTypes = {
  tracker: PropTypes.any,
  field: PropTypes.any,
  errors: PropTypes.any,
  onChange: PropTypes.any,
  value: PropTypes.any
};

export default EmailValidator;
