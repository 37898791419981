import './CartTotal.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import OfferAppliedModal from '@components/SlicingPage/CartDetails/CartSummary/OfferAppliedModal';
import { getAppliedCouponList } from '@redux/actions/coupon';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';
import Global from '@utils/global';

import TaxMessageEl from '../../TaxMessageDetail/TaxMessageEl';

const NUMB = '{seq}';

const CartTotal = ({ cartData, layoutData }) => {
  const dispatch = useDispatch();
  const listAppliedPromotion = useSelector((state) => state.couponReducer.appliedPromotionList);
  const [showPromotion, setShowPromotion] = useState(false);
  const [popUpDetailCouponList, setPopUpDetailCouponList] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [installmentCartLevel, setInstallmentCartLevel] = useState(null);
  const [installmentProductLevel, setInstallmentProductLevel] = useState(null);
  const [installmentCartLevelAndProductLevel, setInstallmentCartLevelAndProductLevel] = useState(null);
  const [isDeposit, setIsDeposit] = useState(false);


  useEffect(() => {
    if (cartData?.Lines && cartData?.Lines?.length > 0) {
      setIsDeposit(checkAllLinesIsPosit(cartData.Lines));
    }
  }, [cartData]);

  const checkAllLinesIsPosit = (data) => {
    return data.some((item) => item.Deposit);
  };

  useEffect(() => {
    dispatch(getAppliedCouponList());
  }, []);

  useEffect(() => {
    if (cartData?.PaymentConfiguration?.Gateways?.length) {
      setInstallmentCartLevel(
        cartData?.PaymentConfiguration?.Gateways.filter(
          (gateway) =>
            cartData?.Lines?.length &&
            gateway?.SupportInstallment &&
            gateway?.InstallmentCartLevel &&
            !gateway?.InstallmentProductLevel &&
            gateway?.InstallmentPeriods?.length &&
            ((!gateway?.InstallmentMaxCartAmount && cartData?.TotalAmount >= gateway?.InstallmentMinCartAmount) ||
              (gateway?.InstallmentMaxCartAmount &&
                cartData?.TotalAmount >= gateway?.InstallmentMinCartAmount &&
                cartData?.TotalAmount < gateway?.InstallmentMaxCartAmount))
        )
      );
      if (cartData?.Lines?.some((line) => line?.InstallmentPeriods?.[0]?.length)) {
        setInstallmentProductLevel(
          cartData?.PaymentConfiguration?.Gateways.filter(
            (gateway) => gateway?.SupportInstallment && gateway?.InstallmentProductLevel && !gateway?.InstallmentCartLevel
          )
        );

        setInstallmentCartLevelAndProductLevel(
          cartData?.PaymentConfiguration?.Gateways.filter(
            (gateway) =>
              gateway?.SupportInstallment &&
              gateway?.InstallmentProductLevel &&
              gateway?.InstallmentCartLevel &&
              ((!gateway?.InstallmentMaxCartAmount && cartData?.TotalAmount >= gateway?.InstallmentMinCartAmount) ||
                (gateway?.InstallmentMaxCartAmount &&
                  cartData?.TotalAmount >= gateway?.InstallmentMinCartAmount &&
                  cartData?.TotalAmount < gateway?.InstallmentMaxCartAmount))
          )
        );
      } else {
        setInstallmentProductLevel(null);

        setInstallmentCartLevelAndProductLevel(null);
      }
    }
  }, [cartData]);

  const onAppliedOffersModalComponentHandler = (status, data) => {
    setPopUpDetailCouponList(status);

    setShowPromotion(!status);

    setPopupDetail(data);
  };

  return cartData ? (
    <div className='cart-total large-text'>
      <div className='container'>
        <div className='row'>
          <div className='col-lg-6'></div>
          <div className='col-lg-6 cart-total__total-amount cart-summary__total-amount'>
            <ul className='cart-summary__group'>
              <li className='group-contents'>
                <label className='contents-left'>{layoutData['SF Pickup Subtotal Label'].value}</label>
                <div className='text-right'>{cartData.Subtotal}</div>
              </li>
              <li className='group-contents'>
                <label className='contents-left'>
                  {layoutData['Billing Discount Label'].value}
                  {listAppliedPromotion.length > 0 ? (
                    <span className='sub-text' onClick={() => setShowPromotion(true)}>
                      {layoutData['Offers Applied Label'].value.replace(NUMB, listAppliedPromotion.length)}
                    </span>
                  ) : (
                    ''
                  )}
                </label>
                <div className='text-right'>-{cartData.Discount}</div>
              </li>
              {cartData.IsDeliveryFee ? (
                <li className='group-contents group-contents--has-sub'>
                  <label className='contents-left'>{layoutData['Billing Delivery Fee Label'].value}</label>
                  {/* <div className='text-right'>{cartData.DeliveryTotal}</div> */}
                  {cartData.IsFreeship ? (
                    <div className='text-right'>
                      <Message messageCode='LBL-Free' />
                    </div>
                  ) : (
                    <div className='text-right'>{cartData.DeliveryTotalAmount > 0 ? cartData.DeliveryTotal : <Message messageCode='LBL-Free' />}</div>
                  )}
                </li>
              ) : (
                ''
              )}
              {cartData.IsDeliveryPickupFee ? (
                <li className='group-contents'>
                  <Text field={layoutData['Pick Up Fee Label']} />
                  <div className='text-right'>{cartData.SFExpressTotalAmount > 0 ? cartData.SFExpressTotal : <Message messageCode='LBL-Free' />}</div>
                </li>
              ) : (
                ''
              )}
              {layoutData['Show Sales Tax'] ? (
                <li className='group-contents'>
                  <label className='contents-left'>{layoutData['Billing Sale Tax Label'].value}</label>
                  <div className='text-right'>{cartData.TaxTotal}</div>
                </li>
              ) : (
                ''
              )}
            </ul>
            <ul className='cart-total__total-amount__last-list'>
              <li className='group-contents group-contents--has-sub cart-total__total-amount__total-price cart-summary__total-amount__total-price'>
                <label className='contents-left'>{layoutData['Billing Total Label'].value}</label>
                <div className='text-right'>{cartData.Total}</div>
              </li>
              <li className='cart-summary__tax-message'>
                <TaxMessageEl />
              </li>
              {!isDeposit && (
                <>
                  <li className='group-contents group-contents--align-baseline' style={{ justifyContent: isDeposit ? 'flex-end' : '' }}>
                    <div className='contents-left'>
                      {installmentCartLevel?.length ? (
                        <div className='installment-info'>
                          {installmentCartLevel?.map((gateway) => {
                            return (
                              <span className='installment-info__text' key={gateway?.Id}>
                                {gateway?.CartText.replace(
                                  '{number_of_payments}',
                                  gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period
                                )
                                  .replace(
                                    '${installment_amount}',
                                    Math.round(
                                      (cartData?.TotalAmount /
                                        gateway?.InstallmentPeriods.slice().sort((a, b) => a?.Period - b?.Period)?.[0]?.Period) *
                                      1000
                                    ) / 1000
                                  )
                                  .replace('{logo}', '')}
                                {gateway?.CartText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                                  <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                      {installmentProductLevel?.length ? (
                        <div className='installment-info'>
                          {installmentProductLevel?.map((gateway) => {
                            return (
                              <span className='installment-info__text' key={gateway?.Id}>
                                {gateway?.CartText.replace(
                                  '{number_of_payments}',
                                  Global.onGetMinInstallmentPeriodsInCartAndReview(cartData?.Lines.filter((line) => line?.InstallmentPeriods?.length))
                                )
                                  .replace(
                                    '${installment_amount}',
                                    Math.round(
                                      (cartData?.TotalAmount /
                                        Global.onGetMinInstallmentPeriodsInCartAndReview(
                                          cartData?.Lines.filter((line) => line?.InstallmentPeriods?.length)
                                        )) *
                                      1000
                                    ) / 1000
                                  )
                                  .replace('{logo}', '')}
                                {gateway?.CartText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                                  <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                      {installmentCartLevelAndProductLevel?.length ? (
                        <div className='installment-info'>
                          {installmentCartLevelAndProductLevel?.map((gateway) => {
                            return (
                              <span className='installment-info__text' key={gateway?.Id}>
                                {gateway?.CartText.replace(
                                  '{number_of_payments}',
                                  Global.onGetMinInstallmentPeriodsInCartAndReview(cartData?.Lines.filter((line) => line?.InstallmentPeriods?.length))
                                )
                                  .replace(
                                    '${installment_amount}',
                                    Math.round(
                                      (cartData?.TotalAmount /
                                        Global.onGetMinInstallmentPeriodsInCartAndReview(
                                          cartData?.Lines.filter((line) => line?.InstallmentPeriods?.length)
                                        )) *
                                      1000
                                    ) / 1000
                                  )
                                  .replace('{logo}', '')}
                                {gateway?.CartText.includes('{logo}') && gateway?.PdpLogoItemUrl ? (
                                  <img className='installment-img' src={gateway?.PdpLogoItemUrl} />
                                ) : (
                                  <></>
                                )}
                              </span>
                            );
                          })}
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className='text-right text-line-through link-text link-text--gray-darker'>{cartData.OriginalTotal}</div>
                  </li>
                  <li className='group-contents'>
                    <label className='contents-left'>{layoutData['Billing Total Saving Label'].value}</label>
                    <div className='text-right'>{cartData.SavingTotal}</div>
                  </li>
                </>
              )}
            </ul>
          </div>
        </div>
      </div>
      <ModalComponent
        isShow={showPromotion}
        titlePopup={<Text field={layoutData['Applied Offers Title']} tag='span' />}
        onCloseHandler={() => setShowPromotion(false)}
      >
        <OfferAppliedModal
          dataFields={layoutData}
          listPromotion={listAppliedPromotion}
          onAppliedOffersModalComponentHandler={onAppliedOffersModalComponentHandler}
        ></OfferAppliedModal>
      </ModalComponent>
      <ModalComponent
        isShow={popUpDetailCouponList}
        onCloseHandler={() => onAppliedOffersModalComponentHandler(false, popupDetail)}
        titlePopup={<Text field={layoutData['Coupon Detail Title']} />}
      >
        <div className='osim-popup__body coupon-popup__info'>
          <h4 className='coupon-popup__info-title'>
            {popupDetail.Title}
            <span> [{popupDetail.Code}]</span>
          </h4>
          <div className='coupon-popup__description'>{popupDetail.Description}</div>
          {popUpDetailCouponList ? (
            <span className='coupon-popup__info-validity'>
              <Text field={layoutData['Valid Until Label']} tag='strong' /> {global.formatDateDDMYYY(popupDetail.ValidityTo)}
            </span>
          ) : (
            <></>
          )}
        </div>
      </ModalComponent>
    </div>
  ) : null;
};

CartTotal.propTypes = {
  cartData: PropTypes.object,
  layoutData: PropTypes.any
};

const mapStateToProps = (state) => {
  const currentDefaultCartData = state.productReducer.cartDetailReducer?.currentCart || null;
  const clientSOCartData = state.personalizeOfferReducer.getClientSOCartReducer.data?.OsimCart || null;

  return {
    cartData: clientSOCartData || currentDefaultCartData
  };
};

export default connect(mapStateToProps)(CartTotal);
