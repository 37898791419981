import produce from 'immer';

import { deepCopy } from '@utils/utility';

import {
  GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA,
  GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_FAILED,
  GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_SUCCESS
} from '../../actions/categoryForRegisterWarranty/categoryForRegisterWarranryTypes';

const initialState = {
  isLoading: false,
  categoryDataForRegister: [],
  error: null
};

const categoryForRegisterWarrantyReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA:
      draft.isLoading = true;
      break;

    case GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_SUCCESS:
      draft.isLoading = false;

      draft.categoryDataForRegister = deepCopy(
        action.payload.map((val) => {
          return {
            CategoryItemId: val.CategoryItemId.replace(/[{}]/gm, ''),
            CategoryName: val.CategoryName
          };
        })
      );
      break;

    case GET_CATEGORY_FOR_REGISTER_WARRANTY_DATA_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default categoryForRegisterWarrantyReducer;
