import { combineEpics } from 'redux-observable';

import { customerReviewsAverageEpic } from './customerReviewsAverage';
import { getBundleElementEpic } from './getBundleElement';
import { getCatalogHeaderNavigationEpic } from './getCatalogHeaderNavigation';
import { getDynamicBundleElementEpic } from './getDynamicBundleElement';
import { getLatestReviewEpic } from './getLatestReview';
import { getProductReviewByIdEpic } from './getProductReviewById';
import { getProductsReviewEpic } from './getProductReviews';
import { getProductsNameEpic } from './getProductsName';
import { serialNumberFormatCheckerEpic,submitProductReviewEpic } from './submitProductReview';
import { postUploadListFilesEpic } from './uploadListFiles';

const productReviewEpic = combineEpics(
  postUploadListFilesEpic,
  customerReviewsAverageEpic,
  getProductsReviewEpic,
  getProductsNameEpic,
  submitProductReviewEpic,
  getLatestReviewEpic,
  serialNumberFormatCheckerEpic,
  getCatalogHeaderNavigationEpic,
  getProductReviewByIdEpic,
  getBundleElementEpic,
  getDynamicBundleElementEpic
);

export default productReviewEpic;
