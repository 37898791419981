import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import FeaturesBarModel from './FeaturesBarModel';

const TEXTARR = [
  '2nd Background Color Code',
  'Background Color Code',
  'CTA Button',
  'Description',
  'HeaderTitle Text',
  'Title Text',
  'Extra Information',
  'Feature Title',
  'Subtitle Text',
  'Button Background Color Code',
  'Button Text Color Code',
  'Content Text Color Code',
  'Header Background Color Code',
  'Header Text Color Code',
  'Body Background Color Code',
  'Body Text Color Code',
  'Left Text Align'
];

const LINKARR = [
  'CTA Button Link',
];

const IMGARR = [
  '2nd Background Image',
  '2nd Background Image Mobile',
  'Background Image',
  'Background Image Mobile',
  'Background Product Image',
  'Product Image',
  'Icon'
];

export default class BannerWithPopupModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    IMGARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    this['Features bar'] = [];

    this['Feature Items Modal Position'] = {};
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMGARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Features bar')) {
      let newItemsList = [];

      dataSource['Features bar'].map((element) => {
        newItemsList.push(new FeaturesBarModel().getData(deepCopy(element) || {}));
      });

      this['Features bar'] = newItemsList;
    }
    if(dataSource && Object.prototype.hasOwnProperty.call(dataSource,'Feature Items Modal Position')){
      this['Feature Items Modal Position'] = deepCopy(dataSource['Feature Items Modal Position']);
    }

    return this;
  }
}
