import PropTypes from 'prop-types';
import React from 'react';

const ExperienceEditorError = (props) => {
  return <div className='experience-editor-error'>{props.children}</div>;
};

ExperienceEditorError.propTypes = {
  children: PropTypes.any
};

export default ExperienceEditorError;
