import PropTypes from 'prop-types';
import React, {Fragment, useEffect, useMemo, useState} from 'react';
import {connect, useDispatch, useSelector} from 'react-redux';

import CartTotal from '@components/CheckoutPage/CartTotal';
import Coupon from '@components/CheckoutPage/Coupon';
import DeliveryComponent from '@components/FulfillmentOptions/DeliveryComponent';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import LoadingMask from '@components/Share/LoadingMask';
import { getClientSOCartStart } from '@redux/actions/personalizeOffer/getClientSOCart';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { REDIRECT_CHECKOUT_PARAM } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';
import Global from '@utils/global';
import {deepCopy} from '@utils/utility';

import CartSellerComponent from '../../FulfillmentOptions/DeliveryComponent/CartSellerComponent';
import {VIEW_OFFER_DETAIL_CART_ID_KEY} from '../../PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';
import CheckoutReviewShimmer from './CheckoutReviewShimmer';
import CheckoutReviewModel from './model/CheckoutReviewModel';

const CheckoutReview = ({ fields, cartData, params }) => {
  const query = useQuery();
  const dispatch = useDispatch();
  const [layoutData, setLayoutData] = useState(null);
  const currentDefaultCartData = useSelector(state => state.productReducer.cartDetailReducer.currentCart || {});
  const clientSOCartData = useSelector((state) => state.personalizeOfferReducer.getClientSOCartReducer.data?.OsimCart);
  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);

  const currentCart = useMemo(() => {
    return clientSOCartData || currentDefaultCartData;
  }, [clientSOCartData, currentDefaultCartData]);

  useEffect(() => {
    const model = new CheckoutReviewModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  useEffect(() => {
    if (!IsAuthenticated  && settingGlobal?.IsDisableGuestCheckout) {
      const fromUrl = window.location.href;

      window.location.href = `${settingGlobal?.LoginLink}?${REDIRECT_CHECKOUT_PARAM}=${fromUrl}`;
    }
  }, [IsAuthenticated, settingGlobal?.IsDisableGuestCheckout]);

  useEffect(() => {
    // INFO: getClientSOCart for checkout if user checkout with SO cart
    const cartId = query.get(VIEW_OFFER_DETAIL_CART_ID_KEY);

    if (cartId) dispatch(getClientSOCartStart({ cartId: cartId }));
  }, []);
  // useDidUpdateEffect(() => {
  //   //TW-Google-Code-0830
  //   const script = document.createElement('script');

  //   script.type = 'type="text/jsx"';

  //   script.textContent = 'gtag("event", "conversion", {"send_to": "AW-796718007/0p4dCJX2qe8CELfn8_sC","transaction_id": ""});';

  //   document.head.appendChild(script);

  //   return () => {
  //     document.head.removeChild(script);
  //   };
  // }, [layoutData]);

  const getPacakgeNumber = (ind, type) => {
    if (type === 'store') {
      return ind + (cartData.Delivery && cartData.Delivery.length > 0 ? 1 : 0) + 1;
    } else if (type === 'sf') {
      return ind + (cartData.Delivery && cartData.Delivery.length > 0 ? 1 : 0) + cartData.PickUpStore.length + 1;
    }
  };

  useDidUpdateEffect(() => {
    if (currentCart?.Lines) {
      const params = {
        items: Global.getItemList(currentCart.Lines)
      };

      new GoogleAnalytic().ggGateWay('event', 'review_payment', params);
    }
  }, [currentCart]);

  return (
    layoutData && cartData ? (
      <Fragment>
        <div className='fulfillment-options animated-slow fadeIn delay-100'>
          {cartData.Delivery?.length > 0 ? (
            <DeliveryComponent cartData={cartData} type='delivery' page='review' layoutData={layoutData} data={cartData?.Delivery} packageNumber={1} expandPackage={params?.['Auto Expand Packages']}></DeliveryComponent>
          ) : null}
          {/* {cartData.DepositListProduct?.length > 0 ? (
            cartData?.DepositListProduct.map((depositProduct, index)=> {
              return(
                <DeliveryComponent key={index} type='deposit' page='fulfillment' layoutData={layoutData} data={cartData.DepositListProduct} depositProduct={depositProduct}></DeliveryComponent>
              );
            })
          ) : null} */}
          {cartData.PickUpStore?.map((item, ind) => {
            return (
              <DeliveryComponent
                type='pick-up-store'
                page='review'
                layoutData={layoutData}
                data={item}
                key={ind}
                packageNumber={getPacakgeNumber(ind, 'store')}
                expandPackage={params?.['Auto Expand Packages']}
              ></DeliveryComponent>
            );
          })}
          {cartData.SFexpress?.length > 0 ? (
            <DeliveryComponent type='sf-express' page='review' layoutData={layoutData} data={cartData.SFexpress} packageNumber={cartData.Delivery?.length > 0 ? 2 : 1} expandPackage={params?.['Auto Expand Packages']}></DeliveryComponent>
          ) : null}
          {/* {cartData.SFexpress?.map((item, ind) => {
            return (
              <DeliveryComponent
                type='sf-express'
                page='review'
                layoutData={layoutData}
                data={item}
                key={ind}
                packageNumber={getPacakgeNumber(ind, 'sf')}
              ></DeliveryComponent>
            );
          })} */}
          {cartData.DeliveryBySFExpress?.length > 0 ? (
            <DeliveryComponent type='delivery-by-sf-express' page='review' layoutData={layoutData} data={cartData.DeliveryBySFExpress} packageNumber={cartData.Delivery?.length > 0 && cartData.SFexpress?.length > 0 ? 3 : cartData.Delivery?.length > 0 || cartData.SFexpress?.length > 0 ? 2 : 1}></DeliveryComponent>
          ) : null}
          <DeliveryComponent layoutData={layoutData} page='review' type='billing-details' expandPackage={params?.['Auto Expand Packages']}></DeliveryComponent>
          {
            cartData.Staff && cartData.Staff.StoreId && cartData.Staff.StaffId ? <CartSellerComponent layoutData={layoutData} cartData={cartData}></CartSellerComponent> : ''
          }
        </div>
        <Coupon layoutData={layoutData}></Coupon>
        <CartTotal layoutData={layoutData}></CartTotal>
      </Fragment>
    ) : (
      // <div className='loading-screen'>
      //   <LoadingMask />
      // </div>
      <div className='fulfillment-options skeleton__item'>
        <CheckoutReviewShimmer></CheckoutReviewShimmer>
      </div>
    )
  );
};

CheckoutReview.propTypes = {
  fields: PropTypes.object,
  cartData: PropTypes.object,
  params: PropTypes.object
};

const mapStateToProps = (state) => {
  const currentCustomCart = state.productReducer.cartDetailReducer.currentCartCustom || null;
  const clientSOCartCustomData = state.personalizeOfferReducer.getClientSOCartReducer.customData || null;

  return {
    cartData: clientSOCartCustomData || currentCustomCart
  };
};

export default connect(mapStateToProps)(CheckoutReview);
