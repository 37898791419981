import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { getScheduleOtpCodeFailure, getScheduleOtpCodeSuccess } from '@redux/actions/bookingExperience/getScheduleOtpCode';
import { GET_SCHEDULE_OTP_CODE_START } from '@redux/actions/bookingExperience/getScheduleOtpCode/types';
import { post } from '@services/genericService';
import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import { deepCopy } from '@utils/utility';

import { addCurlyBrackets } from '../utils';
import { GET_SCHEDULE_OTP_CODE_API_ENDPOINT } from './config';
import { GetScheduleOtpStatus } from './responseStatus';

const getScheduleOtpCodeEpic = (action$) =>
  action$.pipe(
    ofType(GET_SCHEDULE_OTP_CODE_START),
    switchMap((action) => {
      const params = {
        ProductId: addCurlyBrackets(action.payload.productId),
        MobileNumber: isAtSiteName(MY_SITE_NAME) ? `+${action.payload.mobileNumber}`: action.payload.mobileNumber
      };

      return from(post(GET_SCHEDULE_OTP_CODE_API_ENDPOINT, params)).pipe(
        map((res) => {
          if (GetScheduleOtpStatus.SuccessStatusCode.includes(res.data.StatusCode)) {
            return getScheduleOtpCodeSuccess(deepCopy(res.data));
          } else {
            return getScheduleOtpCodeFailure(res.data || 'Failure');
          }
        }),
        catchError((error) => of(getScheduleOtpCodeFailure(error.messages || 'Failure')))
      );
    })
  );

export default getScheduleOtpCodeEpic;
