import SitecoreTextModel from '@models/SitecoreTextModel';

export default class LanguageSelectorModel {
  constructor() {
    this['Language Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Language Label')) {
      this['Language Label'].getData(dataSource['Language Label']);
    }

    return this;
  }
}
