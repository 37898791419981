import './CarouselWidthIcon.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import CarouselWidthIconBottom from './CarouselWidthIconBottom';
import CarouselWidthIconItem from './CarouselWidthIconTop';
import CarouselWidthIconFieldsModel from './Model/CarouselWidthIconFieldsModel';

const CarouselWidthIcon = ({ fields }) => {
  const gallerySwiperRef = useRef(null);
  const thumbnailSwiperRef = useRef(null);

  const [carouselWidthIconFields, setCarouselWidthIconFields] = useState(null);
  const [carouselWidthIcon, setCarouselWidthIcon] = useState(null);

  useEffect(() => {
    const carouselWidthIconFieldsModel = new CarouselWidthIconFieldsModel();

    setCarouselWidthIconFields(carouselWidthIconFieldsModel.getData(deepCopy(fields || {})));
  }, [fields]);

  useDidUpdateEffect(() => {
    setCarouselWidthIcon(
      carouselWidthIconFields.items.every((item) => {
        return !item.fields?.Icon?.value?.src?.length;
      })
    );
  }, [carouselWidthIconFields]);

  const thumbnailSwiperParams = {
    spaceBetween: 32,
    slidesPerView: 3,
    centerInsufficientSlides: true,
    watchOverflow: true,

    breakpoints: {
      768: {
        spaceBetween: 40,
        slidesPerView: 4
      },
      992: {
        spaceBetween: 56,
        slidesPerView: 5
      }
    },
    on: {
      click: () => {
        if (thumbnailSwiperRef?.current?.swiper && gallerySwiperRef?.current?.swiper) {
          const allChildren = Array.from(thumbnailSwiperRef.current.swiper.el.children[0].children);
          const thumbnailSwiper = thumbnailSwiperRef.current.swiper;
          const gallerySwiper = gallerySwiperRef.current.swiper;

          if (thumbnailSwiper.clickedSlide) {
            allChildren.forEach((itemChildren) => itemChildren.classList.remove('slide-selected'));

            gallerySwiper.slideToLoop(thumbnailSwiper.clickedIndex, 200, false);

            thumbnailSwiper.clickedSlide.classList.add('slide-selected');
          }
        }
      },
      imagesReady: () => {
        const allChildren = Array.from(thumbnailSwiperRef.current.swiper.el.children[0].children);

        allChildren[0].classList.add('slide-selected');
      }
    }
  };

  const gallerySwiperParams = {
    spaceBetween: 10,
    loop: true,
    breakpoints: {
      992: {
        centeredSlides: true,
        spaceBetween: 0,
        slidesPerView: 1.9
      }
    },
    on: {
      slideChangeTransitionEnd: () => {
        if (gallerySwiperRef?.current?.swiper && thumbnailSwiperRef?.current?.swiper) {
          const thumbnailSwiper = thumbnailSwiperRef.current.swiper;
          const gallerySwiper = gallerySwiperRef.current.swiper;

          const slideTo = gallerySwiper.realIndex;
          const allChildren = Array.from(thumbnailSwiper.el.children[0].children);

          allChildren.forEach((itemChildren) => itemChildren.classList.remove('slide-selected'));

          allChildren[slideTo].classList.add('slide-selected');

          thumbnailSwiper.slideToLoop(slideTo, 200, false);
        }
      }
    }
  };

  return (
    carouselWidthIconFields &&
    carouselWidthIcon !== null && (
      <Fragment>
        <div className='carousel-width-icon'>
          <div className='carousel-width-icon__background'>
            <Image field={carouselWidthIconFields['Background Image']} />
          </div>
          <div className='container carousel-width-icon__container'>
            <div className='carousel-width-icon__heading'>
              <Text field={carouselWidthIconFields.Title} tag='h2' className='carousel-width-icon__heading__title text-center' />
              <Text field={carouselWidthIconFields.Description} tag='p' className='carousel-width-icon__heading__descrition text-center' />
            </div>
          </div>
          {carouselWidthIconFields.items?.length && (
            <div className='container no-padding-mobile'>
              <div className='carousel-width-icon__container carousel-width-icon__container--main'>
                {carouselWidthIcon ? (
                  <></>
                ) : (
                  <div className='carousel-width-icon__top'>
                    <SwipeComponent param={thumbnailSwiperParams} setRef={thumbnailSwiperRef}>
                      {carouselWidthIconFields.items.map((value, index) => {
                        return <CarouselWidthIconItem key={index} index={index} data={value.fields || {}}></CarouselWidthIconItem>;
                      })}
                    </SwipeComponent>
                  </div>
                )}
                <div className='carousel-width-icon__bottom'>
                  <SwipeComponent param={gallerySwiperParams} setRef={gallerySwiperRef}>
                    {carouselWidthIconFields.items.map((value, index) => {
                      return <CarouselWidthIconBottom key={index} data={value.fields || {}}></CarouselWidthIconBottom>;
                    })}
                  </SwipeComponent>
                </div>
              </div>
            </div>
          )}

          {carouselWidthIconFields['CTA Button Text'].value ? (
            <div className='carousel-width-icon__button text-center'>
              <Link field={carouselWidthIconFields['CTA Button Link']} className='btn btn-outline-CTA2'>
                <Text field={carouselWidthIconFields['CTA Button Text']} />
              </Link>
            </div>
          ) : (
            ''
          )}
        </div>
      </Fragment>
    )
  );
};

CarouselWidthIcon.propTypes = {
  fields: PropTypes.object
};

export default CarouselWidthIcon;
