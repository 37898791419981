import { object } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const CustomerSaleOrdersShimmer = ({ title }) => {
  return (
    <div className='box-data skeleton__item'>
      <div className='box-data__header'>
        <Text field={title} tag='h3' className='box-data__title' />
      </div>
      <div>
        {[...new Array(3)].map((val, index) => (
          <div key={index} className='box-data__header'>
            <span className='data-text text-line-25' />
          </div>
        ))}
      </div>
    </div>
  );
};

CustomerSaleOrdersShimmer.propTypes = {
  title: object
};

export default CustomerSaleOrdersShimmer;
