import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class InfoBoxWithImageModel {
  constructor() {
    this['CTA Button'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();

    this['Image'] = new SitecoreImageModel();

    this['Title'] = new SitecoreTextModel();

    this['Second CTA Button'] = new SitecoreLinkModel();

    this['Second CTA Button Text'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'].getData(dataSource['CTA Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Image')) {
      this['Image'].getData(dataSource['Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second CTA Button')) {
      this['Second CTA Button'].getData(dataSource['Second CTA Button']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second CTA Button Text')) {
      this['Second CTA Button Text'].getData(dataSource['Second CTA Button Text']);
    }

    return this;
  }
}
