import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { COMPLETED_STATUS_KEY } from '@redux/actions/cpSaleOrderStatuses/configs';
import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';
import { useQuery } from '@utils/customsHook/useQuery';
import Global from '@utils/global';

import { useOfferDetails } from '../OfferDetailsProvider';

const OfferDetailsWidget = () => {
  const query = useQuery();
  const { offerDetailFields, soCartData, osimCartData, isDisablePurchaseButton } = useOfferDetails();
  const isLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const formatDate = useFormatDate();

  const offerFromText = useMemo(() => {
    const offerFieldText = offerDetailFields?.['Offer From Label'];
    const staffName = soCartData?.StaffName || soCartData?.StaffId || offerDetailFields?.['Staff Name']?.value || '';

    return {
      ...offerFieldText,
      value: offerFieldText?.value?.replace('{staff}', staffName)?.replace('{store_name}', soCartData?.StoreName)
    };
  }, [soCartData, offerDetailFields]);

  const quantityItemText = useMemo(() => {
    const cartLength = osimCartData?.Lines?.length || 0;
    const textField = cartLength > 1 ? offerDetailFields?.['Quantity Items Label'] : offerDetailFields?.['Quantity Item Label'];

    return {
      ...textField,
      value: textField?.value?.replace('{quantity}', cartLength)
    };
  }, [osimCartData, offerDetailFields]);

  const expiredDate = useMemo(() => {
    const rawExpiredDate = soCartData?.ExpiredDate;
    const convertJsonDate = rawExpiredDate ? Global.convertJSONDateToNumber(rawExpiredDate) : '';

    return convertJsonDate ? formatDate(convertJsonDate) : '';
  }, [soCartData, formatDate]);

  const purchaseLink = useMemo(() => {
    let linkObject = {
      label: offerDetailFields?.['Checkout Link']?.fields?.Title,
      link: offerDetailFields?.['Checkout Link']?.fields?.Link
    };
    if (isLogin)
      linkObject = {
        label: offerDetailFields?.['Fulfillment Link']?.fields?.Title,
        link: offerDetailFields?.['Fulfillment Link']?.fields?.Link
      };

    return {
      ...linkObject,
      link: {
        value: {
          ...linkObject.link?.value,
          href: `${linkObject.link?.value?.href}?${query.toString()}`
        }
      }
    };
  }, [isLogin, offerDetailFields]);

  return soCartData ? (
    <div className='offer-details__widget'>
      <div className='personalize-offer-widget__content offer-details__widget-content'>
        <div className='content-image'>
          <div className='content-image__container'>
            <img src={soCartData.ProductSummary?.Image} alt={soCartData.ProductSummary?.ProductName} />
          </div>
        </div>
        <div className='content-wrapper'>
          <Text field={offerFromText} tag='p' className='personalize-offer-widget__content-description' />
          <Text field={quantityItemText} tag='h2' className='product-name' />
          <div className='product-price-offer'>
            <p className='personalize-offer-widget__content-description'>
              <Text field={offerDetailFields?.['Cart Total Amount Label']} />
              <span className='total-price-value'>{soCartData?.Total}</span>
            </p>
            {soCartData?.Status !== COMPLETED_STATUS_KEY ? (
              <p className='offer-details-valid'>
                <Text field={offerDetailFields?.['Offer Valid Label']} /> {expiredDate}
              </p>
            ) : (
              <></>
            )}
          </div>
          <div className='purchase-now-btn'>
            <Link field={purchaseLink.link} className={`btn btn-primary view-cart-button ${isDisablePurchaseButton ? 'btn-disable' : ''}`}>
              <Text field={purchaseLink.label} />
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default OfferDetailsWidget;
