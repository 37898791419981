import React from 'react';

import { useTradeInUploadImage } from '../TradeInUploadImageProvider';

const UploadImageErrorMessage = () => {
  const { formikRefCurrent } = useTradeInUploadImage();

  return (
    <div className='image-selection__desc text-center'>
      {formikRefCurrent.errors.imageTradeInProduct && formikRefCurrent.touched.imageTradeInProduct ? (
        <span className='error-validate'>{formikRefCurrent.errors.imageTradeInProduct}</span>
      ) : (
        <></>
      )}
    </div>
  );
};

export default UploadImageErrorMessage;
