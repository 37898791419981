import { arrayOf, bool, exact, instanceOf, number, object, oneOfType, string } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useFormatDate } from '@utils/customsHook/useFormatDate';
import { useQuery } from '@utils/customsHook/useQuery';

import { SEARCH_PARAMS_CART_NAME_KEY } from '../../../config';
import { useGenerateProductNames } from './hooks';

const SaleOrderItem = ({ orderItem, layoutData, isViewDetail = false }) => {
  const query = useQuery();
  const generateProductNamesUtil = useGenerateProductNames();
  const { soCartStatuses } = useSelector((state) => state.cpSaleOrderStatusesReducer);
  const { ProductNames, ExpiredDate, Status, SalesOrderId, StoreName, CartName, StaffName } = orderItem;
  const formatDateUtil = useFormatDate();

  const productNames = useMemo(() => {
    return generateProductNamesUtil(ProductNames, layoutData?.['Package count']?.value);
  }, [ProductNames, layoutData]);

  const viewSODetail = useMemo(() => {
    let hrefValue = '';
    if (isViewDetail && CartName) {
      query.set(SEARCH_PARAMS_CART_NAME_KEY, CartName);

      hrefValue = `${layoutData?.['View Sale Order Detail Link']?.value?.href}?${query.toString()}`;
    }

    return hrefValue;
  }, [CartName, isViewDetail, query, layoutData]);

  return (
    <div className='box-data__row'>
      <div className='box-data__col-1'>
        {!isViewDetail ? (
          <div className='box-data__title --sub'>{productNames}</div>
        ) : (
          <p className='sale-order-id'>
            <Text field={layoutData?.['Order']} /> <span>{SalesOrderId}</span>
          </p>
        )}
        {ExpiredDate ? (
          <p className='box-data__notice'>
            <Text field={layoutData?.['Expire On Text']} />{' '}
            <span className={isViewDetail ? 'fw-bold' : ''}>{formatDateUtil(ExpiredDate)}</span>
          </p>
        ) : (
          <></>
        )}
        {isViewDetail ? (
          <>
            <p className='box-data__notice'>
              <Text field={layoutData?.['Store Label']} /> {StoreName}
            </p>
            <p className='box-data__notice'>
              <Text field={layoutData?.['Sale Staff']} /> {StaffName}
            </p>
          </>
        ) : (
          <></>
        )}
      </div>
      <div className='box-data__col-2'>
        {isViewDetail ? (
          <a href={viewSODetail} className='box-data__link so-view-detail-link'>
            <Text field={layoutData?.['View details']} />
          </a>
        ) : (
          <></>
        )}
        <div className='highlight-text highlight-text--radius'>
          <span>{soCartStatuses?.[Status || 0]}</span>
        </div>
      </div>
    </div>
  );
};

SaleOrderItem.propTypes = {
  isViewDetail: bool,
  layoutData: object,
  orderItem: exact({
    ProductNames: arrayOf(string),
    ExpiredDate: string,
    Status: number,
    SalesOrderId: oneOfType([string, instanceOf(undefined)]),
    StoreName: oneOfType([string, instanceOf(undefined)]),
    CartName: oneOfType([string, instanceOf(undefined)]),
    StaffName: oneOfType([string, instanceOf(undefined)])
  })
};

export default SaleOrderItem;
