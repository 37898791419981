import { ofType } from 'redux-observable';
import { from,of } from 'rxjs';
import { catchError,map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/common';
import * as commonTypes from '@redux/actions/common/commonTypes';
import { getAsObservable, postAsObservable } from '@services/genericService';
import { deepCopy } from '@utils/utility';

import * as configs from '../config';

export const commonSearchAllEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.COMMON_SEARCH_ALL),
    switchMap((action) => {

      const params = {
        searchTerm: action.searchTerm,
        page: action.page ? action.page : configs.COMMON_INIT_PAGE,
        pageSz: configs.COMMON_INIT_PAGESZ,
        itemType : configs.ITEM_TYPE_ALL
      };

      return postAsObservable(configs.ACTION_COMMON_SEARCH, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.commonSearchAllSuccess(deepCopy(res.data) || {});
          } else {
            return actions.commonSearchAllFailed('Failed');
          }
        }),
        catchError((error) => of(actions.commonSearchAllFailed(error.message || 'Failed')))
      );
    })
  );

export const commonSearchWellnessEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.COMMON_SEARCH_WELLNESS),
    switchMap((action) => {

      const params = {
        searchTerm: action.params.searchTerm,
        tags: action.params.tags,
        page: action.params.page ? action.params.page : configs.COMMON_INIT_PAGE,
        pageSz: action.params.pageSz ? action.params.pageSz : configs.COMMON_INIT_PAGESZ,
        sortUnread: action.params.sortUnread,
        sortProductRelated: action.params.sortProductRelated,
        itemType : configs.ITEM_TYPE_WELLNESS,
        sortDate: action.params.sortDate
      };

      return postAsObservable(configs.ACTION_COMMON_SEARCH, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.commonSearchWellnessSuccess(deepCopy(res.data.WellnessArticles) || [], res.data.WellnessCount);
          } else {
            return actions.commonSearchResourceFailed('Failed');
          }
        }),
        catchError((error) => of(actions.commonSearchWellnessFailed(error.message || 'Failed')))
      );
    })
  );

export const commonSearchProductEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.COMMON_SEARCH_PRODUCT),
    switchMap((action) => {

      const params = {
        Term: action.payload.Term,
        Page: action.payload.Page ? action.payload.Page : configs.COMMON_INIT_PAGE,
        PageSize: action.payload.PageSize ?  action.payload.PageSize : configs.COMMON_INIT_PAGESZ,
        dataSourcePath: action.payload.dataSourcePath
      };

      return getAsObservable(configs.ACTION_COMMON_SEARCH_PRODUCT, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.commonSearchProductSuccess({
              responses: res,
              isFilter: false,
            });
          } else {
            return actions.commonSearchProductFailed('Failed');
          }
        }),
        catchError((error) => of(actions.commonSearchProductFailed(error.message || 'Failed')))
      );
    })
  );

export const commonSearchProductFilterEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.COMMON_SEARCH_PRODUCT_FILTER),
    switchMap((action) => {

      const params = {
        Term: action.payload.Term || '',
        Page: action.payload.Page ? action.payload.Page : configs.COMMON_INIT_PAGE,
        PageSize: action.payload.PageSize ?  action.payload.PageSize : configs.COMMON_INIT_PAGESZ,
        SortBy: action.payload.SortBy || null,
        PriceFrom: action.payload.PriceFrom,
        PriceTo: action.payload.PriceTo,
        Colors: action.payload.Colors?.length ? action.payload.Colors : '',
        Features : action.payload.Features?.length ? action.payload.Features : '',
      };

      return getAsObservable(configs.ACTION_COMMON_SEARCH_PRODUCT, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.commonSearchProductSuccess({
              responses: res,
              isFilter: true,
            });
          } else {
            return actions.commonSearchProductFailed('Failed');
          }
        }),
        catchError((error) => of(actions.commonSearchProductFailed(error.message || 'Failed')))
      );
    })
  );

export const commonSearchPolicyEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.COMMON_SEARCH_POLICY),
    switchMap((action) => {

      const params = {
        searchTerm: action.params.searchTerm,
        page: action.params.page ? action.params.page : configs.COMMON_INIT_PAGE,
        pageSz: action.params.pageSz ? action.params.pageSz : configs.COMMON_INIT_PAGESZ,
        itemType : configs.ITEM_TYPE_POLICY
      };

      return postAsObservable(configs.ACTION_COMMON_SEARCH, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.commonSearchPolicySuccess(deepCopy(res.data.Policies) || [], res.data.PolicyCount);
          } else {
            return actions.commonSearchPolicyFailed('Failed');
          }
        }),
        catchError((error) => of(actions.commonSearchPolicyFailed(error.message || 'Failed')))
      );
    })
  );

export const commonSearchResourceEpic = (action$) =>
  action$.pipe(
    ofType(commonTypes.COMMON_SEARCH_RESOURCE),
    switchMap((action) => {
      const params = {
        searchTerm: action.params.searchTerm,
        page: action.params.page ? action.params.page : configs.COMMON_INIT_PAGE,
        pageSz: action.params.pageSz ? action.params.pageSz : configs.COMMON_INIT_PAGESZ,
        itemType : configs.ITEM_TYPE_RESOURCE
      };

      return postAsObservable(configs.ACTION_COMMON_SEARCH, params).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.commonSearchResourceSuccess(deepCopy(res.data.ProductResources) || [], res.data.ProductResourceCount);
          } else {
            return actions.commonSearchResourceFailed('Failed');
          }
        }),
        catchError((error) => of(actions.commonSearchAllFailed(error.message || 'Failed')))
      );
    })
  );
