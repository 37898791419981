import { GET_PRODUCT_COUNTDOWN_TIMER, GET_PRODUCT_COUNTDOWN_TIMER_FAILED, GET_PRODUCT_COUNTDOWN_TIMER_SUCCESS } from './productCountdownTimerTypes';

export const getProductCountdownTimer = () => ({
  type: GET_PRODUCT_COUNTDOWN_TIMER
});

export const getProductCountdownTimerSuccess = (payload) => ({
  type: GET_PRODUCT_COUNTDOWN_TIMER_SUCCESS,
  payload
});

export const getProductCountdownTimerFailed = (payload) => ({
  type: GET_PRODUCT_COUNTDOWN_TIMER_FAILED,
  payload
});
