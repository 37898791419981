import * as unsubscribeTypes from '../unsubscribe/unsubscribeTypes';

export const unsubscribe = (payload) => ({
  type: unsubscribeTypes.UN_SUBSCRIBE_NEWS_LETTER,
  payload
});

export const unsubscribeSuccess = (payload) => ({
  type: unsubscribeTypes.UN_SUBSCRIBE_NEWS_LETTER_SUCCESS,
  payload
});

export const unsubscribeFailed = (error) => ({
  type: unsubscribeTypes.UN_SUBSCRIBE_NEWS_LETTER_FAILED,
  error
});

export const getCaptchaValue = (payload) => ({
  type: unsubscribeTypes.GET_CAPTCHA_VALUE,
  payload
});

export const getSelectedSelectionValue = (payload) => ({
  type: unsubscribeTypes.GET_SELECTED_SELECTION_VALUE,
  payload
});

export const resetCaptcha = (payload) => ({
  type: unsubscribeTypes.RESET_CAPTCHA,
  payload
});

export const getResponseMessage = (payload) => ({
  type: unsubscribeTypes.GET_RESPONSE_MESSAGE,
  payload
});

export const getPhoneNumber = (payload) => ({
  type: unsubscribeTypes.GET_PHONE_NUMBER,
  payload
});

export const setSubmitButtonStatus = (payload) => ({
  type: unsubscribeTypes.SET_SUBMIT_BUTTON_STATUS,
  payload
});

export const setLoginLink = (payload) => ({
  type: unsubscribeTypes.SET_LOGIN_LINK,
  payload
});

export const getPhoneNumberId = (payload) => ({
  type: unsubscribeTypes.GET_PHONENUMBER_ID,
  payload
});

export const getSubmitAction = (payload) => ({
  type: unsubscribeTypes.GET_SUBMIT_ACTION,
  payload
});
