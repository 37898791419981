import AnswerFieldsModel from './AnswerFieldsModel';

const STRING_ARR = ['id', 'url', 'name', 'displayName'];

export default class AnswerModel {
  constructor() {
    STRING_ARR.forEach((item) => {
      this[item] = '';
    });

    this.fields = new AnswerFieldsModel();
  }

  getData(dataSource) {
    STRING_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }

    return this;
  }
}
