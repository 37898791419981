import './FeaturedCategories.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { deepCopy } from '@utils/utility';

const FeaturedCategories = (props) => {
  const { fields } = props;
  const MAXIMUM_SLIDES_DESKTOP = 8;
  const MAXIMUM_SLIDES_TABLET = 6;

  const params = {
    slidesPerView: 2.7,
    centeredSlides: false,
    spaceBetween: 40,
    watchOverflow: true,
    breakpoints: {
      1400: {
        slidesPerView: MAXIMUM_SLIDES_DESKTOP
      },

      1200: {
        slidesPerView: MAXIMUM_SLIDES_TABLET
      },

      768: {
        slidesPerView: MAXIMUM_SLIDES_TABLET - 1
      }
    },
    shouldSwiperUpdate: true
  };

  const [listData, setListData] = useState(null);

  useEffect(() => {
    setListData(deepCopy(fields['Featured Categories']));
  }, []);

  return (
    <div className='feature-category'>
      <div className='container'> 
        <h2 className='experience-title feature-category__heading'>{fields.Title.value}</h2>
      </div>
      {listData ? (
        <div className='feature-category__slide'>
          <SwipeComponent param={params}>
            {listData.map((item, index) => (
              <div className='feature-category__slide__item' key={index}>
                <div className='feature-category__slide__item__image'>
                  <img className='feature-category-image' src={require('../../../../assets/Osim-AI-learning.png')} alt='Icon Experience' />
                </div>
                <span className='feature-category__slide__item__text'>{item.fields.DisplayName.value}</span>
              </div>
            ))}
          </SwipeComponent>
        </div>
      ) : null}
    </div>
  );
};

FeaturedCategories.propTypes = {
  fields: PropTypes.object
};

export default FeaturedCategories;
