import produce from 'immer';

import {
  REGISTER_EVENT_BOOKING_FAILED,
  REGISTER_EVENT_BOOKING_START,
  REGISTER_EVENT_BOOKING_SUCCESS
} from '@redux/actions/eventBooking/eventBookingRegister/eventBookingRegisterTypes';

const initialState = {
  isLoading: false,
  registerResponse: null,
  error: null
};

const eventBookingRegisterReducer = (state = initialState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case REGISTER_EVENT_BOOKING_START:
      draft.isLoading = true;
      break;

    case REGISTER_EVENT_BOOKING_SUCCESS:
      draft.isLoading = false;

      draft.registerResponse = action.payload;
      break;

    case REGISTER_EVENT_BOOKING_FAILED:
      draft.isLoading = false;

      draft.error = `${action.payload}`;
      break;
    }

    return draft;
  });

export default eventBookingRegisterReducer;
