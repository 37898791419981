import './ProductAccessories.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';

import ProductAccessoriesModel from './models/ProductAccessoriesModel';
import ProductAccessoriesBody from './ProductAccessoriesBody';
import ProductAccessoriesHeader from './ProductAccessoriesHeader';

const ProductAccessories = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    setLayoutData(new ProductAccessoriesModel().getData(fields));
  }, []);

  return layoutData && layoutData?.ProductAccessories?.length ? (
    <div className='container product-accessories'>
      <div className='support-centre-box no-border-top-mobile'>
        <div className='support-centre-box__wrapper product-accessories__container'>
          <div className='product-accessories__content'>
            <ProductAccessoriesHeader layoutData={layoutData} />
            <ProductAccessoriesBody layoutData={layoutData} />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

ProductAccessories.propTypes = {
  fields: object
};

export default ProductAccessories;
