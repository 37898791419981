import produce from 'immer';

import * as spinAndWinTypes from '@redux/actions/spinAndWin/spinAndWinTypes';

const initState = {
  isLoading: false,
  chanceToWinData: null
};

const getChanceToWinReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case spinAndWinTypes.GET_CHANCE_TO_WIN:
      draft.isLoading = true;
      break;

    case spinAndWinTypes.GET_CHANCE_TO_WIN_SUCCESS:
      draft.isLoading = false;

      draft.chanceToWinData = action.payload.Promotions;
      break;

    case spinAndWinTypes.GET_CHANCE_TO_WIN_FAILED:
      draft.isLoading = false;
      break;
    }

    return draft;
  });

export default getChanceToWinReducer;
