import ProductInfoModel from '@components/Product/models/ProductInfoModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import SpecialRelatedProductItemModel from './SpecialRelatedProductItemModel';

export default class SpecialRelatedProductModel {
  constructor() {
    this['Buy Now Link'] = new SitecoreLinkModel();
    
    this['Buy Now Text'] = new SitecoreTextModel();
    
    this['Products'] = [];

    this['Related Type'] = new ProductInfoModel();
    
    this['Title'] = new SitecoreTextModel();

    this['Description'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Text')) {
      this['Buy Now Text'].getData(dataSource['Buy Now Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Link')) {
      this['Buy Now Link'].getData(dataSource['Buy Now Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products')) {
      let newProducts = [];

      if (dataSource['Products']?.length) {
        dataSource['Products'].map((element) => {
  
          newProducts.push(new SpecialRelatedProductItemModel().getData(deepCopy(element) || {}, this['Buy Now Link']));
        });
      }

      this['Products'] = dataSource['Products'] ? newProducts : this['Products'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'].getData(dataSource['Description']);
    }

    return this;
  }
}
