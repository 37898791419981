import { CLOSE_CP_NOTIFICATION_POPUP, OPEN_CP_NOTIFICATION_POPUP, SET_CP_NOTIFICATION_POPUP_SPACING } from './types';

export const openCpNotificationPopup = () => ({
  type: OPEN_CP_NOTIFICATION_POPUP
});

export const closeCpNotificationPopup = () => ({
  type: CLOSE_CP_NOTIFICATION_POPUP
});

export const setCpNotificationPopupSpacing = (payload) => ({
  type: SET_CP_NOTIFICATION_POPUP_SPACING,
  payload
});
