import BooleanModel from '@components/PopupBanner/model/BooleanModel';
import RegisterOneLastStepModel from '@components/SingleSignOn/RegisterLogin/models/RegisterOneLastStepModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

import CartConfigurationModel from './CartConfigurationModel';

export default class CheckoutThankyouFieldsModel {
  constructor() {
    this['Account Title'] = new SitecoreTextModel();

    this['Cart Configuration'] = new CartConfigurationModel();

    this['Checkout Register Account Message'] = new SitecoreTextModel();

    this['Checkout Register Account Password Label'] = new SitecoreTextModel();

    this['Checkout Register Account Register Button Link'] = new SitecoreLinkModel();

    this['Checkout Register Account Register Button Text'] = new SitecoreTextModel();

    this['Checkout Register Account Title'] = new SitecoreTextModel();

    this['Confirmation Will Be Sent To Label'] = new SitecoreTextModel();

    this['Continue Shopping Label'] = new SitecoreTextModel();

    this['Continue Shopping Link'] = new SitecoreLinkModel();

    this['Delivery Detail Label'] = new SitecoreTextModel();

    this['Delivery Fee Label'] = new SitecoreTextModel();

    this['Description Delivery Detail'] = new SitecoreTextModel();

    this['Did Not Get Email Message'] = new SitecoreTextModel();

    this['Discount Label'] = new SitecoreTextModel();

    this['Register Subscription Label'] = new SitecoreTextModel();

    this['Order Number Label'] = new SitecoreTextModel();

    this['PDPA Message'] = new SitecoreTextModel();

    this['Paid In Full Label'] = new SitecoreTextModel();

    this['Paid In Installment Label'] = new SitecoreTextModel();

    this['Payment Method Label'] = new SitecoreTextModel();

    this['Payment Successfull Label'] = new SitecoreTextModel();

    this['Processing Now Text'] = new SitecoreTextModel();

    this['Register Success Instruction'] = new SitecoreTextModel();

    this['Register Success Message'] = new SitecoreTextModel();

    this['Register Success Title'] = new SitecoreTextModel();

    this['Resend Message'] = new SitecoreTextModel();

    this['Sale Tax Label'] = new SitecoreTextModel();

    this['Subtotal Label'] = new SitecoreTextModel();

    this['Summary Label'] = new SitecoreTextModel();

    this['Thank You For Your Order Label'] = new SitecoreTextModel();

    this['Total Label'] = new SitecoreTextModel();

    this['View Order Detail In My Account Page Text'] = new SitecoreTextModel();

    this['View Order Detail Link'] = new SitecoreLinkModel();

    this['Register One Last Step'] = new RegisterOneLastStepModel();

    this['Show Sales Tax'] = false;

    this['Enable Subscription'] = new BooleanModel();

    this['Subscription Description'] = new SitecoreTextModel();

    this['SMS Subscription Label'] = new SitecoreTextModel();

    this['Email Subscription Label'] = new SitecoreTextModel();

    this['Direct Mailing Subscription Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    this['Register One Last Step'].getData(dataSource);
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Account Title')) {
      this['Account Title'].getData(dataSource['Account Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cart Configuration')) {
      this['Cart Configuration'].getData(dataSource['Cart Configuration']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Checkout Register Account Message')) {
      this['Checkout Register Account Message'].getData(dataSource['Checkout Register Account Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Checkout Register Account Password Label')) {
      this['Checkout Register Account Password Label'].getData(dataSource['Checkout Register Account Password Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Checkout Register Account Register Button Link')) {
      this['Checkout Register Account Register Button Link'].getData(dataSource['Checkout Register Account Register Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Checkout Register Account Register Button Text')) {
      this['Checkout Register Account Register Button Text'].getData(dataSource['Checkout Register Account Register Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Checkout Register Account Title')) {
      this['Checkout Register Account Title'].getData(dataSource['Checkout Register Account Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Confirmation Will Be Sent To Label')) {
      this['Confirmation Will Be Sent To Label'].getData(dataSource['Confirmation Will Be Sent To Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Continue Shopping Label')) {
      this['Continue Shopping Label'].getData(dataSource['Continue Shopping Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Continue Shopping Link')) {
      this['Continue Shopping Link'].getData(dataSource['Continue Shopping Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Detail Label')) {
      this['Delivery Detail Label'].getData(dataSource['Delivery Detail Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Delivery Fee Label')) {
      this['Delivery Fee Label'].getData(dataSource['Delivery Fee Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description Delivery Detail')) {
      this['Description Delivery Detail'].getData(dataSource['Description Delivery Detail']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Did Not Get Email Message')) {
      this['Did Not Get Email Message'].getData(dataSource['Did Not Get Email Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Discount Label')) {
      this['Discount Label'].getData(dataSource['Discount Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Subscription Label')) {
      this['Register Subscription Label'].getData(dataSource['Register Subscription Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Order Number Label')) {
      this['Order Number Label'].getData(dataSource['Order Number Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PDPA Message')) {
      this['PDPA Message'].getData(dataSource['PDPA Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Paid In Full Label')) {
      this['Paid In Full Label'].getData(dataSource['Paid In Full Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Paid In Installment Label')) {
      this['Paid In Installment Label'].getData(dataSource['Paid In Installment Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Payment Method Label')) {
      this['Payment Method Label'].getData(dataSource['Payment Method Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Payment Successfull Label')) {
      this['Payment Successfull Label'].getData(dataSource['Payment Successfull Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Processing Now Text')) {
      this['Processing Now Text'].getData(dataSource['Processing Now Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Success Instruction')) {
      this['Register Success Instruction'].getData(dataSource['Register Success Instruction']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Success Message')) {
      this['Register Success Message'].getData(dataSource['Register Success Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Register Success Title')) {
      this['Register Success Title'].getData(dataSource['Register Success Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Resend Message')) {
      this['Resend Message'].getData(dataSource['Resend Message']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sale Tax Label')) {
      this['Sale Tax Label'].getData(dataSource['Sale Tax Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Subtotal Label')) {
      this['Subtotal Label'].getData(dataSource['Subtotal Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Summary Label')) {
      this['Summary Label'].getData(dataSource['Summary Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Thank You For Your Order Label')) {
      this['Thank You For Your Order Label'].getData(dataSource['Thank You For Your Order Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Total Label')) {
      this['Total Label'].getData(dataSource['Total Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View Order Detail In My Account Page Text')) {
      this['View Order Detail In My Account Page Text'].getData(dataSource['View Order Detail In My Account Page Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View Order Detail Link')) {
      this['View Order Detail Link'].getData(dataSource['View Order Detail Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Enable Subscription')) {
      this['Enable Subscription'].getData(dataSource['Enable Subscription']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Subscription Description')) {
      this['Subscription Description'].getData(dataSource['Subscription Description']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SMS Subscription Label')) {
      this['SMS Subscription Label'].getData(dataSource['SMS Subscription Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Email Subscription Label')) {
      this['Email Subscription Label'].getData(dataSource['Email Subscription Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Direct Mailing Subscription Label')) {
      this['Direct Mailing Subscription Label'].getData(dataSource['Direct Mailing Subscription Label']);
    }

    return this;
  }
}
