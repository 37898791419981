import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import ContactUsIconListItemModel from './ContactUsIconListItemModel';

export default class ContactUsIconListFieldsModel {
  constructor() {
    this.Title = new SitecoreTextModel();

    this.items = [];
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'items')) {
      let newItems = [];

      if (dataSource['items']) {
        dataSource['items'].map((element) => {
  
          newItems.push(new ContactUsIconListItemModel().getData(deepCopy(element) || {}));
        });
      }

      this['items'] = dataSource['items'] ? newItems : this['items'];
    }

    return this;
  }
}
