import './ProductBanner.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ResponsiveImage from '@components/ResponsiveImage';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { CLASS_NAME_CONTENT_RIGHT, TEXT_RIGHT } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import ProductBannerFieldsModel from './Model/ProductBannerFieldsModel';
import ProductBannerShimmer from './ProductBannerShimmer';

const ProductBanner = (props) => {
  const { fields, params } = props;
  const productBannerTextPosition = params['Text Position'] || '';
  const [productBanner, setProductBanner] = useState(null);

  useEffect(() => {
    const productBannerFieldsModel = new ProductBannerFieldsModel();

    setProductBanner(productBannerFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  const setContentPosition = () => (productBannerTextPosition === TEXT_RIGHT ? CLASS_NAME_CONTENT_RIGHT : '');

  return productBanner ? (
    <>
      {productBanner['Banner Click Hyperlink'].value.href.length ? (
        <Link field={productBanner['Banner Click Hyperlink']}>
          <div className='product-banner'>
            <div className='product-banner__image'>
              <ResponsiveImage
                imageMobile={productBanner['Mobile Background Image']}
                srcSet={[ { mw: 768, image: productBanner['Background Image'] } ]}
              />
            </div>
            <div className='product-banner__wrapper-contents'>
              <div className={'container content-position ' + setContentPosition()}>
                <div className='product-banner__contents content-wrapper'>
                  <Text field={productBanner['Sub Title']} tag='p' className='product-banner__contents__sub-title' />
                  <Text field={productBanner.Title} tag='h2' className='experience-title product-banner__contents__title' />
                  <RichText className='product-banner__contents__description' field={productBanner.Description} />
                  <div className='product-banner__contents__btn'>
                    <Link className='product-banner__contents__btn__item btn btn-outline-primary' field={productBanner['CTA Button']}>
                      <Text field={productBanner['CTA Button Text']} tag='span' />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Link>
      ) : (
        <div className='product-banner'>
          <div className='product-banner__image'>
            <ResponsiveImage
              imageMobile={productBanner['Mobile Background Image']}
              srcSet={[ { mw: 768, image: productBanner['Background Image'] } ]}
            />
          </div>
          <div className='product-banner__wrapper-contents'>
            <div className={'container content-position ' + setContentPosition()}>
              <div className='product-banner__contents content-wrapper'>
                <Text field={productBanner['Sub Title']} tag='p' className='product-banner__contents__sub-title' />
                <Text field={productBanner.Title} tag='h2' className='experience-title product-banner__contents__title' />
                <RichText className='product-banner__contents__description' field={productBanner.Description} />
                <div className='product-banner__contents__btn'>
                  <Link className='product-banner__contents__btn__item btn btn-outline-primary' field={productBanner['CTA Button']}>
                    <Text field={productBanner['CTA Button Text']} tag='span' />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <ProductBannerShimmer></ProductBannerShimmer>
    </LoadingShimmer>
  );
};

ProductBanner.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object
};

export default ProductBanner;
