import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { showDeliveryPackagesSection } from '@redux/actions/controlSelectingFreeGiftPopup';

import { DELIVERY_PACKAGES_COMPONENT_ID } from '../FulfillmentOptions/DeliveryComponent';
import { CART_DETAIL_PAGE_ID } from '../SlicingPage/CartDetails';
import useSelectingFreeGift from './SelectingFreeGiftProvider/useSelectingFreeGift';

export const useSelectingFreeGiftUtils = () => {
  const { freeGiftFromCartLines, selectedFreeGift, editingFreeGift, freeGiftDataSort } = useSelectingFreeGift();

  const handleGenerateAccordionKey = (promotionId, key) => `${promotionId}-${key}`;

  const handleCheckFreeGiftExistInCartLine = (promotionId, productId, variantId) =>
    freeGiftFromCartLines?.find(
      (freeGift) => freeGift.PromotionFreeGiftPopupId === promotionId && freeGift.ProductId === productId && freeGift.VariantId === variantId
    );

  const handleCheckFreeGiftExistInSelectedFreeGift = (promotionId, productId, variantId) =>
    selectedFreeGift?.find(
      (freeGift) => freeGift.PromotionId === promotionId && freeGift.ProductId === productId && freeGift.variant.VariantId === variantId
    );

  const handleCheckFreeGiftIsEditing = (promotionId) => editingFreeGift?.find((freeGift) => freeGift.PromotionId === promotionId);

  const handleSetActiveKey = (promotionId, index) => {
    let activePromotionId;
    const freeGiftByPromotionId = selectedFreeGift.filter((freeGift) => freeGift.PromotionId === promotionId);
    if (
      !freeGiftByPromotionId.length ||
      !freeGiftByPromotionId.every((item) => handleCheckFreeGiftExistInCartLine(item.PromotionId, item.ProductId, item.variant.VariantId)) ||
      selectedFreeGift.filter((item) => item.PromotionId === promotionId).length !==
        freeGiftDataSort.find((item) => item.PromotionId === promotionId)?.slotData?.length
    )
      activePromotionId = handleGenerateAccordionKey(promotionId, index);
    if (handleCheckFreeGiftIsEditing(promotionId)) activePromotionId = handleGenerateAccordionKey(promotionId, index);

    return activePromotionId;
  };

  const handleGenerateFreeGiftInfo = (promotionId) => {
    let freeGiftNames = [];
    const freeGiftByPromotionId = selectedFreeGift.filter((freeGift) => freeGift.PromotionId === promotionId);
    for (const freeGiftItem of freeGiftByPromotionId) {
      const freeGiftFromCartLines = handleCheckFreeGiftExistInCartLine(promotionId, freeGiftItem.ProductId);

      const freeGiftFromSelectedFreeGift = handleCheckFreeGiftExistInSelectedFreeGift(
        promotionId,
        freeGiftItem.ProductId,
        freeGiftItem?.variant?.VariantId
      );

      const freeGiftNameItem = freeGiftFromSelectedFreeGift
        ? freeGiftFromSelectedFreeGift?.variant?.VariantName
        : `${freeGiftFromCartLines?.DisplayName} - ${freeGiftFromCartLines?.VariantPropertyValue}`;

      freeGiftNames.push(freeGiftNameItem);
    }

    return freeGiftNames;
  };

  return {
    handleGenerateAccordionKey,
    handleCheckFreeGiftExistInCartLine,
    handleCheckFreeGiftExistInSelectedFreeGift,
    handleSetActiveKey,
    handleGenerateFreeGiftInfo
  };
};

export const useScrollToSections = () => {
  const dispatch = useDispatch();

  return useCallback(() => {
    const deliverySection = document.getElementById(DELIVERY_PACKAGES_COMPONENT_ID);
    const cartDetailSection = document.getElementById(CART_DETAIL_PAGE_ID);
    if (deliverySection) {
      const deliverySectionRect = deliverySection.getBoundingClientRect();

      dispatch(showDeliveryPackagesSection());

      window.scrollTo({
        top: deliverySectionRect.y,
        behavior: 'smooth'
      });
    }
    if (cartDetailSection) {
      const cartSectionRect = cartDetailSection.getBoundingClientRect();

      window.scrollTo({
        top: cartSectionRect.y,
        behavior: 'smooth'
      });
    }
  }, []);
};
