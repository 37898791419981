import NumberFormatModel from './NumberFormatModel';

export default class CurrencyModel {
  constructor() {
    this['CurrencyNumberFormatCulture'] = '';

    this['Description'] = '';

    this['Name'] = '';

    this['NumberFormat'] = new NumberFormatModel();

    this['Symbol'] = '';

    this['SymbolPosition'] = 0;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CurrencyNumberFormatCulture')) {
      this['CurrencyNumberFormatCulture'] = dataSource['CurrencyNumberFormatCulture'] ?? this['CurrencyNumberFormatCulture'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this['Description'] = dataSource['Description'] ?? this['Description'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this['Name'] = dataSource['Name'] ?? this['Name'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'NumberFormat')) {
      this['NumberFormat'].getData(dataSource['NumberFormat']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Symbol')) {
      this['Symbol'] = dataSource['Symbol'] ?? this['Symbol'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SymbolPosition')) {
      this['SymbolPosition'] = dataSource['SymbolPosition'] ?? this['SymbolPosition'];
    }

    return this;
  }
}
