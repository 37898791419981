import './CategoriesNavigation.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import ProductCatalogueItem from '@components/ProductCatalogue/ProductCatalogueItem';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import Global from '@utils/global';

const CategoriesNavigation = ({fields}) => {
  const pageTitle = SitecoreContextFactoryService.getValueContextRouteItem('fields')?.Title?.value;
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    if (isShow) {
      Global.stopScrollingPage();
    }
    
    return () => {
      Global.scrollingPage();
    };
  }, [isShow]);

  return (
    <div className='categories-navigation'>
      <div className='container tablet-container'>
        <div className='categories-navigation__title' onClick={() => setIsShow(!isShow)}>
          <h2>{pageTitle}</h2>
          <div className='categories-navigation__icon'>
            <div className={`arrow-icon-down${isShow ? ' show-drop-down' : ''}`}></div> 
          </div>
          <div className={`categories-navigation__listing${isShow ? ' show-drop-down' : ''}`}>
            <ul className='row'>
              {fields.Categories?.length && fields.Categories.map((item) => {
                return <ProductCatalogueItem key={item.id} data={item} />;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

CategoriesNavigation.propTypes = {
  fields: PropTypes.any
};

export default CategoriesNavigation;