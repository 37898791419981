import FieldsModel from './FieldsModel';

export default class YourProductHubDetailModel {
  constructor() {
    this['fields'] = new FieldsModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields']= dataSource['fields'];
    }

    return this;
  }
}