import './CalendarPicker.scss';

import { any } from 'prop-types';
import React from 'react';
import DatePicker from 'react-datepicker';

const CalendarPicker = ({ ...props }) => {
  return (
    <DatePicker
      {...props}
      inline
      calendarClassName='calendar-picker'
      dayClassName={() => 'calendar-picker__day'}
      monthClassName={() => 'calendar-picker__month'}
    />
  );
};

CalendarPicker.propTypes = {
  props: any
};

export default CalendarPicker;
