import './CartMiniTotalAmount.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';
const QUANTITY = '{quantity}';

const CartMiniTotalAmount = ({data, dataSources, totalItem}) => {
  
  const [total, setTotal] = useState({});

  useEffect(()=> {
    const tempTotal = deepCopy(dataSources['Total Label']);
    
    tempTotal.value = `${tempTotal.value.replace(QUANTITY, totalItem)}`;

    setTotal(tempTotal);
  }, [totalItem]);


  return (
    <div className='cart-mini-total-amount'>
      <div className='cart-mini-total-amount__total-price justify-space-between'>
        <span className='cart-mini-total-amount__total-price__label'><Text field={total}/></span>
        <span className='cart-mini-total-amount__total-price__value'>{data.Total}</span>
      </div>
    </div>
  );
};

CartMiniTotalAmount.propTypes = {
  data: PropTypes.any,
  dataSources: PropTypes.object,
  totalItem: PropTypes.any
};

export default CartMiniTotalAmount;
