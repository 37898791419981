import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import FeaturedTilesItemModel from './FeaturedTilesItemModel';

export default class FeaturedTilesFieldsModel {
  constructor() {
    this['CTA Button'] = new SitecoreLinkModel();

    this['CTA Button Text'] = new SitecoreTextModel();

    this.Description = new SitecoreTextModel();

    this['Featured Tile Items'] = [];

    this.Title = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button')) {
      this['CTA Button'].getData(dataSource['CTA Button']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CTA Button Text')) {
      this['CTA Button Text'].getData(dataSource['CTA Button Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Featured Tile Items')) {
      let tempArr = [];

      dataSource['Featured Tile Items'].map((ele) => {
        tempArr.push(new FeaturedTilesItemModel().getData(deepCopy(ele) || {}));
      });

      this['Featured Tile Items'] = tempArr;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    return this;
  }
}
