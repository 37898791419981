import ProductCardStockStatusModel from './ProductCardStockStatusModel';

export default class ProductCardStockModel {
  constructor() {
    this.LimitStockQuantity = '';

    this.StockQuantity = '';

    this.Purchasable = '';

    this.PurchasableLimit = '';

    this.PurchasableLabel = '';

    this.StockStatus = new ProductCardStockStatusModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LimitStockQuantity')) {
      this.LimitStockQuantity = dataSource.LimitStockQuantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StockQuantity')) {
      this.StockQuantity = dataSource.StockQuantity;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Purchasable')) {
      this.Purchasable = dataSource.Purchasable;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PurchasableLimit')) {
      this.PurchasableLimit = dataSource.PurchasableLimit;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PurchasableLabel')) {
      this.PurchasableLabel = dataSource.PurchasableLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'StockStatus')) {
      this.StockStatus.getData(dataSource.StockStatus || {});
    }

    return this;
  }
}
