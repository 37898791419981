import produce from 'immer';

import {
  CHECK_PRIORITY_DELIVERY_FAILED,
  CHECK_PRIORITY_DELIVERY_START,
  CHECK_PRIORITY_DELIVERY_SUCCESS
} from '@redux/actions/priorityDelivery/priorityDeliveryTypes';

const initState = {
  isLoading: false,
  displayPriorityDelivery: {
    StatusDisplay: false,
    CodeTiers: ''
  },
  error: null
};

const priorityDeliveryReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case CHECK_PRIORITY_DELIVERY_START:
      draft.isLoading = true;
      break;

    case CHECK_PRIORITY_DELIVERY_SUCCESS:
      draft.isLoading = false;

      draft.displayPriorityDelivery = {
        StatusDisplay: action.payload.StatusDisplay,
        CodeTiers: action.payload.CodeTiers
      };
      break;

    case CHECK_PRIORITY_DELIVERY_FAILED:
      draft.isLoading = false;

      draft.error = action.payload;
      break;
    }

    return draft;
  });

export default priorityDeliveryReducer;
