import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map,switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/product';
import * as productTypes from '@redux/actions/product/productTypes';
import { post } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import * as config from './config';

const freeGiftEpic = (action$) =>
  action$.pipe(
    ofType(productTypes.GET_FREE_GIFT),
    switchMap(() => {
      const params = {
        productId: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.['fields']?.ProductId?.value,
        dataType: ['FreeGift'],
        itemId: SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.id,
        sortBy:1,
        sortOrder:0
      };

      return from(post(config.ACTION, params)).pipe(
        map((res) => {
          if (res.status === 200) {
            return actions.getFreeGiftSuccess(res?.data.FreeGiftList);
          } else {
            return actions.getFreeGiftFailed('Failed');
          }
        })
      );
    })
  );

export default freeGiftEpic;
