import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

export default class PromotionInStoreModel {
  constructor() {
    this['PlaceHolder Text'] = new SitecoreTextModel();

    this['Default Suggested Terms'] = [];

    this['Link'] = new SitecoreLinkModel;
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PlaceHolder Text')) {
      this['PlaceHolder Text'].getData(dataSource['PlaceHolder Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Default Suggested Terms')) {
      let newSuggestionList = [];

      dataSource['Default Suggested Terms'].map(item => {
        newSuggestionList.push(item.fields.Term.value);
      });

      this['Default Suggested Terms'] = newSuggestionList || [];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Link')) {
      this['Link'].getData(dataSource['Link']);
    }


    return this;
  }
}
