import './AccountSettings.scss';

import React from 'react';


const AccountSettings = () => {
  return (
    <div className='account-setting'>
    </div>
  );
};

export default AccountSettings;