import ProductDetailSpecItemModel from './ProductDetailSpecItemModel';

export default class ProductDetailSpecSectionModel {
  constructor() {
    this.Name = '';

    this.Attributes = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Name')) {
      this.Name = dataSource.Name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Attributes') && dataSource.Attributes?.length) {   
      dataSource.Attributes.map((item) => {
        this.Attributes.push(new ProductDetailSpecItemModel().getData(item || {}));
      });
    }

    return this;
  }
}
