import produce from 'immer';

import StoreFilterCityListModel from '@components/Share/StoreLocator/model/StoreFilterCityListModel';
import * as storeLocatorTypes from '@redux/actions/storeLocator/storeLocatorTypes';
import { deepCopy } from '@utils/utility.js';

const initialState = {
  name: 'Filter City Options',
  filterCityOptions: [],
  isLoading: false,
  errorMsg: ''
};

const filterCityOptionsReducer = (state = initialState, action) => 
  produce(state, draft => {
    let newCityOptions = [];

    switch (action.type) {
    case storeLocatorTypes.GET_FILTER_CITY_LIST:

      draft.isLoading = true;
      break;

    case storeLocatorTypes.GET_FILTER_CITY_LIST_SUCCESS:
      action.payload.map(element => {
        newCityOptions.push(new StoreFilterCityListModel().getData(deepCopy(element) || {}));
      });

      draft.filterCityOptions = newCityOptions;

      draft.isLoading = false;
      break;

    case storeLocatorTypes.GET_FILTER_CITY_LIST_FAILED:
      draft.filterCityOptions = [];

      draft.isLoading = false;
      break;

    case storeLocatorTypes.CLEAR_FILTER_CITY_LIST:
      draft.filterCityOptions = [];

      break;
    }

    return draft;
  });


export default filterCityOptionsReducer;