import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { getProductCountdownTimerFailed, getProductCountdownTimerSuccess } from '@redux/actions/productCountdownTimer';
import { GET_PRODUCT_COUNTDOWN_TIMER } from '@redux/actions/productCountdownTimer/productCountdownTimerTypes';
import { getAsObservable } from '@services/genericService';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';

import { GET_PRODUCT_COUNTDOWN_TIMER_ENDPOINT } from './config';

const productCountdownTimerEpic = (action$) =>
  action$.pipe(
    ofType(GET_PRODUCT_COUNTDOWN_TIMER),
    switchMap(() => {
      const productPageId = SitecoreContextFactoryService.getValueContextRouteItem('itemId');

      return from(getAsObservable(`${GET_PRODUCT_COUNTDOWN_TIMER_ENDPOINT}${productPageId}`)).pipe(
        map((res) => {
          if (res.status === 200) {
            return getProductCountdownTimerSuccess(res);
          } else {
            return getProductCountdownTimerFailed('Failed');
          }
        })
      );
    })
  );

export default productCountdownTimerEpic;
