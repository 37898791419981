export const ACTION_AUTO_COMPLETE = '/Search/GetAutoComplete';

export const ACTION_COMMON_SEARCH = '/Search/SearchContent';

export const ACTION_COMMON_SEARCH_PRODUCT = '/Catalog/Products';

export const ACTION_AUTO_COMPLETE_PRODUCT = '/Search/product-autocomplete';

// Type
export const ITEM_TYPE_UNKNOWN = 0;

export const ITEM_TYPE_ALL = 1;

export const ITEM_TYPE_WELLNESS = 2;

export const ITEM_TYPE_PRODUCT = 3;

export const ITEM_TYPE_POLICY = 4;

export const ITEM_TYPE_RESOURCE = 5;

// Auto Complete
export const COMMON_RESULT_NUMB = 5;

// Search Common
export const COMMON_INIT_PAGE = 1;

export const COMMON_INIT_PAGESZ = 10;

