import FieldsModel from './FieldsModel';

export default class DownloadOSIMAppDetailModel {
  constructor() {
    this['fields'] = new FieldsModel();
  }

  getData(dataSource) { 
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource.fields);
    }

    return this;
  }
}