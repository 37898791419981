import { any, array, string } from 'prop-types';
import React, { useMemo } from 'react';

const ResponsiveImage = (props) => {
  const { imageMobile, srcSet, cssClassPicture, attributes } = props;
  // const [urlImgMobile, setUrlImgMobile] = useState('');

  // useEffect(() => {
  //   if (imageMobile?.value) {
  //     setUrlImgMobile(imageMobile.value?.src ? imageMobile?.value?.src : null);
  //   } else {
  //     setUrlImgMobile(imageMobile);
  //   }
  // }, [imageMobile]);

  const urlImgMobile = useMemo(() => {
    if (imageMobile?.value) {
      return imageMobile.value?.src || '';
    } else {
      return imageMobile;
    }
  }, [imageMobile]);

  return (
    <picture className={cssClassPicture}>
      {Array.isArray(srcSet) &&
        srcSet.map((item) => {
          const {
            mw, // Media min-width break-point
            mxw, // Media max-width break-point
            image // image break-point
          } = item;

          const urlImgRespon = image?.value ? (image.value?.src ? image?.value?.src : null) : image;
          const breakPoint = mw || mxw;

          return <source key={breakPoint} media={mw ? `(min-width: ${breakPoint}px)` : `(max-width: ${breakPoint}px)`} srcSet={urlImgRespon} />;
        })}
      <img src={urlImgMobile} alt={attributes?.alt ?? 'Image Alt'} {...attributes} />
    </picture>
  );
};

ResponsiveImage.propTypes = {
  imageMobile: any,
  srcSet: array,
  cssClassPicture: string,
  attributes: any
};

export default ResponsiveImage;
