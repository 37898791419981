import './SpecialRelatedProduct.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ConfigComponentModel from './models/ConfigComponentModel';
import SpecialRelatedProductModel from './models/SpecialRelatedProductModel';
import SpecialRelatedProductItem from './SpecialRelatedProductItem';

const SpecialRelatedProduct = (props) => {
  const [inputData, setInputData] = useState(null);
  const [configComponent, setConfigComponent] = useState(null);

  const params = {
    lazy: true,
    slidesPerView: 2.5,
    centeredSlides: false,
    spaceBetween: 18,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    centerInsufficientSlides: true,
    pagination: {
      el: '.swiper-pagination',
      type: 'none',
      clickable: true
    },
    breakpoints: {
      768: {
        slidesPerView: 2,
        spaceBetween: 40,
      },
      1200: {
        slidesPerView: configComponent && configComponent['Column Count'] ? parseInt(configComponent['Column Count']) : 2,
        spaceBetween: 100,
      },

      1600: {
        slidesPerView: configComponent && configComponent['Column Count'] ? parseInt(configComponent['Column Count']) : 2,
        spaceBetween: 150,
      }
    }
  };

  useEffect(() => {
    const productSpecialDeals = new SpecialRelatedProductModel();

    const configComponent = new ConfigComponentModel();

    setInputData(productSpecialDeals.getData(deepCopy(props.fields || {})));

    setConfigComponent(configComponent.getData(deepCopy(props.params || {})));
  }, []);

  if (configComponent && !configComponent['Background Color']) {
    return (
      inputData &&
      configComponent && (
        <div className='special-deal'>
          <div className='special-deal__title experience-title'>
            <Text field={inputData['Title']} tag='h2' />
          </div>
          <div className='special-deal__description'>
            <Text field={inputData['Description']} tag='p' />
          </div>
          <div className='special-deal__list'>
            <SwipeComponent param={params}>
              {inputData.Products.map((item, index) => {
                return (
                  <div className='special-deal__list__item' key={item.Id}>
                    <SpecialRelatedProductItem
                      data={item}
                      dataFromLayout={inputData}
                      isShowBuyNow={props.params}
                      indexGA4={index}
                    />
                  </div>
                );
              })}
            </SwipeComponent>
          </div>
        </div>
      )
    );
  } else {
    return (
      inputData &&
      inputData.Products.length > 0 && (
        <div className='special-deal frequently-bought-together'>
          <div className='container'>
            <div className='special-deal__title experience-title'>
              <Text field={inputData['Title']} tag='h2' />
            </div>
            <div className='special-deal__description'>
              <Text field={inputData['Description']} tag='p' />
            </div>
            <div className='special-deal__list'>
              <SwipeComponent param={params}>
                {inputData.Products.map((item, index) => {
                  return (
                    <div className='special-deal__list__item' key={item.Id}>
                      <SpecialRelatedProductItem
                        data={item}
                        dataFromLayout={inputData}
                        isShowBuyNow={props.params}
                        indexGA4={index}
                      />
                    </div>
                  );
                })}
              </SwipeComponent>
            </div>
          </div>
        </div>
      )
    );
  }
};

SpecialRelatedProduct.propTypes = {
  fields: PropTypes.object,
  productSpecialDealsList: PropTypes.any,
  params: PropTypes.object
};

export default SpecialRelatedProduct;
