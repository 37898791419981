import SitecoreTextModel from '@models/SitecoreTextModel';

export default class MyOrderModel {
  constructor() {
    this['Active Orders Tab Label'] = new SitecoreTextModel();

    this['Past Orders Tab Label'] = new SitecoreTextModel();
    
    this['View Details Text'] = new SitecoreTextModel();

    this['Order Label'] = new SitecoreTextModel();

    this['Order Placed Label'] = new SitecoreTextModel();

    this['Order Total Label'] = new SitecoreTextModel();

    this['Product Summary Label'] = new SitecoreTextModel();

    this['Product Summary Label With Other Product'] = new SitecoreTextModel();

    this['Have No Order Label'] = new SitecoreTextModel();

    this['Refer With Order Label'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Active Orders Tab Label')) {
      this['Active Orders Tab Label'].getData(dataSource['Active Orders Tab Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Past Orders Tab Label')) {
      this['Past Orders Tab Label'].getData(dataSource['Past Orders Tab Label']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View Details Text')) {
      this['View Details Text'].getData(dataSource['View Details Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Order Label')) {
      this['Order Label'].getData(dataSource['Order Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Order Placed Label')) {
      this['Order Placed Label'].getData(dataSource['Order Placed Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Order Total Label')) {
      this['Order Total Label'].getData(dataSource['Order Total Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Summary Label')) {
      this['Product Summary Label'].getData(dataSource['Product Summary Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Summary Label With Other Product')) {
      this['Product Summary Label With Other Product'].getData(dataSource['Product Summary Label With Other Product']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Have No Order Label')) {
      this['Have No Order Label'].getData(dataSource['Have No Order Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Refer With Order Label')) {
      this['Refer With Order Label'].getData(dataSource['Refer With Order Label']);
    }

    return this;
  }
}
