import './ReviewsSummary.scss';

import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import ReviewStarRating from '../ReviewStarRating';
import ReviewsSummaryShimmer from './ReviewsSummaryShimmer';

const ReviewsSummary = ({ fieldsData, dataApiReviewsSummary, commerceId }) => {
  const customerReviewsAverageReducer = useSelector((state) => state.productReviewReducer.customerReviewsAverageReducer);

  const onClickReviewLink = () => {
    const commerceProductId =
    commerceId || SitecoreContextFactoryService.getValueContextRouteItem('fields')?.['Commerce Product Source']?.id?.toUpperCase() ||
    JSON.parse(window.sessionStorage.getItem('allReviewDetailsProduct')).commerceProductId;

    if (fieldsData['Write A Review Link']?.value?.href) {
      if (commerceProductId) {
        window.location.href = `${fieldsData['Write A Review Link']?.value?.href}?commerceProductId={${commerceProductId}}`;
      } else {
        window.location.href = fieldsData['Write A Review Link']?.value?.href;
      }
    }
  };

  return (
    fieldsData && !customerReviewsAverageReducer.isLoading ? (
      <div className='reviews-summary'>
        <h2 className='reviews-summary__title'>
          <Text field={fieldsData['Customer Reviews Title Text']} />
        </h2>
        {dataApiReviewsSummary?.Rate ? (
          <div className='reviews-summary__rating'>
            <div className='reviews-summary__rating__star'>
              <ReviewStarRating disableRating defaultRate={dataApiReviewsSummary?.Rate} />
            </div>
            <div className='reviews-summary__rating__text'>
              <span className='reviews-summary__rating__text__star-number'>{dataApiReviewsSummary?.Rate?.toFixed(1)} </span>
              <Text tag='span' className='reviews-summary__rating__text__star-total' field={fieldsData['Out Of Text']} />
            </div>
          </div>
        ) : (
          ''
        )}
        {dataApiReviewsSummary &&
        (dataApiReviewsSummary?.IsDisableRecommendPercent ? (
          ''
        ) : (
          <Text
            tag='p'
            className='reviews-summary__note'
            field={{ value: dataApiReviewsSummary?.RecommendPercent + '% ' + fieldsData['Customer Recommend Percent'].value }}
          />
        ))}
        {dataApiReviewsSummary?.RatingsCount ? (
          <p className='reviews-summary__rating-total'>{dataApiReviewsSummary?.RatingsCount + ' ' + fieldsData['Ratings Text'].value}</p>
        ) : (
          ''
        )}
        <Text tag='p' className='reviews-summary__label' field={fieldsData['Review This Product Text']} />
        <Text tag='p' className='reviews-summary__note-share' field={fieldsData['Share With Other Customer Text']} />
        <div className='reviews-summary__btn'>
          <span className='btn btn-outline-primary' onClick={() => onClickReviewLink()}>
            <Text field={fieldsData['Write A Review Button Text']} />
          </span>
        </div>
      </div>
    ) : (
      <ReviewsSummaryShimmer/>
    )
  );
};

ReviewsSummary.propTypes = {
  fieldsData: PropTypes.any,
  dataApiReviewsSummary: PropTypes.any,
  commerceId: PropTypes.string
};

export default ReviewsSummary;
