import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreLinkModel from '@models/SitecoreTextModel';
import SearchSettingModel from './SearchSettingModel';

const sitecoreTextModelArr = [
    'Buy Now Text',
    'Clear Filter Text',
    'Experience In Store Text',
    'Filter Text',
    'Filter Title',
    'Sort By Label',
    'Colors Label',
    'Price Range Label',
    'Features Label',
    'Compare Text',
    'Apply Filter Text',
    'Deposit Label',
    'Refundable',
    'Rental Price Label',
    'Learn More Text',
    'Sort By PIV',
    'Deposit Label',
    'Login Messages',
    'Login Button Text',
    'Deposit Product Tag Label'
];

export default class ItemResultModel {
    constructor() {
        sitecoreTextModelArr.map((item) => {
            this[item] = new SitecoreTextModel();
        });
        this['Areas Of Concern'] = [];

        this['Buy Now Link'] = new SitecoreLinkModel();

        this['Experience In Store Button Link'] = new SitecoreLinkModel();

        this['Experience In Store Button Text'] = new SitecoreTextModel();

        this['Learn More Button Text'] = new SitecoreTextModel();

        this['Search Settings'] = new SearchSettingModel();

        this['Filter Title'] = new SitecoreTextModel();

        this['No Products Text'] = new SitecoreTextModel();

        this['Products Found Text'] = new SitecoreTextModel();
    }

    getData(dataSource) {
        sitecoreTextModelArr.map((item) => {
            if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
                this[item].getData(dataSource[item]);
            }
        });

        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Link')) {
            this['Buy Now Link'].getData(dataSource['Buy Now Link']);
        }
        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience In Store Button Link')) {
            this['Experience In Store Button Link'].getData(dataSource['Experience In Store Button Link']);
        }

        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Experience In Store Button Text')) {
            this['Experience In Store Button Text'].getData(dataSource['Experience In Store Button Text']);
        }

        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Learn More Button Text')) {
            this['Learn More Button Text'].getData(dataSource['Learn More Button Text']);
        }

        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'No Products Text')) {
            this['No Products Text'].getData(dataSource['No Products Text']);
        }

        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Products Found Text')) {
            this['Products Found Text'].getData(dataSource['Products Found Text']);
        }

        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Search Settings')) {
            this['Search Settings'].getData(dataSource['Search Settings']?.fields || {});
        }

        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Filter Title')) {
            this['Filter Title'].getData(dataSource['Filter Title']?.fields);
        }

        if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Areas Of Concern')) {
            let newAreas = [];

            dataSource['Areas Of Concern'].map((element) => {
                newAreas.push(element || '');
            });

            this['Areas Of Concern'] = newAreas;
        }
        return this;
    }
}
