import SitecoreTextModel from '@models/SitecoreTextModel';

import SitecoreLinkModel from '../../../models/SitecoreLinkModel';
import { deepCopy } from '../../../utils/utility';

const TEXT_ARR = [
  '1st CTA Button Anchor',
  '1st CTA Button Text',
  '2nd CTA Button Anchor',
  '2nd CTA Button Text',
  '3rd CTA Button Anchor',
  '3rd CTA Button Text',
  'Icon',
  'Text Overlay',
  'Enable Audio',
  'Title Color Code',
  'Title Text',
  'Text Position'
];

const LINK_ARR = ['Animated Background Video'];

export default class AnimatedBannerModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });

    this['Animated Background Banner'] = [];
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    LINK_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Animated Background Banner')) {
      let newItemsList = [];

      dataSource['Animated Background Banner'].map((element) => {
        newItemsList.push(deepCopy(element));
      });

      this['Animated Background Banner'] = newItemsList;
    }

    return this;
  }
}