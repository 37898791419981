import './InfoBox.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image,Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import InfoBoxModel from '../Model/InfoBoxModel';


const InfoBox = ({ params, fields }) => {

  const [dataInfoBox, setDataInfoBox] = useState(null);

  useEffect(() => {
    const infoBoxModel = new InfoBoxModel();

    setDataInfoBox(infoBoxModel.getData(deepCopy(fields) || {}));
  }, []);

  return dataInfoBox ? (
    <div className={`os-section-heading animated-slow fadeIn delay-100 ${params?.['Text Color'] === 'White' ? ' os-section-heading--text-light' : ''}`}>
      <div className='os-section-heading__background'>
        {/* <img className='review-image' src={require('@assets/osim-chair.png')} alt='review image'/> */}
        <Image field={dataInfoBox['Background Image']} />
      </div>
      <div className='container text-center os-section-heading__content'>
        <Text field={dataInfoBox.Title} tag='h2' className='os-section-heading__title experience-title' />
        <RichText className='os-section-heading__desc' field={dataInfoBox.Description} />
        {dataInfoBox['CTA Button Text']?.value !== ""?
          (<div className='os-section-heading__btn'>
            <Link className={params?.['Text Color'] === 'White' ? 'btn btn-outline-white' : 'btn btn-outline-CTA2'} field={dataInfoBox['CTA Button']}>
              <Text field={dataInfoBox['CTA Button Text']} />
            </Link>
          </div>)
          :('')
        }
        
      </div>
    </div>
  ) : (
    <div className='os-section-heading skeleton__item'>
      <div className='container text-center'>
        <h2 className='os-section-heading__title experience-title'>
          <span className='text-line-40-center w-40'></span>
        </h2>
        <div className='os-section-heading__desc'>
          <span className='text-line'></span>
        </div>
        <div className='os-section-heading__btn'>
          <span className='button-line-center w-40'></span>
        </div>
      </div>
    </div>
  );
};

InfoBox.propTypes = {
  params: PropTypes.object,
  fields: PropTypes.object
};

export default InfoBox;