import React, { useMemo } from 'react';

import { useTradeInUploadImage } from '../TradeInUploadImageProvider';
import { useRemoveTradeInImage } from './useRemoveTradeInImage';

const ImagePreviews = () => {
  const { formikRefCurrent } = useTradeInUploadImage();
  const handleRemoveTradeInImage = useRemoveTradeInImage();

  const imageTradeInProduct = useMemo(
    () => formikRefCurrent.values.imageTradeInProduct.filter((image) => image?.url),
    [formikRefCurrent.values.imageTradeInProduct]
  );

  return imageTradeInProduct.length ? (
    imageTradeInProduct.map((file, index) => (
      <div className='image-uploaded' key={index}>
        <div className='image-uploaded__content'>
          <img alt={file?.name || 'trade in upload image'} src={file.url} />
        </div>
        <button type='button' className='image-uploaded__remove' onClick={() => handleRemoveTradeInImage(file.url)}>
          <i className='icon-close'></i>
        </button>
      </div>
    ))
  ) : (
    <></>
  );
};

export default ImagePreviews;
