import produce from 'immer';

import * as cpAppointmentDetailTypes from '@redux/actions/cpAppointmentDetail/cpAppointmentDetailTypes';

const initState = {
  isLoading: false,
  isFetchSuccess: false,
  errors: [],
  appointment: {},
  store: {}
};

const cpAppointmentDetailReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case cpAppointmentDetailTypes.CP_GET_APPOINTMENT_DETAIL:
      draft.isLoading = true;

      break;

    case cpAppointmentDetailTypes.CP_GET_APPOINTMENT_DETAIL_SUCCESS:
      draft.isLoading = false;

      draft.isFetchSuccess = true;

      draft.appointment = action.payload?.appointment;

      draft.store = action.payload?.storeAppointment || {};
      
      break;
  
    case cpAppointmentDetailTypes.CP_GET_APPOINTMENT_DETAIL_FAILED:
      draft.isLoading = false;

      draft.errors = action.error;
      break;
    }

    return draft;
  });

export default cpAppointmentDetailReducer;
