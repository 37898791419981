import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import LoadingShimmer from '@components/Share/LoadingShimmer';
import { deepCopy } from '@utils/utility';

import IconItem from './IconItem';
import { 
  formatClassItemInRow,
  ICON_IN_ROW,
  ICON_LIST_BACKGROUND,
} from './iconListHelper';
import IconListModel from './model/IconListModel';

const IconList = (props) => {
  const { fields, params } = props;
  const [dataFields, setDataFields] = useState(null);
  const [isBackground, setIsBackground] = useState(false);
  const [classItemInRow, setClassItemInRow] = useState('');

  useEffect(() => {
    const iconItemModel = new IconListModel();

    setDataFields(iconItemModel.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    if (params && params['Icon In Row']) {
      if (params['Background Color'] === ICON_LIST_BACKGROUND.SAND) {
        setIsBackground(true);
      }
      if (params['Icon In Row'] === ICON_IN_ROW.ONE_A_ROW) {
        setClassItemInRow(formatClassItemInRow(ICON_IN_ROW.ONE_A_ROW));
      } else if (params['Icon In Row'] === ICON_IN_ROW.TWO_A_ROW) {
        setClassItemInRow(formatClassItemInRow(ICON_IN_ROW.TWO_A_ROW));
      } else setClassItemInRow(formatClassItemInRow(ICON_IN_ROW.THREE_A_ROW));
    }
  }, [params]);

  const componentId = params && params['Component Id'] || '';

  return dataFields ? (
    <div className={`list-icon${isBackground ? ' bg-sand' : ''}${classItemInRow} animated-slow fadeIn delay-100`} id={componentId}>
      <div className='container'>
        {
          dataFields['Title'].value !== '' ? <h2 className='experience-title text-center'>{dataFields['Title'].value}</h2> : null
        }
        <div className='row list-icon__wrap'>
          {dataFields.items?.map((iconList) => {
            return <IconItem contentData={iconList.fields} key={iconList.id}></IconItem>;
          })}
        </div>
      </div>
    </div>
  )
    : <LoadingShimmer itemNumb={1}>
      <div className={`list-icon${isBackground ? ' bg-sand' : ''}`}>
        <div className='container'>
          <div className='row list-icon__wrap'>
            <LoadingShimmer itemNumb={1}>
              <div className='list-icon__item skeleton__item'>
                <div className='list-icon__item__image image-wrap'>
                  <span className='image-line'></span>
                </div>
                <div className='list-icon__item__text'>
                  <span className='text-line'></span>
                </div>
              </div>
            </LoadingShimmer>
          </div>
        </div>
      </div>
    </LoadingShimmer>;
  
};

IconList.propTypes = {
  fields: PropTypes.any,
  params: PropTypes.any
};

export default IconList;
