import './DynamicStripAds.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import SwipeComponent from '@components/Share/SwipeComponent';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import DynamicStripAdsFieldsModel from './Model/DynamicStripAdsFieldsModel';
import { handleTrackingStripAdsEvent, STRIP_ADS_EVENT_CLOSE_ACTION, STRIP_ADS_EVENT_VIEW_ACTION } from './utils';

const DynamicStripAds = ({ fields }) => {
  const swiperRef = useRef();
  const [dynamicStripAdsFields, setDynamicStripAdsFields] = useState(null);
  const [isShow, setIsShow] = useState(true);
  const [stripAdsIndexActive, setStripAdsIndexActive] = useState(null);

  const paramsSwipe = {
    direction: 'vertical',
    slidesPerView: 1,
    shouldSwiperUpdate: true,
    watchOverflow: true,
    loop: true,
    speed: 2000,
    autoHeight: true,
    on: {
      slideChange: () => setStripAdsIndexActive(swiperRef.current.swiper.realIndex)
    }
  };

  const classCustom = (textColor) => {
    return textColor === 'Black' ? 'strip-ads__content text-dark' : 'strip-ads__content';
  };

  const styleInline = (backgroundColorSetting) => {
    return backgroundColorSetting
      ? {
        backgroundColor: backgroundColorSetting
      }
      : {};
  };

  const autoplaySwiper = {
    autoplay: {
      delay: 3000,
      disableOnInteraction: false
    }
  };

  useEffect(() => {
    const dynamicStripAdsFieldsModel = new DynamicStripAdsFieldsModel();

    setDynamicStripAdsFields(dynamicStripAdsFieldsModel.getData(deepCopy(fields?.stripAds || {})));
  }, []);

  const handleCloseStripAds = () => {
    setIsShow(false);

    handleTrackingCloseStripAds();
  };

  const handleTrackingCloseStripAds = useCallback(() => {
    const stripAdsActiveData = dynamicStripAdsFields?.stripAdsContent?.[stripAdsIndexActive];

    handleTrackingStripAdsEvent(STRIP_ADS_EVENT_CLOSE_ACTION, stripAdsActiveData.ID, stripAdsActiveData.name);
  }, [stripAdsIndexActive, dynamicStripAdsFields?.stripAdsContent]);

  const handleSelectViewStripAds = (stripAdsData) => {
    handleTrackingStripAdsEvent(STRIP_ADS_EVENT_VIEW_ACTION, stripAdsData.ID, stripAdsData.name);
  };

  return dynamicStripAdsFields && dynamicStripAdsFields.stripAdsContent && isShow ? (
    <div className='strip-ads strip-ads--dynamic'>
      <div className='strip-ads__wrap'>
        <SwipeComponent
          setRef={swiperRef}
          param={dynamicStripAdsFields.stripAdsContent.length > 1 ? { ...paramsSwipe, ...autoplaySwiper } : paramsSwipe}
        >
          {dynamicStripAdsFields.stripAdsContent.map((item, index) => {
            return (
              <div
                className={classCustom(item['textColor'])}
                style={styleInline(item['backgroundColor'])}
                key={index}
                onClick={() => handleSelectViewStripAds(item)}
              >
                <RichText field={item.content} />
              </div>
            );
          })}
        </SwipeComponent>
        <span className='strip-ads__close-btn' onClick={handleCloseStripAds}>
          <i className='strip-ads__close-btn__icon icon-close'></i>
        </span>
      </div>
    </div>
  ) : (
    <></>
  );
};

DynamicStripAds.propTypes = {
  fields: PropTypes.object
};

export default DynamicStripAds;
