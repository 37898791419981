import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getAsObservable } from './genericService';

export const promotionInfoService = async (promotionId) => {
  return await new Promise((resolve) => {
    getAsObservable(`/promotions/information?promotionId=${promotionId}`)
      .pipe(
        map((response) => {
          if (response.status === 200 || response.statusText.toLowerCase() === 'ok' || response.data) {
            return response.data;
          } else {
            return of({ error: true, message: `Error ${response.status}` });
          }
        }),
        catchError((error) => {
          return of({ error: true, message: error.message });
        })
      )
      .subscribe((data) => resolve(data));
  });
};
