import './FullWidthAutoLoopVideo.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch } from 'react-redux';

import Loading from '@components/Share/Loading';
import { MEDIA_TRACKING_GOAL_ID, mediaStartTrackingGoal } from '@redux/actions/trackingGoal/mediaTracking';
import { deepCopy } from '@utils/utility';

import FullWidthAutoLoopVideoFieldsModel from './Model/FullWidthAutoLoopVideoFieldsModel';

const HEADER_HEIGHT = 73;

const FullWidthAutoLoopVideo = (props) => {
  const { fields } = props;
  const videoFullWidth = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const [videoOffsetTop, setVideoOffsetTop] = useState(0);
  const [isUnderVideo, setIsUnderVideo] = useState(false);
  const dispatch = useDispatch();

  const [fullWidthAutoLoopVideo, setFullWidthAutoLoopVideo] = useState(null);

  useEffect(() => {
    const fullWidthAutoLoopVideoFieldsModel = new FullWidthAutoLoopVideoFieldsModel();

    setFullWidthAutoLoopVideo(fullWidthAutoLoopVideoFieldsModel.getData(deepCopy(fields || {})));
  }, []);


  useEffect(() => {
    if (videoFullWidth.current) {
      setVideoOffsetTop(videoFullWidth.current.offsetTop);
    }
  }, [videoFullWidth?.current?.offsetTop]);


  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
    };

    window.addEventListener('scroll', onScroll);

    return () => window.removeEventListener('scroll', onScroll);
  }, [scrollTop]);

  const mediaTrackingGoalWhenPlay = () => {
    dispatch(mediaStartTrackingGoal({
      'GoalId': MEDIA_TRACKING_GOAL_ID,
      'UseTrackerAPI': false
    }));
  };

  const renderVideo = (data) => {
    if (data['Background Video'].value.href) {
      return (
        <video playsInline={true} loop='loop' muted='muted' autoPlay controls onPlay={mediaTrackingGoalWhenPlay}>
          <source src={data['Background Video'].value.href} type='video/mp4'/>
        </video>
      );
    } else if (data.Video.value.href) {
      return (
        <video playsInline={true} loop='loop' muted='muted' autoPlay controls onPlay={mediaTrackingGoalWhenPlay}>
          <source src={data.Video.value.href} type='video/mp4'/>
        </video>
      );
    } else if (data['Youtube video ID'].value) {
      return (
        <ReactPlayer 
          url={`https://www.youtube.com/embed/${data['Youtube video ID'].value}`} 
          onPlay={mediaTrackingGoalWhenPlay} 
          controls={true} 
          playing={true}
          width='100%'
          height='100%'
        />
      );
    } else if (data['Vimeo video ID'].value) {
      return (
        <ReactPlayer 
          url={`https://player.vimeo.com/video/${data['Vimeo video ID'].value}`}
          onPlay={mediaTrackingGoalWhenPlay}
          controls={true} 
          playing={true}
          width='100%'
          height='100%'
        />
      );
    } else return null;
  };

  useEffect(() => {
    if (videoOffsetTop > 0) {
      if (scrollTop > videoOffsetTop - HEADER_HEIGHT) {
        setIsUnderVideo(true);
      } else setIsUnderVideo(false);
    }
  }, [scrollTop, videoOffsetTop]);

  return (
    fullWidthAutoLoopVideo ? (
      <div className={`video-fullwidth${isUnderVideo ? ' is-sticky' : ''} animated-slow fadeIn delay-100`} ref={videoFullWidth}>
        <div className='video-fullwidth__item'>
          <span className='decor--top'></span>
          <span className='decor--bottom'></span>
          <span className='decor--left'></span>
          <span className='decor--right'></span>
          <div className='video-wrap'>
            {renderVideo(fullWidthAutoLoopVideo)}
          </div>
        </div>
      </div>
    ) : (
      <div className='video-fullwidth skeleton__item'>
        <div className='image-wrap'>
          <Loading></Loading>
        </div>
      </div>
    )
  );
};

FullWidthAutoLoopVideo.propTypes = {
  fields: PropTypes.object
};

export default FullWidthAutoLoopVideo;