import { object } from 'prop-types';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import MemberEventsWidgetModel from './model/MemberEventsWidgetModel';

const MemberEventsWidget = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new MemberEventsWidgetModel();

    setLayoutData(model.getData(fields));
  }, []);

  return layoutData ? (
    <div className='member-event-widget'>
      <div className='my-account-section'>
        <div className='my-account-section__heading'>
          <div className='my-account-section__group'>
            <Text field={layoutData?.['Title']} className='my-account-section__heading__title' tag='h3' />
            <Link field={layoutData?.['View All Link']} className='my-account-section__btn-link'>
              <Text field={layoutData?.['View All Label']} />
            </Link>
          </div>
        </div>
        <div className='my-account-section__content'>
          {layoutData?.['No Event Message Text']?.value ? (
            <Text field={layoutData?.['No Event Message Text']} tag='h5' />
          ) : (
            <>
              <Text field={layoutData?.['Comming Event Text']} tag='h5' />
              <Text field={layoutData?.['Comming Month Events Text']} tag='h5' />
            </>
          )}
        </div>
      </div>
    </div> 
  ) : (<></>);
};

MemberEventsWidget.propTypes = {
  fields: object
};
 
export default MemberEventsWidget;