import produce from 'immer';

import * as productReviewTypes from '@redux/actions/productReview/productReviewTypes';

const initState = {
  isLoading: false,
  uploadMultiFilesData: null,
  filesUploadedStore: null
};

const uploadListFilesReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productReviewTypes.UPLOAD_LIST_FILES:
      draft.isLoading = true;
      break;

    case productReviewTypes.UPLOAD_LIST_FILES_SUCCESS:
      draft.isLoading = false;

      draft.uploadMultiFilesData = action.payload;
      break;

    case productReviewTypes.UPLOAD_LIST_FILES_FAILED:
      draft.isLoading = false;
      break;

    case productReviewTypes.LIST_FILES_UPLOAD_STORE:
      draft.filesUploadedStore = action.payload;
      break;
    
    }

    return draft;
  });

export default uploadListFilesReducer;
