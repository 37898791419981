import './TotalOrder.scss';

import PropTypes from 'prop-types';
import React, {useState } from 'react';
import { connect, useSelector } from 'react-redux';

import Message from '@components/Share/Message';
import ModalComponent from '@components/Share/ModalComponent';
import {  Text } from '@sitecore-jss/sitecore-jss-react';
import global from '@utils/global';

import TaxMessageEl from '../../../../TaxMessageDetail/TaxMessageEl';
import OfferAppliedModal from '../../../CartDetails/CartSummary/OfferAppliedModal';

const NUMB = '{num}';

const CartsTotalAmount = ({ layoutData, cartData }) => {
  const currentCartOrder = useSelector((state)=> state?.myOrderReducer?.orderByIdReducer?.currentCartCustom);
  const currentCartBalance = useSelector((state)=> state?.myOrderReducer?.balanceByIdReducer?.currentCartCustom);
  const [popUpDetailCouponList, setPopUpDetailCouponList] = useState(false);
  const [popupDetail, setPopupDetail] = useState({});
  const [showPromotion, setShowPromotion] = useState(false);

  const onAppliedOffersModalComponentHandler = (status, data) => {
    setPopUpDetailCouponList(status);

    setShowPromotion(!status);

    setPopupDetail(data);
  };

  return (
    layoutData &&
    cartData && (
      <div className='cart-summary'>
        <div className='cart-summary__total-amount'>
          <ul className='cart-summary__group'>
            <li className='group-contents'>
              <Text field={layoutData['Delivery Subtotal Label']} tag='label' />
              <div className='text-right'>{cartData.Subtotal}</div>
            </li>
            <li className='group-contents'>
              <div className='group-contents--has-discount'>
                <Text field={layoutData['Billing Discount Label']} tag='label' />
                {cartData.AppliedOffers.length > 0 ? (
                  <span className='discount-applied' onClick={() => setShowPromotion(true)}>{`${layoutData['Discount Description'].value.replace(
                    NUMB,
                    cartData.AppliedOffers.length
                  )}`}</span>
                ) : null}
              </div>
              <div className='text-right'>-{cartData.Discount}</div>
            </li>
            {cartData.IsDeliveryFee ? (
              <>
                <li className='group-contents group-contents--has-sub'>
                  <Text field={layoutData['Billing Delivery Fee Label']} tag='label' />
                  {cartData.IsFreeship ? (
                    <div className='text-right'>
                      <Message messageCode='LBL-Free' />
                    </div>
                  ) : (
                    <div className='text-right'>{cartData.DeliveryTotalAmount > 0 ? cartData.DeliveryTotal : <Message messageCode='LBL-Free' />}</div>
                  )}
                </li>
              </>
            ) : null}
            {cartData.IsDeliveryPickupFee ? (
              <li className='group-contents'>
                <Text field={layoutData['Pick Up Fee Label']} />
                <div className='text-right'>{cartData.SFExpressTotalAmount > 0 ? cartData.SFExpressTotal : <Message messageCode='LBL-Free' />}</div>
              </li>
            ) : (
              ''
            )}
            {layoutData['Show Sales Tax'] ? (
              <li className='group-contents'>
                <Text field={layoutData['Billing Sale Tax Label']} tag='label' />
                <div className='text-right'>{cartData.TaxTotal}</div>
              </li>
            ) : (
              ''
            )}
          </ul>
          <ul className='cart-summary__total-amount__last-list'>
            <li className='group-contents group-contents--has-sub cart-summary__total-amount__total-price'>
              <Text field={layoutData['Billing Total Label']} tag='label' />
              <div className='text-right'>{cartData.Total}</div>
            </li>
            {(currentCartOrder && currentCartOrder?.DepositListProduct.length) ||
            (currentCartBalance && currentCartBalance?.DepositListProduct.length > 0) ? (
                <TaxMessageEl />
              ) : (
                <>
                  <TaxMessageEl />
                  <li className='group-contents'>
                    <div className='contents-left'></div>
                    <div className='text-right text-line-through link-text link-text--gray-darker'>{cartData.OriginalTotal}</div>
                  </li>
                  <li className='group-contents'>
                    <Text field={layoutData['Billing Total Saving Label']} tag='label' />
                    <div className='text-right'>{cartData.SavingTotal}</div>
                  </li>
                </>
              )}
          </ul>
        </div>
        <ModalComponent
          isShow={showPromotion}
          titlePopup={<Text field={layoutData['Applied Offers Title']} tag='span' />}
          onCloseHandler={() => setShowPromotion(false)}
        >
          <OfferAppliedModal
            dataFields={layoutData}
            listPromotion={cartData.AppliedOffers}
            onAppliedOffersModalComponentHandler={onAppliedOffersModalComponentHandler}
          ></OfferAppliedModal>
        </ModalComponent>
        <ModalComponent
          isShow={popUpDetailCouponList}
          onCloseHandler={() => onAppliedOffersModalComponentHandler(false, popupDetail)}
          titlePopup={<Text field={layoutData['Coupon Detail Title']} />}
        >
          <div className='osim-popup__body coupon-popup__info'>
            <h4 className='coupon-popup__info-title'>
              {popupDetail.Title}
              <span> [{popupDetail.Code}]</span>
            </h4>
            <div className='coupon-popup__description'>{popupDetail.Description}</div>
            {popUpDetailCouponList ? (
              <span className='coupon-popup__info-validity'>
                <Text field={layoutData['Valid Until Label']} tag='strong' /> {global.formatDateDDMYYY(popupDetail.ValidityTo)}
              </span>
            ) : (
              <></>
            )}
          </div>
        </ModalComponent>
      </div>
    )
  );
};

CartsTotalAmount.propTypes = {
  fields: PropTypes.any,
  cartData: PropTypes.any,
  areaOptions: PropTypes.any,
  cityOptions: PropTypes.any,
  provinceOptions: PropTypes.any,
  zipcodeOptions: PropTypes.any,
  shippingFee: PropTypes.any,
  layoutData: PropTypes.any,
};

const mapStateToProps = (state) => ({
  shippingFee: state.storeLocatorReducer.estimateShippingFeeReducer.shippingFee?.Price || 0
});

export default connect(mapStateToProps)(CartsTotalAmount);
