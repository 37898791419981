import './StoreExperiences.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';

import StoreExperiencesModel from './model/StoreExperiencesModel';

const StoreExperiences = ({fields})=> {

  const [dataSources, setDataSources] = useState(null);
  const [storeId, setStoreId] = useState(null);

  useEffect(()=> {
    setDataSources(new StoreExperiencesModel().getData(fields || {}));
    const urlSearchParam = new URLSearchParams(window.location.search);

    setStoreId(urlSearchParam.get('storeId'));
  }, []);
      
  return dataSources ? (
    <div className='store-experiences'>
      <div className='container'>
        <Text field={dataSources?.Title} tag='h2' className='store-experiences__heading'/>
        <div className='store-experiences__content'>
          {
            dataSources?.['List Store Experiences'].map((item, index)=> {
              return (
                <div key={index} className='store-experiences__content__item'>
                  <div className='store-experiences__content__item__icon'>
                    <Image field={item?.fields?.Icon} className='store-experiences-icon'/>
                  </div>
                  <Text field={item?.fields?.Description} tag='p' className='store-experiences__content__item__text'/>
                </div>
              );
            })
          }
        </div>
        <div className='store-experiences__btn-book'>
          <Link field={{ href: `${dataSources?.['Button Book Experience Link']?.value?.href}${storeId ? `?storeId=${storeId}` : ''}` }} className='store-experiences__btn-book__item btn btn-outline-CTA2'>
            <Text field={dataSources?.['Button Book Experience Text']}/>
          </Link>
        </div>
      </div>
    </div>
  ) : (<></>);
};

StoreExperiences.propTypes = {
  fields: PropTypes.object
};

export default StoreExperiences;