import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

const TaskListAuthen = ({fields}) => {
  const dispatch = useDispatch();
  const [isErrorLogin, setErrorLogin] = useState(false);

  useEffect(() => {
  }, []);

  return isErrorLogin ?  (
    <div>
    </div>
  ) : 'Loading...';
};

TaskListAuthen.propTypes = {
  fields: PropTypes.object,
  isLogin: PropTypes.bool,
};

export default TaskListAuthen;
