import './DeliveryComponent.scss';

import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { hideDeliveryPackagesSection } from '@redux/actions/controlSelectingFreeGiftPopup';
import {ENUM_PACKAGE_CLASS} from '@utils/constant';

import { showDeliveryPackagesSection } from '../../../redux/actions/controlSelectingFreeGiftPopup';
import BillingAddressComponent from './BillingAddressComponent';
import ProductListComponent from './ProductListComponent';
import StoreAddressComponent from './StoreAddressComponent';
import UserAddressComponent from './UserAddressComponent';

const PACKAGE = '{seq}';

export const DELIVERY_PACKAGES_COMPONENT_ID = 'delivery-packages-component';

const DeliveryComponent = ({ type, layoutData, data, depositProduct, packageNumber, page, showError, expandPackage }) => {
  const dispatch = useDispatch();
  const currentCustomCart = useSelector((state) => state.productReducer.cartDetailReducer.currentCartCustom);
  const [packageTitle, setPackageTitle] = useState('');
  const { isShowDeliveryPackagesSection } = useSelector((state) => state.controlSelectingFreeGiftPopupReducer);
  const clientSOCartCustomData = useSelector((state) => state.personalizeOfferReducer.getClientSOCartReducer.customData);

  const cartData = useMemo(() => {
    return clientSOCartCustomData || currentCustomCart;
  }, [clientSOCartCustomData, currentCustomCart]);

  useEffect(() => {
    if (expandPackage === '1') dispatch(showDeliveryPackagesSection());
  }, [expandPackage]);

  useEffect(() => {
    let title = '';
    if (type === 'billing-details') {
      title = layoutData['Billing Title'].value;
    } else if (type === 'delivery') {
      title = layoutData['Delivery Package Title'].value.replace(PACKAGE, packageNumber);
    } else if (type === 'pick-up-store') {
      title = `Package ${packageNumber}: Pick Up From Store`;
    } else if (type === 'sf-express') {
      title = layoutData['SF Pickup Package Title'].value.replace(PACKAGE, packageNumber);
    } else if (type === 'delivery-by-sf-express') {
      title = layoutData['Delivery SF Express Package Label'].value.replace(PACKAGE, packageNumber);
    } else if (type === 'deposit') {
      title = layoutData['Deposit Label']?.value;
    }

    setPackageTitle(title);
  }, []);

  const getClassForPackage = (type) => {
    return ENUM_PACKAGE_CLASS.find(item => item.type === type)?.class || '';
  };

  const handleCloseCollapse = useCallback(() => {
    if (isShowDeliveryPackagesSection) dispatch(hideDeliveryPackagesSection());
    else dispatch(showDeliveryPackagesSection());
  }, [isShowDeliveryPackagesSection]);

  return (
    layoutData && cartData && (
      <div id={DELIVERY_PACKAGES_COMPONENT_ID} className={`delivery-component ${page === 'review' && type !== 'billing-details' ? 'review-payment' : ''} ${isShowDeliveryPackagesSection ? 'is-collapsed' : ''}`}>
        <div className='container'>
          <div className='delivery-component__header' onClick={handleCloseCollapse}>
            <div className='delivery-component__header__icon'>
              <span className={`package-icon ${getClassForPackage(type)}`}></span>
            </div>
            <h2 className='delivery-component__header__title'>{packageTitle}</h2>
            <div className='delivery-component__header__icon-collapse'>
              <i className='icon-chevron-down-big'></i>
            </div>
          </div>
          {page === 'review' ? (
            <div className='delivery-component__wrapper'>
              <div className='delivery-container'>
                {type === 'billing-details' ? (
                  <>
                    <BillingAddressComponent cartData={cartData} showError={showError} layoutData={layoutData} type={type} page={page}></BillingAddressComponent>
                  </>
                ) : type === 'delivery' || type == 'delivery-by-sf-express' ? (
                  <Collapse in={isShowDeliveryPackagesSection}>
                    <div>
                      <UserAddressComponent cartData={cartData} showError={showError} layoutData={layoutData} type={type} page={page} cartLineData={data}></UserAddressComponent>
                      <ProductListComponent layoutData={layoutData} type={type} page={page} productList={data}></ProductListComponent>
                    </div>
                  </Collapse>
                ) : (
                  <Collapse in={isShowDeliveryPackagesSection}>
                    <div>
                      <StoreAddressComponent cartData={cartData} showError={showError} layoutData={layoutData} data={data} type={type} page={page}></StoreAddressComponent>
                      <ProductListComponent layoutData={layoutData} type={type} page={page} productList={data}></ProductListComponent>
                    </div>
                  </Collapse>
                )}
              </div>
            </div>
          ) : (
            <div className='delivery-component__wrapper' id={type !== 'pick-up-store' ? type : data.ExternalCartLineId}>
              <div className='delivery-container'>
                {type === 'billing-details' ? (
                  <BillingAddressComponent cartData={cartData} showError={showError} layoutData={layoutData} type={type} page={page}></BillingAddressComponent>
                ) : type === 'delivery' || type == 'delivery-by-sf-express' ? (
                  <>
                    <UserAddressComponent cartData={cartData} showError={showError} layoutData={layoutData} type={type} page={page} cartLineData={data}></UserAddressComponent>
                    <ProductListComponent layoutData={layoutData} type={type} page={page} productList={data}></ProductListComponent>
                  </>
                ) : type === 'deposit' ? (
                  <>
                    <UserAddressComponent cartData={cartData} showError={showError} layoutData={layoutData} type={type} page={page} cartLineData={data}></UserAddressComponent>
                    {depositProduct && <ProductListComponent layoutData={layoutData} type={type} page={page} productList={[]} productItem={depositProduct}></ProductListComponent>}
                  </>
                ) : (
                  <>
                    <StoreAddressComponent cartData={cartData} showError={showError} layoutData={layoutData} data={data} type={type} page={page}></StoreAddressComponent>
                    <ProductListComponent layoutData={layoutData} type={type} page={page} productList={data}></ProductListComponent>
                  </>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    )
  );
};

DeliveryComponent.propTypes = {
  type: PropTypes.string,
  data: PropTypes.any,
  depositProduct: PropTypes.any,
  layoutData: PropTypes.any,
  cartData: PropTypes.any,
  packageNumber: PropTypes.number,
  page: PropTypes.string,
  showError: PropTypes.bool,
  expandPackage: PropTypes.string
};

export default DeliveryComponent;
