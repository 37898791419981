import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import Global from '@utils/global';

export const convertEvalDate = (date) => {
  const language = SitecoreContextFactoryService.getValueContextItem('language');
  const dateConverted = Global.customDate(date, false);

  if (language === 'en') {
    const splitDate = dateConverted?.split(' ') || [];

    if (splitDate.length >= 3) {
      const [date, month, year] = splitDate;

      return `${date <= 9 ? '0' : ''}${date} ${month} ${year}`;
    }
  }

  return dateConverted;
};

export const formatTierAmountNumber = (number) => new Intl.NumberFormat('en-US').format(Number(number));
