import './StepsHeader.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import CheckoutStepsModel from './model/CheckoutStepsModel';

const StepsHeader = (props) => {
  const { fields } = props;
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const currentActiveStep = SitecoreContextFactoryService.getValueContextRouteItem('fields').Step?.value || 1;

    const model = new CheckoutStepsModel().getData(deepCopy(fields));

    model['Checkout Steps'].forEach((element) => {
      if (element.Step.value < currentActiveStep) {
        element.isActive = true;

        element.isCurrent = false;
      } else if (element.Step.value === currentActiveStep) {
        element.isCurrent = true;

        model['title'] = getPageTitleName(model, currentActiveStep);
      } else {
        element.isCurrent = false;

        element.isActive = false;
      }
    });

    setInputData(model);
  }, []);

  const getPageTitleName = (model, currentActiveStep) => {
    return +currentActiveStep === 1 ? model['Your Detail Title'] : +currentActiveStep === 2 ? model['Delivery Title'] : model['Review and Payment Title'];
  };

  return inputData && (
    <div className='checkout-process-stepper'>
      <div className='checkout-process-stepper__title'><Text field={inputData['title']} /></div>
      <div className='container checkout-process-stepper__wrap'>
        {inputData['Checkout Steps'].map((item, index) => {
          return (
            <div className={`checkout-process-stepper__step ${item.isActive ? 'active' : ''}${item.isCurrent ? 'processing' : ''}`} key={index}>
              <div className='checkout-process-stepper__step__line'>
                <div className='step-icon'>
                  <div className='step-icon__item'></div>
                  {/* <img className='product-also-bought-image' src={item['Process Icon'].value.src} alt='No alt' /> */}
                </div>
              </div>
              <div className='checkout-process-stepper__step__info'>
                <div className='checkout-process-stepper__step__info__icon'>
                  <img className='product-also-bought-image' src={item['Process Icon'].value.src} alt='No alt' />
                </div>
                <span className='checkout-process-stepper__step__info__title'>{item['Process Content'].value}</span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

StepsHeader.propTypes = {
  fields: PropTypes.any
};

export default StepsHeader;
