import SitecoreImageModel from '@models/SitecoreImageModel';

export default class BannerImgModel {
  constructor() {
    this['Banner Image'] = new SitecoreImageModel();

    this['Mobile Banner Image'] = new SitecoreImageModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Image')) {
      this['Banner Image'].getData(dataSource['Banner Image']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Mobile Banner Image')) {
      this['Mobile Banner Image'].getData(dataSource['Mobile Banner Image']);
    }

    return this;
  }
}
