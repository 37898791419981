import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { validateScheduleOtpFailure, validateScheduleOtpSuccess } from '@redux/actions/bookingExperience/validateScheduleOtp';
import { VALIDATE_SCHEDULE_OTP_CODE_START } from '@redux/actions/bookingExperience/validateScheduleOtp/types';
import { getContactStart } from '@redux/actions/campaignRegister/getContact';
import { postAsObservable } from '@services/genericService';
import { MY_SITE_NAME } from '@utils/constant';
import { isAtSiteName } from '@utils/customsHook/useIsAtSiteName';
import { deepCopy } from '@utils/utility';

import { addCurlyBrackets } from '../utils';
import { VALIDATE_SCHEDULE_OTP_CODE_ENDPOINT } from './config';

const validateScheduleOtpEpic = (action$) =>
  action$.pipe(
    ofType(VALIDATE_SCHEDULE_OTP_CODE_START),
    switchMap((action) => {
      const phoneNumberRawValue = action.payload.mobileNumber;
      const phoneNumber = isAtSiteName(MY_SITE_NAME) ? `+${phoneNumberRawValue}` : phoneNumberRawValue;

      const params = {
        ProductId: addCurlyBrackets(action.payload.productId),
        MobileNumber: phoneNumber,
        OTP: action.payload.optCode
      };

      return postAsObservable(VALIDATE_SCHEDULE_OTP_CODE_ENDPOINT, params).pipe(
        switchMap((res) => {
          if (res.data.StatusCode === 0) {
            const getContactParams = {
              mobileNumber: phoneNumber,
              emailAddress: action.payload.emailAddress
            };

            return of(validateScheduleOtpSuccess(deepCopy(res.data)), getContactStart(getContactParams));
          } else {
            return of(validateScheduleOtpFailure(res.data || 'Failed'));
          }
        })
      );
    }),
    catchError((error) => of(validateScheduleOtpFailure(error.message || 'Failed')))
  );

export default validateScheduleOtpEpic;
