import './AddToCart.scss';

import PropTypes from 'prop-types';
import React, { Fragment, useCallback } from 'react';

import { SEARCH_PARAMS_CART_NAME_KEY } from '@components/ClientPortal/config';
import { getCartNameValue } from '@redux/epic/product/cartDetail/utils';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useQuery } from '@utils/customsHook/useQuery';

const AddToCart = ({ dataFromLayout }) => {
  const query = useQuery();

  const handleViewCart = useCallback(() => {
    let cartLinkProp = dataFromLayout?.['CTA Button'];
    const cartName = getCartNameValue();
    const cCode = query.get('cCode');
    if (cCode) query.set('cCode', cCode);
    if (cartName) query.set(SEARCH_PARAMS_CART_NAME_KEY, cartName);

    window.location.href = `${cartLinkProp?.value?.href}?${query.toString()}`;
  }, [dataFromLayout]);

  return (
    <Fragment>
      {dataFromLayout.Product.SubLinesImages.length ? (
        <div className='add-to-cart__header add-to-cart__header--bundle'>
          <div className='container add-to-cart__header__container'>
            <div className='add-to-cart__header__image add-to-cart__header__image--bundle'>
              {dataFromLayout.Product.SubLinesImages.map((item, index) => {
                return (
                  <div className='add-to-cart__header__image__item' key={index}>
                    <img src={item} alt='image' />
                  </div>
                );
              })}
            </div>
            <div className='add-to-cart__header__contents'>
              <div className='add-to-cart__header__contents__text'>
                <p className='add-to-cart__header__contents__text__name'>{dataFromLayout.Product.ProductName}</p>
              </div>
              <div className='add-to-cart__header__contents__button'>
                <button className='btn btn-primary flex-between' onClick={handleViewCart}>
                  <Text field={dataFromLayout['CTA Button Text']} tag='span' />
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='add-to-cart__header'>
          <div className='container add-to-cart__header__container'>
            <div className='add-to-cart__header__image'>
              <img src={dataFromLayout.Product.ImageURLs} alt='no image' />
            </div>
            <div className='add-to-cart__header__contents'>
              <div className='add-to-cart__header__contents__text'>
                <p className='add-to-cart__header__contents__text__name'>{dataFromLayout.Product.ProductName}</p>
              </div>
              <div className='add-to-cart__header__contents__button'>
                <button className='btn btn-primary flex-between' onClick={handleViewCart}>
                  <Text field={dataFromLayout['CTA Button Text']} tag='span' />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

AddToCart.propTypes = {
  dataFromLayout: PropTypes.object
};

export default AddToCart;
