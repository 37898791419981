import './Image.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ImageModel from './Model/ImageModel';

const Image = ({ fields }) => {

  const [imageData, setImageData] = useState(null);

  const [imageTargetUrl, setImageTargetUrl] = useState(null);

  useEffect(() => {
    setImageData(new ImageModel().getData(deepCopy(fields || {})));

    setImageTargetUrl(new SitecoreLinkModel().getData(deepCopy(fields?.TargetUrl)));
  }, []);

  return imageData ? (
    <>
      {
        imageTargetUrl && imageTargetUrl.value.href !== '' ? (
          <Link field={imageTargetUrl}>
            <img alt={imageData.Image.value.alt} src={imageData.Image.value.src} />
          </Link>
        ) : (
          <div className='image-main'>
            <img alt={imageData.Image.value.alt} src={imageData.Image.value.src} />
          </div>
        )
      }
    </>
  )
    : <LoadingShimmer itemNumb={1}>
      <div className='image-main skeleton__item'>
        <div className='image-main__background'>
          <Loading></Loading>
        </div>
      </div>
    </LoadingShimmer>;
};

Image.propTypes = {
  fields: PropTypes.object
};

export default Image;
