import './ProductListingItem.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import BuyButtonComponent from '@components/Product/BuyButtonComponent/BuyButtonComponent';
import ProductCountdownTimer from '@components/ProductCountdownTimer';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import InfoStockComponent from '@components/Share/InfoStockComponent';
import ToggleWisList from '@components/Share/ToggleWisList';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import ProductCardPrice from '../../../ProductCardPrice';
// import InfoStockComponent from '@components/Share/InfoStockComponent';
import BookExperienceCta from './BookExperienceCta';
import ProductListingItemLi from './ProductListingItemLi';

const ProductListingItem = ({ dataFromAPI, dataFromLayout, indexGA4, handleOpenPopupLogin }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const isUserLogin = useSelector((state) => state.singleSignOnReducer.userInfoReducer.isLogin);
  const [buyNowLink, setBuyNowLink] = useState(deepCopy(dataFromLayout['Buy Now Link']));
  const [purchaseLabel, setPurchaseLabel] = useState('');
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');

  const isGA4 = Global.getIsGA4();

  const {
    IsEnable,
    StartTime,
    EndTime,
    TitleText,
    DaysText,
    HoursText,
    MinutesText,
    SecondsText,
    TitleTextColorCode,
    CountdownTimerTextColorCode,
    DateUnitColorCode,
    TitleTextFontSize,
    CountdownTimerTextFontSize,
    DateUnitFontSize,
    TitleTextMobileFontSize,
    CountdownTimerTextMobileFontSize,
    DateUnitMobileFontSize,
    BackgroundImageUrl
  } = dataFromAPI?.ProductCountdownTimer;

  useEffect(() => {
    let tempLink = deepCopy(buyNowLink);
    const linkObj = dataFromLayout['Buy Now Link'];

    tempLink.value.href = `${linkObj.value.href}?productPageId=${dataFromAPI.Id || ''}${
      dataFromAPI.IsRentalProduct ? '&datatype=RefunableDeposit' : ''
    }&dataType=TradeIn&LstName=${dataFromAPI.CategoryName}&IndexGA4=${indexGA4}`;

    setBuyNowLink(tempLink);

    dataFromAPI.IsRentalProduct
      ? setPurchaseLabel(messageObj['LBL-RentNowLabel'])
      : dataFromAPI.IsDeposit
        ? setPurchaseLabel(dataFromLayout['Deposit Label']?.value)
        : setPurchaseLabel(dataFromAPI.Stock?.PurchasableLabel);
  }, []);

  const handleGa4SelectItem = (action, event) => {
    if (isGA4) {
      const queryParams = new URLSearchParams(window.location.search);
      const pdpPromoCode = queryParams.get('cCode');
      let dataConvert = [];

      dataConvert.push(dataFromAPI);
      let paramsSelectItem = {
        ecommerce: {
          action: action,
          items: Global.handleGA4ViewAndSelectItems(indexGA4, dataConvert, '', pdpPromoCode)
        }
      };

      new GoogleAnalytic().gAnalytic4('event', 'select_item', paramsSelectItem);
    }

    // Handle show pop-up login
    if (!isUserLogin && dataFromAPI.IsDeposit) {
      event.preventDefault();

      handleOpenPopupLogin(buyNowLink);
    }
  };

  return (
    <div className='swiper-slide category-landing__product category-product'>
      <div className='category-product__wrap'>
        <div className='category-product__contents'>
          <div className='category-product__image'>
            <a
              href={`${dataFromAPI.Url}?IndexGA4=${indexGA4}&CategoryName=${dataFromAPI.CategoryName}&IndexGA4=${indexGA4}&CategoryPageId=${dataFromAPI.CategoryPageId}`}
              onClick={() => handleGa4SelectItem('Image')}
            >
              <img
                className='category-product__image__item'
                src={dataFromAPI.Image}
                alt={dataFromAPI.ProductName ? dataFromAPI.ProductName : 'category item image'}
                title={dataFromAPI.CategoryName}
              />
            </a>
            <ul className='category-product__list-tag list-tag'>
              {dataFromAPI.Labels.length > 0 &&
                dataFromAPI.Labels.map((item, index) => {
                  if (index < 4) {
                    return <ProductListingItemLi data={item} key={index} />;
                  }
                })}
            </ul>
            <ToggleWisList dataProduct={{ indexGA4: indexGA4, ...dataFromAPI }} />
          </div>
          <div className='category-product__main'>
            {dataFromAPI.LastFewUnitsLeftLabel ? <span className='info-stock-message'>{dataFromAPI.LastFewUnitsLeftLabel}</span> : ''}
            {/* <InfoStockComponent
              stock={dataFromAPI?.Stock}
            >
              {dataFromAPI.LastFewUnitsLeftLabel}
            </InfoStockComponent> */}
            <h3 className='category-product__main__name'>
              <a
                className='category-product__main__name__link'
                href={`${dataFromAPI.Url}?IndexGA4=${indexGA4}&CategoryName=${dataFromAPI.CategoryName}&CategoryPageId=${dataFromAPI.CategoryPageId}`}
                onClick={() => handleGa4SelectItem('Image')}
              >
                {dataFromAPI.ProductName}
              </a>
            </h3>
            {parseInt(IsEnable) === 1 ? (
              <div className='category-product__main__countdown'>
                <ProductCountdownTimer
                  fontSize={{
                    dateMobileUnit: DateUnitMobileFontSize || '',
                    dateUnit: DateUnitFontSize || '',
                    timerMobileText: CountdownTimerTextMobileFontSize || '',
                    timerText: CountdownTimerTextFontSize || '',
                    titleMobileText: TitleTextMobileFontSize || '',
                    titleText: TitleTextFontSize || ''
                  }}
                  // colorCode={{
                  //   dateUnitText: DateUnitColorCode || '',
                  //   timerText: CountdownTimerTextColorCode || '',
                  //   titleText: TitleTextColorCode || ''
                  // }}
                  text={{
                    title: TitleText || '',
                    days: DaysText || '',
                    hours: HoursText || '',
                    minutes: MinutesText || '',
                    seconds: SecondsText || ''
                  }}
                  startTime={parseInt(StartTime?.replaceAll(/[/Date()]/gm, '')) || ''}
                  endTime={parseInt(EndTime?.replaceAll(/[/Date()]/gm, '')) || ''}
                  // countdownBgImage={BackgroundImageUrl || ''}
                />
              </div>
            ) : (
              <></>
            )}
            <ProductCardPrice
              isRentalProduct={dataFromAPI.IsRentalProduct}
              rentalPriceLabel={dataFromLayout?.['Rental Price Label']?.value}
              isDeposit={dataFromAPI?.IsDeposit}
              depositLabel={dataFromLayout?.['Deposit Label']?.value}
              depositTagLabel={dataFromLayout?.['Deposit Product Tag Label']?.value}
              depositPriceWithCurrency={dataFromAPI?.Deposit?.DepositPriceWithCurrency}
              depositSellingPriceWithCurrency={dataFromAPI.RefundableDepositProducts[0]?.Price.SellingPriceWithCurrency}
              sellingPriceWithCurrency={dataFromAPI.Price.SellingPriceWithCurrency}
              listPriceWithCurrency={dataFromAPI.Price.ListPriceWithCurrency}
              priceExtraInformation={dataFromAPI.PriceExtraInformation}
              refundableLabel={dataFromLayout?.['Refundable']?.value}
              mainPriceClassName='category-product__main__price product-price'
              oldPriceClassName='category-product__main__old-price'
            />
            {/* <p className='category-product__main__sort-decs'>{dataFromAPI.Description}</p> */}
            <RichText className='category-product__main__sort-decs' field={{ value: dataFromAPI.Description }} />
            <div className='category-product__main__btn-link'>
              {dataFromAPI.IsBundle ? (
                <></>
              ) : dataFromLayout?.['Learn More Text'].value ? (
                <a
                  className={`${
                    SitecoreContextFactoryService.getValueContextItem('site').name === 'OCN' ? 'btn btn-primary' : 'btn-link'
                  } category-product__main__link`}
                  href={dataFromAPI.Url}
                >
                  <Text tag='span' field={dataFromLayout?.['Learn More Text']} />
                </a>
              ) : (
                <></>
              )}
              {dataFromAPI.BundleDealsButtonUrl ? (
                <a className='btn-link category-product__main__link' href={dataFromAPI.BundleDealsButtonUrl}>
                  <span>{messageObj?.['LBL_BundleDealsButtonUrl']}</span>
                </a>
              ) : (
                <></>
              )}
            </div>
            {SitecoreContextFactoryService.getValueContextItem('site').name === 'OCN' ? (
              <></>
            ) : (
              <div className='category-product__main__btn'>
                {dataFromAPI?.IsBookExperience ? <BookExperienceCta layoutData={dataFromLayout} productId={dataFromAPI?.Id} /> : <></>}
                <BuyButtonComponent
                  productData={{
                    ...dataFromAPI,
                    productId: `${dataFromAPI.ProductId}|${dataFromAPI.RepresentativeVariantId}`
                  }}
                >
                  {dataFromAPI.IsBundle ? (
                    <a className='category-product__main__btn__buy-options btn btn-outline-CTA2' href={dataFromAPI.Url}>
                      <Text field={dataFromLayout['Learn More Text']} tag='span' />
                    </a>
                  )
                  //   : isClientTelingUser ? (
                  //   dataFromLayout?.['CP Buy Options Text']?.value ? (
                  //     <Link
                  //       className='category-product__main__btn__buy-options btn btn-primary'
                  //       field={{ href: `${dataFromLayout['CP Buy Options Link']?.value?.href}?productPageId=${dataFromAPI?.Id}` }}
                  //       onClick={(ev) => handleGa4SelectItem('Buy Option', ev)}
                  //     >
                  //       {dataFromAPI?.IsDeposit ? (
                  //         <Text field={dataFromLayout['Deposit Label']} />
                  //       ) : (
                  //         <Text field={dataFromLayout['CP Buy Options Text']} />
                  //       )}
                  //     </Link>
                  //   ) : (
                  //     <></>
                  //   )
                  // )
                    : (
                      <Link
                        className='category-product__main__btn__buy-options btn btn-primary'
                        field={buyNowLink}
                        onClick={(ev) => handleGa4SelectItem('Buy Now', ev)}
                      >
                        {purchaseLabel}
                      </Link>
                    )}
                </BuyButtonComponent>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

ProductListingItem.propTypes = {
  dataFromLayout: PropTypes.object,
  dataFromAPI: PropTypes.object,
  indexGA4: PropTypes.number,
  handleOpenPopupLogin: PropTypes.func
};

export default ProductListingItem;
