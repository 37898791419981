import { PropTypes } from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { addProductWishlist, removeProductWishlist, setProductWishlistCurrent } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';

import GoogleAnalytic from '../GoogleAnalytic';
import Loading from '../Loading';

const ToggleWisList = (props) => {
  const { dataProduct, quantity, quantityDefault } = props;

  const [toogleWistList, setToogleWishList] = useState({
    isActive: false,
    wishListItem: null
  });
  const dispatch = useDispatch();
  const isLoginSuccess = useSelector((state) => state.singleSignOnReducer.userInfoReducer.isLogin);
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const productWishlistCurrent = useSelector((state) => state.setProductWishlistCurrentReducer.productWishlistCurrent);
  const productWishlistReducer = useSelector((state) => state.productReducer.productWishlistReducer);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const isSiteNameValid = SitecoreContextFactoryService.getValueContextItem('site')?.name !== 'OCN';
  const isGA4 = Global.getIsGA4();
  const discountWithPriceObject = dataProduct?.Price?.ListPrice - dataProduct?.Price?.SellingPrice;
  const discountWithoutPriceObject = dataProduct?.ListPrice - dataProduct?.SellingPrice;

  useDidUpdateEffect(() => {
    const isAddWishList = Global.getQueryParams(window.location.href, 'isAddWishList');
    if(isAddWishList && !productWishlistReducer.isLoading) {
      dispatch(setProductWishlistCurrent(''));
    }
  }, [productWishlistReducer.isLoading]);

  //New Logic ON/OFF GA, GA4
  // useDidUpdateEffect(() => {
  //   //Ignore China site
  //   if (isSiteNameValid && isGA4) {
  //     //Check Add wish list is success
  //     if (productWishlistReducer.isAddWishListSuccess) {
  //       let params = {
  //         ecommerce: {
  //           currency: dataProduct?.Price?.CurrencyCode || '',
  //           value: dataProduct?.Price?.SellingPrice,
  //           items: Global.getItems([{...dataProduct}]),
  //         }
  //       };

  //       //GA4
  //       new GoogleAnalytic().gAnalytic4('event', 'add_to_wishlist', params);
  //     }
  //   }
  // }, [productWishlistReducer.isAddWishListSuccess]);

  useEffect(() => {
    const isAddWishList = Global.getQueryParams(window.location.href, 'isAddWishList');
    if(!productWishlistReducer.isGetWishListLoading && productWishlistReducer.listProductWishlist && productWishlistCurrent && isAddWishList) {      
      const isAlreadyWishList = productWishlistReducer.listProductWishlist.find(wishListItem => wishListItem.Product.ProductId.toLowerCase() === productWishlistCurrent.productId.toLowerCase());
      if(!isAlreadyWishList) {
        dispatch(addProductWishlist(productWishlistCurrent));

        //New Logic ON/OFF GA, GA4
        if (isSiteNameValid && isGA4 && productWishlistCurrent.productId === dataProduct.ProductId) {
          //Check Add wish list is success
          let params = {
            ecommerce: {
              currency: dataProduct?.Price?.CurrencyCode || '',
              value: dataProduct?.Price?.SellingPrice,
              items: Global.getItems(
                [{ ...dataProduct }],
                '',
                '',
                '',
                dataProduct.indexGA4 + 1,
                discountWithPriceObject || discountWithoutPriceObject
              )
            }
          };

          //GA4
          new GoogleAnalytic().gAnalytic4('event', 'add_to_wishlist', params);
        }
      }
    }

  }, [productWishlistReducer.isGetWishListLoading]);

  useEffect(() => {
    if (productWishlistReducer.listProductWishlist && dataProduct) {
      const wishList = productWishlistReducer?.listProductWishlist?.find(
        (wishListItem) => (wishListItem?.Product?.ProductId?.toLowerCase() === dataProduct?.ProductId?.toLowerCase())
      );

      setToogleWishList({
        ...toogleWistList,
        isActive: wishList ? true : false,
        wishListItem: wishList
      });
    }
  }, [productWishlistReducer.listProductWishlist, dataProduct]);

  const onToggleWishListHandler = () => {
    
    if (toogleWistList.isActive) {
      dispatch(removeProductWishlist({
        lineIds: toogleWistList.wishListItem.ExternalWishListLineId,
        productId: dataProduct.ProductId
      }));
    } else {
      const wishListModel = {
        productId: dataProduct.ProductId,
        variantId: dataProduct?.VariantId || '',
        quantity: +quantity || quantityDefault
      };
      if (isLoginSuccess) {
        dispatch(
          addProductWishlist(wishListModel)
        );
        
        //New Logic ON/OFF GA, GA4
        if (isSiteNameValid && isGA4 && wishListModel.productId === dataProduct.ProductId) {
          let params = {
            ecommerce: {
              currency: dataProduct?.Price?.CurrencyCode || '',
              value: dataProduct?.Price?.SellingPrice,
              items: Global.getItems(
                [{ ...dataProduct }],
                '',
                '',
                '',
                dataProduct.indexGA4,
                discountWithPriceObject || discountWithoutPriceObject
              )
            }
          };

          //GA4
          new GoogleAnalytic().gAnalytic4('event', 'add_to_wishlist', params);
        }
      } else {
        dispatch(setProductWishlistCurrent(wishListModel));
        const queryString = Global.getQueryParams(window.location.href);
        
        window.location.href = `${settingGlobal.LoginLink || '/login'}?from=${window.location.href}${Object.values(queryString).length ? '&' : '?'}isAddWishList=true`;
      }
    }
  };

  return isSiteNameValid ? (
    !productWishlistReducer.isGetWishListLoading ? (
      <Fragment>
        <div
          className={toogleWistList.isActive ? 'heart item-tooltip active' : 'heart item-tooltip'}
          onClick={onToggleWishListHandler}
        >
          <span className='heart__icon'>
            <span></span>
          </span>
          <div className='item-tooltip__label'>{toogleWistList.isActive ? messageObj?.['LBL-RemoveToWishlist'] :  messageObj?.['LBL-AddToWishlist']}</div>
        </div>
        {productWishlistReducer.isLoading && dataProduct.ProductId === productWishlistReducer.productIdCurrent ? <Loading classNameCustom='add-to-wish-list'/> : ''}
      </Fragment>
    ) : (
      <div className='heart loading-wish-list' title='Loading'>
        <span className='heart__icon'>
          <span></span>
        </span>
      </div>
    )
  ) : (
    <></>
  );
};

ToggleWisList.defaultProps = {
  quantityDefault: 1
};

ToggleWisList.propTypes = {
  toogleWistList: PropTypes.any,
  dataProduct: PropTypes.object,
  quantity: PropTypes.any,
  quantityDefault: PropTypes.number
};

export default ToggleWisList;
