import PropTypes from 'prop-types';
import React from 'react';

import EventCheckbox from '../EventCheckbox';

const ItemCheckbox = ({
  item,
  values,
  layoutData,
  handleUpdate,
  setFieldValue,
  changeDataArrData,
  handleRemove,
  arrCheckbox,
  isCheckFieldDefault,
  label = ''
}) => {
  return (
    <>
      <div className='form-group-left'>
        <div className='osim-checkbox'>
          <input
            id='email'
            className={'osim-checkbox-input'}
            name='email'
            type='checkbox'
            checked={item.checked}
            onClick={(evt) => {
              evt.preventDefault();

              setFieldValue('arrData', changeDataArrData(item.id, values));
            }}
            readOnly
          />
          <label
            className='osim-checkbox-label'
            onClick={(evt) => {
              evt.preventDefault();

              setFieldValue('arrData', changeDataArrData(item.id, values));
            }}
          >
            {`${label || item.category}(${item.value})`}
          </label>
        </div>
      </div>
      <EventCheckbox
        layoutData={layoutData}
        arrCheckbox={arrCheckbox}
        item={item}
        handleUpdate={handleUpdate}
        handleRemove={handleRemove}
        isCheckFieldDefault={isCheckFieldDefault}
      />
    </>
  );
};

ItemCheckbox.propTypes = {
  item: PropTypes.string,
  values: PropTypes.string,
  layoutData: PropTypes.string,
  handleUpdate: PropTypes.func,
  setFieldValue: PropTypes.func,
  changeDataArrData: PropTypes.func,
  handleRemove: PropTypes.func,
  arrCheckbox: PropTypes.array,
  isCheckFieldDefault: PropTypes.any,
  label: PropTypes.string
};

export default ItemCheckbox;
