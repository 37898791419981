import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';
const VIEW_DETAIL = 'VIEW_DETAIL';
const ARROW = 'ARROW';
const TEXT_URL_REPLACE = 'store-locator';

const StoreLocatorListItem = ({ data, dataFromLayout, myCurrentLocation }) => {
  const [altOpenHour, setAltOpenHour] = useState(null);
  const [site] = useState(SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OTW');
  const isGA4 = Global.getIsGA4();

  const onDirectHandler = (e) => {
    e.stopPropagation();

    handleGA4RoadshowDetails(data.Name, ARROW);

    if (!SitecoreContextFactoryService.getValueContextRouteItem('fields').IsChina.value) {
      window.open(
        `https://www.google.com/maps/dir/${myCurrentLocation.latitude},${myCurrentLocation.longitude}/${data.Latitude},${data.Longitude}`,
        '_blank'
      );
    } else {
      window.open(
        `http://api.map.baidu.com/direction?origin=latlng:${myCurrentLocation.latitude},${myCurrentLocation.longitude}|name=origin&destination=latlng:${data.Latitude},${data.Longitude}|name=destination&mode=driving&region=none&output=html`,
        // `http://api.map.baidu.com/direction?origin=latlng:39.940488,116.355425|name:origin&destination=latlng:${data.Latitude},${data.Longitude}|name:destination&mode=driving&region=none&output=html`,
        '_blank'
      );
    }
  };

  useEffect(() => {
    if (data.StoreType === 'Store Detail') {
      let date = new Date();
      let tempHour = deepCopy(data.OpeningHours?.split('&'));
      let tempTodayLabel = deepCopy(dataFromLayout['Today Label']);
      let daysArr = [];

      tempHour = tempHour.map((item) => {
        return decodeURIComponent(item).split('=');
      });

      tempHour.forEach((item) => {
        return daysArr.push(item[0].trim());
      });

      let currDay = daysArr[date.getDay() - 1];

      tempHour.forEach((element) => {
        if (element[0].trim() === currDay) {
          tempTodayLabel.value = `${tempTodayLabel.value}: ${element[1]}`;

          setAltOpenHour(deepCopy(tempTodayLabel));
        }
      });
    }
    if (data.StoreType === 'Road Show Page') {
      // let tempHour = deepCopy(data.OpeningHours?.split('&'));
      // let daysArr = [];

      // tempHour = tempHour.map((item) => {
      //   return decodeURIComponent(item).split(' ');
      // });

      // tempHour.forEach((item) => {
      //   return daysArr.push(item[0].trim());
      // });

      // tempHour.forEach((element) => {
      //   return (element[1] = element[1].substr(0, element[1].indexOf('=')));
      // });

      // setAltOpenHour(`${tempHour[0][0]} ${tempHour[0][1]} ${new Date(Date.now()).getFullYear()} - ${tempHour[1][0]} ${tempHour[1][1]} ${new Date(Date.now()).getFullYear()}`);
      setAltOpenHour('');
    }
  }, []);

  //New Logic ON/OFF GA, GA4
  const handleGA4RoadshowDetails = (data, type = VIEW_DETAIL) => {
    if (isGA4) {
      let params = {
        selected_category: window.location.pathname.replace(/[/]/g, '').replace(TEXT_URL_REPLACE, '') || 'all',
        selected_option: type === VIEW_DETAIL ? 'view detail' : 'arrow', // view detail/ arrow
        selected_value: data // Roadshow/ store name
      };

      new GoogleAnalytic().gAnalytic4('event', 'roadshow_details', params);
    }
  };

  return (
    altOpenHour !== null && (
      <Fragment>
        <div className='store-item__info'>
          {data?.StoreType === 'Store Detail' ? (
            <div className='store-item__info__type'>
              <div className='store-item__info__type__icon package-icon package-icon--pickup'></div>
              <Text className='store-item__info__type__name' field={dataFromLayout['Stores Label']} tag='h5' editable={true} />
            </div>
          ) : (
            <></>
          )}
          {data?.StoreType === 'Road Show Page' ? (
            <div className='store-item__info__type'>
              <div className='store-item__info__type__icon store-item__info__type__icon--roadshow'></div>
              <Text className='store-item__info__type__name' field={dataFromLayout['Roadshows Label']} tag='h5' editable={true} />
            </div>
          ) : (
            <></>
          )}
          <h4 className='store-item__info__name'>{data.Name}</h4>
          {data.StoreType === 'Store Detail' ? (
            <Text className='store-item__info__time' tag='p' field={altOpenHour} />
          ) : (
            <p className='store-item__info__time'>{data.Description}</p>
          )}
          <div className='store-item__info__address'>
            <span className='store-item__info__address__text'>{data.FullAddress}</span>
            <span className='store-item__info__address__icon' onClick={(e) => onDirectHandler(e)}>
              <span className='store-locator-icon store-locator-icon--direction'></span>
            </span>
          </div>
          {site ? (
            <></>
          ) : (
            <div>
              <span className='store-item__info__phone'>{data.Postcode}</span>
            </div>
          )}
          <div>
            <a className='store-item__info__phone' href={`tel:${data.Phone}`}>
              {data.Phone}
            </a>
          </div>
        </div>
        <div className='store-item__btn'>
          <a
            className='store-item__btn__item store-item__btn__item--visit-link btn-link'
            href={`${data.DetailPageURL}?storeId=${data.ItemId}`}
            onClick={(e) => {e.stopPropagation(); 
            
              handleGA4RoadshowDetails(data.Name);
            }}
          >
            <Text field={dataFromLayout['Visit Store Button Label']} tag='span' editable={true} />
          </a>
          {/* <a className='store-item__btn__item store-item__btn__item--book btn btn-outline-primary' href='/'>
          <Text field={dataFields['Book Experience Button Label']} tag='span' editable={true} />
        </a> */}
        </div>
      </Fragment>
    )
  );
};

StoreLocatorListItem.propTypes = {
  data: PropTypes.any,
  dataFromLayout: PropTypes.any,
  myCurrentLocation: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    myCurrentLocation: state.storeLocatorReducer.allStoreReducer.myCurrentLocation || {}
  };
};

export default connect(mapStateToProps)(StoreLocatorListItem);
