import { useCallback } from 'react';

export const useGenerateProductNames = () => {
  return useCallback((productNames, packageCountText) => {
    let productNameValue = '';
    if (productNames?.length) {
      productNameValue += productNames[0];
      if (productNames.length > 1) {
        productNameValue += ` ${packageCountText?.replace('{otherCount}', productNames?.length - 1)}` || '';
      }
    }

    return productNameValue;
  }, []);
};
