import * as couponWalletTypes from '@redux/actions/couponWallet/couponWalletTypes';

export const getCouponWalletList = (payload) => ({
  type: couponWalletTypes.GET_COUPON_WALLET_LIST,
  payload
});

export const getCouponWalletListSuccess = (payload) => ({
  type: couponWalletTypes.GET_COUPON_WALLET_LIST_SUCCESS,
  payload
});

export const getCouponWalletListFailed = () => ({
  type: couponWalletTypes.GET_COUPON_WALLET_LIST_FAILED
});
