import './MyPrizesPopup.scss';

import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';

import SpinCouponItem from '@components/SpinAndWin/SpinCouponItem';

import SpinWinContext from '../SpinAndWinContext';

const MyPrizesPopup = ({ applyOfferButtonText, myPrizesData, onClosePopup, layoutData }) => {
  const {handleApplyCoupon} = useContext(SpinWinContext);
  const [selectedCoupon, setSelectedCoupon] = useState(null);

  return (
    <div className='myprizes-popup'>
      <div className='myprizes-popup__list'>
        {myPrizesData.map((data, index) => (
          <SpinCouponItem
            selectedCoupon={selectedCoupon}
            setSelectedCoupon={setSelectedCoupon}
            key={data.Code || index}
            coupon={data}
            applyOfferButtonText={applyOfferButtonText}
            couponId={data}
            layoutData={layoutData}
          />
        ))}
      </div>
      <div className='myprizes-popup__btn text-center'>
        <button className='btn btn-primary' onClick={() => {
          handleApplyCoupon(selectedCoupon);

          onClosePopup(false);
        }}>
          {applyOfferButtonText}
        </button>
      </div>
    </div>
  );
};

MyPrizesPopup.propTypes = {
  applyOfferButtonText: PropTypes.string,
  myPrizesData: PropTypes.any,
  onClosePopup: PropTypes.any,
  layoutData: PropTypes.any
};

export default MyPrizesPopup;
