import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreImageModel from '@models/SitecoreImageModel';

export default class FullWidthAutoLoopVideoFieldsModel {
  constructor() {

    this['Background Video'] = {value: {href: ''}};

    this['Cover Image'] = new SitecoreImageModel();

    this.Description = new SitecoreTextModel();

    this.Title = new SitecoreTextModel();

    this.Video = {value: {href: ''}};

    this['Vimeo video ID'] = new SitecoreTextModel();

    this['Youtube video ID'] = new SitecoreTextModel();

  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Background Video')) {
      this['Background Video'] = dataSource['Background Video'];
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Cover Image')) {
      this['Cover Image'].getData(dataSource['Cover Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description.getData(dataSource.Description);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title.getData(dataSource.Title);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Video')) {
      this.Video = dataSource.Video;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Vimeo video ID')) {
      this['Vimeo video ID'].getData(dataSource['Vimeo video ID']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Youtube video ID')) {
      this['Youtube video ID'].getData(dataSource['Youtube video ID']);
    }

    return this;
  }
}
