import { READ_SINGLE_NOTIFICATION_FAILURE, READ_SINGLE_NOTIFICATION_START, READ_SINGLE_NOTIFICATION_SUCCESS } from './types';

export const readSingleNotificationStart = (payload) => ({
  type: READ_SINGLE_NOTIFICATION_START,
  payload
});

export const readSingleNotificationSuccess = (payload) => ({
  type: READ_SINGLE_NOTIFICATION_SUCCESS,
  payload
});

export const readSingleNotificationFailure = (payload) => ({
  type: READ_SINGLE_NOTIFICATION_FAILURE,
  payload
});
