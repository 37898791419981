import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import Loading from '@components/Share/Loading';
import { getSOCarts } from '@redux/actions/getSOCarts';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { DEFAULT_PAGE_SIZE, useCustomerSaleOrders } from '../index';

const LoadMoreBtn = () => {
  const { layoutData, currentPage, setCurrentPage, getSOCartParams, totalPage, isLoading } = useCustomerSaleOrders();
  const dispatch = useDispatch();

  const handleLoadMoreData = useCallback(() => {
    const nextPage = currentPage + 1;
    if (nextPage <= totalPage) {
      const params = {
        ...getSOCartParams,
        skip: (nextPage - 1) * DEFAULT_PAGE_SIZE
      };

      dispatch(getSOCarts(params));

      // INFO: set state for currentPage
      setCurrentPage(nextPage);
    }
  }, [currentPage, getSOCartParams, totalPage]);

  const hasNextPage = useMemo(() => {
    return currentPage < totalPage;
  }, [currentPage, totalPage]);

  return !isLoading ? (
    hasNextPage ? (
      <button type='button' className='btn btn-outline-primary' disabled={!hasNextPage || isLoading} onClick={handleLoadMoreData}>
        <Text field={layoutData?.['Load More Button Label']} />
      </button>
    ) : (
      <></>
    )
  ) : (
    <Loading />
  );
};

export default LoadMoreBtn;
