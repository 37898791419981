import PropTypes from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const CartWarrantyItem = ({dataSources, warrantyInfo, freeGiftPomotion}) => {
  return (
    // <li className='cart-products__item'>
    //   <div className='cart-products__item__warranty justify-space-between'>
    //     <div className='cart-products__item__warranty__contents'>
    //       <img src={require('../../../../../../assets/images/warranty-icon.png')} alt='warranty-icon' />
    //       <span>{warrantyInfo.DisplayName} {+warrantyInfo.Quantity > 1 ? `x ${warrantyInfo.Quantity}` : ''}</span>
    //     </div>
    //     <div className='cart-products__item__warranty__price'>{warrantyInfo.LineTotal}</div>
    //   </div>
    // </li>
    <div className='cart-products__item__warranty justify-space-between'>
      <div className='cart-products__item__warranty__contents'>
        <div className='product-info-icon product-info-icon--warranty'></div>
        <span className='option-name'>{warrantyInfo.DisplayName} {+freeGiftPomotion > 1 ? `x ${freeGiftPomotion}` : ''}</span>
      </div>
      <div className='cart-products__item__warranty__price'>{warrantyInfo.LineFinalTotalAmount !== 0 ? warrantyInfo.LineTotal 
        : <Text field={dataSources['Free Text']}/>}</div>
    </div>
  );
};

CartWarrantyItem.propTypes = {
  warrantyInfo: PropTypes.object,
  freeGiftPomotion: PropTypes.any,
  dataSources: PropTypes.object
};

export default CartWarrantyItem;
