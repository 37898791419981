import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class PasswordManagementWidgetModel {
  constructor() {
    this['Title'] = new SitecoreTextModel();

    this['Update Link'] = new SitecoreLinkModel();

    this['Update Text'] = new SitecoreTextModel();

    this['Current Password Label'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Update Link')) {
      this['Update Link'].getData(dataSource['Update Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Current Password Label')) {
      this['Current Password Label'].getData(dataSource['Current Password Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Update Text')) {
      this['Update Text'].getData(dataSource['Update Text']);
    }

    return this;
  }
}
