import IncludedProductVariantModel from '@components/BundleElements/model/IncludedProductVariantModel';
import PriceModel from '@components/BundleElements/model/PriceModel';
import LabelsModel from '@models/LabelsModel';
import { deepCopy } from '@utils/utility';

import RefunableDepositProductModel from '../VariantDetail/ProductDetailOverview/models/RefunableDepositProductModel';
import ProductCardStockModel from './ProductCardStockModel';

const stringArr = [
  'CatalogName',
  'Description',
  'Image',
  'ProductId',
  'ProductName',
  'ProductOverview',
  'RepresentativeVariantId',
  'Url',
  'LastFewUnitsLeftLabel',
  'PriceExtraInformation',
  'CommerceProductId',
  'CategoryPageId',
  'BundleDealsButtonUrl'
];

export default class ProductCardInterfaceModel {
  constructor() {
    stringArr.forEach((item) => {
      this[item] = '';
    });

    this.IsBundle = false;

    this.IsBookExperience= false;

    this.ExternalWishListLineId = '';

    this.CategoryName = '';

    this['Id'] = '';

    this['Labels'] = [];

    this['Price'] = new PriceModel();

    this['Stock'] = new ProductCardStockModel();

    this['Variants'] = [];

    this['IsRentalProduct'] = false;

    this['RefundableDepositProducts'] = [];

    this['ProductCountdownTimer'] = {};

    this['ItemListId'] = '';

    this['ItemListName'] = '';

    this['item_list_id'] = '';

    this.Deposit = {};

    this.IsDeposit = false;
  }
  getData(dataSource) {
    stringArr.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this['Id'] = dataSource['Id'] ?? this['Id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels') && dataSource['Labels']) {
      let newLabelList = [];

      dataSource['Labels'].map((element) => {
        newLabelList.push(new LabelsModel().getData(deepCopy(element) || {}));
      });

      this['Labels'] = newLabelList;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock')) {
      this.Stock.getData(dataSource.Stock || {});
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsBundle')) {
      this.IsBundle = dataSource.IsBundle;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsBookExperience')) {
      this.IsBookExperience = dataSource.IsBookExperience;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'CategoryName')) {
      this.CategoryName = dataSource.CategoryName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Variants') && dataSource['Variants']) {
      let newVariantsList = [];

      dataSource['Variants'].map((element) => {
        newVariantsList.push(new IncludedProductVariantModel().getData(deepCopy(element) || {}));
      });

      this['Variants'] = newVariantsList;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsRentalProduct')) {
      this['IsRentalProduct'] = dataSource['IsRentalProduct'] ?? this['IsRentalProduct'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'RefundableDepositProducts')) {
      if (dataSource['RefundableDepositProducts']?.length) {
        dataSource['RefundableDepositProducts'].map((item) => {
          this['RefundableDepositProducts'].push(new ProductCardInterfaceModel().getData(item || {}));
        });
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductCountdownTimer')) {
      if (dataSource['ProductCountdownTimer']) {
        this['ProductCountdownTimer'] = deepCopy(dataSource['ProductCountdownTimer']);
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemListId')) {
      this['ItemListId'] = dataSource['ItemListId'] ?? this['ItemListId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ItemListName')) {
      this['ItemListName'] = dataSource['ItemListName'] ?? this['ItemListName'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'item_list_id')) {
      this['item_list_id'] = dataSource['item_list_id'] ?? this['item_list_id'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Deposit')) {
      this['Deposit'] = dataSource['Deposit'] ?? this['Deposit'];

      // INFO: new update from ticket https://jira.smartosc.com/browse/OSIM-6543 => to check deposit product using
      // Deposit !== null
      this['IsDeposit'] = dataSource['Deposit'] !== null;

    }
    // if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsDeposit')) {
    //   this['IsDeposit'] = dataSource['IsDeposit'] ?? this['IsDeposit'];
    // }

    return this;
  }
}
