import React from 'react';

const ContactInfor = () => {
  return <div className='contact-infor box-data'>
    <div className='row'>
      <div className='col-md-4'>
        <div className='flex-content'>
          <div className='icon-place'>
            <span className='cp-icon cp-icon-email'></span>
          </div>
          <div className='data-place'>
            <span className='data-text'>John****e@gmail.com</span>
          </div>
        </div>
        <div className='flex-content'>
          <div className='icon-place'>
            <span className='cp-icon cp-icon-email'></span>
          </div>
          <div className='data-place'>
            <span className='data-text'>John****e@gmail.com e@gmail.com</span>
          </div>
        </div>
        
      </div>
      <div className='col-md-4'>
        <div className='flex-content'>
          <div className='icon-place'>
            <span className='cp-icon cp-icon-email'></span>
          </div>
          <div className='data-place'>
            <span className='data-text'>John****e@gmail.com</span>
          </div>
        </div>
      </div>
      <div className='col-md-4'>
        <div className='flex-content'>
          <div className='icon-place'>
            <span className='cp-icon cp-icon-email'></span>
          </div>
          <div className='data-place'>
            <span className='data-text'>John****e@gmail.com</span>
          </div>
        </div>
      </div>
    </div>
  </div>;
};

export default ContactInfor;
