import './hamburger.scss';

import PropTypes from 'prop-types';
import React from 'react';

const Hamburger = (props) => {
  const { isActive, onClickFnc } = props;

  return (
    <div className={`${isActive ? 'hamburger active' : 'hamburger'}`} onClick={onClickFnc}>
      <div className='hamburger-icon'>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

Hamburger.propTypes = {
  isActive: PropTypes.any,
  onClickFnc: PropTypes.any
};

export default Hamburger;
