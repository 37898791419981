import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class MyWishlistWidgetModel {
  constructor() {
    this['Product Summary Label'] = new SitecoreTextModel();

    this['You Have 0 Products In Your Wishlist Label'] = new SitecoreTextModel();

    this['View All Text'] = new SitecoreTextModel();

    this['Explore Osim Products Text'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['View All Link'] = new SitecoreLinkModel();

    this['Explore Osim Products Link'] = new SitecoreLinkModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'You Have 0 Products In Your Wishlist Label')) {
      this['You Have 0 Products In Your Wishlist Label'].getData(dataSource['You Have 0 Products In Your Wishlist Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Product Summary Label')) {
      this['Product Summary Label'].getData(dataSource['Product Summary Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View All Text')) {
      this['View All Text'].getData(dataSource['View All Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'View All Link')) {
      this['View All Link'].getData(dataSource['View All Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Explore Osim Products Text')) {
      this['Explore Osim Products Text'].getData(dataSource['Explore Osim Products Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Explore Osim Products Link')) {
      this['Explore Osim Products Link'].getData(dataSource['Explore Osim Products Link']);
    }

    return this;
  }
}
