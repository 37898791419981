import PropTypes from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

const StoreLocatorFilterModal = ({ 
  dataFields,
  options,
  valueSelected,
  onAreaChange,
  onProvinceChange,
  onCityChange,
  onApplyFilterHandler,
  onClearFilterHandler }) => {
  const objMessages = useSelector(state => state.getMessageReducer?.objMessages);

  return (
    <div className='store-locator__modal'>
      <div className='store-locator__modal__wrap'>
        <div className='store-locator__modal__item'>
          <Select
            value={valueSelected.AddressArea}
            options={options.area}
            placeholder={dataFields['Area Selection Label'].value}
            onChange={onAreaChange}
            className='customization-dropdown'
            classNamePrefix='customization-dropdown'
            isClearable={true}
            noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
          />
        </div>
        <div className='store-locator__modal__item'>
          <Select
            value={valueSelected.AddressState}
            options={options.province}
            placeholder={dataFields['Province Selection Label'].value}
            onChange={onProvinceChange}
            className='customization-dropdown'
            classNamePrefix='customization-dropdown'
            isClearable={true}
            noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
          />
        </div>
        <div className='store-locator__modal__item'>
          <Select 
            value={valueSelected.AddressCity}
            options={options.city}
            placeholder={dataFields['City Selection Label'].value}
            onChange={onCityChange}
            className='customization-dropdown'
            classNamePrefix='customization-dropdown'
            isClearable={true}
            noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
          />
        </div>
      </div>
      <div className='button-container'>
        <div className='btn btn-primary' onClick={onApplyFilterHandler}>{dataFields['Apply Filters Button Label'].value}</div>
        <span className='btn-clear-filter' onClick={onClearFilterHandler}>{dataFields['Clear Filters Button Label'].value}</span>
      </div>
    </div>
  );
};

StoreLocatorFilterModal.propTypes = {
  dataFields: PropTypes.any,
  options: PropTypes.any,
  valueSelected: PropTypes.any,
  onAreaChange: PropTypes.any,
  onProvinceChange: PropTypes.any,
  onCityChange: PropTypes.any,
  onApplyFilterHandler: PropTypes.func,
  onClearFilterHandler: PropTypes.func,
};

export default StoreLocatorFilterModal;
