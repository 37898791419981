import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class MainNavigationSubMenuModel {
  constructor() {
    this['Commerce Category Source'] = {url:''};

    this['Menu Image'] = new SitecoreImageModel();

    this['Thumbnail'] = new SitecoreImageModel();

    this.Title = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Commerce Category Source')) {
      this['Commerce Category Source'] = dataSource['Commerce Category Source'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Menu Image')) {
      this['Menu Image'] = dataSource['Menu Image'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Thumbnail')) {
      this['Thumbnail'] = dataSource['Thumbnail'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this.Title = dataSource.Title;
    }

    return this;
  }
}
