import { any, bool } from 'prop-types';
import React from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const ProductInterestItemQuestionArea = ({ isPurchaseOnSpot, questionData, purchaseOnTheSpotLabel }) => {
  return (
    <>
      <div className='product-interest__item__question__check__box'>
        <div className='form-group cp-form__checkbox w-60'>
          <div className='osim-checkbox'>
            <>
              <input type='checkbox' id='cp-checkbox-1' disabled className='osim-checkbox-input' defaultChecked={isPurchaseOnSpot} />
              <label className='osim-checkbox-label' htmlFor='cp-checkbox-1'>
                <Text field={purchaseOnTheSpotLabel} />
              </label>
            </>
          </div>
        </div>
      </div>
      <div className='product-interest__item__question__content'>
        {questionData.length > 0 &&
          questionData.map((val, index) => (
            <div key={index} className='product-interest__item__question__content__item'>
              <p className='question'>{val.Question} <span>{val.IsRequired && '*'}</span></p>
              <span className='answer'>{val.Answer}</span>
            </div>
          ))}
      </div>
    </>
  );
};

ProductInterestItemQuestionArea.propTypes = {
  isPurchaseOnSpot: bool,
  questionData: any,
  purchaseOnTheSpotLabel: any
};

export default ProductInterestItemQuestionArea;
