import React from 'react';
import { Fragment } from 'react';

const MyOrdersItemShimmer = () => {

  return (
    <Fragment>
      <div className='my-account-section__content skeleton__item'>
        <div className='my-account-section__group'>
          <span className='text-line'></span>
          <span className='clear-8'></span>
          <span className='text-line'></span>
        </div>
        <span className='clear-8'></span>
        <span className='text-line-20'></span>
        <span className='clear-8'></span>
        <span className='text-line-26'></span>
        <span className='clear-8'></span>
        <span className='text-line-22'></span>
        <span className='clear-8'></span>
        <span className='text-line-22'></span>
        <span className='clear-25'></span>
      </div>
    </Fragment>
  );
};

export default MyOrdersItemShimmer;