import './FilterPopup.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as config from '@components/CategoryLanding/ProductListing/config';
import { PARAMS_SUBMIT } from '@components/CategoryLanding/ProductListing/config';
import SortOptionModel from '@components/CategoryLanding/ProductListing/model/SortOptionModel';
import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import { getFilterProductList, setRangePricePosition } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';

import FilterByFeature from './FilterByFeature';
import FilterByPrice from './FilterByPrice';
import FilterProductByColor from './FilterProductByColor';

const FilterPopup = (props) => {
  const { dataSources, dataFromAPI } = props;
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const [isToogleClearFilter, setToogleClearFilter] = useState(false);
  const filterLastest = useSelector((state) => state.productReducer.productListReducer.filterLastest);
  const rangePosition = useSelector((state) => state.productReducer.productListReducer.rangePosition);

  const isGA4 = Global.getIsGA4();

  const [facetSetting] = useState(dataSources['Search Settings']['Facet Fields']);

  const [filterObj, setFilterProduct] = useState({
    CategoryId: '',
    SortBy: dataSources['Search Settings']['Sort Options'][0],
    Price: dataFromAPI.Price,
    Features: [],
    Colors: [],
  });

  useEffect(() => {
    if (filterLastest) {
      setFilterProduct({
        ...filterLastest,
        SortBy: new SortOptionModel().getData(
          dataSources['Search Settings']['Sort Options'].find((item) => item.Value.value === filterLastest.SortBy)
        ),
        Colors: filterLastest.Colors,
        Features: filterLastest.Features,
        positionCurrent: rangePosition,
      });
    }
  }, []);

  const onSelectFilterHandler = (params) => {
    setOpen(false);

    if (params.key === PARAMS_SUBMIT.SortBy) {
      setFilterProduct({
        ...filterObj,
        [params.key]: params.value
      });
    }
    if (params.key === PARAMS_SUBMIT.RangePrice) {
      setFilterProduct({
        ...filterObj,
        Price: {
          min: params.value[0],
          max: params.value[1],
        }
      });
    }
    if (params.key === PARAMS_SUBMIT.Colors) {
      setFilterProduct({
        ...filterObj,
        [params.key]: params.value
      });
    }
    if (params.key === PARAMS_SUBMIT.Features) {
      setFilterProduct({
        ...filterObj,
        [params.key]: params.value
      });
    }
  };

  // INF: Google analytics
  const renderFacetSetting = (facetSetting) => {
    let category_select = [dataSources['Sort By Label'].value];

    facetSetting.forEach((item) => {
      category_select.push(item.Name.value);
    });

    return category_select;
  };

  // INF: Google analytics
  const filterColorNameByColor = (Colors) => {
    const dataColor = dataFromAPI.Facets.find((item) => item.Name === config.STEP_RANGE_COLOR);

    let arrColorName = [];

    Colors.forEach((item) => {
      const found = dataColor.Details.find(element => element.Value == item);

      arrColorName.push(found.Name);
    });

    return arrColorName;
  };

  //New Logic ON/OFF GA, GA4
  const filterByHandlerGA4 = () => {
    if (isGA4) {
      // window.dataLayer = window.dataLayer || [];

      const params = { event: 'filter_by' };

      const category_select = renderFacetSetting(facetSetting);

      const selected = Object.values({
        Term: filterObj.SortBy.Name.value,
        Price: [filterObj.Price.min, filterObj.Price.min + 1 === filterObj.Price.max ? filterObj.Price.min : filterObj.Price.max],
        Colors: filterObj.Colors.length ? filterColorNameByColor(filterObj.Colors) : '',
        Features: filterObj.Features.length ? filterObj.Features : ''
      });

      category_select.forEach((value, index) => {
        params[`selected_category${index + 1}`] = value;

        params[`selected_item${index + 1}`] = selected[index];
      });

      params['page_type'] = SitecoreContextFactoryService.getValueContextRouteItem('name');

      // window.dataLayer.push(params);

      new GoogleAnalytic().gAnalytic4('event', 'filter_by', params);
    }
  };

  const sorByHandlerGA4 = (value) => {
    if (isGA4) {
      let params = {
        sort_by_click: value,
        page_type: SitecoreContextFactoryService.getValueContextRouteItem('name')
      };

      new GoogleAnalytic().gAnalytic4('event', 'sort_by', params);
    }
  };

  const onApplyFilterHandler = () => {
    setToogleClearFilter(false);

    dispatch(setRangePricePosition(filterObj.positionCurrent));

    dispatch(
      getFilterProductList({
        CategoryId: SitecoreContextFactoryService.getValueContextItem('itemId'),
        Term: '',
        SortBy: filterObj.SortBy.Value.value,
        PriceFrom: filterObj.Price.min,
        PriceTo: filterObj.Price.min + 1 === filterObj.Price.max ? filterObj.Price.min : filterObj.Price.max,
        Features: filterObj.Features.length ? filterObj.Features : '',
        Colors: filterObj.Colors.length ? filterObj.Colors : '',
      })
    );

    filterByHandlerGA4();

    props.appplyFilterEvt();
  };

  const onClearFilterHandler = () => {
    setToogleClearFilter(!isToogleClearFilter);
    
    setFilterProduct({
      ...filterObj,
      SortBy: dataSources['Search Settings']['Sort Options'][0],
      Price: dataFromAPI.Price,
      Colors: [],
      Features: [],
      positionCurrent: {}
    });
  };

  const onChangePositionHandler = (position) => {
    setFilterProduct({
      ...filterObj,
      positionCurrent: position
    });
  };

  return (
    <div className='filter-popup'>
      {/* <div className='filter-popup__title'>
        <Text field={dataSources['Filter Title']} />
      </div> */}
      <div className='filter-popup__content'>
        <div className='filter-popup__section'>
          <h3 className='filter-popup__section__heading'>
            <Text field={dataSources['Sort By Label']} />
          </h3>
          {dataSources['Search Settings']['Sort Options'].length ?
            <div className='filter-popup__section__dropdown'>
              <div className='filter-popup__section__dropdown__selected' onMouseEnter={() => setOpen(true)} onMouseLeave={() => setOpen(false)}>
                <span className='sort-type-selected'>
                  <Text field={filterObj.SortBy.Name} />
                </span>
                <i className='icon-chevron-down-big'></i>
              </div>
              <ul
                className={`filter-popup__section__dropdown__list ${isOpen ? 'is-open' : ''}`}
                onMouseEnter={() => setOpen(true)}
                onMouseLeave={() => setOpen(false)}
              >
                {dataSources['Search Settings']['Sort Options'].map((itemType, itemTypeIdx) => (
                  <li
                    className={`filter-popup__section__dropdown__item ${itemType.Value.value === filterObj.SortBy.Value.value ? 'is-selected' : ''}`}
                    key={itemTypeIdx}
                    onClick={() => {
                      onSelectFilterHandler({ key: PARAMS_SUBMIT.SortBy, value: itemType });

                      sorByHandlerGA4(itemType.Name.value);
                    }}
                  >
                    <Text field={itemType.Name} />
                  </li>
                ))}
              </ul>
            </div> : <></>
          }
        </div>
        {facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Price) && isFinite(dataFromAPI.Price.max) && isFinite(dataFromAPI.Price.min) ?
          <FilterByPrice
            filterPrices={filterLastest?.Price}
            isToogleClearFilter={isToogleClearFilter}
            prices={dataFromAPI.Price}
            setSelectRangeEvt={onSelectFilterHandler}
            dataSources={facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Price)}
            setPositionCurrentEvt={onChangePositionHandler}
          /> : ''}
        {facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Color) ?
          <FilterProductByColor
            filterColorCurrent={filterLastest?.Colors}
            isToogleClearFilter={isToogleClearFilter}
            setSelectColorEvt={onSelectFilterHandler}
            colors={dataFromAPI.Facets.find((item) => item.Name === config.STEP_RANGE_COLOR)}
            dataSources={facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Color)}
          /> : ''}
        {facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Feature) ?
          <FilterByFeature
            filterFeatureCurrent={filterLastest?.Features}
            dataSources={facetSetting.find(facetItem => facetItem.Value.value === config.VALUE_FILTER.Feature)}
            features={dataFromAPI.Facets.find((item) => item.Name === config.STEP_RANGE_FEATURE)}
            setSelectFilterEvt={onSelectFilterHandler}
            isToogleClearFilter={isToogleClearFilter}
          /> : ''}
      </div>
      <div className='filter-popup__btn-wrap'>
        <div className='filter-popup__btn-wrap__item'>
          <span className='filter-popup__btn-wrap__item__apply btn btn-outline-CTA1' onClick={onApplyFilterHandler}>
            <Text field={dataSources['Apply Filter Text']} />
          </span>
        </div>
        <div className='filter-popup__btn-wrap__item__clear filter-popup__btn-wrap__item'>
          <span className='btn-link' onClick={onClearFilterHandler}>
            <Text field={dataSources['Clear Filter Text']} />
          </span>
        </div>
      </div>
    </div>
  );
};

FilterPopup.propTypes = {
  dataSources: Proptypes.object,
  dataFromAPI: Proptypes.any,
  appplyFilterEvt: Proptypes.func
};

export default FilterPopup;
