import PropTypes from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';

import { useCampaignContext } from '../../useCampaignContext';
import { useGetOtp } from '../useGetOtp';

const EmailExistNotiModal = ({ isCheckEmailExistBp, isLoginSuccess }) => {
  const { layoutData, userRegisterInfo, setIsOpenLoginModal } = useCampaignContext();
  const getOtpService = useGetOtp();
  const [isShow, setIsShow] = useState(false);

  const getOtpParams = useMemo(() => {
    return {
      campaignId: layoutData?.campaign?.Id,
      phoneNumber: userRegisterInfo.phoneNumber
    };
  }, [layoutData, userRegisterInfo]);

  useDidUpdateEffect(() => {
    if (isCheckEmailExistBp) {
      setIsShow(true);
    } else {
      getOtpService(getOtpParams);
    }
  }, [isCheckEmailExistBp]);

  useDidUpdateEffect(() => {
    if (isLoginSuccess) setIsShow(false);
  }, [isLoginSuccess]);

  const handleCloseModal = useCallback(() => {
    getOtpService(getOtpParams);

    setIsShow(false);
  }, [layoutData, userRegisterInfo.phoneNumber]);
  const handleOpenLoginModal = () => setIsOpenLoginModal(true);

  return (
    <ModalComponent isShow={isShow} onCloseHandler={handleCloseModal} titlePopup={<Text field={layoutData?.emailAlreadyExistsLabel} tag='span' />}>
      <div className='popup-email-already'>
        <p
          className='popup-email-already__description text-center'
          dangerouslySetInnerHTML={{ __html: layoutData?.emailAlreadyExistsMessage?.value }}
        />
        <div className='popup-email-already__action'>
          <Text
            onClick={handleOpenLoginModal}
            className='popup-email-already__action__btn popup-email-already__action__btn--login btn btn-outline-CTA1'
            field={layoutData?.loginButtonText}
            tag='button'
          />
          <button className='btn btn-link popup-email-already__action__btn popup-email-already__action__btn--link' onClick={handleCloseModal}>
            <Text field={layoutData?.continueGuestCheckoutButtonText} />
          </button>
        </div>
      </div>
    </ModalComponent>
  );
};

EmailExistNotiModal.propTypes = {
  isCheckEmailExistBp: PropTypes.bool,
  isLoginSuccess: PropTypes.bool
};

const mapStateToProp = (state) => ({
  isCheckEmailExistBp: state.checkoutProcesstReducer.accountCheckingReducer?.isCheckEmailExistBp,
  isLoginSuccess: state.singleSignOnReducer.userInfoReducer?.isLogin
});

export default connect(mapStateToProp)(EmailExistNotiModal);
