import './AnimatedBanner.scss';

import { any } from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { LIST_CLASS_NAME__MOBILE } from '@utils/constant';

import { deepCopy } from '../../utils/utility';
import AnimatedBannerWithImages from './AnimatedBannerWithImages';
import AnimatedBannerWithImagesItem from './AnimatedBannerWithImages/AnimatedBannerWithImagesItem';
import AnimatedBannerWithVideo from './AnimatedBannerWithVideo';
import AnimatedBannerModel from './models/AnimatedBannerModel';

const AnimatedBanner = ({ fields }) => {
  const [layoutData, setLayoutData] = useState(null);

  useEffect(() => {
    const model = new AnimatedBannerModel();

    setLayoutData(model.getData(deepCopy(fields)));
  }, []);

  const positionClassRender = (data) => {
    return LIST_CLASS_NAME__MOBILE[data] || '';
  };

  return (
    layoutData && (
      <div className='animated-banner__container'>
        <div className='animated-banner__background'>
          {layoutData['Animated Background Video']?.value?.href ? (
            <AnimatedBannerWithVideo fields={layoutData['Animated Background Video']} isMute={layoutData['Enable Audio'].value} />
          ) : layoutData['Animated Background Banner']?.length ? (
            <div className='animated-banner__background-slider'>
              <AnimatedBannerWithImages fields={layoutData['Animated Background Banner']}>
                <AnimatedBannerWithImagesItem />
              </AnimatedBannerWithImages>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={
            'animated-banner__content ' +
            // positionClassRender(layoutData['Text Position'].value)
            'content-bottom'
          }
        >
          <div className='animated-banner__content-container'>
            {layoutData['Icon'].value.src && (
              <div className='animated-banner__content-icon'>
                <Image media={layoutData['Icon'].value} />
              </div>
            )}
            <div className='animated-banner__content-text'>
              <Text
                field={layoutData['Title Text']}
                className='experience-title animated-banner__content-text__title'
                tag='h2'
                style={{ color: '#' + layoutData['Title Color Code'].value }}
              />
              <Text field={layoutData['Text Overlay']} className='animated-banner__content-text__desc' tag='p' />
            </div>
            <div className='animated-banner__content-cta__btn'>
              <Link field={layoutData['1st CTA Button Anchor']} className='animated-banner__content-cta__btn__item btn btn-primary'>
                <Text field={layoutData['1st CTA Button Text']} />
              </Link>
              <Link field={layoutData['2nd CTA Button Anchor']} className='animated-banner__content-cta__btn__item btn btn-primary light-text'>
                <Text field={layoutData['2nd CTA Button Text']} />
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

AnimatedBanner.propTypes = {
  fields: any
};

export default AnimatedBanner;