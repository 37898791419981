import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Image,Text } from '@sitecore-jss/sitecore-jss-react';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import MainNavigationSubMenuModel from '../../../Model/MainNavigationSubMenuModel';

const VerticalTabItem = (props) => {
  const { contentData, contentLabel, creativeSlot } = props;
  const [data, setData] = useState(null);
  const name = SitecoreContextFactoryService.getValueContextRouteItem('name');
  const isSiteNameValid = SitecoreContextFactoryService.getValueContextItem('site')?.name !== 'OCN';
  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const navigationSubMenuModel = new MainNavigationSubMenuModel();

    setData(navigationSubMenuModel.getData(deepCopy(contentData.fields || {})));
  }, []);

  //New Logic ON/OFF GA, GA4
  const handleGA4Event = () => {
    if (isGA4) {
      Global.handleGA4Navigation({
        labelItem: data.Title.value,
        pageTitle: name,
        labelCategory: contentLabel,
        pageUrl: contentData.url || ''
      });

      if (isSiteNameValid && props.type === 'promotion') {
        let dataConvert = [];

        dataConvert.push(contentData);

        const params = {
          ecommerce: {
            items: Global.getItemsPromotion(dataConvert, creativeSlot)
          }
        };

        new GoogleAnalytic().gAnalytic4('event', 'select_promotion', params);
      }
    }
  };

  return (
    data && (
      <li className='header__sub-menu-nav__item'>
        {
          <a onClick={handleGA4Event} href={contentData.url || ''} className='main-navigation__item__link'>
            {
              props.type !== 'promotion' ? (
                <div className='header__sub-menu-nav__item__image'>
                  <Image field={data['Menu Image']} />
                </div>
              ) : (
                <div className='header__sub-menu-nav__item__image'>
                  <Image field={data['Thumbnail']} />
                </div>
              )
            }
            <Text field={data.Title} tag='h3' className='header__sub-menu-nav__item__title'/>
          </a>
        }
      </li>
    )
  );
};

VerticalTabItem.propTypes = {
  contentData: PropTypes.any,
  contentLabel: PropTypes.string,
  type: PropTypes.any,
  creativeSlot: PropTypes?.number
};

VerticalTabItem.defaultProps = {
  contentLabel: '',
};

export default VerticalTabItem;
