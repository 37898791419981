import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import FooterNavigationLinkModel from '../../model/FooterNavigationLinkModel';

const FooterNavigationList = (props) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const footerNavigationListModel = new FooterNavigationLinkModel();

    setData(footerNavigationListModel.getData(deepCopy(props.data.fields)));
  }, []);

  return (
    <Fragment>
      {data &&
        (data.Link.value.href === '/' || data.Link.value.href === '' ? (
          <li className='footer-navigation__options__item'>
            <Text field={data.Title} tag='span' />
          </li>
        ) : (
          <li className='footer-navigation__options__item'>
            {props.isLogin ? (
              <Link field={data.Link} className='footer-navigation__options__link'>
                <Text field={data.Title} tag='span' className='' />
              </Link>
            ) : (
              <></>
            )}
            {!props.isLogin && data['Is Authenticated'].value ? (
              <Link className='footer-navigation__options__link' field={props.loginLink}>
                <Text field={data.Title} tag='span' className='' />
              </Link>
            ) : (
              <></>
            )}
            {!props.isLogin && !data['Is Authenticated'].value ? (
              <Link field={data.Link} className='footer-navigation__options__link'>
                <Text field={data.Title} tag='span' className='' />
              </Link>
            ) : (
              <></>
            )}
          </li>
        ))}
    </Fragment>
  );
};

FooterNavigationList.propTypes = {
  data: PropTypes.object,
  isLogin: PropTypes.bool,
  loginLink: PropTypes.object
};

const mapStateToProps = (state) => ({
  loginLink: state.unsubscribeReducer.loginLink || {},
  isLogin: state.singleSignOnReducer?.userInfoReducer?.isLogin || false
});

export default connect(mapStateToProps)(FooterNavigationList);
