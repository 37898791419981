import PropTypes from 'prop-types';
import React from 'react';

const AppliedCouponItem = (props) => {
  const onApplyCouponHandler = () => {
    props.onApplyCouponHandler({ data: props.data, actionType: 'remove' });
  };

  return (
    <li className='cart-promotion-codes__list-cart-tags__group__item'>
      <span>{props.data.Code}</span>
      <div className='flex-between x-icon' onClick={() => onApplyCouponHandler()}>
        <span></span>
      </div>
    </li>
  );
};

AppliedCouponItem.propTypes = {
  onApplyCouponHandler: PropTypes.func,
  data: PropTypes.object
};

export default AppliedCouponItem;
