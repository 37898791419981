import PropTypes from 'prop-types';
import React, { Fragment, useEffect, useState } from 'react';

import SearchBoxInput from '@components/Share/Header/SearchBox/SearchBoxInput';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import * as configs from './configs';
import { searchBoxOffset$ } from './handleSearchBoxService';
import SearchBoxModel from './model/SearchBoxModel';

const SearchBox = (props) => {

  const [dataFields, setDataFields] = useState(null);

  const [isShowSearchBar, setShowSearchBar] = useState(false);

  //Model Validation
  useEffect(() => {
    const searchBoxModel = new SearchBoxModel();

    setDataFields(searchBoxModel.getData(deepCopy(props.fields) || {}));
  }, []);

  const onToggleSearchBar = (value) => {
    
    setShowSearchBar(value);

    value && searchBoxOffset$.next('check:ShowSubMenu&MiniCart');
  };

  const onSearchHandler = (searchTermValue) => {
    window.location.href = Global.genURLParamAndChangePage(`${dataFields?.Link?.value?.href}`, { Term: searchTermValue });
  };

  return dataFields ? (
    <Fragment>
      <div className='header__right-contents__search header-icon-bg' onClick={() => onToggleSearchBar(true)}></div>
      <div className={'header__search' + `${isShowSearchBar ? ' show-search' : ''}`}>
        <div className='header__search__container'>
          <div className='header__search__input'>
            <SearchBoxInput
              isShowSearchBar={isShowSearchBar}
              initSuggestionList={dataFields['Default Suggested Terms']}
              placeHolderText={dataFields['PlaceHolder Text'].value}
              onSearchHandler={onSearchHandler}
              onToggleSearchBar={onToggleSearchBar}
              onDataSource={props.rendering.dataSource}
              role="searchbox" 
              aria-description="Search on top menu"
            />
            <div className='close-icon' onClick={() => onToggleSearchBar(false)}>
              <span></span>
              <span></span>
            </div>
            {/* <ul className='header__search__suggestions'>
              {FakeSuggestionsData.length > 0 &&
                  FakeSuggestionsData.map((element, index) => {
                    return (
                      <li
                        className='header__search__suggestions__item'
                        style={{ transitionDelay: 0.3 + index * 0.02 + 's' }}
                        key={index}
                        onClick={() => setSearchValue(element.value)}
                      >
                        {element.value}
                      </li>
                    );
                  })}
            </ul> */}
          </div>
        </div>
      </div>

      {isShowSearchBar && <div className='search-overlay' onClick={() => onToggleSearchBar(false)}></div>}
    </Fragment>
  ) : null;
};

SearchBox.propTypes = {
  fields: PropTypes.object,
  rendering: PropTypes.object
};

export default SearchBox;
