import './Footer.scss';

// import ErrorComponent from '@components/ErrorComponent';
import Proptypes from 'prop-types';
import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const Footer = (props) => {
  return (
    <>
      <footer className='footer'>
        <div className='footer-container'>
          <Placeholder name='osim-footer-main' rendering={props.rendering} />
        </div>
        {/* <ErrorComponent /> */}
      </footer>
    </>
  );
};

Footer.propTypes = {
  rendering: Proptypes.any
};

export default Footer;
