import { useFormik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';

import { AddressService, config } from '@components/SlicingPage/MyAccount/MyAddress/AddressForMultiLanguage/service';
import { clearAddressDatasources, disableRequiredField } from '@redux/actions/myAddress';
import { getAddressDatasources } from '@redux/actions/myAddress';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ChangeAreaModel from '../model/ChangeAreaModel';

const SelectArealModalContentData = (props) => {
  const {
    shippingFee,
    dataFields,
    setSubmitAddressEvt,
    addressCurrent,
    options,
    setEstimateShippingFeeEvt,
    objMessages,
    isNotFoundAddress,
    subFieldNameArray
  } = props;
  const addressElements = dataFields['Address Settings']['Address Elements'];
  const dispatch = useDispatch();

  const [fieldNameDropdownArr] = useState(
    addressElements.filter((address) => address.Type.value === config.TypeElement.Dropdown)
  );
  const [optionDropdown, setOption] = useState({});
  const [valueSelected, setValueSelected] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const messageValidate = useSelector((state) => state.getMessageReducer.objMessages?.['Msg_16']);
  const listFieldName = fieldNameDropdownArr.map((fielName) => fielName[config.FieldNameModel].value);
  const shippingFeeModel = new ChangeAreaModel(listFieldName).getData(listFieldName, addressCurrent);

  const formik = useFormik({
    initialValues: Object.assign(AddressService.initialValuesAddress(deepCopy(addressElements), addressCurrent)),
    validationSchema: AddressService.validateFileld(deepCopy(addressElements.filter((item) => item.IsRequired))),
    onSubmit: (values) => {
      let optionDropdownEdit = {};

      fieldNameDropdownArr.map((select) => {
        if (values[select[config.FieldNameModel].value]?.value) {
          optionDropdownEdit = Object.assign(optionDropdownEdit, {
            [select[config.FieldNameModel].value]: values[select[config.FieldNameModel].value].label,
            [`${select[config.FieldNameModel].value}Code`]: values[select[config.FieldNameModel].value].value
          });
        } else {
          optionDropdownEdit = Object.assign(optionDropdownEdit, {
            [select[config.FieldNameModel].value]: '',
            [`${select[config.FieldNameModel].value}Code`]: ''
          });
        }
      });

      setSubmitAddressEvt({
        ...shippingFeeModel,
        ...values,
        ...optionDropdownEdit,
        ...optionDropdown
      });
    },
    validateOnMount: true
  });

  useEffect(() => {
    if (addressCurrent) {
      setEstimateShippingFeeEvt(shippingFeeModel);
    }

    if (fieldNameDropdownArr?.length) {
      dispatch(
        getAddressDatasources({
          model: {
            CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
            RootValue: '',
            RootType: '',
            ChildType: ''
          },
          fieldName: fieldNameDropdownArr[0][config.FieldNameModel].value,
          condition: null
        })
      );
      if (addressCurrent) {
        for (let index = 0; index < fieldNameDropdownArr.length - 1; index++) {
          const rootValue = addressCurrent[`${fieldNameDropdownArr[index][config.FieldNameModel].value}Code`];
          const rootType = fieldNameDropdownArr[index][config.FieldNameModel].value;
          if (rootValue) {
            dispatch(
              getAddressDatasources({
                model: {
                  CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
                  RootValue: rootValue,
                  RootType: rootType,
                  ChildType: ''
                },
                fieldName: fieldNameDropdownArr[index + 1][config.FieldNameModel].value,
                condition: fieldNameDropdownArr[index].Condition
              })
            );
          } else {
            const optionMustReset = fieldNameDropdownArr.slice(index);

            dispatch(disableRequiredField(optionMustReset));
            break;
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (subFieldNameArray.length && isNotFoundAddress) {
      subFieldNameArray.map((item) => {
        formik.setFieldValue(item, 'sdf');
      });
    }
  }, [subFieldNameArray, isNotFoundAddress]);

  const onChangeAreaHandler = (params) => {
    const inputName = params.inputField[config.FieldNameModel].value;

    if (params.option.value !== formik.values[inputName]) {
      setIsFirstLoad(false);

      formik.setFieldValue(inputName, params.option.value);

      const optionCurrent = fieldNameDropdownArr.find(
        (address) => address[config.FieldNameModel].value === inputName
      );
      let optionMustResetObj = {};
      const optionMustReset = fieldNameDropdownArr.filter((address) => address.index > params.index);

      if (!optionMustReset.length) {
        dispatch(disableRequiredField());

        setValueSelected({
          ...valueSelected,
          [inputName]: params.option
        });
      } else {
        dispatch(clearAddressDatasources(optionMustReset));

        dispatch(disableRequiredField(optionMustReset));

        optionMustReset.map((item) => {
          optionMustResetObj = Object.assign(optionMustResetObj, {
            [item[config.FieldNameModel].value]: '',
            [`${item[config.FieldNameModel].value}Code`]: ''
          });

          formik.setFieldValue([item[config.FieldNameModel].value], '');
        });

        setValueSelected({
          ...valueSelected,
          ...optionMustResetObj,
          [inputName]: params.option
        });
      }

      setOption({
        ...optionDropdown,
        [optionCurrent[config.FieldNameModel].value]: params.option.label,
        [`${optionCurrent[config.FieldNameModel].value}Code`]: params.option.value,
        ...optionMustResetObj
      });

      setEstimateShippingFeeEvt({
        ...shippingFeeModel,
        ...optionDropdown,
        [optionCurrent[config.FieldNameModel].value]: params.option.label,
        [`${optionCurrent[config.FieldNameModel].value}Code`]: params.option.value,
        ...optionMustResetObj
      });

      if (inputName !== fieldNameDropdownArr[fieldNameDropdownArr.length - 1][config.FieldNameModel].value) {
        const filterNameList = fieldNameDropdownArr.map((dropdownName) => dropdownName[config.FieldNameModel].value);
        const childTypeIndex = filterNameList.indexOf(inputName) + 1;

        dispatch(
          getAddressDatasources({
            model: {
              CountryCode: dataFields['Address Settings']['Country Settings']['Country Code'].value || 'SA',
              RootValue: params.option.value,
              RootType: inputName,
              ChildType: ''
            },
            fieldName: Object.keys(options).includes(params?.fieldName)
              ? params.fieldName
              : fieldNameDropdownArr[childTypeIndex][config.FieldNameModel].value,
            condition: addressElements.filter((address) => address[config.FieldNameModel] === params.fieldName)
              ?.Condition
          })
        );
      }
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className='store-locator__modal' id='add-addresses'>
      <div className='user-addresses'>
        {addressElements.map((inputField, inputFieldIdx) => {
          if (inputField.Type.value === config.TypeElement.Text || inputField.Type.value === config.TypeElement.Phone) {
            return (
              <div className='form-group' key={inputFieldIdx}>
                <input
                  onChange={formik.handleChange}
                  value={formik.values[inputField[config.FieldNameModel].value]}
                  type='text'
                  className='form-control form-control-lg'
                  name={inputField[config.FieldNameModel].value}
                  placeholder={inputField['Display Name'].value}
                />
                {formik.errors[inputField[config.FieldNameModel].value] && formik.touched[inputField[config.FieldNameModel].value] && (
                  <span className='error-validate'>{messageValidate.replace(config.FieldNameValidate, inputField[config.FieldNameModel].value)}</span>
                )}
              </div>
            );
          }
          if (inputField.Type.value === config.TypeElement.Dropdown) {
            const  isDisableRequired = isNotFoundAddress && subFieldNameArray.indexOf(inputField[config.FieldNameModel].value) >= 0;

            return !isDisableRequired ?  (
              <div className='form-group' key={inputFieldIdx}>
                <Select
                  options={options[inputField[config.FieldNameModel].value]}
                  onChange={(optionCurrent) => onChangeAreaHandler({ option: optionCurrent, inputField: inputField, index: inputFieldIdx })}
                  value={
                    !valueSelected?.[inputField[config.FieldNameModel].value] && isFirstLoad
                      ? formik.values[inputField[config.FieldNameModel].value]
                      : valueSelected[inputField[config.FieldNameModel].value]
                  }
                  name={inputField[config.FieldNameModel].value}
                  placeholder={inputField['Display Name'].value}
                  className='customization-dropdown'
                  classNamePrefix='customization-dropdown'
                  noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
                />
                {formik.errors[inputField[config.FieldNameModel].value] && formik.touched[inputField[config.FieldNameModel].value] && (
                  <span className='error-validate'>{messageValidate.replace(config.FieldNameValidate, inputField[config.FieldNameModel].value)}</span>
                )}
              </div>
            ): <div
              className='form-group form-group--select'
              key={inputFieldIdx}
              id={inputField[config.FieldNameModel].value}
            >
              <Select
                options={options[inputField[config.FieldNameModel].value]}
                value=''
                noOptionsMessage={() => <span>{objMessages['LBL-NoSelection']}</span>}
                placeholder={inputField['Display Name'].value}
                className='customization-dropdown'
                classNamePrefix='customization-dropdown'
                isSearchable={false}
                // menuIsOpen={true}
              />
            </div>;
          }
        })}
      </div>
      <div className='estimate-delivery'>
        <span>{dataFields['Estimated Delivery Fee Label'].value}:</span> <span>{shippingFee}</span>
      </div>
      <div className='button-container'>
        <button className='btn btn-primary' type='submit'>
          <Text field={dataFields['Confirm Selection Label']} />
        </button>
      </div>
    </form>
  );
};

SelectArealModalContentData.propTypes = {
  dataFields: PropTypes.any,
  options: PropTypes.any,
  setSubmitAddressEvt: PropTypes.func,
  setEstimateShippingFeeEvt: PropTypes.func,
  addressCurrent: PropTypes.any,
  shippingFee: PropTypes.any,
  objMessages: PropTypes.object,
  isNotFoundAddress: PropTypes.bool,
  subFieldNameArray: PropTypes.object
};

const mapStateToProps = (state) => ({
  options: state.myAddressReducer.addressDatasourcesReducer.options,
  isNotFoundAddress: state.myAddressReducer.addressDatasourcesReducer.isNotFoundAddress,
  subFieldNameArray: state.myAddressReducer.addressDatasourcesReducer.subFieldNameArray,
  objMessages: state.getMessageReducer?.objMessages || null
});

export default connect(mapStateToProps)(SelectArealModalContentData);
