export default class OmnichatTracking {
  tracking() {
    window.omnichatTracker = window.omnichatTracker || [];

    window.omnichatTracker.push(arguments);
  }

  trackingLoggedUser(loggedInUserInfo) {
    const loggedUserData = {
      memberId: loggedInUserInfo?.Id || '',
      memberEmail: loggedInUserInfo?.Email || '',
      memberPhone: loggedInUserInfo?.PhoneNumber || '',
      memberName: loggedInUserInfo?.FirstName || '',
      memberProfilePic: loggedInUserInfo?.ProfilePic || ''
    };

    this.tracking('init', 'config', loggedUserData);
  }
}
