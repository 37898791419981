import * as types from './types';

export const addPriorityToCart = (payload) => ({
  type: types.ADD_PRIORITY_TO_CART,
  payload
});

export const addPriorityToCartFailed = (payload) => ({
  type: types.ADD_PRIORITY_TO_CART_FAILED,
  payload
});

export const addPriorityToCartSuccess = (payload) => ({
  type: types.ADD_PRIORITY_TO_CART_SUCCESS,
  payload
});