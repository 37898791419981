import produce from 'immer';

import * as productTypes from '@redux/actions/product/productTypes';
// import { deepCopy } from '@utils/utility';

const initState = {
  listProductWishlist: '',
  isLoading: false,
  error: '',
  productIdCurrent: '',
  isGetWishList: false,
  isGetWishListLoading: false,
  isAddWishListSuccess: false
};

const productWishlistReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.GET_PRODUCT_WISH_LIST:
      draft.listProductWishlist = '';

      draft.productIdCurrent = '';

      draft.isAddWishListSuccess = false;

      draft.isGetWishListLoading = true;
      break;
      
    case productTypes.REMOVE_PRODUCT_WISH_LIST:
      draft.isLoading = true;

      draft.isAddWishListSuccess = false;

      draft.productIdCurrent = action.payload.productId;
      break;

    case productTypes.REMOVE_PRODUCT_WISH_LIST_SUCCESS:
      draft.isLoading = false;

      draft.listProductWishlist = draft.listProductWishlist.filter(product => product.ExternalWishListLineId !== action.payload.lineIds);
      break;

    case productTypes.ADD_PRODUCT_WISH_LIST:
      draft.isLoading = true;

      draft.isAddWishListSuccess = false;

      draft.error= '';

      draft.productIdCurrent = action.payload.productId;
      break;
  
    case productTypes.ADD_PRODUCT_WISH_LIST_SUCCESS:
      draft.isLoading = false;

      draft.isAddWishListSuccess = true;
  
      draft.listProductWishlist = action.payload;
      break;

    case productTypes.GET_PRODUCT_WISH_LIST_USER_ID:
      draft.isLoading = true;
  
      draft.listProductWishlist = '';
      break;
      
    case productTypes.GET_PRODUCT_WISH_LIST_SUCCESS:
      //GA4 Whish List Success
      // console.log('action.payload', action.payload);

      draft.isLoading = false;

      draft.listProductWishlist = action.payload;

      draft.isAddWishListSuccess = true;

      draft.isGetWishListLoading = false;
      break;

    case productTypes.ACTION_PRODUCT_WISH_LIST_FAILED:
      draft.isLoading = false;

      draft.error = action.error.message;

      draft.isGetWishList = action.error.isGetWishList;

      draft.isGetWishListLoading = false;
    }

    return draft;
  });

export default productWishlistReducer;
