import produce from 'immer';

import * as spinAndWinTypes from '@redux/actions/spinAndWin/spinAndWinTypes';

const initState = {
  isLoading: false,
  isGetNumSpinSuccess: false,
  numberSpin: 0,
  isErrorMsg: true
};

const getNumberSpinEmailReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case spinAndWinTypes.GET_NUMBER_SPIN_EMAIL:
      draft.isLoading = true;

      draft.isGetNumSpinSuccess = false;
      break;

    case spinAndWinTypes.GET_NUMBER_SPIN_EMAIL_SUCCESS:
      draft.isErrorMsg = true;

      draft.isLoading = false;

      draft.isGetNumSpinSuccess = true;

      draft.numberSpin = action.payload.Data;
      break;

    case spinAndWinTypes.GET_NUMBER_SPIN_EMAIL_FAILED:
      draft.isLoading = false;

      draft.isGetNumSpinSuccess = false;
      break;

    case spinAndWinTypes.CLEAR_DATA_INPUT_SPIN:
      draft.isErrorMsg = false;
      
      draft.numberSpin = 0;
      break;
    }

    return draft;
  });

export default getNumberSpinEmailReducer;
