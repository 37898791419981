import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { Image, Link } from '@sitecore-jss/sitecore-jss-react';

import HeaderLogoModel from '../Model/HeaderLogoModel';

const LogoItem = (props) => {
  const { contentData } = props;

  const [data, setData] = useState(null);

  useEffect(() => {
    const headerLogoModel = new HeaderLogoModel();

    setData(headerLogoModel.getData(contentData));
  }, []);

  return (
    data && (
      <div className='main-logo-wrap'>
        <Link className='main-logo' field={data.Link} title='main logo'>
          <Image className='main-logo__image main-logo__image--default' field={data.Image} />
          <Image className='main-logo__image main-logo__image--dark-mode' field={data['Second Image']} />
        </Link>
        {/* <a href='/' className='main-logo'>
          <Image className='main-logo__image main-logo__image--default' field={data.Image} />
          <Image className='main-logo__image main-logo__image--dark-mode' field={data['Second Image']} />
        </a> */}
      </div>
    )
  );
};

LogoItem.propTypes = {
  contentData: PropTypes.any
};

export default LogoItem;
