import './TermsConditionsModals.scss';

import { any, bool, func } from 'prop-types';
import React from 'react';

import ModalComponent from '@components/Share/ModalComponent';
import { RichText } from '@sitecore-jss/sitecore-jss-react';

const TermsConditionsModals = (props) => {
  const { isShow, onCloseHandler, ondataSources } = props;

  return (
    <ModalComponent isShow={isShow} onCloseHandler={onCloseHandler} titlePopup={ondataSources?.['Terms And Conditions Label']?.value}>
      <div className='termsconditions-modal'>
        <RichText field={ondataSources?.['Terms And Conditions Details']} tag='p' className='termsconditions-modal__content'/>
      </div>
    </ModalComponent>
  );
};

TermsConditionsModals.propTypes = {
  isShow: bool,
  onCloseHandler: func,
  ondataSources: any
};

export default TermsConditionsModals;
