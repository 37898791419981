import { bool, object } from 'prop-types';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { cpCheckContactDuplicateSelector } from '@redux/reducers/cpCheckContactDuplicate/selector';
import { Text } from '@sitecore-jss/sitecore-jss-react';

import { TYPE_TEXT } from '../../../GenericComponents/CPForm/FormConstant';

const AcknowledgeEmailInput = ({ objectValidation, layoutData, disabled, ...props }) => {
  const { checkContactErrorData } = useSelector(cpCheckContactDuplicateSelector);
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  const profileEmail = useMemo(() => {
    return clientTellingPortalCustomer?.customerInformation?.Email || '';
  }, [clientTellingPortalCustomer]);

  const isDisableEmailInput = useMemo(() => {
    const contactType = clientTellingPortalCustomer.contacts?.[0]?.contact_type;

    return ((contactType === 'PDPA lead' || contactType === 'Existing customer') && profileEmail) || disabled;
  }, [clientTellingPortalCustomer, profileEmail, disabled]);

  return (
    <div className='form-group'>
      <div className='cp-input'>
        <input
          {...props}
          type={TYPE_TEXT}
          id='PDPA-email'
          name='PDPA-email'
          placeholder={layoutData['Email Label'].value}
          disabled={isDisableEmailInput}
          readOnly={isDisableEmailInput}
        />
      </div>
      {objectValidation['PDPA-email'].touched && objectValidation['PDPA-email'].empty && (
        <Text tag='span' className='error-validate' field={layoutData['Email Validation Text']} />
      )}
      {objectValidation['PDPA-email'].touched && !objectValidation['PDPA-email'].isValid ? (
        <Text tag='span' className='error-validate' field={layoutData['Email Validation Text']} />
      ) : clientTellingPortalCustomer?.createPDPAErrorCode === 1 || (checkContactErrorData && checkContactErrorData.isDuplicatedEmail) ? (
        <Text tag='span' className='error-validate' field={layoutData['Duplicated Email Message']} />
      ) : (
        <></>
      )}
    </div>
  );
};

AcknowledgeEmailInput.propTypes = {
  objectValidation: object,
  layoutData: object,
  disabled: bool
};

export default AcknowledgeEmailInput;
