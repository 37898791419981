export const GET_BILLING_DATA = '/Checkout/GetBillingData';

export const GET_BALANCE_BILLING_DATA = '/Checkout/GetBalanceBillingData';

export const SUBMIT_ORDER = '/Checkout/SubmitOrder';

export const SUBMIT_ORDER_PAYBALANCE = '/Checkout/PayBalance';

export const PAYMENT_STATUS = '/Checkout/GetOrderPaymentStatus';

