import './LoadingMask.scss';

import React from 'react';

const LoadingMask = () => {
  return (
    <div className='loading-mask'>
      <div className='loading__default'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
  );
};

export default LoadingMask;