import PropTypes from 'prop-types';
import React from 'react';

import { FileUpload } from '@sitecore-jss/sitecore-jss-react-forms';

import FormFieldError from '../../FormFieldError';

const CustomInputTypeFile = (props) => {
  return (
    <>
      <FileUpload {...props} errors={[]} />
      <FormFieldError {...props} errors={props.errors.reverse()} />
    </>
  );
};

CustomInputTypeFile.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.any,
  errors: PropTypes.any,
  tracker: PropTypes.any
};

export default CustomInputTypeFile;
