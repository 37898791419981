import './PopupLayout.scss';

import { any, bool, func, string } from 'prop-types';
import React from 'react';
import { useSelector } from 'react-redux';

import ModalComponent from '@components/Share/ModalComponent';

const FreeGiftPopupLayout = ({ className = '', onClosePopup, children }) => {
  const { isShow } = useSelector((state) => state.controlSelectingFreeGiftPopupReducer);

  return (
    <div className='popup-layout'>
      <ModalComponent isShow={isShow} cssClass={`popup-banner__content popup-layout__content ${className}`} onCloseHandler={onClosePopup}>
        <div className='main-banner'>
          <div className='main-banner__background'>{children}</div>
        </div>
      </ModalComponent>
    </div>
  );
};

FreeGiftPopupLayout.displayName = 'FreeGiftPopupLayout';

FreeGiftPopupLayout.propTypes = {
  className: string,
  isShow: bool,
  children: any,
  onClosePopup: func
};

export default FreeGiftPopupLayout;
