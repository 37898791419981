import './SelectingFreeGift.scss';

import { any, object } from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import { addFreeGiftToCartStart } from '@redux/actions/addFreeGiftToCart';
import { hideSelectingFreeGiftPopup } from '@redux/actions/controlSelectingFreeGiftPopup';
import { updateFreeGiftToCartStart } from '@redux/actions/updateFreeGiftToCart';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import FreeGiftPopupLayout from '../FreeGiftPopupLayout';
import { useSelectingFreeGiftUtils } from '../hooks';
import FreeGiftPopupModel from '../models/FreeGiftPopupModel';
import useSelectingFreeGift from '../SelectingFreeGiftProvider/useSelectingFreeGift';
import FreeGiftSelectionBody from './FreeGiftSelectionBody';

const SelectingFreeGift = ({ ...props }) => {
  const dispatch = useDispatch();
  const { freeGiftData, isLoading: isGettingFreeGiftData } = useSelector((state) => state.getFreeGiftReducer);
  const { selectedFreeGift, freeGiftFromCartLines, freeGiftDataSort } = useSelectingFreeGift();
  const { handleCheckFreeGiftExistInCartLine } = useSelectingFreeGiftUtils();
  // const scrollToSection = useScrollToSections();
  const [fieldData, setFieldData] = useState(null);

  useEffect(() => {
    const freeGiftPopupModel = new FreeGiftPopupModel();

    setFieldData(freeGiftPopupModel.getData(deepCopy(props?.fields) || {}));
  }, []);

  const handleGenerateFreeGiftPopups = () => {
    let FreeGiftPopups = [];
    for (const freeGiftItem of selectedFreeGift) {
      const freeGiftInCart = freeGiftFromCartLines.find(
        (freeGift) => freeGift.PromotionFreeGiftPopupId === freeGiftItem.PromotionId && freeGift.ProductId === freeGiftItem.ProductId
      );

      if (freeGiftInCart) {
        if (freeGiftItem?.ProductId !== freeGiftInCart?.ProductId || freeGiftItem?.variant?.VariantId !== freeGiftInCart?.VariantId) {
          FreeGiftPopups = [
            ...FreeGiftPopups,
            {
              LineUniqueId: freeGiftItem?.LineUniqueId,
              PromotionId: freeGiftItem?.PromotionId,
              ProductId: freeGiftItem?.ProductId,
              VariantId: freeGiftItem?.variant?.VariantId
            }
          ];
        }
      } else {
        FreeGiftPopups = [
          ...FreeGiftPopups,
          {
            LineUniqueId: freeGiftItem?.LineUniqueId,
            PromotionId: freeGiftItem?.PromotionId,
            ProductId: freeGiftItem?.ProductId,
            VariantId: freeGiftItem?.variant.VariantId
          }
        ];
      }
    }

    return FreeGiftPopups;
  };

  const isFreeGiftsInCart = useMemo(() => {
    return freeGiftDataSort.every((freeGift) => {
      const slotDataLength = freeGift?.slotData?.length;
      const freeGiftInCartLine = freeGiftFromCartLines.filter((item) => item.PromotionFreeGiftPopupId === freeGift.PromotionId) || [];

      return slotDataLength === freeGiftInCartLine.length;
    });
  }, [freeGiftFromCartLines, freeGiftDataSort]);

  const isDisableAddToCartButton = useMemo(() => {
    let disabledButton = false;

    if (selectedFreeGift.length) {
      disabledButton = !freeGiftDataSort.every(
        (item) => selectedFreeGift.filter((selectedItem) => selectedItem.PromotionId === item.PromotionId)?.length === item.slotData.length
      );
    }

    return handleGenerateFreeGiftPopups().length ? disabledButton : !isFreeGiftsInCart;
  }, [selectedFreeGift, freeGiftDataSort, handleGenerateFreeGiftPopups, isFreeGiftsInCart]);

  const handleAddFreeGiftToCart = useCallback(() => {
    if (selectedFreeGift.length) {
      if (!selectedFreeGift.every((freeGift) => handleCheckFreeGiftExistInCartLine(freeGift.PromotionId))) {
        const FreeGiftPopups = [...handleGenerateFreeGiftPopups()];
        if (FreeGiftPopups.length) dispatch(addFreeGiftToCartStart({ FreeGiftPopups }));
      } else {
        const FreeGiftPopups = [...handleGenerateFreeGiftPopups()];

        if (FreeGiftPopups.length && !isDisableAddToCartButton) dispatch(updateFreeGiftToCartStart({ FreeGiftPopups }));
      }

      dispatch(hideSelectingFreeGiftPopup());
    } else {
      dispatch(hideSelectingFreeGiftPopup());
    }

    // scrollToSection();
  }, [isDisableAddToCartButton, selectedFreeGift]);

  const handleClosePopup = useCallback(() => {
    if (isFreeGiftsInCart) dispatch(hideSelectingFreeGiftPopup());
  }, [isFreeGiftsInCart]);

  return !isGettingFreeGiftData ? (
    fieldData && freeGiftData?.Data?.FreeGifts.length ? (
      <div className='select-free-gift'>
        <FreeGiftPopupLayout onClosePopup={handleClosePopup} className='select-free-gift__content'>
          <div className='select-free-gift__body'>
            <div className='popup-title'>
              <Text tag='h6' field={fieldData?.['Title']} className='title' />
            </div>
            <div className='popup-description'>
              <div className='description-body'>
                <div className='content'>
                  <Text tag='p' field={fieldData?.['Description']} />
                </div>
                <FreeGiftSelectionBody fieldData={fieldData} />
              </div>
              <div className='description-cta'>
                <button
                  className={`btn btn-primary ${isDisableAddToCartButton ? 'btn-disable' : ''}`}
                  onClick={handleAddFreeGiftToCart}
                  disabled={isDisableAddToCartButton}
                >
                  {!isFreeGiftsInCart || handleGenerateFreeGiftPopups().length ? (
                    <Text field={fieldData?.['Add Free' + ' Gift Label']} />
                  ) : (
                    <Text field={fieldData?.['View Cart Label']} />
                  )}
                </button>
              </div>
            </div>
          </div>
        </FreeGiftPopupLayout>
      </div>
    ) : (
      <></>
    )
  ) : (
    <LoadingMask />
  );
};

SelectingFreeGift.propTypes = {
  currentCart: object,
  fields: any
};

export default SelectingFreeGift;
