import './CartItem.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import LoadingMask from '@components/Share/LoadingMask';
import ToggleWisList from '@components/Share/ToggleWisList';
import PromotionCartUpdateModel from '@components/SlicingPage/CartDetails/models/PromotionCartUpdateModel';
import { removeCartLine, removeSOCartLine, updateCartLine, updateSOCartLine } from '@redux/actions/cartDetail';
import { addProductWishlist } from '@redux/actions/product';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { HK_SITE_NAME, PROMO_TRADEIN_TYPE, PROMO_TRADEIN_TYPE_LOYALTY, TW_SITE_NAME } from '@utils/constant';
import Global from '@utils/global';

import OmnichatTracking from '../../../../Share/OmnichatTracking';
import { behaviorSubjectCartItem$ } from './CartItemBehaviorSubject';
import CartItemDelivery from './CartItemDelivery';
import CartItemInfor from './CartItemInfor';
import CartItemTotalPrice from './CartItemTotalPrice';
import CPEditProductBtn from './CPEditProductBtn';

const CartItem = (props) => {
  const { cartItemInfo, dataSources, currencyInformation, lineId, indexGA4 } = props;

  const [quantityCurrent, setQuantity] = useState(+cartItemInfo.Quantity);
  const [deliveryMethodId, setDeliveryMethod] = useState(null);
  const [outOfStockData, setOutOfStock] = useState(null);
  const [tradeInOption, hasTradeInOption] = useState(null);
  const dispatch = useDispatch();

  const isLoadingRemoveCart = useSelector((state) => state.productReducer.cartDetailReducer.isLoading);
  const isLoadingMoveToWishlist = useSelector((state) => state.productReducer.productWishlistReducer.isLoading);
  const [LoadedGTM, setLoadedGTM] = useState(false);
  const currentSiteName = SitecoreContextFactoryService.getValueContextItem('site')?.name;
  const isTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('HKOmnichat');
  const isTWTurnOnOmnichat = SitecoreContextFactoryService.getValueContextItem('TWOmnichat');
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const [isShow, setShow] = useState(false);
  const [isWishListProduct, setWishListProduct] = useState(false);
  const isGA4 = Global.getIsGA4();
  const listProductWishlist = useSelector((state) => state.productReducer.productWishlistReducer.listProductWishlist);

  useEffect(() => {
    if (listProductWishlist?.length) {
      const wishList = listProductWishlist.find((wishListItem) => wishListItem.Product.ProductId === cartItemInfo.ProductId);

      setWishListProduct(wishList || false);
    }
  }, [listProductWishlist]);

  useEffect(() => {
    const outOfStockObj = cartItemInfo.LineStatus.find((lineItem) => lineItem.Code === 'OutOfStock');

    const tradeIn = cartItemInfo.PromotionCartLines.some((line) => line.Type === PROMO_TRADEIN_TYPE || line.Type === PROMO_TRADEIN_TYPE_LOYALTY);

    setOutOfStock(outOfStockObj);

    hasTradeInOption(tradeIn);
  }, [cartItemInfo]);

  const onChangeQuantityHandler = (quantity) => {
    behaviorSubjectCartItem$.next({ cartLineIdEditCurrent: cartItemInfo.ExternalCartLineId });
    const subLinesObject = [];
    const promotionCartLines = [];
    if (cartItemInfo.PromotionCartLines?.length) {
      cartItemInfo.PromotionCartLines.map((promotionItem) => {
        promotionCartLines.push(new PromotionCartUpdateModel().getData(promotionItem || {}));
      });
    }

    const updateCartPayload = {
      Lines: [
        {
          LineUniqueId: cartItemInfo.ExternalCartLineId,
          ProductId: cartItemInfo.ProductId,
          VariantId: cartItemInfo.VariantId,
          CatalogName: cartItemInfo.Catalog,
          Quantity: quantity,
          PromotionCartLines: promotionCartLines?.length ? promotionCartLines : new PromotionCartUpdateModel().getData({}),
          DeliveryOptionId: deliveryMethodId || cartItemInfo.DeliveryOptionId || ''
        }
      ]
    };

    if (cartItemInfo.SubLines?.length) {
      cartItemInfo.SubLines.map((item) => {
        let bundlePromotion = [];
        if (item.PromotionCartLines && item.PromotionCartLines.length > 0) {
          bundlePromotion = item.PromotionCartLines;
        }

        subLinesObject.push({
          ProductId: item.ProductId,
          VariantId: item.VariantId,
          Quantity: quantity,
          // PromotionCartLines: [
          //   {
          //     ProductId: bundlePromotion.ProductId,
          //     CatalogName: bundlePromotion.Catalog,
          //     Type: bundlePromotion.Type
          //   }
          // ],
          PromotionCartLines: bundlePromotion
        });
      });

      updateCartPayload.Lines[0]['Sublines'] = subLinesObject;
    }

    if (isClientTelingUser) {
      dispatch(updateSOCartLine(updateCartPayload));
    } else {
      dispatch(updateCartLine(updateCartPayload));
    }

    setQuantity(quantity);
  };

  const onRemoveCartLineHandler = (isAddWishList) => {
    setShow(false);

    if (isAddWishList) {
      dispatch(
        addProductWishlist({
          productId: cartItemInfo.ProductId,
          variantId: cartItemInfo?.VariantId || '',
          quantity: +cartItemInfo.Quantity
        })
      );
    }

    // INF: Google analytics
    //Old Logic GA
    // if (currentCart?.Lines) {
    //   const params = {
    //     items: Global.getItemList(currentCart.Lines)
    //   };

    //   new GoogleAnalytic().ggGateWay('event', 'remove_from_cart', params);
    // }

    // INFO: Omnichat Tracing for HK or TW site
    if (currentSiteName === HK_SITE_NAME && isTurnOnOmnichat || currentSiteName === TW_SITE_NAME && isTWTurnOnOmnichat) {
      const productRemoveData = [
        {
          id: cartItemInfo.ProductId || '',
          name: cartItemInfo.DisplayName || '',
          brand: 'Osim',
          category: cartItemInfo.CategoryName || '',
          variant: cartItemInfo.VariantId || '',
          quantity: parseInt(cartItemInfo.Quantity) || '',
          price: cartItemInfo?.LineFinalTotalAmount.toString() || ''
        }
      ];

      new OmnichatTracking().tracking('event', 'remove_from_cart', { items: productRemoveData });
    }

    setLoadedGTM(!LoadedGTM);

    if (isClientTelingUser) {
      dispatch(
        removeSOCartLine({
          cartLineId: cartItemInfo.ExternalCartLineId
        })
      );
    } else {
      dispatch(
        removeCartLine({
          cartLineId: cartItemInfo.ExternalCartLineId
        })
      );
    }
  };

  //Old Logic GA
  // useDidUpdateEffect(() => {
  //   // INF: Google analytics
  //   if (window['google_tag_manager']) {
  //     window.dataLayer = window.dataLayer || [];
  //     let obj = {
  //       event: 'removeFromCart',
  //       ecommerce: {
  //         remove: {
  //           // 'remove' actionFieldObject measures.
  //           products: [
  //             {
  //               //  removing a product to a shopping cart.
  //               name: cartItemInfo?.DisplayName,
  //               id: cartItemInfo?.ProductId,
  //               price: cartItemInfo?.LineFinalTotalAmount.toString(),
  //               category: cartItemInfo?.CategoryName,
  //               quantity: parseInt(cartItemInfo?.Quantity)
  //             }
  //           ]
  //         }
  //       }
  //     };

  //     window.dataLayer.push(obj);
  //     // new GoogleAnalytic().ggGateWay(obj);
  //   }
  // }, [LoadedGTM]);

  const onSelectDeliveryHandler = (deliveryId) => {
    behaviorSubjectCartItem$.next({ cartLineIdEditCurrent: cartItemInfo.ExternalCartLineId });
    const subLinesObject = [];
    const promotionCartLines = [];
    if (cartItemInfo.PromotionCartLines?.length) {
      cartItemInfo.PromotionCartLines.map((promotionItem) => {
        promotionCartLines.push(new PromotionCartUpdateModel().getData(promotionItem || {}));
      });
    }

    const updateCartPayload = {
      Lines: [
        {
          LineUniqueId: cartItemInfo.ExternalCartLineId,
          ProductId: cartItemInfo.ProductId,
          VariantId: cartItemInfo.VariantId,
          CatalogName: cartItemInfo.Catalog,
          Quantity: cartItemInfo.Quantity,
          PromotionCartLines: promotionCartLines?.length ? promotionCartLines : new PromotionCartUpdateModel().getData({}),
          DeliveryOptionId: deliveryId
        }
      ]
    };

    if (cartItemInfo.SubLines?.length) {
      cartItemInfo.SubLines.map((item) => {
        let bundlePromotion = [];
        if (item.PromotionCartLines && item.PromotionCartLines.length > 0) {
          bundlePromotion = item.PromotionCartLines[0];
        }

        subLinesObject.push({
          ProductId: item.ProductId,
          VariantId: item.VariantId,
          Quantity: quantityCurrent,
          PromotionCartLines: [
            {
              ProductId: bundlePromotion.ProductId,
              CatalogName: bundlePromotion.Catalog,
              Type: bundlePromotion.Type
            }
          ]
        });
      });

      updateCartPayload.Lines[0]['Sublines'] = subLinesObject;
    }
    if (isClientTelingUser) {
      dispatch(updateSOCartLine(updateCartPayload));
    } else {
      dispatch(updateCartLine(updateCartPayload));
    }

    setDeliveryMethod(deliveryId);
  };

  const onAddGiftingOption = (giftData) => {
    behaviorSubjectCartItem$.next({ cartLineIdEditCurrent: cartItemInfo.ExternalCartLineId });
    const subLinesObject = [];
    const promotionCartLines = [];
    if (cartItemInfo.PromotionCartLines?.length) {
      cartItemInfo.PromotionCartLines.map((promotionItem) => {
        promotionCartLines.push(new PromotionCartUpdateModel().getData(promotionItem || {}));
      });
    }

    const updateCartPayload = {
      Lines: [
        {
          ...giftData,
          LineUniqueId: cartItemInfo.ExternalCartLineId,
          ProductId: cartItemInfo.ProductId,
          VariantId: cartItemInfo.VariantId,
          CatalogName: cartItemInfo.Catalog,
          Quantity: cartItemInfo.Quantity,
          PromotionCartLines: promotionCartLines?.length ? promotionCartLines : new PromotionCartUpdateModel().getData({}),
          DeliveryOptionId: deliveryMethodId || cartItemInfo.DeliveryOptionId || ''
        }
      ]
    };

    if (cartItemInfo.SubLines?.length) {
      cartItemInfo.SubLines.map((item) => {
        let bundlePromotion = [];
        if (item.PromotionCartLines && item.PromotionCartLines.length > 0) {
          bundlePromotion = item.PromotionCartLines;
        }

        subLinesObject.push({
          ProductId: item.ProductId,
          VariantId: item.VariantId,
          Quantity: quantityCurrent,
          PromotionCartLines: bundlePromotion
        });
      });

      updateCartPayload.Lines[0]['Sublines'] = subLinesObject;
    }

    dispatch(updateCartLine(updateCartPayload));
  };

  return (
    <div className='cart-item' id={cartItemInfo.ExternalCartLineId}>
      {/* <div className='text-highlight text-highlight--mobile'>Special Deal for Purchase with Purchase</div> */}
      <div className='row'>
        <div className={isClientTelingUser ? 'col-md-4' : 'col-lg-6'}>
          <div className='cart-item__container'>
            <ToggleWisList
              quantity={quantityCurrent}
              dataProduct={{
                ...cartItemInfo,
                itemListName: dataSources?.['Title']?.value,
                indexGA4: indexGA4
              }}
            />
            <div className={`row cart-item__image-row ${isClientTelingUser ? 'justify-content-center align-items-center' : ''}`}>
              {cartItemInfo.SubLines && cartItemInfo.SubLines.length > 0 ? (
                <>
                  {cartItemInfo.SubLines.map((item) => {
                    return (
                      <div className='col-6 cart-item__image' key={item.ExternalCartLineId}>
                        <a className='' href={`${cartItemInfo.ProductPageLink}?IndexGA4=${indexGA4}`}>
                          <img src={item.Image} />
                        </a>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className={`${isClientTelingUser ? 'col-11' : 'col-12'} cart-item__image`}>
                  <a className='' href={`${cartItemInfo.ProductPageLink}?IndexGA4=${indexGA4}`}>
                    <img src={cartItemInfo.Image} alt={cartItemInfo.DisplayName} />
                  </a>
                </div>
              )}
              <CPEditProductBtn
                dataSources={dataSources}
                cartItemInfo={cartItemInfo}
                currencyInformation={currencyInformation}
                indexGA4={indexGA4}
                isClientTelingUser={isClientTelingUser}
                isShowModal={isShow}
                isWishListProduct={isWishListProduct}
                onRemoveCartLine={onRemoveCartLineHandler}
                onOpenModal={() => setShow(true)}
                onCloseModal={() => setShow(false)}
              />
            </div>
          </div>
        </div>
        <div className={isClientTelingUser ? 'col-md-8' : 'col-lg-6'}>
          <CartItemInfor
            removeCartLineEvt={onRemoveCartLineHandler}
            quantityItemEvt={onChangeQuantityHandler}
            giftingOption={onAddGiftingOption}
            dataSources={dataSources}
            data={cartItemInfo}
            outOfStockData={outOfStockData}
            tradeInOption={tradeInOption}
            currencyInfor={currencyInformation}
            lineId={lineId}
            indexGA4={indexGA4}
          ></CartItemInfor>
          <CartItemDelivery
            outOfStockData={outOfStockData}
            cartItemInfo={cartItemInfo}
            selectDeliveryEvt={onSelectDeliveryHandler}
            dataSources={dataSources}
          />
          <CartItemTotalPrice dataSources={dataSources} subTotal={cartItemInfo} data={cartItemInfo} />
        </div>
      </div>
      {isLoadingRemoveCart && !isLoadingMoveToWishlist ? <LoadingMask /> : ''}
    </div>
  );
};

CartItem.propTypes = {
  cartItemInfo: PropTypes.object,
  dataSources: PropTypes.object,
  currencyInformation: PropTypes.object,
  wishListProducts: PropTypes.array,
  deliveryOptions: PropTypes.array,
  lineId: PropTypes.string,
  indexGA4: PropTypes.number,
  removeCartLineEvt: PropTypes.func
};

export default CartItem;
