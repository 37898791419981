import VariantDetailModel from '@components/BuyingOptions/BOVariants/model/BOVariantsModel';
import { deepCopy } from '@utils/utility';

export default class BOFreeGiftsModel {
  constructor() {
    this.DisplayName = '';

    this.Id = '';

    this.ImageURLs = [];

    this.ProductId = '';

    this.Slot = '';

    this.VariantId = '';

    this.VariantName = '';

    this.VariantImages = '';
    
    this.Variants = [] ;

    this.MergeId = '';

    this.GiftId = '';

    this.Quantity = 0;

    this.Status = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'DisplayName')) {
      this.DisplayName = dataSource.DisplayName ? dataSource.DisplayName : this.DisplayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Id')) {
      this.Id = dataSource.Id ? dataSource.Id : this.Id;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ImageURLs')) {
      this.ImageURLs = dataSource.ImageURLs ? dataSource.ImageURLs : this.ImageURLs;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductId')) {
      this.ProductId = dataSource.ProductId ? dataSource.ProductId : this.ProductId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Slot')) {
      this.Slot = dataSource.ProductId ? dataSource.Slot : this.Slot;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Variants')) {
      let newVariantList = [];

      if (dataSource.Variants) {
        dataSource.Variants.map(element => {
  
          newVariantList.push(new VariantDetailModel().getData(deepCopy(element), dataSource.id));
        });
      }

      this.Variants = dataSource.Variants ? newVariantList : this.Variants;

      this.Quantity = newVariantList?.length ? newVariantList[0].ProductAvailability.PurchasableLimit : 0;

      this.Status = newVariantList?.length ? newVariantList[0].ProductAvailability.FinalStatus?.Status : this['Status'];

      this.VariantId = newVariantList?.length ? newVariantList[0].VariantId : '';

      this.VariantName = newVariantList?.length ? newVariantList[0].VariantName : '';

      this.VariantImages = newVariantList?.length ? newVariantList[0].Images[0] : '';
    }

    this.GiftId =  `${this.Slot + this.ProductId + (this.Variants[0]?.VariantId || '')}`;

    this.MergeId = `${this.ProductId + (this.Variants[0]?.VariantId || '')}`;

    return this;
  }
}
