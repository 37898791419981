import { useCallback } from 'react';

export const useSetTitleHeight = () => {
  const handleLoopRefsData = useCallback((refsData, cb) => {
    for (const refItem of refsData) {
      if (refItem?.current) {
        const refCurrent = refItem.current;

        cb(refCurrent);
      }
    }
  }, []);

  const handleResetTitleHeight = useCallback(
    (refsData) => {
      handleLoopRefsData(refsData, (refCurrent) => {
        refCurrent.style.height = 'fit-content';
      });
    },
    [handleLoopRefsData]
  );

  const handleGetHighestHeightValue = useCallback(
    (refsData) => {
      let highestHeightTitle = 0;

      handleLoopRefsData(refsData, (refCurrent) => {
        const refHeight = refCurrent.offsetHeight;
        if (refHeight > highestHeightTitle) highestHeightTitle = refHeight;
      });

      return highestHeightTitle;
    },
    [handleLoopRefsData]
  );

  return useCallback(
    (refsData) => {
      if (refsData && refsData.every((ref) => ref.current)) {
        // INFO: reset all title el height before get (re-get) the highest height value
        handleResetTitleHeight(refsData);
        // INFO: get the highest height title
        const highestHeightTitle = handleGetHighestHeightValue(refsData);
        // INFO: set the highest height value for all title when found the highest height
        if (highestHeightTitle) {
          handleLoopRefsData(refsData, (refCurrent) => {
            refCurrent.style.height = `${highestHeightTitle}px`;
          });
        }
      }
    },
    [handleLoopRefsData, handleResetTitleHeight, handleGetHighestHeightValue]
  );
};
