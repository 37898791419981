import { ofType } from 'redux-observable';
import { from } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import * as actions from '@redux/actions/getSOCarts';
import * as getSOCartsTypes from '@redux/actions/getSOCarts/getSOCartsTypes';
import { get } from '@services/genericService';

import { removeCurlyBrackets } from '../../bookingExperience/utils';
import * as config from '../config';

export const getSOCartsEpic = (action$) =>
  action$.pipe(
    ofType(getSOCartsTypes.GET_SO_CARTS),
    switchMap((action) => {
      let params = {
        expireddatefrom: action.payload.expireddatefrom,
        expireddateto: action.payload.expireddateto,
        totalfrom: action.payload.totalfrom,
        totalto: action.payload.totalto,
        searchkeyword: action.payload.searchkeyword,
        staffIds: action.payload.staffIds,
        storeIds: action.payload.storeIds,
        statuses : action.payload.statuses,
        categoryIds: action.payload.categoryIds,
        sortby: action.payload.sortby,
        sortdirection: action.payload.sortdirection,
        take: action.payload.take,
        skip: action.payload.skip
      };
      if (action.payload.ContactId) params.ContactId = action.payload.ContactId;
      if (action.payload.take) params.take = action.payload.take;
      if (action.payload.skip) params.skip = action.payload.skip;
      if (action.payload.storeIds) params.storeIds = removeCurlyBrackets(action.payload.storeIds);
      if (action.payload.sortBy) params.SortBy = action.payload.sortBy;
      if (action.payload.sortDirection) params.SortDirection = action.payload.sortDirection;
      // INFO: remove all properties with value is empty
      const getSOCartParams = {};
      for (const [key, value] of Object.entries(params)) {
        if (value?.toString()) getSOCartParams[key] = value;
      }

      return from(get(config.GET_SO_CARTS, getSOCartParams)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getSOCartsSuccess({
              ...res.data
            });
          } else {
            return actions.getSOCartsFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export const getSOCartsActiveEpic = (action$) =>
  action$.pipe(
    ofType(getSOCartsTypes.GET_SO_CARTS_ACTIVE),
    switchMap((action) => {
      let params = {
        statuses : action.payload.statuses,
        loadentities : action.payload.loadentities
      };

      if(action.payload.storeIds !== config.ALLSTOREID) {
        params = {...params, storeIds: action.payload?.storeIds};
      }
      if (action.payload.staffIds) params.staffIds = action.payload.staffIds;

      return from(get(config.GET_SO_CARTS, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getSOCartsActiveSuccess({
              ...res.data
            });
          } else {
            return actions.getSOCartsActiveFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export const getSOCartsDraftEpic = (action$) =>
  action$.pipe(
    ofType(getSOCartsTypes.GET_SO_CARTS_DRAFT),
    switchMap((action) => {
      let params = {
        statuses : action.payload.statuses,
        loadentities : action.payload.loadentities
      };

      if(action.payload.storeIds !== config.ALLSTOREID) {
        params = {...params, storeIds: action.payload?.storeIds};
      }
      if (action.payload.staffIds) params.staffIds = action.payload.staffIds;

      return from(get(config.GET_SO_CARTS, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getSOCartsDraftSuccess({
              ...res.data
            });
          } else {
            return actions.getSOCartsDraftFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export const getSOCartsViewedEpic = (action$) =>
  action$.pipe(
    ofType(getSOCartsTypes.GET_SO_CARTS_VIEWED),
    switchMap((action) => {
      let params = {
        statuses : action.payload.statuses,
        loadentities : action.payload.loadentities
      };

      if(action.payload.storeIds !== config.ALLSTOREID) {
        params = {...params, storeIds: action.payload?.storeIds};
      }
      if (action.payload.staffIds) params.staffIds = action.payload.staffIds;

      return from(get(config.GET_SO_CARTS, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getSOCartsViewedSuccess({
              ...res.data
            });
          } else {
            return actions.getSOCartsViewedFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export const getSOCartsCompletedEpic = (action$) =>
  action$.pipe(
    ofType(getSOCartsTypes.GET_SO_CARTS_COMPLETED),
    switchMap((action) => {
      let params = {
        statuses : action.payload.statuses,
        loadentities : action.payload.loadentities
      };

      if(action.payload.storeIds !== config.ALLSTOREID) {
        params = {...params, storeIds: action.payload?.storeIds};
      }
      if (action.payload.staffIds) params.staffIds = action.payload.staffIds;

      return from(get(config.GET_SO_CARTS, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getSOCartsCompletedSuccess({
              ...res.data
            });
          } else {
            return actions.getSOCartsCompletedFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );

export const getSOCartsExpiredEpic = (action$) =>
  action$.pipe(
    ofType(getSOCartsTypes.GET_SO_CARTS_EXPIRED),
    switchMap((action) => {
      let params = {
        statuses : action.payload.statuses,
        loadentities : action.payload.loadentities
      };

      if(action.payload.storeIds !== config.ALLSTOREID) {
        params = {...params, storeIds: action.payload?.storeIds};
      }
      if (action.payload.staffIds) params.staffIds = action.payload.staffIds;

      return from(get(config.GET_SO_CARTS, params)).pipe(
        map((res) => {
          if (res.status == 200) {
            return actions.getSOCartsExpiredSuccess({
              ...res.data
            });
          } else {
            return actions.getSOCartsExpiredFailed(res.data?.Errors?.[0] || 'Failed');
          }
        })
      );
    })
  );
