export const SET_RANGE_PRICE_POSITION = 'SET_RANGE_PRICE_POSITION';

export const GET_FILTER_PRODUCT_LIST = 'GET_FILTER_PRODUCT_LIST';

export const GET_PRODUCT_LIST = 'GET_PRODUCT_LIST';

export const GET_PRODUCT_LIST_SUCCESS = 'GET_PRODUCT_LIST_SUCCESS';

export const GET_PRODUCT_LIST_FAILED = 'GET_PRODUCT_LIST_FAILED';
// Each action break down one line or give comments

export const GET_DATA = 'GET_DATA';

export const GET_DATA_SUCCESS = 'GET_DATA_SUCCESS';

export const GET_DATA_FAILED = 'GET_DATA_FAILED';

//Product Information
export const GET_PRODUCT_INFORMATION = 'GET_PRODUCT_INFORMATION';

export const GET_PRODUCT_INFORMATION_SUCCESS = 'GET_PRODUCT_INFORMATION_SUCCESS';

export const GET_PRODUCT_INFORMATION_FAILED = 'GET_PRODUCT_INFORMATION_FAILED';

//Product Detail
export const SET_VARIANT_PRODUCT_DETAIL_HEADER = 'SET_VARIANT_PRODUCT_DETAIL_HEADER';

export const SET_PRODUCT_DETAIL_OVERVIEW_ACTIVE = 'SET_PRODUCT_DETAIL_OVERVIEW_ACTIVE';

export const SET_RECENTTLY_PRODUCT = 'SET_RECENTTLY_PRODUCT';

export const CHANGE_RECENTTLY_PRODUCT = 'CHANGE_RECENTTLY_PRODUCT';

export const GET_NOTIFY_STATUS_PRODUCT = 'GET_NOTIFY_STATUS_PRODUCT';

export const RESET_NOTIFY_MESSAGE_POPUP = 'RESET_NOTIFY_MESSAGE_POPUP';

export const GET_NOTIFY_STATUS_PRODUCT_SUCCESS = 'GET_NOTIFY_STATUS_PRODUCT_SUCCESS';

export const GET_NOTIFY_STATUS_PRODUCT_FAILED = 'GET_NOTIFY_STATUS_PRODUCT_FAILED';

export const SET_STATUS_PRODUCT_DETAIL_VARIANT = 'SET_STATUS_PRODUCT_DETAIL_VARIANT';

export const SET_PRODUCT_DETAIL_VARIANT = 'SET_PRODUCT_DETAIL_VARIANT';

export const CHANGE_PRODUCT_DETAIL_VARIANT = 'CHANGE_PRODUCT_DETAIL_VARIANT';

export const SET_PRODUCT_DETAIL_IMAGES = 'SET_PRODUCT_DETAIL_IMAGES';

export const GET_PRODUCT_DETAIL_HEADER = 'GET_PRODUCT_DETAIL_HEADER';

export const GET_PRODUCT_DETAIL_HEADER_SUCCESS = 'GET_PRODUCT_DETAIL_HEADER_SUCCESS';

export const GET_PRODUCT_DETAIL_HEADER_FAILED = 'GET_PRODUCT_DETAIL_HEADER_FAILED';

export const GET_PRODUCT_DETAIL_OVERVIEW = 'GET_PRODUCT_DETAIL_OVERVIEW';

export const GET_PRODUCT_DETAIL_OVERVIEW_SUCCESS = 'GET_PRODUCT_DETAIL_OVERVIEW_SUCCESS';

export const GET_PRODUCT_DETAIL_OVERVIEW_FAILED = 'GET_PRODUCT_DETAIL_OVERVIEW_FAILED';

export const GET_PRODUCT_DETAIL_SINGLE = 'GET_PRODUCT_DETAIL_SINGLE';

export const GET_VARIANT_SELECTED = 'GET_VARIANT_SELECTED';

export const GET_PRODUCT_DETAIL_STATIC_BUNDLE_OVERVIEW = 'GET_PRODUCT_DETAIL_STATIC_BUNDLE_OVERVIEW';

//Product Also Bought
export const GET_CROSS_SALE_ITEM = 'GET_CROSS_SALE_ITEM';

export const GET_CROSS_SALE_ITEM_SUCCESS = 'GET_CROSS_SALE_ITEM_SUCCESS';

export const GET_CROSS_SALE_ITEM_FAILED = 'GET_CROSS_SALE_ITEM_FAILED';

// Free Gift
export const GET_FREE_GIFT = 'GET_FREE_GIFT';

export const GET_FREE_GIFT_SUCCESS = 'GET_FREE_GIFT_SUCCESS';

export const GET_FREE_GIFT_FAILED = 'GET_FREE_GIFT_FAILED';

// Special Deal
export const GET_SPECIAL_DEAL = 'GET_SPECIAL_DEAL';

export const GET_SPECIAL_DEAL_SUCCESS = 'GET_SPECIAL_DEAL_SUCCESS';

export const GET_SPECIAL_DEAL_FAILED = 'GET_SPECIAL_DEAL_FAILED';

// Shipping Option - Buying Otion
export const GET_DEFAULT_LOCATOR = 'GET_DEFAULT_LOCATOR';

export const GET_DEFAULT_LOCATOR_SUCCESS = 'GET_DEFAULT_LOCATOR_SUCCESS';

export const GET_DEFAULT_LOCATOR_FAILED = 'GET_DEFAULT_LOCATOR_FAILED';

export const GET_STORE_LIST = 'GET_STORE_LIST';

export const GET_STORE_LIST_SUCCESS = 'GET_STORE_LIST_SUCCESS';

export const GET_STORE_LIST_FAILED = 'GET_STORE_LIST_FAILED';

export const SET_SELECTED_STORE = 'SET_SELECTED_STORE';

// Warranty
export const GET_WARRANTY = 'GET_WARRANTY';

export const GET_WARRANTY__SUCCESS = 'GET_WARRANTY__SUCCESS';

export const GET_WARRANTY__FAILED = 'GET_WARRANTY__FAILED';

export const SET_ACTIVE_WARRANTY = 'SET_ACTIVE_WARRANTY';

export const SET_SELECTED_WARRANTY = 'SET_SELECTED_WARRANTY';

export const SET_DEFAULT_WARRANTY = 'SET_DEFAULT_WARRANTY';

// Product wishlist
export const GET_PRODUCT_WISH_LIST = 'GET_PRODUCT_WISH_LIST';

export const ADD_PRODUCT_WISH_LIST = 'ADD_PRODUCT_WISH_LIST';

export const GET_PRODUCT_WISH_LIST_USER_ID = 'GET_PRODUCT_WISH_LIST_USER_ID';

export const GET_PRODUCT_WISH_LIST_SUCCESS = 'GET_PRODUCT_WISH_LIST_SUCCESS';

export const ACTION_PRODUCT_WISH_LIST_FAILED = 'ACTION_PRODUCT_WISH_LIST_FAILED';

export const REMOVE_PRODUCT_WISH_LIST = 'REMOVE_PRODUCT_WISH_LIST';

export const REMOVE_PRODUCT_WISH_LIST_SUCCESS = 'REMOVE_PRODUCT_WISH_LIST_SUCCESS';

export const ADD_PRODUCT_WISH_LIST_SUCCESS = 'ADD_PRODUCT_WISH_LIST_SUCCESS';

export const SET_PRODUCT_WISH_LIST_CURRENT = 'SET_PRODUCT_WISH_LIST_CURRENT';

// CART DETAIL
export const ADD_TO_CART = 'ADD_TO_CART';

export const ADD_TO_CART_SUCCESS = 'ADD_TO_CART_SUCCESS';

export const ADD_TO_CART_FAILED = 'ADD_TO_CART_FAILED';

export const ADD_TO_SO_CART = 'ADD_TO_SO_CART';

export const ADD_TO_SO_CART_SUCCESS = 'ADD_TO_SO_CART_SUCCESS';

export const ADD_TO_SO_CART_FAILED = 'ADD_TO_SO_CART_FAILED';

export const GET_CURRENT_CART = 'GET_CURRENT_CART';

export const GET_CURRENT_CART_SUCCESS = 'GET_CURRENT_CART_SUCCESS';

export const GET_CURRENT_CART_FAILED = 'GET_CURRENT_CART_FAILED';

export const GET_CURRENT_SO_CART = 'GET_CURRENT_SO_CART';

export const GET_CURRENT_SO_CART_SUCCESS = 'GET_CURRENT_SO_CART_SUCCESS';

export const GET_CURRENT_SO_CART_FAILED = 'GET_CURRENT_SO_CART_FAILED';

export const GET_GIFTING_OPTION = 'GET_GIFTING_OPTION';

export const GET_GIFTING_OPTION_SUCCESS = 'GET_GIFTING_OPTION_SUCCESS';

export const GET_GIFTING_OPTION_FAILED = 'GET_GIFTING_OPTION_FAILED';

export const REMOVE_GIFTING_OPTION = 'REMOVE_GIFTING_OPTION';

export const SAVE_SO_CART = 'SAVE_SO_CART';

export const SAVE_SO_CART_SUCCESS = 'SAVE_SO_CART_SUCCESS';

export const SAVE_SO_CART_FAILED = 'SAVE_SO_CART_FAILED';

//cp update cp customer
export const UPDATE_CP_CUSTOMER = 'UPDATE_CP_CUSTOMER';

export const UPDATE_CP_CUSTOMER_SUCCESS = 'UPDATE_CP_CUSTOMER_SUCCESS';

export const UPDATE_CP_CUSTOMER_FAILED = 'UPDATE_CP_CUSTOMER_FAILED';

export const IS_PRODUCT_DETAIL = 'IS_PRODUCT_DETAIL';

export const ADD_TO_CART_V2 = 'ADD_TO_CART_V2';

export const TURN_UPDATE_ADDRESS_LOADING_MASK_OFF = 'TURN_UPDATE_ADDRESS_LOADING_MASK_OFF';

export const ADD_TO_SO_CART_V2 = 'ADD_TO_SO_CART_V2';

export const ADD_TO_SO_CART_V2_SUCCESS = 'ADD_TO_SO_CART_V2_SUCCESS';

export const ADD_TO_SO_CART_V2_FAILED = 'ADD_TO_SO_CART_V2_FAILED';