import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXTARR = [
  'Apply Offer Text',
  'Coupon Info Text',
  'Dialog Title',
  'Required Coupon Error Message',
  'Title',
  'To Label',
  'Valid From Label',
  'View All Text',
  'Hint Text',
  'Vaild Until Label',
  'Personalization'
];
const LINKARR = ['Cart Page Link'];

export default class CouponWalletModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    LINKARR.forEach((item) => {
      this[item] = new SitecoreLinkModel();
    });
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    LINKARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    return this;
  }
}
