import SitecoreTextModel from '@models/SitecoreTextModel';

export default class CartSellerModel {
  constructor() {
    this['Apply Button Text'] = new SitecoreTextModel();
    
    this['Edit Button Text'] = new SitecoreTextModel();

    this['Staff ID Label'] = new SitecoreTextModel();

    this['Staff ID Placeholder'] = new SitecoreTextModel();

    this['Store ID Label'] = new SitecoreTextModel();

    this['Store ID Placeholder'] = new SitecoreTextModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Apply Button Text')) {
      this['Apply Button Text'].getData(dataSource['Apply Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Edit Button Text')) {
      this['Edit Button Text'].getData(dataSource['Edit Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Staff ID Placeholder')) {
      this['Staff ID Placeholder'].getData(dataSource['Staff ID Placeholder']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Staff ID Label')) {
      this['Staff ID Label'].getData(dataSource['Staff ID Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Store ID Label')) {
      this['Store ID Label'].getData(dataSource['Store ID Label']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Store ID Placeholder')) {
      this['Store ID Placeholder'].getData(dataSource['Store ID Placeholder']);
    }

    return this;
  }
}
