import './productRecommendation.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { Text } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ProductRecommendationModel from './model/ProductRecommendationModel';
import ProductRecommendationItem from './ProductRecommendationItem';

const ProductRecommendation = (props) => {
  const [inputData, setInputData] = useState(null);

  useEffect(() => {
    const productRecommendationModel = new ProductRecommendationModel();

    setInputData(productRecommendationModel.getData(deepCopy(props.fields) || {}));
  }, []);

  return inputData ? (
    <div className='product-recommendation'>
      <div className='container product-recommendation__wrap'>
        <Text className='product-recommendation__title experience-title' field={inputData.Title} editable={true} tag='h2' />
        <div className='product-recommendation__list'>
          {/* <SwipeComponent param={params}> */}
          {props.productRecommendation.map((item, index) => {
            return (
              <div key={index} className='product-recommendation__list__item'>
                <ProductRecommendationItem dataFromAPI={item} dataFromLayout={inputData} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  ) : '';
};

ProductRecommendation.propTypes = {
  fields: PropTypes.object,
  productRecommendation: PropTypes.array
};

const mapStateToProps = (state) => {
  return {
    productRecommendation: state.productReducer.productInformationReducer.productInformation?.ProductRecommendation || []
  };
};

export default connect(mapStateToProps)(ProductRecommendation);
