import { object } from 'prop-types';
import React, { useMemo } from 'react';

const DiscoverProductButton = ({ fieldData }) => {
  const hasProductContent = useMemo(() => {
    return fieldData?.['HasProductContent'];
  }, [fieldData]);

  const buttonData = useMemo(() => {
    let value = {
      buttonText: fieldData?.['Discover OSIM Products Label']?.value,
      buttonLink: fieldData?.['Discover OSIM Products Link']?.value?.href
    };
    if (hasProductContent) {
      value = {
        buttonLink: fieldData?.['Discover Button Link'],
        buttonText: fieldData?.['Discover Button Label']
      };
    }

    return value;
  }, [hasProductContent, fieldData]);

  return (
    <a href={buttonData.buttonLink} className='product-thank-you-page__bottom__button btn btn-outline-CTA1'>
      {buttonData.buttonText}
    </a>
  );
};

DiscoverProductButton.propTypes = {
  fieldData: object
};

export default DiscoverProductButton;
