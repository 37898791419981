import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { post } from '@services/genericService';

export const getSignatureTokenService = (orderNumber) => {
  return new Promise((resolve, reject) => {
    const params = {
      orderId: orderNumber
    };

    return from(post('/OsimCart/GetSecuritySignature', params))
      .pipe(
        map((response) => {
          if (response.status === 200 || response.data) {
            resolve(response.data);
          }
        }),
        catchError((error) => reject(error || 'Failed'))
      )
      .subscribe();
  });
};
