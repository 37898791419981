import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = [
  'Cancel Button Label',
  'Popup Title',
  'Remove Button Label',
  'Remove Product Label',
  'Popup Description'
];

export default class RemoveProductCTAModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item] ?? this[item];
      }
    });

    return this;
  }
}
