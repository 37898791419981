import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery } from '@utils/customsHook/useQuery';

import { SCHEDULE_APPOINTMENT_PARAM_NAME } from '../FormikStep/configs';

export const useNavigateNextStep = () => {
  const query = useQuery();
  const history = useHistory();

  return useCallback(
    (stepName) => {
      // INFO: update query string using react-router
      query.set(SCHEDULE_APPOINTMENT_PARAM_NAME, stepName);

      history.push({ search: query.toString() });
    },
    [query]
  );
};
