import { any, bool, object, string } from 'prop-types';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import { useSelector } from 'react-redux';
import Select from 'react-select';

import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useFormatEnDate } from '@utils/customsHook/useFormatDate';
import global from '@utils/global';

const CPDOB = ({ setFieldValue, disabled, currentDOB, isUseForCP = false }) => {
  const [dayOptions, setDayOptions] = useState(null);
  const [monthOptions, setMonthOptions] = useState(null);
  const [yearOptions, setYearOptions] = useState(null);
  const [fullDoB, setFullDoB] = useState(false);
  const yearRef = useRef(null);
  const monthRef = useRef(null);
  const dayRef = useRef(null);
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);
  const formatEnDate = useFormatEnDate();
  let day = 1;
  let month = 1;
  let year = 1900;

  const convertCurrentDate = useMemo(() => {
    let currentDataValue = global.convertDateTimeFromString(currentDOB);
    if (isUseForCP) {
      const customCurrentDate = formatEnDate({
        date: currentDOB
      });

      currentDataValue = global.convertDateTimeFromString(customCurrentDate, 'en');
    }

    return currentDataValue;
  }, [currentDOB, isUseForCP]);

  const [selectedDay, setSelectedDay] = useState(null);

  const [selectedMonth, setSelectedMonth] = useState(null);

  const [selectedYear, setSelectedYear] = useState(null);

  useEffect(() => {
    if (convertCurrentDate && !selectedDay && !selectedMonth && !selectedYear) {
      if (convertCurrentDate.day.value) setSelectedDay(convertCurrentDate.day);
      if (convertCurrentDate.month.value) setSelectedMonth(convertCurrentDate.month);
      if (convertCurrentDate.year.value) setSelectedYear(convertCurrentDate.year);
    }
  }, [convertCurrentDate]);

  useEffect(() => {
    let optionDay = [];
    let optionMonth = [];
    let optionYear = [];
    let date = new Date();
    for (day; day <= 31; day++) {
      let obj = {};

      obj.label = day;

      obj.value = day;

      optionDay.push(obj);
    }
    for (month; month <= 12; month++) {
      let obj = {};

      obj.label = month;

      obj.value = month;

      optionMonth.push(obj);
    }
    for (year; year <= date.getFullYear(); year++) {
      let obj = {};

      obj.label = year;

      obj.value = year;

      if (date.getFullYear() - year >= 18) optionYear.push(obj);
    }

    setDayOptions(optionDay);

    setMonthOptions(optionMonth);

    setYearOptions(optionYear.reverse());
  }, []);

  useEffect(() => {
    selectedDay && selectedMonth ? setFullDoB(true) : setFullDoB(false);
  }, [selectedDay, selectedMonth]);

  useDidUpdateEffect(() => {
    if (selectedDay && selectedMonth) {
      fullDoB
        ? setFieldValue({
          dob: selectedYear
            ? global.formatToISOStringHour(selectedDay, selectedMonth, selectedYear)
            : global.formatToISOStringHour(selectedDay, selectedMonth)
        })
        : setFieldValue({ dob: '' });
    } else setFieldValue({ dob: '' });
  }, [fullDoB, selectedDay, selectedMonth, selectedYear]);

  const onCountLeapYearHandler = (year) => {
    year = parseInt(year);
    if (year % 400 == 0) return true;
    if (year % 4 == 0 && year % 100 != 0) return true;

    return false;
  };

  const onChangeYear = (year) => {
    let optionDay = [];
    if (onCountLeapYearHandler(year.value)) {
      if (monthRef.current.state.value?.value === 2) {
        for (day; day <= 29; day++) {
          let obj = {};

          obj.label = day;

          obj.value = day;

          optionDay.push(obj);
        }

        setDayOptions(optionDay);
      }
    } else {
      if (monthRef.current.state.value?.value === 2) {
        if (dayRef.current.state.value?.value === 29) {
          setSelectedDay(null);
        }
        for (day; day <= 28; day++) {
          let obj = {};

          obj.label = day;

          obj.value = day;

          optionDay.push(obj);
        }

        setDayOptions(optionDay);
      }
    }

    setSelectedYear(year);
  };

  const onChangeMonth = (month) => {
    let optionDay = [];
    if (month.value === 2) {
      if (JSON.stringify(yearRef.current.state.value?.value)?.length) {
        if (onCountLeapYearHandler(yearRef.current.state.value?.value)) {
          selectedDay?.value > 29 && setSelectedDay(null);
          for (day; day <= 29; day++) {
            let obj = {};

            obj.label = day;

            obj.value = day;

            optionDay.push(obj);
          }
        } else {
          selectedDay?.value > 28 && setSelectedDay(null);
          for (day; day <= 28; day++) {
            let obj = {};

            obj.label = day;

            obj.value = day;

            optionDay.push(obj);
          }
        }
      } else {
        selectedDay?.value > 29 && setSelectedDay(null);
        for (day; day <= 29; day++) {
          let obj = {};

          obj.label = day;

          obj.value = day;

          optionDay.push(obj);
        }
      }
    } else if (month.value === 4 || month.value === 6 || month.value === 9 || month.value === 11) {
      selectedDay?.value > 30 && setSelectedDay(null);
      for (day; day <= 30; day++) {
        let obj = {};

        obj.label = day;

        obj.value = day;

        optionDay.push(obj);
      }
    } else {
      for (day; day <= 31; day++) {
        let obj = {};

        obj.label = day;

        obj.value = day;

        optionDay.push(obj);
      }
    }

    setDayOptions(optionDay);

    setSelectedMonth(month);
  };

  const onChangeDay = (day) => {
    setSelectedDay(day);
  };

  return dayOptions && monthOptions && yearOptions ? (
    <>
      <div className='cp-form__group__item'>
        <Select
          id='day'
          name='dob'
          onChange={(day) => onChangeDay(day)}
          ref={dayRef}
          value={selectedDay}
          options={dayOptions}
          placeholder={messageObj['LBL-Day']}
          isSearchable={true}
          className='cp-select'
          classNamePrefix='cp-select'
          isDisabled={disabled}
        />
      </div>
      <div className='cp-form__group__item'>
        <Select
          id='month'
          name='dob'
          onChange={(month) => onChangeMonth(month)}
          ref={monthRef}
          value={selectedMonth}
          options={monthOptions}
          placeholder={messageObj['LBL-Month']}
          isSearchable={true}
          className='cp-select'
          classNamePrefix='cp-select'
          isDisabled={disabled}
        />
      </div>
      <div className='cp-form__group__item'>
        <Select
          id='year'
          name='dob'
          onChange={(option) => onChangeYear(option)}
          ref={yearRef}
          value={selectedYear}
          options={yearOptions}
          placeholder={messageObj['LBL-Year']}
          isSearchable={true}
          className='cp-select'
          classNamePrefix='cp-select'
          isDisabled={disabled}
        />
      </div>
    </>
  ) : (
    <></>
  );
};

CPDOB.propTypes = {
  setFieldValue: any,
  disabled: bool,
  currentDOB: string,
  isUseForCP: bool
};

export default CPDOB;
