import { format } from 'date-fns';
import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import {
  createBookingExperienceContactFailed,
  createBookingExperienceContactSuccess
} from '@redux/actions/bookingExperience/createBookingExperienceContact';
import { CREATE_BOOKING_EXPERIENCE_CONTACT_START } from '@redux/actions/bookingExperience/createBookingExperienceContact/types';
import { scheduleAppointmentStart } from '@redux/actions/bookingExperience/scheduleAppointment';
import { postAsObservable } from '@services/genericService';
import { DEFAULT_LAST_NAME_API_PAYLOAD_VALUE } from '@utils/constant';
import { formatDateToUTC } from '@utils/dateFormatV2';
import { deepCopy } from '@utils/utility';

import { addCurlyBrackets } from '../utils';
import { CREATE_BOOKING_EXPERIENCE_CONTACT_ENDPOINT } from './config';
import { CreateScheduleContactStatus } from './responseStatus';

const createBookingExperienceContactEpic = (action$) =>
  action$.pipe(
    ofType(CREATE_BOOKING_EXPERIENCE_CONTACT_START),
    switchMap((action) => {
      const params = {
        ProductId: addCurlyBrackets(action.payload.productId),
        FirstName: action.payload.firstName,
        LastName: action.payload.lastName || DEFAULT_LAST_NAME_API_PAYLOAD_VALUE,
        DateOfBirth: action.payload.dob ? formatDateToUTC(action.payload.dob) : '',
        EmailAddress: action.payload.emailAddress,
        MobileNumber: action.payload.mobileNumber
      };

      return from(
        postAsObservable(CREATE_BOOKING_EXPERIENCE_CONTACT_ENDPOINT, params).pipe(
          switchMap((res) => {
            if (CreateScheduleContactStatus.SuccessStatusCode.includes(res.data.StatusCode)) {
              return of(
                createBookingExperienceContactSuccess(deepCopy(res.data)),
                scheduleAppointmentStart({
                  ...action.payload,
                  contactId: addCurlyBrackets(res.data.Result.Id)
                })
              );
            } else {
              return of(createBookingExperienceContactFailed(res.data));
            }
          })
        )
      );
    }),
    catchError((error) => of(createBookingExperienceContactFailed(error.message || 'Failed')))
  );

export default createBookingExperienceContactEpic;
