import './footerNavigation.scss';

import produce from 'immer';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Collapse } from 'react-bootstrap';

import FooterNavigationItem from './FooterNavigationItem';
import FooterNavigationList from './FooterNavigationItem/FooterNavigationList';
import FooterNavigationTitle from './FooterNavigationItem/FooterNavigationTitle';

const FooterNavigation = (props) => {
  const [data, setData] = useState(props.fields?.items || null);

  const onControlCollapseHandler = (param) => {
    let nextState = produce(data, (draft) => {
      draft.map((item, index) => {
        if (param.data.id === item.id) {
          draft[index] = param.data;
        }
      });
    });

    setData(nextState);
  };

  return data ? (
    <div className='footer-navigation support'>
      <div className='container'>
        <div className='footer-navigation--mobile'>
          {data.map((item) => {
            return (
              <FooterNavigationItem key={item.id}>
                <FooterNavigationTitle data={item} isCollapsed={onControlCollapseHandler} />
                <Collapse in={item.isOpen ? true : false}>
                  <ul className='footer-navigation__options'>
                    {item.fields.items.map((item) => {
                      return <FooterNavigationList key={item.id} data={item} />;
                    })}
                  </ul>
                </Collapse>
              </FooterNavigationItem>
            );
          })}
        </div>
        <div className='footer-navigation--desktop'>
          <div className='row'>
            {data.map((item) => {
              return (
                <FooterNavigationItem key={item.id}>
                  <div className='col-3'>
                    <FooterNavigationTitle data={item} isCollapsed={onControlCollapseHandler} />
                    <ul className='footer-navigation__options'>
                      {item.fields.items.map((item) => {
                        return <FooterNavigationList key={item.id} data={item} />;
                      })}
                    </ul>
                  </div>
                </FooterNavigationItem>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  ) : '';
};

FooterNavigation.propTypes = {
  fields: PropTypes.object
};

export default FooterNavigation;
