import PropTypes from 'prop-types';
import React from 'react';
import { QuestionItem } from './QuestionItem/QuestionItem'
import { QuestionsStep2 } from './QuestionsStep2/QuestionsStep2'

export const QuestionStep = ({ item, chooseAnswer, nextStep, gotoFirstStep, preStep, step, chooseQuestions, dataFromLayout }) => {
  const { Answers, Title, Type } = item;
  
  const isDisableNext = () => {
    if (step === 1 && chooseQuestions?.step1?.activeAnswers?.length > 0) {
      return false
    }
    else if (step === 2 && chooseQuestions?.step2?.activeAnswers?.length > 0) {
      return false
    }
    else if (step === 3 && chooseQuestions?.step3?.activeAnswers?.length > 0) {
      return false
    }
    else {
      return true
    }
  }

  const isActiveAnswer = (id) => {
    if (step === 1 && chooseQuestions?.step1?.activeAnswers?.includes(id)) {
      return true
    }
    else if (step === 3 && chooseQuestions?.step3?.activeAnswers?.includes(id)) {
      return true
    }
    else {
      return false
    }
  }

  return (
    <>
      <div className='help-me-decide__question'>
        <h3 className='help-me-decide__question__text'>{Title}</h3>
      </div>
      <div className={`help-me-decide__anser ${step === 2 ? 'help-me-decide__anser--step2' : ''}`}>
        {step === 2 ? <QuestionsStep2 chooseAnswer={chooseAnswer} chooseQuestions={chooseQuestions} step={step} Answers={Answers} />
          : <>
            {Answers?.length > 0 && Answers.map((answer, index) => {
              return (
                <QuestionItem key={index} answer={answer} step={step} chooseAnswer={chooseAnswer} Type={Type} active={isActiveAnswer(answer.ID)} />
              )
            })}
          </>}
      </div>
      <div className='help-me-decide__button'>
        {gotoFirstStep && <button className='btn btn-link help-me-decide__button__left' onClick={gotoFirstStep}>{dataFromLayout['Start Again Text']?.value}</button>}
        {/* {preStep && <button className='btn btn-link help-me-decide__button__left' onClick={preStep}>{dataFromLayout['Previous Button Text']?.value}</button>} */}
        {nextStep && <button className={`btn btn-link help-me-decide__button__right ${isDisableNext() && 'disabled'}`} onClick={nextStep} disabled={isDisableNext()}>{dataFromLayout['Next Button text']?.value}</button>}
      </div>
      {/* <div className='text-center'>
        {gotoFirstStep && <button className='btn btn-primary help-me-decide__button__left' onClick={gotoFirstStep}>{dataFromLayout['Start Again Text']?.value}</button>}
      </div> */}
    </>
  );
};

QuestionStep.propTypes = {
  key: PropTypes.string,
  item: PropTypes.object,
  chooseAnswer: PropTypes.func,
  nextStep: PropTypes.func,
  gotoFirstStep: PropTypes.func,
  preStep: PropTypes.func,
  step: PropTypes.number,
  chooseQuestions: PropTypes.object,
  dataFromLayout: PropTypes.any
};


