import { createContext, useContext } from 'react';

const initialStates = {
  selectedFreeGift: [],
  setSelectedFreeGift: () => {},
  freeGiftFromCartLines: [],
  editingFreeGift: [],
  setEditingFreeGift: () => {},
  freeGiftDataSort: [],
  setFreeGiftDataSort: () => {}
};

export const SelectingFreeGiftContext = createContext(initialStates);

const useSelectingFreeGift = () => {
  return useContext(SelectingFreeGiftContext);
};

export default useSelectingFreeGift;
