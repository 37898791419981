import { object } from 'prop-types';
import React from 'react';

import { Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { useWindowSize } from '@utils/customsHook/useWindowSize';

const ViewCartButton = ({ layoutData }) => {
  const windowSize = useWindowSize();

  return (
    <>
      <RichText
        tag={windowSize <= IPAD_BREAKPOINT ? 'p' : ''}
        field={layoutData?.['Description 2']}
        className='abandoned-card-widget__content-description mt-8px'
      />
      <Link field={layoutData?.['Button View Cart']} className='btn btn-primary view-cart-button'>
        <Text field={layoutData?.['Button Label']} />
      </Link>
    </>
  );
};

ViewCartButton.propTypes = {
  layoutData: object
};

export default ViewCartButton;
