export default class InstallmentPeriodsModel {
  constructor() {
    this.Period = 0;
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Period')) {
      this['Period'] = dataSource['Period'] ?? this.Period;
    }

    return this;
  }
}
