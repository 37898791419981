import { object } from 'prop-types';
import React from 'react';
import Select from 'react-select';

import { Text } from '@sitecore-jss/sitecore-jss-react';

const SelectStatus = ({
  formik,
  layoutData
}) => {
  const leadStatus = [
    {
      value: 0,
      label: layoutData?.['New Status Label']?.value
    },
    {
      value: 4,
      label: layoutData?.['Never Pick Up Status Label']?.value
    },
    {
      value: 5,
      label: layoutData?.['AppointmentConfirmed Label']?.value
    },
    {
      value: 2,
      label: layoutData?.['Reschedule Status Label']?.value
    },
    {
      value: 3,
      label: layoutData?.['Trial Complete Status Label']?.value
    },
    {
      value: 1,
      label: layoutData?.['Lost Status Label']?.value
    }
  ];
  
  const handleSetStatusSelected = () => {
    return leadStatus.find((item) => item.value === formik.values.status);
  };
  
  return (
    <>
      <dl className='appointments-infor__item'>
        <Text field={layoutData?.['Status Lable']} tag='dt' className='appointments-infor__item__label w-65'/>
        <dd className='appointments-infor__item__value appointments-status--blue'>
          <Select
            defaultValue={handleSetStatusSelected()}
            isSearchable={false}
            options={leadStatus}
            className='customization-dropdown'
            onChange={(option) => formik.setFieldValue('status', option.value)}
            classNamePrefix='customization-dropdown'/>
        </dd>
        {formik.errors.status && formik.touched.status ? (
          <span className='error-validate'>{formik.errors.status}</span>
        ) : (
          <></>
        )}
      </dl>
      <dl className='appointments-infor__item edit-appointment-form__item-textarea'>
        <textarea
          placeholder={layoutData?.['Note Lable']?.value}
          className='textarea-box'
          value={formik.values.note}
          id='note'
          name='note'
          onChange={formik.handleChange}
        ></textarea>
        {formik.errors.note && formik.touched.note ? (
          <span className='error-validate'>{formik.errors.note}</span>
        ) : (
          <></>
        )}
      </dl>
    </>
  );
};

SelectStatus.propTypes = {
  formik: object,
  layoutData: object
};

export default SelectStatus;