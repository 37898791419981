import ShowSalesTaxModel from './ShowSalesTaxModel';

export default class FieldsModel {
  constructor() {
    this['Show Sales Tax'] = new ShowSalesTaxModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Show Sales Tax')) {
      this['Show Sales Tax'].getData(dataSource['Show Sales Tax']);
    }

    return this;
  }
}
