import LinkModel from './LinkModel';

export default class SitecoreLinkModel {
  constructor() {
    this.value = new LinkModel();

    this.editable = '';
  }
  getData(dataSource, params) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'value')) {
      this.value.getData(dataSource.value ?? {}, params);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'editable')) {
      this.editable = dataSource.editable ?? this.editable;
    }
    
    return this;
  }
}
