import React from 'react';
import { useSelector } from 'react-redux';

import { SG_SITE_NAME } from '@utils/constant';
import { useIsAtSiteName } from '@utils/customsHook/useIsAtSiteName';

export const QuestionTooltipIcon = () => {
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const isAtSGCountry = useIsAtSiteName(SG_SITE_NAME);

  return settingGlobal?.['TooltipIcon'] && isAtSGCountry ? (
    <div className='item-tooltip question-tooltip-icon'>
      <i className='question-tooltip-icon__wrapper'>
        <img src={settingGlobal.TooltipIcon} alt='question tooltip icon' className='icon-img' />
      </i>
      <div
        className='item-tooltip__label'
        dangerouslySetInnerHTML={{ __html: settingGlobal.TooltipContent || '' }}
      ></div>
    </div>
  ) : (
    <></>
  );
};