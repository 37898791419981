import { useEffect, useRef } from 'react';

export const useDeepEffect = (effectFunc, deps) => {
  const isFirstRender = useRef(true);
  const stringifyDeps = JSON.stringify(deps).toLowerCase();
  const prepDeps = useRef(stringifyDeps);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;

      return effectFunc();
    }
    const isChangedDeps = stringifyDeps !== prepDeps?.current;
    if (isChangedDeps) {
      effectFunc();

      prepDeps.current = stringifyDeps;
    }
  }, deps);
};
