import produce from 'immer';

import * as config from '@components/CategoryLanding/ProductListing/config';
import ProductListingApiModel from '@components/CategoryLanding/ProductListing/model/ProductListingApiModel';
import * as helpMeDecideTypes from '../../actions/helpMeDecide/types';
import { deepCopy } from '@utils/utility';

const initState = {
	productList: null,
	facets: null,
	filterLastest: null,
	error: '',
	isLoading: false,
	rangePosition: {}
};
const parseUrlParams = (url) => {
	const queryString = url.split('?')[1];

	if (!queryString) {
		return {};
	}

	const paramsArray = queryString.split('&');
	const params = {};

	paramsArray.forEach(param => {
		const [key, value] = param.split('=');

		if (key && value) {
			const decodedValue = decodeURIComponent(value);

			if (key.startsWith('ids')) {
				const id = decodedValue.replace(/\{|\}/g, '');

				if (!params.ids) {
					params.ids = [];
				}
				params.ids.push(id);
			} else if (key.startsWith('Colors')) {
				if (!params.Colors) {
					params.Colors = [];
				}

				params.Colors.push(decodedValue);
			}
			else if (key.startsWith('Features')) {
				if (!params.Features) {
					params.Features = [];
				}

				params.Features.push(decodedValue);
			}
			else if (key.startsWith('areasOfConcern')) {
				if (!params.areasOfConcern) {
					params.areasOfConcern = [];
				}

				params.areasOfConcern.push(decodedValue);
			} else {
				params[key] = decodedValue;
			}
		}
	});

	return { params };
}
export const helpMeDecideReducer = (state = initState, action) =>
	produce(state, (draft) => {
		let tempProductList = null;
		switch (action.type) {
			case helpMeDecideTypes.GET_HELP_ME_DECIDE:
				draft.isLoading = true;
				break;

			case helpMeDecideTypes.GET_HELP_ME_DECIDE_SUCCESS:
				tempProductList = new ProductListingApiModel().getData(deepCopy(action.payload.responses.data));
				draft.productList = tempProductList.ChildProducts;
				draft.TotalItems = tempProductList.TotalItems;
				if (tempProductList.Facets?.length) {
					if (action.payload.isFilter) {
						const paramsRequest = parseUrlParams(action.payload.responses.config.url)?.params
						draft.filterLastest = { ...paramsRequest, Price: { min: Number(paramsRequest.PriceFrom), max: paramsRequest.PriceFrom !== paramsRequest.PriceTo ? Number(paramsRequest.PriceTo) : Number(paramsRequest.PriceTo) }, PriceFrom: Number(paramsRequest.PriceFrom), PriceTo: Number(paramsRequest.PriceTo) || 0 };
					}
					else {
						const maxPriceList = tempProductList.Facets.find(item => item.Name === config.STEP_RANGE_MAX_PRICE);
						const minPriceList = tempProductList.Facets.find(item => item.Name === config.STEP_RANGE_MIN_PRICE);

						const mathMaxPriceList = Math.max(...maxPriceList.Details.map(price => +price.Value));
						const mathMinPriceList = Math.min(...minPriceList.Details.map(price => +price.Value));

						draft.filterLastest = null;

						draft.facets = {
							Facets: tempProductList.Facets,
							Price: {
								min: mathMinPriceList,
								max: mathMaxPriceList !== mathMinPriceList ? mathMaxPriceList : mathMaxPriceList + 1,
							}
						};
					}

				}

				draft.isLoading = false;
				break;

			case helpMeDecideTypes.GET_HELP_ME_DECIDE_FAILED:
				draft.isLoading = false;

				draft.productList = {};

				draft.error = deepCopy(action.error);
				break;

			case helpMeDecideTypes.SET_RANGE_PRICE_POSITION:
				draft.rangePosition = action.payload;
		}

		return draft;
	});

