import './ThreeColumnBanner.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import Loading from '@components/Share/Loading';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import { Image, Link } from '@sitecore-jss/sitecore-jss-react';
import { deepCopy } from '@utils/utility';

import ThreeColumnBannerFieldsModel from './model/ThreeColumnBannerFieldsModel';

const ThreeColumnBanner = (props) => {
  const { fields } = props;
  const [threeColumnBannerFields, setThreeColumnBannerFields] = useState(null);

  useEffect(() => {
    const threeColumnBannerFieldsModel = new ThreeColumnBannerFieldsModel();

    setThreeColumnBannerFields(threeColumnBannerFieldsModel.getData(deepCopy(fields || {})));
  }, []);

  return threeColumnBannerFields && threeColumnBannerFields.Images.length ? (
    <div className='three-column__banner container'>
      <div className='three-column__banner__container'>
        <div className='three-column__banner__row'>
          {threeColumnBannerFields.Images.map((item) => (
            <div className='three-column__banner__box' key={item.id}>
              <Link className='three-column__banner__item' field={item.fields.TargetUrl}>
                {item.fields.Image.value.src ? <Image media={item.fields.Image} alt='image' /> : <></>}
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  ) : (
    <LoadingShimmer itemNumb={1}>
      <div className='main-banner skeleton__item'>
        <div className='main-banner__background main-banner__background--white'>
          <Loading></Loading>
        </div>
      </div>
    </LoadingShimmer>
  );
};

ThreeColumnBanner.propTypes = {
  fields: PropTypes.any
};

export default ThreeColumnBanner;
