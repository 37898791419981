import produce from 'immer';

import * as cartTypes from '@redux/actions/cartDetail/cartTypes';
import * as cartDetailTypes from '@redux/actions/cartDetail/cartTypes';
// Import action types
import * as productTypes from '@redux/actions/product/productTypes';
import { DELIVERY_OPTION } from '@utils/constant';
import { deepCopy } from '@utils/utility';

import {CHANGE_DELIVERY_OPTION} from '../../../actions/cartDetail/cartTypes';
import BundleBOSubLinesItemModel from '../../buyingOptionsBundle/model/BundleBOSubLinesItemModel';

const initState = {
  currentCart: null,
  defaultShippingAddressStatus: false,
  cartStripAds: null,
  currentCartCustom: null,
  isLoading: false,
  error: '',
  temporalCart: {},
  promotionData: null,
  errorUpdateCart: '',
  errorStripAdsCart: '',
  statusUpdateCart: '',
  cartSellerStatus: true,
  checkShippingArea: false,
  errorItemId: '',
  giftingOptions: null,
  isUpdateContactInterestSuccess: false,
  isProductDetail: false,
  blockInstallment: false,
  showInstallmentBlocker: false,
  DepositListProduct: [],
  NormalListProduct: [],
  Deposit: null,
  isDeposit: false,
  isGetCartDelivery: false,
  isGettingCartDelivery: false,
  isSaveSO: false,
  isDoneGetSOCart: false,
  isLoadingCustomCart: true,
  isDoneAddToSOCart: false,
  SOCartSubLines: null,
  EditQuantity: null,
  isChangedDeliveryOption: false
};

export const checkCartValid = (cart) => {
  let notDelivery = cart.Lines.filter(item => !item.IsDelivery && !item.IsDeliveryBySFExpress);

  // let deliveryStatus = (cart.IsDeliveryFee && cart.Party && cart.Party.FirstName !== '') || !cart.IsDeliveryFee;
  let deliveryStatus = (cart.IsDeliveryFee && cart.Lines.find((line) => line?.DeliveryOptionId == line?.DeliveryOptions.find((option) => option?.ShippingOptionType?.Value == DELIVERY_OPTION.ShipItem)?.ExternalId)?.Address?.FirstName !== '') || !cart.IsDeliveryFee;

  let deliveryBySFExpressStatus = (cart.IsDeliveryBySFExpressFee && cart.Lines.find((line) => line?.DeliveryOptionId == line?.DeliveryOptions.find((option) => option?.ShippingOptionType?.Value == DELIVERY_OPTION.DeliveryBySFExpress)?.ExternalId)?.Address?.FirstName !== '') || !cart.IsDeliveryBySFExpressFee;

  let storeStatus = notDelivery.map((item) => {
    // return item.Address.ExpressStoreId !== '';
    return item?.Address?.Address1 !== '';
  }).every(item => item);

  let billingStatus = cart.BillingAddress && cart.BillingAddress.FirstName !== '';

  return deliveryStatus && storeStatus && deliveryBySFExpressStatus && billingStatus;
};

const getIdItemError = (lines) => {
  return lines.find(item => {
    return item.MissingShipping;
  })?.ExternalCartLineId || '';
};

const cartDetailReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.ADD_TO_CART:
      break;

    case productTypes.ADD_TO_CART_SUCCESS:
      draft.temporalCart = {};
      break;

    case productTypes.ADD_TO_CART_FAILED:
      draft.error = action.error;
      break;

    case productTypes.ADD_TO_SO_CART:
      draft.isDoneAddToSOCart = false;

      draft.isLoading = true;
      break;

    case productTypes.ADD_TO_SO_CART_SUCCESS:
      draft.temporalCart = {};

      draft.isLoading = false;

      draft.isDoneAddToSOCart = true;
      break;

    case productTypes.ADD_TO_SO_CART_FAILED:
      draft.error = action.error;

      draft.isLoading = false;

      draft.isDoneAddToSOCart = false;
      break;

    case cartTypes.UPDATE_CART_LINE:
      draft.isLoading = true;

      draft.errorUpdateCart = '';

      draft.statusUpdateCart = '';
      break;

    case cartTypes.UPDATE_CART_LINE_SUCCESS:
      draft.isLoading = false;

      draft.isLoadingCustomCart = false;

      draft.currentCart = deepCopy(action.payload);

      draft.currentCartCustom = {
        Delivery: action.payload.Lines.filter((item) => item.IsDelivery),
        PickUpStore: action.payload.Lines.filter((item) => !item.IsDelivery && !item.IsSFexpress),
        SFexpress: action.payload.Lines.filter((item) => item.IsSFexpress),
        DepositListProduct: action.payload.Lines.filter((item) => item?.Deposit),
        NormalListProduct: action.payload.Lines.filter((item) => item?.Deposit === null)
      };
      break;

    case cartTypes.UPDATE_CART_LINE_FAILED:
      draft.isLoading = false;

      draft.errorUpdateCart = action.error.errorMessage;

      draft.statusUpdateCart = action.error.failureCode;
      break;

    case cartTypes.UPDATE_SO_CART_LINE:
      draft.isLoading = true;

      draft.errorUpdateCart = '';

      draft.statusUpdateCart = '';

      draft.isDoneAddToSOCart = false;
      break;

    case cartTypes.UPDATE_SO_CART_LINE_SUCCESS:
      draft.isLoading = false;

      draft.currentCart = deepCopy(action.payload);

      draft.currentCartCustom = {
        Delivery: action.payload.Lines.filter((item) => item.IsDelivery),
        PickUpStore: action.payload.Lines.filter((item) => !item.IsDelivery && !item.IsSFexpress),
        SFexpress: action.payload.Lines.filter((item) => item.IsSFexpress)
      };

      draft.isDoneAddToSOCart = true;
      break;

    case cartTypes.UPDATE_SO_CART_LINE_FAILED:
      draft.isLoading = false;

      draft.isLoadingCustomCart = false;

      draft.errorUpdateCart = action.error.errorMessage;

      draft.statusUpdateCart = action.error.failureCode;

      draft.isDoneAddToSOCart = false;
      break;

    case cartTypes.REMOVE_CART_LINE:
      draft.errorUpdateCart = '';

      draft.statusUpdateCart = '';

      draft.isLoading = true;
      break;

    case cartTypes.REMOVE_CART_LINE_SUCCESS:
      draft.isLoading = false;

      draft.currentCart = action.payload;

      draft.currentCartCustom = {
        Delivery: action.payload.Lines.filter((item) => item.IsDelivery),
        NormalListProduct: action.payload.Lines.filter((item) => item?.Deposit === null),
        PickUpStore: action.payload.Lines.filter((item) => !item.IsDelivery && !item.IsSFexpress && !item?.IsDeliveryBySFExpress),
        SFexpress: action.payload.Lines.filter((item) => item.IsSFexpress),
        DeliveryBySFExpress: action.payload.Lines.filter((item) => item.IsDeliveryBySFExpress),
        BillingAddress: action.payload.BillingAddress,
        Party: action.payload.Party,
        IsDeliveryFee: action.payload.Lines.filter((line) => line.IsDelivery).length ? true : false,
        IsDeliveryBySFExpressFee: action.payload.IsDeliveryBySFExpressFee,
        Staff: action.payload.Staff,
        isCartValid: checkCartValid(action.payload),
        SFExpressTotal: action.payload.SFExpressTotal,
        DepositListProduct: action.payload.Lines.filter((item) => item?.Deposit)
      };
      break;

    case cartTypes.REMOVE_CART_LINE_FAILED:
      draft.isLoading = false;

      draft.errorUpdateCart = action.error.errorMessage;
      break;

    case cartTypes.REMOVE_SO_CART_LINE:
      draft.errorUpdateCart = '';

      draft.statusUpdateCart = '';

      draft.isLoading = true;
      break;

    case cartTypes.REMOVE_SO_CART_LINE_SUCCESS:
      draft.isLoading = false;

      draft.currentCart = action.payload;
      break;

    case cartTypes.REMOVE_SO_CART_LINE_FAILED:
      draft.isLoading = false;

      draft.errorUpdateCart = action.error.errorMessage;
      break;

    case productTypes.GET_CURRENT_SO_CART:
      draft.isLoading = true;

      draft.isDoneGetSOCart = false;
      break;

    case productTypes.GET_CURRENT_SO_CART_SUCCESS:
      draft.isLoading = false;

      draft.currentCart = deepCopy(action.payload);

      draft.defaultShippingAddressStatus = true;

      draft.isDoneGetSOCart = true;

      draft.currentCartCustom = {
        Delivery: action.payload.Lines.filter((item) => item.IsDelivery),
        NormalListProduct: action.payload.Lines.filter((item) => !item?.Deposit),
        PickUpStore: action.payload.Lines.filter((item) => !item.IsDelivery && !item.IsSFexpress && !item?.IsDeliveryBySFExpress),
        SFexpress: action.payload.Lines.filter((item) => item.IsSFexpress),
        DeliveryBySFExpress: action.payload.Lines.filter((item) => item.IsDeliveryBySFExpress),
        BillingAddress: action.payload.BillingAddress,
        Party: action.payload.Party,
        IsDeliveryFee: action.payload.Lines.filter((line) => line.IsDelivery).length ? true : false,
        IsDeliveryBySFExpressFee: action.payload.IsDeliveryBySFExpressFee,
        Staff: action.payload.Staff,
        isCartValid: checkCartValid(action.payload),
        SFExpressTotal: action.payload.SFExpressTotal,
        DepositListProduct: action.payload.Lines.filter((item) => item?.Deposit),
        CartStatus: action.payload.CartStatus,
        CustomerName: action.payload.CustomerName
      };

      draft.Deposit = deepCopy(action.payload?.Lines[0]?.Deposit);

      draft.isGettingCartDelivery = false;

      if (action.filteredCartLine?.length) {
        let newSubLines = [];

        action.filteredCartLine[0].SubLines.map((element, elementIndex) => {
          newSubLines.push(new BundleBOSubLinesItemModel().getData(deepCopy(element) || {}, elementIndex));
        });

        draft.SOCartSubLines = newSubLines;

        draft.EditQuantity = +action.filteredCartLine[0].Quantity;
      }
      break;

    case productTypes.GET_CURRENT_SO_CART_FAILED:
      draft.isLoading = false;

      draft.isGettingCartDelivery = false;

      draft.isDoneGetSOCart = true;
      break;

    case productTypes.GET_CURRENT_CART:
      draft.isLoading = true;
      break;

    case productTypes.GET_CURRENT_CART_SUCCESS:
      draft.isLoading = false;

      draft.isLoadingCustomCart = false;

      draft.currentCart = deepCopy(action.payload);

      draft.defaultShippingAddressStatus = true;

      draft.currentCartCustom = {
        Delivery: action.payload.Lines.filter((item) => item.IsDelivery),
        NormalListProduct: action.payload.Lines.filter((item) => item?.Deposit === null),
        PickUpStore: action.payload.Lines.filter((item) => !item.IsDelivery && !item.IsSFexpress && !item?.IsDeliveryBySFExpress),
        SFexpress: action.payload.Lines.filter((item) => item.IsSFexpress),
        DeliveryBySFExpress: action.payload.Lines.filter((item) => item.IsDeliveryBySFExpress),
        BillingAddress: action.payload.BillingAddress,
        Party: action.payload.Party,
        IsDeliveryFee: action.payload.Lines.filter((line) => line.IsDelivery).length ? true : false,
        IsDeliveryBySFExpressFee: action.payload.IsDeliveryBySFExpressFee,
        Staff: action.payload.Staff,
        isCartValid: checkCartValid(action.payload),
        SFExpressTotal: action.payload.SFExpressTotal,
        DepositListProduct: action.payload.Lines.filter((item) => item?.Deposit)
      };

      draft.Deposit = deepCopy(action.payload?.Lines[0]?.Deposit);

      draft.isGettingCartDelivery = false;
      break;

    case cartDetailTypes.SET_CART_FULLFILLMENT:
      draft.errorItemId = '';
      break;

    case cartDetailTypes.SET_CART_FULLFILLMENT_FAILED:
      draft.isLoadingCustomCart = false;

      draft.currentCart = deepCopy(action.error);

      draft.checkShippingArea = true;

      draft.errorItemId = getIdItemError(action.error.Lines);

      draft.currentCartCustom = {
        Delivery: action.error.Lines.filter((item) => item.IsDelivery),
        PickUpStore: action.error.Lines.filter((item) => !item.IsDelivery && !item.IsSFexpress),
        SFexpress: action.error.Lines.filter((item) => item.IsSFexpress),
        BillingAddress: action.error.BillingAddress,
        Party: action.error.Party,
        IsDeliveryFee: action.error.Lines.filter((line) => line.IsDelivery).length ? true : false,
        Staff: action.error.Staff,
        isCartValid: checkCartValid(action.error),
        SFExpressTotal: action.error.SFExpressTotal
      };
      break;

    case productTypes.GET_CURRENT_CART_FAILED:
      draft.isLoading = false;

      draft.isGettingCartDelivery = false;
      break;

    case cartTypes.GET_CART_STRIP_ADS:
      break;

    case cartTypes.GET_CART_STRIP_ADS_SUCCESS:
      draft.cartStripAds = action.payload.Data;
      break;

    case cartTypes.GET_CART_STRIP_ADS_FAILED:
      draft.errorStripAdsCart = action.error;
      break;

    case cartTypes.UPDATE_CART_SELLER:
      draft.cartSellerStatus =
          (action.payload.storeId.length > 0 && action.payload.staffId.length > 0) ||
          (action.payload.storeId.length === 0 && action.payload.staffId.length === 0);
      break;

    case cartTypes.GET_PROMOTION_BY_ID_SUCCESS:
      draft.promotionData = action.payload.Promotion;
      break;

    case productTypes.GET_GIFTING_OPTION_SUCCESS:
      draft.giftingOptions = deepCopy(action.payload);
      break;

    case productTypes.UPDATE_CP_CUSTOMER:
      draft.isUpdateContactInterestSuccess = false;
      break;

    case productTypes.UPDATE_CP_CUSTOMER_SUCCESS:
      draft.isUpdateContactInterestSuccess = true;
      break;

    case productTypes.UPDATE_CP_CUSTOMER_FAILED:
      draft.isUpdateContactInterestSuccess = false;
      break;

    case productTypes.IS_PRODUCT_DETAIL:
      draft.isProductDetail = true;
      break;

    case cartDetailTypes.SET_BLOCK_INSTALLMENT:
      draft.blockInstallment = action.payload.block;
      break;

    case cartDetailTypes.BLOCK_INSTALLMENT_CHECK:
      draft.showInstallmentBlocker = action.payload.show;
      break;

    case cartDetailTypes.GET_CART_DELIVERY:
      draft.isGetCartDelivery = true;

      draft.isGettingCartDelivery = true;
      break;

    case cartDetailTypes.GET_CART_DELIVERY_SUCCESS:
      draft.isGetCartDelivery = false;
      break;

    case cartDetailTypes.GET_CART_DELIVERY_FAILED:
      draft.isGetCartDelivery = false;
      break;

    case productTypes.SAVE_SO_CART:
      draft.isLoading = true;

      draft.isSaveSO = false;
      break;

    case productTypes.SAVE_SO_CART_SUCCESS:
      draft.isLoading = false;

      draft.isSaveSO = true;
      break;

    case productTypes.SAVE_SO_CART_FAILED:
      draft.isLoading = false;

      draft.isSaveSO = false;

      draft.error = action.error;
      break;

    case cartDetailTypes.CHANGE_DELIVERY_OPTION:
      draft.isChangedDeliveryOption = true;
      break;
    }


    return draft;
  });

export default cartDetailReducer;
