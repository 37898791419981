import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

import WellBeingProductsItemModel from './WellBeingProductsItemModel';

export default class WellBeingProductsModel {
  constructor() {
    this['Buy Now Button Link'] = new SitecoreLinkModel();

    this['Buy Now Button Text'] = new SitecoreTextModel();

    this['Title'] = new SitecoreTextModel();

    this['Well-being Products'] = [];
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Link')) {
      this['Buy Now Button Link'].getData(dataSource['Buy Now Button Link']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Buy Now Button Text')) {
      this['Buy Now Button Text'].getData(dataSource['Buy Now Button Text']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'].getData(dataSource['Title']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Well-being Products')) {
      let newProductsArray = [];

      if (dataSource['Well-being Products']?.length) {
        dataSource['Well-being Products'].map((element) => {
          newProductsArray.push(new WellBeingProductsItemModel().getData(deepCopy(element) || {}, this['Buy Now Button Link']));
        });
      }

      this['Well-being Products'] = newProductsArray;
    }

    return this;
  }
}
