import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import {GA4_LOCAL_STORAGE_KEY} from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import AddToCart from '../SlicingPage/AddToCart';
import ProductAddedToCardModel from './model/ProductAddedToCartModel';
import ProductAddedToCartShimmer from './ProductAddedToCartShimmer';

const ProductAddedToCart = ({fields}) => {
  const [inputData, setInputData] = useState(null);
  const cartValue = useSelector((state) => state.productReducer.cartDetailReducer.currentCart);
  const isTWSite = SitecoreContextFactoryService.getValueContextItem('site')?.name === 'OTW';
  const [lstName, setLstName] = useState(null);
  const isGA4 = Global.getIsGA4();

  useEffect(() => {
    const productAddedToCardModel = new ProductAddedToCardModel();

    setInputData(productAddedToCardModel.getData(deepCopy(fields || {})));
  }, []);

  useEffect(() => {
    const LstName = new URLSearchParams(window.location.search).get('LstName');

    setLstName(LstName);
  }, []);

  useDidUpdateEffect(() => {
    if (cartValue && cartValue.Lines?.length > 0) {
      let itemCart = cartValue.Lines[cartValue.Lines.length -1];

      const paramGA4 = Global.getGA4Params();

      paramGA4[itemCart.VariantId] = lstName;

      window.localStorage.setItem(GA4_LOCAL_STORAGE_KEY, JSON.stringify(paramGA4));

      // INF: Google analytics
      if (cartValue?.Lines) {
        //Old Logic GA
        // const params = {
        //   items: Global.getItemList(cartValue.Lines)
        // };

        // new GoogleAnalytic().ggGateWay('event', 'add_to_cart', params);

        //New Logic ON/OFF GA, GA4
        if (isGA4) {
          let dataConvert = [];

          dataConvert.push(itemCart);

          const params = {
            ecommerce: {
              currency: cartValue?.Currency,
              value: itemCart.LineFinalTotalAmount,
              items: Global.getItemsCart(dataConvert, '', '', cartValue?.Currency, '', '', '', lstName)
            }
          };

          new GoogleAnalytic().gAnalytic4('event', 'add_to_cart', params);
        } else {
          const params = {
            items: Global.getItemList(cartValue.Lines)
          };

          new GoogleAnalytic().ggGateWay('event', 'add_to_cart', params);
        }
      }
      // GA HK
      if (isTWSite) {
        let params = {
          'send_to': 'AW-773603377/vXYvCN-92N0CELGA8fAC', 
          'value': itemCart.LineFinalTotalAmount,
          'currency': 'TWD'
        };

        new GoogleAnalytic().ggGateWay('event', 'conversion', params);

        //Yahoo analytic
        window.dotq = window.dotq || []; 

        window.dotq.push({
          'projectId': '10000', 
          'properties': {
            'pixelId': '10148586', 
            'qstrings': {
              'et': 'custom',
              'ea': '01ButtonCart_20210728' 
            }
          }
        });

        //BridgeWell
        window._bwq = window._bwq || [];

        window._bwq.push(['trackSingle', '687-138G445F0MCC238', 'AddToCart',{
          'tags':['cart_687'] }]);
          
        // Tamedia
        if (window.taq) {
          window.taq('track', '8wLls6Qyqd0d', 'AddToCart');
        }

        //facebook pixel code
        if (window.fbq) {
          window.fbq('track', 'AddToCart', {currency:'TWD',value: itemCart.LineFinalTotalAmount});
        }
      }
    }
  }, [cartValue]);

  return inputData ? <AddToCart dataFromLayout={inputData} /> : <ProductAddedToCartShimmer />;
};

ProductAddedToCart.propTypes = {
  fields: PropTypes.object,
  cartValue: PropTypes.object
};

export default ProductAddedToCart;
