import React from 'react';

const ProductAddedToCartShimmer = () => {
  return (
    <div className='add-to-cart__header skeleton__item'>
      <div className='container add-to-cart__header__container'>
        <div className='add-to-cart__header__image'>
          <div className='add-to-cart__header__image__item'>
            <div className='image-line'></div>
          </div>
        </div>
        <div className='add-to-cart__header__contents'>
          <span className='text-line'></span>
          <div className='add-to-cart__header__contents__button'>
            <span className='text-line'></span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductAddedToCartShimmer;
