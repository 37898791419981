import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { behaviorSubjectSubmitForm } from '@components/Form/formBehavior';
import Global from '@utils/global';

import FormFieldError from '../../FormFieldError';
import CustomLabel from '../CustomLabel';

const CustomSingleLineText = (props) => {
  const [label, setLabel] = useState(false);
  const [border, setBorder] = useState(true);
  const { field, value, onChange, errors, tracker } = props;
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  useEffect(() => {
    const behaviorSubjectSubmitFormSubscription = behaviorSubjectSubmitForm.subscribe((params) => {
      setLabel(params.label);
    });

    return () => {
      behaviorSubjectSubmitFormSubscription.unsubscribe();
    };
  }, [behaviorSubjectSubmitForm]);

  const handleOnChange = (field, fieldValue, callback) => {
    let valid = true;
    const errorMessages = [];

    setLabel(fieldValue.length === 0 ? false : true);

    // custom client validation logic here
    if (field.model.required && !fieldValue) {
      valid = false;

      setBorder(false);

      errorMessages.push(`${field.model.title} is required`);
    } else {
      setBorder(true);
    }

    if (field?.model?.name === 'FirstName' || field?.model?.name === 'LastName') {
      const validateErrorObject = {
        FirstName: messageObj?.['Msg_001.1'],
        LastName: messageObj?.['Msg_001.2']
      };
      const validateRegex = Global.nameValidateRegex();

      if (!validateRegex.test(fieldValue)) errorMessages.push(validateErrorObject[field?.model?.name]);
    }

    callback(field.valueField.name, fieldValue, valid, errorMessages);
  };

  return (
    <div className={Global.renderDynamicClass(!border, 'form-group', 'input-error-validate')}>
      <input
        type='text'
        className={Global.renderDynamicClass(label, 'form-control form-control-lg', 'input-valid')}
        id={field.valueField.id}
        name={field.valueField.name}
        value={value}
        maxLength={field.model.maxLength}
        placeholder={field.model.placeholderText}
        onChange={(e) => handleOnChange(field, e.target.value, onChange)}
        onFocus={() => tracker.onFocusField(field, value)}
        onBlur={() => tracker.onBlurField(field, value, errors)}
      />
      <CustomLabel {...props} />
      <FormFieldError {...props} />
    </div>
  );
};

CustomSingleLineText.propTypes = {
  field: PropTypes.object,
  value: PropTypes.any,
  onChange: PropTypes.any,
  errors: PropTypes.any,
  tracker: PropTypes.any
};

export default CustomSingleLineText;
