import { array, func } from 'prop-types';
import React from 'react';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

import SwipeComponent from '../../Share/SwipeComponent';

const PartPartnerSpecialDetailsSwiper = ({ partnerData, handleOpenModalPartner }) => {

  const swiperParams = {
    // loop: true,
    spaceBetween: 18,
    slidesPerView: 2.5,
    watchOverflow: true,
    shouldSwiperUpdate: true,
    breakpoints: {
      768: {
        slidesPerView: 3,
        spaceBetween: 30
      },
      1200: {
        slidesPerView: partnerData.length >= 5 ? 5 : partnerData.length,
        spaceBetween: 34
      }
    }
  };

  return (
    <div className='partner-special__details-swiper'>
      <SwipeComponent param={swiperParams}>
        {partnerData.map((item) => (
          <div key={item?.Id} className='partner-special__details-swiper__item'>
            <div 
              className='partner-logo'
              onClick={() => handleOpenModalPartner(item)}
            >
              <Image field={item?.['Partner Logo']} alt={item?.['Partner Name']} />
            </div>
            <Text tag='p' field={{ value: item?.['Partner Description'] }} className='description' />
          </div>
        ))}
      </SwipeComponent>
    </div>
  );
};

PartPartnerSpecialDetailsSwiper.propTypes = {
  partnerData: array,
  handleOpenModalPartner: func
};

export default PartPartnerSpecialDetailsSwiper;
