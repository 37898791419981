import ProductDetailSpecSectionModel from './ProductDetailSpecSectionModel';


export default class ProductSpecificationsModel {
  constructor() {
    this['Sections'] = [];

  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Sections') && dataSource['Sections']?.length) {      
      dataSource['Sections'].map((item) => {
        this['Sections'].push(new ProductDetailSpecSectionModel().getData(item || {}));
      });
    }

    return this;
  }
}
