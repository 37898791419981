
export const GET_ADDRESS_DATASOURCES = 'GET_ADDRESS_DATASOURCES';

export const GET_ADDRESS_DATASOURCES_SUCCESS = 'GET_ADDRESS_DATASOURCES_SUCCESS';

export const GET_ADDRESS_DATASOURCES_FAILED = 'GET_ADDRESS_DATASOURCES_FAILED';

export const CLEAR_ADDRESS_DATASOURCES = 'CLEAR_ADDRESS_DATASOURCES';

export const DISABLE_REQUIRED_FIELD = 'DISABLE_REQUIRED_FIELD';
