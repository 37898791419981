import './AccountChecking.scss';

import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tabs } from 'react-bootstrap';
import Tab from 'react-bootstrap/Tab';
import { useSelector } from 'react-redux';

import GoogleAnalytic from '@components/Share/GoogleAnalytic';
import ModalComponent from '@components/Share/ModalComponent';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { REDIRECT_CHECKOUT_PARAM } from '@utils/constant';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { useQuery } from '@utils/customsHook/useQuery';
import Global from '@utils/global';

import { VIEW_OFFER_DETAIL_CART_ID_KEY } from '../../PersonalizedOffer/hooks/useGenerateViewOfferDetailLink';
import * as config from './config';
import GuestCheckout from './GuestCheckout';
import AccountCheckingModel from './models/AccountCheckingModel';
import PopupEmailAlready from './PopupEmailAlready';
import ReturningCustomer from './ReturningCustomer';

const AccountChecking = (props) => {
  const { fields } = props;
  const [inputData, setInputData] = useState('');
  const [activeKey, setActiveKey] = useState(config.RETURNING_CUSTOMER_KEY);
  const [isShow, setShow] = useState(false);
  const isLoginSuccess = useSelector(state => state.singleSignOnReducer.userInfoReducer?.isLogin);
  const currentCart = useSelector(state => state.productReducer.cartDetailReducer.currentCart || {});
  const IsAuthenticated = SitecoreContextFactoryService.getValueContextItem('IsAuthenticated');
  const settingGlobal = useSelector((state) => state.settingGlobalReducer.settingGlobal);
  const query = useQuery();

  useEffect(() => {
    setInputData(new AccountCheckingModel().getData(fields || {}));
  }, []);

  useEffect(() => {
    if (isLoginSuccess && inputData) {
      const cartId = query.get(VIEW_OFFER_DETAIL_CART_ID_KEY);
      if (cartId) {
        window.location.href = `${inputData['Continue to Offer Delivery Button Link']?.value?.href}?${query.toString()}`;
      } else {
        window.location.href = inputData['Continue Guest Checkout  Button Link'].value.href;
      }
    }
  }, [isLoginSuccess, inputData, query]);

  useEffect(() => {
    if (!IsAuthenticated  && settingGlobal?.IsDisableGuestCheckout) {
      const fromUrl = window.location.href;

      window.location.href = `${settingGlobal?.LoginLink}?${REDIRECT_CHECKOUT_PARAM}=${fromUrl}`;
    }
  }, [IsAuthenticated, settingGlobal?.IsDisableGuestCheckout]);

  const onCloseModalHandler = () => {
    setShow(false);
  };

  const onActiveTabLoginHandler = () => {
    setShow(false);

    setActiveKey(config.RETURNING_CUSTOMER_KEY);

    window.scrollTo(0, 0);
  };

  const onAlreadyEmailHandler = (isOpen) => {
    setShow(isOpen);
  };

  useDidUpdateEffect(() => {
    if (currentCart?.Lines) {
      const params = {
        items: Global.getItemList(currentCart.Lines)
      };

      new GoogleAnalytic().ggGateWay('event', 'account_checking', params);
    }
  }, [currentCart]);

  return inputData && (
    <div className='account-checking'>
      <div className='container smaller-container'>
        <div className='account-checking__tab'>
          <Tabs activeKey={activeKey} onSelect={currentTab => setActiveKey(currentTab)}>
            <Tab eventKey={config.GUEST_CHECKOUT_KEY} title={inputData['Guest Checkout Label'].value}>
              <GuestCheckout inputData={inputData} alreadyEmailEvt={onAlreadyEmailHandler}/>
            </Tab>
            <Tab eventKey={config.RETURNING_CUSTOMER_KEY} title={inputData['Returning Customer Label'].value}>
              <ReturningCustomer inputData={inputData}/>
            </Tab>
          </Tabs>
          <ModalComponent isShow={isShow} onCloseHandler={onCloseModalHandler} titlePopup={<Text field={inputData['Email Already Exists Label']} tag='span' />} >
            <PopupEmailAlready inputData={inputData} activeTabLoginEvt={onActiveTabLoginHandler}/>
          </ModalComponent>
        </div>
      </div>
    </div>
  );
};

AccountChecking.propTypes = {
  fields: PropTypes.object
};

export default AccountChecking;
