import { ofType } from 'redux-observable';
import { from, of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { updateCPCustomerContact } from '@redux/actions/clientTellingPortalCustomer';
import { checkContactDuplicateFailure, checkContactDuplicateSuccess } from '@redux/actions/cpCheckContactDuplicate';
import { CHECK_CONTACT_DUPLICATE_START } from '@redux/actions/cpCheckContactDuplicate/types';
import { post } from '@services/genericService';

import { CHECK_CONTACT_DUPLICATE_ENDPOINT } from './configs';

export const cpCheckContactDuplicateEpic = (action$) =>
  action$.pipe(
    ofType(CHECK_CONTACT_DUPLICATE_START),
    switchMap((action) => {
      return from(post(CHECK_CONTACT_DUPLICATE_ENDPOINT, action.payload, { 'Content-Type': 'multipart/form-data' }, 'tradein')).pipe(
        switchMap((res) => {
          if (res.data.status_code == 200 && !res.data.error_code) {
            return of(checkContactDuplicateSuccess(res.data?.contacts), updateCPCustomerContact(action.payload));
          } else {
            return of(
              checkContactDuplicateFailure({
                contacts: res.data?.contacts,
                isDuplicatedEmail: res.data?.isDupplicatedEmail,
                isDuplicatedPhone: res.data?.isDupplicatedPhone
              })
            );
          }
        }),
        catchError((error) => of(checkContactDuplicateFailure(error.message || 'Failed')))
      );
    })
  );
