import produce from 'immer';

// Import action types
import * as productTypes from '@redux/actions/product/productTypes';
import { deepCopy } from '@utils/utility';

const initState = {
  specialDeals: null,
  isLoading: false,
  error: ''
};

const specialDealReducer = (state = initState, action) =>
  produce(state, (draft) => {
    switch (action.type) {
    case productTypes.GET_SPECIAL_DEAL:
      draft.isLoading = true;
      break;

    case productTypes.GET_SPECIAL_DEAL_SUCCESS:
      draft.isLoading = false;

      draft.specialDeals = deepCopy(action.payload);
      break;

    case productTypes.GET_SPECIAL_DEAL_FAILED:
      draft.isLoading = false;

      draft.error = deepCopy(action.error);
    }

    return draft;
  });

export default specialDealReducer;
