import React from 'react';

import CPPageHeader from '../CPPageHeader';
import CPPaging from '../CPPaging';
import CPSearchResult from './CPSearchResult';

const CustomerProfileListing = () => {
  return (
    <>
      <CPPageHeader></CPPageHeader>
      <CPSearchResult></CPSearchResult>
      <CPPaging></CPPaging>
    </>
  );
};

export default CustomerProfileListing;