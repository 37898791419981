import Proptypes from 'prop-types';
import React from 'react';

const ProductImagesMenu = ({ onHandleClickZoom }) => {
  return (
    <div className='product-image__menu'>
      <i
        className='product-image__menu__item icon-enlarge'
        onClick={() => onHandleClickZoom()}
      ></i>
      <i className='product-image__menu__item icon-share'></i>
    </div>
  );
};

ProductImagesMenu.propTypes = {
  onHandleClickZoom: Proptypes.any
};

export default ProductImagesMenu;
