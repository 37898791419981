import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { LIST_BENEFIT_KEY, LIST_PRODUCT_FUNCTION, PRODUCT_SUMMARY_KEY } from '../ProductFunctionsDetail';

const removeFunctionNameDuplicate = (preferObject) => {
  const result = { ...preferObject };
  for (const key of Object.keys(result)) {
    result[key] = [
      ...new Map(
        preferObject[key].map((item) =>
          item.Id
            ? [
              item.Id,
              {
                Id: item.Id,
                FunctionName: item.FunctionName
              }
            ]
            : [
              item.FunctionName,
              {
                Id: '',
                FunctionName: item.FunctionName
              }
            ]
        )
      ).values()
    ];
  }

  return result;
};

const removeProductFunctionDuplicateObject = (preferObject) => {
  const result = { ...preferObject };

  for (const key of Object.keys(result)) {
    result[key] = [
      ...new Map(
        preferObject[key].map(
          (item) =>
            // item.Id  ?
            [
              item.Id,
              {
                Id: item.Id
                // Value: item.Value
              }
            ]
          // : [
          //   item.Value,
          //   {
          //     Id: '',
          //     Value: item.Value
          //   }
          // ]
        )
      ).values()
    ];
  }

  return result;
};

const useMapUniqueProductFunction = () => {
  const { productCompareData } = useSelector((state) => state.compareProductReducer);

  return useMemo(() => {
    const functionNameObject = {};
    const productFunctionObject = {};

    if (productCompareData.some((item) => item)) {
      for (const productItem of productCompareData) {
        const productInfoDetail = productItem?.productInfoDetail;
        if (productInfoDetail) {
          for (const key of Object.keys(productInfoDetail)) {
            // INFO: mapped data for productFunctionObject
            if (key === LIST_PRODUCT_FUNCTION) {
              for (const listFunctionItem of productInfoDetail[key]) {
                if (!productFunctionObject[listFunctionItem.Id]) {
                  productFunctionObject[listFunctionItem.Id] = [...listFunctionItem.Functions];
                } else {
                  productFunctionObject[listFunctionItem.Id] = [...productFunctionObject[listFunctionItem.Id], ...listFunctionItem.Functions];
                }
              }
            }
            // INFO: mapped data for functionName object
            if (key !== LIST_BENEFIT_KEY && key !== LIST_PRODUCT_FUNCTION && key !== PRODUCT_SUMMARY_KEY) {
              if (productInfoDetail[key]?.length) {
                if (!functionNameObject[key]) functionNameObject[key] = [...productInfoDetail[key]];
                else functionNameObject[key] = [...functionNameObject[key], ...productInfoDetail[key]];
              }
            }
          }
        }
      }
    }

    // INFO: remove duplicate function
    const uniqueFunctionName = removeFunctionNameDuplicate(functionNameObject);
    const uniqueProductFunctionData = removeProductFunctionDuplicateObject(productFunctionObject);

    return {
      uniqueFunctionName,
      uniqueProductFunctionData
    };
  }, [productCompareData]);
};

export default useMapUniqueProductFunction;
