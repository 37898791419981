export default class PaymentOptionModel {
  constructor() {
    this.PaymentOptionId = '';

    this.PaymentOptionName = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PaymentOptionId')) {
      this['PaymentOptionId'] = dataSource['PaymentOptionId'] ?? this.PaymentOptionId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PaymentOptionName')) {
      this['PaymentOptionName'] = dataSource['PaymentOptionName'] ?? this.PaymentOptionName;
    }

    return this;
  }
}
