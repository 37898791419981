import React from 'react';
import { Fragment } from 'react';

const PolicySearchResultListShimmer = () => {

  return (
    <Fragment>
      <div className='policy-result__item skeleton__item'>
        <h2 className='policy-result__item__title'>
          <div className='text-line'></div>
        </h2>
        <h4 className='policy-result__item__question'>
          <span className='text-line'></span>
        </h4>   
        <p className='policy-result__item__answer'>
          <span className='text-line'></span>
        </p>
        <span className='policy-result__item__learn-more'>
          <span className='text-line'></span>
        </span>
      </div>
    </Fragment>
  );
};

export default PolicySearchResultListShimmer;