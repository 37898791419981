import PropTypes from 'prop-types';
import React, { useState } from 'react';

import Global from '@utils/global';

const VideoFile = ({videoSrc}) => {
  const [durationVideo, setDurationVideo] = useState('00:00');

  const getDurationVideo = (videoSrc) => {
    if (videoSrc) {
      const createVideoTag = document.createElement('video');

      createVideoTag.preload = 'metadata';

      createVideoTag.src = videoSrc;

      createVideoTag.onloadedmetadata = () => {
        let duration = createVideoTag.duration;

        setDurationVideo(Global.fancyTimeFormat(duration));
      };
    }
  };

  return (
    <>
      <video className='upload-media__box__media' playsInline loop autoPlay muted='muted'>
        <source src={videoSrc} type={`video/${Global.videoType(videoSrc)}`}/>
      </video>
      <div className='media-overlay'>
        <span className='media-overlay__icon'></span>
        <span className='media-overlay__time' data-id={getDurationVideo(videoSrc)}>{durationVideo}</span>
      </div>
    </>
  );
};

VideoFile.propTypes = {
  videoSrc: PropTypes.string
};

export default VideoFile;