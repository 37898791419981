import { deepCopy } from '@utils/utility';

import IncludedProductLabelItemModel from './IncludedProductLabelItemModel';
import IncludedProductStockModel from './IncludedProductStockModel';
import PriceModel from './PriceModel';

export default class IncludedProductVariantModel {
  constructor() {
    this['BackOrderDeliveryLabel'] = '';

    this['BannerImage'] = '';

    this['Color'] = '';

    this['Images'] = [];

    this['InStockDeliveryLabel'] = '';

    this['Labels'] = [];

    this['MaxQuantity'] = 0;

    this['MobileBannerImage'] = '';

    this['OutStockDeliveryLabel'] = '';

    this['PreOrderDeliveryLabel'] = '';

    this['Price'] = new PriceModel();

    this['Stock'] = new IncludedProductStockModel();

    this['VariantId'] = '';

    this['VariantName'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BackOrderDeliveryLabel')) {
      this['BackOrderDeliveryLabel'] = dataSource['BackOrderDeliveryLabel'] ?? this['BackOrderDeliveryLabel'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BannerImage')) {
      this['BannerImage'] = dataSource['BannerImage'] ?? this['BannerImage'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Color')) {
      this['Color'] = dataSource['Color'] ?? this['Color'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      let tempArr = [];

      dataSource['Images'].map((ele, index) => {
        tempArr.push(dataSource['Images'][index] || '');
      });

      this['Images'] = tempArr;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InStockDeliveryLabel')) {
      this['InStockDeliveryLabel'] = dataSource['InStockDeliveryLabel'] ?? this['InStockDeliveryLabel'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Labels')) {
      let newLabels = [];

      if (dataSource['Labels']) {
        dataSource['Labels'].map((ele) => {
          newLabels.push(new IncludedProductLabelItemModel().getData(deepCopy(ele)));
        });
      }

      this['Labels'] = newLabels;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MaxQuantity')) {
      this['MaxQuantity'] = dataSource['MaxQuantity'] ?? this['MaxQuantity'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MobileBannerImage')) {
      this['MobileBannerImage'] = dataSource['MobileBannerImage'] ?? this['MobileBannerImage'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OutStockDeliveryLabel')) {
      this['OutStockDeliveryLabel'] = dataSource['OutStockDeliveryLabel'] ?? this['OutStockDeliveryLabel'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PreOrderDeliveryLabel')) {
      this['PreOrderDeliveryLabel'] = dataSource['PreOrderDeliveryLabel'] ?? this['PreOrderDeliveryLabel'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this['Price'].getData(dataSource['Price']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock')) {
      this['Stock'].getData(dataSource['Stock']);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantId')) {
      this['VariantId'] = dataSource['VariantId'] ?? this['VariantId'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantName')) {
      this['VariantName'] = dataSource['VariantName'] ?? this['VariantName'];
    }

    return this;
  }
}
