import './OneColumn.scss';
import Proptypes from 'prop-types';
import React from 'react';

import { Placeholder } from '@sitecore-jss/sitecore-jss-react';

const OneColumn = (props) => {
  return (
    <div className='one-column'>
      <div className='container'>
        <div className='one-column__wrap'>
          <Placeholder name='osim-one-col-wide' rendering={props.rendering} />
        </div>
      </div>
    </div>
  );
};

OneColumn.propTypes = {
  rendering: Proptypes.any,
  params: Proptypes.any
};

export default OneColumn;
