import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { getAsObservable } from '@services/genericService';

import { DEFAULT_API_VALUE } from '../../../utils/constant';

const GET_SO_CONTACT_ENDPOINT = '/clientteling/get-contact-saleorder';

export const useGetSOContactData = () => {
  return useCallback(
    async (soContactId) =>
      await new Promise((resolve, reject) =>
        from(getAsObservable(GET_SO_CONTACT_ENDPOINT, { contactId: soContactId }))
          .pipe(
            map((res) => {
              if (res?.status === 200 && res?.data?.success) {
                return resolve(res?.data?.contacts);
              }

              return resolve([]);
            }),
            catchError((error) => reject(error))
          )
          .subscribe()
      ),
    []
  );
};

export const useGenerateCPContactData = () => {
  const clientTellingPortalCustomer = useSelector((state) => state.clientTellingPortalCustomer);

  return useCallback(
    (phoneNumber, email, prefixCode) => {
      // eslint-disable-next-line no-undef
      let formData = new FormData();

      const params = {
        ContactID: clientTellingPortalCustomer.customerInformation.ContactID || DEFAULT_API_VALUE,
        Email: email || DEFAULT_API_VALUE,
        PhoneNumber: phoneNumber || DEFAULT_API_VALUE,
        PrefixPhoneNumber: prefixCode || DEFAULT_API_VALUE,
        DOB: DEFAULT_API_VALUE,
        PersonalInformation: {
          FirstName: DEFAULT_API_VALUE,
          LastName: DEFAULT_API_VALUE,
          Gender: DEFAULT_API_VALUE
        },
        CPCustomerInfo: {
          MaritalStatus: DEFAULT_API_VALUE,
          ReferPOS: DEFAULT_API_VALUE,
          AllowDirectMail: DEFAULT_API_VALUE,
          AllowSms: DEFAULT_API_VALUE,
          AllowEmail: DEFAULT_API_VALUE,
          PDPAAgreement: DEFAULT_API_VALUE,
          SignatureImageUrl: DEFAULT_API_VALUE,
          CommunicationChannels: [
            {
              PhoneNumber: DEFAULT_API_VALUE,
              AllowWeChat: DEFAULT_API_VALUE,
              AllowWhatsapp: DEFAULT_API_VALUE,
              AllowSMS: DEFAULT_API_VALUE
            }
          ]
        }
      };

      for (let keyParams in params) {
        if (keyParams == 'PersonalInformation') {
          for (let keyChild in params[keyParams]) {
            formData.append(`PersonalInformation[${keyChild}]`, params[keyParams][keyChild]);
          }
        } else if (keyParams == 'CPCustomerInfo') {
          for (let keyChild in params[keyParams]) {
            if (keyChild === 'CommunicationChannels') {
              params[keyParams][keyChild].forEach((item, index) => {
                for (let keyItem in item) {
                  formData.append(`CPCustomerInfo[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
                }
              });
            } else {
              formData.append(`CPCustomerInfo[${keyChild}]`, params[keyParams][keyChild]);
            }
          }
        } else if (keyParams == 'CPCustomerInterest') {
          for (let keyChild in params[keyParams]) {
            params[keyParams][keyChild].forEach((item, index) => {
              for (let keyItem in item) {
                if (keyItem == 'Questions') {
                  params[keyParams][keyChild][index][keyItem].forEach((childItem, childIndex) => {
                    for (let keyChildItem in childItem) {
                      formData.append(
                        `CPCustomerInterest[${keyChild}][${index}][${keyItem}][${childIndex}][${keyChildItem}]`,
                        childItem[keyChildItem]
                      );
                    }
                  });
                } else formData.append(`CPCustomerInterest[${keyChild}][${index}][${keyItem}]`, item[keyItem]);
              }
            });
          }
        } else {
          formData.append(keyParams, params[keyParams]);
        }
      }

      return formData;
    },
    [clientTellingPortalCustomer]
  );
};