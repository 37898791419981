import './ResourceListing.scss';

import Proptypes from 'prop-types';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';

import { PRODUCT_SEARCH_INIT_PAGE, RESOURCE_SEARCH_PAGESZ } from '@components/Search/ProductSearchResult/configs';
import ProductSearchResultListShimmer from '@components/Search/ProductSearchResult/ProductSearchResultListShimmer';
import ResourceSearchItem from '@components/Search/ProductSearchResult/ResourceSearchItem';
import AutoCompleteUI from '@components/Share/AutoCompleteUI';
import { headerOffsetHeight } from '@components/Share/Header/headerHeightService';
import LoadingShimmer from '@components/Share/LoadingShimmer';
import Pagination from '@components/Share/Pagination';
import { WELL_BEING_INIT_PAGE } from '@components/Wellness/WellnessListing/configs';
import { commonSearchResource, getAutoCompleteResource} from '@redux/actions/common';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import Global from '@utils/global';
import { deepCopy } from '@utils/utility';

import ResourceListingModel from '../Models/ResourceListingModel';

const ResourceListing = (props) => {
  const {
    fields,
    resourceSearchData,
    isResourceLoading,
    resourceCount,
    resourceSuggestionList
  } = props;

  const dispatch = useDispatch();
  const resourceListContainer = useRef(null);
  const [dataFields, setDataFields] = useState(null);
  const [isDoneScroll, setIsDoneScroll] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(null);

  const [params, setParams] = useState({
    searchTerm: '',
    page: PRODUCT_SEARCH_INIT_PAGE,
    pageSz: RESOURCE_SEARCH_PAGESZ,
    isDispatch: false
  });

  const setDoneScroll = () => {
    setIsDoneScroll(true);
  };

  useEffect(() => {
    const resourceListingModel = new ResourceListingModel();

    setDataFields(resourceListingModel.getData(deepCopy(fields) || {}));
  }, []);

  const onSearchHandler = (value) => {
    setParams({
      ...params,
      searchTerm: value,
      page: WELL_BEING_INIT_PAGE,
      isDispatch: !params.isDispatch
    });

    Global.genURLParamAndStayPage([{ key: 'Term', value: value }]);
  };

  const onResetTerm = () => {
    setParams({
      ...params,
      searchTerm: ''
    });
  };

  const onChangePageHandler = (pageNum) => {
    setDoneScroll(false);

    setParams({
      ...params,
      page: pageNum,
      isDispatch: !params.isDispatch
    });
  };

  useEffect(() => {
    const headerSubscription = headerOffsetHeight.subscribe((item) => item && setHeaderHeight(item));

    return () => {
      headerSubscription.unsubscribe();
    };
  }, [headerOffsetHeight]);

  useDidUpdateEffect(() => {
    dispatch(commonSearchResource(params));
  }, [params.isDispatch]);

  useDidUpdateEffect(() => {
    if (resourceListContainer?.current) {
      Global.scrollToWithCallback(resourceListContainer.current.offsetTop - headerHeight, setDoneScroll);
    }
  }, [params.page]);

  useEffect(() => {
    setParams({
      ...params,
      searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || ''
    });

    setIsDoneScroll(true);

    dispatch(
      commonSearchResource({
        searchTerm: Global.onGetKeyInParamUrl()?.Term?.[0] || '',
        page: WELL_BEING_INIT_PAGE,
        pageSz: RESOURCE_SEARCH_PAGESZ
      })
    );
  }, []);

  const onChangeSearchTerm = (value) => {
    setParams({
      ...params,
      searchTerm: value
    });
  };

  return dataFields && (
    <div className='resource-listing'>
      <div className='container'>
        <Text tag='h2' className='resource-listing__title' field={dataFields['Title']} />
      </div>
      <div className='searchbox-article'>
        <div className='searchbox-article--tool'>
          <AutoCompleteUI
          //Extra For Resource
            onResetTerm={onResetTerm}
            onChangeSearchTerm={onChangeSearchTerm}
            searchTerm={params?.searchTerm}
            placeholder={dataFields?.['Hidden Text']?.value || ''}
            suggestionsListData={resourceSuggestionList}
            onGetSuggestionListHandler={getAutoCompleteResource}
            onSearchHandler={onSearchHandler}
          ></AutoCompleteUI>
        </div>
      </div>

      <div className='container' ref={resourceListContainer}>
        <div className='results-item'>
          {resourceCount + ' '}
          <Text field={dataFields['Result Message']} />
        </div>
        <div className='resource-search search-result__content'>
          {!isResourceLoading && isDoneScroll && dataFields ? (
            <Fragment>
              { 
              resourceSearchData?.length ? (
                resourceSearchData?.map((item) => {
                  return <ResourceSearchItem key={item.ItemId} dataFromAPI={item} dataFromLayout={dataFields} />;
                })
              ) : (
                <span><Text field={dataFields['No Result Text']} /></span>
              )
              }
            </Fragment>
          ) : (
            <LoadingShimmer itemNumb={RESOURCE_SEARCH_PAGESZ}>
              <ProductSearchResultListShimmer></ProductSearchResultListShimmer>
            </LoadingShimmer>
          )}
          <Pagination
            isReset={false}
            itemPerPage={RESOURCE_SEARCH_PAGESZ}
            totalItem={resourceCount}
            onChangePageHandler={onChangePageHandler}
          />
        </div>
      </div>
    </div>
  );
};

ResourceListing.propTypes = {
  fields: Proptypes.any,
  resourceSearchData: Proptypes.array,
  isResourceLoading: Proptypes.bool,
  resourceCount: Proptypes.number,
  resourceSuggestionList: Proptypes.array
};

const mapStateToProps = (state) => {
  const resourceSearchReducer = state.commonSearchReducer.resourceSearchReducer;

  return {
    resourceCount: resourceSearchReducer.resourceCount || 0,
    resourceSearchData: resourceSearchReducer.resourceSearchData || null,
    isResourceLoading: resourceSearchReducer.isResourceLoading || null,
    resourceSuggestionList: resourceSearchReducer.resourceSuggestionList || null,
  };
};

export default connect(mapStateToProps)(ResourceListing);
