import { object } from 'prop-types';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSOCarts } from '@redux/actions/getSOCarts';
import { useQuery } from '@utils/customsHook/useQuery';

import BoxData from '../BoxData';
import CustomerSaleOrdersShimmer from './CustomerSaleOrdersShimmer';
import CustomerSaleOrdersModel from './models/CustomerSaleOrdersModel';
import SaleOrderItem from './SaleOrderItem';
import ViewAllSaleOrderModal from './ViewAllSaleOrderModal';

const MAX_SO_CART_ITEM = 2;

export const DEFAULT_PAGE_SIZE = 6;

const initialStates = {
  layoutData: null,
  isShowModal: false,
  getSOCartParams: null,
  ordersListing: [],
  totalPage: 0,
  currentPage: 1,
  isLoading: false,
  setCurrentPage: () => {},
  onCloseModal: () => {}
};
const CustomerSaleOrdersContext = createContext(initialStates);

export const useCustomerSaleOrders = () => useContext(CustomerSaleOrdersContext);

const CustomerSaleOrders = ({ fields }) => {
  const dispatch = useDispatch();
  const query = useQuery();
  const soCartData = useSelector((state) => state?.getSOCartsReducer.dataApi);
  const isLoading = useSelector((state) => state?.getSOCartsReducer.isLoading);
  const isGettingData = useSelector((state) => state?.getSOCartsReducer?.isLoading);
  const [layoutData, setLayoutData] = useState(null);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersListing, setOrdersListing] = useState([]);

  const soCarts = useMemo(() => {
    return soCartData?.Carts || [];
  }, [soCartData]);

  const totalPage = useMemo(() => {
    const totalItems = soCartData?.Count || 0;
    const pageSize = soCartData?.Take || 0;

    return soCartData ? Math.ceil(totalItems / pageSize) : 0;
  }, [soCartData]);

  const getSOCartParams = useMemo(() => {
    const contactId = query.get('contactId');

    return {
      ContactId: contactId || '',
      statuses: 'Draft, Active, Viewed, Completed, Expired',
      skip: (currentPage - 1) * DEFAULT_PAGE_SIZE,
      take: DEFAULT_PAGE_SIZE,
      sortBy: 'CreatedDate',
      sortDirection: 'Desc'
    };
  }, [query]);

  const totalRecord = useMemo(() => {
    return soCartData?.Count;
  }, [soCartData]);

  useEffect(() => {
    const model = new CustomerSaleOrdersModel();

    setLayoutData(model.getData(fields));
  }, []);

  useEffect(() => {
    dispatch(
      getSOCarts({
        ...getSOCartParams
      })
    );
  }, [getSOCartParams]);

  useEffect(() => {
    if (soCarts.length) {
      setOrdersListing((prevState) => [...prevState, ...soCarts]);
    }
  }, [soCarts]);

  return layoutData ? (
    <CustomerSaleOrdersContext.Provider
      value={{
        layoutData: layoutData,
        isShowModal: isOpenModal,
        getSOCartParams: getSOCartParams,
        ordersListing: ordersListing,
        totalPage: totalPage,
        currentPage: currentPage,
        isLoading: isLoading,
        setCurrentPage: setCurrentPage,
        onCloseModal: () => setIsOpenModal(false)
      }}
    >
      <div className='col-md-6 profiles__col'>
        {!isGettingData ? (
          <BoxData component='last-visit' layoutData={layoutData} onClickView={() => setIsOpenModal(true)} data={soCarts} totalRecord={totalRecord}>
            {ordersListing.map((orderItem, index) =>
              index < MAX_SO_CART_ITEM ? (
                <SaleOrderItem
                  key={orderItem?.CartName}
                  orderItem={{
                    ProductNames: orderItem?.ProductNames,
                    ExpiredDate: orderItem?.ExpiredDateWithFormat,
                    Status: orderItem?.Status
                  }}
                  layoutData={layoutData}
                />
              ) : (
                <></>
              )
            )}
          </BoxData>
        ) : (
          <CustomerSaleOrdersShimmer title={layoutData?.['Section Title']} />
        )}
      </div>
      <ViewAllSaleOrderModal />
    </CustomerSaleOrdersContext.Provider>
  ) : (
    <></>
  );
};

CustomerSaleOrders.propTypes = {
  fields: object
};

export default CustomerSaleOrders;
