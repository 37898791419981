import { createContext } from 'react';

const initialStates = {
  layoutData: {},
  userRegisterInfo: {
    firstName: '',
    lastName: '',
    email: '',
    dob: '',
    phoneNumber: ''
  },
  setUserRegisterInfo: () => {},
  isOpenLoginModal: false,
  setIsOpenLoginModal: () => {}
};

export const CampaignRegisterContext = createContext(initialStates);
