export default class GenderDatasourceModel {
  constructor() {
    this['Key'] = '';

    this['Value'] = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Key')) {
      this['Key'] = dataSource['Key'] ?? this['Key'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Value')) {
      this['Value'] = dataSource['Value'] ?? this['Value'];
    }

    return this;
  }
}
