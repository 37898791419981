import * as cPOverviewTypes from './cPOverviewTypes.js';

export const cPOverview = (payload) => ({
  type: cPOverviewTypes.GET_OVERVIEW,
  payload
});

export const cPOverviewSuccess = (payload) => ({
  type: cPOverviewTypes.GET_OVERVIEW_SUCCESS,
  payload
});

export const cPOverviewFailed = (error) => ({
  type: cPOverviewTypes.GET_OVERVIEW_FAILED,
  error
});