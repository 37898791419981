// Import dependencies, operators
import { combineEpics } from 'redux-observable';

import { 
  getAutoCompleteAllEpic, 
  getAutoCompletePolicyEpic,
  getAutoCompleteProductEpic,
  getAutoCompleteResourceEpic,
  getAutoCompleteWellnessEpic } from './AutoComplete';
import { 
  commonSearchAllEpic,
  commonSearchPolicyEpic,
  commonSearchProductEpic,
  commonSearchProductFilterEpic,
  commonSearchResourceEpic,
  commonSearchWellnessEpic} from './Search';

const commonEpic = combineEpics(
  getAutoCompleteAllEpic,
  getAutoCompleteWellnessEpic,
  getAutoCompleteProductEpic,
  getAutoCompletePolicyEpic,
  getAutoCompleteResourceEpic,

  commonSearchAllEpic,
  commonSearchWellnessEpic,
  commonSearchProductEpic,
  commonSearchPolicyEpic,
  commonSearchProductFilterEpic,
  commonSearchResourceEpic
);

export default commonEpic;
