import Proptypes from 'prop-types';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import BuyingBundleAccordion from '@components/Share/BuyingBundleAccordion';

import { ADDITIONAL_OPTIONS_EVENT_KEY } from '../config';

const BundleBOAdditionalOptions = ({ dataItem, dataFromLayout, dataText, onSelectAdditionalOptionsHandler, productId, checkValidate, UniqueProductId, additionalOptionIndex, disposalPolicyLabel, disposalPolicyPage }) => {
  const messageObj = useSelector((state) => state.getMessageReducer.objMessages);

  const onOpenItemHandler = (additionalOptionsList) => {
    return additionalOptionsList.some((additionalOption) => {
      return additionalOption.IsChoseAdditionalOption;
    });
  };

  const onFilterActiveAdditionalOption = (data) => {
    let selectedOption = data.Products.find((product) => {
      return product.IsChoseAdditionalOption === true;
    });

    return selectedOption;
  };

  const onCheckValidation = (data) => {
    return data.Products.some((product) => {
      return product.IsChoseAdditionalOption === true;
    });
  };

  return (
    <Fragment>
      {dataItem?.Products.length ? (
        <BuyingBundleAccordion
          parentId={dataItem.RelationshipId}
          data={dataItem?.Products}
          headerType={'FIRSTTYPE'}
          eventKey={ADDITIONAL_OPTIONS_EVENT_KEY}
          isOpenItem={onOpenItemHandler(dataItem?.Products) ? false : true}
          onAction={onSelectAdditionalOptionsHandler}
          selectedItemsName={`${onFilterActiveAdditionalOption(dataItem)?.DisplayName} (${
            onFilterActiveAdditionalOption(dataItem)?.Price?.ListPrice === 0
              ? messageObj['LBL-WarrantyFee'] || ''
              : onFilterActiveAdditionalOption(dataItem)?.Price?.ListPriceWithCurrency
          })`}
          labelText={dataFromLayout[dataItem.RelationshipId].fields.Title.value}
          changeText={dataText['Change Text'].value}
          outStockText={dataText['Out Stock Text'].value}
          errorMsg={dataFromLayout[dataItem.RelationshipId].fields['Validation Message'].value}
          isShowError={checkValidate && !onCheckValidation(dataItem)}
          productId={productId}
          uniqueProductId={UniqueProductId}
          additionalOptionIndex={additionalOptionIndex}
          disposalPolicyLabel={disposalPolicyLabel}
          disposalPolicyPage={disposalPolicyPage}
        />
      ) : null}
    </Fragment>
  );
};

BundleBOAdditionalOptions.propTypes = {
  dataItem: Proptypes.any,
  dataText: Proptypes.any,
  onSelectAdditionalOptionsHandler: Proptypes.any,
  checkValidate: Proptypes.any,
  dataFromLayout: Proptypes.object,
  productId: Proptypes.string,
  UniqueProductId: Proptypes.string,
  additionalOptionIndex: Proptypes.string,
  disposalPolicyLabel: Proptypes.string, 
  disposalPolicyPage: Proptypes.string
};

export default BundleBOAdditionalOptions;
