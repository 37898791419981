import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

export default class BannerFieldsModel {
  constructor() {
    this['First Button Icon'] = new SitecoreImageModel();

    this['First CTA Button'] = new SitecoreLinkModel();

    this['First CTA Button Text'] = new SitecoreTextModel();

    this['Second Button Icon'] = new SitecoreImageModel();

    this['Second CTA Button'] = new SitecoreLinkModel();

    this['Second CTA Button Text'] = new SitecoreTextModel();
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'First Button Icon')) {
      this['First Button Icon'].getData(dataSource['First Button Icon']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'First CTA Button')) {
      this['First CTA Button'].getData(dataSource['First CTA Button']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'First CTA Button Text')) {
      this['First CTA Button Text'].getData(dataSource['First CTA Button Text']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second Button Icon')) {
      this['Second Button Icon'].getData(dataSource['Second Button Icon']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second CTA Button')) {
      this['Second CTA Button'].getData(dataSource['Second CTA Button']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Second CTA Button Text')) {
      this['Second CTA Button Text'].getData(dataSource['Second CTA Button Text']);
    }

    return this;
  }
}
