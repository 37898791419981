import PriceApiModel from '@components/Product/models/PriceApiModel';
import ProductCardStockModel from '@components/Product/models/ProductCardStockModel';
import {STOCK_QUANTITY} from '@utils/constant';

import ProductAvailabilityApiModel from '../models/ProductAvailabilityApiModel';

const TypeProduct = {
  PreOrderable: 'PreOrderable',
  BackOrderable: 'BackOrderable',
  InStock: 'InStock',
  OutOfStock: 'OutOfStock'
};

export default class VariantDetailApiModel {
  constructor() {
    this.VariantId = '';

    this.ProductName = '';

    this.VariantName = '';

    this.Price = new PriceApiModel();

    this.ProductOverview = '';

    this.BannerImage = '';

    this.BannerImageAlt = '';

    this.MobileBannerImage = '';

    this.Color = '';

    this.Images = [];

    this.VariantInformation = '';

    this.ProductAvailability = new ProductAvailabilityApiModel();

    this.Stock = new ProductCardStockModel();

    this.BackOrderDeliveryLabel = '';

    this.OutStockDeliveryLabel = '';

    this.PreOrderDeliveryLabel = '';

    this.InStockDeliveryLabel = '';

    this.LastLabel = '';

    this.LastFewUnitsLeftLabel = '';

    this.CategoryName = '';

    this.PriceExtraInformation = '';

    this.VariantPropertyValue = '';
  }
  getData(dataSource, CategoryName) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BackOrderDeliveryLabel')) {
      this.BackOrderDeliveryLabel = dataSource.BackOrderDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'OutStockDeliveryLabel')) {
      this.OutStockDeliveryLabel = dataSource.OutStockDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PreOrderDeliveryLabel')) {
      this.PreOrderDeliveryLabel = dataSource.PreOrderDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'InStockDeliveryLabel')) {
      this.InStockDeliveryLabel = dataSource.InStockDeliveryLabel;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantId')) {
      this.VariantId = dataSource.VariantId;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductName')) {
      this.ProductName = dataSource.ProductName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantName')) {
      this.VariantName = dataSource.VariantName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ProductOverview')) {
      this.ProductOverview = dataSource.ProductOverview;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BannerImage')) {
      this.BannerImage = dataSource.BannerImage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'BannerImageAlt')) {
      this.BannerImageAlt = dataSource.BannerImageAlt;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'MobileBannerImage')) {
      this.MobileBannerImage = dataSource.MobileBannerImage;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Color')) {
      this.Color = dataSource.Color;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Images')) {
      this.Images = dataSource.Images;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantInformation')) {
      this.VariantInformation = dataSource.VariantInformation;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantInformation') && dataSource.ProductAvailability?.length) {
      this.ProductAvailability.getData(dataSource.ProductAvailability[0]);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Price')) {
      this.Price.getData(dataSource.Price);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Stock') && dataSource.Stock) {
      this.Stock.getData(dataSource.Stock);
      if (this.Stock.StockStatus.Name === TypeProduct.InStock) {
        this.IsInStockProduct = true;
      }
      if (this.Stock.StockStatus.Name === TypeProduct.OutOfStock) {
        this.IsOutStockProduct = true;
      }
      if (this.Stock.StockStatus.Name === TypeProduct.PreOrderable) {
        this.IsPreOrderProduct = true;
      }
      if (this.Stock.StockStatus.Name === TypeProduct.BackOrderable) {
        this.IsBackOrderProduct = true;
      }
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastLabel') && dataSource.LastLabel) {
      this.LastLabel = dataSource.LastLabel.replace(STOCK_QUANTITY, dataSource?.Stock?.PurchasableLimit || 0);
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'LastFewUnitsLeftLabel')) {
      this.LastFewUnitsLeftLabel = dataSource.LastFewUnitsLeftLabel;
    }
    if (CategoryName && CategoryName !== '') {
      this.CategoryName = CategoryName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PriceExtraInformation')) {
      this.PriceExtraInformation = dataSource.PriceExtraInformation ?? this.PriceExtraInformation;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'VariantPropertyValue')) {
      this.VariantPropertyValue = dataSource.VariantPropertyValue ?? this.VariantPropertyValue;
    }
    else this.VariantPropertyValue = dataSource.Color;
    
    return this;
  }
}
