import SitecoreTextModel from '@models/SitecoreTextModel';

import SharingFieldsModel from './SharingFieldsModel';

export default class SharingItemModel {
  constructor() {
    this.id = new SitecoreTextModel();

    this.fields = new SharingFieldsModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this.id = dataSource.id;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }

    return this;
  }
}
