import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Button Text', 'Description', 'Title'];
const IMAGE_ARR = ['Icon'];
const PLAIN_TEXT_ARR = ['ProductManual'];

export default class ProductManualDownloadModel {
  constructor() {
    TEXT_ARR.forEach((item) => (this[item] = new SitecoreTextModel()));

    IMAGE_ARR.forEach((item) => (this[item] = new SitecoreImageModel()));

    PLAIN_TEXT_ARR.forEach((item) => (this[item] = ''));
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    IMAGE_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    PLAIN_TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item] = dataSource[item];
      }
    });

    return this;
  }
}
