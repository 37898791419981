import { combineEpics } from 'redux-observable';

import {
  removeMyProductEpic
} from './GetUserProductDetails/index';

const productWarrantyEpic = combineEpics(
  removeMyProductEpic
);

export default productWarrantyEpic;