import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getSOCartPagingStart } from '@redux/actions/personalizeOffer/getSOCartPaging';

import { DEFAULT_FETCH_OFFER_LISTING_PAGE_SIZE } from './configs';

export const useFetchOfferListing = () => {
  const dispatch = useDispatch();

  return useCallback((page) => {
    const getSOCartPagingPayload = {
      page: page,
      pageSz: DEFAULT_FETCH_OFFER_LISTING_PAGE_SIZE
    };

    dispatch(getSOCartPagingStart(getSOCartPagingPayload));
  }, []);
};
