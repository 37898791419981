import React, { Fragment,useEffect } from 'react';
import { useDispatch } from 'react-redux';

const WellnessListingSearch = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    // Code
  }, []);

  return (
    <Fragment>
      <div>WellnessListingSearch</div>
    </Fragment>
  );
};

export default WellnessListingSearch;