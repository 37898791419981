import { object } from 'prop-types';
import React, { useState } from 'react';
import { Accordion } from 'react-bootstrap';

import { Image, Text } from '@sitecore-jss/sitecore-jss-react';

const PartnerSpecialDetailAccordion = ({ layoutData }) => {
  const [activeKey, setActiveKey] = useState(-1);

  const handleExpandAccordion = (index) => {
    activeKey === index ? setActiveKey(-1) : setActiveKey(index);
  };

  return (
    <>
      <Text tag='h2' field={layoutData?.['Name Section Title']} className='partner-special-detail__title' />
      <div className='partner-special-detail__accordion'>
        <Accordion activeKey={activeKey}>
          {layoutData['ListPartnerSpecials'].map((item, index) => (
            <div key={index} className='partner-special-detail__accordion-item'>
              <div className='partner-special-detail__accordion-item__title' onClick={() => handleExpandAccordion(index + 1)}>
                <Text tag='h4' field={{ value: item?.['Partner Name'] }} className='title' />
                <span className={`icon-chevron-down-big ${activeKey === index + 1 ? 'accordion-active' : ''}`}></span>
              </div>
              <Accordion.Collapse eventKey={index + 1}>
                <div className='brand-banner__container'>
                  <div className='brand-banner__wrapper'>
                    <Image field={item?.['Partner Banner']}  alt={item?.['Partner Name']} />
                  </div>
                </div>
              </Accordion.Collapse>
            </div>
          ))}
        </Accordion>
      </div>
    </>
  );
};

PartnerSpecialDetailAccordion.propTypes = {
  layoutData: object
};

export default PartnerSpecialDetailAccordion;
