import SitecoreLinkModel from '@models/SitecoreLinkModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import SitecoreImageModel from '@models/SitecoreImageModel';

const TEXT_ARR = [
  'Title',
  'Purchase Completed Label',
  'Description Purchase Completed Label',
  'Customer Name Label',
  'POS Details Label',
  'POS Transaction ID Label',
  'Transaction Amount Label',
  'Message Please Process And Review Label',
  'Button Review Text',
  'Button Continue Session Text'
];

const LINK_ARR = [
  'Button Review Link',
  'Button Continue Session Link'
];

const IMAGE_ARR = [
  'Icon Purchase Completed'
];

export default class PurchaseOnSpotCompletedModel {
  constructor() {
    TEXT_ARR.forEach((text) => {
      this[text] = new SitecoreTextModel();
    });

    LINK_ARR.forEach((link) => {
      this[link] = new SitecoreLinkModel();
    });

    IMAGE_ARR.forEach((image) => {
      this[image] = new SitecoreImageModel();
    });
  }

  getData(dataSource) {
    TEXT_ARR.forEach((text) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, text)) {
        this[text].getData(dataSource[text]);
      }
    });

    LINK_ARR.forEach((link) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, link)) {
        this[link].getData(dataSource[link]);
      }
    });

    IMAGE_ARR.forEach((image) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, image)) {
        this[image].getData(dataSource[image]);
      }
    });

    return this;
  }
}
