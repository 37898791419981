import { useCallback } from 'react';
import { useSelector } from 'react-redux';

import { LIST_PRODUCT_FUNCTION } from '../ProductFunctionsDetail';

const useCheckListProductFunctionData = () => {
  const { productCompareData } = useSelector((state) => state.compareProductReducer);

  return useCallback(
    (functionId) => {
      return (
        productCompareData?.length &&
        productCompareData.some((item) => {
          const productInfoDetail = item?.productInfoDetail;
          const listProductFunction = productInfoDetail?.[LIST_PRODUCT_FUNCTION] || [];

          return (
            listProductFunction?.length &&
            listProductFunction.some((functionItem) => functionItem.Id === functionId && functionItem?.Functions?.length)
          );
        })
      );
    },
    [productCompareData]
  );
};

export default useCheckListProductFunctionData;
