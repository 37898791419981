import ProductVariantFieldsModel from './ProductVariantFieldsModel';

export default class ProductVariantModel {
  constructor() {
    this.displayName = '';

    this.id = '';

    this.name = '';

    this.ListPrice = '';

    this.SellingPrice = '';

    this.fields = new ProductVariantFieldsModel();
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'displayName')) {
      this.displayName = dataSource.displayName ? dataSource.displayName : this.displayName;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'id')) {
      this.id = dataSource.id ? dataSource.id : this.id;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'name')) {
      this.name = dataSource.name ? dataSource.name : this.name;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'ListPrice')) {
      this.ListPrice = dataSource.ListPrice ? dataSource.ListPrice : this.ListPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'SellingPrice')) {
      this.SellingPrice = dataSource.SellingPrice ? dataSource.SellingPrice : this.SellingPrice;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'fields')) {
      this['fields'].getData(dataSource['fields']);
    }

    return this;
  }
}
