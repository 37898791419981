export const swiperParams = {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev'
  },
  breakpoints: {
    1680: {
      slidesPerView: 4,
      spaceBetween: 80
    },
    1200: {
      slidesPerView: 4,
      spaceBetween: 60
    },
    1024: {
      slidesPerView: 3,
      spaceBetween: 40
    },
    768: {
      slidesPerView: 3,
      spaceBetween: 20
    },
    320: {
      slidesPerView: 2,
      spaceBetween: 18
    }
  }
};
