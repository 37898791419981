export default class NotifyModel {
  constructor() {
    this.status = false;

    this.message = '';
  }

  getData(dataSource) {
    if (this.validate(dataSource)) {
      this.status = dataSource['status'] ? dataSource['status'] : '';

      this.message = dataSource['message'] ? dataSource['message'] : this.message;
    } else if(dataSource) {
      this.status = dataSource;
    } else {
      this.status = dataSource;
    }

    return this;
  }

  validate(dataSource) {
    if (
      dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'status') &&
      dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'message')
    ) {
      return true;
    }

    return false;
  }
}