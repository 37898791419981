export default class BreadCrumbItemModel {
  constructor() {
    this['PageUrl'] = '/';

    this['Title'] = '';
  }
  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PageUrl')) {
      this['PageUrl'] = dataSource['PageUrl'] && dataSource['PageUrl'].length ? dataSource['PageUrl'] : this['PageUrl'];
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Title')) {
      this['Title'] = dataSource['Title'] && dataSource['Title'].length ? dataSource['Title'] : this['Title'];
    }

    return this;
  }
}
