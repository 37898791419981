import SitecoreTextModel from '@models/SitecoreTextModel';

const TEXT_ARR = ['Background color', 'Content', 'Day expires', 'Text button', 'Title', 'Text color'];

export default class CookieBannerModel {
  constructor() {
    TEXT_ARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    this['IsShow'] = false;
  }

  getData(dataSource) {
    TEXT_ARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'IsShow')) {
      this['IsShow'] = dataSource['IsShow'];
    }

    return this;
  }
}
