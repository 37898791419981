import './HowToPlayPopup.scss';

import React, { useContext } from 'react';

import { Text } from '@sitecore-jss/sitecore-jss-react';

import { SpinWinBannerContext } from '../Spin&WinBanner';

const HowToPlayPopup = () => {
  const { layoutData } = useContext(SpinWinBannerContext);

  return (
    <div className='howtoplay-popup'>
      <Text tag='h2' className='howtoplay-popup__title text-center' field={layoutData['How To Play Title']} />
      <div className='howtoplay-popup__content'>
        <Text tag='p' field={layoutData['How To Play Description']} />
      </div>
    </div>
  );
};

export default HowToPlayPopup;
