import './QuickAccess.scss';

import { object } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getCPCustomer, getQuickAccessLayoutService, setIsActiveAccess, setIsDeactiveAccess } from '@redux/actions/clientTellingPortalCustomer';
import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
import { Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { useDidUpdateEffect } from '@utils/customsHook/useDidUpdateEffect';
import { deepCopy } from '@utils/utility';

import QuickAccessModel from './model/QuickAccessModel';

const QuickAccess = ({ fields, rendering }) => {
  const [layoutData, setLayoutData] = useState(null);
  const clientTellingPortalQuickAccess = useSelector((state) => state.clientTellingPortalQuickAccess);
  const cartDetailReducer = useSelector(state => state.productReducer.cartDetailReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    const model = new QuickAccessModel();

    setLayoutData(model.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    if (cartDetailReducer.isProductDetail) {
      if (cartDetailReducer.isUpdateContactInterestSuccess) {
        // INFO: check if you are in product detail, wait for update-contact-interest api to run successfully, then run get-contact api
        getCPCustomerAPI();
      }
    } else {
      getCPCustomerAPI();
    }
  }, [cartDetailReducer.isUpdateContactInterestSuccess]);

  useDidUpdateEffect(() => {
    dispatch(getQuickAccessLayoutService({ ...layoutData }));
  }, [layoutData]);

  const getCPCustomerAPI = () => {
    if (SitecoreContextFactoryService.getValueContextItem('cpCustomerId')) {
      dispatch(getCPCustomer({
        id: SitecoreContextFactoryService.getValueContextItem('cpCustomerId'),
        sessionId: SitecoreContextFactoryService.getValueContextItem('cpSessionId'),
        fromURL: false
      }));
    }
  };

  return layoutData && (
    <>
      <div className='quick-access'>
        <div className='quick-access__wrap'>
          <div
            className={`quick-access__icon ${clientTellingPortalQuickAccess.isActiveAccess ? 'active' : ''}`}
            onClick={() => {
              dispatch(setIsActiveAccess(!clientTellingPortalQuickAccess.isActiveAccess));
            }}
          >
            <img src={require('@assets/images/CP-images/add-icon.PNG')} alt='x' />
          </div>
          <ul className={`access-listing ${clientTellingPortalQuickAccess.isActiveAccess ? 'show' : ''}`}>
            <Placeholder name='cp-quick-access' rendering={rendering} dataModel={layoutData}/>
          </ul>
          {
            clientTellingPortalQuickAccess.isActiveQACustomerBasicProfile ||
            clientTellingPortalQuickAccess.isActiveQACustomerLifestyle ||
            clientTellingPortalQuickAccess.isActiveQAProductInterestListing ? (
                <div
                  className='overlay-black'
                  onClick={() => {
                    dispatch(setIsDeactiveAccess());
                  }}
                ></div>
              ) : ''
          }
        </div>
      </div>
      {
        clientTellingPortalQuickAccess.isActiveAccess ? (
          <div
            className='quick-access__overlay overlay-transparent'
            onClick={() => {
              dispatch(setIsActiveAccess(false));
            }}
          ></div>
        ) :''
      }
    </>
  );
};

QuickAccess.propTypes = {
  fields: object,
  rendering: object
};

export default QuickAccess;
