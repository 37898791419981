import { ofType } from 'redux-observable';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { addPriorityToCartFailed, addPriorityToCartSuccess } from '@redux/actions/addPriorityToCart';
import { ADD_PRIORITY_TO_CART } from '@redux/actions/addPriorityToCart/types';
import { getCurrentCart } from '@redux/actions/product';
import { postAsObservable } from '@services/genericService';

import { ACTION_ADD_PRIORITY_TO_CART } from './configs';

const addPriorityToCartEpic = (action$) =>
  action$.pipe(
    ofType(ADD_PRIORITY_TO_CART),
    switchMap((action) => {
      return postAsObservable(ACTION_ADD_PRIORITY_TO_CART, action.payload).pipe(
        switchMap((res) => {
          if (res.status === 200 || res.data.Success) {
            return of(
              addPriorityToCartSuccess(res.data), 
              getCurrentCart()
            );
          } else {
            return of(addPriorityToCartFailed(res.data?.Errors?.[0] || 'Failed'));
          }
        }),
        catchError((error) => of(addPriorityToCartFailed(error || 'Failed')))
      );
    })
  );

export default addPriorityToCartEpic;
