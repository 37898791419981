import React from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

import ProductInfoSection from '../../PersonalizedOfferWidget/ProductInfoSection';
import LazyLoading from '../LazyLoading';
import { usePersonalizedOffersWidget } from '../PersonalizedOffersWidgetProvider';

const PersonalizedOffersWidgetBody = () => {
  const { layoutData, offerListingData } = usePersonalizedOffersWidget();

  return (
    <div className='personalized-offer-listing'>
      <div className='container'>
        <Link className='my-account-section__back-link' field={layoutData['Back Button Link']}>
          {'< '} <Text field={layoutData['Back Button Text']} />
        </Link>
      </div>
      {offerListingData.length ? (
        <div aria-label='offer-lisitng-wrapper' className='container personalized-offer-listing__wrapper'>
          {offerListingData.map((cartItem) => (
            <div key={cartItem.CartName} className='personalized-offer-listing__item'>
              <ProductInfoSection layoutData={layoutData} cartData={cartItem} />
            </div>
          ))}
        </div>
      ) : (
        <></>
      )}
      <LazyLoading />
    </div>
  );
};

export default PersonalizedOffersWidgetBody;
