export const isDisableItems = (listId, targetQuantity, targetVariantId) => {
  const countNum = listId.filter((item) => item.VariantId === targetVariantId).length;

  if (targetQuantity - countNum === 0) {
    return true;
  } else {
    return false;
  }
};

export const isActiveItems = (listId, itemId) => {
  return listId.find((itemInList) => itemInList.GiftId === itemId);
};
