import SitecoreContextFactory from '../lib/SitecoreContextFactory';
const NO_LANGUAGE = 'No language';

const SitecoreContextFactoryService = {
  getValueContextItem: (nameString) => {
    return SitecoreContextFactory?.getSitecoreContext()?.[nameString];
  },
  getValueContextRouteItem: (nameString) => {
    return SitecoreContextFactory?.getSitecoreContext()?.route?.[nameString];
  },
  getNameCurrentLanguage: () => {
    const arrayLanguage = SitecoreContextFactoryService.getValueContextItem('availableLanguages');

    const encodeCurrentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
    if (arrayLanguage && encodeCurrentLanguage) {
      const currentLanguage = arrayLanguage.find(
        (languageItem) => languageItem.code === encodeCurrentLanguage
      );

      return currentLanguage?.name || NO_LANGUAGE;
    }

    return NO_LANGUAGE;
  },
  getAvailableLanguages: () => {
    return SitecoreContextFactoryService.getValueContextItem('availableLanguages') || [];
  },
  getNameCurrentCPLanguage: () => {
    const arrayLanguage = SitecoreContextFactoryService.getValueContextItem('cpAvailableLanguages');

    const encodeCurrentLanguage = SitecoreContextFactoryService.getValueContextItem('language');
    if (arrayLanguage && encodeCurrentLanguage) {
      const currentLanguage = arrayLanguage.find(
        (languageItem) => languageItem.code === encodeCurrentLanguage
      );

      return currentLanguage?.name || NO_LANGUAGE;
    }

    return NO_LANGUAGE;
  },
};

export default SitecoreContextFactoryService;
