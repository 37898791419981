import { combineReducers } from 'redux';

import policySearchReducer from './policySearch';
import productSearchReducer from './productSearch';
import resourceSearchReducer from './resourceSearch';
import wellnessSearchReducer from './wellnessSearch';

export const commonSearchReducer = combineReducers({
  wellnessSearchReducer,
  productSearchReducer,
  policySearchReducer,
  resourceSearchReducer
});
