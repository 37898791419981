import { combineEpics } from 'redux-observable';

import createBookingExperienceContactEpic from './createBookingExperienceContact';
import getScheduleOtpCodeEpic from './getScheduleOtpCode';
import { getTotalBookedDateOfStoreEpic, getTotalBookedTimeSlotOfDateEpic } from './getTotalBookedTimeSlot';
import scheduleAppointmentEpic from './scheduleAppointment';
import updateBookingExperienceEpic from './updateBookingExperience';
import updateBookingExperienceContactEpic from './updateBookingExperienceContact';
import validateScheduleOtpEpic from './validateScheduleOtp';

export const bookingExperienceEpic = combineEpics(
  scheduleAppointmentEpic,
  createBookingExperienceContactEpic,
  getScheduleOtpCodeEpic,
  validateScheduleOtpEpic,
  updateBookingExperienceContactEpic,
  updateBookingExperienceEpic,
  getTotalBookedTimeSlotOfDateEpic,
  getTotalBookedDateOfStoreEpic
);
