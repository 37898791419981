import './BannerWithPopup.scss';

import Proptypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { OverlayTrigger } from 'react-bootstrap';

import ResponsiveImage from '@components/ResponsiveImage';
import { Image, Link, RichText, Text } from '@sitecore-jss/sitecore-jss-react';
import { IPAD_BREAKPOINT } from '@utils/constant';
import { useWindowSize } from '@utils/customsHook/useWindowSize';
import { deepCopy } from '@utils/utility';

import SwipeComponent from '../Share/SwipeComponent';
import BannerWithPopupModel from './Model/BannerWithPopupModel';
import PopoverItem from './PopoverItem';

const BannerWithPopup = ({ fields }) => {
  const currentWindowWidth = useWindowSize();
  const swipeRef = useRef();
  const [adOnDetailTitle, setAdOnDetailTitle] = useState('');
  const [adOnDetailContent, setAdOnDetailContent] = useState('');
  const [bannerWithPopupField, setBannerWithPopupField] = useState(null);
  const [windowWidth, setWindowWidth] = useState(768);
  const [twoBackgroundUrl, setTwoBackgroundUrl] = useState(null);
  const MINIMUM_ADDON_ROW = 1;

  // const swipeParams = {
  //   slidesPerView: 5,
  //   spaceBetween: 10,
  //   watchOverflow: true,
  //   navigation: {
  //     nextEl: '.swiper-button-next',
  //     prevEl: '.swiper-button-prev'
  //   },
  // };

  useEffect(() => {
    const bannerWithPopupModel = new BannerWithPopupModel();

    setBannerWithPopupField(bannerWithPopupModel.getData(deepCopy(fields) || {}));
  }, []);

  useEffect(() => {
    setWindowWidth(window.innerWidth);
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth && windowWidth < IPAD_BREAKPOINT) {
      setTwoBackgroundUrl(bannerWithPopupField?.['2nd Background Image Mobile']?.value?.src || '');
    }
    if (windowWidth && windowWidth >= IPAD_BREAKPOINT) {
      setTwoBackgroundUrl(bannerWithPopupField?.['2nd Background Image']?.value?.src || '');
    }
  }, [windowWidth]);

  const onMouseMoveToAdOnItem = (title, content) => {
    setAdOnDetailTitle(title);

    setAdOnDetailContent(content);
  };

  const onMouseLeaveToAdOnItem = () => {
    setAdOnDetailTitle('');

    setAdOnDetailContent('');
  };

  return (
    bannerWithPopupField && (
      // INFO: aria-rowcount={Math.floor(bannerWithPopupField['Features bar'] / 3)} using for mobile screen (max addon icon per row is 3)
      // INFO: reason of `#${bannerWithPopupField[key]?.value?.replace('#', '')}` is handle case user input hex color with # before value. Currently, the backend response not have a # before value
      <div
        aria-rowcount={Math.floor(bannerWithPopupField['Features bar'].length / 3) || MINIMUM_ADDON_ROW}
        aria-current={currentWindowWidth < 576 ? 'mobile' : 'desktop'}
        className='banner-with__popup'
        style={{ backgroundColor: bannerWithPopupField['Background Color Code'].value }}
      >
        <div className='banner-with__popup__bg'>
          <ResponsiveImage 
            imageMobile={bannerWithPopupField['Background Image Mobile']}
            srcSet={[{ mw: 768, image: bannerWithPopupField['Background Image'] }]}
          />
        </div>
        <div className='container banner-with__popup__wrap'>
          <div
            className='banner-with__popup__container'
            style={{
              background: twoBackgroundUrl ? `url(${twoBackgroundUrl})` : bannerWithPopupField['2nd Background Color Code'].value || '',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundSize: 'cover'
            }}
          >
            {/* <div className='banner-border__corner'>
            <div className='banner-border__corner__top'>
              <span></span>
            </div>
            <div className='banner-border__corner__bottom'>
              <span></span>
            </div>
          </div> */}
            {/* {
            bannerWithPopupField['2nd Background Image'].value.src ? (
              <Image field={bannerWithPopupField['2nd Background Image']} className='small-background' />
            ) : ''
          } */}
            <div className='banner-with__popup__wrapper'>
              <div
                className={`banner-with__popup__box ${
                  bannerWithPopupField['Left Text Align'].value ? 'banner-with__popup__swap__item__position' : ''
                }`}
              >
                <div className='banner-with__popup-image'>
                  <div className='banner-with__popup-image__wrapper'>
                    {bannerWithPopupField['Background Product Image'].value.src ? (
                      <Image field={bannerWithPopupField['Background Product Image']} className='product-image-bgr' />
                    ) : (
                      ''
                    )}
                    {bannerWithPopupField['Product Image'].value.src ? (
                      <Image field={bannerWithPopupField['Product Image']} className='product-image' />
                    ) : (
                      ''
                    )}
                  </div>
                </div>
                <div className='banner-with__popup-box'>
                  <div className='banner-with__popup-icon'>
                    <div className='banner-with__popup-icon__container'>
                      <div className='banner-with__popup-icon__wrapper'>
                        <Image media={bannerWithPopupField['Icon']} className='banner-with__popup-icon__image' />
                      </div>
                    </div>
                  </div>
                  <div className='banner-with__popup-content'>
                    <Text
                      tag='p'
                      field={bannerWithPopupField['HeaderTitle Text']}
                      className='banner-with__popup-content__header'
                      style={{
                        color: `#${bannerWithPopupField['Content Text Color Code']?.value?.replace('#', '')}`
                      }}
                    />
                    <Text
                      tag='p'
                      field={bannerWithPopupField['Title Text']}
                      className='banner-with__popup-content__title'
                      style={{
                        color: `#${bannerWithPopupField['Content Text Color Code']?.value?.replace('#', '')}`
                      }}
                    />
                    <Text
                      tag='p'
                      field={bannerWithPopupField['Subtitle Text']}
                      className='banner-with__popup-content__tagline'
                      style={{
                        color: `#${bannerWithPopupField['Content Text Color Code']?.value?.replace('#', '')}`
                      }}
                    />
                    <RichText
                      tag='div'
                      field={bannerWithPopupField.Description}
                      className='banner-with__popup-content__description'
                      style={{
                        color: `#${bannerWithPopupField['Content Text Color Code']?.value?.replace('#', '')}`
                      }}
                    />
                  </div>
                  <div className='banner-with__popup-feature'>
                    <Text
                      tag='p'
                      field={bannerWithPopupField['Feature Title']}
                      className='banner-with__popup-feature__title'
                      style={{
                        color: `#${bannerWithPopupField['Content Text Color Code']?.value?.replace('#', '')}`
                      }}
                    />
                    <div className='banner-with__popup-feature__addon'>
                      {/*<SwipeComponent setRef={swipeRef} param={swipeParams}>*/}
                      {bannerWithPopupField['Features bar']?.length
                        ? bannerWithPopupField['Features bar'].map((featuresVal, index) => (
                          <div
                            key={index}
                            className='banner-with__popup-feature__addon-item'
                            onMouseMove={() =>
                              onMouseMoveToAdOnItem(featuresVal.fields['Feature Title'].value, featuresVal.fields['Feature Description'].value)
                            }
                            onMouseLeave={onMouseLeaveToAdOnItem}
                          >
                            <OverlayTrigger
                              trigger={['hover', 'hover']}
                              placement={bannerWithPopupField['Feature Items Modal Position']?.name || 'bottom'}
                              overlay={PopoverItem(
                                adOnDetailTitle,
                                adOnDetailContent,
                                bannerWithPopupField['Header Background Color Code'].value,
                                bannerWithPopupField['Header Text Color Code'].value,
                                bannerWithPopupField['Body Background Color Code'].value,
                                bannerWithPopupField['Body Text Color Code'].value
                              )}
                            >
                              <Image field={featuresVal.fields['Feature Image']} />
                            </OverlayTrigger>
                          </div>
                        ))
                        : ''}
                      {/*</SwipeComponent>*/}
                    </div>
                    <RichText
                      tag='p'
                      field={bannerWithPopupField['Extra Information']}
                      className='banner-with__popup-feature__learn'
                      style={{
                        color: `#${bannerWithPopupField['Content Text Color Code']?.value?.replace('#', '')}`
                      }}
                    />
                  </div>
                  <div className='banner-with__popup-btn'>
                    <Link
                      field={bannerWithPopupField['CTA Button Link']}
                      className='btn btn-primary'
                      style={{
                        backgroundColor: `#${bannerWithPopupField['Button Background Color Code']?.value?.replace('#', '')}`,
                        color: `#${bannerWithPopupField['Button Text Color Code']?.value?.replace('#', '')}`
                      }}
                    >
                      <Text field={bannerWithPopupField['CTA Button']}></Text>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

BannerWithPopup.propTypes = {
  fields: Proptypes.any
};

export default BannerWithPopup;
