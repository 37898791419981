import * as types from './emailTrackingTypes';

export const startTrackingEmailGoal = ((payload) => ({
  type: types.START_TRACKING_EMAIL,
  payload
}));

export const trackingEmailGoalSuccess = ((payload) => ({
  type: types.TRACKING_EMAIL_SUCCESS,
  payload
}));

export const trackingEmailGoalFailed = ((payload) => ({
  type: types.TRACKING_EMAIL_FAILED,
  payload
}));