import SitecoreImageModel from '@models/SitecoreImageModel';
import SitecoreTextModel from '@models/SitecoreTextModel';
import { deepCopy } from '@utils/utility';

const TEXTARR = [
  'Title',
  'From Date',
  'To Date',
  'MessageDisplay',
  'Description',
  'From Time',
  'To Time',
  'Registered Text Button',
  'Registered Message',
  'Unregistered Text Button',
  'Registration Link',
  'MessageDisplay',
  'When And Where Text',
  'About The Event Text',
  'IsAvailableToRegister',
  'IsRegistered',
  'View Detail Link',
  'Open From Text',
  'Expired Message Text',
  'Open To Text'
];

const IMGARR = ['Gallery Images'];

export default class MemberEventDetailModel {
  constructor() {
    TEXTARR.forEach((item) => {
      this[item] = new SitecoreTextModel();
    });

    IMGARR.forEach((item) => {
      this[item] = new SitecoreImageModel();
    });

    this['Gallery Images'] = [];

    this['Banner Image'] = {};

    this['Location'] = [];

    this['Registration Start Time'] = '';

    this['Registration End Time'] = '';
  }

  getData(dataSource) {
    TEXTARR.forEach((item) => {
      if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, item)) {
        this[item].getData(dataSource[item]);
      }
    });

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Gallery Images')) {
      let newItemsList = [];

      dataSource['Gallery Images'] &&
        dataSource['Gallery Images'].map((element) => {
          newItemsList.push(deepCopy(element));
        });

      this['Gallery Images'] = newItemsList;
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'EventId')) {
      this['EventId'] = deepCopy(dataSource['EventId']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Registration Start Time')) {
      this['Registration Start Time'] = deepCopy(dataSource['Registration Start Time']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Registration End Time')) {
      this['Registration End Time'] = deepCopy(dataSource['Registration End Time']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Banner Image')) {
      this['Banner Image'] = deepCopy(dataSource['Banner Image']);
    }

    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Location')) {
      let newItemsList = [];

      dataSource['Location'] &&
        dataSource['Location'].map((element) => {
          newItemsList.push(deepCopy(element));
        });

      this['Location'] = newItemsList;
    }

    return this;
  }
}
