import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import SitecoreContextFactoryService from '@services/sitecoreContextFactoryService';
const VERIFY_PAGE_ID = '995478f3-e63d-4f46-b7ee-8f62ba89c125';

const CPLoginCheck = () => {
  const loginReducer = useSelector((state) => state.singleSignOnReducer.userInfoReducer);
  const isClientTelingUser = SitecoreContextFactoryService.getValueContextItem('isClientTelingUser');
  const cpLoginPageLink = SitecoreContextFactoryService.getValueContextItem('cpLoginPageLink');
  const verifyLoginTemplateId = SitecoreContextFactoryService.getValueContextRouteItem('templateId');

  useEffect(() => {
    const urlPathname = window.location.pathname;

    if (urlPathname != cpLoginPageLink && verifyLoginTemplateId != VERIFY_PAGE_ID && !isClientTelingUser) {
      window.localStorage.removeItem('POSOrderIdLocal');

      window.location.href = cpLoginPageLink;
    }
  }, [loginReducer.isCPLogin]);

  // useEffect(() => {
  //   const urlPath = window.location.pathname !== cpLoginPageLink ? window.location.pathname : '';
  //   const urlPageSesion = JSON.parse(window.sessionStorage.getItem('urlPage'))?.urlPage || '';

  //   let urlRedirect = {
  //     urlPage: urlPath,
  //     prevUrlPage: urlPageSesion 
  //   };

  //   window.sessionStorage.setItem('urlPage', JSON.stringify(urlRedirect)); //INFO: get URL to redirect when login timeout
  // }, []);

  return <></>;
};

export default CPLoginCheck;