export default class CartStripAdsApiItemModel {
  constructor() {
    this.Description = '';

    this.PromotionId = '';
  }

  getData(dataSource) {
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'Description')) {
      this.Description = dataSource.Description;
    }
    if (dataSource && Object.prototype.hasOwnProperty.call(dataSource, 'PromotionId')) {
      this.PromotionId = dataSource.PromotionId;
    }

    return this;
  }
}
