import { bool, object, string } from 'prop-types';
import React from 'react';

import { Link, Text } from '@sitecore-jss/sitecore-jss-react';

const SerialNumberInput = ({ hasSerialNumber, layoutData, errorMsg, ...props }) => {
  return hasSerialNumber ? (
    <>
      <div className='warranty-registration__form-input__item'>
        <input {...props} type='text' id='serialNumber' name='serialNumber' placeholder={layoutData['Product Serial Number Label'].value} />
        <label htmlFor='serialNumber' className='form-group__label'>
          <Text field={layoutData['Product Serial Number Label']} />
        </label>
        {errorMsg ? <span className='error-validate'>{errorMsg}</span> : <></>}
      </div>
      <div className='warranty-registration__form-link__item'>
        <Link field={layoutData['Find Serial Number Button Link']} className='link-text'>
          <Text field={layoutData['Find Serial Number Button Label']} />
        </Link>
      </div>
    </>
  ) : (
    <></>
  );
};

SerialNumberInput.propTypes = {
  hasSerialNumber: bool,
  layoutData: object,
  errorMsg: string
};

export default SerialNumberInput;
