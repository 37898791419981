import React, { useMemo } from 'react';

import { useScheduleAppointmentContext } from '../../../hooks/useScheduleAppointmentContext';
import ProductSelectSwiper from '../ProductSelectSwiper';

const SelectTea = () => {
  const { layoutData, setScheduleData } = useScheduleAppointmentContext();

  const teaInfoData = useMemo(() => {
    return layoutData?.Teas?.length
      ? layoutData.Teas.map((tea) => ({
        productName: tea?.fields?.Name,
        description: tea?.fields?.Description,
        productId: tea?.id,
        image: tea?.fields?.Image
      }))
      : [];
  }, [layoutData]);

  const initialTea = useMemo(() => {
    if (layoutData?.appointment) {
      const teaData = layoutData?.Teas;
      const teaSelectedId = layoutData?.appointment?.Tea?.Id;
      const teaExistInTeaData = teaData?.find((tea) => tea.id === teaSelectedId);

      return teaData.indexOf(teaExistInTeaData);
    }
  }, [layoutData?.Teas, layoutData?.appointment]);

  const handleSelectTea = (teaId) => {
    setScheduleData((prev) => ({
      ...prev,
      personalizeExperience: {
        ...prev.personalizeExperience,
        teaId: teaId
      }
    }));
  };

  return layoutData?.Teas?.length ? (
    <div className='personalize-experience__body-item'>
      <ProductSelectSwiper
        label={layoutData['Select Tea Label']}
        productInfoData={teaInfoData}
        initialSlide={initialTea}
        onSelectProduct={handleSelectTea}
      />
    </div>
  ) : (
    <></>
  );
};

export default SelectTea;
