export const PARAMS_SUBMIT = {
    Term: 'Term',
    CategoryId: 'CategoryId',
    SortBy: 'SortBy',
    RangePrice: 'RangePrice',
    Colors: 'Colors',
    Features: 'Features',
    AreasOfConcern: 'AreasOfConcern',
    TradeInAvailable: 'TradeInAvailable'
};

export const VALUE_FILTER = {
    Color: 'Color',
    Price: 'Price',
    Feature: 'Feature',
    AreasOfConcern: 'Areas Of Concern',
    TradeIn: 'Trade-in'
};

export const HELP_ME_DECIDE_PAGE_SIZE = 10;
export const HELP_ME_DECIDE_FIRST_PAGE = 1;



export const STEP_RANGE_COLOR = '_osim_product_colors_sm';

export const STEP_RANGE_MIN_PRICE = '_osim_product_minprice_td';

export const STEP_RANGE_MAX_PRICE = '_osim_product_maxprice_td';

export const STEP_RANGE_FEATURE = '_osim_product_features_sm';

export const STEP_RANGE_AREAS = '_osim_product_areas_of_concern_sm';

export const STEP_RANGE_FILTER = 1;

export const STEP_LINE_BACKGROUND_ACTIVE = ['#ccc', '#2a2a2a', '#ccc'];

export const STEP_LINE_BACKGROUND_DISABLED = ['#ccc', '#ccc', '#ccc'];