import { combineEpics } from 'redux-observable';

import bookAppointmentEpic from './bookAppointment';
import createContactEpic from './createContact';
import getContactEpic from './getContact';
import getOtpCodeEpic from './getOtpCode';
import getTotalBookedSlotsEpic from './getTotalBookedSlots';
import updateContactEpic from './updateContact';
import validateOtpEpic from './validateOtp';

const campaignRegister = combineEpics(
  getOtpCodeEpic,
  validateOtpEpic,
  createContactEpic,
  bookAppointmentEpic,
  getTotalBookedSlotsEpic,
  getContactEpic,
  updateContactEpic
);

export default campaignRegister;
